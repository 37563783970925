<mat-card class="px-2 py-2 mb-4 d-flex flex-column justify-content-between">
    <mat-card-title>
        <app-section-title [label]="titleLabel" [helpToDisplay]="helpToDisplay"> </app-section-title>
    </mat-card-title>

    <mat-card-content *ngIf="diagFormGroup" [formGroup]="diagFormGroup">
        <div class="row px-4 mb-4">
            <div class="flex-grow-1">
                <app-state-input-reactive
                    formControlName="valeur"
                    [radioControl]="diagFormGroup.get('valeur')"
                    [disabled]="readonlyMode"
                    [reset]="true"
                    [labels]="true"
                    [choices]="_choices"
                    [(choiceValue)]="section.valeur"
                    (choiceValueChange)="displayElements()"
                >
                </app-state-input-reactive>
            </div>
            <app-notes-buttons [from]="section" [diagnostic]="diagnostic"> </app-notes-buttons>
        </div>
        <div *ngIf="showInformation" class="to-justify">{{ textInformation }}</div>
        <app-form-error [control]="diagFormGroup.get('valeur')" message="Une des valeurs doit être choisie"></app-form-error>

        <div *ngIf="section.valeur === 'present'">
            <div class="row no-gutters px-2 align-items-center underlined mb-3">
                <div class="bold placeholder field">{{ 'Situation' | uppercase }}</div>
                <div *ngIf="canIncrement" class="bold text-center increment placeholder">Nb {{ incrementLabel }}</div>
                <div *ngIf="canDisplayState" class="bold field placeholder">{{ 'Comblée' | uppercase }}</div>
                <div class="flex-grow-1"></div>
            </div>
            <div
                class="row no-gutters px-2 align-items-center"
                *ngFor="let equipment of section.elements; let index = index"
                formArrayName="elementsFormArray"
            >
                <div [formGroupName]="index" class="row no-gutters w-100 align-items-center">
                    <div>
                        <app-custom-select
                            formControlName="situation"
                            [selectControl]="elementsFormArray.controls[index].get('situation')"
                            [showGroup]="true"
                            [disabled]="readonlyMode"
                            [groupChoices]="situationsChoice"
                            [placeholder]="'Emplacement'"
                            [sorted]="true"
                            [addline]="false"
                        >
                        </app-custom-select>
                        <!--                        <mat-form-field>-->
                        <!--                            <mat-select [formControl]="elementsFormArray.controls[index].get('situation')"-->
                        <!--                                        placeholder="Emplacement">-->
                        <!--                                <mat-select-filter [placeholder]="'Rechercher'" [array]="situationsChoice"-->
                        <!--                                                   hasGroup="true" groupArrayName="volumes"-->
                        <!--                                                   displayMember="label" (filteredReturn)="groups=$event">-->
                        <!--                                </mat-select-filter>-->

                        <!--                                <mat-optgroup *ngFor="let group of groups" [label]="group.label">-->
                        <!--                                    <mat-option *ngFor="let option of group.volumes" [value]="option">-->
                        <!--                                        {{ option.label }}-->
                        <!--                                    </mat-option>-->
                        <!--                                </mat-optgroup>-->

                        <!--                            </mat-select>-->
                        <!--                        </mat-form-field>-->
                    </div>
                    <div *ngIf="canIncrement">
                        <app-increment-input-reactive
                            class="tiny-increment"
                            formControlName="nombre"
                            [readonly]="readonlyMode"
                            [incrementControl]="elementsFormArray.controls[index].get('nombre')"
                            [min]="1"
                            [step]="1"
                            [showMatError]="false"
                        >
                        </app-increment-input-reactive>
                    </div>
                    <div *ngIf="canDisplayState">
                        <app-state-input-reactive
                            formControlName="valeur"
                            [reset]="true"
                            [disabled]="!canChangeState"
                            [hideLabel]="true"
                            [radioControl]="elementsFormArray.controls[index].get('valeur')"
                            [choiceValue]="equipment.valeur"
                        >
                        </app-state-input-reactive>
                    </div>
                    <div class="flex-grow-1"></div>
                    <div *ngif="activerAjoutSuppression">
                        <button
                            mat-icon-button
                            color="warn"
                            class="ml-1"
                            matTooltip="Supprimer l'équipement"
                            [disabled]="readonlyMode"
                            (click)="deleteEquipment(equipment)"
                        >
                            <lib-icon class="icon-small">delete</lib-icon>
                        </button>
                    </div>
                    <app-notes-buttons [from]="equipment" [diagnostic]="diagnostic"> </app-notes-buttons>
                </div>
                <div class="w-100">
                    <app-form-error
                        [control]="elementsFormArray.controls[index].get('situation')"
                        [addClass]="'middle-error'"
                        message="Une situation est obligatoire"
                    ></app-form-error>
                    <app-form-error
                        *ngIf="canIncrement"
                        [control]="elementsFormArray.controls[index].get('nombre')"
                        [addClass]="'middle-error'"
                        message="Une valeur est obligatoire"
                    ></app-form-error>
                    <app-form-error
                        *ngIf="canDisplayState"
                        [control]="elementsFormArray.controls[index].get('valeur')"
                        [addClass]="'middle-error'"
                        message="Une des valeurs est obligatoire"
                    ></app-form-error>
                </div>
            </div>
            <div class="d-flex justify-content-center mt-2" *ngif="activerAjoutSuppression">
                <button mat-button color="primary" [disabled]="readonlyMode" (click)="addEquipment()">
                    <lib-icon>add_circle</lib-icon>
                    Ajouter
                </button>
            </div>
        </div>
    </mat-card-content>
</mat-card>
