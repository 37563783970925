import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import * as moment from 'moment';

import { PolluantPrelevement, PrelevementMeta } from 'src/app/modules/diagnostics/polluant/model/polluant-prelevement.model';
import { WidgetSelectModeEnum } from 'src/app/modules/shared/widget-select/widget-select.component';
import { Besoin } from '../../../../../../model/besoin.model';

@Component({
    selector: 'app-prelevement-form-pose',
    templateUrl: './prelevement-form-pose.component.html',
    styleUrls: ['./prelevement-form-pose.component.scss'],
})
export class PrelevementFormPoseComponent implements OnInit {
    formPose: FormGroup;
    listeJustificationNonReglementaire: string[] = ['Absence de stratégie', 'Autre'];
    justificatifNonReglementaireRequired: Boolean = false;

    @Input('diagnostic')
    diagnostic: Diagnostic;
    contenuDiagnostic: PolluantPrelevement;

    @Input('selectedBesoinId')
    selectedBesoinId: string;
    selectedPrelevement: PrelevementMeta;
    besoinStrategie: Besoin;
    isChangeStrategie: boolean;

    @Input() // Utilisé pour passer les champs en read-only
    disabled: Boolean;

    @Output('formChange')
    formChange: EventEmitter<any> = new EventEmitter<any>();

    widgetSelectModeEnum: typeof WidgetSelectModeEnum = WidgetSelectModeEnum;

    constructor(private formBuilder: FormBuilder) {}

    ngOnInit(): void {
        this.contenuDiagnostic = this.diagnostic.contenuDiagnostic as PolluantPrelevement;

        // Récupération du Prélèvement par l'ID de son Besoin
        this.selectedPrelevement = this.contenuDiagnostic.prelevement.data.besoinsList.find(
            (b) => b.besoinPrelevement.id == this.selectedBesoinId
        ) as PrelevementMeta;

        // Récupération du besoin dans la stratégie par l'ID de son Besoin
        this.besoinStrategie = this.selectedPrelevement.besoinPrelevement;
        /*this.contenuDiagnostic.visiteDeChantier.data.besoins.data.besoinsList.find(
            (b) => b.id == this.selectedBesoinId
        ) as Besoin;*/

        this.isChangeStrategie = this.selectedPrelevement.besoinPrelevement === this.besoinStrategie;

        if (!this.listeJustificationNonReglementaire.includes(this.selectedPrelevement.conformitePose.justificatifNonReglementaire)) {
            this.listeJustificationNonReglementaire.push(this.selectedPrelevement.conformitePose.justificatifNonReglementaire);
        }
        this.createForm();
    }

    createForm() {
        this.formPose = this.formBuilder.group({
            faitParLeClient: [this.selectedPrelevement.informationsClientPose.faitParLeClient, Validators.required],
            localisationPrelevement: [
                this.selectedPrelevement.informationsPrelevementPose.localisationPrelevement,
                {
                    updateOn: 'blur',
                },
            ],
            lotFiltre: [this.selectedPrelevement.informationsPrelevementPose.lotFiltre, Validators.required],
            numeroPompe: [
                this.selectedPrelevement.informationsPrelevementPose.numeroPompe,
                {
                    updateOn: 'blur',
                },
            ],
            numeroTete: [
                this.selectedPrelevement.informationsPrelevementPose.numeroTete,
                {
                    updateOn: 'blur',
                },
            ],
            dateDebutPrelevement: [moment(this.selectedPrelevement.informationsPrelevementPose.dateTimeDebutPrelevement), Validators.required],
            dateFinPrelevement: [moment(this.selectedPrelevement.informationsPrelevementPose.dateTimeFinPrelevement), Validators.required],
            debitInitial: [
                this.selectedPrelevement.informationsPrelevementPose.debitInitial,
                {
                    updateOn: 'blur',
                },
            ],
            remarques: [this.selectedPrelevement.informationsPrelevementPose.remarque, Validators.required],

            //Conformité pose
            prelevementReglementaire: [this.selectedPrelevement.conformitePose.prelevementReglementaire, Validators.required],
            justificatifNonReglementaire: [this.selectedPrelevement.conformitePose.justificatifNonReglementaire, Validators.required],
            accreditationCofrac: [this.selectedPrelevement.conformitePose.accreditationCofrac, Validators.required],

            //Validation stratégie
            justificatifChangement: [
                this.selectedPrelevement.validationStrategie.justificatifChangement,
                {
                    updateOn: 'blur',
                },
            ],
            clientPrevenuPar: [this.selectedPrelevement.validationStrategie.clientPrevenuPar],
            informationMeteo: this.formBuilder.group({
                vitesseVent: [this.selectedPrelevement.informationsMeteo.vitesseVent],
                directionVent: [this.selectedPrelevement.informationsMeteo.directionVent],
                temperature: [this.selectedPrelevement.informationsMeteo.temperature],
                hygrometrie: [this.selectedPrelevement.informationsMeteo.hygrometrie],
                pressionAtmospherique: [this.selectedPrelevement.informationsMeteo.pressionAtmospherique],
                conditionMeteo: [this.selectedPrelevement.informationsMeteo.conditionMeteo],
            }),
        });

        this.formPose.valueChanges.subscribe((newValue) => {
            const formValues = this.formPose.getRawValue();

            //Patch des valeurs du diagnostic avec les valeurs du formulaire
            this.selectedPrelevement.informationsClientPose.faitParLeClient = formValues.faitParLeClient;
            this.selectedPrelevement.informationsPrelevementPose.localisationPrelevement = formValues.localisationPrelevement;
            this.selectedPrelevement.informationsPrelevementPose.lotFiltre = formValues.lotFiltre;
            this.selectedPrelevement.informationsPrelevementPose.numeroPompe = formValues.numeroPompe;
            this.selectedPrelevement.informationsPrelevementPose.numeroTete = formValues.numeroTete;
            this.selectedPrelevement.informationsPrelevementPose.dateTimeDebutPrelevement = formValues.dateDebutPrelevement;
            this.selectedPrelevement.informationsPrelevementPose.dateTimeFinPrelevement = formValues.dateFinPrelevement;
            this.selectedPrelevement.informationsPrelevementPose.debitInitial = formValues.debitInitial;
            this.selectedPrelevement.informationsPrelevementPose.remarque = formValues.remarques;

            //Conformité pose
            this.selectedPrelevement.conformitePose.prelevementReglementaire = formValues.prelevementReglementaire;
            this.selectedPrelevement.conformitePose.justificatifNonReglementaire = formValues.justificatifNonReglementaire;
            this.selectedPrelevement.conformitePose.accreditationCofrac = formValues.accreditationCofrac;

            //Validation stratégie
            this.selectedPrelevement.validationStrategie.justificatifChangement = formValues.justificatifChangement;
            this.selectedPrelevement.validationStrategie.clientPrevenuPar = formValues.clientPrevenuPar;

            //Météo
            this.selectedPrelevement.informationsMeteo.vitesseVent = formValues.informationMeteo.vitesseVent;
            this.selectedPrelevement.informationsMeteo.directionVent = formValues.informationMeteo.directionVent;
            this.selectedPrelevement.informationsMeteo.temperature = formValues.informationMeteo.temperature;
            this.selectedPrelevement.informationsMeteo.hygrometrie = formValues.informationMeteo.hygrometrie;
            this.selectedPrelevement.informationsMeteo.conditionMeteo = formValues.informationMeteo.conditionMeteo;
            this.selectedPrelevement.informationsMeteo.pressionAtmospherique = formValues.informationMeteo.pressionAtmospherique;

            this.formChange.emit(this.contenuDiagnostic);
        });
    }

    /**
     * @description Calcul la durée en heures entre le début et la fin du prélèvement
     */
    calculDuree() {
        if (this.formPose == undefined) {
            return '';
        }

        if (this.formPose.get('dateDebutPrelevement').value != '' && this.formPose.get('dateFinPrelevement').value != '') {
            return (
                Math.round(
                    moment
                        .duration(moment(this.formPose.get('dateFinPrelevement').value).diff(moment(this.formPose.get('dateDebutPrelevement').value)))
                        .asHours()
                ) + 'h'
            );
        }
        return '';
    }

    onChangePreloReglementaire() {
        if (this.formPose != undefined) {
            this.justificatifNonReglementaireRequired = !this.formPose.get('prelevementReglementaire').value;
        }
    }
}
