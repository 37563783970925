import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestionContactsComponent } from './gestion-contacts.component';
import { TableauContactsComponent } from './tableau-contacts/tableau-contacts.component';
import { CreationContactComponent } from './creation-contact/creation-contact.component';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LibIconModule } from 'src/app/commons-lib';
import { CnDiagSharedModule } from '../../shared/shared.module';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [GestionContactsComponent, TableauContactsComponent, CreationContactComponent],
    imports: [
        CommonModule,
        MatCardModule,
        MatFormFieldModule,
        MatIconModule,
        MatTableModule,
        MatInputModule,
        MatSortModule,
        MatButtonModule,
        MatMenuModule,
        MatPaginatorModule,
        MatToolbarModule,
        LibIconModule,
        CnDiagSharedModule,
        FormsModule,
    ],
})
export class GestionContactsModule {}
