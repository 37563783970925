<mat-card
    class="p-0 w-100 d-flex justify-content-between verification-card"
    [class.warning]="!isValidated"
    [class.validated]="isValidated"
>
    <mat-card-content class="d-flex justify-content-between h-100 w-100">
        <p><ng-content></ng-content></p>
        <div class="action-container d-flex">
            <button *ngIf="!isInEditMode" mat-button class="bt-cancel" (click)="edit()">
                <lib-icon>edit</lib-icon>
                {{ 'Modifier' | uppercase }}
            </button>
            <button
                *ngIf="!isInEditMode && showValidateButton"
                mat-button
                class="bt-valid"
                (click)="validate()"
                [disabled]="isValidated || form?.invalid"
                [class.disabled]="isValidated || form?.invalid"
            >
                <lib-icon>save</lib-icon>
                {{ 'Valider' | uppercase }}
            </button>

            <button *ngIf="isInEditMode" mat-button class="bt-cancel" (click)="cancel()">
                <lib-icon>cancel</lib-icon>
                {{ 'Annuler' | uppercase }}
            </button>
            <button
                *ngIf="isInEditMode"
                mat-button
                class="bt-valid"
                [disabled]="form?.invalid"
                [class.disabled]="form?.invalid"
                (click)="save()"
            >
                <lib-icon>save</lib-icon>
                {{ 'Enregistrer' | uppercase }}
            </button>
        </div>
    </mat-card-content>
</mat-card>
