import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/commons-lib';
import { map, takeUntil } from 'rxjs/operators';
import { TypeOuvrage } from 'src/app/model/type-ouvrage.model';
import { ReferenceService } from 'src/app/services/reference.service';
import { CnSpinnerService } from '../cn-spinner/service/cn-spinner.service';

class OuvrageModalData {
    constructor(
        // Correspond à l'ouvrage parent, s'il existe, on ne va proposer que les parties d'ouvrages de cet ouvrage parent
        public ouvrageParent: TypeOuvrage,
        // Correspond à l'ouvrage pre-sélectionné s'il existe
        public ouvrageExistant: TypeOuvrage
    ) {}
}

@Component({
    selector: 'app-ouvrage-modal',
    templateUrl: './ouvrage-modal.component.html',
    styleUrls: ['./ouvrage-modal.component.scss'],
})
export class OuvrageModalComponent extends BaseComponent implements OnInit {
    listeTypeOuvrage: TypeOuvrage[] = [];
    ouvrageSelected: TypeOuvrage;

    // Utilisé pour la création des lignes d'ouvrage
    array = Array;
    math = Math;

    constructor(
        private readonly dialogRef: MatDialogRef<OuvrageModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: OuvrageModalData,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly referenceService: ReferenceService
    ) {
        super();
    }

    ngOnInit(): void {
        // à revoir avec la nouvelle mécanique des typesOuvrage
        this.dialogRef.addPanelClass(['minwidth1000-dialog']);
        this.cnSpinnerService
            .withSpinner(
                this.referenceService.findAllTypesOuvrage().pipe(
                    takeUntil(this.ngUnsubscribe),
                    map((typesOuvrage) => {
                        return typesOuvrage.filter((typeOuvrage: TypeOuvrage) => {
                            if (this.data.ouvrageParent) {
                                return typeOuvrage.parentsIds.includes(this.data.ouvrageParent.id);
                            } else {
                                return typeOuvrage.parentsIds && typeOuvrage.parentsIds.length === 0;
                            }
                        });
                    })
                )
            )
            .subscribe((typesOuvrage: TypeOuvrage[]) => {
                this.listeTypeOuvrage = typesOuvrage;
                this.ouvrageSelected = this.data.ouvrageExistant;
            });
    }

    onClickCard(typeOuvrageSelected: TypeOuvrage) {
        this.ouvrageSelected = typeOuvrageSelected;
    }

    /**
     * Evenement click sur le bouton valider
     */
    onClickValider() {
        if (this.ouvrageSelected) {
            this.dialogRef.close({ ouvrage: this.ouvrageSelected });
        }
    }

    /**
     * Evenement click sur le bouton Annuler ou la fermeture de la modale
     */
    onClickCancel() {
        this.dialogRef.close();
    }
}
