import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageConfiguration, PlanParam, SvgParameter } from '@acenv/cnmap-angular-editor-lib';
import { MatSelectChange } from '@angular/material/select';

// TODO: A déplacer dans un endroit plus approprié
export interface ResolutionOptionChoice {
    label: string;
    value: number;
}

@Component({
    selector: 'app-export-print-param',
    templateUrl: './export-print-param.component.html',
    styleUrls: ['./export-print-param.component.scss'],
})
export class ExportPrintParamComponent implements OnInit {
    // /** Page courante */
    // @Input() set page(value: Page) {
    //   this._page = value;
    //   if (this._page) {
    //     this.svgParameter = this._page.svgParam;
    //   }
    // }
    //
    // get page(): Page {
    //   return this._page;
    // }

    /** Configuration de page */
    @Input() pageConfiguration: PageConfiguration;

    /** Paramètres du plan 2D */
    @Input() svgParameter: SvgParameter;

    /** Échelle modifiée */
    @Output() scaleChange = new EventEmitter<void>();

    /** Résolution modifiée */
    @Output() resolutionChange = new EventEmitter<void>();

    /** Fixer l'échelle modifié */
    @Output() fixedScaleChange = new EventEmitter<void>();

    /** Fixer la position du plan modifié */
    @Output() fixedPositionChange = new EventEmitter<void>();

    /** Centrer la carte */
    @Output() centerViewChange = new EventEmitter<void>();

    /** Configuration de page modifiée */
    @Output() pageConfigurationChange = new EventEmitter<void>();

    /** Paramètre du plan modifié */
    @Output() planParamChange = new EventEmitter<PlanParam>();

    // selectedSize: PageSize;
    // selectedOrientation: PageOrientation;

    selectedMargin: number;

    readonly resolutions: ResolutionOptionChoice[] = [
        { label: 'Petit', value: 200 },
        { label: 'Moyen', value: 400 },
        { label: 'Grand', value: 600 },
    ];

    // readonly pageSizes: SelectItem<PageSize>[];
    // readonly orientations: SelectItem<PageOrientation>[];
    //
    // private _page: Page;

    constructor() {
        // this.pageSizes = [
        //   {label: 'A4', value: 'A4'},
        //   {label: 'A3', value: 'A3'}
        // ];
        // this.orientations = [
        //   {label: 'Portrait', value: 'portrait'},
        //   {label: 'Paysage', value: 'landscape'}
        // ];
        this.svgParameter = new SvgParameter();
    }

    ngOnInit() {
        if (this.pageConfiguration) {
            // this.selectedSize = this.pageConfiguration.format.pageSize;
            // this.selectedOrientation = this.pageConfiguration.orientation;
            this.selectedMargin = this.pageConfiguration.margin;
        }
    }

    onSizeChange(event: MatSelectChange) {
        if (event) {
            // this.pageConfiguration.format = pageFormats[event.value];
            this.pageConfiguration.updateDimensions();
            this.pageConfigurationChange.emit();
        }
    }

    onOrientationChange(event: MatSelectChange) {
        if (event) {
            this.pageConfiguration.orientation = event.value;
            this.pageConfiguration.updateDimensions();
            this.pageConfigurationChange.emit();
        }
    }

    onMarginChange() {
        if (this.selectedMargin != null && !isNaN(this.selectedMargin)) {
            this.pageConfiguration.margin = this.selectedMargin;
            this.pageConfigurationChange.emit();
        }
    }

    onResolutionChange() {
        this.resolutionChange.emit();
    }

    onScaleChange() {
        this.scaleChange.emit();
    }

    onFixedScaleChange(event: any) {
        this.svgParameter.fixedScale = event.checked;
        // this.updatePageSvgParam();
        this.fixedScaleChange.emit();
    }

    onFixedPositionChange(event: any) {
        this.svgParameter.fixedPosition = event.checked;
        // this.updatePageSvgParam();
        this.fixedPositionChange.emit();
    }

    onCenterViewChange(event: any) {
        this.centerViewChange.emit();
    }

    onPlanParamChange(event: PlanParam) {
        this.svgParameter.svgParamValues[event.code] = event.value;
        // this.updatePageSvgParam();
        this.planParamChange.emit(event);
    }

    // private updatePageSvgParam() {
    //   if (this._page) {
    //     this._page.svgParam = this.svgParameter;
    //   }
    // }
}
