import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CalendarDateFormatter, CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { combineLatest, Subject } from 'rxjs';
import { addDays, addHours, endOfDay, endOfMonth, isSameDay, isSameMonth, startOfDay, subDays } from 'date-fns';
import { ActivatedRoute } from '@angular/router';
import { CustomDateFormatter } from '../../../utils/custom-date-formatter.utils';
import { takeUntil } from 'rxjs/operators';
import { StaticJsonService } from '../../../services/static-json.service';
import { BaseComponent } from 'src/app/commons-lib';
import { Intervention } from '../../../model/intervention.model';
import { InterventionService } from '../../../services/intervention.service';
import { ToDoItem } from '../../../model/to-do.model';
import { URL_TABLEAU_DE_BORD } from 'src/app/shared/constants/url.constants';

interface InterventionEvent extends CalendarEvent {
    intervention?: Intervention;
}

const colors: any = {
    red: {
        primary: '#ad2121',
        secondary: '#FAE3E3',
    },
    blue: {
        primary: '#1e90ff',
        secondary: '#D1E8FF',
    },
    yellow: {
        primary: '#e3bc08',
        secondary: '#FDF1BA',
    },
};

@Component({
    selector: 'app-agenda',
    templateUrl: './agenda.component.html',
    styleUrls: ['./agenda.component.scss'],
    providers: [
        {
            provide: CalendarDateFormatter,
            useClass: CustomDateFormatter,
        },
    ],
})
export class AgendaComponent extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

    URL_TABLEAU_DE_BORD = URL_TABLEAU_DE_BORD;

    showLots = false;
    showTodos = false;

    locale = 'fr-FR';

    view: CalendarView | string = CalendarView.Day;

    CalendarView = CalendarView;

    viewDate: Date = new Date();
    todos: ToDoItem[] = [];
    interventions: Intervention[];

    modalData: {
        action: string;
        event: CalendarEvent;
    };

    actions: CalendarEventAction[] = [
        {
            label: '<i class="fas fa-fw fa-pencil-alt"></i>',
            a11yLabel: 'Edit',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.handleEvent('Edited', event);
            },
        },
        {
            label: '<i class="fas fa-fw fa-trash-alt"></i>',
            a11yLabel: 'Delete',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.events = this.events.filter((iEvent) => iEvent !== event);
                this.handleEvent('Deleted', event);
            },
        },
    ];

    refresh: Subject<any> = new Subject();

    events: InterventionEvent[] = [
        {
            start: subDays(startOfDay(new Date()), 1),
            end: addDays(new Date(), 1),
            title: 'A 3 day event',
            color: colors.red,
            actions: this.actions,
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true,
            },
            draggable: true,
            intervention: null,
        },
        {
            start: startOfDay(new Date()),
            title: 'An event with no end date',
            color: colors.yellow,
            actions: this.actions,
            intervention: null,
        },
        {
            start: subDays(endOfMonth(new Date()), 3),
            end: addDays(endOfMonth(new Date()), 3),
            title: 'A long event that spans 2 months',
            color: colors.blue,
            allDay: true,
            intervention: null,
        },
        {
            start: addHours(startOfDay(new Date()), 12),
            end: new Date(),
            title: 'Intervention 1',
            color: colors.yellow,
            actions: this.actions,
            resizable: {
                beforeStart: true,
                afterEnd: true,
            },
            draggable: true,
            intervention: null,
        },
        {
            start: addHours(startOfDay(new Date()), 14),
            end: addHours(startOfDay(new Date()), 16),
            title: 'Intervention 2',
            color: colors.yellow,
            actions: this.actions,
            resizable: {
                beforeStart: true,
                afterEnd: true,
            },
            draggable: true,
            intervention: null,
        },
    ];

    activeDayIsOpen = false;

    constructor(private interventionService: InterventionService, private route: ActivatedRoute, private staticJsonService: StaticJsonService) {
        super();
    }

    ngOnInit(): void {
        combineLatest([this.interventionService.findAllForCurrentUserForAgenda(), this.route.data, this.staticJsonService.getJson('todo-list-dev')])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([interventions, routeParam, todos]) => {
                switch (routeParam.mode) {
                    case 'semaine':
                        this.view = CalendarView.Week;
                        break;
                    case 'mois':
                        this.view = CalendarView.Month;
                        break;
                    case 'liste':
                        this.view = 'liste';
                        break;
                    default:
                        this.view = CalendarView.Day;
                        break;
                }
                this.interventions = interventions;
                // TODO FVI pour tests dev
                this.events[3].intervention = this.interventions[0];
                this.events[4].intervention = this.interventions[1];
                this.todos = todos as ToDoItem[];
                // TODO FVI trier les events pour la vue "liste"
                const eventsSorted = this.events.slice().sort((a, b) => a.start.getDate() - b.start.getDate());
            });
    }

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        if (isSameMonth(date, this.viewDate)) {
            this.activeDayIsOpen = !((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0);
            this.viewDate = date;
        }
    }

    eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
        this.events = this.events.map((iEvent) => {
            if (iEvent === event) {
                return {
                    ...event,
                    start: newStart,
                    end: newEnd,
                };
            }
            return iEvent;
        });
        this.handleEvent('Dropped or resized', event);
    }

    handleEvent(action: string, event: CalendarEvent): void {
        this.modalData = { event, action };
        // this.modal.open(this.modalContent, { size: 'lg' });
    }

    addEvent(): void {
        this.events = [
            ...this.events,
            {
                title: 'New event',
                start: startOfDay(new Date()),
                end: endOfDay(new Date()),
                color: colors.red,
                draggable: true,
                resizable: {
                    beforeStart: true,
                    afterEnd: true,
                },
            },
        ];
    }

    deleteEvent(eventToDelete: CalendarEvent) {
        this.events = this.events.filter((event) => event !== eventToDelete);
    }

    setView(view) {
        this.view = view;
    }

    closeOpenMonthViewDay() {
        this.activeDayIsOpen = false;
    }

    afficherLots() {
        this.showLots = !this.showLots;
        this.showTodos = false;
    }

    afficherTodos() {
        this.showTodos = !this.showTodos;
        this.showLots = false;
    }
}
