import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/commons-lib';
import { URL_GESTION_MATERIAUX_EDIT, URL_REFERENTIELS } from 'src/app/shared/constants/url.constants';

@Component({
    selector: 'app-gestion-materiaux',
    templateUrl: './gestion-materiaux.component.html',
    styleUrls: ['./gestion-materiaux.component.scss'],
})
export class GestionMateriauxComponent extends BaseComponent implements OnInit {
    constructor(private readonly router: Router) {
        super();
    }

    ngOnInit(): void {}

    creerMateriau() {
        this.router.navigate([URL_GESTION_MATERIAUX_EDIT]);
    }

    back() {
        this.router.navigate([URL_REFERENTIELS]);
    }
}
