import { Component, Input } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { ReportagePhotoImageFile } from '../../../../../model/diagnostic.model';

@Component({
    selector: 'app-reportage-photo-edit-preview-list',
    templateUrl: './reportage-photo-edit-preview-list.component.html',
    styleUrls: ['./reportage-photo-edit-preview-list.component.scss'],
})
export class ReportagePhotoEditPreviewListComponent extends BaseComponent {
    @Input() imagesFilesSelected: ReportagePhotoImageFile[] = [];
    @Input() title: string;

    // Utilisé pour la création du nombre de page souhaité
    array = Array;
    math = Math;

    constructor() {
        super();
    }
}
