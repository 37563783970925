import { CacheSync, DataWithMeta, OfflineCache, OfflineCacheWrapper, OfflineRequest, SimpleCacheWrapper } from './offline-storage';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

export class ConditionalOfflineRequest<T, ID> implements OfflineRequest<T, ID> {
    constructor(
        private readonly condition$: Observable<boolean>,
        private readonly delegateTrue: OfflineRequest<T, ID>,
        private readonly delegateFalse: OfflineRequest<T, ID>
    ) {}
    request(query?: T): Observable<ID> {
        return this.condition$.pipe(
            take(1),
            switchMap((condition) => {
                if (condition) {
                    return this.delegateTrue.request(query);
                } else {
                    return this.delegateFalse.request(query);
                }
            })
        );
    }
}

export class NoOfflineCacheWrapper<T, ID> implements OfflineCacheWrapper<T, ID>, CacheSync<T> {
    get<Q>(onlineRequest: (query?: Q) => Observable<T[]>, offlineRequest?: (query: Q, store: OfflineCache<T, ID>) => Observable<DataWithMeta<T>[]>) {
        return {
            request: (query: Q) => onlineRequest(query),
        } as OfflineRequest<Q, T[]>;
    }
    delete<R>(deleteRequest: (data?: T) => Observable<R>) {
        return {
            request: (data?: T) => deleteRequest(data).pipe(map(() => true)),
        };
    }

    getOne<Q>(
        onlineRequest: (query?: ID) => Observable<T>,
        offlineRequest: (query: ID, store: OfflineCache<T, ID>) => Observable<DataWithMeta<T>>
    ): OfflineRequest<ID, T> {
        return { request: (query) => onlineRequest(query) };
    }

    save(saveRequest: (data?: T) => Observable<T>) {
        return { request: (data?: T) => saveRequest(data) };
    }

    pull(pullAction: () => Observable<T[]>) {
        return of([]);
    }

    push(create: (data?: T) => Observable<T>, modify: (data?: T) => Observable<T>, deleteRequest: (data?: T) => Observable<T>) {
        return { request: () => of() };
    }
}

export class ConditionalOfflineResourceWrapper<T, ID> implements OfflineCacheWrapper<T, ID>, CacheSync<T> {
    constructor(
        private readonly condition$: Observable<boolean>,
        private readonly delegateTrue: OfflineCacheWrapper<T, ID> & CacheSync<T>,
        private readonly delegateFalse: OfflineCacheWrapper<T, ID> & CacheSync<T>
    ) {}

    get<Q>(onlineRequest: (query?: Q) => Observable<T[]>, offlineRequest?: (query: Q, store: OfflineCache<T, ID>) => Observable<DataWithMeta<T>[]>) {
        return new ConditionalOfflineRequest(
            this.condition$,
            this.delegateTrue.get(onlineRequest, offlineRequest),
            this.delegateFalse.get(onlineRequest, offlineRequest)
        );
    }

    delete(deleteRequest: (data?: T) => Observable<boolean>) {
        return new ConditionalOfflineRequest(this.condition$, this.delegateTrue.delete(deleteRequest), this.delegateFalse.delete(deleteRequest));
    }

    getOne(onlineRequest: (query?: ID) => Observable<T>, offlineRequest?: (query: ID, store: OfflineCache<T, ID>) => Observable<DataWithMeta<T>>) {
        return new ConditionalOfflineRequest(
            this.condition$,
            this.delegateTrue.getOne(onlineRequest, offlineRequest),
            this.delegateFalse.getOne(onlineRequest, offlineRequest)
        );
    }

    save(saveRequest: (data?: T) => Observable<T>) {
        return new ConditionalOfflineRequest(this.condition$, this.delegateTrue.save(saveRequest), this.delegateFalse.save(saveRequest));
    }

    push(create: (data?: T) => Observable<T>, modify: (data?: T) => Observable<T>, deleteRequest: (data?: T) => Observable<T>) {
        return new ConditionalOfflineRequest(
            this.condition$,
            this.delegateTrue.push(create, modify, deleteRequest),
            this.delegateFalse.push(create, modify, deleteRequest)
        );
    }
    pull(pullAction: () => Observable<T[]>): Observable<DataWithMeta<T>[]> {
        return this.condition$.pipe(
            switchMap((condition) => {
                if (condition) {
                    return this.delegateTrue.pull(pullAction);
                } else {
                    return this.delegateFalse.pull(pullAction);
                }
            })
        );
    }
}

export function wrapConditional<T, ID>(condition$: Observable<boolean>, cache: OfflineCache<T, ID>) {
    const delegateTrue = new SimpleCacheWrapper<T, ID>(cache);
    const delegateFalse = new NoOfflineCacheWrapper<T, ID>();
    return new ConditionalOfflineResourceWrapper<T, ID>(condition$, delegateTrue, delegateFalse);
}
