import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { DiagnosticService } from '../../../../services/diagnostic.service';
import { BaseComponent, NotificationService } from 'src/app/commons-lib';
import { CnSpinnerService } from '../../../shared/cn-spinner/service/cn-spinner.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject } from 'rxjs';
import { DATE_FORMAT_FRANCAIS_HH_MM } from '../../../../shared/constants/cndiag.constants';
import { PageRequest, PaginatedDataSource } from '../../../../shared/paging/page';
import { SessionStoragePaginationUtils } from '../../../../utils/session-storage-pagination.utils';
import { INITIAL_PAGE_SIZE, SESSION_STORAGE_KEY_SYNTHESES } from 'src/app/shared/constants/admin.constants';
import { Synthese } from '../../../../model/synthese';
import { ExportSyntheseService } from '../../../../services/export-synthese.service';
import { RapportDownloadModalComponent } from '../../../shared/rapport-download-modal/rapport-download-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { FileService } from '../../../../services/file.service';

export const DEFAULT_SORT = { direction: 'asc', active: 'dateGeneration' };

@Component({
    selector: 'app-tableau-syntheses',
    templateUrl: './tableau-syntheses.component.html',
    styleUrls: ['./tableau-syntheses.component.scss'],
})
export class TableauSynthesesComponent extends BaseComponent implements OnInit {
    @Input()
    initialPageSize = INITIAL_PAGE_SIZE;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    readonly DATE_FORMAT_FRANCAIS_HH_MM = DATE_FORMAT_FRANCAIS_HH_MM;

    displayedColumns = ['dateGeneration', 'numerosCommandes', 'typesPrestations', 'nombrePrestations', 'adresseBien', 'actions'];

    dataSource: PaginatedDataSource<Synthese>;
    sort: Sort;
    private search = new BehaviorSubject('');

    constructor(
        private readonly exportSyntheseService: ExportSyntheseService,
        private readonly diagnosticService: DiagnosticService,
        private readonly notificationService: NotificationService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly router: Router,
        private readonly matDialog: MatDialog,
        private readonly fileService: FileService
    ) {
        super();
        this.sort = SessionStoragePaginationUtils.getInitialSort(SESSION_STORAGE_KEY_SYNTHESES, DEFAULT_SORT);
    }

    ngOnInit(): void {
        this.dataSource = new PaginatedDataSource<Synthese>(
            (pageRequest) => {
                return this.cnSpinnerService.withSpinner(this.getSyntheses(this.search.getValue(), pageRequest));
            },
            SessionStoragePaginationUtils.getInitialSort(SESSION_STORAGE_KEY_SYNTHESES, DEFAULT_SORT),
            SessionStoragePaginationUtils.getInitialPageSize(SESSION_STORAGE_KEY_SYNTHESES, this.initialPageSize)
        );
    }

    /**
     * Récupère les synthèses à afficher dans le tableau
     * @param search
     * @param pageRequest
     */
    private getSyntheses(search: string, pageRequest: PageRequest<Synthese>) {
        return this.exportSyntheseService.findAllSyntheses(search, pageRequest).pipe(takeUntil(this.ngUnsubscribe));
    }

    export(synthese: Synthese) {
        return this.exportSyntheseService
            .findOneRapportSynthese(synthese.idRapport)
            .pipe(
                switchMap((rapport) => {
                    return this.matDialog
                        .open(RapportDownloadModalComponent, {
                            data: {
                                displayTypePrestation: false,
                                listeRapportByIdDiagnostic: [rapport],
                            },
                        })
                        .afterClosed();
                }),
                tap((result: { versionRapportSelected: { url: string; name: string } }) => {
                    if (result && result.versionRapportSelected) {
                        this.fileService.createElementToDownload(result.versionRapportSelected.url, result.versionRapportSelected.name);
                    }
                })
            )
            .subscribe();
    }

    /**
     * Action déclenchée lors de la saisie d'un caractère dans la barre de recherche
     * @param $event
     */
    onKeyupSearchSyntheses($event: any) {
        this.search.next($event.target.value);
        this.setSessionStorageItem();
    }

    /**
     * Action déclenchée lors d'un tri dans le tableau
     * @param $event
     */
    sortSyntheses($event: Sort) {
        this.sort = $event;
        this.dataSource.sortBy($event);
        this.setSessionStorageItem();
    }

    /**
     * Set les valeurs du tableau dans le session storage (pagination, recherche, tri ..)
     * @private
     */
    private setSessionStorageItem() {
        SessionStoragePaginationUtils.setSessionStorageItem(SESSION_STORAGE_KEY_SYNTHESES, this.dataSource.size, this.search.getValue(), this.sort);
    }
}
