<div class="markdown-content pb-5">
    <div class="block-title p-2 pl-5 my-3 d-inline-block">{{ contexte.titre }}</div>

    <div class="px-5 mt-2 block-markdown text-justify">
        <span class="subtittle">DEFINITION DES ZONES DE TRAVAUX</span>
        <table class="tab">
            <thead>
                <tr>
                    <th>Nom de la zone de travail / Localisation</th>
                    <th>Surfaces</th>
                    <th>Nb de pièces unitaires</th>
                    <th>Confinement</th>
                    <th>Sas personnel</th>
                    <th>Sas matériel</th>
                    <th>Zone de récupération</th>
                    <th>Zone de stockage air</th>
                    <th>Taux renouvellement</th>
                    <th>Activités connexes</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let zone of contexte.zones">
                    <th>{{ zone.nomZone }}</th>
                    <td>{{ zone.surfaces.superficie }}m²</td>
                    <td>{{ zone.surfaces.pieceUnitaire }}</td>
                    <td>{{ zone.confinement }}</td>
                    <td>{{ zone.sasPersonnel }}</td>
                    <td>{{ zone.sasMateriel }}</td>
                    <td>{{ zone.zoneRecuperation }}</td>
                    <td>{{ zone.zoneStockage }}</td>
                    <td class="miss">Non défini</td>
                    <td>{{ zone.activiteAdjacente }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <br />
    <div class="px-5 mt-2 block-markdown text-justify">
        <span class="subtittle">PROCESSUS MIS EN ŒUVRE - MATERIAU(X) ET TECHNIQUE(S) DE DÉPOSE(S)</span>
        <table class="tab">
            <thead>
                <tr>
                    <th>Nom de la zone de travail / Localisation</th>
                    <th>MPCA</th>
                    <th>Technique de travail</th>
                    <th>Protection collectives / Captage à la source</th>
                    <th>Travail à l'humide</th>
                    <th>Type de masque</th>
                    <th>Tâche(s)</th>
                    <th>Informations complementaires</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let zone of contexte.zones">
                    <tr>
                        <th [attr.rowspan]="getRowTravaux(zone.listeMpca)">{{ zone.nomZone }}</th>
                    </tr>
                    <ng-container *ngFor="let mpca of zone.listeMpca; let i = index">
                        <tr>
                            <th [attr.rowspan]="mpca.processus.length + 1">
                                MPCA {{ i + 1 }} : <em>({{ mpca.general.materiauxClient }})</em>
                                {{ mpca.general.materiaux }} - {{ mpca.general.etatDegradation }}
                                <span *ngFor="let amiante of mpca.general.typeAmiante"> - {{ amiante }} </span>
                            </th>
                        </tr>
                        <ng-container *ngFor="let processus of mpca.processus; let i = index">
                            <tr>
                                <td>{{ processus.metop.technique }}</td>
                                <td>{{ processus.metop.aspiration }}</td>
                                <td>{{ processus.metop.travailHumide }}</td>
                                <td>{{ processus.pendantTravaux.typeMasqueDesamianteur }}</td>
                                <td>
                                    <ul>
                                        <li *ngFor="let tache of processus.taches">
                                            {{ tache.tache }}
                                        </li>
                                    </ul>
                                </td>
                                <td class="miss">Non défini</td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </tbody>
        </table>
    </div>
    <br />
    <div class="px-5 mt-2 block-markdown text-justify">
        <span class="subtittle">PROTOCOLE D’ECHANTILLONNAGE</span>
        <table class="tab">
            <thead>
                <tr>
                    <th>Nom de la zone de travail / Localisation</th>
                    <th>Type de prélèvements</th>
                    <th>Méthode</th>
                    <th>Quantité</th>
                    <th>Fréquence</th>
                    <th>Durée</th>
                    <th>SA attendue</th>
                    <th>Prélèvement réglementaire</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let zone of contexte.zones">
                    <tr>
                        <th [attr.rowspan]="zone.refs.length + 1">{{ zone.nomZone }}</th>
                    </tr>

                    <tr *ngFor="let prelevement of zone.refs; let i = index">
                        <td>{{ prelevement.ref.reference }} - {{ prelevement.ref.typePrelevement }}</td>
                        <td>{{ prelevement.ref.norme }}</td>
                        <td>{{ prelevement.quantite }}</td>
                        <td>{{ prelevement.ref.frequence }}</td>
                        <td>{{ prelevement.ref.dureeMax }}</td>
                        <td>{{ prelevement.ref.sensibiliteAnalytiqueAttendue }}</td>
                        <td *ngIf="prelevement.quantite == 1; else elseBlock">Non réglementaire</td>
                        <ng-template #elseBlock>Réglementaire</ng-template>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <br />
    <div class="px-5 mt-2 block-markdown text-justify">
        <span class="miss">INSERTION DU PLAN / CROQUIS</span>
    </div>
</div>
