/**
 * Adresse postale.
 */
export class Adresse {
    /**
     * Numéro et nom de la voie
     */
    voie?: string;

    /**
     * Complément d'adresse 1
     */
    complementAdresse1?: string;

    /**
     * Complément d'adresse 2
     */
    complementAdresse2?: string;

    /**
     * Code postal
     */
    codePostal?: string;

    /**
     * Ville
     */
    ville?: string;

    constructor() {}
}
