import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckpointSidebarComponent } from './checkpoint-sidebar/checkpoint-sidebar.component';
import { DiagnosticEquipmentSectionComponent } from './diagnostic-equipment-section/diagnostic-equipment-section.component';
import { CnDiagSharedModule } from '../shared/shared.module';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { LibIconModule, LibIncrementInputModule } from 'src/app/commons-lib';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSelectFilterModule } from 'mat-select-filter';
import { RecapitulatifCommentairesComponent } from './recapitulatif-commentaires/recapitulatif-commentaires.component';
import { MatInputModule } from '@angular/material/input';
import { PipesModule } from '../../pipes/pipes.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialogModule } from '@angular/material/dialog';
import { DiagnosticConfigComponent } from './diagnostic-config/diagnostic-config.component';
import { DocumentsRapportComponent } from './documents-rapport/documents-rapport.component';
import { RouterModule } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { ReportagePhotoEditComponent } from './reportage-photo/reportage-photo-edit/reportage-photo-edit.component';
import { ReportagePhotoEditSelectionListComponent } from './reportage-photo/reportage-photo-edit/reportage-photo-edit-selection-list/reportage-photo-edit-selection-list.component';
import { ReportagePhotoEditPreviewListComponent } from './reportage-photo/reportage-photo-edit/reportage-photo-edit-preview-list/reportage-photo-edit-preview-list.component';
import { ReportagePhotoEditSelectionImageComponent } from './reportage-photo/reportage-photo-edit/reportage-photo-edit-selection-list/reportage-photo-edit-selection-image/reportage-photo-edit-selection-image.component';
import { ReportagePhotoListComponent } from './reportage-photo/reportage-photo-list/reportage-photo-list.component';
import { ReportagePhotoEditPreviewPageComponent } from './reportage-photo/reportage-photo-edit/reportage-photo-edit-preview-list/reportage-photo-edit-preview-page/reportage-photo-edit-preview-page.component';
import { RecommandationsComponent } from './comments/recommandations/recommandations.component';
import { ConstatationsDiversesComponent } from './comments/constatations-diverses/constatations-diverses.component';
import { RapportComponent } from './rapport/rapport.component';
import { BlockReportComponent } from './rapport/block-report/block-report.component';
import { AdministratifsBlockComponent } from './rapport/blocks/administratifs-block/administratifs-block.component';
import { DocumentsListBlockComponent } from './rapport/blocks/documents-list-block/documents-list-block.component';
import { HeaderBlockComponent } from './rapport/blocks/header-block/header-block.component';
import { MarkdownBlockComponent } from './rapport/blocks/markdown-block/markdown-block.component';
import { ReportagePhotoBlockComponent } from './rapport/blocks/reportage-photo-block/reportage-photo-block.component';
import { ReportContentComponent } from './rapport/report-content/report-content.component';
import { ReportScreenshotsListModalComponent } from './rapport/report-screenshots-list-modal/report-screenshots-list-modal.component';
import { ReportValidationModalComponent } from './rapport/report-validation-modal/report-validation-modal.component';
import { ReportCommentsListModalComponent } from './rapport/report-comments-list-modal/report-comments-list-modal.component';
import { PlansBlockComponent } from './rapport/blocks/plans-block/plans-block.component';
import { AdministratifsBonCommandeBlockComponent } from './rapport/blocks/administratifs-bon-commande-block/administratifs-bon-commande-block.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { DirectiveModule } from 'src/app/directives/directive.module';
import { MarkdownModule } from 'ngx-markdown';
import { GestionPlanComponent } from './gestion-plan/gestion-plan.component';

@NgModule({
    imports: [
        CommonModule,
        MatCardModule,
        MatButtonToggleModule,
        MatSidenavModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        MatExpansionModule,
        LibIncrementInputModule,
        ReactiveFormsModule,
        LibIconModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSelectFilterModule,
        CnDiagSharedModule,
        MatInputModule,
        FormsModule,
        PipesModule,
        DragDropModule,
        MatDialogModule,
        RouterModule,
        MatTableModule,
        MarkdownModule.forRoot(),
        MatDividerModule,
        MatCheckboxModule,
        DirectiveModule,
    ],
    declarations: [
        CheckpointSidebarComponent,
        DiagnosticEquipmentSectionComponent,
        RecapitulatifCommentairesComponent,
        DiagnosticConfigComponent,
        DocumentsRapportComponent,
        ReportagePhotoListComponent,
        ReportagePhotoEditComponent,
        ReportagePhotoEditSelectionListComponent,
        ReportagePhotoEditPreviewListComponent,
        ReportagePhotoEditSelectionImageComponent,
        ReportagePhotoEditPreviewPageComponent,
        RecommandationsComponent,
        ConstatationsDiversesComponent,
        RapportComponent,
        BlockReportComponent,
        ReportContentComponent,
        HeaderBlockComponent,
        AdministratifsBlockComponent,
        MarkdownBlockComponent,
        ReportScreenshotsListModalComponent,
        ReportValidationModalComponent,
        DocumentsListBlockComponent,
        ReportagePhotoBlockComponent,
        ReportCommentsListModalComponent,
        PlansBlockComponent,
        AdministratifsBonCommandeBlockComponent,
        GestionPlanComponent,
    ],
    exports: [
        CheckpointSidebarComponent,
        DiagnosticEquipmentSectionComponent,
        RecapitulatifCommentairesComponent,
        DocumentsRapportComponent,
        ReportagePhotoEditPreviewPageComponent,
        RapportComponent,
        PlansBlockComponent,
        ReportContentComponent,
        AdministratifsBonCommandeBlockComponent,
        GestionPlanComponent,
    ],
    entryComponents: [
        ReportagePhotoEditComponent,
        RapportComponent,
        ReportScreenshotsListModalComponent,
        ReportValidationModalComponent,
        DocumentsListBlockComponent,
        ReportagePhotoBlockComponent,
        ReportCommentsListModalComponent,
        MarkdownBlockComponent,
        HeaderBlockComponent,
        AdministratifsBlockComponent,
        PlansBlockComponent,
        AdministratifsBonCommandeBlockComponent,
    ],
})
export class DiagnosticsSharedModule {}
