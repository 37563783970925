import { Component } from '@angular/core';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { CeeService } from '../../../services/cee.service';
import { CeeConfig, CeeModel, PointControle } from '../../../model/cee.model';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CODE_VIDE } from '../../../shared/cee.constants';
import { RESTE_A_FAIRE } from '../../../../../../shared/constants/names.step.constants';
import { EtatProgressionService } from '../../../../../../services/etat-progression.service';
import { TypePrestation, TypePrestationEnum } from 'src/app/model/type-prestation.model';
import { DocumentsService } from 'src/app/services/documents.service';
import { InterventionService } from 'src/app/services/intervention.service';
import { DocumentData } from 'src/app/model/rapport.model';
import { EtapeDiagnosticGenerique } from 'src/app/model/diagnostic-etape.model';

export interface ISolution {
    name: string;
    pointsDeControle: { libelle: string; path: string }[];
    zones: {
        name: string;
        pointsDeControle: { libelle: string; path: string }[];
    }[];
}
@Component({
    selector: 'app-reste-afaire',
    templateUrl: './reste-a-faire.component.html',
    styleUrls: ['./reste-a-faire.component.scss'],
})
export class ResteAFaireComponent {
    iSolutions: ISolution[] = [];
    infosGeneral: { libelle: string; path: string }[] = [];
    caracteristiques: { libelle: string; path: string }[] = [];
    documentsAno: { libelle: string; path: string }[] = [];
    private _ceeConfig: CeeConfig;
    private _typePrestation: TypePrestation;
    private _isDocDevisTravaux: boolean = false;

    constructor(
        private readonly router: Router,
        private readonly diagnosticService: DiagnosticService,
        private readonly etatProgressionService: EtatProgressionService,
        private readonly ceeService: CeeService,
        private readonly documentsService: DocumentsService,
        private interventionService: InterventionService
    ) {
        combineLatest([this.diagnosticService.getCurrentDiagnostic(), this.ceeService.ceeConfig$, this.interventionService.getCurrentIntervention()])
            .pipe(first())
            .subscribe(([diagnostic, config, intervention]) => {
                this._ceeConfig = config;
                this._typePrestation = diagnostic.typePrestation;
                const ceeModel = this.ceeService.mapToCeeModel(diagnostic.contenuDiagnostic);
                // Documents
                const documents: DocumentData[] = this.documentsService.buildDocumentsData(intervention, diagnostic);
                const propertiesFilesName = [
                    { property: 'dateFacture', fileName: 'Facture travaux' },
                    { property: 'dateEtatRecapitulatif', fileName: 'État récapitulatif' },
                ];
                // Initialisation des dates à partir des documents :
                const pathDocument = this.router.url.split('/config/')[0] + '/config/validation-docs';
                documents.forEach((doc) => {
                    const propertyFileNAme = propertiesFilesName.find((f) => f.fileName === doc.nom);
                    if (propertyFileNAme) {
                        ceeModel[propertyFileNAme.property].valeur = doc.dateDocument;
                        ceeModel[propertyFileNAme.property].commentairesId = doc.commentaires.map((d) => d.id);
                        ceeModel[propertyFileNAme.property].checked = doc.dateDocument ? false : true;
                        ceeModel[propertyFileNAme.property].isAnomalie = doc.dateDocument ? false : true;
                    }
                    // Anomalies documents
                    const isEditable = intervention.documents.find((d) => d.id === doc.id).isEditable;
                    if (isEditable) {
                        if (doc.dateDocument ? false : !doc.commentaires.length) {
                            if (doc.nom === 'Devis travaux' && doc.vueParLeTechnicien) {
                                this._isDocDevisTravaux = true;
                            }

                            this.documentsAno.push({
                                libelle: doc.nom,
                                path: pathDocument,
                            });
                        }
                    }
                });

                this.initRAF(Object.assign(new CeeModel(), ceeModel));
                this.etatProgressionService.updateDiagnostic(RESTE_A_FAIRE, 'VOID', diagnostic);
            });
    }

    private initRAF(ceeModel: CeeModel) {
        // Générale
        const pathGeneral = this.router.url.split('/config/')[0] + '/config/initialisation/general';
        const pathDocument = this.router.url.split('/config/')[0] + '/config/validation-docs';
        for (const property of this.getProperties()) {
            const value = ceeModel[property];
            if ((!value?.valeur && !value?.checked) || (value?.checked && !value?.commentairesId?.length)) {
                this.infosGeneral.push({
                    libelle: ceeModel.getLabel(property),
                    path: property === 'dateFacture' || property === 'dateEtatRecapitulatif' ? pathDocument : pathGeneral,
                });
            }
        }
        // Caractéristiques uniquement BARTH161
        if (TypePrestationEnum.BARTH161 === this._typePrestation) {
            const pathCaracteristiques = this.router.url.split('/config/')[0] + '/config/initialisation/caracteristiques';
            for (const key of Object.getOwnPropertyNames(ceeModel.caracteristiques.data)) {
                const value = ceeModel.caracteristiques.data[key];
                if (value && !value.valeur?.length) {
                    const pointcontrole: PointControle = this._ceeConfig.pointsControles.find((p) => p.idInterne === key);
                    if (pointcontrole) {
                        // Uniquement les points de controle car les commentaires peuvent être sur les chapitres
                        const libelle = pointcontrole.description;
                        this.caracteristiques.push({ libelle, path: pathCaracteristiques });
                    }
                }
            }
        }
        // Point de controle
        for (const solution of ceeModel.solutions.solutions) {
            const sol = solution.infoIsolant.find((val) => this.ceeService.idsNomSolution.includes(val.id));
            const solutionName = sol ? sol.valeur : 'Isolation des réseaux';
            const iSolution: ISolution = {
                name: solutionName,
                pointsDeControle: [],
                zones: [],
            };
            // Reste à faire : Isolant
            let ptcInvalid = solution.infoIsolant.filter((p) => !this.ceeService.isCheckPointValid(p, ceeModel));
            if (this._typePrestation === TypePrestationEnum.BARTH161) {
                let nbPointSingulierReel: number = 0;
                for (const id of ['PC400', 'PC401', 'PC402', 'PC403', 'PC404', 'PC405', 'PC406', 'PC407']) {
                    if (ceeModel?.caracteristiques?.data[id]?.valeur) {
                        nbPointSingulierReel += +ceeModel.caracteristiques.data[id].valeur;
                    }
                }

                const ptsC = solution.infoIsolant.filter((value) => {
                    {
                        if (value.id === 'PC430') {
                            const checkpoint: PointControle = this._ceeConfig.pointsControles.find((pt) => pt.idInterne === value.id);
                            if (value.valeur && nbPointSingulierReel !== +value.valeur && !value.commentairesId?.length) {
                                return true;
                            }
                        }
                        return false;
                    }
                });
                ptcInvalid = [...ptcInvalid, ...ptsC];
                let nbPointSingulier: number = Math.ceil(nbPointSingulierReel / 10);
                if (Object.getOwnPropertyNames(solution.pointsDeControles.data).length !== nbPointSingulier) {
                    if (!solution.infoIsolant.find((value) => value.valeur === 'non' || value.valeur === 'partAccessible')) {
                        iSolution.pointsDeControle.push({
                            libelle: "Le nombre de point de contrôle n'est pas atteint",
                            path: this.getPath(solutionName),
                        });
                    }
                }
            }
            for (const raf of ptcInvalid) {
                const pointcontrole: PointControle = this._ceeConfig.pointsControles.find((p) => p.idInterne === raf.id);
                iSolution.pointsDeControle.push({
                    libelle: pointcontrole.description,
                    path: this.getPath(solutionName),
                });
            }
            // Reste à faire point de controles
            for (const zoneName of Object.getOwnPropertyNames(solution.pointsDeControles.data)) {
                let zone = { name: zoneName, pointsDeControle: [] };

                if (solution.pointsDeControles.data[zoneName]) {
                    const pointsControle = solution.pointsDeControles.data[zoneName].filter(
                        (p) => !this.ceeService.isCheckPointValid(p, ceeModel) || this.checkPTCFromCaracteristique(ceeModel, p)
                    );
                    for (const raf of pointsControle) {
                        const pointcontrole: PointControle = this._ceeConfig.pointsControles.find((p) => p.idInterne === raf.id);
                        const libelle = pointcontrole.description;
                        const path = this.getPath(solutionName, zoneName, raf.id);
                        zone.pointsDeControle.push({ libelle, path });
                    }

                    let nomZone = zoneName;
                    let numeroZone = ' ';
                    for (const ptc of solution.pointsDeControles.data[zoneName]) {
                        if (ptc.valeur && ptc.valeur !== CODE_VIDE && ptc.valeur !== null) {
                            if (ptc.id === 'PC306') {
                                nomZone = ptc.valeur;
                            }
                            if (ptc.id === 'PC416') {
                                numeroZone += ptc.valeur;
                            }
                            if (ptc.id === 'PC417') {
                                nomZone = ptc.valeur;
                            }
                        }
                    }
                    zone.name = nomZone + numeroZone;
                    if (zone.pointsDeControle.length) {
                        iSolution.zones.push(zone);
                    }
                }
            }

            if (iSolution.zones.length || iSolution.pointsDeControle.length) {
                this.iSolutions.push(iSolution);
            }
        }
    }

    /**
     * Return TRUE si la valeur saisie dans l'onglet caract.
     * différente de celle saisie dans les points de controle
     * @param ptc
     */
    checkPTCFromCaracteristique(pCeeModel: CeeModel, value: EtapeDiagnosticGenerique): boolean {
        const ptc = this._ceeConfig.pointsControles.find((p) => p.idInterne === value.id);
        if (ptc.lstChoixReponse.length && ptc.lstChoixReponse[0]?.idPointControleReponses) {
            const idPTCCible = ptc.lstChoixReponse[0].idPointControleReponses;
            const valeurCible = pCeeModel.caracteristiques?.data[idPTCCible]?.valeur;
            if (valeurCible) {
                if (valeurCible instanceof Array) {
                    if (value.valeur && !valeurCible.includes(value.valeur)) {
                        return value.commentairesId.length ? false : true;
                    }
                } else if (value.valeur && valeurCible !== value.valeur) {
                    return value.commentairesId.length ? false : true;
                }
            }
        }
        return false;
    }

    private getPath(solutionName: string, zoneName?: string, idInterne?: string) {
        const url = this.router.url.split('/config/')[0] + '/config/points-de-controles/controles/';
        const dataToEncode = idInterne ? { solutionName, zoneName, idInterne } : { solutionName };
        const hash: string = btoa(unescape(encodeURIComponent(JSON.stringify(dataToEncode)))).replace(/=/g, '');
        return url + hash;
    }
    private getProperties() {
        const properties = ['nomEntreprise', 'numeroSIRET', 'presenceDevis', 'presenceFacture', 'dateFacture'];
        if (this._isDocDevisTravaux) {
            properties.push('dateAcceptationDevis');
        }
        if (this._typePrestation?.startsWith('BAREN10')) {
            properties.push('dateVisitePrealable');
            properties.push('dateDebutTravaux');
            properties.push('entrepriseRGE');
        } else if (this._typePrestation?.startsWith('BARTH16')) {
            properties.push('refFacture');
            if (this._typePrestation === TypePrestationEnum.BARTH161) {
                properties.push('dateEtatRecapitulatif');
            }
        }
        return properties;
    }
}
