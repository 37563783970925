<div class="row pt-4">
    <h2>Accompagnateur(s)</h2>
</div>
<div class="row">
    <mat-card
        class="col-3 small-card mr-3 mb-3"
        *ngFor="let contact of contacts; let index = index"
        [ngClass]="contact.presence === true ? 'contact-section-ok' : contact.presence === false ? 'contact-section-ko' : 'contact-section-empty'"
    >
        <mat-card-content>
            <!-- Lecture du contact -->
            <button
                mat-icon-button
                class="position-absolute align-self-end button-group primary"
                [disabled]="readonlyMode || !contacts.length"
                (click)="onClickEditContact(index)"
                matTooltip="Éditer le contact"
            >
                <lib-icon class="icon-small">edit</lib-icon>
            </button>
            <div class="px-3">
                <div class="card-title">{{ contact.type }}</div>
                <div class="card-subtitle-bold">
                    {{ contact.nom | uppercase }}
                    {{ contact.estPersonneMorale ? contact.siret : contact.prenom }}
                </div>
                <div class="card-text">
                    <div class="mb-2 d-flex" *ngIf="contact.adresse && contact.adresse.voie">
                        <div class="mr-2">
                            <lib-icon [class]="'primary mt-1'" class="icon-small align-bottom">place </lib-icon>
                        </div>
                        <div>
                            <div class="ws-preline">
                                {{ contact.adresse.voie }}
                            </div>
                            <div *ngIf="contact.adresse.complementAdresse1">
                                {{ contact.adresse.complementAdresse1 }}
                            </div>
                            <div *ngIf="contact.adresse.complementAdresse2">
                                {{ contact.adresse.complementAdresse2 }}
                            </div>
                            <div>{{ contact.adresse.codePostal }} {{ contact.adresse.ville }}</div>
                        </div>
                    </div>
                    <div class="mb-2 d-flex">
                        <div class="mr-2">
                            <lib-icon [class]="'primary mt-1'" class="icon-small align-bottom">local_phone </lib-icon>
                        </div>
                        <div>
                            {{ contact.telephone }}
                        </div>
                    </div>
                    <div class="d-flex" *ngIf="contact.email">
                        <div class="mr-2">
                            <lib-icon [class]="'primary mt-1'" class="icon-small align-bottom">email </lib-icon>
                        </div>
                        <div>{{ contact.email }}</div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="col-3 d-flex small-card action-card mr-3 mb-3" [ngClass]="readonlyMode ? 'disabled' : ''">
        <mat-card-content class="w-100">
            <button class="w-100 h-100" mat-button [disabled]="readonlyMode" (click)="onClickAddContact()">
                <lib-icon>person_add</lib-icon>
                <br />{{ 'Ajouter un contact' }}
            </button>
        </mat-card-content>
    </mat-card>
</div>
