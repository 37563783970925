import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

export class ActionSheetData {
    constructor(public header?: string, public buttons?: { text: string; icon: string; handler: () => any; warn: boolean }[]) {}
}

@Component({
    selector: 'lib-action-sheet',
    templateUrl: './action-sheet.component.html',
    styleUrls: ['./action-sheet.component.scss'],
})
export class ActionSheetComponent implements OnInit {
    constructor(public bottomSheetRef: MatBottomSheetRef<ActionSheetComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: ActionSheetData) {}

    ngOnInit() {}
}
