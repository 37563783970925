<div>
    <div class="block-subtitle ml-4 my-4">Caractéristique du bien</div>
    <div class="block-content px-5 ml-2 mb-4">
        <table>
            <tr>
                <th class="caracteristiques">Caracteristiques</th>
                <th class="etat">Etat</th>
                <th>Précisions</th>
            </tr>

            <tr *ngFor="let data of dataToDisplay">
                <td>
                    {{ data.label }}
                </td>
                <td class="etat {{ data.etat.style }}">
                    {{ data.etat.valeur }}
                </td>
                <td>
                    {{ data.precisions.join(', ') }}
                </td>
            </tr>
        </table>
    </div>
</div>
