import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../authentication/user.service';
import { NotificationService } from '../../notification/notification.service';
import { User } from '../../authentication/user.model';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
    selector: 'lib-inscription-finish',
    templateUrl: './inscription-finish.component.html',
    styleUrls: ['./inscription-finish.component.scss'],
})
export class InscriptionFinishComponent implements OnInit {
    /** Classe CSS sur le conteneur principal */
    @Input() containerClass = '';

    user$: Observable<User>;
    resetKey: string;
    newPassword: string;
    newPasswordConfirm: string;

    constructor(
        private userService: UserService,
        private notificationService: NotificationService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.user$ = this.route.queryParams.pipe(
            switchMap((params) => {
                this.resetKey = params['key'];
                return this.userService.getUserForInscriptionFinish(this.resetKey);
            }),
            map((user) => {
                const userForInscription = new User();
                userForInscription.firstName = user.firstName;
                userForInscription.lastName = user.lastName;
                if (user.company) {
                    userForInscription.company = user.company;
                }
                return userForInscription;
            })
        );
    }

    submit(user: User) {
        this.userService.inscriptionFinish(user, this.resetKey, this.newPassword).subscribe(() => {
            this.router.navigate(['/login']);
            // Au cas où le login soit dans une popup (comme dans CNBIM), on affiche la notification un poil plus tard
            setTimeout(() => {
                this.notificationService.success('Votre inscription a bien été validée. Merci de vous identifier');
            }, 1000);
        });
    }
}
