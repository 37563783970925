<div class="toolbar">
    <mat-toolbar class="border-bottom-1 primary">
        <button mat-button class="px-0" (click)="back()">
            <lib-icon>arrow_back</lib-icon>
        </button>
        <div>
            <lib-icon [class]="'icon-before-text'">badge</lib-icon>
            <span *ngIf="mode === 'CREATE' && !isReadOnly">Création d'un contact</span>
            <span *ngIf="mode === 'EDIT' && !isReadOnly">Edition du contact</span>
            <span *ngIf="mode === 'DUPLICATE' && !isReadOnly">Duplication du contact</span>
            <span *ngIf="isReadOnly">Consultation du contact</span>
        </div>

        <div class="d-flex justify-content-end w-100">
            <button mat-button color="primary" class="mx-1" (click)="back()">
                <mat-icon>close</mat-icon>
                <span>{{ 'Annuler' | uppercase }}</span>
            </button>
            <button
                *ngIf="!isReadOnly"
                mat-raised-button
                color="primary"
                [disabled]="formContact.invalid"
                (click)="validerContact()"
            >
                <lib-icon>check</lib-icon>
                <span>{{ 'Valider' | uppercase }}</span>
            </button>
            <button *ngIf="isReadOnly" mat-raised-button color="primary" (click)="editer()">
                <mat-icon>edit</mat-icon>
                <span>{{ 'Éditer' | uppercase }}</span>
            </button>
        </div>
    </mat-toolbar>
</div>

<div class="equipement-container d-flex justify-content-center" *ngIf="loadingComplete">
    <mat-card class="col-xl-7 p-2 mt-2 d-flex flex-column justify-content-between">
        <mat-card-content>
            <app-edit-contact
                [formContact]="formContact"
                [isReadOnly]="isReadOnly"
                [isProprietaire]="false"
                [isDonneurOrdre]="false"
                [isContactRef]="true"
                (onSelectExistingContact)="onSelectExistingContact($event)"
            ></app-edit-contact>
        </mat-card-content>
    </mat-card>
</div>
