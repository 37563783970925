import { Component } from '@angular/core';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { EtatProgressionService } from '../../../../../../services/etat-progression.service';
import { CONCLUSION_ADMINISTRATIVE } from '../../../shared/cee.constants';
import { CeeService } from '../../../services/cee.service';
import { CeeConfig, CeeModel, IAnomalie } from '../../../model/cee.model';
import { EtapeDiagnosticGenerique } from 'src/app/model/diagnostic-etape.model';
import { DocumentsService } from 'src/app/services/documents.service';
import { DocumentData } from 'src/app/model/rapport.model';
import { InterventionService } from 'src/app/services/intervention.service';
import { ReferenceService } from 'src/app/services/reference.service';
import { CategorieDocument } from 'src/app/model/categorie-document.model';

@Component({
    selector: 'app-controle-administratif',
    templateUrl: './controle-administratif.component.html',
})
export class ControleAdministratifComponent {
    anomalies: IAnomalie[] = [];
    anomaliesDoc: string[] = [];
    constructor(
        private readonly diagnosticService: DiagnosticService,
        private readonly etatProgressionService: EtatProgressionService,
        private readonly ceeService: CeeService,
        private readonly documentsService: DocumentsService,
        private readonly referenceService: ReferenceService,
        private interventionService: InterventionService
    ) {
        combineLatest([this.diagnosticService.getCurrentDiagnostic(), this.ceeService.ceeConfig$, this.interventionService.getCurrentIntervention()])
            .pipe(first())
            .subscribe(([diagnostic, config, intervention]) => {
                this.initAnomalies(config, this.ceeService.mapToCeeModel(diagnostic.contenuDiagnostic));
                this.etatProgressionService.updateDiagnostic(CONCLUSION_ADMINISTRATIVE, 'VOID', diagnostic);
                // DOCUMENTS
                const documents: DocumentData[] = this.documentsService.buildDocumentsData(intervention, diagnostic);
                let categories: CategorieDocument[] = null;
                this.referenceService
                    .findAllCategorieDocuments()
                    .subscribe((c) => (categories = c))
                    .unsubscribe();

                documents.forEach((doc) => {
                    // Anomalies documents
                    const document = intervention.documents.find((d) => d.id === doc.id);
                    const categorie = categories?.find((cat) => cat.id === document.typeDocument.idCategorie);
                    if (categorie?.dateObligatoire === false) {
                        return;
                    }
                    if (!doc.vueParLeTechnicien) {
                        this.anomaliesDoc.push(doc.nom);
                    }
                });
            });
    }

    private initAnomalies(config: CeeConfig, ceeModel: CeeModel) {
        const iAnomalie: IAnomalie = {
            solution: 'Initialisation',
            zones: [],
        };
        const anomalies = { name: 'General', libelleAnomalies: [] };

        const properties = [
            'nomEntreprise',
            'numeroSIRET',
            'entrepriseRGE',
            'refFacture',
            'dateVisitePrealable',
            'dateDebutTravaux',
            'presenceDevis',
            'presenceFacture',
        ];
        for (const property of properties) {
            if (this.getAnomalieFromEtapeGenerale(ceeModel[property])) {
                anomalies.libelleAnomalies.push(ceeModel.getLabel(property));
            }
        }

        if (anomalies.libelleAnomalies.length) {
            iAnomalie.zones.push(anomalies);
        }
        if (iAnomalie.zones.length) {
            iAnomalie.zones = iAnomalie.zones.filter((zone) => zone.libelleAnomalies.length);
            this.anomalies.push(iAnomalie);
        }
    }

    private getAnomalieFromEtapeGenerale(etape: EtapeDiagnosticGenerique): boolean {
        if (etape.isAnomalie || (etape.checked == true && etape.validated == true) || etape.valeur == '3 - non avec déclaration écrite') {
            return true;
        }
        return false;
    }
}
