import { Pipe, PipeTransform } from '@angular/core';
import { Conformite } from '../model/conformite.model';
import { ReferencePrestation } from '../model/reference-prestation.model';
import { TypeDocumentCheckpoint } from '../model/type-document.model';

/**
 * Vérifier si le document est conforme pour une reference de prestation
 */
@Pipe({
    name: 'checkConformiteDocumentByPrestation',
})
export class CheckConformiteDocumentByPrestationPipe implements PipeTransform {
    constructor() {}

    transform(typeDocumentCheckpoints: TypeDocumentCheckpoint[], referencePrestation: ReferencePrestation): boolean {
        if (!typeDocumentCheckpoints || !referencePrestation) {
            return true;
        }
        const typeDocumentCheckpoint = typeDocumentCheckpoints.find(
            (typeDocumentCheckpointTemp) => typeDocumentCheckpointTemp.referencePrestation.id == referencePrestation.id
        );
        if (!typeDocumentCheckpoint) {
            return true;
        }
        return typeDocumentCheckpoint.conformite == Conformite.CONFORME;
    }
}
