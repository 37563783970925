import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LibFileUploaderModule, LibIconModule } from 'src/app/commons-lib';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { PipesModule } from '../../../../pipes/pipes.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CnDiagSharedModule } from '../../../shared/shared.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TreeModule } from '@circlon/angular-tree-component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { GestionCategoriesOuvragesPiecesComponent } from './gestion-categories-ouvrages-pieces.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { CreationCategorieOuvrageComponent } from './creation-categorie-ouvrage/creation-categorie-ouvrage.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GestionTypesOuvrageModule } from '../gestion-types-ouvrage/gestion-types-ouvrage.module';
import { ArbreCategoriesOuvrageComponent } from './arbre-categories-ouvrage/arbre-categories-ouvrage.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EditValeursParametresModalComponent } from './creation-categorie-ouvrage/edit-valeurs-parametres-modal/edit-valeurs-parametres-modal.component';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [
        GestionCategoriesOuvragesPiecesComponent,
        CreationCategorieOuvrageComponent,
        ArbreCategoriesOuvrageComponent,
        EditValeursParametresModalComponent,
    ],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatSelectModule,
        LibIconModule,
        MatButtonModule,
        MatExpansionModule,
        MatIconModule,
        ReactiveFormsModule,
        MatButtonToggleModule,
        PipesModule,
        MatCardModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatFormFieldModule,
        MatInputModule,
        CnDiagSharedModule,
        FormsModule,
        MatMenuModule,
        MatProgressBarModule,
        TreeModule,
        LibFileUploaderModule,
        MatCheckboxModule,
        MatTooltipModule,
        GestionTypesOuvrageModule,
        MatTreeModule,
        MatSlideToggleModule,
        DragDropModule,
        MatListModule,
        MatDialogModule,
    ],
})
export class GestionCategoriesOuvragesPiecesModule {}
