/**
 * Equipement utilisé dans Wizy qui dérive du GenericObject utilisé dans CnMap.
 */
import { GenericObject } from '@acenv/cnmap-angular-editor-lib';
import { EtatWorkflow } from './etat-workflow.model';

export class Equipement extends GenericObject {
    /***
     * Etat de l'équipement' : actif ou inactif, permet de définir la visibilité de l'équipement dans les intervention/diagnostic
     */
    public etatEquipement: EtatWorkflow;
    public sourceType: 'square' | 'circle' | 'wikipim' = 'square';

    constructor() {
        super();
    }

    /** L'id de l'ObjectInstance, c'est à dire de l'équipement positionné dans le plan */
    /** en Mode liste, l'idObjectInstance = null */
    public objectId: string = null;
}

export enum EquipementContactEnum {
    WALL = 'wall',
    CEILING = 'ceiling',
    FLOOR = 'floor',
}
