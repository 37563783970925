<form (ngSubmit)="onSubmit()" [formGroup]="formAgence">
    <div class="toolbar">
        <mat-toolbar class="border-bottom-1 primary">
            <button type="button" mat-button class="px-0" (click)="onClickBack()">
                <lib-icon>arrow_back</lib-icon>
            </button>
            <div>
                <lib-icon [class]="'icon-before-text'">settings</lib-icon>
                <span *ngIf="mode === 'CREATE' && !readonlyMode">Création d'une agence</span>
                <span *ngIf="mode === 'EDIT' && !readonlyMode">Édition d'une agence</span>
                <span *ngIf="readonlyMode">Consultation d'une agence</span>
            </div>

            <div class="d-flex justify-content-end w-100">
                <button type="button" mat-button color="primary" class="mx-1" (click)="onClickBack()">
                    <mat-icon>close</mat-icon>
                    <span>{{ 'Annuler' | uppercase }}</span>
                </button>
                <button
                    *ngIf="!readonlyMode"
                    type="submit"
                    mat-raised-button
                    color="primary"
                    [disabled]="formAgence.invalid"
                >
                    <lib-icon>check</lib-icon>
                    <span>{{ 'Valider' | uppercase }}</span>
                </button>
                <button *ngIf="readonlyMode" type="button" mat-raised-button color="primary" (click)="onClickEdit()">
                    <mat-icon>edit</mat-icon>
                    <span>{{ 'Éditer' | uppercase }}</span>
                </button>
            </div>
        </mat-toolbar>
    </div>

    <div class="agence-container">
        <div class="h-100 row no-gutters p-4 scrollable-content">
            <!-- Colonne gauche-->
            <div class="col-xl-6 pr-2">
                <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between" formGroupName="general">
                    <mat-card-content>
                        <div class="col-xl-12 m-0 my-3 primary">Informations générales</div>

                        <!-- Id SalesForce -->
                        <div class="d-flex align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary">ID Salesforce</div>
                            <div class="col-xl-8 pr-2">
                                <mat-form-field class="w-100">
                                    <input
                                        [readonly]="readonlyMode"
                                        matInput
                                        type="text"
                                        formControlName="idSalesforce"
                                    />
                                    <app-mat-error
                                        [control]="formAgence?.get('general')?.get('idSalesforce')"
                                    ></app-mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <!-- Nom -->
                        <div class="d-flex align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Nom</div>
                            <div class="col-xl-8 pr-2">
                                <mat-form-field class="w-100">
                                    <input [readonly]="readonlyMode" matInput type="text" formControlName="nom" />
                                    <app-mat-error [control]="formAgence?.get('general')?.get('nom')"></app-mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <!-- Agence -->
                        <div class="d-flex align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary">Agence parente</div>
                            <div class="col-xl-8">
                                <mat-form-field class="flex-grow-1 w-100">
                                    <input
                                        formControlName="agenceAppartenance"
                                        [matAutocomplete]="autoAgence"
                                        matInput
                                        [readonly]="readonlyMode"
                                        type="text"
                                    />
                                    <app-mat-error [control]="formAgence?.get('general')?.get('agenceAppartenance')">
                                    </app-mat-error>
                                    <mat-autocomplete #autoAgence="matAutocomplete" [displayWith]="displayFnName">
                                        <mat-option *ngIf="isSearchingAgences | async" class="is-loading">
                                            <mat-spinner diameter="25"></mat-spinner>
                                        </mat-option>
                                        <ng-container *ngIf="!(isSearchingAgences | async)">
                                            <mat-option *ngFor="let option of filteredSearchAgences" [value]="option">
                                                {{ option.nom }}
                                            </mat-option>
                                        </ng-container>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                        </div>

                        <!-- Nom du responsable-->
                        <div class="d-flex align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Nom du responsable</div>
                            <div class="col-xl-8 pr-2">
                                <mat-form-field class="flex-grow-1 w-100">
                                    <input
                                        formControlName="responsableAgence"
                                        [matAutocomplete]="autoResponsable"
                                        [required]="true"
                                        matInput
                                        [readonly]="readonlyMode"
                                        type="text"
                                    />
                                    <app-mat-error [control]="formAgence?.get('general')?.get('responsableAgence')">
                                    </app-mat-error>
                                    <mat-autocomplete
                                        #autoResponsable="matAutocomplete"
                                        [displayWith]="displayFnUser"
                                        (optionSelected)="onClickChangeResponsableFromSelect($event)"
                                    >
                                        <mat-option *ngIf="isSearchingResponsables | async" class="is-loading">
                                            <mat-spinner diameter="25"></mat-spinner>
                                        </mat-option>
                                        <ng-container *ngIf="!(isSearchingResponsables | async)">
                                            <mat-option
                                                *ngFor="let option of filteredSearchResponsables"
                                                [value]="option"
                                            >
                                                {{
                                                    option.firstName
                                                        ? option.lastName + ' ' + option.firstName
                                                        : option.lastName
                                                }}
                                            </mat-option>
                                        </ng-container>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="d-flex align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary">Téléphone</div>
                            <div class="col-xl-8 pr-2">
                                <mat-form-field class="w-100">
                                    <input [readonly]="readonlyMode" matInput type="text" formControlName="telephone" />
                                    <app-mat-error [control]="formAgence.get('general').get('telephone')">
                                    </app-mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="d-flex align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary">Fax</div>
                            <div class="col-xl-8 pr-2">
                                <mat-form-field class="w-100">
                                    <input [readonly]="readonlyMode" matInput type="text" formControlName="fax" />
                                    <app-mat-error [control]="formAgence.get('general').get('fax')"></app-mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="d-flex align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary">Email</div>
                            <div class="col-xl-8 pr-2">
                                <mat-form-field class="w-100">
                                    <input [readonly]="readonlyMode" matInput type="text" formControlName="email" />
                                    <app-mat-error [control]="formAgence.get('general').get('email')"></app-mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>

                <!--Adresse-->
                <mat-card class="p-2 mb-1 d-flex flex-column justify-content-between">
                    <mat-card-content>
                        <div class="d-flex p-2 align-items-center" formGroupName="adresse">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Adresse</div>
                            <div class="col-xl-8 d-flex flex-column pr-2">
                                <mat-form-field class="w-100">
                                    <textarea
                                        [readonly]="readonlyMode"
                                        placeholder="Voie"
                                        matInput
                                        formControlName="voie"
                                    >
                                    </textarea>
                                </mat-form-field>
                                <mat-form-field class="w-100">
                                    <input
                                        [readonly]="readonlyMode"
                                        placeholder="Complément d'adresse 1"
                                        matInput
                                        type="text"
                                        formControlName="complementAdresse1"
                                    />
                                </mat-form-field>
                                <mat-form-field class="w-100">
                                    <input
                                        [readonly]="readonlyMode"
                                        placeholder="Code postal"
                                        matInput
                                        type="text"
                                        formControlName="codePostal"
                                    />
                                </mat-form-field>
                                <mat-form-field class="w-100">
                                    <input
                                        [readonly]="readonlyMode"
                                        placeholder="Ville"
                                        matInput
                                        type="text"
                                        formControlName="ville"
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button
                                *ngIf="!readonlyMode"
                                [disabled]="
                                    !formAgence?.get('general')?.get('agenceAppartenance')?.value ||
                                    formAgence?.get('general')?.get('agenceAppartenance').hasError('agenceDoesNotExist')
                                "
                                mat-raised-button
                                color="primary"
                                type="button"
                                (click)="onClickCopyAdress()"
                            >
                                Copier l'adresse de l'agence parente
                            </button>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <!--Colonne droite-->
            <div class="col-xl-6 pl-2">
                <!-- Prestation associées -->
                <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between">
                    <mat-card-content>
                        <div class="d-flex align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary">Cofracs associés</div>
                            <div class="col-xl-8">
                                <app-custom-select
                                    class="w-100"
                                    formControlName="cofracs"
                                    [readonly]="readonlyMode"
                                    [selectControl]="formAgence?.get('cofracs')"
                                    [matFormFieldClass]="'w-100'"
                                    [pathValueDisplay]="['nom']"
                                    [choices]="cofracs"
                                    [addline]="false"
                                    [multiple]="true"
                                >
                                </app-custom-select>
                                <mat-error *ngIf="formAgence.get('cofracs').hasError('hasOverlap')">
                                    Il ne peut pas y avoir plusieurs cofracs sur un même type de prestation
                                </mat-error>
                                <app-mat-error [control]="formAgence.get('cofracs')"></app-mat-error>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between" formGroupName="legals">
                    <mat-card-content>
                        <div class="col-xl-12 m-0 my-3 primary">Informations légales</div>

                        <!-- Siret -->
                        <div class="d-flex align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary">Siret</div>
                            <div class="col-xl-8 pr-2">
                                <mat-form-field class="w-100">
                                    <input [readonly]="readonlyMode" matInput type="text" formControlName="siret" />
                                    <button mat-button matSuffix *ngIf="!readonlyMode" (click)="initSiretWithSiren()">
                                        Initialiser avec SIREN
                                    </button>
                                    <app-mat-error [control]="formAgence.get('legals').get('siret')"></app-mat-error>
                                    <mat-error
                                        *ngIf="
                                            formAgence.get('legals').get('siret')?.hasError('muststartwith') &&
                                            !formAgence.get('legals').get('siret').hasError('minlength') &&
                                            !formAgence.get('legals').get('siret').hasError('maxlength') &&
                                            formAgence.get('legals').get('siret')?.touched
                                        "
                                    >
                                        {{ formAgence.get('legals').get('siret')?.errors.muststartwith.message }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="d-flex align-items-center">
                            <div class="col-xl-12 pr-2">
                                <mat-slide-toggle
                                    [disabled]="readonlyMode"
                                    [checked]="hasInfosParent"
                                    (change)="onClickButtonAgency($event)"
                                    >Reprendre les informations suivantes de l'agence parente.</mat-slide-toggle
                                >
                            </div>
                        </div>

                        <!-- Siren -->
                        <div class="d-flex align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary">Siren</div>
                            <div class="col-xl-8 pr-2">
                                <mat-form-field class="w-100">
                                    <input
                                        [readonly]="readonlyMode || hasInfosParent"
                                        matInput
                                        type="text"
                                        formControlName="siren"
                                    />
                                    <app-mat-error [control]="formAgence.get('legals').get('siren')"></app-mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <!-- Code APE -->
                        <div class="d-flex align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Code APE</div>
                            <div class="col-xl-8 pr-2">
                                <mat-form-field class="w-100">
                                    <input
                                        [readonly]="readonlyMode || hasInfosParent"
                                        matInput
                                        type="text"
                                        formControlName="codeApe"
                                    />
                                    <app-mat-error [control]="formAgence.get('legals').get('codeApe')"></app-mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <!-- Code NAF -->
                        <div class="d-flex align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Code NAF</div>
                            <div class="col-xl-8 pr-2">
                                <mat-form-field class="w-100">
                                    <input
                                        [readonly]="readonlyMode || hasInfosParent"
                                        matInput
                                        type="text"
                                        formControlName="codeNaf"
                                    />
                                    <app-mat-error [control]="formAgence.get('legals').get('codeNaf')"></app-mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <!-- TVA intracommunautaire-->
                        <div class="d-flex align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary">TVA intracommunautaire</div>
                            <div class="col-xl-8 pr-2">
                                <mat-form-field class="w-100">
                                    <input
                                        [readonly]="readonlyMode || hasInfosParent"
                                        matInput
                                        type="text"
                                        formControlName="tva"
                                    />
                                    <app-mat-error [control]="formAgence.get('legals').get('tva')"></app-mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between">
                    <mat-card-content>
                        <div class="col-xl-12 m-0 my-3 primary">
                            <div class="d-flex justify-content-between">
                                Assurance
                                <button
                                    *ngIf="!readonlyMode"
                                    mat-raised-button
                                    color="primary"
                                    type="button"
                                    (click)="onClickAddAssurance()"
                                >
                                    Ajouter une assurance
                                </button>
                            </div>
                        </div>

                        <app-tableau-assurance
                            class="mt-1"
                            [assurances]="assurances"
                            [readonlyMode]="readonlyMode"
                            (updateAssurances)="updateAssurances($event)"
                        ></app-tableau-assurance>
                    </mat-card-content>
                </mat-card>
                <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between" formGroupName="others">
                    <mat-card-content>
                        <div class="col-xl-12 m-0 my-3 primary">Autres</div>
                        <!-- Attestation sur l'honneur -->
                        <!---------- Template ---------->
                        <app-import-file
                            [documentTitle]="documentTitle"
                            [codeTypeDocument]="codeTypeDocument"
                            [fileFormControl]="attestationHonneurControl"
                            [readOnlyMode]="readonlyMode"
                        ></app-import-file>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</form>
