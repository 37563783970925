<div class="h-100">
    <div class="toolbar">
        <mat-toolbar class="border-bottom-1 primary">
            <button mat-button class="px-0" (click)="back()">
                <lib-icon>arrow_back</lib-icon>
            </button>
            <div>
                <lib-icon [class]="'icon-before-text'">settings</lib-icon>
                <span>Affichage des icones disponible</span>
            </div>
        </mat-toolbar>
    </div>

    <div class="d-flex flex-row w-100 main-container" style="padding: 20px">
        <div *ngFor="let iconName of iconList">
            <lib-icon theme="wizy" iconName="{{ iconName }}" color="BLACK margin"></lib-icon>
            <span>{{ iconName }}</span>
        </div>
    </div>
</div>
