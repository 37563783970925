import { Pipe, PipeTransform } from '@angular/core';
import { Equipement } from '../model/equipement.model';
import { CategorieEquipement } from '../model/categorie-equipement.model';
import { TypePrestation } from '../model/type-prestation.model';

@Pipe({
    name: 'typePrestationForEquipement',
})
export class TypePrestationForEquipementPipe implements PipeTransform {
    transform(value: Equipement, allCategoriesEquipements?: CategorieEquipement[]): TypePrestation[] {
        const codeBim = value.productTypeCodeBim;
        const cat = allCategoriesEquipements?.find((it) => it.codeBim === codeBim);
        return cat?.typesPrestationsAssocie || [];
    }
}
