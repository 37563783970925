<div class="sequence-modal d-flex flex-column h-100" [formGroup]="formDoc">
    <div class="d-flex w-100 align-items-baseline underlined pt-2">
        <h3 class="flex-grow-1 text-center">Ajouter un filtre</h3>
        <button mat-icon-button color="primary" [mat-dialog-close]="">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div class="error" *ngIf="formDoc.status == 'INVALID'">
        {{ formDoc?.errors?.customError?.message }}
    </div>

    <div mat-dialog-content class="mt-4">
        <div class="d-flex">
            <mat-form-field class="ml-3">
                <mat-label>Numéro filtre</mat-label>
                <input matInput formControlName="numeroFiltre" />
            </mat-form-field>
        </div>
        <div class="d-flex">
            <mat-form-field class="ml-3">
                <mat-label>Numéro pompe</mat-label>
                <input matInput formControlName="numeroPompe" />
            </mat-form-field>
        </div>
        <div class="d-flex">
            <mat-form-field class="ml-3">
                <mat-label>Débit initial</mat-label>
                <input matInput formControlName="debitInitial" />
            </mat-form-field>
        </div>
        <div class="d-flex">
            <mat-form-field class="ml-3">
                <mat-label>Test étancheité</mat-label>
                <input matInput formControlName="testEtancheite" />
            </mat-form-field>
        </div>
    </div>

    <div mat-dialog-actions class="justify-content-end">
        <button
            [disabled]="formDoc.status == 'INVALID'"
            mat-raised-button
            color="primary"
            [mat-dialog-close]="{ form: formDoc.value, index: index }"
        >
            <mat-icon>check</mat-icon>
            <span>Confirmer</span>
        </button>
    </div>
</div>
