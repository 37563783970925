<div *ngIf="relationInterventionBien" class="d-flex h-100 w-100">
    <app-description-bien-mode-menu
        class="d-flex flex-grow-1 w-5 sidebar-button border-right"
        [intervention]="intervention"
        [(viewMode)]="viewMode"
        (viewModeChange)="handleViewModeChange()"
    ></app-description-bien-mode-menu>

    <div class="d-flex h-100 w-95 flex-column overflow-hidden">
        <app-description-bien-choix-niveau
            #descriptionBienChoixNiveauComponent
            [readonlyMode]="readonlyMode"
            [intervention]="intervention"
            [currentBien]="relationInterventionBien?.bien"
            [selectedNiveau]="currentNiveau"
            [(currentVolume)]="currentVolume"
            [viewMode]="viewMode"
            [(descriptionDirecte)]="descriptionDirecte"
            (selectedNiveauChange)="handleSelectedNiveauChange($event)"
            (currentVolumeChange)="handleSelectedVolumeChange($event)"
            class="w-100 flex-grow-0"
        ></app-description-bien-choix-niveau>

        <ng-container *ngIf="relationInterventionBien.bien.description.length > 0">
            <div class="d-flex flex-grow-1 overflow-hidden">
                <div class="w-20 d-flex flex-grow-1 overflow-hidden border-right" *ngIf="viewMode === VIEWMODE.LIST">
                    <app-description-bien-gestion-niveau
                        #descriptionBienGestionNiveauComponent
                        [readonlyMode]="readonlyMode"
                        [intervention]="intervention"
                        [currentBien]="relationInterventionBien?.bien"
                        [(currentNiveau)]="currentNiveau"
                        [selectedVolume]="currentVolume"
                        (refreshJustificationsVolumes)="refreshJustificationNonVisite()"
                        (sansDescriptionChange)="handleSansDescriptionChange($event)"
                        (selectedVolumeChange)="handleSelectedVolumeChange($event)"
                        class="w-100 d-flex flex-column flex-grow-1"
                    ></app-description-bien-gestion-niveau>
                </div>

                <div class="d-flex flex-column w-80 flex-grow-1">
                    <ng-container *ngIf="viewMode === VIEWMODE.LIST">
                        <ng-container *ngIf="currentVolume">
                            <app-description-bien-details-piece
                                class="flex-grow-1 overflow-auto"
                                [readonlyMode]="readonlyMode"
                                [currentBien]="relationInterventionBien?.bien"
                                [(currentVolume)]="currentVolume"
                                [currentNiveau]="currentNiveau"
                                [(selectedRefPrestations)]="filtresRefPrestations"
                                (openDialogEquipementOuvrage)="handleOpenDialogEquipementOuvrages($event)"
                                (refreshJustificationsVolumes)="refreshJustificationNonVisite()"
                                (refreshCnBuilding)="refreshCnBuilding()"
                                (clickRetour)="handleClickRetourDetailsPiece()"
                            >
                            </app-description-bien-details-piece>
                        </ng-container>
                        <ng-container *ngIf="!currentVolume">
                            <app-description-bien-liste-volumes
                                class="d-flex flex-column flex-grow-1 overflow-hidden"
                                [readonlyMode]="readonlyMode"
                                [currentBien]="relationInterventionBien?.bien"
                                [currentNiveau]="currentNiveau"
                                [descriptionDirecte]="descriptionDirecte"
                                (currentVolumeChange)="handleCurrentVolumeChanges($event)"
                                (openDialogEquipementOuvrage)="handleOpenDialogEquipementOuvrages($event)"
                            ></app-description-bien-liste-volumes>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="viewMode === VIEWMODE.PLAN">
                        <app-viewer-map
                            class="w-100 h-100"
                            *ngIf="bienBuilding"
                            #viewMap
                            [building]="bienBuilding"
                            [storeyIdToDisplay]="currentNiveau?.storeyId"
                            [currentBien]="relationInterventionBien"
                            [currentNiveau]="currentNiveau"
                            [useDiagTool]="true"
                            [changeToFirstStoryOnInit]="false"
                            [viewerMapConfig]="viewerMapConfig"
                            (toolEvent)="viewMapEvent($event)"
                            [externalSelectedElement]="externalSelectedElement"
                            [externalSelectedVolume]="externalSelectedVolume"
                        >
                        </app-viewer-map>
                        <div class="w-100 d-flex justify-content-center align-items-center p-3">
                            <mat-card
                                *ngIf="!bienBuilding"
                                class="w-100 drop-card-placeholder d-flex justify-content-center align-items-center m-3"
                            >
                                Pas de plan disponible pour ce bien.
                            </mat-card>
                        </div>
                    </ng-container>
                </div>
                <div class="w-20 d-flex flex-grow-1 overflow-hidden border-left" *ngIf="viewMode === VIEWMODE.PLAN">
                    <app-description-bien-gestion-niveau
                        *ngIf="!currentVolume"
                        #descriptionBienGestionNiveauComponent
                        [readonlyMode]="readonlyMode"
                        [intervention]="intervention"
                        [currentBien]="relationInterventionBien?.bien"
                        [(currentNiveau)]="currentNiveau"
                        [selectedVolume]="currentVolume"
                        (refreshJustificationsVolumes)="refreshJustificationNonVisite()"
                        (sansDescriptionChange)="handleSansDescriptionChange($event)"
                        (selectedVolumeChange)="handleSelectedVolumeChange($event)"
                        class="w-100 d-flex flex-column flex-grow-1"
                    ></app-description-bien-gestion-niveau>
                    <app-description-bien-plan-details-piece
                        *ngIf="currentVolume"
                        [readonlyMode]="readonlyMode"
                        [intervention]="intervention"
                        [currentBien]="relationInterventionBien?.bien"
                        [currentVolume]="currentVolume"
                        [currentNiveau]="currentNiveau"
                        (refreshJustificationsVolumes)="refreshJustificationNonVisite()"
                        (clickRetour)="handleClickRetourDetailsPiece()"
                        (refreshCnBuilding)="refreshCnBuilding()"
                        class="w-100 d-flex flex-column flex-grow-1"
                    ></app-description-bien-plan-details-piece>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="relationInterventionBien.bien.description.length === 0">
            <div class="w-90 bg-light p-5 m-5 rounded-lg font-italic">
                Aucun niveau dans ce bien

                <div class="d-flex flex-grow-0 flex-column w-100 p-2 align-items-center">
                    <div class="d-flex justify-content-start align-items-center w-100 py-1">
                        <span class="mr-2">Sans description</span>
                        <mat-slide-toggle
                            [checked]="relationInterventionBien.bien.sansDescription"
                            (change)="handleSansDescriptionChange($event.checked)"
                        ></mat-slide-toggle>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
