import { Injectable, Type } from '@angular/core';
import { DiagnosticHandlerService } from './diagnostic-handler.service';
import {
    AssuranceData,
    BienData,
    CompanyData,
    CompetenceData,
    ContactData,
    InterventionReportData,
    OperateurData,
    RapportsByDiagnostic,
    ReportContent,
} from '../model/rapport.model';
import * as moment from 'moment';
import { Intervention, RelationInterventionBien } from '../model/intervention.model';
import { Contact, RoleContact } from '../model/contact.model';
import { Rule } from '../model/regle.model';
import { Prestation } from '../model/prestation.model';
import { TypePrestation } from '../model/type-prestation.model';
import { Observable } from 'rxjs';
import { Agence, Assurance } from '../model/agence.model';
import { Diagnostic, EtatDiagnostic, ReportData } from '../model/diagnostic.model';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthenticationStore, HttpErrorService, MongoUtils } from 'src/app/commons-lib';
import { ReportApiService } from './report-api.service';
import { Document } from '../model/document.model';
import { AddressUtils } from '../utils/addressUtils';
import {
    DATE_FORMAT_FRANCAIS_HH_MM_MOMENT,
    DATE_FORMAT_FRANCAIS_SANS_HEURE,
    DATE_FORMAT_FRANCAIS_SANS_HEURE_MOMENT,
    URL_MS_REPORT_FILE,
} from '../shared/constants/cndiag.constants';
import { UserInformation } from '../model/user-wizy.model';
import { AdministratifsBlockComponent } from '../modules/diagnostics-shared/rapport/blocks/administratifs-block/administratifs-block.component';
import { DocumentsListBlockComponent } from '../modules/diagnostics-shared/rapport/blocks/documents-list-block/documents-list-block.component';
import { HeaderBlockComponent } from '../modules/diagnostics-shared/rapport/blocks/header-block/header-block.component';
import { MarkdownBlockComponent } from '../modules/diagnostics-shared/rapport/blocks/markdown-block/markdown-block.component';
import { ReportagePhotoBlockComponent } from '../modules/diagnostics-shared/rapport/blocks/reportage-photo-block/reportage-photo-block.component';
import { PlansBlockComponent } from '../modules/diagnostics-shared/rapport/blocks/plans-block/plans-block.component';
import { TypeReport } from '../model/reference-prestation.model';
import { InterventionService } from './intervention.service';
import { BonCommandeAnalyseAdmin } from '../model/bon-commande.model';
import { filterByCurrentDate } from '../utils/assurance-utils';
import { DiagnosticService } from './diagnostic.service';
import { combineLatestOrEmpty } from '../utils/rxjs.utils';
import { InterventionFileService } from 'src/app/services/intervention-file.service';
import { InterventionFile, TypeReferenceFichier } from 'src/app/model/intervention-file.model';
import { enumTypesCommentaire } from '../model/type-commentaire.model';
import { DocumentsService } from './documents.service';
import { date } from '@storybook/addon-knobs';

/**
 * Liste des Type de component (Class) de block de rapport disponible pour la composition
 * du contenu d'un raport
 */
export const REPORT_BLOCK_CATALOG: { [key: string]: Type<any> } = {
    HeaderBlockComponent: HeaderBlockComponent,
    AdministratifsBlockComponent: AdministratifsBlockComponent,
    MarkdownBlockComponent: MarkdownBlockComponent,
    DocumentsListBlockComponent: DocumentsListBlockComponent,
    ReportagePhotoBlockComponent: ReportagePhotoBlockComponent,
    PlansBlockComponent: PlansBlockComponent,
};

@Injectable({
    providedIn: 'root',
})
export class ReportService {
    constructor(
        private readonly diagnosticHandlerService: DiagnosticHandlerService,
        private readonly interventionService: InterventionService,
        private readonly reportApiService: ReportApiService,
        private readonly httpErrorService: HttpErrorService,
        private readonly authenticationService: AuthenticationStore,
        private readonly diagnosticService: DiagnosticService,
        private readonly interventionFileService: InterventionFileService,
        private readonly documentService: DocumentsService
    ) {}

    /**
     * Récupère la liste des blocks à afficher dans le pré-rapport suivant le type de rapport que l'on souhaite afficher
     * @param prestation
     * @param typeReport
     * @returns
     */
    getReportContent(prestation: Prestation, typeReport: TypeReport): ReportContent {
        let reportContent;
        const reportReference = prestation.reportReferences.find((rr) => {
            const rrParse = JSON.parse(rr.reportContent) as ReportContent;
            return rrParse.typePrestation === prestation.typePrestation && rr.reportType === typeReport;
        });
        if (reportReference) {
            reportContent = JSON.parse(reportReference.reportContent) as ReportContent;
            reportContent.blocks.sort((b1, b2) => b1.order - b2.order);
        }
        return reportContent;
    }

    getReportBlockType(componentName: string, typePrestation: TypePrestation): Type<any> {
        let blockType = REPORT_BLOCK_CATALOG[componentName];
        if (!blockType) {
            if (typePrestation) {
                blockType = this.diagnosticHandlerService.getTypePrestationService(typePrestation).getReportBlockType(componentName);
            }
        }
        return blockType;
    }

    /**
     * Crée l'ensemble des datas permettant d'afficher le pre-rapport du rapport final et qui seront enregistrées dans le diagnostic
     * @param prestation
     * @param intervention
     * @param diagnostic
     * @param userInformation
     * @returns
     */
    getInterventionReportData(
        prestation: Prestation,
        intervention: Intervention,
        diagnostic: Diagnostic,
        userInformation: UserInformation
    ): InterventionReportData {
        if (intervention) {
            const interReportData = this.buildInterventionReportData(intervention, diagnostic?.typePrestation);
            if (diagnostic) {
                const regle = prestation.regles.find((rule) => rule.type === 'REGLE_CONTROL');
                let rules = [];
                if (regle && regle.jsonData) {
                    rules = JSON.parse(regle.jsonData) as Rule[];
                }
                const diagReportData = this.diagnosticHandlerService
                    .getTypePrestationService(diagnostic.typePrestation)
                    .getDiagnosticReportData(intervention, diagnostic, rules, prestation.optionPlan);
                interReportData.diagnostics = [diagReportData];
                interReportData.conformiteGlobale = diagReportData.conformiteGlobale;
                interReportData.listDocuments = interReportData.diagnostics.map((diag) => diag.listDocuments || []).flat();

                interReportData.annexes = interReportData.diagnostics.map((diag) => diag.annexes || []).flat();
            }
            if (userInformation && userInformation.fichierSignatureId) {
                interReportData.operateur.signatureUrl = URL_MS_REPORT_FILE + userInformation.fichierSignatureId;
            }

            if (userInformation && userInformation.competences) {
                userInformation.competences.forEach((competence) => {
                    if (competence.competenceDocument?.typeDocument?.typePrestations) {
                        competence.competenceDocument.typeDocument.typePrestations.forEach((typePrestation) => {
                            if (typePrestation === diagnostic.typePrestation) {
                                let toDay = new Date();
                                if (new Date(competence.dateObtention) < toDay && new Date(competence.dateFin) > toDay) {
                                    let competenceData: CompetenceData = {
                                        nom: competence.nom,
                                        organisme: competence.organisme,
                                        dateObtention: competence.dateObtention,
                                        dateFin: competence.dateFin,
                                        numero: competence.numero,
                                        compentenceDocument: {
                                            id: competence.competenceDocument.id,
                                            idFichier: competence.competenceDocument.idFichier,
                                            nom: competence.competenceDocument.nom,
                                            date: competence.competenceDocument.dateCreation,
                                            type: competence.competenceDocument.typeDocument.code,
                                            afficherDansRapport: competence.competenceDocument.afficherDansRapport,
                                            afficherDansListeDocuments: competence.competenceDocument.afficherDansListeDocuments,
                                            vueParLeTechnicien: competence.competenceDocument.isPresented,
                                            commentaires: this.documentService.buildCommentairesDoc(
                                                competence.competenceDocument.commentairesId,
                                                intervention
                                            ),
                                            nomFichier: competence.competenceDocument.nomFichier,
                                            dateDocument: competence.competenceDocument.dateDocument,
                                            isEditable: competence.competenceDocument.isEditable,
                                            dateObligatoire: competence.competenceDocument.dateDocumentObligatoire,
                                        },
                                    };
                                    interReportData.operateur.certifications.push(competenceData);
                                }
                            }
                        });
                    }
                });
            }

            return interReportData;
        }
        return undefined;
    }

    /**
     * Crée l'ensemble des datas permettant d'afficher le pre-rapport du bon commande et qui seront enregistrées dans le diagnostic
     * @param intervention
     * @param diagnostic
     * @param userInformation
     * @returns
     */
    getInterventionBonCommandeData(intervention: Intervention, diagnostic: Diagnostic, userInformation: UserInformation): InterventionReportData {
        if (intervention) {
            const interReportData = this.buildInterventionReportData(intervention, diagnostic?.typePrestation);
            if (diagnostic) {
                const diagReportData = this.diagnosticHandlerService
                    .getTypePrestationService(diagnostic.typePrestation)
                    .getDiagnosticBonCommandeData(intervention, diagnostic);
                interReportData.diagnostics = [diagReportData];
                interReportData.emisLe = moment().format(DATE_FORMAT_FRANCAIS_SANS_HEURE_MOMENT);
                interReportData.annexes = interReportData.diagnostics.map((diag) => diag.annexes || []).flat();
            }
            if (userInformation && userInformation.fichierSignatureId) {
                interReportData.operateur.signatureUrl = URL_MS_REPORT_FILE + userInformation.fichierSignatureId;
            }
            return interReportData;
        }
        return undefined;
    }

    /**
     * Génère le bon de commande du diagnostic
     * @param intervention
     * @param diagnostic
     * @param interReportData
     */
    generateDiagnosticBonCommande(
        intervention: Intervention,
        diagnostic: Diagnostic,
        interReportData: InterventionReportData
    ): BonCommandeAnalyseAdmin {
        return this.diagnosticHandlerService
            .getTypePrestationService(diagnostic.typePrestation)
            .generateDiagnosticBonCommande(intervention, diagnostic, interReportData);
    }

    /**
     * On ajoute la datas dans le diagnostic
     * @param interReportData
     * @param dataValidateDiagnostic Données qui arrivent de la modale lors de la validation du diagnostic
     * @param intervention
     * @param diagnostic
     * @param typeReport
     * @returns
     */
    saveReportData(
        interReportData: InterventionReportData,
        dataValidateDiagnostic: { etatDiagnosticSelection: EtatDiagnostic; raisonsAttenteSelection: string[] },
        intervention: Intervention,
        diagnostic: Diagnostic,
        typeReport: TypeReport
    ): { diagnostic: Diagnostic; intervention: Intervention } {
        if (dataValidateDiagnostic) {
            // Modification de l'état du diagnostic et enregistrement
            diagnostic.etat = dataValidateDiagnostic.etatDiagnosticSelection;
            diagnostic.raisonsAttente = dataValidateDiagnostic.raisonsAttenteSelection;
        }
        const reportData = diagnostic.reportDatas.find((reportDataTemp) => reportDataTemp.typeReport === typeReport);
        if (reportData) {
            reportData.reportData = JSON.stringify(interReportData);
        } else {
            // Si le reportData n'existe pas (ça ne devrait pas arriver car il est généré à la création du diagnostic)
            const newReportData = new ReportData(typeReport, JSON.stringify(interReportData), null);
            // on généré la reference du report
            this.interventionService.generateRefRapportInReportDataExistant(intervention, diagnostic, newReportData);
            // On le rajoute dans la liste des reports dans le diagnostic
            diagnostic.reportDatas.push(newReportData);
        }

        return {
            intervention,
            diagnostic,
        };
    }

    /**
     * Sauvegarde temporaire des data d'un rapport. ne modifie pas les etats et cloture pas l'intervention
     * @param interReportData
     * @param intervention
     * @param diagnostic
     */
    saveReportDataTemp(interReportData: InterventionReportData, intervention: Intervention, diagnostic: Diagnostic) {
        return this.saveReportData(interReportData, null, intervention, diagnostic, TypeReport.REPORT);
    }

    uploadTemplateRapportDocumentFile(idDocument: string, formData: FormData): Observable<Document> {
        // * on crée un id pour le fichier
        const fileId = MongoUtils.generateObjectId();
        const dateUpload: string = new Date().toLocaleDateString();
        return this.reportApiService.uploadTemplateRapportDocumentFile(idDocument, formData, fileId, dateUpload).pipe(
            catchError((err) => {
                return this.httpErrorService.handleError(err);
            })
        );
    }

    getTemplateReportToDowload(document: Document): void {
        this.reportApiService.getTemplateReportToDowload(document);
    }

    deleteRapportTemplate(idReferencePrestation: string, typeReport: TypeReport, idFichier: string): Observable<any> {
        return this.reportApiService.deleteRapportTemplate(idReferencePrestation, typeReport, idFichier);
    }

    getReportFromIdsDiagnostics(idsDiagnostics: string[]): Observable<RapportsByDiagnostic[]> {
        return this.reportApiService.getReportFromIdsDiagnostics(idsDiagnostics);
    }

    getReportFromIdIntervention(idIntervention: string): Observable<RapportsByDiagnostic[]> {
        return this.reportApiService.getReportFromIdIntervention(idIntervention);
    }

    /**
     * fonction permettant de générer l'ensemble des bons de commande d'une intervention ainsi que de les ajouter dans
     * la dataTable des bons de commande
     * @param intervention
     */
    exportPdfBonCommande(intervention: Intervention): Observable<Diagnostic[]> {
        return this.prepareForExport(intervention, false).pipe(
            switchMap((res) =>
                combineLatestOrEmpty(
                    res.map((r) => {
                        return this.diagnosticService.exportBonCommandeAll(
                            r.diagnostic,
                            r.idReferencePrestation,
                            intervention.id,
                            intervention.relationInterventionBiens.map((it) => it.bien.id)
                        );
                    })
                )
            )
        );
    }

    /**
     * Export d'un rapport uniquement pour un diagnostic terminé
     */
    exportPdf(intervention: Intervention): Observable<Diagnostic[]> {
        return this.prepareForExport(intervention, true).pipe(
            switchMap((res) =>
                combineLatestOrEmpty(
                    res.map((r) => {
                        return this.diagnosticService.exportReport(r.diagnostic, r.idReferencePrestation, intervention.id);
                    })
                )
            )
        );
    }

    /**
     * Préparation de la liste des diagnostics avant export
     * Renvoie un tableau d'objets contenant le diagnostic et l'idReferencePrestation associé
     * @param intervention
     * @param hasFilter Si true, on filtre les diagnostics à l'état terminé ou fini
     * @private
     */
    private prepareForExport(intervention: Intervention, hasFilter: boolean) {
        return this.diagnosticService.getAllDiagnosticsForCurrentIntervention(intervention).pipe(
            map((diag) => (diag ? diag : [])),
            map((diagnostics) => {
                if (hasFilter) {
                    return diagnostics.filter((diag) => [EtatDiagnostic.TERMINE, EtatDiagnostic.FINI, EtatDiagnostic.EN_ATTENTE].includes(diag.etat));
                } else {
                    return diagnostics;
                }
            }),
            map((diagnostics) => {
                return diagnostics.map((diagTermine) => {
                    const idReferencePrestation = intervention.prestationsDiagnostics.find((presta) => presta.idDiagnostic === diagTermine.id)
                        .prestation.referencePrestation.id;
                    return { diagnostic: diagTermine, idReferencePrestation: idReferencePrestation };
                });
            })
        );
    }

    private buildInterventionReportData(intervention: Intervention, pTypePrestation): InterventionReportData {
        const interReportData = new InterventionReportData();
        interReportData.id = intervention.id;
        interReportData.dossierNumber = intervention.nom;
        interReportData.refClient = intervention.refClient;
        interReportData.datePrevue = moment(intervention.dateHeureDebutPrevue).format(DATE_FORMAT_FRANCAIS_SANS_HEURE_MOMENT);
        interReportData.dateEffective = moment(intervention.dateHeureDebutEffective).format(DATE_FORMAT_FRANCAIS_SANS_HEURE_MOMENT);
        interReportData.emisLe = moment().format(DATE_FORMAT_FRANCAIS_SANS_HEURE_MOMENT);
        interReportData.societe = this.buildSociete(intervention.agence);
        interReportData.operateur = this.buildOperateur();
        interReportData.bienPrincipal = this.buildBien(intervention.relationInterventionBiens.find((bien) => bien.isBienPrincipal));
        interReportData.biens = this.buildBiens(intervention.relationInterventionBiens);
        interReportData.donneurOrdre = this.buildContact(intervention.contacts.find((ct) => ct.typeRole === RoleContact.DONNEUR_ORDRE));
        interReportData.proprietaire = this.buildContact(intervention.contacts.find((ct) => ct.typeRole === RoleContact.PROPRIETAIRE));
        intervention.contacts
            .filter((ct) => ct.typeRole === RoleContact.AUTRE)
            .forEach((it) => {
                return (interReportData.contactsAutres = interReportData.contactsAutres.concat(this.buildContact(it)));
            });
        interReportData.documentsPresents = this.buildDocuments(intervention);

        const documentsQuestions = this.documentsQuestionsReportData(intervention, pTypePrestation);
        interReportData.documentsQuestions = documentsQuestions.documentsQuestions;
        interReportData.conformiteDocuments = documentsQuestions.conformiteDocuments;
        interReportData.dateCommande = moment(intervention.dateCommande).format(DATE_FORMAT_FRANCAIS_SANS_HEURE_MOMENT);
        interReportData.auMoinsUnBienMeuble = this.buidAuMoinsUnBienMeuble(intervention.relationInterventionBiens);
        interReportData.auMoinsUnBienOccupe = this.buidAuMoinsUnBienOccupe(intervention.relationInterventionBiens);

        return interReportData;
    }

    private documentsQuestionsReportData(intervention: Intervention, pTypePrestation): any {
        const documentsQuestions = [];
        let conformiteDocuments = true;
        try {
            for (const categorie of [...new Set(intervention.documents.map((doc) => doc.typeDocument.idCategorie))]) {
                const documents = intervention.documents.filter((doc) => doc.typeDocument.idCategorie == categorie);
                const documentQuestion = { categorie, documents: [] };
                for (const doc of documents) {
                    const commentaires = [];
                    doc.commentairesId.forEach((commentaireId) => {
                        const comm = intervention.commentaires.find(
                            (commentaire) => commentaire.id === commentaireId && commentaire.type !== enumTypesCommentaire.NOTE_PERSONNELLE
                        );
                        if (comm) commentaires.push(comm);
                    });
                    const documentReport = {
                        presence: doc.isPresented,
                        typeDocument: doc.typeDocument.nom,
                        commentaires,
                        checkpoints: [],
                    };
                    for (const typeDocCP of doc.typeDocument.typeDocumentCheckpoint.filter(
                        (tdc) => tdc.referencePrestation.typePrestation === pTypePrestation
                    )) {
                        for (const checkpoint of typeDocCP.checkpoints) {
                            const commentairesCP = [];
                            checkpoint.commentairesId.forEach((commentaireId) => {
                                const comm = intervention.commentaires.find(
                                    (commentaire) => commentaire.id === commentaireId && commentaire.type !== enumTypesCommentaire.NOTE_PERSONNELLE
                                );
                                if (comm) commentairesCP.push(comm);
                            });

                            conformiteDocuments = conformiteDocuments && checkpoint.value;
                            documentReport.checkpoints.push({
                                label: checkpoint.label,
                                reponse: checkpoint.value ? 'Oui' : 'Non',
                                commentaires: commentairesCP,
                            });
                        }
                    }
                    if (documentReport.checkpoints.length) {
                        documentQuestion.documents.push(documentReport);
                    }
                }
                if (documentQuestion.documents.length) {
                    documentsQuestions.push(documentQuestion);
                }
            }
        } catch (e) {
            console.error(e);
        }
        return { documentsQuestions, conformiteDocuments };
    }

    private buildContact(contact: Contact): ContactData {
        if (contact) {
            return new ContactData(
                contact.nom ?? 'n.c',
                contact.prenom ?? 'n.c',
                AddressUtils.getFormatedAddress(contact.adresse) ?? 'n.c',
                contact.adresse.codePostal ?? 'n.c',
                contact.adresse.ville ?? 'n.c',
                contact.presence,
                contact.type ?? 'n.c'
            );
        }
        return undefined;
    }

    private buildSociete(agence: Agence): CompanyData {
        const societe = new CompanyData();
        societe.adresse = AddressUtils.getFormatedAddress(agence.adresse) ?? 'n.c';
        societe.codePostal = agence.adresse.codePostal ?? 'n.c';
        societe.ville = agence.adresse.ville ?? 'n.c';
        societe.nom = agence.nom ?? 'n.c';
        societe.siret = agence.siret ? agence.siret : 'n.c';
        societe.codeAPE = agence.codeApe ? agence.codeApe : 'n.c';
        societe.tva = agence.tva ? agence.tva.toString() : 'n.c';
        societe.telephone = agence.telephone ? agence.telephone : 'n.c';
        societe.fax = agence.fax ? agence.fax : 'n.c';

        societe.assurance = this.buildAssurance(agence.assurances);
        return societe;
    }

    private buildAssurance(assurances: Assurance[]): AssuranceData {
        const assuranceData = new AssuranceData();
        assurances = filterByCurrentDate(assurances, 'dateDebutValidite', 'dateEcheance');
        if (assurances.length > 0) {
            assuranceData.nom = assurances[0].compagnie ?? 'n.c';
            assuranceData.numeroPolice = assurances[0].numeroPolice ?? 'n.c';
            assuranceData.dateValidite = moment(assurances[0].dateDebutValidite).format(DATE_FORMAT_FRANCAIS_SANS_HEURE_MOMENT) ?? 'n.c';
            assuranceData.dateEcheance = moment(assurances[0].dateEcheance).format(DATE_FORMAT_FRANCAIS_SANS_HEURE_MOMENT) ?? 'n.c';
        }
        return assuranceData;
    }

    private buildOperateur(): OperateurData {
        const currentUser = this.authenticationService.getCurrentUserSnapshot();
        const operateur = new OperateurData();
        operateur.id = currentUser.id;
        operateur.nom = currentUser.lastName ?? 'n.c';
        operateur.prenom = currentUser.firstName ?? 'n.c';
        operateur.email = currentUser.email ?? 'n.c';

        return operateur;
    }

    private buildBiens(biens: RelationInterventionBien[]): BienData[] {
        const biensData: BienData[] = [];
        biens.forEach((b) => biensData.push(this.buildBien(b)));
        return biensData;
    }
    /**
     * récupère l'intervention file lié a une phto du bien
     * @idFile : l'id du fichier
     */
    public getInterventionFile(interventionId: string, idFile: string, typePtoto: TypeReferenceFichier): Observable<InterventionFile> {
        let interFiles: Observable<InterventionFile> = this.interventionFileService.findByIdInterventionFileId(interventionId, typePtoto, idFile);
        return interFiles;
    }

    private buildBien(bienPrincipal: RelationInterventionBien): BienData {
        const bien = new BienData();
        if (bienPrincipal && bienPrincipal.bien) {
            bien.id = bienPrincipal.bien.id;
            bien.refBien = bienPrincipal.bien.refBien ?? 'n.c';
            bien.designation = bienPrincipal.bien.nom ?? 'n.c';
            bien.annee = String(bienPrincipal.bien.anneeConstruction) ?? 'n.c';
            bien.cadastre = bienPrincipal?.bien?.referenceCadastre ?? 'n.c';
            bien.surface = String(bienPrincipal.bien.surface) ?? 'n.c';
            bien.nbNiveaux = String(bienPrincipal.bien.nbNiveauxPositifs) ?? 'n.c';
            bien.numLot = bienPrincipal.bien.numeroLot ?? 'n.c';
            bien.type = bienPrincipal.bien.idTypeBien ?? 'n.c';
            bien.nombreEntrees = bienPrincipal.bien.nombreEntrees?.toString() ?? 'n.c';
            bien.porte = bienPrincipal.bien.escalierNumPorte ?? 'n.c';
            bien.etage = bienPrincipal.bien.situation ?? 'n.c';
            bien.batiment = bienPrincipal.bien.batiment ?? 'n.c';
            bien.adresse = AddressUtils.getFormatedAddress(bienPrincipal.bien.adresse) ?? 'n.c';
            bien.codePostal = bienPrincipal.bien.adresse.codePostal ?? 'n.c';
            bien.ville = bienPrincipal.bien.adresse.ville ?? 'n.c';

            if (bienPrincipal.bien.idFichierImage) {
                bien.imageUrl = URL_MS_REPORT_FILE + bienPrincipal.bien.idFichierImage;
                let interfile = this.getInterventionFile(
                    bienPrincipal.idIntervention,
                    bienPrincipal.bien.idFichierImage,
                    TypeReferenceFichier.PHOTO_BIEN
                );
                interfile.subscribe((value) => {
                    bien.ExifsPhotoBien = {
                        creationDate: new Date(+value?.creationDate).toLocaleDateString() ?? 'Non resenseigné',
                        gpsLatitudeRef: value?.gpsLatitudeRef ?? 'Non renseigné',
                        gpsLatitude: value?.gpsLatitude ?? 'Non renseigné',
                        gpsLongitudeRef: value?.gpsLongitudeRef ?? 'Non renseigné',
                        gpsLongitude: value?.gpsLongitude ?? 'Non renseigné',
                        gpsAltitudeRef: value?.gpsAltitudeRef ?? 'Non renseigné',
                        gpsAltitude: value?.gpsAltitude ?? 'Non renseigné',
                    };
                });
            } else {
                bien.imageUrl = null;
                bien.ExifsPhotoBien = {
                    creationDate: '',
                    gpsLatitudeRef: '',
                    gpsLatitude: '',
                    gpsLongitudeRef: '',
                    gpsLongitude: '',
                    gpsAltitudeRef: '',
                    gpsAltitude: '',
                };
            }
            if (bienPrincipal.bien.idFichierImageSat) {
                bien.imageUrlSat = URL_MS_REPORT_FILE + bienPrincipal.bien.idFichierImageSat;
                let interfile = this.getInterventionFile(
                    bienPrincipal.idIntervention,
                    bienPrincipal.bien.idFichierImageSat,
                    TypeReferenceFichier.PHOTO_BIEN_SAT
                );
                interfile.subscribe((value1) => {
                    bien.ExifsPhotoAerienneBien = {
                        creationDate: new Date(+value1?.creationDate).toLocaleDateString() ?? 'Non resenseigné',
                        gpsLatitudeRef: value1?.gpsLatitudeRef ?? 'Non renseigné',
                        gpsLatitude: value1?.gpsLatitude ?? 'Non renseigné',
                        gpsLongitudeRef: value1?.gpsLongitudeRef ?? 'Non renseigné',
                        gpsLongitude: value1?.gpsLongitude ?? 'Non renseigné',
                        gpsAltitudeRef: value1?.gpsAltitudeRef ?? 'Non renseigné',
                        gpsAltitude: value1?.gpsAltitude ?? 'Non renseigné',
                    };
                });
            } else {
                bien.imageUrlSat = null;
                bien.ExifsPhotoAerienneBien = {
                    creationDate: '',
                    gpsLatitudeRef: '',
                    gpsLatitude: '',
                    gpsLongitudeRef: '',
                    gpsLongitude: '',
                    gpsAltitudeRef: '',
                    gpsAltitude: '',
                };
            }

            if (bienPrincipal.bienEstMeuble) {
                bien.estMeuble = 'Oui';
            } else {
                bien.estMeuble = 'Non';
            }
            if (bienPrincipal.bienEstOccupe) {
                bien.estOccupe = 'Oui';
            } else {
                bien.estOccupe = 'Non';
            }
            bien.contexteIntervention = bienPrincipal.contexteIntervention;
        }
        return bien;
    }

    private buidAuMoinsUnBienMeuble(relationsInterBien: RelationInterventionBien[]): boolean {
        var meuble: boolean = false;

        relationsInterBien.forEach((relation) => {
            if (relation.bienEstMeuble == true) {
                meuble = true;
            }
        });

        return meuble;
    }

    private buidAuMoinsUnBienOccupe(relationsInterBien: RelationInterventionBien[]): boolean {
        var occupe: boolean = false;

        relationsInterBien.forEach((relation) => {
            if (relation.bienEstOccupe == true) {
                occupe = true;
            }
        });

        return occupe;
    }

    private buildDocuments(intervention: Intervention) {
        return intervention.documents.filter((it) => it.idFichier !== undefined).map((it) => it.typeDocument.code);
    }
}
