<mat-card
    [ngClass]="
        checkpoint.value === validOption.value
            ? 'checkpoint-valid'
            : checkpoint.value === invalidOption.value
            ? 'checkpoint-invalid'
            : 'checkpoint-unknow'
    "
>
    <mat-card-content>
        <div class="d-flex">
            <div class="w-75 d-flex align-items-center">
                <span>{{ checkpoint.label }}</span>
            </div>
            <div class="w-25 d-flex justify-content-between">
                <app-state-input
                    [choices]="choices"
                    [(choiceValue)]="checkpoint.value"
                    [disabled]="readonlyMode"
                    (valueChangeFromUser)="onChangeCheckpointValue()"
                ></app-state-input>
                <app-notes-buttons
                    class="notes-buttons-yellow"
                    [from]="checkpoint"
                    [typesCommentaires]="typeCommentaire"
                >
                </app-notes-buttons>
            </div>
        </div>
    </mat-card-content>
</mat-card>
