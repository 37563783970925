import { Component, Inject, OnInit, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DocumentFileService } from 'src/app/services/document-file.service';

@Component({
    selector: 'app-visualiser-document-modal',
    templateUrl: './visualiser-document-modal.component.html',
    styleUrls: ['./visualiser-document-modal.component.scss'],
})
export class VisualiserDocumentModalComponent implements OnInit {
    pdfSrc: SafeResourceUrl;
    source: string;
    document: any;
    titreModal: string;

    constructor(
        public dialogRef: MatDialogRef<VisualiserDocumentModalComponent>,
        private sanitizer: DomSanitizer,
        private readonly documentFileService: DocumentFileService,
        @Inject(MAT_DIALOG_DATA) public data: { document: any; interventionId: string },
        private ngZone: NgZone
    ) {
        dialogRef.disableClose = false;
    }

    ngOnInit(): void {
        if (this.data.document.nomFichier.split('.').pop() === 'pdf') {
            this.dialogRef.addPanelClass('minwidth1000-dialog');
        } else {
            this.dialogRef.addPanelClass('minwidth100%-dialog');
        }
        this.documentFileService.AfficheFileByFileId(this.data.document).subscribe(async (value) => {
            this.source = (await value.fileContent) ?? '';

            this.ngZone.runOutsideAngular(() => {
                this.pdfSrc = this.sanitizer.bypassSecurityTrustHtml('<object width="100%" height="700" data="' + this.source + '"></object>');
                this.titreModal = this.data.document.nomFichier;
            });
        });
    }

    confirm() {
        this.dialogRef.close();
    }
}
