import { Component, Inject, OnInit } from '@angular/core';
import { PolluantConfig } from '../../../../model/polluant-config.model';
import { DiagnosticService } from 'src/app/services/diagnostic.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PolluantPrelevement } from '../../../../model/polluant-prelevement.model';
import { Polluant } from '../../../../model/polluant.model';
import { FormGroup } from '@angular/forms';
import { Zone } from '../../../../model/zone.model';
import { WidgetSelectModeEnum } from 'src/app/modules/shared/widget-select-multi/widget-select-multi.component';

@Component({
    selector: 'app-zone-travail-modal',
    templateUrl: './zone-travail-modal.component.html',
    styleUrls: ['./zone-travail-modal.component.scss'],
})
export class ZoneTravailModalComponent implements OnInit {
    readonly SEPARATOR = ' - ';
    isModal: boolean = true;
    zonesFiltres: Array<string> = [];
    zonesAll: Array<Zone> = [];
    polluantConfig: PolluantConfig;
    readOnlyMode: Boolean = false;
    private _form: FormGroup;
    private _zoneAssociee: Zone;
    widgetSelectModeEnum: typeof WidgetSelectModeEnum = WidgetSelectModeEnum;
    set zoneAssociee(zone: Zone) {
        this._zoneAssociee = zone;
        if (zone?.id) {
            this.form.controls.idZoneAssociee.setValue(zone.identifiant + this.SEPARATOR + zone.nom);
        }
    }
    get zoneAssociee() {
        return this._zoneAssociee;
    }

    get form() {
        return this._form;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<ZoneTravailModalComponent>,
        private diagnosticService: DiagnosticService
    ) {
        this.polluantConfig = data?.polluantConfig;
        this.readOnlyMode = !data?.isInEditMode;
        this._form = data?.form;
    }

    ngOnInit(): void {
        this.diagnosticService
            .getCurrentDiagnostic()
            .subscribe((diag) => {
                const contenuPolluant = diag.contenuDiagnostic;
                if (contenuPolluant instanceof PolluantPrelevement) {
                    contenuPolluant.visiteDeChantier.data.zones.data.zonesList.map((z) => {
                        this.addZone(z);
                    });
                } else if (contenuPolluant instanceof Polluant) {
                    contenuPolluant.zones.data.zonesList.map((z) => {
                        this.addZone(z);
                    });
                }
            })
            .unsubscribe();
        this.form.controls.environnement.setValue(['Intérieur', 'Extérieur']);
        this.majListeZone();
    }

    private addZone(zone: Zone) {
        if (this.form.controls.id.value != zone.id && zone.typeZone == 'Zone de travail') {
            if (!this.zonesAll.find((z) => z.id === zone.id)) {
                this.zonesAll.push(new Zone(zone));
            }
        }
    }
    modalAction(action) {
        const totalErrors = 0;

        /*
        this.form.markAllAsTouched();
        Object.keys(this.form.controls).forEach((key) => {
            const controlErrors: ValidationErrors = this.form.get(key).errors;
            if (controlErrors != null) {
                totalErrors++;
            }
        });
        */

        if (action == 'saveAndClose') {
            if (totalErrors > 0) {
                return;
            }
            this.dialogRef.close(this._zoneAssociee);
        } else if (action == 'cancel') {
            this.dialogRef.close('cancel');
        } else if (action == 'delete') {
            this.dialogRef.close('delete');
        }
    }

    majListeZone() {
        this.zonesFiltres = [];
        this.zonesAll.forEach((o) => {
            if (o.environnement.some((v) => this.form.get('environnement').value.includes(v))) {
                this.zonesFiltres.push(o.identifiant + this.SEPARATOR + o.nom);
            }
        });
        // console.log('zones : ' + this.zonesFiltres.map((z) => z.environnement).join(', '));
    }

    onChangeZoneAssociee(z: string) {
        const lastIndex = z.lastIndexOf(this.SEPARATOR);
        const id = z.slice(0, lastIndex);
        const nom = z.slice(lastIndex + this.SEPARATOR.length);
        this._zoneAssociee = this.zonesAll.find((zone) => zone.identifiant === id && zone.nom === nom);
        console.log('zones id selected: ' + this.zoneAssociee.id);
    }
}
