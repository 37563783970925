import { UserWizy } from '../model/user-wizy.model';

/**
 * Traitement pour les chaines de caractères
 */
export class StringUtils {
    /**
     * Applique un lowerCase puis supprime les accents.
     * ex : Propriétaire devient proprietaire.
     *
     * @param stringToModified
     * @returns
     */
    static toLowerCase(stringToModified: string) {
        return stringToModified
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
    }

    /**
     * Récupère les initiales d'un utilisateur en prenant en compte s'il a un prenom composé
     * @param user
     */
    static getInitialUser(user: UserWizy): string {
        let textVignette = '';

        if (user.firstName) {
            const tabPrenoms = user.firstName.split('-');
            tabPrenoms.forEach((prenom) => {
                textVignette += prenom[0].toUpperCase();
            });
        }

        if (user.lastName) {
            textVignette += user.lastName[0].toUpperCase();
        }

        return textVignette;
    }
}
