import { Pipe, PipeTransform } from '@angular/core';
import { ProduitLimitationFibre, produitLimitationFibresToLabel } from '../model/prelevement-generique.model';

/**
 * Affiche le nom d'un produit de limitation de fibres
 */
@Pipe({
    name: 'produitLimitationFibres',
})
export class ProduitLimitationFibresPipe implements PipeTransform {
    constructor() {}

    transform(produitLimitationFibres: ProduitLimitationFibre): string {
        return produitLimitationFibresToLabel(produitLimitationFibres);
    }
}
