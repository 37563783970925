<div class="d-flex align-items-center">
    <span *ngIf="label" class="mat-h2 mb-0 primary">
        {{ label }}
    </span>
    <button
        *ngIf="showHelp"
        mat-icon-button
        color="primary"
        class="ml-2"
        matTooltip="Afficher l'aide"
        (click)="openHelp()"
    >
        <lib-icon class="icon-small">help</lib-icon>
    </button>
</div>
