export class ArrayUtils {
    static arraysContainsSameElements(arr1: any[], arr2: any[]) {
        if (!Array.isArray(arr1) || !Array.isArray(arr2) || arr1.length !== arr2.length) {
            return false;
        }

        const arr1Copy = arr1.concat().sort();
        const arr2Copy = arr2.concat().sort();

        for (let i = 0; i < arr1Copy.length; i++) {
            if (arr1Copy[i] !== arr2Copy[i]) {
                return false;
            }
        }

        return true;
    }
}

export function groupBy<T, K extends string | number>(keyGenerator: (item: T) => K): (result: Record<K, T[]>, item: T) => Record<K, T[]> {
    const groupBy = (result: Record<K, T[]>, item: T): Record<K, T[]> => {
        const key = keyGenerator(item);
        if (!result[key]) {
            result[key] = [];
        }
        result[key].push(item);
        return result;
    };
    return groupBy;
}

export function group<T, K extends string | number>(arr: T[], keyGenerator: (item: T) => K): Record<K, T[]> {
    return arr.reduce(groupBy(keyGenerator), {} as Record<K, T[]>);
}
