<div [formGroup]="group">
    <div *ngIf="formConf?.bien?.controls?.bien?.displayed">
        <mat-form-field appearance="fill">
            <mat-label>Bien</mat-label>
            <mat-select formControlName="bien" [compareWith]="compareWith">
                <mat-option *ngFor="let bien of biens" [value]="bien">{{ bien.nom }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="formConf?.bien?.controls?.niveau?.displayed">
        <mat-form-field appearance="fill">
            <mat-label>Niveau</mat-label>
            <mat-select formControlName="niveau" [compareWith]="compareWith">
                <mat-option *ngFor="let niveau of niveaux" [value]="niveau">{{ niveau.nom }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
