<app-header-editor-panel [title]="document?.nom" [showBorder]="false" (closed)="onCloseEditPanel()">
    <div class="d-flex align-items-center mr-3" *ngIf="!readonlyMode">
        <button
            [disabled]="(document | isDocumentReadonly: intervention:diagnostic) || !document?.isEditable"
            type="button"
            mat-icon-button
            color="primary"
            (click)="onClickEditDocument()"
        >
            <mat-icon>edit</mat-icon>
        </button>
    </div>
</app-header-editor-panel>

<div class="container-fluid pl-5 pr-5">
    <div class="row">
        <div class="col">
            <app-documents-prestation-chips [typesPrestation]="document.typePrestations"></app-documents-prestation-chips>
        </div>
    </div>
    <!-- On n'affiche la question de la présence uniquement si il le document est modifiable -->
    <ng-container *ngIf="document.isEditable">
        <div class="row">
            <div class="col-xl-4 d-flex align-items-center primary required">Document présenté</div>
            <div class="d-flex justify-content-between">
                <app-state-input
                    [choices]="choices"
                    [(choiceValue)]="document.isPresented"
                    [disabled]="readonlyMode"
                    (valueChangeFromUser)="handleDateDocumentModelChange(null)"
                ></app-state-input>
                <!--  <app-notes-buttons
            class="notes-buttons-yellow"
            [from]="document"
            [typesCommentaires]="typeDocPresentation"
            [typeNouveauCommentaire]="typeDocPresentation"
        >
        </app-notes-buttons> -->
            </div>
        </div>
        <div *ngIf="!document.isPresented && presenceJustificationNonPresentation() == false" class="row align-items-center to-justify all mr-2 mb-3">
            Il manque une justification
            <app-notes-buttons
                class="mr-2"
                [colorIcon]="colorWarning"
                [from]="document"
                [typesCommentaires]="typeDocPresentation"
                [typeNouveauCommentaire]="typeDocPresentation"
                (onClose)="onChange.emit()"
            >
            </app-notes-buttons>
        </div>
    </ng-container>
    <div class="row" *ngIf="document.isEditable ? (document.isPresented === false ? false : true) : false">
        <div class="col-xl-4 d-flex align-items-center primary required">Date du document</div>
        <mat-form-field class="col-xl-4 pr-2" (click)="!readonlyMode && document?.isEditable ? datepicker.open() : ''" appearance="fill">
            <input
                matInput
                [disabled]="readonlyMode"
                required
                [readonly]="(document | isDocumentReadonly: intervention:diagnostic) || !document?.isEditable"
                [ngModel]="document.dateDocument"
                (ngModelChange)="handleDateDocumentModelChange($event)"
                [matDatepicker]="datepicker"
            />
            <mat-datepicker-toggle *ngIf="!readonlyMode && document?.isEditable" matSuffix [for]="datepicker"> </mat-datepicker-toggle>
            <mat-datepicker #datepicker></mat-datepicker>
        </mat-form-field>
    </div>
    <!-- On n'affiche les lignes de checkpoint uniquement lorsqu'on est dans un diagnostic-->
    <ng-container *ngIf="showCheckpoints && document.isPresented !== false">
        <div class="row mt-3">
            <div class="col">
                <h2>Points de contrôles</h2>
            </div>
        </div>
        <!-- On n'affiche les lignes de checkpoint uniquement lorsqu'on est dans un diagnostic et q'il existe des checkpoints-->
        <div *ngIf="hasCheckpoints; else noCheckpoints" class="row">
            <div class="col">
                <app-documents-detail-checkpoint-list
                    [intervention]="intervention"
                    [diagnostic]="diagnostic"
                    [document]="document"
                    [referencePrestationToShow]="referencePrestationToShow"
                ></app-documents-detail-checkpoint-list>
            </div>
        </div>
        <ng-template #noCheckpoints>
            <div class="col">
                <div class="row">Aucun point de contrôle.</div>
            </div>
        </ng-template>
    </ng-container>
    <div class="row mt-3">
        <div class="col">
            <h2>Détails de la pièce jointe</h2>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <app-documents-detail-attachment
                [document]="document"
                [intervention]="intervention"
                [diagnostic]="diagnostic"
                [readonlyMode]="readonlyMode || (document | isDocumentReadonly: intervention:diagnostic)"
            ></app-documents-detail-attachment>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col">
            <h2>Historique des pièces jointes transmises</h2>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <app-documents-detail-header-attachment-history
                [documentsHistory]="document?.documentsHistory"
            ></app-documents-detail-header-attachment-history>
        </div>
    </div>
</div>
