import { Pipe, PipeTransform } from '@angular/core';
import { TypeAgence, typeAgenceToLabel } from '../model/agence.model';

/**
 * Affiche le nom d'un type d'agence
 */
@Pipe({
    name: 'typeAgence',
})
export class TypeAgencePipe implements PipeTransform {
    constructor() {}

    transform(typeAgence: TypeAgence): string {
        return typeAgenceToLabel(typeAgence);
    }
}
