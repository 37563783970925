import { Injectable } from '@angular/core';
import { webSocket } from 'rxjs/webSocket';
import { environment } from '../../environments/environment';
import { etiquetteZebra } from '../model/etiquetteZebra.model';
import { NotificationService } from 'src/app/commons-lib';

@Injectable({
    providedIn: 'root',
})
export class BluetoothPrintingService {
    private ws;
    private connexion_active;

    constructor(private notificationService: NotificationService) {
        console.log('WS constructor');
        this.connect();
    }

    connect() {
        this.notificationService.notify('Connexion ZebraConnector en cours');
        this.ws = webSocket(environment.webSocketBluetoothPrinting);
        this.connexion_active = true;
        this.ws.subscribe(
            (msg) => console.log('WS message received: ' + msg),
            (err) => {
                console.log(err);
                this.connexion_active = false;
                this.notificationService.warn('Erreur à la connexion ZebraConnector');
            },
            () => console.log('WS complete')
        );
    }

    resetConnexion() {
        if (this.ws != null) {
            this.ws = null;
        }
        this.connect();
    }

    sendMessage(etiquetteZebra: etiquetteZebra) {
        try {
            if (this.connexion_active) {
                this.ws.next(etiquetteZebra);
                return true;
            } else {
                this.notificationService.warn('Vous devez avoir une connexion avec ZebraConnector pour imprimer');
                console.log(etiquetteZebra.codeZpl);
                return false;
            }
        } catch (e) {
            this.notificationService.warn("Erreur à l'impression");
            return false;
        }
    }
}
