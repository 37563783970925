<ng-template #template>
    <!-- Icône Angular Material -->
    <mat-icon *ngIf="!theme || theme == 'angular-material-native'" [ngClass]="class" class="{{ color }}">
        <ng-container *ngIf="iconName">{{ iconName }}</ng-container>
        <ng-content *ngIf="!iconName"></ng-content>
    </mat-icon>
    <!-- Icône Font Awesome -->
    <mat-icon
        *ngIf="theme == 'fa'"
        class="{{ color }}"
        [ngClass]="class"
        [fontSet]="'fa'"
        [fontIcon]="'fa-' + iconName"
    ></mat-icon>
    <!-- Icône Material Design -->
    <mat-icon *ngIf="theme == 'mdi'" class="mdi {{ 'mdi-' + iconName }} {{ color }}" [ngClass]="class"></mat-icon>
    <!-- Icone Wizy -->
    <mat-icon *ngIf="theme == 'wizy'" class="{{ color }}" [svgIcon]="iconName"></mat-icon>
</ng-template>
