'use strict';

import { cn_element, extension_instance } from '..';
import { cn_zoning_type } from '../extension/cn_zoning_type';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************
//***********************************************************************************
//***********************************************************************************
//**** Zone
//***********************************************************************************
//***********************************************************************************

export const ZONE_EXTERIOR_LABEL = 'Extérieur';

export class cn_zone extends cn_element {
    //*******************************************************
    /**
     * Constructor
     */
    constructor(name = '', main_storey = '', zone_type = '', space_auto_numerotation_idx = 0) {
        super();
        this.name = name;
        this.zone_type = zone_type;
        this.main_storey = main_storey;
        this.color = '';
        /**
         * Spaces and storeys ids
         * @type {{space: string, storey: string}[]}
         */
        this.rooms = [];
    }

    /**
     * Returns the list of zoning types
     * @returns {Array<string>}
     */
    static get_zoning_types() {
        return cn_zoning_type.get_zoning_types().map(zt => zt.property);
    }

    //*******************************************************
    /**
     * serialize
     * @returns {object} json object
     */
    serialize() {
        const json = {};
        json.ID = this.ID;
        json.name = this.name;
        json.main_storey = this.main_storey;
        json.zone_type = this.zone_type;
        json.rooms = [...this.rooms];
        json.color = this.color;
        return json;
    }

    //*******************************************************
    /**
     * unserialize
     * @returns {object} zone object
     */
    static unserialize(json) {
        const result = new cn_zone(json.name);
        result.ID = json.ID;
        result.name = json.name;
        result.main_storey = json.main_storey;
        result.zone_type = json.zone_type || '';
        result.rooms = [...json.rooms];
        result.color = json.color;
        return result;
    }

    /**
     * Add room to zone
     *
     * @param {string} space_id
     * @param {string} storey_id
     */
    add_room(space_id, storey_id) {
        if (this.rooms.findIndex(it => it.space === space_id && it.storey === storey_id) < 0) {
            this.rooms.push({ space: space_id, storey: storey_id });
        }
    }

    /**
     * Remove room from zone
     *
     * @param {string} space_id
     * @param {string} storey_id
     */
    remove_room(space_id, storey_id) {
        const index = this.rooms.findIndex(room => room.space === space_id && room.storey === storey_id);
        if (index >= 0) {
            this.rooms.splice(index, 1);
        }
    }
}
