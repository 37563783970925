import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { ActivatedRoute, ActivationStart, Router, RouterOutlet } from '@angular/router';
import { NavigationBarsService } from '../../../services/navigation-bars.service';
import { InterventionService } from '../../../services/intervention.service';
import { EtatProgressionService } from '../../../services/etat-progression.service';
import { takeUntil, tap } from 'rxjs/operators';
import { DiagnosticService } from '../../../services/diagnostic.service';
import { combineLatest } from 'rxjs';

@Component({
    selector: 'app-diagnostic-config',
    templateUrl: './diagnostic-config.component.html',
    styleUrls: ['./diagnostic-config.component.scss'],
})
export class DiagnosticConfigComponent extends BaseComponent implements OnInit {
    constructor(
        private activatedRoute: ActivatedRoute,
        private navigationBarsService: NavigationBarsService,
        private interventionService: InterventionService,
        private etat: EtatProgressionService,
        private diagnosticService: DiagnosticService
    ) {
        super();
    }

    ngOnInit(): void {
        this.etat.setMode('diagnostic');
        combineLatest([this.interventionService.getCurrentIntervention(), this.diagnosticService.getCurrentDiagnostic()])
            .pipe(
                tap(([currentIntervention, currentDiagnostic]) =>
                    this.navigationBarsService.configureNavBar({
                        mode: 'diagnostic',
                        activatedRoute: this.activatedRoute,
                        refObject: { currentIntervention, currentDiagnostic },
                        typePrestation: currentDiagnostic.typePrestation,
                    })
                ),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe();
    }
}
