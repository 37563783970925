import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { MatDialog } from '@angular/material/dialog';
import { CommentModalComponent } from '../comment-modal/comment-modal.component';
import { DialogUtils } from '../../../utils/dialog.utils';
import { TypeCommentaire } from '../../../model/type-commentaire.model';
import { PointDeControleElement } from '../../../model/point-de-controle.model';
import { Intervention } from '../../../model/intervention.model';
import { InterventionService } from '../../../services/intervention.service';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Diagnostic } from '../../../model/diagnostic.model';
import { DiagnosticService } from '../../../services/diagnostic.service';
import { CnSpinnerService } from '../cn-spinner/service/cn-spinner.service';
import { EnvironmentUtils } from 'src/app/utils/environment.utils';
import { FormControl } from '@angular/forms';
import { CustomSelectGroup } from '../custom-select/custom-select.model';
import { CommentaireModalComponent } from '../commentaire-modal/commentaire-modal.component';
import { of } from 'rxjs';

@Component({
    selector: 'app-notes-buttons',
    templateUrl: './notes-buttons.component.html',
    styleUrls: ['./notes-buttons.component.scss'],
})
export class NotesButtonsComponent extends BaseComponent implements OnInit {
    @Input()
    buttonId: any;

    @Input()
    commentsId: string[] = [];

    @Input()
    from: any;

    @Input()
    diagnostic: Diagnostic;

    @Input()
    element: PointDeControleElement;

    // Tous les types de commentaire à proposer
    @Input()
    typesCommentaires: TypeCommentaire[] = [];

    // Force le type du nouveau commentaire
    @Input()
    typeNouveauCommentaire: TypeCommentaire;

    @Input()
    replaceTypesCommentaires = false;

    @Input()
    isDescription = false;

    @Input()
    colorIcon = 'primary';

    //afficher la localisation si null on affiche pas
    @Input()
    itemsLocalisation?: CustomSelectGroup[] = null;

    @Output()
    commentChanged = new EventEmitter<any>();

    @Output()
    onClose = new EventEmitter<any>();

    intervention: Intervention;

    afficherBoutonPhoto: boolean = EnvironmentUtils.afficherBoutonPhoto();

    constructor(
        public matDialog: MatDialog,
        private interventionService: InterventionService,
        private diagnosticService: DiagnosticService,
        private cnSpinnerService: CnSpinnerService
    ) {
        super();
    }

    ngOnInit(): void {
        this.interventionService
            .getCurrentIntervention()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((intervention) => (this.intervention = intervention));
    }

    openPictures() {
        console.log('open picture modal');
    }

    openComments($event) {
        $event.stopPropagation();
        this.matDialog
            .open(CommentaireModalComponent, {
                ...DialogUtils.configFullScreen(),
                data: {
                    commentairesId: [...this.getTabCommentairesId()],
                    typeCommentaireSelected: this.typeNouveauCommentaire,
                    elements: [this.element],
                    localisation: this.itemsLocalisation,
                },
            })
            .afterClosed()
            .pipe(
                switchMap((result) => {
                    if (result && result !== false) {
                        console.log('result', result);
                        this.setTabCommentairesId(result.commentairesId);
                        console.log('roomm', this.from);
                        // Nettoyage des commentaires obsolètes
                        this.getTabCommentairesId().forEach((com) => {
                            const comment = this.intervention.commentaires.find((c) => c.id === com);
                            if (comment === undefined) {
                                this.getTabCommentairesId().splice(this.getTabCommentairesId().indexOf(com), 1);
                            }
                        });

                        return this.cnSpinnerService
                            .withSpinner(
                                this.interventionService.updateIntervention(this.intervention).pipe(
                                    switchMap(() => {
                                        if (this.diagnostic) {
                                            return this.diagnosticService.upsert(this.intervention, this.diagnostic).pipe(map(() => result));
                                        } else {
                                            return of(result);
                                        }
                                    })
                                ),
                                'Ajout de commentaire en cours...'
                            )
                            .pipe(takeUntil(this.ngUnsubscribe));
                    } else {
                        return of(result);
                    }
                }),
                tap(() => this.commentChanged.emit(this.getTabCommentairesId()))
            )
            .subscribe((result: any) => {
                this.onClose.emit();
            });
    }

    getTabCommentairesId(): string[] {
        if (this.from) {
            if (this.from instanceof FormControl) {
                return this.from.value;
            } else {
                return this.from.commentairesId;
            }
        } else {
            return [];
        }
    }

    private setTabCommentairesId(newTabCommentairesId: string[]) {
        console.log('newTabCommentairesId', newTabCommentairesId);
        console.log('from', this.from);
        if (this.from) {
            console.log('from', this.from);
            if (this.from instanceof FormControl) {
                this.from.patchValue(newTabCommentairesId);
            } else {
                console.log('from', this.from);
                this.from.commentairesId = newTabCommentairesId;
                console.log('from', this.from);
            }
        }
    }
}
