import { Conformite } from './conformite.model';
import { TypeCommentaire } from './type-commentaire.model';
import { TypePrestation } from './type-prestation.model';

export class PointDeControleBien {
    /**
     * id du bien
     */
    idBien: string;

    /**
     * Nom du bien
     */
    nomBien: string = null;

    /**
     * Valeurs des paramètres
     */
    valeursParametres: any = {};

    /**
     * Liste des étages dans le bien
     */
    pointsDeControleNiveaux: PointDeControleNiveau[] = [];
}

/**
 * Niveau, possédant des types volume
 */
export class PointDeControleNiveau {
    /**
     * id du niveau
     */
    idNiveau: string;

    /**
     * index
     */
    index: number;

    /**
     * Nom de l'étage
     */
    nom: string = null;

    /**
     * Valeurs des paramètres
     */
    valeursParametres: any = {};

    /**
     * Volumes du niveau
     */
    pointsDeControleVolumes: PointDeControleVolume[] = [];

    /**
     * Id du storey si plan associé
     */
    storeyId: string = null;
}

/**
 * Volume d'un bien
 */
export class PointDeControleVolume {
    /**
     * idVolume
     */
    idVolume: string;

    /**
     * Désignation
     */
    nom: string;

    /**
     * Un volume caché est créé à la création d'un niveau.
     * Il permet de déposer des éléments indirectement dans le niveau
     */
    volumeCache = false;

    /**
     * Éléments à contrôler du volume
     */
    pointsDeControleElements: PointDeControleElement[] = [];

    /**
     * Valeurs des paramètres
     */
    valeursParametres: any = {};

    /**
     * Liste de commentaires liée à ce volume
     */
    commentairesId: string[] = [];

    /**
     * Liste de types de commentaires spécifiques
     */
    typesCommentaires: TypeCommentaire[] = [];

    /**
     * justifié
     */
    justifie = false;

    /**
     * Id du space si plan associé
     */
    spaceId: string = null;

    /**
     * Id de l'usage de la pièce si usage spécifié
     */
    usageId: string = null;
}

/**
 * Élément à contrôler
 */
export class PointDeControleElement {
    /**
     * idElement
     */
    idElement: string;

    /**
     * Désignation
     */
    nom: string = null;

    /**
     * Conformité
     */
    conformite: Conformite;

    /**
     * justifié
     */
    justifie = false;

    /**
     * Code ref
     */
    codeRef: string;

    /**
     * Valeurs des paramètres
     */
    valeursParametres: any = {};

    /**
     * Liste des errors des l'élément
     */
    errors: Map<string, string> = new Map();

    /** Liste des messages d'erreurs à afficher */
    displayError: string;

    /**
     * Délais des travaux
     */
    delais: string;

    /**
     * Liste de commentaires liée à cet élément
     */
    commentairesId: string[] = [];

    /**
     * Liste de types de commentaires spécifiques
     */
    typesCommentaires: TypeCommentaire[] = [];

    /**
     * Id du object_instance si plan associé
     */
    objectId: string = null;

    /**
     * Types de prestations associés à l'élément
     */
    typesPrestationsAssocie: TypePrestation[] = [];
}

export class MesureZone {
    type: string;
    nom: string;
    surface: string;
    mode: string;
}
