<div [formGroup]="group" class="formgroup-container">
    <div [formGroup]="group.get('surface')" *ngIf="zonesConf?.displayed">
        <h4 class="my-2">Surfaces</h4>
        <div>
            <div *ngIf="zonesConf?.controls?.showSurfacesComptabilisees?.displayed">
                <mat-checkbox formControlName="showSurfacesComptabilisees">Comptabilisées</mat-checkbox>
            </div>
            <div *ngIf="zonesConf?.controls?.showSurfacesDecomptees?.displayed">
                <mat-checkbox formControlName="showSurfacesDecomptees">Décomptées</mat-checkbox>
            </div>
        </div>
    </div>
</div>
