import { Pipe, PipeTransform } from '@angular/core';
import { TypeRapport } from '../model/rapport.model';

export const typeRapportIconMapping: { [key in TypeRapport]: { fontSet: string; fontIcon: string; label: string } } = {
    [TypeRapport.INTERMEDIATE]: { fontSet: 'fas', fontIcon: 'fa-file-word', label: 'Intermédiaire Word' },
    [TypeRapport.FINAL_PDF]: { fontSet: 'fas', fontIcon: 'fa-file-pdf', label: 'Fichier PDF final' },
    [TypeRapport.FINAL_EXCEL]: { fontSet: 'fas', fontIcon: 'fa-file-excel', label: 'Fichier Excel final' },
    [TypeRapport.INTERMEDIATE_PDF]: { fontSet: 'fas', fontIcon: 'fa-file-pdf', label: 'Intermédiaire PDF' },
    [TypeRapport.REPORT_DATA_JSON]: { fontSet: 'fas', fontIcon: 'fa-file-code', label: 'Données en entrée JSON' },
};

@Pipe({
    name: 'typeRapport',
})
export class TypeRapportPipe implements PipeTransform {
    transform(value: TypeRapport, arg: 'fontSet' | 'fontIcon' | 'label' = 'label'): unknown {
        return (typeRapportIconMapping[value] || {})[arg];
    }
}
