import { Pipe, PipeTransform } from '@angular/core';
import { TypeCommentaire, typeCommentaireToLabel } from '../model/type-commentaire.model';

/**
 * Affiche le nom d'un type de commentaire
 */
@Pipe({
    name: 'typeCommentaire',
})
export class TypeCommentairePipe implements PipeTransform {
    constructor() {}

    transform(typeCommentaire: TypeCommentaire): string {
        return typeCommentaireToLabel(typeCommentaire);
    }
}
