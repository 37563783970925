import { Component, Inject, OnInit } from '@angular/core';
import { ScreenshotSvg } from '../../../../model/screenshot-svg.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PlanRapport } from '../../../diagnostics/assainissement/model/assainissement.model';

class ReportScreenshotsModalData {
    constructor(public screenshotsAvailable: ScreenshotSvg[], public code: string, public selectedScreenshots: ScreenshotSvg[]) {}
}

@Component({
    selector: 'app-report-screenshots-list-modal',
    templateUrl: './report-screenshots-list-modal.component.html',
    styleUrls: ['./report-screenshots-list-modal.component.scss'],
})
export class ReportScreenshotsListModalComponent implements OnInit {
    screenshotsSelected: PlanRapport[] = [];
    screenshotsAvailable: ScreenshotSvg[] = [];
    constructor(
        private dialogRef: MatDialogRef<ReportScreenshotsListModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ReportScreenshotsModalData
    ) {
        dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth600-dialog');
        if (this.data) {
            this.screenshotsAvailable = this.data.screenshotsAvailable;
            this.screenshotsSelected = this.data.selectedScreenshots;
        }
    }

    confirm() {
        this.dialogRef.close({ screenshotsSelected: this.screenshotsSelected });
    }

    selectionChanged(screenshotsSelected: PlanRapport[]) {
        this.screenshotsSelected = screenshotsSelected;
    }

    cancel() {
        this.dialogRef.close(false);
    }
}
