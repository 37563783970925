<div class="toolbar">
    <mat-toolbar class="border-bottom-1 primary">
        <button mat-button class="px-0" (click)="back()">
            <lib-icon>arrow_back</lib-icon>
        </button>
        <div>
            <lib-icon [class]="'icon-before-text'">settings</lib-icon>
            <span *ngIf="isModeCreate()">Création d'un équipement</span>
            <span *ngIf="isModeEdit()">Edition de l'équipement</span>
            <span *ngIf="isModeDuplicate()">Duplication de l'équipement</span>
        </div>

        <div class="d-flex justify-content-end w-100">
            <button mat-button color="primary" class="mx-1" (click)="back()">
                <mat-icon>close</mat-icon>
                <span>{{ 'Annuler' | uppercase }}</span>
            </button>
            <button mat-raised-button color="primary" [disabled]="formTypeElement.invalid" (click)="validerEquipement()">
                <lib-icon>check</lib-icon>
                <span>{{ 'Valider' | uppercase }}</span>
            </button>
        </div>
    </mat-toolbar>
</div>

<div class="equipement-container">
    <div class="row no-gutters p-4 scrollable-content" [formGroup]="formTypeElement">
        <!-- Colonne gauche-->
        <div class="col-xl-6 pr-2">
            <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="col-xl-12 p-2 m-0 primary">Informations générales</div>

                    <!-- codeBim de l'equipement -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Code bim de la catégorie</div>
                        <div class="col-xl-8 pr-2">{{ categorieEquipement?.codeBim }}</div>
                    </div>

                    <!-- Nom de l'equipement -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Nom de l'équipement</div>
                        <div class="col-xl-8 pr-2">
                            <mat-form-field class="w-100">
                                <input matInput type="text" formControlName="name" />
                                <mat-error *ngIf="formTypeElement.get('name').hasError('erreurNomEquipement')"
                                    >Un autre équipement possède déjà le même nom.
                                </mat-error>
                                <app-mat-error [control]="formTypeElement.get('name')"></app-mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

            <div class="row no-gutters">
                <div class="col-xl-6 pr-2">
                    <!------------ Pictogramme ------------->
                    <mat-card class="d-flex w-100 p-2 mb-2">
                        <mat-card-content class="w-100">
                            <div class="col-xl-12 m-0 mb-3 primary">Pictogramme</div>
                            <mat-card *ngIf="!iconImageData" class="d-flex w-100 action-card">
                                <mat-card-content class="w-100">
                                    <div>
                                        <lib-file-uploader
                                            #fileUploader
                                            [isImage]="true"
                                            (startUpload)="uploadIconeElement($event)"
                                        ></lib-file-uploader>
                                        <mat-progress-bar
                                            class="mt-2"
                                            [hidden]="!(fileUploader.uploadRunning | async)"
                                            [value]="fileUploader.uploadProgress | async"
                                        ></mat-progress-bar>

                                        <button class="w-100" mat-button (click)="fileUploader.selectFile()">
                                            <mat-icon>add_a_photo</mat-icon>
                                            <br />{{ "Ajouter le pictogramme de l'élément à controler" }}
                                        </button>
                                    </div>
                                </mat-card-content>
                            </mat-card>

                            <div *ngIf="iconImageData" class="pl-5">
                                <button mat-icon-button class="position-absolute align-self-end button-group primary" (click)="editPicture = true">
                                    <lib-icon class="icon-small">edit</lib-icon>
                                </button>
                                <div *ngIf="editPicture">
                                    <div class="position-absolute align-self-end button-group">
                                        <button mat-icon-button color="warn" (click)="editPicture = false" matTooltip="Annuler">
                                            <lib-icon class="icon-small">cancel</lib-icon>
                                        </button>
                                        <button
                                            mat-icon-button
                                            color="warn"
                                            (click)="deleteIconeElement()"
                                            matTooltip="Supprimer le pictogramme de l'élément à controler"
                                        >
                                            <lib-icon class="icon-small">delete</lib-icon>
                                        </button>
                                        <lib-file-uploader
                                            #fileUploader
                                            [isImage]="true"
                                            (startUpload)="uploadIconeElement($event)"
                                        ></lib-file-uploader>
                                        <button
                                            mat-icon-button
                                            color="primary"
                                            (click)="fileUploader.selectFile()"
                                            matTooltip="Changer le pictogramme de l'élément à controler"
                                        >
                                            <lib-icon class="icon-small">add_a_photo</lib-icon>
                                        </button>
                                    </div>
                                </div>
                                <img
                                    [src]="iconImageData"
                                    alt="Pictogramme de l'élément à controler"
                                    class="img-fluid"
                                    style="max-width: 100%; max-height: 138px"
                                />
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>

                <div class="col-xl-6 pl-2">
                    <!------------ Vue de dessus ------------->
                    <mat-card class="d-flex w-100 p-2 mb-2">
                        <mat-card-content class="w-100">
                            <div class="col-xl-12 m-0 mb-3 primary">Vue de dessus</div>
                            <mat-card *ngIf="!vueDessusData" class="d-flex w-100 action-card">
                                <mat-card-content class="w-100">
                                    <div>
                                        <lib-file-uploader #fileUploader [isImage]="true" (startUpload)="uploadVueDessus($event)"></lib-file-uploader>
                                        <mat-progress-bar
                                            class="mt-2"
                                            [hidden]="!(fileUploader.uploadRunning | async)"
                                            [value]="fileUploader.uploadProgress | async"
                                        ></mat-progress-bar>

                                        <button class="w-100" mat-button (click)="fileUploader.selectFile()">
                                            <mat-icon>add_a_photo</mat-icon>
                                            <br />{{ "Ajouter la vue de dessus de l'équipement" }}
                                        </button>
                                    </div>
                                </mat-card-content>
                            </mat-card>

                            <div *ngIf="vueDessusData" class="pl-5">
                                <button mat-icon-button class="position-absolute align-self-end button-group primary" (click)="editVueDessus = true">
                                    <lib-icon class="icon-small">edit</lib-icon>
                                </button>
                                <div *ngIf="editVueDessus">
                                    <div class="position-absolute align-self-end button-group">
                                        <button mat-icon-button color="warn" (click)="editVueDessus = false" matTooltip="Annuler">
                                            <lib-icon class="icon-small">cancel</lib-icon>
                                        </button>
                                        <button
                                            mat-icon-button
                                            color="warn"
                                            (click)="deleteVueDessus()"
                                            matTooltip="Supprimer la vue de dessus de l'équipement"
                                        >
                                            <lib-icon class="icon-small">delete</lib-icon>
                                        </button>
                                        <lib-file-uploader #fileUploader [isImage]="true" (startUpload)="uploadVueDessus($event)"></lib-file-uploader>
                                        <button
                                            mat-icon-button
                                            color="primary"
                                            (click)="fileUploader.selectFile()"
                                            matTooltip="Changer la vue de dessus de l'équipement"
                                        >
                                            <lib-icon class="icon-small">add_a_photo</lib-icon>
                                        </button>
                                    </div>
                                </div>
                                <img
                                    [src]="vueDessusData"
                                    alt="Vue de dessus de l'équipement"
                                    class="img-fluid"
                                    style="max-width: 100%; max-height: 138px"
                                />
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>

            <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <!-- valeurs des paramètres de l'équipements -->
                    <div class="d-flex flex-column p-2 align-items-center">
                        <div class="col-xl-12 mat-subheading-2 m-0 primary">Valeurs des paramètres de la catégorie</div>
                        <div *ngFor="let param of categorieEquipement?.parametersDefinitions" class="col-xl-11 d-flex p-2 align-items-center">
                            {{ param.name }} ({{ param.codeBim }}) : {{ getValueParameter(param.codeBim) }}
                            <div class="position-absolute align-self-end button-group">
                                <button
                                    mat-icon-button
                                    class="primary"
                                    (click)="onClickBtnAddOrEditParametre(param)"
                                    data-cy="editer-parametre-button"
                                >
                                    <lib-icon class="icon-small">edit</lib-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

        <!--Colonne droite-->
        <div class="col-xl-6 pl-2">
            <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="col-xl-12 p-2 m-0 primary">Dimensions</div>

                    <!-- Largeur de l'equipement -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Largeur (cm)</div>
                        <div class="col-xl-8 pr-2">
                            <mat-form-field class="w-100">
                                <input matInput type="number" formControlName="width" />
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Prodondeur de l'equipement -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Profondeur (cm)</div>
                        <div class="col-xl-8 pr-2">
                            <mat-form-field class="w-100">
                                <input matInput type="number" formControlName="depth" />
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- hauteur de l'equipement -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Hauteur (cm)</div>
                        <div class="col-xl-8 pr-2">
                            <mat-form-field class="w-100">
                                <input matInput type="number" formControlName="height" />
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- contact de l'equipement -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Parallèle en toiture ?</div>
                        <div class="col-xl-8 pr-2">
                            <mat-checkbox formControlName="roofParallel"></mat-checkbox>
                        </div>
                    </div>

                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Contact</div>
                        <div class="col-xl-8 pr-2">
                            <mat-radio-group class="d-flex flex-column form-custom" formControlName="contact">
                                <ng-container *ngFor="let it of listeContacts">
                                    <mat-radio-button [value]="it.contact">{{ it.label }}</mat-radio-button>
                                </ng-container>
                            </mat-radio-group>
                        </div>
                    </div>

                    <!-- hauteur si contact mur -->
                    <div *ngIf="formTypeElement.value.contact == EquipementContactEnum.WALL" class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary">Hauteur si contact mur (cm)</div>
                        <div class="col-xl-8 pr-2">
                            <mat-form-field class="w-100">
                                <input matInput type="number" formControlName="defaultHeight" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Forme</div>
                        <div class="col-xl-8 pr-2">
                            <mat-radio-group class="d-flex flex-column form-custom" formControlName="sourceType">
                                <mat-radio-button value="square">Rectangle</mat-radio-button>
                                <mat-radio-button value="circle">Elipse</mat-radio-button>
                                <mat-radio-button value="wikipim" disabled>BBO</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="d-flex p-2 align-items-center" *ngIf="formTypeElement.value.sourceType != 'wikipim'">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary">Couleur</div>
                        <div class="col-xl-8 pr-2">
                            <mat-form-field class="no-underline">
                                <input matInput type="color" formControlName="color" />
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- vue 3D de l'equipement -->
                    <div class="d-flex p-2 align-items-center">
                        <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload />
                        <div class="col-xl-4 mat-subheading-2 m-0 primary">Importer 3D (BBO)</div>
                        <div class="col-xl-8 pr-2">
                            <div class="file-upload">
                                <button mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()">
                                    <mat-icon>attach_file</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Vue 3D</div>
                        <div class="col-xl-8 pr-2">
                            <div id="fh_3d_container-product-detail"></div>
                            <button mat-icon-button color="primary" class="reset-view-btn" (click)="resetView()">
                                <mat-icon>center_focus_strong</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
