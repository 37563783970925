<br />
<div class="container">
    <div class="row">
        <div class="col">
            <mat-card class="px-2 py-2 d-flex flex-column justify-content-between">
                <mat-card-title>
                    <div class="d-flex justify-content-between align-items-center">
                        <app-section-title label="Informations générales" [showHelp]="false"></app-section-title>
                    </div>
                </mat-card-title>

                <mat-card-content [formGroup]="formGeneral">
                    <!--NOM DE L'ENTREPRISE-->
                    <div class="d-flex flex-column">
                        <div class="p-2">{{ ceeModel.getLabel('nomEntreprise') }}</div>
                        <div class="ml-2 flex-row">
                            <div class="d-flex justify-content-around align-items-center">
                                <mat-form-field [ngClass]="{ disabledDiv: readonlyMode }" class="col-6">
                                    <mat-label>{{ ceeModel.getLabel('nomEntreprise') }}</mat-label>
                                    <input
                                        matInput
                                        formControlName="nomEntreprise"
                                        [disabled]="readonlyMode"
                                        [required]="!formGeneral?.get('nomEntrepriseNR')?.value"
                                        (focus)="nomEntrepriseFocus()"
                                    />
                                </mat-form-field>
                                <div class="d-flex col-xl-6 p-2 align-items-center">
                                    <mat-checkbox
                                        #nonRenseigneEntreprise
                                        class="col-xl-6"
                                        color="primary"
                                        formControlName="nomEntrepriseNR"
                                        [disabled]="readonlyMode"
                                        (change)="nomEntrepriseNRchange($event.checked)"
                                        >Non renseigné
                                    </mat-checkbox>
                                    <app-notes-buttons
                                        class="mr-2"
                                        [from]="ceeModel.nomEntreprise"
                                        [diagnostic]="diagnostic"
                                        [typesCommentaires]="COMMENT_TYPES"
                                    >
                                    </app-notes-buttons>
                                </div>
                            </div>

                            <div
                                *ngIf="nonRenseigneEntreprise.checked === true && !ceeModel.nomEntreprise?.commentairesId?.length"
                                class="to-justify all mr-2 mb-3"
                            >
                                <input hidden required />
                                Il manque une justification
                                <button
                                    mat-icon-button
                                    color="justify"
                                    class="ml-1"
                                    (click)="addJustification(ceeModel.nomEntreprise, enumTypesCommentaire.JUSTIFICATION_NON_CONFORMITE)"
                                >
                                    <lib-icon theme="mdi" iconName="comment-alert"></lib-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                    <!--NUMÉRO DE SIRET-->
                    <div class="d-flex flex-column">
                        <div class="p-2">{{ ceeModel.getLabel('numeroSIRET') }}</div>
                        <div class="ml-2 flex-row">
                            <div class="d-flex justify-content-around align-items-center">
                                <mat-form-field [ngClass]="{ disabledDiv: readonlyMode }" class="col-6">
                                    <mat-label>{{ ceeModel.getLabel('numeroSIRET') }}</mat-label>
                                    <input
                                        title="numSiret"
                                        matInput
                                        type="number"
                                        [pattern]="'[0-9]{14}'"
                                        oninput="if (value.length > 14) value = value.slice(0, 14);"
                                        formControlName="numeroSIRET"
                                        [required]="!formGeneral?.get('numeroSIRETNR')?.value"
                                        (focus)="numeroSiretFocus()"
                                        (change)="validiteSiret()"
                                        [disabled]="readonlyMode"
                                    />
                                </mat-form-field>
                                <div class="d-flex col-xl-6 p-2 align-items-center">
                                    <mat-checkbox
                                        #nonRenseigneSiret
                                        class="col-xl-6"
                                        color="primary"
                                        formControlName="numeroSIRETNR"
                                        [disabled]="readonlyMode"
                                        (change)="numeroSiretNRchange($event.checked)"
                                        >Non renseigné
                                    </mat-checkbox>
                                    <app-notes-buttons
                                        class="mr-2"
                                        [from]="ceeModel.numeroSIRET"
                                        [diagnostic]="diagnostic"
                                        [typesCommentaires]="COMMENT_TYPES"
                                    >
                                    </app-notes-buttons>
                                </div>
                            </div>
                            <div
                                *ngIf="nonRenseigneSiret.checked === true && !ceeModel.numeroSIRET?.commentairesId?.length"
                                class="to-justify all mr-2 mb-3"
                            >
                                Il manque une justification
                                <button
                                    mat-icon-button
                                    color="justify"
                                    class="ml-1"
                                    (click)="addJustification(ceeModel.numeroSIRET, enumTypesCommentaire.JUSTIFICATION_NON_CONFORMITE)"
                                >
                                    <lib-icon theme="mdi" iconName="comment-alert"></lib-icon>
                                </button>
                            </div>
                        </div>
                        <div *ngIf="diagnostic?.typePrestation.includes('BAREN10')" class="d-flex flex-column">
                            <div class="p-2" matTooltip="Reconnu garant de l'environnement">
                                {{ ceeModel.getLabel('entrepriseRGE') }}
                            </div>
                            <div class="d-flex flex-row">
                                <div class="d-flex justify-content-around align-items-center">
                                    <mat-button-toggle-group [disabled]="readonlyMode" formControlName="entrepriseRGE">
                                        <mat-button-toggle value="OUI"> Oui </mat-button-toggle>
                                        <mat-button-toggle value="NON"> Non </mat-button-toggle>
                                    </mat-button-toggle-group>
                                    <app-notes-buttons
                                        class="mr-2"
                                        [from]="ceeModel.entrepriseRGE"
                                        [diagnostic]="diagnostic"
                                        [typesCommentaires]="COMMENT_TYPES"
                                    >
                                    </app-notes-buttons>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--DATE DE LA VISITE PRÉALABLE-->
                    <div
                        *ngIf="
                            diagnostic &&
                            (diagnostic.typePrestation.includes(TypePrestationEnum.BAREN101) ||
                                diagnostic.typePrestation.includes(TypePrestationEnum.BAREN102) ||
                                diagnostic.typePrestation.includes(TypePrestationEnum.BAREN103))
                        "
                        class="d-flex flex-column"
                    >
                        <div class="p-2">{{ ceeModel.getLabel('dateVisitePrealable') }}</div>
                        <div class="ml-2 flex-row">
                            <div class="d-flex justify-content-around align-items-center">
                                <mat-form-field [ngClass]="{ disabledDiv: readonlyMode }" class="col-6">
                                    <mat-label>{{ ceeModel.getLabel('dateVisitePrealable') }}</mat-label>
                                    <input
                                        title="dateVisitePrealable"
                                        matInput
                                        [required]="!formGeneral?.get('dateVisitePrealableNR')?.value"
                                        (focus)="dateVisitePrealableFocus()"
                                        [matDatepicker]="datepicker"
                                        formControlName="dateVisitePrealable"
                                        [disabled]="readonlyMode"
                                    />
                                    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                                    <mat-datepicker #datepicker startView="multi-year"></mat-datepicker>
                                </mat-form-field>
                                <div class="d-flex col-xl-6 p-2 align-items-center">
                                    <mat-checkbox
                                        #nonRenseigneDateVisitePrealable
                                        class="col-xl-6"
                                        color="primary"
                                        formControlName="dateVisitePrealableNR"
                                        [disabled]="readonlyMode"
                                        (change)="dateVisitePrealableNRchange($event.checked)"
                                        >Non renseigné
                                    </mat-checkbox>
                                    <app-notes-buttons
                                        class="mr-2"
                                        [from]="ceeModel.dateVisitePrealable"
                                        [diagnostic]="diagnostic"
                                        [typesCommentaires]="COMMENT_TYPES"
                                    >
                                    </app-notes-buttons>
                                </div>
                            </div>
                            <div
                                *ngIf="nonRenseigneDateVisitePrealable.checked === true && !ceeModel.dateVisitePrealable?.commentairesId?.length"
                                class="to-justify all mr-2 mb-3"
                            >
                                Il manque une justification
                                <button
                                    mat-icon-button
                                    color="justify"
                                    class="ml-1"
                                    (click)="addJustification(ceeModel.dateVisitePrealable, enumTypesCommentaire.JUSTIFICATION_NON_CONFORMITE)"
                                >
                                    <lib-icon theme="mdi" iconName="comment-alert"></lib-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!--DATE D'ACCEPTATION DU DEVIS-->
                    <div *ngIf="isDocPresent" class="d-flex flex-column">
                        <div class="p-2">{{ ceeModel.getLabel('dateAcceptationDevis') }}</div>
                        <div class="ml-2 flex-row">
                            <div class="d-flex justify-content-around align-items-center">
                                <mat-form-field class="ml-2 col-6">
                                    <mat-label>{{ ceeModel.getLabel('dateAcceptationDevis') }}</mat-label>
                                    <input
                                        title="dateAcceptationDevis"
                                        matInput
                                        [required]="!formGeneral?.get('dateAcceptationDevisNR')?.value"
                                        (focus)="dateAcceptationDevisFocus()"
                                        [matDatepicker]="datepickerAD"
                                        formControlName="dateAcceptationDevis"
                                        [disabled]="readonlyMode"
                                        [ngClass]="{ disabledDiv: readonlyMode }"
                                    />
                                    <mat-datepicker-toggle matSuffix [for]="datepickerAD"></mat-datepicker-toggle>
                                    <mat-datepicker #datepickerAD startView="multi-year"></mat-datepicker>
                                </mat-form-field>
                                <div class="d-flex col-xl-6 p-2 align-items-center">
                                    <mat-checkbox
                                        #nonRenseigneDateAcceptationDevis
                                        class="col-xl-6"
                                        color="primary"
                                        formControlName="dateAcceptationDevisNR"
                                        [disabled]="readonlyMode"
                                        (change)="dateAcceptationDevisNRchange($event.checked)"
                                        >Non renseignée
                                    </mat-checkbox>
                                    <app-notes-buttons
                                        class="mr-2"
                                        [from]="ceeModel.dateAcceptationDevis"
                                        [diagnostic]="diagnostic"
                                        [typesCommentaires]="COMMENT_TYPES"
                                    >
                                    </app-notes-buttons>
                                </div>
                            </div>
                            <div
                                *ngIf="nonRenseigneDateAcceptationDevis.checked === true && !ceeModel.dateAcceptationDevis?.commentairesId?.length"
                                class="to-justify all mr-2 mb-3"
                            >
                                Il manque une justification
                                <button
                                    mat-icon-button
                                    color="justify"
                                    class="ml-1"
                                    (click)="addJustification(ceeModel.dateAcceptationDevis, enumTypesCommentaire.JUSTIFICATION_NON_CONFORMITE)"
                                >
                                    <lib-icon theme="mdi" iconName="comment-alert"></lib-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!--DATE DEBUT DES TRAVAUX-->
                    <div
                        *ngIf="
                            diagnostic &&
                            (diagnostic.typePrestation.includes(TypePrestationEnum.BAREN101) ||
                                diagnostic.typePrestation.includes(TypePrestationEnum.BAREN102) ||
                                diagnostic.typePrestation.includes(TypePrestationEnum.BAREN103))
                        "
                        class="d-flex flex-column"
                    >
                        <div class="p-2">{{ ceeModel.getLabel('dateDebutTravaux') }}</div>
                        <div class="ml-2 flex-row">
                            <div class="d-flex justify-content-around align-items-center">
                                <mat-form-field class="ml-2 col-6">
                                    <mat-label>{{ ceeModel.getLabel('dateDebutTravaux') }}</mat-label>
                                    <input
                                        title="dateDebutTravaux"
                                        matInput
                                        [required]="!formGeneral?.get('dateDebutTravauxNR')?.value"
                                        (focus)="dateDebutTravauxFocus()"
                                        [matDatepicker]="datepickerDT"
                                        formControlName="dateDebutTravaux"
                                        [disabled]="readonlyMode"
                                        [ngClass]="{ disabledDiv: readonlyMode }"
                                    />
                                    <mat-datepicker-toggle matSuffix [for]="datepickerDT"></mat-datepicker-toggle>
                                    <mat-datepicker #datepickerDT startView="multi-year"></mat-datepicker>
                                </mat-form-field>
                                <div class="d-flex col-xl-6 p-2 align-items-center">
                                    <mat-checkbox
                                        #nonRenseigneDateDebutTravaux
                                        class="col-xl-6"
                                        color="primary"
                                        formControlName="dateDebutTravauxNR"
                                        [disabled]="readonlyMode"
                                        (change)="dateDebutTravauxNRchange($event.checked)"
                                        >Non renseignée
                                    </mat-checkbox>
                                    <app-notes-buttons
                                        class="mr-2"
                                        [from]="ceeModel.dateDebutTravaux"
                                        [diagnostic]="diagnostic"
                                        [typesCommentaires]="COMMENT_TYPES"
                                    >
                                    </app-notes-buttons>
                                </div>
                            </div>
                            <div
                                *ngIf="nonRenseigneDateDebutTravaux.checked === true && !ceeModel.dateDebutTravaux?.commentairesId?.length"
                                class="to-justify all mr-2 mb-3"
                            >
                                Il manque une justification
                                <button
                                    mat-icon-button
                                    color="justify"
                                    class="ml-1"
                                    (click)="addJustification(ceeModel.dateDebutTravaux, enumTypesCommentaire.JUSTIFICATION_NON_CONFORMITE)"
                                >
                                    <lib-icon theme="mdi" iconName="comment-alert"></lib-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                    <!--RÉFÉRENCE DE LA FACTURE-->
                    <div *ngIf="diagnostic?.typePrestation.includes('BARTH16')" class="d-flex flex-column">
                        <div class="p-2">{{ ceeModel.getLabel('refFacture') }}</div>
                        <div class="ml-2 flex-row">
                            <div class="d-flex justify-content-around align-items-center">
                                <mat-form-field [ngClass]="{ disabledDiv: readonlyMode }" class="ml-2 col-6">
                                    <mat-label>{{ ceeModel.getLabel('refFacture') }}</mat-label>
                                    <input
                                        matInput
                                        [required]="!formGeneral?.get('refFactureNR')?.value"
                                        (focus)="referenceDeLaFactureFocus()"
                                        formControlName="refFacture"
                                        [disabled]="readonlyMode"
                                    />
                                </mat-form-field>
                                <div class="d-flex col-xl-6 p-2 align-items-center">
                                    <mat-checkbox
                                        #nonRenseigneRefFacture
                                        class="col-xl-6"
                                        color="primary"
                                        formControlName="refFactureNR"
                                        [disabled]="readonlyMode"
                                        (change)="referenceDeLaFactureNRchange($event.checked)"
                                        >Non renseignée
                                    </mat-checkbox>
                                    <app-notes-buttons
                                        class="mr-2"
                                        [from]="ceeModel.refFacture"
                                        [diagnostic]="diagnostic"
                                        [typesCommentaires]="COMMENT_TYPES"
                                    >
                                    </app-notes-buttons>
                                </div>
                            </div>
                            <div
                                *ngIf="nonRenseigneRefFacture.checked === true && !ceeModel.refFacture?.commentairesId?.length"
                                class="to-justify all mr-2 mb-3"
                            >
                                Il manque une justification
                                <button
                                    mat-icon-button
                                    color="justify"
                                    class="ml-1"
                                    (click)="addJustification(ceeModel.refFacture, enumTypesCommentaire.JUSTIFICATION_NON_CONFORMITE)"
                                >
                                    <lib-icon theme="mdi" iconName="comment-alert"></lib-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                    <!--PRESENCE DEVIS-->
                    <div class="d-flex flex-column">
                        <div class="p-2 required">{{ ceeModel.getLabel('presenceDevis') }}</div>
                        <div class="ml-2 flex-row">
                            <div class="d-flex justify-content-around align-items-center">
                                <app-custom-select
                                    class="col-xl-6"
                                    [matFormFieldClass]="'w-100'"
                                    formControlName="presenceDevis"
                                    [selectControl]="formGeneral?.get('presenceDevis')"
                                    [choices]="choices"
                                    [filter]="false"
                                    [addline]="false"
                                    [disabled]="readonlyMode"
                                >
                                </app-custom-select>
                                <div class="d-flex col-xl-6 p-2 align-items-center">
                                    <app-notes-buttons
                                        class="mr-2"
                                        [from]="ceeModel.presenceDevis"
                                        [diagnostic]="diagnostic"
                                        [typesCommentaires]="COMMENT_TYPES"
                                    >
                                    </app-notes-buttons>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--PRESENCE FACTURE-->
                    <div class="d-flex flex-column">
                        <div class="p-2 required">{{ ceeModel.getLabel('presenceFacture') }}</div>
                        <div class="ml-2 flex-row">
                            <div class="d-flex justify-content-around align-items-center">
                                <app-custom-select
                                    class="col-xl-6"
                                    [matFormFieldClass]="'w-100'"
                                    formControlName="presenceFacture"
                                    [selectControl]="formGeneral?.get('presenceFacture')"
                                    [choices]="choices"
                                    [filter]="false"
                                    [addline]="false"
                                    [disabled]="readonlyMode"
                                >
                                </app-custom-select>
                                <div class="d-flex col-xl-6 p-2 align-items-center">
                                    <app-notes-buttons
                                        class="mr-2"
                                        [from]="ceeModel.presenceFacture"
                                        [diagnostic]="diagnostic"
                                        [typesCommentaires]="COMMENT_TYPES"
                                    >
                                    </app-notes-buttons>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
<input type="text" style="display: none" disabled autocomplete="new-password" />
