import { ChangeDetectorRef, Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { ArrayUtils, BaseComponent } from 'src/app/commons-lib';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormControl, FormGroup, NgControl } from '@angular/forms';
import { StateChoiceBoxes } from '../../../model/states.model';
import { notValidatedOption, validatedOption } from '../../../shared/constants/states.constants';

@Component({
    selector: 'app-state-input-reactive',
    templateUrl: './state-input-reactive.component.html',
    styleUrls: ['./state-input-reactive.component.scss'],
})
export class StateInputReactiveComponent extends BaseComponent implements ControlValueAccessor {
    radioForm: FormGroup;

    /**
     * Si on passe par reactive form
     */
    @Input()
    radioControl: FormControl | AbstractControl;

    /** Liste des choix */
    @Input() set choices(choices: StateChoiceBoxes[]) {
        this._choices = choices;
        if (this.choiceValueToSet) {
            this.setChoiceValue(this.choiceValueToSet, false);
            this.choiceValueToSet = null;
        }
    }

    /** Valeurs actuellement sélectionnées */
    @Input()
    set choiceValue(choiceValue: string | boolean | (string | boolean)[]) {
        if (this._choices) {
            this.setChoiceValue(choiceValue, false);
        } else {
            this.choiceValueToSet = [].concat(choiceValue);
        }
    }

    /** Sélection globale (multiline ?) */
    @Input()
    globalStateInput = false;

    /** Désactive ? */
    @Input()
    disabled = false;

    @Input()
    hideLabel = false;

    /** Afficher les grosses icônes et les labels ? */
    @Input()
    labels = false;

    /** Afficher ou non les icones */
    @Input()
    labelsAsIcons = false;

    /** Taille de la marge entre les éléments, entre 1 et 5 (par défaut "1" si label, "0" sinon) */
    @Input()
    spaceBetweenLabels: '0' | '1' | '2' | '3' | '4' | '5' | undefined = undefined;

    /** Multisélection */
    @Input()
    multiple = false;

    /** Si multisélection, choix exclusifs (si sélectionnés, annulent les autres) */
    @Input()
    exclusiveChoices: StateChoiceBoxes[] = [];

    /** Peut-on décocher un état ? */
    @Input()
    reset = false;

    /** Classe CSS sur le conteneur global (par défaut "px-2") */
    @Input()
    containerClass: any = 'px-2';

    /**
     * Afficher le champ en erreur
     * Facultatif
     */
    @Input()
    showError = true;

    @Input()
    showMessageError = false;

    /**
     * Définit si la couleur du bouton est réactive
     */
    @Input()
    isColorReactive: boolean = false;

    /** Changement de valeur */
    @Output()
    choiceValueChange = new EventEmitter<string | boolean | (string | boolean)[]>();

    /**
     * Changement de la valeur par utilisateur (non émit si le changement vient de l'@Input)
     */
    @Output()
    valueChangeFromUser = new EventEmitter<string | boolean | (string | boolean)[]>();

    _choices: StateChoiceBoxes[] = [validatedOption, notValidatedOption];
    currentChoicesValues: (string | boolean)[] = [];
    private choiceValueToSet: (string | boolean)[];

    private radioVal: string;

    constructor(
        @Self()
        @Optional()
        private ngControl: NgControl,
        private formBuilder: FormBuilder,
        private cdref: ChangeDetectorRef
    ) {
        super();
        this.radioForm = formBuilder.group({
            radio: this.radioControl,
        });
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        console.log('registerOnTouched', fn);
    }

    setDisabledState(isDisabled: boolean): void {}

    writeValue(obj: any): void {
        this.currentChoicesValues = obj;
    }

    onChange(value) {}

    getCombinedClass(choice: any, prefix: string) {
        let classes = {};
        if (choice && choice.label) {
            classes[`${prefix}-${choice.label}`] = true;
        }
        return classes;
    }

    private onTouched() {
        console.log('touched');
    }

    private setChoiceValue(choiceValue: string | boolean | (string | boolean)[], fromUser: boolean) {
        const choiceValues = [].concat(choiceValue);
        let changed = false;
        if (!ArrayUtils.arraysContainsSameElements(this.currentChoicesValues, choiceValues)) {
            changed = true;
        }
        this.currentChoicesValues = choiceValues;
        if (changed) {
            this.onChange(choiceValue);
            this.choiceValueChange.emit(choiceValue);
            if (fromUser) {
                this.onChange(choiceValue);
                this.valueChangeFromUser.emit(choiceValue);
            }
        }
    }

    public clickRadio(event: Event, value: any) {
        if (this.disabled) {
            return;
        }

        const exclusivesChoicesValues = this.exclusiveChoices.map((it) => it.value);
        if (exclusivesChoicesValues.includes(value)) {
            // Choix exclusif
            this.setChoiceValue(value, true);
        } else if (this.currentChoicesValues && this.currentChoicesValues.includes(value)) {
            // Choix non-exclusif déjà inclu dans les choix courants
            if (this.reset) {
                // Reset
                this.setChoiceValue(
                    [this.currentChoicesValues].flat().filter((it) => it != value),
                    true
                );
            } else {
                // Reset = false => Rien à faire, le choix est déjà sélectionné et on ne peut pas le décocher
            }
        } else {
            // Choix non-exclusif non inclu dans les choix courants
            if (this.multiple) {
                this.setChoiceValue(this.currentChoicesValues.filter((it) => !exclusivesChoicesValues.includes(it)).concat(value), true);
            } else {
                this.setChoiceValue(value, true);
            }
        }
        event.preventDefault();
        this.cdref.detectChanges();
    }

    public isRadioSelected(value: any) {
        return this.radioVal === value;
    }
}
