import { Equipement } from '../model/equipement.model';
import { Volume } from '../model/bien.model';

export class ListUtils {
    /**
     * Création d'un nom unique en fonction de la liste de noms
     * @param namesAlreadyPresents Liste des noms déjà présents
     * @param name Nom à tester et incrémenter en fonction
     */
    static createUniqueName(namesAlreadyPresents: string[], name: string): string {
        // Vérifie si le nom est déjà présent dans la liste de noms déjà présents
        const isInVolumesNamesAlreadyPresents =
            namesAlreadyPresents
                .map((x) => x.split(/(\d+)/)[0].trim().toLowerCase())
                .filter((it) => it === name.split(/(\d+)/)[0].trim().toLowerCase()).length > 0;

        let nameReturn = name;

        // Si il est déjà présent dans le tableau : générer des noms jusqu'à en trouver un de disponible.
        if (isInVolumesNamesAlreadyPresents) {
            let i = 2;
            let newName = `${name} ${i}`;
            while (namesAlreadyPresents.includes(newName)) {
                newName = `${name} ${i}`;
                i++;
            }
            nameReturn = newName;
        }

        return nameReturn;
    }

    /**
     * Création d'un nom unique en fonction de la liste de noms
     * @param elementAlreadyPresents Liste des elements déjà présents
     * @param element Element à tester et incrémenter en fonction
     */
    static createUniqueNameElementAControler(elementAlreadyPresents: Equipement[], element: Equipement): string {
        if (!element) {
            return null;
        }

        let nameReturn = '';
        nameReturn = element.name + ' 1';

        // Vérifie si le nom est déjà présent dans la liste de noms déjà présents
        let isInElementAlreadyPresents = false;

        isInElementAlreadyPresents =
            (elementAlreadyPresents as Equipement[])
                .map((elementTemp) => elementTemp.productTypeCodeBim)
                .filter((it) => it === (element as Equipement).productTypeCodeBim).length > 0;

        // Si il est déjà présent dans le tableau : générer des noms jusqu'à en trouver un de disponible.
        if (isInElementAlreadyPresents) {
            let i = 1;

            let newName = `${element.name} ${i}`;
            while ((elementAlreadyPresents as Equipement[]).find((typeElementTemp) => typeElementTemp.name === newName) != undefined) {
                newName = `${element.name} ${i}`;
                i++;
            }
            nameReturn = newName;
        }
        return nameReturn;
    }
}

export function isEmpty<T>(list: T[]) {
    return !list || !list.length;
}

export function wherePropEquals<T, V>(prop: string, val: any) {
    return (obj: T) => obj[prop] === val;
}
