<div class="toolbar">
    <mat-toolbar class="border-bottom-1 primary">
        <button mat-button class="px-0" (click)="back()">
            <lib-icon>arrow_back</lib-icon>
        </button>
        <div>
            <lib-icon [class]="'icon-before-text'">settings</lib-icon>
            <span>Gestion des catégories d'ouvrages de pièces</span>
        </div>
    </mat-toolbar>
</div>
<div class="flex-grow-1 main-background overflow-auto">
    <div class="p-4">
        <div class="entete-tableau-admin">
            <div class="d-flex">
                <div class="onglet-tab-admin mt-2 selected">Catégories d'ouvrages</div>
            </div>
            <div class="buttons d-flex justify-content-end">
                <button color="accent" mat-raised-button class="bt-valid" (click)="creerCategorieOuvrage()">
                    <lib-icon>add</lib-icon>
                    {{ "Créer une catégorie d'ouvrage" | uppercase }}
                </button>
                <input type="file" #fileInput (change)="uploadReferentiel($event, arbre)" style="display: none" />
                <button mat-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="fileInput.click()">
                        <span>Importer un nouveau référentiel</span>
                    </button>
                    <button mat-menu-item (click)="downloadReferentiel()">
                        <span>Télécharger le dernier référentiel importé</span>
                    </button>
                </mat-menu>
            </div>
        </div>
        <app-arbre-categories-ouvrage #arbre></app-arbre-categories-ouvrage>
    </div>
</div>
