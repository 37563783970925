<ng-container *ngIf="control?.touched">
    <ng-container *ngIf="control?.hasError('required')">
        {{ textConfig.required }}
    </ng-container>
    <ng-container *ngIf="control?.hasError('email')">
        {{ textConfig.email }}
    </ng-container>
    <ng-container *ngIf="control?.hasError('pattern')">
        {{ textConfig.pattern }}
    </ng-container>
    <ng-container *ngIf="control?.hasError('erreurNomDoc')">
        {{ textConfig.erreurNomDoc }}
    </ng-container>
    <ng-container *ngIf="control?.hasError('erreurNomPerimetreExistant')"
        >{{ textConfig.erreurNomPerimetreExistant }}
    </ng-container>
    <ng-container *ngIf="control?.hasError('referenceExistante')">{{ textConfig.referenceExistante }} </ng-container>
    <ng-container *ngIf="control?.hasError('latitudeError')">{{ textConfig.latitudeError }} </ng-container>
    <ng-container *ngIf="control?.hasError('longitudeError')">{{ textConfig.longitudeError }} </ng-container>
    <ng-container *ngIf="control?.hasError('matDatepickerParse')">{{ textConfig.matDatepickerParse }} </ng-container>
</ng-container>
