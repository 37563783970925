/**
 * Wrapper class.
 * À utiliser pour pipe async sur observable.
 */
export class WrappedValue<T> {
    constructor(private _value: T) {}

    get value(): T {
        return this._value;
    }
}
