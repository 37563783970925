import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { OfflineStorageIndexeddbService } from './offline-storage-indexeddb.service';

/**
 * Type utilisé pour le stockage des fichiers de documents ajoutés, mis à jour ou supprimés.
 */
// export interface FichierDocumentLocal {
//     id?: number;
//     idIntervention: string;
//     idDocument: string;
//     /** Id du fichier, nul pour la suppression d'image */
//     fileId?: string;
//     /** Nom du fichier, nul pour la suppression d'image */
//     fileName?: string;
//     /** Contenu du fichier en base 64, nul pour la suppression d'image */
//     fileContent?: string;
//     /** Indique s'il s'agit d'un document mis à jour */
//     toUpdate: string;
//     /** Indique si le document doit être supprimé lors de la synchro */
//     toDelete: string;
//     /** Date de l'upload */
//     dateUpload: string;
// }

/**
 * Service de stockage et des récupération des données interventions dans la base de données locale.
 *
 * ATTENTION : Les méthodes de cette classe ne sont pas thread-safe, les appelants doivent être attentifs à la synchronisation des appels !
 */
// TODO Réimplementer mode HL pour les documents
@Injectable({
    providedIn: 'root',
})
export class OldOfflineStorageService {
    constructor(private offlineStorageIndexeddbService: OfflineStorageIndexeddbService) {}

    // ********************************************************************
    // STOCKAGE DE FICHIERS DE DOCUMENTS
    // ********************************************************************

    /**
     * Renvoie les fichiers de documents locaux ajouté et non-synchronisés, avec leur contenu (sous forme de dataURI).
     */
    // getUpdatedFichiersDocumentsLocally(): Observable<FichierDocumentLocal[]> {
    //     return this.offlineStorageIndexeddbService.getFichierDocumentUpdates();
    // }

    // getUpdatedFichiersDocumentsByIterventionId(idIntervention: string): Observable<FichierDocumentLocal[]> {
    //     return this.offlineStorageIndexeddbService
    //         .getFichierDocumentByIndex('idIntervention', idIntervention)
    //         .pipe(map((fichiersDocs) => fichiersDocs.filter((fd) => fd.toUpdate === 'true')));
    // }

    /**
     * Supprime de la base local les fichiers de documents synchronisés qui été flagués toDelele, passe le flag toUpdate
     * à false pour les fichiers de documents qui étaient à uploader
     */
    // updateProcessedFichiersDocumentsLocally(
    //     fichierDocuments: FichierDocumentLocal[]
    // ): Observable<FichierDocumentLocal[]> {
    //     return forkJoin(
    //         fichierDocuments.map((fdl) => {
    //             if (fdl.toDelete === 'true') {
    //                 return this.offlineStorageIndexeddbService.deleteFichierDocument(fdl);
    //             } else {
    //                 fdl.toUpdate = 'false';
    //                 return this.offlineStorageIndexeddbService.updateFichierDocument(fdl);
    //             }
    //         })
    //     ).pipe(map(() => fichierDocuments));
    // }

    /**
     * Sauvegarde un ajout de fichier de docuement en local.
     *
     * Pour un ajout file et fileId doivent être renseignés.
     */
    // saveFichierDocumentUpdateLocally(
    //     idIntervention: string,
    //     idDocument: string,
    //     file: File = null,
    //     fileId: string = null,
    //     dateUpload: string
    // ): Observable<void> {
    //     return this.offlineStorageIndexeddbService.getFichierDocumentByIdDocument(idDocument).pipe(
    //         switchMap((fichierDocuments) => {
    //             const currentFichier = fichierDocuments.find((fd) => fd.toDelete === 'false');
    //             return this.deleteFichierDocumentLocaly(currentFichier);
    //         }),
    //         switchMap(() => this.getFileContent(file, fileId)),
    //         switchMap((fileContent) =>
    //             this.offlineStorageIndexeddbService.saveFichierDocument({
    //                 idIntervention,
    //                 idDocument,
    //                 fileId,
    //                 fileContent,
    //                 fileName: this.getFileName(file),
    //                 toUpdate: 'true',
    //                 toDelete: 'false',
    //                 dateUpload,
    //             })
    //         ),
    //         // Ajoute également le fichier de document dans le document de l'intervention stocké localement
    //         switchMap(() =>
    //             this.updateFileIdIntoDocument(idIntervention, idDocument, fileId, file).pipe(map(() => null))
    //         )
    //     );
    // }

    /**
     * Supprime le fichier de docuement local s'il n'a jamais été synchronisé ou la marque comme à supprimer
     */
    // deleteFichierDocumentLocaly(
    //     fichierDocument: FichierDocumentLocal,
    //     force: boolean = false
    // ): Observable<FichierDocumentLocal> {
    //     if (fichierDocument) {
    //         if (force || fichierDocument.toUpdate === 'true') {
    //             return this.offlineStorageIndexeddbService.deleteFichierDocument(fichierDocument);
    //         } else {
    //             fichierDocument.toUpdate = 'true';
    //             fichierDocument.toDelete = 'true';
    //             return this.offlineStorageIndexeddbService.updateFichierDocument(fichierDocument);
    //         }
    //     }
    //     return of(null);
    // }

    /**
     * Supprime un fichier de docuement localement.
     * Met à nul le idFichier du docuement de l'intervention.
     */
    // deleteFichierDocumentByIdDocument(
    //     idIntervention: string,
    //     idDocument: string,
    //     force: boolean = false
    // ): Observable<void> {
    //     return this.offlineStorageIndexeddbService.getFichierDocumentByIdDocument(idDocument).pipe(
    //         switchMap((fichiersDocuments) => {
    //             const currentFichier = fichiersDocuments.find((ib) => ib.toDelete === 'false');
    //             return this.deleteFichierDocumentLocaly(currentFichier, force);
    //         }),
    //         // Supprime également l'image de bien dans le bien stocké localement
    //         switchMap(() => {
    //             if (!force) {
    //                 return this.updateFileIdIntoDocument(idIntervention, idDocument, null, null).pipe(map(() => null));
    //             } else {
    //                 return of(null);
    //             }
    //         })
    //     );
    // }

    /**
     * Met à jour l'id de fichier de docuement pour le docuement de l'intervention.
     * Si le fichier à été supprimer passer un fileId nul
     */
    // updateFileIdIntoDocument(
    //     idIntervention: string,
    //     idDocument: string,
    //     fileId: string,
    //     file: File
    // ): Observable<Intervention> {
    //     return this.offlineStorageIndexeddbService.getInterventionById(idIntervention).pipe(
    //         switchMap((intervention) => {
    //             if (intervention) {
    //                 const document = intervention.documents.find((it) => it.id === idDocument);
    //                 if (document) {
    //                     document.idFichier = fileId;
    //                     document.nomFichier = this.getFileName(file);
    //                     // intervention.toUpdate = 'true';
    //                     return this.offlineStorageIndexeddbService.updateIntervention(intervention);
    //                 }
    //                 return of(null);
    //             }
    //             return of(null);
    //         })
    //     );
    // }

    // ********************************************************************
    // STOCKAGE DE LA DATE DU DERNIER PASSAGE HORS-LIGNE
    // ********************************************************************

    setOfflineDate(value: Date): Observable<Date> {
        return this.offlineStorageIndexeddbService.setOfflineDate(value);
    }

    getOfflineDate(): Observable<Date> {
        return this.offlineStorageIndexeddbService.getOfflineDate();
    }

    deleteOfflineDate(): Observable<boolean> {
        return this.offlineStorageIndexeddbService.deleteOfflineDate();
    }

    // ********************************************************************
    // Utils
    // ********************************************************************

    /**
     * Renvoie le nom du fichier, ou null si le fichier est nul.
     */
    // private getFileName(file: File = null) {
    //     return file ? file.name : null;
    // }

    /**
     * Renvoie le contenu du fichier sous forme de Data URI.
     * Si file et fileId sont nuls, le contenu est renvoyé nul.
     * Si file est renseigné mais fileId est nul, ou vice-versa, une exception est renvoyée.
     */
    // private getFileContent(file: File = null, fileId: string = null): Observable<string> {
    //     if ((file != null && fileId == null) || (file == null && fileId != null)) {
    //         throw Error('file et fileId doivent être soit tous les deux nuls, soit tous les deux renseignés');
    //     }
    //     if (file == null && fileId == null) {
    //         return of(null);
    //     }
    //     return FileUtils.fileToDataURI(file);
    // }
}
