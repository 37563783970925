export class cn_wall_metrics {

    constructor(wall, storey) {
        this.height = 0;
        /** Hottest side length (shall be interior) */
        this.side_length_1 = 0;
        /** Coldest side length (shall be exterior) */
        this.side_length_2 = 0;
        this.axis_length = 0;
        this.thickness = 0;

        this.gross_footprint_area = 0;
        this.net_footprint_area = 0;

        this.gross_side_area_1 = 0;
        this.net_side_area_1 = 0;
        this.gross_side_area_2 = 0;
        this.net_side_area_2 = 0;

        this.gross_side_exchange_area = 0;
        this.net_side_exchange_area = 0;

        this.gross_volume = 0;
        this.net_volume = 0;

        const s0 = (wall.get_flow_direction()) ? 0 : 1;
        const s1 = 1 - s0;

        const polygons = [wall.build_side_polygon(0, storey), wall.build_side_polygon(1, storey)];

        const exchange_polygon = polygons[0].clone();
        exchange_polygon.intersects(polygons[1]);

        const solid_wall = wall.build_3d_solid(storey);

        // Base dimensions
        this.height = solid_wall.get_bounding_box().size[2];

        this.side_length_1 = wall.get_length(s0);
        this.side_length_2 = wall.get_length(s1);
        this.axis_length = wall.bounds.length;

        this.thickness = wall.wall_type.thickness;

        // Footprint areas
        let footprint = wall.build_footprint(0).get_area();
        this.gross_footprint_area = footprint;
        wall.openings.filter(op => op.valid && op.opening_type.z === 0).forEach(op => footprint -= wall.wall_type.thickness * op.opening_type.width);
        this.net_footprint_area = footprint;

        // Gross sides areas
        this.gross_side_area_1 = polygons[s0].get_area();
        this.gross_side_area_2 = polygons[s1].get_area();
        this.gross_side_exchange_area = exchange_polygon.get_area();

        // Net sides areas
        wall.remove_openings_from_polygon(polygons[0], storey);
        wall.remove_openings_from_polygon(polygons[1], storey);
        wall.remove_openings_from_polygon(exchange_polygon, storey);
        this.net_side_area_1 = polygons[s0].get_area();
        this.net_side_area_2 = polygons[s1].get_area();
        this.net_side_exchange_area = exchange_polygon.get_area();

        // Volumes
        const gross_volume = wall.build_3d_solid(storey, false);
        this.gross_volume = gross_volume.get_volume();
        this.net_volume = solid_wall.get_volume();

    }

}
