import { ContenuDiagnostic } from '../../../../model/diagnostic-contenu.model';
import { EtapeDiagnosticGenerique } from '../../../../model/diagnostic-etape.model';
import { AnnexeData, DocumentData, IDiagReportData, ISubBlock, ReportagePhotoData } from '../../../../model/rapport.model';
import { TypePrestation } from '../../../../model/type-prestation.model';
import { Commentaire } from '../../../../model/commentaire.model';
import { Conformite } from '../../../../model/conformite.model';
import { PointDeControleElement } from '../../../../model/point-de-controle.model';
import { ConclusionElement } from '../../../../model/conclusion.model';
import { ScreenshotSvg } from 'src/app/model/screenshot-svg.model';
import { EtatDiagnostic } from 'src/app/model/diagnostic.model';

export type ConclusionComponentMode = 'reseauEu' | 'reseauEp' | 'autre';

export const etatsList: { label: string; value: string }[] = [
    { label: 'Incomplet', value: 'incomplet' },
    { label: 'A justifier', value: 'a-justifier' },
];

export class Gaz implements ContenuDiagnostic {
    // NB : il faut bien donner des valeurs par défaut aux différentes étapes, pour l'intialisation du diagnostic
    gazTest: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
}

/**
 * GazReportData
 *
 * @title Diagnostic Gaz
 * @description Contient toutes les informations relatives à un diagnostic Gaz
 */
export class GazReportData implements IDiagReportData {
    /**
     * Id
     *
     * @title Id
     * @description Id du diagnostic Gaz
     */
    id: string;

    /**
     * Type prestation
     *
     * @title Type de la prestation
     * @description Type de prestation du diagnostic Gaz
     */
    typePrestation: TypePrestation;

    /**
     * Référence du rapport
     *
     * @title Référence du rapport
     * @description Référence du rapport
     */
    refRapport: string;

    /**
     * Option plan
     *
     * @title Option plan
     * @description L'option plan est-elle activée pour ce diagnostic Gaz. En fonction, l'opérateur devra réaliser un plan du/des biens
     */
    optionPlan: boolean;

    /**
     * Age Installation
     *
     * @title Age de l'installation
     * @description Age de l'installation Gaz
     */
    ageInstallation: string;

    /**
     * Caracteristiques
     *
     * @title Caracteristiques de l'Gaz
     * @description Contient toutes les caractéristique de l'Gaz
     */
    caracteristiques: CaracteristiquesData;

    /**
     * Type Gaz Communataire
     *
     * @title Type d'Gaz
     * @description Le type d'Gaz communautaire
     */
    typeGazCommunataire: string;

    /**
     * Regards EU
     *
     * @title Nombre de regards d'eau usée
     * @description Nombre de regards d'eau usée
     */
    regardsEU: string;

    /**
     * Regards EP
     *
     * @title Nombre de regards d'eau pluviale
     * @description Nombre de regards d'eau pluviale
     */
    regardsEP: string;

    /**
     * Non visibles
     *
     * @title Liste des éléments non visibles
     * @description Correspond à la liste des éléments non visibles
     */
    nonVisibles: SubBlockGaz;

    /**
     * Volumes visités
     *
     * @title Liste des volumes visités
     * @description Correspond à la liste des volumes visités
     */
    volumesVisites: SubBlockGaz;

    /**
     * Volumes non visités
     *
     * @title Liste des volumes non visités
     * @description Correspond à la liste des volumes non visités
     */
    volumesNonVisites: SubBlockGaz;

    /**
     * Raccordement EU
     *
     * @title Résultats raccordements aux Eaux Usés
     * @description Résultats des points de contrôles pour les éléments raccordés aux Eaux Usés
     */
    raccordementEU: SubBlockGaz;

    /**
     * Équipements EU
     *
     * @title Résultats équipements aux Eaux Usés
     * @description Résultats des points de contrôles pour les équipements Eaux Usés
     */
    equipementsEU: SubBlockGaz;

    /**
     * Raccordement EP
     *
     * @title Résultats raccordements aux Eaux Pluviales
     * @description Résultats des points de contrôles pour les éléments raccordés aux Eaux Pluviales
     */
    raccordementEP: SubBlockGaz;

    /**
     * Équipements EP
     *
     * @title Résultats équipements aux Eaux Pluviales
     * @description Résultats des points de contrôles pour les équipements Eaux Pluviales
     */
    equipementsEP: SubBlockGaz;

    /**
     * Constatations Specifiques
     *
     * @title Constatations Specifiques
     * @description Contient toutes les Constatations Specifiques relatives à un diagnostic Gaz
     */
    constatationsSpecifiques: SubBlockGaz;

    /**
     * Conclusion Raccordements EU
     *
     * @title Conclusion Raccordements EU
     * @description Contient toutes les conclusions liées aux équipements raccordés aux Eaux Usées
     */
    conclusionRaccordementsEU: SubBlockGaz;

    /**
     * Conclusion Équipements EU
     *
     * @title Conclusion Équipements EU
     * @description Contient toutes les conclusions liées aux équipements Eaux Usées
     */
    conclusionEquipementsEU: SubBlockGaz;

    /**
     * Conclusion Raccordements EP
     *
     * @title Conclusion Raccordements EP
     * @description Contient toutes les conclusions liées aux équipements raccordés aux Eaux Pluviales
     */
    conclusionRaccordementsEP: SubBlockGaz;

    /**
     * Conclusion Équipements EP
     *
     * @title Conclusion Équipements EP
     * @description Contient toutes les conclusions liées aux équipements Eaux Pluviales
     */
    conclusionEquipementsEP: SubBlockGaz;

    /**
     * Recommandations
     *
     * @title Recommandations
     * @description Contient toutes les recommandations relatives à ce diagnostic Gaz
     */
    recommandations: string[] = [];

    /**
     * Constatations diverses
     *
     * @title Constatations diverses
     * @description Contient toutes les constatations diverses relatives à ce diagnostic Gaz
     */
    constatationsDiverses: string[] = [];

    /**
     * Conformite globale
     *
     * @title Conformite globale
     * @description Conformite globale de ce diagnostic Gaz
     */
    conformiteGlobale: boolean;

    /**
     * Documents Data
     *
     * @title Documents Data
     * @description Contient tous les documents liés à ce diagnostic Gaz
     */
    documentsData: DocumentData[] = [];

    /**
     * List Documents
     *
     * @title Liste des documents
     * @description Liste des documents liés à ce diagnostic Gaz
     */
    listDocuments: DocumentData[] = [];

    /**
     * Annexes
     *
     * @title Annexes
     * @description Annexes liées à ce diagnostic Gaz
     */
    annexes: AnnexeData[] = [];

    /**
     * Reportages Photos
     *
     * @title Reportages Photos
     * @description Contient tous les reportages photos de ce diagnostic Gaz
     */
    reportagesPhotos: ReportagePhotoData[];

    /**
     * Screenshots Plan
     *
     * @title Captures de plan
     * @description Contient toutes les captures de plan de ce diagnostic Gaz
     */
    screenshotsPlan: ScreenshotSvg[];

    /**
     * Etat du diagnostic
     *
     * @title Etat du diagnostic
     * @description L'état du diagnostic : EN_ATTENTE, TERMINE, FINI; Si tous les diagnostics sont fini ou annulé, l'intervention passe à terminé, et tous les diagostics passent à terminé.
     */
    etat: EtatDiagnostic;
}

export class SubBlockGaz implements ISubBlock {
    code: string;
    biens: RowBienGaz[] = [];

    constructor(code: string, biens: RowBienGaz[]) {
        this.code = code;
        this.biens = biens;
    }
}

export class RowBienGaz {
    id: string;
    bien: string;
    niveaux: RowNiveauGaz[] = [];

    constructor(id: string, nom: string) {
        this.id = id;
        this.bien = nom;
    }
}

export class RowNiveauGaz {
    id: string;
    niveau: string;
    storeyId?: string = null;
    volumes: RowVolumeGaz[] = [];

    constructor(id: string, nom: string, storeyId: string) {
        this.id = id;
        this.niveau = nom;
        this.storeyId = storeyId;
    }
}

export class RowVolumeGaz {
    volume: string;
    volumeId: string;
    plans: PlanRapport[] = [];
    visite: ItemElementGaz;
    equipements: RowElementGaz[] = [];
    commentaires: Commentaire[] = [];

    constructor(nom: string, volumeId: string) {
        this.volume = nom;
        this.volumeId = volumeId;
    }
}

export class RowElementGaz {
    conformite: ItemElementGaz;
    element: string;
    materiaux?: ItemElementGaz;
    etat?: ItemElementGaz;
    ecoulement?: ItemElementGaz;
    controle?: ItemElementGaz;
    odeur?: ItemElementGaz;
    rejet?: ItemElementGaz;
    nonVisibles?: string[] = [];
    commentaires: Commentaire[] = [];
}

export class ItemElementGaz {
    valeur: string = null;
    style: string = null;

    constructor(valeur: string, style?: string) {
        this.valeur = valeur;
        this.style = style;
    }
}

export class PlanRapport {
    id: string;
    nom: string;

    constructor(id: string, nom: string) {
        this.id = id;
        this.nom = nom;
    }
}

/**
 * CaracteristiquesData
 *
 * @title Caractéristiques du diagnostic assaisnissement
 * @description Contient toutes les caractéristiques
 */
export class CaracteristiquesData {
    /**
     * Type de bâtiment
     *
     * @title Type de bâtiment
     * @description Type de bâtiment du bien concerné
     */
    typeBatiment: Caracteristique;

    /**
     * Alimentation en eau
     *
     * @title Alimentation en eau
     * @description Présence ou non de l'alimentation en eau
     */
    alimentationEau: Caracteristique;

    /**
     * Type de réseau
     *
     * @title Type de réseau
     * @description Type de réseau du bien concercé
     */
    typeReseau: Caracteristique;

    /**
     * Documents liés au diagnostic Gaz
     *
     * @title Documents liés au diagnostic Gaz
     * @description Documents liés au diagnostic Gaz (ex. réglement d'Gaz)
     */
    documentsCaracteristique: Caracteristique;

    /**
     * Documents d'implantation
     *
     * @title Documents d'implantation
     * @description Documents d'implantation
     */
    documentsImplantation: Caracteristique;

    /**
     * Réseau Public d'Eau Potable
     *
     * @title Réseau public d'eau potable
     * @description Le terrain est-il desservi par un réseau public d'eau potable ?
     */
    reseauPublicEauPotable: Caracteristique;

    /**
     * Captage d'eau
     *
     * @title Captage d'eau
     * @description Y a-t-il présence d'un captage d'eau ?
     */
    captageEau: Caracteristique;

    /**
     * Poste de refoulement
     *
     * @title Poste de refoulement
     * @description Y a-t-il présence d'un poste de refoulement ?
     */
    posteRefoulement: Caracteristique;

    /**
     * Clapet anti-retour en contrebas
     *
     * @title Clapet anti-retour en contrebas
     * @description Y a-t-il présence d'un Clapet anti-retour en contrebas ?
     */
    clapetAntiRetourContrebas: Caracteristique;

    /**
     * Ancienne Fosse
     *
     * @title Présence d'une ancienne fosse septique
     * @description Y a-t-il présence d'une ancienne fosse septique ?
     */
    ancienneFosse: Caracteristique;

    /**
     * Ventilation générale
     *
     * @title Ventilation générale
     * @description Y a-t-il présence d'une ventilation générale ?
     */
    ventilationGenerale: Caracteristique;
}

/**
 * Caracteristique
 *
 * @title Caracteristique
 * @description Contient toutes les informations d'une caractéristique
 */
export class Caracteristique {
    /**
     * Label
     *
     * @title Label
     * @description Label de la caractéristique
     */
    label: string;

    /**
     * État
     *
     * @title État
     * @description État de la caractéristique
     */
    etat: ItemElementGaz;

    /**
     * Precisions
     *
     * @title Précisions
     * @description Tableau de précisions sur la caractéristique
     */
    precisions: string[] = [];

    constructor(label: string, etat: ItemElementGaz, precisions?: string[]) {
        this.label = label;
        this.etat = etat;
        this.precisions = precisions;
    }
}

export class ConclusionGaz extends ConclusionElement {
    conformite: Conformite;
    incomplete = false;
    commentary = false;
    errors: string;
    defauts: string[] = [];
    equipement: PointDeControleElement;
}

export const LABEL_METADATA_KEY = 'label';

function Label(label: string) {
    return (target: Object, propertyKey: string) => {
        Object.defineProperty(target, `${LABEL_METADATA_KEY}_${propertyKey}`, {
            value: label,
            enumerable: false, // Make it non-enumerable to avoid issues
        });
    };
}
