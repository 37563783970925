import { Component, Input, OnInit } from '@angular/core';

/**
 * Composant destiné à contenir un formulaire : contenu centré avec une largeur limitée.
 * Si enabled = false, le contenu prend la totalité de la largeur.
 * Si enabled = true et smallMode = false, le contenu prend une largeur 8/12 (large), 10/12 (medium) ou la totalité de la largeur (small).
 * Si enabled = true et smallMode = true, le contenu prend une largeur 5/12 (large), 7/12 (medium) ou 9/12 (small).
 */
@Component({
    selector: 'lib-form-container',
    templateUrl: './form-container.component.html',
    styleUrls: ['./form-container.component.scss'],
})
export class FormContainerComponent implements OnInit {
    @Input() enabled = true;

    @Input() smallMode = false;

    constructor() {}

    ngOnInit() {}
}
