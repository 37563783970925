import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { URL_GESTION_AGENCES_EDIT } from '../../../shared/constants/url.constants';

@Component({
    selector: 'app-gestion-agences',
    templateUrl: './gestion-agences.component.html',
    styleUrls: ['./gestion-agences.component.scss'],
})
export class GestionAgencesComponent implements OnInit {
    enableButtonCreation = false;

    constructor(private readonly router: Router) {}

    ngOnInit(): void {}

    onClickCreateAgence(): void {
        this.router.navigate([URL_GESTION_AGENCES_EDIT]);
    }
}
