<h1 mat-dialog-title class="primary d-flex justify-content-between">
    <span>Sélectionner la partie d'ouvrage</span>
    <button mat-button type="button" (click)="onClickCancel()">
        <lib-icon>close</lib-icon>
    </button>
</h1>

<div mat-dialog-content>
    <!-- Type d'ouvrage -->
    <div class="d-flex align-content-start flex-wrap overflow-auto height-350">
        <!-- On découpe la liste des ouvrages en tableaux de 6 pour les afficher de façon régulière -->
        <ng-container
            *ngFor="let ouvrageForLine of array(math.ceil(listeTypeOuvrage.length / 6)).fill(6); let i = index"
        >
            <div class="row w-100 m-0 pt-2">
                <ng-container *ngFor="let typeOuvrage of listeTypeOuvrage?.slice(6 * i, 6 * i + 6)">
                    <div class="col-2">
                        <app-card-clickable
                            [isSelected]="ouvrageSelected && ouvrageSelected.id === typeOuvrage.id"
                            [label]="typeOuvrage.name"
                            (selectCardElement)="onClickCard(typeOuvrage)"
                        ></app-card-clickable>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-button type="button" color="primary" (click)="onClickCancel()">
        <lib-icon>close</lib-icon>
        <span>Annuler</span>
    </button>
    <button mat-raised-button color="accent" (click)="onClickValider()" [disabled]="!ouvrageSelected">
        <lib-icon>check</lib-icon>
        <span>Valider</span>
    </button>
</div>
