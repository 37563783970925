import * as moment from 'moment';
import { DATE_FORMAT_INTERNATIONAL } from '../shared/constants/cndiag.constants';

/**
 * Filtre une liste d'objets ayant des dates de debut validité et optionnellement une date d'échéance.
 * @param data le tableau à filtrer
 * @param dateDebut le nom de la propriété de date début
 * @param dateFin le nom de la propriété de date fin
 */
export function filterByCurrentDate<T>(data: T[], dateDebut: keyof T, dateFin: keyof T) {
    return data.filter((item) => {
        if (item[dateFin]) {
            return moment().isBetween(item[dateDebut], item[dateFin], 'day', '[]');
        } else {
            return moment().isSameOrAfter(item[dateDebut], 'day');
        }
    });
}
