<div class="markdown-content pb-5 pt-2">
    <div class="block-title p-2 pl-5 my-3 d-inline-block" *ngIf="data">Périmètre d'investigation</div>

    <ng-template ngFor let-perimetre [ngForOf]="reportData.perimetres">
        <div class="px-5 mt-2 block-markdown text-justify">
            <span class="subtittle variable">{{ perimetre.nom }}</span>
            <br />
            <span style="white-space: pre-line">{{ perimetre.description }}</span>
        </div>
        <div class="position-relative p-0 mb-2 svg-plan" *ngFor="let plan of perimetre.plans">
            <div class="bold px-5 mt-2 block-markdown text-justify" style="text-align-last: right">
                <span class="subtittle variable">{{ plan.niveau }}</span>
            </div>
            <div class="svg-preview" [innerHTML]="plan.jsonPlan | safeHtml"></div>
        </div>
    </ng-template>
    <!--br/>
    <div class="px-5 mt-2 block-markdown text-justify">
        <div class="svg-preview w-50 h-100" [innerHTML]="screenshot.jsonPlan | safeHtml"></div>

        <span class="miss">INSERTION DU PLAN / CROQUIS</span>
    </div>
    <br/>
    <div class="px-5 mt-2 block-markdown text-justify">
        <span class="miss">Gestion des exclusions du périmètre</span>
    </div> -->
</div>
