<div class="container resume pt-4">
    <div class="row" *ngFor="let item of ITEMS">
        <div class="col">
            <mat-card class="px-2 py-2 d-flex flex-column justify-content-between">
                <mat-card-title>
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex">
                            <div class="cercle {{ haslementsAControler(item.codeBim) ? 'green' : 'red' }}"></div>
                            <app-section-title
                                class="ml-2"
                                [label]="item.libelle"
                                [showHelp]="false"
                            ></app-section-title>
                        </div>
                        <app-notes-buttons
                            class="mr-2"
                            [from]="diagnostic.pointsDeControleBiens[0].valeursParametres[item.codeBim]"
                            [diagnostic]="diagnostic"
                        >
                        </app-notes-buttons>
                    </div>
                </mat-card-title>
                <mat-card-content>
                    <ng-template ngFor let-niveau [ngForOf]="niveaux">
                        <ng-template ngFor let-volume [ngForOf]="niveau.volumes">
                            <ng-template ngFor let-element [ngForOf]="volume.elementsAControler">
                                <div
                                    *ngIf="element.codeBim == item.codeBim"
                                    class="col-xl-4 mat-subheading-2 m-0 primary required"
                                >
                                    <b>{{ element.nom }}</b> ({{ niveau.nom }} - {{ volume.nom }})
                                </div>
                            </ng-template>
                        </ng-template>
                    </ng-template>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
