<h1 mat-dialog-title class="epi-title">Alerte EPI</h1>

<div mat-dialog-content>
    <div>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        <p>Aliquam consequat lacus sed nibh finibus, sit amet pulvinar sapien rutrum.</p>
        <p>In scelerisque nisi eget hendrerit feugiat.</p>
    </div>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-raised-button color="accent" (click)="confirm()">
        <span>{{ 'Ok' | uppercase }}</span>
    </button>
</div>
