/**
 * Icone
 */
export class Icone {
    id: string;

    theme: string;

    nom: string;

    couleur?: Couleur;
}

export enum Couleur {
    GREEN,
    RED,
    BLUE,
    ORANGE,
    YELLOW,
    GREY,
    WHITE,
    BLACK,
}
