import { Component, Inject } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { OuvrageAControler } from '../../../../../model/categorie-ouvrage.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

class BienModalOuvragesEditNomData {
    ouvrageToEdit: OuvrageAControler;
    listNomsOuvrages: string[];
}

@Component({
    selector: 'app-description-bien-modal-ouvrages-edit-nom',
    templateUrl: './description-bien-modal-ouvrages-edit-nom.component.html',
    styleUrls: ['./description-bien-modal-ouvrages-edit-nom.component.scss'],
})
export class DescriptionBienModalOuvragesEditNomComponent extends BaseComponent {
    form: FormGroup;

    constructor(
        private dialogRef: MatDialogRef<DescriptionBienModalOuvragesEditNomComponent>,
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: BienModalOuvragesEditNomData
    ) {
        super();
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            nom: [
                this.data.ouvrageToEdit?.nom,
                [
                    Validators.required,
                    // Validateur d'unicité de nom du niveau
                    (control: AbstractControl) => {
                        if (this.data.listNomsOuvrages?.includes(control.value.toLocaleUpperCase().trim())) {
                            return { erreurNomOuvrage: true };
                        }
                        return null;
                    },
                ],
            ],
        });
    }

    confirm() {
        this.dialogRef.close(this.form.get('nom').value);
    }

    close() {
        this.dialogRef.close(false);
    }
}
