import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/commons-lib';
import { SESSION_STORAGE_KEY_CONTACT, SESSION_STORAGE_KEY_CONTACT_EDIT } from 'src/app/shared/constants/admin.constants';
import { URL_GESTION_CONTACTS_EDIT } from 'src/app/shared/constants/url.constants';
import { SessionStorageUtils } from 'src/app/utils/session-storage.utils';

@Component({
    selector: 'app-gestion-contacts',
    templateUrl: './gestion-contacts.component.html',
    styleUrls: ['./gestion-contacts.component.scss'],
})
export class GestionContactsComponent extends BaseComponent implements OnInit, OnDestroy {
    typeContactCourant: 'PERSONNES' | 'ENTREPRISES' = 'PERSONNES';

    constructor(private readonly router: Router) {
        super();
    }

    ngOnInit(): void {}

    creerContact() {
        this.router.navigateByUrl(URL_GESTION_CONTACTS_EDIT, {
            state: { estPersonneMorale: this.typeContactCourant === 'ENTREPRISES' },
        });
    }

    typeContactChange(newTypeContact: 'PERSONNES' | 'ENTREPRISES') {
        this.typeContactCourant = newTypeContact;
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
