import { Pipe, PipeTransform } from '@angular/core';
import { EtatIntervention, etatInterventionToLabel } from '../model/intervention.model';

/**
 * Affiche le label de l'état d'une intervention
 */
@Pipe({
    name: 'etatIntervention',
})
export class EtatInterventionPipe implements PipeTransform {
    constructor() {}

    transform(etatIntervention: EtatIntervention): string {
        return etatInterventionToLabel(etatIntervention);
    }
}
