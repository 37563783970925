import { Component, Injector, OnInit } from '@angular/core';
import { MpcaItemOld } from '../../../../model/mpca.model.old';
import * as clone from 'clone';
import { PolluantComponent } from '../../../../utils/polluant-component';

@Component({
    selector: 'app-mpca-listing',
    templateUrl: './mpca-listing.component.html',
    styleUrls: ['./mpca-listing.component.scss'],
})
export class MpcaListingComponent extends PolluantComponent implements OnInit {
    mpcaList: MpcaItemOld[] = [];
    mpcaItemSelected: MpcaItemOld;
    mpcaIndexSelected: number;

    /**
     * @description deep clone du MPCA Item
     * @param mpcaItem
     * @private
     */
    static deepCloneMpca<T>(mpcaItem: MpcaItemOld): MpcaItemOld {
        return clone<T>(mpcaItem);
    }

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {}

    /**
     * restaurer les valeurs MPCA déjà existante
     */
    restoreData() {
        const mpcaList = this.contenuDiagnostic.mpcaList.data.descriptifList;
        if (mpcaList) {
            this.mpcaList = mpcaList;
        } else {
            this.mpcaList.push(new MpcaItemOld());
        }
        this.selectMpcaItem(0);
    }

    /**
     * Selection d'un MPCA Item à partir de son ID
     * On va charger le formulaire de cet MPCA Item
     * Et on va le selectionner dans la colonne de gauche
     * @param index
     * @private
     */
    selectMpcaItem(index: number) {
        this.mpcaItemSelected = this.mpcaList[index];
        this.mpcaIndexSelected = index;
    }

    /**
     * @description ajout d'un nouvelle item MPCA dans la liste MPCA
     *
     * @param mpcaItem
     * @private
     */
    addNewMpcaItem(mpcaItem: MpcaItemOld) {
        this.mpcaList.push(mpcaItem);
        this.selectMpcaItem(this.mpcaList.length - 1);
    }

    /**
     * @description suppression d'un item MPCA dans la liste de MPCA
     * Après la supression il faut selectionner le bon element
     * @param index de l'item MPCA dans la liste MPCA
     * @private
     */
    deleteMpcaItem(index: number) {
        const { mpcaIndexSelected } = this;
        this.mpcaList.splice(index, 1);
        const mpcaListLength = this.mpcaList.length;
        if (mpcaListLength === 0) {
            // si la liste de MPCA est vide, on n'affiche plus le formulaire
            this.mpcaItemSelected = null;
        } else if (mpcaIndexSelected === mpcaListLength) {
            // Si on supprime le dernier item, on selectionne le nouveau dernier
            this.selectMpcaItem(mpcaIndexSelected - 1);
        } else if (index === 0) {
            // Si on supprime le premier item, on selectionne le nouveau premier
            this.selectMpcaItem(0);
        } else if (index === mpcaIndexSelected) {
            // Dans les autres cas, on selectionne celui d'avant
            this.selectMpcaItem(mpcaIndexSelected - 1);
        }
    }
}
