import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { HttpErrorService } from './http-error.service';

@NgModule({
    imports: [CommonModule],
    declarations: [],
})
export class LibHttpModule {
    static forRoot(): ModuleWithProviders<LibHttpModule> {
        return {
            ngModule: LibHttpModule,
            providers: [HttpErrorInterceptor, HttpErrorService],
        };
    }
}
