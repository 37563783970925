// *** Choix réguliers ***

import { StateChoiceBoxes } from '../../model/states.model';
import { Icon } from 'src/app/commons-lib';

// Boolean True
export const trueOption = new StateChoiceBoxes('Validé', true, new Icon('check_circle'), 'ok');
// Boolean False
export const falseOption = new StateChoiceBoxes('Non validé', false, new Icon('cancel'), 'ko');

// String True
export const trueStringOption = new StateChoiceBoxes('Oui', 'true', new Icon('check_circle'), 'ok');
// String False
export const falseStringOption = new StateChoiceBoxes('Non', 'false', new Icon('cancel'), 'ko');

// OK
export const validatedOption = new StateChoiceBoxes('Validé', 'ok', new Icon('check_circle'), 'ok');
// KO
export const notValidatedOption = new StateChoiceBoxes('Non validé', 'ko', new Icon('cancel'), 'ko');
// Ajouté
export const addOption = new StateChoiceBoxes('Ajouté', true, new Icon('check_circle'), 'ok');
// Retiré
export const removeOption = new StateChoiceBoxes('Retiré', false, new Icon('cancel'), 'ko');
// Non visible
export const undefinedOption = new StateChoiceBoxes('Non visible', 'non_visible', new Icon('visibility_off'));
// Non défini
export const neitherOption = new StateChoiceBoxes('Aucun', 'nd', new Icon('indeterminate_check_box'));
// Warning
export const warningOption = new StateChoiceBoxes('Attention', 'warning', new Icon('error'), 'warning');
// Présent
export const presentOption = new StateChoiceBoxes('Présence', 'present', new Icon('check-bold', 'mdi'), 'ok');
// Absent
export const absentOption = new StateChoiceBoxes('Absence', 'absent', new Icon('cancel', 'mdi'), 'ko');
// Odeur

// *** Information du bien ***

// *** Type de bien ***
// Maison
export const houseState = new StateChoiceBoxes('Maison', 'Maison', new Icon('home'));
// Appartement
export const apartmentState = new StateChoiceBoxes('Appartement', 'Appartement', new Icon('floor-plan', 'mdi'));
// Immeuble/PartieCommune
export const buildingState = new StateChoiceBoxes('Immeuble', 'Immeuble/PartieCommune', new Icon('office-building', 'mdi'));
// Immeuble Bati Non Habitation
export const lotState = new StateChoiceBoxes('Autre que habitation', 'ImmeubleBatiNonHabitation', new Icon('factory', 'mdi'));
// Immeuble Non Bati
export const terrainState = new StateChoiceBoxes('Non Bati', 'ImmeubleNonBati', new Icon('terrain'));
