import { CanDeactivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { DESC, DOCUMENTS_RAPPORT, RAPPORT, REPORTAGE_PHOTO } from 'src/app/shared/constants/names.step.constants';
import { EtatProgressionService } from 'src/app/services/etat-progression.service';
import { NavigationBarsService } from 'src/app/services/navigation-bars.service';
import { NavigationBarsHelperService } from 'src/app/modules/interventions/navigation-bars/navigation-bars-helper.service';
import { BaseComponent } from 'src/app/commons-lib';
import { EtatValidation } from 'src/app/model/etat-progression.model';

@Injectable({
    providedIn: 'root',
})
export class ProgressEtatGuard implements CanDeactivate<BaseComponent> {
    constructor(
        private router: Router,
        private etatProgressionService: EtatProgressionService,
        private navigationBarsService: NavigationBarsService,
        private navigationBarsHelperService: NavigationBarsHelperService
    ) {}

    canDeactivate() {
        // permet d'initialiser la liste des routes pour ne pas valider le rapport
        this.navigationBarsService.navigationBarConfiguration$.pipe(first()).subscribe((conf) => {
            const codes = conf.activatedRoute.snapshot.routeConfig.children
                .map((a) => a.data?.code)
                .filter((code) => !!code && ![DESC, 'VALIDE_DOCS'].includes(code));
            let etatProgressions = null;
            this.etatProgressionService.etatProgressions$.subscribe((lstNav) => (etatProgressions = lstNav)).unsubscribe();
            const localConfig = this.navigationBarsHelperService.handleRoute(this.router.url, conf, etatProgressions);
            const codesValid = localConfig.links1.filter((step) => ['VALID', 'VOID'].includes(step.etat)).map((a) => a.code);
            for (const code of codes) {
                let stat: EtatValidation = 'INVALID';
                if (codesValid.includes(code)) {
                    stat = 'VALID';
                } else if (code === RAPPORT) {
                    const ep = etatProgressions.filter((e) => ['VALID', 'VOID'].includes(e.etat)).map((e) => e.code);
                    if (ep.includes(REPORTAGE_PHOTO) && ep.includes(DOCUMENTS_RAPPORT)) {
                        stat = 'VOID';
                    }
                }
                this.etatProgressionService.updateDiagnostic(code, stat, conf.refObject.currentDiagnostic);
            }
        });
        return true;
    }
}
