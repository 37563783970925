<h1 mat-dialog-title class="primary d-flex justify-content-between">
    <span>Modifier les valeurs</span>
    <button mat-button (click)="onClickCancel()">
        <lib-icon>close</lib-icon>
    </button>
</h1>

<div mat-dialog-content class="pb-0">
    <form [formGroup]="form">
        <div class="d-flex flex-column" cdkDropList (cdkDropListDropped)="drop($event)">
            <div *ngFor="let item of valuesControls; let i = index; trackBy: trackByFn" cdkDrag class="d-flex flex-row">
                <button *ngIf="!data.readOnly" mat-icon-button cdkDragHandle class="ml-2">
                    <mat-icon>drag_indicator</mat-icon>
                </button>
                <mat-form-field class="flex-grow-1" appearance="outline">
                    <input matInput [readonly]="data.readOnly" [formControl]="item" [placeholder]="data.placeholder" />
                </mat-form-field>
                <button *ngIf="!data.readOnly" mat-icon-button color="warn" (click)="deleteItem(i)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center" *ngIf="!data.readOnly">
                <button mat-raised-button color="primary" (click)="addValue()">
                    <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color">add</mat-icon>
                </button>
            </div>
        </div>
    </form>
</div>

<div mat-dialog-actions class="justify-content-start">
    <button mat-raised-button color="accent" (click)="onSubmit()">
        <lib-icon>check</lib-icon>
        <span>{{ 'Modifier' | uppercase }}</span>
    </button>
</div>
