import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { MarkdownService } from './markdown.service';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../utils/base.component';
import { Observable, of } from 'rxjs';

export class MarkdownViewModel {
    constructor(public pageTitle: string, public markdownAsHtml: string) {}
}

@Component({
    selector: 'lib-markdown',
    templateUrl: './markdown.component.html',
    styleUrls: ['./markdown.scss'],
})
export class MarkdownComponent extends BaseComponent implements OnInit, OnDestroy {
    viewModel$: Observable<MarkdownViewModel>;

    /** Classe CSS sur le conteneur principal */
    @Input() containerClass = '';

    /** Titre de la page (peut être fourni en input ou en route data) */
    @Input() pageTitle: string;

    /** Titre de la page (peut être fourni en input ou en route data) */
    @Input() markdownAsHtml: string;

    constructor(private markdownService: MarkdownService, private route: ActivatedRoute) {
        super();
    }

    ngOnInit() {
        if (this.pageTitle && this.markdownAsHtml) {
            this.viewModel$ = of(new MarkdownViewModel(this.pageTitle, this.markdownAsHtml));
        } else {
            this.viewModel$ = this.route.data.pipe(
                switchMap((data) =>
                    this.markdownService.getMarkdownFile(data.markdownFile).pipe(
                        map((text) => {
                            const markdownAsHtml = this.markdownService.convertToHTML(text);
                            return new MarkdownViewModel(data.pageTitle, markdownAsHtml);
                        })
                    )
                )
            );
        }
    }
}
