<div *ngIf="paramType === 'checkbox'">
    <mat-checkbox [checked]="checked" (change)="onCheckboxChange($event)">{{ label }}</mat-checkbox>
</div>
<div *ngIf="paramType === 'inputnumber'">
    <lib-increment-input
        [label]="label"
        [(value)]="numberValue"
        [min]="minValue"
        [max]="maxValue"
        [step]="stepValue"
        (valueChange)="onNumberChange()"
    ></lib-increment-input>
</div>
<div *ngIf="paramType === 'select'">
    <mat-form-field>
        <mat-label>{{ label }}</mat-label>
        <mat-select [(value)]="selected" (selectionChange)="onSelectChange($event)">
            <mat-option *ngFor="let item of items" [value]="item.value">
                {{ item.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
