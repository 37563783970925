import { Component, Input } from '@angular/core';
import { DocumentHistory } from '../../../../../model/document.model';
import { enumTypesCommentaire } from 'src/app/model/type-commentaire.model';

@Component({
    selector: 'app-documents-detail-header-attachment-history',
    templateUrl: './documents-detail-attachment-history.component.html',
    styleUrls: ['./documents-detail-attachment-history.component.scss'],
})
export class DocumentsDetailAttachmentHistoryComponent {
    enumTypesCommentaire = enumTypesCommentaire;
    @Input() documentsHistory: DocumentHistory[] = [];

    trackByDocumentHistory(index: number, documentHistory: DocumentHistory): string {
        return documentHistory.nomFichier;
    }
}
