<h1 mat-dialog-title class="primary">
    <span>Ajouter une compétences</span>
</h1>

<form [formGroup]="competenceForm" (ngSubmit)="onSubmitForm()">
    <div class="w-100 pr-2 pt-2">
        <div mat-dialog-content class="dialog-content">
            <div class="d-flex p-2 align-items-center">
                <div class="col-xl-4 mat-subheading-2 m-0 primary required">Nom</div>
                <div class="col-xl-8 pr-2">
                    <mat-form-field class="w-100">
                        <input matInput type="text" formControlName="nom" />
                        <app-mat-error [control]="competenceForm.get('nom')"></app-mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="d-flex p-2 align-items-center">
                <div class="col-xl-4 mat-subheading-2 m-0 primary">Organisme</div>
                <div class="col-xl-8 pr-2">
                    <mat-form-field class="w-100">
                        <input matInput type="text" formControlName="organisme" />
                        <app-mat-error [control]="competenceForm.get('organisme')"></app-mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="d-flex p-2 align-items-center">
                <div class="col-xl-4 mat-subheading-2 m-0 primary">Numéro de certification</div>
                <div class="col-xl-8 pr-2">
                    <mat-form-field class="w-100">
                        <input matInput type="text" formControlName="numero" />
                        <app-mat-error [control]="competenceForm.get('numero')"></app-mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="d-flex p-2 align-items-center">
                <div class="col-xl-4 mat-subheading-2 m-0 primary">Identifiant</div>
                <div class="col-xl-8 pr-2">
                    <mat-form-field class="w-100">
                        <input matInput type="text" formControlName="login" />
                        <app-mat-error [control]="competenceForm.get('login')"></app-mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="d-flex p-2 align-items-center">
                <div class="col-xl-4 mat-subheading-2 m-0 primary">Mot de passe</div>
                <div class="col-xl-8 pr-2">
                    <mat-form-field class="w-100">
                        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" />
                        <mat-icon matSuffix (click)="hide = !hide">{{
                            hide ? 'visibility_off' : 'visibility'
                        }}</mat-icon>
                        <app-mat-error [control]="competenceForm.get('password')"></app-mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="d-flex p-2 align-items-center">
                <div class="col-xl-4 mat-subheading-2 m-0 primary">Date d'obtention</div>
                <div class="col-xl-8 pr-2">
                    <mat-form-field class="w-100">
                        <input matInput type="text" formControlName="dateObtention" [matDatepicker]="dateObtention" />
                        <app-mat-error [control]="competenceForm.get('dateObtention')"></app-mat-error>
                        <mat-datepicker-toggle matSuffix [for]="dateObtention"> </mat-datepicker-toggle>
                        <mat-datepicker #dateObtention></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="d-flex p-2 align-items-center">
                <div class="col-xl-4 mat-subheading-2 m-0 primary">Date de fin</div>
                <div class="col-xl-8 pr-2">
                    <mat-form-field class="w-100">
                        <input matInput type="text" formControlName="dateFin" [matDatepicker]="dateFin" />
                        <app-mat-error [control]="competenceForm.get('dateFin')"></app-mat-error>
                        <mat-datepicker-toggle matSuffix [for]="dateFin"> </mat-datepicker-toggle>
                        <mat-datepicker #dateFin></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="d-flex align-items-center flex-grow-1 p-2">
                <div class="col-xl-4 mat-subheading-2 m-0 primary">Type de certificat</div>
                <div class="col-xl-8">
                    <ng-container *ngIf="competenceForm.value.justificatif">{{
                        competenceForm.value?.justificatif?.typeDocument?.nom
                    }}</ng-container>
                    <app-custom-select
                        *ngIf="!competenceForm.value.justificatif"
                        class="w-75"
                        [readonly]="false"
                        formControlName="typeDocument"
                        [selectControl]="typeDocumentControl"
                        [matFormFieldClass]="'w-100'"
                        [choices]="typesDocFiltered"
                        [addline]="false"
                        [pathValueDisplay]="['nom']"
                        [multiple]="false"
                    >
                    </app-custom-select>
                </div>
            </div>
            {{ codeTypeDocument }}
            <!-- Certificat de compétence -->
            <div class="d-flex align-items-center flex-grow-1" *ngIf="codeTypeDocument">
                <app-import-file
                    class="flex-grow-1"
                    [documentTitle]="documentTitle"
                    [codeTypeDocument]="codeTypeDocument"
                    [fileFormControl]="justificatifControl"
                    [labelDocument]="'Fichier certificat'"
                    [displayLabel]="true"
                    [required]="false"
                ></app-import-file>
            </div>
        </div>

        <div mat-dialog-actions class="justify-content-end">
            <button mat-button color="primary" type="button" (click)="onClickBtnCancel()">
                <mat-icon>close</mat-icon>
                <span>Annuler</span>
            </button>
            <button mat-raised-button color="primary" [disabled]="competenceForm.invalid">
                <mat-icon>check</mat-icon>
                <span>Valider</span>
            </button>
        </div>
    </div>
</form>
