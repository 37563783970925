<select
    *ngIf="mode === widgetSelectModeEnum.SELECT"
    class="widget-select widget-select-theme--{{ theme }}"
    [(ngModel)]="value"
    (change)="change($event)"
>
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</select>

<div *ngIf="mode === widgetSelectModeEnum.BADGE" class="widget-select-badge" [ngClass]="disabled ? 'disabled' : ''">
    <app-badge-select
        *ngFor="let item of badgeValueList"
        (clicked)="change(item.value)"
        [isChecked]="value === item.value"
        [color]="item.color"
        [outlinedWhenUnchecked]="outlinedWhenUnchecked"
    >
        <mat-icon *ngIf="item.type === 'icon'">{{ item.label }}</mat-icon>
        <div *ngIf="item.type === 'label'">{{ item.label }}</div>
        <div *ngIf="item.type === 'html'" [innerHtml]="item.label" class="flex"></div>
    </app-badge-select>
    <select #selectRef [(ngModel)]="value" (change)="change($event)" hidden>
        <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </select>
</div>

<ng-template #contentTpl><ng-content></ng-content></ng-template>
