<ng-container *ngIf="size == 'small'">
    <div class="d-flex justify-content-center" style="min-height: 30px">
        <mat-spinner [class.grey]="_isGrey" [color]="_color" [strokeWidth]="strokeWidth" [diameter]="25"></mat-spinner>
    </div>
</ng-container>
<ng-container *ngIf="size == 'medium'">
    <div class="d-flex justify-content-center" style="min-height: 61px">
        <mat-spinner [class.grey]="_isGrey" [color]="_color" [strokeWidth]="strokeWidth" [diameter]="50"></mat-spinner>
    </div>
</ng-container>
<ng-container *ngIf="size == 'large'">
    <div class="d-flex justify-content-center" style="min-height: 121px">
        <mat-spinner [class.grey]="_isGrey" [color]="_color" [strokeWidth]="strokeWidth"></mat-spinner>
    </div>
</ng-container>
