<lib-file-uploader #fileUploader [isImage]="isImage" (startUpload)="onStartUploadFile($event)"></lib-file-uploader>
<mat-card *ngIf="!interventionFile?.fileId" class="d-flex w-100 action-card" [ngClass]="readonlyMode ? 'disabled' : ''">
    <mat-card-content class="w-100">
        <div>
            <button class="w-100" mat-button [disabled]="readonlyMode" (click)="onClickAddFile()">
                <mat-icon>{{ isImage ? 'add_a_photo' : 'file_upload' }}</mat-icon>
                <br />{{ textConfig.mainText }}
            </button>
        </div>
        <button
            mat-icon-button
            *ngIf="allowLoadFromDocFiles"
            class="position-absolute align-self-end button-group primary no-padding"
            (click)="onClickOpenPlanDuBienModal()"
            [disabled]="readonlyMode"
        >
            <lib-icon class="icon-small">folder</lib-icon>
        </button>
    </mat-card-content>
</mat-card>

<mat-card *ngIf="interventionFile?.fileId" class="d-flex p-0 text-center">
    <mat-card-content class="w-100">
        <div>
            <div class="position-absolute align-self-end button-group no-padding">
                <button mat-icon-button color="primary" (click)="onClickEdit()" [disabled]="readonlyMode">
                    <lib-icon class="icon-small">edit</lib-icon>
                </button>
                <button
                    mat-icon-button
                    color="primary"
                    *ngIf="allowLoadFromDocFiles && fileId"
                    (click)="onClickBookmarkImage()"
                    [disabled]="readonlyMode"
                >
                    <lib-icon class="icon-small">bookmark</lib-icon>
                </button>
            </div>
            <div *ngIf="editionOverlay">
                <div class="position-absolute align-self-end button-group">
                    <button mat-icon-button color="warn" (click)="onClickCancel()" matTooltip="Annuler">
                        <lib-icon class="icon-small">cancel</lib-icon>
                    </button>
                    <!-- Si autoUpload = false -->
                    <button
                        *ngIf="!autoUpload"
                        mat-icon-button
                        color="primary"
                        (click)="rotateLeft(dataUrl)"
                        matTooltip="Pivoter a gauche"
                    >
                        <lib-icon class="icon-small">rotate_left</lib-icon>
                    </button>
                    <button
                        *ngIf="!autoUpload"
                        mat-icon-button
                        color="primary"
                        (click)="rotateRight(dataUrl)"
                        matTooltip="Pivoter à droite"
                    >
                        <lib-icon class="icon-small">rotate_right</lib-icon>
                    </button>
                    <!-- Si autoUpload = true -->
                    <button
                        *ngIf="autoUpload && (fileId | fileDownload | async) as fileContent"
                        mat-icon-button
                        color="primary"
                        (click)="rotateLeft(fileContent)"
                        matTooltip="Pivoter a gauche"
                    >
                        <lib-icon class="icon-small">rotate_left</lib-icon>
                    </button>
                    <button
                        *ngIf="autoUpload && (fileId | fileDownload | async) as fileContent"
                        mat-icon-button
                        color="primary"
                        (click)="rotateRight(fileContent)"
                        matTooltip="Pivoter à droite"
                    >
                        <lib-icon class="icon-small">rotate_right</lib-icon>
                    </button>
                    <button
                        mat-icon-button
                        color="warn"
                        (click)="onClickDeleteFile()"
                        [matTooltip]="textConfig.deleteToolTip"
                        [disabled]="readonlyMode"
                    >
                        <lib-icon class="icon-small">delete</lib-icon>
                    </button>
                    <button
                        *ngIf="allowLoadFromDocFiles"
                        mat-icon-button
                        color="primary"
                        (click)="onClickOpenPlanDuBienModal()"
                        [matTooltip]="textConfig.deleteToolTip"
                        [disabled]="readonlyMode"
                    >
                        <lib-icon class="icon-small">folder</lib-icon>
                    </button>
                    <button
                        mat-icon-button
                        color="primary"
                        (click)="onClickChangeFile()"
                        [matTooltip]="textConfig.changeToolTip"
                        [disabled]="readonlyMode"
                    >
                        <lib-icon class="icon-small">{{ isImage ? 'add_a_photo' : 'file_upload' }}</lib-icon>
                    </button>
                </div>
            </div>

            <ng-container *ngIf="isImage">
                <app-picture-file-uploader
                    [fileId]="fileId"
                    [dataUrl]="dataUrl"
                    [useDownloadPipe]="useDownloadPipe"
                ></app-picture-file-uploader>
            </ng-container>
            <ng-container *ngIf="!isImage && fileName">
                <app-document-file-uploader
                    [nomFichier]="fileName"
                    (fileDeleted)="onClickDeleteFile()"
                ></app-document-file-uploader
            ></ng-container>
        </div>
    </mat-card-content>
</mat-card>
