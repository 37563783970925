import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-list-chips-clickable',
    templateUrl: './list-chips-clickable.component.html',
    styleUrls: ['./list-chips-clickable.component.scss'],
})
export class ListChipsClickableComponent {
    // Défini les chips à afficher
    @Input() chipsToDisplayed: any[] = [];
    // Défini les chips sélectionnés
    @Input() chipsSelected: any[] = [];

    /**
     * Défini comment on récupére la valeur de l'objet à afficher dans la comboBox
     * C'est un tableau de string contenant les différents niveaux dans l'ordre.
     * {
     *  id: 5,
     *  isVisible : true,
     *  batiment: {
     *      nom: "batiment4"
     *      escalier : 4,
     *      porte : 9
     *  }
     * }
     *
     * Si on veut afficher le nom du batiment dans la comboBox, pathValueDisplay sera égale à ["batiment", "nom"]
     * Si null, on affiche la valeur direct du tableau choices. Utile si on passe un tableau de type 'primitifs' (string, number, ...)
     * Avec pathValueDisplay, on ne peut pas utiliser le addLine.
     */
    @Input()
    pathValueDisplay: string[] | ((item: any) => string);

    // Évènement de retour lors du clic sur un chip
    @Output() chipsClicked = new EventEmitter<any>();

    trackByChips(index: number, object: any): number {
        return index;
    }

    /**
     * Évènement lors du clic sur un chip, on renvoit simplement l'évènement au composant parent
     * @param chip
     */
    onClickChip(chip: any) {
        this.chipsClicked.emit(chip);
    }
}
