<div class="toolbar">
    <mat-toolbar class="border-bottom-1 primary">
        <button mat-button class="px-0" (click)="back()">
            <lib-icon>arrow_back</lib-icon>
        </button>
        <div>
            <lib-icon [class]="'icon-before-text'">settings</lib-icon>
            <span *ngIf="mode === 'CREATE'">Création d'une catégorie</span>
            <span *ngIf="mode === 'EDIT'">Edition de la catégorie</span>
            <span *ngIf="mode === 'DUPLICATE'">Duplication de la catégorie</span>
        </div>

        <div class="d-flex justify-content-end w-100">
            <button mat-button color="primary" class="mx-1" (click)="back()">
                <mat-icon>close</mat-icon>
                <span>{{ 'Annuler' | uppercase }}</span>
            </button>
            <button mat-raised-button color="primary" [disabled]="formTypeElement.invalid" (click)="validerCategorieEquipement()">
                <lib-icon>check</lib-icon>
                <span>{{ 'Valider' | uppercase }}</span>
            </button>
        </div>
    </mat-toolbar>
</div>

<div class="equipement-container">
    <div class="h-100 row no-gutters p-4 scrollable-content" [formGroup]="formTypeElement">
        <!-- Colonne gauche-->
        <div class="col-xl-6 pr-2">
            <!-- Etat de l'équipement -->
            <mat-button-toggle-group appearance="legacy" formControlName="etatEquipement" class="mb-3">
                <mat-button-toggle class="button-toggle-double INACTIF left-toggle" value="INACTIF">Inactif</mat-button-toggle>
                <mat-button-toggle class="button-toggle-double ACTIF right-toggle" value="ACTIF">Actif</mat-button-toggle>
            </mat-button-toggle-group>

            <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="col-xl-12 p-2 m-0 primary">Informations générales</div>
                    <!-- Nom de l'equipement -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Nom de l'équipement</div>
                        <div class="col-xl-8 pr-2">
                            <mat-form-field class="w-100">
                                <input matInput type="text" formControlName="name" />
                                <mat-error *ngIf="formTypeElement.get('name').hasError('erreurNomEquipement')"
                                    >Un autre équipement possède déjà le même nom.
                                </mat-error>
                                <app-mat-error [control]="formTypeElement.get('name')"></app-mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- codeBim de l'equipement -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Code bim de l'équipement</div>
                        <div class="col-xl-8 pr-2">
                            <mat-form-field class="w-100">
                                <input matInput type="text" formControlName="codeBim" />
                                <mat-error *ngIf="formTypeElement.get('codeBim').hasError('erreurCodeBimEquipement')"
                                    >Un autre équipement possède déjà le même code bim.
                                </mat-error>
                                <app-mat-error [control]="formTypeElement.get('codeBim')"> </app-mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Description de l'equipement  -->
                    <div class="d-flex p-2 align-items-center">
                        <mat-form-field class="w-100 mb-0" appearance="outline">
                            <mat-label>Description de l'équipement</mat-label>
                            <textarea
                                matInput
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="3"
                                cdkAutosizeMaxRows="5"
                                formControlName="description"
                            ></textarea>
                        </mat-form-field>
                    </div>

                    <!-- Prestation associées -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Types de prestation associés</div>
                        <div class="col-xl-8">
                            <app-custom-select
                                class="w-100"
                                formControlName="typesPrestationsAssocie"
                                [selectControl]="formTypeElement.get('typesPrestationsAssocie')"
                                [matFormFieldClass]="'w-100'"
                                [choices]="typesPrestation"
                                [addline]="false"
                                [multiple]="true"
                            >
                            </app-custom-select>
                            <app-mat-error [control]="formTypeElement.get('typesPrestationsAssocie')"> </app-mat-error>
                        </div>
                    </div>

                    <!-- Abstract -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Autoriser la création d'équipement</div>
                        <div class="col-xl-8">
                            <app-state-input
                                [choices]="[trueOption, falseOption]"
                                [tooltips]="toolTipOuiNon"
                                [(choiceValue)]="abstract"
                                [disabled]="false"
                                (valueChangeFromUser)="changeAbstract()"
                            ></app-state-input>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

            <!------------ Pictogramme ------------->
            <!-- ATTENTION: les images des catégories (WorkType) n'existe plus, elles seront de retour dans une prochaine mise à jour
            <mat-card class="d-flex w-100 p-2 mb-2">
                <mat-card-content class="w-100">
                    <div class="col-xl-12 m-0 mb-3 primary">Pictogramme</div>
                    <mat-card *ngIf="!imgPerso" class="d-flex w-50 action-card">
                        <mat-card-content class="w-100">
                            <div>
                                <lib-file-uploader #fileUploader [isImage]="true" (startUpload)="uploadIconeElement($event)"></lib-file-uploader>
                                <mat-progress-bar
                                    class="mt-2"
                                    [hidden]="!(fileUploader.uploadRunning | async)"
                                    [value]="fileUploader.uploadProgress | async"
                                ></mat-progress-bar>

                                <button class="w-100" mat-button (click)="fileUploader.selectFile()">
                                    <mat-icon>add_a_photo</mat-icon>
                                    <br />{{ "Ajouter le pictogramme de l'élément à controler" }}
                                </button>
                            </div>
                        </mat-card-content>
                    </mat-card>

                    <div *ngIf="imgPerso" class="pl-5">
                        <button mat-icon-button class="position-absolute align-self-end button-group primary" (click)="editPicture = true">
                            <lib-icon class="icon-small">edit</lib-icon>
                        </button>
                        <div *ngIf="editPicture">
                            <div class="position-absolute align-self-end button-group">
                                <button mat-icon-button color="warn" (click)="editPicture = false" matTooltip="Annuler">
                                    <lib-icon class="icon-small">cancel</lib-icon>
                                </button>
                                <button
                                    mat-icon-button
                                    color="warn"
                                    (click)="deleteIconeElement()"
                                    matTooltip="Supprimer le pictogramme de l'élément à controler"
                                >
                                    <lib-icon class="icon-small">delete</lib-icon>
                                </button>
                                <lib-file-uploader #fileUploader [isImage]="true" (startUpload)="uploadIconeElement($event)"></lib-file-uploader>
                                <button
                                    mat-icon-button
                                    color="primary"
                                    (click)="fileUploader.selectFile()"
                                    matTooltip="Changer le pictogramme de l'élément à controler"
                                >
                                    <lib-icon class="icon-small">add_a_photo</lib-icon>
                                </button>
                            </div>
                        </div>
                        <img
                            [src]="imgPerso"
                            alt="Pictogramme de l'élément à controler"
                            class="img-fluid"
                            style="max-width: 100%; max-height: 60px"
                        />
                    </div>
                </mat-card-content>
            </mat-card>
            -->
        </div>

        <!--Colonne droite-->
        <div class="col-xl-6 pl-2">
            <!-- Equipement Parent -->
            <mat-card class="p-2 mt-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="col-xl-12 p-2 m-0 primary">Réglages</div>
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary">Equipements parent</div>
                        <div class="col-xl-8">
                            <app-custom-select
                                class="w-100"
                                formControlName="elementParentName"
                                [selectControl]="formTypeElement.get('elementParentName')"
                                [matFormFieldClass]="'w-100'"
                                [choices]="listeNomCategorieEquipement"
                                [addline]="false"
                                (selectedValueChange)="changeElementParent($event)"
                                [filter]="false"
                            >
                            </app-custom-select>
                            <app-mat-error [control]="formTypeElement.get('elementParentName')"> </app-mat-error>
                        </div>
                    </div>

                    <!-- valeurs des paramètres de l'équipements -->
                    <div class="d-flex flex-column p-2 align-items-center">
                        <div class="col-xl-12 mat-subheading-2 m-0 primary">Valeurs des paramètres</div>
                        <div class="col-xl-8 pr-2">
                            <div class="buttons d-flex align-items-center">
                                <button
                                    color="accent"
                                    mat-raised-button
                                    class="bt-valid"
                                    (click)="onClickBtnAddOrEditParametre(null)"
                                    data-cy="ajouter-parametre-button"
                                >
                                    <lib-icon>post_add</lib-icon>
                                    Ajouter un paramètre
                                </button>
                            </div>
                        </div>
                        <div *ngFor="let param of categorieEquipement?.parametersDefinitions" class="col-xl-11 d-flex p-2 align-items-center">
                            {{ param.name }} ({{ param.codeBim }}), {{ param.type }} : {{ param.choices?.join(', ') }}; valeur par défaut :
                            {{ categorieEquipement.valeursDefautParametres[param.codeBim] }}
                            <div class="position-absolute align-self-end button-group">
                                <button
                                    mat-icon-button
                                    class="primary"
                                    (click)="onClickBtnAddOrEditParametre(param)"
                                    data-cy="editer-parametre-button"
                                >
                                    <lib-icon class="icon-small">edit</lib-icon>
                                </button>
                                <button mat-icon-button class="warn" (click)="onClickBtndeleteParametre(param)" data-cy="supprimer-parametre-button">
                                    <lib-icon class="icon-small">delete</lib-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
