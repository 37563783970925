import { Component, Input, OnInit } from '@angular/core';
import { InterventionReportData, IReportComponent, ReportBlockData } from '../../../../../../model/rapport.model';
import { GazReportData, RowVolumeGaz, SubBlockGaz } from '../../../model/gaz.model';
import { MatDialog } from '@angular/material/dialog';
import { Commentaire } from '../../../../../../model/commentaire.model';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { BaseComponent, NotificationService } from 'src/app/commons-lib';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { ReportService } from '../../../../../../services/report.service';
import { CnSpinnerService } from '../../../../../shared/cn-spinner/service/cn-spinner.service';
import { combineLatest } from 'rxjs';
import { InterventionService } from '../../../../../../services/intervention.service';
import { ReportCommentsListModalComponent } from 'src/app/modules/diagnostics-shared/rapport/report-comments-list-modal/report-comments-list-modal.component';
import { ReportScreenshotsListModalComponent } from 'src/app/modules/diagnostics-shared/rapport/report-screenshots-list-modal/report-screenshots-list-modal.component';
import { Intervention } from 'src/app/model/intervention.model';
import { TypePrestationEnum } from 'src/app/model/type-prestation.model';

@Component({
    selector: 'app-visibilite-equipements-block',
    templateUrl: './visibilite-equipements-block.component.html',
    styleUrls: ['./visibilite-equipements-block.component.scss'],
})
export class VisibiliteEquipementsBlockComponent extends BaseComponent implements OnInit, IReportComponent {
    @Input() data: ReportBlockData;
    @Input() intervention: Intervention;
    @Input() interReportData: InterventionReportData;

    title: string;
    optionPlan = false;
    nonVisible: SubBlockGaz;
    exclusionNonVisible: string[] = [];
    readonlyMode = false;

    private diagnosticData: GazReportData;
    private diagnostic: Diagnostic;

    constructor(
        private matDialog: MatDialog,
        private diagnosticService: DiagnosticService,
        private interventionService: InterventionService,
        private reportService: ReportService,
        private notificationService: NotificationService,
        private cnSpinnerService: CnSpinnerService
    ) {
        super();
    }

    ngOnInit(): void {
        this.diagnosticService
            .getCurrentDiagnostic()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((diag) => {
                this.diagnostic = diag;
                this.readonlyMode = this.diagnosticService.isReadOnlyMode(this.intervention, this.diagnostic);
                if (this.interReportData) {
                    this.diagnosticData = this.interReportData.diagnostics.find(
                        (dia) => dia.typePrestation === TypePrestationEnum.GAZ
                    ) as GazReportData;
                    this.optionPlan = this.diagnosticData.optionPlan;
                    this.nonVisible = this.diagnosticData.nonVisibles;
                }
                if (this.data) {
                    if (this.data.title) {
                        this.title = this.data.title;
                    }
                }
                this.prepareExclusionLists();
            });
    }

    /**
     * Edition de la liste des commentaires à afficher
     * @param commentaires
     * @param code
     */
    onClickEditDisplayedComments(commentaires: Commentaire[], code: string) {
        return this.matDialog
            .open(ReportCommentsListModalComponent, {
                data: {
                    comments: commentaires,
                    code: code,
                    hiddenComments: this.diagnostic.hiddenComments,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.diagnostic.hiddenComments = result.hiddenComments;
                    this.prepareExclusionLists();
                    this.saveReport();
                }
            });
    }

    /**
     * Edition de la liste des screenshots à associer pour un volume
     * @param volume
     */
    onClickEditPlansList(volume: RowVolumeGaz) {
        return this.matDialog
            .open(ReportScreenshotsListModalComponent, {
                data: {
                    screenshotsAvailable: this.diagnosticData.screenshotsPlan,
                    selectedScreenshots: volume.plans,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    volume.plans = result.screenshotsSelected;
                    this.saveReport();
                }
            });
    }

    /**
     * Liste d'exclusion de commentaires
     * @private
     */
    private prepareExclusionLists() {
        this.exclusionNonVisible = this.diagnostic.hiddenComments['NON_VISIBLES'] ? this.diagnostic.hiddenComments['NON_VISIBLES'] : [];
    }

    /**
     * Sauvegarde du pré-rapport temporaire
     * @private
     */
    private saveReport() {
        combineLatest([this.interventionService.getCurrentIntervention(), this.diagnosticService.getCurrentDiagnostic()])
            .pipe(
                take(1),
                map(([intervention, diagnostic]) => this.reportService.saveReportDataTemp(this.interReportData, intervention, diagnostic)),
                switchMap((result) =>
                    this.interventionService
                        .updateIntervention(result.intervention)
                        .pipe(switchMap((i) => this.diagnosticService.upsert(i, result.diagnostic)))
                )
            )
            .subscribe(
                (diag) => {
                    if (diag) {
                        this.notificationService.success(
                            'Les données du rapport ont bien été sauvées pour le diagnostic ' + diag.typePrestation.replace(/_/g, ' ')
                        );
                    } else {
                        this.notificationService.error({
                            message: 'Impossible de sauvegarder les données du rapport',
                            showCloseButton: false,
                            duration: 0,
                        });
                    }
                },
                () => this.cnSpinnerService.hide(),
                () => this.cnSpinnerService.hide()
            );
    }
}
