import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Commentaire } from '../../../model/commentaire.model';
import { enumTypesCommentaire, TypeCommentaire, typesCommentaireAFiltrer } from '../../../model/type-commentaire.model';
import { BaseComponent, ConfirmationService, DateUtils, FileUploaderComponent, MongoUtils, NotificationService } from 'src/app/commons-lib';
import { PointDeControleElement } from '../../../model/point-de-controle.model';
import { CommentairePredefini } from '../../../model/commentaire-predefini.model';
import { Observable, of } from 'rxjs';
import { DiagnosticService } from '../../../services/diagnostic.service';
import { InterventionService } from '../../../services/intervention.service';
import { Intervention } from '../../../model/intervention.model';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { CnSpinnerService } from '../cn-spinner/service/cn-spinner.service';
import { ReferenceService } from 'src/app/services/reference.service';
import { InterventionFile, TypeReferenceFichier } from '../../../model/intervention-file.model';
import { Diagnostic } from '../../../model/diagnostic.model';
import { combineLatestOrEmpty } from '../../../utils/rxjs.utils';
import { InterventionFileService } from '../../../services/intervention-file.service';
import { FileUploaderOutput, FileUploaderTextConfigFile } from '../file-uploader/wizy-file-uploader.component';
import { TypePrestationEnum } from 'src/app/model/type-prestation.model';
import { CustomSelectGroup } from '../custom-select/custom-select.model';
import { CategorieCommentairePredefini } from 'src/app/model/categorie-commentaire-predefini.model';
import { StringUtils } from 'src/app/utils/string.utils';

class CommentaireModalData {
    constructor(
        public commentairesId: string[],
        public typeCommentaireSelected: TypeCommentaire | null = null,
        public elements: any[],
        public elementPathValueDisplay: string[] | ((item: any) => string) = ['nom'],
        public localisation: CustomSelectGroup[] = null
    ) {}
}

@Component({
    selector: 'app-commentaire-modal',
    templateUrl: './commentaire-modal.component.html',
    styleUrls: ['./commentaire-modal.component.scss'],
})
export class CommentaireModalComponent extends BaseComponent implements OnInit {
    disabledSave: boolean = true;
    readonlyMode: boolean = true;
    editmode: boolean = false;
    updating: boolean = false; // Montre si une modification est en cours

    intervention: Intervention;
    diagnostic: Diagnostic;

    // Informations venant de l'appelant via CommentaireModalData
    elements: any[] = [];
    elementPathValueDisplay: string[] | ((item: any) => string) = ['nom'];
    typeCommentaireSelected?: TypeCommentaire = null; // selection par défaut du type commentaire, si il est != null alors l'utilisateur ne peut pas le changer
    typeCommentairelocked: boolean = false;
    localisation?: CustomSelectGroup[] = null; //afficher la localisation si null on affiche pas

    // Pictures
    file: any;
    typeReferenceFichierPicture = TypeReferenceFichier.PHOTO_COMMENTAIRE;
    fileUploaderTextConfig: FileUploaderTextConfigFile;
    currentInterventionFile: InterventionFile = null;
    fileUploaderOutput: FileUploaderOutput;
    @ViewChild('fileUploader') fileUploader: FileUploaderComponent;

    // L'index du commentaire qui a été sélectionné pour la modificatioin
    selectedCommentaireIndex: number;

    nouveauCommentaire: Commentaire; // nouveau commentaire en cours de saisi
    commentaires: Commentaire[] = []; // commentaires déjà saisi chargé à partir des commentairesId de l'appelant via CommentaireModalData
    allCommentairesPredefinis: CommentairePredefini[] = []; // contient tous les commentaires prédéfinit venant de la config pour la prestation en cours sans aucun autre filtre
    commentairesPredefinisFiltres: CommentairePredefini[] = []; // tous les commentaires mais filtré par les catégories sélectionnées
    commentairesAjoutes: Commentaire[] = []; // liste des commentaires nouvellement ajoutés mais pas encore sauvegardés

    listCategories = []; // Liste des catégories possible correspondant aux chips affichés dans l'IHM
    listCategoriesSelected: CategorieCommentairePredefini[] = []; // Liste des catégories (chips) sélectionnées par l'opérateur et qui permettent de filtrer les résultats

    constructor(
        private readonly dialogRef: MatDialogRef<CommentaireModalComponent>,
        @Inject(MAT_DIALOG_DATA) private readonly data: CommentaireModalData,
        private readonly referenceService: ReferenceService,
        private readonly diagnosticService: DiagnosticService,
        private readonly confirmationService: ConfirmationService,
        private readonly notificationService: NotificationService,
        private readonly interventionService: InterventionService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly interventionFileService: InterventionFileService
    ) {
        super();
        this.createNewEmptyCommentaire();
        this.initFileUploaderTextConfig();
    }

    ngOnInit(): void {
        this.cnSpinnerService
            .withSpinner(
                combineLatestOrEmpty([
                    this.interventionService.getCurrentIntervention(),
                    this.diagnosticService.getCurrentDiagnostic(false),
                    this.referenceService.findAllCommentairesPredefinis(),
                ])
            )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                ([intervention, diagnostic, allCommentaires]) => {
                    this.intervention = intervention;
                    this.diagnostic = diagnostic;
                    this.readonlyMode = this.diagnosticService.isReadOnlyMode(intervention, diagnostic);

                    // Utilisation des données envoyées en paramètre de la modale
                    if (this.data) {
                        this.localisation = this.data.localisation;

                        // récupération des l'éléments
                        if (this.data.elements && this.data.elements[0] !== undefined) {
                            this.elements = this.data.elements;

                            // récupération du nom du paramètre de l'élément à afficher
                            if (this.data.elementPathValueDisplay && this.data.elementPathValueDisplay.length > 0) {
                                this.elementPathValueDisplay = this.data.elementPathValueDisplay;
                            }
                        }

                        // récupération des commentaires à partir de leurs ID passer en maramètre
                        if (this.data.commentairesId) {
                            this.commentaires = this.intervention.commentaires.filter((c) => {
                                return this.data.commentairesId.indexOf(c.id) !== -1;
                            });
                        }

                        this.allCommentairesPredefinis = allCommentaires; // contient tous les commentaires prédéfinit venant de la config pour la prestation en cours sans aucun autre filtre

                        if (this.data.typeCommentaireSelected) {
                            this.typeCommentaireSelected = this.data.typeCommentaireSelected;
                            this.typeCommentairelocked = true;
                        } else {
                            this.typeCommentaireSelected = enumTypesCommentaire.NOTE_PERSONNELLE;
                        }
                        this.nouveauCommentaire.type = this.typeCommentaireSelected;

                        this.commentaires.forEach((comm) => {
                            if (comm.imageId != null) {
                                let interfile = this.getInterventionFile(comm.imageId);
                                interfile.subscribe((value) => {
                                    comm.imageDateTimeOriginal = new Date(+value.creationDate).toLocaleDateString();
                                    comm.imageGpsLatitudeRef = value.gpsLatitudeRef;
                                    comm.imageGpsLatitude = value.gpsLatitude;
                                    comm.imageGpsLongitudeRef = value.gpsLongitudeRef;
                                    comm.imageGpsLongitude = value.gpsLongitude;
                                    comm.imageGpsLatitudeRef = value.gpsAltitudeRef;
                                    comm.imageGpsAltitude = value.gpsAltitude;
                                });
                            }
                        });
                    }

                    this.filterComments();
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    /**
     * Évènement lors du clic sur un chip de filtre de catégorie
     * @param categorie
     */
    onClickChipsCategorie(categorie: CategorieCommentairePredefini) {
        const indexCategorie = this.listCategoriesSelected.indexOf(categorie);
        if (indexCategorie == -1) {
            this.listCategoriesSelected.push(categorie);
        } else {
            this.listCategoriesSelected.splice(indexCategorie, 1);
        }

        this.filterComments();
    }

    /**
     * Filtre les commentaires suivant les catégories sélectionnées
     * Si aucune catégorie n'est sélectionnée, c'est comme si elles étaient toutes sélectionnées
     */
    private filterComments() {
        this.filterCategory();
        this.commentairesPredefinisFiltres = this.allCommentairesPredefinis.filter((commentaireTemp) => {
            const catTrouve = this.hasIntersectionBetweenArrays(this.listCategoriesSelected, commentaireTemp.categories);
            return this.listCategoriesSelected.length == 0 || catTrouve;
        });
    }

    private hasIntersectionBetweenArrays(
        listCategoriesSelected: CategorieCommentairePredefini[],
        categories: CategorieCommentairePredefini[]
    ): boolean {
        for (const category of listCategoriesSelected) {
            for (const cat of categories) {
                if (category.name === cat.name) return true;
            }
        }
        return false;
    }

    private filterCategory() {
        // Récupérer les catégories possibles
        this.listCategories = [
            ...new Set(
                this.allCommentairesPredefinis
                    .filter(
                        (cp) =>
                            (cp.typePrestation === this.diagnostic?.typePrestation || cp.typePrestation === TypePrestationEnum.COMMUN) &&
                            (this.typeCommentaireSelected === null || cp.typeCommentaire === this.typeCommentaireSelected)
                    )
                    .flatMap((commentaireTemp) => commentaireTemp.categories)
                    .filter((categorieTemp) => categorieTemp !== null)
                    .sort((c1, c2) => c1.id.localeCompare(c2.id))
            ),
        ];

        // on supprime les doublons
        var cache = {};
        this.listCategories = this.listCategories.filter(function (elem, index, array) {
            return cache[elem.id] ? 0 : (cache[elem.id] = 1);
        });
    }

    /**
     * Evenement de click sur le bouton de fermeture de la modale
     */
    onClickBtnClose() {
        if (this.nouveauCommentaire.contenu || this.nouveauCommentaire.imageId) {
            this.confirmationService.confirmWarn('Le commentaire en cours de saisie ne sera pas sauvegardé<br>Souhaitez-vous continuer ?', () => {
                this.closeDialog();
            });
        } else {
            this.closeDialog();
        }
        this.editmode = false;
    }

    localisationChange(value: any) {
        this.nouveauCommentaire.localisations = value;
    }

    /**
     * Création du/des commentaire(s)
     */
    onClickBtnConfirm() {
        if (!this.nouveauCommentaire.type) {
            return this.notificationService.notify('Veuillez sélectionner un type de commentaire.');
        } else if (!this.nouveauCommentaire.contenu && !this.nouveauCommentaire.localisations) {
            return this.notificationService.notify('Veuillez remplir le champs "Commentaire" ou "Localisation".');
        }
        // Mise à jour de l'intervention
        this.cnSpinnerService.withSpinner(this.postCommentaire()).pipe(takeUntil(this.ngUnsubscribe)).subscribe();
        this.commentairesAjoutes.push(this.nouveauCommentaire);
    }

    /**
     * Sélection du type de commentaire
     */
    onClickTypeCommentaire(type: TypeCommentaire) {
        this.typeCommentaireSelected = type;
        this.nouveauCommentaire.type = type;
        this.filterComments();
    }

    /**
     * Ajout d'un commentaire prédéfini dans les contenu du commentaire
     */
    onClikAddCommentairePredefini(com: string) {
        this.nouveauCommentaire.contenu = this.nouveauCommentaire.contenu ? this.nouveauCommentaire.contenu + ' \n' + com : com;
    }

    onClickCancel = () => {
        // Détection d'une modification
        if (this.nouveauCommentaire.contenu || this.nouveauCommentaire.imageId || this.commentaires?.length) {
            this.confirmationService.confirmWarn('Êtes-vous sûr de vouloir quitter sans enregistrer ?', this.cancel);
        } else {
            this.cancel();
        }
        this.editmode = false;
    };
    private cancel = () => {
        this.closeDialog();
    };

    private deleteImageCommentaire(commentaire: Commentaire) {
        if (commentaire.imageId) {
            // Suppression de l'image
            return this.cnSpinnerService
                .withSpinner(
                    // On récupère l'InterventionFile
                    this.interventionFileService
                        .findByIdInterventionIdDiagnosticIdReference(
                            this.intervention.id,
                            this.diagnostic && this.diagnostic.id ? this.diagnostic.id : undefined,
                            commentaire.id,
                            TypeReferenceFichier.PHOTO_COMMENTAIRE,
                            commentaire.imageId
                        )
                        .pipe(
                            switchMap((interventionFile) => {
                                /**
                                 * Supprime l'image du commentaire et l'objet InterventionFile
                                 */
                                return this.interventionFileService.deleteInterventionFile(interventionFile, true);
                            })
                        )
                )
                .pipe(takeUntil(this.ngUnsubscribe));
        }
        return of(null);
    }

    /**
     * fonction appeléz quand l'utilisateur clique sur l'icone edit
     * pour mettre à jour le commentaire
     */
    onClickStartUpdateCommentaire(commentaire: Commentaire) {
        this.editmode = true;
        console.log(commentaire);
        /**
         * updating devient "true" pour montrer que une modification est en cours
         * Ensuite on conserve l'index du commentaire sélectionné
         */
        this.updating = true;
        this.nouveauCommentaire = Object.assign({}, commentaire);

        this.selectedCommentaireIndex = this.commentaires.indexOf(commentaire);
    }

    /**
     * Mettre à jour le commentaire
     * lorsque l'utilisateur clique sur le bouton modifier
     */
    onclickSaveUpdatedCommentaire() {
        this.disabledSave = false;
        /**
         * Si le commentaire contient une image
         * on retire l'image
         */

        let imageUploadPresent = false;

        if (this.fileUploaderOutput) {
            imageUploadPresent = true;

            if (this.commentaires[this.selectedCommentaireIndex].imageId) {
                this.interventionFileService
                    .findByIdInterventionIdDiagnosticIdReference(
                        this.intervention.id,
                        this.diagnostic && this.diagnostic.id ? this.diagnostic.id : undefined,
                        this.commentaires[this.selectedCommentaireIndex].id,
                        TypeReferenceFichier.PHOTO_COMMENTAIRE,
                        this.commentaires[this.selectedCommentaireIndex].imageId
                    )
                    .pipe(
                        switchMap((interventionFile) => {
                            /**
                             * Supprime l'image du commentaire et l'objet InterventionFile
                             */
                            this.nouveauCommentaire.imageId = null;
                            return this.interventionFileService.deleteInterventionFile(interventionFile, true);
                        })
                    );
            }
        }

        let uploadInterventionFile$;

        if (imageUploadPresent) {
            uploadInterventionFile$ = this.interventionFileService.uploadInterventionFile(
                this.fileUploaderOutput.interventionFile,
                this.fileUploaderOutput.dataUrl,
                null
            );
        } else {
            uploadInterventionFile$ = of(null);
        }

        uploadInterventionFile$.subscribe(() => {
            /**
             * Ici on modifie le commentaire dans l'intervention
             * pour ensuite mettre à jour l'intervention
             */
            this.intervention.commentaires[this.intervention.commentaires.indexOf(this.commentaires[this.selectedCommentaireIndex])] =
                this.nouveauCommentaire;

            /**
             * Le commentaire est modifié en local
             * On retire d'abord le commentaire du tableau commentaires qui est local
             * ensuite on rajoute le nouveau commentaire au même index
             */
            this.commentaires.splice(this.selectedCommentaireIndex, 1);
            this.commentaires.splice(this.selectedCommentaireIndex, 0, this.nouveauCommentaire);

            /**
             * On renitialise le nouveau commentaire
             * on met updating sur false
             */
            this.resetUpdating();
            this.fileUploaderOutput = null;
        });
        this.editmode = false;
    }

    /**
     * L'utilisateur souhaite annuler la modification
     * du commentaire
     */
    onClickCancelUpdateCommentaire() {
        this.resetUpdating();
        this.editmode = false;
    }

    private resetUpdating() {
        this.updating = false;
        this.createNewEmptyCommentaire();
    }

    /**
     * Supprime éventuellement le fichier associé à un commentaire
     * Supprime éventuellement l'occurence InterventionFile associé au commentaire
     * Supprime le commentaire
     * @param commentaire commentaire à supprimer
     */
    onClickDeleteCommentaire(commentaire: Commentaire) {
        this.confirmationService.confirmWarn('Êtes-vous sûr de vouloir supprimer ce commentaire ?', () => {
            if (commentaire.imageId) {
                // Suppression de l'image
                this.cnSpinnerService
                    .withSpinner(
                        // On récupère l'InterventionFile
                        this.interventionFileService
                            .findByIdInterventionIdDiagnosticIdReference(
                                this.intervention.id,
                                this.diagnostic && this.diagnostic.id ? this.diagnostic.id : undefined,
                                commentaire.id,
                                TypeReferenceFichier.PHOTO_COMMENTAIRE,
                                commentaire.imageId
                            )
                            .pipe(
                                switchMap((interventionFile) => {
                                    /**
                                     * Supprime l'image du commentaire et l'objet InterventionFile
                                     */
                                    return this.interventionFileService.deleteInterventionFile(interventionFile, true);
                                })
                            )
                    )
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe();
            }

            // Mise à jour des tableaux
            this.commentaires.splice(this.commentaires.indexOf(commentaire), 1);
            this.intervention.commentaires.splice(this.intervention.commentaires.indexOf(commentaire), 1);
            this.data.commentairesId.splice(this.data.commentairesId.indexOf(commentaire.id), 1);

            // Mise à jour de l'intervention
            this.cnSpinnerService
                .withSpinner(this.interventionService.updateIntervention(this.intervention))
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(() => {
                    this.notificationService.success('Le commentaire a bien été supprimé.');
                });
        });
        this.editmode = false;
    }

    /**
     * Action déclenchée lors du click pour supprimer un élément
     * @param el
     */
    onClickDeleteElement(el: PointDeControleElement) {
        this.elements.splice(this.elements.indexOf(el), 1);
    }

    /**
     * Evenement déclenché lorsque l'image a été ajoutée
     * @param fileUploaderOutput
     */
    onFileUploaded(fileUploaderOutput: FileUploaderOutput): void {
        // On met à jour le form avec l'id de l'image
        this.nouveauCommentaire.imageId = fileUploaderOutput.interventionFile.fileId;

        // On stocke les informations de l'image pour l'uploader manuellement
        this.fileUploaderOutput = fileUploaderOutput;
    }

    /**
     * Evenement déclenché lorsque l'image a été supprimée
     */
    onFileDeleted(): void {
        // On supprime l'id de l'image dans le commentaire, ainsi que l'objet interventionFile
        this.nouveauCommentaire.imageId = undefined;
        this.fileUploaderOutput = null;
    }

    /**
     * Met à jour l'intervention afin d'intégrer le nouveau commentaire.
     * @private
     */
    private postCommentaire(): Observable<Intervention> {
        // Prepare les données
        this.prepareDataToSave();

        let uploadInterventionFile$;
        // Si il y a une image
        if (this.fileUploaderOutput) {
            // Upload du fichier et ajout d'une occurence InterventionFile
            uploadInterventionFile$ = this.interventionFileService.uploadInterventionFile(
                this.fileUploaderOutput.interventionFile,
                this.fileUploaderOutput.dataUrl,
                null
            );
        } else {
            uploadInterventionFile$ = of(null);
        }

        // Mise à jour de l'intervention
        return uploadInterventionFile$.pipe(
            switchMap(() => {
                // Ajout du commentaire dans la liste à afficher à l'écran.
                this.commentaires.push(this.nouveauCommentaire);
                this.selectedCommentaireIndex = this.commentaires.indexOf(this.nouveauCommentaire);

                this.commentaires.forEach((comm) => {
                    if (comm.imageId != null) {
                        let interfile = this.getInterventionFile(comm.imageId);
                        interfile.subscribe((value) => {
                            comm.imageDateTimeOriginal = new Date(+value.creationDate).toLocaleDateString();
                            comm.imageGpsLatitudeRef = value.gpsLatitudeRef;
                            comm.imageGpsLatitude = value.gpsLatitude;
                            comm.imageGpsLongitudeRef = value.gpsLongitudeRef;
                            comm.imageGpsLongitude = value.gpsLongitude;
                            comm.imageGpsLatitudeRef = value.gpsAltitudeRef;
                            comm.imageGpsAltitude = value.gpsAltitude;
                        });
                    }
                });

                // Mise à jour de l'intervention.
                return this.interventionService.updateIntervention(this.intervention).pipe(
                    tap(() => {
                        // Création d'un nouveau commentaire vide pour la prochaine saisie.
                        this.createNewEmptyCommentaire();
                        this.fileUploaderOutput = null;
                    })
                );
            })
        );
    }

    /**
     * Crée un nouvel objet de commentaire vide
     * @private
     */
    private createNewEmptyCommentaire() {
        this.nouveauCommentaire = new Commentaire();
        this.nouveauCommentaire.id = MongoUtils.generateObjectId();
        this.nouveauCommentaire.type = this.typeCommentaireSelected ? this.typeCommentaireSelected : null;
        this.currentInterventionFile = null;
    }

    /**
     * Prepare les données à sauvegarder
     * @private
     */
    private prepareDataToSave() {
        this.nouveauCommentaire.date = DateUtils.localDateTimeString();
        if (this.elements.length > 0) {
            if (this.elements.length === 1) {
                this.elements[0].commentairesId = [...new Set(this.elements[0].commentairesId.concat(this.nouveauCommentaire.id))];
                this.intervention.commentaires = this.intervention.commentaires.concat(this.nouveauCommentaire);
            } else {
                this.elements.forEach((el) => {
                    const nouveauCommentaireSpecifique = { ...this.nouveauCommentaire };
                    nouveauCommentaireSpecifique.id = MongoUtils.generateObjectId();
                    el.commentairesId = [...new Set(el.commentairesId.concat(nouveauCommentaireSpecifique.id))];
                    this.intervention.commentaires = this.intervention.commentaires.concat(nouveauCommentaireSpecifique);
                });
            }
        }
        if (this.data.commentairesId) {
            this.data.commentairesId = [...new Set(this.data.commentairesId.concat(this.nouveauCommentaire.id))];
        }

        if (this.elements.length === 0) {
            this.intervention.commentaires = this.intervention.commentaires.concat(this.nouveauCommentaire);
        }
    }

    /**
     * Ferme la modale
     * @private
     */
    private closeDialog() {
        this.dialogRef.close({ commentairesId: this.commentaires.map((c) => c.id) });
    }

    /**
     * Initialise l'objet contenant les informations textuelles à afficher dans le composant d'upload
     * @private
     */
    private initFileUploaderTextConfig() {
        this.fileUploaderTextConfig = {
            mainText: `Ajouter l'image du commentaire`,
            deleteToolTip: `Supprimer l'image du commentaire`,
            changeToolTip: `Changer la photo du commentaire`,
        };
    }

    /**
     * récupère l'intervention file lié a un commentaire
     * @idFile : l'id du fichier
     */
    public getInterventionFile(idFile: string): Observable<InterventionFile> {
        const interFiles: Observable<InterventionFile> = this.interventionFileService.findByIdInterventionFileId(
            this.intervention.id,
            TypeReferenceFichier.PHOTO_COMMENTAIRE,
            idFile
        );
        return interFiles;
    }

    /**
     * Filtre la liste de commentaires prédéfinis selon le type de prestation et les codes ref.
     * Supprime les doublons.
     */
    get commentairesPredefinisFiltered(): CommentairePredefini[] {
        let commentairesPredefinis: CommentairePredefini[] = [];

        let commentairesPredefinisFilteredByTypePrestation: CommentairePredefini[] = JSON.parse(
            JSON.stringify(
                this.commentairesPredefinisFiltres.filter(
                    (cp) =>
                        (cp.typePrestation === this.diagnostic?.typePrestation || cp.typePrestation === TypePrestationEnum.COMMUN) &&
                        (cp.reponses.length === 0 ||
                            cp.reponses.filter((rep) => rep.references.some((ref) => this.elements.map((el) => el.codeRef).includes(ref))).length >
                                0) &&
                        (this.typeCommentaireSelected === null || cp.typeCommentaire === this.typeCommentaireSelected) &&
                        (this.typeCommentairelocked || !typesCommentaireAFiltrer.includes(cp.typeCommentaire))
                )
            )
        );

        commentairesPredefinis = [...commentairesPredefinisFilteredByTypePrestation];

        // filtrer les réponses des commentaires prédéfinis en fonction des équipements sélectionnés.
        // attention à bien faire une "deep copy" du tableau pour ne pas impacter le tableau de référence
        for (let i = 0; i < commentairesPredefinis.length; i++) {
            for (let j = 0; j < commentairesPredefinis[i].reponses.length; j++) {
                if (!commentairesPredefinis[i].reponses[j].references.some((ref) => this.elements.map((el) => el.codeRef).includes(ref))) {
                    commentairesPredefinis[i].reponses.splice(j);
                }
            }
        }

        // Suppression des doublons
        commentairesPredefinis = commentairesPredefinis.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });

        return commentairesPredefinis;
    }

    /**
     * @returns les types de commentaires pour ce type de prestation ou TypePrestationEnum.COMMUN
     */
    get typesCommentaires(): TypeCommentaire[] {
        let commentairesPredefinisFilteredByTypePrestation: CommentairePredefini[] = this.allCommentairesPredefinis.filter(
            (cp) =>
                (cp.typePrestation === this.diagnostic?.typePrestation || cp.typePrestation === TypePrestationEnum.COMMUN) &&
                (this.typeCommentairelocked || !typesCommentaireAFiltrer.includes(cp.typeCommentaire))
        );

        // Suppression des doublons
        let temp: TypeCommentaire[] = [];
        temp.push(enumTypesCommentaire.NOTE_PERSONNELLE);
        commentairesPredefinisFilteredByTypePrestation.forEach((com) => {
            if (!temp.includes(com.typeCommentaire)) {
                temp.push(com.typeCommentaire);
            }
        });

        return temp;
    }

    get heigthCommPre() {
        const px = 93 + +document.getElementById('idImageUpload').offsetHeight;
        return 'calc(100% - ' + px + 'px)';
    }
}
