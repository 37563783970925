import { ITypePrestationInterface } from '../../../../services/interfaces/type-prestation.interface';
import { Injectable, Injector, Type } from '@angular/core';
import { TypePrestation, TypePrestationEnum } from '../../../../model/type-prestation.model';
import { Route } from '@angular/router';
import { ContenuDiagnostic } from '../../../../model/diagnostic-contenu.model';
import { Polluant } from '../model/polluant.model';
import { Intervention, PrestationDiagnostic } from '../../../../model/intervention.model';
import { Diagnostic } from '../../../../model/diagnostic.model';
import { IDiagReportData, InterventionReportData } from '../../../../model/rapport.model';
import { Commentaire } from '../../../../model/commentaire.model';
import { CommentairePredefini } from '../../../../model/commentaire-predefini.model';
import { polluantRoutingNavbarRoutes } from '../../polluant/routing/polluant-prelevement/polluant-prelevement-routing.module';
import { Rule } from '../../../../model/regle.model';
import { PolluantPrelevement, PrelevementAcr, PrelevementMest, PrelevementMeta, PrelevementMetop } from '../model/polluant-prelevement.model';
import { DiagnosticService } from 'src/app/services/diagnostic.service';
import { InterventionService } from 'src/app/services/intervention.service';
import { cloneDeep } from 'lodash';
import { Zone } from '../model/zone.model';
import { Besoin } from '../model/besoin.model';
import { ConditionsPrelevement } from '../model/polluant-config.model';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BonCommandeAnalyseAdmin } from 'src/app/model/bon-commande.model';
import { Surface } from '../model/surface.model';
import { LegendScreenshot } from '../../../../model/screenshot-svg.model';
import { cn_storey } from '@acenv/cnmap-editor';
import { Bien, Niveau, Volume } from '../../../../model/bien.model';
import { PointDeControleService } from '../../../../services/point-de-controle.service';
import { TypePrelevementAmiante } from 'src/app/model/prelevement-generique.model';
import { TypeVolume } from '../../../../model/type-element-a-controler.model';

@Injectable({
    providedIn: 'root',
})
export class TypePolluantPrelevementService implements ITypePrestationInterface {
    // PARAM volume
    private typePrestation: TypePrestation = TypePrestationEnum.POLLUANT_PRELEVEMENT;

    private interventionService: InterventionService;
    private diagnosticService: DiagnosticService;

    constructor(private injector: Injector, private readonly pointDeControleService: PointDeControleService) {}

    getTypePrestation(): TypePrestation {
        return this.typePrestation;
    }

    getRoutes(): Route[] {
        return polluantRoutingNavbarRoutes;
    }

    getContenuDiagnosticFromParent(diagnosticToUpdate: Diagnostic, diagnosticParent: Diagnostic): PolluantPrelevement {
        //Récupération du diag par son ID

        let contenuDiagnostic = diagnosticToUpdate.contenuDiagnostic as PolluantPrelevement;
        contenuDiagnostic.listeZones.data.listeZones = {};
        let contenuVisiteChantier: Polluant = cloneDeep(diagnosticParent.contenuDiagnostic) as Polluant;
        contenuDiagnostic.visiteDeChantier.data = contenuVisiteChantier;
        contenuDiagnostic.espaces = contenuVisiteChantier.espaces;

        //Transfert des données des zones au nouveau diagnostic
        // et création de la zone par défaut
        contenuDiagnostic.listeZones.data.listeZones = contenuVisiteChantier.zones.data.zonesList;
        const zoneHorsStrategie = new Zone();
        zoneHorsStrategie.nom = 'Hors stratégie';

        //Ajout de l'ensemble des volumes du périmètre dans la zone Hors Stratégie
        let listeVolumesIdHS = [];
        contenuDiagnostic.listeZones.data.listeZones.forEach((zone: Zone, index: number) => {
            zone.listeSurfaces.forEach((surface: Surface) => {
                listeVolumesIdHS.push(...surface.listeIdVolume);
            });
        });
        zoneHorsStrategie.listeSurfaces[0].listeIdVolume = listeVolumesIdHS;

        contenuDiagnostic.listeZones.data.listeZones.push(zoneHorsStrategie);

        //Transformation des besoins de prélèvement du précédent diagnostic en fonction du type de prélèvement
        let _prelevements = [];
        contenuVisiteChantier.besoins.data.besoinsList.forEach((besoin: Besoin) => {
            let _objMesurage: ConditionsPrelevement = besoin.objectifMesurage;
            if (_objMesurage.typePrelevement == TypePrelevementAmiante.META) {
                _prelevements.push(PrelevementMeta.fromBesoin(besoin));
            } else if (_objMesurage.typePrelevement == TypePrelevementAmiante.METOP) {
                _prelevements.push(PrelevementMetop.fromBesoin(besoin, contenuDiagnostic));
            } else if (_objMesurage.typePrelevement == TypePrelevementAmiante.MEST) {
                _prelevements.push(PrelevementMest.fromBesoin(besoin));
            } else if (_objMesurage.typePrelevement == TypePrelevementAmiante.ACR) {
                _prelevements.push(PrelevementAcr.fromBesoin(besoin));
            }
        });

        contenuDiagnostic.prelevement.data = {
            besoinsList: _prelevements,
            mpcaList: contenuVisiteChantier.mpcaList.data.descriptifList,
        };

        return contenuDiagnostic;
    }

    generateLegendForScreenshot(diagnostic: Diagnostic): LegendScreenshot[] {
        return [];
    }

    getContenuDiagnostic(typePrestation: TypePrestation, prestationDiag?: PrestationDiagnostic): Observable<ContenuDiagnostic> {
        this.interventionService = this.injector.get(InterventionService);
        this.diagnosticService = this.injector.get(DiagnosticService);
        if (prestationDiag?.idPrestationDiagnosticParente != undefined) {
            return this.diagnosticService
                .getDiagnosticByInterventionIdAndPrestationDiagnosticId(
                    prestationDiag.idInterventionParente,
                    prestationDiag.idPrestationDiagnosticParente
                )
                .pipe(
                    switchMap((diag: Diagnostic) => {
                        //Récupération du diag par son ID
                        let contenuDiagnostic = new PolluantPrelevement();
                        contenuDiagnostic.listeZones.setData({});
                        let contenuVisiteChantier: Polluant = cloneDeep(diag.contenuDiagnostic) as Polluant;
                        contenuDiagnostic.visiteDeChantier.data = contenuVisiteChantier;

                        //Transfert des données des zones au nouveau diagnostic
                        // et création de la zone par défaut
                        contenuDiagnostic.listeZones.data.listeZones = contenuVisiteChantier.zones.data.zonesList;
                        const zoneHorsStrategie = new Zone();
                        zoneHorsStrategie.nom = 'Hors stratégie';

                        // Transfert données espaces
                        contenuDiagnostic.espaces = contenuVisiteChantier.espaces;

                        //Ajout de l'ensemble des volumes du périmètre dans la zone Hors Stratégie
                        let listeVolumesIdHS = [];
                        contenuDiagnostic.listeZones.data.listeZones.forEach((zone: Zone, index: number) => {
                            zone.listeSurfaces.forEach((surface: Surface) => {
                                listeVolumesIdHS.push(...surface.listeIdVolume);
                            });
                        });
                        zoneHorsStrategie.listeSurfaces[0].listeIdVolume = listeVolumesIdHS;

                        contenuDiagnostic.listeZones.data.listeZones.push(zoneHorsStrategie);

                        //Transformation des besoins de prélèvement du précédent diagnostic en fonction du type de prélèvement
                        let _prelevements = [];
                        contenuVisiteChantier.besoins.data.besoinsList.forEach((besoin: Besoin) => {
                            let _objMesurage: ConditionsPrelevement = besoin.objectifMesurage;
                            if (_objMesurage.typePrelevement == TypePrelevementAmiante.META) {
                                _prelevements.push(PrelevementMeta.fromBesoin(besoin));
                            } else if (_objMesurage.typePrelevement == TypePrelevementAmiante.METOP) {
                                _prelevements.push(PrelevementMetop.fromBesoin(besoin, contenuDiagnostic));
                            } else if (_objMesurage.typePrelevement == TypePrelevementAmiante.MEST) {
                                _prelevements.push(PrelevementMest.fromBesoin(besoin));
                            } else if (_objMesurage.typePrelevement == TypePrelevementAmiante.ACR) {
                                _prelevements.push(PrelevementAcr.fromBesoin(besoin));
                            }
                        });

                        contenuDiagnostic.prelevement.data = {
                            besoinsList: _prelevements,
                            mpcaList: contenuVisiteChantier.mpcaList.data.descriptifList,
                        };

                        return of(contenuDiagnostic);
                    })
                );
        } else {
            return of(new PolluantPrelevement());
        }
    }

    getCodeBimEquipementBien(typePrestation: TypePrestation): string[] {
        return [];
    }

    getCompletionPercentage(diagnostic: Diagnostic): number {
        return 0;
    }

    getDiagnosticReportData(intervention: Intervention, diagnostic: Diagnostic, rules?: Rule[], optionPlan?: boolean): IDiagReportData {
        return undefined;
    }

    getReportBlockType(componentName: string): Type<any> {
        return undefined;
    }

    isItemAlreadyFilled(diagnostic: Diagnostic, type: string, itemId: string): boolean {
        return false;
    }

    prepareFilteredCommentsForReport(diagnosticData: IDiagReportData, hiddenComments: Map<string, string[]>) {}

    prepareForm(intervention: Intervention, contenuDiagnostic: ContenuDiagnostic) {}

    prepareSpecificComments(diagnostic: Diagnostic, commentairesGeneraux: Commentaire[], commentaires: CommentairePredefini[]) {}

    getDiagnosticBonCommandeData(intervention: Intervention, diagnostic: Diagnostic): IDiagReportData {
        return undefined;
    }

    generateDiagnosticBonCommande(
        intervention: Intervention,
        diagnostic: Diagnostic,
        interReportData: InterventionReportData
    ): BonCommandeAnalyseAdmin {
        return undefined;
    }

    prepareStoreyForScreenshot(diagnostic: Diagnostic, currentStorey: cn_storey, conf: any) {}

    deplaceVolume(diagnostic: Diagnostic, volumeSource: Volume, niveauDestination: Niveau, currentBien: Bien) {
        this.pointDeControleService.deplaceVolume(diagnostic, volumeSource, niveauDestination, currentBien);
    }

    mergeNiveau(diagnostic: Diagnostic, niveauSource: Niveau, niveauDestination: Niveau, currentBien: Bien) {
        this.pointDeControleService.mergeNiveau(diagnostic, niveauSource, niveauDestination, currentBien);
    }

    mergeVolume(diagnostic: Diagnostic, volumeSource: Volume, volumeDestination: Volume, currentBien: Bien, typeVolume: TypeVolume) {
        this.pointDeControleService.mergeVolume(diagnostic, volumeSource, volumeDestination, currentBien, typeVolume);
    }

    deplaceEquipement(idEquipement: string, diagnostic: Diagnostic, volumeDestination: Volume, bien: Bien) {
        this.pointDeControleService.deplaceEquipement(idEquipement, diagnostic, volumeDestination, bien);
    }
}
