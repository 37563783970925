import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent, NotificationService } from 'src/app/commons-lib';
import { combineLatest, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Bien } from 'src/app/model/bien.model';
import { EditMode } from 'src/app/model/edit-mode.model';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { BienService } from 'src/app/services/bien.service';
import { URL_GESTION_BIENS, URL_GESTION_BIENS_EDIT } from 'src/app/shared/constants/url.constants';

@Component({
    selector: 'app-creation-bien',
    templateUrl: './creation-bien.component.html',
    styleUrls: ['./creation-bien.component.scss'],
})
export class CreationBienComponent extends BaseComponent implements OnInit, OnDestroy {
    formBien: FormGroup;
    bien: Bien;
    bienInitial: Bien;
    idBien: string;

    mode: EditMode = 'CREATE';
    isReadOnly = false;

    loadingComplete = false;

    constructor(
        private readonly bienService: BienService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly notificationService: NotificationService
    ) {
        super();
    }

    ngOnInit(): void {
        this.formBien = this.bienService.initBienForm();

        this.cnSpinnerService
            .withSpinner(
                combineLatest([this.route.paramMap, this.route.data]).pipe(
                    takeUntil(this.ngUnsubscribe),
                    switchMap(([params, data]) => {
                        this.idBien = params.get('idBien');
                        if (this.idBien) {
                            this.mode = data.duplicate ? 'DUPLICATE' : 'EDIT';
                            this.isReadOnly = data.consulter;
                            return this.bienService.findOneBien(this.idBien);
                        }
                        return of(null);
                    })
                )
            )
            .subscribe((currentBien) => {
                if (currentBien) {
                    this.bienInitial = JSON.parse(JSON.stringify(currentBien));
                    this.bien = currentBien;
                } else {
                    this.bien = new Bien();
                }

                if (this.mode === 'DUPLICATE') {
                    this.bien.id = null;
                    this.bien.commentairesId = [];
                }

                this.loadingComplete = true;
            });
    }

    validerBien() {
        this.cnSpinnerService.show('Sauvegarde en cours...');

        this.bienService.updateBienWithForm(this.bien, this.formBien);

        if (this.mode === 'EDIT') {
            this.bienService.updateBien(this.bien).subscribe(
                () => {
                    this.cnSpinnerService.hide();
                    this.notificationService.success('Le bien a été modifié');
                    this.back();
                },
                () => this.cnSpinnerService.hide()
            );
        } else {
            this.bienService.createBien(this.bien).subscribe(
                () => {
                    this.cnSpinnerService.hide();
                    const message = this.mode === 'CREATE' ? 'Le bien a été créé' : 'Le bien a été dupliqué';
                    this.notificationService.success(message);
                    this.back();
                },
                () => this.cnSpinnerService.hide()
            );
        }
    }

    editer() {
        if (this.bien.hasNewVersionInRelationInterventionBien) {
            this.bienService.confirmSynchroniserBien(this.bien, () => this.redirectEditer());
        } else {
            this.redirectEditer();
        }
    }

    private redirectEditer() {
        this.router.navigate([URL_GESTION_BIENS_EDIT, this.bien.id]);
    }

    back() {
        this.router.navigate([URL_GESTION_BIENS]);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
