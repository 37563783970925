export const environment = {
    appVersion: require('../../package.json').version,
    production: true,
    devel: false,
    serviceWorker: true,
    host: window.location.protocol + '//' + window.location.hostname,
    apiUrl: '/api',
    managementUrl: '/management',
    apiWikipimUrl: 'https://wikipim.fr/api',
    // apiUrl: 'http://localhost:8082/api',
    // managementUrl: 'http://localhost:8082/management',
    // apiWikipimUrl: 'https://wikipim.fr/api',
    cacherBoutonPhoto: true,
    cacherAgendaDashboard: true,
    cacherLotsInterventionsDashboard: true,
    cacherToDoListDashboard: true,
    cacherMissionsEnCoursDashboard: true,
    cacherGestionInterventionsDashboard: false,
    cacherGestionReferentielsDashboard: false,
    cacherGestionBiensDashboard: false,
    cacherGestionContactsDashboard: false,
    cacherGestionCommandesDashboard: false,
    cacherGestionSynthesesDashboard: false,
    cacherGestionEmployesDashboard: false,
    cacherGestionAgencesDashboard: false,
    cacherGestionCofracsDashboard: false,
    cacherGestionBonsCommande: false,
    cacherGestionDocuments: false,
    cacherCommentairesPredefinis: false,
    cacherGestionLivrablesDashboard: false,
    interventionMinusDay: 0,
    interventionPlusDay: 1,
    webSocketBluetoothPrinting: 'ws://localhost:8098',
    showGenerateSimpleIntervention: false,
    defaultBucket: 'wizydiag-api',
};
