import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationStore, BaseComponent } from 'src/app/commons-lib';
import { Rapport, RapportGenere, RapportsByDiagnostic } from 'src/app/model/rapport.model';
import { CnSpinnerService } from '../cn-spinner/service/cn-spinner.service';
import { DatePipe, formatDate } from '@angular/common';
import { DATE_FORMAT_FRANCAIS_HH_MM } from '../../../shared/constants/cndiag.constants';
import { hasTypeRapportAccess } from '../../../utils/type-rapport.utils';

@Component({
    selector: 'app-rapport-download-modal',
    templateUrl: './rapport-download-modal.component.html',
    styleUrls: ['./rapport-download-modal.component.scss'],
})
export class RapportDownloadModalComponent extends BaseComponent implements OnInit, OnDestroy {
    rapports: RapportsByDiagnostic[] = [];
    versionsPossible: { rapport: Rapport; file?: RapportGenere }[] = [];
    rapportSelected: RapportsByDiagnostic = null;
    versionRapportSelected: { rapport: Rapport; file?: RapportGenere } = null;
    displayTypePrestation = true;
    displayRapportItem = (item: { rapport: Rapport; file?: RapportGenere }) => {
        let formattedDate = formatDate(item.rapport.dateRapport, DATE_FORMAT_FRANCAIS_HH_MM, this.locale);
        if (item.rapport.errorMessage) {
            return `${formattedDate} - ${item.rapport.errorMessage}`;
        } else if (item.file) {
            return `${formattedDate} - ${item.file.name}`;
        } else {
            return `${formattedDate} - no file`;
        }
    };
    disableRapportItem = (item: { rapport: Rapport; file?: RapportGenere }) => {
        return !item.file || !item.file.name;
    };

    private datePipe = new DatePipe('');
    constructor(
        public dialogRef: MatDialogRef<RapportDownloadModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { displayTypePrestation: boolean; listeRapportByIdDiagnostic: RapportsByDiagnostic[] },
        private cnSpinnerService: CnSpinnerService,
        @Inject(LOCALE_ID) private locale: string,
        private authenticationStore: AuthenticationStore
    ) {
        super();
        this.dialogRef.addPanelClass('minwidth1000-dialog');
    }

    ngOnInit(): void {
        if (this.data) {
            this.displayTypePrestation = this.data.displayTypePrestation;
            this.rapports = this.data.listeRapportByIdDiagnostic.map((d) => ({
                ...d,
                rapports: d.rapports.slice().sort((a, b) => (a.dateRapport < b.dateRapport ? -1 : 1)),
            }));
            if (this.rapports && this.rapports.length === 1) {
                // s'il y en a qu'un sélectionner par défaut.
                this.changeTypePrestation(this.rapports[0]);
            }
        }
    }

    changeTypePrestation(item: RapportsByDiagnostic) {
        this.rapportSelected = item;
        this.versionRapportSelected = null;
        let rapports = [];
        if (this.rapportSelected && this.rapportSelected.rapports) {
            rapports = this.rapportSelected.rapports;
        }
        this.versionsPossible = rapports
            .map((rapport) =>
                (
                    rapport.outputFiles.map((file) => ({
                        file,
                        rapport,
                    })) as { file?: RapportGenere; rapport: Rapport }[]
                ).concat(
                    rapport.error
                        ? [
                              {
                                  rapport,
                              },
                          ]
                        : []
                )
            )
            .flat(1)
            .filter((s) => hasTypeRapportAccess(s.file?.type, this.authenticationStore.getCurrentUserSnapshot()));
    }

    changeVersion(item: { rapport: Rapport; file: RapportGenere }) {
        this.versionRapportSelected = item;
    }

    cancel() {
        this.dialogRef.close(false);
    }

    confirm() {
        this.dialogRef.close({
            rapportSelected: this.rapportSelected,
            versionRapportSelected: this.versionRapportSelected.file,
        });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
