<app-mpca-form-skeleton>
    <div title>Après Prélèvement METOP</div>
    <div form class="form" [formGroup]="formApres">
        <div class="column">
            <div class="section">
                <div class="header">Informations client</div>
                <mat-form-field class="ml-3">
                    <mat-label>Date début: </mat-label>
                    <input
                        matInput
                        readonly
                        formControlName="dateDebutPrelevement"
                        [ngxMatDatetimePicker]="datepickerDebut"
                    />
                    <mat-datepicker-toggle matSuffix [for]="datepickerDebut"> </mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #datepickerDebut [showSeconds]="false"></ngx-mat-datetime-picker>
                </mat-form-field>
                <mat-form-field class="ml-3">
                    <mat-label>Date fin: </mat-label>
                    <input
                        matInput
                        readonly
                        formControlName="dateFinPrelevement"
                        [ngxMatDatetimePicker]="datepickerFin"
                    />
                    <mat-datepicker-toggle matSuffix [for]="datepickerFin"> </mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #datepickerFin [showSeconds]="false"></ngx-mat-datetime-picker>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Durée totale du prélèvement:</mat-label>
                    <input matInput [readonly]="true" placeholder="en heures" [value]="calculDuree()" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Pression moyenne (mbar)</mat-label>
                    <input matInput formControlName="pressionMoyenne" [readonly]="disabled" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Température moyenne (°C)</mat-label>
                    <input matInput formControlName="temperatureMoyenne" [readonly]="disabled" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Taux d'humidité intérieur (%)</mat-label>
                    <input matInput formControlName="tauxHumiditeInterieur" [readonly]="disabled" />
                </mat-form-field>
                <div class="cndiad-form-field">
                    <mat-label class="required">Selon 1600-7 et 46-033</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="selon1600746033"
                        [disabled]="disabled"
                    >
                        <option value="true" datatype="icon">check</option>
                        <option value="false" datatype="icon">close</option>
                    </app-widget-select>
                </div>
                <div class="cndiad-form-field">
                    <mat-label class="required">Selon NFX 43-269</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="selonNFX43269"
                        [disabled]="disabled"
                    >
                        <option value="true" datatype="icon">check</option>
                        <option value="false" datatype="icon">close</option>
                    </app-widget-select>
                </div>
            </div>

            <!-- section VALIDATION STRATEGIE -->
            <div class="section">
                <div class="header">Validation stratégie</div>

                <!-- JUSTIFICATIF DE CHANGEMENT-->
                <mat-form-field appearance="outline">
                    <mat-label>Justificatif de changement</mat-label>
                    <input matInput formControlName="justificatifChangement" [readonly]="disabled" />
                </mat-form-field>

                <!-- CLIENT PREVENU PAR -->
                <div class="cndiad-form-field">
                    <mat-label class="required">Client prévenu par</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="clientPrevenuPar"
                        [disabled]="disabled"
                    >
                        <option value="surPlace" datatype="label">Sur place</option>
                        <option value="telephone" datatype="label">Téléphone</option>
                        <option value="email" datatype="label">Email</option>
                    </app-widget-select>
                </div>
            </div>
        </div>
        <div class="column">
            <!-- section INFORMATIONS METEO -->
            <div class="section">
                <div class="header">Informations météo</div>
                <app-information-meteo
                    [formInformationMeteo]="formApres.get('informationMeteo')"
                ></app-information-meteo>
            </div>

            <!-- section FILTRES -->
            <div class="section">
                <filtres-item-list
                    [filterForm]="this.formApres.get('listeFiltres')"
                    (add)="ajouterFiltre()"
                    (edit)="modifierFiltre($event)"
                    (delete)="supprimerFiltre($event)"
                    (duplicate)="dupliquerFiltre($event)"
                    [disabled]="disabled"
                ></filtres-item-list>
            </div>

            <!-- section CONFORMITE -->
            <div class="section">
                <div class="header">Conformité</div>

                <!-- PRELEVEMENT REGLEMENTAIRE -->
                <div class="cndiad-form-field">
                    <mat-label class="required">Prélèvement réglementaire</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="prelevementReglementaire"
                        [disabled]="disabled"
                    >
                        <option value="true" datatype="icon">check</option>
                        <option value="false" datatype="icon">close</option>
                    </app-widget-select>
                </div>

                <!-- JUSTIFICATIF NON REGLEMENTAIRE -->
                <mat-form-field appearance="outline">
                    <mat-label>Justificatif non réglementaire</mat-label>
                    <mat-select required formControlName="justificatifNonReglementaire" [disabled]="disabled">
                        <mat-option value="absenceStrategie">Absence de stratégie</mat-option>
                        <mat-option value="autre">Autre</mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- ACCREDITATION COFRAC -->
                <div class="cndiad-form-field">
                    <mat-label class="required">Accréditation cofrac</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="accreditationCofrac"
                        [disabled]="disabled"
                    >
                        <option value="true" datatype="icon">check</option>
                        <option value="false" datatype="icon">close</option>
                    </app-widget-select>
                </div>
            </div>

            <button mat-button class="bt bouton-impression" (click)="impressionEtiquette()">
                <lib-icon>printer-wireless</lib-icon>
                {{ "Imprimer l'étiquette" }}
            </button>
        </div>
    </div>
</app-mpca-form-skeleton>
