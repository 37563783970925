import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ObjectifsMesurage, PolluantConfig } from '../../../../model/polluant-config.model';
import { Besoin } from '../../../../model/besoin.model';
import { Zone } from '../../../../model/zone.model';
import { PolluantService } from '../../../../services/polluant.service';
import { PolluantPrelevement } from '../../../../model/polluant-prelevement.model';
import { Surface } from '../../../../model/surface.model';
import { Volume } from 'src/app/model/bien.model';
import { MpcaItemOld } from '../../../../model/mpca.model.old';

@Component({
    selector: 'app-prelevement-zone-besoin-modal',
    templateUrl: './prelevement-zone-besoin-modal.component.html',
    styleUrls: ['./prelevement-zone-besoin-modal.component.scss'],
})
export class PrelevementZoneBesoinModalComponent implements OnInit {
    formBuilder: FormBuilder;
    polluantService: PolluantService;

    polluantConfig: PolluantConfig;
    contenuDiagnostic: PolluantPrelevement;
    contenuListesDeroulantes: {
        listeObjMesurage?: ObjectifsMesurage[];
        listeVolumesId?: string[];
        listeMpca?: MpcaItemOld[];
    } = {};
    zone: Zone;
    volumes: Array<Volume>;
    isInEditMode: Boolean;
    formBesoin: FormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<PrelevementZoneBesoinModalComponent>) {
        //Récupération des dépendances
        this.formBuilder = data.deps.formBuilder;
        this.polluantService = data.deps.polluantService;
        this.contenuDiagnostic = data.contenuDiagnostic as PolluantPrelevement;

        //Edit mode
        this.isInEditMode = data.isInEditMode;

        //Récupération de la liste de tous les volumes
        this.volumes = data.volumes;

        //Valeurs listes déroulantes
        //Récupération de tous les objectifs de mesurage
        this.contenuListesDeroulantes.listeObjMesurage = this.contenuDiagnostic.visiteDeChantier.data.objectifsMesurage.data.objectifsMesurage;
        this.contenuListesDeroulantes.listeVolumesId = [];
        this.contenuListesDeroulantes.listeMpca = [];
        this.contenuDiagnostic.listeZones.data.listeZones.forEach((zone: Zone) => {
            //Récupération de tous les volumes de toutes les surfaces de toutes les zones
            zone.listeSurfaces.forEach((surface: Surface) => {
                surface.listeIdVolume.forEach((volumeId: string) => {
                    //On s'assure qu'il n'y a pas de doublons
                    if (
                        this.contenuListesDeroulantes.listeVolumesId.find((volumeIdToFind: string) => {
                            return volumeId == volumeIdToFind;
                        }) == undefined
                    ) {
                        this.contenuListesDeroulantes.listeVolumesId.push(volumeId);
                    }
                });
            });

            //Récupération de la liste de tous les MPCAS de toutes les zones, sans doublons
            zone.listeMpca.forEach((mpca: MpcaItemOld) => {
                if (
                    this.contenuListesDeroulantes.listeMpca.find((mpcaToFind: MpcaItemOld) => {
                        return mpca.id == mpcaToFind.id;
                    }) == undefined
                ) {
                    this.contenuListesDeroulantes.listeMpca.push(mpca);
                }
            });
        });

        //Initialisation du formulaire
        if (data.besoin == undefined) {
            this.formBesoin = this.createFormBesoin(new Besoin());
        } else {
            this.formBesoin = this.createFormBesoin(data.besoin as Besoin);
        }

        this.zone = data.zone;
        this.polluantConfig = data.polluantConfig;
    }

    ngOnInit(): void {}

    /**
     * @description Transforme un besoin en FormGroup
     * @param besoinData Données du besoin à transformer en FormGroup
     * @returns Le FormGroup du besoin
     */
    createFormBesoin(besoinData: Besoin): FormGroup {
        return this.formBuilder.group(
            {
                id: [besoinData.id],
                objectifMesurage: [besoinData.objectifMesurage, Validators.required],
                numero: [besoinData.numero],
                zoneLettre: ['HS'],
                pieceId: [besoinData.pieceId, Validators.required],
                mpcaId: [besoinData.mpcaId, Validators.required],
                // localisation: [besoinData.localisation],
                // commentaire: [besoinData.commentaire],
                listeSequences: this._createFormArrayListeSequences(besoinData.listeSequences),
            },
            { updateOn: 'blur' }
        );
    }
    /**
     * Transforme un tableau de sequences en FormArray
     * @param listeSequences
     * @returns Un form array des sequences passées en paramètre
     */
    _createFormArrayListeSequences(listeSequences: any[]): FormArray {
        let sequencesForm = this.formBuilder.array([]);

        listeSequences.forEach((sequence: any) => {
            sequencesForm.push(
                this.formBuilder.group({
                    dateTimeDebut: [sequence.dateTimeDebut],
                    dateTimeFin: [sequence.dateTimeFin],
                    difference: [sequence.difference],
                })
            );
        });

        return sequencesForm;
    }

    modalAction(actionReturn) {
        let totalErrors = 0;

        this.formBesoin.markAllAsTouched();
        Object.keys(this.formBesoin.controls).forEach((key) => {
            const controlErrors: ValidationErrors = this.formBesoin.get(key).errors;
            if (controlErrors != null) {
                totalErrors++;
            }
        });

        if (actionReturn == 'saveAndClose') {
            if (totalErrors > 0) {
                return;
            }
            this.dialogRef.close({
                actionReturn,
                data: this.formBesoin.getRawValue(),
            });
        } else if (actionReturn == 'cancel') {
            this.dialogRef.close({
                actionReturn,
                data: undefined,
            });
        }
    }
}
