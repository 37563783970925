<div class="sequence-card">
    <div class="card-border--left"></div>
    <div class="content" [ngClass]="{ selected: isSelected }" [formGroup]="sequence">
        <div class="number">
            {{ ('0' + (number + 1)).slice(-3) }}
        </div>
        <div class="dates">
            <div class="debut">{{ sequence.getRawValue().dateTimeDebut }}</div>
            <div class="fin">{{ sequence.getRawValue().dateTimeFin }}</div>
        </div>
        <div class="duration">Durée: {{ sequence.getRawValue().difference }} jours</div>
        <ng-content></ng-content>
    </div>
</div>
