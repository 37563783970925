import { Directive, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Directive({
    selector: '[libLongPress]',
})
export class LongPressDirective implements OnInit, OnDestroy {
    @Input('libLongPress') stopLongPressButton: Observable<boolean>;
    subscription: Subscription;

    _isPressing: boolean;
    _isLongPressing: boolean;
    timeout: any;
    interval: any;

    @Output()
    longPress = new EventEmitter();

    @Output()
    longPressing = new EventEmitter();

    @HostBinding('class.press')
    get isPressing() {
        return this._isPressing;
    }

    @HostBinding('class.longpress')
    get isLongPressing() {
        return this._isLongPressing;
    }

    @HostListener('touchstart', ['$event'])
    @HostListener('mousedown', ['$event'])
    onMouseDown(event) {
        this._isPressing = true;
        this._isLongPressing = false;
        this.timeout = setTimeout(() => {
            this._isLongPressing = true;
            this.longPress.emit(event);
            this.interval = setInterval(() => {
                this.longPressing.emit(event);
            }, 50);
        }, 500);
    }

    @HostListener('touchend')
    @HostListener('mouseup')
    @HostListener('mouseleave')
    endPress() {
        clearTimeout(this.timeout);
        clearInterval(this.interval);
        this._isLongPressing = false;
        this._isPressing = false;
    }

    ngOnInit() {
        if (this.stopLongPressButton) {
            this.subscription = this.stopLongPressButton.subscribe((value) => {
                if (value) {
                    this.endPress();
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.subscription && this.subscription.unsubscribe();
    }
}
