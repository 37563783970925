import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-fontaines',
    templateUrl: './fontaines.component.html',
    styleUrls: ['./fontaines.component.scss'],
})
export class FontainesComponent implements OnInit {
    readonly MENU_NAME = 'Fontaines';

    constructor() {}

    ngOnInit(): void {}
}
