import { cn_svg_map, cn_svg_tool_selection } from '@acenv/cnmap-editor';
import { Volume } from 'src/app/model/bien.model';
import { PointDeControleVolume } from 'src/app/model/point-de-controle.model';
import { CndiagSampleMarker } from './cndiag-tool_sampling_creation';

export class CndiagPolluantTool extends cn_svg_tool_selection {
    private svgMap: cn_svg_map;

    // Si true, la multi sélection pour les objets est activé
    private isMultipleSelectionObject = false;

    constructor(svgMap) {
        super(svgMap);

        this.svgMap = svgMap;
    }

    selectVolumes(values: PointDeControleVolume[] | Volume[]) {
        if (!this.isMultipleSelectionObject) {
            this._controller.clear_selection();
        }
        for (const vol of values) {
            const ele = this._scene.spaces.find((sp) => sp.ID === vol.spaceId);
            if (ele) {
                this._controller.select_element(ele, false);
            }
        }
        this.refreshMap();
    }
    /**
     * Rafraichit le contenu de la map
     */
    refreshMap() {
        this.svgMap.refresh();
    }

    /**
     * Active ou désactive la multi sélection des objets
     * @param isMultipleSelectionObject
     */
    multipleSelectionObject(isMultipleSelectionObject: boolean) {
        this._controller.clear_selection();
        this.isMultipleSelectionObject = isMultipleSelectionObject;
        this.refreshMap();
    }

    remove_elements(selection) {
        for (var i in selection) {
            var elt = selection[i];
            if (!elt.removable) continue;
            if (elt.constructor == CndiagSampleMarker) {
                const marker_index = this._scene.storey.markers.findIndex((marker) => marker.ID === elt.ID);
                if (marker_index >= 0) {
                    this.push_item_set(this._scene.storey, 'markers');
                    this._scene.storey.markers.splice(marker_index, 1);
                }
            }
        }
        super.remove_elements(selection);
        this.call('remove_elements', selection);
    }
}
