import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationStore, HttpErrorService, MongoUtils, NotificationService } from 'src/app/commons-lib';
import * as moment from 'moment';
import { InterventionApiService } from './intervention-api.service';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, observable, Observable, of, throwError } from 'rxjs';
import { catchError, defaultIfEmpty, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { EtatIntervention, Intervention, PrestationDiagnostic, Revision } from '../model/intervention.model';
import { RaisonAnnulation } from '../model/raison-annulation';
import { Diagnostic, EtatDiagnostic, ReportData } from '../model/diagnostic.model';
import { DiagnosticService } from './diagnostic.service';
import { CancelInterventionModalComponent } from '../modules/shared/cancel-intervention-modal/cancel-intervention-modal.component';
import { DATE_FORMAT_INTERNATIONAL_HH_MM } from '../shared/constants/cndiag.constants';
import { URL_TABLEAU_DE_BORD } from '../shared/constants/url.constants';
import { mapTypeReportByTypePrestation, TypeReport } from '../model/reference-prestation.model';
import { BaseObject } from '../model/base-object.model';
import { InterventionFileService } from './intervention-file.service';
import { combineLatestOrEmpty } from '../utils/rxjs.utils';
import { InterventionFile, TypeReferenceFichier } from '../model/intervention-file.model';
import { Hap } from '../modules/diagnostics/hap/model/hap.model';
import { TypePrestation, TypePrestationEnum, typePrestationToPath } from '../model/type-prestation.model';
import { Document } from '../model/document.model';
import { ReferenceService } from './reference.service';
import { ConfigApiService } from './config-api.service';
import { FileApiService } from './file-api.service';
import { BackgroundMapApiService } from './background-map-api.service';
import * as internal from 'stream';
import { resolve } from 'cypress/types/bluebird';
import { waitForAsync } from '@angular/core/testing';

/**
 * Service pour les interventions.
 * Pour la gestion du mode hors-ligne :
 * - ce service n'appelle pas directement les APIs, mais utilise {@link interventionApiService} pour le faire.
 * - le service d'APIs utilise HttpBackend au lieu de HttpClient, pour ne pas passer par les intercepteurs (donc bypasse l'intercepteur {@link HttpErrorInterceptor}).
 * - Le présent service doit donc gérer lui-même les erreurs HTTP.
 */
@Injectable({
    providedIn: 'root',
})
export class InterventionService {
    /**
     * Intervention courante
     */
    private currentIntervention$ = new BehaviorSubject<Intervention>(undefined);

    /**
     * Indique si un bien est en cours d'édition.
     * La validation des infos biens étant manuelle, ceci permet d'empêcher d'autres actions (ex : changement de statut de l'intervention)
     * tant que les modifications ne sont pas soit validées, soit annulées.
     */
    private bienEditMode$ = new BehaviorSubject(false);

    // private jwt: string;

    constructor(
        private interventionApiService: InterventionApiService,
        private configApiService: ConfigApiService,
        private diagnosticService: DiagnosticService,
        private httpErrorService: HttpErrorService,
        private matDialog: MatDialog,
        private router: Router,
        private notificationService: NotificationService,
        private authenticationService: AuthenticationStore,
        private interventionFileService: InterventionFileService,
        private referenceService: ReferenceService,
        private fileApiService: FileApiService,
        private backgroundMapApiService: BackgroundMapApiService
    ) {
        // this.authenticationService.getAuthenticationToken().subscribe((token) => (this.jwt = token));
    }

    /**
     * Charge l'intervention courante à partir de son id.
     */
    loadCurrentIntervention(idIntervention: string): Observable<Intervention> {
        return this.findOne(idIntervention).pipe(
            switchMap((intervention) => {
                this.currentIntervention$.next(intervention);
                return of(intervention);
            })
        );
    }

    /**
     * Recharge l'intervention courante.
     */
    reloadCurrentIntervention(): void {
        const currentInterventionValue = this.currentIntervention$.getValue();
        this.currentIntervention$.next(undefined);
        if (!currentInterventionValue) {
            return;
        }
        this.loadCurrentIntervention(currentInterventionValue.id).pipe(take(1)).subscribe();
    }

    /**
     * Renvoie l'intervention courante.
     */
    getCurrentIntervention(): Observable<Intervention> {
        return this.currentIntervention$.asObservable().pipe(filter((it) => it !== undefined));
    }

    getCurrentInterventionMatchesEtat(etat: EtatIntervention) {
        return this.getCurrentInterventionMatchesCondition((i) => i.etat === etat);
    }

    getCurrentInterventionMatchesCondition(condition: (intervention: Intervention) => boolean) {
        return this.getCurrentIntervention().pipe(map((intervention) => condition(intervention)));
    }

    /**
     * Retourne la dernière valeur du BehaviorSubject de l'intervention courante
     */
    getCurrentInterventionValue(): Intervention {
        return this.currentIntervention$.getValue();
    }

    /**
     * Reset current intervention to undefined
     */
    resetCurrentIntervention() {
        if (this.currentIntervention$.getValue()) {
            this.currentIntervention$.next(undefined);
        }
    }

    /**
     * Permet de savoir si on accède à l'intervention en readOnly ou en modification.
     * On est en readOnly quand l'intervention est TERMINEE ou ANNULEE ou si la personne qui essaie d'y accéder ne fait pas
     * partie des opérateurs définis sur l'intervention (exemple : chef de projet) ou
     * si un diagnostic de l'intervention est EN_ATTENTE, FINI ou TERMINE
     * @param intervention
     * @param currentDiagnostic
     */
    isReadOnlyMode(intervention: Intervention): Observable<boolean> {
        return of(null).pipe(
            switchMap(() => {
                if (
                    !intervention ||
                    intervention.etat === EtatIntervention.TERMINEE ||
                    intervention.etat === EtatIntervention.ANNULEE ||
                    intervention.etat === EtatIntervention.NON_REALISEE ||
                    !this.isCurrentUserCanEdit(intervention)
                ) {
                    return of(true);
                } else {
                    return this.diagnosticService
                        .getAllDiagnosticsForCurrentIntervention(intervention)
                        .pipe(map((diagnostics) => this.diagnosticService.isDiagnosticWaitingOrEnding(diagnostics)));
                }
            }),
            catchError((err) => {
                console.log(err);
                return of(null);
            })
        );
    }

    /**
     * Vérifie que l'utilisateur courant peut éditer l'intervention ou les diagnostics associés
     */
    isCurrentUserCanEdit(intervention: Intervention) {
        const user = this.authenticationService.getCurrentUserSnapshot();
        return intervention.prestataires.map((it) => it.id).includes(user.id);
    }

    /**
     * Indique si un bien est en cours d'édition.
     */
    setBienEditMode(mode: boolean) {
        this.bienEditMode$.next(mode);
    }

    /**
     * Indique si un bien est en cours d'édition.
     */
    getBienEditMode(): Observable<boolean> {
        return this.bienEditMode$.asObservable();
    }

    /**
     * Indique si un bien est en cours d'édition.
     */
    getBienEditModeValue(): boolean {
        return this.bienEditMode$.getValue();
    }

    /**
     * Renvoit l'ensemble des interventions qui ne sont ni TERMINEE ni ANNULLEE et dont le userId est prestataire.
     * @returns
     */
    findAllActiveForCurrentUser(): Observable<Intervention[]> {
        return this.interventionApiService.findAllActiveForCurrentUser().pipe(catchError((err) => this.httpErrorService.handleError(err)));
    }

    /**
     * Renvoie une intervention en mode chef de projet
     */
    findOneOnline(id: string): Observable<Intervention> {
        return this.interventionApiService.findOneOnline(id).pipe(
            catchError((err) => {
                return throwError(err);
            }),
            map((intervention) => {
                return intervention;
            }),
            catchError((err) => this.httpErrorService.handleError(err))
        );
    }

    /**
     * Renvoie une intervention en mode opérateur avec gestion de la base locale
     */
    findOne(id: string): Observable<Intervention> {
        // En mode hors-connexion, ou si l'intervention n'est pas trouvée (intervention locale non synchronisé), on renvoie l'intervention locale
        return this.interventionApiService.findOne(id).pipe(
            catchError((err) => {
                return throwError(err);
            }),
            map((intervention) => {
                return intervention;
            }),
            catchError((err) => this.httpErrorService.handleError(err))
        );
    }

    /**
     * Crée une intervention
     */
    createIntervention(intervention: Intervention): Observable<Intervention> {
        return this.interventionApiService.createIntervention(intervention).pipe(catchError((err) => this.httpErrorService.handleError(err)));
    }

    /**
     * Renvoie toutes les interventions d'un utilisateur pour l'interval de dates paramettré.
     */
    findAllForCurrentUserBetweenDate(): Observable<Intervention[]> {
        return this.interventionApiService.findAllForCurrentUserBetweenDates().pipe(
            catchError((err) => {
                return this.httpErrorService.handleError(err);
            })
        );
    }

    /**
     * Renvoie toutes les interventions étayées pour l'agenda d'un utilisateur.
     */
    // TODO utiliser nveau mode HL
    findAllForCurrentUserForAgenda(): Observable<Intervention[]> {
        return this.interventionApiService.findAllForCurrentUserForAgenda().pipe(
            catchError((err) => {
                return this.httpErrorService.handleError(err);
            })
        );
    }

    /**
     * Renvoie toutes les interventions à afficher sur la liste des intervention pur le chef de projet.
     */
    findAllForListeInterventionsAdmin(): Observable<Intervention[]> {
        return this.interventionApiService.findAllForListeInterventionsAdmin();
    }

    /**
     * Renvoie l'id et le nom de chaque intervention pouvant être parente d'une prestation
     * @param numeroCommande filtre les interventions qui ont ce numéro de commande
     * @param idBien Filtre les interventions qui ont ce bien comme bien principal
     */
    findAllForParentPrestations(idBien: string, numeroCommande: string): Observable<BaseObject[]> {
        return this.interventionApiService.findAllForParentPrestations(idBien, numeroCommande);
    }

    findAllByCommandeId(commandeId: string): Observable<Intervention[]> {
        return this.interventionApiService.findAllByCommandeId(commandeId);
    }

    /**
     * Met à jour une intervention en mode chef de projet
     */
    updateInterventionOnline(intervention: Intervention): Observable<Intervention> {
        return this.interventionApiService.updateInterventionOnline(intervention).pipe(
            catchError((err) => {
                return throwError(err);
            }),
            catchError((err) => {
                return this.httpErrorService.handleError(err);
            })
        );
    }

    /**
     * Met à jour une intervention en mode opérateur avec gestion de la base locale.
     */
    updateIntervention(intervention: Intervention): Observable<Intervention> {
        return this.interventionApiService.updateIntervention(intervention).pipe(
            catchError((err) => {
                return throwError(err);
            }),
            catchError((err) => {
                return this.httpErrorService.handleError(err);
            })
        );
    }

    /**
     * updateRelationInterventionBien fct qui met à jours les paramètres d'une relation intervention/bien dans une intervention
     * /!\ met à jour l'entièreté de l'intervention
     * @param intervention
     * @param idBien
     * @param idNiveau
     * @param idVolume
     * @param paramVolumeVisiteField
     * @param paramVolumeVisite
     */
    updateRelationInterventionBien(
        intervention: Intervention,
        idBien: string,
        idNiveau: string,
        idVolume: string,
        paramVolumeVisiteField: string,
        paramVolumeVisite: string
    ) {
        const bienToUpdate = intervention.relationInterventionBiens.find((it) => it.bien.id === idBien)?.bien;
        if (bienToUpdate) {
            bienToUpdate.description.find((it) => it.id === idNiveau).volumes.find((it) => it.id === idVolume).valeursParametres[
                paramVolumeVisiteField
            ] = paramVolumeVisite;
            this.updateIntervention(intervention).pipe(take(1)).subscribe();
        }
    }

    //////////////////////////////////////////////////////////////////////////////////
    // GESTION DU STATUT
    //////////////////////////////////////////////////////////////////////////////////

    /**
     * Démarre ou continue une intervention si son statut le permet, recharge celle-ci si besoin, puis navigue vers le diagnostic a initialiser (ou non) choisi.
     */
    performCurrentIntervention(prestationDiagnostic: PrestationDiagnostic) {
        if (this.checkBienNotEditionMode()) {
            return;
        }
        const currentIntervention = this.currentIntervention$.getValue();
        if (!currentIntervention.prestationsDiagnostics || !currentIntervention.prestationsDiagnostics.length) {
            this.notificationService.error('Aucune prestation à effectuer pour cette intervention');
            return;
        }
        switch (currentIntervention.etat) {
            case EtatIntervention.NON_DEMARREE:
                this.startPrestation(currentIntervention, prestationDiagnostic)
                    .pipe(
                        catchError((err) => this.httpErrorService.handleError(err)),
                        tap(() => this.reloadCurrentIntervention()),
                        switchMap(() => this.getCurrentIntervention()),
                        take(1)
                    )
                    .subscribe(() => this.navigateToPrestation(prestationDiagnostic));
                break;

            case EtatIntervention.EN_COURS:
            case EtatIntervention.EN_ATTENTE:
            case EtatIntervention.TERMINEE:
                if (prestationDiagnostic.idDiagnostic) {
                    this.navigateToPrestation(prestationDiagnostic);
                } else {
                    this.startPrestation(currentIntervention, prestationDiagnostic)
                        .pipe(
                            catchError((err) => this.httpErrorService.handleError(err)),
                            tap(() => this.reloadCurrentIntervention()),
                            switchMap(() => this.getCurrentIntervention()),
                            take(1)
                        )
                        .subscribe(() => this.navigateToPrestation(prestationDiagnostic));
                }
                break;

            case EtatIntervention.ANNULEE:
                this.notificationService.warn("L'intervention a été annulée");
                break;
            case EtatIntervention.NON_REALISEE:
                this.notificationService.warn("L'intervention est non réalisée");
                break;
        }
    }

    /**
     * Annule l'intervention courante.
     */
    cancelCurrentIntervention() {
        if (this.checkBienNotEditionMode()) {
            return;
        }
        return this.matDialog
            .open(CancelInterventionModalComponent)
            .afterClosed()
            .subscribe((result) => {
                if (result && result !== false) {
                    this.cancelIntervention(this.currentIntervention$.getValue(), result.raisonAnnulation)
                        .pipe(
                            catchError((err) => this.httpErrorService.handleError(err)),
                            tap(() => this.reloadCurrentIntervention()),
                            switchMap(() => this.getCurrentIntervention()),
                            take(1)
                        )
                        .subscribe(() => {
                            this.notificationService.error({
                                message: 'Intervention non réalisée',
                                showCloseButton: false,
                                duration: 2000,
                            });
                        });
                }
            });
    }

    cancelInterventionFromDashboard(interventionFull: Intervention) {
        return this.matDialog
            .open(CancelInterventionModalComponent)
            .afterClosed()
            .subscribe((result) => {
                if (result && result !== false) {
                    this.cancelIntervention(interventionFull, result.raisonAnnulation)
                        .pipe(
                            catchError((err) => this.httpErrorService.handleError(err)),
                            tap(() => this.reloadCurrentIntervention()),
                            switchMap(() => this.getCurrentIntervention()),
                            take(1)
                        )
                        .subscribe(() => {
                            interventionFull.etat = EtatIntervention.NON_REALISEE;

                            //REVISION
                            //si l'intervention est une révision alors il faut mettre estRevisee à false sur les prestationsDiagnostic concernées de l'intervention révisée.
                            let idInterDeBase;
                            interventionFull.prestationsDiagnostics.forEach((prestaDiag) => {
                                if (prestaDiag.revision) {
                                    idInterDeBase = prestaDiag.revision.idInterventionRevisee;
                                }
                            });
                            this.findOne(idInterDeBase).subscribe((inter1) => {
                                interventionFull.prestationsDiagnostics.forEach((prestaDiag2) => {
                                    if (prestaDiag2.revision) {
                                        inter1.prestationsDiagnostics.forEach((prestaDiag1) => {
                                            if (prestaDiag1.id === prestaDiag2.revision.idPrestationDiagRevisee) {
                                                prestaDiag1.estRevisee = false;
                                            }
                                        });
                                    }
                                });
                                this.updateInterventionOnline(inter1).subscribe();
                            });
                            //END REVISION

                            this.notificationService.error({
                                message: 'Intervention non réalisée',
                                showCloseButton: false,
                                duration: 2000,
                            });
                        });
                }
            });
    }

    /**
     * Reprend l'intervention courante
     */

    reprendreCurrentIntervention() {
        if (this.checkBienNotEditionMode()) {
            return;
        }
        this.resumeIntervention(this.currentIntervention$.getValue())
            .pipe(
                catchError((err) => this.httpErrorService.handleError(err)),
                tap(() => this.reloadCurrentIntervention()),
                switchMap(() => this.getCurrentIntervention()),
                take(1)
            )
            .subscribe(() => {
                this.notificationService.success({
                    message: 'Intervention reprise',
                    showCloseButton: false,
                    duration: 2000,
                });
            });
    }

    //pour les révisions
    //Si l'intervention révisée à une prestationDiagnostic déjà révisée alors on ne réactive pas l'intervention
    //pour que les révisions ne saute pas de versions
    async interinterventionDeBaseEstRevise(intervention: Intervention): Promise<boolean> {
        return new Promise((resolve) => {
            this.interventionDeBaseEstRevise(intervention).subscribe((result) => resolve(result));
        });
    }

    interventionDeBaseEstRevise(intervention: Intervention): Observable<boolean> {
        const idInterDeBase = intervention.prestationsDiagnostics.find((prestaDiag) => prestaDiag.revision)?.revision.idInterventionRevisee;

        if (!idInterDeBase) {
            return of(false);
        } else {
            return this.findOne(idInterDeBase).pipe(
                map((inter1) => {
                    let auMoinsUnePrestationDiagnosticDejaRevisee = false;
                    intervention.prestationsDiagnostics.forEach((prestaDiag2) => {
                        if (prestaDiag2.revision) {
                            inter1.prestationsDiagnostics.forEach((prestaDiag1) => {
                                if (prestaDiag1.id === prestaDiag2.revision.idPrestationDiagRevisee) {
                                    if (prestaDiag1.estRevisee === true) {
                                        auMoinsUnePrestationDiagnosticDejaRevisee = true;
                                    }
                                }
                            });
                        }
                    });
                    return auMoinsUnePrestationDiagnosticDejaRevisee;
                })
            );
        }
    }

    //Pour les révisions
    //on passe estRevisee a true sur les prestationsDiagnostic de l'intervention de base
    estReviseeSurPrestationsDeBase(intervention: Intervention) {
        let idInterDeBase;
        intervention.prestationsDiagnostics.forEach((prestaDiag) => {
            if (prestaDiag.revision) {
                idInterDeBase = prestaDiag.revision.idInterventionRevisee;
            }
        });
        if (idInterDeBase) {
            this.findOne(idInterDeBase).subscribe((inter1) => {
                intervention.prestationsDiagnostics.forEach((prestaDiag2) => {
                    if (prestaDiag2.revision) {
                        inter1.prestationsDiagnostics.forEach((prestaDiag1) => {
                            if (prestaDiag1.id === prestaDiag2.revision.idPrestationDiagRevisee) {
                                prestaDiag1.estRevisee = true;
                            }
                        });
                    }
                });
                this.updateInterventionOnline(inter1).subscribe();
            });
        }
    }
    /**
     * Reprend l'intervention courante, recharge celle-ci.
     * En mode admin, on ne fait pas de redirection vers l'intervention, l'utilisateur reste sur son tableau d'intervention
     */
    async resumeCurrentIntervention(intervention: Intervention, modeAdmin: boolean = false) {
        if (this.checkBienNotEditionMode()) {
            return;
        }

        if (await this.interinterventionDeBaseEstRevise(intervention)) {
            return;
        } else {
            return this.startPrestation(intervention).subscribe(() => {
                this.estReviseeSurPrestationsDeBase(intervention);
                this.notificationService.success({
                    message: 'Intervention reprise',
                    showCloseButton: false,
                    duration: 3000,
                });
                if (!modeAdmin) {
                    this.router.navigate(['/interventions/' + intervention.id]);
                }
                return true;
            });
        }
    }

    reprendreIntervention(intervention: Intervention, modeAdmin: boolean = false) {
        if (this.checkBienNotEditionMode()) {
            return;
        }
        this.checkState(intervention);
        this.notificationService.success({
            message: 'Intervention reprise',
            showCloseButton: false,
            duration: 1000,
        });
        this.updateIntervention(intervention);
        if (!modeAdmin) {
            this.router.navigate(['/interventions/' + intervention.id]);
        }
    }

    /**
     * Annule une intervention.
     *
     * Met à jour l'intervention, avec les modifications suivantes
     * - passe son état à "NON_REALISEE"
     * - renseigne sa date de fin effective à maintenant
     * - renseigne sa raison d'annulation
     */
    cancelIntervention(intervention: Intervention, raisonAnnulation: RaisonAnnulation): Observable<any> {
        intervention.etat = EtatIntervention.NON_REALISEE;
        intervention.dateHeureFinEffective = moment().format(DATE_FORMAT_INTERNATIONAL_HH_MM);
        intervention.raisonAnnulation = raisonAnnulation;
        return this.updateIntervention(intervention);
    }
    /**
     * Reprend une intervention.
     *
     * Met à jour l'intervention, avec les modifications suivantes
     * - passe son état à "EN_COURS"
     * - renseigne sa date de fin effective à null
     * - renseigne sa raison d'annulation à null
     */

    resumeIntervention(intervention: Intervention): Observable<any> {
        intervention.etat = EtatIntervention.EN_COURS;
        intervention.dateHeureFinEffective = null;
        intervention.raisonAnnulation = null;
        return this.updateIntervention(intervention);
    }

    /**
     * Passe l'intervention à l'état TERMINEE et les diagnostics à TERMINEE
     * @param intervention
     */
    closeIntervention(intervention: Intervention): Observable<any[]> {
        return this.diagnosticService.getAllDiagnosticsForCurrentIntervention(intervention).pipe(
            switchMap((diags) => {
                return forkJoin([
                    ...diags.map((diag) => {
                        if (diag.etat === EtatDiagnostic.FINI) {
                            diag.etat = EtatDiagnostic.TERMINE;
                            return this.diagnosticService.upsert(intervention, diag);
                        } else {
                            return of(diag);
                        }
                    }),
                ]);
            }),
            // Export des rapports
            switchMap((diags) => {
                return combineLatestOrEmpty([
                    of(diags),
                    ...diags
                        .filter((d) => d.etat === EtatDiagnostic.TERMINE)
                        .map((diagTermineTemp) => {
                            const idReferencePrestation = intervention.prestationsDiagnostics.find(
                                (presta) => presta.idDiagnostic === diagTermineTemp.id
                            ).prestation.referencePrestation.id;
                            return this.diagnosticService.exportReport(diagTermineTemp, idReferencePrestation, intervention.id);
                        }),
                ]);
            }),
            // Export des bons commandes
            switchMap(([diags]) => {
                return combineLatestOrEmpty([
                    of(diags),
                    ...diags.map((diagTemp) => {
                        const idReferencePrestation = intervention.prestationsDiagnostics.find((presta) => presta.idDiagnostic === diagTemp.id)
                            .prestation.referencePrestation.id;
                        return this.diagnosticService.exportBonCommandeAll(
                            diagTemp,
                            idReferencePrestation,
                            intervention.id,
                            intervention.relationInterventionBiens.map((it) => it.bien.id)
                        );
                    }),
                ]);
            }),
            switchMap(([diags]) => {
                intervention.etat = EtatIntervention.TERMINEE;
                intervention.dateHeureFinEffective = moment().format(DATE_FORMAT_INTERNATIONAL_HH_MM);
                return forkJoin([this.updateIntervention(intervention), of(diags)]);
            })
        );
    }

    /**
     * Envoie une notification, et renvoie true, si une modification d'un bien est en cours.
     */
    private checkBienNotEditionMode(): boolean {
        if (this.getBienEditModeValue()) {
            this.notificationService.warn(`Vous devez valider ou annuler la modification du bien avant de pouvoir faire cette action`);
            return true;
        }
        return false;
    }

    /**
     * Démarre ou redémarre une prestation.
     *
     * Met à jour l'intervention, avec les modifications suivantes
     * - passe son état à "en cours"
     * - renseigne sa date de début effective à maintenant (seulement si elle n'a pas déjà été renseignée)
     * - vide sa date de fin effective
     * - vide sa raison d'annulation
     */
    startPrestation(intervention: Intervention, prestationDiagnostic?: PrestationDiagnostic): Observable<any> {
        let diagnosticsToUpsert: Diagnostic;
        // Spécifie si le diagnotic existe déjà ou pas => si idDiagnostic == null alors false sinon true
        let diagnosticAlreadyExist = true;
        if (typeof prestationDiagnostic !== 'undefined' && prestationDiagnostic.idDiagnostic == null) {
            diagnosticAlreadyExist = false;

            prestationDiagnostic.idDiagnostic = MongoUtils.generateObjectId();
            const diagnostic: Diagnostic = new Diagnostic();
            diagnostic.id = prestationDiagnostic.idDiagnostic;
            diagnostic.typePrestation = prestationDiagnostic.prestation.typePrestation;
            diagnostic.idIntervention = intervention.id;

            // Check si besoin d'avoir les config (Polluant, Cee, Elec..)
            let needConfig = this.configApiService.needConfig(prestationDiagnostic.prestation.typePrestation);
            if (needConfig) {
                return this.configApiService.findAllConfig(diagnostic.typePrestation).pipe(
                    switchMap((configs: any) => {
                        diagnostic.idConfig = this.configApiService.findLastIdConfig(configs);
                        if (diagnostic.idConfig == null) {
                            this.notificationService.error('Erreur : Aucune config dans la base locale, merci de faire une synchronisation.');
                            return EMPTY;
                        }
                        console.log('prestationDiagnostic', prestationDiagnostic);

                        return this.diagnosticService.getContenuDiagnostic(diagnostic.typePrestation, prestationDiagnostic);
                    }),
                    switchMap((contenuDiagnostic) => {
                        diagnostic.contenuDiagnostic = Diagnostic.initContenu(contenuDiagnostic);
                        diagnostic.etat = EtatDiagnostic.EN_COURS;
                        this.generateRefRapport(intervention, diagnostic);
                        diagnosticsToUpsert = diagnostic;
                        this.checkState(intervention);
                        return combineLatest([
                            diagnosticAlreadyExist
                                ? this.diagnosticService.findOne(prestationDiagnostic.idDiagnostic)
                                : this.diagnosticService.upsert(intervention, diagnosticsToUpsert),
                            this.updateIntervention(intervention),
                        ]);
                    })
                );
            } else {
                return this.diagnosticService.getContenuDiagnostic(diagnostic.typePrestation).pipe(
                    switchMap((contenuDiagnostic) => {
                        diagnostic.contenuDiagnostic = Diagnostic.initContenu(contenuDiagnostic);
                        diagnostic.etat = EtatDiagnostic.EN_COURS;
                        this.generateRefRapport(intervention, diagnostic);
                        diagnosticsToUpsert = diagnostic;
                        this.checkState(intervention);
                        return combineLatest([
                            diagnosticAlreadyExist
                                ? this.diagnosticService.findOne(prestationDiagnostic.idDiagnostic)
                                : this.diagnosticService.upsert(intervention, diagnosticsToUpsert),
                            this.updateIntervention(intervention),
                        ]);
                    })
                );
            }
        } else {
            this.checkState(intervention);
            return combineLatest([
                diagnosticAlreadyExist
                    ? intervention.prestationsDiagnostics[0].idDiagnostic
                        ? this.diagnosticService.findOne(intervention.prestationsDiagnostics[0].idDiagnostic)
                        : of(null)
                    : this.diagnosticService.upsert(intervention, diagnosticsToUpsert),
                this.updateIntervention(intervention),
            ]);
        }

        // Si on est dans un des états :
        // if ([EtatIntervention.NON_DEMARREE, EtatIntervention.ANNULEE].find((etat) => intervention.etat === etat)) {
        //     intervention.etat = EtatIntervention.EN_COURS;
        //     intervention.dateHeureDebutEffective = moment().format(DATE_FORMAT_INTERNATIONAL_HH_MM);
        //     intervention.dateHeureFinEffective = null;
        //     intervention.raisonAnnulation = null;
        // }
    }

    checkState(intervention: Intervention) {
        if (
            [EtatIntervention.NON_DEMARREE, EtatIntervention.ANNULEE, EtatIntervention.NON_REALISEE, EtatIntervention.NON_REALISEE].find(
                (etat) => intervention.etat === etat
            )
        ) {
            intervention.etat = EtatIntervention.EN_COURS;
            intervention.repriseAnnulationPossible = true;
            intervention.dateHeureDebutEffective = moment().format(DATE_FORMAT_INTERNATIONAL_HH_MM);
            intervention.dateHeureFinEffective = null;
            intervention.raisonAnnulation = null;
        }
    }

    cancelPrestation(datas: any): Observable<any> {
        return forkJoin(
            datas.prestationDiagnostic.map((prestaDiag) =>
                this.diagnosticService.getContenuDiagnostic(prestaDiag.prestation.prestation.typePrestation).pipe()
            )
        ).pipe(
            switchMap((contenuDiagnostic) => {
                const diagnosticsToUpsert: Diagnostic[] = [];
                datas.prestationDiagnostic.forEach((prestaDiag, index) => {
                    if (prestaDiag.diagnostic == null || prestaDiag.diagnostic == undefined) {
                        prestaDiag.diagnostic = new Diagnostic();
                        prestaDiag.prestation.idDiagnostic = MongoUtils.generateObjectId();
                        prestaDiag.diagnostic.id = prestaDiag.prestation.idDiagnostic;
                        prestaDiag.diagnostic.typePrestation = prestaDiag.prestation.prestation.typePrestation;
                        prestaDiag.diagnostic.idIntervention = datas.intervention.id;
                        prestaDiag.diagnostic.contenuDiagnostic = Diagnostic.initContenu(contenuDiagnostic[index]);

                        this.generateRefRapport(datas.intervention, prestaDiag.diagnostic);
                    }

                    // On passe l'état du diagnostique à ANNULE dans le diagnostic et dans la prestation (prestation.etatDiagnostic utilisé uniquement côté front)
                    prestaDiag.diagnostic.etat = EtatDiagnostic.ANNULE;
                    prestaDiag.prestation.etatDiagnostic = EtatDiagnostic.ANNULE;
                    datas.intervention.prestationsDiagnostics.map((presta: { id: any }) =>
                        presta.id === prestaDiag.prestation.id ? prestaDiag.prestation : presta
                    );

                    diagnosticsToUpsert.push(prestaDiag.diagnostic);
                });

                // Nombre de diagnostic à l'état FINI
                const nbDiagEnding: number = datas.intervention.prestationsDiagnostics.filter((presta: { etatDiagnostic: EtatDiagnostic }) => {
                    return presta.etatDiagnostic === EtatDiagnostic.FINI;
                }).length;

                // Nombre de diagnostic à l'état EN_ATTENTE
                const nbDiagWaiting: number = datas.intervention.prestationsDiagnostics.filter((presta: { etatDiagnostic: EtatDiagnostic }) => {
                    return presta.etatDiagnostic === EtatDiagnostic.EN_ATTENTE;
                }).length;

                // Nombre de diagnostic à l'état ANNULE
                const nbDiagAnnule: number = datas.intervention.prestationsDiagnostics.filter((presta: { etatDiagnostic: EtatDiagnostic }) => {
                    return presta.etatDiagnostic === EtatDiagnostic.ANNULE;
                }).length;

                // Si tous les diag sont FINI ou ANNULE, l'intervention passe à l'état TERMINE et tous les diagnostics sont passés à clôturé
                if ((nbDiagEnding + nbDiagAnnule).toFixed(0) == datas.intervention.prestationsDiagnostics.length) {
                    // On met à jour les diagnostics annulés puis on clôture l'intervention
                    return forkJoin([...diagnosticsToUpsert.map((diagnostic) => this.diagnosticService.upsert(datas.intervention, diagnostic))]).pipe(
                        switchMap(() => {
                            return this.closeIntervention(datas.intervention);
                        })
                    );
                } else {
                    // Si au moins 1 diag est à l'état EN_ATTENTE et tous les autres diags sont FINI ou ANNULE, on passe l'intervention à l'état EN_ATTENTE
                    // Sinon l'intervention passe EN_COURS
                    if (
                        nbDiagWaiting > 0 &&
                        (nbDiagEnding + nbDiagWaiting + nbDiagAnnule).toFixed(0) == datas.intervention.prestationsDiagnostics.length
                    ) {
                        datas.intervention.etat = EtatIntervention.EN_ATTENTE;
                        datas.intervention.dateHeureDebutEffective = datas.intervention.dateHeureDebutEffective
                            ? datas.intervention.dateHeureDebutEffective
                            : moment().format(DATE_FORMAT_INTERNATIONAL_HH_MM);
                    } else {
                        datas.intervention.etat = EtatIntervention.EN_COURS;
                        datas.intervention.dateHeureDebutEffective = datas.intervention.dateHeureDebutEffective
                            ? datas.intervention.dateHeureDebutEffective
                            : moment().format(DATE_FORMAT_INTERNATIONAL_HH_MM);
                    }

                    return forkJoin([
                        this.updateIntervention(datas.intervention),
                        ...diagnosticsToUpsert.map((diagnostic) => this.diagnosticService.upsert(datas.intervention, diagnostic)),
                    ]);
                }
            })
        );
    }

    /**
     * Réactive une prestation après une annulation
     * @returns
     */
    resumePrestations(datas: any) {
        const diagnosticsToUpsert: Diagnostic[] = [];
        return this.interventionDeBaseEstRevise(datas.intervention).pipe(
            switchMap((revise) => {
                if (revise) {
                    return of(false);
                } else {
                    this.estReviseeSurPrestationsDeBase(datas.intervention);
                    datas.prestationDiagnostic.forEach((prestaDiag: { prestation: { etatDiagnostic: EtatDiagnostic }; diagnostic: Diagnostic }) => {
                        prestaDiag.prestation.etatDiagnostic = EtatDiagnostic.EN_COURS;
                        if (prestaDiag.diagnostic) {
                            prestaDiag.diagnostic.etat = EtatDiagnostic.EN_COURS;
                            this.generateRefRapport(datas.intervention, prestaDiag.diagnostic);
                            diagnosticsToUpsert.push(prestaDiag.diagnostic);
                        }
                    });

                    datas.intervention.etat = EtatIntervention.EN_COURS;
                    datas.intervention.dateHeureFinEffective = null;

                    return forkJoin([
                        this.updateIntervention(datas.intervention),
                        ...diagnosticsToUpsert.map((diagnostic) => this.diagnosticService.upsert(datas.intervention, diagnostic)),
                    ]);
                }
            })
        );
    }

    /**
     * Navigue vers le diagnostic choisi de l'intervention courante.
     * Upsert le diagnostic quitté si présent, puis navigue vers le diagnostic choisi.
     */
    private navigateToPrestation(prestationDiagnostic: PrestationDiagnostic) {
        const currentIntervention = this.currentIntervention$.getValue();
        if (this.diagnosticService.getCurrentDiagnosticValue() !== undefined) {
            this.diagnosticService
                .getCurrentDiagnostic()
                .pipe(
                    switchMap((diag) => {
                        return this.diagnosticService.upsert(currentIntervention, diag);
                    }),
                    take(1)
                )
                .subscribe(() => {
                    this.router.navigate([
                        `/interventions/${currentIntervention.id}/diagnostics/${prestationDiagnostic.idDiagnostic}/${typePrestationToPath(
                            prestationDiagnostic.prestation?.typePrestation
                        )}/config`,
                    ]);
                });
        } else {
            this.router.navigate([`/interventions/${currentIntervention.id}/diagnostics/${prestationDiagnostic.idDiagnostic}`]);
        }
    }

    /**
     * Génère la référence de chaque rapport du diagnostic suivant son type de prestation
     * @param intervention
     * @param diagnostic
     */
    public generateRefRapport(intervention: Intervention, diagnostic: Diagnostic) {
        mapTypeReportByTypePrestation
            .get(diagnostic.typePrestation)
            .filter((it) => {
                if (it === TypeReport.REPORT) {
                    return it;
                }
            })
            .forEach((typeReport) => {
                let reportDataExistant = diagnostic.reportDatas.find((reportDataTemp) => {
                    return reportDataTemp.typeReport === typeReport;
                });
                // Si le reportData n'existe pas encore, on l'initialise
                if (!reportDataExistant) {
                    reportDataExistant = new ReportData(typeReport, null, null);
                    diagnostic.reportDatas.push(reportDataExistant);
                }

                // On génère la refRapport
                this.generateRefRapportInReportDataExistant(intervention, diagnostic, reportDataExistant);
            });
    }

    /**
     * Génère la référence du rapport passé en paramètre suivant le type de rapport
     * @param intervention
     * @param diagnostic
     */

    generateRefRapportInReportDataExistant(intervention: Intervention, diagnostic: Diagnostic, reportDataExistant: ReportData) {
        const date = moment(intervention.dateHeureDebutEffective).format('DD-MM-YY');
        // On génère la refRapport
        switch (reportDataExistant.typeReport) {
            case TypeReport.REPORT: {
                let prestationDiagnostic = intervention.prestationsDiagnostics.find((presta) => presta.idDiagnostic === diagnostic.id);
                let revision: string = '';
                if (prestationDiagnostic.revision) {
                    revision = `_REV${prestationDiagnostic.revision.incrementRevision.toString()}`;
                }
                reportDataExistant.refRapport = `${intervention.numeroCommande}_${intervention.nom}_${diagnostic.typePrestation}${revision}`;
                break;
            }
            case TypeReport.BON_COMMANDE: {
                reportDataExistant.refRapport = (
                    TypeReport.BON_COMMANDE +
                    ' ' +
                    intervention.agence.nom.substring(0, 2) +
                    ' ' +
                    date +
                    ' ' +
                    Intervention.getRelationInterventionBienPrincipal(intervention).bien.nom.substring(0, 5) +
                    ' ' +
                    diagnostic.typePrestation.substring(0, 6)
                ).toUpperCase();
                break;
            }
        }
    }

    /**
     *
     * @param interventionToUpdate
     * @param prestationToUpdate
     * @returns
     */
    updateInterventionAndDiagnosticWithPrestationParente(
        interventionToUpdate: Intervention,
        prestationToUpdate: PrestationDiagnostic,
        updateInterventionNeeded: boolean
    ): Observable<any[]> {
        // Si les infos interventionParente ou prestationParente n'existe pas, il n'y a rien à faire
        if (!prestationToUpdate.idInterventionParente || !prestationToUpdate.idPrestationDiagnosticParente) {
            return of([]);
        }

        return combineLatest([
            this.findOne(prestationToUpdate.idInterventionParente),
            this.diagnosticService.getDiagnosticByInterventionIdAndPrestationDiagnosticId(
                prestationToUpdate.idInterventionParente,
                prestationToUpdate.idPrestationDiagnosticParente
            ),
            prestationToUpdate.idDiagnostic ? this.diagnosticService.findOne(prestationToUpdate.idDiagnostic) : of(null),
        ]).pipe(
            switchMap(([interventionParente, diagnosticParent, diagnosticToUpdate]) => {
                return combineLatestOrEmpty([
                    of(interventionParente),
                    of(diagnosticParent),
                    of(diagnosticToUpdate),
                    this.referenceService.findAllTypesDocument(),
                    // InterventionFile existant pour l'intervention et le diagnostic
                    this.interventionFileService.getInterventionFilesOfIntervention(interventionToUpdate),
                    diagnosticToUpdate
                        ? this.interventionFileService.getInterventionFilesOfDiagnostic(interventionToUpdate, diagnosticToUpdate as Diagnostic)
                        : of([]),
                    // InterventionFile à dupliquer pour l'intervention et le diagnostic
                    this.interventionFileService.getInterventionFilesOfIntervention(interventionParente),
                    diagnosticParent ? this.interventionFileService.getInterventionFilesOfDiagnostic(interventionParente, diagnosticParent) : of([]),
                ]);
            }),
            switchMap(
                ([
                    interventionParente,
                    diagnosticParent,
                    diagnosticToUpdate,
                    allTypeDocuments,
                    interventionFilesOfInterventionExistant,
                    interventionFilesOfDiagnosticExistant,
                    interventionFilesOfIntervention,
                    interventionFilesOfDiagnostic,
                ]) => {
                    const observables$: Observable<any>[] = [];

                    // Mise à jour de l'intervention
                    // Commentaires
                    interventionToUpdate.commentaires = [...interventionParente.commentaires];
                    interventionToUpdate.commentairesId = [...interventionParente.commentairesId];

                    // Documents, la suppression des documents existant est géré lors de l'update de l'intervention côté back
                    // Ici, on doit récupérer les documents liés à la prestation parente et
                    // changer leur prestation avec la prestation actuelle pour pouvoir les afficher dans la partie opérateur
                    if (interventionParente.documents) {
                        const typePrestationsInIntervention = interventionToUpdate.prestationsDiagnostics.map(
                            (prestationDiagTemp) => prestationDiagTemp.prestation.typePrestation
                        );

                        const prestationToDiagParent = (interventionParente as Intervention).prestationsDiagnostics.find(
                            (prestaDiagTemp) => prestaDiagTemp.id == prestationToUpdate.idPrestationDiagnosticParente
                        );

                        // Documents liés à l'intervention/prestation parente :
                        const documentToAdd: Document[] = [
                            ...interventionParente.documents
                                .filter((docFilterTemp: Document) =>
                                    docFilterTemp.typePrestations.includes(prestationToDiagParent.prestation.typePrestation)
                                )
                                .map((docTemp: Document) => {
                                    const typeDocumentOriginal = allTypeDocuments.find((it) => it.id == docTemp.typeDocument.id);
                                    // Filtre des checkpoints
                                    typeDocumentOriginal.typeDocumentCheckpoint = typeDocumentOriginal.typeDocumentCheckpoint.filter((typeDocTemp) =>
                                        typePrestationsInIntervention.includes(typeDocTemp.referencePrestation.typePrestation)
                                    );

                                    docTemp.id = undefined;
                                    docTemp.typePrestations = typePrestationsInIntervention;
                                    docTemp.typeDocument = typeDocumentOriginal;
                                    return docTemp;
                                }),
                        ];

                        // On supprime tous les documents non obligatoire de l'intervention à mettre à jour car ils ont forcément étaient rajoutés à la main
                        interventionToUpdate.documents = interventionToUpdate.documents.filter((docTemp) => docTemp.requiredForIntervention);

                        documentToAdd.forEach((docTemp) => {
                            // On récupère l'index du document qui a le même nom dans l'intervention à mettre à jour que le document que l'on veut lui ajouter
                            const indexDocExisting = interventionToUpdate.documents.findIndex(
                                (docInterToUpdateTemp) => docInterToUpdateTemp.nom == docTemp.nom
                            );
                            // S'il existe, on le ressemble
                            // Sinon on ajoute juste le document dans la liste des document de l'intervention à mettre à jour
                            if (indexDocExisting != -1) {
                                interventionToUpdate.documents.splice(indexDocExisting, 1, docTemp);
                            } else {
                                interventionToUpdate.documents.push(docTemp);
                            }
                        });
                    }

                    // InterventionFile intervention
                    // Suppression des interventionFileExistant
                    interventionFilesOfInterventionExistant.forEach((interventionFileExistantTemp: InterventionFile) => {
                        observables$.push(this.interventionFileService.deleteInterventionFile(interventionFileExistantTemp, true));
                    });
                    // Duplication des InterventionFile de l'intervention parente
                    interventionFilesOfIntervention.forEach(
                        (interventionFileTemp: { typeReferenceFichier: TypeReferenceFichier; referenceId: string; fileId: string }) => {
                            // on ne tient pas compte du bien car il est géré lors de la creation/modification du bien
                            if (interventionFileTemp.typeReferenceFichier !== TypeReferenceFichier.PHOTO_BIEN) {
                                const interventionFileNew = this.interventionFileService.getNewInterventionFile(
                                    interventionToUpdate.id,
                                    undefined,
                                    interventionFileTemp.referenceId,
                                    interventionFileTemp.typeReferenceFichier,
                                    interventionFileTemp.fileId
                                );
                                observables$.push(this.interventionFileService.upsert(interventionFileNew));
                            }
                        }
                    );

                    // InterventionFile diagnostic
                    if (diagnosticToUpdate) {
                        // Suppression des interventionFileExistant
                        interventionFilesOfDiagnosticExistant.forEach((interventionFileExistantTemp: any) => {
                            observables$.push(this.interventionFileService.deleteInterventionFile(interventionFileExistantTemp, true));
                        });
                        // Duplication des InterventionFile du diagnostic parente
                        interventionFilesOfDiagnostic.forEach(
                            (interventionFileTemp: { referenceId: string; typeReferenceFichier: TypeReferenceFichier; fileId: string }) => {
                                const interventionFileNew = this.interventionFileService.getNewInterventionFile(
                                    interventionToUpdate.id,
                                    prestationToUpdate.idDiagnostic,
                                    interventionFileTemp.referenceId,
                                    interventionFileTemp.typeReferenceFichier,
                                    interventionFileTemp.fileId
                                );
                                observables$.push(this.interventionFileService.upsert(interventionFileNew));
                            }
                        );
                    }

                    // Contact de l'intervention
                    // Cas particulier du HAP : On vérifie la prestation à créer et la prestation parente.
                    // Suivant les cas, il faut récupérer des informations de contact dans le diagnostic pour l'intervention courante.
                    if (
                        diagnosticParent &&
                        ((prestationToUpdate.prestation.typePrestation === TypePrestationEnum.HAP_VISITE_RECONNAISSANCE &&
                            diagnosticParent.typePrestation === TypePrestationEnum.HAP_ETUDE_SITUATION) ||
                            (prestationToUpdate.prestation.typePrestation === TypePrestationEnum.HAP_TERRAIN &&
                                [TypePrestationEnum.HAP_ETUDE_SITUATION, TypePrestationEnum.HAP_VISITE_RECONNAISSANCE].includes(
                                    diagnosticParent.typePrestation
                                )))
                    ) {
                        // Récupération du diagnostic correspondant à la prestationParente
                        // Récupération des contacts pré-définis suivant le cas
                        let contactsDefinis = [];
                        const contenuDiagnostic = diagnosticParent.contenuDiagnostic as Hap;
                        if (prestationToUpdate.prestation.typePrestation === TypePrestationEnum.HAP_VISITE_RECONNAISSANCE) {
                            contactsDefinis = contenuDiagnostic.preparation.valeur.reconnaissance.contacts;
                        } else if (prestationToUpdate.prestation.typePrestation === TypePrestationEnum.HAP_TERRAIN) {
                            contactsDefinis = contenuDiagnostic.preparation.valeur.terrain.contacts;
                        }

                        // Si des contacts ont été définis, on remplace ceux saisie lors de l'intervention et on modifie le rdvContact avec le 1er contact (choix par défaut)
                        // Sinon, on ne modifie pas les contacts
                        if (contactsDefinis && contactsDefinis.length > 0) {
                            interventionToUpdate.contacts = contactsDefinis;
                            interventionToUpdate.rdvContact = contactsDefinis[0];
                        }
                    }

                    // Diagnostic
                    if (prestationToUpdate.idDiagnostic) {
                        observables$.push(
                            this.diagnosticService.updateDiagnosticWithDiagnosticParent(interventionToUpdate, diagnosticToUpdate, diagnosticParent)
                        );
                    }

                    // Ajout de la mise à jour de l'intervention
                    if (updateInterventionNeeded) {
                        observables$.push(this.updateIntervention(interventionToUpdate));
                    }

                    return observables$.length === 0 ? of([]) : forkJoin(observables$);
                }
            )
        );
    }

    findReferencePrestationByIdDiagnostic(intervention: Intervention, diagnostic: Diagnostic) {
        if (intervention && diagnostic) {
            const prestationDiagnosticCourant = intervention.prestationsDiagnostics.find(
                (prestationDiagTemp) => prestationDiagTemp.idDiagnostic === diagnostic.id
            );
            return prestationDiagnosticCourant.prestation.referencePrestation;
        }
        return null;
    }

    findReferencePrestationByTypePrestation(intervention: Intervention, typePrestation: TypePrestation) {
        if (intervention && typePrestation) {
            const prestationDiagnosticCourant = intervention.prestationsDiagnostics.find(
                (prestationDiagTemp) => prestationDiagTemp.prestation.typePrestation == typePrestation
            );
            return prestationDiagnosticCourant ? prestationDiagnosticCourant.prestation.referencePrestation : null;
        }

        return null;
    }

    getDefaultDocuments(prestations: PrestationDiagnostic[]) {
        return this.interventionApiService.getDefaultDocuments(prestations).pipe(catchError((err) => this.httpErrorService.handleError(err)));
    }

    ajouterInterventionHorsLigne(intervention: Intervention) {
        return this.findOne(intervention.id).pipe(
            switchMap((fullIntervention) =>
                forkJoin([
                    combineLatest(
                        fullIntervention.prestationsDiagnostics
                            .filter((it) => it.idDiagnostic)
                            .map((it) => this.diagnosticService.findOne(it.idDiagnostic))
                    ).pipe(defaultIfEmpty([])),
                    this.interventionFileService
                        .getInterventionFilesOfIntervention(intervention)
                        .pipe(switchMap((interventionFiles) => this.fileApiService.pullFiles(interventionFiles))),
                    this.backgroundMapApiService.pullBackgroundImages([intervention]),
                ])
            )
        );
    }

    private dataSubject = new BehaviorSubject<any>(null);
    data$ = this.dataSubject.asObservable();

    updateVolumesSurfaces(newVolumesSurfaces: any) {
        this.dataSubject.next(newVolumesSurfaces);
    }
}
