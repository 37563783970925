<div mat-dialog-content class="calc {{ mode }}">
    <div class="d-flex flex-column align-items-center">
        <div class="d-flex flex-row" *ngIf="mode == 'saisie'">
            <input type="text" [ngModel]="input" class="result text-right" readonly />
            <div class="calc-button" (click)="clearLastToken()">C</div>
        </div>

        <div class="d-flex flex-row" *ngIf="mode !== 'saisie'">
            <div class="d-flex flex-column">
                <input type="text" [ngModel]="formattedTokens" class="summary text-right" readonly />
                <input type="text" [ngModel]="input" class="result text-right" readonly />
            </div>
            <div class="calc-button clear-button" (click)="clearLastToken()">C</div>
        </div>

        <div class="d-flex flex-row" *ngIf="mode !== 'saisie'">
            <div class="calc-button" (click)="reset()">AC</div>
            <div class="calc-button operator" (click)="execOperator('(')">(</div>
            <div class="calc-button operator" (click)="execOperator(')')">)</div>
            <div class="calc-button operator" (click)="execOperator('/')">/</div>
        </div>

        <div class="d-flex flex-row">
            <div class="calc-button" (click)="insertChar('1')">1</div>
            <div class="calc-button" (click)="insertChar('2')">2</div>
            <div class="calc-button" (click)="insertChar('3')">3</div>
            <div class="calc-button operator" *ngIf="mode !== 'saisie'" (click)="execOperator('*')">x</div>
        </div>

        <div class="d-flex flex-row">
            <div class="calc-button" (click)="insertChar('4')">4</div>
            <div class="calc-button" (click)="insertChar('5')">5</div>
            <div class="calc-button" (click)="insertChar('6')">6</div>
            <div class="calc-button operator" *ngIf="mode !== 'saisie'" (click)="execOperator('+')">+</div>
        </div>

        <div class="d-flex flex-row">
            <div class="calc-button" (click)="insertChar('7')">7</div>
            <div class="calc-button" (click)="insertChar('8')">8</div>
            <div class="calc-button" (click)="insertChar('9')">9</div>
            <div class="calc-button operator" *ngIf="mode !== 'saisie'" (click)="execOperator('-')">-</div>
        </div>

        <div class="d-flex flex-row">
            <div class="calc-button button-0" (click)="insertChar('0')">0</div>
            <div class="calc-button" (click)="insertChar('.')">,</div>
            <div class="calc-button equal" *ngIf="mode !== 'saisie'" (click)="evaluate()">=</div>
        </div>
    </div>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-button color="warn" *ngIf="mode === 'saisie'" (click)="reinit()">
        <span>{{ 'Ré-initialiser' | uppercase }}</span>
    </button>

    <button mat-raised-button color="accent" (click)="confirm()">
        <span>{{ 'Valider' | uppercase }}</span>
    </button>
</div>
