import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import * as moment from 'moment';

import { FiltrePrelevementMetop, PolluantPrelevement, PrelevementMetop } from 'src/app/modules/diagnostics/polluant/model/polluant-prelevement.model';
import { WidgetSelectModeEnum } from 'src/app/modules/shared/widget-select/widget-select.component';
import { MatDialog } from '@angular/material/dialog';
import { FiltreModalComponent } from '../prelevement-form-avant/filtres-item-list/filtres-modal/filtre-modal.component';
import { MpcaService } from 'src/app/modules/diagnostics/polluant/services/mpca.service';
import { isThisSecond } from 'date-fns';
import { MpcaItemOld, MpcaProcessus, MpcaProcessusTaskOld } from 'src/app/modules/diagnostics/polluant/model/mpca.model.old';
import { ProcessusMPCA } from 'src/app/modules/diagnostics/polluant/model/processus-mpca.model';
import { values } from 'underscore';

@Component({
    selector: 'app-prelevement-form-pendant',
    templateUrl: './prelevement-form-pendant.component.html',
    styleUrls: ['./prelevement-form-pendant.component.scss'],
})
export class PrelevementFormPendantComponent implements OnInit {
    formPendant: FormGroup;

    @Input('diagnostic')
    diagnostic: Diagnostic;
    contenuDiagnostic: PolluantPrelevement;

    @Input('selectedBesoinId')
    selectedBesoinId: string;
    selectedPrelevement: PrelevementMetop;
    selectedProcessus: MpcaProcessus;
    indexSelectedProcessus: number = 0;
    selectedMpca: MpcaItemOld;

    @Input() // Utilisé pour passer les champs en read-only
    disabled: Boolean;

    @Output('formChange')
    formChange: EventEmitter<any> = new EventEmitter<any>();

    isLoaded: boolean = false;

    widgetSelectModeEnum: typeof WidgetSelectModeEnum = WidgetSelectModeEnum;

    constructor(private formBuilder: FormBuilder, private dialog: MatDialog, private mpcaFormService: MpcaService) {}

    ngOnInit(): void {
        this.restoreData();
        this.createForm();
        this.isLoaded = true;
    }

    restoreData() {
        this.contenuDiagnostic = this.diagnostic.contenuDiagnostic as PolluantPrelevement;

        //Récupération du Prélèvement par l'ID de son Besoin
        this.selectedPrelevement = this.contenuDiagnostic.prelevement.data.besoinsList.find(
            (b) => b.besoinPrelevement.id == this.selectedBesoinId
        ) as PrelevementMetop;

        //Récupération des filtres de l'étape Avant
        if (this.selectedPrelevement.listeFiltresPendant == undefined || this.selectedPrelevement.listeFiltresPendant.length == 0) {
            this.selectedPrelevement.listeFiltresPendant = this.selectedPrelevement.listeFiltresAvant;
        }

        this.selectedMpca = this.contenuDiagnostic.visiteDeChantier.data.mpcaList.data.descriptifList.find((mpca: MpcaItemOld) => {
            return (mpca.id = this.selectedPrelevement.besoinPrelevement.mpcaId);
        });

        //Sélection initiale du processus
        this.selectProcessus();
    }

    createForm() {
        this.formPendant = this.formBuilder.group({
            processus: this.selectedProcessus,
            formProcessus: this.createProcessusForm(this.selectedProcessus),
            positionGenerale: this.selectedPrelevement.positionGenerale,
            postureGenerale: this.selectedPrelevement.postureGenerale,
            prelevementRealise: this.selectedPrelevement.prelevementRealise,
            informationMeteo: this.formBuilder.group({
                vitesseVent: [this.selectedPrelevement.informationsMeteoPendant.vitesseVent],
                directionVent: [this.selectedPrelevement.informationsMeteoPendant.directionVent],
                temperature: [this.selectedPrelevement.informationsMeteoPendant.temperature],
                hygrometrie: [this.selectedPrelevement.informationsMeteoPendant.hygrometrie],
                pressionAtmospherique: [this.selectedPrelevement.informationsMeteoPendant.pressionAtmospherique],
                conditionMeteo: [this.selectedPrelevement.informationsMeteoPendant.conditionMeteo],
            }),
            listeFiltres: this.formBuilder.array([]),
        });

        //Ajout des formulaires de filtres dans le formulaire
        this.selectedPrelevement.listeFiltresPendant.forEach((filtre: FiltrePrelevementMetop) => {
            (this.formPendant.get('listeFiltres') as FormArray).push(this.createFilterForm(filtre));
        });

        this.formPendant.valueChanges.subscribe((newValue) => {
            this.changeListener(newValue);
        });

        this.formPendant.get('formProcessus')!.valueChanges.subscribe((newValue) => {
            this.changeListener(newValue);
        });
    }

    private changeListener(newFormValue): void {
        const formValues = this.formPendant.getRawValue();

        let processus: MpcaProcessus = formValues.processus;
        processus.taches = formValues.formProcessus.taches;

        //Processus
        this.selectedPrelevement.processus = processus;
        // this.selectedPrelevement.processus = undefined;

        //Météo
        this.selectedPrelevement.informationsMeteoPendant.vitesseVent = formValues.informationMeteo.vitesseVent;
        this.selectedPrelevement.informationsMeteoPendant.directionVent = formValues.informationMeteo.directionVent;
        this.selectedPrelevement.informationsMeteoPendant.temperature = formValues.informationMeteo.temperature;
        this.selectedPrelevement.informationsMeteoPendant.hygrometrie = formValues.informationMeteo.hygrometrie;
        this.selectedPrelevement.informationsMeteoPendant.conditionMeteo = formValues.informationMeteo.conditionMeteo;
        this.selectedPrelevement.informationsMeteoPendant.pressionAtmospherique = formValues.informationMeteo.pressionAtmospherique;
        this.selectedPrelevement.listeFiltresPendant = formValues.listeFiltres;
        this.selectedPrelevement.positionGenerale = formValues.positionGenerale;
        this.selectedPrelevement.postureGenerale = formValues.postureGenerale;
        this.selectedPrelevement.prelevementRealise = formValues.prelevementRealise;
        //this.selectedPrelevement.liste = formValues.taches;

        //Envoi des changements au composant parent pour sauvegarde
        this.formChange.emit(this.contenuDiagnostic);
    }

    /**
     * @description Méthode servant à sélectionner initialement le processus,
     *              ainsi que réagir à une sélection utilisateur
     * @param index Index du processus à sélectionner.
     *              Si undefined, la valeur par défaut sera sélectionnée
     */
    selectProcessus(index = undefined): void {
        //Si l'index n'est pas défini, alors il s'agit de la sélection initiale
        if (index == undefined) {
            //Si un processus a déjà été sélectionné par le passé
            if (this.selectedPrelevement.processus != undefined) {
                this.selectedProcessus = this.selectedPrelevement.processus;
            } else {
                //Pré-sélection du processus
                this.selectedProcessus = this.selectedMpca.processus.find((processus: MpcaProcessus) => processus.primary);
                if (this.selectedProcessus == undefined) {
                    this.selectedProcessus = this.selectedMpca.processus[this.indexSelectedProcessus];
                }
            }

            //Si l'index est défini, c'est qu'il s'agit d'un choix déclenché par l'utilisateur
        } else {
            //Si l'index passé en paramètre est le même que celui déjà sélectionné
            // rien ne se passe
            if (index == this.indexSelectedProcessus) {
                return;
            }

            //Mise à jour de l'index et sélection du nouveau processus
            this.indexSelectedProcessus = index;
            this.selectedProcessus = this.selectedMpca.processus[this.indexSelectedProcessus];

            //Mise à jour du formulaire avec le nouveau processus
            this.formPendant.get('formProcessus').patchValue(this.createProcessusForm(this.selectedProcessus));
        }
        console.log(this.selectedProcessus);
    }

    /**
     * Créé un FormGroup à partir d'un objet Processus
     * @param processus Object Processus à transformer en FormGroup
     * @returns Le FormGroup du processus
     */
    createProcessusForm(processus): FormGroup {
        // let _processus = processus.taches;
        const processusForm = this.formBuilder.group({
            nom: [processus.nom],
            // primary: [processus.primary],
            taches: this.formBuilder.array([]),
        });

        //Ajout des formulaires de taches dans le formulaire
        processus.taches.forEach((tache: MpcaProcessusTaskOld) => {
            (processusForm.get('taches') as FormArray).push(this.mpcaFormService.getTacheGroup(tache));
        });

        return processusForm;
    }

    createFilterForm(filtre: FiltrePrelevementMetop): FormGroup {
        const filtreForm = this.formBuilder.group({
            numeroFiltre: [filtre.numeroFiltre, Validators.required],
            numeroPompe: [filtre.numeroPompe, Validators.required],
            debitInitial: [filtre.debitInitial, Validators.required],
            testEtancheite: [filtre.testEtancheite, Validators.required],
        });

        return filtreForm;
    }

    /**
     * @description Calcul la durée en heures entre le début et la fin du prélèvement
     */
    calculDuree() {
        return '';
    }

    /**
     *  @description Ouvre la modal d'édition d'un filtre avec le paramètre '-1'
     *               pour indiquer une création
     */
    ajouterFiltre() {
        this.openModal(-1);
    }

    /**
     * @description Ouvre la modal d'édition d'un processus avec l'index du processus à modifier
     * @param index Index du processus à modifier
     */
    modifierFiltre(index: number) {
        this.openModal(index);
    }

    /**
     * Méthode appellée pour supprimer un processus de MPCA à l'index donné
     * @param index Index du processus à supprimer
     */
    supprimerFiltre(index: number) {
        (this.formPendant.get('listeFiltres') as FormArray).removeAt(index);
    }

    /**
     * Méthode appellée pour dupliquer un processus de MPCA
     * @param index Index du processus à dupliquer
     */
    dupliquerFiltre(index: number) {
        const processusToInsert = this.createFilterForm((this.formPendant.get('listeFiltres') as FormArray).value[index]);
        (this.formPendant.get('listeFiltres') as FormArray).insert(1, processusToInsert);
    }

    /**
     *
     * @param index Index du processus avec lequel initialiser le formulaire
     * Dans le cas d'une création, l'index est à '-1' pour initialiser le formulaire à vide
     */
    openModal(index) {
        let filtre: FiltrePrelevementMetop = new FiltrePrelevementMetop();
        if (index != -1) {
            filtre = (this.formPendant.get('listeFiltres') as FormArray).value[index];
        }

        const dialogRef = this.dialog.open(FiltreModalComponent, {
            width: '250px',
            data: {
                filtre,
                index,
            },
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result.index == '-1') {
                //Dans le cas d'une création
                //On ajoute le formulaire du filtre créé à la liste des filtres
                (this.formPendant.get('listeFiltres') as FormArray).push(this.createFilterForm(result.form));
            } else {
                //Dans le cas d'une mise à jour
                //On patch les valeurs du filtre avec les valeurs retournées
                (this.formPendant.get('listeFiltres') as FormArray).controls[result.index].patchValue({
                    numeroFiltre: result.form.numeroFiltre,
                    numeroPompe: result.form.numeroPompe,
                    debitInitial: result.form.debitInitial,
                    testEtancheite: result.form.testEtancheite,
                });
            }
        });
    }

    /**
     * @description Méthode appellée pour imprimer l'étiquette liée au prélèvement
     */
    public impressionEtiquette() {
        //A implémenter
    }
}
