import { TypeMateriau } from './type-materiau.model';
import { MongoUtils } from 'src/app/commons-lib';
import { TypeOuvrage } from './type-ouvrage.model';
import { Prelevement } from './prelevement-generique.model';
import { Legende } from './legende.model';

export class Zone {
    id: string = MongoUtils.generateObjectId();
    nom: string;
    description: string;
    partieOuvrage: TypeOuvrage;
    typeMateriaux: TypeMateriau[] = [];
    listePrelevements: Prelevement[] = [];
    /**
     * Id du fichier contenant la photo de situation de la zone
     */
    idFichierImageSituation?: string;
    legende: Legende = new Legende();
}
