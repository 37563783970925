import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Bien } from '../model/bien.model';
import { Commentaire } from '../model/commentaire.model';
import { Conformite } from '../model/conformite.model';
import { Diagnostic } from '../model/diagnostic.model';
import { Document } from '../model/document.model';
import { EtatValidation } from '../model/etat-progression.model';
import { PointDeControleBien, PointDeControleVolume } from '../model/point-de-controle.model';
import { ReferencePrestation } from '../model/reference-prestation.model';
import { PropertiesMesurage } from '../modules/diagnostics/mesurage/model/mesurage.model';
import { PARAM_VOLUME_VISITE, VOLUME_VISITE } from '../shared/constants/cndiag.constants';
import { EtatProgressionService } from './etat-progression.service';
import { RulesService } from './rules.service';
import { TypeDocumentCheckpoint } from '../model/type-document.model';

@Injectable({
    providedIn: 'root',
})
export class CheckValidityTabService {
    constructor(private readonly rulesService: RulesService, private readonly etatProgressionService: EtatProgressionService) {}

    /**
     * Vérifie la validité des onglets point de controle pièces sans équipements et points de controle
     * @param pointsDeControleBiens
     * @param commentairesIntervention
     * @returns
     */
    checkValidityPointDeControle(pointsDeControleBiens: PointDeControleBien[], commentairesIntervention: Commentaire[]): Observable<EtatValidation> {
        let etat: EtatValidation = 'VALID';
        const flattenedElement = pointsDeControleBiens
            .flatMap((bien) => bien.pointsDeControleNiveaux)
            .flatMap((niveau) => niveau.pointsDeControleVolumes)
            .flatMap((volume) => volume.pointsDeControleElements);
        for (const element of flattenedElement) {
            if (
                (element.errors && element.errors.size > 0) ||
                !element.conformite ||
                (element.conformite === Conformite.A_JUSTIFIER && !element.justifie)
            ) {
                etat = 'INVALID';
                break;
            }
        }
        const flattenedRoom = pointsDeControleBiens
            .flatMap((bien) => bien.pointsDeControleNiveaux)
            .flatMap((niveau) => niveau.pointsDeControleVolumes);
        for (const room of flattenedRoom) {
            this.rulesService.computeJustificationNonVisite(room, commentairesIntervention);
            if (
                !room.volumeCache &&
                ((['ko', 'warning'].includes(room.valeursParametres[PARAM_VOLUME_VISITE]) && !room.justifie) ||
                    !room.valeursParametres[PARAM_VOLUME_VISITE])
            ) {
                etat = 'INVALID';
                break;
            }
        }

        return of(etat);
    }

    /**
     * Vérifie la validité de l'onglet Mesurage dans tous les diagnostics mesurage
     * @param pointsDeControleBiens
     * @param commentairesIntervention
     * @param propertiesMesurage
     * @returns
     */
    checkValidityMesure(
        pointsDeControleBiens: PointDeControleBien[],
        commentairesIntervention: Commentaire[],
        propertiesMesurage: PropertiesMesurage
    ): Observable<EtatValidation> {
        let etat: EtatValidation = 'VALID';
        const flattenedRoom = pointsDeControleBiens
            .flatMap((bien) => bien.pointsDeControleNiveaux)
            .flatMap((niveau) => niveau.pointsDeControleVolumes);
        for (const room of flattenedRoom) {
            this.rulesService.computeJustificationNonVisite(room, commentairesIntervention);
            if (
                (['ko', 'warning'].includes(room.valeursParametres[PARAM_VOLUME_VISITE]) && !room.justifie) ||
                this.isInvalid(room.valeursParametres[propertiesMesurage.listeMesures[0]], room) ||
                this.isInvalid(room.valeursParametres[propertiesMesurage.listeMesures[1]], room) ||
                this.isInvalid(room.valeursParametres[propertiesMesurage.listeMesures[2]], room)
            ) {
                etat = 'INVALID';
                break;
            }
        }

        return of(etat);
    }

    /**
     * Vérifie si la mesure passée en paramètre est valide ou non
     * 2 cas : non renseignée avec la pièce visitée ou négative
     * @param mesure
     * @param room
     * @returns
     */
    private isInvalid(mesure: string, room: PointDeControleVolume) {
        return (mesure === undefined && [undefined, 'ok'].includes(room.valeursParametres[PARAM_VOLUME_VISITE])) || this.isNegative(mesure);
    }

    /**
     * Vérifie si la mesure est négative ou non
     * @param mesure
     * @returns
     */
    private isNegative(mesure: string) {
        return mesure && !isNaN(Number(mesure)) && Number(mesure) < 0;
    }

    /**
     * Vérifie la validité des sous-onglet dans Description
     * @returns
     */
    checkValidityDescription(bienSelected: Bien): Observable<EtatValidation> {
        const etat = bienSelected.sansDescription
            ? 'VALID'
            : bienSelected.description.some((niveau) =>
                  niveau.volumes.some(
                      (volume) =>
                          !volume.valeursParametres[PARAM_VOLUME_VISITE] ||
                          (volume.valeursParametres[PARAM_VOLUME_VISITE] &&
                              (volume.valeursParametres[PARAM_VOLUME_VISITE] === VOLUME_VISITE.KO ||
                                  volume.valeursParametres[PARAM_VOLUME_VISITE] === VOLUME_VISITE.WARNING) &&
                              !volume.justifie)
                  )
              )
            ? 'INVALID'
            : 'VALID';

        return of(etat);
    }

    /**
     * Valide l'onglet bon de commande.
     * L'onglet est valide à partir du moment où le diagnostic contient les données du bon de commande
     * @param diagnostic
     */
    checkValidityBonCommande(diagnostic: Diagnostic) {
        let etat: EtatValidation = 'INVALID';
        const listeBonCommande = diagnostic.listeBonCommande;

        if (listeBonCommande !== undefined && listeBonCommande.length > 0) {
            etat = 'VALID';
        }

        return of(etat);
    }

    /**
     * Valide l'onglet document d'un diagnostic.
     * On vérifie la conformité du document uniquement pour cette referencePrestation
     * @param documentsToCheck Liste des documents à controler
     * @param referencePrestation
     */
    checkValidityDocument(documentsToCheck: Document[], referencePrestation: ReferencePrestation) {
        let etat: EtatValidation = 'INVALID';
        // Dans le cas d'une vérification par rapport à une referencePrestation
        if (referencePrestation) {
            const listeDocumentNonConforme = documentsToCheck.filter((documentTemp) => {
                const typeDocumentForPrestation: TypeDocumentCheckpoint =
                    documentTemp.typeDocument.typeDocumentCheckpoint.find(
                        (typeDocumentCheckpointTemp) =>
                            typeDocumentCheckpointTemp.referencePrestation.id == referencePrestation.id
                    );

                // S'il n'y a pas de points de contrôle pour le document (typeDocumentForPrestation === null)
                // Le document est forcément conforme
                if (documentTemp.dateDocumentObligatoire === false) {
                    return false;
                } else if (typeDocumentForPrestation && documentTemp.isPresented === false) {
                    // Si document pas présent et aucun commentaires
                    return !documentTemp.commentairesId?.length;
                } else if (documentTemp.isPresented === true && !documentTemp.dateDocument) {
                    return true; // S'il n'y pas de date
                }
                return (
                    typeDocumentForPrestation &&
                    typeDocumentForPrestation.checkpoints.length > 0 &&
                    typeDocumentForPrestation.checkpoints.some((checkpoint) => {
                        return checkpoint.value == undefined && checkpoint.value == null;
                    })
                );
            });

            if (listeDocumentNonConforme.length == 0) {
                etat = 'VALID';
            }
            // Dans le cas d'une vérification globale du document
        }

        return of(etat);
    }
}
