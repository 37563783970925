import { Perimetre } from '../../../../model/perimetre.model';
import { Espace } from '../../../../model/espace.model';
import { Zone } from '../../../../model/zone.model';
import { Prelevement, ResultSeuil } from '../../../../model/prelevement-generique.model';

export const PRELEVEMENT_DONNEES_TECHNIQUES_ALERT_MESSAGE = {
    lave: {
        message: `Si le prélèvement n'est pas lavé, vous devez spécifier un commentaire de justification`,
        titleLabel: `Le prélèvement n'est pas lavé`,
        confirmLabel: 'Justifier',
        cancelLabel: 'Annuler',
        warn: false,
    },
    couchesADissocier: {
        message: `Si les couches ne sont pas à dissocier lors des analyses, vous devez spécifier un commentaire de justification`,
        titleLabel: 'Couches à dissocier',
        confirmLabel: 'Justifier',
        cancelLabel: 'Annuler',
        warn: false,
    },
    analyseCouches: {
        message: `Si toutes les couches ne sont pas à analyser, vous devez spécifier un commentaire de justification`,
        titleLabel: 'Analyser toutes les couches',
        confirmLabel: 'Justifier',
        cancelLabel: 'Annuler',
        warn: false,
    },
    limitationFibre: {
        message: `S'il n'y a pas eu de limitation de fibres, vous devez spécifier un commentaire de justification`,
        titleLabel: 'Limitation de fibres',
        confirmLabel: 'Justifier',
        cancelLabel: 'Annuler',
        warn: false,
    },
    pollutionSurfacique: {
        message: `S'il y a une pollution surfacique au prélèvement, vous devez spécifier un commentaire de justification`,
        titleLabel: 'Pollution surfacique du prélèvement',
        confirmLabel: 'Justifier',
        cancelLabel: 'Annuler',
        warn: false,
    },
    analyseAmiante: {
        message: `Si l'analyse amiante n'est pas nécessaire, vous devez spécifier un commentaire de justification et un PV d'analyse d'amiante`,
        titleLabel: 'Analyse amiante à réaliser',
        confirmLabel: 'Saisir un PV',
        cancelLabel: 'Annuler',
        warn: false,
    },
};

export const HAP_SUPPRESSION_ELEMENT_ALERT_MESSAGE = {
    espace: (espace: Espace) => {
        return {
            message: `Êtes vous sûr de vouloir supprimer l'espace: ${espace.nom}, ainsi que tous les périmètres, zones, besoins et prélèvements qu'il contient?`,
            titleLabel: "Suppression d'un espace",
            confirmLabel: 'Supprimer',
            cancelLabel: 'Annuler',
            warn: true,
        };
    },
    planLocalisation: (espace: Espace) => {
        return {
            message: `Êtes vous sûr de vouloir supprimer le périmètre de localisation: ${espace.nom}, ainsi que toutes les délimitations qu'il contient?`,
            titleLabel: "Suppression d'un périmètre de localisation",
            confirmLabel: 'Supprimer',
            cancelLabel: 'Annuler',
            warn: true,
        };
    },
    perimetre: (perimetre: Perimetre) => {
        return {
            message: `Êtes-vous sûr de vouloir supprimer le périmètre${
                perimetre.nom ? ' : ' + perimetre.nom : ''
            }, ainsi que toutes les zones, besoins et prélèvements qu'il contient?`,
            titleLabel: "Suppression d'un périmètre",
            confirmLabel: 'Supprimer',
            cancelLabel: 'Annuler',
            warn: true,
        };
    },
    perimetreLocalisation: (perimetre: any) => {
        return {
            message: `Êtes-vous sûr de vouloir supprimer le périmètre${
                perimetre.nom ? ' : ' + perimetre.nom : ''
            }, ainsi que toutes les zones, besoins et prélèvements qu'il contient?`,
            titleLabel: "Suppression d'un périmètre",
            confirmLabel: 'Supprimer',
            cancelLabel: 'Annuler',
            warn: true,
        };
    },
    zone: (zone: Zone) => {
        return {
            message: `Êtes-vous sûr de vouloir supprimer la zone: ${zone.nom}, ainsi que tous les besoins et prélèvements qu'elle contient?`,
            titleLabel: "Suppression d'une zone",
            confirmLabel: 'Supprimer',
            cancelLabel: 'Annuler',
            warn: true,
        };
    },
    besoin: (besoin: Prelevement) => {
        return {
            message: `Êtes-vous sûr de vouloir supprimer le besoin: ${besoin.general.reference}?`,
            titleLabel: "Suppression d'un besoin",
            confirmLabel: 'Supprimer',
            cancelLabel: 'Annuler',
            warn: true,
        };
    },
    prelevement: (prelevement: Prelevement) => {
        return {
            message: `Êtes-vous sûr de vouloir supprimer le prélèvement: ${prelevement.general.reference}?`,
            titleLabel: "Suppression d'un prélèvement",
            confirmLabel: 'Supprimer',
            cancelLabel: 'Annuler',
            warn: true,
        };
    },
};

export const SEUIL_INF_50 = {
    seuilScore: ResultSeuil.INFERIEUR_EGAL_50,
    color: '#92D04F',
    label: 'Seuil inférieur ou égal à 50mg/kg',
};

export const SEUIL_ENTRE_51_ET_500 = {
    seuilScore: ResultSeuil.ENTRE_51_ET_500,
    color: '#C6BC9F',
    label: 'Seuil situé entre 51 et 500mg/kg',
};

export const SEUIL_ENTRE_501_ET_1000 = {
    seuilScore: ResultSeuil.ENTRE_501_ET_1000,
    color: '#FFC130',
    label: 'Seuil situé entre 501 et 1000mg/kg',
};

export const SEUIL_SUP_1000 = {
    seuilScore: ResultSeuil.SUPERIEUR_1000,
    color: '#BF0018',
    label: 'Seuil supérieur à 1000mg/kg',
};
