import { Component, Input } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { CnSpinnerService } from '../../../../shared/cn-spinner/service/cn-spinner.service';
import { ReferenceService } from '../../../../../services/reference.service';
import { combineLatest, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { CategorieOuvrage, OuvrageAControler } from '../../../../../model/categorie-ouvrage.model';
import { Bien, Volume } from '../../../../../model/bien.model';
import { EtatWorkflow } from '../../../../../model/etat-workflow.model';
import { ConfirmDialogComponent } from '../../../../../lib/confirmation/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CalculatriceModaleComponent } from '../../../../shared/calculatrice-modale/calculatrice-modale.component';
import { DescriptionBienService } from '../../../../../services/description-bien.service';

@Component({
    selector: 'app-description-bien-modal-ouvrages',
    templateUrl: './description-bien-modal-ouvrages.component.html',
    styleUrls: ['./description-bien-modal-ouvrages.component.scss'],
})
export class DescriptionBienModalOuvragesComponent extends BaseComponent {
    @Input()
    readonlyMode: boolean = false;

    @Input()
    bien: Bien;

    @Input() set currentVolume(newVolume: Volume) {
        this._currentVolume = newVolume;
        this.selectedCategorieOuvrage = null;
        this.selectedCategorieOuvrageParent = null;
        this.refreshCategoriesParentes();
        this.filterCategoriesOuvrages();
    }

    get currentVolume() {
        return this._currentVolume;
    }

    @Input()
    categorieKeyToOpen: string;

    private _currentVolume: Volume;

    listCategoriesOuvragesRacineToDisplay: CategorieOuvrage[] = [];
    listCategoriesOuvragesToDisplay: CategorieOuvrage[] = [];
    listCategoriesRacines: CategorieOuvrage[] = [];

    searchCategoriesOuvrages: string;

    mapCategoriesOuvrages: Map<string, CategorieOuvrage> = new Map<string, CategorieOuvrage>();
    mapCategoriesParentes: Map<string, number> = new Map<string, number>();

    selectedCategorieOuvrageRacine: CategorieOuvrage;
    selectedCategorieOuvrage: CategorieOuvrage;
    selectedCategorieOuvrageParent: CategorieOuvrage;

    filtreCategorieOuvrage: string[] = [];

    constructor(
        private readonly dialog: MatDialog,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly referenceService: ReferenceService,
        private readonly descriptionBienService: DescriptionBienService
    ) {
        super();
    }

    ngOnInit() {
        this.cnSpinnerService
            .withSpinner(
                combineLatest([this.referenceService.findAllCategoriesOuvrages()]).pipe(
                    takeUntil(this.ngUnsubscribe),
                    switchMap(([categoriesOuvrages]) => {
                        return combineLatest([of(categoriesOuvrages)]);
                    })
                )
            )
            .subscribe(([categoriesOuvrages]) => {
                categoriesOuvrages.forEach((it) => {
                    this.mapCategoriesOuvrages.set(it.code, it);
                });
                this.listCategoriesRacines = categoriesOuvrages.filter(
                    (catOuv) => catOuv.etatCategorieOuvrage === EtatWorkflow.ACTIF && !catOuv.lienCodeParent
                );
                this.listCategoriesOuvragesRacineToDisplay = this.listCategoriesRacines;

                this.refreshCategoriesParentes();

                if (this.categorieKeyToOpen) {
                    this.selectedCategorieOuvrageParent = categoriesOuvrages.find((it) => it.code === this.categorieKeyToOpen);
                    this.filtreCategorieOuvrage = [this.selectedCategorieOuvrageParent.code];
                }

                this.filterCategoriesOuvrages();
                this.cnSpinnerService.hide();
            });
    }

    onKeyupSearchCategoriesOuvrages($event) {
        this.filterCategoriesOuvrages();
    }

    clearSearchCategoriesOuvrages() {
        this.searchCategoriesOuvrages = null;
        this.filterCategoriesOuvrages();
    }

    handleSelectCategorieOuvrageRacine(categorieOuvrageRacine: CategorieOuvrage) {
        this.selectedCategorieOuvrageRacine = categorieOuvrageRacine;
        this.listCategoriesOuvragesToDisplay = Array.from(this.mapCategoriesOuvrages.values())
            .filter(
                (it: CategorieOuvrage) =>
                    it.etatCategorieOuvrage === EtatWorkflow.ACTIF && it.lienCodeParent === this.selectedCategorieOuvrageRacine.code
            )
            .filter((it) => {
                return !this.searchCategoriesOuvrages || it.nom.toLowerCase().includes(this.searchCategoriesOuvrages.toLowerCase());
            });
    }

    handleRemoveSelectedCategorieOuvrageRacine() {
        this.selectedCategorieOuvrageRacine = null;
        this.filterCategoriesOuvrages();
    }

    handleAjouterCategorieOuvrage(categorieOuvrage: CategorieOuvrage) {
        this.selectedCategorieOuvrage = categorieOuvrage;
    }

    handleAjouterOuvrageOfCategorie(codeCategorieOuvrage: string) {
        this.retourChoixCategorie(codeCategorieOuvrage);
    }

    handleRetourChoixCategoriesOuvrages() {
        this.retourChoixCategorie();
    }

    handleSelectCategorieOuvrageParent(categorieOuvrageCode: string) {
        const categorieOuvrage = this.mapCategoriesOuvrages.get(categorieOuvrageCode);
        if (this.selectedCategorieOuvrageParent === categorieOuvrage) {
            this.selectedCategorieOuvrageParent = null;
            this.filtreCategorieOuvrage = [];
        } else {
            this.selectedCategorieOuvrageParent = categorieOuvrage;
            if (this.mapCategoriesParentes.get(this.selectedCategorieOuvrageParent.code) === 0) {
                this.filtreCategorieOuvrage = [this.selectedCategorieOuvrageParent.code];
            }
        }
        this.filterCategoriesOuvrages();
    }

    handleAjouterNombreOuvrages(nbOuvrages: number) {
        this.addNbOuvrages(nbOuvrages);
    }

    handleAjouterAutreNbOuvrages() {
        this.dialog
            .open(CalculatriceModaleComponent, {
                data: {
                    mode: 'calculatrice',
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.addNbOuvrages(+result.resultat);
                }
            });
    }

    handleSupprimerCategorie($event, categorieOuvrageCode) {
        $event.stopPropagation();
        const message = 'Voulez-vous vraiment supprimer tous les éléments de type ' + this.mapCategoriesOuvrages.get(categorieOuvrageCode).nom + ' ?';
        this.dialog
            .open(ConfirmDialogComponent, {
                data: {
                    message,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.currentVolume.ouvragesAControler
                        .filter((it) => this.mapCategoriesOuvrages.get(it.codeCategorieOuvrage).lienCodeParent === categorieOuvrageCode)
                        .forEach((it) =>
                            this.currentVolume.ouvragesAControler.splice(
                                this.currentVolume.ouvragesAControler.findIndex((ocASupprimer) => ocASupprimer.id === it.id),
                                1
                            )
                        );
                    if (this.selectedCategorieOuvrage?.code === categorieOuvrageCode) {
                        this.selectedCategorieOuvrage = null;
                    }
                    this.filterCategoriesOuvrages();
                    this.refreshCategoriesParentes();
                }
            });
    }

    filterCategoriesOuvrages() {
        this.listCategoriesOuvragesRacineToDisplay = this.listCategoriesRacines.filter((it) => {
            return !this.searchCategoriesOuvrages || it.nom.toLowerCase().includes(this.searchCategoriesOuvrages.toLowerCase());
        });
        this.listCategoriesOuvragesToDisplay = Array.from(this.mapCategoriesOuvrages.values())
            .filter(
                (it: CategorieOuvrage) =>
                    it.etatCategorieOuvrage === EtatWorkflow.ACTIF &&
                    it.lienCodeParent &&
                    !this.mapCategoriesOuvrages.get(it.lienCodeParent)?.lienCodeParent
            )
            .filter(
                (it: CategorieOuvrage) =>
                    it.etatCategorieOuvrage === EtatWorkflow.ACTIF && it.lienCodeParent === this.selectedCategorieOuvrageRacine?.code
            )
            .filter((it) => {
                return (
                    (!this.searchCategoriesOuvrages || it.nom.toLowerCase().includes(this.searchCategoriesOuvrages.toLowerCase())) &&
                    (this.filtreCategorieOuvrage.length === 0 || this.filtreCategorieOuvrage.includes(it.lienCodeParent))
                );
            });
    }

    handleRefreshCategoriesOuvragesParents() {
        this.refreshCategoriesParentes();
    }

    private addNbOuvrages(nbOuvrages: number) {
        const listNoms = this.currentVolume.ouvragesAControler
            .filter((it) => it.codeCategorieOuvrage === this.selectedCategorieOuvrage.code)
            .map((it) => it.nom);
        for (let i = 1; i < nbOuvrages + 1; i++) {
            const newOuvrage = new OuvrageAControler();
            newOuvrage.codeCategorieOuvrage = this.selectedCategorieOuvrage.code;
            newOuvrage.nom = this.descriptionBienService.generateNewNom(
                this.selectedCategorieOuvrage,
                {
                    volume: this.currentVolume,
                    bien: this.bien,
                },
                Array.from(this.mapCategoriesOuvrages.values())
            );
            this.currentVolume.ouvragesAControler.push(newOuvrage);
            listNoms.push(newOuvrage.nom);
        }
        this.refreshCategoriesParentes();
        this.selectedCategorieOuvrageParent = this.mapCategoriesOuvrages.get(this.selectedCategorieOuvrage.lienCodeParent);
        this.selectedCategorieOuvrage = null;
    }

    private refreshCategoriesParentes() {
        this.listCategoriesRacines
            .map((catOuv) => {
                this.mapCategoriesParentes.set(catOuv.code, 0);
                return catOuv;
            })
            .forEach((catOuv) => {
                const sum = this.currentVolume.ouvragesAControler.filter(
                    (ouvrage) => this.mapCategoriesOuvrages.get(ouvrage.codeCategorieOuvrage).lienCodeParent === catOuv.code
                ).length;

                this.mapCategoriesParentes.set(catOuv.code, this.mapCategoriesParentes.get(catOuv.code) + sum);
            });
    }

    private retourChoixCategorie(codeCategorieOuvrage?: string) {
        this.selectedCategorieOuvrageParent = null;
        this.selectedCategorieOuvrage = null;
        this.filtreCategorieOuvrage = codeCategorieOuvrage ? [codeCategorieOuvrage] : [];
        this.filterCategoriesOuvrages();
    }
}
