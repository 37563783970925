import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/commons-lib';
import { URL_GESTION_COMMANDES_EDIT } from 'src/app/shared/constants/url.constants';

@Component({
    selector: 'app-gestion-commandes',
    templateUrl: './gestion-commandes.component.html',
    styleUrls: ['./gestion-commandes.component.scss'],
})
export class GestionCommandesComponent extends BaseComponent implements OnDestroy {
    constructor(private readonly router: Router) {
        super();
    }

    creerCommande() {
        this.router.navigate([URL_GESTION_COMMANDES_EDIT]);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
