import { Injectable } from '@angular/core';
import { ComputeModeEnum, RegleRef, ResultCondition, Rule, RuleOptions, RuleResults } from '../model/regle.model';
import { Conformite } from '../model/conformite.model';
import { PointDeControleBien, PointDeControleElement, PointDeControleVolume } from '../model/point-de-controle.model';
import { EngineRule } from '../shared/regle/engine-rule';
import { Document } from '../model/document.model';
import { ContenuDiagnostic } from '../model/diagnostic-contenu.model';
import { PARAM_ODEUR_IS_REQUIRED } from '../modules/diagnostics/assainissement/shared/assainissement.constants';
import { Observable } from 'rxjs';
import { RulesApiService } from './rules-api.service';
import { TypePrestation } from '../model/type-prestation.model';
import { PrestationDiagnostic } from '../model/intervention.model';
import { Commentaire } from '../model/commentaire.model';
import { PARAM_VOLUME_VISITE } from '../shared/constants/cndiag.constants';
import { enumTypesCommentaire, typesJustification } from '../model/type-commentaire.model';
import { Volume } from '../model/bien.model';

@Injectable({
    providedIn: 'root',
})
export class RulesService {
    engineRule: EngineRule;

    constructor(private readonly rulesApiService: RulesApiService) {
        this.engineRule = new EngineRule();
    }

    findRulesControlIntoPrestation(prestationDiag: PrestationDiagnostic): Rule[] {
        if (prestationDiag) {
            const regle = prestationDiag.prestation.regles.find((rule) => rule.type === 'REGLE_CONTROL');
            if (regle && regle.jsonData) {
                return JSON.parse(regle.jsonData) as Rule[];
            }
        }

        return undefined;
    }

    /**
     * Vérifie la conformité de chaque élement de chaque bien de l'intervention et la présence d'un commentaire justificatif si nécessaire
     * @param commentairesIntervention
     * @param pointDeControleBiens
     * @param contenuDiagnostic
     * @param reglesConformite
     */
    computeConformityDiagnosticAssainissement(
        commentairesIntervention: Commentaire[],
        pointDeControleBiens: PointDeControleBien[],
        contenuDiagnostic: ContenuDiagnostic,
        reglesConformite: Rule[]
    ) {
        pointDeControleBiens.forEach((pcb) => {
            pcb.pointsDeControleNiveaux.forEach((pcn) => {
                pcn.pointsDeControleVolumes.forEach((pcv) => {
                    pcv.pointsDeControleElements.forEach((pce) => {
                        this.computeConformity(pce, contenuDiagnostic, reglesConformite);
                        // Vérifie s'il faut une justification et si elle est présente
                        pce.justifie =
                            pce.conformite === Conformite.A_JUSTIFIER &&
                            commentairesIntervention
                                .filter((c) => {
                                    return pce.commentairesId.indexOf(c.id) !== -1;
                                })
                                .some((comm) => comm.type === enumTypesCommentaire.JUSTIFICATION_RESERVE);
                    });
                });
            });
        });
    }

    /**
     * Vérifie si l'élément à contrôler à une conformité A_JUSTIFIER et si la justification est présente
     * Affiche également le message en cas de multiples éléments à justifier
     */
    computeJustificationElement(pc: PointDeControleElement, commentairesIntervention: Commentaire[], checkValidity?: any) {
        pc.justifie =
            pc.conformite === Conformite.A_JUSTIFIER &&
            commentairesIntervention
                .filter((c) => {
                    return pc.commentairesId.indexOf(c.id) !== -1;
                })
                .find((c) => typesJustification.includes(c.type)) !== undefined;

        if (checkValidity) {
            checkValidity();
        }
    }

    /**
     * Vérifie si la pièce à contrôler a été non visitée ou partiellement visitée et si la justification est présente
     */
    computeJustificationNonVisite(
        volume: PointDeControleVolume | Volume,
        commentairesIntervention: Commentaire[],
        checkValidity?: any
    ) {
        volume.justifie =
            ['ko', 'warning'].includes(volume.valeursParametres[PARAM_VOLUME_VISITE]) &&
            commentairesIntervention
                .filter((c) => {
                    return volume.commentairesId.indexOf(c.id) !== -1;
                })
                .find((c) => c.type === enumTypesCommentaire.JUSTIFICATION_NON_VISITE) !== undefined;

        if (checkValidity) {
            checkValidity();
        }
    }

    computeControlElement(
        pointDeControleElement: PointDeControleElement,
        currentDiagnostic: ContenuDiagnostic,
        reglesConformite: Rule[],
        options?: RuleOptions
    ): RuleResults[] {
        if (!options) {
            this.engineRule.computeMode = ComputeModeEnum.FIRST_VALID;
        }
        return this.engineRule.computeRules(reglesConformite, pointDeControleElement.codeRef, pointDeControleElement, currentDiagnostic, options);
    }

    computeConformity(
        pointDeControleElement: PointDeControleElement,
        currentDiagnostic: ContenuDiagnostic,
        reglesConformite: Rule[],
        options?: RuleOptions
    ) {
        pointDeControleElement.conformite = null;
        pointDeControleElement.errors = new Map<string, string>();
        pointDeControleElement.displayError = null;
        const ruleResults = this.computeControlElement(pointDeControleElement, currentDiagnostic, reglesConformite, options);
        ruleResults.forEach((ruleResult) => {
            if (ruleResult.ruleName === 'required') {
                this.getRequiredMessages(pointDeControleElement, ruleResult.results);
            } else if (ruleResult.ruleName === 'conformite') {
                const result = ruleResult.results.find((res) => res.valid && !res.prerequired);
                if (result) {
                    switch (result.type) {
                        case 'non-conforme':
                            pointDeControleElement.conformite = Conformite.NON_CONFORME;
                            pointDeControleElement.typesCommentaires = [enumTypesCommentaire.JUSTIFICATION_NON_CONFORMITE];
                            break;
                        case 'a-justifier':
                            pointDeControleElement.conformite = Conformite.A_JUSTIFIER;
                            pointDeControleElement.typesCommentaires = [enumTypesCommentaire.JUSTIFICATION_RESERVE];
                            break;
                        case 'conforme':
                            pointDeControleElement.conformite = Conformite.CONFORME;
                            pointDeControleElement.typesCommentaires = [];
                            break;
                        default:
                            pointDeControleElement.conformite = null;
                            pointDeControleElement.typesCommentaires = [];
                            break;
                    }
                }
            }
        });
    }

    computeRequiredDocument(documents: Document[], regles: Rule[]): { code: string; missing: boolean }[] {
        const ruleResults = this.engineRule.computeRules(regles, 'document', documents);
        const requiredDocuments = [];
        ruleResults.forEach((result) => {
            result.results.forEach((res) => {
                if (res.params.get('invalids')) {
                    res.params.get('invalids').forEach((operation) => {
                        requiredDocuments.push({ code: operation.values, missing: true });
                    });
                }
                if (res.params.get('valids')) {
                    res.params.get('valids').forEach((operation) => {
                        requiredDocuments.push({ code: operation.values, missing: false });
                    });
                }
            });
        });
        return requiredDocuments;
    }

    getRequiredMessages(pointDeControleElement: PointDeControleElement, results: ResultCondition[]) {
        results.forEach((result) => {
            const message = result.params.get('message') ? result.params.get('message') : 'Erreur';
            if (result.params.get('unverifiedBy')) {
                pointDeControleElement.displayError = pointDeControleElement.displayError
                    ? pointDeControleElement.displayError + ', ' + message
                    : message;
                result.params.get('unverifiedBy').forEach((operation) => {
                    pointDeControleElement.errors.set(operation.name, message);
                });
            }
        });
    }

    /**
     * Pour chaque point de controle element, on regarde dans les regles si l'odeur est un element obligatoire de la conformité.
     * @param reglesConformite
     * @param pointsDeControleBiens
     */
    initOdeurRequiredElement(reglesConformite: Rule[], pointsDeControleBiens: PointDeControleBien[]) {
        pointsDeControleBiens.forEach((bien) => {
            bien.pointsDeControleNiveaux.forEach((niveau) => {
                niveau.pointsDeControleVolumes.forEach((volume) => {
                    volume.pointsDeControleElements.forEach((it) => {
                        if (it.valeursParametres[PARAM_ODEUR_IS_REQUIRED] === undefined) {
                            const ruleset = reglesConformite.filter((rule) => rule.references.includes(it.codeRef) && rule.name === 'conformite');
                            ruleset.forEach((rule) => {
                                const prerequiredConditions = rule.conditions
                                    .filter((condition) => condition.prerequired)
                                    .sort((cond1, cond2) => cond1.priority - cond2.priority);
                                if (prerequiredConditions.length > 0) {
                                    prerequiredConditions.forEach((condition) => {
                                        if (condition.and && condition.and.length > 0) {
                                            condition.and.forEach((operatorAnd) => {
                                                if (
                                                    operatorAnd.pathControl &&
                                                    operatorAnd.pathControl.includes('odeur') &&
                                                    operatorAnd.operator === 'required'
                                                ) {
                                                    it.valeursParametres[PARAM_ODEUR_IS_REQUIRED] = true;
                                                }
                                            });
                                        }
                                        if (condition.or && condition.or.length > 0) {
                                            condition.or.forEach((operatorOr) => {
                                                if (
                                                    operatorOr.pathControl &&
                                                    operatorOr.pathControl.includes('odeur') &&
                                                    operatorOr.operator === 'required'
                                                ) {
                                                    it.valeursParametres[PARAM_ODEUR_IS_REQUIRED] = true;
                                                }
                                            });
                                        }
                                    });
                                } else {
                                    it.valeursParametres[PARAM_ODEUR_IS_REQUIRED] = false;
                                }
                            });
                        }
                    });
                });
            });
        });
    }

    /**
     * Renvoie toutes les règles liées aux types de prestations passés en paramètre
     */
    findAllByTypesPrestations(listeTypesPrestations: TypePrestation[]): Observable<RegleRef[]> {
        return this.rulesApiService.findAllByTypesPrestations(listeTypesPrestations);
    }

    /**
     * Mets à jour une règle en base
     * @param regleRef
     */
    updateRule(regleRef: RegleRef): Observable<RegleRef> {
        return this.rulesApiService.updateRule(regleRef);
    }
}
