import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { Cofrac } from '../model/cofrac.model';
import { CofracApiService } from './cofrac-api.service';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthenticationService, HttpErrorService } from 'src/app/commons-lib';
import { NavigationUtils } from '../utils/navigation.utils';

@Injectable({
    providedIn: 'root',
})
export class CofracService {
    // private jwt: string;

    constructor(
        private readonly cofracApiService: CofracApiService,
        private readonly httpErrorService: HttpErrorService,
        private readonly authenticationService: AuthenticationService
    ) {
        // this.authenticationService.getAuthenticationToken().subscribe((token) => (this.jwt = token));
    }

    /**
     * Renvoie un cofrac.
     */
    findOne(idCofrac: string): Observable<Cofrac> {
        return this.cofracApiService.findOne(idCofrac).pipe(
            switchMap((cofrac: Cofrac) => {
                if (cofrac.idFichierLogo) {
                    // cofrac.logoUrl = NavigationUtils.getLogoUrlCofrac(cofrac, this.jwt);
                    cofrac.logoUrl = NavigationUtils.getLogoUrlCofrac(cofrac);
                }
                return of(cofrac);
            })
        );
    }

    findAllCofracs(): Observable<Cofrac[]> {
        return this.cofracApiService.findAllCofracs().pipe(
            catchError((err) => throwError(err)),
            catchError((err) => this.httpErrorService.handleError(err))
        );
    }

    /**
     * Créer un cofrac
     * @param cofrac
     */
    createCofrac(cofrac: Cofrac): Observable<Cofrac> {
        return this.cofracApiService.createCofrac(cofrac).pipe(
            catchError((err) => throwError(err)),
            catchError((err) => this.httpErrorService.handleError(err))
        );
    }

    /**
     * Mettre à jour un cofrac
     * @param cofrac
     */
    updateCofracOnline(cofrac: Cofrac): Observable<Cofrac> {
        return this.cofracApiService.updateCofracOnline(cofrac).pipe(
            catchError((err) => throwError(err)),
            catchError((err) => this.httpErrorService.handleError(err))
        );
    }
}
