import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/commons-lib';
import { BehaviorSubject } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { ReferenceApiService } from 'src/app/services/reference-api.service';
import {
    DEBOUNCE_TIME_VALUE,
    INITIAL_PAGE_SIZE,
    SESSION_STORAGE_KEY_EQUIPEMENT,
    SESSION_STORAGE_KEY_MATERIAUX,
} from 'src/app/shared/constants/admin.constants';
import { PaginatedDataSource } from 'src/app/shared/paging/page';
import { SessionStoragePaginationUtils } from 'src/app/utils/session-storage-pagination.utils';
import { URL_GESTION_MATERIAUX_CONSULTER, URL_GESTION_MATERIAUX_EDIT } from 'src/app/shared/constants/url.constants';
import { TypeMateriau } from '../../../../../model/type-materiau.model';

export const DEFAULT_SORT = { direction: 'asc', active: 'nom' };

@Component({
    selector: 'app-tableau-materiaux',
    templateUrl: './tableau-materiaux.component.html',
    styleUrls: ['./tableau-materiaux.component.scss'],
})
export class TableauMateriauxComponent extends BaseComponent implements OnInit {
    @Input() initialPageSize = INITIAL_PAGE_SIZE;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayedColumns: string[] = ['nom', 'actions'];

    dataSource: PaginatedDataSource<TypeMateriau>;
    sort = SessionStoragePaginationUtils.getInitialSort(SESSION_STORAGE_KEY_MATERIAUX, DEFAULT_SORT);
    searchValue = SessionStoragePaginationUtils.getInitialReseach(SESSION_STORAGE_KEY_MATERIAUX);
    private search = new BehaviorSubject(this.searchValue);

    constructor(
        private readonly referenceApiService: ReferenceApiService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly router: Router
    ) {
        super();
    }

    ngOnInit(): void {
        this.search.pipe(debounceTime(DEBOUNCE_TIME_VALUE), distinctUntilChanged(), takeUntil(this.ngUnsubscribe)).subscribe((search) => {
            this.dataSource.fetch(0, this.dataSource.size);
        });
        this.dataSource = new PaginatedDataSource<TypeMateriau>(
            (pageRequest) => {
                return this.cnSpinnerService.withSpinner(this.referenceApiService.findAllMateriauxPage(this.search.getValue(), pageRequest));
            },
            SessionStoragePaginationUtils.getInitialSort(SESSION_STORAGE_KEY_EQUIPEMENT, DEFAULT_SORT),
            SessionStoragePaginationUtils.getInitialPageSize(SESSION_STORAGE_KEY_EQUIPEMENT, this.initialPageSize)
        );
    }

    onClickDupliquerMateriel(materiel: TypeMateriau) {
        this.setSessionStorageItem();
        this.router.navigate([URL_GESTION_MATERIAUX_EDIT, materiel.id, 'duplicate']);
    }

    onClickEditerMateriel(materiel: TypeMateriau) {
        this.setSessionStorageItem();
        this.router.navigate([URL_GESTION_MATERIAUX_EDIT, materiel.id]);
    }

    onClickConsulter(materiel: TypeMateriau) {
        this.setSessionStorageItem();
        this.router.navigate([URL_GESTION_MATERIAUX_CONSULTER, materiel.id]);
    }

    onClickDelete(materiel: TypeMateriau) {
        this.referenceApiService.deleteMateriel(materiel.id).subscribe((it) => {
            this.dataSource = new PaginatedDataSource<TypeMateriau>(
                (pageRequest) => {
                    return this.cnSpinnerService.withSpinner(this.referenceApiService.findAllMateriauxPage(this.search.getValue(), pageRequest));
                },
                SessionStoragePaginationUtils.getInitialSort(SESSION_STORAGE_KEY_EQUIPEMENT, DEFAULT_SORT),
                SessionStoragePaginationUtils.getInitialPageSize(SESSION_STORAGE_KEY_EQUIPEMENT, this.initialPageSize)
            );
        });
    }

    /**
     * Set les valeurs du tableau dans le session storage (pagination, recherche, tri ..)
     * @private
     */
    private setSessionStorageItem() {
        SessionStoragePaginationUtils.setSessionStorageItem(SESSION_STORAGE_KEY_MATERIAUX, this.dataSource.size, this.search.getValue(), this.sort);
    }

    /**
     * Action déclenchée lors de la saisie d'un caractère dans la barre de recherche
     * @param $event
     */
    onKeyupSearchEquipements($event: any) {
        this.search.next($event.target.value);
        this.setSessionStorageItem();
    }

    /**
     * Action déclenchée lors d'un tri dans le tableau
     * @param $event
     */
    onChangeSortEquipements($event: Sort) {
        this.sort = $event;
        this.dataSource.sortBy($event);
        this.setSessionStorageItem();
    }
}
