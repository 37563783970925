import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-missions',
    templateUrl: './missions.component.html',
    styleUrls: ['./missions.component.scss'],
})
export class MissionsComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
