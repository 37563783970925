import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersListComponent } from './users-list/users-list.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { UserManagementService } from './user-management.service';
import { LibFormContainerModule } from '../form-container/form-container.module';
import { UserDeleteDialogComponent } from './user-delete-dialog/user-delete-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TranslateAuthorityPipe } from './translate-authority.pipe';
import { UserSetPasswordDialogComponent } from './user-set-password-dialog/user-set-password-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        LibFormContainerModule,
        MatFormFieldModule,
        FormsModule,
        MatInputModule,
        MatSelectModule,
        MatDialogModule,
        MatCheckboxModule,
        MatAutocompleteModule,
    ],
    exports: [UsersListComponent, UserDetailComponent, UserDeleteDialogComponent],
    declarations: [UsersListComponent, UserDetailComponent, UserDeleteDialogComponent, TranslateAuthorityPipe, UserSetPasswordDialogComponent],
})
export class LibAdminModule {
    static forRoot(): ModuleWithProviders<LibAdminModule> {
        return {
            ngModule: LibAdminModule,
            providers: [UserManagementService],
        };
    }
}
