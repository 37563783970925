import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Document } from '../../../../../model/document.model';
import { Diagnostic } from '../../../../../model/diagnostic.model';
import { Intervention } from '../../../../../model/intervention.model';
import { ReferencePrestation } from 'src/app/model/reference-prestation.model';
import { InterventionService } from 'src/app/services/intervention.service';
import { EditDocumentModalComponent } from '../../edit-document-modal/edit-document-modal.component';
import { CnSpinnerService } from '../../../cn-spinner/service/cn-spinner.service';
import { MatDialog } from '@angular/material/dialog';
import { Icon, NotificationService } from 'src/app/commons-lib';
import * as moment from 'moment';
import { DATE_FORMAT_INTERNATIONAL } from '../../../../../shared/constants/cndiag.constants';
import { StateChoiceBoxes } from 'src/app/model/states.model';
import { TypeCommentaire, enumTypesCommentaire } from 'src/app/model/type-commentaire.model';
import { DocumentsService } from 'src/app/services/documents.service';

@Component({
    selector: 'app-documents-detail',
    templateUrl: './documents-detail.component.html',
    styleUrls: ['./documents-detail.component.scss'],
})
export class DocumentsDetailComponent implements OnInit, OnChanges {
    @Input() document: Document;
    @Input() diagnostic: Diagnostic;
    @Input() intervention: Intervention;
    @Input() readonlyMode: boolean;
    @Input() showCheckpoints = true;

    @Output() closedEditPanel = new EventEmitter<any>();
    @Output() onChange = new EventEmitter<void>();

    // Permet de n'afficher que les checkpoints correspondant à la referencePrestation que l'on a dans l'intervention
    referencePrestationToShow: ReferencePrestation;
    // S'il n'y a pas de checkpoint pour la referencePrestaiton courante, pas besoin d'afficher le bloc point de controle
    hasCheckpoints = true;

    validOption = new StateChoiceBoxes('Valide', true, new Icon('check_circle'), 'ok');
    invalidOption = new StateChoiceBoxes('Invalide', false, new Icon('cancel'), 'ko');

    choices: StateChoiceBoxes[] = [this.validOption, this.invalidOption];
    typeDocPresentation: TypeCommentaire = enumTypesCommentaire.JUSTIFICATION_NON_PRESENCE;
    colorWarning: string = 'to-justify';
    constructor(
        private readonly interventionService: InterventionService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly matDialog: MatDialog,
        private readonly notificationService: NotificationService,
        private readonly documentsService: DocumentsService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.document.isPresented = this.document.isPresented || this.document.dateDocument != undefined;
        this.updateCheckpoints();
    }

    ngOnInit(): void {
        // Recherche de la référence prestation correspondante au diagnostic courant
        this.updateCheckpoints();
    }

    private updateCheckpoints() {
        if (this.diagnostic) {
            this.referencePrestationToShow = this.interventionService.findReferencePrestationByIdDiagnostic(this.intervention, this.diagnostic);

            if (this.referencePrestationToShow) {
                const typeDocumentCheckpointCurrent = this.document.typeDocument.typeDocumentCheckpoint.find((typeDocumentCheckpointValue) => {
                    return this.referencePrestationToShow.id == typeDocumentCheckpointValue.referencePrestation.id;
                });

                this.hasCheckpoints = typeDocumentCheckpointCurrent && typeDocumentCheckpointCurrent.checkpoints.length > 0;
            }
        }
    }

    /**
     * Edition du document
     */
    onClickEditDocument() {
        const documentToEditCopy = { ...this.document };
        return this.matDialog
            .open(EditDocumentModalComponent, {
                data: {
                    idIntervention: this.intervention.id,
                    document: documentToEditCopy,
                    typePrestation: undefined,
                    documentsAlreadyPresent: this.intervention.documents.map((it) => {
                        return {
                            nom: it.nom,
                            id: it.id,
                        };
                    }),
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.intervention.documents = this.intervention.documents.concat(result.document);
                    this.cnSpinnerService.withSpinner(this.interventionService.updateIntervention(this.intervention)).subscribe(() => {
                        this.notificationService.success('Le document a été modifié');
                        this.interventionService.reloadCurrentIntervention();
                    });
                    this.document = result.document;
                }
            });
    }

    onCloseEditPanel(): void {
        this.closedEditPanel.emit();
    }

    handleDateDocumentModelChange($event) {
        if ($event) {
            const dateDocument = moment($event);
            this.document.dateDocument = dateDocument.format(DATE_FORMAT_INTERNATIONAL);
        } else {
            this.document.dateDocument = null;
            this.documentsService.checkConformiteDocument(this.document, this.referencePrestationToShow);
        }
        this.onChange.emit();
    }

    presenceJustificationNonPresentation(): boolean {
        return (
            this.intervention.commentaires.filter(
                (c) => this.document.commentairesId.includes(c.id) && c.type === enumTypesCommentaire.JUSTIFICATION_NON_PRESENCE
            ).length > 0
        );
    }
}
