/**
 * L'aide d'une section
 */
import { TypePrestation } from './type-prestation.model';

export class SectionHelp {
    /**
     * Titre de l'aide
     */
    title?: string;

    /**
     * Type prestation
     */
    typePrestation?: TypePrestation;

    /**
     * Le contenu de l'aide
     */
    sectionId?: string;

    constructor(title: string, typePrestation: TypePrestation, sectionId: string) {
        this.title = title;
        this.typePrestation = typePrestation;
        this.sectionId = sectionId;
    }
}
