import { Injectable } from '@angular/core';
import { HttpErrorInterceptor } from 'src/app/commons-lib';
import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { EtatIntervention, Intervention, PrestationDiagnostic } from '../model/intervention.model';
import { Document } from '../model/document.model';
import { FileService } from './file.service';
import { OfflineStorageService, ResourceWrapper } from '../shared/offline/offline-storage.service';
import { STORAGE_KEY_AGENDAS, STORAGE_KEY_INTERVENTIONS } from '../shared/constants/indexeddb.constants';
import { DataWithMeta } from '../shared/offline/offline-storage';
import { extractPage, PageRequest, toHttpParams } from '../shared/paging/page';
import { BaseObject } from '../model/base-object.model';

/**
 * Service d'appel aux APIs pour les interventions.
 * Ne pas appeler directement depuis les composants des pages, uniquement depuis d'autres services.
 * NB : On utilise HttpBackend au lieu de HttpClient ici, pour ne pas passer par les intercepteurs (donc on bypasse l'intercepteur {@link HttpErrorInterceptor}).
 *      Le service appelant doit donc gérer lui-même les erreurs HTTP.
 */
@Injectable({
    providedIn: 'root',
})
export class InterventionApiService {
    public resourceUrl = `${environment.apiUrl}/interventions`;

    // Ressource pour le wrapper HL
    private resource: ResourceWrapper<Intervention, string> = this.offlineStorage.wrapRestResource<Intervention, string>({
        cacheName: STORAGE_KEY_INTERVENTIONS,
        idField: 'id',
        resourceUrl: `${environment.apiUrl}/interventions`,
        pullUrl: `${environment.apiUrl}/interventions/current-user-between-dates`,
    });

    private resourceAgenda: ResourceWrapper<Intervention, string> = this.offlineStorage.wrapRestResource<Intervention, string>({
        cacheName: STORAGE_KEY_AGENDAS,
        idField: 'id',
        resourceUrl: `${this.resourceUrl}/agenda`,
    });

    constructor(
        private http: HttpClient,
        private fileService: FileService,
        private offlineStorage: OfflineStorageService
    ) {}

    findAllForCurrentUser(): Observable<Intervention[]> {
        return this.http.get<Intervention[]>(this.resourceUrl);
    }

    /**
     * Renvoit l'ensemble des interventions qui ne sont ni TERMINEE ni ANNULLEE et dont le userId est prestataire.
     * @returns
     */
    findAllActiveForCurrentUser(): Observable<Intervention[]> {
        return this.http.get<Intervention[]>(this.resourceUrl + '/active-for-curent-user');
    }

    findAllForCurrentUserBetweenDates(): Observable<Intervention[]> {
        return this.resource.getAll(null, null, `${this.resourceUrl}/current-user-between-dates`);
    }

    // TODO voir comment faire pour agenda, nouvelle collection ou pas...
    findAllForCurrentUserForAgenda(): Observable<Intervention[]> {
        return this.resourceAgenda.getAll();
    }

    findAllForListeInterventionsAdmin(): Observable<Intervention[]> {
        return this.http.get<Intervention[]>(`${this.resourceUrl}/liste-interventions`);
    }

    /**
     * Renvoie l'id et le nom de chaque intervention pouvant être parente d'une prestation
     * @param numeroCommande filtre les interventions qui ont ce numéro de commande
     * @param idBien Filtre les interventions qui ont ce bien comme bien principal
     */
    findAllForParentPrestations(idBien: string, numeroCommande: string): Observable<BaseObject[]> {
        let params = new HttpParams();
        if (numeroCommande) {
            params = params.set('numeroCommande', numeroCommande);
        }
        if (idBien) {
            params = params.set('idBien', idBien);
        }
        return this.http.get<BaseObject[]>(`${this.resourceUrl}/interventions-parent-prestation`, { params });
    }

    findAllByCommandeId(commandeId: string): Observable<Intervention[]> {
        return this.http.get<Intervention[]>(`${this.resourceUrl}/liste-interventions-by-commandeId/${commandeId}`);
    }

    searchInterventions(
        search: string,
        startDate: string,
        endDate: string,
        etats: EtatIntervention[],
        types: string[],
        idsPrestataire: string[],
        idsAgence: string[],
        idsCommande: string[],
        pageRequest: PageRequest<Intervention>,
        isRendezVous?: boolean[]
    ) {
        const params = {
            ...toHttpParams(pageRequest),
            search,
            startDate,
            endDate,
            etats,
            types,
            idsPrestataire,
            idsAgence,
            idsCommande,
        };

        if (isRendezVous.length && isRendezVous.length < 2) {
            // Si on filtre uniquement sur isRendezVous true OU false
            // Ajout du filtre optionnel isRendezVous
            params['isRendezVous'] = isRendezVous[0];
        }

        return extractPage(
            this.http.get<Intervention[]>(`${this.resourceUrl}/liste-interventions-page`, {
                observe: 'response',
                params: params,
            })
        );
    }

    /**
     * Find one intervention en mode chef de projet
     * @param id
     */
    findOneOnline(id: string): Observable<Intervention> {
        return this.http.get<Intervention>(`${this.resourceUrl}/${id}`);
    }

    /**
     * Find one intervention en mode opérateur avec gestion de la base locale
     * @param id
     */
    findOne(id: string): Observable<Intervention> {
        return this.resource.getOne(id);
    }

    /**
     * Création d'intervention en mode chef de projet
     * @param intervention
     */
    createIntervention(intervention: Intervention): Observable<Intervention> {
        return this.http.post<Intervention>(this.resourceUrl, intervention);
    }

    /**
     * Update de l'intervention en mode chef de projet
     * @param intervention
     */
    updateInterventionOnline(intervention: Intervention): Observable<Intervention> {
        return this.http.put<Intervention>(this.resourceUrl, intervention);
    }

    /**
     * Update de l'intervention en mode opérateur avec gestion de la base locale
     * @param intervention
     */
    updateIntervention(intervention: Intervention): Observable<Intervention> {
        return this.resource.save(intervention);
    }

    /**
     * Push de toutes les interventions modifiées localement vers le back
     */
    pushInterventions(): Observable<Intervention[]> {
        return this.resource.push();
    }

    /**
     * Pull des interventions du back vers le local
     */
    pullInterventions(): Observable<DataWithMeta<Intervention>[]> {
        return this.resource.pull();
    }

    //////////////////////////////////////////////////////////////////////////////////
    // GESTION DES FICHIERS DE DOCUMENTS
    //////////////////////////////////////////////////////////////////////////////////

    uploadDocumentFileWithProgressReport(
        idIntervention: string,
        idDocument: string,
        file: FormData,
        fileId: string,
        dateUpload: string
    ): Observable<HttpEvent<any>> {
        let params = new HttpParams();
        params = params.set('fileId', fileId);
        params = params.set('dateUpload', dateUpload);
        return this.http.post<any>(`${this.resourceUrl}/${idIntervention}/documents/${idDocument}`, file, {
            params,
            reportProgress: true,
            observe: 'events',
        });
    }

    downloadDocumentFile(idIntervention: string, document: Document): void {
        const fileDownloadTokenUrl = `${this.resourceUrl}/${idIntervention}/documents/${document.id}/fichiers/${document.idFichier}/download`;
        const fileName = document.nomFichier;
        this.fileService.downloadDocumentFile(fileDownloadTokenUrl, fileName);
    }

    getDefaultDocuments(prestations: PrestationDiagnostic[]): Observable<Document[]> {
        return this.http.post<Document[]>(`${this.resourceUrl}/default-documents`, prestations);
    }

    findAllInterventionsAdminByName(search: string, pageRequest: PageRequest<Intervention>) {
        return extractPage(
            this.http.get<Intervention[]>(`${this.resourceUrl}/find-all-interventions-searched`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), search },
            })
        );
    }
}
