export enum EtatWorkflow {
    /** Actif : visible sur l'interface d'intervention/diagnostic */
    ACTIF = 'ACTIF',
    /** Inactif, en cours de création... : non visible sur l'interface d'intervention/diagnostic  */
    INACTIF = 'INACTIF',
}

/**
 * À partir d'un etat, renvoie le label associé.
 */
export function etatWorkflowToLabel(etat: EtatWorkflow): string {
    switch (etat) {
        case EtatWorkflow.ACTIF:
            return 'Actif';

        case EtatWorkflow.INACTIF:
            return 'Inactif';

        default:
            return etat;
    }
}
