<div class="fullscreen-content">
    <div class="header">
        <div class="title">Ajouter un commentaire</div>
        <button mat-button class="close-button" (click)="onClickCancel()">
            <lib-icon>close</lib-icon>
        </button>
    </div>

    <div class="content row scrollable-content">
        <!-- Colonne gauche-->
        <div class="col-xl-6 flex-column h-100 p-4">
            <div class="row pb-2">
                <div class="col-6">
                    <div class="mat-h3 mb-2 primary">Type de commentaire :</div>
                    <div class="d-flex mb-2 flex-wrap" [ngClass]="{ disabledDiv: readonlyMode }">
                        <div class="d-flex no-gutters align-items-center mr-2 mb-2" *ngFor="let type of commentTypes">
                            <button
                                mat-button
                                color="primary"
                                class="px-3"
                                [disabled]="typeCommentairePredefini && type !== nouveauCommentaire.type"
                                [ngClass]="type === nouveauCommentaire.type ? 'selected-comment-type' : 'unselected-comment-type'"
                                (click)="onClickTypeCommentaire(type)"
                            >
                                <span class="d-flex align-items-center">
                                    {{ type | typeCommentaire }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="!readonlyMode" id="idImageUpload" class="col-6">
                    <div *ngFor="let com of commentaires">
                        <ng-container *ngIf="com.imageId === nouveauCommentaireTemp?.imageId">
                            <app-wizy-file-uploader
                                *ngIf="editmode && com.imageId | fileDownload | async as fileContent"
                                [dataUrl]="fileContent"
                                [isImage]="true"
                                [interventionId]="intervention?.id"
                                [diagnosticId]="diagnostic?.id"
                                [referenceId]="nouveauCommentaire?.id"
                                [fileId]="nouveauCommentaire?.imageId"
                                [typeReferenceFichier]="typeReferenceFichierPicture"
                                [textConfig]="fileUploaderTextConfig"
                                [autoUpload]="false"
                                (fileUploaded)="onFileUploaded($event)"
                                (deleted)="onFileDeleted()"
                            ></app-wizy-file-uploader>
                        </ng-container>
                    </div>
                    <app-wizy-file-uploader
                        *ngIf="nouveauCommentaireTemp?.imageId == null"
                        [isImage]="true"
                        [interventionId]="intervention?.id"
                        [diagnosticId]="diagnostic?.id"
                        [referenceId]="nouveauCommentaire?.id"
                        [fileId]="nouveauCommentaire?.imageId"
                        [typeReferenceFichier]="typeReferenceFichierPicture"
                        [textConfig]="fileUploaderTextConfig"
                        [autoUpload]="false"
                        (fileUploaded)="onFileUploaded($event)"
                        (deleted)="onFileDeleted()"
                    ></app-wizy-file-uploader>
                </div>
                <div *ngIf="localisation != null && localisation.length > 0" class="d-flex col-xl-6 p-2 align-items-center">
                    <div class="col-xl-6 mat-subheading-2 m-0 primary">Localisation :</div>
                    <div class="col-xl-6">
                        <app-custom-select
                            [placeholder]="'Localisation'"
                            [disabled]="readonlyMode"
                            [multiple]="true"
                            [filter]="false"
                            [groupChoices]="localisation"
                            [showGroup]="true"
                            [addline]="false"
                            [selectedValue]="nouveauCommentaire?.localisations"
                            (selectedValueChange)="localisationChange($event)"
                        >
                        </app-custom-select>
                    </div>
                </div>
            </div>
            <div class="underlined"></div>

            <div *ngIf="!readonlyMode && elements.length > 0" class="d-flex flex-column chips mb-2">
                <div class="primary d-flex align-items-center align-self-start">
                    Lié à {{ elements.length }} élement<span *ngIf="elements.length > 1">s</span>
                </div>
                <mat-form-field class="w-100">
                    <mat-chip-list aria-label="Liste des éléments sélectionnés">
                        <mat-chip
                            *ngFor="let el of elements"
                            selectable="true"
                            [removable]="elementsAreRemovable && removable"
                            (removed)="onClickDeleteElement(el)"
                        >
                            {{ el | findAttribute: elementPathValueDisplay }}
                            <mat-icon matChipRemove *ngIf="elementsAreRemovable && removable">cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </mat-form-field>
            </div>
            <!-- Commentaires prédéfinis-->
            <div class="scrollable-content" [style.maxHeight]="heigthCommPre" *ngIf="!readonlyMode && reponsesPredefinisFiltered.length">
                <div class="d-flex flex-column mb-2">
                    <div *ngIf="intitule" class="mat-h3 mb-2 primary w-100">{{ intitule }}</div>
                    <mat-card
                        class="px-2 py-2 mb-2 d-flex flex-column justify-content-between text-justify commentaire-predefini"
                        *ngFor="let com of reponsesPredefinisFiltered"
                        (click)="onClikAddCommentairePredefini(com)"
                    >
                        {{ com }}
                    </mat-card>
                </div>
                <div class="underlined"></div>
            </div>

            <!-- Commentaire -->
            <div class="div-fixed" [ngClass]="{ disabledDiv: readonlyMode }">
                <div class="underlined my-2"></div>
                <!-- Saisi libre d'un commentaire -->
                <div class="input-with-prefix">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Commentaire</mat-label>
                        <textarea
                            matInput
                            cdkTextareaAutosize
                            cdkAutosizeMinRows="5"
                            cdkAutosizeMaxRows="5"
                            [(ngModel)]="nouveauCommentaire.contenu"
                        ></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <!-- Colonne droite-->
        <div class="col-xl-6 flex-column flex-grow-1 main-background h-100 p-4 scrollable-content">
            <div *ngFor="let com of commentaires" class="mb-4">
                <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                        <b>{{ com.date | date: 'EEEE d MMMM y - HH:mm:ss' | titlecase }}</b>
                        ({{ com.type | typeCommentaire }}
                        <span *ngIf="com.fromDescription && !isDescription"> depuis la description</span>
                        <span *ngIf="com.localisations != null && com.localisations.length">, Localisation : {{ com.localisations }}</span
                        >)
                    </div>
                    <button
                        *ngIf="
                            !readonlyMode &&
                            (!com.fromDescription || isDescription) &&
                            com.type !== enumTypesCommentaire.JUSTIFICATION_ANNULATION_PRESTATION &&
                            com.type !== enumTypesCommentaire.JUSTIFICATION_MODIFICATION_TECHNIQUES_ANALYSES_PRELEVEMENT &&
                            com.type !== enumTypesCommentaire.MOTIF_MODIFICATION_FICHIER
                        "
                        mat-icon-button
                        color="primary"
                        (click)="onClickStartUpdateCommentaire(com)"
                    >
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button
                        *ngIf="
                            !readonlyMode &&
                            (!com.fromDescription || isDescription) &&
                            com.type !== enumTypesCommentaire.JUSTIFICATION_ANNULATION_PRESTATION &&
                            com.type !== enumTypesCommentaire.JUSTIFICATION_MODIFICATION_TECHNIQUES_ANALYSES_PRELEVEMENT &&
                            com.type !== enumTypesCommentaire.MOTIF_MODIFICATION_FICHIER
                        "
                        mat-icon-button
                        color="warn"
                        (click)="onClickDeleteCommentaire(com)"
                    >
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <div class="separation">
                    <div *ngIf="com.imageId | fileDownload | async as fileContent">
                        <img *ngIf="fileContent" [src]="fileContent" alt="Photo du commentaire" class="img-fluid photo-bien resize_picture" />
                        <div class="p-2 comments">
                            GPS Latitude : {{ com.imageGpsLatitude }} GPS Longitude : {{ com.imageGpsLongitude }} Date :
                            {{ com.imageDateTimeOriginal }}
                        </div>
                    </div>

                    <div class="p-2 comments">{{ com.contenu }}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-div">
        <button *ngIf="!readonlyMode && !updating" mat-raised-button color="accent" (click)="onClickBtnConfirm()">
            <lib-icon> add </lib-icon>
            {{ 'Ajouter' | uppercase }}
        </button>
        <button
            mat-raised-button
            [disabled]="!nouveauCommentaire.type || !nouveauCommentaire.contenu"
            color="warn"
            *ngIf="updating"
            [matTooltip]="'Annuler les modifications'"
            (click)="onClickCancelUpdateCommentaire()"
        >
            <lib-icon> close </lib-icon>
            <span>{{ 'Annuler les modifications' | uppercase }}</span>
        </button>
        <button
            *ngIf="updating"
            [matTooltip]="'Sauvegarder les modifications'"
            [disabled]="!nouveauCommentaire.type || !nouveauCommentaire.contenu"
            (click)="onclickSaveUpdatedCommentaire()"
            mat-raised-button
            color="accent"
        >
            <lib-icon> download </lib-icon>
            <span>{{ 'Modifier' | uppercase }}</span>
        </button>
        <button mat-raised-button color="warn" (click)="onClickCancel()">
            <lib-icon> close </lib-icon>
            {{ 'Annuler' | uppercase }}
        </button>
        <button
            *ngIf="!readonlyMode"
            mat-raised-button
            color="primary"
            (click)="onClickBtnClose()"
            [disabled]="this.commentairesAjoutes?.length ? false : disabledSave"
        >
            <lib-icon> download </lib-icon>
            {{ 'Sauvegarder' | uppercase }}
        </button>
    </div>
</div>
