import { Pipe, PipeTransform } from '@angular/core';
import { TypeAnalyse, typeAnalyseToLabelSimple } from '../model/prelevement-generique.model';

/**
 * Affiche le nom simplifié d'un type d'analyse
 */
@Pipe({
    name: 'typeAnalyseSimple',
})
export class TypeAnalyseSimplePipe implements PipeTransform {
    transform(typeAnalyse: TypeAnalyse): string {
        return typeAnalyseToLabelSimple(typeAnalyse);
    }
}
