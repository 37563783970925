import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StateChoiceBoxes } from '../../../../../../model/states.model';
import { EtapeDiagnosticGenerique, ItemEtapeDiagnosticGenerique } from '../../../../../../model/diagnostic-etape.model';
import { TypeCommentaire, enumTypesCommentaire } from '../../../../../../model/type-commentaire.model';
import { CalculatriceModaleComponent } from '../../../../../shared/calculatrice-modale/calculatrice-modale.component';
import { MatDialog } from '@angular/material/dialog';
import { Aide, Anomalie, PointControle, Reponse } from '../../../model/cee.model';
import { BaseComponent, Icon } from 'src/app/commons-lib';
import { nonOption, ouiOption } from '../../../../electricite/shared/electricite.constants';
import { MESURE_NON_COMMUNIQUE } from '../../../../mesurage/shared/mesurage.constants';
import { DialogCalculPartieComponent } from '../dialog-calcul-partie/dialog-calcul-partie.component';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { InterventionFile, TypeReferenceFichier } from '../../../../../../model/intervention-file.model';
import { FileUploaderOutput, FileUploaderTextConfigFile } from '../../../../../shared/file-uploader/wizy-file-uploader.component';
import { InterventionFileService } from '../../../../../../services/intervention-file.service';
import {
    CODE_NON_COMMUNIQUE,
    CODE_NON_VISIBLE,
    CODE_SANS_OBJET,
    EGAL,
    INFERIEUR,
    INFERIEUR_EGAL,
    nonVisibleOption,
    partiellementAccessible,
    SUPPERIEUR,
    SUPPERIEUR_EGAL,
} from '../../../shared/cee.constants';
import { WidgetSelectModeEnum } from '../../../../../shared/widget-select/widget-select.component';
import {
    PARAM_TOOLTIP_NON,
    PARAM_TOOLTIP_NON_VISITEE,
    PARAM_TOOLTIP_OUI,
    PARAM_TOOLTIP_PARTIELLEMENT_ACCESSIBLE,
} from '../../../../../../shared/constants/cndiag.constants';
import { Observable } from 'rxjs';
import { InterventionService } from 'src/app/services/intervention.service';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { Intervention } from 'src/app/model/intervention.model';
import { takeUntil } from 'rxjs/operators';
import { CommentModalComponent } from 'src/app/modules/shared/comment-modal/comment-modal.component';
import { DialogUtils } from 'src/app/utils/dialog.utils';
import { CheckpointHelpModalComponent } from 'src/app/modules/shared/checkpoint-help-modal/checkpoint-help-modal.component';

@Component({
    selector: 'app-ptc-content',
    templateUrl: './ptc-content.component.html',
    styleUrls: ['./ptc-content.component.scss'],
})
export class PtcContentComponent extends BaseComponent {
    readonly MESURE_NON_COMMUNIQUE = MESURE_NON_COMMUNIQUE;
    readonly widgetSelectModeEnum = WidgetSelectModeEnum;
    CLASSIQUE = 'classique'; //iconne OUI NON etc.. a droite de la ligne du Point de controle
    BOUTON_DUPLIQUER = 'bouttonDupliquer'; //Bouton de duplication de zone ou de solution
    RATIO = 'ratio'; //boutons ratio
    RATIO_PLUS = 'ratioPlus'; //bouton ratio + un champs libre
    RATIO_PRECISION = 'ratioPrecision'; //boutons ratio + un champs de "précision" (on concatera la description du bouton sélectionné et la précision dans le rapport)
    LISTE_PLUS = 'listePlus'; //Liste déroulante + champ libre
    LISTE_CHECKBOX_PLUS = 'listeChecboxPlus'; //Liste déroulante + champ libre
    TEXT_LIBRE = 'txtBoxLibre'; //textbox libre
    TEXT_NUMERIQUE = 'txtBoxNumerique'; //textbox numériqaue
    DATE = 'date'; //Date
    NUMERIQUE_ZERO = 'txtBoxNumeriqueZero';
    TEXT_NUMERIQUE_AUTO = 'txtBoxNumeriqueAuto';
    PHOTO = 'photo';
    readonly NON_VISIBLE = CODE_NON_VISIBLE;
    readonly OPTIONS: StateChoiceBoxes[] = [ouiOption, nonOption, partiellementAccessible, nonVisibleOption];
    interventId: string;
    tooltips = [PARAM_TOOLTIP_OUI, PARAM_TOOLTIP_NON, PARAM_TOOLTIP_PARTIELLEMENT_ACCESSIBLE, PARAM_TOOLTIP_NON_VISITEE];

    @Input() name: string;
    @Input() diagnostic: Diagnostic;
    @Input() libelle: string;
    @Input() decalage: number = 0;
    @Input() type: string; // PHOTO | CLASSIQUE | BOUTON_DUPLIQUER | RATIO | RATIO_PLUS | RATIO_PRECISION | LISTE_PLUS | TEXT_LIBRE | TEXT_NUMERIQUE | DATE
    @Input() value: EtapeDiagnosticGenerique;
    @Input() checkpoint: PointControle;
    @Input() valeurCible: string;
    @Input() valeurAControler: string;
    @Input() valeurAControlerReel: string;
    @Input() aides: Aide[];

    @Input('choices') set setChoices(values: Reponse[]) {
        if (values[0]?.option) {
            this.type = this.RATIO_PRECISION;
        }
        this.choices = [];
        for (const value of values) {
            const option = this.OPTIONS.find((opt) => opt.value == value.code);
            this.choices.push(
                option
                    ? option
                    : value.option
                    ? new StateChoiceBoxes(
                          value.option.label,
                          value.code,
                          new Icon(value.option?.icone?.nom, value.option?.icone?.theme),
                          this.getCssClass(value.option?.icone?.color)
                      )
                    : new StateChoiceBoxes(value.description, value.code, new Icon('radio_button_unchecked'))
            );
        }
        this.showNonCommunique = this.choices.some((ch) => ch.value === CODE_NON_COMMUNIQUE);
        if (this.type == this.BOUTON_DUPLIQUER) {
            this.value.checked = true; // Ne pas l'afficher dans le RAF
        }
        if (this.choices) {
            this.choicesListString = this.choices.map((v) => v.label);
        }
        if (this.LISTE_PLUS === this.type && this.value?.valeur) {
            if (!this.choicesListString.find((c) => c === this.value.valeur)) {
                this.choicesListString.push(this.value.valeur);
            }
        }
    }
    private getCssClass(value) {
        switch (value) {
            case 'GREEN':
                return 'ok';
            case 'RED':
                return 'ko';
            case 'ORANGE':
            case 'YELLOW':
                return 'warning';
        }
        return 'dark';
    }
    choices: StateChoiceBoxes[];
    choicesListString: string[];
    typeReferenceFichierPicture = TypeReferenceFichier.PHOTO_PRESTATION;
    fileUploaderTextConfig: FileUploaderTextConfigFile;
    fileUploaderOutput: FileUploaderOutput;
    imageGpsLatitude: string;
    imageGpsLongitude: string;
    imageDateTimeOriginal: string;
    //readonly interventionFileService: InterventionFileService;
    showNonCommunique: boolean = false;
    @Input() showNotesButtons: boolean = true;
    @Input() disabled: boolean;
    @Input() required: boolean;
    @Input() canForceAnomalie: boolean;
    @Input() isCalculette: boolean;
    @Input() isCalculZone: boolean;
    @Output() valueChange: EventEmitter<EtapeDiagnosticGenerique> = new EventEmitter<EtapeDiagnosticGenerique>();
    @Output() onDuplicate: EventEmitter<EtapeDiagnosticGenerique> = new EventEmitter<EtapeDiagnosticGenerique>();

    constructor(
        private matDialog: MatDialog,
        private readonly interventionFileService: InterventionFileService,
        private readonly interventionService: InterventionService,
        private readonly cnSpinnerService: CnSpinnerService
    ) {
        super();
        this.initFileUploaderTextConfig();
    }

    /**
     * La mesure est égale à zéro
     */
    setZero() {
        this.value.valeur = '0';
        this.valueChange.emit(this.value);
    }

    openModeC() {
        console.log('mode C');
    }

    /**
     * @param {"saisie" | "calculatrice"} pMode
     */
    openNumKeyboardOrCalculator(pMode: 'saisie' | 'calculatrice') {
        return this.matDialog
            .open(CalculatriceModaleComponent, {
                data: {
                    mode: pMode,
                    originalValue: this.value.valeur,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.value.valeur = result.resultat;
                    this.valueChange.emit(this.value);
                }
            });
    }

    /**
     * Initialise l'objet contenant les informations textuelles à afficher dans le composant d'upload
     * @private
     */
    private initFileUploaderTextConfig() {
        this.fileUploaderTextConfig = {
            mainText: `Ajouter l'image`,
            deleteToolTip: `Supprimer l'image`,
            changeToolTip: `Changer la photo`,
        };
    }

    /**
     * récupère l'intervention file lié a un commentaire
     * @idFile : l'id du fichier
     */
    public getInterventionFile(idFile: string): Observable<InterventionFile> {
        let interFiles: Observable<InterventionFile> = this.interventionFileService.findByIdInterventionFileId(
            this.diagnostic.idIntervention,
            TypeReferenceFichier.PHOTO_COMMENTAIRE,
            idFile
        );

        return interFiles;
    }

    gateDate(date: string): string {
        return new Date(+date).toLocaleDateString();
    }

    showAides(): boolean {
        let ret = false;
        if (this.aides !== null && this.aides !== undefined && this.aides.length > 0) {
            this.aides.forEach((aide) => {
                if (aide.contenu !== null || aide.description !== null) {
                    ret = true;
                }
            });
        }
        return ret;
    }

    onFileUploaded(fileUploaderOutput: FileUploaderOutput): void {
        // On met à jour le form avec l'id de l'image
        this.value.valeur = 'Photo';
        this.value.idImage = fileUploaderOutput.interventionFile.fileId;

        /*let uploadInterventionFile$;
        uploadInterventionFile$ = this.interventionFileService
            .uploadInterventionFile(fileUploaderOutput.interventionFile, fileUploaderOutput.dataUrl, null)
            .subscribe();*/

        let inter$ = this.interventionService.findOne(fileUploaderOutput.interventionFile.interventionId);
        inter$.subscribe((value) => {
            this.updateIntervention(value).subscribe();
        });
    }
    private updateIntervention(intervention: Intervention) {
        return this.cnSpinnerService.withSpinner(this.interventionService.updateIntervention(intervention)).pipe(takeUntil(this.ngUnsubscribe));
    }
    /**
     * Evenement déclenché lorsque l'image a été supprimée
     */
    onFileDeleted(fileUploaderOutput: FileUploaderOutput): void {
        // On supprime l'id de l'image dans le commentaire, ainsi que l'objet interventionFile
        this.value.valeur = undefined;
        this.value.idImage = undefined;
        this.fileUploaderOutput = null;
    }

    openDialogCalculPartieComponent() {
        const refDialog = this.matDialog.open(DialogCalculPartieComponent, {
            width: '70%',
            disableClose: true,
        });

        const zones = [];
        for (const ele of this.value.elements) {
            zones.push({
                nom: ele.valeur,
                surface: ele.nombre,
                mode: ele.type,
                quantite: ele.quantite,
            });
        }
        refDialog.componentInstance.zones = zones;
        refDialog.afterClosed().subscribe((result: any[]) => {
            if (result && result.length) {
                this.value.elements = [];
                for (const zone of result) {
                    const ele = new ItemEtapeDiagnosticGenerique();
                    ele.valeur = zone.nom;
                    ele.nombre = zone.surface;
                    ele.type = zone.mode;
                    ele.quantite = zone.quantite;
                    this.value.elements.push(ele);
                }
                this.value.valeur = '' + refDialog.componentInstance.total;
                this.valueChange.emit(this.value);
            }
        });
    }

    get conformeClass(): string {
        switch (true) {
            case this.value.valeur == this.NON_VISIBLE && !this.value.commentairesId.length:
                return 'point-de-controle-non-conforme';
            case this.value.valeur == CODE_SANS_OBJET || this.value.valeur == nonOption.value:
                return 'point-de-controle-justifier';
            case this.value.valeur == ouiOption.value || (this.value.valeur == this.NON_VISIBLE && !!this.value.commentairesId.length):
                return 'point-de-controle-conforme';
        }
        return 'point-de-controle-non-rempli';
    }

    get isAnomalie(): boolean {
        if (!this.checkpoint) {
            return false;
        }
        let anomalie: Anomalie = this.checkpoint.lstAnomalie.find((an) => an.codeReponse === this.value.valeur);

        if (!anomalie) {
            anomalie = this.checkpoint.lstAnomalie.find((an) => {
                let valeurReference = an.valeurReference;
                if (an.idPointDeControleReference) {
                    valeurReference = +this.valeurAControlerReel;
                }
                switch (an.type) {
                    case INFERIEUR:
                        return +this.value.valeur < valeurReference;
                    case SUPPERIEUR:
                        return +this.value.valeur > valeurReference;
                    case EGAL:
                        return +this.value.valeur === valeurReference;
                    case INFERIEUR_EGAL:
                        return +this.value.valeur <= valeurReference;
                    case SUPPERIEUR_EGAL:
                        return +this.value.valeur >= valeurReference;
                }
                return false;
            });
        }
        return !!anomalie;
    }

    get COMMENT_TYPES(): TypeCommentaire[] {
        return [
            enumTypesCommentaire.JUSTIFICATION_NON_CONFORMITE,
            enumTypesCommentaire.NOTE_PERSONNELLE,
            enumTypesCommentaire.REMARQUE,
            enumTypesCommentaire.CONSTATATION_DIVERSE,
        ];
    }

    protected readonly WidgetSelectModeEnum = WidgetSelectModeEnum;

    onChangeDate($event) {
        this.value.valeur = new Date($event);
        this.valueChange.emit(this.value);
    }

    addJustification(typeNouveauCommentaire: string) {
        this.matDialog
            .open(CommentModalComponent, {
                ...DialogUtils.configFullScreen(),
                data: {
                    commentairesId: this.value?.commentairesId,
                    replaceTypesCommentaires: true,
                    typesCommentaires: [
                        enumTypesCommentaire.JUSTIFICATION_NON_CONFORMITE,
                        enumTypesCommentaire.NOTE_PERSONNELLE,
                        enumTypesCommentaire.REMARQUE,
                        enumTypesCommentaire.CONSTATATION_DIVERSE,
                    ],
                    typeNouveauCommentaire: typeNouveauCommentaire,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result && result !== false) {
                    this.value.commentairesId = result.commentairesId;
                }
                this.valueChange.emit(this.value);
            });
    }

    openHelp() {
        const dialogRef = this.matDialog.open(CheckpointHelpModalComponent, {});

        const contents = [];
        this.aides.forEach((aide) => {
            contents.push({ innerHTML: aide.description, innerContenueHTML: aide.contenu });
        });

        const titre = this.aides[0].description;

        dialogRef.componentInstance.title = titre;
        dialogRef.componentInstance.contents = contents;
    }
}
