<h1 mat-dialog-title class="primary d-flex justify-content-between">
    <span>Ajouter un {{ isNewDocument ? 'document' : 'fichier' }}</span>
    <button mat-button (click)="onClickCancel()">
        <lib-icon>close</lib-icon>
    </button>
</h1>
<form [formGroup]="documentForm" (ngSubmit)="onSubmitForm()">
    <div class="w-100 pr-2 pt-2">
        <div mat-dialog-content class="dialog-content">
            <div class="d-flex flex-row">
                <ng-template [ngIf]="isNewDocument">
                    <div class="p-2 w-100">
                        <div class="my-3 primary">Document</div>
                        <!--LIÉ À LA PRESTATION-->
                        <div class="mat-subheading-2 m-0 primary required">Lié à la prestation</div>
                        <div>
                            <mat-form-field class="w-100">
                                <input matInput type="text" formControlName="prestationLie" />
                                <app-mat-error [control]="documentForm.get('prestationLie')"></app-mat-error>
                            </mat-form-field>
                        </div>
                        <!--NOM DOCUMENT-->
                        <div class="mt-3 mat-subheading-2 m-0 primary required">Nom du document</div>
                        <div>
                            <mat-form-field class="w-100">
                                <input matInput type="text" formControlName="nomDocument" />
                                <app-mat-error [control]="documentForm.get('nomDocument')"></app-mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <mat-divider class="mx-5" [vertical]="true"></mat-divider>
                </ng-template>
                <div class="p-2 w-100">
                    <div class="m-0 my-3 primary">Fichier</div>
                    <!--CRÉÉ PAR-->
                    <div class="mat-subheading-2 m-0 primary required">Créé par</div>
                    <div>
                        <mat-form-field class="w-100">
                            <input matInput type="text" formControlName="creePar" />
                            <app-mat-error [control]="documentForm.get('creePar')"></app-mat-error>
                        </mat-form-field>
                    </div>
                    <!--CRÉÉ LE-->
                    <div class="mat-subheading-2 m-0 primary required">Créé le</div>
                    <div>
                        <mat-form-field class="w-100">
                            <input matInput type="text" formControlName="dateCreation" [matDatepicker]="dateCreation" />
                            <app-mat-error [control]="documentForm.get('dateCreation')"></app-mat-error>
                            <mat-datepicker-toggle matSuffix [for]="dateCreation"></mat-datepicker-toggle>
                            <mat-datepicker #dateCreation></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <!--TRANSMIT PAR-->
                    <div class="mat-subheading-2 m-0 primary required">Transmit par</div>
                    <div>
                        <mat-form-field class="w-100">
                            <input matInput type="text" formControlName="transmitPar" />
                            <app-mat-error [control]="documentForm.get('transmitPar')"></app-mat-error>
                        </mat-form-field>
                    </div>
                    <!-- AJOUTER UN FICHIER -->
                    <mat-card *ngIf="!fileUploaded" class="w-100 d-flex small-card action-card align-self-center">
                        <mat-card-content class="w-100">
                            <button class="w-100" mat-button (click)="fileUploader.selectFile()">
                                <lib-icon>add</lib-icon>
                                <br />{{ 'Ajouter un fichier' }}
                            </button>
                        </mat-card-content>
                    </mat-card>
                    <lib-file-uploader #fileUploader (startUpload)="uploadFile($event)"></lib-file-uploader>
                    <div class="d-flex p-1" *ngIf="fileUploaded">
                        <span class="flex-grow-1">{{ fileUploaded.name }}</span>
                        <button mat-icon-button class="align-self-end button-group warn" (click)="deleteDocumentFile()">
                            <lib-icon class="icon-small">delete</lib-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div mat-dialog-actions class="justify-content-end">
            <button mat-button color="primary" type="button" (click)="onClickCancel()">
                <mat-icon>close</mat-icon>
                <span>Annuler</span>
            </button>
            <button mat-raised-button color="primary" [disabled]="documentForm.invalid" (click)="onSubmitForm()">
                <mat-icon>check</mat-icon>
                <span>Valider</span>
            </button>
        </div>
    </div>
</form>
