<div class="pb-5">
    <div class="block-title p-2 pl-5 my-3 d-inline-block" *ngIf="data">{{ data.title }}</div>
    <div class="block-content px-5 ml-2 mb-4">
        <ng-container *ngFor="let espace of listeEspaces">
            <div class="block-subtitle-2 mt-4">{{ espace.nom }}</div>
            <ng-container *ngIf="espace.listePerimetres.length === 0">
                <td colspan="3" class="text-center p-4">Aucun périmètre défini pour cet espace.</td>
            </ng-container>
            <ng-container *ngFor="let perimetre of espace.listePerimetres; let index = index">
                <!--------------- Détail des résultats -------------->
                <table>
                    <tr>
                        <th>Périmètre</th>
                        <th>Zone</th>
                        <th>Prélèvement <span class="font-weight-normal">/ Echantillons</span></th>
                        <th>Prés. amiante</th>
                        <th>Méthode</th>
                        <th>Résultats</th>
                        <th>Unité</th>
                    </tr>
                    <!-- Pas de zone -->
                    <tr *ngIf="perimetre?.listeZones.length === 0">
                        <td>{{ perimetre?.nom }}</td>
                        <td colspan="6" class="text-center p-4">Aucune zone pour ce périmètre</td>
                    </tr>
                    <!-- Zone présente -->
                    <ng-container *ngFor="let zone of perimetre?.listeZones">
                        <!-- Pas de prélèvement -->
                        <tr *ngIf="zone?.listePrelevements.length === 0">
                            <td>
                                <span class="primary"
                                    ><b>{{ perimetre?.nom }}</b></span
                                >
                            </td>
                            <td>
                                <span class="primary">{{ zone?.nom }}</span>
                            </td>
                            <td colspan="5" class="text-center p-4">Aucun prélèvement pour cette zone</td>
                        </tr>
                        <ng-container *ngFor="let prelevement of zone?.listePrelevements">
                            <tr>
                                <td>
                                    <span class="primary"
                                        ><b>{{ perimetre?.nom }}</b></span
                                    >
                                </td>
                                <td>
                                    <span class="primary">{{ zone?.nom }}</span>
                                </td>
                                <td>
                                    <b>{{ prelevement?.general?.reference }}</b>
                                </td>
                                <td colspan="4"></td>
                            </tr>

                            <ng-container *ngFor="let echantillonage of prelevement.echantillonages">
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>&nbsp;&nbsp;&nbsp;{{ echantillonage?.reference }}</td>

                                    <td>{{ echantillonage?.presenceAmiante }}</td>
                                    <td>{{ echantillonage?.methodeAnalyse }}</td>
                                    <td>
                                        <span [style.color]="echantillonage?.color">{{
                                            echantillonage?.resultatAnalyse
                                        }}</span>
                                    </td>
                                    <td>{{ echantillonage?.unite }}</td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </table>
            </ng-container>
        </ng-container>
    </div>
</div>
