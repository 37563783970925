/**
 * Identifiant d'étape d'un diagnostic (= type String)
 */

import { HapPreparation } from '../modules/diagnostics/hap/model/hap-preparation.model';
import { TypeMateriau } from './type-materiau.model';
import { ZoneMesure } from '../modules/diagnostics/mesurage/model/mesurage.model';
import { Espace } from './espace.model';
import { MongoUtils } from 'src/app/commons-lib';
import { PerimetreInvestigation } from './perimetre-investigation.model';

export type IdEtapeDiagnostic = string;

/**
 * Types d'étapes de dianostics
 */
export enum TypeEtapeDiagnostic {
    ETAPE_GENERIQUE = 'ETAPE_GENERIQUE',
    ETAPE_INITIALISATION_ELECTRIQUE = 'ETAPE_INITIALISATION_ELECTRICITE',
    ETAPE_DIAGNOSTIC_CEE = 'ETAPE_DIAGNOSTIC_CEE',
    ETAPE_ESPACE_HAP = 'ETAPE_ESPACE_HAP',
    ETAPE_ZONE_MESURE = 'ETAPE_ZONE_MESURE',
    ETAPE_LISTE_MATERIAU_CUSTOM = 'ETAPE_LISTE_MATERIAU_CUSTOM',
    ETAPE_DIAGNOSTIC_PREPARATION_HAP = 'ETAPE_DIAGNOSTIC_PREPARATION_HAP',
    ETAPE_ABSTRACT = 'ETAPE_ABSTRACT',
}

/**
 * Étape d'un diagnostic
 */
export interface EtapeDiagnostic {
    /**
     * Identifiant de l'étape (identique à la clé de la Map "etapes")
     */
    id: IdEtapeDiagnostic;

    /**
     * Type d'étape
     */
    type: TypeEtapeDiagnostic;
}

/**
 * Item d'une étape d'un diagnostic (implémentation générique).
 */
export class ItemEtapeDiagnosticGenerique {
    nombre = 0;
    quantite: number;
    situation: string;
    valeur?: string;
    type?: string;
    commentairesId?: string[] = [];
}

/**
 * Étape d'un diagnostic (implémentation générique).
 */
export class EtapeDiagnosticGenerique implements EtapeDiagnostic {
    id: IdEtapeDiagnostic;
    idUnique: string = MongoUtils.generateObjectId();
    type: TypeEtapeDiagnostic = TypeEtapeDiagnostic.ETAPE_GENERIQUE;
    valeur: any = null;
    valeurs: string[] = [];
    checked = false;
    elements: ItemEtapeDiagnosticGenerique[] = [];
    commentairesId?: string[] = [];
    validated: Boolean = false;
    disabled: Boolean = false;
    hide: Boolean = false;
    required: Boolean = false;
    idImage?: string;
    libelle?: string;
    isForceAnomalie: Boolean = false;
    isAnomalie: Boolean = false;
}

/**
 * Étape de l'initialisation Electicite
 */
export class EtapeDiagnosticInitialisationElectricite implements EtapeDiagnostic {
    id: IdEtapeDiagnostic;
    type: TypeEtapeDiagnostic = TypeEtapeDiagnostic.ETAPE_INITIALISATION_ELECTRIQUE;
    valeur: string | boolean = null;
    oldValeur: string = null;
    valeurs: string[] = [];
    checked = false;
    estimation: Boolean = false;
    elements: ItemEtapeDiagnosticGenerique[] = [];
    commentairesId?: string[] = [];
    validated: Boolean = false;
}

/**
 * Étape d'un diagnostic (implémentation abstract permettant d'envoyer un object).
 */
export class EtapeDiagnosticAbstract implements EtapeDiagnostic {
    id: IdEtapeDiagnostic;
    type: TypeEtapeDiagnostic = TypeEtapeDiagnostic.ETAPE_ABSTRACT;
    data: any = {};
    validated: Boolean = false;
    errors: any[] = [];
    constructor(id?: IdEtapeDiagnostic) {
        this.id = id;
    }

    message: string = '';
    //Utilisé pour donner une valeur par défaut
    setData(data: any) {
        this.data = data;

        return this;
    }
}

/**
 * Étape d'un diagnostic Périmetre
 */
export class EtapeDiagnosticPerimetreHAP implements EtapeDiagnostic {
    id: IdEtapeDiagnostic;
    type: TypeEtapeDiagnostic = TypeEtapeDiagnostic.ETAPE_ABSTRACT;
    data: {
        listePerimetreInvestigation: PerimetreInvestigation[];
    } = {
        listePerimetreInvestigation: [],
    };
    validated: Boolean = false;
    errors: any[] = [];
    message: string = '';

    //Utilisé pour donner une valeur par défaut
    setData(data: any) {
        this.data = data;

        return this;
    }
}

/**
 * Étape Espace dans l'onglet périmètre d'un diagnostic HAP
 */
export class EtapeDiagnosticEspacesHAP implements EtapeDiagnostic {
    id: IdEtapeDiagnostic;
    type: TypeEtapeDiagnostic = TypeEtapeDiagnostic.ETAPE_ESPACE_HAP;
    valeur: Espace[] = [];
    checked = false;
    commentairesId?: string[] = [];
}

/**
 * Étape préparation du diagnostic HAP, permet de stocker l'ensemble des données liées à la préparation des
 * prestations HAP suivante
 */
export class EtapeDiagnosticPreparationHAP implements EtapeDiagnostic {
    id: IdEtapeDiagnostic;
    type: TypeEtapeDiagnostic = TypeEtapeDiagnostic.ETAPE_DIAGNOSTIC_PREPARATION_HAP;
    valeur: HapPreparation = new HapPreparation();
    checked = false;
    commentairesId?: string[] = [];
    listeCompetences?: string[] = [];
}

/**
 * Étape matériaux custom permettant de stocker l'ensemble des type de matériau créé dans ce diagnostic
 * et qui n'existe pas dans la liste des matériaux connus
 */
export class EtapeDiagnosticMateriauCustom implements EtapeDiagnostic {
    id: IdEtapeDiagnostic;
    type: TypeEtapeDiagnostic = TypeEtapeDiagnostic.ETAPE_LISTE_MATERIAU_CUSTOM;
    valeur: TypeMateriau[] = [];
    checked = false;
    commentairesId?: string[] = [];
}

export class Situation {
    id: string;
    valeur: string;

    constructor(id: string, valeur: string) {
        this.id = id;
        this.valeur = valeur;
    }
}

/**
 * Étape Espace dans l'onglet périmètre d'un diagnostic HAP
 */
export class EtapeDiagnosticZoneMesure implements EtapeDiagnostic {
    id: IdEtapeDiagnostic;
    type: TypeEtapeDiagnostic = TypeEtapeDiagnostic.ETAPE_ZONE_MESURE;
    valeurs: ZoneMesure[] = [];
    checked = false;
    commentairesId?: string[] = [];
}
