<div class="p-3 d-flex justify-content-between align-items-center">
    <div [ngClass]="{ justify: niveauAJustifier }">{{ currentNiveau.nom }}</div>
    <div>
        <button *ngIf="!readonlyMode" mat-icon-button matTooltip="Éditer le niveau" (click)="handleEditerNiveau()">
            <mat-icon>edit</mat-icon>
        </button>
        <button
            mat-icon-button
            matTooltip="Dupliquer le niveau"
            [disabled]="readonlyMode"
            (click)="handleDupliquerNiveau()"
        >
            <mat-icon>content_copy</mat-icon>
        </button>
        <button
            *ngIf="!currentNiveau.storeyId"
            mat-icon-button
            matTooltip="Supprimer le niveau"
            class="warn"
            [disabled]="readonlyMode"
            (click)="handleSupprimerNiveau()"
        >
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>
<div class="flex-grow-1 overflow-auto">
    <div *ngIf="currentNiveau.volumes && currentNiveau.volumes.length > 0">
        <div
            *ngFor="let volume of currentNiveau.volumes; let index = index"
            class="w-100 pl-1 d-flex justify-content-between align-items-center border-bottom c-pointer"
            [ngClass]="{ 'elec-item-selected': volume === selectedVolume }"
            (click)="handleClickVolume(volume)"
        >
            <div class="d-flex align-items-center py-2">
                <ng-container [ngSwitch]="volume.valeursParametres[PARAM_VOLUME_VISITE]">
                    <mat-icon *ngSwitchCase="VOLUME_VISITE.OK" fontSet="material-icons-two-tone" class="success"
                        >lock_open
                    </mat-icon>
                    <mat-icon *ngSwitchCase="VOLUME_VISITE.KO" fontSet="material-icons-two-tone" class="error"
                        >lock
                    </mat-icon>
                    <mat-icon *ngSwitchCase="VOLUME_VISITE.WARNING" fontSet="material-icons-two-tone" class="justify"
                        >lock
                    </mat-icon>
                    <mat-icon *ngSwitchDefault>lock_open</mat-icon>
                </ng-container>
                <mat-icon *ngIf="isPlanInBien && !volume.spaceId && !volume.volumeCache" class="icon-small m-2"
                    >location_off</mat-icon
                >
                <div
                    class="pl-1"
                    [ngClass]="{
                        justify:
                            !volume.valeursParametres[PARAM_VOLUME_VISITE] ||
                            (volume.valeursParametres[PARAM_VOLUME_VISITE] &&
                                (volume.valeursParametres[PARAM_VOLUME_VISITE] === VOLUME_VISITE.KO ||
                                    volume.valeursParametres[PARAM_VOLUME_VISITE] === VOLUME_VISITE.WARNING) &&
                                !volume.justifie)
                    }"
                >
                    {{ volume.nom }}
                </div>
            </div>

            <div *ngIf="selectedVolume === volume && !volume.volumeCache">
                <button
                    class="w-50"
                    mat-icon-button
                    matTooltip="Descendre"
                    [disabled]="readonlyMode || index === currentNiveau.volumes.length - 1"
                    (click)="handleOrdreDescendreVolume($event, volume)"
                >
                    <mat-icon>expand_more</mat-icon>
                </button>
                <button
                    class="w-50"
                    mat-icon-button
                    matTooltip="Monter"
                    [disabled]="readonlyMode || index === 0"
                    (click)="handleOrdreMonterVolume($event, volume)"
                >
                    <mat-icon>expand_less</mat-icon>
                </button>
            </div>
        </div>
        <div
            class="w-100 px-3 pt-2"
            *ngIf="currentNiveau.volumes && currentNiveau.volumes.length === 1 && currentNiveau.volumes[0].volumeCache"
        >
            <p class="font-weight-bold m-0">Aucune pièce dans ce niveau</p>
            <p class="font-weight-light m-0">Cliquez sur les pièces selon le sens de visite</p>
        </div>
    </div>
</div>

<div class="d-flex flex-grow-0 flex-column w-100 p-2 align-items-center border-top">
    <div class="d-flex justify-content-end align-items-center w-100 py-1">
        <span class="mr-2">Sans description</span>
        <mat-slide-toggle
            [checked]="currentBien.sansDescription"
            (change)="handleSansDescriptionChange($event.checked)"
        ></mat-slide-toggle>
    </div>
</div>

<app-description-bien-fusion-volume
    [currentBien]="currentBien"
    [(currentVolume)]="selectedVolume"
></app-description-bien-fusion-volume>
