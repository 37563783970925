import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from './notification.component';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface NotificationOptions {
    message?: string;
    showCloseButton?: boolean;
    duration?: number;
    addTrailingDotIfMissing?: boolean;
}

@Injectable()
export class NotificationService {
    public defaultShowCloseButton = false;
    public defaultDuration = 5000;
    public defaultDurationWithAction = 15000;
    public defaultAddTrailingDotIfMissing = true;

    constructor(private snackBar: MatSnackBar) {}

    /**
     * Notification d'information.
     */
    notify(input: string | NotificationOptions) {
        const options = this.buildOptionsFromInput(input, this.defaultShowCloseButton, this.defaultDuration, this.defaultAddTrailingDotIfMissing);
        this.snackBar.openFromComponent(NotificationComponent, {
            duration: options.duration,
            data: {
                message: this.formatMessage(options.addTrailingDotIfMissing, options.message),
                icon: 'info',
                showClose: options.showCloseButton,
            },
            panelClass: 'notification-info',
        });
    }

    /**
     * Notification d'information, avec message et action.
     *
     * Un observable est renvoyé contenant 'true' si le message a été cliqué, ou 'false' sinon.
     * L'observable est terminé après que la valeur de retour ait été émise.
     */
    notifyWithAction(input: string | NotificationOptions, actionMessage: string): Observable<boolean> {
        const options = this.buildOptionsFromInput(
            input,
            this.defaultShowCloseButton,
            this.defaultDurationWithAction,
            this.defaultAddTrailingDotIfMissing
        );
        const snackBarRef = this.snackBar.openFromComponent(NotificationComponent, {
            duration: options.duration,
            data: {
                message: this.formatMessage(options.addTrailingDotIfMissing, options.message),
                actionMessage,
                icon: 'info',
                showClose: options.showCloseButton,
            },
            panelClass: 'notification-info',
        });
        const result = new BehaviorSubject<boolean>(null);
        snackBarRef.onAction().subscribe(() => {
            result.next(true);
            result.complete();
        });
        snackBarRef.afterDismissed().subscribe(() => {
            if (result.isStopped) return;
            result.next(false);
            result.complete();
        });
        return result;
    }

    /**
     * Notification de succès.
     */
    success(input: string | NotificationOptions) {
        const options = this.buildOptionsFromInput(input, this.defaultShowCloseButton, this.defaultDuration, this.defaultAddTrailingDotIfMissing);
        this.snackBar.openFromComponent(NotificationComponent, {
            duration: options.duration,
            data: {
                message: this.formatMessage(options.addTrailingDotIfMissing, options.message),
                icon: 'check_circle',
                showClose: options.showCloseButton,
            },
            panelClass: 'notification-success',
        });
    }

    /**
     * Notification de warning.
     */
    warn(input: string | NotificationOptions) {
        const options = this.buildOptionsFromInput(input, this.defaultShowCloseButton, this.defaultDuration, this.defaultAddTrailingDotIfMissing);
        this.snackBar.openFromComponent(NotificationComponent, {
            duration: options.duration,
            data: {
                message: this.formatMessage(options.addTrailingDotIfMissing, options.message),
                icon: 'warning',
                showClose: options.showCloseButton,
            },
            panelClass: 'notification-warn',
        });
    }

    /**
     * Notification d'erreur.
     */
    error(input: string | NotificationOptions) {
        const options = this.buildOptionsFromInput(input, true, 10000, this.defaultAddTrailingDotIfMissing);
        this.snackBar.openFromComponent(NotificationComponent, {
            duration: options.duration,
            data: {
                message: this.formatMessage(options.addTrailingDotIfMissing, options.message),
                icon: 'error',
                showClose: options.showCloseButton,
            },
            panelClass: 'notification-error',
        });
    }

    /**
     * Efface la notification.
     */
    dismiss() {
        this.snackBar.dismiss();
    }

    private buildOptionsFromInput(
        input: string | NotificationOptions,
        defaultShowCloseButton: boolean,
        defaultDuration: number,
        defaultAddTrailingDotIfMissing: boolean
    ): NotificationOptions {
        return typeof input === 'string'
            ? {
                  message: input,
                  showCloseButton: defaultShowCloseButton,
                  duration: defaultDuration,
                  addTrailingDotIfMissing: defaultAddTrailingDotIfMissing,
              }
            : {
                  message: input.message,
                  showCloseButton: input.showCloseButton ?? defaultShowCloseButton,
                  duration: input.duration ?? defaultDuration,
                  addTrailingDotIfMissing: input.addTrailingDotIfMissing ?? defaultAddTrailingDotIfMissing,
              };
    }

    private formatMessage(addTrailingDotIfMissing: boolean, message: string) {
        return addTrailingDotIfMissing ? this.addTrailingDotIfMissing(message.trim()) : message.trim();
    }

    private addTrailingDotIfMissing(messageTrimmed: string) {
        return messageTrimmed.endsWith('.') || messageTrimmed.endsWith('!') || messageTrimmed.endsWith('?') ? messageTrimmed : messageTrimmed + '.';
    }
}
