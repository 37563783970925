import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { PointDeControleVolume } from '../../../../../../model/point-de-controle.model';
import { MatDialog } from '@angular/material/dialog';
import { CalculatriceModaleComponent } from '../../../../../shared/calculatrice-modale/calculatrice-modale.component';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import {
    PARAM_TOOLTIP_CALCULTATRICE,
    PARAM_TOOLTIP_DEDUIRE,
    PARAM_TOOLTIP_REINITIALISER,
    PARAM_TOOLTIP_SET0,
} from '../../../../../../shared/constants/cndiag.constants';
import { MESURE_NON_COMMUNIQUE } from '../../../shared/mesurage.constants';
import { takeUntil } from 'rxjs/operators';
import { InterventionService } from '../../../../../../services/intervention.service';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { EtatProgressionService } from '../../../../../../services/etat-progression.service';

@Component({
    selector: 'app-section-mesure',
    templateUrl: './section-mesure.component.html',
    styleUrls: ['./section-mesure.component.scss'],
})
export class SectionMesureComponent extends BaseComponent implements OnInit {
    readonly MESURE_NON_COMMUNIQUE = MESURE_NON_COMMUNIQUE;

    @Input() volume: PointDeControleVolume;

    @Input()
    diagnostic: Diagnostic;

    @Input() mesure: string;

    @Input() label: string;

    /**
     * Mode readonly, en cas de diagnostic terminé
     */
    @Input()
    readonlyMode = false;

    @Input() isMesurageUtile = false;

    // Si true, on n'affiche pas les différents boutons et on ne peut pas cliquer sur l'input pour saisir une valeur
    @Input() isNonRepute = false;

    @Input() mesureSuggested = null;

    @Output() mesureChangedFromUser = new EventEmitter();

    @Output() mesureZone = new EventEmitter();

    @Output() calcMesure = new EventEmitter();

    mesureCss: string;

    tooltipsTools = [PARAM_TOOLTIP_DEDUIRE, PARAM_TOOLTIP_CALCULTATRICE, PARAM_TOOLTIP_REINITIALISER, PARAM_TOOLTIP_SET0];

    constructor(
        private matDialog: MatDialog,
        private interventionService: InterventionService,
        private diagnosticService: DiagnosticService,
        private etatProgressionService: EtatProgressionService
    ) {
        super();
    }

    ngOnInit(): void {
        this.mesureCss = this.mesure.replace(/_/g, '-');
        this.etatProgressionService.etatProgressions$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((diagn) => {
            this.readonlyMode = this.diagnosticService.isReadOnlyMode(
                this.interventionService.getCurrentInterventionValue(),
                this.diagnosticService.getCurrentDiagnosticValue()
            );
        });
    }

    /**
     * Mode saisie
     */
    openNumKeyboard() {
        return this.matDialog
            .open(CalculatriceModaleComponent, {
                data: {
                    mode: 'saisie',
                    originalValue: this.volume.valeursParametres[this.mesure],
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.volume.valeursParametres[this.mesure] = result.resultat;
                    this.valueChangedFromUser();
                }
            });
    }

    /**
     * Mode calculatrice
     */
    openCalculator() {
        return this.matDialog
            .open(CalculatriceModaleComponent, {
                data: {
                    mode: 'calculatrice',
                    originalValue: this.volume.valeursParametres[this.mesure],
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.volume.valeursParametres[this.mesure] = result.resultat;
                    this.valueChangedFromUser();
                }
            });
    }

    addValueSuggested() {
        this.volume.valeursParametres[this.mesure] = this.mesureSuggested;
        this.valueChangedFromUser();
    }

    /**
     * Réinitialisation du champs
     */
    deduireMesure() {
        this.calcMesure.emit();
    }

    valueChangedFromUser() {
        this.mesureChangedFromUser.emit();
    }

    setZero() {
        this.volume.valeursParametres[this.mesure] = '0';
        this.valueChangedFromUser();
    }
}
