import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { FormContainerComponent } from './form-container.component';

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [FormContainerComponent],
    exports: [FormContainerComponent],
})
export class LibFormContainerModule {}
