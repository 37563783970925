<mat-error *ngIf="control?.hasError('required') && control?.touched">
    {{ requiredMessage }}
</mat-error>
<mat-error *ngIf="control?.hasError('email') && control?.touched">
    {{ emailMessage }}
</mat-error>
<mat-error *ngIf="control?.hasError('pattern') && control?.touched">
    {{ patternMessage }}
</mat-error>
<mat-error *ngIf="control?.hasError('erreurNomPerimetreExistant')">
    Un autre périmètre possède déjà le même nom.
</mat-error>
<mat-error *ngIf="control?.hasError('minlength') && control?.hasError('maxlength') && control?.touched">
    Doit comporter entre {{ control.errors.minlength.requiredLength }} et
    {{ control.errors.maxlength.requiredLength }} caractères.
</mat-error>
<mat-error *ngIf="control?.hasError('minlength') && !control?.hasError('maxlength') && control?.touched">
    Doit comporter au moins {{ control.errors.minlength.requiredLength }} caractères.
</mat-error>
<mat-error *ngIf="!control?.hasError('minlength') && control?.hasError('maxlength') && control?.touched">
    Ne doit pas comporter plus de {{ control.errors.maxlength.requiredLength }} caractères.
</mat-error>
<mat-error *ngIf="control?.hasError('entitymustexist') && control?.touched">
    {{ control.errors.entitymustexist.message }}
</mat-error>
