import { Injectable } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { FormContext } from '../model/rule/form-context.model';
import { debounceTime, distinctUntilChanged, filter, finalize, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DataUtils } from '../utils/data.utils';
import { PageRequest } from '../shared/paging/page';
import { Agence } from '../model/agence.model';
import { AgenceApiService } from './agence-api.service';
import { BehaviorSubject } from 'rxjs';
import { CommandeApiService } from './commande-api.service';
import { Commande } from '../model/commande.model';
import { Bien } from '../model/bien.model';
import { BienApiService } from './bien-api.service';
import { ContactService } from './contact.service';
import { Contact } from '../model/contact.model';
import { ManagementApiService } from './management-api.service';
import { UserWizy } from '../model/user-wizy.model';
import { InterventionApiService } from './intervention-api.service';
import { Intervention } from '../model/intervention.model';

@Injectable({
    providedIn: 'root',
})
export class FormService {
    constructor() {}

    private pageRequest = {
        page: 0,
        size: 20,
        sort: {
            order: 'asc',
            property: 'nom',
        },
    };
    private isSearchingAgences: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private isSearchingCommandes: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private isSearchingBiens: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private isSearchingBiensAdresses: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private isSearchingContacts: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private isSearchingUsers: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private isSearchingEntreprises: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private isSearchingOperators: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private isSearchingTelephones: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private isSearchingEmails: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private isSearchingSirets: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private isSearchingInterventions: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public isSearchingAgences$ = this.isSearchingAgences.asObservable();
    public isSearchingCommandes$ = this.isSearchingCommandes.asObservable();
    public isSearchingBiens$ = this.isSearchingBiens.asObservable();
    public isSearchingBiensAdresses$ = this.isSearchingBiensAdresses.asObservable();
    public isSearchingContacts$ = this.isSearchingContacts.asObservable();
    public isSearchingUsers$ = this.isSearchingUsers.asObservable();
    public isSearchingEntreprises$ = this.isSearchingEntreprises.asObservable();
    public isSearchingOperators$ = this.isSearchingOperators.asObservable();
    public isSearchingTelephones$ = this.isSearchingTelephones.asObservable();
    public isSearchingEmails$ = this.isSearchingEmails.asObservable();
    public isSearchingSirets$ = this.isSearchingSirets.asObservable();
    public isSearchingInterventions$ = this.isSearchingInterventions.asObservable();

    /**
     * Recherche d'agence selon le nom
     * @param form
     * @param service
     */
    agenceSearchValueChange(form: AbstractControl, service: AgenceApiService) {
        return form.valueChanges.pipe(
            startWith(''),
            filter((value) => !!value),
            filter((value) => typeof value === 'string'),
            debounceTime(100),
            tap(() => this.isSearchingAgences.next(true)),
            distinctUntilChanged(),
            switchMap((search) => {
                this.pageRequest.sort.property = 'nom';
                return service
                    .findAllAgencesByName(search, this.pageRequest as PageRequest<Agence>)
                    .pipe(finalize(() => this.isSearchingAgences.next(false)));
            })
        );
    }

    /**
     * Recherche d'intervention selon le nom
     * @param form
     * @param service
     */
    interventionSearchValueChange(form: AbstractControl, service: InterventionApiService) {
        return form.valueChanges.pipe(
            startWith(''),
            filter((value) => !!value),
            filter((value) => typeof value === 'string'),
            debounceTime(100),
            tap(() => this.isSearchingInterventions.next(true)),
            distinctUntilChanged(),
            switchMap((search) => {
                this.pageRequest.sort.property = 'nom';
                return service
                    .findAllInterventionsAdminByName(search, this.pageRequest as PageRequest<Intervention>)
                    .pipe(finalize(() => this.isSearchingInterventions.next(false)));
            })
        );
    }

    /**
     * Création d'un FormControl associé à un data modèle et à un de ses attributs. Ce FormControl peut être
     * configurer, via l'objet de context pour mettre automatiquement à jour le modèle avec les données saisies
     * et pour s'initialiser à partir du data modèle.
     * @param id identifiant du champ du formulaire
     * @param context objet de type FormContext contenant les paramètres et données pour ce FormControl
     * @param disable Permet de rendre le formControl disable lors de sa création
     */
    createFormControl(id: string, context: FormContext, disable = false): FormControl {
        const formControl = new FormControl();

        if (disable) {
            formControl.disable();
        }

        if (context.validators) {
            formControl.setValidators(context.validators);
        }

        if (context.data) {
            const dataValue = DataUtils.getFieldValue(context.field, context.data);
            formControl.patchValue(dataValue);

            if (context.markAsTouched) {
                formControl.markAsTouched();
            }
        }

        if (context.autoUpdate) {
            formControl.valueChanges.pipe(takeUntil(context.ngUnsubscribe)).subscribe((value) => {
                DataUtils.setValue(context.field, context.data, value);
            });
        }

        return formControl;
    }

    /**
     * Recherche de commande selon le numéro de commande
     * @param form
     * @param service
     */
    commandeSearchValueChange(form: AbstractControl, service: CommandeApiService) {
        return form.valueChanges.pipe(
            startWith(''),
            filter((value) => !!value),
            filter((value) => typeof value === 'string'),
            debounceTime(100),
            tap(() => this.isSearchingCommandes.next(true)),
            distinctUntilChanged(),
            switchMap((search) => {
                this.pageRequest.sort.property = 'numeroCommande';
                return service
                    .findAllCommandesByNumber(search, this.pageRequest as PageRequest<Commande>)
                    .pipe(finalize(() => this.isSearchingCommandes.next(false)));
            })
        );
    }

    /**
     * Recherche de bien selon le nom
     * @param form
     * @param service
     */
    bienSearchValueChange(form: AbstractControl, service: BienApiService) {
        return form.valueChanges.pipe(
            startWith(''),
            filter((value) => !!value),
            filter((value) => typeof value === 'string'),
            debounceTime(100),
            tap(() => this.isSearchingBiens.next(true)),
            distinctUntilChanged(),
            switchMap((search) => {
                this.pageRequest.sort.property = 'nom';
                return service
                    .findAllBiensByName(search, this.pageRequest as PageRequest<Bien>)
                    .pipe(finalize(() => this.isSearchingBiens.next(false)));
            })
        );
    }

    /**
     * Recherche de bien selon le nom et l'adresse
     * @param form
     * @param service
     */
    bienSearchValueAdresseChange(form: AbstractControl, service: BienApiService) {
        return form.valueChanges.pipe(
            startWith(''),
            filter((value) => !!value),
            filter((value) => typeof value === 'string'),
            debounceTime(100),
            tap(() => this.isSearchingBiensAdresses.next(true)),
            distinctUntilChanged(),
            switchMap((search) => {
                this.pageRequest.sort.property = 'nom';
                return service
                    .findAllBiensByNameAndAdress(search, this.pageRequest as PageRequest<Bien>)
                    .pipe(finalize(() => this.isSearchingBiensAdresses.next(false)));
            })
        );
    }

    /**
     * Recherche d'agence selon le nom de contact
     * @param form
     * @param service
     */
    contactSearchValueChange(form: AbstractControl, service: ContactService) {
        return form.valueChanges.pipe(
            startWith(''),
            filter((value) => !!value),
            filter((value) => typeof value === 'string'),
            debounceTime(100),
            tap(() => this.isSearchingContacts.next(true)),
            distinctUntilChanged(),
            switchMap((search) => {
                this.pageRequest.sort.property = 'nom';
                return service
                    .findAllContactsByName(search, this.pageRequest as PageRequest<Contact>)
                    .pipe(finalize(() => this.isSearchingContacts.next(false)));
            })
        );
    }

    nomSearchValueChange(isPersonneMorale, form: AbstractControl, service: ContactService) {
        return isPersonneMorale
            ? this.entrepriseSearchValueChange(form, service)
            : this.contactSearchValueChange(form, service);
    }

    /**
     * Recherche de user selon le nom
     * @param form
     * @param service
     * @param isOperator
     */
    userSearchValueChange(form: AbstractControl, service: ManagementApiService, isOperator = false) {
        return form.valueChanges.pipe(
            startWith(''),
            filter((value) => !!value),
            filter((value) => typeof value === 'string'),
            debounceTime(100),
            tap(() => {
                this.isSearchingUsers.next(true);
                this.isSearchingOperators.next(true);
            }),
            distinctUntilChanged(),
            switchMap((search) => {
                this.pageRequest.sort.property = 'lastName';
                if (!isOperator) {
                    return service
                        .findAllUsersByName(search, this.pageRequest as PageRequest<UserWizy>)
                        .pipe(finalize(() => this.isSearchingUsers.next(false)));
                } else {
                    return service
                        .findAllOperatorsBySearchString(search, this.pageRequest as PageRequest<UserWizy>)
                        .pipe(finalize(() => this.isSearchingOperators.next(false)));
                }
            })
        );
    }

    /**
     * Recherche d'entreprise selon le nom
     * @param form
     * @param service
     */
    entrepriseSearchValueChange(form: AbstractControl, service: ContactService) {
        return form.valueChanges.pipe(
            startWith(''),
            filter((value) => !!value),
            filter((value) => typeof value === 'string'),
            debounceTime(100),
            distinctUntilChanged(),
            tap(() => this.isSearchingEntreprises.next(true)),
            switchMap((search) => {
                this.pageRequest.sort.property = 'nom';
                return service
                    .findAllEntreprisesByName(search, this.pageRequest as PageRequest<Contact>)
                    .pipe(finalize(() => this.isSearchingEntreprises.next(false)));
            })
        );
    }

    /**
     * Recherche de contact selon le téléphone
     * @param form
     * @param service
     */
    telephoneSearchValueChange(form: AbstractControl, service: ContactService) {
        return form.valueChanges.pipe(
            startWith(''),
            filter((value) => !!value),
            filter((value) => typeof value === 'string'),
            debounceTime(100),
            distinctUntilChanged(),
            tap(() => this.isSearchingTelephones.next(true)),
            switchMap((search) => {
                this.pageRequest.sort.property = 'telephone';
                return service
                    .findAllContactsByPhone(search, this.pageRequest as PageRequest<Contact>)
                    .pipe(finalize(() => this.isSearchingTelephones.next(false)));
            })
        );
    }

    /**
     * Recherche de contact selon l'email
     * @param form
     * @param service
     */
    emailSearchValueChange(form: AbstractControl, service: ContactService) {
        return form.valueChanges.pipe(
            startWith(''),
            filter((value) => !!value),
            filter((value) => typeof value === 'string'),
            debounceTime(100),
            distinctUntilChanged(),
            tap(() => this.isSearchingEmails.next(true)),
            switchMap((search) => {
                this.pageRequest.sort.property = 'email';
                return service
                    .findAllContactsByEmail(search, this.pageRequest as PageRequest<Contact>)
                    .pipe(finalize(() => this.isSearchingEmails.next(false)));
            })
        );
    }

    /**
     * Recherche de contact selon le numéro de siret
     * @param form
     * @param service
     */
    siretSearchValueChange(form: AbstractControl, service: ContactService) {
        return form.valueChanges.pipe(
            startWith(''),
            filter((value) => !!value),
            filter((value) => typeof value === 'string'),
            debounceTime(100),
            distinctUntilChanged(),
            tap(() => this.isSearchingSirets.next(true)),
            switchMap((search) => {
                this.pageRequest.sort.property = 'siret';
                return service
                    .findAllContactsBySiret(search, this.pageRequest as PageRequest<Contact>)
                    .pipe(finalize(() => this.isSearchingSirets.next(false)));
            })
        );
    }
}
