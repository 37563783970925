import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/commons-lib';
import { URL_GESTION_PRESTATIONS_EDIT, URL_REFERENTIELS } from 'src/app/shared/constants/url.constants';

@Component({
    selector: 'app-gestion-diagnotics',
    templateUrl: './gestion-diagnotics.component.html',
    styleUrls: ['./gestion-diagnotics.component.scss'],
})
export class GestionDiagnoticsComponent extends BaseComponent implements OnInit {
    constructor(private readonly router: Router) {
        super();
    }

    ngOnInit(): void {}

    creerPrestationDiagnostic() {
        this.router.navigate([URL_GESTION_PRESTATIONS_EDIT]);
    }

    back() {
        this.router.navigate([URL_REFERENTIELS]);
    }
}
