<div class="d-flex flex-column h-100 scrollable-container">
    <div class="toolbar">
        <mat-toolbar class="border-bottom-1">
            <button mat-icon-button color="grey" [routerLink]="URL_TABLEAU_DE_BORD">
                <lib-icon>dashboard</lib-icon>
            </button>
            <lib-icon>chevron_right</lib-icon>
            <div>
                <lib-icon [class]="'icon-before-text'">date_range</lib-icon>
                <span>Agenda</span>
            </div>
            <div class="flex-grow-1"></div>
            <div>
                <lib-icon [class]="'icon-before-text warn'">cloud_off</lib-icon>
                <span>User</span>
            </div>
        </mat-toolbar>
    </div>

    <div class="d-flex flex-row w-100 main-background align-items-center justify-content-between p-2">
        <div class="d-flex flex-row align-items-center">
            <button
                mat-icon-button
                mwlCalendarPreviousView
                [view]="view"
                [(viewDate)]="viewDate"
                (viewDateChange)="closeOpenMonthViewDay()"
            >
                <lib-icon [class]="'primary icon-large'">chevron_left</lib-icon>
            </button>
            <button mat-button class="primary" mwlCalendarToday [(viewDate)]="viewDate">
                {{ "Aujourd'hui" | uppercase }}
            </button>
            <button
                mat-icon-button
                mwlCalendarNextView
                [view]="view"
                [(viewDate)]="viewDate"
                (viewDateChange)="closeOpenMonthViewDay()"
            >
                <lib-icon [class]="'primary icon-large'">chevron_right</lib-icon>
            </button>

            <div class="ml-4 bold primary">{{ viewDate | calendarDate: view + 'ViewTitle':locale }}</div>
        </div>
        <mat-button-toggle-group [(value)]="view" appearance="legacy">
            <mat-button-toggle value="day">{{ 'Jour' | uppercase }}</mat-button-toggle>
            <mat-button-toggle value="week">{{ 'Semaine' | uppercase }}</mat-button-toggle>
            <mat-button-toggle value="month">{{ 'Mois' | uppercase }}</mat-button-toggle>
            <mat-button-toggle value="liste">{{ 'Liste' | uppercase }}</mat-button-toggle>
        </mat-button-toggle-group>
    </div>

    <div class="flex-grow-1 main-background scrollable-content">
        <mat-sidenav-container class="sidenav-container" [autosize]="true">
            <!-- Partie gauche : agenda -->
            <mat-sidenav-content class="main">
                <div class="p-4">
                    <div [ngSwitch]="view">
                        <mwl-calendar-month-view
                            *ngSwitchCase="CalendarView.Month"
                            [excludeDays]="[0, 6]"
                            [weekStartsOn]="1"
                            [locale]="locale"
                            [viewDate]="viewDate"
                            [events]="events"
                            [refresh]="refresh"
                            [cellTemplate]="customMonthCellTemplate"
                            [activeDayIsOpen]="activeDayIsOpen"
                            (dayClicked)="dayClicked($event.day)"
                            (eventClicked)="handleEvent('Clicked', $event.event)"
                            (eventTimesChanged)="eventTimesChanged($event)"
                        >
                        </mwl-calendar-month-view>

                        <mwl-calendar-week-view
                            *ngSwitchCase="CalendarView.Week"
                            [excludeDays]="[0, 6]"
                            [weekStartsOn]="1"
                            [dayStartHour]="7"
                            [dayEndHour]="19"
                            [locale]="locale"
                            [viewDate]="viewDate"
                            [events]="events"
                            [eventTemplate]="customWeekCellTemplate"
                            [refresh]="refresh"
                            (eventClicked)="handleEvent('Clicked', $event.event)"
                            (eventTimesChanged)="eventTimesChanged($event)"
                        >
                        </mwl-calendar-week-view>

                        <mwl-calendar-day-view
                            *ngSwitchCase="CalendarView.Day"
                            [dayStartHour]="7"
                            [dayEndHour]="19"
                            [locale]="locale"
                            [viewDate]="viewDate"
                            [events]="events"
                            [eventTitleTemplate]="customDayCellTemplate"
                            [refresh]="refresh"
                            (eventClicked)="handleEvent('Clicked', $event.event)"
                            (eventTimesChanged)="eventTimesChanged($event)"
                        >
                        </mwl-calendar-day-view>
                        <div *ngSwitchCase="'liste'">Le mode liste</div>
                    </div>
                </div>
            </mat-sidenav-content>

            <!-- Partie droite : liste des taches, ... -->
            <mat-sidenav mode="side" opened="true" class="mat-elevation-z4 sidebar p-2 shadow-none" position="end">
                <div class="d-flex flex-row h-100">
                    <mat-nav-list class="d-flex flex-column px-0">
                        <a
                            [ngClass]="showLots ? 'accent' : ''"
                            mat-list-item
                            (click)="afficherLots()"
                            title="Afficher lots"
                        >
                            <lib-icon matListIcon>assignment</lib-icon>
                        </a>
                        <a
                            [ngClass]="showTodos ? 'accent' : ''"
                            mat-list-item
                            (click)="afficherTodos()"
                            title="Afficher todos"
                        >
                            <lib-icon matListIcon theme="mdi" iconName="order-bool-descending"></lib-icon>
                        </a>
                    </mat-nav-list>

                    <div *ngIf="showLots || showTodos" class="d-flex flex-column h-100 border-left">
                        <div *ngIf="showLots">
                            <div class="d-flex flex-row">
                                <div class="mat-h2 primary p-2 mb-0 flex-grow-1">
                                    {{ "Lots d'intervention" | uppercase }}
                                </div>
                                <button mat-icon-button (click)="showLots = !showLots">
                                    <lib-icon>close</lib-icon>
                                </button>
                            </div>
                            <div>la liste</div>
                        </div>

                        <div *ngIf="showTodos">
                            <div class="d-flex flex-row">
                                <div class="mat-h2 primary p-2 mb-0 flex-grow-1">
                                    {{ 'Tâches' | uppercase }}
                                </div>
                                <button mat-icon-button (click)="showTodos = !showTodos">
                                    <lib-icon>close</lib-icon>
                                </button>
                            </div>
                            <div *ngFor="let todo of todos" class="p-2 d-flex w-100 border-top">
                                <div
                                    [ngClass]="todo.delay ? 'todo-retard' : ''"
                                    class="d-flex flex-row align-items-center w-100"
                                >
                                    <mat-checkbox [checked]="todo.done"></mat-checkbox>
                                    <div class="ml-2 flex-grow-1">
                                        <div>
                                            {{ todo.nom }}
                                        </div>
                                        <div>
                                            {{ todo.description }}
                                        </div>
                                        <div *ngIf="todo.delay">{{ todo.date }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-sidenav>
        </mat-sidenav-container>
    </div>
</div>

<!--Template mois-->
<ng-template #customMonthCellTemplate let-day="day" let-locale="locale">
    <div class="cal-cell-top">
        <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
        <span class="cal-day-number">{{ day.date | calendarDate: 'monthViewDayNumber':locale }}</span>
    </div>
    <ng-container *ngFor="let event of day.events">
        <div *ngIf="event.intervention" class="month-event mb-2">
            <div class="bold">
                {{ event.intervention.nom }}
            </div>
            <div>{{ event.start | date: 'shortTime' }} à {{ event.end | date: 'shortTime' }}</div>
            <div class="d-flex align-items-center">
                <lib-icon [class]="'primary'" class="icon-small align-bottom">place</lib-icon>
                {{ event.intervention.adresse.voie }}
                , {{ event.intervention.adresse.codePostal }} {{ event.intervention.adresse.ville }}
            </div>
        </div>
    </ng-container>
    <button *ngIf="day.events.length == 3" mat-raised-button color="black">
        + {{ day.events.length - 2 }}événement
    </button>
    <button *ngIf="day.events.length > 3" mat-raised-button color="black">
        + {{ day.events.length - 2 }}événements
    </button>
</ng-template>

<!--Template jour-->
<ng-template #customDayCellTemplate let-event="event">
    <div>{{ event.start | date: 'shortTime' }} à {{ event.end | date: 'shortTime' }}</div>
    <div *ngIf="event.intervention">
        <div class="cal-cell-top bold">
            {{ event.intervention.nom }}
        </div>
        <div class="d-flex align-items-center">
            <lib-icon [class]="'primary'" class="icon-small align-bottom">place</lib-icon>
            {{ event.intervention.adresse.voie }}
            , {{ event.intervention.adresse.codePostal }} {{ event.intervention.adresse.ville }}
        </div>
    </div>
</ng-template>

<!--Template semaine-->
<ng-template #customWeekCellTemplate let-weekEvent="weekEvent">
    <div class="cal-event">
        <div>{{ weekEvent.event.start | date: 'shortTime' }} à {{ weekEvent.event.end | date: 'shortTime' }}</div>
        <div *ngIf="weekEvent.event.intervention">
            <div class="cal-cell-top bold">
                {{ weekEvent.event.intervention.nom }}
            </div>
            <div class="d-flex align-items-center">
                <lib-icon [class]="'primary'" class="icon-small align-bottom">place</lib-icon>
                {{ weekEvent.event.intervention.adresse.ville }}
            </div>
        </div>
    </div>
</ng-template>
