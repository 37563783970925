<app-mpca-form-skeleton>
    <div title>Prélèvement MEST</div>
    <div form class="form" [formGroup]="formGeneral">
        <div class="column">
            <div class="section">
                <div class="header">Informations client</div>
                <div class="cndiad-form-field">
                    <mat-label class="required">Fait par le client</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="faitParLeClient"
                        [disabled]="disabled"
                    >
                        <option value="true" datatype="icon">check</option>
                        <option value="false" datatype="icon">close</option>
                    </app-widget-select>
                </div>
            </div>
            <div class="section">
                <div class="header">Informations concernant le prélèvement</div>

                <!-- LOCALISATION DU PRELEVEMENT -->
                <div class="cndiad-form-field">
                    <mat-label class="required">Localisation du prélèvement</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="localisationPrelevement"
                        [disabled]="disabled"
                    >
                        <option value="personnel" datatype="label">Personnel</option>
                        <option value="materiel" datatype="label">Matériel</option>
                        <option value="uniteMobile" datatype="label">Unité mobile</option>
                    </app-widget-select>
                </div>

                <!-- CIRCONSTANCE -->
                <div class="cndiad-form-field">
                    <mat-label class="required">Localisation du prélèvement</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="circonstance"
                        [disabled]="disabled"
                    >
                        <option value="avant" datatype="label">Avant</option>
                        <option value="apres" datatype="label">Après</option>
                    </app-widget-select>
                </div>

                <!-- DATE DU PRELEVEMENT-->
                <mat-form-field class="ml-3">
                    <mat-label>Date du prélèvement</mat-label>
                    <input
                        matInput
                        readonly
                        formControlName="dateTimePrelevement"
                        [ngxMatDatetimePicker]="datepickerDebut"
                    />
                    <mat-datepicker-toggle matSuffix [for]="datepickerDebut"> </mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #datepickerDebut [showSeconds]="false"></ngx-mat-datetime-picker>
                </mat-form-field>

                <!-- REMARQUES  -->
                <mat-form-field appearance="outline">
                    <mat-label>Remarques: </mat-label>
                    <input matInput formControlName="remarques" [readonly]="disabled" />
                </mat-form-field>
            </div>
        </div>
        <div class="column">
            <div class="section">
                <div class="header">Conformité</div>

                <!-- ACCREDITATION COFRAC -->
                <div class="cndiad-form-field">
                    <mat-label class="required">Accréditation cofrac</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="accreditationCofrac"
                        [disabled]="disabled"
                    >
                        <option value="true" datatype="icon">check</option>
                        <option value="false" datatype="icon">close</option>
                    </app-widget-select>
                </div>
            </div>

            <button mat-button class="bt bouton-impression" (click)="impressionEtiquette()">
                <lib-icon>printer-wireless</lib-icon>
                {{ "Imprimer l'étiquette" }}
            </button>
        </div>
    </div>
</app-mpca-form-skeleton>
