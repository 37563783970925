import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'app-double-mesure-dialog',
    templateUrl: './double-mesure-dialog.component.html',
    styleUrls: ['./double-mesure-dialog.component.scss'],
})
export class DoubleMesureDialogComponent {
    surface: number;
    form: FormGroup;
    automatique: boolean = false;
    constructor(
        public dialogRef: MatDialogRef<DoubleMesureDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder
    ) {
        this.dialogRef.disableClose = true;
        this.form = this.formBuilder.group({
            mesure1: [null /*, [Validators.required, Validators.min(0), this.decimalNumberValidator()]*/],
            mesure2: [null /*, [Validators.required, Validators.min(0), this.decimalNumberValidator()]*/],
        });
    }
    onCancelClick(): void {
        this.dialogRef.close({ confirmed: false, surface: null });
    }

    onConfirmClick(): void {
        if (this.form.valid) {
            this.dialogRef.close({
                confirmed: true,
                mesure1: this.form.get('mesure1').value,
                mesure2: this.form.get('mesure2').value,
                selectedOption: this.data.selectedOption,
                automatique: this.automatique,
            });
        }
        // else : Gérer l'affichage d'un message d'erreur ou autre action en cas de validation invalide.
    }

    decimalNumberValidator() {
        return (control) => {
            const pattern = /^[0-9]+(\.[0-9]+)?$/;
            if (control.value && !pattern.test(control.value)) {
                return { invalidNumber: true };
            }
            return null;
        };
    }

    onChangeAutoVal($event: MatCheckboxChange, mesure?: string) {
        this.automatique = $event.checked;
        if (this.automatique) {
            this.form.get('mesure1').setValue(this.data.dimension.val1);
            this.form.get('mesure2').setValue(this.data.dimension.val2);
        } else {
            this.form.get('mesure1').setValue('');
            this.form.get('mesure2').setValue('');
        }
    }
}
