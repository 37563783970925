import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import {
    CategorieOuvrage,
    Couleur,
    OuvrageAControler,
    Revetement,
    Substrat,
    TypeCaracteristiqueCouleur,
    TypeParametreCategorieOuvrage,
} from '../../../../../model/categorie-ouvrage.model';
import { combineLatest, of } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { CnSpinnerService } from '../../../../shared/cn-spinner/service/cn-spinner.service';
import { ReferenceService } from '../../../../../services/reference.service';
import { FormControl, FormGroup } from '@angular/forms';
import { KeyValue } from '@angular/common';

enum Boussole {
    NO = 'NO',
    N = 'N',
    NE = 'NE',
    O = 'O',
    ICONE = 'ICONE',
    E = 'E',
    SO = 'SO',
    S = 'S',
    SE = 'SE',
}

@Component({
    selector: 'app-description-bien-modal-ouvrages-details',
    templateUrl: './description-bien-modal-ouvrages-details.component.html',
    styleUrls: ['./description-bien-modal-ouvrages-details.component.scss'],
})
export class DescriptionBienModalOuvragesDetailsComponent extends BaseComponent implements OnInit {
    @Input()
    readonlyMode: boolean = false;

    @Input() set ouvrageToEdit(newOuvrage: OuvrageAControler) {
        this._ouvrageToEdit = newOuvrage;
        this.refreshData();
    }

    @Input()
    listOuvragesCurrentVolume: OuvrageAControler[];

    @Output()
    ouvrageToEditChange: EventEmitter<OuvrageAControler> = new EventEmitter<OuvrageAControler>();

    get ouvrageToEdit(): OuvrageAControler {
        return this._ouvrageToEdit;
    }

    private _ouvrageToEdit: OuvrageAControler;

    BOUSSOLE = Boussole;

    form: FormGroup;

    TypeParametreCategorieOuvrage = TypeParametreCategorieOuvrage;

    mapCategoriesOuvrages: Map<string, CategorieOuvrage>;

    listRevetements: Revetement[];
    listSubstrats: Substrat[];
    mapCouleurs: Map<string, Couleur> = new Map<string, Couleur>();
    listCaracteristiquesCouleurs: any[];

    originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
        return 0;
    };

    constructor(private readonly cnSpinnerService: CnSpinnerService, private readonly referenceService: ReferenceService) {
        super();
    }

    ngOnInit() {
        this.refreshData();
        this.refreshListsRevetements();
    }

    handleClickBoussole(boussoleElementKey) {
        this.updateOuvrage(TypeParametreCategorieOuvrage.LOCALISATION_BOUSSOLE, boussoleElementKey.value);
    }

    handleSelectionRevetementChange() {
        this.ouvrageToEdit.nomRevetement = this.listRevetements.find((it) => it.code === this.ouvrageToEdit.codeRevetement)?.nom;
        this.ouvrageToEdit.partiesOuvrages.forEach((it) => {
            if (!it.codeRevetement) {
                it.codeRevetement = this.ouvrageToEdit.codeRevetement;
            }
            if (!it.nomRevetement) {
                it.nomRevetement = this.ouvrageToEdit.nomRevetement;
            }
        });
    }

    handleSelectionSubstratChange() {
        this.ouvrageToEdit.nomSubstrat = this.listSubstrats.find((it) => it.code === this.ouvrageToEdit.codeSubstrat)?.nom;
        this.ouvrageToEdit.partiesOuvrages.forEach((it) => {
            if (!it.codeSubstrat) {
                it.codeSubstrat = this.ouvrageToEdit.codeSubstrat;
            }
            if (!it.nomSubstrat) {
                it.nomSubstrat = this.ouvrageToEdit.nomSubstrat;
            }
        });
    }

    handleSelectionCouleurChange() {
        this.ouvrageToEdit.nomCouleur = this.mapCouleurs.get(this.ouvrageToEdit.valeurCouleur)?.nom;
        this.ouvrageToEdit.partiesOuvrages.forEach((it) => {
            if (!it.valeurCouleur) {
                it.valeurCouleur = this.ouvrageToEdit.valeurCouleur;
            }
            if (!it.nomCouleur) {
                it.nomCouleur = this.ouvrageToEdit.nomCouleur;
            }
        });
    }

    handleSelectionCaracteristiqueCouleurChange() {
        this.ouvrageToEdit.partiesOuvrages.forEach((it) => {
            if (!it.valeurCaracteristiqueCouleur) {
                it.valeurCaracteristiqueCouleur = this.ouvrageToEdit.valeurCaracteristiqueCouleur;
            }
        });
    }

    private refreshData() {
        this.cnSpinnerService
            .withSpinner(
                combineLatest([this.referenceService.findAllCategoriesOuvrages()]).pipe(
                    takeUntil(this.ngUnsubscribe),
                    switchMap(([categoriesOuvrages]) => {
                        return combineLatest([of(categoriesOuvrages)]);
                    })
                )
            )
            .subscribe(([categoriesOuvrages]) => {
                this.mapCategoriesOuvrages = new Map<string, CategorieOuvrage>();
                categoriesOuvrages.forEach((it) => {
                    this.mapCategoriesOuvrages.set(it.code, it);
                });
                this.createForm();
                this.refreshListsRevetements();
            });
    }

    private createForm() {
        const group: any = {};
        if (!this.ouvrageToEdit.parametres) {
            this.ouvrageToEdit.parametres = {};
        }
        this.mapCategoriesOuvrages.get(this.ouvrageToEdit.codeCategorieOuvrage).parametres.forEach((it) => {
            this.ouvrageToEdit.parametres[it.code] = this.ouvrageToEdit.parametres[it.code] ?? null;
            group[it.code] = new FormControl(this.ouvrageToEdit.parametres[it.code]);
        });
        this.form = new FormGroup(group);
        this.mapCategoriesOuvrages.get(this.ouvrageToEdit.codeCategorieOuvrage).parametres.forEach((it) => {
            this.form
                .get(it.code)
                .valueChanges.pipe(
                    tap((newValue) => {
                        this.updateOuvrage(it.code, newValue);
                    }),
                    takeUntil(this.ngUnsubscribe)
                )
                .subscribe();
        });
    }

    private updateOuvrage(codeValue: string, value: any) {
        this.ouvrageToEdit.parametres[codeValue] = value;
        this.ouvrageToEditChange.emit(this.ouvrageToEdit);
    }

    private refreshListsRevetements() {
        if (this.ouvrageToEdit) {
            const currentCategorie = this.mapCategoriesOuvrages.get(this.ouvrageToEdit.codeCategorieOuvrage);
            this.listRevetements = currentCategorie.revetements;
            this.listSubstrats = currentCategorie.substrats;
            currentCategorie.couleurs.forEach((it) => {
                this.mapCouleurs.set(it.valeur, it);
            });
            this.listCaracteristiquesCouleurs = Object.keys(TypeCaracteristiqueCouleur).map((it) => {
                return {
                    nom: TypeCaracteristiqueCouleur[it],
                    valeur: it,
                };
            });
        }
    }
}
