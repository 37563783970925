import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-parties-communes-et-privatives',
    templateUrl: './parties-communes-et-privatives.component.html',
    styleUrls: ['./parties-communes-et-privatives.component.scss'],
})
export class PartiesCommunesEtPrivativesComponent implements OnInit {
    readonly MENU_NAME = 'Parties communes et privatives';

    constructor() {}

    ngOnInit(): void {}
}
