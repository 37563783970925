import { Pipe, PipeTransform } from '@angular/core';

/**
 * Rupture intelligente des lignes dans les chaînes contenant de longues chaînes contaténées.
 * - Remplacement des undescores par des espaces
 * - Rupture possible après les deux-points (:)
 */
@Pipe({
    name: 'smartWordBreak',
})
export class SmartWordBreakPipe implements PipeTransform {
    transform(value: string): any {
        if (!value) return '';
        return value.replace(/_/g, ' ').replace(/:/g, ':\u200B');
    }
}
