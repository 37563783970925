import { ITypePrestationInterface } from '../../../../services/interfaces/type-prestation.interface';
import { TypePrestation, TypePrestationEnum } from '../../../../model/type-prestation.model';
import { Injectable, Type } from '@angular/core';
import { ContenuDiagnostic } from '../../../../model/diagnostic-contenu.model';
import { Anomalie, CeeModel, CEEReportData, IAnomalie, IReportPC, ISolution, PointControle } from '../model/cee.model';
import { Route } from '@angular/router';
import { ceeNavBarRoutes } from '../cee-routing.module';
import { CommentairePredefini } from '../../../../model/commentaire-predefini.model';
import { Commentaire } from '../../../../model/commentaire.model';
import { Diagnostic } from '../../../../model/diagnostic.model';
import { Intervention } from '../../../../model/intervention.model';
import { IDiagReportData, InterventionReportData } from '../../../../model/rapport.model';
import { Rule } from '../../../../model/regle.model';
import { Observable, of } from 'rxjs';
import { BonCommandeAnalyseAdmin } from '../../../../model/bon-commande.model';
import { TypeReport } from '../../../../model/reference-prestation.model';
import { CeeService } from './cee.service';
import { URL_MS_REPORT_FILE } from '../../../../shared/constants/cndiag.constants';
import {
    DIFFERENT,
    EGAL,
    INFERIEUR,
    INFERIEUR_EGAL,
    LONGUEURS_RESEAU_ISOLEES,
    nonOption,
    nonVisibleOption,
    ouiOption,
    PERFORMANCE_THERMIQUE,
    POINTS_SINGULIERS,
    sansObjetOption,
    SUPPERIEUR,
    SUPPERIEUR_EGAL,
    SURFACE_ISOLEE,
} from '../shared/cee.constants';
import { first } from 'rxjs/operators';
import { ReportCeeBlockComponent } from '../modules/report/blocks/report-cee-block/report-cee-block.component';
import { EtapeDiagnosticGenerique } from '../../../../model/diagnostic-etape.model';
import { CommentUtils } from '../../../../utils/comment.utils';
import { ReportagePhotoService } from '../../../../services/reportage-photo.service';
import { LegendScreenshot } from '../../../../model/screenshot-svg.model';
import { cn_storey } from '@acenv/cnmap-editor';
import { Bien, Niveau, Volume } from '../../../../model/bien.model';
import { PointDeControleService } from '../../../../services/point-de-controle.service';
import { ReportBarth160Component } from '../modules/report/blocks/report-barth160-block/report-barth160.component';
import { InterventionFile, TypeReferenceFichier } from 'src/app/model/intervention-file.model';
import { InterventionFileService } from 'src/app/services/intervention-file.service';
import { DocumentsService } from 'src/app/services/documents.service';
import { forEach } from 'cypress/types/lodash';
import { TypeVolume } from '../../../../model/type-element-a-controler.model';

export const REPORT_BLOCK_CATALOG: { [key: string]: Type<any> } = {
    ReportCeeBlockComponent: ReportCeeBlockComponent,
    ReportBarth160Component: ReportBarth160Component,
};

@Injectable({
    providedIn: 'root',
})
export class TypeCEESerice implements ITypePrestationInterface {
    protected typePrestation: TypePrestation = null;
    protected sommeNbPointSingulierDeclares: number = 0;
    protected temperatureMaximalDeServiceDeclarees: number = 0;
    protected resistanceThermiqueA50C: number = 0;
    protected resistanceThermiqueA100C: number = 0;

    constructor(
        private readonly ceeService: CeeService,
        private readonly reportagePhotoService: ReportagePhotoService,
        private readonly pointDeControleService: PointDeControleService,
        private readonly interventionFileService: InterventionFileService,
        private readonly documentsService: DocumentsService
    ) {}

    getTypePrestation(): TypePrestation {
        return this.typePrestation;
    }

    getRoutes(): Route[] {
        return ceeNavBarRoutes;
    }

    getContenuDiagnosticFromParent(diagnosticToUpdate: Diagnostic, diagnosticParent: Diagnostic): ContenuDiagnostic {
        return { ...diagnosticParent.contenuDiagnostic };
    }

    getContenuDiagnostic(typePrestation: TypePrestation): Observable<ContenuDiagnostic> {
        return of(new CeeModel());
    }

    getCodeBimEquipementBien(typePrestation: TypePrestation): string[] {
        return undefined;
    }

    getCompletionPercentage(diagnostic: Diagnostic): number {
        let total: number = 0;
        let totalValide: number = 0;
        const ceeModel = this.ceeService.mapToCeeModel(diagnostic.contenuDiagnostic);
        if (ceeModel) {
            for (const solution of ceeModel.solutions.solutions) {
                for (const zone of Object.getOwnPropertyNames(solution.pointsDeControles.data)) {
                    total += solution.pointsDeControles.data[zone].filter((ptc) => !ptc.hide && this.getPointDeControle(ptc.id).required).length;
                    totalValide += solution.pointsDeControles.data[zone].filter((ptc) => this.ceeService.isCheckPointValid(ptc, ceeModel)).length;
                }
            }
        }
        return (totalValide * 100) / total;
    }

    getDiagnosticBonCommandeData(intervention: Intervention, diagnostic: Diagnostic): IDiagReportData {
        return undefined;
    }

    getDiagnosticReportData(intervention: Intervention, diagnostic: Diagnostic, rules?: Rule[], optionPlan?: boolean): IDiagReportData {
        const diagReportData = new CEEReportData();
        diagReportData.revision = null;
        intervention.prestationsDiagnostics.forEach((prestadiag) => {
            if (prestadiag.idDiagnostic === diagnostic.id) {
                if (prestadiag.revision) {
                    diagReportData.revision = {
                        idInterventionRevisee: prestadiag.revision.idInterventionRevisee,
                        referenceInterventionRevisee: prestadiag.revision.referenceInterventionRevisee,
                        idPrestationDiagRevisee: prestadiag.revision.idPrestationDiagRevisee,
                        referenceRapportRevise: prestadiag.revision.referenceRapportRevise,
                        incrementRevision: prestadiag.revision.incrementRevision,
                        motifRevision: prestadiag.revision.motifRevision,
                        oldsRevisions: prestadiag.revision.oldsRevisions,
                    };
                }
            }
        });
        diagReportData.id = diagnostic.id;
        diagReportData.typePrestation = diagnostic.typePrestation;
        diagReportData.refRapport = diagnostic.reportDatas.find((reportDataTemp) => reportDataTemp.typeReport === TypeReport.REPORT).refRapport;

        if (diagnostic.recommandationsFinales.length) {
            diagReportData.recommandations = diagnostic.recommandationsFinales.map((it) => it.contenu);
        }
        if (diagnostic.constatationsFinales.length) {
            diagReportData.constatationsDiverses = diagnostic.constatationsFinales.map((it) => it.contenu);
        }
        diagReportData.reportagesPhotos = this.reportagePhotoService.buildReportagePhotoData(diagnostic);

        const ceeModel: CeeModel = new CeeModel(diagnostic.contenuDiagnostic as CeeModel);
        // Ecran Général
        for (const property of ['nomEntreprise', 'numeroSIRET', 'dateAcceptationDevis']) {
            ceeModel[property].libelle = ceeModel.getLabel(property);
            const step: any = ceeModel[property];
            step.estAnomalie = this.getAnomalieFromEtapeGenerale(ceeModel[property]);
            step.commentaires = [];
            for (const commentaireId of ceeModel[property].commentairesId) {
                step.commentaires.push(CommentUtils.getCommentaire(commentaireId, intervention.commentaires));
            }
            diagReportData.etapesGenerales.push(property === 'dateAcceptationDevis' ? this.convertDateToString(step) : step);
        }

        if (![TypePrestationEnum.BARTH160, TypePrestationEnum.BARTH161].includes(this.typePrestation)) {
            for (const property of ['entrepriseRGE', 'dateVisitePrealable']) {
                ceeModel[property].libelle = ceeModel.getLabel(property);
                const step: any = ceeModel[property];
                step.estAnomalie = this.getAnomalieFromEtapeGenerale(ceeModel[property]);
                step.commentaires = [];
                for (const commentaireId of ceeModel[property].commentairesId) {
                    step.commentaires.push(CommentUtils.getCommentaire(commentaireId, intervention.commentaires));
                }
                diagReportData.etapesGenerales.push(property === 'dateVisitePrealable' ? this.convertDateToString(step) : step);
            }
        }
        // conformitereglementaire
        diagReportData.conformitereglementaire = [];
        for (const property of ['presenceDevis', 'presenceFacture']) {
            ceeModel[property].libelle = ceeModel.getLabel(property);
            const step: any = ceeModel[property];
            step.estAnomalie = this.getAnomalieFromEtapeGenerale(ceeModel[property]);
            step.commentaires = [];
            for (const commentaireId of ceeModel[property].commentairesId) {
                step.commentaires.push(CommentUtils.getCommentaire(commentaireId, intervention.commentaires));
            }
            diagReportData.conformitereglementaire.push(step);
        }

        ceeModel.zoneClimatique.libelle = ceeModel.getLabel('zoneClimatique'); //'Zone climatique';
        let zoneClimatique: any = ceeModel.zoneClimatique;
        zoneClimatique.valeur = this.calculerZoneClimatique(intervention.adresseBienPrincipal.codePostal.substring(0, 2));
        zoneClimatique.estAnomalie = false;
        zoneClimatique.commentaires = [];
        diagReportData.etapesGenerales.push(zoneClimatique);

        diagReportData.longuerReseauxIsoles = +ceeModel.longuerReseauxIsoles.valeur;
        diagReportData.longuerReseauxIsolesHorsVolumeChauffe = +ceeModel.longuerReseauxIsolesHorsVolumeChauffe.valeur;
        diagReportData.longuerReseauxIsolesSurFacture = +ceeModel.solutions.solutions[0].infoIsolant.find((s) => s.id === ' ')?.valeur;
        diagReportData.anomalieLonguerReseauxIsoles = this.ceeService.calculAnomalieLongueurReseau(
            diagReportData.longuerReseauxIsolesHorsVolumeChauffe,
            diagReportData.longuerReseauxIsolesSurFacture
        );

        // Liste des Documents etapes Generales
        diagReportData.documentsData = this.documentsService.buildDocumentsData(intervention, diagnostic);
        const infoDocReport = [
            { id: 'dateFacture', nom: 'Facture travaux', libelle: 'Date de la facture' },
            { id: 'dateEtatRecapitulatif', nom: 'État récapitulatif', libelle: "Date de l'état récapitulatif" },
        ];
        diagReportData.documentsData.forEach((doc) => {
            const fileTMP = infoDocReport.find((f) => f.nom === doc.nom);
            if (fileTMP) {
                diagReportData.etapesGenerales.push({
                    id: fileTMP.id,
                    valeur: doc.vueParLeTechnicien ? doc.dateDocument : 'Document non présenté',
                    libelle: fileTMP.libelle,
                    estAnomalie: doc.vueParLeTechnicien ? false : true,
                    commentaires: doc.commentaires,
                });
            }
        });
        diagReportData.listDocuments = diagReportData.documentsData.filter((doc) => doc.afficherDansListeDocuments);
        diagReportData.annexes = diagReportData.documentsData
            .filter((doc) => doc.afficherDansRapport)
            .map((doc) => ({
                id: doc.nom,
                filename: URL_MS_REPORT_FILE + doc.idFichier,
                type: 'pdf',
            }));

        //La conformité des documents
        let anomaliesDocuments: string[] = [];
        for (const doc of intervention.documents) {
            for (const typeDocCP of doc.typeDocument.typeDocumentCheckpoint.filter(
                (tdc) => tdc.referencePrestation.typePrestation === diagnostic.typePrestation
            )) {
                for (const checkpoint of typeDocCP.checkpoints) {
                    if (checkpoint.value === false) {
                        anomaliesDocuments.push(checkpoint.label);
                    }
                }
            }
        }
        // Les Points de controles
        diagReportData.pointsDeControle = this.getPointsDeControleReport(intervention, ceeModel, diagReportData.anomalies);
        diagReportData.pointsDeControle.solution[0].caracteristiques = this.getCaracteristiquesReport(ceeModel, intervention);
        console.log(diagReportData);
        // Les Anomalies
        diagReportData.anomalies = this.getAnomaliesReport(ceeModel, diagReportData, anomaliesDocuments);
        diagReportData.conformiteGlobale = this.calculerConformiteGlobal(diagReportData);
        // Conclusion non conforme et anomalie
        if (diagReportData.conformiteGlobale) {
            for (const sol of diagReportData.pointsDeControle.solution) {
                diagReportData.conformiteGlobale =
                    diagReportData.conformiteGlobale &&
                    !sol.infosSolutions.informations.some((inf) => {
                        return inf.libelle == 'Conclusion' && inf.reponse.includes('Non conforme');
                    });
                if (diagReportData.conformiteGlobale && [TypePrestationEnum.BARTH161, TypePrestationEnum.BARTH160].includes(this.typePrestation)) {
                    for (const zone of sol.zones) {
                        for (const pc of zone.pointsDeControle) {
                            if (pc.estAnomalie) {
                                diagReportData.conformiteGlobale = false;
                                break;
                            }
                        }
                    }
                    // Ecran Général
                    for (const property of Object.getOwnPropertyNames(new CeeModel())) {
                        if (ceeModel[property].estAnomalie) {
                            diagReportData.conformiteGlobale = false;
                            break;
                        }
                    }
                    // Caractéristiques
                    if (diagReportData.conformiteGlobale && TypePrestationEnum.BARTH161 === this.typePrestation) {
                        for (const property of Object.getOwnPropertyNames(ceeModel.caracteristiques.data)) {
                            if (ceeModel.caracteristiques.data[property].isAnomalie) {
                                diagReportData.conformiteGlobale = false;
                                break;
                            }
                        }
                    }
                }
            }
        }

        diagReportData.etat = diagnostic.etat;
        return diagReportData;
    }

    /**
     * Écran caractéristique : Uniquement en BARTH161
     * @param ceeModel
     * @param intervention
     */
    getCaracteristiquesReport(ceeModel: CeeModel, intervention: Intervention) {
        let caracteristiques = [];
        if (this.typePrestation === TypePrestationEnum.BARTH161) {
            const data: any[] = Object.values(ceeModel.caracteristiques.data);
            for (const etape of data) {
                const checkpoint: PointControle = this.getPointDeControle(etape.id);

                if (checkpoint?.sectionRapport) {
                    const comm = [];
                    for (const commentaireId of etape.commentairesId) {
                        comm.push(CommentUtils.getCommentaire(commentaireId, intervention.commentaires));
                    }
                    let reponse = etape.valeur;
                    if (
                        checkpoint.lstChoixReponse &&
                        checkpoint.lstChoixReponse.length &&
                        checkpoint.idInterne !== 'PC408' &&
                        checkpoint.idInterne !== 'PC409'
                    ) {
                        const choice = checkpoint.lstChoixReponse.find((cp) => cp.code === etape.valeur);
                        reponse = choice ? choice.description : etape.valeur;
                    }

                    let ano = this.getAnomalieFromEtape(etape, ceeModel);
                    caracteristiques.push({
                        libelle: checkpoint.libelleRapport ? checkpoint.libelleRapport : checkpoint.description,
                        reponse: etape.valeur,
                        commentaires: comm,
                        sectionRapport: checkpoint.sectionRapport,
                        ordreSectionRapport: checkpoint.ordreSectionRapport,
                        estAnomalie: !!ano,
                        idChapitre: checkpoint.idChapitre,
                        idPointControle: checkpoint.idInterne,
                    });
                }
            }

            this.sommeNbPointSingulierDeclares = 0;
            for (const carac of caracteristiques) {
                if (
                    carac.idPointControle === 'PC400' ||
                    carac.idPointControle === 'PC401' ||
                    carac.idPointControle === 'PC402' ||
                    carac.idPointControle === 'PC403' ||
                    carac.idPointControle === 'PC404' ||
                    carac.idPointControle === 'PC405' ||
                    carac.idPointControle === 'PC406' ||
                    carac.idPointControle === 'PC407'
                ) {
                    this.sommeNbPointSingulierDeclares += +carac.reponse;
                }
            }
            caracteristiques.push({
                libelle: 'Nombre total de points singulier déclarés',
                reponse: this.sommeNbPointSingulierDeclares,
                commentaires: [],
                sectionRapport: 'Caracteristiques',
                ordreSectionRapport: 1,
                estAnomalie: false,
                idChapitre: 'CH400',
                idPointControle: 'PC429',
            });

            caracteristiques = caracteristiques.sort(this.sortValues);
        }
        return caracteristiques;
    }
    readonly sortValues = (a: any, b: any): number => {
        // Compare d'abord par sectionRapport
        const sectionComparison = a.sectionRapport.localeCompare(b.sectionRapport);

        // Si les sections sont égales, compare par ordreSectionRapport
        if (sectionComparison === 0) {
            return +a.ordreSectionRapport - +b.ordreSectionRapport;
        }

        // Sinon, retourne le résultat de la comparaison par sectionRapport
        return sectionComparison;
    };

    calculerZoneClimatique(departement: string): string {
        let config = null;
        let ret = 'Aucune zone climatique trouvée pour le département ' + departement;
        this.ceeService.ceeConfig$.pipe(first()).subscribe((conf) => (config = conf));
        config.tableauZoneClimatique.lstDepartementsZones.forEach((element) => {
            if (element.departement.toString() === departement) {
                ret = element.zone;
            }
        });
        return ret;
    }

    calculerConformiteGlobal(diagReportData: CEEReportData) {
        if (diagReportData.anomalies.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    private convertDateToString(value: EtapeDiagnosticGenerique): EtapeDiagnosticGenerique {
        const newValue = Object.assign({}, value);
        newValue.valeur = value.valeur ? new Date(value.valeur).toLocaleDateString() : '';
        return newValue;
    }
    private getInfoSolution(
        intervention: Intervention,
        solution: ISolution,
        anomalies: IAnomalie[],
        ceeModel: CeeModel
    ): {
        solutionName: string;
        conclusionAccessibiliteRealisationNonSatisfaisant: boolean;
        conclusionMiseEnServiceNonSatisfaisant: boolean;
        informations: {
            libelle: string;
            reponse: string;
            commentaires: Commentaire[];
            sectionRapport: string;
            ordreSectionRapport: string;
            estAnomalie: boolean;
            libellesAnomalies: any[];
        }[];
    } {
        let noAnomalies: boolean = true;
        const infoSolution: any = {};
        infoSolution.informations = [];
        infoSolution.solutionName = this.getSolutionName(solution);

        solution.infoIsolant.forEach((repPtc) => {
            const checkpoint = this.getPointDeControle(repPtc.id);
            if (checkpoint && checkpoint.sectionRapport) {
                let reponse = repPtc.valeur;

                if (checkpoint.lstChoixReponse && checkpoint.lstChoixReponse.length) {
                    const choice = checkpoint.lstChoixReponse.find((cp) => cp.code === repPtc.valeur);
                    reponse = choice ? choice.description : repPtc.valeur;
                }
                const commentaires = [];
                for (const commentaireId of repPtc.commentairesId) {
                    commentaires.push(CommentUtils.getCommentaire(commentaireId, intervention.commentaires));
                }
                let ano = this.getAnomalieFromEtape(repPtc, ceeModel);
                if (checkpoint.type === 'date') {
                    //reponse = new Date(reponse).toLocaleTimeString();
                    const ReponseDate = new Date(reponse).getTime().toString();
                    ano = this.getAnomalieFromEtape(repPtc, ceeModel);
                    reponse = new Date(reponse).toLocaleDateString();
                }
                const libellesAnomalies = ano ? [ano] : [];

                infoSolution.informations.push({
                    libelle: checkpoint.libelleRapport ? checkpoint.libelleRapport : checkpoint.description,
                    reponse,
                    commentaires,
                    sectionRapport: checkpoint.sectionRapport,
                    ordreSectionRapport: checkpoint.ordreSectionRapport,
                    estAnomalie: !!ano,
                    libellesAnomalies,
                });

                // Search anomalie
                const iAno = anomalies.find((sol) => sol.solution === infoSolution.solutionName);
                if (iAno && iAno.zones && iAno.zones[0].libelleAnomalies) {
                    noAnomalies = iAno.zones[0].libelleAnomalies.find((libelle) => libelle === checkpoint.description) ? false : noAnomalies;
                }
                infoSolution.conclusionAccessibiliteRealisationNonSatisfaisant = false;
                infoSolution.conclusionMiseEnServiceNonSatisfaisant = false;
                for (const info of infoSolution.informations) {
                    if (info.sectionRapport === 'Acessibilite et réalisation des travaux') {
                        if (info.estAnomalie === true) {
                            infoSolution.conclusionAccessibiliteRealisationNonSatisfaisant = true;
                        }
                    }
                    if (info.sectionRapport === "Mise en service de l'installation") {
                        if (info.estAnomalie === true) {
                            infoSolution.conclusionMiseEnServiceNonSatisfaisant = true;
                        }
                    }
                }
            }
        });
        if (![TypePrestationEnum.BARTH160, TypePrestationEnum.BARTH161].includes(this.typePrestation)) {
            infoSolution.informations.push({
                libelle: 'Conclusion',
                reponse: noAnomalies ? 'Conforme' : 'Non conforme',
                commentaires: [],
                sectionRapport: PERFORMANCE_THERMIQUE,
                ordreSectionRapport: '99',
                estAnomalie: false,
                libellesAnomalies: [],
            });
        }

        infoSolution.informations.sort(this.sortValues);
        return infoSolution;
    }

    private getPointsDeControleReport(intervention: Intervention, ceeModel: CeeModel, anomalies): { solution: IReportPC[] } {
        const reponses: { solution: IReportPC[] } = { solution: [] };
        const solutions = ceeModel.solutions.solutions;
        for (const solution of solutions) {
            // Informations générale
            const response: IReportPC = {
                infosSolutions: this.getInfoSolution(intervention, solution, anomalies, ceeModel),
                zones: [],
            };
            // PTC zones
            for (const nomZone of Object.getOwnPropertyNames(solution.pointsDeControles.data)) {
                let zoneName = nomZone;
                if ([TypePrestationEnum.BARTH160].includes(this.typePrestation)) {
                    //for (const rep of solution.pointsDeControles.data[nomZone]) {
                    //if ('PC306' === rep.id) zoneName = rep.valeur ?? '';
                    //}
                    const nom = solution.pointsDeControles.data[nomZone].find((pt) => pt.id === 'PC306')?.valeur;
                    zoneName = nom ?? '';
                }
                if ([TypePrestationEnum.BARTH161].includes(this.typePrestation)) {
                    const numero = solution.pointsDeControles.data[nomZone].find((pt) => pt.id === 'PC416')?.valeur;
                    const nom = solution.pointsDeControles.data[nomZone].find((pt) => pt.id === 'PC417')?.valeur;
                    zoneName = nom ? `${nom} N° ${numero ?? ''}` : nomZone;
                }
                const zone: {
                    zoneName: string;
                    pointsDeControle: {
                        libelle: string;
                        reponse: string;
                        commentaires: Commentaire[];
                        sectionRapport: string;
                        ordreSectionRapport: string;
                        estAnomalie: boolean;
                        libellesAnomalies: any[];
                        imageZone: {
                            legende: string;
                            url: string;
                            creationDate: string;
                            gpsLatitudeRef: string;
                            gpsLatitude: string;
                            gpsLongitudeRef: string;
                            gpsLongitude: string;
                            gpsAltitudeRef: string;
                            gpsAltitude: string;
                        };
                    }[];
                } = { zoneName, pointsDeControle: [] };

                if (solution.pointsDeControles.data[nomZone]) {
                    let localisationReseau = '';
                    let precisionLocalisationReseau = '';
                    let commentairesLocalisation = [];
                    let localisationPointSingulier = '';
                    let precisionLocalisationPointSingulier = '';
                    let commentairesLocalisationPointSingulier = [];
                    for (const ptc of solution.pointsDeControles.data[nomZone]) {
                        const checkpoint: PointControle = this.getPointDeControle(ptc.id);
                        let reponse = ptc.valeur;
                        if (checkpoint.lstChoixReponse && checkpoint.lstChoixReponse.length) {
                            const choice = checkpoint.lstChoixReponse.find((cp) => cp.code === reponse);
                            reponse = choice ? choice.description : reponse;
                        } else {
                            const option = [ouiOption, nonOption, sansObjetOption, nonVisibleOption].find((opt) => opt.value == reponse);
                            reponse = option ? option.label : reponse;
                        }

                        const commentaires = [];
                        for (const commentaireId of ptc.commentairesId) {
                            commentaires.push(CommentUtils.getCommentaire(commentaireId, intervention.commentaires));
                        }

                        ///////////
                        let anomalie: any;
                        if (ptc.isForceAnomalie) {
                            anomalie = true;
                        } else {
                            anomalie = this.getAnomalieFromEtape(ptc, ceeModel);
                        }
                        const libellesAnomalies = [];
                        if (anomalie) {
                            libellesAnomalies.push({
                                libelleAnomalie: anomalie?.description ? anomalie.description : checkpoint.description,
                            });
                        }
                        let libelleRapport = String();
                        if (checkpoint.libelleRapport) {
                            libelleRapport = checkpoint.libelleRapport;
                        } else {
                            libelleRapport = checkpoint.description;
                        }

                        //Barth161 - Absences d'anomalie ou de manquement aux règles de l'art
                        //comme la question est inversée par rapport a l'intilulé du rapport on inverse aussi les réponse
                        if (checkpoint.idInterne === 'PC415') {
                            switch (reponse) {
                                case 'oui': {
                                    reponse = 'non';
                                }
                                case 'non': {
                                    reponse = 'oui';
                                }
                            }
                        }
                        //Barth161 - si pas de Diamètre nominal
                        if (checkpoint.idInterne === 'PC420' && (reponse == null || reponse === '')) {
                            reponse = 'e.p.';
                        }
                        //BarTH160 BarTh161
                        //concatenation de la localisation et de la précision du réseau
                        if (checkpoint.idInterne === 'PC309') {
                            localisationReseau = reponse;
                            if (commentaires.length > 0) commentairesLocalisation.push(commentaires);
                        } else if (checkpoint.idInterne === 'PC320') {
                            if (reponse != null || '') precisionLocalisationReseau = ' ' + reponse;
                            if (commentaires.length > 0) commentairesLocalisation.push(commentaires);
                        } else if (checkpoint.idInterne === 'PC419') {
                            localisationPointSingulier = reponse;
                            if (commentaires.length > 0) commentairesLocalisationPointSingulier.push(commentaires);
                        } else if (checkpoint.idInterne === 'PC440') {
                            if (reponse != null || '') precisionLocalisationPointSingulier = ' ' + reponse;
                            if (commentaires.length > 0) commentairesLocalisationPointSingulier.push(commentaires);
                        } else {
                            if (checkpoint.type === 'photo') {
                                if (ptc.idImage) {
                                    let interfile = this.getInterventionFile(intervention.id, ptc.idImage);
                                    interfile.subscribe((value) => {
                                        zone.pointsDeControle.push({
                                            libelle: libelleRapport,
                                            reponse,
                                            commentaires,
                                            sectionRapport: checkpoint.sectionRapport,
                                            ordreSectionRapport: checkpoint.ordreSectionRapport,
                                            estAnomalie: !!anomalie,
                                            libellesAnomalies,
                                            imageZone: {
                                                url: URL_MS_REPORT_FILE + ptc.idImage,
                                                legende: 'Photo ' + zone.zoneName,
                                                creationDate: new Date(+value?.creationDate ?? 'Non renseigné').toLocaleDateString(),
                                                gpsLatitudeRef: value?.gpsLatitudeRef ?? 'Non renseigné',
                                                gpsLatitude: value?.gpsLatitude ?? 'Non renseigné',
                                                gpsLongitudeRef: value?.gpsLongitudeRef ?? 'Non renseigné',
                                                gpsLongitude: value?.gpsLongitude ?? 'Non renseigné',
                                                gpsAltitudeRef: value?.gpsAltitudeRef ?? 'Non renseigné',
                                                gpsAltitude: value?.gpsAltitude ?? 'Non renseigné',
                                            },
                                        });
                                    });
                                }
                            } else {
                                zone.pointsDeControle.push({
                                    libelle: libelleRapport,
                                    reponse,
                                    commentaires,
                                    sectionRapport: checkpoint.sectionRapport,
                                    ordreSectionRapport: checkpoint.ordreSectionRapport,
                                    estAnomalie: ptc.isAnomalie ? true : !!anomalie,
                                    libellesAnomalies,
                                    imageZone: {
                                        url: '',
                                        legende: '',
                                        creationDate: '',
                                        gpsLatitudeRef: '',
                                        gpsLatitude: '',
                                        gpsLongitudeRef: '',
                                        gpsLongitude: '',
                                        gpsAltitudeRef: '',
                                        gpsAltitude: '',
                                    },
                                });
                            }
                        }
                    }

                    //BarTH160
                    //Localisation du réseau + précision de la localisation
                    if ([TypePrestationEnum.BARTH160].includes(this.typePrestation)) {
                        zone.pointsDeControle.push({
                            libelle: 'Localisation du réseau',
                            reponse: localisationReseau + precisionLocalisationReseau,
                            commentaires: commentairesLocalisation,
                            sectionRapport: LONGUEURS_RESEAU_ISOLEES,
                            ordreSectionRapport: '3',
                            estAnomalie: false,
                            libellesAnomalies: [],
                            imageZone: {
                                url: '',
                                legende: '',
                                creationDate: '',
                                gpsLatitudeRef: '',
                                gpsLatitude: '',
                                gpsLongitudeRef: '',
                                gpsLongitude: '',
                                gpsAltitudeRef: '',
                                gpsAltitude: '',
                            },
                        });
                    }
                    //BarTH160
                    //Localisation du réseau + précision de la localisation
                    if ([TypePrestationEnum.BARTH161].includes(this.typePrestation)) {
                        zone.pointsDeControle.push({
                            libelle: 'Localisation du point singulier',
                            reponse: localisationPointSingulier + precisionLocalisationPointSingulier,
                            commentaires: commentairesLocalisationPointSingulier,
                            sectionRapport: POINTS_SINGULIERS,
                            ordreSectionRapport: '4',
                            estAnomalie: false,
                            libellesAnomalies: [],
                            imageZone: {
                                url: '',
                                legende: '',
                                creationDate: '',
                                gpsLatitudeRef: '',
                                gpsLatitude: '',
                                gpsLongitudeRef: '',
                                gpsLongitude: '',
                                gpsAltitudeRef: '',
                                gpsAltitude: '',
                            },
                        });
                    }
                    if (![TypePrestationEnum.BARTH160, TypePrestationEnum.BARTH161].includes(this.typePrestation)) {
                        const surfaceFacture: number = this.getSurfaceFactureeBySolution(solution);
                        const surfaceMesuree: number = this.getSurfaceMesureeByZone(solution.pointsDeControles.data[nomZone]);
                        const ecart = Math.abs(((surfaceFacture - surfaceMesuree) / surfaceMesuree) * 100).toFixed(2);

                        zone.pointsDeControle.push({
                            libelle: 'Surface isolée sur preuve de réalisation (Sent) [m²]',
                            reponse: surfaceFacture + ' m²',
                            commentaires: [],
                            sectionRapport: SURFACE_ISOLEE,
                            ordreSectionRapport: '2',
                            estAnomalie: false,
                            libellesAnomalies: [],
                            imageZone: {
                                url: '',
                                legende: '',
                                creationDate: '',
                                gpsLatitudeRef: '',
                                gpsLatitude: '',
                                gpsLongitudeRef: '',
                                gpsLongitude: '',
                                gpsAltitudeRef: '',
                                gpsAltitude: '',
                            },
                        });
                        zone.pointsDeControle.push({
                            libelle: "Calcul de l'écart = (Sent-Sinsp)/Sinsp*100",
                            reponse: ecart + ' %',
                            commentaires: [],
                            sectionRapport: SURFACE_ISOLEE,
                            ordreSectionRapport: '3',
                            estAnomalie: false,
                            libellesAnomalies: [],
                            imageZone: {
                                url: '',
                                legende: '',
                                creationDate: '',
                                gpsLatitudeRef: '',
                                gpsLatitude: '',
                                gpsLongitudeRef: '',
                                gpsLongitude: '',
                                gpsAltitudeRef: '',
                                gpsAltitude: '',
                            },
                        });
                        zone.pointsDeControle.push({
                            libelle: 'Ecart > 10%',
                            reponse: +ecart > 10 ? 'Oui' : 'Non',
                            commentaires: [],
                            sectionRapport: SURFACE_ISOLEE,
                            ordreSectionRapport: '4',
                            estAnomalie: +ecart > 10,
                            libellesAnomalies: [],
                            imageZone: {
                                url: '',
                                legende: '',
                                creationDate: '',
                                gpsLatitudeRef: '',
                                gpsLatitude: '',
                                gpsLongitudeRef: '',
                                gpsLongitude: '',
                                gpsAltitudeRef: '',
                                gpsAltitude: '',
                            },
                        });
                    }
                    zone.pointsDeControle.sort(this.sortValues);

                    response.zones.push(zone);
                }
            }
            reponses.solution.push(response);
        }
        return reponses;
    }
    /**
     * récupère l'intervention file lié a un photo_prestation
     * @idFile : l'id du fichier
     */
    public getInterventionFile(interventionId: string, idFile: string): Observable<InterventionFile> {
        let interFiles: Observable<InterventionFile> = this.interventionFileService.findByIdInterventionFileId(
            interventionId,
            TypeReferenceFichier.PHOTO_PRESTATION,
            idFile
        );
        console.log(interFiles);
        return interFiles;
    }

    //retourne true si "non renseigné" est coché
    //si "non ernseigné" est coché alors .checked = true et .validated = true
    private getAnomalieFromEtapeGenerale(etape: EtapeDiagnosticGenerique): boolean {
        let ret = false;
        if (etape.checked == true && etape.validated == true) {
            ret = true;
        }
        //pour les questions : presenceDevis et presenceFacture
        if (etape.valeur == '3 - non avec déclaration écrite') {
            ret = true;
        }
        return ret;
    }

    private getAnomalieFromEtape(etape: EtapeDiagnosticGenerique, ceeModel: CeeModel) {
        return this.ceeService.getAnomalieFromCheckpoint(etape, ceeModel);
    }

    private getPointDeControle(idOrDescription: string): PointControle {
        let config = null;
        this.ceeService.ceeConfig$.pipe(first()).subscribe((conf) => (config = conf));
        return config.pointsControles.find((ptc) => ptc.description === idOrDescription || ptc.idInterne === idOrDescription);
    }

    private getAnomaliesReport(ceeModel: CeeModel, report: CEEReportData, anomaliesDocuments: string[]): IAnomalie[] {
        const iAnomalies: IAnomalie[] = [];

        //Questions sur les documents
        const iAnomalieDocumentaire: IAnomalie = {
            solution: 'Documents',
            zones: [],
        };
        const anomalieQuestionDocuments = { name: 'QuestionsDocuments', libelleAnomalies: [] };
        for (const anomalieQuestions of anomaliesDocuments) {
            {
                anomalieQuestionDocuments.libelleAnomalies.push(anomalieQuestions);
            }
        }
        if (anomalieQuestionDocuments.libelleAnomalies.length) {
            iAnomalieDocumentaire.zones.push(anomalieQuestionDocuments);
        }
        if (iAnomalieDocumentaire.zones.length) {
            iAnomalieDocumentaire.zones = iAnomalieDocumentaire.zones.filter((zone) => zone.libelleAnomalies.length);
            iAnomalies.push(iAnomalieDocumentaire);
        }

        //Initialisation (Etape générique)
        const iAnomalieGenerale: IAnomalie = {
            solution: 'InitialisationGeneral',
            zones: [],
        };
        const anomalieGenerale = { name: 'EtapesGenerales', libelleAnomalies: [] };
        for (const etapeGenerique of report.etapesGenerales) {
            if (etapeGenerique.estAnomalie === true) {
                anomalieGenerale.libelleAnomalies.push(etapeGenerique.libelle);
            }
        }
        if (anomalieGenerale.libelleAnomalies.length) {
            iAnomalieGenerale.zones.push(anomalieGenerale);
        }
        if (iAnomalieGenerale.zones.length) {
            iAnomalieGenerale.zones = iAnomalieGenerale.zones.filter((zone) => zone.libelleAnomalies.length);
            iAnomalies.push(iAnomalieGenerale);
        }

        //Conformité réglementaire (question document dans l'initialisation)
        const iAnomalieReglementaire: IAnomalie = {
            solution: 'ConformiteReglementaire',
            zones: [],
        };
        const anomalieDocumentaire = { name: 'QuestionReglementaire', libelleAnomalies: [] };
        for (const questionDocumentaire of report.conformitereglementaire) {
            if (questionDocumentaire.estAnomalie === true) {
                anomalieDocumentaire.libelleAnomalies.push(questionDocumentaire.libelle);
            }
        }
        if (anomalieDocumentaire.libelleAnomalies.length) {
            iAnomalieReglementaire.zones.push(anomalieDocumentaire);
        }
        if (iAnomalieReglementaire.zones.length) {
            iAnomalieReglementaire.zones = iAnomalieReglementaire.zones.filter((zone) => zone.libelleAnomalies.length);
            iAnomalies.push(iAnomalieReglementaire);
        }

        //Points de Controle Solution
        for (const solutionR of report.pointsDeControle.solution) {
            const iAnomalieSolution: IAnomalie = {
                solution: 'InfosSolution ' + solutionR.infosSolutions.solutionName,
                zones: [],
            };
            const anomalieSolution = { name: 'InfosSolution', libelleAnomalies: [] };
            for (const ptc of solutionR.infosSolutions.informations) {
                if (ptc.estAnomalie === true) {
                    anomalieSolution.libelleAnomalies.push(ptc.libelle);
                }
            }
            if (anomalieSolution.libelleAnomalies.length) {
                iAnomalieSolution.zones.push(anomalieSolution);
            }
            if (iAnomalieSolution.zones.length) {
                iAnomalieSolution.zones = iAnomalieSolution.zones.filter((zone) => zone.libelleAnomalies.length);
                iAnomalies.push(iAnomalieSolution);
            }
        }

        //Points de Controle Zones
        for (const solutionR of report.pointsDeControle.solution) {
            const iAnomaliePointControleZone: IAnomalie = {
                solution: solutionR.infosSolutions.solutionName,
                zones: [],
            };
            for (const zone of solutionR.zones) {
                const anomaliePtcZone = { name: zone.zoneName, libelleAnomalies: [] };
                for (const ptc of zone.pointsDeControle)
                    if (ptc.estAnomalie === true) {
                        anomaliePtcZone.libelleAnomalies.push(ptc.libelle);
                    }
                if (anomaliePtcZone.libelleAnomalies.length) {
                    iAnomaliePointControleZone.zones.push(anomaliePtcZone);
                }
            }
            if (iAnomaliePointControleZone.zones.length) {
                iAnomaliePointControleZone.zones = iAnomaliePointControleZone.zones.filter((zone) => zone.libelleAnomalies.length);
                iAnomalies.push(iAnomaliePointControleZone);
            }
        }

        //Point de controle différence entre mesuré et sur preuve de réalisation
        for (const solution of ceeModel.solutions.solutions) {
            const nomSolution = this.getSolutionName(solution);
            const epaisseurFacture = this.getEpaisseurFactureeBySolution(solution); // Epaisseur sur preuve de réalisation (en cm)
            const surfaceFacture = this.getSurfaceFactureeBySolution(solution); // Surface isolée sur preuve de réalisation (m²)
            const iAnomalie: IAnomalie = {
                solution: 'EcartFactureMesure ' + nomSolution,
                zones: [],
            };
            const totalEpaisseur = this.getEpaisseurMesureeBySolution(solution);
            const totalSurface = this.getSurfaceMesureeBySolution(solution);

            for (const name of Object.getOwnPropertyNames(solution.pointsDeControles.data)) {
                const anomalies = { name, libelleAnomalies: [] };
                if (surfaceFacture && Math.abs(((+surfaceFacture - totalSurface) / totalSurface) * 100) > 10) {
                    anomalies.libelleAnomalies.push('Surface isolée mesurée par l’inspecteur (Sinsp) [m²]');
                    anomalies.libelleAnomalies.push('Surface isolée sur preuve de réalisation (m²)');
                }
                if (epaisseurFacture && Math.abs(((+epaisseurFacture - totalEpaisseur) / totalEpaisseur) * 100) > 10) {
                    anomalies.libelleAnomalies.push('Épaisseur mesurée par l’inspecteur (cm)');
                    anomalies.libelleAnomalies.push('Epaisseur sur preuve de réalisation (en cm)');
                }

                if (anomalies.libelleAnomalies.length) {
                    iAnomalie.zones.push(anomalies);
                }
            }
            if (iAnomalie.zones.length) {
                iAnomalie.zones = iAnomalie.zones.filter((zone) => zone.libelleAnomalies.length);
                iAnomalies.push(iAnomalie);
            }
        }

        if (report.anomalieLonguerReseauxIsoles) {
            const iAnomalie: IAnomalie = {
                solution: 'EcartFactureMesure',
                zones: [
                    {
                        name: 'InfosSolution',
                        libelleAnomalies: [
                            "L'écart de longueur de réseaux isolées lors de l'opération, déclarées sur la facture est supérieure à 10%",
                        ],
                    },
                ],
            };
            iAnomalies.push(iAnomalie);
        }

        // Ajout des anomalies des caractéristiques
        let iAnomalieCaracteristiques: IAnomalie = {
            solution: 'InfosCaracteristiques',
            zones: [],
        };
        let anomalieCaracteristique = {
            name: 'Caracteristiques',
            libelleAnomalies: [],
        };
        const caracteristiques: any[] = Object.values(ceeModel.caracteristiques.data);
        caracteristiques.forEach((carac) => {
            if (carac.isAnomalie) {
                const checkpoint = this.getPointDeControle(carac.id);
                anomalieCaracteristique.libelleAnomalies.push(this.getAnomalieFromEtape(carac, ceeModel));
            }
        });

        if (anomalieCaracteristique.libelleAnomalies.length > 0) {
            iAnomalieCaracteristiques.zones.push(anomalieCaracteristique);
            iAnomalies.push(iAnomalieCaracteristiques);
        }

        return iAnomalies;
    }

    // Retourne le nom d'une solution
    private getSolutionName(solution: ISolution): string {
        let nomSolution = '';
        if (solution.infoIsolant) {
            const solValue = solution.infoIsolant.find((val) => this.ceeService.idsNomSolution.includes(val.id));
            if (solValue) {
                nomSolution = solValue.valeur;
            }
        }
        return nomSolution;
    }

    // Retourne la surface facture par solution
    private getSurfaceFactureeBySolution(solution: ISolution): number {
        let config = null;
        this.ceeService.ceeConfig$.pipe(first()).subscribe((conf) => (config = conf));
        const idsSurfaceFacture = config.pointsControles
            .filter((ptc) => ptc.description === 'Surface isolée sur preuve de réalisation (m²)')
            .map((ptc) => ptc.idInterne);
        let surfaceFacture: EtapeDiagnosticGenerique = null; // Surface isolée sur preuve de réalisation (m²)
        if (solution.infoIsolant) {
            surfaceFacture = solution.infoIsolant.find((val) => idsSurfaceFacture.includes(val.id));
        }
        return surfaceFacture ? +surfaceFacture.valeur : 0;
    }

    // Retourne l'Epaisseur facture par solution
    private getEpaisseurFactureeBySolution(solution: ISolution): number {
        let config = null;
        this.ceeService.ceeConfig$.pipe(first()).subscribe((conf) => (config = conf));
        const idsEpaisseurFacture = config.pointsControles
            .filter((ptc) => ptc.description === 'Epaisseur sur preuve de réalisation (en cm)')
            .map((ptc) => ptc.idInterne);
        let epaisseurFacture: EtapeDiagnosticGenerique = null; // Surface isolée sur preuve de réalisation (m²)
        if (solution.infoIsolant) {
            epaisseurFacture = solution.infoIsolant.find((val) => idsEpaisseurFacture.includes(val.id));
        }
        return epaisseurFacture ? +epaisseurFacture.valeur : 0;
    }

    // Retourne la surface mesurée par solution (somme zones)
    private getSurfaceMesureeBySolution(solution: ISolution): number {
        let totalSurface = 0;
        for (const name of Object.getOwnPropertyNames(solution.pointsDeControles.data)) {
            totalSurface += this.getSurfaceMesureeByZone(solution.pointsDeControles.data[name]);
        }
        return totalSurface;
    }

    // Retourne la surface mesurée par solution (somme zones)
    private getSurfaceMesureeByZone(pointsDeControle: EtapeDiagnosticGenerique[]): number {
        let config = null;
        this.ceeService.ceeConfig$.pipe(first()).subscribe((conf) => (config = conf));
        const idsSurfaceMesure = config.pointsControles
            .filter((ptc) => ptc.description === 'Surface isolée mesurée par l’inspecteur (Sinsp) [m²]')
            .map((ptc) => ptc.idInterne);
        const value = pointsDeControle.find((val) => idsSurfaceMesure.includes(val.id));
        return value ? +value.valeur : 0;
    }

    // Retourne Epaisseur mesurée par solution (somme zones)
    private getEpaisseurMesureeBySolution(solution: ISolution): number {
        let totalEpaisseur = 0;
        for (const name of Object.getOwnPropertyNames(solution.pointsDeControles.data)) {
            totalEpaisseur += this.getEpaisseurMesureeByZone(solution.pointsDeControles.data[name]);
        }
        return totalEpaisseur;
    }

    // Retourne Epaisseur mesurée par Zone
    private getEpaisseurMesureeByZone(pointsDeControle: EtapeDiagnosticGenerique[]): number {
        let config = null;
        this.ceeService.ceeConfig$.pipe(first()).subscribe((conf) => (config = conf));
        const idsEpaisseurMesure = config.pointsControles
            .filter((ptc) => ptc.description === 'Épaisseur mesurée par l’inspecteur (cm)')
            .map((ptc) => ptc.idInterne);
        const value = pointsDeControle.find((val) => idsEpaisseurMesure.includes(val.id));
        return value ? +value.valeur : 0;
    }

    getReportBlockType(componentName: string): Type<any> {
        const blockType = REPORT_BLOCK_CATALOG[componentName];
        if (!blockType) {
            console.log('Block %s not found', componentName);
        }
        return blockType;
    }

    isItemAlreadyFilled(diagnostic: Diagnostic, type: string, itemId: string): boolean {
        return false;
    }

    prepareFilteredCommentsForReport(diagnosticData: IDiagReportData, hiddenComments: Map<string, string[]>) {}

    prepareForm(intervention: Intervention, contenuDiagnostic: ContenuDiagnostic) {}

    prepareSpecificComments(diagnostic: Diagnostic, commentairesGeneraux: Commentaire[], commentaires: CommentairePredefini[]): Commentaire[] | any {
        return [];
    }

    generateDiagnosticBonCommande(
        intervention: Intervention,
        diagnostic: Diagnostic,
        interReportData: InterventionReportData
    ): BonCommandeAnalyseAdmin {
        return undefined;
    }

    generateLegendForScreenshot(diagnostic: Diagnostic): LegendScreenshot[] {
        return [];
    }

    prepareStoreyForScreenshot(diagnostic: Diagnostic, currentStorey: cn_storey, conf: any) {}

    deplaceVolume(diagnostic: Diagnostic, volumeSource: Volume, niveauDestination: Niveau, currentBien: Bien) {
        this.pointDeControleService.deplaceVolume(diagnostic, volumeSource, niveauDestination, currentBien);
    }

    mergeNiveau(diagnostic: Diagnostic, niveauSource: Niveau, niveauDestination: Niveau, currentBien: Bien) {
        this.pointDeControleService.mergeNiveau(diagnostic, niveauSource, niveauDestination, currentBien);
    }

    mergeVolume(diagnostic: Diagnostic, volumeSource: Volume, volumeDestination: Volume, currentBien: Bien, typeVolume: TypeVolume) {
        this.pointDeControleService.mergeVolume(diagnostic, volumeSource, volumeDestination, currentBien, typeVolume);
    }

    deplaceEquipement(idEquipement: string, diagnostic: Diagnostic, volumeDestination: Volume, bien: Bien) {
        this.pointDeControleService.deplaceEquipement(idEquipement, diagnostic, volumeDestination, bien);
    }
}

@Injectable({
    providedIn: 'root',
})
export class TypeBAREN101Service extends TypeCEESerice {
    getTypePrestation(): TypePrestation {
        return TypePrestationEnum.BAREN101;
    }
}

@Injectable({
    providedIn: 'root',
})
export class TypeBAREN102Service extends TypeCEESerice {
    constructor(
        private readonly ceeServicee: CeeService,
        private readonly reportagePhotoServicee: ReportagePhotoService,
        pointDeControleService: PointDeControleService,
        interventionFileService: InterventionFileService,
        documentsService: DocumentsService
    ) {
        super(ceeServicee, reportagePhotoServicee, pointDeControleService, interventionFileService, documentsService);
        this.typePrestation = TypePrestationEnum.BAREN102;
    }
}

@Injectable({
    providedIn: 'root',
})
export class TypeBAREN103Service extends TypeCEESerice {
    constructor(
        private readonly ceeServicee: CeeService,
        private readonly reportagePhotoServicee: ReportagePhotoService,
        pointDeControleService: PointDeControleService,
        interventionFileService: InterventionFileService,
        documentsService: DocumentsService
    ) {
        super(ceeServicee, reportagePhotoServicee, pointDeControleService, interventionFileService, documentsService);
        this.typePrestation = TypePrestationEnum.BAREN103;
    }
}

@Injectable({
    providedIn: 'root',
})
export class TypeBARTH160Service extends TypeCEESerice {
    constructor(
        private readonly ceeServicee: CeeService,
        private readonly reportagePhotoServicee: ReportagePhotoService,
        pointDeControleService: PointDeControleService,
        interventionFileService: InterventionFileService,
        documentsService: DocumentsService
    ) {
        super(ceeServicee, reportagePhotoServicee, pointDeControleService, interventionFileService, documentsService);
        this.typePrestation = TypePrestationEnum.BARTH160;
    }
}

@Injectable({
    providedIn: 'root',
})
export class TypeBARTH161Service extends TypeCEESerice {
    constructor(
        private readonly ceeServicee: CeeService,
        private readonly reportagePhotoServicee: ReportagePhotoService,
        pointDeControleService: PointDeControleService,
        interventionFileService: InterventionFileService,
        documentsService: DocumentsService
    ) {
        super(ceeServicee, reportagePhotoServicee, pointDeControleService, interventionFileService, documentsService);
        this.typePrestation = TypePrestationEnum.BARTH161;
    }
}
