export const ROLE_ANONYMOUS = 'ROLE_ANONYMOUS';
export const ROLE_USER = 'ROLE_USER';
export const ROLE_ADMIN = 'ROLE_ADMIN';

export class User {
    constructor(
        public id?: string,
        public email?: string,
        public firstName?: string,
        public lastName?: string,
        public company?: string,
        public address?: string,
        public phone?: string,
        public langKey?: string,
        public imageUrl?: string,
        public authorities?: string[],
        public deletedDate?: string
    ) {}
}
