<app-expension-panel theme="default" *ngIf="showPu()">
    <div title class="piece-unitaire-besoin-title piece-unitaire-besoin--grey">
        <span class="mr-3">P.U</span>
        <input readonly width="30px" [value]="index + 1" />
    </div>
    <ng-container *ngFor="let idVolume of pieceUnitaire.listeIdVolume; let volumeIndex = index">
        <ng-container *ngFor="let volume of volumes">
            <app-expension-panel class="expension-panel" theme="secondary" *ngIf="volume.id == idVolume">
                <div title class="piece-unitaire-besoin-title piece-unitaire-besoin--white">
                    <mat-icon class="mr-3">extension</mat-icon>
                    <span>{{ volume.nom }}</span>
                </div>

                <div description *ngIf="showActions" class="piece-unitaire-besoin-action">
                    <button mat-icon-button (click)="nouveauPrelevement($event, volume.id)" [disabled]="!isInEditMode">
                        <mat-icon>colorize</mat-icon>
                    </button>
                </div>

                <div *ngFor="let besoin of besoins; let key = index">
                    <!-- On boucle sur tous les besoins pour afficher uniquement ceux qui 
                    sont liés à la pièce bouclée actuellement -->
                    <!-- On s'assure aussi que le besoin est dans la bonne zone, si celle ci est définie -->
                    <!-- <ng-container *ngFor="let zone of selectedZones"> -->
                    <app-item-card
                        *ngIf="besoin.pieceId == volume.id && zone?.id == besoin.zoneId"
                        class="card-besoin"
                        (click)="selectBesoin($event, besoin, key)"
                        [isSelected]="key == indexBesoinSelected"
                        [ngClass]="{ todo: besoin.todo }"
                    >
                        <div title class="item-card-besoin">
                            <button
                                mat-icon-button
                                (click)="editBesoin($event, besoin, key)"
                                *ngIf="showActions"
                                [disabled]="!isInEditMode"
                            >
                                <mat-icon>colorize</mat-icon>
                            </button>
                            <div>
                                {{
                                    besoin.objectifMesurage?.reference != undefined
                                        ? (besoin | RefBesoinAir)
                                        : 'Nouveau besoin'
                                }}
                            </div>
                        </div>
                        <div action class="item-card-besoin">
                            <button
                                mat-icon-button
                                *ngIf="showActions"
                                (click)="duplicateBesoin($event, besoin, key)"
                                [disabled]="!isInEditMode"
                            >
                                <mat-icon>content_copy</mat-icon>
                            </button>
                            <button
                                mat-icon-button
                                *ngIf="showActions"
                                (click)="deleteBesoin($event, besoin, key)"
                                [disabled]="!isInEditMode"
                            >
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </app-item-card>
                    <!-- </ng-container> -->
                </div>
            </app-expension-panel>
        </ng-container>
    </ng-container>
</app-expension-panel>
