<lib-form-container>
    <h1 *ngIf="creationMode">Nouvel utilisateur</h1>
    <h1 *ngIf="!creationMode">Détail de l'utilisateur</h1>

    <h2 class="my-4" *ngIf="!creationMode">Email : {{ user.email }}</h2>

    <mat-form-field class="w-100" [hidden]="!creationMode">
        <mat-label>Email</mat-label>
        <input matInput type="email" email required [(ngModel)]="user.email" #email="ngModel" />
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>Prénom</mat-label>
        <input matInput type="text" required [(ngModel)]="user.firstName" #firstName="ngModel" />
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>Nom</mat-label>
        <input matInput type="text" required [(ngModel)]="user.lastName" #lastName="ngModel" />
    </mat-form-field>

    <mat-form-field
        class="w-100"
        *ngIf="!userCompany"
        [hintLabel]="userIdsByCompaniesNames ? 'Pour renseigner, passer par l\'écran de gestion des sociétés' : null"
    >
        <mat-label>Société</mat-label>
        <input matInput type="text" [(ngModel)]="user.company" [readonly]="userIdsByCompaniesNames" />
    </mat-form-field>

    <mat-form-field
        class="w-100"
        *ngIf="userCompany"
        hintLabel="Pour modifier, passer par l'écran de gestion des sociétés"
    >
        <mat-label>Société</mat-label>
        <input matInput type="text" [ngModel]="userCompany ? userCompany : user.company" [readonly]="true" />
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>Adresse</mat-label>
        <input matInput type="text" [(ngModel)]="user.address" />
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>Numéro de téléphone</mat-label>
        <input matInput type="text" [(ngModel)]="user.phone" />
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>Rôles</mat-label>
        <mat-select multiple required [(ngModel)]="user.authorities">
            <mat-option *ngFor="let authority of authorities$ | async" [value]="authority">{{
                authority | translateAuthority
            }}</mat-option>
        </mat-select>
    </mat-form-field>

    <div class="my-3">
        <button
            mat-raised-button
            color="accent"
            [disabled]="email.invalid || firstName.invalid || lastName.invalid || !user.authorities.length"
            (click)="save()"
        >
            <mat-icon>check</mat-icon>
            <span *ngIf="creationMode">Créer</span>
            <span *ngIf="!creationMode">Mettre à jour</span>
        </button>
        <button mat-button (click)="back()">
            <mat-icon>close</mat-icon>
            <span>Annuler</span>
        </button>
    </div>
</lib-form-container>
