'use strict';

import { fh_polygon } from '@acenv/fh-3d-viewer';
import { cn_building } from '../../model/cn_building';
import { cn_scene } from '../../model/cn_scene';
import { cn_storey } from '../../model/cn_storey';
import { cn_vertex } from '../../model/cn_vertex';
import { cn_wall, cn_wall_delegate } from '../../model/cn_wall';
import { cn_wall_type } from '../../model/cn_wall_type';
import { cn_add, cn_dist, cn_dot, cn_intersect_line, cn_middle, cn_mul, cn_normal, cn_normalize, cn_sub, cnx_clone } from '../cn_utilities';
import { cn_opening_type } from '../../model/cn_opening_type';
import { cn_opening } from '../../model/cn_opening';
import { cn_material } from '../../model/cn_material';
import { logger } from '../cn_logger';

//***********************************************************************************
//***********************************************************************************
//**** WISEBIM importer
//***********************************************************************************
//***********************************************************************************

export function cn_read_wisebim(wisebim_json) {
    var reader = new cn_wisebim_reader();
    reader.parse(wisebim_json);
    return reader._building;
}

export class cn_wisebim_reader {
    constructor() {
        this._building = null;
    }

    //***********************************************************************************
    //**** Main function
    //***********************************************************************************
    parse(wisebim) {
        this._building = new cn_building();
        this._building.storeys = [];

        try {
            wisebim.storeys.forEach(s => this._read_storey(s));
        } catch {
            console.error('parse error reading Wisebim file');
            this._building = null;
        }
    }

    /**
     * read a storey
     * @param {any} wb_storey
     */
    _read_storey(wb_storey) {
        const storey = new cn_storey(this._building);
        storey.storey_index = this._building.storeys.length;
        this._building.storeys.push(storey);
        //storey.ID = wb_storey.id;
        storey.name = wb_storey.name;

        const scene = new cn_scene(this._building);
        storey.scene = scene;
        scene.storey = storey;

        const wall_polygons = [];

        const wall_types = [];

        wb_storey.walls.filter(wb_wall => wb_wall.coordinates.length == 4).forEach(wb_wall => {
            const c0 = this._parse_point(wb_wall.coordinates[0]);
            const c1 = this._parse_point(wb_wall.coordinates[1]);
            const c2 = this._parse_point(wb_wall.coordinates[2]);
            const c3 = this._parse_point(wb_wall.coordinates[3]);
            const d1 = cn_dist(c0, c1);
            const d2 = cn_dist(c0, c3);
            var thickness = 0;
            var p = [[0, 0], [0, 0]];
            if (d1 > d2) {
                const nor = cn_normal(cn_sub(c1, c0));
                cn_normalize(nor);
                thickness = Math.abs(cn_dot(nor, c0) - cn_dot(nor, c3));
                p[0] = cn_middle(c0, c3);
                p[1] = cn_middle(c1, c2);
            } else {
                const nor = cn_normal(cn_sub(c3, c0));
                cn_normalize(nor);
                thickness = Math.abs(cn_dot(nor, c0) - cn_dot(nor, c1));
                p[0] = cn_middle(c0, c1);
                p[1] = cn_middle(c2, c3);
            }
            thickness = 0.5 * Math.round(100 * thickness / 0.5) / 100;

            var vertices = [null, null];
            for (var nv = 0; nv < 2; nv++) {
                const pp = p[nv];
                const vertex = new cn_vertex(pp);
                scene.vertices.push(vertex);
                vertices[nv] = vertex;
            }
            var wall_type = wall_types.find(wt => Math.abs(wt.thickness - thickness) < 0.005);
            if (!wall_type) {
                wall_type = new cn_wall_type();
                wall_type.thickness = thickness;
                wall_type.name = 'Mur ' + (0.1 * Math.round(thickness * 1000)).toFixed(1) + 'cm';
                wall_type.layers = [new cn_material('Inconnu', 'unknown', thickness)];
                this._building.element_types.push(wall_type);
                wall_types.push(wall_type);
            }
            const wall = new cn_wall(vertices[0], vertices[1], wall_type, 0, scene);
            wall.ID = wb_wall.id;
            scene.insert_wall(wall);

            const wall_pg = new fh_polygon([0, 0, 0], [0, 0, 1]);
            wall_pg.add_contour([c0, c1, c2, c3]);
            wall_pg['wall'] = wall;
            wall_pg['intersections'] = [[], [], []];
            wall_polygons.push(wall_pg);
        });

        scene.update_vertices(false);
        scene.update_walls();

        class wall_position {
            constructor(wall, position) {
                this.wall = wall;
                this.position = position;
            }
        }

        class wall_intersection {
            constructor() {
                this.point = [0, 0, 0];
                this.wall_positions = [];
            }

            merge(other) {
                logger.log('merge', this.point, other.point);
                this.wall_positions = this.wall_positions.concat(other.wall_positions);
                other.wall_positions = [];
            }
        }

        const wall_intersections = [];

        function find_intersection(wall, position) {
            if (position >= 2) return null;
            return wall_intersections.find(wi => wi.wall_positions.some(wp => wp.wall == wall && wp.position == position))
        }

        function analyse_intersection(pg, intersection) {
            const wall = pg['wall'];
            var xmin = wall.bounds.length;
            var xmax = 0;
            intersection.compute_contours();
            intersection.contour_vertices.forEach(v => {
                const x = cn_dot(wall.bounds.direction, cn_sub(v, wall.vertices[0].position));
                if (x < xmin) xmin = x;
                if (x > xmax) xmax = x;
            });
            if (intersection.contains(cnx_clone(wall.vertices[0].position)) || (xmin < wall.bounds.length / 2 && xmin < 0.1))
                return 0;
            if (intersection.contains(cnx_clone(wall.vertices[1].position)) || (xmax > wall.bounds.length / 2 && xmax > wall.bounds.length - 0.1))
                return 1;
            return 2;
        }

        //*** Compute intersections between walls */
        for (var npg = 0; npg < wall_polygons.length; npg++) {
            for (var npg1 = npg + 1; npg1 < wall_polygons.length; npg1++) {
                const pg = wall_polygons[npg].clone();
                pg.intersects(wall_polygons[npg1]);
                if (pg.get_area() > 0.0001) {
                    pg.offset(0.01);
                    const p0 = analyse_intersection(wall_polygons[npg], pg);
                    const p1 = analyse_intersection(wall_polygons[npg1], pg);
                    const w0 = wall_polygons[npg]['wall'];
                    const w1 = wall_polygons[npg1]['wall'];
                    const i0 = find_intersection(w0, p0);
                    const i1 = find_intersection(w1, p1);
                    if (i0 && i1) {
                        if (i0 != i1) i0.merge(i1);
                    } else if (i0)
                        i0.wall_positions.push(new wall_position(w1, p1));
                    else if (i1)
                        i1.wall_positions.push(new wall_position(w0, p0));
                    else {
                        const ii = new wall_intersection();
                        const box = pg.get_bounding_box();
                        ii.point = cn_add(box.position, cn_mul(box.size, 0.5));
                        ii.wall_positions.push(new wall_position(w1, p1));
                        ii.wall_positions.push(new wall_position(w0, p0));
                        wall_intersections.push(ii);
                    }
                }
            }
        }

        function compare_thickness(wp0, wp1) {
            return wp1.wall.wall_type.thickness - wp0.wall.wall_type.thickness;
        }

        logger.log('more than 2', wall_intersections.filter(wi => wi.wall_positions.length > 2));

        wall_intersections.forEach(wi => {
            logger.log(wi.point, wi.wall_positions.concat([]));
        });

        //*** We do something of the intersections */
        const lonely_vertices = [];
        wall_intersections.forEach(wi => {
            const end_connexions = wi.wall_positions.filter(wp => wp.position < 2);
            if (end_connexions.length > 1) {
                if (end_connexions.length > 2) {
                    end_connexions.sort(compare_thickness);
                }
                const w0 = end_connexions[0].wall;
                const v0 = w0.vertices[end_connexions[0].position];
                const w1 = end_connexions[1].wall;
                const v1 = w1.vertices[end_connexions[1].position];
                if (Math.abs(cn_dot(w0.bounds.direction, w1.bounds.direction)) > 0.95)
                    v0.position = cn_middle(v0.position, v1.position);
                else
                    v0.position = cn_intersect_line(v0.position, w0.bounds.direction, v1.position, w1.bounds.direction);
                scene.merge_vertices(v0, v1);
                for (var k = 2; k < end_connexions.length; k++) {
                    const ww = end_connexions[k].wall;
                    const p = end_connexions[k].position;
                    ww.delegates[p] = new cn_wall_delegate(end_connexions[k].wall.vertices[p].position, w0, 0);
                    ww.vertices[p] = v0;
                }
                scene.update_vertices(false);
                scene.update_walls();
            } else if (end_connexions.length == 1) {
                lonely_vertices.push(end_connexions[0].wall.vertices[end_connexions[0].position]);
            }
        });

        lonely_vertices.forEach(vertex => {
            const wall = vertex.walls[0];
            var max_distance = 0.1;
            var close_vertex = null;
            scene.vertices.forEach(v => {
                if (v != vertex && v.walls.length > 1) {
                    const dist = cn_dist(vertex.position, v.position);
                    if (dist < max_distance) {
                        close_vertex = v;
                        max_distance = dist;
                    }
                }
            });
            if (close_vertex) {
                const p = (wall.vertices[0] == vertex) ? 0 : 1;
                // @ts-ignore
                wall.delegates[p] = new cn_wall_delegate(vertex.position, close_vertex.walls[0], 0);
                wall.vertices[p] = close_vertex;
            } else {
                var wall_below = scene.walls.find(w => w != wall && w.contains(vertex.position) && Math.abs(cn_dot(w.bounds.direction, wall.bounds.direction)) < 0.99);
                if (wall_below) {
                    const pt = cn_intersect_line(wall_below.vertex_position(0), wall_below.bounds.direction, vertex.position, wall.bounds.direction);
                    if (pt) {
                        vertex.position = pt;
                        scene.split_wall(wall_below, vertex);
                    }
                }
            }
            scene.update_vertices(false);
            scene.update_walls();
        });

        scene.run_diagnostic();

        wb_storey.windows.forEach(o => this._read_opening(o, scene, 'window'));
        wb_storey.doors.forEach(o => this._read_opening(o, scene, 'door'));
    }

    _read_opening(wb_opening, scene, category) {

        var position = [0, 0];
        wb_opening.coordinates.forEach(c => position = cn_add(position, this._parse_point(c)));
        position = cn_mul(position, 1 / wb_opening.coordinates.length);
        const wall = scene.find_wall(position);
        if (!wall) return;

        const x = cn_dot(wall.bounds.direction, cn_sub(position, wall.vertex_position(0)));

        const z = 0.5 * Math.round(wb_opening.elevation * 100 / 0.5) / 100;
        const width = 0.5 * Math.round(wb_opening.length * 100 / 0.5) / 100;
        const height = 0.5 * Math.round(wb_opening.height * 100 / 0.5) / 100;

        var opening_type = this._building.get_opening_types().find(ot => ot.z == z && ot.width == width && ot.height == height && ot.category == category);
        if (!opening_type) {
            opening_type = (category == 'window') ? cn_opening_type.default_window() : cn_opening_type.default_door();
            opening_type.name = (category == 'window') ? 'Fenêtre' : 'Porte';
            opening_type.name += ' ' + (0.1 * Math.round(width * 1000)).toFixed(1) + 'x' + (0.1 * Math.round(height * 1000)).toFixed(1);
            if (category == 'window') opening_type.name += ' z=' + (0.1 * Math.round(z * 1000)).toFixed(1);
            opening_type.category = category;
            opening_type.z = z;
            opening_type.width = width;
            opening_type.height = height;
            opening_type.panels = (width > 1.2) ? 2 : 1;
            this._building.element_types.push(opening_type);
        }

        const opening = new cn_opening(opening_type);
        opening.position = x - width / 2;
        opening.wall = wall;
        wall.openings.push(opening);
    }

    _parse_point(p) {
        return [parseFloat(p.x), parseFloat(p.y), 0];
    }
}


