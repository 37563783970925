<mat-form-field (click)="yearPicker.open()">
    <mat-label>{{_label}}</mat-label>
    <input matInput [disabled]="readonlyMode" [required]="required" [matDatepicker]="yearPicker" [formControl]="ageInstallation" [max]="maxDate" />
    <mat-datepicker-toggle matSuffix [for]="yearPicker"></mat-datepicker-toggle>
    <mat-datepicker
        #yearPicker
        startView="multi-year"
        (yearSelected)="chosenYearHandler($event, yearPicker)"
        [calendarHeaderComponent]="customHeaderComponent"
        panelClass="yearPicker"
        [dateClass]="dateClass"
    >
    </mat-datepicker>
</mat-form-field>
