<h1 mat-dialog-title class="epi-title">{{ titreModal }}</h1>

<div mat-dialog-content>
    <div>
        <div [innerHTML]="pdfSrc"></div>
    </div>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-raised-button (click)="confirm()">
        <span>{{ 'Fermer' | uppercase }}</span>
    </button>
</div>
