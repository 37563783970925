import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarkdownService } from './markdown.service';
import { MarkdownComponent } from './markdown.component';
import { MatCardModule } from '@angular/material/card';
import { LibFormContainerModule } from '../form-container/form-container.module';

@NgModule({
    imports: [CommonModule, MatCardModule, LibFormContainerModule],
    exports: [MarkdownComponent],
    declarations: [MarkdownComponent],
})
export class LibMarkdownModule {
    static forRoot(): ModuleWithProviders<LibMarkdownModule> {
        return {
            ngModule: LibMarkdownModule,
            providers: [MarkdownService],
        };
    }
}
