<div class="toolbar">
    <mat-toolbar class="border-bottom-1 primary">
        <button mat-button class="px-0" (click)="back()">
            <lib-icon>arrow_back</lib-icon>
        </button>
        <div>
            <lib-icon [class]="'icon-before-text'">settings</lib-icon>
            <span *ngIf="mode === 'CREATE' && !isReadOnly">Création d'un type d'ouvrage</span>
            <span *ngIf="mode === 'EDIT' && !isReadOnly">Edition du type d'ouvrage</span>
            <span *ngIf="mode === 'DUPLICATE' && !isReadOnly">Duplication du type d'ouvrage</span>
            <span *ngIf="isReadOnly">Consultation du type d'ouvrage</span>
        </div>

        <div class="d-flex justify-content-end w-100">
            <button mat-button color="primary" class="mx-1" (click)="back()">
                <mat-icon>close</mat-icon>
                <span>{{ 'Annuler' | uppercase }}</span>
            </button>
            <button
                *ngIf="!isReadOnly"
                mat-raised-button
                color="primary"
                [disabled]="formTypeOuvrage.invalid"
                (click)="validerTypeOuvrage()"
            >
                <lib-icon>check</lib-icon>
                <span>{{ 'Valider' | uppercase }}</span>
            </button>
        </div>
    </mat-toolbar>
</div>

<div class="equipement-container">
    <div class="h-100 row no-gutters p-4 scrollable-content" [formGroup]="formTypeOuvrage">
        <!-- Colonne gauche-->
        <div class="col-xl-6 pr-2">
            <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="col-xl-12 p-2 m-0 primary">Informations générales</div>
                    <!-- Nom de l'equipement -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Nom du type d'ouvrage</div>
                        <div class="col-xl-8 pr-2">
                            <mat-form-field class="w-100">
                                <input matInput [readonly]="isReadOnly" type="text" formControlName="name" />
                                <mat-error *ngIf="formTypeOuvrage.get('name').hasError('contactExistant')"
                                    >Un autre type d'ouvrage possède déjà le même nom.
                                </mat-error>
                                <app-mat-error [control]="formTypeOuvrage.get('name')"></app-mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

        <!--Colonne droite-->
        <div class="col-xl-6 pl-2">
            <!-- Equipement Parent -->
            <mat-card class="p-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="col-xl-12 p-2 m-0 primary">Réglages</div>
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary">Type d'ouvrage parent</div>
                        <div class="col-xl-8">
                            <mat-form-field class="w-100" [class.mat-input-readonly]="isReadOnly">
                                <mat-select
                                    [disabled]="isReadOnly"
                                    [class.readonly]="isReadOnly"
                                    placeholder="Choisir un type d'ouvrage parent"
                                    formControlName="parentsOuvrage"
                                >
                                    <mat-option [value]=""></mat-option>
                                    <mat-option *ngFor="let c of listeFullTypeOuvrageParent" [value]="c">
                                        {{ c.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="formTypeOuvrage.get('parentsOuvrage').hasError('contactExistant')"
                                    >Un autre type d'ouvrage possède déjà le même nom.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
