import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MpcaItemOld } from '../../model/mpca.model.old';
import { WidgetSelectModeEnum } from '../../../../shared/widget-select/widget-select.component';

@Component({
    selector: 'app-mpca-list-select',
    templateUrl: './mpca-list-select.component.html',
    styleUrls: ['./mpca-list-select.component.scss'],
})
export class MpcaListSelectComponent {
    widgetSelectModeEnum = WidgetSelectModeEnum;
    @Input()
    mpcaList: MpcaItemOld[] = [];

    @Output()
    add = new EventEmitter<string>();

    @Output()
    delete = new EventEmitter<string>();

    @Output()
    retirer = new EventEmitter<string>();

    @Output()
    encapsuler = new EventEmitter<string>();

    @Input()
    selectedMpcaList = [];
    // selectedMpcaList = [];

    @Input()
    mandatory: Boolean = false;

    @Input()
    isInEditMode: Boolean = true;

    constructor() {}

    /**
     * Fonction utilisée pour déterminer si un mpca est déjà sélectionné en fonction de son ID
     * @param mpcaId
     * @returns
     */
    mpcaIsAlreadySelected(mpcaId: string): Boolean {
        return this.selectedMpcaList.find((m) => m.id == mpcaId) != undefined;
    }

    addItem(mpcaId: string) {
        this.add.emit(mpcaId);
    }

    remove(mpcaId: string) {
        this.delete.emit(mpcaId);
    }
}
