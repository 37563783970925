import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Assurance } from '../../../../../../model/agence.model';
import { ConfirmationService, NotificationService } from 'src/app/commons-lib';
import { AgenceService } from '../../../../../../services/agence.service';
import * as moment from 'moment';

export interface DataAssuranceModal {
    assurance: Assurance;
}

@Component({
    selector: 'app-creation-assurance',
    templateUrl: './creation-assurance-modal.component.html',
    styleUrls: ['./creation-assurance-modal.component.scss'],
})
export class CreationAssuranceModalComponent implements OnInit {
    assuranceForm: FormGroup;

    justificatifFormData: FormData;

    get justificatifControl() {
        return this.assuranceForm.get('justificatif') as FormControl;
    }

    private assurance: Assurance;
    readonly codeTypeDocument = 'ASSURANCE';
    readonly documentTitle = 'Assurance';
    readonly labelDocument = 'Justificatif';

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly dialogRef: MatDialogRef<CreationAssuranceModalComponent>,
        private readonly confirmationService: ConfirmationService,
        private readonly notificationService: NotificationService,
        private readonly agenceService: AgenceService,
        @Inject(MAT_DIALOG_DATA) public data: DataAssuranceModal
    ) {
        this.dialogRef.addPanelClass('minwidth700-dialog');
        this.initData();
        this.createForm();
    }

    ngOnInit(): void {
        this.populateForm(this.assurance);
    }

    /**
     * Initialise les données
     */
    initData(): void {
        if (this.data.assurance) {
            this.assurance = this.data.assurance;
        } else {
            this.assurance = new Assurance();
        }
    }

    /**
     * Crée le formulaire
     */
    createForm(): void {
        this.assuranceForm = this.formBuilder.group({
            compagnie: ['', [Validators.required]],
            numeroPolice: ['', [Validators.required]],
            dateDebutValidite: ['', [Validators.required]],
            dateEcheance: ['', [Validators.required]],
            justificatif: [null, [Validators.required]],
        });
    }

    /**
     * Rempli le formulaire avec les données assurances
     * @param assurance
     */
    populateForm(assurance: Assurance): void {
        this.assuranceForm.patchValue({
            compagnie: assurance.compagnie ? assurance.compagnie : '',
            numeroPolice: assurance.numeroPolice ? assurance.numeroPolice : '',
            dateDebutValidite: assurance.dateDebutValidite ? assurance.dateDebutValidite : '',
            dateEcheance: assurance.dateEcheance ? assurance.dateEcheance : '',
            justificatif: assurance.justificatifDocument ? assurance.justificatifDocument : null,
        });
    }

    /**
     * Prépare les données à sauvegarder
     */
    prepareSaveEntity(): Assurance {
        const assurance = new Assurance();
        const formValues = this.assuranceForm.value;
        assurance.compagnie = formValues.compagnie;
        assurance.numeroPolice = formValues.numeroPolice;
        assurance.dateDebutValidite = moment(formValues.dateDebutValidite).format('YYYY-MM-DD');
        assurance.dateEcheance = moment(formValues.dateEcheance).format('YYYY-MM-DD');
        assurance.justificatifDocument = formValues.justificatif;

        return assurance;
    }

    /**
     * Action quand l'utilisateur clique sur le bouton Annuler
     */
    onClickBtnCancel(): void {
        this.dialogRef.close();
    }

    /**
     * Action pour préparer les données et envoyer le formulaire
     */
    onSubmitForm(): void {
        if (this.assuranceForm.valid) {
            this.dialogRef.close(this.prepareSaveEntity());
        }
    }
}
