import * as moment from 'moment';
import { Moment } from 'moment';

export class DateUtils {
    /**
     * Renvoie un moment formaté en ISO sans son information sa timezone.
     * À utiliser pour générer une date à mapper sur le type Java LocalDateTime (ou LocalDate ou encore LocalTime).
     *
     * @param dateParam Moment, optionnel, si null alors on utilise l'instant courant.
     */
    static localDateTimeString(dateParam: Moment = null): string {
        const date: Moment = dateParam ? dateParam : moment();
        return date.local().format('YYYY-MM-DDTHH:mm:ss');
    }

    /**
     * Renvoie un moment formaté en ISO avec sa timezone.
     * À utiliser pour générer une date à mapper sur le type Java ZonedDateTime.
     *
     * @param dateParam Moment, optionnel, si null alors on utilise l'instant courant.
     * @param utc Si utc = true, le moment est affiché à UTC, sinon le moment est affiché avec la locale du navigateur.
     */
    static zonedDateTimeString(dateParam: Moment = null, utc = false): string {
        const date: Moment = dateParam ? dateParam : moment();
        return utc ? date.utc().format() : date.local().format();
    }
}
