<div class="col-12 d-flex align-items-center no-padding">
    <mat-form-field class="flex-grow-1 w-100">
        <input
            matInput
            type="text"
            placeholder="Recherche d'une zone existante"
            aria-label="Rechercher"
            (click)="clearInput()"
            [formControl]="optionControl"
            [matAutocomplete]="autoSurface"
            class="col-12"
        />
        <mat-icon matPrefix class="my-icon">search</mat-icon>
        <mat-autocomplete
            #autoSurface="matAutocomplete"
            (optionSelected)="onSelectionChange($event)"
            [displayWith]="displayWithZoneName"
        >
            <mat-option
                *ngFor="let option of filteredOptions | async"
                [value]="option"
                [matTooltip]="option | zoneFullnamePipe"
            >
                {{ option | zoneFullnamePipe }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>
