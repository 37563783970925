<div class="container">
    <div class="row">
        <form [formGroup]="formGroup" class="offset-2 col-8 mt-4">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Saisir les modalités d'accès et de circulation</mat-label>
                <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="30" formControlName="modalites"></textarea>
            </mat-form-field>
        </form>
    </div>
</div>
