import { Component, Input, OnInit } from '@angular/core';
import { Intervention } from 'src/app/model/intervention.model';
import { InterventionReportData, IReportComponent, ReportBlockData } from '../../../../../model/rapport.model';
import { DiagnosticService } from '../../../../../services/diagnostic.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/commons-lib';

@Component({
    selector: 'app-header-block',
    templateUrl: './header-block.component.html',
    styleUrls: ['./header-block.component.scss'],
})
export class HeaderBlockComponent extends BaseComponent implements OnInit, IReportComponent {
    @Input() data: ReportBlockData;
    @Input() intervention: Intervention;
    @Input() interReportData: InterventionReportData;

    subTitle: string;
    numDossier: string;
    conforme: boolean;
    messages = [];
    conformeValeur = {
        text: '',
        class: '',
    };

    constructor(private readonly diagnosticService: DiagnosticService) {
        super();
    }

    ngOnInit(): void {
        if (this.data) {
            if (this.data.values['articles']) {
                this.subTitle = this.data.values['articles'];
            }

            if (this.data.values['messages']) {
                this.messages = this.data.values['messages'];
            }
        }
        if (this.interReportData) {
            this.diagnosticService
                .getCurrentDiagnostic()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((diagnostic) => {
                    this.conforme = this.interReportData.conformiteGlobale;
                    this.numDossier = this.interReportData.dossierNumber;
                    if (this.conforme) {
                        this.conformeValeur.text = 'conformes';
                        this.conformeValeur.class = 'conforme';
                    } else if (this.conforme === false) {
                        this.conformeValeur.text = 'non conformes';
                        this.conformeValeur.class = 'non-conforme';
                    }
                });
        }
    }
}
