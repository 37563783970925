import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { combineLatest, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { ReferenceService } from '../../../../services/reference.service';
import { CnSpinnerService } from '../../../shared/cn-spinner/service/cn-spinner.service';
import { TypeVolume } from '../../../../model/type-element-a-controler.model';
import { TypeBien } from '../../../../model/type-bien.model';
import { Bien, Niveau, Volume } from '../../../../model/bien.model';
import { EtatWorkflow } from '../../../../model/etat-workflow.model';
import {
    EquipementsOuvragesModalData,
    INDEXES_TABS_MODAL_EQUIPEMENTS_OUVRAGES,
} from '../description-bien-modal-equipements-ouvrages/description-bien-modal-equipements-ouvrages.component';
import {
    CODE_BIM_PARAM_ESPACE_HUMIDE,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE,
    PARAM_VOLUME_LOT,
} from '../../../../shared/constants/cndiag.constants';
import { DescriptionBienService, isANumber } from '../../../../services/description-bien.service';

@Component({
    selector: 'app-description-bien-liste-volumes',
    templateUrl: './description-bien-liste-volumes.component.html',
    styleUrls: ['./description-bien-liste-volumes.component.scss'],
})
export class DescriptionBienListeVolumesComponent extends BaseComponent implements OnInit {
    @Input()
    readonlyMode: boolean = false;

    @Input()
    currentBien: Bien;

    @Input()
    currentNiveau: Niveau;

    @Input()
    descriptionDirecte: boolean;

    @Output()
    currentVolumeChange: EventEmitter<Volume> = new EventEmitter<Volume>();

    @Output()
    openDialogEquipementOuvrage: EventEmitter<EquipementsOuvragesModalData> = new EventEmitter<EquipementsOuvragesModalData>();

    listTypeVolumes: TypeVolume[];
    listTypeVolumesToDisplay: TypeVolume[];

    searchVolume: string;

    listTypesBien: TypeBien[];
    selectedTypesBien: string[] = [];

    constructor(
        private readonly descriptionBienService: DescriptionBienService,
        private readonly referenceService: ReferenceService,
        private readonly cnSpinnerService: CnSpinnerService
    ) {
        super();
    }

    ngOnInit() {
        this.cnSpinnerService
            .withSpinner(
                combineLatest([this.referenceService.findAllTypesVolumes(), this.referenceService.findAllTypesBien()]).pipe(
                    takeUntil(this.ngUnsubscribe),
                    switchMap(([pieces, typesBien]) => {
                        return combineLatest([of(pieces), of(typesBien)]);
                    })
                )
            )
            .subscribe(([pieces, typesBien]) => {
                this.listTypeVolumes = pieces
                    .filter((piece) => piece.etatVolume === EtatWorkflow.ACTIF)
                    .sort((a, b) => this.compareTypeVolumes(a, b));

                this.listTypesBien = typesBien;

                this.selectedTypesBien.push(this.listTypesBien.find((it) => it.id === this.currentBien.idTypeBien).id);
                this.filterTypeVolumes();
            });
    }

    private compareTypeVolumes(a: TypeVolume, b: TypeVolume): number {
        const ordreA = a.ordre ?? Infinity;
        const ordreB = b.ordre ?? Infinity;

        return ordreA !== ordreB ? ordreA - ordreB : a.nom.localeCompare(b.nom);
    }

    handleAjouterVolume(typeVolume: TypeVolume) {
        const volumeResult = new Volume();

        volumeResult.nomBase = typeVolume.nom;

        const similarVolume = this.currentNiveau.volumes.filter((volume) => volume.nomBase === volumeResult.nomBase);

        if (similarVolume.length > 0) {
            volumeResult.numerotationDebut = similarVolume[0].numerotationDebut;
            volumeResult.prefixeNumerotation = similarVolume[0].prefixeNumerotation;
            const numerotationMax = similarVolume.map((volume) => volume.numerotation).reduce((a, b) => Math.max(a, b));
            volumeResult.numerotation = numerotationMax + 1;
        } else {
            volumeResult.numerotationDebut = 1;
            volumeResult.prefixeNumerotation = '';
            volumeResult.numerotation = 0;
        }

        const indexEspaceMax =
            this.currentNiveau.volumes
                .filter((it) => isANumber(it.indexEspace))
                .map((volume) => volume.indexEspace)
                .reduce((a, b) => Math.max(a, b), 0) ?? 0;
        volumeResult.indexEspace = indexEspaceMax + 1;

        volumeResult.nom = this.descriptionBienService.generateVolumeName(volumeResult, this.currentNiveau);

        volumeResult.usageId = typeVolume.id;
        volumeResult.valeursParametres = {
            [CODE_BIM_PARAM_ESPACE_HUMIDE]: typeVolume.valeursDefautParametres[CODE_BIM_PARAM_ESPACE_HUMIDE],
            [CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ]: typeVolume.valeursDefautParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ],
            [CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE]: typeVolume.valeursDefautParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE],
            [CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE]: typeVolume.valeursDefautParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE],
        };
        this.currentNiveau.volumes.push(volumeResult);
        this.currentNiveau.volumes.forEach((vol) => (vol.nom = this.descriptionBienService.generateVolumeName(vol, this.currentNiveau)));
        if (this.descriptionDirecte) {
            this.currentVolumeChange.emit(volumeResult);
            this.openDialogEquipementOuvrage.emit({ tabToOpen: INDEXES_TABS_MODAL_EQUIPEMENTS_OUVRAGES.OUVRAGES });
        }
    }

    handleSelectedTypesBienChange() {
        this.filterTypeVolumes();
    }

    onKeyupSearchVolume($event) {
        this.filterTypeVolumes();
    }

    clearSearchVolume() {
        this.searchVolume = null;
        this.filterTypeVolumes();
    }

    private filterTypeVolumes() {
        this.listTypeVolumesToDisplay = this.listTypeVolumes.filter(
            (it) =>
                (!this.searchVolume || it.nom.toLowerCase().includes(this.searchVolume.toLowerCase())) &&
                (this.selectedTypesBien.length === 0 || this.selectedTypesBien.some((typeBien) => it.typesBienAssocies.includes(typeBien)))
        );
    }
}
