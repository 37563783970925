<div class="d-flex flex-column h-100 checkpoint-content" *ngIf="currentDiagnostic">
    <app-progress-diagnostic></app-progress-diagnostic>
    <!-- Message affiché en cas d'absence de volume dans le bien-->
    <div *ngIf="noDescription" class="w-100 d-flex align-items-center mt-4 justify-content-center">
        <span class="mat-h2 mb-0 ml-2 primary"> Il n'y a aucun volume à mesurer dans ce bien. </span>
    </div>

    <div class="d-flex flex-grow-1 flex-row w-100">
        <mat-sidenav-container *ngIf="!noDescription" class="sidenav-container">
            <mat-sidenav [mode]="'side'" [opened]="true" class="sidebar-button">
                <div class="d-flex flex-column w-100 justify-content-end">
                    <mat-button-toggle-group [vertical]="true" (change)="changeViewMode($event)" [value]="viewMode" appearance="legacy">
                        <mat-button-toggle matTooltip="Vue liste" value="list">
                            <mat-icon>list</mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle matTooltip="Vue plan" value="plan">
                            <mat-icon>layers</mat-icon>
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                    <mat-divider></mat-divider>
                </div>
            </mat-sidenav>
            <mat-sidenav-content class="main" [ngClass]="pagesPanelOpened ? 'main' : 'main-fullscreen'">
                <ng-container *ngIf="viewMode === 'list'">
                    <ng-container *ngTemplateOutlet="displayList"></ng-container>
                </ng-container>

                <ng-container *ngIf="viewMode === 'plan'">
                    <ng-container *ngTemplateOutlet="displayMap"></ng-container>
                </ng-container>
            </mat-sidenav-content>
        </mat-sidenav-container>

        <!-- Partie de gauche -->
        <ng-template #displayList>
            <div class="h-100 border-right p-0" *ngIf="selection">
                <app-bien-niveau-select
                    [biens]="biens"
                    [selectedBien]="selection.currentBien.nomBien"
                    (bienChanged)="changeCurrentBien($event)"
                    [selectedNiveau]="currentNiveau"
                    (niveauChanged)="changeCurrentNiveau($event.id)"
                ></app-bien-niveau-select>

                <!-- Tableau de volumes -->
                <div *ngIf="!noDescription && currentNiveau" class="mesurage-volumes-list">
                    <table [dataSource]="currentNiveau.pointsDeControleVolumes" class="mat-elevation-z8" mat-table>
                        <ng-container matColumnDef="visite">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let volume">
                                <lib-icon *ngIf="volume?.valeursParametres[PARAM_VOLUME_VISITE] === undefined"> lock </lib-icon>
                            </td>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>

                        <ng-container matColumnDef="nom">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let volume">
                                <b
                                    >{{ volume?.nom }}
                                    {{
                                        volume?.valeursParametres[PARAM_VOLUME_LOT] ? '(Lot ' + volume?.valeursParametres[PARAM_VOLUME_LOT] + ')' : ''
                                    }}</b
                                >
                            </td>
                            <td mat-footer-cell *matFooterCellDef>
                                <div>
                                    <div class="mb-4"><b>Total</b> {{ currentNiveau?.nom }}</div>
                                    <div><b>Total</b> {{ selection.currentBien?.nomBien }}</div>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container [matColumnDef]="mesurageConfig.propertiesMesurage.displayedColumns[2]">
                            <th mat-header-cell *matHeaderCellDef>
                                <div class="{{ mesurageConfig.propertiesMesurage.listeMesuresCss[0] }}">
                                    {{ mesurageConfig.propertiesMesurage.displayedColumns[2] | titlecase }}
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let volume">
                                <div
                                    class="mesure-cell {{ mesurageConfig.propertiesMesurage.listeMesuresCss[0] }}"
                                    [innerHTML]="
                                        volume.valeursParametres[mesurageConfig.propertiesMesurage.listeMesures[0]]
                                            ? (volume.valeursParametres[mesurageConfig.propertiesMesurage.listeMesures[0]]
                                              | number: '1.2-2'
                                              | mesure
                                              | safeHtml)
                                            : MESURE_NON_COMMUNIQUE
                                    "
                                ></div>
                            </td>
                            <td mat-footer-cell *matFooterCellDef>
                                <div
                                    class="mesure-cell {{ mesurageConfig.propertiesMesurage.listeMesuresCss[0] }}"
                                    [innerHTML]="
                                        currentNiveau.valeursParametres[mesurageConfig.propertiesMesurage.listeMesuresTotales[0]]
                                            ? (currentNiveau.valeursParametres[mesurageConfig.propertiesMesurage.listeMesuresTotales[0]]
                                              | number: '1.2-2'
                                              | mesure
                                              | safeHtml)
                                            : MESURE_NON_COMMUNIQUE
                                    "
                                ></div>
                                <br />
                                <div
                                    class="mesure-cell {{ mesurageConfig.propertiesMesurage.listeMesuresCss[0] }}"
                                    [innerHTML]="
                                        selection.currentBien.valeursParametres[mesurageConfig.propertiesMesurage.listeMesuresTotales[0]]
                                            ? (selection.currentBien.valeursParametres[mesurageConfig.propertiesMesurage.listeMesuresTotales[0]]
                                              | number: '1.2-2'
                                              | mesure
                                              | safeHtml)
                                            : MESURE_NON_COMMUNIQUE
                                    "
                                ></div>
                            </td>
                        </ng-container>

                        <ng-container [matColumnDef]="mesurageConfig.propertiesMesurage.displayedColumns[3]">
                            <th mat-header-cell *matHeaderCellDef>
                                <div class="{{ mesurageConfig.propertiesMesurage.listeMesuresCss[1] }}">
                                    {{ mesurageConfig.propertiesMesurage.displayedColumns[3] | titlecase }}
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let volume">
                                <div
                                    class="mesure-cell {{ mesurageConfig.propertiesMesurage.listeMesuresCss[1] }}"
                                    [innerHTML]="
                                        volume.valeursParametres[mesurageConfig.propertiesMesurage.listeMesures[1]]
                                            ? (volume.valeursParametres[mesurageConfig.propertiesMesurage.listeMesures[1]]
                                              | number: '1.2-2'
                                              | mesure
                                              | safeHtml)
                                            : MESURE_NON_COMMUNIQUE
                                    "
                                ></div>
                            </td>
                            <td mat-footer-cell *matFooterCellDef>
                                <div
                                    class="mesure-cell {{ mesurageConfig.propertiesMesurage.listeMesuresCss[1] }}"
                                    [innerHTML]="
                                        currentNiveau.valeursParametres[mesurageConfig.propertiesMesurage.listeMesuresTotales[1]]
                                            ? (currentNiveau.valeursParametres[mesurageConfig.propertiesMesurage.listeMesuresTotales[1]]
                                              | number: '1.2-2'
                                              | mesure
                                              | safeHtml)
                                            : MESURE_NON_COMMUNIQUE
                                    "
                                ></div>
                                <br />
                                <div
                                    class="mesure-cell {{ mesurageConfig.propertiesMesurage.listeMesuresCss[1] }}"
                                    [innerHTML]="
                                        selection.currentBien.valeursParametres[mesurageConfig.propertiesMesurage.listeMesuresTotales[1]]
                                            ? (selection.currentBien.valeursParametres[mesurageConfig.propertiesMesurage.listeMesuresTotales[1]]
                                              | number: '1.2-2'
                                              | mesure
                                              | safeHtml)
                                            : MESURE_NON_COMMUNIQUE
                                    "
                                ></div>
                            </td>
                        </ng-container>

                        <ng-container [matColumnDef]="mesurageConfig.propertiesMesurage.displayedColumns[4]">
                            <th mat-header-cell *matHeaderCellDef>
                                <div class="{{ mesurageConfig.propertiesMesurage.listeMesuresCss[2] }}">
                                    {{ mesurageConfig.propertiesMesurage.displayedColumns[4] | titlecase }}
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let volume">
                                <div
                                    class="mesure-cell {{ mesurageConfig.propertiesMesurage.listeMesuresCss[2] }}"
                                    [innerHTML]="
                                        volume.valeursParametres[mesurageConfig.propertiesMesurage.listeMesures[2]]
                                            ? (volume.valeursParametres[mesurageConfig.propertiesMesurage.listeMesures[2]]
                                              | number: '1.2-2'
                                              | mesure
                                              | safeHtml)
                                            : MESURE_NON_COMMUNIQUE
                                    "
                                ></div>
                            </td>
                            <td mat-footer-cell *matFooterCellDef>
                                <div
                                    class="mesure-cell {{ mesurageConfig.propertiesMesurage.listeMesuresCss[2] }}"
                                    [innerHTML]="
                                        currentNiveau.valeursParametres[mesurageConfig.propertiesMesurage.listeMesuresTotales[2]]
                                            ? (currentNiveau.valeursParametres[mesurageConfig.propertiesMesurage.listeMesuresTotales[2]]
                                              | number: '1.2-2'
                                              | mesure
                                              | safeHtml)
                                            : MESURE_NON_COMMUNIQUE
                                    "
                                ></div>
                                <br />
                                <div
                                    class="mesure-cell {{ mesurageConfig.propertiesMesurage.listeMesuresCss[2] }}"
                                    [innerHTML]="
                                        selection.currentBien.valeursParametres[mesurageConfig.propertiesMesurage.listeMesuresTotales[2]]
                                            ? (selection.currentBien.valeursParametres[mesurageConfig.propertiesMesurage.listeMesuresTotales[2]]
                                              | number: '1.2-2'
                                              | mesure
                                              | safeHtml)
                                            : MESURE_NON_COMMUNIQUE
                                    "
                                ></div>
                            </td>
                        </ng-container>

                        <tr *matHeaderRowDef="mesurageConfig.propertiesMesurage.displayedColumns; sticky: true" mat-header-row></tr>
                        <tr
                            *matRowDef="let row; columns: mesurageConfig.propertiesMesurage.displayedColumns"
                            mat-row
                            [ngClass]="{ highlight: currentPointDeControleVolume?.idVolume == row.idVolume }"
                            (click)="!editZone && changeCurrentVolume(row)"
                        ></tr>
                        <tr mat-footer-row *matFooterRowDef="mesurageConfig.propertiesMesurage.displayedColumns; sticky: true"></tr>
                    </table>
                </div>
            </div>
        </ng-template>

        <ng-template #displayMap class="sidebar-map">
            <ng-container *ngIf="selection">
                <app-bien-niveau-select
                    [biens]="biens"
                    [selectedBien]="selection.currentBien.nomBien"
                    (bienChanged)="changeCurrentBien($event)"
                    [selectedNiveau]="currentNiveau"
                    (niveauChanged)="changeCurrentNiveau($event.id)"
                    [forPlan]="false"
                ></app-bien-niveau-select>
                <div *ngIf="!selection.bienBuilding">
                    <mat-card class="w-100 drop-card-placeholder d-flex justify-content-center align-items-center mb-2 shadow-none">
                        Pas de plan disponible
                    </mat-card>
                </div>
                <app-viewer-map
                    *ngIf="selection.bienBuilding"
                    #viewMap
                    [building]="selection.bienBuilding"
                    [currentBien]="selection.currentRelationBien"
                    [storeyIdToDisplay]="storeyIdToDisplayInMap"
                    [formPointDeControl]="selection.currentBien"
                    [useDiagTool]="true"
                    [cnMapConfig]="cnMapConfig"
                    [viewerMapConfig]="viewerMapConfig"
                    [externalSelectedVolume]="currentVolumeForMap"
                    (toolEvent)="viewMapEvent($event)"
                ></app-viewer-map>
            </ng-container>
        </ng-template>
        <!-- Partie de droite -->
        <div class="col-xl-6 h-100 p-0">
            <div class="row no-gutters w-100" *ngIf="(viewMode === 'plan' && selection.bienBuilding) || viewMode === 'list'">
                <div class="w-100 p-2">
                    <div *ngIf="currentPointDeControleVolume?.nom" class="w-100">
                        <div class="d-flex flex-row justify-content-between align-items-baseline">
                            <div class="d-flex flex-row align-items-center">
                                <button
                                    mat-icon-button
                                    color="primary"
                                    class="ml-1 btn-primay"
                                    (click)="nextPrevVolume('prev')"
                                    [disabled]="prevVolumeIsDisabled"
                                >
                                    <lib-icon iconName="arrow-left-bold-circle" [theme]="'mdi'"></lib-icon>
                                </button>

                                <mat-form-field class="volume-select-container" styles="width : 300px;" appearance="standard">
                                    <mat-select
                                        [value]="currentPointDeControleVolume"
                                        (selectionChange)="changeCurrentVolume($event.value)"
                                        [compareWith]="attributeDisplay"
                                    >
                                        <mat-option *ngFor="let vol of currentNiveau.pointsDeControleVolumes" [value]="vol">
                                            {{ vol.nom }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <button
                                    mat-icon-button
                                    color="primary"
                                    class="ml-1 btn-primay"
                                    (click)="nextPrevVolume('next')"
                                    [disabled]="nextVolumeIsDisabled"
                                >
                                    <lib-icon iconName="arrow-right-bold-circle" [theme]="'mdi'"></lib-icon>
                                </button>
                            </div>
                            <div class="d-flex flex-row align-items-center">
                                <b>{{ 'Visitée' | uppercase }}</b>
                                <app-state-input
                                    class="right-separation"
                                    [choices]="[validatedChoiceOption, warningChoiceOption, notValidatedChoiceOption]"
                                    [tooltips]="tooltipsVisite"
                                    [disabled]="readonlyMode"
                                    [(choiceValue)]="currentPointDeControleVolume?.valeursParametres[PARAM_VOLUME_VISITE]"
                                    (valueChangeFromUser)="currentVisiteChanged($event)"
                                >
                                </app-state-input>
                                <app-notes-buttons
                                    class="ml-2"
                                    [from]="currentVolumeForMap"
                                    [diagnostic]="currentDiagnostic"
                                    [typesCommentaires]="manqueJustificatifNonVisite ? typeCommentaireJustifNonVisite : []"
                                    (commentChanged)="currentVolumeCommentChanged(currentVolumeForMap)"
                                >
                                </app-notes-buttons>
                            </div>
                        </div>
                        <div *ngIf="manqueJustificatifNonVisite" class="to-justify">
                            Il manque une justification de non visite
                            <button mat-icon-button color="justify" class="ml-1" (click)="addJustificationNonVisite(currentVolumeForMap)">
                                <lib-icon theme="mdi" iconName="comment-alert"></lib-icon>
                            </button>
                        </div>
                        <div>
                            <ng-container *ngIf="mesurageConfig.typeMesurage === TypePrestationEnum.MESURAGE">
                                <mat-checkbox
                                    [(ngModel)]="currentPointDeControleVolume?.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ]"
                                    [disabled]="readonlyMode"
                                    (change)="isRepute()"
                                >
                                    Non réputé loi Carrez
                                </mat-checkbox>
                                <mat-checkbox
                                    *ngIf="currentVolumeComplementaire"
                                    [(ngModel)]="currentVolumeComplementaire?.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE]"
                                    disabled="true"
                                >
                                    Non réputé habitable
                                </mat-checkbox>
                            </ng-container>
                            <ng-container *ngIf="mesurageConfig.typeMesurage === TypePrestationEnum.MESURAGE_HABITABLE">
                                <mat-checkbox
                                    [(ngModel)]="currentPointDeControleVolume?.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE]"
                                    [disabled]="readonlyMode"
                                    (change)="isRepute()"
                                >
                                    Non réputé habitable
                                </mat-checkbox>
                                <mat-checkbox
                                    *ngIf="currentVolumeComplementaire"
                                    [(ngModel)]="currentVolumeComplementaire?.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ]"
                                    disabled="true"
                                >
                                    Non réputé loi Carrez
                                </mat-checkbox>
                            </ng-container>
                            <ng-container *ngIf="mesurageConfig.typeMesurage === TypePrestationEnum.MESURAGE_UTILE">
                                <mat-checkbox
                                    [(ngModel)]="currentPointDeControleVolume?.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE]"
                                    [disabled]="readonlyMode"
                                    (change)="isRepute()"
                                >
                                    Non réputé utile
                                </mat-checkbox>
                            </ng-container>
                        </div>
                        <div *ngIf="hasNegativeMesure" class="to-justify mb-2">
                            Au moins une des valeurs est négative, corrigez les surfaces concernées
                        </div>
                        <div *ngIf="currentPointDeControleVolume?.valeursParametres[PARAM_MESURES_INCOHERENTES]" class="to-justify mb-2">
                            Les données ne sont pas cohérentes, corrigez les surfaces concernées
                        </div>
                        <div>
                            <mat-tab-group animationDuration="0ms" class="mesure-tab-group" (selectedTabChange)="tabChanged()">
                                <mat-tab label="MESURAGE SIMPLE">
                                    <!-- Formulaire-->
                                    <div class="p-2 mt-2">
                                        <!--  L'ordre des sections de mesures est différent selon le type de mesurage-->
                                        <ng-container *ngIf="!mesurageConfig.propertiesMesurage.isMesurageUtile">
                                            <app-section-mesure
                                                [volume]="currentPointDeControleVolume"
                                                [mesure]="mesurageConfig.propertiesMesurage.listeMesures[2]"
                                                [diagnostic]="currentDiagnostic"
                                                [readonlyMode]="readonlyMode"
                                                [label]="mesurageConfig.propertiesMesurage.listeMesuresLabels[2]"
                                                [isMesurageUtile]="mesurageConfig.propertiesMesurage.isMesurageUtile"
                                                [mesureSuggested]="mesureSuggested"
                                                (mesureChangedFromUser)="cleanMesures(mesurageConfig.propertiesMesurage.listeMesures[2])"
                                                (calcMesure)="calcMesure(mesurageConfig.propertiesMesurage.listeMesures[2])"
                                            >
                                            </app-section-mesure>
                                        </ng-container>
                                        <app-section-mesure
                                            [volume]="currentPointDeControleVolume"
                                            [mesure]="mesurageConfig.propertiesMesurage.listeMesures[0]"
                                            [diagnostic]="currentDiagnostic"
                                            [readonlyMode]="readonlyMode"
                                            [label]="mesurageConfig.propertiesMesurage.listeMesuresLabels[0]"
                                            [isMesurageUtile]="mesurageConfig.propertiesMesurage.isMesurageUtile"
                                            [isNonRepute]="
                                                currentPointDeControleVolume.valeursParametres[mesurageConfig.propertiesMesurage.typeNonRepute]
                                            "
                                            (mesureChangedFromUser)="cleanMesures(mesurageConfig.propertiesMesurage.listeMesures[0])"
                                            (calcMesure)="calcMesure(mesurageConfig.propertiesMesurage.listeMesures[0])"
                                        >
                                        </app-section-mesure>
                                        <app-section-mesure
                                            [volume]="currentPointDeControleVolume"
                                            [mesure]="mesurageConfig.propertiesMesurage.listeMesures[1]"
                                            [diagnostic]="currentDiagnostic"
                                            [readonlyMode]="readonlyMode"
                                            [label]="mesurageConfig.propertiesMesurage.listeMesuresLabels[1]"
                                            [isMesurageUtile]="mesurageConfig.propertiesMesurage.isMesurageUtile"
                                            [isNonRepute]="
                                                mesurageConfig.propertiesMesurage.isMesurageUtile &&
                                                currentPointDeControleVolume.valeursParametres[mesurageConfig.propertiesMesurage.typeNonRepute]
                                            "
                                            (mesureChangedFromUser)="cleanMesures(mesurageConfig.propertiesMesurage.listeMesures[1])"
                                            (calcMesure)="calcMesure(mesurageConfig.propertiesMesurage.listeMesures[1])"
                                        >
                                        </app-section-mesure>
                                        <!-- L'ordre des sections de mesures est différent selon le type de mesurage-->
                                        <ng-container *ngIf="mesurageConfig.propertiesMesurage.isMesurageUtile">
                                            <app-section-mesure
                                                [volume]="currentPointDeControleVolume"
                                                [mesure]="mesurageConfig.propertiesMesurage.listeMesures[2]"
                                                [diagnostic]="currentDiagnostic"
                                                [readonlyMode]="readonlyMode"
                                                [label]="mesurageConfig.propertiesMesurage.listeMesuresLabels[2]"
                                                [isMesurageUtile]="mesurageConfig.propertiesMesurage.isMesurageUtile"
                                                (mesureChangedFromUser)="cleanMesures(mesurageConfig.propertiesMesurage.listeMesures[2])"
                                            >
                                            </app-section-mesure>
                                        </ng-container>
                                    </div>
                                    <ng-container *ngIf="currentVolumeComplementaire">
                                        <div class="mt-4 table-complementaire">
                                            <table [dataSource]="[currentVolumeComplementaire]" class="mat-elevation-z8" mat-table>
                                                <ng-container
                                                    *ngFor="
                                                        let col of mesurageConfig.displayedColumnsComplementaires;
                                                        let i = index;
                                                        let isFirst = first;
                                                        let isLast = last
                                                    "
                                                >
                                                    <ng-container [matColumnDef]="col">
                                                        <ng-container *ngIf="isFirst">
                                                            <th mat-header-cell *matHeaderCellDef>
                                                                <div>Mesures existantes</div>
                                                            </th>
                                                            <td mat-cell *matCellDef="let mes">
                                                                <div class="mesure-cell">
                                                                    Diagnostic
                                                                    <b>{{ mesurageConfig.diagnosticComplementaireLabel }}</b>
                                                                </div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container *ngIf="!isFirst && !isLast">
                                                            <th mat-header-cell *matHeaderCellDef>
                                                                <div class="{{ mesurageConfig.propertiesMesurage.listeMesuresCss[i - 1] }}">
                                                                    {{ mesurageConfig.displayedColumnsComplementaires[i] | titlecase }}
                                                                </div>
                                                            </th>
                                                            <td mat-cell *matCellDef="let mes">
                                                                <div
                                                                    class="mesure-cell {{ mesurageConfig.propertiesMesurage.listeMesuresCss[i - 1] }}"
                                                                    [innerHTML]="
                                                                        currentVolumeComplementaire.valeursParametres[
                                                                            mesurageConfig.listeMesuresComplementaires[i - 1]
                                                                        ]
                                                                            ? (currentVolumeComplementaire.valeursParametres[
                                                                                  mesurageConfig.listeMesuresComplementaires[i - 1]
                                                                              ]
                                                                              | number: '1.2-2'
                                                                              | mesure
                                                                              | safeHtml)
                                                                            : MESURE_NON_COMMUNIQUE
                                                                    "
                                                                ></div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container *ngIf="isLast">
                                                            <th mat-header-cell *matHeaderCellDef></th>
                                                            <td mat-cell *matCellDef="let mes">
                                                                <div class="mesure-cell">
                                                                    <button
                                                                        mat-icon-button
                                                                        mat-raised-button
                                                                        matTooltip="Utiliser les mêmes mesures"
                                                                        [disabled]="
                                                                            currentPointDeControleVolume?.valeursParametres[
                                                                                mesurageConfig.propertiesMesurage.typeNonRepute
                                                                            ] || readonlyMode
                                                                        "
                                                                        color="accent"
                                                                        (click)="applyMeasuresFromMesurageComplementaire()"
                                                                    >
                                                                        <lib-icon>control_point_duplicate</lib-icon>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>

                                                <tr *matHeaderRowDef="mesurageConfig.displayedColumnsComplementaires" mat-header-row></tr>
                                                <tr *matRowDef="let row; columns: mesurageConfig.displayedColumnsComplementaires" mat-row></tr>
                                            </table>
                                        </div>
                                    </ng-container>
                                </mat-tab>

                                <mat-tab label="MESURAGE PAR ZONE">
                                    <!--Tableau récapitulatif-->
                                    <div class="zones-details" *ngIf="zones?.length > 0 && !editZone">
                                        <table [dataSource]="zones" class="mat-elevation-z8" mat-table>
                                            <ng-container matColumnDef="mode">
                                                <th mat-header-cell *matHeaderCellDef></th>
                                                <td
                                                    mat-cell
                                                    class="mat-cell mode-cell {{ zone?.mode }}"
                                                    *matCellDef="let zone"
                                                    [ngClass]="zone?.style !== undefined ? zone?.style : ''"
                                                >
                                                    <lib-icon>{{ zone?.mode }}</lib-icon>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="nom">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    <div>Description</div>
                                                </th>
                                                <td mat-cell *matCellDef="let zone" [ngClass]="zone?.style !== undefined ? zone?.style : ''">
                                                    <div>
                                                        <b>{{ zone?.nom }}</b>
                                                    </div>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="surface">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    <div class="text-center">Surface</div>
                                                </th>
                                                <td mat-cell *matCellDef="let zone" [ngClass]="zone?.style !== undefined ? zone?.style : ''">
                                                    <div
                                                        [innerHTML]="
                                                            zone.surface
                                                                ? (zone.surface | number: '1.2-2' | mesure | safeHtml)
                                                                : MESURE_NON_COMMUNIQUE
                                                        "
                                                    ></div>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="edit">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    <div></div>
                                                </th>
                                                <td mat-cell *matCellDef="let zone" [ngClass]="zone?.style !== undefined ? zone?.style : ''">
                                                    <button
                                                        *ngIf="zone?.style === undefined"
                                                        mat-icon-button
                                                        matTooltip="Éditer la zone"
                                                        [disabled]="readonlyMode"
                                                        (click)="editZoneRow(zone)"
                                                    >
                                                        <lib-icon>create</lib-icon>
                                                    </button>
                                                </td>
                                            </ng-container>

                                            <tr *matHeaderRowDef="displayedColumnsZones; sticky: true" mat-header-row></tr>
                                            <tr *matRowDef="let row; columns: displayedColumnsZones" mat-row></tr>
                                        </table>
                                    </div>

                                    <div *ngIf="!editZone" class="d-flex justify-content-center pt-4">
                                        <button mat-raised-button color="accent" [disabled]="readonlyMode" (click)="editZone = true">
                                            <lib-icon>add</lib-icon>
                                            {{ zones?.length > 0 ? 'Ajouter une zone' : ('Ajouter une première zone' | uppercase) }}
                                        </button>
                                    </div>

                                    <div class="w-100" *ngIf="editZone">
                                        <app-mesure-zone
                                            [typePrestation]="mesurageConfig.typeMesurage"
                                            [volume]="currentPointDeControleVolume"
                                            [zone]="zoneToEdit"
                                            (mesureChangedFromUser)="closeZoneMode()"
                                        >
                                        </app-mesure-zone>
                                    </div>

                                    <ng-container *ngIf="currentVolumeComplementaire && !editZone">
                                        <div class="mt-4 table-complementaire">
                                            <table [dataSource]="[currentVolumeComplementaire]" class="mat-elevation-z8" mat-table>
                                                <ng-container
                                                    *ngFor="
                                                        let col of mesurageConfig.displayedColumnsZonesComplementaires;
                                                        let i = index;
                                                        let isFirst = first;
                                                        let isLast = last
                                                    "
                                                >
                                                    <ng-container [matColumnDef]="col">
                                                        <ng-container *ngIf="isFirst">
                                                            <th mat-header-cell *matHeaderCellDef>
                                                                <div>Mesures existantes</div>
                                                            </th>
                                                            <td mat-cell *matCellDef="let mes">
                                                                <div class="mesure-cell">
                                                                    Diagnostic
                                                                    <b>{{ mesurageConfig.diagnosticComplementaireLabel }}</b>
                                                                </div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container *ngIf="!isFirst && !isLast">
                                                            <th mat-header-cell *matHeaderCellDef>
                                                                <div class="{{ mesurageConfig.propertiesMesurage.listeMesuresCss[i - 1] }}">
                                                                    {{ mesurageConfig.displayedColumnsZonesComplementaires[i] | titlecase }}
                                                                </div>
                                                            </th>
                                                            <td mat-cell *matCellDef="let mes">
                                                                <div
                                                                    class="mesure-cell {{ mesurageConfig.propertiesMesurage.listeMesuresCss[i - 1] }}"
                                                                >
                                                                    {{
                                                                        currentVolumeComplementaire?.valeursParametres[
                                                                            mesurageConfig.listeMesuresZonesComplementaires[i - 1]
                                                                        ]
                                                                            ? currentVolumeComplementaire?.valeursParametres[
                                                                                  mesurageConfig.listeMesuresZonesComplementaires[i - 1]
                                                                              ].length
                                                                            : 0
                                                                    }}
                                                                    zone(s)
                                                                </div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container *ngIf="isLast">
                                                            <th mat-header-cell *matHeaderCellDef></th>
                                                            <td mat-cell *matCellDef="let mes">
                                                                <div class="mesure-cell">
                                                                    <button
                                                                        mat-icon-button
                                                                        mat-raised-button
                                                                        matTooltip="Utiliser les mêmes zones"
                                                                        [disabled]="
                                                                            currentPointDeControleVolume?.valeursParametres[
                                                                                mesurageConfig.propertiesMesurage.typeNonRepute
                                                                            ] || readonlyMode
                                                                        "
                                                                        color="accent"
                                                                        (click)="applyZonesFromMesurageComplementaire()"
                                                                    >
                                                                        <lib-icon>control_point_duplicate</lib-icon>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>

                                                <tr *matHeaderRowDef="mesurageConfig.displayedColumnsZonesComplementaires" mat-header-row></tr>
                                                <tr *matRowDef="let row; columns: mesurageConfig.displayedColumnsZonesComplementaires" mat-row></tr>
                                            </table>
                                        </div>
                                    </ng-container>
                                </mat-tab>
                            </mat-tab-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
