import { TypePrestation } from './type-prestation.model';

/**
 * Mode de parcourt des conditions de la règle
 */
export enum ComputeModeEnum {
    'ALL',
    'FIRST_VALID',
    'FIRST_INVALID',
}

export type ComputeMode = ComputeModeEnum.ALL | ComputeModeEnum.FIRST_VALID | ComputeModeEnum.FIRST_INVALID;

export type OperationType = 'or' | 'and';

export type TypeRegle = 'REGLE_CONTROL' | 'REGLE_DOCUMENT';

/**
 * Règles
 */
export class Rule {
    /** Liste des codes éléments auxquels s'appliquent les règles */
    references: string[];

    /** Nom de la règle */
    name: string;

    /** Liste des conditions de la règle */
    conditions: Condition[];
}

/**
 * Règles pour une condition
 */
export class Condition {
    /** Liste des optérations ET */
    and: Operation[] = [];
    /** Liste des oprérations OU */
    or: Operation[] = [];
    /** Résultat de la consition après traitement des opérations */
    result: ResultCondition;
    /** Ordre de priorité d'exécution de la condition par rapport aux autres condition de la liste */
    priority: number;
    /** Flag indiquant que la condition doit être obligatoirement validée avec les autres */
    prerequired = false;
    /** Nom de la condition */
    name: string = null;
    /**
     * Si true évalue toutes les opérations sans s'interrompre au premier résultat du "and" ou du "or".
     * Celà n'a pas d'impact sur le résultat de l'opération.
     */
    processAll = false;
    /**
     * Si true la liste des opérations valide et des opérations invalide sont ajoutées dans les
     * paramètres du résultat.
     */
    verbose = false;
}

/**
 * Item de règle
 */
export class Operation {
    /**
     * Sous groupe d'opérations pouvant avoir un résultat intermédiaire
     * Optionel, si présent le reste des attributs de l'opération sont ignorés
     */
    condition: Condition = null;
    /** Path JSON premettant de récupérer la valeur à tester dans l'objet de données de référence */
    pathReference?: string = null;
    /** Path JSON premettant de récupérer la valeur à tester dans l'objet de données de contrôle */
    pathControl: string;
    /** Valeur(s) de référence(s) par rapport auxquelles on test la valeur du path */
    values: string | string[];
    /** Nom de l'opérateur utilisé pour réaliser le test */
    operator: string = null;
    /** Nom optionel de l'opération */
    name: string = null;
}

export class RuleOptions {
    /**
     * Mode de parcourt des conditions de la règle (conditions de de plus haut niveau cf Rule.conditions).<br/>
     * Si <b>ALL</b> toutes les conditions sont parcourues<br/>
     * Si <b>FIRST_VALID</b> parcour jusqu'à la première condition valid hors prerequis<br/>
     * Si <b>FIRST_INVALID</b> parcour jusqu'à la première condition invalid hors prerequis
     */
    computeMode: ComputeMode = ComputeModeEnum.ALL;
    /**
     * Si true évalue toutes les opérations sans s'interrompre au premier résultat du "and" ou du "or".
     * Celà n'a pas d'imparct sur le résultat de l'opération.
     */
    processAll = false;
    /**
     * Si true la liste des opérations valide et des opérations invalide sont ajoutées dans les
     * paramètres du résultat.
     */
    verbose = false;

    constructor(computeMode: ComputeMode, processAll?: boolean, verbose?: boolean) {
        this.computeMode = computeMode;
        this.processAll = processAll === true ? processAll : false;
        this.verbose = verbose === true ? verbose : false;
    }
}

/**
 * Résultat de l'examen de la condition
 */
export class ResultCondition {
    conditionName: string = null;
    valid = false;
    type: string = null;
    params: Map<string, any> = new Map();
    prerequired = false;
    operation: OperationType;
    subResult: ResultCondition[] = [];

    constructor(valid: boolean, type?: string, conditionName?: string, operation?: OperationType, params?: Map<string, any>) {
        this.valid = valid;
        this.type = type;
        this.conditionName = conditionName;
        this.operation = operation;
        if (params) {
            // tslint:disable-next-line:forin
            for (const paramName in params) {
                this.params.set(paramName, params[paramName]);
            }
        }
    }
}

/**
 * Résultat de l'exécution de la règle avec la liste des résultats des conditions
 */
export class RuleResults {
    results: ResultCondition[] = [];
    valid = true;
    ruleName: string;

    constructor(ruleName?: string) {
        this.ruleName = ruleName;
    }

    addResult(result: ResultCondition) {
        this.results.push(result);
        this.valid = this.valid && result.valid;
    }
}

/**
 * Données de référence pour les règles. JSON contenant les règles
 */
export class RegleRef {
    id: string;
    typePrestation: TypePrestation;
    type: TypeRegle;
    jsonData: string;
}
