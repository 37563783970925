'use strict';
//***********************************************************************************
//***********************************************************************************
//**** cn_tool wall : a tool to build walls
//***********************************************************************************
//***********************************************************************************

import { cnx_clone } from '../utils/cn_utilities';
import { cn_svg_tool_creation } from './cn_svg_tool_creation';
import { cn_mouse_event } from './cn_mouse_event';
import { cn_element } from '../model/cn_element';
import { cn_edition_handler } from './cn_edition_handler';
import { cn_roof_dormer } from '../model/cn_roof_dormer';
import { cn_roof_slab } from '../model/cn_roof_slab';
import { cn_roof_dormer_handler } from './cn_roof_dormer_handler';
import { HELPER } from '../utils/cn_wordings';

export class cn_svg_tool_roof_dormer_creation extends cn_svg_tool_creation {
    constructor(map) {
        super(map);

        //*** Scene data
        this.slopes = 2;
        this._ghost = new cn_roof_dormer(this._scene);

        this._categories = null;
        this._new_opening_handler = null;
        this.element_filter = function (element) {
            if (element.constructor == cn_roof_dormer) return true;
            return element.constructor == cn_roof_slab;
        };

        this._initialize_ghost();
    }

    _initialize_ghost() {
        this._ghost = new cn_roof_dormer(this._scene);
        if (this.slopes == 2) {
            this._ghost.slopes = 2;
        } else {
            this._ghost.slopes = 1;
        }
        this._ghost.valid = false;
    }

    //***********************************************************************************
    //**** Selection callback
    //***********************************************************************************

    open_tool() {
        super.open_tool();
        this.push_instruction_input(HELPER.roof_dormer.creation);
    }

    set_slopes(nb_slopes) {
        this.slopes = nb_slopes;
        this._initialize_ghost();
    }

    get_slopes() {
        return this.slopes;
    }

    //***********************************************************************************
    //**** Draws  specific svg for the tool. Returns svg string
    //***********************************************************************************
    draw(camera) {
        var html = super.draw(camera);
        if (this._focus_handler != this) return html;

        html += this._ghost.draw(camera, []);

        return html;
    }

    //***********************************************************************************
    //**** Mouse callbacks
    //***********************************************************************************

    clear_move() {
        this._ghost.slab = null;
        super.clear_move();
    }

    click(ev) {
        if (this._focus_handler == this)
            this._create_element(ev);
        else
            super.click(ev);
        return true;
    }

    drop(ev) {
        if (this._focus_handler == this)
            this._create_element(ev);
        else
            super.drop(ev);
        return true;
    }

    grab(ev) {
        if (this._focus_handler == this)
            return true;
        return super.grab(ev);
    }

    move(ev) {
        if (super.move(ev)) return true;
        this._update_ghost(ev);
        this._focus_handler = this;
        return true;
    }

    drag(ev) {
        if (this._focus_handler == this)
            this._update_ghost(ev);
        else
            super.drag(ev);
        return true;
    }

    //***************************************************************
    /**
     * Creates a new opening
     * @param {cn_mouse_event} ev
     */
    _create_element(ev) {
        this._update_ghost(ev);
        if (!this._ghost.slab) return;
        this.push_transaction('Création de chien assis');
        const new_dormer = this._ghost;
        this._initialize_ghost();
        this.push_item_set(this._scene, 'roof_dormers');
        this._scene.roof_dormers.push(new_dormer);
        this._scene.update();
        this._scene.update_deep();

        this.call('creation', [new_dormer]);
        this._svg_parent.call('roof_change');
        this._initiate_edition([new_dormer]);
    }

    //***************************************************************
    /**
     * Update ghost depending on mouse position
     * @param {cn_mouse_event} ev
     */
    _update_ghost(ev) {
        this._ghost.position = cnx_clone(ev.mouse_world);
        this._ghost.slab = this._scene.find_slab(this._ghost.position);
        this._ghost.update(true);
        return this._ghost.slab != null;
    }

    /**
     * TODO : derivate in order to allow edition of other element in the process of creation
     * @param {cn_mouse_event} mouse_event
     * @returns {cn_element}
     */
    _find_other_element(mouse_event) {
        return this._scene.find_roof_dormer(mouse_event.mouse_world, mouse_event.camera.snap_world_distance);
    }

    /**
     * TODO : derivate in order to provide an edition handler
     * @param {Array<cn_roof_dormer>} elements
     * @returns {cn_edition_handler}
     */
    _build_edition_handler(elements) {
        return new cn_roof_dormer_handler(elements, this._map, true);
    }

    /**
     * TODO : derivate in order to find siblings of an element
     * @param {cn_roof_dormer} element
     * @returns {Array<cn_element>}
     */
    _get_siblings(element) {
        return this._scene.roof_dormers.filter(rd => rd.slopes == element.slopes);
    }
}

