<ng-container *ngIf="ouvrageACopier$ | async as context">
    <div *ngIf="context.enabled || context.onlyRevetement" class="d-flex flex-grow-0 bg-warning flex-column w-100 p-2">
        <div class="d-flex justify-content-between align-items-top w-100 py-1">
            <div *ngIf="!context.onlyRevetement">
                <p class="font-weight-light m-0">Parties d'ouvrage à coller depuis :</p>
                <p class="font-weight-bold m-0">{{ context.ouvrage.nom }}</p>
                <p class="font-weight-light m-0">{{ context.volume.nom }}</p>
            </div>
            <div *ngIf="context.onlyRevetement">
                <p class="font-weight-light m-0">Revêtement & Substrat à coller depuis :</p>
                <p class="font-weight-bold m-0">
                    {{ context.ouvrage.nom }} - {{ context.ouvrage.partiesOuvrages[0]?.nom }}
                </p>
                <p class="font-weight-light m-0">{{ context.volume.nom }}</p>
            </div>
            <button mat-icon-button matTooltip="Annuler" (click)="handleCancel()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <ng-container *ngIf="!context.onlyRevetement">
            <div class="font-weight-light" *ngFor="let partie of context.ouvrage.partiesOuvrages">
                <mat-checkbox [checked]="isPartieSelected(partie, context)" (change)="togglePartie(partie, context)">{{
                    partie.nom
                }}</mat-checkbox>
            </div>
        </ng-container>
        <div class="d-flex justify-content-center">
            <button
                [disabled]="!canCopy || context.selectedParties?.length == 0"
                mat-raised-button
                color="accent"
                class="my-auto"
                (click)="handleCopy()"
            >
                <span *ngIf="!context.onlyRevetement">Coller sur les ouvrages sélectionnées</span>
                <span *ngIf="context.onlyRevetement">Coller revêtement & substrat</span>
            </button>
        </div>
    </div>
</ng-container>
