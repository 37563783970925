import { Pipe, PipeTransform } from '@angular/core';
import { TypeAnalyse, typeAnalyseToLabel } from '../model/prelevement-generique.model';

/**
 * Affiche le nom d'un type d'analyse
 */
@Pipe({
    name: 'typeAnalyse',
})
export class TypeAnalysePipe implements PipeTransform {
    transform(typeAnalyse: TypeAnalyse): string {
        return typeAnalyseToLabel(typeAnalyse);
    }
}
