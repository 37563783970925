<h1 mat-dialog-title class="primary d-flex justify-content-between">
    <span>Modifier les résultats de l'échantillon {{ echantillonToEdit?.reference }}</span>
    <button mat-button (click)="onClickCancel()">
        <lib-icon>close</lib-icon>
    </button>
</h1>

<div mat-dialog-content class="pb-0">
    <div class="d-flex flex-column">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <mat-card class="p-2 mb-4 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="w-100">
                        <div class="d-flex">
                            <div class="col-xl-4 pr-2">
                                <app-custom-select
                                    formControlName="typeAnalyse"
                                    [choices]="listTypesAnalyse"
                                    [pathValueDisplay]="['label']"
                                    [matFormFieldClass]="'w-100'"
                                    [addline]="false"
                                    [placeholder]="'Type d\'analyse'"
                                    [filter]="false"
                                >
                                </app-custom-select>
                            </div>

                            <mat-form-field class="col-xl-4 pr-2">
                                <input placeholder="Méthode" matInput type="text" formControlName="methodeAnalyse" />
                            </mat-form-field>

                            <mat-form-field class="col-xl-4" (click)="dateAnalyse.open()" appearance="fill">
                                <input
                                    matInput
                                    formControlName="dateAnalyse"
                                    [matDatepicker]="dateAnalyse"
                                    [placeholder]="'Date d\'analyse'"
                                />
                                <mat-datepicker-toggle matSuffix [for]="dateAnalyse"></mat-datepicker-toggle>
                                <mat-datepicker #dateAnalyse></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="d-flex align-items-center">
                            <mat-form-field class="col-xl-4 pr-2">
                                <input placeholder="Texte" matInput type="text" formControlName="description" />
                            </mat-form-field>

                            <mat-form-field class="col-xl-2 pr-2">
                                <input
                                    placeholder="Nombre"
                                    matInput
                                    type="number"
                                    formControlName="number"
                                    step="0.1"
                                />
                            </mat-form-field>

                            <mat-form-field class="col-xl-2">
                                <input placeholder="Unité" matInput type="text" formControlName="unit" />
                            </mat-form-field>

                            <div class="w-100 d-flex justify-content-center">
                                <button color="accent" mat-raised-button class="bt-valid" [disabled]="form.invalid">
                                    {{ editMode ? 'Modifier' : 'Ajouter' }} résultat
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </form>
        <div class="w-100">
            <mat-card class="p-2 mb-4 d-flex flex-column justify-content-between">
                <div class="d-flex flex-column">
                    <table mat-table [dataSource]="dataSource" matSort aria-describedby="Tableau des résultats">
                        <ng-container matColumnDef="typeAnalyse">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type d'analyse</th>
                            <td mat-cell *matCellDef="let row">{{ row?.typeAnalyse | typeAnalyseSimple }}</td>
                        </ng-container>
                        <ng-container matColumnDef="methodeAnalyse">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Méthode</th>
                            <td mat-cell *matCellDef="let row">
                                {{ row?.methodeAnalyse }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="description">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
                            <td mat-cell *matCellDef="let row">
                                {{ row?.description }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="number">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
                            <td mat-cell *matCellDef="let row">
                                {{ row?.number }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="unit">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Unité</th>
                            <td mat-cell *matCellDef="let row">{{ row?.unit }}</td>
                        </ng-container>
                        <ng-container matColumnDef="dateAnalyse">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date d'analyse</th>
                            <td mat-cell *matCellDef="let row">
                                {{ row?.dateAnalyse | date: DATE_FORMAT_FRANCAIS_SANS_HEURE }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let row">
                                <button type="button" mat-icon-button (click)="onClickEditResultat(row)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button type="button" mat-icon-button class="warn" (click)="onClickDeleteResultat(row)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </table>
                </div>
            </mat-card>
        </div>
    </div>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-raised-button color="accent" (click)="validateModifications()">
        <lib-icon>check</lib-icon>
        <span>{{ 'Confirmer' | uppercase }}</span>
    </button>
</div>
