import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ReportagePhoto, ReportagePhotoImageFile } from '../../../../../model/diagnostic.model';

@Component({
    selector: 'app-reportage-photo-edit-selection-list',
    templateUrl: './reportage-photo-edit-selection-list.component.html',
    styleUrls: ['./reportage-photo-edit-selection-list.component.scss'],
})
export class ReportagePhotoEditSelectionListComponent {
    @Input() reportagePhoto: ReportagePhoto;
    @Input() imagesFiles: ReportagePhotoImageFile[];
    @Input() readonlyMode = false;

    @Output() imageFileSelected = new EventEmitter<ReportagePhotoImageFile>();
    @Output() legendChanged = new EventEmitter<ReportagePhotoImageFile>();
    @Output() autoGeneratedChanged = new EventEmitter<boolean>();

    /**
     * Action réalisée lorsque l'utilisateur clique sur une image
     * @param reportagePhotoImageFile
     */
    onSelectImage(reportagePhotoImageFile: ReportagePhotoImageFile): void {
        this.imageFileSelected.emit(reportagePhotoImageFile);
    }

    onLegendChanged(imageFile: ReportagePhotoImageFile) {
        this.legendChanged.emit(imageFile);
    }

    onAutoGeneratedChanged(event: boolean) {
        this.autoGeneratedChanged.emit(event);
    }
}
