import { ISpacesObjectsSuggestionsHandler } from '@acenv/cnmap-angular-editor-lib';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ReferenceService } from '../../../../../services/reference.service';
import { ObjectCodeBimSuggestion } from '@acenv/cnmap-angular-editor-lib/lib/model/object-code-bim-suggestion.model';

@Injectable({
    providedIn: 'root',
})
export class SpacesObjectsHandler implements ISpacesObjectsSuggestionsHandler {
    constructor(private http: HttpClient, private readonly referenceService: ReferenceService) {}

    getDefaultObjectsSuggestionsForSpaceType(spaceTypeCodeBim: string): Observable<ObjectCodeBimSuggestion[]> {
        return of([]);
    }
}
