import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { AdditionnalAreaType, ButtonsTogleGroupComponent } from '../../buttons-togle-group/buttons-togle-group.component';
import { Bien, Volume } from '../../../../../../../../model/bien.model';
import { MatDialog } from '@angular/material/dialog';
import { SurfaceInputDialogComponent } from '../../../../../shared/surface-input-dialog/surface-input-dialog.component';
import { DoubleMesureDialogComponent } from '../../../../../shared/double-mesure-dialog/double-mesure-dialog.component';
import { cn_building } from '@acenv/cnmap-editor';

@Component({
    selector: 'app-surface-piece-unitaire',
    templateUrl: './surface-piece-unitaire.component.html',
    styleUrls: ['./surface-piece-unitaire.component.scss'],
})
export class SurfacePieceUnitaireComponent implements OnInit {
    @ViewChild(ButtonsTogleGroupComponent) buttonsTogleGroupComponent: ButtonsTogleGroupComponent;

    @Input() selected: boolean = false;

    @Input() surface: AbstractControl;

    @Input() indexSurface: number;

    @Input() biens: Bien[];

    @Input() volumesSurface: any = {};

    @Input() volumeStoreyLinkObject: any = {};

    @Input() formArraySurfaces: FormArray;

    @Input() sommeMesureVolumes: Function;

    @Input() storeyId: string;
    @Input() cnBuilding: cn_building;

    @Output() selectSurfaceByIndex = new EventEmitter<number>();

    @Output() removeSurfaceByIndex = new EventEmitter<number>();

    @Output() onAddJustification = new EventEmitter<number>();

    @Output() removeVolumeZone = new EventEmitter<{ idVolume: String; indexSurface: number }>();

    @Output() surfaceDataEmitter: EventEmitter<{
        surface: any;
        mesure1: number;
        mesure2: number;
        selectedOption: string;
    }> = new EventEmitter();

    @Output() declareSurfaceEvent = new EventEmitter<{ surfaceToDeclare: string; volume: Volume }>();

    typeSurface: AdditionnalAreaType = null;
    aditionnalSurfaveValue: number = 0;
    selectedCard: any = null;

    LONGUEUR_MAX_LABEL: InputDataType = new InputDataType('Longueur maxi', 'Longueur (m)', 'longueurMax');
    SURFACE_LABEL: InputDataType = new InputDataType('Surface plancher', 'Surface (m²)', 'superficie');

    constructor(public dialog: MatDialog, private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        if (!this.surface.get('typeSurface').value) {
            this.surface.get('surfaceCalcul').setValue(this.sommeMesureVolumes(this.surface.get('listeIdVolume').value));
        }
    }

    get surfaceInterface() {
        return this.surface.get('longueurInterface').value * this.surface.get('hauteurInterface').value;
    }

    /**
     * Gestion de la séléction d'une surface au click
     * @param surface
     * @param indexSurface
     */
    onCardClick(surface, indexSurface) {
        this.selectSurfaceByIndex.emit(indexSurface);
        this.selectedCard = surface;
    }

    /**
     * Mise à jour du modèle de la surface
     * @param data
     */
    onSurfaceData(data: { surface; mesure1: number; mesure2: number; selectedOption: string }) {
        const selectedSurface = this.formArraySurfaces.controls.find((surface) => surface === data.surface);
        let area = 0;
        if (data.selectedOption == 'LONGUEUR_MAX') {
            area = Math.round(data.mesure1);
        } else {
            area = Math.round(data.mesure1 * data.mesure2);
        }
        selectedSurface.get('typeSurface').setValue(data.selectedOption ? data.selectedOption : '');
        selectedSurface.get('aditionnalSurfaveValue').setValue(data.selectedOption ? area : 0);
        if (data.selectedOption) {
            selectedSurface.get('surfaceCalcul').setValue(area);
            selectedSurface
                .get('longueurInterface')
                .setValue(data.selectedOption == AdditionnalAreaType.SURFACE_INTERFACE ? Math.round(data.mesure1) : 0);
            selectedSurface
                .get('hauteurInterface')
                .setValue(data.selectedOption == AdditionnalAreaType.SURFACE_INTERFACE ? Math.round(data.mesure2) : 0);
            selectedSurface
                .get('longueurSurfaceTravail')
                .setValue(data.selectedOption == AdditionnalAreaType.SURFACE_INTERFACE ? 0 : Math.round(data.mesure1));
            selectedSurface
                .get('largeurSurfaceTravail')
                .setValue(data.selectedOption == AdditionnalAreaType.SURFACE_INTERFACE ? 0 : Math.round(data.mesure2));
        } else {
            selectedSurface.get('surfaceCalcul').setValue(selectedSurface.get('superficie').value);
        }
        this.changeDetectorRef.detectChanges();
    }

    /**
     * Réceptionne l'évenement du clic sur le bouton pour modifier une surface déclaré
     */
    onClickOpenModal() {
        if (this.surface.get('typeSurface').value) {
            this.openModal(this.surface.get('typeSurface').value);
        } else {
            // this.edit(surface)
            this.openSurfaceModal(this.surface, this.SURFACE_LABEL);
        }
    }

    /**
     * Calcule la largeur et la longueur d'une pièce en fonction de ses coordonnées.
     * @param coordonnees - Un tableau de coordonnées représentant les points de la pièce.
     * @returns Un objet contenant la largeur et la longueur de la pièce.
     */
    calculerLargeurLongueur(coordonnees) {
        // Extraire les coordonnées x et y séparément
        let val1, val2;
        if (coordonnees.length) {
            const coordonneesX = coordonnees.map((coordonnee) => coordonnee[0]);
            const coordonneesY = coordonnees.map((coordonnee) => coordonnee[1]);
            val1 = (Math.max(...coordonneesX) - Math.min(...coordonneesX)).toFixed(2);
            val2 = (Math.max(...coordonneesY) - Math.min(...coordonneesY)).toFixed(2);
        } else {
            //Si la pièce n'est pas un rectangle il faut trouver comment calculer la largeur et la longueur
            val1 = 0;
            val2 = 0;
        }

        return { val1, val2 };
    }

    /**
     * Ouvre une modale pour entrer les surfaces déclarés
     * @param selectedOption option du type de surface
     */
    openModal(selectedOption) {
        if (selectedOption) {
            let volumes = this.getVolumes(this.surface.get('listeIdVolume').value);
            let spaceId = volumes.map((objet) => objet.spaceId)[0];

            const cnSpace = this.cnBuilding?.find_storey(this.storeyId)?.scene?.spaces?.find((sp) => sp.ID === spaceId);

            // const dimensions = this.calculerLargeurLongueur(coordonnees);
            const dimensions = this.calculerLargeurLongueur(cnSpace._full_facing_floor_polygon.contour_vertices);
            let volumesId = volumes.map((objet) => objet.spaceId);
            // this.currentNiveau.storeyId,
            // MesureComponent.loadBuilding(this.currentRelationBien.bien),
            // this._currentBien.bien.jsonPlan
            const data: {
                label: string;
                mesure1: string;
                mesure2: string;
            } = (() => {
                switch (selectedOption) {
                    case AdditionnalAreaType.SURFACE_TRAVAIL:
                        return {
                            label: 'Surface de travail',
                            mesure1: 'Largeur (m)',
                            mesure2: 'Longueur (m)',
                        };
                    case AdditionnalAreaType.SURFACE_INTERFACE:
                        return {
                            label: "Surface d'interface",
                            mesure1: "Longueur d'interface (m)",
                            mesure2: "Hauteur d'interface (m)",
                        };
                    case AdditionnalAreaType.LONGUEUR_MAX:
                        return {
                            label: 'Longueur max',
                            mesure1: 'Longueur max (m)',
                            mesure2: null,
                        };
                }
            })();
            const dialogRef = this.dialog.open(DoubleMesureDialogComponent, {
                width: '350px',
                data: {
                    label: data.label,
                    mesure1: data.mesure1,
                    mesure2: data.mesure2,
                    selectedOption: selectedOption,
                    surface: this.surface,
                    biens: this.biens,
                    dimension: dimensions,
                },
            });

            dialogRef.afterClosed().subscribe((result) => {
                if (result && result.confirmed) {
                    this.onSurfaceData({
                        surface: this.surface,
                        mesure1: result.mesure1,
                        mesure2: result.mesure2,
                        selectedOption: result.selectedOption,
                    });
                }
            });
        } else {
            this.onSurfaceData({
                surface: this.surface,
                mesure1: 0,
                mesure2: 0,
                selectedOption: '',
            });
            this.buttonsTogleGroupComponent.clearSelection('');
        }

        // this.declareSurfaceVolume(this.surface.get('surfaceCalcul'))
        // if (this.surface.get('listeIdVolume').value.length == 1) {
        //     // this.openSurfaceModal(this.surface, )
        //     this.polluantService.openModal(
        //         this.surface.get('typeSurface').value,
        //         this.surfaceDataEmitter,
        //         this.surface
        //     );
        // }
    }

    openSurfaceModal(surface: any, dataNames: InputDataType) {
        const surfaceFormGroup = surface as FormGroup;
        let volumes = this.getVolumes(this.surface.get('listeIdVolume').value);
        let spaceId = volumes.map((objet) => objet.spaceId)[0];
        const cnSpace = this.cnBuilding?.find_storey(this.storeyId)?.scene?.spaces?.find((sp) => sp.ID === spaceId);
        const dimensions = cnSpace.area;

        const dialogRef = this.dialog.open(SurfaceInputDialogComponent, {
            width: '350px',
            data: {
                surface: surfaceFormGroup,
                modalLabel: dataNames.modalLabel,
                formLabel: dataNames.formLabel,
                formControlName: dataNames.formControlName,
                superficieCnMap: Math.round(dimensions),
                declaredArea: cnSpace.declared_area,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.confirmed) {
                surfaceFormGroup.get(result.formControlName).setValue(result?.value);
                this.declareSurfaceVolume({
                    surfaceToDeclare: result.automatique ? -1 : result?.value,
                    // surfaceToDeclare: result?.value,
                    volume: volumes[0],
                });
                this.volumesSurface = result?.value;
            }
        });
    }

    removeVolume(event) {
        this.removeVolumeZone.emit({ idVolume: event.idVolume, indexSurface: event.indexSurface });
    }

    /**
     * @description Retourne la liste de tous les volumes du bien dans un tableau
     * @returns Tableau contenant la liste des volumes
     */
    getAllVolumes(): Volume[] {
        const volumes: Volume[] = [];
        this.biens.forEach((bien) => {
            bien.description.forEach((niveau) => {
                if (this.volumeStoreyLinkObject[niveau.storeyId] == undefined) {
                    this.volumeStoreyLinkObject[niveau.storeyId] = [];
                }

                niveau.volumes.forEach((volume) => {
                    volumes.push(volume);
                    this.volumeStoreyLinkObject[niveau.storeyId].push(volume);
                });
            });
        });
        return volumes;
    }

    /**
     * Renvoit la liste des volumes désirées
     * @param volumeId liste des id de volumes
     */
    getVolumes(volumeId: string[]) {
        return this.getAllVolumes().filter((volume) => {
            return volumeId.includes(volume.id);
        });
    }

    /**
     * Déclenche l'évenement vers le composant zone pour supprimer une surface
     * @param indexSurface l'index de la surface dans la liste
     */
    removeSurface(indexSurface: number) {
        this.removeSurfaceByIndex.emit(indexSurface);
    }

    /**
     * Déclenche l'évenement vers le composant zone pour modifier la surface d'un volume
     * @param event
     */
    declareSurfaceVolume(event: { surfaceToDeclare: string; volume: Volume }) {
        this.declareSurfaceEvent.emit({ surfaceToDeclare: event.surfaceToDeclare, volume: event.volume });
    }

    /**
     * Obtient le libellé de la surface en fonction du type de surface.
     * @param surface - Le formulaire de la surface.
     * @returns Le libellé correspondant au type de surface.
     */
    getSurfaceLabel(surface: AbstractControl): string {
        const typeSurface = surface.get('typeSurface').value;
        if (typeSurface === 'SURFACE_TRAVAIL') {
            return 'Surface travail';
        } else if (typeSurface === 'SURFACE_INTERFACE') {
            return 'Surface interf.'; // Ou une autre étiquette appropriée
        } else if (typeSurface === 'LONGUEUR_MAX') {
            return 'Longueur max.'; // Ou une autre étiquette appropriée
        } else {
            return 'Surface au sol';
        }
    }

    /**
     * Vérifie si la surface a un seul volume.
     * @param surface - Le formulaire de la surface.
     * @returns true si la surface a un seul volume, sinon false.
     */
    isSingleVolume(surface: AbstractControl): boolean {
        return surface.get('listeIdVolume').value.length === 1;
    }
}

class InputDataType {
    modalLabel: string;
    formLabel: string;
    formControlName: string;

    constructor(modalLabel: string, formLabel: string, formControlName: string) {
        this.modalLabel = modalLabel;
        this.formLabel = formLabel;
        this.formControlName = formControlName;
    }
}
