'use strict';

import { cn_space } from '../model/cn_space';
import { cn_storey } from '../model/cn_storey';
import { cn_zone } from '../model/cn_zone';
import { cn_pastille } from '../svg/cn_pastille';
import { cn_svg_map } from '../svg/cn_svg_map';
import { cn_transaction_manager } from '../utils/cn_transaction_manager';
import { cn_extension_feature } from './cn_extension_feature';

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** space extension model
//***********************************************************************************
//***********************************************************************************


export class cn_space_extension extends cn_extension_feature {

    constructor(is_registered = false) {
        super(is_registered, 'space_extension');
    }

    //*****************************************************************
    /**
     * Immuable space name transformation
     * @param { cn_space } space
     * @param { cn_storey } storey
     * @returns {string} name
     */
    get_space_name(space, storey) {
        return space.name;
    }

    //*****************************************************************
    /**
     * Add space handler edition pastilles
     * @param { cn_space } space
     * @param { cn_svg_map } map
     * @returns { cn_pastille[] } pastille
     */
    space_handler_option(space, map) {
        return [];
    }

    //*****************************************************************
    /**
     * Trigger when space changes zone
     * @param { cn_space } space
     * @param { cn_zone } new_zone
     * @returns { void }
     */
    on_change_zone(space, old_zone, new_zone) {
    }

    //*****************************************************************
    /**
     * Trigger when zone is deleted
     * @param { cn_zone } zone
     * @param { cn_transaction_manager } transaction_manager
     * @returns { void }
     */
    on_delete_zone(zone, transaction_manager) {
    }

}


