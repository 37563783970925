import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MpcaGeneralOld, MpcaItemOld, MpcaProtectionExpositionOld } from '../../diagnostics/polluant/model/mpca.model.old';
import { MongoUtils } from 'src/app/commons-lib';
import * as clone from 'clone';

@Component({
    selector: 'mpca-panel-list-card',
    templateUrl: './mpca-panel-list-card.html',
    styleUrls: ['./mpca-panel-list-card.scss'],
})
export class MpcaPanelListCardComponent implements OnInit {
    // A implementer
    /* @Output()
     share = new EventEmitter<any>();*/

    @Output()
    add = new EventEmitter<any>();

    @Output()
    copy = new EventEmitter<any>();

    @Output()
    delete = new EventEmitter<number>();

    @Output()
    select = new EventEmitter<number>();

    @Input()
    mpcaList: MpcaItemOld[];

    @Input()
    indexSelected: number;

    @Input()
    canCreate = false;

    @Input()
    canShare = false;

    @Input() // Utilisé pour passer les champs en read-only lors de la visite de chantier
    disabled: Boolean;

    constructor() {}

    ngOnInit(): void {}

    addNewMpca() {
        this.add.emit({
            general: new MpcaGeneralOld(),
            protectionExposition: new MpcaProtectionExpositionOld(),
            processus: [],
        });
    }

    selectItem($e, index: number) {
        this.select.emit(index);
    }

    copyItem($e, item: MpcaItemOld) {
        $e.stopPropagation();
        const mpca = MpcaPanelListCardComponent.deepCloneMpca(item);
        mpca.id = MongoUtils.generateObjectId();
        this.copy.emit(mpca);
    }

    deleteItem($e, index) {
        $e.stopPropagation();
        this.delete.emit(index);
    }

    /**
     * @description deep clone du MPCA Item
     * @param mpcaItem
     * @private
     */
    static deepCloneMpca<T>(mpcaItem: MpcaItemOld): MpcaItemOld {
        return clone<T>(mpcaItem);
    }
}
