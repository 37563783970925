import { Pipe, PipeTransform } from '@angular/core';
import { TypeRapport } from '../model/rapport.model';
import { typeRapportIconMapping } from './type-rapport.pipe';
import { OuvrageAControler } from '../model/categorie-ouvrage.model';

@Pipe({
    name: 'typeVitrage',
})
export class TypeVitragePipe implements PipeTransform {
    transform(value: OuvrageAControler): unknown {
        return [value, ...value.partiesOuvrages].map((it) => it.parametres.VITRAGE).filter((it) => !!it)?.[0] ?? '';
    }
}
