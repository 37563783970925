import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild } from '@angular/core';
import { ReportBlockDirective } from '../../../../directives/report-block.directive';
import { InterventionReportData, IReportComponent, ReportBlock } from '../../../../model/rapport.model';
import { ReportService } from '../../../../services/report.service';
import { BaseComponent } from 'src/app/commons-lib';
import { TypePrestation } from '../../../../model/type-prestation.model';
import { Intervention } from 'src/app/model/intervention.model';
import { ScreenshotSvg } from '../../../../model/screenshot-svg.model';

@Component({
    selector: 'app-block-report',
    templateUrl: './block-report.component.html',
    styleUrls: ['./block-report.component.scss'],
})
export class BlockReportComponent extends BaseComponent implements OnInit {
    @ViewChild(ReportBlockDirective, { static: true })
    public blockHost: ReportBlockDirective;

    @Input()
    reportBlock: ReportBlock;

    @Input()
    interReportData: InterventionReportData;

    @Input()
    typePrestation: TypePrestation;

    @Input() intervention: Intervention;

    @Input()
    screenshotsWithSvg: { screenshotSvg: ScreenshotSvg; svgPlan: string }[];

    constructor(private componentFactoryResolver: ComponentFactoryResolver, private reportService: ReportService) {
        super();
    }

    ngOnInit(): void {
        if (this.reportBlock && this.blockHost) {
            this.loadComponent(this.typePrestation);
        }
    }

    private loadComponent(typePrestation: TypePrestation) {
        const reportBlockType = this.reportService.getReportBlockType(this.reportBlock.componentName, typePrestation);
        if (reportBlockType) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(reportBlockType);
            const viewContainerRef = this.blockHost.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent<IReportComponent>(componentFactory);
            componentRef.instance.data = this.reportBlock.data;
            componentRef.instance.interReportData = this.interReportData;
            componentRef.instance.intervention = this.intervention;
            componentRef.instance.screenshotsWithSvg = this.screenshotsWithSvg;
        }
    }
}
