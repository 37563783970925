/**
 * Type élément à controler.
 */
import { CategorieEquipement } from './categorie-equipement.model';
import { DefinitionParametre } from './definition-parametre.model';
import { Equipement } from './equipement.model';
import { EtatWorkflow } from './etat-workflow.model';
import { TypePrestation } from './type-prestation.model';
import { MongoUtils } from 'src/app/commons-lib';
import { TypeBien } from './type-bien.model';

export class TypeElementAControler {
    public id: string = null;

    /**
     * Code BIM
     */
    public codeBim: string;

    /**
     * Nom
     */
    public nom: string = null;

    /**
     * Description
     */
    public description: string = null;

    /**
     * Description
     */
    public icone: string = null;

    /**
     * Abstrait (= ne peut pas être instancié)
     */
    public abstrait: boolean;

    /**
     * Types d'éléments ancêtres
     *
     * Liste de liste car héritage multiples (on trace tous les chemins pour aller jusqu'au sommet de la hiérarchie)
     */
    public idsAncetres: string[][] = [];

    /**
     * Définition de paramètres
     */
    public definitionsParametres: DefinitionParametre[] = [];

    /**
     * Valeurs par défaut des paramètres
     */
    public valeursDefautParametres: any = {};

    public custom = false;

    public etatEquipement: EtatWorkflow;

    /**
     * Types de prestations associés à l'élément
     */
    public typesPrestationsAssocie: TypePrestation[] = [];

    public imageFileId?: string;
    public nbProducts: number;
    public flag: 'UNKNOWN' | 'OTHER';
    constructor() {}

    public hauteur: number;
    public largeur: number;
    public profondeur: number;
}

export class TypeElementAControlerAdmin {
    public id: string = null;

    /**
     * Code BIM
     */
    public codeBim: string;

    /**
     * Nom
     */
    public nom: string = null;

    /**
     * Description
     */
    public description: string = null;

    /**
     * Description
     */
    public icone: string = null;

    /**
     * Abstrait (= ne peut pas être instancié)
     */
    public abstrait: boolean;

    /**
     * Types d'éléments ancêtres
     */
    public elementsParent: TypeElementAControler[] = [];

    /**
     * Eléments à contrôler parent à la pièce sous forme de string pour la recherche rapide
     */
    public elementsParentToString: string;

    /**
     * Définition de paramètres
     */
    public definitionsParametres: DefinitionParametre[] = [];

    /**
     * Valeurs par défaut des paramètres
     */
    public valeursDefautParametres: any = {};

    public etatEquipement: EtatWorkflow;

    public garderRegle: boolean;

    /**
     * Types de prestations associés à l'élément
     */
    public typesPrestationsAssocie: TypePrestation[] = [];

    constructor() {}
}

/**
 * Type volume possédant des éléments à controler
 */
export class TypeVolume {
    public id: string = null;

    /**
     * Nom du type volume
     */
    public nom: string = null;

    /**
     * Code BIM
     */
    public codeBim: string;

    /**
     * Ids des éléments à contrôler de la pièce pour chaque prestation
     */
    public piecesPreequipeesConfigs: PiecePreequipeeConfig[] = [];

    /**
     * Valeurs par défaut des paramètres
     */
    public valeursDefautParametres: any = {};

    public etatVolume: EtatWorkflow;

    public typesBienAssocies: string[];

    public ordre: number;

    constructor() {}
}

/**
 * Type volume possédant des éléments à controler
 * Objet pour la liste d'affichage des volumes pour un administrateur
 */
export class TypeVolumeAdmin {
    public id: string = null;

    /**
     * Nom du type volume
     */
    public nom: string = null;

    /**
     * Code BIM
     */
    public codeBim: string;

    /**
     * Ids des éléments à contrôler de la pièce pour chaque prestation
     */
    public piecesPreequipeesConfigs: PiecePreequipeeConfig[] = [];

    /**
     * Eléments à contrôler associés à la pièce sous forme de string pour la recherche rapide
     */
    public typesElementsAControlerToString: string;

    /**
     * Valeurs par défaut des paramètres
     */
    public valeursDefautParametres: any = {};

    public etatVolume: EtatWorkflow;

    public typesBienAssocies: string[];

    public ordre: number;

    constructor() {}
}

/**
 * Type volume possédant des éléments à controler
 * Objet pour la liste d'affichage des volumes pour un administrateur
 */
export class PiecePreequipeeConfig {
    // Cet identifiant est utilisé uniquement côté front pour différencier les différents formControl de la partie admin
    public id: string = MongoUtils.generateObjectId();

    /**
     * Type de prestation
     */
    public nom: string;

    /**
     * éléments à contrôler de la pièce pour la prestation
     */
    public equipementsAssocies?: Equipement[] = [];

    /**
     * Ids des éléments à contrôler de la pièce pour la prestation
     */
    public idsEquipementsAssocies?: string[] = [];

    constructor() {}
}

/**
 * Type de point de contrôle à effectuer selon l'élément
 */
export enum TypeCheckpoint {
    EQUIPEMENT = 'EQUIPEMENT',
    AUTRE = 'AUTRE',
}

/**
 * Renvoie le label de l'id du type Element envoyé en paramètre
 * @param id
 * @param typesElementsAController
 */
export function equipementIdToLabel(id: string, typesElementsAController: CategorieEquipement[]) {
    const typeElementAControllerFound = typesElementsAController.find((typeElementsAController) => {
        return typeElementsAController.id === id;
    });

    return typeElementAControllerFound ? typeElementAControllerFound.name : id;
}
