<h1 mat-dialog-title class="primary d-flex justify-content-between px-4">
    Sélectionner un fichier depuis les documents de l'intervention
</h1>
<div mat-dialog-content *ngIf="loadedFile" class="d-flex flex-wrap align-items-center mx-auto">
    <div class="m-2" *ngFor="let doc of docList">
        <img
            *ngIf="doc.idFichier | fileDownload | async as fileContent"
            [src]="fileContent"
            alt="Image du bien"
            class="img-fluid doc-image {{ doc.id === this.documentSelected?.id ? 'background-selected' : '' }}"
            (click)="toggleFile(doc)"
        />
        <div class="text-center">{{ doc.nom }}</div>
    </div>
</div>
<div mat-dialog-actions class="justify-content-end">
    <button mat-button type="button" color="primary" (click)="onClickCancel()">
        <lib-icon>close</lib-icon>
        <span>Annuler</span>
    </button>
    <button mat-raised-button color="accent" (click)="onClickValidate()" [disabled]="!documentSelected">
        <lib-icon>check</lib-icon>
        <span>Valider</span>
    </button>
</div>
