import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { WidgetSelectModeEnum } from 'src/app/modules/shared/widget-select/widget-select.component';

@Component({
    selector: 'filtres-item-list',
    templateUrl: './filtres-item-list.component.html',
    styleUrls: ['./filtres-item-list.component.scss'],
})
export class FiltresItemListComponent implements OnInit {
    formAvant: FormGroup;

    @Input('filterForm')
    filterForm: FormArray;

    @Output()
    add = new EventEmitter<any>();

    @Output()
    edit = new EventEmitter<any>();

    @Output()
    delete = new EventEmitter<any>();

    @Output()
    duplicate = new EventEmitter<any>();

    @Input() // Utilisé pour passer les champs en read-only
    disabled: Boolean;

    widgetSelectModeEnum: typeof WidgetSelectModeEnum = WidgetSelectModeEnum;

    constructor(private formBuilder: FormBuilder) {}

    ngOnInit(): void {}

    ajouterFiltre() {
        this.add.emit('');
    }

    modifierFiltre(i: number) {
        this.edit.emit(i);
    }

    dupliquerFiltre(i: number) {
        this.duplicate.emit(i);
    }

    supprimerFiltre(i: number) {
        this.delete.emit(i);
    }
}
