<div class="pb-5">
    <div class="block-title p-2 pl-5 my-3 d-inline-block" *ngIf="data">{{ data.title }}</div>
    <div class="block-content px-5 ml-2 mb-4">
        <ng-container *ngFor="let espace of listeEspaces">
            <ng-container *ngIf="espace.listePerimetres.length === 0">
                Aucun périmètre défini pour cet espace.
            </ng-container>
            <ng-container *ngFor="let perimetre of espace.listePerimetres; let index = index">
                <!----------------- Nom du périmètre ---------------->
                <div class="block-title p-2 d-inline-block mt-4" *ngIf="data">{{ espace.nom }}-{{ perimetre.nom }}</div>
                <!--------------- Détail des zones -------------->
                <ng-container *ngIf="perimetre.listeZones.length === 0">
                    <div>Aucun zone définie pour ce périmètre.</div>
                </ng-container>
                <ng-container *ngFor="let zone of perimetre.listeZones">
                    <table>
                        <tr>
                            <th class="informations">INFORMATIONS</th>
                            <th class="precision">DESCRIPTION</th>
                            <th class="precision">PHOTO DE SITUATION</th>
                        </tr>

                        <tr>
                            <!-------------- Colonne Informations -------------->
                            <td>
                                <div class="d-flex flex-column my-3">
                                    <div class="block-subtitle-2 mb-2">{{ zone.nom ? zone.nom : 'n.c' }}</div>
                                    <div>Partie d'ouvrage : {{ zone.partieOuvrage }}</div>
                                    <mat-chip-list class="mt-2">
                                        <mat-chip *ngFor="let typeMateriau of zone.materiaux">
                                            {{ typeMateriau }}
                                        </mat-chip>
                                    </mat-chip-list>
                                </div>
                            </td>

                            <!-------------- Colonne Description -------------->
                            <td>
                                <div class="d-flex flex-column my-3">
                                    {{ zone.description }}
                                </div>
                            </td>

                            <!-------------- Colonne Photo -------------->
                            <td>
                                <div class="d-flex w-100 justify-content-center">
                                    <div class="block-photo">
                                        <div
                                            *ngIf="!zone.idFichierImageSituation"
                                            class="h-100 d-flex justify-content-center align-items-center"
                                        >
                                            <div class="text-photo">PHOTO DE SITUATION</div>
                                        </div>
                                        <div
                                            *ngIf="zone.idFichierImageSituation | fileDownload | async as fileContent"
                                            class="image-photo d-flex justify-content-center"
                                        >
                                            <img
                                                [src]="fileContent"
                                                alt="Image de situation de la zone"
                                                class="img-fluid photo-prelevement"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>
