export class DataUtils {
    static getFieldValue(field: string, data: any): any {
        if (field.indexOf('.') > -1) {
            const firstField = field.substr(0, field.indexOf('.'));
            const newField = field.substr(field.indexOf('.') + 1);
            if (!data[firstField]) {
                return undefined;
            }
            return this.getFieldValue(newField, data[firstField]);
        }
        return data[field];
    }

    static setValue(field: string, data: any, value: any) {
        if (field.indexOf('.') > -1) {
            const newField = field.substr(0, field.lastIndexOf('.'));
            const subData = this.getFieldValue(newField, data);
            if (subData) {
                subData[field.substr(field.lastIndexOf('.') + 1)] = value;
            }
        } else {
            data[field] = value;
        }
    }
}
