import { MongoUtils } from 'src/app/commons-lib';
import { TypeOuvrage } from './type-ouvrage.model';
import { Legende } from './legende.model';
import { Zone } from './zone.model';
import { Prelevement } from './prelevement-generique.model';

export class Perimetre {
    id: string = MongoUtils.generateObjectId();
    nom: string;
    description: string;
    typeOuvrage?: TypeOuvrage;
    legende: Legende = new Legende();
    localisation: string;
    listeZones: Zone[] = [];
    listeBesoins: Prelevement[] = [];
}
