import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-widget-button',
    templateUrl: './widget-button.component.html',
    styleUrls: ['./widget-button.component.scss'],
})
export class WidgetButtonComponent implements OnInit {
    @Input()
    theme = 'dashed';

    @Input()
    size = 'medium';

    @Input()
    shape = 'round';

    constructor() {}

    ngOnInit(): void {}
}
