import { RelationInterventionBien } from '../model/intervention.model';
import { Bien, Volume } from '../model/bien.model';

/**
 * Utilitaire lié au bien
 */
export class BienUtils {
    /**
     *
     * Renvoie le suffix d'un bien (bienSelected)
     */
    static getSuffixBien(bienSelected: Bien, relationInterventionBiens: RelationInterventionBien[]): string {
        return relationInterventionBiens.some((relation) => relation.bien.id === bienSelected.id && !relation.isBienPrincipal)
            ? '_' + bienSelected.id
            : '';
    }
}

/**
 * Extrait le texte du dernier niveau de parenthèses du nom du volume, en fonction si dans plan ou pas.
 * @param {Volume} volume - Le volume avec son nom qui peut contenir des parenthèses.
 * @returns {string} - Le texte du dernier niveau de parenthèses ou le nom du volume s'il n'y a pas de parenthèses.
 */
export function extraireNomVolume(volume?: Volume) {
    if (!volume?.spaceId) {
        return volume?.nom;
    }
    const nomVolume = volume?.nom;
    const texteEntreParentheses = nomVolume?.match(/\(([^()]+)\)$/);
    if (texteEntreParentheses) {
        const spaceName = texteEntreParentheses[1];
        const regex = /^(.+?)(?:\s+\d+)?$/;
        const match = spaceName.match(regex);
        return match ? match[1].trim() : spaceName;
    } else {
        return nomVolume;
    }
}
