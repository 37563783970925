import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent, ConfirmationService, NotificationService } from 'src/app/commons-lib';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Contact } from 'src/app/model/contact.model';
import { EditMode } from 'src/app/model/edit-mode.model';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { ContactService } from 'src/app/services/contact.service';
import { SESSION_STORAGE_KEY_CONTACT_EDIT } from 'src/app/shared/constants/admin.constants';
import { URL_GESTION_CONTACTS, URL_GESTION_CONTACTS_EDIT } from 'src/app/shared/constants/url.constants';
import { SessionStorageUtils } from 'src/app/utils/session-storage.utils';
import { EditContactFormService } from '../../../shared/edit-contact/edit-contact-form.service';
import { confirm } from '../../../../utils/confirm.utils';
import { MatDialog } from '@angular/material/dialog';
import { SelectExistingContactModalComponent } from '../../../shared/edit-contact/select-existing-contact-modal/select-existing-contact-modal.component';

@Component({
    selector: 'app-creation-contact',
    templateUrl: './creation-contact.component.html',
    styleUrls: ['./creation-contact.component.scss'],
})
export class CreationContactComponent extends BaseComponent implements OnInit, OnDestroy {
    formContact: FormGroup;
    mode: EditMode = 'CREATE';
    isReadOnly = false;
    loadingComplete = false;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly contactService: ContactService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly notificationService: NotificationService,
        private readonly editContactFormService: EditContactFormService,
        private readonly confirmationService: ConfirmationService,
        private readonly matDialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        const item = SessionStorageUtils.getItem(SESSION_STORAGE_KEY_CONTACT_EDIT);
        this.formContact = this.editContactFormService.buildContactForm({ id: null, typeRole: null }, false, false);

        this.cnSpinnerService
            .withSpinner(
                combineLatest([
                    this.route.paramMap.pipe(
                        switchMap((params) => {
                            const idContact = params.get('idContact');
                            if (idContact) {
                                return this.contactService.findOneContact(idContact);
                            } else {
                                return of(null) as Observable<Contact>;
                            }
                        })
                    ),
                    this.route.data,
                ]).pipe(takeUntil(this.ngUnsubscribe))
            )
            .subscribe(([currentContact, data]) => {
                this.isReadOnly = data.consulter;
                const duplicate = data.duplicate;
                this.initForm(duplicate, currentContact);
                this.loadingComplete = true;
            });
    }

    private initForm(duplicate, currentContact: Contact) {
        this.mode = currentContact?.id ? (duplicate ? 'DUPLICATE' : 'EDIT') : 'CREATE';
        this.editContactFormService.patchContactForm(currentContact, this.formContact, this.mode === 'DUPLICATE');
    }

    validerContact() {
        this.editContactFormService
            .saveContactFromForm(this.formContact, this.formContact.value.id, null, !this.formContact.value.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                () => this.back(),
                (error) => this.notificationService.error(error)
            );
    }

    editer() {
        this.router.navigate([URL_GESTION_CONTACTS_EDIT, this.formContact.value.id]);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    back() {
        this.router.navigate([URL_GESTION_CONTACTS]);
    }

    onSelectExistingContact(existingContact: Contact) {
        const message = `Vous venez de sélectionner un contact existant, voulez-vous éditer celui-ci ou dupliquer ces données pour la création d'un nouveau contact ?`;
        const confirmLabel = 'Éditer existant';
        this.matDialog
            .open(SelectExistingContactModalComponent, {
                data: {
                    existingContact,
                    message,
                    confirmLabel,
                },
            })
            .afterClosed()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((action: 'cancel' | 'confirm' | 'duplicate') => {
                if (action === 'confirm') {
                    this.router.navigate([URL_GESTION_CONTACTS_EDIT, existingContact.id]);
                } else if (action == 'duplicate') {
                    this.initForm(true, existingContact);
                }
            });
    }
}
