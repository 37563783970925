<button
    mat-icon-button
    matPrefix
    aria-label="Moins"
    color="primary"
    class="mx-2"
    [matTooltip]="label ? label + ' (diminuer)' : 'Diminuer'"
    [libLongPress]="stopLong"
    (click)="decrementValue()"
    (longPressing)="decrementValue()"
    [disabled]="(value != null && value <= this.min) || disabled || readonly"
    [class.invisible]="disabled && hideButtonsWhenDisabled"
>
    <mat-icon>remove</mat-icon>
</button>
<mat-form-field [ngClass]="matFormFieldClass" [class.flex-grow-1]="fullWidth">
    <mat-label>{{ label }}</mat-label>
    <input
        matInput
        type="number"
        [(ngModel)]="value"
        (change)="inputManuallyChanged()"
        [disabled]="disabled"
        [readonly]="readonly"
        [required]="required"
        [step]="step"
        [min]="min"
        [max]="max"
    />
    <mat-hint *ngIf="hint" [ngClass]="matHintClass">{{ hint }}</mat-hint>
</mat-form-field>
<button
    mat-icon-button
    matSuffix
    aria-label="Plus"
    color="primary"
    class="mx-2"
    [matTooltip]="label ? label + ' (augmenter)' : 'Augmenter'"
    [libLongPress]="stopLong"
    (click)="incrementValue()"
    (longPressing)="incrementValue()"
    [disabled]="value >= this.max || disabled || readonly"
    [class.invisible]="disabled && hideButtonsWhenDisabled"
>
    <mat-icon>add</mat-icon>
</button>
