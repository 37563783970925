import { Component, Input, OnInit } from '@angular/core';
import { Document } from '../../../../../model/document.model';
import { DocumentCheckpoint } from 'src/app/model/type-document.model';
import { BaseComponent, Icon } from 'src/app/commons-lib';
import { ReferencePrestation } from 'src/app/model/reference-prestation.model';
import { Intervention } from 'src/app/model/intervention.model';
import { CheckValidityTabService } from 'src/app/services/check-validity-tab.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { EtatProgressionService } from 'src/app/services/etat-progression.service';
import { DocumentsService } from 'src/app/services/documents.service';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { StateChoiceBoxes } from '../../../../../model/states.model';
import { combineLatest, Observable, of } from 'rxjs';
import { DiagnosticService } from '../../../../../services/diagnostic.service';
import { CnSpinnerService } from '../../../cn-spinner/service/cn-spinner.service';
import { InterventionService } from '../../../../../services/intervention.service';

@Component({
    selector: 'app-documents-detail-checkpoint-list',
    templateUrl: './documents-detail-checkpoint-list.component.html',
    styleUrls: ['./documents-detail-checkpoint-list.component.scss'],
})
export class DocumentsDetailCheckpointListComponent extends BaseComponent implements OnInit {
    @Input() intervention: Intervention;
    @Input() diagnostic: Diagnostic;
    @Input() set document(document: Document) {
        this._document = document;
        this.populateCheckpointsList();
    }
    get document(): Document {
        return this._document;
    }
    @Input() set referencePrestationToShow(referencePrestationToShow: ReferencePrestation) {
        this._referencePrestationToShow = referencePrestationToShow;
        this.populateCheckpointsList();
    }
    get referencePrestationToShow(): ReferencePrestation {
        return this._referencePrestationToShow;
    }

    _document: Document;
    _referencePrestationToShow: ReferencePrestation;

    // Contient la liste des checkpoints du document pour la referencePrestation courante
    checkpointsList: DocumentCheckpoint[] = [];

    validOption = new StateChoiceBoxes('Valide', true, new Icon('check_circle'), 'ok');
    invalidOption = new StateChoiceBoxes('Invalide', false, new Icon('cancel'), 'ko');
    choices: StateChoiceBoxes[] = [this.validOption, this.invalidOption];
    allCheckpointsValue: boolean = null;

    readonlyMode: boolean;

    constructor(
        private readonly checkValidityTabService: CheckValidityTabService,
        private readonly documentsService: DocumentsService,
        private readonly interventionService: InterventionService,
        private readonly diagnosticService: DiagnosticService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly etatProgressionService: EtatProgressionService,
        private readonly route: ActivatedRoute
    ) {
        super();
    }

    ngOnInit(): void {}

    /**
     * Remplir la liste des checkpoints
     */
    populateCheckpointsList(): void {
        this.cnSpinnerService
            .withSpinner(
                this.interventionService.getCurrentIntervention().pipe(
                    switchMap((intervention) => {
                        const idDiagnostic = intervention.prestationsDiagnostics.find(
                            (it) => it.prestation.referencePrestation.id === this.referencePrestationToShow?.id
                        )?.idDiagnostic;
                        const diag = idDiagnostic ? [this.diagnosticService.findOne(idDiagnostic)] : [];
                        return combineLatest(([of(intervention)] as Observable<any>[]).concat(...diag));
                    }),
                    switchMap(([intervention, diagnostic]) => {
                        return of(this.diagnosticService.isReadOnlyMode(intervention, diagnostic));
                    }),
                    takeUntil(this.ngUnsubscribe)
                )
            )
            .subscribe((readonly) => {
                this.readonlyMode = readonly;
                if (this.document && this.referencePrestationToShow) {
                    // On reinitilise la liste
                    this.checkpointsList = [];

                    this.document.typeDocument.typeDocumentCheckpoint
                        .filter((typeDocumentCheckpointValue) => {
                            return this.referencePrestationToShow.id == typeDocumentCheckpointValue.referencePrestation.id;
                        })
                        .forEach((typeDocumentCheckpointValue) => {
                            typeDocumentCheckpointValue.checkpoints.forEach((checkpoint) => {
                                this.checkpointsList.push(checkpoint);
                            });
                        });
                    this.updateAllCheckpointsValue();
                }
            });
    }

    /**
     * Evènement lors de la modification de la valeur d'un checkpoint
     * On vérifie la conformité du document courant et on valide l'onglet document
     */
    onChangeCheckpointValue() {
        // Calcul de la conformité du document en cours
        this.documentsService.checkConformiteDocument(this.document, this.referencePrestationToShow);

        // Récupération de tous les documents liés à la prestation et
        // Vérification de l'onglet Document
        const listeDocumentToValidate = this.documentsService.findDocumentForPrestation(this.intervention, null, this.referencePrestationToShow);

        if (this.referencePrestationToShow) {
            this.checkValidityTabService
                .checkValidityDocument(listeDocumentToValidate, this.referencePrestationToShow)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((etat) => {
                    const code = this.route.snapshot.data['code'];
                    this.etatProgressionService.updateDiagnostic(code, etat, this.diagnostic);
                });
        }
        this.updateAllCheckpointsValue();
    }

    private updateAllCheckpointsValue() {
        if (this.checkpointsList.length > 0) {
            this.allCheckpointsValue = this.checkpointsList.every((checkpoint) => this.checkpointsList[0].value === checkpoint.value)
                ? this.checkpointsList[0].value
                : undefined;
        }
    }

    handleChangeReponseAll($event) {
        this.checkpointsList.forEach((it) => (it.value = $event));
        this.onChangeCheckpointValue();
    }
}
