<h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{ data.label }}</h2>
<mat-dialog-content>
    <form [formGroup]="form">
        <div class="input">
            <mat-label class="col-sm-6">{{ data.mesure1 }}</mat-label>
            <app-increment-input-reactive
                class="col-sm-6"
                formControlName="mesure1"
                data-cy="mesure1"
                [incrementControl]="form.get('mesure1')"
                [min]="0"
                [step]="1"
                [numericKeyboard]="true"
            >
            </app-increment-input-reactive>
        </div>

        <div class="input" *ngIf="data.mesure2">
            <mat-label class="col-sm-6">{{ data.mesure2 }}</mat-label>
            <app-increment-input-reactive
                class="col-sm-6"
                formControlName="mesure2"
                data-cy="mesure2"
                [incrementControl]="form.get('mesure2')"
                [min]="0"
                [step]="1"
                [numericKeyboard]="true"
            >
            </app-increment-input-reactive>
            <mat-checkbox class="mr-1" (change)="onChangeAutoVal($event, 'mesure2')">Automatique</mat-checkbox>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="onCancelClick()">Annuler</button>
    <button mat-button (click)="onConfirmClick()" [disabled]="!form.valid">Valider</button>
</mat-dialog-actions>
