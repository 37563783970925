<div class="toolbar">
    <mat-toolbar class="border-bottom-1 primary">
        <button mat-button class="px-0" (click)="back()">
            <lib-icon>arrow_back</lib-icon>
        </button>
        <div>
            <lib-icon [class]="'icon-before-text'">settings</lib-icon>
            <span *ngIf="mode === 'CREATE' && !isReadOnly">Création d'un équipement</span>
            <span *ngIf="mode === 'EDIT' && !isReadOnly">Edition de l'équipement</span>
            <span *ngIf="mode === 'DUPLICATE' && !isReadOnly">Duplication de l'équipement</span>
            <span *ngIf="isReadOnly">Consultation de l'équipement</span>
        </div>

        <div class="d-flex justify-content-end w-100">
            <button mat-button color="primary" class="mx-1" (click)="back()">
                <mat-icon>close</mat-icon>
                <span>{{ 'Annuler' | uppercase }}</span>
            </button>
            <button
                *ngIf="!isReadOnly"
                mat-raised-button
                color="primary"
                [disabled]="formTypeElement.invalid"
                (click)="validerEquipement()"
            >
                <lib-icon>check</lib-icon>
                <span>{{ 'Valider' | uppercase }}</span>
            </button>
            <button
                *ngIf="isReadOnly"
                mat-raised-button
                color="primary"
                [disabled]="typeElementAControler.etatEquipement === 'ACTIF'"
                (click)="editer()"
            >
                <mat-icon>edit</mat-icon>
                <span>{{ 'Éditer' | uppercase }}</span>
            </button>
        </div>
    </mat-toolbar>
</div>

<div class="equipement-container">
    <div class="h-100 row no-gutters p-4 scrollable-content" [formGroup]="formTypeElement">
        <!-- Colonne gauche-->
        <div class="col-xl-6 pr-2">
            <!-- Etat de l'équipement -->
            <mat-button-toggle-group
                appearance="legacy"
                *ngIf="!isReadOnly"
                formControlName="etatEquipement"
                class="mb-3"
            >
                <mat-button-toggle class="button-toggle-double INACTIF left-toggle" value="INACTIF"
                    >Inactif</mat-button-toggle
                >
                <mat-button-toggle class="button-toggle-double ACTIF right-toggle" value="ACTIF"
                    >Actif</mat-button-toggle
                >
            </mat-button-toggle-group>

            <div
                *ngIf="isReadOnly"
                class="statut-workflow consultation mb-3"
                [ngClass]="typeElementAControler.etatEquipement"
            >
                {{ typeElementAControler.etatEquipement | etatWorkflow }}
            </div>

            <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="col-xl-12 p-2 m-0 primary">Informations générales</div>
                    <!-- Nom de l'equipement -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Nom de l'équipement</div>
                        <div class="col-xl-8 pr-2">
                            <mat-form-field class="w-100">
                                <input matInput [readonly]="isReadOnly" type="text" formControlName="nom" />
                                <mat-error *ngIf="formTypeElement.get('nom').hasError('erreurNomEquipement')"
                                    >Un autre équipement possède déjà le même nom.
                                </mat-error>
                                <app-mat-error [control]="formTypeElement.get('nom')"></app-mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- codeBim de l'equipement -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Code bim de l'équipement</div>
                        <div class="col-xl-8 pr-2">
                            <mat-form-field class="w-100">
                                <input matInput [readonly]="isReadOnly" type="text" formControlName="codeBim" />
                                <mat-error *ngIf="formTypeElement.get('codeBim').hasError('erreurCodeBimEquipement')"
                                    >Un autre équipement possède déjà le même code bim.
                                </mat-error>
                                <app-mat-error [control]="formTypeElement.get('codeBim')"> </app-mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Description de l'equipement  -->
                    <div class="d-flex p-2 align-items-center">
                        <mat-form-field class="w-100 mb-0" appearance="outline">
                            <mat-label>Description de l'équipement</mat-label>
                            <textarea
                                matInput
                                [readonly]="isReadOnly"
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="3"
                                cdkAutosizeMaxRows="5"
                                formControlName="description"
                            ></textarea>
                        </mat-form-field>
                    </div>

                    <!-- Prestation associées -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Types de prestation associés</div>
                        <div class="col-xl-8">
                            <app-custom-select
                                class="w-100"
                                formControlName="typesPrestationsAssocie"
                                [readonly]="isReadOnly"
                                [selectControl]="formTypeElement.get('typesPrestationsAssocie')"
                                [matFormFieldClass]="'w-100'"
                                [choices]="typesPrestation"
                                [addline]="false"
                                [multiple]="true"
                            >
                            </app-custom-select>
                            <app-mat-error [control]="formTypeElement.get('typesPrestationsAssocie')"> </app-mat-error>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

            <!------------ Pictogramme ------------->
            <mat-card class="d-flex w-100 p-2 mb-2">
                <mat-card-content class="w-100">
                    <div class="col-xl-12 m-0 mb-3 primary">Pictogramme</div>
                    <mat-card *ngIf="!imgPerso && !isReadOnly" class="d-flex w-50 action-card">
                        <mat-card-content class="w-100">
                            <div>
                                <lib-file-uploader
                                    #fileUploader
                                    [isImage]="true"
                                    (startUpload)="uploadIconeElement($event)"
                                ></lib-file-uploader>
                                <mat-progress-bar
                                    class="mt-2"
                                    [hidden]="!(fileUploader.uploadRunning | async)"
                                    [value]="fileUploader.uploadProgress | async"
                                ></mat-progress-bar>

                                <button class="w-100" mat-button (click)="fileUploader.selectFile()">
                                    <mat-icon>add_a_photo</mat-icon>
                                    <br />{{ "Ajouter le pictogramme de l'élément à controler" }}
                                </button>
                            </div>
                        </mat-card-content>
                    </mat-card>

                    <div *ngIf="imgPerso" class="pl-5">
                        <button
                            *ngIf="!isReadOnly"
                            mat-icon-button
                            class="position-absolute align-self-end button-group primary"
                            (click)="editPicture = true"
                        >
                            <lib-icon class="icon-small">edit</lib-icon>
                        </button>
                        <div *ngIf="editPicture && !isReadOnly">
                            <div class="position-absolute align-self-end button-group">
                                <button mat-icon-button color="warn" (click)="editPicture = false" matTooltip="Annuler">
                                    <lib-icon class="icon-small">cancel</lib-icon>
                                </button>
                                <button
                                    mat-icon-button
                                    color="warn"
                                    (click)="deleteIconeElement()"
                                    matTooltip="Supprimer le pictogramme de l'élément à controler"
                                >
                                    <lib-icon class="icon-small">delete</lib-icon>
                                </button>
                                <lib-file-uploader
                                    #fileUploader
                                    [isImage]="true"
                                    (startUpload)="uploadIconeElement($event)"
                                ></lib-file-uploader>
                                <button
                                    mat-icon-button
                                    color="primary"
                                    (click)="fileUploader.selectFile()"
                                    matTooltip="Changer le pictogramme de l'élément à controler"
                                >
                                    <lib-icon class="icon-small">add_a_photo</lib-icon>
                                </button>
                            </div>
                        </div>
                        <img
                            [src]="imgPerso"
                            alt="Pictogramme de l'élément à controler"
                            class="img-fluid"
                            style="max-width: 100%; max-height: 300px"
                        />
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

        <!--Colonne droite-->
        <div class="col-xl-6 pl-2">
            <!-- Equipement Parent -->
            <mat-card class="p-2 mt-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="col-xl-12 p-2 m-0 primary">Réglages</div>
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Equipements parent</div>
                        <div class="col-xl-8">
                            <app-custom-select
                                class="w-100"
                                formControlName="elementParent"
                                [readonly]="isReadOnly"
                                [selectControl]="formTypeElement.get('elementParent')"
                                [matFormFieldClass]="'w-100'"
                                [choices]="listeNomTypeElementAControler"
                                [addline]="false"
                                (selectedValueChange)="changeElementParent($event)"
                            >
                            </app-custom-select>
                            <app-mat-error [control]="formTypeElement.get('elementParent')"> </app-mat-error>
                        </div>
                    </div>

                    <!-- valeurs des paramètres de l'équipements -->
                    <div class="d-flex flex-column p-2 align-items-center">
                        <div class="col-xl-12 mat-subheading-2 m-0 primary required">Valeurs des paramètres</div>
                        <div
                            *ngIf="formTemplateValeursParametres.length === 0"
                            class="col-xl-11 mat-subheading-2 m-0 primary"
                        >
                            Pas de valeurs à renseigner pour cet équipement parent
                        </div>
                        <div
                            *ngFor="let form_elem of formTemplateValeursParametres"
                            class="col-xl-11 d-flex p-2 align-items-center"
                        >
                            <div class="col-xl-4 mat-subheading-2 m-0 primary required">
                                {{ form_elem.nom }}
                            </div>
                            <div class="col-xl-8 pl-0">
                                <div [ngSwitch]="form_elem.type">
                                    <div *ngSwitchCase="'TEXTE'">
                                        <input
                                            matInput
                                            type="text"
                                            [readonly]="isReadOnly"
                                            formControlName="{{ 'val_param_' + form_elem.codeBim }}"
                                            required
                                        />
                                    </div>
                                    <div *ngSwitchCase="'NUMBER'">
                                        <input
                                            matInput
                                            type="number"
                                            [readonly]="isReadOnly"
                                            formControlName="{{ 'val_param_' + form_elem.codeBim }}"
                                            required
                                        />
                                    </div>
                                    <div *ngSwitchCase="'LISTE_CHOIX'">
                                        <app-custom-select
                                            class="w-100"
                                            [readonly]="isReadOnly"
                                            [selectControl]="formTypeElement.get('val_param_' + form_elem.codeBim)"
                                            [matFormFieldClass]="'w-100'"
                                            [choices]="form_elem.parametreListeChoix"
                                            [addline]="false"
                                        >
                                        </app-custom-select>
                                    </div>
                                    <app-mat-error [control]="formTypeElement.get('val_param_' + form_elem.codeBim)">
                                    </app-mat-error>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Règles -->
                    <div class="d-flex p-2 flex-column align-items-center">
                        <div class="col-xl-12 mat-subheading-2 m-0 primary required">Règles de conformité</div>
                        <div
                            *ngIf="mode !== 'EDIT' && !formTypeElement.controls['elementParent'].value"
                            class="col-xl-11 mat-subheading-2 m-0 primary"
                        >
                            Pas de valeurs à renseigner pour cet équipement parent
                        </div>
                        <div
                            *ngIf="mode !== 'EDIT' && formTypeElement.controls['elementParent'].value"
                            class="col-xl-11 d-flex p-2 align-items-center"
                        >
                            <div class="col-xl-4 mat-subheading-2 m-0 primary">
                                Garder les règles de "{{ formTypeElement.controls['elementParent'].value }}"?
                            </div>
                            <div class="col-xl-8 pl-0">
                                <div class="col-xl-4 pl-0">
                                    <mat-button-toggle-group
                                        appearance="legacy"
                                        formControlName="regle"
                                        class="mb-3 pl-0"
                                    >
                                        <mat-button-toggle class="button-toggle-double ACTIF left-toggle" value="true"
                                            >Oui</mat-button-toggle
                                        >
                                        <mat-button-toggle
                                            class="button-toggle-double INACTIF right-toggle"
                                            value="false"
                                            >Non</mat-button-toggle
                                        >
                                    </mat-button-toggle-group>
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="mode !== 'EDIT' && formTypeElement.controls['regle'].value === 'false'"
                            class="col-xl-12 mat-subheading-2 m-0 mat-error"
                        >
                            Vous ne pouvez pas définir de nouvelles règles pour le moment
                        </div>
                        <div *ngIf="mode === 'EDIT'" class="col-xl-12 mat-subheading-2 m-0 mat-error">
                            Vous ne pouvez pas modifier les règles pour le moment
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
