import { Injectable } from '@angular/core';
import { PointDeControleBien, PointDeControleNiveau, PointDeControleVolume } from '../../../../model/point-de-controle.model';
import {
    CODE_BIM_PARAM_ESPACE_HUMIDE,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE,
    PARAM_VOLUME_LOT,
    PARAM_VOLUME_VISITE,
    URL_MS_REPORT_FILE,
} from 'src/app/shared/constants/cndiag.constants';
import { EtatIntervention, Intervention } from '../../../../model/intervention.model';
import { Diagnostic } from '../../../../model/diagnostic.model';
import {
    ItemElementMesurage,
    Mesurage,
    MesurageReportData,
    RowBienMesurage,
    RowNiveauMesurage,
    RowVolumeMesurage,
    SubBlockMesurage,
    ZoneMesure,
} from '../model/mesurage.model';
import { Commentaire } from '../../../../model/commentaire.model';
import {
    NAMES_MAPPER,
    PARAM_AU_SOL,
    PARAM_AUTRE,
    PARAM_CARREZ,
    PARAM_HABITABLE,
    PARAM_HORS_CARREZ,
    PARAM_MESURES_INCOHERENTES,
    PARAM_NON_HABITABLE,
    PARAM_TOTAL_AUTRE,
    PARAM_TOTAL_CARREZ,
    PARAM_TOTAL_HABITABLE,
    PARAM_TOTAL_HORS_CARREZ,
    PARAM_TOTAL_NON_HABITABLE,
    PARAM_TOTAL_UTILE_BRUT,
    PARAM_TOTAL_UTILE_NET,
    PARAM_UTILE_BRUT,
    PARAM_UTILE_NET,
} from '../shared/mesurage.constants';
import { DocumentsService } from '../../../../services/documents.service';
import { ReportagePhotoService } from 'src/app/services/reportage-photo.service';
import { TypeReport } from 'src/app/model/reference-prestation.model';
import { cn_building, cn_storey } from '@acenv/cnmap-editor';
import { CndiagMarkerZone } from '../../../shared/map/model/cndiag-marker-zone.model';
import { PointDeControleService } from '../../../../services/point-de-controle.service';
import { enumTypesCommentaire } from 'src/app/model/type-commentaire.model';

@Injectable({
    providedIn: 'root',
})
export class MesurageService {
    constructor(
        private readonly documentsService: DocumentsService,
        private readonly reportagePhotoService: ReportagePhotoService,
        private readonly pointDeControleService: PointDeControleService
    ) {}

    /**
     * Initialisation / maj des mesures dans le diagnostic, selon la description des biens.
     * Si la description évolue au fur et à mesure du diagnostic, les mesures sont elles aussi mises à jour
     * (ajout de bien/niveau/volume, maj des noms des bien/niveau/volume, ou suppression d'un bien/niveau/volume)
     *
     * Commun pour le mesurage Carrez et habitable
     */
    static initMesures(formPointsDeControle: PointDeControleBien[], intervention: Intervention) {
        // BIEN
        intervention.relationInterventionBiens.forEach((relationInterventionBien) => {
            let jsonPlan = null;
            if (relationInterventionBien.bien.jsonPlan) {
                jsonPlan = JSON.parse(relationInterventionBien.bien.jsonPlan);
            }
            let bienHasChanged = false;
            let newBien = false;
            let formBien = formPointsDeControle.find((f) => f.idBien === relationInterventionBien.bien.id);
            // Si le bien a été ajouté dans la description
            if (formBien === undefined) {
                newBien = true;
                formBien = new PointDeControleBien();
                formBien.idBien = relationInterventionBien.bien.id;
                formBien.valeursParametres[PARAM_VOLUME_LOT] = relationInterventionBien.bien.numeroLot;
            }
            // maj du nom du bien
            formBien.nomBien = relationInterventionBien.bien.nom;
            formBien.valeursParametres[PARAM_VOLUME_LOT] = relationInterventionBien.bien.numeroLot;

            // NIVEAU
            relationInterventionBien.bien.description.forEach((niveau) => {
                let niveauHasChanged = false;
                let newNiveau = false;
                let formNiveau = formBien.pointsDeControleNiveaux.find((f) => f.idNiveau === niveau.id);
                // Si le niveau a été ajouté dans la description
                if (formNiveau === undefined) {
                    newNiveau = true;
                    bienHasChanged = true;
                    formNiveau = new PointDeControleNiveau();
                    formNiveau.idNiveau = niveau.id;
                }
                // maj du nom du niveau
                formNiveau.nom = niveau.nom;
                formNiveau.index = niveau.index;
                formNiveau.storeyId = niveau.storeyId;

                // VOLUME
                // les volumes cachés ne sont pas utilisés dans les diagnostics mesurages
                niveau.volumes
                    .filter((v) => !v.volumeCache)
                    .forEach((volume) => {
                        let newVolume = false;
                        let formVolume = formNiveau.pointsDeControleVolumes.find((f) => f.idVolume === volume.id);
                        // Si le volume a été ajouté dans la description
                        if (formVolume === undefined) {
                            newVolume = true;
                            niveauHasChanged = true;
                            bienHasChanged = true;
                            formVolume = new PointDeControleVolume();
                            formVolume.idVolume = volume.id;
                            formVolume.spaceId = volume.spaceId;
                            // On maj les valeursparamètres de la pièce
                            formVolume.valeursParametres[CODE_BIM_PARAM_ESPACE_HUMIDE] = volume.valeursParametres[CODE_BIM_PARAM_ESPACE_HUMIDE];
                            formVolume.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ] =
                                volume.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ];
                            formVolume.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE] =
                                volume.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE];
                            formVolume.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE] =
                                volume.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE];
                        }

                        if (![EtatIntervention.ANNULEE, EtatIntervention.TERMINEE, EtatIntervention.NON_REALISEE].includes(intervention.etat)) {
                            formVolume.valeursParametres[PARAM_VOLUME_VISITE] = volume.valeursParametres[PARAM_VOLUME_VISITE];
                        }

                        // maj du nom du volume
                        formVolume.nom = volume.nom;
                        formVolume.valeursParametres[PARAM_VOLUME_LOT] =
                            volume.valeursParametres[PARAM_VOLUME_LOT] ?? relationInterventionBien.bien.numeroLot;
                        formVolume.usageId = volume.usageId;
                        if (jsonPlan && volume.spaceId) {
                            const st = jsonPlan.storeys.find((s) => s.ID === formNiveau.storeyId);
                            if (st) {
                                const declaredArea = st.scene.spaces.find((sp) => sp.ID === formVolume.spaceId).declared_area;
                                formVolume.valeursParametres[PARAM_AU_SOL] = declaredArea && declaredArea > -1 ? declaredArea.toString() : undefined;
                            }
                        }

                        // Si un volume a été ajouté
                        if (newVolume) {
                            formNiveau.pointsDeControleVolumes.push(formVolume);
                        }
                    });

                // Si un niveau a été ajouté
                if (niveauHasChanged && newNiveau) {
                    formNiveau.pointsDeControleVolumes.length && formBien.pointsDeControleNiveaux.push(formNiveau);
                }
                formBien.pointsDeControleNiveaux = formBien.pointsDeControleNiveaux.sort((a, b) => a.index - b.index);
            });

            // Si un bien a été ajouté
            if (bienHasChanged && newBien) {
                formPointsDeControle.push(formBien);
            }
        });
        // Si on supprime des infos dans la description, maj du formulaire
        // BIEN
        formPointsDeControle.forEach((formBien) => {
            const bien = intervention.relationInterventionBiens.find((b) => b.bien.id === formBien.idBien);

            // Si le bien a été supprimé de la description
            if (bien === undefined) {
                formPointsDeControle.splice(formPointsDeControle.indexOf(formBien), 1);
            } else {
                formBien.pointsDeControleNiveaux.forEach((formNiveau) => {
                    const niveau = bien.bien.description.find((niv) => niv.id === formNiveau.idNiveau);

                    // Si le niveau a été supprimé de la description
                    if (niveau === undefined) {
                        formBien.pointsDeControleNiveaux.splice(formBien.pointsDeControleNiveaux.indexOf(formNiveau), 1);
                        // TODO FVI : si un niveau a été supprimé de la sélection, cela peut être lié à un merge de pièces.
                        // Il faut donc parcourir la liste des pièces potentiellement déplacée pour réatribuer aux pièces target mergée les conformité des équipements
                    } else {
                        formNiveau.pointsDeControleVolumes.forEach((formVolume) => {
                            const volume = niveau.volumes.find((vol) => vol.id === formVolume.idVolume);

                            // TODO FVI : si un niveau a été supprimé de la sélection, cela peut être lié à un merge de pièces.
                            // Il faut donc parcourir la liste des pièces potentiellement déplacée pour réatribuer aux pièces target mergée les conformité des équipements
                            // Si le volume a été supprimé de la description
                            if (volume === undefined) {
                                formNiveau.pointsDeControleVolumes.splice(formNiveau.pointsDeControleVolumes.indexOf(formVolume), 1);
                            }
                        });
                    }
                });
            }
        });
    }

    /**
     * Calcul la surface totale d'un niveau pour tout type de mesurage
     */
    static calculTotalNiveauMesurage(pcNiveau: PointDeControleNiveau, typeMesures: string[], typeMesuresTotales: string[]) {
        const numOr0 = (n) => (isNaN(n) ? 0 : n);
        const reducer = (a, b) => numOr0(a) + numOr0(b);
        for (let i = 0; i < typeMesures.length; i++) {
            pcNiveau.valeursParametres[typeMesuresTotales[i]] = pcNiveau.pointsDeControleVolumes
                .map((it) => Number(it.valeursParametres[typeMesures[i]]))
                .reduce(reducer);
            pcNiveau.valeursParametres[typeMesuresTotales[i]] = isNaN(pcNiveau.valeursParametres[typeMesuresTotales[i]])
                ? pcNiveau.valeursParametres[typeMesuresTotales[i]]
                : pcNiveau.valeursParametres[typeMesuresTotales[i]].toString();
        }
    }

    /**
     * Calcul la surface totale d'un bien pour tout type de mesurage
     */
    static calculTotalBienMesurage(pcBien: PointDeControleBien, typeMesures: string[]) {
        const numOr0 = (n) => (isNaN(n) ? 0 : n);
        const reducer = (a, b) => numOr0(a) + numOr0(b);
        typeMesures.forEach((m) => {
            pcBien.valeursParametres[m] = pcBien.pointsDeControleNiveaux.map((it) => Number(it.valeursParametres[m])).reduce(reducer);
            pcBien.valeursParametres[m] = isNaN(pcBien.valeursParametres[m]) ? pcBien.valeursParametres[m] : pcBien.valeursParametres[m].toString();
        });
    }

    /**
     * Permet de savoir si une mesure a été faite et est positive
     * @param pdc
     */
    static isFilledAndPositive(pdc: PointDeControleVolume) {
        return pdc && !isNaN(Number(pdc)) && Number(pdc) >= 0;
    }

    /**
     * Vérifie la cohérence des mesures.
     * Pas appliqué si on est en mode mesurageUtile ou si toutes les mesures ne sont pas renseignées
     * @param volume
     */
    static checkValidityMesures(pdc: PointDeControleVolume, isMesurageUtile: boolean, listeMesures: string[]) {
        if (
            !isMesurageUtile &&
            pdc.valeursParametres[listeMesures[0]] &&
            pdc.valeursParametres[listeMesures[1]] &&
            pdc.valeursParametres[listeMesures[2]]
        ) {
            const numOr0 = (n) => (isNaN(n) ? 0 : n);
            const additionMesures = (
                numOr0(Number(pdc.valeursParametres[listeMesures[0]])) + numOr0(Number(pdc.valeursParametres[listeMesures[1]]))
            ).toFixed(2);
            const mesureAuSol = numOr0(Number(pdc.valeursParametres[listeMesures[2]]));
            // Si la somme des mesures est différente de la valeur de surface au sol, on a une incohérence.
            pdc.valeursParametres[PARAM_MESURES_INCOHERENTES] = additionMesures != mesureAuSol;
        } else {
            pdc.valeursParametres[PARAM_MESURES_INCOHERENTES] = false;
        }
    }

    /**
     * Préparation de la prévisualisation du rapport mesurage en fonction des points de contrôles
     */
    prepareDiagnosticReportData(diagReportData: MesurageReportData, diagnostic: Diagnostic, intervention: Intervention) {
        diagReportData.refRapport = diagnostic.reportDatas.find((reportDataTemp) => reportDataTemp.typeReport === TypeReport.REPORT).refRapport;

        const commentaires = intervention.commentaires;
        // Bloc visite
        const subBlockVolumesNonVisites = new SubBlockMesurage('VOLUMES_NON_VISITES', []);
        const subBlockVolumesVisites = new SubBlockMesurage('VOLUMES_VISITES', []);
        const subBlockMesures = new SubBlockMesurage('MESURES', []);

        // BIENS
        diagnostic.pointsDeControleBiens.forEach((bien) => {
            // Visitabilité
            const rowBienNonVisites = new RowBienMesurage(bien.nomBien);
            const rowBienVisites = new RowBienMesurage(bien.nomBien);
            const rowBienMesures = new RowBienMesurage(bien.nomBien);
            rowBienMesures.lot = bien.valeursParametres[PARAM_VOLUME_LOT];
            // Carrez
            rowBienMesures.carrez = bien.valeursParametres[PARAM_TOTAL_CARREZ];
            rowBienMesures.horsCarrez = bien.valeursParametres[PARAM_TOTAL_HORS_CARREZ];
            // Habitable
            rowBienMesures.habitable = bien.valeursParametres[PARAM_TOTAL_HABITABLE];
            rowBienMesures.nonHabitable = bien.valeursParametres[PARAM_TOTAL_NON_HABITABLE];
            // Utile
            rowBienMesures.utileBrut = bien.valeursParametres[PARAM_TOTAL_UTILE_BRUT];
            rowBienMesures.utileNet = bien.valeursParametres[PARAM_TOTAL_UTILE_NET];
            rowBienMesures.utileAutre = bien.valeursParametres[PARAM_TOTAL_AUTRE];

            // NIVEAUX
            bien.pointsDeControleNiveaux.forEach((niveau) => {
                // Visitabilité
                const rowNiveauNonVisites = new RowNiveauMesurage(niveau.nom);
                const rowNiveauVisites = new RowNiveauMesurage(niveau.nom);
                const rowNiveauMesures = new RowNiveauMesurage(niveau.nom);
                rowNiveauMesures.lot = bien.valeursParametres[PARAM_VOLUME_LOT];
                // Carrez
                rowNiveauMesures.carrez = niveau.valeursParametres[PARAM_TOTAL_CARREZ];
                rowNiveauMesures.horsCarrez = niveau.valeursParametres[PARAM_TOTAL_HORS_CARREZ];
                // Habitable
                rowNiveauMesures.habitable = niveau.valeursParametres[PARAM_TOTAL_HABITABLE];
                rowNiveauMesures.nonHabitable = niveau.valeursParametres[PARAM_TOTAL_NON_HABITABLE];
                // Utile
                rowNiveauMesures.utileBrut = niveau.valeursParametres[PARAM_TOTAL_UTILE_BRUT];
                rowNiveauMesures.utileNet = niveau.valeursParametres[PARAM_TOTAL_UTILE_NET];
                rowNiveauMesures.utileAutre = niveau.valeursParametres[PARAM_TOTAL_AUTRE];

                // VOLUMES
                niveau.pointsDeControleVolumes.forEach((volume) => {
                    const rowVolume = this.prepareVolume(volume, diagReportData, commentaires);
                    // Visitabilité
                    if (volume.valeursParametres[PARAM_VOLUME_VISITE] && ['ko', 'warning'].includes(volume.valeursParametres[PARAM_VOLUME_VISITE])) {
                        const rowVolumeNonVisite = { ...rowVolume };
                        rowVolumeNonVisite.commentaires = rowVolume.commentaires.filter(
                            (it) => it.type === enumTypesCommentaire.JUSTIFICATION_NON_VISITE
                        );
                        rowNiveauNonVisites.volumes.push(rowVolumeNonVisite);
                    }
                    if (volume.valeursParametres['visite'] && volume.valeursParametres[PARAM_VOLUME_VISITE] === 'ok') {
                        const rowVolumeVisite = { ...rowVolume };
                        rowNiveauVisites.volumes.push(rowVolumeVisite);
                    }
                    const rowVolumeMesures = { ...rowVolume };
                    rowVolumeMesures.lot = volume.valeursParametres[PARAM_VOLUME_LOT];
                    // Carrez
                    rowVolumeMesures.carrez = volume.valeursParametres[PARAM_CARREZ];
                    rowVolumeMesures.horsCarrez = volume.valeursParametres[PARAM_HORS_CARREZ];
                    // Habitable
                    rowVolumeMesures.habitable = volume.valeursParametres[PARAM_HABITABLE];
                    rowVolumeMesures.nonHabitable = volume.valeursParametres[PARAM_NON_HABITABLE];
                    // Utile
                    rowVolumeMesures.utileBrut = volume.valeursParametres[PARAM_UTILE_BRUT];
                    rowVolumeMesures.utileNet = volume.valeursParametres[PARAM_UTILE_NET];
                    rowVolumeMesures.utileAutre = volume.valeursParametres[PARAM_AUTRE];

                    rowNiveauMesures.volumes.push(rowVolumeMesures);
                });
                // Visitabilité
                rowNiveauNonVisites.volumes.length && rowBienNonVisites.niveaux.push(rowNiveauNonVisites);
                rowNiveauVisites.volumes.length && rowBienVisites.niveaux.push(rowNiveauVisites);

                // Mesures
                rowNiveauMesures.volumes.length && rowBienMesures.niveaux.push(rowNiveauMesures);
            });

            // Visitabilité
            subBlockVolumesNonVisites.biens.push(rowBienNonVisites);
            subBlockVolumesVisites.biens.push(rowBienVisites);

            // Mesures
            subBlockMesures.biens.push(rowBienMesures);
        });

        // Visitabilité
        diagReportData.volumesNonVisites = subBlockVolumesNonVisites;
        diagReportData.volumesVisites = subBlockVolumesVisites;

        // Mesures
        diagReportData.mesures = subBlockMesures;

        // Liste des Documents
        diagReportData.documentsData = this.documentsService.buildDocumentsData(intervention, diagnostic);
        diagReportData.listDocuments = diagReportData.documentsData.filter((doc) => doc.afficherDansListeDocuments);
        diagReportData.annexes = diagReportData.documentsData
            .filter((doc) => doc.afficherDansRapport)
            .map((doc) => ({
                id: doc.nom,
                filename: URL_MS_REPORT_FILE + doc.idFichier,
                type: 'pdf',
            }));

        // Reportage photos
        diagReportData.reportagesPhotos = this.reportagePhotoService.buildReportagePhotoData(diagnostic);

        diagReportData.etat = diagnostic.etat;
    }

    /**
     * Initialisation d'un rowVolume
     */
    private prepareVolume(volume: PointDeControleVolume, diagReportData: MesurageReportData, commentaires: Commentaire[]) {
        const newRowVolume = new RowVolumeMesurage(volume.nom, volume.idVolume);
        newRowVolume.lot = volume.valeursParametres[PARAM_VOLUME_LOT];
        if (diagReportData.optionPlan) {
            // TODO
            newRowVolume.plans = 'plan 1';
        }
        newRowVolume.visite = new ItemElementMesurage(
            volume.valeursParametres[PARAM_VOLUME_VISITE]
                ? this.translateValue(PARAM_VOLUME_VISITE, volume.valeursParametres[PARAM_VOLUME_VISITE])
                : null,
            null
        );

        newRowVolume.commentaires = commentaires
            .filter((c) => {
                return volume.commentairesId.indexOf(c.id) !== -1;
            })
            .filter((it) => it.type !== enumTypesCommentaire.NOTE_PERSONNELLE);

        newRowVolume.commentaires.map((com) => {
            const newCom = Object.assign({}, com);
            if (newCom.imageId) {
                newCom.imageId = URL_MS_REPORT_FILE + com.imageId;
            }
            return newCom;
        });
        return newRowVolume;
    }

    private translateValue(ppty: string, value: string): string {
        let valueToDisplay = value;
        if (NAMES_MAPPER[ppty]) {
            const finded = NAMES_MAPPER[ppty].find((it) => it.valeur === value);
            if (finded) {
                valueToDisplay = finded.afficher;
            }
        }
        return valueToDisplay;
    }

    synchronizeDeclaredSurfaceFormDiagToBuilding(
        surfaceToDeclare: string,
        spaceId: string,
        storeyId: string,
        building: cn_building,
        currentJson: string
    ) {
        const cnSpace = building?.find_storey(storeyId)?.scene?.spaces?.find((sp) => sp.ID === spaceId);

        if (cnSpace) {
            if (Number(surfaceToDeclare) > 0) {
                cnSpace.declared_area = Number(surfaceToDeclare);
            } else {
                cnSpace.declared_area = Number(-1);
            }
            return JSON.stringify(building.serialize());
        } else {
            return currentJson;
        }
    }

    /**
     * Complete le cn_storey avec le backgroundMap et les markers
     * @param diagnostic
     * @param currentStorey
     * @param idBien
     * @param idNiveau
     * @param showComments
     * @param showZonePositive
     * @param showZoneNegative
     */
    populateStoreyWithMarkers(
        diagnostic: Mesurage,
        currentStorey: cn_storey,
        idBien: string,
        idNiveau: string,
        showComments = true,
        showZonePositive = true,
        showZoneNegative = true
    ) {
        // Selon la configuration, on affiche ou non les commentaires présents de cet étage
        currentStorey.markers = showComments ? currentStorey.markers : [];
        // On n'affiche dans le storey uniquement les markers correspondant
        const zones = diagnostic.zonesMesures.valeurs.filter((z) => z.idBien === idBien && z.idNiveau === idNiveau);
        zones?.forEach((zone) => {
            const jsonParsed = JSON.parse(zone.markerJson);
            // Affichage des zones "comptabilisées"
            if (showZonePositive && jsonParsed.typeZone === 0) {
                currentStorey.markers.push(CndiagMarkerZone.unserialize(jsonParsed, currentStorey) as CndiagMarkerZone);
            }
            // Affichage des zones "décomptées"
            if (showZoneNegative && jsonParsed.typeZone === 1) {
                currentStorey.markers.push(CndiagMarkerZone.unserialize(jsonParsed, currentStorey) as CndiagMarkerZone);
            }
        });
    }

    /**
     * Ajout un marker dans la liste des markersJson de l'espace
     * @param selected
     * @param zoneMesure
     * @param diagnostic
     */
    addZoneMesureToNiveau(selected: CndiagMarkerZone, zoneMesure: ZoneMesure, diagnostic: Diagnostic) {
        // serialize le marker pour le sauvegarder en base de données
        zoneMesure.markerJson = JSON.stringify(selected.serialize());
        (diagnostic.contenuDiagnostic as Mesurage).zonesMesures.valeurs.push(zoneMesure);
    }

    updateZoneMesureToNiveau(markerToUpdate: CndiagMarkerZone, diagnostic: Diagnostic) {
        // Pour chaque marker du storey, on le serialize et on l'enregistre dans l'espace
        const index = (diagnostic.contenuDiagnostic as Mesurage).zonesMesures.valeurs.map((z) => z.id).indexOf(markerToUpdate.idZone);
        if (index > -1) {
            (diagnostic.contenuDiagnostic as Mesurage).zonesMesures.valeurs[index].markerJson = JSON.stringify(markerToUpdate.serialize());
        }
    }

    deleteZoneToNiveau(markerToUpdate: CndiagMarkerZone, diagnostic: Diagnostic) {
        (diagnostic.contenuDiagnostic as Mesurage).zonesMesures.valeurs = (diagnostic.contenuDiagnostic as Mesurage).zonesMesures.valeurs.filter(
            (z) => z.id !== markerToUpdate.idZone
        );
    }
}
