<div class="mpca-material-modal">
    <!-- Header -->
    <div class="header">Sélection du matériaux</div>

    <!-- Body -->
    <div class="content">
        <div class="search-container" [formGroup]="formModal">
            <div class="search-bar">
                <lib-icon matListIcon>search</lib-icon>
                <input matInput type="text" formControlName="searchInput" />
                <div (click)="clearSearch()" *ngIf="formModal.get('searchInput').value != ''" class="clear-input">
                    <lib-icon matListIcon>close</lib-icon>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="formModal.get('searchInput').value == ''">
            <div class="col">
                <div class="col-header">
                    <p>Catégorie</p>
                </div>
                <ul>
                    <li
                        *ngFor="let cat of materiauxTree"
                        (click)="selectCategorie(cat)"
                        [class.selected]="selectedCategorie?.nom == cat.nom"
                    >
                        {{ cat.nom }}
                    </li>
                </ul>
            </div>
            <div class="col">
                <div class="col-header">
                    <p>Sous-catégorie</p>
                </div>
                <ul *ngIf="selectedCategorie">
                    <li
                        *ngFor="let oc of selectedCategorie.ouvrageOuComposant"
                        (click)="selectOuvrageComposant(oc)"
                        [class.selected]="selectedOuvrageComposant?.nom == oc.nom"
                    >
                        {{ oc.nom }}
                    </li>
                </ul>
            </div>
            <div class="col">
                <div class="col-header">
                    <p>Matériaux</p>
                </div>
                <ul *ngIf="selectedOuvrageComposant">
                    <li
                        *ngFor="let m of selectedOuvrageComposant.partieOuvrageOuComposant"
                        [class.selected]="selectedMateriaux?.nom == m.nom"
                        (click)="selectMateriaux(m)"
                    >
                        {{ m.nom }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="search-panel" *ngIf="formModal.get('searchInput').value != ''">
            <h2>Résultats de la recherche</h2>
            <div *ngIf="searchResult.categorie.length > 0">
                <h3>Catégories</h3>
                <ul>
                    <li
                        *ngFor="let cat of searchResult.categorie"
                        [innerHtml]="cat | highlightSearchPipe: formModal.get('searchInput').value"
                        (click)="clickSearchResult('categorie', cat)"
                    ></li>
                </ul>
                <hr />
            </div>
            <div *ngIf="searchResult.ouvrageOuComposant.length > 0">
                <h3>Ouvrage ou composant</h3>
                <ul>
                    <li
                        *ngFor="let oc of searchResult.ouvrageOuComposant"
                        [innerHtml]="oc | highlightSearchPipe: formModal.get('searchInput').value"
                        (click)="clickSearchResult('ouvrageComposant', oc)"
                    ></li>
                </ul>
                <hr />
            </div>
            <div *ngIf="searchResult.materiaux.length > 0">
                <h3>Matériaux</h3>
                <ul>
                    <li
                        *ngFor="let mat of searchResult.materiaux"
                        [innerHtml]="mat | highlightSearchPipe: formModal.get('searchInput').value"
                        (click)="clickSearchResult('materiaux', mat)"
                    ></li>
                </ul>
            </div>
            <h3
                *ngIf="
                    searchResult.categorie.length == 0 &&
                    searchResult.ouvrageOuComposant.length == 0 &&
                    searchResult.materiaux.length == 0
                "
            >
                Aucun résultat
            </h3>
        </div>
    </div>

    <!-- Footer -->
    <div class="footer">
        <div class="group-button">
            <button mat-flat-button (click)="cancel()">Annuler</button>

            <button
                mat-flat-button
                color="primary"
                (click)="selectAndClose()"
                [disabled]="selectedMateriaux == undefined"
                data-cy="selectionner-mpca-button"
            >
                Sélectionner
            </button>
        </div>
    </div>
</div>
