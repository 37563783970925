<h1 mat-dialog-title class="epi-title">Dupliquer les données</h1>

<div mat-dialog-content>
    <div *ngIf="zones.length" class="required">Choisir une zone semblable</div>
    <div *ngIf="!zones.length">Aucune donnée trouvée</div>
    <div class="d-flex flex-column-reverse">
        <div class="p-2">
            <mat-radio-group [(ngModel)]="choiceSelected" class="ml-2 d-flex flex-column">
                <mat-radio-button class="example-radio-button" *ngFor="let choice of zones" [value]="choice">
                    {{ choice }}
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-stroked-button color="accent" (click)="dialogRef.close()">
        <span>Annuler</span>
    </button>

    <button mat-raised-button class="ml-2" color="accent" (click)="onValider()">
        <span>Valider</span>
    </button>
</div>
