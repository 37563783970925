import {
    cn_contour,
    cn_freeform_handler,
    cn_marker,
    cn_marker_handler,
    cn_mouse_event,
    cn_polygon_handler,
    cn_sampling,
    cn_storey,
    cn_svg_map,
    cn_svg_tool_sampling_creation,
    cnx_clone,
} from '@acenv/cnmap-editor';
import { LevelToDisplay } from 'src/app/modules/diagnostics/hap/model/hap.model';
import { CndiagMarker } from '../model/cndiag-marker.model';

export class CndiagSampleMarker extends cn_sampling {
    idReference: string;

    constructor(storey: cn_storey, type?: string, free_position?: boolean) {
        super(storey, type, free_position);
    }
    /**
     * En plus du traitement parent, on map le level et l'idReference
     * @returns
     */
    serialize() {
        const markerJson = super.serialize();
        markerJson.idReference = this.idReference;

        return markerJson;
    }

    /**
     * En plus du traitement parent, on map le level et l'idReference
     * @param json
     * @param storey
     * @returns
     */
    static unserialize(json, storey): CndiagSampleMarker {
        let marker = new CndiagSampleMarker(storey);
        const markerTemp = cn_marker.unserialize(json, storey);
        marker = Object.assign(marker, markerTemp);
        marker.support = json.support || 'wall';
        marker.zpso = json.zpso || '';
        marker.control_result = json.control_result || '';
        marker.free_position = json.free_position || false;

        if (typeof json.idReference === 'string') marker.idReference = json.idReference;

        return marker;
    }
}

export class CndiagToolSamplingCreation extends cn_svg_tool_sampling_creation {
    referenceToMarker: any;
    currentLevel: LevelToDisplay;
    _edition_handler: cn_marker_handler;

    /**
     * @param svgMap
     */
    constructor(svgMap: cn_svg_map) {
        super(svgMap, true);
    }

    //***********************************************************************************
    /**
     * Builds a new marker
     * TODO: derivate this in order to manage marker derivates
     * @param {cn_storey} storey
     * @returns {cn_marker}
     */
    _build_marker(storey) {
        var marker = new CndiagSampleMarker(this._map._storey, '', this.free_position);
        marker.idReference = this.referenceToMarker.id;
        marker.label = `BP${this.referenceToMarker.numero}`;
        marker.support = this.support;
        return marker;
    }

    //***************************************************************
    _initialize_freeform_handler() {
        this._handler = new cn_freeform_handler(50, 20);
        this._handlers.push(this._handler);

        this._ghost = null;
        var obj = this;

        //*** event filter */
        this._handler.allow_creation = function (mouse_event) {
            return !obj._find_marker(mouse_event);
        };

        this._handler.allow_freeform = () => {
            return this._allow_freeform_marker() && (!this._type || this._type === 'eraser');
        };

        //*** Callback upon creation */
        this._handler.on('start_creation', function () {
            obj._terminate_edition();
        });

        this._handler.on('change', function (mouse_event) {
            // @ts-ignore
            if (obj._handler.cursor && obj._handler.vertices.length <= 1) {
                if (!obj._ghost || obj._ghost.storey != obj._handler.creation_storey) {
                    obj._ghost = obj._build_marker(obj._handler.creation_storey);
                }

                //*** check filter */
                obj._ghost.place_from_mouse(mouse_event);
                // @ts-ignore
                if (obj._ghost.element && !obj.check_marker(obj._ghost)) {
                    obj._ghost.element = null;
                    obj._ghost.position = cnx_clone(mouse_event.mouse_world);
                    if (obj._ghost.config.head || obj._ghost.config.line) {
                        obj._ghost.normal = [0.707, -0.707, 0];
                    } else {
                        obj._ghost.normal = [0, 0, 0];
                    }
                }
            } else {
                obj._ghost = null;
            }
        });

        //*** Callback upon creation */
        this._handler.on('end_creation', function (mouse_event) {
            obj._ghost = null;

            if (obj._handler.vertices.length <= 0) {
                obj._initialize_creation();
                return;
            }

            var new_marker = obj._build_marker(obj._handler.creation_storey);

            if (obj._handler.vertices.length == 1) {
                if (obj._force_freeform) {
                    obj._initialize_freeform_handler();
                    return;
                }
                new_marker.place_from_mouse(mouse_event);
                if (!obj._check_new_marker_creation(new_marker)) {
                    obj._initialize_creation();
                    return;
                }
            } else {
                const is_3d = mouse_event.camera.is_3d();
                new_marker.shape = new cn_contour();
                new_marker.set_shape_3d(obj._handler.vertices, is_3d);
            }

            new_marker.get_scene().update_deep();

            if (obj._view_overlay) obj._view_overlay.update_marker(new_marker);

            obj._initialize_creation();
            obj._marker_created(new_marker);
        });
    }
    /**
     * Called when a new marker is created
     * @param {cn_marker} marker
     */
    _marker_created(marker) {
        var obj = this;
        obj._insert_marker(marker);
        obj._initiate_edition([marker]);
        // const input = this._edition_handler.buildMarkerInput(marker, false);
        obj._map.refresh();
        this.call('sample_created', marker);
    }

    // /**
    //  * Overload the called method, which searches for elements under the cursor in creation mode.
    //  * This deactivates perimeter selection on hover in mode 0 : simple marker
    //  * @param {cn_marker} marker
    //  */
    // _find_other_element(mouse_event) {
    //     return null;
    // }

    // /**
    //  * Overload the called method, which searches for elements under the cursor in creation mode.
    //  * This deactivates perimeter selection on hover in mode 1 : area marke
    //  * @param {cn_marker} marker
    //  */
    // _find_marker(mouse_event: cn_mouse_event) {
    //     if (this._view_overlay) {
    //         var elt = this._view_overlay.find_mouse_element(mouse_event);
    //         if (!elt) return false;
    //         return elt.element instanceof cn_marker;
    //     }
    //     return false;
    // }

    // /**
    //  * Overload method called when a new marker is created
    //  * @param {cn_marker} marker
    //  */
    // _marker_created(marker) {
    //     var obj = this;
    //     obj._insert_marker(marker);
    //     obj._initiate_edition([marker]);
    //     this._edition_handler.buildMarkerInput(marker, false);
    //     obj._map.refresh();
    //     //call l'event creation. Si on call marker_input alors cela fait apparaitre la pop up
    //     this.call('creation', marker);
    // }

    // _initialize_handler() {
    //     super._initialize_shape_handler();

    //     // Permet de saisir un marker sur un autre marker en mode 1
    //     this._handler.allow_creation = (mouseWorld, cam) => {
    //         return true;
    //     };
    // }

    // // ***************************************************************
    // _initialize_freeform_handler() {
    //     super._initialize_freeform_handler();

    //     // Permet de saisir un marker sur un autre marker en mode 0
    //     this._handler.allow_creation = (mouseWorld, cam) => {
    //         return true;
    //     };
    // }

    // setCurrentLevel(level: LevelToDisplay) {
    //     this.currentLevel = level;
    //     if (this.currentLevel === LevelToDisplay.PRELEVEMENT || this.currentLevel === LevelToDisplay.BESOIN) {
    //         this.set_marker_mode(0);
    //     } else {
    //         // area marker
    //         this.set_marker_mode(1);
    //     }
    // }
}
