import { Component, OnInit } from '@angular/core';
import { FormContext } from '../../../../../../model/rule/form-context.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/commons-lib';
import { InterventionService } from '../../../../../../services/intervention.service';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { FormService } from '../../../../../../services/form.service';
import { Bouton, Electricite, ElectriciteConfig } from '../../../model/electricite.model';
import { takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { Intervention } from '../../../../../../model/intervention.model';
import { EtatValidation } from '../../../../../../model/etat-progression.model';
import { EtatProgressionService } from '../../../../../../services/etat-progression.service';
import { ActivatedRoute } from '@angular/router';
import { ElectriciteService } from '../../../services/electricite.service';
import { PointDeControleBien } from '../../../../../../model/point-de-controle.model';
import { TypeCommentaire, enumTypesCommentaire } from '../../../../../../model/type-commentaire.model';
import { CalculatriceModaleComponent } from '../../../../../shared/calculatrice-modale/calculatrice-modale.component';
import { MatDialog } from '@angular/material/dialog';
import { SectionHelp } from '../../../../../../model/section-help.model';
import { TypePrestationEnum } from 'src/app/model/type-prestation.model';
import * as moment from 'moment';
import { Moment } from 'moment';
import { date } from '@storybook/addon-knobs';

@Component({
    selector: 'app-intervention',
    templateUrl: './intervention.component.html',
    styleUrls: ['./intervention.component.scss'],
})
export class InterventionComponent extends BaseComponent implements OnInit {
    readonly COMMENT_TYPES: TypeCommentaire[] = [enumTypesCommentaire.NOTE_PERSONNELLE, enumTypesCommentaire.REMARQUE];
    readonly COMMENT_TYPES_COMPTEUR: TypeCommentaire[] = [
        enumTypesCommentaire.MOTIF_NON_RENSEIGNE,
        enumTypesCommentaire.NOTE_PERSONNELLE,
        enumTypesCommentaire.REMARQUE,
    ];
    helpToDisplay: SectionHelp = new SectionHelp("Age de l'installation", TypePrestationEnum.ELECTRICITE, 'age-installation');

    formIntervention: FormGroup;
    currentContenuDiagnostic: Electricite;
    diagnostic: Diagnostic;
    intervention: Intervention;
    readonlyMode = false;
    fournisseursToDisplay: string[] = [];
    maxDate: Date = new Date();
    private _boutons: Bouton[];
    label = "Année d'installation";
    datesSurlignees: Moment[] = [];

    constructor(
        private matDialog: MatDialog,
        private diagnosticService: DiagnosticService,
        private interventionService: InterventionService,
        private etatProgressionService: EtatProgressionService,
        private electriciteService: ElectriciteService,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private formService: FormService
    ) {
        super();
    }

    ngOnInit(): void {
        combineLatest([
            this.interventionService.getCurrentIntervention(),
            this.diagnosticService.getCurrentDiagnostic(),
            this.electriciteService.electriciteConfig$,
        ])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([intervention, diagnostic, electriciteConfig]) => {
                if (!electriciteConfig) {
                    return;
                }
                this.readonlyMode = this.diagnosticService.isReadOnlyMode(intervention, diagnostic);
                this.datesSurlignees = this.getDatesSurlignees();
                this._boutons = (electriciteConfig as ElectriciteConfig).boutons;
                this.fournisseursToDisplay = this.placerEnedisEnPremier(
                    electriciteConfig.distributeurs.sort((a, b) => a.description.localeCompare(b.description)).map((dist) => dist.description)
                );
                this.intervention = intervention;
                const relationBienPrincipal = Intervention.getRelationInterventionBienPrincipal(this.intervention);
                this.diagnostic = diagnostic;

                if (!this.diagnostic.pointsDeControleBiens[0]) {
                    this.diagnostic.pointsDeControleBiens[0] = new PointDeControleBien();
                    this.diagnostic.pointsDeControleBiens[0].idBien = relationBienPrincipal.bien.id;
                    this.diagnostic.pointsDeControleBiens[0].nomBien = relationBienPrincipal.bien.nom;
                }

                this.currentContenuDiagnostic = this.diagnostic.contenuDiagnostic as Electricite;
                this.formIntervention = this.formBuilder.group({
                    dateInstallation: this.formService.createFormControl(
                        'dateInstallation',
                        new FormContext('dateInstallation.valeur', this.currentContenuDiagnostic, null, this.ngUnsubscribe)
                    ),
                    dateInstallationND: this.formService.createFormControl(
                        'dateInstallationND',
                        new FormContext('dateInstallation.checked', this.currentContenuDiagnostic, [], this.ngUnsubscribe)
                    ),
                    dateInstallationEstimation: this.formService.createFormControl(
                        'dateInstallationEstimation',
                        new FormContext('dateInstallation.estimation', this.currentContenuDiagnostic, [], this.ngUnsubscribe)
                    ),
                    numeroCompteur: this.formService.createFormControl(
                        'numeroCompteur',
                        new FormContext('numeroCompteur.valeur', this.currentContenuDiagnostic, [], this.ngUnsubscribe)
                    ),
                    numeroCompteurNR: this.formService.createFormControl(
                        'numeroCompteurNR',
                        new FormContext('numeroCompteur.checked', this.currentContenuDiagnostic, [], this.ngUnsubscribe)
                    ),
                    fournisseur: this.formService.createFormControl(
                        'fournisseur',
                        new FormContext('fournisseur.valeur', this.currentContenuDiagnostic, [], this.ngUnsubscribe)
                    ),
                    fournisseurND: this.formService.createFormControl(
                        'fournisseurND',
                        new FormContext('fournisseur.checked', this.currentContenuDiagnostic, [], this.ngUnsubscribe)
                    ),
                    justificationCompteur: this.formService.createFormControl(
                        'justificationCompteur',
                        new FormContext('numeroCompteur.valeur', this.currentContenuDiagnostic, [], this.ngUnsubscribe)
                    ),
                });

                this.formIntervention.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
                    this.checkValidity();
                });
            });
    }

    onfocusNumeroCompteur() {
        this.formIntervention.patchValue({ numeroCompteurNR: false });
    }
    onfocusDateInstallation() {
        this.formIntervention.patchValue({ dateInstallationNR: false });
    }

    numeroCompteurNRModified(checked: boolean) {
        this.formIntervention.patchValue({ numeroCompteur: checked ? null : '' });
    }

    yearChanged(selectedYear) {
        this.formIntervention.patchValue({ dateInstallation: selectedYear });
        this.uncheckND();
    }

    dateNDModified(checked: boolean) {
        this.formIntervention.patchValue({ dateInstallation: checked ? null : '' });
    }

    dateEstimationModified(checked: boolean) {
        this.formIntervention.patchValue({ dateInstallationEstimation: checked });
    }

    uncheckND() {
        this.formIntervention.patchValue({ dateInstallationND: false });
    }

    fournisseurNDModified(checked: boolean) {
        this.formIntervention.patchValue({ fournisseur: checked ? null : '' });
    }

    fournisseurChanged(value: any) {
        this.formIntervention.patchValue({ fournisseurND: false });
    }

    /**
     * Mode saisie compteur
     * saisie / caluculatrice
     */
    openNumKeyboard(value: string) {
        return this.matDialog
            .open(CalculatriceModaleComponent, {
                data: {
                    mode: 'saisie',
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    if (value == 'numeroCompteur') {
                        this.formIntervention.get('numeroCompteur').setValue(result.resultat);
                        this.onfocusNumeroCompteur();
                    }
                    if (value == 'dateInstallation') {
                        this.formIntervention.get('dateInstallation').setValue(result.resultat);
                        this.onfocusDateInstallation();
                    }
                }
            });
    }

    private checkValidity() {
        let etat: EtatValidation = 'INVALID';
        if (this.formIntervention && this.formIntervention.valid) {
            etat = 'VALID';
        }
        const code = this.route.snapshot.data['code'];
        this.diagnostic.contenuDiagnostic['numeroCompteur'].valeur = this.formIntervention.get('numeroCompteur').value;
        this.etatProgressionService.updateDiagnostic(code, etat, this.diagnostic);
    }

    private placerEnedisEnPremier(tableau) {
        var index = tableau.indexOf('Enedis');

        var valeur = tableau[index];

        var tab2 = [];
        var partOne = tableau.slice(0, index);
        var partTwo = tableau.slice(index + 1, tableau.length);

        tab2.push(valeur);
        tab2 = tab2.concat(partOne, partTwo);

        return tab2;
    }

    getDatesSurlignees(): Moment[] {
        let dates: Moment[] = [];
        const format = 'YYYY';
        let dateNow = moment(new Date(), format);
        let dateRet = dateNow.year() - 16;

        dates.push(moment(new Date(dateRet.toString())));
        return dates;
    }
}
