import { Injectable, Type } from '@angular/core';
import { ITypePrestationInterface } from '../../../../services/interfaces/type-prestation.interface';
import { Diagnostic } from '../../../../model/diagnostic.model';
import { ContenuDiagnostic } from '../../../../model/diagnostic-contenu.model';
import { Intervention } from '../../../../model/intervention.model';
import { Rule } from '../../../../model/regle.model';
import { ContactData, IDiagReportData, InterventionReportData } from '../../../../model/rapport.model';
import { TypePrestation, TypePrestationEnum } from '../../../../model/type-prestation.model';
import { Commentaire } from '../../../../model/commentaire.model';
import { CommentairePredefini } from '../../../../model/commentaire-predefini.model';
import { Hap, LevelToDisplay } from '../model/hap.model';
import { ObjectifBlockComponent } from '../modules/report/blocks/objectif-block/objectif-block.component';
import { CommentairesBlockComponent } from '../modules/report/blocks/commentaires-block/commentaires-block.component';
import { DATE_FORMAT_FRANCAIS_SANS_HEURE_MOMENT, URL_MS_REPORT_FILE } from 'src/app/shared/constants/cndiag.constants';
import { DocumentsService } from 'src/app/services/documents.service';
import { PerimetreBlockComponent } from '../modules/report/blocks/perimetre-block/perimetre-block.component';
import { TypeReport } from 'src/app/model/reference-prestation.model';
import { EspaceData, HapReportData, PerimetreData, PrelevementData, PreparationData } from '../model/hapData.model';
import { BonCommandeAnalyseAdmin } from 'src/app/model/bon-commande.model';
import { Observable, of } from 'rxjs';
import { Espace } from '../../../../model/espace.model';
import { BesoinBlockComponent } from '../modules/report/blocks/besoin-block/besoin-block.component';
import { PreparationBlockComponent } from '../modules/report/blocks/preparation-block/preparation-block.component';
import { EtapeDiagnosticPreparationHAP } from '../../../../model/diagnostic-etape.model';
import { Contact } from '../../../../model/contact.model';
import { AddressUtils } from '../../../../utils/addressUtils';
import * as moment from 'moment/moment';
import { ReportagePhotoService } from '../../../../services/reportage-photo.service';
import { HapCnMapService } from './hap-cn-map.service';
import { LegendScreenshot } from 'src/app/model/screenshot-svg.model';
import { typeCarottageToLabel, typePrelevementToLabel } from '../../../../model/prelevement-generique.model';
import { cn_storey } from '@acenv/cnmap-editor';
import { Bien, Niveau, Volume } from '../../../../model/bien.model';
import { PointDeControleService } from '../../../../services/point-de-controle.service';
import { TypeVolume } from '../../../../model/type-element-a-controler.model';

/**
 * Liste des Type de component (Class) de block de rapport disponible pour la composition
 * du contenu d'un raport
 */
export const REPORT_BLOCK_CATALOG: { [key: string]: Type<any> } = {
    ObjectifBlockComponent: ObjectifBlockComponent,
    PerimetreBlockComponent: PerimetreBlockComponent,
    BesoinBlockComponent: BesoinBlockComponent,
    PreparationBlockComponent: PreparationBlockComponent,
    CommentairesBlockComponent: CommentairesBlockComponent,
};

/**
 * Service pour le diagnostic HAP_ETUDE_SITUATION.
 */
@Injectable({
    providedIn: 'root',
})
export class TypeHapEtudeSituationService implements ITypePrestationInterface {
    private typePrestation: TypePrestation = TypePrestationEnum.HAP_ETUDE_SITUATION;

    constructor(
        private readonly documentsService: DocumentsService,
        private readonly reportagePhotoService: ReportagePhotoService,
        private readonly hapCnMapService: HapCnMapService,
        private readonly pointDeControleService: PointDeControleService
    ) {}

    generateLegendForScreenshot(diagnostic: Diagnostic, conf: any): LegendScreenshot[] {
        return this.hapCnMapService.generateLegendForScreenshot(diagnostic, conf);
    }

    generateDiagnosticBonCommande(
        intervention: Intervention,
        diagnostic: Diagnostic,
        interReportData: InterventionReportData
    ): BonCommandeAnalyseAdmin {
        return undefined;
    }

    getCodeBimEquipementBien(typePrestation: TypePrestation): string[] {
        return [];
    }

    getCompletionPercentage(diagnostic: Diagnostic): number {
        return 0;
    }

    getContenuDiagnosticFromParent(diagnosticToUpdate: Diagnostic, diagnosticParent: Diagnostic): ContenuDiagnostic {
        return { ...diagnosticParent.contenuDiagnostic };
    }

    getContenuDiagnostic(typePrestation: TypePrestation): Observable<ContenuDiagnostic> {
        return of(new Hap());
    }

    getReportBlockType(componentName: string): Type<any> {
        const blockType = REPORT_BLOCK_CATALOG[componentName];
        if (!blockType) {
            console.log('Block %s not found', componentName);
        }
        return blockType;
    }

    getDiagnosticReportData(intervention: Intervention, diagnostic: Diagnostic, regles: Rule[], optionPlan: boolean): IDiagReportData {
        const diagReportData = new HapReportData();
        diagReportData.id = diagnostic.id;
        diagReportData.typePrestation = diagnostic.typePrestation;
        const contenuDiagnostic = diagnostic.contenuDiagnostic as Hap;
        diagReportData.refRapport = diagnostic.reportDatas.find((reportDataTemp) => reportDataTemp.typeReport === TypeReport.REPORT).refRapport;
        diagReportData.listeEspaces = this.buildListeEspaceReport(contenuDiagnostic.espaces.valeur);
        diagReportData.objectif = contenuDiagnostic.objectifs.valeur;
        diagReportData.preparation = this.buildPreparationRapport(contenuDiagnostic.preparation);
        if (diagnostic.recommandationsFinales.length) {
            diagReportData.recommandations = diagnostic.recommandationsFinales.map((it) => it.contenu);
        }
        if (diagnostic.constatationsFinales.length) {
            diagReportData.constatationsDiverses = diagnostic.constatationsFinales.map((it) => it.contenu);
        }

        // Liste des Documents
        diagReportData.documentsData = this.documentsService.buildDocumentsData(intervention, diagnostic);
        diagReportData.listDocuments = diagReportData.documentsData.filter((doc) => doc.afficherDansListeDocuments);
        diagReportData.annexes = diagReportData.documentsData
            .filter((doc) => doc.afficherDansRapport)
            .map((doc) => ({
                id: doc.nom,
                filename: URL_MS_REPORT_FILE + doc.idFichier,
                type: 'pdf',
            }));

        // Screenshot
        diagReportData.screenshotsPlan = diagnostic.screenshotsPlan;
        diagReportData.reportagesPhotos = this.reportagePhotoService.buildReportagePhotoData(diagnostic);

        diagReportData.etat = diagnostic.etat;
        return diagReportData;
    }

    /**
     * Génère le reportData du bon de commande
     * @param intervention
     * @param diagnostic
     */
    getDiagnosticBonCommandeData(intervention: Intervention, diagnostic: Diagnostic): IDiagReportData {
        return null;
    }

    private buildListeEspaceReport(espaces: Espace[]): EspaceData[] {
        const espacesData = [];
        espaces.forEach((espace) => {
            const espaceData: EspaceData = new EspaceData();
            espaceData.nom = espace.nom;
            espaceData.listePerimetres = [];
            espace.listePerimetres.forEach((perimetre) => {
                const perimetreData: PerimetreData = new PerimetreData();
                perimetreData.nom = perimetre.nom;
                perimetreData.typeOuvrage = perimetre.typeOuvrage ? perimetre.typeOuvrage.name : undefined;
                perimetreData.description = perimetre.description;
                perimetreData.listeZones = [];
                perimetre.listeBesoins.length &&
                    perimetre.listeBesoins.forEach((besoin) => {
                        const prelevementData: PrelevementData = new PrelevementData();
                        prelevementData.general = {
                            reference: besoin.general.reference,
                        };
                        prelevementData.details = {
                            typeCarottage: typeCarottageToLabel(besoin.details.typeCarottage),
                            typePrelevement: typePrelevementToLabel(besoin.details.typePrelevement),
                            profondeurCarottage: besoin.details.profondeurCarottage,
                        };
                        prelevementData.echantillonages = [];

                        prelevementData.donneesTechniques = {
                            lave: besoin.donneesTechniques.lave.value,
                            analyseAmiante: besoin.donneesTechniques.analyseAmiante.value,
                            pollutionSurfacique: besoin.donneesTechniques.pollutionSurfacique.value,
                            limitationFibre: besoin.donneesTechniques.limitationFibre.value,
                            analyseCouches: besoin.donneesTechniques.analyseCouches.value,
                            couchesADissocier: besoin.donneesTechniques.couchesADissocier.value,
                        };
                        perimetreData.listeBesoins.push(prelevementData);
                    });
                espaceData.listePerimetres.push(perimetreData);
            });
            espacesData.push(espaceData);
        });
        return espacesData;
    }

    getTypePrestation(): TypePrestation {
        return this.typePrestation;
    }

    isItemAlreadyFilled(diagnostic: Diagnostic, type: string, itemId: string): boolean {
        return false;
    }

    prepareFilteredCommentsForReport(diagnosticData: IDiagReportData, hiddenComments: Map<string, string[]>) {}

    prepareForm(intervention: Intervention, contenuDiagnostic: Hap) {}

    prepareSpecificComments(diagnostic: Diagnostic, commentairesGeneraux: Commentaire[], commentaires: CommentairePredefini[]): Commentaire[] {
        return [];
    }

    private buildPreparationRapport(preparation: EtapeDiagnosticPreparationHAP): PreparationData {
        const preparationData = new PreparationData();
        // DICT
        preparationData.soumisDict = preparation.valeur.dict.soumisDICT;
        preparationData.dateDemandeDict = preparation.valeur.dict.demande.dateDemande
            ? moment(preparation.valeur.dict.demande.dateDemande).format(DATE_FORMAT_FRANCAIS_SANS_HEURE_MOMENT)
            : '';
        preparationData.dateReponse = preparation.valeur.dict.reponse.dateReponse
            ? moment(preparation.valeur.dict.reponse.dateReponse).format(DATE_FORMAT_FRANCAIS_SANS_HEURE_MOMENT)
            : '';
        // Visite reconnaissance
        preparationData.visiteReconnaissanceAmont = preparation.valeur.reconnaissance.reconnaissanceNecessaire;
        preparationData.dateVisiteReconnaissance = preparation.valeur.reconnaissance.dateReconnaissance
            ? moment(preparation.valeur.reconnaissance.dateReconnaissance).format(DATE_FORMAT_FRANCAIS_SANS_HEURE_MOMENT)
            : '';
        preparationData.accompagnateursVisiteReconnaissance = preparation.valeur.reconnaissance.contacts.map((c) => this.buildContact(c));
        // Modalité accès et circulation
        preparationData.modaliteAcces = preparation.valeur.modalites;
        // Visite terrain
        preparationData.accompagnateursVisiteTerrain = preparation.valeur.terrain.contacts.map((c) => this.buildContact(c));

        return preparationData;
    }

    private buildContact(contact: Contact): ContactData {
        if (contact) {
            return new ContactData(
                contact.nom,
                contact.prenom,
                AddressUtils.getFormatedAddress(contact.adresse),
                contact.adresse.codePostal,
                contact.adresse.ville,
                contact.presence,
                contact.type
            );
        }
        return undefined;
    }

    prepareStoreyForScreenshot(diagnostic: Diagnostic, currentStorey: cn_storey, conf: any) {
        const espaceId = conf.espace?.espace?.id;
        const espace = (diagnostic.contenuDiagnostic as Hap).espaces.valeur.find((esp) => esp.id === espaceId);
        this.hapCnMapService.populateStoreyWithMarkers(
            diagnostic,
            currentStorey,
            espace,
            LevelToDisplay.PRELEVEMENT,
            this.hapCnMapService.getHapConf(conf),
            this.hapCnMapService.getFilters(conf)
        );
    }

    deplaceVolume(diagnostic: Diagnostic, volumeSource: Volume, niveauDestination: Niveau, currentBien: Bien) {
        this.pointDeControleService.deplaceVolume(diagnostic, volumeSource, niveauDestination, currentBien);
    }

    mergeNiveau(diagnostic: Diagnostic, niveauSource: Niveau, niveauDestination: Niveau, currentBien: Bien) {
        this.pointDeControleService.mergeNiveau(diagnostic, niveauSource, niveauDestination, currentBien);
    }

    mergeVolume(diagnostic: Diagnostic, volumeSource: Volume, volumeDestination: Volume, currentBien: Bien, typeVolume: TypeVolume) {
        this.pointDeControleService.mergeVolume(diagnostic, volumeSource, volumeDestination, currentBien, typeVolume);
    }

    deplaceEquipement(idEquipement: string, diagnostic: Diagnostic, volumeDestination: Volume, bien: Bien) {
        this.pointDeControleService.deplaceEquipement(idEquipement, diagnostic, volumeDestination, bien);
    }
}
