import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/commons-lib';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Contact } from 'src/app/model/contact.model';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { PaginatedDataSource } from '../../../../shared/paging/page';
import { ContactApiService } from '../../../../services/contact-api.service';
import { BehaviorSubject } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { SessionStoragePaginationUtils } from '../../../../utils/session-storage-pagination.utils';
import { DEBOUNCE_TIME_VALUE, INITIAL_PAGE_SIZE, SESSION_STORAGE_KEY_CONTACT } from 'src/app/shared/constants/admin.constants';
import { URL_GESTION_CONTACTS_CONSULTER, URL_GESTION_CONTACTS_EDIT } from 'src/app/shared/constants/url.constants';

export const personnesColonnes = ['nom', 'prenom', 'nomEntrepriseParent', 'adresse', 'actions'];
export const entreprisesColonnes = ['nom', 'siret', 'adresse', 'actions'];

export const DEFAULT_SORT = { direction: 'asc', active: 'nom' };

@Component({
    selector: 'app-tableau-contacts',
    templateUrl: './tableau-contacts.component.html',
    styleUrls: ['./tableau-contacts.component.scss'],
})
export class TableauContactsComponent extends BaseComponent implements OnInit, OnDestroy {
    @Input()
    displayedColumns: string[] = personnesColonnes;

    @Input()
    type: 'PERSONNES' | 'ENTREPRISES' = 'PERSONNES';

    @Input()
    initialPageSize = INITIAL_PAGE_SIZE;

    dataSource: PaginatedDataSource<Contact>;
    sort: Sort;
    searchValue = SessionStoragePaginationUtils.getInitialReseach(SESSION_STORAGE_KEY_CONTACT);
    private search = new BehaviorSubject('');

    constructor(
        private readonly contactApiService: ContactApiService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly router: Router
    ) {
        super();
        this.sort = SessionStoragePaginationUtils.getInitialSort(SESSION_STORAGE_KEY_CONTACT, DEFAULT_SORT);
    }

    ngOnInit(): void {
        if (this.type === 'PERSONNES') {
            this.displayedColumns = personnesColonnes;
        } else {
            this.displayedColumns = entreprisesColonnes;
        }
        this.search.pipe(debounceTime(DEBOUNCE_TIME_VALUE), distinctUntilChanged(), takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.dataSource.fetch(0, this.dataSource.size);
        });
        this.dataSource = new PaginatedDataSource<Contact>(
            (pageRequest) => {
                return this.cnSpinnerService.withSpinner(this.contactApiService.searchContacts(this.type, this.search.getValue(), pageRequest));
            },
            SessionStoragePaginationUtils.getInitialSort(SESSION_STORAGE_KEY_CONTACT, DEFAULT_SORT),
            SessionStoragePaginationUtils.getInitialPageSize(SESSION_STORAGE_KEY_CONTACT, this.initialPageSize)
        );
    }

    dupliquerContact(contact: Contact) {
        this.setSessionStorageItem();
        this.router.navigate([URL_GESTION_CONTACTS_EDIT, contact.id, 'duplicate']);
    }

    editerContact(contact: Contact) {
        this.setSessionStorageItem();
        this.router.navigate([URL_GESTION_CONTACTS_EDIT, contact.id]);
    }

    consulter(contact: Contact) {
        this.setSessionStorageItem();
        this.router.navigate([URL_GESTION_CONTACTS_CONSULTER, contact.id]);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    private setSessionStorageItem() {
        SessionStoragePaginationUtils.setSessionStorageItem(SESSION_STORAGE_KEY_CONTACT, this.dataSource.size, this.search.getValue(), this.sort);
    }

    onKeyupSearchContacts($event: any) {
        this.search.next($event.target.value);
        this.setSessionStorageItem();
    }

    sortContacts($event: Sort) {
        this.sort = $event;
        this.dataSource.sortBy($event);
        this.setSessionStorageItem();
    }
}
