import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CnDiagSharedModule } from '../../shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { LibIconModule } from 'src/app/commons-lib';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTimepickerModule } from 'mat-timepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { PipesModule } from '../../../pipes/pipes.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CreationExportSyntheseComponent } from './creation-export-synthese/creation-export-synthese.component';
import { TableauSynthesesComponent } from './tableau-syntheses/tableau-syntheses.component';
import { GestionSynthesesComponent } from './gestion-syntheses.component';
import { MatChipsModule } from '@angular/material/chips';

@NgModule({
    declarations: [CreationExportSyntheseComponent, TableauSynthesesComponent, GestionSynthesesComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        ReactiveFormsModule,
        CnDiagSharedModule,
        MatButtonModule,
        LibIconModule,
        MatToolbarModule,
        MatIconModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatTimepickerModule,
        MatSelectModule,
        FormsModule,
        MatTableModule,
        MatSortModule,
        PipesModule,
        MatMenuModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatDividerModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatChipsModule,
    ],
})
export class GestionSynthesesModule {}
