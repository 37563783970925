import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-expension-panel',
    templateUrl: './expension-panel.component.html',
    styleUrls: ['./expension-panel.component.scss'],
})
export class ExpensionPanelComponent implements OnInit {
    @Input()
    panelOpenState = true;

    @Input()
    theme = 'default';

    @Input()
    togglePosition = 'before';

    constructor() {}

    ngOnInit(): void {}
}
