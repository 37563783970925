import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MpcaMetopOld } from '../../../../../model/mpca.model.old';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ListeDeroulante } from '../../../../../model/polluant-config.model';

@Component({
    selector: 'app-mpca-form-metop',
    templateUrl: './mpca-form-metop.component.html',
    styleUrls: ['./mpca-form-metop.component.scss'],
})
export class MpcaFormMetopComponent implements OnInit {
    @Output()
    metopDataChange = new EventEmitter<MpcaMetopOld>();

    @Input()
    methodeConfig: ListeDeroulante;

    @Input()
    techniqueConfig: ListeDeroulante;

    @Input()
    set outilConfig(outilConf: ListeDeroulante) {
        this.addOutils(outilConf?.items);
    }

    @Input()
    aspirationConfig: ListeDeroulante;

    @Input()
    travailHumideConfig: ListeDeroulante;

    @Input()
    set formMetop(form: FormGroup | AbstractControl) {
        this._formMetop = form;
        this.addOutils(form?.get('outil')?.value);
    }

    get formMetop(): FormGroup | AbstractControl {
        return this._formMetop;
    }
    private _formMetop: FormGroup | AbstractControl;
    public outilConfigItems: string[];
    ngOnInit() {
        console.log(this.formMetop);
        console.log((this.formMetop as FormGroup).getRawValue().autresOutils);
    }
    private addOutils(values: string[]) {
        this.outilConfigItems = this.outilConfigItems || [];
        for (const item of values) {
            if (!this.outilConfigItems.includes(item)) {
                this.outilConfigItems.push(item);
            }
        }
    }
}
