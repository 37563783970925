import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportSvgDialogComponent } from './export-svg-dialog.component';
import { ExportSvgSideNavFormComponent } from './export-svg-side-nav-form/export-svg-side-nav-form.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxResizeObserverModule } from 'ngx-resize-observer';
import { ExportSvgSubformPageComponent } from './export-svg-side-nav-form/export-svg-subform-page/export-svg-subform-page.component';
import { ExportSvgSubformPlanComponent } from './export-svg-side-nav-form/export-svg-subform-plan/export-svg-subform-plan.component';
import { ExportSvgSubformEquipmentsComponent } from './export-svg-side-nav-form/export-svg-subform-equipments/export-svg-subform-equipments.component';
import { ExportSvgSubFormBiensComponent } from './export-svg-side-nav-form/export-svg-sub-form-biens/export-svg-sub-form-biens.component';
import { LibIconModule, LibLongPressModule } from 'src/app/commons-lib';
import { PipesModule } from '../../../../pipes/pipes.module';
import { ExportSvgSubFormEspacesComponent } from './export-svg-side-nav-form/export-svg-sub-form-espaces/export-svg-sub-form-espaces.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ExportSvgSubformZonesComponent } from './export-svg-side-nav-form/export-svg-subform-zones/export-svg-subform-zones.component';
import { ExportSvgSubformHapComponent } from './export-svg-side-nav-form/export-svg-subform-hap/export-svg-subform-hap.component';

@NgModule({
    declarations: [
        ExportSvgDialogComponent,
        ExportSvgSideNavFormComponent,
        ExportSvgSubformPageComponent,
        ExportSvgSubformPlanComponent,
        ExportSvgSubformEquipmentsComponent,
        ExportSvgSubformZonesComponent,
        ExportSvgSubFormBiensComponent,
        ExportSvgSubFormEspacesComponent,
        ExportSvgSubformHapComponent,
    ],
    exports: [ExportSvgDialogComponent],
    entryComponents: [ExportSvgDialogComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatDialogModule,
        MatInputModule,
        MatCheckboxModule,
        MatSelectModule,
        MatExpansionModule,
        NgxResizeObserverModule,
        LibIconModule,
        PipesModule,
        MatTooltipModule,
        LibLongPressModule,
    ],
})
export class ExportSvgDialogModule {}
