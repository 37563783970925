<mat-chip-list>
    <ng-container *ngFor="let chip of chipsToDisplayed; trackBy: trackByChips">
        <mat-chip
            [ngClass]="chipsSelected.includes(chip) ? 'state-selected' : 'state-not-selected'"
            (click)="onClickChip(chip)"
        >
            {{ chip | findAttribute: pathValueDisplay }}
        </mat-chip>
    </ng-container>
</mat-chip-list>
