import { Pipe, PipeTransform } from '@angular/core';

/**
 * Recherche la valeur d'un attribute suivant les éléments du path passés en paramètre
 * {
 *  id: 5,
 *  isVisible : true,
 *  batiment: {
 *      nom: "batiment4"
 *      escalier : 4,
 *      porte : 9
 *  }
 * }
 *
 * Si on veut retourner le nom du batiment, pathValueDisplay sera égale à ["batiment", "nom"]
 */
@Pipe({
    name: 'findAttribute',
})
export class FindAttributePipe implements PipeTransform {
    constructor() {}

    transform(item: any, pathValueDisplay: string[] | ((item: any) => string)): string {
        if (pathValueDisplay) {
            if ('push' in pathValueDisplay) {
                const pathValueDisplayArray = pathValueDisplay as string[];
                const attributeSearching = pathValueDisplayArray[0];
                if (pathValueDisplayArray.length == 1) {
                    return item[attributeSearching];
                } else {
                    pathValueDisplay.splice(0, 1);
                    return this.transform(item[attributeSearching], pathValueDisplay);
                }
            } else {
                const callback = pathValueDisplay as (item: any) => string;
                return callback(item);
            }
        } else {
            return item;
        }
    }
}
