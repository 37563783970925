<mat-card>
    <div class="input-with-prefix">
        <mat-form-field appearance="outline" class="w-50">
            <input
                matInput
                type="text"
                (keyup)="onKeyupSearchDiagnotics($event)"
                [(ngModel)]="searchValue"
                placeholder="Recherche libre"
            />
            <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
    </div>
    <div class="d-flex flex-column">
        <table
            mat-table
            [dataSource]="dataSource"
            matSort
            (matSortChange)="onChangeSortDiagnotics($event)"
            [matSortActive]="sort?.active"
            [matSortDirection]="sort?.direction"
            matSortDisableClear="true"
        >
            <ng-container matColumnDef="nom">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
                <td mat-cell *matCellDef="let row">{{ row.nom }}</td>
            </ng-container>

            <ng-container matColumnDef="idSalesforce">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Identifiant Salesforce</th>
                <td mat-cell *matCellDef="let row">{{ row.idSalesforce }}</td>
            </ng-container>

            <ng-container matColumnDef="typePrestation">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Type de prestation</th>
                <td mat-cell *matCellDef="let row">{{ row.typePrestation }}</td>
            </ng-container>

            <ng-container matColumnDef="etatPrestation">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>État</th>
                <td mat-cell *matCellDef="let row">
                    <span class="statut-workflow" [ngClass]="row.etatPrestation">{{
                        row.etatPrestation | etatWorkflow
                    }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                    <button mat-icon-button (click)="onClickConsulter(row)">
                        <mat-icon>visibility</mat-icon>
                    </button>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="onClickDupliquerPrestationDiagnostic(row)">
                            <mat-icon>content_copy</mat-icon>
                            <span>Dupliquer</span>
                        </button>
                        <button
                            mat-menu-item
                            [disabled]="row.etatPrestation !== 'INACTIF'"
                            (click)="onClickEditerPrestationDiagnostic(row)"
                        >
                            <mat-icon>edit</mat-icon>
                            <span>Éditer</span>
                        </button>
                        <button mat-menu-item (click)="onClickChangerEtatPrestationDiagnostic(row)">
                            <mat-icon *ngIf="row.etatPrestation === 'INACTIF'">lock_open</mat-icon>
                            <mat-icon *ngIf="row.etatPrestation === 'ACTIF'">lock</mat-icon>
                            <span *ngIf="row.etatPrestation === 'INACTIF'">Activer</span>
                            <span *ngIf="row.etatPrestation === 'ACTIF'">Désactiver</span>
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator
            #paginator
            *ngIf="dataSource.page$ | async as page"
            [length]="page.totalElements"
            [pageSize]="page.size"
            [pageIndex]="page.number"
            [pageSizeOptions]="[initialPageSize, 50, 100]"
            (page)="dataSource.fetch($event.pageIndex, paginator.pageSize)"
        ></mat-paginator>
    </div>
</mat-card>
