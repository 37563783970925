<app-mpca-form-skeleton>
    <div title>Prélèvement METOP</div>
    <div form class="form" [formGroup]="formPendant" *ngIf="isLoaded">
        <div class="column">
            <mat-form-field appearance="outline">
                <mat-label>Processus</mat-label>
                <mat-select formControlName="processus">
                    <mat-option
                        *ngFor="let processus of selectedMpca.processus; let i = index"
                        [value]="processus"
                        (ngModelChange)="selectProcessus(i)"
                    >
                        {{ processus.nom }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <app-detail-processus-card
                [formTaches]="formPendant.get('formProcessus')"
                [modePrelevement]="true"
            ></app-detail-processus-card>
        </div>
        <div class="column">
            <div class="section">
                <div class="header">Informations météo</div>
                <app-information-meteo
                    [formInformationMeteo]="formPendant.get('informationMeteo')"
                ></app-information-meteo>
            </div>
            <div class="section">
                <filtres-item-list
                    [filterForm]="this.formPendant.get('listeFiltres')"
                    (add)="ajouterFiltre()"
                    (edit)="modifierFiltre($event)"
                    (delete)="supprimerFiltre($event)"
                    (duplicate)="dupliquerFiltre($event)"
                    [disabled]="disabled"
                ></filtres-item-list>
            </div>
            <div class="section">
                <mat-form-field appearance="outline">
                    <mat-label>Position générale</mat-label>
                    <mat-select formControlName="positionGenerale" required [disabled]="disabled">
                        <mat-option></mat-option>
                        <mat-option value="dosExtracteur">Dos à l'extracteur d'air</mat-option>
                        <mat-option value="faceExtracteur">Face à l'extracteur d'air</mat-option>
                        <mat-option value="coteExtracteur">A coté à l'extracteur d'air</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Posture générale</mat-label>
                    <mat-select formControlName="postureGenerale" required [disabled]="disabled">
                        <mat-option></mat-option>
                        <mat-option value="haut">Tête orientée vers le haut</mat-option>
                        <mat-option value="test">Test</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="cndiad-form-field">
                <mat-label class="required">Prélèvement matériau réalisé</mat-label>
                <app-widget-select
                    [mode]="widgetSelectModeEnum.BADGE"
                    formControlName="prelevementRealise"
                    [disabled]="disabled"
                >
                    <option value="true" datatype="icon">check</option>
                    <option value="false" datatype="icon">close</option>
                </app-widget-select>
            </div>

            <button mat-button class="bt bouton-impression" (click)="impressionEtiquette()">
                <lib-icon>printer-wireless</lib-icon>
                {{ "Imprimer l'étiquette" }}
            </button>
        </div>
    </div>
</app-mpca-form-skeleton>
