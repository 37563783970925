import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { Intervention } from 'src/app/model/intervention.model';
import { IReportComponent, ReportBlockData, InterventionReportData } from 'src/app/model/rapport.model';

@Component({
    selector: 'app-localisation-block',
    templateUrl: './localisation-block.component.html',
    styleUrls: ['./localisation-block.component.scss'],
})
export class LocalisationBlockComponent extends BaseComponent implements OnInit, IReportComponent {
    @Input() data: ReportBlockData;
    @Input() intervention: Intervention;
    @Input() interReportData: InterventionReportData;

    constructor() {
        super();
    }

    ngOnInit(): void {}
}
