<div *ngIf="style == 'horizontal'" class="increment-input-reactive horizontal" [ngClass]="disabled ? 'disabled' : ''">
    <button
        *ngIf="!noButtons"
        mat-icon-button
        matPrefix
        aria-label="Moins"
        color="primary"
        class="mx-2"
        matTooltip="{{ label }} (diminuer)"
        [class.mat-input-readonly]="readonly"
        (click)="decrementValue()"
        [disabled]="value <= this.min || disabled"
    >
        <mat-icon>remove</mat-icon>
    </button>
    <mat-form-field [ngClass]="matFormFieldClass" [class.flex-grow-1]="fullWidth" [class.mat-input-readonly]="readonly">
        <mat-label *ngIf="label != ''">{{ label }}</mat-label>
        <input
            matInput
            type="number"
            [formControl]="incrementControl"
            [value]="value"
            (change)="inputManuallyChanged($event)"
            [disabled]="disabled"
            [readonly]="readonly"
            [step]="step"
            [min]="min"
            [max]="max"
            (click)="numericKeyboard ? openNumKeyboard() : ''"
        />
        <app-mat-error *ngIf="showMatError" class="w-100 error" [control]="incrementControl"> </app-mat-error>
    </mat-form-field>
    <button
        *ngIf="!noButtons"
        mat-icon-button
        matSuffix
        aria-label="Plus"
        color="primary"
        class="mx-2"
        matTooltip="{{ label }} (augmenter)"
        [class.mat-input-readonly]="readonly"
        (click)="incrementValue()"
        [disabled]="value >= this.max || disabled"
    >
        <mat-icon>add</mat-icon>
    </button>
</div>
<div *ngIf="style == 'vertical'" class="increment-input-reactive vertical" [ngClass]="disabled ? 'disabled' : ''">
    <button
        *ngIf="!noButtons"
        mat-icon-button
        matSuffix
        aria-label="Plus"
        color="primary"
        class="mx-2"
        matTooltip="{{ label }} (augmenter)"
        [class.mat-input-readonly]="readonly"
        (click)="incrementValue()"
        [disabled]="value >= this.max || disabled"
    >
        <mat-icon>add</mat-icon>
    </button>
    <mat-form-field>
        <mat-label *ngIf="label != ''">{{ label }}</mat-label>
        <input
            matInput
            type="number"
            [formControl]="incrementControl"
            [value]="value"
            (change)="inputManuallyChanged($event)"
            [disabled]="disabled"
            [readonly]="readonly"
            [step]="step"
            [min]="min"
            [max]="max"
            (click)="numericKeyboard ? openNumKeyboard() : ''"
        />
        <app-mat-error *ngIf="showMatError" class="w-100 error" [control]="incrementControl"> </app-mat-error>
    </mat-form-field>
    <button
        *ngIf="!noButtons"
        mat-icon-button
        matPrefix
        aria-label="Moins"
        color="primary"
        class="mx-2"
        matTooltip="{{ label }} (diminuer)"
        [class.mat-input-readonly]="readonly"
        (click)="decrementValue()"
        [disabled]="value <= this.min || disabled"
    >
        <mat-icon>remove</mat-icon>
    </button>
</div>
