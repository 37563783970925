import { Inject, Injectable, InjectionToken } from '@angular/core';
import { catchError, delay, exhaustMap, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subscription, timer } from 'rxjs';
import { PingService } from './ping.service';
import { OldOfflineStorageService } from './old-offline-storage.service';
import { InterventionApiService } from './intervention-api.service';
import { BonCommandeTask, DiagnosticApiService, ReportTask } from './diagnostic-api.service';
import { PolluantConfig } from '../modules/diagnostics/polluant/model/polluant-config.model';
import * as moment from 'moment';
import { Intervention } from '../model/intervention.model';
import { BackgroundMapApiService, BackgroundMapFileData } from './background-map-api.service';
import { ReferenceApiService } from './reference-api.service';
import { Diagnostic } from '../model/diagnostic.model';
import { InterventionFileApiService } from './intervention-file-api.service';
import { InterventionFile } from '../model/intervention-file.model';
import { FileData } from '../shared/offline/offline-storage.service';
import { FileApiService } from './file-api.service';
import { UserInformationApiService } from './user-information-api.service';
import { ElectriciteConfig } from '../modules/diagnostics/electricite/model/electricite.model';
import { ConfigApiService } from './config-api.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { dbConfig } from '../shared/constants/indexeddb.constants';
import { CeeConfig } from '../modules/diagnostics/cee/model/cee.model';
import { first } from 'rxjs/operators';
import { SYNC_STATE, SyncState } from './syncState';
import { NotificationService } from '../lib/notification/notification.service';
import { AuthenticationStore } from '../lib/authentication/authentication.store';

export const SYNC_PROGRESS = new InjectionToken<BehaviorSubject<{ running: boolean; progress: number }>>('SYNC_PROGRESS');
export const syncProgress$ = new BehaviorSubject({
    running: false,
    progress: 0,
});

/**
 * Service de stockage et des récupération des données interventions dans la base de données locale.
 */
@Injectable({
    providedIn: 'root',
})
export class SynchronizationService {
    constructor(
        private interventionApiService: InterventionApiService,
        private diagnosticApiService: DiagnosticApiService,
        private configApiService: ConfigApiService,
        private offlineStorageService: OldOfflineStorageService,
        private backgroundMapApiService: BackgroundMapApiService,
        private pingService: PingService,
        private notificationService: NotificationService,
        private readonly referenceApiService: ReferenceApiService,
        @Inject(SYNC_STATE) private syncState: BehaviorSubject<SyncState>,
        private readonly interventionFileApiService: InterventionFileApiService,
        private readonly fileApiService: FileApiService,
        private readonly userInformationApiService: UserInformationApiService,
        private readonly authStore: AuthenticationStore,
        @Inject(SYNC_PROGRESS) private readonly syncProgress: BehaviorSubject<{ running: boolean; progress: number }>,
        private readonly ngxIndexedDBService: NgxIndexedDBService
    ) {}

    private offlineDelayReached = new BehaviorSubject<boolean>(false);
    private unsyncDataNumber = new BehaviorSubject<number>(0);

    /**
     * Délai en minutes audelà duquel certains avertissements s'affichent
     */
    readonly OFFLINE_DELAY_MINUTES = 840;

    private readonly SYNC_INTERVAL_SECONDS = 60;
    private readonly SYNC_RETRY_INTERVAL_SECONDS = 10;

    private readonly ONLINE_CHECK_INTERVAL_SECONDS = 20;

    /**
     * Timer de synchronisation automatique
     */
    private syncTimer$ = timer(0, this.SYNC_INTERVAL_SECONDS * 1000);

    /**
     * Souscription au timer de synchronisation automatique
     */
    private syncTimerSubscription: Subscription;

    /**
     * Souscription au timer de vérification de l'état de connexion
     */
    private onlineTimerSubscription: Subscription;

    /**
     * Souscription au timer comptant les données non synchronisées
     */
    private unsyncDataTimer: Subscription;

    /**
     * Planifie la synchronisation automatique
     */
    scheduleSync() {
        if (!this.syncTimerSubscription || this.syncTimerSubscription.closed) {
            console.log(`Programmation de la synchronisation automatique maintenant puis toutes les ${this.SYNC_INTERVAL_SECONDS} secondes`);
            this.syncTimerSubscription = this.syncTimer$
                .pipe(
                    // Si une synchro est déjà en cours, on ne relance pas une autre synchro
                    exhaustMap(() => this.synchronizeData())
                )
                .subscribe();
        }
    }

    /**
     * Déplanifie la synchronisation automatique
     */
    cancelSyncSchedule() {
        if (this.syncTimerSubscription) {
            console.log(`Déprogrammation de la synchronisation automatique`);
            this.syncTimerSubscription.unsubscribe();
        }
    }

    /**
     * Planifie la vérification de l'état de connexion
     */
    scheduleOnlineCheck() {
        if (!this.onlineTimerSubscription || this.onlineTimerSubscription.closed) {
            console.log(
                `Programmation de vérification de l'état de connexion maintenant puis toutes les ${this.ONLINE_CHECK_INTERVAL_SECONDS} secondes`
            );
            this.onlineTimerSubscription = timer(0, this.ONLINE_CHECK_INTERVAL_SECONDS * 1000)
                .pipe(
                    // Si un count est déjà en cours, on ne relance pas une autre count
                    exhaustMap(() => this.onlineCheck()),
                    catchError((e) => of(e))
                )
                .subscribe();
        }
    }

    forceOnlineCheck() {
        this.onlineCheck().pipe(first()).subscribe();
    }

    private onlineCheck(): Observable<boolean> {
        let ret: Observable<boolean> = of(null);
        forkJoin({
            onlineState: this.pingService.checkOnline(),
            numberOfItemsNoSynced: this.getDbDataNoSync(),
        }).subscribe((data) => {
            console.log('onlineCheck : ping = ' + data.onlineState + ', numberOfItemsNoSynced = ' + data.numberOfItemsNoSynced);
            if (data.onlineState === true && data.numberOfItemsNoSynced > 0) {
                this.switchPushInProgress()
                    .pipe(first())
                    .subscribe((ret) => {
                        console.log('relance onlineCheck suite au Pull');
                        this.onlineCheck().pipe(first()).subscribe();
                        return of(ret);
                    });
            } else if (data.onlineState === true) {
                return this.switchOnline().pipe(first()).subscribe();
            } else if (data.onlineState === false) {
                return this.switchOffline().pipe(first()).subscribe();
            } else {
                console.log('check online returned', data.onlineState);
                return of(null);
            }
        });

        return ret;
    }

    /**
     * Déplanifie la vérification de l'état de connexion
     */
    cancelScheduleOnlineCheck() {
        if (this.onlineTimerSubscription) {
            console.log(`Déprogrammation de la vérification de l'état de connexion`);
            this.onlineTimerSubscription.unsubscribe();
        }
    }

    /**
     * Déplanifie la mise à jour du compte de données non synchronisées (Devel uniquement)
     */
    cancelScheduleUnsycData() {
        if (this.unsyncDataTimer) {
            console.log(`Déprogrammation de la mise à jour du compte de données non synchronisées`);
            this.unsyncDataTimer.unsubscribe();
        }
    }

    /**
     * Synchronise les données modifiées sur le serveur.
     * Traitement asynchrone.
     */
    envoyerData(): Observable<boolean> {
        console.groupCollapsed('Envoi des données modifiées sur le serveur...');
        this.syncProgress.next({ running: true, progress: 0 });
        const nbEtapesTotal = 7;

        // On commence la synchro par un ping => si le serveur est indispo (hors-connexion), on réessaye toutes les X secondes jusqu'à la synchro automatique suivante
        return this.pingService.whenOnline(this.SYNC_INTERVAL_SECONDS, this.SYNC_RETRY_INTERVAL_SECONDS).pipe(
            switchMap(() => this.PushData(nbEtapesTotal)),
            // Logs et notifications
            tap(() => {
                console.log('Envoi des données hors-ligne : fin (succès)');
                this.notificationService.success('Envoi des données WizyDiag terminé avec succès');
                return of(true);
            }),
            catchError((err) => {
                console.error('Envoi des données hors-ligne : fin (erreur)', err);
                this.notificationService.error('Envoi des données hors-ligne : fin (erreur) : ' + err);
                return of(false);
            }),
            finalize(() => {
                this.syncProgress.next({ running: false, progress: 0 });
                console.log('Envoi des données (fin)');
                console.groupEnd();
            })
        );
    }

    /**
     * Synchronise l'intégralité des données interventions avec le serveur.
     * Traitement asynchrone.
     */
    synchronizeData(): Observable<any> {
        console.groupCollapsed('Synchronisation des données...');
        this.syncProgress.next({ running: true, progress: 0 });
        const nbEtapesTotal = 17;
        // On commence la synchro par un ping => si le serveur est indispo (hors-connexion), on réessaye toutes les X secondes jusqu'à la synchro automatique suivante
        return this.pingService.whenOnline(this.SYNC_INTERVAL_SECONDS, this.SYNC_RETRY_INTERVAL_SECONDS).pipe(
            switchMap(() => this.PushData(nbEtapesTotal)),

            switchMap(() => this.PullData(nbEtapesTotal)),

            // Logs et notifications
            tap(() => {
                console.log('synchronisation des données hors-ligne : fin (succès)');
                console.groupEnd();
                this.notificationService.success('Synchronisation des données WizyDiag terminé avec succès');
            }),
            tap(() => this.syncProgress.next({ running: false, progress: 0 })),
            catchError((err) => {
                this.syncProgress.next({ running: false, progress: 0 });
                console.error('synchronisation des données hors-ligne : fin (erreur)', err);
                console.groupEnd();
                this.notificationService.error('synchronisation des données hors-ligne : fin (erreur) : ' + err);
                return of(null);
            }),
            finalize(() => {
                console.log('Synchronisation des données (fin)');
            })
        );
    }

    private PushData(nbEtapesTotal: number): Observable<any> {
        console.log('Push Data Offline : begin');

        return this.pushUpdatedInterventions().pipe(
            tap(() => this.notifyProgress(nbEtapesTotal)),

            switchMap(() => this.pushFiles()),
            tap(() => this.notifyProgress(nbEtapesTotal)),

            switchMap(() => this.pushInterventionFiles()),
            tap(() => this.notifyProgress(nbEtapesTotal)),

            switchMap(() => this.pushBackgroundMaps()),
            tap(() => this.notifyProgress(nbEtapesTotal)),

            // Pousse les fichiers de documents ajoutés/modifiés/supprimés
            // switchMap(() => this.pushUpdatedFichiersDocuments()),

            // Pousse les diagnostics ajoutés/modifiés
            switchMap(() => this.pushUpdatedDiagnostics()),
            tap(() => this.notifyProgress(nbEtapesTotal)),

            switchMap(() => this.pushExportReports()),
            tap(() => this.notifyProgress(nbEtapesTotal)),

            switchMap(() => this.pushBonCommandes()),
            tap(() => this.notifyProgress(nbEtapesTotal)),

            // Count unsynchronized data
            // TODO: A voir si on rebranche
            // switchMap(() => this.countUnsync()),

            // Logs et notifications
            tap(() => {
                console.log('Push Data Offline : end (succès)');
                //this.notificationService.success('Envoi des données WizyDiag terminé avec succès');
            }),
            catchError((err) => {
                //this.syncProgress.next({ running: false, progress: 0 });
                console.error('Push Data Offline : end  (erreur)', err);
                //this.notificationService.error('Envoi des données WizyDiag terminé avec des erreurs : ' + err);
                return of(null);
            }),
            finalize(() => {
                console.log('Push Data Offline : end');
            })
        );
    }

    private PullData(nbEtapesTotal: number): Observable<any> {
        console.log('Pull Data Offline : begin');

        return this.pullUserInformation().pipe(
            tap(() => this.notifyProgress(nbEtapesTotal)),

            // Récupère les interventions et les biens
            switchMap(() => this.pullInterventionsWithBiens()),
            tap(() => this.notifyProgress(nbEtapesTotal)),

            // Récupre les configs
            //switchMap((interventions) => this.pullConfigPolluant(interventions)),
            switchMap((interventions) => combineLatest([of(interventions), this.pullConfigPolluant()])),
            tap(() => this.notifyProgress(nbEtapesTotal)),

            switchMap(([interventions]) => combineLatest([of(interventions), this.pullConfigElec()])),
            tap(() => this.notifyProgress(nbEtapesTotal)),
            // Config CEE
            switchMap(([interventions]) => combineLatest([of(interventions), this.pullConfigCee()])),
            tap(() => this.notifyProgress(nbEtapesTotal)),

            // Récupère les interventionsFiles
            switchMap(([interventions]) => this.pullInterventionFiles(interventions)),
            tap(() => this.notifyProgress(nbEtapesTotal)),

            // Récupère les fichiers
            switchMap(([interventions, interventionFiles]) => this.pullFiles(interventionFiles, interventions)),
            tap(() => this.notifyProgress(nbEtapesTotal)),

            switchMap((interventions) => this.pullBackgroundMaps(interventions)),
            tap(() => this.notifyProgress(nbEtapesTotal)),

            switchMap((interventions) => this.pullPictosReference(interventions)),
            tap(() => this.notifyProgress(nbEtapesTotal)),

            // TODO: A garder
            //  Récupère les interventions minimales pour l'agenda
            // switchMap(() => this.pullInterventionsAgenda()),

            //  Récupère les diagnostics
            switchMap(() => this.pullDiagnostics()),
            tap(() => this.notifyProgress(nbEtapesTotal)),

            // Count unsynchronized data
            // TODO: A voir si on rebranche
            // switchMap(() => this.countUnsync()),

            // Logs et notifications
            tap(() => {
                console.log('Pull Data Offline : end (succès)');
                //this.notificationService.success('Récupération des données WizyDiag terminé avec succès');
            }),
            catchError((err) => {
                //this.syncProgress.next({ running: false, progress: 0 });
                console.error('Push Data Offline : end  (erreur)', err);
                //this.notificationService.error('Récupération des données WizyDiag terminé avec des erreurs : ' + err);
                return of(null);
            }),
            finalize(() => {
                console.log('Pull Data Offline : end');
            })
        );
    }

    private notifyProgress(nbEtapesTotal: number) {
        const progress = this.syncProgress.value.progress + 100 / nbEtapesTotal;
        this.syncProgress.next({ running: true, progress });
    }

    private pullUserInformation() {
        return this.authStore
            .getCurrentUser()
            .pipe(switchMap((currentUser) => this.userInformationApiService.getUserInformationByUserId(currentUser.id)));
    }

    getSyncState(): Observable<SyncState> {
        return this.syncState.asObservable();
    }

    updateSyncState(state: SyncState) {
        this.syncState.next(state);
    }

    getOfflineDelayReached(): Observable<boolean> {
        return this.offlineDelayReached.asObservable();
    }

    updateOfflineDelayReached(reach: boolean) {
        this.offlineDelayReached.next(reach);
    }

    getUnsyncDataNumber(): Observable<number> {
        return this.unsyncDataNumber.asObservable();
    }

    // TODO: /!\ A conserver (sera rebranché plus tard)
    updateUnsyncDataNumber(value: number) {
        this.unsyncDataNumber.next(value);
    }

    offlineMessage() {
        this.notificationService.error("Cette fonctionnalité n'est pas disponible hors-ligne");
    }

    private switchOffline(): Observable<boolean> {
        return this.getSyncState().pipe(
            take(1),
            switchMap((etat) => {
                if (etat != SyncState.Offline) {
                    this.updateSyncState(SyncState.Offline);
                    return of(true);
                }
                return of(false);
            }),
            switchMap((switched) => {
                if (switched) {
                    return this.offlineStorageService.getOfflineDate().pipe(
                        switchMap((value) => (value === null ? this.offlineStorageService.setOfflineDate(new Date()) : of(value))),
                        switchMap((offlineDate) => {
                            if (moment.duration(moment().diff(moment(offlineDate))).asMinutes() >= this.OFFLINE_DELAY_MINUTES) {
                                this.updateOfflineDelayReached(true);
                            }
                            return of(true);
                        })
                    );
                }
                return of(false);
            })
        );
    }

    private switchOnline(): Observable<boolean> {
        return this.getSyncState().pipe(
            take(1),
            switchMap((etat) => {
                if (etat != SyncState.Online) {
                    this.updateSyncState(SyncState.Online);
                    this.updateOfflineDelayReached(false);
                    return of(true);
                }
                return of(false);
            }),
            switchMap((switched) => {
                if (switched) {
                    return this.offlineStorageService.deleteOfflineDate();
                }
                return of(switched);
            })
        );
    }

    /*
    private switchPushInProgress(): Observable<boolean> {
        this.getSyncState()
            .pipe(
                map((etat) => {
                    if (etat != SyncState.PushInProgess) {
                        this.updateSyncState(SyncState.PushInProgess);
                    }
                    if (this.syncProgress.value.running == false) {
                        this.envoyerData().pipe(
                            map((retour) => {
                                console.log('retour envoyerData() = ' + retour);
                                return retour;
                            }),
                            catchError((err) => of(false))
                        );
                    } else {
                        return of(false);
                    }
                }),
                catchError((err) => of(false))
            )
            .subscribe();
    }
*/

    private switchPushInProgress(): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            this.getSyncState()
                .pipe(first())
                .subscribe(
                    (etat) => {
                        if (etat != SyncState.PushInProgess) {
                            this.updateSyncState(SyncState.PushInProgess);
                        }
                        if (this.syncProgress.value.running == false) {
                            this.envoyerData().subscribe(
                                (retour) => {
                                    console.log('retour envoyerData() = ' + retour);
                                    observer.next(retour);
                                    observer.complete();
                                },
                                (err) => observer.error(err)
                            );
                        } else {
                            observer.next(false);
                            observer.complete();
                        }
                    },
                    (err) => observer.error(err)
                );
        });
    }

    // ------------------------------
    // INTERVENTIONS
    // ------------------------------
    private pushUpdatedInterventions(): Observable<Intervention[]> {
        return this.interventionApiService.pushInterventions().pipe(
            tap((interventions) => console.log(`Push des interventions`, interventions)),
            catchError((err) => {
                console.log(`Interventions non pushées (erreur)`, err);
                return of(null);
            })
        );
    }

    /**
     * Récupère les interventions présentes côté serveur et leurs biens, et les stocke en local.
     *
     * Les éventuelles modifications (interventions / biens) non-transmises au serveur restent présentes dans les interventions.
     *
     * Une valeur est émise dans l'observable est émis à la fin du traitement, puis l'observable se termine.
     */
    private pullInterventionsWithBiens(): Observable<Intervention[]> {
        return this.interventionApiService.pullInterventions().pipe(
            tap((interventions) => console.log(`Pull de toutes les interventions avec leurs biens`, interventions)),
            map((interventions) => interventions.map((i) => i.content)),
            catchError((err) => {
                console.log(`Interventions non récupérées (erreur)`, err);
                return of([]);
            })
        );
    }

    // ------------------------------
    // DIAGNOSTICS
    // ------------------------------
    private pushUpdatedDiagnostics(): Observable<Diagnostic[]> {
        return this.diagnosticApiService.pushDiagnostics().pipe(
            tap((diagnostics) => console.log(`Push des diagnostics`, diagnostics)),
            catchError((err) => {
                console.log(`Diagnostics non pushées (erreur)`, err);
                return of(null);
            })
        );
    }

    /**
     * Pull les diagnostics dans l'indexDb
     */
    private pullDiagnostics(): Observable<Diagnostic[]> {
        return this.diagnosticApiService.pullDiagnostics();
    }

    private pushInterventionFiles(): Observable<InterventionFile[]> {
        return this.interventionFileApiService.pushInterventionFiles().pipe(
            tap((interventionFiles) => console.log(`Push des interventionFiles`, interventionFiles)),
            catchError((err) => {
                console.log(`InterventionFiles non pushés (erreur)`, err);
                return of(null);
            })
        );
    }

    private pullInterventionFiles(interventions: Intervention[]): Observable<[Intervention[], InterventionFile[]]> {
        return combineLatest([of(interventions), this.interventionFileApiService.pullInterventionFiles()]);
    }

    private pushFiles(): Observable<FileData[]> {
        return this.fileApiService.pushFiles().pipe(
            tap((files) => console.log(`Push des des files`, files)),
            catchError((err) => {
                console.log(`Files non pushés (erreur)`, err);
                return of(null);
            })
        );
    }

    private pullFiles(interventionFiles: InterventionFile[], interventions: Intervention[]): Observable<Intervention[]> {
        return this.fileApiService.pullFiles(interventionFiles).pipe(
            map(() => {
                return interventions;
            })
        );
    }
    // ------------------------------
    // CONFIG
    // ------------------------------
    /**
     * Pull les configPolluant dans l'indexDb
     */
    /*
    private pullConfigPolluant(interventions: Intervention[]): Observable<PolluantConfig[]> {
        //on passe les interventions pour checker si nous avons des diag polluant et ne récupérer la config que si on en a besoin
        console.log(`Check si il y a des diags polluant dans les interventions`);
        let getConfigPoll: boolean = false;
        interventions.forEach((inter) => {
            inter.prestationsDiagnostics.forEach((presta) => {
                if (getGroupPrestation(presta.prestation.typePrestation) == 'POLLUANT') {
                    getConfigPoll = true;
                    return;
                }
            });
        });
        if (getConfigPoll) {
            console.log(`Configs polluant à récupérer`);
            return this.configApiService.pullConfigPolluant().pipe(
                tap((configs) => console.log(`Pull de toutes les configs polluant`, configs)),
                catchError((err) => {
                    console.log(`PolluantConfig non récupérées (erreur)`, err);
                    return of(null);
                })
            );
        }
        console.log(`Aucune configs polluant à récupérer`);

        return EMPTY;
    }
    */
    private pullConfigPolluant(): Observable<PolluantConfig[]> {
        return this.configApiService.pullConfigPolluant().pipe(
            tap((configs) => console.log(`Pull de toutes les configs polluant`, configs)),
            catchError((err) => {
                console.log(`PolluantConfig non récupérées (erreur)`, err);
                return of(null);
            })
        );
    }

    // TODO : Faire pour les autres config après le merge
    private pullConfigElec(): Observable<ElectriciteConfig[]> {
        return this.configApiService.pullConfigElec().pipe(
            tap((configs) => {
                console.log(`Pull de toutes les configs elec`, configs);
                if (configs?.length) {
                    // Chargement des images dans indexeDB pour le mode hors ligne
                    for (const photo of configs[0].photos) {
                        this.fileApiService
                            .downloadFileV2ressources(photo.idPhoto)
                            .pipe(first())
                            .subscribe((file) => {
                                if (file) {
                                    this.fileApiService.uploadFile(file.fileId, file.fileContent, file.fileName);
                                }
                            });
                    }
                }
            }),
            catchError((err) => {
                console.log(`ElectriciteConfig non récupérées (erreur)`, err);
                return of(null);
            })
        );
    }

    private pullConfigCee(): Observable<CeeConfig[]> {
        return this.configApiService.pullConfigCee().pipe(
            tap((configs) => console.log(`Pull de toutes les configs CEE`, configs)),
            catchError((err) => {
                console.log(`Config CEE non récupérées (erreur)`, err);
                return of(null);
            })
        );
    }

    // ------------------------------
    // IMAGES BACKGROUND MAPS
    // ------------------------------
    /**
     * Push les fonds de carte stockées dans IndexDb
     * @private
     */
    private pushBackgroundMaps(): Observable<BackgroundMapFileData> {
        return this.backgroundMapApiService.pushBackgroundImages().pipe(
            tap((element) => console.log(`Push des fonds de carte`, element)),
            catchError((err) => {
                console.log(`BackgroundMapFileData non pushés (erreur)`, err);
                return of(null);
            })
        );
    }

    /**
     * Pull les fonds de carte et renvoie la liste des interventions afin de chainer dans la fonction synchronizeData
     * @param interventions
     * @private
     */
    private pullBackgroundMaps(interventions: Intervention[]): Observable<Intervention[]> {
        return this.backgroundMapApiService.pullBackgroundImages(interventions).pipe(map(() => interventions));
    }

    // ------------------------------
    // PICTOS PRESTATIONS
    // ------------------------------
    /**
     * Pull l'ensenble des pictos associés au references contenu dans les interventions.
     * @param interventions
     * @private
     */
    private pullPictosReference(interventions: Intervention[]): Observable<Intervention[]> {
        return this.referenceApiService.pullPictosReference(interventions);
    }

    // ------------------------------
    // RAPPORTS
    // ------------------------------
    /**
     * push l'ensemble des demandes de générations des rapports faites
     * alors que l'application est en mode hors-ligne.
     * @private
     */
    private pushExportReports(): Observable<ReportTask[]> {
        return this.diagnosticApiService.pushExportReports().pipe(
            tap((element) => console.log(`Push des demandes de générations rapports`, element)),
            catchError((err) => {
                console.log(`ReportTask non pushés (erreur)`, err);
                return of(null);
            })
        );
    }

    // ------------------------------
    // Bons de commande
    // ------------------------------
    /**
     * push l'ensemble des demandes de générations des bons de commande faites
     * alors que l'application est en mode hors-ligne.
     * @private
     */
    private pushBonCommandes(): Observable<BonCommandeTask[]> {
        return this.diagnosticApiService.pushExportBonCommandes().pipe(
            tap((element) => console.log(`Push des demandes de générations des bons de commande`, element)),
            catchError((err) => {
                console.log(`BonCommandeTask non pushés (erreur)`, err);
                return of(null);
            })
        );
    }

    reinit() {
        return of(null).pipe(
            switchMap(() => combineLatest(dbConfig.objectStoresMeta.map((s) => this.ngxIndexedDBService.clear(s.store)))),
            delay(3000),
            tap(() => {
                const service = this.ngxIndexedDBService as unknown as any;
                if (service.indexedDB && service.indexedDB.deleteDatabase) {
                    service.indexedDB.deleteDatabase(service.dbConfig.name);
                }
            }),
            delay(3000),
            tap(() => window.location.reload())
        );
    }

    getDbData() {
        return combineLatest(
            dbConfig.objectStoresMeta.map((s) =>
                this.ngxIndexedDBService.count(s.store).pipe(
                    map((count) => ({ nom: s.store, count })),
                    catchError((err) => of({ nom: s.store, error: err }))
                )
            )
        );
    }

    clearTable(nom: string) {
        return this.ngxIndexedDBService.clear(nom);
    }

    /**
     * Retourn le nombre d'objet dans l'indexedDB qui ont été soit créé, modifié ou supprimé en attente de synchronisation
     */
    getDbDataNoSync(): Observable<number> {
        var tasks$ = [];
        dbConfig.objectStoresMeta.map((s) => tasks$.push(this.getNumberItemNoSync(s.store)));

        return forkJoin(tasks$).pipe(map((numbers: number[]) => numbers.reduce((a, b) => a + b, 0)));
    }

    getNumberItemNoSync(item: string): Observable<number> {
        return this.ngxIndexedDBService.getAll(item).pipe(
            map(
                (data) =>
                    data.filter((m: any) => {
                        //console.log(m);
                        return (
                            m.id != 'offlinedateid' && // on ne prendre pas en compte cette collection qui n'est pas à synchroniser
                            (m.metadata.createdLocally || m.metadata.modifiedLocally || m.metadata.deletedLocally)
                        );
                    }).length
            )
        );
    }
}
