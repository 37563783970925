/**
 * Diagnostic.
 */
import { TypePrestation } from './type-prestation.model';
import { ContenuDiagnostic } from './diagnostic-contenu.model';
import { EtapeDiagnostic } from './diagnostic-etape.model';
import { PointDeControleBien } from './point-de-controle.model';
import { Commentaire } from './commentaire.model';
import { EtatProgression } from './etat-progression.model';
import { TypeReport } from './reference-prestation.model';
import { TypeReferenceFichier } from './intervention-file.model';
import { ScreenshotSvg } from './screenshot-svg.model';
import { BonCommandeAnalyseAdmin } from './bon-commande.model';

export enum EtatDiagnostic {
    /**
     * Prestation existe mais on ne l'a jamais ouvert.
     */
    NON_DEMARRE = 'NON_DEMARRE',

    NON_REALISEE = 'NON_REALISEE',

    /** En cours */
    EN_COURS = 'EN_COURS',

    /**
     * Si tous les diagnostics sont fini ou annulé,
     * l'intervention passe à terminé,
     * et tous les diagostics passent à terminé.
     */
    TERMINE = 'TERMINE',

    /**
     * En attente, possibilité de modifier certains éléments (par exemple documents).
     * Voir {@link RaisonAttente}
     */
    EN_ATTENTE = 'EN_ATTENTE',

    /**
     * La partie terrain est fini (le rapport est validé), on ne modifie plus ce diagnostic
     */
    FINI = 'FINI',

    /**
     * Le diagnostic est annulé, on en a plus besoin.
     */
    ANNULE = 'ANNULE',
}

/**
 * Raison attente d'un diagnostic.
 */
export enum RaisonAttente {
    /** En attente de documents */
    ATTENTE_DOCUMENT = 'En attente de documents',
    /** En attente de résultats */
    ATTENTE_RESULTAT = 'En attente de résultats de prélèvement',
}

/**
 * À partir d'un etat d'un diagnostic, renvoie le label associé.
 */
export function etatDiagnosticToLabel(etatDiagnostic: EtatDiagnostic): string {
    switch (etatDiagnostic) {
        case EtatDiagnostic.NON_DEMARRE:
            return 'Non démarré';

        case EtatDiagnostic.EN_COURS:
            return 'En cours';

        case EtatDiagnostic.TERMINE:
            return 'Clôturé';

        case EtatDiagnostic.EN_ATTENTE:
            return 'En attente';

        case EtatDiagnostic.FINI:
            return 'Fini';

        case EtatDiagnostic.ANNULE:
            return 'Annulé';

        default:
            return etatDiagnostic;
    }
}

export class ReportagePhoto {
    id: string;
    nom: string;
    photos: DetailPhoto[] = [];
    typePreset?: PresetPhoto;
    autoGenerated = false;
}

/**
 * Raison attente d'un diagnostic.
 */
export type PresetPhoto = 'ANOMALIES' | 'CONSTATATIONS_DIVERSES' | 'DESCRIPTIF_DU_BIEN' | 'PRELEVEMENTS_HAP';
// À COMPLÉTER AVEC LES DIFFÉRENTS TYPES DE presets photos...

/**
 * Types de prestation (version itérable)
 */
export const presetsPhoto: PresetPhoto[] = [
    'ANOMALIES',
    'CONSTATATIONS_DIVERSES',
    'DESCRIPTIF_DU_BIEN',
    'PRELEVEMENTS_HAP',
    // À COMPLÉTER AVEC LES DIFFÉRENTS TYPES DE presets photos...
];

/**
 * À partir d'un etat d'un diagnostic, renvoie le label associé.
 */
export function presetPhotoToLabel(presetPhoto: PresetPhoto): string {
    switch (presetPhoto) {
        case 'ANOMALIES':
            return 'Anomalies';

        case 'DESCRIPTIF_DU_BIEN':
            return 'Descriptif du bien';

        case 'CONSTATATIONS_DIVERSES':
            return 'Constatations diverses';

        case 'PRELEVEMENTS_HAP':
            return 'Prélèvements HAP';

        default:
            return presetPhoto;
    }
}

export class DetailPhoto {
    idFichier: string;

    /**
     * Permet de savoir d'où est tiré la photo
     */
    typeReferenceFichier: TypeReferenceFichier;

    /**
     * Légende de la photo
     */
    legende: string;

    legendEdited = false;

    creationDate?: string;
    gpsLatitudeRef?: string;
    gpsLatitude?: string;
    gpsLongitudeRef?: string;
    gpsLongitude?: string;
    gpsAltitudeRef?: string;
    gpsAltitude?: string;

    objetReference: string;
    nonConformiteReference: string;
    localisations: string[] = [];

    constructor(
        idFichier: string,
        typeReferenceFichier: TypeReferenceFichier,
        legende: string,
        legendEdited: boolean = false,
        creationDate: string,
        gpsLatitudeRef: string,
        gpsLatitude: string,
        gpsLongitudeRef: string,
        gpsLongitude: string,
        gpsAltitudeRef: string,
        gpsAltitude: string,
        objetReference: string,
        nonConformiteReference: string,
        localisations: string[] = []
    ) {
        this.idFichier = idFichier;
        this.typeReferenceFichier = typeReferenceFichier;
        this.legende = legende;
        this.legendEdited = legendEdited;
        this.creationDate = creationDate;
        this.gpsLatitudeRef = gpsLatitudeRef;
        this.gpsLatitude = gpsLatitude;
        this.gpsLongitudeRef = gpsLongitudeRef;
        this.gpsLongitude = gpsLongitude;
        this.gpsAltitudeRef = gpsAltitudeRef;
        this.gpsAltitude = gpsAltitude;
        this.objetReference = objetReference;
        this.nonConformiteReference = nonConformiteReference;
        this.localisations = localisations;
    }
}

export class ReportagePhotoImageFile {
    fileId: string;
    fileContent: string;
    selected: boolean;
    /**
     * Permet de savoir d'où est tiré la photo
     */
    typeReferenceFichier: TypeReferenceFichier;
    legende: string;
    legendEdited = false;

    objetReference: string;
    nonConformiteReference: string;
    localisations: string[] = [];

    creationDate: string;
    gpsLatitudeRef: string;
    gpsLatitude: string;
    gpsLongitudeRef: string;
    gpsLongitude: string;
    gpsAltitudeRef: string;
    gpsAltitude: string;
}

export class Diagnostic {
    id: string;

    /**
     * Prestation
     */
    typePrestation: TypePrestation;

    /**
     * idConfig
     */
    idConfig?: string;

    /**
     * Id de l'intervention auquel le diagnostic est associé, sert pour le mode hors-ligne
     */
    idIntervention?: string;

    /**
     * Contenu du diagnostic
     */
    contenuDiagnostic: ContenuDiagnostic;

    /**
     * Contenu des points de controles
     */
    pointsDeControleBiens: PointDeControleBien[] = [];

    /**
     * Recommandations finales
     */
    recommandationsFinales: Commentaire[] = [];

    /**
     * Constatations diverses finales
     */
    constatationsFinales: Commentaire[] = [];

    /**
     * Commentaires cachés dans le rapport
     */
    hiddenComments: Map<string, string[]> = new Map();

    /**
     * Données du rapport au format JSON
     */
    reportDatas: ReportData[] = [];

    /**
     * Etat de la progression de la sisaie des informations dans chaque onglet
     */
    etatProgressions: EtatProgression[] = [];

    /**
     * État
     */
    etat: EtatDiagnostic;

    /**
     * Raison mise en attente du diagnostic
     */
    raisonsAttente: string[] = [];

    /**
     * List des documents à afficher dans la liste du rapport
     */
    documentsListRapport: string[] = [];

    /**
     * List des documents à afficher en annexes
     */
    documentsRapport: string[] = [];

    /**
     *  Liste des reportages photo fait sur le diagnostic
     */
    reportagesPhoto: ReportagePhoto[] = [];

    /**
     * Liste de plans de repérage de plan
     */
    screenshotsPlan: ScreenshotSvg[] = [];

    /**
     * Liste des bons de commmande
     */
    listeBonCommande: BonCommandeAnalyseAdmin[] = [];

    /**
     * Valeur de conformité séléctionnée par l'opérateur
     */
    conformiteDefinie: ConformiteDefinie;

    static initContenu(contenuDiagnostic: ContenuDiagnostic): ContenuDiagnostic {
        // Pour chaque étape, renseigne le type de l'étape avec le même nom que la clé dans la Map
        Object.keys(contenuDiagnostic).forEach((key) => {
            const etapeDiagnostic = contenuDiagnostic[key] as EtapeDiagnostic;
            etapeDiagnostic.id = key;
        });
        return contenuDiagnostic;
    }
}

export class ConformiteDefinie {
    value: boolean | null = null;
    /**
     * Liste de commentaires liée à ce volume
     */
    commentairesId: string[] = [];
    /**
     * justifié
     */
    justifie = false;
}

export class ReportData {
    typeReport: TypeReport = TypeReport.REPORT;
    reportData: string;
    refRapport: string;

    constructor(typeReport: TypeReport, reportData: string, refRapport: string) {
        this.typeReport = typeReport;
        this.reportData = reportData;
        this.refRapport = refRapport;
    }
}
