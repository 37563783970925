<!--Révision-->
<div *ngIf="ceeReportData?.revision">
    <div class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">
        {{ 'Révision' | uppercase }}
    </div>
    <div class="block-label px-5 ml-2 mb-5 text-justify">
        <div>Numéro de révision : {{ ceeReportData?.revision.incrementRevision }}</div>
        <div>Ce rapport annule et remplace le rapport {{ ceeReportData?.revision.referenceRapportRevise }}.</div>
        <div>Motif de révision : {{ ceeReportData?.revision.motifRevision }}</div>
    </div>
    <div *ngIf="ceeReportData?.revision.oldsRevisions.length > 0">
        <div class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">
            {{ 'HISTORIQUE DE REVISION' | uppercase }}
        </div>
        <div class="block-label px-5 ml-2 mb-5 text-justify" *ngFor="let oldRevision of ceeReportData?.revision.oldsRevisions">
            <div>Numéro de révision : {{ oldRevision.incrementRevision }}</div>
            <div>Ce rapport annule et remplace le rapport {{ oldRevision.referenceRapportRevise }}.</div>
            <div>Motif de révision : {{ oldRevision.motifRevision }}</div>
        </div>
    </div>
</div>
<!--Informations générales-->
<div>
    <div class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">
        {{ 'Informations générales' | uppercase }}
    </div>
    <!--    Block Tableau-->
    <div class="block-content px-5 ml-2 mb-5">
        <table>
            <ng-container *ngFor="let infoGenerale of ceeReportData?.etapesGenerales">
                <tr>
                    <td>{{ infoGenerale?.libelle }}</td>
                    <td [ngClass]="{ warn: infoGenerale?.estAnomalie }">
                        <b>{{ infoGenerale?.valeur ? infoGenerale.valeur : 'Non renseigné' }}</b>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>
</div>
<ng-container *ngFor="let content of contentPTC">
    <div>
        <div class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">
            {{ content.libelle | uppercase }}
        </div>
        <!--    Block Tableau-->
        <div class="block-content px-5 ml-2 mb-5">
            <table>
                <ng-container *ngFor="let inf of content.pointsDeControle">
                    <tr>
                        <td>{{ inf.libelle }}</td>
                        <td [ngClass]="{ warn: inf?.estAnomalie }">
                            <b>{{ inf.reponse }}</b>
                        </td>
                    </tr>
                </ng-container>
            </table>
        </div>
    </div>
</ng-container>
<div>
    <div *ngIf="ceeReportData?.typePrestation === TypePrestationEnum.BARTH160" class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">
        {{ 'Longueurs de réseaux isolées hors volumes chauffés' | uppercase }}
    </div>
    <div *ngIf="ceeReportData?.typePrestation === TypePrestationEnum.BARTH161" class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">
        {{ 'Isolation des points singuliers' | uppercase }}
    </div>
    <!--    Block Tableau-->
    <div class="block-content px-5 ml-2 mb-5">
        <table *ngIf="ceeReportData?.typePrestation === TypePrestationEnum.BARTH160">
            <tr>
                <td>Longueurs de réseaux isolées lors de l'opération, déclarées sur la facture en m :</td>
                <td>
                    <b>{{ ceeReportData?.longuerReseauxIsolesSurFacture }}</b>
                </td>
            </tr>
            <tr>
                <td>Longueur totale isolée en m :</td>
                <td>
                    <b>{{ ceeReportData?.longuerReseauxIsoles }}</b>
                </td>
            </tr>
            <tr>
                <td>Longueur totale isolée (hors volume chauffé) en m :</td>
                <td>
                    <b>{{ ceeReportData?.longuerReseauxIsolesHorsVolumeChauffe }}</b>
                </td>
            </tr>
            <tr>
                <td>Écart > 10% :</td>
                <td [ngClass]="{ warn: ceeReportData?.anomalieLonguerReseauxIsoles }">
                    <b>{{ ceeReportData?.anomalieLonguerReseauxIsoles | ouiNon }}</b>
                </td>
            </tr>
        </table>

        <div *ngFor="let zone of ceeReportData?.pointsDeControle?.solution[0]?.zones" style="margin: 35px 0px">
            <div *ngFor="let info of zone.pointsDeControle" class="h-100 d-flex justify-content-center align-items-center">
                <div
                    class="block-group w-50 mr-3 d-flex justify-content-center"
                    *ngIf="info?.libelle === 'Photo du réseau' || info?.libelle === 'Photo du point singulier'"
                >
                    <div class="block-photo">
                        <div *ngIf="!info?.imageZone?.url" class="h-100 d-flex justify-content-center align-items-center">
                            <div class="text-photo">PHOTO MANQUANTE</div>
                        </div>
                        <div
                            *ngIf="splitUrl(info?.imageZone.url) | fileDownload | async as fileContent"
                            class="h-100 image-photo d-flex align-items-center"
                        >
                            <img [src]="fileContent" alt="Photo du réseau" class="img-fluid photo-reseau" />
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <table>
                    <ng-container class="mt-5" *ngFor="let info of zone.pointsDeControle">
                        <tr *ngIf="info?.libelle != 'Photo du réseau' && info?.libelle != 'Photo du point singulier'">
                            <td>
                                {{ info?.libelle }}
                            </td>
                            <td [ngClass]="{ warn: info?.estAnomalie }"><b [innerHTML]="info?.reponse"></b></td>
                            <td>
                                <button
                                    *ngIf="!readonlyMode && info.commentaires?.length"
                                    mat-icon-button
                                    class="small-button button-group primary"
                                    (click)="editDisplayedComments(info.commentaires, info.sectionRapport)"
                                >
                                    <mat-icon class="icon-small">edit</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </table>
            </div>
        </div>
    </div>
</div>
