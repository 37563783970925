/**
 * TypePrestation
 *
 * @title Type de la prestation
 * @description Correspond au type de la prestation
 */
export type TypePrestation =
    /** Commun */
    | TypePrestationEnum.COMMUN
    /** Assainissement */
    | TypePrestationEnum.ASSAINISSEMENT
    /** Mesurage */
    | TypePrestationEnum.MESURAGE
    /** Mesurage habitable */
    | TypePrestationEnum.MESURAGE_HABITABLE
    /** Mesurage utile */
    | TypePrestationEnum.MESURAGE_UTILE
    /** HAP */
    | TypePrestationEnum.HAP_VALIDATION
    /** HAP partie 1 : Etude de situation */
    | TypePrestationEnum.HAP_ETUDE_SITUATION
    /** HAP partie 2 : Visite de reconnaissance */
    | TypePrestationEnum.HAP_VISITE_RECONNAISSANCE
    /** HAP partie 3 : Partie terrain */
    | TypePrestationEnum.HAP_TERRAIN
    /** HAP : Validation des résultats */
    | TypePrestationEnum.HAP_VALIDATION_RESULTATS
    /** Électricité */
    | TypePrestationEnum.ELECTRICITE
    /** CEE */
    | TypePrestationEnum.BAREN101
    | TypePrestationEnum.BAREN102
    | TypePrestationEnum.BAREN103
    | TypePrestationEnum.BARTH160
    | TypePrestationEnum.BARTH161
    /** Polluant étude de situation */
    | TypePrestationEnum.POLLUANT_ETUDE_SITUATION
    /** Polluant visite chantier */
    | TypePrestationEnum.POLLUANT_VISITE_CHANTIER
    /** Polluant prélèvement */
    | TypePrestationEnum.POLLUANT_PRELEVEMENT
    /** Polluant rédaction stratégie */
    | TypePrestationEnum.POLLUANT_REDACTION_STRATEGIE
    /** Polluant résultats d'analyses */
    | TypePrestationEnum.POLLUANT_RESULTATS_ANALYSES
    /** Gaz */
    | TypePrestationEnum.GAZ;
// À COMPLÉTER AVEC LES DIFFÉRENTS TYPES DE PRESTATIONS...

export enum TypePrestationEnum {
    COMMUN = 'COMMUN',
    ASSAINISSEMENT = 'ASSAINISSEMENT',
    MESURAGE = 'MESURAGE',
    MESURAGE_HABITABLE = 'MESURAGE_HABITABLE',
    MESURAGE_UTILE = 'MESURAGE_UTILE',
    HAP_VALIDATION = 'HAP_VALIDATION',
    HAP_ETUDE_SITUATION = 'HAP_ETUDE_SITUATION',
    HAP_VISITE_RECONNAISSANCE = 'HAP_VISITE_RECONNAISSANCE',
    HAP_TERRAIN = 'HAP_TERRAIN',
    HAP_VALIDATION_RESULTATS = 'HAP_VALIDATION_RESULTATS',
    ELECTRICITE = 'ELECTRICITE',
    BAREN101 = 'BAREN101',
    BAREN102 = 'BAREN102',
    BAREN103 = 'BAREN103',
    BARTH160 = 'BARTH160',
    BARTH161 = 'BARTH161',
    POLLUANT_ETUDE_SITUATION = 'POLLUANT_ETUDE_SITUATION',
    POLLUANT_VISITE_CHANTIER = 'POLLUANT_VISITE_CHANTIER',
    POLLUANT_PRELEVEMENT = 'POLLUANT_PRELEVEMENT',
    POLLUANT_REDACTION_STRATEGIE = 'POLLUANT_REDACTION_STRATEGIE',
    POLLUANT_RESULTATS_ANALYSES = 'POLLUANT_RESULTATS_ANALYSES',
    GAZ = 'GAZ',
}

/**
 * Types de prestation (version itérable)
 */
export const typesPrestation: TypePrestation[] = [
    TypePrestationEnum.COMMUN,
    TypePrestationEnum.ASSAINISSEMENT,
    TypePrestationEnum.MESURAGE,
    TypePrestationEnum.MESURAGE_HABITABLE,
    TypePrestationEnum.MESURAGE_UTILE,
    TypePrestationEnum.HAP_VALIDATION,
    TypePrestationEnum.HAP_ETUDE_SITUATION,
    TypePrestationEnum.HAP_VISITE_RECONNAISSANCE,
    TypePrestationEnum.HAP_TERRAIN,
    TypePrestationEnum.HAP_VALIDATION_RESULTATS,
    TypePrestationEnum.ELECTRICITE,
    TypePrestationEnum.BAREN101,
    TypePrestationEnum.BAREN102,
    TypePrestationEnum.BAREN103,
    TypePrestationEnum.BARTH160,
    TypePrestationEnum.BARTH161,
    TypePrestationEnum.POLLUANT_ETUDE_SITUATION,
    TypePrestationEnum.POLLUANT_VISITE_CHANTIER,
    TypePrestationEnum.POLLUANT_PRELEVEMENT,
    TypePrestationEnum.POLLUANT_REDACTION_STRATEGIE,
    TypePrestationEnum.POLLUANT_RESULTATS_ANALYSES,
    TypePrestationEnum.GAZ,
    // À COMPLÉTER AVEC LES DIFFÉRENTS TYPES DE PRESTATIONS...
];

/**
 * À partir d'un type de prestation, renvoie la route Angular associée,
 * en mettant en minuscule et en remplaçant les underscore par des tirets
 */
export function typePrestationToPath(typePrestation: TypePrestation): string {
    return typePrestation.toLowerCase().replace(/_/g, '-');
}

/**
 * À partir d'un type de prestation, renvoie le label associé.
 */
export function typePrestationToLabel(typePrestation?: string | TypePrestation): string {
    switch (typePrestation) {
        case TypePrestationEnum.COMMUN:
            return 'Commun';
        case TypePrestationEnum.ASSAINISSEMENT:
            return 'Assainissement';
        case TypePrestationEnum.MESURAGE:
            return 'Mesurage Loi Carrez';
        case TypePrestationEnum.MESURAGE_HABITABLE:
            return 'Mesurage habitable';
        case TypePrestationEnum.MESURAGE_UTILE:
            return 'Mesurage utile (brute et nette)';
        case TypePrestationEnum.HAP_VALIDATION:
            return 'HAP Validation';
        case TypePrestationEnum.HAP_ETUDE_SITUATION:
            return 'HAP Étude de situation';
        case TypePrestationEnum.HAP_VISITE_RECONNAISSANCE:
            return 'HAP Visite de reconnaissance';
        case TypePrestationEnum.HAP_TERRAIN:
            return 'HAP Terrain';
        case TypePrestationEnum.HAP_VALIDATION_RESULTATS:
            return 'HAP Validation des résultats';
        case TypePrestationEnum.ELECTRICITE:
            return 'Électricité';
        case TypePrestationEnum.BAREN101:
            return 'Isolation de combles ou de toitures [BAR-EN-101]';
        case TypePrestationEnum.BAREN102:
            return 'Isolation des murs [BAR-EN-102]';
        case TypePrestationEnum.BAREN103:
            return 'Isolation d’un plancher [BAR-EN-103]';
        case TypePrestationEnum.BARTH160:
            return "Isolation d'un réseau hydraulique de chauffage ou d'eau chaude sanitaire [BAR-TH-160]";
        case TypePrestationEnum.BARTH161:
            return "Isolation de points singuliers d'un réseau [BAR-TH-161]";
        case TypePrestationEnum.POLLUANT_ETUDE_SITUATION:
            return 'Polluant (Etude de situation)';
        case TypePrestationEnum.POLLUANT_VISITE_CHANTIER:
            return 'Polluant (Visite de chantier)';
        case TypePrestationEnum.POLLUANT_PRELEVEMENT:
            return 'Polluant (Prélèvement)';
        case TypePrestationEnum.POLLUANT_REDACTION_STRATEGIE:
            return 'Polluant (Rédaction de stratégie)';
        case TypePrestationEnum.POLLUANT_RESULTATS_ANALYSES:
            return "Polluant (Résultats d'analyses)";
        case TypePrestationEnum.GAZ:
            return 'Gaz';
        // À COMPLÉTER AVEC LES DIFFÉRENTS TYPES DE PRESTATIONS...
        default:
            return '';
    }
}

export const groupePrestationPolluant = [
    TypePrestationEnum.POLLUANT_ETUDE_SITUATION,
    TypePrestationEnum.POLLUANT_VISITE_CHANTIER,
    TypePrestationEnum.POLLUANT_PRELEVEMENT,
    TypePrestationEnum.POLLUANT_REDACTION_STRATEGIE,
    TypePrestationEnum.POLLUANT_RESULTATS_ANALYSES,
];

export const groupePrestationCEE = [
    TypePrestationEnum.BAREN101,
    TypePrestationEnum.BAREN102,
    TypePrestationEnum.BAREN103,
    TypePrestationEnum.BARTH160,
    TypePrestationEnum.BARTH161,
];
