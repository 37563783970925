<h1 mat-dialog-title class="primary">
    <span>Télécharger les rapports</span>
</h1>

<div mat-dialog-content class="d-flex flex-column">
    <div class="w-100 pr-2 pt-2">
        <!-- Type de prestation -->
        <div *ngIf="displayTypePrestation" class="d-flex align-items-center">
            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Type de prestation</div>
            <div class="col-xl-8 pr-2">
                <app-custom-select
                    class="w-100"
                    [matFormFieldClass]="'w-100'"
                    [choices]="rapports"
                    [pathValueDisplay]="['typePrestation']"
                    [selectedValue]="rapportSelected"
                    [filter]="false"
                    [addline]="false"
                    (selectedValueChange)="changeTypePrestation($event)"
                >
                </app-custom-select>
            </div>
        </div>

        <!-- Version de rapport -->
        <div class="d-flex align-items-center">
            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Version de rapport</div>
            <div class="col-xl-8 pr-2">
                <app-custom-select
                    class="w-100"
                    [matFormFieldClass]="'w-100'"
                    [choices]="versionsPossible"
                    [pathValueDisplay]="displayRapportItem"
                    [disabledCallback]="disableRapportItem"
                    [filter]="false"
                    [addline]="false"
                    (selectedValueChange)="changeVersion($event)"
                >
                </app-custom-select>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="justify-content-end">
        <button mat-button color="primary" (click)="cancel()">
            <lib-icon>close</lib-icon>
            <span>Annuler</span>
        </button>
        <button
            mat-raised-button
            color="accent"
            [disabled]="!versionRapportSelected || !rapportSelected || !!versionRapportSelected.rapport.errorMessage"
            (click)="confirm()"
        >
            <lib-icon>check</lib-icon>
            <span>Confirmer</span>
        </button>
    </div>
</div>
