import { Component, Input, OnInit } from '@angular/core';
import { InterventionReportData, IReportComponent, ReportBlockData } from '../../../../../../model/rapport.model';
import { AssainissementReportData, RowVolumeAssainissement, SubBlockAssainissement } from '../../../model/assainissement.model';
import { Commentaire } from '../../../../../../model/commentaire.model';
import { MatDialog } from '@angular/material/dialog';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { BaseComponent, NotificationService } from 'src/app/commons-lib';
import { ReportService } from '../../../../../../services/report.service';
import { CnSpinnerService } from '../../../../../shared/cn-spinner/service/cn-spinner.service';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { InterventionService } from '../../../../../../services/intervention.service';
import { ReportCommentsListModalComponent } from 'src/app/modules/diagnostics-shared/rapport/report-comments-list-modal/report-comments-list-modal.component';
import { ReportScreenshotsListModalComponent } from 'src/app/modules/diagnostics-shared/rapport/report-screenshots-list-modal/report-screenshots-list-modal.component';
import { Intervention } from 'src/app/model/intervention.model';
import { TypePrestationEnum } from 'src/app/model/type-prestation.model';

@Component({
    selector: 'app-commentaires-block',
    templateUrl: './commentaires-block.component.html',
    styleUrls: ['./commentaires-block.component.scss'],
})
export class CommentairesBlockComponent extends BaseComponent implements OnInit, IReportComponent {
    @Input() data: ReportBlockData;
    @Input() interReportData: InterventionReportData;
    @Input() intervention: Intervention;

    recommandations: string[] = [];
    constatationsDiverses: string[] = [];
    constatationsSpecifiques: SubBlockAssainissement;
    exclusionsConstatationsSpecifiques: any;
    optionPlan = false;
    readonlyMode = false;

    private diagnosticData: AssainissementReportData;
    private diagnostic: Diagnostic;

    constructor(
        private matDialog: MatDialog,
        private diagnosticService: DiagnosticService,
        private interventionService: InterventionService,
        private reportService: ReportService,
        private notificationService: NotificationService,
        private cnSpinnerService: CnSpinnerService
    ) {
        super();
    }

    ngOnInit(): void {
        this.diagnosticService
            .getCurrentDiagnostic()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((diag) => {
                this.diagnostic = diag;
                this.readonlyMode = this.diagnosticService.isReadOnlyMode(this.intervention, this.diagnostic);
                if (this.interReportData) {
                    this.diagnosticData = this.interReportData.diagnostics.find(
                        (dia) => dia.typePrestation === TypePrestationEnum.ASSAINISSEMENT
                    ) as AssainissementReportData;
                    this.optionPlan = this.diagnosticData.optionPlan;
                    this.recommandations = this.diagnosticData.recommandations;
                    this.constatationsDiverses = this.diagnosticData.constatationsDiverses;
                    this.constatationsSpecifiques = this.diagnosticData.constatationsSpecifiques;
                }
                this.prepareExclusionLists();
            });
    }

    /**
     * Edition de la liste des commentaires à afficher
     * @param commentaires
     * @param code
     */
    onClickEditDisplayedComments(commentaires: Commentaire[], code: string) {
        return this.matDialog
            .open(ReportCommentsListModalComponent, {
                data: {
                    comments: commentaires,
                    code: code,
                    hiddenComments: this.diagnostic.hiddenComments,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.diagnostic.hiddenComments = result.hiddenComments;
                    this.prepareExclusionLists();
                    this.saveReport();
                }
            });
    }

    /**
     * Edition de la liste des plans de repérage à associer pour un volume
     * @param volume
     */
    onClickEditPlansList(volume: RowVolumeAssainissement) {
        return this.matDialog
            .open(ReportScreenshotsListModalComponent, {
                data: {
                    screenshotsAvailable: this.diagnosticData.screenshotsPlan,
                    selectedScreenshots: volume.plans,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    volume.plans = result.screenshotsSelected;
                    this.saveReport();
                }
            });
    }

    /**
     * Liste d'exclusion de commentaires
     * @private
     */
    private prepareExclusionLists() {
        this.exclusionsConstatationsSpecifiques = this.diagnostic.hiddenComments['CONSTATATIONS_SPECIFIQUES']
            ? this.diagnostic.hiddenComments['CONSTATATIONS_SPECIFIQUES']
            : [];
    }

    /**
     * Sauvegarde du pré-rapport temporaire
     * @private
     */
    private saveReport() {
        this.cnSpinnerService
            .withSpinner(
                combineLatest([this.interventionService.getCurrentIntervention(), this.diagnosticService.getCurrentDiagnostic()]).pipe(
                    take(1),
                    map(([intervention, diagnostic]) => this.reportService.saveReportDataTemp(this.interReportData, intervention, diagnostic)),
                    switchMap((result) =>
                        this.interventionService
                            .updateIntervention(result.intervention)
                            .pipe(switchMap((i) => this.diagnosticService.upsert(i, result.diagnostic)))
                    )
                )
            )
            .subscribe((diag) => {
                if (diag) {
                    this.notificationService.success(
                        'Les données du rapport ont bien été sauvées pour le diagnostic ' + diag.typePrestation.replace(/_/g, ' ')
                    );
                } else {
                    this.notificationService.error({
                        message: 'Impossible de sauvegarder les données du rapport',
                        showCloseButton: false,
                        duration: 0,
                    });
                }
            });
    }
}
