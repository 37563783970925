<div class="p-2 px-5 d-flex flex-column">
    <ng-template ngFor let-constatationD [ngForOf]="constatationsDiverses">
        <ng-template [ngIf]="!constatationD.hide && constatationD.constatationDiverse.reference && constatationD.constatationDiverse.description">
            <mat-card class="p-1">
                <mat-card-title>
                    <app-section-title
                        *ngIf="!constatationD.isLast"
                        class="ml-{{ constatationD.niveau + 3 }}"
                        [label]="constatationD.constatationDiverse.reference + ' ' + constatationD.constatationDiverse.description"
                        [showHelp]="false"
                    ></app-section-title>
                </mat-card-title>
                <mat-card-content>
                    <mat-card
                        *ngIf="constatationD.isLast"
                        class="p-1 ml-{{ constatationD.niveau + 3 }}"
                        [ngClass]="
                            diagnostic.pointsDeControleBiens[0].valeursParametres[constatationD.constatationDiverse.idInterne]?.valeur == true
                                ? 'point-de-controle-conforme'
                                : diagnostic.pointsDeControleBiens[0].valeursParametres[constatationD.constatationDiverse.idInterne]?.valeur == false
                                ? 'point-de-controle'
                                : 'point-de-controle'
                        "
                    >
                        <mat-card-content [ngClass]="constatationD.disabled ? 'disabledContent' : ''">
                            <ng-container
                                *ngIf="
                                    diagnostic.pointsDeControleBiens[0].valeursParametres[constatationD.constatationDiverse.idInterne]
                                        ?.greyExplication
                                "
                            >
                                <div class="d-flex px-2">
                                    <b>{{
                                        diagnostic.pointsDeControleBiens[0].valeursParametres[constatationD.constatationDiverse.idInterne]
                                            ?.greyExplication
                                    }}</b>
                                </div>
                                <mat-divider></mat-divider>
                            </ng-container>
                            <div class="d-flex px-2 align-items-center mt-2">
                                <div class="mr-auto d-flex">
                                    <div>
                                        {{ constatationD.constatationDiverse.reference }}
                                        {{ constatationD.constatationDiverse.description }}
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <button
                                        mat-icon-button
                                        color="primary"
                                        class="mr-2"
                                        matTooltip="Afficher l'aide"
                                        (click)="openHelp(constatationD.constatationDiverse)"
                                    >
                                        <lib-icon class="icon-small">help</lib-icon>
                                    </button>
                                    <app-notes-buttons
                                        class="mr-2"
                                        [ngStyle]="{
                                            'pointer-events': diagnostic.pointsDeControleBiens[0].valeursParametres[
                                                constatationD.constatationDiverse.idInterne
                                            ]
                                                ? ''
                                                : 'none'
                                        }"
                                        [from]="diagnostic.pointsDeControleBiens[0].valeursParametres[constatationD.constatationDiverse.idInterne]"
                                        [diagnostic]="diagnostic"
                                        [typesCommentaires]="COMMENT_TYPES"
                                        [replaceTypesCommentaires]="COMMENT_TYPES"
                                    >
                                    </app-notes-buttons>
                                    <mat-checkbox
                                        [disabled]="constatationD.disabled || readonlyMode"
                                        [checked]="
                                            diagnostic.pointsDeControleBiens[0].valeursParametres[constatationD.constatationDiverse.idInterne]?.valeur
                                        "
                                        (change)="onValeurChange($event.checked, constatationD.constatationDiverse.idInterne)"
                                    ></mat-checkbox>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </mat-card-content>
            </mat-card>
        </ng-template>
    </ng-template>
</div>
