<div class="container">
    <div *ngIf="!anomalies.length" class="py-4 d-flex flex-column">
        <div class="text-center">Aucune anomalie constatée</div>
    </div>
    <div *ngIf="anomalies.length" class="p-4 d-flex flex-column">
        <ng-template ngFor let-anomalie [ngForOf]="anomalies">
            <mat-accordion>
                <mat-expansion-panel expanded hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ anomalie.solution }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template ngFor let-ano [ngForOf]="anomalie.anomalies">
                        <mat-card
                            class="
                                p-1
                                pb-0
                                mb-3
                                w-100
                                d-flex
                                flex-column
                                justify-content-between
                                point-de-controle-non-conforme
                            "
                        >
                            <mat-card-content>
                                <div class="p-2 mr-auto d-flex">
                                    <div>
                                        {{ ano }}
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </ng-template>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-template>
    </div>
</div>
