<h1 mat-dialog-title class="cancel-title">{{ title }}</h1>

<div mat-dialog-content>
    <div>
        <app-custom-select
            [choices]="reasonsList"
            [placeholder]="'Raison de la non réalisation'"
            [matFormFieldClass]="'w-100'"
            [addLineButtonLabel]="'Ajouter une raison'"
            [(selectedValue)]="reason"
            [sorted]="true"
            [filter]="false"
        ></app-custom-select>
    </div>
    <div>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Commentaire</mat-label>
            <textarea
                matInput
                cdkTextareaAutosize
                cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="5"
                [(ngModel)]="comment"
            ></textarea>
        </mat-form-field>
    </div>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-button color="primary" (click)="cancel()">
        <lib-icon>close</lib-icon>
        <span>{{ 'Annuler' | uppercase }}</span>
    </button>
    <button mat-raised-button color="warn" [disabled]="reason.length === 0" (click)="confirm()">
        <lib-icon>check</lib-icon>
        <span>{{ 'Confirmer' | uppercase }}</span>
    </button>
</div>
