import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { CeeService } from './cee.service';

@Injectable()
export class InitConfigResolver implements Resolve<void> {
    constructor(private activatedRoute: ActivatedRoute, private ceeService: CeeService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.ceeService.initConfig();
    }
}
