'use strict';

import { fh_polygon } from "@acenv/fh-3d-viewer";
import { CN_CURRENT_DATE } from "../utils/cn_transaction_manager";
import { cn_add, cn_dist, cn_dot, cn_intersect_line, cn_middle, cn_mul, cn_normal, cn_normalize, cn_random, cn_seed, cn_sub, cnx_add, cnx_clone, cnx_mul } from "../utils/cn_utilities";
import { cn_scene } from "./cn_scene";
import { CN_INNER, CN_MIDDLE, CN_OUTER, cn_wall, cn_wall_delegate } from "./cn_wall";
import { cn_wall_type } from "./cn_wall_type";
import { cn_material } from "./cn_material";
import { cn_vertex } from "./cn_vertex";
import { cn_opening } from "./cn_opening";
import { logger } from "../utils/cn_logger";

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2024 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

const CNA_PARALLEL_THERSHOLD = 0.95;

/**
 * Cna wall : the class tro manage a wxall inside the analyser.
 */
class cna_wall {
    /**
     *
     * @param {cn_wall} wall
     */
    constructor(wall) {
        this.wall = wall;
        this.thickness = 0;
        this.valid = true;
        this.dirty = false;
        this.p0 = [0, 0];
        this.p1 = [0, 0];
        this.direction = [0, 0];
        this.length = 0;
        this.vertices = [];
        /**
         * @type {fh_polygon}
         */
        this.shape = null;

    }

    //*** Compute wall geometry */
    update() {
        this.thickness = this.wall.wall_type.thickness;
        const p0 = this.wall.vertex_position(0);
        const p1 = this.wall.vertex_position(1);
        this.wall.build_self();
        const dir = cn_sub(p1, p0);
        const l = cn_normalize(dir);
        if (l < 0.01) {
            this.valid = false;
            this.dirty = true;
        }
        this.valid = true;
        this.thickness = this.wall.wall_type.thickness;
        const nor = cn_normal(dir);
        const y0 = -0.01 + ((this.wall.axis == CN_OUTER) ? -this.thickness : (this.wall.axis == CN_INNER) ? 0 : -0.5 * this.thickness);
        const y1 = y0 + this.thickness + 0.02;
        const vertices = [];
        const pp0 = cn_sub(p0, cn_mul(dir, 0.01));
        const pp1 = cn_add(p1, cn_mul(dir, 0.01));
        vertices.push(cnx_add(pp0, cnx_mul(nor, y0)));
        vertices.push(cnx_add(pp1, cnx_mul(nor, y0)));
        vertices.push(cnx_add(pp1, cnx_mul(nor, y1)));
        vertices.push(cnx_add(pp0, cnx_mul(nor, y1)));
        if (this.vertices.length != 0 && cn_dist(p0, this.p0) < 0.001 && cn_dist(p1, this.p1) < 0.001 && !vertices.some((v, i) => cn_dist(v, this.vertices[i]) > 0.001))
            return;
        this.dirty = true;
        this.p0 = p0;
        this.p1 = p1;
        this.direction = dir;
        this.length = l;
        this.vertices = vertices;
        this.shape = new fh_polygon([0, 0, 0], [0, 0, 1]);
        this.shape.add_contour(vertices);
    }
}

//***********************************************************************************
//***********************************************************************************
//**** cn_scene_analyser : update walls and spaces in a scene.
//***********************************************************************************
//***********************************************************************************

export class cn_scene_analyser {
    /**
     *
     * @param {cn_scene} scene
     */
    constructor(scene) {
        this.scene = scene;
        this.building = scene.parent;
        this.analysis_date = -1;
        this.wall_objects = [];
        this.test_number = -1;
    }

    process(allow_vertex_displacement = false) {
        //***  datation check */
        var rebuild_wall_objects = true;
        const analysis_date = this.analysis_date;
        this.analysis_date = CN_CURRENT_DATE;
        while (analysis_date >= 0) {

            //*** Rough check */
            if (this.scene.parent.up_to_date(analysis_date))
                return;

            //*** wall existence check */
            if (!this.scene.up_to_date(analysis_date, "walls")) break;
            rebuild_wall_objects = false;

            //*** wall geometry check */
            if (!this.scene.up_to_date(analysis_date, "vertices")) break;
            if (this.scene.vertices.some(v => !v.up_to_date("position"))) break;
            if (this.scene.walls.some(w => !w.up_to_date("vertices") || !w.up_to_date("axis"))) break;
            if (this.wall_objects.some(w => w.thickness != w.wall.wall_type.thickness)) break;

            //*** nothing has changed! */
            return;
        }

        //*** Rebuild wall objects */
        if (rebuild_wall_objects) {
            this.wall_objects = this.scene.walls.map(w => new cna_wall(w))
        }
        this.wall_objects.forEach(w => w.update());

        //*** last datation check. Maybe no wall has changed its geometry */
        if (!this.wall_objects.some(w => w.dirty))
            return;

        this._cleanse_walls();

        for (var niter = (allow_vertex_displacement) ? 0 : 1; niter < 2; niter++) {
            this._analyse_walls(niter == 0);

            //*** rebuild objects */
            this.wall_objects = this.scene.walls.map(w => new cna_wall(w))
            this.wall_objects.forEach(w => {
                w.update();
                w.dirty = (niter == 0);
            });
        }

        this.scene.build_automatic_spaces();
    }

    _cleanse_walls() {
        this.wall_objects.filter(w => !w.valid).forEach(w => {
            const wi = this.scene.walls.indexOf(w.wall);
            if (wi >= 0) this.scene.walls.splice(wi, 1);
        });
        this.wall_objects = this.wall_objects.filter(w => w.valid);
    }
    /**
     * Performs wall analysis
     */
    _analyse_walls(allow_vertex_displacement) {

        const wall_intersections = [];

        class wall_position {
            constructor(wall, position) {
                this.wall = wall;
                this.position = position;
            }
        }

        class wall_intersection {
            constructor() {
                this.point = [0, 0, 0];
                this.wall_positions = [];
                this.vertex = null;
                this.liberties = 2;
                this.liberty_direction = [0, 0];
            }

            merge(other) {
                console.log('merge', this.point, other.point);
                this.wall_positions = this.wall_positions.concat(other.wall_positions);
                other.wall_positions = [];
            }
        }

        function find_intersection(wall_object, position) {
            if (position >= 2) return null;
            return wall_intersections.find(wi => wi.wall_positions.some(wp => wp.wall_object == wall_object && wp.position == position))
        }

        function analyse_intersection(wall_object, intersection) {
            var xmin = wall_object.length;
            var xmax = 0;
            intersection.compute_contours();
            intersection.contour_vertices.forEach(v => {
                const x = cn_dot(wall_object.direction, cn_sub(v, wall_object.p0));
                if (x < xmin) xmin = x;
                if (x > xmax) xmax = x;
            });
            const p0 = (intersection.contains(cnx_clone(wall_object.p0)) || (xmin < wall_object.length / 2 && xmin < 0.1))
            const p1 = (intersection.contains(cnx_clone(wall_object.p1)) || (xmax > wall_object.length / 2 && xmax > wall_object.length - 0.1))
            if (p0 && p1) return 2;
            if (p0) return 0;
            if (p1) return 1;
            return 2;
        }

        /**
         * returns 'true' if wp0 is prioritary over wp1.
         * @param {*} wp0
         * @param {*} wp1
         * @returns
         */
        function compare_priorities(wp0, wp1) {
            //*** The prioritary is the one that's not a balcony */
            if (wp0.wall.balcony != wp1.wall.balcony)
                return wp1.wall.balcony;

            //*** The prioritary is the one that's not free */
            if (wp0.wall.wall_type.free != wp1.wall.wall_type.free)
                return wp1.wall.wall_type.free;

            //*** The prioritary is the one with position on the middle */
            if ((wp0.position == 2) != (wp1.position == 2))
                return (wp0.position == 2);

            //*** Choose the one with no delegates */
            if (wp0.position != 2) {
                if ((wp0.wall.delegates[wp0.position] == null) != (wp1.wall.delegates[wp0.position] == null))
                    return (wp0.wall.delegates[wp0.position] == null);
            }

            //*** The prioritary is the thickest */
            return wp0.wall.wall_type.thickness >= wp1.wall.wall_type.thickness;
        }

        //*** Fix overlapping walls */
        this.wall_objects.filter(w0 => w0.dirty).forEach((w0, i0) => {
            this.wall_objects.filter((w1, i1) => !w1.dirty || i1 > i0).forEach(w1 => {
                //*** Filter parallel walls */
                if (w0.wall && w1.wall && w0.valid && w1.valid) {
                    const dot_prod = cn_dot(w0.direction, w1.direction);
                    if (Math.abs(dot_prod) > CNA_PARALLEL_THERSHOLD) {
                        //*** Work on the less prioritary wall */
                        const ww = compare_priorities(w0, w1) ? w1 : w0;
                        const pg = (ww == w0) ? w1.shape : w0.shape;

                        //*** Compute intersection of this wall to the other footprint */
                        const segments = pg.intersect_segment(cnx_clone(ww.p0), cnx_clone(ww.p1));

                        //*** Special case : the wall is entirely inside the other */
                        if (segments.length == 2 && cn_dist(segments[0], ww.p0) < 0.01 && cn_dist(segments[1], ww.p1) < 0.01) {
                            const wi = this.scene.walls.indexOf(ww.wall);
                            if (wi >= 0) this.scene.walls.splice(wi, 1);
                            ww.wall = null;
                            console.log("Removing wall", ww.p0, ww.p1);
                        }
                        //*** Other interesting cases : there is an intersection */
                        else if (segments.length > 0) {
                            let wall_to_cut = ww.wall;
                            const all_walls = [wall_to_cut];
                            const walls_to_keep = [];
                            for (var nv = 0; nv < segments.length; nv++) {
                                if (cn_dist(segments[nv], wall_to_cut.vertex_position(0)) > 0.001) {
                                    if (cn_dist(segments[nv], wall_to_cut.vertex_position(1)) > 0.001) {
                                        const next_wall = this.scene.split_wall(wall_to_cut, new cn_vertex(segments[nv]));
                                        if (nv & 1) walls_to_keep.push(next_wall);
                                        else walls_to_keep.push(wall_to_cut);
                                        wall_to_cut = next_wall;
                                        all_walls.push(next_wall);
                                    }
                                    else {
                                        if ((nv & 1) == 0)
                                            walls_to_keep.push(wall_to_cut);
                                        break;
                                    }
                                }
                                else {
                                    if (nv & 1) walls_to_keep.push(wall_to_cut);
                                }
                            }
                            all_walls.filter(w => !walls_to_keep.includes(w)).forEach(w => {
                                const wi = this.scene.walls.indexOf(w);
                                if (wi >= 0) this.scene.walls.splice(wi, 1);
                            });
                            all_walls.filter(w => walls_to_keep.includes(w)).forEach(w => {
                                const new_ww = new cna_wall(w);
                                new_ww.update();
                                this.wall_objects.push(new_ww);
                            })
                            ww.wall = null;
                            console.log("Cutting wall", ww.p0, ww.p1);
                            all_walls.filter(w => walls_to_keep.includes(w)).forEach(w =>
                                console.log(w.vertex_position(0), w.vertex_position(1)));
                            console.log("segments:");
                            segments.forEach(s => console.log(s));
                        }
                    }
                }
            });
        });
        this.wall_objects = this.wall_objects.filter(w0 => w0.wall);
        this._cleanse_walls();

        //*** Compute intersections between walls */
        this.wall_objects.filter(w0 => w0.dirty).forEach((w0, i0) => {
            this.wall_objects.filter((w1, i1) => !w1.dirty || i1 > i0).forEach(w1 => {
                const pg = w0.shape.clone();
                pg.intersects(w1.shape);
                if (pg.get_area() > 0.00001) {
                    const p0 = analyse_intersection(w0, pg);
                    const p1 = analyse_intersection(w1, pg);
                    const i0 = find_intersection(w0, p0);
                    const i1 = find_intersection(w1, p1);
                    if (i0 && i1) {
                        if (i0 != i1) i0.merge(i1);
                    } else if (i0)
                        i0.wall_positions.push(new wall_position(w1.wall, p1));
                    else if (i1)
                        i1.wall_positions.push(new wall_position(w0.wall, p0));
                    else {
                        const ii = new wall_intersection();
                        const box = pg.get_bounding_box();
                        ii.point = cn_add(box.position, cn_mul(box.size, 0.5));
                        ii.wall_positions.push(new wall_position(w1.wall, p1));
                        ii.wall_positions.push(new wall_position(w0.wall, p0));
                        wall_intersections.push(ii);
                    }
                }
            });
        });

        console.log('more than 2', wall_intersections.filter(wi => wi.wall_positions.length > 2));

        wall_intersections.forEach(wi => {
            console.log(wi.point, wi.wall_positions.concat([]));
        });

        function thickest_connexion(connexions) {
            if (connexions.length == 0) return connexions[0];
            var thickest = connexions[0];
            connexions.forEach(c => {
                if (compare_priorities(c, thickest)) {
                    thickest = c;
                }
            });
            return thickest;
        }

        //*** We manage intersections */
        wall_intersections.forEach((wi, wiindex) => {
            var vertex = null;
            var vertex_wall = null;
            var liberties = 2;
            var liberty_direction = [0, 0];

            while (wi.wall_positions.length) {
                const con = thickest_connexion(wi.wall_positions);
                const wall = con.wall;
                const p0 = wall.vertex_position(0);
                const dir = wall.bounds.direction;

                //*** Check middle connexions */
                if (con.position == 2) {
                    var split_wall = null;
                    if (liberties == 2) {
                        //*** First middle connexion : we build the split vertex */
                        const x = cn_dot(cn_sub(wi.point, p0), dir);
                        vertex = new cn_vertex(cn_add(p0, cn_mul(dir, x)));
                        vertex_wall = wall;
                        this.scene.vertices.push(vertex);
                        liberties = 1;
                        liberty_direction = dir;
                        split_wall = this.scene.split_wall(wall, vertex);
                    }
                    else if (liberties == 1 && (Math.abs(cn_dot(dir, liberty_direction)) < CNA_PARALLEL_THERSHOLD)) {
                        //*** Second middle connexion : we may slide the vertex along the initial wall */
                        vertex.position = cn_intersect_line(vertex.position, liberty_direction, p0, dir);
                        if (!vertex.position) {
                            logger.error("Shouldn't be here", Math.abs(cn_dot(dir, liberty_direction)), cn_dot(liberty_direction, cn_normal(dir)))
                        }
                        split_wall = this.scene.split_wall(wall, vertex);
                        liberties = 0;
                    }
                    else {
                        //*** other middle connexion : we cut the wall and create delegates */
                        const x = cn_dot(cn_sub(vertex.position, p0), dir);
                        const position = cn_add(p0, cn_mul(dir, x));
                        split_wall = this.scene.split_wall(wall, vertex);
                        if (cn_dist(vertex.position, position) > 0.001) {
                            wall.delegates[1] = new cn_wall_delegate(position, vertex_wall, 0);
                            split_wall.delegates[0] = new cn_wall_delegate(position, vertex_wall, 0);
                        }
                        else {
                            wall.delegates[1] = null;
                            split_wall.delegates[0] = null;
                        }
                    }

                    //*** replace split wall in next intersections */
                    var xnext = cn_dot(dir, cn_sub(wall.vertices[1].position, p0));
                    for (var wii = wiindex + 1; wii < wall_intersections.length; wii++) {
                        wall_intersections[wii].wall_positions.filter(wp => wp.wall == wall).forEach(wp => {
                            const x = cn_dot(dir, cn_sub(wall_intersections[wii].point, p0));
                            if (x > xnext) wp.wall = split_wall;
                        });
                    }
                }
                else if (liberties == 2) {
                    //*** initial end connexion : this sets the vertex */
                    vertex = wall.vertices[con.position];
                    vertex.position = wall.vertex_position(con.position);
                    if (!vertex.position)
                        console.error("coucou");
                    vertex_wall = wall;
                    liberties = 1;
                    liberty_direction = dir;
                    wall.delegates[con.position] = null;
                }
                else if (allow_vertex_displacement && liberties == 1 && (Math.abs(cn_dot(dir, liberty_direction)) < CNA_PARALLEL_THERSHOLD)) {
                    //*** Second end connexion : we may slide the vertex */
                    vertex.position = cn_intersect_line(vertex.position, liberty_direction, p0, dir);
                    if (!vertex.position)
                        console.error("coucou");
                    wall.vertices[con.position] = vertex;
                    liberties = 0;
                }
                else {
                    //*** other end connexion : we create delegates */
                    const pos = wall.vertex_position(con.position)
                    wall.vertices[con.position] = vertex;
                    wall.delegates[con.position] = (cn_dist(pos, vertex.position) > 0.001) ? new cn_wall_delegate(pos, vertex_wall, 0) : null;
                }
                const index = wi.wall_positions.indexOf(con);
                wi.wall_positions.splice(index, 1);
            }

            this.scene.update_vertices(false);
            this.scene.update_walls();
        });
    }

    /**
     * Unitary monkey test
     */
    monkey_test(nb_walls = 50) {

        this.building.transaction_manager.push_transaction("Monkey test");
        this.building.transaction_manager.push_item_set(this.scene, ["vertices", "walls"]);

        this.scene.vertices = [];
        this.scene.walls = [];

        //*** Build wall types */
        const thicknesses = [0, 0.08, 0.2, 0.5];
        const wall_types = [];
        thicknesses.forEach(thickness => {
            var wall_type = this.building.get_wall_types().find(w => w.thickness == thickness);
            if (!wall_type) {
                wall_type = new cn_wall_type();
                wall_type.thickness = thickness;
                wall_type.name = 'Mur ' + (0.1 * Math.round(thickness * 1000)).toFixed(1) + 'cm';
                wall_type.layers = [new cn_material('Inconnu', 'unknown', thickness)];
                this.building.element_types.push(wall_type);
            }
            wall_types.push(wall_type);
        });

        this.test_number++;
        let wall_type = wall_types[2];
        if (this.test_number == 0) {
            this.scene.vertices.push(new cn_vertex([-5, 0]));
            this.scene.vertices.push(new cn_vertex([5, 0]));
            this.scene.walls.push(new cn_wall(this.scene.vertices[0], this.scene.vertices[1], wall_type, CN_MIDDLE, this.scene));
        }
        else if (this.test_number == 1) {
            this.scene.vertices.push(new cn_vertex([-5, 0]));
            this.scene.vertices.push(new cn_vertex([5, 0]));
            this.scene.vertices.push(new cn_vertex([-5, -5]));
            this.scene.vertices.push(new cn_vertex([5, -5]));
            this.scene.vertices.push(new cn_vertex([-5, 5]));
            this.scene.vertices.push(new cn_vertex([5, 5]));
            this.scene.vertices.push(new cn_vertex([0, 10]));
            this.scene.vertices.push(new cn_vertex([0, -10]));
            this.scene.walls.push(new cn_wall(this.scene.vertices[0], this.scene.vertices[1], wall_type, CN_MIDDLE, this.scene));
            this.scene.walls.push(new cn_wall(this.scene.vertices[2], this.scene.vertices[3], wall_type, CN_MIDDLE, this.scene));
            this.scene.walls.push(new cn_wall(this.scene.vertices[4], this.scene.vertices[5], wall_type, CN_MIDDLE, this.scene));
            this.scene.walls.push(new cn_wall(this.scene.vertices[6], this.scene.vertices[7], wall_type, CN_MIDDLE, this.scene));
            this.scene.walls.push(new cn_wall(this.scene.vertices[0], this.scene.vertices[2], wall_type, CN_MIDDLE, this.scene));
        }
        else if (this.test_number == 2) {
            this._test_overlap(wall_type, wall_type, 0)
        }
        else if (this.test_number == 3) {
            this._test_overlap(wall_type, wall_types[3], 0)
        }
        else if (this.test_number == 4) {
            this._test_overlap(wall_types[0], wall_types[3], 0)
        }
        else if (this.test_number == 5) {
            this._test_overlap(wall_types[2], wall_types[3], 0.05)
        }
        else {
            cn_seed(0.65487 + 0.01 * this.test_number);
            //*** build random vertices */
            for (var n = 0; n < nb_walls / 4 + 4; n++)
                this.scene.vertices.push(new cn_vertex([-10 + 20 * cn_random(), -10 + 20 * cn_random()], false));

            //*** Build random walls */
            for (var n = 0; n < nb_walls; n++) {
                wall_type = wall_types[Math.floor(cn_random() * wall_types.length) % wall_types.length];
                const v0 = this.scene.vertices[Math.floor(cn_random() * this.scene.vertices.length) % this.scene.vertices.length];
                var v1 = v0;
                while (v1 == v0)
                    v1 = this.scene.vertices[Math.floor(cn_random() * this.scene.vertices.length) % this.scene.vertices.length];
                this.scene.walls.push(new cn_wall(v0, v1, wall_type, CN_MIDDLE, this.scene));
            }
        }

        this.scene.update_vertices(false);
        this.scene.update_walls();
        this.process();
    }

    _test_overlap(wall_type0, wall_type1, dy) {
        const opening_type = this.building.get_opening_types()[0];

        let nv = 0;
        for (var y = -6; y <= 6; y++) {
            this.scene.vertices.push(new cn_vertex([-2.5, y]));
            this.scene.vertices.push(new cn_vertex([2.5, y]));
            this.scene.vertices.push(new cn_vertex([-2 + y, y - dy]));
            this.scene.vertices.push(new cn_vertex([2 + y, y + dy]));
            const wall0 = new cn_wall(this.scene.vertices[nv], this.scene.vertices[nv + 1], wall_type0, CN_MIDDLE, this.scene);
            const wall1 = new cn_wall(this.scene.vertices[nv + 2], this.scene.vertices[nv + 3], wall_type1, CN_MIDDLE, this.scene);
            wall0.openings.push(new cn_opening(opening_type));
            wall0.openings[0].position = 0.5;
            wall0.openings[0].wall = wall0;
            wall1.openings.push(new cn_opening(opening_type));
            wall1.openings[0].position = 0.5;
            wall1.openings[0].wall = wall1;
            this.scene.walls.push(wall0);
            this.scene.walls.push(wall1);
            nv += 4;
        }
    }
}

