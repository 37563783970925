<div class="container">
    <div class="py-4 d-flex flex-column">
        <ng-container *ngIf="interventionFiles.length > 0; else elseZeroPhoto">
            <div
                *ngIf="!currentDiagnostic?.reportagesPhoto || currentDiagnostic?.reportagesPhoto.length === 0"
                class="text-center"
            >
                Pas de reportage photo existant dans ce diagnostic
            </div>
            <div *ngIf="currentDiagnostic?.reportagesPhoto && currentDiagnostic?.reportagesPhoto.length > 0">
                <table class="table-reportage-photo" mat-table [dataSource]="currentDiagnostic?.reportagesPhoto">
                    <ng-container matColumnDef="nom">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Liste des reportages photo</th>
                        <td mat-cell *matCellDef="let rowRreportagePhoto">
                            <span class="flex-grow-1" [ngClass]="!rowRreportagePhoto.autoGenerated ? 'ml-4' : ''">
                                <lib-icon *ngIf="rowRreportagePhoto.autoGenerated" class="icon-small"
                                    >auto_fix_normal</lib-icon
                                >
                                {{ rowRreportagePhoto.nom }}</span
                            >
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let rowRreportagePhoto" class="text-center">
                            <button
                                mat-icon-button
                                class="align-self-end button-group primary"
                                (click)="onClickEditReportage(rowRreportagePhoto)"
                            >
                                <lib-icon class="icon-small">edit</lib-icon>
                            </button>
                            <button
                                *ngIf="!readonlyMode"
                                mat-icon-button
                                class="align-self-end button-group warn ml-3"
                                (click)="onClickDeleteReportage(rowRreportagePhoto)"
                            >
                                <lib-icon class="icon-small">delete</lib-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>
        </ng-container>
        <ng-template #elseZeroPhoto>
            <div class="text-center">
                Vous n'avez pas de photos, veuillez d'abord ajouter des photos avant de créer un reportage.
            </div>
        </ng-template>

        <button
            [disabled]="readonlyMode"
            mat-raised-button
            color="accent"
            class="mx-0 mt-4 align-self-center"
            (click)="onClickCreerReportage()"
        >
            Créer un nouveau reportage photo
        </button>
    </div>
</div>
