import { Component, Input } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { MatDialog } from '@angular/material/dialog';
import { SectionHelp } from '../../../model/section-help.model';
import { HelpModalComponent } from '../help-modal/help-modal.component';
import { DialogUtils } from '../../../utils/dialog.utils';

@Component({
    selector: 'app-section-title',
    templateUrl: './section-title.component.html',
    styleUrls: ['./section-title.component.scss'],
})
export class SectionTitleComponent extends BaseComponent {
    @Input() label: string;

    @Input() showHelp = true;

    @Input() helpToDisplay: SectionHelp;

    constructor(public matDialog: MatDialog) {
        super();
    }

    openHelp() {
        return this.matDialog
            .open(HelpModalComponent, {
                ...DialogUtils.configFullScreen(),
                data: {
                    help: this.helpToDisplay,
                },
            })
            .afterClosed()
            .subscribe();
    }
}
