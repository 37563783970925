import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-logement-prises',
    templateUrl: './logement-prises.component.html',
    styleUrls: ['./logement-prises.component.scss'],
})
export class LogementPrisesComponent implements OnInit {
    readonly MENU_NAME = 'Prises';

    constructor() {}

    ngOnInit(): void {}
}
