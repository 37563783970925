'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** facing trimming : a trimming on a facing
//***********************************************************************************
//***********************************************************************************

import { cn_element } from './cn_element';
import { cn_contour } from './cn_contour';
import { cn_add, cn_box, cn_clone, cn_color_hexa_to_rgb, cnx_add, cnx_clone, cnx_cross, cnx_dot, cnx_mul, cnx_sub, } from '../utils/cn_utilities';
import { fh_polygon, fh_scene } from '@acenv/fh-3d-viewer';
import { cn_scene } from './cn_scene';
import { cn_image_dir } from '../utils/image_dir';
import { cn_3d_building } from './cn_3d_building';
import { cn_building } from './cn_building';
import { cn_camera } from '../svg/cn_camera';
import { CN_CURRENT_DATE } from '../utils/cn_transaction_manager';
import { cn_storey } from './cn_storey';
import { logger } from '../utils/cn_logger';

export const CN_FACING_TRIMMING_PLACEMENT_WALL = 0;
export const CN_FACING_TRIMMING_PLACEMENT_FLOOR = 1;
export const CN_FACING_TRIMMING_PLACEMENT_CEILING = 1;

export class cn_facing_trimming extends cn_element {
    /**
     *
     * @param {cn_scene | cn_building} parent
     */
    constructor(parent) {
        super(parent);
        this.scene = (parent.constructor == cn_scene) ? parent : null;
        this.building = (parent.constructor == cn_building) ? parent : null;

        //*** placement : wall, floor or ceiling */
        this.placement = (this.scene) ? CN_FACING_TRIMMING_PLACEMENT_FLOOR : CN_FACING_TRIMMING_PLACEMENT_WALL;

        //*** if placement is a floor or a ceiling, current space impacted by the trimming. This may vary when spaces vary */
        this.space = null;

        //*** if placement is a wall, the wall impacted by the trimming */
        this.wall = null;
        this.wall_storey = null;
        this.wall_side = 0;
        this.wall_point = [0, 0, 0];
        this.wall_normal = [0, 0, 0];

        //*** Shape : an instance of cn_contour, or null. */
        //*** In world coordinates if placement is floor or ceiling, relative to the wall otherwise. */
        this.shape = null;

        //*** The facing for this trimming */
        this.facing = null;

        //*** volatile data */
        this._update_date = 0;
        this._bounding_box = new cn_box();
        this._polygon = new fh_polygon([0, 0, 0], [0, 0, 1]);
    }

    //***********************************************************************************
    /**
     * serialize
     * @returns {object}
     */
    serialize() {
        var json = {};
        json.ID = this.ID;
        json.placement = this.placement;
        if (this.placement == CN_FACING_TRIMMING_PLACEMENT_WALL) {
            json.wall_point = this.wall_point
            json.wall_normal = this.wall_normal;
        }

        json.shape = this.shape.serialize();

        if (this.facing)
            json.facing = (this.facing) ? { ID: this.facing.ID } : null;

        return json;
    }

    //***********************************************************************************
    /**
     * unserialize
     * @param {object} json
     * @param {cn_scene | cn_building} parent
     * @returns {cn_facing_trimming}
     */
    static unserialize(json, parent) {
        if (typeof (json.placement) != 'number') return null;
        if (typeof (json.shape) != 'object') return null;
        const contour = cn_contour.unserialize(json.shape, parent, null);
        if (!contour) return null;
        var ft = new cn_facing_trimming(parent);
        parent.facing_trimmings.push(ft);

        if (typeof (json.ID) == 'string')
            ft.ID = json.ID;

        if (typeof (json.placement) == 'number')
            ft.placement = json.placement;

        if (ft.placement == CN_FACING_TRIMMING_PLACEMENT_WALL && parent.constructor == cn_building) {
            ft.wall_point = json.wall_point
            ft.wall_normal = json.wall_normal;
        }

        ft.shape = contour;
        contour.parent = ft;

        if (json.facing && json.facing.ID) {
            const building = (parent.constructor == cn_building) ? parent : (parent.constructor == cn_scene) ? parent.building : null;
            if (building)
                ft.facing = building.facing_types.find(ft => ft.ID === json.facing.ID);
        }

        return ft;
    }

    //***********************************************************************************
    //**** update geometry
    //***********************************************************************************
    update() {

        if (this.placement == CN_FACING_TRIMMING_PLACEMENT_FLOOR || this.placement == CN_FACING_TRIMMING_PLACEMENT_CEILING) {
            if (this._update_date > 0 && this.up_to_date_shape(this._update_date))
                return;
            this._bounding_box = this.shape.get_bounding_box();
            this._polygon = this.shape.build_3d_polygon(0);
        }
        if (this.placement == CN_FACING_TRIMMING_PLACEMENT_WALL) {
            if (this.building.all_storeys().indexOf(this.wall_storey) >= 0 && this.wall_storey.scene.walls.indexOf(this.wall) >= 0) {
                this.wall_point = cnx_clone((this.wall_side == 0) ? this.wall.bounds.pmin : this.wall.bounds.pmax, this.wall_storey.altitude);
                this.wall_normal = cnx_clone(this.wall.bounds.normal);
                if (this.wall_side == 0) this.wall_normal = cnx_mul(this.wall_normal, -1);
            } else {
                this.wall_storey = null;
                this.wall = null;
                this.building.compute_altitudes();
                const shape = this.get_shape_3d(null);
                this.building.all_storeys().forEach(storey => {
                    const parallel_walls = storey.scene.walls.filter(w => Math.abs(cnx_dot(this.wall_normal, w.bounds.direction)) < 0.001);
                    parallel_walls.forEach(wall => {
                        const side = (cnx_dot(this.wall_normal, wall.bounds.normal) > 0) ? 1 : 0;
                        const wall_point = (side == 0) ? wall.bounds.pmin : wall.bounds.pmax;
                        if (Math.abs(cnx_dot(this.wall_normal, cnx_sub(this.wall_point, wall_point))) < 0.01) {
                            this.wall = wall;
                            this.wall_storey = storey;
                            this.wall_side = side;
                            this.wall_point = cnx_clone(wall_point, this.wall_storey.altitude);
                        }
                    });
                });
                this.set_shape_3d(shape, null);
            }
        }
        this._update_date = CN_CURRENT_DATE;
    }

    /**
     * Returns the list shape as a list of 3D points.
     * @param {cn_storey} storey
     * @returns {number[][]}
     */
    get_shape_3d(storey) {
        if (this.placement == CN_FACING_TRIMMING_PLACEMENT_WALL) {
            const p0 = this.wall_point;
            const dy = [0, 0, 1];
            const dx = cnx_cross(dy, this.wall_normal);
            const res = this.shape.vertices.map(v => cnx_add(p0, cnx_add(cnx_mul(dx, v.position[0]), cnx_mul(dy, v.position[1]))));
            return res;
        }

        var z = 0;
        if (storey)
            z += storey.altitude;
        if (this.space)
            z += this.space.slab_offset;

        return this.shape.vertices.map(v => cnx_clone(v.position, z));
    }

    /**
     * Sets the shape depending on support
     * @param {Array<number[]>} vertices
     * @param {cn_storey} storey
     */
    set_shape_3d(vertices, storey) {
        var contour = [];
        if (this.placement == CN_FACING_TRIMMING_PLACEMENT_WALL) {
            const p0 = this.wall_point;
            const dy = [0, 0, 1];
            const dx = cnx_cross(dy, this.wall_normal);
            contour = vertices.map(v => [cnx_dot(dx, cnx_sub(v, p0)), cnx_dot(dy, cnx_sub(v, p0))]);
        } else {
            contour = vertices.map(v => cn_clone(v));
        }
        this.shape = new cn_contour(contour, this);
    }

    /**
     * returns true if shape hasn't changed since date
     * @param {number} date
     * @returns {boolean}
     */
    up_to_date_shape(date) {
        if (this._creation_date > date) return false;
        if (this.get_date('shape') > date) return false;
        if (this.shape._date > date) return false;
        return true;
    }

    //***********************************************************************************
    //**** Draw the contour in svg
    //***********************************************************************************
    draw(camera, add_classes) {
        var html = '';
        if (this.status < 0) return html;
        if (this.placement == CN_FACING_TRIMMING_PLACEMENT_WALL && !camera.is_3d()) return html;

        var draw_class = 'facing_trimming ';
        if (add_classes)
            draw_class += ' ' + add_classes.join(' ');
        html += '<path class=\'' + draw_class + '\' d=\'';
        var shape_contour = this.get_shape_3d(camera.storey);
        for (var j = 0; j < shape_contour.length; j++) {
            if (j == 0) html += 'M ';
            else if (j == 1) html += 'L ';
            var p = camera.world_to_screen(shape_contour[j]);
            if (p.length == 0) return '';
            html += '' + p[0] + ' ' + p[1] + ' ';
        }
        html += 'Z ';
        html += '\' />';
        return html;
    }

    //***********************************************************************************
    //**** Translates
    //***********************************************************************************
    translate(delta) {
        if (this.placement == CN_FACING_TRIMMING_PLACEMENT_WALL) return;
        this.shape.inner_contour = [];
        for (var j in this.shape.vertices) {
            this.shape.vertices[j].position = cn_add(this.shape.vertices[j].position, delta);
            this.shape.inner_contour.push(cn_clone(this.shape.vertices[j].position));
        }
    }

    //***********************************************************************************
    /**
     * Vertex operation : transform all vertices
     * @param {function} operation : vertex operator
     */
    vertex_operation(operation) {
        if (this.placement == CN_FACING_TRIMMING_PLACEMENT_WALL) return;
        this.shape.inner_contour = [];
        for (var j in this.shape.vertices) {
            operation(this.shape.vertices[j].position);
            this.shape.inner_contour.push(cn_clone(this.shape.vertices[j].position));
        }
    }

    //***********************************************************************************
    /**
     * flip operation : transform all vertices
     * @param {number[]} center : center of flip
     * @param {boolean} horizontal : true for horizontal flip, vertical otherwise
     * @param {function} operation : vertex operator
     */
    perform_flip(center, horizontal, operation) {
        if (this.placement == CN_FACING_TRIMMING_PLACEMENT_WALL) return;
        this.shape.inner_contour = [];
        for (var j in this.shape.vertices) {
            operation(this.shape.vertices[j].position);
            this.shape.inner_contour.push(cn_clone(this.shape.vertices[j].position));
        }
        this.shape.vertices = this.shape.vertices.reverse();
        this.shape.inner_contour = this.shape.inner_contour.reverse();
    }

    //***********************************************************************************
    //**** Contains
    //***********************************************************************************
    contains(p) {
        if (this.placement == CN_FACING_TRIMMING_PLACEMENT_WALL) return false;
        return this.shape.contains(p, true);
    }

    //***********************************************************************************
    //**** Contains
    //***********************************************************************************
    contained_by_box(box) {
        if (this.placement == CN_FACING_TRIMMING_PLACEMENT_WALL) return false;
        if (!this.shape.contained_by_box(box))
            return false;
        return true;
    }

    //***********************************************************************************
    //**** get box
    //***********************************************************************************
    get_bounding_box() {
        return this._bounding_box;
    }

    /**
     * Returns the bounding box of the element
     * @param {cn_camera} camera
     * @returns {cn_box}
     */
    get_screen_bounding_box(camera) {
        const box = new cn_box();
        this.get_shape_3d(camera.storey).forEach(v => box.enlarge_point(camera.world_to_screen(v)));
        return box;
    }

    //***********************************************************************************
    /**
     * Build a polygon
     * @returns {fh_polygon}
     */
    build_3d_polygon() {
        var polygon = new fh_polygon();
        polygon.add_contour(this.get_shape_3d(null));
        polygon.compute_contours();
        return polygon;
    }

    /**
     * Update the 3D geometry of floor spacings
     * @param {cn_3d_building} building_3d
     * @param {cn_storey} storey
     */
    update_3d_texture(building_3d, storey = null) {
        const texture = (this.facing) ? cn_image_dir() + 'texture_' + this.facing.texture + '.jpg' : '';

        var objects3d = building_3d.get_3d_objects(this);
        if (storey)
            objects3d = objects3d.filter(ob => ob.cnmap_storey == storey);
        objects3d.forEach(ob => {
            const target_color = [...cn_color_hexa_to_rgb(this.facing && this.facing.color ? this.facing.color : '#ffffff'), 0.5];
            fh_scene.update_mesh_color(ob._meshes[0], target_color, texture);
            ob._meshes[0]._original_material.needsUpdate = true;
        });
    }

}
