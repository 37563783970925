<h1 mat-dialog-title class="primary">Avertissement</h1>

<div mat-dialog-content>
    <ng-container *ngIf="valid">
        Il y a des modifications non enregistrées. Souhaitez-vous quitter sans enregistrer ?
    </ng-container>
    <ng-container *ngIf="!valid">
        Le formulaire n'est pas valide. Souhaitez-vous quitter sans enregistrer ?
    </ng-container>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-button color="primary" (click)="cancel()">
        <mat-icon>close</mat-icon>
        <span>{{ 'Ne pas quitter' | uppercase }}</span>
    </button>

    <button mat-button color="primary" (click)="quitWithoutSaving()">
        <lib-icon theme="mdi" iconName="content-save-off"></lib-icon>
        <span>{{ 'Annuler sans enregistrer' | uppercase }}</span>
    </button>

    <button *ngIf="valid" mat-raised-button color="accent" (click)="quitWithSaving()">
        <mat-icon>save</mat-icon>
        <span>{{ 'Enregistrer puis quitter' | uppercase }}</span>
    </button>
</div>
