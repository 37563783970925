<div class="filter-header">
    <div class="field field-label">
        <label for="process" class="font-weight-normal">n°filtre</label>
        <label for="process" class="font-weight-normal">n°pompe</label>
        <label for="process" class="font-weight-normal">Débit initial</label>
        <label for="process" class="font-weight-normal">Test Etanchéité</label>
    </div>

    <button mat-button (click)="ajouterFiltre()" [disabled]="disabled" class="button-center">
        <mat-icon>add</mat-icon>
    </button>
</div>
<div class="filter-list">
    <app-item-card *ngFor="let filter of filterForm.value; let i = index" class="filter-card">
        <div content class="content-col">
            <span class="col">n°{{ filter.numeroFiltre }}</span>
            <span class="col">MOC {{ filter.numeroPompe }}</span>
            <span class="col">{{ filter.debitInitial }}</span>
            <span class="col">{{ filter.testEtancheite }}</span>
        </div>
        <div action>
            <button mat-icon-button aria-label="copier" (click)="dupliquerFiltre(i)" [disabled]="disabled">
                <mat-icon>content_copy</mat-icon>
            </button>
            <button mat-icon-button aria-label="modifier" (click)="modifierFiltre(i)" [disabled]="disabled">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button aria-label="supprimer" (click)="supprimerFiltre(i)" [disabled]="disabled">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </app-item-card>
</div>
