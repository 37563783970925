import { MongoUtils } from 'src/app/commons-lib';

/**
 * Type d'ouvrage.
 */
export class TypeOuvrage {
    id: string = MongoUtils.generateObjectId();

    /**
     * Désignation
     */
    name: string;

    /**
     * Liste des ids des types d'ouvrage parent
     */
    parentsIds?: string[] = [];

    constructor() {}
}
