<mat-card>
    <div class="input-with-prefix">
        <mat-form-field appearance="outline" class="w-50">
            <input matInput type="text" (keyup)="onKeyupSearchBiens($event)" [(ngModel)]="searchValue" placeholder="Recherche libre" />
            <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
    </div>
    <div class="d-flex flex-column">
        <table
            mat-table
            [dataSource]="dataSource"
            matSort
            (matSortChange)="sortBiens($event)"
            [matSortActive]="sort?.active"
            [matSortDirection]="sort?.direction"
            matSortDisableClear="true"
        >
            <ng-container matColumnDef="nom">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
                <td mat-cell *matCellDef="let row">{{ row.nom }}</td>
            </ng-container>

            <ng-container matColumnDef="contactProprietaire.nom">
                <th mat-header-cell *matHeaderCellDef>Propriétaire</th>
                <td mat-cell *matCellDef="let row">{{ row.contactProprietaireString }}</td>
            </ng-container>

            <ng-container matColumnDef="idTypeBien">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Type de bien</th>
                <td mat-cell *matCellDef="let row">{{ row.idTypeBien }}</td>
            </ng-container>

            <ng-container matColumnDef="adresse.voie">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Adresse</th>
                <td mat-cell *matCellDef="let row">
                    {{ row?.adresse?.voie }} <br />
                    {{ row?.adresse?.codePostal }} {{ row?.adresse?.ville }}
                </td>
            </ng-container>

            <ng-container matColumnDef="numeroLot">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Numéro de lot</th>
                <td mat-cell *matCellDef="let row">{{ row.numeroLot }}</td>
            </ng-container>

            <ng-container matColumnDef="referenceCadastre">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Référence cadastrale</th>
                <td mat-cell *matCellDef="let row">{{ row.referenceCadastre }}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                    <button mat-icon-button (click)="consulter(row)">
                        <mat-icon>visibility</mat-icon>
                    </button>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="dupliquerBien(row)">
                            <mat-icon>content_copy</mat-icon>
                            <span>Dupliquer</span>
                        </button>
                        <button mat-menu-item (click)="editerBien(row)">
                            <mat-icon>edit</mat-icon>
                            <span>Éditer</span>
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator
            #paginator
            *ngIf="dataSource.page$ | async as page"
            [length]="page.totalElements"
            [pageSize]="page.size"
            [pageIndex]="page.number"
            [pageSizeOptions]="[initialPageSize, 50, 100]"
            (page)="dataSource.fetch($event.pageIndex, paginator.pageSize)"
        ></mat-paginator>
    </div>
</mat-card>
