import { Component, OnInit } from '@angular/core';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CnSpinnerService } from '../../../../../shared/cn-spinner/service/cn-spinner.service';
import { BaseComponent } from 'src/app/commons-lib';
import { takeUntil } from 'rxjs/operators';
import { Polluant } from '../../../model/polluant.model';

@Component({
    selector: 'app-besoins-selection-redirection',
    templateUrl: './besoins-prelevement-redirection.component.html',
    styleUrls: ['./besoins-prelevement-redirection.component.scss'],
})
export class PolluantPerimetreSelectionRedirectionComponent extends BaseComponent implements OnInit {
    constructor(
        private readonly diagnosticService: DiagnosticService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly cnSpinnerService: CnSpinnerService
    ) {
        super();
    }

    ngOnInit(): void {
        this.cnSpinnerService
            .withSpinner(this.diagnosticService.getCurrentDiagnostic())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((currentDiagnostic) => {
                const perimetres = (currentDiagnostic.contenuDiagnostic as Polluant).perimetresInvestigation.data.listePerimetreInvestigation;

                // Permet de rediriger vers le premier périmètre d'investigation
                if (perimetres && perimetres.length) {
                    const idPerimetre = perimetres[0].id;
                    this.router.navigate([`../${idPerimetre}`], { relativeTo: this.route });
                }
            });
    }

    navigateEcran(nomEcran) {
        const url = this.router.url.split('/');
        url.pop();
        url.pop();
        url.push(nomEcran);
        this.router.navigateByUrl(url.join('/'));
    }
}
