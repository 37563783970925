import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'lib-user-set-password-dialog',
    templateUrl: './user-set-password-dialog.component.html',
    styleUrls: ['./user-set-password-dialog.component.css'],
})
export class UserSetPasswordDialogComponent implements OnInit {
    newPassword: string;
    newPasswordConfirm: string;
    notifyUserByEmail: boolean;

    constructor(private dialogRef: MatDialogRef<UserSetPasswordDialogComponent>) {}

    ngOnInit() {}

    submit() {
        this.dialogRef.close({
            newPassword: this.newPassword,
            notifyUserByEmail: this.notifyUserByEmail,
        });
    }
}
