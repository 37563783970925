/**
 * Traitement pour les chaines de caractères
 */
export const DISPLAY_FN_COMMANDE = (a) => {
    return a && a.numeroCommande ? a.numeroCommande : null;
};

export const DISPLAY_FN_NAME = (a) => {
    return a && a.nom ? a.nom : null;
};

export const DISPLAY_FN_CONTACT = (a) => {
    return a && a.nom ? (a.estPersonneMorale ? a.nom + ' - ' + a.siret : a.prenom ? a.nom + ' - ' + a.prenom : a.nom) : null;
};

export const DISPLAY_FN_USER = (a) => {
    return a && a.lastName ? (a.firstName ? a.lastName + ' ' + a.firstName : a.lastName) : null;
};
