import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { CeeConfig } from '../model/cee.model';
import { OfflineStorageService, ResourceWrapper } from '../../../../shared/offline/offline-storage.service';
import { STORAGE_KEY_CONFIGS_CEE } from '../../../../shared/constants/indexeddb.constants';

@Injectable({
    providedIn: 'root',
})
export class CeeApiService {
    public resourceUrl = `${environment.apiUrl}/cee/config`;
    private http: HttpClient;
    private resource: ResourceWrapper<CeeConfig, string> = this.offlineStorage.wrapRestResource<CeeConfig, string>(
        {
            cacheName: STORAGE_KEY_CONFIGS_CEE,
            idField: 'id',
            resourceUrl: this.resourceUrl,
        },
        true
    );
    private resourceOffline: ResourceWrapper<CeeConfig, string> = this.offlineStorage.wrapRestResource<CeeConfig, string>(
        {
            cacheName: STORAGE_KEY_CONFIGS_CEE,
            idField: 'id',
            resourceUrl: this.resourceUrl,
        },
        false
    );
    constructor(private httpBackend: HttpBackend, private offlineStorage: OfflineStorageService) {
        this.http = new HttpClient(httpBackend);
    }

    getConfig(): Observable<CeeConfig[]> {
        //return this.http.get<CeeConfig>(this.resourceUrl + '/config');
        return this.resourceOffline.getAll();
    }

    findAllCeeConfig(): Observable<CeeConfig[]> {
        return this.resource.getAll(null, null, `${this.resourceUrl}`);
    }

    findAllCeeConfigOffline(): Observable<CeeConfig[]> {
        return this.resource.getAll();
    }
}
