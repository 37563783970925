<div class="container">
    <div class="pt-5 row">
        <mat-card class="w-100 position-relative p-1 mb-2">
            <div class="p-2 header-editor-panel d-flex align-content-center w-100 justify-content-between">
                <div class="d-flex">
                    <div class="pl-1 header-editor-panel-text d-flex align-items-center">Information sur la visite</div>
                </div>
            </div>
            <form [formGroup]="dateForm" class="container-fluid">
                <div class="row d-block">
                    <label class="primary col-2 align-middle">Date du rendez-vous<span class="required"></span></label>
                    <mat-form-field class="col-3 form-custom" appearance="outline">
                        <input matInput [matDatepicker]="pickerVisite" placeholder="Date" formControlName="dateRDV" required />
                        <mat-datepicker-toggle matSuffix [for]="pickerVisite"></mat-datepicker-toggle>
                        <mat-datepicker #pickerVisite></mat-datepicker>
                    </mat-form-field>
                    <mat-checkbox [color]="'primary'" formControlName="isDateAuPlusTard"> Date au plus tard ou prévisionnelle </mat-checkbox>
                </div>
            </form>
        </mat-card>
    </div>
    <app-accompagnateurs [contacts]="contacts" [readonlyMode]="readonlyMode"></app-accompagnateurs>
</div>
