import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

import { ValidatorUtils } from '../../../../../../utils/validator.utils';

export interface DialogData {
    sequence: any;
}

@Component({
    selector: 'app-sequence-modal',
    templateUrl: './sequence-modal.component.html',
    styleUrls: ['./sequence-modal.component.scss'],
})
export class SequenceModalComponent {
    formDoc: FormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, private formBuilder: FormBuilder) {
        this.formDoc = this.formBuilder.group({
            dateDebut: [moment(data.sequence.dateTimeDebut), [Validators.required]],
            dateFin: [moment(data.sequence.dateTimeFin), [Validators.required]],
        });
        this.formDoc.setValidators(ValidatorUtils.checkFirstDateIsBeforeSecond());
    }
}
