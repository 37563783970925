export interface IdControl {
    value: {
        id: string;
    };
    displayed: boolean;
}
export interface StringControl {
    value: string;
    displayed: boolean;
}

export interface NumberControl {
    value: number;
    displayed: boolean;
}

export interface BooleanControl {
    value: boolean;
    displayed: boolean;
}

export interface NullableStringControl {
    value: string | null;
    displayed: boolean;
}

export interface MainControls {
    nom: StringControl;
    detail: StringControl;
}

export interface BienControls {
    bien: IdControl;
    niveau: IdControl;
}

export interface EspaceControls {
    espace: IdControl;
}

export interface PageControls {
    format: StringControl;
}

export interface PlanGeneralControls {
    scale: NumberControl;
    resolution: NumberControl;
    fixedScale: BooleanControl;
    fixedPosition: BooleanControl;
}

export interface PlanDisplayControls {
    render: NumberControl;
    showGrid: BooleanControl;
    showScale: BooleanControl;
    showBackground: BooleanControl;
    showCompass: BooleanControl;
}

export interface PlanElementsControls {
    showSpaces: BooleanControl;
    showOuterWalls: BooleanControl;
    showInnerWalls: BooleanControl;
    showBalconies: BooleanControl;
    showWindows: BooleanControl;
    showDoors: BooleanControl;
    showStairs: BooleanControl;
    showSlabOpenings: BooleanControl;
    showBeams: BooleanControl;
    showColumns: BooleanControl;
    showObjectsShape: BooleanControl;
    showObjectsIcon: BooleanControl;
    showPipes: BooleanControl;
}

export interface PlanTextControls {
    showSpaceLabel: BooleanControl;
    showSpaceArea: BooleanControl;
    showVisitLock?: BooleanControl;
    showMarkers: BooleanControl;
    showNumerotation: BooleanControl;
    showLegend: BooleanControl;
}

export interface PlanCotesControls {
    showOuterMeasures: BooleanControl;
    showInnerMeasures: BooleanControl;
    showOpeningMeasures: BooleanControl;
}

export interface PlanGroups {
    general: {
        displayed: boolean;
        controls: PlanGeneralControls;
    };
    display: {
        displayed: boolean;
        controls: PlanDisplayControls;
    };
    elements: {
        displayed: boolean;
        controls: PlanElementsControls;
    };
    text: {
        displayed: boolean;
        controls: PlanTextControls;
    };
    cotes: {
        displayed: boolean;
        controls: PlanCotesControls;
    };
}

export interface EquipmentConformiteControls {
    showConformeObjects: BooleanControl;
    showNonConformeObjects: BooleanControl;
    showAJustifierObjects: BooleanControl;
    showNonVerifieObjects: BooleanControl;
}

export interface EquipmentGroups {
    conformite: {
        displayed: boolean;
        controls: EquipmentConformiteControls;
    };
}

export interface ZoneSurfaceControls {
    showSurfacesComptabilisees: BooleanControl;
    showSurfacesDecomptees: BooleanControl;
}

export interface ZoneGroups {
    surface: {
        displayed: boolean;
        controls: ZoneSurfaceControls;
    };
}

export interface HapSurfaceControls {
    showPerimetres: BooleanControl;
    showZones: BooleanControl;
    showPrelevements: BooleanControl;
}

export interface HapZoneControls {
    showZoneInferieurEgal50: BooleanControl;
    showZoneEntre51Et500: BooleanControl;
    showZoneEntre501Et1000: BooleanControl;
    showZoneSuperieur1000: BooleanControl;
    showZoneNonRealises: BooleanControl;
}

export interface HapPrelevementControls {
    showPrelevementInferieurEgal50: BooleanControl;
    showPrelevementEntre51Et500: BooleanControl;
    showPrelevementEntre501Et1000: BooleanControl;
    showPrelevementSuperieur1000: BooleanControl;
    showPrelevementNonRealises: BooleanControl;
}

export interface HapGroups {
    surface: {
        displayed: boolean;
        controls: HapSurfaceControls;
    };
    zone: {
        displayed: boolean;
        controls: HapZoneControls;
    };
    prelevement: {
        displayed: boolean;
        controls: HapPrelevementControls;
    };
}

export interface PolluantControls {
    zoneId: NullableStringControl;
    perimetreId: NullableStringControl;
    showBesoins: BooleanControl;
    showPerimetres: BooleanControl;
}

export interface SvgConfigType {
    id: string;
    name: string;
    main: {
        controls: MainControls;
    };
    bien: {
        displayed: boolean;
        controls: BienControls;
    };
    espace?: {
        displayed: boolean;
        controls: EspaceControls;
    };
    page: {
        displayed: boolean;
        controls: PageControls;
    };
    plan: {
        displayed: boolean;
        groups: PlanGroups;
    };
    equipment?: {
        displayed: boolean;
        groups: EquipmentGroups;
    };
    zone?: {
        displayed: boolean;
        groups: ZoneGroups;
    };
    hap?: {
        displayed: boolean;
        groups: HapGroups;
    };
    polluant?: {
        displayed: boolean;
        controls: PolluantControls;
    };
}

export const exportSvgTypes: SvgConfigType[] = [
    {
        id: 'idConfAssainissementConforme',
        name: 'Elements conformes',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idConfAssainissementConforme', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: true },
                        showOuterWalls: { value: true, displayed: true },
                        showInnerWalls: { value: true, displayed: true },
                        showBalconies: { value: true, displayed: true },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: true },
                        showSlabOpenings: { value: false, displayed: true },
                        showBeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: true },
                        showPipes: { value: true, displayed: true },
                        showObjectsShape: { value: false, displayed: true },
                        showObjectsIcon: { value: true, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showVisitLock: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: false, displayed: false },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: true,
            groups: {
                conformite: {
                    displayed: true,
                    controls: {
                        showConformeObjects: { value: true, displayed: true },
                        showNonConformeObjects: { value: false, displayed: true },
                        showAJustifierObjects: { value: false, displayed: true },
                        showNonVerifieObjects: { value: false, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: true, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: true, displayed: true },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: true },
                        showZoneEntre51Et500: { value: false, displayed: true },
                        showZoneEntre501Et1000: { value: false, displayed: true },
                        showZoneSuperieur1000: { value: false, displayed: true },
                        showZoneNonRealises: { value: false, displayed: true },
                    },
                },
                prelevement: {
                    displayed: true,
                    controls: {
                        showPrelevementInferieurEgal50: { value: true, displayed: true },
                        showPrelevementEntre51Et500: { value: true, displayed: true },
                        showPrelevementEntre501Et1000: { value: true, displayed: true },
                        showPrelevementSuperieur1000: { value: true, displayed: true },
                        showPrelevementNonRealises: { value: true, displayed: true },
                    },
                },
            },
        },
    },
    {
        id: 'idConfAssainissementNonConforme',
        name: 'Elements non conformes',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idConfAssainissementNonConforme', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showBeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showPipes: { value: true, displayed: false },
                        showObjectsShape: { value: false, displayed: true },
                        showObjectsIcon: { value: true, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showVisitLock: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: false, displayed: false },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: true,
            groups: {
                conformite: {
                    displayed: true,
                    controls: {
                        showConformeObjects: { value: false, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: true, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: true, displayed: true },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: true },
                        showZoneEntre51Et500: { value: false, displayed: true },
                        showZoneEntre501Et1000: { value: false, displayed: true },
                        showZoneSuperieur1000: { value: false, displayed: true },
                        showZoneNonRealises: { value: false, displayed: true },
                    },
                },
                prelevement: {
                    displayed: true,
                    controls: {
                        showPrelevementInferieurEgal50: { value: true, displayed: true },
                        showPrelevementEntre51Et500: { value: true, displayed: true },
                        showPrelevementEntre501Et1000: { value: true, displayed: true },
                        showPrelevementSuperieur1000: { value: true, displayed: true },
                        showPrelevementNonRealises: { value: true, displayed: true },
                    },
                },
            },
        },
    },
    {
        id: 'idConfAssainissementBienEtEquipement',
        name: 'Bien et équipements',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idConfAssainissementBienEtEquipement', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showBeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showPipes: { value: true, displayed: false },
                        showObjectsShape: { value: false, displayed: true },
                        showObjectsIcon: { value: true, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showVisitLock: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: false, displayed: false },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: true,
            groups: {
                conformite: {
                    displayed: true,
                    controls: {
                        showConformeObjects: { value: false, displayed: true },
                        showNonConformeObjects: { value: false, displayed: true },
                        showAJustifierObjects: { value: false, displayed: true },
                        showNonVerifieObjects: { value: false, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: true, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: true, displayed: true },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: true },
                        showZoneEntre51Et500: { value: false, displayed: true },
                        showZoneEntre501Et1000: { value: false, displayed: true },
                        showZoneSuperieur1000: { value: false, displayed: true },
                        showZoneNonRealises: { value: false, displayed: true },
                    },
                },
                prelevement: {
                    displayed: true,
                    controls: {
                        showPrelevementInferieurEgal50: { value: true, displayed: true },
                        showPrelevementEntre51Et500: { value: true, displayed: true },
                        showPrelevementEntre501Et1000: { value: true, displayed: true },
                        showPrelevementSuperieur1000: { value: true, displayed: true },
                        showPrelevementNonRealises: { value: true, displayed: true },
                    },
                },
            },
        },
    },
    {
        id: 'idMesurageTotal',
        name: 'Mesurage',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idMesurageTotal', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showBeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showPipes: { value: true, displayed: false },
                        showObjectsShape: { value: false, displayed: true },
                        showObjectsIcon: { value: true, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showVisitLock: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: false, displayed: false },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: false,
            groups: {
                conformite: {
                    displayed: true,
                    controls: {
                        showConformeObjects: { value: true, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: true,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: true, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: true, displayed: true },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: true },
                        showZoneEntre51Et500: { value: false, displayed: true },
                        showZoneEntre501Et1000: { value: false, displayed: true },
                        showZoneSuperieur1000: { value: false, displayed: true },
                        showZoneNonRealises: { value: false, displayed: true },
                    },
                },
                prelevement: {
                    displayed: true,
                    controls: {
                        showPrelevementInferieurEgal50: { value: true, displayed: true },
                        showPrelevementEntre51Et500: { value: true, displayed: true },
                        showPrelevementEntre501Et1000: { value: true, displayed: true },
                        showPrelevementSuperieur1000: { value: true, displayed: true },
                        showPrelevementNonRealises: { value: true, displayed: true },
                    },
                },
            },
        },
    },
    {
        id: 'idMesurageComptabilisee',
        name: 'Surfaces comptabilisées',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idMesurageComptabilisee', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showBeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showPipes: { value: true, displayed: false },
                        showObjectsShape: { value: false, displayed: true },
                        showObjectsIcon: { value: true, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showVisitLock: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: false, displayed: false },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: false,
            groups: {
                conformite: {
                    displayed: true,
                    controls: {
                        showConformeObjects: { value: true, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: true,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: true, displayed: true },
                        showSurfacesDecomptees: { value: false, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: true, displayed: true },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: true },
                        showZoneEntre51Et500: { value: false, displayed: true },
                        showZoneEntre501Et1000: { value: false, displayed: true },
                        showZoneSuperieur1000: { value: false, displayed: true },
                        showZoneNonRealises: { value: false, displayed: true },
                    },
                },
                prelevement: {
                    displayed: true,
                    controls: {
                        showPrelevementInferieurEgal50: { value: true, displayed: true },
                        showPrelevementEntre51Et500: { value: true, displayed: true },
                        showPrelevementEntre501Et1000: { value: true, displayed: true },
                        showPrelevementSuperieur1000: { value: true, displayed: true },
                        showPrelevementNonRealises: { value: true, displayed: true },
                    },
                },
            },
        },
    },
    {
        id: 'idMesurageDecomptee',
        name: 'Surfaces décomptées',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idMesurageDecomptee', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showBeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showPipes: { value: true, displayed: false },
                        showObjectsShape: { value: false, displayed: true },
                        showObjectsIcon: { value: true, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showVisitLock: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: false, displayed: false },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: false,
            groups: {
                conformite: {
                    displayed: true,
                    controls: {
                        showConformeObjects: { value: true, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: true,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: false, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: true, displayed: true },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: true },
                        showZoneEntre51Et500: { value: false, displayed: true },
                        showZoneEntre501Et1000: { value: false, displayed: true },
                        showZoneSuperieur1000: { value: false, displayed: true },
                        showZoneNonRealises: { value: false, displayed: true },
                    },
                },
                prelevement: {
                    displayed: true,
                    controls: {
                        showPrelevementInferieurEgal50: { value: true, displayed: true },
                        showPrelevementEntre51Et500: { value: true, displayed: true },
                        showPrelevementEntre501Et1000: { value: true, displayed: true },
                        showPrelevementSuperieur1000: { value: true, displayed: true },
                        showPrelevementNonRealises: { value: true, displayed: true },
                    },
                },
            },
        },
    },
    {
        id: 'idHapPerimetres',
        name: 'HAP - Périmètres',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idHapPerimetres', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        espace: {
            displayed: true,
            controls: {
                espace: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showBeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showPipes: { value: true, displayed: false },
                        showObjectsShape: { value: false, displayed: true },
                        showObjectsIcon: { value: true, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showVisitLock: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: true, displayed: true },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: false,
            groups: {
                conformite: {
                    displayed: true,
                    controls: {
                        showConformeObjects: { value: true, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: false, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: true,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: false, displayed: true },
                        showPrelevements: { value: false, displayed: true },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: false },
                        showZoneEntre51Et500: { value: false, displayed: false },
                        showZoneEntre501Et1000: { value: false, displayed: false },
                        showZoneSuperieur1000: { value: false, displayed: false },
                        showZoneNonRealises: { value: false, displayed: false },
                    },
                },
                prelevement: {
                    displayed: false,
                    controls: {
                        showPrelevementInferieurEgal50: { value: false, displayed: false },
                        showPrelevementEntre51Et500: { value: false, displayed: false },
                        showPrelevementEntre501Et1000: { value: false, displayed: false },
                        showPrelevementSuperieur1000: { value: false, displayed: false },
                        showPrelevementNonRealises: { value: false, displayed: false },
                    },
                },
            },
        },
    },
    {
        id: 'idHapZones',
        name: 'HAP - Zones',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idHapZones', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        espace: {
            displayed: true,
            controls: {
                espace: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showBeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showPipes: { value: true, displayed: false },
                        showObjectsShape: { value: false, displayed: true },
                        showObjectsIcon: { value: true, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showVisitLock: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: true, displayed: true },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: false,
            groups: {
                conformite: {
                    displayed: true,
                    controls: {
                        showConformeObjects: { value: true, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: false, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: true,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: false, displayed: false },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: false },
                        showZoneEntre51Et500: { value: false, displayed: false },
                        showZoneEntre501Et1000: { value: false, displayed: false },
                        showZoneSuperieur1000: { value: false, displayed: false },
                        showZoneNonRealises: { value: false, displayed: false },
                    },
                },
                prelevement: {
                    displayed: false,
                    controls: {
                        showPrelevementInferieurEgal50: { value: false, displayed: false },
                        showPrelevementEntre51Et500: { value: false, displayed: false },
                        showPrelevementEntre501Et1000: { value: false, displayed: false },
                        showPrelevementSuperieur1000: { value: false, displayed: false },
                        showPrelevementNonRealises: { value: false, displayed: false },
                    },
                },
            },
        },
    },
    {
        id: 'idHapBesoins',
        name: 'HAP - Besoins / Prélèvements',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idHapBesoins', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        espace: {
            displayed: true,
            controls: {
                espace: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showBeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showPipes: { value: true, displayed: false },
                        showObjectsShape: { value: false, displayed: true },
                        showObjectsIcon: { value: true, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showVisitLock: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: true, displayed: true },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: false,
            groups: {
                conformite: {
                    displayed: true,
                    controls: {
                        showConformeObjects: { value: true, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: false, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: true,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: false, displayed: true },
                        showPrelevements: { value: true, displayed: true },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: false },
                        showZoneEntre51Et500: { value: false, displayed: false },
                        showZoneEntre501Et1000: { value: false, displayed: false },
                        showZoneSuperieur1000: { value: false, displayed: false },
                        showZoneNonRealises: { value: false, displayed: false },
                    },
                },
                prelevement: {
                    displayed: false,
                    controls: {
                        showPrelevementInferieurEgal50: { value: false, displayed: false },
                        showPrelevementEntre51Et500: { value: false, displayed: false },
                        showPrelevementEntre501Et1000: { value: false, displayed: false },
                        showPrelevementSuperieur1000: { value: false, displayed: false },
                        showPrelevementNonRealises: { value: false, displayed: false },
                    },
                },
            },
        },
    },
    {
        id: 'idHapPrelResultats',
        name: 'HAP - Prélèvements et résultats',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idHapPrelResultats', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        espace: {
            displayed: true,
            controls: {
                espace: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showBeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showPipes: { value: true, displayed: false },
                        showObjectsShape: { value: false, displayed: true },
                        showObjectsIcon: { value: true, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showVisitLock: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: true, displayed: true },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: false,
            groups: {
                conformite: {
                    displayed: true,
                    controls: {
                        showConformeObjects: { value: true, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: false, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: true,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: false, displayed: true },
                    },
                },
                zone: {
                    displayed: true,
                    controls: {
                        showZoneInferieurEgal50: { value: true, displayed: false },
                        showZoneEntre51Et500: { value: true, displayed: false },
                        showZoneEntre501Et1000: { value: true, displayed: false },
                        showZoneSuperieur1000: { value: true, displayed: false },
                        showZoneNonRealises: { value: true, displayed: true },
                    },
                },
                prelevement: {
                    displayed: false,
                    controls: {
                        showPrelevementInferieurEgal50: { value: true, displayed: false },
                        showPrelevementEntre51Et500: { value: true, displayed: false },
                        showPrelevementEntre501Et1000: { value: true, displayed: false },
                        showPrelevementSuperieur1000: { value: true, displayed: false },
                        showPrelevementNonRealises: { value: true, displayed: true },
                    },
                },
            },
        },
    },
    {
        id: 'idHapPrelNonReal',
        name: 'HAP - Prélèvements non réalisés',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idHapPrelNonReal', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        espace: {
            displayed: true,
            controls: {
                espace: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showBeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showPipes: { value: true, displayed: false },
                        showObjectsShape: { value: false, displayed: true },
                        showObjectsIcon: { value: true, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showVisitLock: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: true, displayed: true },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: false,
            groups: {
                conformite: {
                    displayed: true,
                    controls: {
                        showConformeObjects: { value: true, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: false, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: true,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: true, displayed: false },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: false },
                        showZoneEntre51Et500: { value: false, displayed: false },
                        showZoneEntre501Et1000: { value: false, displayed: false },
                        showZoneSuperieur1000: { value: false, displayed: false },
                        showZoneNonRealises: { value: false, displayed: false },
                    },
                },
                prelevement: {
                    displayed: false,
                    controls: {
                        showPrelevementInferieurEgal50: { value: false, displayed: false },
                        showPrelevementEntre51Et500: { value: false, displayed: false },
                        showPrelevementEntre501Et1000: { value: false, displayed: false },
                        showPrelevementSuperieur1000: { value: false, displayed: false },
                        showPrelevementNonRealises: { value: true, displayed: false },
                    },
                },
            },
        },
    },
    {
        id: 'idHapGenerique',
        name: 'HAP',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idHapGenerique', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        espace: {
            displayed: true,
            controls: {
                espace: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showBeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showPipes: { value: true, displayed: false },
                        showObjectsShape: { value: false, displayed: true },
                        showObjectsIcon: { value: true, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showVisitLock: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: true, displayed: true },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: false,
            groups: {
                conformite: {
                    displayed: true,
                    controls: {
                        showConformeObjects: { value: true, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: false, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: true,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: true, displayed: true },
                    },
                },
                zone: {
                    displayed: true,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: true },
                        showZoneEntre51Et500: { value: false, displayed: true },
                        showZoneEntre501Et1000: { value: false, displayed: true },
                        showZoneSuperieur1000: { value: false, displayed: true },
                        showZoneNonRealises: { value: false, displayed: true },
                    },
                },
                prelevement: {
                    displayed: true,
                    controls: {
                        showPrelevementInferieurEgal50: { value: false, displayed: true },
                        showPrelevementEntre51Et500: { value: false, displayed: true },
                        showPrelevementEntre501Et1000: { value: false, displayed: true },
                        showPrelevementSuperieur1000: { value: false, displayed: true },
                        showPrelevementNonRealises: { value: true, displayed: true },
                    },
                },
            },
        },
    },
    {
        id: 'idEtatVisite',
        name: 'Etat des visites',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idEtatVisite', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showBeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showPipes: { value: false, displayed: true },
                        showObjectsIcon: { value: true, displayed: true },
                        showObjectsShape: { value: true, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showVisitLock: { value: true, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: false, displayed: false },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: true,
            groups: {
                conformite: {
                    displayed: true,
                    controls: {
                        showConformeObjects: { value: true, displayed: true },
                        showNonConformeObjects: { value: false, displayed: true },
                        showAJustifierObjects: { value: false, displayed: true },
                        showNonVerifieObjects: { value: false, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: true, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: true, displayed: true },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: true },
                        showZoneEntre51Et500: { value: false, displayed: true },
                        showZoneEntre501Et1000: { value: false, displayed: true },
                        showZoneSuperieur1000: { value: false, displayed: true },
                        showZoneNonRealises: { value: false, displayed: true },
                    },
                },
                prelevement: {
                    displayed: true,
                    controls: {
                        showPrelevementInferieurEgal50: { value: true, displayed: true },
                        showPrelevementEntre51Et500: { value: true, displayed: true },
                        showPrelevementEntre501Et1000: { value: true, displayed: true },
                        showPrelevementSuperieur1000: { value: true, displayed: true },
                        showPrelevementNonRealises: { value: true, displayed: true },
                    },
                },
            },
        },
    },
    {
        id: 'idConfPolluant',
        name: 'Bien et équipements',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idConfPolluant', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: false, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showBeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showPipes: { value: true, displayed: false },
                        showObjectsShape: { value: false, displayed: true },
                        showObjectsIcon: { value: true, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: false, displayed: true },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: false,
            groups: {
                conformite: {
                    displayed: false,
                    controls: {
                        showConformeObjects: { value: false, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: true, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: true, displayed: true },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: true },
                        showZoneEntre51Et500: { value: false, displayed: true },
                        showZoneEntre501Et1000: { value: false, displayed: true },
                        showZoneSuperieur1000: { value: false, displayed: true },
                        showZoneNonRealises: { value: false, displayed: true },
                    },
                },
                prelevement: {
                    displayed: true,
                    controls: {
                        showPrelevementInferieurEgal50: { value: true, displayed: true },
                        showPrelevementEntre51Et500: { value: true, displayed: true },
                        showPrelevementEntre501Et1000: { value: true, displayed: true },
                        showPrelevementSuperieur1000: { value: true, displayed: true },
                        showPrelevementNonRealises: { value: true, displayed: true },
                    },
                },
            },
        },
        polluant: {
            displayed: true,
            controls: {
                zoneId: { value: null, displayed: true },
                perimetreId: { value: null, displayed: true },
                showBesoins: { value: false, displayed: true },
                showPerimetres: { value: false, displayed: true },
            },
        },
    },
    {
        id: 'idConfPolluantPerimetre',
        name: 'POLLUANT - Périmètres',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idConfPolluantPerimetre', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: false, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showBeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showPipes: { value: true, displayed: false },
                        showObjectsShape: { value: false, displayed: true },
                        showObjectsIcon: { value: true, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: true, displayed: true },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: false,
            groups: {
                conformite: {
                    displayed: false,
                    controls: {
                        showConformeObjects: { value: false, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: true, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: true, displayed: true },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: true },
                        showZoneEntre51Et500: { value: false, displayed: true },
                        showZoneEntre501Et1000: { value: false, displayed: true },
                        showZoneSuperieur1000: { value: false, displayed: true },
                        showZoneNonRealises: { value: false, displayed: true },
                    },
                },
                prelevement: {
                    displayed: true,
                    controls: {
                        showPrelevementInferieurEgal50: { value: true, displayed: true },
                        showPrelevementEntre51Et500: { value: true, displayed: true },
                        showPrelevementEntre501Et1000: { value: true, displayed: true },
                        showPrelevementSuperieur1000: { value: true, displayed: true },
                        showPrelevementNonRealises: { value: true, displayed: true },
                    },
                },
            },
        },
        polluant: {
            displayed: true,
            controls: {
                perimetreId: { value: null, displayed: true },
                showBesoins: { value: false, displayed: true },
                showPerimetres: { value: true, displayed: true },
                zoneId: { value: null, displayed: true },
            },
        },
    },
    {
        id: 'idConfPolluantZones',
        name: 'POLLUANT - Zones',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idConfPolluantZones', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: false, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showBeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showPipes: { value: true, displayed: false },
                        showObjectsShape: { value: false, displayed: true },
                        showObjectsIcon: { value: true, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: false, displayed: true },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: false,
            groups: {
                conformite: {
                    displayed: false,
                    controls: {
                        showConformeObjects: { value: false, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: true, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: true, displayed: true },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: true },
                        showZoneEntre51Et500: { value: false, displayed: true },
                        showZoneEntre501Et1000: { value: false, displayed: true },
                        showZoneSuperieur1000: { value: false, displayed: true },
                        showZoneNonRealises: { value: false, displayed: true },
                    },
                },
                prelevement: {
                    displayed: true,
                    controls: {
                        showPrelevementInferieurEgal50: { value: true, displayed: true },
                        showPrelevementEntre51Et500: { value: true, displayed: true },
                        showPrelevementEntre501Et1000: { value: true, displayed: true },
                        showPrelevementSuperieur1000: { value: true, displayed: true },
                        showPrelevementNonRealises: { value: true, displayed: true },
                    },
                },
            },
        },
        polluant: {
            displayed: true,
            controls: {
                perimetreId: { value: null, displayed: true },
                showBesoins: { value: false, displayed: true },
                showPerimetres: { value: false, displayed: true },
                zoneId: { value: null, displayed: true },
            },
        },
    },
    {
        id: 'idConfPolluantZonesBesoins',
        name: 'POLLUANT - Zones / Besoins',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idConfPolluantZonesBesoins', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: false, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showBeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showPipes: { value: true, displayed: false },
                        showObjectsShape: { value: false, displayed: true },
                        showObjectsIcon: { value: true, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: false, displayed: true },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: false,
            groups: {
                conformite: {
                    displayed: false,
                    controls: {
                        showConformeObjects: { value: false, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: true, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: true, displayed: true },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: true },
                        showZoneEntre51Et500: { value: false, displayed: true },
                        showZoneEntre501Et1000: { value: false, displayed: true },
                        showZoneSuperieur1000: { value: false, displayed: true },
                        showZoneNonRealises: { value: false, displayed: true },
                    },
                },
                prelevement: {
                    displayed: true,
                    controls: {
                        showPrelevementInferieurEgal50: { value: true, displayed: true },
                        showPrelevementEntre51Et500: { value: true, displayed: true },
                        showPrelevementEntre501Et1000: { value: true, displayed: true },
                        showPrelevementSuperieur1000: { value: true, displayed: true },
                        showPrelevementNonRealises: { value: true, displayed: true },
                    },
                },
            },
        },
        polluant: {
            displayed: true,
            controls: {
                perimetreId: { value: null, displayed: true },
                showBesoins: { value: true, displayed: true },
                showPerimetres: { value: false, displayed: true },
                zoneId: { value: null, displayed: true },
            },
        },
    },
];
