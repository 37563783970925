import { Component, Injector, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/commons-lib';
import { Diagnostic } from '../../../../../../../model/diagnostic.model';
import { ActivatedRoute } from '@angular/router';
import { PointDeControleBien, PointDeControleVolume } from '../../../../../../../model/point-de-controle.model';
import { InterventionService } from '../../../../../../../services/intervention.service';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormGroup, Validators } from '@angular/forms';
import { EtatValidation } from '../../../../../../../model/etat-progression.model';
import { Polluant } from '../../../../model/polluant.model';
import { CnSpinnerService } from '../../../../../../shared/cn-spinner/service/cn-spinner.service';
import { IValidatableComponent } from '../../../../services/interfaces/validatable-component.interface';
import { PolluantComponent } from '../../../../utils/polluant-component';
import { Zone } from '../../../../model/zone.model';
import { Surface } from '../../../../model/surface.model';
import { ConfirmDialogComponent } from 'src/app/lib/confirmation/confirm-dialog.component';
import { Besoin } from '../../../../model/besoin.model';
import { FormContext } from 'src/app/model/rule/form-context.model';
import { RelationInterventionBien } from 'src/app/model/intervention.model';
import { ConfirmationService } from 'src/app/lib/confirmation/confirmation.service';
import { HAP_SUPPRESSION_ELEMENT_ALERT_MESSAGE } from 'src/app/modules/diagnostics/hap/constantes/hap.constantes';
import { PRELEVEMENT_ESPACE, ZONE_ESPACE } from 'src/app/shared/constants/names.step.constants';
import { PerimetreInvestigation } from 'src/app/model/perimetre-investigation.model';
import { PerimetreInvestigationModalComponent } from 'src/app/modules/shared/map/perimetre-map/perimetre-investigation-modal/perimetre-investigation-modal.component';
import { TypePrestationEnum } from 'src/app/model/type-prestation.model';

@Component({
    selector: 'app-perimetre-descriptif',
    templateUrl: './descriptif.component.html',
    styleUrls: ['./descriptif.component.scss'],
})
export class DescriptifComponent extends PolluantComponent implements OnInit, IValidatableComponent {
    TypePrestationEnum = TypePrestationEnum;

    readonly roomNamingParameter = '{{room}}';
    listBienSidebar: PointDeControleBien[] = [];
    listVolumeId: string[] = [];
    previouslistVolumeId: string[] = [];
    formDescriptif: FormGroup;
    currentDiagnostic: Diagnostic;
    isLoading = true;

    listePerimetreInvestigation: PerimetreInvestigation[];
    selectedPerimetreInvestigation: PerimetreInvestigation;

    listeTemplateDescription: any[];
    selectedTemplateDescription: any;

    currentBien: RelationInterventionBien;

    constructor(
        public interventionService: InterventionService,
        private readonly confirmationService: ConfirmationService,
        private cnSpinnerService: CnSpinnerService,
        private route: ActivatedRoute,
        private notificationService: NotificationService,
        private injector: Injector
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.cnSpinnerService.show();
        combineLatest([this.interventionService.getCurrentIntervention(), this.diagnosticService.getCurrentDiagnostic(), this.route.data])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([intervention, diagnostic, routerData]) => {
                this.currentDiagnostic = diagnostic;
                this.intervention = intervention;

                this.polluantService
                    .findOnePolluantConfigIndexedDB(diagnostic.idConfig)
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe((polluantConfig) => {
                        if (polluantConfig == null) {
                            this.notificationService.error('Erreur : Merci de faire une synchro pour récupérer les configs en local.');
                            this.cnSpinnerService.hide();
                            return;
                        }

                        this.contenuDiagnostic = diagnostic.contenuDiagnostic as Polluant;

                        // Récupération de la liste des templates de description dans la config
                        this.listeTemplateDescription = polluantConfig.contenuListeDeroulante.perimetreInvestigation.items;

                        // Initialise la liste des biens
                        this.listBienSidebar = diagnostic.pointsDeControleBiens;

                        if (this.listBienSidebar.length == 0) {
                            this.cnSpinnerService.hide();
                            this.notificationService.error(`Merci de saisir un descriptif du bien afin de pouvoir continuer.`);
                            return;
                        }

                        // Récupération des Périmètres d'investigation
                        this.listePerimetreInvestigation =
                            (diagnostic.contenuDiagnostic as Polluant).perimetresInvestigation.data.listePerimetreInvestigation ?? [];

                        // Si il n'y aucun périmètre alors on l'initialise
                        if (!this.listePerimetreInvestigation.length) {
                            this.listePerimetreInvestigation = [];
                            this.listePerimetreInvestigation.push(
                                new PerimetreInvestigation({
                                    nom: "Périmètre d'investigation 1",
                                    idBien: this.listBienSidebar[0].idBien,
                                    indexNiveau: 0,
                                    backgroundFileId: '',
                                })
                            );
                        }
                        // On sélectionne le premier périmètre par défaut
                        this.selectedPerimetreInvestigation = this.listePerimetreInvestigation[0];

                        this.getDataFromPerimetre();
                        this.initForm();

                        this.isLoading = false;
                        this.cnSpinnerService.hide();
                        this.isValidated = this.contenuDiagnostic.perimetresInvestigation.validated;
                        this.isInEditMode =
                            !(!!routerData.readonly || this.diagnosticService.isReadOnlyMode(intervention, this.diagnostic)) && this.isInEditMode;
                        if (!this.isInEditMode) {
                            this.formDescriptif.disable();
                        }
                        this.cnSpinnerService.hide();
                    });
            });
    }

    /**
     * Récupère la liste des volumes et la description du périmètre d'investigation sélectionné
     */
    getDataFromPerimetre() {
        // Selection du bien associé au périmètre
        this.currentBien = this.intervention.relationInterventionBiens.find((rel) => rel.bien.id === this.selectedPerimetreInvestigation.idBien);

        // Récupération de la liste des volumes sélectionnés dans ce périmiètre
        this.listVolumeId = this.selectedPerimetreInvestigation.listeVolumeId;

        // Récupération du template utilisé dans ce périmètre ou alors sélection du premier par défaut
        this.selectedTemplateDescription =
            this.listeTemplateDescription.find(({ id }) => {
                this.selectedPerimetreInvestigation.idTemplateDescription == id;
            }) ?? this.listeTemplateDescription[0];
    }

    /**
     * Initialise le formulaire avec les données du périmètre d'investigation sélectionné
     */
    initForm() {
        this.formDescriptif = this.formBuilder.group({
            listVolumeId: this.formService.createFormControl(
                'espaces',
                new FormContext('listeVolumeId', this.selectedPerimetreInvestigation, Validators.required, this.ngUnsubscribe)
            ),
            description: this.formService.createFormControl(
                'espaces',
                new FormContext('description', this.selectedPerimetreInvestigation, Validators.required, this.ngUnsubscribe)
            ),
        });
    }

    protected createDescription() {
        const listeVolumes = this.findListVolumeFromId(this.listVolumeId);
        const description = this.selectedTemplateDescription.description;

        // Si on a des pièces selectionnées
        if (this.listVolumeId.length) {
            let parameters = '';
            if (listeVolumes) {
                parameters = listeVolumes
                    .map((elem) => {
                        return elem.nom;
                    })
                    .join(', ');
            } else {
                parameters = this.roomNamingParameter;
            }
            this.patchDescription(description.replace(this.roomNamingParameter, parameters));
        } else {
            // Si aucune pièce selectionnée, on reset la description
            this.resetDescription();
        }
    }

    /**
     * @description reset the description and add the 3 dots ...
     * @private
     */
    private resetDescription() {
        const resetValue = this.selectedTemplateDescription.description.replace(this.roomNamingParameter, '...');
        this.patchDescription(resetValue);
    }

    /**
     * @description patch la nouvelle valeur de la description dans le formulaire
     * @param value
     * @private
     */
    private patchDescription(value: string) {
        this.formDescriptif.patchValue({ description: value });
    }

    /**
     * @description retourne une liste de point de controle de volume à partir d'une liste d'ID
     * @param listVolumeId
     * @private
     */
    private findListVolumeFromId(listVolumeId: string[]): PointDeControleVolume[] {
        const listVolumeSelected: PointDeControleVolume[] = [];
        listVolumeId.forEach((id) => {
            this.listBienSidebar.forEach((bien) => {
                bien.pointsDeControleNiveaux.forEach((niveau) => {
                    niveau.pointsDeControleVolumes.forEach((volume) => {
                        if (volume.idVolume === id) {
                            listVolumeSelected.push(volume);
                        }
                    });
                });
            });
        });
        return listVolumeSelected;
    }

    /**
     * @description Met à jour la description quand un nouveau volume est sélectionné
     * Si un volume est retiré, on vérifie si ce volume est utilisé dans une zone et demande une confirmation
     * @param room est la pièce sélectionnée
     */
    listBienUpdated(listVolumeId: string[]) {
        //Si il y'a une modification sur la liste des volumes
        if (this.previouslistVolumeId.length != listVolumeId.length) {
            //Si un volume a été enlevé
            if (listVolumeId.length < this.previouslistVolumeId.length) {
                //Identification de l'id du volume décoché
                const removedIdVolume = this.previouslistVolumeId.filter((x) => !listVolumeId.includes(x))[0];

                //On détermine si le volume décoché est lié à une zone
                const zonesAssociees = this.contenuDiagnostic.zones?.data?.zonesList?.filter((zone: Zone) => {
                    let r = false;
                    zone.listeSurfaces.forEach((surface: Surface) => {
                        if (surface.listeIdVolume.indexOf(removedIdVolume) != -1) {
                            r = true;
                        }
                    });
                    return r;
                });

                if (zonesAssociees && zonesAssociees.length > 0) {
                    this.matDialog
                        .open(ConfirmDialogComponent, {
                            data: {
                                message:
                                    'Attention, ce volume est associé à une zone.\nRetirer ce volume du périmètre entrainera son retrait de la zone, ainsi que la suppression des besoins de prélèvement associés.',
                            },
                        })
                        .afterClosed()
                        .subscribe((result) => {
                            if (result && result !== false) {
                                this.listVolumeId = [...listVolumeId];

                                //Suppression du volume dans les surfaces des zones
                                zonesAssociees.forEach((zone: Zone) => {
                                    zone.listeSurfaces.forEach((surface: Surface) => {
                                        let index = surface.listeIdVolume.indexOf(removedIdVolume);
                                        if (index != -1) {
                                            surface.listeIdVolume.splice(index, 1);
                                        }
                                    });
                                });

                                if (this.contenuDiagnostic.besoins.data.besoinsList) {
                                    //Suppression des besoins de prélèvements associés
                                    this.contenuDiagnostic.besoins.data.besoinsList = this.contenuDiagnostic.besoins.data.besoinsList.filter(
                                        (besoin: Besoin) => {
                                            return besoin.pieceId != removedIdVolume;
                                        }
                                    );
                                }

                                // sauvegarde la liste des biens
                                this.formDescriptif.patchValue({ listVolumeId: this.listVolumeId });
                                this.checkValidity();

                                // On va créer la description à partir des chambres selectionnées
                                this.createDescription();
                            }
                        });

                    this.listVolumeId = [...this.previouslistVolumeId];
                } else {
                    //Si le volume sélectionné n'est pas utilisé dans l'écran Zone
                    this.listVolumeId = [...listVolumeId];
                    this.previouslistVolumeId = [...listVolumeId];
                }
            } else {
                this.listVolumeId = [...listVolumeId];
                this.previouslistVolumeId = [...listVolumeId];
            }
        }

        // sauvegarde la liste des biens
        this.formDescriptif.patchValue({ listVolumeId: this.listVolumeId });
        this.checkValidity();

        // On va créer la description à partir des chambres selectionnées
        this.createDescription();
    }

    // ===========================================
    // Fonctions navigation périmètre localisation
    // ===========================================

    /**
     * Sélectionne le périmètre d'investigation et le bien du périmètre passé en paramètre
     * @param pi
     */
    selectPerimetreInvestigation(pi: PerimetreInvestigation) {
        this.selectedPerimetreInvestigation = pi;

        this.getDataFromPerimetre();
        this.initForm();
    }

    /**
     * Passer au périmètre précédent (flèche de gauche)
     */
    onClickPreviousPerimetre() {
        this.selectPerimetreInvestigation(
            this.listePerimetreInvestigation[
                this.listePerimetreInvestigation.findIndex((espace) => this.selectedPerimetreInvestigation.id === espace.id) - 1
            ]
        );
    }

    /**
     * Passer au périmètre suivant(flèche de droite)
     */
    onClickNextPerimetre() {
        this.selectPerimetreInvestigation(
            this.listePerimetreInvestigation[
                this.listePerimetreInvestigation.findIndex((espace) => this.selectedPerimetreInvestigation.id === espace.id) + 1
            ]
        );
    }

    /**
     * Ouvre la modale de création de Périmètre d'investigation
     */
    onClickCreatePerimetre() {
        this.matDialog
            .open(PerimetreInvestigationModalComponent, {
                data: {
                    perimetreInvestigation: new PerimetreInvestigation(),
                    listePerimetresExistant: this.listePerimetreInvestigation,
                    isCreation: true,
                    diagnostic: this.diagnostic,
                    intervention: this.intervention,
                },
            })
            .afterClosed()
            .subscribe((perimetreResultat) => {
                if (perimetreResultat && perimetreResultat.perimetre) {
                    this.savePerimetreInvestigation(perimetreResultat.perimetre);
                }
                this.checkValidity();
            });
    }

    onClickEditPerimetre() {
        this.matDialog
            .open(PerimetreInvestigationModalComponent, {
                data: {
                    perimetreInvestigation: this.selectedPerimetreInvestigation,
                    listePerimetresExistant: this.listePerimetreInvestigation,
                    isCreation: true,
                    diagnostic: this.diagnostic,
                    intervention: this.intervention,
                },
            })
            .afterClosed()
            .subscribe((perimetreResultat) => {
                if (perimetreResultat && perimetreResultat.perimetre) {
                    this.savePerimetreInvestigation(perimetreResultat.perimetre);
                }
                this.checkValidity();
            });
    }

    savePerimetreInvestigation(pi: PerimetreInvestigation) {
        // this.listePerimetreInvestigation = [...this.listePerimetreInvestigation, espace];

        // Ajoute le nouvel espace à la liste des Périmètres d'investigation
        this.listePerimetreInvestigation = [...this.listePerimetreInvestigation, pi];

        this.selectPerimetreInvestigation(pi);

        // this.contenuDiagnostic.espaces.valeur.push(espace);
        // this.diagnosticService.upsert(this.intervention, this.diagnostic).subscribe(() => {
        //     this.checkValidity();

        //     // Re-Validation des onglets ZONE et PRELEVEMENT
        //     this.hapCheckValidityService.checkValidityZoneFromOutside(
        //         this.selectedPerimetreInvestigation.listePerimetres,
        //         this.selectedPerimetreInvestigation.id,
        //         this.ngUnsubscribe,
        //         this.diagnostic
        //     );
        // });
    }

    /**
     * On supprime le périmètre sélectionné et on sélectionne le 1er de la liste
     */
    onClickDeletePerimetre() {
        this.confirmationService.confirm(HAP_SUPPRESSION_ELEMENT_ALERT_MESSAGE['perimetreLocalisation'](this.selectedPerimetreInvestigation), () => {
            this.listePerimetreInvestigation = this.listePerimetreInvestigation.filter((espace) => {
                return espace.id !== this.selectedPerimetreInvestigation.id;
            });

            // Sauvegarde du diagnostic sans l'espace sélectionné
            this.contenuDiagnostic.perimetresInvestigation.data.listePerimetreInvestigation = Object.assign([], this.listePerimetreInvestigation);
            this.diagnosticService.upsert(this.intervention, this.diagnostic).subscribe(() => {
                this.checkValidity();

                // Suppression de l'état progression correspondant à l'espace dans ZONE et PRELEVEMENT
                const codeZone = `${ZONE_ESPACE}_${this.selectedPerimetreInvestigation.id}`;
                this.etatProgressionService.deleteCodeProgression(codeZone, this.diagnostic.etatProgressions);
                const codePrelevement = `${PRELEVEMENT_ESPACE}_${this.selectedPerimetreInvestigation.id}`;
                this.etatProgressionService.deleteCodeProgression(codePrelevement, this.diagnostic.etatProgressions);

                // Sélection du 1er espace de la liste et chargement de son plan
                this.selectPerimetreInvestigation(this.listePerimetreInvestigation.length > 0 ? this.listePerimetreInvestigation[0] : null);
            });
        });
    }

    /**
     * @description verifie si le formulaire est valide ou non et update
     * @private
     */
    private checkValidity() {
        this.contenuDiagnostic.perimetresInvestigation.data.listePerimetreInvestigation = this.listePerimetreInvestigation;

        const code = this.route.snapshot.data['code'];

        let isFormEnabled = this.formDescriptif.enabled;
        //Si le formulaire est désactivé, on l'active afin de pouvoir savoir si il est valide
        if (!isFormEnabled) {
            this.formDescriptif.enable({
                emitEvent: false,
            });
        }

        let etat: EtatValidation = 'INVALID';

        if (this.formDescriptif && this.formDescriptif.valid) {
            etat = 'VALID';
        }
        //Si le formulaire était désactivé avant, on le désactive à nouveau
        if (!isFormEnabled) {
            this.formDescriptif.disable({
                emitEvent: false,
            });
        }

        this.etatProgressionService.updateDiagnostic(code, etat, this.currentDiagnostic);
    }

    //Interface IValidatableComponent implémentation
    cancelModification() {
        this.isInEditMode = false;
        this.formDescriptif.patchValue(this.previousFormValue);
        this.previousFormValue = undefined;
    }

    saveModification() {
        this.isInEditMode = false;

        this.checkValidity();
    }

    validateTab() {
        this.isValidated = true;
        this.contenuDiagnostic.perimetreDescriptif.validated = true;
        this.checkValidity();
    }

    startModification() {
        this.isInEditMode = true;
        this.previousFormValue = this.formDescriptif.getRawValue();
    }
}
