<div class="pb-4">
    <div class="block-header p-5 w-100 d-flex">
        <div class="block-icon flex-grow-1">
            <img src="../../../../../../assets/img/logo-report.png" alt="report logo" />
        </div>
        <div class="block-label">Dossier N° : {{ numDossier }}</div>
    </div>
    <div class="pl-5 py-4">
        <div class="block-title-content pl-3 py-2">
            <div class="block-label" *ngIf="data">{{ data.title }}</div>
            <div class="block-label-2 mt-2" *ngIf="subTitle">{{ subTitle }}</div>
        </div>
    </div>
    <ng-container *ngIf="conforme !== undefined">
        <div class="block-category p-2 pl-5 d-inline-block">SYNTHÈSE</div>
        <div class="block-synthese px-5 py-4 d-flex align-items-center">
            <div class="block-icon mr-3" [ngClass]="conforme ? 'conforme' : 'non-conforme'">
                <i *ngIf="conforme === false" class="fas fa-ban"></i>
                <i *ngIf="conforme" class="fas fa-check"></i>
            </div>
            <div class="block-label mr-5 text-justify" *ngIf="messages || conformeValeur">
                <span *ngIf="messages[0]" class="{{ messages[0].class }}">{{ messages[0].text }} </span>
                <span *ngIf="conformeValeur" class="{{ conformeValeur.class }}">{{ conformeValeur.text }} </span>
                <span *ngIf="messages[1]" class="{{ messages[1].class }}">{{ messages[1].text }} </span>
            </div>
        </div>
    </ng-container>
</div>
