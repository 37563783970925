import { RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { Icon } from 'src/app/commons-lib';
import {
    AUTRE_EQUIPEMENT,
    COMMENT,
    COMMENT_DIVERS,
    COMMENT_RECO,
    CONCLUSION,
    CONSTATATION,
    CONTROL_POINT,
    DESC,
    DESC_BIEN,
    DESC_BIEN_ANNEXE,
    DOCS_DIAG,
    DOCUMENTS_RAPPORT,
    GESTION_PLAN,
    INIT,
    INSTALL,
    INSTALLATION_ELECTRIQUE_ENVIRONNEMENT,
    INSTALLATIONS_NON_COUVERTES,
    INTERVENTION,
    LOGEMENT,
    POINTS_DE_CONTROLES_NON_VERIFIABLE,
    PREVI_RAPPORT,
    RAPPORT,
    RECAP,
    REPORTAGE_PHOTO,
    RESTE_A_FAIRE,
    SAVE_RAPPORT,
    TABLEAU,
    TERRE,
} from '../../../shared/constants/names.step.constants';
import { InterventionComponent } from './modules/initialisation/intervention/intervention.component';
import { InstallationComponent } from './modules/initialisation/installation/installation.component';
import { ResumeComponent } from './modules/initialisation/resume/resume.component';
import { TerreComponent } from './modules/points-de-controles/terre/terre.component';
import { TableauComponent } from './modules/points-de-controles/tableau/tableau.component';
import { LogementComponent } from './modules/points-de-controles/logement/logement.component';
import { AutreEquipementComponent } from './modules/points-de-controles/autre-equipement/autre-equipement.component';
import { CoupureComponent } from './modules/points-de-controles/tableau/coupure/coupure.component';
import { SurintensiteComponent } from './modules/points-de-controles/tableau/surintensite/surintensite.component';
import { DifferentielComponent } from './modules/points-de-controles/tableau/differentiel/differentiel.component';
import { InstallationElectriqueEnvironnementComponent } from './modules/constatation/installation-electrique-environnement/installation-electrique-environnement.component';
import { InstalationsNonCouvertesComponent } from './modules/constatation/instalations-non-couvertes/instalations-non-couvertes.component';
import { ResteAFaireComponent } from './modules/recapitulatif/reste-a-faire/reste-a-faire.component';
import { QuitDiagnosticGuard } from '../../../guards/quit-diagnostic.guard';
import { ListeAnomaliesComponent } from './modules/conclusion/liste-anomalies/liste-anomalies.component';
import { RapportComponent } from '../../diagnostics-shared/rapport/rapport.component';
import { TerreInstallationComponent } from './modules/points-de-controles/terre/terre-installation/terre-installation.component';
import { TerreCalculComponent } from './modules/points-de-controles/terre/terre-calcul/terre-calcul.component';
import { PriseTerreComponent } from './modules/points-de-controles/terre/prise-terre/prise-terre.component';
import { LogementContactsComponent } from './modules/points-de-controles/logement/logement-contacts/logement-contacts.component';
import { LogementSalleDEauComponent } from './modules/points-de-controles/logement/logement-salle-deau/logement-salle-deau.component';
import { LogementPrisesComponent } from './modules/points-de-controles/logement/logement-prises/logement-prises.component';
import { LogementHuisseriesMetalliquesComponent } from './modules/points-de-controles/logement/logement-huisseries-metalliques/logement-huisseries-metalliques.component';
import { LogementConduitsMetalliquesComponent } from './modules/points-de-controles/logement/logement-conduits-metalliques/logement-conduits-metalliques.component';
import { LogementInadapteComponent } from './modules/points-de-controles/logement/logement-inadapte/logement-inadapte.component';
import { ExterieurAscenseurComponent } from './modules/points-de-controles/autre-equipement/exterieur-ascenseur/exterieur-ascenseur.component';
import { FontainesComponent } from './modules/points-de-controles/autre-equipement/fontaines/fontaines.component';
import { PiscinePriveeComponent } from './modules/points-de-controles/autre-equipement/piscine-privee/piscine-privee.component';
import { PartiesCommunesEtPrivativesComponent } from './modules/points-de-controles/autre-equipement/parties-communes-et-privatives/parties-communes-et-privatives.component';
import { PtControlesNonVerifiableComponent } from './modules/recapitulatif/pt-controles-non-verifiable/pt-controles-non-verifiable.component';
import { FlukeComponent } from './modules/points-de-controles/fluke/fluke.component';
import { PointsDeControleComponent } from './modules/points-de-controles/points-de-controle.component';
import { DiagnosticConfigComponent } from '../../diagnostics-shared/diagnostic-config/diagnostic-config.component';
import { InitConfigResolver } from './services/initConfig.resolver';
import {
    interventionRouteDescriptionIterateFunction,
    interventionRouteDescriptionIterateLabel,
    interventionRouteDescriptionIteratePath,
    interventionRouteDescriptionIterateSuffix,
} from '../../interventions/description/description-bien.module';
import { EditorGuard } from '../../../guards/editor.guard';
import { QuitDescriptionBienGuard } from '../../../guards/quit-description-bien.guard';
import { DocumentsComponent } from './modules/documents/documents.component';
import { AutresConstatationsComponent } from './modules/recapitulatif/autres-constatations/autres-constatations.component';
import { ConstatationsDiversesComponent } from '../../diagnostics-shared/comments/constatations-diverses/constatations-diverses.component';
import { RecommandationsComponent } from '../../diagnostics-shared/comments/recommandations/recommandations.component';
import { ReportagePhotoListComponent } from '../../diagnostics-shared/reportage-photo/reportage-photo-list/reportage-photo-list.component';
import { DocumentsRapportComponent } from '../../diagnostics-shared/documents-rapport/documents-rapport.component';
import { TypePrestationEnum } from 'src/app/model/type-prestation.model';
import { QuitDocumentGuard } from 'src/app/guards/quit-document.guard';
import { DescriptionBienPageComponent } from '../../interventions/description/description-bien-page/description-bien-page.component';

const DEFAULT_PAGE = 'validation-docs';

/**
 * Routes du module à utiliser pour les barres de navigation.
 * L'attribut "data" doit implémenter l'interface {@link NavigationLinkRouteData}
 */
export const electriciteNavBarRoutes: Routes = [
    {
        path: 'description',
        data: {
            label: 'Description',
            icon: new Icon('floor-plan', 'mdi'),
            code: DESC,
        },
        children: [
            {
                path: '',
                redirectTo: 'bien-principal',
                pathMatch: 'full',
            },
            {
                path: 'bien-principal',
                data: {
                    label: 'Bien principal',
                    code: DESC_BIEN,
                },
                canDeactivate: [QuitDescriptionBienGuard],
                component: DescriptionBienPageComponent,
            },
            {
                path: ':idBien',
                data: {
                    iterate: {
                        function: interventionRouteDescriptionIterateFunction,
                        path: interventionRouteDescriptionIteratePath,
                        label: interventionRouteDescriptionIterateLabel,
                        suffix: interventionRouteDescriptionIterateSuffix,
                    },
                    code: DESC_BIEN_ANNEXE,
                },
                canDeactivate: [QuitDescriptionBienGuard],
                component: DescriptionBienPageComponent,
            },
            {
                path: 'bien-principal/editor',
                canActivate: [EditorGuard],
                loadChildren: () => import('../../shared/map/editor-map/editor-map.module').then((m) => m.EditorMapModule),
            },
            {
                path: ':idBien/editor',
                canActivate: [EditorGuard],
                loadChildren: () => import('../../shared/map/editor-map/editor-map.module').then((m) => m.EditorMapModule),
            },
        ],
    },
    {
        path: 'validation-docs',
        data: {
            label: 'Validation Docs',
            icon: new Icon('list-status', 'mdi'),
            code: DOCS_DIAG,
        },
        canDeactivate: [QuitDocumentGuard],
        component: DocumentsComponent,
    },
    {
        path: 'initialisation',
        data: {
            label: 'Initialisation',
            icon: new Icon('settings'),
            code: INIT,
        },
        children: [
            {
                path: '',
                redirectTo: 'intervention',
                pathMatch: 'full',
            },
            {
                path: 'intervention',
                data: {
                    label: 'INTERVENTION',
                    code: INTERVENTION,
                },
                component: InterventionComponent,
            },
            {
                path: 'installation',
                data: {
                    label: 'INSTALLATION',
                    code: INSTALL,
                },
                component: InstallationComponent,
            },
            {
                path: 'resume',
                data: {
                    label: 'Installation(s) particulière(s)',
                    code: 'RÉSUMÉ',
                    status: 'VOID',
                },
                component: ResumeComponent,
            },
        ],
    },
    {
        path: 'points-de-controles',
        component: PointsDeControleComponent,
        data: {
            label: 'Points de contrôles',
            icon: new Icon('list-status', 'mdi'),
            code: CONTROL_POINT,
        },
        children: [
            {
                path: '',
                redirectTo: 'mesurage',
                pathMatch: 'full',
            },
            {
                path: 'mesurage',
                data: {
                    label: 'ESSAIS/MESURAGE',
                    code: TypePrestationEnum.MESURAGE,
                    status: 'INVALID',
                },
                component: FlukeComponent,
            },
            {
                path: 'tableau',
                data: {
                    label: 'TABLEAU',
                    code: TABLEAU,
                    status: 'INVALID',
                },
                component: TableauComponent,
                children: [
                    {
                        path: '',
                        redirectTo: 'coupure',
                        pathMatch: 'full',
                    },
                    {
                        path: 'coupure',
                        data: {
                            label: 'Coupure',
                        },
                        component: CoupureComponent,
                    },
                    {
                        path: 'differentiel',
                        data: {
                            label: 'Différentiel',
                        },
                        component: DifferentielComponent,
                    },
                    {
                        path: 'surintensite',
                        data: {
                            label: 'Surintensités',
                        },
                        component: SurintensiteComponent,
                    },
                ],
            },
            {
                path: 'terre',
                data: {
                    label: 'TERRE',
                    code: TERRE,
                    status: 'INVALID',
                },
                component: TerreComponent,
                children: [
                    {
                        path: '',
                        redirectTo: 'prise-de-terre',
                        pathMatch: 'full',
                    },
                    {
                        path: 'prise-de-terre',
                        data: {
                            label: 'Prise de terre',
                        },
                        component: PriseTerreComponent,
                    },
                    {
                        path: 'installation',
                        data: {
                            label: 'Installation',
                        },
                        component: TerreInstallationComponent,
                    },
                    {
                        path: 'calcul',
                        data: {
                            label: 'Calcul',
                        },
                        component: TerreCalculComponent,
                    },
                ],
            },
            {
                path: 'logement',
                data: {
                    label: 'LOGEMENT',
                    code: LOGEMENT,
                    status: 'INVALID',
                },
                component: LogementComponent,
                children: [
                    {
                        path: '',
                        redirectTo: 'contacts',
                        pathMatch: 'full',
                    },
                    {
                        path: 'contacts',
                        data: {
                            label: 'Contacts',
                        },
                        component: LogementContactsComponent,
                    },
                    {
                        path: 'inadapte',
                        data: {
                            label: 'Inadapté',
                        },
                        component: LogementInadapteComponent,
                    },
                    {
                        path: 'salle-d-eau',
                        data: {
                            label: "Salle d'eau",
                        },
                        component: LogementSalleDEauComponent,
                    },
                    {
                        path: 'prises',
                        data: {
                            label: 'Prises',
                        },
                        component: LogementPrisesComponent,
                    },
                    {
                        path: 'huisseries-metalliques',
                        data: {
                            label: 'Huisseries métalliques',
                        },
                        component: LogementHuisseriesMetalliquesComponent,
                    },
                    {
                        path: 'conduits-metalliques',
                        data: {
                            label: 'Conduits métalliques',
                        },
                        component: LogementConduitsMetalliquesComponent,
                    },
                ],
            },
            {
                path: 'autre-equipement',
                data: {
                    label: 'AUTRE ÉQUIPEMENT',
                    code: AUTRE_EQUIPEMENT,
                    status: 'INVALID',
                },
                component: AutreEquipementComponent,
                children: [
                    {
                        path: '',
                        redirectTo: 'parties-communes-et-privatives',
                        pathMatch: 'full',
                    },
                    {
                        path: 'parties-communes-et-privatives',
                        data: {
                            label: 'Parties communes et privatives',
                        },
                        component: PartiesCommunesEtPrivativesComponent,
                    },
                    {
                        path: 'piscine-privee',
                        data: {
                            label: 'Piscine privée',
                        },
                        component: PiscinePriveeComponent,
                    },
                    {
                        path: 'fontaines',
                        data: {
                            label: 'Fontaines',
                        },
                        component: FontainesComponent,
                    },
                    {
                        path: 'exterieur-ascenseur',
                        data: {
                            label: 'Extérieur/Ascenseur',
                        },
                        component: ExterieurAscenseurComponent,
                    },
                ],
            },
        ],
    },
    {
        path: 'constatation',
        component: PointsDeControleComponent,
        data: {
            label: 'CONSTATATION',
            icon: new Icon('border_color'),
            code: CONSTATATION,
        },
        children: [
            {
                path: '',
                redirectTo: 'installations-non-couvertes',
                pathMatch: 'full',
            },
            {
                path: 'installations-non-couvertes',
                data: {
                    label: 'INSTALLATIONS NON COUVERTES',
                    code: INSTALLATIONS_NON_COUVERTES,
                    status: 'VOID',
                },
                component: InstalationsNonCouvertesComponent,
            },
            {
                path: 'installation-electrique-et-ou-son-environnement',
                data: {
                    label: 'INSTALLATION ÉLECTRIQUE ET/OU SON ENVIRONNEMENT',
                    code: INSTALLATION_ELECTRIQUE_ENVIRONNEMENT,
                    status: 'VOID',
                },
                component: InstallationElectriqueEnvironnementComponent,
            },
        ],
    },
    {
        path: 'commentaires',
        data: {
            label: 'Commentaires',
            icon: new Icon('playlist-edit', 'mdi'),
            code: COMMENT,
        },
        children: [
            {
                path: '',
                canDeactivate: [QuitDiagnosticGuard],
                redirectTo: 'recommandations',
                pathMatch: 'full',
            },
            {
                path: 'recommandations',
                data: {
                    label: 'Recommandations',
                    code: COMMENT_RECO,
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: RecommandationsComponent,
            },
            {
                path: 'constatations-diverses',
                data: {
                    label: 'Constatations diverses',
                    code: COMMENT_DIVERS,
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: ConstatationsDiversesComponent,
            },
        ],
    },
    {
        path: 'recapitulatif',
        component: PointsDeControleComponent,
        data: {
            label: 'Récapitulatif',
            icon: new Icon('eye', 'fa'),
            code: RECAP,
            status: 'VOID',
        },
        children: [
            {
                path: '',
                redirectTo: 'reste-a-faire',
                pathMatch: 'full',
            },
            {
                path: 'reste-a-faire',
                data: {
                    label: 'RESTE À FAIRE',
                    code: RESTE_A_FAIRE,
                    status: 'VOID',
                },
                component: ResteAFaireComponent,
            },
            {
                path: 'points-de-controles-non-verifiable',
                data: {
                    label: 'POINTS DE CONTRÔLES NON VÉRIFIABLE',
                    code: POINTS_DE_CONTROLES_NON_VERIFIABLE,
                    status: 'VOID',
                },
                component: PtControlesNonVerifiableComponent,
            },
        ],
    },
    {
        path: 'conclusion',
        data: {
            label: 'Conclusion',
            icon: new Icon('clipboard-list', 'fa'),
            code: CONCLUSION,
            status: 'VOID',
        },
        component: PointsDeControleComponent,
        children: [
            {
                path: '',
                redirectTo: 'anomalies',
                pathMatch: 'full',
            },
            {
                path: 'anomalies',
                data: {
                    label: 'ANOMALIES',
                    code: 'ANOMALIES',
                    status: 'VOID',
                },
                component: ListeAnomaliesComponent,
            },
            {
                path: 'autres-constatations',
                data: {
                    label: 'AUTRES CONSTATATIONS',
                    code: 'AUTRES_CONSTATATIONS',
                    status: 'VOID',
                },
                component: AutresConstatationsComponent,
                children: [
                    {
                        path: '',
                        canDeactivate: [QuitDiagnosticGuard],
                        redirectTo: 'installations-particulieres',
                        pathMatch: 'full',
                    },
                    {
                        path: 'installations-particulieres',
                        data: {
                            label: 'Installations particulieres',
                        },
                        canDeactivate: [QuitDiagnosticGuard],
                    },
                    {
                        path: 'informations-complementaires',
                        data: {
                            label: 'Informations complementaires',
                        },
                        canDeactivate: [QuitDiagnosticGuard],
                    },
                ],
            },
        ],
    },
    {
        path: 'rapport',
        data: {
            label: 'Rapport',
            icon: new Icon('book'),
            code: RAPPORT,
            status: 'VOID',
        },
        children: [
            {
                path: '',
                canDeactivate: [QuitDiagnosticGuard],
                redirectTo: 'gestion-plan',
                pathMatch: 'full',
            },
            {
                path: 'gestion-plan',
                data: {
                    label: 'Gestion plan',
                    code: GESTION_PLAN,
                    status: 'VOID',
                },
                canDeactivate: [QuitDiagnosticGuard],
            },
            {
                path: 'reportage-photo',
                data: {
                    label: 'Reportage photo',
                    code: REPORTAGE_PHOTO,
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: ReportagePhotoListComponent,
            },
            {
                path: 'documents-rapport',
                data: {
                    label: 'Documents',
                    code: DOCUMENTS_RAPPORT,
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: DocumentsRapportComponent,
            },
            {
                path: 'apercu-rapport',
                data: {
                    label: 'Aperçu rapport',
                    code: PREVI_RAPPORT,
                    actions: [
                        {
                            label: 'Valider',
                            icon: new Icon('signature-freehand', 'mdi'),
                            code: SAVE_RAPPORT,
                            textColor: '#FFFFFF',
                            backgroundColor: '#6DC911',
                        },
                    ],
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: RapportComponent,
            },
        ],
    },
];

const routes: Routes = [
    {
        path: '',
        redirectTo: 'config',
        pathMatch: 'full',
    },
    {
        path: 'config',
        component: DiagnosticConfigComponent,
        children: [
            {
                path: '',
                redirectTo: DEFAULT_PAGE,
                pathMatch: 'full',
            },
            ...electriciteNavBarRoutes,
        ],
        resolve: { elec: InitConfigResolver },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ElectriciteRoutingModule {}
