import { Pipe, PipeTransform } from '@angular/core';
import { EtatRapport } from '../model/rapport.model';

export const etatRapportMapping: { [key in EtatRapport]?: string } = {
    EN_FILE_D_ATTENTE: "En file d'attente",
    GENERATION_EN_COURS: 'Génération en cours',
    EN_PAUSE: 'En pause',
    DIFFERE: 'Différé',
    EN_ERREUR: 'En erreur',
    INCONNU: 'Inconnu',
    TERMINE: 'Terminé',
};

@Pipe({
    name: 'etatRapport',
})
export class EtatRapportPipe implements PipeTransform {
    transform(value: EtatRapport): unknown {
        return etatRapportMapping[value];
    }
}
