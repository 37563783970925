import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { input } from '../increment-input-reactive/increment-input-reactive.stories';
import { FormContext } from 'src/app/model/rule/form-context.model';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { customHeaderComponent } from './custom-header/custom-header';

export const MY_FORMATS = {
    parse: {
        dateInput: 'YYYY',
    },
    display: {
        dateInput: 'YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
    },
};

@Component({
    selector: 'app-years-picker',
    templateUrl: './years-picker.html',
    styleUrls: ['./years-picker.scss'],
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class YearsPicker implements OnInit {
    customHeaderComponent = customHeaderComponent;

    @Input()
    set ageInstallation(ageInstallation: FormContext) {
        if (ageInstallation) {
            this._ageInstallation = ageInstallation;
        }
    }

    get ageInstallation(): FormContext {
        return this._ageInstallation;
    }

    @Input()
    set maxDate(maxDate: Moment) {
        if (maxDate) {
            this._maxDate = maxDate;
        }
    }

    @Input()
    set label(label: string) {
        if (label) {
            this._label = label;
        }
    }

    get maxDate(): Moment {
        return this._maxDate;
    }

    @Output()
    yearChanged = new EventEmitter<String>();

    @Input() highlightedYears: Moment[] = []; // Tableau des années à mettre en surbrillance

    @Input()
    readonlyMode = false;

    @Input()
    required = true;

    private _ageInstallation: FormContext;

    private _maxDate: Moment;

    _label: string = "Date de l'installation";

    constructor() {}

    ngOnInit(): void {}

    dateClass = (date: Moment): MatCalendarCellCssClasses => {
        return this.highlightedYears.some((highlightedYear) => highlightedYear.isSame(date, 'year')) ? 'highlighted-cell' : '';
    };

    chosenYearHandler(selectedYear: Moment, yearPicker: any) {
        yearPicker.close();
        this.yearChanged.emit(selectedYear.format('YYYY'));
    }
}
