import { Pipe, PipeTransform } from '@angular/core';
import { ReferencePrestation } from '../model/reference-prestation.model';
import { TypeDocumentCheckpoint } from '../model/type-document.model';

/**
 * Vérifier si le document est conforme pour une reference de prestation
 */
@Pipe({
    name: 'checkAnswerDocumentByPrestation',
})
export class CheckAnswerDocumentByPrestationPipe implements PipeTransform {
    constructor() {}

    transform(typeDocumentCheckpoints: TypeDocumentCheckpoint[], referencePrestation: ReferencePrestation): boolean {
        if (!typeDocumentCheckpoints || !referencePrestation) {
            return true;
        }
        const typeDocumentCheckpoint = typeDocumentCheckpoints.find(
            (typeDocumentCheckpointTemp) => typeDocumentCheckpointTemp.referencePrestation.id == referencePrestation.id
        );
        if (!typeDocumentCheckpoint) {
            return true;
        }

        return typeDocumentCheckpoint.checkpoints.every((checkpoint) => checkpoint.value != undefined && checkpoint.value != null);
    }
}
