import { Component, OnInit } from '@angular/core';
import { AuthenticationStore, BaseComponent, NotificationService } from 'src/app/commons-lib';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { EtatProgressionService } from '../../../../../../services/etat-progression.service';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { InterventionService } from 'src/app/services/intervention.service';
import { Intervention } from 'src/app/model/intervention.model';
import { PolluantPrelevement } from '../../../model/polluant-prelevement.model';
import { Materiel, UserWizy } from 'src/app/model/user-wizy.model';
import { ConditionsPrelevement, PolluantConfig } from '../../../model/polluant-config.model';
import { UserInformationApiService } from 'src/app/services/user-information-api.service';
import { EtatValidation } from 'src/app/model/etat-progression.model';
import { getRefInfo } from '../../etude-situation/initialisation/objectifs/objectifs.component';
import { PolluantService } from '../../../services/polluant.service';
import { TypePrelevementAmiante } from 'src/app/model/prelevement-generique.model';
import { ConfirmationService } from '@acenv/commons-lib';

@Component({
    selector: 'app-prelevement-materiel',
    templateUrl: './prelevement-materiel.component.html',
    styleUrls: ['./prelevement-materiel.component.scss'],
})
export class InitMaterielPrelevement extends BaseComponent implements OnInit {
    diagnostic: Diagnostic;
    intervention: Intervention;
    contenuDiagnostic: PolluantPrelevement;
    polluantConfig: PolluantConfig;
    isLoaded: Boolean = false;
    isInEditMode: Boolean = false;
    isValidated: Boolean = false;

    material: Materiel = new Materiel();
    previousFormValue: Materiel = new Materiel();

    materielChantierNecessaire: Boolean = false;
    contientMetop: Boolean = false;

    user: UserWizy;
    userInfo: any;
    readonly lstFilds = [
        { label: 'Chronomètre', property: 'numeroChrono' },
        { label: 'Débitmètre', property: 'numeroDebitmetre' },
        { label: 'Kimo', property: 'kimo' },
        { label: 'Anémomètre', property: 'anemomtre' },
        { label: 'Boussole', property: 'boussole' },
        { label: 'Souffleur', property: 'souffleur' },
    ];

    constructor(
        private diagnosticService: DiagnosticService,
        private cnSpinnerService: CnSpinnerService,
        public interventionService: InterventionService,
        private etatProgressionService: EtatProgressionService,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationStore,
        private userInformationApiService: UserInformationApiService,
        private polluantService: PolluantService,
        private readonly notificationService: NotificationService,
        private confirmationService: ConfirmationService
    ) {
        super();
    }

    ngOnInit(): void {
        this.cnSpinnerService.show();
        combineLatest([
            this.interventionService.getCurrentIntervention(),
            this.diagnosticService.getCurrentDiagnostic(),
            this.authenticationService.getCurrentUser().pipe(
                takeUntil(this.ngUnsubscribe),
                switchMap((user: UserWizy) => {
                    this.user = user;
                    return this.userInformationApiService.getUserInformationByUserId(user.id);
                })
            ),
        ])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                ([intervention, diag, userInfo]) => {
                    this.intervention = intervention;
                    this.diagnostic = diag;

                    combineLatest([this.polluantService.findOnePolluantConfigIndexedDB(this.diagnostic.idConfig)])
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe(([polluantConfig]) => {
                            if (polluantConfig == null) {
                                this.notificationService.error('Erreur : Merci de faire une synchro pour récupérer les configs en local.');
                                this.cnSpinnerService.hide();
                                return;
                            }
                            this.polluantConfig = polluantConfig;

                            this.userInfo = userInfo;

                            this.initData();

                            this.isValidated = this.contenuDiagnostic.material.validated;
                            this.isLoaded = true;
                            this.cnSpinnerService.hide();
                        });
                },
                () => this.cnSpinnerService.hide()
            );
    }

    private initData() {
        this.contenuDiagnostic = this.diagnostic.contenuDiagnostic as PolluantPrelevement;

        //On vérifie si l'écran a déja été validé
        this.isValidated = this.contenuDiagnostic.material.validated;

        //Initialisation de l'objet Materiel avec les données de la fiche Utilisateur
        // ou du contenuDiag si défini

        const materielRensigne: boolean = !!this.userInfo && !!this.userInfo.materiel;
        for (let item of this.lstFilds) {
            if (!!this.contenuDiagnostic.material?.data[item.property]) {
                this.material[item.property] = this.contenuDiagnostic.material.data[item.property];
            } else if (materielRensigne) {
                this.material[item.property] = this.userInfo.materiel[item.property];
            }
        }
        if (materielRensigne == false) {
            this.notificationService.warn("Vous n'avez pas encore rensigné vos Informations matériel à votre compte.");
        }
        //Le champ "immatriculationUmd" n'est pas présent dans le constructeur
        this.material.immatriculationUmd = this.contenuDiagnostic.material.data.immatriculationUmd;

        if (!this.contenuDiagnostic.visiteDeChantier.data.objectifsMesurage) {
            this.contenuDiagnostic.visiteDeChantier.data.objectifsMesurage = {
                data: {
                    objectifsMesurage: [],
                },
            };
        }

        //Permet de définir si un objectif de mesurage nécessite du matériel de chantier
        this.contenuDiagnostic.visiteDeChantier.data.objectifsMesurage.data.objectifsMesurage.forEach((obj: any) => {
            let refObj = getRefInfo(this.polluantConfig.objectifsMesurage, obj.reference);

            if (refObj.materielChantierNecessaire) {
                this.materielChantierNecessaire = true;
            }
        });
        //Permet de définir si un objectif de mesurage de type METOP est présent
        if (
            this.contenuDiagnostic.visiteDeChantier.data.objectifsMesurage.data.objectifsMesurage.find((obj: ConditionsPrelevement) => {
                return obj.typePrelevement === TypePrelevementAmiante.METOP;
            })
        ) {
            this.contientMetop = true;
        }
    }

    private checkValidity() {
        this.contenuDiagnostic.material.data = Object.assign(new Materiel(), this.material);
        this.diagnostic.contenuDiagnostic = this.contenuDiagnostic;

        let etat: EtatValidation = 'INVALID';
        if (this.form && this.form.valid && this.contenuDiagnostic.material.validated) {
            etat = 'VALID';
        }

        const code = this.route.snapshot.data['code'];
        this.etatProgressionService.updateDiagnostic(code, etat, this.diagnostic, true);
    }

    cancelModification() {
        this.isInEditMode = false;
        this.material = Object.assign(new Materiel(), this.previousFormValue);
        this.previousFormValue = undefined;
    }

    saveModification() {
        this.isInEditMode = false;
        this.checkValidity();
        this.verifyWithUserInformations();
    }

    validateTab() {
        this.isValidated = true;
        this.contenuDiagnostic.material.validated = true;
        this.checkValidity();
    }

    startModification() {
        this.isInEditMode = true;
        this.previousFormValue = Object.assign(new Materiel(), this.material);
    }

    /**
     * Si détection changt informations => pop-up
     * @private
     */
    private verifyWithUserInformations() {
        this.userInfo.materiel;
        for (const item of this.lstFilds) {
            if (this.userInfo.materiel[item.property] !== this.material[item.property]) {
                this.confirmationService.confirm(
                    {
                        message: "Souhaitez-vous mettre à jour le profil de l'opérateur pour les prochaines interventions ?",
                        titleLabel: 'Confirmation la modification',
                        confirmLabel: 'Mettre à jour',
                        cancelLabel: 'Non',
                        warn: false,
                    },

                    () => {
                        this.notificationService.dismiss();
                        this.userInformationApiService.updateMaterielUser(this.user.id, this.material).subscribe(
                            () => {
                                this.notificationService.success('Votre materiel est correctement enregistré');
                            },
                            (err) => {
                                this.notificationService.error('Une erreur technique est survenue');
                            }
                        );
                    }
                );
                return; // Exit
            }
        }
    }
    /** Utilisé seulement pour le card pour vérfier le form */
    get form() {
        for (const item of this.lstFilds) {
            if (!this.material[item.property]) {
                return { invalid: true, valid: false };
            }
        }
        if (this.materielChantierNecessaire) {
            return { invalid: !this.material.immatriculationUmd, valid: !!this.material.immatriculationUmd };
        }
        return { invalid: false, valid: true };
    }
}
