<div class="d-flex d-flex justify-content-center p-3">
    <ng-container *ngFor="let onglet of listeOnglets; let first = first; let last = last">
        <button
            [routerLink]="[onglet.url]"
            [routerLinkActive]="'is-active'"
            [ngClass]="{ tab: true, 'last-tab': last, 'first-tab': first }"
        >
            <lib-icon *ngIf="onglet.typeIcon === 'mdi'" theme="mdi" [iconName]="onglet.icon"></lib-icon>
            <mat-icon *ngIf="onglet.typeIcon === 'mat-icon'">{{ onglet.icon }}</mat-icon>
            <span class="libelle-onglet">{{ onglet.label }}</span>
        </button>
    </ng-container>
</div>
