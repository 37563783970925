import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { InterventionService } from '../services/intervention.service';
import { DiagnosticService } from '../services/diagnostic.service';
import { AssainissementService } from '../modules/diagnostics/assainissement/services/assainissement.service';
import { RulesService } from '../services/rules.service';
import { CheckValidityTabService } from '../services/check-validity-tab.service';
import { TypeCheckpoint } from '../model/type-element-a-controler.model';
import { AUTRE, EMPTY_VOLUME, EQUIPEMENT, MESU } from '../shared/constants/names.step.constants';
import { EtatDiagnostic } from '../model/diagnostic.model';
import { EtatProgressionService } from '../services/etat-progression.service';
import { ProgressDiagnosticService } from '../services/progress-diagnostic.service';
import { EtatValidation } from '../model/etat-progression.model';
import { NavigationBarsService } from '../services/navigation-bars.service';
import {
    PROPERTIES_MESURAGE_CARREZ,
    PROPERTIES_MESURAGE_HABITABLE,
    PROPERTIES_MESURAGE_UTILE,
} from '../modules/diagnostics/mesurage/shared/mesurage.constants';
import { MesurageService } from '../modules/diagnostics/mesurage/services/mesurage.service';
import { TypePrestationEnum } from '../model/type-prestation.model';
import { DescriptionBienPageComponent } from '../modules/interventions/description/description-bien-page/description-bien-page.component';
import { CnSpinnerService } from '../modules/shared/cn-spinner/service/cn-spinner.service';

/**
 * Enregistre l'intervention courante lorsqu'on quitte la page de description
 * On vérifie la validité des onglet point de controle pour les diagnostics assainissement suivant l'jout ou la suppression d'un niveau/pièce/équipement
 */
@Injectable({
    providedIn: 'root',
})
export class QuitDescriptionBienGuard implements CanDeactivate<DescriptionBienPageComponent> {
    constructor(
        private readonly interventionService: InterventionService,
        private readonly diagnosticService: DiagnosticService,
        private readonly assainissementService: AssainissementService,
        private readonly rulesService: RulesService,
        private readonly checkValidityTabService: CheckValidityTabService,
        private readonly etatProgressionService: EtatProgressionService,
        private readonly navigationBarsService: NavigationBarsService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly progressDiagnosticService: ProgressDiagnosticService
    ) {}

    canDeactivate(target: DescriptionBienPageComponent): Observable<boolean> {
        return this.cnSpinnerService
            .withSpinner(
                combineLatest([this.navigationBarsService.navigationBarConfiguration$, this.interventionService.getCurrentIntervention()]).pipe(
                    switchMap(([config, intervention]) =>
                        combineLatest([
                            of(intervention),
                            config.mode === 'diagnostic' ? this.diagnosticService.getCurrentDiagnostic() : of(null),
                            this.diagnosticService.getAllDiagnosticsForCurrentIntervention(intervention),
                        ])
                    ),
                    switchMap(([intervention, currentDiagnostic, diagnostics]) => {
                        diagnostics.forEach((diag) => {
                            // On modifie le currentDiagnostic si on est dans l'onglet description d'un diagnostic
                            // car les traitement pour le rafraichissement des onglet se base dessus
                            // Sinon on modifie le diagnostic récupéré de la base
                            const diagToUpdate = currentDiagnostic && currentDiagnostic.id == diag.id ? currentDiagnostic : diag;

                            // Récupération de la prestation correspondante
                            const prestationDiag = intervention.prestationsDiagnostics.find(
                                (presta) => presta.idDiagnostic === diagToUpdate.id && diagToUpdate.etat !== EtatDiagnostic.ANNULE
                            );

                            if (prestationDiag) {
                                const mapPropertiesByMesurage = {
                                    MESURAGE: PROPERTIES_MESURAGE_CARREZ,
                                    MESURAGE_HABITABLE: PROPERTIES_MESURAGE_HABITABLE,
                                    MESURAGE_UTILE: PROPERTIES_MESURAGE_UTILE,
                                };
                                switch (prestationDiag.prestation.typePrestation) {
                                    case TypePrestationEnum.ASSAINISSEMENT:
                                        // Recalcul des point de controle pour les diganostic assainissement
                                        this.assainissementService.initFormPointsDeControleAssainissementTotal(
                                            diagToUpdate.id,
                                            diagToUpdate.pointsDeControleBiens,
                                            intervention.relationInterventionBiens,
                                            intervention
                                        );

                                        const rules = this.rulesService.findRulesControlIntoPrestation(prestationDiag);
                                        this.rulesService.computeConformityDiagnosticAssainissement(
                                            intervention.commentaires,
                                            diagToUpdate.pointsDeControleBiens,
                                            diagToUpdate.contenuDiagnostic,
                                            rules
                                        );

                                        // Il faut maintenant check les différents onglets de point de controle
                                        // on filtre les points de controle selon le type de checkpoint à afficher
                                        // -------------- Onglet Pièces sans équipements -------------//
                                        const pcPieceSansEquipement = [];
                                        this.assainissementService.filterTypeCheckpoint(
                                            pcPieceSansEquipement,
                                            diagToUpdate.pointsDeControleBiens,
                                            true,
                                            undefined,
                                            intervention
                                        );
                                        // -------------- Onglet Point de controle => Equipements -------------//
                                        const pcTypeEquipement = [];
                                        this.assainissementService.filterTypeCheckpoint(
                                            pcTypeEquipement,
                                            diagToUpdate.pointsDeControleBiens,
                                            false,
                                            TypeCheckpoint.EQUIPEMENT,
                                            intervention
                                        );
                                        // -------------- Onglet Point de controle => Toilettes sèche -------------//
                                        const pcTypeAutre = [];
                                        this.assainissementService.filterTypeCheckpoint(
                                            pcTypeAutre,
                                            diagToUpdate.pointsDeControleBiens,
                                            false,
                                            TypeCheckpoint.AUTRE,
                                            intervention
                                        );

                                        // Vérification de chaque onglet et mise à jour du diagnostic si nécessaire
                                        combineLatest([
                                            this.checkValidityTabService.checkValidityPointDeControle(
                                                pcPieceSansEquipement,
                                                intervention.commentaires
                                            ),
                                            this.checkValidityTabService.checkValidityPointDeControle(pcTypeEquipement, intervention.commentaires),
                                            this.checkValidityTabService.checkValidityPointDeControle(pcTypeAutre, intervention.commentaires),
                                        ])
                                            .pipe(take(1))
                                            .subscribe(([etatEmptyVolume, etatEquipement, etatAutre]) => {
                                                const mapEtatByCode = new Map<string, EtatValidation>([
                                                    [EMPTY_VOLUME, etatEmptyVolume],
                                                    [EQUIPEMENT, etatEquipement],
                                                    [AUTRE, etatAutre],
                                                ]);
                                                this.etatProgressionService.updateDiagnosticMultipleCode(mapEtatByCode, diagToUpdate);
                                                this.progressDiagnosticService.refresh();
                                            });
                                        break;

                                    case TypePrestationEnum.MESURAGE:
                                    case TypePrestationEnum.MESURAGE_HABITABLE:
                                    case TypePrestationEnum.MESURAGE_UTILE:
                                        // Initialisation du formulaire de mesurages
                                        MesurageService.initMesures(diagToUpdate.pointsDeControleBiens, intervention);

                                        this.checkValidityTabService
                                            .checkValidityMesure(
                                                diagToUpdate.pointsDeControleBiens,
                                                intervention.commentaires,
                                                mapPropertiesByMesurage[prestationDiag.prestation.typePrestation]
                                            )
                                            .pipe(take(1))
                                            .subscribe((etatMesurage) => {
                                                this.etatProgressionService.updateDiagnostic(MESU, etatMesurage, diagToUpdate);
                                                this.progressDiagnosticService.refresh();
                                            });
                                        break;
                                }
                            }
                        });
                        // On met à jour l'intervention dans le cas ou des commentaires ont été supprimés (sur un volume ou une équipement supprimé par exemple)
                        return this.interventionService.updateIntervention(intervention);
                    }),

                    map(() => {
                        return true;
                    }),
                    catchError(() => {
                        return of(false);
                    })
                )
            )
            .pipe();
    }
}
