'use strict';

import { extension_instance } from './cn_extension';

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** zoning type object
//***********************************************************************************
//***********************************************************************************

export class cn_zoning_type {
    /**
     * Constructor
     * @param {string} property
     * @param {string} label_singular
     * @param {string} label_plural
     * @param {string} label_plural_short
     * @param {string} icon
     * @param {boolean} is_bbp_zone_type : is this zone type the one used in BBP, and has a bim code
     */
    constructor(property, label_singular, label_plural, label_plural_short, icon, is_bbp_zone_type = false) {
        this.property = property;
        this.label_singular = label_singular;
        this.label_plural = label_plural;
        this.label_plural_short = label_plural_short;
        this.icon = icon;
        this.is_bbp_zone_type = is_bbp_zone_type;
    }

    /**
     * returns the list of existing zoning types
     * @returns {Array<cn_zoning_type>}
     */
    static get_zoning_types() {
        return extension_instance.zone.get_zones_tools();
    }
}


