import { Component, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DiagnosticService } from '../../../../../../../services/diagnostic.service';
import { first } from 'rxjs/operators';
import { Polluant } from '../../../../model/polluant.model';
import { POLL_MODALITES } from '../../../../../../../shared/constants/names.step.constants';
import { EtatProgressionService } from '../../../../../../../services/etat-progression.service';
import { Diagnostic } from '../../../../../../../model/diagnostic.model';

@Component({
    selector: 'app-modalites',
    templateUrl: './modalites.component.html',
})
export class ModalitesComponent extends BaseComponent implements OnDestroy {
    // PUBLIC
    readonlyMode = false;
    formGroup: FormGroup;

    // PRIVATE
    private _diagnostic: Diagnostic;

    constructor(private fb: FormBuilder, private diagnosticService: DiagnosticService, private etatProgressionService: EtatProgressionService) {
        super();
        this.diagnosticService
            .getCurrentDiagnostic()
            .pipe(first())
            .subscribe((diagnostic) => {
                this._diagnostic = diagnostic;
                const contenuDiag = diagnostic.contenuDiagnostic as Polluant;
                this.formGroup = this.fb.group({
                    modalites: [contenuDiag.preparation.data.modalites || '', Validators.required],
                });
            });
    }

    ngOnDestroy(): void {
        (this._diagnostic.contenuDiagnostic as Polluant).preparation.data.modalites = this.formGroup?.value?.modalites;
        this.etatProgressionService.updateDiagnostic(
            POLL_MODALITES,
            (this._diagnostic.contenuDiagnostic as Polluant).preparation.data.modalites ? 'VALID' : 'INVALID',
            this._diagnostic,
            true
        );
        super.ngOnDestroy();
    }
}
