/**
 * Représente une catégorie contenant des équipements dans wizy.
 * Elle dérive de WorkType qui est utilisé pour la même chose dans CnMap
 * Seule les catégories abstract=false peuvent donner lieu à un équipement qui pourra être instancié pour être placé sur le plan ou dans le mode liste.
 * L'object instancié est un EquipementInstancie
 */
import { WorkType } from '@acenv/commons-lib';
import { EtatWorkflow } from './etat-workflow.model';
import { TypePrestation } from './type-prestation.model';

export class CategorieEquipement extends WorkType {
    /***
     * Etat de la catégorie : actif ou inactif, permet de définir la visibilité de la catégorie dans les intervention/diagnostic
     */
    public etatEquipement: EtatWorkflow;

    /**
     * Types de prestations associés à l'élément
     */
    public typesPrestationsAssocie: TypePrestation[] = [];

    /**
     * Valeurs par défaut des paramètres
     */
    public valeursDefautParametres?: {
        [codeBim: string]: any;
    };

    public children: CategorieEquipement[] = [];
}
