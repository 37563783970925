<div
    class="pl-1 header-editor-panel d-flex align-content-center w-100 justify-content-between"
    [ngClass]="showBorder ? 'header-editor-panel-border' : ''"
>
    <div class="d-flex">
        <div class="d-flex align-items-center">
            <button mat-icon-button (click)="onClickBtnClose()">
                <mat-icon class="chevron-left-ico">chevron_left</mat-icon>
            </button>
        </div>
        <div class="pl-1 header-editor-panel-text d-flex align-items-center">{{ title }}</div>
    </div>
    <!-- Boutons de droite -->
    <ng-content></ng-content>
</div>
