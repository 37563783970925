'use strict';
//***********************************************************************************
//***********************************************************************************
//**** A general handler for object instances
//***********************************************************************************
//***********************************************************************************

import { cn_add, cn_clone, cn_middle, cn_mul, cn_normal, cn_sub } from '../utils/cn_utilities';
import { cn_object_instance } from '../model/cn_object_instance';
import { cn_storey } from '../model/cn_storey';
import { cn_svg_map } from './cn_svg_map';
import {
    cn_facing_trimming,
    cn_handler_rotation,
    cn_handler_wall_distance,
    cn_pastille,
    cn_roof_slab,
    cn_type_pastille,
    cn_view_overlay,
    PARAM_STATUS_CUSTOM_VALUE,
    PARAM_STATUS_USE_DEFAULT_VALUE,
    PARAM_STATUS_VARIABLE
} from '..';
import { cn_handler_space_drag2 } from './cn_handler_space_drag2';
import { cn_handler_wall_drag } from './cn_handler_wall_drag';
import { cn_handler_object } from './cn_handler_object';
import { cn_edition_handler } from './cn_edition_handler';
import { cn_mouse_event } from './cn_mouse_event';
import { cn_edit_box } from './cn_edit_box';
import { cn_number_input, cn_object_parameters_input } from './cn_inputs';

export class cn_object_instance_handler extends cn_edition_handler {
    /**
     * Constructor
     * @param {Array<cn_object_instance>} instances
     * @param {cn_svg_map | cn_view_overlay} map
     * @param {boolean} creation
     * @param {cn_storey} storey
     */
    // @ts-ignore
    constructor(instances, map, creation = false, storey = null) {
        // @ts-ignore
        super(instances, map);
        this._view_overlay = (map && map.constructor == cn_view_overlay) ? map : null;
        this.instance = (instances.length == 1) ? instances[0] : null;
        this.instances = instances;
        this._storey = storey;
        map = (map && map.constructor == cn_svg_map) ? map : null;
        this._map = map;
        this._controller = (map) ? map._controller : null;
        // @ts-ignore
        this._transaction_manager = instances[0].parent.building.transaction_manager;
        this._handler = null;
        this._measure_handler = null;
        this._ghost = null;
        this._grab_roof_instance = false;

        this._build_handler();
    }

    _build_handler() {

        this._handlers = [];
        this._measure_handler = null;
        const obj = this;

        //*** Create a handler for that object, depending on the object's type
        this.drawing_storey = (this._storey) ? this._storey : (this._map) ? this._map._storey : null;
        if (this.instance) {
            if (this.instance.is_roof()) {
                this._handler = new cn_handler_rotation(this.instance.position, this.instance.orientation, 5, this.instance.object.size[0] + this.instance.object.size[1]);
                this._handler.on('change', () => {
                    // @ts-ignore
                    this._transaction_manager.push_transaction('Rotation d\'ouvrant de toiture', this.instance.ID);
                    this._transaction_manager.push_item_set(obj.instance, 'orientation', item => {
                        item.update();
                    });
                    // @ts-ignore
                    this.instance.orientation = this._handler.angle;
                    this._update_measure_handler();
                    if (this._view_overlay) this._view_overlay.refresh_3d();
                });
                // @ts-ignore
                this._measure_handler = new cn_handler_wall_distance(this.instance.parent, this._view_overlay || this._map);
            } else {
                // @ts-ignore
                this._scene = this.instance.parent;
                let anchor_position = null;
                let anchor_normal = null;
                if (this.instance.object.place_on_wall()) {
                    anchor_position = this.instance.object.get_anchor_position();
                    anchor_normal = this.instance.object.get_anchor_normal();
                }
                const handler_object = new cn_handler_object(this.instance.object.size[0], this.instance.object.size[1], this.instance.position, this.instance.orientation * Math.PI / 180, anchor_position, anchor_normal);
                handler_object.element = this.instance;

                if (anchor_position)
                    this._handler = new cn_handler_wall_drag(handler_object, this._scene, this.drawing_storey);
                else
                    this._handler = new cn_handler_space_drag2(handler_object, this._scene, this.drawing_storey);

                this._measure_handler = new cn_handler_wall_distance(this._scene, this._view_overlay || this._map);
            }
            this._handler['instance'] = this.instance;
            this._handlers.push(this._handler);
            this._handlers.push(this._measure_handler);
            this._update_measure_handler();
            this._measure_handler.on("change", (offset) => {
                // @ts-ignore
                this._transaction_manager.push_transaction('Déplacement d\'objet', this.instance.ID);
                this._transaction_manager.push_item_set(this.instance, ['position']);
                this.instance.position = cn_add(this.instance.position, offset);
                this.instance.update_deep();
                this._build_handler();
                if (this._view_overlay)
                    this._view_overlay.refresh_3d();
                else
                    this._map.refresh();
            });
        }

        //*** Edit box for mass selection */
        // @ts-ignore
        const edit_box = new cn_edit_box(this, this.instances, this._readOnly);
        this._handlers.push(edit_box);

        this._height_pastille = null;
        //*** Altitude edition */
        if (this.instance && !this._readOnly) {
            const z0 = this.instance.object.get_default_height();
            let z = z0 + this.instance.height;

            const height_pastille = new cn_pastille([0, 0], 'h=' + (z * 100).toFixed(0));
            this._height_pastille = height_pastille;
            edit_box.add_pastille(height_pastille);
            height_pastille.svg_class = 'pastille_background white';
            height_pastille.text_class = 'pastille_text black';
            height_pastille.title = 'Modifier la hauteur';
            height_pastille.clicked = () => {
                const input = new cn_number_input(`Hauteur de l'équipement`, z * 100, 'cm', 0, 0);
                input.callback = () => {
                    this._transaction_manager.push_transaction('Hauteur d\'équipement');
                    this._transaction_manager.push_item_set(this.instance, ['height']);
                    let v = input.value / 100;
                    this.instance.height = v - z0;
                    z = z0 + this.instance.height;
                    height_pastille.label = 'h=' + (z * 100).toFixed(0);

                    //*** 3D change ? */
                    if (this._view_overlay) {
                        this._view_overlay.refresh_3d();
                    }

                };
                this.call('number_input', input);
            }
        }

        //*** parameters */
        if (this.instances[0].object.source && this.instances[0].object.source.product_type) {
            //*** We check that all instances have the same source */
            const source = this.instances[0].object.source;
            if (source.product_type != '' && !this.instances.some(i => i.object.source != source)) {
                //*** We create the parameters pastille */
                const parameters_pastille = new cn_pastille([0, 0], 'text.svg');
                edit_box.add_pastille(parameters_pastille);
                parameters_pastille.svg_class = 'pastille_background white';
                parameters_pastille.title = 'Paramètres de l\'équipement';

                //*** Pastille click method :  */
                parameters_pastille.clicked = () => {

                    //*** Build input */
                    const input = new cn_object_parameters_input();
                    const distinctLabels = new Set(this.instances.map(it => it.object.get_label()));
                    input.label = distinctLabels.size === 1 ? distinctLabels.values().next().value : '';
                    input.product_type = source.product_type;
                    input.parameters = [];
                    input.readonly = this._readOnly;

                    //*** Fill codes & values */
                    const codes = [];
                    const typesValueByCode = new Map();
                    const instanceDistinctValuesByCode = new Map();
                    for (let code in source.parameters) {
                        codes.push(code);
                        typesValueByCode.set(code, source.parameters[code]);
                    }
                    this.instances.forEach(instance => {
                        for (let code in instance.parameters) {
                            if (!codes.includes(code)) {
                                codes.push(code);
                            }
                            if (!instanceDistinctValuesByCode.has(code)) {
                                instanceDistinctValuesByCode.set(code, new Set());
                            }
                            instanceDistinctValuesByCode.get(code).add(instance.parameters[code]);
                        }
                    });

                    //*** Fill input parameters */
                    const inputParams = [];
                    codes.forEach(code => {
                        //*** Get type & instance values */
                        const typeValue = typesValueByCode.get(code);
                        const instanceDistinctValues = instanceDistinctValuesByCode.get(code) || new Set();
                        //*** Get status */
                        let status;
                        const allDistinctValues = new Set();
                        if (typeValue != null) {
                            allDistinctValues.add(typeValue);
                        }
                        instanceDistinctValues.forEach(val => allDistinctValues.add(val));

                        if (this.instances.length === 1) {
                            switch (allDistinctValues.size) {
                                case 0:
                                    status = PARAM_STATUS_USE_DEFAULT_VALUE;
                                    break;
                                case 1:
                                    if (typeValue != null) {
                                        status = PARAM_STATUS_USE_DEFAULT_VALUE;
                                    } else {
                                        status = PARAM_STATUS_CUSTOM_VALUE;
                                    }
                                    break;
                                default:
                                    status = PARAM_STATUS_CUSTOM_VALUE;
                                    break;
                            }
                        } else {
                            switch (allDistinctValues.size) {
                                case 0:
                                    status = PARAM_STATUS_USE_DEFAULT_VALUE;
                                    break;
                                case 1:
                                    if (typeValue != null) {
                                        status = PARAM_STATUS_USE_DEFAULT_VALUE;
                                    } else if (this.instances.every(instance => instance.parameters[code] === instanceDistinctValues.values().next().value)) {
                                        status = PARAM_STATUS_CUSTOM_VALUE;
                                    } else {
                                        status = PARAM_STATUS_VARIABLE;
                                    }
                                    break;
                                default:
                                    if (instanceDistinctValues.size === 1 && this.instances.every(instance => instance.parameters[code] === instanceDistinctValues.values().next().value)) {
                                        status = PARAM_STATUS_CUSTOM_VALUE;
                                    } else {
                                        status = PARAM_STATUS_VARIABLE;
                                    }
                                    break;
                            }
                        }

                        //*** Get effective value */
                        let effectiveValue;
                        switch (status) {
                            case PARAM_STATUS_USE_DEFAULT_VALUE:
                                effectiveValue = typeValue;
                                break
                            case PARAM_STATUS_CUSTOM_VALUE:
                                effectiveValue = instanceDistinctValues.values().next().value;
                                break;
                            case PARAM_STATUS_VARIABLE:
                                effectiveValue = undefined;
                                break;
                        }

                        const inputParam = {
                            code_bim: code,
                            default_value: typeValue,
                            value: effectiveValue,
                            status: status,
                        }
                        inputParams.push(inputParam);
                    })
                    input.parameters = inputParams;

                    //*** Input callback */
                    input.callback = () => {
                        this._transaction_manager.push_transaction('Modification d\'équipements');
                        this.instances.forEach(it => {
                            this._transaction_manager.push_item_set(it, ['parameters']);
                        });
                        input.parameters.forEach(p => {
                            if (p.status === PARAM_STATUS_USE_DEFAULT_VALUE) {
                                this.instances.forEach(i => delete i.parameters[p.code_bim]);
                            } else if (p.status === PARAM_STATUS_CUSTOM_VALUE) {
                                this.instances.forEach(i => i.parameters[p.code_bim] = p.value);
                            }
                        });
                    };

                    this.call('object_parameters_input', input);
                };
            }
        }

        if (!this._readOnly) {

            //*** Type pastille */
            if (this.instance) {
                const building = (this._map) ? this._map._building : this._view_overlay._building;
                // @ts-ignore
                const type_pastille = new cn_type_pastille(this.instances, 'object', () => [...building.get_objects()], 'Modèle d\'équipement', this._map);
                type_pastille.title = 'Modifier le modèle d\'équipement';
                edit_box.add_pastille(type_pastille);
                type_pastille.on('type_changed', () => this._build_handler());
            }

            if (this.instance) {
                edit_box.add_lock_pastille(this._transaction_manager);
            }

        }

        edit_box.add_select_siblings_pastille('object');
    }

    //***********************************************************************************
    //**** Refresh
    //***********************************************************************************
    draw(camera) {
        // @ts-ignore
        if (this._handler) this._handler.active = this._handler.visible = !this.instance.virtual && !this.instance.locked;
        if (camera.is_3d() && this._handler) {
            this._handler.altitude = this.instance.get_altitude(this.drawing_storey);
            if (this.instance.space) {
                if (this.instance.object.roof_parallel)
                    // @ts-ignore
                    this._handler.plane_normal = this.instance.space.normal;
                else
                    // @ts-ignore
                    this._handler.plane_normal = [0, 0, 1];
            }
        }

        if (camera.is_3d() && this._measure_handler)
            this._measure_handler.altitude = this.instance.get_altitude(this.drawing_storey);

        if (this._handler && this.instance && this.instance.is_roof()) {
            // @ts-ignore
            this._handler._snap_segments = [];
            if (this.instance.space)
                // @ts-ignore
                this._handler._snap_segments = this.instance.space.get_segments();
        }

        return super.draw(camera);
    }

    //***********************************************************************************
    //**** clear move effects
    //***********************************************************************************
    clear_move() {
        this._ghost = null;
        super.clear_move();
    }

    /**
     * Manage a move. To return 'true' if grab is to be managed.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
    move(mouse_event) {
        if (super.move(mouse_event)) return true;

        //*** maybe we are grabbing a roof instance */
        if (this._mouse_over_roof_instance(mouse_event))
            return true;

        return false;
    }

    /**
     * Manage a grab. To return 'true' if grab is to be managed.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
    grab(mouse_event) {
        this._grab_roof_instance = false;
        this._ghost = null;
        if (this.instance && !this.instance.is_roof() && this._controller && this._controller.get_selection().length == 1 && this._controller.get_selection()[0] == this.instance)
            mouse_event.drag_and_drop_element = this.instance;

        if (super.grab(mouse_event))
            return true;

        mouse_event.drag_and_drop_element = null;

        //*** Special management for roof instances */
        this._grab_roof_instance = this._mouse_over_roof_instance(mouse_event);
        if (this._grab_roof_instance)
            return true;

        return false;
    }

    _mouse_over_roof_instance(mouse_event) {

        if (!this.instance || !this.instance.is_roof()) return false;

        if (this._view_overlay)
            return mouse_event.impacts.length && mouse_event.impacts[0].storey_element && mouse_event.impacts[0].storey_element.element == this.instance;
        return this.instance.contains(mouse_event.mouse_world);
    }

    /**
     * Manage a drag. Only after a grab that returned true. To return 'true' if drag had an effect.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
    drag(mouse_event) {
        const res = super.drag(mouse_event);

        //*** special case for roof instances */
        if (this._grab_roof_instance) {
            var slab = null;
            var position = [0, 0];
            //*** 3D change ? */
            if (this._view_overlay) {
                if (mouse_event.impacts.length) {
                    for (var i = 0; i < mouse_event.impacts.length; i++) {
                        //*** skip the object itself */
                        if (mouse_event.impacts[i].storey_element && mouse_event.impacts[i].storey_element.element == this.instance) continue;

                        //*** skip facings */
                        const element = (mouse_event.impacts[i].storey_element) ? mouse_event.impacts[i].storey_element.element : null;
                        if (!element) break;
                        if (element.constructor == cn_facing_trimming) continue;

                        //*** Search for roof slab */
                        // @ts-ignore
                        if (element.constructor == cn_roof_slab && mouse_event.impacts[i].storey_element.storey.roof == this.instance.parent) {
                            slab = element;
                            position = cn_clone(mouse_event.impacts[i].position);
                        }
                        break;
                    }
                }
            } else {
                // @ts-ignore
                slab = this.instance.parent.find_slab(mouse_event.mouse_world);
                position = cn_clone(mouse_event.mouse_world);
            }
            if (slab) {
                // @ts-ignore
                this._transaction_manager.push_transaction('Déplacement d\'objet', this.instance.ID);
                this._transaction_manager.push_item_set(this.instance, ['position', 'space']);
                this.instance.position = position;
                this.instance.space = slab;
                // @ts-ignore
                this._handler.center = cn_clone(position);
                this._update_measure_handler();
                if (this._view_overlay)
                    this._view_overlay.refresh_3d();
            }
            return true;
        }

        if (this._handler && this._handler == this._focus_handler) {
            if (this.instance.is_roof()) return true;

            this._ghost = null;

            if (res) {
                // @ts-ignore
                this._transaction_manager.push_transaction('Déplacement d\'objet', this.instance.ID);
                this._transaction_manager.push_item_set(this.instance, ['position', 'orientation', '_contact_wall', 'space', 'height']);
                this.instance._contact_wall = null;
                // @ts-ignore
                this.instance.position = cn_clone(this._handler.object.center);
                // @ts-ignore
                this.instance.orientation = this._handler.object.angle * 180 / Math.PI;
                this.instance.space = this._handler['space'];
                this.instance.update_deep();

                //*** 3D change ? */
                if (this._view_overlay) {
                    //*** maybe update height ? */
                    if (this.instance.object.get_contact() == 'wall') {
                        const dh = this.instance.object.get_default_height();
                        this.instance.height += mouse_event.impact_behind.position[2] - this.instance.get_altitude(this.drawing_storey);
                        if (dh + this.instance.height < 0.05) this.instance.height = -dh;
                        if (this._height_pastille) {
                            const z = dh + this.instance.height;
                            this._height_pastille.label = 'h=' + (z * 100).toFixed(0);
                        }
                    }

                    this._view_overlay.refresh_3d();
                }

                this._update_measure_handler();
                this.call('change');
                this._pending_changes = true;
            } else
                this._ghost = mouse_event.mouse_world;
        }
        return true;
    }

    /**
     * Manage a drop. Only after a grab that returned true, and at least one drag. To return 'true' if drop had an effect.
     * @param {cn_mouse_event} mouse_event
     * @returns {boolean}
     */
    // @ts-ignore
    drop(mouse_event) {
        return true;
    }

    /**
     * During a drag and drop, the owner of the drag and drop may change.
     * Return true to accept owning the drag and drop events.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
    grab_element(mouse_event) {
        if (this.instance) {
            this.instance.virtual = false;
            this._handler.grab_element(mouse_event);
            this._focus_handler = this._handler;
            this._dragging_handler = this._handler;
        }
        return true;
    }

    /**
     * Manage a drag, during a drag and drop.
     * This may follow:
     * - Either a grab event, where drag and drop has been triggered (by setting drag_and_drop_element on the mouse event)
     * - Either after an accepted grab_element event.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
    drag_element(mouse_event) {
        return this.drag(mouse_event);
    }

    /**
     * Manage a drop, in a drag and drop event.
     * @param {cn_mouse_event} mouse_event
     * @returns {boolean}
     */
    // @ts-ignore
    drop_element(mouse_event) {
        return true;
    }

    _update_measure_handler() {
        if (!this._measure_handler) return;

        const width = this.instance.object.size[0];
        const height = this.instance.object.size[1];
        const position = this.instance.position;
        const angle = this.instance.orientation * Math.PI / 180;
        const dx = [Math.cos(angle), Math.sin(angle)];
        const dy = cn_normal(dx);

        const vertices = [];
        for (var n = 0; n < 4; n++) {
            var p = cn_clone(position);
            if (n & 1) p = cn_add(p, cn_mul(dx, 0.5 * width));
            else p = cn_sub(p, cn_mul(dx, 0.5 * width));
            if (n & 2) p = cn_add(p, cn_mul(dy, 0.5 * height));
            else p = cn_sub(p, cn_mul(dy, 0.5 * height));
            vertices.push(p);
        }

        this._measure_handler.segments = [];
        if (this.instance.object.place_on_wall()) {
            this._measure_handler.segments.push([vertices[2], vertices[3]]);
            this._measure_handler.segments.push([vertices[1], vertices[0]]);
        }
        else {
            this._measure_handler.segments.push([vertices[0], cn_middle(vertices[0], vertices[2])]);
            this._measure_handler.segments.push([vertices[2], cn_middle(vertices[2], vertices[3])]);
            this._measure_handler.segments.push([vertices[3], cn_middle(vertices[3], vertices[1])]);
            this._measure_handler.segments.push([vertices[1], cn_middle(vertices[1], vertices[0])]);
        }
    }
}

