import { Component, Input, OnInit } from '@angular/core';
import { Intervention, numCofracAgence } from 'src/app/model/intervention.model';
import { InterventionReportData, IReportComponent, ReportBlockData } from '../../../../../../model/rapport.model';
import { TypePrestation, TypePrestationEnum } from 'src/app/model/type-prestation.model';

@Component({
    selector: 'app-poll-header-block',
    templateUrl: './poll-header-block.component.html',
    styleUrls: ['./poll-header-block.component.scss'],
})
export class PollHeaderBlockComponent implements OnInit, IReportComponent {
    @Input() data: ReportBlockData;
    @Input() intervention: Intervention;
    @Input() interReportData: InterventionReportData;

    subTitle: string;
    numDossier: string;
    cofrac: boolean;
    typePresta: TypePrestation = TypePrestationEnum.POLLUANT_VISITE_CHANTIER;
    messages = [];
    numCofrac = {
        text: '',
        class: '',
    };
    cofracValeur = {
        text: '',
        class: '',
    };

    constructor() {}

    ngOnInit(): void {
        if (this.data) {
            if (this.data.values['articles']) {
                this.subTitle = this.data.values['articles'];
            }

            if (this.data.values['messages']) {
                this.messages = this.data.values['messages'];
            }
        }
        if (this.interReportData) {
            this.numDossier = this.interReportData.dossierNumber;

            this.cofrac = this.interReportData.conformiteCalculee;
            if (this.cofrac) {
                this.cofracValeur.text = 'cofrac';
                this.cofracValeur.class = 'cofrac';

                if (numCofracAgence(this.typePresta, this.intervention) != null) {
                    this.numCofrac.text = 'N° ' + numCofracAgence(this.typePresta, this.intervention).numero;
                } else {
                    this.numCofrac.class = 'pas-num-cofrac';
                    this.numCofrac.text = '(Numéro Cofrac Introuvable)';
                }
            } else if (this.cofrac === false) {
                this.cofracValeur.text = 'non cofrac';
                this.cofracValeur.class = 'non-cofrac';
            }
        }
    }
}
