import { Injectable } from '@angular/core';
import { DiagnosticService } from './diagnostic.service';
import { Commentaire } from '../model/commentaire.model';
import { CommentairePredefini } from '../model/commentaire-predefini.model';
import { DiagnosticHandlerService } from './diagnostic-handler.service';
import { TypeCommentaire } from '../model/type-commentaire.model';
import { DateUtils, MongoUtils } from 'src/app/commons-lib';
import { Diagnostic } from '../model/diagnostic.model';
import { Intervention } from '../model/intervention.model';
import { PointDeControleVolume } from '../model/point-de-controle.model';

@Injectable({
    providedIn: 'root',
})
export class CommentaireService {
    constructor(private diagnosticService: DiagnosticService, private diagnosticHandlerService: DiagnosticHandlerService) {}

    /**
     * Récupération des commentaires prédéfinis selon les réponses antérieures des formulaires
     * @param commentairesGeneraux
     * @param commentaires
     */
    prepareSpecificCommentsFromFormResponses(commentairesGeneraux: Commentaire[], commentaires: CommentairePredefini[]): Commentaire[] {
        const diagnostic = this.diagnosticService.getCurrentDiagnosticValue();
        return this.diagnosticHandlerService
            .getTypePrestationService(diagnostic.typePrestation)
            .prepareSpecificComments(diagnostic, commentairesGeneraux, commentaires);
    }

    /**
     * Prépare les données pour un commentaire light
     * @param typeCommentaire
     * @param content
     */
    prepareLightCommentaireToSave(typeCommentaire: TypeCommentaire, content: string): Commentaire {
        return {
            id: MongoUtils.generateObjectId(),
            date: DateUtils.localDateTimeString(),
            fromDescription: false,
            type: typeCommentaire,
            selected: true,
            contenu: content,
            categories: [],
        };
    }

    addCommentaireFromRoomToVolume(room: PointDeControleVolume, diagnostic: Diagnostic, intervention: Intervention) {
        diagnostic.pointsDeControleBiens.forEach((b) => {
            b.pointsDeControleNiveaux.forEach((n) => {
                const niveau = intervention.relationInterventionBiens[0].bien.description.find((niveau) => niveau.storeyId === n.storeyId);
                n.pointsDeControleVolumes.forEach((v) => {
                    if (v.idVolume === room.idVolume) {
                        const volume = niveau.volumes.find((vol) => vol.id === v.idVolume);
                        volume.commentairesId = room.commentairesId;
                    }
                });
            });
        });
    }
}
