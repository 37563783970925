/**
 * BaseObject
 * Objet de base qui contient un id et un nom.
 * Utilisé nottament pour les listes déroulantes.
 */
export class BaseObject {
    id: string;

    /**
     * Nom
     */
    nom?: string;
}
