<div [formGroup]="group">
    <div *ngIf="formConf?.espace?.controls?.espace?.displayed">
        <mat-form-field appearance="fill">
            <mat-label>Espace</mat-label>
            <mat-select formControlName="espace" [compareWith]="compareWith">
                <mat-option *ngFor="let espace of espaces" [value]="espace">{{ espace.nom }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
