import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/commons-lib';

@Component({
    selector: 'app-export-svg-subform-hap',
    templateUrl: './export-svg-subform-hap.component.html',
    styleUrls: ['./export-svg-subform-hap.component.scss'],
})
export class ExportSvgSubformHapComponent extends BaseComponent implements OnInit {
    @Input() group: FormGroup;
    @Input() formConf: any = {};

    get hapSurfaceConf() {
        return this.formConf.hap.groups.surface;
    }

    get hapPrelevementConf() {
        return this.formConf.hap.groups.prelevement;
    }

    get hapZoneConf() {
        return this.formConf.hap.groups.zone;
    }

    constructor() {
        super();
    }

    ngOnInit(): void {}
}
