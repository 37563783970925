<div class="volume-block">
    <div class="title">
        {{ label }}
    </div>
    <div class="action row">
        <div *ngIf="nbVolumes == 1">
            <span class="badge-info-grey-little"> {{ surface }} m² </span>
        </div>
        <div *ngIf="nbVolumes > 1" class="d-flex justify-content-end">
            <input
                type="text"
                (click)="openCalculator()"
                class="col-sm-3 input-badge"
                readonly
                [value]="surface"
                [readonly]="false"
            /><span class="ml-2 span-black">m²</span>
        </div>

        <button mat-icon-button (click)="delete()" [matTooltip]="'Supprimer (' + label + ')'">
            <mat-icon class="warn">delete</mat-icon>
        </button>
    </div>
</div>
