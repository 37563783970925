import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent, NotificationService } from 'src/app/commons-lib';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormContext } from '../../../../../../model/rule/form-context.model';
import { FormService } from '../../../../../../services/form.service';
import { CeeModel } from '../../../model/cee.model';
import { EtatProgressionService } from '../../../../../../services/etat-progression.service';
import { takeUntil } from 'rxjs/operators';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { TypeCommentaire, enumTypesCommentaire } from '../../../../../../model/type-commentaire.model';
import { MatDialog } from '@angular/material/dialog';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { InterventionService } from 'src/app/services/intervention.service';
import { DocumentsService } from 'src/app/services/documents.service';
import { ActivatedRoute } from '@angular/router';
import { Document, DocumentPreRapport } from 'src/app/model/document.model';
import { Intervention } from 'src/app/model/intervention.model';
import { combineLatest } from 'rxjs';
import { EtapeDiagnosticGenerique } from 'src/app/model/diagnostic-etape.model';
import { CommentModalComponent } from 'src/app/modules/shared/comment-modal/comment-modal.component';
import { DialogUtils } from 'src/app/utils/dialog.utils';
import { Siret } from 'src/app/shared/siret/Siret';
import { EtatValidation } from '../../../../../../model/etat-progression.model';
import { DocumentFileService } from 'src/app/services/document-file.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TypePrestationEnum } from 'src/app/model/type-prestation.model';

@Component({
    selector: 'app-generale',
    templateUrl: './general.component.html',
    styleUrls: ['./general.component.scss'],
})
export class GeneralComponent extends BaseComponent implements OnInit, OnDestroy {
    enumTypesCommentaire = enumTypesCommentaire;
    TypePrestationEnum = TypePrestationEnum;

    readonly COMMENT_TYPES: TypeCommentaire[] = [
        enumTypesCommentaire.NOTE_PERSONNELLE,
        enumTypesCommentaire.REMARQUE,
        enumTypesCommentaire.JUSTIFICATION_NON_CONFORMITE,
        enumTypesCommentaire.CONSTATATION_DIVERSE,
    ];
    public choices: any[] = [
        '1 - oui et pièce présentée',
        '2 - oui mais pièce non présentée',
        '3 - non avec déclaration écrite',
        '4 - non sans déclaration écrite',
        "5 - non vérifiable car le bénéficiaire ne s'en souvient pas",
        "6 - non vérifiable car la personne présente n'est pas le bénéficiaire",
    ];
    readonlyMode: boolean = false;
    public diagnostic: Diagnostic;
    formGeneral: FormGroup;
    ceeModel: CeeModel = new CeeModel();

    displayedColumns = ['docInfo', 'view'];

    private documentsDiagnostic: (Document & DocumentPreRapport)[];
    private currentIntervention: Intervention;

    source: string;
    pdfSrc: SafeResourceUrl;
    titreModal: string;
    currentDocumentId: string | null = null;
    largeur: any;
    isDocPresent: boolean;

    constructor(
        public matDialog: MatDialog,
        private formService: FormService,
        private readonly diagnosticService: DiagnosticService,
        private etatProgressionService: EtatProgressionService,
        private formBuilder: FormBuilder,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly interventionService: InterventionService,
        private readonly route: ActivatedRoute,
        private readonly documentService: DocumentsService,
        private readonly notificationService: NotificationService,
        private readonly documentFileService: DocumentFileService,
        private ngZone: NgZone,
        private sanitizer: DomSanitizer
    ) {
        super();
    }

    ngOnInit(): void {
        this.cnSpinnerService
            .withSpinner(combineLatest([this.interventionService.getCurrentIntervention(), this.diagnosticService.getCurrentDiagnostic()]))
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([intervention, diagnostic]) => {
                this.currentIntervention = intervention;
                this.diagnostic = diagnostic;
                this.ceeModel = Object.assign(new CeeModel(), diagnostic.contenuDiagnostic as CeeModel);
                this.readonlyMode = this.diagnosticService.isReadOnlyMode(this.currentIntervention, this.diagnostic);
                if (!this.diagnostic.documentsRapport) {
                    this.diagnostic.documentsRapport = [];
                }
                this.documentsDiagnostic = DocumentsService.buildDocumentsAfficheRapport(
                    this.documentService.getDocumentsInformation(this.currentIntervention, this.diagnostic),
                    this.diagnostic,
                    !this.readonlyMode
                );
                // sync
                this.diagnostic.documentsRapport = this.documentsDiagnostic.filter((doc) => doc.afficherDansRapport).map((doc) => doc.id);
                this.diagnostic.documentsListRapport = this.documentsDiagnostic.filter((doc) => doc.afficherDansListeDocuments).map((doc) => doc.id);
                this.initFormGeneral();
                // Devis présenté ?
                const devisDoc = this.documentsDiagnostic.find((doc) => doc.nom === 'Devis travaux');
                this.isDocPresent = devisDoc?.isPresented;
            });
    }

    validiteSiret() {
        let ret = Siret.validateRegistrationNumber(
            this.formGeneral.get('numeroSIRET').value ? this.formGeneral.get('numeroSIRET').value.toString() : ''
        );
        if (!ret) {
            this.formGeneral.patchValue({ numeroSIRET: null });
            this.notificationService.warn("Le numéro de SIRET n'est pas valide.<br><br>veuillez renseigner un numéro SIRET valide.");
        }
    }

    private initFormGeneral() {
        this.formGeneral = this.formBuilder.group({
            nomEntreprise: this.formService.createFormControl(
                'nomEntreprise',
                new FormContext('nomEntreprise.valeur', this.ceeModel, [], this.ngUnsubscribe)
            ),
            nomEntrepriseNR: this.formService.createFormControl(
                'nomEntrepriseNR',
                new FormContext('nomEntreprise.checked', this.ceeModel, [], this.ngUnsubscribe)
            ),
            numeroSIRET: this.formService.createFormControl(
                'numeroSIRET',
                new FormContext('numeroSIRET.valeur', this.ceeModel, [], this.ngUnsubscribe)
            ),
            numeroSIRETNR: this.formService.createFormControl(
                'numeroSIRETNR',
                new FormContext('numeroSIRET.checked', this.ceeModel, [], this.ngUnsubscribe)
            ),
            entrepriseRGE: this.formService.createFormControl(
                'entrepriseRGE',
                new FormContext('entrepriseRGE.valeur', this.ceeModel, [], this.ngUnsubscribe)
            ),
            dateAcceptationDevis: this.formService.createFormControl(
                'dateAcceptationDevis',
                new FormContext('dateAcceptationDevis.valeur', this.ceeModel, [], this.ngUnsubscribe)
            ),
            dateAcceptationDevisNR: this.formService.createFormControl(
                'dateAcceptationDevisNR',
                new FormContext('dateAcceptationDevis.checked', this.ceeModel, [], this.ngUnsubscribe)
            ),
            dateVisitePrealable: this.formService.createFormControl(
                'dateVisitePrealable',
                new FormContext('dateVisitePrealable.valeur', this.ceeModel, [], this.ngUnsubscribe)
            ),
            dateVisitePrealableNR: this.formService.createFormControl(
                'dateVisitePrealableNR',
                new FormContext('dateVisitePrealable.checked', this.ceeModel, [], this.ngUnsubscribe)
            ),
            dateDebutTravaux: this.formService.createFormControl(
                'dateDebutTravaux',
                new FormContext('dateDebutTravaux.valeur', this.ceeModel, [], this.ngUnsubscribe)
            ),
            dateDebutTravauxNR: this.formService.createFormControl(
                'dateDebutTravauxNR',
                new FormContext('dateDebutTravaux.checked', this.ceeModel, [], this.ngUnsubscribe)
            ),
            refFacture: this.formService.createFormControl('refFacture', new FormContext('refFacture.valeur', this.ceeModel, [], this.ngUnsubscribe)),
            refFactureNR: this.formService.createFormControl(
                'refFactureNR',
                new FormContext('refFacture.checked', this.ceeModel, [], this.ngUnsubscribe)
            ),
            presenceDevis: this.formService.createFormControl(
                'presenceDevis',
                new FormContext('presenceDevis.valeur', this.ceeModel, Validators.required, this.ngUnsubscribe)
            ),
            presenceFacture: this.formService.createFormControl(
                'presenceFacture',
                new FormContext('presenceFacture.valeur', this.ceeModel, Validators.required, this.ngUnsubscribe)
            ),
        });
        this.formGeneral.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.checkValidity());
    }

    //Nom Entreprise
    nomEntrepriseNRchange(checked: boolean) {
        this.formGeneral.patchValue({ nomEntreprise: checked ? null : '' });
        this.ceeModel.nomEntreprise.validated = checked ? checked : false;
    }
    nomEntrepriseFocus() {
        this.formGeneral.patchValue({ nomEntrepriseNR: false });
    }

    //Numéro SIRET
    numeroSiretNRchange(checked: boolean) {
        this.formGeneral.patchValue({ numeroSIRET: checked ? null : '' });
        this.ceeModel.numeroSIRET.validated = checked ? checked : false;
    }
    numeroSiretFocus() {
        this.formGeneral.patchValue({ numeroSIRETNR: false });
    }

    //Date Visite Préalable
    dateVisitePrealableNRchange(checked: boolean) {
        this.formGeneral.patchValue({ dateVisitePrealable: checked ? null : '' });
        this.ceeModel.dateVisitePrealable.validated = checked ? checked : false;
    }
    dateVisitePrealableFocus() {
        this.formGeneral.patchValue({ dateVisitePrealableNR: false });
    }

    //Date Acceptation Devis
    dateAcceptationDevisNRchange(checked: boolean) {
        this.formGeneral.patchValue({ dateAcceptationDevis: checked ? null : '' });
        this.ceeModel.dateAcceptationDevis.validated = checked ? checked : false;
        this.ceeModel.dateAcceptationDevis.isAnomalie = !!checked;
    }
    dateAcceptationDevisFocus() {
        this.formGeneral.patchValue({ dateAcceptationDevisNR: false });
    }

    //Date Début des travaux
    dateDebutTravauxNRchange(checked: boolean) {
        this.formGeneral.patchValue({ dateDebutTravaux: checked ? null : '' });
        this.ceeModel.dateDebutTravaux.validated = checked ? checked : false;
    }
    dateDebutTravauxFocus() {
        this.formGeneral.patchValue({ dateDebutTravauxNR: false });
    }

    //Ref De La Facture
    referenceDeLaFactureNRchange(checked: boolean) {
        this.formGeneral.patchValue({ refFacture: checked ? null : '' });
        this.ceeModel.refFacture.validated = checked ? checked : false;
    }
    referenceDeLaFactureFocus() {
        this.formGeneral.patchValue({ refFactureNR: false });
    }

    addJustification(value: EtapeDiagnosticGenerique, typeNouveauCommentaire: string) {
        this.matDialog
            .open(CommentModalComponent, {
                ...DialogUtils.configFullScreen(),
                data: {
                    commentairesId: value?.commentairesId,
                    replaceTypesCommentaires: true,
                    typesCommentaires: [
                        enumTypesCommentaire.NOTE_PERSONNELLE,
                        enumTypesCommentaire.REMARQUE,
                        enumTypesCommentaire.JUSTIFICATION_NON_CONFORMITE,
                        enumTypesCommentaire.CONSTATATION_DIVERSE,
                    ],
                    typeNouveauCommentaire: typeNouveauCommentaire,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result && result !== false) {
                    value.commentairesId = result.commentairesId;
                }
            });
    }
    ngOnDestroy() {
        for (const property of ['dateVisitePrealable', 'dateAcceptationDevis', 'dateDebutTravaux', 'dateFacture', 'dateEtatRecapitulatif']) {
            if (this.ceeModel[property]?.valeur) {
                this.ceeModel[property].valeur = new Date(this.ceeModel[property].valeur);
            }
        }
        this.checkValidity(true);
        super.ngOnDestroy();
    }

    /**
     * @description Permet d'afficher le document séléctionner.
     * @param data Type document à faire passer
     */
    openDocument(data: Document) {
        if (data.nomFichier.split('.').pop() === 'pdf') {
            this.largeur = 700;
        } else {
            this.largeur = 'auto';
        }
        if (this.currentDocumentId !== data.id) {
            this.currentDocumentId = data.id;

            this.documentFileService.AfficheFileByFileId(data).subscribe(async (value) => {
                this.source = (await value.fileContent) ?? '';

                this.ngZone.runOutsideAngular(() => {
                    this.pdfSrc = this.sanitizer.bypassSecurityTrustHtml(
                        '<object width="100%" height="' + this.largeur + '" data="' + this.source + '"></object>'
                    );
                    this.titreModal = data.nomFichier;
                });
            });
        } else {
            this.currentDocumentId = null;
            this.pdfSrc = null;
        }
    }

    private checkValidity(force?: boolean) {
        const code = this.route.snapshot.data['code'];
        let etat: EtatValidation = 'INVALID';
        if (this.formGeneral?.valid) {
            etat = 'VALID';
            // Non rensignée
            // Vérification si un commentaire existe
            for (const property of Object.getOwnPropertyNames(this.formGeneral.value)) {
                if (this.ceeModel[property]?.checked === true && !this.ceeModel[property]?.commentairesId?.length) {
                    etat = 'INVALID';
                    break;
                }
            }
        }
        this.etatProgressionService.updateDiagnostic(code, etat, this.diagnostic, force);
    }
}
