import { environment } from 'src/environments/environment';

export class EnvironmentUtils {
    static afficherGestionReferentielsDashboard() {
        return !environment.cacherGestionReferentielsDashboard;
    }

    static afficherMissionsEnCoursDashboard() {
        return !environment.cacherMissionsEnCoursDashboard;
    }

    static getMessageNotificationModePlan(): string {
        return 'Fonctionnalité en cours de développement';
    }

    static afficherAgendaDashboard(): boolean {
        return !environment.cacherAgendaDashboard;
    }

    static afficherBoutonPhoto(): boolean {
        return !environment.cacherBoutonPhoto;
    }

    static afficherLotsInterventionsDashboard(): boolean {
        return !environment.cacherLotsInterventionsDashboard;
    }

    static afficherToDoListDashboard(): boolean {
        return !environment.cacherToDoListDashboard;
    }

    static afficherGestionInterventionsDashboard(): boolean {
        return !environment.cacherGestionInterventionsDashboard;
    }

    static afficherGestionBiensDashboard(): boolean {
        return !environment.cacherGestionBiensDashboard;
    }

    static afficherGestionCommandesDashboard(): boolean {
        return !environment.cacherGestionCommandesDashboard;
    }

    static afficherGestionSynthesesDashboard(): boolean {
        return !environment.cacherGestionSynthesesDashboard;
    }

    static afficherGestionContactsDashboard(): boolean {
        return !environment.cacherGestionContactsDashboard;
    }

    static afficherGestionEmployesDashboard(): boolean {
        return !environment.cacherGestionEmployesDashboard;
    }

    static afficherGestionAgencesDashboard(): boolean {
        return !environment.cacherGestionAgencesDashboard;
    }

    static afficherGestionCofracsDashboard(): boolean {
        return !environment.cacherGestionCofracsDashboard;
    }

    static afficherGestionBonsCommandeDashboard(): boolean {
        return !environment.cacherGestionBonsCommande;
    }

    static afficherGestionDocumentDashboard(): boolean {
        return !environment.cacherGestionDocuments;
    }

    static afficherGestionCommentairesPredefinis(): boolean {
        return !environment.cacherCommentairesPredefinis;
    }

    static getInterventionMinusDay(): number {
        return environment.interventionMinusDay;
    }

    static getInterventionPlusDay(): number {
        return environment.interventionPlusDay;
    }

    static afficherGestionLivrablesDashboard(): boolean {
        return !environment.cacherGestionLivrablesDashboard;
    }
}
