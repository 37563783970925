import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { CategorieEquipement } from 'src/app/model/categorie-equipement.model';
import { ReferenceApiService } from 'src/app/services/reference-api.service';
import { throwError } from 'rxjs';

@Component({
    selector: 'app-tree-categorie-equipements',
    templateUrl: './tree-categorie-equipements.component.html',
    styleUrls: ['./tree-categorie-equipements.component.scss'],
})
export class TreeCategorieEquipementsComponent implements OnInit {
    @Output() selectedCategorieEvent = new EventEmitter<CategorieEquipement>();

    @Input()
    idCategorieSelected: string;

    listeCategories: CategorieEquipement[];
    treeControl = new NestedTreeControl<CategorieEquipement>((node) => node.children);
    dataSource = new MatTreeNestedDataSource<CategorieEquipement>();
    activeNode: CategorieEquipement;
    initialized = false;

    constructor(private readonly referenceApiService: ReferenceApiService) {}

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this.referenceApiService.findAllCategorieEquipement().subscribe(
            (data) => {
                this.listeCategories = data.sort((a, b) => a.name.localeCompare(b.name));
                this.dataSource.data = this.createTree(this.listeCategories);
                this.treeControl.dataNodes = this.dataSource.data;

                if (this.idCategorieSelected) {
                    // déplie et sélectionne la catégorie en cours
                    let nodesSelect = this.findNodeAndParentsById(this.idCategorieSelected, this.treeControl.dataNodes);
                    this.expandNodes(nodesSelect);
                    this.selectNode(this.findNodeById(this.idCategorieSelected, this.treeControl.dataNodes));
                }
            },
            (error) => {
                if (error.status != 404) {
                    console.log(error);
                } else {
                    throwError(error);
                }
            },
            () => {}
        );
    }

    expandNodes(nodes: CategorieEquipement[]) {
        nodes.forEach((node) => {
            this.treeControl.expand(node);
        });
    }

    findNodeAndParentsById(idToFind: string, nodes: CategorieEquipement[]): CategorieEquipement[] {
        let ret: CategorieEquipement[] = [];
        if (nodes && nodes.length > 0) {
            for (const node of nodes) {
                if (node.id === idToFind) {
                    ret.push(node);
                } else if (node.children && node.children.length > 0) {
                    let n = this.findNodeAndParentsById(idToFind, node.children);
                    if (n && n.length > 0) {
                        ret = ret.concat(node);
                        ret = ret.concat(n);
                    }
                }
            }
        }
        return ret;
    }

    findNodeById(idToFind: string, rootNodes: CategorieEquipement[]): CategorieEquipement {
        if (rootNodes && rootNodes.length > 0) {
            for (const rootNode of rootNodes) {
                if (rootNode.id === idToFind) {
                    return rootNode;
                } else if (rootNode.children && rootNode.children.length > 0) {
                    let n = this.findNodeById(idToFind, rootNode.children);
                    if (n) {
                        return n;
                    }
                }
            }
        }
        return null;
    }

    selectNode(node: CategorieEquipement) {
        if (node) {
            this.activeNode = node;
            this.selectedCategorieEvent.emit(node);
        }
    }

    hasChild = (_: number, node: CategorieEquipement) => !!node.children && node.children.length > 0;

    createTree(data: CategorieEquipement[]): CategorieEquipement[] {
        let root: CategorieEquipement[] = [];

        const idMapping = data.reduce((acc, el, i) => {
            acc[el.id] = i;
            return acc;
        }, {});

        data.forEach((el) => {
            // Handle the root element
            if (el.parentsIds.length === 0) {
                root.push(el);
                return;
            }
            // Use our mapping to locate the parent element in our data array
            const parentEl = data[idMapping[el.parentsIds[0]]];
            if (parentEl != undefined) {
                // Add our current el to its parent's `children` array
                parentEl.children = [...(parentEl.children || []), el];
            } else {
                // on retrouve pas l'élément dans le mapping
                //console.log('element :');
                //console.log(el);
            }
        });

        return root;
    }

    onClickNode(cat: CategorieEquipement) {
        this.selectNode(cat);
    }
}
