import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CnDiagSharedModule } from '../../shared/shared.module';
import { DescriptionBienPageComponent } from './description-bien-page/description-bien-page.component';
import { LibFileUploaderModule, LibIconModule } from 'src/app/commons-lib';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipesModule } from '../../../pipes/pipes.module';
import { DescriptionBienModeMenuComponent } from './description-bien-mode-menu/description-bien-mode-menu.component';
import { MatListModule } from '@angular/material/list';
import { DescriptionBienChoixNiveauComponent } from './description-bien-choix-niveau/description-bien-choix-niveau.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DescriptionBienGestionNiveauComponent } from './description-bien-gestion-niveau/description-bien-gestion-niveau.component';
import { DescriptionBienListeVolumesComponent } from './description-bien-liste-volumes/description-bien-liste-volumes.component';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CeeModule } from '../../diagnostics/cee/cee.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { DescriptionBienDetailsPieceComponent } from './description-bien-details-piece/description-bien-details-piece.component';
import { DescriptionBienModalPieceComponent } from './description-bien-modal-piece/description-bien-modal-piece.component';
import { DescriptionBienModalEquipementsOuvragesComponent } from './description-bien-modal-equipements-ouvrages/description-bien-modal-equipements-ouvrages.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { DescriptionBienFilterTypePrestationsComponent } from './description-bien-filter-types-prestations/description-bien-filter-type-prestations.component';
import { DescriptionBienModalEquipementsComponent } from './description-bien-modal-equipements-ouvrages/description-bien-modal-equipements/description-bien-modal-equipements.component';
import { DescriptionBienModalOuvragesComponent } from './description-bien-modal-equipements-ouvrages/description-bien-modal-ouvrages/description-bien-modal-ouvrages.component';
import { DescriptionBienModalOuvragesPartiesComponent } from './description-bien-modal-equipements-ouvrages/description-bien-modal-ouvrages-parties/description-bien-modal-ouvrages-parties.component';
import { DescriptionBienModalOuvragesParametresComponent } from './description-bien-modal-equipements-ouvrages/description-bien-modal-ouvrages-edit/description-bien-modal-ouvrages-parametres.component';
import { DescriptionBienModalOuvragesEditNomComponent } from './description-bien-modal-equipements-ouvrages/description-bien-modal-ouvrages-edit-nom/description-bien-modal-ouvrages-edit-nom.component';
import { DescriptionBienPlanDetailsPieceComponent } from './description-bien-plan-details-piece/description-bien-plan-details-piece.component';
import { MatBadgeModule } from '@angular/material/badge';
import { DescriptionBienFusionVolumeComponent } from './description-bien-fusion-volume/description-bien-fusion-volume.component';
import { Intervention, RelationInterventionBien } from '../../../model/intervention.model';
import { AddNiveauModalComponent } from './add-niveau-modal/add-niveau-modal.component';
import { MatMenuModule } from '@angular/material/menu';
import { DescriptionBienModalOuvragesService } from './description-bien-modal-equipements-ouvrages/description-bien-modal-ouvrages.service';
import { CopierCollerOuvragesComponent } from './description-bien-modal-equipements-ouvrages/copier-coller-ouvrages/copier-coller-ouvrages.component';
import { Diagnostic } from '../../../model/diagnostic.model';
import { PerimetreInvestigation } from '../../../model/perimetre-investigation.model';
import { DescriptionBienModalOuvragesDetailsComponent } from './description-bien-modal-equipements-ouvrages/description-bien-modal-ouvrages-details/description-bien-modal-ouvrages-details.component';

// NB : Les fonctions passées dans l'objet "data" de la description du routage doivent être donc fonctions nommés exportées, sinon le code ne compile pas
export function interventionRouteDescriptionIterateFunction(context: { currentIntervention: Intervention }): RelationInterventionBien[] {
    return context.currentIntervention.relationInterventionBiens.filter((b) => !b.isBienPrincipal);
}

export function interventionRouteDescriptionIteratePath(relationInterventionBien: RelationInterventionBien): string {
    return relationInterventionBien.bien.id;
}

export function interventionRouteDescriptionIterateLabel(relationInterventionBien: RelationInterventionBien): string {
    return relationInterventionBien.bien.nom;
}

export function interventionRouteDescriptionIterateSuffix(relationInterventionBien: RelationInterventionBien): string {
    return '_' + relationInterventionBien.bien.id;
}

export function zoneRouteIterateFunction(context: { currentIntervention: Intervention; currentDiagnostic: Diagnostic }): PerimetreInvestigation[] {
    const diag: any = context.currentDiagnostic?.contenuDiagnostic;
    return diag?.perimetresInvestigation?.data?.listePerimetreInvestigation ?? [];
}

export function zoneRouteIterateIteratePath(perInves: PerimetreInvestigation): string {
    return perInves.id;
}

export function zoneRouteIterateIterateLabel(perInves: PerimetreInvestigation): string {
    return perInves.nom;
}

export function zoneRouteIterateIterateSuffix(perInves: PerimetreInvestigation): string {
    return '_' + perInves.id;
}

@NgModule({
    imports: [
        CommonModule,
        CnDiagSharedModule,
        LibIconModule,
        MatButtonToggleModule,
        MatDividerModule,
        MatIconModule,
        MatSidenavModule,
        MatTooltipModule,
        PipesModule,
        MatListModule,
        DragDropModule,
        MatButtonModule,
        MatSlideToggleModule,
        LibFileUploaderModule,
        MatCardModule,
        MatProgressBarModule,
        CeeModule,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        OverlayModule,
        MatBadgeModule,
        MatMenuModule,
    ],
    declarations: [
        DescriptionBienPageComponent,
        DescriptionBienModeMenuComponent,
        DescriptionBienChoixNiveauComponent,
        AddNiveauModalComponent,
        DescriptionBienGestionNiveauComponent,
        DescriptionBienListeVolumesComponent,
        DescriptionBienDetailsPieceComponent,
        DescriptionBienModalPieceComponent,
        DescriptionBienModalEquipementsOuvragesComponent,
        DescriptionBienModalEquipementsComponent,
        DescriptionBienModalOuvragesComponent,
        DescriptionBienModalOuvragesPartiesComponent,
        DescriptionBienModalOuvragesParametresComponent,
        DescriptionBienModalOuvragesDetailsComponent,
        DescriptionBienModalOuvragesEditNomComponent,
        DescriptionBienFilterTypePrestationsComponent,
        DescriptionBienPlanDetailsPieceComponent,
        DescriptionBienFusionVolumeComponent,
        CopierCollerOuvragesComponent,
    ],

    providers: [DescriptionBienModalOuvragesService],
})
export class DescriptionBienModule {}
