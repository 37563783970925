import { Prelevement } from '../model/prelevement-generique.model';

/**
 * Utilitaire lié aux tableaux
 */
export class ArrayUtils {
    static getIntersectionArray(array1: any[], array2: any[]): any[] {
        return array1.filter((item1) => array2.indexOf(item1) !== -1);
    }

    static hasIntersectionBetweenArrays(array1: any[], array2: any[]): boolean {
        return this.getIntersectionArray(array1, array2).length > 0;
    }
}

export function removeItemFromList<T>(currentList: T[], item: T, predicate: (a: T, b: T) => boolean) {
    if (currentList) {
        const index = currentList.findIndex((it) => predicate(it, item));
        // suppression effectives de ces éléments
        if (index > -1) {
            currentList.splice(index, 1);
        }
    }
}
