<div class="sequence-modal d-flex flex-column h-100" [formGroup]="formDoc">
    <div class="d-flex w-100 align-items-baseline underlined pt-2">
        <h3 class="flex-grow-1 text-center">Ajouter une séquence</h3>
        <button mat-icon-button color="primary" [mat-dialog-close]="">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div class="error" *ngIf="formDoc.status == 'INVALID'">
        {{ formDoc?.errors?.customError?.message }}
    </div>

    <div mat-dialog-content class="mt-4">
        <div class="d-flex">
            <mat-form-field class="ml-3">
                <mat-label>Date début: </mat-label>
                <input matInput readonly formControlName="dateDebut" [ngxMatDatetimePicker]="datepickerDebut" />
                <mat-datepicker-toggle matSuffix [for]="datepickerDebut"> </mat-datepicker-toggle>
                <ngx-mat-datetime-picker #datepickerDebut [showSeconds]="false"></ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
        <div class="d-flex">
            <mat-form-field class="ml-3">
                <mat-label>Date fin: </mat-label>
                <input matInput readonly formControlName="dateFin" [ngxMatDatetimePicker]="datepickerFin" />
                <mat-datepicker-toggle matSuffix [for]="datepickerFin"> </mat-datepicker-toggle>
                <ngx-mat-datetime-picker #datepickerFin [showSeconds]="false"></ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
    </div>

    <div mat-dialog-actions class="justify-content-end">
        <button
            [disabled]="formDoc.status == 'INVALID'"
            mat-raised-button
            color="primary"
            [mat-dialog-close]="formDoc.value"
        >
            <mat-icon>check</mat-icon>
            <span>Confirmer</span>
        </button>
    </div>
</div>
