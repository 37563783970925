<mat-card
    class="d-flex flex-column align-items-center justify-content-between card-custom px-0 py-1"
    [ngClass]="reportagePhotoImageFile.selected ? 'card-selected' : ''"
>
    <div (click)="onClickSelectImage()" class="d-flex flex-grow-1 align-items-center">
        <img [src]="reportagePhotoImageFile.fileContent" alt="image" class="img-fluid img-size" />
        <div *ngIf="reportagePhotoImageFile.selected" class="selected-icon">
            <div class="d-flex align-items-center justify-content-center h-100 w-100">
                <mat-icon matBadgePosition="below after"> done</mat-icon>
            </div>
        </div>
    </div>
    <mat-form-field class="w-100 px-1" appearance="outline">
        <mat-label>Légende</mat-label>
        <input matInput class="legend" [(ngModel)]="reportagePhotoImageFile.legende" (ngModelChange)="onLegendChanged($event)" />
    </mat-form-field>
</mat-card>
