<div class="pb-4">
    <div class="block-header p-5 w-100 d-flex">
        <div class="block-icon flex-grow-1">
            <img src="../../../../../../../assets/img/logo-report.png" alt="report logo" />
        </div>
        <div class="block-label">Dossier N° : {{ numDossier }}</div>
    </div>
    <div class="pl-5 py-4">
        <div class="block-title-content pl-3 py-2">
            <div class="block-label" *ngIf="data">{{ data.title }}</div>
            <div class="block-label-2 mt-2" *ngIf="subTitle">{{ subTitle }}</div>
        </div>
    </div>
    <ng-container *ngIf="cofrac !== undefined">
        <div class="block-category p-2 pl-5 d-inline-block">SYNTHÈSE</div>
        <div class="block-synthese px-5 py-4 d-flex align-items-center">
            <div *ngIf="cofrac">
                <img src="../../../../../../../assets/img/logo-cofrac.png" width="15%" alt="cofrac logo" />
            </div>
            <div class="block-label mr-5 text-justify">
                <span class="{{ messages[0].class }}">{{ messages[0].text }} </span>
                <span class="{{ cofracValeur.class }}">{{ cofracValeur.text }} </span>
                <span class="{{ numCofrac.class }}">{{ numCofrac.text }} </span>
            </div>
        </div>
    </ng-container>
</div>
