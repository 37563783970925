<div [formGroup]="group">
    <div *ngIf="pageControlsConf?.format?.displayed">
        <mat-form-field appearance="fill">
            <mat-label>Format</mat-label>
            <mat-select formControlName="format" [compareWith]="compareWith">
                <mat-option *ngFor="let format of formats" [value]="format.value">
                    {{ format.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
