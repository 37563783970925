/**
 * Raison annulation.
 */
export class RaisonAnnulation {
    id: string;

    /**
     * Raison
     */
    raison: string;

    /**
     * Commentaire (facultatif)
     */
    commentaire?: string;

    constructor() {}
}
