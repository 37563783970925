import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { Intervention } from 'src/app/model/intervention.model';
import { IReportComponent, ReportBlockData, InterventionReportData } from 'src/app/model/rapport.model';
import { DiagnosticService } from 'src/app/services/diagnostic.service';
import { HapBonCommandeData } from '../../../../model/hap-bon-commande.model';
import { TypeEchantillonage } from '../../../../../../../model/prelevement-generique.model';

@Component({
    selector: 'app-echantillon-commande-block',
    templateUrl: './echantillon-commande-block.component.html',
    styleUrls: ['./echantillon-commande-block.component.scss'],
})
export class EchantillonCommandeBlockComponent extends BaseComponent implements OnInit, IReportComponent {
    @Input() data: ReportBlockData;
    @Input() intervention: Intervention;
    @Input() interReportData: InterventionReportData;

    private diagnostic: Diagnostic;

    diagnosticData: HapBonCommandeData;

    readonly enumTypeEchantillonage = TypeEchantillonage;
    constructor(private readonly diagnosticService: DiagnosticService) {
        super();
    }

    ngOnInit(): void {
        this.diagnosticService.getCurrentDiagnostic().subscribe((diag) => {
            this.diagnostic = diag;
            if (this.interReportData) {
                this.diagnosticData = this.interReportData.diagnostics.find(
                    (dia) => dia.typePrestation === this.diagnostic.typePrestation
                ) as HapBonCommandeData;
            }
        });
    }
}
