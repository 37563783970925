import { Component } from '@angular/core';
import { CnSpinnerService } from './service/cn-spinner.service';

@Component({
    selector: 'app-cn-spinner',
    templateUrl: './cn-spinner.component.html',
    styleUrls: ['./cn-spinner.component.scss'],
})
export class CnSpinnerComponent {
    label$ = this.cnSpinnerService.label$;
    display$ = this.cnSpinnerService.displaySpinner$;

    constructor(private cnSpinnerService: CnSpinnerService) {}
}
