import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MentionsLegalesComponent } from './mentions-legales.component';
import { LibMarkdownModule } from '../markdown/markdown.module';

@NgModule({
    imports: [CommonModule, LibMarkdownModule],
    declarations: [MentionsLegalesComponent],
})
export class LibMentionsLegalesModule {
    static forRoot(): ModuleWithProviders<LibMentionsLegalesModule> {
        return {
            ngModule: LibMentionsLegalesModule,
        };
    }
}
