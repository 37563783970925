import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { Bien, Niveau, Volume } from '../../../../model/bien.model';
import { PARAM_VOLUME_VISITE, VOLUME_VISITE } from '../../../../shared/constants/cndiag.constants';
import { DescriptionBienModalPieceComponent } from '../description-bien-modal-piece/description-bien-modal-piece.component';
import { MatDialog } from '@angular/material/dialog';
import { CommentModalComponent } from '../../../shared/comment-modal/comment-modal.component';
import { DialogUtils } from '../../../../utils/dialog.utils';
import { ConfirmDialogComponent } from '../../../../lib/confirmation/confirm-dialog.component';
import { DescriptionBienService } from '../../../../services/description-bien.service';
import { Intervention } from '../../../../model/intervention.model';
import { notValidatedOption, warningOption } from '../../../../shared/constants/states.constants';
import { NiveauModalComponent } from '../../../shared/niveau-modal/niveau-modal.component';
import { filter, switchMap, tap } from 'rxjs/operators';
import { MergeBienService } from '../../../../services/merge-bien.service';
import { NotificationService } from '@acenv/commons-lib';
import { combineLatest } from 'rxjs';
import { ReferenceService } from '../../../../services/reference.service';

@Component({
    selector: 'app-description-bien-plan-details-piece',
    templateUrl: './description-bien-plan-details-piece.component.html',
    styleUrls: ['./description-bien-plan-details-piece.component.scss'],
})
export class DescriptionBienPlanDetailsPieceComponent extends BaseComponent {
    @Input()
    readonlyMode: boolean = false;

    @Input()
    intervention: Intervention;

    @Input()
    currentBien: Bien;

    @Input()
    currentVolume: Volume;

    @Input()
    currentNiveau: Niveau;

    @Input()
    commentairesId: string[];

    @Output()
    refreshJustificationsVolumes: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    clickRetour: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    refreshCnBuilding: EventEmitter<boolean> = new EventEmitter<boolean>();

    readonly VOLUME_VISITE = VOLUME_VISITE;
    readonly PARAM_VOLUME_VISITE = PARAM_VOLUME_VISITE;

    protected readonly warningOption = warningOption;
    protected readonly notValidatedOption = notValidatedOption;

    constructor(
        private readonly dialog: MatDialog,
        private readonly descriptionBienService: DescriptionBienService,
        private readonly mergeBienService: MergeBienService,
        private readonly notificationService: NotificationService
    ) {
        super();
    }

    handleEditerVolume() {
        const listNomsVolumes = this.currentNiveau.volumes
            .filter((it) => it.id != this.currentVolume.id)
            .map((it) => it.nom.toLocaleUpperCase().trim());
        this.dialog
            .open(DescriptionBienModalPieceComponent, {
                width: '600px',
                data: {
                    volume: this.currentVolume,
                    niveau: this.currentNiveau,
                    listNomsVolumes: listNomsVolumes,
                    bien: this.currentBien,
                },
            })
            .afterClosed()
            .pipe(
                filter((volumeResult) => !!volumeResult),
                switchMap(() => this.descriptionBienService.editVolumeInMap(this.currentBien, this.currentNiveau, this.currentVolume)),
                filter((it) => !!it)
            )
            .subscribe(() => this.refreshCnBuilding.emit(true));
    }

    handleCommenterVolume() {
        const typesCommentaires = ['JUSTIFICATION_NON_VISITE', 'CONSTATATION_DIVERSE', 'NOTE_PERSONNELLE'];
        this.dialog
            .open(CommentModalComponent, {
                ...DialogUtils.configFullScreen(),
                data: {
                    commentairesId: this.currentVolume.commentairesId,
                    typesCommentaires: typesCommentaires,
                    replaceTypesCommentaires: true,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result && result !== false) {
                    this.currentVolume.commentairesId = result.commentairesId;
                    this.refreshJustificationsVolumes.emit();
                }
            });
    }

    handleClickVolumeVisite(nouvelEtatVolumeVisite) {
        if (this.currentVolume.valeursParametres[PARAM_VOLUME_VISITE] != VOLUME_VISITE.OK) {
            const justificationNonVisiteVolume = this.intervention.commentaires.filter(
                (it) => it.type === 'JUSTIFICATION_NON_VISITE' && this.currentVolume.commentairesId.includes(it.id)
            );
            if (justificationNonVisiteVolume.length > 0) {
                const message = 'Voulez-vous également supprimer la justification de non visite ?';
                this.dialog
                    .open(ConfirmDialogComponent, {
                        data: {
                            message,
                            confirmLabel: 'Oui',
                            cancelLabel: 'Non',
                        },
                    })
                    .afterClosed()
                    .subscribe((result) => {
                        if (result) {
                            this.descriptionBienService.supprimerCommentairesFromVolume(this.currentVolume, ['JUSTIFICATION_NON_VISITE']);
                        }
                        this.changeVolumeVisite(nouvelEtatVolumeVisite);
                    });
            } else {
                this.changeVolumeVisite(nouvelEtatVolumeVisite);
            }
        } else {
            this.changeVolumeVisite(nouvelEtatVolumeVisite);
        }
    }

    private changeVolumeVisite(nouvelEtatVolumeVisite: VOLUME_VISITE) {
        this.currentVolume.valeursParametres[PARAM_VOLUME_VISITE] = nouvelEtatVolumeVisite;
        this.refreshJustificationsVolumes.emit();
    }

    handleClickRetour() {
        this.clickRetour.emit();
    }

    handleMergeVolume() {
        this.descriptionBienService.setVolumeToMerge(this.currentVolume);
        this.handleClickRetour();
    }

    addJustificationNonVisite() {
        this.dialog
            .open(CommentModalComponent, {
                ...DialogUtils.configFullScreen(),
                data: {
                    commentairesId: this.currentVolume.commentairesId,
                    typesCommentaires: ['JUSTIFICATION_NON_VISITE'],
                    typeNouveauCommentaire: 'JUSTIFICATION_NON_VISITE',
                    replaceTypesCommentaires: true,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result && result !== false) {
                    this.currentVolume.commentairesId = result.commentairesId;
                    this.refreshJustificationsVolumes.emit();
                }
            });
    }

    handleDeplacerVolume() {
        this.dialog
            .open(NiveauModalComponent, {
                data: {
                    currentBien: this.currentBien,
                    currentNiveau: this.currentNiveau,
                },
            })
            .afterClosed()
            .pipe(
                filter((res) => res && res !== false),
                switchMap((niveauCible) => {
                    let updatedInterventionDiag = this.mergeBienService.transfertVolumeToNiveau(
                        this.currentBien,
                        this.currentVolume,
                        niveauCible,
                        this.intervention
                    );
                    return updatedInterventionDiag;
                }),
                tap(() => {
                    this.notificationService.success("Volume déplacé. L'intervention a été modifiée");
                    this.refreshJustificationsVolumes.emit();
                })
            )
            .subscribe();
    }
}
