export class Siret {
    public static validateRegistrationNumber(registrationNumber: string): boolean {
        return (
            registrationNumber.length === 14 &&
            registrationNumber.split('').reduce((sum, char, i) => {
                let digit = parseInt(char, 10) * ((i % 2) + 1);
                return sum + (digit > 9 ? digit - 9 : digit);
            }, 0) %
                10 ===
                0
        );
    }
}
