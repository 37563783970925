<app-mpca-form-skeleton>
    <div title>Informations pour METOP</div>
    <div form>
        <div [formGroup]="formMetop" class="form-metop">
            <!-- ACTIVITE CONNEXE -->
            <mat-form-field appearance="outline">
                <mat-label>Activité adjacente</mat-label>
                <input matInput required formControlName="activiteAdjacente" [readonly]="disabled" />
            </mat-form-field>

            <!-- CONFINEMENT -->
            <mat-form-field appearance="outline">
                <mat-label>Confinement</mat-label>
                <input matInput required formControlName="confinement" [readonly]="disabled" />
            </mat-form-field>

            <!-- NOMBRE D'EXTRACTEURS -->
            <mat-form-field appearance="outline">
                <mat-label>Nombre d'extracteurs</mat-label>
                <input matInput required formControlName="nombreExtracteurs" [readonly]="disabled" />
            </mat-form-field>

            <!-- SAS MATERIEL -->
            <mat-form-field appearance="outline">
                <mat-label>Sas matériel</mat-label>
                <input matInput required formControlName="sasMateriel" [readonly]="disabled" />
            </mat-form-field>

            <!-- SAS PERSONNEL -->
            <mat-form-field appearance="outline">
                <mat-label>Sas personnel</mat-label>
                <input matInput required formControlName="sasPersonnel" [readonly]="disabled" />
            </mat-form-field>

            <!-- ACTIVITE AMIANTE -->
            <mat-form-field appearance="outline">
                <mat-label>Activité amiante</mat-label>
                <input matInput required formControlName="activiteAmiante" [readonly]="disabled" />
            </mat-form-field>

            <!-- ZONE DE RECUPERATION -->
            <mat-form-field appearance="outline">
                <mat-label>Zone de récupération</mat-label>
                <input matInput required formControlName="zoneRecuperation" [readonly]="disabled" />
            </mat-form-field>

            <!-- ZONE STOCKAGE DECHETS -->
            <mat-form-field appearance="outline">
                <mat-label>Zone de stockage des déchets</mat-label>
                <input matInput required formControlName="zoneStockageDechets" [readonly]="disabled" />
            </mat-form-field>
        </div>
    </div>
</app-mpca-form-skeleton>
