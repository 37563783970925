import { Pipe, PipeTransform } from '@angular/core';

/**
 * Affiche le nom d'un fichier
 */
@Pipe({
    name: 'fileName',
})
export class FileNamePipe implements PipeTransform {
    constructor() {}

    transform(filename: string): string {
        return filename.substring(filename.lastIndexOf('\\') + 1, filename.lastIndexOf('.'));
    }
}
