import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent } from '../../../lib/utils/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Bien, Niveau } from '../../../model/bien.model';

class NiveauModalData {
    constructor(public currentNiveau: Niveau, public currentBien: Bien) {}
}

@Component({
    selector: 'app-niveau-modal',
    templateUrl: './niveau-modal.component.html',
    styleUrls: ['./niveau-modal.component.scss'],
})
export class NiveauModalComponent extends BaseComponent implements OnInit {
    form: FormGroup;
    listNiveau: Niveau[];

    constructor(
        @Inject(MAT_DIALOG_DATA) private readonly data: NiveauModalData,
        private readonly dialogRef: MatDialogRef<NiveauModalComponent>,
        private formBuilder: FormBuilder
    ) {
        super();
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            selectedNiveauControl: ['', Validators.required],
        });
        if (this.data) {
            let currentNiveau = this.data.currentNiveau || null;
            let currentBien = this.data.currentBien || null;
            this.listNiveau = currentBien
                ? currentBien.description.filter((niv) => niv.id !== currentNiveau?.id) //&& niv.storeyId == null
                : [];
        }
    }

    onClickClose() {
        this.dialogRef.close(false);
    }

    onClickConfirm() {
        this.dialogRef.close(this.form.get('selectedNiveauControl').value);
    }
}
