import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/commons-lib';

@Component({
    selector: 'app-export-svg-subform-equipments',
    templateUrl: './export-svg-subform-equipments.component.html',
    styleUrls: ['./export-svg-subform-equipments.component.scss'],
})
export class ExportSvgSubformEquipmentsComponent extends BaseComponent implements OnInit {
    @Input() group: FormGroup;
    @Input() formConf: any = {};

    get equipmentsConformiteConf() {
        return this.formConf.equipment.groups.conformite;
    }

    constructor() {
        super();
    }

    ngOnInit(): void {}
}
