<div *ngIf="refPrestations && refPrestationsInter" class="d-flex align-items-center flex-grow-0 px-2 filters-presta">
    <div
        *ngFor="let refPresta of selectedRefPrestations"
        class="
            bg-primary
            c-pointer
            px-1
            mx-1
            rounded-circle
            border
            filter-diag
            d-flex
            justify-content-center
            align-items-center
        "
        matTooltip="{{ refPresta.typePrestation | typePrestation }}"
        (click)="handleClickFilterDiag(refPresta)"
    >
        <ng-container *ngIf="refPresta | pictoPrestation | async as fileContent">
            <img *ngIf="fileContent" [src]="fileContent" alt="" class="rounded rounded-circle" />
        </ng-container>
    </div>
    <ng-container *ngFor="let refPresta of refPrestationsInter">
        <div
            *ngIf="!selectedRefPrestations.includes(refPresta)"
            class="
                bg-light
                c-pointer
                px-1
                mx-1
                rounded-circle
                border
                filter-diag
                d-flex
                justify-content-center
                align-items-center
            "
            matTooltip="{{ refPresta.typePrestation | typePrestation }}"
            (click)="handleClickFilterDiag(refPresta)"
        >
            <ng-container *ngIf="refPresta | pictoPrestation | async as fileContent">
                <img *ngIf="fileContent" [src]="fileContent" alt="" class="rounded rounded-circle" />
            </ng-container>
        </div>
    </ng-container>
    <button
        *ngIf="!displayAllRefPresta"
        mat-icon-button
        matTooltip="Afficher les autres prestations"
        (click)="displayAllRefPresta = !displayAllRefPresta"
    >
        <mat-icon class="icon-small">chevron_right</mat-icon>
    </button>
    <button
        *ngIf="displayAllRefPresta"
        mat-icon-button
        matTooltip="Cacher les autres prestations"
        (click)="displayAllRefPresta = !displayAllRefPresta"
    >
        <mat-icon class="icon-small">chevron_left</mat-icon>
    </button>
    <ng-container *ngIf="displayAllRefPresta">
        <ng-container *ngFor="let refPresta of refPrestations">
            <div
                *ngIf="!refPrestationsInter.includes(refPresta) && !selectedRefPrestations.includes(refPresta)"
                class="
                    bg-light
                    c-pointer
                    px-1
                    mx-1
                    rounded-circle
                    border
                    filter-diag
                    d-flex
                    justify-content-center
                    align-items-center
                "
                matTooltip="{{ refPresta.typePrestation | typePrestation }}"
                (click)="handleClickFilterDiag(refPresta)"
            >
                <ng-container *ngIf="refPresta | pictoPrestation | async as fileContent">
                    <img *ngIf="fileContent" [src]="fileContent" alt="" class="rounded rounded-circle" />
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
</div>
