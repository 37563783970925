import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/commons-lib';
import { UserWizy } from 'src/app/model/user-wizy.model';
import { URL_MON_COMPTE_PROFIL } from 'src/app/shared/constants/url.constants';
import { StringUtils } from 'src/app/utils/string.utils';

@Component({
    selector: 'app-vignette-profil',
    templateUrl: './vignette-profil.component.html',
    styleUrls: ['./vignette-profil.component.scss'],
})
export class VignetteProfilComponent extends BaseComponent implements OnInit {
    @Input()
    set currentUser(user: UserWizy) {
        if (user) {
            this.textVignette = StringUtils.getInitialUser(user);
        }
    }

    textVignette = '';

    constructor(private readonly router: Router) {
        super();
    }

    ngOnInit(): void {}

    onClickEspacePersoOperateur() {
        this.router.navigate([URL_MON_COMPTE_PROFIL]);
    }
}
