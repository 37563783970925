import { Injectable } from '@angular/core';

import { Intervention } from '../../../../model/intervention.model';
import * as moment from 'moment';
import { Electricite, ElectriciteConfig } from '../model/electricite.model';
import { ElectriciteApiService } from './electricite-api.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { CustomSelectGroup, ItemCustomSelect } from 'src/app/modules/shared/custom-select/custom-select.model';

/**
 * Service pour le diagnostic électricité.
 */
@Injectable({
    providedIn: 'root',
})
export class ElectriciteService {
    electriciteConfig$ = new BehaviorSubject<ElectriciteConfig>(undefined);

    constructor(private electriciteApiService: ElectriciteApiService) {}

    /**
     * Préparation du formulaire Electricite
     */
    prepareFormElectricite(intervention: Intervention, contenuDiagnostic: Electricite) {
        // la date d'installation doit être convertie en string pour fonctionner avec indexedDB
        // if (contenuDiagnostic.dateInstallation.valeur) {
        //   contenuDiagnostic.dateInstallation.valeur = moment(contenuDiagnostic.dateInstallation.valeur).toISOString();
        //}
    }

    initConfig() {
        if (!this.electriciteConfig$.getValue())
            this.electriciteApiService
                .findAllElectriciteConfigOffline()
                .pipe(map((configs) => configs[0]))
                .subscribe((config) => {
                    console.info(config);
                    this.electriciteConfig$.next(config);
                });
    }

    getItemsLocalisationCommentaire(intervention: Intervention): CustomSelectGroup[] {
        let menus: CustomSelectGroup[] = [];
        intervention.relationInterventionBiens.forEach((relationInterBien) => {
            relationInterBien.bien.description.forEach((niveau) => {
                const menu = new CustomSelectGroup();
                menu.label = relationInterBien.bien.nom + '_' + niveau.nom;
                niveau.volumes.forEach((volume) => {
                    const m = new ItemCustomSelect();
                    m.id = volume.id;
                    m.label = volume.nom;
                    menu.items = menu.items.concat(m);
                });
                menus = menus.concat(menu);
            });
        });
        return menus;
    }
}
