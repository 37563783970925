import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent, ConfirmationService, NotificationService } from 'src/app/commons-lib';
import { AgenceService } from '../../../../services/agence.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CnSpinnerService } from '../../../shared/cn-spinner/service/cn-spinner.service';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Agence, AgenceMinimal, Assurance, TypeAgence } from '../../../../model/agence.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EditMode } from '../../../../model/edit-mode.model';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { ManagementApiService } from '../../../../services/management-api.service';
import { UserWizy } from '../../../../model/user-wizy.model';
import { Adresse } from '../../../../model/adresse.model';
import { MatDialog } from '@angular/material/dialog';
import { CreationAssuranceModalComponent } from './tableau-assurance/creation-assurance/creation-assurance-modal.component';
import { URL_GESTION_AGENCES, URL_GESTION_AGENCES_EDIT } from '../../../../shared/constants/url.constants';
import { CofracService } from 'src/app/services/cofrac.service';
import { Cofrac } from 'src/app/model/cofrac.model';
import { ValidatorUtils } from 'src/app/utils/validator.utils';
import { REGEX_TELEPHONE } from '../../../../shared/constants/cndiag.constants';
import { FormService } from '../../../../services/form.service';
import { AgenceApiService } from '../../../../services/agence-api.service';
import { DISPLAY_FN_NAME, DISPLAY_FN_USER } from '../../../../utils/display-function.utils';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
    selector: 'app-creation-agences',
    templateUrl: './creation-agence.component.html',
    styleUrls: ['./creation-agence.component.scss'],
})
export class CreationAgenceComponent extends BaseComponent implements OnInit, OnDestroy {
    formAgence: FormGroup;
    agence: Agence;
    readonlyMode = false;
    mode: EditMode = 'CREATE';
    assurances: Assurance[] = [];
    responsable: UserWizy;
    cofracs: Cofrac[] = [];

    // Dropdown
    isSearchingAgences = this.formService.isSearchingAgences$;
    isSearchingResponsables = this.formService.isSearchingUsers$;
    filteredSearchAgences: Agence[];
    filteredSearchResponsables: UserWizy[];

    hasInfosParent = false;

    readonly displayFnName = DISPLAY_FN_NAME;
    readonly displayFnUser = DISPLAY_FN_USER;
    readonly codeTypeDocument = 'ATTESTATION_HONNEUR';
    readonly documentTitle = "Attestation sur l'honneur";
    private idAgence: string;

    get attestationHonneurControl() {
        return this.formAgence.get('others').get('attestationFile') as FormControl;
    }

    constructor(
        private readonly agenceService: AgenceService,
        private readonly agenceApiService: AgenceApiService,
        private readonly cofracService: CofracService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly notificationService: NotificationService,
        private readonly formBuilder: FormBuilder,
        private readonly managementApiService: ManagementApiService,
        private readonly dialog: MatDialog,
        private readonly confirmationService: ConfirmationService,
        private readonly formService: FormService
    ) {
        super();
        this.createForm();
    }

    ngOnInit(): void {
        // Initialise les données (récupération de l'agence et du contenu des listes déroulantes)
        // Puis initialise le formulaire
        this.cnSpinnerService
            .withSpinner(
                combineLatest([this.route.paramMap, this.route.data]).pipe(
                    takeUntil(this.ngUnsubscribe),

                    switchMap(([params, data]) => {
                        this.idAgence = params.get('idAgence');
                        if (this.idAgence) {
                            this.mode = 'EDIT';
                            this.readonlyMode = data.consulter;
                            return this.agenceService.findOne(this.idAgence).pipe(
                                map((currentAgence) => {
                                    if (currentAgence) {
                                        this.agence = currentAgence;
                                        this.hasInfosParent = this.agence.hasInfosParent;
                                    }
                                    return currentAgence;
                                })
                            );
                        } else {
                            this.agence = new Agence();
                            this.agence.typeAgence = TypeAgence.AGENCE;
                            return of(null);
                        }
                    }),
                    switchMap((currentAgence) =>
                        forkJoin([
                            of(currentAgence),
                            currentAgence && currentAgence.responsableAgence ? of(currentAgence.responsableAgence) : of(null),
                            this.cofracService.findAllCofracs(),
                        ])
                    )
                )
            )
            .subscribe(([, responsable, cofracs]) => {
                // Initialise le formulaire avec les données
                this.responsable = responsable;
                this.cofracs = cofracs;
                this.populateForm(this.agence);
                this.updateFormGroupStates();
            });

        this.search();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    /**
     * Crée le formulaire d'Agence
     */
    createForm(): void {
        this.formAgence = this.formBuilder.group({
            general: this.formBuilder.group({
                idSalesforce: [''],
                nom: ['', [Validators.required]],
                agenceAppartenance: ['', [ValidatorUtils.entityMustExist("Aucune agence n'existe avec ce nom.")]],
                responsableAgence: ['', [Validators.required, ValidatorUtils.entityMustExist('Veuillez sélectionner un responsable.')]],
                fax: ['', [Validators.pattern(REGEX_TELEPHONE)]],
                email: ['', [Validators.email]],
                telephone: ['', [Validators.pattern(REGEX_TELEPHONE)]],
            }),
            adresse: this.formBuilder.group({
                voie: ['', Validators.required],
                complementAdresse1: [''],
                codePostal: ['', Validators.required],
                ville: ['', Validators.required],
            }),
            cofracs: [[], [ValidatorUtils.validateCofrac]],
            legals: this.formBuilder.group({
                siret: [''],
                siren: ['', ValidatorUtils.emptyOr([Validators.minLength(9), Validators.maxLength(9)])],
                codeApe: ['', Validators.required],
                codeNaf: ['', Validators.required],
                tva: [''],
            }),
            others: this.formBuilder.group({
                attestationFile: [null, Validators.required],
            }),
        });
        const sirenControl = this.formAgence.get('legals').get('siren');
        const siretValidators = [
            ValidatorUtils.emptyOr([
                Validators.minLength(14),
                Validators.maxLength(14),
                ValidatorUtils.mustStartWith(sirenControl, 'Le début du champ SIRET doit être identique au SIREN'),
            ]),
        ];
        this.formAgence.get('legals').get('siret').addValidators(siretValidators);
        this.formAgence
            .get('legals')
            .get('siren')
            .valueChanges.pipe(
                tap(() => this.formAgence.get('legals').get('siret').updateValueAndValidity()),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe();
        this.formAgence
            .get('general')
            .get('agenceAppartenance')
            .valueChanges.pipe(
                filter((agence) => this.hasInfosParent && !this.readonlyMode && !!agence?.id),
                switchMap((agence: AgenceMinimal) => this.getAgenceParent(agence.id)),
                tap((agence) => this.updateInfosParentes(agence))
            )
            .subscribe();
    }

    /**
     * Rempli le fomulaire avec les données de l'agence courante
     * @param agence
     */
    populateForm(agence: Agence): void {
        const cofracsCurrent: Cofrac[] = [];
        agence.cofracs.forEach((cofracAgenceTemp) => {
            const coffracAssocie = this.cofracs.find((cofracTotalTemp) => {
                return cofracTotalTemp.id === cofracAgenceTemp.id;
            });
            if (coffracAssocie) {
                cofracsCurrent.push(coffracAssocie);
            }
        });
        if (agence) {
            this.formAgence.patchValue({
                general: {
                    idSalesforce: agence.idSalesforce ? agence.idSalesforce : '',
                    nom: agence.nom ? agence.nom : '',
                    agenceAppartenance: agence.agenceParent,
                    responsableAgence: this.responsable,
                    fax: agence.fax ? agence.fax : '',
                    email: agence.email ? agence.email : '',
                    telephone: agence.telephone ? agence.telephone : '',
                },
                adresse: {
                    voie: agence.adresse && agence.adresse.voie ? agence.adresse.voie : '',
                    complementAdresse1: agence.adresse && agence.adresse.complementAdresse1,
                    codePostal: agence.adresse && agence.adresse.codePostal,
                    ville: agence.adresse && agence.adresse.ville,
                },
                cofracs: cofracsCurrent,
                legals: {
                    siret: agence.siret,
                    siren: agence.siren,
                    codeApe: agence.codeApe,
                    codeNaf: agence.codeNaf,
                    tva: agence.tva,
                },
                others: {
                    attestationFile: agence.attestationHonneurDocument,
                },
            });

            // Rempli le tableau d'assurances :
            if (this.agence.assurances) {
                this.assurances = [...this.agence.assurances];
            }
        }
    }

    /**
     * Prépare les données du formulaire pour la sauvegarde
     */
    prepareSaveEntity(): Agence {
        // General
        this.agence.idSalesforce = this.formAgence.value.general.idSalesforce;
        this.agence.nom = this.formAgence.value.general.nom;
        this.agence.responsableAgence = this.responsable;
        if (this?.formAgence?.value?.general?.agenceAppartenance?.id && this?.formAgence?.value?.general?.agenceAppartenance?.nom) {
            this.agence.agenceParent = this.formAgence.value.general.agenceAppartenance;
        } else {
            this.agence.agenceParent = null;
        }

        // Coordonnées suplémentaires :
        this.agence.fax = this.formAgence.value.general.fax;
        this.agence.email = this.formAgence.value.general.email;
        this.agence.telephone = this.formAgence.value.general.telephone;

        // Adresse :
        this.agence.adresse = this.formAgence.value.adresse;

        // Cofracs :
        this.agence.cofracs = this.formAgence.value.cofracs;

        // Informations légales :
        // On les ajoute que si on n'a pas activé pour lier les données avec les données du parent
        const legals = this.formAgence.value.legals;
        this.agence.siret = legals.siret ? legals.siret : '';
        this.agence.siren = !this.hasInfosParent && legals.siren ? legals.siren : '';
        this.agence.codeApe = !this.hasInfosParent && legals.codeApe ? legals.codeApe : '';
        this.agence.codeNaf = !this.hasInfosParent && legals.codeNaf ? legals.codeNaf : '';
        this.agence.tva = !this.hasInfosParent && legals.tva ? legals.tva : '';

        // Assurance
        this.agence.assurances = !this.hasInfosParent ? this.assurances : [];

        // Flag pour savoir si l'agence hérite des données du parent
        this.agence.hasInfosParent = this.hasInfosParent;

        // Attestation sur honneur
        this.agence.attestationHonneurDocument = this.attestationHonneurControl.value;

        // Autres :
        return this.agence;
    }

    /**
     * Action lorsque l'utilisateur envoye le formulaire
     */
    onSubmit(): void {
        if (this.formAgence.valid) {
            // Prépare les données à sauvegarder
            this.prepareSaveEntity();
            this.uploadData();
        }
    }

    uploadData() {
        // Mise à jour des données
        if (this.mode === 'EDIT') {
            this.cnSpinnerService
                .withSpinner(this.agenceService.updateAgenceOnline(this.agence))
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(() => {
                    this.navigateBack();
                });
        } else {
            // Ou création des données
            this.cnSpinnerService
                .withSpinner(this.agenceService.createAgence(this.agence))
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(() => {
                    this.navigateBack();
                });
        }
    }

    /**
     * Action lorsque l'utilisateur clique sur le bouton pour ajouter une assurance
     */
    onClickAddAssurance(): void {
        this.openAssuranceDialog();
    }

    updateAssurances(assurances: Assurance[]): void {
        this.assurances = assurances;
    }

    /**
     * Action lorsque l'utilisateur clique sur le bouton pour copier l'adresse
     */
    onClickCopyAdress(): void {
        const agenceAppartenance = this.formAgence.get('general').get('agenceAppartenance').value;
        if (agenceAppartenance) {
            this.cnSpinnerService
                .withSpinner(this.getAgenceParent(agenceAppartenance.id))
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((currentAgence) => {
                    this.patchFormAdresse(currentAgence.adresse);
                });
        }
    }

    /**
     * Action lorsque l'utilisateur clique sur le bouton pour associer les données de l'agence parent
     * @param value
     */
    onClickButtonAgency(value: { checked: boolean }): void {
        this.hasInfosParent = value.checked;
        this.updateFormGroupStates();
        if (this.hasInfosParent) {
            this.getAgenceParent(this.agence.agenceParent?.id)
                .pipe(
                    takeUntil(this.ngUnsubscribe),
                    tap((agence) => this.updateInfosParentes(agence))
                )
                .subscribe();
        }
    }

    updateFormGroupStates() {
        if (this.hasInfosParent) {
            this.formAgence.get('legals').disable();
            this.formAgence.get('legals').get('siret').enable();
            this.formAgence.get('others').disable();
        } else {
            this.formAgence.get('legals').enable();
            this.formAgence.get('others').enable();
        }
    }

    /**
     * Récupère les données de l'agence parente
     * @param idAgence
     */
    getAgenceParent(idAgence: string): Observable<Agence> {
        return this.agenceService.findOne(idAgence);
    }

    /**
     * Mets à jour les données de l'adresse dans le formulaire
     * @param adresse
     */
    patchFormAdresse(adresse: Adresse): void {
        if (adresse) {
            this.formAgence.patchValue({
                adresse: {
                    voie: adresse.voie ? adresse.voie : '',
                    complementAdresse1: adresse.complementAdresse1,
                    codePostal: adresse.codePostal,
                    ville: adresse.ville,
                },
            });
        }
    }

    /**
     * Action lorsque l'utilisateur clique sur le bouton "editer"
     */
    onClickEdit(): void {
        this.router.navigate([URL_GESTION_AGENCES_EDIT, this.agence.id]);
    }

    /**
     * Action lorsque l'utilisateur clique sur le bouton "retour"
     */
    onClickBack(): void {
        this.navigateBack();
    }

    /**
     * Renvoie vers la liste des agences
     */
    navigateBack(): void {
        this.router.navigate([URL_GESTION_AGENCES]);
    }

    /**
     * Ouvre la modale permettant d'ajouter une assurance
     */
    openAssuranceDialog(): void {
        const dialogRef = this.dialog.open(CreationAssuranceModalComponent, {
            width: '250px',
            data: { assurance: new Assurance() },
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.assurances.push(result);
                    this.assurances = [...this.assurances];
                }
            });
    }

    /**
     * Permet de filtrer les agences, commandes, biens et contacts
     * @private
     */
    private search() {
        this.formService
            .agenceSearchValueChange(this.formAgence.get('general').get('agenceAppartenance'), this.agenceApiService)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result) => {
                this.filteredSearchAgences = result.content;
            });

        this.formService
            .userSearchValueChange(this.formAgence.get('general').get('responsableAgence'), this.managementApiService)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result) => {
                this.filteredSearchResponsables = result.content;
            });
    }

    onClickChangeResponsableFromSelect(event: MatAutocompleteSelectedEvent) {
        if (event?.option?.value?.id) {
            this.responsable = event.option.value;
        }
    }

    initSiretWithSiren() {
        this.formAgence.get('legals').get('siret').setValue(this.formAgence.get('legals').get('siren').value);
        this.formAgence.get('legals').get('siret').updateValueAndValidity();
    }

    private updateInfosParentes(agence: Agence) {
        this.formAgence.get('legals').patchValue({
            siren: agence.siren,
            codeApe: agence.codeApe,
            codeNaf: agence.codeNaf,
            tva: agence.tva,
        });
    }
}
