import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { FormContext } from 'src/app/model/rule/form-context.model';
import { MatCalendar, MatCalendarHeader, MatMultiYearView } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export const MY_FORMATS = {
    parse: {
        dateInput: 'YYYY',
    },
    display: {
        dateInput: 'YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
    },
};

@Component({
    selector: 'example-header',
    styleUrls: ['./custom-header.scss'],
    templateUrl: './custom-header.html',

    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class customHeaderComponent<D> implements OnDestroy {
    private _destroyed = new Subject<void>();

    constructor(
        private _calendar: MatCalendar<D>,
        private _dateAdapter: DateAdapter<D>,
        @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
        cdr: ChangeDetectorRef
    ) {
        _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
    }

    ngOnDestroy() {
        this._destroyed.next();
        this._destroyed.complete();
    }

    private _yearsPerPage = 24;

    get periodLabel() {
        const activeYear = this._dateAdapter.getYear(this._calendar.activeDate);
        const minYearOfPage =
            activeYear - this.getActiveOffset(this._dateAdapter, this._calendar.activeDate, this._calendar.minDate, this._calendar.maxDate);
        const maxYearOfPage = minYearOfPage + this._yearsPerPage - 1;
        const minYearName = this._dateAdapter.getYearName(this._dateAdapter.createDate(minYearOfPage, 0, 1));
        const maxYearName = this._dateAdapter.getYearName(this._dateAdapter.createDate(maxYearOfPage, 0, 1));

        return `${minYearName} - ${maxYearName}`;
    }

    previousClicked() {
        this._calendar.activeDate = this._dateAdapter.addCalendarYears(this._calendar.activeDate, -24);
    }

    nextClicked() {
        this._calendar.activeDate = this._dateAdapter.addCalendarYears(this._calendar.activeDate, 24);
    }

    getActiveOffset(dateAdapter, activeDate, minDate, maxDate) {
        const activeYear = dateAdapter.getYear(activeDate);
        return this.euclideanModulo(activeYear - this.getStartingYear(dateAdapter, minDate, maxDate), this._yearsPerPage);
    }

    euclideanModulo(a, b) {
        return ((a % b) + b) % b;
    }

    getStartingYear(dateAdapter, minDate, maxDate) {
        let startingYear = 0;
        if (maxDate) {
            const maxYear = dateAdapter.getYear(maxDate);
            startingYear = maxYear - this._yearsPerPage + 1;
        } else if (minDate) {
            startingYear = dateAdapter.getYear(minDate);
        }
        return startingYear;
    }
}
