import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { ActivatedRoute } from '@angular/router';
import { EtatProgressionService } from '../../../../../../services/etat-progression.service';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { CnSpinnerService } from '../../../../../shared/cn-spinner/service/cn-spinner.service';
import { takeUntil } from 'rxjs/operators';
import { typePolluant } from '../../../enum/type-polluant';

@Component({
    selector: 'app-validation-documentaire',
    templateUrl: './validation-documentaire.component.html',
    styleUrls: ['./validation-documentaire.component.scss'],
})
export class ValidationDocumentaireComponent extends BaseComponent implements OnInit {
    currentDiagnostic: Diagnostic;
    CurrentPrestation: typePolluant[] = [
        typePolluant.etude_situation,
        typePolluant.visite_chantier,
        typePolluant.prelevement_polluant,
        typePolluant.redaction_strategie,
        typePolluant.resultats_analyses,
    ];

    constructor(
        private readonly route: ActivatedRoute,
        private readonly etatProgressionService: EtatProgressionService,
        private readonly diagnosticService: DiagnosticService,
        private readonly cnSpinnerService: CnSpinnerService
    ) {
        super();
    }

    ngOnInit(): void {
        this.cnSpinnerService.withSpinner(this.diagnosticService.getCurrentDiagnostic().pipe(takeUntil(this.ngUnsubscribe))).subscribe((diag) => {
            this.currentDiagnostic = diag;
        });
        this.checkValidity();
    }

    private checkValidity() {
        const code = this.route.snapshot.data['code'];
        this.etatProgressionService.updateDiagnostic(code, 'VOID', this.currentDiagnostic);
    }
}
