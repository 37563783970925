'use strict';
//***********************************************************************************
//***********************************************************************************
//**** Build nomenclatures
//***********************************************************************************
//***********************************************************************************

import { cn_building } from '../../model/cn_building';
import { cn_nomenclature } from '../cn_nomenclature';

const POSITION_LABEL = new Map([
    ['floor', 'Sol'],
    ['ceiling', 'Plafond'],
    ['wall', 'Mur'],
    ['facade', 'Façade'],
    ['exterior', 'Extérieur']
])

//***********************************************************************************
//***********************************************************************************
/**
 * Buils facing's types  nomenclatures for the building
 * @param {cn_building} building
 * @returns {cn_nomenclature[]}
 */
export function cn_nomenclature_facing_types(building) {
    const output = [];

    output.push(new cn_nomenclature('Nom'));
    output.push(new cn_nomenclature('Position'));
    output.push(new cn_nomenclature('Surface totale').withNumberDefinition('m²', 2));

    const area_per_facing = new Map();

    building.storeys.forEach(storey => {
        storey.scene.storey = storey;
        storey.scene.update_deep();

        //*** We compute the storey volume */
        storey.build_facing_volume();

        storey.scene.spaces.forEach(space => {
            space.get_floor_facing_polygons().forEach(pg => {
                const facing = pg['facing_trimming'] ? pg['facing_trimming'].facing : space.facings[0];
                if (facing) {
                    area_per_facing.set(facing.ID, (area_per_facing.get(facing.ID) || 0) + pg.get_area());
                }
            });
            if (space.has_roof && space.facings[1]) {
                const ceilings = space.get_3d_ceilings(storey);
                if (ceilings.length) {
                    area_per_facing.set(space.facings[1].ID, (area_per_facing.get(space.facings[1].ID) || 0) + ceilings.reduce((agg, ceiling) => agg + ceiling.get_area(), 0));
                }
            }
            space.contours.forEach(ctr => {
                ctr.walls.forEach((wall, index) => {
                    const side = ctr.wall_orientations[index] ? 0 : 1;
                    wall.build_facing_polygons(side, storey).forEach(pg => {
                        const facing = pg['facing_trimming'] ? pg['facing_trimming'].facing : wall.facings[side];
                        if (facing) {
                            area_per_facing.set(facing.ID, (area_per_facing.get(facing.ID) || 0) + pg.get_area());
                        }
                    });
                });
            });
        });

    });

    building.facing_types.forEach((facing_type, i) => {

        let nomenclature_entry_index = 0;
        output[nomenclature_entry_index].values[i] = facing_type.name;
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = POSITION_LABEL.get(facing_type.support);
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = area_per_facing.get(facing_type.ID) || 0;
        nomenclature_entry_index++;
    });

    return output;
}
