import { fromEvent, Observable, Observer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ImageSize } from './file.utils';

export class ImageUtils {
    /**
     * Convertit une URL d'image en URL base 64.
     */
    static getBase64ImageFromURL(url: string): Observable<string> {
        return new Observable(createObs);

        function createObs(observer: Observer<string>) {
            // create an image object
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = url;
            if (!img.complete) {
                // This will call another method that will create image from url
                img.onload = () => {
                    observer.next(ImageUtils.getBase64Image(img));
                    observer.complete();
                };
                img.onerror = (err) => {
                    observer.error(err);
                };
            } else {
                observer.next(ImageUtils.getBase64Image(img));
                observer.complete();
            }
        }
    }

    /**
     *  Crée une URL d'image en base 64 à partir d'une image HTML.
     */
    private static getBase64Image(img: HTMLImageElement): string {
        // We create a HTML canvas object that will create a 2d image
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
        // This will draw image
        ctx.drawImage(img, 0, 0);
        // Convert the drawn image to Data URL
        const dataURL: string = canvas.toDataURL('image/png');
        // this.base64DefaultURL = dataURL;
        return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
    }

    /**
     * Renvoie la taille d'une image.
     */
    static getImgSize(imageSource: string): Observable<ImageSize> {
        const mapLoadedImage = (event): ImageSize => {
            return {
                width: event.target.width,
                height: event.target.height,
            };
        };
        const image = new Image();
        const $loadedImg = fromEvent(image, 'load').pipe(take(1), map(mapLoadedImage));
        image.src = imageSource;
        return $loadedImg;
    }
}
