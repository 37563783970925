import { MongoUtils } from 'src/app/commons-lib';
import { ResultatAnalyseGenerique } from './resultatAnalyseGenerique.model';
import { TypeMateriau } from './type-materiau.model';
import { Legende } from './legende.model';

export class PrelevementGenerique {
    id: string = MongoUtils.generateObjectId();
    reference?: string;
    numero: number;
    resultats: ResultatAnalyseGenerique[] = [];

    constructor() {}
}

export enum TypePrelevement {
    /** CAROTTAGE */
    CAROTTAGE = 'CAROTTAGE',

    /** BURINAGE_MANUEL */
    BURINAGE_MANUEL = 'BURINAGE_MANUEL',
}

export enum ProduitLimitationFibres {
    /** EAU */
    EAU = 'EAU',

    /** EAU + ADDITIFS */
    EAU_ADDITIFS = 'EAU_ADDITIFS',

    /** GEL HYDRIQUE */
    GEL_HYDRIQUE = 'GEL_HYDRIQUE',
}

export enum TypeCarottage {
    /** TOTAL */
    TOTAL = 'TOTAL',

    /** PARTIEL */
    PARTIEL = 'PARTIEL',
}

export enum TypeEchantillonage {
    AGREGAT = 'AGREGAT',
    MATERIAUX_DE_CONSTRUCTION = 'MATERIAUX_DE_CONSTRUCTION',
}

export class PrelevementGeneral {
    zoneId: string;
    reference?: string;
    gpsLatitude?: number;
    gpsLongitude?: number;
    description?: string;
}

export enum ProduitLimitationFibre {
    EAU = 'EAU',
    EAU_ADDITIFS = 'EAU_ADDITIFS',
    GEL_HYDRIQUE = 'GEL_HYDRIQUE',
}

export enum TypeAnalyse {
    HAP = 'HAP',
    ANALYSE_AMIANTE = 'ANALYSE_AMIANTE',
}

export class PrelevementDetails {
    typePrelevement?: TypePrelevement;
    produitLimitationFibre?: ProduitLimitationFibre;
    typeCarottage?: TypeCarottage;
    profondeurCarottage?: number;
    /**
     * Id du fichier contenant la photo de situation du prélèvement
     */
    idFichierImageSituation?: string;
    /**
     * Id du fichier contenant la photo d'échelle' du prélèvement
     */
    idFichierImageEchelle?: string;
    sensCarottage?: SensCarottage;
}

export class PrelevementEchantillonage {
    id: string = MongoUtils.generateObjectId();
    reference?: string;
    type?: TypeEchantillonage;
    hauteur?: number;
    materiaux?: TypeMateriau[] = [];
    commentairesId?: string[] = [];
    prelevementId?: string;
    resultatsAnalyse?: ResultatAnalyse[] = [];
}

// Résultats du BDC laboratoire
export class PrelevementEchantillonageAdmin {
    id: string;
    reference?: string;
    prelevementId?: string;
    type?: TypeEchantillonage;
    hauteur?: number;
    typePrelevement: TypePrelevement;
    typesAnalyse: TypeAnalyse[];
    resultatsAnalyse: ResultatAnalyse[];
    validated: boolean;

    commentairesId?: string[] = [];
}

// Résultats reçus du laboratoire, après analyse
export class PrelevementEchantillonageADO {
    id: string;
    reference?: string;
    prelevementId?: string;
    idBonCommandeAnalyse?: string;
    laboratoire?: string;
    typePrelevement: TypePrelevement;
    typesAnalyse: TypeAnalyse[];
    resultatsAnalyse: ResultatAnalyse[];
    dateEnvoiAnalyse?: string;
    validated: boolean;

    commentairesId?: string[] = [];
}

export class PrelevementDonneesTechniques {
    lave: PrelevementDonneesTechniquesElement = {
        value: true,
        commentairesId: [],
    };
    couchesADissocier: PrelevementDonneesTechniquesElement = {
        value: true,
        commentairesId: [],
    };
    analyseCouches: PrelevementDonneesTechniquesElement = {
        value: true,
        commentairesId: [],
    };
    limitationFibre: PrelevementDonneesTechniquesElement = {
        value: true,
        commentairesId: [],
    };
    pollutionSurfacique: PrelevementDonneesTechniquesElement = {
        value: false,
        commentairesId: [],
    };
    analyseAmiante: PrelevementAnalyseAmianteElement = {
        value: true,
        commentairesId: [],
        idDocumentPvAmiante: '',
    };

    constructor() {
        this.lave = {
            value: true,
            commentairesId: [],
        };
        this.couchesADissocier = {
            value: true,
            commentairesId: [],
        };
        this.analyseCouches = {
            value: true,
            commentairesId: [],
        };
        this.limitationFibre = {
            value: true,
            commentairesId: [],
        };
        this.pollutionSurfacique = {
            value: false,
            commentairesId: [],
        };
        this.analyseAmiante = {
            value: true,
            commentairesId: [],
            idDocumentPvAmiante: '',
        };
    }
}

export class Prelevement {
    id: string = MongoUtils.generateObjectId();
    nom?: string;
    legende: Legende = new Legende();
    general: PrelevementGeneral = new PrelevementGeneral();
    details: PrelevementDetails = new PrelevementDetails();
    echantillonages: PrelevementEchantillonage[] = [];
    donneesTechniques: PrelevementDonneesTechniques = new PrelevementDonneesTechniques();
    commentairesId?: string[] = [];
    isRealize = true;
}

/**
 * À partir d'un type de prestation, renvoie le label associé.
 */
export function typePrelevementToLabel(typePrelevement: string | TypePrelevement): string {
    switch (typePrelevement) {
        case 'CAROTTAGE':
            return 'Carottage';
        case 'BURINAGE_MANUEL':
            return 'Burinage manuel';
        // À COMPLÉTER AVEC LES DIFFÉRENTS TYPES DE PRELEVEMENT...
        default:
            return typePrelevement;
    }
}

/**
 * À partir d'un produit de limitation de fibres, renvoie le label associé.
 */
export function produitLimitationFibresToLabel(produitLimitationFibres: string | ProduitLimitationFibre): string {
    switch (produitLimitationFibres) {
        case 'EAU':
            return 'Eau';
        case 'EAU_ADDITIFS':
            return 'Eau + additifs';
        case 'GEL_HYDRIQUE':
            return 'Gel hydrique';
        // À COMPLÉTER AVEC LES DIFFÉRENTS PRODUITS...
        default:
            return produitLimitationFibres;
    }
}

/**
 * À partir d'un type de carottage, renvoie le label associé.
 */
export function typeCarottageToLabel(typeCarottage: string | TypeCarottage): string {
    switch (typeCarottage) {
        case 'TOTAL':
            return 'CAROTTAGE TOTAL';
        case 'PARTIEL':
            return 'CAROTTAGE PARTIEL';
        // À COMPLÉTER AVEC LES CAROTTAGES...
        default:
            return typeCarottage;
    }
}

export enum SensCarottage {
    UP = 'UP',
    RIGHT = 'RIGHT',
    LEFT = 'LEFT',
    DOWN = 'DOWN',
}

/**
 * À partir d'un type d'échantillonage, renvoie le label associé.
 */
export function typeEchantillonageToLabel(typeEchantillonage: string | TypeEchantillonage): string {
    switch (typeEchantillonage) {
        case 'AGREGAT':
            return 'Agregat';
        case 'MATERIAUX_DE_CONSTRUCTION':
            return 'Mat. de construction';
        // À COMPLÉTER AVEC LES TYPES D'ECHANTILLONAGE...
        default:
            return typeEchantillonage;
    }
}

export function typeAnalyseToLabel(typeAnalyse: TypeAnalyse): string {
    switch (typeAnalyse) {
        case TypeAnalyse.HAP:
            return 'HAP';
        case TypeAnalyse.ANALYSE_AMIANTE:
            return 'Amiante naturellement présent et Amiante ajoutée délibérément';
        // A COMPLETER AVEC LES TYPES D'ANALYSE
        default:
            return typeAnalyse;
    }
}

export function typeAnalyseToLabelSimple(typeAnalyse: TypeAnalyse): string {
    switch (typeAnalyse) {
        case TypeAnalyse.HAP:
            return 'HAP';
        case TypeAnalyse.ANALYSE_AMIANTE:
            return 'Amiante';
        // A COMPLETER AVEC LES TYPES D'ANALYSE
        default:
            return typeAnalyse;
    }
}

export class ResultatAnalyse {
    id: string = MongoUtils.generateObjectId();
    typeAnalyse: TypeAnalyse;
    methodeAnalyse: string;
    description: string;
    number: number;
    unit: string;
    dateAnalyse: string;
    groupResultat: {
        seuilScore: ResultSeuil;
        color: string;
        label: string;
    };
}

export class PrelevementDonneesTechniquesElement {
    value = true;
    commentairesId: string[] = [];
}

export class PrelevementAnalyseAmianteElement {
    value = true;
    commentairesId: string[] = [];
    idDocumentPvAmiante: string;
}

/**
 * Si on rajoute des seuils, il faut penser à les classer du score le meilleur au score le plus mauvais
 */
export enum ResultSeuil {
    INFERIEUR_EGAL_50,
    ENTRE_51_ET_500,
    ENTRE_501_ET_1000,
    SUPERIEUR_1000,
}

export enum TypePrelevementAmiante {
    META = 'META', //ambiance
    METOP = 'METOP', //operateur
    MEST = 'MEST', //prelèvement de matière en suspension dans l'eau
    ACR = 'ACR', //compresseur
    MATERIAU = 'MATERIAU',
    LINGETTE = 'LINGETTE',
    UNDEFINED = 'UNDEFINED',
}
