<app-expension-panel [panelOpenState]="true" [formGroup]="surface" class="piece-unitaire-panel">
    <div title class="header" (click)="$event.stopPropagation()">
        <div class="row m-0 align-items-center">
            <div class="mr-1 required">Surface au sol (en m²):</div>
            <app-increment-input-reactive
                readonly
                class="mr-3 mat-border-radius"
                formControlName="superficie"
                [incrementControl]="surface.get('superficie')"
                [min]="0"
                [step]="1"
                [numericKeyboard]="false"
                [noButtons]="true"
                [disabled]="disabled"
                data-cy="superficie-input"
            >
            </app-increment-input-reactive>

            <ng-container *ngIf="surface.get('superficie').value < 100">
                <ng-container *ngIf="surface.get('inferieurSeuilMin')?.value === false">
                    <div class="mr-1">Longueur maxi:</div>
                    <app-increment-input-reactive
                        class="mr-3 mat-border-radius"
                        formControlName="longueurMax"
                        [incrementControl]="surface.get('longueurMax')"
                        [min]="15"
                        [step]="1"
                        [numericKeyboard]="true"
                        [noButtons]="true"
                        [disabled]="disabled"
                        data-cy="longueur-maxi-input"
                        (valueChange)="onChangeLongueurMaxi($event)"
                    >
                    </app-increment-input-reactive>
                    <div class="mr-1">ou</div>
                </ng-container>
                <mat-checkbox
                    class="mr-1"
                    formControlName="inferieurSeuilMin"
                    [disabled]="isCheckboxDisabled"
                    [class.readonly]="disabled"
                    (ngModelChange)="onChangeInferieurSeuilMin()"
                ></mat-checkbox>
                <div class="mr-3">< 15m</div>
            </ng-container>

            <div class="mr-1">P.U.</div>
            <app-increment-input-reactive
                readonly
                class="mr-3 mat-border-radius"
                formControlName="nombrePu"
                [incrementControl]="surface.get('nombrePu')"
                [min]="0"
                [step]="1"
                [numericKeyboard]="false"
                [noButtons]="true"
                [disabled]="disabled"
                data-cy="nombrePu-input"
            >
            </app-increment-input-reactive>
        </div>
        <div class="row m-0 mt-2 mb-2" *ngIf="!isActiveSurfaceInterfaceDisabled">
            <mat-checkbox
                class="mr-1"
                formControlName="activeSurfaceInterface"
                [disabled]="isActiveSurfaceInterfaceDisabled"
                [class.readonly]="disabled"
                (change)="(true)"
                #activeSurfaceInterface
                ><div class="mr-1 required">ou Surface d'interface (en m²):</div></mat-checkbox
            >
            <div class="row m-0" *ngIf="activeSurfaceInterface.checked">
                <input type="number" class="mr-3 mat-border-radius mat-grey" [value]="surfaceInterface" readonly />

                <div class="mr-1">avec Longueur :</div>
                <app-increment-input-reactive
                    class="mr-3 mat-border-radius"
                    formControlName="longueurInterface"
                    [incrementControl]="surface.get('longueurInterface')"
                    [min]="0"
                    [step]="1"
                    [numericKeyboard]="true"
                    [noButtons]="true"
                    [disabled]="disabled"
                    data-cy="longueurInterface-maxi-input"
                >
                </app-increment-input-reactive>

                <div class="mr-1">X Hauteur :</div>
                <app-increment-input-reactive
                    class="mr-3 mat-border-radius"
                    formControlName="hauteurInterface"
                    [incrementControl]="surface.get('hauteurInterface')"
                    [min]="0"
                    [step]="1"
                    [numericKeyboard]="true"
                    [noButtons]="true"
                    [disabled]="disabled"
                    data-cy="hauteurInterface-maxi-input"
                >
                </app-increment-input-reactive>
            </div>
        </div>
    </div>
    <div description class="action" *ngIf="!disabled">
        <button mat-icon-button (click)="deleteSurface($event)">
            <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button (click)="add($event)" data-cy="ajouter-volume-button">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <div *ngFor="let volume of listVolumePu; let index = index">
        <div class="mb-1" *ngIf="volume">
            <app-volume-block
                (remove)="delete(volume.id)"
                [label]="volume.nom"
                [disabled]="disabled"
            ></app-volume-block>
        </div>
    </div>
</app-expension-panel>
