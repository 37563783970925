import { Component, Input, OnInit } from '@angular/core';
import { EspaceData } from '../../../../../model/hapData.model';
import { ReportBlockData } from '../../../../../../../../model/rapport.model';
import { TypePrestation, TypePrestationEnum } from 'src/app/model/type-prestation.model';

@Component({
    selector: 'app-liste-detail-perimetre',
    templateUrl: './liste-detail-perimetre.component.html',
    styleUrls: ['./liste-detail-perimetre.component.scss'],
})
export class ListeDetailPerimetreComponent implements OnInit {
    TypePrestationEnum = TypePrestationEnum;

    @Input() listeEspaces: EspaceData[];

    @Input() data: ReportBlockData;

    @Input() typePrestation: TypePrestation;

    @Input() isPrelevementRealise: boolean;

    constructor() {}

    ngOnInit(): void {}
}
