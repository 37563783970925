import { Injectable } from '@angular/core';
import { ZplService } from '../../../../services/zpl.service';
import { etiquetteZebra } from '../../../../model/etiquetteZebra.model';
import { PrelevementAir, PrelevementMeta, Temoin } from '../model/polluant-prelevement.model';
import { Sachet } from '../../../../model/sachet.model';

@Injectable({
    providedIn: 'root',
})
export class ZplPolluantService extends ZplService {
    constructor() {
        super();
    }

    public generateLabelMateriau(prelo: PrelevementAir): etiquetteZebra {
        return new etiquetteZebra(
            prelo.reference,
            super.debutEtiquette() +
                super.codeBarreDetachable(prelo.reference) +
                super.qrCodeCorps('https://p.diag.ac/' + prelo.id) +
                super.referenceDetachable(prelo.reference) +
                super.descriptionMateriauDetachable(prelo.besoinPrelevement.mpcaId) +
                // super.localisationMateriauDetachable(prelo.besoinPrelevement.localisation) +
                super.numeroPreloCorps(prelo.numero) +
                super.logoCorps() +
                super.logoDetachable() +
                super.regleCorps() +
                super.attentionCorps() +
                super.finEtiquette()
        );
    }

    public generateLabelSachet(sachet: Sachet): etiquetteZebra {
        return new etiquetteZebra(
            sachet.reference,
            super.debutEtiquette() +
                super.codeBarreDetachable(sachet.reference) +
                super.referenceDetachable(sachet.reference) +
                super.logoCorps() +
                super.referenceCorps(sachet.reference) +
                super.referenceInterventionCorps(sachet.refIntervention) +
                super.nombrePrelevementDansSachetCorps(sachet.prelevements.length) +
                super.finEtiquette()
        );
    }

    public generateLabelTemoin(temoin: Temoin): etiquetteZebra {
        return new etiquetteZebra(
            temoin.reference,
            super.debutEtiquette() +
                super.codeBarreDetachable(temoin.reference) +
                super.referenceDetachable(temoin.reference) +
                super.texteLibreDetachableSousReference1ereLigne('-- Filtre témoin --') +
                super.texteLibreDetachableSousReference3emeLigne('Sachet:' + temoin.refSachet) +
                super.texteLibreDetachableSousReference5emeLigne('Inter.:' + temoin.refIntervention) +
                super.logoDetachable() +
                super.finEtiquette()
        );
    }

    public generateLabelDetachable(prelo: PrelevementAir, titre: string): etiquetteZebra {
        return new etiquetteZebra(
            prelo.reference,
            super.debutEtiquette() +
                super.codeBarreDetachable(prelo.reference) +
                super.referenceDetachable(prelo.reference) +
                super.texteLibreDetachableSousReference1ereLigne(titre) +
                super.texteLibreDetachableSousReference3emeLigne('Sachet:' + prelo.refSachet) +
                super.texteLibreDetachableSousReference5emeLigne('Inter.:' + prelo.refIntervention) +
                // super.texteLibreDetachableSousReference('Local.:' + prelo.besoinPrelevement.localisation) +
                super.logoDetachable() +
                super.finEtiquette()
        );
    }

    public generateLabelMEST(prelo: PrelevementAir): etiquetteZebra {
        return this.generateLabelDetachable(prelo, '-- Prélo. MEST --');
    }

    public generateLabelLingette(prelo: PrelevementAir): etiquetteZebra {
        return this.generateLabelDetachable(prelo, '-- Lingette plomb --');
    }

    public generateLabelMET(prelo: PrelevementAir): etiquetteZebra {
        return new etiquetteZebra(
            prelo.reference,
            super.debutEtiquette() +
                super.codeBarreDetachable(prelo.reference) +
                super.referenceDetachable(prelo.reference) +
                super.texteLibreDetachableSousReference1ereLigne('Num pompe:' + prelo.refSachet) +
                super.texteLibreDetachableSousReference2emeLigne('Num tête:' + prelo.refSachet) +
                super.texteLibreDetachableSousReference3emeLigne('Lot filtre:' + prelo.refSachet) +
                super.texteLibreDetachableSousReference4emeLigne('Condition:' + prelo.refSachet) +
                super.texteLibreDetachableSousReference5emeLigne('Sachet:' + prelo.refSachet) +
                super.texteLibreDetachableSousReference6emeLigne('Inter.:' + prelo.refIntervention) +
                // super.texteLibreDetachableSousReference('Local.:' + prelo.besoinPrelevement.localisation) +
                super.logoDetachable() +
                super.logoCorps() +
                super.codeBarreCorps(prelo.reference) +
                super.texteLibre1ereLigneCorps(prelo.reference) +
                super.texteLibre2emeLigneCorps('Num pompe:' + prelo.refSachet) +
                super.texteLibre3emeLigneCorps('Num tête:' + prelo.refSachet) +
                super.texteLibre4emeLigneCorps('Lot filtre:' + prelo.refSachet) +
                super.texteLibre5emeLigneCorps('Condition:' + prelo.refSachet) +
                super.texteLibre6emeLigneCorps('Sachet:' + prelo.refSachet) +
                super.texteLibre7emeLigneCorps('Inter.:' + prelo.refIntervention) +
                // super.texteLibre8emeLigneCorps('Local.:' + prelo.besoinPrelevement.localisation) +
                super.attentionCorps() +
                super.finEtiquette()
        );
    }

    public generateLabelCalibration(): etiquetteZebra {
        return new etiquetteZebra('LabelCalibration', super.labelCalibration());
    }
}
