'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** cn_wall :
//***********************************************************************************
//***********************************************************************************

import { BbpHelper } from '../utils/cn_bbp_helper';
import {
    cn_add,
    cn_box,
    cn_clone,
    cn_clone_3d,
    cn_color_hexa_to_rgb,
    cn_compute_intersection_position,
    cn_dist,
    cn_dot,
    cn_intersect_line,
    cn_intersect_segments,
    cn_max,
    cn_min,
    cn_mul,
    cn_normal,
    cn_normalize,
    cn_polar,
    cn_project_segment,
    cn_size,
    cn_sub,
    cnx_add,
    cnx_clone,
    cnx_mul,
    cnx_normalize
} from '../utils/cn_utilities';
import { cn_element_visitor } from '../utils/visitors/cn_element_visitor';
import { cn_balcony_type } from './cn_balcony_type';
import { cn_element } from './cn_element';
import { cn_opening } from './cn_opening';
import { cn_wall_type } from './cn_wall_type';
import { cn_fence_type } from './cn_fence_type';
import { cn_vertex } from './cn_vertex';
import { fh_add, fh_box, fh_clone, fh_matrix, fh_mul, fh_polygon, fh_scene, fh_solid, fh_sub } from '@acenv/fh-3d-viewer';
import { cn_storey } from './cn_storey';
import { cn_3d_building } from './cn_3d_building';
import { CODES_BIM_OUVRAGES, CODES_BIM_PARAMETRES_OUVRAGES } from '../utils/cn_bbp_constants';
import { cn_building } from './cn_building';
import { CN_CURRENT_DATE, cn_facing_trimming, cn_image_dir, logger } from '..';
import { cn_bbp_geometry } from '../utils/cn_bbp_geometry';
import { cn_wall_metrics } from './cn_wall_metrics';
// @ts-ignore

export const CN_MIDDLE = 0;
export const CN_OUTER = 1;
export const CN_INNER = 2;

export const CN_WALL_DRAW_MODE_RECTANGLE = 0;
export const CN_WALL_DRAW_MODE_OPEN_POLYGON = 1;

export const CN_WALL_COSINE_SHAPE_THRESHOLD = 0.99;

/**
 * Revêtements de mur
 * NB : Bien penser à mettre à jour cn_bbp_bim_referrer en cas d'ajout de nouveaux types !
 */
export const INTERIOR_WALL_FACING_LIST = [
    { code: 'concrete', label: 'Béton' },
    { code: 'brick', label: 'Brique' },
    { code: 'paint', label: 'Peinture' },
    { code: 'fibreglass', label: 'Fibre de verre' },
    { code: 'wall_tiles', label: 'Crédence' },
    { code: 'wood_wall', label: 'Lambris' },
    { code: 'carpet', label: 'Moquette' },
];

/**
 * Revêtements de façade
 * NB : Bien penser à mettre à jour cn_bbp_bim_referrer en cas d'ajout de nouveaux types !
 */
export const EXTERIOR_WALL_FACING_LIST = [
    { code: 'concrete', label: 'Béton' },
    { code: 'brick', label: 'Brique' },
    { code: 'paint', label: 'Enduit' },
    { code: 'wood_facade', label: 'Bardage bois' },
    { code: 'alu_facade', label: 'Bardage alu' },
];

function sort_openings_by_position(o0, o1) {
    if (o0.position < o1.position) return -1;
    return 1;
}

export class cn_wall_delegate {
    constructor(pos, wall, side) {
        this.position = cn_clone(pos);
        this.wall = wall;
        this.side = side;
        this.locked = false;
    }

    clone() {
        return new cn_wall_delegate(this.position, this.wall, this.side);
    }

    serialize() {
        var json = {};
        json.position = this.position;
        return json;
    }

    static unserialize(json, scene) {
        if (json == null) return null;
        if (typeof (json) != 'object') return null;
        if (typeof (json.position) != 'object') return null;
        return new cn_wall_delegate(json.position, null, 0);
    }
}

export class cn_wall extends cn_element {
    constructor(v0, v1, wt, axis, scene) {
        super(scene);
        this.scene = scene;

        //*** Model data
        this.vertices = [v0, v1];
        this.balcony = (wt && wt.constructor == cn_balcony_type);
        this.fence = (wt && wt.constructor == cn_fence_type);
        this.axis = axis;
        this.wall_type = wt;
        this.openings = [];

        this.delegates = [null, null];

        this.facings = [null, null];
        this.facing_areas = [0, 0];

        //*** volatile data
        this.draw_priority = 5;
        this.shape = [[0, 0], [0, 0], [0, 0], [0, 0]];
        this.valid = true;
        this.spaces = [null, null];
        this.contours = [null, null];
        this.single = [false, false];
        this.space_lines = [[], []];
        this.full_space_lines = [[], []];
        this.measure_points = [[[0, 0], [0, 0]], [[0, 0], [0, 0]]];
        this.facing_topo = [false, false];
        this.dormer = null;
    }

    //***********************************************************************************
    //**** serialize
    //***********************************************************************************
    serialize(check_selection) {
        var json = {};
        json.ID = this.ID;
        json.locked = this.locked;
        json.vertices = [this.vertices[0].s_index, this.vertices[1].s_index];
        json.balcony = this.balcony;
        json.fence = this.fence;
        json.axis = this.axis;
        json.wall_type = this.wall_type.ID;
        json.openings = [];
        json.facings = this.facings.map(f => (f) ? { ID: f.ID } : null);

        for (var i in this.openings) {
            if (check_selection && !this.openings[i].selected) continue;
            json.openings.push(this.openings[i].serialize());
        }
        json.delegates = this.delegates.map(d => (d) ? d.serialize() : null);
        return json;
    }

    static unserialize(json, scene) {
        if (typeof (json) != 'object') return false;
        if (typeof (json.vertices) != 'object') return false;
        if (typeof (json.openings) != 'object') return false;

        if (json.vertices[0] == json.vertices[1]) return false;

        var v0 = scene.vertices[json.vertices[0]];
        var v1 = scene.vertices[json.vertices[1]];
        if (!v0 || !v1) return false;

        var balcony = (typeof (json.balcony) == 'boolean') ? json.balcony : false;
        var fence = (typeof (json.fence) == 'boolean') ? json.fence : false;

        var wt = null;
        if (typeof (json.wall_type) == 'string')
            wt = scene.building.get_element_type(json.wall_type);
        if (wt == null) {
            logger.log('use default wall type');
            if (balcony)
                wt = scene.building.get_balcony_types()[0];
            else if (fence)
                wt = scene.building.get_fence_types()[0];
            else
                wt = scene.building.get_wall_types()[0];
        }
        if (balcony && wt.constructor != cn_balcony_type)
            logger.log('INCOHERENCE !!!', json, wt, scene.building);

        if (fence && wt.constructor != cn_fence_type)
            logger.log('INCOHERENCE !!!', json, wt, scene.building);

        if (!balcony && !fence && wt.constructor != cn_wall_type)
            logger.log('INCOHERENCE !!!', json, wt, scene.building);

        var wall = new cn_wall(v0, v1, wt, CN_MIDDLE, scene);

        if (typeof (json.ID) == 'string')
            wall.ID = json.ID;

        if (typeof (json.axis) == 'number')
            wall.axis = json.axis;

        if (typeof (json.locked) == 'boolean')
            wall.locked = json.locked;

        for (var i in json.openings) {
            var opening = cn_opening.unserialize(json.openings[i], scene);
            if (!opening) {
                logger.log('WARNING : cannot unserialize opening : ', json.openings[i]);
                continue;
            }
            opening.wall = wall;
            wall.openings.push(opening);
        }

        if (typeof (json.delegates) == 'object' && json.delegates && json.delegates.length >= 2) {
            wall.delegates[0] = cn_wall_delegate.unserialize(json.delegates[0], scene);
            wall.delegates[1] = cn_wall_delegate.unserialize(json.delegates[1], scene);
        }

        if (json.facings && json.facings.length) {
            wall.facings = json.facings.map(facing => facing && facing.ID ? scene.building.facing_types.find(ft => ft.ID === facing.ID) : null);
        } else {
            wall.facings = [null, null]
        }

        scene.walls.push(wall);
        wall.parent = scene;
        return wall;
    }

    is_wall_dormer() {
        return this.dormer != null;
    }

    /**
     * returns vertex position, depending on delegates
     * @param {*} index
     */
    vertex_position(index) {
        return cn_clone((this.delegates[index]) ? this.delegates[index].position : this.vertices[index].position);
    }

    /**
     * Returns the direction of the wall, starting at given vertex.
     * @param {cn_vertex} vertex
     * @returns {number[]}
     */
    direction_from(vertex) {
        const wid = (this.vertices[0] == vertex) ? 0 : 1;
        const dir = cn_sub(this.vertex_position(1 - wid), this.vertex_position(wid));
        cn_normalize(dir);
        return dir;
    }

    /**
     * Returns the direction of the wall, ending at given vertex.
     * @param {cn_vertex} vertex
     * @returns {number[]}
     */
    direction_to(vertex) {
        const wid = (this.vertices[0] == vertex) ? 0 : 1;
        const dir = cn_sub(this.vertex_position(wid), this.vertex_position(1 - wid));
        cn_normalize(dir);
        return dir;
    }

    /**
     * returns a point and direction of the given side, by index.
     * Sides are like this :
     *                         side 1
     *              |-----------------------------|
     *        side 2  |                             |  side 3
     *              |-----------------------------|
     *                           side 0
     *
     * @param {number} side
     * @returns {number[][]}
     */
    get_side(side) {
        if (side == 0)
            return [this.bounds.pmin, this.bounds.direction];
        if (side == 1)
            return [this.bounds.pmax, this.bounds.direction];
        if (side == 2)
            return [this.vertex_position(0), this.bounds.normal];
        return [this.vertex_position(1), this.bounds.normal];
    }

    /**
     * Returns true if given side bears a delegate for given vertex index
     * @param {number} vertex_index
     * @param {number} side
     * @return {boolean}
     */
    bears_delegate(side, vertex_index = 0) {
        const vertex = (side >= 2) ? this.vertices[side - 2] : this.vertices[vertex_index];
        for (var i = 0; i < vertex.walls.length; i++) {
            var w = vertex.walls[i];
            if (w == this) continue;
            var vi = w.vertices.indexOf(vertex);
            if (w.delegates[vi] == null) continue;
            if (w.delegates[vi].wall != this) continue;
            if (w.delegates[vi].side != side) continue;
            return true;
        }
        return false;
    }

    get_delegate(vertex) {
        if (this.vertices[0] == vertex) return this.delegates[0];
        return this.delegates[1];
    }

    /**
     * returns the list of all facings (interior or exterior) that may be attributed to walls.
     * Does not include 'no facing'.
     * @returns {object[]}
     */
    static get_all_facings() {
        let codes = [];
        let facing_list = [];
        EXTERIOR_WALL_FACING_LIST.concat(INTERIOR_WALL_FACING_LIST).forEach(f => {
            if (f.code != '' && codes.indexOf(f.code) < 0) {
                codes.push(f.code);
                facing_list.push(f);
            }
        });
        return facing_list;
    }

    //***********************************************************************************
    //**** Build lines
    //***********************************************************************************
    //*** Build geometry of bounds of the wall
    build_self() {
        var bounds = {};
        var v0 = this.vertex_position(0);
        var v1 = this.vertex_position(1);
        bounds.direction = cn_sub(v1, v0);
        bounds.length = cn_normalize(bounds.direction);
        bounds.normal = cn_normal(bounds.direction);

        bounds.y0 = -0.5 * this.wall_type.thickness;
        if (this.axis == CN_OUTER)
            bounds.y0 = -this.wall_type.thickness;
        else if (this.axis == CN_INNER)
            bounds.y0 = 0;
        bounds.y1 = bounds.y0 + this.wall_type.thickness;
        bounds.pmin = cn_add(v0, cn_mul(bounds.normal, bounds.y0));
        bounds.pmax = cn_add(v0, cn_mul(bounds.normal, bounds.y1));
        this.bounds = bounds;
        this.valid = (bounds.length > 0.01);
        this.opening_area = [0, this.bounds.length];
        this.shape = [bounds.pmin, bounds.pmax, cn_add(bounds.pmax, cn_mul(bounds.direction, bounds.length)), cn_add(bounds.pmin, cn_mul(bounds.direction, bounds.length))];
        this.single = [false, false];
        this.space_lines = [[cn_clone(this.shape[0]), cn_clone(this.shape[3])], [cn_clone(this.shape[2]), cn_clone(this.shape[1])]];
        this.full_space_lines = [[cn_clone(this.shape[0]), cn_clone(this.shape[3])], [cn_clone(this.shape[2]), cn_clone(this.shape[1])]];
    }

    /**
     * Compute the delegate on given vertex index.
     * If something has changed, this method has called 'build_self' automatically.
     * @param {number} vertex_index
     * @returns {boolean} returns 'true' if the delegate is OK.
     */
    compute_delegate(vertex_index, display_log = false) {
        if (!this.delegates[vertex_index]) return true;
        if (this.delegates[vertex_index].locked) {
            if (display_log) logger.log(`######### Compute_delegate ${vertex_index} - LOCKED`, this);
            return true;
        }
        if (display_log) logger.log(`######### Start compute_delegate ${vertex_index} - [${this.vertices[0].position[0]},${this.vertices[0].position[1]}] - [${this.vertices[1].position[0]},${this.vertices[1].position[1]}]`);

        //*** On which walls may the delegate lie ? */
        var candidate_walls = [];
        var w = this.vertices[vertex_index].previous_wall(this, false, true);

        //*** special case : this shouldn't be a delegate. TODO : manage this earlier */
        if (w == this) {
            this.delegates[vertex_index].wall = null;
            return false;
        }

        //*** find walls on which the delegate may lie */
        candidate_walls.push(w);
        var w = this.vertices[vertex_index].next_wall(this, false, true);
        if (w != candidate_walls[0]) candidate_walls.push(w);

        if (display_log) logger.log(`${candidate_walls.length} Candidate walls`);

        //*** this_direction is direction along the wall toward the vertex */
        const this_direction = (vertex_index == 0) ? this.bounds.direction : cn_mul(this.bounds.direction, -1);

        var pmin = this.bounds.pmin;
        var pmax = this.bounds.pmax;
        if (this.wall_type.free || this.wall_type.thickness < 0.001) {
            pmin = cn_add(pmin, cn_mul(this.bounds.normal, -0.001));
            pmax = cn_add(pmin, cn_mul(this.bounds.normal, 0.001));
        }

        //*** we compute on which wall the delegate lies the most */
        var best_wall = null;
        var best_side = 0;
        var max_dist = 0;
        candidate_walls.forEach(w => {
            const dotp = cn_dot(this_direction, w.bounds.normal);
            const wid = (w.vertices[0] == this.vertices[vertex_index]) ? 0 : 1;
            const dd = (wid == 0) ? w.bounds.direction : cn_mul(w.bounds.direction, -1);

            //*** check side of the wall */
            if (Math.abs(dotp) > 0.001) {
                const side = (dotp > 0) ? 1 : 0;
                var v0 = (wid == 0) ? w.shape[side] : w.shape[3 - side];

                var x0 = cn_compute_intersection_position(v0, dd, pmin, this_direction);
                var x1 = cn_compute_intersection_position(v0, dd, pmax, this_direction);
                if (display_log) logger.log(`Side ${side} wall candidate - x0 = ${x0} - x1 = ${x1} dir [${w.bounds.direction[0]} ${w.bounds.direction[1]}]`);
                if (x1 < x0) {
                    const xtmp = x0;
                    x0 = x1;
                    x1 = xtmp;
                }
                if (x0 < 0) x0 = 0;
                if (x1 < 0) x1 = 0;
                const dist = (x1 - x0) * Math.abs(dotp);
                if (dist > max_dist) {
                    max_dist = dist;
                    best_wall = w;
                    best_side = side;
                }
            }

            //*** check top of the wall */
            if (w.single[wid] && cn_dot(dd, this_direction) < 0) {
                var v0 = (wid == 0) ? w.shape[0] : w.shape[3];

                var x0 = cn_compute_intersection_position(v0, w.bounds.normal, pmin, this_direction);
                var x1 = cn_compute_intersection_position(v0, w.bounds.normal, pmax, this_direction);
                if (display_log) logger.log(`single ${(wid == 0) ? 2 : 3} wall candidate - x0 = ${x0} - x1 = ${x1} dir [${w.bounds.direction[0]} ${w.bounds.direction[1]}]`);
                if (x1 < x0) {
                    const xtmp = x0;
                    x0 = x1;
                    x1 = xtmp;
                }
                if (x0 < 0) x0 = 0;
                if (x1 < 0) x1 = 0;
                if (x0 > w.wall_type.thickness) x0 = w.wall_type.thickness;
                if (x1 > w.wall_type.thickness) x1 = w.wall_type.thickness;
                const dist = (x1 - x0) * Math.abs(cn_dot(dd, this_direction));
                if (dist > max_dist) {
                    max_dist = dist;
                    best_wall = w;
                    best_side = (wid == 0) ? 2 : 3;
                }
            }
        });

        //*** maybe no result ?  */
        if (best_wall == null) {
            this.delegates[vertex_index].wall = null;
            return false;
        }

        //*** regular case : we are on a wall ! */
        if (display_log) {
            // @ts-ignore
            logger.log(`delegate found wall dir ${best_wall.bounds.direction[0]} ${best_wall.bounds.direction[1]} side ${best_side}`);
            if (this.delegates[vertex_index].wall != best_wall)
                logger.log(`### Change on delegate wall`, this.delegates[vertex_index].wall, best_wall);
            if (this.delegates[vertex_index].side != best_side)
                logger.log(`### Change on delegate side ${this.delegates[vertex_index].side} => ${best_side}`);
        }
        this.delegates[vertex_index].wall = best_wall;
        this.delegates[vertex_index].side = best_side;

        //*** we adjust the position, without changing the wall direction */
        var line = this.delegates[vertex_index].wall.get_side(this.delegates[vertex_index].side);
        const normal = cn_normal(line[1]);
        const y = cn_dot(normal, (cn_sub(line[0], this.delegates[vertex_index].position)));
        const den = cn_dot(this.bounds.direction, normal);
        const lambda = y / den;
        if (display_log) logger.log(`adjusting position : ${lambda}`, line);
        if (Math.abs(lambda) > 0.001) {
            this.delegates[vertex_index].position = cn_add(this.delegates[vertex_index].position, cn_mul(this.bounds.direction, lambda));
            this.build_self();
        }
        return true;
    }

    /**
     * Computes the vertex delegate and fixes it.
     * @param {number} vertex_index
     * @returns true if delegate is ok, false if not ok, whatever the fix.
     */
    fix_delegate(vertex_index) {
        if (this.compute_delegate(vertex_index, false)) return true;
        logger.log('#### fixing wrong delegate');
        this.compute_delegate(vertex_index, true);
        this.delegates[vertex_index] = new cn_wall_delegate(this.vertices[vertex_index].position, null, 0);
        if (this.compute_delegate(vertex_index)) return false;
        this.delegates[vertex_index] = null;
        return false;
    }

    //*** build lines of the wall
    //***      pmax  shape[1]                      shape[2]
    //***      V0.............................................v1
    //***      pmin  shape[0]                      shape[3]
    build_bounds(vertex_index) {
        if (!this.valid) return;

        //*** special case for delegates */
        if (this.delegates[vertex_index] && this.delegates[vertex_index].wall) {
            var line = this.delegates[vertex_index].wall.get_side(this.delegates[vertex_index].side);
            for (var niter = 0; niter < 2; niter++) {
                var shape_index = niter + 2 * vertex_index;
                var p0 = (shape_index == 0 || shape_index == 3) ? this.bounds.pmin : this.bounds.pmax;
                const shp = cn_intersect_line(p0, this.bounds.direction, line[0], line[1]);
                if (shp) this.shape[shape_index] = shp;
            }
            return false;
        }

        //*** Special case : the wall is single. default bounds are ok. */
        this.single[vertex_index] = (this.vertices[vertex_index].previous_wall(this, false, true) == this);
        if (this.single[vertex_index]) return false;

        var vertex = this.vertices[vertex_index];
        var vertex_position = this.vertex_position(vertex_index);
        for (var niter = 0; niter < 2; niter++) {
            var shape_index = niter + 2 * vertex_index;
            var p0 = (shape_index == 0 || shape_index == 3) ? this.bounds.pmin : this.bounds.pmax;
            var wall = (niter == 0) ? vertex.previous_wall(this, true, true) : vertex.next_wall(this, true, true);
            if (wall != this && wall != null && wall.valid && Math.abs(cn_dot(this.bounds.direction, wall.bounds.direction)) < CN_WALL_COSINE_SHAPE_THRESHOLD) {
                var check_vertex = (niter == 0) ? wall.vertices[1] : wall.vertices[0];
                var p1 = (check_vertex == vertex) ? wall.bounds.pmin : wall.bounds.pmax;
                const shp = cn_intersect_line(p0, this.bounds.direction, p1, wall.bounds.direction);
                if (shp) this.shape[shape_index] = shp;
            }
        }
        return false;
    }

    /**
     * Build dependant data, i.e. data once bounds of all walls were computed.
     */
    build_dependant() {

        if (!this.valid) return;
        this._build_openings();
        this._build_space_lines();
    }

    /**
     * Build space lines, i.e. lines from each side that goes along a given space.
     */
    _build_space_lines(display_log = false) {

        //display_log = this.balcony;
        for (var niter = 0; niter < 2; niter++) {
            const avoid_balconies = (!this.balcony && niter == 0);

            this.measure_points = [[[0, 0], [0, 0]], [[0, 0], [0, 0]]];
            //*** loop on both sides */
            for (var side = 0; side < 2; side++) {
                if (display_log) logger.log(`###################### Compute space line ${side}`);
                this.space_lines[side] = [];
                const v0 = this.vertices[side];
                const v1 = this.vertices[1 - side];

                //*** first point : by default it will be the start of the shape */
                var p0 = cn_clone(this.shape[(side == 0) ? 0 : 2]);
                if (this.delegates[side] == null) {
                    if (display_log) logger.log(`start is not a delegate`);

                    var w = v0.previous_wall(this, avoid_balconies, false);
                    var wid = w.vertices.indexOf(v0);
                    if (display_log) logger.log('previous wall', w, wid);
                    if (w.delegates[wid] && w.delegates[wid].wall == this && w.delegates[wid].side == side) {
                        //*** if there is a delegate upon the start of the side, maybe start at this delegate
                        //
                        //       ___________________________________________
                        //
                        //       _______O___________________________________
                        //         |    |
                        //         |    |
                        //
                        if (display_log) logger.log(`there is wall delegate on this start and side`);
                        p0 = cn_clone(w.shape[(wid == 0) ? 1 : 3]);
                    }
                    //*** special case when a balcony ends on a wall */
                    else if (this.balcony && !w.balcony) {
                        const p00 = this.shape[(side == 0) ? 3 : 1];
                        var imp = cn_intersect_segments(p0, p00, w.shape[(wid == 0) ? 1 : 0], w.shape[(wid == 0) ? 2 : 3]);
                        if (imp) p0 = imp;
                        else {
                            w = v0.next_wall(this, avoid_balconies, false);
                            if (!w.balcony) {
                                wid = w.vertices.indexOf(v0);
                                imp = cn_intersect_segments(p0, p00, w.shape[(wid == 0) ? 0 : 1], w.shape[(wid == 0) ? 3 : 2]);
                                if (imp) p0 = imp;
                            }
                        }
                    }
                } else {
                    if (display_log) logger.log(`Start is a delegate`);
                    var w = this.delegates[side].wall;
                    var wid = w.vertices.indexOf(v0);
                    const s = this.delegates[side].side;
                    if (s + 1 - wid == 1) {
                        if (display_log) logger.log(`we add the start shape of the delegate`);
                        //*** if we land on the end of the wall, we add it's end shape */
                        //
                        //   |     |___________________________________________
                        //   |     |
                        //   |     |___________________________________________
                        //   |     |
                        //   |_____O
                        //
                        this.space_lines[side].push(cn_clone(w.shape[(wid == 0) ? 0 : 2]));
                    } else if (s >= 2) {
                        if (display_log) logger.log(`start delegate on end of wall`);
                        //*** the wall may start on the top of another wall. In that case we add the left point */
                        //
                        //_______
                        //       |___________________________________________
                        //       |
                        //       |___________________________________________
                        //       |
                        //_______O
                        //
                        var w = this.delegates[side].wall;
                        const s = this.delegates[side].side;
                        this.space_lines[side].push(cn_clone(w.shape[(s == 2) ? 1 : 3]));
                    }
                }
                this.space_lines[side].push(p0);
                this.measure_points[side][0] = p0;

                //*** Now we add the points at the end of the line */
                var p1 = cn_clone(this.shape[(side == 0) ? 3 : 1]);
                var add_end = this.single[1 - side];
                if (display_log && add_end) logger.log(`top drawing possible`);
                if (this.delegates[1 - side] == null) {
                    if (display_log) logger.log(`End is not a delegate`);
                    var w = v1.next_wall(this, avoid_balconies, false);
                    var wid = w.vertices.indexOf(v1);
                    if (display_log) logger.log('next wall', w, wid);
                    if (w.delegates[wid] && w.delegates[wid].wall == this) {
                        if (display_log) logger.log(`there is wall delegate on this end`);
                        if (w.delegates[wid].side == side) {
                            if (display_log) logger.log(`there is wall delegate on this end, and this side - remove top drawing`);
                            //*** If next wall is a delegate that ends on this side, this is the new limit */
                            //
                            //        ___________________________________________
                            //
                            //        ______________________________________O____
                            //                                              |   |
                            //                                              |   |
                            //
                            p1 = cn_clone(w.shape[(wid == 0) ? 0 : 2]);
                            add_end = false;
                        }
                        //*** if next wall is a delegate that ends on the end of the wall, we will not draw it */
                        else if (w.delegates[wid].side == ((side == 0) ? 3 : 2)) {
                            if (display_log) logger.log(`delegate on top - remove top drawing`, w.delegates[wid]);
                            add_end = false;
                        }
                    } else if (this.balcony && !w.balcony) {
                        const p10 = this.shape[(side == 0) ? 0 : 2];
                        var imp = cn_intersect_segments(p1, p10, w.shape[(wid == 0) ? 0 : 1], w.shape[(wid == 0) ? 3 : 2]);
                        if (imp) p1 = imp;
                        else {
                            w = v1.previous_wall(this, avoid_balconies, false);
                            if (!w.balcony) {
                                wid = w.vertices.indexOf(v1);
                                imp = cn_intersect_segments(p1, p10, w.shape[(wid == 0) ? 1 : 0], w.shape[(wid == 0) ? 2 : 3]);
                                if (imp) p1 = imp;
                            }
                        }
                    }
                    this.space_lines[side].push(p1);
                } else {
                    if (display_log) logger.log(`End is a delegate- remove top drawing`);
                    //*** If this end is a delegate, we already can add the end of the line */
                    //
                    //        ___________________________________________|    |
                    //                                                   |    |
                    //        ___________________________________________O    |
                    //                                                   |    |
                    //
                    add_end = false;
                    this.space_lines[side].push(p1);
                    var w = this.delegates[1 - side].wall;
                    var wid = w.vertices.indexOf(v1);
                    const s = this.delegates[1 - side].side;
                    if (s + wid == 1) {
                        if (display_log) logger.log(`we add the end shape`);
                        //*** if we land on the end of the wall, we add it's end shape */
                        //
                        //        ___________________________________________|    |
                        //                                                   |    |
                        //        ___________________________________________|    |
                        //                                                   |    |
                        //                                                   O____|
                        //
                        this.space_lines[side].push(cn_clone(w.shape[(s == 0) ? 3 : 1]));
                        if (w.single[wid]) {
                            if (display_log) logger.log(`we add the single end shape`);
                            // Then maybe we add its end
                            //        ___________________________________________|    |
                            //                                                   |    |
                            //        ___________________________________________|    |
                            //                                                   |    |
                            //                                                   |____O
                            //
                            this.space_lines[side].push(cn_clone(w.shape[(s == 0) ? 2 : 0]));
                        }
                    } else if (s >= 2) {
                        if (display_log) logger.log(`we add the single end shape of top`);
                        // if we end on its top, we add the top shape
                        //                                                    _________________
                        //        ___________________________________________|
                        //                                                   |
                        //        ___________________________________________|
                        //                                                   O_________________
                        //
                        this.space_lines[side].push(cn_clone(w.shape[(s == 2) ? 0 : 2]));
                    }
                }

                this.measure_points[side][1] = p1;

                //*** Add end : in some cases, we want to add the end of the wall */
                //
                //        ___________________________________________O
                //                                                   |
                //        ___________________________________________|
                //
                if (add_end) {
                    if (display_log) logger.log(`we add this end`);
                    this.space_lines[side].push(cn_clone(this.shape[(side == 0) ? 2 : 0]));
                }
            }
            if (this.balcony) break;
            if (niter == 0)
                this.full_space_lines = this.space_lines.concat([]);
        }
    }

    /**
     * Build opening data
     */
    _build_openings() {
        this.opening_area = [0, this.bounds.length];
        var v0 = this.vertex_position(0);
        // @ts-ignore
        var v1 = this.vertex_position(1);

        var zz = [0, 0, 0, 0];
        for (var niter = 0; niter < 4; niter++) {
            var z = cn_dot(this.bounds.direction, cn_sub(this.shape[niter], v0));
            zz[niter] = z;
            if (niter < 2) {
                if (z > this.opening_area[0])
                    this.opening_area[0] = z;
            } else {
                if (z < this.opening_area[1])
                    this.opening_area[1] = z;
            }
        }

        if (zz[3] <= zz[0] || zz[2] <= zz[1]) {
            this.valid = false;
            return;
        }

        //*** check openings positions
        if (this.openings.length == 0) return;

        if (this.wall_type.free) {
            for (var i in this.openings)
                this.openings[i].valid = false;
            return;
        }

        this.openings.sort(sort_openings_by_position);
        var x = this.opening_area[0];
        for (var i in this.openings) {
            var opening = this.openings[i];
            if (opening.position < x || opening.position + opening.opening_type.width > this.opening_area[1]) {
                opening.valid = false;
                continue;
            }
            opening.valid = true;
            x = opening.position + opening.opening_type.width;
        }
    }

    has_facings() {
        if (this.wall_type.free) return false;
        if (this.wall_type.constructor == cn_wall_type) return true;
        return (this.wall_type.category == 'wall' || this.wall_type.category == 'planter' || this.wall_type.category == 'fence');
    }

    //***********************************************************************************
    //**** Draw the wall in svg
    //***********************************************************************************
    draw(camera, add_classes = [], fill = '') {
        var html = '';
        if (!this.valid) return html;

        var p0 = camera.world_to_screen(this.vertex_position(0));
        var p1 = camera.world_to_screen(this.vertex_position(1));

        var base_class = (this.balcony) ? 'balcony' : 'wall';

        //*** Special case for mouseover or selection : only draw highlight */
        const selected = (add_classes.indexOf('selected') >= 0);
        const mouseover = (add_classes.indexOf('mouseover') >= 0);
        if (selected || mouseover) {
            html += `<line class="wall_highlight ${(selected) ? 'selected' : 'mouseover'}" x1="${p0[0]}" y1="${p0[1]}" x2="${p1[0]}" y2="${p1[1]}" />`;

            var axis_class = base_class + '_axis';
            if (this.locked) axis_class += ' locked';
            html += '<line class=\'' + axis_class + '\'' + ' ' + fill + ' x1=\'' + p0[0] + '\' y1=\'' + p0[1] + '\' x2=\'' + p1[0] + '\' y2=\'' + p1[1] + '\' />';

            return html;
        }

        if (this.status < 0)
            html += '<g opacity=\'0.3\'>';

        var exp = (add_classes.indexOf('exp') >= 0);

        if (this.wall_type.free) {
            html += '<line class=\'' + base_class + '_free ' + add_classes.join(' ') + ' ' + fill
                + '\' x1=\'' + p0[0] + '\' y1=\'' + p0[1] + '\' x2=\'' + p1[0] + '\' y2=\'' + p1[1] + '\' />';
            if (this.status < 0)
                html += '</g>';
            return html;
        }

        var draw_class = base_class;
        var extra_classes = '';
        if (add_classes) {
            extra_classes = ' ' + add_classes.join(' ');
            draw_class += extra_classes;
        }
        if (this.locked) extra_classes += ' locked';
        if (this.shape.length == 4) {
            if (camera.show_wall_type && !this.balcony) {
                var w = camera.world_to_screen_scale * this.wall_type.thickness;
                var h = w * 10;
                html += '<pattern id=\'' + this.wall_type.ID + camera.ID + '\' x=\'0\' y=\'0\' width=\'' + w + '\' height=\'' + h + '\' patternUnits=\'userSpaceOnUse\' viewBox=\'0 0 ' + w + ' ' + h + '\'>';
                html += this.wall_type.draw_svg_icon(w, h, true);
                html += '</pattern>';

                var or = this.bounds.pmin;
                var dx = this.bounds.normal;
                if (this.get_flow_direction()) {
                    or = this.bounds.pmax;
                    dx = cn_mul(this.bounds.normal, -1);
                }
                var ort = camera.world_to_screen(or);
                var dy = this.bounds.direction;

                // @ts-ignore
                function transform(pt) {
                    var pt1 = cn_sub(pt, or);
                    return [camera.world_to_screen_scale * cn_dot(dx, pt1), camera.world_to_screen_scale * cn_dot(dy, pt1)];
                }

                var angle = cn_polar(this.bounds.direction)[1] * 180 / Math.PI;

                var path = '';
                if (this.get_flow_direction())
                    path += '<g transform=\'translate(' + ort[0] + ',' + ort[1] + ') rotate(' + (-90 - angle) + ') scale(-1,1)\'>';
                else
                    path += '<g transform=\'translate(' + ort[0] + ',' + ort[1] + ') rotate(' + (-90 - angle) + ')\'>';
                //
                path += '<path d=\'';
                var s = transform(this.shape[0]);
                path += ' M ' + s[0] + ' ' + s[1];
                s = transform(this.vertices[0].position);
                path += ' L ' + s[0] + ' ' + s[1];
                s = transform(this.shape[1]);
                path += ' ' + s[0] + ' ' + s[1];
                s = transform(this.shape[2]);
                path += ' ' + s[0] + ' ' + s[1];
                s = transform(this.vertices[1].position);
                path += ' ' + s[0] + ' ' + s[1];
                s = transform(this.shape[3]);
                path += ' ' + s[0] + ' ' + s[1] + 'Z\' fill=\'url(#' + this.wall_type.ID + camera.ID + ')\'/>';

                path += '</g>';

                html += path;
            }

            html += '<path class=\'' + draw_class + '\' ' + fill + ' d=\'';
            var s0 = camera.world_to_screen(this.shape[0]);
            var s1 = camera.world_to_screen(this.shape[1]);
            var s2 = camera.world_to_screen(this.shape[2]);
            var s3 = camera.world_to_screen(this.shape[3]);
            html += ' M ' + s0[0] + ' ' + s0[1];
            html += ' L ' + p0[0] + ' ' + p0[1];
            html += ' ' + s1[0] + ' ' + s1[1];
            html += ' ' + s2[0] + ' ' + s2[1];
            html += ' ' + p1[0] + ' ' + p1[1];
            html += ' ' + s3[0] + ' ' + s3[1] + 'Z\' />';
            //html += "<line class='" + base_class + "_line" + extra_classes + "' " + fill + " x1='" + s0[0] + "' y1='" + s0[1] + "' x2='" + s3[0] + "' y2='" + s3[1] + "' />";
            //html += "<line class='" + base_class + "_line" + extra_classes + "' " + fill + " x1='" + s1[0] + "' y1='" + s1[1] + "' x2='" + s2[0] + "' y2='" + s2[1] + "' />";

            for (var side = 0; side < 2; side++) {
                html += `<path class="${base_class}_line${extra_classes}" fill="none" d="`;
                var vertices = this.space_lines[side].map(v => camera.world_to_screen(v));
                for (var k = 0; k < vertices.length; k++) {
                    if (k == 0) html += ' M ';
                    else html += ' L ';
                    html += ` ${vertices[k][0]} ${vertices[k][1]}`;
                }
                html += `" />`
            }

            /** DONT REMOVE !!! Code to check datation on walls
             * const nb_dates = 3;
             for (var dt = CN_CURRENT_DATE-nb_dates;dt<=CN_CURRENT_DATE;dt++)
             {
             if (this.up_to_date_geometry(dt)) break;
             }
             dt += nb_dates - CN_CURRENT_DATE;
             if (dt > 0)
             {
             html += `<g opacity="${dt/nb_dates}">`;

             html += `<path fill="red" stroke="none" d="`;
             html += " M " + s0[0] + " " + s0[1];
             html += " L " + p0[0] + " " + p0[1];
             html += " " + s1[0] + " " + s1[1];
             html += " " + s2[0] + " " + s2[1];
             html += " " + p1[0] + " " + p1[1];
             html += " " + s3[0] + " " + s3[1] + `Z" /></g>`;
             }*/
        }

        //*** Draw wall axis */
        if (!exp) {
            var axis_class = base_class + '_axis';
            if (this.locked) axis_class += ' locked';
            html += '<line class=\'' + axis_class + '\'' + ' ' + fill + ' x1=\'' + p0[0] + '\' y1=\'' + p0[1] + '\' x2=\'' + p1[0] + '\' y2=\'' + p1[1] + '\' />';
        }

        if (this.status < 0)
            html += '</g>';

        /* LEAVE THIS for test
        if (add_classes.indexOf("mouseover") >= 0)
        {
            this._build_space_lines(true);
            logger.log("space lines",this.space_lines);
            for (var side=0;side<2;side++)
            {
                html += `<path stroke="${(side==0)?"red":"blue"}" fill="none" stroke-width="3" d="`;
                var vertices = this.space_lines[side].map(v => camera.world_to_screen(v));
                for (var k=0;k<vertices.length;k++)
                {
                    if (k==0) html += " M ";
                    else html  += " L ";
                    html += ` ${vertices[k][0]} ${vertices[k][1]}`;
                }
                html += `" />`
            }
        }*/
        return html;
    }

    //***********************************************************************************
    //**** Draw the wall shape in svg
    //***********************************************************************************
    draw_shape(camera, draw_class) {
        var html = '';
        if (this.shape.length != 4) return html;

        var p0 = camera.world_to_screen(this.vertex_position(0));
        var p1 = camera.world_to_screen(this.vertex_position(1));

        html += '<path class=\'' + draw_class + '\' d=\'';
        var s0 = camera.world_to_screen(this.shape[0]);
        var s1 = camera.world_to_screen(this.shape[1]);
        var s2 = camera.world_to_screen(this.shape[2]);
        var s3 = camera.world_to_screen(this.shape[3]);
        html += ' M ' + s0[0] + ' ' + s0[1];
        html += ' L ' + p0[0] + ' ' + p0[1];
        html += ' ' + s1[0] + ' ' + s1[1];
        html += ' ' + s2[0] + ' ' + s2[1];
        html += ' ' + p1[0] + ' ' + p1[1];
        html += ' ' + s3[0] + ' ' + s3[1] + 'Z\' />';

        return html;
    }

    //***********************************************************************************
    //**** Project a point on the wall.
    //***********************************************************************************
    project(p) {
        return cn_project_segment(p, this.vertex_position(0), this.vertex_position(1));
    }

    //***********************************************************************************
    //**** returns -1 if no intersection with wall, 0 if on first vertex, 1 if on second vertex, 2 if on wall.
    //***********************************************************************************
    intersects(p, range) {
        const v0 = this.vertex_position(0);
        const v1 = this.vertex_position(1);
        var direction = cn_sub(v1, v0);
        var length = cn_normalize(direction);
        if (length < 0.01) return false;
        var d = cn_sub(p, v0);
        var x = cn_dot(direction, d);
        if (x < -range) return -1;
        if (x > length + range) return -1;

        var normal = cn_normal(direction);
        var z = cn_dot(normal, d);
        if (this.axis == CN_OUTER) z += this.wall_type.thickness * 0.5;
        else if (this.axis == CN_INNER) z -= this.wall_type.thickness * 0.5;
        if (Math.abs(z) > this.wall_type.thickness * 0.5 + range) return -1;
        if (x < range) return 0;
        if (x > length - range) return 1;
        return 2;
    }

    //***********************************************************************************
    //**** Returns true if wall contains the point
    //***********************************************************************************
    contains(p, tolerance = 0) {
        if (typeof (this.bounds) == 'undefined') this.build_self();
        const v0 = this.vertex_position(0);
        const v1 = this.vertex_position(1);
        if (this.bounds.length <= tolerance || this.bounds.length < 0.01) {
            if (cn_dist(p, v0) <= tolerance) return true;
            if (cn_dist(p, v1) <= tolerance) return true;
            return false;
        }
        var dp = cn_sub(p, v0);
        var y = cn_dot(dp, this.bounds.normal);
        if (y < this.bounds.y0 - tolerance) return false;
        if (y > this.bounds.y1 + tolerance) return false;

        var x = cn_dot(dp, this.bounds.direction);
        if (x < -tolerance) return false;
        if (x > this.bounds.length + tolerance) return false;
        return true;
    }

    //***********************************************************************************
    //**** Project a point on the wall.
    //***********************************************************************************
    snap(p, camera) {
        const v0 = this.vertex_position(0);
        const v1 = this.vertex_position(1);
        var direction = cn_sub(v1, v0);
        var length = cn_normalize(direction);
        if (length < camera.snap_world_distance) {
            var vtx = null;
            var min_distance = 0;
            for (var k = 0; k < 2; k++) {
                const vk = (k == 0) ? v0 : v1;
                if (Math.abs(p[0] - vk[0]) > camera.snap_world_distance) continue;
                if (Math.abs(p[1] - vk[1]) > camera.snap_world_distance) continue;
                var dst = cn_dist(p, vk);
                if (vtx && dst > min_distance) continue;
                vtx = this.vertices[k];
                min_distance = dst;
            }
            if (vtx == null) return -1;
            return min_distance;
        }

        var normal = cn_normal(direction);
        var d0 = cn_sub(p, v0);

        var pscal = cn_dot(direction, d0);
        if (pscal <= -camera.snap_world_distance) return -1;
        if (pscal >= length + camera.snap_world_distance) return -1;
        var z = Math.abs(cn_dot(normal, d0));
        if (z > this.wall_type.thickness * 0.5 && z > camera.snap_world_distance) return -1;
        return z;
    }

    //***********************************************************************************
    //**** Split the wall
    //***********************************************************************************
    split(vertex, new_wall = null) {
        var wall = new_wall;
        if (wall) {
            wall.vertices[0] = vertex;
            wall.vertices[1] = this.vertices[1];
        } else {
            wall = new cn_wall(vertex, this.vertices[1], this.wall_type, this.axis, this.scene);
            wall.balcony = this.balcony;
            wall.fence = this.fence;
        }

        wall.delegates[1] = this.delegates[1];
        this.delegates[1] = null;

        this.vertices[1] = vertex;
        vertex.walls.push(this);
        vertex.walls.push(wall);
        wall.spaces[0] = this.spaces[0];
        wall.spaces[1] = this.spaces[1];
        wall.contours[0] = this.contours[0];
        wall.contours[1] = this.contours[1];
        wall.facings[0] = this.facings[0];
        wall.facings[1] = this.facings[1];
        var index = wall.vertices[1].walls.indexOf(this);
        if (index >= 0) wall.vertices[1].walls.splice(index, 1);
        wall.vertices[1].walls.push(wall);
        vertex.update();
        wall.vertices[1].update();

        //*** move openings
        var openings = this.openings;
        this.openings = [];
        var length = cn_dist(this.vertices[0].position, this.vertices[1].position);
        for (var i in openings) {
            if (openings[i].position < length)
                this.openings.push(openings[i]);
            else {
                openings[i].wall = wall;
                openings[i].position -= length;
                wall.openings.push(openings[i]);
            }
        }
        return wall;
    }

    //******************************************************
    //*** get other vertex
    //******************************************************
    other_vertex(v) {
        if (this.vertices[0] == v) return this.vertices[1];
        return this.vertices[0];
    }

    other_vertex_position(v) {
        if (this.vertices[0] == v) return this.vertex_position(1);
        return this.vertex_position(0);
    }

    //******************************************************
    //*** find opening
    //******************************************************
    find_opening(p) {
        if (this.openings.length == 0) return null;
        var z = cn_dot(this.bounds.direction, cn_sub(p, this.bounds.pmin));
        for (var i in this.openings) {
            if (z < this.openings[i].position) continue;
            if (z > this.openings[i].position + this.openings[i].opening_type.width) continue;
            return this.openings[i];
        }
        return null;
    }

    //******************************************************
    //*** find opening before a given position
    //******************************************************
    opening_before(pos) {
        if (this.openings.length == 0) return null;
        var xmax = 0;
        var opening = null;
        for (var i in this.openings) {
            var x = this.openings[i].position + this.openings[i].opening_type.width;
            if (x >= pos) continue;
            if (opening && x < xmax) continue;
            opening = this.openings[i];
            xmax = x;
        }
        return opening;
    }

    opening_after(pos) {
        if (this.openings.length == 0) return null;
        var xmin = 0;
        var opening = null;
        for (var i in this.openings) {
            var x = this.openings[i].position;
            if (x <= pos) continue;
            if (opening && x > xmin) continue;
            opening = this.openings[i];
            xmin = x;
        }
        return opening;
    }

    //******************************************************
    //*** does this wall cross other wall ?
    //******************************************************
    crosses(walls) {
        var p0 = this.vertex_position(0);
        var d0 = this.bounds.direction;
        for (var i in walls) {
            var w = walls[i];
            if (w == this) continue;
            if (w.vertices[0] == this.vertices[0]) continue;
            if (w.vertices[0] == this.vertices[1]) continue;
            if (w.vertices[1] == this.vertices[0]) continue;
            if (w.vertices[1] == this.vertices[1]) continue;

            var dis = cn_dot(d0, w.bounds.normal);
            if (Math.abs(dis) < 0.01) {
                continue;
            }

            var x = cn_dot(w.bounds.normal, cn_sub(w.vertex_position(0), p0)) / dis;
            if (x < -0.1) continue;
            if (x > this.bounds.length + 0.1) continue;
            var pt = cn_add(p0, cn_mul(d0, x));
            var x1 = cn_dot(w.bounds.direction, cn_sub(pt, w.vertex_position(0)));
            if (x1 < -0.1) continue;
            if (x1 > w.bounds.length + 0.1) continue;
            return true;
        }
        return false;
    }

    //***********************************************************************************
    //**** returns true if wall is lossy
    //***********************************************************************************
    is_lossy(storey = null) {
        if (this.is_wall_dormer()) return true;
        return this.spaces[0].is_heated(storey) != this.spaces[1].is_heated(storey);
    }

    //***********************************************************************************
    /**
     * Returns true if heat flows go from space 0 to space 1, false otherwise.
     * Returns true if not defined
     * @param {cn_storey} storey
     * @returns {boolean}
     */
    get_flow_direction(storey = null) {
        if (this.is_wall_dormer()) return true;

        function space_heating(space) {
            if (space == null) return 0;
            if (space.outside) return 0;
            if (!space.has_roof) return 1;
            if (!space.is_indoor(storey)) return 2;
            if (!space.heated) return 3;
            return 4;
        }

        var s0 = space_heating(this.spaces[0]);
        var s1 = space_heating(this.spaces[1]);
        return s1 <= s0;
    }

    //***********************************************************************************
    //**** returns true if wall can have limit conditions
    //***********************************************************************************
    has_limit_conditions(storey = null) {
        if (this.is_wall_dormer()) return false;
        if (!this.is_lossy(storey)) return false;
        if (!this.spaces[0].outside && !this.spaces[1].outside) return false;
        return true;
    }

    //***********************************************************************************
    //**** raytrace to wall axis
    //***********************************************************************************
    raytrace(origin, direction, max_distance) {
        var x = cn_dot(direction, this.bounds.normal);
        if (Math.abs(x) < 0.0001) return false;
        var lambda = cn_dot(this.bounds.normal, cn_sub(this.vertices[0].position, origin)) / x;
        if (lambda <= 0) return false;
        if (lambda >= max_distance) return false;
        var p = cn_add(origin, cn_mul(direction, lambda));
        var e = cn_dot(this.bounds.direction, cn_sub(p, this.vertices[0].position));
        if (e < 0) return false;
        if (e > this.bounds.length) return false;
        const space_index = (x > 0) ? 0 : 1;
        return {
            'distance': lambda,
            'point': cn_add(this.vertices[0].position, cn_mul(this.bounds.direction, e)),
            'wall': this,
            'wall_position': e,
            'wall_side': space_index,
            'spaces': [this.spaces[space_index], this.spaces[1 - space_index]]
        };
    }

    //***********************************************************************************
    //**** raytrace to wall axis
    //***********************************************************************************
    raytrace_bounds(origin, direction, max_distance) {
        var x = cn_dot(direction, this.bounds.normal);
        if (Math.abs(x) < 0.0001) return false;
        const wpoints = (x > 0) ? [this.shape[0], this.shape[3]] : [this.shape[1], this.shape[2]];
        var lambda = cn_dot(this.bounds.normal, cn_sub(wpoints[0], origin)) / x;
        if (lambda <= 0) return false;
        if (lambda >= max_distance) return false;
        var p = cn_add(origin, cn_mul(direction, lambda));
        const xx = cn_dot(this.bounds.direction, cn_sub(p, wpoints[0]));
        if (xx < 0) return false;
        if (cn_dot(this.bounds.direction, cn_sub(p, wpoints[1])) > 0) return false;
        var e = cn_dot(this.bounds.direction, cn_sub(p, this.vertices[0].position));
        const space_index = (x > 0) ? 0 : 1;
        return {
            'distance': lambda,
            'point': cn_add(wpoints[0], cn_mul(this.bounds.direction, xx)),
            'wall': this,
            'wall_position': e,
            'wall_side': space_index,
            'spaces': [this.spaces[space_index], this.spaces[1 - space_index]]
        };
    }

    //***********************************************************************************
    //***Reverse the wall */
    reverse() {
        if (this.axis > 0)
            this.axis = 3 - this.axis;
        this.spaces = this.spaces.reverse();
        for (var i in this.openings)
            this.openings[i].reverse();
    }

    /**
     * Accept element visitor
     *
     * @param {cn_element_visitor} element_visitor
     */
    accept_visitor(element_visitor) {
        element_visitor.visit_wall(this);
    }

    /**
     * Builds a list of 3D vertices (at given height z) of the wall basis
     * @param {number} z
     * @returns {number[][]}
     */
    build_3d_vertices(z) {
        var vertices = [];
        vertices.push(cn_clone_3d(this.shape[0], z));
        vertices.push(cn_clone_3d(this.shape[3], z));
        if (this.delegates[1] == null)
            vertices.push(cn_clone_3d(this.vertices[1].position, z));
        vertices.push(cn_clone_3d(this.shape[2], z));
        vertices.push(cn_clone_3d(this.shape[1], z));
        if (this.delegates[0] == null)
            vertices.push(cn_clone_3d(this.vertices[0].position, z));
        return vertices;
    }

    /**
     * Builds a polygon that forms the wall basis
     * @param {number} z
     * @returns {fh_polygon}
     */
    build_3d_polygon(z) {
        if (this.wall_type.free || this.wall_type.thickness < 0.001) return null;
        var polygon = new fh_polygon([0, 0, z], [0, 0, 1]);
        polygon.add_contour(this.build_3d_vertices(z));
        polygon.compute_contours();
        return polygon;
    }

    /**
     * Builds half the wall basis, corresponding to given input side.
     * @param {number} z
     * @param {number} side
     * @returns {fh_polygon}
     */
    build_3d_half_polygon(z, side) {
        if (this.wall_type.free || this.wall_type.thickness < 0.001) return null;
        var vertices = this.build_3d_vertices(z);
        var nor = (side == 0) ? this.bounds.normal : cn_mul(this.bounds.normal, -1);
        var p0 = cn_add(this.shape[0], cn_mul(this.bounds.normal, 0.5 * this.wall_type.thickness()))
        var x0 = cn_dot(nor, p0);
        var kept_vertices = [];
        var pprec = vertices[vertices.length - 1];
        var xprec = cn_dot(nor, pprec) - x0;
        for (var n = 0; n < vertices.length; n++) {
            var x = cn_dot(nor, vertices[n]) - x0;
            if (x * xprec < 0) {
                const a0 = x / (x - xprec);
                const a1 = -xprec / (x - xprec);
                kept_vertices.push(cn_add(cn_mul(pprec, a0), cn_mul(vertices[n], a1)));
            }
            if (x < 0)
                kept_vertices.push(cn_clone(vertices[n]));

            xprec = x;
            pprec = vertices[n];
        }
        kept_vertices.forEach(v => v.push(z));
        var polygon = new fh_polygon([0, 0, z], [0, 0, 1]);
        polygon.add_contour(kept_vertices);
        polygon.compute_contours();
        return polygon;
    }


    /**
     * Compute next measure point, on the side of the wall.
     * @param {number} start_position
     * @param {number} side
     * @param {boolean} forward
     * @param {boolean} stop_on_window
     * @param {any} output : filled by result
     * @return {number[]}
     */
    get_next_measure_point(start_position, side, forward, stop_on_window = false, output = null) {

        const p0 = (side == 0) ? this.bounds.pmin : this.bounds.pmax;
        var wall = this;
        var position = start_position;
        var nb_walls = 0;
        if (output) {
            output.walls = [];
            output.end = null;
        }
        while (true) {
            if (output) output.walls.push(wall);
            var p = (side == 0) ? wall.bounds.pmin : wall.bounds.pmax;

            //*** Maybe we need to stop on a window ?  */
            if (stop_on_window) {
                //*** along the wall or reverse ?  */
                if ((side == 0) == forward) {
                    for (var no = 0; no < wall.openings.length; no++) {
                        if (!wall.openings[no].valid) continue;
                        const x = wall.openings[no].position;
                        if (x < position) continue;
                        if (output) output.end = wall.openings[no];
                        return cn_add(p, cn_mul(wall.bounds.direction, x));
                    }
                } else {
                    for (var no = wall.openings.length - 1; no >= 0; no--) {
                        if (!wall.openings[no].valid) continue;
                        const x = wall.openings[no].position + wall.openings[no].opening_type.width;
                        if (x > position) continue;
                        if (output) output.end = wall.openings[no];
                        return cn_add(p, cn_mul(wall.bounds.direction, x));
                    }
                }
            }

            //*** check next wall */
            const vertex_index = ((side == 0) == forward) ? 1 : 0;
            const next_wall = (forward) ? wall.vertices[vertex_index].next_wall(wall, false, false) : wall.vertices[vertex_index].previous_wall(wall, false, false);

            //*** dead end */
            if (next_wall == wall)
                return cn_clone(wall.shape[(forward) ? 3 - side * 2 : side * 2]);

            //*** compute next wall */
            var next_side = side;
            var next_forward = forward;
            if (next_wall.vertices[vertex_index] == wall.vertices[vertex_index])
                next_side = 1 - next_side;

            //*** maybe the wall side continues on another wall ? */
            var p1 = (next_side == 0) ? next_wall.bounds.pmin : next_wall.bounds.pmax;
            var p2 = cn_add(p1, cn_mul(next_wall.bounds.direction, next_wall.bounds.length));
            if (Math.abs(cn_dot(this.bounds.normal, cn_sub(p1, p0))) < 0.001 && Math.abs(cn_dot(this.bounds.normal, cn_sub(p2, p0))) < 0.001) {
                wall = next_wall;
                forward = next_forward;
                side = next_side;
                position = ((side == 0) == forward) ? 0 : wall.bounds.length;
                if (nb_walls > 100) {
                    logger.log('ERROR : too many walls');
                    return [0, 0];
                }
                nb_walls++;
                continue;
            }

            //*** we stop !
            if (output) output.end = next_wall;
            const next_index = next_wall.vertices.indexOf(wall.vertices[vertex_index]);
            if (next_wall.delegates[next_index] && next_wall.delegates[next_index].wall == wall && next_wall.delegates[next_index].side == side) {
                logger.log('stopped by wall', next_wall, next_side, next_forward, (next_forward) ? next_side * 2 : 3 - next_side * 2);

                return cn_clone(next_wall.shape[(next_forward) ? next_side * 2 : 3 - next_side * 2]);
            }
            logger.log('stop on end', (forward) ? 3 - side * 2 : side * 2);
            return cn_clone(wall.shape[(forward) ? 3 - side * 2 : side * 2]);
        }
        return [0, 0];
    }

    /**
     * Compute measures of a wall.
     * - First dimension of the array is the side of the wall.
     * - Second dimension of the array is the position on the wall (start, end)
     * - output walls are the first walls out of the measure, or null in case of a dead end.
     * @returns {{points:number[][][],walls:cn_wall[][]}}
     */
    get_measures() {
        var tmp_output = {};
        var output = {
            points: [[], []],
            walls: [[], []]
        };

        for (var side = 0; side < 2; side++) {
            output.points[side].push(this.get_next_measure_point(this.bounds.length / 2, side, false, false, tmp_output));
            output.walls[side].push(tmp_output.end);
            output.points[side].push(this.get_next_measure_point(this.bounds.length / 2, side, true, false, tmp_output));
            output.walls[side].push(tmp_output.end);
        }
        return output;
    }

    /**
     * Returns the facing trimmings that are potentially on the wall
     * @param {number} side
     * @returns {Array<cn_facing_trimming>}
     */
    _get_trimmings(side) {
        const wall_point = (side == 0) ? this.bounds.pmin : this.bounds.pmax;
        var wall_normal = this.bounds.normal;
        if (side == 0) wall_normal = cn_mul(wall_normal, -1);

        function trimming_filter(f) {
            if (f.wall == null) return false;
            if (cn_dot(f.wall_normal, wall_normal) < 0.999) return false;
            if (Math.abs(cn_dot(wall_normal, cn_sub(f.wall_point, wall_point))) > 0.05) return false;
            return true;
        };
        return this.scene.building.facing_trimmings.filter(ft => trimming_filter(ft));
    }

    /**
     * Builds the polygon corresponding to the side of the wall.
     * @param {number} side
     * @param {cn_storey} storey
     * @returns {fh_polygon}
     */
    build_side_polygon(side, storey) {
        const facing_volume = (this.dormer) ? this.dormer.get_clipping_volume(side) : (this.is_facade(-1, storey)) ? storey.facing_volume_no_dormers : storey.facing_volume;
        const storey_bb = (facing_volume) ? facing_volume.get_bounding_box() : new fh_box();

        const max_slab_thickness = storey.get_max_slab_thickness();

        const facade_0 = this.is_facade(0, storey);
        const facade_1 = this.is_facade(1, storey);
        var high_offset = 0;
        if (!this.dormer) {
            if (!facade_0 && !facade_1) {
                high_offset = Math.max(this.spaces[0].slab_offset, this.spaces[1].slab_offset);
            } else if (!facade_0) {
                high_offset = this.spaces[0].slab_offset;
            } else if (!facade_1) {
                high_offset = this.spaces[1].slab_offset;
            }
        }

        let z1 = 0;
        if (this.wall_type.constructor == cn_wall_type)
            z1 = storey_bb.position[2] + storey_bb.size[2];
        else if (this.wall_type.constructor == cn_balcony_type)
            z1 = high_offset + this.wall_type.height;
        else if (this.wall_type.constructor == cn_fence_type)
            z1 = this.wall_type.wall_height;

        var delta = 0;
        if (!storey.exterior && !this.dormer) {
            if (this.is_facade(side, storey)) {
                if (!this.is_facade(1 - side, storey))
                    delta = this.spaces[1 - side].slab_offset - max_slab_thickness;
            } else
                delta = this.spaces[side].slab_offset;
        }

        var p0 = (side == 0) ? this.shape[0] : this.shape[2];
        var p1 = (side == 0) ? this.shape[3] : this.shape[1];

        var nor = cn_normal(cn_sub(p0, p1));
        nor.push(0);
        cnx_normalize(nor);

        if (storey.exterior) {
            delta = p0[2];
            z1 = p0[2] + this.wall_type.wall_height;
        }

        var polygon = new fh_polygon(cnx_add(p0, cnx_mul(nor, -0.001)), nor);

        let contour = [];
        p0[2] = delta;
        contour.push(cnx_clone(p0));
        p0[2] = z1;
        contour.push(cnx_clone(p0));
        p1[2] = z1;
        contour.push(cnx_clone(p1));
        p1[2] = delta;
        contour.push(cnx_clone(p1));
        polygon.add_contour(contour);

        if (facing_volume)
            facing_volume.intersects_polygon(polygon);
        return polygon;
    }

    /**
     * Remove all valide openings of the wall from the polygon
     * @param {fh_polygon} polygon
     * @param {cn_storey} storey
     */
    remove_openings_from_polygon(polygon, storey) {
        var p0 = cnx_clone(this.bounds.pmin);
        const dx = cnx_clone(this.bounds.direction);
        const dy = cnx_clone(this.bounds.normal);
        const dz = [0, 0, 1];

        //*** Apply openings */
        this.openings.forEach(opening => {
            if (opening.valid) {
                p0[2] = this.compute_opening_offset(opening);
                const p = cnx_add(p0, cnx_mul(dx, opening.position));
                const piercing = opening.opening_type.build_piercing_polygon(p, dx, dy, dz);
                polygon.substracts(piercing);
            }
        });
    }

    /**
     * Returns the length of the wall, dependig its side
     * @param {number} side
     * @returns {number}
     */
    get_length(side) {
        if (side == 0)
            return cn_dist(this.shape[0], this.shape[3]);
        return cn_dist(this.shape[1], this.shape[2]);
    }

    /**
     * Builds all facing polygons
     * @param {number} side
     * @param {cn_storey} storey
     * @param {boolean} apply_openings
     * @param {function} facing_filter - If defined, a boolean function that returns true is facing is concerned.
     * @returns
     */
    build_facing_polygons(side, storey, apply_openings = true, facing_filter = null) {
        var polygons = [];
        this.facing_topo[side] = false;
        if (!this.has_facings()) return polygons;

        const facing_volume = (this.dormer) ? this.dormer.get_clipping_volume(side) : (this.is_facade(-1, storey)) ? storey.facing_volume_no_dormers : storey.facing_volume;

        const storey_bb = (facing_volume) ? facing_volume.get_bounding_box() : new fh_box();

        const max_slab_thickness = storey.get_max_slab_thickness();

        let z1 = 0;
        if (this.wall_type.constructor == cn_wall_type)
            z1 = storey_bb.position[2] + storey_bb.size[2];
        else if (this.wall_type.constructor == cn_balcony_type)
            z1 = this.get_high_offset() + this.wall_type.height;
        else if (this.wall_type.constructor == cn_fence_type)
            z1 = this.wall_type.wall_height;

        var parallel_polygons = [];

        function trimming_filter(f, wp, wn) {
            if (f.wall == null) return false;
            if (cn_dot(f.wall_normal, wn) < 0.999) return false;
            return Math.abs(cn_dot(wn, cn_sub(f.wall_point, wp))) < 0.05;
        };

        const topography = this.scene.building.topography;

        const points = [cnx_clone(this.bounds.pmin), cnx_clone(this.bounds.pmax)];
        const trimmings = this._get_trimmings(side);

        //*** Is the native facing of this face concerned ?  */
        const this_facing_filter = (!facing_filter || facing_filter(this.facings[side]));

        //*** If no facing is concerned, skip this side */
        if (facing_filter &&
            !this_facing_filter &&
            !trimmings.some(trimming => facing_filter(trimming.facing)))
            return [];

        var space_lines = [];

        if (this.wall_type.constructor == cn_wall_type)
            space_lines = this.full_space_lines[side];
        else if (this.wall_type.constructor == cn_balcony_type)
            space_lines = this.space_lines[side];
        else if (this.wall_type.constructor == cn_fence_type)
            space_lines = this.wall_type.compute_space_line_slices(this, side, (storey.exterior) ? storey.building.topography : null);

        var delta = 0;
        if (!storey.exterior && !this.dormer) {
            if (this.is_facade(side))
                delta = this.spaces[1 - side].slab_offset - max_slab_thickness;
            else
                delta = this.spaces[side].slab_offset;
        }

        for (var i = 0; i < space_lines.length - 1; i++) {
            var p0 = cnx_clone(space_lines[i]);
            var p1 = cnx_clone(space_lines[i + 1]);
            var nor = cn_normal(cn_sub(p0, p1));
            if (cn_size(nor) < 0.001) continue;
            nor.push(0);
            cnx_normalize(nor);

            if (storey.exterior) {
                delta = p0[2];
                z1 = p0[2] + this.wall_type.wall_height;
            }

            var polygon = new fh_polygon(cnx_add(p0, cnx_mul(nor, -0.001)), nor);
            if (this_facing_filter)
                polygons.push(polygon);

            let contour = [];
            p0[2] = delta;
            contour.push(cnx_clone(p0));
            p0[2] = z1;
            contour.push(cnx_clone(p0));
            p1[2] = z1;
            contour.push(cnx_clone(p1));
            p1[2] = delta;
            contour.push(cnx_clone(p1));
            polygon.add_contour(contour);

            //*** maybe add topography ? */
            if (!this.is_wall_dormer() && this.spaces[side].outside && this.scene.building.facings_above_ground && delta + storey.altitude < topography.max_height + topography.z + this.scene.building.facings_above_ground_height) {
                const topo_sampling = 0.5;
                contour = [];
                const fdir = cn_sub(p1, p0);
                const flength = cn_normalize(fdir);
                let has_topo = false;
                for (var x = -topo_sampling; x < flength + topo_sampling; x += topo_sampling) {
                    const fp = cnx_add(p0, cn_mul(fdir, x));
                    fp[2] = topography.compute_height(fp) + this.scene.building.facings_above_ground_height - storey.altitude;
                    has_topo ||= fp[2] > delta;
                    contour.push(fp);
                }
                if (has_topo) {
                    contour.push(cnx_clone(contour[contour.length - 1], delta - 0.1));
                    contour.push(cnx_clone(contour[0], delta - 0.1));
                    var topo_polygon = new fh_polygon(cnx_add(p0, cnx_mul(nor, -0.001)), nor);
                    topo_polygon.add_contour(contour);
                    polygon.substracts(topo_polygon);
                    this.facing_topo[side] = true;
                }
            }

            //*** Add facing trimmings */
            if (Math.abs(cn_dot(nor, this.bounds.direction)) < 0.01) {
                if (this_facing_filter)
                    parallel_polygons.push(polygon);

                if (trimmings.length) {
                    const face_polygon = polygon.clone();
                    const previous_polygons = [polygon];
                    trimmings.forEach(ft => {
                        const pg = new fh_polygon();
                        const shape = ft.get_shape_3d(null);
                        shape.forEach(v => v[2] -= storey.altitude);
                        pg.add_contour(shape);
                        pg.compute_contours();
                        pg.intersects(face_polygon);
                        if (pg.get_area() > 0.01) {
                            previous_polygons.forEach(pp => pp.substracts(pg));
                            pg['facing_trimming'] = ft;
                            previous_polygons.push(pg);
                            if (!facing_filter || facing_filter(ft.facing)) {
                                parallel_polygons.push(pg);
                                polygons.push(pg);
                            }
                        }
                    });
                }
            }
        }

        //*** Apply openings */
        if (apply_openings) {
            parallel_polygons.forEach(pg => this.remove_openings_from_polygon(pg, storey));
        }

        polygons.forEach(facing_polygon => {

            //*** intersect with roof volume */
            if (facing_volume) facing_volume.intersects_polygon(facing_polygon);

            //*** small translation of the facing to avoid zfight with wall */
            var matrix = new fh_matrix();
            matrix.load_translation(fh_mul(facing_polygon.get_normal(), 0.002));
            facing_polygon.apply_matrix(matrix);
        });
        return polygons;
    }

    _facing_has_topo(side, storey) {
        if (!this.has_facings()) return false;
        if (this.is_wall_dormer()) return false;
        if (!this.spaces[side].outside) return false;
        if (!this.scene.building.facings_above_ground) return false;
        const topography = this.scene.building.topography;
        var delta = 0;
        if (!storey.exterior && !this.dormer) {
            if (this.is_facade(side, storey)) {
                if (!this.is_facade(1 - side, storey))
                    delta = this.spaces[1 - side].slab_offset - storey.get_max_slab_thickness();
            } else
                delta = this.spaces[side].slab_offset;
        }
        if (delta + storey.altitude >= topography.max_height + topography.z + this.scene.building.facings_above_ground_height) return false;
        return true;
    }

    get_bounding_box() {
        const box = new cn_box();
        box.enlarge_point(this.vertex_position(0));
        box.enlarge_point(this.vertex_position(1));
        box.enlarge_distance(this.wall_type.thickness);
        return box;
    }

    /**
     * Returns true if this is a facade wall.
     * If side is not defined (negative value), true is answered if one side at least is a facade side.
     * @param {number} side
     * @param {cn_storey} storey
     * @returns {boolean}
     */
    is_facade(side = -1, storey = null) {
        if (!this.valid) return false;
        if (this.dormer) return (side != 0);
        if (this.wall_type.free) return false;
        if (side != 0 && side != 1) return this.is_facade(0, storey) || this.is_facade(1, storey);

        if (this.scene.storey.exterior) return true;
        if (this.spaces[side].is_indoor(storey)) return false;
        if (this.balcony) {
            return this.spaces[side].outside;
        } else {
            if (!this.spaces[side].is_roof(storey)) return true;
            return this.spaces[1 - side].is_roof(storey);
        }
    }

    /**
     * Returns true if the given side of the wall is outdoor.
     * @param {number} side
     * @param {cn_storey} storey
     * @returns {boolean}
     */
    is_outdoor(side, storey = null) {
        if (this.scene.storey.exterior) return true;
        if (this.is_wall_dormer()) return side == 1;
        return !this.spaces[side].is_indoor(storey);
    }

    /**
     * Returns a simple polygon, used for area calculation or gbxml
     * @param {cn_storey} storey
     * @returns {fh_polygon}
     */
    build_simple_polygon(storey) {
        if (!this.valid) return null;
        if (this.balcony) return null;

        var sp0 = (this.spaces[0].outside) ? null : this.spaces[0];
        var sp1 = (this.spaces[1].outside) ? null : this.spaces[1];

        //*** Wall heights */
        var zmin = 0;
        var zmax = 0;
        var lower_space = null;
        if (sp0) {
            if (sp1) {
                zmin = cn_min(sp0.slab_offset, sp1.slab_offset);
                zmax = cn_max(sp0.slab_offset, sp1.slab_offset);
                lower_space = (sp0.slab_offset < sp1.slab_offset) ? sp0 : sp1
            } else {
                zmin = sp0.slab_offset;
                zmax = sp0.slab_offset;
            }
        } else if (sp1) {
            zmin = sp1.slab_offset;
            zmax = sp1.slab_offset;
        }

        //*** Wall geometry */
        var normal = [this.bounds.normal[0], this.bounds.normal[1], 0];
        var contour = [];
        var direction = cn_clone(this.bounds.direction);
        direction.push(0);
        var p00 = cn_clone(this.vertices[0].position);
        var p10 = cn_clone(this.vertices[1].position);
        p00.push(zmax);
        p10.push(zmax);
        var p01 = fh_clone(p00);
        var p11 = fh_clone(p10);
        p01[2] = p11[2] = storey.get_max_height();

        contour.push(p00);
        contour.push(p01);
        contour.push(p11);
        contour.push(p10);
        var wall_polygon = new fh_polygon(contour[0], normal);
        wall_polygon.add_contour(contour);

        if (storey.space_volume) {
            var roof_section = storey.space_volume.plane_intersection(wall_polygon.get_point(), wall_polygon.get_normal());
            if (roof_section)
                wall_polygon.intersects(roof_section);
        }
        return wall_polygon;
    }

    /**
     * Returns lowest slab height (0, except if spaces around have slab offsets)
     * @returns {number}
     */
    get_lowest_slab_height() {
        if (this.is_wall_dormer()) return 0;
        if (this.spaces[0].outside && this.spaces[1].outside) return 0;
        if (this.spaces[0].outside) return this.spaces[1].slab_offset;
        if (this.spaces[1].outside) return this.spaces[0].slab_offset;
        return Math.min(this.spaces[0].slab_offset, this.spaces[1].slab_offset);
    }

    /**
     * Returns heighest slab height (0, except if spaces around have slab offsets)
     * @returns {number}
     */
    get_highest_slab_height() {
        if (this.is_wall_dormer()) return 0;
        if (this.spaces[0].outside && this.spaces[1].outside) return 0;
        if (this.spaces[0].outside) return this.spaces[1].slab_offset;
        if (this.spaces[1].outside) return this.spaces[0].slab_offset;
        return Math.max(this.spaces[0].slab_offset, this.spaces[1].slab_offset);
    }

    /**
     * returns true if wall connexion to vertices hasn't changed since date
     * @param {number} date
     * @returns {boolean}
     */
    up_to_date_vertex_geometry(date) {
        if (this.get_date('wall_type') > date) return false;
        if (this.get_date('axis') > date) return false;
        if (this.get_date('delegates') > date) return false;
        if (this.vertices[0].get_date('position') > date) return false;
        if (this.vertices[1].get_date('position') > date) return false;
        if (!this.wall_type.up_to_date_thickness(date)) return false;
        return true;
    }

    /**
     * returns true if wall geometry hasn't changed since date
     * @param {number} date
     * @returns {boolean}
     */
    up_to_date_geometry(date) {
        if (!this.up_to_date_vertex_geometry(date)) return false;
        if (!this.vertices[0].up_to_date_geometry(date)) return false;
        if (!this.vertices[1].up_to_date_geometry(date)) return false;
        return true;
    }

    /**
     * Updates facings geometries
     * @param {cn_3d_building} building_3d
     * @param {cn_storey} storey
     */
    update_3d_facings(building_3d, storey) {
        var res = 0;
        if (!this.has_facings()) return res;

        const filter = ob => {
            if (ob.cnmap_storey != storey) return false;
            if (!ob.json_object || (ob.json_object.Code_BIM != CODES_BIM_OUVRAGES.facing_facade && ob.json_object.Code_BIM != CODES_BIM_OUVRAGES.facing_wall)) return false;
            if (ob.cnmap_element == this) return true;
            if (ob.cnmap_element.constructor == cn_facing_trimming && ob.json_object.cnmap_wall == this) return true;
            return false;
        };
        let all_facing_objects = building_3d.get_3d_objects_filter(filter);

        for (var side = 0; side < 2; side++) {
            const facing_objects = all_facing_objects.filter(obj => obj.json_object.cnmap_wall_side == side);

            //*** check that anything has changed */
            var need_build = true;
            while (true) {
                //*** Change in topography */
                if ((storey.exterior || !this.spaces[side].has_roof) &&
                    (this.facing_topo[side] || this._facing_has_topo(side, storey)) &&
                    (!building_3d._building.topography.up_to_date(building_3d._update_date, 'heights') ||
                        !building_3d._building.up_to_date(building_3d._update_date, 'facings_above_ground') ||
                        !building_3d._building.up_to_date(building_3d._update_date, 'facings_above_ground_height'))) {
                    break;
                }
                //*** Change if some trimmings have changed */
                if (this._get_trimmings(side).some(wt => !wt.up_to_date_shape(building_3d._update_date))) {
                    break;
                }

                var old_trimmings = [];
                facing_objects.forEach(ob => {
                    if (ob.json_object && ob.json_object.cnmap_trimmings) old_trimmings = ob.json_object.cnmap_trimmings;
                });

                //*** change if an old trimming no longer exists */
                if (old_trimmings.some(wt => this.scene.building.facing_trimmings.indexOf(wt) < 0)) {
                    break;
                }

                //*** change if an old trimming is not up to date */
                if (old_trimmings.some(wt => !wt.up_to_date_shape(building_3d._update_date))) {
                    break;
                }
                need_build = false;
                break;
            }
            if (!need_build) continue;

            //*** There were changes */
            building_3d.remove_objects(facing_objects);
            building_3d.add_bbp_objects(this.build_bbp_facings(side, storey, true));
            res++;
        }
        return res;
    }

    build_footprint_contour() {
        var vertices_2d = [];
        vertices_2d.push(cn_clone(this.shape[0]));
        if (this.delegates[0] == null)
            vertices_2d.push(cn_clone(this.vertices[0].position));
        vertices_2d.push(cn_clone(this.shape[1]));
        vertices_2d.push(cn_clone(this.shape[2]));
        if (this.delegates[1] == null)
            vertices_2d.push(cn_clone(this.vertices[1].position));
        vertices_2d.push(cn_clone(this.shape[3]));
        return vertices_2d;
    }

    build_footprint(z) {
        var vertices_2d = this.build_footprint_contour();

        for (var i in vertices_2d)
            vertices_2d[i].push(z);
        var footprint = new fh_polygon(vertices_2d[0], [0, 0, 1]);
        footprint.add_contour(vertices_2d);
        return footprint;
    }

    /**
     * Returns the lowest point of the wall, depending on lab offsets.
     * @returns {number}
     */
    get_low_offset() {
        if (this.is_wall_dormer()) return 0;
        var low_offset = this.spaces[0].slab_offset;
        if (!this.spaces[0].outside && !this.spaces[1].outside)
            return Math.min(this.spaces[0].slab_offset, this.spaces[1].slab_offset);
        if (!this.spaces[1].outside)
            return this.spaces[1].slab_offset;
        if (!this.spaces[0].outside)
            return this.spaces[0].slab_offset;
        return -this.parent.storey.get_max_slab_thickness();
    }

    /**
     * Returns the hoghest point of the wall, depending on lab offsets.
     * @returns {number}
     */
    get_high_offset() {
        if (this.is_wall_dormer()) return 0;
        var high_offset = this.spaces[0].slab_offset;
        if (!this.spaces[0].outside && !this.spaces[1].outside)
            high_offset = Math.max(this.spaces[0].slab_offset, this.spaces[1].slab_offset);
        else if (this.spaces[0].outside)
            high_offset = this.spaces[1].slab_offset;
        return high_offset;
    }

    /**
     * Returns the opening vertical offset, relatively to 0.
     * @param {cn_opening} opening
     * @returns {number}
     */
    compute_opening_offset(opening) {
        if (this.dormer) return this.dormer.get_opening_height(opening);
        const facade_0 = this.is_facade(0);
        const facade_1 = this.is_facade(1);

        if (!facade_0 && !facade_1) {
            return Math.max(this.spaces[0].slab_offset, this.spaces[1].slab_offset);
        } else if (!facade_0) {
            return this.spaces[0].slab_offset;
        } else if (!facade_1) {
            return this.spaces[1].slab_offset;
        }
        return 0;
    }

    /**
     * Builds the 3D shape of the wall
     * @param {cn_storey} storey
     * @param {boolean} remove_openings
     * @returns {fh_solid}
     */
    build_3d_solid(storey, remove_openings = true) {
        // @ts-ignore
        return this.get_lazy_data(() => {
            var solid_wall = new fh_solid();

            const z0 = this.get_low_offset();
            var footprint = this.build_footprint(z0);

            const roof_volume = (this.dormer) ? this.dormer.get_clipping_volume(0) : (this.is_facade(-1, storey)) ? storey.build_space_volume(false) : storey.build_space_volume(true);
            const box = roof_volume.get_bounding_box();
            var delta_h = box.size[2] + box.position[2];
            solid_wall.extrusion(footprint, [0, 0, delta_h - z0]);

            //*** Add openings
            if (remove_openings && this.openings.length > 0) {
                //*** data for hole geometry
                var dx = [this.bounds.direction[0], this.bounds.direction[1], 0];
                var dy = [-this.bounds.direction[1], this.bounds.direction[0], 0];
                var dz = [0, 0, 1];
                var p0 = [this.bounds.pmin[0], this.bounds.pmin[1], 0];
                p0 = fh_sub(p0, fh_mul(dy, 0.01));

                //*** loop on openings
                this.openings.filter(op => op.valid).forEach(opening => {
                    p0[2] = this.compute_opening_offset(opening);
                    //*** add opening hole
                    var opening_solid = opening.opening_type.build_piercing_solid(fh_add(p0, fh_mul(dx, opening.position)), dx, dy, dz, this.wall_type.thickness + 0.02);
                    solid_wall.substracts(opening_solid);
                });
            }
            solid_wall.intersects(roof_volume);

            return solid_wall;
        }, (remove_openings) ? "solid_pierced" : "solid_unpierced", storey);
    }

    /**
     * Builds bbp objects that match facings
     * @param {number} side
     * @param {cn_storey} storey
     * @param {boolean} cnmap_pointers
     * @param {BbpHelper} bbpHelper
     * @returns
     */
    build_bbp_facings(side, storey, cnmap_pointers = false, bbpHelper = null) {
        if (!this.has_facings()) return [];
        //*** Build wall facings */
        var facings = this.build_facing_polygons(side, storey);

        var facing_index = 0;
        const bbp_objects = [];
        facings.forEach(facing_polygon => {
            const area = facing_polygon.get_area();
            if (area > 0.01) {
                //*** build facing */
                var bbp_facing = {};
                bbp_objects.push(bbp_facing);
                bbp_facing.ID = cn_building.create_unique_id(storey, this, side, facing_index, 'facing');
                facing_index++;
                if (this.is_outdoor(side)) {
                    bbp_facing.Name = 'Revêtement de façade';
                    bbp_facing.Code_BIM = CODES_BIM_OUVRAGES.facing_facade;
                } else {
                    bbp_facing.Name = 'Revêtement de mur';
                    bbp_facing.Code_BIM = CODES_BIM_OUVRAGES.facing_wall;
                    if (this.spaces[side]) bbp_facing.SPACE = cn_building.create_unique_id(storey, this.spaces[side]);
                }
                if (bbpHelper) {
                    Object.assign(bbp_facing, bbpHelper.getWallFacingParameters(this, side));
                }
                bbp_facing.storey = (this.dormer) ? storey.storey_index + 1 : storey.storey_index;
                bbp_facing[CODES_BIM_PARAMETRES_OUVRAGES.facing_surface] = area;
                bbp_facing[CODES_BIM_PARAMETRES_OUVRAGES.wall_facing] = cn_building.create_unique_id(storey, this);
                if (cnmap_pointers) {
                    bbp_facing.cnmap_storey = storey;
                    if (facing_polygon['facing_trimming'])
                        bbp_facing.cnmap_element = facing_polygon['facing_trimming'];
                    else {
                        bbp_facing.cnmap_element = this;
                        bbp_facing.cnmap_trimmings = [];
                        facings.forEach(f => {
                            if (f['facing_trimming']) bbp_facing.cnmap_trimmings.push(f)
                        });
                    }
                    bbp_facing.cnmap_wall = this;
                    bbp_facing.cnmap_wall_side = side;
                }

                const geometry = cn_bbp_geometry.from_polygon(facing_polygon, storey.altitude);
                logger.log('built bbp object', facing_polygon, geometry);
                const facing = facing_polygon['facing_trimming'] ? facing_polygon['facing_trimming'].facing : this.facings[side];
                if (facing) {
                    geometry.texture = cn_image_dir() + 'texture_' + facing.texture + '.jpg';
                    geometry.color = [...cn_color_hexa_to_rgb(facing.color || '#FFFFFF'), 0.5];
                }

                bbp_facing.geometries = [geometry];

                if (storey.exterior) {
                    bbp_facing.storey = storey.building.storeys[storey.building.storey_0_index].storey_index;
                    bbp_facing.topography = '';
                }
            }
        });

        return bbp_objects;
    }

    /**
     * Updates facings textures
     * @param {cn_3d_building} building_3d
     * @param {cn_storey} storey
     */
    update_3d_facing_texture(building_3d, storey) {
        const objects = building_3d.get_3d_objects(this).filter(obj => obj.cnmap_storey == storey && (obj.json_object.Code_BIM == CODES_BIM_OUVRAGES.facing_wall || obj.json_object.Code_BIM == CODES_BIM_OUVRAGES.facing_facade));
        const target_facings = this.facings.map(f => ({
            texture: f ? cn_image_dir() + 'texture_' + f.texture + '.jpg' : '',
            color: f && f.color ? f.color : '#FFFFFF'
        })
        );
        objects.forEach(obj => {
            const target_facing = target_facings[obj.json_object.cnmap_wall_side];
            const target_color = [...cn_color_hexa_to_rgb(target_facing.color || '#FFFFFF'), 0.5];
            fh_scene.update_mesh_color(obj._meshes[0], target_color, target_facing.texture);
            obj._meshes[0]._original_material.needsUpdate = true;
        });
    }

    /**
     * @param {cn_storey} storey
     * @returns {cn_wall_metrics}
     */
    get_metrics(storey) {
        // @ts-ignore
        return this.get_lazy_data(() => {
            return new cn_wall_metrics(this, storey);
        }, "metrics", storey);
    }
}

