import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Volume } from '../../../model/bien.model';
import { MatDialog } from '@angular/material/dialog';
import { CalculatriceModaleComponent } from '../calculatrice-modale/calculatrice-modale.component';

@Component({
    selector: 'app-volume-block',
    templateUrl: './volume-block.component.html',
    styleUrls: ['./volume-block.component.scss'],
})
export class VolumeBlockComponent implements OnInit, OnChanges {
    @Output()
    remove = new EventEmitter<{ idVolume: String; indexSurface: number }>();

    @Output()
    declareSurfaceEvent = new EventEmitter<{ surfaceToDeclare: string; volume: Volume }>();

    @Input()
    disabled: Boolean = true;

    @Input()
    label = '';

    @Input()
    volume: Volume = null;

    @Input()
    indexSurface: number = 0;

    @Input()
    nbVolumes: number = 0;

    @Input()
    longueurMax: number = 0;

    @Input()
    volumesSurface = {};

    buttonActive = false;
    surface: string = '';

    constructor(private matDialog: MatDialog) {}

    ngOnInit(): void {
        if (this.volume && this.volume.id && this.volumesSurface[this.volume.id]) {
            this.surface = this.volumesSurface[this.volume.id].toFixed(0);
        }

        if (this.longueurMax >= 15) {
            this.buttonActive = true;
        }
    }

    /**
     * Emet la suppression du volume à la surface correspondante au composant parent
     */
    delete() {
        this.remove.emit({ idVolume: this.volume.id, indexSurface: this.indexSurface });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.longueurMax) {
            if (this.longueurMax >= 15) {
                this.buttonActive = true;
            } else {
                this.buttonActive = false;
            }
        }
        if (changes.volumesSurface) {
            this.surface = changes.volumesSurface.currentValue;
        }
    }

    /**
     * Ouvre la calculatrice pour entrer la surface déclaré d'un volume
     */
    openCalculator() {
        return this.matDialog
            .open(CalculatriceModaleComponent, {
                data: {
                    mode: 'calculatrice',
                    originalValue: this.surface,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.surface = result.resultat;
                    this.declareSurfaceEvent.emit({ surfaceToDeclare: this.surface, volume: this.volume });
                }
            });
    }
}
