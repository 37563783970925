<div class="zone-propriete-modal" [formGroup]="formBesoin">
    <p class="header">Propriétés: Besoin de prélèvement R{{ formBesoin.get('numero').value }}</p>
    <div class="zone-content">
        <div class="zone-content-column col-md-6">
            <mat-form-field class="flex-grow-0 form-inline">
                <label>Objectif de mesurage<span class="required"></span></label>
                <mat-select
                    matInput
                    name="objectif"
                    id="objectif"
                    formControlName="objectifMesurage"
                    [compareWith]="polluantService.compareObjectifs"
                >
                    <mat-option *ngFor="let condition of contenuListesDeroulantes.listeObjMesurage" [value]="condition">
                        {{ condition.reference }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
            <mat-form-field class="flex-grow-0 form-inline">
                <label>Numéro: </label>
                <input matInput type="number" name="numero" id="numero" formControlName="numero" />
            </mat-form-field>
            <mat-form-field class="flex-grow-0 form-inline">
                <label>Pièce: <span class="required"></span></label>
                <mat-select matInput name="piece" id="piece" formControlName="pieceId">
                    <ng-container *ngFor="let volumeId of contenuListesDeroulantes.listeVolumesId">
                        <ng-container *ngFor="let volume of volumes">
                            <mat-option *ngIf="volumeId == volume.id" [value]="volume.id">{{ volume.nom }}</mat-option>
                        </ng-container>
                    </ng-container>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="flex-grow-0 form-inline">
                <label>MPCA: <span class="required"></span></label>
                <mat-select matInput name="mpca" id="mpca" formControlName="mpcaId">
                    <mat-option *ngFor="let mpca of contenuListesDeroulantes.listeMpca" [value]="mpca.id">{{
                        mpca.general.materiauxClient
                    }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="flex-grow-0 form-inline">
                <label>Localisation: </label>
                <input matInput type="text" name="localisation" id="localisation" formControlName="localisation" />
            </mat-form-field>
        </div>
        <app-sequence-list class="sequence-list col-md-6" [sequenceList]="formBesoin.get('listeSequences')">
        </app-sequence-list>
    </div>

    <div class="zone-action">
        <div class="group-button">
            <button mat-flat-button (click)="modalAction('cancel')">Annuler</button>
            <button
                mat-flat-button
                color="primary"
                (click)="modalAction('saveAndClose')"
                *ngIf="isInEditMode"
                [disabled]="formBesoin.invalid"
            >
                Sauvegarder
            </button>
        </div>
    </div>
</div>
