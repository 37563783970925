<div class="pb-5">
    <div class="block-title p-2 pl-5 my-3 d-inline-block" *ngIf="data">{{ data.title }}</div>
    <div class="block-content px-5 ml-2 mb-4">
        <ng-container>
            <table class="mt-4">
                <tr>
                    <th>ÉCHANTILLONS</th>
                    <th>TYPES D'ANALYSE</th>
                    <th>TYPE</th>
                    <th>PÉRIMÈTRE / ZONE DE MATÉRIAUX</th>
                    <th>LAVÉ</th>
                    <th>DISSOCIATION</th>
                    <th>ANALYSE DE TOUTES LES COUCHES PRÉSENTES DANS LE SACHET</th>
                    <th>LIMITATION ÉVENTUELLE DE FIBRES</th>
                    <th>POLLUTION SURFACIQUE PRÉLÈVEMENT</th>
                </tr>

                <ng-container *ngFor="let echantillon of diagnosticData.echantillons">
                    <tr>
                        <td>
                            <div class="d-flex flex-column">
                                <span class="font-weight-bold">{{ echantillon.reference }}</span>
                                <span
                                    ><span class="font-weight-bold">Désignation :</span>
                                    {{
                                        (echantillon.ouvrage ? echantillon.ouvrage : 'n.c') +
                                            ' - ' +
                                            (echantillon.partieOuvrage ? echantillon.partieOuvrage : 'n.c') +
                                            ' - ' +
                                            (echantillon.materiaux ? echantillon.materiaux : 'n.c')
                                    }}</span
                                >
                            </div>
                        </td>
                        <td>
                            <div class="d-flex flex-column">
                                <span>HAP</span>
                                <span *ngIf="echantillon.amiantePresent"
                                    >Amiante naturellement présent et Amiante ajouté délibérément</span
                                >
                            </div>
                        </td>
                        <td>
                            <div class="d-flex flex-column">
                                <span class="font-weight-bold">{{ echantillon.typeEchantillon }}</span>
                            </div>
                        </td>
                        <td>
                            <div class="d-flex flex-column">
                                <span class="font-weight-bold">{{ echantillon.nomPerimetre + ' / ' }}</span>
                                <span>{{ echantillon.nomZone }}</span>
                            </div>
                        </td>
                        <td>
                            <span class="font-weight-bold" [ngClass]="echantillon.lave ? 'success' : 'warn'">{{
                                echantillon.lave ? 'Oui' : 'Non'
                            }}</span>
                        </td>
                        <td>
                            <span class="font-weight-bold" [ngClass]="echantillon.dissociation ? 'success' : 'warn'">{{
                                echantillon.dissociation ? 'Oui' : 'Non'
                            }}</span>
                        </td>
                        <td>
                            <span
                                class="font-weight-bold"
                                [ngClass]="echantillon.analyseToutesCouches ? 'success' : 'warn'"
                                >{{ echantillon.analyseToutesCouches ? 'Oui' : 'Non' }}</span
                            >
                        </td>
                        <td>
                            <span
                                class="font-weight-bold"
                                [ngClass]="echantillon.limitationFibres ? 'success' : 'warn'"
                                >{{ echantillon.limitationFibres ? 'Oui' : 'Non' }}</span
                            >
                        </td>
                        <td>
                            <span
                                class="font-weight-bold"
                                [ngClass]="echantillon.pollutionSurfacique ? 'success' : 'warn'"
                                >{{ echantillon.pollutionSurfacique ? 'Oui' : 'Non' }}</span
                            >
                        </td>
                    </tr>
                </ng-container>
                <tr *ngIf="diagnosticData.echantillons.length === 0">
                    <td colspan="9" class="text-center p-2">Aucun échantillon</td>
                </tr>
            </table>
        </ng-container>
    </div>
</div>
