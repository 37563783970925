import * as moment from 'moment';
import { DATE_FORMAT } from '../constants/cndiag.constants';

/**
 * Callback par défaut de l'opérateur "required""<br/>
 * Recheche si "value" n'est pas null/undefined ou si sa taille est suppérieur à 0
 */
export const REQUIRED = (refValue: any, value: any) => {
    return value !== null && value !== undefined && value.length > 0;
};

/**
 * Callback par défaut de l'opérateur "equal"
 */
export const EQUAL = (refValue: any, value: any) => {
    return value === refValue;
};

/**
 * Callback par défaut de l'opérateur "notEqual"
 */
export const NOTEQUAL = (refValue: any, value: any) => {
    return value !== refValue;
};

/**
 * Callback par défaut de l'opérateur "in"<br/>
 * Recherche si "value" est contenue dans "refValue"
 */
export const IN = (refValue: any, value: any) => {
    return refValue.indexOf(value) > -1;
};

/**
 * Callback par défaut de l'opérateur "notIn"<br/>
 * Recherche si "value" n'est pas contenue dans "refValue"
 */
export const NOTIN = (refValue: any, value: any) => {
    return refValue.indexOf(value) === -1;
};

/**
 * Callback par défaut de l'opérateur "contains"<br/>
 * Recheche si "refValue" est contenue dans "value"
 */
export const CONTAINS = (refValue: any, value: any) => {
    return value.indexOf(refValue) > -1;
};

/**
 * Callback par défaut de l'opérateur "notContains"<br/>
 * Recheche si "refValue" n'est pas contenue dans "value"
 */
export const NOTCONTAINS = (refValue: any, value: any) => {
    return value.indexOf(refValue) === -1;
};

/**
 * Callback par défaut de l'opérateur "lessThan""<br/>
 * Recheche si "value" est inférieur à "refValue"
 */
export const LESSTHAN = (refValue: any, value: any) => {
    if (typeof refValue === 'number' && typeof value === 'number') {
        return value < refValue;
    }
    return false;
};

/**
 * Callback par défaut de l'opérateur "lessEqThan""<br/>
 * Recheche si "value" est inférieur ou égale à "refValue"
 */
export const LESSEQTHAN = (refValue: any, value: any) => {
    if (typeof refValue === 'number' && typeof value === 'number') {
        return value <= refValue;
    }
    return false;
};

/**
 * Callback par défaut de l'opérateur "greaterThan""<br/>
 * Recheche si "value" est suppérieur à "refValue"
 */
export const GREATERTHAN = (refValue: any, value: any) => {
    if (typeof refValue === 'number' && typeof value === 'number') {
        return value > refValue;
    }
    return false;
};

/**
 * Callback par défaut de l'opérateur "greaterEqThan""<br/>
 * Recheche si "value" est suppérieur ou égale à "refValue"
 */
export const GREATEREQTHAN = (refValue: any, value: any) => {
    if (typeof refValue === 'number' && typeof value === 'number') {
        return value >= refValue;
    }
    return false;
};

/**
 * Callback par défaut de l'opérateur "dateBefore"<br/>
 * Recheche si la date "value" est avant la date de référence "refValue"<br/>
 * Formate de date YYYY-MM-DDTHH:mm:ss
 */
export const DATEBEFORE = (refValue: any, value: any) => {
    if (refValue && value) {
        const refDate = moment(refValue, DATE_FORMAT);
        const dateValue = moment.isDate(value)
            ? moment(value)
            : moment.isMoment(value)
            ? value
            : typeof value === 'string'
            ? moment(value, DATE_FORMAT)
            : null;
        if (dateValue) {
            return dateValue.isBefore(refDate);
        }
    }
    return false;
};

/**
 * Callback par défaut de l'opérateur "dateAfter"<br/>
 * Recheche si la date "value" est après la date de référence "refValue"<br/>
 * Formate de date YYYY-MM-DDTHH:mm:ss
 */
export const DATEAFTER = (refValue: any, value: any) => {
    if (refValue && value) {
        const refDate = moment(refValue, DATE_FORMAT);
        const dateValue = moment.isDate(value)
            ? moment(value)
            : moment.isMoment(value)
            ? value
            : typeof value === 'string'
            ? moment(value, DATE_FORMAT)
            : null;
        if (dateValue) {
            return dateValue.isAfter(refDate);
        }
    }
    return false;
};

/**
 * Callback par défaut de l'opérateur "dateEqual"<br/>
 * Recheche si la date "value" est égale à la date de référence "refValue"<br/>
 * Formate de date YYYY-MM-DDTHH:mm:ss
 */
export const DATEEQUAL = (refValue: any, value: any) => {
    if (refValue && value) {
        const refDate = moment(refValue, DATE_FORMAT);
        const dateValue = moment.isDate(value)
            ? moment(value)
            : moment.isMoment(value)
            ? value
            : typeof value === 'string'
            ? moment(value, DATE_FORMAT)
            : null;
        if (dateValue) {
            return dateValue.isSame(refDate);
        }
    }
    return false;
};

/**
 * Classe d'oprérateur évaluant une condition à partir d'un callback
 */
export class Operator {
    private readonly _name: string;
    private readonly callback: (refValue: any, value: any) => boolean;

    constructor(name: string, callback: (refValue: any, value: any) => boolean) {
        this._name = name;
        this.callback = callback;
    }

    get name(): string {
        return this._name;
    }

    evaluate(refValue: any, value: any): boolean {
        if (this.callback) {
            return this.callback(refValue, value);
        } else {
            throw new Error('Callback manquant');
        }
    }
}
