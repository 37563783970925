/**
 * Type de document.
 */
import { MongoUtils } from 'src/app/commons-lib';
import { Conformite } from './conformite.model';
import { ReferencePrestation } from './reference-prestation.model';
import { TypePrestation } from './type-prestation.model';

export class TypeDocument {
    id: string;

    nom: string;

    code: string;

    /**
     * Type de prestation associé au document
     */
    typePrestations?: TypePrestation[] = [];

    /**
     * Correspond à l'identifiant de la catégorie contenant le type de document
     */
    idCategorie: string;

    /**
     * Cet attribut n'existe que côté front et est utilisé dans le tableau admin pour pouvoir trier et filtrer par catégorie
     */
    nomCategorie?: string;

    /**
     * Points de controle à afficher suivant le type de prestation
     */
    typeDocumentCheckpoint?: TypeDocumentCheckpoint[] = [];
}

export class TypeDocumentCheckpoint {
    referencePrestation: ReferencePrestation;
    checkpoints: DocumentCheckpoint[] = [];
    // Défini si le type de document est obligatoire pour la referencePrestation
    isRequired = false;
    // Défini si le type de document est modifiable pour la referencePrestation
    isEditable = false;
    // Défini la conformité du document pour la referencePrestation
    // (le document peut être CONFORME pour une prestation et NON_CONFORME pour une autre prestation)
    conformite: Conformite = Conformite.NON_CONFORME;

    constructor(referencePrestation: ReferencePrestation, checkpoints: DocumentCheckpoint[] = [], isRequired = false, isEditable = false) {
        this.referencePrestation = referencePrestation;
        this.checkpoints = checkpoints;
        this.isRequired = isRequired;
        this.isEditable = isEditable;
    }
}

export class DocumentCheckpoint {
    id: string = MongoUtils.generateObjectId();
    label: string;
    value: boolean;

    /**
     * Commentaires
     */
    commentairesId: string[] = [];
}
