export enum Conformite {
    /** Conforme */
    'CONFORME' = 'CONFORME',

    /** A justifier */
    'A_JUSTIFIER' = 'A_JUSTIFIER',

    /** Non conforme */
    'NON_CONFORME' = 'NON_CONFORME',
}
