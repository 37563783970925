<h1 mat-dialog-title class="primary d-flex justify-content-between">
    <span *ngIf="data?.newLegend">Ajouter une légende</span>
    <span *ngIf="!data?.newLegend">{{ 'éditer' | titlecase }} une légende</span>
    <button mat-button (click)="cancel()">
        <lib-icon>close</lib-icon>
    </button>
</h1>

<div mat-dialog-content [formGroup]="form">
    <div class="d-flex">
        <div class="w-100">
            <mat-form-field class="w-100">
                <mat-label>Modèle par défaut</mat-label>
                <mat-select (selectionChange)="changeTemplate($event.value)" [disabled]="disabledShapeTypeAndTemplate">
                    <mat-option *ngFor="let l of legends" [value]="l">
                        {{ l?.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-100">
                <input matInput placeholder="Nom de la légende" formControlName="name" [(ngModel)]="legend.name" />
            </mat-form-field>

            <mat-form-field class="w-100">
                <mat-label>Forme</mat-label>
                <mat-select
                    [(ngModel)]="legend.shapeType"
                    [disabled]="disabledShapeTypeAndTemplate"
                    formControlName="shapeType"
                >
                    <mat-option *ngFor="let type of shapetypes" [value]="type" [disabled]="isOnEdit(type)">
                        {{ type }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-100">
                <mat-label>Type de bordure</mat-label>
                <mat-select [(ngModel)]="legend.borderType" formControlName="borderType">
                    <mat-option *ngFor="let type of bordertypes" [value]="type">
                        {{ type?.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="d-flex justify-content-around mb-3">
                <span> Fond </span>
                <span> Bordure </span>
            </div>
            <div class="d-flex justify-content-around mb-2">
                <div>
                    <color-block [colors]="colors" [(color)]="legend.backgroundColor"></color-block>
                </div>
                <div>
                    <color-block [colors]="colors" [(color)]="legend.borderColor"></color-block>
                </div>
            </div>
            <div class="d-flex justify-content-around align-items-center">
                <div>
                    {{ 'transparence' | titlecase }}
                    <mat-slider
                        [(value)]="legend.backgroundColor.a"
                        [min]="0"
                        [max]="1"
                        [step]="0.01"
                        [thumbLabel]="true"
                    >
                    </mat-slider>
                </div>
                <div>
                    <mat-checkbox color="primary" (change)="transparent($event)">Transparent</mat-checkbox>
                </div>
            </div>
        </div>
    </div>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-button color="primary" (click)="cancel()">
        <lib-icon>close</lib-icon>
        <span>{{ 'Annuler' | uppercase }}</span>
    </button>
    <button mat-raised-button color="accent" [disabled]="!form.valid" (click)="confirm()">
        <lib-icon>check</lib-icon>
        <span>{{ 'Confirmer' | uppercase }}</span>
    </button>
</div>
