import { Component, Input, OnInit } from '@angular/core';
import { Intervention } from 'src/app/model/intervention.model';
import { InterventionReportData, IReportComponent, ReportBlockData } from '../../../../../../model/rapport.model';
import { Polluant, PolluantReportData } from '../../../model/polluant.model';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { DiagnosticService } from 'src/app/services/diagnostic.service';
import { PolluantService } from '../../../services/polluant.service';
import { PolluantConfig } from '../../../model/polluant-config.model';
import { TypePrestationEnum } from 'src/app/model/type-prestation.model';

@Component({
    selector: 'app-poll-introduction-block',
    templateUrl: './poll-introduction-block.component.html',
    styleUrls: ['./poll-introduction-block.component.scss'],
})
export class PollIntroductionBlockComponent implements IReportComponent {
    @Input() data: ReportBlockData;
    @Input() intervention: Intervention;
    @Input() set interReportData(value: InterventionReportData) {
        this.reportData = value.diagnostics[0] as PolluantReportData;
    }
    reportData: PolluantReportData;

    constructor() {}
}
