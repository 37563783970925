<!-- On doit déclarer le ng-content car on ne peut pas le déclarer plusieurs fois ;-) -->
<ng-template #content>
    <ng-content></ng-content>
</ng-template>

<ng-container *ngIf="enabled && smallMode">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-sm-9 col-md-7 col-lg-5">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="enabled && !smallMode">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-sm-12 col-md-10 col-lg-8">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!enabled">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>
