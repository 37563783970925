import { cn_add, cn_box, cn_clone, cn_image_dir, cn_mul, cn_space_labelizer } from '@acenv/cnmap-editor';
import { Niveau } from '../../../../model/bien.model';
import { PARAM_VOLUME_VISITE, VOLUME_VISITE } from '../../../../shared/constants/cndiag.constants';

export var SPACE_EXTERIOR_FACING_LIST = ['asphalt', 'concrete', 'gravel', 'lawn', 'marble', 'pavement'];
export var SPACE_EXTERIOR_FACING_LABEL_LIST = ['Asphalte', 'Béton', 'Gravier', 'Pelouse', 'Dallage', 'Pavement'];

const CN_SPACE_LABEL_WIDTH = [150, 235];
class cn_equipment_label {
    get svg(): string {
        return this._svg;
    }

    set svg(value: string) {
        this._svg = value;
    }
    get box(): cn_box {
        return this._box;
    }

    set box(value: cn_box) {
        this._box = value;
    }
    get equipment(): any {
        return this._equipment;
    }

    set equipment(value: any) {
        this._equipment = value;
    }
    private _equipment: any;
    private _box: cn_box;
    private _svg: string;

    constructor(equipment) {
        this._equipment = equipment;
        this._box = new cn_box();
        this._svg = '';
    }
}

export const ICON_UNLOCKED_GREEN = 'icon_unlocked_green.png';
export const ICON_LOCKED_ORANGE = 'icon_locked_orange.png';
export const ICON_LOCKED_RED = 'icon_locked_red.png';

export class CndiagSpaceLabelizer extends cn_space_labelizer {
    currentNiveau: Niveau;

    public show_visit_lock: boolean[];

    mapImage: {
        key: string;
        value: string;
    }[];

    constructor(storey, currentNiveau, mapImage) {
        super(storey);

        this.mapImage = mapImage;

        this.currentNiveau = currentNiveau;
    }

    set_default_settings() {
        super.set_default_settings();

        this.show_visit_lock = [true, false];

        this.interactive = false;
        this.move_button = false;
        this.expanding_mode = 0;

        this.parameter_keys = {
            ...this.parameter_keys,
            show_visit_lock: 'Cadenas visite',
        } as any;
    }

    // "@acenv/cnmap-editor": "1.5.0",
    _update(space, space_label, add_classes = []) {
        space_label.box.posmin = [0, 0];
        space_label.box.size = [0, 0];

        const expanded =
            this.expanding_mode == 0 || (this.expanding_mode == 1 && space != this._expanded_space) ? 0 : 1;

        space_label.box.size[0] = CN_SPACE_LABEL_WIDTH[expanded];
        if (add_classes.indexOf('exp') > -1) {
            space_label.box.size[0] = CN_SPACE_LABEL_WIDTH[1];
        }

        space_label.svg = '';
        const extra = add_classes.join(' ');

        //*** Draw move button */
        var button_svg = '';

        if (this.interactive && this.move_button) {
            space_label.move_box.posmin = [3, 3];
            space_label.move_box.size = [20, 20];
            var bb = space_label.move_box;
            var p = cn_add(bb.posmin, cn_mul(bb.size, 0.5));
            var radius = 0.5 * bb.size[0];
            button_svg +=
                "<circle class='space_label_move_button' cx='" + p[0] + "' cy='" + p[1] + "' r='" + radius + "'/>";
            button_svg +=
                "<image xlink:href='" +
                cn_image_dir() +
                "white_double_arrow.png' x='" +
                bb.posmin[0] +
                "' y='" +
                bb.posmin[1] +
                "' width='" +
                bb.size[0] +
                "' height='" +
                bb.size[1] +
                "' preserveAspectRatio='xMidYMid meet'/>";
        }

        const volume = this.currentNiveau?.volumes.find((it) => it.spaceId === space.ID);
        if (this.show_visit_lock[expanded] && volume) {
            const visite =
                volume.valeursParametres[PARAM_VOLUME_VISITE] &&
                volume.valeursParametres[PARAM_VOLUME_VISITE] === VOLUME_VISITE.OK;
            const partiellement =
                volume.valeursParametres[PARAM_VOLUME_VISITE] &&
                volume.valeursParametres[PARAM_VOLUME_VISITE] === VOLUME_VISITE.WARNING;
            const notVisite =
                volume.valeursParametres[PARAM_VOLUME_VISITE] &&
                volume.valeursParametres[PARAM_VOLUME_VISITE] === VOLUME_VISITE.KO;

            const icon = visite ? ICON_UNLOCKED_GREEN : partiellement ? ICON_LOCKED_ORANGE : ICON_LOCKED_RED;

            space_label.move_box.posmin = [-15, 3];
            space_label.move_box.size = [20, 20];
            var bb = space_label.move_box;

            const image = this.mapImage?.find((it) => it.key === icon);

            button_svg +=
                "<image xlink:href='data:image/png;base64," +
                image?.value +
                "' x='" +
                bb.posmin[0] +
                "' y='" +
                bb.posmin[1] +
                "' width='" +
                bb.size[0] +
                "' height='" +
                bb.size[1] +
                "' preserveAspectRatio='xMidYMid meet'/>";
        }

        var x = 5;
        var x0 = x;
        var ww = space_label.box.size[0];
        if (this.move_button) {
            x0 += space_label.move_box.posmin[0] + space_label.move_box.size[0];
            ww -= space_label.move_box.posmin[0] + space_label.move_box.size[0];
        }

        var y = 18;
        var line_height = 20;

        //*** Write name */
        if (this.name_visible[expanded]) {
            space_label.svg += `<text x="${x0}" y="${y}" class="space_label_name ${extra}">${space.get_name(
                this._storey
            )}</text>`;
            y += line_height;
        }

        //*** Write buttons (after name to draw above) */
        space_label.svg += button_svg;

        //*** Write area */
        if (this.area_visible[expanded]) {
            space_label.svg += `<text x="${
                this.name_visible[expanded] ? x : x0
            }" y="${y}" class="space_label_text ${extra}">S: ${space.get_area().toFixed(2)} m3²</text>`;
            y += line_height;

            if (space.plain_area < space.get_area() - 0.01) {
                space_label.svg += `<text x="${x}" y="${y}" class="space_label_text ${extra}">S nette: ${space.plain_area.toFixed(
                    2
                )} m²</text>`;
                y += line_height;
            }
        }

        //*** Write perimeter */
        if (this.perimeter_visible[expanded]) {
            space_label.svg += `<text x="${x}" y="${y}" class="space_label_text ${extra}">Périmètre: ${space
                .get_perimeter()
                .toFixed(2)} m</text>`;
            y += line_height;
        }

        //*** Write floor offset */
        if (this.facing_visible[expanded]) {
            var index = SPACE_EXTERIOR_FACING_LIST.indexOf(space.facing);
            var facing = index >= 0 ? SPACE_EXTERIOR_FACING_LABEL_LIST[index] : 'Néant';
            space_label.svg += `<text x="${x}" y="${y}" class="space_label_text ${extra}">Revêtement: ${facing}</text>`;
            y += line_height;
        }

        //*** Write floor offset */
        if (this.floor_offset_visible[expanded]) {
            space_label.svg += `<text x="${x}" y="${y}" class="space_label_text ${extra}">Décallage de dalle: ${space.slab_offset.toFixed(
                2
            )} m</text>`;
            y += line_height;
        }

        if ((space.ceiling_height > 0 && this.area_visible[expanded]) || this.ceiling_visible[expanded]) {
            space_label.svg += `<text x="${x}" y="${y}" class="space_label_text ${extra}">${
                this.ceiling_visible[expanded] ? 'Faux-plafond' : 'H'
            }: ${space.ceiling_height > 0 ? space.ceiling_height.toFixed(2) + ' m' : 'Auto'}</text>`;
            y += line_height;
        }

        //*** write equipments */
        y -= 10;
        x = space_label.box.size[0];
        const eq_size = [30, 30];
        y -= eq_size[1] + 3;

        space_label.equipment_labels = [];
        if (this.equipments_visible[expanded]) {
            let i = 1;
            const start_pos_y = y + 0;
            let width = 0;
            this._scene.object_instances.forEach((eq) => {
                if (eq.virtual && eq.space === space && eq.object) {
                    if (x > space_label.box.size[0] - eq_size[0] - i * 3) {
                        i = 1;
                        x = 3;
                        y += eq_size[1] + 3;
                    } else {
                        i++;
                        x += eq_size[0] + 3;
                    }
                    width = Math.max(width, x);
                    const eq_label = new cn_equipment_label(eq);
                    space_label.equipment_labels.push(eq_label);
                    eq_label.box.posmin = [x, y];
                    eq_label.box.size = cn_clone(eq_size);
                    space_label.svg +=
                        `<g opacity="0.8" transform="translate(${x},${y})">` +
                        eq.object.draw_svg_icon(eq_size[0], eq_size[1]) +
                        `</g>`;
                }
            });
            if (this._scene.object_instances.find((eq) => eq.virtual && eq.space === space)) {
                space_label.svg =
                    `<rect class="space_label_equipments_list ${extra}" x="0" y="${start_pos_y + eq_size[1]}" width="${
                        width + eq_size[0]
                    }" height="${y - start_pos_y + 3}"/>` + space_label.svg;
            }
        }

        space_label.box.size[1] = y + eq_size[1] + 3;
    }
}
