import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from './authentication.service';
import { AuthGuard } from './auth.guard';
import { UserService } from './user.service';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LibFormContainerModule } from '../form-container/form-container.module';
import { RouterModule } from '@angular/router';
import { LogoutComponent } from './logout/logout.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { AuthenticationStore } from './authentication.store';

@NgModule({
    imports: [
        CommonModule,
        LibFormContainerModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        RouterModule,
    ],
    exports: [LoginComponent, LogoutComponent, AccessDeniedComponent],
    declarations: [LoginComponent, LogoutComponent, AccessDeniedComponent],
})
export class LibAuthenticationModule {
    static forRoot(): ModuleWithProviders<LibAuthenticationModule> {
        return {
            ngModule: LibAuthenticationModule,
            providers: [AuthenticationService, AuthenticationStore, AuthGuard, UserService],
        };
    }
}
