'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2022 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** Pipe type
//***********************************************************************************
//***********************************************************************************

import { fh_add, fh_mul, fh_polygon, fh_solid } from '@acenv/fh-3d-viewer';
import { cn_element_type } from './cn_element_type';
import { cn_configuration, cn_configuration_choice, cn_configuration_line, cn_configuration_param, cn_configuration_param_group, cn_configuration_tab } from './cn_configuration';
import { code_to_label } from './cn_opening_type';
import { cn_element_type_visitor } from '..';

export const PIPE_FLUID_LIST = [
    { label: 'Eau', code: 'water' },
    { label: 'Eaux usagées', code: 'waste' },
    { label: 'Air', code: 'air' },
    { label: 'Gaz', code: 'gaz' },
    { label: 'Autre', code: 'other' }
];

export const PIPE_MATERIAL_LIST = [
    { label: 'PVC', code: 'pvc' },
    { label: 'Cuivre', code: 'copper' },
    { label: 'PER', code: 'per' },
    { label: 'Autre', code: 'other' }
];

/**
 * @class cn_pipe_type
 * Common class type for pipes
 */
export class cn_pipe_type extends cn_element_type {
    //***********************************************************************************
    /**
     * Constructor
     */
    constructor() {
        super();
        this.name = '';

        this.diameter = 0.2;
        this.fluid = 'water'
        this.material = 'pvc';
    }

    //***********************************************************************************
    /**
     * Creates a default pipe type
     * @returns {cn_pipe_type[]}
     */
    static default_types() {
        const def = [];

        const copper_pipe_10 = new cn_pipe_type();
        copper_pipe_10.diameter = 0.01;
        copper_pipe_10.fluid = 'water';
        copper_pipe_10.material = 'copper';
        def.push(copper_pipe_10);

        const pvc_pipe_50 = new cn_pipe_type();
        pvc_pipe_50.diameter = 0.05;
        pvc_pipe_50.fluid = 'water';
        pvc_pipe_50.material = 'pvc';
        def.push(pvc_pipe_50);

        const pvc_unknown = new cn_pipe_type();
        pvc_unknown.diameter = 0.1;
        pvc_unknown.fluid = 'waste';
        pvc_unknown.material = 'pvc';
        def.push(pvc_unknown);

        return def;
    }

    //***********************************************************************************
    /**
     * Returns a clone of this
     * @returns {cn_pipe_type}
     */
    clone() {
        const c = new cn_pipe_type();
        c.name = this.name;
        c.diameter = this.diameter;
        c.fluid = this.fluid;
        c.material = this.material;

        return c;
    }

    //***********************************************************************************
    get_generic_label() {
        return 'Type de conduit';
    }

    //***********************************************************************************
    /**
     * Returns the list of keys of the element
     * @returns {string[]}
     */
    model_keys() {
        return ['name', 'diameter', 'fluid', 'material'];
    }

    //***********************************************************************************
    /**
     * Serialize
     * @returns {object}
     */
    serialize() {
        const json = {};
        json.class_name = 'cn_pipe_type';
        json.ID = this.ID;
        json.name = this.name;
        json.diameter = this.diameter;
        json.fluid = this.fluid;
        json.material = this.material;

        return json;
    }

    //***********************************************************************************
    /**
     * Unserialize
     * @param {object} json
     * @returns {cn_pipe_type | false}
     */
    static unserialize(json) {
        if (typeof (json) != 'object') return false;
        if (typeof (json.ID) != 'string') return false;
        if (json.class_name !== 'cn_pipe_type') return false;
        if (typeof (json.diameter) != 'number') return false;

        const ot = new cn_pipe_type();

        ot.ID = json.ID;
        if (typeof (json.name) == 'string') ot.name = json.name;
        ot.diameter = json.diameter;

        if (typeof (json.fluid) == 'string' && code_to_label(json.fluid, PIPE_FLUID_LIST))
            ot.fluid = json.fluid;

        if (typeof (json.material) == 'string' && code_to_label(json.material, PIPE_MATERIAL_LIST))
            ot.material = json.material;
        return ot;
    }

    //***********************************************************************************
    /**
     * Returns a displayble label
     * @returns {string}
     */
    get_label() {
        if (this.name !== '') return this.name;
        let html = code_to_label(this.fluid, PIPE_FLUID_LIST) + ' - ' + code_to_label(this.material, PIPE_MATERIAL_LIST);
        return `${html} ⌀ ${(this.diameter * 1000).toFixed(0)} mm`;
    }

    //***********************************************************************************
    /**
     * Returns an array of objects describing the type.
     * @returns {{label: string, value?: any, decimals?: number, unit?: string}[]}
     */
    get_description() {
        const description = [];

        const fluid_label = code_to_label(this.fluid, PIPE_FLUID_LIST);
        if (fluid_label)
            description.push({ label: 'Fluide', value: fluid_label });

        const material_label = code_to_label(this.material, PIPE_MATERIAL_LIST);
        if (material_label)
            description.push({ label: 'Matériau', value: material_label });

        description.push({ label: 'Diamètre', value: this.diameter * 1000, decimals: 0, unit: 'mm' });

        return description;
    }

    //***********************************************************************************
    /**
     * Returns a html string to be put in a <svg>
     * @param {number} w : width of svg element
     * @param {number} h : height of svg element
     * @returns {string}
     */
    draw_svg_icon(w, h) {
        let html = ``;

        const od = this.diameter;
        const scale = (1 > w / h) ? w / (1.05 * od) : h / (1.05 * od);

        const x0 = w / 2 - od * scale / 2;
        const x1 = w / 2 + od * scale / 2;
        const y0 = h / 2 - od * scale / 2;
        const y1 = h / 2 + od * scale / 2;

        //html += `<ellipse cx="${(x0 + x1) * 0.5}" cy="${(y0 + y1) * 0.5}" rx="${0.5 * (x1 - x0)}" ry="${0.5 * (y1 - y0)}" `;

        var ar = 0.25 * (x1 - x0);
        var alpha = ar * Math.cos(Math.PI * 0.25);

        html += `<path d="M ${x0 + ar - alpha} ${y0 + ar + alpha} `;
        html += `A  ${ar} ${ar} 0 1 1 ${x0 + ar + alpha} ${y0 + ar - alpha} `;
        html += `L ${x1 - ar + alpha} ${y1 - ar - alpha}`;
        html += `A  ${ar} ${ar} 0 1 1 ${x1 - ar - alpha} ${y1 - ar + alpha} `;
        html += `Z"`;
        html += ` class="pipe_type_icon pipe_${this.material}" />`;

        html += `<circle cx="${x1 - ar}" cy="${x1 - ar}" r="${ar}" class="pipe_type_icon pipe_face" />`;
        html += `<circle cx="${x1 - ar}" cy="${x1 - ar}" r="${ar * 0.8}" class="pipe_type_icon pipe_fluid_${this.fluid}" />`;
        return html;
    }

    //***********************************************************************************
    //**** Build 3D solid
    //***********************************************************************************
    /**
     * Builds a 3D solid with origin (0,0,0)
     * @param {number} height
     */
    build_solid(height) {
        const dz = [0, 0, height];
        const dx = [this.diameter, 0, 0];
        const dy = [0, this.diameter, 0];
        const ori = [-0.5 * this.diameter, -0.5 * this.diameter, 0];

        const solid = new fh_solid();
        solid.cylinder(ori, dx, dy, dz);

        solid['color'] = this.get_color();

        return solid;
    }

    //***********************************************************************************
    /**
     * Build footprint, with left corner at 0,0, as horizontal polygon
     * @returns {fh_polygon}
     */
    build_footprint() {
        const dz = [0, 0, 1];
        const dx = [this.diameter, 0, 0];
        const dy = [0, this.diameter, 0];
        const ori = [-0.5 * this.diameter, -0.5 * this.diameter, 0];
        const polygon = new fh_polygon(ori, dz);
        const contour = [];
        const center = [this.diameter / 2, this.diameter / 2, 0];
        for (let theta = 0; theta < 360; theta += 10) {
            const x = 0.5 * Math.cos(theta * Math.PI / 180);
            const y = 0.5 * Math.sin(theta * Math.PI / 180);
            contour.push(fh_add(center, fh_add(fh_mul(dx, x), fh_mul(dy, y))));
        }
        polygon.add_contour(contour);
        return polygon;
    }

    //***********************************************************************************
    /*** Returns color */
    get_color() {
        if (this.material === 'pvc')
            return [100 / 255, 100 / 255, 100 / 255];
        if (this.material === 'coper')
            return [150 / 255, 100 / 255, 100 / 255];
        if (this.material === 'per')
            return [150 / 255, 100 / 255, 150 / 255];
        return [100 / 255, 150 / 255, 100 / 255];
    }

    //***********************************************************************************
    //**** Get configuration
    //***********************************************************************************
    static configuration() {
        const configuration = new cn_configuration();

        //*** Global params
        const param_group = new cn_configuration_param_group('Dimensions', 'dimensions');
        configuration.add_param_group(param_group);
        param_group.add_param(new cn_configuration_param('Diamètre', 'diameter', 5, 500, 'mm'));

        const tab = new cn_configuration_tab('Typologie', 'standard');
        configuration.add_tab(tab);
        this.prepare_configuration_tab(tab);

        return configuration;
    }

    //***********************************************************************************
    //**** Prepare material and shape configuration for tabs
    //***********************************************************************************
    static prepare_configuration_tab(tab) {
        //***********************************************

        //*** Fluid
        const fluid_list = new cn_configuration_line('Fluide', 'fluid');
        tab.add_line(fluid_list);
        PIPE_FLUID_LIST.forEach((fluid) => {
            fluid_list.add_choice(new cn_configuration_choice(fluid.label, fluid.code));
        });

        //*** Material
        const material_list = new cn_configuration_line('Matériau', 'material');
        tab.add_line(material_list);
        PIPE_MATERIAL_LIST.forEach((material) => {
            material_list.add_choice(new cn_configuration_choice(material.label, material.code));
        });
    }

    //***********************************************************************************
    //**** Fill configuration
    //***********************************************************************************
    /**
     * Returns a new configuration built from the element type, or fills an existing configuration.
     * @param {cn_configuration} config
     * @returns {cn_configuration}
     */
    fill_configuration(config = null) {
        const configuration = (config) ? config : cn_pipe_type.configuration();
        // tab selected
        const selection = configuration.selection;
        const param_group = configuration.get_param_group('dimensions');
        param_group.set_param('diameter', this.diameter * 1000);

        const fluid_line = configuration.tabs[selection].get_line('fluid');
        fluid_line.set_selection(this.fluid);

        const material_line = configuration.tabs[selection].get_line('material');
        material_line.set_selection(this.material);

        return configuration;
    }

    //***********************************************************************************
    //**** Load configuration
    //***********************************************************************************
    load_configuration(configuration, actual_change = false) {
        const param_group = configuration.get_param_group('dimensions');
        this.diameter = param_group.get_param('diameter').value / 1000;

        // tab selected
        const selection = configuration.selection;
        const configuration_tab = configuration.tabs[selection];
        this.fluid = configuration_tab.get_line('fluid').get_selection();
        this.material = configuration_tab.get_line('material').get_selection();
        return true;
    }

    /**
     * Accept element type visitor
     *
     * @param {cn_element_type_visitor} element_type_visitor
     */
    accept_visitor(element_type_visitor) {
        element_type_visitor.visit_pipe_type(this);
    }
}

