export class CustomSelectGroup {
    id?: string = null;
    label: string;
    items: ItemCustomSelect[] = [];
}

export class ItemCustomSelect {
    id: string;
    label: string;
}
