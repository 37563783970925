import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { catchError, delay, map, switchMap, take, tap } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { InterventionService } from '../services/intervention.service';
import { BaseComponent } from 'src/app/commons-lib';
import { CnSpinnerService } from '../modules/shared/cn-spinner/service/cn-spinner.service';

@Injectable({
    providedIn: 'root',
})
export class QuitDocumentGuard implements CanDeactivate<BaseComponent> {
    constructor(private interventionService: InterventionService, private spinner: CnSpinnerService) {}

    canDeactivate() {
        return combineLatest([this.interventionService.getCurrentIntervention()]).pipe(
            take(1),
            switchMap(([intervention]) =>
                this.spinner.withSpinner(this.interventionService.updateIntervention(intervention), "Enregistrement de l'intervention...")
            ),
            map(() => true),
            catchError(() => of(false))
        );
    }
}
