<!--Déclaration du filter svg, permet l'affichage des objets dans le svg-->
<span style="display: none" [innerHTML]="this.svgFilterHtml | safeHtml"></span>
<div class="fullscreen-content">
    <div class="header">
        <div class="title">Création d'un plan de repérage</div>
        <button mat-button class="close-button" type="button" (click)="onClickBtnCancel()">
            <lib-icon>close</lib-icon>
        </button>
    </div>
    <div class="dialog-content scrollable-content h-100 w-100">
        <div class="d-flex h-100">
            <!-- CENTER : DISPLAY MAP-->
            <div class="flex-column w-100 block-svg">
                <div class="w-100">
                    <form class="px-2 d-flex align-items-center justify-content-center" [formGroup]="topForm">
                        <mat-form-field class="w-25 pr-2">
                            <mat-select formControlName="detail" (valueChange)="onPresetExportSvgTypeChanged($event)">
                                <mat-option *ngFor="let detail of details" [value]="detail.value">
                                    {{ detail.label }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="w-50 pl-2">
                            <input matInput type="text" formControlName="nom" placeholder="Nom" />
                            <mat-error *ngIf="topForm?.get('nom')?.hasError('required')"> Nom requis </mat-error>
                        </mat-form-field>
                    </form>
                </div>
                <div
                    #viewerElement
                    class="svg-container d-flex h-100 flex-grow-1 mx-2"
                    (onResize)="handleContainerResize($event)"
                >
                    <div class="d-flex flex-column control-group control-zoom mat-elevation-z3">
                        <button
                            mat-button
                            libLongPress
                            (click)="onClickBtnZoom(true)"
                            (longPressing)="onClickBtnZoom(true)"
                            color="primary"
                            class="control-button"
                            matTooltip="Zoom avant"
                        >
                            <mat-icon>zoom_in</mat-icon>
                        </button>
                        <button
                            mat-button
                            libLongPress
                            (click)="onClickBtnZoom(false)"
                            (longPressing)="onClickBtnZoom(false)"
                            color="primary"
                            class="control-button"
                            matTooltip="Zoom arrière"
                        >
                            <mat-icon>zoom_out</mat-icon>
                        </button>
                        <button
                            mat-button
                            (click)="onCenterViewChange()"
                            color="primary"
                            class="control-button"
                            matTooltip="Recentrer la vue"
                        >
                            <mat-icon>center_focus_strong</mat-icon>
                        </button>
                    </div>
                    <div
                        class="m-auto"
                        [ngStyle]="{ width: divWidth, height: divHeight }"
                        (onResize)="handleDivResize($event)"
                    >
                        <svg class="svg-view h-100 w-100" id="svg_view" tabindex="0"></svg>
                    </div>
                </div>
            </div>
            <!-- RIGHT : SIDENAV FORM-->
            <div class="svg-params-container h-100 overflow-auto">
                <app-export-svg-side-nav-form
                    [formConf]="formConf"
                    [currentDiagnostic]="currentDiagnostic"
                    [biens]="biens"
                    [espaces]="espaces"
                    [dynamicScale]="dynamicScale"
                    (clickCenterView)="onCenterViewChange()"
                    (pagesGroupChanged)="onChangePagesGroup($event)"
                    (bienGroupChanged)="onChangeBienGroup($event)"
                    (espaceGroupChanged)="onChangeEspaceGroup($event)"
                    (planGroupChanged)="onChangePlanGroup($event)"
                    (equipmentGroupChanged)="onChangeEquipmentGroup($event)"
                    (zoneGroupChanged)="onChangeZoneGroup($event)"
                    (hapGroupChanged)="onChangeHapGroup($event)"
                    (polluantFormChanged)="onChangePolluantForm($event)"
                ></app-export-svg-side-nav-form>
            </div>
        </div>
    </div>
</div>

<!--MODAL FOOTER ACTIONS-->
<div mat-dialog-actions class="justify-content-end">
    <button type="button" mat-button color="primary" (click)="onClickBtnCancel()">
        <mat-icon>close</mat-icon>
        <span>Annuler</span>
    </button>
    <button type="button" mat-raised-button color="primary" (click)="onClickBtnExport()" [disabled]="!isValidTopForm">
        <mat-icon>save_alt</mat-icon>
        <span>Enregistrer</span>
    </button>
</div>
