import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorUtils } from 'src/app/utils/validator.utils';
import { MpcaProcessusTaskOld } from '../../diagnostics/polluant/model/mpca.model.old';
import { MpcaService } from '../../diagnostics/polluant/services/mpca.service';

@Component({
    selector: 'app-detail-processus-card',
    templateUrl: './detail-processus-card.component.html',
    styleUrls: ['./detail-processus-card.component.scss'],
})
export class DetailProcessusCardComponent implements OnInit {
    @Input()
    formTaches: FormGroup;

    //Input utilisée pour afficher ou non les noms des opérateurs ainsi que les heures
    @Input()
    modePrelevement: boolean = false;

    indexSelectedTache = undefined;

    formTache: FormGroup;

    private clickInsideDetected: boolean = false;

    constructor(private formBuilder: FormBuilder, private mpcaFormService: MpcaService, private eRef: ElementRef) {}

    ngOnInit(): void {
        this.formTache = this.createTacheForm();
    }

    createTacheForm(): FormGroup {
        const form = this.formBuilder.group({
            operateur1: new FormControl(false),
            nom1: [
                '',
                {
                    updateOn: 'blur',
                },
            ],
            operateur2: new FormControl(false),
            nom2: [
                '',
                {
                    updateOn: 'blur',
                },
            ],
            operateur3: new FormControl(false),
            nom3: [
                '',
                {
                    updateOn: 'blur',
                },
            ],
            operateur4: new FormControl(false),
            nom4: [
                '',
                {
                    updateOn: 'blur',
                },
            ],
            tache: new FormControl('', [Validators.required]),
        });
        form.setValidators(ValidatorUtils.checkedOneOperatorChecked());
        return form;
    }

    /**
     * @description Ajouter un nouveau processus
     * @param index
     */
    add() {
        //Ajout de la nouvelle tache
        this.taches.push(this.mpcaFormService.getTacheGroup(this.formTache.getRawValue()));

        //Reset form
        this.formTache = this.createTacheForm();
    }

    /**
     * @description modifie le procesus sélectionné
     * @param index
     */
    modifier() {
        //Patch des valeurs de la tache dans la liste avec les données du formulaire
        this.taches.at(this.indexSelectedTache).patchValue(this.formTache.getRawValue());

        //Reset form
        this.formTache = this.createTacheForm();

        //Déselectionne la tache
        this.indexSelectedTache = undefined;
    }

    /**
     * @description retourner un tache group
     * @param item
     */
    getTacheGroup(item: MpcaProcessusTaskOld) {
        return this.mpcaFormService.getTacheGroup(item);
    }

    /**
     * @description Suprimmer une tache
     * En fonction de la position actuelle de l'item sélectionné,
     * On ira sélectionné le bon element
     * De plus, il n'est pas possible de supprimer tous les elements
     * Il doit toujours avoir au minimum 1 item.
     * @param index
     */
    removeTache(index: number) {
        this.taches.removeAt(index);
        this.formTache = this.createTacheForm();
        this.indexSelectedTache = undefined;
    }

    /**
     * Selection d'une tache
     * @param index
     */
    selectTache(index: number) {
        this.indexSelectedTache = index;

        this.formTache.patchValue(this.taches.value[index]);
    }

    /**
     * Get le formulaire tableau root taches
     */
    get taches(): FormArray {
        return this.formTaches.get('taches') as FormArray;
    }

    /**
     * @description en fonction des opérateurs sélectionnés,
     * On va saisir le label
     * @param item
     */
    getTitle(item) {
        const list = [];
        if (item.operateur1) {
            list.push(1);
        }
        if (item.operateur2) {
            list.push(2);
        }
        if (item.operateur3) {
            list.push(3);
        }
        if (item.operateur4) {
            list.push(4);
        }
        return list.join(' et ');
    }

    @HostListener('document:click', ['$event'])
    onClickOutside(event) {
        if (this.eRef.nativeElement.contains(event.target)) {
            this.clickInsideDetected = true;
        } else {
            // clicked outside
            if (this.clickInsideDetected && this.indexSelectedTache >= 0) {
                this.modifier();
                this.clickInsideDetected = false;
            }
        }
    }
}
