/**
 * Bon de commande Analyse
 */
import { LaboratoireAdmin } from './laboratoire.model';
import { TypeReport } from './reference-prestation.model';
import { MongoUtils } from 'src/app/commons-lib';
import { PrelevementEchantillonageAdmin } from './prelevement-generique.model';

export class BonCommandeAnalyseAdmin {
    id: string = MongoUtils.generateObjectId();
    numeroCommande: string;
    sendingDate?: string = null;
    creationDate: string;
    laboratoire?: LaboratoireAdmin = null;
    state: BonCommandeAnalyseState;
    interventionId: string;
    interventionName: string;
    diagnosticId: string;
    typeReport: TypeReport;
    refReport: string;
    dataReport: string;
    listeEchantillons: PrelevementEchantillonageAdmin[] = [];
    emailResults: string;
    idOperators: string[];
    idAgence: string;
    signed: boolean = false;
    idsBien: string[];
}

export enum BonCommandeAnalyseState {
    ENVOYE = 'ENVOYE',
    NON_ENVOYE = 'NON_ENVOYE',
    RETOUR_PARTIEL = 'RETOUR_PARTIEL',
    RETOUR_TOTAL = 'RETOUR_TOTAL',
    VALIDE = 'VALIDE',
}

/**
 * Renvoie le label d'un état de bon de commande d'analyse
 * @param bonCommandeAnalyseState
 */
export function bonCommandeAnalyseStateToLabel(bonCommandeAnalyseState: string | BonCommandeAnalyseState): string {
    switch (bonCommandeAnalyseState) {
        case 'ENVOYE':
            return 'Envoyé';
        case 'NON_ENVOYE':
            return 'Non envoyé';
        case 'RETOUR_PARTIEL':
            return 'Retour partiel';
        case 'RETOUR_TOTAL':
            return 'Retour total';
        case 'VALIDE':
            return 'Valide';
        default:
            return '';
    }
}

export class BatchBonCommandeLaboratoire {
    idBonCommandeLaboratoire: string;
    validated: boolean;
    listeEchantillons: string[];
}
