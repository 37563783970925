import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { CnDiagSharedModule } from '../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { ValidationDocsComponent } from './modules/validation-docs/validation-docs.component';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PipesModule } from '../../../pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DirectiveModule } from '../../../directives/directive.module';
import { LibFileUploaderModule, LibIconModule, LibIncrementInputModule } from 'src/app/commons-lib';
import { NewDocumentComponent } from './modules/modales/document/new-document.component';
import { GeneralComponent } from './modules/initialisation/general/general.component';
import { PointsDeControlesComponent } from './modules/points-de-controles/points-de-controles.component';
import { DiagnosticsSharedModule } from '../../diagnostics-shared/diagnostics-shared.module';
import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { PtcContentComponent } from './modules/points-de-controles/ptc-content/ptc-content.component';
import { InitConfigResolver } from './services/initConfig.resolver';
import { DialogSolutionIsolationComponent } from './modules/points-de-controles/dialog-solution-isolation/dialog-solution-isolation.component';
import { DialogDuplicationComponent } from './modules/points-de-controles/dialog-duplication/dialog-duplication.component';
import { DialogCalculPartieComponent } from './modules/points-de-controles/dialog-calcul-partie/dialog-calcul-partie.component';
import { ControleAdministratifComponent } from './modules/conclusion/controle-administratif/controle-administratif.component';
import { ConcPointsDeControleComponent } from './modules/conclusion/conc-points-de-controle/conc-points-de-controle.component';
import { AnoIsolantComponent } from './modules/conclusion/isolants/ano-isolant.component';
import { ResteAFaireComponent } from './modules/recapitulatif/reste-a-faire/reste-a-faire.component';
import { ReportCeeBlockComponent } from './modules/report/blocks/report-cee-block/report-cee-block.component';
import { ProgressEtatGuard } from './guards/progressEtat.guard';

@NgModule({
    declarations: [
        ValidationDocsComponent,
        NewDocumentComponent,
        GeneralComponent,
        PointsDeControlesComponent,
        PtcContentComponent,
        DialogSolutionIsolationComponent,
        DialogDuplicationComponent,
        DialogCalculPartieComponent,
        ControleAdministratifComponent,
        ConcPointsDeControleComponent,
        AnoIsolantComponent,
        ResteAFaireComponent,
        ReportCeeBlockComponent,
        GeneralComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        CnDiagSharedModule,
        LibIncrementInputModule,
        LibIconModule,
        CnDiagSharedModule,
        LibFileUploaderModule,
        DiagnosticsSharedModule,
        // FORMS
        FormsModule,
        ReactiveFormsModule,
        DirectiveModule,
        PipesModule,

        // MATERIAL
        MatButtonModule,
        MatDialogModule,
        MatDividerModule,
        MatCheckboxModule,
        MatInputModule,
        MatIconModule,
        MatDatepickerModule,
        MatCardModule,
        MatProgressBarModule,
        MatRadioModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatButtonToggleModule,
        MatTableModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatListModule,
        MatTabsModule,
        MatRippleModule,
        MatExpansionModule,
        MatSnackBarModule,
        MatChipsModule,
    ],
    exports: [
        CommonModule,
        CnDiagSharedModule,
        LibIncrementInputModule,
        LibIconModule,
        CnDiagSharedModule,
        LibFileUploaderModule,
        DiagnosticsSharedModule,
        // FORMS
        FormsModule,
        ReactiveFormsModule,
        DirectiveModule,
        PipesModule,

        // MATERIAL
        MatButtonModule,
        MatDialogModule,
        MatDividerModule,
        MatCheckboxModule,
        MatInputModule,
        MatIconModule,
        MatDatepickerModule,
        MatCardModule,
        MatProgressBarModule,
        MatRadioModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatButtonToggleModule,
        MatTableModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatListModule,
        MatTabsModule,
        MatRippleModule,
        MatExpansionModule,
        MatSnackBarModule,
        MatChipsModule,
        ValidationDocsComponent,
        NewDocumentComponent,
        GeneralComponent,
        PointsDeControlesComponent,
        PtcContentComponent,
        DialogSolutionIsolationComponent,
        DialogDuplicationComponent,
        DialogCalculPartieComponent,
        ControleAdministratifComponent,
        ConcPointsDeControleComponent,
        AnoIsolantComponent,
        ResteAFaireComponent,
        ReportCeeBlockComponent,
        GeneralComponent,
    ],
    entryComponents: [
        NewDocumentComponent,
        DialogDuplicationComponent,
        DialogSolutionIsolationComponent,
        DialogCalculPartieComponent,
        ReportCeeBlockComponent,
    ],
    providers: [InitConfigResolver, ProgressEtatGuard],
})
export class CeeModule {}
