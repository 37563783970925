import { Component, Input, OnInit } from '@angular/core';
import { Intervention } from 'src/app/model/intervention.model';
import { InterventionReportData, IReportComponent, ReportBlockData } from '../../../../../model/rapport.model';

@Component({
    selector: 'app-markdown-block',
    templateUrl: './markdown-block.component.html',
    styleUrls: ['./markdown-block.component.scss'],
})
export class MarkdownBlockComponent implements OnInit, IReportComponent {
    @Input() data: ReportBlockData;
    @Input() intervention: Intervention;
    @Input() interReportData: InterventionReportData;

    markdown = '';

    constructor() {}

    ngOnInit(): void {
        if (this.data && this.data.values['markdown']) {
            this.markdown = this.data.values['markdown'];
        }
    }
}
