<h1 mat-dialog-title class="primary">
    <span *ngIf="mode === 'CREATE'">Création d'un paramètre</span>
    <span *ngIf="mode === 'EDIT'">Edition d'un paramètre</span>
</h1>

<div mat-dialog-content>
    <div class="w-100 pr-2 pt-2">
        <!--<h2>Paramètre</h2>-->
        <!-- codeBim du paramètre -->
        <div class="d-flex p-2 align-items-center">
            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Code bim du paramètre</div>
            <div class="col-xl-8 pr-2">{{ parameterDefinition.codeBim }}</div>
        </div>

        <!-- Type du paramètre -->
        <div class="d-flex p-2 align-items-center">
            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Nom du paramètre</div>
            <div class="col-xl-8 p-2">{{ parameterDefinition.name }}</div>
        </div>

        <!-- valeurs possible du paramètre -->
        <div class="d-flex p-2 align-items-center">
            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Valeur du paramètre</div>
            <div class="col-xl-8 p-2">
                <ng-container [ngSwitch]="parameterDefinition.type">
                    <ng-container *ngSwitchCase="'NOMBRE'">
                        <mat-form-field class="w-100">
                            <mat-label>{{
                                parameterDefinition.name +
                                    (parameterDefinition.numberDefinitions?.unit ? ' (' + parameterDefinition.numberDefinitions.unit + ')' : '')
                            }}</mat-label>
                            <input
                                matInput
                                type="number"
                                [(ngModel)]="parameterDefinition"
                                [step]="parameterDefinition.numberDefinitions?.nbDecimals | step"
                                [min]="parameterDefinition.numberDefinitions?.min"
                                [max]="parameterDefinition.numberDefinitions?.max"
                            />
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngSwitchCase="'BOOLEEN'">
                        <mat-form-field class="w-100">
                            <mat-label>{{ parameterDefinition.name }}</mat-label>
                            <mat-select [(ngModel)]="parameterValue">
                                <mat-option [value]=""><i>indéfini</i></mat-option>
                                <mat-option [value]="true">Oui</mat-option>
                                <mat-option [value]="false">Non</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngSwitchCase="'LISTE_CHOIX'">
                        <app-custom-select
                            class="w-100"
                            [matFormFieldClass]="'w-100'"
                            [choices]="parameterDefinition.choices"
                            [selectedValue]="parameterValue"
                            [multiple]="false"
                        >
                        </app-custom-select>
                    </ng-container>
                    <ng-container *ngSwitchCase="'DATE'">
                        <mat-form-field class="w-100">
                            <mat-label>{{ parameterDefinition.name }}</mat-label>
                            <input matInput [matDatepicker]="datepicker" [(ngModel)]="parameterValue" />
                            <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                            <mat-datepicker #datepicker></mat-datepicker>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <mat-form-field class="w-100">
                            <mat-label>{{ parameterDefinition.name }}</mat-label>
                            <input matInput type="text" [(ngModel)]="parameterValue" />
                        </mat-form-field>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="justify-content-end">
        <button mat-button color="primary" (click)="onClickCancel()">
            <lib-icon>close</lib-icon>
            <span>Annuler</span>
        </button>

        <button mat-raised-button color="accent" (click)="onClickSaveModification()" data-cy="submit-prestation-button">
            <lib-icon>check</lib-icon>
            <span>Confirmer</span>
        </button>
    </div>
</div>
