//***********************************************************************************
//***********************************************************************************
//**** cn_view_roof  : A tool to manage interaction with a 3D roof
//***********************************************************************************
//***********************************************************************************
'use strict';
import { fh_view_perspective } from '@acenv/fh-3d-viewer';
import { cn_roof } from '../model/cn_roof';
import { cn_svg_map } from './cn_svg_map';
import { cn_svg_tool_roof_slopes } from './cn_svg_tool_roof_slopes';
import { cn_view_base } from './cn_view_base';
import { cn_view_overlay } from './cn_view_overlay';
import { CN_OUTER } from '../model/cn_wall';
import { logger } from '../utils/cn_logger';

const WALL_COLOR = [0.8, 0.75, 0.7, 1];

export class cn_view_roof extends cn_view_base {
    /**
     *
     * @param {string} div_id
     * @param {cn_roof} roof
     * @param {cn_svg_map} parent
     */
    constructor(div_id, roof, parent = null) {
        super(div_id, parent);
        this._roof = roof;

        //*** clear container */
        const container_div = (typeof (div_id) == 'string') ? document.getElementById(div_id) : div_id;
        container_div.innerHTML = '';

        //*** build 3D renderer */
        const renderer_div = document.createElement('div');
        renderer_div.id = container_div.id + '_3d';
        container_div.append(renderer_div);
        this._renderer = new fh_view_perspective(renderer_div.id, this._scene_3d);
        this._overlay = null;
        this.rebuild_geometry();

        //*** svg renderer */
        const overlay_div = document.createElement('div');
        overlay_div.id = container_div.id + '_2d';
        renderer_div.appendChild(overlay_div);
        this._overlay = new cn_view_overlay(overlay_div, this);
        this._overlay._show_markers = false;
        this._overlay.visible = false;
        this._overlay.set_renderer(this._renderer);
        this._overlay.set_building(this._roof.building);

        const tool = new cn_svg_tool_roof_slopes(parent);
        tool._scene = roof;
        this._overlay.add_tool(tool);
        this._overlay.set_current_tool(tool);

        this._renderer.on('render', () => {
            this._overlay.refresh_rendering()
        });
        this._renderer.reset_camera();

        this._enlarged = false;
        this._container_div.classList.remove('large');
        tool.on('roof_update', () => {
            this.update_geometry();
            return true;
        });
        this.on('get_focus', () => {
            this.change_view_size(true);
            return true;
        });
    }

    destroy() {
        this._renderer.dispose();
        this._scene_3d.clear();
        this._overlay.destroy();
    }

    /**
     * Reset view
     */
    reset_camera() {
        this._renderer.reset_camera();
        this.refresh_rendering();
    }

    /**
     * Enlarge the view
     * @param {boolean} enlarge
     * @returns
     */
    change_view_size(enlarge) {
        if (this._enlarged == enlarge) return;

        if (enlarge) {
            if (this._container_div.classList.contains('large')) return;
            this._container_div.classList.add('large');
        } else {
            if (!this._container_div.classList.contains('large')) return;
            this._container_div.classList.remove('large');
        }
        this._enlarged = enlarge;
        this._overlay.visible = enlarge;
        this.refresh_rendering();
    }

    //***********************************************************************************
    /**
     * Rebuilds geometry
     */
    rebuild_geometry() {
        logger.log('rebuild geometry');
        var json = {};
        json.storey_list = ['0'];
        json.storey_heights = [0];
        json.instances = [];
        json.objects = [];
        const z0 = -this._roof.storey.height;
        this._roof.slabs.forEach(slab => {
            var polygon = slab.build_3d_polygon(0);
            if (polygon && polygon.get_area() > 0.1) {
                var bbp_roof = {};
                json.objects.push(bbp_roof);
                bbp_roof.ID = 'roof_slab_' + slab.ID;
                bbp_roof.Name = 'Toiture';
                bbp_roof.Code_BIM = 'roof';
                bbp_roof.geometries = [];
                bbp_roof.storey = '0';

                var geometry_3d = {};
                geometry_3d.color = slab.get_3d_color();
                geometry_3d.views = ['3d'];
                polygon.compute_tesselation();
                geometry_3d.vertices = polygon.tesselation_vertices.flat();
                geometry_3d.triangles = polygon.tesselation_triangles;
                bbp_roof.geometries.push(geometry_3d);

                var bbp_roof_walls = {};
                json.objects.push(bbp_roof_walls);
                bbp_roof_walls.ID = 'roof_wall_' + slab.ID;
                bbp_roof_walls.Name = 'Toiture';
                bbp_roof_walls.Code_BIM = 'roof';
                bbp_roof_walls.geometries = [];
                bbp_roof_walls.storey = '0';

                var geometry_walls = {};
                geometry_walls.color = WALL_COLOR;
                geometry_walls.views = ['3d'];
                geometry_walls.vertices = polygon.contour_vertices.flat().concat(polygon.contour_vertices.map(v => [v[0], v[1], z0]).flat());
                geometry_walls.triangles = [];
                bbp_roof_walls.geometries.push(geometry_walls);
                const nb_vertices = polygon.contour_vertices.length;
                var offset = 0;
                for (var nctr = 0; nctr < polygon.contour_sizes.length; nctr++) {
                    const sz = polygon.contour_sizes[nctr];
                    for (var nv = 0; nv < sz; nv++) {
                        const v0 = offset + nv;
                        const v1 = offset + ((nv + 1) % sz);
                        geometry_walls.triangles.push(v0, v1, v1 + nb_vertices);
                        geometry_walls.triangles.push(v0, v1 + nb_vertices, v0 + nb_vertices);
                    }
                    offset += sz;
                }
            }
        });

        this._roof.lines.forEach(line => {
            var polygon = line.build_overhang_polygon(0);
            if (polygon && polygon.get_area() > 0.1) {
                var bbp_roof = {};
                json.objects.push(bbp_roof);
                bbp_roof.ID = 'roof_overhang_' + line.ID;
                bbp_roof.Name = 'Toiture';
                bbp_roof.Code_BIM = 'roof';
                bbp_roof.geometries = [];
                bbp_roof.storey = '0';

                var geometry_3d = {};
                geometry_3d.color = line.slabs[0].get_3d_color();
                geometry_3d.views = ['3d'];
                polygon.compute_tesselation();
                geometry_3d.vertices = polygon.tesselation_vertices.flat();
                geometry_3d.triangles = polygon.tesselation_triangles;
                bbp_roof.geometries.push(geometry_3d);
            }
        });

        this._roof.roof_dormers.forEach(roof_dormer => {
            var bbp_dormer = {};
            json.objects.push(bbp_dormer);
            bbp_dormer.ID = 'roof_dormer_' + roof_dormer.ID;
            bbp_dormer.Name = 'Toiture';
            bbp_dormer.Code_BIM = 'roof';
            bbp_dormer.geometries = [];
            bbp_dormer.storey = '0';

            const polygons = roof_dormer.build_3d_polygons(0, CN_OUTER, true).concat(roof_dormer.build_3d_wall_polygons(0, CN_OUTER));
            polygons.forEach(polygon => {
                polygon.compute_tesselation();
                var geometry_3d = {};
                geometry_3d.color = this._get_roof_color(polygon.get_normal(), roof_dormer);
                geometry_3d.views = ['3d'];
                geometry_3d.vertices = polygon.tesselation_vertices.flat();
                geometry_3d.triangles = polygon.tesselation_triangles;
                bbp_dormer.geometries.push(geometry_3d);
            });
        });

        this._scene_3d.load_json(json);

        this._roof.slabs.forEach(slab => {
            slab.object_3d = this._scene_3d._objects_by_id['roof_slab_' + slab.ID];
            slab.object_3d_walls = this._scene_3d._objects_by_id['roof_wall_' + slab.ID];
        });

        this._roof.lines.forEach(line => {
            line.object_3d = this._scene_3d._objects_by_id['roof_overhang_' + line.ID];
        });

        this._roof.roof_dormers.forEach(roof_dormer => {
            roof_dormer.object_3d = this._scene_3d._objects_by_id['roof_dormer_' + roof_dormer.ID];
        });

        this._renderer.update_scene();
        this._renderer.refresh_rendering();
    };

    _get_roof_color(normal, dormer) {
        if (Math.abs(normal[2]) < 0.01) return WALL_COLOR;
        return dormer.slab.get_3d_color();
    }

    //***********************************************************************************
    /**
     * update geometry
     */
    update_geometry() {
        logger.log('update geometry');
        this._roof.slabs.forEach(slab => {

            //*** Compute slab geometry */
            if (slab.object_3d) {
                const col = slab.get_3d_color();
                slab.object_3d.children.forEach(mesh => {
                    if (typeof (mesh.geometry) == 'object' && typeof (mesh.geometry.vertices) == 'object') {
                        mesh.geometry.vertices.forEach(vtx => {
                            const zzz = slab.compute_height([vtx.x, vtx.y]);
                            vtx.z = zzz;
                        });
                        mesh.material.color.r = col[0];
                        mesh.material.color.g = col[1];
                        mesh.material.color.b = col[2];
                        mesh.geometry.verticesNeedUpdate = true;
                        mesh.geometry.normalsNeedUpdate = true;
                        mesh.geometry.colorsNeedUpdate = true;
                        mesh.geometry.elementsNeedUpdate = true;
                        mesh.geometry.computeFaceNormals();
                        mesh.geometry.computeBoundingBox();
                        mesh.geometry.computeBoundingSphere();
                    }
                });
            }

            //*** Compute walls geometry */
            if (slab.object_3d_walls) {
                slab.object_3d_walls.children.forEach(mesh => {
                    if (typeof (mesh.geometry) == 'object' && typeof (mesh.geometry.vertices) == 'object') {
                        for (var nv = 0; nv < mesh.geometry.vertices.length / 2; nv++) {
                            const vtx = mesh.geometry.vertices[nv];
                            const zzz = slab.compute_height([vtx.x, vtx.y]);
                            vtx.z = zzz;
                        }
                        ;
                        mesh.geometry.verticesNeedUpdate = true;
                        mesh.geometry.normalsNeedUpdate = true;
                        mesh.geometry.colorsNeedUpdate = true;
                        mesh.geometry.elementsNeedUpdate = true;
                        mesh.geometry.computeFaceNormals();
                        mesh.geometry.computeBoundingBox();
                        mesh.geometry.computeBoundingSphere();
                    }
                });
            }
        });

        this._roof.lines.forEach(line => {

            if (line.object_3d) {
                const slab = line.slabs[0];
                const col = slab.get_3d_color();
                line.object_3d.children.forEach(mesh => {
                    if (typeof (mesh.geometry) == 'object' && typeof (mesh.geometry.vertices) == 'object') {
                        mesh.geometry.vertices.forEach(vtx => {
                            const zzz = slab.compute_height([vtx.x, vtx.y]);
                            vtx.z = zzz;
                        });
                        mesh.material.color.r = col[0];
                        mesh.material.color.g = col[1];
                        mesh.material.color.b = col[2];
                        mesh.geometry.verticesNeedUpdate = true;
                        mesh.geometry.normalsNeedUpdate = true;
                        mesh.geometry.colorsNeedUpdate = true;
                        mesh.geometry.elementsNeedUpdate = true;
                        mesh.geometry.computeFaceNormals();
                        mesh.geometry.computeBoundingBox();
                        mesh.geometry.computeBoundingSphere();
                    }
                });
            }
        });

        this._roof.roof_dormers.forEach(roof_dormer => {

            if (roof_dormer.object_3d) {
                const polygons = roof_dormer.build_3d_polygons(0, CN_OUTER, true).concat(roof_dormer.build_3d_wall_polygons(0, CN_OUTER));
                roof_dormer.object_3d.children.forEach((mesh, index) => {
                    if (typeof (mesh.geometry) == 'object' && typeof (mesh.geometry.vertices) == 'object' && index < polygons.length && polygons[index].contour_vertices.length == mesh.geometry.vertices.length) {
                        mesh.geometry.vertices.forEach((vtx, k) => {
                            const v = polygons[index].contour_vertices[k]
                            vtx.x = v[0];
                            vtx.y = v[1];
                            vtx.z = v[2];
                        });
                        const col = this._get_roof_color(polygons[index].get_normal(), roof_dormer);
                        mesh.material.color.r = col[0];
                        mesh.material.color.g = col[1];
                        mesh.material.color.b = col[2];
                        mesh.geometry.verticesNeedUpdate = true;
                        mesh.geometry.normalsNeedUpdate = true;
                        mesh.geometry.colorsNeedUpdate = true;
                        mesh.geometry.elementsNeedUpdate = true;
                        mesh.geometry.computeFaceNormals();
                        mesh.geometry.computeBoundingBox();
                        mesh.geometry.computeBoundingSphere();
                    }
                });
            }
        });

        this._roof.roof_dormers.forEach(roof_dormer => {
        });

        this._scene_3d.update_bounding_box();
        this.refresh_rendering();
    }

    //***********************************************************************************
    /**
     * Refresh rendering
     */
    refresh_rendering() {
        this._renderer.refresh_rendering();
        if (this._overlay) this._overlay.refresh_rendering();
    }
}

