<div class="zone-surface-panel" *ngIf="surfaces != undefined">
    <div class="content">
        <app-piece-unitaire-besoin
            class="expension-panel"
            *ngFor="let pu of surfaces; let index = index"
            [pieceUnitaire]="pu"
            [indexBesoinSelected]="indexBesoinSelected"
            [index]="index"
            [besoins]="besoins"
            [volumes]="volumes"
            [selectedZones]="[zone]"
            (newBesoin)="nouveauBesoin($event)"
            (besoinActionEmitter)="besoinAction($event)"
            [isInEditMode]="isInEditMode"
            [showActions]="showActions"
        >
        </app-piece-unitaire-besoin>
    </div>
</div>
