import { cn_marker, cn_marker_handler, cn_mouse_event, cn_svg_map, cn_svg_tool_sampling_edition } from '@acenv/cnmap-editor';
import { MarkerHandler } from '../editor-map/handler/marker-handler';

export class CndiagToolSamplingEdition extends cn_svg_tool_sampling_edition {
    /**
     * @param svgMap
     */
    constructor(svgMap: cn_svg_map) {
        super(svgMap);
    }

    on_selection_change() {
        this._handler = null;
        this._handlers = [];
        // *** We create a handler only if single selection
        const sel = this._controller.get_selection();
        // *** build edition handler */
        this._handler = this._build_handler(sel as cn_marker[]);
        if (!this._handler) return;

        this._handlers.push(this._handler);
        if (this._view_overlay) {
            this._handler.on('shape_changed', function () {
                this._view_overlay.update_marker(sel[0]);
                this.call('change', sel);
            });
        }
        return true;
    }

    drag(mouse_event: cn_mouse_event) {
        if (this._handler) {
            const sel = this._controller.get_selection();
            return this._handler.drag(mouse_event);
        }
        return false;
    }

    _build_handler(markers: cn_marker[]): cn_marker_handler {
        if (markers[0].storey == undefined) {
            return null;
        }
        const markerHandler = new MarkerHandler(markers[0], this._map);
        // Prolonge l'évènement au composant parent
        markerHandler.on('change', () => {
            console.log(markers);
            this.call('sample_moved', markers);
        });
        // this.call('zone_selected', markers);
        return markerHandler;
    }
}
