import { Injectable } from '@angular/core';
import { InterventionApiService } from './intervention-api.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Document } from '../model/document.model';
import { FileApiService } from './file-api.service';
import { FileData } from '../shared/offline/offline-storage.service';
import { FileService } from './file.service';

/**
 * Service pour les fichiers des documents.
 */
// TODO Réimplémenter un mode HL
@Injectable({
    providedIn: 'root',
})
export class DocumentFileService {
    constructor(private interventionApiService: InterventionApiService, private fileApiService: FileApiService, private fileService: FileService) {}

    /**
     * Télécharge le fichier d'un document.
     */
    downloadFile(idIntervention: string, document: Document): void {
        this.interventionApiService.downloadDocumentFile(idIntervention, document);
    }

    /**
     * Télécharge le fichier du document passé en paramètre
     * @param document
     */
    downloadFileByFileId(document: Document): Observable<FileData> {
        return this.fileApiService.downloadFile(document.idFichier).pipe(
            tap((fileData) => {
                this.fileService.createElementToDownloadByFileContent(
                    document.nomFichier ? document.nomFichier : fileData.fileName ? fileData.fileName : 'default',
                    fileData.fileContent
                );
                return fileData;
            })
        );
    }

    /**
     * Affiche le fichier du document passé en paramètre
     * @param document
     * @returns {FileData}
     */
    AfficheFileByFileId(document: Document): Observable<FileData> {
        return this.fileApiService.downloadFile(document.idFichier).pipe(
            tap((fileData) => {
                return fileData;
            })
        );
    }
}
