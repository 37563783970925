import { EtatWorkflow } from './etat-workflow.model';
import { TypePrestation, TypePrestationEnum } from './type-prestation.model';
import { Document } from './document.model';
import { PresetPhoto } from './diagnostic.model';

export class ReferencePrestation {
    id: string;
    pictoUrl: string | ArrayBuffer;
    idFichierPicto: string;
    typePrestation: TypePrestation;
    nom: string;
    idRegles: string[] = [];
    etatPrestation: EtatWorkflow;
    pictoImage: FormData;
    idSalesforce: string;
    templates?: TemplateADO[];
    optionPlanActive: boolean;
    verificationPresence: boolean;
    descriptionTravaux: boolean;
    presetsReportagePhotosAuto: PresetPhoto[] = [];
    presetsExportScreenshot: string[] = [];
}

export enum TypeReport {
    REPORT = 'REPORT',
    BON_COMMANDE = 'BON_COMMANDE',
}

export class TemplateADO {
    typeReport: TypeReport = TypeReport.REPORT;
    document?: Document = null;
}

/**
 * TypeReport possible suivant le type de prestation
 */
export const mapTypeReportByTypePrestation: Map<TypePrestation, TypeReport[]> = new Map([
    [TypePrestationEnum.ASSAINISSEMENT, [TypeReport.REPORT]],
    [TypePrestationEnum.MESURAGE, [TypeReport.REPORT]],
    [TypePrestationEnum.MESURAGE_HABITABLE, [TypeReport.REPORT]],
    [TypePrestationEnum.MESURAGE_UTILE, [TypeReport.REPORT]],
    [TypePrestationEnum.HAP_VALIDATION, [TypeReport.BON_COMMANDE, TypeReport.REPORT]],
    [TypePrestationEnum.HAP_ETUDE_SITUATION, [TypeReport.REPORT]],
    [TypePrestationEnum.HAP_VISITE_RECONNAISSANCE, [TypeReport.REPORT]],
    [TypePrestationEnum.HAP_TERRAIN, [TypeReport.BON_COMMANDE, TypeReport.REPORT]],
    [TypePrestationEnum.HAP_VALIDATION_RESULTATS, [TypeReport.REPORT]],
    [TypePrestationEnum.ELECTRICITE, [TypeReport.REPORT]],
    [TypePrestationEnum.BAREN101, [TypeReport.REPORT]],
    [TypePrestationEnum.BAREN102, [TypeReport.REPORT]],
    [TypePrestationEnum.BAREN103, [TypeReport.REPORT]],
    [TypePrestationEnum.BARTH160, [TypeReport.REPORT]],
    [TypePrestationEnum.BARTH161, [TypeReport.REPORT]],
    [TypePrestationEnum.POLLUANT_ETUDE_SITUATION, [TypeReport.REPORT]],
    [TypePrestationEnum.POLLUANT_VISITE_CHANTIER, [TypeReport.REPORT]],
    [TypePrestationEnum.POLLUANT_PRELEVEMENT, [TypeReport.REPORT]],
    [TypePrestationEnum.POLLUANT_REDACTION_STRATEGIE, [TypeReport.REPORT]],
    [TypePrestationEnum.POLLUANT_RESULTATS_ANALYSES, [TypeReport.REPORT]],
    [TypePrestationEnum.GAZ, [TypeReport.REPORT]],
    // À COMPLÉTER AVEC LES DIFFÉRENTS TYPES DE PRESTATIONS...
]);
