export abstract class ZplService {
    protected constructor() {}

    protected debutEtiquette(): string {
        return '^XA^POI^LL1376^PW384';
        //^POI = renverse l'étiquette
        //^LL = longueur de l'étiquette (longueur en mm  / 25.4 * 203.2 ) exemple : 172 / 25.4 * 203.2 = 1376
        //^PW = largeur de l'étiquette (largeur en mm  / 25.4 * 203.2 ) exemple : 48 / 25.4 * 203.2 = 384
    }

    protected finEtiquette(): string {
        return '^XZ';
    }

    protected codeBarreDetachable(ref: string): string {
        return '^BY2,3,40^FT331,43^BCR,,N,N\n' + '^FH^FD' + ref + '^FS\n';
    }

    protected codeBarreCorps(ref: string): string {
        return '^BY2,3,40^FT225,900^BCR,,N,N\n' + '^FH^FD' + ref + '^FS\n';
    }

    protected qrCodeCorps(url: string): string {
        return '^FT79,1336^BQN,2,9\n' + '^FH^FDLA,' + url + '^FS\n';
    }

    protected referenceDetachable(ref: string): string {
        return '^FPH,2^FT22,55^A0N,23,23^FH^CI28^FD' + ref + '^FS\n';
    }

    protected descriptionMateriauDetachable(description: string): string {
        return '^FB280,7,0,J,,^FT22,201^AAN,18,10^FH^FD' + description + '^FS\n';
    }

    protected localisationMateriauDetachable(localisation: string): string {
        return '^FB280,3,0,J,,^FT22,277^AAN,18,10^FH^FD' + localisation + '^FS\n';
    }

    // le gros P999 (numéro de prélèvement)
    protected numeroPreloCorps(numero: number): string {
        return '^FPH,8^FT95,530^A0R,203,203^FH^CI28^FDP' + numero.toString() + '^FS\n';
    }

    // logo AC sur la petite partie détachable
    protected logoDetachable(): string {
        return (
            '^FO111,340^GFA,1406,2304,24,O0LFV0LFQ0N07MFT07MFP0M03NFCR03NFEO0M0PF8Q0PF8N0L03PFEP03PFEN0L0RFP0RF8M0K03RFCN03RFCM0K0TFN07SFM0J01TF8L01TF8L0J03KF8I0KFCL03KF8I0KFEL0J07JFL0KFL07JF8K0KFL0I01JFCL01JF8K0JFCL01JF8K0I03IFEN07IFCJ01JFN07IFCK0I07IF8N01IFEJ03IFCN01IFEK0I0JFP07IFJ07IFP07IFK0H01IFCP03IF8I0IFCP03IF8J0H01IF8P01IFCH01IF8Q0IFCJ0H03HFER07HFCH03IFR07HFEJ0H07HFCR03HFEH07HFER03HFEJ0H0IF8R01IFH07HFCR01IFJ0H0IFT0IF80IFT0IF8I001HFET07HF81IFT07HF8I003HFCT03HFC1HFET03HF8I003HF8T01HFC3HFCT01HF8I007HF8T01HFE3HF8U0HF8I007HFV0IF7HFV07FJ00HFEV07HF7HFV07CJ00HFEV03JFEV03K01HFCV03JFCgH01HF8V01JFCgH03HF8V01JF8gH03HFX0JF8gH03HFX0JFgI07FEX07IFgI0:7FEX07HFEgI0HFCX03HFEgI0:HFCX03HFCgI0HFCX01HFCgI0HF8X01HFCgI0:HF8X01HF8gI0::HF8Y0HF8gI0:::::HF8X01HF8gI0::HF8X01HFCgI0:HFCX01HFCgI0HFCX03HFCgI0:HFCX03HFEgI07FEX03HFEgI07FEX07HFEgI07FEX07IFgI07HFX0JFgI03HFX0JF8gH03HF8V01JF8gH01HF8V01JFCgH01HFCV03JFCgH01HFCV03JFEgH00HFEV07JFEV038J00IFV07HF7HFV07EJ007HFV0HFE7HF8U0HF8I007HF8T01HFE3HFCT01HF8I003HFCT03HFC3HFCT01HF8I001HFET07HFC1HFET03HF8I001IFT0IF80IFT07HF8I0H0IF8S0IFH0IF8S0IF8I0H07HFCR03IFH07HFCR01IFJ0H07HFER07HFEH03HFER03HFEJ0H03IFR0IFCH01IF8Q0IFCJ0H01IF8P01IF8H01IFCP01IFCJ0I0IFEP07IFJ0IFEP03IF8J0I07IFP0IFEJ07IF8O0JFK0I03IFCN03IFCJ03IFEN03IFEK0I01JFN0JF8J01JF8M0JFCK0J0JFEL07JFL0KFL03JF8K0J07JFCJ03JFEL07JFEJ03JFEL0J03LFH0LFCL01LF807KFCL0K0TFN0TF8L0K07RFEN03RFEM0K01RF8N01RFCM0L07QFP07QFN0L03PFCP01PFCN0M07NFER07OFO0M01NF8R01NF8O0N03LFCT03LFCP0\n' +
            '^FO52,440^GFA,1377,1320,40,j0:::::03FI03F8I07IF8FH0F3CH079E1IFI0FEH0FH0F0FH0F0JF1FC07F0JF1E01E3IFCL003F8H0HFEI07IF8F80F1EH0F1E1IF803HF80F80F0F80F0JF1FC07F0JF1F01E3IFCL003F801IFI07IF8FC0F1EH0F1E1IFC07HFC0FC0F0FC0F0JF1FC07F0JF1F81E3IFCL007F803IF8H07IF8FC0F1EH0F1E1IFE0IFE0FC0F0FC0F0JF1FE0HF0JF1F81E3IFCL007BC07E0F8H078I0FE0F0F01E1E1E03E1F83F0FE0F0FE0F0FI01HE0EF0FI01FC1EH0FN007BC07807CH078I0FE0F0F01E1E1E01E1EH0F0FE0F0FE0F0FI01HE0EF0FI01FC1EH0FN00FBC0F803I078I0HF0F0F01E1E1E01E3EH0F8HF0F0HF0F0FI01HE0EF0FI01FE1EH0FN00F1E0FL078I0F70F0783C1E1E03E3CH078F70F0F70F0FI01HE0EF0FI01HE1EH0FN00F1E0FL07IF0F78F0783C1E1IFC3CH078F78F0F78F0IFE1E71CF0IFE1EF1EH0FN01F1E0FL07IF0F3CF0783C1E1IFC3CH078F3CF0F3CF0IFE1E71CF0IFE1E79EH0FN01E0F0FL07IF0F3CF038381E1IF83CH078F3CF0F3CF0IFE1E71CF0IFE1E79EH0FN01E0F0FL07IF0F1EF03C781E1HFE03CH078F1EF0F1EF0IFE1E318F0IFE1E3DEH0FN03IF0FH03I078I0F0EF03C781E1E1F03CH078F0EF0F0EF0FI01E3B8F0FI01E1DEH0FN03IF8F807CH078I0F0HF01C701E1E0F83EH0F8F0HF0F0HF0FI01E3B8F0FI01E1FEH0FN03IF87807CH078I0F07F01EF01E1E0781F01F0F07F0F07F0FI01E3B8F0FI01E0FEH0FN07C0787E1F8H078I0F07F01EF01E1E07C1F83F0F07F0F07F0FI01E1B0F0FI01E0FEH0FN07803C3IF8H07IF8F03FH0HE01E1E03E0IFE0F03F0F03F0JF1E1F0F0JF1E07EH0FN07803C1IFI07IF8F03FH0FE01E1E01E07HFC0F03F0F03F0JF1E1F0F0JF1E07EH0FN0F803C0HFEI07IF8F01FH07C01E1E01F03HF80F01F0F01F0JF1E0E0F0JF1E03EH0FN0FH01E03F8I07IF8F01FH07C01E1EH0F80FEH0F01F0F01F0JF1E0E0F0JF1E03EH0FN0j0::::::\n'
        );
    }

    // logo + site web + n° vert
    protected logoCorps(): string {
        return (
            '^FO293,645^GFA,884,892,4,H018J001F8J00FEK00EL00FCK001F8J0H038J001F8J00FCK00EL00FEK001F8J0H018J0:01F8J00FEK00EL00FCK001F8J0H038J001F8J00FCK00EL00FEK001F8J0H018J0:01F8J00FEK00EL00FCK001F8J0H038J001F8J00FCK00EL00FEK001F8J0H018J0N00CL0:N0::073K00FB8J00D98J00CD8J0:07F8J00HFK008L0N003EK007FK00E38J00C18J0:0E38J0063K0N0:018K0::::N003EK007FK00ED8J00CD8J0:06FK002EK0N0:0HF8J0:H03K0H018J0::0HF8J00HFK0N0:H038J001F8J00FCK00EL00FCK001F8J0H038J0N0:0HFBJ0:N0:0HF8J0:H03K0H018J0::N003EK007FK00E38J00C18J0:0E38J007FK003EK0N0:0HF8J0:H03K0H018J0::0HF8J00HFK0N0:0HF8J0:H03K0H018J0::0HF8J00HFK0N0:03EK007FK00ED8J00CD8J0:06FK002EK0N0:0HF8J0:H03K0H018J0:0HF8J00HFK0H03K0H018J0:0HF8J00HFK0N0:03EK007FK00ED8J00CD8J0:06FK002EK0N0:0HF8J0:H03K0H018J0::0HF8J00HFK0N0H018J007FEJ00IFJ00C18J0:N0:0CL0:N0::03EK007FK00E38J00C18J0:0E38J0063K0N0:03EK007FK00E38J00C18J0:0E38J007FK003EK0N0:0HF8J0:H03K0H018J0:0HF8J00HFK0H03K0H018J0:0HF8J00HFK0N0\n' +
            '^FO261,626^GFA,1057,1044,4,N0:03IFEH0::J07CH0I01F8H0I07EI0I0F8I0H03EJ0H0FCJ003FK003IFEH0::N0::J038H0J07CH0J0C6H0::J07CH0J038H0N0::::::K02H0J01EH0J0FEH0I07FCH0H03FEI001HFJ003F8J003CK003F8J001HFJ0H03FEI0I07FCH0J0FEH0J01EH0K02H0N0:03IFEH0::03870EH0:::::::038H0EH0N0::03IFEH0::I070EH0::I0F0EH0H01F0EH0H03F0EH0H0FB9EH003F3FEH003C1FCH00380F8H002L0N0:K0EH0::::03IFEH0::K0EH0::::N0::::::::0381CI0::N0::::::::H03FEI0H0IF8H001IFCH003C01EH0038H0EH0::03C01EH001IFCH0H0IF8H0H03FEI0N0:::::::H078FI001FDFCH001IFCH003CF9EH003870EH0::03CF9EH001IFCH001FDFCH0H078FI0N0:H03FEI0H0IF8H001IFCH003C01EH0038H0EH0::03C01EH001IFCH0H0IF8H0H03FEI0N0:H03FEI0H0IF8H001IFCH003C01EH0038H0EH0::03C01EH001IFCH0H0IF8H0H03FEI0N0::::::H078J0H07CJ0H07FJ0H0H78I0H073EI0H070FI0H0703CH003IFEH0::H07K0:N0:H03FEI0H0IF8H001IFCH003C01EH0038H0EH0::03C01EH001IFCH0H0IF8H0H03FEI0N0:H03FEI0H0IF8H001IFCH003C01EH0038H0EH0::03C01EH001IFCH0H0IF8H0H03FEI0N0::::::::J0EI0J07I0:J038H003IFEH0::N0::::H03FEI0H0IF8H001IFCH003C01EH0038H0EH0::03C01EH001IFCH0H0IF8H0H03FEI0N0:H03FEI0H0IF8H001IFCH003C01EH0038H0EH0::03C01EH001IFCH0H0IF8H0H03FEI0N0\n' +
            '^FO326,672^GFA,434,596,4,N01FCK0104K0::0F8K0N0::1FCK0N0:1M00EL0058K00H4K0058K00EL01M0N00F8K0104K0:124K00E8K0N0::1FCK0018K002L00CL01FCK0N0::0F8K0104K0::0F8K0N0::098K0124K0:0C8K0N0:H04K0:1FCK0H04K0:N0:1FCK0N0::0F8K0104K0::0H8K0N0::098K0124K0:0C8K0N0::::::1FCK0N0:1FCK0018K00EL01M00EL0018K01FCK0N01FCK0018K00EL01M00EL0018K01FCK0N0:0F8K0104K0::0F8K0N0::1FCK0124K0:0F8K0N0::1FCK0N0::1FCK01M0::N0:1FCK0N0::1FCK0124K0:::N0:1FCK0024K0:064K0198K0N0::098K0124K0:0C8K0N0\n' +
            '^FO313,545^GFA,984,1464,12,M07FCT0L0JFES0K07KFCR0J01MFR0J07MFCQ0I01IFE0JFQ0I03HFCI07HF8P0I07FEK0HFEP0I0HF8K01HFP0H01HFM0HF8O0H03FCM07FCO0H07F8M01FEO0H0FEO0HFO001FCO07FO003F8O03F8N003F8O01FCN007FQ0FCN00FEQ0FEN00FCQ07EN01FCQ03FN01F8Q01FN01FR01F8M03FR01F8M03ES0F8M03ES0FCM07ES07CM0:7CS07CM0:::7CS03CM0::::7CS07CM0:::7ES07CM03ES0FCM0:3FS0F8M03FR01F8M01FR01F8M01F8Q03FN00FCQ07FN00FEQ07EN007EQ0FEN007FP01FCN003F8O01FCN003FCO03F8N001FEO07FO0H0HFN01FEO0H07F8M03FCO0H03FEM07F8O0H01HFL01HFP0I0HFCK07FEP0I03HF8I01HFCP0I01IF803IFQ0J0NFEQ0J03MFCQ0K0MFR0J01MFR0J07MFCQ0J0JFBJFQ0I03HFCI07HF8P0I07FEK0HFEP0I0HF8K03HFP0H01FEM0HF8O0H03FCM03FCO0H0HFN01FEO0H0FEO0HFO001FCO07F8N003F8O03F8N007FP01FCN007FQ0FEN00FEQ07EN00FCQ07EN01FCQ03FN01F8Q03FN01FR01F8M03FR01F8M03FS0F8M03ES0FCM07ES0FCM07ES07CM07CS07CM0:::7CS03CM0::::7CS07CM0:::7ES07CM07ES0FCM03ES0FCM03FS0F8M03FR01F8M01F8Q01F8M01F8Q03FN00FCQ07FN00FEQ07EN007EQ0FEN007FP01FCN003F8O03F8N001FCO07F8N001FEO07FO0H0HFO0FEO0H07F8M03FEO0H03FCM07F8O0H01FEM0HFP0I0FCM07EP0I07CM07CP0I018M03Q0\n' +
            '^FO335,672^GFA,1216,2360,8,03T003ES003FCR003HF8Q001IFQ0H03HFEP0H01IFCO0H01CHFCO0H01C1FCO0H01C03CO0H01C1FCO0H01CHFCO0H01IF8O0H03HFCP001HFEQ003HFR003F8R003CS002T0V0I0HFQ0H03HFCP0H07HFEP0H0JFP001F81F8O001EH078O003EH07CO003CH03CO0:::03EH03CO003EH07CO001F80F8O001FC1F8O0H0FC1FP0H0780EP0H01808P0V0::::::::::03JFCO0:::03C3C3CO0::::::::::03CH03CO0V0::03JFCO0:::J01F8O0J07FP0I01FCP0I03FQ0I0FCQ0H03F8Q0H0FER003F8R003JFCO0:::V0:L04O0K03CO0J01FCO0J0HFCO0I07HF8O0H03HFCP0H0HFEQ003FER003FS0038S003FS003FER0H0HFEQ0H03HFCP0I07HF8O0J0HFCO0J01FCO0K03CO0L04O0V0:03JFCO0:::V0:::03JFCO0:::I03C3CO0:::I07C3CO0I0FC3CO0H03FC3CO0H07FE7CO001FDHFCO003F9HF8O003E0HFP003C03EP003T002T0V0I0HFQ0H03HFCP0H07HFEP0H0JFP001F81F8O001FH078O003EH07CO003CH03CO0::::03EH07CO001FH078O001F81F8O0H0JFP0H07HFEP0H03HFCP0I0HFQ0V0::03JFCO0:::J01F8O0J07FP0I01FCP0I03FQ0I0FCQ0H03F8Q0H0FER003F8R003JFCO0:::V0:::03JFCO0:::J01F8O0J07FP0I01FCP0I03FQ0I0FCQ0H03F8Q0H0FER003F8R003JFCO0:::V0:::03JFCO0:::03C3C3CO0::::::::::03CH03CO0V0::03JFCO0:::K03CO0J03FCO0I01HFCO0H01HFEP0H0HFCQ003FCR003CS003FCR0H0HFCQ0H01HFEP0I01HFCO0J03FCO0K03CO003JFCO0:::V0:::03JFCO0:::03C3C3CO0::::::::::03CH03CO0V0::03JFCO0:::J01F8O0J07FP0I01FCP0I03FQ0I0FCQ0H03F8Q0H0FER003F8R003JFCO0:::V0::K03CO0:::::03JFCO0:::K03CO0:::::V0\n'
        );
    }

    // règle de 10cm
    protected regleCorps(): string {
        return (
            '^FO20,511^GB0,799,4^FS\n' +
            '^FO22,511^GB24,0,4^FS\n' +
            '^FO22,589^GB24,0,4^FS\n' +
            '^FO22,669^GB24,0,4^FS\n' +
            '^FO22,749^GB24,0,4^FS\n' +
            '^FO22,829^GB24,0,4^FS\n' +
            '^FO22,908^GB24,0,4^FS\n' +
            '^FO22,988^GB24,0,4^FS\n' +
            '^FO22,1068^GB24,0,4^FS\n' +
            '^FO22,1148^GB24,0,4^FS\n' +
            '^FO22,1228^GB24,0,4^FS\n' +
            '^FO22,1306^GB24,0,4^FS\n' +
            '^FO46,502^GFA,69,88,4,0FCK0102K0::0FCK0N0:0FL0108K0::09L0N0:1F8K001L0H08K01F8K0H08K0:1FL0N0\n' +
            '^FO46,899^GFA,73,88,4,098K0H16K0H12K0:0E2K0N0:0FL0108K0::09L0N0:1F8K001L0H08K01F8K0H08K0:1FL0N0\n' +
            '^FO46,1294^GFA,89,116,4,H08K0H04K01FEK0N0:::0FCK0102K0::0FCK0N0:0FL0108K0::09L0N0:1F8K001L0H08K01F8K0H08K0:1FL0N0'
        );
    }

    // merci de ne pas décoller cette étiquette
    protected attentionCorps(): string {
        return '^FO36,1340^GFA,997,836,44,jN0::404FE7F07H807E1FC0823F81F8103E03F0FE1E03820203F9FC01E1FCFEFEFE07F3FC0704H1FCFEFEFEL060C8041H8480431I0CH2H01042861021H80210C620202010602H1H010108H04H0H418C4H1H010108M060C8040H82804H1I0CH2H01042841020H8020H8H2020201020209H010108H04H0H410H4H1H010108M051480409H080409I0AH2H0104284H020480401012020201020401H010108H04H0H42024H1H010108M051480409H080409I0AH2H010I42H020480401012020201020401H010108H04H0H42024H1H010108M0514FE4H1H080409FC0923F810I4380204FE4010120203F9040401FC1010FE07F0H42024H1FC1010FEL0494807F1H080409I09H2H01F8H406020480401012020201FC0401H010108H04H0H42024H1H010108M04A480H41H080409I08A2H01H0FE0102048040101202020H1H0401H010108H04H0H42024H1H010108M04A480421H080409I08A2H01H08241020480401012020201080401H010108H04H0H42024H1H010108M04E48041082804H1I0862H01H08241020H8020H8H2020201040209H010108H04H0H4H1H4H1H010108M0I480410C480431I0862H01010142021H80310CH20202010403H1H010108H04H0H418CH21H010108M0I4FE4087H807E1FC0823F8101013E03F0FE1E03C3FBFBF90201E1FC1010FE07F0H40F61C1FC1010FEL0jN0:::\n';
    }

    // référence sous le code barre dans la partie détachable
    //protected referenceDetachableSousCodeBarre(reference: string) {
    //    return '^FPH,2^FT305,60^A0R,23,23^FH^CI28^FD' + reference + '^FS\n';
    //}

    // référence du sachet dans le corps pour les étiquettes sachet
    protected referenceCorps(reference: string) {
        return this.texteLibre2emeLigneCorps('Ref sachet : ' + reference);
    }

    // référence dde l'intervention dans le corps pour les étiquettes sachet
    protected referenceInterventionCorps(referenceIntervention: string) {
        return this.texteLibre3emeLigneCorps('Ref intervention : ' + referenceIntervention);
    }

    // nombre de prélo dans le sachet dans le corps pour les étiquettes sachet
    protected nombrePrelevementDansSachetCorps(nbPrelo: number) {
        return this.texteLibre4emeLigneCorps('Nombre prélèvement : ' + nbPrelo.toString());
    }

    protected texteLibre1ereLigneCorps(texte: string) {
        return '^FPH,2^FT225,530^A0R,23,23^FH^CI28^FD' + texte.substring(55, 0) + '^FS\n';
    }
    protected texteLibre2emeLigneCorps(texte: string) {
        return '^FPH,2^FT195,530^A0R,23,23^FH^CI28^FD' + texte.substring(55, 0) + '^FS\n';
    }
    protected texteLibre3emeLigneCorps(texte: string) {
        return '^FPH,2^FT165,530^A0R,23,23^FH^CI28^FD' + texte.substring(55, 0) + '^FS\n';
    }
    protected texteLibre4emeLigneCorps(texte: string) {
        return '^FPH,2^FT135,530^A0R,23,23^FH^CI28^FD' + texte.substring(55, 0) + '^FS\n';
    }
    protected texteLibre5emeLigneCorps(texte: string) {
        return '^FPH,2^FT105,530^A0R,23,23^FH^CI28^FD' + texte.substring(55, 0) + '^FS\n';
    }
    protected texteLibre6emeLigneCorps(texte: string) {
        return '^FPH,2^FT75,530^A0R,23,23^FH^CI28^FD' + texte.substring(55, 0) + '^FS\n';
    }
    protected texteLibre7emeLigneCorps(texte: string) {
        return '^FPH,2^FT45,530^A0R,23,23^FH^CI28^FD' + texte.substring(55, 0) + '^FS\n';
    }
    protected texteLibre8emeLigneCorps(texte: string) {
        return '^FPH,2^FT15,530^A0R,23,23^FH^CI28^FD' + texte.substring(55, 0) + '^FS\n';
    }

    protected texteLibreDetachableSousReference1ereLigne(texte: string) {
        return '^FT22,80^AAN,18,10^FD' + texte.substring(25, 0) + '^FS\n';
    }
    protected texteLibreDetachableSousReference2emeLigne(texte: string) {
        return '^FT22,100^AAN,18,10^FD' + texte.substring(25, 0) + '^FS\n';
    }
    protected texteLibreDetachableSousReference3emeLigne(texte: string) {
        return '^FT22,120^AAN,18,10^FD' + texte.substring(25, 0) + '^FS\n';
    }
    protected texteLibreDetachableSousReference4emeLigne(texte: string) {
        return '^FT22,140^AAN,18,10^FD' + texte.substring(25, 0) + '^FS\n';
    }
    protected texteLibreDetachableSousReference5emeLigne(texte: string) {
        return '^FT22,160^AAN,18,10^FD' + texte.substring(25, 0) + '^FS\n';
    }
    protected texteLibreDetachableSousReference6emeLigne(texte: string) {
        return '^FT22,180^AAN,18,10^FD' + texte.substring(25, 0) + '^FS\n';
    }

    // Texte libre dans la partie
    protected texteLibreDetachableSousReference(texte: string) {
        return '^FT22,330^AAN,18,10^FB300,8,0,J,,^FD' + texte + '^FS\n';
    }

    labelCalibration() {
        return '~JC\n';
    }
}
