<div class="besoin-prelevement" *ngIf="isLoaded">
    <mat-sidenav-container *ngIf="!noDescription" class="sidenav-container w-100">
        <div class="besoin-prelevement error-message" *ngIf="error">
            Les données saisies dans l'écran Zone sont incorrectes ou incomplètes
            {{ error.message }}
            <div>
                <button mat-raised-button color="primary" (click)="navigateEcran(error.redirect)">
                    {{ error.buttonLabel }}
                </button>
            </div>
        </div>

        <!-- Sidebar -->
        <mat-sidenav [mode]="'side'" [opened]="true" class="sidebar-button" *ngIf="!error">
            <div class="d-flex flex-column w-100 justify-content-end">
                <mat-button-toggle-group [vertical]="true" (change)="changeViewMode($event)" [value]="viewMode" appearance="legacy">
                    <mat-button-toggle matTooltip="Vue liste" value="list">
                        <mat-icon>list</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle matTooltip="Vue plan" value="plan">
                        <mat-icon>layers</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <mat-divider></mat-divider>
            </div>
        </mat-sidenav>

        <!-- Content -->
        <mat-sidenav-content *ngIf="!error" style="min-height: 85vh" class="main fullscreen-content" ngClass="main">
            <!-- MODE LISTE  -->
            <mat-sidenav-container *ngIf="viewMode === 'list' && !noDescription" [autosize]="true" class="sidenav-container h-100">
                <mat-sidenav [mode]="'side'" [opened]="true" [disableClose]="true" class="panel">
                    <ng-container *ngTemplateOutlet="templateCard"></ng-container>
                </mat-sidenav>
                <mat-sidenav-content class="main" style="min-height: 85vh">
                    <ng-container *ngIf="viewMode == 'list'">
                        <p>Liste</p>
                    </ng-container>
                </mat-sidenav-content>
            </mat-sidenav-container>
            <!-- MODE PLAN -->
            <ng-template [ngIf]="viewMode === 'plan' && !noDescription">
                <div class="d-flex no-padding">
                    <div class="w-100">
                        <div class="mapHeader">
                            <!-- Sélecteur de bien -->
                            <mat-form-field class="bienSelector" appearance="standard">
                                <mat-select [disabled]="false" [(value)]="bien.id" (selectionChange)="changeBien($event.value)">
                                    <mat-option *ngFor="let relationBien of listeBiens" [value]="relationBien.bien.id">
                                        {{ relationBien.bien.nom }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <!-- Sélecteur de niveau -->
                            <div class="d-flex flex-row align-items-center niveauSelectorContainer">
                                <button
                                    mat-icon-button
                                    color="primary"
                                    [ngClass]="'pl-0'"
                                    [disabled]="indexNiveauSelected == 0"
                                    (click)="previousNiveau()"
                                    data-cy="niveau-precedant-button"
                                >
                                    <lib-icon iconName="arrow-left-bold-circle" [theme]="'mdi'"></lib-icon>
                                </button>
                                <mat-form-field class="custom-select-container w-100" appearance="standard">
                                    <mat-select [disabled]="false" [(value)]="indexNiveauSelected" (selectionChange)="changeNiveau($event.value)">
                                        <mat-option *ngFor="let niveau of bien.description; let i = index" [value]="i"> Niveau {{ i }} </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <!-- <app-widget-select-search [options]="zones" (modelChange)="changeZone($event)">
                                        </app-widget-select-search> -->
                                <button
                                    mat-icon-button
                                    color="primary"
                                    [ngClass]="'pr-0'"
                                    [disabled]="indexNiveauSelected == bien.description.length - 1"
                                    (click)="nextNiveau()"
                                    data-cy="niveau-suivant-button"
                                >
                                    <lib-icon iconName="arrow-right-bold-circle" [theme]="'mdi'"></lib-icon>
                                </button>
                            </div>
                        </div>
                        <ng-container *ngTemplateOutlet="displayMap"></ng-container>
                    </div>
                    <div [style.minWidth]="'560px'" style="overflow: auto; height: calc(100vh - 80px)" class="panel">
                        <ng-container *ngTemplateOutlet="templateCard"></ng-container>
                    </div>
                </div>
            </ng-template>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
<!-- <div class="besoin-prelevement error-message" *ngIf="isLoaded && contenuDiagnosticPolluant.zones.errors.length != 0">
    Les données saisies dans l'écran Zone sont incorrectes ou incomplètes
    <div>
        <button mat-raised-button color="primary" (click)="navigateEcranZone()">Retourner à l'écran Zone</button>
    </div>
</div> -->

<ng-template #displayMap class="sidebar-map">
    <app-viewer-map-polluant
        *ngIf="building && !error"
        #viewMap
        [building]="building"
        [currentBien]="currentBien"
        [currentNiveau]="currentNiveau"
        [useDiagTool]="true"
        [multiSelection]="false"
        [showControls]="true"
        [height100]="false"
        (toolEvent)="onTouchOnPlan($event)"
        [currentTool]="currentMapTool"
        [referenceToMarker]="elementToLocate"
        [externalSelectedVolumes]="externalSelectedVolumes"
    >
    </app-viewer-map-polluant>
</ng-template>

<ng-template #templateCard>
    <verification-card
        *ngIf="typeMesurage === TypePrestationEnum.POLLUANT_VISITE_CHANTIER"
        [showValidateButton]="typeMesurage === TypePrestationEnum.POLLUANT_VISITE_CHANTIER"
        [isInEditMode]="isInEditMode"
        [isValidated]="isValidated"
        (onCancel)="cancelModification()"
        (onSave)="saveModification()"
        (onValidate)="validateTab()"
        (onEdit)="startModification()"
        [form]="form"
    >
        Veuillez vérifier les besoins
    </verification-card>

    <div class="d-flex flex-row align-items-center" [ngClass]="'w-100 p-0'">
        <button
            mat-icon-button
            color="primary"
            [ngClass]="'pl-0'"
            [disabled]="indexZoneSelected == 0"
            (click)="nextOrPreviousZone(-1)"
            data-cy="niveau-precedant-button"
        >
            <lib-icon iconName="arrow-left-bold-circle" [theme]="'mdi'"></lib-icon>
        </button>
        <mat-form-field class="custom-select-container w-100" appearance="standard">
            <mat-select [disabled]="false" [(value)]="indexZoneSelected" (selectionChange)="changeZone($event.value)">
                <mat-option *ngFor="let zone of zones; let i = index" [value]="i">
                    {{ zone.identifiant }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!-- <app-widget-select-search [options]="zones" (modelChange)="changeZone($event)">
            </app-widget-select-search> -->
        <button
            mat-icon-button
            color="primary"
            [ngClass]="'pr-0'"
            [disabled]="indexZoneSelected == zones.length - 1"
            (click)="nextOrPreviousZone(1)"
            data-cy="niveau-suivant-button"
        >
            <lib-icon iconName="arrow-right-bold-circle" [theme]="'mdi'"></lib-icon>
        </button>
    </div>
    <div class="d-flex flex-row mb-3">
        <p class="zone-name badge-info-grey-little">{{ currentZone.identifiant }}</p>
        <div class="d-flex flex-column align-items-center p-0">
            <span>Nb PU</span>
            <span class="badge-info-grey-little">{{ puTotal(currentZone) }}</span>
        </div>
    </div>

    <div class="liste-objectifs">
        <!-- Objectif mesurage panel -->
        <ng-container *ngFor="let formObj of form.controls; let i = index">
            <mat-expansion-panel class="objectif-accordion" [expanded]="true" [formGroup]="formObj" icon>
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ formObj.get('objectifMesurage').value.label }}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="accordion-content">
                    <div class="d-flex justify-content-between">
                        <div>
                            Nb besoins min
                            <span class="badge-info-grey-little">{{ formObj.get('nbBesoinMin').value }}</span>
                        </div>
                        <div>
                            Nb besoins supp
                            <span class="badge-info-grey-little">{{ formObj.get('nbBesoinSupp').value }}</span>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            Fréquence
                            <span class="badge-info-grey-little">{{ formObj.get('objectifMesurage').value.frequence }}</span>
                        </div>
                        <button mat-flat-button color="primary" class="h-100 unfill-light-blue-btn" (click)="ajouterBesoin(i)">
                            <div class="small-font d-flex justify-content-between">
                                <mat-icon>add</mat-icon>
                                Ajouter un besoin
                            </div>
                        </button>
                    </div>
                    <div class="row besoinsContainer">
                        <ng-container *ngFor="let besoin of formObj.get('besoins').controls; let j = index">
                            <mat-expansion-panel
                                class="besoin-accordion"
                                [class.selected]="besoin.get('id').value == idBesoinSelected"
                                [formGroup]="besoin"
                                (opened)="showError = true; onBesoinSelected(besoin.get('id').value)"
                                (closed)="onBesoinSelected(null)"
                            >
                                <mat-expansion-panel-header
                                    [ngStyle]="{
                                        'min-height':
                                            besoin.get('objectifMesurage').value.norme.indexOf('43-269') > 0 &&
                                            besoin.get('objectifMesurage').value.reference === '13J'
                                                ? '130px'
                                                : '50px'
                                    }"
                                >
                                    <!-- Affiche le numero du besoin + son type -->
                                    <!-- ex: BP2 (META) -->
                                    <mat-panel-title
                                        >BP{{ besoin.get('numero').value }} ({{
                                            besoin.get('objectifMesurage').value.norme.indexOf('43-050') > 0
                                                ? TypePrelevementAmiante.META
                                                : besoin.get('objectifMesurage').value.norme.indexOf('43-269') > 0
                                                ? TypePrelevementAmiante.METOP
                                                : ''
                                        }})</mat-panel-title
                                    >
                                    <mat-panel-description>
                                        <ng-template [ngIf]="besoin.get('objectifMesurage').value.norme.indexOf('43-269') > 0">
                                            <div class="d-flex flex-column">
                                                <!-- @See POLL-COR-578-->
                                                <ng-template [ngIf]="besoin.get('objectifMesurage').value.reference === '13J'">
                                                    <app-custom-select
                                                        #appCustomSelect
                                                        class="w-100"
                                                        [ngClass]="{
                                                            'gradient-background': showError && !besoin.get('objectifMesurage').value.contexte
                                                        }"
                                                        [placeholder]="'Contexte'"
                                                        [(selectedValue)]="besoin.get('objectifMesurage').value.contexte"
                                                        [readonly]="false"
                                                        [filter]="false"
                                                        [required]="true"
                                                        [matFormFieldClass]="'w-100'"
                                                        [choices]="[null, 'Chantier Test', 'Validation 1', 'Validation 2', 'Validation 3']"
                                                        [addline]="false"
                                                    >
                                                    </app-custom-select>
                                                </ng-template>
                                                <app-custom-select
                                                    #appCustomSelect
                                                    class="w-100"
                                                    [ngClass]="{
                                                        'gradient-background': showError && !besoin.get('objectifMesurage').value.proessusName
                                                    }"
                                                    [placeholder]="'Processus'"
                                                    [(selectedValue)]="besoin.get('objectifMesurage').value.proessusName"
                                                    [readonly]="false"
                                                    [filter]="false"
                                                    [required]="true"
                                                    [matFormFieldClass]="'w-100'"
                                                    [choices]="lstProcessus"
                                                    [addline]="false"
                                                >
                                                </app-custom-select>
                                            </div>
                                        </ng-template>
                                        <!-- Si meta, on affiche le sélecteur d'orientatin -->
                                        <button
                                            *ngIf="besoin.get('objectifMesurage').value.norme.indexOf('43-050') > 0"
                                            mat-icon-button
                                            matTooltip="Orientation du prélèvement"
                                            (click)="ouvrirDialogOrientation($event, besoin)"
                                        >
                                            <!-- <mat-icon>grid_on</mat-icon> -->
                                            <!-- {{ besoin.get('orientation').value ?? 'x' }} -->
                                            <app-orientation-indicator
                                                [orientation]="besoin.get('orientation').value"
                                                [precisionOrientation]="besoin.get('precisionOrientation').value"
                                                [valid]="!!besoin.get('orientation').value"
                                            ></app-orientation-indicator>
                                        </button>
                                        <!-- Bouton pour localiser le prélèvement précisement si META -->
                                        <button
                                            *ngIf="besoin.get('objectifMesurage').value.norme.indexOf('43-050') > 0"
                                            mat-icon-button
                                            matTooltip="Emplacement du prélèvement"
                                            [class.error]="!besoin.get('markerId').value"
                                            (click)="localiserPrelevement($event, besoin.get('id').value)"
                                        >
                                            <mat-icon>location_on</mat-icon>
                                        </button>

                                        <app-notes-buttons
                                            [from]="besoin.get('commentairesId')"
                                            [diagnostic]="diagnostic"
                                            [buttonId]="'app-notes-buttons' + j"
                                            [typesCommentaires]="[
                                                enumTypesCommentaire.NOTE_PERSONNELLE,
                                                enumTypesCommentaire.CONSTATATION_DIVERSE,
                                                enumTypesCommentaire.JUSTIFICATION_DU_PRELEVEMENT
                                            ]"
                                            [typeNouveauCommentaire]="enumTypesCommentaire.JUSTIFICATION_DU_PRELEVEMENT"
                                        >
                                        </app-notes-buttons>
                                        <button
                                            *ngIf="j >= formObj.get('nbBesoinMin').value"
                                            mat-icon-button
                                            matTooltip="Supprimer le besoin"
                                            class="warn"
                                            (click)="supprimerBesoin($event, i, j)"
                                        >
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <div class="besoin-accordion-content">
                                    <!-- Bouton Emplacement de la mesure -->
                                    <div *ngIf="besoin?.value?.objectifMesurage?.emplacement" class="d-flex">
                                        <mat-icon class="primary mr-2 mt-1 icon-small align-bottom">location_on</mat-icon>
                                        <div class="card-subtitle-bold">
                                            {{ besoin.value.objectifMesurage.emplacement }}
                                        </div>
                                    </div>
                                    <mat-list>
                                        <ng-container *ngFor="let volume of volumes">
                                            <mat-list-item *ngIf="besoin.get('piecesId').value?.includes(volume.id)">
                                                <button
                                                    *ngIf="besoin.get('objectifMesurage').value.norme.indexOf('43-269') > 0"
                                                    mat-icon-button
                                                    matTooltip="Supprimer"
                                                    class="warn"
                                                    (click)="supprimerEspaceMetop(volume.id, besoin)"
                                                >
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                                <div mat-line>{{ volume.nom }}</div>
                                            </mat-list-item>
                                        </ng-container>
                                    </mat-list>
                                </div>
                            </mat-expansion-panel>
                            <ng-template [ngIf]="j >= formObj.get('nbBesoinMin').value && !besoin.get('commentairesId').value?.length">
                                <div class="d-flex align-items-center to-justify w-100">
                                    <div>Il manque une justification</div>
                                    <button mat-icon-button color="justify" class="ml-1" (click)="addJustification('app-notes-buttons' + j)">
                                        <lib-icon theme="mdi" iconName="comment-alert"></lib-icon>
                                    </button>
                                </div>
                            </ng-template>
                        </ng-container>
                    </div>
                </div>
            </mat-expansion-panel>
        </ng-container>
    </div>
</ng-template>
