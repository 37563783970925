<app-mpca-form-skeleton>
    <div title>Général</div>
    <div form>
        <div [formGroup]="formGeneral" class="form-general">
            <div class="materiaux-selecter-container">
                <mat-form-field appearance="outline" (click)="selectMaterial(formGeneral.get('materiaux')?.value)">
                    <mat-label>Matériaux</mat-label>
                    <input matInput type="text" [disabled]="true" [value]="formGeneral.get('materiaux')?.value?.nom" />
                </mat-form-field>

                <button
                    mat-raised-button
                    color="primary"
                    (click)="selectMaterial(formGeneral.get('materiaux')?.value)"
                    [disabled]="disabled"
                    data-cy="select-material-button"
                >
                    {{ formGeneral.get('materiaux')?.value ? 'Modifier' : 'Ajouter' }}
                </button>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>Matériaux Client</mat-label>
                <input
                    matInput
                    placeholder="Crépi"
                    formControlName="materiauxClient"
                    [readonly]="disabled"
                    [class.disabled]="disabled"
                    data-cy="materiauxClient"
                />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Type d'amiante</mat-label>

                <mat-select formControlName="typeAmiante" multiple required [disabled]="disabled" data-cy="typeAmiante">
                    <mat-option *ngFor="let typeAmiante of typeAmianteConfig.items" [value]="typeAmiante"
                        >{{ typeAmiante }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <app-mpca-form-skeleton>
                <div title>État de dégradation</div>
            </app-mpca-form-skeleton>
            <div class="d-flex flex-row justify-content-between">
                <input type="hidden" formControlName="etatDegradation" data-cy="etatDegradation" />
                <ng-template ngFor let-etat [ngForOf]="etatsDegradation">
                    <ng-container
                        *ngTemplateOutlet="
                            contentTpl;
                            context: {
                                control: formGeneral.get('etatDegradation'),
                                options: etat.values,
                                title: etat.title
                            }
                        "
                    ></ng-container>
                </ng-template>
            </div>
        </div>
    </div>
</app-mpca-form-skeleton>
<ng-template let-options="options" let-title="title" let-control="control" #contentTpl>
    <div class="d-flex flex-column">
        <div style="text-align: center">
            <b>{{ title }}</b>
        </div>
        <div
            *ngFor="let item of options"
            [ngClass]="{ disabled: disabled }"
            class="badge-select m-1 p-2 {{ colorByItem(item, control.value === item) }}"
            (click)="control.setValue(item)"
            data-cy="degradation-badge"
        >
            {{ item }}
        </div>
    </div>
</ng-template>
