<div class="compass-container">
    <div class="row">{{ _value | formatWindDirection }}</div>
    <div class="compass">
        <div class="row">
            <div class="no" id="direction-no" (click)="select($event)">
                <div id="part1"></div>
            </div>

            <div class="vertical" id="direction-n" (click)="select($event)">
                <div id="part1"></div>
                <div id="part2"></div>
            </div>

            <div class="ne" id="direction-ne" (click)="select($event)">
                <div id="part1"></div>
            </div>
        </div>
        <div class="row center">
            <div class="horizontal" id="direction-o" (click)="select($event)">
                <div id="part1"></div>
                <div id="part2"></div>
            </div>
            <div class="circle"></div>
            <div class="horizontal" id="direction-e" (click)="select($event)">
                <div id="part1"></div>
                <div id="part2"></div>
            </div>
        </div>

        <div class="row">
            <div class="so" id="direction-so" (click)="select($event)">
                <div id="part1"></div>
            </div>

            <div class="vertical" id="direction-s" (click)="select($event)">
                <div id="part1"></div>
                <div id="part2"></div>
            </div>

            <div class="se" id="direction-se" (click)="select($event)">
                <div id="part1"></div>
            </div>
        </div>
    </div>
</div>
