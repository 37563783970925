<div class="toolbar">
    <mat-toolbar class="border-bottom-1 primary">
        <button mat-button class="px-0" (click)="back()">
            <lib-icon>arrow_back</lib-icon>
        </button>
        <div>
            <lib-icon [class]="'icon-before-text'">folder_copy</lib-icon>
            <span *ngIf="mode === 'CREATE' && !isReadOnly">Création d'un rapport de synthèse</span>
            <span *ngIf="mode === 'EDIT' && !isReadOnly">Edition d'un rapport de synthèse</span>
            <span *ngIf="isReadOnly">Consultation d'un rapport de synthèse</span>
        </div>
        <div class="d-flex justify-content-end w-100">
            <button mat-button color="primary" class="mx-1" (click)="back()">
                <mat-icon>close</mat-icon>
                <span>{{ 'Annuler' | uppercase }}</span>
            </button>
            <button
                mat-raised-button
                color="primary"
                [disabled]="
                    formSynthese?.invalid ||
                    !anyCommandeMere ||
                    (selection?.selectedItems?.length === 0 && !selection?.all?.checked)
                "
                (click)="creerExportSynthese(selection)"
            >
                <lib-icon>check</lib-icon>
                <span>{{ 'Générer la synthèse' | uppercase }}</span>
            </button>
        </div>
    </mat-toolbar>
</div>
<div class="intervention-container">
    <div class="h-100 row no-gutters p-4 scrollable-content" [formGroup]="formSynthese">
        <!-- Colonne gauche-->
        <div class="col-xl-6 pr-2">
            <!-- Commandes -->
            <mat-card class="p-2 mt-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary">Commandes<span class="required"></span></div>
                        <div class="col-xl-8">
                            <mat-form-field class="w-100 flex-grow-1">
                                <input
                                    formControlName="commande"
                                    [matAutocomplete]="autoCommande"
                                    [required]="true"
                                    matInput
                                    [readonly]="isReadOnly"
                                    placeholder="Numéro de commande"
                                    type="text"
                                />
                                <mat-error *ngIf="formSynthese?.get('commande').hasError('commandeDoesNotExist')">
                                    Aucune commande n'existe avec ce numéro.
                                </mat-error>
                                <mat-autocomplete
                                    #autoCommande="matAutocomplete"
                                    [displayWith]="displayFnCommande"
                                    (optionSelected)="onClickBtnAddCommandFromSelect($event)"
                                >
                                    <mat-option *ngIf="isSearchingCommandes | async" class="is-loading">
                                        <mat-spinner diameter="25"></mat-spinner>
                                    </mat-option>
                                    <ng-container *ngIf="!(isSearchingCommandes | async)">
                                        <mat-option *ngFor="let option of filteredSearchCommandes" [value]="option">
                                            {{ option.numeroCommande }}
                                        </mat-option>
                                    </ng-container>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

            <!--Liste des commandes-->
            <div
                class="d-flex flex-wrap justify-content-between align-items-start mb-2"
                *ngIf="commandes$ | async as commandes"
            >
                <mat-card
                    class="mt-1 d-flex flex-column justify-content-between position-relative small-card"
                    *ngFor="let com of commandes$ | async as commandes; let index = index"
                >
                    <div>
                        <div class="position-absolute align-self-end button-group">
                            <button *ngIf="!isReadOnly" mat-icon-button class="warn" (click)="deleteCommande(index)">
                                <lib-icon class="icon-small">delete</lib-icon>
                            </button>
                        </div>
                        <div class="mat-h3 bold ml-2 mb-2">{{ com?.numeroCommande }}</div>
                        <div class="d-flex">
                            <div class="mr-2">
                                <lib-icon [class]="'primary'" class="icon-small align-bottom">person</lib-icon>
                            </div>
                            <div>{{ com?.contact?.nom }}</div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-2 justify-content-between bold primary">
                        {{ 'Rattacher la synthèse à cette commande' | uppercase }}
                        <app-state-input
                            [disabled]="isReadOnly"
                            [choices]="[trueOption, falseOption]"
                            [tooltips]="tooltipSyntheseCommande"
                            [(choiceValue)]="com.commandeMere"
                            (valueChangeFromUser)="CommandeMereChanged($event, com)"
                        ></app-state-input>
                    </div>
                </mat-card>
            </div>
        </div>

        <div class="col-xl-6 pr-2">
            <mat-card class="p-2 mt-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary">Template<span class="required"></span></div>
                        <div class="col-xl-8">
                            <app-custom-select
                                class="w-100"
                                [readonly]="isReadOnly"
                                formControlName="template"
                                [selectControl]="formSynthese.get('template')"
                                [matFormFieldClass]="'w-100'"
                                [choices]="listTemplates"
                                [addline]="false"
                                [pathValueDisplay]="['nom']"
                            >
                            </app-custom-select>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="w-100">
            <mat-card *ngIf="formSynthese?.get('commandes')?.value?.length" class="mb-2">
                <div class="d-flex flex-column">
                    <div class="d-flex flex-row w-100">
                        <div class="d-flex align-items-center flex-grow-1 p-2">
                            <div class="mat-subheading-2 m-0 primary col-3">Types prestation</div>
                            <div class="col-9">
                                <app-custom-select
                                    class="w-100"
                                    formControlName="typesPrestations"
                                    [matFormFieldClass]="'w-100'"
                                    [choices]="listTypesPrestation"
                                    [pathValueDisplay]="['label']"
                                    [addline]="false"
                                    [multiple]="true"
                                    (valueChangeFromUser)="toggleCheckAll(false)"
                                >
                                </app-custom-select>
                            </div>
                        </div>
                        <div class="d-flex p-2 align-items-center">
                            <div class="mat-subheading-2 m-0 primary">Depuis</div>
                            <mat-form-field class="p-2" (click)="datepickerFrom.open()" appearance="fill">
                                <input
                                    matInput
                                    formControlName="startDate"
                                    [matDatepicker]="datepickerFrom"
                                    (dateChange)="toggleCheckAll(false)"
                                />
                                <mat-datepicker-toggle matSuffix [for]="datepickerFrom"></mat-datepicker-toggle>
                                <mat-datepicker #datepickerFrom></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="d-flex p-2 align-items-center">
                            <div class="mat-subheading-2 m-0 primary">Jusqu'à</div>
                            <mat-form-field class="p-2" (click)="datepickerTo.open()" appearance="fill">
                                <input
                                    matInput
                                    formControlName="endDate"
                                    [matDatepicker]="datepickerTo"
                                    (dateChange)="toggleCheckAll(false)"
                                />
                                <mat-datepicker-toggle matSuffix [for]="datepickerTo"></mat-datepicker-toggle>
                                <mat-datepicker #datepickerTo></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card>
                <div class="d-flex flex-column">
                    <table
                        mat-table
                        [dataSource]="dataSource"
                        matSort
                        (matSortChange)="sortPrestations($event)"
                        [matSortActive]="sort?.active"
                        [matSortDirection]="sort?.direction"
                        matSortDisableClear="true"
                    >
                        <ng-container matColumnDef="check">
                            <th mat-header-cell *matHeaderCellDef>
                                <div style="padding-left: 8px">
                                    <mat-checkbox
                                        class="square-check-box no-label"
                                        [checked]="selection.all.checked"
                                        [indeterminate]="selection.all.indeterminate"
                                        (change)="toggleCheckAll($event.checked)"
                                    ></mat-checkbox>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox
                                    [checked]="row | isPrestationChecked: selection"
                                    class="square-check-box no-label"
                                    (change)="toggleCheck($event, row)"
                                ></mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="numCommande">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom commande</th>
                            <td mat-cell *matCellDef="let row">{{ row.numCommande }}</td>
                        </ng-container>
                        <ng-container matColumnDef="client">
                            <th mat-header-cell *matHeaderCellDef>Client</th>
                            <td mat-cell *matCellDef="let row">{{ row.client?.nom }}</td>
                        </ng-container>
                        <ng-container matColumnDef="nomIntervention">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom intervention</th>
                            <td mat-cell *matCellDef="let row">{{ row.nomIntervention }}</td>
                        </ng-container>
                        <ng-container matColumnDef="typePrestation">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Prestation</th>
                            <td mat-cell *matCellDef="let row">{{ row.typePrestation | typePrestation }}</td>
                        </ng-container>
                        <ng-container matColumnDef="adresse">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Adresse</th>
                            <td mat-cell *matCellDef="let row">
                                {{ row?.adresse }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="prestataires">
                            <th mat-header-cell *matHeaderCellDef>Opérateurs</th>
                            <td mat-cell *matCellDef="let row">
                                <ng-container *ngFor="let prestataire of row.prestataires; let last = last"
                                    >{{ prestataire.firstName }} {{ prestataire.lastName
                                    }}<ng-container *ngIf="!last"> <br /></ng-container
                                ></ng-container>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="dateIntervention">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                            <td mat-cell *matCellDef="let row">
                                {{ row.dateIntervention | date: DATE_FORMAT_FRANCAIS_HH_MM }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="etatDiagnostic">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Statut</th>
                            <td mat-cell *matCellDef="let row">
                                <div>
                                    <mat-chip-list>
                                        <mat-chip class="statut-intervention" [ngClass]="row.etatDiagnostic">{{
                                            row.etatDiagnostic | etatDiagnostic
                                        }}</mat-chip>
                                    </mat-chip-list>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </table>
                    <mat-paginator
                        #paginator
                        *ngIf="dataSource.page$ | async as page"
                        [length]="page.totalElements"
                        [pageSize]="page.size"
                        [pageIndex]="page.number"
                        [pageSizeOptions]="[20, 50, 100]"
                        (page)="dataSource.fetch($event.pageIndex, paginator.pageSize)"
                    ></mat-paginator>
                </div>
            </mat-card>
        </div>
    </div>
</div>
