import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestionAgencesComponent } from './gestion-agences.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LibFileUploaderModule, LibIconModule } from 'src/app/commons-lib';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TableauAgencesComponent } from './tableau-agences/tableau-agences.component';
import { MatInputModule } from '@angular/material/input';
import { PipesModule } from '../../../pipes/pipes.module';
import { CreationAgenceComponent } from './creation-agences/creation-agence.component';
import { CnDiagSharedModule } from '../../shared/shared.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TableauAssuranceComponent } from './creation-agences/tableau-assurance/tableau-assurance.component';
import { CreationAssuranceModalComponent } from './creation-agences/tableau-assurance/creation-assurance/creation-assurance-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
    declarations: [
        GestionAgencesComponent,
        TableauAgencesComponent,
        CreationAgenceComponent,
        TableauAssuranceComponent,
        CreationAssuranceModalComponent,
    ],
    imports: [
        CommonModule,
        MatToolbarModule,
        LibIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatCardModule,
        FormsModule,
        MatIconModule,
        MatTableModule,
        MatSortModule,
        MatMenuModule,
        MatPaginatorModule,
        MatInputModule,
        PipesModule,
        CnDiagSharedModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatDatepickerModule,
        LibFileUploaderModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
    ],
    exports: [GestionAgencesComponent, TableauAgencesComponent],
    entryComponents: [CreationAssuranceModalComponent],
})
export class GestionAgencesModule {}
