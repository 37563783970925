<h1 mat-dialog-title class="primary d-flex justify-content-between">
    <span>Editer une pièce</span>
    <button mat-button (click)="close()">
        <lib-icon>close</lib-icon>
    </button>
</h1>

<div mat-dialog-content [formGroup]="form">
    <div class="d-flex">
        <mat-form-field class="w-50">
            <mat-label>Base du nom</mat-label>
            <input matInput type="text" formControlName="nomBase" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-50 pl-3">
            <mat-label>Type de volume</mat-label>
            <mat-select formControlName="selectedTypeVolume">
                <mat-option *ngFor="let typeVolume of listTypeVolumes" [value]="typeVolume.id">
                    {{ typeVolume.nom }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="d-flex">
        <mat-form-field class="w-100 pl-3">
            <mat-label>Numéro de lot</mat-label>
            <input placeholder="Numéro de lot" matInput type="text" formControlName="numLot" />
        </mat-form-field>
    </div>
    <div class="d-flex flex-row align-items-center">
        <div class="w-25">
            <div class="mat-caption mat-label-like">Valeur numérotation</div>
            <app-increment-input-reactive
                class="tiny-increment"
                formControlName="valeurNumerotation"
                [incrementControl]="form.get('valeurNumerotation')"
                [min]="form.get('numerotationDebut').value"
                [step]="1"
                [showMatError]="false"
                [disabled]="numerotationDisabled"
            >
            </app-increment-input-reactive>
        </div>

        <div class="w-25">
            <div class="mat-caption mat-label-like">Numérotation début</div>
            <app-increment-input-reactive
                class="tiny-increment"
                formControlName="numerotationDebut"
                [incrementControl]="form.get('numerotationDebut')"
                [min]="1"
                [step]="1"
                [showMatError]="false"
                [disabled]="numerotationDisabled"
            >
            </app-increment-input-reactive>
        </div>
        <mat-form-field class="w-50">
            <mat-label>Préfixe de numérotation</mat-label>
            <input matInput type="text" formControlName="prefixeNumerotation" />
        </mat-form-field>
    </div>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-button color="primary" (click)="close()">
        <lib-icon>close</lib-icon>
        <span>{{ 'Annuler' | uppercase }}</span>
    </button>
    <button mat-raised-button [disabled]="!form.valid" color="accent" (click)="confirm()" data-cy="confirmer-ajouter-niveau-button">
        <lib-icon>check</lib-icon>
        <span>{{ 'Confirmer' | uppercase }}</span>
    </button>
</div>
