import { Pipe, PipeTransform } from '@angular/core';
import { TypePrelevement, typePrelevementToLabel } from '../model/prelevement-generique.model';

/**
 * Affiche le nom d'un type de prélèvement
 */
@Pipe({
    name: 'typePrelevement',
})
export class TypePrelevementPipe implements PipeTransform {
    constructor() {}

    transform(typePrelevement: TypePrelevement): string {
        return typePrelevementToLabel(typePrelevement);
    }
}
