import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Commande } from '../model/commande.model';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { extractPage, PageRequest, toHttpParams } from '../shared/paging/page';

@Injectable({
    providedIn: 'root',
})
export class CommandeApiService {
    private http: HttpClient;

    public resourceUrl = environment.apiUrl + '/commandes';

    constructor(private httpBackend: HttpBackend) {
        this.http = new HttpClient(httpBackend);
    }

    findAllCommandes(): Observable<Commande[]> {
        return this.http.get<Commande[]>(`${this.resourceUrl}/find-all-commandes`);
    }

    findOneCommande(idCommande: string): Observable<Commande> {
        return this.http.get<Commande>(`${this.resourceUrl}/${idCommande}`);
    }

    findOneCommandeByNumberCommande(numCommande: string): Observable<Commande> {
        return this.http.get<Commande>(`${this.resourceUrl}/find-by-command-number/${numCommande}`);
    }

    upsertCommande(commande: Commande): Observable<Commande> {
        return this.http.post<Commande>(`${this.resourceUrl}/upsertCommande`, commande);
    }

    searchCommandes(search: string, pageRequest: PageRequest<Commande>) {
        return extractPage(
            this.http.get<Commande[]>(`${this.resourceUrl}/find-all-commandes-page`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), search },
            })
        );
    }

    findAllCommandesMinimales(): Observable<Commande[]> {
        return this.http.get<Commande[]>(`${this.resourceUrl}/find-all-commandes-minimales`);
    }

    findAllCommandesByNumber(search: string, pageRequest: PageRequest<Commande>) {
        return extractPage(
            this.http.get<Commande[]>(`${this.resourceUrl}/find-all-commandes-searched`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), search },
            })
        );
    }

    isNumeroCommandeAvailable(numCommande: string): Observable<boolean> {
        return this.http.get<boolean>(`${this.resourceUrl}/available/${numCommande}`);
    }
}
