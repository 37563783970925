import { Pipe, PipeTransform } from '@angular/core';
import { CategorieDocument } from '../model/categorie-document.model';

/**
 * Affiche le nom d'une catégorie de document via son id
 */
@Pipe({
    name: 'categorieDocument',
})
export class CategorieDocumentPipe implements PipeTransform {
    constructor() {}

    transform(idCategorieDocument: string, allCategories: CategorieDocument[] = []): string {
        const categorie = allCategories.find((categorieTemp) => categorieTemp.id === idCategorieDocument);
        return categorie.nom;
    }
}
