import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestionBiensComponent } from './gestion-biens.component';
import { CreationBienComponent } from './creation-bien/creation-bien.component';
import { TableauBienComponent } from './tableau-bien/tableau-bien.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LibIconModule } from 'src/app/commons-lib';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CnDiagSharedModule } from '../../shared/shared.module';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
    declarations: [GestionBiensComponent, CreationBienComponent, TableauBienComponent],
    imports: [
        CommonModule,
        MatToolbarModule,
        LibIconModule,
        MatButtonModule,
        MatIconModule,
        CnDiagSharedModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatTableModule,
        MatSortModule,
        MatMenuModule,
        MatPaginatorModule,
    ],
})
export class GestionBiensModule {}
