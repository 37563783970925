import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent, ConfirmationService, MongoUtils, NotificationService } from 'src/app/commons-lib';
import { Contact, RoleContact } from 'src/app/model/contact.model';
import { ContactService } from 'src/app/services/contact.service';
import { CnSpinnerService } from '../../cn-spinner/service/cn-spinner.service';
import { EditContactFormService } from '../edit-contact-form.service';
import { SelectExistingContactModalComponent } from '../select-existing-contact-modal/select-existing-contact-modal.component';
import { takeUntil } from 'rxjs/operators';

class EditContactModalData {
    constructor(
        public contact: Contact = new Contact(),
        public isReadOnly = false,
        public isProprietaire = false,
        public isDonneurOrdre = false,
        public isCreation = false,
        public isContactRef = false,
        public canDelete?: boolean
    ) {}
}

@Component({
    selector: 'app-edit-contact-modal',
    templateUrl: './edit-contact-modal.component.html',
    styleUrls: ['./edit-contact-modal.component.scss'],
})
export class EditContactModalComponent extends BaseComponent implements OnInit {
    contact: Contact;
    formContact: FormGroup;
    isProprietaire: boolean;
    isDonneurOrdre: boolean;

    isCreation: boolean;
    isContactRef: boolean;
    isReadOnly: boolean;

    loadingComplete = false;
    deleteContact = false;
    canDelete = false;

    readonly enumRoleContact = RoleContact;

    constructor(
        private readonly dialogRef: MatDialogRef<EditContactModalComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: EditContactModalData,
        private readonly formBuilder: FormBuilder,
        private readonly contactService: ContactService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly confirmationService: ConfirmationService,
        private readonly notificationService: NotificationService,
        private readonly editContactFormService: EditContactFormService,
        private readonly matDialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth700-dialog');
        if (this.data) {
            this.contact = this.data.contact;
            this.isReadOnly = this.data.isReadOnly;
            this.isProprietaire = this.data.isProprietaire;
            this.isDonneurOrdre = this.data.isDonneurOrdre;
            this.isContactRef = this.data.isContactRef;
        }

        this.formContact = this.editContactFormService.buildContactForm(this.contact, this.isProprietaire, this.isDonneurOrdre);
        const isCreation = this.data?.isCreation;
        this.isCreation = isCreation;
        this.canDelete = this.data?.canDelete === undefined ? true : !!this.data?.canDelete && !this.isReadOnly;
        this.editContactFormService.patchContactForm(this.contact, this.formContact, false);
        this.loadingComplete = true;
    }

    cancel() {
        this.dialogRef.close(false);
    }

    confirm() {
        this.editContactFormService.saveContactFromForm(this.formContact, this.contact.id, this.contact.typeRole, this.isCreation).subscribe(
            (contact) => {
                this.dialogRef.close({ contact, deleteContact: false });
            },
            (error) => {
                this.notificationService.error(error);
            }
        );
    }

    delete() {
        this.deleteContact = true;
    }

    confirmDeletion() {
        this.dialogRef.close({ deleteContact: true });
    }

    onSelectExistingContact(existingContact: Contact) {
        const message = `Vous venez de sélectionner un contact existant, voulez-vous référencer celui-ci ou dupliquer ces données pour la création d'un nouveau contact ?`;
        const confirmLabel = 'Référencer existant';
        this.matDialog
            .open(SelectExistingContactModalComponent, {
                data: {
                    existingContact,
                    message,
                    confirmLabel,
                },
            })
            .afterClosed()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((action: 'cancel' | 'confirm' | 'duplicate') => {
                const type = this.formContact.value.type;
                if (action === 'confirm') {
                    this.contact = { ...this.contact, ...existingContact };
                    this.isCreation = false;
                } else if (action == 'duplicate') {
                    this.contact = { ...this.contact, ...existingContact, id: MongoUtils.generateObjectId() };
                    this.isCreation = true;
                }
                if (['confirm', 'duplicate'].indexOf(action) > -1) {
                    this.editContactFormService.patchContactForm(this.contact, this.formContact, false);
                    if (type) {
                        const disabled = this.formContact.get('type').disabled;
                        this.formContact.get('type').enable();
                        this.formContact.get('type').patchValue(type);
                        if (disabled) {
                            this.formContact.get('type').disable();
                        }
                    }
                }
            });
    }
}
