import { Pipe, PipeTransform } from '@angular/core';
import { OuvrageAControler } from '../model/categorie-ouvrage.model';

@Pipe({
    name: 'ouvrageName',
})
export class OuvrageNamePipe implements PipeTransform {
    transform(idOuvrage: string, ouvragesAControler: OuvrageAControler[]): string {
        const ouvrage = ouvragesAControler.find((ouvrage) => ouvrage.id === idOuvrage);
        return ouvrage ? ouvrage.nom : '';
    }
}
