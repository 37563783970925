import { Component, OnInit } from '@angular/core';
import { BaseComponent, NotificationService } from 'src/app/commons-lib';
import { takeUntil } from 'rxjs/operators';
import { URL_REFERENTIELS } from '../../../../shared/constants/url.constants';
import { Router } from '@angular/router';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { TypePrestation } from '../../../../model/type-prestation.model';
import { MatSelectChange } from '@angular/material/select';
import { DIAGNOSTICS_TREE } from '../../../../../report-schema';
import { SchemaTemplateService, TemplateNode } from '../../../../services/schema-template.service';

/** Flat node with expandable and level information */
interface ExampleFlatNode {
    expandable: boolean;
    name: string;
    level: number;
}

@Component({
    selector: 'app-gestion-template-rapport',
    templateUrl: './gestion-template-rapport.component.html',
    styleUrls: ['./gestion-template-rapport.component.scss'],
})
export class GestionTemplateRapportComponent extends BaseComponent implements OnInit {
    selectedNode: TemplateNode = null;
    copyChampSimple = null;
    copyChampDepuisRacine = null;
    copyChampDepuisDernierTableau = null;
    copyBoucle = null;
    copyBoucleHtml = null;
    searchValue = null;
    listReportDataClass = DIAGNOSTICS_TREE.map((it) => it.reportData).concat('InterventionReportData');
    private originalJsonSchema = null;
    listTypePrestation: TypePrestation[] = [];
    treeControl = new CustomTreeControl<TemplateNode>(
        (node) => node.level,
        (node) => node.expandable
    );

    private _transformer = (node: TemplateNode, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            type: node.type,
            title: node.title,
            description: node.description,
            typesPrestation: node.typesPrestation,
            level: level,
            visible: node.visible,
        };
    };

    treeFlattener = new MatTreeFlattener(
        this._transformer,
        (node) => node.level,
        (node) => node.expandable,
        (node) => node.children
    );

    originalDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

    constructor(
        private readonly schemaTemplateService: SchemaTemplateService,
        private readonly router: Router,
        private readonly notificationService: NotificationService
    ) {
        super();
    }

    ngOnInit(): void {
        this.schemaTemplateService
            .getSchemaTemplate()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
                this.originalJsonSchema = res;
                this.originalDataSource.data = this.schemaTemplateService.getInterventionReportDataSchema(res);
                this.dataSource.data = JSON.parse(JSON.stringify(this.originalDataSource.data));

                const listePresta = [
                    ...new Set(
                        this.originalDataSource.data[0].children
                            .find((it) => it.name === 'diagnostics')
                            ?.children.map((it) => it.typesPrestation)
                            .filter((element) => element !== undefined)
                            .flat()
                    ),
                ];
                this.listTypePrestation = listePresta.sort((a, b) => a.localeCompare(b));
            });
    }

    back() {
        this.router.navigate([URL_REFERENTIELS]);
    }

    onNodeClicked(event: MouseEvent, node) {
        this.selectedNode = node;

        // Copier coller Champ simple
        this.copyChampSimple = `{${node.name}}`;

        // Copier coller Champ depuis la racine
        let pathChampDepuisRacine = [];
        this.treeControl.getParents(node, pathChampDepuisRacine);
        pathChampDepuisRacine = pathChampDepuisRacine.reverse();
        // Ajout du nom du nœud sélectionné
        pathChampDepuisRacine.push(node.name);
        this.copyChampDepuisRacine = `{${pathChampDepuisRacine.join('.')}}`;

        // Copier coller Champ depuis le dernier tableau
        let pathChampDepuisDernierTableau = [];
        this.treeControl.getParents(node, pathChampDepuisDernierTableau, true);

        // Si on est dans un tableau
        if (pathChampDepuisDernierTableau.find((it) => it.includes('[index]'))) {
            pathChampDepuisDernierTableau = pathChampDepuisDernierTableau.reverse();
            // Ajout du nom du nœud sélectionné
            pathChampDepuisDernierTableau.push(node.name);
            this.copyChampDepuisDernierTableau = `{${pathChampDepuisDernierTableau.join('.')}}`;
        } else {
            this.copyChampDepuisDernierTableau = null;
        }

        // Copier coller Copier la boucle
        if (node?.type?.includes('[]')) {
            this.copyBoucle = `{FOR ${node.name.substring(0, 3)} IN ${node.name}}\n{END-FOR ${node.name.substring(0, 3)}}`;
        } else {
            this.copyBoucle = null;
        }
        this.copyBoucleHtml = this.copyBoucle?.replace('\n', '<br/>');
    }

    onChangeFilterTypePrestation(event: MatSelectChange) {
        if (event.value === undefined) {
            this.dataSource.data[0].children.find((it) => it.name === 'diagnostics').children = this.originalDataSource.data[0].children.find(
                (it) => it.name === 'diagnostics'
            ).children;
        } else {
            this.dataSource.data[0].children.find((it) => it.name === 'diagnostics').children = this.originalDataSource.data[0].children
                .find((it) => it.name === 'diagnostics')
                .children.filter((diag) => diag.typesPrestation?.includes(event.value));
        }
        this.clearSearch();
        // rafraichi l'affichage de l'arbre
        this.dataSource.data = this.dataSource.data;
        this.treeControl.expandAll();
    }

    copyField() {
        this.notificationService.success('Champs copié');
    }

    clearSearch() {
        this.searchValue = null;
    }

    filterChanged(searchValue: string) {
        this.searchValue = searchValue;
        const filteredItems = this.treeControl.dataNodes.filter(
            (x) =>
                x.name?.toLowerCase()?.indexOf(this.searchValue.toLowerCase()) === -1 ||
                x.description?.toLowerCase()?.indexOf(this.searchValue.toLowerCase()) === -1
        );
        filteredItems.forEach((x) => {
            x.visible = false;
        });

        const visibleItems = this.treeControl.dataNodes.filter(
            (x) =>
                x.name?.toLowerCase()?.indexOf(this.searchValue.toLowerCase()) > -1 ||
                x.description?.toLowerCase()?.indexOf(this.searchValue.toLowerCase()) > -1
        );
        visibleItems.forEach((x) => {
            x.visible = true;
            this.treeControl.markParents(x);
        });
        this.treeControl.expandAll();
    }
}

export class CustomTreeControl<T> extends FlatTreeControl<T> {
    listReportDataClass = DIAGNOSTICS_TREE.map((it) => it.reportData).concat('InterventionReportData');

    /**
     *    Récupère tous les parents de manière récursive
     *    Si le parent est de type Array, on rajoute [index] dans le chemin généré
     *    Si isArray is true, on s'arrête directement
     *
     * @param node
     * @param path
     * @param isArray
     */
    getParents(node: T, path: string[], isArray = false): any {
        const parent = this.getParent(node);
        if (parent) {
            if ((parent as any)?.name && !this.listReportDataClass.includes((parent as any)?.name)) {
                path.push((parent as any)?.name);

                if ((parent as any)?.type.includes('[]')) {
                    path[path.length - 1] = path[path.length - 1] + '[index]';
                }

                if (isArray) {
                    return;
                }
            }
            this.getParents(parent, path, isArray);
        }
        return;
    }

    markParents(node: T) {
        const parent = this.getParent(node);
        if (parent) {
            (parent as any).visible = true;
            this.markParents(parent);
        }
        return;
    }

    /**
     * Récupère le noeud parent juste au dessus
     * @param node
     */
    getParent(node: T) {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
    }
}
