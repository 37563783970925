import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LibFormContainerModule } from '../form-container/form-container.module';
import { PasswordComponent } from './password/password.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [CommonModule, FormsModule, RouterModule, LibFormContainerModule, MatButtonModule, MatIconModule, MatFormFieldModule, MatInputModule],
    declarations: [ProfileComponent, PasswordComponent],
})
export class LibProfileModule {}
