<lib-form-container>
    <h1>Mon profil</h1>

    <mat-form-field class="w-100">
        <mat-label>Email</mat-label>
        <input
            matInput
            type="email"
            email
            required
            [disabled]="!editMailMode"
            [(ngModel)]="user.email"
            #email="ngModel"
        />
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>Prénom</mat-label>
        <input matInput type="text" required [(ngModel)]="user.firstName" #firstName="ngModel" />
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>Nom</mat-label>
        <input matInput type="text" required [(ngModel)]="user.lastName" #lastName="ngModel" />
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>Société</mat-label>
        <input matInput type="text" [(ngModel)]="user.company" />
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>Adresse</mat-label>
        <input matInput type="text" [(ngModel)]="user.address" />
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>Numéro de téléphone</mat-label>
        <input matInput type="text" [(ngModel)]="user.phone" />
    </mat-form-field>

    <div class="my-3">
        <button
            mat-raised-button
            color="accent"
            [disabled]="email.invalid || firstName.invalid || lastName.invalid"
            (click)="save()"
        >
            <mat-icon>check</mat-icon>
            <span>Mettre à jour</span>
        </button>
        <button mat-button [routerLink]="'./password'">
            <mat-icon>vpn_key</mat-icon>
            <span>Changer le mot de passe</span>
        </button>
        <button mat-button [hidden]="true" [disabled]="editMailMode" (click)="editMail()">
            <mat-icon>email</mat-icon>
            <span>Changer l'adresse mail</span>
        </button>
    </div>
</lib-form-container>
