<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <button mat-icon-button disabled></button>
        <ng-container *ngIf="node">
            <ng-container *ngTemplateOutlet="treeNodeLine; context: { $implicit: node }"></ng-container>
        </ng-container>
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Replier / déplier ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
                {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
        </button>
        <ng-container *ngIf="node">
            <ng-container *ngTemplateOutlet="treeNodeLine; context: { $implicit: node }"></ng-container>
        </ng-container>
    </mat-tree-node>

    <ng-template #treeNodeLine let-node>
        <span> {{ node.data.nom }}</span>
        <div class="flex-grow-1"></div>
        <div class="buttons justify-content-end d-flex align-items-center">
            <mat-slide-toggle
                class="pr-1"
                [checked]="node.data.etatCategorieOuvrage === 'ACTIF'"
                (click)="onChangeEtat(node.data, $event)"
                (keyup.space)="onChangeEtat(node.data, $event)"
                [matTooltip]="node.data.etatCategorieOuvrage === 'ACTIF' ? 'Désactiver' : 'Activer'"
            ></mat-slide-toggle>
            <button mat-icon-button (click)="onClickConsulter(node.data)">
                <mat-icon>visibility</mat-icon>
            </button>

            <button
                mat-icon-button
                color="accent"
                matTooltip="Modifier"
                (click)="editCategorieOuvrage(node.data)"
                [disabled]="node.data.etatCategorieOuvrage !== 'INACTIF'"
            >
                <lib-icon>edit</lib-icon>
            </button>
            <button
                mat-icon-button
                color="warn"
                matTooltip="Supprimer"
                [disabled]="node.data.etatCategorieOuvrage !== 'INACTIF'"
                (click)="deleteCategorieOuvrage(node.data)"
            >
                <lib-icon>delete</lib-icon>
            </button>
            <button mat-icon-button (click)="onClickDupliquerCategorieOuvrage(node.data)" matTooltip="Dupliquer">
                <mat-icon>content_copy</mat-icon>
            </button>
        </div>
    </ng-template>
</mat-tree>
