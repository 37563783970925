import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponent, ConfirmationService, FileUploaderComponent, MongoUtils, NotificationService } from 'src/app/commons-lib';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Observable } from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { InterventionFile, TypeReferenceFichier } from 'src/app/model/intervention-file.model';
import { FileApiService } from 'src/app/services/file-api.service';
import { FileService } from 'src/app/services/file.service';
import { InterventionFileApiService } from 'src/app/services/intervention-file-api.service';
import { InterventionFileService } from 'src/app/services/intervention-file.service';
import { blobToArrayBuffer, blobToDataUrl } from 'src/app/shared/offline/file.utils';
import { CnSpinnerService } from '../cn-spinner/service/cn-spinner.service';
import { data } from 'cypress/types/jquery';
import { ExifParserFactory } from 'src/app/shared/ts-exif-parser';
import { ExifData } from 'src/app/shared/ts-exif-parser/ExifData';
import { SelectTypePhotoModal } from './select-type-photo-modal/select-type-photo-modal';
import { MatDialog } from '@angular/material/dialog';
import { CaptureWebcamImages } from '../capture-webcam-images/capture-webcam-images';
import { LoadFileFromDocsModalComponent } from '../load-file-from-docs-modal/load-file-from-docs-modal.component';
import { InterventionService } from '../../../services/intervention.service';
import { Document } from '../../../model/document.model';
import { EditDocumentModalComponent } from '../documents-gestion/edit-document-modal/edit-document-modal.component';
import { TypeDocument } from '../../../model/type-document.model';
import { Intervention } from '../../../model/intervention.model';

export class FileUploaderTextConfigFile {
    mainText? = `Ajouter l'image`;
    deleteToolTip? = `Supprimer l'image`;
    changeToolTip? = `Changer la photo`;
    confirmDeleteDialog? = `Êtes-vous sûr de vouloir supprimer cette image ?`;
    fileDeleted? = `L'image a bien été supprimée`;
}

export class FileUploaderOutput {
    interventionFile: InterventionFile;
    fileName: string;
    dataUrl: string;
    constructor(interventionFile: InterventionFile, fileName: string, dataUrl: string) {
        this.interventionFile = interventionFile;
        this.fileName = fileName;
        this.dataUrl = dataUrl;
    }
}

@Component({
    selector: 'app-wizy-file-uploader',
    templateUrl: './wizy-file-uploader.component.html',
    styleUrls: ['./wizy-file-uploader.component.scss'],
})
export class WizyFileUploaderComponent extends BaseComponent implements OnChanges {
    // Défini si on n'autorise à télécharger que des images ou pas. Cet attribut va définir le composant que l'on va utiliser pour l'IHM
    @Input() isImage: boolean;

    /**
     * Permet de custommiser le contenu textuel du composant
     * @param textConfig
     */
    @Input() set textConfig(textConfig: FileUploaderTextConfigFile) {
        // On merge car les paramètres de personnalisation sont optionnels.
        const originalTextConfig = new FileUploaderTextConfigFile();
        this._textConfig = Object.assign(originalTextConfig, textConfig);
    }
    get textConfig(): FileUploaderTextConfigFile {
        return this._textConfig;
    }
    private _textConfig = new FileUploaderTextConfigFile();

    @Input() readonlyMode = false;
    @Input() interventionId: string;
    @Input() diagnosticId: string;
    @Input() referenceId: string;
    @Input() typeReferenceFichier: TypeReferenceFichier;
    @Input() fileId: string;
    @Input() autoUpload: boolean;
    @Input() allowLoadFromDocFiles = false;

    @Output() fileUploaded = new EventEmitter<FileUploaderOutput>();
    @Output() deleted = new EventEmitter();
    @Output() fileSelected = new EventEmitter();

    @ViewChild('fileUploader') fileUploader: FileUploaderComponent;

    interventionFile: InterventionFile;
    editionOverlay = false;
    @Input() dataUrl: string = null;
    exifData: any = null;
    fileName: string = null;
    useDownloadPipe = false;

    TypePhotoChoisie: boolean;

    constructor(
        private readonly fileApiService: FileApiService,
        private readonly interventionFileApiService: InterventionFileApiService,
        private readonly interventionFileService: InterventionFileService,
        private readonly interventionService: InterventionService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly confirmationService: ConfirmationService,
        private readonly notificationService: NotificationService,
        private readonly imageCompress: NgxImageCompressService,
        private readonly fileService: FileService,
        private readonly matDialog: MatDialog
    ) {
        super();
    }

    ngOnChanges(value) {
        // Création de l'interventionFile
        this.cnSpinnerService
            .withSpinner(this.initInterventionFile())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.updateUseDownloadPipe();
            });
    }

    /**
     * Initialise l'objet InterventionFile.
     * Récupère l'objet existant ou en crée un nouveau
     */
    private initInterventionFile(): Observable<InterventionFile> {
        return this.interventionFileService
            .findByIdInterventionIdDiagnosticIdReference(
                this.interventionId,
                this.diagnosticId,
                this.referenceId,
                this.typeReferenceFichier,
                this.fileId
            )
            .pipe(
                tap((interventionFile) => {
                    this.interventionFile = interventionFile;
                })
            );
    }

    /**
     * Règles permettant de déterminer si on doit utiliser le pipe permettant de télécharger l'image ou non.
     */
    private updateUseDownloadPipe(): void {
        this.useDownloadPipe = this.autoUpload || !!this.fileId;
    }

    /**
     * Prépare le fichier à uploader
     *  - Compression
     *  - Verification de la taille
     * @param fileData
     * @private
     */
    private async prepareFile(fileData: File): Promise<string> {
        let dataUrl = await blobToDataUrl(fileData);

        // Compression dans le cas d'une image
        if (this.isImage) {
            dataUrl = await this.imageCompress.compressFile(dataUrl, -1, 100, 60);
            dataUrl = this.fileService.verifySizeDocument(dataUrl);
        }
        return dataUrl;
    }

    private async recupereExif(fileData: File): Promise<ExifData> {
        let ArrayBuffer = await blobToArrayBuffer(fileData);

        let Data = ExifParserFactory.create(ArrayBuffer).parse();
        return Data;
    }

    /**
     * Evenement déclenché lorsqu'un fichier a été séléctionné dans le composant.
     * - Prépare le fichier a injecter directement dans le dom.
     *
     * Dans le cas d'un auto-upload :
     *  - Upload le fichier
     *  - Créer une occurence InterventionFile
     *
     * Renvoie un objet contenant les données du fichier et de l'intervention file à l'élément parent
     *  par le biais d'un event emitter
     * @param fichier => soit on fait passer un File (import) soit on fait passer un fichier avec un File, les données Gps et date (capture webcam)
     */
    async onStartUploadFile(fichier: any): Promise<void> {
        // Préparation du fichier (compression vérification etc ...)
        // Récupération du fichier au format FormData et convertion en base64.
        let fileData;
        if (fichier.fichier != null || fichier.fichier != undefined) {
            fileData = fichier.fichier as File;
        } else {
            fileData = fichier.get('file') as File;
        }
        this.fileName = fileData.name;
        this.dataUrl = await this.prepareFile(fileData);
        this.exifData = await this.recupereExif(fileData);

        // Si on est en mode autoUpload, on va requêter le serveur pour
        // sauvegarder le fichier et l'instance InterventionFile.
        if (this.autoUpload) {
            this.cnSpinnerService
                .withSpinner(
                    // Upload du fichier
                    this.fileApiService.uploadFile(this.dataUrl, this.fileName).pipe(
                        switchMap((fileDataResponse) => {
                            // On crée un objet temporaire afin de ne pas mettre à jour
                            // l'objet intervention File trop tôt et éviter de déclencher le pipe
                            // permettant de télécharger l'image.
                            // Sinon le download risque de se faire trop tôt, ce qui peut engendrer un problème de droit
                            const interventionFileTemp = Object.assign({}, this.interventionFile);
                            interventionFileTemp.fileId = fileDataResponse.fileId;

                            if (fichier.captureDate == null || fichier.captureDate.undefined) {
                                //SubSecTimeOriginal doit être egal a 3 caractères, si on a pas 3 caractères on rajoute un zéro devant
                                let dateSec = '000' + this.exifData?.tags?.SubSecTimeOriginal ?? '';
                                dateSec = dateSec.slice(-3);
                                interventionFileTemp.creationDate = +this.exifData?.tags?.DateTimeOriginal?.toString().concat(dateSec);
                            } else {
                                interventionFileTemp.creationDate = fichier.captureDate;
                            }

                            if (fichier.geo == null || fichier.geo == undefined) {
                                interventionFileTemp.gpsLatitudeRef = this.exifData?.tags?.GPSLatitudeRef ?? 'non défini';
                                interventionFileTemp.gpsLatitude = this.exifData?.tags?.GPSLatitude ?? 'non défini';
                                interventionFileTemp.gpsLongitudeRef = this.exifData?.tags?.GPSLongitudeRef ?? 'non défini';
                                interventionFileTemp.gpsLongitude = this.exifData?.tags?.GPSLongitude ?? 'non défini';
                                interventionFileTemp.gpsAltitudeRef = this.exifData?.tags?.GPSAltitudeRef ?? 'non défini';
                                interventionFileTemp.gpsAltitude = this.exifData?.tags?.GPSAltitude ?? 'non défini';
                            } else {
                                interventionFileTemp.gpsLatitudeRef = 'non défini';
                                interventionFileTemp.gpsLatitude = fichier.geo.latitude ?? 'non défini';
                                interventionFileTemp.gpsLongitudeRef = 'non défini';
                                interventionFileTemp.gpsLongitude = fichier.geo.longitude ?? 'non défini';
                                interventionFileTemp.gpsAltitudeRef = 'non défini';
                                interventionFileTemp.gpsAltitude = fichier.geo.altitude ?? 'non défini';
                            }

                            // Post de l'instance InterventionFile

                            return this.interventionFileApiService.upsert(interventionFileTemp);
                        })
                    )
                )
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((interventionFile) => {
                    // On assigne ici le fichier id pour ne pas déclencher le pipe de downlaod trop tôt
                    this.interventionFile.fileId = interventionFile.fileId;

                    if (fichier.captureDate == null || fichier.captureDate.undefined) {
                        //SubSecTimeOriginal doit être egal a 3 caractères, si on a pas 3 caractères on rajoute un zéro devant
                        let dateSec;
                        dateSec = '000' + this.exifData?.tags?.SubSecTimeOriginal ?? '';
                        dateSec = dateSec.slice(-3);
                        this.interventionFile.creationDate = +this.exifData?.tags?.DateTimeOriginal?.toString().concat(dateSec);
                    } else {
                        this.interventionFile.creationDate = fichier.captureDate;
                    }
                    if (fichier.geo == null || fichier.geo == undefined) {
                        this.interventionFile.gpsLatitudeRef = this.exifData?.tags?.GPSLatitudeRef ?? 'non défini';
                        this.interventionFile.gpsLatitude = this.exifData?.tags?.GPSLatitude ?? 'non défini';
                        this.interventionFile.gpsLongitudeRef = this.exifData?.tags?.GPSLongitudeRef ?? 'non défini';
                        this.interventionFile.gpsLongitude = this.exifData?.tags?.GPSLongitude ?? 'non défini';
                        this.interventionFile.gpsAltitudeRef = this.exifData?.tags?.GPSAltitudeRef ?? 'non défini';
                        this.interventionFile.gpsAltitude = this.exifData?.tags?.GPSAltitude ?? 'non défini';
                    } else {
                        this.interventionFile.gpsLatitudeRef = 'non défini';
                        this.interventionFile.gpsLatitude = fichier.geo.latitude ?? 'non défini';
                        this.interventionFile.gpsLongitudeRef = 'non défini';
                        this.interventionFile.gpsLongitude = fichier.geo.longitude ?? 'non défini';
                        this.interventionFile.gpsAltitudeRef = 'non défini';
                        this.interventionFile.gpsAltitude = fichier.geo.altitude ?? 'non défini';
                    }

                    // On prépare l'objet contenant les datas et on le renvoie dans l'eventEmitter
                    this.fileUploaded.emit(this.prepareFileUploaderOutput(this.interventionFile, this.fileName, this.dataUrl));
                });
        } else {
            // Si on n'est pas en mode autoUpload, on va simplement stocker le contenu sans faire de sauvegarder
            // On affiche donc les informations du fichier stockées en cache.
            this.interventionFile.fileId = MongoUtils.generateObjectId();

            if (fichier.captureDate == null || fichier.captureDate.undefined) {
                //SubSecTimeOriginal doit être egal a 3 caractères, si on a pas 3 caractères on rajoute un zéro devant
                let dateSec;
                dateSec = '000' + this.exifData?.tags?.SubSecTimeOriginal ?? '';
                dateSec = dateSec.slice(-3);
                this.interventionFile.creationDate = +this.exifData?.tags?.DateTimeOriginal?.toString().concat(dateSec);
            } else {
                this.interventionFile.creationDate = fichier.captureDate;
            }
            if (fichier.geo == null || fichier.geo == undefined) {
                this.interventionFile.gpsLatitudeRef = this.exifData?.tags?.GPSLatitudeRef ?? 'non défini';
                this.interventionFile.gpsLatitude = this.exifData?.tags?.GPSLatitude ?? 'non défini';
                this.interventionFile.gpsLongitudeRef = this.exifData?.tags?.GPSLongitudeRef ?? 'non défini';
                this.interventionFile.gpsLongitude = this.exifData?.tags?.GPSLongitude ?? 'non défini';
                this.interventionFile.gpsAltitudeRef = this.exifData?.tags?.GPSAltitudeRef ?? 'non défini';
                this.interventionFile.gpsAltitude = this.exifData?.tags?.GPSAltitude ?? 'non défini';
            } else {
                this.interventionFile.gpsLatitudeRef = 'non défini';
                this.interventionFile.gpsLatitude = fichier.geo.latitude ?? 'non défini';
                this.interventionFile.gpsLongitudeRef = 'non défini';
                this.interventionFile.gpsLongitude = fichier.geo.longitude ?? 'non défini';
                this.interventionFile.gpsAltitudeRef = 'non défini';
                this.interventionFile.gpsAltitude = fichier.geo.altitude ?? 'non défini';
            }

            // On prépare l'objet contenant les datas et on le renvoie dans l'eventEmitter
            this.fileUploaded.emit(this.prepareFileUploaderOutput(this.interventionFile, this.fileName, this.dataUrl));
        }

        console.log(this.interventionFile);
    }

    /**
     * Supprime le fichier
     * Supprime l'occurence InterventionFile associée à ce fichier
     * Crée une nouvelle instance InterventionFile
     */
    onClickDeleteFile(): void {
        this.confirmationService.confirmWarn(this.textConfig.confirmDeleteDialog, () => {
            if (this.autoUpload) {
                this.cnSpinnerService
                    .withSpinner(
                        this.interventionFileService.deleteInterventionFile(this.interventionFile, true).pipe(
                            switchMap(() => {
                                return this.initInterventionFile();
                            })
                        )
                    )
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe(() => {
                        this.notificationService.success(this.textConfig.fileDeleted);
                        this.deleted.emit();
                    });
            } else {
                // Préparation objet à renvoyer en output
                this.dataUrl = null;
                this.cnSpinnerService.withSpinner(this.initInterventionFile()).pipe(takeUntil(this.ngUnsubscribe)).subscribe();

                this.notificationService.success(this.textConfig.fileDeleted);
                this.deleted.emit();
            }
        });
    }

    /**
     * Action lors du click permettant d'ajouter un fichier
     */
    onClickAddFile() {
        const dialogRef = this.matDialog.open(SelectTypePhotoModal);

        dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined) {
                this.TypePhotoChoisie = result;

                if (this.TypePhotoChoisie) {
                    // L'utilisateur a choisi de prendre une photo
                    const dialogRef = this.matDialog.open(CaptureWebcamImages);
                    dialogRef.afterClosed().subscribe((capturedImage: any) => {
                        if (capturedImage) {
                            this.fileUploader.uploadFileData(capturedImage);
                        }
                    });
                } else {
                    // L'utilisateur a choisi d'importer une photo
                    this.fileUploader.selectFile();
                }
            }
        });
    }

    /**
     * Action lors du click pour modifier un fichier
     */
    onClickChangeFile(): void {
        const dialogRef = this.matDialog.open(SelectTypePhotoModal);

        dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined) {
                this.TypePhotoChoisie = result;

                if (this.TypePhotoChoisie) {
                    // L'utilisateur a choisi de prendre une photo
                    const dialogRef = this.matDialog.open(CaptureWebcamImages);
                    dialogRef.afterClosed().subscribe((capturedImage: any) => {
                        if (capturedImage) {
                            this.fileUploader.uploadFileData(capturedImage);
                        }
                    });
                } else {
                    // L'utilisateur a choisi d'importer une photo
                    this.fileUploader.selectFile();
                }
            }
        });
    }

    /**
     * Action lors du click pour annuler l'edition (la croix)
     */
    onClickCancel(): void {
        this.showEditionOverlay(false);
    }

    /**
     * Action lors du click pour afficher le panneau d'edition
     */
    onClickEdit(): void {
        this.showEditionOverlay(true);
    }

    /**
     * Renvoie un objet contenant l'image à uploader et l'objet InterventionFile
     * @param interventionFile
     * @param dataUrl
     * @private
     */
    private prepareFileUploaderOutput(interventionFile: InterventionFile, fileName: string, dataUrl: string): FileUploaderOutput {
        return new FileUploaderOutput(interventionFile, fileName, dataUrl);
    }

    /**
     * Affiche ou non l'overlay d'édition
     * @param value
     * @private
     */
    private showEditionOverlay(value: boolean): void {
        this.editionOverlay = value;
    }

    /**
     * Action lors du click sur le bouton pour charger un fichier depuis les documents
     * ouvre une modale qui propose les photos contenuent dans les documment de type PLAN_BIEN de l'intervention
     * @private
     */
    onClickOpenPlanDuBienModal(): void {
        this.matDialog
            .open(LoadFileFromDocsModalComponent, {
                maxWidth: '600px',
            })
            .afterClosed()
            .subscribe((result) => {
                if (result && result.idFile) {
                    this.fileSelected.emit(result.idFile);
                }
            });
    }

    /**
     * Action lors du click sur le bouton pour bookmark la photo
     * ouvre la modale d'édition de document pour enregistrer la photo comme document de type PHOTO_BIEN
     * si un existe sans fichier, on le met à jour, sinon on en crée un nouveau
     * @private
     */
    onClickBookmarkImage(): void {
        this.interventionService
            .getCurrentIntervention()
            .pipe(take(1))
            .subscribe((intervention) => {
                // on filtre les documents de type PHOTO_BIEN
                const document: Document[] = intervention?.documents.filter((it) => it.typeDocument?.code === 'PLAN_BIEN') ?? [];
                // on récupére le premier document de type PHOTO_BIEN qui n'a pas de fichier
                const documentWithoutFile = document.find((it) => !it.idFichier);

                if (documentWithoutFile) {
                    documentWithoutFile.idFichier = this.fileId;
                }

                this.matDialog
                    .open(EditDocumentModalComponent, {
                        data: {
                            document: documentWithoutFile,
                            idFile: this.fileId,
                            intervention: intervention,
                            idIntervention: this.interventionId,
                            documentsAlreadyPresent: intervention?.documents ?? [],
                            editFichier: true,
                        },
                    })
                    .afterClosed()
                    .pipe(
                        filter((result) => intervention && result?.document),
                        switchMap((result) => {
                            intervention.documents.push(result.document);
                            return this.interventionService.updateIntervention(intervention).pipe(take(1));
                        }),
                        tap(() => {
                            this.notificationService.success('La photo a été importée dans la liste des documents en tant que plan du bien');
                            this.interventionService.reloadCurrentIntervention();
                        })
                    )
                    .subscribe();
            });
    }

    rotateLeft(fichier: any): void {
        this.rotateImage('-90', fichier);
    }

    rotateRight(fichier: any): void {
        this.rotateImage('90', fichier);
    }

    private rotateImage(degrees: string, fichierBase64: string) {
        this.dataUrl = this.rotateBase64Image(fichierBase64, degrees);
        if (this.dataUrl) {
            let fichier: any = this.dataURItoFile(this.dataUrl, this.interventionFile.fileId + '.jpg');

            let fileExif: {
                fichier: File;
                geo: { latitude: any; longitude: any; altitude: any };
                captureDate: any;
            };
            fileExif = {
                fichier: fichier,
                geo: {
                    latitude: this.interventionFile.gpsLatitude,
                    longitude: this.interventionFile.gpsLongitude,
                    altitude: this.interventionFile.gpsAltitude,
                },
                captureDate: this.interventionFile.creationDate,
            };

            this.fileUploader.uploadFileData(fileExif);
        }
    }

    rotateBase64Image(base64data: string, givenDegrees: string): string {
        const image = new Image();
        image.src = base64data;

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        let newWidth = image.width;
        let newHeight = image.height;

        if (givenDegrees === '90' || givenDegrees === '-90') {
            newWidth = image.height;
            newHeight = image.width;
        }

        canvas.width = newWidth;
        canvas.height = newHeight;

        if (ctx) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate((parseInt(givenDegrees) * Math.PI) / 180);
            ctx.drawImage(image, -image.width / 2, -image.height / 2);
        }

        return canvas.toDataURL('image/jpeg');
    }

    dataURItoFile(dataURI: string, fileName: string): File {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        let file = new File([ab], fileName, { type: mimeString });
        return file;
    }
}
