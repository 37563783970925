export const HELPER = {
    selection: {
        simple: 'Cliquer pour sélectionner un élément du bâti',
        multi: 'Cliquer sur les éléments un par un pour les intégrer ou les exclure de la sélection',
        area: 'Glisser-déposer pour tracer un périmètre de sélection'
    },
    wall: {
        creation: {
            simple: 'Cliquer pour tracer des murs point à point. Fermer le contour ou valider le tracé en appuyant sur la coche verte',
            shape: 'Glisser déposer pour créer un rectangle fermé de 4 murs',
            split: 'Cliquer sur une paroi pour créer une découpe'
        }
    },
    opening: {
        creation: `Cliquer sur une paroi pour positionner l'ouvrant`
    },
    background_map: {
        empty_state: 'Importer un nouveau fond de plan ou utiliser ceux depuis les médias'
    },
    stairs: {
        creation: {
            simple: `Cliquer pour les définir les 2 points de l'escalier`,
            u: `Glisser-déposer pour définir le tracé de l'escalier`,
            multi: `Cliquer pour tracer les escaliers point à point, valider le tracé en appuyant sur la coche verte`,
            spiral: `Glisser-déposer pour définir le diamètre de l'escalier, cliquer à nouveau pour définir l'angle`
        }
    },
    object: {
        creation: `Sélectionner un type d'équipement dans le menu, et cliquer sur le plan pour le positionner`
    },
    pipe: {
        creation: `Cliquer pour tracer des canalisations point à point, valider le tracé en appuyant sur la coche verte`
    },
    beam: {
        creation: `Cliquer pour tracer des poutres point à point, valider le tracé en appuyant sur la coche verte`
    },
    column: {
        creation: `Cliquer pour positionner une colonne`
    },
    slab: {
        creation: `Cliquer sur une dalle appliquer le type`,
        opening: `Glisser-déposer pour tracer le périmètre de la découpe de dalle`
    },
    facing: {
        creation: `Sélectionner un espace ou une paroi pour y associer un revêtement`
    },
    balcony: {
        creation: {
            simple: 'Cliquer pour tracer des baclons point à point. Fermer le contour ou valider le tracé en appuyant sur la coche verte',
            shape: 'Glisser déposer pour créer un rectangle fermé de 4 murs',
            split: 'Cliquer sur une paroi pour créer une découpe'
        }
    },
    area_context: {
        carrez_area: `Cliquer sur un espace pour l'inclure ou l'exclure de la surface Carrez`,
        inhabitable_area: `Cliquer sur un espace pour l'inclure ou l'exclure de la surface habitable`,
        useful_area: `Cliquer sur un espace pour l'inclure dans la surfaces utiles brutes et nettes, cliquer une nouvelle fois pour l'exclure de la surface nette, cliquer une fois encore pour l'exclure de la surface brute `,
        trimming: `Glisser-déposer pour tracer le périmètre d'une zone d'exclusion de la surface`
    },
    layer: {
        empty_state: 'Créer un nouveau calque depuis le menu de gauche',
        select: 'Cliquer sur un calque pour le sélectionner',
        full_mode: `Cliquer sur un élément du bâti pour l'associer au calque`,
        section_mode: `Glisser-déposer pour tracer une section`,
        line_mode: 'Cliquer pour tracer des lignes point à point, valider le tracé en appuyant sur la coche verte'
    },
    zpso: {
        empty_state: 'Créer une nouvelle zpso depuis le menu de gauche',
        select: 'Cliquer sur une zpso pour la sélectionner',
        full_mode: `Cliquer sur un élément du bâti pour l'associer à la zpso`,
        section_mode: `Glisser-déposer pour tracer une section`,
        line_mode: 'Cliquer pour tracer des lignes point à point, valider le tracé en appuyant sur la coche verte'
    },
    marker: {
        creation: {
            simple: `Cliquer pour positionner une annotation, ou glisser-déposer pour former une zone d'annotation`,
            rectangular: `Glisser-déposer pour tracer une zone rectangulaire d'annotation`,
            chip: `Cliquer pour déposer une pastille`,
            label: `Cliquer pour déposer une étiquette de texte`,
            eraser: `Glisser-déposer pour tracer une surface de recouvrement`,
            liaison: `Cliquer pour définir les 2 points de la liaison`
        }
    },
    sampling: {
        creation: `Cliquer pour positionner un prélèvement`
    },
    zone: {
        empty_state: 'Créer une zone depuis le menu de gauche',
        select: 'Cliquer sur une zone pour la sélectionner',
        creation: `Cliquer sur un espace pour l'inclure ou l'exclure de la zone`
    },
    roof_line: {
        creation: `Cliquer pour définir les 2 points de la ligne`
    },
    roof_height: {
        creation: `Cliquer sur la valeur d'un point pour le modifier`
    },
    roof_slab: {
        creation: `Cliquer sur une toiture appliquer le type`,
    },
    roof_opening: {
        creation: `Cliquer sur une toiture pour positionner la fenêtre de toit`
    },
    roof_dormer: {
        creation: `Cliquer sur une toiture pour positionner le chien assis`,
        facing: `Cliquer sur la paroi d'un chien assis pour y associer un revêtement`
    },

}


