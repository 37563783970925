import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Intervention, RelationInterventionBien } from '../model/intervention.model';
import { Bien, Niveau } from '../model/bien.model';
import { PointDeControleBien } from '../model/point-de-controle.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BienApiService } from './bien-api.service';
import { CnSpinnerService } from '../modules/shared/cn-spinner/service/cn-spinner.service';
import { ConfirmationService } from 'src/app/commons-lib';
import { TypeReferenceFichier } from '../model/intervention-file.model';
import { InterventionFileService } from './intervention-file.service';

@Injectable({
    providedIn: 'root',
})
export class BienService {
    private currentRelationInterventionBien$ = new BehaviorSubject<RelationInterventionBien>(undefined);
    /**
     * Indique si une maquette est en cours d'édition.
     */
    private planModified$ = new BehaviorSubject(false);

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly bienApiService: BienApiService,
        private readonly interventionFileService: InterventionFileService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly confirmationService: ConfirmationService
    ) {}

    /**
     * Renvoie le bien courant.
     */
    getCurrentBien(): Observable<RelationInterventionBien> {
        return this.currentRelationInterventionBien$.asObservable().pipe(filter((it) => it !== undefined));
    }

    getCurrentBienValue(): RelationInterventionBien {
        return this.currentRelationInterventionBien$.getValue();
    }

    setCurrentBien(bien: RelationInterventionBien) {
        this.currentRelationInterventionBien$.next(bien);
    }

    /**
     * Indique si une maquette est en cours d'édition.
     */
    setPlanModified(mode: boolean) {
        this.planModified$.next(mode);
    }

    /**
     * Indique si une maquette est en cours d'édition.
     */
    getPlanModified(): Observable<boolean> {
        return this.planModified$.asObservable();
    }

    /**
     * Indique si une maquette est en cours d'édition.
     */
    getPlanModifiedValue(): boolean {
        return this.planModified$.getValue();
    }

    /**
     * Retourne une liste de biens à partir d'une liste de PointDeControleBiens filtrés
     */
    filterBienFromCheckPoint(filteredPointsDeControles: PointDeControleBien[]): Bien[] {
        const filteredBiens = [];

        filteredPointsDeControles.forEach((fpc) => {
            const currentBien = new Bien();
            currentBien.id = fpc.idBien;
            currentBien.nom = fpc.nomBien;
            fpc.pointsDeControleNiveaux.forEach((pcn) => {
                const niveau = new Niveau();
                niveau.id = pcn.idNiveau;
                niveau.nom = pcn.nom;
                niveau.storeyId = pcn.storeyId;
                currentBien.description.push(niveau);
            });
            filteredBiens.push(currentBien);
        });

        return filteredBiens;
    }

    /**
     * Formulaire d'informations
     */
    initBienForm(): FormGroup {
        return this.formBuilder.group({
            nom: ['', [Validators.required]],
            idTypeBien: ['Appartement', Validators.required],
            adresse: this.formBuilder.group({
                voie: ['', Validators.required],
                complementAdresse1: [''],
                complementAdresse2: [''],
                codePostal: ['', Validators.required],
                ville: ['', Validators.required],
            }),
            refBien: [''],
            batiment: [''],
            nombreEntrees: ['', Validators.required],
            escalierNumPorte: [''],
            sousType: ['', Validators.required],
            anneeConstruction: ['', Validators.required],
            moisConstruction: ['Avant'],
            situation: ['', Validators.required],
            nbPiecesPrincipales: ['', Validators.required],
            nbNiveauxPositifs: ['', Validators.required],
            nbNiveauxNegatifs: ['', Validators.required],
            nbCagesEscalier: ['', Validators.required],
            numeroLot: [''],
            numeroParcelle: [''],
            coordonnees: this.formBuilder.group({
                first: [''],
                second: [''],
            }),
            typologieSurface: [''],
            surface: [''],
            contactProprietaire: [''],
            estOccupe: String,
            estMeuble: String,
            referenceCadastre: [''],
        });
    }

    /**
     * Mise à jour du formulaire de bien à partir du bien
     */
    updateBienFrom(formBien: FormGroup, bien: Bien) {
        formBien.patchValue({
            nom: bien.nom,
            idTypeBien: bien.idTypeBien,
            adresse: bien.adresse,
            refBien: bien.refBien,
            batiment: bien.batiment,
            nombreEntrees: bien.nombreEntrees,
            escalierNumPorte: bien.escalierNumPorte,
            sousType: bien.sousType,
            anneeConstruction: bien.anneeConstruction,
            moisConstruction: bien.moisConstruction,
            situation: bien.situation,
            nbPiecesPrincipales: bien.nbPiecesPrincipales,
            nbNiveauxPositifs: bien.nbNiveauxPositifs,
            nbNiveauxNegatifs: bien.nbNiveauxNegatifs,
            nbCagesEscalier: bien.nbCagesEscalier,
            numeroLot: bien.numeroLot,
            numeroParcelle: bien.numeroParcelle,
            referenceCadastre: bien.referenceCadastre,
            coordonnees: bien.coordonnees,
            surface: bien.surface,
            contactProprietaire: bien.contactProprietaire,
            typologieSurface: bien.typologieSurface,
        });
    }
    /**
     * Mise à jour du bien à partir du formulaire du bien
     */
    updateBienWithForm(bien: Bien, formBien: FormGroup) {
        bien.nom = formBien.value.nom;
        bien.idTypeBien = formBien.value.idTypeBien;
        bien.adresse = formBien.value.adresse;
        bien.refBien = formBien.value.refBien;
        bien.batiment = formBien.value.batiment;
        bien.nombreEntrees = formBien.value.nombreEntrees;
        bien.escalierNumPorte = formBien.value.escalierNumPorte;
        bien.sousType = formBien.value.sousType;
        bien.anneeConstruction = formBien.value.anneeConstruction;
        bien.moisConstruction = formBien.value.moisConstruction;
        bien.situation = formBien.value.situation;
        bien.nbPiecesPrincipales = formBien.value.nbPiecesPrincipales;
        bien.nbNiveauxPositifs = formBien.value.nbNiveauxPositifs;
        bien.nbNiveauxNegatifs = formBien.value.nbNiveauxNegatifs;
        bien.nbCagesEscalier = formBien.value.nbCagesEscalier;
        bien.surface = formBien.value.surface;
        bien.numeroLot = formBien.value.numeroLot;
        bien.numeroParcelle = formBien.value.numeroParcelle;
        bien.coordonnees = formBien.value.coordonnees;
        bien.typologieSurface = formBien.value.typologieSurface;
    }

    // findAllBiens(): Observable<Bien[]> {
    //     return this.bienApiService.findAllBiens();
    // }

    findOneBien(idBien: string): Observable<Bien> {
        return this.bienApiService.findOneBien(idBien);
    }

    createBien(bien: Bien): Observable<Bien> {
        return this.bienApiService.createBien(bien);
    }

    updateBien(bien: Bien): Observable<Bien> {
        return this.bienApiService.updateBien(bien);
    }

    private deleteComments(ids: string[], intervention: Intervention) {
        ids.forEach((id) => {
            const com = intervention.commentaires.find((c) => c.id === id);
            if (com && com.imageId) {
                // Suppression de l'image
                this.cnSpinnerService
                    .withSpinner(
                        this.interventionFileService.deleteInterventionFileAndFile(
                            intervention.id,
                            undefined,
                            com.id,
                            TypeReferenceFichier.PHOTO_COMMENTAIRE,
                            com.imageId
                        )
                    )
                    .subscribe();
            }
            intervention.commentaires.splice(intervention.commentaires.indexOf(com), 1);
        });
    }

    confirmSynchroniserBien(bien: Bien, callback?: any) {
        this.confirmationService.confirmWarn(
            {
                message: 'Il existe une version plus récente de ce bien, voulez-vous la récupérer?',
                titleLabel: 'Synchroniser le bien',
                confirmLabel: 'Oui',
                cancelLabel: `Non, garder en l'état`,
            },

            () => {
                this.cnSpinnerService
                    .withSpinner(this.bienApiService.synchroniserBien(bien.id), 'Synchronisation en cours ...')
                    .subscribe((resultBien) => {
                        callback(resultBien);
                    });
            },
            () => {
                callback(bien);
            }
        );
    }

    findAllBiensMinimals() {
        return this.bienApiService.findAllBiensMinimals();
    }
}
