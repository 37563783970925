<div class="compass" [class.error]="!valid">
    <div [class.selected]="orientation == 'NO'"></div>
    <div [class.selected]="orientation == 'N'"></div>
    <div [class.selected]="orientation == 'NE'"></div>
    <div [class.selected]="orientation == 'E'"></div>
    <div [class.selected]="orientation == 'C'"></div>
    <div [class.selected]="orientation == 'O'"></div>
    <div [class.selected]="orientation == 'SO'"></div>
    <div [class.selected]="orientation == 'S'"></div>
    <div [class.selected]="orientation == 'SE'"></div>
</div>
<span *ngIf="precisionOrientation && precisionOrientation != ''" class="mat-badge-content mat-badge-active"></span>
