// @ts-check
'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** cn_background_map : a background map to be displayed behind a scene
//***********************************************************************************
//***********************************************************************************

import { cn_element } from './cn_element';
import { cn_add, cn_box, cn_clone, cn_dist, cn_dot, cn_mul, cn_normal, cn_sub } from '../utils/cn_utilities';
import { to_image, to_world } from '../utils/cn_image_utils';

export var CN_GOOGLE_MAPS_API_KEY = "";
var CN_GOOGLE_MAPS_SESSION_AUTHENT = null;

export class cn_background_map extends cn_element {
    constructor(parent) {
        super(parent);
        this.image_id = '';
        this.image_size = [0, 0];
        this.scale = 0; //**** world size of a pixel
        this.offset = [0, 0];
        this.orientation = 0;
        this.reference_points = [[0.6, 0.1], [0.8, 0.1]];
        this.background_opacity = 0.5;
        this.order = 1;
        this.fixed_map = false;
        this.longitude = 0;
        this.latitude = 0;
        this.map_type = "satellite";

        this.old_offset = [0, 0];
        this.old_image_size = [0, 0];
    }


    //***********************************************************************************
    //**** serialize
    //***********************************************************************************
    serialize() {
        const json = {};
        json.ID = this.ID;
        if (!this.fixed_map) json.image_id = this.image_id;
        json.image_size = [this.image_size[0], this.image_size[1]];
        json.scale = this.scale;
        json.offset = [this.offset[0], this.offset[1]];
        json.orientation = this.orientation;
        json.reference_points = [cn_clone(this.reference_points[0]), cn_clone(this.reference_points[1])];
        json.background_opacity = this.background_opacity;
        json.order = this.order;
        json.fixed_map = this.fixed_map;
        json.longitude = this.longitude;
        json.latitude = this.latitude;
        return json;
    }

    static unserialize(json, building) {
        if (typeof (json) != 'object') return false;
        if (typeof (json.image_size) != 'object') return false;

        const background_map = new cn_background_map(building);
        if (typeof (json.ID) == 'string')
            background_map.ID = json.ID;
        if (typeof (json.image_id) == 'string')
            background_map.image_id = json.image_id;
        background_map.image_size[0] = json.image_size[0];
        background_map.image_size[1] = json.image_size[1];
        if (typeof (json.scale) == 'number')
            background_map.scale = json.scale;
        if (typeof (json.order) == 'number')
            background_map.order = json.order;
        if (typeof (json.background_opacity) == 'number' && json.background_opacity >= 0 && json.background_opacity <= 1)
            background_map.background_opacity = json.background_opacity;
        if (typeof (json.offset) == 'object') {
            if (typeof (json.offset[0]) == 'number')
                background_map.offset[0] = json.offset[0];
            if (typeof (json.offset[1]) == 'number')
                background_map.offset[1] = json.offset[1];
        }
        if (typeof (json.orientation) == 'number')
            background_map.orientation = json.orientation;

        if (typeof (json.reference_points) == 'object') {
            for (var k = 0; k < 2; k++) {
                if (typeof (json.reference_points[k]) != 'object') continue;
                if (typeof (json.reference_points[k][0]) == 'number')
                    background_map.reference_points[k][0] = json.reference_points[k][0];
                if (typeof (json.reference_points[k][1]) == 'number')
                    background_map.reference_points[k][1] = json.reference_points[k][1];
            }
        }

        if (typeof (json.fixed_map) == 'boolean')
            background_map.fixed_map = json.fixed_map;

        if (typeof (json.longitude) == 'number')
            background_map.longitude = json.longitude;

        if (typeof (json.latitude) == 'number')
            background_map.latitude = json.latitude;

        if (background_map.fixed_map) {
            building.unserialize_callback_count++;
            background_map.update_image_id(() => {
                if (building.unserialize_callback) {
                    building.unserialize_callback_count--;
                    building.unserialize_callback(building.unserialize_callback_count);
                }
            });
        }

        return background_map;
    }

    //***********************************************************************************
    //**** svg display
    //***********************************************************************************
    draw(camera, add_classes = [], opacity = 1, delegate_url = '') {
        var html = '';
        if (this.image_id == '') return html;
        var url = delegate_url;
        if (this.fixed_map) {
            url = this.image_id;
            if (url == '') return html;
        }
        if (url == '') {
            if (typeof (cn_background_map['image_id_to_url']) != 'function') return html;
            url = cn_background_map['image_id_to_url'](this.image_id);
        }
        if (url == '') return html;

        const offset = (this.fixed_map) ? this.old_offset : this.offset;
        const image_size = (this.fixed_map) ? this.old_image_size : this.image_size;
        var p0 = camera.world_to_screen(offset);
        var w = image_size[0] * this.scale * camera.world_to_screen_scale;
        var h = image_size[1] * this.scale * camera.world_to_screen_scale;

        if (this.fixed_map) this.orientation = -this.parent.compass_orientation;
        html += `<g transform="translate(${p0[0]},${p0[1]}) rotate(${-this.orientation}) translate(${-0.5 * w},${-0.5 * h})">`;

        html += '<image xlink:href=\'' + url + '\' ';
        html += 'x=\'0\' ';
        html += 'y=\'0\' ';
        html += 'width=\'' + w + '\' ';
        html += 'height=\'' + h + '\' ';
        if (opacity != 1)
            html += 'opacity=\'' + opacity + '\' ';
        html += ' />';

        html += '</g>';

        return html;
    }

    //***********************************************************************************
    //**** simple draw display
    //***********************************************************************************
    simple_draw(camera, add_classes = []) {
        return this.draw(camera, add_classes);
    }

    to_world(p) {
        return to_world(p, this.orientation, this.offset, this.image_size, this.scale);
    }

    get_world_size() {
        return [this.image_size[0] * this.scale, this.image_size[1] * this.scale];
    }

    /** Returns the background image url */
    get_image_url() {
        if (this.fixed_map) return this.image_id;
        return cn_background_map.image_id_to_url(this.image_id);
    }

    /**
     * This method is supposed to provide an URL from an image_id.
     * TODO : redefine this method in the host application.
     * @param {string} image_id
     * @return string
     */
    static image_id_to_url(image_id) {
        return undefined;
    }

    /**
     * Sets the API key for google map (tiles)
     * @param {string} x
     */
    static set_google_maps_api_key(x) {
        CN_GOOGLE_MAPS_API_KEY = x;
    }

    /**
     * Returns true if google maps is correct
     */
    static has_google_map_api() {
        return (CN_GOOGLE_MAPS_API_KEY != "");
    }

    static _update_map_type(map_type, done_function) {
        if (CN_GOOGLE_MAPS_API_KEY == "" || (CN_GOOGLE_MAPS_SESSION_AUTHENT && CN_GOOGLE_MAPS_SESSION_AUTHENT.map_type == map_type)) {
            if (done_function) done_function();
        }
        else {
            const post_data = { language: "fr", region: "FR" };
            post_data.map_type = (map_type == "hybrid") ? "satellite" : map_type;
            if (map_type == "hybrid" || map_type == "terrain") post_data.layerTypes = "layerRoadmap";

            fetch("https://tile.googleapis.com/v1/createSession?key=" + CN_GOOGLE_MAPS_API_KEY, {
                method: "POST",
                body: JSON.stringify(post_data)
            })
                .then((response) => response.json())
                .then((json) => {
                    CN_GOOGLE_MAPS_SESSION_AUTHENT = json;
                    CN_GOOGLE_MAPS_SESSION_AUTHENT.map_type = map_type;
                    console.log("Token authent google ", CN_GOOGLE_MAPS_SESSION_AUTHENT);
                    if (done_function) done_function();
                });
        }
    }

    static retrieve_map_tile(xyz, map_type, on_load, on_error) {

        function openstreet_map_retrieve() {
            const image_object = new Image();
            image_object.src = `https://a.tile.openstreetmap.fr/osmfr/${xyz[2]}/${xyz[0]}/${xyz[1]}.png`;
            image_object.setAttribute('crossorigin', 'anonymous');
            image_object.onload = () => { if (on_load) on_load(image_object); };
            image_object.onerror = on_error;
        };

        if (CN_GOOGLE_MAPS_API_KEY != "") {
            cn_background_map._update_map_type(map_type, () => {
                if (CN_GOOGLE_MAPS_SESSION_AUTHENT) {
                    const url = `https://tile.googleapis.com/v1/2dtiles/${xyz[2]}/${xyz[0]}/${xyz[1]}?session=${CN_GOOGLE_MAPS_SESSION_AUTHENT.session}&key=${CN_GOOGLE_MAPS_API_KEY}`;
                    const image_object = new Image();
                    image_object.src = url;
                    image_object.setAttribute('crossorigin', 'anonymous');
                    image_object.onload = () => { if (on_load) on_load(image_object); };
                    image_object.onerror = on_error;
                }
                else openstreet_map_retrieve();
            });
        }
        else
            openstreet_map_retrieve();
    }

    /**
     * method to retrieve an image from various elements
     * @param {function} on_done
     * @param {function} on_progress
     * @returns
     */
    retrieve_map_image(on_done, on_progress = null) {
        const latitude = this.latitude;
        const longitude = this.longitude;
        const width = this.image_size[0];
        const height = this.image_size[1];
        const map_type = this.map_type;
        const background_map = this;
        const TILE_SIZE = 256;

        var zoom = 22;
        var tiles = [];
        var tile_index = 0;

        function _from_coord_to_tile(lat, lng, z) {
            const mercator = -Math.log(Math.tan((0.25 + lat / 360) * Math.PI));
            const scale = Math.pow(2, z);
            return [scale * (lng / 360 + 0.5), scale / 2 * (1 + mercator / Math.PI)];
        }

        var y_tiles = 0;
        var x_tiles = 0;
        var image_origin = [0, 0,];
        var image_size = [0, 0];

        const canvas_id = 'canvas_google_maps_a9de8f7az65d7';
        var canvas = document.getElementById(canvas_id);
        if (!canvas) {
            canvas = document.createElement("canvas");
            canvas.setAttribute("id", canvas_id);
        }
        // @ts-ignore
        //$("#canvas").show();
        // @ts-ignore
        const ctx = canvas.getContext('2d');

        function _compute_next_zoom() {
            const resolution = 156543.03 * Math.cos(latitude * Math.PI / 180) / Math.pow(2, zoom);
            const tile_length = TILE_SIZE * resolution;
            if ((width + height) / tile_length > 15 && zoom > 10) {
                zoom--;
                _compute_next_zoom();
                return;
            }
            const xyz = _from_coord_to_tile(latitude, longitude, zoom);
            tiles = [];
            y_tiles = 0;
            for (var y = Math.floor(xyz[1] - height / (2 * tile_length)); y <= 1 + Math.floor(xyz[1] + height / (2 * tile_length)); y++) {
                x_tiles = 0;
                for (var x = Math.floor(xyz[0] - width / (2 * tile_length)); x <= 1 + Math.floor(xyz[0] + width / (2 * tile_length)); x++) {
                    tiles.push({ xyz: [x, y, zoom], canvas: [x_tiles, y_tiles] });
                    x_tiles++;
                }
                y_tiles++;
            }

            var x0 = xyz[0] - width / (2 * tile_length);
            x0 -= Math.floor(x0);
            x0 = Math.floor(TILE_SIZE * x0);
            var y0 = xyz[1] - height / (2 * tile_length);
            y0 -= Math.floor(y0);
            y0 = Math.floor(TILE_SIZE * y0);
            image_origin = [x0, y0];
            image_size = [Math.floor(width / resolution), Math.floor(height / resolution)]

            ctx.canvas.width = image_size[0];
            ctx.canvas.height = image_size[1];
            console.log("building canvas size", image_size, zoom);
            tile_index = 0;
            _compute_next_tile();
        }

        function _compute_next_tile() {
            if (tile_index < tiles.length) {
                const tile = tiles[tile_index];
                const xyz = tile.xyz;
                cn_background_map.retrieve_map_tile(xyz, map_type, (image_object) => {
                    var posd = [0, 1].map(i => -image_origin[i] + tile.canvas[i] * TILE_SIZE);
                    var sized = [TILE_SIZE, TILE_SIZE];
                    var poss = [0, 0];
                    var sizes = [TILE_SIZE, TILE_SIZE];
                    for (var i = 0; i < 2; i++) {
                        if (posd[i] < 0) {
                            const delta = -posd[i];
                            poss[i] += delta;
                            posd[i] += delta;
                            sized[i] -= delta;
                            sizes[i] -= delta;
                        }
                        if (posd[i] + sized[i] > image_size[i]) {
                            const delta = posd[i] + sized[i] - image_size[i];
                            sized[i] -= delta;
                            sizes[i] -= delta;
                        }
                    }
                    ctx.drawImage(image_object, poss[0], poss[1], sizes[0], sizes[1], posd[0], posd[1], sized[0], sized[1]);
                    tile_index++;
                    if (on_progress) on_progress(tile_index / tiles.length);
                    _compute_next_tile();
                }
                    , () => {
                        if (zoom >= 5) {
                            zoom--;
                            _compute_next_zoom();
                        }
                    });
            }
            else {
                // @ts-ignore
                const url = canvas.toDataURL("image/jpeg");
                background_map.image_id = url;
                if (on_done) on_done();
            }
        }

        _compute_next_zoom();
    }

    to_image(p) {
        return to_image(p, this.orientation, this.offset, this.image_size, this.scale);
    }

    /**
     * Asynchronous update of image id.
     * @param {function} done_function
     */
    update_image_id(done_function = null, progress_function = null) {
        if (!this.fixed_map) {
            if (done_function) done_function();
            return;
        };

        this.retrieve_map_image(() => {
            this.old_image_size = cn_clone(this.image_size);
            this.old_offset = cn_clone(this.offset);
            if (done_function) done_function();
        }, progress_function);
    }

    contains(p) {
        var pop = this.to_image(p);
        if (pop[0] < 0 || pop[0] > 1) return false;
        if (pop[1] < 0 || pop[1] > 1) return false;
        return true;
    }

    get_bounding_box() {
        let box = new cn_box();
        let limits = [[0, 0], [0, 1], [1, 0], [1, 1]];
        for (let l in limits) {
            box.enlarge_point(this.to_world(limits[l]));
        }
        return box;
    }

    update_coordinates(old_world_center) {
        const old_center = this.to_image(old_world_center);
        old_center[0] *= this.image_size[0];
        old_center[1] *= this.image_size[1];
        const new_center = [0.5, 0.5];
        new_center[0] *= this.image_size[0];
        new_center[1] *= this.image_size[1];
        if (cn_dist(new_center, old_center) < 0.01) return;
        const equator = 40075016.686;
        this.longitude += (new_center[0] - old_center[0]) * 360 / (Math.cos(this.latitude * Math.PI / 180) * equator);
        this.latitude += (new_center[1] - old_center[1]) * 360 / equator;
    }
}


