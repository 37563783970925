<div [formGroup]="group" class="formgroup-container">
    <div [formGroup]="group.get('conformite')" *ngIf="equipmentsConformiteConf?.displayed">
        <h4 class="my-2">Conformité</h4>
        <div>
            <div *ngIf="equipmentsConformiteConf?.controls?.showConformeObjects?.displayed">
                <mat-checkbox formControlName="showConformeObjects">Conformes</mat-checkbox>
            </div>
            <div *ngIf="equipmentsConformiteConf?.controls?.showNonConformeObjects?.displayed">
                <mat-checkbox formControlName="showNonConformeObjects">Non conformes</mat-checkbox>
            </div>
            <div *ngIf="equipmentsConformiteConf?.controls?.showAJustifierObjects?.displayed">
                <mat-checkbox formControlName="showAJustifierObjects">A justifer</mat-checkbox>
            </div>
            <div *ngIf="equipmentsConformiteConf?.controls?.showNonVerifieObjects?.displayed">
                <mat-checkbox formControlName="showNonVerifieObjects">Non vérifiés</mat-checkbox>
            </div>
        </div>
    </div>
</div>
