import { Pipe, PipeTransform } from '@angular/core';
import { TemplateADO, TypeReport } from '../model/reference-prestation.model';

/**
 * Recuppére le bon TemplateADO en fonction du type de report
 */
@Pipe({
    name: 'prestation_rapport',
})
export class PrestationRapportPipe implements PipeTransform {
    constructor() {}

    transform(listeTemplateReport: TemplateADO[], typeReport: TypeReport): TemplateADO {
        if (listeTemplateReport) {
            return listeTemplateReport.find((templateADO) => templateADO.typeReport === typeReport);
        }
        return null;
    }
}
