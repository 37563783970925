import { Diagnostic } from '../model/diagnostic.model';
import { EtapeDiagnosticGenerique } from '../model/diagnostic-etape.model';
import { TypePrelevement } from '../model/prelevement-generique.model';

export class FormUtils {
    static formatPrelevementReference(diagnostic: Diagnostic, typePrelevement: TypePrelevement = TypePrelevement.CAROTTAGE): string {
        const prelevementReference = typePrelevement === TypePrelevement.BURINAGE_MANUEL ? 'P' : 'C';
        let counter = parseInt((diagnostic.contenuDiagnostic['counterReference'] as EtapeDiagnosticGenerique)?.valeur, 10);
        if (Number.isNaN(counter)) {
            counter = 1;
        } else {
            counter += 1;
        }
        const counterAsString = counter.toString();
        (diagnostic.contenuDiagnostic['counterReference'] as EtapeDiagnosticGenerique).valeur = counterAsString;
        return `${prelevementReference}${counterAsString.padStart(5, '0')}`;
    }
}
