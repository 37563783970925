<div>
    <div class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">
        {{ 'Mesures' | uppercase }}
    </div>

    <div class="d-flex flex-row">
        <div class="block-subtitle ml-4 mb-5 flex-grow-1">Détail des surfaces par lot</div>
    </div>
    <div class="block-content px-5 ml-2 mb-5">
        <table>
            <tr>
                <th>Bien</th>
                <th class="lot">N° de lot</th>
                <ng-container *ngFor="let col of displayedColumns">
                    <th>Total {{ col }}</th>
                </ng-container>
            </tr>

            <tr *ngIf="mesures.biens.length === 0">
                <td [attr.colspan]="4 + displayedColumns.length" class="text-center p-4">Aucune mesure effectuée</td>
            </tr>
            <ng-container *ngFor="let bien of mesures.biens">
                <tr>
                    <td class="bien">
                        {{ bien.bien }}
                    </td>

                    <td>
                        {{ bien.lot }}
                    </td>
                    <ng-container *ngFor="let mes of displayedMesures">
                        <td
                            [innerHTML]="
                                bien[mes] ? (bien[mes] | number: '1.2-2' | mesure | safeHtml) : MESURE_NON_COMMUNIQUE
                            "
                        ></td>
                    </ng-container>
                </tr>
            </ng-container>
        </table>
    </div>

    <div class="d-flex flex-row">
        <div class="block-subtitle ml-4 mb-2 flex-grow-1">Détail des surfaces</div>
    </div>
    <div class="block-content px-5 ml-2 mb-5">
        <ng-container *ngFor="let bien of mesures.biens">
            <div class="block-subtitle-2 mt-4 mb-2">{{ bien.bien }}</div>
            <table>
                <tr>
                    <th class="niveau">Niveau / Volume</th>
                    <th class="lot">N° de lot</th>
                    <ng-container *ngFor="let col of displayedColumns">
                        <th>
                            {{ col }}
                        </th>
                    </ng-container>
                    <th>Commentaires</th>
                    <th class="edit-comment"></th>
                </tr>

                <tr *ngIf="bien.niveaux.length === 0">
                    <td [attr.colspan]="4 + displayedColumns.length" class="text-center p-4">
                        Aucune mesure effectuée
                    </td>
                </tr>

                <ng-container *ngFor="let lv of bien.niveaux; let isFirst = first; let isLast = last">
                    <tr>
                        <td
                            [attr.colspan]="4 + displayedColumns.length"
                            class="row-niveau"
                            [ngClass]="!isFirst ? 'pt-4' : ''"
                        >
                            {{ lv.niveau }}
                        </td>
                    </tr>

                    <ng-container *ngFor="let vol of lv.volumes">
                        <tr>
                            <td class="pl-4">
                                {{ vol.volume }}
                            </td>
                            <td>
                                {{ vol.lot }}
                            </td>
                            <ng-container *ngFor="let mes of displayedMesures">
                                <td
                                    [innerHTML]="
                                        vol[mes]
                                            ? (vol[mes] | number: '1.2-2' | mesure | safeHtml)
                                            : MESURE_NON_COMMUNIQUE
                                    "
                                ></td>
                            </ng-container>
                            <td>
                                <ng-container *ngFor="let com of vol.commentaires">
                                    <div [ngClass]="exclusionsMesures.includes(com.id) ? 'hidden-com' : ''">
                                        {{ com.contenu }} <br />
                                    </div>
                                </ng-container>
                            </td>
                            <td>
                                <button
                                    mat-icon-button
                                    class="small-button button-group primary"
                                    *ngIf="!readonlyMode && vol.commentaires.length"
                                    (click)="editDisplayedComments(vol.commentaires, mesures.code)"
                                >
                                    <mat-icon class="icon-small">edit</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                    <tr class="row-niveau row-total-niveau" [ngClass]="isLast ? '' : 'border-total'">
                        <td colspan="2">Total {{ lv.niveau }}</td>
                        <ng-container *ngFor="let mes of displayedMesures">
                            <td
                                [innerHTML]="
                                    lv[mes] ? (lv[mes] | number: '1.2-2' | mesure | safeHtml) : MESURE_NON_COMMUNIQUE
                                "
                            ></td>
                        </ng-container>
                        <td colspan="2"></td>
                    </tr>
                </ng-container>
            </table>
        </ng-container>
    </div>
</div>
