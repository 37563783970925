import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/commons-lib';

class InputTextareaModalData {
    constructor(
        public existingValue: string,
        public titreModal: string,
        public labelInput: string,
        public placeholderInput: string,
        public texteBoutonValider: string,
        public texteBoutonAnnuler: string
    ) {}
}

@Component({
    selector: 'app-input-textarea-modal',
    templateUrl: './input-textarea-modal.component.html',
    styleUrls: ['./input-textarea-modal.component.scss'],
})
export class InputTextareaModalComponent extends BaseComponent implements OnInit {
    formGroup: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<InputTextareaModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: InputTextareaModalData
    ) {
        super();
        dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth600-dialog');

        this.createForm();
    }

    /**
     * Crée le formulaire
     * @private
     */
    private createForm(): void {
        this.formGroup = this.formBuilder.group({
            valueInput: [this.data.existingValue, Validators.required],
        });
    }

    onClickCancel() {
        this.dialogRef.close(false);
    }

    onClickConfirm() {
        if (this.formGroup.valid) {
            this.dialogRef.close({ valueInput: this.formGroup.value.valueInput });
        }
    }
}
