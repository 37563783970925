import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject } from 'rxjs';
import { map, pluck, share, startWith, switchMap } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { Sort as SortMaterial } from '@angular/material/sort';

export interface SimpleDataSource<T> extends DataSource<T> {
    connect(): Observable<T[]>;
    disconnect(): void;
}

export interface Sort<T> {
    property: keyof T;
    order: 'asc' | 'desc';
}

export interface PageRequest<T> {
    page: number;
    size: number;
    sort?: Sort<T>;
}

export function toHttpParams<T>(request: PageRequest<T>) {
    return {
        page: `${request.page}`,
        size: `${request.size}`,
        sort: request.sort ? `${String(request.sort.property)},${request.sort.order}` : undefined,
    };
}

export interface Page<T> {
    content: T[];
    totalElements: number;
    size: number;
    number: number;
}

export type PaginatedEndpoint<T> = (req: PageRequest<T>) => Observable<Page<T>>;

/**
 * Datasource permettant la gestion de données paginées.
 */
export class PaginatedDataSource<T> implements SimpleDataSource<T> {
    private pageNumber = new Subject<number>();
    private sort = new Subject<Sort<T>>();

    public page$: Observable<Page<T>>;

    constructor(endpoint: PaginatedEndpoint<T>, initialSortMaterial: SortMaterial, public size = 20, initialPage = 0) {
        let initialSort = this.sortMaterialToSort(initialSortMaterial);
        this.page$ = this.sort.pipe(
            startWith(initialSort),
            switchMap((sort) =>
                this.pageNumber.pipe(
                    startWith(initialPage),
                    switchMap((page) => endpoint({ page, sort, size: this.size }))
                )
            ),
            share()
        );
    }

    private sortMaterialToSort(initialSortMaterial: SortMaterial): Sort<T> {
        return { property: initialSortMaterial.active as keyof T, order: initialSortMaterial.direction as any };
    }

    sortBy(initialSortMaterial: SortMaterial): void {
        this.sort.next(this.sortMaterialToSort(initialSortMaterial));
    }

    getSort(): Observable<Sort<T>> {
        return this.sort;
    }

    fetch(page: number, size: number): void {
        this.size = size;
        this.pageNumber.next(page);
    }

    connect(): Observable<T[]> {
        return this.page$.pipe(pluck('content'));
    }

    disconnect(): void {}
}

/**
 * Permet de wrapper un appel http pour en extraire les informations de pagination.
 * @param httpPaginated handler pour une requête http avec entêtes de pagination dans la réponse
 */
export function withPaging<T>(httpPaginated: (t: PageRequest<T>) => Observable<HttpResponse<T[]>>): PaginatedEndpoint<T> {
    return (pageRequest: PageRequest<T>) => extractPage(httpPaginated(pageRequest));
}

export function extractPage<T>(source: Observable<HttpResponse<T[]>>) {
    return source.pipe(
        map((response: HttpResponse<T[]>) => {
            const totalElements = Number(response.headers.get('X-Total-Count'));
            const number = Number(response.headers.get('X-Page-Number'));
            const size = Number(response.headers.get('X-Page-Size'));
            return {
                content: response.body,
                totalElements,
                number,
                size,
            };
        })
    );
}

export class PaginatedSelection {
    all: {
        checked: boolean;
        indeterminate: boolean;
    } = {
        checked: false,
        indeterminate: false,
    };
    selectedItems: string[] = [];
    mode: 'select' | 'deselect' = 'select';

    private totalElements = 0;

    constructor(old?: PaginatedSelection) {
        if (old) {
            this.all = old.all;
            this.selectedItems = old.selectedItems;
            this.mode = old.mode;
            this.totalElements = old.totalElements;
        }
    }

    checkAll(checked: boolean) {
        if (checked) {
            this.all.checked = true;
            this.all.indeterminate = false;
            this.mode = 'deselect';
            this.selectedItems = [];
        } else {
            this.all.checked = false;
            this.all.indeterminate = false;
            this.mode = 'select';
            this.selectedItems = [];
        }
        return new PaginatedSelection(this);
    }

    checkItem(item: string, checked: boolean) {
        const selectedIndex = this.selectedItems.indexOf(item);
        const exists = selectedIndex !== -1;
        if (checked) {
            if (this.mode === 'select' && !exists) {
                this.selectedItems.push(item);
            } else if (this.mode === 'deselect' && exists) {
                this.selectedItems.splice(selectedIndex, 1);
            }
        } else {
            if (this.mode === 'select' && exists) {
                this.selectedItems.splice(selectedIndex, 1);
            } else if (this.mode === 'deselect' && !exists) {
                this.selectedItems.push(item);
            }
        }
        if (this.selectedItems.length === this.totalElements) {
            this.all.indeterminate = false;
            this.all.checked = this.mode === 'select';
        } else if (!this.selectedItems.length) {
            this.all.indeterminate = false;
            this.all.checked = this.mode === 'deselect';
        } else {
            this.all.indeterminate = true;
            this.all.checked = false;
        }
        return new PaginatedSelection(this);
    }

    setTotalElements(total: number) {
        this.totalElements = total;
        return new PaginatedSelection(this);
    }
}
