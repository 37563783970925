import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DescriptionBienModalOuvragesService, OuvrageCopyContext } from '../description-bien-modal-ouvrages.service';
import { OuvrageAControler } from '../../../../../model/categorie-ouvrage.model';

@Component({
    selector: 'app-copier-coller-ouvrages',
    templateUrl: './copier-coller-ouvrages.component.html',
    styleUrls: ['./copier-coller-ouvrages.component.scss'],
})
export class CopierCollerOuvragesComponent implements OnInit {
    ouvrageACopier$ = this.descriptionBienModalOuvragesService.selectedOuvrageToCopyContext;

    @Input()
    canCopy: boolean;

    @Output()
    doCopy: EventEmitter<void> = new EventEmitter<void>();
    constructor(private readonly descriptionBienModalOuvragesService: DescriptionBienModalOuvragesService) {}

    ngOnInit(): void {}

    handleCancel() {
        this.descriptionBienModalOuvragesService.cancelCopy();
    }

    handleCopy() {
        this.doCopy.emit();
    }

    isPartieSelected(partie: OuvrageAControler, context: OuvrageCopyContext) {
        return context.selectedParties?.some((it) => it === partie.id);
    }

    togglePartie(partie: OuvrageAControler, context: OuvrageCopyContext) {
        const newSet = new Set(context.selectedParties);

        if (newSet.has(partie.id)) {
            newSet.delete(partie.id);
        } else {
            newSet.add(partie.id);
        }

        context.selectedParties = [...newSet];
    }
}
