<div class="checkpoint-content" *ngIf="diagnostic">
    <app-progress-diagnostic></app-progress-diagnostic>
    <mat-sidenav-container [autosize]="true" class="sidenav-container">
        <mat-sidenav mode="side" opened [disableClose]="true" [style.width]="'200px'">
            <mat-nav-list class="p-0">
                <a
                    *ngFor="let navItem of solutions; let index = index"
                    mat-list-item
                    class="pt-1"
                    style="min-height: 48px; height: auto !important"
                    [routerLink]="transformToRouteSolution(getNomIsolant(navItem)?.valeur)"
                    [ngClass]="{
                        'elec-item-selected': getNomIsolant(currentSolution) == getNomIsolant(navItem),
                        'tab-invalid': !navItem.valid
                    }"
                >
                    <div class="d-flex align-items-center w-100 justify-content-between">
                        <div class="d-flex align-items-center">
                            <lib-icon class="mr-2" *ngIf="!navItem.valid">priority_high</lib-icon>
                            <span>{{ getNomIsolant(navItem)?.valeur }}</span>
                        </div>
                        <button
                            (click)="delSolutionFacturee(index)"
                            color="warn"
                            mat-icon-button
                            matTooltip="Supprimer la solution facturée"
                            [disabled]="readonlyMode"
                        >
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </a>
            </mat-nav-list>
            <button
                [style.white-space]="'normal'"
                class="m-3"
                mat-stroked-button
                color="accent"
                (click)="addSolutionFacturee()"
                [disabled]="readonlyMode"
            >
                <lib-icon>add</lib-icon>
                Ajouter une solution facturée
            </button>
        </mat-sidenav>
        <mat-sidenav-content style="min-height: 85vh" class="fullscreen-content">
            <mat-sidenav-container *ngIf="currentIndexSolution >= 0" [autosize]="true" class="sidenav-container">
                <mat-sidenav mode="side" opened [disableClose]="true" [style.width]="'200px'">
                    <!--LISTE DES ZONES -->
                    <mat-nav-list class="p-0">
                        <a
                            *ngFor="let navItem of zones"
                            mat-list-item
                            class="pt-1"
                            style="min-height: 48px; height: auto !important"
                            [routerLink]="transformToRouteSolution(getNomIsolant(currentSolution)?.valeur, navItem)"
                            [ngClass]="{
                                'elec-item-selected': zoneSelected == navItem,
                                'tab-invalid': zoneSelected != navItem
                            }"
                        >
                            <div class="d-flex align-items-center w-100 justify-content-between">
                                <div class="d-flex align-items-center">
                                    <lib-icon class="mr-2" *ngIf="!currentSolution?.valid">priority_high</lib-icon>
                                    <span>{{ navItem }}</span>
                                </div>
                                <button
                                    mat-icon-button
                                    color="warn"
                                    matTooltip="Supprimer la zone"
                                    [disabled]="readonlyMode"
                                    (click)="deleteZone(navItem)"
                                >
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </a>
                    </mat-nav-list>
                    <div class="d-flex justify-content-center">
                        <button
                            [disabled]="readonlyMode"
                            class="m-3"
                            mat-stroked-button
                            color="accent"
                            (click)="addZone()"
                        >
                            <lib-icon>add</lib-icon>
                            Ajouter une zone
                        </button>
                    </div>
                </mat-sidenav>
                <mat-sidenav-content style="min-height: 85vh" class="fullscreen-content">
                    <!--SOUS MENUS-->
                    <mat-sidenav-container [autosize]="true" class="sidenav-container">
                        <mat-sidenav
                            *ngIf="zoneSelected"
                            mode="side"
                            opened
                            [disableClose]="true"
                            [style.width]="'200px'"
                        >
                            <mat-nav-list class="p-0">
                                <a
                                    *ngFor="let navItem of config"
                                    mat-list-item
                                    class="p-0"
                                    style="min-height: 48px"
                                    [routerLink]="
                                        transformToRouteSolution(
                                            getNomIsolant(currentSolution).valeur,
                                            zoneSelected,
                                            navItem.chapitre
                                        )
                                    "
                                    [ngClass]="{
                                        'elec-item-selected': contentSelected?.chapitre == navItem.chapitre,
                                        'tab-invalid': currentSolution?.valid
                                            ? false
                                            : contentSelected?.chapitre != navItem.chapitre
                                    }"
                                >
                                    <div class="d-flex align-items-center w-100">
                                        <div class="d-flex align-items-center">
                                            <lib-icon *ngIf="!currentSolution?.valid" class="mr-2"
                                                >priority_high
                                            </lib-icon>
                                            <span>{{ navItem.chapitre }}</span>
                                        </div>
                                    </div>
                                </a>
                            </mat-nav-list>
                        </mat-sidenav>
                        <mat-sidenav-content style="min-height: 85vh" class="fullscreen-content">
                            <ng-container *ngIf="!zoneSelected">
                                <app-dialog-solution-isolation
                                    [readonlyMode]="readonlyMode"
                                    [solution]="currentSolution"
                                    (outValidityForm)="solutioniSolationValid = $event"
                                ></app-dialog-solution-isolation>
                            </ng-container>
                            <ng-container *ngIf="zoneSelected">
                                <div class="m-3 d-flex justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <button disabled mat-button>
                                            <lib-icon>navigate_before</lib-icon>
                                        </button>
                                        <div>
                                            {{ contentSelected?.chapitre }}
                                        </div>
                                    </div>
                                </div>
                                <mat-divider></mat-divider>
                                <ng-template ngFor let-container [ngForOf]="contentSelected?.content">
                                    <mat-card class="p-4" *ngIf="container?.pointsDeControles?.length">
                                        <mat-card-title>
                                            <div class="d-flex" *ngIf="container.sousChapitre?.length">
                                                <div class="mr-auto">
                                                    <app-section-title
                                                        [label]="container.sousChapitre"
                                                        [showHelp]="false"
                                                    ></app-section-title>
                                                </div>
                                            </div>
                                        </mat-card-title>
                                        <mat-card-content>
                                            <ng-template
                                                ngFor
                                                let-pointDeControle
                                                [ngForOf]="container.pointsDeControles"
                                            >
                                                <app-ptc-content
                                                    [diagnostic]="diagnostic"
                                                    [name]="pointDeControle.idInterne"
                                                    [checkpoint]="pointDeControle"
                                                    [decalage]="pointDeControle.decalage"
                                                    [libelle]="pointDeControle.description"
                                                    [isCalculette]="pointDeControle.isCalculette"
                                                    [isCalculZone]="pointDeControle.isCalculZone"
                                                    [type]="pointDeControle.type"
                                                    [required]="pointDeControle.required"
                                                    [disabled]="readonlyMode || pointDeControle.idInterne === 'PC55'"
                                                    [value]="getPointDeControle(pointDeControle)"
                                                    [choices]="pointDeControle.lstChoixReponse"
                                                    (valueChange)="onChangeValue($event)"
                                                    (onDuplicate)="duplicateSolution($event)"
                                                ></app-ptc-content>
                                            </ng-template>
                                        </mat-card-content>
                                    </mat-card>
                                </ng-template>
                            </ng-container>
                        </mat-sidenav-content>
                    </mat-sidenav-container>
                </mat-sidenav-content>
            </mat-sidenav-container>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
