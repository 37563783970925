import { Injectable } from '@angular/core';
import { NotificationService } from '../notification/notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { SynchronizationService } from 'src/app/services/synchronization.service';

/**
 * Indique si une erreur est une erreur HTTP de type "serveur indisponible"
 */
export const isServerUnavailableError: (error: any) => boolean = (error) =>
    error && (error.status === 0 || error.status === 502 || error.status === 503 || error.status === 504);

/**
 * Indique si une erreur est une erreur HTTP de type 404 Not Found
 */
export const isNotFoundError: (error: any) => boolean = (error) => error && error.status === 404;

@Injectable()
export class HttpErrorService {
    constructor(private notificationService: NotificationService, private readonly synchronizationService: SynchronizationService) {}

    /**
     * À partir d'une erreur HTTP, notifie une erreur à l'utilisateur avec :
     * - le message de l'exception si le backend en spécifie un,
     * - sinon un message standard en fonction du statut de l'erreur.
     */
    handleError(errorResponse: HttpErrorResponse): Observable<any> {
        try {
            const error = errorResponse.error;
            if (errorResponse.status === 504) {
                this.synchronizationService.forceOnlineCheck();
            } else if (error.message) {
                // Message spécifique
                this.notificationService.error(error.message);
            } else if (errorResponse.status === 403 && error.detail) {
                // Message spécifique (cas de l'erreur 403)
                this.notificationService.error(error.detail);
            } else {
                this.notifyErrorFromHttpStatusAndUrl(errorResponse.status);
            }
        } catch (e) {
            this.notifyErrorFromHttpStatusAndUrl(errorResponse.status);
        }
        return throwError(Object.assign({ doNotNotify: true, errorResponse }));
    }

    /**
     * À partir d'une erreur HTTP, notifie une erreur à l'utilisateur avec un message standard en fonction du statut de l'erreur.
     */
    notifyErrorFromHttpStatusAndUrl(status: number) {
        if (status === 400) {
            this.notificationService.error('Requête incorrecte. Réessayez ou contactez le support.');
        } else if (status === 401) {
            this.notificationService.error("Vous n'êtes pas autorisé à effectuer cette requête. Merci de vous authentifier");
        } else if (status === 403) {
            this.notificationService.error("Vous n'avez pas les permissions suffisantes pour effectuer cette requête");
        } else if (status === 404) {
            this.notificationService.error('Objet non trouvé.');
        } else if (status === 500) {
            this.notificationService.error('Erreur serveur. Contactez le support.');
        } else if (status === 502 || status === 503 || status === 504) {
            this.notificationService.error('Service indisponible. Réessayez plus tard ou contactez le support.');
        } else if (status === 0) {
            this.notificationService.error('Non disponible en mode hors ligne.');
        } else if (status === 408 || status === 599) {
            this.notificationService.error('Erreur réseau. Réessayez plus tard ou contactez le support.');
        } else {
            this.notificationService.error(`Erreur ${status}. Merci de contacter le support.`);
        }
    }
}
