import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { InterventionService } from '../services/intervention.service';
import { RelationInterventionBien } from '../model/intervention.model';
import { BienService } from '../services/bien.service';

/**
 * Récupère le bien à partir du paramètre "idBien" et la charge dans le service.
 */
@Injectable({
    providedIn: 'root',
})
export class EditorGuard implements CanActivate {
    constructor(private interventionService: InterventionService, private bienService: BienService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return of(route.paramMap).pipe(
            switchMap((params) => {
                let relationInterventionBien: RelationInterventionBien;
                if (params.get('idBien')) {
                    relationInterventionBien = this.interventionService
                        .getCurrentInterventionValue()
                        .relationInterventionBiens.find((it) => it.bien.id === params.get('idBien'));
                } else {
                    relationInterventionBien = this.interventionService
                        .getCurrentInterventionValue()
                        .relationInterventionBiens.find((it) => it.isBienPrincipal);
                }
                this.bienService.setCurrentBien(relationInterventionBien);
                return of(true);
            }),
            map(() => true)
        );
    }
}
