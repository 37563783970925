<div class="d-flex flex-column flex-grow-1 overflow-hidden">
    <div class="p-2 d-flex justify-content-between">
        <button mat-raised-button color="accent" class="my-auto" (click)="handleClickRetour()">
            <mat-icon>arrow_back</mat-icon>
            <span>Retour</span>
        </button>
        <div>
            <button
                *ngIf="!currentVolume.spaceId"
                mat-icon-button
                matTooltip="Déplacer le volume"
                [disabled]="readonlyMode"
                (click)="handleDeplacerVolume()"
            >
                <mat-icon>move_down</mat-icon>
            </button>
            <button
                *ngIf="!currentVolume.volumeCache"
                mat-icon-button
                matTooltip="Fusionner le volume"
                [disabled]="readonlyMode"
                (click)="handleMergeVolume()"
            >
                <mat-icon>swap_horiz</mat-icon>
            </button>
        </div>
    </div>

    <div *ngIf="!currentVolume.valeursParametres[PARAM_VOLUME_VISITE]" class="to-justify py-0">
        <span class="text-small">Il manque un état de visite</span>
    </div>

    <div
        *ngIf="
            currentVolume.valeursParametres[PARAM_VOLUME_VISITE] &&
            (currentVolume.valeursParametres[PARAM_VOLUME_VISITE] === VOLUME_VISITE.WARNING ||
                currentVolume.valeursParametres[PARAM_VOLUME_VISITE] === VOLUME_VISITE.KO) &&
            !currentVolume.justifie
        "
        class="to-justify"
    >
        <span class="text-small">Il manque une justification de non visite</span>
        <button mat-icon-button color="justify" class="ml-1" (click)="addJustificationNonVisite()">
            <lib-icon theme="mdi" iconName="comment-alert"></lib-icon>
        </button>
    </div>
    <div class="d-flex px-3 justify-content-between align-items-center">
        <div
            [ngClass]="{
                justify:
                    !currentVolume.valeursParametres[PARAM_VOLUME_VISITE] ||
                    (currentVolume.valeursParametres[PARAM_VOLUME_VISITE] &&
                        (currentVolume.valeursParametres[PARAM_VOLUME_VISITE] === VOLUME_VISITE.KO ||
                            currentVolume.valeursParametres[PARAM_VOLUME_VISITE] === VOLUME_VISITE.WARNING) &&
                        !currentVolume.justifie)
            }"
        >
            {{ currentVolume.nom }}
        </div>
        <div>
            <button
                *ngIf="!currentVolume.volumeCache"
                mat-icon-button
                matTooltip="Éditer le volume"
                [disabled]="readonlyMode"
                (click)="handleEditerVolume()"
            >
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button matTooltip="Ajouter un commentaire" [disabled]="readonlyMode" (click)="handleCommenterVolume()">
                <mat-icon
                    [matBadgeHidden]="currentVolume.commentairesId.length == 0"
                    matBadge="{{ currentVolume.commentairesId.length }}"
                    matBadgeColor="accent"
                    >comment</mat-icon
                >
            </button>
        </div>
    </div>
    <div class="d-flex px-3 align-items-center justify-content-between">
        <span>Visitée</span>
        <div class="d-flex">
            <button
                mat-icon-button
                matTooltip="Visitée"
                class="small-icon-button"
                [disabled]="readonlyMode"
                (click)="handleClickVolumeVisite(VOLUME_VISITE.OK)"
            >
                <mat-icon
                    fontSet="material-icons-two-tone"
                    class="success"
                    [ngClass]="{
                        'icon-disabled':
                            currentVolume.valeursParametres[PARAM_VOLUME_VISITE] &&
                            currentVolume.valeursParametres[PARAM_VOLUME_VISITE] != VOLUME_VISITE.OK
                    }"
                    >lock_open
                </mat-icon>
            </button>
            <button
                mat-icon-button
                matTooltip="Partiellement visitée"
                class="small-icon-button"
                [disabled]="readonlyMode"
                (click)="handleClickVolumeVisite(VOLUME_VISITE.WARNING)"
            >
                <mat-icon
                    fontSet="material-icons-two-tone"
                    class="justify"
                    [ngClass]="{
                        'icon-disabled':
                            currentVolume.valeursParametres[PARAM_VOLUME_VISITE] &&
                            currentVolume.valeursParametres[PARAM_VOLUME_VISITE] != VOLUME_VISITE.WARNING
                    }"
                    >lock
                </mat-icon>
            </button>
            <button
                mat-icon-button
                matTooltip="Non visitée"
                class="small-icon-button"
                [disabled]="readonlyMode"
                (click)="handleClickVolumeVisite(VOLUME_VISITE.KO)"
            >
                <mat-icon
                    fontSet="material-icons-two-tone"
                    class="error"
                    [ngClass]="{
                        'icon-disabled':
                            currentVolume.valeursParametres[PARAM_VOLUME_VISITE] &&
                            currentVolume.valeursParametres[PARAM_VOLUME_VISITE] != VOLUME_VISITE.KO
                    }"
                    >lock
                </mat-icon>
            </button>
        </div>
    </div>
    <div class="flex-grow-1 p-2 overflow-auto">
        <div *ngIf="currentVolume.equipements.length === 0">Ne contient pas d'équipements</div>
        <div *ngIf="currentVolume.equipements.length > 0">
            <div class="mt-3">
                <mat-card
                    *ngFor="let element of currentVolume.equipements"
                    class="d-flex p-1 row no-gutters justify-content-between align-items-center px-1 my-2 py-0 rounded"
                >
                    <div class="d-flex p-1 align-items-center">
                        <span>{{ element.name | uppercase }}</span>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</div>
<app-description-bien-fusion-volume [currentBien]="currentBien" [(currentVolume)]="currentVolume"></app-description-bien-fusion-volume>
