import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TypeBien } from '../model/type-bien.model';
import { ReferenceService } from '../services/reference.service';

/**
 * Affiche le nom d'un type de bien
 */
@Pipe({
    name: 'typeBien',
})
export class TypeBienPipe implements PipeTransform {
    private allTypeBien$: Observable<TypeBien[]> = this.referenceService.findAllTypesBien();

    constructor(private referenceService: ReferenceService) {}

    transform(idTypeBien: string): Observable<string> {
        return this.allTypeBien$.pipe(
            map((allTypeBien) => {
                const typeBien = allTypeBien.find((it) => it.id === idTypeBien);
                return typeBien ? typeBien.nom : idTypeBien;
            })
        );
    }
}
