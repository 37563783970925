import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/commons-lib';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditMode } from 'src/app/model/edit-mode.model';
import { ParameterDefinition, ParametersGroup, parametersTypesStandard, ParameterType } from '@acenv/commons-lib';
import { ReferenceService } from 'src/app/services/reference.service';

@Component({
    selector: 'app-edit-parameterDefinition-modal',
    templateUrl: './edit-parameterDefinition-modal.component.html',
    styleUrls: ['./edit-parameterDefinition-modal.component.scss'],
})
export class EditParameterDefinitionModalComponent extends BaseComponent implements OnInit {
    currentParameter: ParameterDefinition;
    formParam: FormGroup;
    mode: EditMode = 'CREATE';
    listeType: ParameterType[] = parametersTypesStandard;
    typeSelected: ParameterType = 'TEXTE';
    currentEditParameter: ParameterDefinition;
    valuesParameter: { [codeBim: string]: any };
    listeGroups: ParametersGroup[];

    constructor(
        private readonly dialogRef: MatDialogRef<EditParameterDefinitionModalComponent>,
        private readonly formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) readonly data: any,
        private readonly referenceService: ReferenceService
    ) {
        super();
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth1000-dialog');
        this.currentEditParameter = this.data.parameter;
        this.valuesParameter = this.data.valuesParameter;
        if (this.currentEditParameter != null) {
            this.currentParameter = this.currentEditParameter;
            this.typeSelected = this.currentParameter.type;
            this.mode = 'EDIT';
        }

        this.formParam = this.formBuilder.group({
            name: ['', Validators.required],
            codeBim: ['', Validators.required],
            description: '',
            type: ['', Validators.required],
            choices: [],
            numberDefinitions: [],
            parameterValue: [],
            group: ['', Validators.required],
        });

        this.referenceService.findAllParametersGroups().subscribe((gr) => (this.listeGroups = gr));

        this.populateForm();
    }

    /**
     * Remplis le formulaire avec les données existantes
     */
    populateForm() {
        if (this.currentParameter != null) {
            this.formParam.patchValue({
                name: this.currentParameter.name,
                codeBim: this.currentParameter.codeBim,
                description: this.currentParameter.description,
                type: this.currentParameter.type,
                choices: this.currentParameter.choices,
                parameterValue: this.valuesParameter[this.currentParameter?.codeBim],
                group: this.listeGroups.find((g) => g.id == this.currentParameter.groupId),
            });
        } else {
            this.formParam.patchValue({
                name: '',
                codeBim: '',
                description: '',
                type: '',
                choices: [],
                parameterValue: null,
                group: null,
            });
        }
    }

    onClickSaveModification() {
        let param: ParameterDefinition = {
            name: this.formParam.value.name,
            codeBim: this.formParam.value.codeBim,
            description: this.formParam.value.description,
            type: this.formParam.value.type,
            choices: this.formParam.value.choices,
            groupId: this.formParam.value.group.id,
        };
        let paramValue: { [codeBim: string]: any } = {
            [param.codeBim]: this.formParam.value.parameterValue,
        };
        this.dialogRef.close({ param: param, paramValue: paramValue });
    }

    onClickCancel() {
        this.dialogRef.close(null);
    }

    changeType(event) {
        this.typeSelected = event;
    }

    changeChoices($event: any) {
        if (!this.currentParameter?.choices.includes($event)) {
            this.currentParameter?.choices.push($event);
        }
    }

    changeGroup($event: any) {
        throw new Error('Method not implemented.');
    }
}
