import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { ReferencePrestation } from '../../../../model/reference-prestation.model';
import { combineLatest, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { InterventionService } from '../../../../services/intervention.service';
import { ReferenceService } from '../../../../services/reference.service';
import { CnSpinnerService } from '../../../shared/cn-spinner/service/cn-spinner.service';

@Component({
    selector: 'app-description-bien-filter-type-prestations',
    templateUrl: './description-bien-filter-type-prestations.component.html',
    styleUrls: ['./description-bien-filter-type-prestations.component.scss'],
})
export class DescriptionBienFilterTypePrestationsComponent extends BaseComponent {
    @Input()
    selectedRefPrestations: ReferencePrestation[] = [];

    @Output()
    selectedRefPrestationsChange: EventEmitter<ReferencePrestation[]> = new EventEmitter<ReferencePrestation[]>();

    refPrestations: ReferencePrestation[];
    refPrestationsInter: ReferencePrestation[];
    displayAllRefPresta: boolean = false;

    constructor(
        private readonly interventionService: InterventionService,
        private readonly referenceService: ReferenceService,
        private readonly cnSpinnerService: CnSpinnerService
    ) {
        super();
    }

    ngOnInit() {
        this.cnSpinnerService
            .withSpinner(
                combineLatest([
                    this.interventionService.getCurrentIntervention(),
                    this.referenceService.findAllReferencePrestations(),
                ]).pipe(
                    takeUntil(this.ngUnsubscribe),
                    switchMap(([currentIntervention, referencePrestations]) => {
                        return combineLatest([of(currentIntervention), of(referencePrestations)]);
                    })
                )
            )
            .subscribe(([currentIntervention, referencePrestations]) => {
                const refPrestationsInterIds = currentIntervention.prestationsDiagnostics.map(
                    (it) => it.prestation.referencePrestation.id
                );
                this.refPrestationsInter = referencePrestations.filter((it) => refPrestationsInterIds.includes(it.id));
                this.refPrestations = referencePrestations;

                this.selectedRefPrestationsChange.emit(this.selectedRefPrestations);
                this.cnSpinnerService.hide();
            });
    }

    handleClickFilterDiag(refPresta: ReferencePrestation) {
        const index = this.selectedRefPrestations.indexOf(refPresta);
        if (index != -1) {
            this.selectedRefPrestations.splice(index, 1);
        } else {
            this.selectedRefPrestations.push(refPresta);
        }
        this.selectedRefPrestationsChange.emit(this.selectedRefPrestations);
    }
}
