import { Contact } from '../../../../model/contact.model';

export enum EtatDICT {
    RECU = 'RECU',
    EN_ATTTENTE = 'EN_ATTTENTE',
}

export enum TypeDateReconnaissance {
    AU_PLUS_TARD = 'AU_PLUS_TARD',
    DATE_PREVISIONNELLE = 'DATE_PREVISIONNELLE',
    RDV = 'RDV',
}

export class HapPreparationDICTDemande {
    commentairesId: string[] = [];
    dateDemande: string = null;
    datePrevisionnelleReponse: string = null;
}

export class HapPreparationDICTReponse {
    commentairesId: string[] = [];
    dateReponse: string = null;
    dateFinValidite: string = null;
    idDocumentDICT: string = null;
}

export class HapPreparationReconnaissance {
    reconnaissanceNecessaire = true;
    commentairesId: string[] = [];
    dateReconnaissance: string = null;
    dateAuPlusTard?: TypeDateReconnaissance;
    contacts: Contact[] = [];
}

export class HapPreparationTerrain {
    contacts: Contact[] = [];
}

export class HapPreparationDICT {
    soumisDICT = true;
    etatDemande: EtatDICT = EtatDICT.EN_ATTTENTE;
    demande: HapPreparationDICTDemande = new HapPreparationDICTDemande();
    reponse: HapPreparationDICTReponse = new HapPreparationDICTReponse();
}

/**
 * ensemble des classes, du model data, liées à l'onglet préparation
 */
export class HapPreparation {
    dict: HapPreparationDICT = new HapPreparationDICT();
    reconnaissance: HapPreparationReconnaissance = new HapPreparationReconnaissance();
    modalites: string = null;
    terrain: HapPreparationTerrain = new HapPreparationTerrain();
}
