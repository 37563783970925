import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { marked } from 'marked';

@Injectable()
export class MarkdownService {
    constructor(private http: HttpClient) {}

    getMarkdownFile(markdownFile): Observable<string> {
        return this.http.get(markdownFile, { responseType: 'text' });
    }

    convertToHTML(mdAsText): string {
        return marked(mdAsText);
    }
}
