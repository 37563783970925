import { Injectable } from '@angular/core';
import { MpcaProcessusTaskOld } from '../model/mpca.model.old';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorUtils } from '../../../../utils/validator.utils';
import { DiagnosticService } from '../../../../services/diagnostic.service';

@Injectable({
    providedIn: 'root',
})
export class MpcaService {
    constructor(private formBuilder: FormBuilder, private diagnosticService: DiagnosticService) {}

    getTacheGroup(item: MpcaProcessusTaskOld) {
        const newTacheGroup = this.formBuilder.group({
            operateur1: new FormControl(item.operateur1),
            nom1: [
                item.nom1,
                {
                    updateOn: 'blur',
                },
            ],
            operateur2: new FormControl(item.operateur2),
            nom2: [
                item.nom2,
                {
                    updateOn: 'blur',
                },
            ],
            operateur3: new FormControl(item.operateur3),
            nom3: [
                item.nom3,
                {
                    updateOn: 'blur',
                },
            ],
            operateur4: new FormControl(item.operateur4),
            nom4: [
                item.nom4,
                {
                    updateOn: 'blur',
                },
            ],
            tache: new FormControl(item.tache, [Validators.required]),
        });
        newTacheGroup.setValidators(ValidatorUtils.checkedOneOperatorChecked());
        return newTacheGroup;
    }

    /**
     *
     * @param processus Un objet
     * @returns FormGroup
     */
    createProcessusForm(processus): FormGroup {
        const processForm = this.formBuilder.group({
            nom: [processus.nom, Validators.required],
            taches: new FormArray([]),
            metop: this.formBuilder.group({
                methode: [processus.metop.methode, Validators.required],
                technique: [processus.metop.technique],
                outil: [processus.metop.outil],
                aspiration: [processus.metop.aspiration],
                travailHumide: [processus.metop.travailHumide],
            }),
            pendantTravaux: this.formBuilder.group({
                niveauEmpoussierement: [processus.pendantTravaux.niveauEmpoussierement, Validators.required],
                typeMasqueDesamianteur: [processus.pendantTravaux.typeMasqueDesamianteur, Validators.required],
                concentrationFi: [processus.pendantTravaux.concentrationFi, Validators.required],
            }),
            echantillonnage: this.formBuilder.group({
                concentrationAttendue: [processus.echantillonnage.concentrationAttendue],
                dureeRepresentativeProcessus: [processus.echantillonnage.dureeRepresentativeProcessus],
                dureeSaturationPoussieres: [processus.echantillonnage.dureeSaturationPoussieres],
                debitPrelevement: [processus.echantillonnage.debitPrelevement],
                fractionFiltre: [processus.echantillonnage.fractionFiltre],
            }),
        });

        processus.taches.forEach((tache) => {
            (processForm.get('taches') as FormArray).push(this.getTacheGroup(tache));
        });

        return processForm;
    }
}
