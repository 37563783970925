import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { AddressUtils } from '../../../../utils/addressUtils';
import { Router } from '@angular/router';
import { CnSpinnerService } from '../../../shared/cn-spinner/service/cn-spinner.service';
import { BaseComponent, ConfirmationService } from 'src/app/commons-lib';
import { Bien } from '../../../../model/bien.model';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject } from 'rxjs';
import { PaginatedDataSource } from '../../../../shared/paging/page';
import { BienApiService } from '../../../../services/bien-api.service';
import { SessionStoragePaginationUtils } from '../../../../utils/session-storage-pagination.utils';
import { DEBOUNCE_TIME_VALUE, INITIAL_PAGE_SIZE, SESSION_STORAGE_KEY_BIEN } from 'src/app/shared/constants/admin.constants';
import { URL_GESTION_BIENS_CONSULTER, URL_GESTION_BIENS_EDIT } from 'src/app/shared/constants/url.constants';
import { BienService } from 'src/app/services/bien.service';

export const DEFAULT_SORT = { direction: 'asc', active: 'nom' };

@Component({
    selector: 'app-tableau-bien',
    templateUrl: './tableau-bien.component.html',
    styleUrls: ['./tableau-bien.component.scss'],
})
export class TableauBienComponent extends BaseComponent implements OnInit, OnDestroy {
    @Input()
    initialPageSize = INITIAL_PAGE_SIZE;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayedColumns: string[] = ['nom', 'contactProprietaire.nom', 'idTypeBien', 'adresse.voie', 'numeroLot', 'referenceCadastre', 'actions'];

    dataSource: PaginatedDataSource<Bien>;
    sort: Sort;
    searchValue = SessionStoragePaginationUtils.getInitialReseach(SESSION_STORAGE_KEY_BIEN);
    private search = new BehaviorSubject(this.searchValue);

    constructor(
        private readonly bienService: BienService,
        private readonly bienServiceApi: BienApiService,
        private readonly router: Router,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly confirmationService: ConfirmationService
    ) {
        super();
        this.sort = SessionStoragePaginationUtils.getInitialSort(SESSION_STORAGE_KEY_BIEN, DEFAULT_SORT);
    }

    ngOnInit(): void {
        this.search.pipe(debounceTime(DEBOUNCE_TIME_VALUE), distinctUntilChanged(), takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.dataSource.fetch(0, this.dataSource.size);
        });
        this.dataSource = new PaginatedDataSource<Bien>(
            (pageRequest) => {
                return this.cnSpinnerService.withSpinner(
                    this.bienServiceApi.searchBiens(this.search.getValue(), pageRequest).pipe(
                        map((response) => {
                            response.content.map((bien) => {
                                return this.formatBien(bien);
                            });
                            return response;
                        })
                    )
                );
            },
            SessionStoragePaginationUtils.getInitialSort(SESSION_STORAGE_KEY_BIEN, DEFAULT_SORT),
            SessionStoragePaginationUtils.getInitialPageSize(SESSION_STORAGE_KEY_BIEN, this.initialPageSize)
        );
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    /**
     * Action déclenchée lors du click sur le bouton Dupliquer Bien
     * @param bien
     */
    dupliquerBien(bien: Bien) {
        this.setSessionStorageItem();
        if (bien.hasNewVersionInRelationInterventionBien) {
            this.bienService.confirmSynchroniserBien(bien, (b) => this.redirectDupliquer(b));
        } else {
            this.redirectDupliquer(bien);
        }
    }

    private redirectDupliquer(bien?: any) {
        if (bien) {
            this.router.navigate([URL_GESTION_BIENS_EDIT, bien.id, 'duplicate']);
        }
    }

    /**
     * Action déclenchée lors du click sur le bouton "Editer"
     * @param bien
     */
    editerBien(bien: Bien) {
        this.setSessionStorageItem();
        if (bien.hasNewVersionInRelationInterventionBien) {
            this.bienService.confirmSynchroniserBien(bien, (b) => this.redirectEditer(b));
        } else {
            this.redirectEditer(bien);
        }
    }

    private redirectEditer(bien?: any) {
        if (bien) {
            this.setSessionStorageItem();
            this.router.navigate([URL_GESTION_BIENS_EDIT, bien.id]);
        }
    }

    /**
     * Action déclenchée lors du click sur le bouton "Consulter"
     * @param bien
     */
    consulter(bien: Bien) {
        this.setSessionStorageItem();
        this.router.navigate([URL_GESTION_BIENS_CONSULTER, bien.id]);
    }

    /**
     * Action déclenchée lors de la saisie d'un caractère dans la barre de recherche
     * @param $event
     */
    onKeyupSearchBiens($event: any) {
        this.search.next($event.target.value);
        this.setSessionStorageItem();
    }

    /**
     * Action déclenchée lors d'un tri dans le tableau
     * @param $event
     */
    sortBiens($event: Sort) {
        this.sort = $event;
        this.dataSource.sortBy($event);
        this.setSessionStorageItem();
    }

    /**
     * Set les valeurs du tableau dans le session storage (pagination, recherche, tri ..)
     * @private
     */
    private setSessionStorageItem() {
        SessionStoragePaginationUtils.setSessionStorageItem(SESSION_STORAGE_KEY_BIEN, this.dataSource.size, this.search.getValue(), this.sort);
    }

    /**
     * Prépare les données des biens pour l'affichage dans le tableau
     * @param bien
     * @private
     */
    private formatBien(bien: Bien): Bien {
        bien.adresseStringPourFiltre = AddressUtils.getFormatedAddress(bien.adresse);

        bien.contactProprietaireString = bien.contactProprietaire
            ? `${bien.contactProprietaire.nom}
              ${
                  bien.contactProprietaire.prenom
                      ? bien.contactProprietaire.prenom
                      : bien.contactProprietaire.siret
                      ? bien.contactProprietaire.siret
                      : ''
              }`
            : '';
        return bien;
    }
}
