import { Component, Inject, OnInit } from '@angular/core';
import { InterventionService } from '../../../../services/intervention.service';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { Intervention } from '../../../../model/intervention.model';
import { combineLatestOrEmpty } from '../../../../utils/rxjs.utils';
import { DiagnosticService } from '../../../../services/diagnostic.service';
import { DetailPhoto, Diagnostic, PresetPhoto, presetsPhoto, ReportagePhoto, ReportagePhotoImageFile } from '../../../../model/diagnostic.model';
import { BaseComponent, ConfirmationService, MongoUtils } from 'src/app/commons-lib';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CnSpinnerService } from '../../../shared/cn-spinner/service/cn-spinner.service';
import { InterventionFileService } from '../../../../services/intervention-file.service';
import { ReportagePhotoService } from '../../../../services/reportage-photo.service';
import { MatOptionSelectionChange } from '@angular/material/core';

@Component({
    selector: 'app-reportage-photo-edit',
    templateUrl: './reportage-photo-edit.component.html',
    styleUrls: ['./reportage-photo-edit.component.scss'],
})
export class ReportagePhotoEditComponent extends BaseComponent implements OnInit {
    idReportagePhoto = '';
    form: FormGroup;

    readonlyMode = false;

    reportagePhoto: ReportagePhoto;
    reportagePhotoInitial: ReportagePhoto;
    reportagePhotoImageFiles: ReportagePhotoImageFile[] = [];
    reportagePhotoImageFilesSelected: ReportagePhotoImageFile[] = [];
    presetReportagePhoto: PresetPhoto[] = [];

    private intervention: Intervention;
    private diagnostic: Diagnostic;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private readonly interventionService: InterventionService,
        private readonly diagnosticService: DiagnosticService,
        private readonly formBuilder: FormBuilder,
        private readonly dialogRef: MatDialogRef<ReportagePhotoEditComponent>,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly interventionFileService: InterventionFileService,
        private readonly reportagePhotoService: ReportagePhotoService,
        private readonly confirmationService: ConfirmationService
    ) {
        super();
        this.idReportagePhoto = data.idReportagePhoto ? data.idReportagePhoto : '';
        this.createForm();
    }

    ngOnInit(): void {
        this.cnSpinnerService
            .withSpinner(
                combineLatest([this.interventionService.getCurrentIntervention(), this.diagnosticService.getCurrentDiagnostic()]).pipe(
                    switchMap(([intervention, diagnostic]) => {
                        return combineLatestOrEmpty([
                            this.interventionFileService.getInterventionFilesOfDiagnostic(intervention, diagnostic),
                            this.interventionFileService.getInterventionFilesOfInterventionForReportagePhoto(intervention),
                        ]).pipe(
                            map(([interventionFilesOfDiagnostic, interventionFilesOfIntervention]) => {
                                return interventionFilesOfDiagnostic.concat(interventionFilesOfIntervention);
                            }),
                            switchMap((interventionFiles) => {
                                return combineLatest([
                                    of(intervention),
                                    of(diagnostic),
                                    combineLatestOrEmpty(this.reportagePhotoService.getFiles(intervention, interventionFiles)),
                                ]);
                            })
                        );
                    })
                )
            )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([intervention, diagnostic, files]) => {
                this.reportagePhotoImageFiles = files;

                this.intervention = intervention;
                this.diagnostic = diagnostic;
                this.presetReportagePhoto = intervention.prestationsDiagnostics.find(
                    (diag) => diag.idDiagnostic === diagnostic.id
                ).prestation.referencePrestation.presetsReportagePhotosAuto;
                this.readonlyMode = this.data.readonlyMode;

                if (this.idReportagePhoto) {
                    // @ Mode édition

                    // Récupération du reportage photo courant
                    this.reportagePhoto = this.diagnostic.reportagesPhoto.find((reportagePhoto) => {
                        return reportagePhoto.id === this.idReportagePhoto;
                    });
                    // Sauvegarde du reportage dans l'état en cas d'annulation
                    this.reportagePhotoInitial = Object.assign({}, this.reportagePhoto);

                    // Mets à jour en supprimant les photos qui ne sont plus disponible
                    // (si elles ont été supprimé dans un commentaire par exemple)
                    this.reportagePhoto.photos = this.getAvailablesPhotos(this.reportagePhotoImageFiles, this.reportagePhoto.photos);

                    // Déterminer les photos sélectionnées
                    this.reportagePhotoImageFiles.forEach((reportagePhotoImageFile) => {
                        reportagePhotoImageFile.selected = !!this.reportagePhoto.photos.find((photo) => {
                            return photo.idFichier === reportagePhotoImageFile.fileId;
                        });
                        reportagePhotoImageFile.legende = this.reportagePhoto.photos.find((photo) => {
                            return photo.idFichier === reportagePhotoImageFile.fileId;
                        })?.legende;
                    });

                    // Liste intermédiaire pour afficher les éléments séléctionnés
                    this.reportagePhotoImageFilesSelected = this.getReportagePhotoImageFilesSelected();
                } else {
                    // @ Mode création :
                    this.reportagePhoto = new ReportagePhoto();
                }

                // Préparation des légendes
                this.prepareLegends();
                this.populateForm();
            });
    }

    /**
     * Action lorsque l'utilisateur sélectionne une image
     * @param reportagePhotoImageFile
     */
    onSelectImageFile(reportagePhotoImageFile: ReportagePhotoImageFile) {
        if (reportagePhotoImageFile.selected) {
            // Si la photo est séléctionnée, on l'ajoute à la liste des photos
            if (this.reportagePhoto.photos.find((p) => p.idFichier === reportagePhotoImageFile.fileId) === undefined) {
                this.reportagePhoto.photos.push(
                    new DetailPhoto(
                        reportagePhotoImageFile.fileId,
                        reportagePhotoImageFile.typeReferenceFichier,
                        reportagePhotoImageFile.legende,
                        false,
                        reportagePhotoImageFile.creationDate ?? 'Non renseigné',
                        reportagePhotoImageFile.gpsAltitudeRef ?? 'Non renseigné',
                        reportagePhotoImageFile.gpsLatitude ?? 'Non renseigné',
                        reportagePhotoImageFile.gpsLongitudeRef ?? 'Non renseigné',
                        reportagePhotoImageFile.gpsLongitude ?? 'Non renseigné',
                        reportagePhotoImageFile.gpsAltitudeRef ?? 'Non renseigné',
                        reportagePhotoImageFile.gpsAltitude ?? 'Non renseigné',
                        reportagePhotoImageFile.objetReference ?? '',
                        reportagePhotoImageFile.nonConformiteReference ?? '',
                        reportagePhotoImageFile.localisations
                    )
                );
            }
        } else {
            // Si la photo est désélectionnée, on la supprime de la liste des photos
            const index = this.reportagePhoto.photos.findIndex((detailPhoto) => {
                return (
                    detailPhoto.idFichier === reportagePhotoImageFile.fileId &&
                    detailPhoto.typeReferenceFichier === reportagePhotoImageFile.typeReferenceFichier
                );
            });
            if (index > -1) {
                this.reportagePhoto.photos.splice(index, 1);
            }
        }
        // Mise à jour du tableau permettant d'afficher les images séléctionnées
        this.reportagePhotoImageFilesSelected = this.getReportagePhotoImageFilesSelected();
    }

    /**
     * Action lorsque l'utilisateur sélectionne un preset de reportage photo
     * @param event
     */
    presetSelectionChanged(event: MatOptionSelectionChange<PresetPhoto>) {
        if (event.isUserInput) {
            if (!event.source.value) {
                this.form.patchValue({
                    isSubscribing: false,
                });
            } else {
                this.form.patchValue({
                    isSubscribing: true,
                });
            }
            this.form.updateValueAndValidity();
            this.changePreset(event);
        }
    }

    /**
     * Modifie la légende d'une photo
     * @param image
     */
    onLegendChanged(image: ReportagePhotoImageFile) {
        const index = this.reportagePhoto.photos.findIndex((p) => p.idFichier === image.fileId);
        if (index > -1) {
            this.reportagePhoto.photos[index].legende = image.legende;
            this.reportagePhoto.photos[index].legendEdited = true;
        }
    }

    /**
     * Filtre les images contenus dans "photos" afin de supprimer les images qui
     * ne sont plus associées à un élément.
     * Exemple : Le cas où le commentaire a été supprimé.
     * L'idée étant de ne plus l'afficher dans la liste de gauche.
     * @param reportagePhotoImageFiles
     * @param photos
     * @private
     */
    private getAvailablesPhotos(reportagePhotoImageFiles: ReportagePhotoImageFile[], photos: DetailPhoto[]) {
        const reportagePhotoImageFileIds = reportagePhotoImageFiles.map((reportagePhotoImageFile) => reportagePhotoImageFile.fileId);

        return photos.filter((photo) => {
            return reportagePhotoImageFileIds.includes(photo.idFichier);
        });
    }

    /**
     * Récupère la liste des photos sélectionnées
     */
    private getReportagePhotoImageFilesSelected() {
        return this.reportagePhoto.photos.map((detailFichier) => {
            return this.reportagePhotoImageFiles.find((tempReportagePhotoImageFile) => {
                return tempReportagePhotoImageFile.fileId === detailFichier.idFichier;
            });
        });
    }

    // Formulaire :

    /**
     * Crée le formulaire
     */
    private createForm(): void {
        this.form = this.formBuilder.group({
            nom: ['', Validators.required],
            typePreset: [''],
            isSubscribing: [false],
        });
    }

    /**
     * Remplie le formulaire avec les données dans le cas de l'édition
     */
    private populateForm(): void {
        this.form.patchValue({
            nom: this.reportagePhoto.nom,
            typePreset: this.reportagePhoto.typePreset,
            isSubscribing: this.reportagePhoto.autoGenerated,
        });
    }

    /**
     * Action lorsque l'utilisateur clique sur le bouton pour sauvegarder les informations
     */
    onSubmitForm(): void {
        if (this.form.valid) {
            this.prepareData();
            this.diagnosticService.upsert(this.intervention, this.diagnostic).subscribe((diagnostic) => {
                // Si on ne fait pas ça, le current Diagnostic n'est pas mis à jour avec les valeurs courantes
                this.diagnosticService.reloadCurrentDiagnostic();
                this.goBack(diagnostic);
            });
        }
    }

    /**
     * Prépare les données à sauvegarder
     */
    private prepareData() {
        this.reportagePhoto.nom = this.form.value.nom;
        this.reportagePhoto.typePreset = this.form.value.typePreset;
        this.reportagePhoto.autoGenerated = this.form.value.isSubscribing;

        if (!this.reportagePhoto.id) {
            this.reportagePhoto.id = MongoUtils.generateObjectId();
            this.diagnostic.reportagesPhoto.push(this.reportagePhoto);
        }
    }

    /**
     * Action lorsque l'utilisateur clique sur le bouton "Annuler"
     */
    onClickBtnCancel() {
        // Rollback dans le cas où le reportage photo existait déjà
        if (this.reportagePhotoInitial) {
            this.reportagePhoto.nom = this.reportagePhotoInitial.nom;
            this.reportagePhoto.photos = this.reportagePhotoInitial.photos;
            this.reportagePhoto.typePreset = this.reportagePhotoInitial.typePreset;
            this.reportagePhoto.autoGenerated = this.reportagePhotoInitial.autoGenerated;
        }
        this.goBack();
    }

    /**
     * Ferme la modale et revient sur l'écran précédent.
     * Renvoie le diagnostic mis à jour si il est envoyé en paramètre
     * @param diagnostic
     */
    private goBack(diagnostic?: Diagnostic) {
        this.dialogRef.close(diagnostic ? diagnostic : null);
    }

    /**
     * Prépare la liste des légendes à partir des commentaires, prélèvements
     * @private
     */
    private prepareLegends() {
        if (this.diagnostic.typePrestation.includes('HAP')) {
            this.reportagePhotoImageFiles = this.reportagePhotoService.preparePrelevements(this.diagnostic, this.reportagePhotoImageFiles);
        }

        this.reportagePhotoImageFiles.forEach((p) => {
            // Si la photo ne possède pas déjà de légende, on cherche le contenu du commentaire rattaché
            if (!!!p.legende) {
                p.legende = this.intervention.commentaires.find((c) => c.imageId === p.fileId)?.contenu;
            }
        });
    }

    private changePreset(event: MatOptionSelectionChange<PresetPhoto>) {
        const filter = this.reportagePhotoService.getCorrespondanceTypeCommentaire(event.source.value);
        if (filter) {
            if (filter === 'PRELEVEMENTS') {
                const pvtsImgs = this.reportagePhotoService.getPicturesFromPrelevements(this.diagnostic);
                this.reportagePhotoImageFiles.forEach((p) => {
                    p.selected = !!pvtsImgs.includes(p.fileId);
                    this.onSelectImageFile(p);
                });
            } else {
                this.reportagePhotoImageFiles.forEach((p) => {
                    p.selected = !!this.intervention.commentaires.filter((c) => c.type === filter)?.find((c) => c.imageId === p.fileId);
                    this.onSelectImageFile(p);
                });
            }
        }
    }

    onAutoGeneratedChanged(event: boolean) {
        this.reportagePhoto.autoGenerated = event;
        this.form.patchValue({
            isSubscribing: false,
        });
        this.form.updateValueAndValidity();
    }

    isSubscribingModified(checked: boolean) {
        if (!checked) {
            this.confirmationService.confirmWarn(
                'Ce reportage ne sera plus mis à jour automatiquement si vous le modifiez manuellement.',
                () => {
                    this.form.patchValue({
                        isSubscribing: false,
                    });
                    this.form.updateValueAndValidity();
                },
                () => {
                    this.form.patchValue({
                        isSubscribing: true,
                    });
                    this.form.updateValueAndValidity();
                }
            );
        }
    }
}
