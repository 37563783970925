<div class="mpca-panel-list-card">
    <div class="header">
        <div class="font-weight-normal">Liste globales des MPCA</div>
        <div>
            <button mat-icon-button aria-label="modifier" [disabled]="!canShare">
                <mat-icon [ngClass]="{'mat-icon-mpca-header': canShare}" disabled>send</mat-icon>
            </button>
            <button
                mat-icon-button
                aria-label="supprimer"
                (click)="addNewMpca()"
                [disabled]="!canCreate"
                data-cy="ajouter-mpca-button"
            >
                <mat-icon [ngClass]="{'mat-icon-mpca-header': canCreate}">add</mat-icon>
            </button>
        </div>
    </div>
    <div class="content">
        <div class="item" *ngFor="let item of mpcaList; let index = index" (click)="selectItem($event, index)">
            <app-item-card [isSelected]="indexSelected === index">
                <div title class="title">{{item.general.materiauxClient}}</div>
                <div state>{{item.general.etatDegradation}}</div>
                <div action>
                    <button aria-label="copier" (click)="copyItem($event, item)" [disabled]="disabled">
                        <mat-icon>content_copy</mat-icon>
                    </button>
                    <button
                        aria-label="supprimer"
                        (click)="deleteItem($event, index)"
                        [disabled]="disabled"
                        class="warn"
                    >
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </app-item-card>
        </div>
    </div>
</div>
