import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-capture-webcam-images',
    templateUrl: './capture-webcam-images.html',
    styleUrls: ['./capture-webcam-images.scss'],
})
export class CaptureWebcamImages implements OnInit {
    @ViewChild('video') video: ElementRef;
    @ViewChild('canvas') canvas: any;

    capturedImage: string;
    showVideo: boolean = true;
    showCapturedImage: boolean = false;
    capturing: boolean = false;
    flashActive: boolean = false;
    rotateCameraActive: boolean = false;
    private videoStream: MediaStream;
    geo: GeolocationCoordinates;
    facingMode: string = 'environment';
    videoDevices: any;
    cameraOptions: string;
    isTorchSupported: boolean = false;

    zoomLevel: number = 1;
    minZoom: number = 1;
    maxZoom: number = 10;
    zoomStep: number = 0.5;
    zoomScale: number = 1;

    currentZoomLevel: number = 1;

    constructor(public dialogRef: MatDialogRef<CaptureWebcamImages>, private _snackBar: MatSnackBar) {
        dialogRef.disableClose = false;
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth600-dialog');
        this.getCameraSelection();
    }

    ngAfterViewInit() {
        this.startVideoStream();
    }

    startVideoStream() {
        const constraints: MediaStreamConstraints = {
            video: {
                width: 1280,
                height: 720,
                facingMode: this.facingMode,
                deviceId: {
                    exact: this.cameraOptions,
                },
            },
        };

        if (this.isTorchSupported) {
            constraints.video['torch'] = this.flashActive;
        }

        navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
                this.video.nativeElement.srcObject = stream;
                this.videoStream = stream;
                this.updateZoom();
            })
            .catch((error) => {
                console.error("Impossible d'accéder à la caméra: ", error);
                this._snackBar.open('Veuillez brancher votre caméra', '', {
                    duration: 2000,
                });
            });
    }

    capture() {
        this.getUserLocation();

        const video = this.video.nativeElement;
        const boxVideo = document.querySelector('.BoxVideo');

        // Utiliser clientWidth et clientHeight pour obtenir la taille visible de la BoxVideo
        const boxWidth = boxVideo.clientWidth;
        const boxHeight = boxVideo.clientHeight;

        // Initialize canvas avec la taille visible de la BoxVideo
        const canvas = document.createElement('canvas');
        canvas.width = boxWidth;
        canvas.height = boxHeight;

        // Calculer le rapport de zoom
        const zoomScale = this.zoomScale;

        // Calculer les coordonnées pour découper la partie visible avec le zoom
        const offsetX = (video.videoWidth - boxWidth / zoomScale) / 2;
        const offsetY = (video.videoHeight - boxHeight / zoomScale) / 2;

        // Dessiner l'image visible de la vidéo sur le canvas
        const context = canvas.getContext('2d');
        context.drawImage(video, offsetX, offsetY, boxWidth / zoomScale, boxHeight / zoomScale, 0, 0, boxWidth, boxHeight);

        this.capturedImage = canvas.toDataURL('image/jpeg');

        this.showVideo = false;
        this.showCapturedImage = true;
        this.capturing = true;

        this.stopVideoStream();
    }

    confirm() {
        this.stopVideoStream();
        this.dialogRef.close();
    }

    async Enregistrer() {
        if (this.capturedImage === '' || this.capturedImage === undefined) {
            this._snackBar.open("Veuillez prendre une photo avant d'enregistrer", '', {
                duration: 2000,
            });
        } else {
            let fichier: File = this.dataURItoFile(this.capturedImage, 'capture.jpg');
            console.log(fichier);
            this.stopVideoStream();
            let fileExif: {
                fichier: File;
                geo: any;
                captureDate: any;
            };
            if (this.geo === undefined) {
                fileExif = {
                    fichier: fichier,
                    geo: undefined,
                    captureDate: Date.now(),
                };
            } else {
                fileExif = {
                    fichier: fichier,
                    geo: this.geo,
                    captureDate: Date.now(),
                };
            }
            this.dialogRef.close(fileExif);
            console.log(fichier);
        }
    }

    ReCapture() {
        this.showVideo = true;
        this.showCapturedImage = false;
        this.capturing = false;
        this.currentZoomLevel = 1;
        this.startVideoStream();
    }

    stopVideoStream() {
        if (this.videoStream) {
            this.videoStream.getTracks().forEach((track) => track.stop());
            this.videoStream = null;
        }
    }

    dataURItoFile(dataURI: string, fileName: string): File {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        let file = new File([ab], fileName, { type: mimeString });
        return file;
    }

    public getUserLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.geo = position.coords;
            });
        } else {
            console.log('User not allowed');
            this.geo = undefined;
        }
    }

    toggleFlash() {
        this._snackBar.open("Le flash n'est pas encore fonctionnel", '', {
            duration: 2000,
        });
        //this.flashActive = !this.flashActive;
        //this.startVideoStream();
    }

    rotateCamera() {
        this.stopVideoStream();
        if (this.videoDevices && this.videoDevices.length > 1) {
            this.rotateCameraActive = !this.rotateCameraActive;
            if (this.rotateCameraActive) {
                this.cameraOptions = this.videoDevices[1].deviceId || this.videoDevices[0].deviceId;
            } else {
                this.cameraOptions = this.videoDevices[0].deviceId;
            }
            this.startVideoStream();
        } else {
            this._snackBar.open("Il n'y a qu'une seul camera de disponible", '', {
                duration: 2000,
            });
            this.startVideoStream();
        }
    }

    getCameraSelection = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        this.videoDevices = devices.filter((device) => device.kind === 'videoinput');

        const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
        this.isTorchSupported = 'torch' in supportedConstraints;
    };

    zoomIn() {
        if (this.currentZoomLevel < this.maxZoom) {
            this.currentZoomLevel += this.zoomStep;
            this.updateZoom();
        }
    }

    zoomOut() {
        if (this.currentZoomLevel > this.minZoom) {
            this.currentZoomLevel -= this.zoomStep;
            this.updateZoom();
        }
    }

    updateZoom() {
        this.zoomScale = Math.pow(2, this.currentZoomLevel - 1);

        this.video.nativeElement.style.transform = `scale(${this.zoomScale})`;
    }
}
