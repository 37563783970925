import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { OuvrageAControler } from '../../../../../model/categorie-ouvrage.model';
import { MatDialog } from '@angular/material/dialog';
import { DescriptionBienModalOuvragesEditNomComponent } from '../description-bien-modal-ouvrages-edit-nom/description-bien-modal-ouvrages-edit-nom.component';

@Component({
    selector: 'app-description-bien-modal-ouvrages-parametres',
    templateUrl: './description-bien-modal-ouvrages-parametres.component.html',
    styleUrls: ['./description-bien-modal-ouvrages-parametres.component.scss'],
})
export class DescriptionBienModalOuvragesParametresComponent extends BaseComponent {
    @Input()
    readonlyMode: boolean = false;

    @Input() set ouvrageToEdit(newOuvrage: OuvrageAControler) {
        this._ouvrageToEdit = newOuvrage;
    }

    @Input()
    listNomsOuvrages: string[];

    @Input()
    listOuvragesCurrentVolume: OuvrageAControler[];

    @Output()
    ouvrageToEditChange: EventEmitter<OuvrageAControler> = new EventEmitter<OuvrageAControler>();

    get ouvrageToEdit(): OuvrageAControler {
        return this._ouvrageToEdit;
    }

    private _ouvrageToEdit: OuvrageAControler;

    constructor(private readonly dialog: MatDialog) {
        super();
    }

    handleEditNomOuvrage() {
        this.dialog
            .open(DescriptionBienModalOuvragesEditNomComponent, {
                data: {
                    ouvrageToEdit: this.ouvrageToEdit,
                    listNomsOuvrages: this.listNomsOuvrages,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.ouvrageToEdit.nom = result;
                }
            });
    }
}
