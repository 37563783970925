import { ITypePrestationInterface } from '../../../../services/interfaces/type-prestation.interface';
import { TypePrestation, TypePrestationEnum } from '../../../../model/type-prestation.model';
import { Intervention } from '../../../../model/intervention.model';
import { Injectable, Type } from '@angular/core';
import { AssainissementService } from './assainissement.service';
import {
    Assainissement,
    AssainissementReportData,
    Caracteristique,
    CaracteristiquesData,
    ItemElementAssainissement,
    RowBienAssainissement,
} from '../model/assainissement.model';
import { ContenuDiagnostic } from '../../../../model/diagnostic-contenu.model';
import {
    CODE_BIM_TYPE_ELEMENT_EQUIPEMENTS_SANITAIRES,
    CODE_BIM_TYPE_ELEMENT_RESEAUX_EVACUATION,
    PARAM_VOLUME_VISITE,
    URL_MS_REPORT_FILE,
} from '../../../../shared/constants/cndiag.constants';
import { VisibiliteEquipementsBlockComponent } from '../report/blocks/visibilite-equipements-block/visibilite-equipements-block.component';
import { Diagnostic } from '../../../../model/diagnostic.model';
import { IDiagReportData, InterventionReportData } from '../../../../model/rapport.model';
import { ListeVolumesBlockComponent } from '../report/blocks/liste-volumes-block/liste-volumes-block.component';
import { GrilleControleBlockComponent } from '../report/blocks/grille-controle-block/grille-controle-block.component';
import { Rule } from '../../../../model/regle.model';
import { CommentairesBlockComponent } from '../report/blocks/commentaires-block/commentaires-block.component';
import { CaracteristiquesBlockComponent } from '../report/blocks/caracteristiques-block/caracteristiques-block.component';
import { Commentaire } from '../../../../model/commentaire.model';
import { CommentairePredefini } from '../../../../model/commentaire-predefini.model';
import { CommentUtils } from '../../../../utils/comment.utils';
import { ConclusionBlockComponent } from '../report/blocks/conclusion-block/conclusion-block.component';
import {
    PARAM_ELEMENT_ABSENCE_NUISANCE,
    PARAM_ELEMENT_ADAPTATION,
    PARAM_ELEMENT_CONTROLE,
    PARAM_ELEMENT_ECOULEMENT,
    PARAM_ELEMENT_ETANCHEITE,
    PARAM_ELEMENT_ETAT,
    PARAM_ELEMENT_MATERIAUX,
    PARAM_ELEMENT_ODEUR,
    PARAM_ELEMENT_REJET,
    PARAM_ELEMENT_VALORISATION,
} from '../shared/assainissement.constants';
import { PointDeControleNiveau } from '../../../../model/point-de-controle.model';
import { Bien, Niveau, Volume } from '../../../../model/bien.model';
import { Conformite } from '../../../../model/conformite.model';
import { ReportagePhotoService } from '../../../../services/reportage-photo.service';
import { TypeReport } from 'src/app/model/reference-prestation.model';
import { BonCommandeAnalyseAdmin } from '../../../../model/bon-commande.model';
import { Observable, of } from 'rxjs';
import { LegendScreenshot } from '../../../../model/screenshot-svg.model';
import { cn_storey } from '@acenv/cnmap-editor';
import { PointDeControleService } from '../../../../services/point-de-controle.service';
import { TypeVolume } from '../../../../model/type-element-a-controler.model';

/**
 * Liste des Type de component (Class) de block de rapport disponible pour la composition
 * du contenu d'un raport
 */
export const REPORT_BLOCK_CATALOG: { [key: string]: Type<any> } = {
    VisibiliteEquipementsBlockComponent: VisibiliteEquipementsBlockComponent,
    ListeVolumesBlockComponent: ListeVolumesBlockComponent,
    GrilleControleBlockComponent: GrilleControleBlockComponent,
    CommentairesBlockComponent: CommentairesBlockComponent,
    CaracteristiquesBlockComponent: CaracteristiquesBlockComponent,
    ConclusionBlockComponent: ConclusionBlockComponent,
};

@Injectable({
    providedIn: 'root',
})
export class TypeAssainissementService implements ITypePrestationInterface {
    // PARAM volume
    readonly paramVolumeVisite = PARAM_VOLUME_VISITE;
    readonly params = [
        PARAM_VOLUME_VISITE,
        PARAM_ELEMENT_ADAPTATION,
        PARAM_ELEMENT_ETANCHEITE,
        PARAM_ELEMENT_VALORISATION,
        PARAM_ELEMENT_ABSENCE_NUISANCE,
        PARAM_ELEMENT_ETAT,
        PARAM_ELEMENT_ODEUR,
        PARAM_ELEMENT_ECOULEMENT,
        PARAM_ELEMENT_REJET,
        PARAM_ELEMENT_CONTROLE,
        PARAM_ELEMENT_MATERIAUX,
    ];

    private typePrestation: TypePrestation = TypePrestationEnum.ASSAINISSEMENT;

    constructor(
        private readonly assainissementService: AssainissementService,
        private readonly reportagePhotoService: ReportagePhotoService,
        private readonly pointDeControleService: PointDeControleService
    ) {}

    /**
     * Pas d'implémentation car pas de bon de commande en Assainissement
     * @param intervention
     * @param diagnostic
     */
    getDiagnosticBonCommandeData(intervention: Intervention, diagnostic: Diagnostic): IDiagReportData {
        throw new Error('Method not implemented.');
    }

    getTypePrestation(): TypePrestation {
        return this.typePrestation;
    }

    prepareForm(intervention: Intervention, contenuDiagnostic: ContenuDiagnostic) {
        this.assainissementService.prepareFormAssainissement(intervention, contenuDiagnostic as Assainissement);
    }

    getContenuDiagnosticFromParent(diagnosticToUpdate: Diagnostic, diagnosticParent: Diagnostic): ContenuDiagnostic {
        return { ...diagnosticParent.contenuDiagnostic };
    }

    getContenuDiagnostic(typePrestation: TypePrestation): Observable<ContenuDiagnostic> {
        return of(new Assainissement());
    }

    getCodeBimEquipementBien(typePrestation: TypePrestation): string[] {
        return [CODE_BIM_TYPE_ELEMENT_EQUIPEMENTS_SANITAIRES, CODE_BIM_TYPE_ELEMENT_RESEAUX_EVACUATION];
    }

    /**
     * Préparation des commentaires spécifiques à l'assainissement selon le type de réponse des formulaires
     * @param diagnostic
     * @param commentairesGeneraux
     * @param commentaires
     */
    prepareSpecificComments(diagnostic: Diagnostic, commentairesGeneraux: Commentaire[], commentaires: CommentairePredefini[]): Commentaire[] {
        const currentContenuDiagnostic = diagnostic.contenuDiagnostic as Assainissement;
        if (currentContenuDiagnostic.alimentationEnEau.valeur !== 'absent') {
            commentaires = CommentUtils.filterCommentByName('alimentation_en_eau_absence', commentairesGeneraux, commentaires);
        }
        if (currentContenuDiagnostic.reseauPublic.valeur !== 'absent') {
            commentaires = CommentUtils.filterCommentByName('desservi_par_reseau_public_absence', commentairesGeneraux, commentaires);
        }
        return commentaires.map((it) => CommentUtils.commentairePredefiniToCommentaireMapper(it));
    }

    getReportBlockType(componentName: string): Type<any> {
        const blockType = REPORT_BLOCK_CATALOG[componentName];
        if (!blockType) {
            console.log('Block %s not found', componentName);
        }
        return blockType;
    }

    getDiagnosticReportData(intervention: Intervention, diagnostic: Diagnostic, regles: Rule[], optionPlan: boolean): IDiagReportData {
        const diagReportData = new AssainissementReportData();
        diagReportData.id = diagnostic.id;
        diagReportData.typePrestation = diagnostic.typePrestation;
        diagReportData.optionPlan = optionPlan;
        const contenuDiagnostic = diagnostic.contenuDiagnostic as Assainissement;
        diagReportData.ageInstallation = contenuDiagnostic.ageInstallation.valeur;
        diagReportData.refRapport = diagnostic.reportDatas.find((reportDataTemp) => reportDataTemp.typeReport === TypeReport.REPORT).refRapport;
        this.prepareDiagnosticCaracteristics(contenuDiagnostic, diagReportData, intervention);

        // Documents
        const docsPresents = intervention.documents.filter((it) => it.idFichier !== undefined).map((it) => it.typeDocument.code);
        diagReportData.caracteristiques.documentsImplantation = new Caracteristique(
            "Documents permettant de déterminer l'implantation du système",
            this.isDocumentPresent(docsPresents, 'IMPLANTATION_INSTALLATION'),
            []
        );
        diagReportData.caracteristiques.documentsCaracteristique = new Caracteristique(
            'Documents permettant de déterminer les caractéristiques du système',
            this.isDocumentPresent(docsPresents, 'REGLEMENT_ASSAINISSEMENT'),
            []
        );
        if (diagnostic.recommandationsFinales.length) {
            diagReportData.recommandations = diagnostic.recommandationsFinales.map((it) => it.contenu);
        }
        if (diagnostic.constatationsFinales.length) {
            diagReportData.constatationsDiverses = diagnostic.constatationsFinales.map((it) => it.contenu);
        }

        diagReportData.reportagesPhotos = this.reportagePhotoService.buildReportagePhotoData(diagnostic);

        // Screenshot
        diagReportData.screenshotsPlan = diagnostic.screenshotsPlan.map((screenshot) => ({
            ...screenshot,
            jsonPlan: `${URL_MS_REPORT_FILE}${screenshot.jsonPlan}`,
        }));

        this.assainissementService.prepareDiagnosticReportData(diagReportData, diagnostic, intervention, regles);
        return diagReportData;
    }

    getCompletionPercentage(diagnostic: Diagnostic): number {
        // Elements
        const elements = diagnostic.pointsDeControleBiens
            .flatMap((bien) => bien.pointsDeControleNiveaux)
            .flatMap((niveau) => niveau.pointsDeControleVolumes)
            .flatMap((volume) => volume.pointsDeControleElements);
        const totalCp = elements.length;
        const completeCp = elements.filter(
            (elt) =>
                !elt.displayError &&
                (elt.conformite === Conformite.CONFORME ||
                    elt.conformite === Conformite.NON_CONFORME ||
                    (elt.conformite === Conformite.A_JUSTIFIER && elt.justifie))
        ).length;

        // Visite de volumes (on ne compte pas les volumes cachés)
        const volumes = diagnostic.pointsDeControleBiens
            .flatMap((bien) => bien.pointsDeControleNiveaux)
            .flatMap((niveau) => niveau.pointsDeControleVolumes)
            .filter((it) => !it.volumeCache);
        const totalRoom = volumes.length;
        const completeRoom = volumes.filter(
            (v) =>
                v.valeursParametres[this.paramVolumeVisite] === 'ok' ||
                (['ko', 'warning'].includes(v.valeursParametres[this.paramVolumeVisite]) && v.justifie)
        ).length;

        return ((completeCp + completeRoom) * 100) / (totalCp + totalRoom);
    }

    prepareFilteredCommentsForReport(diagnosticData: IDiagReportData, hiddenComments: Map<string, string[]>) {
        const diagReportData = diagnosticData as AssainissementReportData;
        if (hiddenComments[diagReportData.equipementsEU.code]) {
            this.filterEquipementComments(diagReportData.equipementsEU.biens, hiddenComments[diagReportData.equipementsEU.code]);
        }
        if (hiddenComments[diagReportData.raccordementEU.code]) {
            this.filterEquipementComments(diagReportData.raccordementEU.biens, hiddenComments[diagReportData.raccordementEU.code]);
        }
        if (hiddenComments[diagReportData.nonVisibles.code]) {
            this.filterEquipementComments(diagReportData.nonVisibles.biens, hiddenComments[diagReportData.nonVisibles.code]);
        }
        if (hiddenComments[diagReportData.raccordementEP.code]) {
            this.filterEquipementComments(diagReportData.raccordementEP.biens, hiddenComments[diagReportData.raccordementEP.code]);
        }
        if (hiddenComments[diagReportData.equipementsEP.code]) {
            this.filterEquipementComments(diagReportData.equipementsEP.biens, hiddenComments[diagReportData.equipementsEP.code]);
        }
        if (hiddenComments[diagReportData.constatationsSpecifiques.code]) {
            this.filterEquipementComments(
                diagReportData.constatationsSpecifiques.biens,
                hiddenComments[diagReportData.constatationsSpecifiques.code]
            );
        }
        if (hiddenComments[diagReportData.volumesNonVisites.code]) {
            this.filterVolumeComments(diagReportData.volumesNonVisites.biens, hiddenComments[diagReportData.volumesNonVisites.code]);
        }
        if (hiddenComments[diagReportData.volumesVisites.code]) {
            this.filterVolumeComments(diagReportData.volumesVisites.biens, hiddenComments[diagReportData.volumesVisites.code]);
        }
    }

    /**
     * Vérifie si un bien/niveau/volmume/élément a déjà complété dans les checkpoints
     */
    isItemAlreadyFilled(diagnostic: Diagnostic, type: string, itemId: string): boolean {
        let used = false;
        let pointsDeControle = [];
        if (diagnostic.pointsDeControleBiens.length) {
            switch (type) {
                case 'element':
                    pointsDeControle = diagnostic.pointsDeControleBiens
                        .flatMap((bien) => bien.pointsDeControleNiveaux)
                        .flatMap((niveau) => niveau.pointsDeControleVolumes)
                        .flatMap((volume) => volume.pointsDeControleElements);
                    const elementAVerifier = pointsDeControle.find((it) => it.idElement === itemId);
                    if (elementAVerifier) {
                        // paramètres de checkpoint
                        for (const p of this.params) {
                            if (elementAVerifier.valeursParametres[p]) {
                                used = true;
                                return used;
                            }
                        }
                    }
                    break;

                // Seules les pièces visitables peuvent être supprimées directement depuis l'écran description.
                // Les volumes cachés ne peuvent pas être effacés directement.
                case 'volume':
                    pointsDeControle = diagnostic.pointsDeControleBiens
                        .flatMap((bien) => bien.pointsDeControleNiveaux)
                        .flatMap((niveau) => niveau.pointsDeControleVolumes);
                    const volumeAVerifier = pointsDeControle.find((it) => it.idVolume === itemId);
                    if (volumeAVerifier) {
                        // visite
                        if (volumeAVerifier.valeursParametres[this.paramVolumeVisite]) {
                            used = true;
                            return used;
                        }
                    }
                    break;

                case 'niveau':
                    pointsDeControle = diagnostic.pointsDeControleBiens.flatMap((bien) => bien.pointsDeControleNiveaux);
                    const niveauAVerifier = pointsDeControle.find((it) => it.idNiveau === itemId);
                    if (niveauAVerifier) {
                        used = this.isNiveauAlreadyUsed(niveauAVerifier, used);
                    }
                    break;

                case 'bien':
                    pointsDeControle = diagnostic.pointsDeControleBiens;
                    const bienAVerifier = pointsDeControle.find((it) => it.idBien === itemId);
                    if (bienAVerifier) {
                        for (const niveau of bienAVerifier.pointsDeControleNiveaux) {
                            used = this.isNiveauAlreadyUsed(niveau, used);
                            if (used) {
                                return used;
                            }
                        }
                    }
                    break;
            }
        }
        return used;
    }

    isDocumentPresent(documentsPresents: string[], code: string): ItemElementAssainissement {
        const item = new ItemElementAssainissement('n.c.', null);
        const value = documentsPresents.includes(code);
        if (value === true) {
            item.valeur = 'Oui';
            item.style = 'conforme';
        } else if (value === false) {
            item.valeur = 'Non';
            item.style = 'non-conforme';
        }
        return item;
    }

    /**
     * Vérifie si un niveau possède au moins un volume visité, ou un équipement déjà complété dans les checkpoints
     */
    private isNiveauAlreadyUsed(niveau: PointDeControleNiveau, used: boolean): boolean {
        if (niveau.pointsDeControleVolumes.length) {
            const visites = niveau.pointsDeControleVolumes.filter((it) => !it.volumeCache && it.valeursParametres[this.paramVolumeVisite]);
            // visite si non caché
            if (visites.length) {
                used = true;
                return used;
            }
            // éléments si cachés
            const volumeCache = niveau.pointsDeControleVolumes.find((it) => it.volumeCache);
            if (volumeCache && volumeCache.pointsDeControleElements.length) {
                for (const el of volumeCache.pointsDeControleElements) {
                    // paramètres de checkpoint
                    for (const p of this.params) {
                        if (el.valeursParametres[p]) {
                            used = true;
                            return used;
                        }
                    }
                }
            }
        }
    }

    private filterEquipementComments(biens: RowBienAssainissement[], excluded: string[]) {
        biens.forEach((bien) => {
            bien.niveaux.forEach((niveau) => {
                niveau.volumes.forEach((volume) => {
                    volume.equipements.forEach((eq) => {
                        eq.commentaires = eq.commentaires.filter((it) => !excluded.includes(it.id));
                    });
                });
            });
        });
    }

    private filterVolumeComments(biens: RowBienAssainissement[], excluded: string[]) {
        biens.forEach((bien) => {
            bien.niveaux.forEach((niveau) => {
                niveau.volumes.forEach((volume) => {
                    volume.commentaires = volume.commentaires.filter((it) => !excluded.includes(it.id));
                });
            });
        });
    }

    private prepareDiagnosticCaracteristics(contenuDiagnostic: Assainissement, diagReportData: AssainissementReportData, intervention: Intervention) {
        diagReportData.typeAssainissementCommunataire = contenuDiagnostic.assainissementPublic.valeur;
        const volumes = intervention.relationInterventionBiens.flatMap((bien) => bien.bien.description).flatMap((niveau) => niveau.volumes);
        const caracteristiques = new CaracteristiquesData();
        // Type de bâtiment
        const bienPrincipal = intervention.relationInterventionBiens.find((b) => b.isBienPrincipal === true).bien;
        caracteristiques.typeBatiment = new Caracteristique('Type de bâtiment', new ItemElementAssainissement(bienPrincipal.idTypeBien, null), [
            bienPrincipal.nom,
        ]);

        // Alimentation en eau
        caracteristiques.alimentationEau = new Caracteristique(
            "Système est alimenté en eau lors de l'intervention",
            new ItemElementAssainissement(
                this.translateValue(contenuDiagnostic.alimentationEnEau.valeur),
                this.getStyleFromValue(contenuDiagnostic.alimentationEnEau.valeur)
            ),
            []
        );

        // Type de réseau
        caracteristiques.typeReseau = new Caracteristique(
            'Type de réseau',
            new ItemElementAssainissement(
                contenuDiagnostic.assainissementCollectif.valeur
                    ? contenuDiagnostic.assainissementCollectif.valeur.charAt(0).toUpperCase() +
                      contenuDiagnostic.assainissementCollectif.valeur.slice(1)
                    : 'n.c.',
                null
            ),
            []
        );

        // Réseau public eau potable
        caracteristiques.reseauPublicEauPotable = new Caracteristique(
            "Terrain est desservi par un réseau public d'eau potable",
            new ItemElementAssainissement(
                this.translateValue(contenuDiagnostic.reseauPublic.valeur),
                this.getStyleFromValue(contenuDiagnostic.reseauPublic.valeur)
            ),
            []
        );

        // Captage d'eau
        caracteristiques.captageEau = new Caracteristique(
            "Captage d'eau",
            new ItemElementAssainissement(
                this.translateValue(contenuDiagnostic.captageEau.valeur),
                this.getStyleFromValue(contenuDiagnostic.captageEau.valeur)
            ),
            contenuDiagnostic.captageEau.elements.length
                ? contenuDiagnostic.captageEau.elements.map((el) => (el.situation ? this.getVolumeNameFromId(el.situation, volumes) : ''))
                : []
        );

        // Poste de refoulement
        caracteristiques.posteRefoulement = new Caracteristique(
            'Poste de refoulement',
            new ItemElementAssainissement(
                contenuDiagnostic.pompesDeRelevage.elements.length > 0 ? 'Oui' : 'Non',
                contenuDiagnostic.pompesDeRelevage.elements.length > 0 ? 'conforme' : 'non-conforme'
            ),
            contenuDiagnostic.pompesDeRelevage.elements.length
                ? contenuDiagnostic.pompesDeRelevage.elements.map((el) => (el.situation ? this.getVolumeNameFromId(el.situation, volumes) : ''))
                : []
        );

        // Clapet anti retours
        caracteristiques.clapetAntiRetourContrebas = new Caracteristique(
            'Clapet anti-retour en contrebas',
            new ItemElementAssainissement(
                contenuDiagnostic.clapetsAntiretour.elements.length > 0 ? 'Oui' : 'Non',
                contenuDiagnostic.clapetsAntiretour.elements.length > 0 ? 'conforme' : 'non-conforme'
            ),
            contenuDiagnostic.clapetsAntiretour.elements.length
                ? contenuDiagnostic.clapetsAntiretour.elements.map((el) => (el.situation ? this.getVolumeNameFromId(el.situation, volumes) : ''))
                : []
        );

        // Ancienne fosse
        caracteristiques.ancienneFosse = new Caracteristique(
            "Présence d'une ancienne fosse septique",
            new ItemElementAssainissement(
                this.translateValue(contenuDiagnostic.anciennesFosses.valeur),
                this.getStyleFromValue(contenuDiagnostic.anciennesFosses.valeur)
            ),
            contenuDiagnostic.anciennesFosses.elements.length
                ? contenuDiagnostic.anciennesFosses.elements.map((el) => (el.situation ? this.getVolumeNameFromId(el.situation, volumes) : ''))
                : []
        );

        // Ventilation générale
        caracteristiques.ventilationGenerale = new Caracteristique(
            "Présence d'une ventilation générale",
            new ItemElementAssainissement(
                this.translateValue(contenuDiagnostic.ventilationsGenerale.valeur),
                this.getStyleFromValue(contenuDiagnostic.ventilationsGenerale.valeur)
            ),
            contenuDiagnostic.ventilationsGenerale.elements.length
                ? contenuDiagnostic.ventilationsGenerale.elements.map((el) => (el.situation ? this.getVolumeNameFromId(el.situation, volumes) : ''))
                : []
        );

        diagReportData.caracteristiques = caracteristiques;
    }

    private translateValue(value: string): string {
        let valueToDisplay = 'n.c.';
        if (value === 'present') {
            valueToDisplay = 'Oui';
        } else if (value === 'absent') {
            valueToDisplay = 'Non';
        }
        return valueToDisplay;
    }

    private getStyleFromValue(value: string): string {
        let valueToDisplay = '';
        if (value === 'present') {
            valueToDisplay = 'conforme';
        } else if (value === 'absent') {
            valueToDisplay = 'non-conforme';
        }
        return valueToDisplay;
    }

    private getVolumeNameFromId(id: string, volumes: Volume[]): string {
        const volume = volumes.find((v) => v.id === id);
        return volume ? volume.nom : 'n.c';
    }

    generateDiagnosticBonCommande(
        intervention: Intervention,
        diagnostic: Diagnostic,
        interReportData: InterventionReportData
    ): BonCommandeAnalyseAdmin {
        return undefined;
    }

    generateLegendForScreenshot(diagnostic: Diagnostic): LegendScreenshot[] {
        return [];
    }

    prepareStoreyForScreenshot(diagnostic: Diagnostic, currentStorey: cn_storey, conf: any) {}

    deplaceVolume(diagnostic: Diagnostic, volumeSource: Volume, niveauDestination: Niveau, currentBien: Bien) {
        this.pointDeControleService.deplaceVolume(diagnostic, volumeSource, niveauDestination, currentBien);
    }

    mergeNiveau(diagnostic: Diagnostic, niveauSource: Niveau, niveauDestination: Niveau, currentBien: Bien) {
        this.pointDeControleService.mergeNiveau(diagnostic, niveauSource, niveauDestination, currentBien);
    }

    mergeVolume(diagnostic: Diagnostic, volumeSource: Volume, volumeDestination: Volume, currentBien: Bien, typeVolume: TypeVolume) {
        this.pointDeControleService.mergeVolume(diagnostic, volumeSource, volumeDestination, currentBien, typeVolume);
    }

    deplaceEquipement(idEquipement: string, diagnostic: Diagnostic, volumeDestination: Volume, bien: Bien) {
        this.pointDeControleService.deplaceEquipement(idEquipement, diagnostic, volumeDestination, bien);
    }
}
