<h1 mat-dialog-title class="primary">Validation de la partie terrain</h1>

<div mat-dialog-content>
    <div class="to-justify d-flex justify-content-between align-items-center" *ngIf="!hasSignature">
        Vous n'avez pas encore associé de signature à votre compte. Vous ne pouvez donc pas valider vos prestations
        terminées.
    </div>

    <div *ngIf="hasSignature">
        <p>Je déclare le diagnostic :</p>

        <mat-button-toggle-group
            appearance="legacy"
            class="toggle-group-etat-diag"
            [(value)]="etatDiagnosticSelection"
            (change)="onChangeEtatDiagnostic($event)"
        >
            <mat-button-toggle [value]="enumEtatDiagnostic.EN_ATTENTE" class="toggle-etat-diag blue-toggle">
                {{ enumEtatDiagnostic.EN_ATTENTE | etatDiagnostic }}
            </mat-button-toggle>
            <mat-button-toggle
                [value]="enumEtatDiagnostic.FINI"
                [ngClass]="docsIsInvalid ? 'disabled-toggle' : 'blue-toggle'"
                [disabled]="docsIsInvalid"
            >
                {{ enumEtatDiagnostic.FINI | etatDiagnostic }}
            </mat-button-toggle>
        </mat-button-toggle-group>

        <ng-container *ngIf="etatDiagnosticSelection === enumEtatDiagnostic.FINI">
            <p>La prestation est totalement finie et vous validez la signature.</p>
        </ng-container>

        <ng-container *ngIf="etatDiagnosticSelection === enumEtatDiagnostic.EN_ATTENTE">
            <div>
                <app-custom-select
                    [choices]="reasonsList"
                    [placeholder]="'Raison de l\'attente'"
                    [matFormFieldClass]="'w-75'"
                    [(selectedValue)]="raisonsAttenteSelection"
                    (selectedValueChange)="checkValidateIsPossible()"
                    [sorted]="true"
                    [multiple]="true"
                    [addline]="false"
                    [filter]="false"
                ></app-custom-select>
            </div>
        </ng-container>
    </div>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-button color="primary" (click)="cancel()">
        <lib-icon>close</lib-icon>
        <span>{{ 'Annuler' | uppercase }}</span>
    </button>
    <button mat-raised-button color="accent" *ngIf="hasSignature" (click)="confirm()" [disabled]="!validateIsPossible">
        <lib-icon>check</lib-icon>
        <span>{{ 'Valider' | uppercase }}</span>
    </button>
    <button mat-raised-button color="accent" *ngIf="!hasSignature" (click)="onClickBtnAddSignature()">
        <lib-icon [theme]="'mdi'" [iconName]="'signature-freehand'"></lib-icon>
        <span>{{ 'Ajouter ma signature' | uppercase }}</span>
    </button>
</div>
