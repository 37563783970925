import { Component, Injector, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { IValidatableComponent } from '../../../../services/interfaces/validatable-component.interface';
import { PolluantComponent } from '../../../../utils/polluant-component';
import { ActivatedRoute, Router } from '@angular/router';
import { Estimation } from '../../../../model/estimation.model';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Polluant } from '../../../../model/polluant.model';
import { Besoin } from '../../../../model/besoin.model';
import { Zone } from 'src/app/model/zone.model';
import { combineLatest } from 'rxjs';
import { InterventionService } from '../../../../../../../services/intervention.service';
import { TypePrestationEnum } from 'src/app/model/type-prestation.model';
import { TypePrelevementAmiante } from 'src/app/model/prelevement-generique.model';
import { TypePolluantEtudeSituationService } from '../../../../services/type-polluant-etude-situation.service';
import { AuthenticationStore } from '../../../../../../../lib/authentication/authentication.store';
import { UserInformationApiService } from '../../../../../../../services/user-information-api.service';

@Component({
    selector: 'app-previsite',
    templateUrl: './previsite.component.html',
    styleUrls: ['./previsite.component.scss'],
})
export class PrevisiteComponent extends PolluantComponent implements OnInit, IValidatableComponent {
    TypePrestationEnum = TypePrestationEnum;

    diagPolluant: Polluant;
    form: FormGroup;
    isLoaded = false;
    erreursBesoin = true;
    userInformation;

    private typePolluantEtudeSituationService: TypePolluantEtudeSituationService;
    constructor(
        private readonly userInformationApiService: UserInformationApiService,
        private readonly authenticationStore: AuthenticationStore,
        private route: ActivatedRoute,
        private injector: Injector,
        private interventionService: InterventionService,
        private router: Router
    ) {
        super(injector);
        this.typePolluantEtudeSituationService = this.injector.get(TypePolluantEtudeSituationService);
    }

    ngOnInit(): void {
        combineLatest([
            this.authenticationStore.getCurrentUser().pipe(switchMap((user) => this.userInformationApiService.getUserInformationByUserId(user.id))),
            this.interventionService.getCurrentIntervention(),
            this.diagnosticService.getCurrentDiagnostic(),
            this.route.data,
        ])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([userInformation, intervention, diag, routerData]) => {
                this.userInformation = userInformation;
                this.diagnostic = diag;
                this.intervention = intervention;
                let estimation: Estimation;
                this.diagPolluant = diag.contenuDiagnostic as Polluant;
                this.isValidated = (this.diagPolluant as Polluant).estimations.validated;
                const diagEstimation = this.diagPolluant.estimations.data.estimation;
                const nbVentilateurs = this.diagPolluant.besoins.data.besoinsList.reduce((total: number, besoin: Besoin) => {
                    const zone = this.diagPolluant.zones.data.zonesList.find((z) => z.id === besoin.zoneId);
                    const presenceSurSite = ['Aucune', 'Locaux occasionnellement visités'].includes(zone?.frequentation);
                    return presenceSurSite && besoin?.objectifMesurage?.ventilateur === true ? ++total : total;
                }, 0);
                if (diagEstimation != undefined) {
                    estimation = diagEstimation;
                    estimation.strategie.nombreVentilateurs = nbVentilateurs;
                } else {
                    // Mise à jour de l'estimation avec les données du diagnostic
                    try {
                        estimation = new Estimation({
                            nombreBatiment: diag.pointsDeControleBiens.length,
                            nombreZones: this.diagPolluant.zones.data.zonesList.length,
                            nombreMeta: this.diagPolluant.besoins.data.besoinsList.filter(
                                (b) => b.objectifMesurage.typePrelevement == TypePrelevementAmiante.META
                            ).length,
                            nombreMetop: this.diagPolluant.besoins.data.besoinsList.filter(
                                (b) => b.objectifMesurage.typePrelevement == TypePrelevementAmiante.METOP
                            ).length,
                            nombreVentilateurs: nbVentilateurs,
                        });
                    } catch (e) {
                        this.isLoaded = true;
                        this.erreursBesoin = true;
                        return;
                    }
                }

                //Création du formulaire
                this.form = this.formBuilder.group({
                    strategie: this.formBuilder.group({
                        nombreBatiment: [estimation.strategie.nombreBatiment, []],
                        nombreZones: [estimation.strategie.nombreZones, []],
                        nombreMeta: [estimation.strategie.nombreMeta, []],
                        nombreMetop: [estimation.strategie.nombreMetop, []],
                        nombreVentilateurs: [estimation.strategie.nombreVentilateurs, []],
                        commentaire: [estimation.strategie.commentaire, []],
                    }),
                    premiereIntervention: this.formBuilder.group({
                        nombreBatiment: [estimation.premiereIntervention.nombreBatiment, []],
                        zones: [estimation.premiereIntervention.zones, [Validators.required]],
                        nombreMeta: [estimation.premiereIntervention.nombreMeta, []],
                        nombreMetop: [estimation.premiereIntervention.nombreMetop, []],
                        nombreVentilateurs: [estimation.premiereIntervention.nombreVentilateurs, []],
                        dureeVisiteHeures: [estimation.premiereIntervention.dureeVisiteHeures, []],
                        dureeVisiteMinutes: [estimation.premiereIntervention.dureeVisiteMinutes, []],
                        commentaire: [estimation.premiereIntervention.commentaire, []],
                    }),
                    listeCompetences: [estimation.listeCompetences, []],
                });

                // Lorsqu'on a un update sur un champ du formulaire, on va le valider puis sauvegarder en db
                this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
                    this.checkValidity();
                });

                // this.onZoneSelectChange();
                this.isInEditMode = !(!!routerData.readonly || this.diagnosticService.isReadOnlyMode(intervention, this.diagnostic));
                if (!this.isInEditMode) {
                    this.form.disable();
                }
                this.erreursBesoin = false;
                this.isLoaded = true;
            });
    }

    onZoneSelectChange = () => {
        //Liste des besoins en fonction des zones sélectionnées
        const selectedZones = this.form.get('premiereIntervention').get('zones').value;
        const filteredBesoins: Besoin[] = this.diagPolluant.besoins.data.besoinsList.filter((besoin: Besoin) => {
            return selectedZones.find((zone: Zone) => zone.id == besoin.zoneId) != undefined;
        });

        let nbMeta = 0;
        let nbMetop = 0;
        let nbVentilateurs = 0;

        filteredBesoins.forEach((besoin) => {
            //META METOP
            if (besoin.objectifMesurage?.typePrelevement == TypePrelevementAmiante.METOP) {
                nbMetop++;
            } else if (besoin.objectifMesurage?.typePrelevement == TypePrelevementAmiante.META) {
                nbMeta++;
            }

            //VENTILATEURS
            if (besoin.conditionPrelevement?.ventilateur == true) {
                nbVentilateurs++;
            }
        });

        this.form.get('premiereIntervention').patchValue({
            nombreMeta: nbMeta,
            nombreMetop: nbMetop,
            nombreVentilateurs: nbVentilateurs,
        });
    };

    private checkValidity() {
        this.diagPolluant.estimations.data.estimation = this.form.value;
        const code = this.route.snapshot.data['code'];
        this.etatProgressionService.updateDiagnostic(code, 'VOID', this.diagnostic);
    }

    //Interface IValidatableComponent implémentation
    cancelModification() {
        this.isInEditMode = false;
        this.form.patchValue(this.previousFormValue);
        this.previousFormValue = undefined;
    }

    saveModification() {
        this.isInEditMode = false;
        this.checkValidity();
    }

    validateTab() {
        this.isValidated = true;
        this.diagPolluant.estimations.validated = true;
        this.checkValidity();
    }

    startModification() {
        this.isInEditMode = true;
        this.previousFormValue = this.form.getRawValue();
    }

    navigateEcranBesoin() {
        const url = this.router.url.split('/');
        url.pop();
        url.pop();
        url.push('besoin');
        this.router.navigateByUrl(url.join('/'));
    }
}
