import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ROLE_ADMIN, User } from './user.model';

@Injectable()
export class AuthenticationStore {
    private currentUser$ = new BehaviorSubject<User>(undefined); // undefined => on ne sait pas encore si l'utilisateur est loggué ou non

    constructor() {}

    setCurrentUser(currentUser: User) {
        this.currentUser$.next(currentUser);
    }

    unsetCurrentUser() {
        this.currentUser$.next(null); // null => on sait que l'utilisateur n'est pas loggué
    }

    getAuthenticationState(): Observable<boolean> {
        return this.currentUser$.asObservable().pipe(
            filter((user) => user !== undefined),
            map((user) => !!user)
        );
    }

    getCurrentUser(): Observable<User> {
        return this.currentUser$.pipe(filter((user) => user !== undefined));
    }

    getCurrentUserSnapshot(): User {
        return this.currentUser$.getValue();
    }

    isAdmin(): Observable<boolean> {
        return this.currentUser$.pipe(
            filter((user) => user !== undefined),
            map((user) => (user ? user.authorities.includes(ROLE_ADMIN) : false))
        );
    }
}
