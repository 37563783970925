import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Bien, Niveau } from '../../../../model/bien.model';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DescriptionBienService } from '../../../../services/description-bien.service';

class NiveauModalData {
    constructor(public bien: Bien, public niveauToEdit: Niveau) {}
}

@Component({
    selector: 'app-add-niveau-modal',
    templateUrl: './add-niveau-modal.component.html',
    styleUrls: ['./add-niveau-modal.component.scss'],
})
export class AddNiveauModalComponent implements OnInit {
    niveauToEdit = new Niveau();
    newNiveau = true;
    niveaux = [];
    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<AddNiveauModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: NiveauModalData,
        private readonly descriptionBienService: DescriptionBienService,
        private formBuilder: FormBuilder
    ) {
        dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth600-dialog');
        this.niveaux = this.data.bien.description.map((n) => n.nom.toLocaleUpperCase().trim());

        this.form = this.formBuilder.group({
            nom: [
                this.descriptionBienService.createNiveauNom(this.data.bien.description),
                [
                    Validators.required,
                    // Validateur d'unicité de nom du niveau
                    (control: AbstractControl) => {
                        if (this.niveaux.includes(control.value.toLocaleUpperCase().trim())) {
                            return { erreurNomNiveau: true };
                        }
                        return null;
                    },
                ],
            ],
        });
        if (this.data.niveauToEdit) {
            this.newNiveau = false;
            this.form.patchValue({
                nom: this.data.niveauToEdit.nom,
            });
            this.form.get('nom').clearValidators();
            this.form.get('nom').setValidators([
                Validators.required,
                (control: AbstractControl) => {
                    if (
                        this.niveaux.includes(control.value.toLocaleUpperCase().trim()) &&
                        this.data.niveauToEdit.nom.toLocaleUpperCase().trim() !== control.value.toLocaleUpperCase().trim()
                    ) {
                        return { erreurNomNiveau: true };
                    }
                    return null;
                },
            ]);
            this.form.updateValueAndValidity();
        }
    }

    cancel() {
        this.dialogRef.close(false);
    }

    confirm() {
        this.niveauToEdit.nom = this.form.get('nom').value;
        this.dialogRef.close({ niveau: this.niveauToEdit });
    }
}
