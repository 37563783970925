import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/commons-lib';

@Component({
    selector: 'app-export-svg-subform-plan',
    templateUrl: './export-svg-subform-plan.component.html',
    styleUrls: ['./export-svg-subform-plan.component.scss'],
})
export class ExportSvgSubformPlanComponent extends BaseComponent implements OnInit {
    @Input() group: FormGroup;
    @Input() formConf: any = {};

    @Output() clickCenterView = new EventEmitter<any>();

    get planGeneralConf() {
        return this.formConf.plan.groups.general;
    }

    get planDisplayConf() {
        return this.formConf.plan.groups.display;
    }

    get planElementsConf() {
        return this.formConf.plan.groups.elements;
    }

    get planTextConf() {
        return this.formConf.plan.groups.text;
    }

    get planCotesConf() {
        return this.formConf.plan.groups.cotes;
    }

    renders = [
        { label: 'Couleur', value: 0 },
        { label: 'Gris', value: 1 },
        { label: 'Noir & Blanc', value: 2 },
    ];

    resolutions = [
        { label: 'Gras', value: 200 },
        { label: 'Normal', value: 400 },
        { label: 'Fin', value: 500 },
    ];

    constructor() {
        super();
    }

    ngOnInit(): void {}

    onClickCenterView(): void {
        this.clickCenterView.emit();
    }
}
