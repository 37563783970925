import { Bien, Niveau, Volume } from '../model/bien.model';
import { TypeVolume } from '../model/type-element-a-controler.model';
import { cn_building, cn_object_instance, cn_space, cn_storey } from '@acenv/cnmap-editor';
import {
    CODE_BIM_PARAM_ESPACE_CHAUFFE,
    CODE_BIM_PARAM_ESPACE_HUMIDE,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE,
    PARAM_VOLUME_LOT,
} from '../shared/constants/cndiag.constants';
import { ListUtils } from '../utils/list.utils';
import { Injectable } from '@angular/core';
import { Diagnostic } from '../model/diagnostic.model';
import { DiagnosticHandlerService } from './diagnostic-handler.service';
import { Equipement } from '../model/equipement.model';
import { group, MongoUtils } from '../commons-lib';
import { CategorieOuvrage, CategorieOuvrageMapping, OuvrageAControler, TypeIncrementation } from '../model/categorie-ouvrage.model';
import { buildOuvrageTree, createOuvrage, getGenericOuvrages, mapTypesAndParams, mergeOuvrageTrees } from '../utils/sync-plan-ouvrages.utils';

export function getStoreyName(storey: cn_storey) {
    return storey.name ? storey.name : storey.get_storey_name();
}

export function isSameZone(zone: string, lot: string, bien: Bien) {
    return (
        ([null, undefined].includes(zone) && [null, undefined].includes(lot)) ||
        zone === lot ||
        ([null, undefined].includes(zone) && lot === bien.numeroLot)
    );
}

@Injectable({
    providedIn: 'root',
})
export class SyncBienPlanService {
    constructor(private readonly diagnosticHandlerService: DiagnosticHandlerService) {}
    /**
     * Mise à jour de la description du bien à partir du plan s'il existe
     */
    updateBienFromMap(
        bien: Bien,
        building: cn_building,
        equipements: Equipement[],
        typesVolumes: TypeVolume[] = [],
        diagnostics: Diagnostic[],
        categoriesOuvrages: CategorieOuvrage[] = [],
        categoriesOuvragesMappings: CategorieOuvrageMapping[] = []
    ) {
        if (bien && building) {
            // ADDITION ET MAJ
            // NIVEAU

            const extensionData = JSON.parse(JSON.parse(bien.jsonPlan).extension_data);
            const spacesNumerotation = extensionData.ac_env_spaces_numerotation;

            const genericOuvrages = getGenericOuvrages(building, null);
            const mappedOuvrages = mapTypesAndParams(genericOuvrages, categoriesOuvrages, categoriesOuvragesMappings, {
                element: '',
                categorie: '',
                partie: '',
                exterior: false,
                outside: null,
                inside: null,
                fluid: '',
                free: false,
                opening: '',
                glazing: '',
                closing: '',
                transom: '',
                sill: '',
                palier: '',
                floor: null,
                ceiling: null,
                floor_side: null,
                ceiling_side: null,
            });
            const groupedOuvrages = mappedOuvrages.reduce((acc, { ouvrage, categorie, parametres }) => {
                const key = `${ouvrage.storeyId}-${ouvrage.spaceId}`;
                acc.set(key, [...(acc.get(key) ?? []), createOuvrage(ouvrage, categorie, parametres)]);
                return acc;
            }, new Map<string, OuvrageAControler[]>());

            const niveaux = building.storeys?.map((storey) => ({
                storey,
                niveau: this.getNiveauOrCreate(bien, storey),
                bien,
            }));

            const objectsBySpace = new Map<string, cn_object_instance[]>();
            niveaux.forEach((it) =>
                (it.storey.scene.object_instances as cn_object_instance[]).forEach((objectInstance: cn_object_instance) => {
                    const key = `${it.storey.ID}-${objectInstance.space.ID}`;
                    return objectsBySpace.set(key, [...(objectsBySpace.get(key) ?? []), objectInstance]);
                })
            );

            const allSpacesFromPlan = niveaux
                .flatMap(({ niveau, storey }) =>
                    storey.scene?.spaces?.map((space, spaceIndex) => ({
                        space,
                        volume: this.getVolumeOrCreate(space, niveau),
                        niveau,
                        storey,
                        spaceIndex,
                        objects: objectsBySpace.get(`${storey.ID}-${space.ID}`) ?? [],
                    }))
                )
                .map((v) => ({
                    ...v,
                    typeVolumeListe: typesVolumes?.find((it) => it.id === v.volume.usageId),
                    typeVolumePlan: typesVolumes?.find((it) => it.codeBim === v.space.space_usage),
                }));

            const allObjectsFromPlan = allSpacesFromPlan.flatMap((it) =>
                it.objects.map((object) => ({
                    ...it,
                    storey: it.storey,
                    object,
                    typeElement: equipements.find((te) => te.id === object.object.ID),
                    equipement: it.volume.equipements.find((eq) => eq.objectId === object.ID),
                }))
            );

            const objectsFromPlanByStoreyAndObjectID = group(allObjectsFromPlan, ({ storey, object, space }) => `${storey.ID}-${object.ID}`);

            niveaux.forEach(({ storey, niveau }) => {
                niveau.nom = getStoreyName(storey);
                niveau.index = storey.storey_index;
            });

            allSpacesFromPlan.forEach(({ volume, niveau, storey, space, spaceIndex, typeVolumeListe, typeVolumePlan }) => {
                volume.usageId = typeVolumePlan?.id;
                // MAJ nom de volume dans tous les cas
                volume.nom = space.get_name(storey);
                volume.nomBase = space.name;
                volume.indexEspace = spaceIndex;
                const spaceNumerotation = spacesNumerotation?.find(
                    (it) =>
                        niveau.storeyId === it.storey &&
                        it.name === volume.nomBase &&
                        isSameZone(it.zone, volume.valeursParametres[PARAM_VOLUME_LOT], bien)
                );
                const currentValue = spaceNumerotation?.values.find((value) => value.id === volume?.spaceId);
                if (currentValue) {
                    volume.numerotationDebut = spaceNumerotation.numerotation;
                    volume.prefixeNumerotation = spaceNumerotation.prefix;
                    volume.numerotation = currentValue.index;
                } else {
                    volume.numerotationDebut = undefined;
                    volume.prefixeNumerotation = '';
                    volume.numerotation = undefined;
                }

                // MAJ des paramètres de la pièce en fonction de l'usage déclaré dans la map
                if (volume.usageId && typeVolumeListe !== typeVolumePlan && typeVolumePlan?.valeursDefautParametres) {
                    [
                        CODE_BIM_PARAM_ESPACE_HUMIDE,
                        CODE_BIM_PARAM_ESPACE_CHAUFFE,
                        CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ,
                        CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE,
                        CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE,
                    ].forEach((key) => (volume.valeursParametres[key] = typeVolumePlan.valeursDefautParametres[key]));
                }

                if (volume.valeursParametres[CODE_BIM_PARAM_ESPACE_CHAUFFE]) {
                    volume.valeursParametres[CODE_BIM_PARAM_ESPACE_CHAUFFE] = space.heated;
                }

                const zoneName = building.zones?.lot?.find((z) => z.rooms.find((r) => r.space === space.ID && r.storey === storey.ID))?.name;
                if (zoneName) {
                    volume.valeursParametres[PARAM_VOLUME_LOT] = zoneName;
                } else {
                    volume.valeursParametres[PARAM_VOLUME_LOT] = bien.numeroLot ? bien.numeroLot : null;
                }
            });

            const allEquipementsInList = Object.fromEntries(
                bien.description.flatMap((niveau) =>
                    niveau.volumes.flatMap((volume) =>
                        volume.equipements.map((equipement, index) => [
                            `${niveau.storeyId}-${equipement.objectId}`,
                            { equipement, volume, niveau, index },
                        ])
                    )
                )
            );

            //déplacer équipements
            allObjectsFromPlan
                .filter(({ equipement, storey, object }) => !equipement && allEquipementsInList[`${storey.ID}-${object.ID}`])
                .forEach(({ storey, object }) => {
                    const existingObj = allEquipementsInList[`${storey.ID}-${object.ID}`];
                    const volume = existingObj.volume;
                    const targetVolume = objectsFromPlanByStoreyAndObjectID[`${storey.ID}-${object.ID}`][0].volume;
                    const existingEquipement = existingObj.volume.equipements.splice(existingObj.index, 1)[0];
                    targetVolume.equipements.push(existingEquipement);
                    diagnostics?.forEach((diagnostic) =>
                        this.diagnosticHandlerService
                            .getTypePrestationService(diagnostic.typePrestation)
                            ?.deplaceEquipement(existingEquipement.id, diagnostic, volume, bien)
                    );
                });

            allObjectsFromPlan
                .filter(
                    ({ typeElement, equipement, storey, object }) =>
                        !!typeElement && !equipement && !allEquipementsInList[`${storey.ID}-${object.ID}`]
                )
                .forEach(({ object, volume, typeElement }) => {
                    const equipementName = ListUtils.createUniqueNameElementAControler(volume.equipements, typeElement);
                    const element = JSON.parse(JSON.stringify(typeElement)); // deep copy pour ne pas garder de lien entre les équipements ( on créer une nouvelle instance)
                    element.id = MongoUtils.generateObjectId();
                    element.name = equipementName;
                    element.objectId = object.ID;
                    volume.equipements.push(element);
                });

            allSpacesFromPlan.forEach(({ volume, storey, space }) => {
                const ouvrageAControlers = groupedOuvrages.get(`${storey.ID}-${space.ID}`);
                const ouvrageTree = buildOuvrageTree(ouvrageAControlers ?? [], categoriesOuvrages, 1)
                    .map((it) => it.partiesOuvrages)
                    .flat();

                volume.ouvragesAControler = mergeOuvrageTrees(volume.ouvragesAControler ?? [], ouvrageTree);

                const allOuvragesInVolumeByObjectIdAndCodeCategorie = Object.fromEntries(
                    volume.ouvragesAControler
                        .flatMap((it1) => [
                            it1,
                            ...(it1.partiesOuvrages ?? []).flatMap((it2) => [
                                it2,
                                ...(it2.partiesOuvrages ?? []).flatMap((it3) => [it3, ...(it3.partiesOuvrages ?? [])]),
                            ]),
                        ])
                        .map((it) => [`${it.objectId}-${it.codeCategorieOuvrage}`, it])
                );
                const allOuvragesInVolumeByObjectId = Object.fromEntries(
                    Object.entries(allOuvragesInVolumeByObjectIdAndCodeCategorie).map(([key, ouvrage]) => [ouvrage.objectId, ouvrage])
                );
                volume.ouvragesAControler
                    .flatMap((it1) => [it1, ...it1.partiesOuvrages])
                    .forEach((it) => {
                        const allTreeMapElement = allOuvragesInVolumeByObjectIdAndCodeCategorie[`${it.objectId}-${it.codeCategorieOuvrage}`];
                        if (!allTreeMapElement) {
                            it.objectId = null;
                        } else {
                            const localisation = it.parametres?.LOCALISATION_SUR_OUVRAGE;
                            const ouvrageCible = allOuvragesInVolumeByObjectId[`${localisation}`];
                            if (localisation && ouvrageCible) {
                                it.parametres.LOCALISATION_SUR_OUVRAGE = ouvrageCible.id;
                            }
                        }
                    });
            });

            bien.description.filter((niv) => !niveaux.some((it) => it.storey.ID === niv.storeyId)).forEach((niv) => (niv.storeyId = null));

            bien.description
                .flatMap((niv) =>
                    niv.volumes.filter(
                        (vol) =>
                            !niv.storeyId ||
                            !vol.spaceId ||
                            !allSpacesFromPlan.some((it) => it.space.ID === vol.spaceId && it.niveau.storeyId === niv.storeyId)
                    )
                )
                .forEach((it) => (it.spaceId = null));

            bien.description
                .flatMap((niv) =>
                    niv.volumes.flatMap((vol) =>
                        vol.equipements.filter(
                            (eq) => !niv.storeyId || !vol.spaceId || !objectsFromPlanByStoreyAndObjectID[`${niv.storeyId}-${eq.objectId}`]
                        )
                    )
                )
                .forEach((eq) => (eq.objectId = null));

            // On tri les étages en fonction de leurs indexes
            bien.description = bien.description.sort((a, b) => a.index - b.index);

            // bien.description
            //     .flatMap((n) => n.volumes)
            //     .forEach((volume) => {
            //         const indexPortePrincipale = volume.ouvragesAControler.findIndex((ouvrage) => !!ouvrage.parametres['AVEC_PORTE_PRINCIPALE']);
            //         if (indexPortePrincipale > -1) {
            //             volume.ouvragesAControler = volume.ouvragesAControler
            //                 .slice(indexPortePrincipale)
            //                 .concat(volume.ouvragesAControler.slice(0, indexPortePrincipale));
            //         }
            //     });

            Object.entries(
                group(
                    bien.description.flatMap((niveau) =>
                        niveau.volumes.flatMap((volume) =>
                            volume.ouvragesAControler.flatMap((ouvrage, index) => ({ volume, ouvrage, index, niveau }))
                        )
                    ),
                    ({ ouvrage, niveau, volume }) => `${ouvrage.codeCategorieOuvrage}-${niveau.storeyId}-${volume.spaceId}`
                )
            )
                .flatMap(([code, ouvrages]) =>
                    ouvrages.map((it, index) => ({
                        ...it,
                        index,
                        categorieOuvrage: categoriesOuvrages?.find((categorie) => categorie.code === it.ouvrage.codeCategorieOuvrage),
                    }))
                )
                .filter(
                    ({ categorieOuvrage, ouvrage }) =>
                        !!ouvrage.objectId && categorieOuvrage.typeIncrementation && categorieOuvrage.typeIncrementation !== TypeIncrementation.AUCUN
                )
                .forEach(({ volume, ouvrage, index, categorieOuvrage }) => {
                    const numerotation =
                        categorieOuvrage.typeIncrementation === TypeIncrementation.LETTRE ? String.fromCharCode(65 + index) : index + 1;
                    ouvrage.nom = `${categorieOuvrage.nomOuvrage} ${numerotation}`;
                });
        }
    }

    private getNiveauOrCreate(bien: Bien, storey: cn_storey) {
        let currentNiveau = bien.description.find((niveau) => niveau.storeyId === storey.ID);
        // Si le niveau a été ajouté dans la map
        if (!currentNiveau) {
            currentNiveau = new Niveau();
            currentNiveau.storeyId = storey.ID;
            // On rajoute manuellement le volume "caché"
            const volumeCache = new Volume();

            volumeCache.nom = 'Extérieur';
            volumeCache.volumeCache = true;
            currentNiveau.volumes.push(volumeCache);
            bien.description.push(currentNiveau);
        }
        return currentNiveau;
    }

    private getVolumeOrCreate(space: cn_space, currentNiveau: Niveau) {
        let currentVolume: Volume;
        if (!space.outside) {
            currentVolume = currentNiveau.volumes.filter((it) => !!it.spaceId).find((volume) => volume.spaceId === space.ID);
        } else {
            currentVolume = currentNiveau.volumes.find((v) => v.volumeCache);
        }
        // Si le volume a été ajouté dans la map
        if (!currentVolume) {
            currentVolume = new Volume();
            currentVolume.spaceId = space.ID;
            // Maj des flags
            currentNiveau.volumes.push(currentVolume);
        }
        return currentVolume;
    }
}
