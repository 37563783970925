import { MongoUtils } from 'src/app/commons-lib';

export class MpcaItemOld {
    id: string;
    general: MpcaGeneralOld = new MpcaGeneralOld();
    protectionExposition: MpcaProtectionExpositionOld = new MpcaProtectionExpositionOld();
    processus: MpcaProcessus[] = [];

    constructor() {
        this.id = MongoUtils.generateObjectId();
    }
}

export class Materiaux {
    nom: string = String();
    categorieMateriaux: string = String();
    ouvrageComposantMateriaux: string = String();
}

export class MpcaGeneralOld {
    materiaux: Materiaux;
    materiauxClient: string = String();
    etatDegradation: string = String();
    typeAmiante: string = String();
}

export class MpcaPendantTravauxOld {
    niveauEmpoussierement: string = String();
    typeMasqueDesamianteur: string = String();
    concentrationFi: Number = 0;
}

export class MpcaMetopOld {
    methode: string = String();
    technique: string = String();
    outil: string = String();
    autresOutils: string[] = [];
    aspiration: string = String();
    travailHumide: string = String();
}

export class MpcaProtectionExpositionOld {
    type: string = String();
    etancheite: string = String();
    chocVibration: string = String();
    circulationAir: string = String();
}

export class MpcaProcessus {
    id: string = MongoUtils.generateObjectId();
    nom: string = '';
    pendantTravaux: MpcaPendantTravauxOld = new MpcaPendantTravauxOld();
    metop: MpcaMetopOld = new MpcaMetopOld();
    echantillonnage: MpcaEchantillonnage = new MpcaEchantillonnage();
    taches: MpcaProcessusTaskOld[] = [];
    primary: boolean = false;
}

export class MpcaEchantillonnage {
    concentrationAttendue: number;
    dureeRepresentativeProcessus: number;
    dureeSaturationPoussieres: number;
    debitPrelevement: number;
    fractionFiltre: string = String();
}

export class MpcaProcessusTaskOld {
    operateur1 = false;
    operateur2 = false;
    operateur3 = false;
    operateur4 = false;
    nom1: string = '';
    nom2: string = '';
    nom3: string = '';
    nom4: string = '';
    tache: string = String();
}
