import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-exterieur-ascenseur',
    templateUrl: './exterieur-ascenseur.component.html',
    styleUrls: ['./exterieur-ascenseur.component.scss'],
})
export class ExterieurAscenseurComponent implements OnInit {
    readonly MENU_NAME = 'Extérieur/Ascenseur';

    constructor() {}

    ngOnInit(): void {}
}
