import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Contact } from '../model/contact.model';
import { ContactApiService } from './contact-api.service';
import { PageRequest } from '../shared/paging/page';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { confirmWarn } from '../utils/confirm.utils';
import { ConfirmationService, NotificationService } from 'src/app/commons-lib';
import { HTTP_STATUS_CONFLICT } from '../shared/constants/cndiag.constants';

@Injectable({
    providedIn: 'root',
})
export class ContactService {
    constructor(
        private contactApiService: ContactApiService,
        private readonly confirmationService: ConfirmationService,
        private readonly notificationService: NotificationService
    ) {}

    findContacts(categorie: 'PERSONNES' | 'ENTREPRISES' | 'INDIFFERENT' = 'PERSONNES', search, pageRequest) {
        return this.contactApiService.searchContacts(categorie, search, pageRequest);
    }
    /**
     * Renvoie un contact.
     */
    findOneContact(idContact: string): Observable<Contact> {
        return this.contactApiService.findOneContact(idContact);
    }

    upsertContact(contact: Contact, forceUpdate: boolean): Observable<Contact> {
        return this.contactApiService.upsertContact(contact, forceUpdate);
    }

    findAllContactsByName(search: string, pageRequest: PageRequest<Contact>) {
        return this.contactApiService.findAllContactByName(search, pageRequest);
    }

    findAllEntreprisesByName(search: string, pageRequest: PageRequest<Contact>) {
        return this.contactApiService.findAllEntreprisesByName(search, pageRequest);
    }

    findAllContactsByPhone(search: string, pageRequest: PageRequest<Contact>) {
        return this.contactApiService.findAllContactByPhone(search, pageRequest);
    }

    findAllContactsByEmail(search: string, pageRequest: PageRequest<Contact>) {
        return this.contactApiService.findAllContactByEmail(search, pageRequest);
    }

    findAllContactsBySiret(search: string, pageRequest: PageRequest<Contact>) {
        return this.contactApiService.findAllContactBySiret(search, pageRequest);
    }

    upsertContactWarnDuplicate(contact: Contact, textSuccess: string, textWarn: string, textError: string) {
        return this.upsertContact(contact, false).pipe(
            catchError((err) => {
                if (err.status === HTTP_STATUS_CONFLICT) {
                    return confirmWarn(this.confirmationService, textWarn).pipe(
                        switchMap((confirmation) => {
                            if (confirmation) {
                                return this.upsertContact(contact, true);
                            } else {
                                throw Error(textError);
                            }
                        })
                    );
                } else {
                    throw err;
                }
            }),
            tap(() => this.notificationService.success(textSuccess))
        );
    }

    public getfullname(contact: Contact): string {
        if (contact.estPersonneMorale) {
            return contact.civilite + ' ' + contact.nom + ' ' + contact.prenom;
        } else {
            return contact.nom;
        }
    }
}
