import { Component, Input, OnInit } from '@angular/core';
import { Surface } from '../../../../model/surface.model';

@Component({
    selector: 'app-header-zone',
    templateUrl: './header-zone.component.html',
    styleUrls: ['./header-zone.component.scss'],
})
export class HeaderZoneComponent implements OnInit {
    @Input()
    listeSurfaces: any[];

    @Input()
    isInEditMode: Boolean = true;

    constructor() {}

    @Input()
    surfaceTotale: number = 0;

    @Input()
    puTotal: number = 0;

    ngOnInit() {}
}
