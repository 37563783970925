import { EtatDiagnostic } from 'src/app/model/diagnostic.model';
import { AnnexeData, DocumentData, IDiagReportData, ReportagePhotoData } from 'src/app/model/rapport.model';
import { TypePrestation } from 'src/app/model/type-prestation.model';

export class HapBonCommandeData implements IDiagReportData {
    documentsData: DocumentData[];
    annexes: AnnexeData[];
    reportagesPhotos: ReportagePhotoData[];
    conformiteGlobale: boolean;
    id: string;
    typePrestation: TypePrestation;
    refRapport: string;
    datePrelevement = 'n.c';
    dateEnvoi = 'n.c';
    emailEnvoieResultatGenerique = 'resultats@ac-environnement.com';
    echantillons: EchantilonBonCommandeData[] = [];
    listDocuments: DocumentData[];

    /**
     * Etat du diagnostic
     *
     * @title Etat du diagnostic
     * @description L'état du diagnostic : EN_ATTENTE, TERMINE, FINI; Si tous les diagnostics sont fini ou annulé, l'intervention passe à terminé, et tous les diagostics passent à terminé.
     */
    etat: EtatDiagnostic;
}

export class EchantilonBonCommandeData {
    reference = 'n.c';
    ouvrage = 'n.c';
    partieOuvrage = 'n.c';
    materiaux = 'n.c';
    amiantePresent = false;
    typeEchantillon = 'n.c';
    nomPerimetre = 'n.c';
    nomZone = 'n.c';
    lave = true;
    dissociation = true;
    analyseToutesCouches = true;
    limitationFibres = true;
    pollutionSurfacique = false;
}
