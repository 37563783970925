import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { InterventionService } from '../../../../services/intervention.service';
import { CnSpinnerService } from '../../../shared/cn-spinner/service/cn-spinner.service';
import { DiagnosticService } from '../../../../services/diagnostic.service';
import { PolluantService } from '../../../diagnostics/polluant/services/polluant.service';
import { BaseComponent, NotificationService } from 'src/app/commons-lib';
import { Polluant } from '../../../diagnostics/polluant/model/polluant.model';
import { BaseObject } from '../../../../model/base-object.model';
import { Diagnostic } from '../../../../model/diagnostic.model';
import { Intervention } from '../../../../model/intervention.model';
import { Besoin } from 'src/app/modules/diagnostics/polluant/model/besoin.model';
import {
    PolluantPrelevement,
    PrelevementAcr,
    PrelevementMest,
    PrelevementMeta,
    PrelevementMetop,
} from 'src/app/modules/diagnostics/polluant/model/polluant-prelevement.model';
import { ConditionsPrelevement } from 'src/app/modules/diagnostics/polluant/model/polluant-config.model';
import { Zone } from 'src/app/model/zone.model';
import { TypePrelevementAmiante } from 'src/app/model/prelevement-generique.model';

@Component({
    selector: 'app-planification-besoins',
    templateUrl: './planification-besoins.component.html',
    styleUrls: ['./planification-besoins.component.scss'],
})
export class PlanificationBesoinsComponent extends BaseComponent implements OnInit {
    readonly zoneHorsStrategie = {
        nom: 'Hors stratégie',
        lettre: 'HS',
    };
    public allReferences: BaseObject[] = [];
    public zonesList: any[] = [];
    public zonesSelected: any[] = [];
    public besoinsList: any[] = [];
    public planifications: any[];
    public prestationId: string;

    private _besoinsListRef: any[] = [];
    private _conditionsPrelevement: any[] = [];
    private _diagnostic: Diagnostic;
    private _interventionParent: Intervention;
    public refSelected: string = null; // Hors Stratégie
    public newInterventionId: string = null; // L'Id de l'intervention en cours de création
    public isCreation: boolean = true; // par défaut c'est une création d'une intervention

    onZoneSelected(zone: any) {
        if (zone) {
            const index = this.zonesSelected.indexOf(zone);
            if (index < 0) {
                this.zonesSelected.push(zone);
            } else {
                this.zonesSelected.splice(index, 1);
            }
        } else {
            this.zonesSelected = this.zonesList.length === this.zonesSelected.length ? [] : [...this.zonesList];
        }
        this.initBesoinsByZone();
    }
    zoneIsSlected(zone): boolean {
        return this.zonesSelected.some((z) => z === zone);
    }
    constructor(
        public readonly dialogRef: MatDialogRef<PlanificationBesoinsComponent>,
        private cnSpinnerService: CnSpinnerService,
        private diagnosticService: DiagnosticService,
        private readonly notificationService: NotificationService,
        private polluantService: PolluantService,
        private interventionService: InterventionService
    ) {
        super();
    }

    // Required
    set diagnostic(pDiagnostic: Diagnostic) {
        this._diagnostic = pDiagnostic;
        if (this.isCreation) {
            const contenuDiagnostic = this._diagnostic.contenuDiagnostic as Polluant;
            this.zonesList = contenuDiagnostic.zones.data?.zonesList?.filter((z) => z.nom !== 'Toutes les zones');
            this._besoinsListRef = contenuDiagnostic.besoins.data.besoinsList;
        } else {
            const contenuDiagnostic = this._diagnostic.contenuDiagnostic as PolluantPrelevement;
            this._besoinsListRef = (contenuDiagnostic.prelevement.data.besoinsList || []).map((v) => v.besoinPrelevement);
            this.zonesList = contenuDiagnostic.listeZones?.data?.listeZones?.filter((z) => z.nom !== 'Toutes les zones');
        }
        if (!this._besoinsListRef?.length) {
            this._besoinsListRef = [];
            this.notificationService.warn("Erreur : Aucun besoin n'a été trouvé sur la prestation.");
        }
        this.initPlanifications();
        this.polluantService
            .findOnePolluantConfigIndexedDB(pDiagnostic.idConfig)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((polluantConfig) => {
                if (!polluantConfig) {
                    this.notificationService.error('Erreur : Merci de faire une synchro pour récupérer les configs en local.');
                    this.cnSpinnerService.hide();
                    this.dialogRef.close();
                    return;
                }
                console.log(polluantConfig);
                polluantConfig.objectifsMesurage
                    .filter((config) => !!config.conditionsPrelevement)
                    .map((conf) => conf.conditionsPrelevement)
                    .forEach((datas) => {
                        this._conditionsPrelevement.push(...datas);
                    });
                this._conditionsPrelevement.forEach((cond) => {
                    this.allReferences.push({ nom: cond.reference, id: cond.id });
                });
                this.cnSpinnerService.hide();
            });
    }
    // Required
    set intervention(pIntervention: Intervention) {
        this._interventionParent = pIntervention;
        if (!this.prestationId) {
            console.info('prestationId undefined');
            return;
        }
        const prestDiag = this._interventionParent.prestationsDiagnostics.find((prest) => prest.id === this.prestationId);
        if (!prestDiag.idDiagnostic) {
            this.notificationService.error('Erreur : Diagnostic introuvable sur cette prestation.');
            this.cnSpinnerService.hide();
            this.dialogRef.close();
            return;
        }
        this.diagnosticService
            .findOne(prestDiag.idDiagnostic)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((diagnostic) => {
                this.diagnostic = diagnostic;
            });
    }

    // Required En création
    set interventionId(pId: string) {
        this.cnSpinnerService.show();
        this.interventionService
            .findOneOnline(pId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((intervention) => {
                this.intervention = intervention;
            });
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth1000-dialog');
    }
    get choices() {
        return this.allReferences.map((value) => value.nom);
    }
    addBesoin(besoin: any) {
        if (!this.zonesSelected.length) {
            return this.notificationService.error('Veuillez sélectionner au moins une zone');
        }

        for (const zone of this.zonesSelected) {
            this.addPlanification(zone, besoin);
        }
    }

    /**
     * Initialise la liste des besoinsa en fonction des zones sélectionnées.
     */
    private initBesoinsByZone() {
        const listBesoin = [];
        this.zonesList.forEach((z: Zone) => {
            if (this.zoneIsSlected(z)) {
                listBesoin.push(z.id);
            }
        });

        this.besoinsList = this._besoinsListRef.filter((besoin: Besoin) => listBesoin.includes(besoin.zoneId) && besoin?.objectifMesurage?.reference);
    }
    private initPlanifications() {
        const interventionId = this.newInterventionId || this._interventionParent.id;
        const besoins = this._besoinsListRef.filter((besoin) => besoin.interventionId === interventionId);
        this.planifications = [];
        besoins.forEach((besoin) => {
            this.addPlanification(
                this.zonesList.find((z: Zone) => z.id === besoin.zoneId),
                besoin
            );
        });
    }
    private addPlanification(zone, besoin) {
        if (
            this.planifications.some(
                (item) =>
                    (besoin.numero ? besoin.numero === item?.besoin?.numero : true) &&
                    item.zone.nom === zone.nom &&
                    (item.besoin?.objectifMesurage?.reference === besoin?.objectifMesurage?.reference || item.besoin?.reference === besoin?.reference)
            )
        ) {
            return this.notificationService.notify('Existe déjà parmis la liste des planifications.');
        }
        this.planifications.push({
            zone,
            besoin,
        });
    }

    /**
     * Ajouter un besoins Hors-Stratégie
     */
    addPlanificationHorsZone() {
        if (!this.refSelected) {
            return this.notificationService.error('Veuillez sélectionner un besoin');
        }
        let numero = 1;
        for (
            ;
            this.planifications.some(
                (item) =>
                    item.zone.nom === this.zoneHorsStrategie.nom &&
                    item.besoin?.objectifMesurage?.reference === this.refSelected &&
                    +item?.besoin?.numero.split(' ')[1] === numero
            );
            numero++
        ) {}

        const besoinSelected = this._conditionsPrelevement.find((cond) => cond.reference === this.refSelected);
        const besoinHS: Besoin = new Besoin();
        //TODO
        // besoinHS.zoneLettre = 'HS';
        besoinHS.numero = besoinSelected.reference.split(' ')[1] + ' ' + numero;
        besoinHS.interventionId = this.newInterventionId || this._interventionParent.id;
        besoinHS.objectifMesurage = {
            label: besoinSelected.objectifMesure,
            parentId: besoinSelected.id,
            reference: besoinSelected.reference,
            typePrelevement: besoinSelected.typePrelevement,
        };
        const niveaux = this._interventionParent.relationInterventionBiens[0]?.bien?.description;
        besoinHS.pieceId = niveaux[0]?.volumes.filter((v) => v.nom !== 'Extérieur')[0]?.id;
        this.addPlanification(this.zoneHorsStrategie, besoinHS);
    }

    delPlanification(item: any) {
        this.planifications = this.planifications.filter((val) => val !== item);
    }

    onValidate() {
        const interventionId = this.newInterventionId || this._interventionParent.id;
        for (const besoin of this._besoinsListRef) {
            if (besoin.interventionId === interventionId) {
                besoin.interventionId = null;
            }
        }
        for (const besoin of this._besoinsListRef) {
            this.planifications.forEach((item) => {
                if (
                    besoin.zoneId === item.zone.id &&
                    besoin?.objectifMesurage?.reference === item.besoin?.objectifMesurage?.reference &&
                    besoin?.numero === item.besoin?.numero
                ) {
                    besoin.interventionId = interventionId;
                }
            });
        }

        // Gestion des besoins hors stratégie
        const planificationsHS = this.planifications.filter((p) => p.zone.id === 'Hors stratégie');
        if (planificationsHS.length) {
            for (const item of planificationsHS) {
                if (!this._besoinsListRef.some((val) => val.id === 'Hors stratégie' && val.numero === item.besoin.numero)) {
                    this._besoinsListRef.push(item.besoin);
                }
            }
        }

        if (!this.isCreation) {
            const contenuDiagnostic = this._diagnostic.contenuDiagnostic as PolluantPrelevement;
            contenuDiagnostic.prelevement.data.besoinsList = [];
            for (const besoin of this._besoinsListRef) {
                const _objMesurage: ConditionsPrelevement = besoin.objectifMesurage;
                if (_objMesurage.typePrelevement == TypePrelevementAmiante.META) {
                    contenuDiagnostic.prelevement.data.besoinsList.push(PrelevementMeta.fromBesoin(besoin));
                } else if (_objMesurage.typePrelevement == TypePrelevementAmiante.METOP) {
                    contenuDiagnostic.prelevement.data.besoinsList.push(PrelevementMetop.fromBesoin(besoin, contenuDiagnostic));
                } else if (_objMesurage.typePrelevement == TypePrelevementAmiante.MEST) {
                    contenuDiagnostic.prelevement.data.besoinsList.push(PrelevementMest.fromBesoin(besoin));
                } else if (_objMesurage.typePrelevement == TypePrelevementAmiante.ACR) {
                    contenuDiagnostic.prelevement.data.besoinsList.push(PrelevementAcr.fromBesoin(besoin));
                }
            }
        }
        console.log(this._diagnostic);
        // Mise à jour du diagnostic
        this.cnSpinnerService
            .withSpinner(this.diagnosticService.upsert(this._interventionParent, this._diagnostic))
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
                console.log(this.planifications);
                this.dialogRef.close(this.planifications);
            });
    }
}
