<div class="recapitulatif" *ngIf="isLoaded">
    <div class="container d-flex flex-column">
        <div class="recapitulatif-item d-flex p-2">
            <div class="">Contexte(s) de mesurage</div>
            <div class="value">
                {{ objectifs | objectifsMesurage }}
            </div>
        </div>
        <div class="recapitulatif-item d-flex p-2">
            <div class="">
                <a routerLink="../validationDoc">Validation documentaire</a>
            </div>
            <div class="value">
                <span>{{ invalidDocumentsName }}</span>
                <mat-icon *ngIf="stateDocuments == true" style="color: green"> check_circle </mat-icon>
                <mat-icon *ngIf="stateDocuments == false" style="color: red"> cancel </mat-icon>
            </div>
            <!--
            <div class="value">Plan (périmètre non couvert)</div>
            -->
        </div>

        <!--
        <div class="recapitulatif-item d-flex p-2">
            <div class="">Duplication de la stratégie</div>
            <div class="value">Ref de la stratégie source</div>
        </div>
            -->
        <div class="recapitulatif-item d-flex p-2">
            <div class="">Estimation de la stratégie</div>
            <div class="value">{{ estimation | estimationPipe }}</div>
        </div>
        <div class="recapitulatif-item d-flex p-2">
            <div class="">Estimation 1ère intervention</div>
            <div class="value">{{ estimation | interventionPipe }}</div>
        </div>
        <div class="recapitulatif-item d-flex p-2">
            <div class="">Compétence(s) requise(s)</div>
            <div class="value">{{ estimation | competencesPipe }}</div>
        </div>
        <div class="liste-ecran-erreurs d-flex p-2">
            <div class="container-ecrans">
                <div class="ecran">
                    <p class="ecran-header" (click)="navigateEcran('listeMPCA')">Ecran MPCA</p>
                    <div *ngIf="!erreurs['mpcaList'].hasError" class="ecran-content">
                        <p>
                            Ecran valide
                            <mat-icon style="color: green; vertical-align: middle"> check_circle </mat-icon>
                        </p>
                    </div>
                    <div *ngIf="erreurs['mpcaList'].hasError" class="ecran-content">
                        <ng-container *ngIf="erreurs['mpcaList'].champs.length != 0">
                            <p>
                                Champs manquants
                                <mat-icon style="color: red; vertical-align: middle"> check_circle </mat-icon>
                            </p>
                            <div *ngFor="let mpca of erreurs['mpcaList'].champs" class="item-ecran">
                                <ng-container *ngIf="mpca.erreurs.length != 0">
                                    <p>MPCA {{ mpca.nom }}</p>
                                    <ul class="liste-erreurs">
                                        <li *ngFor="let error of mpca.erreurs" class="item-erreur">
                                            {{ error | unCamelCasePipe }}
                                        </li>
                                    </ul>
                                </ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="erreurs['mpcaList'].autre != undefined">
                            <p>
                                {{ erreurs['mpcaList'].autre }}
                                <mat-icon style="color: red; vertical-align: middle"> check_circle </mat-icon>
                            </p>
                        </ng-container>
                    </div>
                </div>
                <div class="ecran">
                    <p class="ecran-header" (click)="navigateEcran('zone')">Ecran Zone</p>
                    <div *ngIf="!erreurs['zones'].hasError" class="ecran-content">
                        <p>
                            Ecran valide
                            <mat-icon style="color: green; vertical-align: middle"> check_circle </mat-icon>
                        </p>
                    </div>
                    <div *ngIf="erreurs['zones'].hasError" class="ecran-content">
                        <ng-container *ngIf="erreurs['zones'].champs.length != 0">
                            <p>
                                Champs manquants
                                <mat-icon style="color: red; vertical-align: middle"> check_circle </mat-icon>
                            </p>
                            <div *ngFor="let zone of erreurs['zones'].champs" class="item-ecran">
                                <ng-container *ngIf="zone.erreurs.length != 0">
                                    <p>Zone {{ zone.reference }}</p>
                                    <ul class="liste-erreurs">
                                        <li *ngFor="let error of zone.erreurs" class="item-erreur">
                                            {{ error | unCamelCasePipe }}
                                        </li>
                                    </ul>
                                </ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="erreurs['zones'].autre != undefined">
                            <p>
                                {{ erreurs['zones'].autre }}
                                <mat-icon style="color: red; vertical-align: middle"> check_circle </mat-icon>
                            </p>
                        </ng-container>
                    </div>
                </div>
                <div class="ecran">
                    <p class="ecran-header" (click)="navigateEcran('besoin')">Ecran Besoins</p>
                    <div *ngIf="!erreurs['besoins'].hasError" class="ecran-content">
                        <p>
                            Ecran valide
                            <mat-icon style="color: green; vertical-align: middle"> check_circle </mat-icon>
                        </p>
                    </div>
                    <div *ngIf="erreurs['besoins'].hasError" class="ecran-content">
                        <ng-container *ngIf="erreurs['besoins'].champs.length != 0">
                            <p>
                                Champs manquants
                                <mat-icon style="color: red; vertical-align: middle"> check_circle </mat-icon>
                            </p>
                            <div *ngFor="let besoin of erreurs['besoins'].champs" class="item-ecran">
                                <ng-container *ngIf="besoin.erreurs.length != 0">
                                    <p>Besoin {{ besoin.reference }}</p>
                                    <ul class="liste-erreurs">
                                        <li *ngFor="let error of besoin.erreurs" class="item-erreur">
                                            {{ error | unCamelCasePipe }}
                                        </li>
                                    </ul>
                                </ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="erreurs['besoins'].autre != undefined">
                            <p>
                                {{ erreurs['besoins'].autre }}
                                <mat-icon style="color: red; vertical-align: middle"> check_circle </mat-icon>
                            </p>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
