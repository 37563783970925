<div class="mpca-card">
    <div class="card-border--left"></div>
    <div class="content" [ngClass]="{ selected: isSelected, disabled: disabled }">
        <div class="description">
            <ng-content title></ng-content>
            <ng-content content></ng-content>
            <ng-content select="[state]"></ng-content>
        </div>
        <div class="action">
            <ng-content select="[action]"></ng-content>
        </div>
    </div>
</div>
