import {
    AppInfoService,
    AuthenticationService,
    AuthenticationStore,
    BaseComponent,
    ConfirmationService,
    Environment,
    NotificationService,
    User,
} from 'src/app/commons-lib';
import { ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';

import { DEFAULT_LANDING_PAGE } from './app-routing.module';
import { Environment as EditorEnvironment, PluginOptionService, ToolName } from '@acenv/cnmap-angular-editor-lib';
import { cn_global_params, cn_space_extension_acenv, extension_instance } from '@acenv/cnmap-editor';
import { VersionCheckService } from './services/version-check.service';
import { environment } from '../environments/environment';
import { SYNC_PROGRESS, SynchronizationService } from './services/synchronization.service';
import { OldOfflineStorageService } from './services/old-offline-storage.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { EnvironmentUtils } from './utils/environment.utils';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ReferenceService } from './services/reference.service';
import { SessionStorageUtils } from './utils/session-storage.utils';
import {
    URL_GESTION_AGENCES,
    URL_GESTION_BIENS,
    URL_GESTION_BONS_COMMANDE_ANALYSE,
    URL_GESTION_COFRACS,
    URL_GESTION_COMMANDES,
    URL_GESTION_COMMENTAIRES_PREDEFINIS,
    URL_GESTION_CONTACTS,
    URL_GESTION_DOCUMENT,
    URL_GESTION_EQUIPEMENTS,
    URL_GESTION_INTERVENTIONS,
    URL_GESTION_LIVRABLES,
    URL_GESTION_PIECES,
    URL_GESTION_PRESTATIONS,
    URL_GESTION_SYNTHESES,
    URL_GESTION_USERS,
    URL_MON_COMPTE,
    URL_REFERENTIELS,
    URL_TABLEAU_DE_BORD,
} from './shared/constants/url.constants';
import { UserInformationApiService } from './services/user-information-api.service';
import { dbConfig } from './shared/constants/indexeddb.constants';
import { SyncState } from './services/syncState';

const develIsOnlineKey = 'develIsOnlineKey';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
    /** Utilisateur actuel */
    user: User = new User();

    /** Le menu est-il visible ? */
    menuVisible: boolean;

    /** L'orientation du device est-elle correcte ? */
    correctOrientation = true;

    /** Pages accessibles à l'utilsateur */
    appPages: any[];

    /** Mode développement ? */
    develMode = environment.devel;

    /** Padding top ? */
    showMainPaddingTop: boolean;

    /** Nombre d'interventions non synchronisées */
    unsyncedInterventions$: Observable<number>;

    /** show labels in sidebar */
    showLabel = true;

    accueilUrl = '';

    /** Toutes les pages (sera filtré en fonction des rôles) */
    // private allAppPages = [
    //     {
    //         title: 'Mon profil',
    //         url: '/profile',
    //         icon: 'person',
    //         authorities: ['ROLE_USER']
    //     },
    //     {
    //         title: 'Gestion des utilisateurs',
    //         url: '/admin/users',
    //         icon: 'perm_contact_calendar',
    //         authorities: ['ROLE_ADMIN']
    //     }
    // ];
    private allAppPages = [
        {
            title: 'Tableau de bord',
            url: URL_TABLEAU_DE_BORD,
            icon: 'dashboard',
            authorities: ['ROLE_OPERATOR', 'ROLE_PROJECT_LEADER'],
        },
        {
            title: 'Agenda',
            url: '/agenda',
            icon: 'date_range',
            authorities: ['ROLE_OPERATOR'],
            disabled: !EnvironmentUtils.afficherAgendaDashboard(),
        },
        {
            title: 'Missions en cours',
            url: '/missions',
            icon: 'assignment',
            authorities: ['ROLE_OPERATOR'],
            disabled: !EnvironmentUtils.afficherMissionsEnCoursDashboard(),
        },
        {
            title: 'Interventions',
            url: URL_GESTION_INTERVENTIONS,
            icon: 'handyman',
            authorities: ['ROLE_OPERATOR', 'ROLE_PROJECT_LEADER', 'ROLE_MANAGER', 'ROLE_ADMIN'],
            disabled: !EnvironmentUtils.afficherGestionInterventionsDashboard(),
        },
        {
            title: 'Commande laboratoires',
            url: URL_GESTION_BONS_COMMANDE_ANALYSE,
            icon: 'biotech',
            authorities: ['ROLE_OPERATOR', 'ROLE_PROJECT_LEADER', 'ROLE_MANAGER', 'ROLE_ADMIN'],
            disabled: !EnvironmentUtils.afficherGestionBonsCommandeDashboard(),
        },
        {
            title: 'Biens',
            url: URL_GESTION_BIENS,
            icon: 'maps_home_work',
            authorities: ['ROLE_ADMIN'],
            disabled: !EnvironmentUtils.afficherGestionBiensDashboard(),
        },
        {
            title: 'Commandes',
            url: URL_GESTION_COMMANDES,
            icon: 'receipt_long',
            authorities: ['ROLE_ADMIN'],
            disabled: !EnvironmentUtils.afficherGestionCommandesDashboard(),
        },
        {
            title: 'Rapports de synthèses',
            url: URL_GESTION_SYNTHESES,
            icon: 'folder_copy',
            authorities: ['ROLE_PROJECT_LEADER', 'ROLE_MANAGER', 'ROLE_ADMIN'],
            disabled: !EnvironmentUtils.afficherGestionSynthesesDashboard(),
        },
        {
            title: 'Contacts',
            url: URL_GESTION_CONTACTS,
            icon: 'badge',
            authorities: ['ROLE_ADMIN'],
            disabled: !EnvironmentUtils.afficherGestionContactsDashboard(),
        },
        {
            title: 'Employés',
            url: URL_GESTION_USERS,
            icon: 'people',
            authorities: ['ROLE_ADMIN'],
            disabled: !EnvironmentUtils.afficherGestionEmployesDashboard(),
        },
        {
            title: 'Agences',
            url: URL_GESTION_AGENCES,
            icon: 'domain',
            authorities: ['ROLE_ADMIN'],
            disabled: !EnvironmentUtils.afficherGestionAgencesDashboard(),
        },
        {
            title: 'Cofracs',
            url: URL_GESTION_COFRACS,
            icon: 'school',
            authorities: ['ROLE_ADMIN'],
            disabled: !EnvironmentUtils.afficherGestionCofracsDashboard(),
        },
        {
            title: 'Documents',
            url: URL_GESTION_DOCUMENT,
            icon: 'folder',
            authorities: ['ROLE_ADMIN'],
            disabled: !EnvironmentUtils.afficherGestionDocumentDashboard(),
        },
        {
            title: 'Livrables',
            url: URL_GESTION_LIVRABLES,
            icon: 'insert_drive_file',
            authorities: ['ROLE_OPERATOR', 'ROLE_PROJECT_LEADER', 'ROLE_MANAGER', 'ROLE_ADMIN'],
            disabled: !EnvironmentUtils.afficherGestionLivrablesDashboard(),
        },
        {
            title: 'Commentaires prédéfinis',
            url: URL_GESTION_COMMENTAIRES_PREDEFINIS,
            icon: 'speaker_notes',
            authorities: ['ROLE_ADMIN'],
            disabled: !EnvironmentUtils.afficherGestionDocumentDashboard(),
        },
        {
            title: 'Réglages',
            url: URL_REFERENTIELS,
            icon: 'view_comfy',
            authorities: ['ROLE_ADMIN'],
            disabled: !EnvironmentUtils.afficherGestionReferentielsDashboard(),
        },
    ];

    private deferredPrompt: any;
    isOnline: boolean;
    syncProgress$ = this.syncProgress.asObservable();

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly authenticationStore: AuthenticationStore,
        private readonly userInformationApiService: UserInformationApiService,
        private readonly router: Router,
        private readonly confirmationService: ConfirmationService,
        // we need versionsCheckService, to create this service and start checking versions
        private readonly versionCheckService: VersionCheckService,
        private readonly notificationService: NotificationService,
        private readonly synchronizationService: SynchronizationService,
        private readonly offlineStorageService: OldOfflineStorageService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly appInfoService: AppInfoService,
        private readonly referenceService: ReferenceService,
        // Ne pas effacer, nécessaire pour initialiser la bdd inedexeddb
        private readonly dbService: NgxIndexedDBService,
        private readonly pluginOption: PluginOptionService,
        @Inject(SYNC_PROGRESS) private readonly syncProgress: BehaviorSubject<{ running: boolean; progress: number }>
    ) {
        super();
    }

    @HostListener('window:orientationchange')
    onOrientationChange() {
        this.detectOrientation();
    }

    @HostListener('window:beforeinstallprompt', ['$event'])
    beforeInstallPrompt(event) {
        // Stash the event so it can be triggered later.
        this.deferredPrompt = event;
        this.showInstallButton();
    }

    showInstallButton() {
        this.notificationService
            .notifyWithAction(
                { message: "Vous pouvez installer l'application sur votre appareil", showCloseButton: true },
                "Cliquer ici pour l'installer"
            )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result) => {
                if (result === true) this.install();
            });
    }

    install() {
        const promptEvent = this.deferredPrompt;
        if (!promptEvent) {
            // The deferred prompt isn't available.
            return;
        }
        // Show the install prompt.
        promptEvent.prompt();
        // Log the result
        promptEvent.userChoice.then(() => {
            // Reset the deferred prompt variable, since prompt() can only be called once.
            this.deferredPrompt = null;
        });
    }

    ngOnInit() {
        // Renseigne les paramètres d'environnement dans la librairie "angular-commons"
        Environment.apiUrl = environment.apiUrl;
        Environment.managementUrl = environment.managementUrl;
        Environment.defaultLandingPage = DEFAULT_LANDING_PAGE;
        Environment.appName = 'CN-DIAG';
        Environment.appVersion = environment.appVersion;

        Environment.canReplaceUser = false;
        Environment.canSetUserPassword = false;
        cn_global_params.min_grid_spacing = 0.5;

        // désactivation de l'outil surface dans le descriptif
        var excludedTools: ToolName[] = ['AREA'];
        this.pluginOption.setExcludedTools(excludedTools);
        //
        //this.pluginOption.setNamesOnlyProvided(isOptionActive: boolean);
        //
        //this.pluginOption.setZpsoOption(isOptionActive: boolean);
        //
        //this.pluginOption.setZpsoReadOnly(isReadOnly: boolean);
        //
        //this.pluginOption.setNumerotationOption(isOptionActive: boolean);
        //
        //this.pluginOption.setDisplayMainMenu(isVisible: boolean);
        // On active le mode croquis
        this.pluginOption.setActivateSketchMode(true);
        //
        //this.pluginOption.setActivatePhotoMode(isActivated: boolean);
        //
        //this.pluginOption.setActivateExportSvg(isActivated: boolean);
        //
        //this.pluginOption.setActivateExportPdf(isActivated: boolean);
        //
        //this.pluginOption.setDisplaySaveAs(value: boolean);
        //
        //this.pluginOption.setDisplayRecoverMenu(value: boolean);
        //
        //this.pluginOption.setDisplayCloneStorey(value: boolean);
        // activation de l'extension de numérotation AC
        extension_instance.space = new cn_space_extension_acenv();

        // Initialise l'état de l'authentification
        this.authenticationService.queryAndSetCurrentUser().subscribe();

        // Filtre les pages accessibles à l'utilisateur, et indique s'il faut afficher le menu
        this.authenticationStore
            .getCurrentUser()
            .pipe(
                takeUntil(this.ngUnsubscribe),
                switchMap((user) => {
                    this.user = user;
                    const authorities: string[] = user ? user.authorities : [];
                    let appPages = [];
                    this.allAppPages.forEach((page) => {
                        if (!page.disabled && (!page.authorities || page.authorities.filter((aut) => authorities.includes(aut)).length)) {
                            appPages = appPages.concat(page);
                        }
                    });
                    this.accueilUrl = URL_TABLEAU_DE_BORD;
                    this.appPages = appPages;
                    this.menuVisible = !!user;
                    this.changeDetectorRef.detectChanges(); // pour éviter ExpressionChangedAfterItHasBeenCheckedError
                    if (this.user && this.user.id) {
                        return this.userInformationApiService.getUserInformationByUserId(this.user.id);
                    } else {
                        return of(null);
                    }
                })
            )
            .subscribe((user) => {});

        // Active l'icône dans le menu en fonction de la route
        this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            const activeRoute = event.url || '';
            this.showMainPaddingTop =
                activeRoute != '/connexion' &&
                activeRoute != '/logout' &&
                !activeRoute.startsWith(URL_GESTION_INTERVENTIONS) &&
                !activeRoute.startsWith(URL_GESTION_BIENS) &&
                !activeRoute.startsWith(URL_GESTION_COMMANDES) &&
                !activeRoute.startsWith(URL_GESTION_CONTACTS) &&
                !activeRoute.startsWith(URL_GESTION_SYNTHESES) &&
                !activeRoute.startsWith(URL_GESTION_USERS) &&
                !activeRoute.startsWith(URL_REFERENTIELS) &&
                !activeRoute.startsWith('/diagnostic') &&
                !activeRoute.startsWith(URL_TABLEAU_DE_BORD) &&
                !activeRoute.startsWith(URL_MON_COMPTE) &&
                !activeRoute.startsWith(URL_GESTION_AGENCES) &&
                !activeRoute.startsWith(URL_GESTION_COFRACS) &&
                !activeRoute.startsWith(URL_GESTION_DOCUMENT) &&
                !activeRoute.startsWith(URL_GESTION_BONS_COMMANDE_ANALYSE) &&
                !activeRoute.startsWith(URL_GESTION_COMMENTAIRES_PREDEFINIS) &&
                !activeRoute.startsWith(URL_GESTION_LIVRABLES);
            this.menuVisible =
                activeRoute.startsWith(URL_TABLEAU_DE_BORD) ||
                activeRoute.startsWith('/agenda') ||
                activeRoute.startsWith('/missions') ||
                activeRoute.startsWith(URL_GESTION_INTERVENTIONS) ||
                activeRoute.startsWith(URL_GESTION_BIENS) ||
                activeRoute.startsWith(URL_GESTION_COMMANDES) ||
                activeRoute.startsWith(URL_GESTION_SYNTHESES) ||
                activeRoute.startsWith(URL_GESTION_CONTACTS) ||
                activeRoute.startsWith(URL_GESTION_USERS) ||
                activeRoute.startsWith(URL_REFERENTIELS) ||
                activeRoute.startsWith(URL_MON_COMPTE) ||
                activeRoute.startsWith(URL_GESTION_AGENCES) ||
                activeRoute.startsWith(URL_GESTION_COFRACS) ||
                activeRoute.startsWith(URL_GESTION_DOCUMENT) ||
                activeRoute.startsWith(URL_GESTION_BONS_COMMANDE_ANALYSE) ||
                activeRoute.startsWith(URL_GESTION_COMMENTAIRES_PREDEFINIS) ||
                activeRoute.startsWith(URL_GESTION_LIVRABLES) ||
                activeRoute.startsWith('/application-infos') ||
                activeRoute.startsWith('/changelog') ||
                activeRoute.startsWith('/cp');

            this.showLabel =
                this.showLabel &&
                !activeRoute.startsWith(`${URL_GESTION_INTERVENTIONS}/`) &&
                !activeRoute.startsWith(`${URL_GESTION_BIENS}/`) &&
                !activeRoute.startsWith(`${URL_GESTION_COMMANDES}/`) &&
                !activeRoute.startsWith(`${URL_GESTION_SYNTHESES}/`) &&
                !activeRoute.startsWith(`${URL_GESTION_CONTACTS}/`) &&
                !activeRoute.startsWith(`${URL_GESTION_USERS}/`) &&
                !activeRoute.startsWith(`${URL_GESTION_EQUIPEMENTS}/`) &&
                !activeRoute.startsWith(`${URL_GESTION_PIECES}`) &&
                !activeRoute.startsWith(`${URL_GESTION_PRESTATIONS}/`) &&
                !activeRoute.startsWith(`${URL_GESTION_AGENCES}/`) &&
                !activeRoute.startsWith(`${URL_GESTION_COFRACS}/`) &&
                !activeRoute.startsWith(`${URL_GESTION_DOCUMENT}/`) &&
                !activeRoute.startsWith(`${URL_GESTION_BONS_COMMANDE_ANALYSE}/`) &&
                !activeRoute.startsWith(`${URL_GESTION_COMMENTAIRES_PREDEFINIS}/`) &&
                !activeRoute.startsWith(`${URL_GESTION_LIVRABLES}/`);

            if (!this.appPages) {
                return;
            }
            this.appPages.forEach((appPage) => (appPage.active = activeRoute.startsWith(appPage.url)));
        });

        // Synchro auto (désactivée en mode développement)
        if (!this.develMode) {
            // Active la synchronisation automatique à la connnexion
            this.authenticationStore
                .getCurrentUser()
                .pipe(
                    filter((user) => user != null),
                    takeUntil(this.ngUnsubscribe)
                )
                .subscribe(() => {
                    this.synchronizationService.scheduleSync();
                    this.synchronizationService.scheduleOnlineCheck();
                    if (this.develMode) {
                        // TODO a rebrancher ?
                        // this.synchronizationService.scheduleUnsycData();
                    }
                });

            // Désactive la synchronisation à la déconnexion
            this.authenticationStore
                .getCurrentUser()
                .pipe(
                    filter((user) => user == null),
                    takeUntil(this.ngUnsubscribe)
                )
                .subscribe(() => {
                    this.synchronizationService.cancelSyncSchedule();
                    this.synchronizationService.cancelScheduleOnlineCheck();
                    if (this.develMode) {
                        this.synchronizationService.cancelScheduleUnsycData();
                    }
                });
        }

        // Infos de synchro (mode développement uniquement)
        if (this.develMode) {
            this.unsyncedInterventions$ = this.synchronizationService.getUnsyncDataNumber();

            this.synchronizationService
                .getSyncState()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((isStateOnline) => {
                    if (isStateOnline !== SyncState.Offline) {
                        this.isOnline = true;
                    } else {
                        this.isOnline = false;
                    }
                });
            const develIsOnline = localStorage.getItem(develIsOnlineKey);
            if (develIsOnline) {
                if (develIsOnline === 'true') {
                    this.onClickDevBtnOnline(true);
                } else {
                    this.onClickDevBtnOnline(false);
                }
            }
        }

        // Précharge le référentiel à la connnexion
        this.authenticationStore
            .getCurrentUser()
            .pipe(
                filter((user) => user != null),
                take(1)
            )
            .subscribe(() => {
                this.referenceService.loadReferenceData();
            });

        // Détecte l'orientation
        this.detectOrientation();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    showAppInfo() {
        this.router.navigate(['/application-infos']);
    }

    forceSync() {
        this.synchronizationService.synchronizeData().subscribe();
    }

    logout() {
        this.confirmationService.confirm(
            {
                message: 'Êtes-vous sûr de vouloir vous déconnecter ?',
                titleLabel: 'Déconnexion',
                confirmLabel: 'Se déconnecter',
                cancelLabel: 'Annuler',
                warn: false,
            },

            () => this.router.navigate([`/logout`])
        );
    }

    resetSessionStorageAndRedirect(url: string) {
        SessionStorageUtils.removeItem(url);
        this.router.navigate([url]);
    }

    private detectOrientation() {
        console.log('orientation : ' + screen.orientation.type);
        this.correctOrientation = !screen.orientation.type.includes('portrait');
    }

    /**
     * Enable/disable online mode when user click on the toggle switch button
     */
    onClickDevBtnOnline(checked) {
        if (checked) {
            this.synchronizationService.scheduleOnlineCheck();
            this.synchronizationService.updateSyncState(SyncState.Online);
        } else {
            this.synchronizationService.cancelScheduleOnlineCheck();
            this.synchronizationService.updateSyncState(SyncState.Offline);
        }
        localStorage.setItem(develIsOnlineKey, '' + checked);
    }
}
