import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/commons-lib';
import { FormGroup } from '@angular/forms';

class FormModalData {
    constructor(public titreModale: string, public form: FormGroup) {}
}

@Component({
    selector: 'app-form-modal',
    templateUrl: './form-modal.component.html',
    styleUrls: ['./form-modal.component.scss'],
})
export class FormModalComponent extends BaseComponent {
    titreModale: string;
    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<FormModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FormModalData
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.data) {
            this.form = this.data.form;
            this.titreModale = this.data.titreModale;
        }
    }

    cancel() {
        this.dialogRef.close(false);
    }

    confirm() {
        this.dialogRef.close(this.form);
    }
}
