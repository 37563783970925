import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/commons-lib';
import { URL_GESTION_CREATION_SYNTHESE } from 'src/app/shared/constants/url.constants';

@Component({
    selector: 'app-gestion-syntheses',
    templateUrl: './gestion-syntheses.component.html',
    styleUrls: ['./gestion-syntheses.component.scss'],
})
export class GestionSynthesesComponent extends BaseComponent implements OnDestroy {
    constructor(private readonly router: Router) {
        super();
    }

    creerSynthese() {
        this.router.navigate([URL_GESTION_CREATION_SYNTHESE]);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
