import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-context-zone-travaux-template',
    templateUrl: './context-zone-travaux-template.component.html',
    styleUrls: ['./context-zone-travaux-template.component.scss'],
})
export class ContextZoneTravauxTemplateComponent implements OnInit {
    @Input() contexte: any;

    constructor() {}

    ngOnInit(): void {}

    getRowTravaux(listeMpca: any): number {
        var nbRowTechniques = 1;

        listeMpca.forEach((mpca) => {
            nbRowTechniques++;
            mpca.processus.forEach(() => {
                nbRowTechniques++;
            });
        });

        return nbRowTechniques;
    }
}
