import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/commons-lib';
import { URL_GESTION_COFRACS_EDIT } from 'src/app/shared/constants/url.constants';

@Component({
    selector: 'app-gestion-cofracs',
    templateUrl: './gestion-cofracs.component.html',
    styleUrls: ['./gestion-cofracs.component.scss'],
})
export class GestionCofracsComponent extends BaseComponent {
    constructor(private readonly router: Router) {
        super();
    }

    onClickCreerCofrac() {
        this.router.navigate([URL_GESTION_COFRACS_EDIT]);
    }
}
