/**
 * Wrapper class pour un booléen.
 * À utiliser pour pipe async sur observable de booléen.
 */
export class WrappedBoolean {
    constructor(private _value: boolean) {}

    get value(): boolean {
        return this._value;
    }
}
