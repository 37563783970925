<div [formGroup]="form" *ngIf="isLoaded">
    <nav class="navbar p-3">
        <span class="navbar-brand mr-3 h1">Modèle d'introduction</span>
        <select formControlName="model" (change)="selectModelUpdated()">
            <option *ngIf="diagnosticPolluant.introduction.data.customModel" value="999">Modèle personnalisé</option>
            <option *ngFor="let intro of intros; let i = index" [value]="i">{{ intro.titre }}</option>
        </select>
    </nav>

    <div class="ml-3 mr-3 mt-3 mb-0">
        <verification-card
            *ngIf="typeMesurage === TypePrestationEnum.POLLUANT_VISITE_CHANTIER"
            [isInEditMode]="isInEditMode"
            [isValidated]="isValidated"
            (onCancel)="cancelModification()"
            (onSave)="saveModification()"
            (onValidate)="validateTab()"
            (onEdit)="startModification()"
            [form]="form"
        >
            Veuillez vérifier l'introduction
        </verification-card>
    </div>

    <div class="container-fluid pr-5 pl-5 pt-3">
        <div class="row d-flex justify-content-around">
            <div class="col-md-2">
                <div *ngFor="let control of form.controls | keyvalue">
                    <ng-container *ngFor="let fo of fieldsOptions">
                        <div class="d-flex mb-3" *ngIf="fo.name == control.key.slice(1, control.key.length)">
                            <!-- Affiche le nom de la variable sans le @ -->
                            <label [for]="control.key">{{ fo.label }}</label>

                            <div *ngIf="getOptionsForField(control.key)">
                                <app-widget-select
                                    [mode]="widgetSelectModeEnum.BADGE"
                                    [disabled]="!isInEditMode"
                                    [id]="control.key"
                                    [formControlName]="control.key"
                                    (ngModelChange)="configurableFieldUpdated()"
                                    [outlinedWhenUnchecked]="true"
                                >
                                    <option
                                        *ngFor="let option of getOptionsForField(control.key)"
                                        [value]="option.label"
                                        [attr.data-color]="option.backgroundColor"
                                        datatype="html"
                                    >
                                        {{ option.label }}
                                    </option>
                                </app-widget-select>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="col-md-5 d-flex flex-column">
                <label for="introduction">Introduction :</label>
                <textarea
                    id="introduction"
                    class="mat-border-radius mat-textarea"
                    formControlName="introduction"
                    cols="30"
                    rows="18"
                    [readonly]="true"
                    (change)="onModelChange()"
                ></textarea>
            </div>
            <div class="col-md-5 d-flex flex-column">
                <label for="introduction">Complément :</label>
                <textarea
                    id="complement"
                    class="mat-border-radius mat-textarea"
                    formControlName="complement"
                    cols="30"
                    rows="18"
                    [readonly]="!isInEditMode"
                ></textarea>
            </div>
        </div>
    </div>
</div>
