import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { FileApiService } from '../services/file-api.service';
import { FileData } from '../shared/offline/offline-storage.service';

/**
 * Pipe permettant ade télécharger un fichier en fonction de l'id fourni en paramètre
 */
@Pipe({
    name: 'fileDownload',
})
export class FileDownloadPipe implements PipeTransform {
    constructor(private readonly fileApi: FileApiService) {}

    transform(fileId: string, ...args: unknown[]): Observable<string> {
        if (fileId) {
            return this.fileApi.downloadFile(fileId).pipe(
                map((fileData: FileData) => {
                    return fileData && fileData.fileContent ? fileData.fileContent : null;
                })
            );
        }
        return of(null);
    }
}
