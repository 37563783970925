import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { ViewMode } from '../../../../model/bien.model';

@Component({
    selector: 'app-description-bien-mode-menu',
    templateUrl: './description-bien-mode-menu.component.html',
    styleUrls: ['./description-bien-mode-menu.component.scss'],
})
export class DescriptionBienModeMenuComponent extends BaseComponent {
    readonly VIEWMODE = ViewMode;

    @Input()
    viewMode: ViewMode;

    @Output()
    viewModeChange: EventEmitter<ViewMode> = new EventEmitter<ViewMode>();

    @Input()
    intervention: any;

    changeViewMode($event) {
        this.viewMode = $event.value;
        this.viewModeChange.emit(this.viewMode);
    }
}
