<div *ngIf="!chapitres.length" class="w-100 d-flex align-items-center flex-column">
    <span>Aucun point de contrôle</span>
</div>
<ng-template ngFor let-chapitre [ngForOf]="chapitres">
    <mat-accordion>
        <mat-expansion-panel expanded hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ chapitre.chapitre.description }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template ngFor let-checkpoint [ngForOf]="chapitre.chapitres">
                <mat-card>
                    <mat-card-title>
                        <app-section-title
                            [label]="checkpoint.chapitre.description"
                            [showHelp]="false"
                        ></app-section-title>
                    </mat-card-title>
                    <mat-card-content>
                        <ng-template ngFor let-pointControle [ngForOf]="checkpoint.pointsControles">
                            <mat-card
                                class="onHover p-1 pb-0 mb-3 w-100 d-flex flex-column justify-content-between"
                                [ngClass]="'point-de-controle-non-conforme'"
                            >
                                <mat-card-content>
                                    <div class="d-flex px-2 align-items-center mt-2">
                                        <div class="mr-auto d-flex">
                                            <div>
                                                {{ pointControle.numeroArticle }}
                                                {{ pointControle.description }}
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <button
                                                mat-icon-button
                                                color="primary"
                                                class="mr-2"
                                                matTooltip="Afficher l'aide"
                                                (click)="openHelp(pointControle)"
                                            >
                                                <lib-icon class="icon-small">help</lib-icon>
                                            </button>
                                            <app-notes-buttons
                                                class="mr-2"
                                                [from]="
                                                    diagnostic.pointsDeControleBiens[0].valeursParametres[
                                                        pointControle.idInterne
                                                    ]
                                                "
                                                [diagnostic]="diagnostic"
                                                [typesCommentaires]="COMMENT_TYPES"
                                                [replaceTypesCommentaires]="COMMENT_TYPES"
                                            >
                                            </app-notes-buttons>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </ng-template>
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
</ng-template>
