<div>
    <div class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">
        {{ 'Liste des volumes visités ou non visités' | uppercase }}
    </div>
    <div class="block-subtitle ml-4 mb-2">Liste des volumes visités</div>
    <div class="block-content px-5 ml-2 mb-4">
        <ng-container *ngFor="let bien of volumesVisites.biens">
            <div class="block-subtitle-2 mt-4 mb-2">{{ bien.bien }}</div>
            <table>
                <tr>
                    <th class="niveau">Niveau</th>
                    <th class="volume">Volume</th>
                    <ng-container *ngIf="optionPlan">
                        <th class="plan">Plan</th>
                        <th class="edit-comment"></th>
                    </ng-container>
                    <th>Commentaires</th>
                    <th class="edit-comment"></th>
                </tr>

                <tr *ngIf="bien.niveaux.length === 0">
                    <td [attr.colspan]="optionPlan ? '7' : '5'" class="text-center p-4">Aucun volume visité</td>
                </tr>

                <ng-container *ngFor="let lv of bien.niveaux">
                    <tr *ngFor="let vol of lv.volumes">
                        <td>
                            <b>{{ lv.niveau }}</b>
                        </td>
                        <td>
                            {{ vol.volume }}
                        </td>
                        <ng-container *ngIf="optionPlan">
                            <td>
                                <ng-container *ngFor="let screen of vol.plans"> {{ screen.nom }} <br /> </ng-container>
                            </td>
                            <td>
                                <button
                                    *ngIf="readonlyMode"
                                    mat-icon-button
                                    class="small-button button-group primary"
                                    (click)="onClickEditPlansList(vol)"
                                >
                                    <mat-icon class="icon-small">edit</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <td>
                            <ng-container *ngFor="let com of vol.commentaires">
                                <div [ngClass]="exclusionVolumesVisites.includes(com.id) ? 'hidden-com' : ''">
                                    {{ com.contenu }} <br />
                                </div>
                            </ng-container>
                        </td>
                        <td>
                            <button
                                mat-icon-button
                                class="small-button button-group primary"
                                *ngIf="!readonlyMode && vol.commentaires.length"
                                (click)="onClickEditDisplayedComments(vol.commentaires, volumesVisites.code)"
                            >
                                <mat-icon class="icon-small">edit</mat-icon>
                            </button>
                        </td>
                    </tr>
                </ng-container>
            </table>
        </ng-container>
    </div>

    <div class="block-subtitle ml-4 mb-2">Liste des volumes non visités et partiellement visités</div>
    <div class="px-5 ml-2 text-justify" *ngIf="disclaimer">
        {{ disclaimer }}
    </div>
    <div class="block-content px-5 ml-2 mb-4">
        <ng-container *ngFor="let bien of volumesNonVisites.biens">
            <div class="block-subtitle-2 mt-4 mb-2">{{ bien.bien }}</div>
            <table>
                <tr>
                    <th class="niveau">Niveau</th>
                    <th class="volume">Volume</th>
                    <ng-container *ngIf="optionPlan">
                        <th class="plan">Plan</th>
                        <th class="edit-comment"></th>
                    </ng-container>
                    <th class="visite">État</th>
                    <th>Commentaires</th>
                    <th class="edit-comment"></th>
                </tr>

                <tr *ngIf="bien.niveaux.length === 0">
                    <td [attr.colspan]="optionPlan ? '7' : '5'" class="text-center p-4">Aucun volume non visité</td>
                </tr>

                <ng-container *ngFor="let lv of bien.niveaux">
                    <tr *ngFor="let vol of lv.volumes">
                        <td>
                            <b>{{ lv.niveau }}</b>
                        </td>
                        <td>
                            {{ vol.volume }}
                        </td>
                        <ng-container *ngIf="optionPlan">
                            <td>
                                <ng-container *ngFor="let screen of vol.plans"> {{ screen.nom }} <br /> </ng-container>
                            </td>
                            <td>
                                <button
                                    *ngIf="readonlyMode"
                                    mat-icon-button
                                    class="small-button button-group primary"
                                    (click)="onClickEditPlansList(vol)"
                                >
                                    <mat-icon class="icon-small">edit</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <td>
                            {{ vol.visite.valeur }}
                        </td>
                        <td>
                            <ng-container *ngFor="let com of vol.commentaires">
                                <div [ngClass]="exclusionVolumesNonVisites.includes(com.id) ? 'hidden-com' : ''">
                                    {{ com.contenu }} <br />
                                </div>
                            </ng-container>
                        </td>
                        <td>
                            <button
                                mat-icon-button
                                class="small-button button-group primary"
                                *ngIf="!readonlyMode && vol.commentaires.length"
                                (click)="onClickEditDisplayedComments(vol.commentaires, volumesNonVisites.code)"
                            >
                                <mat-icon class="icon-small">edit</mat-icon>
                            </button>
                        </td>
                    </tr>
                </ng-container>
            </table>
        </ng-container>
    </div>
</div>
