import { Commentaire } from '../model/commentaire.model';
import { CommentairePredefini } from '../model/commentaire-predefini.model';
import { Intervention } from '../model/intervention.model';
import { Diagnostic } from '../model/diagnostic.model';
import { ArrayUtils } from './array.utils';

const labelCommentairesId = 'commentairesId';

/**
 * Récupération du commentaire suggéré spécifique
 */
export class CommentUtils {
    /**
     * Renvoie une liste de commentaires prédéfinis sans les entrées correspondant au commentName
     * @param commentName
     * @param commentairesGeneraux
     * @param commentairesPredefinis
     */
    static filterCommentByName(
        commentName: string,
        commentairesGeneraux: Commentaire[],
        commentairesPredefinis: CommentairePredefini[]
    ): CommentairePredefini[] {
        return commentairesPredefinis.filter((commentairePredefini) => !commentairePredefini.references.includes(commentName));
    }

    /**
     * Mapper de commentaire prédéfini en commentaire
     * @param commentairePredefini
     */
    static commentairePredefiniToCommentaireMapper(commentairePredefini: CommentairePredefini): Commentaire {
        const newComment = new Commentaire();
        newComment.id = commentairePredefini.id;
        newComment.type = commentairePredefini.typeCommentaire;
        newComment.contenu = commentairePredefini.text;
        newComment.categories = commentairePredefini.categories;
        return newComment;
    }

    /**
     * Récupère le commentaire contenu dans la liste envoyée en paramètre
     * @param commentaireId
     * @param commentaires
     */
    static getCommentaire(commentaireId: string, commentaires: Commentaire[]): Commentaire {
        return commentaires.find((commentaire) => {
            return commentaireId === commentaire.id;
        });
    }

    /**
     * Ramène les commentaireIds présent dans l'object que l'on parcourt de manière récursive
     */
    static getCommentIdsInObjectRecursively(object: any): string[] {
        let commentIds: string[] = [];

        Object.keys(object).forEach((key) => {
            if (key === labelCommentairesId) {
                // S'il y a au moins 1 commentaire
                if (object[key] && object[key].length > 0) {
                    commentIds = commentIds.concat(object[key]);
                }
            } else if (object[key] !== null && typeof object != 'string') {
                commentIds = commentIds.concat(this.getCommentIdsInObjectRecursively(object[key]));
            }
        });
        return commentIds;
    }

    /**
     * Ramène les commentaireIds présent dans la partie point de controle du diagnostic
     * Les commentaires peuvent être sur les volumes ou sur les équipements
     */
    static getCommentIdsInPointDeControleDiagnotic(diagnostic: Diagnostic): string[] {
        let commentIds: string[] = [];
        diagnostic.pointsDeControleBiens.forEach((pdb) => {
            pdb.pointsDeControleNiveaux.forEach((pdn) => {
                pdn.pointsDeControleVolumes.forEach((pdv) => {
                    // S'il y a au moins 1 commentaire
                    if (pdv.commentairesId && pdv.commentairesId.length > 0) {
                        commentIds = commentIds.concat(pdv.commentairesId);
                    }
                    pdv.pointsDeControleElements.forEach((pde) => {
                        // S'il y a au moins 1 commentaire
                        if (pde.commentairesId && pde.commentairesId.length > 0) {
                            commentIds = commentIds.concat(pde.commentairesId);
                        }
                    });
                });
            });
        });
        return commentIds;
    }

    /**
     * Ramène les commentaireIds présent dans la partie informationBien et les volumes de la description de chaque bien de l'intervention
     */
    static getCommentIdsInBien(intervention: Intervention): string[] {
        let commentIds: string[] = [];
        intervention.relationInterventionBiens.forEach((relationInterventionBien) => {
            // S'il y a au moins 1 commentaire
            if (relationInterventionBien.bien.commentairesId && relationInterventionBien.bien.commentairesId.length > 0) {
                commentIds = commentIds.concat(relationInterventionBien.bien.commentairesId);
            }

            relationInterventionBien.bien.description.forEach((niveau) => {
                niveau.volumes.forEach((volume) => {
                    // S'il y a au moins 1 commentaire
                    if (volume.commentairesId && volume.commentairesId.length > 0) {
                        commentIds = commentIds.concat(volume.commentairesId);
                    }
                });
            });
            return commentIds;
        });
        return commentIds;
    }

    /**
     * Ramène les commentaireIds commun présent dans l'intervention
     */
    static getCommentIdsCommunInIntervention(intervention: Intervention): string[] {
        // S'il y a au moins 1 commentaire
        if (intervention.commentairesId && intervention.commentairesId.length > 0) {
            return intervention.commentairesId;
        } else {
            return [];
        }
    }

    /**
     * Ramène les commentaireIds des prestations correspondantes aux diagnostics passés en paramètre
     */
    static getCommentIdsInPrestationIntervention(intervention: Intervention, diagnostics: Diagnostic[]): string[] {
        let commentIds: string[] = [];
        diagnostics.forEach((diagnostic) => {
            if (diagnostic.id !== undefined) {
                const prestationDiagnostic = intervention.prestationsDiagnostics.find((presta) => presta.idDiagnostic === diagnostic.id);
                if (prestationDiagnostic !== undefined) {
                    // S'il y a au moins 1 commentaire
                    if (prestationDiagnostic.commentairesId && prestationDiagnostic.commentairesId.length > 0) {
                        commentIds = commentIds.concat(prestationDiagnostic.commentairesId);
                    }
                }
            }
        });
        return commentIds;
    }

    /**
     * Ramène les commentaireIds des documents communs et ceux correspondant au diagnostic passé en paramètre
     */
    static getCommentIdsInDocuments(intervention: Intervention, diagnostics: Diagnostic[]): string[] {
        let commentIds: string[] = [];
        const typesPrestationValide = [...new Set(diagnostics.map((diag) => diag.typePrestation))];
        intervention.documents.forEach((doc) => {
            if (!doc.typePrestations || ArrayUtils.hasIntersectionBetweenArrays(typesPrestationValide, doc.typePrestations)) {
                // S'il y a au moins 1 commentaire
                if (doc.commentairesId && doc.commentairesId.length > 0) {
                    commentIds = commentIds.concat(doc.commentairesId);
                }
            }
        });
        return commentIds;
    }
}
