import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
    selector: 'app-form-error',
    templateUrl: './form-error.component.html',
    styleUrls: ['./form-error.component.scss'],
})
export class FormErrorComponent implements OnInit {
    /**
     * Form control
     */
    @Input()
    control: FormControl | AbstractControl;

    @Input()
    message: string;

    @Input()
    messageStyle: string;

    @Input()
    addClass = '';

    constructor() {}

    ngOnInit(): void {}
}
