import { Component, OnInit, Input } from '@angular/core';
import { InterventionReportData, IReportComponent, ReportBlockData } from '../../../../../../model/rapport.model';
import { Intervention } from 'src/app/model/intervention.model';
import { Polluant, PolluantReportData } from '../../../model/polluant.model';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { DiagnosticService } from 'src/app/services/diagnostic.service';

@Component({
    selector: 'app-poll-perimetre-block',
    templateUrl: './poll-perimetre-block.component.html',
    styleUrls: ['./poll-perimetre-block.component.scss'],
})
export class PollPerimetreBlockComponent implements OnInit, IReportComponent {
    @Input() data: ReportBlockData;
    @Input() intervention: Intervention;
    @Input() interReportData: InterventionReportData;

    values: any;
    currentContenuDiagnostic: Polluant;
    diagnostic: Diagnostic;
    reportData: PolluantReportData;

    constructor(private diagnosticService: DiagnosticService) {}

    ngOnInit(): void {
        if (this.data) {
            if (this.data.values) {
                this.values = this.data.values;
            }
        }
        this.reportData = this.interReportData.diagnostics[0] as PolluantReportData;

        console.log(this.reportData.perimetre);
    }
}
