import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-terre-installation',
    templateUrl: './terre-installation.component.html',
    styleUrls: ['./terre-installation.component.scss'],
})
export class TerreInstallationComponent implements OnInit {
    readonly MENU_NAME = 'Installation de mise à la terre';

    constructor() {}

    ngOnInit(): void {}
}
