import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-prise-terre',
    templateUrl: './prise-terre.component.html',
    styleUrls: ['./prise-terre.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriseTerreComponent implements OnInit {
    readonly MENU_NAME = 'Prise de terre, conducteur de terre et barrette de terre';

    constructor() {}

    ngOnInit(): void {}
}
