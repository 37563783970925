import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { OfflineStorageService } from './offline-storage.service';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    imports: [NgxIndexedDBModule, HttpClientModule],
    declarations: [],
    exports: [],
    entryComponents: [],
})
export class OfflineStorageModule {
    // Méthode static permettant d'éviter les multiples instance des handlers
    // lors d'un routage en lazy loading, avec loadChildren par exemple
    static forRoot(): ModuleWithProviders<OfflineStorageModule> {
        return {
            ngModule: OfflineStorageModule,
            providers: [OfflineStorageService],
        };
    }

    constructor() {}
}
