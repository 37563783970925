import { Injectable } from '@angular/core';
import { Zone } from '../model/zone.model';
import { ConditionsPrelevement } from '../model/polluant-config.model';
import { MpcaItemOld, MpcaProcessus } from '../model/mpca.model.old';

@Injectable({
    providedIn: 'root',
})
export class FrequenceService {
    constructor() {}

    getFrequenceObj(condition: ConditionsPrelevement, zone: Zone): string {
        if (condition != undefined) {
            if (condition.calculAutoFrequence) {
                let zoneDeCalcul;
                if (zone.zoneAssociee != null) {
                    zoneDeCalcul = zone.zoneAssociee;
                } else {
                    zoneDeCalcul = zone;
                }
                return this.determineFrequenceMinimale(zoneDeCalcul);
            } else return condition.frequence;
        }
        return null;
    }

    determineFrequenceMinimale(zone: Zone): string {
        return this.determineFrequence(this.calculPoints(zone));
    }

    private calculPoints(zone: Zone): number {
        let points: number = 0;
        points += this.activiteAdjacente(zone.activiteAdjacente);
        points += this.environnement(zone.environnement);
        points += this.concentrationFibre(zone.listeMpca);
        points += this.moyenProtection(zone.confinement);

        return points;
    }

    private determineFrequence(points: number): string {
        switch (true) {
            case points <= 5:
                return '1 fois par quinzaine';
            case points <= 7:
                return '1 fois par semaine';
            case points <= 9:
                return '2 fois par semaine';
            case points <= 11:
                return "Cotation incohérente vis-à-vis de l'analyse de risque du commanditaire";
        }
        return '';
    }

    private moyenProtection(confinement: string) {
        switch (confinement) {
            case 'Aucun':
                return 3;
            case 'Isolement simple de la zone':
                return 2;
            case 'Calfeutrement de la zone':
                return 2;
        }
        return 1;
    }

    private concentrationFibre(listeMpca: MpcaItemOld[]): number {
        let un: boolean = false;
        let deux: boolean = false;
        let trois: boolean = false;
        listeMpca.forEach((mpca: MpcaItemOld) => {
            mpca.processus.forEach((proces: MpcaProcessus) => {
                if (proces.echantillonnage?.concentrationAttendue) {
                    switch (true) {
                        case proces.echantillonnage?.concentrationAttendue < 100:
                            un = true;
                            break;
                        case proces.echantillonnage?.concentrationAttendue < 6000:
                            deux = true;
                            break;
                        case proces.echantillonnage?.concentrationAttendue >= 6000:
                            trois = true;
                            break;
                        default:
                            return 0;
                    }
                }
            });
        });

        if (trois) return 3;
        else if (deux) return 2;
        else return 1;
    }

    private environnement(environnement: string[]): number {
        if (environnement.includes('Intérieur')) return 2;
        else if (environnement.includes('Extérieur')) return 1;
        else return 0;
    }

    private activiteAdjacente(activiteAdjacente: string): number {
        switch (activiteAdjacente) {
            case 'Public':
                return 3;
                break;

            case 'Entreprise':
                return 2;
                break;

            default:
                return 1;
        }
    }
}
