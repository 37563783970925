import { Espace } from '../../../../model/espace.model';
import { Diagnostic } from '../../../../model/diagnostic.model';
import { LevelToDisplay } from '../model/hap.model';
import { SeuilsToDisplay } from '../services/hap-cn-map.service';
import { LegendScreenshot, LegendScreenshotItem, LegendShape } from '../../../../model/screenshot-svg.model';
import { CndiagMarker } from '../../../shared/map/model/cndiag-marker.model';
import { SEUIL_ENTRE_501_ET_1000, SEUIL_ENTRE_51_ET_500, SEUIL_INF_50, SEUIL_SUP_1000 } from '../constantes/hap.constantes';
import { TypePrestation, TypePrestationEnum } from '../../../../model/type-prestation.model';
import { Prelevement, ResultSeuil } from '../../../../model/prelevement-generique.model';
import {
    SvgFormValuesHapPrelevements,
    SvgFormValuesHapZones,
} from '../../../shared/map/export-svg-dialog/shared/interfaces/export-svg-form-values.interface';

export const mapLegends = new Map([
    [
        0,
        {
            nom: SEUIL_INF_50.label,
            couleur: SEUIL_INF_50.color,
        },
    ],
    [
        1,
        {
            nom: SEUIL_ENTRE_51_ET_500.label,
            couleur: SEUIL_ENTRE_51_ET_500.color,
        },
    ],
    [
        2,
        {
            nom: SEUIL_ENTRE_501_ET_1000.label,
            couleur: SEUIL_ENTRE_501_ET_1000.color,
        },
    ],
    [
        3,
        {
            nom: SEUIL_SUP_1000.label,
            couleur: SEUIL_SUP_1000.color,
        },
    ],
    [
        undefined,
        {
            nom: 'Non réalisés',
            couleur: '#000000',
        },
    ],
]);

function labelForBesoins(diagnostic?: Diagnostic) {
    const types: TypePrestation[] = [TypePrestationEnum.HAP_TERRAIN, TypePrestationEnum.HAP_VALIDATION_RESULTATS, TypePrestationEnum.HAP_VALIDATION];
    if (diagnostic && types.includes(diagnostic.typePrestation)) {
        return 'Prélèvements';
    } else {
        return 'Besoins';
    }
}

export function showSeuilsZones(seuilsToDisplay: SeuilsToDisplay) {
    return [
        seuilsToDisplay.showZoneInferieurEgal50,
        seuilsToDisplay.showZoneEntre51Et500,
        seuilsToDisplay.showZoneEntre501Et1000,
        seuilsToDisplay.showZoneSuperieur1000,
        seuilsToDisplay.showZoneNonRealises,
    ].some((show) => show);
}

export function showSeuilsPrelevements(seuilsToDisplay: SvgFormValuesHapZones & SvgFormValuesHapPrelevements) {
    return [
        seuilsToDisplay.showPrelevementInferieurEgal50,
        seuilsToDisplay.showPrelevementEntre51Et500,
        seuilsToDisplay.showPrelevementEntre501Et1000,
        seuilsToDisplay.showPrelevementSuperieur1000,
        seuilsToDisplay.showPrelevementNonRealises,
    ].some((show) => show);
}

export function buildLegend(
    espace: Espace,
    filters: { levelsToDisplay: LevelToDisplay[]; seuilsToDisplay: SeuilsToDisplay },
    diagnostic?: Diagnostic
): LegendScreenshot[] {
    if (!filters) {
        return [];
    }

    function filterByLevel(level: LevelToDisplay) {
        return (marker) => filters.levelsToDisplay.includes(level) && marker.level === level;
    }
    const parsedMarkers = espace.listeMarkersJson.map((markerJsonTemp) => JSON.parse(markerJsonTemp) as Partial<CndiagMarker>);
    const legendePerimetres: Partial<CndiagMarker>[] = parsedMarkers.filter(filterByLevel(LevelToDisplay.PERIMETRE));

    const legendeZones: Partial<CndiagMarker>[] = !showSeuilsZones(filters.seuilsToDisplay)
        ? parsedMarkers.filter(filterByLevel(LevelToDisplay.ZONE))
        : [];

    const legendeBesoinsPrelevements: Partial<CndiagMarker>[] = !showSeuilsPrelevements(filters.seuilsToDisplay)
        ? parsedMarkers.filter((it) => filterByLevel(LevelToDisplay.BESOIN)(it) || filterByLevel(LevelToDisplay.PRELEVEMENT)(it))
        : [];

    const legendeSeuils: Partial<CndiagMarker>[] = parsedMarkers.filter(
        (marker) =>
            (marker.level === LevelToDisplay.ZONE && showSeuilsZones(filters.seuilsToDisplay) && marker.resultLevel !== undefined) ||
            ([LevelToDisplay.PRELEVEMENT, LevelToDisplay.BESOIN].includes(marker.level) && showSeuilsPrelevements(filters.seuilsToDisplay))
    );

    const legendsScreenshot: LegendScreenshot[] = [];
    if (legendePerimetres.length) {
        const perimetres: LegendScreenshotItem[] = legendePerimetres.map((perimetre) => ({
            nom: perimetre.label,
            couleur: perimetre.shape_color,
            opacity: perimetre.shape_opacity,
            shapes: [LegendShape.EMPTY_SQUARE],
        }));

        legendsScreenshot.push({ nomCategorie: 'Périmètres', items: perimetres });
    }

    if (legendeZones.length) {
        const items: LegendScreenshotItem[] = legendeZones.map((zone) => ({
            nom: zone.label,
            couleur: zone.shape_color,
            opacity: zone.shape_opacity,
            shapes: [LegendShape.FILLED_SQUARE],
        }));

        legendsScreenshot.push({ nomCategorie: 'Zones', items });
    }

    if (legendeBesoinsPrelevements.length) {
        const items = [{ opacity: 1, nom: labelForBesoins(diagnostic), couleur: '#ff6c00', shapes: [LegendShape.LINE] }];
        legendsScreenshot.push({ nomCategorie: labelForBesoins(diagnostic), items });
    }

    if (legendeSeuils.length) {
        const seuilFilter: { [key: number]: boolean } = {
            [ResultSeuil.INFERIEUR_EGAL_50]:
                filters.seuilsToDisplay.showPrelevementInferieurEgal50 || filters.seuilsToDisplay.showZoneInferieurEgal50,
            [ResultSeuil.ENTRE_51_ET_500]: filters.seuilsToDisplay.showPrelevementEntre51Et500 || filters.seuilsToDisplay.showZoneEntre51Et500,
            [ResultSeuil.ENTRE_501_ET_1000]: filters.seuilsToDisplay.showPrelevementEntre501Et1000 || filters.seuilsToDisplay.showZoneEntre501Et1000,
            [ResultSeuil.SUPERIEUR_1000]: filters.seuilsToDisplay.showPrelevementSuperieur1000 || filters.seuilsToDisplay.showZoneSuperieur1000,
            [-1]: filters.seuilsToDisplay.showPrelevementNonRealises || filters.seuilsToDisplay.showZoneNonRealises,
        };

        const uniqLevels: LevelToDisplay[] = [...new Set(legendeSeuils.map((p) => p.level))].sort((a, b) => a - b);
        const uniqSeuils: ResultSeuil[] = [...new Set(legendeSeuils.map((p) => p.resultLevel))]
            .filter((seuil) => seuilFilter[seuil || -1])
            .sort((a, b) => a - b);

        const nomCategorie = uniqLevels
            .map((level) =>
                level === LevelToDisplay.ZONE ? 'Zones' : [LevelToDisplay.PRELEVEMENT, LevelToDisplay.BESOIN].includes(level) ? 'Prélèvements' : ''
            )
            .filter(Boolean)
            .join(' / ');

        const shapes: LegendShape[] = [];
        if (uniqLevels.includes(LevelToDisplay.ZONE)) {
            shapes.push(LegendShape.FILLED_SQUARE);
        }
        if ([LevelToDisplay.BESOIN, LevelToDisplay.PRELEVEMENT].some((it) => uniqLevels.includes(it))) {
            shapes.push(LegendShape.LINE);
        }

        const items = uniqSeuils
            .filter((it) => mapLegends.get(it).nom)
            .map((seuil) => new LegendScreenshotItem(mapLegends.get(seuil).nom, mapLegends.get(seuil).couleur, 1, shapes));

        if (items.length) {
            legendsScreenshot.push({ nomCategorie, items });
        }
    }

    return legendsScreenshot;
}

export function filterMarkers(
    levelsActif: LevelToDisplay[],
    showBySeuil: SvgFormValuesHapZones & SvgFormValuesHapPrelevements,
    listePrelevementsNonRealises: Prelevement[]
) {
    return (marker) => {
        // Filtre des markers selon le niveau à afficher
        if (!levelsActif.includes(marker.level)) {
            return false;
        }

        if (marker.level === LevelToDisplay.PERIMETRE && levelsActif.includes(LevelToDisplay.PERIMETRE)) {
            return true;
        }

        if (marker.level === LevelToDisplay.PRELEVEMENT || marker.level === LevelToDisplay.BESOIN) {
            const isPrelevementNonRealise = listePrelevementsNonRealises.map((pvt) => pvt.id).includes(marker.idReference);
            if (!showSeuilsPrelevements(showBySeuil) && !isPrelevementNonRealise) {
                return true;
            }
            if (marker.resultLevel === ResultSeuil.INFERIEUR_EGAL_50 && showBySeuil.showPrelevementInferieurEgal50) {
                return true;
            }

            if (marker.resultLevel === ResultSeuil.ENTRE_51_ET_500 && showBySeuil.showPrelevementEntre51Et500) {
                return true;
            }

            if (marker.resultLevel === ResultSeuil.ENTRE_501_ET_1000 && showBySeuil.showPrelevementEntre501Et1000) {
                return true;
            }

            if (marker.resultLevel === ResultSeuil.SUPERIEUR_1000 && showBySeuil.showPrelevementSuperieur1000) {
                return true;
            }

            if (marker.resultLevel === undefined && showBySeuil.showPrelevementNonRealises && isPrelevementNonRealise) {
                return true;
            }
        }

        if (marker.level === LevelToDisplay.ZONE) {
            if (!showSeuilsZones(showBySeuil) && levelsActif.includes(LevelToDisplay.ZONE)) {
                return true;
            }
            if (marker.resultLevel === ResultSeuil.INFERIEUR_EGAL_50 && showBySeuil.showZoneInferieurEgal50) {
                return true;
            }

            if (marker.resultLevel === ResultSeuil.ENTRE_51_ET_500 && showBySeuil.showZoneEntre51Et500) {
                return true;
            }

            if (marker.resultLevel === ResultSeuil.ENTRE_501_ET_1000 && showBySeuil.showZoneEntre501Et1000) {
                return true;
            }

            if (marker.resultLevel === ResultSeuil.SUPERIEUR_1000 && showBySeuil.showZoneSuperieur1000) {
                return true;
            }
        }
        return false;
    };
}
