import { Pipe, PipeTransform } from '@angular/core';

/**
 * Affiche le nom d'un type de bien
 */
@Pipe({
    name: 'filenameWithoutExtension',
})
export class FilenameWithoutExtensionPipe implements PipeTransform {
    constructor() {}

    transform(filename: string): string {
        return filename.substr(0, filename.lastIndexOf('.'));
    }
}
