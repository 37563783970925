<app-mpca-form-skeleton>
    <div title>Pose META</div>
    <div form class="form" [formGroup]="formPose">
        <div class="column">
            <div class="section">
                <div class="header">Informations client</div>
                <div class="cndiad-form-field">
                    <mat-label class="required">Fait par le client</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="faitParLeClient"
                        [disabled]="disabled"
                    >
                        <option value="true" datatype="icon">check</option>
                        <option value="false" datatype="icon">close</option>
                    </app-widget-select>
                </div>
            </div>
            <div class="section">
                <div class="header">Informations client</div>
                <mat-form-field appearance="outline">
                    <mat-label>Localisation du prélèvement</mat-label>
                    <input matInput formControlName="localisationPrelevement" [readonly]="disabled" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Lot filtre</mat-label>
                    <input matInput required formControlName="lotFiltre" [readonly]="disabled" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Numéro de pompe</mat-label>
                    <input matInput formControlName="numeroPompe" [readonly]="disabled" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Numéro de tête</mat-label>
                    <input matInput formControlName="numeroTete" [readonly]="disabled" />
                </mat-form-field>
                <mat-form-field class="ml-3">
                    <mat-label>Date début: </mat-label>
                    <input
                        matInput
                        readonly
                        formControlName="dateDebutPrelevement"
                        [ngxMatDatetimePicker]="datepickerDebut"
                    />
                    <mat-datepicker-toggle matSuffix [for]="datepickerDebut"> </mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #datepickerDebut [showSeconds]="false"></ngx-mat-datetime-picker>
                </mat-form-field>
                <mat-form-field class="ml-3">
                    <mat-label>Date fin: </mat-label>
                    <input
                        matInput
                        readonly
                        formControlName="dateFinPrelevement"
                        [ngxMatDatetimePicker]="datepickerFin"
                    />
                    <mat-datepicker-toggle matSuffix [for]="datepickerFin"> </mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #datepickerFin [showSeconds]="false"></ngx-mat-datetime-picker>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Durée totale du prélèvement:</mat-label>
                    <input matInput [readonly]="true" placeholder="en heures" [value]="calculDuree()" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Débit initial:</mat-label>
                    <input matInput formControlName="debitInitial" [readonly]="disabled" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Remarques: </mat-label>
                    <input matInput formControlName="remarques" [readonly]="disabled" />
                </mat-form-field>
            </div>
        </div>
        <div class="column">
            <div class="section">
                <div class="header">Informations météo</div>
                <app-information-meteo
                    [formInformationMeteo]="formPose.get('informationMeteo')"
                ></app-information-meteo>
            </div>
            <div class="section">
                <div class="header">Conformité</div>
                <div class="cndiad-form-field">
                    <mat-label class="required">Prélèvement réglementaire</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="prelevementReglementaire"
                        [disabled]="disabled"
                        (ngModelChange)="onChangePreloReglementaire()"
                    >
                        <option value="true" datatype="icon">check</option>
                        <option value="false" datatype="icon">close</option>
                    </app-widget-select>
                </div>
                <app-custom-select
                    class="w-100"
                    formControlName="justificatifNonReglementaire"
                    [required]="justificatifNonReglementaireRequired"
                    [choices]="listeJustificationNonReglementaire"
                    [addLineButtonLabel]="'Ajouter un justificatif'"
                    [sorted]="true"
                    [placeholder]="'Justificatif non réglementaire'"
                    [matFormFieldClass]="'w-100'"
                    [multiple]="false"
                >
                </app-custom-select>
                <!--
                    <mat-form-field appearance="outline">
                        <mat-label>Justificatif non réglementaire</mat-label>
                        <mat-select required formControlName="lotFiltre" [disabled]="disabled">
                            <mat-option value="absenceStrategie">Absence de stratégie</mat-option>
                            <mat-option value="autre">Autre</mat-option>
                        </mat-select>
                    </mat-form-field>
                    -->

                <div class="cndiad-form-field">
                    <mat-label class="required">Accréditation cofrac</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="accreditationCofrac"
                        [disabled]="disabled"
                    >
                        <option value="true" datatype="icon">check</option>
                        <option value="false" datatype="icon">close</option>
                    </app-widget-select>
                </div>
            </div>
            <div class="section" [hidden]="!isChangeStrategie">
                <div class="header">Validation stratégie</div>

                <mat-form-field appearance="outline">
                    <mat-label>Justificatif de changement</mat-label>
                    <input
                        matInput
                        formControlName="justificatifChangement"
                        [readonly]="disabled"
                        [required]="isChangeStrategie"
                    />
                </mat-form-field>

                <!-- CLIENT PREVENU PAR -->
                <div class="cndiad-form-field">
                    <mat-label class="required">Client prévenu par</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="clientPrevenuPar"
                        [disabled]="disabled"
                        [required]="isChangeStrategie"
                    >
                        <option value="surPlace" datatype="label">Sur place</option>
                        <option value="telephone" datatype="label">Téléphone</option>
                        <option value="email" datatype="label">Email</option>
                    </app-widget-select>
                </div>
            </div>
        </div>
    </div>
</app-mpca-form-skeleton>
