<div class="container">
    <div *ngIf="!iSolutions.length && !infosGeneral.length && !caracteristiques.length && !documentsAno.length" class="py-4 d-flex flex-column">
        <div class="text-center">Aucun point de contrôle</div>
    </div>
    <!-- INFORMATIONS GÉNÉRALES -->
    <div *ngIf="infosGeneral.length" class="p-4 d-flex flex-column">
        <mat-accordion>
            <mat-expansion-panel expanded hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title> {{ 'Informations générales' | uppercase }}</mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngTemplateOutlet="templatePTC; context: { pointsDeControle: infosGeneral }"></ng-container>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <!-- DOCUMENTS  -->
    <div *ngIf="documentsAno.length" class="p-4 d-flex flex-column">
        <mat-accordion>
            <mat-expansion-panel expanded hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>DOCUMENTS</mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngTemplateOutlet="templatePTC; context: { pointsDeControle: documentsAno }"></ng-container>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <!-- CARACTÉRISTIQUES  -->
    <div *ngIf="caracteristiques.length" class="p-4 d-flex flex-column">
        <mat-accordion>
            <mat-expansion-panel expanded hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>CARACTÉRISTIQUES</mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngTemplateOutlet="templatePTC; context: { pointsDeControle: caracteristiques }"></ng-container>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <!-- POINTS DE CONTROLES -->
    <div *ngIf="iSolutions.length" class="p-4 d-flex flex-column">
        <ng-template ngFor let-solution [ngForOf]="iSolutions">
            <mat-accordion>
                <mat-expansion-panel expanded hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ solution.name | uppercase }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <!-- Reste à faire : infos de l'isolant                     -->
                    <ng-container *ngTemplateOutlet="templatePTC; context: { pointsDeControle: solution.pointsDeControle }"></ng-container>
                    <!-- Reste à faire : points de controles-->
                    <ng-template ngFor let-zone [ngForOf]="solution.zones">
                        <mat-accordion>
                            <mat-expansion-panel expanded hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{ zone.name | uppercase }}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <ng-container *ngTemplateOutlet="templatePTC; context: { pointsDeControle: zone.pointsDeControle }"></ng-container>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </ng-template>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-template>
    </div>
</div>

<ng-template #templatePTC let-pointsDeControle="pointsDeControle">
    <ng-template ngFor let-pointDeControle [ngForOf]="pointsDeControle">
        <mat-card
            matRipple
            [routerLink]="pointDeControle.path"
            class="onHover p-1 pb-0 mb-3 w-100 d-flex flex-column justify-content-between"
            [ngClass]="'point-de-controle-non-rempli'"
        >
            <mat-card-content>
                <div class="p-2 mr-auto d-flex">
                    <div>
                        {{ pointDeControle.libelle }}
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </ng-template>
</ng-template>
