<div>
    <div class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">
        {{ 'Commentaires' | uppercase }}
    </div>

    <ng-container>
        <div class="block-subtitle ml-4 mb-2">Recommandations</div>
        <div *ngIf="recommandations.length" class="px-5 ml-2 mb-4 bloc-content text-justify">
            {{ recommandations.join('.\n') }}
        </div>
        <div *ngIf="recommandations.length === 0" class="px-5 ml-2 mb-4 bloc-content text-justify">
            Aucune recommandation.
        </div>
    </ng-container>

    <ng-container>
        <div class="block-subtitle ml-4 mb-2">Constatations diverses</div>
        <div *ngIf="constatationsDiverses.length" class="px-5 ml-2 mb-4 bloc-content text-justify">
            {{ constatationsDiverses.join('.\n') }}
        </div>
        <div *ngIf="constatationsDiverses.length === 0" class="px-5 ml-2 mb-4 bloc-content text-justify">
            Aucune constatation.
        </div>
    </ng-container>

    <ng-container>
        <div class="block-subtitle ml-4 mb-2 flex-grow-1">Constatations spécifiques</div>

        <div class="block-content px-5 ml-2 mb-5" *ngIf="constatationsSpecifiques.biens.length">
            <ng-container *ngFor="let bien of constatationsSpecifiques.biens">
                <div class="block-subtitle-2 mt-4 mb-2">{{ bien.bien }}</div>
                <table>
                    <tr>
                        <th class="niveau">Niveau / Volume</th>

                        <ng-container *ngIf="optionPlan">
                            <th class="plan">Plan</th>
                            <th class="edit-comment"></th>
                        </ng-container>

                        <th class="equip">Équipements</th>
                        <th>Constatations</th>
                        <th class="edit-comment"></th>
                    </tr>

                    <tr *ngIf="bien.niveaux.length === 0">
                        <td [attr.colspan]="optionPlan ? '7' : '5'" class="text-center p-4">
                            Aucune constatation spécifique
                        </td>
                    </tr>

                    <ng-container *ngFor="let lv of bien.niveaux">
                        <tr>
                            <td [attr.colspan]="optionPlan ? '7' : '5'" class="row-niveau">
                                {{ lv.niveau }}
                            </td>
                        </tr>

                        <ng-container *ngFor="let vol of lv.volumes">
                            <tr *ngFor="let el of vol.equipements">
                                <td>
                                    <b>{{ vol.volume }}</b>
                                </td>
                                <ng-container *ngIf="optionPlan">
                                    <td>
                                        <ng-container *ngFor="let screen of vol.plans">
                                            {{ screen.nom }} <br />
                                        </ng-container>
                                    </td>
                                    <td>
                                        <button
                                            *ngIf="readonlyMode"
                                            mat-icon-button
                                            class="small-button button-group primary"
                                            (click)="onClickEditPlansList(vol)"
                                        >
                                            <mat-icon class="icon-small">edit</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>
                                <td>
                                    {{ el.element }}
                                </td>
                                <td>
                                    <ng-container *ngFor="let com of el.commentaires">
                                        <div
                                            [ngClass]="
                                                exclusionsConstatationsSpecifiques.includes(com.id) ? 'hidden-com' : ''
                                            "
                                        >
                                            {{ com.contenu }} <br />
                                        </div>
                                    </ng-container>
                                </td>
                                <td>
                                    <button
                                        mat-icon-button
                                        class="small-button button-group primary"
                                        *ngIf="!readonlyMode && el.commentaires.length"
                                        (click)="
                                            onClickEditDisplayedComments(el.commentaires, constatationsSpecifiques.code)
                                        "
                                    >
                                        <mat-icon class="icon-small">edit</mat-icon>
                                    </button>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </table>
            </ng-container>
        </div>
    </ng-container>
</div>
