import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LibFileUploaderModule, LibIconModule } from 'src/app/commons-lib';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { GestionTypesOuvrageComponent } from './gestion-types-ouvrage.component';
import { CreationTypesOuvrageComponent } from './creation-types-ouvrage/creation-types-ouvrage.component';
import { PipesModule } from '../../../../pipes/pipes.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CnDiagSharedModule } from '../../../shared/shared.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ArbreTypesOuvrageComponent } from './arbre-types-ouvrage/arbre-types-ouvrage.component';
import { TreeModule } from '@circlon/angular-tree-component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
    declarations: [GestionTypesOuvrageComponent, CreationTypesOuvrageComponent, ArbreTypesOuvrageComponent],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatSelectModule,
        LibIconModule,
        MatButtonModule,
        MatExpansionModule,
        MatIconModule,
        ReactiveFormsModule,
        MatButtonToggleModule,
        PipesModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        CnDiagSharedModule,
        FormsModule,
        MatMenuModule,
        MatProgressBarModule,
        TreeModule,
        LibFileUploaderModule,
    ],
})
export class GestionTypesOuvrageModule {}
