<form [formGroup]="form" (ngSubmit)="onSubmitForm()">
    <div class="fullscreen-content">
        <div class="header">
            <div class="title">Création d'un reportage photo</div>
            <button mat-button class="close-button" type="button" (click)="onClickBtnCancel()">
                <lib-icon>close</lib-icon>
            </button>
        </div>
        <div class="dialog-content scrollable-content">
            <div class="row no-gutters">
                <div class="col-6 pl-4 pr-4 pt-4">
                    <!-- Form-->
                    <mat-form-field appearance="fill" class="mr-1">
                        <mat-label>Nom</mat-label>
                        <input [readonly]="readonlyMode" matInput formControlName="nom" type="text" placeholder="Nom" />
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="ml-1">
                        <mat-label>Type de reportage</mat-label>
                        <mat-select formControlName="typePreset">
                            <mat-option (onSelectionChange)="presetSelectionChanged($event)"></mat-option>
                            <mat-option
                                *ngFor="let preset of presetReportagePhoto"
                                [value]="preset"
                                (onSelectionChange)="presetSelectionChanged($event)"
                            >
                                {{ preset | presetPhoto }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-checkbox
                        *ngIf="form?.value?.typePreset"
                        color="primary"
                        formControlName="isSubscribing"
                        [disabled]="readonlyMode"
                        (change)="isSubscribingModified($event.checked)"
                        >MAJ automatiques
                    </mat-checkbox>
                </div>
            </div>

            <div class="row no-gutters">
                <div class="col-6">
                    <div class="text-center">
                        {{ reportagePhotoImageFilesSelected.length }} photo{{
                            reportagePhotoImageFilesSelected.length > 1 ? 's' : ''
                        }}
                        sélectionnée{{ reportagePhotoImageFilesSelected.length > 1 ? 's' : '' }}
                    </div>
                    <!--Liste de selection -->
                    <app-reportage-photo-edit-selection-list
                        [reportagePhoto]="reportagePhoto"
                        [imagesFiles]="reportagePhotoImageFiles"
                        [readonlyMode]="readonlyMode"
                        (imageFileSelected)="!readonlyMode ? onSelectImageFile($event) : ''"
                        (legendChanged)="onLegendChanged($event)"
                        (autoGeneratedChanged)="onAutoGeneratedChanged($event)"
                    ></app-reportage-photo-edit-selection-list>
                </div>
                <div class="col-6">
                    <!-- Liste de prévisualisation des pages -->
                    <app-reportage-photo-edit-preview-list
                        [imagesFilesSelected]="reportagePhotoImageFilesSelected"
                        [title]="form?.get('nom')?.value"
                    ></app-reportage-photo-edit-preview-list>
                </div>
            </div>
        </div>
    </div>

    <!--MODAL FOOTER ACTIONS-->
    <div mat-dialog-actions class="justify-content-end">
        <button type="button" mat-button color="primary" (click)="onClickBtnCancel()">
            <mat-icon>close</mat-icon>
            <span>{{ readonlyMode ? 'Fermer' : 'Annuler' }}</span>
        </button>
        <button
            *ngIf="!readonlyMode"
            type="button"
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="form?.invalid || reportagePhotoImageFilesSelected.length === 0"
        >
            <mat-icon>save_alt</mat-icon>
            <span>Sauvegarder</span>
        </button>
    </div>
</form>
