<div class="toolbar">
    <mat-toolbar class="border-bottom-1 primary">
        <button mat-button class="px-0" (click)="back()">
            <lib-icon>arrow_back</lib-icon>
        </button>
        <div>
            <lib-icon [class]="'icon-before-text'">settings</lib-icon>
            <span *ngIf="mode === 'CREATE' && !isReadOnly">Création d'un bien</span>
            <span *ngIf="mode === 'EDIT' && !isReadOnly">Edition d'un bien</span>
            <span *ngIf="mode === 'DUPLICATE' && !isReadOnly">Duplication du bien</span>
            <span *ngIf="isReadOnly">Consultation du bien</span>
        </div>

        <div class="d-flex justify-content-end w-100">
            <button mat-button color="primary" class="mx-1" (click)="back()">
                <mat-icon>close</mat-icon>
                <span>{{ 'Annuler' | uppercase }}</span>
            </button>
            <button
                *ngIf="!isReadOnly"
                mat-raised-button
                color="primary"
                [disabled]="formBien.invalid"
                (click)="validerBien()"
            >
                <lib-icon>check</lib-icon>
                <span>{{ 'Valider' | uppercase }}</span>
            </button>
            <button *ngIf="isReadOnly" mat-raised-button color="primary" (click)="editer()">
                <mat-icon>edit</mat-icon>
                <span>{{ 'Éditer' | uppercase }}</span>
            </button>
        </div>
    </mat-toolbar>
</div>
<div *ngIf="loadingComplete" class="h-100 row no-gutters p-4 scrollable-content">
    <app-edit-bien
        class="col-xl-12"
        [bien]="bien"
        [formInfo]="formBien"
        [isEditMode]="!isReadOnly"
        [isAdminMode]="true"
    ></app-edit-bien>
</div>
