<app-mpca-form-skeleton *ngIf="isLoaded">
    <div title>Prélèvement METOP</div>
    <div form class="form" [formGroup]="formAvant">
        <div class="column">
            <div class="section">
                <div class="header">Informations client</div>
                <div class="cndiad-form-field">
                    <mat-label class="required">Fait par le client</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="faitParLeClient"
                        [disabled]="disabled"
                    >
                        <option value="true" datatype="html">
                            Oui
                            <mat-icon>check</mat-icon>
                        </option>
                        <option value="false" datatype="html">
                            Non
                            <mat-icon>close</mat-icon>
                        </option>
                    </app-widget-select>
                </div>
            </div>
            <div class="section">
                <div class="header">Informations client</div>
                <mat-form-field appearance="outline">
                    <mat-label>Lot filtre</mat-label>
                    <input matInput required type="string" formControlName="lotFiltre" [readonly]="disabled" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Durée totale du prélèvement</mat-label>
                    <input
                        matInput
                        [readonly]="disabled"
                        placeholder="en heures"
                        formControlName="dureeTotalePrelevement"
                        type="number"
                    />
                </mat-form-field>
            </div>
        </div>
        <div class="column">
            <div class="section">
                <div class="header">Informations météo</div>
                <app-information-meteo
                    [formInformationMeteo]="formAvant.get('informationMeteo')"
                ></app-information-meteo>
            </div>
            <div class="section">
                <filtres-item-list
                    [filterForm]="this.formAvant.get('listeFiltres')"
                    (add)="ajouterFiltre()"
                    (edit)="modifierFiltre($event)"
                    (delete)="supprimerFiltre($event)"
                    (duplicate)="dupliquerFiltre($event)"
                    [disabled]="disabled"
                ></filtres-item-list>
            </div>

            <button mat-button class="bt bouton-impression" (click)="impressionEtiquette()">
                <lib-icon>printer-wireless</lib-icon>
                {{ "Imprimer l'étiquette" }}
            </button>
        </div>
    </div>
</app-mpca-form-skeleton>
