import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/commons-lib';
import { Espace } from '../../../../../../model/espace.model';
import { SvgConfigType } from '../../shared/conf/export-svg-types.conf';

@Component({
    selector: 'app-export-svg-sub-form-espaces',
    templateUrl: './export-svg-sub-form-espaces.component.html',
    styleUrls: ['./export-svg-sub-form-espaces.component.scss'],
})
export class ExportSvgSubFormEspacesComponent extends BaseComponent {
    @Input() group: FormGroup;

    @Input() espaces: Espace[] = [];

    @Input() formConf: SvgConfigType;

    constructor() {
        super();
    }

    compareWith(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }
}
