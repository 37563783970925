<div class="d-flex justify-content-center">
    <mat-card class="p-2 mt-5 d-flex col-8">
        <mat-card-content class="w-100">
            <div class="headline m-0 primary">Lots de filtres & blanc de terrain</div>
            <div class="d-flex p-2 w-50">
                <mat-list class="w-100">
                    <div matSubheader>
                        <button color="accent" mat-icon-button class="bt-valid mr-3" (click)="onAddLot()">
                            <lib-icon>add</lib-icon>
                        </button>
                        Lot de filtre
                    </div>
                    <ng-container *ngFor="let item of lstLotDeFiltre">
                        <mat-list-item mat-ripple>
                            <p style="background-color: #e0e0e0; padding: 10px; border-radius: 25px" matLine>{{ item.valeur }}</p>
                            <app-notes-buttons class="mr-2" [from]="item" [diagnostic]="diagnostic"> </app-notes-buttons>
                            <button mat-icon-button color="warn" matTooltip="Supprimer" (click)="onDeleteLot(item)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-list-item>
                    </ng-container>
                    <mat-list-item mat-ripple *ngIf="isAdding">
                        <app-custom-select
                            class="w-100"
                            [choices]="confLstLotDeFiltre"
                            [addLineButtonLabel]="'Ajouter un lot'"
                            [matFormFieldClass]="'w-100'"
                            (valueChangeFromUser)="onChangeValueByUser($event)"
                        >
                        </app-custom-select>
                        <app-notes-buttons class="mr-2" [from]="currentItem" [diagnostic]="diagnostic"> </app-notes-buttons>
                        <button mat-icon-button color="warn" matTooltip="Supprimer" (click)="isAdding = false">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-list-item>
                </mat-list>
            </div>
        </mat-card-content>
    </mat-card>
</div>
