import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { BaseComponent, NotificationService } from 'src/app/commons-lib';
import { BehaviorSubject } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { UserWizy } from 'src/app/model/user-wizy.model';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { ManagementApiService } from 'src/app/services/management-api.service';
import {
    DEBOUNCE_TIME_VALUE,
    INITIAL_PAGE_SIZE,
    SESSION_STORAGE_KEY_EMPLOYE,
    SESSION_STORAGE_KEY_INTERVENTION,
} from 'src/app/shared/constants/admin.constants';
import { PageRequest, PaginatedDataSource } from 'src/app/shared/paging/page';
import { SessionStoragePaginationUtils } from 'src/app/utils/session-storage-pagination.utils';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { InputDateModalComponent } from 'src/app/modules/shared/input-date-modal/input-date-modal.component';
import { MAP_ROLE } from 'src/app/shared/constants/authorities.constants';
import { SessionStorageUtils } from 'src/app/utils/session-storage.utils';
import { DATE_FORMAT_FRANCAIS_SANS_HEURE } from 'src/app/shared/constants/cndiag.constants';
import { URL_GESTION_INTERVENTIONS, URL_GESTION_USERS_CONSULTER, URL_GESTION_USERS_EDIT } from 'src/app/shared/constants/url.constants';

export const DEFAULT_SORT = { direction: 'asc', active: 'lastName' };

@Component({
    selector: 'app-tableau-operateurs',
    templateUrl: './tableau-operateurs.component.html',
    styleUrls: ['./tableau-operateurs.component.scss'],
})
export class TableauOperateursComponent extends BaseComponent implements OnInit {
    @Input()
    initialPageSize = INITIAL_PAGE_SIZE;

    DATE_FORMAT_FRANCAIS_SANS_HEURE = DATE_FORMAT_FRANCAIS_SANS_HEURE;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] = ['lastName', 'firstName', 'companyName', 'listeRoleString', 'nbInterventionsEnCours', 'deletedDate', 'actions'];
    dataSource: PaginatedDataSource<UserWizy>;
    sort: Sort;
    searchValue = SessionStoragePaginationUtils.getInitialReseach(SESSION_STORAGE_KEY_EMPLOYE);
    private search = new BehaviorSubject(this.searchValue);

    constructor(
        private readonly managementApiService: ManagementApiService,
        private readonly matDialog: MatDialog,
        private readonly notificationService: NotificationService,
        private readonly router: Router,
        private readonly cnSpinnerService: CnSpinnerService
    ) {
        super();
        this.sort = SessionStoragePaginationUtils.getInitialSort(SESSION_STORAGE_KEY_EMPLOYE, DEFAULT_SORT);
    }

    ngOnInit(): void {
        this.search.pipe(debounceTime(DEBOUNCE_TIME_VALUE), distinctUntilChanged(), takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.dataSource.fetch(0, this.dataSource.size);
        });
        this.dataSource = new PaginatedDataSource<UserWizy>(
            (pageRequest) => {
                return this.getUsers(pageRequest);
            },
            SessionStoragePaginationUtils.getInitialSort(SESSION_STORAGE_KEY_EMPLOYE, DEFAULT_SORT),
            SessionStoragePaginationUtils.getInitialPageSize(SESSION_STORAGE_KEY_EMPLOYE, this.initialPageSize)
        );
    }

    onClickEditerUser(user: UserWizy) {
        this.setSessionStorageItem();
        this.router.navigate([URL_GESTION_USERS_EDIT, user.id]);
    }

    onClickConsulter(user: UserWizy) {
        this.setSessionStorageItem();
        this.router.navigate([URL_GESTION_USERS_CONSULTER, user.id]);
    }

    onClickSortirUser(user: UserWizy) {
        if (user.deletedDate) {
            return;
        }
        return this.matDialog
            .open(InputDateModalComponent)
            .afterClosed()
            .subscribe((result) => {
                if (result && result !== false) {
                    user.deletedDate = result.dateSortie;

                    // TODO FVI
                    this.managementApiService.upsertUser(user, []).subscribe(
                        () => {
                            this.cnSpinnerService.hide();
                            this.notificationService.success("L'employé a été modifié");
                        },
                        () => this.cnSpinnerService.hide()
                    );
                }
            });
    }

    /**
     * Redirection vers le tableau des interventions en spécifiant le user dans la recherche rapide pour filtrer sur ces interventions
     * @param user
     */
    goToInterventionsOperateur(user: UserWizy) {
        SessionStorageUtils.setItem(SESSION_STORAGE_KEY_INTERVENTION, {
            rechercheLibre: user.lastName + ' ' + (user.firstName ? user.firstName : ''),
        });
        this.router.navigate([URL_GESTION_INTERVENTIONS]);
    }

    /**
     * Action déclenchée lors de la saisie d'un caractère dans la barre de recherche
     * @param $event
     */
    onKeyupSearchUsers($event: any) {
        this.search.next($event.target.value);
        this.setSessionStorageItem();
    }

    /**
     * Action déclenchée lors d'un tri dans le tableau
     * @param $event
     */
    onChangeSortUsers($event: Sort) {
        this.sort = $event;
        this.dataSource.sortBy($event);
        this.setSessionStorageItem();
    }

    /**
     * Set les valeurs du tableau dans le session storage (pagination, recherche, tri ..)
     * @private
     */
    private setSessionStorageItem() {
        SessionStoragePaginationUtils.setSessionStorageItem(SESSION_STORAGE_KEY_EMPLOYE, this.dataSource.size, this.search.getValue(), this.sort);
    }

    getUsers(pageRequest: PageRequest<UserWizy>) {
        return this.cnSpinnerService.withSpinner(
            this.managementApiService
                .searchUsers(this.search.getValue(), pageRequest)
                .pipe(takeUntil(this.ngUnsubscribe))
                .pipe(
                    map((response) => {
                        if (response && response.content) {
                            response.content = this.prepareUsers(response.content);
                        }
                        return response;
                    })
                )
        );
    }
    /**
     * Formate les Users pour l'affichage dans le tableau
     * @param users
     * @private
     */
    prepareUsers(users: UserWizy[]) {
        users.map((user) => {
            const roles = user.authorities.sort().map((role) => {
                return MAP_ROLE.get(role);
            });
            user.listeRoleString = roles.filter((e) => e !== undefined).join('; ');
        });
        return users;
    }
}
