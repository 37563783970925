import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-mpca-form-skeleton',
    templateUrl: './mpca-form-skeleton.component.html',
    styleUrls: ['./mpca-form-skeleton.component.scss'],
})
export class MpcaFormSkeletonComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
