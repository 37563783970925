<app-mpca-form-skeleton>
    <div title>METOP</div>
    <div form>
        <div [formGroup]="formMetop" class="form-metop">
            <div class="form-input">
                <mat-label>Méthode</mat-label>
                <app-custom-select
                    class="w-100"
                    formControlName="methode"
                    [required]="true"
                    [choices]="methodeConfig.items"
                    [sorted]="true"
                    [addline]="false"
                    [matFormFieldClass]="'w-100'"
                    [title]="formMetop.get('methode').value"
                    data-cy="methode"
                >
                </app-custom-select>
            </div>

            <div class="form-input">
                <mat-label>Technique</mat-label>
                <app-custom-select
                    class="w-100"
                    formControlName="technique"
                    [required]="true"
                    [choices]="techniqueConfig.items"
                    [filter]="false"
                    [addline]="false"
                    [matFormFieldClass]="'w-100'"
                    [title]="formMetop.get('technique').value"
                    data-cy="technique"
                >
                </app-custom-select>
            </div>

            <div class="form-input">
                <mat-label>Aspiration</mat-label>
                <app-custom-select
                    class="w-100"
                    formControlName="aspiration"
                    [required]="true"
                    [choices]="aspirationConfig.items"
                    [filter]="false"
                    [addline]="false"
                    [matFormFieldClass]="'w-100'"
                    [title]="formMetop.get('aspiration').value"
                    data-cy="aspiration"
                >
                </app-custom-select>
            </div>

            <div class="form-input">
                <mat-label>Travail à l'humide</mat-label>
                <app-custom-select
                    class="w-100"
                    formControlName="travailHumide"
                    [required]="true"
                    [choices]="travailHumideConfig.items"
                    [sorted]="true"
                    [addline]="false"
                    [matFormFieldClass]="'w-100'"
                    [title]="formMetop.get('travailHumide').value"
                    data-cy="travailHumide"
                >
                </app-custom-select>
            </div>

            <div class="form-input">
                <mat-label>Outil</mat-label>
                <app-custom-select
                    class="w-100"
                    formControlName="outil"
                    [required]="true"
                    [choices]="outilConfigItems"
                    [sorted]="true"
                    [addLineButtonLabel]="'Ajouter un autre outil'"
                    [multiple]="true"
                    [matFormFieldClass]="'w-100'"
                    [title]="formMetop.get('outil').value"
                    data-cy="outil"
                >
                </app-custom-select>
            </div>
        </div>
    </div>
</app-mpca-form-skeleton>
