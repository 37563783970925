<div
    class="d-flex w-100 flex-column p-3"
    *ngIf="
        mapCategoriesOuvrages.get(ouvrageToEdit.codeCategorieOuvrage).activatedRevetements ||
        mapCategoriesOuvrages.get(ouvrageToEdit.codeCategorieOuvrage).activatedSubstrats ||
        mapCategoriesOuvrages.get(ouvrageToEdit.codeCategorieOuvrage).activatedCouleurs
    "
>
    <div class="overflow-auto d-flex flex-column align-items-center pt-3">
        <div
            *ngIf="mapCategoriesOuvrages.get(ouvrageToEdit.codeCategorieOuvrage).activatedRevetements"
            class="d-flex flex-column align-items-center w-100"
        >
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Revêtement</mat-label>
                <mat-select
                    [disabled]="readonlyMode"
                    [(ngModel)]="ouvrageToEdit.codeRevetement"
                    (selectionChange)="handleSelectionRevetementChange()"
                >
                    <mat-option [value]="" class="font-weight-lighter font-italic">Aucun</mat-option>
                    <mat-option [value]="'AUTRE'" class="font-weight-lighter font-italic">Autre</mat-option>
                    <mat-option *ngFor="let revetement of listRevetements" [value]="revetement.code">
                        {{ revetement.nom }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="ouvrageToEdit.codeRevetement === 'AUTRE'" appearance="fill" class="w-100">
                <mat-label>Revêtement autre</mat-label>
                <input matInput [(ngModel)]="ouvrageToEdit.nomRevetement" />
            </mat-form-field>
        </div>
        <div
            *ngIf="mapCategoriesOuvrages.get(ouvrageToEdit.codeCategorieOuvrage).activatedSubstrats"
            class="d-flex flex-column align-items-center w-100"
        >
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Substrat</mat-label>
                <mat-select [disabled]="readonlyMode" [(ngModel)]="ouvrageToEdit.codeSubstrat" (selectionChange)="handleSelectionSubstratChange()">
                    <mat-option [value]="" class="font-weight-lighter font-italic">Aucun</mat-option>
                    <mat-option [value]="'AUTRE'" class="font-weight-lighter font-italic">Autre</mat-option>
                    <mat-option *ngFor="let substrat of listSubstrats" [value]="substrat.code">
                        {{ substrat.nom }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="ouvrageToEdit.codeSubstrat === 'AUTRE'" appearance="fill" class="w-100">
                <mat-label>Substrat autre</mat-label>
                <input matInput [(ngModel)]="ouvrageToEdit.nomSubstrat" />
            </mat-form-field>
        </div>
        <div
            *ngIf="mapCategoriesOuvrages.get(ouvrageToEdit.codeCategorieOuvrage).activatedCouleurs"
            class="d-flex flex-column align-items-center w-100"
        >
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Couleur</mat-label>
                <mat-select [disabled]="readonlyMode" [(ngModel)]="ouvrageToEdit.valeurCouleur" (selectionChange)="handleSelectionCouleurChange()">
                    <mat-select-trigger>
                        <div *ngIf="ouvrageToEdit.valeurCouleur && ouvrageToEdit.valeurCouleur != 'AUTRE'" class="d-flex align-items-center">
                            <span
                                class="color-sample mr-3"
                                [style]="'background-color: ' + mapCouleurs.get(ouvrageToEdit.valeurCouleur)?.valeur"
                            ></span>
                            <span>{{ mapCouleurs.get(ouvrageToEdit.valeurCouleur)?.nom }}</span>
                        </div>
                        <div *ngIf="ouvrageToEdit.valeurCouleur && ouvrageToEdit.valeurCouleur === 'AUTRE'">
                            <span>Autre</span>
                        </div>
                    </mat-select-trigger>
                    <mat-option [value]="" class="font-weight-lighter font-italic">Aucune</mat-option>
                    <mat-option [value]="'AUTRE'" class="font-weight-lighter font-italic">Autre</mat-option>
                    <mat-option *ngFor="let couleur of mapCouleurs | keyvalue" [value]="couleur.value.valeur">
                        <div class="d-flex align-items-center">
                            <span class="color-sample mr-3" [style]="'background-color: ' + couleur.value.valeur"></span>
                            <span>{{ couleur.value.nom }}</span>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="ouvrageToEdit.valeurCouleur === 'AUTRE'" appearance="fill" class="w-100">
                <mat-label>Couleur autre</mat-label>
                <input matInput [(ngModel)]="ouvrageToEdit.nomCouleur" />
            </mat-form-field>
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Caractéristique couleur</mat-label>
                <mat-select
                    [disabled]="readonlyMode"
                    [(ngModel)]="ouvrageToEdit.valeurCaracteristiqueCouleur"
                    (selectionChange)="handleSelectionCaracteristiqueCouleurChange()"
                >
                    <mat-option [value]="" class="font-weight-lighter font-italic">Aucune</mat-option>
                    <mat-option *ngFor="let caracCouleur of listCaracteristiquesCouleurs" [value]="caracCouleur.valeur">
                        {{ caracCouleur.nom }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>
<div class="d-flex w-100 flex-column p-3" [formGroup]="form">
    <ng-container *ngFor="let param of mapCategoriesOuvrages.get(ouvrageToEdit.codeCategorieOuvrage).parametres">
        <mat-checkbox
            *ngIf="param.typeParametre === TypeParametreCategorieOuvrage.BOOLEAN"
            class="square-check-box py-1"
            [disabled]="readonlyMode"
            [formControlName]="param.code"
        >
            <span>{{ param.label }}</span>
        </mat-checkbox>

        <mat-form-field
            *ngIf="param.typeParametre === TypeParametreCategorieOuvrage.NUMBER"
            class="py-1"
            appearance="outline"
            [floatLabel]="readonlyMode ? 'always' : 'auto'"
        >
            <mat-label>{{ param.label }}</mat-label>
            <input matInput type="number" [readonly]="readonlyMode" [formControlName]="param.code" />
        </mat-form-field>

        <mat-form-field
            *ngIf="param.typeParametre === TypeParametreCategorieOuvrage.STRING"
            class="py-1"
            appearance="outline"
            [floatLabel]="readonlyMode ? 'always' : 'auto'"
        >
            <mat-label>{{ param.label }}</mat-label>
            <input matInput type="text" [readonly]="readonlyMode" [formControlName]="param.code" />
        </mat-form-field>

        <mat-form-field *ngIf="param.typeParametre === TypeParametreCategorieOuvrage.LOCALISATION_OUVRAGE" appearance="fill">
            <mat-label>{{ param.label }}</mat-label>
            <mat-select [disabled]="readonlyMode" [formControlName]="param.code">
                <mat-option [value]="" class="font-weight-lighter font-italic">Aucune</mat-option>
                <ng-container *ngFor="let ouvrage of listOuvragesCurrentVolume">
                    <mat-option *ngIf="ouvrageToEdit.id != ouvrage.id" [value]="ouvrage.id">
                        {{ ouvrage.nom }}
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="param.typeParametre === TypeParametreCategorieOuvrage.SIMPLE_CHOICE" appearance="fill">
            <mat-label>{{ param.label }}</mat-label>
            <mat-select [disabled]="readonlyMode" [formControlName]="param.code">
                <mat-option [value]="" class="font-weight-lighter font-italic">Vide</mat-option>
                <ng-container *ngFor="let value of param.values">
                    <mat-option [value]="value">
                        {{ value }}
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
        <ng-container *ngIf="param.typeParametre === TypeParametreCategorieOuvrage.LOCALISATION_BOUSSOLE">
            <span>{{ param.label }}</span>
            <div class="d-flex row no-gutters">
                <ng-container *ngFor="let boussoleElement of BOUSSOLE | keyvalue: originalOrder">
                    <mat-card
                        *ngIf="boussoleElement.key != BOUSSOLE.ICONE"
                        class="d-flex m-1 col-3 card-boussole"
                        [ngClass]="{
                            'bg-primary':
                                this.ouvrageToEdit.parametres[TypeParametreCategorieOuvrage.LOCALISATION_BOUSSOLE] === boussoleElement.value,
                            'c-pointer': !readonlyMode
                        }"
                        (click)="!readonlyMode && handleClickBoussole(boussoleElement)"
                    >
                        <mat-card-content class="w-100 h-100 mh-100 d-flex justify-content-center align-items-center">
                            <span>{{ boussoleElement.value }}</span>
                        </mat-card-content>
                    </mat-card>
                    <div *ngIf="boussoleElement.key === BOUSSOLE.ICONE" class="d-flex m-1 col-3 align-items-center justify-content-center">
                        <lib-icon>explore</lib-icon>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
</div>
