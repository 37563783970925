import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../utils/base.component';
import { AuthenticationService } from '../authentication.service';

@Component({
    selector: 'lib-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.css'],
})
export class LogoutComponent extends BaseComponent implements OnInit, OnDestroy {
    constructor(private authenticationService: AuthenticationService) {
        super();
    }

    ngOnInit() {
        this.authenticationService.logout().subscribe();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
