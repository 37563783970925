<div *ngIf="bien && formInfo" class="row edit-bien-content" [formGroup]="formInfo">
    <!-- Colonne gauche-->
    <div class="col-xl-6">
        <mat-card class="p-2 mb-4 d-flex flex-column justify-content-between">
            <mat-card-content>
                <!--Désignation du bien-->
                <div class="d-flex p-2 align-items-center">
                    <div class="block-label mat-subheading-2 primary">
                        Désignation du bien <span *ngIf="editMode$ | async" class="required"></span>
                    </div>
                    <div class="block-value pr-2">
                        <mat-form-field class="w-100" [ngClass]="(editMode$ | async) ? '' : 'mat-input-readonly'">
                            <input matInput type="text" formControlName="nom" [readonly]="!(editMode$ | async)" />
                            <mat-error *ngIf="formInfo.get('nom').hasError('erreurNomBien')"
                                >Un autre bien possède déjà le même nom dans cette intervention.
                            </mat-error>
                            <app-mat-error [control]="formInfo.get('nom')"> </app-mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="p-2 mb-4 d-flex flex-column justify-content-between">
            <mat-card-content class="d-flex justify-content-around">
                <app-state-input-reactive
                    class="mt-2"
                    formControlName="idTypeBien"
                    [choices]="choixTypesBien"
                    [labels]="true"
                    [choiceValue]="bien.idTypeBien"
                    [radioControl]="formInfo.get('idTypeBien')"
                    [spaceBetweenLabels]="'0'"
                    [containerClass]="['px-2 w-100 justify-content-between']"
                    [disabled]="!(editMode$ | async)"
                >
                </app-state-input-reactive>
            </mat-card-content>
        </mat-card>

        <mat-card class="p-2 mb-4 d-flex flex-column justify-content-between">
            <mat-card-content>
                <!--Adresse-->
                <div class="d-flex p-2 align-items-center" formGroupName="adresse">
                    <div class="block-label mat-subheading-2 primary column clickable" (click)="onClickRedirect()">
                        <lib-icon>place</lib-icon>
                        Adresse <span *ngIf="editMode$ | async" class="required"></span>
                    </div>
                    <div class="block-value d-flex flex-column pr-2">
                        <mat-form-field class="w-100" [ngClass]="(editMode$ | async) ? '' : 'mat-input-readonly'">
                            <textarea placeholder="Voie" matInput formControlName="voie" [readonly]="!(editMode$ | async)"></textarea>
                            <app-mat-error [control]="formInfo.get('adresse').get('voie')"> </app-mat-error>
                        </mat-form-field>
                        <mat-form-field class="w-100" [ngClass]="(editMode$ | async) ? '' : 'mat-input-readonly'">
                            <input
                                placeholder="Complément d'adresse 1"
                                matInput
                                type="text"
                                formControlName="complementAdresse1"
                                [readonly]="!(editMode$ | async)"
                            />
                            <app-mat-error [control]="formInfo.get('adresse').get('complementAdresse1')"> </app-mat-error>
                        </mat-form-field>
                        <mat-form-field class="w-100" [ngClass]="(editMode$ | async) ? '' : 'mat-input-readonly'">
                            <input
                                placeholder="Complément d'adresse 2"
                                matInput
                                type="text"
                                formControlName="complementAdresse2"
                                [readonly]="!(editMode$ | async)"
                            />
                            <app-mat-error [control]="formInfo.get('adresse').get('complementAdresse2')"> </app-mat-error>
                        </mat-form-field>
                        <mat-form-field class="w-100" [ngClass]="(editMode$ | async) ? '' : 'mat-input-readonly'">
                            <input placeholder="Code postal" matInput type="text" formControlName="codePostal" [readonly]="!(editMode$ | async)" />
                            <app-mat-error [control]="formInfo.get('adresse').get('codePostal')"> </app-mat-error>
                        </mat-form-field>
                        <mat-form-field class="w-100" [ngClass]="(editMode$ | async) ? '' : 'mat-input-readonly'">
                            <input placeholder="Ville" matInput type="text" formControlName="ville" [readonly]="!(editMode$ | async)" />
                            <app-mat-error [control]="formInfo.get('adresse').get('ville')"> </app-mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <!--Référence du bien-->
                <div class="d-flex p-2 align-items-center">
                    <div class="block-label mat-subheading-2 primary">Référence du bien (client)</div>
                    <div class="block-value pr-2">
                        <mat-form-field class="w-100" [ngClass]="(editMode$ | async) ? '' : 'mat-input-readonly'">
                            <input matInput type="text" formControlName="refBien" [readonly]="!(editMode$ | async)" />
                        </mat-form-field>
                    </div>
                </div>

                <!--Bâtiment-->
                <div *ngIf="typeBienSelected?.batiment" class="d-flex p-2 align-items-center">
                    <div class="block-label mat-subheading-2 primary">
                        Bâtiment <span *ngIf="editMode$ | async"></span>
                    </div>
                    <div class="block-value pr-2">
                        <mat-form-field class="w-100" [ngClass]="(editMode$ | async) ? '' : 'mat-input-readonly'">
                            <input matInput type="text" formControlName="batiment" [readonly]="!(editMode$ | async)" />
                            <app-mat-error [control]="formInfo.get('batiment')"> </app-mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <!--Nombre d'entrées-->
                <div *ngIf="typeBienSelected?.nombreEntrees" class="d-flex p-2 align-items-center">
                    <div class="block-label mat-subheading-2 primary">Nombre d'entrées<span *ngIf="editMode$ | async" class="required"></span></div>
                    <div class="block-value pr-2">
                        <app-increment-input-reactive
                            class="small-increment"
                            formControlName="nombreEntrees"
                            [incrementControl]="formInfo.get('nombreEntrees')"
                            [min]="0"
                            [step]="1"
                            [readonly]="!(editMode$ | async)"
                            [disabled]="!(editMode$ | async)"
                        >
                        </app-increment-input-reactive>
                    </div>
                </div>

                <!--Escalier / N° de porte-->
                <div *ngIf="typeBienSelected?.escalierNumPorte" class="d-flex p-2 align-items-center">
                    <div class="block-label mat-subheading-2 primary">
                        Escalier / N° de porte<span *ngIf="editMode$ | async" class="required"></span>
                    </div>
                    <div class="block-value pr-2">
                        <mat-form-field class="w-100" [ngClass]="(editMode$ | async) ? '' : 'mat-input-readonly'">
                            <input matInput type="text" formControlName="escalierNumPorte" [readonly]="!(editMode$ | async)" />
                            <app-mat-error [control]="formInfo.get('escalierNumPorte')"> </app-mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <!--Type de bien-->
                <div *ngIf="typeBienSelected?.sousTypes" class="d-flex p-2 align-items-center">
                    <div class="block-label mat-subheading-2 primary">Type de bien <span *ngIf="editMode$ | async" class="required"></span></div>
                    <div class="block-value pr-2">
                        <app-custom-select
                            class="w-100"
                            formControlName="sousType"
                            [matFormFieldClass]="'w-100'"
                            [choices]="subTypeList"
                            [addLineButtonLabel]="'Ajouter un type de bien'"
                            [sorted]="true"
                            [readonly]="!(editMode$ | async)"
                        >
                        </app-custom-select>
                    </div>
                </div>

                <!--Année de construction-->
                <div *ngIf="typeBienSelected?.dateConstruction" class="d-flex p-2 align-items-center">
                    <div class="block-label mat-subheading-2 primary">
                        Année de construction <span *ngIf="editMode$ | async" class="required"></span>
                    </div>
                    <div class="block-value pr-2">
                        <app-increment-input-reactive
                            class="small-increment"
                            formControlName="anneeConstruction"
                            [incrementControl]="formInfo.get('anneeConstruction')"
                            [min]="0"
                            [step]="1"
                            [readonly]="!(editMode$ | async)"
                            [disabled]="!(editMode$ | async)"
                            [numericKeyboard]="true"
                        >
                        </app-increment-input-reactive>
                    </div>
                </div>

                <!--Mois de construction-->
                <div *ngIf="typeBienSelected?.dateConstruction" class="d-flex p-2 align-items-center">
                    <div class="block-label mat-subheading-2 primary">Mois de construction</div>
                    <div class="block-value pr-2">
                        <app-custom-select
                            class="w-100"
                            formControlName="moisConstruction"
                            [matFormFieldClass]="'w-100'"
                            [choices]="months"
                            [addline]="false"
                            [filter]="false"
                            [readonly]="!(editMode$ | async)"
                        >
                        </app-custom-select>
                    </div>
                </div>

                <!--Situation-->
                <div *ngIf="typeBienSelected?.situation" class="d-flex p-2 align-items-center">
                    <div class="block-label mat-subheading-2 primary">
                        Situation (niveau/étage) <span *ngIf="editMode$ | async" class="required"></span>
                    </div>
                    <div class="block-value pr-2">
                        <mat-form-field class="w-100" [ngClass]="(editMode$ | async) ? '' : 'mat-input-readonly'">
                            <input matInput type="text" formControlName="situation" [readonly]="!(editMode$ | async)" />
                            <app-mat-error [control]="formInfo.get('situation')"> </app-mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>

    <!--Colonne droite-->
    <div class="col-xl-6">
        <mat-card class="p-2 mb-4 d-flex flex-column justify-content-between">
            <mat-card-content>
                <!--Nombre de pièces                    -->
                <div *ngIf="typeBienSelected?.nbPiecesPrincipales" class="d-flex p-2 align-items-center">
                    <div class="block-label mat-subheading-2 primary">
                        Nb pièces principales <span *ngIf="editMode$ | async" class="required"></span>
                    </div>
                    <div class="block-value pr-2">
                        <app-custom-select
                            class="w-100"
                            formControlName="nbPiecesPrincipales"
                            [selectControl]="formInfo.get('nbPiecesPrincipales')"
                            [matFormFieldClass]="'w-100'"
                            [choices]="nbRoomsList"
                            [filter]="false"
                            [pathValueDisplay]="null"
                            [multiple]="false"
                            [addline]="false"
                            [readonly]="!(editMode$ | async)"
                            [disabled]="!(editMode$ | async)"
                        >
                        </app-custom-select>
                    </div>
                </div>

                <!-- Nombre d'étages -->
                <div *ngIf="typeBienSelected?.nbNiveaux" class="d-flex p-2 align-items-center">
                    <div class="block-label mat-subheading-2 primary">Nb d'étages <span *ngIf="editMode$ | async" class="required"></span></div>
                    <div class="block-value pr-2 d-flex">
                        <app-increment-input-reactive
                            class="small-increment"
                            formControlName="nbNiveauxNegatifs"
                            [incrementControl]="formInfo.get('nbNiveauxNegatifs')"
                            [max]="0"
                            [min]="-1000"
                            [step]="1"
                            [fromZero]="true"
                            [readonly]="!(editMode$ | async)"
                            [disabled]="!(editMode$ | async)"
                            [numericKeyboard]="true"
                        >
                        </app-increment-input-reactive>
                        <div class="inter-label d-flex align-items-center" [class.readonly]="!(editMode$ | async)">
                            <span>R</span>
                        </div>
                        <app-increment-input-reactive
                            class="small-increment"
                            formControlName="nbNiveauxPositifs"
                            [incrementControl]="formInfo.get('nbNiveauxPositifs')"
                            [min]="0"
                            [step]="1"
                            [readonly]="!(editMode$ | async)"
                            [disabled]="!(editMode$ | async)"
                            [numericKeyboard]="true"
                        >
                        </app-increment-input-reactive>
                    </div>
                </div>

                <!--Nombre de cages d'escaliers-->
                <div *ngIf="typeBienSelected?.nbCagesEscalier" class="d-flex p-2 align-items-center">
                    <div class="block-label mat-subheading-2 primary">
                        Nb de cages d'escaliers <span *ngIf="editMode$ | async" class="required"></span>
                    </div>
                    <div class="block-value pr-2">
                        <app-increment-input-reactive
                            class="small-increment"
                            formControlName="nbCagesEscalier"
                            [incrementControl]="formInfo.get('nbCagesEscalier')"
                            [min]="0"
                            [step]="1"
                            [readonly]="!(editMode$ | async)"
                            [disabled]="!(editMode$ | async)"
                            [numericKeyboard]="true"
                        >
                        </app-increment-input-reactive>
                    </div>
                </div>

                <!--Numéro de lot-->
                <div *ngIf="typeBienSelected?.numeroLot" class="d-flex p-2 align-items-center">
                    <div class="block-label mat-subheading-2 primary">Numéro de lot</div>
                    <div class="block-value pr-2">
                        <mat-form-field class="w-100" [ngClass]="(editMode$ | async) ? '' : 'mat-input-readonly'">
                            <input matInput type="text" formControlName="numeroLot" [readonly]="!(editMode$ | async)" />
                            <app-mat-error [control]="formInfo.get('numeroLot')"> </app-mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <!--Numéro de parcelle-->
                <div *ngIf="typeBienSelected?.referenceCadastrale" class="d-flex p-2 align-items-center">
                    <div class="block-label mat-subheading-2 primary">Référence cadastrale</div>
                    <div class="block-value pr-2">
                        <mat-form-field class="w-100" [ngClass]="(editMode$ | async) ? '' : 'mat-input-readonly'">
                            <input matInput type="text" formControlName="referenceCadastre" [readonly]="!(editMode$ | async)" />
                            <app-mat-error [control]="formInfo.get('referenceCadastre')"> </app-mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <!--Coord GPS-->
                <div class="d-flex p-2 align-items-center" formGroupName="coordonnees">
                    <div class="block-label mat-subheading-2 primary">Coordonnées GPS</div>
                    <div class="block-value pr-2 d-flex">
                        <mat-form-field class="w-50 pr-1" [ngClass]="(editMode$ | async) ? '' : 'mat-input-readonly'">
                            <input matInput type="number" placeholder="Lat" formControlName="first" [readonly]="!(editMode$ | async)" />
                            <button
                                mat-icon-button
                                matSuffix
                                color="primary"
                                class="pt-2"
                                *ngIf="editMode$ | async"
                                (click)="openNumKeyboard(formInfo.get('coordonnees').get('first'))"
                            >
                                <mat-icon>dialpad</mat-icon>
                            </button>
                            <app-mat-error [control]="formInfo.get('coordonnees').get('first')"> </app-mat-error>
                        </mat-form-field>
                        <mat-form-field class="w-50 pr-1" [ngClass]="(editMode$ | async) ? '' : 'mat-input-readonly'">
                            <input matInput type="number" placeholder="Long" formControlName="second" [readonly]="!(editMode$ | async)" />
                            <button
                                mat-icon-button
                                matSuffix
                                color="primary"
                                class="pt-2"
                                *ngIf="editMode$ | async"
                                (click)="openNumKeyboard(formInfo.get('coordonnees').get('second'))"
                            >
                                <mat-icon>dialpad</mat-icon>
                            </button>
                            <app-mat-error [control]="formInfo.get('coordonnees').get('second')"> </app-mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <!--Typologie Surface-->
                <div *ngIf="typeBienSelected?.id === 'Appartement' || typeBienSelected?.id === 'Maison'" class="d-flex p-2 align-items-center">
                    <div class="block-label mat-subheading-2 primary">Typologie de surface (m<sup>2</sup>)</div>
                    <div class="block-value pr-2">
                        <app-custom-select
                            class="w-100"
                            formControlName="typologieSurface"
                            [selectControl]="formInfo.get('typologieSurface')"
                            [matFormFieldClass]="'w-100'"
                            [choices]="typologieSurfaceList"
                            [filter]="false"
                            [pathValueDisplay]="null"
                            [multiple]="false"
                            [addline]="false"
                            [readonly]="!(editMode$ | async)"
                            [disabled]="!(editMode$ | async)"
                        >
                        </app-custom-select>
                    </div>
                </div>

                <!--Surface-->
                <div class="d-flex p-2 align-items-center">
                    <div class="block-label mat-subheading-2 primary">Surface (m<sup>2</sup>)</div>
                    <div class="block-value pr-2">
                        <app-increment-input-reactive
                            class="small-increment"
                            formControlName="surface"
                            [incrementControl]="formInfo.get('surface')"
                            [min]="0"
                            [step]="0.1"
                            [readonly]="!(editMode$ | async)"
                            [disabled]="!(editMode$ | async)"
                            [numericKeyboard]="true"
                        >
                        </app-increment-input-reactive>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <!-- Contacts -->
        <mat-card class="p-2 mt-2 d-flex flex-column justify-content-between">
            <mat-card-content>
                <div class="d-flex p-2 align-items-center">
                    <div class="col-xl-4 mat-subheading-2 m-0 primary">Propriétaire</div>
                    <div class="col-xl-8 d-flex">
                        <div class="col-xl-6 pr-2" *ngIf="isAdminMode">
                            <div *ngIf="(editMode$ | async) && !hasProprietaire">
                                <mat-form-field class="flex-grow-1">
                                    <input
                                        formControlName="contactProprietaire"
                                        [matAutocomplete]="autoContactProprietaire"
                                        matInput
                                        placeholder="Propriétaire"
                                        type="text"
                                    />
                                    <mat-error *ngIf="formInfo.get('contactProprietaire').hasError('contactDoesNotExist')">
                                        Aucun contact n'existe avec ce nom.
                                    </mat-error>
                                    <mat-autocomplete
                                        #autoContactProprietaire="matAutocomplete"
                                        [displayWith]="displayFnContact"
                                        (optionSelected)="changeProprietaire($event)"
                                    >
                                        <mat-option *ngIf="isSearchingContacts | async" class="is-loading">
                                            <mat-spinner diameter="25"></mat-spinner>
                                        </mat-option>
                                        <ng-container *ngIf="!(isSearchingContacts | async)">
                                            <mat-option *ngFor="let option of filteredSearchContactsProprietaires" [value]="option">
                                                {{
                                                    option.nom
                                                        ? option.estPersonneMorale
                                                            ? option.nom + ' - ' + option.siret
                                                            : option.prenom
                                                            ? option.nom + ' - ' + option.prenom
                                                            : option.nom
                                                        : null
                                                }}
                                            </mat-option>
                                        </ng-container>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <div>ou</div>
                                <button color="accent" mat-raised-button class="bt-valid" (click)="addProprietaire()">
                                    <lib-icon>person_add</lib-icon>
                                    Ajouter un propriétaire
                                </button>
                            </div>
                        </div>
                        <div class="col-xl-12 pr-2 mat-subheading-2 m-0 primary" *ngIf="!isAdminMode && !bien.contactProprietaire">
                            Pas de propriétaire renseigné pour ce bien
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <div class="d-flex flex-wrap justify-content-between align-items-start" *ngIf="bien.contactProprietaire">
            <mat-card class="mt-1 d-flex flex-column justify-content-between position-relative small-card">
                <div>
                    <div class="position-absolute align-self-end button-group">
                        <button *ngIf="(editMode$ | async) && isAdminMode" mat-icon-button class="primary" (click)="editProprietaire()">
                            <lib-icon class="icon-small">edit</lib-icon>
                        </button>
                        <button *ngIf="(editMode$ | async) && isAdminMode" mat-icon-button class="warn" (click)="deleteProprietaire()">
                            <lib-icon class="icon-small">delete</lib-icon>
                        </button>
                    </div>
                    <div class="mat-h3 bold ml-2 mb-2">{{ bien.contactProprietaire.prenom }} {{ bien.contactProprietaire.nom }}</div>
                    <div class="mb-2 d-flex" *ngIf="bien.contactProprietaire.adresse.voie">
                        <div class="mr-2">
                            <lib-icon [class]="'primary'" class="icon-small align-bottom">place </lib-icon>
                        </div>
                        <div>
                            <span class="ws-preline"
                                >{{ bien.contactProprietaire.adresse.voie }}
                                <span *ngIf="bien.contactProprietaire.adresse.complementAdresse1"
                                    ><br />{{ bien.contactProprietaire.adresse.complementAdresse1 }}</span
                                >
                                <span *ngIf="bien.contactProprietaire.adresse.complementAdresse2"
                                    ><br />{{ bien.contactProprietaire.adresse.complementAdresse2 }}</span
                                >
                                <br />{{ bien.contactProprietaire.adresse.codePostal }} {{ bien.contactProprietaire.adresse.ville }}</span
                            >
                        </div>
                    </div>
                    <div class="mb-2 d-flex">
                        <div class="mr-2">
                            <lib-icon [class]="'primary'" class="icon-small align-bottom">local_phone </lib-icon>
                        </div>
                        <div>
                            {{ bien.contactProprietaire.telephone }}
                        </div>
                    </div>
                    <div class="d-flex" *ngIf="bien.contactProprietaire.email">
                        <div class="mr-2">
                            <lib-icon [class]="'primary'" class="icon-small align-bottom">email </lib-icon>
                        </div>
                        <div>{{ bien.contactProprietaire.email }}</div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>
