import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { MongoUtils } from 'src/app/commons-lib';
import { environment } from 'src/environments/environment';
import { FileData, OfflineStorageService, ResourceWrapper } from '../shared/offline/offline-storage.service';
import { STORAGE_KEY_BACKGROUND_MAPS } from '../shared/constants/indexeddb.constants';
import { combineLatestOrEmpty } from '../utils/rxjs.utils';
import { Intervention } from '../model/intervention.model';

export class BackgroundMapFileData implements FileData {
    fileId?: string;
    fileContent?: string;
    fileName?: string;
    interventionId: string;
    idRelationInterventionBien: string;
}

@Injectable({
    providedIn: 'root',
})
export class BackgroundMapApiService {
    public resourceUrl = `${environment.apiUrl}/interventions/`;

    private http: HttpClient;

    // Ressource pour le wrapper HL
    private resource: ResourceWrapper<BackgroundMapFileData, string> = this.offlineStorage.wrapFileResource<BackgroundMapFileData>({
        cacheName: STORAGE_KEY_BACKGROUND_MAPS,
        idField: 'fileId',
        resourceUrl: this.resourceUrl,
        paramsForPull: null,
        urlByTemplate: (data: BackgroundMapFileData) =>
            `${this.resourceUrl}${data.interventionId}/description/${data.idRelationInterventionBien}/background-map`,
    });

    constructor(private httpBackend: HttpBackend, private offlineStorage: OfflineStorageService) {
        this.http = new HttpClient(httpBackend);
    }

    /**
     * Upload une nouvelle image de background
     * @param interventionId id de l'intervention
     * @param relationInterventionBien id de la relationInterventionBien
     * @param fileContent image à uploader en Base64
     */
    uploadBackgroundImage(interventionId: string, idRelationInterventionBien: string, fileContent: string): Observable<BackgroundMapFileData> {
        const fileId = MongoUtils.generateObjectId();
        return this.resource.save({
            interventionId,
            idRelationInterventionBien,
            fileContent,
            fileId,
        });
    }

    /**
     * Supprime l'image du background
     * @param interventionId id de l'intervention
     * @param relationInterventionBien id de la relationInterventionBien
     * @param fileId id du fichier
     */
    deleteBackgroundImage(interventionId: string, idRelationInterventionBien: string, fileId: string): Observable<any> {
        return this.resource.delete({
            interventionId,
            idRelationInterventionBien,
            fileId,
        });
    }

    /**
     * Télécharge l'image du background
     * @param interventionId id de l'intervention
     * @param relationInterventionBien id de la relationInterventionBien
     * @param fileId id du fichier
     */
    downloadBackgroundImage(interventionId: string, idRelationInterventionBien: string, fileId: string): Observable<BackgroundMapFileData> {
        if (fileId) {
            return this.resource.getOneByTemplate({
                interventionId: interventionId,
                idRelationInterventionBien: idRelationInterventionBien,
                fileId: fileId,
            });
        } else {
            return of(null);
        }
    }

    /**
     * Push les images du background stockées dans IndexDB
     */
    pushBackgroundImages(): Observable<BackgroundMapFileData> {
        return this.resource.push();
    }

    /**
     * Pull les fonds de carte
     * @param interventions liste des interventions disponibles en mode hors ligne
     */
    pullBackgroundImages(interventions: Intervention[]): Observable<Intervention[]> {
        return combineLatestOrEmpty(
            interventions
                .filter((intervention) => {
                    if (intervention.relationInterventionBiens != undefined) {
                        intervention.relationInterventionBiens.filter((relation) => relation.bien.backgroundMaps.length);
                    }
                })
                .map((intervention) => {
                    return intervention.relationInterventionBiens.map((relation) => {
                        return relation.bien.backgroundMaps.map((bg) => {
                            return this.downloadBackgroundImage(intervention.id, relation.id, bg.fileId);
                        });
                    });
                })
                .flat(2)
        );
    }
}
