import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { RelationInterventionBien } from '../../../../model/intervention.model';
import { cn_background_map, cn_storey, cn_svg_map, cn_svg_tool_selection } from '@acenv/cnmap-editor';
import { CnStoreyComponent, DrawingPicture } from '@acenv/cnmap-angular-editor-lib';
import { InterventionService } from '../../../../services/intervention.service';
import { BackgroundMapApiService } from '../../../../services/background-map-api.service';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ZoneMesure } from '../../../diagnostics/mesurage/model/mesurage.model';
import { CndiagMarkerZoneToolCreation } from '../tools/cndiag-marker-zone-tool-creation';
import { CndiagMarkerZoneToolEdition } from '../tools/cndiag-marker-zone-tool-edition';

@Component({
    selector: 'app-viewer-map-mesure',
    templateUrl: './viewer-map-mesure.component.html',
    styleUrls: ['./viewer-map-mesure.component.scss'],
})
export class ViewerMapMesureComponent extends BaseComponent implements OnInit {
    @Input()
    set currentBien(currentBien: RelationInterventionBien) {
        this._currentBien = currentBien;
        if (this._currentBien) {
            this.loadBM();
            this.cnStoreyComp.refresh();
        }
    }
    @Input() set currentStorey(currentStorey: cn_storey) {
        this._currentStorey = currentStorey;
    }

    get currentStorey(): cn_storey {
        return this._currentStorey;
    }
    private _currentStorey: cn_storey;

    @Input() readonlyMode = false;
    // Indique que l'on va utiliser des outils spécifique pour gérer les sélections
    @Input() useDiagTool = false;

    // Représente l'objet pour lequel on veut faire un marker (périmètre / zone / prélèvement...)
    @Input() set referenceToMarker(referenceToMarker: ZoneMesure) {
        this._referenceToMarker = referenceToMarker;
        // Si la referenceToMarker est  renseignée, on est en mode création pour cette reference
        // Sinon la referenceToMarker n'est pas renseignée, c'est qu'on est en mode édition
        if (this.selectionTool) {
            if (this.referenceToMarker) {
                this.markerToolCreation.referenceToMarker = this.referenceToMarker;
                this.markerToolCreation.setMarkerModeToZone();
                this.selectionTool.set_main_tool('markerZone', true);
            } else {
                this.selectionTool.set_main_tool('markerZone', false);
            }
            this.refresh();
        }
    }

    get referenceToMarker(): any {
        return this._referenceToMarker;
    }

    @Input() set backgroundMap(backgroundMap: DrawingPicture) {
        if (backgroundMap) {
            this._backgroundMap = backgroundMap;
        } else {
            this._backgroundMap = null;
        }
        if (this._currentBien) {
            this.loadBM();
            this.cnStoreyComp.refresh();
        }
    }

    get backgroundMap(): DrawingPicture {
        return this._backgroundMap;
    }

    @Output()
    toolEvent = new EventEmitter<any>();

    @ViewChild('libCnStorey', { static: true }) cnStoreyComp: CnStoreyComponent;
    cnStoreyReadonly: boolean;
    elementId = 'storey_svg_editor';

    private _currentBien: RelationInterventionBien;
    private _referenceToMarker: ZoneMesure;
    private _backgroundMap: DrawingPicture;
    private markerToolCreation: CndiagMarkerZoneToolCreation = null;
    private markerToolEdition: CndiagMarkerZoneToolEdition = null;
    selectionTool: cn_svg_tool_selection;
    svgMap: cn_svg_map;

    constructor(private readonly interventionService: InterventionService, private readonly backgroundMapApiService: BackgroundMapApiService) {
        super();
        (cn_background_map as any).image_id_to_url = (fileId) => {
            if (this.backgroundMap && this.backgroundMap.fileId === fileId) {
                return this.backgroundMap.imageUrl;
            } else {
                return '';
            }
        };
    }

    ngOnInit(): void {
        // Ouverture de CnStoreyComponent en readonly si readOnly est true ou si useDiagTool est true.
        // this.cnStoreyReadonly = this.readOnly ? this.useDiagTool ? false : true : false;
        this.cnStoreyReadonly = this.readonlyMode || this.useDiagTool;
        this.loadBM();
        (this.cnStoreyComp as any).svgMap$
            .pipe(
                takeUntil(this.ngUnsubscribe),
                tap((svgMap: cn_svg_map) => {
                    this.svgMap = svgMap;
                    if (this.useDiagTool && svgMap) {
                        if (svgMap._scene.storey == null) {
                            svgMap._scene.storey = svgMap._storey;
                        }
                        // Création du tool selection et affectation des tool marker pour la création et l'édition
                        this.selectionTool = new cn_svg_tool_selection(svgMap);
                        // this.selectionTool.on('selection_change', (value) => {
                        //     this.toolEvent.emit({
                        //         toolName: 'ZONING',
                        //         tool: this.selectionTool,
                        //         event: 'selection_change',
                        //         mode: 'edition',
                        //         value,
                        //     });
                        // });

                        // Initialiser le tool creation
                        this.markerToolCreation = new CndiagMarkerZoneToolCreation(svgMap);
                        this.markerToolCreation.on('creation', (value) => {
                            this.toolEvent.emit({
                                toolName: 'ZONING',
                                tool: this.markerToolCreation,
                                event: 'creation',
                                mode: 'creation',
                                value,
                            });
                        });

                        // Initialiser le tool édition
                        this.markerToolEdition = new CndiagMarkerZoneToolEdition(svgMap, this.readonlyMode);

                        this.markerToolEdition.on('delete_selection', (value) => {
                            alert('delete');
                            this.toolEvent.emit({
                                toolName: 'ZONING',
                                tool: this.markerToolEdition,
                                event: 'delete_selection',
                                mode: 'edition',
                                value,
                            });
                        });

                        this.markerToolEdition.on('change', (value) => {
                            this.toolEvent.emit({
                                toolName: 'ZONING',
                                tool: this.markerToolEdition,
                                event: 'change',
                                mode: 'edition',
                                value,
                            });
                        });
                        this.markerToolEdition.on('zone_selected', (value) => {
                            this.toolEvent.emit({
                                toolName: 'ZONING',
                                tool: this.markerToolEdition,
                                event: 'zone_selected',
                                mode: 'edition',
                                value,
                            });
                        });
                        this.selectionTool.add_tool('markerZone', ['markerZone'], this.markerToolEdition, this.markerToolCreation);

                        // Si un objet référence existe, on le set puis on active le tool creation
                        if (this.referenceToMarker) {
                            this.markerToolCreation.referenceToMarker = this.referenceToMarker;
                            this.markerToolCreation.setMarkerModeToZone();
                            this.selectionTool.set_main_tool('markerZone', true);
                        } else {
                            this.selectionTool.set_main_tool('markerZone', false);
                        }

                        // On active l'affichage des markers
                        this.svgMap.set_draw_comments(true);
                        this.svgMap.draw_background = true;
                        // Affection du tool à la map
                        this.svgMap.set_svg_tool(this.selectionTool);
                        this.svgMap.refresh_degraded();
                    }
                })
            )
            .subscribe();
    }

    private loadBM() {
        this.loadBackgroundmaps(this._currentBien)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.refresh());
    }

    refresh() {
        if (this.cnStoreyComp && this.svgMap && this.svgMap._scene) {
            this.cnStoreyComp.refresh();
        }
    }

    private loadBackgroundmaps(currentBien: RelationInterventionBien): Observable<DrawingPicture> {
        if (this._backgroundMap) {
            return this.backgroundMapApiService
                .downloadBackgroundImage(this.interventionService.getCurrentInterventionValue().id, currentBien.id, this._backgroundMap.fileId)
                .pipe(
                    map((res) => {
                        if (res) {
                            this._backgroundMap.imageUrl = res.fileContent;
                        }
                        return this._backgroundMap;
                    })
                );
        } else {
            return of(null);
        }
    }
}
