import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Volume } from 'src/app/model/bien.model';
import { Zone } from '../../model/zone.model';

@Component({
    selector: 'app-piece-unitaire-besoin',
    templateUrl: './piece-unitaire-besoin.component.html',
    styleUrls: ['./piece-unitaire-besoin.component.scss'],
})
export class PieceUnitaireBesoinComponent implements OnInit {
    @Input()
    pieceUnitaire;

    @Input()
    besoins: Array<any>;

    @Input()
    index;

    @Input()
    isInEditMode: Boolean = true;

    @Input()
    showActions: Boolean = true;

    @Input()
    indexBesoinSelected: number;

    @Input()
    selectedZones: Array<Zone>;

    @Input()
    zone: Zone;

    @Input()
    volumes: Array<Volume>;

    @Output()
    besoinActionEmitter = new EventEmitter<any>();
    _showPu: Boolean = false;

    constructor() {}

    ngOnInit(): void {}

    //Méthode appellée par la Vue permettant de définir si une pièce-unitaire doit être affichée
    // Une pièce-unitaire est masquée lorsqu'elle ne contient aucun volume associé au niveau sélectionné
    showPu() {
        this._showPu = false;
        //La pièce unitaire est masquée si aucun volume ne correspond
        this.pieceUnitaire.listeIdVolume.forEach((idVolume: string) => {
            if (this.volumes.find((volume: Volume) => volume.id == idVolume) != undefined) {
                this._showPu = true;
            }
        });
        return this._showPu;
    }

    nouveauPrelevement(e, volumeId): void {
        e.stopPropagation();
        this.besoinActionEmitter.emit({
            action: 'new',
            volumeId,
            zone: this.zone,
            besoinIndex: undefined,
        });
    }

    editBesoin(e, besoin, key): void {
        e.stopPropagation();
        this.besoinActionEmitter.emit({
            action: 'edit',
            volumeId: besoin.pieceId,
            besoinIndex: key,
        });
    }

    selectBesoin(e, besoin, key): void {
        e.stopPropagation();
        this.besoinActionEmitter.emit({
            action: 'select',
            volumeId: besoin.pieceId,
            besoinIndex: key,
        });
    }

    duplicateBesoin(e, besoin, key): void {
        e.stopPropagation();
        this.besoinActionEmitter.emit({
            action: 'duplicate',
            volumeId: besoin.pieceId,
            besoinIndex: key,
        });
    }

    deleteBesoin(e, besoin, key): void {
        e.stopPropagation();
        this.besoinActionEmitter.emit({
            action: 'delete',
            volumeId: besoin.pieceId,
            besoinIndex: key,
        });
    }
}
