import { competence } from '../enum/competence';
import { Zone } from './zone.model';

export class Estimation {
    constructor(params?: { nombreBatiment: number; nombreZones: number; nombreMeta: number; nombreMetop: number; nombreVentilateurs: number }) {
        if (params) {
            this.strategie.nombreBatiment = params.nombreBatiment;
            this.premiereIntervention.nombreBatiment = params.nombreBatiment;

            this.strategie.nombreZones = params.nombreZones;

            this.strategie.nombreMeta = params.nombreMeta;
            this.strategie.nombreMetop = params.nombreMetop;
            this.premiereIntervention.nombreMeta = params.nombreMeta + 1;
            this.premiereIntervention.nombreMetop = params.nombreMetop + 1;

            this.strategie.nombreVentilateurs = params.nombreVentilateurs;
        }
    }

    strategie: {
        nombreBatiment?: number;
        nombreZones?: number;
        nombreMeta?: number;
        nombreMetop?: number;
        nombreVentilateurs?: number;
        commentaire?: string;
    } = {
        nombreBatiment: 0,
        nombreZones: 0,
        nombreMeta: 0,
        nombreMetop: 0,
        nombreVentilateurs: 0,
        commentaire: '',
    };
    premiereIntervention: {
        nombreBatiment?: number;
        zones: Zone[];
        nombreMeta?: number;
        nombreMetop?: number;
        nombreVentilateurs?: number;
        commentaire?: string;
        dureeVisiteHeures?: number;
        dureeVisiteMinutes?: number;
    } = {
        nombreBatiment: 0,
        zones: [],
        nombreMeta: 0,
        nombreMetop: 0,
        nombreVentilateurs: 0,
        dureeVisiteHeures: 0,
        dureeVisiteMinutes: 0,
        commentaire: '',
    };
    listeCompetences?: competence[] = [];
}
