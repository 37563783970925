import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Polluant } from '../../../../model/polluant.model';
import { FormContext } from '../../../../../../../model/rule/form-context.model';
import { FormGroup, Validators } from '@angular/forms';
import { CnSpinnerService } from '../../../../../../shared/cn-spinner/service/cn-spinner.service';
import { takeUntil } from 'rxjs/operators';
import { EtatValidation } from '../../../../../../../model/etat-progression.model';
import { SectionHelp } from '../../../../../../../model/section-help.model';
import { CSPOption, SS3Option, SS4Option } from '../../../../shared/polluant.constants';
import { combineLatest } from 'rxjs';
import { InterventionService } from 'src/app/services/intervention.service';
import { PolluantService } from '../../../../services/polluant.service';
import { PolluantComponent } from '../../../../utils/polluant-component';
import { TypePrestationEnum } from 'src/app/model/type-prestation.model';

@Component({
    selector: 'app-activites',
    templateUrl: './activites.component.html',
    styleUrls: ['./activites.component.scss'],
})
export class ActivitesComponent extends PolluantComponent implements OnInit {
    TypePrestationEnum = TypePrestationEnum;

    activityTypes = [SS3Option, SS4Option, CSPOption];
    isLoaded: Boolean = false;

    helpTypeDActivites = new SectionHelp("Type d'activités amiante", TypePrestationEnum.POLLUANT_ETUDE_SITUATION, 'type-d-activite');

    formActivites: FormGroup;

    constructor(
        private interventionService: InterventionService,
        private cnSpinnerService: CnSpinnerService,
        private route: ActivatedRoute,
        private injector: Injector
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.cnSpinnerService.show();
        combineLatest([this.interventionService.getCurrentIntervention(), this.diagnosticService.getCurrentDiagnostic(), this.route.data])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                ([intervention, diag, routerData]) => {
                    this.contenuDiagnostic = this.diagnostic.contenuDiagnostic as Polluant;
                    this.isValidated = this.contenuDiagnostic.activitesAmiante.validated;

                    this.formActivites = this.formBuilder.group({
                        activitesAmiante: this.formService.createFormControl(
                            'activitesAmiante',
                            new FormContext('activitesAmiante.valeurs', this.contenuDiagnostic, Validators.required, this.ngUnsubscribe)
                        ),
                    });

                    this.formActivites.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
                        this.checkValidity();
                    });

                    PolluantService.initPolluant(this.diagnostic.pointsDeControleBiens, intervention.relationInterventionBiens);

                    this.isLoaded = true;
                    this.isInEditMode =
                        !(!!routerData.readonly || this.diagnosticService.isReadOnlyMode(intervention, this.diagnostic)) && this.isInEditMode; //this.isInEditMode = false est défini dans polluant-component.ts si le diag est une visite de chantier
                    if (!this.isInEditMode) {
                        this.formActivites.disable();
                    }
                    this.cnSpinnerService.hide();
                },
                () => this.cnSpinnerService.hide()
            );
    }

    private checkValidity() {
        let isFormEnabled = this.formActivites.enabled;

        //Si le formulaire est désactivé, on l'active afin de pouvoir savoir si il est valide
        if (!isFormEnabled) {
            this.formActivites.enable({
                emitEvent: false,
            });
        }

        let etat: EtatValidation = 'INVALID';
        if (this.typeMesurage == TypePrestationEnum.POLLUANT_VISITE_CHANTIER) {
            //L'écran est valide si le formulaire n'a pas d'erreurs
            // et si les données de l'écran activitesAmiante ont été validées

            if (this.formActivites && this.formActivites.valid && this.isValidated && this.contenuDiagnostic.activitesAmiante.validated) {
                etat = 'VALID';
            }
        } else {
            //L'écran est valide si le formulaire n'a pas d'erreurs
            if (this.formActivites && this.formActivites.valid) {
                etat = 'VALID';
            }
        }

        //Si le formulaire était désactivé avant, on le désactive à nouveau
        if (!isFormEnabled) {
            this.formActivites.disable({
                emitEvent: false,
            });
        }

        const code = this.route.snapshot.data['code'];
        this.etatProgressionService.updateDiagnostic(code, etat, this.diagnostic);
    }

    //Interface IValidatableComponent implémentation
    cancelModification() {
        this.formActivites.disable();
        this.isInEditMode = false;
        this.formActivites.patchValue(this.previousFormValue);
        this.previousFormValue = undefined;
    }

    saveModification() {
        this.formActivites.disable();
        this.isInEditMode = false;
        this.checkValidity();
    }

    validateTab() {
        this.isValidated = true;
        this.contenuDiagnostic.activitesAmiante.validated = true;
        this.checkValidity();
    }

    startModification() {
        //Unvalidate screen
        this.isValidated = false;
        this.contenuDiagnostic.activitesAmiante.validated = false;
        this.checkValidity();

        //Enable form
        this.formActivites.enable();
        this.isInEditMode = true;

        //Memorize previous form value
        this.previousFormValue = this.formActivites.getRawValue();
    }
}
