import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/commons-lib';
import { URL_REFERENTIELS } from 'src/app/shared/constants/url.constants';

@Component({
    selector: 'app-libs-icones',
    templateUrl: './libs-icones.component.html',
    styleUrls: ['./libs-icones.component.scss'],
})
export class LibsIconesComponent extends BaseComponent implements OnInit {
    constructor(private readonly router: Router, private iconRegistry: MatIconRegistry) {
        super();
    }

    iconList = [];

    ngOnInit() {
        this.iconRegistry['_svgIconConfigs'].forEach((value, key) => {
            this.iconList.push(key.slice(1));
        });
    }

    back() {
        this.router.navigate([URL_REFERENTIELS]);
    }
}
