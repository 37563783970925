import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { PageRequest, PaginatedDataSource } from '../../../../shared/paging/page';
import { Sort } from '@angular/material/sort';
import { SessionStoragePaginationUtils } from '../../../../utils/session-storage-pagination.utils';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from 'src/app/commons-lib';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { CnSpinnerService } from '../../../shared/cn-spinner/service/cn-spinner.service';
import { Agence } from '../../../../model/agence.model';
import { Router } from '@angular/router';
import { AgenceApiService } from '../../../../services/agence-api.service';
import { DEBOUNCE_TIME_VALUE, INITIAL_PAGE_SIZE, SESSION_STORAGE_KEY_AGENCE } from 'src/app/shared/constants/admin.constants';
import { URL_GESTION_AGENCES_CONSULTER, URL_GESTION_AGENCES_EDIT } from 'src/app/shared/constants/url.constants';

export const DEFAULT_SORT = { direction: 'asc', active: 'nom' };

@Component({
    selector: 'app-tableau-agences',
    templateUrl: './tableau-agences.component.html',
    styleUrls: ['./tableau-agences.component.scss'],
})
export class TableauAgencesComponent extends BaseComponent implements OnInit {
    @Input()
    initialPageSize = INITIAL_PAGE_SIZE;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayedColumns = ['typeAgence', 'nom', 'adresse.voie', 'adresse.ville', 'adresse.codePostal', 'actions'];

    dataSource: PaginatedDataSource<Agence>;
    sort: Sort;
    searchValue = SessionStoragePaginationUtils.getInitialReseach(SESSION_STORAGE_KEY_AGENCE);

    private search = new BehaviorSubject(this.searchValue);

    constructor(
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly router: Router,
        private readonly agenceApiService: AgenceApiService
    ) {
        super();
        this.sort = SessionStoragePaginationUtils.getInitialSort(SESSION_STORAGE_KEY_AGENCE, DEFAULT_SORT);
    }

    ngOnInit(): void {
        // Abonnement pour les valeurs saisies dans le champs de recherche
        this.search.pipe(debounceTime(DEBOUNCE_TIME_VALUE), distinctUntilChanged(), takeUntil(this.ngUnsubscribe)).subscribe((search) => {
            this.dataSource.fetch(0, this.dataSource.size);
        });

        this.dataSource = new PaginatedDataSource<Agence>(
            (pageRequest) => {
                return this.cnSpinnerService.withSpinner(this.getAgences(this.search.getValue(), pageRequest));
            },
            SessionStoragePaginationUtils.getInitialSort(SESSION_STORAGE_KEY_AGENCE, DEFAULT_SORT),
            SessionStoragePaginationUtils.getInitialPageSize(SESSION_STORAGE_KEY_AGENCE, this.initialPageSize)
        );
    }

    /**
     * Récupère la liste des agences à afficher dans le tableau
     * @param search
     * @param pageRequest
     */
    getAgences(search: string, pageRequest: PageRequest<Agence>) {
        return this.agenceApiService.searchAgences(search, pageRequest).pipe(takeUntil(this.ngUnsubscribe));
    }

    /**
     * Action déclenchée lors du click sur le bouton "Editer"
     * @param agence
     */
    onClickEditerAgence(agence: Agence) {
        this.setSessionStorageItem();
        this.router.navigate([URL_GESTION_AGENCES_EDIT, agence.id]);
    }

    /**
     * Action déclenchée au click sur le bouton "Consulter"
     * @param agence
     */
    onClickConsulter(agence: Agence) {
        this.setSessionStorageItem();
        this.router.navigate([URL_GESTION_AGENCES_CONSULTER, agence.id]);
    }

    /**
     * Action déclenchée lors de la saisie d'un caractère dans la barre de recherche
     * @param $event
     */
    onKeyupSearchAgences($event: any) {
        this.search.next($event.target.value);
        this.setSessionStorageItem();
    }

    /**
     * Action déclenchée lors d'un tri dans le tableau
     * @param $event
     */
    sortAgences($event: Sort) {
        this.sort = $event;
        this.dataSource.sortBy($event);
    }

    /**
     * Set les valeurs du tableau dans le session storage (pagination, recherche, tri ..)
     * @private
     */
    private setSessionStorageItem() {
        SessionStoragePaginationUtils.setSessionStorageItem(SESSION_STORAGE_KEY_AGENCE, this.dataSource.size, this.search.getValue(), this.sort);
    }
}
