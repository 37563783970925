<h1 mat-dialog-title class="primary d-flex justify-content-between">
    <span>Planification des besoins</span>
    <button mat-button (click)="dialogRef.close()">
        <lib-icon>close</lib-icon>
    </button>
</h1>

<div mat-dialog-content>
    <div class="w-100 pr-2 pt-2">
        <div class="row align-items-center">
            <label class="col-4 primary">Besoin Hors-Stratégie</label>
            <div class="col-7">
                <app-custom-select
                    class="w-100"
                    [matFormFieldClass]="'w-100'"
                    [choices]="choices"
                    [filter]="false"
                    [addline]="false"
                    [(selectedValue)]="refSelected"
                >
                </app-custom-select>
            </div>
            <button mat-icon-button color="primary" (click)="addPlanificationHorsZone()">
                <mat-icon> add </mat-icon>
            </button>
        </div>
        <div class="d-flex justify-content-between">
            <!-- DEBUT LISTE DE ZONES-->
            <mat-card class="col-3">
                <mat-card-subtitle>Zone</mat-card-subtitle>
                <mat-card-content>
                    <mat-list>
                        <mat-list-item
                            *ngIf="zonesList?.length > 1"
                            [style.minHeight]="'42px'"
                            role="listitem"
                            class="onHover"
                            (click)="onZoneSelected(null)"
                            [ngClass]="{
                                'elec-item-selected': zonesList?.length > 0 && zonesList?.length === zonesSelected?.length
                            }"
                        >
                            <span> Tout sélectioner</span>
                            <mat-divider></mat-divider>
                        </mat-list-item>
                        <ng-container *ngFor="let item of zonesList">
                            <mat-list-item
                                *ngIf="item.nom != 'Hors stratégie'"
                                [style.minHeight]="'42px'"
                                role="listitem"
                                [ngClass]="{ 'elec-item-selected': zoneIsSlected(item) }"
                                class="onHover"
                                (click)="onZoneSelected(item)"
                            >
                                <span> {{ item.nom }} </span>
                                <mat-divider></mat-divider>
                            </mat-list-item>
                        </ng-container>
                    </mat-list>
                </mat-card-content>
            </mat-card>
            <!--FIN LISTE DE ZONES-->
            <!--DEBUT LISTE DES BESOINS -->
            <mat-card class="col-3">
                <mat-card-subtitle>Besoin</mat-card-subtitle>
                <mat-card-content>
                    <mat-list>
                        <mat-list-item [style.minHeight]="'42px'" role="listitem" *ngFor="let item of besoinsList">
                            <div class="w-100 d-flex align-items-center justify-content-between">
                                <span> {{ item?.objectifMesurage?.reference }} ({{ item?.numero }}) </span>
                                <button mat-icon-button color="primary" (click)="addBesoin(item)">
                                    <mat-icon> add </mat-icon>
                                </button>
                            </div>
                            <mat-divider></mat-divider>
                        </mat-list-item>
                    </mat-list>
                </mat-card-content>
            </mat-card>
            <!--FIN LISTE DES BESOINS -->
            <mat-card class="col-4">
                <mat-card-subtitle>Planification</mat-card-subtitle>
                <mat-card-content>
                    <mat-list>
                        <mat-list-item [style.minHeight]="'42px'" role="listitem" *ngFor="let item of planifications">
                            <div class="w-100 d-flex align-items-center justify-content-between">
                                <span>
                                    {{ item.zone.nom + ' / ' + (item.besoin?.objectifMesurage?.reference || item?.besoin?.reference) }}
                                    ({{ item.besoin?.numero || '' }})
                                </span>
                                <button mat-icon-button color="warn" (click)="delPlanification(item)">
                                    <mat-icon> remove </mat-icon>
                                </button>
                            </div>
                            <mat-divider></mat-divider>
                        </mat-list-item>
                    </mat-list>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-raised-button color="accent" type="button" (click)="onValidate()">
        <lib-icon>check</lib-icon>
        <span>{{ 'Valider' | uppercase }}</span>
    </button>
</div>
