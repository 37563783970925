import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-sequence-card',
    templateUrl: './sequence-card.component.html',
    styleUrls: ['./sequence-card.component.scss'],
})
export class SequenceCardComponent implements OnInit {
    @Input()
    sequence: FormGroup;

    @Input()
    number: string;
    isSelected: any;

    constructor() {}

    ngOnInit(): void {}
}
