import { CnmapObjectUtils, GenericObject, IObjectsHandler, ParamObjectPanelBehavior } from '@acenv/cnmap-angular-editor-lib';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { ReferenceService } from '../../../../../services/reference.service';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { ParametersGroup, WorkType } from '@acenv/commons-lib';

@Injectable({
    providedIn: 'root',
})
export class ObjectsHandler implements IObjectsHandler {
    private parametersGroups$: Observable<ParametersGroup[]>;
    private productsTypes$: Observable<WorkType[]>;
    private genericObjects$: Observable<GenericObject[]>;
    constructor(private http: HttpClient, private readonly referenceService: ReferenceService) {}
    getDimensionsLabels(): string[] {
        return [
            'Largeur', // axe X
            'Profondeur', // axe Y
            'Hauteur', // axe Z
        ];
    }
    nameCompletionCallback(): Function {
        return null;
    }
    getParamObjectPanelBehavior(): ParamObjectPanelBehavior {
        return ParamObjectPanelBehavior.COLLAPSE_ALL;
    }

    getObjectsParametersGroups(): Observable<ParametersGroup[]> {
        if (this.parametersGroups$ == null) {
            this.parametersGroups$ = this.referenceService.findAllParametersGroups().pipe(shareReplay({ bufferSize: 1, refCount: true }));
        }
        return this.parametersGroups$;
    }

    getObjectsProductsTypes(): Observable<WorkType[]> {
        if (this.productsTypes$ == null) {
            this.productsTypes$ = combineLatest([this.referenceService.findAllCategoriesEquipements()]).pipe(
                switchMap(([objects]) => {
                    const productTypes = objects
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((obj) => {
                            const productType = new WorkType();
                            productType.id = obj.id;
                            productType.codeBim = obj.codeBim;
                            productType.name = obj.name;
                            productType.description = obj.description;
                            productType.parentsIds = obj.parentsIds;
                            productType.nbProducts = obj.nbProducts;
                            productType.abstract = obj.abstract;
                            productType.flag = obj.flag;
                            productType.parametersDefinitions = obj.parametersDefinitions;
                            productType.parametersDefaultValues = obj.parametersDefaultValues;
                            return productType;
                        });
                    productTypes.push(CnmapObjectUtils.productTypeUnknown);
                    return of(productTypes);
                })
            );
        }
        return this.productsTypes$;
    }

    getGenericObjects(): Observable<GenericObject[]> {
        if (this.genericObjects$ == null) {
            this.genericObjects$ = this.referenceService.findAllEquipements().pipe(
                map((its) => {
                    const genericObjects = [];
                    if (its?.length) {
                        for (const it of its) {
                            const result = new GenericObject();
                            result.id = it.id;
                            result.name = it.name;
                            result.productTypeCodeBim = it.productTypeCodeBim;
                            result.width = it.width;
                            result.depth = it.depth;
                            result.height = it.height;
                            result.iconImageData = it.iconImageData;
                            result.contact = it.contact;
                            result.defaultHeight = it.defaultHeight;
                            result.geometricData = it.geometricData;
                            result.parameters = it.parameters;
                            result.roofParallel = it.roofParallel;
                            result.color = it.color;
                            genericObjects.push(result);
                        }
                    }
                    return genericObjects;
                }),
                shareReplay({ bufferSize: 1, refCount: true })
            );
        }
        return this.genericObjects$;
    }
}
