import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MpcaItemOld } from '../../../../model/mpca.model.old';
import { WidgetSelectModeEnum } from '../../../../../../shared/widget-select/widget-select.component';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DiagnosticService } from '../../../../../../../services/diagnostic.service';
import { ObjectifsTypeZone, PolluantConfig } from '../../../../model/polluant-config.model';
import { Polluant } from '../../../../model/polluant.model';
import { PolluantService } from '../../../../services/polluant.service';
import { PolluantPrelevement } from '../../../../model/polluant-prelevement.model';
import { ZoneTravailModalComponent } from '../zone-travail-modal/zone-travail-modal.component';
import { Zone } from '../../../../model/zone.model';
import { ConditionPrelevementSelectionnee } from '../../../../model/conditionPrelevementSelectionnee.model';
import { Besoin } from '../../../../model/besoin.model';
import { ProcessusMPCA } from '../../../../model/processus-mpca.model';
import { MpcaPanelListCardComponent } from 'src/app/modules/shared/mpca-panel-list-card/mpca-panel-list-card';
import { MongoUtils } from '../../../../../../../lib/utils/mongo.utils';
import { Surface } from '../../../../model/surface.model';
import { EtatValidation } from '../../../../../../../model/etat-progression.model';
import { EtatProgressionService } from '../../../../../../../services/etat-progression.service';
import cloneDeep from 'lodash/cloneDeep';
import { TypePrestationEnum } from 'src/app/model/type-prestation.model';
import { NotificationService } from '../../../../../../../lib/notification/notification.service';
import { ConfirmationService } from '@acenv/commons-lib';
import { BaseComponent } from '../../../../../../../lib/utils/base.component';

@Component({
    selector: 'app-zone-propriete-modal',
    templateUrl: './zone-propriete-modal.component.html',
    styleUrls: ['./zone-propriete-modal.component.scss'],
})
export class ZoneProprieteModalComponent extends BaseComponent {
    objectifsMultiplePossible: boolean = true;
    mpcaList: MpcaItemOld[] = [];
    @Input() isModal: boolean = true;
    @Input() mpcaListSelected: MpcaItemOld[] = [];
    @Input() polluantConfig: PolluantConfig;
    @Input() indexZone: number;
    @Input() lienVolumeObj: any;
    @Input() isInEditMode: Boolean = true;
    @Input() set form(form: FormGroup) {
        if (!form) return;
        this._form = form;
        this.mpcaListSelected = this.form?.get('listeMpca')?.value;
        this._isContextTravaux();
        this.initialiseContexte();
        this.majListeTypeZone(true);
        this.onTypeZoneChange(true);
        // Initalisation du objectifsMultiplePossible
        this.onZSEChange(true);
    }

    contenuPolluant: Polluant | PolluantPrelevement;
    zones: Zone[] = [];
    objectifsMesurage: ConditionPrelevementSelectionnee[];
    objectifsMesurageEligible: ConditionPrelevementSelectionnee[];
    isContextTravaux: Boolean = true;
    typeDeZone: String[] = [];
    objTypeDeZone: ObjectifsTypeZone[] = [];
    ZoneSimilaireEchantillonnage: String[] = [];
    zone: FormGroup = null;
    formBuilder: FormBuilder;
    isNewZone: Boolean = true;
    widgetSelectModeEnum: typeof WidgetSelectModeEnum = WidgetSelectModeEnum;
    public isDuplication: boolean; // Duplication puis annulation => faudrait pouvoir supprimer pour éviter deux zones du même nom

    private _form: FormGroup;
    private formZones: FormArray;
    private formArraySurfaces: FormArray;
    private route: any;
    private diagnosticPolluant: any;
    private currentDiagnostic: any;
    private typeMesurage: any;
    private etatProgressionService: EtatProgressionService;
    private notificationService: NotificationService;

    get form() {
        return this._form;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<ZoneTravailModalComponent>,
        private diagnosticService: DiagnosticService,
        public polluantService: PolluantService,
        public dialog: MatDialog,
        private readonly confirmationService: ConfirmationService
    ) {
        super();
        this.diagnosticService
            .getCurrentDiagnostic()
            .subscribe((diag) => {
                if (diag.contenuDiagnostic instanceof PolluantPrelevement) {
                    this.contenuPolluant = diag.contenuDiagnostic as PolluantPrelevement;
                    this.mpcaList = this.contenuPolluant.visiteDeChantier.data.mpcaList.data.descriptifList;
                    this.objectifsMesurage = this.contenuPolluant.visiteDeChantier.data.objectifsMesurage.data.objectifsMesurage;
                    this.contenuPolluant.listeZones.data.listeZones.forEach((z) => this.zones.push(new Zone(z)));
                } else {
                    this.contenuPolluant = diag.contenuDiagnostic as Polluant;
                    this.mpcaList = this.contenuPolluant.mpcaList.data.descriptifList;
                    this.objectifsMesurage = this.contenuPolluant.objectifsMesurage.data.objectifsMesurage;
                    if (this.contenuPolluant.zones.data.zonesList) {
                        this.contenuPolluant.zones.data.zonesList.forEach((z) => this.zones.push(new Zone(z)));
                    } else {
                        this.zones = [];
                    }
                }
            })
            .unsubscribe();
        this.polluantConfig = data?.polluantConfig;
        this.indexZone = parseInt(data?.indexZone);
        this.isInEditMode = data?.isInEditMode;
        this.lienVolumeObj = data?.lienVolumeObj;
        this.form = data?.form;
        this.zone = data?.zone;
        this.formBuilder = data?.formBuilder;
        this.formArraySurfaces = data?.formArraySurfaces;
        this.formZones = data?.formZones;
        this.route = data?.route;
        this.diagnosticPolluant = data?.diagnosticPolluant;
        this.currentDiagnostic = data?.currentDiagnostic;
        this.typeMesurage = data?.typeMesurage;
        this.etatProgressionService = data?.etatProgressionService;
        this.notificationService = data?.notificationService;
        this.isNewZone = data?.isNewZone;
    }

    private initialiseContexte() {
        let presenceContexteTravaux = false;
        let presenceContexteHorsTravaux = false;
        this.polluantConfig.objectifsMesurage.forEach((o) => {
            o.conditionsPrelevement.forEach((c) => {
                const i = this.objectifsMesurage.findIndex((m) => m.reference === c.reference);
                if (i > -1) {
                    if (c.contextTravaux) {
                        presenceContexteTravaux = true;
                    } else {
                        presenceContexteHorsTravaux = true;
                    }
                }
            });
        });

        if (presenceContexteTravaux && !presenceContexteHorsTravaux) this.form.get('zoneContexte').setValue('Pendant travaux');
        if (!presenceContexteTravaux && presenceContexteHorsTravaux) this.form.get('zoneContexte').setValue('Hors travaux');
    }

    majListeTypeZone(init = false) {
        this.typeDeZone = [];

        this.objTypeDeZone = this.polluantConfig.objectifsEligible.filter((typeZ) => {
            return (
                this.form.get('zoneContexte').value === typeZ.contexte &&
                typeZ.environnement.every((a) => this.form.get('environnement').value.includes(a)) &&
                this.objectifsMesurage.filter((o) => {
                    return typeZ.objectifs.includes(o.reference);
                }).length > 0
            );
        });

        this.objTypeDeZone.forEach((o) => {
            if (!this.typeDeZone.includes(o.typeZone)) this.typeDeZone.push(o.typeZone);
        });

        if (!init) {
            this.form.patchValue({
                typeZone: undefined,
                objectifMesurage: [],
            });
        }

        // this.onTypeZoneChange(init);
    }

    onTypeZoneChange(init: boolean = false) {
        this.ZoneSimilaireEchantillonnage = [];
        this.objectifsMesurageEligible = [];
        if (init == false) this.form.controls.objectifMesurage.setValue([]);

        var zse = this.objTypeDeZone.filter((typeZ) => {
            return typeZ.typeZone == this.form.get('typeZone').value;
        });

        zse.forEach((z) => {
            this.objectifsMultiplePossible = z.objectifsMultiple;
            if (this.objectifsMultiplePossible && !Array.isArray(this.form.value.objectifMesurage)) {
                this.form.value.objectifMesurage = [];
            }

            z.objectifs.forEach((o) => {
                const i = this.objectifsMesurage.findIndex((m) => m.reference === o);
                if (i > -1 && !this.objectifsMesurageEligible.includes(this.objectifsMesurage[i]))
                    this.objectifsMesurageEligible.push(this.objectifsMesurage[i]);
            });
            if (!this.ZoneSimilaireEchantillonnage.includes(z.zse) && z.zse != null) this.ZoneSimilaireEchantillonnage.push(z.zse);
        });

        if (this.ZoneSimilaireEchantillonnage.length > 0) {
            this.form.controls.zse.setValidators([Validators.required]);
        } else {
            this.form.controls.zse.clearValidators();
            this.form.controls.zse.setErrors(null);
            this.form.controls.zse.setValue([]);
        }

        this._isContextTravaux();
        this.genererNomZone();
    }

    onZSEChange(isInit?: boolean) {
        if (!isInit) {
            this.objectifsMesurageEligible = [];
            this.form.controls.objectifMesurage.setValue([]);
        }

        const zse = this.objTypeDeZone.find((z) => z.zse == this.form.get('zse').value);
        if (!!zse) {
            if (!isInit) {
                zse.objectifs.map((ref) => {
                    const i = this.objectifsMesurage.findIndex((m) => m.reference === ref);
                    if (i > -1) {
                        this.objectifsMesurageEligible.push(this.objectifsMesurage[i]);
                    }
                });
            }
            this.objectifsMultiplePossible = zse.objectifsMultiple;
        }
    }

    addMpca(mpcaId: string) {
        const mpca = MpcaPanelListCardComponent.deepCloneMpca(this.mpcaList.find((mpca) => mpca.id == mpcaId));

        this.mpcaListSelected.push(mpca);
        this.form.get('listeMpca').setValue(this.mpcaListSelected);
        this.genererNomZone();
    }

    deleteMpca(mpcaId: string) {
        const index = this.mpcaListSelected.indexOf(this.mpcaListSelected.find((m) => m.id == mpcaId));
        this.mpcaListSelected.splice(index, 1);
        this.form.get('listeMpca').setValue(this.mpcaListSelected);
        this.genererNomZone();

        //Réinitialisation du champ processus pour tous les besoins qui ont un processus associé à ce MPCA
        const mpca = this.mpcaList.find((mpca) => mpcaId == mpca.id);
        mpca.processus.forEach((processus: ProcessusMPCA) => {
            const besoinsList = (this.contenuPolluant as Polluant).besoins?.data?.besoinsList;
            if (besoinsList?.length) {
                besoinsList.forEach((besoin: Besoin) => {
                    if (besoin.processusId == processus.id) {
                        besoin.processusId = '';
                    }
                });
            }
        });
    }

    /**
     * Si on clique sur "Retirée" : il faut renseigner tous les champs suivant en retirée :
     * Etat de dégradation
     * Type de protection
     * Etanchéité protection
     * Choc et vibration
     * Circulation d'air
     */
    retirerMpca(mpcaId: any) {
        const mpca = this.mpcaListSelected.find((mpca) => mpca.id === mpcaId);
        if (!mpca) {
            return console.error('Erreur mpca non existant : ' + mpcaId);
        }
        mpca.general.etatDegradation = 'Retiré';
        mpca.protectionExposition.type = 'Matériau retiré';
        mpca.protectionExposition.etancheite = 'Matériau retiré';
        mpca.protectionExposition.circulationAir = 'Matériau retiré';
        mpca.protectionExposition.chocVibration = 'Matériau retiré';
    }

    /**
     * Si on clique sur "Encapsulée" : il faut renseigner tous les champs suivant:
     * Etat de dégradation
     * Type de protection
     * Etanchéité protection
     * Choc et vibration
     * Circulation d'air
     * @param mpcaId
     */
    encapsulerMpca(mpcaId: any) {
        // const lMpca = this.mpcaList.find((mpca) => mpca.id === mpcaId);
        const mpca = this.mpcaListSelected.find((mpca) => mpca.id === mpcaId);
        const ongletMPCA = (this.contenuPolluant as Polluant).mpcaList.data.descriptifList.find((mpca) => mpca.id === mpcaId);

        if (!(mpca || ongletMPCA)) {
            return console.error('Erreur mpca non existant : ' + mpcaId);
        }
        mpca.general.etatDegradation = ongletMPCA.general.etatDegradation;
        mpca.protectionExposition.type = ongletMPCA.protectionExposition.type;
        mpca.protectionExposition.etancheite = ongletMPCA.protectionExposition.etancheite;
        mpca.protectionExposition.circulationAir = ongletMPCA.protectionExposition.circulationAir;
        mpca.protectionExposition.chocVibration = ongletMPCA.protectionExposition.chocVibration;
    }

    modalAction(action) {
        if (action == 'saveAndClose') {
            if (!this.formValid) {
                return;
            }
            if (this.isDuplication) {
                // Vérify same name
                if (this.formZones.controls.filter((z) => `${z.value.identifiant} - ${z.value.nom}` === this.data.zoneName).length > 1) {
                    return this.notificationService.warn(
                        "Impossible d'avoir deux zones avec le même nom. Veuillez choisir un nom différent pour éviter les conflits."
                    );
                }
                // Vérification qu'il y a pas deux fois le même volume pour un même objectif de mesurage
                if (this.verifyObjectifMesurage()) {
                    this.confirmationService.confirmWarn(
                        'Attention, un ou plusieurs espaces sont déjà inclus dans une autre zone ayant le même objectif de mesure.<br>Souhaitez-vous continuer ?',
                        () => {
                            this.dialogRef.close({
                                action,
                                form: this.form.value,
                                contenuDiagnostic: this.contenuPolluant,
                            });
                        }
                    );
                    /* désactivé temporairement return this.notificationService.warn(
                        'Un espace ne peut pas être inclus dans 2 zones différentes comportant le même objectif de prélèvement.'
                    );*/
                    return;
                }
            }
            this.dialogRef.close({ action, form: this.form.value, contenuDiagnostic: this.contenuPolluant });
        } else if (action == 'cancel') {
            this.dialogRef.close({ action });
        } else if (action == 'delete') {
            this.dialogRef.close({ action });
        } else if (action == 'duplicateZone') {
            this.dialogRef.close({
                action,
                indexZone: this.formZones.length - 1,
            });
        }
    }
    /**
     * Si la zone en cours de duplication a le même objectif de mesurage
     * return true s'il y a des espaces
     * Un espace ne peut pas être inclus dans 2 zones différentes si même objectif de mesurage
     * sinon false
     */
    private verifyObjectifMesurage(): boolean {
        const oldForm = this.formZones.controls[this.indexZone];
        if (oldForm.value.objectifMesurage.some((item) => this.form.value.objectifMesurage.map((o) => o.parentId).includes(item.parentId))) {
            // C'est le même objectif de mesurage : la zone a des espaces ?
            if (oldForm.value.listeSurfaces.some((surface) => surface.listeIdVolume?.length)) {
                return true;
            }
        }
        return false;
    }

    get formValid(): boolean {
        for (const key of Object.keys(this.form.controls)) {
            const controlErrors: ValidationErrors = this.form.get(key).errors;
            if (controlErrors != null) {
                return false;
            }
        }
        return true;
    }
    /**
     * @description Fonction appellée par chaque élément du menu déroulant Objectif de mesurage
     *              afin de définir si il doit être désactivé ou non en fonction des choix précédents
     * @param item
     */
    isDisabled(item) {
        let state = false;
        let formValues = this.form.value.objectifMesurage;
        //Toutes les cases cochées doivent pouvoir être décochées
        formValues.forEach((formValue: any) => {
            if (!this.objectifsMultiplePossible && formValue.reference != item.reference) {
                state = true;
            }
        });

        this._isContextTravaux();

        return state;
    }

    //Fonction permettant de préselectionner un objectif de mesurage dans le formulaire
    protected compareObj(obj1: any, obj2: any): boolean {
        return obj1.reference == obj2.reference;
    }

    onObjectifMesurageChange() {
        this._isContextTravaux();
        this.genererNomZone();
    }

    /**
     * @description Génère le nom de la zone en fonction de l'obj de mesurage sélectionné
     * et du MPCA
     */
    genererNomZone() {
        let nom = [];

        if (this.form.get('typeZone').value != 'zone de travail annexe') {
            nom.push(this.form.get('typeZone').value);
        } else {
            nom.push('zse');
            nom.push(this.form.get('zse').value);
        }

        let mpcaList = [];

        // Concaténation des noms de MPCA
        this.form.value.listeMpca.forEach((mpca: MpcaItemOld, index) => {
            mpcaList.push(mpca.general?.materiaux.nom);
        });
        if (mpcaList.length != 0) {
            nom.push('(' + mpcaList.join(', ') + ')');
        }

        this.form.get('identifiant').setValue(nom.join(' - '));
    }

    /**
     * Algo pour déterminer les objectifs éligible en fonction du type de zone et ZSE sélectionnées
     * Voir Annexe 2.2 du document "Nouvelle norme avec ZSE V4.pdf"
     */
    updateListeObjectifs() {}

    /**
     * @description
     *
     */
    _isContextTravaux() {
        // const objectifs = this.form.value.objectifMesurage;
        // const objectifsMesurage = this.polluantConfig.objectifsMesurage;
        var state = false;

        if (this.form.get('typeZone').value == 'Zone de travail') {
            state = true;
        }

        if (!state) {
            // vider le from et enlever les required
            this.form.controls.activiteAdjacente.clearValidators();
            this.form.controls.confinement.clearValidators();
            this.form.controls.nombreExtracteurs.clearValidators();
            this.form.controls.sasMateriel.clearValidators();
            this.form.controls.sasPersonnel.clearValidators();

            this.form.controls.activiteAdjacente.setErrors(null);
            this.form.controls.confinement.setErrors(null);
            this.form.controls.nombreExtracteurs.setErrors(null);
            this.form.controls.sasMateriel.setErrors(null);
            this.form.controls.sasPersonnel.setErrors(null);

            this.form.patchValue({
                activiteAdjacente: '',
                confinement: '',
                nombreExtracteurs: 0,
                sasMateriel: '',
                sasPersonnel: '',
            });
        } else {
            this.form.controls.activiteAdjacente.setValidators([Validators.required]);
            this.form.controls.confinement.setValidators([Validators.required]);
            this.form.controls.nombreExtracteurs.setValidators([Validators.required]);
            this.form.controls.sasMateriel.setValidators([Validators.required]);
            this.form.controls.sasPersonnel.setValidators([Validators.required]);
        }

        this.form.updateValueAndValidity();

        this.isContextTravaux = state;
    }

    selectZoneTravail() {
        const dialogRef = this.dialog.open(ZoneTravailModalComponent, {
            width: '65%',
            data: {
                polluantConfig: this.polluantConfig,
                isInEditMode: this.isInEditMode,
                form: this.createFormZoneTravail(),
            },
            disableClose: true,
            autoFocus: true,
        });
        dialogRef.componentInstance.zoneAssociee = this.form.controls.zoneAssociee.value;
        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'cancel') {
                return;
            } else if (result === 'delete') {
                this.form.controls.idZoneAssociee.setValue('');
                this.form.controls.zoneAssociee.setValue('');
            } else {
                //Update values in the form
                this.form.controls.idZoneAssociee.setValue(result?.id);
                this.form.controls.zoneAssociee.setValue(result);
            }
        });
    }

    createFormZoneTravail() {
        return new FormGroup({
            id: new FormControl(this.form.controls.id.value),
            environnement: new FormControl(this.form.controls.environnement.value),
            idZoneAssociee: new FormControl(this.form.controls.idZoneAssociee.value),
            zoneAssociee: new FormControl(this.form.controls.zoneAssociee.value),
        });
    }

    get zoneCurrent(): Zone {
        if (this.form.controls.id.value != undefined) {
            return this.zones.filter((z) => {
                return z.id == this.form.controls.id.value;
            })[0];
        } else {
            return null;
        }
    }

    duplicateFormGroupZone(zone: FormGroup): FormGroup {
        this.zone = zone;
        return this.formBuilder.group({
            id: [MongoUtils.generateObjectId()],
            identifiant: [zone.get('identifiant').value, Validators.required],
            nom: [''],
            idPerimetre: [zone.get('idPerimetre').value],
            usageLocaux: [zone.get('usageLocaux').value, Validators.required],
            objectifMesurage: [zone.get('objectifMesurage').value, Validators.required],
            ventilation: [zone.get('ventilation').value, Validators.required],
            environnement: [zone.get('environnement').value, Validators.required],
            frequentation: [zone.get('frequentation').value, Validators.required],
            personnesPresentes: [zone.get('personnesPresentes').value, Validators.required],
            activiteAdjacente: [zone.get('activiteAdjacente').value, Validators.required],
            confinement: [zone.get('confinement').value, Validators.required],
            nombreExtracteurs: [zone.get('nombreExtracteurs').value, Validators.required],
            sasMateriel: [zone.get('sasMateriel').value, Validators.required],
            sasPersonnel: [zone.get('sasPersonnel').value, Validators.required],
            listeMpca: [zone.get('listeMpca').value, Validators.required],
            listeSurfaces: this.getSurfaceGroup(zone.get('listeSurfaces').value),
            zoneContexte: [zone.get('zoneContexte').value, Validators.required],
            typeZone: [zone.get('typeZone').value, Validators.required],
            zse: [zone.get('zse').value],
            idZoneAssociee: [zone.get('idZoneAssociee').value],
        });
    }

    getSurfaceGroup(listeSurfaces: Surface[]) {
        var surfaceFormArray = new FormArray([]);
        listeSurfaces.forEach((surface) => {
            const surfaceForm = this.createNewSurfaceForm(surface);
            surfaceFormArray.push(surfaceForm);
        });

        return surfaceFormArray;
    }

    createNewSurfaceForm(surface: Surface) {
        const formSurface = new FormGroup(
            {
                superficie: new FormControl(surface.superficie, Validators.required),
                longueurMax: new FormControl(surface.longueurMax, {
                    validators: surface.inferieurSeuilMin ? undefined : Validators.required,
                    updateOn: 'change',
                }),
                inferieurSeuilMin: new FormControl(surface.inferieurSeuilMin, {
                    validators: [Validators.required],
                    updateOn: 'change',
                }),
                listeIdVolume: new FormControl(surface.listeIdVolume, Validators.required),
                listeCommentaire: new FormControl(surface.listeCommentaire),
                nombrePu: new FormControl(surface.nombrePu),
                longueurInterface: new FormControl(surface.longueurInterface),
                hauteurInterface: new FormControl(surface.hauteurInterface),
                surfaceInterface: new FormControl(surface.surfaceInterface),
                longueurSurfaceTravail: new FormControl(surface.longueurSurfaceTravail),
                largeurSurfaceTravail: new FormControl(surface.largeurSurfaceTravail),

                activeSurfaceInterface: new FormControl(surface.activeSurfaceInterface),
                typeSurface: new FormControl(surface.typeSurface),
                aditionnalSurfaveValue: new FormControl(surface.aditionnalSurfaveValue),
                surfaceCalcul: new FormControl(surface.surfaceCalcul),
            },
            { updateOn: 'blur' }
        );
        //UpdateOn: Blur permet de ne trigger l'event ValueChange que lorsque l'utilisateur
        //          clique en dehors du champ

        formSurface.valueChanges.subscribe((value) => {
            this.formArraySurfaces.controls.forEach((formSurface: FormGroup) => {
                let surface = formSurface.getRawValue();
                let puNb;
                if (surface.longueurInterface > 0 && surface.hauteurInterface > 0) {
                    puNb = Math.ceil(
                        (14 * surface.longueurInterface * surface.hauteurInterface) / (730 + surface.longueurInterface * surface.hauteurInterface)
                    );
                } else if (surface.superficie <= 100 && surface.longueurMax <= 15) {
                    puNb = 1;
                } else if (surface.superficie <= 100 && surface.longueurMax > 15) {
                    puNb = Math.ceil(surface.longueurMax / 15);
                } else if (surface.superficie > 100) {
                    puNb = Math.ceil((14 * surface.superficie) / (730 + surface.superficie));
                } else {
                    puNb = 1;
                }

                formSurface.get('nombrePu').setValue(puNb, { emitEvent: false });
            });
            //Mise à jour du diagnostic
            this.checkValidity();
        });

        return formSurface;
    }

    private checkValidity() {
        const code = this.route.snapshot.data['code'];

        //Si le formulaire est désactivé, on l'active afin de pouvoir savoir si il est valide
        let isFormEnabled = this.form.enabled;
        if (!isFormEnabled) {
            this.form.enable({
                emitEvent: false,
            });
        }

        //Récupération de toutes les erreurs
        const erreursZones = [];
        this.formZones.controls.forEach((formZone: FormGroup, index: number) => {
            let erreursZone = {
                numero: index + 1,
                erreurs: [],
            };

            //boucle sur les controles du formulaire pour trouver les erreurs
            for (const controlName in formZone.controls) {
                if (formZone.get(controlName).invalid == true) {
                    erreursZone.erreurs.push(controlName);
                }
            }

            //Si il y'a des erreurs
            if (erreursZone.erreurs.length != 0) {
                erreursZones.push(erreursZone);
            }
        });

        // this.diagnosticPolluant.zones.data = this.form.value; // Pas possible car form est le form d'une zone
        this.diagnosticPolluant.zones.errors = erreursZones;

        this.currentDiagnostic.contenuDiagnostic = this.diagnosticPolluant;

        let etat: EtatValidation = 'INVALID';
        if (this.typeMesurage == TypePrestationEnum.POLLUANT_VISITE_CHANTIER) {
            //L'écran est valide si le formulaire n'a pas d'erreurs
            // et si les données des Zones ont été validées
            if (this.diagnosticPolluant.zones.validated && this.form.valid) {
                etat = 'VALID';
            }
        } else {
            //L'écran est valide si le formulaire n'a pas d'erreurs
            if (this.form && this.form.valid) {
                etat = 'VALID';
            }
        }

        this.etatProgressionService.updateDiagnostic(code, etat, this.currentDiagnostic, true);

        //Si le formulaire était désactivé avant, on le désactive à nouveau
        if (!isFormEnabled) {
            this.form.disable({
                emitEvent: false,
            });
        }
    }

    duplicateZone() {
        this.isDuplication = true;
        //Création du formulaire de la zone et ajout au formulaire global
        const formZone = cloneDeep(this.formZones.controls[this.indexZone] as FormGroup);
        this.form = this.duplicateFormGroupZone(formZone);
        this.form.get('objectifMesurage').setValue([]);
        this.formZones.push(this.form);
        this.notificationService.notify('Merci de choisir un nouveau nom.');
    }
}
