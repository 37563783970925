<div class="sequence-list">
    <div class="sequence-action-container">
        <div>Liste des Séquences</div>
        <button mat-button (click)="openNewModal()" class="button-center">
            <mat-icon>add</mat-icon>
        </button>
    </div>

    <app-sequence-card
        *ngFor="let sequence of sequenceList.controls; let index = index"
        class="sequence-card"
        [sequence]="sequence"
        [number]="index"
    >
        <div class="action">
            <div>
                <button mat-icon-button aria-label="copier" (click)="edit(index)">
                    <mat-icon>create</mat-icon>
                    {{ color }}
                </button>
                <button mat-icon-button aria-label="supprimer" (click)="remove(index)">
                    <mat-icon>delete</mat-icon>
                    {{ color }}
                </button>
            </div>
        </div>
    </app-sequence-card>
</div>
