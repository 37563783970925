'use strict';
//***********************************************************************************
//***********************************************************************************
import { cn_pipe_type } from '../model/cn_pipe_type';
import { cn_pipe } from '../model/cn_pipe';
import { cn_svg_tool_creation } from './cn_svg_tool_creation';
import { cn_svg_map } from './cn_svg_map';
import { cn_pipe_handler } from './cn_pipe_handler';
import { cn_polygon_handler } from './cn_polygon_handler';
import { cnx_clone } from '../utils/cn_utilities';
import { cn_mouse_event } from './cn_mouse_event';
import { cn_element } from '../model/cn_element';
import { cn_edition_handler } from './cn_edition_handler';
import { HELPER } from '../utils/cn_wordings';

/**
 * @class cn_svg_tool_pipe_creation
 * A tool to create pipe and columns
 */
export class cn_svg_tool_pipe_creation extends cn_svg_tool_creation {
    //*****************************************************************
    /**
     * Constructor
     * @param {cn_svg_map} map
     */
    constructor(map) {
        super(map);

        this._vertical_pipe = false;
        this._edition_handler = null;
        this._pipe_type = null;
        this._creation_handler = null;
        this._initiate_creation();
        this.element_filter = element => {
            return element.constructor == cn_pipe;
        };
    }

    //*****************************************************************
    //*** Parameters
    //*****************************************************************
    /**
     * @returns {boolean} returns the type of pipe in creation
     */
    get_vertical_pipe() {
        return this._vertical_pipe;
    }

    /**
     * Sets the v erticality of pipe to create
     * @param {boolean} vp
     */
    set_vertical_pipe(vp) {
        if (this._vertical_pipe == vp) return;
        this._vertical_pipe = vp;
        this._initiate_creation();
    }

    /**
     * @returns {cn_pipe_type} returns current pipe type
     */
    get_current_pipe_type() {
        return this._pipe_type;
    }

    /**
     * Sets thecurrent pipe type
     * @param {cn_pipe_type} pipe_type
     */
    set_current_pipe_type(pipe_type) {
        this._pipe_type = pipe_type;
        this._initiate_creation();
        this._map.refresh_main();
    }

    /**
     * Checks current pipe type
     */
    check_current_element_type() {
        var pipe_types = this._scene.building.get_pipe_types();
        if (pipe_types.indexOf(this._pipe_type) >= 0) return;
        this._pipe_type = (pipe_types.length > 0) ? pipe_types[0] : null;
        this._initiate_creation();
    }

    /**
     * Returns all pipe types inside the building
     * @returns {Array<cn_pipe_type>}
     */
    get_element_types() {
        return this._scene.building.get_pipe_types();
    }

    /**
     * Selects all elements with given pipe type
     * @param {cn_pipe_type} pipe_type
     */
    select_elements_by_type(pipe_type) {
        this._initiate_edition(this._scene.pipes.filter(p => p.element_type == pipe_type));
    }

    //*****************************************************************
    //*** Must return 'true' if wa are in modal creation.
    //*****************************************************************
    is_creating() {
        return this._creation_handler.is_creating();
    }

    open_tool() {
        super.open_tool();
        const element_types = this.get_element_types();
        if (element_types.indexOf(this._pipe_type) < 0)
            this._pipe_type = element_types[0];
        this._initiate_creation();
        this.push_instruction_input(HELPER.pipe.creation);
    }

    //***********************************************************************************
    //**** Refresh
    //***********************************************************************************
    draw(camera) {
        this._creation_handler.visible = (this._focus_handler == this._creation_handler);
        return super.draw(camera);
    }

    //***********************************************************************************
    //**** Start creation of pipes
    //***********************************************************************************
    _initiate_creation() {
        if (this._creation_handler) {
            const index = this._handlers.indexOf(this._creation_handler);
            if (index >= 0) this._handlers.splice(index, 1);
        }
        var obj = this;
        var scene = obj._scene;
        this._creation_handler = cn_polygon_handler.create_open_polygon(this, this._vertical_pipe ? 1 : 0, true, true);
        this._creation_handler.display_measures = false;

        this._handlers.unshift(this._creation_handler);
        this._creation_handler.snap_elements = scene.spaces.concat(scene.pipes);

        //*** Event on creation start */
        this._creation_handler.on('start_creation', () => {
            obj._terminate_edition();
        });

        this._creation_handler.on('end_creation', () => {
            const new_pipes = [];
            this.push_transaction('Création de conduit');
            this.push_item_set(scene, 'pipes');
            if (this._vertical_pipe) {
                const new_pipe = new cn_pipe(obj._pipe_type);
                new_pipe.vertices[0] = cnx_clone(this._creation_handler.vertices[0]);
                if (this._creation_handler.vertices.length > 1)
                    new_pipe.vertices[1] = cnx_clone(this._creation_handler.vertices[1]);
                else
                    new_pipe.vertices[1] = cnx_clone(this._creation_handler.vertices[0]);
                new_pipe.vertices[0][2] = 0;
                new_pipe.vertices[1][2] = 1;
                new_pipes.push(new_pipe)
            } else {
                this._creation_handler.vertices.forEach((vertex, index) => {
                    if (index < this._creation_handler.vertices.length - 1) {
                        const new_pipe = new cn_pipe(obj._pipe_type);
                        new_pipe.vertices[0] = cnx_clone(vertex);
                        new_pipe.vertices[1] = cnx_clone(this._creation_handler.vertices[index + 1]);
                        new_pipe.vertices[0][2] = 0.1;
                        new_pipe.vertices[1][2] = 0.1;
                        new_pipes.push(new_pipe)
                    }
                });
            }
            scene.pipes.push(...new_pipes);
            this.call('creation', new_pipes);
            this._initiate_creation();
            this._initiate_edition(new_pipes);
        });

    }

    //***********************************************************************************
    //**** Edition elements
    //***********************************************************************************
    /**
     * TODO : derivate in order to allow edition of other element in the process of creation
     * @param {cn_mouse_event} mouse_event
     * @returns {cn_element}
     */
    _find_other_element(mouse_event) {
        return this._scene.find_pipe(mouse_event.mouse_world, mouse_event.camera.snap_world_distance);
    }

    /**
     * TODO : derivate in order to provide an edition handler
     * @param {Array<cn_pipe>} elements
     * @returns {cn_edition_handler}
     */
    _build_edition_handler(elements) {
        return new cn_pipe_handler(elements, this._map, true);
    }

    /**
     * TODO : derivate in order to find siblings of an element
     * @param {cn_pipe} element
     * @returns {Array<cn_element>}
     */
    _get_siblings(element) {
        const ot = element.element_type;
        return this._scene.pipes.filter(s => s.element_type == ot);
    }

}

