import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import * as moment from 'moment';

import { FiltrePrelevementMetop, PolluantPrelevement, PrelevementMetop } from 'src/app/modules/diagnostics/polluant/model/polluant-prelevement.model';
import { WidgetSelectModeEnum } from 'src/app/modules/shared/widget-select/widget-select.component';
import { MatDialog } from '@angular/material/dialog';
import { FiltreModalComponent } from '../prelevement-form-avant/filtres-item-list/filtres-modal/filtre-modal.component';

@Component({
    selector: 'app-prelevement-form-apres',
    templateUrl: './prelevement-form-apres.component.html',
    styleUrls: ['./prelevement-form-apres.component.scss'],
})
export class PrelevementFormApresComponent implements OnInit {
    formApres: FormGroup;

    @Input('diagnostic')
    diagnostic: Diagnostic;
    contenuDiagnostic: PolluantPrelevement;

    @Input('selectedBesoinId')
    selectedBesoinId: string;
    selectedPrelevement: PrelevementMetop;

    @Input() // Utilisé pour passer les champs en read-only
    disabled: Boolean;

    @Output('formChange')
    formChange: EventEmitter<any> = new EventEmitter<any>();

    widgetSelectModeEnum: typeof WidgetSelectModeEnum = WidgetSelectModeEnum;

    constructor(private formBuilder: FormBuilder, private dialog: MatDialog) {}

    ngOnInit(): void {
        this.restoreData();
        this.createForm();
    }

    restoreData() {
        this.contenuDiagnostic = this.diagnostic.contenuDiagnostic as PolluantPrelevement;

        //Récupération du Prélèvement par l'ID de son Besoin
        this.selectedPrelevement = this.contenuDiagnostic.prelevement.data.besoinsList.find(
            (b) => b.besoinPrelevement.id == this.selectedBesoinId
        ) as PrelevementMetop;

        //Récupération des filtres de l'étape Pendant
        if (this.selectedPrelevement.listeFiltresApres == undefined || this.selectedPrelevement.listeFiltresApres.length == 0) {
            this.selectedPrelevement.listeFiltresApres = this.selectedPrelevement.listeFiltresPendant;
        }
    }

    createForm() {
        this.formApres = this.formBuilder.group({
            dateDebutPrelevement: [moment(this.selectedPrelevement.informationsPrelevementApres.dateTimeDebutPrelevement), Validators.required],
            dateFinPrelevement: [moment(this.selectedPrelevement.informationsPrelevementApres.dateTimeFinPrelevement), Validators.required],
            pressionMoyenne: [
                this.selectedPrelevement.informationsPrelevementApres.pressionMoyenne,
                {
                    updateOn: 'blur',
                },
            ],
            temperatureMoyenne: [
                this.selectedPrelevement.informationsPrelevementApres.temperatureMoyenne,
                {
                    updateOn: 'blur',
                },
            ],
            tauxHumiditeInterieur: [
                this.selectedPrelevement.informationsPrelevementApres.tauxHumiditeInterieur,
                {
                    updateOn: 'blur',
                },
            ],
            selon1600746033: [this.selectedPrelevement.informationsPrelevementApres.selon1600746033],
            selonNFX43269: [this.selectedPrelevement.informationsPrelevementApres.selonNFX43269],

            //Validation stratégie
            justificatifChangement: [
                this.selectedPrelevement.validationStrategie.justificatifChangement,
                {
                    updateOn: 'blur',
                },
            ],
            clientPrevenuPar: [this.selectedPrelevement.validationStrategie.clientPrevenuPar],

            //Information Meteo
            informationMeteo: this.formBuilder.group({
                vitesseVent: [this.selectedPrelevement.informationsMeteoApres.vitesseVent],
                directionVent: [this.selectedPrelevement.informationsMeteoApres.directionVent],
                temperature: [this.selectedPrelevement.informationsMeteoApres.temperature],
                hygrometrie: [this.selectedPrelevement.informationsMeteoApres.hygrometrie],
                pressionAtmospherique: [this.selectedPrelevement.informationsMeteoApres.pressionAtmospherique],
                conditionMeteo: [this.selectedPrelevement.informationsMeteoApres.conditionMeteo],
            }),

            //Filtres
            listeFiltres: this.formBuilder.array([]),

            //Conformité pose
            prelevementReglementaire: [this.selectedPrelevement.conformite.prelevementReglementaire, Validators.required],
            justificatifNonReglementaire: [this.selectedPrelevement.conformite.justificatifNonReglementaire, Validators.required],
            accreditationCofrac: [this.selectedPrelevement.conformite.accreditationCofrac, Validators.required],
        });

        this.selectedPrelevement.listeFiltresApres.forEach((filtre: FiltrePrelevementMetop) => {
            (this.formApres.get('listeFiltres') as FormArray).push(this.createFilterForm(filtre));
        });

        this.formApres.valueChanges.subscribe((newValue) => {
            const formValues = this.formApres.getRawValue();

            //Patch des valeurs du diagnostic avec les valeurs du formulaire
            this.selectedPrelevement.informationsPrelevementApres.dureeTotalePrelevement = formValues.dureeTotalePrelevement;
            this.selectedPrelevement.informationsPrelevementApres.pressionMoyenne = formValues.pressionMoyenne;
            this.selectedPrelevement.informationsPrelevementApres.temperatureMoyenne = formValues.temperatureMoyenne;
            this.selectedPrelevement.informationsPrelevementApres.tauxHumiditeInterieur = formValues.tauxHumiditeInterieur;
            this.selectedPrelevement.informationsPrelevementApres.selon1600746033 = formValues.selon1600746033;
            this.selectedPrelevement.informationsPrelevementApres.selonNFX43269 = formValues.selonNFX43269;

            //Validation stratégie
            this.selectedPrelevement.validationStrategie.justificatifChangement = formValues.justificatifChangement;
            this.selectedPrelevement.validationStrategie.clientPrevenuPar = formValues.clientPrevenuPar;

            //Météo
            this.selectedPrelevement.informationsMeteoApres.vitesseVent = formValues.informationMeteo.vitesseVent;
            this.selectedPrelevement.informationsMeteoApres.directionVent = formValues.informationMeteo.directionVent;
            this.selectedPrelevement.informationsMeteoApres.temperature = formValues.informationMeteo.temperature;
            this.selectedPrelevement.informationsMeteoApres.hygrometrie = formValues.informationMeteo.hygrometrie;
            this.selectedPrelevement.informationsMeteoApres.conditionMeteo = formValues.informationMeteo.conditionMeteo;
            this.selectedPrelevement.informationsMeteoApres.pressionAtmospherique = formValues.informationMeteo.pressionAtmospherique;

            //Filtres
            this.selectedPrelevement.listeFiltresApres = formValues.listeFiltres;

            //Conformité pose
            this.selectedPrelevement.conformite.prelevementReglementaire = formValues.prelevementReglementaire;
            this.selectedPrelevement.conformite.justificatifNonReglementaire = formValues.justificatifNonReglementaire;
            this.selectedPrelevement.conformite.accreditationCofrac = formValues.accreditationCofrac;

            //Envoi des changements au composant parent pour sauvegarde
            this.formChange.emit(this.contenuDiagnostic);
        });
    }

    createFilterForm(filtre: FiltrePrelevementMetop): FormGroup {
        const filtreForm = this.formBuilder.group({
            numeroFiltre: [filtre.numeroFiltre, Validators.required],
            numeroPompe: [filtre.numeroPompe, Validators.required],
            debitInitial: [filtre.debitInitial, Validators.required],
            testEtancheite: [filtre.testEtancheite, Validators.required],
        });

        return filtreForm;
    }

    /**
     * @description Calcul la durée en heures entre le début et la fin du prélèvement
     */
    calculDuree() {
        if (this.formApres == undefined) {
            return '';
        }

        if (this.formApres.get('dateDebutPrelevement').value != '' && this.formApres.get('dateFinPrelevement').value != '') {
            return (
                Math.round(
                    moment
                        .duration(
                            moment(this.formApres.get('dateFinPrelevement').value).diff(moment(this.formApres.get('dateDebutPrelevement').value))
                        )
                        .asHours()
                ) + 'h'
            );
        }
        return '';
    }

    /**
     *  @description Ouvre la modal d'édition d'un filtre avec le paramètre '-1'
     *               pour indiquer une création
     */
    ajouterFiltre() {
        this.openModal(-1);
    }

    /**
     * @description Ouvre la modal d'édition d'un processus avec l'index du processus à modifier
     * @param index Index du processus à modifier
     */
    modifierFiltre(index: number) {
        this.openModal(index);
    }

    /**
     * Méthode appellée pour supprimer un processus de MPCA à l'index donné
     * @param index Index du processus à supprimer
     */
    supprimerFiltre(index: number) {
        (this.formApres.get('listeFiltres') as FormArray).removeAt(index);
    }

    /**
     * Méthode appellée pour dupliquer un processus de MPCA
     * @param index Index du processus à dupliquer
     */
    dupliquerFiltre(index: number) {
        const processusToInsert = this.createFilterForm((this.formApres.get('listeFiltres') as FormArray).value[index]);
        (this.formApres.get('listeFiltres') as FormArray).insert(1, processusToInsert);
    }

    /**
     *
     * @param index Index du processus avec lequel initialiser le formulaire
     * Dans le cas d'une création, l'index est à '-1' pour initialiser le formulaire à vide
     */
    openModal(index) {
        let filtre: FiltrePrelevementMetop = new FiltrePrelevementMetop();
        if (index != -1) {
            filtre = (this.formApres.get('listeFiltres') as FormArray).value[index];
        }

        const dialogRef = this.dialog.open(FiltreModalComponent, {
            width: '250px',
            data: {
                filtre,
                index,
            },
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result.index == '-1') {
                //Dans le cas d'une création
                //On ajoute le formulaire du filtre créé à la liste des filtres
                (this.formApres.get('listeFiltres') as FormArray).push(this.createFilterForm(result.form));
            } else {
                //Dans le cas d'une mise à jour
                //On patch les valeurs du filtre avec les valeurs retournées
                (this.formApres.get('listeFiltres') as FormArray).controls[result.index].patchValue({
                    numeroFiltre: result.form.numeroFiltre,
                    numeroPompe: result.form.numeroPompe,
                    debitInitial: result.form.debitInitial,
                    testEtancheite: result.form.testEtancheite,
                });
            }
        });
    }

    /**
     * @description Méthode appellée pour imprimer l'étiquette liée au prélèvement
     */
    public impressionEtiquette() {
        //A implémenter
    }
}
