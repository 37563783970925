import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { CeeConfig, CeeModel, Chapitre, PointControle, TypeCee } from '../model/cee.model';
import { CeeApiService } from './cee-api.service';
import {
    CODE_NON_VISIBLE,
    CODE_VIDE,
    DIFFERENT,
    EGAL,
    INFERIEUR,
    INFERIEUR_EGAL,
    NOM_DE_LA_SOLUTION,
    SUPPERIEUR,
    SUPPERIEUR_EGAL,
} from '../shared/cee.constants';
import { NavigationBarsService } from '../../../../services/navigation-bars.service';
import { TypePrestationEnum } from 'src/app/model/type-prestation.model';
import { EtapeDiagnosticGenerique } from '../../../../model/diagnostic-etape.model';

@Injectable({
    providedIn: 'root',
})
export class CeeService {
    ceeConfig$ = new BehaviorSubject<CeeConfig>(undefined);
    idsNomSolution: string[] = [];
    typesCee: TypeCee[];
    chapitres: Chapitre[];
    constructor(private ceeApiService: CeeApiService, private navigationBarsService: NavigationBarsService) {}

    initConfig() {
        if (!this.ceeConfig$.getValue()) {
            this.ceeApiService
                .getConfig()
                .pipe(map((configs) => configs[0]))
                .subscribe((config: CeeConfig) => {
                    console.info(config);
                    this.ceeConfig$.next(config);
                    this.typesCee = config.typesCee;
                    this.chapitres = config.chapitres;
                    this.idsNomSolution = [
                        '160',
                        '161',
                        ...config.pointsControles.filter((ptc) => ptc.description == NOM_DE_LA_SOLUTION).map((ptc) => ptc.idInterne),
                    ];
                    this.initRoutes();
                });
        }
    }

    private initRoutes() {
        this.navigationBarsService.navigationBarConfiguration$.subscribe((conf) => {
            if (conf) {
                if (
                    [
                        TypePrestationEnum.BAREN101,
                        TypePrestationEnum.BAREN102,
                        TypePrestationEnum.BAREN103,
                        TypePrestationEnum.BARTH160,
                        TypePrestationEnum.BARTH161,
                    ].includes(conf.typePrestation)
                ) {
                    if (conf.refObject && !conf.refObject['idsNomSolution']) {
                        conf.refObject['idsNomSolution'] = this.idsNomSolution;
                        conf.refObject['typesCee'] = this.typesCee;
                        conf.refObject['chapitres'] = this.chapitres;
                        this.navigationBarsService.configureNavBar(conf);
                    }
                }
            }
        });
    }

    /**
     * TRUE si tous les checkpoints sont valid
     * FALSE si au moint un checkpoint n'est pas valid
     * @param checkpoints
     */
    public isCheckPointsValid = (checkpoints: EtapeDiagnosticGenerique[], ceeModel: CeeModel): boolean => {
        if (!checkpoints?.length) {
            return false;
        }
        for (const ptc of checkpoints) {
            if (!this.isCheckPointValid(ptc, ceeModel)) {
                return false;
            }
        }
        return true;
    };

    /**
     * TRUE si le checkpoint est VALID, FALSE sinon
     * @param ptc
     */
    public isCheckPointValid(ptc: EtapeDiagnosticGenerique, ceeModel: CeeModel): boolean {
        const pointcontrole: PointControle = this.ceeConfig$.value.pointsControles.find((p) => p.idInterne === ptc.id);
        if (!pointcontrole) {
            return true;
        }
        if (pointcontrole.required && !ptc.checked && !ptc.hide && !ptc.disabled) {
            if (
                !ptc.valeur ||
                !ptc.valeur?.toString().length ||
                ptc.valeur === CODE_VIDE ||
                (ptc.valeur == CODE_NON_VISIBLE && !ptc.commentairesId.length) ||
                (this.getAnomalieFromCheckpoint(ptc, ceeModel) && !ptc.commentairesId.length) ||
                (this.needJustification(ptc) && !ptc.commentairesId.length)
            ) {
                return false;
            }
        }
        return true;
    }

    /**
     * Recherche de l'anomalie en fonction de la valeur saise
     * Si anomalie return True
     * @param etapeGenerique
     * @private
     */
    public getAnomalieFromCheckpoint(etapeGenerique: EtapeDiagnosticGenerique, ceeModel: CeeModel) {
        const checkpoint: PointControle = this.ceeConfig$.value.pointsControles.find((p) => p.idInterne === etapeGenerique.id);
        if (etapeGenerique?.isForceAnomalie) {
            return true;
        }

        if ('PC430' === checkpoint?.idInterne) {
            let sommeNbPointSingulierDeclares = 0;
            const data: any[] = Object.values(ceeModel.caracteristiques.data);
            for (const carac of data) {
                if (
                    carac.id === 'PC400' ||
                    carac.id === 'PC401' ||
                    carac.id === 'PC402' ||
                    carac.id === 'PC403' ||
                    carac.id === 'PC404' ||
                    carac.id === 'PC405' ||
                    carac.id === 'PC406' ||
                    carac.id === 'PC407'
                ) {
                    sommeNbPointSingulierDeclares += +carac.valeur;
                }
            }
            if (sommeNbPointSingulierDeclares != +etapeGenerique.valeur) return true;
            else return false;
        }
        if (checkpoint?.lstChoixReponse) {
            for (const rep of checkpoint.lstChoixReponse) {
                if (rep.needJustification && rep.code === etapeGenerique.valeur) {
                    return rep.phraseJustification;
                }
            }
        }
        if ('PC421' === checkpoint?.idInterne) {
            if (!checkpoint.lstChoixReponse.map((v) => v.description).find((v) => v === etapeGenerique.valeur)) {
                return { description: "la marque de la housse n'existe pas parmis la liste" };
            }
        } else if ('PC422' === checkpoint?.idInterne) {
            if (!checkpoint.lstChoixReponse.map((v) => v.description).find((v) => v === etapeGenerique.valeur)) {
                return { description: "la référence de la housse n'existe pas parmis la liste" };
            }
        }

        let anomalie = checkpoint.lstAnomalie.find((an) => an.codeReponse === etapeGenerique?.valeur);
        if (!anomalie) {
            anomalie = checkpoint.lstAnomalie.find((an) => {
                let valeurReference = an.valeurReference;
                if (an.idPointDeControleReference && ceeModel) {
                    valeurReference = ceeModel.caracteristiques.data[an.idPointDeControleReference].valeur;
                }
                switch (an.type) {
                    case INFERIEUR:
                        return +etapeGenerique.valeur < +valeurReference;
                    case SUPPERIEUR:
                        return +etapeGenerique.valeur > +valeurReference;
                    case EGAL:
                        return +etapeGenerique.valeur === +valeurReference;
                    case INFERIEUR_EGAL:
                        return +etapeGenerique.valeur <= +valeurReference;
                    case SUPPERIEUR_EGAL:
                        return +etapeGenerique.valeur >= +valeurReference;
                    case DIFFERENT:
                        return +etapeGenerique.valeur != +valeurReference;
                }
                return false;
            });
        }
        return anomalie;
    }

    public needJustification(value: EtapeDiagnosticGenerique): boolean {
        const checkpoint: PointControle = this.ceeConfig$.value.pointsControles.find((p) => p.idInterne === value.id);
        if (checkpoint?.lstChoixReponse) {
            for (const rep of checkpoint.lstChoixReponse) {
                if (rep.needJustification && rep.code === value?.valeur) {
                    return !!rep.phraseJustification;
                }
            }
        }
        if (value?.valeur) {
            if (['PC421', 'PC422'].includes(checkpoint?.idInterne)) {
                if (!checkpoint.lstChoixReponse.map((v) => v.description).find((v) => v === value.valeur)) {
                    return true;
                }
            }
        }
        return false;
    }

    mapToCeeModel(contenuDiagnostic: any): CeeModel {
        if (contenuDiagnostic instanceof CeeModel) {
            return contenuDiagnostic as CeeModel;
        }
        return new CeeModel(contenuDiagnostic);
    }

    calculAnomalieLongueurReseau(longuerReseauxIsolesHorsVolumesChauffes: number, longuerReseauxFacture: number): boolean {
        const ecart = (longuerReseauxFacture - longuerReseauxIsolesHorsVolumesChauffes) / longuerReseauxIsolesHorsVolumesChauffes;
        return ecart * 100 > 10; // Anomalie si l'écart est strictement supérieur à 10%
    }
}
