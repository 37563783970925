import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Environment } from '../environment';
import { User } from './user.model';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    public resourceUrl = Environment.apiUrl + '/account';

    constructor(private http: HttpClient) {}

    updateAccount(account: User): Observable<User> {
        return this.http.post<User>(this.resourceUrl, account);
    }

    updatePassword(newPassword: string, currentPassword: string): Observable<any> {
        return this.http.post(`${this.resourceUrl}/change-password`, { currentPassword, newPassword });
    }

    resetPasswordInit(email: string): Observable<any> {
        return this.http.post(`${this.resourceUrl}/reset-password/init`, email);
    }

    resetPasswordFinish(key: string, newPassword: string): Observable<any> {
        return this.http.post(`${this.resourceUrl}/reset-password/finish`, { key, newPassword });
    }

    getUserForInscriptionFinish(key: string): Observable<User> {
        return this.http.get(`${this.resourceUrl}/inscription/finish?key=${key}`);
    }

    inscriptionFinish(user: User, key: string, newPassword: string) {
        return this.http.post(`${this.resourceUrl}/inscription/finish`, { ...user, key, newPassword });
    }
}
