<app-header-editor-panel title="Éditer une delimitation" (closed)="onCloseEditPanel()"></app-header-editor-panel>

<div class="pl-5 pr-5 pt-4 container-fluid" *ngIf="perimetreFormGroup" [formGroup]="perimetreFormGroup">
    <label class="primary">Type de délimitation <span class="required"></span></label>
    <!-- <mat-label class="required">Contexte</mat-label> -->
    <app-widget-select
        [mode]="widgetSelectModeEnum.BADGE"
        formControlName="typeDelimitation"
        [disabled]="readonlyMode"
        (ngModelChange)="onChangeTypeDelimitation(perimetreFormGroup)"
        class="d-block mb-4"
    >
        <!-- <option
            [value]="item.label"
            [attr.data-color]="item.backgroundColor"
            datatype="html"
            *ngFor="let item of polluantConfig.contenuListeDeroulante.typesDelimitation.items"
        >
            {{ item.label }}
        </option> -->
        <option value="Concerné" datatype="html" [attr.data-color]="'ORANGE'">Concerné</option>
        <option value="Non concerné" datatype="html" [attr.data-color]="'GREEN'">Non concerné</option>
        <option value="Autre" datatype="html" [attr.data-color]="'BLUE'">Autre</option>
    </app-widget-select>

    <label class="primary">Nom de la délimitation <span class="required"></span></label>
    <mat-form-field class="w-100 form-custom" appearance="outline">
        <input
            matInput
            type="text"
            formControlName="nomPerimetre"
            (change)="onChangeNom(perimetreFormGroup)"
            [readonly]="readonlyMode"
        />
        <mat-error>
            <app-form-control-error-message
                [control]="perimetreFormGroup.get('nomPerimetre')"
            ></app-form-control-error-message>
        </mat-error>
    </mat-form-field>

    <label class="primary">Précision sur la partie délimitée</label>
    <mat-form-field class="w-100 form-custom" appearance="outline">
        <textarea
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="10"
            cdkAutosizeMaxRows="10"
            formControlName="description"
            [readonly]="readonlyMode"
        ></textarea>
        <mat-error>
            <app-form-control-error-message
                [control]="perimetreFormGroup.get('description')"
            ></app-form-control-error-message>
        </mat-error>
    </mat-form-field>

    <ng-container *ngIf="isHAP">
        <div class="row no-gutters">
            <div class="col-6">
                <label class="primary">Type d'ouvrage<span class="required"></span></label>
                <div class="d-flex align-items-start chips">
                    <mat-form-field class="w-75">
                        <mat-chip-list #ouvChipList formControlName="typeOuvrage">
                            <mat-chip *ngIf="perimetreFormGroup?.get('typeOuvrage')?.value">
                                {{ perimetreFormGroup?.get('typeOuvrage')?.value.name }}
                            </mat-chip>
                        </mat-chip-list>
                    </mat-form-field>
                    <button
                        class="mt-2"
                        type="button"
                        mat-raised-button
                        color="accent"
                        (click)="onClickChooseTypeOuvrage()"
                        [disabled]="readonlyMode"
                    >
                        Choisir
                    </button>
                </div>
            </div>
        </div>
        <mat-divider class="mt-3 mb-4"></mat-divider>
    </ng-container>
    <div class="row">
        <div class="col">
            <h2>Légende</h2>
        </div>
    </div>

    <div class="row">
        <div class="col-6">
            <app-legende-perimetre
                [formGroup]="perimetreFormGroup"
                (changeColor)="onChangeColor(perimetreFormGroup)"
                [readonly]="readonlyMode"
            ></app-legende-perimetre>
        </div>
        <div class="col col-6">
            <label class="primary">Épaisseur du trait</label>
            <input type="number" (change)="onChangeStrokeWidth(perimetreFormGroup)" formControlName="epaisseurTrait" />
        </div>
    </div>
</div>
