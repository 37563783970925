import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-vue-switch-toggle',
    templateUrl: './vue-switch-toggle.component.html',
    styleUrls: ['./vue-switch-toggle.component.scss'],
})
export class VueSwitchToggleComponent implements OnInit {
    @Input()
    value = 'list';

    constructor() {}

    ngOnInit(): void {}
}
