import { Injectable } from '@angular/core';
import { TypePrestation } from '../../../model/type-prestation.model';
import { all, NavigationLink, NavigationUtils } from '../../../utils/navigation.utils';
import { NavigationBarsConfig } from '../../../services/navigation-bars.service';
import { EtatProgression } from '../../../model/etat-progression.model';

@Injectable()
export class NavigationBarsHelperService {
    constructor() {}

    public handleRoute(url: string, config: NavigationBarsConfig, etatsProgressions: EtatProgression[]) {
        const activeBasePath = this.getActiveBasePath(config, url);
        const links1 = this.getLinks(activeBasePath, config, url);
        const activeLink1 = links1.find((l) => l.inActiveTrail);

        const links2 = activeLink1 ? activeLink1.children : [];
        let activeLink2 = links2.find((l) => l.inActiveTrail);
        if (activeLink2 && activeLink2.children && activeLink2.children.length) {
            activeLink2 = activeLink2.children.find((l) => l.inActiveTrail);
        }

        const customActions = [activeLink1, activeLink2]
            .filter((l) => !!l)
            .map((l) => l.actions)
            .filter((l) => !!l)
            .flat();
        const helpToDisplay = this.getHelpToDisplay(config.typePrestation, activeLink2, activeLink1);

        // init all etat
        links1
            .map(all)
            .flat()
            .forEach((link) => {
                // TODO get etats progressions
                const etat = this.findEtatProgression(link.code, etatsProgressions);
                if (etat) {
                    link.etat = etat.etat;
                }
            });

        // update etat on parent nodes
        links1.forEach((link1) => {
            // si ce lien a au moins 1 enfant, on met à jour son état
            if (link1.children && link1.children.length) {
                const etat = this.getEtatGlobalForLinkList(link1.children);
                if (etat) {
                    link1.etat = etat;
                }
            }
        });
        const activeLink = activeLink2 || activeLink1;
        const previousLink = activeLink ? activeLink.previous : undefined;
        const nextLink = activeLink ? activeLink.next : undefined;
        return {
            links1,
            links2,
            customActions,
            helpToDisplay,
            previousLink,
            nextLink,
            activeLink,
            activeLink1,
            activeLink2,
        };
    }

    private getLinks(activeBasePath: string, config: NavigationBarsConfig, url: string) {
        if (activeBasePath) {
            return NavigationUtils.routesToLinks(config.activatedRoute.routeConfig.children, config.refObject, {
                activeBasePath,
                url,
            });
        }
        return [];
    }

    private getActiveBasePath(config: NavigationBarsConfig, url: string) {
        if (config.activatedRoute) {
            const activeUrlPart = config.activatedRoute.snapshot.url.map((u) => u.path).join('');
            const indexOf = url.lastIndexOf(activeUrlPart);
            if (indexOf > -1) {
                return url.substring(0, indexOf + activeUrlPart.length);
            }
        }
        return '';
    }

    private getHelpToDisplay(typePrestation: TypePrestation, link2, link1) {
        let helpToDisplay;
        if (link1) {
            let title = link2 ? link2.label : link1.label;
            let sectionId = link2 ? link1.path + '-' + link2.path : link1.path;
            helpToDisplay = { typePrestation, sectionId, title };
        }
        return helpToDisplay;
    }

    private findEtatProgression(code: string, etatProgessions: EtatProgression[]) {
        if (code) {
            return etatProgessions
                .filter((s) => !!s)
                .flat()
                .find((etatP) => etatP.code === code);
        } else {
            return undefined;
        }
    }

    /**
     * Récupère l'état de l'onglet correspondant au lien avec son code dans les données sauvegardées au niveau
     * de l'intervention ou du diagnostique.
     * Retourne VALID si tous les liens sont VALID ou VOID.
     * Retourne INVALID si au moins un des liens est INVALID.
     * Retourne VOID si les liens sont tous VOID.
     */
    public getEtatGlobalForLinkList(links: NavigationLink[]) {
        if (links.some((l) => l.etat === 'INVALID')) {
            return 'INVALID';
        } else if (!links.some((l) => l.etat !== 'VOID')) {
            return 'VOID';
        } else if (!links.some((l) => ['VOID', 'VALID'].indexOf(l.etat) === -1)) {
            return 'VALID';
        }
        return undefined;
    }
}
