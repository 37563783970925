// @ts-check
'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** roof opening type
//***********************************************************************************
//***********************************************************************************

import { cn_element_type } from './cn_element_type';
import { cn_configuration, cn_configuration_choice, cn_configuration_line, cn_configuration_param, cn_configuration_param_group, cn_configuration_tab } from './cn_configuration';
import { fh_add, fh_extruded_polygon, fh_mul, fh_polygon, fh_solid } from '@acenv/fh-3d-viewer';
import { CLOSING_LIST, code_to_label, FRAME_LIST, OPENING_LIST, GLAZING_GAZ_LIST } from './cn_opening_type';
import { cn_element_type_visitor, logger } from '..';

export const ROOF_GLAZING_LIST = [
    { label: 'Aucun', code: 'none' },
    { label: 'Simple', code: 'single' },
    { label: 'Double', code: 'double' },
    { label: 'Polycarbonate', code: 'polycarbonate' }
];

export const ROOF_OPENING_CATEGORY_LIST = [
    { label: 'Fenêtre de toit', code: 'window' },
    { label: 'Lanterneau', code: 'skylight' },
    { label: 'Exutoire', code: 'vent' }
];

var CLIP_ID = 0;

export class cn_roof_opening_type extends cn_element_type {
    //***********************************************************************************
    /**
     * constructor
     */
    constructor() {
        super();
        this.class_name = 'cn_roof_opening_type';
        this.name = '';
        this.category = 'window';
        this.width = 0.8;
        this.length = 0.8;
        this.orientation = 0;

        //*** specific for windows */
        this.frame = 'alu';
        this.frame_quality = false;
        this.glazing = 'double';
        this.glazing_gaz = 'air_16';
        this.opening = 'vertical';
        this.closing = 'none';

        //*** specific for skylights */
        this.slopes = 2;
        this.slope_angle = 30;
        this.spacing = 0.5;

        //*** specific for vents */
        this.height = 0.3;

        //*** Physics: */
        this.user_physics = false;
        this.Uw = 0;
        this.Sw = 0;
        this.Tl = 0;
    }

    //***********************************************************************************
    /**
     * Retrns default roof opening types
     * @returns {cn_roof_opening_type[]}
     */
    static default_types() {
        var lst = [];

        var rot = new cn_roof_opening_type();
        rot.category = 'vent';
        rot.glazing = 'polycarbonate';
        lst.push(rot);

        var rot = new cn_roof_opening_type();
        rot.category = 'skylight';
        rot.width = 1.20;
        rot.length = 0.60;
        rot.spacing = 0.3;
        lst.push(rot);

        var rot = new cn_roof_opening_type();
        lst.push(rot);

        return lst;
    }

    //***********************************************************************************
    //**** Clone
    //***********************************************************************************
    clone() {
        var c = new cn_roof_opening_type();
        c.name = this.name;
        c.category = this.category;
        c.width = this.width;
        c.length = this.length;
        c.frame = this.frame;
        c.frame_quality = this.frame_quality;
        c.glazing = this.glazing;
        c.glazing_gaz = this.glazing_gaz;
        c.opening = this.opening;
        c.closing = this.closing;
        c.slopes = this.slopes;
        c.slope_angle = this.slope_angle;
        c.spacing = this.spacing;
        c.height = this.height;

        c.user_physics = this.user_physics;
        c.Uw = this.Uw;
        c.Sw = this.Sw;
        c.Tl = this.Tl;
        return c;
    }

    //***********************************************************************************
    get_generic_label() {
        return 'Type d\'ouvrant de toiture';
    }

    //***********************************************************************************
    //**** keys
    //***********************************************************************************
    model_keys() {
        return ['name', 'category', 'width', 'length', 'frame', 'frame_quality', 'glazing', 'glazing_gaz', 'opening', 'closing', 'slopes', 'slope_angle', 'spacing', 'height', 'user_physics', 'Uw', 'Sw', 'Tl'];
    }

    //***********************************************************************************
    /**
     * serialize
     * @returns {object}
     */
    serialize() {
        var json = {};
        json.class_name = 'cn_roof_opening_type';
        json.ID = this.ID;
        json.name = this.name;
        json.category = this.category;
        json.width = this.width;
        json.length = this.length;

        json.frame = this.frame;
        json.frame_quality = this.frame_quality;
        json.glazing = this.glazing;
        json.glazing_gaz = this.glazing_gaz;
        json.opening = this.opening;
        json.closing = this.closing;

        json.slopes = this.slopes;
        json.slope_angle = this.slope_angle;
        json.spacing = this.spacing;
        json.height = this.height;

        json.user_physics = this.user_physics;
        json.Uw = this.Uw;
        json.Sw = this.Sw;
        json.Tl = this.Tl;
        return json;
    }

    //***********************************************************************************
    /**
     * Unserialize
     * @param {object} json
     * @returns {cn_roof_opening_type}
     */
    static unserialize(json) {
        if (typeof (json) != 'object') return null;
        if (typeof (json.ID) != 'string') return null;
        if (json.class_name != 'cn_roof_opening_type') return null;
        if (typeof (json.category) != 'string') return null;
        if (typeof (json.width) != 'number') return null;
        if (typeof (json.length) != 'number') return null;
        var ot = new cn_roof_opening_type();
        if (typeof (json.name) == 'string') ot.name = json.name;
        if (typeof (json.frame) == 'string') ot.frame = json.frame;
        if (typeof (json.frame_quality) == 'boolean') ot.frame_quality = json.frame_quality;
        ot.category = json.category;
        ot.ID = json.ID;
        ot.width = json.width;
        ot.length = json.length;

        if (typeof (json.glazing) == 'string') ot.glazing = json.glazing;
        if (typeof (json.glazing_gaz) == 'string') ot.glazing_gaz = json.glazing_gaz;
        if (typeof (json.opening) == 'string') ot.opening = json.opening;
        if (typeof (json.closing) == 'string') ot.closing = json.closing;

        if (typeof (json.slopes) == 'number') ot.slopes = json.slopes;
        if (typeof (json.slope_angle) == 'number') ot.slope_angle = json.slope_angle;
        if (typeof (json.spacing) == 'number') ot.spacing = json.spacing;
        if (typeof (json.height) == 'number') ot.height = json.height;


        if (typeof (json.user_physics) == 'boolean')
            ot.user_physics = json.user_physics;

        if (typeof (json.Uw) == 'number')
            ot.Uw = json.Uw;

        if (typeof (json.Sw) == 'number')
            ot.Sw = json.Sw;

        if (typeof (json.Tl) == 'number')
            ot.Tl = json.Tl;

        return ot;
    }

    //***********************************************************************************
    //**** Returs visible label
    //***********************************************************************************
    get_label() {
        if (this.name != '') return this.name;
        var html = code_to_label(this.category, ROOF_OPENING_CATEGORY_LIST);
        return html + ' ' + (this.width * 100).toFixed(0) + ' x ' + (this.length * 100).toFixed(0);
    }

    //***********************************************************************************
    //**** Returns description
    //***********************************************************************************

    /**
     * Returns an array of objects describing the type.
     * @returns {{label: string, value?: any, decimals?: number, unit?: string}[]}
     */
    get_description() {
        var description = [];

        var cat = { label: 'Catégorie', value: code_to_label(this.category, ROOF_OPENING_CATEGORY_LIST) };
        description.push(cat);

        description.push({ label: 'Largeur', value: this.width * 100, decimals: 0, unit: 'cm' });

        description.push({ label: 'Longueur', value: this.length * 100, decimals: 0, unit: 'cm' });

        var frame_label = code_to_label(this.frame, FRAME_LIST);
        if (frame_label) {
            if (this.frame_quality) frame_label += ' récent';
            else frame_label += ' ancien';
            description.push({ label: 'Menuiserie', value: frame_label });
        }

        var glazing_label = code_to_label(this.glazing, ROOF_GLAZING_LIST);
        if (glazing_label) {
            if (this.glazing == 'double') glazing_label += ' ' + code_to_label(this.glazing_gaz, GLAZING_GAZ_LIST);
            description.push({ label: 'Vitrage', value: glazing_label });
        }

        var opening_label = code_to_label(this.opening, OPENING_LIST);
        if (opening_label)
            description.push({ label: 'Ouverture', value: opening_label });

        var closing_label = code_to_label(this.closing, CLOSING_LIST);
        if (closing_label)
            description.push({ label: 'Fermeture', value: closing_label });

        if (this.category == 'skylight') {
            description.push({ label: 'Pentes', value: this.slopes, decimals: 0 });
            description.push({ label: 'Angle', value: this.slope_angle, decimals: 0, unit: '°' });
            description.push({ label: 'Espacement', value: this.spacing * 100, decimals: 0, unit: 'cm' });
        }

        if (this.category == 'vent') {
            description.push({ label: 'Hauteur', value: this.height * 100, decimals: 0, unit: 'cm' });
        }

        this.compute_physics();
        description.push({ label: 'Uw', value: this.Uw, decimals: 3, unit: 'W/m²/K' });
        description.push({ label: 'Facteur solaire', value: this.Sw * 100, decimals: 1, unit: '%' });
        description.push({ label: 'Transmission', value: this.Tl * 100, decimals: 1, unit: '%' });

        return description;
    }

    //***********************************************************************************
    /**
     * Draws around the origin, respecting size
     * @param {*} w
     * @param {*} h
     * @returns {string}
     */
    draw_svg(w, h, fill = '') {
        var html = '';

        let style = '';
        if (!fill) {
            let fill_color = '';
            if (this.frame == 'alu') fill_color = 'rgb(100,100,100)';
            else if (this.frame == 'pvc') fill_color = 'rgb(200,200,200)';
            else if (this.frame == 'wood') fill_color = 'rgb(150,125,100)';
            else if (this.frame == 'steel') fill_color = 'rgb(70,70,70)';
            style = 'style="fill:' + fill_color + ';stroke:black"';
        } else {
            style = fill.slice(0, -1) + 'stroke: black"';
        }

        let glass_color = '';
        if (this.glazing == 'single') glass_color = 'rgb(250,250,255)';
        else if (this.glazing == 'double') glass_color = 'rgb(220,220,255)';
        else if (this.glazing == 'polycarbonate') glass_color = 'rgb(200,200,200)';
        else if (this.frame == 'alu') glass_color = 'rgb(100,100,100)';
        else if (this.frame == 'pvc') glass_color = 'rgb(200,200,200)';
        else if (this.frame == 'wood') glass_color = 'rgb(150,125,100)';
        else if (this.frame == 'steel') glass_color = 'rgb(70,70,70)';

        html += '<rect x=\'' + (-w / 2) + '\' y=\'' + (-h / 2) + '\' width=\'' + w + '\' height=\'' + h + '\' ' + style + '/>';

        var mullion = (this.category == 'skylight') ? (0.03 * w / this.width) : (0.1 * w / this.width);

        var x0 = -w / 2 + mullion;
        var y0 = -h / 2 + mullion;
        var ww = w - 2 * mullion;
        var hh = h - 2 * mullion

        if (this.category == 'window') {
            html += '<rect x=\'' + x0 + '\' y=\'' + y0 + '\' width=\'' + ww + '\' height=\'' + hh + '\' style=\'fill:' + glass_color + ';stroke:black\' />';
        } else if (this.category == 'vent') {
            var radius = mullion * 3;
            if (radius > ww / 2 || radius > hh / 2) {
                if (ww > hh) radius = hh / 2;
                else radius = ww / 2;
            }
            html += '<path d=\'M ' + x0 + ' ' + (y0 + radius);
            html += ' L ' + x0 + ' ' + (y0 + hh - radius);
            html += ' Q ' + x0 + ' ' + (y0 + hh);
            html += '  ' + (x0 + radius) + ' ' + (y0 + hh);
            html += ' L ' + (x0 + ww - radius) + ' ' + (y0 + hh);
            html += ' Q ' + (x0 + ww) + ' ' + (y0 + hh);
            html += ' ' + (x0 + ww) + ' ' + (y0 + hh - radius);
            html += ' L ' + (x0 + ww) + ' ' + (y0 + radius);
            html += ' Q ' + (x0 + ww) + ' ' + y0;
            html += ' ' + (x0 + ww - radius) + ' ' + y0;
            html += ' L ' + (x0 + radius) + ' ' + y0;
            html += ' Q ' + x0 + ' ' + y0;
            html += ' ' + x0 + ' ' + (y0 + radius);
            html += ' Z';

            html += '\' style=\'fill:' + glass_color + ';stroke:black\' />';
        } else if (this.category == 'skylight') {
            var spacing = this.spacing * w / this.width;
            var nn = Math.round(ww / spacing);
            if (nn <= 0) nn = 1;
            var dw = ww / nn;

            if (this.slopes < 2) {
                if (this.slopes == 1) {
                    html += '<rect x=\'' + (-w / 2) + '\' y=\'' + (-h / 2) + '\' width=\'' + w + '\' height=\'' + (mullion * 2) + '\' style=\'fill:white;stroke:black\' />';
                    hh -= mullion;
                    y0 += mullion;
                }

                for (var i = 0; i < nn; i++) {
                    var xx0 = (i == 0) ? x0 : x0 + i * dw + mullion / 2;
                    var ddw = dw - mullion;
                    if (i == 0) ddw += mullion / 2;
                    if (i == nn - 1) ddw += mullion / 2;
                    html += '<rect x=\'' + xx0 + '\' y=\'' + y0 + '\' width=\'' + ddw + '\' height=\'' + hh + '\' style=\'fill:' + glass_color + ';stroke:black\' />';
                }
            } else if (this.slopes == 2) {
                html += '<line x1=\'' + (-w / 2) + '\' y1=\'0\' x2=\'' + (w / 2) + '\' y2=\'0\' style=\'stroke:black\' />';

                for (var i = 0; i < nn; i++) {
                    var xx0 = (i == 0) ? x0 : x0 + i * dw + mullion / 2;
                    var ddw = dw - mullion;
                    if (i == 0) ddw += mullion / 2;
                    if (i == nn - 1) ddw += mullion / 2;
                    html += '<rect x=\'' + xx0 + '\' y=\'' + y0 + '\' width=\'' + ddw + '\' height=\'' + (hh / 2 - mullion) + '\' style=\'fill:' + glass_color + ';stroke:black\' />';
                    html += '<rect x=\'' + xx0 + '\' y=\'' + (y0 + hh / 2 + mullion) + '\' width=\'' + ddw + '\' height=\'' + (hh / 2 - mullion) + '\' style=\'fill:' + glass_color + ';stroke:black\' />';
                }
            } else {
                var hhh = hh / 2 - mullion;
                if (this.width > this.length) {
                    var delta = w - h;
                    html += '<line x1=\'' + (-delta / 2) + '\' y1=\'0\' x2=\'' + (delta / 2) + '\' y2=\'0\' style=\'stroke:black\' />';
                    html += '<line x1=\'' + (-delta / 2) + '\' y1=\'0\' x2=\'' + (-w / 2) + '\' y2=\'' + (-h / 2) + '\' style=\'stroke:black\' />';
                    html += '<line x1=\'' + (-delta / 2) + '\' y1=\'0\' x2=\'' + (-w / 2) + '\' y2=\'' + (h / 2) + '\' style=\'stroke:black\' />';
                    html += '<line x1=\'' + (delta / 2) + '\' y1=\'0\' x2=\'' + (w / 2) + '\' y2=\'' + (-h / 2) + '\' style=\'stroke:black\' />';
                    html += '<line x1=\'' + (delta / 2) + '\' y1=\'0\' x2=\'' + (w / 2) + '\' y2=\'' + (h / 2) + '\' style=\'stroke:black\' />';
                    hhh = hh / 2 - mullion;
                } else {
                    var delta = h - w;
                    html += '<line x1=\'0\' y1=\'' + (-delta / 2) + '\' x2=\'0\' y2=\'' + (delta / 2) + '\' style=\'stroke:black\' />';
                    html += '<line x1=\'0\' y1=\'' + (-delta / 2) + '\' x2=\'' + (-w / 2) + '\' y2=\'' + (-h / 2) + '\' style=\'stroke:black\' />';
                    html += '<line x1=\'0\' y1=\'' + (-delta / 2) + '\' x2=\'' + (w / 2) + '\' y2=\'' + (-h / 2) + '\' style=\'stroke:black\' />';
                    html += '<line x1=\'0\' y1=\'' + (delta / 2) + '\' x2=\'' + (-w / 2) + '\' y2=\'' + (h / 2) + '\' style=\'stroke:black\' />';
                    html += '<line x1=\'0\' y1=\'' + (delta / 2) + '\' x2=\'' + (w / 2) + '\' y2=\'' + (h / 2) + '\' style=\'stroke:black\' />';
                    hhh = ww / 2 - mullion;
                }

                var size = ww;
                for (var niter = 0; niter < 2; niter++) {
                    if (niter == 1) {
                        nn = Math.round(hh / spacing);
                        if (nn <= 0) nn = 1;
                        dw = hh / nn;
                        size = hh;
                    }
                    for (var m = 0; m < nn; m++) {
                        var xx0 = m * dw + mullion / 2;
                        var xx1 = xx0 + dw - mullion;
                        if (m == 0) xx0 += mullion / 2;
                        if (m == nn - 1) xx1 -= mullion / 2;
                        var path = [];
                        path.push([xx0, 0]);
                        path.push([xx1, 0]);
                        if (xx1 <= hhh) {
                            path.push([xx1, xx1 - mullion]);
                            path.push([xx0, xx0 - mullion]);
                        } else if (xx1 < size - hhh) {
                            path.push([xx1, hhh - mullion]);
                            if (xx0 > hhh)
                                path.push([xx0, hhh - mullion]);
                            else {
                                path.push([hhh, hhh - mullion]);
                                path.push([xx0, xx0 - mullion]);
                            }
                        } else {
                            path.push([xx1, size - xx1 - mullion]);
                            if (xx0 > size - hhh)
                                path.push([xx0, size - xx0 - mullion]);
                            else {
                                path.push([size - hhh, hhh - mullion]);
                                if (xx0 > hhh)
                                    path.push([xx0, hhh - mullion]);
                                else {
                                    path.push([hhh, hhh - mullion]);
                                    path.push([xx0, xx0 - mullion]);
                                }
                            }
                        }

                        html += '<path d=\'';
                        for (var i = 0; i < path.length; i++) {
                            if (i == 0) html += 'M ';
                            else html += 'L ';
                            if (niter == 0)
                                html += (x0 + path[i][0]) + ' ' + (y0 + path[i][1]) + ' ';
                            else
                                html += (x0 + path[i][1]) + ' ' + (y0 + path[i][0]) + ' ';
                        }
                        html += 'Z\' style=\'fill:' + glass_color + ';stroke:black\' />';


                        html += '<path d=\'';
                        for (var i = 0; i < path.length; i++) {
                            if (i == 0) html += 'M ';
                            else html += 'L ';
                            if (niter == 0)
                                html += (x0 + path[i][0]) + ' ' + (y0 + hh - path[i][1]) + ' ';
                            else
                                html += (x0 + ww - path[i][1]) + ' ' + (y0 + path[i][0]) + ' ';
                        }
                        html += 'Z\' style=\'fill:' + glass_color + ';stroke:black\' />';

                    }
                }
            }
        }

        return html;
    }

    //***********************************************************************************
    //**** svg icon
    //***********************************************************************************
    draw_svg_icon(w, h) {
        var html = '';


        var ow = this.width;
        var oh = this.length;
        var scale = (ow / oh > w / h) ? w / (1.05 * ow) : h / (1.05 * oh);

        html += '<g transform=\'translate(' + (w / 2) + ',' + (h / 2) + ')\'>';
        html += this.draw_svg(this.width * scale, this.length * scale);
        html += '</g>'
        return html;
    }

    //***********************************************************************************
    //**** 3D geometry
    //***********************************************************************************
    /**
     * Builds the piercing polygon
     * @param {number[]} origin : 3D origin of the opening (at ground level)
     * @param {number[]} dx : x normalized direction of the opening (along opening width)
     * @param {number[]} dy : y normalized direction of the opening (along thickness)
     * @param {number[]} dz : vertical direction of the opening
     * @returns {fh_polygon} output solid
     */
    build_piercing_polygon(origin, dx, dy, dz) {

        var contour_vertices = [];
        var origin2 = fh_add(origin, fh_add(fh_mul(dx, -0.5 * this.width), fh_mul(dy, -0.5 * this.length)));
        contour_vertices.push(origin2);
        var p = fh_add(origin2, fh_mul(dx, this.width));
        contour_vertices.push(p);
        contour_vertices.push(fh_add(p, fh_mul(dy, this.length)));
        contour_vertices.push(fh_add(origin2, fh_mul(dy, this.length)));
        var polygon = new fh_polygon(origin2, dz);
        polygon.add_contour(contour_vertices);
        return polygon;
    }

    build_piercing_solid(origin, dx, dy, dz, thickness) {
        var pg = this.build_piercing_polygon(origin, dx, dy, dz);
        var solid = new fh_solid();
        solid.extrusion(pg, fh_mul(dz, thickness));
        return solid;
    }

    //***********************************************************************************
    /**
     * Builds pierceing geometry at given matrix position
     * @param {number[]} origin
     * @param {number[]} dx
     * @param {number[]} dy
     * @param {number[]} dz
     * @param {number[]} thickness
     * @returns {fh_extruded_polygon}
     */
    build_piercing_extrusion(origin, dx, dy, dz, thickness) {
        var pg = this.build_piercing_polygon(origin, dx, dy, dz);
        return fh_extruded_polygon.build_extrusion(pg, fh_mul(dz, thickness), [0, 0, 0]);
    }

    get_area() {
        return this.width * this.length;
    }

    get_glazing_area() {
        if (this.glazing == 'none')
            return 0;
        if (this.category == 'window' || this.category == 'skylight') {
            const frame_width = 0.1;
            if (this.width > 2 * frame_width && this.length > 2 * frame_width)
                return (this.width - 2 * frame_width) * (this.length - 2 * frame_width);
            return 0;
        }
        //*** vent */
        const frame_width = 0.2;
        if (this.width > 2 * frame_width && this.length > 2 * frame_width)
            return (this.width - 2 * frame_width) * (this.length - 2 * frame_width);
        return 0;
    }

    /**
     * Compute physics (areas, Uw, Sw, Tl)
     */
    compute_physics() {
        this.build_extruded_polygons();
        if (this.user_physics) return;
        this.Uw = 2;
        this.Sw = 0.5;
        this.Tl = 0.5;

        var Ug = 0;
        var Uf = 0;
        var Tl = 0;
        var g = 0;
        var PSIg = 0;
        if (this.glazing == 'none') {
            Ug = 5.8;
            PSIg = (this.frame == 'alu' || this.frame == 'steel') ? 0.08 : 0.06;
            Tl = 0;
            g = 0;
        } else if (this.glazing == 'single') {
            Ug = 5.8;
            PSIg = (this.frame == 'alu' || this.frame == 'steel') ? 0.08 : 0.06;
            Tl = 0.86;
            g = 0.90;
        } else if (this.glazing == 'double') {
            PSIg = (this.frame == 'alu' || this.frame == 'steel') ? 0.08 : 0.06;
            if (this.glazing_gaz == 'air_6') {
                Ug = 3.44;
                Tl = 0.76;
                g = 0.81;
            } else if (this.glazing_gaz == 'air_8') {
                Ug = 3.25;
                Tl = 0.76;
                g = 0.81;
            } else if (this.glazing_gaz == 'air_12') {
                Ug = 2.46;
                Tl = 0.72;
                g = 0.75;
            } else if (this.glazing_gaz == 'air_16') {
                Ug = 1.40;
                Tl = 0.62;
                g = 0.65;
            } else if (this.glazing_gaz == 'argon_16') {
                Ug = 1.1;
                Tl = 0.61;
                g = 0.65;
            }
        } else if (this.glazing == 'polycarbonate') {
            Ug = 2.3;
            PSIg = (this.frame == 'alu' || this.frame == 'steel') ? 0.06 : 0.05;
            Tl = 0.75;
            g = 0.75;
        }

        if (this.frame == 'alu' || this.frame == 'steel') {
            Uf = (this.frame_quality) ? 1.8 : 5.8;
        } else if (this.frame == 'pvc') {
            Uf = (this.frame_quality) ? 1.6 : 3.8;
        } else if (this.frame == 'wood') {
            Uf = (this.frame_quality) ? 1.5 : 3.5;
        }

        this.Uw = (this.glazing_area * Ug + this.opaque_area * Ug + this.frame_area * Uf + this.glazing_frame_linear * PSIg + this.opaque_frame_linear * PSIg) / this.full_area;
        this.Sw = g * this.glazing_area / this.full_area;
        this.Tl = Tl * this.glazing_area / this.full_area;
    }

    //***********************************************************************************
    /**
     * Builds 3D geometry as a list of extrusions
     * @returns {fh_extruded_polygon[]}
     */
    build_extruded_polygons() {
        var extruded_polygons = [];
        this.glazing_area = 0;
        this.opaque_area = 0;
        this.frame_area = 0;
        this.full_area = 0;
        this.glazing_frame_linear = 0;
        this.opaque_frame_linear = 0;

        var frame_color = [1, 1, 1, 1];
        if (this.frame == 'alu') frame_color = [0.4, 0.4, 0.4, 1];
        if (this.frame == 'pvc') frame_color = [0.8, 0.8, 0.8, 1];
        if (this.frame == 'wood') frame_color = [0.7, 0.6, 0.5, 1];
        if (this.frame == 'steel') frame_color = [0.2, 0.2, 0.2, 1];

        var filling_color = [1, 1, 1, 0.2];
        if (this.glazing == 'none') filling_color = frame_color;
        else if (this.glazing == 'polyvarbonate') filling_color = [0.5, 0.5, 0.5, 0.8];

        var obj = this;

        function build_pane(contour, thickness, width, glazing = false) {
            var pg = new fh_polygon();
            pg.add_contour(contour);
            pg.compute_contours();
            var normal = pg.get_normal();
            var pg1 = pg.clone();
            pg1.offset(-width);
            pg.substracts(pg1);
            extruded_polygons.push(fh_extruded_polygon.build_extrusion(pg, fh_mul(normal, -thickness), frame_color));
            obj.frame_area += pg.get_area();
            if (glazing) {
                pg1.project(fh_add(contour[0], fh_mul(normal, -thickness * 0.5 - 0.01)), normal, normal);
                extruded_polygons.push(fh_extruded_polygon.build_extrusion(pg1, fh_mul(normal, 0.02), filling_color));
                if (filling_color[3] < 0.9) {
                    obj.glazing_area += pg1.get_area();
                    obj.glazing_frame_linear += pg1.get_perimeter();
                } else {
                    obj.opaque_area += pg1.get_area();
                    obj.opaque_frame_linear += pg1.get_perimeter();
                }
            }
            if (isNaN(obj.frame_area) || isNaN(obj.glazing_area))
                logger.log('isnan');
        }

        //*** draw frame
        if (this.category == 'window') {
            var frame_width = 0.03;
            var contour = [];
            contour.push([-this.width / 2, -this.length / 2, 0]);
            contour.push([this.width / 2, -this.length / 2, 0]);
            contour.push([this.width / 2, this.length / 2, 0]);
            contour.push([-this.width / 2, this.length / 2, 0]);
            if (this.opening == 'none') {
                build_pane(contour, 0.05, 0.1, true);
            } else {
                build_pane(contour, 0.1, frame_width);
                contour = [];
                contour.push([-this.width / 2 + frame_width, -this.length / 2 + frame_width, -0.03]);
                contour.push([this.width / 2 - frame_width, -this.length / 2 + frame_width, -0.03]);
                contour.push([this.width / 2 - frame_width, this.length / 2 - frame_width, -0.03]);
                contour.push([-this.width / 2 + frame_width, this.length / 2 - frame_width, -0.03]);
                build_pane(contour, 0.05, 0.07, true);
            }
        } else if (this.category == 'skylight') {
            var nn = Math.round(this.width / this.spacing);
            if (nn <= 0) nn = 1;
            var dw = this.width / nn;
            var mullion = 0.03;
            if (this.slopes <= 1) {
                var z = 0;
                if (this.slopes == 1) z = this.length * Math.tan(this.slope_angle * Math.PI / 180);
                var dy = (z > 0) ? mullion : 0;
                for (var x = 0; x < nn; x++) {
                    var x0 = -this.width / 2 + dw * x;
                    var x1 = x0 + dw;
                    if (x < nn - 1) x1 += mullion * 0.5;
                    if (x > 0) x0 -= mullion * 0.5;
                    var contour = [];
                    contour.push([x0, -this.length / 2, 0]);
                    contour.push([x1, -this.length / 2, 0]);
                    contour.push([x1, this.length / 2 - dy, z]);
                    contour.push([x0, this.length / 2 - dy, z]);
                    build_pane(contour, mullion, mullion, true);
                    if (z > 0) {
                        contour = [];
                        contour.push([x0, this.length / 2, z]);
                        contour.push([x1, this.length / 2, z]);
                        contour.push([x1, this.length / 2, 0]);
                        contour.push([x0, this.length / 2, 0]);
                        build_pane(contour, mullion, mullion, true);
                    }
                }
                if (z > 0) {
                    var contour = [];
                    contour.push([-this.width / 2, -this.length / 2, 0]);
                    contour.push([-this.width / 2, this.length / 2, z]);
                    contour.push([-this.width / 2, this.length / 2, 0]);
                    build_pane(contour, mullion, mullion, true);

                    contour = [];
                    contour.push([this.width / 2, -this.length / 2, 0]);
                    contour.push([this.width / 2, this.length / 2, 0]);
                    contour.push([this.width / 2, this.length / 2, z]);
                    build_pane(contour, mullion, mullion, true);
                }
            } else if (this.slopes == 2) {
                var z = this.length * 0.5 * Math.tan(this.slope_angle * Math.PI / 180);
                for (var x = 0; x < nn; x++) {
                    var x0 = -this.width / 2 + dw * x;
                    var x1 = x0 + dw;
                    if (x < nn - 1) x1 += mullion * 0.5;
                    if (x > 0) x0 -= mullion * 0.5;
                    var contour = [];
                    contour.push([x0, -this.length / 2, 0]);
                    contour.push([x1, -this.length / 2, 0]);
                    contour.push([x1, 0, z]);
                    contour.push([x0, 0, z]);
                    build_pane(contour, mullion, mullion, true);
                    contour = [];
                    contour.push([x0, 0, z]);
                    contour.push([x1, 0, z]);
                    contour.push([x1, this.length / 2, 0]);
                    contour.push([x0, this.length / 2, 0]);
                    build_pane(contour, mullion, mullion, true);
                }
                if (z > 0) {
                    var contour = [];
                    contour.push([-this.width / 2, -this.length / 2, 0]);
                    contour.push([-this.width / 2, 0, z]);
                    contour.push([-this.width / 2, this.length / 2, 0]);
                    build_pane(contour, mullion, mullion, true);

                    contour = [];
                    contour.push([this.width / 2, this.length / 2, 0]);
                    contour.push([this.width / 2, 0, z]);
                    contour.push([this.width / 2, -this.length / 2, 0]);
                    build_pane(contour, mullion, mullion, true);
                }
            } else if (this.slopes == 4) {
                var z = this.length * 0.5 * Math.tan(this.slope_angle * Math.PI / 180);
                var limit = (this.length > this.width) ? this.width / 2 : this.length / 2;
                var size = this.width;
                for (var niter = 0; niter < 2; niter++) {
                    if (niter == 1) {
                        size = this.length;
                        nn = Math.round(size / this.spacing);
                        if (nn <= 0) nn = 1;
                        dw = size / nn;
                    }
                    for (var x = 0; x < nn; x++) {
                        var x0 = dw * x;
                        var x1 = x0 + dw;
                        if (x < nn - 1) x1 += mullion * 0.5;
                        if (x > 0) x0 -= mullion * 0.5;
                        var contour = [];
                        contour.push([x1, 0, 0]);
                        contour.push([x0, 0, 0]);
                        if (x1 < limit) {
                            contour.push([x0, x0, z * x0 / limit]);
                            contour.push([x1, x1, z * x1 / limit]);
                        } else if (x1 < size - limit) {
                            if (x0 < limit) {
                                contour.push([x0, x0, z * x0 / limit]);
                                contour.push([limit, limit, z]);
                            } else
                                contour.push([x0, limit, z]);
                            contour.push([x1, limit, z]);
                        } else {
                            if (x0 < limit) {
                                contour.push([x0, x0, z * x0 / limit]);
                                contour.push([limit, limit, z]);
                                contour.push([size - limit, limit, z]);
                            } else if (x0 < size - limit) {
                                contour.push([x0, limit, z]);
                                contour.push([size - limit, limit, z]);
                            } else
                                contour.push([x0, size - x0, z * (size - x0) / limit]);

                            contour.push([x1, size - x1, z * (size - x1) / limit]);
                        }
                        if (niter == 0)
                            contour.reverse();
                        var ctr = [];
                        for (var k = 0; k < contour.length; k++)
                            ctr.push([contour[k][niter] - this.width / 2, contour[k][1 - niter] - this.length / 2, contour[k][2]]);

                        build_pane(ctr, mullion, mullion, true);
                        ctr.reverse();
                        for (var kk in ctr) ctr[kk][1 - niter] = -ctr[kk][1 - niter];
                        build_pane(ctr, mullion, mullion, true);
                    }
                }
            }
        } else if (this.category == 'vent') {
            var contour = [];
            contour.push([-this.width / 2, -this.length / 2, this.height]);
            contour.push([this.width / 2, -this.length / 2, this.height]);
            contour.push([this.width / 2, this.length / 2, this.height]);
            contour.push([-this.width / 2, this.length / 2, this.height]);
            build_pane(contour, this.height + 0.1, 0.02);

            var radius = 0.3;
            mullion = 0.1;
            var z = this.height - 0.01;
            if (mullion > this.width / 5) mullion = this.width / 5;
            if (mullion > this.length / 5) mullion = this.length / 5;
            var ww = this.width - 2 * mullion;
            var hh = this.length - 2 * mullion;
            if (radius > ww / 2) radius = ww / 2;
            if (radius > hh / 2) radius = ww / 2;

            var cx = ww / 2 - radius;
            var cy = hh / 2 - radius;
            var ctr = [];
            for (var a = 0; a < 360; a += 5) {
                var x = Math.cos(a * Math.PI / 180);
                var y = Math.sin(a * Math.PI / 180);
                if (a == 0) {
                    ctr.push([cx + radius * x, -cy + radius * y, z]);
                    ctr.push([cx + radius * x, cy + radius * y, z]);
                } else if (a == 90) {
                    ctr.push([cx + radius * x, cy + radius * y, z]);
                    ctr.push([-cx + radius * x, cy + radius * y, z]);
                } else if (a == 180) {
                    ctr.push([-cx + radius * x, cy + radius * y, z]);
                    ctr.push([-cx + radius * x, -cy + radius * y, z]);
                } else if (a == 270) {
                    ctr.push([-cx + radius * x, -cy + radius * y, z]);
                    ctr.push([cx + radius * x, -cy + radius * y, z]);
                } else {
                    var ccx = (x > 0) ? cx : -cx;
                    var ccy = (y > 0) ? cy : -cy;
                    ctr.push([ccx + radius * x, ccy + radius * y, z]);
                }
            }
            var pg0 = new fh_polygon();
            pg0.add_contour(contour);
            var pg1 = new fh_polygon();
            pg1.add_contour(ctr);
            pg0.substracts(pg1);
            this.frame_area += pg0.get_area();
            if (filling_color[3] < 0.9) {
                this.glazing_area += pg1.get_area();
                this.glazing_frame_linear += pg1.get_perimeter();
            } else {
                this.opaque_area += pg1.get_area();
                this.opaque_frame_linear += pg1.get_perimeter();
            }
            extruded_polygons.push(fh_extruded_polygon.build_extrusion(pg0, [0, 0, -0.04], frame_color));
            extruded_polygons.push(fh_extruded_polygon.build_extrusion(pg1, [0, 0, -0.02], filling_color));
        }

        this.full_area = this.glazing_area + this.frame_area + this.opaque_area;

        return extruded_polygons;
    }

    //***********************************************************************************
    //**** Get configuration
    //***********************************************************************************
    static configuration() {
        var configuration = new cn_configuration();

        //*** Global params
        var param_group = new cn_configuration_param_group('Dimensions', 'dimensions');
        configuration.add_param_group(param_group);
        param_group.add_param(new cn_configuration_param('Largeur', 'width', 1, 1000, 'cm'));
        param_group.add_param(new cn_configuration_param('Longueur', 'length', 1, 1000, 'cm'));


        param_group = new cn_configuration_param_group('Thermique', 'thermics', true);
        configuration.add_param_group(param_group);
        param_group.add_param(new cn_configuration_param('Uw', 'Uw', 0, 10, 'W/m²/K', 3, 0.1));
        param_group.add_param(new cn_configuration_param('Facteur solaire', 'Sw', 0, 100, '%', 1, 0.1));
        param_group.add_param(new cn_configuration_param('Transmission', 'Tl', 0, 100, '%', 1, 0.1));

        //***********************************************
        //*** Window tab
        var tab = new cn_configuration_tab('Fenêtre de toit', 'window');
        configuration.add_tab(tab);

        //*** Frame
        var frame_list = new cn_configuration_line('Menuiserie', 'frame');
        tab.add_line(frame_list);
        for (var i in FRAME_LIST)
            frame_list.add_choice(new cn_configuration_choice(FRAME_LIST[i].label, FRAME_LIST[i].code));

        frame_list.add_param(cn_configuration_param.choice_list('Type', 'frame_quality', ['ancien', 'récent']));

        //*** Glazing
        var glazing_list = new cn_configuration_line('Vitrage', 'glazing');
        tab.add_line(glazing_list);
        for (var i in ROOF_GLAZING_LIST)
            glazing_list.add_choice(new cn_configuration_choice(ROOF_GLAZING_LIST[i].label, ROOF_GLAZING_LIST[i].code));

        glazing_list.add_param(cn_configuration_param.choice_list('Lame d\'air', 'glazing_gaz', GLAZING_GAZ_LIST.map(g => g.label), 3));

        //*** Opening
        var opening_list = new cn_configuration_line('Ouverture', 'opening');
        tab.add_line(opening_list);
        for (var i in OPENING_LIST)
            opening_list.add_choice(new cn_configuration_choice(OPENING_LIST[i].label, OPENING_LIST[i].code));

        //*** closing
        var closing_list = new cn_configuration_line('Fermeture', 'closing');
        tab.add_line(closing_list);
        for (var i in CLOSING_LIST)
            closing_list.add_choice(new cn_configuration_choice(CLOSING_LIST[i].label, CLOSING_LIST[i].code));

        //***********************************************
        //*** Skylight tab
        tab = new cn_configuration_tab('Lanterneau', 'skylight');
        configuration.add_tab(tab);

        //*** Frame
        frame_list = new cn_configuration_line('Menuiserie', 'frame');
        tab.add_line(frame_list);
        frame_list.add_param(new cn_configuration_param('Espacement', 'spacing', 10, 1000, ' cm', 0, 1, 50));
        for (var i in FRAME_LIST)
            frame_list.add_choice(new cn_configuration_choice(FRAME_LIST[i].label, FRAME_LIST[i].code));

        frame_list.add_param(cn_configuration_param.choice_list('Type', 'frame_quality', ['ancien', 'récent']));

        //*** Glazing
        glazing_list = new cn_configuration_line('Vitrage', 'glazing');
        tab.add_line(glazing_list);
        for (var i in ROOF_GLAZING_LIST)
            glazing_list.add_choice(new cn_configuration_choice(ROOF_GLAZING_LIST[i].label, ROOF_GLAZING_LIST[i].code));

        glazing_list.add_param(cn_configuration_param.choice_list('Lame d\'air', 'glazing_gaz', GLAZING_GAZ_LIST.map(g => g.label), 3));

        //*** Slopes
        var slope_list = new cn_configuration_line('Pentes', 'slopes');
        tab.add_line(slope_list);
        slope_list.add_param(new cn_configuration_param('Angle', 'slope_angle', 0, 60, '°', 0, 1));
        slope_list.add_choice(new cn_configuration_choice('Aucune', '0'));
        slope_list.add_choice(new cn_configuration_choice('1', '1'));
        slope_list.add_choice(new cn_configuration_choice('2', '2'));
        slope_list.add_choice(new cn_configuration_choice('4', '4'));

        //***********************************************
        //*** Vent tab
        tab = new cn_configuration_tab('Exutoire', 'vent');
        configuration.add_tab(tab);

        //*** Frame
        frame_list = new cn_configuration_line('Menuiserie', 'frame');
        tab.add_line(frame_list);
        frame_list.add_param(new cn_configuration_param('Hauteur', 'height', 0, 100, 'cm', 1, 0.5));
        for (var i in FRAME_LIST)
            frame_list.add_choice(new cn_configuration_choice(FRAME_LIST[i].label, FRAME_LIST[i].code));

        frame_list.add_param(cn_configuration_param.choice_list('Type', 'frame_quality', ['ancien', 'récent']));

        //*** Glazing
        glazing_list = new cn_configuration_line('Vitrage', 'glazing');
        tab.add_line(glazing_list);
        for (var i in ROOF_GLAZING_LIST)
            glazing_list.add_choice(new cn_configuration_choice(ROOF_GLAZING_LIST[i].label, ROOF_GLAZING_LIST[i].code));

        glazing_list.add_param(cn_configuration_param.choice_list('Lame d\'air', 'glazing_gaz', GLAZING_GAZ_LIST.map(g => g.label), 3));

        return configuration
    }

    //***********************************************************************************
    /**
     * Builds or fill a configuration with data from thie element type
     * @param {object} config : input config, or null if config to be created
     * @returns {object} configuration filled
     */
    fill_configuration(config = null) {
        var configuration = (config) ? config : cn_roof_opening_type.configuration();

        var param_group = configuration.get_param_group('dimensions');
        param_group.set_param('width', this.width * 100);
        param_group.set_param('length', this.length * 100);

        this.compute_physics();
        param_group = configuration.get_param_group('thermics');
        param_group.checked = this.user_physics;
        param_group.set_param('Uw', this.Uw);
        param_group.set_param('Sw', this.Sw * 100);
        param_group.set_param('Tl', this.Tl * 100);

        //*** default to generic
        configuration.selection = configuration.get_tab_index(this.category);

        //*** default data */
        var tab = configuration.tabs[configuration.get_tab_index('window')];
        tab.get_line('frame').set_selection(this.frame);
        tab.get_line('frame').set_param('frame_quality', (this.frame_quality) ? 1 : 0);
        tab.get_line('glazing').set_selection(this.glazing);
        tab.get_line('glazing').set_param('glazing_gaz', GLAZING_GAZ_LIST.map(g => g.code).indexOf(this.glazing_gaz), this.glazing == 'double');
        tab.get_line('opening').set_selection(this.opening);
        tab.get_line('closing').set_selection(this.closing);

        var tab = configuration.tabs[configuration.get_tab_index('skylight')];
        tab.get_line('frame').set_selection(this.frame);
        tab.get_line('frame').set_param('frame_quality', (this.frame_quality) ? 1 : 0);
        tab.get_line('frame').get_param('spacing').value = this.spacing * 100;
        tab.get_line('glazing').set_selection(this.glazing);
        tab.get_line('glazing').set_param('glazing_gaz', GLAZING_GAZ_LIST.map(g => g.code).indexOf(this.glazing_gaz), this.glazing == 'double');
        tab.get_line('slopes').set_selection(this.slopes);
        tab.get_line('slopes').get_param('slope_angle').value = this.slope_angle;

        var tab = configuration.tabs[configuration.get_tab_index('vent')];
        tab.get_line('frame').set_selection(this.frame);
        tab.get_line('frame').set_param('frame_quality', (this.frame_quality) ? 1 : 0);
        tab.get_line('frame').get_param('height').value = this.height * 100;
        tab.get_line('glazing').set_selection(this.glazing);
        tab.get_line('glazing').set_param('glazing_gaz', GLAZING_GAZ_LIST.map(g => g.code).indexOf(this.glazing_gaz), this.glazing == 'double');

        return configuration;
    }

    //***********************************************************************************
    load_configuration(configuration, actual_change = false) {
        var param_group = configuration.get_param_group('dimensions');
        this.width = param_group.get_param('width').value / 100;
        this.length = param_group.get_param('length').value / 100;

        var configuration_tab = configuration.tabs[configuration.selection];
        this.category = configuration_tab.name;

        if (configuration.get_selection() == 'free') {
            configuration.get_param_group('thermics').visible = false;
            this.free = true;
            return true;
        }

        param_group = configuration.get_param_group('thermics');
        param_group.visible = true;
        this.user_physics = param_group.checked;
        if (this.user_physics) {
            this.Uw = param_group.get_param('Uw').value;
            this.Sw = param_group.get_param('Sw').value / 100;
            this.Tl = param_group.get_param('Tl').value / 100;
        } else
            this.compute_physics();

        //*** read frame */
        this.frame = configuration_tab.get_line('frame').get_selection();
        this.frame_quality = (configuration_tab.get_line('frame').get_param('frame_quality').value == 1);

        //*** read glazing */
        this.glazing = configuration_tab.get_line('glazing').get_selection();
        if (this.glazing == 'double')
            this.glazing_gaz = GLAZING_GAZ_LIST.map(g => g.code)[configuration_tab.get_line('glazing').get_param('glazing_gaz').value];

        if (this.category == 'window') {
            this.opening = configuration_tab.get_line('opening').get_selection();
            this.closing = configuration_tab.get_line('closing').get_selection();
        } else if (this.category == 'skylight') {
            var slope_line = configuration_tab.get_line('slopes');
            this.slopes = parseInt(slope_line.get_selection());
            this.slope_angle = slope_line.get_param('slope_angle').value;
            this.spacing = configuration_tab.get_line('frame').get_param('spacing').value / 100;
        } else if (this.category == 'vent') {
            this.height = configuration_tab.get_line('frame').get_param('height').value / 100;
        }

        if (!this.user_physics) {
            this.compute_physics();
            param_group = configuration.get_param_group('thermics');
            param_group.checked = this.user_physics;
            param_group.set_param('Uw', this.Uw);
            param_group.set_param('Sw', this.Sw * 100);
            param_group.set_param('Tl', this.Tl * 100);
        }
        return true;
    }

    /**
     * Accept element type visitor
     *
     * @param {cn_element_type_visitor} element_type_visitor
     */
    accept_visitor(element_type_visitor) {
        element_type_visitor.visit_roof_opening_type(this);
    }
}

