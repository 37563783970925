import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-logement-salle-deau',
    templateUrl: './logement-salle-deau.component.html',
    styleUrls: ['./logement-salle-deau.component.scss'],
})
export class LogementSalleDEauComponent implements OnInit {
    readonly MENU_NAME = "Salle d'eau";

    constructor() {}

    ngOnInit(): void {}
}
