import { Adresse } from './adresse.model';
import { UserWizy } from './user-wizy.model';
import { Document } from './document.model';
import { Cofrac } from './cofrac.model';
/**
 * Agence
 */
export class Agence {
    id: string;

    idSalesforce: string;

    nom?: string;

    typeAgence: TypeAgence;

    agenceParent?: AgenceMinimal;

    adresse: Adresse;

    siret?: string;

    siren?: string;

    assurances?: Assurance[] = [];

    codeApe?: string;

    codeNaf?: string;

    tva?: number;

    cofracs?: Cofrac[] = [];

    attestationHonneurDocument?: Document;

    responsableAgence: UserWizy;

    email?: string;

    telephone?: string;

    fax?: string;

    hasInfosParent?: boolean;
}

export enum TypeAgence {
    /** MaisonMère */
    MAISON_MERE = 'MAISON_MERE',

    /** Franchise */
    FRANCHISE = 'FRANCHISE',

    /** Agence */
    AGENCE = 'AGENCE',
}

/**
 * À partir d'un type d'agence, renvoie le label associé.
 */
export function typeAgenceToLabel(typeAgence: string | TypeAgence): string {
    switch (typeAgence) {
        case 'MAISON_MERE':
            return 'Maison Mère';
        case 'FRANCHISE':
            return 'Franchise';
        case 'AGENCE':
            return 'Agence';
        default:
            return typeAgence;
    }
}

export class Assurance {
    compagnie: string;

    /**
     * Adresse
     */
    adresse: Adresse;

    /**
     * Numéro de police
     */
    numeroPolice?: string;

    /**
     * Date de début de validité du contrat
     */
    dateDebutValidite: string;

    /**
     * Date d'échéance du contrat
     */
    dateEcheance: string;

    /**
     * Fichier de justificatif
     */
    justificatifDocument?: Document;
}

export class AgenceMinimal {
    id?: string;
    nom?: string;
}
