import { Pipe, PipeTransform } from '@angular/core';
import { TypeEchantillonage, typeEchantillonageToLabel } from '../model/prelevement-generique.model';

/**
 * Affiche le nom du type d'un echantillonage
 */
@Pipe({
    name: 'typeEchantillonage',
})
export class TypeEchantillonagePipe implements PipeTransform {
    constructor() {}

    transform(typeEchantillonage: TypeEchantillonage): string {
        return typeEchantillonageToLabel(typeEchantillonage);
    }
}
