<lib-form-container [smallMode]="true">
    <h1>Réinitialisation du mot de passe</h1>

    <mat-form-field class="w-100">
        <mat-label>Votre e-mail</mat-label>
        <input matInput type="email" email required [(ngModel)]="email" #emailField="ngModel" />
    </mat-form-field>

    <div class="text-center mt-3">
        <span>Un email va vous être envoyé avec le code de réinitialisation.</span>
    </div>

    <div class="text-center mt-3">
        <button mat-raised-button color="accent" [disabled]="emailField.invalid" (click)="submit()">
            <span>Envoyer l'email</span>
        </button>
    </div>
</lib-form-container>
