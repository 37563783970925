import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseComponent, ConfirmationService, NotificationService } from 'src/app/commons-lib';
import { CategorieEquipement } from 'src/app/model/categorie-equipement.model';
import { typesPrestation } from 'src/app/model/type-prestation.model';
import { Router } from '@angular/router';
import { URL_GESTION_CATEGORIES_EQUIPEMENTS_EDIT } from 'src/app/shared/constants/url.constants';
import { StateChoiceBoxes } from 'src/app/model/states.model';
import { falseOption, trueOption } from '../../../../../shared/constants/states.constants';
import { EditModeEnum } from 'src/app/model/edit-mode.model';
import { takeUntil } from 'rxjs/operators';
import { ReferenceApiService } from 'src/app/services/reference-api.service';

@Component({
    selector: 'app-view-details-categorie-equipement',
    templateUrl: './view-details-categorie-equipement.component.html',
    styleUrls: ['./view-details-categorie-equipement.component.scss'],
})
export class ViewDetailsCategorieEquipementComponent extends BaseComponent implements OnInit, OnDestroy {
    @Output() deletedCategorieEquipementEvent = new EventEmitter<CategorieEquipement>();

    @Input()
    categorie: CategorieEquipement;

    @Input()
    listeAllCategories: CategorieEquipement[];

    listeCategories: CategorieEquipement[];

    typesPrestation = typesPrestation;

    trueOption: StateChoiceBoxes = trueOption;
    falseOption: StateChoiceBoxes = falseOption;
    toolTipOuiNon = ['Oui', 'Non'];
    abstract: boolean = false;

    constructor(
        private readonly router: Router,
        private readonly referenceApiService: ReferenceApiService,
        private readonly confirmationService: ConfirmationService,
        private readonly notificationService: NotificationService
    ) {
        super();
    }

    ngOnInit(): void {
        this.abstract = this.controleAbstract();
    }

    controleAbstract(): boolean {
        if (this.categorie == null) {
            return false;
        } else if (this.categorie.abstract == null) {
            return false;
        } else {
            return !this.categorie.abstract;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        //console.log(changes);
        this.categorie = changes.categorie.currentValue;
        this.abstract = this.controleAbstract();
    }

    nomCategorieParente() {
        //console.log('nomCategorieParente');
        //console.log(this.categorie);

        if (this.categorie?.parentsIds.length > 0) {
            return this.listeCategories?.find((cat) => cat.id == this.categorie?.parentsIds[0]).name;
        }
    }

    creerCategorieEquipement() {
        this.router.navigate([URL_GESTION_CATEGORIES_EQUIPEMENTS_EDIT, EditModeEnum.CREATE]);
    }

    dupliquerCategorieEquipement(id: string) {
        this.router.navigate([URL_GESTION_CATEGORIES_EQUIPEMENTS_EDIT, id, EditModeEnum.DUPLICATE]);
    }

    editerCategorieEquipement(id: string) {
        this.router.navigate([URL_GESTION_CATEGORIES_EQUIPEMENTS_EDIT, id, EditModeEnum.EDIT]);
    }

    supprimerCategorieEquipement(id: string) {
        if (this.currentCategorieEquipementIsEmpty()) {
            this.confirmationService.confirm(`Voulez-vous supprimer définitivement cette catégorie : ${this.categorie.name} ?`, () => {
                this.referenceApiService
                    .deleteCategorieEquipement(id)
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe((resp) => {
                        this.notificationService.success(`La catégorie d'équipement : ${this.categorie.name} à bien été supprimée`);
                        this.deletedCategorieEquipementEvent.emit(this.categorie);
                    });
            });
        } else {
            this.notificationService.warn(
                `La catégorie d'équipement ${this.categorie.name} ne peut pas être supprimée car elle contient des équipements`
            );
        }
    }

    currentCategorieEquipementIsEmpty() {
        let ret = true;
        if (this.categorie?.children?.length > 0) {
            ret = false;
        }
        return ret;
    }
}
