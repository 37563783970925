/**
 * Liste des états de validation des données.
 * Utilisée pour définir l'état d'avancement par onglet
 */

export type EtatValidation = 'VOID' | 'UNVERIFIED' | 'INVALID' | 'VALID';

/**
 * Etat de la progression de la saisie de données associé à un code.
 * Le code correspond a celui définit dans les datas de la route correspondant à un onglet
 */
export class EtatProgression {
    /**
     * Code de la route correspondant à l'onglet
     */
    code: string;

    /**
     * Etat de la progression de la saisie des informations
     */
    etat: EtatValidation;

    constructor(code: string, etat: EtatValidation) {
        this.code = code;
        this.etat = etat;
    }
}
