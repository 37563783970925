import { Component, OnInit, Input } from '@angular/core';
import { TypePrelevementAmiante } from '../../../../../../../model/prelevement-generique.model';

@Component({
    selector: 'app-context-zone-homogene-template',
    templateUrl: './context-zone-homogene-template.component.html',
    styleUrls: ['./context-zone-homogene-template.component.scss'],
})
export class ContextZoneHomogeneTemplateComponent implements OnInit {
    @Input() contexte: any;

    constructor() {}

    ngOnInit(): void {}
}
