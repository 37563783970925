<mat-card class="clickable" (click)="onCardClick(surface, indexSurface)" [class.selected]="selected">
    <mat-card-content
        class="w-100 h-100 mh-100 d-flex flex-column justify-content-center align-items-center text-center"
        [ngClass]="{
            'bg-primary': !surface,
            'text-white': !surface
        }"
    >
        <div class="container">
            <div class="row small-font align-items-center">
                <div class="col-9">
                    <div *ngIf="surface.get('listeIdVolume').value.length == 1" class="row justify-content-start">
                        <app-button-togle-group
                            (surfaceDataEmitter)="openModal($event)"
                            [surface]="surface"
                            [volumesSurface]="volumesSurface"
                            [typeSurface]="surface.get('typeSurface').value"
                        ></app-button-togle-group>
                    </div>
                </div>
                <div class="d-flex flex-row col-3">
                    <button mat-icon-button matTooltip="Supprimer la surface" class="warn mr-1" (click)="removeSurface(indexSurface)">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button
                        *ngIf="surface.get('listeIdVolume')?.value?.length > 1"
                        mat-icon-button
                        matTooltip="Ajouter un commentaire"
                        (click)="onAddJustification.emit(indexSurface)"
                    >
                        <mat-icon
                            [matBadgeHidden]="surface.get('commentairesId')?.value?.length == 0"
                            [matBadge]="surface.get('commentairesId')?.value?.length"
                            matBadgeColor="accent"
                        >
                            speaker_notes
                        </mat-icon>
                    </button>
                </div>
            </div>
            <div class="row small-font">
                <div class="col-12">
                    <div class="row justify-content-between small-font">
                        <div class="col-6">
                            <div class="row no-padding d-flex align-items-center">
                                {{ getSurfaceLabel(surface) }} :
                                <div *ngIf="isSingleVolume(surface)" class="d-flex justify-content-center m-1">
                                    <div *ngIf="this.surface.get('typeSurface').value === ''" class="badge-info-grey">
                                        {{ this.surface.get('superficie').value }}
                                    </div>
                                    <div *ngIf="this.surface.get('typeSurface').value !== ''" class="badge-info-grey">
                                        {{ this.surface.get('aditionnalSurfaveValue').value }}
                                    </div>
                                    <a class="custom-button" (click)="onClickOpenModal()" data-cy="edit-zone-button" matTooltip="Editer la surface">
                                        <mat-icon>edit</mat-icon>
                                    </a>
                                </div>
                                <span
                                    *ngIf="!isSingleVolume(surface) && this.surface.get('typeSurface').value === ''"
                                    class="badge-info-grey-little"
                                    >{{ this.surface.get('superficie').value }}</span
                                >

                                <span
                                    *ngIf="!isSingleVolume(surface) && this.surface.get('typeSurface').value !== ''"
                                    class="badge-info-grey-little"
                                    >{{ this.surface.get('aditionnalSurfaveValue').value }}</span
                                >
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="row justify-content-end">
                                P.U. : <span class="badge-info-grey-little"> {{ surface.get('nombrePu').value }} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-2">
                <div *ngFor="let volume of getVolumes(surface.get('listeIdVolume').value); let index = index">
                    <div class="mb-1" *ngIf="volume">
                        <app-volume-block
                            (remove)="removeVolume($event)"
                            (declareSurfaceEvent)="declareSurfaceVolume($event)"
                            [volume]="volume"
                            [volumesSurface]="volumesSurface"
                            [label]="volume.nom"
                            [indexSurface]="indexSurface"
                            [longueurMax]="surface.get('longueurMax').value"
                            [nbVolumes]="surface.get('listeIdVolume').value.length"
                        ></app-volume-block>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
