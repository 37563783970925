import { AccessDeniedComponent, AuthGuard } from 'src/app/commons-lib';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { MissionsComponent } from './modules/accueil/missions/missions.component';
import { AjoutRegleComponent } from './modules/admin/ajout-regle/ajout-regle.component';
import { URL_TABLEAU_DE_BORD } from './shared/constants/url.constants';
import { AppInfoComponent } from './modules/app-info/app-info.component';
import { StartInterGuard } from './guards/startInter.guard';
import { OnlineGuard } from './guards/online.guard';
// tslint:disable-next-line:max-line-length

export const DEFAULT_LANDING_PAGE = URL_TABLEAU_DE_BORD;

const routes: Routes = [
    {
        path: '',
        redirectTo: DEFAULT_LANDING_PAGE,
        pathMatch: 'full',
    },
    {
        path: 'access-denied',
        component: AccessDeniedComponent,
    },
    {
        path: 'login',
        loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginPageModule),
    },
    {
        path: 'logout',
        loadChildren: () => import('./modules/logout/logout.module').then((m) => m.LogoutPageModule),
    },
    {
        path: 'password-reset',
        loadChildren: () => import('./lib-routing.modules').then((m) => m.PasswordResetModule),
    },
    {
        path: 'tableau-de-bord',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/tableau-de-bord/tableau-de-bord.module').then((m) => m.TableauDeBordModule),
    },
    {
        path: 'agenda',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: 'jour',
                pathMatch: 'full',
            },
        ],
    },
    {
        path: 'missions',
        canActivate: [AuthGuard],
        component: MissionsComponent,
    },

    {
        path: 'admin',
        canActivate: [AuthGuard, OnlineGuard],
        data: { authorities: ['ROLE_ADMIN'] },
        loadChildren: () => import('./lib-routing.modules').then((m) => m.AdminEnerbimModule),
    },
    {
        path: 'admin',
        canActivate: [AuthGuard, OnlineGuard],
        loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
    },
    {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: () => import('./lib-routing.modules').then((m) => m.ProfileModule),
    },
    {
        path: 'interventions',
        canActivate: [AuthGuard, StartInterGuard],
        loadChildren: () => import('./modules/interventions/intervention.module').then((m) => m.InterventionModule),
    },
    {
        path: 'gestion-interventions',
        data: { authorities: ['ROLE_OPERATOR', 'ROLE_PROJECT_LEADER', 'ROLE_MANAGER', 'ROLE_ADMIN'] },
        canActivate: [AuthGuard, OnlineGuard],
        loadChildren: () => import('./modules/gestion-interventions/gestion-interventions.module').then((m) => m.GestionInterventionsModule),
    },
    {
        path: 'gestion-livrables',
        canActivate: [AuthGuard, OnlineGuard],
        loadChildren: () => import('./modules/admin/gestion-livrables/gestion-livrables.module').then((m) => m.GestionLivrablesModule),
    },
    {
        path: 'ajout-regle',
        canActivate: [AuthGuard, OnlineGuard],
        data: { authorities: ['ROLE_ADMIN'] },
        component: AjoutRegleComponent,
    },
    {
        path: 'application-infos',
        canActivate: [AuthGuard],
        component: AppInfoComponent,
        children: [
            {
                path: 'changelog',
                canActivate: [AuthGuard],
                loadChildren: () => import('./lib-routing.modules').then((m) => m.MarkdownModule),
                data: {
                    pageTitle: 'Journal des modifications',
                    markdownFile: 'Changelog.csv',
                },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
