import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/commons-lib';
import { cn_storey } from '@acenv/cnmap-editor';
import { MatDialog } from '@angular/material/dialog';
import { OuvrageModalComponent } from 'src/app/modules/shared/ouvrage-modal/ouvrage-modal.component';
import { Espace } from '../../../../../model/espace.model';
import { HapFormService } from '../../../../diagnostics/hap/services/hap-form.service';
import { HapCnMapService } from '../../../../diagnostics/hap/services/hap-cn-map.service';
import { TypePrestation } from 'src/app/model/type-prestation.model';
import { PlanLocalisation } from 'src/app/model/perimetre-investigation.model';
import { WidgetSelectModeEnum } from '../../../widget-select/widget-select.component';

@Component({
    selector: 'app-perimetre-edit-panel',
    templateUrl: './perimetre-edit-panel.component.html',
    styleUrls: ['./perimetre-edit-panel.component.scss'],
})
export class PerimetreEditPanelComponent extends BaseComponent implements OnInit {
    @Input() currentPlanLocalisation: PlanLocalisation;
    @Input() currentStorey: cn_storey;
    @Input() perimetreFormGroup: FormGroup;
    @Input() set typePrestation(val: TypePrestation) {
        this.isHAP = val ? val.startsWith('HAP') : true;
    }

    @Input() readonlyMode: boolean;

    @Output()
    closedEditPanel: EventEmitter<void> = new EventEmitter<void>();
    public isHAP: boolean = false;
    widgetSelectModeEnum: typeof WidgetSelectModeEnum = WidgetSelectModeEnum;

    constructor(
        private readonly matDialog: MatDialog,
        private readonly hapFormService: HapFormService,
        private readonly hapCnMapService: HapCnMapService
    ) {
        super();
    }
    ngOnInit(): void {}

    /**
     * Evenement click sur le bouton valider
     */
    onClickValider() {
        if (this.perimetreFormGroup.valid) {
            this.closedEditPanel.emit();
        }
    }

    /**
     * Evenement click sur le bouton Annuler ou la fermeture de la modale
     */
    onCloseEditPanel() {
        this.closedEditPanel.emit();
    }

    /**
     * Action déclenchée lors du click sur le bouton permettant de choisir le type d'ouvrage
     */
    onClickChooseTypeOuvrage(): void {
        this.matDialog
            .open(OuvrageModalComponent, {
                data: {
                    ouvrageParent: undefined,
                    ouvrageExistant: this.perimetreFormGroup.get('typeOuvrage').value,
                },
            })
            .afterClosed()
            .subscribe((espaceResult) => {
                if (espaceResult && espaceResult.ouvrage) {
                    this.perimetreFormGroup.patchValue({
                        typeOuvrage: espaceResult.ouvrage,
                    });
                }
            });
    }

    /**
     * Déclenché lorsque le nom du périmètre est modifié.
     * Il faut modifier le nom du marker correspondant s'il existe dans le storey
     * @param formGroup
     */
    onChangeNom(formGroup: FormGroup) {
        this.hapCnMapService.changeNameMarker(
            this.currentPlanLocalisation as any,
            this.currentStorey,
            this.hapFormService.findPerimetreByFormGroup(this.currentPlanLocalisation.listePerimetres, formGroup)
        );
    }

    /**
     * Déclenché lorsque le type de délimitation est modifié
     * Modifie la couleur par défaut de la délimitation
     */
    onChangeTypeDelimitation(formGroup: FormGroup) {
        // Modifier la couleur
        let colorHex = '';
        switch (formGroup.get('typeDelimitation').value) {
            case 'Non concerné':
                colorHex = '#6dc911';
                break;
            case 'Autre':
                colorHex = '#0066ff';
                break;
            case 'Concerné':
                colorHex = '#fd7313';
                break;
        }

        // Modifier le nom
        // Todo incrémentation numéro partie
        let nom = `Partie ${'1'} ${formGroup.get('typeDelimitation').value}`;

        formGroup.patchValue({
            nomPerimetre: nom,
            color: colorHex,
        });

        this.onChangeColor(formGroup);
        this.onChangeNom(formGroup);
    }

    /**
     * Déclenché lorsque la couleur du périmètre est modifiée.
     * Il faut modifier la couleur du marker correspondant s'il existe dans le storey
     * @param formGroup
     */
    onChangeColor(formGroup: FormGroup) {
        this.hapCnMapService.changeColorMarker(
            this.currentPlanLocalisation as any,
            this.currentStorey,
            this.hapFormService.findPerimetreByFormGroup(this.currentPlanLocalisation.listePerimetres, formGroup)
        );
    }

    /**
     * Déclenché lorsque l'épaisseur du marker est modifié
     */
    onChangeStrokeWidth(formGroup: FormGroup) {
        this.hapCnMapService.changeStrokeMarker(
            this.currentPlanLocalisation as any,
            this.currentStorey,
            this.hapFormService.findPerimetreByFormGroup(this.currentPlanLocalisation.listePerimetres, formGroup)
        );
    }
}
