import { CnmapImageUtils, dataUrlToBlob } from '@acenv/cnmap-angular-editor-lib';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxImageCompressService } from 'ngx-image-compress';
import { BaseComponent, FileUploaderComponent, NotificationService } from 'src/app/commons-lib';
import { CategorieEquipement } from 'src/app/model/categorie-equipement.model';
import { EditMode, EditModeEnum } from 'src/app/model/edit-mode.model';
import { EtatWorkflow } from 'src/app/model/etat-workflow.model';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { ValidatorUtils } from 'src/app/utils/validator.utils';
import { StringUtils } from 'src/app/utils/string.utils';
import { typesPrestation } from 'src/app/model/type-prestation.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ReferenceApiService } from 'src/app/services/reference-api.service';
import { URL_GESTION_EQUIPEMENTS } from 'src/app/shared/constants/url.constants';
import { combineLatest, forkJoin, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { StateChoiceBoxes } from 'src/app/model/states.model';
import { falseOption, trueOption } from '../../../../../shared/constants/states.constants';
import { ReferenceService } from 'src/app/services/reference.service';
import { ParameterDefinition } from '@acenv/commons-lib';
import { EditParameterDefinitionModalComponent } from '../edit-parameterDefinition-modal/edit-parameterDefinition-modal.component';

@Component({
    selector: 'app-edit-categorie-equipement',
    templateUrl: './edit-categorie-equipement.component.html',
    styleUrls: ['./edit-categorie-equipement.component.scss'],
})
export class EditCategorieEquipementComponent extends BaseComponent implements OnInit, OnDestroy {
    idCategorieEquipement: string;
    categorieEquipementInitial: CategorieEquipement;
    categorieEquipement: CategorieEquipement;
    listeFullCategorieEquipement: CategorieEquipement[];
    listeNomCategorieEquipement: string[] = [];

    formTypeElement: FormGroup;

    mode: EditMode = EditModeEnum.CREATE;

    imgPerso: string;

    typesPrestation = typesPrestation;

    trueOption: StateChoiceBoxes = trueOption;
    falseOption: StateChoiceBoxes = falseOption;
    toolTipOuiNon = ['Oui', 'Non'];
    abstract: boolean = false;

    @ViewChild('fileUploader') fileUploader: FileUploaderComponent;
    editPicture: boolean;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly referenceApiService: ReferenceApiService,
        private readonly referenceService: ReferenceService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly notificationService: NotificationService,
        private readonly imageCompress: NgxImageCompressService,
        private readonly matDialog: MatDialog
    ) {
        super();

        this.formTypeElement = this.formBuilder.group({
            name: [
                '',
                [
                    Validators.required,
                    (control: AbstractControl) => {
                        if (
                            control.value &&
                            ValidatorUtils.checkAlreadyExist(
                                this.formTypeElement.controls.name.value,
                                this.mode !== EditModeEnum.EDIT,
                                this.categorieEquipementInitial?.name,
                                this.listeFullCategorieEquipement.map((ref) => ref.name)
                            )
                        ) {
                            return { erreurNomEquipement: true };
                        }
                        return null;
                    },
                ],
            ],
            abstract: [false],
            codeBim: [
                '',
                [
                    Validators.required,
                    (control: AbstractControl) => {
                        if (control.value && this.codeBimElementExisteDeja()) {
                            return { erreurCodeBimEquipement: true };
                        }
                        return null;
                    },
                ],
            ],
            etatEquipement: [EtatWorkflow.INACTIF, Validators.required],
            description: '',
            parametersDefinitions: [new FormControl([])],
            valeursDefautParametres: [new FormControl([])],
            elementParentName: [''],
            regle: 'true',
            typesPrestationsAssocie: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        this.cnSpinnerService
            .withSpinner(
                combineLatest([this.route.paramMap, this.route.data]).pipe(
                    takeUntil(this.ngUnsubscribe),
                    switchMap(([params, data]) => {
                        this.mode = data.mode;
                        this.idCategorieEquipement = params.get('idCategorieEquipement');
                        if (this.idCategorieEquipement) {
                            return this.referenceApiService.findOneCategorieEquipement(this.idCategorieEquipement);
                        }
                        return of(null);
                    }),
                    switchMap((currentTypeElement) => forkJoin([of(currentTypeElement), this.referenceApiService.findAllCategorieEquipement()]))
                )
            )
            .subscribe(([currentTypeElement, listeCategorieEquipement]) => {
                if (currentTypeElement) {
                    //console.log(currentTypeElement);
                    this.categorieEquipementInitial = currentTypeElement;
                    this.categorieEquipement = currentTypeElement;
                }
                //this.imgPerso = this.categorieEquipement?.imageUrl;
                this.listeFullCategorieEquipement = listeCategorieEquipement.sort((a, b) => a.name.localeCompare(b.name));

                if (this.mode === EditModeEnum.CREATE) {
                    let cat = new CategorieEquipement();
                    cat.parentsIds = [];
                    //cat.imageUrl = this.categorieEquipement?.imageUrl;
                    cat.parametersDefinitions = this.categorieEquipement?.parametersDefinitions;
                    cat.parametersDefaultValues = this.categorieEquipement?.parametersDefaultValues;
                    cat.typesPrestationsAssocie = this.categorieEquipement?.typesPrestationsAssocie;
                    cat.abstract = this.categorieEquipement?.abstract;
                    this.categorieEquipement = cat;
                }

                this.formTypeElement.patchValue(this.categorieEquipement);
                this.formTypeElement.patchValue({
                    elementParentName: this.listeFullCategorieEquipement.find((element) => this.categorieEquipement?.parentsIds[0] == element.id)
                        ?.name,
                });
                this.miseAJourAbstract();

                this.listeNomCategorieEquipement = this.listeFullCategorieEquipement.map((element) => element.name);
                if (this.mode != EditModeEnum.CREATE) {
                    // Initialisation de la liste des équipements parent possible pour la liste déroulante
                    this.listeNomCategorieEquipement = this.listeFullCategorieEquipement
                        .filter((element) => element.id !== this.categorieEquipementInitial?.id)
                        .map((element) => element.name);
                }
            });
    }

    miseAJourAbstract(): void {
        if (this.categorieEquipement == null) {
            this.abstract = false;
        } else if (this.categorieEquipement.abstract == null) {
            this.abstract = false;
        } else {
            this.abstract = !this.categorieEquipement.abstract;
        }
    }

    changeAbstract() {
        this.categorieEquipement.abstract = !this.abstract;
        this.formTypeElement.patchValue({
            abstract: !this.abstract,
        });
        if (this.categorieEquipement.abstract) {
            this.formTypeElement.controls.abstract.clearValidators();
        } else {
            this.formTypeElement.controls.abstract.addValidators(Validators.required);
        }
        this.formTypeElement.controls.abstract.updateValueAndValidity();
    }

    validerCategorieEquipement() {
        this.cnSpinnerService.show('Sauvegarde en cours...');

        // Values
        this.categorieEquipement.name = this.formTypeElement.value.name;
        this.categorieEquipement.codeBim = this.formTypeElement.value.codeBim;
        this.categorieEquipement.description = this.formTypeElement.value.description;
        //this.categorieEquipement.imageUrl = this.imgPerso;
        this.categorieEquipement.etatEquipement = this.formTypeElement.value.etatEquipement;
        this.categorieEquipement.typesPrestationsAssocie = this.formTypeElement.value.typesPrestationsAssocie;
        this.categorieEquipement.parametersDefinitions = this.formTypeElement.value.parametersDefinitions;
        this.categorieEquipement.abstract = this.formTypeElement.value.abstract;
        this.categorieEquipement.valeursDefautParametres = this.categorieEquipement.valeursDefautParametres;

        if (this.mode === EditModeEnum.EDIT) {
            this.referenceApiService.updateCategorieEquipement(this.categorieEquipement).subscribe(
                () => {
                    this.cnSpinnerService.hide();
                    this.notificationService.success("L'équipement a été modifié");
                    // On réinitialise la liste des equipements dans le cache de l'appli
                    this.referenceService.forceReloadCategoriesEquipements();
                    this.back();
                },

                () => this.cnSpinnerService.hide()
            );
        } else {
            this.referenceApiService.createCategorieEquipement(this.categorieEquipement).subscribe(
                () => {
                    this.cnSpinnerService.hide();
                    const message = this.mode === EditModeEnum.CREATE ? "L'équipement a été créé" : "L'équipement a été dupliqué";
                    this.notificationService.success(message);
                    // On réinitialise la liste des equipements dans le cache de l'appli
                    this.referenceService.forceReloadCategoriesEquipements();
                    this.back();
                },
                () => this.cnSpinnerService.hide()
            );
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    back() {
        this.router.navigate([URL_GESTION_EQUIPEMENTS, this.idCategorieEquipement]);
    }

    /**
     * Return true si le codeBim existe déjà et false sinon
     */
    codeBimElementExisteDeja(): boolean {
        const codeBimAControler = this.formTypeElement.controls.codeBim.value;
        if (this.mode === EditModeEnum.EDIT) {
            return (
                this.categorieEquipementInitial.codeBim !== codeBimAControler &&
                this.listeFullCategorieEquipement
                    .map((ref) => StringUtils.toLowerCase(ref.codeBim).trim())
                    .includes(StringUtils.toLowerCase(codeBimAControler).trim())
            );
        } else {
            return this.listeFullCategorieEquipement
                .map((ref) => StringUtils.toLowerCase(ref.codeBim).trim())
                .includes(StringUtils.toLowerCase(codeBimAControler).trim());
        }
    }

    uploadIconeElement(formData: FormData) {
        const file = formData.get('file') as File;
        CnmapImageUtils.getBase64(file).then((data) => {
            this.imageCompress.compressFile(data as string, -1, 100, 80, 120, 120).then((comp) => {
                this.imgPerso = this.verifySizeDocument(comp);
            });
        });
    }

    private verifySizeDocument(currentDocumentUrl: string): string {
        const imageBlob = dataUrlToBlob(currentDocumentUrl);
        if (imageBlob.size > 0.1 * 1048576) {
            this.notificationService.error("L'image est trop volumineuse. Veuillez en utiliser une autre.");
            return null;
        } else {
            return currentDocumentUrl;
        }
    }

    deleteIconeElement() {
        this.imgPerso = '';
    }

    changeElementParent(nomElement: string) {
        // Réinitialisation
        this.categorieEquipement.parentsIds = [];

        const elementSelectionne = this.listeFullCategorieEquipement.find((element) => element.name === nomElement);

        // On récupère les ancètres de l'élementParent et on rajoute l'élément parent sélectionné
        this.categorieEquipement.parentsIds = this.getIdsParent(elementSelectionne.id);
    }

    getIdsParent(idElement: string) {
        var ret = [];
        ret.push(idElement);
        const parentsIds = this.listeFullCategorieEquipement.find((element) => element.id === idElement).parentsIds;
        if (parentsIds.length > 0) {
            ret = ret.concat(parentsIds);
        }
        return ret;
    }

    onClickBtnAddOrEditParametre(param: ParameterDefinition | null) {
        const refDialog = this.matDialog.open(EditParameterDefinitionModalComponent, {
            data: { parameter: param, valuesParameter: this.categorieEquipement.valeursDefautParametres },
        });
        return refDialog.afterClosed().subscribe((result: { param: ParameterDefinition; paramValue: { [codeBim: string]: any } }) => {
            if (result?.param) {
                let indexParam = this.formTypeElement.value.parametersDefinitions.findIndex((p) => p.codeBim == result.param.codeBim);
                if (indexParam >= 0) {
                    this.formTypeElement.value.parametersDefinitions[indexParam] = result.param;
                } else {
                    this.formTypeElement.value.parametersDefinitions.push(result.param);
                }
                this.categorieEquipement.parametersDefinitions = this.formTypeElement.value.parametersDefinitions;

                if (result?.paramValue) {
                    this.formTypeElement.value.valeursDefautParametres[result.param.codeBim] = result.paramValue[result.param.codeBim];
                    this.categorieEquipement.valeursDefautParametres[result.param.codeBim] = result.paramValue[result.param.codeBim];
                }
            }
            //console.log(this.categorieEquipement.parametersDefinitions);
        });
    }

    onClickBtndeleteParametre(param: ParameterDefinition | null) {
        let indexParam = this.formTypeElement.value.parametersDefinitions.findIndex((p) => p == param);
        if (indexParam >= 0) {
            this.formTypeElement.value.parametersDefinitions.splice(indexParam, 1);
            this.categorieEquipement.parametersDefinitions = this.formTypeElement.value.parametersDefinitions;
        }

        delete this.formTypeElement.value.valeursDefautParametres[param.codeBim];
        delete this.categorieEquipement.valeursDefautParametres[param.codeBim];
    }
}
