import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-terre-calcul',
    templateUrl: './terre-calcul.component.html',
    styleUrls: ['./terre-calcul.component.scss'],
})
export class TerreCalculComponent implements OnInit {
    readonly MENU_NAME = 'Calcul correspondance DDR et résistance de terre';

    constructor() {}

    ngOnInit(): void {}
}
