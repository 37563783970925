<div *ngIf="isLoaded && currentIdPerimetreInvestigation" class="zone">
    <mat-sidenav-container *ngIf="!noDescription" class="sidenav-container w-100">
        <mat-sidenav [mode]="'side'" [opened]="true" class="sidebar-button">
            <div class="d-flex flex-column w-100 justify-content-end">
                <mat-button-toggle-group [vertical]="true" (change)="changeViewMode($event)" [value]="viewMode" appearance="legacy">
                    <mat-button-toggle matTooltip="Vue liste" value="list">
                        <mat-icon>list</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle matTooltip="Vue plan" value="plan">
                        <mat-icon>layers</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <mat-divider></mat-divider>
            </div>
        </mat-sidenav>
        <mat-sidenav-content
            style="min-height: 85vh; overflow-y: hidden"
            class="main fullscreen-content"
            [ngClass]="pagesPanelOpened ? 'main' : 'main-fullscreen'"
        >
            <!-- MODE LISTE  -->
            <mat-sidenav-container *ngIf="viewMode === 'list' && !noDescription" [autosize]="true" class="sidenav-container">
                <mat-sidenav [mode]="'side'" [opened]="true" [disableClose]="true" [style.minWidth]="'560px'">
                    <ng-container *ngTemplateOutlet="templateCard"></ng-container>
                </mat-sidenav>
                <mat-sidenav-content class="main" style="height: 85vh" [ngClass]="pagesPanelOpened ? 'main' : 'main-fullscreen'">
                    <ng-container *ngTemplateOutlet="displayList"></ng-container>
                </mat-sidenav-content>
            </mat-sidenav-container>
            <!-- MODE PLAN -->
            <ng-template [ngIf]="viewMode === 'plan' && !noDescription">
                <div class="d-flex no-padding">
                    <div class="w-100">
                        <ng-container *ngTemplateOutlet="displayMap"></ng-container>
                    </div>
                    <div [style.minWidth]="'560px'" style="overflow: auto; height: calc(100vh - 80px)">
                        <ng-container *ngTemplateOutlet="templateCard"></ng-container>
                    </div>
                </div>
            </ng-template>
        </mat-sidenav-content>
    </mat-sidenav-container>

    <ng-template #displayList>
        <app-point-controle-bien-panel
            class="panel"
            [disabled]="indexSurface == undefined"
            [biens]="pdcBiens"
            [volumesIdDispo]="listVolumeId"
            [listVolumeDisabled]="listVolumeDisabled"
            [listVolumeChecked]="listVolumeChecked"
            (panelSubmit)="handleVolumePanelSubmit($event)"
            (panelCancel)="handleVolumePanelCancel()"
        >
        </app-point-controle-bien-panel>
    </ng-template>

    <ng-template #displayMap class="sidebar-map">
        <app-bien-niveau-select
            [biens]="biens"
            [selectedBien]="currentBien.bien.nom"
            [forPlan]="true"
            [selectedNiveau]="currentNiveau"
            (niveauChanged)="changeCurrentStairMap($event)"
        ></app-bien-niveau-select>

        <app-viewer-map-polluant
            *ngIf="building"
            #viewMap
            [building]="building"
            [currentBien]="currentBien"
            [currentNiveau]="currentNiveau"
            [useDiagTool]="true"
            [multiSelection]="true"
            [showControls]="true"
            [height100]="false"
            (toolEvent)="onTouchOnPlan($event)"
            [currentTool]="currentMapTool"
            [referenceToMarker]="elementToLocate"
            [externalSelectedVolumes]="externalSelectedVolumes"
        >
        </app-viewer-map-polluant>
    </ng-template>

    <ng-template #templateCard>
        <div class="d-flex flex-column justify-content-end">
            <div class="content">
                <message-card *ngIf="message">
                    <p>{{ message }}</p>
                </message-card>
                <verification-card
                    *ngIf="typeMesurage === TypePrestationEnum.POLLUANT_VISITE_CHANTIER"
                    [showValidateButton]="typeMesurage === TypePrestationEnum.POLLUANT_VISITE_CHANTIER"
                    [isInEditMode]="isInEditMode"
                    [isValidated]="isValidated"
                    (onCancel)="cancelModification()"
                    (onSave)="saveModification()"
                    (onValidate)="validateTab()"
                    (onEdit)="startModification()"
                    [form]="form"
                >
                    Veuillez vérifier et valider les zones
                </verification-card>
                <div class="d-flex no-padding">
                    <div class="d-flex col-sm-10 align-items-center no-padding">
                        <div class="col-sm-1 no-padding">
                            <button
                                mat-fab
                                color="primary"
                                class="circle-little-button no-padding"
                                (click)="navigationZone('previous')"
                                [disabled]="this.indexZone <= 0"
                                [matTooltip]="previousOrNextZone(-1) | zoneFullnamePipe"
                            >
                                <mat-icon class="no-padding">arrow_back</mat-icon>
                            </button>
                        </div>
                        <div class="col-sm-10">
                            <app-widget-select-search
                                [options]="zonesPerimetre"
                                (modelChange)="changeZoneSelected($event)"
                                [idPerimetre]="currentIdPerimetreInvestigation"
                            >
                            </app-widget-select-search>
                        </div>
                        <div class="d-flex col-sm-1 align-items-center no-padding">
                            <button
                                mat-fab
                                color="primary"
                                class="circle-little-button no-padding"
                                (click)="navigationZone('next')"
                                [disabled]="[0, 1].includes(zonesPerimetre.length) || this.indexZone >= zonesPerimetre.length - 1"
                                [matTooltip]="previousOrNextZone(1) | zoneFullnamePipe"
                            >
                                <mat-icon class="no-padding">arrow_forward</mat-icon>
                            </button>
                        </div>
                    </div>

                    <div class="d-flex col-sm-2 align-items-center">
                        <button
                            mat-flat-button
                            color="primary"
                            class="unfill-light-blue-btn"
                            (click)="addZone()"
                            matTooltip="Ajouter une nouvelle zone"
                        >
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="row justify-content-between col-md-12">
                        <div class="badge-info-grey col-md-10">
                            {{ zoneName ? zoneName : 'Aucune zone séléctionnée' }}
                        </div>
                        <a class="custom-button col-md-2" (click)="editZone()" data-cy="edit-zone-button" matTooltip="Éditer la zone">
                            <mat-icon>edit</mat-icon>
                        </a>
                    </div>
                </div>

                <div class="d-flex row justify-content-between mt-1 col-md-12">
                    <div class="col-md-3">
                        <button
                            mat-flat-button
                            color="primary"
                            class="unfill-light-blue-btn"
                            (click)="addSurface()"
                            *ngIf="isInEditMode"
                            data-cy="ajouter-surface-button"
                        >
                            <div class="small-font d-flex justify-content-between">
                                <mat-icon>add</mat-icon>
                                Ajouter une nouvelle surface
                            </div>
                        </button>
                    </div>
                    <div class="d-flex col-md-8 justify-content-end align-all-center">
                        <div>
                            Surface total de la zone
                            <span class="badge-info-grey-little"> {{ surfaceTotale }}</span>
                        </div>
                        <div>m²</div>
                    </div>
                </div>

                <div class="d-flex row justify-content-between mt-1 col-md-12">
                    <div class="col-md-3">
                        <button mat-flat-button color="primary" class="unfill-light-blue-btn" (click)="ajouterToutesLesSurfaces()">
                            <div class="small-font d-flex justify-content-between">
                                <mat-icon>select_all</mat-icon>
                                Ajouter toutes les surfaces
                            </div>
                        </button>
                    </div>
                    <div class="d-flex align-all-center justify-content-end">
                        <div>
                            P.U. total de la zone
                            <span class="badge-info-grey-little"> {{ totalPu }} </span>
                        </div>
                        <div style="height: auto; width: 34px"></div>
                    </div>
                </div>
                <div *ngIf="formZones.length">
                    <div *ngFor="let surface of formArraySurfaces.controls; let index = index" class="mt-3">
                        <app-surface-piece-unitaire
                            [selected]="indexSurface === index"
                            [surface]="surface"
                            [indexSurface]="index"
                            [biens]="biens"
                            [volumesSurface]="volumesSurfaceSubject"
                            [volumeStoreyLinkObject]="volumeStoreyLinkObject"
                            [formArraySurfaces]="formArraySurfaces"
                            [sommeMesureVolumes]="sommeMesureVolumes"
                            [storeyId]="currentNiveau.storeyId"
                            [cnBuilding]="cnBuilding"
                            (selectSurfaceByIndex)="selectSurfaceByIndex($event)"
                            (removeSurfaceByIndex)="removeSurfaceByIndex($event)"
                            (removeVolumeZone)="removeVolume($event)"
                            (declareSurfaceEvent)="declareSurfaceVolume($event)"
                            (onAddJustification)="addJustificationSurface(surface)"
                        ></app-surface-piece-unitaire>
                        <div
                            *ngIf="!surface.get('commentairesId')?.value?.length && surface.get('listeIdVolume')?.value?.length > 1"
                            class="to-justify all mt-2 mb-3"
                        >
                            Justification requise pour le regroupement des locaux.
                            <button (click)="addJustificationSurface(surface)" mat-icon-button color="justify" class="ml-1">
                                <lib-icon theme="mdi" iconName="comment-alert"></lib-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
