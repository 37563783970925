import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class StaticJsonService {
    constructor(private http: HttpClient) {}

    loadJson(file: string, folder: string = null) {
        if (folder) {
            return this.http.get('../../assets/' + folder + '/' + file + '.json');
        } else {
            return this.http.get('../../assets/' + file + '.json');
        }
    }

    getJson(name: string): Observable<any> {
        switch (name) {
            case 'todo-list-dev':
                return of(this.getTodoList());
            case 'missions-list-dev':
                return of(this.getMissionList());
        }
    }

    private getTodoList(): any {
        return [
            {
                nom: 'MI.H9.L8.6850',
                description: 'Finaliser le rapport',
                date: 'Avant le 10/12/2020',
                delay: false,
                done: false,
            },
            {
                nom: 'MI.H9.L8.6850',
                description: 'Machine plomb à aller chercher',
                date: 'Avant le 10/12/2020',
                delay: false,
                done: true,
            },
            {
                nom: 'MI.H9.L8.6850',
                description: 'Finaliser le rapport',
                date: 'Avant le 10/12/2020',
                delay: true,
                done: false,
            },
            {
                nom: 'MI.H9.L8.6850',
                description: 'Finaliser le rapport',
                date: 'Avant le 10/12/2020',
                delay: false,
                done: false,
            },
        ];
    }

    private getMissionList(): any {
        return [
            {
                identifiant: 'TI.H9.L8.6850',
                ville: 'Toulouse',
                debut: '01/12/2020',
                fin: '30/12/2020',
                done: 30,
                total: 40,
            },
            {
                identifiant: 'TI.H9.L8.6851',
                ville: 'Toulouse',
                debut: '01/12/2020',
                fin: '30/12/2020',
                done: 35,
                total: 40,
            },
            {
                identifiant: 'TI.H9.L8.6852',
                ville: 'Toulouse',
                debut: '01/12/2020',
                fin: '30/12/2020',
                done: 20,
                total: 40,
            },
            {
                identifiant: 'TI.H9.L8.6853',
                ville: 'Toulouse',
                debut: '01/12/2020',
                fin: '30/12/2020',
                done: 10,
                total: 40,
            },
        ];
    }
}
