import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/commons-lib';

@Component({
    selector: 'app-export-svg-subform-page',
    templateUrl: './export-svg-subform-page.component.html',
    styleUrls: ['./export-svg-subform-page.component.scss'],
})
export class ExportSvgSubformPageComponent extends BaseComponent implements OnInit {
    @Input() group: FormGroup;

    @Input() formConf: any = {};

    formats = [{ label: 'A4 paysage', value: 'a4_paysage' }];

    get pageControlsConf() {
        return this.formConf.page.controls;
    }

    constructor() {
        super();
    }

    ngOnInit(): void {}

    compareWith(value1: any, value2: any) {
        return value1 === value2;
    }
}
