/*----------------------------------------------------------------
//----------------------------------------------------------------
//  /!\ ATTENTION : Bien penser à rajouter les nouveaux
//                  type de commentaire dans le backend
//                  dans la class wizydiag.domain.TypeCommentaire
//                  dans le fichier wizydiag.domain.Commentaire.kt
//----------------------------------------------------------------*/

export enum enumTypesCommentaire {
    RECOMMANDATION = 'RECOMMANDATION',
    CONSTATATION_DIVERSE = 'CONSTATATION_DIVERSE',
    CONSTATATION_DIVERSE_GENERALE = 'CONSTATATION_DIVERSE_GENERALE',
    NOTE_PERSONNELLE = 'NOTE_PERSONNELLE',
    JUSTIFICATION_NON_CONFORMITE = 'JUSTIFICATION_NON_CONFORMITE',
    JUSTIFICATION_RESERVE = 'JUSTIFICATION_RESERVE',
    JUSTIFICATION_NON_VISITE = 'JUSTIFICATION_NON_VISITE',
    JUSTIFICATION_ANNULATION_PRESTATION = 'JUSTIFICATION_ANNULATION_PRESTATION',
    JUSTIFICATION_MODIFICATION_TECHNIQUES_ANALYSES_PRELEVEMENT = 'JUSTIFICATION_MODIFICATION_TECHNIQUES_ANALYSES_PRELEVEMENT',
    MOTIF_MODIFICATION_FICHIER = 'MOTIF_MODIFICATION_FICHIER',
    MOTIF_NON_VERIFIABLE = 'MOTIF_NON_VERIFIABLE',
    REMARQUE = 'REMARQUE',
    MOTIF_NON_DEMONTABLE = 'MOTIF_NON_DEMONTABLE',
    MOTIF_NON_RENSEIGNE = 'MOTIF_NON_RENSEIGNE',
    JUSTIFICATION_NON_PRESENCE = 'JUSTIFICATION_NON_PRESENCE',
    JUSTIFICATION_DU_PRELEVEMENT = 'JUSTIFICATION_DU_PRELEVEMENT',
    // À COMPLÉTER AVEC LES DIFFÉRENTS TYPES DE COMMENTAIRES...
}

export type TypeCommentaire =
    /** Recommandation générale pour un diagnostic */
    | enumTypesCommentaire.RECOMMANDATION

    /** Constatation diverse spécifique pour un élément */
    | enumTypesCommentaire.CONSTATATION_DIVERSE

    /** Constatation diverse générale pour un diagnostic */
    | enumTypesCommentaire.CONSTATATION_DIVERSE_GENERALE

    /** Note personnelle */
    | enumTypesCommentaire.NOTE_PERSONNELLE

    /** Justification de non conformité */
    | enumTypesCommentaire.JUSTIFICATION_NON_CONFORMITE

    /** Justification de réserve */
    | enumTypesCommentaire.JUSTIFICATION_RESERVE

    /** Justification de non visite */
    | enumTypesCommentaire.JUSTIFICATION_NON_VISITE

    /** Justification d'annulation de la prestation */
    | enumTypesCommentaire.JUSTIFICATION_ANNULATION_PRESTATION

    /** Justification d'ajout de la prestation */
    | 'JUSTIFICATION_AJOUT_PRESTATION'

    /** Justification de la modification des techniques d'analyses d'un prélèvement */
    | enumTypesCommentaire.JUSTIFICATION_MODIFICATION_TECHNIQUES_ANALYSES_PRELEVEMENT

    /** Motif de modification du fichier */
    | enumTypesCommentaire.MOTIF_MODIFICATION_FICHIER

    /** Justification de la non vérification d'un point de contrôle en électricité */
    | enumTypesCommentaire.MOTIF_NON_VERIFIABLE

    /** Remarques en électricité */
    | enumTypesCommentaire.REMARQUE

    /** Motif non renseigné pour numéro de compteur - électricité */
    | enumTypesCommentaire.MOTIF_NON_RENSEIGNE

    /** Motif non démontable pour les capots des tableaux en électricité */
    | enumTypesCommentaire.MOTIF_NON_DEMONTABLE

    /** justification pour la non présentation d'un document */
    | enumTypesCommentaire.JUSTIFICATION_NON_PRESENCE

    /** justification pour l'ajout d'un prélèvement */
    | enumTypesCommentaire.JUSTIFICATION_DU_PRELEVEMENT;

export const typesCommentaire: TypeCommentaire[] = [
    enumTypesCommentaire.RECOMMANDATION, // uniquement à la fin d'une prestation
    enumTypesCommentaire.CONSTATATION_DIVERSE_GENERALE, // uniquement à la fin d'une prestation
    enumTypesCommentaire.JUSTIFICATION_NON_CONFORMITE,
    enumTypesCommentaire.JUSTIFICATION_RESERVE,
    enumTypesCommentaire.JUSTIFICATION_NON_VISITE,
    enumTypesCommentaire.JUSTIFICATION_ANNULATION_PRESTATION,
    enumTypesCommentaire.JUSTIFICATION_MODIFICATION_TECHNIQUES_ANALYSES_PRELEVEMENT,
    enumTypesCommentaire.JUSTIFICATION_NON_PRESENCE,
    enumTypesCommentaire.JUSTIFICATION_DU_PRELEVEMENT,
    enumTypesCommentaire.MOTIF_MODIFICATION_FICHIER, // que pour les documents
    enumTypesCommentaire.CONSTATATION_DIVERSE,
    enumTypesCommentaire.NOTE_PERSONNELLE,
    enumTypesCommentaire.MOTIF_NON_VERIFIABLE,
    enumTypesCommentaire.REMARQUE,
    enumTypesCommentaire.MOTIF_NON_DEMONTABLE,
    enumTypesCommentaire.MOTIF_NON_RENSEIGNE,
    // À COMPLÉTER AVEC LES DIFFÉRENTS TYPES DE COMMENTAIRES...
  'JUSTIFICATION_AJOUT_PRESTATION'
];

// Liste des types de commentaires à filtrer pour les commentaires généraux dans le prédiag et les diag.
export const typesCommentaireAFiltrer: TypeCommentaire[] = [
    enumTypesCommentaire.RECOMMANDATION,
    enumTypesCommentaire.CONSTATATION_DIVERSE_GENERALE,
    enumTypesCommentaire.JUSTIFICATION_NON_CONFORMITE,
    enumTypesCommentaire.JUSTIFICATION_RESERVE,
    enumTypesCommentaire.JUSTIFICATION_NON_VISITE,
    enumTypesCommentaire.JUSTIFICATION_ANNULATION_PRESTATION,
    enumTypesCommentaire.JUSTIFICATION_MODIFICATION_TECHNIQUES_ANALYSES_PRELEVEMENT,
    enumTypesCommentaire.JUSTIFICATION_NON_PRESENCE,
    enumTypesCommentaire.JUSTIFICATION_DU_PRELEVEMENT,
    enumTypesCommentaire.MOTIF_MODIFICATION_FICHIER,
  'JUSTIFICATION_AJOUT_PRESTATION'
];

export const typesJustification: TypeCommentaire[] = [
    enumTypesCommentaire.JUSTIFICATION_NON_CONFORMITE,
    enumTypesCommentaire.JUSTIFICATION_RESERVE,
    enumTypesCommentaire.JUSTIFICATION_NON_VISITE,
    enumTypesCommentaire.JUSTIFICATION_ANNULATION_PRESTATION,
    enumTypesCommentaire.JUSTIFICATION_MODIFICATION_TECHNIQUES_ANALYSES_PRELEVEMENT,
    enumTypesCommentaire.JUSTIFICATION_NON_PRESENCE,
    enumTypesCommentaire.JUSTIFICATION_DU_PRELEVEMENT,
  'JUSTIFICATION_AJOUT_PRESTATION'
];

/**
 * À partir d'un type de commentaire, renvoie le label associé.
 */
export function typeCommentaireToLabel(typeCommentaire: string | TypeCommentaire): string {
    switch (typeCommentaire) {
        case enumTypesCommentaire.NOTE_PERSONNELLE:
            return 'Note personnelle';

        case enumTypesCommentaire.RECOMMANDATION:
            return 'Recommandation';

        case enumTypesCommentaire.CONSTATATION_DIVERSE:
            return 'Constatation diverse';

        case enumTypesCommentaire.CONSTATATION_DIVERSE_GENERALE:
            return 'Constatation diverse générale';

        case enumTypesCommentaire.JUSTIFICATION_NON_VISITE:
            return 'Justification de non visite';

        case enumTypesCommentaire.JUSTIFICATION_NON_CONFORMITE:
            return 'Justification de non conformité';

        case enumTypesCommentaire.JUSTIFICATION_RESERVE:
            return 'Justification de réserve';

        case enumTypesCommentaire.JUSTIFICATION_ANNULATION_PRESTATION:
            return `Justification d'annulation de la prestation`;

        case 'JUSTIFICATION_AJOUT_PRESTATION':
            return `Justification d'ajout de la prestation`;

        case enumTypesCommentaire.JUSTIFICATION_MODIFICATION_TECHNIQUES_ANALYSES_PRELEVEMENT:
            return `Justification de la modification de l'analyse technique`;

        case enumTypesCommentaire.MOTIF_MODIFICATION_FICHIER:
            return 'Motif de la modification du fichier';

        case enumTypesCommentaire.MOTIF_NON_VERIFIABLE:
            return `Motif non vérifiable`;

        case enumTypesCommentaire.REMARQUE:
            return `Remarque`;

        case enumTypesCommentaire.MOTIF_NON_DEMONTABLE:
            return `Motif non démontable`;

        case enumTypesCommentaire.MOTIF_NON_RENSEIGNE:
            return `Motif non renseigné`;

        case enumTypesCommentaire.JUSTIFICATION_NON_PRESENCE:
            return 'Justificatif de non présentation du document';

        case enumTypesCommentaire.JUSTIFICATION_DU_PRELEVEMENT:
            return 'Justification du prélèvement';
        default:
            return typeCommentaire;
    }
}
