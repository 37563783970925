<div class="d-flex flex-wrap">
    <div *ngFor="let imageFile of imagesFiles">
        <app-reportage-photo-edit-selection-image
            [reportagePhotoImageFile]="imageFile"
            [readonlyMode]="readonlyMode"
            [autoGenerated]="reportagePhoto.autoGenerated"
            (imageSelected)="onSelectImage($event)"
            (legendChanged)="onLegendChanged($event)"
            (autoGeneratedChanged)="onAutoGeneratedChanged($event)"
        ></app-reportage-photo-edit-selection-image>
    </div>
</div>
