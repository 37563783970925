import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlanParam, PlanParamType, SelectItem } from '@acenv/cnmap-angular-editor-lib';
import { cn_svg_param, cn_svg_param_boolean, cn_svg_param_enum, cn_svg_param_number } from '@acenv/cnmap-editor';
import { MatSelectChange } from '@angular/material/select';

@Component({
    selector: 'app-export-print-svg-param',
    templateUrl: './export-print-svg-param.component.html',
    styleUrls: ['./export-print-svg-param.component.scss'],
})
export class ExportPrintSvgParamComponent implements OnInit {
    /** Définition du paramètre (avec sa valeur par défaut) */
    @Input() svgParam: cn_svg_param;

    /** Valeur actuelle du paramètre pour la page */
    @Input() currentValue: any;

    @Output() paramChange = new EventEmitter<PlanParam>();

    paramType: PlanParamType;
    label: string;
    minValue: number;
    maxValue: number;
    stepValue: number;
    items: SelectItem<number>[];

    /** Valeur actuelle pour une checkbox */
    checked: boolean;

    /** Valeur actuelle pour un nombre */
    numberValue: number;

    /** Valeur actuelle pour une liste de choix */
    selected: number;

    ngOnInit(): void {
        this.paramType = null;
        this.label = '';
        this.checked = false;
        this.numberValue = 0;
        if (this.svgParam) {
            this.label = this.svgParam.label;
            if (this.svgParam instanceof cn_svg_param_boolean) {
                this.setupCheckbox();
            } else if (this.svgParam instanceof cn_svg_param_enum) {
                this.setupSelect();
            } else if (this.svgParam instanceof cn_svg_param_number) {
                this.setupInputNumber();
            }
        }
    }

    onCheckboxChange(event: any) {
        this.paramChange.emit(new PlanParam(this.svgParam.code, event.checked));
    }

    onNumberChange() {
        this.paramChange.emit(new PlanParam(this.svgParam.code, this.numberValue));
    }

    onSelectChange(event: MatSelectChange) {
        this.paramChange.emit(new PlanParam(this.svgParam.code, event.value));
    }

    private setupCheckbox() {
        this.paramType = 'checkbox';
        const svgParamBoolean = this.svgParam as cn_svg_param_boolean;
        if (this.currentValue != null) {
            this.checked = this.currentValue;
        } else if (svgParamBoolean.value != null) {
            this.checked = svgParamBoolean.value;
        } else {
            this.checked = false;
        }
    }

    private setupSelect() {
        this.paramType = 'select';
        const svgParamSelect = this.svgParam as cn_svg_param_enum;
        this.items = [];
        svgParamSelect.labels.forEach((value, index) => {
            this.items.push({ label: value, value: index });
        });
        if (this.currentValue != null) {
            this.selected = this.currentValue;
        } else if (svgParamSelect.value != null) {
            this.selected = svgParamSelect.value;
        }
    }

    private setupInputNumber() {
        this.paramType = 'inputnumber';
        const svgParamNumber = this.svgParam as cn_svg_param_number;
        this.stepValue = 0.1;
        if (svgParamNumber.unit) {
            this.label = this.label + ' (' + svgParamNumber.unit + ')';
        }
        this.minValue = 0;
        if (svgParamNumber.min_value != null && !isNaN(svgParamNumber.min_value)) {
            this.minValue = svgParamNumber.min_value;
        }
        this.maxValue = Infinity;
        if (svgParamNumber.max_value != null && !isNaN(svgParamNumber.max_value)) {
            this.maxValue = svgParamNumber.max_value;
        }
        if (this.currentValue != null) {
            this.numberValue = this.currentValue;
        } else if (svgParamNumber.value != null) {
            this.numberValue = svgParamNumber.value;
        } else {
            this.numberValue = 0;
        }
    }
}
