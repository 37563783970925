<h1 mat-dialog-title class="primary d-flex justify-content-between">
    <span> Selectionner un niveau destination </span>
    <button mat-button (click)="onClickClose()">
        <lib-icon>close</lib-icon>
    </button>
</h1>

<div mat-dialog-content [formGroup]="form">
    <mat-form-field appearance="fill">
        <mat-label>Choix du niveau</mat-label>
        <mat-select formControlName="selectedNiveauControl">
            <mat-option [value]="" class="font-weight-lighter font-italic">Aucune</mat-option>
            <ng-container *ngFor="let niveau of listNiveau">
                <mat-option [value]="niveau">
                    {{ niveau.nom }}
                </mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-button type="button" color="primary" (click)="onClickClose()">
        <lib-icon>close</lib-icon>
        <span>{{ 'Annuler' | uppercase }}</span>
    </button>
    <button
        mat-raised-button
        color="accent"
        [disabled]="!form.valid"
        (click)="onClickConfirm()"
        data-cy="confirmer-ajouter-niveau-button"
    >
        <lib-icon>check</lib-icon>
        <span>{{ 'Confirmer' | uppercase }}</span>
    </button>
</div>
