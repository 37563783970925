import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ReportagePhotoImageFile } from '../../../../../../model/diagnostic.model';
import { ConfirmationService } from 'src/app/commons-lib';

@Component({
    selector: 'app-reportage-photo-edit-selection-image',
    templateUrl: './reportage-photo-edit-selection-image.component.html',
    styleUrls: ['./reportage-photo-edit-selection-image.component.scss'],
})
export class ReportagePhotoEditSelectionImageComponent {
    @Input() reportagePhotoImageFile: ReportagePhotoImageFile;
    @Input() readonlyMode = false;
    @Input() autoGenerated: boolean;

    @Output() imageSelected = new EventEmitter<ReportagePhotoImageFile>();
    @Output() legendChanged = new EventEmitter<ReportagePhotoImageFile>();
    @Output() autoGeneratedChanged = new EventEmitter<boolean>();

    constructor(private readonly confirmationService: ConfirmationService) {}

    /**
     * Action réalisée lorsque l'utilisateur clique sur l'image
     */
    onClickSelectImage(): void {
        if (!this.readonlyMode) {
            if (this.autoGenerated) {
                this.confirmationService.confirmWarn('Ce reportage ne sera plus mis à jour automatiquement si vous le modifiez manuellement.', () => {
                    this.autoGeneratedChanged.emit(false);
                    this.reportagePhotoImageFile.selected = !this.reportagePhotoImageFile.selected;
                    this.imageSelected.emit(this.reportagePhotoImageFile);
                });
            } else {
                this.reportagePhotoImageFile.selected = !this.reportagePhotoImageFile.selected;
                this.imageSelected.emit(this.reportagePhotoImageFile);
            }
        }
    }

    onLegendChanged(event: any) {
        if (this.autoGenerated) {
            this.confirmationService.confirmWarn('Ce reportage ne sera plus mis à jour automatiquement si vous le modifiez manuellement.', () => {
                this.autoGeneratedChanged.emit(false);
                this.legendChanged.emit(this.reportagePhotoImageFile);
            });
        } else {
            this.legendChanged.emit(this.reportagePhotoImageFile);
        }
    }
}
