import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent, NotificationService } from 'src/app/commons-lib';
import { combineLatest, forkJoin, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { EditMode } from 'src/app/model/edit-mode.model';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { ReferenceApiService } from 'src/app/services/reference-api.service';
import { ReferenceService } from 'src/app/services/reference.service';
import { URL_GESTION_TYPES_OUVRAGE } from 'src/app/shared/constants/url.constants';
import { ValidatorUtils } from 'src/app/utils/validator.utils';
import { TypeOuvrage } from '../../../../../model/type-ouvrage.model';

@Component({
    selector: 'app-creation-types-ouvrage',
    templateUrl: './creation-types-ouvrage.component.html',
    styleUrls: ['./creation-types-ouvrage.component.scss'],
})
export class CreationTypesOuvrageComponent extends BaseComponent implements OnInit, OnDestroy {
    idTypeOuvrage: string;
    typeOuvrageInitial: TypeOuvrage = new TypeOuvrage();
    typeOuvrage: TypeOuvrage = new TypeOuvrage();

    listeFullTypeOuvrage: TypeOuvrage[] = [];
    listeFullTypeOuvrageParent: TypeOuvrage[] = [];

    formTemplateValeursParametres: any[] = [];

    formTypeOuvrage: FormGroup;

    mode: EditMode = 'CREATE';
    isReadOnly = false;

    isTypeOuvrageParentInitial = false;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly referenceApiService: ReferenceApiService,
        private readonly referenceService: ReferenceService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly notificationService: NotificationService
    ) {
        super();
    }

    ngOnInit(): void {
        this.formTypeOuvrage = this.formBuilder.group({
            name: ['', [Validators.required], { updateOn: 'blur' }],
            parentsOuvrage: ['', { updateOn: 'blur' }],
        });

        this.cnSpinnerService
            .withSpinner(
                combineLatest([this.route.paramMap, this.route.data]).pipe(
                    takeUntil(this.ngUnsubscribe),
                    switchMap(([params, data]) => {
                        this.idTypeOuvrage = params.get('idTypeOuvrage');
                        if (this.idTypeOuvrage) {
                            this.mode = data.duplicate ? 'DUPLICATE' : 'EDIT';
                            this.isReadOnly = data.consulter;
                            return this.referenceApiService.findOneTypeOuvrage(this.idTypeOuvrage);
                        }
                        return of(null);
                    }),
                    switchMap((currentTypeElement) => forkJoin([of(currentTypeElement), this.referenceService.forceReloadTypesOuvrage()]))
                )
            )
            .subscribe(([currentTypeElement, listeTypeOuvrage]) => {
                if (currentTypeElement) {
                    this.typeOuvrageInitial = JSON.parse(JSON.stringify(currentTypeElement));
                    this.typeOuvrage = currentTypeElement;
                }
                this.listeFullTypeOuvrage = listeTypeOuvrage;

                this.setAsyncValidatorsCustom();

                if (this.mode !== 'CREATE') {
                    this.isTypeOuvrageParentInitial = true;

                    let name = '';
                    const parentsOuvrage =
                        this.typeOuvrage.parentsIds && this.typeOuvrage.parentsIds.length > 0
                            ? this.listeFullTypeOuvrage.find((it) => it.id === this.typeOuvrage.parentsIds[0])
                            : null;
                    if (this.mode === 'EDIT') {
                        name = this.typeOuvrage.name;
                    } else if (this.mode === 'DUPLICATE') {
                        this.typeOuvrage.id = null;
                    }
                    this.formTypeOuvrage.patchValue({
                        name: name,
                        parentsOuvrage: parentsOuvrage,
                    });

                    this.formTypeOuvrage.updateValueAndValidity();
                }

                // Initialisation de la liste des équipements parent possible pour la liste déroulante
                this.listeFullTypeOuvrageParent = this.listeFullTypeOuvrage.filter(
                    (element) => element.id !== this.typeOuvrage.id && element.parentsIds.length === 0
                );
            });
    }

    setAsyncValidatorsCustom() {
        this.formTypeOuvrage
            .get('name')
            .setAsyncValidators(
                ValidatorUtils.uniciteTypeOuvrageValidator(
                    this.formTypeOuvrage.get('name'),
                    this.formTypeOuvrage.get('parentsOuvrage'),
                    this.referenceApiService,
                    this.mode !== 'EDIT' ? null : this.typeOuvrageInitial.id
                )
            );
        this.formTypeOuvrage
            .get('parentsOuvrage')
            .setAsyncValidators(
                ValidatorUtils.uniciteTypeOuvrageValidator(
                    this.formTypeOuvrage.get('name'),
                    this.formTypeOuvrage.get('parentsOuvrage'),
                    this.referenceApiService,
                    this.mode === 'EDIT' ? this.typeOuvrageInitial.id : null
                )
            );

        // on marque les contrôles "dirty" pour que les erreurs s'appliquent
        // sur les champs sans avoir à sélectionner les différents champs
        this.formTypeOuvrage.get('name').markAsTouched();
        this.formTypeOuvrage.get('parentsOuvrage').markAsTouched();

        // on met à jour l'état du formulaire pour appliquer les nouveaux Validators ajoutés au formulaire
        this.formTypeOuvrage.updateValueAndValidity();
    }

    validerTypeOuvrage() {
        this.cnSpinnerService.show('Sauvegarde en cours...');

        // Values
        this.typeOuvrage.name = this.formTypeOuvrage.value.name;
        this.typeOuvrage.parentsIds = this.formTypeOuvrage.value.parentsOuvrage?.id ? [this.formTypeOuvrage.value.parentsOuvrage?.id] : [];

        if (this.mode === 'EDIT') {
            this.referenceApiService.updateTypeOuvrage(this.typeOuvrage).subscribe(
                () => {
                    this.cnSpinnerService.hide();
                    this.notificationService.success("Le type d'ouvrage a été modifié");
                    // On réinitialise la liste des types d'ouvrage dans le cache de l'appli
                    this.referenceApiService.buildTypesOuvrage$();
                    this.back();
                },
                () => this.cnSpinnerService.hide()
            );
        } else {
            this.referenceApiService.createTypeOuvrage(this.typeOuvrage).subscribe(
                () => {
                    this.cnSpinnerService.hide();
                    const message = this.mode === 'CREATE' ? "Le type d'ouvrage a été créé" : "Le type d'ouvrage a été dupliqué";
                    this.notificationService.success(message);
                    // On réinitialise la liste des types d'ouvrage dans le cache de l'appli
                    this.referenceApiService.buildTypesOuvrage$();
                    this.back();
                },
                () => this.cnSpinnerService.hide()
            );
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    back() {
        this.router.navigate([URL_GESTION_TYPES_OUVRAGE]);
    }
}
