import { Injectable } from '@angular/core';
import { defer, Observable, of, throwError } from 'rxjs';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { HttpErrorInterceptor, isServerUnavailableError } from 'src/app/commons-lib';
import { catchError, delay, map, retryWhen, take, tap } from 'rxjs/operators';

/**
 * Service pour le ping du serveur.
 * NB : On utilise HttpBackend au lieu de HttpClient ici, pour ne pas passer par les intercepteurs (donc on bypasse l'intercepteur {@link HttpErrorInterceptor}).
 *      Le service appelant doit donc gérer lui-même les erreurs HTTP.
 */
@Injectable({
    providedIn: 'root',
})
export class PingService {
    private http: HttpClient;

    public resourceUrl = environment.apiUrl + '/ping';

    constructor(private httpBackend: HttpBackend) {
        this.http = new HttpClient(httpBackend);
    }

    /**
     * Teste si serveur API est disponible et renvoie un boolean (ou null si erreur inconnue).
     */
    checkOnline(): Observable<boolean | null> {
        return defer(() => this.ping()).pipe(
            //tap(() => console.log('Basculement en mode en ligne')),
            map(() => true),
            catchError((err) => {
                if (isServerUnavailableError(err)) {
                    //console.log('Pas de connexion serveur');
                    //console.log('Basculement en mode hors-ligne');
                    return of(false);
                } else {
                    console.log('Erreur checkOnline', err);
                    return of(null);
                }
            })
        );
    }

    /**
     * Renvoie un observable si l'API est dispo (en ligne), sinon une erreur.
     * @param maxWaitSeconds le temps maximal à attendre
     * @param retryIntervalSeconds le temps entre 2 tentatives de ping
     */
    whenOnline(maxWaitSeconds: number, retryIntervalSeconds: number) {
        const nbRetries = Math.max(0, maxWaitSeconds / retryIntervalSeconds - 1);
        let retryIndex = 0;
        return defer(() => this.ping()).pipe(
            catchError((err) => {
                if (isServerUnavailableError(err)) {
                    console.log('synchronisation des données hors-ligne impossible (pas de connexion)');
                    return throwError(err);
                } else {
                    return of(null);
                }
            }),
            retryWhen((errors) =>
                errors.pipe(
                    tap(() => retryIndex++),
                    tap(() => console.log(`synchronisation des données hors-ligne : nouvelle tentative dans ${retryIntervalSeconds} secondes`)),
                    delay(retryIntervalSeconds * 1000),
                    take(nbRetries),
                    tap(() => console.log(`synchronisation des données hors-ligne : nouvelle tentative ${retryIndex}/${nbRetries}...`))
                )
            ),
            catchError((err) => {
                console.log('synchronisation des données hors-ligne : fin des tentatives');
                return throwError(err);
            })
        );
    }

    /**
     * Appelle la ressource '/ping' sur l'API afin de savoir si le backend est dispo.
     */
    private ping(): Observable<any> {
        return this.http.get(this.resourceUrl, { responseType: 'text' });
    }
}
