import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-point-controle-volume-item',
    templateUrl: './point-controle-volume-item.component.html',
    styleUrls: ['./point-controle-volume-item.component.scss'],
})
export class PointControleVolumeItemComponent implements OnInit {
    @Input()
    checked = false;

    @Input()
    disabled = false;

    @Input()
    visible = false;

    @Input()
    label = '';

    @Output()
    change = new EventEmitter<boolean>();

    constructor() {}

    ngOnInit(): void {}

    selected() {
        if (!this.disabled) {
            this.checked = !this.checked;
            this.change.emit(this.checked);
        }
    }
}
