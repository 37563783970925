import { Pipe, PipeTransform } from '@angular/core';
import { TypeLivrable } from '../model/rapport.model';

export const typeLivrableMapping: { [key in TypeLivrable]: string } = {
    BON_DE_COMMANDE_LABO: 'Bon de commande laboratoire',
    RAPPORT: 'Rapport',
    SYNTHESE: 'Synthèse',
};

@Pipe({
    name: 'typeLivrable',
})
export class TypeLivrablePipe implements PipeTransform {
    transform(value: TypeLivrable): unknown {
        return typeLivrableMapping[value];
    }
}
