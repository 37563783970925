import { ValidatorFn } from '@angular/forms';
import { Subject } from 'rxjs';

export class FormContext {
    /**
     * Champ du data modèle à assossier au FormControl
     */
    field: string;

    /**
     * Objet data modèle
     */
    data?: any;

    /**
     * Boolean indiquant si le data modèle doit être automatiquement mis à jour lors du changement
     * de valeur dans le formulaire.<br/>
     * Facultatif, valeur par défaut à true
     */
    autoUpdate? = true;

    /**
     * Liste des validateurs à associer au formulaire
     */
    validators?: ValidatorFn | ValidatorFn[];

    /**
     * Attribut ngUnsubscribe du composant contenant le formulaire afin de gérer l'unsubscribe du valueChanges
     * lors de la destruction du composant
     */
    ngUnsubscribe?: Subject<void>;

    /**
     * Force le FormControl a être marqué comme modifié afin de rendre les champ en erreur avant toute action
     * utilisateur
     */
    markAsTouched = true;

    constructor(
        field: string,
        data?: any,
        validators?: ValidatorFn | ValidatorFn[],
        ngUnsubscribe?: Subject<void>,
        autoUpdate?: boolean,
        markAsTouched?: boolean
    ) {
        this.field = field;
        this.data = data;
        this.validators = validators;
        this.ngUnsubscribe = ngUnsubscribe;

        if (autoUpdate !== undefined) {
            this.autoUpdate = autoUpdate;
        }
        if (markAsTouched !== undefined) {
            this.markAsTouched = markAsTouched;
        }
    }
}
