<div class="container container-page">
    <div class="preview-title d-flex justify-content-center">
        <span *ngIf="titreReportagePhoto" class="pb-3">{{ titreReportagePhoto }} </span>
    </div>

    <div class="row">
        <div
            *ngFor="let imageFilePage of imagesFilesPage"
            class="col-6 pb-2 d-flex justify-content-center"
            [ngClass]="!imageFilePage?.fileContent ? 'container-icon-no-image' : ''"
        >
            <div class="w-100">
                <div class="d-flex flex-grow-1">
                    <img *ngIf="imageFilePage.fileContent" [src]="imageFilePage.fileContent" alt="image" class="img-fluid img-size" />
                </div>
                <div class="legend"><br />{{ getReference(imageFilePage) }}</div>
                <div class="legend"><br />{{ getAnomalie(imageFilePage) }}</div>
                <div class="legend">{{ imageFilePage.legende }}</div>
                <div class="legend"><br />{{ getLocalisation(imageFilePage) }}</div>

                <mat-icon *ngIf="!imageFilePage.fileContent" class="size-icon-no-filecontent">no_photography</mat-icon>
            </div>
        </div>
    </div>
</div>
