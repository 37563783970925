import { Pipe, PipeTransform } from '@angular/core';
import { ExigenceDescription, exigenceDescriptionToLabel } from '../model/intervention.model';

/**
 * Affiche le label de l'exigence de description d'une intervention
 */
@Pipe({
    name: 'exigenceDescription',
})
export class ExigenceDescriptionPipe implements PipeTransform {
    constructor() {}

    transform(exigenceDescription: ExigenceDescription): string {
        return exigenceDescriptionToLabel(exigenceDescription);
    }
}
