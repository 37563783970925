<div [formGroup]="group" class="formgroup-container">
    <div [formGroup]="group.get('general')" *ngIf="planGeneralConf?.displayed">
        <h4 class="my-2">Général</h4>
        <div *ngIf="planGeneralConf?.controls?.scale?.displayed">
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Echelle</mat-label>
                <input
                    matInput
                    formControlName="scale"
                    type="number"
                    min="0"
                    max="200"
                    [readonly]="group?.get('general')?.get('fixedScale')?.value"
                />
            </mat-form-field>
        </div>
        <div *ngIf="planGeneralConf?.controls?.resolution?.displayed">
            <mat-form-field appearance="fill">
                <mat-label>Résolution (ppp)</mat-label>
                <mat-select formControlName="resolution">
                    <mat-option *ngFor="let resolution of resolutions" [value]="resolution.value">
                        {{ resolution.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="planGeneralConf?.controls?.fixedScale?.displayed">
            <mat-checkbox formControlName="fixedScale">Bloquer l'échelle</mat-checkbox>
        </div>

        <div *ngIf="planGeneralConf?.controls?.fixedPosition?.displayed">
            <mat-checkbox formControlName="fixedPosition">Centrer la carte</mat-checkbox>
        </div>
        <div>
            <button
                mat-button
                color="primary"
                type="button"
                (click)="onClickCenterView()"
                [disabled]="group.get('general').get('fixedPosition').value || group.get('general').get('fixedScale').value"
            >
                <mat-icon>center_focus_strong</mat-icon>
                <span>Adapter à la page</span>
            </button>
        </div>
    </div>

    <div [formGroup]="group.get('display')" *ngIf="planDisplayConf?.displayed">
        <h4 class="my-2">Affichage</h4>
        <mat-form-field appearance="fill" *ngIf="planDisplayConf?.controls?.render?.displayed">
            <mat-label>Rendu</mat-label>
            <mat-select formControlName="render">
                <mat-option *ngFor="let render of renders" [value]="render.value">{{ render.label }}</mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="planDisplayConf?.controls?.showGrid?.displayed">
            <mat-checkbox formControlName="showGrid">Grille</mat-checkbox>
        </div>

        <div *ngIf="planDisplayConf?.controls?.showScale?.displayed">
            <mat-checkbox formControlName="showScale">Echelle</mat-checkbox>
        </div>
        <div *ngIf="planDisplayConf?.controls?.showBackground?.displayed">
            <mat-checkbox formControlName="showBackground">Fond de carte</mat-checkbox>
        </div>
        <div *ngIf="planDisplayConf?.controls?.showCompass?.displayed">
            <mat-checkbox formControlName="showCompass">Boussole</mat-checkbox>
        </div>
    </div>
    <div [formGroup]="group.get('elements')" *ngIf="planElementsConf?.displayed">
        <h4 class="my-2">Eléments</h4>
        <div *ngIf="planElementsConf?.controls?.showSpaces?.displayed">
            <mat-checkbox formControlName="showSpaces">Pièces</mat-checkbox>
        </div>
        <div *ngIf="planElementsConf?.controls?.showOuterWalls?.displayed">
            <mat-checkbox formControlName="showOuterWalls">Parois extérieurs</mat-checkbox>
        </div>
        <div *ngIf="planElementsConf?.controls?.showInnerWalls?.displayed">
            <mat-checkbox formControlName="showInnerWalls">Parois intérieurs</mat-checkbox>
        </div>
        <div *ngIf="planElementsConf?.controls?.showBalconies?.displayed">
            <mat-checkbox formControlName="showBalconies">Balcons</mat-checkbox>
        </div>
        <div *ngIf="planElementsConf?.controls?.showWindows?.displayed">
            <mat-checkbox formControlName="showWindows">Fenêtres</mat-checkbox>
        </div>
        <div *ngIf="planElementsConf?.controls?.showDoors?.displayed">
            <mat-checkbox formControlName="showDoors">Portes</mat-checkbox>
        </div>
        <div *ngIf="planElementsConf?.controls?.showStairs?.displayed">
            <mat-checkbox formControlName="showStairs">Escaliers</mat-checkbox>
        </div>
        <div *ngIf="planElementsConf?.controls?.showSlabOpenings?.displayed">
            <mat-checkbox formControlName="showSlabOpenings">Trémies</mat-checkbox>
        </div>
        <div *ngIf="planElementsConf?.controls?.showBeams?.displayed">
            <mat-checkbox formControlName="showBeams">Poutres</mat-checkbox>
        </div>
        <div *ngIf="planElementsConf?.controls?.showColumns?.displayed">
            <mat-checkbox formControlName="showColumns">Colonnes</mat-checkbox>
        </div>
        <div *ngIf="planElementsConf?.controls?.showPipes?.displayed">
            <mat-checkbox formControlName="showPipes">Conduits</mat-checkbox>
        </div>
        <div *ngIf="planElementsConf?.controls?.showObjectsShape?.displayed">
            <mat-checkbox formControlName="showObjectsShape">Objets (forme)</mat-checkbox>
        </div>
        <div *ngIf="planElementsConf?.controls?.showObjectsIcon?.displayed">
            <mat-checkbox formControlName="showObjectsIcon">Objets (icône)</mat-checkbox>
        </div>
    </div>

    <div [formGroup]="group.get('text')" *ngIf="planTextConf?.displayed">
        <h4 class="my-2">Texte</h4>
        <div *ngIf="planTextConf?.controls?.showSpaceLabel?.displayed">
            <mat-checkbox formControlName="showSpaceLabel">Étiquettes des pièces</mat-checkbox>
        </div>
        <div *ngIf="planTextConf?.controls?.showSpaceArea?.displayed && group.get('text').get('showSpaceLabel')?.value === true">
            <mat-checkbox formControlName="showSpaceArea">Surface des pièces</mat-checkbox>
        </div>
        <div
            *ngIf="
                planTextConf?.controls?.showVisitLock?.displayed &&
                group.get('text').get('showSpaceLabel')?.value === true
            "
        >
            <mat-checkbox formControlName="showVisitLock">Indicateur de visite</mat-checkbox>
        </div>
        <div *ngIf="planTextConf?.controls?.showMarkers?.displayed">
            <mat-checkbox formControlName="showMarkers">Annotations</mat-checkbox>
        </div>
        <div *ngIf="planTextConf?.controls?.showNumerotation?.displayed">
            <mat-checkbox formControlName="showNumerotation">Numérotations des murs</mat-checkbox>
        </div>
        <div *ngIf="planTextConf?.controls?.showLegend?.displayed">
            <mat-checkbox formControlName="showLegend">Légende</mat-checkbox>
        </div>
    </div>

    <div [formGroup]="group.get('cotes')" *ngIf="planCotesConf?.displayed">
        <h4 class="my-2">Côtes</h4>
        <div>
            <div *ngIf="planCotesConf?.controls?.showOuterMeasures?.displayed">
                <mat-checkbox formControlName="showOuterMeasures">Exterieures</mat-checkbox>
            </div>
            <div *ngIf="planCotesConf?.controls?.showInnerMeasures?.displayed">
                <mat-checkbox formControlName="showInnerMeasures">Intérieures</mat-checkbox>
            </div>
            <div *ngIf="planCotesConf?.controls?.showOpeningMeasures?.displayed">
                <mat-checkbox formControlName="showOpeningMeasures">Baies</mat-checkbox>
            </div>
        </div>
    </div>
</div>
