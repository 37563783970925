import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-badge-select',
    templateUrl: './badge-select.component.html',
    styleUrls: ['./badge-select.component.scss'],
})
export class BadgeSelectComponent implements OnInit {
    @Input()
    isChecked = false;

    @Input()
    color: string;

    @Input()
    outlinedWhenUnchecked = false;

    @Output()
    clicked = new EventEmitter<boolean>();

    constructor() {}

    ngOnInit(): void {}
}
