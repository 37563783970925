<mat-card class="validate-card content-card p-0">
    <mat-card-content class="d-flex justify-content-between h-100">
        <div class="d-flex align-items-center flex-grow-1 p-2" [ngClass]="isValid ? 'data-valid' : 'warning'">
            <span *ngIf="!isValid">
                <ng-content select="[text-not-valid]"></ng-content>
            </span>
            <span *ngIf="isValid">
                <ng-content select="[text-valid]"></ng-content>
            </span>
        </div>
        <div class="buttons d-flex align-items-center">
            <button
                *ngIf="isValid"
                [disabled]="disabled"
                mat-raised-button
                [ngClass]="!disabled ? 'mat-orange' : ''"
                class="p-2"
                (click)="onClickUpdate()"
            >
                <lib-icon>edit</lib-icon>
                {{ 'Modifier' | uppercase }}
            </button>
            <button
                *ngIf="!isValid"
                [disabled]="disabled"
                mat-raised-button
                [ngClass]="!disabled ? 'mat-success' : ''"
                class="p-2"
                (click)="onClickValidate()"
            >
                <lib-icon>check</lib-icon>
                {{ 'Valider' | uppercase }}
            </button>
        </div>
    </mat-card-content>
</mat-card>
