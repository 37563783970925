<div class="markdown-content pb-5">
    <div class="block-title p-2 pl-5 my-3 d-inline-block">{{ contexte.titre }}</div>

    <div class="px-5 mt-2 block-markdown text-justify">
        <ng-container *ngFor="let zone of contexte.zones">
            <span class="subtittle">{{ zone.nomZone }}</span>
            <table class="tab w-100">
                <thead class="greenBgColor">
                    <tr>
                        <th>Rappel de l'objectif de mesurage</th>
                        <th>Contexte réglementaire</th>
                        <th>Objectif de comparaison analytique</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let obj of zone.objectifMesurage">
                        <tr>
                            <td>{{ obj.label }}</td>
                            <td>{{ obj.texteReglementaire }}</td>
                            <td>{{ obj.sensibiliteAnalytiqueAttendue }}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <br />
            <table class="tab w-100">
                <thead class="greenBgColor">
                    <tr>
                        <th rowspan="2">MPCA concerné (type d'amiante)</th>
                        <th rowspan="2">Etat de dégradation</th>
                        <th rowspan="2">Protection & étanchéïté</th>
                        <th colspan="2">Exposition du MPCA</th>
                    </tr>
                    <tr>
                        <th>Chocs & vibrations</th>
                        <th>Circulation d'air</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let mpca of zone.listeMpca">
                        <tr>
                            <td>{{ mpca.general.materiaux.nom }}</td>
                            <td>{{ mpca.general.etatDegradation }}</td>
                            <td>{{ mpca.protectionExposition.type }}</td>
                            <td>{{ mpca.protectionExposition.chocVibration }}</td>
                            <td>{{ mpca.protectionExposition.circulationAir }}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <br />
            <table class="tab w-100">
                <thead class="greenBgColor">
                    <tr>
                        <th colspan="4">Description du ou des locaux concernés</th>
                    </tr>
                    <tr>
                        <th>Environnement</th>
                        <th>Type de bâtiment</th>
                        <th>Ventilation</th>
                        <th>Fréquentation</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ zone.environnement }}</td>
                        <td>{{ zone.typeBatiment }}</td>
                        <td>{{ zone.ventilation }}</td>
                        <td>{{ zone.frequentation }}</td>
                    </tr>
                </tbody>
            </table>
            <br />
            <table class="tab w-100">
                <thead class="greenBgColor">
                    <tr>
                        <th rowspan="2">Désignation de l'espace (niveau)</th>
                        <th rowspan="2">Type de surface</th>
                        <th colspan="2">Caractéristiques</th>
                        <th rowspan="2">Nombre de PU</th>
                    </tr>
                    <tr>
                        <th>Surface</th>
                        <th>Longueur max ou hauteur & longueur si interface</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let surface of zone.surfacesDetaillees">
                        <tr *ngFor="let volume of surface.listeVolumes">
                            <td>{{ volume.nom }} ({{ volume.niveau }})</td>
                            <td>{{ zone.typeBatiment }}</td>
                            <td>{{ surface.superficie }}</td>
                            <td>
                                <ng-container [ngSwitch]="surface.typeSurface">
                                    <ng-container *ngSwitchCase="'LONGUEUR_MAX'">Longueur max : {{ surface.surfaceCalcul }}</ng-container>
                                    <ng-container *ngSwitchCase="'SURFACE_INTERFACE'"
                                        >Hauteur : {{ surface.hauteurInterface }} m, Longueur : {{ surface.longueurInterface }} m</ng-container
                                    >
                                    <ng-container *ngSwitchDefault>{{ surface.superficie < 15 ? '< 15' : surface.superficie }} m</ng-container>
                                </ng-container>
                            </td>
                            <td>{{ volume.puNb }}</td>
                        </tr>
                    </ng-container>
                </tbody>
                <tfoot>
                    <tr>
                        <th class="greenBgColor" colspan="4">Nombre total de PU</th>
                        <td>{{ zone.nombrePuTotal }}</td>
                    </tr>
                </tfoot>
            </table>
            <br />

            <!-- S'il y a des besoins META-->
            <table *ngIf="zone.objNF_X43_050?.length" class="tab w-100">
                <thead class="greenBgColor">
                    <tr>
                        <th colspan="7">Prélèvements à poste fixe</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th colspan="7">Méthode : NF X43-050</th>
                    </tr>
                    <tr>
                        <th colspan="7">Nombre total de prélèvements pour la ZSE : {{ zone.objectifMesurage?.length }}</th>
                    </tr>
                    <tr class="greenBgColor">
                        <th>N° du besoin / type de besoin</th>
                        <th>Conditions de mise en œuvre</th>
                        <th>Période</th>
                        <th>Fréquence</th>
                        <th>Durée</th>
                        <th>Localisation</th>
                        <th>Justification localisation</th>
                    </tr>
                    <tr *ngFor="let obj of zone.objNF_X43_050; let num = index">
                        <td>BP{{ num + 1 }}</td>
                        <td>{{ obj.simulation }}</td>
                        <td>{{ obj.descriptionConditionPrelevement }}</td>
                        <td>{{ obj.frequence }}</td>
                        <td>{{ obj.dureeMax }}</td>
                        <td>{{ obj.emplacement }}</td>
                        <td>{{ obj.justificatifEmplacement }}</td>
                    </tr>
                </tbody>
            </table>
            <br />

            <!-- S'il y a des besoins META-->
            <table *ngIf="zone.objNF_X43_269?.length" class="tab w-100">
                <thead class="greenBgColor">
                    <tr>
                        <th colspan="7">Prélèvements individuels</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th colspan="7">Méthode : NF X43-269</th>
                    </tr>
                    <tr>
                        <th colspan="7">Nombre total de prélèvements pour la ZSE : {{ zone.objectifMesurage?.length }}</th>
                    </tr>
                    <tr class="greenBgColor">
                        <th>N° du besoin / type de besoin</th>
                        <th>Processus ou PO concerné</th>
                        <th>MPCA concerné</th>
                        <th>Fréquence</th>
                        <th>Durée</th>
                        <th></th>
                        <th>SA visée</th>
                    </tr>
                    <tr *ngFor="let obj of zone.objNF_X43_269; let num = index">
                        <td>BP{{ num + 1 }}</td>
                        <td>{{ obj.simulation }}</td>
                        <td>{{ obj.descriptionConditionPrelevement }}</td>
                        <td>{{ obj.frequence }}</td>
                        <td>{{ obj.dureeMax }}</td>
                        <td>{{ obj.emplacement }}</td>
                        <td>{{ obj.justificatifEmplacement }}</td>
                    </tr>
                    <tr class="greenBgColor">
                        <th colspan="7">Descriptif prélèvements individuels</th>
                    </tr>
                    <tr>
                        <th>N° du besoin / type de besoin</th>
                        <th>Processus ou PO concerné</th>
                        <th>Méthode / technique / Outil</th>
                        <th>Aspiration</th>
                        <th>Travail à l'humide</th>
                        <th>Tâches</th>
                        <th>Informations complémentaires (concentration attendue, Tr, Tmin, Tsat)</th>
                    </tr>
                    <tr *ngFor="let obj of zone.objNF_X43_269; let num = index">
                        <td>BP{{ num + 1 }}</td>
                        <td>{{ obj.simulation }}</td>
                        <td>{{ obj.descriptionConditionPrelevement }}</td>
                        <td>{{ obj.frequence }}</td>
                        <td>{{ obj.dureeMax }}</td>
                        <td>{{ obj.emplacement }}</td>
                        <td>{{ obj.justificatifEmplacement }}</td>
                    </tr>
                </tbody>
            </table>
            <br />
        </ng-container>
    </div>
</div>

<!--div class="markdown-content pb-5">
    <div class="block-title p-2 pl-5 my-3 d-inline-block">{{ contexte.titre }}</div>

    <div class="px-5 mt-2 block-markdown text-justify">
        <span class="subtittle">DEFINITION DES ZONES HOMOGENES</span>
        <table class="tab">
            <thead>
                <tr>
                    <th>Nom de la zone homogène</th>
                    <th>Matériaux contenant de l'amiante</th>
                    <th>Activité</th>
                    <th>Fréquentation</th>
                    <th>Type</th>
                    <th>Étanchéité</th>
                    <th>Choc et vibration</th>
                    <th>Circulation d'air</th>
                    <th>Surfaces</th>
                    <th>Nombre de pièce unitaire</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let zone of contexte.zones">
                    <tr>
                        <th [attr.rowspan]="zone.listeMpca.length + 1">{{ zone.nomZone }}</th>
                    </tr>
                    <tr *ngFor="let mpca of zone.listeMpca; let i = index">
                        <th>
                            MPCA {{ i + 1 }} : <em>({{ mpca.general.materiauxClient }})</em> {{ mpca.general.materiaux }} -
                            {{ mpca.general.etatDegradation }}
                            <span *ngFor="let amiante of mpca.general.typeAmiante"> - {{ amiante }} </span>
                        </th>
                        <td>{{ zone.activite }}</td>
                        <td>{{ zone.frequentation }}</td>
                        <td>{{ mpca.protectionExposition.type }}</td>
                        <td>{{ mpca.protectionExposition.etancheite }}</td>
                        <td>{{ mpca.protectionExposition.chocVibration }}</td>
                        <td>{{ mpca.protectionExposition.circulationAir }}</td>
                        <td>{{ zone.surfaces.superficie }}m²</td>
                        <td>{{ zone.surfaces.pieceUnitaire }}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <br />
    <div class="px-5 mt-2 block-markdown text-justify">
        <span class="subtittle">PIECES INDEPENDANTES</span>
        <table class="tab">
            <thead>
                <tr>
                    <th>Nom de la zone homogène</th>
                    <th>Détail des surfaces</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let zone of contexte.zones">
                    <tr>
                        <th>{{ zone.nomZone }}</th>
                        <td>
                            <ng-container *ngFor="let surface of zone.surfacesDetaillees; let i = index">
                                Vol {{ i + 1 }} : {{ surface.superficie }}m²
                                <ng-container *ngIf="i != zone.surfacesDetaillees.length - 1" ;>,</ng-container>
                            </ng-container>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <br />
    <div class="px-5 mt-2 block-markdown text-justify">
        <span class="subtittle">PROTOCOLE D’ECHANTILLONNAGE</span>
        <table class="tab">
            <thead>
                <tr>
                    <th>Nom de la zone homogène</th>
                    <th>Type de prélèvement</th>
                    <th>Méthode</th>
                    <th>Quantité</th>
                    <th>Durée</th>
                    <th>SA attendue</th>
                    <th>Prélèvement réglementaire</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let zone of contexte.zones">
                    <tr>
                        <th [attr.rowspan]="zone.refs.length + 1">{{ zone.nomZone }}</th>
                    </tr>

                    <tr *ngFor="let prelevement of zone.refs; let i = index">
                        <td>{{ prelevement.ref.reference }} - {{ prelevement.ref.typePrelevement }}</td>
                        <td>{{ prelevement.ref.norme }}</td>
                        <td>{{ prelevement.quantite }}</td>
                        <td>{{ prelevement.ref.dureeMax }}</td>
                        <td>{{ prelevement.ref.sensibiliteAnalytiqueAttendue }}</td>
                        <td *ngIf="prelevement.quantite == 1; else elseBlock">Non réglementaire</td>
                        <ng-template #elseBlock>Réglementaire</ng-template>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <br />
    <div class="px-5 mt-2 block-markdown text-justify">
        <span class="subtittle">LOCALISATION ET PÉRIODE DES PRÉLÈVEMENTS</span>
        <table class="tab">
            <thead>
                <tr>
                    <th>Nom de la zone homogène</th>
                    <th>Prélèvement</th>
                    <th>Localisation</th>
                    <th>Justification</th>
                    <th>Condition du prélèvement</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let zone of contexte.zones">
                    <tr *ngFor="let ref of zone.refs">
                        <th>{{ zone.nomZone }}</th>
                        <td class="miss">Non défini</td>
                        <td class="miss">Non défini</td>
                        <td class="miss">Non défini</td>
                        <td class="miss">Non défini</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <br />
    <div class="px-5 mt-2 block-markdown text-justify">
        <span class="miss">INSERTION DU PLAN / CROQUIS</span>
    </div>
</div-->
