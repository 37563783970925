import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize, switchMap, tap, share } from 'rxjs/operators';
import { MongoUtils } from 'src/app/commons-lib';

/**
 * Text par défaut affiché lors du spinner, peut être remplacé via l'attribut label de la méthode show(),
 * ou par l'input label du component.
 */
const DEFAULT_LABEL = 'En cours de chargement...';

/**
 * Seuil avant lequel on n'affiche pas le spinner (en ms)
 */
const DISPLAY_THRESHOLD = 300;

@Injectable({
    providedIn: 'root',
})
export class CnSpinnerService {
    private displaySpinner = new BehaviorSubject<boolean>(false);
    private spinnerLabel = new BehaviorSubject<string>(DEFAULT_LABEL);

    /**
     * Observable permettant d'obtenir le statut de visibilité du spinner.
     */
    public readonly displaySpinner$ = this.displaySpinner.asObservable().pipe(debounceTime(DISPLAY_THRESHOLD), distinctUntilChanged(), share());

    /**
     * Observable permettant d'obtenir le libellé du spinner.
     */
    public readonly label$ = this.spinnerLabel.asObservable().pipe(share());

    /**
     * Permet l'imbrication de plusieurs appels sans que le hide du premier appel n'intérompe le suivant.
     */
    private stack: {
        label: string;
        id: string;
    }[] = [];

    /**
     * Affiche le spinner
     * @param label un libellé (optionnel)
     * @param id un id à réutiliser pour le hide correspondant
     */
    show(label: string = DEFAULT_LABEL, id?: string) {
        const stackEntry = {
            id,
            label,
        };
        if (id) {
            const existingIndex = this.stack.findIndex((s) => s.id === id);
            if (existingIndex > -1) {
                this.stack.splice(existingIndex, 1);
            }
        }
        this.stack.push(stackEntry);
        this.displaySpinner.next(true);
        this.spinnerLabel.next(label);
    }

    /**
     * Cache le spinner si la stack est vide, ou sinon restaure le libellé.
     * @param id l'id qu'on a utilisé sur l'appel show correspondant.
     */
    hide(id?: string) {
        let stackIndex: number;
        if (id) {
            // On cherche l'index correspondant à l'id depuis la fin du stack
            stackIndex = this.stack
                .slice()
                .reverse()
                .findIndex((s) => s.id === id);
        } else {
            // on cherche le premier élément sans id depuis la fin du stack
            stackIndex = this.stack
                .slice()
                .reverse()
                .findIndex((s) => !s.id);
        }
        if (stackIndex > -1) {
            // Si on a trouvé un élément on l'enleve du tableau
            this.stack.splice(this.stack.length - 1 - stackIndex, 1);
        }

        if (!this.stack.length) {
            // stack is empty, hide spinner
            this.displaySpinner.next(false);
        } else {
            // restore label
            this.spinnerLabel.next(this.stack.slice().reverse()[0].label);
        }
    }

    /**
     * Entoure l'observable en entrée par un appel à show et hide
     * @param wrappedObservable the wrapped observable
     * @param label
     * @param id id optionnel, autogénéré si vide
     */
    withSpinner<T>(wrappedObservable: Observable<T>, label?: string, id: string = MongoUtils.generateObjectId()) {
        return of(null).pipe(
            tap(() => this.show(label, id)),
            switchMap(() => wrappedObservable),
            tap(() => this.hide(id)),
            catchError((err) => {
                this.hide(id);
                throw err;
            }),
            finalize(() => this.hide(id))
        );
    }
}
