import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Commande } from '../model/commande.model';
import { CommandeApiService } from './commande-api.service';

@Injectable({
    providedIn: 'root',
})
export class CommandeService {
    constructor(private commandeApiService: CommandeApiService) {}

    findAllCommandes(): Observable<Commande[]> {
        return this.commandeApiService.findAllCommandes();
    }

    findOneCommande(idCommande: string): Observable<Commande> {
        return this.commandeApiService.findOneCommande(idCommande);
    }

    findOneCommandeByNumberCommande(numCommande: string): Observable<Commande> {
        this.commandeApiService.findOneCommandeByNumberCommande(numCommande).subscribe(function (event) {});
        return this.commandeApiService.findOneCommandeByNumberCommande(numCommande);
    }

    upsertCommande(commande: Commande): Observable<Commande> {
        return this.commandeApiService.upsertCommande(commande);
    }

    findAllCommandesMinimales(): Observable<Commande[]> {
        return this.commandeApiService.findAllCommandesMinimales();
    }

    isNumeroCommandeAvailable(numCommande: string): Observable<boolean> {
        return this.commandeApiService.isNumeroCommandeAvailable(numCommande);
    }
}
