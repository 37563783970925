import { CnmapImageUtils, CnmapObjectUtils, dataUrlToBlob } from '@acenv/cnmap-angular-editor-lib';
import { cn_object } from '@acenv/cnmap-editor';
import { NotificationService, OBJECT_CONTACTS, ObjectContact, ParameterDefinition } from '@acenv/commons-lib';
import { fh_scene, fh_view_perspective } from '@acenv/fh-3d-viewer';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxImageCompressService } from 'ngx-image-compress';
import { combineLatest, forkJoin, observable, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { FileUploaderComponent } from 'src/app/commons-lib';
import { BaseComponent } from 'src/app/lib/utils/base.component';
import { CategorieEquipement } from 'src/app/model/categorie-equipement.model';
import { EditMode, EditModeEnum } from 'src/app/model/edit-mode.model';
import { Equipement, EquipementContactEnum } from 'src/app/model/equipement.model';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { EquipementApiService } from 'src/app/services/equipement-api.service';
import { ReferenceApiService } from 'src/app/services/reference-api.service';
import { URL_GESTION_EQUIPEMENTS } from 'src/app/shared/constants/url.constants';
import { EditParameterModalComponent } from '../edit-parameter-modal/edit-parameter-modal.component';
import { ObjectShape } from '@acenv/cnmap-angular-editor-lib/lib/model/object-shape.model';
import { ReferenceService } from 'src/app/services/reference.service';

@Component({
    selector: 'app-edit-equipement',
    templateUrl: './edit-equipement.component.html',
    styleUrls: ['./edit-equipement.component.scss'],
})
export class EditEquipementComponent extends BaseComponent implements OnInit, OnDestroy {
    EquipementContactEnum = EquipementContactEnum;

    idEquipement: string;
    idCategorieEquipement: string;

    categorieEquipement: CategorieEquipement;
    parameters: { [codeBim: string]: any } = {};

    equipement: Equipement;
    equipementInitial: Equipement;

    iconImageData: string;
    vueDessusData: string;

    formTypeElement: FormGroup;

    mode: EditMode = EditModeEnum.CREATE;

    @ViewChild('fileUploader') fileUploader: FileUploaderComponent;
    editPicture: boolean;
    editVueDessus: boolean;

    readonly listeContacts = OBJECT_CONTACTS;

    private scene3d: fh_scene;
    private view3d: fh_view_perspective;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly referenceApiService: ReferenceApiService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly notificationService: NotificationService,
        private readonly imageCompress: NgxImageCompressService,
        private readonly equipementApiService: EquipementApiService,
        private readonly matDialog: MatDialog,
        private readonly referenceService: ReferenceService
    ) {
        super();

        this.formTypeElement = this.formBuilder.group({
            name: new FormControl(null, { validators: Validators.required, updateOn: 'change' }),
            width: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
            depth: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
            height: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
            contact: new FormControl(null, { validators: Validators.required, updateOn: 'change' }),
            defaultHeight: new FormControl(null, { validators: Validators.nullValidator, updateOn: 'blur' }),
            parameters: [new FormControl([])],
            color: new FormControl(null, { validators: Validators.nullValidator, updateOn: 'change' }),
            roofParallel: new FormControl(null, { validators: Validators.nullValidator, updateOn: 'change' }),
            sourceType: new FormControl(null, { validators: Validators.required, updateOn: 'change' }),
        });
    }

    ngOnInit(): void {
        this.cnSpinnerService
            .withSpinner(
                combineLatest([this.route.paramMap, this.route.data]).pipe(
                    takeUntil(this.ngUnsubscribe),
                    switchMap(([params, data]) => {
                        this.mode = data.mode;
                        if (this.mode != EditModeEnum.CREATE) {
                            this.idEquipement = params.get('idEquipement');
                            return this.equipementApiService.findOneEquipement(this.idEquipement);
                        } else {
                            this.idCategorieEquipement = params.get('idCategorieEquipement');
                            return of(null);
                        }
                    }),
                    switchMap((currentTypeElement) => {
                        if (this.mode != EditModeEnum.CREATE) {
                            return forkJoin([
                                of(currentTypeElement),
                                this.referenceApiService.findFirstCategorieEquipementByCodeBim(currentTypeElement.productTypeCodeBim),
                            ]);
                        } else {
                            return forkJoin([
                                of(currentTypeElement),
                                this.referenceApiService.findOneCategorieEquipement(this.idCategorieEquipement),
                            ]);
                        }
                    })
                )
            )
            .subscribe(([currentTypeElement, currentCategorieEquipement]) => {
                if (currentTypeElement) {
                    this.equipementInitial = currentTypeElement;
                    this.equipement = currentTypeElement as Equipement;
                }
                if (currentCategorieEquipement) {
                    this.categorieEquipement = currentCategorieEquipement;
                }

                if (this.mode === EditModeEnum.CREATE) {
                    this.equipement = new Equipement();
                    this.equipement.productTypeCodeBim = this.categorieEquipement.codeBim;
                    //this.equipement.iconImageData = this.categorieEquipement.imageUrl;
                    //this.iconImageData = this.categorieEquipement.imageUrl;
                } else {
                    this.iconImageData = this.equipement?.iconImageData;
                    this.vueDessusData = this.equipement?.geometricData?.topViewImageData;
                    this.formTypeElement.patchValue(currentTypeElement);
                }

                this.initParameters();
                this.initChangeForm();
                this.refreshView();
            });
    }

    private initChangeForm() {
        this.formTypeElement.controls.width.valueChanges.subscribe((value: number) => {
            //console.log('width: ' + value);
            this.equipement.width = value;
            this.refreshView();
        });
        this.formTypeElement.controls.height.valueChanges.subscribe((value: number) => {
            //console.log('height: ' + value);
            this.equipement.height = value;
            this.refreshView();
        });
        this.formTypeElement.controls.depth.valueChanges.subscribe((value: number) => {
            //console.log('depth: ' + value);
            this.equipement.depth = value;
            this.refreshView();
        });
        this.formTypeElement.controls.defaultHeight.valueChanges.subscribe((value: number) => {
            //console.log('defaultHeight: ' + value);
            this.equipement.defaultHeight = value;
            this.refreshView();
        });
        this.formTypeElement.controls.contact.valueChanges.subscribe((value: ObjectContact) => {
            //console.log('contact: ' + value);
            this.equipement.contact = value;
            this.refreshView();
        });
        this.formTypeElement.controls.color.valueChanges.subscribe((value: string) => {
            //console.log('color: ' + value);
            this.equipement.color = value;
            this.refreshView();
        });
        this.formTypeElement.controls.roofParallel.valueChanges.subscribe((value: boolean) => {
            //console.log('roofParallel: ' + value);
            this.equipement.roofParallel = value;
            this.refreshView();
        });
        this.formTypeElement.controls.sourceType.valueChanges.subscribe((value: 'square' | 'circle' | 'wikipim') => {
            //console.log('sourceType: ' + value);
            this.equipement.sourceType = value;
            if (value !== 'wikipim') {
                if (this.equipement.geometricData == null)
                    this.equipement.geometricData = { topViewImageData: '', geometries: [], instanciations: [] };
                else {
                    this.equipement.geometricData.geometries = [];
                    this.equipement.geometricData.instanciations = [];
                }
            }
            this.refreshView();
        });
    }

    validerEquipement() {
        this.cnSpinnerService.show('Sauvegarde en cours...');

        // Values
        this.equipement.name = this.formTypeElement.value.name;
        this.equipement.defaultHeight = this.formTypeElement.value.defaultHeight;
        this.equipement.height = this.formTypeElement.value.height;
        this.equipement.width = this.formTypeElement.value.width;
        this.equipement.depth = this.formTypeElement.value.depth;
        this.equipement.contact = this.formTypeElement.value.contact;
        this.equipement.color = this.formTypeElement.value.color;
        this.equipement.iconImageData = this.iconImageData;
        if (this.vueDessusData && this.equipement.geometricData === null) {
            this.equipement.geometricData = {
                topViewImageData: this.vueDessusData,
                geometries: this.formTypeElement.value.geometries,
                instanciations: this.formTypeElement.value.instanciations,
            };
        }
        this.equipement.geometricData.topViewImageData = this.vueDessusData ? this.vueDessusData : '';
        this.equipement.parameters = this.formTypeElement.value.parameters;

        if (this.mode === EditModeEnum.EDIT) {
            this.referenceApiService.updateEquipement(this.equipement).subscribe(
                () => {
                    this.cnSpinnerService.hide();
                    this.notificationService.success("L'équipement a été modifié");
                    // On réinitialise la liste des equipements dans le cache de l'appli
                    this.referenceService.forceReloadEquipements();
                    this.back();
                },

                () => this.cnSpinnerService.hide()
            );
        } else {
            if (this.mode === EditModeEnum.DUPLICATE) {
                this.equipement.id = '';
            }
            this.referenceApiService.createEquipement(this.equipement).subscribe(
                () => {
                    this.cnSpinnerService.hide();
                    const message = this.mode === EditModeEnum.CREATE ? "L'équipement a été créé" : "L'équipement a été dupliqué";
                    this.notificationService.success(message);
                    // On réinitialise la liste des equipements dans le cache de l'appli
                    this.referenceService.forceReloadEquipements();
                    this.back();
                },
                () => this.cnSpinnerService.hide()
            );
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    back() {
        this.router.navigate([URL_GESTION_EQUIPEMENTS, this.categorieEquipement.id]);
    }

    uploadIconeElement(formData: FormData) {
        const file = formData.get('file') as File;
        CnmapImageUtils.getBase64(file).then((data) => {
            this.imageCompress.compressFile(data as string, -1, 100, 80, 120, 120).then((comp) => {
                this.iconImageData = this.verifySizeDocument(comp);
            });
        });
    }

    uploadVueDessus(formData: FormData) {
        const file = formData.get('file') as File;
        CnmapImageUtils.getBase64(file).then((data) => {
            this.imageCompress.compressFile(data as string, -1, 100, 80, 120, 120).then((comp) => {
                this.vueDessusData = this.verifySizeDocument(comp);
            });
        });
    }

    private verifySizeDocument(currentDocumentUrl: string): string {
        const imageBlob = dataUrlToBlob(currentDocumentUrl);
        if (imageBlob.size > 0.1 * 1048576) {
            this.notificationService.error("L'image est trop volumineuse. Veuillez en utiliser une autre.");
            return null;
        } else {
            return currentDocumentUrl;
        }
    }

    deleteIconeElement() {
        this.iconImageData = '';
    }

    deleteVueDessus() {
        this.vueDessusData = '';
    }

    isModeCreate(): boolean {
        return this.mode === EditModeEnum.CREATE;
    }
    isModeEdit(): boolean {
        return this.mode === EditModeEnum.EDIT;
    }
    isModeDuplicate(): boolean {
        return this.mode === EditModeEnum.DUPLICATE;
    }

    resetView() {
        //console.log('resetView');
        this.view3d && this.view3d.reset_camera();
    }

    refreshView() {
        //console.log('refreshView');
        this.load3d(
            CnmapObjectUtils.fillCnObjectWithProperties(
                CnmapObjectUtils.generateCnObjectForGenericObject(this.equipement, [this.categorieEquipement], false),
                this.equipement.iconImageData,
                this.equipement.geometricData?.topViewImageData,
                this.convertSourceType(this.equipement.sourceType),
                this.equipement.width / 100,
                this.equipement.depth / 100,
                this.equipement.height / 100,
                this.equipement.contact,
                this.equipement.roofParallel, // roofParallel
                this.equipement.defaultHeight / 100,
                this.equipement.color, // color
                this.equipement.name,
                this.categorieEquipement.codeBim,
                this.categorieEquipement.name,
                {}
            )
        );
    }

    convertSourceType(sourceType: 'square' | 'circle' | 'wikipim'): '' | ObjectShape {
        if (sourceType === 'wikipim') return '';
        return sourceType;
    }

    private load3d(object: cn_object) {
        if (!this.scene3d) {
            this.scene3d = new fh_scene();
        }
        object.fill_3d_scene(this.scene3d);
        if (this.view3d) {
            this.view3d.reset_camera();
            this.view3d.refresh_rendering();
        } else {
            // Le setTimeout est nécessaire, car il faut laisser au DOM le temps de se charger avant de créer l'élément fh_view
            setTimeout(() => {
                this.view3d = new fh_view_perspective('fh_3d_container-product-detail', this.scene3d);
                this.view3d.reset_camera();
                this.view3d.refresh_rendering();
            }, 1000);
        }
    }

    onFileSelected(event) {
        const file: File = event.target.files[0];
        var jsonObj;
        if (file) {
            const fileReader = new FileReader();
            fileReader.readAsText(file);
            fileReader.onload = () => {
                jsonObj = JSON.parse(fileReader.result.toString());
                //console.log(jsonObj);
                this.equipement.geometricData.geometries = jsonObj.geometries;
                this.equipement.geometricData.instanciations = jsonObj.instanciations;
                this.equipement.sourceType = 'wikipim';
                this.formTypeElement.controls.sourceType.setValue(this.equipement.sourceType);
                this.refreshView();
            };
            fileReader.onerror = (error) => {
                //console.log(error);
            };
        }
    }

    onClickBtnAddOrEditParametre(param: ParameterDefinition | null) {
        const refDialog = this.matDialog.open(EditParameterModalComponent, {
            data: { parameterDefinition: param, parameterValue: this.equipement.parameters[param.codeBim] },
        });
        return refDialog.afterClosed().subscribe((result: any | boolean) => {
            if (result && result != null && typeof result !== 'boolean') {
                this.formTypeElement.value.parameters[result.codeBim] = result.parameterValue;
                this.equipement.parameters[result.codeBim] = result.parameterValue;
            }
            ////console.log(this.equipement.parameters);
        });
    }

    initParameters() {
        this.categorieEquipement?.parametersDefinitions.forEach((param) => {
            if (this.equipement.parameters != null && this.equipement.parameters[param.codeBim] != null) {
                this.parameters[param.codeBim] = this.equipement.parameters[param.codeBim];
            } else if (this.categorieEquipement.valeursDefautParametres != null && this.categorieEquipement.valeursDefautParametres[param.codeBim]) {
                this.parameters[param.codeBim] = this.categorieEquipement.valeursDefautParametres[param.codeBim];
            } else {
                this.parameters[param.codeBim] = null;
            }
        });
    }

    getValueParameter(codeBim: string) {
        return this.equipement.parameters[codeBim];
    }
}
