import { ContenuDiagnostic } from '../../../../model/diagnostic-contenu.model';
import {
    EtapeDiagnostic,
    EtapeDiagnosticAbstract,
    EtapeDiagnosticGenerique,
    IdEtapeDiagnostic,
    TypeEtapeDiagnostic,
} from '../../../../model/diagnostic-etape.model';
import { AnnexeData, DocumentData, IDiagReportData, ReportagePhotoData, RevisionRapport } from '../../../../model/rapport.model';
import { TypePrestation } from '../../../../model/type-prestation.model';
import { Commentaire } from '../../../../model/commentaire.model';
import { EtatDiagnostic } from 'src/app/model/diagnostic.model';

export const LABEL_METADATA_KEY = 'label';

function Label(label: string) {
    return (target: Object, propertyKey: string) => {
        Object.defineProperty(target, `${LABEL_METADATA_KEY}_${propertyKey}`, {
            value: label,
            enumerable: false, // Make it non-enumerable to avoid issues
        });
    };
}

export class CeeModel implements ContenuDiagnostic {
    @Label("Nom de l'entreprise ayant réalisé les travaux")
    nomEntreprise: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    @Label('Numéro de SIRET')
    numeroSIRET: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    @Label('Entreprise RGE')
    entrepriseRGE: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    @Label('Référence de la facture')
    refFacture: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    @Label('Date de la visite préalable')
    dateVisitePrealable: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    @Label('Date de début des travaux')
    dateDebutTravaux: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    @Label('Zone climatique')
    zoneClimatique: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    @Label("Document matérialisant l'engagement de l'opération (devis signé...)")
    presenceDevis: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    @Label('Document prouvant la réalisation de l’opération (facture des travaux, PV de réception, etc) remis au bénéficiaire')
    presenceFacture: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    // Utilsé dans le reste à faire :
    @Label("Date d'acceptation du devis")
    dateAcceptationDevis: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    @Label('Date de la facture')
    dateFacture: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    @Label("Date de l'état récapitulatif")
    dateEtatRecapitulatif: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();

    typeIsolant: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    marqueIsolant: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    epaisseur: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    nombreSacs: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    resistanceThermique: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    surfaceIsolee: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    solutions: EtapeDiagnosticCee = new EtapeDiagnosticCee();
    caracteristiques: EtapeDiagnosticAbstract = new EtapeDiagnosticAbstract();
    longuerReseauxIsoles: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    longuerReseauxIsolesHorsVolumeChauffe: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();

    public getLabel(propertyKey: string): string {
        return this[`${LABEL_METADATA_KEY}_${propertyKey}`];
    }
    constructor(data?: CeeModel) {
        if (data) {
            for (const property of Object.getOwnPropertyNames(data)) {
                this[property] = data[property];
            }
        }
    }
}

export class EtapeDiagnosticCee implements EtapeDiagnostic {
    id: IdEtapeDiagnostic;
    type: TypeEtapeDiagnostic = TypeEtapeDiagnostic.ETAPE_DIAGNOSTIC_CEE;
    solutions: ISolution[] = [];
}

export interface ISolution {
    infoIsolant?: EtapeDiagnosticGenerique[];
    pointsDeControles?: EtapeDiagnosticAbstract;
    valid?: boolean; // Si une solution est vaiide
    // => data = Map<string, EtapeDiagnosticGenerique[]> : key : Nom de la zone
}

export class Anomalie {
    id: string;
    code: string;
    codeReponse: string;
    type: string;
    valeurReference: number;
    description: string;
    idPointDeControleReference: string;
}

export interface IReportPC {
    caracteristiques?: {
        libelle: string;
        reponse: string;
        commentaires: Commentaire[];
        sectionRapport: string;
        ordreSectionRapport: string;
        estAnomalie: boolean;
        idChapitre: string;
        idPointControle: string;
    }[];
    infosSolutions: {
        conclusionAccessibiliteRealisationNonSatisfaisant: boolean;
        conclusionMiseEnServiceNonSatisfaisant: boolean;
        solutionName: string;
        informations: {
            libelle: string;
            reponse: string;
            commentaires: Commentaire[];
            sectionRapport: string;
            ordreSectionRapport: string;
            estAnomalie: boolean;
        }[];
    };
    zones: {
        zoneName: string;
        pointsDeControle: {
            libelle: string;
            reponse: string;
            commentaires: Commentaire[];
            sectionRapport: string;
            ordreSectionRapport: string;
            estAnomalie: boolean;
            libellesAnomalies: any[];
            imageZone: {
                legende: string;
                url: string;
                creationDate: string;
                gpsLatitudeRef: string;
                gpsLatitude: string;
                gpsLongitudeRef: string;
                gpsLongitude: string;
                gpsAltitudeRef: string;
                gpsAltitude: string;
            };
        }[];
    }[];
}

export interface IAnomalie {
    solution: string;
    zones: {
        name: string;
        libelleAnomalies: string[];
        commentairesId?: string[];
    }[];
}

export class CEEReportData implements IDiagReportData {
    id: string;
    nom: string;
    typePrestation: TypePrestation;
    conformiteGlobale: boolean;
    documentsData: DocumentData[];
    annexes: AnnexeData[];
    reportagesPhotos: ReportagePhotoData[];
    recommandations: string[] = [];
    constatationsDiverses: string[] = [];
    refRapport: string;
    anomalies: IAnomalie[] = [];
    pointsDeControle: {
        solution: IReportPC[];
    };
    etapesGenerales: any[] = [];
    conformitereglementaire: any[] = [];
    listDocuments: DocumentData[];
    longuerReseauxIsoles: number;
    longuerReseauxIsolesHorsVolumeChauffe: number;
    revision: RevisionRapport;
    longuerReseauxIsolesSurFacture: number;
    anomalieLonguerReseauxIsoles: boolean = false;
    /*nomEntreprise: any;
    numeroSIRET: any;
    entrepriseRGE: any;
    dateVisitePrealable: any;
    dateAcceptationDevis: any;
    dateFacture: any;
    refFacture: any;
    dateEtatRecapitulatif: any;
    */

    /**
     * Etat du diagnostic
     *
     * @title Etat du diagnostic
     * @description L'état du diagnostic : EN_ATTENTE, TERMINE, FINI; Si tous les diagnostics sont fini ou annulé, l'intervention passe à terminé, et tous les diagostics passent à terminé.
     */
    etat: EtatDiagnostic;
}

export class TypeCee {
    id: string;
    idInterne: string;
    description: string;
    libelle: string;
}

export class CeeConfig {
    id: string;
    dateDeCreation: Date;
    idInterne: string;
    typesCee: TypeCee[];
    chapitres: Chapitre[];
    pointsControles: PointControle[];
    tableauZoneTresFroide: TableauZoneTresFroide;
    tableauZoneClimatique: TableauZoneClimatique;
}

export class TableauZoneTresFroide {
    id: string;
    lstDepartementAltitude: DepartementAltitude[];
}

export class DepartementAltitude {
    id: string;
    departement: number;
    altitude: number;
}

export class TableauZoneClimatique {
    id: string;
    lstDepartementsZones: DepartementZone[];
}
export class DepartementZone {
    id: string;
    departement: number;
    zone: string;
}

export class Chapitre {
    id: string;
    idInterne: string;
    idChapitreParent: string;
    idChapitreRacine: string;
    idTypeCee: string;
    description: string;
    chemin: string;
}

export class Aide {
    contenu: string;
    description: string;
    ordre: number;
}

export class PointControle {
    id: string;
    idInterne: string;
    idChapitre: string;
    ordreChapitre: number;
    peutEtreRepondu: boolean;
    description: string;
    type: string;
    decalage: number;
    lstChoixReponse: Reponse[];
    libelleRapport: string;
    isGrey: boolean = false;
    reponseDefaut: string = 'Vide';
    isCalculette: boolean = false;
    isCalculZone: boolean = false;
    hide: boolean = false;
    idPointControleParent: string;
    lstPointsContolesReponsesCibles: PointControleReponse[];
    lstAnomalie: Anomalie[];
    sectionRapport: string;
    ordreSectionRapport: string;
    required: boolean;
    canForceAnomalie: boolean;
    aides: Aide[];
}

export class PointControleReponse {
    id: string;
    codeReponseInitiale: string;
    idPointControleCible: string;
    reponseCible: Reponse;
    isGreyCible: boolean;
}

export class Reponse {
    id: string;
    code: string;
    description: string;
    idPointControleReponses: string;
    option: any;
    needJustification: boolean;
    phraseJustification: string;
}
