<div class="block-content px-5 ml-2 mb-4">
    <ng-container *ngFor="let espace of listeEspaces">
        <ng-container *ngIf="espace.listePerimetres.length === 0">
            Aucun périmètre défini pour cet espace.
        </ng-container>
        <ng-container *ngFor="let perimetre of espace.listePerimetres; let index = index">
            <!----------------- Nom du périmètre ---------------->
            <div class="block-title p-2 d-inline-block mt-4" *ngIf="data">{{ espace.nom }}-{{ perimetre.nom }}</div>

            <!--------------- Détail des zones -------------->
            <ng-container *ngIf="perimetre.listeZones.length === 0">
                <div>Aucun zone définie pour ce périmètre.</div>
            </ng-container>
            <ng-container *ngFor="let zone of perimetre.listeZones">
                <div class="block-subtitle-2 mt-4 mb-2">{{ zone.nom ? zone.nom : 'n.c' }}</div>
                <table>
                    <tr>
                        <th class="prelevement">PRÉLÈVEMENT</th>
                        <th class="precision">PRÉCISIONS</th>
                    </tr>

                    <ng-container *ngFor="let prelevement of zone.listePrelevements">
                        <tr>
                            <!-------------- Colonne PRELEVEMENTS -------------->
                            <td>
                                <div class="d-flex flex-column my-3">
                                    <span class="primary"
                                        ><b
                                            >Ref :
                                            {{
                                                prelevement.general.reference ? prelevement.general.reference : 'n.c'
                                            }}</b
                                        ></span
                                    >
                                    <span *ngIf="prelevement.general.gpsLatitude && prelevement.general.gpsLongitude"
                                        >Coordonnées GPS :
                                        {{
                                            prelevement.general.gpsLatitude + ' - ' + prelevement.general.gpsLongitude
                                        }}</span
                                    >
                                    <span>Ouvrage : {{ perimetre.typeOuvrage ? perimetre.typeOuvrage : 'n.c' }}</span>
                                    <span
                                        >Partie d'ouvrage : {{ zone.partieOuvrage ? zone.partieOuvrage : 'n.c' }}</span
                                    >
                                </div>
                            </td>

                            <!-------------- Colonne PRECISIONS -------------->
                            <td>
                                <div class="d-flex flex-column my-3">
                                    <span
                                        >Type de prélèvement :
                                        {{
                                            prelevement.details.typePrelevement
                                                ? prelevement.details.typePrelevement
                                                : 'n.c'
                                        }}</span
                                    >
                                    <span *ngIf="prelevement.details.profondeurCarottage"
                                        >Profondeur : {{ prelevement.details.profondeurCarottage }}cm</span
                                    >

                                    <ng-container
                                        *ngIf="
                                            [
                                                TypePrestationEnum.HAP_TERRAIN,
                                                TypePrestationEnum.HAP_VALIDATION_RESULTATS
                                            ].includes(typePrestation)
                                        "
                                    >
                                        <div class="d-flex w-100 justify-content-center">
                                            <div class="block-photo">
                                                <div
                                                    *ngIf="!prelevement.details.idFichierImageSituation"
                                                    class="h-100 d-flex justify-content-center align-items-center"
                                                >
                                                    <div class="text-photo">PHOTO DE SITUATION</div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        prelevement.details.idFichierImageSituation
                                                            | fileDownload
                                                            | async as fileContent
                                                    "
                                                    class="image-photo d-flex justify-content-center"
                                                >
                                                    <img
                                                        [src]="fileContent"
                                                        alt="Image de situation du prélèvement"
                                                        class="img-fluid photo-prelevement"
                                                    />
                                                </div>
                                            </div>

                                            <div class="block-photo ml-4">
                                                <div
                                                    *ngIf="!prelevement.details.idFichierImageEchelle"
                                                    class="h-100 d-flex justify-content-center align-items-center"
                                                >
                                                    <div class="text-photo">PHOTO D'ÉCHELLE</div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        prelevement.details.idFichierImageEchelle
                                                            | fileDownload
                                                            | async as fileContent
                                                    "
                                                    class="image-photo d-flex justify-content-center"
                                                >
                                                    <img
                                                        [src]="fileContent"
                                                        alt="Image d'échelle du prélèvement"
                                                        class="img-fluid photo-prelevement"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <table class="mt-4">
                                            <tr>
                                                <th class="prelevement">ÉCHANTILLONS</th>
                                                <th class="precision">TYPE DE MATÉRIAUX</th>
                                                <th class="precision">ÉPAISSEUR</th>
                                                <th class="precision">ANALYSE AMIANTE</th>
                                            </tr>

                                            <ng-container *ngFor="let echantillon of prelevement.echantillonages">
                                                <tr>
                                                    <td>{{ echantillon.reference }}</td>
                                                    <td>
                                                        <mat-chip-list #materiauChipList>
                                                            <mat-chip
                                                                *ngFor="let typeMateriau of echantillon.materiaux"
                                                            >
                                                                {{ typeMateriau }}
                                                            </mat-chip>
                                                        </mat-chip-list>
                                                    </td>
                                                    <td>
                                                        {{ echantillon.hauteur ? echantillon.hauteur + 'cm' : 'n.c' }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            prelevement.donneesTechniques.analyseAmiante
                                                                ? 'A faire'
                                                                : 'Déjà réalisée'
                                                        }}
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            <tr *ngIf="prelevement.echantillonages.length === 0">
                                                <td colspan="4" class="text-center p-2">Aucun échantillon</td>
                                            </tr>
                                        </table>
                                    </ng-container>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                    <tr *ngIf="zone.listePrelevements.length === 0">
                        <td colspan="3" class="text-center p-4">
                            Aucun prélèvement {{ isPrelevementRealise ? '' : 'non réalisé' }}
                        </td>
                    </tr>
                </table>
            </ng-container>

            <!--Hors zone-->
            <ng-container *ngIf="perimetre.listeBesoins.length > 0">
                <div class="block-subtitle-2 mt-4 mb-2">Hors zone</div>
                <table>
                    <tr>
                        <th class="prelevement">PRÉLÈVEMENT</th>
                        <th class="precision">PRÉCISIONS</th>
                    </tr>
                    <ng-container *ngFor="let besoin of perimetre.listeBesoins">
                        <tr>
                            <!-------------- Colonne PRELEVEMENTS -------------->
                            <td>
                                <div class="d-flex flex-column my-3">
                                    <span class="primary"
                                        ><b
                                            >Ref : {{ besoin.general.reference ? besoin.general.reference : 'n.c' }}</b
                                        ></span
                                    >
                                    <span *ngIf="besoin.general.gpsLatitude && besoin.general.gpsLongitude"
                                        >Coordonnées GPS :
                                        {{ besoin.general.gpsLatitude + ' - ' + besoin.general.gpsLongitude }}</span
                                    >
                                    <span>Ouvrage : {{ perimetre.typeOuvrage ? perimetre.typeOuvrage : 'n.c' }}</span>
                                </div>
                            </td>

                            <!-------------- Colonne PRECISIONS -------------->
                            <td>
                                <div class="d-flex flex-column my-3">
                                    <span
                                        >Type de prélèvement :
                                        {{
                                            besoin.details.typePrelevement ? besoin.details.typePrelevement : 'n.c'
                                        }}</span
                                    >
                                    <span *ngIf="besoin.details.profondeurCarottage"
                                        >Profondeur : {{ besoin.details.profondeurCarottage }}cm</span
                                    >

                                    <ng-container
                                        *ngIf="
                                            [
                                                TypePrestationEnum.HAP_TERRAIN,
                                                TypePrestationEnum.HAP_VALIDATION_RESULTATS
                                            ].includes(typePrestation)
                                        "
                                    >
                                        <div class="d-flex w-100 justify-content-center">
                                            <div class="block-photo">
                                                <div
                                                    *ngIf="!besoin.details.idFichierImageSituation"
                                                    class="h-100 d-flex justify-content-center align-items-center"
                                                >
                                                    <div class="text-photo">PHOTO DE SITUATION</div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        besoin.details.idFichierImageSituation
                                                            | fileDownload
                                                            | async as fileContent
                                                    "
                                                    class="image-photo d-flex justify-content-center"
                                                >
                                                    <img
                                                        [src]="fileContent"
                                                        alt="Image de situation du prélèvement"
                                                        class="img-fluid photo-prelevement"
                                                    />
                                                </div>
                                            </div>

                                            <div class="block-photo ml-4">
                                                <div
                                                    *ngIf="!besoin.details.idFichierImageEchelle"
                                                    class="h-100 d-flex justify-content-center align-items-center"
                                                >
                                                    <div class="text-photo">PHOTO D'ÉCHELLE</div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        besoin.details.idFichierImageEchelle
                                                            | fileDownload
                                                            | async as fileContent
                                                    "
                                                    class="image-photo d-flex justify-content-center"
                                                >
                                                    <img
                                                        [src]="fileContent"
                                                        alt="Image d'échelle du prélèvement"
                                                        class="img-fluid photo-prelevement"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <table class="mt-4">
                                            <tr>
                                                <th class="prelevement">ÉCHANTILLONS</th>
                                                <th class="precision">TYPE DE MATÉRIAUX</th>
                                                <th class="precision">ÉPAISSEUR</th>
                                                <th class="precision">ANALYSE AMIANTE</th>
                                            </tr>

                                            <ng-container *ngFor="let echantillon of besoin.echantillonages">
                                                <tr>
                                                    <td>{{ echantillon.reference }}</td>
                                                    <td>
                                                        <mat-chip-list #materiauChipList>
                                                            <mat-chip
                                                                *ngFor="let typeMateriau of echantillon.materiaux"
                                                            >
                                                                {{ typeMateriau }}
                                                            </mat-chip>
                                                        </mat-chip-list>
                                                    </td>
                                                    <td>
                                                        {{ echantillon.hauteur ? echantillon.hauteur + 'cm' : 'n.c' }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            besoin.donneesTechniques.analyseAmiante
                                                                ? 'A faire'
                                                                : 'Déjà réalisée'
                                                        }}
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            <tr *ngIf="besoin.echantillonages.length === 0">
                                                <td colspan="4" class="text-center p-2">Aucun échantillon</td>
                                            </tr>
                                        </table>
                                    </ng-container>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </table>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
