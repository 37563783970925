import { Injectable } from '@angular/core';
import { InterventionFile, TypeReferenceFichier } from '../model/intervention-file.model';
import { InterventionFileApiService } from './intervention-file-api.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FileApiService } from './file-api.service';
import { Intervention } from '../model/intervention.model';
import { Diagnostic } from '../model/diagnostic.model';
import { combineLatestOrEmpty } from '../utils/rxjs.utils';
import { Document } from '../model/document.model';

@Injectable({
    providedIn: 'root',
})
export class InterventionFileService {
    constructor(private readonly interventionFileApiService: InterventionFileApiService, private readonly fileApiService: FileApiService) {}

    /**
     * Récupère l'objet InterventionFile existant ou en crée un nouveau
     * @param interventionId
     * @param diagnosticId
     * @param referenceId
     * @param typeReferenceFichier
     * @param fileId
     */
    findByIdInterventionIdDiagnosticIdReference(
        interventionId: string,
        diagnosticId: string,
        referenceId: string,
        typeReferenceFichier: TypeReferenceFichier,
        fileId: string
    ): Observable<InterventionFile> {
        if (fileId) {
            return this.interventionFileApiService
                .findByIdInterventionIdDiagnosticIdReference(interventionId, diagnosticId, referenceId, typeReferenceFichier, fileId)
                .pipe(
                    map((interventionFile) => {
                        if (interventionFile && interventionFile.length > 0) {
                            // Il existe déjà une image
                            return interventionFile[0];
                        } else {
                            // Il n'existe pas encore d'image
                            return this.getNewInterventionFile(interventionId, diagnosticId, referenceId, typeReferenceFichier, fileId);
                        }
                    })
                );
        } else {
            // Il n'existe pas encore d'image
            return of(this.getNewInterventionFile(interventionId, diagnosticId, referenceId, typeReferenceFichier, fileId));
        }
    }

    /**
     * Récupère l'objet InterventionFile existant ou en crée un nouveau
     * @param interventionId
     * @param typeReferenceFichier
     * @param fileId
     */
    findByIdInterventionFileId(interventionId: string, typeReferenceFichier: TypeReferenceFichier, fileId: string): Observable<InterventionFile> {
        if (fileId) {
            return this.interventionFileApiService.findByIdInterventionFileId(interventionId, typeReferenceFichier, fileId).pipe(
                map((interventionFile) => {
                    if (interventionFile && interventionFile.length > 0) {
                        // Il existe déjà une image
                        return interventionFile[0];
                    }
                })
            );
        }
    }

    /**
     * Retourne une nouvelle instance de la classe InterventionFile
     * avec un id automatiquement généré
     * @param interventionId
     * @param typeReferenceFichier
     * @param diagnosticId
     * @param referenceId
     * @param fileId
     */
    getNewInterventionFile(
        interventionId: string,
        diagnosticId: string,
        referenceId: string,
        typeReferenceFichier: TypeReferenceFichier,
        fileId: string
    ) {
        // Nouvelle photo
        const interventionFile = new InterventionFile();
        interventionFile.interventionId = interventionId;
        interventionFile.typeReferenceFichier = typeReferenceFichier;
        interventionFile.diagnosticId = diagnosticId;
        interventionFile.referenceId = referenceId;
        interventionFile.fileId = fileId;
        return interventionFile;
    }

    /**
     * Supprime l'occurence interventionFile passée en paramètre.
     * Si le paramèter deletefile = true, supprime également le fichier
     * @param interventionFile
     * @param deleteFile
     */
    deleteInterventionFile(interventionFile, deleteFile = false): Observable<any> {
        if (deleteFile) {
            return this.fileApiService.deleteFile(interventionFile.fileId).pipe(
                switchMap(() => {
                    return this.interventionFileApiService.delete(interventionFile);
                })
            );
        } else {
            return this.interventionFileApiService.delete(interventionFile);
        }
    }

    /**
     * Upload le fichier passé en paramètre
     * rajoute une occurence dans la table InterventionFile
     * @param interventionFile
     * @param dataUrl
     * @param fileName
     */
    uploadInterventionFile(interventionFile, dataUrl, fileName: string): Observable<InterventionFile> {
        // Upload du fichier
        return this.fileApiService.uploadFile(dataUrl, fileName, interventionFile.fileId).pipe(
            switchMap((fileDataResponse) => {
                interventionFile.fileId = fileDataResponse.fileId;
                // Post de l'instance InterventionFile
                return this.interventionFileApiService.upsert(interventionFile);
            })
        );
    }

    /**
     * Retourne une liste de fichier InterventionFile liés à l'intervention
     * mais qui n'est pas associée à un diagnostic
     * @param intervention
     */
    getInterventionFilesOfIntervention(intervention: { id: string } | Intervention): Observable<InterventionFile[]> {
        return this.interventionFileApiService.findAllByAllAttributsWithAllType(intervention.id, null);
    }

    /**
     * Retourne une liste de fichier InterventionFile liés à l'intervention
     * mais qui n'est pas associée à un diagnostic
     * @param intervention
     */
    getInterventionFilesOfInterventionWithInterventionIdAndTypeReferenceFichier(idIntervention: string): Observable<InterventionFile[]> {
        return this.interventionFileApiService.findAllByAllAttributsWithAllTypeAndInterventionId(idIntervention);
    }

    /**
     * Retourne une liste de fichier InterventionFile liés à l'intervention
     * mais qui n'est pas associée à un diagnostic
     * On ne prend que les InterventionFile correspondant à une photo pour le reportage photo
     * @param intervention
     */
    getInterventionFilesOfInterventionForReportagePhoto(intervention: Intervention): Observable<InterventionFile[]> {
        const typesReferenceFichier: TypeReferenceFichier[] = [TypeReferenceFichier.PHOTO_BIEN, TypeReferenceFichier.PHOTO_COMMENTAIRE];

        return this.interventionFileApiService.findAllByAllAttributs(intervention.id, null, typesReferenceFichier);
    }

    /**
     * Retourne une liste de fichiers InterventionFile liés à l'intervention et au diagnostic
     * @param intervention
     * @param diagnostic
     */
    getInterventionFilesOfDiagnostic(intervention: Intervention, diagnostic: Diagnostic): Observable<InterventionFile[]> {
        const typesReferenceFichier: TypeReferenceFichier[] = [
            TypeReferenceFichier.PHOTO_COMMENTAIRE,
            TypeReferenceFichier.PHOTO_ZONE,
            TypeReferenceFichier.PHOTO_PRELEVEMENT_SITUATION,
            TypeReferenceFichier.PHOTO_PRELEVEMENT_ECHELLE,
        ];

        return this.interventionFileApiService.findAllByAllAttributs(intervention.id, diagnostic.id, typesReferenceFichier);
    }

    /**
     * Met à jour un InterventionFile
     * @param interventionFile
     */
    upsert(interventionFile: InterventionFile): Observable<InterventionFile> {
        return this.interventionFileApiService.upsert(interventionFile);
    }

    /**
     * Supprime l'interventionFile et le fichier associés suivant les paramètres passés à la méthode
     * @param idIntervention
     * @param idDiagnostic
     * @param idReference
     * @param typeReferenceFichier
     * @param fileId
     * @returns
     */
    deleteInterventionFileAndFile(
        idIntervention: string,
        idDiagnostic: string,
        idReference: string,
        typeReferenceFichier: TypeReferenceFichier,
        fileId: string
    ) {
        // On récupère l'InterventionFile
        return this.findByIdInterventionIdDiagnosticIdReference(idIntervention, idDiagnostic, idReference, typeReferenceFichier, fileId).pipe(
            switchMap((interventionFile) => {
                /**
                 * Supprime l'image du commentaire et l'objet InterventionFile
                 */
                return this.deleteInterventionFile(interventionFile, true);
            })
        );
    }

    assureDocumentAccess(intervention: { id: string }, documents: Document[]) {
        return this.getInterventionFilesOfIntervention(intervention).pipe(
            map((interventionFiles) => documents.filter((d) => d.idFichier && !interventionFiles.some((iF) => iF.fileId === d.idFichier))),
            map((documents) =>
                documents.map((d) => this.getNewInterventionFile(intervention.id, null, d.id, TypeReferenceFichier.FICHIER_DOCUMENT, d.idFichier))
            ),
            switchMap((interventionFiles) => combineLatestOrEmpty(interventionFiles.map((iF) => this.upsert(iF))))
        );
    }
}
