import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MpcaProtectionExpositionOld } from '../../../../../model/mpca.model.old';
import { ListeDeroulante } from '../../../../../model/polluant-config.model';
import { AbstractControl, FormGroup } from '@angular/forms';
import { WidgetSelectModeEnum } from 'src/app/modules/shared/widget-select/widget-select.component';

@Component({
    selector: 'app-mpca-form-protection-exposition',
    templateUrl: './mpca-form-protection-exposition.component.html',
    styleUrls: ['./mpca-form-protection-exposition.component.scss'],
})
export class MpcaFormProtectionExpositionComponent {
    @Output()
    protectionExpositionDataChange = new EventEmitter<MpcaProtectionExpositionOld>();

    @Input() set typeProtectionExposition(value: ListeDeroulante) {
        for (const item of value?.items) {
            const color = item === 'Présence' ? 'yellow' : item === 'Absence' ? 'orange' : item === 'Non renseigné' ? 'grey' : 'green';
            this.itemProtectionExposition.push({
                color,
                item,
            });
        }
    }
    @Input() set typeEtancheiteConfig(value: ListeDeroulante) {
        for (const item of value?.items) {
            const color =
                item === 'Faible'
                    ? 'red'
                    : item === 'Moyen'
                    ? 'orange'
                    : item === 'Fort'
                    ? 'yellow'
                    : item === 'Matériau retiré'
                    ? 'green'
                    : item === 'Non applicable'
                    ? 'blue'
                    : 'grey';
            this.itemEtancheiteConfig.push({
                color,
                item,
            });
        }
    }
    @Input() set typeConditionExpoChocVibration(value: ListeDeroulante) {
        for (const item of value?.items) {
            const color =
                item === 'Aucun'
                    ? 'blue'
                    : item === 'Faible'
                    ? 'yellow'
                    : item === 'Moyen'
                    ? 'orange'
                    : item === 'Fort'
                    ? 'red'
                    : item === 'Matériau retiré'
                    ? 'green'
                    : item === 'Non renseigné'
                    ? 'grey'
                    : 'blue';
            this.itemConditionExpoChocVibration.push({
                color,
                item,
            });
        }
    }
    @Input() set typeConditionExpoCirculationAir(value: ListeDeroulante) {
        for (const item of value?.items) {
            const color =
                item === 'Faible' ? 'yellow' : item === 'Moyen' ? 'orange' : item === 'Fort' ? 'red' : item === 'Non renseigné' ? 'grey' : 'green';
            this.itemConditionExpoCirculationAir.push({
                color,
                item,
            });
        }
    }
    @Input()
    formProtectionExposition: FormGroup | AbstractControl;

    @Input() // Utilisé pour passer les champs en read-only lors de la visite de chantier
    disabled: Boolean;

    widgetSelectModeEnum: typeof WidgetSelectModeEnum = WidgetSelectModeEnum;
    itemProtectionExposition: { color: string; item }[] = [];
    itemEtancheiteConfig: { color: string; item }[] = [];
    itemConditionExpoChocVibration: { color: string; item }[] = [];
    itemConditionExpoCirculationAir: { color: string; item }[] = [];
}
