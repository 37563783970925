/**
 * Type d'ouvrage.
 */
import { MongoUtils } from 'src/app/commons-lib';

export class TypeMateriau {
    id: string = MongoUtils.generateObjectId();

    /**
     * Désignation
     */
    nom: string;

    constructor() {}
}
