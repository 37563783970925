<div class="pb-5">
    <div class="block-title p-2 pl-5 my-3 d-inline-block" *ngIf="data">{{ data.title }}</div>

    <!--------------------- Renseignements relatifs à l'analyse --------------------->
    <div class="px-5 mt-4 d-flex">
        <div class="block-group mr-3 w-100">
            <div class="d-flex mt-1">
                <div class="group-label">Date de prélèvement :</div>
                <div class="group-value">{{ diagnosticData.datePrelevement }}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">Auteur de prélèvement :</div>
                <div class="group-value">
                    {{ interReportData.operateur.nom
                    }}<span *ngIf="interReportData.operateur.prenom !== 'n.c'">
                        {{ interReportData.operateur.prenom }}</span
                    >
                </div>
            </div>
            <div class="d-flex mt-1">
                <!-- Correspond à l'adresse du bien -->
                <div class="group-label">Site de prélèvement / Localisation :</div>
                <div class="group-value d-flex flex-column">
                    <span>{{ interReportData.bienPrincipal.adresse }}</span>
                    <span>{{
                        interReportData.bienPrincipal.codePostal + ' ' + interReportData.bienPrincipal.ville
                    }}</span>
                </div>
            </div>
            <div class="d-flex mt-1">
                <!-- Correspond au nom du bien -->
                <div class="group-label">Désignation :</div>
                <div class="group-value">{{ interReportData.bienPrincipal.refBien }}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">Date d'envoi :</div>
                <div class="group-value">{{ diagnosticData.dateEnvoi }}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">Envois des résultats :</div>
                <div class="group-value d-flex flex-column">
                    <span
                        ><mat-icon class="success" matBadgePosition="below after"> done </mat-icon>Accusé de réception
                        par mails des échantillons</span
                    >
                    <span>E-mail : {{ interReportData.operateur.email }}</span>
                    <span class="decalage-email-generique">{{ diagnosticData.emailEnvoieResultatGenerique }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
