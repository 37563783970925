import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { Contact } from '../../../../../../../model/contact.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { DiagnosticService } from '../../../../../../../services/diagnostic.service';
import { Polluant } from '../../../../model/polluant.model';
import { POLL_DATE_CONTACTS } from '../../../../../../../shared/constants/names.step.constants';
import { EtatProgressionService } from '../../../../../../../services/etat-progression.service';
import { Diagnostic } from '../../../../../../../model/diagnostic.model';

@Component({
    selector: 'app-date-contacts',
    templateUrl: './date-contacts.component.html',
})
export class DateContactsComponent extends BaseComponent implements OnInit, OnDestroy {
    // PUBLIC
    readonlyMode = false;
    dateForm: FormGroup;
    contacts: Contact[] = [];

    private _diagnostic: Diagnostic;

    constructor(private fb: FormBuilder, private diagnosticService: DiagnosticService, private etatProgressionService: EtatProgressionService) {
        super();
        this.dateForm = this.fb.group({
            date: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        this.diagnosticService
            .getCurrentDiagnostic()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((diagnostic) => {
                this._diagnostic = diagnostic;
                // Init contacts
                const contenuDiag = diagnostic.contenuDiagnostic as Polluant;
                this.contacts = contenuDiag.preparation.data.contacts;
                if (contenuDiag.preparation.data.dateContacts) {
                    this.dateForm.setValue(contenuDiag.preparation.data.dateContacts);
                }
            });
    }
    ngOnDestroy() {
        (this._diagnostic.contenuDiagnostic as Polluant).preparation.data.dateContacts = this.dateForm.value;
        this.etatProgressionService.updateDiagnostic(
            POLL_DATE_CONTACTS,
            (this._diagnostic.contenuDiagnostic as Polluant).preparation.data?.dateContacts?.date ? 'VALID' : 'INVALID',
            this._diagnostic,
            true
        );
        super.ngOnDestroy();
    }
}
