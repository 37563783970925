<div class="w-20 h-100 d-flex flex-column">
    <app-description-bien-filter-type-prestations
        class="border-bottom"
        [selectedRefPrestations]="selectedRefPrestations"
        (selectedRefPrestationsChange)="handleSelectedRefPrestations($event)"
    >
    </app-description-bien-filter-type-prestations>
    <div class="flex-grow-1 overflow-auto">
        <div
            *ngFor="let element of listEquipementsToDisplay"
            class="d-flex row no-gutters justify-content-between align-items-center px-1 py-2 rounded border-bottom"
        >
            <div class="w-75 d-flex align-items-center">
                <span class="mx-1">
                    <ng-container *ngFor="let typePresta of element | typePrestationForEquipement: allCategoriesEquipements">
                        <ng-container *ngIf="refPrestations[typePresta] | pictoPrestation | async as fileContent">
                            <img
                                *ngIf="fileContent"
                                [src]="fileContent"
                                matTooltip="{{ typePresta | typePrestation }}"
                                alt=""
                                class="rounded rounded-circle diag-equipement"
                            />
                        </ng-container>
                    </ng-container>
                </span>
                <span class="text-small">{{ element.name }}</span>
            </div>
            <div class="w-25 d-flex pr-2 m-0 justify-content-center">
                <button mat-icon-button matTooltip="Dupliquer l'élément" [disabled]="readonlyMode" (click)="handleDupliquerElement(element)">
                    <mat-icon>content_copy</mat-icon>
                </button>
                <button
                    mat-icon-button
                    matTooltip="Supprimer l'élément"
                    class="warn"
                    [disabled]="readonlyMode"
                    *ngIf="!element.objectId"
                    (click)="handleSupprimerEquipement(element)"
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!readonlyMode" class="d-flex flex-column w-80 h-100 justify-content-center m-0 border-left bg-ultralight">
    <div class="w-100 flex-grow-0 d-flex justify-content-between align-items-center p-2 border-bottom">
        <mat-form-field appearance="outline" class="w-25 white-search-form-field small-search-form-field">
            <mat-icon matPrefix>search</mat-icon>
            <input matInput type="text" (keyup)="onKeyupSearchTypesElements($event)" [(ngModel)]="searchTypesElements" placeholder="Rechercher" />
            <button *ngIf="searchTypesElements" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchTypesElements()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <mat-slide-toggle *ngIf="listPiecesPreequipees.length > 0" [(ngModel)]="displayPiecesPreequipees">
            Afficher pièces pré-équipées
        </mat-slide-toggle>
    </div>
    <div class="flex-grow-1 overflow-auto">
        <div *ngIf="displayPiecesPreequipees" class="d-flex row no-gutters justify-content-center">
            <mat-card *ngFor="let piecePreequipee of listPiecesPreequipees" class="d-flex col-2 element-card c-pointer">
                <mat-card-content
                    (click)="handleAjouterPiecePreequipee(piecePreequipee)"
                    class="w-100 h-100 d-flex justify-content-center align-items-center text-center"
                >
                    {{ piecePreequipee.nom }}
                    <button mat-icon-button cdkOverlayOrigin #trigger="cdkOverlayOrigin">
                        <mat-icon (mouseenter)="handleOverlay(trigger, piecePreequipee)" (mouseleave)="handleOverlay(null, null)" class="icon-small"
                            >info
                        </mat-icon>
                    </button>
                    <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="overlayTriggerOrigin" [cdkConnectedOverlayOpen]="isOverlayOpen">
                        <div class="bg-white rounded border">
                            <span class="m-3 font-italic font-weight-bold">Elements inclus</span>
                            <ul class="m-0 p-0">
                                <li class="item-element-preequipe" *ngFor="let element of pieceToDisplay?.equipements">
                                    {{ element.name }}
                                </li>
                            </ul>
                        </div>
                    </ng-template>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="d-flex row no-gutters flex-grow-1 justify-content-center overflow-auto">
            <mat-card *ngFor="let typeElement of listChoixEquipementsToDisplay" class="d-flex col-2 element-card c-pointer">
                <mat-card-content
                    class="w-100 h-100 mh-100 d-flex flex-column justify-content-center align-items-center text-center"
                    (click)="handleAjouterElement(typeElement)"
                >
                    <img
                        *ngIf="typeElement.iconImageData"
                        [src]="typeElement.iconImageData"
                        alt="Pictogramme de l'élément à controler"
                        class="img-element"
                    />
                    <mat-icon *ngIf="!typeElement.iconImageData">cancel</mat-icon>
                    {{ typeElement.name }}
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
