<mat-accordion [togglePosition]="togglePosition">
    <mat-expansion-panel
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
        [expanded]="panelOpenState"
        [ngClass]="'expension-panel--' + theme"
    >
        <mat-expansion-panel-header>
            <mat-panel-title>
                <ng-content select="[title]"></ng-content>
            </mat-panel-title>
            <mat-panel-description>
                <ng-content select="[description]"></ng-content>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div [ngClass]="'expension-panel--' + theme">
            <ng-content></ng-content>
        </div>
    </mat-expansion-panel>
</mat-accordion>
