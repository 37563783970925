import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent, NotificationService } from 'src/app/commons-lib';
import { ValidatorUtils } from 'src/app/utils/validator.utils';
import { Intervention } from '../../../../../model/intervention.model';
import { Bien } from '../../../../../model/bien.model';
import { BackgroundMapApiService, BackgroundMapFileData } from '../../../../../services/background-map-api.service';
import { PerimetreInvestigation } from 'src/app/model/perimetre-investigation.model';

class PerimetreInvestigationModalData {
    constructor(
        public perimetreInvestigation: PerimetreInvestigation,
        public listePerimetresExistant: PerimetreInvestigation[],
        public isCreation: boolean,
        public readonlyMode: boolean,
        public intervention: Intervention
    ) {}
}

@Component({
    selector: 'app-perimetre-investigation-modal',
    templateUrl: './perimetre-investigation-modal.component.html',
    styleUrls: ['./perimetre-investigation-modal.component.scss'],
})
export class PerimetreInvestigationModalComponent extends BaseComponent implements OnInit {
    perimetreInvestigation: PerimetreInvestigation;
    listeBiens: Bien[] = [];
    listePerimetresExistant: PerimetreInvestigation[];
    listeBackgroundBien: BackgroundMapFileData[] = [];
    isCreation: boolean;

    formPerimetre: FormGroup;
    readonlyMode = false;

    constructor(
        private readonly dialogRef: MatDialogRef<PerimetreInvestigationModalComponent>,
        private readonly formBuilder: FormBuilder,
        private readonly backgroundMapApiService: BackgroundMapApiService,
        private readonly notificationService: NotificationService,
        @Inject(MAT_DIALOG_DATA) public data: PerimetreInvestigationModalData
    ) {
        super();
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth1000-dialog');
        this.prepareComponent();
        this.preparePerimetreForm();

        if (!this.isCreation && this.perimetreInvestigation) {
            this.populatePerimetreForm();
        }
    }

    /**
     * Initialisation des différentes variables du composant en fonction des datas passés en paramètre de la modale
     * @private
     */
    private prepareComponent() {
        // selection du périmètre
        this.perimetreInvestigation = this.data.perimetreInvestigation;

        // récupération de la liste des biens
        this.listeBiens = this.data.intervention.relationInterventionBiens.map((relationBien) => relationBien.bien);

        this.listePerimetresExistant = this.data.listePerimetresExistant;
        this.isCreation = this.data.isCreation;

        this.readonlyMode = this.data.readonlyMode;
    }

    /**
     * Crée la base du formulaire pour la creation/edition d'un périmètre
     */
    preparePerimetreForm() {
        const bien = this.listeBiens.find((b) => b.id == this.perimetreInvestigation.idBien) ?? undefined;

        this.formPerimetre = this.formBuilder.group({
            nomPerimetre: [
                this.perimetreInvestigation.nom,
                [
                    Validators.required,
                    (control: AbstractControl) => {
                        if (
                            control.value &&
                            ValidatorUtils.checkAlreadyExist(
                                this.formPerimetre?.controls?.nomPerimetre?.value,
                                this.isCreation,
                                this.perimetreInvestigation?.nom,
                                this.listePerimetresExistant
                                    .filter((perimetre) => perimetre.id != this.perimetreInvestigation.id)
                                    .map((perimetre) => perimetre.nom)
                            )
                        ) {
                            return { erreurNomPerimetreExistant: true };
                        }
                        return null;
                    },
                ],
            ],
            bien: [bien, [Validators.required]],
        });
    }

    /**
     * Initialise les données du formulaire pour l'edition d'un périmètre
     */
    populatePerimetreForm() {
        // selection de la relation intervention bien
        const selectedRelationInterventionBien = this.data.intervention.relationInterventionBiens.find(
            (relationBien) => relationBien.bien.id === this.perimetreInvestigation.idBien
        );
        let selectedBien = null;
        let selectedNiveau = null;

        if (selectedRelationInterventionBien) {
            // selection du bien
            selectedBien = selectedRelationInterventionBien.bien;

            // selection du niveau
            // selectedNiveau = selectedBien.description.find(
            //     (niveau) => niveau.index === this.perimetreInvestigation.indexNiveau
            // );

            // this.prepareBackgroundList(selectedBien);
        } else {
            // Console à garder pour retrouver plus facilement le message d'erreur qui apparait plusieurs fois dans l'appli
            this.notificationService.warn(`Les informations du bien ont changé, des erreurs pourraient survenir.`);
        }

        this.formPerimetre.patchValue({
            nomPerimetre: this.perimetreInvestigation.nom,
            bien: selectedBien,
            // niveau: selectedNiveau,
            // background: null,
        });
    }

    /**
     * Compléter l'entité avant de l'envoyer au back
     */
    prepareDataToSave() {
        this.perimetreInvestigation.nom = this.formPerimetre.controls.nomPerimetre.value;
        const selectedBien = this.formPerimetre.controls['bien'].value;
        this.perimetreInvestigation.idBien = selectedBien.id;
        // this.perimetreInvestigation.indexNiveau = this.formPerimetre.controls['niveau'].value?.index;
        // this.perimetreInvestigation.backgroundFileId = this.formPerimetre.controls['background'].value
        //     ? this.formPerimetre.controls['background'].value.fileId
        //     : null;
    }

    /**
     * Evenement click sur le bouton valider
     */
    onClickValider() {
        if (this.formPerimetre.valid) {
            this.prepareDataToSave();
            this.dialogRef.close({ perimetre: this.perimetreInvestigation });
        }
    }

    /**
     * Evenement click sur le bouton Annuler ou la fermeture de la modale
     */
    onClickCancel() {
        this.dialogRef.close();
    }

    onToggleBackground(background: BackgroundMapFileData) {
        const selectedBackgroundBien = this.formPerimetre.controls['background'].value;
        if (selectedBackgroundBien && selectedBackgroundBien.fileId === background.fileId) {
            this.formPerimetre.controls['background'].setValue(null);
        } else {
            this.formPerimetre.controls['background'].setValue(background);
        }
    }
}
