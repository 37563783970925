import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Agence } from '../model/agence.model';
import { AgenceApiService } from './agence-api.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorService, MongoUtils } from 'src/app/commons-lib';
import { Document } from '../model/document.model';

@Injectable({
    providedIn: 'root',
})
export class AgenceService {
    constructor(private readonly agenceApiService: AgenceApiService, private readonly httpErrorService: HttpErrorService) {}

    /**
     * Renvoie une agence.
     */
    findOne(idAgence: string): Observable<Agence> {
        return this.agenceApiService.findOne(idAgence);
    }

    /**
     * Renvoi la liste des noms d'agence
     */
    findAllAgencesMinimales(): Observable<Agence[]> {
        return this.agenceApiService.findAllAgencesMinimales();
    }

    /**
     * Créer une agence
     * @param agence
     */
    createAgence(agence: Agence): Observable<Agence> {
        return this.agenceApiService.createAgence(agence).pipe(
            catchError((err) => throwError(err)),
            catchError((err) => this.httpErrorService.handleError(err))
        );
    }

    /**
     * Mettre à jour une agence
     * @param agence
     */
    updateAgenceOnline(agence: Agence): Observable<Agence> {
        return this.agenceApiService.updateAgenceOnline(agence).pipe(
            catchError((err) => throwError(err)),
            catchError((err) => this.httpErrorService.handleError(err))
        );
    }
}
