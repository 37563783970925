import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { EtatProgressionService } from '../../../../../../services/etat-progression.service';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { InterventionService } from 'src/app/services/intervention.service';
import { Intervention } from 'src/app/model/intervention.model';
import { Estimation } from '../../../model/estimation.model';
import { Conformite } from '../../../../../../model/conformite.model';
import { Polluant } from '../../../model/polluant.model';
import { PolluantService } from '../../../services/polluant.service';
import { TypePrestationEnum } from 'src/app/model/type-prestation.model';

@Component({
    selector: 'app-etude-recap',
    templateUrl: './recapitulatif.component.html',
    styleUrls: ['./recapitulatif.component.scss'],
})
export class RecapitulatifComponent extends BaseComponent implements OnInit {
    diagnostic: Diagnostic;
    intervention: Intervention;
    diagnosticPolluant: Polluant;
    stateDocuments: Boolean = true;
    objectifs: any[];
    isLoaded: Boolean = false;
    estimation: Estimation;
    invalidDocuments: String[] = [];
    invalidDocumentsName: String = '';
    erreurs: any = {};

    typeMesurage: String;

    polluantConfig: any;

    constructor(
        private diagnosticService: DiagnosticService,
        private cnSpinnerService: CnSpinnerService,
        public interventionService: InterventionService,
        private etatProgressionService: EtatProgressionService,
        private route: ActivatedRoute,
        private polluantService: PolluantService,
        private router: Router
    ) {
        super();
    }

    ngOnInit(): void {
        this.cnSpinnerService.show();
        combineLatest([this.interventionService.getCurrentIntervention(), this.diagnosticService.getCurrentDiagnostic()])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                ([intervention, diag]) => {
                    this.intervention = intervention;
                    this.diagnostic = diag;

                    this.polluantService
                        .findOnePolluantConfigIndexedDB(diag.idConfig)
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe((polluantConfig) => {
                            this.polluantConfig = polluantConfig;

                            //Les valeurs n'est par défaut pas modifiable si il s'agit d'une visite de chantier
                            this.typeMesurage = this.diagnostic.typePrestation;

                            this.checkDocuments();

                            this.diagnosticPolluant = this.diagnostic.contenuDiagnostic as Polluant;
                            this.objectifs = this.diagnosticPolluant.objectifsMesurage.data.objectifsMesurage;
                            this.estimation = this.diagnosticPolluant.estimations.data.estimation as Estimation;

                            this.erreurs['mpcaList'] = this.getErreurs('mpcaList');
                            this.erreurs['zones'] = this.getErreurs('zones');
                            this.erreurs['besoins'] = this.getErreurs('besoins');

                            this.checkValidity();
                            this.isLoaded = true;
                            this.cnSpinnerService.hide();
                        });
                },
                () => this.cnSpinnerService.hide()
            );
    }

    errorKeys() {
        return Object.keys(this.erreurs);
    }

    //récupère les erreurs d'un écran passé en paramètre
    private getErreurs = (nomEcran: string) => {
        let hasError = false;
        let message = '';
        let champs = [];

        if (this.typeMesurage == TypePrestationEnum.POLLUANT_VISITE_CHANTIER && !this.diagnosticPolluant[nomEcran].validated) {
            // En Visite de Chantier, si l'écran n'a pas été validé

            hasError = true;
            message = 'Ecran non-validé';
        } else if (Object.keys(this.diagnosticPolluant[nomEcran].data).length == 0) {
            // Si l'écran n'a pas été visité

            hasError = true;
            message = 'Ecran non-visité';
        } else if (this.diagnosticPolluant[nomEcran].errors.length != 0) {
            // Si l'écran contient des erreurs

            hasError = true;
            champs = this.diagnosticPolluant[nomEcran].errors;
        }

        return {
            hasError,
            champs,
            autre: message,
        };
    };

    private checkDocuments() {
        this.stateDocuments = true;
        this.intervention.documents.forEach((document) => {
            //TODO AC utiliser nouveau modèle points de contrôles documents
            if (document.conformite != Conformite.CONFORME) {
                this.invalidDocuments.push(document.nom);
                this.stateDocuments = false;
            }
        });
        this.invalidDocumentsName = [...new Set(this.invalidDocuments)].join(', '); //suppression doublons
    }

    navigateEcran(nomEcran: string) {
        const url = this.router.url.split('/');
        url.pop();
        url.push(nomEcran);
        this.router.navigateByUrl(url.join('/'));
    }

    private checkValidity() {
        const code = this.route.snapshot.data['code'];
        this.etatProgressionService.updateDiagnostic(code, 'VOID', this.diagnostic);
    }
}
