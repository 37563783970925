import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { NotificationService } from '../commons-lib';
import { SYNC_STATE, SyncState } from '../services/syncState';
import { map } from 'rxjs/operators';

/**
 * Vérification que nous sommes bien en mode online avant d'accéder à certain modules.
 */
@Injectable({
    providedIn: 'root',
})
export class OnlineGuard implements CanActivate {
    constructor(@Inject(SYNC_STATE) private syncState: BehaviorSubject<SyncState>, private notificationService: NotificationService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (this.syncState.value !== SyncState.Online) {
            this.notificationService.notify(`Ce module n'est accéssible qu'en mode 'En-ligne',<br>merci de vérifier votre connexion.`);
            return of(false);
        }
        return of(true);
    }
}
