import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { ManagementApiService } from '../../../services/management-api.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Return true si le mail existe déjà et false sinon
 */
export function emailExistingValidator(managementApiService: ManagementApiService, mailInitial: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
        if (mailInitial && mailInitial.trim() === control.value.trim()) {
            return of(null);
        }
        return managementApiService.isEmailAvailable(control.value).pipe(
            map((result: boolean) => {
                if (mailInitial) {
                    return mailInitial.trim() !== control.value.trim() && result ? { erreurEmail: true } : null;
                } else {
                    return result ? { erreurEmail: true } : null;
                }
            })
        );
    };
}
