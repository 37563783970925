import { Component, Input, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MongoUtils } from 'src/app/commons-lib';
import * as _ from 'lodash';
import { Besoin } from '../../diagnostics/polluant/model/besoin.model';
import { Surface } from '../../diagnostics/polluant/model/surface.model';
import { Zone } from '../../diagnostics/polluant/model/zone.model';

@Component({
    selector: 'besoins-pu-panel',
    templateUrl: './besoins-pu-panel.component.html',
    styleUrls: ['./besoins-pu-panel.component.scss'],
})
export class BesoinsPuPanelComponent implements OnInit {
    indexNiveauSelected: number;
    indexZoneSelected: number;
    indexBienSelected: number = 0;

    @Input() zone: Zone;
    @Input() besoinsPrelevement: Array<any>;
    besoins: Array<Besoin>;
    @Input() showActions: Boolean = true;

    @Input()
    isInEditMode: Boolean = true;

    @Input()
    indexBesoinSelected: number;

    @Input()
    volumes = [];
    surfaces: Surface[];

    @Output() besoinActionEmitter = new EventEmitter<any>();

    constructor() {}

    ngOnInit(): void {
        this.surfaces = this.zone.listeSurfaces;
        this.besoins = this.extractBesoinsArrayFromPrelevements(this.besoinsPrelevement);
    }

    extractBesoinsArrayFromPrelevements(besoinsPrelevements: Array<Besoin>) {
        let _besoins: Array<Besoin> = [];

        besoinsPrelevements.forEach((bp: any) => {
            _besoins.push(bp.besoinPrelevement);
        });
        return _besoins;
    }

    nouveauBesoin(volumeId): void {
        this.besoinAction({ action: 'select', volumeId, besoinId: undefined });
    }

    besoinAction(data) {
        //Selection du volume parent du besoin sélectionné
        //let volume = this.selectedNiveau.volumes.find((item) => item.id == data.volumeId);

        //let besoinToDisplayInForm = data.besoin;
        let besoinIdToDisplay;
        let besoin: Besoin;
        if (data.action == 'delete') {
            //Suppression du besoin de la liste de besoins de ce volume
            this.besoinsPrelevement.splice(data.besoinIndex, 1);
            //On renvoi undefined pour ne plus afficher le formulaire
            besoinIdToDisplay = undefined;

            //Mise à jour de la liste des Besoins (sans les infos de prélèvement)
            // pour être utilisé par app-piece-unitaire-besoin
            this.besoins = this.extractBesoinsArrayFromPrelevements(this.besoinsPrelevement);
        } else if (data.action == 'duplicate') {
            //Duplication du besoin sélectionné à la position suivante du tableau de besoin de ce volume
            let newBesoin = { ...this.besoins[data.besoinIndex] };
            //Incrémentation du numéro de besoin
            newBesoin.numero = (parseInt(newBesoin.numero) + 1).toString();
            this.besoinsPrelevement.splice(data.besoinIndex + 1, 0, newBesoin);
            //Récupération du besoin qui vient d'être inséré
            besoinIdToDisplay = data.besoinIndex + 1;

            //Mise à jour de la liste des Besoins (sans les infos de prélèvement)
            // pour être utilisé par app-piece-unitaire-besoin
            this.besoins = this.extractBesoinsArrayFromPrelevements(this.besoinsPrelevement);
        } else if (data.action == 'new') {
            besoin = new Besoin();
            besoin.numero = (this.besoins.length + 1).toString();
            besoin.pieceId = data.volumeId;
            besoin.zoneId = this.zone.id;
            besoinIdToDisplay = this.besoins.length - 1;
        } else if (data.action == 'select') {
            besoinIdToDisplay = data.besoinIndex;
        } else if (data.action == 'edit') {
            besoin = this.besoins[data.besoinIndex];
        }

        //L'id du besoin à afficher dans le formualaire est envoyé en @Output au composant parent
        //ainsi que la liste à jour des besoins
        this.besoinActionEmitter.emit({
            action: data.action,
            besoinIdToDisplay,
            indexZoneSelected: this.indexZoneSelected,
            besoin: besoin,
        });
    }
}
