import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorService, NotificationService } from 'src/app/commons-lib';
import { SynchronizationService } from 'src/app/services/synchronization.service';
import { formatErrorMessage } from 'src/app/utils/notification.utils';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class AppGlobalErrorhandler extends ErrorHandler {
    // Erreur qui ne doivent pas être affiché sous forme de notification
    private errorsNotDisplayableInNotification = ['aria-labelledby:', 'ExpressionChangedAfterItHasBeenCheckedError:'];

    constructor(private readonly notificationService: NotificationService, private readonly synchronizationService: SynchronizationService, private httpErrorService: HttpErrorService) {
        super();
    }

    handleError(error) {
        // appeler l'implémentation par défaut d'abord, pour correctement loguer l'exception
        super.handleError(error);
        if (this.isErrorDisplayableInNotification(error)) {
            // Pour des erreur
            if (error instanceof HttpErrorResponse) {
                try {
                    this.httpErrorService.handleError(error);
                } catch (e) {}
            } else if (error.status === 504) {
              this.synchronizationService.forceOnlineCheck();
            } else {
                this.notificationService.error({
                    message: formatErrorMessage(error),
                    showCloseButton: true,
                    duration: 0,
                });
            }
        }
    }

    /**
     * Indique l'erreur passée en paramètre doit être affichée dans la notification.
     * @param error
     * @private
     */
    private isErrorDisplayableInNotification(error: any): boolean {
        const hasMessage = !!(error && error.message);
        // Si l'erreur est explicitement tagué pour ne pas être 'notifié', on notifie pas.
        return (
            !error.doNotNotify &&
            hasMessage &&
            !this.errorsNotDisplayableInNotification.some((errorNotToDisplay) => (error.message as string).indexOf(errorNotToDisplay) > -1)
        );
    }
}
