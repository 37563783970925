import { Inject, Injectable, InjectionToken } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SimpleOfflineCache } from './offline-storage';
import { combineLatest, from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { blobToDataUrl, dataUrlToBlob } from './file.utils';
import { wrapConditional } from './conditional-offline-resource-wrapper';
import { SYNC_STATE, SyncState } from 'src/app/services/syncState';

export const IS_OFFLINE_CAPABLE = new InjectionToken<Observable<boolean>>('IS_OFFLINE_CAPABLE');

/**
 * Configuration pour une encapsulation d'une ressource REST.
 * Permet de configurer entre autre l'url de la ressource, le nom de la collection en indexeddb.
 */
export class ResourceWrapperConfig {
    /**
     * L'url de la ressource (incluant l'url de base de l'api).
     */
    resourceUrl: string;
    /**
     * Le nom du cache = nom de la collection indexeddb.
     */
    cacheName: string;
    /**
     * Le nom du champ contenant l'id sur l'entité (souvent 'id').
     */
    idField: string;
    /**
     * Callback pour renvoyer les query params pour la requête http utilisé pour le pull (synchro).
     */
    paramsForPull?: () =>
        | HttpParams
        | {
              [param: string]: string | string[];
          };
    pullUrl?: string;
}

/**
 * Encapsule des appels à une ressource REST pour appeler un cache local (indexed db).
 *
 */
export interface ResourceWrapper<T, ID> {
    /**
     * Renvoie un seul élément par ID (observable).
     * @param id
     */
    getOne(id: ID): Observable<T>;

    /**
     * Renvoie un seul élément en se basant sur un exemple d'objet.
     * Pour les ressources CRUD, on utilise l'ID de cet objet, pour les ressources fichier on utilise plusieurs params de cet objet
     * @param template
     */
    getOneByTemplate(template: T): Observable<T>;

    /**
     * Renvoie plusieurs éléments
     * @param params un objet HttpParams envoyé en tant que queryparams à la requête http en mode en ligne.
     * @param offlineFilter callback permettant de filtrer les résultat en mode hors ligne : en entrée un élément data. Attention n'est pas appelé sur les résultat en ligne.
     * en sortie boolean true pour retenir l'élément, false pour ne pas renvoyer cet élément.
     * @param url permet de surcharger l'url à appeler en mode en ligne.
     */
    getAll(params?: HttpParams, offlineFilter?: (data: T) => boolean, url?: string): Observable<T[]>;

    /**
     * Enregistre (create ou update) un élément.
     * @param data l'élément à sauvegarder.
     */
    save(data: T): Observable<T>;

    /**
     * Supprime un élément.
     * @param data l'élément à supprimer.
     */
    delete(data: T): Observable<boolean>;

    /**
     * Effectue la synchro push (appele POST pour les éléments créés, PUT pour les éléments modifiés, DELETE pour ceux supprimés).
     */
    push(): Observable<any>;

    /**
     * Effectue la synchro pull.
     */
    pull(): Observable<any>;
}

/**
 * Service pour simplifier la gestion du cache des ressources REST en mode hors ligne.
 */
@Injectable({
    providedIn: 'root',
})
export class OfflineStorageService {
    constructor(
        private indexedDb: NgxIndexedDBService,
        private http: HttpClient,
        /**
         * Un observable permettant de déterminer si l'application est en ligne ou hors ligne.
         */
        @Inject(SYNC_STATE) private syncState: Observable<SyncState>,
        @Inject(IS_OFFLINE_CAPABLE) private isOfflineCapable: Observable<boolean>
    ) {}

    /**
     * Renvoie un {@link ResourceWrapper} pour une ressource REST.
     * Le wrapper renvoyé permet d'appeler de façon transparente des méthodes CRUD sur une ressource REST.
     * Le wrapper fait soit des appels en ligne (via http) soit des appels en local (indexeddb) en fonction de l'état de l'application.
     *
     * @param config configuration du wrapper.
     */
    public wrapRestResource<T, ID>(config: ResourceWrapperConfig, forceState?: boolean): ResourceWrapper<T, ID> {
        var cache = new SimpleOfflineCache<T, ID>(config.cacheName, config.idField, this.syncState, this.indexedDb);
        if (forceState != null) {
            let forceSyncState = SyncState.Online;
            if (!forceState) forceSyncState = SyncState.Offline;
            cache = new SimpleOfflineCache<T, ID>(config.cacheName, config.idField, of(forceSyncState), this.indexedDb);
        }

        const wrappedCache = this.wrap(cache);
        const paramsForPull = config.paramsForPull ? config.paramsForPull : () => undefined;
        const pullUrl = config.pullUrl ? config.pullUrl : config.resourceUrl;
        const create = (data) => this.http.post<T>(`${config.resourceUrl}`, data);
        const modify = (data) => this.http.put<T>(`${config.resourceUrl}`, data);
        const getOne = (reqId) => this.http.get<T>(`${config.resourceUrl}/${reqId}`);
        return {
            getOne: (id: ID) => wrappedCache.getOne(getOne).request(id),
            getOneByTemplate: (template: T) => wrappedCache.getOne(getOne).request(template[config.idField]),
            getAll: (params?: HttpParams, offlineFilter?: (data: T) => boolean, customUrl?: string) =>
                wrappedCache
                    .get(
                        () => {
                            return this.http.get<T[]>(customUrl ? customUrl : `${config.resourceUrl}`, {
                                params: params,
                            });
                        },
                        (_, cacheOff) => {
                            return cacheOff.queryAll().pipe(
                                map((data) => {
                                    if (offlineFilter) {
                                        return data.filter((d) => offlineFilter(d.content));
                                    } else {
                                        return data;
                                    }
                                })
                            );
                        }
                    )
                    .request(),
            save: (data: T) => wrappedCache.save(modify).request(data),
            delete: (data: T) =>
                wrappedCache
                    .delete((requestData) => {
                        console.log('delete du wrap normal');
                        return this.http.delete(`${config.resourceUrl}/${requestData[config.idField]}`).pipe(map(() => true));
                    })
                    .request(data),
            push: () =>
                wrappedCache
                    .push(create, modify, (data) => this.http.delete(`${config.resourceUrl}/${data[config.idField]}`).pipe(switchMap(() => of(null))))
                    .request(),
            pull: () =>
                wrappedCache.pull(() =>
                    this.http.get<T[]>(`${pullUrl}`, {
                        params: paramsForPull(),
                    })
                ),
        };
    }

    public wrapFileResource<T extends FileData>(config: FileResourceWrapperConfig<T>): ResourceWrapper<T, string> {
        const cache = new SimpleOfflineCache<T, string>(config.cacheName, config.idField, this.syncState, this.indexedDb);
        const wrappedCache = this.wrap(cache);
        const url = (template) =>
            config.urlByTemplate
                ? `${config.urlByTemplate(template)}/${template[config.idField]}`
                : `${config.resourceUrl}/${template[config.idField]}`;
        const urlForPost = (template) => (config.urlByTemplate ? `${config.urlByTemplate(template)}` : `${config.resourceUrl}`);
        const create = (data) => {
            const formData = new FormData();
            formData.append('file', dataUrlToBlob(data.fileContent));
            formData.append('fileId', data.fileId);
            if (data.fileName) {
                formData.append('fileName', data.fileName);
            }
            const postUrl = urlForPost(data);
            return this.http.post<any>(postUrl, formData).pipe(map(() => data));
        };
        return {
            getOne: (id: string) => {
                throw 'getOne not implemented';
            },
            getOneByTemplate: (template: T) => {
                return wrappedCache
                    .getOne(() => {
                        return this.http
                            .get<Blob>(url(template), {
                                observe: 'response',
                                responseType: 'blob' as 'json',
                            })
                            .pipe(
                                switchMap((response) => combineLatest([of(response.body), from(blobToDataUrl(response.body))])),
                                map(([blob, dataUrl]) => {
                                    return Object.assign(
                                        {
                                            fileContent: dataUrl,
                                        },
                                        template
                                    );
                                })
                            );
                    })
                    .request(template[config.idField]);
            },
            getAll: (params?: HttpParams, offlineFilter?: (data: T) => boolean, customUrl?: string) => {
                throw 'getAll not implemented';
            },
            save: (data: T) => wrappedCache.save(create).request(data),
            delete: (data: T) =>
                wrappedCache
                    .delete((requestData) => {
                        console.log('delete du wrap file');
                        return this.http.delete(url(requestData)).pipe(map(() => true));
                    })
                    .request(data),
            push: () =>
                wrappedCache
                    .push(create, create, (data) => {
                        return this.http.delete(url(data)).pipe(switchMap(() => of(null)));
                    })
                    .request(),
            pull: () => {
                throw 'pull not implemented';
            },
        };
    }

    wrapTaskResource<T>(config: { idField: string; cacheName: string; task: (data?: T) => Observable<T> }): ResourceWrapper<T, string> {
        const notImplemented = () => {
            throw 'not implemented';
        };
        const cache = new SimpleOfflineCache<T, string>(config.cacheName, config.idField, this.syncState, this.indexedDb);
        const wrappedCache = this.wrap(cache);
        //const create = (data: T) => wrappedCache.save(config.task).request(data);
        const create = config.task;
        return {
            getOne: notImplemented,
            getOneByTemplate: notImplemented,
            getAll: notImplemented,
            save: (data: T) => wrappedCache.save(config.task).request(data),
            delete: notImplemented,
            push: () => wrappedCache.push(create, create, notImplemented).request(),
            pull: notImplemented,
        };
    }

    private wrap<T, ID>(cache: SimpleOfflineCache<T, ID>) {
        return wrapConditional(this.isOfflineCapable, cache);
    }
}

export class FileResourceWrapperConfig<T> {
    cacheName: string;
    idField: string;
    urlByTemplate: (template: T) => string;
    paramsForPull: any;
    resourceUrl: string;
}

export interface FileData {
    fileId?: string;
    fileContent?: string;
    fileName?: string;
}
