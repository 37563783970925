import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../lib/authentication/user.model';

@Pipe({
    name: 'userHasRole',
})
export class UserHasRolePipe implements PipeTransform {
    transform(value: User, ...args: string[]): unknown {
        return value.authorities.some((it) => args.some((a) => a === it));
    }
}
