import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NavigationBarsMode } from '../modules/interventions/navigation-bars/navigation-bars.component';
import { TypePrestation } from '../model/type-prestation.model';

export class NavigationBarsConfig {
    refObject?: any;
    mode: NavigationBarsMode;
    activatedRoute: ActivatedRoute | undefined;
    typePrestation: TypePrestation | undefined;
}

@Injectable({
    providedIn: 'root',
})
export class NavigationBarsService {
    /**
     * Subject permettant de s'enregistrer pour recevoir les émissions lors du clique sur les boutons d'actions
     * personnalisées des la barre de navigation.
     */
    private customAction = new Subject<string>();
    private navigationBarConfiguration: BehaviorSubject<NavigationBarsConfig> = new BehaviorSubject({
        mode: 'editor',
        typePrestation: undefined,
        activatedRoute: undefined,
    });
    public navigationBarConfiguration$ = this.navigationBarConfiguration.asObservable();

    /**
     * Emission d'une action personalisée pour réagir au clique sur une bouton d'action de la barre de navigation.
     * La valeur à émettre correspond au code permettant d'identifier le bouton d'action cliqué.
     */
    emitCustomAction(code: string) {
        this.customAction.next(code);
    }

    /**
     * Retourne un Observable du customAction afin de souscrire à ce dernier pour être informé de l'émission d'une
     * action.
     */
    listenCustomAction(): Observable<string> {
        return this.customAction.asObservable();
    }

    configureNavBar(config: NavigationBarsConfig) {
        this.navigationBarConfiguration.next(config);
    }
}
