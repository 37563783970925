import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../authentication/user.service';
import { NotificationService } from '../../notification/notification.service';

@Component({
    selector: 'lib-password-reset-init',
    templateUrl: './password-reset-init.component.html',
    styleUrls: ['./password-reset-init.component.scss'],
})
export class PasswordResetInitComponent implements OnInit {
    /** Classe CSS sur le conteneur principal */
    @Input() containerClass = '';

    email: string;

    constructor(private userService: UserService, private notificationService: NotificationService, private router: Router) {}

    ngOnInit() {}

    submit() {
        this.userService.resetPasswordInit(this.email).subscribe(() => {
            this.router.navigate(['/password-reset/finish']);
            this.notificationService.notify('Un mail vous a été envoyé contenant le code de réinitialisation.');
        });
    }
}
