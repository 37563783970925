'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** facing trimming : a trimming on a facing
//***********************************************************************************
//***********************************************************************************

import { cn_element } from './cn_element';
import { cn_azimut_label, cn_dot, cnx_add, cnx_clone, cnx_cross, cnx_dist, cnx_dot, cnx_mul, cnx_normalize, cnx_sub } from '../utils/cn_utilities';
import { fh_add, fh_cross, fh_dist, fh_dot, fh_matrix, fh_mul, fh_normalize, fh_polygon, fh_sub } from '@acenv/fh-3d-viewer';
import { cn_facing } from './cn_facing';
import { cn_building } from './cn_building';
import { cn_storey_element } from './cn_storey_element';
import { cn_facade } from './cn_facade';

export class cn_facade_lineics extends cn_element {
    /**
     *
     * @param {cn_building} parent
     */
    constructor(point, normal, facing, parent = null) {
        super(parent);

        this.point = point;
        this.normal = normal;
        this.facade = null;
        this.facing = facing;
        this.label = facing.name + ' ' + cn_azimut_label(normal);
        this.polygon = null;
        this.walls = [];
        this.area = 0;
        this.full_area = 0;

        this.lower = [];
        this.bearing = [];
        this.upper = [];
        this.vertical = [];
        this.corner = [];
        this.upper_opening = [];
        this.vertical_opening = [];
        this.upper_opening_depth = [];
        this.vertical_opening_depth = [];
        this.lower_opening = [];
        this.lower_opening_element = [];
        this.lower_door = [];
        this.lower_glass_door = [];
    }

    _remove_lineic(p0, p1, vertices) {
        const threshold = 0.01;
        const ythreshold = 0.05;

        const dir = fh_sub(p1, p0);
        fh_normalize(dir);
        for (var n = 0; n < vertices.length; n += 2) {
            const v0 = vertices[n];
            const v1 = vertices[n + 1];
            const d = fh_sub(v1, v0);
            const l = fh_normalize(d);
            if (fh_dot(d, dir) > -0.999) continue;
            const s0 = fh_sub(p0, v0);
            const x0 = fh_dot(d, s0);
            if (x0 < threshold) continue;
            const ld = Math.sqrt(fh_dot(s0, s0) - x0 * x0);
            if (ld > ythreshold) continue;
            const x1 = fh_dot(d, fh_sub(p1, v0));
            if (x1 > l - threshold) continue;

            vertices.splice(n, 2);

            //*** perfect match */
            if (Math.abs(l - threshold - x0) < 0.01 && Math.abs(x1) < threshold) {
                return [];
            }
            if (x1 > threshold) {
                vertices.push(v0);
                vertices.push(fh_add(v0, fh_mul(d, x1)));
            }
            if (x0 < l - threshold) {
                vertices.push(fh_add(v0, fh_mul(d, x0)));
                vertices.push(v1);
            }

            const new_vertices = [];
            if (x0 > l + threshold) {
                new_vertices.push(p0);
                const x = fh_dot(dir, fh_sub(v1, p0));
                new_vertices.push(fh_add(p0, fh_mul(dir, x)));
            }
            if (x1 < -threshold) {
                const x = fh_dot(dir, fh_sub(v0, p0));
                new_vertices.push(fh_add(p0, fh_mul(dir, x)));
                new_vertices.push(p1);
            }
            if (new_vertices.length == 0) return [];

            var output = [];
            for (var nn = 0; nn < new_vertices.length; nn += 2) {
                const rr = this._remove_lineic(new_vertices[nn], new_vertices[nn + 1], vertices);
                if (rr === false) {
                    output.push(new_vertices[nn], new_vertices[nn + 1]);
                } else if (rr.length)
                    output = output.concat(rr);
            }
            return output;
        }
        return false;
    }

    remove_vertical(p0, p1) {
        return this._remove_lineic(p0, p1, this.vertical);
    }

    remove_lower_horizontal(p0, p1) {
        return this._remove_lineic(p0, p1, this.bearing);
    }

    remove_upper_horizontal(p0, p1) {
        return this._remove_lineic(p0, p1, this.upper);
    }

    //***********************************************************************************
    //**** Draw the contour in svg
    //***********************************************************************************
    draw(camera) {
        var html = '';

        function draw_lineics(vertices, draw_class) {
            for (var n = 0; n < vertices.length; n += 2) {
                const sc0 = camera.world_to_screen(vertices[n]);
                const sc1 = camera.world_to_screen(vertices[n + 1]);
                html += `<line class="${draw_class}" x1="${sc0[0]}" y1="${sc0[1]}" x2="${sc1[0]}" y2="${sc1[1]}" />`;
            }
        }

        draw_lineics(this.bearing, 'bearing_facade_lineic');
        draw_lineics(this.lower, 'lower_facade_lineic');
        draw_lineics(this.upper, 'upper_facade_lineic');
        draw_lineics(this.vertical, 'vertical_facade_lineic');
        draw_lineics(this.corner, 'corner_facade_lineic');
        draw_lineics(this.upper_opening, 'opening_facade_lineic');
        draw_lineics(this.lower_opening, 'lower_opening_facade_lineic');
        draw_lineics(this.lower_door, 'lower_door_facade_lineic');
        draw_lineics(this.vertical_opening, 'opening_facade_lineic');

        return html;
    }

    //***********************************************************************************
    /**
     * Build facade linecis matching a give facing for the building
     * @param {cn_building} building
     * @param {cn_facing} facing
     * @returns {Array<cn_facade_lineics>}
     */
    static build_for_facing(building, facing) {
        if (!facing) {
            return [];
        }

        //*** facing filter function */
        function facing_filter(f) {
            if (facing == null && f == null) return true;
            if (!facing || !f) return false;
            return facing.ID == f.ID;
        };

        const facades = building.get_facades();

        //*** gather polygons */
        const facade_lineics = [];
        building.storeys.forEach(storey => {
            const translation_matrix = new fh_matrix();
            translation_matrix.translate([0, 0, storey.altitude]);
            storey.get_walls().filter(w => w.is_facade(-1, storey)).forEach(wall => {
                for (var side = 0; side < 2; side++) {
                    if (!wall.is_facade(side, storey)) continue;
                    var pgs = wall.build_facing_polygons(side, storey, false, facing_filter);
                    pgs.forEach(pg => {
                        pg.apply_matrix(translation_matrix);
                        pg.offset(0.01);
                        const facade = cn_facade.find_facade_by_wall(wall, side, facades);
                        const facade_point = cnx_clone(facade.point);
                        const facade_normal = cnx_clone(facade.normal);
                        if (facade) {
                            var lineic = facade_lineics.find(f => f.facade == facade);
                            const elt = new cn_storey_element(wall, storey);
                            if (!lineic) {
                                lineic = new cn_facade_lineics(facade_point, facade_normal, facing, building);
                                facade_lineics.push(lineic);
                                lineic.facade = facade;
                                lineic.label = facade.label;
                                lineic.polygon = new fh_polygon(facade_point, facade_normal);
                                lineic.polygon.unites(pg);
                                lineic.walls = [elt];
                            } else {
                                lineic.polygon.unites(pg);
                                const last_elt = lineic.walls[lineic.walls.length - 1];
                                if (!last_elt.equals(elt)) lineic.walls.push(elt);
                            }
                        }
                        /*else
                        {
                            logger.log("No facade found");
                            const f = cn_facade.find_facade(pg.get_point(),pg.get_normal(),facades);
                        }*/
                    });
                }
            });
        });

        //*** build lineics */
        facade_lineics.forEach(facade_lineic => {
            facade_lineic.polygon.offset(-0.01);
            facade_lineic.area = facade_lineic.full_area = facade_lineic.polygon.get_area();
            const polygon = facade_lineic.polygon;
            const normal = facade_lineic.normal;
            var offset = 0;
            for (var nct = 0; nct < polygon.contour_sizes.length; nct++) {
                const sz = polygon.contour_sizes[nct];
                for (var n = 0; n < sz; n++) {
                    const v0 = polygon.contour_vertices[offset + n];
                    const v1 = polygon.contour_vertices[offset + ((n + 1) % sz)];
                    const dir = fh_sub(v1, v0);
                    if (fh_normalize(dir) > 0.01) {
                        if (Math.abs(dir[2]) > 0.707) {
                            facade_lineic.vertical.push(v0);
                            facade_lineic.vertical.push(v1);
                        } else {
                            const dx = fh_cross(dir, normal);
                            if (dx[2] < 0) {
                                facade_lineic.bearing.push(v0);
                                facade_lineic.bearing.push(v1);
                            } else {
                                facade_lineic.upper.push(v0);
                                facade_lineic.upper.push(v1);
                            }
                        }
                    }
                }
                offset += sz;
            }

            //*** apply openings */
            facade_lineic.walls.forEach(storey_element => {
                const wall = storey_element.element;
                var p0 = cnx_clone(wall.bounds.pmin);
                //p0[2] = wall.get_high_offset() + storey_element.storey.altitude;
                const dx = cnx_clone(wall.bounds.direction);
                const dy = cnx_clone(wall.bounds.normal);
                const dz = [0, 0, 1];

                storey_element.element.openings.forEach(opening => {
                    if (opening.valid) {
                        p0[2] = storey_element.element.compute_opening_offset(opening) + storey_element.storey.altitude;
                        const p = cnx_add(p0, cnx_mul(dx, opening.position));
                        const piercing = polygon.clone();
                        const org_piercing = opening.opening_type.build_piercing_polygon(p, dx, dy, dz);
                        piercing.intersects(org_piercing);
                        piercing.compute_contours();
                        facade_lineic.area -= piercing.get_area();
                        var offset = 0;
                        const depth = opening.get_outside_depth();
                        for (var nct = 0; nct < piercing.contour_sizes.length; nct++) {
                            const sz = piercing.contour_sizes[nct];
                            for (var n = 0; n < sz; n++) {
                                const v0 = piercing.contour_vertices[offset + n];
                                const v1 = piercing.contour_vertices[offset + ((n + 1) % sz)];
                                const dir = fh_sub(v1, v0);
                                if (fh_normalize(dir) > 0.01) {
                                    if (Math.abs(dir[2]) > 0.707) {
                                        facade_lineic.vertical_opening.push(v1);
                                        facade_lineic.vertical_opening.push(v0);
                                        facade_lineic.vertical_opening_depth.push(depth);
                                    } else {
                                        const dx = fh_cross(dir, normal);
                                        if (dx[2] < 0) {
                                            facade_lineic.lower_opening.push(v1);
                                            facade_lineic.lower_opening.push(v0);
                                            facade_lineic.lower_opening_element.push(opening);
                                        } else {
                                            facade_lineic.upper_opening.push(v1);
                                            facade_lineic.upper_opening.push(v0);
                                            facade_lineic.upper_opening_depth.push(depth);
                                        }
                                    }
                                }
                            }
                            offset += sz;
                        }
                    }
                });
            });
        });


        //*** merge vertical facades */
        facade_lineics.forEach(facade => {
            for (var n = 0; n < facade.vertical.length; n += 2) {
                //*** only upward verticals */
                const p0 = facade.vertical[n];
                const p1 = facade.vertical[n + 1];
                if (p0[2] < p1[2]) continue;

                //*** direction to wall */
                const dir = cnx_cross(cnx_sub(p1, p0), facade.normal);
                cnx_normalize(dir);

                for (var nv = 0; nv < facade_lineics.length; nv++) {
                    if (facade_lineics[nv] == facade) continue;

                    const res = facade_lineics[nv].remove_vertical(p0, p1);
                    if (res === false) continue;

                    //*** build corners only for outer angles */
                    if (cnx_dot(facade_lineics[nv].normal, dir) > 0) {
                        if (res.length == 0) {
                            facade.corner.push(p0);
                            facade.corner.push(p1);
                        } else {
                            for (var nr = 0; nr < res.length; nr += 2) {
                                if (nr == 0 && fh_dist(p0, res[nr]) > 0.05) {
                                    facade.corner.push(p0);
                                    facade.corner.push(res[nr]);
                                }
                                if (nr + 1 == res.length - 1 && fh_dist(p0, res[nr + 1]) > 0.05) {
                                    facade.corner.push(res[nr + 1]);
                                    facade.corner.push(p1);
                                }
                                if (nr + 2 < res.length) {
                                    facade.corner.push(res[nr + 1]);
                                    facade.corner.push(res[nr + 2]);
                                }
                            }
                        }
                    }
                    facade.vertical.splice(n, 2);
                    facade.vertical = facade.vertical.concat(res);
                    n -= 2;
                    break;
                }
            }
        });

        //*** merge horizontal lower openings facades */
        facade_lineics.forEach(facade => {
            for (var n = 0; n < facade.lower_opening.length; n += 2) {
                const p0 = facade.lower_opening[n];
                const p1 = facade.lower_opening[n + 1];
                const res = facade.remove_lower_horizontal(p0, p1);
                if (res === false) continue;
                facade.lower_opening.splice(n, 2);
                facade.lower_opening = facade.lower_opening.concat(res);
                if (facade.lower_opening_element[n / 2].opening_type.glazing == 'none')
                    facade.lower_door.push(p0, p1)
                else
                    facade.lower_glass_door.push(p0, p1);
                facade.lower_opening_element.splice(n / 2, 1);
                n -= 2;
            }
            for (var n = 0; n < facade.upper_opening.length; n += 2) {
                const p0 = facade.upper_opening[n];
                const p1 = facade.upper_opening[n + 1];
                const res = facade.remove_upper_horizontal(p0, p1);
                if (res === false) continue;
                const depth = facade.upper_opening_depth[n / 2];
                facade.upper_opening.splice(n, 2);
                facade.upper_opening_depth.splice(n / 2, 1);
                facade.upper_opening = facade.upper_opening.concat(res);
                for (var k = 0; k < res.length / 2; k++) facade.upper_opening_depth.push(depth);
                n -= 2;
            }
            for (var n = 0; n < facade.vertical_opening.length; n += 2) {
                const p0 = facade.vertical_opening[n];
                const p1 = facade.vertical_opening[n + 1];
                const res = facade.remove_vertical(p0, p1);
                if (res === false) continue;
                const depth = facade.vertical_opening_depth[n / 2];
                facade.vertical_opening.splice(n, 2);
                facade.vertical_opening_depth.splice(n / 2, 1);
                facade.vertical_opening = facade.vertical_opening.concat(res);
                for (var k = 0; k < res.length / 2; k++) facade.vertical_opening_depth.push(depth);
                n -= 2;
            }
        });
        return facade_lineics;
    }

    /**
     * returns area of opening depth
     * @returns {number}
     */
    get_opening_depth_area() {
        var area = 0;
        for (var n = 0; n < this.vertical_opening.length; n += 2)
            area += this.vertical_opening_depth[n / 2] * cnx_dist(this.vertical_opening[n], this.vertical_opening[n + 1]);

        for (var n = 0; n < this.upper_opening.length; n += 2)
            area += this.upper_opening_depth[n / 2] * cnx_dist(this.upper_opening[n], this.upper_opening[n + 1]);

        return area;
    }
}
