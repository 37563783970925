import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { INITIAL_PAGE_SIZE } from '../../../shared/constants/admin.constants';
import { MatPaginator } from '@angular/material/paginator';
import { BaseComponent } from 'src/app/commons-lib';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { MatDialog } from '@angular/material/dialog';
import { EditResultatModalComponent } from '../../admin/gestion-bons-commande-analyse/components/edit-resultat-modal/edit-resultat-modal.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DATE_FORMAT_FRANCAIS_SANS_HEURE } from 'src/app/shared/constants/cndiag.constants';
import { PrelevementEchantillonageAdmin, ResultatAnalyse, TypeAnalyse } from '../../../model/prelevement-generique.model';
import { Diagnostic } from '../../../model/diagnostic.model';

@Component({
    selector: 'app-bon-commande-view-echantillons-table',
    templateUrl: './bon-commande-view-echantillons-table.component.html',
    styleUrls: ['./bon-commande-view-echantillons-table.component.scss'],
})
export class BonCommandeViewEchantillonsTableComponent extends BaseComponent implements OnInit, OnChanges {
    _canAddResult = false;

    @Input() initialPageSize = INITIAL_PAGE_SIZE;
    @Input() prelevementsEchantillonage: PrelevementEchantillonageAdmin[] = [];

    @Input() canEdit;

    @Input() laboratoireName: string;

    @Input() set canAddResult(canAddResult: boolean) {
        this._canAddResult = canAddResult;
        if (!this.canEdit || this._canAddResult) {
            this.displayedColumns = [
                'reference',
                'laboratoire',
                'typesAnalyse',
                'dateEnvoiAnalyse',
                'description',
                'resultats',
                'dateAnalyse',
                'validation',
                'actions',
            ];
        }
    }

    get canAddResult() {
        return this._canAddResult;
    }

    @Input() readonlyMode = false;

    @Input() diagnostic: Diagnostic;
    @Output() bonDeCommandeLaboChanged = new EventEmitter<any>();
    @Output() onNavigateClicked = new EventEmitter<any>();
    @Output() onCommentAdded = new EventEmitter<any>();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    displayedColumns = ['reference', 'typesAnalyse', 'description'];
    dataSource;
    allChecked = false;
    readonly DATE_FORMAT_FRANCAIS_SANS_HEURE = DATE_FORMAT_FRANCAIS_SANS_HEURE;

    constructor(private readonly matDialog: MatDialog) {
        super();
    }

    ngOnInit(): void {
        this.initDataSource([]);
        if (!this.canEdit || this._canAddResult) {
            this.displayedColumns = [
                'reference',
                'laboratoire',
                'typesAnalyse',
                'dateEnvoiAnalyse',
                'description',
                'resultats',
                'dateAnalyse',
                'validation',
                'actions',
            ];
        }
    }

    ngOnChanges(): void {
        if (this.prelevementsEchantillonage) {
            this.initDataSource(this.prelevementsEchantillonage);
        }
    }

    /**
     * Initialize datasource
     * @param values
     */
    initDataSource(values: any[]) {
        if (values) {
            this.prepareValues(values);
            this.dataSource = new MatTableDataSource(values);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
        }
    }

    /**
     * Action déclenchée lors de la saisie d'un caractère dans la barre de recherche
     * @param $event
     */
    onKeyupSearch($event: any) {
        this.dataSource.filter = $event.target.value;

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    /**
     * Track by for typeanalyse loop
     * @param index
     * @param typeAnalyse
     */
    trackByTypeAnalyse(index: number, typeAnalyse: TypeAnalyse): TypeAnalyse {
        return typeAnalyse;
    }

    trackByResultat(index: number, resultat: ResultatAnalyse): ResultatAnalyse {
        return resultat;
    }

    onClickOpenModalEditResultat(row: PrelevementEchantillonageAdmin) {
        const rowToEdit = { ...row, resultatsAnalyse: [...row.resultatsAnalyse] };

        this.matDialog
            .open(EditResultatModalComponent, {
                data: {
                    echantillonToEdit: rowToEdit,
                },
            })
            .afterClosed()
            .subscribe((res) => {
                if (res && res.id) {
                    const index = this.prelevementsEchantillonage.findIndex((pvt) => pvt.id === res.id);
                    this.prelevementsEchantillonage[index] = res;
                    this.bonDeCommandeLaboChanged.emit(true);
                }
            });
    }

    toggleCheck(event: MatCheckboxChange, row) {
        this.prelevementsEchantillonage.find((it) => it === row).validated = event.checked;
        if (!event.checked) {
            this.allChecked = false;
        }
        this.bonDeCommandeLaboChanged.emit(row);
    }

    toggleCheckAll(checked: boolean) {
        this.prelevementsEchantillonage.forEach((pvt) => {
            if (pvt.resultatsAnalyse.length) {
                pvt.validated = checked;
            }
        });
        if (checked) {
            this.allChecked = true;
        }
        this.bonDeCommandeLaboChanged.emit({ checkValue: checked });
    }

    private prepareValues(pvts: any[]) {
        pvts.forEach((pvt) => {
            const resultat = pvt?.resultatsAnalyse?.find((res) => res.dateAnalyse !== null);
            pvt.dateAnalyse = resultat ? resultat.dateAnalyse : null;
        });
    }

    onClickNavigateToEspace(row) {
        this.onNavigateClicked.emit(row);
    }

    commentChanged(row: any) {
        this.onCommentAdded.emit(row);
    }
}
