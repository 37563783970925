<app-mpca-form-skeleton>
    <div title>Prélèvement ACR</div>
    <div form class="form" [formGroup]="formGeneral" *ngIf="isLoaded">
        <div class="column">
            <div class="section" [formGroup]="formGeneral.get('informationsPrelevement')">
                <div class="header">Informations concernant le prélèvement</div>

                <!-- LOCALISATION DU PRELEVEMENT -->
                <div class="cndiad-form-field">
                    <mat-label class="required">Localisation du prélèvement</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="localisationPrelevement"
                        [disabled]="disabled"
                    >
                        <option value="personnel" datatype="label">Personnel</option>
                        <option value="materiel" datatype="label">Matériel</option>
                        <option value="uniteMobile" datatype="label">Unité mobile</option>
                    </app-widget-select>
                </div>

                <!-- CIRCONSTANCE -->
                <div class="cndiad-form-field">
                    <mat-label class="required">Circonstance</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="circonstance"
                        [disabled]="disabled"
                    >
                        <option value="avant" datatype="html">
                            Avant
                            <mat-icon svgIcon="shower" aria-hidden="false"></mat-icon>
                        </option>
                        <option value="apres" datatype="html">
                            Après
                            <mat-icon svgIcon="shower" aria-hidden="false"></mat-icon>
                        </option>
                    </app-widget-select>
                </div>

                <!-- DATE DU PRELEVEMENT-->
                <mat-form-field>
                    <mat-label>Date du prélèvement</mat-label>
                    <input
                        matInput
                        readonly
                        formControlName="dateTimePrelevement"
                        [ngxMatDatetimePicker]="datepickerDebut"
                    />
                    <mat-datepicker-toggle matSuffix [for]="datepickerDebut"> </mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #datepickerDebut [showSeconds]="false"></ngx-mat-datetime-picker>
                </mat-form-field>

                <!-- REMARQUES  -->
                <mat-form-field appearance="outline">
                    <mat-label>Remarques: </mat-label>
                    <input matInput formControlName="remarques" [readonly]="disabled" />
                </mat-form-field>
            </div>

            <div class="section" [formGroup]="formGeneral.get('informationsCompresseur')">
                <div class="header">Informations compresseur</div>

                <mat-form-field appearance="outline">
                    <mat-label>Marque</mat-label>
                    <mat-select required formControlName="marque" [disabled]="disabled">
                        <mat-option value="absenceStrategie">Test</mat-option>
                        <mat-option value="autre">Autre</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Modèle</mat-label>
                    <mat-select required formControlName="modele" [disabled]="disabled">
                        <mat-option value="absenceStrategie">Test</mat-option>
                        <mat-option value="autre">Autre</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Numéro série</mat-label>
                    <input matInput type="string" formControlName="numeroSerie" [readonly]="disabled" />
                </mat-form-field>
                <div class="cndiad-form-field">
                    <mat-label class="required">Type de compresseur</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="typeCompresseur"
                        [disabled]="disabled"
                    >
                        <option value="avecHuile" datatype="html">
                            Huile
                            <mat-icon>check</mat-icon>
                        </option>
                        <option value="sansHuile" datatype="html">
                            Huile
                            <mat-icon>close</mat-icon>
                        </option>
                    </app-widget-select>
                </div>
                <mat-form-field appearance="outline">
                    <mat-label>Type d'huile</mat-label>
                    <input matInput type="string" formControlName="typeHuile" [readonly]="disabled" />
                </mat-form-field>
            </div>

            <div class="section" [formGroup]="formGeneral.get('conformite')">
                <div class="header">Conformité</div>

                <!-- ACCREDITATION COFRAC -->
                <div class="cndiad-form-field">
                    <mat-label class="required">Accréditation cofrac</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="accreditationCofrac"
                        [disabled]="disabled"
                    >
                        <option value="true" datatype="icon">check</option>
                        <option value="false" datatype="icon">close</option>
                    </app-widget-select>
                </div>
            </div>
        </div>
        <div class="column">
            <mat-card class="info">
                <mat-card-content> Veuillez vérifier que la pression est à 3 bar </mat-card-content>
            </mat-card>

            <div class="section vapeur" [formGroup]="formGeneral.get('vapeurHuile')">
                <div class="header">
                    <div class="space-between">
                        Vapeur d'huile
                        <span>seuil: 0.5 mg/m³</span>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <mat-form-field appearance="outline">
                            <mat-label>Débit (l/min)</mat-label>
                            <input matInput type="string" formControlName="debit" [readonly]="disabled" />
                        </mat-form-field>
                    </div>
                    <div class="column">
                        <mat-form-field appearance="outline">
                            <mat-label>Graduation Maxi</mat-label>
                            <input matInput type="string" formControlName="graduationMaxi" [readonly]="disabled" />
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Concentration mesurée à 10mn</mat-label>
                            <input
                                matInput
                                type="string"
                                formControlName="concentrationMesuree"
                                [readonly]="disabled"
                            />
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="section vapeur" [formGroup]="formGeneral.get('co')">
                <div class="header">
                    <div class="space-between">
                        CO
                        <span>seuil: &lt; 5 ppm</span>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <mat-form-field appearance="outline">
                            <mat-label>Débit (l/min)</mat-label>
                            <input matInput type="string" formControlName="debit" [readonly]="disabled" />
                        </mat-form-field>
                    </div>
                    <div class="column">
                        <mat-form-field appearance="outline">
                            <mat-label>Graduation Maxi</mat-label>
                            <input matInput type="string" formControlName="graduationMaxi" [readonly]="disabled" />
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Concentration mesurée à 10mn</mat-label>
                            <input
                                matInput
                                type="string"
                                formControlName="concentrationMesuree"
                                [readonly]="disabled"
                            />
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="section vapeur" [formGroup]="formGeneral.get('co2')">
                <div class="header">
                    <div class="space-between">
                        CO²
                        <span>seuil: &lt; 1960 mg/m³</span>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <mat-form-field appearance="outline">
                            <mat-label>Débit (l/min)</mat-label>
                            <input matInput type="string" formControlName="debit" [readonly]="disabled" />
                        </mat-form-field>
                    </div>
                    <div class="column">
                        <mat-form-field appearance="outline">
                            <mat-label>Graduation Maxi</mat-label>
                            <input matInput type="string" formControlName="graduationMaxi" [readonly]="disabled" />
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Concentration mesurée à 10mn</mat-label>
                            <input
                                matInput
                                type="string"
                                formControlName="concentrationMesuree"
                                [readonly]="disabled"
                            />
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="section vapeur" [formGroup]="formGeneral.get('vapeurEau')">
                <div class="header">
                    <div class="space-between">
                        Vapeur d'eau
                        <span>seuil: &lt; 1960 mg/m³</span>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <mat-form-field appearance="outline">
                            <mat-label>Débit (l/min)</mat-label>
                            <input matInput type="string" formControlName="debit" [readonly]="disabled" />
                        </mat-form-field>
                    </div>
                    <div class="column">
                        <mat-form-field appearance="outline">
                            <mat-label>Graduation Maxi</mat-label>
                            <input matInput type="string" formControlName="graduationMaxi" [readonly]="disabled" />
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Concentration mesurée à 10mn</mat-label>
                            <input
                                matInput
                                type="string"
                                formControlName="concentrationMesuree"
                                [readonly]="disabled"
                            />
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <button mat-button class="bt bouton-impression" (click)="impressionEtiquette()">
                <lib-icon>printer-wireless</lib-icon>
                {{ "Imprimer l'étiquette" }}
            </button>
        </div>
    </div>
</app-mpca-form-skeleton>
