import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { InterventionService } from '../../../../services/intervention.service';
import { map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Intervention, RelationInterventionBien } from '../../../../model/intervention.model';
import { DiagnosticService } from '../../../../services/diagnostic.service';
import { combineLatest, Observable, of } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ReferenceApiService } from 'src/app/services/reference-api.service';
import { Niveau, ViewMode, Volume } from '../../../../model/bien.model';
import { cn_building, extension_instance } from '@acenv/cnmap-editor';
import { ViewerMapConfig } from '../../../shared/map/viewer-map/viewer-map-config';
import { RulesService } from '../../../../services/rules.service';
import { SyncBienPlanService } from '../../../../services/sync-bien-plan.service';
import { ReferenceService } from '../../../../services/reference.service';
import { EtatWorkflow } from '../../../../model/etat-workflow.model';
import { TypeElementAControler, TypeVolume } from '../../../../model/type-element-a-controler.model';
import {
    DescriptionBienModalEquipementsOuvragesComponent,
    EquipementsOuvragesModalData,
} from '../description-bien-modal-equipements-ouvrages/description-bien-modal-equipements-ouvrages.component';
import { MatDialog } from '@angular/material/dialog';
import { ReferencePrestation } from '../../../../model/reference-prestation.model';
import { DescriptionBienGestionNiveauComponent } from '../description-bien-gestion-niveau/description-bien-gestion-niveau.component';
import { DescriptionBienChoixNiveauComponent } from '../description-bien-choix-niveau/description-bien-choix-niveau.component';
import { BienUtils } from '../../../../utils/bien.utils';
import { CheckValidityTabService } from '../../../../services/check-validity-tab.service';
import { EtatProgressionService } from '../../../../services/etat-progression.service';
import { CnSpinnerService } from '../../../shared/cn-spinner/service/cn-spinner.service';
import { ViewerMapComponent, ViewerMapToolEvent } from '../../../shared/map/viewer-map/viewer-map.component';
import { CategorieOuvrage, CategorieOuvrageMapping } from '../../../../model/categorie-ouvrage.model';
import { Equipement } from '../../../../model/equipement.model';
import { Diagnostic } from '../../../../model/diagnostic.model';

export const MODE_KEY = 'MODE';
export const VOLUME_KEY = 'VOLUME';
export const NIVEAU_KEY = 'NIVEAU';

@Component({
    selector: 'app-description-bien-page',
    templateUrl: './description-bien-page.component.html',
    styleUrls: ['./description-bien-page.component.scss'],
})
export class DescriptionBienPageComponent extends BaseComponent implements OnInit {
    intervention: Intervention;
    relationInterventionBien: RelationInterventionBien;

    viewMode: ViewMode = ViewMode.LIST;
    readonly VIEWMODE = ViewMode;

    currentNiveau: Niveau = new Niveau();
    currentVolume: Volume;

    descriptionDirecte: boolean;
    equipements: Equipement[];
    categoriesOuvrages: CategorieOuvrage[];

    typesVolumes: TypeVolume[] = [];

    // CN_MAP
    bienBuilding: cn_building;
    viewerMapConfig: ViewerMapConfig = {
        isSelectablePieceSansEquipement: false,
        isSelectableEquipement: true,
    };
    externalSelectedElement = null;
    externalSelectedVolume = null;

    filtresRefPrestations: ReferencePrestation[] = [];

    readonlyMode: boolean = false;

    @ViewChild('descriptionBienGestionNiveauComponent')
    descriptionBienGestionNiveauComponent: DescriptionBienGestionNiveauComponent;

    @ViewChild('descriptionBienChoixNiveauComponent')
    descriptionBienChoixNiveauComponent: DescriptionBienChoixNiveauComponent;

    @ViewChild('viewMap') viewMap: ViewerMapComponent;

    private categoriesOuvragesMappings: CategorieOuvrageMapping[];

    constructor(
        private readonly dialog: MatDialog,
        private readonly referenceApiService: ReferenceApiService,
        private readonly interventionService: InterventionService,
        private readonly rulesService: RulesService,
        private readonly route: ActivatedRoute,
        private readonly diagnosticService: DiagnosticService,
        private readonly syncBienPlanService: SyncBienPlanService,
        private readonly referenceService: ReferenceService,
        private readonly checkValidityTabService: CheckValidityTabService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly etatProgressionService: EtatProgressionService
    ) {
        super();
    }

    ngOnInit() {
        this.cnSpinnerService
            .withSpinner(
                combineLatest([
                    this.interventionService
                        .getCurrentIntervention()
                        .pipe(
                            switchMap((intervention) =>
                                this.interventionService.isReadOnlyMode(intervention).pipe(map((readonlyMode) => ({ intervention, readonlyMode })))
                            )
                        ),
                    this.referenceService.findAllTypesVolumes(),
                    this.referenceService.findAllEquipements(),
                    combineLatest([
                        this.referenceService.forceReloadCategoriesOuvrages(),
                        this.referenceService.forceReloadCategoriesOuvragesMappings(),
                    ]),
                    this.route.paramMap,
                ])
            )
            .subscribe(([intervention, typesVolumes, equipements, [categoriesOuvrages, categoriesOuvragesMappings], params]) => {
                this.readonlyMode = intervention.readonlyMode;
                this.intervention = intervention.intervention;
                const idBien = params.get('idBien');
                if (idBien) {
                    this.relationInterventionBien = this.intervention.relationInterventionBiens.find((it) => it.bien.id === idBien);
                } else {
                    this.relationInterventionBien = this.intervention.relationInterventionBiens.find((it) => it.isBienPrincipal);
                }
                this.relationInterventionBien.bien.description.forEach((niveau) => {
                    niveau.volumes.forEach((it) => {
                        this.rulesService.computeJustificationNonVisite(it, this.intervention.commentaires);
                    });
                });

                this.equipements = equipements;
                this.categoriesOuvrages = categoriesOuvrages;
                this.categoriesOuvragesMappings = categoriesOuvragesMappings;
                this.typesVolumes = typesVolumes
                    .sort((c1, c2) => c1.nom.localeCompare(c2.nom))
                    .filter((piece) => piece.etatVolume === EtatWorkflow.ACTIF);

                const interventionId = this.intervention.id;
                const itemInterventionSession = sessionStorage.getItem(interventionId) ? JSON.parse(sessionStorage.getItem(interventionId)) : {};

                const bienId = this.relationInterventionBien.bien.id;
                let itemBienSession = itemInterventionSession[bienId] ? itemInterventionSession[bienId] : null;

                if (itemBienSession) {
                    this.currentNiveau = this.relationInterventionBien.bien.description.find((it) => it.id === itemBienSession[NIVEAU_KEY]);
                    this.currentVolume = itemBienSession[VOLUME_KEY]
                        ? this.currentNiveau?.volumes.find((it) => it.id === itemBienSession[VOLUME_KEY])
                        : null;
                    this.viewMode = itemBienSession[MODE_KEY] ? itemBienSession[MODE_KEY] : this.viewMode;
                }

                if (!itemBienSession || !this.currentNiveau) {
                    const rdc = this.relationInterventionBien.bien.description[0];
                    this.currentNiveau = rdc ?? new Niveau();
                    itemBienSession = {
                        [NIVEAU_KEY]: this.currentNiveau?.id,
                        [VOLUME_KEY]: this.currentVolume ? this.currentVolume.id : null,
                        [MODE_KEY]: this.viewMode,
                    };
                    itemInterventionSession[bienId] = itemBienSession;
                    sessionStorage.setItem(interventionId, JSON.stringify(itemInterventionSession));
                }

                this.refreshCnBuilding();
                this.externalSelectedVolume = this.currentVolume;
                this.checkValidity();
                this.cnSpinnerService.hide();
            });
    }

    refreshJustificationNonVisite() {
        this.currentNiveau.volumes.forEach((it) => {
            this.rulesService.computeJustificationNonVisite(it, this.intervention.commentaires);
        });
        if (this.descriptionBienGestionNiveauComponent) {
            this.descriptionBienGestionNiveauComponent.refreshJustificationNiveau();
        }
        if (this.descriptionBienChoixNiveauComponent) {
            this.descriptionBienChoixNiveauComponent.refreshJustificationNiveaux();
        }
        this.viewMap?.refresh();

        this.checkValidity();
    }

    handleSelectedNiveauChange($event: Niveau) {
        this.setCurrentNiveau($event);
    }

    handleSansDescriptionChange($event: boolean) {
        this.relationInterventionBien.bien.sansDescription = $event;
        this.interventionService.updateIntervention(this.intervention).subscribe(() => {
            this.interventionService.reloadCurrentIntervention();
        });
    }

    handleSelectedVolumeChange($event: Volume) {
        this.setCurrentVolume($event);
    }

    viewMapEvent(event: ViewerMapToolEvent) {
        if (event.event === 'selection_change' && event.value) {
            const selected = event.value[0];
            if (selected.area) {
                if (selected.outside) {
                    this.setCurrentVolume(null);
                } else {
                    this.setCurrentVolume(this.currentNiveau.volumes.find((it) => it.spaceId === event.value[0].ID));
                }
            } else if (selected.object) {
                const volumeSelected = this.currentNiveau.volumes.find((vol) => vol.equipements.find((el) => el.objectId === selected.ID));
                if (volumeSelected) {
                    this.setCurrentVolume(volumeSelected, false);
                }
            }
        }
    }

    handleOpenDialogEquipementOuvrages(data: EquipementsOuvragesModalData) {
        this.dialog
            .open(DescriptionBienModalEquipementsOuvragesComponent, {
                height: '90%',
                width: '100%',
                panelClass: ['modal-overflow-hidden'],
                backdropClass: 'blurred-backdrop',
                disableClose: true,
                maxWidth: '95vw',
                data: {
                    tabToOpen: data.tabToOpen,
                    readonlyMode: this.readonlyMode,
                    niveau: this.currentNiveau,
                    volume: this.currentVolume,
                    categorieOuvrageCode: data.categorieOuvrageCode,
                    filtresRefPrestations: this.filtresRefPrestations,
                },
            })
            .afterClosed()
            .subscribe((resultDialog) => {
                this.setCurrentNiveau(resultDialog.niveau);
                this.setCurrentVolume(resultDialog.volume);
                this.filtresRefPrestations = resultDialog.filtresRefPrestations;
            });
    }

    handleCurrentVolumeChanges($event) {
        this.setCurrentVolume($event);
    }

    handleViewModeChange() {
        this.updateSessionStorageItem(MODE_KEY, this.viewMode);
    }

    handleClickRetourDetailsPiece() {
        this.setCurrentVolume(null);
    }

    private setCurrentVolume(newVolume: Volume, setExternalVolume: boolean = true) {
        this.currentVolume = newVolume;
        this.externalSelectedVolume = setExternalVolume ? newVolume : this.externalSelectedVolume;
        this.updateSessionStorageItem(VOLUME_KEY, this.currentVolume?.id);
    }

    private updateSessionStorageItem(itemKey: string, itemValue) {
        const interventionId = this.intervention.id;
        const bienId = this.relationInterventionBien.bien.id;
        const itemInterventionSession = JSON.parse(sessionStorage.getItem(interventionId));
        const itemBienSession = itemInterventionSession[bienId];
        itemBienSession[itemKey] = itemValue;
        sessionStorage.setItem(interventionId, JSON.stringify(itemInterventionSession));
    }

    private checkValidity() {
        this.checkValidityTabService
            .checkValidityDescription(this.relationInterventionBien.bien)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((etat) => {
                const suffix = BienUtils.getSuffixBien(this.relationInterventionBien.bien, this.intervention.relationInterventionBiens);
                const code = this.route.snapshot.data['code'] + suffix;
                this.etatProgressionService.updateIntervention(code, etat, this.intervention);
            });
    }

    refreshCnBuilding() {
        const typeElements = this.relationInterventionBien.bien.equipements?.concat(this.equipements || []);
        if (this.relationInterventionBien.bien.jsonPlan) {
            const jsonPlanParse = JSON.parse(this.relationInterventionBien.bien.jsonPlan);

            // workaround to manually refresh extension data
            extension_instance.data = JSON.parse(jsonPlanParse.extension_data);

            this.bienBuilding = cn_building.unserialize(jsonPlanParse);
            this.syncBienPlanService.updateBienFromMap(
                this.relationInterventionBien.bien,
                this.bienBuilding,
                typeElements,
                this.typesVolumes,
                this.intervention.diagnostics,
                this.categoriesOuvrages,
                this.categoriesOuvragesMappings
            );
        }
    }

    private setCurrentNiveau(newNiveau: Niveau) {
        if (this.currentNiveau != newNiveau) {
            this.setCurrentVolume(null);
        }
        this.currentNiveau = newNiveau;
        this.updateSessionStorageItem(NIVEAU_KEY, this.currentNiveau?.id);
        this.refreshJustificationNonVisite();
    }
}
