<div class="app-prelevement">
    <div class="sidebar" *ngIf="isLoaded">
        <!-- <zone-surface-panel
            class="zone-surface-panel"
            [biens]="biens"
            [zones]="zones"
            [besoins]="besoins"
            [indexBesoinSelected]="indexBesoinSelected"
            [isInEditMode]="isInEditMode"
            [includeAllNiveaux]="true"
            [includeAllZones]="true"
            [showActions]="false"
            [(indexZoneSelected)]="indexZoneSelected"
            (besoinActionEmitter)="besoinSelected($event)"
        >
            <div class="d-flex mb-2 justify-content-center">
                <button mat-stroked-button class="w-100" (click)="onPlanifierBesoin()">Planifier un besoin</button>
            </div>
        </zone-surface-panel> -->
        <div class="mpca-list" *ngIf="showMpcaList()">
            <app-item-card
                *ngFor="let mpca of contenuDiagnostic.prelevement.data.mpcaList; let i = index"
                class="processus-card"
                (click)="selectMpca(i)"
                [isSelected]="i == selectedMpcaIndex"
            >
                <div title class="title">{{ mpca.general.materiaux }}</div>
                <div action>
                    <button mat-icon-button aria-label="copier" (click)="dupliquerMpca(i)">
                        <mat-icon>content_copy</mat-icon>
                    </button>
                    <button mat-icon-button aria-label="modifier" (click)="modifierMpca(i)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button aria-label="supprimer" (click)="supprimerMpca(i)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </app-item-card>
        </div>
    </div>
    <div class="content">
        <div class="navbar-container">
            <nav class="navbar">
                <a
                    *ngFor="let onglet of onglets; let i = index"
                    (click)="navigationTab(i)"
                    [class.selected]="selectedTab == i ? true : false"
                    >{{ onglet.nom }}</a
                >
            </nav>
        </div>
        <div class="form">
            <ng-template #dynamicComponent></ng-template>
        </div>
    </div>
</div>
