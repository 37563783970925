<form (ngSubmit)="onSubmit()" [formGroup]="formCofrac">
    <div class="toolbar">
        <mat-toolbar class="border-bottom-1 primary">
            <button type="button" mat-button class="px-0" (click)="onClickBack()">
                <lib-icon>arrow_back</lib-icon>
            </button>
            <div>
                <lib-icon [class]="'icon-before-text'">settings</lib-icon>
                <span *ngIf="mode === 'CREATE' && !readonlyMode">Création d'un cofrac</span>
                <span *ngIf="mode === 'EDIT' && !readonlyMode">Édition d'un cofrac</span>
                <span *ngIf="readonlyMode">Consultation d'un cofrac</span>
            </div>

            <div class="d-flex justify-content-end w-100">
                <button type="button" mat-button color="primary" class="mx-1" (click)="onClickBack()">
                    <mat-icon>close</mat-icon>
                    <span>{{ 'Annuler' | uppercase }}</span>
                </button>
                <button
                    *ngIf="!readonlyMode"
                    type="submit"
                    mat-raised-button
                    color="primary"
                    [disabled]="formCofrac.invalid || !cofrac?.logoUrl"
                >
                    <lib-icon>check</lib-icon>
                    <span>{{ 'Valider' | uppercase }}</span>
                </button>
                <button *ngIf="readonlyMode" type="button" mat-raised-button color="primary" (click)="onClickEdit()">
                    <mat-icon>edit</mat-icon>
                    <span>{{ 'Éditer' | uppercase }}</span>
                </button>
            </div>
        </mat-toolbar>
    </div>

    <div class="cofrac-container">
        <div class="h-100 row no-gutters p-4 scrollable-content">
            <!-- Colonne gauche-->
            <div class="col-xl-6 pr-2">
                <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between">
                    <mat-card-content>
                        <!-- Nom -->
                        <div class="d-flex align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Nom</div>
                            <div class="col-xl-8 pr-2">
                                <mat-form-field class="w-100">
                                    <input [readonly]="readonlyMode" matInput type="text" formControlName="nom" />
                                    <app-mat-error [control]="formCofrac.get('nom')"> </app-mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <!-- Numéro -->
                        <div class="d-flex align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Numéro</div>
                            <div class="col-xl-8 pr-2">
                                <mat-form-field class="w-100">
                                    <input [readonly]="readonlyMode" matInput type="text" formControlName="numero" />
                                    <app-mat-error [control]="formCofrac.get('numero')"> </app-mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <!---------------------- Date de validité ------------------->
                        <div class="d-flex align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Date de début</div>
                            <div class="col-xl-8 pr-2">
                                <mat-form-field class="w-100">
                                    <input
                                        [readonly]="readonlyMode"
                                        matInput
                                        type="text"
                                        formControlName="dateDebutValidite"
                                        [matDatepicker]="dateDebutValidite"
                                    />
                                    <app-mat-error [control]="formCofrac.get('dateDebutValidite')"></app-mat-error>
                                    <mat-datepicker-toggle *ngIf="!readonlyMode" matSuffix [for]="dateDebutValidite">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #dateDebutValidite></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <!------------------------ Date d'échéance ------------------->
                        <div class="d-flex align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary">Date d'échéance</div>
                            <div class="col-xl-8 pr-2">
                                <mat-form-field class="w-100">
                                    <input
                                        [readonly]="readonlyMode"
                                        matInput
                                        type="text"
                                        formControlName="dateEcheance"
                                        [matDatepicker]="dateEcheance"
                                    />
                                    <app-mat-error [control]="formCofrac.get('dateEcheance')"></app-mat-error>
                                    <mat-datepicker-toggle *ngIf="!readonlyMode" matSuffix [for]="dateEcheance">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #dateEcheance></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>

                        <!-- Prestation associées -->
                        <div class="d-flex align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary required">
                                Types de prestations associées
                            </div>
                            <div class="col-xl-8">
                                <app-custom-select
                                    class="w-100"
                                    formControlName="typePrestations"
                                    [readonly]="readonlyMode"
                                    [selectControl]="formCofrac.get('typePrestations')"
                                    [matFormFieldClass]="'w-100'"
                                    [choices]="typePrestationsPossible"
                                    [addline]="false"
                                    [multiple]="true"
                                >
                                </app-custom-select>
                                <app-mat-error [control]="formCofrac.get('typePrestations')"> </app-mat-error>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <!--Colonne droite-->
            <div class="col-xl-6 pl-2">
                <!------------ Logo ------------->
                <mat-card class="d-flex w-100 p-2 mb-2">
                    <mat-card-content class="w-100">
                        <div class="col-xl-12 m-0 mb-3 primary">Logo</div>
                        <mat-card *ngIf="!cofrac?.logoUrl && !readonlyMode" class="d-flex w-50 action-card">
                            <mat-card-content class="w-100">
                                <div>
                                    <lib-file-uploader
                                        #fileUploaderLogo
                                        [isImage]="true"
                                        (startUpload)="onClickUploadLogo($event)"
                                        (uploadFinished)="uploadFileFinish('logo', $event)"
                                    ></lib-file-uploader>
                                    <mat-progress-bar
                                        [hidden]="!(fileUploaderLogo.uploadRunning | async)"
                                        [value]="fileUploaderLogo.uploadProgress | async"
                                    ></mat-progress-bar>
                                    <button
                                        class="w-100"
                                        type="button"
                                        mat-button
                                        (click)="fileUploaderLogo.selectFile()"
                                    >
                                        <mat-icon>add_a_photo</mat-icon>
                                        <br />{{ 'Ajouter le logo du cofrac' }}
                                    </button>
                                </div>
                            </mat-card-content>
                        </mat-card>

                        <div *ngIf="cofrac?.logoUrl" class="pl-5">
                            <button
                                *ngIf="!readonlyMode"
                                type="button"
                                mat-icon-button
                                class="position-absolute align-self-end button-group primary"
                                (click)="editPicture = true"
                            >
                                <lib-icon class="icon-small">edit</lib-icon>
                            </button>
                            <div *ngIf="editPicture && !readonlyMode">
                                <div class="position-absolute align-self-end button-group">
                                    <button
                                        mat-icon-button
                                        type="button"
                                        color="warn"
                                        (click)="editPicture = false"
                                        matTooltip="Annuler"
                                    >
                                        <lib-icon class="icon-small">cancel</lib-icon>
                                    </button>
                                    <button
                                        mat-icon-button
                                        type="button"
                                        color="warn"
                                        (click)="onClickDeleteLogo()"
                                        matTooltip="Supprimer le logo du cofrac"
                                    >
                                        <lib-icon class="icon-small">delete</lib-icon>
                                    </button>
                                    <lib-file-uploader
                                        #fileUploaderLogo
                                        [isImage]="true"
                                        (startUpload)="onClickUploadLogo($event)"
                                        (uploadFinished)="uploadFileFinish('logo', $event)"
                                    ></lib-file-uploader>
                                    <button
                                        mat-icon-button
                                        type="button"
                                        color="primary"
                                        (click)="fileUploaderLogo.selectFile()"
                                        matTooltip="Changer le logo du cofrac"
                                    >
                                        <lib-icon class="icon-small">add_a_photo</lib-icon>
                                    </button>
                                </div>
                            </div>
                            <img
                                [src]="cofrac.logoUrl"
                                alt="Logo du cofrac"
                                class="img-fluid"
                                style="max-width: 100%; max-height: 300px"
                            />
                        </div>
                    </mat-card-content>
                </mat-card>

                <!---------- Document cofrac ---------->

                <mat-card class="w-100 position-relative p-2 mb-2">
                    <mat-card-content>
                        <div class="align-items-center">
                            <div class="col-xl-12 m-0 mb-3 primary">Document de cofrac</div>
                            <app-import-file
                                [documentTitle]="documentTitle"
                                [codeTypeDocument]="codeTypeDocument"
                                [fileFormControl]="documentCofracControl"
                                [displayLabel]="false"
                                [readOnlyMode]="readonlyMode"
                            ></app-import-file>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</form>
