// Paramètre points de controle assainissement

import { StateChoiceBoxes } from '../../../../model/states.model';
import { Icon } from 'src/app/commons-lib';

/** Element état */
export const PARAM_ELEMENT_ETAT = 'etat';

/** Element odeur */
export const PARAM_ELEMENT_ODEUR = 'odeur';

/** Boolean si l'odeur est obligatoire pour la conformité */
export const PARAM_ODEUR_IS_REQUIRED = 'odeurIsRequired';

/** Element ecoulement */
export const PARAM_ELEMENT_ECOULEMENT = 'ecoulement';

/** Element rejet */
export const PARAM_ELEMENT_REJET = 'rejet';

/** Element controle */
export const PARAM_ELEMENT_CONTROLE = 'controle';

/** Element materiaux */
export const PARAM_ELEMENT_MATERIAUX = 'materiaux';

/** Element adaptation */
export const PARAM_ELEMENT_ADAPTATION = 'adaptation';

/** Element etancheite */
export const PARAM_ELEMENT_ETANCHEITE = 'etancheite';

/** Element valorisation */
export const PARAM_ELEMENT_VALORISATION = 'valorisation';

/** Element absenceNuisance */
export const PARAM_ELEMENT_ABSENCE_NUISANCE = 'absenceNuisance';

/** Element delais */
export const PARAM_ELEMENT_DELAIS = 'delais';

// *** States ***

// *** Situations ***
// Plus haut
export const aboveState = new StateChoiceBoxes('Plus haut', 'plus_haut', new Icon('arrow_circle_up'));
// Contre-bas
export const belowState = new StateChoiceBoxes('En contre-bas', 'contre_bas', new Icon('arrow_circle_down'));

// *** Type d'assainissement ***
// Collectif
export const collectiveTypeOption = new StateChoiceBoxes('Collectif', 'collectif', new Icon('account-multiple', 'mdi'), 'dark');
// Non collectif
export const notCollectiveTypeOption = new StateChoiceBoxes('Non-collectif', 'non_collectif', new Icon('person'));

// *** Réseau ***
// Réseau unitaire
export const unitNetworkTypeOption = new StateChoiceBoxes('Unitaire', 'unitaire', new Icon('call_merge'));
// Réseau séparatif
export const separativeNetworkTypeOption = new StateChoiceBoxes('Séparatif', 'separatif', new Icon('call_split'));

// *** Toilettes sèches ***
// Présent
export const withDryToiletOption = new StateChoiceBoxes('Toilettes sèches', 'present', new Icon('toilet-paper', 'fa'));
// Absent
export const withoutDryToiletOption = new StateChoiceBoxes('Sans toilettes sèches', 'absent', new Icon('toilet-paper-slash', 'fa'));

// *** Connection à un réseau public ***
// Connecté
export const connectedNetworkOption = new StateChoiceBoxes('Raccordé', 'present', new Icon('check-network', 'mdi'));
// Non connecté
export const notConnectedNetworkOption = new StateChoiceBoxes('Non raccordé', 'absent', new Icon('close-network', 'mdi'));

// *** Alimentation en eau ***
// Connecté
export const connectedWaterSupplyOption = new StateChoiceBoxes('Alimenté', 'present', new Icon('invert_colors'));
// Non connecté
export const notConnectedWaterSupplyOption = new StateChoiceBoxes('Non-alimenté', 'absent', new Icon('invert_colors_off'));

// *** Méthodes de test ***
// Visuel
export const eyeMethodOption = new StateChoiceBoxes('Visuel', 'visuel', new Icon('visibility'));
// Colorant
export const chemicalMethodOption = new StateChoiceBoxes('Colorant', 'colorant', new Icon('flask-outline', 'mdi'));
// Fumigène
export const smokeMethodOption = new StateChoiceBoxes('Fumigène', 'fumigene', new Icon('soundcloud', 'mdi'));

// *** Points de controle ***
// Présent
export const presentOption = new StateChoiceBoxes('Présence', 'present', new Icon('check_circle'), 'ok');
// Absent
export const absentOption = new StateChoiceBoxes('Absence', 'absent', new Icon('cancel'), 'ko');
// Odeur
export const smellyOption = new StateChoiceBoxes("Présence d'odeur", 'present', new Icon('cancel'), 'ko');
// Pas d'odeur
export const notSmellyOption = new StateChoiceBoxes("Absence d'odeur", 'absent', new Icon('check_circle'), 'ok');

// Conformité calculé
export const conformeOption = new StateChoiceBoxes('Conforme', true, new Icon('check_circle'), 'ok');
// Conformité calculé
export const nonConformeOption = new StateChoiceBoxes('Non conforme', false, new Icon('cancel'), 'ko');
// *** Mapping noms pour rapport ***
export const NAMES_MAPPER = {
    visite: [
        {
            valeur: 'ko',
            afficher: 'Non visité',
        },
        {
            valeur: 'warning',
            afficher: 'Partiellement visité',
        },
        {
            valeur: 'ok',
            afficher: 'Visité',
        },
    ],
    etat: [
        {
            valeur: 'ko',
            afficher: 'Mauvais',
        },
        {
            valeur: 'warning',
            afficher: 'Moyen',
        },
        {
            valeur: 'non_visible',
            afficher: 'Non visible',
        },
        {
            valeur: 'ok',
            afficher: 'Bon',
        },
    ],
    ecoulement: [
        {
            valeur: 'ko',
            afficher: 'Incorrect',
        },
        {
            valeur: 'non_visible',
            afficher: 'Non visible',
        },
        {
            valeur: 'ok',
            afficher: 'Correct',
        },
    ],
    odeur: [
        {
            valeur: 'absent',
            afficher: 'Non',
        },
        {
            valeur: 'present',
            afficher: 'Oui',
        },
    ],
    controle: [
        {
            valeur: 'visuel',
            afficher: 'Visuel',
        },
        {
            valeur: 'colorant',
            afficher: 'Colorant',
        },
        {
            valeur: 'fumigene',
            afficher: 'Fumigene',
        },
    ],
    rejet: [
        {
            valeur: 'ko',
            afficher: 'Incorrect',
        },
        {
            valeur: 'non_visible',
            afficher: 'Non visible',
        },
        {
            valeur: 'ok',
            afficher: 'Correct',
        },
    ],
};
