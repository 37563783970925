import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { STORAGE_KEY_OFFLINE_DATE, STORAGE_OFFLINE_DATE_ID } from '../shared/constants/indexeddb.constants';

/**
 * Interface d'accès au stockage local dans IndexedDB pour les interventions.
 */
@Injectable({
    providedIn: 'root',
})
export class OfflineStorageIndexeddbService {
    constructor(private dbService: NgxIndexedDBService) {}

    // -----------------------------------------------------------------------------------------------------------------
    // -- indexeddb Offline Date
    // -----------------------------------------------------------------------------------------------------------------

    setOfflineDate(date: Date): Observable<Date> {
        return this.dbService.addItem(STORAGE_KEY_OFFLINE_DATE, { id: STORAGE_OFFLINE_DATE_ID, date: date }).pipe(
            map((value) => (!!value ? value.date : null)),
            catchError((err) => {
                console.log(err);
                return of(null);
            })
        );
    }

    getOfflineDate(): Observable<Date> {
        return (
            this.dbService.getByID(STORAGE_KEY_OFFLINE_DATE, STORAGE_OFFLINE_DATE_ID) as Observable<{
                id: string;
                date: Date;
            }>
        ).pipe(
            map((value) => (!!value ? value.date : null)),
            catchError((err) => {
                console.log(err);
                return of(null);
            })
        );
    }

    deleteOfflineDate(): Observable<boolean> {
        return this.dbService.deleteByKey(STORAGE_KEY_OFFLINE_DATE, STORAGE_OFFLINE_DATE_ID);
    }
}
