<h1 mat-dialog-title>{{ data.titreModal }}</h1>

<form [formGroup]="formGroup" (ngSubmit)="onClickConfirm()">
    <div mat-dialog-content>
        <!-- input -->
        <div class="d-flex flex-column">
            <div class="mat-subheading-2 m-0 primary">{{ data.titreModal }}</div>
            <mat-form-field class="w-100" appearance="outline">
                <textarea matInput formControlName="valueInput" cdkTextareaAutosize cdkAutosizeMinRows="5"></textarea>
                <app-mat-error [control]="formGroup.get('valueInput')"></app-mat-error>
            </mat-form-field>
        </div>
    </div>

    <div mat-dialog-actions class="justify-content-end">
        <button *ngIf="data.texteBoutonAnnuler" mat-button color="primary" type="button" (click)="onClickCancel()">
            <lib-icon>close</lib-icon>
            <span>{{ data.texteBoutonAnnuler | uppercase }}</span>
        </button>
        <button
            *ngIf="data.texteBoutonValider"
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="formGroup.invalid"
        >
            <lib-icon>check</lib-icon>
            <span>{{ data.texteBoutonValider | uppercase }}</span>
        </button>
    </div>
</form>
