import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { DiagnosticService } from '../services/diagnostic.service';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { InterventionService } from '../services/intervention.service';
import { BaseComponent } from 'src/app/commons-lib';
import { CnSpinnerService } from '../modules/shared/cn-spinner/service/cn-spinner.service';

@Injectable({
    providedIn: 'root',
})
export class QuitDiagnosticGuard implements CanDeactivate<BaseComponent> {
    constructor(private interventionService: InterventionService, private diagnosticService: DiagnosticService, private spinner: CnSpinnerService) {}

    canDeactivate() {
        return combineLatest([this.interventionService.getCurrentIntervention(), this.diagnosticService.getCurrentDiagnostic()]).pipe(
            take(1),
            switchMap(([intervention, diagnostic]) => {
                return this.spinner.withSpinner(this.diagnosticService.upsert(intervention, diagnostic), 'Enregistrement du diagnostic...');
            }),
            map(() => true),
            catchError(() => of(false))
        );
    }
}
