<mat-card
    *ngIf="!this.currentStorey"
    class="w-100 drop-card-placeholder d-flex justify-content-center align-items-center my-3"
>
    Pas de plan disponible pour ce niveau.
</mat-card>
<div
    class="storey-view-content"
    [ngClass]="{ 'storey-view-content-custom': height100, 'bg-selected-space-custom': multiSelection }"
    [hidden]="!currentStorey"
>
    <lib-cn-storey
        #libCnStorey
        [storey]="currentStorey"
        [config]="cnMapConfig"
        [readOnly]="cnStoreyReadonly"
        [showControls]="showControls"
        [config]="{
            showComments: true
        }"
    ></lib-cn-storey>
</div>
