<div>
    <app-bien-niveau-select
        [biens]="biens"
        [column]="true"
        (bienChanged)="changeCurrentBien($event)"
        [selectedBien]="currentBienToDisplay"
        [selectedNiveau]="currentStair"
        (niveauChanged)="changeCurrentStair($event)"
    >
    </app-bien-niveau-select>
</div>

<div *ngIf="pieceSansEquipement"></div>
<!--        Liste des pièces-->
<div *ngIf="!pieceSansEquipement">
    <div *ngIf="currentStair?.pointsDeControleVolumes.length > 0">
        <div
            *ngIf="multipleSelection && isInEditMode && currentStair.pointsDeControleVolumes.length > 1"
            class="d-flex piece-item justify-content-between"
            (click)="changeSelectAll(checkAllCheckedInNiveau())"
        >
            <div class="piece-title">
                <span>Tout sélectionner</span>
            </div>
            <div class="d-flex">
                <mat-checkbox
                    class="mat-checkbox"
                    [checked]="checkAllCheckedInNiveau()"
                    [disabled]="true"
                ></mat-checkbox>
            </div>
        </div>
        <div
            *ngFor="let piece of currentStair.pointsDeControleVolumes"
            class="d-flex piece-item justify-content-between"
            [class.readonly]="!isInEditMode"
            [ngClass]="{ 'piece-item-selected': piece.idVolume === selectedRoom }"
            [class.hide]="piece.volumeCache && piece.pointsDeControleElements.length === 0"
            (click)="changeCurrentRoom(piece)"
        >
            <div class="piece-icon" *ngIf="!multipleSelection">
                <i *ngIf="!piece.valeursParametres[paramVolumeVisite] && !piece.volumeCache" class="fas fa-lock"></i>
            </div>
            <div
                class="piece-title"
                [ngClass]="{ 'piece-empty': controlPieceEmpty && piece.pointsDeControleElements.length === 0 }"
            >
                <span>{{ piece.nom }} ({{ piece.pointsDeControleElements.length }})</span>
            </div>
            <div *ngIf="multipleSelection" class="d-flex">
                <mat-checkbox
                    class="mat-checkbox"
                    [checked]="listVolumeId.includes(piece.idVolume)"
                    [disabled]="true"
                ></mat-checkbox>
            </div>
        </div>
    </div>
</div>
