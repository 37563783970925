import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Environment } from '../environment';

@Injectable()
export class ApiVersionService {
    constructor(private httpClient: HttpClient) {}

    getApiVersion(): Observable<string> {
        return this.httpClient.get(`${Environment.apiUrl}/version`, { responseType: 'text' });
    }
}
