import { Pipe, PipeTransform } from '@angular/core';
import { KeyValuePipe } from '@angular/common';

const keepOrder = (ignored, ignored2) => 0;

/**
 * Keyvalue pipe qui préserve l'ordre d'itération (càd qu'on itère dans l'ordre où les clés sont déclarées).
 */
@Pipe({
    name: 'keyvaluePreserveOrder',
})
export class KeyValuePreserveOrderPipe extends KeyValuePipe implements PipeTransform {
    transform(value, compareFn = keepOrder): any {
        return super.transform(value, compareFn);
    }
}
