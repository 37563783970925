<div class="mesure d-flex flex-row align-items-center mb-2">
    <div class="mesure-label mr-4 {{ mesureCss }}">Surface {{ label }}</div>
    <mat-form-field appearance="outline">
        <input
            matInput
            type="number"
            [placeholder]="MESURE_NON_COMMUNIQUE"
            [disabled]="readonlyMode"
            (click)="!isNonRepute ? openNumKeyboard() : ''"
            [(ngModel)]="volume?.valeursParametres[mesure]"
            (ngModelChange)="valueChangedFromUser()"
            readonly
        />
    </mat-form-field>

    <ng-container *ngIf="!isNonRepute && !isMesurageUtile">
        <div class="button-group-mesure mx-1">
            <button
                mat-icon-button
                color="primary"
                [matTooltip]="tooltipsTools[0]"
                [disabled]="readonlyMode"
                (click)="deduireMesure()"
            >
                <span>=</span>
            </button>
            <button
                mat-icon-button
                color="primary"
                [matTooltip]="tooltipsTools[1]"
                [disabled]="readonlyMode"
                (click)="openCalculator()"
            >
                <mat-icon>calculate</mat-icon>
            </button>
            <button
                *ngIf="mesure == 'hors_carrez' || mesure == 'non_habitable'"
                mat-icon-button
                color="primary"
                [matTooltip]="tooltipsTools[3]"
                [disabled]="readonlyMode"
                (click)="setZero()"
            >
                <mat-icon>exposure_zero</mat-icon>
            </button>
        </div>
        <button
            *ngIf="mesureSuggested"
            mat-raised-button
            color="primary"
            class="py-2 mx-1"
            [disabled]="readonlyMode"
            (click)="addValueSuggested()"
        >
            <mat-icon>auto_fix_normal</mat-icon>
            {{ mesureSuggested + ' m²' }}
        </button>
    </ng-container>

    <ng-container *ngIf="!isNonRepute && isMesurageUtile">
        <div class="button-group-mesure">
            <button
                mat-icon-button
                color="primary"
                [matTooltip]="tooltipsTools[0]"
                [disabled]="readonlyMode"
                (click)="setZero()"
            >
                <mat-icon>exposure_zero</mat-icon>
            </button>
            <button
                mat-icon-button
                color="primary"
                [matTooltip]="tooltipsTools[1]"
                [disabled]="readonlyMode"
                (click)="openCalculator()"
            >
                <mat-icon>calculate</mat-icon>
            </button>
        </div>
    </ng-container>
</div>
