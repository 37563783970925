import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

export enum WidgetSelectModeEnum {
    SELECT = 'select',
    BADGE = 'badge',
}

export enum WidgetSelectThemeEnum {
    WHITE = 'white',
    DARK = 'dark',
}

@Component({
    selector: 'app-widget-select',
    templateUrl: './widget-select.component.html',
    styleUrls: ['./widget-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: WidgetSelectComponent,
            multi: true,
        },
    ],
})
export class WidgetSelectComponent implements OnInit, ControlValueAccessor, AfterViewInit {
    @Input()
    theme = WidgetSelectThemeEnum.DARK;

    @Input()
    mode = WidgetSelectModeEnum.SELECT;

    @Input()
    disabled: boolean = false;

    @Input()
    outlinedWhenUnchecked = false;

    @ViewChild('selectRef') selectRef: ElementRef;

    badgeValueList = [];

    widgetSelectModeEnum: typeof WidgetSelectModeEnum = WidgetSelectModeEnum;

    onChange: (value: string) => void;
    onTouched: any = () => {};

    value: string;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit(): void {}

    ngAfterViewInit() {
        if (this.selectRef) {
            const options = this.selectRef.nativeElement.options;
            if (options) {
                Array.prototype.forEach.call(options, (option) => {
                    const type = option.getAttribute('datatype');
                    if (type == 'html') {
                        this.badgeValueList.push({
                            label: this.sanitizer.bypassSecurityTrustHtml(option.innerHTML),
                            value: option.value,
                            type: option.getAttribute('datatype'),
                            color: option.getAttribute('data-color'),
                        });
                    } else {
                        this.badgeValueList.push({
                            label: option.innerHTML,
                            value: option.value,
                            type: option.getAttribute('datatype'),
                            color: option.getAttribute('data-color'),
                        });
                    }
                });
            }
        }
    }

    change(event) {
        if (this.disabled) return;
        if (event.target) {
            this.value = event.target.value;
        } else {
            this.value = event;
        }
        this.onChange(this.value);
    }

    writeValue(obj: any): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
