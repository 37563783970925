import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Competence } from '../../../model/user-wizy.model';
import { MatTableDataSource } from '@angular/material/table';
import { DATE_FORMAT_FRANCAIS_SANS_HEURE } from 'src/app/shared/constants/cndiag.constants';
import { Document } from '../../../model/document.model';
import { DocumentFileService } from '../../../services/document-file.service';

@Component({
    selector: 'app-tableau-competences',
    templateUrl: './tableau-competences.component.html',
    styleUrls: ['./tableau-competences.component.scss'],
})
export class TableauCompetencesComponent extends BaseComponent implements OnChanges {
    readonly DATE_FORMAT_FRANCAIS_SANS_HEURE = DATE_FORMAT_FRANCAIS_SANS_HEURE;

    @Input()
    competences?: Competence[];

    @Input()
    canEdit?: boolean;

    @Output()
    editCompetenceEvent = new EventEmitter<Competence>();

    @Output()
    deleteCompetenceEvent = new EventEmitter<Competence>();

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    displayedColumns: string[] = ['nom', 'organisme', 'numero', 'login', 'dateObtention', 'dateFin', 'action'];
    dataSource: MatTableDataSource<Competence>;
    sort: Sort;

    constructor(private readonly documentFileService: DocumentFileService) {
        super();
    }

    ngOnChanges(data): void {
        if (data.competences) {
            this.dataSource = new MatTableDataSource<Competence>(this.competences);
        }
    }

    onEditButtonClicked(row: Competence) {
        this.editCompetenceEvent.emit(row);
    }

    onDeleteButtonClicked(row: Competence) {
        this.deleteCompetenceEvent.emit(row);
    }

    /**
     *  Ouverture de la pièce jointe
     */
    onClickOpenDocument(doc: Document) {
        this.documentFileService.downloadFileByFileId(doc).subscribe();
    }
}
