<app-mpca-form-skeleton>
    <div title>Informations sur la zone de prélèvement</div>
    <div form>
        <div [formGroup]="formInformation" class="form-general">
            <!-- NOM -->
            <mat-form-field appearance="outline">
                <mat-label>Nom</mat-label>
                <input matInput required formControlName="nom" [readonly]="disabled" />
            </mat-form-field>

            <!-- ACTIVITE -->
            <mat-form-field appearance="outline">
                <mat-label>Activité</mat-label>
                <mat-select required formControlName="activite" [disabled]="disabled">
                    <mat-option
                        *ngFor="let item of polluantConfig.contenuListeDeroulante.usageLocal.items"
                        [value]="item"
                    >
                        {{ item }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- TYPE DE BATIMENT -->
            <mat-form-field appearance="outline">
                <mat-label>Usage des locaux</mat-label>
                <mat-select required formControlName="usageLocaux" [disabled]="disabled">
                    <mat-option
                        *ngFor="let item of polluantConfig.contenuListeDeroulante.usageLocaux.items"
                        [value]="item"
                    >
                        {{ item }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- TYPE ACTIVITE -->
            <mat-form-field appearance="outline">
                <mat-label>Type Activité</mat-label>
                <mat-select required formControlName="typeActivite" [disabled]="disabled">
                    <mat-option
                        *ngFor="let item of contenuDiagnostic.visiteDeChantier.data.activitesAmiante.valeurs"
                        [value]="item"
                    >
                        {{ item }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- OBJECTIFS DE MESURAGE -->
            <mat-form-field appearance="outline">
                <mat-label>Objectifs de mesurage</mat-label>
                <mat-select
                    formControlName="objectifsMesurage"
                    multiple
                    [disabled]="disabled"
                    [compareWith]="PolluantService.compareObjectifs"
                >
                    <mat-option
                        *ngFor="
                            let item of contenuDiagnostic.visiteDeChantier.data.objectifsMesurage.data.objectifsMesurage
                        "
                        [value]="item"
                        [disabled]="isItemDisabled(item)"
                    >
                        {{ item.reference }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- VENTILATION -->
            <div class="cndiad-form-field">
                <mat-label class="required">Ventilation</mat-label>
                <app-widget-select
                    [mode]="widgetSelectModeEnum.BADGE"
                    formControlName="ventilation"
                    [disabled]="disabled"
                >
                    <option
                        [value]="item.label"
                        datatype="html"
                        *ngFor="let item of polluantConfig.contenuListeDeroulante.ventilationZone.items"
                    >
                        <mat-icon *ngIf="item.icon != ''" aria-hidden="false" [svgIcon]="item.icon"></mat-icon>
                        {{ item.label }}
                    </option>
                </app-widget-select>
            </div>

            <!-- ENVIRONNEMENT -->
            <div class="cndiad-form-field">
                <mat-label class="required">Environnement</mat-label>
                <app-widget-select
                    [mode]="widgetSelectModeEnum.BADGE"
                    formControlName="environnement"
                    [disabled]="disabled"
                >
                    <option
                        *ngFor="let item of polluantConfig.contenuListeDeroulante.typeEnvironnement.items"
                        [value]="item.label"
                        datatype="html"
                    >
                        <mat-icon *ngIf="item.icon != ''" aria-hidden="false">{{ item.icon }}</mat-icon>
                        {{ item.label }}
                    </option>
                </app-widget-select>
            </div>
        </div>
    </div>
</app-mpca-form-skeleton>
