import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Composant input type number avec des boutons pour incrémenter/décrémenter.
 */
@Component({
    selector: 'lib-increment-input',
    templateUrl: './increment-input.component.html',
    styleUrls: ['./increment-input.component.scss'],
})
export class IncrementInputComponent {
    /** Permet au longPress de s'arrêter quand on a atteind le min ou le max du compteur */
    public stopLong = new EventEmitter<boolean>();

    private _value: number;

    @Input()
    set value(value: number) {
        this.setValue(value, false);
    }

    get value() {
        return this._value;
    }

    private _step = 1;

    @Input()
    set step(_step: number) {
        this._step = this.parseNumber(_step);
        this.nbDecimals = this.countDecimals(_step);
    }

    get step(): number {
        return this._step;
    }

    private _min = 0;

    @Input()
    set min(value: number) {
        if (value != null && !isNaN(value)) this._min = value;
    }

    get min(): number {
        return this._min;
    }

    private _max = Infinity;

    @Input()
    set max(value: number) {
        if (value != null && !isNaN(value)) this._max = value;
    }

    get max(): number {
        return this._max;
    }

    @Input()
    label = '';

    @Input()
    disabled = false;

    @Input()
    readonly = false;

    @Input()
    required = false;

    @Input()
    fullWidth = false;

    @Input()
    matFormFieldClass = '';

    @Input()
    hideButtonsWhenDisabled = false;

    @Input()
    hint: string;

    @Input()
    matHintClass = '';

    /**
     * Changement de la valeur par utilisateur (non émit si le changement vient de l'@Input)
     */
    @Output()
    valueChangeFromUser = new EventEmitter<number>();

    /**
     * Changement du modèle (utilisateur ou non)
     */
    @Output()
    valueChange = new EventEmitter<number>();

    private nbDecimals = 0;

    constructor() {}

    decrementValue(): void {
        if (this._value == null) {
            this.setValueFromUser(this._max);
            return;
        }
        if (parseFloat((this._value - this._step).toFixed(this.nbDecimals)) === this._min) {
            this.setValueFromUser(this._min);
            this.stopLong.emit(true);
            return;
        }
        if (this._value <= this._min) {
            this.stopLong.emit(true);
            return;
        }

        this.stopLong.emit();
        this.setValueFromUser(parseFloat((this._value - this._step).toFixed(this.nbDecimals)));
    }

    incrementValue(): void {
        if (this._value == null) {
            this.setValueFromUser(this._min);
            return;
        }
        if (parseFloat((this._value + this._step).toFixed(this.nbDecimals)) === this._max) {
            this.setValueFromUser(this._max);
            this.stopLong.emit(true);
            return;
        }
        if (this._value >= this._max) {
            this.stopLong.emit(true);
            return;
        }
        this.stopLong.emit();
        this.setValueFromUser(parseFloat((this._value + this._step).toFixed(this.nbDecimals)));
    }

    inputManuallyChanged() {
        // Changement de l'utilisateur directement dans l'input
        // => il faut forcer l'émission de l'output indiquant un changement manuel (car "setValue" est appelé avec fromUser=false dans ce cas)
        this.valueChangeFromUser.emit(this._value);
    }

    private setValueFromUser(value: number) {
        this.setValue(value, true);
    }

    private setValue(value: number, fromUser: boolean) {
        let changed = false;
        if (this._value != value) {
            changed = true;
        }
        if (value == null) {
            this._value = null;
        } else if (isNaN(value)) {
            this._value = NaN;
        } else {
            this._value = this.parseNumber(value);
        }
        if (changed) {
            this.valueChange.emit(this._value);
            if (fromUser) {
                this.valueChangeFromUser.emit(this._value);
            }
        }
    }

    private parseNumber(num: number): number {
        return +num;
    }

    private countDecimals(value) {
        if (Math.floor(value) === value) return 0;
        return value.toString().split('.')[1].length || 0;
    }
}
