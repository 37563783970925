import { AfterViewChecked, Component, ElementRef, Injector, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Diagnostic } from '../../../../../../../model/diagnostic.model';
import { Zone } from '../../../../model/zone.model';
import { MatDialog } from '@angular/material/dialog';
import { ZoneProprieteModalComponent } from '../zone-propriete-modal/zone-propriete-modal.component';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { InterventionService } from 'src/app/services/intervention.service';
import { Intervention, RelationInterventionBien } from 'src/app/model/intervention.model';
import { Bien, Niveau, Volume } from 'src/app/model/bien.model';
import { PointDeControleBien } from 'src/app/model/point-de-controle.model';
import { Polluant } from '../../../../model/polluant.model';
import { Surface } from '../../../../model/surface.model';
import { PolluantConfig } from '../../../../model/polluant-config.model';
import { IValidatableComponent } from '../../../../services/interfaces/validatable-component.interface';
import { PolluantComponent } from '../../../../utils/polluant-component';
import { EtatValidation } from 'src/app/model/etat-progression.model';
import { ConfirmationService, NotificationService } from 'src/app/commons-lib';
import cloneDeep from 'lodash/cloneDeep';
import { cn_building, cn_space, cn_storey, zone_colors } from '@acenv/cnmap-editor';
import { Besoin } from '../../../../model/besoin.model';
import { ConfirmDialogComponent } from 'src/app/lib/confirmation/confirm-dialog.component';
import { PerimetreInvestigation } from 'src/app/model/perimetre-investigation.model';
import { BaseObject } from '../../../../../../../model/base-object.model';
import { WidgetSelectSearchComponent } from '../../../../../../shared/widget-select-search/widget-select-search.component';
import { MesurageService } from '../../../../../mesurage/services/mesurage.service';
import { MesureComponent } from 'src/app/modules/diagnostics/mesurage/modules/mesure/mesure.component';
import { ViewerMapConfig } from '../../../../../../shared/map/viewer-map/viewer-map-config';
import { TypePrestationEnum } from 'src/app/model/type-prestation.model';
import { ViewerMapComponent } from '../../../../../../shared/map/viewer-map/viewer-map.component';
import { CommentModalComponent } from 'src/app/modules/shared/comment-modal/comment-modal.component';
import { DialogUtils } from 'src/app/utils/dialog.utils';
import { enumTypesCommentaire } from 'src/app/model/type-commentaire.model';

@Component({
    selector: 'app-zone-polluant',
    templateUrl: './zone.component.html',
    styleUrls: ['./zone.component.scss'],
})
export class ZoneComponent extends PolluantComponent implements OnInit, IValidatableComponent, AfterViewChecked {
    TypePrestationEnum = TypePrestationEnum;
    @ViewChild('viewMap') viewMap: ViewerMapComponent;

    cnMapConfig: any = {
        drawGrid: true,
        /** Afficher l'échelle ? */
        drawScale: true,
        /** Afficher les commentaires ? */
        showComments: true,
        /** Afficher le fond de carte ? */
        drawBackground: true,
        /** Afficher l'étage inférieur ? */
        drawPreviousStorey: true,
        /** Afficher les extérieurs ? */
        drawExterior: true,
        /** Opacité de l'étage inférieur */
        previousStoreyOpacity: true,
        /** Opacité de l'extérieur */
        exteriorOpacit: true,
        /** Afficher le nom des espaces ? */
        drawSpaceNames: true,
        /** Afficher la surface des espaces ? */
        drawSpaceAreas: true,
        /** Afficher le rendu des types des murs ? */
        drawWallTypes: true,
        /** Afficher les revêtements */
        drawFacings: true,
        /** Afficher les angles ? */
        showAngles: true,
        /** Afficher les côtes ? */
        showMeasures: true,
        /** Afficher la vue du haut des objets ? */
        showObjectsTopView: true,
        /** Afficher les icônes des objets ? */
        showObjectsIcons: true,
        /** Afficher la boussole ? */
        showCompass: true,
        /** Afficher la box de redimensionnement des poteaux ? */
        drawHeightBox: true,
        /** Afficher la sélection des mesures d'un espace ? */
        showSpaceMeasure: true,
        /** Padding pour la caméra */
        /** Mode preview */
        storeyPrevie: true,
        padding: { left: 30, right: 30, top: 0, bottom: 0 },
    };

    @Input()
    disabled: Boolean = true;
    @Input()
    listeIdVolumes = [];
    @ViewChild(WidgetSelectSearchComponent) selectSearch: WidgetSelectSearchComponent;

    listVolumePu = [];

    biens: Bien[] = [];
    pdcBiens: PointDeControleBien[] = [];
    filteredPointsDeControles: PointDeControleBien[] = [];

    perimetreInvestigation: PerimetreInvestigation;

    //Panel data
    listVolumeDisabled: String[] = [];
    private _volumesPartages: String[] = []; // Même volumes dans plusieurs zones

    listVolumeId: String[] = [];
    listVolumeChecked: String[] = [];

    indexZone: number = 0;
    indexSurface: number = 0;

    zone: Zone = null;
    volumes: Volume[] = [];
    surfaces: any[];

    form: FormGroup;
    intervention: Intervention;
    cnBuilding: cn_building;
    currentDiagnostic: Diagnostic;

    polluantConfig: PolluantConfig;

    volumesSurface: any = {};

    volumeStoreyLinkObject: any = {};

    previousFormValue: any;

    lienVolumeObj = {};

    message?: string;
    externalSelectedVolumes: Volume[] = [];

    noDescription = false;
    viewMode = 'list';
    pagesPanelOpened = true;
    selection: {
        get currentBien(): PointDeControleBien;
        // get currentRelationBien(): RelationInterventionBien;
        get bienBuilding(): cn_building;
    };
    zonesPerimetre: Zone[];

    currentMapTool: string = 'SELECTION';
    // elementToLocate: Zone;

    constructor(
        public dialog: MatDialog,
        private cnSpinnerService: CnSpinnerService,
        public interventionService: InterventionService,
        public injector: Injector,
        public route: ActivatedRoute,
        private router: Router,
        private readonly notificationService: NotificationService,
        private el: ElementRef,
        private renderer: Renderer2,
        private readonly confirmationService: ConfirmationService,
        private mesurageService: MesurageService
    ) {
        super(injector);
    }
    volumesSurfaceSubject: any;
    ngOnInit(): void {
        this.cnSpinnerService.show();
        this.interventionService.data$.subscribe((newVolumesSurface) => {
            this.volumesSurfaceSubject = newVolumesSurface;
        });
        combineLatest([
            this.interventionService.getCurrentIntervention(),
            this.diagnosticService.getCurrentDiagnostic(),
            this.route.data,
            this.route.paramMap,
        ])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                ([intervention, diagnostic, routerData, params]) => {
                    const currentIdPerimetreInvestigation = params.get('idPerimetreInvestigation');
                    this.indexZone = 0;
                    this.intervention = intervention;
                    this.currentDiagnostic = diagnostic;
                    this.contenuDiagnostic = diagnostic.contenuDiagnostic as Polluant;
                    this._perimetreInvestigationSelected = this.contenuDiagnostic.perimetresInvestigation.data.listePerimetreInvestigation.find(
                        (p) => p.id == currentIdPerimetreInvestigation
                    );

                    // Redirection vers le premier périmetre d'investigation
                    if (!currentIdPerimetreInvestigation) {
                        this.cnSpinnerService.hide();
                        if (this.contenuDiagnostic?.perimetresInvestigation?.data?.listePerimetreInvestigation?.length) {
                            this.router.navigate([
                                this.router.url,
                                this.contenuDiagnostic.perimetresInvestigation.data.listePerimetreInvestigation[0].id,
                            ]);
                            return;
                        } else {
                            // Redirection vers l'écran périmètre ?
                            return;
                        }
                    }

                    combineLatest([this.polluantService.findOnePolluantConfigIndexedDB(this.diagnostic.idConfig)])
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe(([polluantConfig]) => {
                            if (polluantConfig == null) {
                                this.notificationService.error('Erreur : Merci de faire une synchro pour récupérer les configs en local.');
                                this.cnSpinnerService.hide();
                                return;
                            }
                            this.polluantConfig = polluantConfig;

                            this.isValidated = this.contenuDiagnostic.zones.validated;
                            this.pdcBiens = this.currentDiagnostic.pointsDeControleBiens;

                            this.biens = this.intervention.relationInterventionBiens.map((relationBienPrincipal) => relationBienPrincipal.bien);
                            // Récupération du bien associé au périmètre d'investigation
                            this.currentBien = this.intervention.relationInterventionBiens.find((value) => {
                                return value.bien.id == this._perimetreInvestigationSelected.idBien;
                            });
                            this.currentNiveau = this.currentBien.bien.description[0];

                            this.restoreData();

                            this.form = this.createForm();

                            this.findDisabledVolumes();
                            this._isContextTravaux();
                            this.getVolumesSurface();
                            this.checkValidity();

                            this.zoneSelectedByIdx(this.indexZone);
                            this.cnBuilding = MesureComponent.loadBuilding(this.currentRelationBien.bien);

                            this.cnSpinnerService.hide();
                            this.isInEditMode =
                                !(!!routerData.readonly || this.diagnosticService.isReadOnlyMode(intervention, this.diagnostic)) && this.isInEditMode;
                            // this.checkEcransDependants(this.contenuDiagnostic);
                            if (!this.isInEditMode) {
                                this.form.disable();
                            }
                            this.message = this.contenuDiagnostic.zones.message;
                            this.contenuDiagnostic.zones.message = '';
                            this.surfaces = this.getSelectionnableSurfaces();
                            this.isLoaded = true;
                        });
                },
                () => this.cnSpinnerService.hide()
            );
    }

    /**
     * Charge le bien
     * @private
     */
    private getVolumesSurface() {
        let currentBien = this.diagnostic.pointsDeControleBiens[0];

        let bienBuilding: cn_building;

        if (currentBien) {
            const bien = this.biens.find((b) => b.id === currentBien.idBien);
            //Si le plan est utilisé, alors on charge ses données pour récupérer la superficie des pièces
            if (bien && bien.jsonPlan) {
                bienBuilding = cn_building.unserialize(JSON.parse(bien.jsonPlan));
            } else {
                this.volumesSurface = {};
                return;
            }

            bienBuilding.storeys.forEach((storey: cn_storey) => {
                storey.scene.spaces.forEach((space: cn_space) => {
                    if (space.name == 'Extérieur') return;

                    let volume = this.volumeStoreyLinkObject[storey.ID].find((volume: Volume) => {
                        return volume.spaceId == space.ID;
                    });

                    if (volume == undefined) return;

                    this.volumesSurface[volume.id] = space.declared_area > -1 ? space.declared_area : space.area;
                });
            });
            this.interventionService.updateVolumesSurfaces(this.volumesSurface);
        }
    }

    private createForm() {
        //Création du formulaire global
        const form = new FormGroup({ zonesList: new FormArray([]) });
        //Le formulaire est initialisé soit avec les données passées en paramètre,
        // sinon avec les données du diag
        let zones = undefined; //= data ?? this.contenuDiagnostic.zones.data.zonesList;

        if (this.contenuDiagnostic.zones.data.zonesList) {
            zones = this.contenuDiagnostic.zones.data.zonesList.filter((element) => {
                return element.idPerimetre == this.currentIdPerimetreInvestigation;
            });
        }
        //Si des données de Zones existent, création d'un nouveau formulaire
        //Sinon initialisation d'un nouveau FormGroup de Zone
        if (zones != undefined && zones.length > 0) {
            this.contenuDiagnostic.zones.data.zonesList.forEach((zone) => {
                const zoneForm = this.createNewFormGroupZone(zone);
                (form.get('zonesList') as FormArray).push(zoneForm);
            });
        } else {
            if (this.contenuDiagnostic.zones.data.zonesList && this.contenuDiagnostic.zones.data.zonesList.length > 0) {
                this.contenuDiagnostic.zones.data.zonesList.forEach((zone) => {
                    const zoneForm = this.createNewFormGroupZone(zone);
                    (form.get('zonesList') as FormArray).push(zoneForm);
                });
            } else {
                this.contenuDiagnostic.perimetresInvestigation.data.listePerimetreInvestigation.forEach((perimetre) => {
                    let zone = new Zone();
                    zone.idPerimetre = perimetre.id;
                    const zoneForm = this.createNewFormGroupZone(zone);
                    (form.get('zonesList') as FormArray).push(zoneForm);
                });
            }
        }
        this.zonesPerimetre = form.get('zonesList').value.filter((element) => {
            return element.idPerimetre == this.currentIdPerimetreInvestigation;
        });
        return form;
    }

    createNewFormGroupZone(zone: Zone) {
        let require = Validators.nullValidator;
        if (zone.typeZone == 'Zone de travail') require = Validators.required;
        return new FormGroup({
            id: new FormControl(zone.id),
            identifiant: new FormControl(zone.identifiant),
            nom: new FormControl(zone.nom),
            usageLocaux: new FormControl(zone.usageLocaux, Validators.required),
            objectifMesurage: new FormControl(zone.objectifMesurage, Validators.required),
            ventilation: new FormControl(zone.ventilation, Validators.required),
            environnement: new FormControl(zone.environnement, Validators.required),
            frequentation: new FormControl(zone.frequentation, Validators.required),
            personnesPresentes: new FormControl(zone.personnesPresentes, Validators.required),
            listeMpca: new FormControl(zone.listeMpca, Validators.required),
            listeSurfaces: this.getSurfaceGroup(zone.listeSurfaces),
            zoneContexte: new FormControl(zone.zoneContexte, Validators.required),
            typeZone: new FormControl(zone.typeZone, Validators.required),
            zse: new FormControl(zone.zse),
            storeyId: new FormControl(this.currentNiveau.storeyId),
            idZoneAssociee: new FormControl(zone.idZoneAssociee),
            zoneAssociee: new FormControl(zone.zoneAssociee),
            activiteAdjacente: new FormControl(zone.activiteAdjacente, require),
            confinement: new FormControl(zone.confinement, require),
            nombreExtracteurs: new FormControl(zone.nombreExtracteurs, require),
            sasMateriel: new FormControl(zone.sasMateriel, require),
            sasPersonnel: new FormControl(zone.sasPersonnel, require),
            idPerimetre: new FormControl(zone.idPerimetre),
        });
    }

    getSurfaceGroup(listeSurfaces: Surface[]) {
        var surfaceFormArray = new FormArray([]);
        listeSurfaces.forEach((surface) => {
            const surfaceForm = this.createNewSurfaceForm(surface);
            surfaceFormArray.push(surfaceForm);
        });

        return surfaceFormArray;
    }

    createNewSurfaceForm(surface: Surface) {
        const formSurface = new FormGroup(
            {
                superficie: new FormControl(surface.superficie, Validators.required),
                longueurMax: new FormControl(surface.longueurMax, {
                    validators: surface.inferieurSeuilMin ? undefined : Validators.required,
                    updateOn: 'change',
                }),
                inferieurSeuilMin: new FormControl(surface.inferieurSeuilMin, {
                    validators: [Validators.required],
                    updateOn: 'change',
                }),
                listeIdVolume: new FormControl(surface.listeIdVolume, Validators.required),
                listeCommentaire: new FormControl(surface.listeCommentaire),
                commentairesId: new FormControl(surface.commentairesId),
                nombrePu: new FormControl(surface.nombrePu),
                longueurInterface: new FormControl(surface.longueurInterface),
                hauteurInterface: new FormControl(surface.hauteurInterface),
                surfaceInterface: new FormControl(surface.surfaceInterface),
                longueurSurfaceTravail: new FormControl(surface.longueurSurfaceTravail),
                largeurSurfaceTravail: new FormControl(surface.largeurSurfaceTravail),

                activeSurfaceInterface: new FormControl(surface.activeSurfaceInterface),
                typeSurface: new FormControl(surface.typeSurface),
                aditionnalSurfaveValue: new FormControl(surface.aditionnalSurfaveValue),
                surfaceCalcul: new FormControl(surface.surfaceCalcul),
            },
            { updateOn: 'blur' }
        );
        //UpdateOn: Blur permet de ne trigger l'event ValueChange que lorsque l'utilisateur
        //          clique en dehors du champ

        formSurface.valueChanges.subscribe((value) => {
            this.formArraySurfaces.controls.forEach((formSurface: FormGroup) => {
                let surface = formSurface.getRawValue();
                let puNb: number = 1;
                if (surface.typeSurface === 'LONGUEUR_MAX') {
                    if (surface.superficie < 100) {
                        puNb = surface.aditionnalSurfaveValue ? Math.ceil(surface.aditionnalSurfaveValue / 15) : 1;
                    } else {
                        puNb = Math.ceil((14 * surface.superficie) / (730 + surface.superficie));
                    }
                } else {
                    if (surface.surfaceCalcul > 100 || surface.typeSurface === 'SURFACE_INTERFACE') {
                        puNb = Math.ceil((14 * surface.surfaceCalcul) / (730 + surface.surfaceCalcul));
                    } else if (surface.superficie > 100) {
                        puNb = Math.ceil((14 * surface.superficie) / (730 + surface.superficie));
                    }
                }
                formSurface.get('nombrePu').setValue(puNb, { emitEvent: false });
            });
            //Mise à jour du diagnostic
            this.checkValidity();
        });

        return formSurface;
    }

    addZone() {
        //Création de la zone
        this.isEditingZone = false;
        //Ouverture de la modal d'édition
        this.openModal();
    }

    editZone() {
        this.selectSurfaceByIndex(undefined);
        this.isEditingZone = true;
        this.openModal();
    }

    addSurface() {
        const surface = new Surface();
        surface.inferieurSeuilMin = true;
        const newSurface = this.createNewSurfaceForm(surface);
        this.formArraySurfaces.push(newSurface);
        this.selectSurfaceByIndex(this.formArraySurfaces.length - 1); // Selection de la dernière surface ajoutée

        //Mise à jour du diagnostic
        this.checkValidity();
    }

    getTypeZseByIndex(index) {
        return this.formZones.controls[index]?.get('zse').value;
    }

    /**
     * @description Supprime une surface et débloque les volumes qu'elle contenait
     * @param indexSurface l'index de la surface a supprimer dans le liste de surfaces de la zone actuelle
     */
    removeSurfaceByIndex(indexSurface) {
        this.selectSurfaceByIndex(undefined);
        const _volumesASupprimer: Array<String> = [...this.formArraySurfaces.value[indexSurface].listeIdVolume];
        let nbVolumesSupprimee = 0;
        _volumesASupprimer.forEach((idVolume: String) => {
            const refDiag = this.removeVolumeFromSurface(idVolume, indexSurface);
            if (refDiag) {
                refDiag.afterClosed().subscribe((result) => {
                    if (result && result !== false) {
                        nbVolumesSupprimee++;
                        if (nbVolumesSupprimee === _volumesASupprimer.length) {
                            this.formArraySurfaces.removeAt(indexSurface);
                        }
                    }
                });
            } else {
                nbVolumesSupprimee++;
            }
        });
        if (nbVolumesSupprimee === _volumesASupprimer.length) {
            this.formArraySurfaces.removeAt(indexSurface);
        }

        this.findDisabledVolumes();
        this.selectFirstSurfaceCard();
        this.checkValidity();
    }

    removeVolume(event) {
        this.removeVolumeFromSurface(event.idVolume, event.indexSurface);
    }

    /**
     * @description Supprime un volume d'une surface et met à jour la liste des volumes desactivés
     * @param idVolume id du volume à supprimer
     * @param indexSurface index de la surface dans laquelle se trouve le volume
     */
    removeVolumeFromSurface(idVolume, indexSurface) {
        //Vérification si ce volume est associé à des besoins de prélèvements
        const besoinsAssocies: Besoin[] = this.contenuDiagnostic.besoins.data?.besoinsList?.filter((besoin: Besoin) => {
            return besoin.pieceId == idVolume || besoin.piecesId.includes(idVolume);
        });
        if (besoinsAssocies?.length > 0) {
            let message = 'Attention, ce volume est associé aux besoins de prélèvement suivants:';
            besoinsAssocies.forEach((besoin: Besoin) => {
                message += `<br/>- BP${besoin.numero}`;
            });
            message += '<br/><br/>Retirer ce volume de la zone entrainera la suppression de ces besoins de prélèvement.';
            message += '<br/>Confirmez-vous la suppression ?';

            const refDiag = this.matDialog.open(ConfirmDialogComponent, {
                data: {
                    message,
                },
            });
            refDiag.afterClosed().subscribe((result) => {
                if (result && result !== false) {
                    //Si l'opération est confirmée, la ou les références sont supprimée
                    const filteredBesoins = this.contenuDiagnostic.besoins.data.besoinsList.filter((besoin: Besoin) => {
                        return besoin.pieceId !== idVolume && !besoin.piecesId.includes(idVolume);
                    });
                    this.contenuDiagnostic.besoins.data.besoinsList = filteredBesoins;
                    // Mise à jour des marker de la liste
                    this._deleteVolume(idVolume, indexSurface);
                    // Suppresion du marker
                    this._perimetreInvestigationSelected.listeMarkersJson = this._perimetreInvestigationSelected.listeMarkersJson.filter(
                        (markerJson) => {
                            const obj = JSON.parse(markerJson);
                            return !besoinsAssocies.map((besoin) => besoin.id).includes(obj.idReference);
                        }
                    );
                    // Suppression du lien au besoin
                    besoinsAssocies.forEach((b) => {
                        b.pieceId = null;
                        b.piecesId = [];
                    });
                }
            });
            return refDiag;
        } else {
            this._deleteVolume(idVolume, indexSurface);
        }
        this.selectSurfaceByIndex(this.indexSurface);
        this.computeVolumes();
        return null;
    }

    sommeMesureVolumes(idsPieces: string[]): number {
        const somme = idsPieces.reduce((acc, idPiece) => {
            const mesurePiece = this.volumesSurface[idPiece];
            if (mesurePiece !== undefined) {
                return acc + mesurePiece;
            }
            return acc;
        }, 0);
        return Math.round(somme);
    }

    _deleteVolume(idVolume, indexSurface) {
        //Suppression de l'id du volume supprimé de la liste des volumes de la surface
        let _currentSurface = this.formArraySurfaces.at(indexSurface) as FormGroup;
        let _listeVolumes = (_currentSurface.getRawValue() as Surface).listeIdVolume;
        _listeVolumes.splice(_listeVolumes.indexOf(idVolume), 1);
        //Patcher les valeurs pour mettre à jour le formulaire
        _currentSurface.get('listeIdVolume').setValue(_listeVolumes);
        //Si la surface est vide, alors définir la superficie à 0
        this.updateSurfaceFromVolumes(_currentSurface, _listeVolumes);
        _currentSurface.get('typeSurface').setValue('');

        //Suppression de l'id du volume supprimé de la liste des volumes désactivés
        this.listVolumeDisabled.splice(this.listVolumeDisabled.indexOf(idVolume), 1);
        this.listVolumeChecked = [...this.listVolumeDisabled];

        //Mise à jour du diagnostic
        this.checkValidity();
        this.computeVolumes();
    }

    /**
     * Calcul la superficie de la surface en fonction des volumes qui la compose
     * @param listeVolumes la listes des volumes
     * @param currentSurface la surface dont la superficie est a calculer
     */
    updateSurfaceFromVolumes(currentSurface, listeVolumes) {
        if (listeVolumes.length == 0) {
            currentSurface.get('superficie').setValue(0);
        } else {
            const _totalVolumesSurface = this.sommeMesureVolumes(listeVolumes);
            currentSurface.get('superficie').setValue(_totalVolumesSurface);
            if (!currentSurface.get('typeSurface').value) {
                currentSurface.get('surfaceCalcul').setValue(_totalVolumesSurface);
            }
        }
    }

    openModal() {
        let formZone;
        if (this.isEditingZone) {
            formZone = cloneDeep(
                this.formZones.controls.find((control) => control.get('id').value == this.zonesPerimetre[this.indexZone].id) as FormGroup
            );
        } else {
            let newZone: Zone = new Zone();
            newZone.idPerimetre = this.currentIdPerimetreInvestigation;
            formZone = this.createNewFormGroupZone(newZone);
        }
        const dialogRef = this.matDialog.open(ZoneProprieteModalComponent, {
            width: '250px',
            data: {
                sequence: {
                    action: 'new',
                },
                form: formZone,
                polluantConfig: this.polluantConfig,
                indexZone: this.indexZone,
                isInEditMode: this.isInEditMode,
                lienVolumeObj: this.lienVolumeObj,
                zone: this.zone,
                zoneName: this.zoneName,
                formBuilder: this.formBuilder,
                formArraySurfaces: this.formArraySurfaces,
                formZones: this.formZones,
                route: this.route,
                diagnosticPolluant: this.contenuDiagnostic,
                currentDiagnostic: this.currentDiagnostic,
                typeMesurage: this.typeMesurage,
                etatProgressionService: this.etatProgressionService,
                notificationService: this.notificationService,
                isNewZone: this.isEditingZone,
            },
            disableClose: true,
            autoFocus: false,
        });

        dialogRef.afterClosed().subscribe(({ action, form }) => {
            if (action === 'cancel') {
                // this.indexZone = _index;
                if (dialogRef.componentInstance.isDuplication) {
                    // delete le form ajouté
                    this.formZones.removeAt(this.formZones.length - 1);
                }
                this.zoneSelected(this.indexZone);
                return;
            } else if (action === 'delete') {
                //Suppression des volumes associés aux surfaces de la zone à supprimer
                this.formArraySurfaces.value.forEach((surface, index) => {
                    this.removeSurfaceByIndex(index);
                });
                this.formZones.removeAt(this.formZones.value.findIndex((zone) => zone.id === this.zonesPerimetre[this.indexZone].id));
                this.zonesPerimetre.splice(this.indexZone, 1);
                this.indexZone = this.indexZone - 1;
                this.zoneSelected(this.indexZone);
            } else {
                // Sauvegarder
                if (form != undefined) {
                    if (this.isEditingZone) {
                        if (dialogRef.componentInstance.isDuplication) {
                            // C'est forcément le dernier
                            this.indexZone = this.zonesPerimetre.length;
                        }
                        this.zonesPerimetre[this.indexZone] = form;
                        this.formZones.controls
                            .find((control) => control.get('id').value === form.id)
                            .setValue(form, {
                                emitEvent: false,
                            });
                        this.zoneSelected(this.indexZone);
                    } else {
                        //Création du formulaire de la nouvelle zone et ajout au formulaire global
                        this.zonesPerimetre.push(form);
                        // Sélection de l'index de la nouvelle zone
                        this.formZones.push(this.createNewFormGroupZone(form));
                        this.indexZone = this.zonesPerimetre.length - 1;
                        this.zoneSelected(this.indexZone);
                    }
                }
                this.selectSearch.clearInput();
                this._isContextTravaux();
                this.zoneSelected(this.indexZone);
            }
            this.checkValidity();
        });
    }

    numToSSColumn(num) {
        let s = '',
            t;

        while (num > 0) {
            t = (num - 1) % 26;
            s = String.fromCharCode(65 + t) + s;
            num = ((num - t) / 26) | 0;
        }
        return s || undefined;
    }

    /**
     * Restaures les données sauvegardées dans le Diagnostic courant
     */
    restoreData() {
        //Restaure la liste des id de volume disponible définis dans le périmètre
        this.listVolumeId =
            this._perimetreInvestigationSelected?.listeVolumeId != undefined ? this._perimetreInvestigationSelected.listeVolumeId : [];
        if (this.contenuDiagnostic.zones.data.zonesList) {
            this.zone = this.contenuDiagnostic.zones.data.zonesList.filter((element) => {
                return element.idPerimetre == this._perimetreInvestigationSelected.id;
            });
        }

        //Récupère tous les volumes
        this.volumes = this.getAllVolumes();
    }

    /**
     * @description Fais le lien entre les volumes et les contextes d'objectifs de mesurage
     */
    private findDisabledVolumes() {
        this.lienVolumeObj = {};

        //Création du tableau de lien entre les volumes et les objs de mesurage
        //pour empêcher deux volumes d'être présents dans deux zones possédant un même objectif de mesurage
        this.zonesPerimetre.forEach((formZone) => {
            formZone.listeSurfaces.forEach((surface) => {
                surface.listeIdVolume.forEach((idVolume: string) => {
                    if (!formZone.objectifMesurage) {
                        return;
                    }
                    const tableauRefs = formZone.objectifMesurage.reduce((acc, obj: any) => {
                        return acc.concat(obj.parentId);
                    }, []);

                    this.lienVolumeObj[idVolume] = (this.lienVolumeObj[idVolume] || []).concat(tableauRefs);
                });
            });
        });

        //Boucle sur toutes les surfaces de toutes les zones pour lister les volumes et ajouter leurs id à la
        // liste des volumes desactivés - this.listVolumeDisabled
        this.listVolumeDisabled = [];
        let formArraySurfaces = this.formCurrentZone.get('listeSurfaces') as FormArray;
        formArraySurfaces.value.forEach((surface) => {
            surface.listeIdVolume.forEach((id) => {
                const vol = this.volumes.find((x) => x.id === id);
                if (vol != undefined) this.listVolumeDisabled.push(vol.id);
            });
        });

        // Vérification si un volume est présent dans une autre zone
        // avec au moins un objectif de mesurage similaire
        // si oui alors ce volume ne peut pas être ajouté
        this._volumesPartages = [];
        this.formCurrentZone.get('objectifMesurage').value.forEach((obj) => {
            this.volumes.forEach((volume) => {
                if (this.lienVolumeObj[volume.id] == undefined) return;
                if (this.lienVolumeObj[volume.id].indexOf(obj.parentId) != -1 && !this.listVolumeDisabled.includes(volume.id)) {
                    // Cette option est suppprimée de façon non définitive :
                    // this.listVolumeDisabled.push(volume.id);
                    this._volumesPartages.push(volume.id);
                }
            });
        });

        //A l'initialisation, les volumes cochés sont uniquement ceux qui sont désactivés aka déjà assignés
        this.listVolumeChecked = [...this.listVolumeDisabled];
    }

    navigationZone(e) {
        let newZoneIndex;
        if (e == 'next') newZoneIndex = this.indexZone + 1;
        else if (e == 'previous') newZoneIndex = this.indexZone - 1;
        this.zoneSelected(newZoneIndex);
        this.selectSearch.clearInput();
    }

    /**
     *
     * @param value
     * @returns
     */
    previousOrNextZone(value: 1 | -1): Zone {
        return this.zonesPerimetre[this.indexZone + value];
    }

    zoneSelected(index) {
        this.indexZone = parseInt(index);
        this.zone = this.zonesPerimetre[index];
        if (this.zone) {
            this.zoneName = !this.zone.identifiant && !this.zone.nom ? 'Nom de zone' : `${this.zone.identifiant} : ${this.zone.nom}`;
        }
        this.selectSurfaceByIndex(0);
        // Sélectionne la première carte de la zone
        this.selectFirstSurfaceCard();
        this.findDisabledVolumes();
    }

    private selectFirstSurfaceCard() {
        this.formArraySurfaces.controls = this.formArraySurfaces.controls.map((value, index) => {
            value['selected'] = index === 0;
            return value;
        });
    }

    /**
     * Sélectionne une zone
     * @param index
     */
    zoneSelectedByIdx(index) {
        this.selectSurfaceByIndex(undefined);
        this.indexZone = parseInt(index);
        this.zone = this.zonesPerimetre[index];
        if (this.zone) {
            this.zoneName = !this.zone.identifiant && !this.zone.nom ? 'Nom de zone' : `${this.zone.identifiant} - ${this.zone.nom}`;
        }
        this.findDisabledVolumes();
    }

    changeZoneSelected(zone: Zone) {
        if (this.zone === zone) return;
        let index = this.getSelectedOptionIndex(zone);
        if (index != -1) {
            this.zone = this.formZones.value[index];
            this.zoneSelected(index);
        }
    }

    getSelectedOptionIndex(zone: Zone): number {
        if (zone) {
            return this.zonesPerimetre.findIndex((option) => option.id === zone.id);
        }
        return -1;
    }

    /**
     * @description Fonction appellée lorsque l'utilisateur clique sur "Valider"
     *              dans le panel de sélection des Volumes.
     * @param listVolume La liste des TOUS les index des volumes sélectionnés,
     *                   y compris ceux déjà désactivés
     */
    handleVolumePanelSubmit(listVolume, pIndexForm?: number) {
        const volumesId = listVolume.filter((x) => this._volumesPartages.includes(x));
        if (volumesId.length) {
            let msg = 'Attention, ';
            msg += volumesId.length === 1 ? 'le volume ci-dessous est déjà intégré' : 'les volumes ci-dessous sont déjà intégrés';
            msg += ' dans une autre zone<ul>';
            for (const id of volumesId) {
                msg += `<li>${this.volumes.find((v) => v.id === id).nom}</li>`;
            }
            msg += '</ul>Êtes-vous sûr de vouloir continuer ?';
            this.confirmationService.confirmWarn(msg, () => this.handleVolumePanelSubmitConfirme(listVolume, pIndexForm));
        } else {
            this.handleVolumePanelSubmitConfirme(listVolume, pIndexForm);
        }
    }

    /**
     * @See handleVolumePanelSubmit
     * @param listVolume
     * @param pIndexForm
     */
    private handleVolumePanelSubmitConfirme(listVolume, pIndexForm?: number) {
        //Récupère la surface concernée
        const indexForm = pIndexForm >= 0 ? pIndexForm : this.indexSurface;
        let selectedSurface = this.formArraySurfaces.at(indexForm) as FormGroup;

        // Récupère la liste des volumes nouvellement sélectionnés en faisant la différence
        // entre la liste de tous les volumes sélectionnés et la liste des volumes désactivés
        let _volumesToAdd = listVolume.filter((x) => !this.listVolumeDisabled.includes(x));

        //Ajoute les nouveaux volumes à la liste des volumes de la surface sélectionnée.
        const _listeIdVolume = selectedSurface.value.listeIdVolume.concat(_volumesToAdd);
        const _totalVolumesSurface = this.sommeMesureVolumes(_listeIdVolume);

        // Vérification Regroupement de locaux
        if (_listeIdVolume.length > 1) {
            if (_listeIdVolume.length > 4) {
                return this.notificationService.warn('Limite de regroupement de locaux atteinte !');
            } else if (+_totalVolumesSurface > 100) {
                return this.notificationService.warn('Limite de superficie au sol atteinte !');
            }
        }

        selectedSurface.get('typeSurface').setValue('');
        selectedSurface.get('listeIdVolume').setValue(_listeIdVolume);
        selectedSurface.get('superficie').setValue(_totalVolumesSurface);
        if (!selectedSurface.get('typeSurface').value) {
            selectedSurface.get('surfaceCalcul').setValue(_totalVolumesSurface);
        }

        //Mise à jour de la liste des volumes désactivés et cochés
        this.listVolumeDisabled = this.listVolumeDisabled.concat(_volumesToAdd);
        this.listVolumeChecked = [...this.listVolumeDisabled];

        this.zonesPerimetre = this.formZones.value.filter((element) => {
            return element.idPerimetre == this.currentIdPerimetreInvestigation;
        });

        //Déselectionne la surface et verrouille le panel en définissant
        // l'index de la surface sélectionnée à undefined
        this.selectSurfaceByIndex(indexForm);
        //Mise à jour du diagnostic
        this.checkValidity();
    }

    /**
     * @description Appelé lors de l'annulation du panel
     *              Passe le panel en read-only
     */
    handleVolumePanelCancel() {
        this.selectSurfaceByIndex(undefined);
        this.listVolumeChecked = [...this.listVolumeDisabled];

        //Mise à jour du diagnostic
        this.checkValidity();
    }

    /**
     * @description Définis l'index de la surface actuellement sélectionnée
     *              Si undefined, alors le panel de sélection de volumes est désactivé
     * @param indexSurfaceSelected l'index de la surface sélectionnée
     */
    selectSurfaceByIndex(indexSurfaceSelected) {
        this.indexSurface = indexSurfaceSelected ? indexSurfaceSelected : 0; //TODO à supprimer
        this.updateExternalSelectedVolumes();
    }

    /**
     * @description Retourne la liste de tous les volumes du bien dans un tableau
     * @returns Tableau contenant la liste des volumes
     */
    getAllVolumes(): Volume[] {
        const volumes: Volume[] = [];
        this.biens.forEach((bien) => {
            bien.description.forEach((niveau) => {
                if (this.volumeStoreyLinkObject[niveau.storeyId] == undefined) {
                    this.volumeStoreyLinkObject[niveau.storeyId] = [];
                }

                niveau.volumes.forEach((volume) => {
                    volumes.push(volume);
                    this.volumeStoreyLinkObject[niveau.storeyId].push(volume);
                });
            });
        });
        return volumes;
    }

    private checkValidity() {
        // return; //ajouté par antoine a vérifié si a Garder
        // const code = this.route.snapshot.data['code'];

        //Si le formulaire est désactivé, on l'active afin de pouvoir savoir si il est valide
        let isFormEnabled = this.form.enabled;
        if (!isFormEnabled) {
            this.form.enable({
                emitEvent: false,
            });
        }

        // on met à jour la zone associée
        this.form.value.zonesList.forEach((zone) => {
            if (zone.idZoneAssociee) {
                zone.zoneAssociee = this.form.value.zonesList.find((it) => it.id == zone.idZoneAssociee);
            }
        });

        //Récupération de toutes les erreurs
        const erreursZones = [];
        this.formZones.controls.forEach((formZone: FormGroup, index: number) => {
            let erreursZone = {
                numero: index + 1,
                erreurs: [],
            };

            //boucle sur les controles du formulaire pour trouver les erreurs
            for (const controlName in formZone.controls) {
                if (formZone.get(controlName).invalid == true) {
                    erreursZone.erreurs.push(controlName);
                }
            }

            //Si il y'a des erreurs
            if (erreursZone.erreurs.length != 0) {
                erreursZones.push(erreursZone);
            }
        });

        this.contenuDiagnostic.zones.data = this.form.value;
        this.contenuDiagnostic.zones.errors = erreursZones;

        // this.contenuDiagnostic.zones.data = undefined;
        this.currentDiagnostic.contenuDiagnostic = this.contenuDiagnostic;

        let etat: EtatValidation = 'INVALID';
        if (this.typeMesurage == TypePrestationEnum.POLLUANT_VISITE_CHANTIER) {
            //L'écran est valide si le formulaire n'a pas d'erreurs
            // et si les données des Zones ont été validées
            if (this.contenuDiagnostic.zones.validated && this.form.valid) {
                etat = 'VALID';
            }
        } else {
            // Vérification que tous les espaces ont bien été affecté à une surface
            const lstVolumeChecked: string[] = [];
            if (this.contenuDiagnostic?.zones?.data?.zonesList?.length) {
                for (const zone of this.contenuDiagnostic.zones.data.zonesList) {
                    if (zone.listeSurfaces?.length) {
                        for (const surface of zone.listeSurfaces) {
                            for (const volumeId of surface.listeIdVolume) {
                                if (!lstVolumeChecked.includes(volumeId)) {
                                    lstVolumeChecked.push(volumeId);
                                }
                            }
                        }
                    }
                }
            }
            if (this.listVolumeId?.length === lstVolumeChecked.length) {
                etat = 'VALID';
            }
            // Chaque périmètre a une liste de zones : vérification des zones
            for (const zoneForm of this.formZones.controls) {
                if (this.zonesPerimetre.find((zp) => zp.id === zoneForm.value.id)) {
                    if (zoneForm.invalid) {
                        etat = 'INVALID';
                        break;
                    }
                }
            }
        }
        const code = `${this.route.snapshot.data['code']}_${this.currentIdPerimetreInvestigation}`;
        this.etatProgressionService.updateDiagnostic(code, etat, this.currentDiagnostic, true);
        //Si le formulaire était désactivé avant, on le désactive à nouveau
        if (!isFormEnabled) {
            this.form.disable({
                emitEvent: false,
            });
        }
    }

    get formZones() {
        return this.form.get('zonesList') as FormArray;
    }

    get formCurrentZone() {
        // Création du zone si le périmètre visualisé n'en contient pas encore
        if (this.zonesPerimetre.length === 0) {
            let newZone: Zone = new Zone();
            newZone.idPerimetre = this.currentIdPerimetreInvestigation;
            this.zonesPerimetre.push(newZone);
            this.formZones.push(this.createNewFormGroupZone(newZone));
            this.indexZone = this.zonesPerimetre.length - 1;
            this.zoneSelected(this.indexZone);
        }
        return this.formZones.controls.find((zone) => zone.get('id').value === this.zonesPerimetre[this.indexZone].id) as FormGroup;
    }

    get formArraySurfaces() {
        return this.formZones.controls
            .find((zone) => zone.get('id').value === this.zonesPerimetre[this.indexZone].id)
            .get('listeSurfaces') as FormArray;
    }

    getSelectionnableSurfaces(): any[] {
        const surfacesArray = this.formArraySurfaces.controls.map((control, index) => {
            return {
                index: index, // Index de la surface dans le tableau
                selected: control.value.selected, // La valeur de l'attribut 'selected' de la surface
                value: control.value, // La valeur complète de la surface
            };
        });
        return surfacesArray;
    }

    //Interface IValidatableComponent implémentation
    cancelModification() {
        this.isInEditMode = false;
        this.form.disable();
        this.form.patchValue(this.previousFormValue);
        this.previousFormValue = undefined;
    }

    saveModification() {
        this.isInEditMode = false;
        this.form.disable();
        this.isInEditMode = false;
        // this.contenuDiagnostic = ZoneComponent.supprimerDonneesDependantes(this.contenuDiagnostic);

        this.checkValidity();
    }

    validateTab() {
        this.isValidated = true;
        this.contenuDiagnostic.zones.validated = true;
        this.checkValidity();
    }

    startModification() {
        this.isInEditMode = true;
        this.form.enable();
        this.previousFormValue = this.form.getRawValue();
    }

    /**
     * @description Permet de savoir si les écrans dépendant de celui-ci ont été renseignés
     * Si oui alors un avertissement est affiché pour indiquer qu'en cas de modification, les données des écrans dépendants seront supprimées
     */
    // checkEcransDependants(contenuDiagnostic: Polluant) {
    //     ZoneComponent.ecransDependants.forEach(({ nom }) => {
    //         //Parmi les écrans définis comme dépendants, si les données de l'un d'entre eux sont définies
    //         // alors l'écran est verrouillé
    //         if (contenuDiagnostic[nom] && Object.values(contenuDiagnostic[nom].data).length > 0) {
    //             this.isInEditMode = false;
    //         }
    //     });
    // }

    // static supprimerDonneesDependantes(contenuDiagnostic: Polluant) {
    //     ZoneComponent.ecransDependants.forEach(({ nom, composant }: any) => {
    //         //Suppression en cascade des données des écrans dépendants
    //         if (composant.supprimerDonneesDependantes) {
    //             let r = composant.supprimerDonneesDependantes(contenuDiagnostic);
    //         }

    //         contenuDiagnostic[nom].data = {};
    //         contenuDiagnostic[nom].errors.push({
    //             label: 'Ecran non-visité',
    //         });
    //     });

    //     return contenuDiagnostic;
    // }

    /**
     * @description
     *
     */
    _isContextTravaux() {
        const objectifsMesurage = this.polluantConfig.objectifsMesurage;
        var state = false;

        (this.form.controls.zonesList as FormArray).controls.forEach((formZone: FormGroup) => {
            const objectifs = formZone.value.objectifMesurage;

            if (objectifs != null && objectifs.length > 0) {
                objectifs.forEach((objectif) => {
                    objectifsMesurage.forEach((obm) => {
                        obm.conditionsPrelevement.forEach((cdPrel) => {
                            if (cdPrel.reference == objectif.reference) {
                                if (cdPrel.contextTravaux) {
                                    state = true;
                                }
                            }
                        });
                    });
                });
            }

            if (!state) {
                // vider le from et enlever les required
                formZone.controls.activiteAdjacente.clearValidators();
                formZone.controls.confinement.clearValidators();
                formZone.controls.nombreExtracteurs.clearValidators();
                formZone.controls.sasMateriel.clearValidators();
                formZone.controls.sasPersonnel.clearValidators();

                formZone.controls.activiteAdjacente.setErrors(null);
                formZone.controls.confinement.setErrors(null);
                formZone.controls.nombreExtracteurs.setErrors(null);
                formZone.controls.sasMateriel.setErrors(null);
                formZone.controls.sasPersonnel.setErrors(null);

                formZone.patchValue({
                    activiteAdjacente: '',
                    confinement: '',
                    nombreExtracteurs: 0,
                    sasMateriel: '',
                    sasPersonnel: '',
                });
            } else {
                formZone.controls.activiteAdjacente.setValidators([Validators.required]);
                formZone.controls.confinement.setValidators([Validators.required]);
                formZone.controls.nombreExtracteurs.setValidators([Validators.required]);
                formZone.controls.sasMateriel.setValidators([Validators.required]);
                formZone.controls.sasPersonnel.setValidators([Validators.required]);
            }

            formZone.updateValueAndValidity();
        });
    }

    get surfaceTotale() {
        return this.formArraySurfaces.value.reduce((n, { superficie }) => Math.round(n) + Math.round(superficie), 0);
    }

    get totalPu() {
        return this.formArraySurfaces.value.reduce((n, { nombrePu }) => Math.round(n) + Math.round(nombrePu), 0);
    }

    /**
     * Toggle mode liste ou plan
     */
    changeViewMode(value) {
        this.viewMode = value.value;
        setTimeout(() => {
            this.updateExternalSelectedVolumes();
        }, 200);
    }
    // Start Ismael
    private _currentBien: RelationInterventionBien;
    private _perimetreInvestigationSelected: PerimetreInvestigation;
    building: cn_building;
    currentNiveau: Niveau;
    zoneName: string;
    viewerMapConfig: ViewerMapConfig = {
        isSelectablePieceSansEquipement: false,
        isSelectableEquipement: true,
    };

    changeCurrentStairMap(niveauSelected: BaseObject) {
        this.currentNiveau = this._currentBien.bien.description.find((it) => it.id === niveauSelected.id);
        setTimeout(() => {
            this.updateExternalSelectedVolumes();
        }, 200);
    }
    /**
     * Pour chaque volume une surface est créée
     */
    ajouterToutesLesSurfaces() {
        const allVolumes: Volume[] = []; // Liste des volumes en fonction du périmètre
        for (const id of this._perimetreInvestigationSelected.listeVolumeId) {
            const lVolume = this.currentNiveau.volumes.find((v) => v.id === id);
            if (lVolume) {
                allVolumes.push(lVolume);
            }
        }
        const volumesToAdd: Volume[] = []; // Qui vont être ajoutés
        for (const volume of allVolumes) {
            let exist = false;
            for (let i = 0; i < this.formArraySurfaces.length; i++) {
                const surfaceForm = this.formArraySurfaces.at(i) as FormGroup;
                const listeIdVolume = surfaceForm.get('listeIdVolume').value;
                if (listeIdVolume.find((id) => volume.id == id)) {
                    exist = true;
                    break;
                }
            }
            if (!exist) {
                volumesToAdd.push(volume);
            }
        }
        // Suppression des surfaces vide
        for (let index = 0; index < this.formArraySurfaces.value.length; index++) {
            if (!this.formArraySurfaces.value[index].listeIdVolume.length) this.formArraySurfaces.removeAt(index);
        }
        // création d'une surface par volume
        for (const volume of volumesToAdd) {
            this.addSurface();
            this.handleVolumePanelSubmit(
                [volume].map((v) => v.id),
                this.formArraySurfaces.length - 1
            );
        }
    }

    /**
     * Justification obligatoire pour regrouper les locaux.
     * TODO : Doit apparaitre sur le rapport de stratégie.
     * @param formSurface
     */
    addJustificationSurface(formSurface: FormGroup) {
        this.matDialog
            .open(CommentModalComponent, {
                ...DialogUtils.configFullScreen(),
                data: {
                    commentairesId: formSurface.get('commentairesId').value,
                    replaceTypesCommentaires: true,
                    typesCommentaires: [enumTypesCommentaire.CONSTATATION_DIVERSE],
                    typeNouveauCommentaire: enumTypesCommentaire.CONSTATATION_DIVERSE,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result && result !== false) {
                    formSurface.get('commentairesId').setValue(result.commentairesId);
                }
            });
    }

    set currentBien(currentBien: RelationInterventionBien) {
        this._currentBien = currentBien;
        this.loadBuilding();
    }

    get currentBien() {
        return this._currentBien;
    }

    private loadBuilding() {
        if (this._currentBien.bien && this._currentBien.bien.jsonPlan) {
            this.building = cn_building.unserialize(JSON.parse(this._currentBien.bien.jsonPlan));
        }
    }

    /**
     * À la sélection d'une surface
     * => Selection des espaces
     * @private
     */
    private updateExternalSelectedVolumes() {
        const volumes = [];
        const surfaceForm = this.formArraySurfaces.at(this.indexSurface) as FormGroup;
        if (surfaceForm) {
            const volumesId: string[] = surfaceForm.get('listeIdVolume').value;
            for (const id of volumesId) {
                const lVolume = this.currentNiveau.volumes.find((v) => v.id === id);
                if (lVolume) {
                    volumes.push(lVolume);
                }
            }
        }
        this.externalSelectedVolumes = volumes;
    }

    public onTouchOnPlan($event) {
        if (!(this.indexSurface >= 0)) {
            return this.notificationService.notify('Veuillez selectionner une surface');
        }
        if (!$event) {
            return;
        }
        $event.value = $event.value ? $event.value : [];
        // Vérification des volumes en fonction du périmètte choisis
        const lstVolumles: Volume[] = []; // Les volumes du périmètre
        for (const id of this._perimetreInvestigationSelected.listeVolumeId) {
            const lVolume = this.currentNiveau.volumes.find((v) => v.id === id);
            if (lVolume) {
                lstVolumles.push(lVolume);
            }
        }
        for (const vol of $event.value) {
            if (!lstVolumles.find((v) => v.spaceId === vol.ID)) {
                this.updateExternalSelectedVolumes();
                return this.notificationService.notify("L'espace sélectionné se trouve en dehors des limites du périmètre d'investigation");
            }
        }
        // Gestion de la suppression
        const surfaceFormSupp = this.formArraySurfaces.at(this.indexSurface) as FormGroup;
        const listeIdVolumeSupp = surfaceFormSupp.get('listeIdVolume').value;
        const volumes = [];
        for (const id of listeIdVolumeSupp) {
            const v = this.currentNiveau.volumes.find((v) => v.id === id);
            if (v) {
                volumes.push(v);
            }
        }

        // Gestion de la suppression
        for (let i = 0; i < volumes.length; i++) {
            if (!$event.value.find((v) => v.ID === volumes[i].spaceId)) {
                this.confirmationService.confirm(
                    `Souhaitez-vous supprimer l'espace (${volumes[i].nom}) ?`,
                    () => {
                        this.removeVolumeFromSurface(volumes[i].id, this.indexSurface);
                    },
                    () => {
                        this.externalSelectedVolumes = volumes;
                    }
                );
                return;
            }
        }
        // gestion de l'ajout
        const volumesSelected: Volume[] = [];
        for (const vol of $event.value) {
            const volumeSelected = this.currentNiveau?.volumes?.find((it) => it.spaceId === vol?.ID);
            // Traitement seulement si un volume a été selectionné
            if (volumeSelected) {
                // Vérification si volume déjà present dans d'autres surfaces
                for (let i = 0; i < this.formArraySurfaces.length; i++) {
                    //Récupère la surface concernée
                    if (this.indexSurface != i) {
                        const surfaceForm = this.formArraySurfaces.at(i) as FormGroup;
                        const listeIdVolume = surfaceForm.get('listeIdVolume').value;
                        if (listeIdVolume.find((id) => volumeSelected.id == id)) {
                            this.updateExternalSelectedVolumes();
                            return this.notificationService.notify('Le volume est déjà présent dans une autre surface');
                        }
                    }
                }
                volumesSelected.push(volumeSelected);
            }
        }
        if (volumesSelected?.length) {
            // Ajout de l'espace dans la surface
            this.handleVolumePanelSubmit(volumesSelected.map((v) => v.id));
        }

        // Selection des suface
        this.updateExternalSelectedVolumes();
    }

    ngAfterViewChecked(): void {
        this.applyColors();
    }

    /**
     * Applique les couleurs des espaces en fonction :
     * 1. Neutre SI appartient au périmètre Gris SINON
     * 2. Couleur vive SI appartient au périmètre ET déjà sélectionné dans une autre surface SINON couleur foncé (selected)
     * Une couleur différente pour chaque zone
     * @private
     */
    private applyColors() {
        if (!this.currentNiveau) return;
        const colorHexSelected = zone_colors[this.indexZone % zone_colors.length];
        for (const space of this.currentNiveau.volumes) {
            const element = this.el.nativeElement.querySelector('path.' + space.spaceId);
            if (element) {
                if (this.externalSelectedVolumes.map((s) => s.spaceId).includes(space.spaceId)) {
                    this.renderer.setStyle(element, 'fill', colorHexSelected);
                } else if (!this.listVolumeId.includes(space.id)) {
                    this.renderer.setStyle(element, 'fill', '#ccc');
                } else if (this.listVolumeDisabled.includes(space.id)) {
                    this.renderer.setStyle(element, 'fill', this.reduceOpacity(colorHexSelected));
                }
            }
        }
    }

    private reduceOpacity(colorHex: string): string {
        // Extraire les composants RGB de la couleur d'entrée
        const r = parseInt(colorHex.slice(1, 3), 16);
        const g = parseInt(colorHex.slice(3, 5), 16);
        const b = parseInt(colorHex.slice(5, 7), 16);

        // Retourner la couleur avec l'opacité réduite
        return `rgba(${r}, ${g}, ${b},0.1)`;
    }

    get currentIdPerimetreInvestigation() {
        return this._perimetreInvestigationSelected?.id;
    }

    /*
    private reduceOpacity(colorHex: string): string {
        // Extraire les composants RGB de la couleur d'entrée
        const r = parseInt(colorHex.slice(1, 3), 16);
        const g = parseInt(colorHex.slice(3, 5), 16);
        const b = parseInt(colorHex.slice(5, 7), 16);
        // Convert decimal to hexadecimal
        const hexR: string = r.toString(16).padStart(2, '0');
        const hexG: string = g.toString(16).padStart(2, '0');
        const hexB: string = b.toString(16).padStart(2, '0');
        const hexA: string = Math.round(0.1 * 255).toString(16).padStart(2, '0');

        // Retourner la couleur avec l'opacité réduite
        return `rgba(${r}, ${g}, ${b},0.1)`;
    }
    */

    // end Ismael

    /**
     * Calcule la liste des volumes liés à la surface actuelle.
     * Actualise également la propriété listVolumePu avec les volumes obtenus.
     */
    computeVolumes() {
        this.listVolumePu = [];
        //Si on connait la surface de chaque volumes via le plan
        if (Object.keys(this.volumesSurface).length > 0) {
            let surface = 0;
            this.listeIdVolumes.forEach((id) => {
                const vol = this.volumes.find((x) => x.id === id);

                surface += this.volumesSurface[vol.id];
                this.listVolumePu.push(vol);
            });
        } else {
            this.listeIdVolumes.forEach((id) => {
                const vol = this.volumes.find((x) => x.id === id);
                this.listVolumePu.push(vol);
            });
        }
    }

    /**
     * Récupère la relation bien actuelle liée à l'intervention.
     * @returns La relation bien actuelle liée à l'intervention.
     */
    get currentRelationBien() {
        return this.intervention.relationInterventionBiens.find((rel) => {
            return rel.bien.id === this._currentBien.bien.id;
        });
    }

    /**
     * Déclare la surface d'un volume et met à jour le plan en conséquence.
     * Actualise également la liste des volumes de la surface, recompte les volumes et recharge le bâtiment.
     * @param event - Les informations sur la surface à déclarer et le volume associé.
     */
    declareSurfaceVolume(event: { surfaceToDeclare: string; volume: Volume }) {
        let jsonPlan = this.mesurageService.synchronizeDeclaredSurfaceFormDiagToBuilding(
            event.surfaceToDeclare,
            event.volume?.spaceId,
            this.currentNiveau.storeyId,
            this.cnBuilding,
            this._currentBien.bien.jsonPlan
        );
        if (jsonPlan) {
            let relationInterventionBien = this.intervention.relationInterventionBiens.find((it) => it === this.currentRelationBien);
            relationInterventionBien.bien.jsonPlan = jsonPlan;
        }
        let _currentSurface = this.formArraySurfaces.at(this.indexSurface) as FormGroup;
        let _listeVolumes = (_currentSurface.getRawValue() as Surface).listeIdVolume;
        this.getVolumesSurface();
        this.updateSurfaceFromVolumes(_currentSurface, _listeVolumes);
        this.computeVolumes();
        this.loadBuilding();
        if (this.viewMap) {
            this.viewMap.refresh();
        }
    }

    get elementToLocate(): Zone {
        return this.zone;
    }

    protected readonly MesureComponent = MesureComponent;
}
