<ul>
    <button mat-button class="bt" (click)="testResetConnexion()">
        <lib-icon>printer-search</lib-icon>
        {{ 'Reset Connexion' }}
    </button>
</ul>

<ul>
    <button mat-button class="bt" (click)="testCalibrageEtiquette()">
        <lib-icon>printer-wireless</lib-icon>
        {{ 'Calibrage Etiquette' }}
    </button>
</ul>

<ul>
    <button mat-button class="bt" (click)="testEtiquetteMateriau()">
        <lib-icon>printer-wireless</lib-icon>
        {{ 'Etiquette Matériau' }}
    </button>
</ul>

<ul>
    <button mat-button class="bt" (click)="testEtiquetteSachet()">
        <lib-icon>printer-wireless</lib-icon>
        {{ 'Etiquette Sachet' }}
    </button>
</ul>

<ul>
    <button mat-button class="bt" (click)="testEtiquetteTemoin()">
        <lib-icon>printer-wireless</lib-icon>
        {{ 'Etiquette Témoin' }}
    </button>
</ul>

<ul>
    <button mat-button class="bt" (click)="testEtiquetteLingette()">
        <lib-icon>printer-wireless</lib-icon>
        {{ 'Etiquette Lingette' }}
    </button>
</ul>

<ul>
    <button mat-button class="bt" (click)="testEtiquetteMET()">
        <lib-icon>printer-wireless</lib-icon>
        {{ 'Etiquette MET' }}
    </button>
</ul>

<ul>
    <button mat-button class="bt" (click)="testEtiquetteMEST()">
        <lib-icon>printer-wireless</lib-icon>
        {{ 'Etiquette MEST' }}
    </button>
</ul>
