import { Pipe, PipeTransform } from '@angular/core';
import { Besoin } from '../modules/diagnostics/polluant/model/besoin.model';

@Pipe({
    name: 'RefBesoinAir',
})
export class RefBesoinAirPipe implements PipeTransform {
    transform(b: Besoin): string {
        return 'B' + b.numero + ' (' + b.objectifMesurage.typePrelevement + ')';
    }
}
