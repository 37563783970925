import { AbstractControl } from '@angular/forms';

export class HapValidatorUtils {
    /**
     * Validateur spécifique pour les coordonnée gps
     * @param control: AbstractControl
     */
    static validatorIsGpsCoordonnatesEmptyOrBothValid(control: AbstractControl): { [key: string]: any } | null {
        const coordonneePattern = new RegExp('^[-+]?[0-9]{1,7}(.[0-9]+)?$');
        const longitudeFormControl = control.get('longitude');
        const latitudeFormControl = control.get('latitude');

        const lon = longitudeFormControl.value ? longitudeFormControl.value : '';
        const lat = latitudeFormControl.value ? latitudeFormControl.value : '';

        if (
            (lat.length !== 0 && lon.length === 0) ||
            (lat.length === 0 && lon.length !== 0) ||
            (lon.length > 0 && (!coordonneePattern.test(lat) || !coordonneePattern.test(lon)))
        ) {
            // Ajout de l'erreur sur la longitude
            longitudeFormControl.errors
                ? (longitudeFormControl.errors.longitudeError = true)
                : longitudeFormControl.setErrors({ longitudeError: true });
            // Ajout de l'erreurs sur la latitude
            latitudeFormControl.errors ? (latitudeFormControl.errors.latitudeError = true) : latitudeFormControl.setErrors({ latitudeError: true });
            return {
                customError: {
                    message: 'Les deux coordonnées GPS doivent être valides et présentes si une est renseignée',
                },
            };
        } else {
            // Suppression de l'erreur sur le nom
            if (longitudeFormControl.errors) {
                delete longitudeFormControl.errors.longitudeError;
            }
            if (longitudeFormControl.errors && Object.entries(longitudeFormControl.errors).length == 0) {
                longitudeFormControl.setErrors(null);
            }

            // Suppression de l'erreurs sur la latitude
            if (latitudeFormControl.errors) {
                delete latitudeFormControl.errors.latitudeError;
            }
            if (latitudeFormControl.errors && Object.entries(latitudeFormControl.errors).length == 0) {
                latitudeFormControl.setErrors(null);
            }
        }
        return null;
    }
}
