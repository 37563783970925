<div class="toolbar">
    <mat-toolbar class="border-bottom-1 primary">
        <div>
            <lib-icon [class]="'icon-before-text'">people</lib-icon>
            <span>Gestion des employés</span>
        </div>
        <div class="flex-grow-1"></div>
        <div>
            <span>User</span>
        </div>
    </mat-toolbar>
</div>
<div class="flex-grow-1 main-background overflow-auto">
    <div class="p-4">
        <div class="entete-tableau-admin">
            <div class="d-flex">
                <div class="onglet-tab-admin mt-2 selected">Employés</div>
            </div>
            <div class="buttons d-flex justify-content-end">
                <button color="accent" mat-raised-button class="bt-valid" (click)="creerUser()">
                    <lib-icon>add</lib-icon>
                    {{ 'Créer un employé' | uppercase }}
                </button>
            </div>
        </div>
        <app-tableau-operateurs></app-tableau-operateurs>
    </div>
</div>
