import { ROLE_ADMIN } from 'src/app/commons-lib';

/**
 * Rôle pour le chef de projet
 */
export const ROLE_MANAGER = 'ROLE_MANAGER';

/**
 * Rôle pour le chef de projet
 */
export const ROLE_PROJECT_LEADER = 'ROLE_PROJECT_LEADER';

/**
 * Rôle pour l'opérateur
 */
export const ROLE_OPERATOR = 'ROLE_OPERATOR';

export const MAP_ROLE = new Map([
    [ROLE_OPERATOR, 'Opérateur'],
    [ROLE_PROJECT_LEADER, 'Chef de projet'],
    [ROLE_MANAGER, 'Gestionnaire'],
    [ROLE_ADMIN, 'Administrateur'],
]);
