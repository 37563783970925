<div class="d-flex" [ngClass]="containerClass">
    <ng-container *ngFor="let choice of _choices; let last = last; let index = index">
        <div
            class="d-flex flex-column align-items-center"
            [matTooltip]="tooltips[index]"
            [class.mr-0]="!last && spaceBetweenLabels == '0'"
            [class.mr-1]="!last && (spaceBetweenLabels == '1' || (spaceBetweenLabels == undefined && labels))"
            [class.mr-2]="!last && spaceBetweenLabels == '2'"
            [class.mr-3]="!last && spaceBetweenLabels == '3'"
            [class.mr-4]="!last && spaceBetweenLabels == '4'"
            [class.mr-5]="!last && spaceBetweenLabels == '5'"
            [class.button-large-container]="labels"
            [class.c-pointer]="!disabled"
            [class.disabled-choice]="disabled"
            (click)="clickChoiceValue(choice.value)"
        >
            <button
                mat-icon-button
                [ngClass]="persistentColor ? 'state-input-button-colored' : 'state-input-button'"
                [disabled]="disabled"
            >
                <lib-icon
                    [ngClass]="choice.cssClass"
                    [class.global]="globalStateInput"
                    [class.selected]="currentChoicesValues | includes: choice.value"
                    [class.icon-large]="labels"
                    [class.disabled]="disabled"
                    [theme]="choice.icon.theme"
                    [iconName]="choice.icon.name"
                >
                </lib-icon>
            </button>
            <span
                *ngIf="labels"
                class="mat-caption text-center state-label"
                [class.disabled]="disabled"
                [class.selected]="currentChoicesValues | includes: choice.value"
                >{{ choice.label }}</span
            >
        </div>
    </ng-container>
</div>
