<div class="fullscreen-content">
    <div class="m-3 d-flex justify-content-between">
        <div class="d-flex align-items-center">
            <button disabled mat-button>
                <lib-icon>navigate_before</lib-icon>
            </button>
            <div [ngClass]="{ 'tab-invalid': !getValidity() }" class="d-flex align-items-center">
                <lib-icon class="mr-2" *ngIf="!getValidity()">priority_high</lib-icon>
                <span>{{ titre }}</span>
            </div>
        </div>
    </div>
    <mat-divider></mat-divider>
    <form #form="ngForm" class="p-5 d-flex flex-column">
        <ng-container *ngFor="let pointDeControle of pointsDeControleGlobal">
            <ng-container *ngTemplateOutlet="contextPTC; context: { pointDeControle: pointDeControle }"></ng-container>
        </ng-container>
        <ng-container *ngFor="let pointDeControle of pointsDeControlByTypeIsolant">
            <ng-container *ngTemplateOutlet="contextPTC; context: { pointDeControle: pointDeControle }"></ng-container>
        </ng-container>
    </form>
</div>

<ng-template #contextPTC let-pointDeControle="pointDeControle">
    <app-ptc-content
        class="d-flex flex-row justify-content-between"
        [style.background-color]="'#f7f7f7'"
        [style.border-radius]="'10px'"
        [style.margin-bottom]="'2%'"
        [style.width]="'100%'"
        [diagnostic]="diagnostic"
        [name]="pointDeControle.idInterne"
        [libelle]="pointDeControle.description"
        [isCalculette]="pointDeControle.isCalculette"
        [isCalculZone]="pointDeControle.isCalculZone"
        [type]="pointDeControle.type"
        [required]="pointDeControle.required"
        [disabled]="readonlyMode"
        [choices]="pointDeControle.lstChoixReponse"
        [value]="getPointDeControle(pointDeControle)"
        [checkpoint]="pointDeControle"
        [valeurAControler]="valeurAControler"
        [valeurAControlerReel]="valeurAControlerReel"
        (valueChange)="onChangeValue($event)"
        [aides]="pointDeControle.aides"
    ></app-ptc-content>
    <div
        *ngIf="
            (needJustification(pointDeControle) || getAnomalieFromCheckpoint(pointDeControle)) &&
            !getPointDeControle(pointDeControle)?.commentairesId?.length
        "
        class="to-justify all mr-2 mb-3"
    >
        Il manque une justification {{ needJustification(pointDeControle) }}
        <button mat-icon-button color="justify" class="ml-1" (click)="addJustification(pointDeControle)">
            <lib-icon theme="mdi" iconName="comment-alert"></lib-icon>
        </button>
    </div>
</ng-template>
