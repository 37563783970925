'use strict';

import { cn_space } from "./cn_space";
import { cn_storey } from "./cn_storey";

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2024 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** cn_space_metrics : Container for metrics of one space
//***********************************************************************************
//***********************************************************************************

export class cn_space_metrics {
    /**
     *
     * @param {cn_space} space
     * @param {cn_storey} storey
     */
    constructor(space, storey) {

        /** Volumes */
        const thermal_volume = space.build_thermal_volume(storey);
        const space_volume = space.build_space_volume(storey);

        this.gross_volume = thermal_volume.get_volume();
        this.net_volume = space_volume.get_volume();

        this.gross_ceiling_area = 0;
        this.net_ceiling_area = 0;
        this.height = -1;
        this.finished_ceiling_height = -1;

        //*** Heights : only if relevant */
        if (space.is_roof(storey)) {

            var thermal_ceilings = thermal_volume.get_faces().filter(f => f.get_normal()[2] > 0.1);

            //*** Needs homogeneous upper slabs */
            if (thermal_ceilings.length && !thermal_ceilings.some(c => c.get_normal()[2] < 0.99)) {
                this.height = thermal_volume.get_bounding_box().size[2];
            }

            var space_ceilings = space_volume.get_faces().filter(f => f.get_normal()[2] > 0.1);
            if (space.ceiling_height > 0) {
                if (space_ceilings.length && !space_ceilings.some(c => c.get_normal()[2] < 0.99)) {
                    this.finished_ceiling_height = space_volume.get_bounding_box().size[2];
                }
            }

            //*** add ceiling areas */
            space_ceilings.forEach(pg => this.gross_ceiling_area += pg.get_area());

            const net_ceilings = space.get_3d_ceilings(storey);
            net_ceilings.forEach(pg => this.net_ceiling_area += pg.get_area());
        }

        const space_walls = storey.scene.walls.filter(w => w.spaces[0] == space || w.spaces[1] == space);

        //*** Perimeters */
        this.gross_perimeter = space.get_perimeter();
        this.net_perimeter = this.gross_perimeter;
        space_walls.forEach(w => {
            w.openings.filter(o => o.valid && o.opening_type.z == 0).forEach(o => this.net_perimeter -= o.opening_type.width);
        });

        //*** floor areas */
        this.gross_floor_area = space.get_inner_polygon().get_area();
        var net_floor_polygon = space.get_inner_pierced_polygon(storey).clone();
        storey.scene.columns.forEach(col => net_floor_polygon.substracts(col.get_footprint()));
        this.net_floor_area = net_floor_polygon.get_area();

        //*** wall areas */
        this.gross_wall_area = 0;
        space_volume.get_faces().filter(f => Math.abs(f.get_normal()[2]) < 0.01).forEach(f => this.gross_wall_area += f.get_area());
        this.net_wall_area = this.gross_wall_area;
        space_walls.forEach(w => {
            w.openings.filter(o => o.valid).forEach(o => this.net_wall_area -= o.opening_type.get_area());
        });

    }
}

