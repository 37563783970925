/**
 * Etiquette pour les imprimantes thermique Bluetooth Zebra
 */
export class etiquetteZebra {
    reference: string;
    codeZpl: string;

    constructor(ref: string, zpl: string) {
        this.reference = ref;
        this.codeZpl = zpl;
    }
}
