import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/commons-lib';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { takeUntil, switchMap, withLatestFrom, startWith } from 'rxjs/operators';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { Document } from 'src/app/model/document.model';
import { Intervention, PrestationDiagnostic } from 'src/app/model/intervention.model';
import { TypePrestation } from 'src/app/model/type-prestation.model';
import { DiagnosticService } from 'src/app/services/diagnostic.service';
import { EtatProgressionService } from 'src/app/services/etat-progression.service';
import { InterventionService } from 'src/app/services/intervention.service';
import { DocumentUtils } from 'src/app/utils/document.utils';
import { CnSpinnerService } from '../cn-spinner/service/cn-spinner.service';
import { MatDrawer } from '@angular/material/sidenav';
import { ArrayUtils } from 'src/app/utils/array.utils';
import { CheckValidityTabService } from 'src/app/services/check-validity-tab.service';
import { DocumentsService } from 'src/app/services/documents.service';
import { ReferenceService } from '../../../services/reference.service';

@Component({
    selector: 'app-documents-gestion',
    templateUrl: './documents-gestion.component.html',
    styleUrls: ['./documents-gestion.component.scss'],
})
export class DocumentsGestionComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
    /**
     * Type de prestation (null pour tous)
     * permet de récupérer les documents suivant les types de prestation que l'on passe en Input
     */
    @Input()
    typesPrestationToDisplay: TypePrestation[] = null;

    @Input()
    forDiagnostic = true;

    intervention: Intervention;
    diagnostic: Diagnostic;
    readonlyMode = false;
    currentPrestationDiagnostic: PrestationDiagnostic;

    // Correspond à la liste des documents affichés filtrer si nécessaire par les typesPrestationToDisplay
    get documentsFiltered() {
        return this._documentsFiltered;
    }
    set documentsFiltered(docs: Document[]) {
        this._documentsFiltered = docs;
        if (docs) {
            // Initialisation de la date obligatoire en fonction de la catégorie du document
            this.referenceService
                .findAllCategorieDocuments()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((categories) => {
                    if (categories) {
                        for (const doc of this._documentsFiltered) {
                            const categorie = categories.find((cat) => cat.id === doc.typeDocument.idCategorie);
                            doc.dateDocumentObligatoire = true;
                            if (categorie?.dateObligatoire === false) {
                                doc.dateDocumentObligatoire = false;
                                doc.isEditable = false;
                            }
                        }
                    }
                });
        }
    }

    private _documentsFiltered: Document[];

    documentSelected: Document;

    @ViewChild('drawer') public drawer: MatDrawer;

    private forDiagnosticChanged = new BehaviorSubject<boolean>(undefined);

    constructor(
        private readonly interventionService: InterventionService,
        private readonly diagnosticService: DiagnosticService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly etatProgressionService: EtatProgressionService,
        private readonly route: ActivatedRoute,
        private readonly checkValidityTabService: CheckValidityTabService,
        private readonly documentsService: DocumentsService,
        private readonly referenceService: ReferenceService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.forDiagnostic) {
            this.forDiagnosticChanged.next(changes.forDiagnostic.currentValue);
        }
    }

    ngOnInit(): void {
        this.cnSpinnerService
            .withSpinner(
                this.interventionService.getCurrentIntervention().pipe(
                    withLatestFrom(this.forDiagnosticChanged.asObservable()),
                    switchMap(([intervention, forDiagnostic]) =>
                        combineLatest([of(intervention), this.diagnosticService.getCurrentDiagnostic().pipe(startWith(undefined))])
                    ),
                    switchMap(([intervention, diagnostic]) => {
                        return combineLatest([of(intervention), of(diagnostic), of(this.diagnosticService.isReadOnlyMode(intervention, diagnostic))]);
                    }),
                    takeUntil(this.ngUnsubscribe)
                )
            )
            .subscribe(([intervention, diagnostic, readonlyMode]) => {
                this.intervention = intervention;
                this.diagnostic = diagnostic;

                this.currentPrestationDiagnostic = this.intervention.prestationsDiagnostics.find(
                    (prestationDiagnosticTemp) => prestationDiagnosticTemp.idDiagnostic === this.diagnostic?.id
                );
                this.readonlyMode = readonlyMode;

                // Initialisation des filtres
                this.resetDocumentsFiltered();

                // Si on est dans un diagnostic, on vérifie la conformité des documents
                if (this.diagnostic && this.currentPrestationDiagnostic) {
                    // Calcul de la conformité des documents de la prestation
                    this.documentsFiltered.forEach((docTemp) => {
                        this.documentsService.checkConformiteDocument(docTemp, this.currentPrestationDiagnostic.prestation.referencePrestation);
                    });
                }

                this.checkValidity();
            });
    }

    /**
     * Évènement déclenché lors de l'ajout ou de la suppression d'un document
     */
    onAddOrRemoveDocument() {
        this.resetDocumentsFiltered();
        this.onOpenEditPanel();
    }

    /**
     * Réinitialise les documents le filtre type de prestation
     */
    private resetDocumentsFiltered() {
        if (this.typesPrestationToDisplay != null) {
            this.filterSpecificPrestation(this.typesPrestationToDisplay);
        } else {
            this.documentsFiltered = this.intervention.documents;
        }
    }

    /**
     * Filtre selon type de prestation
     */
    private filterSpecificPrestation(typesPrestation: TypePrestation[]) {
        this.documentsFiltered = this.intervention.documents.filter((docTemp) =>
            ArrayUtils.hasIntersectionBetweenArrays(typesPrestation, docTemp.typePrestations)
        );
        DocumentUtils.sortDocuments(this.documentsFiltered);
    }

    /**
     * Valider l'onglet document
     */
    checkValidity() {
        if (this.diagnostic && this.currentPrestationDiagnostic) {
            const currentReferencePrestation = this.currentPrestationDiagnostic.prestation.referencePrestation;

            // Vérification de l'onglet Document
            this.checkValidityTabService
                .checkValidityDocument(this.documentsFiltered, currentReferencePrestation)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((etat) => {
                    const code = this.route.snapshot.data['code'];
                    this.etatProgressionService.updateDiagnostic(code, etat, this.diagnostic);
                });
        }
    }

    onClickDocument(document: Document) {
        this.documentSelected = document;
        this.onOpenEditPanel();
    }

    onOpenEditPanel() {
        if (this.drawer) {
            this.drawer.open();
        }
    }

    onCloseEditPanel() {
        if (this.drawer) {
            this.drawer.close();
        }
        this.checkValidity();
    }

    ngOnDestroy(): void {
        this.checkValidity();
        super.ngOnDestroy();
    }
}
