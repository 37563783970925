'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
import { cn_element } from './cn_element';
import { cn_contour } from './cn_contour';
import { cn_storey } from './cn_storey';
import { cn_box } from '../utils/cn_utilities';
import { fh_polygon } from '@acenv/fh-3d-viewer';
import { cn_element_visitor } from '../utils/visitors/cn_element_visitor';
import { logger } from '../utils/cn_logger';
import { cn_slab_metrics } from './cn_slab_metrics';
import { CN_CURRENT_DATE } from '../utils/cn_transaction_manager';

/**
 * @class cn_slab
 * Class used to draw intersections of spaces between 2 storeys (or storey and void)
 * If both spaces are null, this is the outer slab for the storey
 */

//***********************************************************************************
//***********************************************************************************
export class cn_slab extends cn_element {

    constructor(parent) {
        super(parent);
        this.name = '';
        this.area = 0;
        this.spaces = [null, null];
        this.space_unique_ids = ['', ''];
        this.contours = [];
        this.slab_type = null;

        this.removable = false;

        this.outside = false;

        this.pierced = false;

        this.outer_polygon = new fh_polygon([0, 0, 0], [0, 0, 1]);
        this.inner_polygon = new fh_polygon([0, 0, 0], [0, 0, 1]);
        this.pierced_inner_polygon = new fh_polygon([0, 0, 0], [0, 0, 1]);
        this.pierced_outer_polygon = new fh_polygon([0, 0, 0], [0, 0, 1]);
    }

    //***********************************************************************************
    /**
     * serialization
     * @returns {object} json serializeation
     */
    serialize() {
        var json = {};

        json.contours = [];
        for (var i in this.contours)
            json.contours.push(this.contours[i].serialize());

        json.slab_type = this.slab_type.ID;

        return json;
    }

    //***********************************************************************************
    /**
     * unseriatlize json object
     * @param {object} json
     * @param {cn_storey} storey
     */
    static unserialize(json, storey) {
        if (typeof (json.contours) != 'object') return false;
        if (typeof (json.slab_type) != 'string') return false;

        var slab = new cn_slab();

        if (typeof (json.slab_type) == 'string')
            slab.slab_type = storey.building.get_element_type(json.slab_type);
        if (slab.slab_type == null) {
            logger.log('use default slab type');
            slab.slab_type = storey.building.get_floor_types()[0];
        }

        for (var i in json.contours) {
            var contour = cn_contour.unserialize(json.contours[i], storey.scene);
            slab.contours.push(contour);
        }

        return slab;
    }

    //***********************************************************************************
    /**
     * update geometry
     */
    update() {
        this.area = 0;
        for (var i in this.contours) {
            this.contours[i].update();
            if (this.contours[i].clockwise)
                this.area += this.contours[i].area;
            else
                this.area -= this.contours[i].area;
        }
        this.area = Math.abs(this.area);

        this.pierced = (this.pierced_inner_polygon.get_area() < this.inner_polygon.get_area() - 0.1);
    }

    //***********************************************************************************
    /**
     * Returns slab type ("roof", "floor", "intermediate", "outer") depending on spaces
     * @returns {string}
     */
    get_slab_type() {
        if (this.spaces[0] == null) {
            if (this.spaces[1] == null)
                return 'outer';
            return 'floor';
        }

        if (this.spaces[1] == null)
            return 'roof';
        return 'intermediate';
    }

    //***********************************************************************************
    //**** Draw the space in svg
    //***********************************************************************************
    draw(camera, add_classes, fill = '', draw_pattern = false) {
        var html = '';
        if (this.outside) return html;

        var draw_class = 'slab';
        if (this.spaces[0]) draw_class += ' space_below';
        if (this.spaces[1]) draw_class += ' space_above';
        if (add_classes)
            draw_class += ' ' + add_classes.join(' ');

        //draw_class="xx";
        var path = 'd=\'';
        for (var i in this.contours) {
            var vertices = this.contours[i].vertices;
            if (typeof (vertices) != 'object') continue;
            for (var j = 0; j < vertices.length; j++) {
                if (j == 0) path += 'M ';
                else if (j == 1) path += 'L ';
                var p = camera.world_to_screen(vertices[j].position);
                path += '' + p[0] + ' ' + p[1] + ' ';
            }
            path += 'Z ';
        }
        path += '\'';

        if (draw_pattern)
            html += '<path ' + path + ' fill=\'url(#' + this.slab_type.ID + ')\' />';

        html += '<path class=\'' + draw_class + '\' ' + fill + ' ' + path + '/>';

        return html;
    }

    //***********************************************************************************
    //**** Contains
    //***********************************************************************************
    contains(p) {
        var inside = 0;
        for (var i in this.contours) {
            if (!this.contours[i].contains(p)) continue;
            if (this.contours[i].clockwise)
                inside++;
            else
                inside--;
        }
        return (inside > 0);
    }

    //***********************************************************************************
    //**** Contains
    //***********************************************************************************
    contained_by_box(box) {
        for (var i in this.contours) {
            if (!this.contours[i].contained_by_box(box))
                return false;
        }
        return true;
    }

    //***********************************************************************************
    //**** get box
    //***********************************************************************************
    get_bounding_box() {
        var box = new cn_box();
        for (var i in this.contours)
            box.enlarge_box(this.contours[i].get_bounding_box());
        return box;
    }

    //***********************************************************************************
    //**** returns true if slab is lossy
    //***********************************************************************************
    is_lossy() {
        var out0 = (this.spaces[0] == null || this.spaces[0].outside || !this.spaces[0].has_roof);
        var out1 = (this.spaces[1] == null || this.spaces[1].outside || !this.spaces[1].has_roof);
        return out0 != out1;
    }

    //***********************************************************************************
    //**** returns true if slab can have limit conditions
    //***********************************************************************************
    has_limit_conditions() {
        if (!this.is_lossy()) return false;
        var out0 = (this.spaces[0] == null || this.spaces[0].outside);
        var out1 = (this.spaces[1] == null || this.spaces[1].outside);
        if (!out0 && !out1) return false;
        return true;
    }

    //***********************************************************************************
    /**
     * Builds a polygon at given height for that slab
     * @param {number} z : height of polygon
     * @returns {fh_polygon}
     */
    build_polygon(z) {
        var polygon = new fh_polygon([0, 0, z], [0, 0, 1]);
        for (var i in this.contours) {
            polygon.add_contour(this.contours[i].build_3d_contour(z));
        }
        return polygon;
    }

    /**
     * Accept element visitor
     *
     * @param {cn_element_visitor} element_visitor
     */
    accept_visitor(element_visitor) {
        element_visitor.visit_slab(this);
    }

    /**
     * @returns {cn_slab_metrics}
     */
    get_metrics() {
        // @ts-ignore
        return this.get_lazy_data(() => {
            return new cn_slab_metrics(this);
        }, "metrics");
    }
}

