import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationService, FileUploaderComponent } from 'src/app/commons-lib';
import { DocumentFileService } from '../../../../../../services/document-file.service';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';

@Component({
    selector: 'app-validation-docs',
    templateUrl: './new-document.component.html',
})
export class NewDocumentComponent implements OnInit {
    @ViewChild('fileUploader', { static: false }) fileUploader: FileUploaderComponent;
    documentForm: FormGroup;
    fileUploaded: File;
    @Input() isNewDocument: boolean = false;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly documentService: DocumentFileService,
        private readonly confirmationService: ConfirmationService,
        public readonly diagnosticService: DiagnosticService,
        private readonly dialogRef: MatDialogRef<NewDocumentComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {
        this.createForm();
    }

    onSubmitForm() {
        console.log('submit ');
    }

    onClickCancel() {
        this.dialogRef.close();
    }

    uploadFile(formData: FormData) {
        this.fileUploaded = formData.get('file') as File;
    }

    /**
     * Suppression de la pièce jointe d'un document
     */
    deleteDocumentFile() {
        this.confirmationService.confirmWarn('Êtes-vous sûr de vouloir supprimer ce fichier attaché ?', () => {
            this.fileUploaded = null;
        });
    }

    /**
     * Crée le formulaire
     */
    private createForm(): void {
        if (this.isNewDocument) {
            this.documentForm = this.formBuilder.group({
                prestationLie: ['', [Validators.required]],
                nomDocument: ['', [Validators.required]],
                creePar: ['', [Validators.required]],
                dateCreation: ['', [Validators.required]],
                transmitPar: [null, [Validators.required]],
            });
        } else {
            this.documentForm = this.formBuilder.group({
                creePar: ['', [Validators.required]],
                dateCreation: ['', [Validators.required]],
                transmitPar: [null, [Validators.required]],
            });
        }
    }
}
