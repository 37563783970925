import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Competence, UserWizy } from '../model/user-wizy.model';
import { extractPage, PageRequest, toHttpParams } from '../shared/paging/page';

/**
 * Service d'appel aux API de management
 */
@Injectable({
    providedIn: 'root',
})
export class ManagementApiService {
    public resourceUrl = environment.apiUrl + '/management';

    constructor(private http: HttpClient) {}

    findAllUsersByName(search: string, pageRequest: PageRequest<UserWizy>) {
        return extractPage(
            this.http.get<UserWizy[]>(`${this.resourceUrl}/users`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), name: search },
            })
        );
    }

    findAllOperatorsBySearchString(search: string, pageRequest: PageRequest<UserWizy>) {
        return extractPage(
            this.http.get<UserWizy[]>(`${this.resourceUrl}/users`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), search: search },
            })
        );
    }

    searchUsers(search: string, pageRequest: PageRequest<UserWizy>) {
        return extractPage(
            this.http.get<UserWizy[]>(`${this.resourceUrl + '/users'}`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), 'search-for-list': search },
            })
        );
    }

    /**
     * Retourne un employé via son id
     * @param idUser
     * @returns
     */
    findOneUser(idUser: string): Observable<UserWizy[]> {
        return this.http.get<UserWizy[]>(`${this.resourceUrl}/${idUser}`);
    }

    /**
     * Crée ou met à jour un employé
     * @param user
     * @param competences
     * @returns
     */
    upsertUser(user: UserWizy, competences: Competence[]): Observable<UserWizy> {
        return this.http.post<UserWizy>(`${this.resourceUrl}/upsertUser`, {
            user,
            competences,
        });
    }

    isEmailAvailable(mail: string): Observable<boolean> {
        return this.http.get<boolean>(`${this.resourceUrl}/available/${mail}`);
    }

    findAllUsersByIds(ids: any[]): Observable<UserWizy[]> {
        return this.http.get<UserWizy[]>(`${this.resourceUrl}/operators`, {
            params: {
                ids: ids,
            },
        });
    }
}
