<h1 mat-dialog-title class="epi-title">{{ title }}</h1>

<div mat-dialog-content>
    <div *ngIf="isEmpty">Aucune aide disponible</div>
    <ng-template ngFor let-content [ngForOf]="contents">
        <mat-card class="text-center mb-4">
            <mat-card-content class="d-flex flex-column align-items-center">
                <mat-card-subtitle>
                    <div [innerHTML]="content.innerHTML"></div>
                </mat-card-subtitle>
                <div [innerHTML]="content.innerContenueHTML"></div>
                <div class="d-flex flex-column-reverse">
                    <div *ngIf="content.photo && (content.photo.idPhoto | fileDownload | async)" class="p-2">
                        <img
                            (click)="fullScreen($event)"
                            [src]="content.photo.idPhoto | fileDownload | async"
                            class="img-fluid"
                            style="cursor: zoom-in; max-width: 100%; max-height: 300px"
                        />
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </ng-template>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-raised-button color="primary" (click)="confirm()">
        <span>{{ 'Ok' | uppercase }}</span>
    </button>
</div>
