<div class="mpca-processus-modal">
    <div class="header">
        Détail du processus: {{ processusForm.get('nom').value }} | MPCA:
        {{ this.selectedMpca.general.materiauxClient }}
    </div>
    <div class="content forms" *ngIf="processusForm" [formGroup]="processusForm">
        <div class="forms-row">
            <mat-form-field>
                <input matInput type="text" formControlName="nom" placeholder="Nom du processus" data-cy="nom" />
            </mat-form-field>
        </div>
        <div class="forms-row">
            <div class="row-item">
                <app-mpca-form-metop
                    [formMetop]="processusForm.get('metop')"
                    [methodeConfig]="polluantConfig.contenuListeDeroulante.methodeTechniqueTraitement"
                    [techniqueConfig]="polluantConfig.contenuListeDeroulante.typeTechniqueDepose"
                    [outilConfig]="polluantConfig.contenuListeDeroulante.typeOutil"
                    [aspirationConfig]="polluantConfig.contenuListeDeroulante.typeCaptageSource"
                    [travailHumideConfig]="polluantConfig.contenuListeDeroulante.typeTravailHumide"
                >
                </app-mpca-form-metop>
                <div class="mt-2"></div>
                <app-mpca-form-pendant-travaux
                    [formPendantTravaux]="processusForm.get('pendantTravaux')"
                    [typeMasqueConfig]="polluantConfig.contenuListeDeroulante.typeMasque"
                    [niveauEmpoussierementConfig]="polluantConfig.contenuListeDeroulante.niveauEmpoussierement"
                >
                </app-mpca-form-pendant-travaux>
                <div class="mt-2"></div>
                <app-detail-processus-card [formTaches]="processusForm"></app-detail-processus-card>
            </div>
            <div class="row-item">
                <app-mpca-form-echantillonnage
                    [formEchantillonnage]="processusForm.get('echantillonnage')"
                    (generalDataChange)="onGeneralDataChange()"
                >
                </app-mpca-form-echantillonnage>
            </div>
        </div>
    </div>

    <div class="zone-action">
        <div>
            <button mat-flat-button color="warn" mat-dialog-close="delete" (click)="delete()">Supprimer</button>
        </div>

        <div class="group-button">
            <button mat-flat-button (click)="cancel()">Annuler</button>

            <button mat-flat-button color="primary" (click)="saveDiagnostic()" data-cy="sauvegarder-processus-button">
                Sauvegarder
            </button>
            <!-- <button mat-flat-button color="primary" [mat-dialog-close]="processusForm.value">Sauvegarder</button> -->
        </div>
    </div>
</div>
