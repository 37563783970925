import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { GestionAgencesModule } from './gestion-agences/gestion-agences.module';
import { GestionEquipementsModule } from './gestion-referentiels/gestion-equipements/gestion-equipements.module';
import { GestionReferentielsModule } from './gestion-referentiels/gestion-referentiels.module';
import { GestionPiecesModule } from './gestion-referentiels/gestion-pieces/gestion-pieces.module';
import { GestionDiagnosticsModule } from './gestion-referentiels/gestion-diagnotics/gestion-diagnostics.module';
import { GestionContactsModule } from './gestion-contacts/gestion-contacts.module';
import { GestionOperateursModule } from './gestion-operateurs/gestion-operateurs.module';
import { GestionBiensModule } from './gestion-biens/gestion-biens.module';
import { GestionCommandesModule } from './gestion-commandes/gestion-commandes.module';
import { GestionCofracsModule } from './gestion-cofracs/gestion-cofracs.module';
import { GestionTypesOuvrageModule } from './gestion-referentiels/gestion-types-ouvrage/gestion-types-ouvrage.module';
import { GestionMateriauxModule } from './gestion-referentiels/gestion-materiaux/gestion-materiaux.module';
import { GestionSynthesesModule } from './gestion-syntheses/gestion-syntheses.module';
import { ExportSyntheseService } from '../../services/export-synthese.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { LibIconModule } from 'src/app/commons-lib';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { PipesModule } from '../../pipes/pipes.module';
import { GestionTemplateRapportComponent } from './gestion-referentiels/gestion-template-rapport/gestion-template-rapport.component';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { LibsIconesComponent } from './gestion-referentiels/libs-icones/libs-icones.component';
import { GestionCategoriesOuvragesPiecesModule } from './gestion-referentiels/gestion-categories-ouvrages-pieces/gestion-categories-ouvrages-pieces.module';

@NgModule({
    imports: [
        CommonModule,
        AdminRoutingModule,
        GestionAgencesModule,
        GestionEquipementsModule,
        GestionTypesOuvrageModule,
        GestionMateriauxModule,
        GestionReferentielsModule,
        GestionPiecesModule,
        GestionDiagnosticsModule,
        GestionBiensModule,
        GestionContactsModule,
        GestionOperateursModule,
        GestionCommandesModule,
        GestionCofracsModule,
        GestionSynthesesModule,
        GestionCategoriesOuvragesPiecesModule,
        MatSidenavModule,
        MatToolbarModule,
        MatButtonModule,
        LibIconModule,
        MatTreeModule,
        MatIconModule,
        MatCardModule,
        MatFormFieldModule,
        MatSelectModule,
        ClipboardModule,
        PipesModule,
        FormsModule,
        MatInputModule,
    ],
    providers: [ExportSyntheseService],
    declarations: [GestionTemplateRapportComponent, LibsIconesComponent],
    exports: [GestionTemplateRapportComponent, LibsIconesComponent],
})
export class AdminModule {}
