export interface TreeInputData {
    id?: string;
    name?: string;
    nom?: string;
    parents?: string[];
    parentsIds?: string[];
}

export interface TreeUtilsNode<T> {
    id: string;
    name: string;
    data: T;
    children: TreeUtilsNode<T>[];
}

export interface BuildTreeOptions {
    // options liées au tri

    /**
     * Tri alphabétique ?
     */
    sortAlphabetically?: boolean;

    /**
     * Si fourni, les noeuds avec ce nom apparaîtront toujours en premier
     */
    firstNodesNames?: string[];

    // options liées à l'ajout d'un noeud parent

    /**
     * Ajouter un noeud parent ?
     */
    addRootNode?: boolean;

    /**
     * Label du noeud parent
     */
    rootNodeLabel?: string;

    /**
     * Id du noeud parent
     */
    rootNodeId?: string;

    /**
     * Données du noeud parent
     */
    rootNodeData?: any;
}

export class TreeUtils {
    /**
     * Construit un arbre à partir d'un tableau de données.
     * Chaque donnée du tableau doit contenir les propriétés
     * - "id"
     * - "name" ou "nom" (nom du noeud)
     * - "parentsIds" ou "parents" (référence vers les ids de parents).
     *
     * Ajoute potentiellement un noeud racine en fonction des options, par défaut sa propriété "data" est nulle, son nom est "Tous" et son "id" est "NODE_ALL".
     *
     * Trie potentiellement par ordre alphabétique si spécifié dans les options, sinon les noeuds sont affichés dans l'ordre dans lequel il sont fournis en entrée.
     *
     * @param dataArray Données
     * @param options Options
     */
    static buildTreeFromData<T>(
        dataArray: TreeInputData[],
        options?: BuildTreeOptions
    ): TreeUtilsNode<TreeInputData>[] {
        const addRootNode = options && options.addRootNode;
        const rootNodeLabel = options && options.rootNodeLabel ? options.rootNodeLabel : 'Tous';
        const rootNodeId = options && options.rootNodeId ? options.rootNodeId : 'NODE_ALL';
        const rootNodeData = options && options.rootNodeData ? options.rootNodeData : null;
        const firstNodesNames = options && options.firstNodesNames ? options.firstNodesNames : [];
        let treeNodes: TreeUtilsNode<TreeInputData>[] = dataArray
            .map((data) => {
                return {
                    id: data.id,
                    name: data.name ? data.name : data.nom ? data.nom : '',
                    data,
                    children: [],
                };
            })
            .map((data, idx, array) => {
                data.children = array.filter((child) => {
                    if (child.data.parentsIds) {
                        return child.data.parentsIds.length > 0 && child.data.parentsIds[0] === data.id;
                    } else if (child.data.parents) {
                        return child.data.parents.length > 0 && child.data.parents[0] === data.id;
                    } else {
                        return false;
                    }
                });
                if (options && options.sortAlphabetically) {
                    data.children = data.children.sort((a, b) => this.sortNodes(a, b, firstNodesNames));
                }
                return data;
            })
            .filter((element) => {
                if (element.data.parentsIds) {
                    return element.data.parentsIds.length === 0;
                } else if (element.data.parents) {
                    return element.data.parents.length === 0;
                } else {
                    return false;
                }
            });
        if (options && options.sortAlphabetically) {
            treeNodes = treeNodes.sort((a, b) => this.sortNodes(a, b, firstNodesNames));
        }
        // Cas 1, pas d'ajout de noeud racine, on renvoie tel quel
        if (!addRootNode) return treeNodes;
        // Cas 2, ajout d'un noeud racine
        return [{ id: rootNodeId, name: rootNodeLabel, data: rootNodeData, children: treeNodes }];
    }

    private static sortNodes<T>(a: TreeUtilsNode<T>, b: TreeUtilsNode<T>, firstNodesNames: string[]) {
        const nameA = a.name;
        const nameB = b.name;
        if (firstNodesNames && firstNodesNames.length) {
            if (firstNodesNames.includes(nameA) && firstNodesNames.includes(nameB)) {
                return firstNodesNames.indexOf(nameA) - firstNodesNames.indexOf(nameB);
            }
            if (firstNodesNames.includes(nameA)) {
                return -1;
            }
            if (firstNodesNames.includes(nameB)) {
                return 1;
            }
        }
        // cas standard
        return nameA.localeCompare(nameB, 'fr', { sensitivity: 'base' });
    }
}
