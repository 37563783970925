import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

@Component({
    selector: 'verification-card',
    templateUrl: './verification-card.component.html',
    styleUrls: ['./verification-card.component.scss'],
})
export class VerificationCardComponent implements OnInit {
    @Input()
    isInEditMode: Boolean = false;
    @Input()
    isValidated: Boolean = false;

    @Input()
    showValidateButton: Boolean = true;

    @Output()
    onCancel = new EventEmitter<any>();
    @Output()
    onSave = new EventEmitter<any>();
    @Output()
    onValidate = new EventEmitter<any>();
    @Output()
    onEdit = new EventEmitter<any>();

    @Input()
    form: FormGroup;

    previousValue: any;

    constructor() {}

    ngOnInit(): void {}

    //Edit the values of this screen
    edit() {
        this.onEdit.emit();
    }

    //Validate the values of this screen
    validate() {
        this.onValidate.emit();
    }

    cancel() {
        this.onCancel.emit();
    }

    save() {
        this.onSave.emit();
    }
}
