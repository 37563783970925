<h1 mat-dialog-title>{{ data.archiveOnly ? 'Confirmation' : 'Confirmation de suppression' }}</h1>

<div mat-dialog-content [innerHTML]="message"></div>

<div *ngIf="messageCheckboxConfirm" class="mt-3">
    <mat-checkbox [(ngModel)]="enableConfirm">
        <span>{{ messageCheckboxConfirm }}</span>
    </mat-checkbox>
</div>

<div *ngIf="data.canReplaceUser" class="mt-3">
    <mat-checkbox [(ngModel)]="replacementUserEnabled">
        <span>Je souhaite nommer un utilisateur remplaçant</span>
    </mat-checkbox>
    <mat-form-field *ngIf="replacementUserEnabled" class="w-100">
        <mat-label>Email du remplaçant</mat-label>
        <input
            matInput
            type="email"
            email
            required
            [(ngModel)]="replacementUserEmail"
            [matAutocomplete]="autoEmail"
            (keyup)="replacementUserEmailChanged$.next()"
        />
        <mat-autocomplete #autoEmail="matAutocomplete">
            <mat-option *ngFor="let option of replacementUserOptions$ | async" [value]="option">
                {{ option }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>

<!-- Input caché pour la validation de l'email, car le champ de saisie n'est pas toujours dans le DOM -->
<input matInput type="email" email required [hidden]="true" [ngModel]="replacementUserEmail" #email="ngModel" />

<div mat-dialog-actions>
    <button
        mat-raised-button
        color="warn"
        cdkFocusInitial
        [disabled]="!enableConfirm || (replacementUserEnabled && email?.invalid)"
        (click)="onConfirm()"
    >
        <mat-icon>check</mat-icon>
        <span>{{ confirmLabel || 'Confirmer' }}</span>
    </button>

    <button mat-button (click)="onDismiss()">
        <mat-icon>close</mat-icon>
        <span>Annuler</span>
    </button>
</div>
