'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** cn_roof_height : a height position for roof
//***********************************************************************************
//***********************************************************************************

import { cn_element } from './cn_element';
import { cnx_add, cnx_clone, cnx_dist, cnx_normalize, cnx_sub, cn_add, cn_box, cn_cart, cn_clone, cn_dist, cn_mul, cn_normalize, cn_polar, cn_sub } from '../utils/cn_utilities';
import { cn_pastille } from '../svg/cn_pastille';
import { fh_add, fh_cross, fh_dot, fh_mul, fh_normalize } from '@acenv/fh-3d-viewer';
import { cn_image_dir } from '../utils/image_dir';
import { logger } from '../utils/cn_logger';

export class cn_roof_height extends cn_element {
    constructor(p, roof) {
        super(roof);
        this.removable = false;
        this.roof = roof;

        //*** serialized data
        this.custom = false;
        this.position = cn_clone(p);  	//*** position of the height value
        this.vertex = null;				//*** Maybe height is defined by a roof vertex ?
        this.line = null;					//*** Maybe height is defined by a roof line ?
        this.slab = null;					//*** Maybe height is defined by a slab ?
        this.values = [];				//*** Height value for each slab that share the height
        this.locks = [];				//*** Height lock for each slab that share the height
        this.neighbour_lock = true;		//*** locks between consective slabs

        //*** volatile data
        this.slabs = [];				//*** slabs that share the height
        this.directions = []; 			//*** direction to slabs, one for each slab. [[0,0]] if only one slab.
        this.storey_angles = [];
        this.angles = [];

        this.neighbour_lock_pastille = new cn_pastille();
        this.neighbour_lock_pastille.rectangle = [40, 20];
        this.neighbour_lock_pastille.svg_class = 'roof_height_neighbour_lock';

        this._box_positions = [];
    }

    //***********************************************************************************
    //**** Create a new custom roof height
    //***********************************************************************************
    static new_custom(roof) {
        var rh = new cn_roof_height([0, 0], roof);
        rh.custom = true;
        rh.removable = true;
        rh.update();
        return rh;
    }

    //***********************************************************************************
    //**** serialize
    //***********************************************************************************
    serialize() {
        var json = {};
        json.ID = this.ID;
        json.custom = this.custom;
        json.position = cn_clone(this.position);
        if (this.vertex)
            json.vertex = this.vertex.s_index;
        if (this.line)
            json.line = this.line.s_index;
        if (this.slab)
            json.slab = this.slab.s_index;
        json.values = this.values.concat([]);
        json.locks = this.locks.concat([]);
        json.neighbour_lock = this.neighbour_lock;
        return json;
    }

    static unserialize(json, scene) {
        if (typeof (json) != 'object') return false;
        var roof_height = new cn_roof_height(json.position, scene);
        scene.heights.push(roof_height);
        if (typeof (json.ID) == 'string')
            roof_height.ID = json.ID;
        if (typeof (json.custom) == 'boolean')
            roof_height.custom = json.custom;
        if (typeof (json.vertex) == 'number' && json.vertex >= 0 && json.vertex < scene.vertices.length)
            roof_height.vertex = scene.vertices[json.vertex];
        if (typeof (json.line) == 'number' && json.line >= 0 && json.line < scene.lines.length)
            roof_height.line = scene.lines[json.line];
        if (typeof (json.slab) == 'number' && json.slab >= 0 && json.slab < scene.slabs.length)
            roof_height.slab = scene.slabs[json.slab];
        if (typeof (json.values) == 'object')
            roof_height.values = json.values.concat([]);
        if (typeof (json.locks) == 'object')
            roof_height.locks = json.locks.concat([]);
        if (typeof (json.neighbour_lock) == 'boolean')
            roof_height.neighbour_lock = json.neighbour_lock;

        roof_height.removable = roof_height.custom;
        return roof_height;
    }

    //***********************************************************************************
    //**** Sets lock and value
    //***********************************************************************************
    set(index, lock, value) {
        if (this.neighbour_lock) {
            for (var i = 0; i < this.slabs.length; i++) {
                this.locks[i] = lock;
                if (lock) this.values[i] = value;
            }
            return;
        }
        if (index >= 0 && index < this.slabs.length) {
            this.locks[index] = lock;
            if (lock) this.values[index] = value;
        }
    }

    //***********************************************************************************
    //**** Draw the roof height
    //***********************************************************************************
    draw(camera, extra, highlight, highlight_3d = -1) {
        var html = '';

        this._box_size = [40, 20];
        this._box_positions = [];

        const position = cnx_clone(this.position);
        var center = [0, 0];
        var low_center = [0, 0];
        var lock_pos;

        //*** Compuute screen position for each height value */
        this.screen_positions = [];
        this.draw_checked = false;
        var lowest = 100;
        var highest = -100;
        this.values.forEach(v => {
            position[2] = v;
            const sc = camera.world_to_screen(position);
            if (sc.length < 2) return;
            if (isNaN(sc[0]) || isNaN(sc[1])) return;
            this.screen_positions.push(sc);
            if (v > highest) {
                highest = v;
                center = sc;
            }
            if (v < lowest) {
                lowest = v;
                low_center = sc;
            }
            if (!this.draw_checked && camera.check_visibility(position))
                this.draw_checked = true;
        });
        if (!this.draw_checked || this.screen_positions.length != this.values.length) return html;

        position[2] = highest;

        const delta_height = this.roof.storey.height;
        const box_distance = 50;
        if (this.custom && this.slab == null) {
            //*** do nothing here */
        } else if (this.neighbour_lock || this.slab || this.custom) {
            var box_class = 'roof_height_box';
            if (highlight >= 0)
                box_class += ' mouseover';
            if (this.locks[0])
                box_class += ' locked';
            else if (this.slabs.some(s => s.defined))
                box_class += ' defined';

            var tp = cn_add(center, [box_distance, 0]);
            html += '<line class=\'roof_height_line\' x1=\'' + center[0] + '\' y1=\'' + center[1] + '\' x2=\'' + tp[0] + '\' y2=\'' + tp[1] + '\' />';

            lock_pos = tp;
            if (this.is_coherent())
                html += camera.draw_text_box_screen(tp, this._box_size[0], this._box_size[1], (100 * (this.values[0] + delta_height)).toFixed(0), box_class, 'roof_height_text');
            else
                html += camera.draw_text_box_screen(tp, this._box_size[0], this._box_size[1], '-', box_class + ' incoherent', 'roof_height_text');

            this._box_positions.push(tp);
            this.neighbour_lock_pastille.offset = [40, 0];
        } else {
            if (camera.is_3d()) {
                html += '<line class=\'roof_height_line\' x1=\'' + center[0] + '\' y1=\'' + center[1] + '\' x2=\'' + low_center[0] + '\' y2=\'' + low_center[1] + '\' />';
            }

            var angles = [];
            for (var ii = 0; ii < this.slabs.length; ii++) {
                //*** compute direction to text box */
                var a0 = this.angles[ii];
                var a1 = (ii + 1 < this.angles.length) ? this.angles[ii + 1] : this.angles[0] + 2 * Math.PI;
                var p = cnx_add(this.position, cn_cart([1, 0.5 * (a0 + a1)]));
                p[2] = this.values[ii];
                const dir = cn_sub(camera.world_to_screen(p), this.screen_positions[ii]);
                cn_normalize(dir);
                angles.push(cn_polar(dir)[1]);

                //*** compute box position */
                var box_position = cn_add(this.screen_positions[ii], cn_mul(dir, box_distance));
                this._box_positions.push(box_position);

                //** draw line from point to box */
                html += '<line class=\'roof_height_line\' x1=\'' + this.screen_positions[ii][0] + '\' y1=\'' + this.screen_positions[ii][1] + '\' x2=\'' + box_position[0] + '\' y2=\'' + box_position[1] + '\' />';

                //*** displace screen position */
                this.screen_positions[ii] = cn_add(this.screen_positions[ii], cn_mul(dir, 20));

                //*** draw box text */
                var box_class = 'roof_height_box';
                if (ii == highlight) box_class += ' mouseover';
                if (this.locks[ii]) box_class += ' locked';
                else if (this.slabs[ii].defined) box_class += ' defined';
                html += camera.draw_text_box_screen(box_position, this._box_size[0], this._box_size[1], (100 * (this.values[ii] + delta_height)).toFixed(0), box_class, 'roof_height_text');
            }

            position[2] = 0.5 * (lowest + highest);
            lock_pos = camera.world_to_screen(position);
            angles.sort((a, b) => a - b);
            var da = 0;
            var best_angle = 0;
            for (var ia = 0; ia < angles.length; ia++) {
                const a0 = angles[ia];
                const a1 = (ia == angles.length - 1) ? 2 * Math.PI + angles[0] : angles[ia + 1];
                if (a1 - a0 > da) {
                    da = a1 - a0;
                    best_angle = 0.5 * (a0 + a1);
                }
            }
            this.neighbour_lock_pastille.offset = cn_cart([box_distance, best_angle]);

        }

        //*** Draw neighbour lock pastille
        if (this.slabs.length > 1) {
            if (!this.neighbour_lock) {
                const pl = cn_add(lock_pos, this.neighbour_lock_pastille.offset);
                html += '<line class=\'roof_height_line\' x1=\'' + lock_pos[0] + '\' y1=\'' + lock_pos[1] + '\' x2=\'' + pl[0] + '\' y2=\'' + pl[1] + '\' />';
            }
            this.neighbour_lock_pastille.position = camera.screen_to_world(lock_pos);
            this.neighbour_lock_pastille.label = (this.neighbour_lock) ? 'link.svg' : 'unlink.svg';
            html += this.neighbour_lock_pastille.draw(camera);
        }

        //*** Draw height markers */
        var r = 10;
        if (camera.is_3d()) {
            var classes = 'roof_height_disk';
            if (this.custom) classes += ' custom';
            if (this.neighbour_lock || this.slab || this.custom) {
                center = this.screen_positions[0];

                //*** for a cuustom wrong height, we add opacity */
                if (this.custom && this.slab == null)
                    html += '<g opacity=\'0.3\'>';

                //*** Draw move arrow */
                if (highlight_3d >= 0) {
                    const dir = cnx_sub(camera.world_to_screen(cnx_add(position, [0, 0, 1])), center);
                    var multi = 50 / cnx_normalize(dir);
                    if (multi > 10) multi = 10;
                    const v0 = camera.world_to_screen(cnx_sub(position, [0, 0, multi]));
                    const v1 = camera.world_to_screen(cnx_sub(position, [0, 0, -multi]));
                    html += `<line class="dimensionning_line" x1="${v0[0]}" y1="${v0[1]}" `;
                    html += `x2="${v1[0]}" y2="${v1[1]}" `;
                    html += `/>`;
                }

                //*** Draw height marker */
                var h_classes = (highlight_3d >= 0) ? classes + ' mouseover' : classes;
                if (this.locks[0])
                    h_classes += ' locked';
                else if (!this.slabs.some(s => s.defined))
                    h_classes += ' unlocked';
                html += '<circle class=\'' + h_classes + '\' cx=\'' + center[0] + '\' cy=\'' + center[1] + '\' r=\'' + r + '\'/>';

                if (this.custom && this.slab == null)
                    html += '</g>';
            } else {
                for (var ii = 0; ii < this.slabs.length; ii++) {
                    var h_classes = (highlight_3d == ii) ? classes + ' mouseover' : classes;
                    if (this.locks[ii])
                        h_classes += ' locked';
                    else if (!this.slabs[ii].defined)
                        h_classes += ' unlocked';
                    html += '<circle class=\'' + h_classes + '\' cx=\'' + this.screen_positions[ii][0] + '\' cy=\'' + this.screen_positions[ii][1] + '\' r=\'' + r + '\'/>';
                }
            }
        } else {
            var classes = 'roof_height_disk';
            if (this.custom && this.slab == null)
                html += '<g opacity=\'0.3\'>';
            if (this.custom) {
                classes += ' custom';
                if (extra.indexOf('mouseover') >= 0) classes += ' mouseover';
                else if (extra.indexOf('selected') >= 0) classes += ' selected';
            }
            html += '<circle class=\'' + classes + '\' cx=\'' + center[0] + '\' cy=\'' + center[1] + '\' r=\'' + r + '\'/>';
            html += '<image xlink:href=\'' + cn_image_dir() + 'roof_height.png\' x=\'' + (center[0] - r) + '\' y=\'' + (center[1] - r) + '\' width=\'' + (r * 2) + '\' height=\'' + (r * 2) + '\' />';

            if (this.custom && this.slab == null)
                html += '</g>';
        }

        return html;
    }

    //***********************************************************************************
    //**** Draw for export
    //***********************************************************************************
    draw_for_export(camera, delta_height) {
        var html = '';

        this._box_size = [40, 20];

        var center = camera.world_to_screen(this.position);

        if (this.custom && this.slab == null) return html;

        //*** Number of different values */
        var coherent = true;
        var ref = (100 * (this.values[0] + delta_height)).toFixed(0);
        for (var i = 1; i < this.values.length; i++) {
            var x = (100 * (this.values[i] + delta_height)).toFixed(0);
            if (x == ref) continue;
            coherent = false;
            break;
        }

        //** One single value */
        if (coherent) {
            var text_pos = cn_add(this.position, cn_mul([40, 0], camera.screen_to_world_scale));
            var tp = camera.world_to_screen(text_pos);
            html += '<line class=\'roof_height_line exp\' x1=\'' + center[0] + '\' y1=\'' + center[1] + '\' x2=\'' + tp[0] + '\' y2=\'' + tp[1] + '\' />';
            html += camera.draw_text_box(text_pos, this._box_size[0], this._box_size[1], ref, 'roof_height_box exp', 'roof_height_text exp');
        } else {
            var radius = 40 * camera.screen_to_world_scale;
            for (var i = 0; i < this.slabs.length; i++) {
                var a0 = this.angles[i];
                var a1 = (i + 1 < this.angles.length) ? this.angles[i + 1] : this.angles[0] + 2 * Math.PI;
                var box_position = cn_add(this.position, cn_cart([radius, 0.5 * (a0 + a1)]));

                var p = camera.world_to_screen(box_position);
                html += '<line class=\'roof_height_line exp\' x1=\'' + center[0] + '\' y1=\'' + center[1] + '\' x2=\'' + p[0] + '\' y2=\'' + p[1] + '\' />';
                html += camera.draw_text_box(box_position, this._box_size[0], this._box_size[1], (100 * (this.values[i] + delta_height)).toFixed(0), 'roof_height_box exp', 'roof_height_text exp');
            }
        }

        //*** draw roof height disk
        var r = 10;
        html += '<circle class=\'roof_height_disk exp\' cx=\'' + center[0] + '\' cy=\'' + center[1] + '\' r=\'' + r + '\'/>';
        html += '<use xlink:href=\'#roof_height\' x=\'' + (center[0] - r) + '\' y=\'' + (center[1] - r) + '\' />';

        return html;
    }

    //******************************************************
    //*** is the height coherent between neighbours ?
    //******************************************************
    is_coherent() {
        if (!this.neighbour_lock) return true;
        if (this.slabs.length < 2) return true;

        var value = false;
        for (var i = 0; i < this.slabs.length; i++) {
            if (this.values[i] === false) continue;
            if (value === false) {
                value = this.values[i];
                continue;
            }
            // @ts-ignore
            if (Math.abs(this.values[i] - value) > 0.001)
                return false;
        }
        return true;
    }

    //******************************************************
    //*** Contains a given point ?
    //******************************************************
    mouseover(pt, camera) {
        if (!this.draw_checked) return -1;

        if (this.slabs.length > 1) {
            this.neighbour_lock_pastille.mouseover = this.neighbour_lock_pastille.contains(pt, camera);
            if (this.neighbour_lock_pastille.mouseover)
                return this.slabs.length;
        }

        for (var i = 0; i < this._box_positions.length; i++) {
            if (!this.locks[i] && this.slabs[i].defined) continue;
            var xx = cn_sub(camera.world_to_screen(pt), this._box_positions[i]);
            if (Math.abs(xx[0]) < this._box_size[0] / 2 && Math.abs(xx[1]) < this._box_size[1] / 2)
                return i;
        }
        return -1;
    }

    custom_mouseover(pt, camera) {
        if (!this.draw_checked) return false;
        if (this.custom && cn_dist(pt, this.position) <= 10 * camera.screen_to_world_scale) {
            return true;
        }
        return false;
    }

    mouseover_3d_height(ev) {
        if (!this.draw_checked) return -1;
        if (ev.ray && Math.abs(ev.ray.direction[2]) > 0.8) return -1;
        for (var i = 0; i < this.screen_positions.length; i++) {
            if (!this.locks[i] && this.slabs[i].defined) continue;
            if (cn_dist(this.screen_positions[i], ev.mouse_screen) < 20)
                return i;
        }
        return -1;
    }

    //******************************************************
    //*** switch neighbour lock
    //******************************************************
    switch_neighbour_lock() {

        //*** simple case : we unlock
        if (this.neighbour_lock) {
            this.neighbour_lock = false;
            return;
        }

        this.neighbour_lock = true;

        //*** not so simple case : we lock
        var average_lock = 0;
        var num_locs = 0;
        for (var i = 0; i < this.slabs.length; i++) {
            if (!this.locks[i]) continue;
            num_locs++;
            average_lock += this.values[i];
        }

        //*** simple if no value was locked
        if (num_locs == 0) return;

        //*** We apply coherent locked value
        average_lock /= num_locs;
        for (var i = 0; i < this.slabs.length; i++) {
            this.locks[i] = true;
            this.values[i] = average_lock;
        }
    }

    //******************************************************
    //*** update
    //******************************************************
    update() {
        //*** maybe just a slab height ?
        if (this.slab || this.custom) {
            if (this.values.length != 1)
                this.values = [0];
            if (this.locks.length != 1)
                this.locks = [false];
            this.slabs = [this.slab];
            this.angles = this.storey_angles;
            if (!this.locks[0]) {
                if (this.slabs[0])
                    this.values[0] = this.slabs[0].compute_height(this.position);
                else
                    this.values[0] = 0;
            }
            return;
        }

        this.angles = [];
        this.slabs = [];

        //*** maybe a line height ?
        if (this.line) {
            var alpha = cn_polar(this.line.bounds.direction)[1];
            var reverse = false;
            while (alpha >= this.storey_angles[0]) alpha -= 2 * Math.PI;
            while (alpha < this.storey_angles[0]) {
                alpha += Math.PI;
                reverse = !reverse;
            }
            this.angles = [this.storey_angles[0], alpha, this.storey_angles[1]];
            if (reverse)
                this.slabs = [this.line.slabs[1], this.line.slabs[0]];
            else
                this.slabs = [this.line.slabs[0], this.line.slabs[1]];
        }

        //*** Maybe a vertex ?
        if (this.vertex) {
            for (var j = 0; j < this.vertex.lines.length; j++) {
                this.angles.push(this.vertex.angles[j]);
                var line = this.vertex.lines[j];
                var s = (line.vertices[0] == this.vertex) ? line.slabs[1] : line.slabs[0];
                this.slabs.push(s);
            }
        }

        //*** update elements
        if (this.values.length != this.slabs.length) {
            this.values = new Array(this.slabs.length);
            this.values.fill(0);
        }
        if (this.locks.length != this.slabs.length) {
            this.locks = new Array(this.slabs.length);
            this.locks.fill(false);
        }
        if (this.slabs.length == 1)
            this.directions = [[0, 0]];

        for (var i in this.slabs) {
            if (this.locks[i]) continue;
            this.values[i] = this.slabs[i].compute_height(this.position);
        }
    }

    //******************************************************
    //*** Returns height of a given slab
    //******************************************************
    slab_height(slab) {
        var index = this.slabs.indexOf(slab);
        if (index < 0) return false;
        return this.values[index];
    }

    //******************************************************
    //*** Force neighbour lock
    //******************************************************
    force_neighbour_lock() {
        var slabs = [];
        for (var i = 0; i < this.slabs.length; i++) {
            if (this.locks[i]) return false;
            if (this.slabs[i].defined)
                slabs.push(this.slabs[i]);
        }

        if (slabs.length < 2) return false;

        var normal = null;
        var direction = null;
        var p = [this.position[0], this.position[1], 0];
        for (var i = 0; i < slabs.length; i++) {
            var slab = slabs[i];
            if (normal == null) {
                normal = slab.normal;
                p[2] = slab.compute_height(p);
                continue;
            }
            if (direction == null) {
                var dir = fh_cross(normal, slab.normal);
                if (fh_normalize(dir) < 0.001) continue;
                direction = dir;
                var d = fh_cross(direction, normal);
                fh_normalize(d);
                var lambda = (slab.plane - fh_dot(p, slab.normal)) / fh_dot(d, slab.normal);
                p = fh_add(p, fh_mul(d, lambda));
                logger.log('comparison ' + p[2] + ' ' + slab.compute_height(p));
                continue;
            }
            var den = fh_dot(direction, slab.normal);
            if (Math.abs(den) < 0.001) continue;
            var lambda = (slab.plane - fh_dot(p, slab.normal)) / fh_dot(direction, slab.normal);
            p = fh_add(p, fh_mul(direction, lambda));
            logger.log('comparison ' + p[2] + ' ' + slab.compute_height(p));
            break;
        }

        if (direction == null) return false;
        this.vertex.position = cn_clone(p);
        for (var i = 0; i < slabs.length; i++) {
            logger.log('height : ' + slabs[i].compute_height(this.vertex.position));
        }
    }

    get_bounding_box() {
        const box = new cn_box();
        box.enlarge_point(this.position);
        return box;
    }
}

