import { Document } from '../model/document.model';

export class DocumentUtils {
    /**
     * Sort des documents selon le nom
     */
    static sortDocuments(documentToSort: Document[]): Document[] {
        if (documentToSort && documentToSort.length > 0) {
            return documentToSort.sort((c1, c2) => c1.nom.localeCompare(c2.nom));
        } else {
            return documentToSort;
        }
    }
}
