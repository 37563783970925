<div>
    <div class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">
        {{ 'Visibilité des équipements' | uppercase }}
    </div>
    <div class="block-subtitle ml-4 mb-2" *ngIf="data">
        {{ title }}
    </div>
    <div class="block-content px-5 ml-2 mb-4">
        <ng-container *ngFor="let bien of nonVisible.biens">
            <div class="block-subtitle-2 mt-4 mb-2">{{ bien.bien }}</div>
            <table>
                <tr>
                    <th class="niveau">Niveau / Volume</th>
                    <ng-container *ngIf="optionPlan">
                        <th class="plan">Plan</th>
                        <th class="edit-comment"></th>
                    </ng-container>
                    <th class="equip">Équipements</th>
                    <th class="non-visible">Partie non visible</th>
                    <th>Commentaires</th>
                    <th class="edit-comment"></th>
                </tr>
                <tr *ngIf="bien.niveaux.length === 0">
                    <td [attr.colspan]="optionPlan ? '8' : '6'" class="text-center p-4">
                        Aucun équipement totalement ou partiellement non-visible
                    </td>
                </tr>
                <ng-container *ngFor="let lv of bien.niveaux">
                    <tr>
                        <td [attr.colspan]="optionPlan ? '8' : '6'" class="row-niveau">
                            {{ lv.niveau }}
                        </td>
                    </tr>
                    <ng-container *ngFor="let vol of lv.volumes">
                        <tr *ngFor="let el of vol.equipements">
                            <td>
                                {{ vol.volume }}
                            </td>
                            <ng-container *ngIf="optionPlan">
                                <td>
                                    <ng-container *ngFor="let screen of vol.plans">
                                        {{ screen.nom }} <br />
                                    </ng-container>
                                </td>
                                <td>
                                    <button
                                        *ngIf="readonlyMode"
                                        mat-icon-button
                                        class="small-button button-group primary"
                                        (click)="onClickEditPlansList(vol)"
                                    >
                                        <mat-icon class="icon-small">edit</mat-icon>
                                    </button>
                                </td>
                            </ng-container>
                            <td>
                                <b>{{ el.element }}</b>
                            </td>
                            <td>
                                {{ el.nonVisibles.join(',\n') }}
                            </td>
                            <td>
                                <ng-container *ngFor="let com of el.commentaires">
                                    <div [ngClass]="exclusionNonVisible.includes(com.id) ? 'hidden-com' : ''">
                                        {{ com.contenu }} <br />
                                    </div>
                                </ng-container>
                            </td>
                            <td>
                                <button
                                    mat-icon-button
                                    class="small-button button-group primary"
                                    *ngIf="!readonlyMode && el.commentaires.length"
                                    (click)="onClickEditDisplayedComments(el.commentaires, nonVisible.code)"
                                >
                                    <mat-icon class="icon-small">edit</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </table>
        </ng-container>
    </div>
</div>
