<h1 mat-dialog-title class="primary d-flex justify-content-between">
    <span>Calcul par partie</span>
    <button mat-button (click)="dialogRef.close()">
        <lib-icon>close</lib-icon>
    </button>
</h1>
<div mat-dialog-content>
    <div class="zones-details" *ngIf="zones?.length > 0 && !editZone">
        <table [dataSource]="zones" class="mat-elevation-z8" mat-table>
            <ng-container matColumnDef="mode">
                <th mat-header-cell *matHeaderCellDef></th>
                <td
                    mat-cell
                    class="mat-cell mode-cell {{ zone?.mode }}"
                    *matCellDef="let zone"
                    [ngClass]="zone?.style !== undefined ? zone?.style : ''"
                >
                    <lib-icon>{{ zone?.mode }}</lib-icon>
                </td>
                <td mat-footer-cell *matFooterCellDef>Total</td>
            </ng-container>
            <ng-container matColumnDef="nom">
                <th mat-header-cell *matHeaderCellDef>
                    <div>Nom</div>
                </th>
                <td mat-cell *matCellDef="let zone" [ngClass]="zone?.style !== undefined ? zone?.style : ''">
                    <div>
                        <b>{{ zone?.nom }}</b>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="surface">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="text-center">Surface (m²)</div>
                </th>
                <td mat-cell *matCellDef="let zone" [ngClass]="zone?.style !== undefined ? zone?.style : ''">
                    <div
                        [innerHTML]="zone.surface ? (zone.surface | number: '1.2-2' | mesure | safeHtml) : 'n.c.'"
                    ></div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="quantite">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="text-center">Quantité</div>
                </th>
                <td
                    mat-cell
                    *matCellDef="let zone"
                    class="text-center"
                    [ngClass]="zone?.style !== undefined ? zone?.style : ''"
                >
                    <div class="d-flex align-items-center wrapper">
                        <button
                            mat-icon-button
                            color="warn"
                            [disabled]="zone.quantite < 2"
                            (click)="zone.quantite = zone.quantite - 1"
                        >
                            <lib-icon>remove</lib-icon>
                        </button>
                        <input
                            type="number"
                            readonly
                            class="num"
                            value="1"
                            (click)="openNumKeyboard('quantite')"
                            [(ngModel)]="zone.quantite"
                        />
                        <button mat-icon-button color="primary" (click)="zone.quantite = zone.quantite + 1">
                            <lib-icon>add</lib-icon>
                        </button>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef class="text-center">
                    <b>{{ total | number: '1.2-2' }}</b>
                </td>
            </ng-container>
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef>
                    <div></div>
                </th>
                <td mat-cell *matCellDef="let zone" [ngClass]="zone?.style !== undefined ? zone?.style : ''">
                    <button mat-icon-button matTooltip="Éditer" [disabled]="readonlyMode" (click)="editZoneRow(zone)">
                        <lib-icon>create</lib-icon>
                    </button>
                    <button
                        mat-icon-button
                        color="warn"
                        matTooltip="Supprimer"
                        [disabled]="readonlyMode"
                        (click)="deleteZone(zone)"
                    >
                        <lib-icon>delete</lib-icon>
                    </button>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <tr *matHeaderRowDef="displayedColumnsZones; sticky: true" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumnsZones" mat-row></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumnsZones"></tr>
        </table>
    </div>

    <div *ngIf="!editZone" class="d-flex justify-content-center pt-4">
        <button
            mat-raised-button
            color="accent"
            [disabled]="readonlyMode"
            (click)="editZone = true; zoneToEdit = { quantite: 1 }"
        >
            <lib-icon>add</lib-icon>
            {{ zones?.length > 0 ? 'Ajouter une partie' : ('Ajouter une première partie' | uppercase) }}
        </button>
    </div>

    <div class="w-100" *ngIf="editZone">
        <!--Saisie de surface-->
        <mat-card class="mb-2 d-flex flex-column justify-content-between">
            <mat-card-content>
                <div class="d-flex flex-row align-items-center justify-content-between mb-2">
                    <div>
                        <div class="required">Nom de la partie</div>
                        <div class="ml-5 row align-items-center">
                            <mat-form-field>
                                <input
                                    name="nameP"
                                    matInput
                                    type="text"
                                    [(ngModel)]="zoneToEdit.nom"
                                    (ngModelChange)="isUniqueName()"
                                />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="mesure">
                        <div class="required">Surface de la partie</div>
                        <div class="ml-4 row align-items-center">
                            <mat-form-field appearance="outline">
                                <input
                                    matInput
                                    class="ml-2"
                                    type="number"
                                    [(ngModel)]="zoneToEdit.surface"
                                    (click)="openNumKeyboard('surface')"
                                    readonly
                                />
                            </mat-form-field>

                            <div class="button-group-mesure">
                                <button mat-icon-button color="primary" (click)="setZero()">
                                    <mat-icon>close</mat-icon>
                                </button>
                                <button mat-icon-button color="primary" (click)="openCalculator()">
                                    <mat-icon>calculate</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="required">Ajouter ou déduire</div>
                        <mat-button-toggle-group [(value)]="zoneToEdit.mode">
                            <mat-button-toggle value="add" class="green-toggle" [matTooltip]="'Ajouter la partie'">
                                <mat-icon>add</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle value="remove" class="red-toggle" [matTooltip]="'Déduire la partie'">
                                <mat-icon>remove</mat-icon>
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
                <div *ngIf="nameAlreadyPresent" class="error-div p-2">Une partie du même nom est déjà présente</div>
                <div class="d-flex flex-row justify-content-end">
                    <button mat-raised-button color="accent" (click)="validateZone()">
                        {{ editMode ? 'Modifier' : 'Créer' }}
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-stroked-button color="accent" (click)="dialogRef.close()">
        <span>Annuler</span>
    </button>

    <button mat-raised-button class="ml-2" color="accent" (click)="onValider()">
        <span>Valider</span>
    </button>
</div>
