import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestionOperateursComponent } from './gestion-operateurs.component';
import { CreationOperateurComponent } from './creation-operateur/creation-operateur.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LibIconModule } from 'src/app/commons-lib';
import { MatCardModule } from '@angular/material/card';
import { CnDiagSharedModule } from '../../shared/shared.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TableauOperateursComponent } from './tableau-operateurs/tableau-operateurs.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CreationCompetenceModalComponent } from './creation-operateur/creation-competence/creation-competence-modal.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [GestionOperateursComponent, CreationOperateurComponent, TableauOperateursComponent, CreationCompetenceModalComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatIconModule,
        MatTableModule,
        MatSortModule,
        MatButtonModule,
        MatMenuModule,
        MatPaginatorModule,
        MatToolbarModule,
        LibIconModule,
        MatCardModule,
        ReactiveFormsModule,
        CnDiagSharedModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatDialogModule,
    ],
    entryComponents: [CreationCompetenceModalComponent],
})
export class GestionOperateursModule {}
