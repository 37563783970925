<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">
        <!-- Nom -->
        <ng-container matColumnDef="compagnie">
            <th mat-header-cell *matHeaderCellDef>Nom</th>
            <td mat-cell *matCellDef="let element">{{ element.compagnie }}</td>
        </ng-container>

        <!-- Date de debut Column -->
        <ng-container matColumnDef="dateDebutValidite">
            <th mat-header-cell *matHeaderCellDef>Date de début</th>
            <td mat-cell *matCellDef="let element">{{ element.dateDebutValidite | date: 'shortDate' }}</td>
        </ng-container>

        <!-- Date de fin Column -->
        <ng-container matColumnDef="dateEcheance">
            <th mat-header-cell *matHeaderCellDef>Date de fin</th>
            <td mat-cell *matCellDef="let element">{{ element.dateEcheance | date: 'shortDate' }}</td>
        </ng-container>

        <!-- action -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element">
                <mat-icon *ngIf="!readonlyMode" (click)="editAssurance(element)">edit</mat-icon>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]"> </mat-paginator>
</div>
