<div class="p-2">
    <!--Zone d'édition de nom-->
    <div class="d-flex p-2 align-items-center">
        <div class="mat-subheading-2 m-0 primary required">Nom de la zone</div>
        <div class="pl-4 pr-2">
            <mat-form-field class="w-100">
                <input
                    matInput
                    type="text"
                    [(ngModel)]="zoneToEdit.nom"
                    (ngModelChange)="isUniqueName()"
                    [readonly]="!editNameMode"
                />
            </mat-form-field>
        </div>
        <button mat-icon-button *ngIf="!editNameMode" (click)="editName()" matTooltip="Éditer la zone">
            <lib-icon>create</lib-icon>
        </button>
        <button
            mat-icon-button
            color="primary"
            *ngIf="editNameMode"
            (click)="confirmName()"
            matTooltip="Valider la modification"
            [disabled]="nameAlreadyPresent"
        >
            <lib-icon>done</lib-icon>
        </button>
        <button
            mat-icon-button
            color="primary"
            *ngIf="editNameMode"
            (click)="cancelName()"
            matTooltip="Annuler la modification"
        >
            <lib-icon>clear</lib-icon>
        </button>
    </div>
    <div *ngIf="nameAlreadyPresent" class="error-div p-2">Une surface du même nom est déjà présente</div>
</div>

<!--Saisie de surface-->
<mat-card class="mb-2 d-flex flex-column justify-content-between">
    <mat-card-content>
        <div class="mesure d-flex flex-row align-items-center justify-content-between mb-2">
            <div>
                <div class="required">Type de zone</div>
                <!--  Le nombre de mesures est différent selon le type de mesurage-->
                <ng-container *ngIf="!propertiesMesurage.isMesurageUtile">
                    <mat-button-toggle-group appearance="legacy" [(value)]="zoneToEdit.type" [disabled]="editNameMode">
                        <mat-button-toggle
                            [value]="propertiesMesurage.listeMesures[0]"
                            [ngClass]="nonRepute ? '' : 'green-toggle'"
                            [disabled]="nonRepute"
                        >
                            {{ listeMesuresLabels[0] | uppercase }}
                        </mat-button-toggle>
                        <mat-button-toggle [value]="propertiesMesurage.listeMesures[1]" class="red-toggle">
                            {{ listeMesuresLabels[1] | uppercase }}
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </ng-container>

                <ng-container *ngIf="propertiesMesurage.isMesurageUtile">
                    <mat-button-toggle-group appearance="legacy" [(value)]="zoneToEdit.type" [disabled]="editNameMode">
                        <mat-button-toggle
                            [value]="propertiesMesurage.listeMesures[0]"
                            [ngClass]="nonRepute ? '' : 'blue-toggle'"
                            [disabled]="nonRepute"
                        >
                            {{ listeMesuresLabels[0] | uppercase }}
                        </mat-button-toggle>
                        <mat-button-toggle
                            [value]="propertiesMesurage.listeMesures[1]"
                            [ngClass]="nonRepute ? '' : 'purple-toggle'"
                            [disabled]="nonRepute"
                        >
                            {{ listeMesuresLabels[1] | uppercase }}
                        </mat-button-toggle>
                        <mat-button-toggle [value]="propertiesMesurage.listeMesures[2]" class="red-toggle">
                            {{ listeMesuresLabels[2] | uppercase }}
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </ng-container>
            </div>
            <div>
                <div class="required">Surface de la zone</div>
                <div class="row align-items-center">
                    <mat-form-field appearance="outline">
                        <input
                            matInput
                            type="number"
                            [placeholder]="MESURE_NON_COMMUNIQUE"
                            [disabled]="editNameMode"
                            [(ngModel)]="zoneToEdit.surface"
                            (click)="openNumKeyboard()"
                            readonly
                        />
                    </mat-form-field>

                    <div class="button-group-mesure">
                        <button
                            mat-icon-button
                            color="primary"
                            [matTooltip]="tooltipsTools[0]"
                            [disabled]="editNameMode"
                            (click)="setZero()"
                        >
                            <mat-icon>exposure_zero</mat-icon>
                        </button>
                        <button
                            mat-icon-button
                            color="primary"
                            [matTooltip]="tooltipsTools[1]"
                            [disabled]="editNameMode"
                            (click)="openCalculator()"
                        >
                            <mat-icon>calculate</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <div class="required">Ajouter ou déduire</div>
                <mat-button-toggle-group appearance="legacy" [(value)]="zoneToEdit.mode" [disabled]="editNameMode">
                    <mat-button-toggle value="add" class="green-toggle" [matTooltip]="tooltipsZone[0]">
                        <mat-icon>add</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle value="remove" class="red-toggle" [matTooltip]="tooltipsZone[1]">
                        <mat-icon>remove</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
        <div class="d-flex flex-row justify-content-end">
            <button mat-button *ngIf="editZone" color="warn" [disabled]="editNameMode" (click)="deleteZone()">
                {{ 'Supprimer la zone' | uppercase }}
            </button>
            <button
                mat-raised-button
                color="accent"
                [disabled]="!canValidate() || editNameMode"
                (click)="validateZone()"
            >
                {{ editZone ? 'Modifier' : ('Créer' | uppercase) }}
            </button>
        </div>
    </mat-card-content>
</mat-card>
