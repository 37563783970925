<form (ngSubmit)="onClickValider()" [formGroup]="formPlan" *ngIf="formPlan">
    <h1 mat-dialog-title class="primary d-flex justify-content-between">
        <span *ngIf="isCreation">Créer un plan de localisation: {{ this.formPlan.get('nomPlan').value }}</span>
        <span *ngIf="!isCreation">Modifier un plan de localisation: {{ this.formPlan.get('nomPlan').value }}</span>
        <button mat-button type="button" (click)="onClickCancel()">
            <lib-icon>close</lib-icon>
        </button>
    </h1>

    <div mat-dialog-content>
        <div class="row">
            <div class="col-6">
                <div class="row">
                    <!-- Nom du plan de localisation -->
                    <div class="d-flex p-2 align-items-center" style="flex: 1">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary">
                            Nom du plan de localisation<span class="required"></span>
                        </div>
                        <div class="col-xl-8 pr-2">
                            <mat-form-field class="w-100">
                                <input matInput type="text" [readonly]="readonlyMode" formControlName="nomPlan" />
                                <mat-error *ngIf="formPlan.get('nomPlan').hasError('erreurNomPlanExistant')">
                                    Un autre plan de localisation possède déjà le même nom.
                                </mat-error>
                                <app-mat-error [control]="formPlan.get('nomPlan')"></app-mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <!-- Selection du bien-->
                    <div
                        class="d-flex p-2 align-items-center"
                        *ngIf="formPlan.controls['perimetre'].value"
                        style="flex: 1"
                    >
                        <div class="col-xl-4 mat-subheading-2 m-0 primary">
                            Sélectionner un bien<span class="required"></span>
                        </div>
                        <div class="col-xl-8 pr-2">
                            <app-custom-select
                                class="w-100"
                                [readonly]="readonlyMode"
                                formControlName="bien"
                                [selectControl]="formPlan.get('bien')"
                                [matFormFieldClass]="'w-100'"
                                [choices]="listeBiens"
                                [addline]="false"
                                [pathValueDisplay]="['nom']"
                                (selectedValueChange)="onChangeBien($event)"
                            >
                            </app-custom-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="d-flex p-2 align-items-center" style="flex: 1">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary">Périmètre d'investigation</div>
                        <div class="col-xl-8 pr-2">
                            <app-custom-select
                                class="w-100"
                                [readonly]="readonlyMode || listePerimetresInvestigation.length == 1"
                                formControlName="perimetre"
                                [selectControl]="formPlan.get('perimetre')"
                                [matFormFieldClass]="'w-100'"
                                [choices]="listePerimetresInvestigation"
                                [addline]="false"
                                [pathValueDisplay]="['nom']"
                            >
                            </app-custom-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="d-flex p-2 align-items-center" *ngIf="formPlan.controls['bien'].value" style="flex: 1">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary">Sélectionner un niveau</div>
                        <div class="col-xl-8 pr-2">
                            <app-custom-select
                                class="w-100"
                                [readonly]="readonlyMode"
                                formControlName="niveau"
                                [selectControl]="formPlan.get('niveau')"
                                [matFormFieldClass]="'w-100'"
                                [choices]="formPlan.controls['bien'].value.description"
                                [addline]="false"
                                [pathValueDisplay]="['nom']"
                                [emptyOptionActivate]="true"
                            >
                            </app-custom-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12" *ngIf="listeBackgroundBien.length > 0">
            <div class="d-flex p-2 align-items-center">
                <div class="col-xl-4 mat-subheading-2 primary">
                    Sélectionner une image de fond<span class="required"></span>
                </div>
            </div>
        </div>
        <div class="col-3 d-flex justify-content-center" *ngFor="let background of listeBackgroundBien">
            <img
                [ngClass]="
                    background.fileId === this.formPlan.controls['background'].value?.fileId
                        ? 'background-selected'
                        : ''
                "
                [src]="background.fileContent"
                alt="image"
                class="img-fluid img-size"
                (click)="onToggleBackground(background)"
            />
        </div>

        <div mat-dialog-actions class="justify-content-end">
            <button mat-button type="button" color="primary" (click)="onClickCancel()">
                <lib-icon>close</lib-icon>
                <span>Annuler</span>
            </button>
            <button mat-raised-button color="accent" type="submit" [disabled]="formPlan.invalid">
                <lib-icon>check</lib-icon>
                <span>Valider</span>
            </button>
        </div>
    </div>
</form>
