import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/commons-lib';
import { URL_GESTION_USERS_EDIT } from 'src/app/shared/constants/url.constants';

@Component({
    selector: 'app-gestion-operateurs',
    templateUrl: './gestion-operateurs.component.html',
    styleUrls: ['./gestion-operateurs.component.scss'],
})
export class GestionOperateursComponent extends BaseComponent implements OnInit {
    constructor(private readonly router: Router) {
        super();
    }

    ngOnInit(): void {}

    creerUser() {
        this.router.navigate([URL_GESTION_USERS_EDIT]);
    }
}
