import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mesure',
})
export class MesurePipe implements PipeTransform {
    transform(value: string): any {
        const regex = new RegExp('([^,]+)');
        if (value[0] === '-') {
            return `<b><span style="color:orange">${value}</span></b>`;
        } else {
            return value.replace(regex, (match) => `<b>${match.replace(/\*/, '')}</b>`);
        }
    }
}
