'use strict';

import { fh_add, fh_dot, fh_mul, fh_normalize, fh_sub } from '@acenv/fh-3d-viewer';

//***********************************************************************************
//***********************************************************************************
//**** Utilities
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//**** min  max
//***********************************************************************************
export function cn_min(a, b) {
    return (a < b) ? a : b;
}

export function cn_max(a, b) {
    return (a > b) ? a : b;
}

//***********************************************************************************
//**** 2D geometry
//***********************************************************************************
export function cn_clone(p) {
    return [p[0], p[1]];
}

export function cn_clone_3d(p, z) {
    return [p[0], p[1], z];
}

export function cn_copy(src, dest) {
    dest[0] = src[0];
    dest[1] = src[1];
}

export function cn_add(p0, p1) {
    return [p0[0] + p1[0], p0[1] + p1[1]];
}

export function cn_sub(p0, p1) {
    return [p0[0] - p1[0], p0[1] - p1[1]];
}

export function cn_middle(p0, p1) {
    return [0.5 * (p0[0] + p1[0]), 0.5 * (p0[1] + p1[1])];
}

export function cn_size(p) {
    return Math.sqrt(p[0] * p[0] + p[1] * p[1]);
}

export function cn_normalize(p) {
    var n = cn_size(p);
    var invn = (n > 0.0001) ? 1 / n : n;
    p[0] *= invn;
    p[1] *= invn;
    return n;
}

export function cn_dot(p0, p1) {
    return p0[0] * p1[0] + p0[1] * p1[1];
}

export function cn_cross(p0, p1) {
    return p0[0] * p1[1] - p0[1] * p1[0];
}

export function cn_dist(p0, p1) {
    return cn_size(cn_sub(p0, p1));
}

export function cn_mul(p, x) {
    return [p[0] * x, p[1] * x];
}

export function cn_normal(p) {
    return [-p[1], p[0]];
}

export function cn_flip(v) {
    v[0] = -v[0];
    v[1] = -v[1];
}

export function cn_polar(p) {
    var sz = cn_size(p);
    if (sz < 0.0001) return [0, 0];
    var alpha = Math.acos(p[0] / sz);
    if (p[1] < 0) alpha = -alpha;
    return [sz, alpha];
}

export function cn_clamp_angle(a, amin = 0) {
    var aa = a;
    while (aa > amin + 360) aa -= 360;
    while (aa < amin) aa += 360;
    return aa;
}

export function cn_cart(p) {
    return [p[0] * Math.cos(p[1]), p[0] * Math.sin(p[1])];
}

export function cn_intersect_line(p0, d0, p1, d1) {
    var n1 = cn_normal(d1);
    var sub = cn_dot(d0, n1);
    if (Math.abs(sub) < 0.001) return null;
    var lambda = cn_dot(n1, cn_sub(p1, p0)) / sub;
    return cn_add(p0, cn_mul(d0, lambda));
}

export function cnx_intersect_line(p0, d0, p1, d1) {
    var nn = cnx_cross(d0, d1);
    var n1 = cnx_cross(d1, nn);
    cnx_normalize(n1);
    var sub = cnx_dot(d0, n1);
    if (Math.abs(sub) < 0.001) return null;
    var lambda = cnx_dot(n1, cnx_sub(p1, p0)) / sub;
    return cnx_add(p0, cnx_mul(d0, lambda));
}

export function cn_intersect_segments(s00, s01, s10, s11) {
    var p0 = s00;
    var d0 = cn_sub(s01, s00);
    var l0 = cn_normalize(d0);
    if (l0 < 0.001) return null;
    var n0 = cn_normal(d0);

    var p1 = s10;
    var d1 = cn_sub(s11, s10);
    var l1 = cn_normalize(d1);
    if (l1 < 0.001) return null;
    var n1 = cn_normal(d1);

    var den = cn_dot(n0, d1);
    if (Math.abs(den) < 0.001) return null;
    var lambda = cn_dot(n0, cn_sub(p0, p1)) / den;
    if (lambda < 0 || lambda > l1)
        return null;

    lambda = cn_dot(n1, cn_sub(p0, p1)) / den;
    if (lambda < 0 || lambda > l0)
        return null;

    return cn_add(p0, cn_mul(d0, lambda));
}

export function cn_project_line(p, v0, d) {
    return cn_add(v0, cn_mul(d, cn_dot(cn_sub(p, v0), d)));
}

export function cn_project_line_3d(p, v0, d) {
    return fh_add(v0, fh_mul(d, fh_dot(fh_sub(p, v0), d)));
}

export function cn_project_segment(p, v0, v1) {
    var d = cn_sub(v1, v0);
    cn_normalize(d);
    return cn_project_line(p, v0, d);
}

export function cn_project_segment_3d(p, v0, v1) {
    var d = fh_sub(v1, v0);
    fh_normalize(d);
    return cn_project_line_3d(p, v0, d);
}

export function cn_point_on_segment(p, v0, v1, tolerance) {
    var d = cn_sub(v1, v0);
    var lx = cn_normalize(d);
    if (lx <= tolerance)
        return ((cn_dist(p, v0) <= tolerance) || (cn_dist(p, v1) <= tolerance))

    var pp = cn_sub(p, v0);
    var x = cn_dot(pp, d);
    if (x < -tolerance) return false;
    if (x > lx + tolerance) return false;
    if (Math.abs(cn_dot(pp, cn_normal(d))) > tolerance) return false;
    return true;
}

/**
 * Computes the intersection position between 2 lines. position is the distance from p0, on first line.
 * @param {number[]} p0
 * @param {number[]} d0
 * @param {number[]} p1
 * @param {number[]} d1
 * @returns {number}
 */
export function cn_compute_intersection_position(p0, d0, p1, d1) {
    const n1 = cn_normal(d1);
    const den = cn_dot(d0, n1);
    if (den == 0) return 0;
    return (cn_dot(n1, cn_sub(p1, p0)) / den);
}

/**
 * Returns true if vertices is a rectangle (at 1mm precision)
 * @param {any} vertices
 * @returns {boolean}
 */
export function cn_is_rectangle(vertices) {
    if (vertices.length != 4) return false;
    var dx = cn_sub(vertices[1], vertices[0]);
    var w = cn_normalize(dx);
    if (w == 0) return false;
    if (Math.abs(cn_dot(dx, cn_sub(vertices[2], vertices[0])) - w) > 0.001) return false;
    if (Math.abs(cn_dot(dx, cn_sub(vertices[3], vertices[0]))) > 0.001) return false;
    const dy = cn_normal(dx);
    const h = cn_dot(dy, cn_sub(vertices[2], vertices[0]));
    if (Math.abs(cn_dot(dy, cn_sub(vertices[3], vertices[0])) - h) > 0.001) return false;
    return true;
}

/**
 * Returns true if vertices is a clockwise contour
 * @param {any} vertices
 * @returns {boolean}
 */
export function cn_is_clockwise(vertices) {
    const sz = vertices.length;
    if (sz < 3) return false;
    var area = 0;
    for (var i = 0; i < sz; i++) {
        const v0 = vertices[i];
        const v1 = vertices[(i + 1) % sz];
        area += (v0[0] + v1[0]) * (v1[1] - v0[1]);
    }
    return area > 0;
}

/**
 * Returns true if vertices is a rectangle (at 1mm precision)
 * @param {any} vertices
 * @returns {boolean}
 */
export function cn_is_3d_rectangle(vertices) {
    if (vertices.length != 4) return false;
    var dx = fh_sub(vertices[1], vertices[0]);
    var w = fh_normalize(dx);
    if (w == 0) return false;
    if (Math.abs(fh_dot(dx, fh_sub(vertices[2], vertices[0])) - w) > 0.001) return false;
    if (Math.abs(fh_dot(dx, fh_sub(vertices[3], vertices[0]))) > 0.001) return false;
    const dy = fh_sub(vertices[3], vertices[0]);
    const h = fh_normalize(dy);
    if (Math.abs(fh_dot(dy, fh_sub(vertices[2], vertices[0])) - h) > 0.001) return false;
    if (Math.abs(fh_dot(dy, fh_sub(vertices[1], vertices[0]))) > 0.001) return false;
    return true;
}

/**
 * Returns triangle area using Heron formula
 * @param {number[]} p0
 * @param {number[]} p1
 * @param {number[]} p2
 * @returns {number}
 */
export function cn_triangle_area(p0, p1, p2) {
    const a = cnx_dist(p0, p1);
    const b = cnx_dist(p1, p2);
    const c = cnx_dist(p2, p0);
    const s = (a + b + c) / 2;
    const area_2 = s * (s - a) * (s - b) * (s - c);
    if (area_2 <= 0) return 0;
    return Math.sqrt(area_2);
}

/**
 * returns a new array, made of vertices that are not distant from more than precision.
 * Vertices are copied as is, so they may be 2d or 3d. distance only considers the first two dimensions.
 * @param {number[][]} vertices
 * @param {number} precision
 * @returns {number[][]}
 */
export function cn_filter_contour(vertices, precision) {
    if (vertices.length == 0) return [];
    var ctr = [];
    var previous_point = vertices[vertices.length - 1];
    for (var k = 0; k < vertices.length; k++) {
        if (cn_dist(previous_point, vertices[k]) > precision) {
            previous_point = vertices[k];
            ctr.push(previous_point);
        }
    }
    return ctr;
}

export function cn_md5(...args) {
    var s = '';
    for (var i in args) {
        var arg = args[i];
        if (typeof (arg) == 'object') {
            if (arg == null)
                s += 'null';
            else if (typeof (arg.ID) == 'string')
                s += arg.ID;
        } else if (typeof (arg) == 'number' || typeof (arg) == 'string' || typeof (arg) == 'boolean')
            s += arg;
    }

    function L(k, d) {
        return (k << d) | (k >>> (32 - d))
    };

    function K(G, k) {
        var I, d, F, H, x;
        F = (G & 2147483648);
        H = (k & 2147483648);
        I = (G & 1073741824);
        d = (k & 1073741824);
        x = (G & 1073741823) + (k & 1073741823);
        if (I & d) {
            return (x ^ 2147483648 ^ F ^ H)
        }
        if (I | d) {
            if (x & 1073741824) {
                return (x ^ 3221225472 ^ F ^ H)
            } else {
                return (x ^ 1073741824 ^ F ^ H)
            }
        } else {
            return (x ^ F ^ H)
        }
    }

    function r(d, F, k) {
        return (d & F) | ((~d) & k)
    }

    function q(d, F, k) {
        return (d & k) | (F & (~k))
    }

    function p(d, F, k) {
        return (d ^ F ^ k)
    }

    function n(d, F, k) {
        return (F ^ (d | (~k)))
    }

    function u(G, F, aa, Z, k, H, I) {
        G = K(G, K(K(r(F, aa, Z), k), I));
        return K(L(G, H), F)
    }

    function f(G, F, aa, Z, k, H, I) {
        G = K(G, K(K(q(F, aa, Z), k), I));
        return K(L(G, H), F)
    }

    function D(G, F, aa, Z, k, H, I) {
        G = K(G, K(K(p(F, aa, Z), k), I));
        return K(L(G, H), F)
    }

    function t(G, F, aa, Z, k, H, I) {
        G = K(G, K(K(n(F, aa, Z), k), I));
        return K(L(G, H), F)
    }

    function e(G) {
        var Z;
        var F = G.length;
        var x = F + 8;
        var k = (x - (x % 64)) / 64;
        var I = (k + 1) * 16;
        var aa = Array(I - 1);
        var d = 0;
        var H = 0;
        while (H < F) {
            Z = (H - (H % 4)) / 4;
            d = (H % 4) * 8;
            aa[Z] = (aa[Z] | (G.charCodeAt(H) << d));
            H++
        }
        Z = (H - (H % 4)) / 4;
        d = (H % 4) * 8;
        aa[Z] = aa[Z] | (128 << d);
        aa[I - 2] = F << 3;
        aa[I - 1] = F >>> 29;
        return aa
    }

    function B(x) {
        var k = '', F = '', G, d;
        for (d = 0; d <= 3; d++) {
            G = (x >>> (d * 8)) & 255;
            F = '0' + G.toString(16);
            k = k + F.substr(F.length - 2, 2)
        }
        return k
    }

    function J(k) {
        k = k.replace(/rn/g, 'n');
        var d = '';
        for (var F = 0; F < k.length; F++) {
            var x = k.charCodeAt(F);
            if (x < 128) {
                d += String.fromCharCode(x)
            } else {
                if ((x > 127) && (x < 2048)) {
                    d += String.fromCharCode((x >> 6) | 192);
                    d += String.fromCharCode((x & 63) | 128)
                } else {
                    d += String.fromCharCode((x >> 12) | 224);
                    d += String.fromCharCode(((x >> 6) & 63) | 128);
                    d += String.fromCharCode((x & 63) | 128)
                }
            }
        }
        return d
    }

    var C = Array();
    var P, h, E, v, g, Y, X, W, V;
    var S = 7, Q = 12, N = 17, M = 22;
    var A = 5, z = 9, y = 14, w = 20;
    var o = 4, m = 11, l = 16, j = 23;
    var U = 6, T = 10, R = 15, O = 21;
    s = J(s);
    C = e(s);
    Y = 1732584193;
    X = 4023233417;
    W = 2562383102;
    V = 271733878;
    for (P = 0; P < C.length; P += 16) {
        h = Y;
        E = X;
        v = W;
        g = V;
        Y = u(Y, X, W, V, C[P + 0], S, 3614090360);
        V = u(V, Y, X, W, C[P + 1], Q, 3905402710);
        W = u(W, V, Y, X, C[P + 2], N, 606105819);
        X = u(X, W, V, Y, C[P + 3], M, 3250441966);
        Y = u(Y, X, W, V, C[P + 4], S, 4118548399);
        V = u(V, Y, X, W, C[P + 5], Q, 1200080426);
        W = u(W, V, Y, X, C[P + 6], N, 2821735955);
        X = u(X, W, V, Y, C[P + 7], M, 4249261313);
        Y = u(Y, X, W, V, C[P + 8], S, 1770035416);
        V = u(V, Y, X, W, C[P + 9], Q, 2336552879);
        W = u(W, V, Y, X, C[P + 10], N, 4294925233);
        X = u(X, W, V, Y, C[P + 11], M, 2304563134);
        Y = u(Y, X, W, V, C[P + 12], S, 1804603682);
        V = u(V, Y, X, W, C[P + 13], Q, 4254626195);
        W = u(W, V, Y, X, C[P + 14], N, 2792965006);
        X = u(X, W, V, Y, C[P + 15], M, 1236535329);
        Y = f(Y, X, W, V, C[P + 1], A, 4129170786);
        V = f(V, Y, X, W, C[P + 6], z, 3225465664);
        W = f(W, V, Y, X, C[P + 11], y, 643717713);
        X = f(X, W, V, Y, C[P + 0], w, 3921069994);
        Y = f(Y, X, W, V, C[P + 5], A, 3593408605);
        V = f(V, Y, X, W, C[P + 10], z, 38016083);
        W = f(W, V, Y, X, C[P + 15], y, 3634488961);
        X = f(X, W, V, Y, C[P + 4], w, 3889429448);
        Y = f(Y, X, W, V, C[P + 9], A, 568446438);
        V = f(V, Y, X, W, C[P + 14], z, 3275163606);
        W = f(W, V, Y, X, C[P + 3], y, 4107603335);
        X = f(X, W, V, Y, C[P + 8], w, 1163531501);
        Y = f(Y, X, W, V, C[P + 13], A, 2850285829);
        V = f(V, Y, X, W, C[P + 2], z, 4243563512);
        W = f(W, V, Y, X, C[P + 7], y, 1735328473);
        X = f(X, W, V, Y, C[P + 12], w, 2368359562);
        Y = D(Y, X, W, V, C[P + 5], o, 4294588738);
        V = D(V, Y, X, W, C[P + 8], m, 2272392833);
        W = D(W, V, Y, X, C[P + 11], l, 1839030562);
        X = D(X, W, V, Y, C[P + 14], j, 4259657740);
        Y = D(Y, X, W, V, C[P + 1], o, 2763975236);
        V = D(V, Y, X, W, C[P + 4], m, 1272893353);
        W = D(W, V, Y, X, C[P + 7], l, 4139469664);
        X = D(X, W, V, Y, C[P + 10], j, 3200236656);
        Y = D(Y, X, W, V, C[P + 13], o, 681279174);
        V = D(V, Y, X, W, C[P + 0], m, 3936430074);
        W = D(W, V, Y, X, C[P + 3], l, 3572445317);
        X = D(X, W, V, Y, C[P + 6], j, 76029189);
        Y = D(Y, X, W, V, C[P + 9], o, 3654602809);
        V = D(V, Y, X, W, C[P + 12], m, 3873151461);
        W = D(W, V, Y, X, C[P + 15], l, 530742520);
        X = D(X, W, V, Y, C[P + 2], j, 3299628645);
        Y = t(Y, X, W, V, C[P + 0], U, 4096336452);
        V = t(V, Y, X, W, C[P + 7], T, 1126891415);
        W = t(W, V, Y, X, C[P + 14], R, 2878612391);
        X = t(X, W, V, Y, C[P + 5], O, 4237533241);
        Y = t(Y, X, W, V, C[P + 12], U, 1700485571);
        V = t(V, Y, X, W, C[P + 3], T, 2399980690);
        W = t(W, V, Y, X, C[P + 10], R, 4293915773);
        X = t(X, W, V, Y, C[P + 1], O, 2240044497);
        Y = t(Y, X, W, V, C[P + 8], U, 1873313359);
        V = t(V, Y, X, W, C[P + 15], T, 4264355552)
            ; W = t(W, V, Y, X, C[P + 6], R, 2734768916);
        X = t(X, W, V, Y, C[P + 13], O, 1309151649);
        Y = t(Y, X, W, V, C[P + 4], U, 4149444226);
        V = t(V, Y, X, W, C[P + 11], T, 3174756917);
        W = t(W, V, Y, X, C[P + 2], R, 718787259);
        X = t(X, W, V, Y, C[P + 9], O, 3951481745);
        Y = K(Y, h);
        X = K(X, E);
        W = K(W, v);
        V = K(V, g)
    }
    ;
    var i = B(Y) + B(X) + B(W) + B(V);
    return i.toLowerCase()
};

export function cn_uuid(s) {
    return 'cn' + cn_md5(s + Math.random() + Date.now());
}

/**
 * Rotate a point around a given center witha  given angle
 * @param {number[]} point
 * @param {number[]} center
 * @param {number} angle
 */
export function cn_rotate(point, center, angle) {
    var d = cn_sub(point, center);
    var polar = cn_polar(d);
    polar[1] += angle;
    return cn_add(center, cn_cart(polar));
}

//***********************************************************************************
//**** random generator
//***********************************************************************************

var random_func = null;

export function cn_seed(x) {
    let a = (x * 2 ** 32) >>> 0;
    random_func = () => {
        let t = a += 0x6D2B79F5;
        t = Math.imul(t ^ t >>> 15, t | 1);
        t ^= t + Math.imul(t ^ t >>> 7, t | 61);
        return ((t ^ t >>> 14) >>> 0) / 4294967296;
    }
}

export function cn_random() {
    if (!random_func) cn_seed(0.654654);
    return random_func();
};

//***********************************************************************************
//**** Forced 3D geometry
//***********************************************************************************
export function cnx_clone(p, z = 0) {
    return [p[0], p[1], (p.length > 2 && z == 0) ? p[2] : z];
}

export function cnx_copy(src, dest) {
    dest[0] = src[0];
    dest[1] = src[1];
    if (dest.length < 2) dest.push(0);
    dest[2] = (src.length > 2) ? src[2] : 0;
}

export function cnx_add(p0, p1) {
    var z = 0;
    if (p0.length > 2) z += p0[2];
    if (p1.length > 2) z += p1[2];
    return [p0[0] + p1[0], p0[1] + p1[1], z];
}

export function cnx_sub(p0, p1) {
    var z = 0;
    if (p0.length > 2) z += p0[2];
    if (p1.length > 2) z -= p1[2];
    return [p0[0] - p1[0], p0[1] - p1[1], z];
}

export function cnx_size(p) {
    var z = 0;
    if (p.length > 2) z += p[2];
    return Math.sqrt(p[0] * p[0] + p[1] * p[1] + z * z);
}

export function cnx_normalize(p) {
    var n = cnx_size(p);
    var invn = (n > 0.0001) ? 1 / n : n;
    p[0] *= invn;
    p[1] *= invn;
    if (p.length < 2) p.push(0);
    p[2] *= invn;
    return n;
}

export function cnx_dot(p0, p1) {
    var z = 1;
    if (p0.length > 2) z *= p0[2];
    if (p1.length > 2) z *= p1[2];
    return p0[0] * p1[0] + p0[1] * p1[1] + z;
}

export function cnx_dist(p0, p1) {
    return cnx_size(cnx_sub(p0, p1));
}

export function cnx_mul(p, x) {
    var z = (p.length > 2) ? p[2] : 0;
    return [p[0] * x, p[1] * x, z * x];
}

export function cnx_cross(p0, p1) {
    const v0 = cnx_clone(p0);
    const v1 = cnx_clone(p1);
    return [p0[1] * p1[2] - p0[2] * p1[1], p0[2] * p1[0] - p0[0] * p1[2], p0[0] * p1[1] - p0[1] * p1[0]];
}

export function cnx_compute_normal(vertices) {
    var mad_length = 0;
    var best_normal = [0, 0, 0];
    const cl = vertices.length;
    for (var i = 0; i < cl; i++) {
        const d0 = cnx_sub(vertices[(i + 1) % cl], vertices[i]);
        const d1 = cnx_sub(vertices[(i + 2) % cl], vertices[(i + 1) % cl]);
        const normal = cnx_cross(d0, d1);
        const l = cnx_normalize(normal);
        if (l > mad_length) {
            mad_length = l;
            best_normal = normal;
        }
    }
    return best_normal;
}

/**
 * Build 3D axis around a given normal
 * @param {number[]} normal
 * @param {number[]} dx
 * @param {number[]} dy
 */
export function cnx_build_axis(normal, dx, dy) {
    cnx_normalize(normal);
    if (Math.abs(normal[2]) > 0.99)
        cnx_copy(cnx_cross([0, 1, 0], normal), dx);
    else
        cnx_copy(cnx_cross([0, 0, 1], normal), dx);
    cnx_normalize(dx);
    cnx_copy(cnx_cross(normal, dx), dy);
}

//***********************************************************************************
//**** Colors
//***********************************************************************************

export function cn_color_hexa_to_rgb(hexa) {
    while (hexa.length < 7) hexa += '0';
    return [parseInt(hexa.substring(1, 3), 16) / 255, parseInt(hexa.substring(3, 5), 16) / 255, parseInt(hexa.substring(5, 7), 16) / 255];
}

//***********************************************************************************
//**** Trigonometry
//***********************************************************************************

export function cn_cos(x) {
    return Math.cos(x * Math.PI / 180);
}

export function cn_sin(x) {
    return Math.sin(x * Math.PI / 180);
}

export function cn_acos(x) {
    if (x >= 1) return 0;
    if (x <= -1) return Math.PI;
    return Math.acos(x);
}

export function cn_asin(x) {
    if (x >= 1) return Math.PI / 2;
    if (x <= -1) return -Math.PI / 2;
    return Math.asin(x);
}

/**
 * Returns azimut of a vector, in ]-180, 180]. 0 = North, 90 = East, 180 = south, -90 = west
 * @param {Array<number>} v
 * @param {number} north
 * @returns {number}
 */
export function cn_azimut(v, north = 0) {
    const polv = cn_polar(v);
    if (polv[0] < 0.01) return 0;
    var pol = 90 - polv[1] * 180 / Math.PI - north;
    while (pol > 180) pol -= 360;
    while (pol <= -180) pol += 360;
    return pol;
}

/**
 * Returns a srting describint azimuut of a vector
 * @param {Array<number>} v
 * @param {number} north
 * @returns {string}
 */
export function cn_azimut_label(v, north = 0) {
    const a = cn_azimut(v, north);
    if (a < -180 + 22.5) return 'Sud';
    if (a < -180 + 22.5 + 45) return 'Sud-Ouest';
    if (a < -180 + 22.5 + 90) return 'Ouest';
    if (a < -180 + 22.5 + 135) return 'Nord-Ouest';
    if (a < 22.5) return 'Nord';
    if (a < 22.5 + 45) return 'Nord-Est';
    if (a < 22.5 + 90) return 'Est';
    if (a < 22.5 + 135) return 'Sud-Est';
    return 'Sud';
}

//***********************************************************************************
//**** 2D box
//***********************************************************************************
export class cn_box {
    constructor(point_list = null) {
        this.posmin = null;
        this.size = [0, 0];
        if (point_list) {
            for (var i = 0; i < point_list.length; i++)
                this.enlarge_point(point_list[i]);
        }
    }

    enlarge_point(p) {
        if (this.posmin == null) {
            this.posmin = [p[0], p[1]];
            return;
        }

        if (p[0] < this.posmin[0]) {
            this.size[0] = this.posmin[0] + this.size[0] - p[0];
            this.posmin[0] = p[0];
        }
        if (p[1] < this.posmin[1]) {
            this.size[1] = this.posmin[1] + this.size[1] - p[1];
            this.posmin[1] = p[1];
        }
        if (p[0] > this.posmin[0] + this.size[0]) this.size[0] = p[0] - this.posmin[0];
        if (p[1] > this.posmin[1] + this.size[1]) this.size[1] = p[1] - this.posmin[1];
    }

    enlarge_box(b) {
        if (b.posmin == null) return;
        this.enlarge_point(b.posmin);
        this.enlarge_point(cn_add(b.posmin, b.size));
    }

    enlarge_distance(x) {
        if (this.posmin == null) return;
        this.posmin[0] -= x;
        this.posmin[1] -= x;
        this.size[0] += x * 2;
        this.size[1] += x * 2;
    }

    contains_point(p) {
        if (this.posmin == null) return false;
        if (p[0] < this.posmin[0]) return false;
        if (p[1] < this.posmin[1]) return false;
        if (p[0] > this.posmin[0] + this.size[0]) return false;
        if (p[1] > this.posmin[1] + this.size[1]) return false;
        return true;
    }

    clear() {
        this.posmin = null;
        this.size = [0, 0];
    }

    intersects(box) {
        if (box.posmin[0] > this.posmin[0] + this.size[0]) return false;
        if (box.posmin[1] > this.posmin[1] + this.size[1]) return false;
        if (box.posmin[0] + box.size[0] < this.posmin[0]) return false;
        if (box.posmin[1] + box.size[1] < this.posmin[1]) return false;
        return true;
    }
}

//***********************************************************************************
//**** Date
//***********************************************************************************

export var DATATION = 0;

//***********************************************************************************
//**** Images
//***********************************************************************************

var UTF8 = {
    from_ansi: function (s) {
        for (var c, i = -1, l = (s = s.split('')).length, o = String.fromCharCode; ++i < l;
            s[i] = (c = s[i].charCodeAt(0)) >= 127 ? o(0xc0 | (c >>> 6)) + o(0x80 | (c & 0x3f)) : s[i]
        );
        return s.join('');
    },
    to_ansi: function (s) {
        for (var a, b, i = -1, l = (s = s.split('')).length, o = String.fromCharCode, c = 'charCodeAt'; ++i < l;
            ((a = s[i][c](0)) & 0x80) &&
            (s[i] = (a & 0xfc) == 0xc0 && ((b = s[i + 1][c](0)) & 0xc0) == 0x80 ?
                o(((a & 0x03) << 6) + (b & 0x3f)) : o(128), s[++i] = '')
        );
        return s.join('');
    }
};

//***********************************************************************************
//**** Simplifies a contour (2D or 3D)
//***********************************************************************************

export function cn_simplify_contour(contour, precision = 0.01) {
    if (contour.length == 0) return contour;
    const dim = contour[0].length;
    while (true) {
        var change = false;
        for (var i = 0; i < contour.length; i++) {
            var p0 = contour[i];
            var p1 = contour[(i + 1) % contour.length];
            var p2 = contour[(i + 2) % contour.length];
            var dir = [0, 0, 0];
            var nrm = 0;
            for (var k = 0; k < dim; k++) {
                dir[k] = p2[k] - p1[k];
                nrm += dir[k] * dir[k];
            }
            nrm = Math.sqrt(nrm);
            if (nrm < precision) {
                if (contour.length > 0) contour.splice((i + 1) % contour.length, 1);
                change = true;
                break;
            }
            var dotprod = 0;
            for (var k = 0; k < dim; k++) {
                dir[k] /= nrm;
                dotprod += dir[k] * (p1[k] - p0[k]);
            }
            var dist = 0;
            for (var k = 0; k < dim; k++) {
                const x = p0[k] + dotprod * dir[k] - p1[k];
                dist += x * x;
            }
            if (dist < precision * precision) {
                contour.splice((i + 1) % contour.length, 1);
                change = true;
                break;
            }
        }
        if (!change) break;
    }
    return contour;
}

/**
 * Returns true if point is inside the contour described by vertices
 * @param {number[]} point
 * @param {Array<number[]>} vertices
 * @param {number} precision
 * @returns {boolean}
 */
export function cn_contour_contains(point, vertices, precision = 0.001) {
    const l = vertices.length;
    for (var i = 0; i < l; i++) {
        if (cn_point_on_segment(point, vertices[i], vertices[(i + 1) % l], precision)) return true;
    }

    var nleft = 0;
    // @ts-ignore
    var nright = 0;
    var sense = 0;
    var o = 0;
    for (; o < l; o++) {
        var v0 = vertices[o];
        if (v0[1] > point[1] || v0[1] < point[1]) break;
    }

    for (var i = 0; i < l; i++) {
        var v0 = vertices[(i + o) % l];
        var v1 = vertices[(i + o + 1) % l];
        if (v0[1] > point[1] && v1[1] > point[1]) continue;
        if (v0[1] < point[1] && v1[1] < point[1]) continue;
        if ((v0[1] < point[1] && v1[1] > point[1]) || (v0[1] > point[1] && v1[1] < point[1])) {
            var coef = (point[1] - v0[1]) / (v1[1] - v0[1]);
            var x = v0[0] * (1 - coef) + v1[0] * coef;

            // on border :
            if (Math.abs(x - point[0]) <= precision) return true;
            if (x >= point[0]) nright++;
            else {
                nleft++;
            }
            continue;
        }
        if (v0[1] == point[1] && v1[1] == point[1]) continue;
        var x = 0;
        var new_sense = 0;
        if (v1[1] == point[1]) {
            x = v1[0];
            new_sense = (v0[1] > point[1]) ? 1 : -1;
        } else {
            x = v0[0];
            new_sense = (v1[1] < point[1]) ? 1 : -1;
        }
        if (sense == new_sense) {
            sense = 0;
            continue;
        }
        if (sense == -new_sense)
            sense = 0;
        else
            sense = new_sense;

        if (Math.abs(x - point[0]) <= precision) return true;
        if (x >= point[0]) nright++;
        else {
            nleft++;
        }
    }

    if (nleft & 1) return true;
    return false;
}
