<div class="pb-5">
    <div class="block-title p-2 pl-5 my-3 d-inline-block" *ngIf="data">{{ data.title }}</div>
    <div class="block-content px-5 ml-2 mb-4">
        <ng-container *ngFor="let espace of listeEspaces">
            <div class="block-subtitle-2 mt-4">{{ espace.nom }}</div>
            <ng-container *ngIf="espace.listePerimetres.length === 0">
                <td colspan="3" class="text-center p-4">Aucun périmètre défini pour cet espace.</td>
            </ng-container>
            <ng-container *ngFor="let perimetre of espace.listePerimetres; let index = index">
                <!----------------- Nom du périmètre ---------------->
                <div class="block-title p-2 d-inline-block mt-2" *ngIf="data">
                    {{ perimetre.nom }}
                </div>

                <!--------------- Détail des besoins -------------->
                <table>
                    <tr>
                        <th class="reference">Référence</th>
                        <th>Type</th>
                        <th class="amiante">Analyse amiante à réaliser</th>
                    </tr>

                    <tr *ngIf="perimetre.listeBesoins.length === 0">
                        <td colspan="3" class="text-center p-4">Aucun besoin pour ce périmètre</td>
                    </tr>

                    <tr *ngFor="let besoin of perimetre.listeBesoins">
                        <td>
                            <b>{{ besoin?.general?.reference }}</b>
                        </td>
                        <td>
                            <b
                                >{{ besoin?.details?.typePrelevement }}
                                <span *ngIf="besoin?.details?.typeCarottage">
                                    - {{ besoin?.details?.typeCarottage | titlecase }}</span
                                >
                                <span *ngIf="besoin?.details?.profondeurCarottage">
                                    - {{ besoin?.details?.profondeurCarottage }}cm</span
                                ></b
                            >
                        </td>
                        <td>
                            <b>{{ besoin?.donneesTechniques?.analyseAmiante ? 'Oui' : 'Non' }}</b>
                        </td>
                    </tr>
                </table>
            </ng-container>
        </ng-container>
    </div>
</div>
