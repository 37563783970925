import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/commons-lib';

@Component({
    selector: 'app-legende-perimetre',
    templateUrl: './legende-perimetre.component.html',
    styleUrls: ['./legende-perimetre.component.scss'],
})
export class LegendePerimetreComponent extends BaseComponent {
    @Input() formGroup: FormGroup;
    @Output() changeColor: EventEmitter<void> = new EventEmitter<void>();
    @Input() readonly: boolean;

    constructor() {
        super();
    }
}
