<div class="fullscreen-content">
    <div class="header">
        <div class="title">Guide de référence</div>
        <button mat-button class="close-button" (click)="onClickBtnClose()">
            <lib-icon>close</lib-icon>
        </button>
    </div>

    <div class="d-flex flex-column">
        <div class="d-flex p-2">
            <img
                [src]="currentImage"
                [@guideAnimation]="index == 0 ? 'RECTO' : 'VERSO'"
                style="max-height: 85vh; width: 100%"
            />
        </div>
        <div class="d-flex justify-content-end">
            <div class="d-flex">
                <button mat-button type="button" color="primary" class="px-3" (click)="onClickBtnSuivante()">
                    <span>
                        {{ index == 0 ? 'SUIVANT' : 'PRÉCÉDANT' }}
                    </span>
                </button>
            </div>
            <div class="d-flex ml-2">
                <button type="button" mat-button color="danger" class="px-3" (click)="onClickBtnClose()">
                    <span> FERMER </span>
                </button>
            </div>
        </div>
    </div>
</div>
