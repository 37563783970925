<div class="container-fluid p-0 w-100">
    <div class="entete-tableau-admin" *ngIf="canEdit">
        <div class="d-flex">
            <div class="onglet-tab-admin mt-2 selected p-2">Échantillons</div>
        </div>
        <div class="buttons d-flex justify-content-end"></div>
    </div>
    <mat-card>
        <div class="input-with-prefix" *ngIf="canEdit">
            <mat-form-field appearance="outline" class="w-50 form-custom">
                <input matInput type="text" (keyup)="onKeyupSearch($event)" placeholder="Recherche libre" />
                <mat-icon matPrefix>search</mat-icon>
            </mat-form-field>
        </div>
        <div class="d-flex flex-column">
            <table mat-table [dataSource]="dataSource" matSort aria-describedby="Tableau des échantillons">
                <ng-container matColumnDef="reference">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="reference"># Échantillon</th>
                    <td mat-cell *matCellDef="let row">
                        <b>{{ row?.reference }}</b>
                    </td>
                </ng-container>
                <ng-container *ngIf="!canEdit || canAddResult" matColumnDef="laboratoire">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="laboratoire">Laboratoire</th>
                    <td mat-cell *matCellDef="let row">
                        {{ row?.laboratoire ? row?.laboratoire : laboratoireName }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="typesAnalyse">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="typesAnalyse">Type d'analyse</th>
                    <td mat-cell *matCellDef="let row">
                        <ul class="m-0">
                            <li *ngFor="let typeAnalyse of row?.typesAnalyse; trackBy: trackByTypeAnalyse">
                                - {{ typeAnalyse | typeAnalyse }}
                            </li>
                        </ul>
                    </td>
                </ng-container>

                <ng-container *ngIf="!canEdit || canAddResult" matColumnDef="dateEnvoiAnalyse">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="date-analyse">Date d'envoi</th>
                    <td mat-cell *matCellDef="let row">
                        {{ row?.dateEnvoiAnalyse | date: DATE_FORMAT_FRANCAIS_SANS_HEURE }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="description">Description</th>
                    <td mat-cell *matCellDef="let row">
                        <b>{{ row?.typePrelevement | typePrelevement }}</b> <br />
                        <ng-container *ngIf="row?.hauteur"><b>Profondeur :</b> {{ row?.hauteur }}cm</ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="resultats">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Résultats</th>
                    <td mat-cell *matCellDef="let row">
                        <ul class="m-0">
                            <li *ngFor="let resultat of row?.resultatsAnalyse; trackBy: trackByResultat">
                                - <b>{{ resultat?.typeAnalyse | typeAnalyseSimple }} :</b>
                                <ng-container *ngIf="resultat?.typeAnalyse === 'HAP'">
                                    <span [style.color]="resultat?.groupResultat?.color"> {{ resultat?.number }}</span>
                                    {{ resultat?.unit }}
                                </ng-container>
                                <ng-container *ngIf="resultat?.typeAnalyse === 'ANALYSE_AMIANTE'">
                                    {{ resultat?.description }}
                                </ng-container>
                            </li>
                        </ul>
                    </td>
                </ng-container>

                <ng-container matColumnDef="dateAnalyse">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="date-analyse">Date d'analyse</th>
                    <td mat-cell *matCellDef="let row">
                        {{ row?.dateAnalyse | date: DATE_FORMAT_FRANCAIS_SANS_HEURE }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="validation">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="validation">
                        <mat-checkbox
                            class="square-check-box no-label"
                            [disabled]="readonlyMode"
                            [checked]="allChecked"
                            (change)="toggleCheckAll($event.checked)"
                            >Validation
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox
                            *ngIf="row?.resultatsAnalyse?.length > 0"
                            [disabled]="readonlyMode"
                            [checked]="row?.validated"
                            class="square-check-box no-label"
                            (change)="toggleCheck($event, row)"
                        ></mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="actions"></th>
                    <td mat-cell *matCellDef="let row">
                        <button
                            *ngIf="canAddResult"
                            mat-icon-button
                            [disabled]="row?.validated"
                            (click)="onClickOpenModalEditResultat(row)"
                        >
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button *ngIf="!canEdit" mat-icon-button class="primary" (click)="onClickNavigateToEspace(row)">
                            <mat-icon>visibility</mat-icon>
                        </button>
                        <app-notes-buttons
                            *ngIf="!canEdit"
                            [from]="row"
                            [diagnostic]="diagnostic"
                            (commentChanged)="commentChanged(row)"
                        ></app-notes-buttons>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
        </div>
    </mat-card>
</div>
