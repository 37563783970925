import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { extractPage, PageRequest, PaginatedSelection, toHttpParams } from '../shared/paging/page';
import { environment } from '../../environments/environment';
import { Synthese } from '../model/synthese';
import { map, switchMap } from 'rxjs/operators';
import { Document } from '../model/document.model';
import { Rapport } from '../model/rapport.model';
import { Observable } from 'rxjs';
import { TypePrestation } from '../model/type-prestation.model';
import { Contact } from '../model/contact.model';

export class SimplePrestationDiagnostic {
    id: string;
    numero: string;
    prestation: string;
    prestationId: string;
    prestataires: { email: string; firstName: string; lastName: string };
    client: Contact;
    bien: string;
    bienId: string;
    adresse: string;
    dateHeureFinEffective: string;
    statut: string;
}

@Injectable()
export class ExportSyntheseService {
    constructor(private http: HttpClient) {}

    getPrestationsSynthese(
        idsCommandes: string[],
        search: string,
        typesPrestation: TypePrestation[],
        startDate: string,
        endDate: string,
        pageRequest: PageRequest<SimplePrestationDiagnostic>
    ) {
        return extractPage(
            this.http.get<SimplePrestationDiagnostic[]>(`${environment.apiUrl}/export-synthese/commande/prestations`, {
                observe: 'response',
                params: {
                    ...toHttpParams(pageRequest),
                    idsCommandes: idsCommandes,
                    search: search,
                    typesPrestation: typesPrestation,
                    startDate,
                    endDate,
                },
            })
        );
    }

    creerSynthese(
        idsCommandes: string[],
        idCommandeMere: string,
        idAgence: string,
        selection: PaginatedSelection,
        template: Document,
        filtres: {
            search: string;
            typesPrestation: TypePrestation[];
            startDate: string;
            endDate: string;
        }
    ) {
        return this.http.post<Synthese[]>(`${environment.apiUrl}/export-synthese/creer`, {
            idsCommandes: idsCommandes,
            idCommandeMere: idCommandeMere,
            idAgence: idAgence,
            selectionMode: selection.mode,
            selectedItems: selection.selectedItems,
            all: selection.all.checked,
            idTemplate: template.id,
            filtres,
        });
    }

    generate(idSynthese: string) {
        return this.http.post<Synthese>(`${environment.apiUrl}/export-synthese/synthese/${idSynthese}/generate`, {});
    }

    listTemplates(): Observable<Document[]> {
        return this.http.get<Document[]>(`${environment.apiUrl}/export-synthese/templates`);
    }

    findOneRapportSynthese(idRapport: string) {
        return this.http.get<Rapport>(`${environment.apiUrl}/export-synthese/rapport/${idRapport}`);
    }

    findAllSyntheses(search: string, pageRequest: PageRequest<Synthese>) {
        return extractPage(
            this.http.get<Synthese[]>(`${environment.apiUrl}/export-synthese/synthese`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), search },
            })
        );
    }
}
