<div class="d-flex flex-grow-0 bg-warning flex-column w-100 p-2 align-items-center" *ngIf="volumeToMerge">
    <div class="d-flex justify-content-between align-items-center w-100 py-1">
        <div>
            <p class="font-weight-bold m-0">Pièce à fusionner (cible)</p>
            <p class="font-weight-light m-0">{{ niveauVolumeToMerge.nom }} - {{ volumeToMerge.nom }}</p>
        </div>
        <button mat-icon-button matTooltip="Annuler la fusion" (click)="handleCancelMerge()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div *ngIf="currentVolume && !currentVolume.spaceId && currentVolume.id != volumeToMerge.id">
        <button mat-raised-button color="accent" class="my-auto" (click)="handleMergeVolume()">
            <span>Fusionner</span>
        </button>
    </div>
</div>
