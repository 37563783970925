import { Adresse } from '../model/adresse.model';

/**
 * Classe utilitaire pour les adresses
 */
export class AddressUtils {
    /**
     * Renvoie la chaine permettant d'executer le filtre dans le tableau d'Angular Material.
     * @param adresse
     */
    static getAddressStringForFilterValue(adresse: Adresse): string {
        if (adresse) {
            return []
                .concat(adresse.ville ? adresse.ville : '')
                .concat(adresse.codePostal ? adresse.codePostal : '')
                .concat(adresse.voie ? adresse.voie : '')
                .concat(adresse.complementAdresse1 ? adresse.complementAdresse1 : '')
                .concat(adresse.complementAdresse2 ? adresse.complementAdresse2 : '')
                .filter((e) => e !== '')
                .join('; ');
        }
        return '';
    }

    /**
     * Renvoie l'adresse formatée
     * @param adresse
     */
    static getFormatedAddress(adresse: Adresse): string {
        if (adresse) {
            return []
                .concat(adresse.voie ? adresse.voie : '')
                .concat(adresse.complementAdresse1 ? adresse.complementAdresse1 : '')
                .concat(adresse.complementAdresse2 ? adresse.complementAdresse2 : '')
                .filter((e) => e !== '')
                .join('\n');
        }
        return '';
    }
}
