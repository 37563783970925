import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { DiagnosticService } from '../../../../../services/diagnostic.service';
import { Diagnostic } from '../../../../../model/diagnostic.model';
import { TypePrestationEnum } from 'src/app/model/type-prestation.model';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-documents',
    templateUrl: './document.component.html',
    styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent extends BaseComponent implements OnInit {
    TypePrestationEnum = TypePrestationEnum;

    showFilter = false;
    currentDiagnostic: Diagnostic;

    constructor(private diagnosticService: DiagnosticService) {
        super();
    }

    ngOnInit(): void {
        this.diagnosticService
            .getCurrentDiagnostic()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((diag) => {
                console.log(diag);
                this.currentDiagnostic = diag;
            });
    }
}
