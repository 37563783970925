import { Commentaire } from './commentaire.model';

export class Surface {
    superficie?: number = 0;
    longueurMax?: number = 0;
    inferieurSeuilMin?: boolean = true;
    listeIdVolume?: string[] = [];
    listeVolumes?: any[];
    listeCommentaire?: Commentaire[] = [new Commentaire()];
    nombrePu?: number = 0;
    longueurInterface?: number = 0;
    hauteurInterface?: number = 0;
    longueurSurfaceTravail?: number = 0;
    largeurSurfaceTravail?: number = 0;
    activeSurfaceInterface?: boolean = false;
    typeSurface?: string = '';
    aditionnalSurfaveValue?: number = 0;
    surfaceCalcul?: number = 0;
    commentairesId: string[] = [];

    public get surfaceInterface() {
        return this.longueurInterface * this.hauteurInterface;
    }
}
