<mat-chip-list>
    <ng-container *ngFor="let typePrestation of typesPrestation; let index = index; trackBy: trackByTypePrestation">
        <mat-chip
            class="chips-disabled-no-style"
            [disabled]="true"
            *ngIf="nbPrestationsDisplayed == -1 || index < nbPrestationsDisplayed"
        >
            {{ typePrestation | typePrestation }}
        </mat-chip>
    </ng-container>

    <mat-chip
        class="chips-disabled-no-style"
        [disabled]="true"
        *ngIf="nbPrestationsDisplayed != -1 && nbPrestationsDisplayed < typesPrestation.length"
    >
        + {{ typesPrestation.length - nbPrestationsDisplayed }}
    </mat-chip>
</mat-chip-list>
