<div class="toolbar">
    <mat-toolbar class="border-bottom-1 primary">
        <button mat-button class="px-0" (click)="back()">
            <lib-icon>arrow_back</lib-icon>
        </button>
        <div>
            <lib-icon [class]="'icon-before-text'">settings</lib-icon>
            <span>Gestion des pièces</span>
        </div>
        <div class="flex-grow-1"></div>
        <div>
            <span>User</span>
        </div>
    </mat-toolbar>
</div>
<div class="flex-grow-1 main-background overflow-auto">
    <div class="p-4">
        <div class="entete-tableau-admin">
            <div class="d-flex">
                <div class="onglet-tab-admin mt-2 selected">Type de pièce</div>
            </div>
            <div class="buttons d-flex justify-content-end">
                <button color="accent" mat-raised-button class="bt-valid" (click)="creerVolume()">
                    <lib-icon>add</lib-icon>
                    {{ 'Créer une pièce' | uppercase }}
                </button>
            </div>
        </div>
        <app-tableau-piece></app-tableau-piece>
    </div>
</div>
