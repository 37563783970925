import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FiltrePrelevementMetop } from 'src/app/modules/diagnostics/polluant/model/polluant-prelevement.model';

export interface DialogData {
    index: number;
    filtre: FiltrePrelevementMetop;
}

@Component({
    selector: 'app-filtre-modal',
    templateUrl: './filtre-modal.component.html',
    styleUrls: ['./filtre-modal.component.scss'],
})
export class FiltreModalComponent implements OnInit {
    formDoc: FormGroup;
    index: number;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, private formBuilder: FormBuilder) {
        this.index = data.index;
        this.formDoc = this.formBuilder.group({
            numeroFiltre: [data.filtre.numeroFiltre, Validators.required],
            numeroPompe: [data.filtre.numeroPompe, Validators.required],
            debitInitial: [data.filtre.debitInitial, Validators.required],
            testEtancheite: [data.filtre.testEtancheite, Validators.required],
        });
    }

    ngOnInit(): void {}
}
