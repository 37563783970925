<div [formGroup]="group" class="formgroup-container">
    <div [formGroup]="group.get('surface')" *ngIf="hapSurfaceConf?.displayed">
        <h4 class="my-2">Surfaces</h4>
        <div>
            <div *ngIf="hapSurfaceConf?.controls?.showPerimetres?.displayed">
                <mat-checkbox formControlName="showPerimetres">Périmètres</mat-checkbox>
            </div>
            <div *ngIf="hapSurfaceConf?.controls?.showZones?.displayed">
                <mat-checkbox formControlName="showZones">Zones</mat-checkbox>
            </div>
            <div *ngIf="hapSurfaceConf?.controls?.showPrelevements?.displayed">
                <mat-checkbox formControlName="showPrelevements">Besoins</mat-checkbox>
            </div>
        </div>
    </div>
    <div [formGroup]="group.get('zone')" *ngIf="hapZoneConf?.displayed">
        <h4 class="my-2">Zones</h4>
        <div>
            <div *ngIf="hapZoneConf?.controls?.showZoneInferieurEgal50?.displayed">
                <mat-checkbox formControlName="showZoneInferieurEgal50">
                    <div class="text-wrap">Seuil inférieur ou égal à 50mg/kg</div>
                </mat-checkbox>
            </div>
            <div *ngIf="hapZoneConf?.controls?.showZoneEntre51Et500?.displayed">
                <mat-checkbox formControlName="showZoneEntre51Et500">
                    <div class="text-wrap">Seuil situé entre 51 et 500mg/kg</div>
                </mat-checkbox>
            </div>
            <div *ngIf="hapZoneConf?.controls?.showZoneEntre501Et1000?.displayed">
                <mat-checkbox formControlName="showZoneEntre501Et1000">
                    <div class="text-wrap">Seuil situé entre 501 et 1000mg/kg</div>
                </mat-checkbox>
            </div>
            <div *ngIf="hapZoneConf?.controls?.showZoneSuperieur1000?.displayed">
                <mat-checkbox formControlName="showZoneSuperieur1000">
                    <div class="text-wrap">Seuil supérieur à 1000mg/kg</div>
                </mat-checkbox>
            </div>
            <div *ngIf="hapZoneConf?.controls?.showZoneNonRealises?.displayed">
                <mat-checkbox formControlName="showZoneNonRealises">
                    <div class="text-wrap">Non réalisés</div>
                </mat-checkbox>
            </div>
        </div>
    </div>
    <div [formGroup]="group.get('prelevement')" *ngIf="hapPrelevementConf?.displayed">
        <h4 class="my-2">Prélèvements</h4>
        <div>
            <div *ngIf="hapPrelevementConf?.controls?.showPrelevementInferieurEgal50?.displayed">
                <mat-checkbox formControlName="showPrelevementInferieurEgal50">
                    <div class="text-wrap">Seuil inférieur ou égal à 50mg/kg</div>
                </mat-checkbox>
            </div>
            <div *ngIf="hapPrelevementConf?.controls?.showPrelevementEntre51Et500?.displayed">
                <mat-checkbox formControlName="showPrelevementEntre51Et500">
                    <div class="text-wrap">Seuil situé entre 51 et 500mg/kg</div>
                </mat-checkbox>
            </div>
            <div *ngIf="hapPrelevementConf?.controls?.showPrelevementEntre501Et1000?.displayed">
                <mat-checkbox formControlName="showPrelevementEntre501Et1000">
                    <div class="text-wrap">Seuil situé entre 501 et 1000mg/kg</div>
                </mat-checkbox>
            </div>
            <div *ngIf="hapPrelevementConf?.controls?.showPrelevementSuperieur1000?.displayed">
                <mat-checkbox formControlName="showPrelevementSuperieur1000">
                    <div class="text-wrap">Seuil supérieur à 1000mg/kg</div>
                </mat-checkbox>
            </div>
            <div *ngIf="hapPrelevementConf?.controls?.showPrelevementNonRealises?.displayed">
                <mat-checkbox formControlName="showPrelevementNonRealises">
                    <div class="text-wrap">Non réalisés</div>
                </mat-checkbox>
            </div>
        </div>
    </div>
</div>
