'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

import { cn_element } from './cn_element';
import { cn_storey } from './cn_storey';
import { cn_space, EXTERIOR_SPACE_FACING_LIST, INTERIOR_CEILING_FACING_LIST, INTERIOR_FLOOR_FACING_LIST } from './cn_space';
import { CN_CURRENT_DATE, cn_transaction_manager } from '../utils/cn_transaction_manager';
import { cn_background_map } from './cn_background_map';
import { cn_wall_type } from './cn_wall_type';
import { cn_balcony_type } from './cn_balcony_type';
import { cn_fence_type } from './cn_fence_type';
import { cn_opening_type } from './cn_opening_type';
import { cn_beam_column_type } from './cn_beam_column_type';
import { cn_slab_type } from './cn_slab_type';
import { cn_roof_opening_type } from './cn_roof_opening_type';
import { cn_fence_opening_type } from './cn_fence_opening_type';
import { cn_pipe_type } from './cn_pipe_type';
import { cn_object } from './cn_object';
import { cn_object_instance } from './cn_object_instance';
import { cn_scene } from './cn_scene';
import { cn_slab } from './cn_slab';
import { cn_contour } from './cn_contour';
import { fh_clone, fh_polygon, fh_solid } from '@acenv/fh-3d-viewer';
import { cn_md5 } from '../utils/cn_utilities';
import { cn_element_type } from './cn_element_type';
import { cn_area_context, CN_AREA_REGULATIONS } from './cn_area_context';
import { cn_zpso } from './cn_zpso';
import { cn_unserialize_element_type } from '../utils/cn_unserialize_element_type';
import { cn_zone } from './cn_zone';
import { zone_colors } from '../utils/cn_zone_color';
import { cn_config_export } from './cn_config_export';
import { cn_topography } from './cn_topography';
import { cn_layer } from './cn_layer';
import { cn_facing } from './cn_facing';
import { EXTERIOR_WALL_FACING_LIST, INTERIOR_WALL_FACING_LIST } from './cn_wall';
import { cn_building_data } from './cn_building_data';
import { cn_data_extension } from '../extension/cn_data_extension';
import { extension_instance } from '../extension/cn_extension';
import { cn_facing_trimming } from './cn_facing_trimming';
import { cn_facade } from './cn_facade';
import { cn_marker } from './cn_marker';
import { logger } from '../utils/cn_logger';

export const CNMAP_VERSION = process.env.CNMAP_VERSION;

//***********************************************************************************
//***********************************************************************************
/**
 * @class cn_building
 * Root class for all data
 */
export class cn_building extends cn_element {
    //*******************************************************
    /**
     * Constructor
     */
    constructor() {
        super();
        this.build_version = CNMAP_VERSION;
        this.removable = false;
        this.name = 'Mon projet';

        //*** Do we consider facings above ground ? */
        this.facings_above_ground = true;
        this.facings_above_ground_height = 0;

        this.building_data = new cn_building_data();
        this.storeys = [new cn_storey(this)];
        this.storey_0_index = 0;
        /**
         * @type {cn_background_map[]}
         */
        this.maps = [];
        this.element_types = [];
        this.facing_types = [];

        //*** exterior is managed by a specific storey */
        this.exterior = cn_building._init_exterior(this);

        //*** Exterior ground */
        this.topography = new cn_topography(this);

        this.objects = [];

        this.space_equipments_suggestions = [];

        this.transaction_manager = new cn_transaction_manager();

        this.views = [];
        this.clipping_planes = [];

        this.clipboard = [];

        this.tmp_element_types = [];
        this.tmp_objects = [];
        this.save_version = undefined;

        this.zpsos = [];
        this.layers = [];
        this.compass_orientation = 0;
        /**
         * @type {{[property: string]: cn_zone[]}}
         */
        this.zones = {};

        /**
         * A callback called by asynchronous processes during unserialization.
         * @type {function}
         */
        this.unserialize_callback = null;
        this.unserialize_callback_count = 0;

        this.area_contexts = [];

        this.config_exports = [];

        //*** Wall facing trimmings are global */
        this.facing_trimmings = [];

        this.build_default_element_types();

        //*** Facade data */
        this._facades = [];
        this._facades_date = 0;

        //*** The products types reference data, from BIMWIQ PIM <- DATA */
        /**
         * @type {{id: string, name: string, codeBim: string, parentsIds: string[], ifcType: string}[]}
         */
        this._reference_data_products = [];

        //*** The spaces types reference data, from BIMWIQ DATA */
        /**
         * @type {{codeBim: string, name: string, codeGbXml: string}[]}
         */
        this._reference_data_spaces = [];

        //*** The zones types reference data, from BIMWIQ DATA */
        /**
         * @type {{codeBim: string, name: string, codeGbXml: string}[]}
         */
        this._reference_data_zones = [];

        //*** The building types reference data, from BIMWIQ DATA */
        /**
         * @type {{codeBim: string, name: string, codeGbXml: string}[]}
         */
        this._reference_data_buildings = [];

        extension_instance.data = new cn_data_extension();

        this.fixed_corrupted_data = false;

        this._update_roofs_date = -1;
    }

    static _init_exterior(building) {
        const exterior = new cn_storey(building);
        exterior.storey_index = 999;
        exterior.exterior = true;
        return exterior;
    }

    //*******************************************************
    /**
     * serialize
     * @returns {object} json object
     */
    serialize() {
        var json = {};
        json.build_version = this.build_version;
        json.save_version = CNMAP_VERSION;
        json.ID = this.ID;
        json.name = this.name;
        json.facings_above_ground = this.facings_above_ground;
        json.facings_above_ground_height = this.facings_above_ground_height;
        json.building_data = this.building_data.serialize();
        json.storey_0_index = this.storey_0_index;
        json.storeys = [];

        for (var i = 0; i < this.storeys.length; i++)
            this.storeys[i]['s_index'] = i;
        // @ts-ignore
        for (var i in this.storeys)
            json.storeys.push(this.storeys[i].serialize());

        json.exterior = this.exterior.serialize();
        json.exterior.storey_index = 999;
        json.exterior.exterior = true;

        if (this.topography)
            json.topography = this.topography.serialize();

        json.maps = [];
        // @ts-ignore
        for (var i in this.maps)
            json.maps.push(this.maps[i].serialize());

        json.element_types = [];
        // @ts-ignore
        for (var i in this.element_types)
            json.element_types.push(this.element_types[i].serialize());

        json.objects = [];
        // @ts-ignore
        for (var i in this.objects)
            json.objects.push(this.objects[i].serialize());

        json.space_equipments_suggestions = this.space_equipments_suggestions;

        json.views = this.views;
        json.clipping_planes = this.clipping_planes;

        if (this.zpsos.length) {
            json.zpsos = this.zpsos.map(zpso => zpso.serialize(this));
        }

        if (this.layers.length) {
            json.layers = this.layers.map(layer => layer.serialize(this));
        }

        if (this.zones) {
            json.zones = {};
            for (let zoning_type in this.zones) {
                json.zones[zoning_type] = this.zones[zoning_type].map(zone => zone.serialize());
            }
        }

        json.compass_orientation = this.compass_orientation;

        if (this.area_contexts.length) {
            json.area_contexts = this.area_contexts.map(area_context => area_context.serialize());
        }

        json.facing_trimmings = this.facing_trimmings.map(facing_trimming => facing_trimming.serialize());

        json.config_exports = this.config_exports.map(config => config.serialize());

        json.facing_types = this.facing_types.map(type => type.serialize());
        json.extension_data = extension_instance.data.serialize();

        return json;
    }

    //*******************************************************
    /**
     * Unserialize
     * The asynchronous callback will be called at the end of each asynchronous function triggered
     * by the unserialization.
     * @param {any} json : json object
     * @param {cn_building} building
     * @param {function} asynchronous_callback
     */
    static unserialize(json, building = null, asynchronous_callback = null) {
        if (typeof (json.storeys) != 'object')
            throw 'Error reading building : \'storeys\' not found';

        var building = new cn_building();
        building.unserialize_callback = asynchronous_callback;
        building.unserialize_callback_count = 0;

        building._update_roofs_date = -2;

        if (typeof (json.build_version) == 'string')
            building.build_version = json.build_version;
        else
            building.build_version = '-';

        if (typeof (json.save_version) == 'string')
            building.save_version = json.save_version;
        else
            building.save_version = '-';

        if (typeof (json.name) == 'string')
            building.name = json.name;

        if (json.building_data && typeof (json.building_data) == 'object')
            building.building_data = cn_building_data.unserialize(json.building_data);

        if (typeof (json.storey_0_index) == 'number')
            building.storey_0_index = json.storey_0_index;

        if (typeof (json.ID) == 'string') building.ID = json.ID;

        if (typeof (json.facings_above_ground) == 'boolean')
            building.facings_above_ground = json.facings_above_ground;

        if (typeof (json.facings_above_ground_height) == 'number')
            building.facings_above_ground_height = json.facings_above_ground_height;

        if (typeof (json.maps) == 'object') {
            for (var i in json.maps) {
                var map = cn_background_map.unserialize(json.maps[i], building);
                if (map) building.maps.push(map);
            }
        }

        building.element_types = [];
        if (typeof (json.element_types) == 'object') {
            building.element_types = json.element_types.map(et => cn_unserialize_element_type.unserialize(et))
        }

        if (json.facing_types) {
            building.facing_types = json.facing_types.map(type => cn_facing.unserialize(type));
        } else {
            building.facing_types = cn_building.generate_generic_facing_types(building);
        }

        building.build_default_element_types();

        building.objects = [];
        if (typeof (json.objects) == 'object') {
            for (var i in json.objects) {
                var obj = cn_object.unserialize(json.objects[i]);
                if (obj) building.objects.push(obj);
            }
        }

        building.space_equipments_suggestions = json.space_equipments_suggestions || [];

        building.storeys = [];
        for (var i in json.storeys)
            building.storeys.push(new cn_storey(building));
        for (var i in building.storeys)
            building.storeys[i].unserialize(json.storeys[i]);

        if (json.exterior && typeof (json.exterior) == 'object') {
            building.exterior.unserialize(json.exterior);
            /** for exterior */
            building.exterior.unserialize_markers(json.exterior);
        } else {
            building.exterior = cn_building._init_exterior(building);
        }

        if (json.topography && typeof (json.topography) == 'object' && building.topography)
            building.topography = cn_topography.unserialize(json.topography, building);
        else if (building.topography)
            building.topography = new cn_topography(building);

        for (var i in building.storeys) {
            if (building.storeys[i].clone_of && building.storeys[i].clone_of != building.storeys[i])
                building.storeys[i].scene = building.storeys[i].clone_of.scene;
        }
        building.rename_storeys();

        if (typeof (json.views) != 'undefined')
            building.views = json.views;

        if (typeof (json.clipping_planes) != 'undefined')
            building.clipping_planes = json.clipping_planes;

        building.zpsos = [];
        if (json.zpsos) {
            building.zpsos = json.zpsos.map(zpso => cn_zpso.unserialize(zpso, building.storeys));
        }

        building.layers = [];
        if (json.layers) {
            building.layers = json.layers.map(layer => cn_layer.unserialize(layer, building.storeys));
        }

        building.zones = {};
        if (json.zones) {
            for (let zoning_type in json.zones) {
                building.zones[zoning_type] = json.zones[zoning_type].map(zone => cn_zone.unserialize(zone));
            }
        }

        building.area_contexts = [];
        if (json.area_contexts && json.area_contexts.length) {
            json.area_contexts.forEach(ac => cn_area_context.unserialize(ac, building));
        }

        building.facing_trimmings = [];
        for (var i in json.facing_trimmings)
            cn_facing_trimming.unserialize(json.facing_trimmings[i], building);

        cn_building._populate_area_context(building);

        if (json.compass_orientation) {
            building.compass_orientation = json.compass_orientation;
        }

        //*** unserialize markers */
        for (var i in building.storeys)
            building.storeys[i].unserialize_markers(json.storeys[i]);


        if (json.config_exports) {
            building.config_exports = json.config_exports.map(config => cn_config_export.unserialize(config));
        } else {
            building.config_exports = [];
        }

        if (json.extension_data) {
            extension_instance.data = cn_data_extension.unserialize(json.extension_data);
        } else {
            extension_instance.data = cn_data_extension.unserialize('{}');
        }

        cn_building._fix_corrupted_data(building);

        building._update_roofs_date = -1;
        building.update_roofs();

        return building;
    }

    /**
     * Fix corrupted data in building, caused by past bugs
     */
    static _fix_corrupted_data(building) {

        let has_to_fix = false;

        // Remove images
        building.config_exports.forEach(config => {
            config.pages.forEach(page => {
                if (page.preview) {
                    delete page.preview;
                    has_to_fix = true;
                }
            })
        });

        extension_instance.features.forEach(f => {
            has_to_fix = has_to_fix || f.feature.fix_corrupted_data(building);
        });

        if (has_to_fix) {
            building.fixed_corrupted_data = true;
        }
    }

    static _populate_area_context(building) {
        CN_AREA_REGULATIONS.forEach(area_context => {
            if (!building.area_contexts.find(ac => ac.label === area_context.label)) {
                const new_ac = new cn_area_context(area_context.code, building);
                building.storeys.reduce((agg, st) => agg.concat(st.scene.spaces), []).forEach(space => {
                    if (!space.outside)
                        new_ac.add_space(space);
                });
                building.area_contexts.push(new_ac);
            }
        });
    }

    //*******************************************************
    /**
     * Return true if building has some geometry (i.e. walls)
     * @returns [boolean}
     */
    has_geometry() {
        for (var i in this.storeys) {
            if (this.storeys[i].scene.walls.length > 0) return true;
        }
        return false;
    }

    //*******************************************************
    /**
     * Sets transaction context (context for undo redo. Can be anything)
     * @param {any} ctx
     */
    set_transaction_context(ctx) {
        this.transaction_manager.set_transaction_context(ctx);
    }

    //*******************************************************
    /**
     * Gets transaction context (context for undo redo. Can be anything)
     * @returns {any}
     */
    get_transaction_context() {
        return this.transaction_manager.get_transaction_context();
    }

    //*******************************************************
    /**
     * is building empty ?
     * @returns {boolean}
     */
    is_empty() {
        if (this.storeys.length != 1) return false;
        return (this.storeys[0].scene.walls.length == 0);
    }

    //*******************************************************
    //**** get element types
    //*******************************************************

    // NB : must use @ts-ignore to force return type in JSDoc in following methods

    /**
     * returns wall types
     * @returns {cn_wall_type[]}
     */
    get_wall_types() {
        // @ts-ignore
        return this.get_element_types(cn_wall_type);
    }

    /**
     * returns balcony types
     * @returns {cn_balcony_type[]}
     */
    get_balcony_types() {
        // @ts-ignore
        return this.get_element_types(cn_balcony_type);
    }

    /**
     * returns fence types
     * @returns {cn_fence_type[]}
     */
    get_fence_types() {
        // @ts-ignore
        return this.get_element_types(cn_fence_type);
    }

    /**
     * returns opening types
     * @returns {cn_opening_type[]}
     */
    get_opening_types() {
        // @ts-ignore
        return this.get_element_types(cn_opening_type);
    }

    /**
     * returns window types
     * @returns {cn_opening_type[]}
     */
    get_window_types() {
        // @ts-ignore
        return this.get_element_types(cn_opening_type, 'window');
    }

    /**
     * returns door types
     * @returns {cn_opening_type[]}
     */
    get_door_types() {
        // @ts-ignore
        return this.get_element_types(cn_opening_type, 'door');
    }

    /**
     * returns beam and column types
     * @returns {cn_beam_column_type[]}
     */
    get_beam_types() {
        // @ts-ignore
        return this.get_element_types(cn_beam_column_type);
    }

    /**
     * returns slab types
     * @returns {cn_slab_type[]}
     */
    get_slab_types() {
        // @ts-ignore
        return this.get_element_types(cn_slab_type);
    }

    /**
     * returns floor types
     * @returns {cn_slab_type[]}
     */
    get_floor_types() {
        // @ts-ignore
        return this.get_element_types(cn_slab_type, 'floor');
    }

    /**
     * returns roof types
     * @returns {cn_slab_type[]}
     */
    get_roof_types() {
        // @ts-ignore
        return this.get_element_types(cn_slab_type, 'roof');
    }

    /**
     * returns roof opening types
     * @returns {cn_element_type[]}
     */
    get_roof_opening_types() {
        return this.get_element_types(cn_roof_opening_type);
    }

    /**
     * returns fence opening types
     * @returns {cn_fence_opening_type[]}
     */
    get_fence_opening_types() {
        // @ts-ignore
        return this.get_element_types(cn_fence_opening_type);
    }

    /**
     * returns roof opening types
     * @returns {cn_pipe_type[]}
     */
    get_pipe_types() {
        // @ts-ignore
        return this.get_element_types(cn_pipe_type);
    }

    /**
     * returns facing types
     * @returns {cn_facing[]}
     */
    get_facing_types() {
        return this.facing_types;
    }

    /**
     * Returns element types of a given constructor and category
     * @param {any} constructor
     * @param {string} category
     * @returns {cn_element_type[]}
     */
    get_element_types(constructor, category = null) {
        var lst = [];
        for (var i in this.element_types) {
            if (this.element_types[i].constructor != constructor) continue;
            if (category && this.element_types[i].category != category) continue;
            lst.push(this.element_types[i]);
        }
        return lst;
    }

    //*******************************************************
    /**
     * Add element types
     * @param {cn_element_type} et
     */
    add_element_type(et) {
        if (this.get_element_type(et.ID)) return false;
        this.transaction_manager.push_transaction('Ajout du type ' + et.get_label());
        this.transaction_manager.push_item_set(this, 'element_types');
        this.element_types.unshift(et);
        return true;
    }

    //*******************************************************
    /**
     * Add default element type
     * Same that add_element_type but without transaction_manager_trace
     * @param {cn_element_type} et
     */
    _add_default_element_type(et) {
        if (this.get_element_type(et.ID)) return false;
        this.element_types.push(et);
        return true;
    }

    //*******************************************************
    /**
     * Removes an element type, if not used in the building.
     * @param {cn_element_type} element_type
     * @returns {boolean} true if removal could be done.
     */
    remove_element_type(element_type) {
        if (this.get_element_type_count(element_type) != 0) return false;
        var index = this.element_types.indexOf(element_type);
        if (index < 0) return false;
        this.transaction_manager.push_transaction('Suppression du type ' + element_type.get_label());
        this.transaction_manager.push_item_set(this, 'element_types');
        this.element_types.splice(index, 1);
        return true;
    }

    //*******************************************************
    /**
     * Returns the number of elements using given type.
     * @param {cn_element_type} element_type
     * @returns {number} Number of elements of that type.
     */
    get_element_type_count(element_type) {
        var cnt = 0;
        var is_wall_type = (element_type.constructor == cn_wall_type);
        var is_balcony_type = (element_type.constructor == cn_balcony_type);
        var is_fence_type = (element_type.constructor == cn_fence_type);
        var is_opening_type = (element_type.constructor == cn_opening_type);
        var is_beam_type = (element_type.constructor == cn_beam_column_type);
        var is_slab_type = (element_type.constructor == cn_slab_type);
        var is_roof_opening_type = (element_type.constructor == cn_roof_opening_type);
        var is_fence_opening_type = (element_type.constructor == cn_fence_opening_type);
        var is_pipe_type = (element_type.constructor == cn_pipe_type);
        if (!is_wall_type && !is_balcony_type && !is_fence_type && !is_opening_type && !is_fence_opening_type && !is_beam_type && !is_pipe_type && !is_slab_type && !is_roof_opening_type) return cnt;

        for (var i in this.storeys) {
            if (is_slab_type) {
                for (var j in this.storeys[i].slabs) {
                    if (this.storeys[i].slabs[j].slab_type == element_type)
                        cnt++;
                }
                continue;
            }
            if (is_roof_opening_type) {
                var roof = this.storeys[i].roof;
                if (roof == null) continue;
                for (var j in roof.openings) {
                    if (roof.openings[j].opening_type == element_type)
                        cnt++;
                }
                continue;
            }

            if (is_wall_type || is_balcony_type || is_fence_type) {
                cnt += this.storeys[i].get_walls().filter(wall => wall.wall_type == element_type).length;
                continue;
            }

            if (is_opening_type) {
                this.storeys[i].get_walls().forEach(wall => {
                    cnt += wall.openings.filter(op => op.opening_type == element_type).length;
                });
                continue;
            }

            var scene = this.storeys[i].scene;
            if (is_beam_type) {
                cnt += scene.beams.filter(beam => beam.element_type == element_type).length;
                cnt += scene.columns.filter(column => column.element_type == element_type).length;
                continue;
            }

            if (is_pipe_type) {
                cnt += scene.pipes.filter(pipe => pipe.element_type == element_type).length;
                continue;
            }
        }
        if (is_fence_type || is_fence_opening_type) {
            for (var j in this.exterior.scene.walls) {
                if (is_fence_type) {
                    if (this.exterior.scene.walls[j].wall_type == element_type)
                        cnt++;
                } else {
                    var openings = this.exterior.scene.walls[j].openings;
                    for (var k in openings) {
                        if (openings[k].opening_type == element_type)
                            cnt++;
                    }
                }
            }
        }
        return cnt;
    }

    //*******************************************************
    /**
     * Returns an element type given its id
     * @param {string} id
     * @returns {cn_element_type} or null if element type swas not found.
     */
    get_element_type(id) {
        for (var i in this.element_types) {
            if (this.element_types[i].ID == id)
                return this.element_types[i];
        }
        for (var i in this.tmp_element_types) {
            if (this.tmp_element_types[i].ID == id)
                return this.tmp_element_types[i];
        }
        return null;
    }

    //*******************************************************
    //*******************************************************
    //**** Object methods
    //*******************************************************
    //*******************************************************
    /**
     * Returns all objects in the building
     * @returns {cn_object[]}
     */
    get_objects() {
        return this.objects;
    }

    //*******************************************************
    /**
     * Returns an object given its id
     * @param {string} id
     * @returns {cn_object} or null if object not found
     */
    get_object(id) {
        for (let i in this.objects) {
            if (this.objects[i].ID === id)
                return this.objects[i];
        }
        for (let i in this.tmp_objects) {
            if (this.tmp_objects[i].ID === id)
                return this.tmp_objects[i];
        }
        return null;
    }

    //*******************************************************
    /**
     * Adds objects in the building, without tracing in transaction manager.
     * @param {cn_object[]} objects
     */
    add_initial_objects(objects) {
        this.objects.splice(0, 0, ...objects);
    }

    //*******************************************************
    /**
     * Adds an object in the building. If the object comes from wikipim, checks that the object is not already here.
     * @param {cn_object} object
     * @param {boolean} after_object_creation If true, merges current transaction (add object) with previous transaction (add instance), for state to be consistent in case of undo
     * @returns {cn_object} The object added, or a similar object found.
     */
    add_object(object, after_object_creation = false) {
        this.transaction_manager.push_transaction('Ajout du modèle d\'équipement ' + object.get_label());
        this.transaction_manager.push_item_set(this, 'objects');
        if (after_object_creation) {
            // merges current transaction (add object) with previous transaction (add instance)
            // for building to be consistent in case of undo
            // (orelse, object could be missing in building, but instance still present, causing unserialization issue)
            this.transaction_manager.merge_current_transaction();
        }
        this.objects.splice(0, 0, object);
        return object;
    }

    //*******************************************************
    /**
     * removes object from the building.
     * @param {cn_object} object
     * @returns {boolean} true if object was removed
     */
    remove_object(object) {
        if (this.get_object_count(object) != 0) return false;
        const index = this.objects.indexOf(object);
        if (index < 0) return false;
        this.transaction_manager.push_transaction('Suppression du modèle d\'équipement ' + object.get_label());
        this.transaction_manager.push_item_set(this, 'objects');
        this.objects.splice(index, 1);
        return true;
    }

    //*******************************************************
    /**
     * Returns the number of object instances using given type.
     * @param {cn_object} object
     * @returns {number} Number of elements of that type.
     */
    get_object_count(object) {
        let cnt = 0;
        for (let i in this.storeys) {
            const scene = this.storeys[i].scene;
            for (let j in scene.object_instances) {
                if (scene.object_instances[j].object.ID === object.ID)
                    cnt++;
            }
        }
        if (this.exterior && this.exterior.scene) {
            const scene = this.exterior.scene;
            for (let j in scene.object_instances) {
                if (scene.object_instances[j].object.ID === object.ID)
                    cnt++;
            }
        }
        return cnt;
    }

    //*******************************************************
    /**
     * Returns the list of object instances using given type.
     * @param {cn_object} object
     * @returns {cn_object_instance[]} Number of elements of that type.
     */
    get_object_instances(object) {
        const list = [];
        this.storeys.forEach(storey => {
            storey.scene.object_instances.forEach(instance => {
                if (instance.object.ID === object.ID)
                    list.push(instance);
            });
        })
        return list;
    }

    //*******************************************************
    /**
     * Adds a suggestion of objects for space usage.
     * @param {string} usage space usage
     * @param {{[id: string]: number}} objects cn_object.ID
     */
    add_space_equipments_suggestions(usage, objects) {
        this.transaction_manager.push_transaction('Mise à jour des suggestions d\'équipements pour ' + usage);
        this.transaction_manager.push_item_set(this, 'space_equipments_suggestions');
        this.space_equipments_suggestions = this.space_equipments_suggestions.filter(it => it.usage !== usage).concat({ usage, objects });
    }

    //*******************************************************
    /**
     * Remove a suggestion of objects for space usage.
     *  @param {string} usage space usage
     */
    remove_space_equipments_suggestions(usage) {
        this.transaction_manager.push_transaction('Suppression de suggestions d\'équipements pour ' + usage);
        this.transaction_manager.push_item_set(this, 'space_equipments_suggestions');
        this.space_equipments_suggestions = this.space_equipments_suggestions.filter(it => it.usage !== usage);
    }

    //*******************************************************
    /**
     * Gets the suggestion of objects for space usage.
     * @param {string} usage
     * @returns {{[id: string]: number} | null}
     */
    get_space_equipments_suggestions(usage) {
        const suggestion = this.space_equipments_suggestions.find(it => it.usage === usage);
        return !!suggestion ? suggestion.objects : null;
    }

    //*******************************************************
    /**
     * Build default element types
     */
    build_default_element_types() {
        if (this.get_wall_types().length == 0) {
            this._add_default_element_type(cn_wall_type.default_outer_wall());
            this._add_default_element_type(cn_wall_type.default_inner_wall());
            this._add_default_element_type(cn_wall_type.default_free());
        }
        if (this.get_balcony_types().length == 0) {
            this._add_default_element_type(cn_balcony_type.default_railing());
            this._add_default_element_type(cn_balcony_type.default_wall());
        }
        if (this.get_fence_types().length == 0) {
            var def_ft = cn_fence_type.default_types();
            for (var i in def_ft)
                this._add_default_element_type(def_ft[i]);
        }

        if (this.get_window_types().length == 0) {
            this._add_default_element_type(cn_opening_type.default_window());
        }

        if (this.get_door_types().length == 0) {
            this._add_default_element_type(cn_opening_type.default_door());
            this._add_default_element_type(cn_opening_type.default_door_90());
            this._add_default_element_type(cn_opening_type.default_french_window());
        }

        if (this.get_beam_types().length == 0) {
            var def = cn_beam_column_type.default_types();
            for (var i in def)
                this._add_default_element_type(def[i]);
        }

        if (this.get_slab_types().length == 0) {
            var def2 = cn_slab_type.default_types();
            for (var i in def2)
                this._add_default_element_type(def2[i]);
        }

        if (this.get_pipe_types().length == 0) {
            var defpipe = cn_pipe_type.default_types();
            for (var i in defpipe)
                this._add_default_element_type(defpipe[i]);
        }

        if (this.get_roof_opening_types().length == 0) {
            var def_rot = cn_roof_opening_type.default_types();
            for (var i in def_rot)
                this._add_default_element_type(def_rot[i]);
        }

        if (this.get_fence_opening_types().length == 0) {
            var def_fot = cn_fence_opening_type.default_types();
            for (var i in def_fot)
                this._add_default_element_type(def_fot[i]);
        }
    }

    //*******************************************************
    /**
     * Update roofs
     */
    update_roofs() {
        if (this._update_roofs_date < -1) return;
        if (this._update_roofs_date >= 0 && this.up_to_date(this._update_roofs_date)) return;

        this.facing_trimmings.forEach(ft => ft.update());
        logger.log('start roof update');
        var t0 = new Date();
        for (var i = 0; i < this.storeys.length; i++) {
            //*** Update lower slabs for each storey */
            this.storeys[i].update_slabs();

            //** special case for clone storeys */
            if (i + 1 < this.storeys.length && this.storeys[i].scene == this.storeys[i + 1].scene) {
                this.storeys[i].update_roof(null);
                continue;
            }

            //*** build roofs of this storey
            var roof_footprint = this.storeys[i].build_slab_polygon(0, false, false);

            //*** remove floors of upper storey
            var upper_footprint = null;
            if (i + 1 < this.storeys.length) {
                upper_footprint = this.storeys[i + 1].build_slab_polygon(0, true, false);
                if (upper_footprint.get_area() < 0.1) upper_footprint = null;
                if (upper_footprint)
                    roof_footprint.substracts(upper_footprint);
            }
            this.storeys[i].update_roof(roof_footprint, upper_footprint);
        }

        //*** Compute inner characteristics */
        this.propagate_indoor();

        var t1 = new Date();
        logger.log('Update roof time : ' + (t1.getTime() - t0.getTime()));

        this._update_roofs_date = CN_CURRENT_DATE;
    }

    //*******************************************************
    /**
     * returns duplication filters. For each element in the array :
     * - label : displayable label
     * - code :
     * @returns {object} duplication filters. Built on the flys, can be changed
     */
    get_duplication_filters() {
        var filters = {};
        filters.outer_walls = { 'label': 'Murs extérieurs', 'value': true };
        filters.inner_walls = { 'label': 'Murs intérieurs', 'value': true };
        filters.balconies = { 'label': 'Balcons', 'value': true };
        filters.windows = { 'label': 'Fenêtres', 'value': true };
        filters.doors = { 'label': 'Portes', 'value': true };
        filters.slab_openings = { 'label': 'Trémies', 'value': true };
        filters.stairs = { 'label': 'Escaliers', 'value': true };
        filters.columns = { 'label': 'Poteaux', 'value': true };
        filters.beams = { 'label': 'Poutres', 'value': true };
        filters.pipes = { 'label': 'Conduits', 'value': true };
        filters.objects = { 'label': 'Objets', 'value': true };
        filters.zones = { 'label': 'Zones', 'value': true };
        return filters;
    }

    //*******************************************************
    /**
     * Duplicate a storey. Returns new storey ID if it was duplicated, false otherwise
     * @param {string} storey_id
     * @param {object} filters : similar to the output of get_duplication_filters
     * @returns {string|false} new storey ID or false if duplication could not be done
     */
    duplicate_storey(storey_id, filters = null) {

        let j;
        let elt;

        function scan_filter(key) {
            if (filters == null) return true;
            if (typeof (filters) != 'object') return true;
            if (!filters[key]) {
                console.error('Wrong key in duplication filters : ' + key);
                return true;
            }
            if (typeof (filters[key].value) != 'boolean') return true;
            return filters[key].value;
        }

        const do_outer_walls = scan_filter('outer_walls');
        const do_inner_walls = scan_filter('inner_walls');
        const do_balconies = scan_filter('balconies');
        const do_windows = scan_filter('windows');
        const do_doors = scan_filter('doors');
        const do_slab_openings = scan_filter('slab_openings');
        const do_stairs = scan_filter('stairs');
        const do_columns = scan_filter('columns');
        const do_beams = scan_filter('beams');
        const do_pipes = scan_filter('pipes');
        const do_objects = scan_filter('objects');
        const do_zones = scan_filter('zones');

        const storey = this.find_storey(storey_id);
        if (storey == null) return false;

        this.transaction_manager.push_transaction('Duplication de niveau', '', () => {
            this.rename_storeys();
        });
        this.transaction_manager.push_item_set(this, 'storeys');

        for (const i in this.storeys) {
            if (this.storeys[i].storey_index > storey.storey_index) {
                this.transaction_manager.push_item_set(this.storeys[i], 'storey_index');
                this.storeys[i].storey_index++;
            }
        }

        const new_storey = new cn_storey(this);
        new_storey.height = storey.height;
        new_storey.name = storey.name;
        new_storey.storey_index = storey.storey_index + 1;
        const scene = cn_scene.unserialize(storey.scene.serialize(), this);

        if (!do_outer_walls || !do_inner_walls || !do_balconies) {
            for (j = 0; j < scene.walls.length; j++) {
                elt = scene.walls[j];
                if (elt.balcony) {
                    if (do_balconies) continue;
                } else if (elt.spaces[0].has_roof && elt.spaces[1].has_roof) {
                    if (do_inner_walls) continue;
                } else if (do_outer_walls) continue;
                scene.walls.splice(j, 1);
                scene._need_rebuild_spaces = true;
                j--;
            }
        }

        if (!do_windows || !do_doors) {
            for (let j0 = 0; j0 < scene.walls.length; j0++) {
                elt = scene.walls[j0];
                for (j = 0; j < elt.openings.length; j++) {
                    const op = elt.openings[j];
                    if (op.opening_type.category == 'window') {
                        if (do_windows) continue;
                    } else if (op.opening_type.category == 'door') {
                        if (do_doors) continue;
                    }
                    elt.openings.splice(j, 1);
                    j--;
                }
            }
        }

        if (!do_inner_walls) {
            scene.spaces.forEach(space => space.reset_properties());
        }

        if (!do_slab_openings)
            scene.slab_openings = [];

        if (!do_stairs)
            scene.stairs = [];

        if (!do_objects)
            scene.object_instances = [];

        if (!do_columns)
            scene.columns = [];

        if (!do_beams)
            scene.beams = [];

        if (!do_pipes)
            scene.pipes = [];

        scene.full_update();

        new_storey.scene = scene;
        this.storeys.push(new_storey);
        this.rename_storeys();

        if (do_zones) {
            Object.keys(this.zones).forEach((zoning_type) => {
                this.transaction_manager.push_item_set(this.zones, zoning_type);
                this.get_zones(zoning_type).filter(zone => zone.main_storey === storey_id).forEach(zone => {
                    const new_zone = new cn_zone(zone.name, new_storey.ID);
                    new_zone.color = zone_colors[this.get_zones(zoning_type).length % 10];
                    new_zone.rooms = [...zone.rooms.filter(room => room.storey === storey_id).map(room => {
                        return { space: room.space, storey: new_storey.ID }
                    })];
                    this.zones[zoning_type].push(new_zone);
                });
            });
        }

        extension_instance.features.forEach(f => {
            f.feature.on_duplicate_storey(storey, new_storey);
        });

        return new_storey.ID;
    }

    //*******************************************************
    /**
     * Deletes a storey
     * @param {string} storey_id
     * @returns {boolean} Returns true if it was deleted, false otherwise
     */
    delete_storey(storey_id) {
        const storey = this.find_storey(storey_id);
        if (storey == null) return false;
        const index = this.storeys.indexOf(storey);
        if (index < 0) return false;

        this.transaction_manager.push_transaction('Suppression de niveau', '', () => {
            this.rename_storeys();
        });
        this.transaction_manager.push_item_set(this, 'storeys');

        //*** Remove the storey
        this.storeys.splice(index, 1);

        //*** Keep track of clones
        let new_clone = null;
        let other_clone = false;

        extension_instance.features.forEach(f => f.feature.on_delete_storey(storey));

        //*** operate remaining storeys
        for (const i in this.storeys) {
            //*** Update storeys indices
            if (this.storeys[i].storey_index > storey.storey_index) {
                this.transaction_manager.push_item_set(this.storeys[i], 'storey_index');
                this.storeys[i].storey_index--;
            }

            //***we replace clone origin if necessary
            if (storey.clone_of && this.storeys[i].clone_of == storey.clone_of) {
                if (new_clone == null) new_clone = this.storeys[i];
                else other_clone = true;

                this.transaction_manager.push_item_set(this.storeys[i], 'clone_of');
                this.storeys[i].clone_of = new_clone;
            }
        }

        //** This was the last clone : no more cloning !
        if (new_clone && !other_clone) {
            this.transaction_manager.push_item_set(new_clone, 'clone_of');
            new_clone.clone_of = null;
        }

        if (this.zpsos) {
            this.zpsos.forEach((zpso, index_zpso) => {
                if (zpso.elements.some(el => el.storey === storey_id)) {
                    this.transaction_manager.push_item_set(this.zpsos[index_zpso], 'elements');
                    let index_element = -1
                    while ((index_element = zpso.elements.findIndex(el => el.storey === storey_id)) >= 0) {
                        zpso.elements.splice(index_element, 1);
                    }
                }
                if (zpso.sections.some(section => section.storey === storey_id)) {
                    this.transaction_manager.push_item_set(this.zpsos[index_zpso], 'sections');
                    let index_section = -1
                    while ((index_section = zpso.sections.findIndex(el => el.storey === storey_id)) >= 0) {
                        zpso.sections.splice(index_section, 1);
                    }
                }
            });
        }

        Object.keys(this.zones).forEach((zoning_type) => {
            if (this.zones[zoning_type].length) {
                // Remove zones where the deleted storey is main storey
                this.transaction_manager.push_item_set(this.zones, zoning_type);
                let index_zone = -1
                while ((index_zone = this.zones[zoning_type].findIndex(zone => zone.main_storey === storey_id)) >= 0) {
                    this.zones[zoning_type].splice(index_zone, 1);
                }
                // Remove zone from other storeys
                this.zones[zoning_type].forEach((zone, index) => {
                    if (zone.rooms.some(room => room.storey === storey_id)) {
                        this.transaction_manager.push_item_set(this.zones[zoning_type][index], 'rooms');
                        let index_room = -1;
                        while ((index_room = zone.rooms.findIndex(room => room.storey === storey_id)) >= 0) {
                            zone.rooms.splice(index_room, 1);
                        }
                    }
                })
            }
        });

        this.rename_storeys();
        return true;
    }

    //*******************************************************
    /**
     * Clone a storey
     * @param {string} storey_id
     * @returns {string | false} Returns new storey ID if it was cloned, false otherwise
     */
    clone_storey(storey_id) {
        const storey = this.find_storey(storey_id);
        if (storey == null) return false;

        this.transaction_manager.push_transaction('Clonage de niveau', '', () => {
            this.rename_storeys();
        });
        this.transaction_manager.push_item_set(this, 'storeys');

        for (const i in this.storeys) {
            if (this.storeys[i].storey_index > storey.storey_index) {
                this.transaction_manager.push_item_set(this.storeys[i], 'storey_index');
                this.storeys[i].storey_index++;
            }
        }


        const new_storey = new cn_storey(this);
        new_storey.storey_index = storey.storey_index + 1;
        new_storey.height = storey.height;
        if (storey.clone_of == null) storey.clone_of = storey;
        new_storey.clone_of = storey.clone_of;
        new_storey.scene = storey.scene;
        new_storey.name = storey.name;
        new_storey.building = this;
        this.storeys.push(new_storey);
        this.rename_storeys();

        this.transaction_manager.push_item_set(this, 'zones');
        Object.keys(this.zones).forEach((zoning_type) => {
            this.zones[zoning_type].filter(zone => zone.main_storey === storey_id).forEach(zone => {
                const new_zone = new cn_zone(zone.name, new_storey.ID);
                new_zone.color = zone_colors[this.zones[zoning_type].length % 10];
                new_zone.rooms = [...zone.rooms.filter(room => room.storey === storey_id).map(room => {
                    return { space: room.space, storey: new_storey.ID }
                })];
                this.zones[zoning_type].push(new_zone);
            });
        });

        return new_storey.ID;
    }

    //*******************************************************
    /**
     * find a storey by its id (interior only)
     * @param {string} id
     * @returns {cn_storey}
     */
    find_storey(id) {
        return this.storeys.find(it => it.ID == id) || null;
    }

    /**
     * Returns all storeys, + exterior storey
     * @returns {Array<cn_storey>}
     */
    all_storeys() {
        return [...this.storeys, this.exterior];
    }

    //*******************************************************
    /**
     * find a storey by its id, looking also in exterior
     * @param {string} id
     * @returns {cn_storey}
     */
    find_storey_including_exterior(id) {
        return [...this.storeys, this.exterior].find(it => it.ID == id) || null;
    }

    //*******************************************************
    /**
     * find a space by its storey and space ids
     * @param {string} storey_id
     * @param {string} space_id
     * @returns {cn_space}
     */
    find_space(storey_id, space_id) {
        const storey = this.find_storey_including_exterior(storey_id);
        if (!storey) return null;
        return storey.scene.spaces.find(it => it.ID == space_id) || null;
    }

    //*******************************************************
    /**
     * find exterior
     * @returns {cn_storey}
     */
    find_exterior() {
        return this.exterior;
    }

    //*******************************************************
    /**
     * find a storey by its index
     * @param {number} storey_index
     * @returns {cn_storey}
     */
    find_storey_by_index(storey_index) {
        return this.storeys.find(it => it.storey_index == storey_index) || null;
    }

    //*******************************************************
    /**
     * Sets ground storey
     * @param {string} storey_id
     */
    set_storey_0(storey_id) {
        const storey = this.find_storey(storey_id);
        if (storey == null) return;
        if (this.storey_0_index === storey.storey_index) return;

        this.transaction_manager.push_transaction('Choix du rez-de-chaussée', '', () => {
            this.rename_storeys();
        });
        this.transaction_manager.push_item_set(this, 'storey_0_index');

        this.storey_0_index = storey.storey_index;

        this.rename_storeys();
    }

    /**
     * Returns building markers.
     * @return {cn_marker[]}
     */
    get_markers() {
        return this.storeys.flatMap(it => it.markers);
    }

    /**
     * Find background map by image id
     * @param {string} image_id
     * @return {cn_background_map|null}
     */
    find_background_map(image_id) {
        return this.maps.find(it => it.image_id == image_id) || null;
    }

    /**
     * Creates a background map in building
     * @param {string} image_id
     * @param {number} width
     * @param {number} height
     * @return {cn_background_map}
     */
    new_background_map(image_id, width, height) {

        var bg = new cn_background_map(this);
        this.maps.push(bg);

        bg.image_id = image_id;
        bg.image_size[0] = width;
        bg.image_size[1] = height;
        bg.scale = 10 / width;

        return bg;
    }

    /**
     * Indicates if a background map is used in some storey.
     * @param {cn_background_map} map
     * @return {boolean}
     */
    is_used_background_map(map) {
        for (let i = 0; i < this.storeys.length; i++) {
            if (this.storeys[i].background_maps.indexOf(map) >= 0) return true;
        }
        return false;
    }

    /**
     * Remove a background map from building, only if it's not used in storeys.
     * @param {cn_background_map} map
     * @return {boolean}
     */
    remove_background_map(map) {
        let index = this.maps.indexOf(map);
        if (index < 0) return false;
        if (this.is_used_background_map(map)) return false;
        this.maps.splice(index, 1);
    }

    /**
     * Returns storeys indexes where background map is used (empty array if unused)
     * @param {cn_background_map} map
     * @return {number[]}
     */
    get_background_map_storey_indexes(map) {
        const result = [];
        for (let storey_index = 0; storey_index < this.storeys.length; storey_index++) {
            if (this.storeys[storey_index].background_maps.indexOf(map) >= 0) result.push(storey_index);
        }
        return result;
    }

    //*******************************************************
    //**** sets storey height (and on clones)
    //*******************************************************

    set_storey_height(storey_id, new_height) {
        if (new_height < 0.1 || new_height > 50) return false;

        var storey = this.find_storey(storey_id);
        if (storey == null) return false;

        var obj = this;
        this.transaction_manager.push_transaction('Hauteur de niveau');
        this.transaction_manager.push_item_set(storey, 'height');

        storey.height = new_height;
        if (storey.clone_of) {
            for (var i in this.storeys) {
                if (this.storeys[i].clone_of == storey.clone_of) {
                    this.transaction_manager.push_item_set(this.storeys[i], 'height');
                    this.storeys[i].height = new_height;
                }
            }
            storey.clone_of.scene.update_height();
        } else
            storey.scene.update_height();
        return true;
    }

    //*******************************************************
    //**** move storey to a new position
    //*******************************************************
    move_storey(storey_id, new_index) {
        const storey = this.find_storey(storey_id);
        if (storey == null) return;

        this.transaction_manager.push_transaction('Déplacement d\'un niveau', this.ID, () => {
            this.rename_storeys();
        });

        storey.storey_index = new_index;

        let i = 1;
        this.storeys.forEach(s => {
            if (s.ID !== storey_id && s.storey_index >= new_index) {
                this.transaction_manager.push_item_set(s, 'storey_index');
                s.storey_index = new_index + i;
                i++;
            }
        });

        this.storeys.sort(function (a, b) {
            if (a.storey_index > b.storey_index) return 1;
            return -1;
        });
        this.rename_storeys();
    }

    //*******************************************************
    //**** sets storey index
    //*******************************************************
    set_storey_index(storey_id, new_index) {
        var storey = this.find_storey(storey_id);
        if (storey == null) return;

        this.transaction_manager.push_transaction('Ordonnancement de niveaux', this.ID);
        this.transaction_manager.push_item_set(storey, 'storey_index');

        storey.storey_index = new_index;

        this.storeys.sort(function (a, b) {
            if (a.storey_index > b.storey_index) return 1;
            return -1;
        });
        this.rename_storeys();
    }

    /**
     *  Rename a storey by his id
     *
     * @param {string} storey_id
     * @param {string} name
     */
    set_storey_name(storey_id, name) {
        var storey = this.find_storey(storey_id);
        if (storey) {
            this.transaction_manager.push_transaction('Changement de nom d\'un niveau');
            this.transaction_manager.push_item_set(storey, 'name');
            storey.name = name;
        }
    }

    //*******************************************************
    //**** rename storeys
    //*******************************************************
    rename_storeys() {
        for (var i in this.storeys) {
            var index = this.storeys[i].storey_index - this.storey_0_index;
            if (index == 0) {
                this.storeys[i].short_name = 'RdC';
            } else {
                this.storeys[i].short_name = '' + index;
            }
        }
    }

    //*******************************************************
    /**
     * Compute altitude of storeys and topography
     */
    compute_altitudes() {
        var h = 0;
        for (var nbs = 0; nbs < this.storeys.length; nbs++) {
            var storey = this.storeys[nbs];
            h += storey.get_max_slab_thickness();
            storey.altitude = h;

            h += storey.height;
            storey.roof_altitude = h;
            if (nbs < this.storeys.length - 1)
                storey.roof_altitude += this.storeys[nbs + 1].get_max_slab_thickness();
            if (nbs == this.storey_0_index) {
                this.topography.z = storey.altitude;
                this.exterior.altitude = storey.altitude;
            }
        }
    }

    //*******************************************************
    //**** Build floors from given storey
    //*******************************************************
    build_floor_polygon(storey_index, balconies, slab_openings) {
        if (storey_index < 0 || storey_index >= this.storeys.length)
            return null;

        //*** build floors of this storey
        var storey = this.storeys[storey_index];
        return storey.build_slab_polygon(0, balconies, slab_openings);
    }

    //*******************************************************
    //**** Build roofs from given storey
    //*******************************************************
    build_roof_polygon(storey_index) {
        if (storey_index < 0 || storey_index >= this.storeys.length)
            return null;

        //*** build roofs of this storey
        var storey = this.storeys[storey_index];
        var polygon = storey.build_slab_polygon(0, false, false);

        //*** remove floors of upper storey
        if (storey_index + 1 < this.storeys.length) {
            storey = this.storeys[storey_index + 1];
            polygon.substracts(storey.build_slab_polygon(0, true, false));
        }
        polygon.compute_contours();
        if (polygon.contour_sizes.length == 0) return null;
        return polygon;
    }

    //*******************************************************
    /**
     * Build slabs from 2 storeys :
     * @param {number} storey_0_index first storey is below, can be negative
     * @param {number} storey_1_index second storey is above, can be > nb storeys
     * @param {boolean} build_balconies if false (default), will not build balcony slabs
     * @returns {cn_slab[]}
     */
    build_slabs(storey_0_index, storey_1_index, build_balconies = false) {
        var storey_0 = null;
        if (storey_0_index >= 0 && storey_0_index < this.storeys.length)
            storey_0 = this.storeys[storey_0_index];

        var storey_1 = null;
        if (storey_1_index >= 0 && storey_1_index < this.storeys.length)
            storey_1 = this.storeys[storey_1_index];

        if (storey_1) storey_1.scene.storey = storey_1;

        var slabs = [];
        var storeys = [storey_0, storey_1];

        //*** build all polygons
        var polygons = [[], []];
        var inner_polygons = [[], []];
        var exterior_polygons = [new fh_polygon([0, 0, 0], [0, 0, 1]), new fh_polygon([0, 0, 0], [0, 0, 1])];
        for (var n = 0; n < 2; n++) {
            if (storeys[n] == null) continue;
            storeys[n].scene.storey = storeys[n];
            storeys[n].scene.update();
            storeys[n].scene.update_deep();
            var spaces = storeys[n].scene.spaces;
            for (var i in spaces) {
                if (spaces[i].outside) continue;
                if (!build_balconies && !spaces[i].has_roof) continue;
                var pg = spaces[i].build_outer_polygon(0, false);
                pg.compute_contours();
                pg['space_index'] = i;
                polygons[n].push(pg);
                var pgi = spaces[i].build_inner_polygon(0, false);
                pgi.compute_contours();
                pgi['space_index'] = i;
                inner_polygons[n].push(pgi);
                exterior_polygons[n].unites(pg);
            }
        }

        //*** build full exterior slab
        var exterior_polygon = new fh_polygon([0, 0, 0], [0, 0, 1]);
        for (var n = 0; n < 2; n++)
            exterior_polygon.unites(exterior_polygons[n]);

        var slab = new cn_slab(storey_1);
        slab.spaces[0] = slab.spaces[1] = null;
        slab.ID = cn_building.create_unique_id(storey_0);
        slab.contours = cn_contour.build_from_polygon(exterior_polygon);
        slabs.push(slab);

        function find_best_match(pg_list, pg) {
            var common_area = 0;
            var best_index = -1;
            pg_list.forEach((pgi, index) => {
                if (pgi) {
                    const pgc = pgi.clone();
                    pgc.intersects(pg);
                    const area = pgc.get_area();
                    if (area > common_area) {
                        best_index = index;
                        common_area = area;
                    }
                }
            });
            if (best_index < 0) return new fh_polygon([0, 0, 0], [0, 0, 1]);
            const res = pg_list[best_index];
            pg_list[best_index] = null;
            return res;
        }

        //*** build roofs
        for (var i in polygons[0]) {
            var pg2 = polygons[0][i].clone();
            pg2.substracts(exterior_polygons[1]);
            if (pg2.get_area() < 0.01) continue;

            var pgi2 = inner_polygons[0][i].clone();
            pgi2.substracts(exterior_polygons[1]);
            var pgis = pgi2.split();
            if (pgis.length == 0) continue;
            var pgs = pg2.split();
            for (var k = 0; k < pgs.length; k++) {
                slab = new cn_slab(storey_1);
                slab.spaces[0] = storeys[0].scene.spaces[polygons[0][i].space_index];
                slab.space_unique_ids[0] = cn_building.create_unique_id(storey_0, slab.spaces[0]);
                slab.spaces[1] = null;
                slab.ID = cn_building.create_unique_id(storey_0, slab.spaces[0], slab.spaces[1], k);
                slab.outer_polygon = pgs[k];
                slabs.push(slab);
                if (pgs.length == 1 && pgis.length == 1)
                    slab.inner_polygon = pgis[0];
                else
                    slab.inner_polygon = find_best_match(pgis, pgs[k]);
            }
        }

        //*** build floors
        for (var i in polygons[1]) {
            var pg3 = polygons[1][i].clone();
            pg3.substracts(exterior_polygons[0]);
            if (pg3.get_area() < 0.01) continue;

            var pgi3 = inner_polygons[1][i].clone();
            pgi3.substracts(exterior_polygons[0]);
            var pgis = pgi3.split();
            if (pgis.length == 0) continue;
            var pgs = pg3.split();
            for (var k = 0; k < pgs.length; k++) {
                slab = new cn_slab(storey_1);
                slab.spaces[0] = null;
                slab.spaces[1] = storeys[1].scene.spaces[polygons[1][i].space_index];
                slab.space_unique_ids[1] = cn_building.create_unique_id(storey_1, slab.spaces[1]);
                slab.ID = cn_building.create_unique_id(storey_0, slab.spaces[0], slab.spaces[1], k);
                slab.outer_polygon = pgs[k];
                slabs.push(slab);

                if (pgs.length == 1 && pgis.length == 1)
                    slab.inner_polygon = pgis[0];
                else
                    slab.inner_polygon = find_best_match(pgis, pgs[k]);
            }
        }

        //*** build intermediates
        for (var i in polygons[0]) {
            for (var j in polygons[1]) {
                var pgi4 = inner_polygons[0][i].clone();
                pgi4.intersects(inner_polygons[1][j]);
                if (pgi4.contour_sizes.length == 0) continue;
                if (pgi4.get_area() < 0.01) continue;

                var pg4 = polygons[0][i].clone();
                pg4.intersects(polygons[1][j]);
                if (pg4.contour_sizes.length == 0) continue;

                var pgis = pgi4.split();
                var pgs = pg4.split();
                for (var k = 0; k < pgs.length; k++) {
                    slab = new cn_slab(storey_1);
                    slab.spaces[0] = storeys[0].scene.spaces[polygons[0][i].space_index];
                    slab.space_unique_ids[0] = cn_building.create_unique_id(storey_0, slab.spaces[0]);
                    slab.spaces[1] = storeys[1].scene.spaces[polygons[1][j].space_index];
                    slab.space_unique_ids[1] = cn_building.create_unique_id(storey_1, slab.spaces[1]);
                    slab.ID = cn_building.create_unique_id(storey_0, slab.spaces[0], slab.spaces[1], k);
                    slab.outer_polygon = pgs[k];
                    if (pgs.length == 1 && pgis.length == 1)
                        slab.inner_polygon = pgis[0];
                    else
                        slab.inner_polygon = find_best_match(pgis, pgs[k]);
                    slabs.push(slab);
                }
            }
        }

        var slab_openings = [];
        if (storey_0) {
            storey_0.scene.stairs.forEach(st => {
                slab_openings.push(st.build_3d_slab_opening(0));
            });
            storey_0.scene.spaces.filter(sp => !sp.has_ceiling).forEach(sp => {
                slab_openings.push(sp.build_inner_polygon(0, false));
            });
        }
        if (storey_1) {
            storey_1.scene.slab_openings.forEach(so => {
                slab_openings.push(so.build_3d_polygon(0));
            });
        }
        slab_openings = slab_openings.filter(s => s);

        slabs.forEach(slab => {
            if (slab.spaces[0] || slab.spaces[1]) {
                slab.pierced_inner_polygon = slab.inner_polygon.clone();
                slab.pierced_outer_polygon = slab.outer_polygon.clone();
                if (slab_openings.length) {
                    slab_openings.forEach(so => slab.pierced_inner_polygon.substracts(so));
                    if (slab.pierced_inner_polygon.get_area() < slab.inner_polygon.get_area() - 0.001) {
                        const piercings = slab.inner_polygon.clone();
                        piercings.substracts(slab.pierced_inner_polygon);
                        slab.pierced_outer_polygon.substracts(piercings);
                    }
                }
                slab.contours = cn_contour.build_from_polygon(slab.pierced_outer_polygon);
            }
            slab.update();
        });

        return slabs;
    }

    /**
     *  Add zpso to building
     *
     *  @param {string} zpso_name
     *  @param {string} color
     *  @param {string} stripes
     *  @param {string} control_result
     *  @param {string} decision_criteria
     *  @returns {cn_zpso} zpso
     **/
    add_zpso(zpso_name, color, stripes, control_result, decision_criteria) {
        const result = new cn_zpso(zpso_name)
        result.color = color || '';
        result.stripes = stripes || '';
        result.control_result = control_result;
        result.decision_criteria = decision_criteria;
        this.zpsos.push(result);
        return result;
    }

    /**
     *  Add layer to building
     *
     *  @param {string} layer_name
     *  @param {string} color
     *  @param {string} stripes
     *  @returns {cn_layer} layer
     **/
    add_layer(layer_name, color, stripes) {
        let result = null;
        if (!this.layers.find(layer => layer.name === layer_name)) {
            result = new cn_layer(layer_name)
            result.color = color || '';
            result.stripes = stripes || '';
            this.layers.push(result);
        } else {
            console.error(`Le calque ${layer_name} existe déjà`);
        }
        return result;
    }

    /**
     *  Remove zpso from building
     *
     *  @param {string} id_zpso
     **/
    remove_zpso(id_zpso) {
        const zpso_index = this.zpsos.findIndex(zpso => zpso.ID === id_zpso)
        if (zpso_index >= 0) {
            this.zpsos.splice(zpso_index, 1);
        } else {
            console.error(`ZPSO ${id_zpso} doesn't exist`);
        }
    }

    /**
     *  Remove layer from building
     *
     *  @param {string} id_layer
     **/
    remove_layer(id_layer) {
        const layer_index = this.layers.findIndex(layer => layer.ID === id_layer)
        if (layer_index >= 0) {
            this.layers.splice(layer_index, 1);
        } else {
            console.error(`Le calque ${id_layer} n'existe pas`);
        }
    }

    //*******************************************************
    /**
     * Returns true if the given area context is active in the building
     * @param {string} code
     * @returns {cn_area_context}
     */
    check_area_context(code) {
        return this.area_contexts.find(function (ac) {
            return (ac.code == code);
        });
    }

    /**
     * Compute all areas in area contexts
     */
    compute_area_contexts() {
        this.area_contexts.forEach(ac => ac.compute_space_areas());
    }

    /**
     * Add a new config of export
     *
     * @param {cn_config_export} config
     */
    add_new_config_export(config) {
        this.config_exports.push(config);
    }

    //*******************************************************
    //**** Get images ids
    //*******************************************************

    /**
     * Returns all image_id used in building background maps (not deduplicated).
     * @returns {string[]}
     */
    get_images_ids_backgrounds_maps() {
        return this.storeys
            .flatMap(it => it.background_maps)
            .map(it => it.image_id);
    }

    /**
     * Returns all image_id used in building markers (not deduplicated).
     * @returns {string[]}
     */
    get_images_ids_markers() {
        return this.storeys
            .flatMap(it => it.markers)
            .flatMap(it => it.pictures)
            .map(it => it.image_id);
    }

    /**
     * Returns all image_id used in building (not deduplicated).
     * @returns {string[]}
     */
    get_images_ids_used_in_building() {
        return [
            ...this.get_images_ids_backgrounds_maps(),
            ...this.get_images_ids_markers(),
        ];
    }

    //*******************************************************
    //**** Create unique id
    //*******************************************************
    static create_unique_id(...args) {
        return 'cn' + cn_md5(...args);
    }

    /**
     *  Generate building and define storeys' names and roofs
     *
     * @returns {cn_building}
     */
    static generate_new_building() {
        const result = new cn_building();
        result.facing_types = cn_building.generate_generic_facing_types(result);
        cn_building._populate_area_context(result);
        result.rename_storeys();
        result.update_roofs();
        return result;
    }

    static generate_generic_facing_types(building) {
        return [
            { support: 'wall', list: INTERIOR_WALL_FACING_LIST },
            { support: 'floor', list: INTERIOR_FLOOR_FACING_LIST },
            { support: 'ceiling', list: INTERIOR_CEILING_FACING_LIST },
            { support: 'facade', list: EXTERIOR_WALL_FACING_LIST },
            { support: 'exterior', list: EXTERIOR_SPACE_FACING_LIST }
        ].map(el => el.list.map(category => {
            // @ts-ignore
            const result = new cn_facing(el.support);
            result.name = category.label;
            result.category = category.code;
            result.texture = category.code;
            return result;
        })
        ).reduce((a, b) => a.concat(b), [])
    }

    get_facades() {
        if (this._facades_date > 0 && this.up_to_date(this._facades_date))
            return this._facades;
        this._facades = cn_facade.build_facades(this);
        this._facades_date = CN_CURRENT_DATE;
        return this._facades;
    }

    /**
     * Returns zones from a given zoning type, or empty array if no zone (never returns null)
     * @param {string} zoning_type
     * @return {cn_zone[]}
     */
    get_zones(zoning_type) {
        return this.zones[zoning_type] || [];
    }

    /**
     * Returns true if at least one zone is not empty.
     * @param {string} zoning_type
     * @returns {boolean}
     */
    has_non_empty_zone(zoning_type) {
        return this.get_zones(zoning_type).some(z => z.rooms.length > 0);
    }

    /**
     * Returns the zone matching a given space
     * @param {cn_space} space
     * @param {cn_storey} storey
     * @param {string} zoning_type
     * @returns {cn_zone}
     */
    find_zone(space, storey, zoning_type) {
        const z = this.get_zones(zoning_type).find(z => z.rooms.some(r => r.space == space.ID && r.storey == storey.ID));
        if (z) return z;
        return null;
    }

    /**
     * Sets reference data for products types.
     * @param {{id: string, name: string, codeBim: string, parentsIds: string[], ifcType: string}[]} reference_data_products
     */
    set_reference_data_products(reference_data_products) {
        this._reference_data_products = reference_data_products;
    }

    /**
     * Sets reference data for spaces types.
     * @param {{codeBim: string, name: string, codeGbXml: string}[]} reference_data_spaces
     */
    set_reference_data_spaces(reference_data_spaces) {
        this._reference_data_spaces = reference_data_spaces;
    }

    /**
     * Sets reference data for zones types.
     * @param {{codeBim: string, name: string, codeGbXml: string}[]} reference_data_zones
     */
    set_reference_data_zones(reference_data_zones) {
        this._reference_data_zones = reference_data_zones;
    }

    /**
     * Sets reference data for buildings types.
     * @param {{codeBim: string, name: string, codeGbXml: string}[]} reference_data_buildings
     */
    set_reference_data_buildings(reference_data_buildings) {
        this._reference_data_buildings = reference_data_buildings;
        // Comme certains buildings ont de vieux codes, on va éventuellement les convertir en nouveaux codes bim
        // Ce bout de code pourra être supprimé à terme
        if (this.building_data && this.building_data.typology) {
            const building_types_code_map = [
                { code: 'unknown', code_bim: 'inconnu' },
                { code: 'lodging_house', code_bim: 'maison_individuelle' },
                { code: 'lodging_flat', code_bim: 'appartement' },
                { code: 'lodging_building', code_bim: 'immeuble_logement' },
                { code: 'office_building', code_bim: 'immeuble_bureaux' },
                { code: 'mixt_building', code_bim: 'immeuble_mixte' },
                { code: 'other', code_bim: 'autres' }
            ];
            const building_type_code_mapping = building_types_code_map.find(it => it.code == this.building_data.typology);
            if (building_type_code_mapping) {
                this.building_data.typology = building_type_code_mapping.code_bim;
                logger.log('Replaced old typology "' + building_type_code_mapping.code + '" by new "' + building_type_code_mapping.code_bim + '"')
            }
        }
    }

    /**
     * Gets product type reference data from product type code bim.
     * @param {string} product_type
     * @return {{id: string, name: string, codeBim: string, parentsIds: string[], ifcType: string, parent?: {id: string, name: string, codeBim: string, parentsIds: string[], ifcType: string}}}
     */
    get_product_reference(product_type) {
        const res = this._reference_data_products.find(pr => pr.codeBim == product_type);
        if (!res) return null;
        this._build_product_parent(res);
        return res;
    }

    /**
     * @param {{id: string, name: string, codeBim: string, parentsIds: string[], ifcType: string, parent?: {id: string, name: string, codeBim: string, parentsIds: string[], ifcType: string}}} reference
     */
    _build_product_parent(reference) {
        if (typeof (reference.parent) != 'undefined') return;
        reference.parent = null;
        if (!reference.parentsIds || reference.parentsIds.length == 0) return;
        const parent = this._reference_data_products.find(pr => pr.id == reference.parentsIds[reference.parentsIds.length - 1]);
        if (!parent) return;
        reference.parent = parent;
        this._build_product_parent(parent);
    }

    /**
     * Gets space type reference data from space type code bim.
     * @param {string} space_type
     * @return {{codeBim: string, name: string, codeGbXml: string}}
     */
    get_space_reference(space_type) {
        const res = this._reference_data_spaces.find(pr => pr.codeBim == space_type);
        if (!res) return null;
        return res;
    }

    /**
     * Gets building type reference data from building type code bim.
     * @param {string} building_type
     * @return {{codeBim: string, name: string, codeGbXml: string}}
     */
    get_building_reference(building_type) {
        const res = this._reference_data_buildings.find(pr => pr.codeBim == building_type);
        if (!res) return null;
        return res;
    }


    /**
     * Partial update building data.
     * Only non-null fields are updated.
     * @param {Partial<cn_building_data>} update
     * @param {boolean} initial default false, set true at first call at deserialization, then already defined values are not overwritten, and transaction is not logged.
     */
    update_building_data(update, initial = false) {
        this.building_data.update(update, initial, this.transaction_manager);
    }

    /**
     * Get all unique group name from all markers
     *
     * @returns {string[]}
     */
    get_all_marker_groups() {
        return Array.from(new Set(this.storeys.flatMap(storey => storey.markers).map(marker => marker.group).filter(group => !!group)));
    }

    /**
     * Get all unique group name from all samplings
     *
     * @returns {string[]}
     */
    get_all_sampling_groups() {
        return Array.from(new Set(this.storeys.flatMap(storey => storey.samplings).map(sampling => sampling.group).filter(group => !!group)));
    }

    /**
     * Get all clipping planes serialized
     * @return {any[]}
     */
    get_clipping_planes() {
        return this.clipping_planes;
    }

    /**
     * Get serialized clipping planes
     * @param {[]} value
     */
    set_clipping_planes(value) {
        this.transaction_manager.push_transaction('Mise à jour des coupes');
        this.transaction_manager.push_item_set(this, 'clipping_planes');
        this.clipping_planes = value;
    }

    /**
     * Propagate indoor characteristics through the building.
     */
    propagate_indoor() {

        //*** initialize */
        this.storeys.forEach(storey => {
            storey.scene.spaces.forEach(space => {
                space._has_roof[storey.ID] = (!space.outside);
                space._indoor[storey.ID] = (!space.outside);
            });
        });

        //*** Apply no ceiling to spaces without anything above */
        this.storeys.filter(storey => storey.scene.spaces.some(sp => !sp.has_ceiling)).forEach(storey => {
            const storey_id = storey.ID;
            const storey_after = storey.get_next_storey();
            var upper_storey_footprint = null;
            if (storey_after) {
                upper_storey_footprint = new fh_polygon([0, 0, 0], [0, 0, 1]);
                storey_after.scene.spaces.filter(space => !space.outside).forEach(space =>
                    upper_storey_footprint.unites(space.build_outer_polygon(0, false)));
            }
            storey.scene.spaces.filter(sp => !sp.has_ceiling).forEach(space => {
                var test = true;
                if (upper_storey_footprint) {
                    const pg = space.build_inner_polygon(0, false);
                    pg.substracts(upper_storey_footprint);
                    test = pg.get_area() > 0.1;
                }
                if (test) {
                    space._has_roof[storey_id] = false;
                    space._indoor[storey_id] = false;
                }
            });
        });

        //*** Propagation */
        while (true) {
            var change = false;
            this.storeys.forEach(storey => change ||= this._propagate_indoor_spaces_horizontal(storey));
            this.storeys.forEach(storey => change ||= this._propagate_indoor_spaces_vertical(storey));
            if (!change) break;
        }
    }

    /**
     * Propagate indoor characteristic in storey
     * @param {cn_storey} storey
     * @returns {boolean} returns true if there was any change.
     */
    _propagate_indoor_spaces_horizontal(storey) {
        var overall_change = false;
        const storey_id = storey.ID;

        while (true) {
            var change = false;

            storey.scene.walls.filter(w => !w.indoor && w.spaces[0]._indoor[storey_id] != w.spaces[1]._indoor[storey_id]).forEach(w => {
                w.spaces[0]._indoor[storey_id] = false;
                w.spaces[1]._indoor[storey_id] = false;
                change = true;
            });

            if (!change) break;
            overall_change = true;
        }
        return overall_change;
    }

    /**
     * Propagate indoor characteristic in storey
     * @param {cn_storey} storey
     * @returns {boolean} returns true if there was any change.
     */
    _propagate_indoor_spaces_vertical(storey) {
        var overall_change = false;
        const storey_id = storey.ID;
        const storey_after = storey.get_next_storey();

        storey.scene.walls.filter(w => w.balcony && w.spaces[0]._has_roof[storey_id] != w.spaces[1]._has_roof[storey_id]).forEach(w => {
            w.spaces[0]._has_roof[storey_id] = false;
            w.spaces[1]._has_roof[storey_id] = false;
            w.spaces[0]._indoor[storey_id] = false;
            w.spaces[1]._indoor[storey_id] = false;
        });

        //*** propagation between storeys */
        if (storey_after) {
            const storey_after_id = storey_after.ID;
            storey_after.slabs.filter(slab => slab.pierced).forEach(slab => {
                if (slab.spaces[0] && slab.spaces[1] && slab.spaces[0]._indoor[storey_id] != slab.spaces[1]._indoor[storey_after_id]) {
                    slab.spaces[0]._indoor[storey_id] = false;
                    slab.spaces[1]._indoor[storey_after_id] = false;
                    overall_change = true;
                }
            });

            //*** Roof : needs all slabs to upper storey to have no roof */
            storey.scene.spaces.filter(sp => !sp.has_ceiling && sp._has_roof[storey_id] && !sp._indoor[storey_id]).forEach(sp => {
                if (!storey_after.slabs.filter(slab => slab.spaces[0] == sp).some(slab => slab.spaces[1]._has_roof[storey_after_id]))
                    sp._has_roof[storey_id] = false;
            })
        }

        return overall_change;
    }

}

