<div>
    <div class="m-3">
        <verification-card
            *ngIf="typeMesurage === TypePrestationEnum.POLLUANT_VISITE_CHANTIER"
            [isInEditMode]="isInEditMode"
            [isValidated]="isValidated"
            (onCancel)="cancelModification()"
            (onSave)="saveModification()"
            (onValidate)="validateTab()"
            (onEdit)="startModification()"
            [form]="formCompetences"
        >
            Veuillez vérifier les compétences
        </verification-card>
    </div>
    <div class="container">
        <mat-card class="p-2 mt-2 d-flex flex-column justify-content-between" [formGroup]="formCompetences">
            <mat-card-content>
                <div class="d-flex flex-column p-2">
                    <div class="col-xl-12 m-0 mb-3 primary">
                        <p>
                            Liste des compétences et/ou qualifications particulières pour réaliser la visite et/ou les
                            prélèvements :
                        </p>
                    </div>
                    <ng-container *ngFor="let key of COMPETENCES_KEY">
                        <mat-checkbox
                            [disabled]="isReadOnly"
                            formControlName="{{ key }}"
                            class="ml-5"
                            [class.readonly]="!isInEditMode"
                            >{{ COMPETENCES[key] }}
                        </mat-checkbox>
                    </ng-container>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
