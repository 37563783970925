import { Injectable } from '@angular/core';
import { AgenceService } from './agence.service';
import { CommandeService } from './commande.service';
import { InterventionService } from './intervention.service';
import { AgenceMinimal } from '../model/agence.model';
import { Document } from '../model/document.model';
import { EtatIntervention, Intervention, PrestationDiagnostic } from '../model/intervention.model';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { DocumentsService } from './documents.service';
import { filterByCurrentDate } from '../utils/assurance-utils';
import { Cofrac } from '../model/cofrac.model';
import { MongoUtils } from 'src/app/commons-lib';
import { TypeDocumentCheckpoint } from '../model/type-document.model';
import { Conformite } from '../model/conformite.model';
import { UserInformationApiService } from './user-information-api.service';
import { combineLatestOrEmpty } from '../utils/rxjs.utils';
import { Competence } from '../model/user-wizy.model';
import { InterventionFileService } from './intervention-file.service';
import { SynchronizationService } from './synchronization.service';
import { SyncState } from './syncState';

function sameTypeDocument(doc: Document) {
    return (it) => doc.typeDocument.id === it.typeDocument.id;
}

function mergePointsControle(typeDocumentCheckpoint: TypeDocumentCheckpoint[], checkPointsToBeMerged: TypeDocumentCheckpoint[]) {
    const newCheckPoints = checkPointsToBeMerged.filter(
        (it) => !typeDocumentCheckpoint.some((it2) => it2.referencePrestation.id === it.referencePrestation.id)
    );
    return typeDocumentCheckpoint
        .map((it) => {
            const checkpointToBeMerged = checkPointsToBeMerged.find((it2) => it2.referencePrestation.id === it.referencePrestation.id);
            let newCheckpoints = [];
            if (checkpointToBeMerged) {
                const existingCheckpoints = it.checkpoints;
                newCheckpoints = checkpointToBeMerged.checkpoints.map((foo) => {
                    const retainThis = existingCheckpoints.find((eC) => eC.id === foo.id);
                    return retainThis ? retainThis : foo;
                });
            }
            it.checkpoints = newCheckpoints;
            return it;
        })
        .concat(newCheckPoints);
}

/**
 * Filtrer une liste de compétences et leurs prestataires. Ne retenir qu'une compétences par type de compétence,
 * en priorité celle du responsable. Si pas de responsable pour un type de compétence on prend la première compétence dans la liste.
 * @param certificatsPerPrestataire
 */
function filterByCompentenceTypeAndResponsable(
    certificatsPerPrestataire: {
        prestataire: { id: string; responsable?: boolean };
        competences: Competence[];
    }[]
): Competence[] {
    return certificatsPerPrestataire
        .map((c) =>
            c.competences
                .filter((competence) => !!competence.competenceDocument?.typeDocument)
                .map((competence) => ({
                    competence,
                    responsable: c.prestataire.responsable,
                    idTypeDocument: competence.competenceDocument.typeDocument.id,
                }))
        )
        .flat(1)
        .reduce((previous, c) => {
            const existingIndex = previous.findIndex((p) => p.idTypeDocument === c.idTypeDocument);
            let nonExistant = existingIndex < 0;
            if (nonExistant || c.responsable) {
                if (nonExistant) {
                    previous.push(c);
                } else {
                    previous.splice(existingIndex, 1, c);
                }
            }
            return previous;
        }, [] as { responsable: boolean; idTypeDocument: string; competence: Competence }[])
        .map((c) => c.competence);
}

@Injectable({
    providedIn: 'root',
})
export class RefreshDocumentsService {
    constructor(
        private agenceService: AgenceService,
        private commandeService: CommandeService,
        private documentsService: DocumentsService,
        private interventionService: InterventionService,
        private userInformationApiService: UserInformationApiService,
        private interventionFileService: InterventionFileService,
        private readonly synchronizationService: SynchronizationService
    ) {}

    /**
     * Rafraichie la liste des documents en entrée
     * @param existingDocuments
     * @param request
     */
    refreshDocuments(
        existingDocuments: Document[],
        request: {
            agence?: AgenceMinimal;
            commandeId?: string;
            prestationsDiagnostic?: PrestationDiagnostic[];
            prestataires: { id: string; responsable?: boolean }[];
            idIntervention: string;
        }
    ): Observable<Document[]> {
        return of(existingDocuments).pipe(
            switchMap((exisDocuments) => this.refreshPrestations(exisDocuments, request.prestationsDiagnostic)),
            switchMap((documents) => this.refreshCommande(documents, request.commandeId)),
            switchMap((documents) => this.refreshAgence(documents, request.agence)),
            switchMap((documents) => this.refreshCompetences(documents, request.prestataires)),
            switchMap((documents) =>
                this.interventionFileService.assureDocumentAccess({ id: request.idIntervention }, documents).pipe(map(() => documents))
            )
        );
    }

    /**
     * Rafraichie les documents d'une intervention non démarée, en cours ou en attente, si on est en ligne
     * @param intervention
     */
    refreshDocumentsForIntervention(intervention: Intervention): Observable<Intervention> {
        return this.synchronizationService.getSyncState().pipe(
            switchMap((state) => {
                if (
                    state == SyncState.Online &&
                    [EtatIntervention.NON_DEMARREE, EtatIntervention.EN_COURS, EtatIntervention.EN_ATTENTE].includes(intervention.etat)
                ) {
                    return combineLatestOrEmpty([
                        of(intervention),
                        this.refreshDocuments(intervention.documents, {
                            agence: intervention.agence,
                            commandeId: intervention.idCommande,
                            prestationsDiagnostic: intervention.prestationsDiagnostics,
                            prestataires: intervention.prestataires,
                            idIntervention: intervention.id,
                        }).pipe(take(1)),
                    ]);
                } else {
                    return combineLatest([of(intervention), of([])]);
                }
            }),
            switchMap(([inter, documents]) => {
                // Si les documents ont été rafraichis, on maj les documents de l'intervention
                if (documents.length) {
                    intervention.documents = documents;
                    return this.interventionService.updateIntervention(inter);
                } else {
                    return of(inter);
                }
            }),
            tap(() => this.interventionService.reloadCurrentIntervention()),
            take(1)
        );
    }

    private refreshPrestations(existingDocuments: Document[], prestationsDiagnostic: PrestationDiagnostic[]) {
        if (prestationsDiagnostic) {
            return this.interventionService.getDefaultDocuments(prestationsDiagnostic).pipe(
                map((defaultDocuments: Document[]) => {
                    // Des documents par défaut on ne garde que ceux qui ne se trouvent pas déjà dans la liste des existants.
                    const defaultDocumentsToKeep = defaultDocuments.filter((it) =>
                        RefreshDocumentsService.isNotInExistingDocuments(it, existingDocuments)
                    );
                    const refreshedExistingDocuments = existingDocuments.map((existingDoc) => {
                        const defaultDoc = defaultDocuments.find((it) => it.typeDocument.code === existingDoc.typeDocument.code);
                        if (defaultDoc) {
                            // on merge les points de contrôles.
                            existingDoc.typeDocument.typeDocumentCheckpoint = mergePointsControle(
                                existingDoc.typeDocument.typeDocumentCheckpoint,
                                defaultDoc.typeDocument.typeDocumentCheckpoint
                            );
                            existingDoc.conformite = Conformite.NON_CONFORME;
                            existingDoc.requiredForIntervention = defaultDoc.requiredForIntervention;
                            existingDoc.isEditable = defaultDoc.isEditable;
                            existingDoc.typePrestations = defaultDoc.typePrestations;
                        } else {
                            // il ne s'agit plus d'un document par défaut on le passe en non requis et éditable.
                            existingDoc.isEditable = true;
                            existingDoc.requiredForIntervention = false;
                        }
                        return existingDoc;
                    });
                    return refreshedExistingDocuments.concat(defaultDocumentsToKeep);
                })
            );
        } else {
            return of(existingDocuments);
        }
    }

    /**
     * Déterminer si le document en entrée n'est pas dans la liste fourni en 2e paramètre
     * @param doc
     * @param documents
     * @private
     */
    private static isNotInExistingDocuments(doc: Document, documents: Document[]) {
        return !documents.some(sameTypeDocument(doc));
    }

    private static refreshFromExisting(it: Document, documents: Document[]) {
        // TODO voir ce qu'il faut raffraichir
        return it;
    }

    private refreshCommande(documents: Document[], commandeId: string) {
        if (commandeId && documents.some((it) => it.typeDocument.code === 'COMMANDE')) {
            return this.commandeService.findOneCommande(commandeId).pipe(
                switchMap((currentCommande) => {
                    if (currentCommande && currentCommande.documentBonCommande) {
                        return this.documentsService.findOneDocument(currentCommande.documentBonCommande.id).pipe(
                            map((bonDeCommande) =>
                                // On insère le bon de commande uniquement s'il figure dans la liste des documents.
                                documents.map((it) =>
                                    it.id === bonDeCommande.id || it.typeDocument.code === 'COMMANDE' ? this.mergeWithExisting(bonDeCommande, it) : it
                                )
                            )
                        );
                    } else {
                        return of(documents);
                    }
                })
            );
        }
        return of(documents);
    }

    private refreshAgence(documents: Document[], agence: AgenceMinimal) {
        if (agence && agence.id) {
            // les types de documents d'agence
            const agenceDocTypeCodes = ['ATTESTATION_HONNEUR', 'ASSURANCE', 'DOCUMENT_COFRAC'];
            // on détermine les types de documents d'agence existant
            const agenceDocs = documents.filter((it) => agenceDocTypeCodes.indexOf(it.typeDocument.code) > -1).map((it) => it.typeDocument.code);
            const needsAgenceRefresh = !!agenceDocs.length;
            // on ne raffraichi uniquement s'il y a des docs d'agence déjà présent
            if (needsAgenceRefresh) {
                return this.agenceService.findOne(agence.id).pipe(
                    // on crée une liste de tous les docs d'agence, en fonction des dates de validité
                    map((currentAgence) =>
                        filterByCurrentDate(currentAgence.assurances, 'dateDebutValidite', 'dateEcheance')
                            .map((it) => it.justificatifDocument)
                            .concat([currentAgence.attestationHonneurDocument])
                            .concat(
                                filterByCurrentDate<Cofrac>(currentAgence.cofracs, 'dateDebutValidite', 'dateEcheance').map((it) => it.documentCofrac)
                            )
                            .filter((it) => !!it)
                    ),
                    // on ne retient que ceux dont le type était déjà présent
                    map((it) => it.filter((it) => agenceDocs.indexOf(it.typeDocument.code) > -1)),
                    // on merge cette liste avec la liste existante en remplaçant les docs existants.
                    map((docsFromAgence) =>
                        documents
                            .filter((it) => !docsFromAgence.some((d) => d.typeDocument.code === it.typeDocument.code))
                            .concat(
                                docsFromAgence
                                    .map((it) => this.mergeWithExisting(it, this.findExisting(it, documents)))
                                    .map((it) => {
                                        it.id = MongoUtils.generateObjectId();
                                        return it;
                                    })
                            )
                    )
                );
            }
        }
        return of(documents);
    }

    private refreshCompetences(documents: Document[], prestataires: { id: string; responsable?: boolean }[]) {
        if (prestataires) {
            return combineLatestOrEmpty(
                prestataires.map((prestataire) =>
                    this.userInformationApiService.findCompetencesByIdUser(prestataire.id).pipe(
                        map((competences) => filterByCurrentDate(competences, 'dateObtention', 'dateFin')),
                        map((competences) => ({ prestataire, competences }))
                    )
                )
            ).pipe(
                map(
                    (
                        certificatsPerPrestataire: {
                            prestataire: { id: string; responsable?: boolean };
                            competences: Competence[];
                        }[]
                    ) => filterByCompentenceTypeAndResponsable(certificatsPerPrestataire)
                ),
                map((competences: Competence[]) => competences.map((it) => it.competenceDocument).filter((it) => !!it)),
                map((certificats) => {
                    const certificatsTypes = certificats.map((it) => it.typeDocument);
                    const filteredDocuments = documents.filter((it) => !certificatsTypes.some((type) => type.id === it.typeDocument.id));
                    return filteredDocuments.concat(
                        certificats
                            .filter((it) => documents.some((doc) => doc.typeDocument.id === it.typeDocument.id))
                            .map((it) => this.mergeWithExisting(it, this.findExisting(it, documents)))
                    );
                })
            );
        } else {
            return of(documents);
        }
    }

    private mergeWithExisting(doc: Document, it: Document) {
        const toBeMerged = {
            idFichier: doc.idFichier,
            nomFichier: doc.nomFichier,
            nom: doc.nom,
        };
        return { ...it, ...toBeMerged };
    }

    private findExisting(doc: Document, documents: Document[]) {
        let existingDoc = documents.find((it) => it.typeDocument.code === doc.typeDocument.code);
        return existingDoc || doc;
    }
}
