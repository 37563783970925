import { ElementLibrary, ILibraryHandler, LibraryItem } from '@acenv/cnmap-angular-editor-lib';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ReferenceService } from '../../../../../services/reference.service';

@Injectable({
    providedIn: 'root',
})
export class LibraryHandler implements ILibraryHandler {
    constructor(private http: HttpClient, private readonly referenceService: ReferenceService) {}

    addItemIntoLibraries(libraryIds: string[], item: LibraryItem): Observable<boolean> {
        return of(false);
    }

    getLibraries(): Observable<ElementLibrary[]> {
        return of([]);
    }

    isLibraryDisplayed(): boolean {
        return false;
    }

    navigateToLibraries(): any {}
}
