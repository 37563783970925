import { Component, Input, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Diagnostic } from '../../../model/diagnostic.model';
import { BaseComponent } from 'src/app/commons-lib';
import { ProgressDiagnosticService } from '../../../services/progress-diagnostic.service';
import { takeUntil } from 'rxjs/operators';
import { DiagnosticHandlerService } from '../../../services/diagnostic-handler.service';

@Component({
    selector: 'app-progress-diagnostic',
    templateUrl: './progress-diagnostic.component.html',
    styleUrls: ['./progress-diagnostic.component.scss'],
})
export class ProgressDiagnosticComponent {
    progress$ = this.progressDiagnosticService.progress$;

    constructor(private progressDiagnosticService: ProgressDiagnosticService) {}
}
