import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LibFileUploaderModule, LibIconModule } from 'src/app/commons-lib';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { GestionMateriauxComponent } from './gestion-materiaux.component';
import { CreationMateriauxComponent } from './creation-materiaux/creation-materiaux.component';
import { PipesModule } from '../../../../pipes/pipes.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CnDiagSharedModule } from '../../../shared/shared.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TreeModule } from '@circlon/angular-tree-component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { TableauMateriauxComponent } from './tableau-materiaux/tableau-materiaux.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
    declarations: [GestionMateriauxComponent, CreationMateriauxComponent, TableauMateriauxComponent],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatSelectModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        LibIconModule,
        MatButtonModule,
        MatExpansionModule,
        MatIconModule,
        ReactiveFormsModule,
        MatButtonToggleModule,
        PipesModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        CnDiagSharedModule,
        FormsModule,
        MatMenuModule,
        MatProgressBarModule,
        TreeModule,
        LibFileUploaderModule,
    ],
})
export class GestionMateriauxModule {}
