import { Pipe, PipeTransform } from '@angular/core';
import { EtatWorkflow, etatWorkflowToLabel } from '../model/etat-workflow.model';

/**
 * Affiche le label de l'état d'un element divers
 */
@Pipe({
    name: 'etatWorkflow',
})
export class EtatWorkflowPipe implements PipeTransform {
    constructor() {}

    transform(etat: EtatWorkflow): string {
        return etatWorkflowToLabel(etat);
    }
}
