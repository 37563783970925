import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { Bien } from '../../../../model/bien.model';
import { InterventionService } from '../../../../services/intervention.service';
import { BaseComponent, ConfirmationService } from 'src/app/commons-lib';
import { BienService } from '../../../../services/bien.service';
import { Intervention } from '../../../../model/intervention.model';
import { Diagnostic } from '../../../../model/diagnostic.model';
import { DiagnosticService } from '../../../../services/diagnostic.service';
import { DiagnosticHandlerService } from '../../../../services/diagnostic-handler.service';

class BienModalData {
    constructor(
        public bien: Bien,
        public intervention: Intervention,
        public isCreation: boolean,
        public isPrincipal: boolean,
        public isAdminMode: boolean = false
    ) {}
}

export class BienModalResult {
    constructor(public bien: Bien, public saved: boolean = false, public deleted: boolean = false) {}
}

@Component({
    selector: 'app-edit-bien-modal',
    templateUrl: './edit-bien-modal.component.html',
    styleUrls: ['./edit-bien-modal.component.scss'],
})
export class EditBienModalComponent extends BaseComponent implements OnInit {
    bien: Bien;
    intervention: Intervention;
    formInfo: FormGroup;
    modeCreate: boolean;
    btSaveLabel: string;
    isPrincipal: boolean;
    isEditMode: boolean;
    isAdminMode: boolean;

    constructor(
        private dialogRef: MatDialogRef<EditBienModalComponent>,
        private interventionService: InterventionService,
        private diagnosticService: DiagnosticService,
        private diagnosticHandlerService: DiagnosticHandlerService,
        private confirmationService: ConfirmationService,
        private bienService: BienService,
        @Inject(MAT_DIALOG_DATA) public data: BienModalData
    ) {
        super();
    }

    ngOnInit(): void {
        // this.interventionService.setBienEditMode(true);
        this.isEditMode = true;
        this.bien = this.data.bien;
        this.intervention = this.data.intervention;
        this.modeCreate = this.data.isCreation === undefined ? true : this.data.isCreation;
        this.btSaveLabel = this.modeCreate ? 'Créer' : 'Modifier';
        this.formInfo = this.bienService.initBienForm();
        this.isPrincipal = this.data.isPrincipal;
        this.isAdminMode = this.data.isAdminMode;
    }

    save() {
        // this.interventionService.setBienEditMode(false);
        this.isEditMode = false;
        this.bienService.updateBienWithForm(this.bien, this.formInfo);
        this.dialogRef.close(new BienModalResult(this.bien, true));
    }

    cancel() {
        // this.interventionService.setBienEditMode(false);
        this.isEditMode = false;
        this.dialogRef.close(false);
    }

    /**
     * Suppression du bien annexe courant, ainsi que les niveaux, pièces et équipements associés.
     * Vérification si le bien a déjà été complété dans les points de contrôle d'un diagnostic
     */
    confirmDelete() {
        let msg = 'Êtes-vous sûr de vouloir supprimer ce bien annexe ?';
        let isAlreadyUsed = false;
        this.diagnosticService.getAllDiagnosticsForCurrentIntervention(this.intervention).subscribe((diagnostics: Diagnostic[]) => {
            if (diagnostics) {
                for (const diagnostic of diagnostics) {
                    const typePrestationService = this.diagnosticHandlerService.getTypePrestationService(diagnostic.typePrestation);
                    if (typePrestationService) {
                        isAlreadyUsed = typePrestationService.isItemAlreadyFilled(diagnostic, 'bien', this.bien.id);
                    }
                    if (isAlreadyUsed) {
                        break;
                    }
                }
                if (isAlreadyUsed) {
                    msg =
                        '<div class="warn">Attention, ce bien annexe a déjà été renseigné dans un diagnostic.</div>Êtes-vous sûr de vouloir le supprimer ?';
                }
            }
            this.confirmationService.confirmWarn(msg, () => {
                this.dialogRef.close(new BienModalResult(this.bien, false, true));
            });
        });
    }
}
