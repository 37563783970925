import { MongoUtils } from 'src/app/commons-lib';
import { Perimetre } from './perimetre.model';

export class Espace {
    id: string = MongoUtils.generateObjectId();
    nom: string;
    idBien: string;
    indexNiveau: number;
    backgroundFileId: string;
    listePerimetres: Perimetre[] = [];
    listeMarkersJson: string[] = [];
    isBesoinValid = false;
    isPerimetreValid = false;
    idTemplateDescription: string;
    description: string = '';
    listeVolumeId: string[] = [];
    idPerimetre: string = '';

    constructor(props?) {
        // this.nom = props?.nom;
        // this.idBien = props?.idBien;
        // this.indexNiveau = props?.indexNiveau;
        // this.backgroundFileId = props?.backgroundFileId;
    }
}
