<div class="h-100 d-flex">
    <div class="row no-gutters flex-grow-1">
        <!-- Colonne gauche-->
        <div class="col-xl-6 px-4 py-2 white-background h-100 scrollable-content">
            <!-- Barre de recherche-->
            <div class="input-with-prefix">
                <mat-form-field class="w-100">
                    <input
                        placeholder="Rechercher"
                        matInput
                        type="text"
                        [(ngModel)]="searchword"
                        (input)="onInputSearch()"
                    />
                    <lib-icon matPrefix>search</lib-icon>
                </mat-form-field>
            </div>

            <!-- Liste des chips pour filtrer les commentaires prédéfinis -->
            <app-list-chips-clickable
                [chipsToDisplayed]="listCategories"
                [chipsSelected]="listCategoriesSelected"
                [pathValueDisplay]="['name']"
                (chipsClicked)="onClickChipsCategorie($event)"
            ></app-list-chips-clickable>

            <!-- Liste de suggestion de commentaires prédéfinis -->
            <div class="mt-2" [hidden]="!commentairesGenerauxFiltres.length">
                <div class="mat-h3 mb-2 primary">
                    Sélectionner une {{ typeCommentaire | typeCommentaire | lowercase }}
                    prédéfinie
                </div>
                <div *ngFor="let recom of commentairesGenerauxFiltres" class="d-flex align-items-center">
                    <mat-card class="px-2 py-2 mb-2 d-flex align-items-center text-justify flex-grow-1">
                        <div class="flex-grow-1 comments">{{ recom.contenu }}</div>
                    </mat-card>
                    <button
                        mat-icon-button
                        class="mb-2"
                        color="primary"
                        [disabled]="readonlyMode"
                        (click)="onClickBtnAddComment(recom)"
                    >
                        <mat-icon>add_circle</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <!-- Colonne droite-->
        <div class="col-xl-6 px-4 py-2 h-100">
            <div class="mat-h3 mb-2 primary">Sélection finale pour le rapport</div>
            <div class="scrollable-content height-list-commentaires">
                <div *ngFor="let recom of commentairesFinaux; let first = first; let last = last" class="d-flex">
                    <mat-card class="px-2 py-2 mb-2 d-flex align-items-center text-justify flex-grow-1 minh-100">
                        <div class="flex-grow-1 comments">{{ recom.contenu }}</div>
                    </mat-card>
                    <div class="d-flex flex-column mb-2">
                        <!-- Faire monter le commentaire -->
                        <button
                            mat-icon-button
                            color="primary"
                            [disabled]="readonlyMode || first"
                            (click)="onClickBtnMoveCommentUp(recom)"
                        >
                            <mat-icon>arrow_upward</mat-icon>
                        </button>
                        <!-- Supprimer le commentaire -->
                        <button
                            mat-icon-button
                            color="warn"
                            [disabled]="readonlyMode"
                            (click)="onClickBtnDeleteComment(recom)"
                        >
                            <mat-icon>remove_circle</mat-icon>
                        </button>
                        <!-- Éditer le commentaire -->
                        <button
                            mat-icon-button
                            color="primary"
                            [disabled]="readonlyMode"
                            (click)="onClickBtnEditComment(recom)"
                        >
                            <mat-icon>edit</mat-icon>
                        </button>
                        <!-- Faire descendre le commentaire -->
                        <button
                            mat-icon-button
                            color="primary"
                            [disabled]="readonlyMode || last"
                            (click)="onClickBtnMoveCommentDown(recom)"
                        >
                            <mat-icon>arrow_downward</mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div class="div-fixed">
                <div class="underlined my-2"></div>
                <!-- Saisi libre d'un commentaire -->
                <div class="input-with-prefix">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>{{ typeCommentaire | typeCommentaire }}</mat-label>
                        <textarea
                            matInput
                            cdkTextareaAutosize
                            cdkAutosizeMinRows="5"
                            cdkAutosizeMaxRows="5"
                            [disabled]="readonlyMode"
                            [(ngModel)]="freeComment"
                        ></textarea>
                    </mat-form-field>
                </div>
                <!-- Ajout du commentaire libre -->
                <div class="d-flex justify-content-end pb-2">
                    <button
                        mat-raised-button
                        color="accent"
                        [disabled]="freeComment.length === 0"
                        (click)="onClickBtnAddFreeTextComment()"
                    >
                        <lib-icon>check</lib-icon>
                        <span>{{ 'Ajouter' | uppercase }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
