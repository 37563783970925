import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-piscine-privee',
    templateUrl: './piscine-privee.component.html',
    styleUrls: ['./piscine-privee.component.scss'],
})
export class PiscinePriveeComponent implements OnInit {
    readonly MENU_NAME = 'Piscine privée';

    constructor() {}

    ngOnInit(): void {}
}
