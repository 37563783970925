import { Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CalculatriceModaleComponent } from '../calculatrice-modale/calculatrice-modale.component';

@Component({
    selector: 'app-increment-input-reactive',
    templateUrl: './increment-input-reactive.component.html',
    styleUrls: ['./increment-input-reactive.component.scss'],
})
export class IncrementInputReactiveComponent implements ControlValueAccessor {
    private _value: number;

    @Input()
    set value(value: number) {
        this.setValue(value, false);
    }

    @Input()
    noButtons: Boolean = false;

    get value() {
        return this._value;
    }

    private _step = 1;

    @Input()
    set step(_step: number) {
        this._step = this.parseNumber(_step);
        this.nbDecimals = this.countDecimals(_step);
    }

    get step(): number {
        return this._step;
    }

    private _min = 0;

    @Input()
    set min(value: number) {
        if (value != null && !isNaN(value)) this._min = value;
    }

    get min(): number {
        return this._min;
    }

    private _max = Infinity;

    @Input()
    set max(value: number) {
        if (value != null && !isNaN(value)) this._max = value;
    }

    get max(): number {
        return this._max;
    }

    @Input()
    label = '';

    @Input()
    disabled = false;

    @Input()
    readonly = false;

    @Input()
    required = false;

    @Input()
    fullWidth = false;

    @Input()
    matFormFieldClass = '';

    /**
     * Si on passe par reactive form
     */
    @Input()
    incrementControl: FormControl | AbstractControl;

    /**
     * Afficher le mat-error
     * Facultatif
     */
    @Input()
    showMatError = true;

    @Input()
    fromZero = false;

    @Input()
    style: string = 'horizontal';

    /**
     * Changement de la valeur par utilisateur (non émit si le changement vient de l'@Input)
     */
    @Output()
    valueChangeFromUser = new EventEmitter<number>();

    /**
     * Changement du modèle (utilisateur ou non)
     */
    @Output()
    valueChange = new EventEmitter<number>();

    /**
     * Afficher ou non le le clavier numérique lorsque l'input est cliqué
     * False par défaut
     */
    @Input()
    numericKeyboard: Boolean = false;

    private nbDecimals = 0;

    constructor(
        @Self()
        @Optional()
        private ngControl: NgControl,
        private matDialog: MatDialog
    ) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    decrementValue(): void {
        if (this._value == null) {
            if (this.fromZero) {
                this.setValueFromUser(0);
            } else {
                this.setValueFromUser(this._max);
            }
            return;
        }
        if (this._value == this._min) return;
        if (this._value < this._min) {
            this.setValueFromUser(this._min);
            return;
        }
        if (this._value > this._max) {
            this.setValueFromUser(this._max);
            return;
        }
        this.setValueFromUser(parseFloat((this._value - this._step).toFixed(this.nbDecimals)));
    }

    incrementValue(): void {
        if (this._value == null) {
            if (this.fromZero) {
                this.setValueFromUser(0);
            } else {
                this.setValueFromUser(this._min);
            }
            return;
        }
        if (this._value >= this._max) return;
        if (this._value < this._min) {
            this.setValueFromUser(this._min);
            return;
        }
        if (this._value > this._max) {
            this.setValueFromUser(this._max);
            return;
        }
        this.setValueFromUser(parseFloat((this._value + this._step).toFixed(this.nbDecimals)));
    }

    inputManuallyChanged(event) {
        this.setValue(event.target.value, false);
        // Changement de l'utilisateur directement dans l'input
        // => il faut forcer l'émission de l'output indiquant un changement manuel (car "setValue" est appelé avec fromUser=false dans ce cas)
        this.valueChangeFromUser.emit(this._value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {}

    writeValue(obj: any): void {
        this._value = obj;
    }

    onChange(value) {}

    private onTouched() {}

    private setValueFromUser(value: number) {
        this.setValue(value, true);
    }

    private setValue(value: number, fromUser: boolean) {
        if (value == null || isNaN(value)) {
            return;
        }
        let changed = false;
        if (this._value != value) {
            changed = true;
        }
        this._value = this.parseNumber(value);
        if (changed) {
            this.onChange(this._value);
            this.valueChange.emit(this._value);
            if (fromUser) {
                this.onChange(this._value);
                this.valueChangeFromUser.emit(this._value);
            }
        }
    }

    private parseNumber(num: any): number {
        return +num;
    }

    private countDecimals(value) {
        if (Math.floor(value) === value) return 0;
        return value.toString().split('.')[1].length || 0;
    }

    /**
     * Mode saisie
     */
    openNumKeyboard() {
        return this.matDialog
            .open(CalculatriceModaleComponent, {
                data: {
                    mode: 'saisie',
                    originalValue: this.value,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.setValue(result.resultat, true);
                }
            });
    }
}
