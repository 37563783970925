"use strict";
//**************************************************************
//**************************************************************
/*
----- FHTAGN Project ---------------------------------------

Copyright(C) 2015-2019 EnerBIM

------------------------------------------------------------
*/
//**************************************************************
//**************************************************************
//***** polygon operations
//**************************************************************
//**************************************************************

import { fh_add, fh_build_axis, fh_clone, fh_cross, fh_dist, fh_dot, fh_mul, fh_normalize, fh_size, fh_sub } from "./fh_vector";
import earcut from "earcut";
import { Clipper, ClipperOffset, ClipType, EndType, IntPoint, Path, Paths, PolyFillType, PolyTree, PolyType } from "js-clipper";
import { fh_box } from "./fh_box";


export class fh_polygon {
    constructor(point = null, normal = null) {
        //*** plane data
        this._plane_defined = false;
        this._point = [0, 0, 0];
        this._normal = [0, 0, 1];
        this._dx = [1, 0, 0];
        this._dy = [0, 1, 0];
        if (point && normal) {
            this._point = fh_clone(point);
            this._normal = fh_clone(normal);
            fh_build_axis(this._normal, this._dx, this._dy);
            this._plane_defined = true;
        }

        this._tmp_vertices = [];
        this._tmp_sizes = [];
        this._contour_computed = false;
        this._tesselation_computed = false;
        this._bounding_box = null;

        //*** constant
        this._clipper_scale = 0.0001;

        //**** Ouput contours
        this.contour_sizes = [];
        this.contour_vertices = [];
        this.contour_orientations = [];
        this.contour_parents = [];
        this.area = 0;
        this.perimeter = 0;
        this.outer_perimeter = 0;

        //*** output tesselation
        this.tesselation_vertices = [];
        this.tesselation_triangles = [];
        this._contours_computed = null;
    }

    clear() {

        this._tmp_vertices = [];
        this._tmp_sizes = [];
        this._contour_computed = true;
        this._tesselation_computed = true;
        this._bounding_box = null;

        //**** Ouput contours
        this.contour_sizes = [];
        this.contour_vertices = [];
        this.contour_orientations = [];
        this.contour_parents = [];
        this.area = 0;
        this.perimeter = 0;
        this.outer_perimeter = 0;

        //*** output tesselation
        this.tesselation_vertices = [];
        this.tesselation_triangles = [];
    }

    //*********************************************
    /** clone */
    clone() {
        var other = new fh_polygon();

        other._plane_defined = this._plane_defined;
        other._point = fh_clone(this._point);
        other._normal = fh_clone(this._normal);
        other._dx = fh_clone(this._dx);
        other._dy = fh_clone(this._dy);

        other._tmp_vertices = JSON.parse(JSON.stringify(this._tmp_vertices));
        other._tmp_sizes = this._tmp_sizes.concat([]);
        other._contour_computed = this._contour_computed;
        other._tesselation_computed = this._tesselation_computed;
        other._bounding_box = (this._bounding_box == null) ? null : this._bounding_box.clone();

        //*** constant
        other._clipper_scale = this._clipper_scale;

        //**** Ouput contours
        other.contour_sizes = this.contour_sizes.concat([]);
        other.contour_vertices = JSON.parse(JSON.stringify(this.contour_vertices));
        other.contour_orientations = this.contour_orientations.concat([]);
        other.contour_parents = this.contour_parents.concat([]);
        other.area = this.area;
        other.perimeter = this.perimeter;
        other.outer_perimeter = this.outer_perimeter;

        //*** output tesselation
        other.tesselation_vertices = JSON.parse(JSON.stringify(this.tesselation_vertices));
        other.tesselation_triangles = this.tesselation_triangles.concat([]);
        return other;
    }

    //*********************************************
    /** serialize */
    serialize() {
        this.compute_contours();
        var json = {};
        json.normal = fh_clone(this._normal);
        json.point = fh_clone(this._point);
        json.vertices = [];
        json.contours = [];
        var offset = 0;
        for (var nct = 0; nct < this.contour_sizes.length; nct++) {
            var sz = this.contour_sizes[nct];
            json.contours.push(sz);
            for (var nv = 0; nv < sz; nv++) {
                json.vertices.push(fh_clone(this.contour_vertices[offset + nv]));
            }
            offset += sz;
        }
        return json;
    }

    //*********************************************
    /** unserialize */
    static unserialize(json) {
        if (typeof (json.point) != 'object') return null;
        if (typeof (json.normal) != 'object') return null;
        if (typeof (json.vertices) != 'object') return null;
        if (typeof (json.contours) != 'object') return null;

        var polygon = new fh_polygon(json.point, json.normal);
        var offset = 0;
        for (var nct = 0; nct < json.contours.length; nct++) {
            var contour = [];
            var sz = json.contours[nct];
            for (var nv = 0; nv < sz; nv++) {
                contour.push(fh_clone(json.vertices[offset + nv]));
            }
            polygon.add_contour(contour);
            offset += sz;
        }
        return polygon;
    }

    //*********************************************
    /** polygon plane */

    /** Sets one point of the polygon. If the polygon is not empty, this setting my translate the polygon so that it fits the new plane */
    set_point(point) {
        if (this._plane_defined)
            this.project(point, this._normal, this._normal);
        else
            this._point = point;
    }

    get_point() {
        this.compute_contours();
        return fh_clone(this._point);
    }

    /** Sets the normal of the polygon. If the polygon is not empty, this setting my project the polygon so that it fits the new plane */
    set_normal(normal) {
        this.project(this._point, normal, normal);
    }

    get_normal() {
        this.compute_contours();
        return fh_clone(this._normal);
    }

    get_area() {
        this.compute_contours();
        return this.area;
    }

    get_perimeter() {
        this.compute_contours();
        return this.perimeter;
    }

    get_outer_perimeter() {
        this.compute_contours();
        return this.outer_perimeter;
    }

    //**************************************************************
    //***** Apply matrix
    //**************************************************************
    apply_matrix(matrix) {
        var json = this.serialize();
        this.clear();
        this._plane_defined = false;

        var offset = 0;
        for (var nct = 0; nct < json.contours.length; nct++) {
            var contour = [];
            var sz = json.contours[nct];
            for (var nv = 0; nv < sz; nv++) {
                contour.push(matrix.transform_point(json.vertices[offset + nv]));
            }
            this.add_contour(contour);
            offset += sz;
        }
    }

    //*********************************************
    /** operations */

    compute_contours() {
        if (this._contour_computed) return;
        this._compute_contours();
        this._compute_area();
        this._contour_computed = true;
        this._tesselation_computed = false;
    }

    compute_tesselation() {
        this.compute_contours();
        if (this._tesselation_computed) return;
        this._compute_tesselation();
        this._tesselation_computed = true;
    }

    /** adds a contour to the polygon. If the contour is counter clockwise, the contour is filled. Otherwise it is a hole. */
    add_contour(overtices) {
        let vertices = [];
        overtices.forEach(v => {
            if (vertices.length == 0 || fh_dist(v, vertices[vertices.length - 1]) > 0.0001)
                vertices.push(v);
        });
        if (vertices.length > 2) {
            if (fh_dist(vertices[0], vertices[vertices.length - 1]) < 0.0001)
                vertices.splice(vertices.length - 1, 1);
        }
        if (vertices.length < 3) return;
        this._tmp_vertices = this._tmp_vertices.concat(vertices);
        this._tmp_sizes.push(vertices.length);
        this._contour_computed = false;
    }

    /** Unites another polygon. The other polygon is first projected into the polygon's plane */
    unites(other) {
        this.compute_contours();
        other.compute_contours();
        if (other.contour_sizes.length == 0)
            return this;

        this._operate(other, ClipType.ctUnion);
        this._compute_area();
        return this;
    }

    /** Substracts another polygon. The other polygon is first projected into the polygon's plane */
    substracts(other) {
        this.compute_contours();
        other.compute_contours();
        if (this.contour_sizes.length == 0)
            return this;
        if (other.contour_sizes.length == 0)
            return this;

        this._operate(other, ClipType.ctDifference);
        this._compute_area();
        return this;
    }

    /** replace with intersection of polygon with another polygon. The other polygon is first projected into the polygon's plane */
    intersects(other) {
        this.compute_contours();
        other.compute_contours();
        if (this.contour_sizes.length == 0)
            return this;
        if (other.contour_sizes.length == 0) {
            this.clear();
            return this;
        }
        this._operate(other, ClipType.ctIntersection);
        this._compute_area();
        return this;
    }

    /** reverse normal and contours */
    reverse() {
        this.compute_contours();

        this._normal = fh_mul(this._normal, -1.0);
        this._dy = fh_mul(this._dy, -1.0);
        var offset = 0;
        var vertices = [];
        for (var n = 0; n < this.contour_sizes.length; n++) {
            var sz = this.contour_sizes[n];
            for (var k = 0; k < sz; k++)
                vertices.push(this.contour_vertices[offset + sz - k - 1]);
            offset += sz;
        }
        this.contour_vertices = vertices;

        if (this._tesselation_computed) {
            for (var n = 0; n < this.tesselation_triangles.length; n += 3) {
                var tmp = this.tesselation_triangles[n + 1];
                this.tesselation_triangles[n + 1] = this.tesselation_triangles[n + 2];
                this.tesselation_triangles[n + 2] = tmp;
            }
        }
        return this;
    }

    /** Split to simple polygons with holes */
    split() {
        this.compute_contours();

        //*** are there several outer contours ?
        var nb_outer = 0;
        for (var i in this.contour_orientations) {
            if (this.contour_orientations[i]) nb_outer++;
        }
        if (nb_outer < 2) return [this];

        //*** build one polygon for each outer contour
        var polygons = [];
        for (var i in this.contour_orientations) {
            if (!this.contour_orientations[i]) continue;

            var pg = new fh_polygon(this._point, this._normal);
            polygons.push(pg);
            pg._contours_computed = true;
            pg._add_contour(this, i, -1);

            for (var j in this.contour_orientations) {
                if (this.contour_orientations[j]) continue;
                if (this.contour_parents[j] != i) continue;
                pg._add_contour(this, j, i);
            }
        }
        return polygons;
    }

    /** Turns to a list of 2d contours */
    to_2d_contours() {
        this.compute_contours();
        var contours = [];
        var offset = 0;
        for (var i in this.contour_sizes) {
            var sz = this.contour_sizes[i];
            var ctr = [];
            contours.push(ctr);
            for (var k = 0; k < sz; k++) {
                var vtx = this.contour_vertices[offset + k];
                ctr.push([vtx[0], vtx[1]]);
            }
            offset += sz;
        }
        return contours;
    }

    //*********************************************
    /** returns a single contour that matches the polygon */
    //*********************************************
    to_single_contour() {
        this.compute_contours();
        if (this.contour_sizes.length == 1) return this.contour_vertices;
        this.compute_tesselation();

        var half_edges = [];
        for (var i = 0; i < this.tesselation_triangles.length; i += 3) {
            for (var k = 0; k < 3; k++) {
                var v0 = this.tesselation_triangles[i + k];
                var v1 = this.tesselation_triangles[i + (k + 1) % 3];
                var he = { v0: v0, v1: v1, other: null, contour: null };
                while (v0 >= half_edges.length)
                    half_edges.push([]);
                half_edges[v0].push(he);
                if (typeof (half_edges[v1]) == 'object') {
                    var he_list = half_edges[v1];
                    for (var n in he_list) {
                        if (he_list[n].v1 == v0) {
                            he_list[n].other = he;
                            he.other = he_list[n];
                            break;
                        }
                    }
                }
            }
        }

        //**** Build contours */
        var contours = [];
        while (true) {
            //*** search for the start of a contour */
            var start = null;
            for (var k = 0; k < half_edges.length; k++) {
                var he_list = half_edges[k];
                // @ts-ignore
                for (var i in he_list) {
                    if (he_list[i].other || he_list[i].contour) continue;
                    start = he_list[i];
                    he_list.splice(i, 1);
                    break;
                }
                if (start) break;
            }
            if (start == null) break;

            //*** Build new contour */
            var contour = [start];
            contours.push(contour);
            start.contour = contour;

            //*** Follow contour */
            while (true) {
                var v1 = contour[contour.length - 1].v1;
                if (typeof (half_edges[v1]) == 'undefined') {
                    console.log("no edges starting here...");
                    return [];
                }
                var he_list = half_edges[v1];
                var next = null;
                // @ts-ignore
                for (var k in he_list) {
                    if (he_list[k].other) continue;
                    if (he_list[k].contour) continue;
                    next = he_list[k];
                    he_list.splice(k, 1);
                    break;
                }
                if (next == null) {
                    console.log("no edges matching...");
                    return [];
                }
                contour.push(next);
                next.contour = contour;
                if (next.v1 == contour[0].v0) break;
            }
        }

        var contours_by_vertices = [];
        // @ts-ignore
        for (var n = 0; n < this.tesselation_vertices.length; n++)
            contours_by_vertices.push(null);

        function mark_contour(ctr) {
            var sz = ctr.length;
            for (var i = 0; i < sz; i++) {
                contours_by_vertices[ctr[i].v0] = ctr;
            }
        }

        for (var k = 0; k < contours.length; k++)
            mark_contour(contours[k]);

        // @ts-ignore
        half_edges = half_edges.flat();

        //*** merge contours */
        while (contours.length > 1) {
            //*** Search for a junction between 2 contours */
            var junction = null;
            for (var k = 0; k < half_edges.length; k++) {
                var he2 = half_edges[k];
                var c0 = contours_by_vertices[he2.v0];
                var c1 = contours_by_vertices[he2.v1];
                if (c0 == null || c1 == null || c0 == c1) continue;
                junction = he2;
                break;
            }

            if (junction == null) {
                console.log("more than 1 contours left...");
                return [];
            }

            var new_contour = [];
            for (var niter = 0; niter < 2; niter++) {
                //*** Adds appropriate half edge */
                var vertex = null;
                if (niter == 0) {
                    new_contour.push(junction.other);
                    vertex = junction.v0;
                }
                else {
                    new_contour.push(junction);
                    vertex = junction.v1;
                }

                //*** Append contour at the right place */
                var contour2 = contours_by_vertices[vertex];
                var sz = contour2.length;
                // @ts-ignore
                for (var n = 0; n < sz; n++) {
                    if (contour2[n].v0 != vertex) continue;
                    for (var k = 0; k < sz; k++) {
                        // @ts-ignore
                        new_contour.push(contour2[(n + k) % sz]);
                    }
                    break;
                }

                //*** remove contour */
                var index = contours.indexOf(contour2);
                if (index >= 0) contours.splice(index, 1);
            }
            mark_contour(new_contour);
            contours.push(new_contour);
        }

        //*** Build final contour */
        var vertices = [];
        // @ts-ignore
        for (var i in contours[0]) {
            vertices.push(this.tesselation_vertices[contours[0][i].v0]);
        }
        return vertices;
    }

    //*********************************************
    /** Returns highest inner diameter (i.e. highest diameter of inner circle of tesselation triangles) */
    //*********************************************
    compute_inner_diameter() {
        this.compute_contours();
        this.compute_tesselation();
        var highest_diameter = 0;
        for (var i = 0; i < this.tesselation_triangles.length; i += 3) {
            var p0 = this.tesselation_vertices[this.tesselation_triangles[i]];
            var p1 = this.tesselation_vertices[this.tesselation_triangles[i + 1]];
            var p2 = this.tesselation_vertices[this.tesselation_triangles[i + 2]];
            var l0 = fh_dist(p0, p1);
            var l1 = fh_dist(p1, p2);
            var l2 = fh_dist(p2, p0);
            if (l0 + l1 + l2 < highest_diameter) continue;

            var s = 0.5 * (l0 + l1 + l2);
            if ((s <= l0) || (s <= l1) || (s <= l2)) continue;
            var a = Math.sqrt(s * (s - l0) * (s - l1) * (s - l2));
            var d = 4 * a / (l0 + l1 + l2);
            if (d > highest_diameter)
                highest_diameter = d;
        }
        return highest_diameter;
    }

    //*********************************************
    /** project on a plane */
    //*********************************************
    project(point, normal, direction) {
        this.compute_contours();
        var pscal = fh_dot(normal, this._normal);
        if (Math.abs(pscal) < 0.001) return false;
        if (pscal < 0) this.reverse();

        this._normal = normal;
        this._point = point;
        fh_build_axis(this._normal, this._dx, this._dy);

        //*** Projection on plane function
        var invps = 1 / fh_dot(direction, this._normal);
        function project(p) {
            var lambda = fh_dot(fh_sub(point, p), normal) * invps;
            return fh_add(p, fh_mul(direction, lambda));
        }
        this.area *= invps;
        this.perimeter *= invps;
        this.outer_perimeter *= invps;

        for (var i in this.contour_vertices)
            this.contour_vertices[i] = project(this.contour_vertices[i]);

        if (this._tesselation_computed) {
            for (var i in this.contour_vertices)
                this.tesselation_vertices[i] = project(this.tesselation_vertices[i]);
        }
    }

    //*********************************************
    //*********************************************
    /** Offset by a given distance. Method can be 0 (square), 1 (round), 2 (mitter) */
    //*********************************************
    //*********************************************
    offset(distance, method = 2) {
        this.compute_contours();
        if (this.contour_sizes.length == 0)
            return;

        var clpr = new ClipperOffset();

        var jt = method;
        var et = EndType.etClosedPolygon;

        //*** add contours already computed
        var offset = 0;
        for (var nct = 0; nct < this.contour_sizes.length; nct++) {
            var path = new Path();
            for (var k = 0; k < this.contour_sizes[nct]; k++) {
                path.push(this._convert_to_clipper(this.contour_vertices[offset + k]));
            }
            clpr.AddPath(path, jt, et);
            offset += this.contour_sizes[nct];
        }

        //*** operate
        var polytree = new PolyTree();
        var res = clpr.Execute(polytree, distance / this._clipper_scale);

        //*** transform results
        this.contour_vertices = [];
        this.contour_sizes = [];
        this.contour_orientations = [];
        this.contour_parents = [];
        this._read_result(polytree, -1);
        this._compute_area();
    }

    //*********************************************
    //*********************************************
    /** Does polygon contain a point ? */
    //*********************************************
    //*********************************************
    contains(point) {
        this.compute_contours();

        var p = fh_sub(point, this._point);
        var x = fh_dot(p, this._dx);
        var y = fh_dot(p, this._dy);
        var offset = 0;
        var before = 0;
        var after = 0;

        for (var nc = 0; nc < this.contour_sizes.length; nc++) {
            var nbv = this.contour_sizes[nc];

            for (var k = 0; k < nbv; k++) {
                p = fh_sub(this.contour_vertices[offset + k], this._point);
                var x0 = fh_dot(p, this._dx);
                var y0 = fh_dot(p, this._dy);
                p = fh_sub(this.contour_vertices[offset + ((k + 1) % nbv)], this._point);
                var x1 = fh_dot(p, this._dx);
                var y1 = fh_dot(p, this._dy);
                if (y0 < y && y1 < y) continue;
                if (y0 > y && y1 > y) continue;
                if (y0 == y) {
                    if (y1 <= y) continue;
                    if (x0 < x) before++;
                    else after++;
                }
                else if (y1 == y) {
                    if (y0 <= y) continue;
                    if (x1 < x) before++;
                    else after++;
                }
                else {
                    var lambda = (y - y0) / (y1 - y0);
                    var xinter = x0 + lambda * (x1 - x0);
                    if (xinter < x) before++;
                    else after++;
                }
            }
            offset += nbv;
        }
        if (before == 0) return false;
        if (after == 0) return false;
        if ((before + after) & 1) return false;
        if (before & 1) return true;
        return false;
    }

    /**
     * Returns the list of sun-segments of [p0,p1] (if any) that lies inside the polygon.
     * @param {number[]} p0
     * @param {number[]} p1
     * @returns {Array<number[]>}
     */
    intersect_segment(p0, p1) {
        this.compute_contours();
        if (this.contour_vertices.length == 0) return [];

        //*** project on plane */
        const pp0 = [fh_dot(this._dx, fh_sub(p0, this._point)), fh_dot(this._dy, fh_sub(p0, this._point)), 0];
        const pp1 = [fh_dot(this._dx, fh_sub(p1, this._point)), fh_dot(this._dy, fh_sub(p1, this._point)), 0];
        const dx = fh_sub(pp1, pp0);
        const length = fh_normalize(dx)
        if (length < 0.001) return [];
        const dy = fh_cross([0, 0, 1], dx);

        //*** project contour vertices */
        const contour_vertices = this.contour_vertices.map(v => [fh_dot(dx, fh_sub(v, pp0)), fh_dot(dy, fh_sub(v, pp0)), 0]);

        //*** Check bounding box */
        const box = new fh_box();
        contour_vertices.forEach(v => box.enlarge_point(v));
        if (box.position[0] >= length || box.position[1] > 0) return [];
        if (box.position[0] + box.size[0] <= 0 || box.position[1] + box.size[1] < 0) return [];

        var offset = 0;
        var positions = [];
        for (var nc = 0; nc < this.contour_sizes.length; nc++) {
            var nbv = this.contour_sizes[nc];

            for (var k = 0; k < nbv; k++) {
                var vertex = contour_vertices[offset + k];
                var x0 = vertex[0];
                var y0 = vertex[1];
                vertex = contour_vertices[offset + ((k + 1) % nbv)];
                var x1 = vertex[0];
                var y1 = vertex[1];
                if (y0 < 0 && y1 < 0) continue;
                if (y0 > 0 && y1 > 0) continue;
                if (y0 == 0) {
                    if (y1 <= 0) continue;
                    positions.push(x0);
                }
                else if (y1 == 0) {
                    if (y0 <= 0) continue;
                    positions.push(x1);
                }
                else {
                    var lambda = (0 - y0) / (y1 - y0);
                    positions.push(x0 + lambda * (x1 - x0));
                }
            }
            offset += nbv;
        }
        if (positions.length == 0) return [];
        positions.sort((a, b) => a - b);

        //*** Trivial cases */
        if (positions[0] >= length) return [];
        if (positions[positions.length - 1] <= 0) return [];

        const saved_positions = [];
        var status = (positions[0] < 0) ? 0 : 1;
        positions.forEach((p, index) => {
            if (p > 0) {
                if (status == 0) {
                    if (index & 1) saved_positions.push(0);
                    status = 1;
                }
                if (p < length)
                    saved_positions.push(p);
                else if (status < 2) {
                    if (index & 1) saved_positions.push(length);
                    status = 2;
                }
            }
        });

        const dir = fh_mul(fh_sub(p1, p0), 1 / length);
        return saved_positions.map(p => fh_add(p0, fh_mul(dir, p)));
    }

    //*********************************************
    //*********************************************
    /** Compute bounding box */
    //*********************************************
    //*********************************************
    get_bounding_box() {
        this.compute_contours();
        if (this._bounding_box) return this._bounding_box;
        this._bounding_box = new fh_box();
        for (var i in this.contour_vertices)
            this._bounding_box.enlarge_point(this.contour_vertices[i]);
        return this._bounding_box;
    }

    //*********************************************
    //*********************************************
    /** Internal methods */
    //*********************************************
    //*********************************************

    _add_contour(polygon, index, parent_index) {
        var offset = 0;
        for (var i = 0; i < index; i++)
            offset += polygon.contour_sizes[i];
        var sz = polygon.contour_sizes[index];
        this.contour_sizes.push(sz);
        this.contour_orientations.push(polygon.contour_orientations[index]);
        this.contour_parents.push(parent_index);

        for (var k = 0; k < sz; k++)
            this.contour_vertices.push(fh_clone(polygon.contour_vertices[offset + k]));
    }

    //*********************************************
    //** Convert to clipper
    _convert_to_clipper(p) {
        var v = fh_sub(p, this._point);
        var x = fh_dot(v, this._dx) / this._clipper_scale;
        var y = fh_dot(v, this._dy) / this._clipper_scale;
        return new IntPoint(Math.round(x), Math.round(y));
    }

    _convert_from_clipper(ip) {
        var dx = fh_mul(this._dx, this._clipper_scale * ip.X);
        var dy = fh_mul(this._dy, this._clipper_scale * ip.Y);
        return fh_add(this._point, fh_add(dx, dy));
    }

    //*********************************************
    //** Compute contours
    _compute_contours() {
        this._tesselation_computed = false;
        this._bounding_box = null;

        //****************************************************************
        //*** maybe nothing to compute
        if (this._tmp_vertices.length < 3) {
            this._tmp_vertices = [];
            this._tmp_sizes = [];
            return;
        }

        //****************************************************************
        //*** now we might want to set the geometry of the polygon
        if (!this._plane_defined) {
            this._point = this._tmp_vertices[0];
            this._normal = [0, 0, 0];
            var sz0 = this._tmp_sizes[0];
            for (var k = 0; k < sz0; k++) {
                var d0 = fh_sub(this._tmp_vertices[(k + 1) % sz0], this._tmp_vertices[k]);
                var d1 = fh_sub(this._tmp_vertices[(k + 2) % sz0], this._tmp_vertices[(k + 1) % sz0]);
                this._normal = fh_add(this._normal, fh_cross(d0, d1));
            }

            if (fh_size(this._normal) < 0.000001) {
                this._normal = [0, 0, 0];
                this._tmp_vertices = [];
                this._tmp_sizes = [];
                return;
            }

            fh_normalize(this._normal);
            fh_build_axis(this._normal, this._dx, this._dy);

            //*** check that first path is clockwise
            var first_path = new Path();
            for (var k = 0; k < this._tmp_sizes[0]; k++) {
                first_path.push(this._convert_to_clipper(this._tmp_vertices[k]));
            }
            if (!Clipper.Orientation(first_path)) {
                this._normal = fh_mul(this._normal, -1.0);
                fh_build_axis(this._normal, this._dx, this._dy);
            }

            this._plane_defined = true;
        }

        //****************************************************************
        //*** trivial case
        if (this._tmp_vertices.length == 3.5465) {
            this.contour_vertices = this._tmp_vertices;
            this.contour_sizes = [3];
            this.contour_orientations = [true];
            this.contour_parents = [-1];

            //*** clear tmp data
            this._tmp_vertices = [];
            this._tmp_sizes = [];
            return;
        }

        //****************************************************************
        //*** now we use the clipper
        var clpr = new Clipper();

        //*** add contours already computed
        var offset = 0;
        for (var nct = 0; nct < this.contour_sizes.length; nct++) {
            var path = new Path();
            for (var k = 0; k < this.contour_sizes[nct]; k++) {
                path.push(this._convert_to_clipper(this.contour_vertices[offset + k]));
            }
            clpr.AddPath(path, PolyType.ptSubject, true);
            offset += this.contour_sizes[nct];
        }

        //*** add new contours
        offset = 0;
        for (var nct = 0; nct < this._tmp_sizes.length; nct++) {
            var path = new Path();
            for (var k = 0; k < this._tmp_sizes[nct]; k++) {
                path.push(this._convert_to_clipper(this._tmp_vertices[offset + k]));
            }
            clpr.AddPath(path, PolyType.ptSubject, true);
            offset += this._tmp_sizes[nct];
        }

        //*** operate
        var polytree = new PolyTree();
        var solution_paths = new Paths();
        var res = clpr.Execute(ClipType.ctUnion, polytree, PolyFillType.pftEvenOdd, PolyFillType.pftEvenOdd);
        //var res = clpr.Execute(ClipType.ctUnion, solution_paths,PolyFillType.pftEvenOdd,PolyFillType.pftEvenOdd);
        //console.log(JSON.stringify(polytree));
        //console.log(JSON.stringify(solution_paths));
        //*** transform results
        this.contour_vertices = [];
        this.contour_sizes = [];
        this.contour_orientations = [];
        this.contour_parents = [];
        this._tesselation_computed = false;
        this._read_result(polytree, -1);

        //*** clear tmp data
        this._tmp_vertices = [];
        this._tmp_sizes = [];
    }

    //*********************************************
    /** read polytree from clipper */
    _read_result(polytree, parent_index) {
        //*** read contour
        var contour = polytree.Contour();
        if (contour.length > 0) {
            this.contour_sizes.push(contour.length);
            if (polytree.IsHole() && parent_index >= 0)
                this.contour_orientations.push(false);
            else
                this.contour_orientations.push(true);
            this.contour_parents.push(parent_index);
            for (var n = 0; n < contour.length; n++) {
                this.contour_vertices.push(this._convert_from_clipper(contour[n]));
            }
            parent_index = this.contour_sizes.length - 1;
        }

        //*** read children
        var children = polytree.Childs();
        for (var n = 0; n < children.length; n++)
            this._read_result(children[n], parent_index);
    }

    //*********************************************
    /** operate any operation between 2 polygons */
    _operate(other, clip_type) {
        var clpr = new Clipper();

        //*** add contours from this polygon
        var offset = 0;
        for (var nct = 0; nct < this.contour_sizes.length; nct++) {
            var path = new Path();
            for (var k = 0; k < this.contour_sizes[nct]; k++) {
                path.push(this._convert_to_clipper(this.contour_vertices[offset + k]));
            }
            clpr.AddPath(path, PolyType.ptSubject, true);
            offset += this.contour_sizes[nct];
        }

        //*** do we have to reverse other polygon ?
        var reverse = (fh_dot(this._normal, other._normal) < 0);

        //*** add contours from other polygon
        offset = 0;
        for (var nct = 0; nct < other.contour_sizes.length; nct++) {
            var path = new Path();
            if (reverse) {
                var offset2 = offset + other.contour_sizes[nct] - 1;
                for (var k = 0; k < other.contour_sizes[nct]; k++) {
                    path.push(this._convert_to_clipper(other.contour_vertices[offset2 - k]));
                }
            }
            else {
                for (var k = 0; k < other.contour_sizes[nct]; k++) {
                    path.push(this._convert_to_clipper(other.contour_vertices[offset + k]));
                }
            }
            clpr.AddPath(path, PolyType.ptClip, true);
            offset += other.contour_sizes[nct];
        }

        //*** operate
        var polytree = new PolyTree();
        clpr.Execute(clip_type, polytree);

        //*** transform results
        this.contour_vertices = [];
        this.contour_sizes = [];
        this.contour_orientations = [];
        this.contour_parents = [];
        this._tesselation_computed = false;
        this._read_result(polytree, -1);
    }

    //*******************************************************
    //*** Compute tesselation
    //*******************************************************
    _compute_tesselation() {
        var pgs = this.split();

        this.tesselation_vertices = [];
        this.tesselation_triangles = [];

        for (var n in pgs) {
            var vertices = [];
            var earcut_vertices = [];
            var earcut_contours = [];

            var pg = pgs[n];
            pg.compute_contours();

            var offset = 0;

            var vertices = [];
            var earcut_vertices = [];
            var earcut_contours = [];
            for (var i = 0; i < pg.contour_sizes.length; i++) {
                var sz = pg.contour_sizes[i];
                if (i < pg.contour_sizes.length - 1)
                    earcut_contours.push(offset + sz);
                for (var j = 0; j < sz; j++) {
                    var vtx = fh_clone(pg.contour_vertices[offset + j]);
                    vertices.push(vtx);
                    earcut_vertices.push(fh_dot(vtx, this._dx));
                    earcut_vertices.push(fh_dot(vtx, this._dy));
                    //earcut_vertices.push(vtx[2]);
                }
                offset += sz;
            }
            var earcut_triangles = earcut(earcut_vertices, earcut_contours, 2);

            offset = this.tesselation_vertices.length;
            // @ts-ignore
            for (var i in earcut_triangles)
                this.tesselation_triangles.push(earcut_triangles[i] + offset);
            this.tesselation_vertices = this.tesselation_vertices.concat(vertices);
        }
        this._tesselation_computed = true;
    }

    //*******************************************************
    //*** Compute area
    //*******************************************************
    _compute_area() {
        var obj = this;
        function convert_for_area(p) {
            var v = fh_sub(p, obj._point);
            return [fh_dot(v, obj._dx), fh_dot(v, obj._dy)];
        }
        this.area = 0;
        this.perimeter = 0;
        this.outer_perimeter = 0;
        var offset = 0;
        for (var nct = 0; nct < this.contour_sizes.length; nct++) {
            var sz = this.contour_sizes[nct];

            var a = 0;
            var p = 0;
            var v0 = convert_for_area(this.contour_vertices[offset + sz - 1]);
            for (var nv = 0; nv < sz; nv++) {
                var v1 = convert_for_area(this.contour_vertices[offset + nv]);
                a += (v0[0] + v1[0]) * (v0[1] - v1[1]) * 0.5;
                p += Math.sqrt((v0[0] - v1[0]) * (v0[0] - v1[0]) + (v0[1] - v1[1]) * (v0[1] - v1[1]));
                v0 = v1;
            }
            offset += sz;
            this.perimeter += p;
            if (this.contour_orientations[nct]) {
                this.area += Math.abs(a);
                this.outer_perimeter += p;
            }
            else
                this.area -= Math.abs(a);
        }
        this.area = Math.abs(this.area);
    }

    //*******************************************************
    //*** Unitary tests
    //*******************************************************

    static test() {
        var po0 = new fh_polygon([0, 0, 0], [0, 0, 1]);
        console.log("Polygon creation : ", po0);

        po0.add_contour([[0, 0, 0], [1, 0, 0], [1, 1, 0], [0, 1, 0]]);
        console.log("Polygon with 1 contour : ", po0);

        //po0.add_contour([[0.5,0.5,0],[1.5,0.5,0],[1.5,1.5,0],[0.5,1.5,0]]);
        //console.log("Polygon with 2 contour : ",po0);

        po0.compute_contours();
        console.log("Polygon computed : ", JSON.stringify(po0.contour_sizes), JSON.stringify(po0.contour_vertices));

        var po1 = po0.clone();

        var po2 = new fh_polygon([0, 0, 0], [0, 0, 1]);
        po2.add_contour([[0.5, 0.5, 0], [1.5, 0.5, 0], [1.5, 1.5, 0], [0.5, 1.5, 0]]);

        po0.unites(po2);
        console.log("Union computed : ", JSON.stringify(po0.contour_sizes), JSON.stringify(po0.contour_vertices));

        po1.intersects(po2);
        console.log("Intersection computed : ", JSON.stringify(po1.contour_sizes), JSON.stringify(po1.contour_vertices));
    }
}
