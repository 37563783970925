import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Equipement } from '../model/equipement.model';

@Injectable({
    providedIn: 'root',
})
export class EquipementApiService {
    public resourceUrl = `${environment.apiUrl}/reference`;

    private http: HttpClient;

    constructor(private httpBackend: HttpBackend) {
        this.http = new HttpClient(httpBackend);
    }

    findOneEquipement(idEquipement: string): Observable<Equipement> {
        return this.http
            .get<Equipement>(`${this.resourceUrl}/equipement/${idEquipement}`)
            .pipe(
                map((it: any) => {
                    const result = new Equipement();
                    result.id = it.id;
                    result.name = it.name;
                    result.productTypeCodeBim = it.productTypeCodeBim;
                    result.width = it.width;
                    result.depth = it.depth;
                    result.height = it.height;
                    result.iconImageData = it.iconImageData;
                    result.contact = it.contact;
                    result.defaultHeight = it.defaultHeight;
                    if (it.geometricData) {
                        result.geometricData = {
                            topViewImageData: it.geometricData.topViewImageData,
                            geometries: null,
                            instanciations: null,
                        };

                        result.geometricData.geometries = JSON.parse(it.geometricData.geometries);
                        result.geometricData.instanciations = JSON.parse(it.geometricData.instanciations);
                    }
                    result.parameters = it.parameters;
                    result.color = it.color;
                    result.roofParallel = it.roofParallel;
                    result.sourceType = it.sourceType;
                    //console.log('load Equipement : ', result);
                    return result;
                })
            )
            .pipe(map((rawEquipement) => Object.assign(new Equipement(), rawEquipement)));
    }
}
