<div *ngIf="form" class="zone-propriete-modal" [ngClass]="{ modal: isModal }" [formGroup]="form">
    <div class="header">Sélection d'une zone de travail existante</div>

    <div class="zone-content" [ngClass]="{ 'content-modal': isModal }">
        <div class="zone-content-column">
            <div class="cndiad-form-field">
                <mat-label>Environnement</mat-label>
                <app-widget-select-multi
                    [mode]="widgetSelectModeEnum.BADGE"
                    formControlName="environnement"
                    [disabled]="readOnlyMode"
                    (ngModelChange)="majListeZone()"
                    multiple
                >
                    <option
                        *ngFor="let item of polluantConfig.contenuListeDeroulante.typeEnvironnement.items"
                        [value]="item.label"
                        [attr.data-color]="item.backgroundColor"
                        datatype="html"
                    >
                        <lib-icon
                            *ngIf="item.icone != ''"
                            theme="{{ item.icone.theme }}"
                            iconName="{{ item.icone.nom }}"
                            color="{{ item.icone.couleur }}"
                        ></lib-icon>
                        {{ item.label }}
                    </option>
                </app-widget-select-multi>
            </div>
            <div class="d-flex align-items-center">
                <mat-label class="required">Zone associée</mat-label>
                <div class="col-xl-8">
                    <app-custom-select
                        class="w-100"
                        formControlName="idZoneAssociee"
                        [readonly]="readOnlyMode"
                        [matFormFieldClass]="'w-100'"
                        [choices]="zonesFiltres"
                        [addline]="false"
                        [multiple]="false"
                        (selectedValueChange)="onChangeZoneAssociee($event)"
                    >
                    </app-custom-select>
                </div>
            </div>
        </div>
    </div>

    <div class="zone-action" *ngIf="isModal">
        <div>
            <button mat-flat-button color="warn" mat-dialog-close="delete" *ngIf="zoneAssociee">Supprimer</button>
        </div>

        <div class="group-button">
            <button mat-flat-button (click)="modalAction('cancel')">Annuler</button>
            <button mat-flat-button color="primary" (click)="modalAction('saveAndClose')" [disabled]="readOnlyMode">
                Sauvegarder
            </button>
        </div>
    </div>
</div>
