<ng-container *ngFor="let equipement of listeEquipements">
    <a (click)="selectEquipement(equipement)">
        <mat-card
            class="d-flex p-2 mb-2 mr-2 float-left"
            [ngClass]="currentEquipement != null && equipement.id == currentEquipement?.id ? 'selected' : ''"
        >
            <mat-card-content>
                {{ equipement.name }}
                <div *ngIf="equipement.iconImageData" class="pl-5">
                    <img
                        [src]="equipement.iconImageData"
                        alt="Pictogramme de l'équipement"
                        class="img-fluid"
                        style="max-width: 100%; max-height: 60px"
                    />
                </div>
            </mat-card-content>
        </mat-card>
    </a>
</ng-container>
<button color="accent" mat-raised-button class="bt-valid" (click)="createEquipement()" *ngIf="!this.categorie?.abstract">
    <lib-icon>add</lib-icon>
    {{ 'Créer un équipement' | uppercase }}
</button>
