<div class="header-zone mat-card">
    <div>
        <div class="mr-3">Surface totale de la zone</div>
        <input type="number" class="mat-border-radius" readonly [value]="surfaceTotale" [readonly]="true" />
    </div>
    <div>
        <div class="mr-3">P.U total de la zone</div>
        <input type="number" class="mat-border-radius" readonly [value]="puTotal" [readonly]="true" />
    </div>
</div>
