<h1 mat-dialog-title class="primary d-flex justify-content-between">
    <span>Liste des éléments sélectionnés</span>
    <button mat-button (click)="cancel()">
        <lib-icon>close</lib-icon>
    </button>
</h1>
<div mat-dialog-content>
    <ng-container *ngFor="let el of elementsToDisplay">
        <mat-card
            class="p-0 mb-2 w-100 d-flex flex-column justify-content-between"
            [ngClass]="
                el.conformite === enumConformite.CONFORME
                    ? 'point-de-controle-conforme'
                    : el.conformite === enumConformite.NON_CONFORME
                    ? 'point-de-controle-non-conforme'
                    : el.conformite === enumConformite.A_JUSTIFIER
                    ? 'point-de-controle-justifier'
                    : 'point-de-controle-non-rempli'
            "
        >
            <mat-card-content class="p-2">
                <!--Nom de l'équipement-->
                <div class="row no-gutters align-items-center">
                    <div class="flex-grow-1">{{ el.nom }}</div>
                </div>
            </mat-card-content>
        </mat-card>
    </ng-container>
</div>
