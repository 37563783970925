import { Pipe, PipeTransform } from '@angular/core';
import { PresetPhoto, presetPhotoToLabel } from '../model/diagnostic.model';

/**
 * Affiche le label du preset photo
 */
@Pipe({
    name: 'presetPhoto',
})
export class PresetPhotoPipe implements PipeTransform {
    constructor() {}

    transform(presetPhoto: PresetPhoto): string {
        return presetPhotoToLabel(presetPhoto);
    }
}
