import { Contact } from './contact.model';
import { Bien } from './bien.model';
import { Adresse } from './adresse.model';
import { RaisonAnnulation } from './raison-annulation';
import { Document } from './document.model';
import { Prestation } from './prestation.model';
import { Prestataire } from './prestataire.model';
import { EtatProgression } from './etat-progression.model';
import { Commentaire } from './commentaire.model';
import { Diagnostic, EtatDiagnostic } from './diagnostic.model';
import { Cofrac } from './cofrac.model';
import { Agence } from './agence.model';
import { MongoUtils } from 'src/app/commons-lib';

export enum EtatIntervention {
    /** Non démarrée */
    NON_DEMARREE = 'NON_DEMARREE',

    /** Annulée */
    ANNULEE = 'ANNULEE',

    NON_REALISEE = 'NON_REALISEE',

    /** En cours */
    EN_COURS = 'EN_COURS',

    /** En attente */
    EN_ATTENTE = 'EN_ATTENTE',

    /** Terminée */
    TERMINEE = 'TERMINEE',
}

/**
 * À partir d'un etat d'intervention, renvoie le label associé.
 */
export function etatInterventionToLabel(etatIntervention: EtatIntervention): string {
    switch (etatIntervention) {
        case EtatIntervention.NON_DEMARREE:
            return 'Non démarrée';

        case EtatIntervention.ANNULEE:
            return 'Annulée';

        case EtatIntervention.EN_COURS:
            return 'En cours';

        case EtatIntervention.EN_ATTENTE:
            return 'En attente';

        case EtatIntervention.TERMINEE:
            return 'Terminée';

        case EtatIntervention.NON_REALISEE:
            return 'Non réalisée';

        default:
            return etatIntervention;
    }
}

/**
 * À partir d'une inter et TypePresta renvoie le numéro cofrac en cours.
 */
export function numCofracAgence(typePresta: string, intervention: Intervention): Cofrac {
    let Cofrac: Cofrac = null;

    intervention.agence.cofracs.forEach((cof) => {
        cof.typePrestations.forEach((presta) => {
            if (presta == typePresta) {
                if (cof.dateDebutValidite < intervention.dateHeureDebutEffective && intervention.dateHeureDebutEffective < cof.dateEcheance) {
                    Cofrac = cof;
                }
            }
        });
    });

    return Cofrac;
}

export type StorageStatus = 'FULL' | 'PARTIEL';

export enum ExigenceDescription {
    /** Sans exigence */
    SANS_EXIGENCE = 'SANS_EXIGENCE',

    /** Plan */
    PLAN = 'PLAN',

    /** Croquis */
    CROQUIS = 'CROQUIS',
}

/**
 * À partir d'une exigence de description, renvoie le label associé.
 */
export function exigenceDescriptionToLabel(exigenceDescription: ExigenceDescription): string {
    switch (exigenceDescription) {
        case ExigenceDescription.SANS_EXIGENCE:
            return 'Sans exigence';

        case ExigenceDescription.PLAN:
            return 'Plan';

        case ExigenceDescription.CROQUIS:
            return 'Croquis';

        default:
            return exigenceDescription;
    }
}

export class Intervention {
    id: string = MongoUtils.generateObjectId();

    /**
     * Nom
     */
    nom: string;

    /**
     * Référence client
     */
    refClient: string;

    /**
     * Adresse de RDV pour l'intervention (peut être différent de l'adresse du bien)
     */
    adresse: Adresse;

    /**
     * Adresse à plat du type voie; cmpl1; cmpl2; CP; ville utilisée pour filtrer dans les tableaux
     */
    adresseStringPourFiltre: string;

    /**
     * Contact privilégié du rdv
     */
    rdvContact: Contact;

    /**
     * Biens sur lequels l'intervention doit être effectuée
     */
    relationInterventionBiens: RelationInterventionBien[] = [];

    adresseBienPrincipal: Adresse;

    /**
     * Date et heure de début
     */
    dateHeureDebutPrevue: string;

    /**
     * Date et heure de fin
     */
    dateHeureFinPrevue: string;

    /**
     * Date et heure de début effective
     */
    dateHeureDebutEffective: string;

    /**
     * Date et heure de fin effective
     */
    dateHeureFinEffective: string;

    /**
     * État
     */
    etat: EtatIntervention;

    /**
     * Exigence de détail de description
     */
    exigenceDescription: ExigenceDescription;

    /**
     * Description des travaux envisagés
     */
    descriptionTravaux: string;

    /**
     * Prestations à effectuer et diagnostics liés
     */
    prestationsDiagnostics: PrestationDiagnostic[] = [];

    /**
     * Diagnostics de l'intervention (utilisé uniquement pour le tableau admin)
     */
    diagnostics: Diagnostic[] = [];

    /**
     * Contacts
     */
    contacts: Contact[] = [];

    /**
     * Prestataires
     */
    prestataires: Prestataire[] = [];

    /**
     * Prestataire à plat du type userid; nom; prenom;  utilisée pour filtrer dans les tableaux
     */
    prestatairesListePourFiltreTri = '';

    /**
     * Raison de l'annulation de l'intervention
     */
    raisonAnnulation?: RaisonAnnulation;

    /**
     * Documents relatifs à l'intervention
     */
    documents: Document[] = [];

    /**
     * Etat de la progression de la sisaie des informations dans chaque onglet
     */
    etatProgressions: EtatProgression[] = [];

    /**
     * Liste de commentaires liée à cet élément
     */
    commentairesId: string[] = [];

    /**
     * Commentaires de l'intervention
     */
    commentaires: Commentaire[] = [];

    /**
     * Note sur l'intervention, provenant de salesforce
     */
    noteIntervention?: string;

    storageStatus: StorageStatus = 'FULL';

    /**
     * Date de commande au format yyyy-mm-dd
     */
    dateCommande?: string;

    /**
     * Numero de commande
     */
    numeroCommande?: string;

    /**
     * Id de la commande
     */
    idCommande?: string;

    /**
     * Agence ayant réalisée l'intervention
     */
    agence?: Agence;
    nomAgence?: string;

    /**
     * Agence à plat du type nom; siret; siren;  utilisée pour filtrer dans les tableaux
     */
    agenceStringPourFiltre?: string;

    /**
     *  True si l'intervention est ANNULEE depuis moins de 24h
     */
    repriseAnnulationPossible: boolean;

    /**
     *  True si l'intervention contient au moins 1 diagnostic terminé
     */
    hasDiagnosticsTermines: boolean;

    /*
     * L'intervention est-elle un rdv
     */
    isRendezVous: boolean = false;

    /**
     * Renvoie la RelationInterventionBien du bien principal
     */
    static getRelationInterventionBienPrincipal(intervention: Intervention): RelationInterventionBien {
        if (intervention) {
            return (intervention.relationInterventionBiens || []).filter((relation) => relation.isBienPrincipal === true).slice(0, 1)[0];
        } else {
            return null;
        }
    }
}

/**
 * Encapsulation d'une prestation et du diagnostic associé
 */
export class PrestationDiagnostic {
    /**
     * Identifiant
     */
    id: string = MongoUtils.generateObjectId();

    /**
     * Prestation
     */
    prestation: Prestation;

    /**
     * Id du diagnostic (nul si non démarré)
     */
    idDiagnostic?: string = null;

    /**
     * Etat du diagnostic
     */
    etatDiagnostic: EtatDiagnostic = EtatDiagnostic.NON_DEMARRE;

    /**
     * Id de l'intervention contenant la prestationDiagnostic parente si elle existe
     */
    idInterventionParente?: string = null;

    /**
     * Id de la prestationDiagnostic parente si elle existe
     */
    idPrestationDiagnosticParente?: string = null;

    /**
     * Permet de savoir si la prestation est sélectionnée dans la liste de prestation d'une intervention
     */
    isChecked = false;

    /**
     * Commentaires posé sur la liste des prestations de l'intervention
     */
    commentairesId: string[] = [];

    /**
     * Planification des besoins
     */
    planificationBesoins: any;

    /**
     * ref de la prestation (nom pour le rapport)
     */
    refPrestation: string;

    /**
     * données pour la révision
     */
    revision?: Revision;

    /**
     * true si la prestation est révisée
     */
    estRevisee: boolean = false;
    /**
     * données temporaires
     */
    datas: any = null;
}

export class Revision {
    /**
     * Id de l'Intervention révisée (Intervention révisée)
     */

    idInterventionRevisee: string;

    /**
     * Référence de l'intervention révisée
     */
    referenceInterventionRevisee: string;

    /**
     * Id de la Prestation Diagnostic révisée (PrestationDiagnostic révisée)
     */
    idPrestationDiagRevisee: string;

    /**
     * Référence du rapport révisé
     */
    referenceRapportRevise: string;

    /**
     * motif de révision
     */
    incrementRevision: number = 0;

    /**
     * motif de révision
     */
    motifRevision?: string;

    /**
     * tableau récapitulatif des anciens révisions
     */
    oldsRevisions: Revision[] = [];
}

/**
 * Relation entre l'intervention et le bien avec la date de dernière mise à jour
 * Précise également si ce bien est le bien principal de l'intervention
 */
export class RelationInterventionBien {
    /**
     * Identifiant
     */
    id: string = MongoUtils.generateObjectId();

    idIntervention: string;

    /**
     * Bien
     */
    bien: Bien;

    /**
     * Flag 'bien principal'
     */
    isBienPrincipal: boolean;

    /**
     * le bien est meublé
     */
    bienEstMeuble: boolean | null = null;

    /**
     * le bien est occupé
     */
    bienEstOccupe: boolean | null = null;

    /*
     * le contexte de l'intervention, Location ou Vente
     */
    contexteIntervention: string | null = null;

    constructor(idIntervention: string, bien: Bien, isBienPrincipal: boolean) {
        this.idIntervention = idIntervention;
        this.bien = bien;
        this.isBienPrincipal = isBienPrincipal;
    }
}
