<div class="pb-5">
    <div class="block-title p-2 pl-5 my-3 d-inline-block" *ngIf="data">{{ data.title }}</div>

    <!--------------------- Intervention --------------------->
    <div class="px-5 mt-4 d-flex flex-row">
        <div class="block-group w-50 mr-3">
            <div class="group-title mb-3">Intervention</div>
            <div class="d-flex mt-1">
                <div class="group-label">Num du dossier :</div>
                <div class="group-value">{{ interReportData?.dossierNumber ?? 'n.c' }}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">Date de commande :</div>
                <div class="group-value">{{ interReportData?.dateCommande ?? 'n.c' }}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">
                    {{ typePrestation === TypePrestationEnum.HAP_ETUDE_SITUATION ? "Date de l'étude de situation" : "Date de l'intervention" }}
                    :
                </div>
                <div class="group-value">{{ interReportData?.datePrevue ?? 'n.c' }}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">{{ typePrestation === TypePrestationEnum.HAP_ETUDE_SITUATION ? 'CR émis le' : 'Rapport émis le' }} :</div>
                <div class="group-value">{{ interReportData?.emisLe ?? 'n.c' }}</div>
            </div>
        </div>
        <!--------------------- Opérateur --------------------->
        <div class="block-group w-50">
            <div class="group-title mb-3">Opérateur de diagnostic</div>
            <div class="d-flex mt-1">
                <div class="group-label">Opérateur :</div>
                <div class="group-value">
                    {{ interReportData?.operateur.nom
                    }}<span *ngIf="interReportData?.operateur.prenom !== 'n.c'"> {{ interReportData?.operateur.prenom }}</span>
                </div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">Certifications :</div>
                <div class="group-value" *ngIf="interReportData?.operateur?.certifications.length === 0">Aucune certification trouvée.</div>
                <div class="group-value" *ngFor="let certif of interReportData?.operateur?.certifications">
                    {{ certif.numero }}
                </div>
            </div>
        </div>
    </div>
    <!--------------------- Etude de situation --------------------->
    <div *ngIf="typePrestationsAvecEtudeSituation.includes(typePrestation)" class="px-5 mt-4 d-flex flex-row">
        <div class="block-group w-50 mr-3">
            <div class="group-title mb-3">Etude de situation</div>
            <div class="d-flex mt-1">
                <div class="group-label">Num du dossier :</div>
                <div class="group-value">{{ interReportData?.numDossierInterventionParente ?? 'n.c' }}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">Date de commande :</div>
                <div class="group-value">{{ interReportData?.dateCommandeInterventionParente ?? 'n.c' }}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">Rapport émis le :</div>
                <div class="group-value">{{ interReportData?.rapportParentEmisLe ?? 'n.c' }}</div>
            </div>
        </div>
        <!--------------------- Opérateur de l'étude de situation --------------------->
        <div class="block-group w-50">
            <div class="group-title mb-3">Personne ayant réalisée l'étude</div>
            <div class="d-flex mt-1">
                <div class="group-value">n.c</div>
            </div>
        </div>
    </div>

    <div class="px-5 mt-4 d-flex flex-row">
        <div class="block-group w-50 mr-3">
            <div class="group-title mb-3">Société et assurance</div>
            <div class="d-flex mt-1">
                <div class="group-label">Nom de la société :</div>
                <div class="group-value">{{ interReportData?.societe.nom ?? 'n.c' }}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">Adresse :</div>
                <div class="group-value text-format">{{ interReportData?.societe.adresse ?? 'n.c' }}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">Société et siret :</div>
                <div class="group-value">{{ interReportData?.societe.siret ?? 'n.c' }}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">Assurance :</div>
                <div class="group-value">
                    <ng-container *ngIf="interReportData?.societe.assurance.nom"> {{ interReportData?.societe.assurance.nom }}<br /> </ng-container>
                    <ng-container *ngIf="interReportData?.societe.assurance.numeroPolice">
                        {{ interReportData?.societe.assurance.numeroPolice }}
                    </ng-container>
                    <ng-container *ngIf="!interReportData?.societe.assurance.numeroPolice && !interReportData?.societe.assurance.nom">
                        n.c
                    </ng-container>
                </div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">Date de validité :</div>
                <div class="group-value">{{ interReportData?.societe.assurance.dateValidite ?? 'n.c' }}</div>
            </div>
        </div>
    </div>

    <div class="mt-4 block-bien">
        <div class="d-flex flex-row">
            <div class="block-group w-50 mr-3">
                <div class="block-photo">
                    <div *ngIf="!imageBienFileId" class="h-100 d-flex justify-content-center align-items-center">
                        <div class="text-photo">PHOTO DU BIEN</div>
                    </div>
                    <div *ngIf="imageBienFileId | fileDownload | async as fileContent" class="h-100 image-photo d-flex justify-content-center">
                        <img [src]="fileContent" alt="Image du bien" class="img-fluid photo-bien" />
                    </div>
                </div>
            </div>
            <div class="block-group w-50 pr-5 pt-2">
                <div class="group-title mb-3">Lieu expertisé</div>
                <div class="d-flex mt-1">
                    <div class="group-label">Adresse :</div>
                    <div class="group-value text-format">{{ interReportData?.bienPrincipal.adresse ?? 'n.c' }}</div>
                </div>
                <div class="d-flex mt-1">
                    <div class="group-label">Code postal :</div>
                    <div class="group-value">{{ interReportData?.bienPrincipal.codePostal ?? 'n.c' }}</div>
                </div>
                <div class="d-flex mt-1">
                    <div class="group-label">Ville :</div>
                    <div class="group-value">{{ interReportData?.bienPrincipal.ville ?? 'n.c' }}</div>
                </div>
                <div class="d-flex mt-1">
                    <div class="group-label">Bât. :</div>
                    <div class="group-value">{{ interReportData?.bienPrincipal.batiment ?? 'n.c' }}</div>
                </div>
                <div class="d-flex mt-1">
                    <div class="group-label">Etage :</div>
                    <div class="group-value">{{ interReportData?.bienPrincipal.etage ?? 'n.c' }}</div>
                </div>
                <div class="d-flex mt-1">
                    <div class="group-label">Porte :</div>
                    <div class="group-value">{{ interReportData?.bienPrincipal.porte ?? 'n.c' }}</div>
                </div>
                <div class="d-flex mt-1">
                    <div class="group-label">Meublé :</div>
                    <div class="group-value">{{ interReportData?.bienPrincipal.estMeuble ?? 'n.c' }}</div>
                </div>
                <div class="d-flex mt-1">
                    <div class="group-label">Occupé :</div>
                    <div class="group-value">{{ interReportData?.bienPrincipal.estOccupe ?? 'n.c' }}</div>
                </div>
            </div>
        </div>

        <div class="px-5 py-2 d-flex flex-row">
            <div class="block-group w-50 mr-3">
                <div class="d-flex mt-1">
                    <div class="group-label">Type :</div>
                    <div class="group-value">{{ interReportData?.bienPrincipal.type ?? 'n.c' }}</div>
                </div>
                <div class="d-flex mt-1">
                    <div class="group-label">Affectation :</div>
                    <div class="group-value">{{ interReportData?.bienPrincipal.affectation ?? 'n.c' }}</div>
                </div>
                <div class="d-flex mt-1">
                    <div class="group-label">Lot n° :</div>
                    <div class="group-value">{{ interReportData?.bienPrincipal.numLot ?? 'n.c' }}</div>
                </div>
                <div class="d-flex mt-1" *ngIf="interReportData?.bienPrincipal.nbNiveaux !== 'undefined'">
                    <div class="group-label">Nb de niveaux :</div>
                    <div class="group-value">{{ interReportData?.bienPrincipal.nbNiveaux }}</div>
                </div>
            </div>
            <div class="block-group w-50">
                <div class="d-flex mt-1">
                    <div class="group-label">Surface :</div>
                    <div class="group-value">{{ interReportData?.bienPrincipal.surface ?? 'n.c' }}</div>
                </div>
                <div class="d-flex mt-1">
                    <div class="group-label">Cadastre :</div>
                    <div class="group-value">{{ interReportData?.bienPrincipal.cadastre ?? 'n.c' }}</div>
                </div>
                <div class="d-flex mt-1">
                    <div class="group-label">Année :</div>
                    <div class="group-value">{{ interReportData?.bienPrincipal.annee ?? 'n.c' }}</div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="idFichierImageSat" class="px-5 mt-4 d-flex flex-row">
        <div class="block-group w-100 mr-3">
            <div class="group-title mb-3">Image aérienne du bien</div>
            <div class="d-flex justify-content-center">
                <div class="block-photo">
                    <div *ngIf="idFichierImageSat | fileDownload | async as fileContent" class="h-100 image-photo d-flex justify-content-center">
                        <img [src]="fileContent" alt="Image aérienne du bien" class="img-fluid photo-bien" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="px-5 mt-4 d-flex flex-row">
        <div class="block-group w-50 mr-3">
            <div class="group-title mb-3">Donneur d'ordre</div>
            <div class="d-flex mt-1">
                <div class="group-label">Nom :</div>
                <div class="group-value">{{ interReportData?.donneurOrdre.nom ?? 'n.c' }}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">Prénom :</div>
                <div class="group-value">{{ interReportData?.donneurOrdre.prenom ?? 'n.c' }}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">Adresse :</div>
                <div class="group-value text-format">{{ interReportData?.donneurOrdre.adresse ?? 'n.c' }}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">Code postal :</div>
                <div class="group-value">{{ interReportData?.donneurOrdre.codePostal ?? 'n.c' }}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">Ville :</div>
                <div class="group-value">{{ interReportData?.donneurOrdre.ville ?? 'n.c' }}</div>
            </div>
        </div>

        <div class="block-group w-50">
            <div class="group-title mb-3">Propriétaire</div>
            <div class="d-flex mt-1">
                <div class="group-label">Nom :</div>
                <div class="group-value">{{ interReportData?.proprietaire.nom ?? 'n.c' }}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">Prénom :</div>
                <div class="group-value">{{ interReportData?.proprietaire.prenom ?? 'n.c' }}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">Adresse :</div>
                <div class="group-value text-format">{{ interReportData?.proprietaire.adresse ?? 'n.c' }}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">Code postal :</div>
                <div class="group-value">{{ interReportData?.proprietaire.codePostal ?? 'n.c' }}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">Ville :</div>
                <div class="group-value">{{ interReportData?.proprietaire.ville ?? 'n.c' }}</div>
            </div>
        </div>
    </div>
</div>
