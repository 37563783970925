import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LibFileUploaderModule, LibIconModule } from 'src/app/commons-lib';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTreeModule } from '@angular/material/tree';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { GestionEquipementsComponent } from './gestion-equipements.component';
import { CreationEquipementComponent } from './creation-equipement/creation-equipement.component';
import { PipesModule } from '../../../../pipes/pipes.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CnDiagSharedModule } from '../../../shared/shared.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TreeCategorieEquipementsComponent } from './tree-categorie-equipements/tree-categorie-equipements.component';
import { ViewDetailsCategorieEquipementComponent } from './view-details-categorie-equipement/view-details-categorie-equipement.component';
import { EditCategorieEquipementComponent } from './edit-categorie-equipement/edit-categorie-equipement.component';
import { MatListModule } from '@angular/material/list';
import { EditParameterModalComponent } from './edit-parameter-modal/edit-parameter-modal.component';
import { EditParameterDefinitionModalComponent } from './edit-parameterDefinition-modal/edit-parameterDefinition-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ViewListeEquipementsComponent } from './view-liste-equipements/view-liste-equipements.component';
import { ViewDetailsEquipementComponent } from './view-details-equipement/view-details-equipement.component';
import { EditEquipementComponent } from './edit-equipement/edit-equipement.component';
import { LibPipesModule } from '../../../../lib/pipes/pipes.module';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { EnerbimCommonsIncrementInputModule } from '@acenv/commons-lib';

@NgModule({
    declarations: [
        GestionEquipementsComponent,
        CreationEquipementComponent,
        TreeCategorieEquipementsComponent,
        ViewDetailsCategorieEquipementComponent,
        EditCategorieEquipementComponent,
        EditParameterModalComponent,
        EditParameterDefinitionModalComponent,
        ViewListeEquipementsComponent,
        ViewDetailsEquipementComponent,
        EditEquipementComponent,
    ],
    imports: [
        CommonModule,
        MatToolbarModule,
        LibIconModule,
        MatButtonModule,
        MatIconModule,
        ReactiveFormsModule,
        MatButtonToggleModule,
        PipesModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        CnDiagSharedModule,
        FormsModule,
        MatTableModule,
        MatTreeModule,
        MatSortModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        LibFileUploaderModule,
        MatListModule,
        MatCheckboxModule,
        MatRadioModule,
        LibPipesModule,
        MatSelectModule,
        MatNativeDateModule,
        MatDatepickerModule,
        EnerbimCommonsIncrementInputModule,
    ],
})
export class GestionEquipementsModule {}
