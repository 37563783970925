import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { MongoUtils } from 'src/app/commons-lib';
import { environment } from '../../environments/environment';
import { FileData, OfflineStorageService, ResourceWrapper } from '../shared/offline/offline-storage.service';
import { STORAGE_KEY_FILES } from '../shared/constants/indexeddb.constants';
import { combineLatest, Observable, of } from 'rxjs';
import { InterventionFile } from '../model/intervention-file.model';
import { Document } from '../model/document.model';
import { FileService } from './file.service';

@Injectable({
    providedIn: 'root',
})
export class FileApiService {
    public resourceUrl = `${environment.apiUrl}/files/v2`;

    private http: HttpClient;
    // Ressource pour le wrapper HL
    private resource: ResourceWrapper<FileData, string> = this.offlineStorage.wrapFileResource<FileData>({
        cacheName: STORAGE_KEY_FILES,
        idField: 'fileId',
        resourceUrl: this.resourceUrl,
        paramsForPull: null,
        urlByTemplate: (data: FileData) => this.resourceUrl,
    });

    private resourceV1: ResourceWrapper<FileData, string> = this.offlineStorage.wrapFileResource<FileData>({
        cacheName: STORAGE_KEY_FILES,
        idField: 'fileId',
        resourceUrl: `${this.resourceUrl}/ressources`,
        paramsForPull: null,
        urlByTemplate: (data: FileData) => `${this.resourceUrl}/ressources`,
    });

    constructor(private httpBackend: HttpBackend, private offlineStorage: OfflineStorageService, private readonly fileService: FileService) {
        this.http = new HttpClient(httpBackend);
    }

    /**
     * Crée un nouvel Id et upload le fichier
     * @param fileContent
     * @param fileName
     * @param fileId
     */
    uploadFile(fileContent: string, fileName: string, fileId = null): Observable<FileData> {
        if (!fileId) {
            fileId = MongoUtils.generateObjectId();
        }

        return this.resource.save({
            fileContent: fileContent,
            fileId: fileId,
            fileName: fileName,
        });
    }

    /**
     * Supprime le fichier
     * @param fileId
     */
    deleteFile(fileId: string): Observable<any> {
        return this.resource.delete({
            fileId,
        });
    }

    /**
     * Télécharge le fichier
     * @param fileId
     */
    downloadFile(fileId: string): Observable<FileData> {
        if (fileId) {
            return this.resource.getOneByTemplate({
                fileId: fileId,
            });
        } else {
            return of(null);
        }
    }

    downloadFileV2ressources(fileId: string): Observable<FileData> {
        if (fileId) {
            return this.resourceV1.getOneByTemplate({
                fileId: fileId,
            });
        } else {
            return of(null);
        }
    }

    /**
     * Push la liste des fichiers stockés dans la base IndexDB
     */
    pushFiles(): Observable<FileData> {
        return this.resource.push();
    }

    /**
     * Met à jour la liste des fichiers dans la base IndexDB
     * @param interventionFiles
     */
    pullFiles(interventionFiles: InterventionFile[]): Observable<FileData[]> {
        if (interventionFiles && interventionFiles.length > 0) {
            return combineLatest(
                interventionFiles.map((interventionFile) => {
                    return this.downloadFile(interventionFile.fileId);
                })
            );
        } else {
            return of([]);
        }
    }

    /**
     * Télécharge le fichier
     * @param document
     */
    getFileToDownload(document: Document) {
        const fileDownloadTokenUrl = `${environment.apiUrl}/files/v2/getDocument/${document.idFichier}`;
        const fileName = document.nomFichier;
        this.fileService.downloadDocumentFile(fileDownloadTokenUrl, fileName);
    }

    /**
     * Upload le fichier
     * @param documentId
     * @param file
     * @param fileId
     * @param dateUpload
     */
    uploadDocumentFile(documentId: string, file: FormData, codeTypeDocument: string, documentTitle: string): Observable<HttpResponse<Document>> {
        const fileId = MongoUtils.generateObjectId();
        const dateUpload: string = new Date().toLocaleDateString();
        let params = new HttpParams();
        params = params.set('fileId', fileId);
        params = params.set('dateUpload', dateUpload);
        params = params.set('idDocument', documentId);
        params = params.set('codeTypeDocument', codeTypeDocument);
        params = params.set('title', documentTitle);

        return this.http.post<Document>(`${this.resourceUrl}/uploadDocument`, file, {
            params,
            observe: 'response',
            reportProgress: true,
        });
    }

    /**
     * Télécharge le document
     * @param document
     */
    downloadDocumentFile(document: Document) {
        const fileDownloadTokenUrl = `${this.resourceUrl}/getDocumentCofrac/${document.idFichier}`;
        const fileName = document.nomFichier;
        this.fileService.downloadDocumentFile(fileDownloadTokenUrl, fileName);
    }
}
