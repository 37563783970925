<h1 mat-dialog-title class="epi-title">Ajouter une photo</h1>

<div mat-dialog-content>
    <mat-card-content class="w-100">
        <div class="button-container">
            <button class="button" mat-button (click)="PrendreUnePhoto()">
                <mat-icon>add_a_photo</mat-icon>
                <div>Prendre une photo</div>
            </button>
            <button class="button" mat-button (click)="ImporterUnePhoto()">
                <mat-icon>add_photo_alternate</mat-icon>
                <div>Importer une photo</div>
            </button>
        </div>
    </mat-card-content>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-raised-button (click)="confirm()">
        <span>{{ 'Fermer' | uppercase }}</span>
    </button>
</div>
