import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { NotificationService } from 'src/app/commons-lib';
import { first } from 'rxjs/operators';
import { concat, interval } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class VersionCheckService {
    constructor(private appRef: ApplicationRef, private notificationService: NotificationService, private swUpdate: SwUpdate) {
        const nbSecondsBetweenVersionCheck = environment.devel ? 15 : 60;
        const appIsStable$ = appRef.isStable.pipe(first((isStable) => isStable === true));
        const intervalForVersionCheck$ = interval(nbSecondsBetweenVersionCheck * 1000);
        const appIsStableOrIntervalForVersionCheck$ = concat(appIsStable$, intervalForVersionCheck$);

        appIsStableOrIntervalForVersionCheck$.subscribe(() => {
            if (swUpdate.isEnabled) {
                swUpdate.checkForUpdate();
            }
        });
        this.swUpdate.available.subscribe((evt) => {
            console.log('update available ', evt);
            this.notifyNewVersion();
        });
    }

    private notifyNewVersion() {
        const duration = 10 * 60 * 1000; // Notification affichée pendant 10mn
        this.notificationService
            .notifyWithAction(
                {
                    message: "Une nouvelle version de l'application est disponible !",
                    showCloseButton: true,
                    duration,
                },
                'Cliquez ici pour rafraîchir la page'
            )
            .subscribe((result) => {
                if (result === true) window.location.reload();
            });
    }
}
