import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Contact } from '../../../../model/contact.model';

@Component({
    selector: 'lib-confirm-dialog',
    templateUrl: './select-existing-contact-modal.component.html',
    styleUrls: ['./select-existing-contact-modal.component.scss'],
})
export class SelectExistingContactModalComponent implements OnInit {
    /**
     * Sécurité pour la confirmation
     */
    enableConfirm: boolean;

    constructor(
        private dialogRef: MatDialogRef<SelectExistingContactModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            confirmLabel: string;
            message: string;
            existingContact: Contact;
        }
    ) {}

    ngOnInit() {}

    onConfirm(): void {
        this.dialogRef.close('confirm');
    }

    onDismiss(): void {
        this.dialogRef.close('cancel');
    }

    onDuplicate(): void {
        this.dialogRef.close('duplicate');
    }
}
