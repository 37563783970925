import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { CommentairePredefini } from '../model/commentaire-predefini.model';

/**
 * Service d'appel aux APIs pour les categories de commentaires prédéfinis.
 * Ne pas appeler directement depuis les composants des pages, uniquement depuis d'autres services.
 * NB : On utilise HttpBackend au lieu de HttpClient ici, pour ne pas passer par les intercepteurs (donc on bypasse l'intercepteur).
 *      Le service appelant doit donc gérer lui-même les erreurs HTTP.
 */
@Injectable({
    providedIn: 'root',
})
export class CommentairePredefiniApiService {
    private http: HttpClient;

    public resourceUrl = environment.apiUrl + '/reference/commentaire-predefini';

    constructor(private readonly httpBackend: HttpBackend) {
        this.http = new HttpClient(httpBackend);
    }

    /**
     * Renvoie un commentaire prédéfini
     * @param id
     */
    findOne(id: string): Observable<CommentairePredefini> {
        return this.http.get<CommentairePredefini>(`${this.resourceUrl}/${id}`);
    }

    /**
     * Renvoie la liste complète de commentaires prédéfinis
     */
    findAll(): Observable<CommentairePredefini[]> {
        return this.http.get<CommentairePredefini[]>(`${this.resourceUrl}`);
    }

    /**
     * Crée ou met à jour un commentaire prédéfini
     * @param categorieCommentairePredefini
     */
    upsert(categorieCommentairePredefini: CommentairePredefini): Observable<CommentairePredefini> {
        return this.http.put<CommentairePredefini>(this.resourceUrl, categorieCommentairePredefini);
    }
}
