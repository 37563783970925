import { Injectable } from '@angular/core';
import { HttpErrorInterceptor, MongoUtils } from 'src/app/commons-lib';
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { Diagnostic } from '../model/diagnostic.model';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { OfflineStorageService, ResourceWrapper } from '../shared/offline/offline-storage.service';
import { STORAGE_KEY_BON_COMMANDE_TASKS, STORAGE_KEY_DIAGNOSTICS, STORAGE_KEY_REPORT_TASKS } from '../shared/constants/indexeddb.constants';
import { InterventionApiService } from './intervention-api.service';
import { BonCommandeAnalyseAdmin } from '../model/bon-commande.model';
import { BaseObject } from '../model/base-object.model';
import { combineLatestOrEmpty } from '../utils/rxjs.utils';

export class ReportTask {
    id: string;
    diagnostic: Diagnostic;
    idReferencePrestation: string;
    idIntervention: string;
}

export class BonCommandeTask {
    id: string;
    bonCommande: BonCommandeAnalyseAdmin;
    idReferencePrestation: string;
    idIntervention: string;
    idsBien: string[];
}

/**
 * Service d'appel aux APIs pour les diagnostics.
 * Ne pas appeler directement depuis les composants des pages, uniquement depuis d'autres services.
 * NB : On utilise HttpBackend au lieu de HttpClient ici, pour ne pas passer par les intercepteurs (donc on bypasse l'intercepteur {@link HttpErrorInterceptor}).
 *      Le service appelant doit donc gérer lui-même les erreurs HTTP.
 */
@Injectable({
    providedIn: 'root',
})
export class DiagnosticApiService {
    public resourceUrl = `${environment.apiUrl}/diagnostics`;

    private http: HttpClient;
    // Ressource pour le wrapper HL
    private resource: ResourceWrapper<Diagnostic, string> = this.offlineStorage.wrapRestResource<Diagnostic, string>({
        cacheName: STORAGE_KEY_DIAGNOSTICS,
        idField: 'id',
        resourceUrl: this.resourceUrl,
    });

    private reportTaskQueue = this.offlineStorage.wrapTaskResource<ReportTask>({
        cacheName: STORAGE_KEY_REPORT_TASKS,
        idField: 'id',
        task: (data?: ReportTask) =>
            this.http.post<ReportTask>(`${environment.apiUrl}/report/${data.idReferencePrestation}`, {
                diagnostic: data.diagnostic,
                idIntervention: data.idIntervention,
            }),
    });

    private bonDeCommandeTaskQueue = this.offlineStorage.wrapTaskResource<BonCommandeTask>({
        cacheName: STORAGE_KEY_BON_COMMANDE_TASKS,
        idField: 'id',
        task: (data?: BonCommandeTask) =>
            this.http
                .post<BonCommandeTask>(`${environment.apiUrl}/bon-de-commande/${data.idReferencePrestation}`, {
                    bonDeCommande: data.bonCommande,
                    idIntervention: data.idIntervention,
                    idsBien: data.idsBien,
                })
                .pipe(map(() => null)),
    });

    constructor(
        private httpBackend: HttpBackend,
        private offlineStorage: OfflineStorageService,
        private interventionApiService: InterventionApiService
    ) {
        this.http = new HttpClient(httpBackend);
    }

    /**
     * Renvoie un diagnostic à partir de son id.
     */
    findOne(idDiagnostic: string): Observable<Diagnostic> {
        return this.resource.getOne(idDiagnostic);
    }

    /**
     * Renvoie un tableau de diagnostics à partir de leurs ids.
     */
    findByIds(idsDiagnostics: string[]): Observable<Diagnostic[]> {
        const queryParams: any = {};
        if (idsDiagnostics && idsDiagnostics.length) {
            queryParams.idsDiagnostics = idsDiagnostics;
            return this.resource.getAll(queryParams);
        } else {
            return of([]);
        }
    }

    /**
     * Retourne toutes les prestations d'une intervention
     */
    getAllPrestationsDiagnosticByInterventionId(idIntervention: string): Observable<BaseObject[]> {
        let params = new HttpParams();
        params = params.set('idIntervention', idIntervention);
        return this.http.get<BaseObject[]>(`${this.resourceUrl}/prestation-parent`, { params });
    }

    /**
     * Retourne le diagnostic suivant une intervention et une prestationDiagnostic
     */
    getDiagnosticByInterventionIdAndPrestationDiagnosticId(idIntervention: string, idPrestationDiagnostic: string): Observable<Diagnostic> {
        let params = new HttpParams();
        params = params.set('idIntervention', idIntervention);
        params = params.set('idPrestationDiagnostic', idPrestationDiagnostic);
        return this.http.get<Diagnostic>(`${this.resourceUrl}/by-intervention-and-prestation-diagnostic`, { params });
    }

    /**
     * Crée ou met à jour un diagnostic.
     * Dans le cas d'une mise à jour, seul le contenu est mis à jour.
     */
    upsert(diagnostic: Diagnostic): Observable<Diagnostic> {
        return this.resource.save(diagnostic);
    }

    /**
     * Push les diagnostics du local vers le back
     */
    pushDiagnostics(): Observable<Diagnostic[]> {
        return this.resource.push();
    }

    /**
     * Pull les diagnostics dans l'indexDb
     */
    pullDiagnostics(): Observable<Diagnostic[]> {
        return this.interventionApiService.findAllForCurrentUserBetweenDates().pipe(
            switchMap((interventions) => {
                const idsDiagnostics = interventions
                    .flatMap((it) => it.prestationsDiagnostics.flatMap((p) => p.idDiagnostic))
                    .filter((it) => it !== null);
                return this.findByIds(idsDiagnostics).pipe(
                    tap((diagnostics) => console.log(`Pull de tous les diagnostics`, diagnostics)),
                    catchError((err) => {
                        console.log(`Diagnostics non récupérées (erreur)`, err);
                        return of(null);
                    })
                );
            })
        );
    }

    /**
     * appel au webService pour générer tous les bons de commande d'un diagnostic
     * @param diagnostic
     * @param idReferencePrestation
     */
    exportReport(diagnostic: Diagnostic, idReferencePrestation: string, idIntervention: string): Observable<Diagnostic> {
        return this.reportTaskQueue
            .save({ id: MongoUtils.generateObjectId(), diagnostic, idReferencePrestation, idIntervention })
            .pipe(map(() => diagnostic));
    }

    /**
     * Push les exports de rapport du local vers le back
     */
    pushExportReports(): Observable<ReportTask[]> {
        return this.reportTaskQueue.push();
    }

    /**
     * appel au webService pour générer tous les bons de commande d'un diagnostic
     * @param diagnostic
     * @param idReferencePrestation
     */
    exportBonCommandeAll(diagnostic: Diagnostic, idReferencePrestation: string, idIntervention: string, idsBien: string[]): Observable<Diagnostic> {
        return combineLatestOrEmpty(
            diagnostic.listeBonCommande.map((bonCommande) =>
                this.bonDeCommandeTaskQueue.save({
                    id: MongoUtils.generateObjectId(),
                    bonCommande,
                    idReferencePrestation,
                    idIntervention,
                    idsBien,
                })
            )
        ).pipe(map(() => diagnostic));
    }

    /**
     * Push les exports de rapport du local vers le back
     */
    pushExportBonCommandes(): Observable<BonCommandeTask[]> {
        return this.bonDeCommandeTaskQueue.push();
    }
}
