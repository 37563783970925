// @ts-check
'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** Balcony type
//***********************************************************************************
//***********************************************************************************

import { cn_element_type } from './cn_element_type';
import { cn_configuration, cn_configuration_choice, cn_configuration_line, cn_configuration_param, cn_configuration_tab } from './cn_configuration';
import { fh_add, fh_clone, fh_cross, fh_extruded_polygon, fh_mul, fh_normalize, fh_polygon, fh_sub } from '@acenv/fh-3d-viewer';
import { cn_element_type_visitor, code_to_label } from '..';

var CN_BT_MIDDLE = 0;
var CN_BT_OUTER = 1;
var CN_BT_INNER = 2;

var CN_BT_CATEGORY_LIST = [
    { label: 'Fer forgé', code: 'railing', filled: false, color: [50, 100, 50] },
    { label: 'Bois', code: 'wood', filled: false, color: [150, 125, 100] },
    { label: 'Cable', code: 'cable', filled: false, color: [100, 100, 100] },
    { label: 'Tole', code: 'plate', filled: true, color: [50, 50, 50], filling: [50, 50, 50] },
    { label: 'Verre', code: 'glass', filled: true, color: [50, 50, 100], filling: [200, 200, 255, 100] },
    { label: 'Béton', code: 'wall', filled: true, color: [150, 150, 150] },
    { label: 'Jardinière', code: 'planter', filled: true, color: [150, 150, 150] }
];

export class cn_balcony_type extends cn_element_type {
    constructor() {
        super();
        this.name = '';
        this.height = 1.1;
        this.thickness = 0.1;
        this.planter_width = 0;
        this.category = 'railing';

        this.free = false;
    }

    //***********************************************************************************
    get_generic_label() {
        return 'Type de balcon';
    }

    //***********************************************************************************
    /**
     * Clone
     * @returns {cn_element_type}
     */
    clone() {
        var c = new cn_balcony_type();
        c.name = this.name;
        c.height = this.height;
        c.thickness = this.thickness;
        c.category = this.category;
        c.planter_width = this.planter_width;
        return c;
    }

    //***********************************************************************************
    //**** keys
    //***********************************************************************************
    model_keys() {
        return ['name', 'height', 'thickness', 'category', 'planter_width'];
    }

    //***********************************************************************************
    //**** set category
    //***********************************************************************************
    set_category(category, actual_change = false) {
        if (this.category == category) return;
        this.category = category;
        if (actual_change)
            this.thickness = (this.category == 'wall') ? 0.2 : (this.category == 'planter') ? 0.5 : 0.1;
    }

    //***********************************************************************************
    //**** default
    //***********************************************************************************
    /**
     *
     * @returns cn_element_type
     */
    static default_railing() {
        return new cn_balcony_type();
    }

    /**
     *
     * @returns cn_element_type
     */
    static default_wall() {
        var bt = new cn_balcony_type();
        bt.height = 1.1;
        bt.thickness = 0.2;
        bt.category = 'wall';
        return bt;
    }

    //***********************************************************************************
    //**** serialize
    //***********************************************************************************
    serialize() {
        var json = {};
        json.class_name = 'cn_balcony_type';
        json.ID = this.ID;
        json.name = this.name;
        json.height = this.height;
        json.thickness = this.thickness;
        json.planter_width = this.planter_width;
        json.category = this.category;
        return json;
    }

    static unserialize(json) {
        if (typeof (json.ID) != 'string') return false;
        if (json.class_name != 'cn_balcony_type') return false;
        if (typeof (json.name) != 'string') return false;
        if (typeof (json.height) != 'number') return false;
        if (typeof (json.thickness) != 'number') return false;
        var wt = new cn_balcony_type();
        if (typeof (json.name) == 'string') wt.name = json.name;
        wt.ID = json.ID;
        wt.name = json.name;
        wt.height = json.height;
        wt.thickness = json.thickness;
        if (typeof (json.category) == 'string' && code_to_label(json.category, CN_BT_CATEGORY_LIST))
            wt.category = json.category;
        if (typeof (json.planter_width) == 'number') wt.planter_width = json.planter_width;
        return wt;
    }

    //***********************************************************************************
    /**
     * label
     * @returns {string}
     */
    get_label() {
        if (this.name != '') return this.name;
        var element_type_name = code_to_label(this.category, CN_BT_CATEGORY_LIST);
        element_type_name += ' ' + (this.height * 100).toFixed(0) + 'cm';
        return element_type_name;
    }

    //***********************************************************************************
    /**
     * Category label
     * @returns {string}
     */
    get_category_label() {
        return code_to_label(this.category, CN_BT_CATEGORY_LIST);
    }

    //***********************************************************************************
    //**** Returns description
    //***********************************************************************************

    /**
     * Returns an array of objects describing the type.
     * @returns {{label: string, value?: any, decimals?: number, unit?: string}[]}
     */
    get_description() {
        var description = [];

        var cat = { label: 'Catégorie' };
        cat.value = code_to_label(this.category, CN_BT_CATEGORY_LIST);
        description.push(cat);

        var height = { label: 'Hauteur' };
        description.push(height);
        height.value = this.height * 100;
        height.decimals = 0;
        height.unit = 'cm';

        var th = { label: 'Epaisseur' };
        description.push(th);
        th.value = this.thickness * 100;
        th.decimals = 0;
        th.unit = 'cm';

        return description;
    }

    //***********************************************************************************
    /**
     * draw svg icon
     * @param {number} width
     * @param {number} height
     * @returns {string}
     */
    draw_svg_icon(width, height) {
        var html = '';
        var z = height * 0.2;
        const cat = CN_BT_CATEGORY_LIST.find(ct => ct.code == this.category);
        var color = `rgb(${cat.color[0]},${cat.color[1]},${cat.color[2]})`;
        if (this.category == 'wall') {
            html += '<rect x=\'0\' y=\'' + z + '\' width=\'' + width + '\' height=\'' + (height - z) + '\' style=\'fill:rgb(150,150,150);stroke:black;\' />';
        } else if (this.category == 'planter') {
            z = height * 0.4;
            html += '<rect x=\'0\' y=\'' + z + '\' width=\'' + width + '\' height=\'' + (height - z) + '\' style=\'fill:rgb(150,150,150);stroke:black;\' />';
        } else {
            var ramp_width = height * 0.05;
            var rail_size = height * 0.02;
            var rail_height = height * 0.1;
            html += '<rect x=\'0\' y=\'' + z + '\' width=\'' + width + '\' height=\'' + ramp_width + '\' style=\'fill:' + color + ';stroke:black;\' />';
            if (!cat.filling)
                html += '<rect x=\'0\' y=\'' + (height - rail_height) + '\' width=\'' + width + '\' height=\'' + rail_size + '\' style=\'fill:' + color + ';stroke:black;\' />';
            var h = height - z - ramp_width;
            for (var n = 0; n < 2; n++) {
                var x = (n == 0) ? 0 : width - rail_size;
                html += '<rect x=\'' + x + '\' y=\'' + (z + ramp_width) + '\' width=\'' + rail_size + '\' height=\'' + h + '\' style=\'fill:' + color + ';stroke:black;\' />';
            }

            if (this.category == 'railing' || this.category == 'wood') {
                var nb_rails = 8;
                h = height - z - ramp_width - rail_height;
                var rail_spacing = (width - rail_size) / (nb_rails - 1);
                var x = rail_spacing;
                for (var n = 1; n < nb_rails - 1; n++) {
                    html += '<rect x=\'' + x + '\' y=\'' + (z + ramp_width) + '\' width=\'' + rail_size + '\' height=\'' + h + '\' style=\'fill:' + color + ';stroke:black;\' />';
                    x += rail_spacing;
                }
            } else if (cat.filling) {
                var filling_color = `rgb(${cat.filling[0]},${cat.filling[1]},${cat.filling[2]})`;
                h = height - z - 3 * ramp_width - rail_height;
                html += '<rect x=\'' + rail_size + '\' y=\'' + (z + 3 * ramp_width) + '\' width=\'' + (width - 2 * rail_size) + '\' height=\'' + h + '\' style=\'fill:' + filling_color + ';stroke:black;\' />';
            } else if (this.category == 'cable') {
                var nb_cables = 5;
                h = height - z - 3 * ramp_width - rail_height;
                for (var n = 0; n < nb_cables; n++) {
                    var y = z + ramp_width + (n + 1) * h / nb_cables;
                    html += `<line x1="${rail_size}" y1="${y}" x2="${width - rail_size}" y2="${y}" style="fill:none;stroke:black;" />`;
                }
            }
        }
        return html;
    }

    //***********************************************************************************
    //**** Comparison
    //***********************************************************************************
    is_equal_to(other) {
        if (this.name != other.name) return false;
        if (this.height != other.height) return false;
        if (this.thickness != other.thickness) return false;
        if (this.category != other.category) return false;
        if (this.category == 'planter' && this.planter_width != other.planter_width) return false;

        return true;
    }

    //***********************************************************************************
    //**** 3D geometry
    //***********************************************************************************
    build_extruded_polygons(z, wall) {
        var extruded_polygons = [];

        //*** Wall balcony
        const cat = CN_BT_CATEGORY_LIST.find(ct => ct.code == this.category);
        const color = cat.color.map(c => c / 255);
        if (this.category == 'wall' || this.category == 'planter') {
            if (this.height <= 0 || this.thickness <= 0) return extruded_polygons;
            var ctr2d = [];
            ctr2d.push(wall.shape[0]);
            if (wall.delegates[0] == null)
                ctr2d.push(wall.vertices[0].position);
            ctr2d.push(wall.shape[1]);
            ctr2d.push(wall.shape[2]);
            if (wall.delegates[1] == null)
                ctr2d.push(wall.vertices[1].position);
            ctr2d.push(wall.shape[3]);
            var ctr3d = [];
            for (var i in ctr2d) {
                var v = ctr2d[i];
                ctr3d.push([v[0], v[1], z]);
            }
            var epg = new fh_extruded_polygon();
            extruded_polygons.push(epg);
            epg.polygon = new fh_polygon(ctr3d[0], [0, 0, 1]);
            epg.polygon.add_contour(ctr3d);
            epg.direction = [0, 0, this.height];
            epg.color = color;

            if (this.category == 'planter') {
                const hole = epg.polygon.clone();
                hole.offset(-0.05, 2);
                epg.polygon.substracts(hole);

                var epg = new fh_extruded_polygon();
                extruded_polygons.push(epg);
                epg.polygon = hole;
                epg.direction = [0, 0, this.height - 0.05];
                epg.color = [80, 138, 44].map(x => x / 255);
            }
        }
        //*** railing balcony
        else {
            var ramp_thickness = 0.03;
            var bottom_height = 0.1;
            var rail_thickness = 0.015;
            var start_rail_thickness = 0.03;

            var v0 = wall.vertex_position(0);
            v0.push(z);
            var v1 = wall.vertex_position(1);
            v1.push(z);
            var direction = fh_sub(v1, v0);
            var length = fh_normalize(direction);
            var dz = [0, 0, 1];
            var dx = fh_cross(direction, dz);

            var p0 = fh_clone(v0);

            //*** ramp
            p0[2] = z + this.height - ramp_thickness * 0.5;
            var epg = new fh_extruded_polygon();
            extruded_polygons.push(epg);
            epg.tube(p0, fh_mul(direction, length), dx, this.thickness, ramp_thickness);
            epg.color = color;

            //*** Bottom bar
            if (!cat.filling) {
                p0[2] = z + bottom_height;
                p0 = fh_add(v0, fh_mul(dz, bottom_height));
                epg = new fh_extruded_polygon();
                extruded_polygons.push(epg);
                epg.tube(p0, fh_mul(direction, length), dx, rail_thickness, rail_thickness);
                epg.color = color;
            }

            //*** start rails
            epg = new fh_extruded_polygon();
            extruded_polygons.push(epg);
            epg.tube(v0, fh_mul(dz, this.height), dx, start_rail_thickness, start_rail_thickness);
            epg.color = color;

            epg = new fh_extruded_polygon();
            extruded_polygons.push(epg);
            epg.tube(v1, fh_mul(dz, this.height), dx, start_rail_thickness, start_rail_thickness);
            epg.color = color;

            //*** rails
            if (this.category == 'railing' || this.category == 'wood') {
                var nb_rails = 1 + Math.floor(length / (0.11 - rail_thickness));
                var rail_spacing = length / nb_rails;
                var ez = fh_mul(dz, this.height - bottom_height - ramp_thickness - rail_thickness * 0.5);
                p0 = fh_add(v0, fh_mul(dz, bottom_height + rail_thickness * 0.5));
                for (var n = 1; n < nb_rails; n++) {
                    epg = new fh_extruded_polygon();
                    extruded_polygons.push(epg);
                    epg.tube(fh_add(p0, fh_mul(direction, rail_spacing * n)), ez, dx, rail_thickness, rail_thickness);
                    epg.color = color;
                }
            }

            //*** cables */
            if (this.category == 'cable') {
                var nb_cables = 1 + Math.floor((this.height - bottom_height) / 0.11);
                var cable_spacing = (this.height - bottom_height) / nb_cables;
                for (var n = 1; n < nb_cables; n++) {
                    epg = new fh_extruded_polygon();
                    extruded_polygons.push(epg);
                    p0[2] = z + bottom_height + n * cable_spacing;
                    epg.tube(p0, fh_mul(direction, length), dx, 0.005, 0.005);
                    epg.color = color;
                }
            }

            if (cat.filling) {
                epg = new fh_extruded_polygon();
                extruded_polygons.push(epg);
                p0[2] = z + bottom_height;
                epg.brick(p0, fh_mul(direction, length), fh_mul(dx, 0.01), fh_mul(dz, this.height - 2 * bottom_height));
                epg.color = cat.filling.map(c => c / 255);
            }
        }
        return extruded_polygons;
    }

    //***********************************************************************************
    //**** Get configuration
    //***********************************************************************************
    static configuration() {
        var configuration = new cn_configuration();

        //***********************************************
        //*** Railing configuration
        var railing_unfilled = new cn_configuration_tab('Garde-corps ajouré', 'unfilled');
        configuration.add_tab(railing_unfilled);

        var railing_unfilled_list = new cn_configuration_line('Balustrade');
        railing_unfilled.add_line(railing_unfilled_list);

        railing_unfilled_list.add_param(new cn_configuration_param('Epaisseur', 'thickness', 1, 100, 'cm'));
        railing_unfilled_list.add_param(new cn_configuration_param('Hauteur', 'height', 30, 200, 'cm'));

        CN_BT_CATEGORY_LIST.filter(category => !category.filled).forEach(category => {
            railing_unfilled_list.add_choice(new cn_configuration_choice(category.label, category.code));
        });

        //***********************************************
        //*** Railing configuration
        var railing_filed = new cn_configuration_tab('Garde-corps plein', 'filled');
        configuration.add_tab(railing_filed);

        var railing_filed_list = new cn_configuration_line('Balustrade');
        railing_filed.add_line(railing_filed_list);

        railing_filed_list.add_param(new cn_configuration_param('Epaisseur', 'thickness', 1, 100, 'cm'));
        railing_filed_list.add_param(new cn_configuration_param('Hauteur', 'height', 30, 200, 'cm'));

        CN_BT_CATEGORY_LIST.filter(category => category.filled).forEach(category => {
            railing_filed_list.add_choice(new cn_configuration_choice(category.label, category.code));
        });

        return configuration
    }

    //***********************************************************************************
    //**** Fill configuration
    //***********************************************************************************
    fill_configuration(config = null) {
        var configuration = (config) ? config : cn_balcony_type.configuration();

        const cat = CN_BT_CATEGORY_LIST.find(ct => ct.code == this.category);

        //*** default to railing
        configuration.selection = (cat.filled) ? 1 : 0;

        const line = configuration.tabs[configuration.selection].lines[0];
        line.set_selection(this.category);

        configuration.tabs[0].lines[0].get_param('thickness').value = this.thickness * 100;
        configuration.tabs[0].lines[0].get_param('height').value = this.height * 100;
        configuration.tabs[1].lines[0].get_param('thickness').value = this.thickness * 100;
        configuration.tabs[1].lines[0].get_param('height').value = this.height * 100;

        return configuration;
    }

    //***********************************************************************************
    //**** Load configuration
    //***********************************************************************************
    load_configuration(configuration, actual_change = false) {
        const configuration_line = configuration.tabs[configuration.selection].lines[0];
        const dont_read_thickness = (actual_change && this.category != configuration_line.get_selection())
        this.set_category(configuration_line.get_selection(), actual_change);
        if (!dont_read_thickness)
            this.thickness = 0.01 * configuration_line.get_param('thickness').value;
        this.height = 0.01 * configuration_line.get_param('height').value;
        return true;
    }

    /**
     * Accept element type visitor
     *
     * @param {cn_element_type_visitor} element_type_visitor
     */
    accept_visitor(element_type_visitor) {
        element_type_visitor.visit_balcony_type(this);
    }

    /**
     * returns true if wall geometry hasn't changed since date
     * @param {number} date
     * @returns {boolean}
     */
    up_to_date_thickness(date) {
        if (this.get_date() <= date) return true;
        if (this.get_date('thickness') > date) return false;
        return true;
    }
}

