<lib-form-container [smallMode]="true">
    <h1>Terminer la réinitialisation du mot de passe</h1>

    <mat-form-field class="w-100">
        <mat-label>Code de réinitialisation</mat-label>
        <input matInput type="text" required autocomplete="off" [(ngModel)]="resetKey" />
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>Nouveau mot de passe</mat-label>
        <input
            matInput
            type="password"
            required
            autocomplete="off"
            [(ngModel)]="newPassword"
            minlength="4"
            maxlength="100"
            #newPasswordField="ngModel"
        />
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>Confirmez le nouveau mot de passe</mat-label>
        <input matInput type="password" required autocomplete="off" [(ngModel)]="newPasswordConfirm" />
    </mat-form-field>

    <div class="text-center mt-3">
        <button
            mat-raised-button
            color="accent"
            [disabled]="
                !resetKey ||
                !newPassword ||
                !newPasswordConfirm ||
                !newPasswordField.valid ||
                newPassword !== newPasswordConfirm
            "
            (click)="submit()"
        >
            <span>Réinitialiser le mot de passe</span>
        </button>
    </div>
</lib-form-container>
