import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { NotificationComponent } from './notification.component';
import { LibPipesModule } from '../pipes/pipes.module';
import { NotificationService } from './notification.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    imports: [CommonModule, LibPipesModule, MatIconModule, MatSnackBarModule, MatButtonModule],
    declarations: [NotificationComponent],
})
export class LibNotificationModule {
    static forRoot(): ModuleWithProviders<LibNotificationModule> {
        return {
            ngModule: LibNotificationModule,
            providers: [NotificationService],
        };
    }
}
