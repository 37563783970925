<h1 mat-dialog-title class="primary">Sélectionnez les plans à associer</h1>

<div mat-dialog-content>
    <div *ngIf="screenshotsAvailable.length === 0">Aucun plan de repérage n'a été créé pour ce niveau.</div>
    <app-plans-list
        *ngIf="screenshotsAvailable.length > 0"
        class="liste-screenshots mb-3"
        [enableSelection]="true"
        [selectedScreenshots]="screenshotsSelected"
        [screenshotsSvgWithJson]="screenshotsAvailable"
        (selectionChanged)="selectionChanged($event)"
    ></app-plans-list>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-button color="primary" (click)="cancel()">
        <lib-icon>close</lib-icon>
        <span>{{ 'Annuler' | uppercase }}</span>
    </button>
    <button mat-raised-button color="accent" (click)="confirm()">
        <lib-icon>check</lib-icon>
        <span>{{ 'Valider' | uppercase }}</span>
    </button>
</div>
