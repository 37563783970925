import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditMode } from 'src/app/model/edit-mode.model';
import { ParameterDefinition, parametersTypesStandard, ParameterType } from '@acenv/commons-lib';

@Component({
    selector: 'app-edit-parameter-modal',
    templateUrl: './edit-parameter-modal.component.html',
    styleUrls: ['./edit-parameter-modal.component.scss'],
})
export class EditParameterModalComponent extends BaseComponent implements OnInit {
    parameterDefinition: ParameterDefinition;
    parameterValue: any;
    mode: EditMode = 'CREATE';
    listType: ParameterType[] = parametersTypesStandard;
    listChoices: String[];

    constructor(private readonly dialogRef: MatDialogRef<EditParameterModalComponent>, @Inject(MAT_DIALOG_DATA) readonly data: any) {
        super();
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth1000-dialog');

        if (this.data.parameterDefinition != null) {
            this.parameterDefinition = this.data.parameterDefinition;
        }

        this.parameterValue = this.data.parameterValue;
        if (this.data.parameterValue != null) {
            this.mode = 'EDIT';
        }

        if (this.parameterDefinition.choices.findIndex((c) => c == this.parameterValue) == -1) {
            this.parameterDefinition.choices.push(this.parameterValue);
        }
    }

    onClickSaveModification() {
        this.dialogRef.close({
            codeBim: this.parameterDefinition.codeBim,
            parameterValue: this.parameterValue,
        });
    }

    onClickCancel() {
        this.dialogRef.close(false);
    }
}
