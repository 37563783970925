'use strict';
import { extension_instance } from '../../extension/cn_extension';
//***********************************************************************************
//***********************************************************************************
//**** Build nomenclatures
//***********************************************************************************
//***********************************************************************************
import { cn_building } from '../../model/cn_building';
import { cn_storey, STOREY_EXTERIOR_LABEL } from '../../model/cn_storey';
import { cn_zone } from '../../model/cn_zone';
import { cn_nomenclature, push_cn_nomenclatures_zones } from '../cn_nomenclature';

//***********************************************************************************
/**
 * Buils space nomenclatures for the building
 * @param {cn_building} building
 * @param is_exterior
 * @returns {cn_nomenclature[]}
 */
export function cn_nomenclature_spaces(building, is_exterior = false) {
    const output = [];
    push_cn_nomenclatures_zones(output, false);
    output.push(new cn_nomenclature('Nom'));
    output.push(new cn_nomenclature('Usage'));
    output.push(new cn_nomenclature('Pièces rattachées'));

    if (is_exterior) {
        output.push(new cn_nomenclature('Revêtement'));
        output.push(new cn_nomenclature('Niveau'));
        output.push(new cn_nomenclature('Surface totale').withNumberDefinition('m²', 2));
        output.push(new cn_nomenclature('Surface déclarée').withNumberDefinition('m²', 2).withVisibility(false));
        output.push(new cn_nomenclature('Surface nette').withNumberDefinition('m²', 2));
        output.push(new cn_nomenclature('Périmètre').withNumberDefinition('m', 2));
        output.push(new cn_nomenclature('Périmètre déclaré').withNumberDefinition('m', 2).withVisibility(false));

        prepare_area_contexts(building, output);
        populate_nomenclature_spaces(building.exterior, output, true, building)
    } else {
        output.push(new cn_nomenclature('Type'));
        output.push(new cn_nomenclature('Revêtement de sol'));
        output.push(new cn_nomenclature('Revêtement de plafond'));
        output.push(new cn_nomenclature('Niveau'));
        output.push(new cn_nomenclature('Hauteur max sous plafond').withNumberDefinition('m', 2));
        output.push(new cn_nomenclature('Surface totale').withNumberDefinition('m²', 2));
        output.push(new cn_nomenclature('Surface plancher').withNumberDefinition('m²', 2));
        output.push(new cn_nomenclature('Surface déclarée').withNumberDefinition('m²', 2).withVisibility(false));
        output.push(new cn_nomenclature('Périmètre').withNumberDefinition('m', 2).withVisibility(false));
        output.push(new cn_nomenclature('Périmètre déclaré').withNumberDefinition('m', 2).withVisibility(false));
        output.push(new cn_nomenclature('Volume').withNumberDefinition('m³', 2));
        output.push(new cn_nomenclature('Espace chauffé').withType('boolean'));

        prepare_area_contexts(building, output);
        building.storeys.length && building.storeys.forEach((storey) => {
            populate_nomenclature_spaces(storey, output, false, building);
        });
    }

    return output;
}

//***********************************************************************************
/**
 *
 * @param {cn_storey} storey
 * @param {any[]} output
 * @param {boolean} is_exterior
 * @param {cn_building} building
 */
function populate_nomenclature_spaces(storey, output, is_exterior, building) {
    const bbp_zone_types = extension_instance.zone.get_zones_tools().filter(it => it.is_bbp_zone_type).map(it => it.property);
    const zones_types_by_code = building._reference_data_zones.reduce((obj, type) => {
        obj[type.codeBim] = type;
        return obj;
    }, {});
    const spaces_types_by_code = building._reference_data_spaces.reduce((obj, type) => {
        obj[type.codeBim] = type;
        return obj;
    }, {});
    storey.scene.storey = storey;
    storey.scene.update_deep();
    storey.build_space_volume();
    storey.scene.spaces.length && storey.scene.spaces.filter(space => !space.parent_space).forEach(space => {
        if (!space.outside) {
            let k = 0;

            //*** Zones */
            cn_zone.get_zoning_types().forEach(zoning_type => {
                const zone = space.get_zone(zoning_type, storey);
                output[k].values.push(zone ? zone.name : '');
                k++;
                if (bbp_zone_types.includes(zoning_type)) {
                    const zone_type_code = zone ? zone.zone_type : null;
                    const zone_type = zones_types_by_code[zone_type_code];
                    const zone_type_label = zone_type ? zone_type.name : '';
                    output[k].values.push(zone_type_label);
                    k++;
                }
            });

            //*** name */
            output[k].values.push(space.get_name(storey));
            k++

            //*** Usage */
            const space_type_code = space.space_usage;
            const space_type = spaces_types_by_code[space_type_code];
            const space_type_label = space_type ? space_type.name : '';
            output[k].values.push(space_type_label);
            k++

            //*** children space name */
            output[k].values.push(space.get_children_spaces().map(s => s.get_name(storey)).join(', '));
            k++

            if (!is_exterior) {
                //*** Type */
                if (!space.has_roof) {
                    output[k].values.push('Terrasse');
                } else if (!space.is_indoor()) {
                    output[k].values.push('Terrasse couverte');
                } else {
                    output[k].values.push('Intérieur');
                }
                k++
            }

            //*** Floor Facing */
            output[k].values.push((space.facings[0]) ? space.facings[0].name : 'Aucun');
            k++;

            //*** Ceiling Facing */
            if (!is_exterior) {
                output[k].values.push((space.facings[1]) ? space.facings[1].name : 'Aucun');
                k++;
            }

            //*** storey */
            let storey_name = storey.exterior ? STOREY_EXTERIOR_LABEL : storey.storey_index;
            output[k].values.push(storey_name);
            k++

            //*** Compute space volume */
            const space_volume = (!is_exterior && space.has_roof) ? space.build_solid(storey) : null;

            if (!is_exterior) {
                //*** ceiling heigth */
                const space_height = (space_volume) ? space_volume.get_bounding_box().size[2] : 0;
                output[k].values.push(space_height);
                k++
            }

            //*** full area  */
            output[k].values.push(space.get_area(true));
            k++

            if (!is_exterior) {
                //*** floor area  */
                output[k].values.push(space.get_plain_area(true));
                k++
            }

            //*** Surface déclarée */
            const declared_area = space.declared_area && space.declared_area !== -1 ? space.declared_area : null;
            output[k].values.push(declared_area);
            k++

            if (is_exterior) {
                //*** Neat area  */
                output[k].values.push(space.plain_area);
                k++
            }

            //*** Perimeter */
            output[k].values.push(space.get_perimeter());
            k++

            //*** Périmètre déclaré */
            const declared_perimeter = space.declared_perimeter && space.declared_perimeter !== -1 ? space.declared_perimeter : null;
            output[k].values.push(declared_perimeter);
            k++

            if (!is_exterior) {
                var volume = (space_volume) ? space_volume.get_volume() : 0;
                space.get_children_spaces().forEach(sp => {
                    const sv = sp.build_solid(storey);
                    if (sv) volume += sv.get_volume();
                });
                output[k].values.push(volume);
                k++

                output[k].values.push(space.is_heated());
                k++
            }

            //*** Display areas according to regulation */
            building.area_contexts.forEach(area_context => {
                for (let nl = 0; nl === 0 || nl < area_context.sub_labels.length; nl++) {
                    output[k].values.push(space.get_area_from_context(area_context, nl, storey, true));
                    k++
                }
            });
        }

    });
}

function prepare_area_contexts(building, output) {
    building.area_contexts.forEach(area_context => {
        area_context.update_deep();
        if (area_context.sub_labels.length === 0) {
            output.push(new cn_nomenclature(area_context.label).withNumberDefinition('m²', 2));
        } else {
            area_context.sub_labels.forEach(lbl => output.push(new cn_nomenclature(lbl).withNumberDefinition('m²', 2)));
        }
    });
}
