'use strict';
//***********************************************************************************
//***********************************************************************************
//**** Thermal model : a common class for exporting thermal data
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//**** Global functions :
//***********************************************************************************

import { fh_add, fh_clone, fh_matrix, fh_mul, fh_polygon, fh_solid } from '@acenv/fh-3d-viewer';
import { cn_element } from '../../model/cn_element';
import { cn_roof_slab } from '../../model/cn_roof_slab';
import { cn_space } from '../../model/cn_space';
import { cn_storey } from '../../model/cn_storey';
import { cn_storey_element } from '../../model/cn_storey_element';
import { CN_INNER } from '../../model/cn_wall';
import { logger } from '../cn_logger';
import {
    cn_clone,
    cn_dist,
    cn_dot,
    cn_md5,
    cn_middle,
    cn_normal,
    cn_sub,
    cnx_add,
    cnx_clone,
    cnx_cross,
    cnx_dist,
    cnx_dot,
    cnx_mul,
    cnx_normalize,
    cnx_sub
} from '../cn_utilities';

//***********************************************************************************
//***********************************************************************************
//**** cn_thermal_space class
//***********************************************************************************
//***********************************************************************************

/**
 * Creates a unique id
 * @param  {...any} args
 * @returns {string}
 */
var UNIQUE_IDS = {};

function _build_unique_id(...args) {
    var id = 'cn' + cn_md5(...args);
    for (var niter = 0; ; niter++) {
        if (typeof (UNIQUE_IDS[id]) == 'undefined') {
            UNIQUE_IDS[id] = true;
            return id;
        }
        id = 'cn' + cn_md5(id, niter);
    }
    return '';
}


export class cn_storey_space {
    /**
     * Constructor
     * @param {cn_space} space
     * @param {cn_storey} storey
     */
    constructor(space, storey) {
        this.space = space;
        this.storey = storey;
    }

}

export class cn_thermal_space {
    /**
     * Constructor
     * @param {fh_solid} solid
     * @param {cn_storey_space} storey_space
     */
    constructor(solid, storey_space) {
        this.solid = solid;
        this.storey_space = storey_space;
        this.zone = null;
        this.id = _build_unique_id(storey_space.storey, storey_space.space);
        this.name = storey_space.space.get_name(storey_space.storey);
    }
}

//***********************************************************************************
//***********************************************************************************
//**** cn_thermal_zone class
//***********************************************************************************
//***********************************************************************************

export class cn_thermal_zone {
    /**
     * Constructor
     * @param {cn_element} element
     */
    constructor(element) {
        this.element = element;
        this.spaces = [];
        this.id = _build_unique_id(element);
        // @ts-ignore
        this.name = (element.constructor == cn_storey) ? element.short_name : element.name;
    }
}

//***********************************************************************************
//***********************************************************************************
//**** cn_thermal_wall class
//**** Describes an horizontal wall, or a vertical one.
//**** - horizontal wall : space_0 is below, space_1 is above,  */
//**** - vertical wall : space_0 is inside, space_1 is outside,  */
//***********************************************************************************
//***********************************************************************************

export class cn_thermal_wall {
    /**
     * Constructor
     * @param {fh_polygon} polygon
     * @param {number[]} axis_offset
     * @param {cn_storey_element} storey_element
     * @param {cn_thermal_space} space_0
     * @param {cn_thermal_space} space_1
     */
    constructor(polygon, axis_offset, storey_element, space_0, space_1) {
        this.id = (storey_element) ? _build_unique_id(storey_element.storey, storey_element.element) : _build_unique_id('dummy');
        this.polygon = polygon;
        this.spaces = [space_0, space_1];
        this.cn_spaces = this.spaces.map(sp => (sp && sp.storey_space) ? sp.storey_space.space : null);
        this.vertical = Math.abs(polygon.get_normal()[2]) < 0.1;
        this.storey_element = storey_element;
        this.openings = [];
        this.element_type = null;
        this.axis_offset = axis_offset;
        if (storey_element && storey_element.element) {
            const element = storey_element.element;
            // @ts-ignore
            if (typeof (element.wall_type) == 'object')
                // @ts-ignore
                this.element_type = element.wall_type;

            // @ts-ignore
            if (typeof (element.slab_type) == 'object')
                // @ts-ignore
                this.element_type = element.slab_type;
        }
        this.contact_type = null;
        this.lossy = false;
        this.underground = false;
        this.on_ground = false;
    }

    compute_contact() {
        const heated = [false, false];
        const indoor = [false, false];
        for (var side = 0; side < 2; side++) {
            if (this.cn_spaces[side] && this.spaces[side] && this.spaces[side].storey_space && this.cn_spaces[side].is_indoor(this.spaces[side].storey_space.storey)) {
                indoor[side] = true;
                heated[side] = this.cn_spaces[side].is_heated();
            }
        }
        //const heated = [0, 1].map(side => this.cn_spaces[side] && this.cn_spaces[side].is_heated());
        //const indoor = [0, 1].map(side => this.cn_spaces[side] && this.cn_spaces[side].is_indoor(this.spaces[side].storey_space.storey));
        this.lossy = (heated[0] != heated[1]);
        if (this.vertical) {
            if (this.element_type && this.element_type.free) {
                this.contact_type = { code: 'virtual', label: 'Paroi virtuelle', gbxml: 'Air' };
            } else if (indoor[0] && indoor[1]) {
                if (heated[0] && heated[1])
                    this.contact_type = { code: 'wall_h_h', label: 'Mur intérieur', gbxml: 'InteriorWall' };
                else if (heated[0] || heated[1])
                    this.contact_type = { code: 'wall_h_nh', label: 'Mur sur LNC', gbxml: 'InteriorWall' };
                else
                    this.contact_type = { code: 'wall_nh_nh', label: 'Mur LNC', gbxml: 'InteriorWall' };
            } else if (indoor[0] || indoor[1]) {
                if (this.underground) {
                    if (heated[0] || heated[1])
                        this.contact_type = { code: 'wall_h_ug', label: 'Façade enterrée', gbxml: 'UndergroundWall' };
                    else
                        this.contact_type = { code: 'wall_nh_ug', label: 'Façade LNC enterrée', gbxml: 'UndergroundWall' };
                } else if (heated[0] || heated[1])
                    this.contact_type = { code: 'wall_h_ex', label: 'Façade', gbxml: 'ExteriorWall' };
                else
                    this.contact_type = { code: 'wall_nh_ex', label: 'Façade LNC', gbxml: 'ExteriorWall' };
            } else {
                if (this.underground)
                    this.contact_type = { code: 'wall_ug_ug', label: 'Mur extérieur enterré', gbxml: 'Shade' };
                else
                    this.contact_type = { code: 'wall_ex_ex', label: 'Mur extérieur', gbxml: 'Shade' };
            }
        } else {
            if (this.polygon.get_normal()[2] < 0) {
                const h = heated[0];
                heated[0] = heated[1];
                heated[1] = h;
            }
            if (indoor[0] && indoor[1]) {
                if (heated[0] && heated[1])
                    this.contact_type = { code: 'slab_h_h', label: 'Plancher intermédiaire', gbxml: 'InteriorFloor' };
                else if (heated[0])
                    this.contact_type = { code: 'slab_h_nh', label: 'Plancher sous LNC', gbxml: 'InteriorFloor' };
                else if (heated[1])
                    this.contact_type = { code: 'slab_nh_h', label: 'Plancher sur LNC', gbxml: 'InteriorFloor' };
                else
                    this.contact_type = { code: 'slab_nh_nh', label: 'Plancher intermédiaire LNC', gbxml: 'InteriorFloor' };
            } else if (indoor[0]) {
                if (heated[0]) {
                    if (this.underground)
                        this.contact_type = { code: 'slab_h_ug', label: 'Plancher haut enterré', gbxml: 'UndergroundCeiling' };
                    else if (heated[0])
                        this.contact_type = { code: 'slab_h_ex', label: 'Toiture', gbxml: 'Roof' };
                } else if (this.underground)
                    this.contact_type = { code: 'slab_nh_ug', label: 'Plancher haut enterré sur LNC', gbxml: 'UndergroundCeiling' };
                else
                    this.contact_type = { code: 'slab_nh_ex', label: 'Toiture sur LNC', gbxml: 'Roof' };
            } else if (indoor[1]) {
                if (heated[1]) {
                    if (this.underground)
                        this.contact_type = { code: 'slab_ug_h', label: 'Plancher bas enterré', gbxml: 'UndergroundSlab' };
                    else if (!this.on_ground)
                        this.contact_type = { code: 'slab_ex_h', label: 'Plancher bas surplomb', gbxml: 'ExposedFloor' };
                    else
                        this.contact_type = { code: 'slab_g_h', label: 'Plancher bas', gbxml: 'SlabOnGrade' };
                } else if (this.underground)
                    this.contact_type = { code: 'slab_ug_nh', label: 'Plancher bas LNC enterré', gbxml: 'UndergroundSlab' };
                else if (!this.on_ground)
                    this.contact_type = { code: 'slab_ex_nh', label: 'Plancher bas LNC surplomb', gbxml: 'ExposedFloor' };
                else
                    this.contact_type = { code: 'slab_g_nh', label: 'Plancher bas LNC', gbxml: 'SlabOnGrade' };
            } else {
                if (this.underground)
                    this.contact_type = { code: 'slab_ug_ug', label: 'Dalle extérieure enterrée', gbxml: 'Shade' };
                else if (!this.on_ground)
                    this.contact_type = { code: 'slab_ex_ex', label: 'Dalle extérieure surplomb', gbxml: 'Shade' };
                else
                    this.contact_type = { code: 'slab_g_ex', label: 'Dalle extérieure', gbxml: 'Shade' };
            }
        }
    }
}

//***********************************************************************************
//***********************************************************************************
//**** cn_thermal_conexion class
//**** Describes a line, border of a cn_thermal_wall.
//***********************************************************************************
//***********************************************************************************

const MINIMAL_THERMAL_BRIDGE_LENGTH = 0.3;

export class cn_thermal_connexion {
    /**
     *
     * @param {number[]} p0
     * @param {number[]} p1
     * @param {number[]} normal
     * @param {cn_thermal_wall} surface
     */
    constructor(p0, p1, normal, surface) {
        this.p0 = p0;
        this.p1 = p1;
        this.normal = normal;
        this.surface = surface;

        this.direction = cnx_sub(p1, p0);
        this.length = cnx_normalize(this.direction);

        this.storey_index = surface.storey_element.storey.storey_index;

        this.vertical = Math.abs(this.direction[2]) > 0.9;
        this.wall_direction = cnx_cross(this.normal, this.direction);

        // @ts-ignore
        this.heated = [0, 1].map(side => !!this.surface.cn_spaces[side] && this.surface.cn_spaces[side].is_heated());
    }

    matches(connexion) {
        if (Math.abs(cnx_dot(this.normal, connexion.normal)) > 0.9) return 0;
        const y = cnx_dot(this.normal, cnx_sub(connexion.p0, this.p0));
        if (y < this.surface.axis_offset[0] - 0.01) return -1;
        if (y > this.surface.axis_offset[1] + 0.01) return -1;
        const y1 = cnx_dot(connexion.normal, cnx_sub(this.p0, connexion.p0));
        if (y1 < connexion.surface.axis_offset[0] - 0.01) return -1;
        if (y1 > connexion.surface.axis_offset[1] + 0.01) return -1;

        return 1;
    }

    reverse() {
        const p = this.p0;
        this.p0 = this.p1;
        this.p1 = p;
        this.direction = cnx_mul(this.direction, -1);
    }

    cut(x) {
        if (x < 0 || x >= this.length) return null;
        const pp = cnx_add(this.p0, cnx_mul(this.direction, x));
        var connexion = null;
        if (x < this.length - MINIMAL_THERMAL_BRIDGE_LENGTH) {
            connexion = new cn_thermal_connexion(pp, this.p1, this.normal, this.surface);
            connexion.wall_direction = this.wall_direction;
        }
        this.p1 = pp;
        this.length = x;
        return connexion;
    }
};

//***********************************************************************************
//***********************************************************************************
//**** cn_thermal_bridge class
//**** Describes athermal bridge.
//***********************************************************************************
//***********************************************************************************

export class cn_thermal_bridge {
    /**
     * Constructor
     * @param {cn_thermal_connexion} connexion
     */
    constructor(connexion) {
        this.connexions = [connexion];
        this.storey_index = connexion.storey_index;
        this.vertical = connexion.vertical;
        this.p0 = connexion.p0;
        this.p1 = connexion.p1;
        this.direction = connexion.direction;
        this.length = cnx_dist(this.p0, this.p1);
        this.connexion_type = null;
        this.lossy = false;
    }

    /**
     *
     * @param {cn_thermal_connexion} connexion
     * @returns {{additional_thermal_bridges,additional_connexions}}
     */
    can_absorb(connexion) {
        if (connexion.length < MINIMAL_THERMAL_BRIDGE_LENGTH) return null;
        if (this.length < MINIMAL_THERMAL_BRIDGE_LENGTH) return null;
        if (this.storey_index < connexion.storey_index - 1) return null;
        if (this.vertical != connexion.vertical) return null;
        const dot_product = cnx_dot(this.direction, connexion.direction);
        const abs_dot_product = Math.abs(dot_product);
        if (abs_dot_product < 0.99) return null;
        var potential = false;
        for (var i in this.connexions) {
            const r = this.connexions[i].matches(connexion);
            if (r < 0) return null;
            if (r > 0) potential = true;
        }
        if (!potential) return null;

        var x0 = cnx_dot(cnx_sub((dot_product > 0) ? connexion.p0 : connexion.p1, this.p0), this.direction);
        var x1 = cnx_dot(cnx_sub((dot_product > 0) ? connexion.p1 : connexion.p0, this.p0), this.direction);
        if (x1 < MINIMAL_THERMAL_BRIDGE_LENGTH) return null;
        if (x0 > this.length - MINIMAL_THERMAL_BRIDGE_LENGTH) return null;
        if (x0 >= x1) return null;
        if (dot_product < 0) connexion.reverse();

        const display_log = false;
        if (display_log) {
            var log = '';
            log += `x0=${x0}, x1=${x1}, length=${this.length}}`
        }

        const res = { additional_thermal_bridges: [], additional_connexions: [] };
        if (x0 < 0) {
            const new_cn = connexion.cut(-x0 / abs_dot_product);
            if (!new_cn) return null;
            if (connexion.length > MINIMAL_THERMAL_BRIDGE_LENGTH) res.additional_connexions.push(connexion);
            connexion = new_cn;
            x0 = 0;
            if (display_log) log += ` - cutting c0`;
        }
        if (x1 > this.length) {
            const new_cn = connexion.cut((this.length - x0) / abs_dot_product);
            if (new_cn && new_cn.length >= MINIMAL_THERMAL_BRIDGE_LENGTH) res.additional_connexions.push(new_cn);
            x1 = this.length;
            if (display_log) log += ` - cutting c1`;
        }

        var thermal_bridge = null;
        if (x0 > 0) {
            thermal_bridge = this.cut(x0);
            if (!thermal_bridge) return res;
            if (display_log) log += ` - cutting t0 : ${this.length} + ${thermal_bridge.length}`;
            res.additional_thermal_bridges.push(thermal_bridge);
            x1 -= x0;
        } else
            thermal_bridge = this;

        if (x1 < thermal_bridge.length) {
            const stb = thermal_bridge.cut(x1);
            if (stb) res.additional_thermal_bridges.push(stb);
            if (display_log) log += ` - cutting t1`;
        }
        if (display_log) log += ` - new connexion : ${connexion.length}`;
        thermal_bridge.connexions.push(connexion);
        thermal_bridge.compute();
        x0 = cnx_dot(cnx_sub(connexion.p0, thermal_bridge.p0), thermal_bridge.direction);
        x1 = cnx_dot(cnx_sub(connexion.p1, thermal_bridge.p0), thermal_bridge.direction);
        if (display_log) {
            logger.log(`Merging : ${x0} - ${x1} (length ${thermal_bridge.length})`);
            if (Math.abs(x0) > 0.01 || Math.abs(thermal_bridge.length - x1) > 0.01) {
                logger.log('ERREUR!!!', log);
                logger.log(`${cnx_dot(thermal_bridge.direction, thermal_bridge.p0)} - ${cnx_dot(thermal_bridge.direction, thermal_bridge.p1)} - ${thermal_bridge.length}`);
                thermal_bridge.connexions.forEach(c => {
                    logger.log(`${cnx_dot(thermal_bridge.direction, c.p0)} - ${cnx_dot(thermal_bridge.direction, c.p1)} - ${c.length}`)
                })
            }
        }
        return res;
    }

    /**
     *
     * @param {number} x
     * @returns {cn_thermal_bridge}
     */
    cut(x) {
        var new_tb = null;
        var new_connexions = [];
        this.connexions.forEach(cn => {
            var new_cn = null;
            new_connexions.push(cn);
            new_cn = cn.cut(x / cnx_dot(this.direction, cn.direction));
            if (new_cn) {
                if (!new_tb) new_tb = new cn_thermal_bridge(new_cn);
                else new_tb.connexions.push(new_cn);
            }
        });
        this.connexions = new_connexions;
        this.compute();
        // @ts-ignore
        if (new_tb) new_tb.compute();
        return new_tb;
    }

    compute() {
        this.p0 = [0, 0, 0];
        this.p1 = [0, 0, 0];
        this.connexions.forEach(cn => {
            this.p0 = cnx_add(this.p0, cn.p0);
            this.p1 = cnx_add(this.p1, cn.p1);
        });
        this.p0 = cnx_mul(this.p0, 1 / this.connexions.length);
        this.p1 = cnx_mul(this.p1, 1 / this.connexions.length);
        this.direction = cnx_sub(this.p1, this.p0);
        this.length = cnx_normalize(this.direction);
    }

    /**
     *
     * @returns {{code:string, attribute:string,label:string}}
     */
    get_connexion_type() {
        const outdoor_connexions = this.connexions.filter(connexion => connexion.heated[0] != connexion.heated[1]);

        this.lossy = outdoor_connexions.length > 0;

        this.id = _build_unique_id(this.connexions.map(c => c.surface.id));

        //**** Vertical connexions */
        if (this.vertical) {
            var nb_outer_walls = 0;
            const directions = outdoor_connexions.map(connexion => connexion.wall_direction);
            if (outdoor_connexions.length == 2) {
                if (cnx_dot(directions[0], directions[1]) < -0.9) {
                    if (this.connexions.length > 2)
                        return { code: 'facade_inner_wall', attribute: 'FacadeInnerWall', label: 'Façade / Refend' };
                    else
                        return { code: 'unknown', attribute: 'Unknown', label: 'Inconnu vertical' };
                } else {
                    var normal = outdoor_connexions[0].normal;
                    if (outdoor_connexions[0].heated[1]) normal = cnx_mul(normal, -1);
                    if (cnx_dot(normal, directions[1]) > 0)
                        return { code: 'facade_angle_inner', attribute: 'FacadeAngleInner', label: 'Façades angle rentrant' };
                    else
                        return { code: 'facade_angle_outer', attribute: 'FacadeAngleOuter', label: 'Façades angle sortant' };
                }
            } else
                return { code: 'inner_walls', attribute: 'InnerWalls', label: 'Refends' };
        }

        //**** Horizontal connexions */
        const indoor_connexions = this.connexions.filter(connexion => connexion.heated[0] == connexion.heated[1]);
        const roof_connexions = outdoor_connexions.filter(connexion => connexion.normal[2] > 0.5 && connexion.heated[0]);
        const floor_connexions = outdoor_connexions.filter(connexion => connexion.normal[2] > 0.5 && connexion.heated[1]);
        const wall_connexions = outdoor_connexions.filter(connexion => connexion.normal[2] < 0.5);
        const intermediate_connexions = indoor_connexions.filter(connexion => connexion.normal[2] > 0.5 && connexion.heated[0]);
        const outside_slab_connexions = indoor_connexions.filter(connexion => connexion.normal[2] > 0.5 && !connexion.heated[0]);
        const inner_connexions = indoor_connexions.filter(connexion => connexion.normal[2] < 0.5 && connexion.heated[0]);
        if (roof_connexions.length) {
            if (wall_connexions.length) {
                if (intermediate_connexions.length) return { code: 'roof_facade_intermediate', attribute: 'RoofFacadeIntermediate', label: 'Toiture / Façade / Intermédiaire' };
                if (wall_connexions[0].wall_direction[2] > 0) return { code: 'roof_upper_facade', attribute: 'RoofUpperFacade', label: 'Toiture / Façade supérieure' };
                return { code: 'roof_facade', attribute: 'RoofFacade', label: 'Toiture / Façade' };
            }
            if (roof_connexions.length > 1) {
                if (inner_connexions.length) return { code: 'roof_roof_inner_wall', attribute: 'RoofRoofInnerWall', label: 'Toiture / Toiture / Refend' };
                return { code: 'roof_roof', attribute: 'RoofRoof', label: 'Toiture / Toiture' };
            }
        } else if (floor_connexions.length) {
            if (wall_connexions.length) return { code: 'lower_floor_soil_facade', attribute: 'LowerFloorSoilFacade', label: 'Plancher bas / Façade' };
            if (inner_connexions.length) return { code: 'lower_floor_soil_inner_wall', attribute: 'LowerFloorSoilInnerWall', label: 'Plancher bas / Refend' };
        } else if (intermediate_connexions.length) {
            if (wall_connexions.length > 1) {
                if (outside_slab_connexions.length) return {
                    code: 'facade_balcony_intermediate',
                    attribute: 'FacadeBalconyIntermediate',
                    label: 'Façade / Balcon / Intermédiaire'
                };
                return { code: 'facade_intermediate', attribute: 'FacadeIntermediate', label: 'Façade / Intermédiaire' };
            }
            if (inner_connexions.length) return { code: 'inner_intermediate', attribute: 'InnerIntermediate', label: 'Intermédiaire / Refend' };
        }
        return { code: 'unknown', attribute: 'Unknown', label: 'Inconnu horizontal' };
    }
};

//***********************************************************************************
//***********************************************************************************
//**** cn_thermal_opening class
//***********************************************************************************
//***********************************************************************************

export class cn_thermal_opening {
    /**
     * Constructor
     * @param {fh_polygon} polygon
     * @param {cn_storey_element} storey_element
     */
    constructor(polygon, storey_element) {
        this.id = _build_unique_id(storey_element.storey, storey_element.element);
        this.polygon = polygon;
        this.storey_element = storey_element;
        // @ts-ignore
        this.element_type = (storey_element.element && storey_element.element.opening_type) ? storey_element.element.opening_type : null;
    }
}

//***********************************************************************************
//***********************************************************************************
//**** cn_thermal_model class
//***********************************************************************************
//***********************************************************************************

export class cn_thermal_model {
    //********************************************************************************
    //**** Constructor
    //********************************************************************************
    constructor(building) {
        this.building = building;
        this.walls = [];
        this.spaces = [];
        this.zones = [];
        this.bridges = [];
    }

    /**
     *
     * @param {cn_storey_space} storey_space
     * @returns
     */
    find_thermal_space(storey_space) {
        if (!storey_space || !storey_space.space) return null;
        const sp = this.spaces.find(s => s.storey_space.space == storey_space.space && s.storey_space.storey == storey_space.storey);
        if (sp) return sp;
        return null;
    }

    /**
     *
     * @param {cn_element} zone
     * @returns
     */
    find_thermal_zone(zone) {
        if (!zone) return null;
        const z = this.zones.find(thermal_zone => thermal_zone.element == zone);
        if (z) return z;
        const thermal_zone = new cn_thermal_zone(zone);
        this.zones.push(thermal_zone);
        return thermal_zone;
    }

    //********************************************************************************
    /**
     * Main function
     */
    analyse() {
        //*** Precompute some stuff for the building */
        this.building.rename_storeys();
        this.building.update_roofs();
        this.building.compute_altitudes();

        //*** Clear data */
        this.walls = [];
        this.spaces = [];
        this.zones = [];
        this.bridges = [];
        UNIQUE_IDS = {};

        //*** Build zones and spaces */
        this._has_zones = this.building.has_non_empty_zone('thermal');
        this.building.storeys.forEach(storey => {
            storey.build_thermal_volume();
            storey.build_space_volume();
            this._build_spaces(storey);
        });

        //*** Build envelope */
        this._ceiling_slabs = [];
        var storey_below = null;
        this.building.storeys.forEach(storey => {
            this._analyse_storey(storey, storey_below);
            this._check_orphan_thermal_bridges();
            storey_below = storey;
        });

        //*** We keep only bridges with at least two connexions. */
        this.bridges = this.bridges.filter(t => t.connexions.length > 1);
        this.bridges.forEach(bridge => bridge.connexion_type = bridge.get_connexion_type());

        this.walls.forEach(w => w.compute_contact());

        logger.log('thermal model', this);
    }

    /**
     * Build spaces for a given storey
     * @param {cn_storey} storey
     */
    _build_spaces(storey) {
        let default_zone = null;
        if (!this._has_zones) {
            default_zone = new cn_thermal_zone(storey);
            this.zones.push(default_zone);
        }

        const matrix = new fh_matrix();
        matrix.load_translation([0, 0, storey.altitude]);

        storey.scene.spaces.filter(sp => sp.is_indoor(storey)).forEach(space => {
            var zone = this.find_thermal_zone(this.building.find_zone(space, storey, 'thermal'));
            if (!zone) zone = this.find_thermal_zone(storey);
            const solid_base = space.build_solid(storey, true);
            const solid = new fh_solid();
            solid_base.get_faces().forEach(f => solid.add_face(f.clone()));
            solid.apply_matrix(matrix);

            const thermal_space = new cn_thermal_space(solid, new cn_storey_space(space, storey));
            thermal_space.zone = zone;
            this.spaces.push(thermal_space);
            zone.spaces.push(thermal_space);
        });
    }

    /**
     * Builds a storey element
     * @param {cn_element} element
     * @param {cn_storey} storey
     * @returns {cn_storey_element}
     */
    _storey_element(element, storey) {
        if (element) return new cn_storey_element(element, storey);
        return null;
    }

    /**
     * Builds a space element
     * @param {cn_space} space
     * @param {cn_storey} storey
     * @returns {{space:cn_space, storey:cn_storey}}
     */
    _storey_space(space, storey) {
        if (space) return new cn_storey_space(space, storey);
        return null;
    }

    /**
     * Build envelope for a given storey
     * @param {cn_storey} storey
     * @param {cn_storey} storey_below
     */
    _analyse_storey(storey, storey_below) {
        var scene = storey.scene;

        //***************************************
        //*** read openings in floor
        //***************************************
        const full_slab_opening = new fh_polygon([0, 0, 0], [0, 0, 1]);
        storey.scene.slab_openings.forEach(so => {
            full_slab_opening.unites(so.build_3d_polygon(0));
        });

        if (storey_below) {
            storey_below.scene.stairs.forEach(st => {
                const pg = st.build_3d_slab_opening(0);
                if (pg) full_slab_opening.unites(pg);
            });
        }
        const slab_openings = full_slab_opening.split().filter(pg => pg.get_area() > 0.01);

        //***************************************
        //*** Build floor slabs
        //***************************************
        storey.slabs.filter(slab => slab.spaces[1] && slab.inner_polygon.get_area() > 0.01 && (!slab.spaces[0] || slab.spaces[0].has_ceiling)).forEach(slab => {
            var slab_z = storey.altitude;
            if (slab.spaces[1] && !slab.spaces[1].outside) slab_z += slab.spaces[1].slab_offset;
            var polygon = slab.inner_polygon.clone();

            const axis_offset = [-slab.slab_type.thickness, 0];

            const thermal_slabs = this._build_thermal_wall(polygon, axis_offset, this._storey_element(slab, storey), this._storey_space(slab.spaces[0], storey_below), this._storey_space(slab.spaces[1], storey), slab_z);
            thermal_slabs.forEach(thermal_slab => {
                slab_openings.forEach(so => {
                    var opening_pg = thermal_slab.polygon.clone();
                    opening_pg.intersects(so);
                    if (opening_pg.get_area() > 0.1) {
                        const thermal_opening = new cn_thermal_opening(opening_pg, this._storey_element(slab, storey));
                        thermal_slab.openings.push(thermal_opening);
                    }
                });
            });
        });

        //***************************************
        //*** Build walls
        //***************************************
        for (var i in scene.walls)
            this._build_wall(scene.walls[i], storey, storey_below);

        //***************************************
        //*** Build ceiling slabs
        //***************************************
        var ceiling_slabs = [];
        for (var i in scene.spaces) {
            var space = scene.spaces[i];
            if (space.outside) continue;
            if (!space.has_roof) continue;
            if (!space.has_ceiling) continue;
            var pg = space.build_slab_polygon(0, true, false);
            pg['space'] = this.find_thermal_space(this._storey_space(space, storey));
            ceiling_slabs.push(pg);
        }

        //***************************************
        //*** Build roof
        //***************************************
        const opening_matrix = new fh_matrix();
        opening_matrix.load_translation([0, 0, storey.altitude]);

        var roof = storey.roof;
        if (roof) {
            const is_last_storey = (this.building.storeys.indexOf(storey) == this.building.storeys.length - 1);

            //*** Build geometry of roof openings */
            roof.openings.forEach(op => op.thermal_polygon = op.build_3d_opening(storey.roof_altitude));

            [...roof.slabs, ...roof.roof_dormers.filter(rd => rd.valid)].forEach(roof_element => {
                const slab_openings = roof.openings.filter(op => op.slab == roof_element);
                var slab = null;
                var roof_polygons = [];
                if (roof_element.constructor == cn_roof_slab) {
                    slab = roof_element;
                    roof_polygons = [slab.build_3d_polygon(storey.roof_altitude, false, true)];
                } else {
                    slab = roof_element.slab;
                    roof_polygons = roof_element.build_3d_polygons(storey.roof_altitude, CN_INNER);
                }
                const axis_offset = (is_last_storey) ? [0, slab.slab_type.thickness] : [-slab.slab_type.thickness, 0];

                //*** Check intersection with ceiling slabs */
                ceiling_slabs.forEach(cs => {
                    roof_polygons.forEach(roof_pg => {
                        var ceiling_slab = cs.clone();
                        var roof_polygon = roof_pg.clone();
                        roof_polygon.compute_contours();
                        ceiling_slab.project(roof_polygon.get_point(), roof_polygon.get_normal(), [0, 0, 1]);
                        ceiling_slab.intersects(roof_polygon);
                        if (ceiling_slab.get_area() > 0.01) {
                            roof_polygon.substracts(ceiling_slab);
                            const thermal_slabs = this._build_thermal_wall(ceiling_slab, axis_offset, this._storey_element(slab, storey), (cs.space) ? cs.space.storey_space : null, null);
                            slab_openings.forEach(op => {
                                thermal_slabs.forEach(thermal_slab => {
                                    var opening_pg = op.thermal_polygon.clone();
                                    opening_pg.intersects(thermal_slab.polygon);
                                    if (opening_pg.get_area() > 0.1) {
                                        const thermal_opening = new cn_thermal_opening(opening_pg, new cn_storey_element(op, storey));
                                        thermal_slab.openings.push(thermal_opening);
                                    }
                                });
                            });
                        }
                    });
                });
            });

            //*** Build specific facade walls on roof line discontinuities */
            for (var i in storey.roof.lines) {
                var line = storey.roof.lines[i];
                if (line.is_border()) continue;
                var discontinuity = false;
                for (var nv = 0; nv < 2; nv++) {
                    var h0 = line.slabs[0].compute_height(line.vertices[nv].position);
                    var h1 = line.slabs[1].compute_height(line.vertices[nv].position);
                    if (Math.abs(h0 - h1) < 0.01) continue;
                    discontinuity = true;
                }
                if (!discontinuity) continue;

                var low_h = [0, 0];
                var high_h = [0, 0];
                var high_slab = [-1, -1];
                for (var nv = 0; nv < 2; nv++) {
                    var h0 = storey.roof_altitude + line.slabs[0].compute_height(line.vertices[nv].position);
                    var h1 = storey.roof_altitude + line.slabs[1].compute_height(line.vertices[nv].position);
                    low_h[nv] = (h0 > h1) ? h1 : h0;
                    high_h[nv] = (h0 > h1) ? h0 : h1;
                    if (high_h[nv] - low_h[nv] > 0.01)
                        high_slab[nv] = (h0 > h1) ? 0 : 1;
                }

                if (high_slab[0] < 0 && high_slab[1] < 0) continue;

                var p0 = cn_clone(line.vertices[0].position);
                p0.push(low_h[0]);
                p0.push(high_h[0]);
                var p1 = cn_clone(line.vertices[1].position);
                p1.push(low_h[1]);
                p1.push(high_h[1]);

                //*** maybe intermediate point ? */
                if (high_slab[0] >= 0 && high_slab[1] >= 0 && high_slab[0] != high_slab[1]) {
                    var dh0 = high_h[0] - low_h[0];
                    var dh1 = high_h[1] - low_h[1];
                    var x = dh0 / dh1 / (1 + dh0 / dh1);
                    var p1h = fh_clone(p1);
                    p1h[2] = p1[3];
                    var pp = fh_add(fh_mul(p0, 1 - x), fh_mul(p1h, x));
                    pp.push(pp[2]);

                    this._build_extra_wall(p0, pp, [0, 0], null, null, storey);
                    this._build_extra_wall(pp, p1, [0, 0], null, null, storey);
                }
                //*** regular case */
                else {
                    this._build_extra_wall(p0, p1, [0, 0], null, null, storey);
                }
            }

            //*** Build dormer walls */
            roof.roof_dormers.filter(dormer => dormer.valid).forEach(dormer => {
                const wall_polygons = dormer.build_thermal_walls(ceiling_slabs);
                wall_polygons.forEach(wall_polygon => {
                    const wall = wall_polygon['wall'];
                    const thermal_walls = this._build_thermal_wall(wall_polygon, [0, wall.wall_type.thickness], new cn_storey_element(wall, storey), wall_polygon['space'].storey_space, null, storey.altitude);

                    thermal_walls.forEach(thermal_wall => {
                        wall_polygon['openings'].forEach(opening_polygon => {
                            const element = new cn_storey_element(opening_polygon['opening'], storey);
                            const opening_pg = opening_polygon.clone();
                            opening_pg.apply_matrix(opening_matrix);
                            opening_pg.intersects(thermal_wall.polygon)
                            if (opening_pg.get_area() > 0.01) {
                                thermal_wall.openings.push(new cn_thermal_opening(opening_pg, element));
                            }
                        });
                    });
                });
            });
        }
    }

    _build_wall(wall, storey, storey_below) {
        if (!wall.valid) return;
        if (wall.balcony) return;
        if (wall.wall_type.free && !wall.spaces[0]._indoor[storey.ID]) return;

        const storey_space0 = new cn_storey_space(wall.spaces[0], storey);
        const storey_space1 = new cn_storey_space(wall.spaces[1], storey);

        //*** Wall heights */
        var zmin = wall.get_lowest_slab_height();
        var zmax = wall.get_highest_slab_height();
        var lower_storey_space = (storey_space0 && storey_space1 && storey_space0.space.slab_offset < storey_space1.space.slab_offset) ? storey_space0 : storey_space1;

        var side = (wall.is_lossy()) ? wall.get_flow_direction() ? 0 : 1 : -1;

        //*** Wall geometry */
        var normal = [wall.bounds.normal[0], wall.bounds.normal[1], 0];
        const flow_normal = cnx_mul(normal, wall.get_flow_direction() ? 1 : -1);

        var contour = [];
        var direction = cn_clone(wall.bounds.direction);
        direction.push(0);
        if (side >= 0) {
            var p00 = cnx_clone(wall.measure_points[side][0], zmax);
            var p10 = cnx_clone(wall.measure_points[side][1], zmax);
        } else {
            var p00 = cnx_clone(cn_middle(wall.measure_points[0][0], wall.measure_points[1][1]), zmax);
            var p10 = cnx_clone(cn_middle(wall.measure_points[0][1], wall.measure_points[1][0]), zmax);
        }
        var p01 = cnx_clone(p00);
        var p11 = cnx_clone(p10);
        var roof_altitude = storey.roof_altitude - storey.altitude;
        if (storey.thermal_volume) {
            var box = storey.thermal_volume.get_bounding_box();
            roof_altitude = box.position[2] + box.size[2];
        }
        p01[2] = p11[2] = roof_altitude;

        contour.push(p00);
        contour.push(p01);
        contour.push(p11);
        contour.push(p10);
        var wall_polygon = new fh_polygon(contour[0], flow_normal);
        wall_polygon.add_contour(contour);

        if (storey.thermal_volume) {
            var roof_section = storey.thermal_volume.plane_intersection(wall_polygon.get_point(), wall_polygon.get_normal());
            if (roof_section)
                wall_polygon.intersects(roof_section);
        }
        if (wall_polygon.get_area() < 0.01) return;

        const axis_offset = [0, 0];
        axis_offset[0] = cnx_dot(normal, cnx_sub(wall.measure_points[0][0], p00));
        axis_offset[1] = axis_offset[0] + wall.wall_type.thickness;

        const thermal_walls = this._build_thermal_wall(wall_polygon, axis_offset, new cn_storey_element(wall, storey), storey_space0, storey_space1, storey.altitude);
        if (!thermal_walls.length) return;

        //*** Create openings */
        var p00 = cnx_clone(wall.vertex_position(0), zmax);
        for (var o = 0; o < wall.openings.length; o++) {
            var cn_opening = wall.openings[o];
            if (!cn_opening.valid) continue;

            contour = [];
            var x = cn_opening.position;
            var pp0 = fh_add(p00, [0, 0, storey.altitude + cn_opening.opening_type.z])
            var pp1 = fh_add(p00, [0, 0, storey.altitude + cn_opening.opening_type.z + cn_opening.opening_type.height])

            contour.push(fh_add(pp0, fh_mul(direction, x)));
            contour.push(fh_add(pp0, fh_mul(direction, x + cn_opening.opening_type.width)));
            contour.push(fh_add(pp1, fh_mul(direction, x + cn_opening.opening_type.width)));
            contour.push(fh_add(pp1, fh_mul(direction, x)));

            var opening_polygon = new fh_polygon(wall_polygon.get_point(), flow_normal);
            opening_polygon.add_contour(contour);

            const element = new cn_storey_element(wall.openings[o], storey);
            thermal_walls.forEach(thermal_wall => {
                const opening_pg = opening_polygon.clone();
                opening_pg.intersects(thermal_wall.polygon)
                if (opening_pg.get_area() > 0.01)
                    thermal_wall.openings.push(new cn_thermal_opening(opening_pg, element));
            });
        }

        //*** The wall below */
        if (zmax - zmin > 0.01) {
            //*** if zero storey, this is a wall on ground */
            if (storey.storey_index == 0) {
                if (lower_storey_space) {
                    p00 = cn_clone(wall.vertices[0].position);
                    p10 = cn_clone(wall.vertices[1].position);
                    p00.push(zmin);
                    p10.push(zmin);
                    p01 = fh_clone(p00);
                    p11 = fh_clone(p10);
                    p01[2] = p11[2] = zmax;

                    wall_polygon = new fh_polygon(p00, flow_normal);
                    wall_polygon.add_contour([p00, p01, p11, p10]);
                    this._build_thermal_wall(wall_polygon, [wall.bounds.y0, wall.bounds.y1], new cn_storey_element(wall, storey), lower_storey_space, null, storey.altitude);
                }
            } else {
                var p0 = cn_clone(wall.vertices[0].position);
                p0.push(zmin + storey.altitude);
                p0.push(zmax + storey.altitude);
                var p1 = cn_clone(wall.vertices[1].position);
                p1.push(zmin + storey.altitude);
                p1.push(zmax + storey.altitude);
                this._build_extra_wall(p0, p1, [wall.bounds.y0, wall.bounds.y1], new cn_storey_element(wall, storey), lower_storey_space, storey_below);
            }
        }
    }

    _build_extra_wall(p0, p1, axis_offset, storey_wall, space, storey_below) {
        const scene_below = storey_below.scene;
        const impacts = scene_below.pathtrace(p0, p1);
        const length = cn_dist(p0, p1);
        var normal = cn_normal(cn_sub(p1, p0));
        normal.push(0);

        var spaces_under = [null, null]
        if (impacts.length == 0)
            spaces_under[0] = spaces_under[1] = scene_below.find_space(cn_middle(p0, p1));
        else {
            spaces_under[0] = impacts[0].spaces[0];
            spaces_under[1] = impacts[impacts.length - 1].spaces[1];
        }
        impacts.splice(0, 0, { point: cn_clone(p0), distance: 0, spaces: [null, spaces_under[0]] })
        impacts.push({ point: cn_clone(p1), distance: length, spaces: [spaces_under[1], null] });

        for (var n = 0; n < impacts.length; n++) {
            var t = impacts[n].distance / length;
            impacts[n].point.push(p0[2] * (1 - t) + p1[2] * t);
            impacts[n].point.push(p0[3] * (1 - t) + p1[3] * t);
        }

        for (var n = 0; n < impacts.length - 1; n++) {
            var p00 = fh_clone(impacts[n].point);
            var p10 = fh_clone(impacts[n + 1].point);
            var p01 = fh_clone(p00);
            var p11 = fh_clone(p10);
            p01[2] = impacts[n].point[3];
            p11[2] = impacts[n + 1].point[3];
            var wall_polygon = new fh_polygon(p00, normal);
            wall_polygon.add_contour([p00, p10, p11, p01]);
            this._build_thermal_wall(wall_polygon, axis_offset, storey_wall, this._storey_space(impacts[n].spaces[1], storey_below), space);
        }
    }

    /**
     * Builds a thermal wall
     * @param {fh_polygon} polygon
     * @param {number[]} axis_offset
     * @param {cn_storey_element} storey_element
     * @param {cn_storey_space} storey_space0
     * @param {cn_storey_space} storey_space1
     * @param {number} zoffset
     * @returns {Array<cn_thermal_wall>}
     */
    _build_thermal_wall(polygon, axis_offset, storey_element, storey_space0, storey_space1, zoffset = 0) {
        const thermal_space0 = this.find_thermal_space(storey_space0);
        const thermal_space1 = this.find_thermal_space(storey_space1);
        var pg = polygon;
        if (zoffset) {
            pg = polygon.clone();
            const matrix = new fh_matrix();
            matrix.load_translation([0, 0, zoffset]);
            pg.apply_matrix(matrix);
        }

        const polygons = this._explode_underground_polygon(pg, storey_space0, storey_space1, axis_offset);

        const thermal_walls = [];
        polygons.filter(polygon => polygon.get_area() > 0.01).forEach(polygon => {
            const thermal_wall = new cn_thermal_wall(polygon, axis_offset, storey_element, thermal_space0, thermal_space1);
            thermal_wall.cn_spaces[0] = (storey_space0) ? storey_space0.space : null;
            thermal_wall.cn_spaces[1] = (storey_space1) ? storey_space1.space : null;
            thermal_wall.underground = polygon['underground'];
            if (polygon['on_ground']) thermal_wall.on_ground = true;
            this.walls.push(thermal_wall);
            thermal_walls.push(thermal_wall);
            //*** Build connections *
            if (storey_element)
                this._build_polygon_connexions(polygon, thermal_wall);

        });

        return thermal_walls;
    }

    _explode_underground_polygon(polygon, storey_space0, storey_space1, axis_offset) {
        const outside1 = !storey_space1 || storey_space1.space.outside;
        const outside0 = !storey_space0 || storey_space0.space.outside;
        const polygons = polygon.split();
        if (!outside0 && !outside1) {
            polygons.forEach(pg => pg['underground'] = false);
            return polygons;
        }

        //*** simpler calculation for slabs */
        if (Math.abs(polygon.get_normal()[2]) > 0.01) {
            polygons.forEach(pg => pg['underground'] = this._is_slab_underground(pg, axis_offset[0] + 0.5));
            if (outside0)
                polygons.forEach(pg => pg['on_ground'] = this._is_slab_underground(pg, axis_offset[0] - 0.5));

            return polygons;
        }

        var splitted = [];
        polygons.forEach(pg => splitted = splitted.concat(this._split_underground_polygon(pg)));
        return splitted;
    }

    _split_underground_polygon(polygon) {
        const topography = this.building.topography;
        const box = polygon.get_bounding_box();
        polygon['underground'] = false;

        //*** maybe completely above ground ?  */
        if (box.position[2] > topography.max_height + topography.z)
            return [polygon];

        //*** maybe completely bellow ground ?  */
        if (box.position[2] + box.size[2] < topography.min_height + topography.z) {
            polygon['underground'] = true;
            return [polygon];
        }

        const point = polygon.contour_vertices[0];
        const normal = polygon.get_normal();
        const dir = cnx_cross(normal, [0, 0, 1]);
        var xmin = 0;
        var xmax = 0;
        polygon.contour_vertices.forEach(v => {
            const x = cn_dot(dir, cn_sub(v, point));
            if (x < xmin) xmin = x;
            if (x > xmax) xmax = x;
        })
        xmin -= 0.1;
        xmax += 0.11;
        const contour = [];
        var nb_bellow = 0;
        var nb_above = 0;
        var nb_middle = 0;
        for (var x = xmin; x < xmax; x += 0.1) {
            var pt = cnx_add(point, cnx_mul(dir, x));
            pt[2] = topography.compute_height(pt);
            if (pt[2] < box.position[2] - 0.01) {
                pt[2] = box.position[2] - 0.01;
                nb_above++;
            } else if (pt[2] > box.position[2] + box.size[2])
                nb_bellow++;
            else
                nb_middle++;
            contour.push(pt);
        }

        if (nb_middle == 0) {
            if (nb_bellow == 0)
                return [polygon];
            if (nb_above == 0) {
                polygon['underground'] = true;
                return [polygon];
            }
        }

        var pt = cnx_add(point, cnx_mul(dir, xmax));
        pt[2] = box.position[2] - 0.1;
        contour.push(pt);
        pt = cnx_add(point, cnx_mul(dir, xmin));
        pt[2] = box.position[2] - 0.1;
        contour.push(pt);

        const ground_polygon = new fh_polygon(point, normal);
        ground_polygon.add_contour(contour);
        ground_polygon.compute_contours();

        const polygon_underground = polygon.clone();
        polygon_underground.intersects(ground_polygon);
        if (polygon_underground.get_area() < 0.01)
            return [polygon];

        polygon_underground['underground'] = true;
        polygon.substracts(ground_polygon);
        if (polygon.get_area() < 0.01)
            return [polygon_underground];
        return [polygon, polygon_underground];
    }

    _is_slab_underground(polygon, zoffset = 0) {
        const topography = this.building.topography;
        const box = polygon.get_bounding_box();

        //*** maybe completely above ground ?  */
        if (box.position[2] + zoffset > topography.max_height + topography.z)
            return false;

        //*** maybe completely bellow ground ?  */
        if (box.position[2] + box.size[2] + zoffset < topography.min_height + topography.z)
            return true;

        return !(polygon.contour_vertices.some(v => v[2] + zoffset > topography.compute_height(v)));
    }

    /**
     * Creates all connexions for thermal bridges for a given surfgace polygon.
     * @param {fh_polygon} polygon
     * @param {cn_thermal_wall} surface
     */
    _build_polygon_connexions(polygon, surface) {
        polygon.compute_contours();
        const vertices = polygon.contour_vertices;
        const contour_sizes = polygon.contour_sizes;
        const normal = polygon.get_normal();
        var offset = 0;
        for (var nct = 0; nct < contour_sizes.length; nct++) {
            let sz = contour_sizes[nct];

            for (var n = 0; n < sz; n++) {
                var connexions = [new cn_thermal_connexion(vertices[offset + n], vertices[offset + ((n + 1) % sz)], normal, surface)];
                this._absorb_connexions(connexions);
            }

            offset += sz;
        }
    }

    /**
     * Check all orphand connexions, and try to merge them to existing thermal bridges.
     */
    _check_orphan_thermal_bridges(display_log = false) {
        const orphans = this.bridges.filter(t => t.connexions.length == 1).map(t => t.connexions[0]);
        this.bridges = this.bridges.filter(t => t.connexions.length > 1);
        this._absorb_connexions(orphans);
        this.bridges = this.bridges.filter(t => t.length > MINIMAL_THERMAL_BRIDGE_LENGTH);

        if (display_log) {
            logger.log('After reduction');
            this.bridges.forEach(tb => {
                logger.log('TB ' + tb.get_connexion_type().label, tb.connexions.concat([]));
            });
        }
    }

    /**
     * Tries to absorb a list of connexions into thermal bridges.
     * @param {cn_thermal_connexion[]} connexions
     */
    _absorb_connexions(connexions) {
        var additional_thermal_bridges = [];
        for (var nth = 0; nth < this.bridges.length; nth++) {
            const th = this.bridges[nth];
            for (var ncn = 0; ncn < connexions.length; ncn++) {
                const res = th.can_absorb(connexions[ncn]);
                if (!res) continue;
                additional_thermal_bridges = additional_thermal_bridges.concat(res.additional_thermal_bridges);
                connexions = connexions.concat(res.additional_connexions);
                connexions.splice(ncn, 1);
                ncn--;
                if (connexions.length == 0) break;
            }
            if (connexions.length == 0) break;
        }
        this.bridges = this.bridges.concat(additional_thermal_bridges);
        connexions.forEach(cn => {
            this.bridges.push(new cn_thermal_bridge(cn));
        })
    }
}
