import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Bien, Niveau } from '../../../../../../model/bien.model';
import { tap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/commons-lib';

@Component({
    selector: 'app-export-svg-sub-form-biens',
    templateUrl: './export-svg-sub-form-biens.component.html',
    styleUrls: ['./export-svg-sub-form-biens.component.scss'],
})
export class ExportSvgSubFormBiensComponent extends BaseComponent implements OnInit {
    @Input() group: FormGroup;

    @Input() biens: Bien[] = [];

    @Input() formConf: any = {};

    get bienConf() {
        return this.formConf.bien.controls;
    }

    niveaux: Niveau[];

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.updateNiveaux(this.formConf.bien.controls.bien.value);

        this.group
            .get('bien')
            .valueChanges.pipe(
                tap((bien: Bien) => {
                    this.updateNiveaux(bien);
                    return bien;
                })
            )
            .subscribe();
    }

    updateNiveaux(bien: Bien) {
        if (bien) {
            this.niveaux = bien.description;
            this.group.patchValue(
                {
                    niveau: this.formConf.bien.controls.niveau.value ? this.formConf.bien.controls.niveau.value : [],
                },
                { onlySelf: true, emitEvent: false }
            );
        }
    }

    compareWith(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }
}
