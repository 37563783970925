<div class="toolbar">
    <mat-toolbar class="border-bottom-1 primary">
        <button mat-button class="px-0" (click)="back()">
            <lib-icon>arrow_back</lib-icon>
        </button>
        <div>
            <lib-icon [class]="'icon-before-text'">settings</lib-icon>
            <span *ngIf="mode === 'CREATE' && !isReadOnly">Création d'une pièce</span>
            <span *ngIf="mode === 'EDIT' && !isReadOnly">Edition de la pièce</span>
            <span *ngIf="mode === 'DUPLICATE' && !isReadOnly">Duplication de la pièce</span>
            <span *ngIf="isReadOnly">Consultation de la pièce</span>
        </div>

        <div class="d-flex justify-content-end w-100">
            <button mat-button color="primary" class="mx-1" (click)="back()">
                <mat-icon>close</mat-icon>
                <span>{{ 'Annuler' | uppercase }}</span>
            </button>
            <button *ngIf="!isReadOnly" mat-raised-button color="primary" [disabled]="formTypeVolume.invalid" (click)="onSubmit()">
                <lib-icon>check</lib-icon>
                <span>{{ 'Valider' | uppercase }}</span>
            </button>
            <button *ngIf="isReadOnly" mat-raised-button color="primary" [disabled]="typeVolume.etatVolume === 'ACTIF'" (click)="editer()">
                <mat-icon>edit</mat-icon>
                <span>{{ 'Éditer' | uppercase }}</span>
            </button>
        </div>
    </mat-toolbar>
</div>

<div class="volume-container">
    <div class="h-100 row no-gutters p-4 scrollable-content" [formGroup]="formTypeVolume">
        <!-- Colonne gauche-->
        <div class="col-xl-12 pr-2">
            <!-- Etat -->
            <mat-button-toggle-group appearance="legacy" *ngIf="!isReadOnly" formControlName="etatVolume" class="mb-3">
                <mat-button-toggle class="button-toggle-double INACTIF left-toggle" value="INACTIF">Inactif </mat-button-toggle>
                <mat-button-toggle class="button-toggle-double ACTIF right-toggle" value="ACTIF">Actif </mat-button-toggle>
            </mat-button-toggle-group>
            <div *ngIf="isReadOnly" class="statut-workflow consultation mb-3" [ngClass]="typeVolume.etatVolume">
                {{ typeVolume.etatVolume | etatWorkflow }}
            </div>
        </div>
        <div class="col-xl-6 pr-2">
            <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="col-xl-12 p-2 m-0 primary">Informations générales</div>
                    <!-- Nom -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Nom de la pièce</div>
                        <div class="col-xl-8 pr-2">
                            <mat-form-field class="w-100">
                                <input matInput type="text" [readonly]="isReadOnly" formControlName="nom" />
                                <mat-error *ngIf="formTypeVolume.get('nom').hasError('erreurNomTypeVolume')">
                                    Une autre pièce possède déjà le même nom.
                                </mat-error>
                                <app-mat-error [control]="formTypeVolume.get('nom')"></app-mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- Code BIM -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Code BIM</div>
                        <div class="col-xl-8 pr-2">
                            <mat-form-field class="w-100">
                                <input matInput type="text" [readonly]="isReadOnly" formControlName="codeBim" />
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="col-xl-12 p-2 m-0 primary">Gestion des équipements</div>

                    <div class="d-flex p-2 align-items-center" *ngIf="configurationsFormArray?.controls?.length === 0">
                        Pas de pièce pré-équipée définie
                    </div>

                    <div formArrayName="configurations">
                        <div *ngFor="let configuration of configurationsFormArray.controls; let i = index">
                            <div [formGroupName]="i">
                                <mat-card class="p-2 mb-2 m-3 d-flex flex-column justify-content-between">
                                    <mat-card-content>
                                        <div class="d-flex justify-content-end">
                                            <button
                                                *ngIf="!isReadOnly"
                                                mat-icon-button
                                                class="warn"
                                                (click)="confirmDeleteConfiguration(configuration.value, i)"
                                            >
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>
                                        <!-- Nom de la configuration -->
                                        <div class="d-flex pt-0 p-2 align-items-center">
                                            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Nom de la configuration</div>
                                            <div class="col-xl-8 pr-2">
                                                <mat-form-field class="w-100">
                                                    <input matInput type="text" [readonly]="isReadOnly" formControlName="nom" />
                                                    <app-mat-error [control]="configuration.get('nom')"> </app-mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <!-- Equipements associés -->
                                        <div class="d-flex p-2 align-items-center">
                                            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Équipements associés</div>
                                            <div class="col-xl-8" *ngIf="!isReadOnly">
                                                <app-custom-select
                                                    [matFormFieldClass]="'w-75'"
                                                    [readonly]="isReadOnly"
                                                    formControlName="selectEquipment"
                                                    [choices]="listeNomEquipements"
                                                    [addline]="false"
                                                    [multiple]="false"
                                                >
                                                </app-custom-select>
                                                <button
                                                    [disabled]="configuration.get('selectEquipment').value === ''"
                                                    mat-raised-button
                                                    color="primary"
                                                    class="ml-2"
                                                    (click)="createEquipment(configuration, configuration.get('selectEquipment').value)"
                                                >
                                                    <lib-icon>add</lib-icon>
                                                </button>
                                            </div>
                                        </div>
                                        <div formArrayName="equipementsAssocies">
                                            <div
                                                *ngFor="
                                                    let equipment of configuration.get('equipementsAssocies').controls;
                                                    let indexEquipment = index
                                                "
                                            >
                                                <div class="equipment-card p-1 m-1 d-flex align-items-center">
                                                    <div class="flex-grow-1">{{ equipment.value.name }}</div>
                                                    <button mat-icon-button color="warn">
                                                        <mat-icon *ngIf="!isReadOnly" (click)="deleteEquipment(configuration, indexEquipment)"
                                                            >delete
                                                        </mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>
                    </div>

                    <!-- Bouton ajouter équipement pour configuration -->
                    <div *ngIf="!isReadOnly" class="d-flex justify-content-end w-100">
                        <button mat-raised-button color="primary" (click)="createConfiguration(null)">
                            <mat-icon>add</mat-icon>
                            <span>{{ 'Ajouter une configuration' | uppercase }}</span>
                        </button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

        <!--Colonne droite-->
        <div class="col-xl-6 pl-2">
            <!-- Réglages -->
            <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="d-flex flex-column p-2">
                        <div class="col-xl-12 m-0 mb-3 primary">Réglages par défaut</div>
                        <div class="col-xl-12 ml-4 text-secondary">Réglages généraux</div>
                        <mat-checkbox [disabled]="isReadOnly" class="ml-5" formControlName="pieceHumide">Pièce humide </mat-checkbox>
                        <mat-checkbox [disabled]="isReadOnly" class="ml-5" formControlName="pieceChauffee">Pièce chauffée </mat-checkbox>
                        <div class="col-xl-12 ml-4 text-secondary">Réglages mesurage</div>
                        <mat-checkbox [disabled]="isReadOnly" class="ml-5" formControlName="pieceNonCarrez">Pièce non réputé Carrez </mat-checkbox>
                        <mat-checkbox [disabled]="isReadOnly" class="ml-5" formControlName="pieceNonHabitable"
                            >Pièce non réputé Habitable
                        </mat-checkbox>
                        <mat-checkbox [disabled]="isReadOnly" class="ml-5" formControlName="pieceNonUtile">Pièce non réputé Utile </mat-checkbox>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="col-xl-12 p-2 m-0 primary">Association avec types de bien</div>
                    <!-- Le select est illisible en readonly, passage par une liste des valeurs si on est en consultation -->
                    <mat-form-field *ngIf="!isReadOnly" appearance="outline" class="w-50 px-3">
                        <mat-label>Type de bien</mat-label>
                        <mat-select multiple formControlName="selectedTypesBien">
                            <mat-option *ngFor="let typeBien of listTypesBien" [value]="typeBien.id">
                                {{ typeBien.nom }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="isReadOnly" class="d-flex flex-column w-50 px-2">
                        <span *ngFor="let typeBien of typeVolume.typesBienAssocies">{{ typeBien }}</span>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="d-flex flex-column p-2">
                        <div class="col-xl-12 m-0 mb-3 primary">Ordre ajout type de pièces</div>
                        <mat-form-field class="w-100">
                            <input matInput type="number" [readonly]="isReadOnly" formControlName="ordre" />
                        </mat-form-field>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
