<div class="d-flex flex-column flex-grow-1 scrollable-content">
    <div class="d-flex flex-grow-0 align-items-center p-3">
        <span class="pr-2">Editer un ouvrage</span>
        <span class="font-weight-bold">{{ ouvrageToEdit.nom }}</span>
        <button mat-icon-button [disabled]="readonlyMode" (click)="handleEditNomOuvrage()">
            <lib-icon>edit</lib-icon>
        </button>
    </div>
    <app-description-bien-modal-ouvrages-details
        [(ouvrageToEdit)]="ouvrageToEdit"
        [readonlyMode]="readonlyMode"
        [listOuvragesCurrentVolume]="listOuvragesCurrentVolume"
    ></app-description-bien-modal-ouvrages-details>
</div>
