<div class="proprietes" [formGroup]="formCurrentBesoin">
    <p class="entete">
        Propriétés :
        {{ formCurrentBesoin.get('numero').value }}
    </p>
    <div class="main-container">
        <div class="col-md-6">
            <div>
                <mat-form-field class="flex-grow-0 form-inline">
                    <label>Objectif de mesurage<span class="required"></span></label>
                    <mat-select
                        matInput
                        name="objectif"
                        id="objectif"
                        formControlName="objectifMesurage"
                        [compareWith]="PolluantSvc.compareObjectifs"
                        [disabled]="!isInEditMode"
                    >
                        <ng-container *ngFor="let selectedZone of selectedZones; let i = index">
                            <ng-container *ngIf="formCurrentBesoin.get('zoneId').value == selectedZone.id">
                                <mat-option *ngFor="let condition of selectedZone.objectifMesurage" [value]="condition">
                                    {{ condition?.label }} ({{ condition?.typePrelevement }})</mat-option
                                >
                            </ng-container>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="flex-grow-0 form-inline">
                    <label>Numéro</label>
                    <input matInput name="numero" id="numero" formControlName="numero" [readonly]="!isInEditMode" />
                </mat-form-field>
                <mat-form-field class="flex-grow-0 form-inline">
                    <label>Localisation<span class="required"></span></label>
                    <mat-select matInput name="piece" id="piece" formControlName="pieceId" [disabled]="!isInEditMode">
                        <ng-container *ngFor="let selectedZone of selectedZones; let i = index">
                            <ng-container *ngIf="formCurrentBesoin.get('zoneId').value == selectedZone.id">
                                <ng-container *ngFor="let volumeId of availableVolumesIdInZone">
                                    <ng-container *ngFor="let volume of volumes">
                                        <mat-option *ngIf="volumeId == volume.id" [value]="volumeId">{{
                                            volume.nom
                                        }}</mat-option>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="showEmplacement" class="flex-grow-0 form-inline">
                    <label>Emplacement</label>
                    <input matInput type="string" name="emplacement" id="emplacement" formControlName="emplacement" />
                </mat-form-field>
                <mat-form-field *ngIf="showDetailsLocalisation" class="flex-grow-0 form-inline">
                    <label>Précision localisation</label>
                    <input
                        matInput
                        type="string"
                        name="precisionLocalisation"
                        id="precisionLocalisation"
                        formControlName="precisionLocalisation"
                        [readonly]="!isInEditMode"
                    />
                </mat-form-field>
                <mat-form-field *ngIf="showDetailsLocalisation" class="flex-grow-0 form-inline">
                    <label>Justificatif localisation<span class="required"></span></label>
                    <mat-select
                        matInput
                        name="mpca"
                        id="mpca"
                        formControlName="justificatifLocalisation"
                        [disabled]="!isInEditMode"
                    >
                        <mat-option
                            *ngFor="
                                let justificatifLocalisation of polluantConfig.contenuListeDeroulante
                                    .justificatifLocalisation.items
                            "
                            [value]="justificatifLocalisation"
                            >{{ justificatifLocalisation }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>
            </div>
            <mat-form-field class="flex-grow-0 form-inline">
                <label>Fréquence</label>
                <input
                    matInput
                    readonly
                    type="string"
                    name="frequencePrelevement"
                    id="frequencePrelevement"
                    formControlName="frequencePrelevement"
                />
            </mat-form-field>
            <mat-form-field *ngIf="showContexteMesure" class="flex-grow-0 form-inline">
                <label>Contexte<span class="required"></span></label>
                <mat-select matInput name="mpca" id="mpca" formControlName="contexteMesure" [disabled]="!isInEditMode">
                    <mat-option *ngFor="let cm of contextesMesure" [value]="cm.label">{{ cm.label }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="showConditionPrelevement" class="flex-grow-0 form-inline">
                <label>Condition du prélèvement</label>
                <mat-select
                    matInput
                    name="conditionPrelevement"
                    id="conditionPrelevement"
                    formControlName="conditionPrelevement"
                    [compareWith]="PolluantSvc.compareSimulation"
                    [disabled]="!isInEditMode"
                >
                    <mat-option
                        *ngFor="
                            let conditionPrelevement of polluantConfig.contenuListeDeroulante.simulationActiviteHumaine
                                .items
                        "
                        [value]="conditionPrelevement"
                        >{{ conditionPrelevement.label }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>
        <div
            class="col-md-6"
            *ngIf="
                showProcessus ||
                formCurrentBesoin.get('justificatifLocalisation').value == 'Autre' ||
                formCurrentBesoin.get('frequencePrelevement').value == 'Autre'
            "
        >
            <mat-form-field *ngIf="showProcessus" class="flex-grow-0 form-inline">
                <label>Processus<span class="required"></span></label>
                <mat-select matInput name="mpca" id="mpca" formControlName="processusId" [disabled]="!isInEditMode">
                    <ng-container *ngFor="let selectedZone of selectedZones; let i = index">
                        <ng-container *ngIf="formCurrentBesoin.get('zoneId').value == selectedZone.id">
                            <ng-container *ngFor="let mpca of selectedZone.listeMpca">
                                <mat-option *ngFor="let processus of mpca.processus" [value]="processus.id">{{
                                    mpca.general.materiauxClient + ' - ' + processus.nom
                                }}</mat-option>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </mat-select>
            </mat-form-field>

            <div *ngIf="showProcessus" class="computed-values">
                <div [formGroup]="computedForm" class="mat-card d-flex f-direction-column align-items-center">
                    <mat-form-field appearance="outline" class="row inline-input">
                        <mat-label>Schéma indicatif de prélèvement</mat-label>
                        <input matInput formControlName="schemaPrelevement" />
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="row inline-input">
                        <mat-label>Débit / Volume prélevé</mat-label>
                        <input matInput formControlName="debitVolumePreleve" />
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="row inline-input">
                        <mat-label>Schéma indicatif d'analyses</mat-label>
                        <input matInput formControlName="schemaAnalyse" />
                    </mat-form-field>
                </div>
            </div>
            <mat-form-field
                *ngIf="formCurrentBesoin.get('justificatifLocalisation').value == 'Autre'"
                class="flex-grow-0 form-inline"
            >
                <label>Justificatif</label>
                <input
                    matInput
                    type="string"
                    name="justificatifAutre"
                    id="justificatifAutre"
                    formControlName="justificatifAutre"
                    [readonly]="!isInEditMode"
                />
            </mat-form-field>
            <mat-form-field
                *ngIf="formCurrentBesoin.get('frequencePrelevement').value == 'Autre'"
                class="flex-grow-0 form-inline"
            >
                <label>Autre</label>
                <input
                    matInput
                    type="string"
                    name="frequencePrelevementAutre"
                    id="frequencePrelevementAutre"
                    formControlName="frequencePrelevementAutre"
                    [readonly]="!isInEditMode"
                />
            </mat-form-field>
        </div>
        <!-- <app-sequence-list
            class="sequence-list col-md-6"
            [sequenceList]="formCurrentBesoin.get('listeSequences')"
        >
        </app-sequence-list> -->
    </div>
</div>
