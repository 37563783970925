import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'asType',
    pure: true,
})
export class AsTypePipe implements PipeTransform {
    transform<T>(value: any, clss: new (...args: any[]) => T): T {
        return value as T;
    }
}
