import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseComponent, ConfirmationService, NotificationService } from 'src/app/commons-lib';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { CategorieDocument } from 'src/app/model/categorie-document.model';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { Document } from 'src/app/model/document.model';
import { TypeReferenceFichier } from 'src/app/model/intervention-file.model';
import { Intervention } from 'src/app/model/intervention.model';
import { ReferencePrestation } from 'src/app/model/reference-prestation.model';
import { TypePrestation, TypePrestationEnum, typePrestationToLabel, typesPrestation } from 'src/app/model/type-prestation.model';
import { CheckConformiteDocumentByPrestationPipe } from 'src/app/pipes/check-conformite-document-by-prestation.pipe';
import { DocumentFileService } from 'src/app/services/document-file.service';
import { DocumentsService } from 'src/app/services/documents.service';
import { InterventionFileService } from 'src/app/services/intervention-file.service';
import { InterventionService } from 'src/app/services/intervention.service';
import { ReferenceService } from 'src/app/services/reference.service';
import { SynchronizationService } from 'src/app/services/synchronization.service';
import { CnSpinnerService } from '../../cn-spinner/service/cn-spinner.service';
import { EditDocumentModalComponent } from '../edit-document-modal/edit-document-modal.component';
import { RefreshDocumentsService } from '../../../../services/refresh-documents.service';
import { formatErrorMessage } from '../../../../utils/notification.utils';
import { EMPTY } from 'rxjs';
import { CheckAnswerDocumentByPrestationPipe } from '../../../../pipes/check-answer-document-by-prestation.pipe';
import { DiagnosticService } from '../../../../services/diagnostic.service';
import { SyncState } from 'src/app/services/syncState';
import { enumTypesCommentaire } from 'src/app/model/type-commentaire.model';

@Component({
    selector: 'app-documents-list',
    templateUrl: './documents-list.component.html',
    styleUrls: ['./documents-list.component.scss'],
})
export class DocumentsListComponent extends BaseComponent implements OnInit {
    @Input() intervention: Intervention;
    @Input() diagnostic: Diagnostic;
    @Input() readonlyMode: boolean;

    _documentsFiltered: Document[] = [];

    get documentsFiltered() {
        return this._documentsFiltered;
    }

    @Input() set documentsFiltered(documentsFiltered: Document[]) {
        if (documentsFiltered && documentsFiltered.length > 0) {
            this._documentsFiltered = documentsFiltered;
            if (this.allCategorieDocuments && this.allCategorieDocuments.length > 0) {
                this.getDocumentsByCategorie();
            }
        } else {
            this.documentsByCategorie = new Map();
        }
    }

    // Evenement déclenché lors de lajout et suppression d'un document
    @Output() addedOrRemovedDocument = new EventEmitter<any>();
    @Output() documentClicked = new EventEmitter<Document>();

    // Défini si on est en ligne ou hors ligne
    SyncState = SyncState;
    isOnline$ = this.synchronizationService.getSyncState();

    // Défini la valeur du filtre sur le type de prestation
    filterPrestationValue: TypePrestation;
    // Contient l'ensemble des possibilités de type de prestation pour remplir la liste déroulante de filtre
    allTypesPrestation: TypePrestation[] = typesPrestation.filter((typePrestationTemp) => typePrestationTemp != TypePrestationEnum.COMMUN);
    // Contient l'ensemble des catégorie de document possible enregistrées en base
    allCategorieDocuments: CategorieDocument[] = [];
    // Liste les documents à afficher par catégorie
    documentsByCategorie: Map<string, Document[]> = new Map();

    // Correspond au document sélectionné. Sa ligne doit avoir un fond bleu
    documentSelected: Document;

    // Correspond à la referencePrestation courante lorsqu'on est dans un diagnostic
    currentReferencePrestation: ReferencePrestation;

    // Ajout du choix du type de commentaire de non présence
    presenceJustification: enumTypesCommentaire = enumTypesCommentaire.JUSTIFICATION_NON_PRESENCE;

    canAddDocument: boolean;

    displayTypePrestation = (item: TypePrestation) => {
        return typePrestationToLabel(item);
    };

    constructor(
        private readonly confirmationService: ConfirmationService,
        private readonly notificationService: NotificationService,
        private readonly interventionService: InterventionService,
        private readonly interventionFileService: InterventionFileService,
        private readonly documentFileService: DocumentFileService,
        private readonly documentsService: DocumentsService,
        private readonly diagnosticService: DiagnosticService,
        private readonly matDialog: MatDialog,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly synchronizationService: SynchronizationService,
        private readonly referenceService: ReferenceService,
        public readonly checkConformiteDocumentByPrestationPipe: CheckConformiteDocumentByPrestationPipe,
        public readonly checkAnswerDocumentByPrestationPipe: CheckAnswerDocumentByPrestationPipe,
        private readonly refreshDocumentsService: RefreshDocumentsService
    ) {
        super();
    }

    ngOnInit(): void {
        this.filterPrestationValue = this.diagnostic ? this.diagnostic.typePrestation : undefined;

        // Filtre de la liste des types de prestation suivant les prestations présentes dans l'intervention
        this.allTypesPrestation = this.allTypesPrestation.filter((typePrestationTemp) => {
            return this.intervention.prestationsDiagnostics
                .map((prestaDiagTemp) => prestaDiagTemp.prestation.typePrestation)
                .includes(typePrestationTemp);
        });

        this.cnSpinnerService
            .withSpinner(this.referenceService.findAllCategorieDocuments())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((allCategorieDocuments) => {
                this.allCategorieDocuments = allCategorieDocuments;
                this.getDocumentsByCategorie();

                this.canAddDocument = this.diagnostic
                    ? !this.diagnosticService.isReadOnlyMode(this.intervention, this.diagnostic)
                    : !this.readonlyMode;

                this.currentReferencePrestation = this.interventionService.findReferencePrestationByIdDiagnostic(this.intervention, this.diagnostic);
            });
    }

    /**
     * Évènement déclenché lors du changement de filtre de type de prestation
     * @param filterTypePrestation
     */
    onChangeFilter(filterTypePrestation: TypePrestation) {
        this.filterPrestationValue = filterTypePrestation.length != 0 ? filterTypePrestation : null;

        // Recherche de la référence prestation correspondante au diagnostic courant
        const referencePrestationToShow = this.interventionService.findReferencePrestationByTypePrestation(
            this.intervention,
            this.filterPrestationValue
        );

        this.documentsFiltered = this.documentsService.findDocumentForPrestation(
            this.intervention,
            this.diagnostic ? this.diagnostic.id : null,
            referencePrestationToShow
        );
    }

    /**
     * Ajout de document
     */
    onClickAddDocument() {
        return this.matDialog
            .open(EditDocumentModalComponent, {
                data: {
                    idIntervention: this.intervention.id,
                    typePrestation: this.filterPrestationValue,
                    documentsAlreadyPresent: this.intervention.documents.map((it) => {
                        return {
                            nom: it.nom,
                            id: it.id,
                        };
                    }),
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.intervention.documents = this.intervention.documents.concat(result.document);
                    this.cnSpinnerService.withSpinner(this.interventionService.updateIntervention(this.intervention)).subscribe(() => {
                        this.notificationService.success('Le document a été ajouté');
                        this.interventionService.reloadCurrentIntervention();
                        this.addedOrRemovedDocument.emit();
                    });
                }
            });
    }

    /**
     * Sélection d'un document existant
     */
    onClickDocument(doc: Document) {
        this.documentSelected = doc;
        console.log(doc);
        this.documentClicked.emit(doc);
    }

    /**
     * Suppression d'un document, uniquement dans le cas d'un document non obligatoire (+ vérification au niveau HTML)
     */
    onClickDeleteDocument(doc: Document) {
        if (!doc.requiredForIntervention) {
            this.confirmationService.confirmWarn(
                'Êtes-vous sûr de vouloir supprimer ce document ?<br/><br/>NB : Le fichier sera également supprimé',
                () => {
                    if (doc.idFichier) {
                        // Suppression du fichier
                        this.cnSpinnerService
                            .withSpinner(
                                // On récupère l'InterventionFile
                                this.interventionFileService
                                    .findByIdInterventionIdDiagnosticIdReference(
                                        this.intervention.id,
                                        this.diagnostic && this.diagnostic.id ? this.diagnostic.id : undefined,
                                        doc.id,
                                        TypeReferenceFichier.FICHIER_DOCUMENT,
                                        doc.idFichier
                                    )
                                    .pipe(
                                        switchMap((interventionFile) => {
                                            /**
                                             * Supprime le fichier du document et l'objet InterventionFile
                                             */
                                            return this.interventionFileService.deleteInterventionFile(interventionFile, true);
                                        })
                                    )
                            )
                            .pipe(takeUntil(this.ngUnsubscribe))
                            .subscribe();
                    }

                    this.intervention.documents = this.intervention.documents.filter((it) => it.id != doc.id);
                    this.cnSpinnerService.withSpinner(this.interventionService.updateIntervention(this.intervention)).subscribe(() => {
                        this.notificationService.success('Le document a bien été supprimé');
                        this.interventionService.reloadCurrentIntervention();
                        this.addedOrRemovedDocument.emit();
                    });
                }
            );
        }
    }

    /**
     *  Ouverture de la pièce jointe
     */
    onClickOpenDocument(doc: Document) {
        this.documentFileService.downloadFileByFileId(doc).subscribe();
    }

    /**
     * Remplir la Map dont la clé est la catégorie de document qui contient les documents associés
     */
    getDocumentsByCategorie() {
        this.documentsByCategorie = new Map();
        this.documentsFiltered.forEach((docTemp) => {
            const categorieName = this.referenceService.findNameCategorieDocumentFromId(docTemp.typeDocument.idCategorie, this.allCategorieDocuments);
            if (this.documentsByCategorie.get(categorieName)) {
                this.documentsByCategorie.get(categorieName).push(docTemp);
            } else {
                this.documentsByCategorie.set(categorieName, [docTemp]);
            }
        });
        const firstCategorie = this.documentsByCategorie.values().next().value;
        if (firstCategorie) {
            this.onClickDocument(firstCategorie[0]);
        }
    }

    /**
     * Track by for categorie loop
     * @param index
     * @param keyValue
     */
    trackByCategorieWithDocument(index: number, keyValue: any) {
        return index;
    }

    /**
     * Track by for document loop
     * @param index
     * @param document
     */
    trackByDocument(index: number, document: Document) {
        return index;
    }

    majDocuments() {
        this.refreshDocumentsService
            .refreshDocumentsForIntervention(this.intervention)
            .pipe(
                catchError((err) => {
                    this.notificationService.error(formatErrorMessage(err));
                    return EMPTY;
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe();
    }

    docPresenteOuJustifie(doc: Document): boolean {
        return this.documentsService.checkPresentationDocument(doc, this.intervention);
    }

    docNonPresenteEtJustifie(doc: Document): boolean {
        return !doc.isPresented && this.documentsService.checkJustification(this.intervention, doc);
    }
}
