import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { NotificationService } from '../commons-lib';
import { SYNC_PROGRESS } from '../services/synchronization.service';

/**
 * Vérification de la config est bien terminée avant de lancer l'intervention
 */
@Injectable({
    providedIn: 'root',
})
export class StartInterGuard implements CanActivate {
    constructor(
        @Inject(SYNC_PROGRESS) private readonly syncProgress: BehaviorSubject<{ running: boolean; progress: number }>,
        private notificationService: NotificationService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (this.syncProgress.value.running) {
            this.notificationService.notify('Synchronisation en cours ...');
            return of(false);
        }
        return of(true);
    }
}
