import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { InterventionReportData, IReportComponent, ReportBlockData } from '../../../../../../../model/rapport.model';
import { CEEReportData } from '../../../../model/cee.model';
import { Intervention } from '../../../../../../../model/intervention.model';
import { NavigationBarsService } from '../../../../../../../services/navigation-bars.service';
import { Commentaire } from '../../../../../../../model/commentaire.model';
import { MatDialog } from '@angular/material/dialog';
import { ReportCommentsListModalComponent } from 'src/app/modules/diagnostics-shared/rapport/report-comments-list-modal/report-comments-list-modal.component';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { DiagnosticService } from 'src/app/services/diagnostic.service';
import { TypePrestationEnum } from 'src/app/model/type-prestation.model';

@Component({
    selector: 'app-report-cee-block',
    templateUrl: './report-barth160.component.html',
    styleUrls: ['../report-cee-block/report-cee-block.component.scss'],
})
export class ReportBarth160Component extends BaseComponent implements OnInit, IReportComponent {
    TypePrestationEnum = TypePrestationEnum;

    data: ReportBlockData;
    interReportData: InterventionReportData;
    intervention: Intervention;
    public ceeReportData: CEEReportData;
    public readonlyMode: boolean;
    private diagnostic: Diagnostic;
    public contentPTC: {
        libelle: string;
        section: string;
        pointsDeControle: any[];
    }[] = [];
    constructor(private navigationBarsService: NavigationBarsService, private diagnosticService: DiagnosticService, private matDialog: MatDialog) {
        super();
        this.diagnosticService.getCurrentDiagnostic().subscribe((diag) => {
            this.diagnostic = diag;
            this.readonlyMode = this.diagnosticService.isReadOnlyMode(this.intervention, this.diagnostic);
        });
    }

    ngOnInit(): void {
        if (this.interReportData) {
            let typePrestation = '';
            this.navigationBarsService.navigationBarConfiguration$.subscribe((value) => (typePrestation = value.typePrestation)).unsubscribe();
            this.ceeReportData = this.interReportData.diagnostics.find((dia) => dia.typePrestation === typePrestation) as CEEReportData;
            for (const info of this.ceeReportData.pointsDeControle.solution[0].infosSolutions.informations) {
                let exist = this.contentPTC.find((v) => v.section === info.sectionRapport);
                if (!exist) {
                    exist = {
                        libelle: info.libelle,
                        section: info.sectionRapport,
                        pointsDeControle: [],
                    };
                    this.contentPTC.push(exist);
                }
                exist.pointsDeControle.push(info);
            }
        }
        console.log(this.contentPTC);
    }

    editDisplayedComments(commentaires: Commentaire[], code: string) {
        console.log(commentaires);
        console.log(this.diagnostic.hiddenComments);
        return this.matDialog
            .open(ReportCommentsListModalComponent, {
                data: {
                    comments: commentaires,
                    code,
                    hiddenComments: this.diagnostic.hiddenComments,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.diagnostic.hiddenComments = result.hiddenComments;
                }
            });
    }

    splitUrl(url: string): string {
        let urlSplit = url.split('/');

        return urlSplit[urlSplit.length - 1];
    }
}
