import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/commons-lib';
import { GestionAgencesComponent } from './gestion-agences/gestion-agences.component';
import { GestionReferentielsComponent } from './gestion-referentiels/gestion-referentiels.component';
import { GestionEquipementsComponent } from './gestion-referentiels/gestion-equipements/gestion-equipements.component';
import { CreationEquipementComponent } from './gestion-referentiels/gestion-equipements/creation-equipement/creation-equipement.component';
import { EditEquipementComponent } from './gestion-referentiels/gestion-equipements/edit-equipement/edit-equipement.component';
import { GestionPiecesComponent } from './gestion-referentiels/gestion-pieces/gestion-pieces.component';
import { CreationPieceComponent } from './gestion-referentiels/gestion-pieces/creation-piece/creation-piece.component';
import { GestionDiagnoticsComponent } from './gestion-referentiels/gestion-diagnotics/gestion-diagnotics.component';
import { CreationPrestationDiagnosticComponent } from './gestion-referentiels/gestion-diagnotics/creation-prestation-diagnostic/creation-prestation-diagnostic.component';
import { GestionContactsComponent } from './gestion-contacts/gestion-contacts.component';
import { CreationContactComponent } from './gestion-contacts/creation-contact/creation-contact.component';
import { GestionOperateursComponent } from './gestion-operateurs/gestion-operateurs.component';
import { CreationOperateurComponent } from './gestion-operateurs/creation-operateur/creation-operateur.component';
import { GestionBiensComponent } from './gestion-biens/gestion-biens.component';
import { CreationBienComponent } from './gestion-biens/creation-bien/creation-bien.component';
import { GestionCommandesComponent } from './gestion-commandes/gestion-commandes.component';
import { CreationCommandeComponent } from './gestion-commandes/creation-commande/creation-commande.component';
import { CreationAgenceComponent } from './gestion-agences/creation-agences/creation-agence.component';
import { CreationCofracComponent } from './gestion-cofracs/creation-cofrac/creation-cofrac.component';
import { GestionCofracsComponent } from './gestion-cofracs/gestion-cofracs.component';
import { GestionTypesOuvrageComponent } from './gestion-referentiels/gestion-types-ouvrage/gestion-types-ouvrage.component';
import { CreationTypesOuvrageComponent } from './gestion-referentiels/gestion-types-ouvrage/creation-types-ouvrage/creation-types-ouvrage.component';
import { GestionMateriauxComponent } from './gestion-referentiels/gestion-materiaux/gestion-materiaux.component';
import { CreationMateriauxComponent } from './gestion-referentiels/gestion-materiaux/creation-materiaux/creation-materiaux.component';
import { GestionSynthesesComponent } from './gestion-syntheses/gestion-syntheses.component';
import { CreationExportSyntheseComponent } from './gestion-syntheses/creation-export-synthese/creation-export-synthese.component';
import { GestionTemplateRapportComponent } from './gestion-referentiels/gestion-template-rapport/gestion-template-rapport.component';
import { LibsIconesComponent } from './gestion-referentiels/libs-icones/libs-icones.component';
import { EditCategorieEquipementComponent } from './gestion-referentiels/gestion-equipements/edit-categorie-equipement/edit-categorie-equipement.component';
import { EditModeEnum } from 'src/app/model/edit-mode.model';
import { GestionCategoriesOuvragesPiecesComponent } from './gestion-referentiels/gestion-categories-ouvrages-pieces/gestion-categories-ouvrages-pieces.component';
import { CreationCategorieOuvrageComponent } from './gestion-referentiels/gestion-categories-ouvrages-pieces/creation-categorie-ouvrage/creation-categorie-ouvrage.component';

const routes: Routes = [
    {
        path: 'gestion-contacts',
        data: { authorities: ['ROLE_PROJECT_LEADER'] },
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: GestionContactsComponent,
            },
            {
                path: 'consulter-contact/:idContact',
                data: { consulter: true, duplicate: false },
                component: CreationContactComponent,
            },
            {
                path: 'edit-contact',
                children: [
                    {
                        path: '',
                        data: { consulter: false, duplicate: false },
                        component: CreationContactComponent,
                    },
                    {
                        path: ':idContact',
                        data: { consulter: false, duplicate: false },
                        component: CreationContactComponent,
                    },
                    {
                        path: ':idContact/duplicate',
                        data: { consulter: false, duplicate: true },
                        component: CreationContactComponent,
                    },
                ],
            },
        ],
    },
    {
        path: 'gestion-users',
        data: { authorities: ['ROLE_PROJECT_LEADER'] },
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: GestionOperateursComponent,
            },
            {
                path: 'consulter-user/:idUser',
                data: { consulter: true, duplicate: false },
                component: CreationOperateurComponent,
            },
            {
                path: 'edit-user',
                children: [
                    {
                        path: '',
                        data: { consulter: false, duplicate: false },
                        component: CreationOperateurComponent,
                    },
                    {
                        path: ':idUser',
                        data: { consulter: false, duplicate: false },
                        component: CreationOperateurComponent,
                    },
                    {
                        path: ':idUser/duplicate',
                        data: { consulter: false, duplicate: true },
                        component: CreationOperateurComponent,
                    },
                ],
            },
        ],
    },
    {
        path: 'gestion-biens',
        data: { authorities: ['ROLE_PROJECT_LEADER'] },
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: GestionBiensComponent,
            },
            {
                path: 'consulter-bien/:idBien',
                data: { consulter: true, duplicate: false },
                component: CreationBienComponent,
            },
            {
                path: 'edit-bien',
                children: [
                    {
                        path: '',
                        data: { consulter: false, duplicate: false },
                        component: CreationBienComponent,
                    },
                    {
                        path: ':idBien',
                        data: { consulter: false, duplicate: false },
                        component: CreationBienComponent,
                    },
                    {
                        path: ':idBien/duplicate',
                        data: { consulter: false, duplicate: true },
                        component: CreationBienComponent,
                    },
                ],
            },
        ],
    },
    {
        path: 'gestion-commandes',
        data: { authorities: ['ROLE_PROJECT_LEADER'] },
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: GestionCommandesComponent,
            },
            {
                path: 'consulter-commande/:idCommande',
                data: { consulter: true, duplicate: false },
                component: CreationCommandeComponent,
            },
            {
                path: 'edit-commande',
                children: [
                    {
                        path: '',
                        data: { consulter: false, duplicate: false },
                        component: CreationCommandeComponent,
                    },
                    {
                        path: ':idCommande',
                        data: { consulter: false, duplicate: false },
                        component: CreationCommandeComponent,
                    },
                    {
                        path: ':idCommande/duplicate',
                        data: { consulter: false, duplicate: true },
                        component: CreationCommandeComponent,
                    },
                ],
            },
        ],
    },
    {
        path: 'referentiels',
        data: { authorities: ['ROLE_ADMIN'] },
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: GestionReferentielsComponent,
            },
            {
                path: 'equipements',
                data: { authorities: ['ROLE_ADMIN'] },
                canActivate: [AuthGuard],
                children: [
                    {
                        path: '',
                        component: GestionEquipementsComponent,
                    },
                    {
                        path: ':idEquipement',
                        component: GestionEquipementsComponent,
                    },
                    {
                        path: 'consulter-equipement/:idEquipement',
                        component: CreationEquipementComponent,
                    },
                    {
                        path: 'edit-equipement',
                        children: [
                            {
                                path: ':idEquipement/' + EditModeEnum.EDIT,
                                data: { mode: EditModeEnum.EDIT },
                                component: EditEquipementComponent,
                            },
                            {
                                path: ':idEquipement/' + EditModeEnum.DUPLICATE,
                                data: { mode: EditModeEnum.DUPLICATE },
                                component: EditEquipementComponent,
                            },
                        ],
                    },
                    {
                        path: 'create-equipement',
                        children: [
                            {
                                path: ':idCategorieEquipement',
                                data: { mode: EditModeEnum.CREATE },
                                component: EditEquipementComponent,
                            },
                        ],
                    },
                    {
                        path: 'edit-categorie-equipement',
                        children: [
                            {
                                path: ':idCategorieEquipement/' + EditModeEnum.EDIT,
                                data: { mode: EditModeEnum.EDIT },
                                component: EditCategorieEquipementComponent,
                            },
                            {
                                path: ':idCategorieEquipement/' + EditModeEnum.DUPLICATE,
                                data: { mode: EditModeEnum.DUPLICATE },
                                component: EditCategorieEquipementComponent,
                            },
                        ],
                    },
                    {
                        path: 'create-categorie-equipement',
                        children: [
                            {
                                path: ':idCategorieEquipement',
                                data: { mode: EditModeEnum.CREATE },
                                component: EditCategorieEquipementComponent,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'types-ouvrage',
                data: { authorities: ['ROLE_ADMIN'] },
                canActivate: [AuthGuard],
                children: [
                    {
                        path: '',
                        component: GestionTypesOuvrageComponent,
                    },
                    {
                        path: 'consulter-types-ouvrage/:idTypeOuvrage',
                        data: { consulter: true, duplicate: false },
                        component: CreationTypesOuvrageComponent,
                    },
                    {
                        path: 'edit-type-ouvrage',
                        children: [
                            {
                                path: '',
                                data: { consulter: false, duplicate: false },
                                component: CreationTypesOuvrageComponent,
                            },
                            {
                                path: ':idTypeOuvrage',
                                data: { consulter: false, duplicate: false },
                                component: CreationTypesOuvrageComponent,
                            },
                            {
                                path: ':idTypeOuvrage/duplicate',
                                data: { consulter: false, duplicate: true },
                                component: CreationTypesOuvrageComponent,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'materiaux',
                data: { authorities: ['ROLE_ADMIN'] },
                canActivate: [AuthGuard],
                children: [
                    {
                        path: '',
                        component: GestionMateriauxComponent,
                    },
                    {
                        path: 'consulter-materiaux/:idMateriel',
                        data: { consulter: true, duplicate: false },
                        component: CreationMateriauxComponent,
                    },
                    {
                        path: 'edit-materiaux',
                        children: [
                            {
                                path: '',
                                data: { consulter: false, duplicate: false },
                                component: CreationMateriauxComponent,
                            },
                            {
                                path: ':idMateriel',
                                data: { consulter: false, duplicate: false },
                                component: CreationMateriauxComponent,
                            },
                            {
                                path: ':idMateriel/duplicate',
                                data: { consulter: false, duplicate: true },
                                component: CreationMateriauxComponent,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'volumes',
                data: { authorities: ['ROLE_ADMIN'] },
                canActivate: [AuthGuard],
                children: [
                    {
                        path: '',
                        component: GestionPiecesComponent,
                    },
                    {
                        path: 'consulter-volume/:idVolume',
                        data: { consulter: true, duplicate: false },
                        component: CreationPieceComponent,
                    },
                    {
                        path: 'edit-volume',
                        children: [
                            {
                                path: '',
                                data: { consulter: false, duplicate: false },
                                component: CreationPieceComponent,
                            },
                            {
                                path: ':idVolume',
                                data: { consulter: false, duplicate: false },
                                component: CreationPieceComponent,
                            },
                            {
                                path: ':idVolume/duplicate',
                                data: { consulter: false, duplicate: true },
                                component: CreationPieceComponent,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'prestations',
                children: [
                    {
                        path: '',
                        component: GestionDiagnoticsComponent,
                    },
                    {
                        path: 'consulter-prestation/:idPrestation',
                        data: { consulter: true, duplicate: false },
                        component: CreationPrestationDiagnosticComponent,
                    },
                    {
                        path: 'edit-prestation',
                        children: [
                            {
                                path: '',
                                data: { consulter: false, duplicate: false },
                                component: CreationPrestationDiagnosticComponent,
                            },
                            {
                                path: ':idPrestation',
                                data: { consulter: false, duplicate: false },
                                component: CreationPrestationDiagnosticComponent,
                            },
                            {
                                path: ':idPrestation/duplicate',
                                data: { consulter: false, duplicate: true },
                                component: CreationPrestationDiagnosticComponent,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'gestion-template-rapport',
                data: { consulter: true, duplicate: false },
                component: GestionTemplateRapportComponent,
            },
            {
                path: 'libs-icones',
                data: { consulter: true, duplicate: false },
                component: LibsIconesComponent,
            },
            {
                path: 'categories-ouvrages',
                data: { authorities: ['ROLE_ADMIN'] },
                canActivate: [AuthGuard],
                children: [
                    {
                        path: '',
                        component: GestionCategoriesOuvragesPiecesComponent,
                    },
                    {
                        path: 'consulter-categorie-ouvrage/:idCategorieOuvrage',
                        data: { consulter: true, duplicate: false },
                        component: CreationCategorieOuvrageComponent,
                    },
                    {
                        path: 'edit-categorie-ouvrage',
                        children: [
                            {
                                path: '',
                                data: { consulter: false, duplicate: false },
                                component: CreationCategorieOuvrageComponent,
                            },
                            {
                                path: ':idCategorieOuvrage',
                                data: { consulter: false, duplicate: false },
                                component: CreationCategorieOuvrageComponent,
                            },
                            {
                                path: ':idCategorieOuvrage/duplicate',
                                data: { consulter: false, duplicate: true },
                                component: CreationCategorieOuvrageComponent,
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: 'gestion-agences',
        data: { authorities: ['ROLE_PROJECT_LEADER'] },
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: GestionAgencesComponent,
            },
            {
                path: 'consulter-agence/:idAgence',
                data: { consulter: true, duplicate: false },
                component: CreationAgenceComponent,
            },
            {
                path: 'edit-agence',
                children: [
                    {
                        path: '',
                        data: { consulter: false, duplicate: false },
                        component: CreationAgenceComponent,
                    },
                    {
                        path: ':idAgence',
                        data: { consulter: false, duplicate: false },
                        component: CreationAgenceComponent,
                    },
                ],
            },
        ],
    },
    {
        path: 'gestion-cofracs',
        data: { authorities: ['ROLE_PROJECT_LEADER'] },
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: GestionCofracsComponent,
            },
            {
                path: 'consulter-cofrac/:idCofrac',
                data: { consulter: true, duplicate: false },
                component: CreationCofracComponent,
            },
            {
                path: 'edit-cofrac',
                children: [
                    {
                        path: '',
                        data: { consulter: false, duplicate: false },
                        component: CreationCofracComponent,
                    },
                    {
                        path: ':idCofrac',
                        data: { consulter: false, duplicate: false },
                        component: CreationCofracComponent,
                    },
                ],
            },
        ],
    },
    {
        path: 'gestion-documents',
        data: { authorities: ['ROLE_PROJECT_LEADER'] },
        canActivate: [AuthGuard],
        loadChildren: () => import('./gestion-documents/gestion-documents.module').then((m) => m.GestionDocumentsModule),
    },
    {
        path: 'gestion-bons-de-commande-analyse',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./gestion-bons-commande-analyse/gestion-bons-commande-analyse.module').then((m) => m.GestionBonsCommandeAnalyseModule),
    },
    {
        path: 'gestion-commentaires-predefinis',
        data: { authorities: ['ROLE_PROJECT_LEADER'] },
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./gestion-commentaires-predefinis/gestion-commentaires-predefinis.module').then((m) => m.GestionCommentairesPredefinisModule),
    },
    {
        path: 'gestion-syntheses',
        data: { authorities: ['ROLE_PROJECT_LEADER', 'ROLE_MANAGER'] },
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: GestionSynthesesComponent,
            },
            {
                path: 'creer-synthese',
                component: CreationExportSyntheseComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AdminRoutingModule {}
