import { Component, EventEmitter, OnInit } from '@angular/core';
import { TreeUtilsNode } from '../../../../../lib/utils/tree.utils';
import { CategorieOuvrage } from '../../../../../model/categorie-ouvrage.model';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { URL_GESTION_CATEGORIE_OUVRAGE_CONSULTER, URL_GESTION_CATEGORIE_OUVRAGE_EDIT } from '../../../../../shared/constants/url.constants';
import { ReferenceApiService } from '../../../../../services/reference-api.service';
import { Router } from '@angular/router';
import { ConfirmationService } from '../../../../../lib/confirmation/confirmation.service';
import { BaseComponent } from '../../../../../lib/utils/base.component';
import { EtatWorkflow } from '../../../../../model/etat-workflow.model';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { NotificationService } from '../../../../../lib/notification/notification.service';
import { confirm } from '../../../../../utils/confirm.utils';
import { BehaviorSubject, of } from 'rxjs';

/** Flat node with expandable and level information */
interface CategoriesOuvrageFlatNode {
    expandable: boolean;
    name: string;
    level: number;
}

export function buildTree<T extends { id: string; nom: string; code: string; lienCodeParent: string }>(items: T[]): TreeUtilsNode<T>[] {
    const nodes: TreeUtilsNode<T>[] = items.map((item) => ({
        id: item.id,
        name: item.nom,
        children: [],
        data: item,
    }));

    const itemMap: { [key: string]: TreeUtilsNode<T> } = {};
    nodes.forEach((node) => (itemMap[node.data.code] = node));

    const rootNodes: TreeUtilsNode<T>[] = [];
    nodes.forEach((node) => {
        if (node.data.lienCodeParent && itemMap[node.data.lienCodeParent]) {
            itemMap[node.data.lienCodeParent].children.push(node);
        } else {
            rootNodes.push(node);
        }
    });

    return rootNodes;
}

@Component({
    selector: 'app-arbre-categories-ouvrage',
    templateUrl: './arbre-categories-ouvrage.component.html',
    styleUrls: ['./arbre-categories-ouvrage.component.scss'],
})
export class ArbreCategoriesOuvrageComponent extends BaseComponent implements OnInit {
    treeControl: FlatTreeControl<CategoriesOuvrageFlatNode> = new FlatTreeControl<CategoriesOuvrageFlatNode>(
        (node) => node.level,
        (node) => node.expandable
    );

    dataSource: MatTreeFlatDataSource<TreeUtilsNode<CategorieOuvrage>, CategoriesOuvrageFlatNode> = new MatTreeFlatDataSource(
        this.treeControl,
        new MatTreeFlattener(
            (node: TreeUtilsNode<CategorieOuvrage>, level: number) => ({
                expandable: !!node.children && node.children.length > 0,
                name: node.name,
                level: level,
                data: node.data,
            }),
            (node) => node.level,
            (node) => node.expandable,
            (node) => node.children
        )
    );

    hasChild = (_: number, node: CategoriesOuvrageFlatNode) => node.expandable;

    private readonly $refresh = new EventEmitter<void>();

    constructor(
        private readonly referenceApiService: ReferenceApiService,
        private readonly router: Router,
        private readonly confirmationService: ConfirmationService,
        private notificationService: NotificationService
    ) {
        super();
    }

    ngOnInit() {
        this.$refresh
            .pipe(
                switchMap(() => this.referenceApiService.buildCategorieOuvrage$()),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe((resp) => {
                this.dataSource.data = buildTree(resp);
            });
        this.$refresh.emit();
    }

    /**
     * Supprime définitivement la catégorie d'ouvrage spécifiée après confirmation de l'utilisateur.
     *
     * @method
     * @param {TreeInputData} categorieOuvrage - Données du nœud de la catégorie d'ouvrage à supprimer.
     */
    deleteCategorieOuvrage(categorieOuvrage: CategorieOuvrage) {
        this.confirmationService.confirm(`Voulez-vous supprimer définitivement la catégorie d'ouvrage: ${categorieOuvrage.nom} ?`, () => {
            this.referenceApiService
                .deleteCategorieOuvrage(categorieOuvrage.id)
                .pipe(
                    tap(() => this.notificationService.success(`La catégorie d'ouvrage: ${categorieOuvrage.nom} a bien été supprimée`)),
                    switchMap(() => this.referenceApiService.buildCategorieOuvrage$()),
                    takeUntil(this.ngUnsubscribe)
                )
                .subscribe((resp: CategorieOuvrage[]) => {
                    this.dataSource.data = buildTree(resp);
                });
        });
    }

    editCategorieOuvrage(typeOuvrageNode: CategorieOuvrage) {
        this.router.navigate([URL_GESTION_CATEGORIE_OUVRAGE_EDIT, typeOuvrageNode.id]);
    }

    onClickDupliquerCategorieOuvrage(categorieOuvrage: CategorieOuvrage) {
        this.router.navigate([URL_GESTION_CATEGORIE_OUVRAGE_EDIT, categorieOuvrage.id, 'duplicate']);
    }

    onClickConsulter(categorieOuvrage: CategorieOuvrage) {
        this.router.navigate([URL_GESTION_CATEGORIE_OUVRAGE_CONSULTER, categorieOuvrage.id]);
    }

    onChangeEtat(data: CategorieOuvrage, $event: MouseEvent) {
        $event.preventDefault();
        const enable = data.etatCategorieOuvrage === EtatWorkflow.INACTIF;
        const updateMessage = enable ? `Voulez-vous activer '${data.nom}' ?` : `Voulez-vous désactiver '${data.nom}'`;
        confirm(this.confirmationService, updateMessage)
            .pipe(
                filter((confirmed) => confirmed),
                switchMap((confirmed) => {
                    const newState = confirmed ? enable : !enable;
                    data.etatCategorieOuvrage = newState ? EtatWorkflow.ACTIF : EtatWorkflow.INACTIF;
                    if (confirmed) {
                        return this.referenceApiService
                            .updateCategorieOuvrage(data)
                            .pipe(switchMap(() => this.referenceApiService.buildCategorieOuvrage$()));
                    } else {
                        return of([]);
                    }
                })
            )
            .subscribe();
    }

    refresh() {
        this.$refresh.emit();
    }
}
