import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PointDeControleBien } from '../../../../../../../model/point-de-controle.model';
import { FormBuilder } from '@angular/forms';
import { Volume } from 'src/app/model/bien.model';

@Component({
    selector: 'app-piece-unitaire-block',
    templateUrl: './piece-unitaire-block.component.html',
    styleUrls: ['./piece-unitaire-block.component.scss'],
})
export class PieceUnitaireBlockComponent implements OnInit, OnChanges {
    @Input()
    surface: any;

    @Input() set typeZse(typeZse: string) {
        if (typeZse == 'Zone adjacente ou pouvant être impacter par les travaux') {
            this.isActiveSurfaceInterfaceDisabled = false;
        } else {
            this.surface?.get('activeSurfaceInterface')?.setValue(false);
            this.isActiveSurfaceInterfaceDisabled = true;
        }
    }

    @Input()
    biens: PointDeControleBien[] = [];

    @Input()
    volumesSurface: any;

    @Output()
    surfaceSelected = new EventEmitter<any>();

    @Output()
    volumeDeleted = new EventEmitter<any>();

    @Output()
    surfaceDeleted = new EventEmitter<any>();

    @Input()
    volumes: Volume[] = [];

    @Input()
    disabled: boolean = false;

    @Input()
    nbVolumes: any;

    @Input()
    listeIdVolumes = [];
    listVolumePu = [];
    // form: FormGroup;
    isCheckboxDisabled: Boolean = false;
    isActiveSurfaceInterfaceDisabled: Boolean = false;

    hasChanged: Boolean;

    constructor(public dialog: MatDialog, private formBuilder: FormBuilder) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.computeVolumes();
    }

    onChangeLongueurMaxi(val: number): void {
        if (val > 15) {
            this.isCheckboxDisabled = true;
        } else {
            this.isCheckboxDisabled = false;
        }
    }

    ngOnInit(): void {}

    computeVolumes() {
        this.listVolumePu = [];

        //Si on connait la surface de chaque volumes via le plan
        if (Object.keys(this.volumesSurface).length > 0) {
            let surface = 0;
            this.listeIdVolumes.forEach((id) => {
                const vol = this.volumes.find((x) => x.id === id);

                surface += this.volumesSurface[vol.id];
                this.listVolumePu.push(vol);
            });
            this.surface.get('superficie').setValue(Math.round(surface)), { emitEvent: false };
        } else {
            this.listeIdVolumes.forEach((id) => {
                const vol = this.volumes.find((x) => x.id === id);
                this.listVolumePu.push(vol);
            });
        }
    }

    /**
     * @description Permet de supprimer un volume individuel en fonction de son id
     * @param idVolume
     */
    delete(idVolume: number) {
        this.volumeDeleted.emit(idVolume);
    }

    /**
     * @description Supprime la surface sélectionnée et tous les volumes qu'elle contient
     * @param event
     */
    deleteSurface(event) {
        event.stopPropagation();
        this.surfaceDeleted.emit();
    }

    add(event) {
        event.stopPropagation();
        this.surfaceSelected.emit();
    }

    roundDown(number, decimals?) {
        decimals = decimals || 0;
        return Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals) + 1;
    }

    get surfaceInterface() {
        return this.surface.get('longueurInterface').value * this.surface.get('hauteurInterface').value;
    }

    onChangeInferieurSeuilMin() {
        if (this.surface.get('inferieurSeuilMin').value == true) {
            this.surface.get('longueurMax').clearValidators();
            this.surface.get('longueurMax').setErrors(null);
            this.surface.get('longueurMax').setValue('');
        }
    }
}
