import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationStore, BaseComponent } from 'src/app/commons-lib';
import {
    URL_GESTION_EQUIPEMENTS,
    URL_GESTION_MATERIAUX,
    URL_GESTION_PIECES,
    URL_GESTION_PRESTATIONS,
    URL_GESTION_TEMPLATE_RAPPORT,
    URL_GESTION_TYPES_OUVRAGE,
    URL_GESTION_LIBS_ICONES,
    URL_GESTION_CATEGORIES_OUVRAGES,
} from 'src/app/shared/constants/url.constants';
import { takeUntil } from 'rxjs/operators';
import { UserSpecificService } from '../../../services/user-specific.service';

@Component({
    selector: 'app-gestion-referentiels',
    templateUrl: './gestion-referentiels.component.html',
    styleUrls: ['./gestion-referentiels.component.scss'],
})
export class GestionReferentielsComponent extends BaseComponent implements OnInit, OnDestroy {
    hasAdminAuthority = false;

    private readonly listRole = ['ROLE_ADMIN'];

    constructor(private readonly router: Router, private readonly userSpecificService: UserSpecificService) {
        super();
    }

    ngOnInit(): void {
        this.userSpecificService
            .checkIfUserHasAuthorities(this.listRole)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((hasAuthorities) => (this.hasAdminAuthority = hasAuthorities));
    }

    goToEquipements() {
        this.router.navigate([URL_GESTION_EQUIPEMENTS]);
    }

    goToTemplateRapport() {
        this.router.navigate([URL_GESTION_TEMPLATE_RAPPORT]);
    }

    goToLibIcones() {
        this.router.navigate([URL_GESTION_LIBS_ICONES]);
    }

    goToTypesOuvrage() {
        this.router.navigate([URL_GESTION_TYPES_OUVRAGE]);
    }

    goToMateriaux() {
        this.router.navigate([URL_GESTION_MATERIAUX]);
    }

    goToVolumes() {
        this.router.navigate([URL_GESTION_PIECES]);
    }

    goToPrestations() {
        this.router.navigate([URL_GESTION_PRESTATIONS]);
    }

    goToCategoriesOuvrages() {
        this.router.navigate([URL_GESTION_CATEGORIES_OUVRAGES]);
    }
}
