import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SequenceModalComponent } from '../sequence-modal/sequence-modal.component';
import * as moment from 'moment';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-sequence-list',
    templateUrl: './sequence-list.component.html',
    styleUrls: ['./sequence-list.component.scss'],
})
export class SequenceListComponent {
    @Input()
    sequenceList: FormArray;

    selectedSequenceIndex: number = undefined;
    color: any;

    constructor(public dialog: MatDialog) {}

    openNewModal(): void {
        const dialogRef = this.dialog.open(SequenceModalComponent, {
            width: '250px',
            data: {
                sequence: {
                    action: 'new',
                },
            },
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result == undefined) return;

            this.addSequence(result);
        });
    }

    edit(index: number) {
        this.selectedSequenceIndex = index;
        const data = {
            sequence: {
                action: 'edit',
                dateTimeDebut: moment(this.formCurrentSequence.get('dateTimeDebut').value, 'DD/MM/YYYY HH:mm'),
                dateTimeFin: moment(this.formCurrentSequence.get('dateTimeFin').value, 'DD/MM/YYYY HH:mm'),
            },
        };
        const dialogRef = this.dialog.open(SequenceModalComponent, {
            width: '250px',
            data,
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe((result) => {
            //Si le bouton 'Annuler' a été cliqué
            if (result == undefined) return;

            this.formCurrentSequence.patchValue({
                dateTimeDebut: result.dateDebut.format('DD/MM/YYYY HH:mm'),
                dateTimeFin: result.dateFin.format('DD/MM/YYYY HH:mm'),
                difference: result.dateFin.diff(result.dateDebut, 'days'),
            });
        });
    }

    addSequence(data: any) {
        this.sequenceList.push(
            new FormGroup({
                dateTimeDebut: new FormControl(data.dateDebut.format('DD/MM/YYYY HH:mm')),
                dateTimeFin: new FormControl(data.dateFin.format('DD/MM/YYYY HH:mm')),
                difference: new FormControl(data.dateFin.diff(data.dateDebut, 'days')),
            })
        );
    }

    remove(index: number) {
        this.sequenceList.removeAt(index);
    }

    /**
     * Renvoi la séquence actuellement sélectionnée
     */
    get formCurrentSequence(): FormGroup {
        return this.sequenceList.controls[this.selectedSequenceIndex] as FormGroup;
    }
}
