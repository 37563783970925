import { Inject, Injectable, Optional } from '@angular/core';
import { ITypePrestationInterface, TYPE_PRESTATION_SERVICE } from './interfaces/type-prestation.interface';
import { TypePrestation } from '../model/type-prestation.model';

@Injectable({
    providedIn: 'root',
})
export class DiagnosticHandlerService {
    constructor(@Optional() @Inject(TYPE_PRESTATION_SERVICE) private typePrestationService: ITypePrestationInterface[]) {}

    /**
     * Retourne le service qui implement ITypePrestationInterface pour le type de prestation ou
     * undefined s'il n'y a pas d'implémentation
     */
    getTypePrestationService(typePrestation: TypePrestation): ITypePrestationInterface {
        if (this.typePrestationService) {
            return this.typePrestationService.find((tp) => tp.getTypePrestation() === typePrestation);
        }
        return undefined;
    }
}
