<div class="d-flex flex-grow-1 flex-wrap menu-container">
    <div
        class="d-flex flex-grow-1 flex-no-wrap overflow-auto align-items-center niveau-container w-85"
        cdkDropList
        cdkDropListOrientation="horizontal"
        [cdkDropListDisabled]="readonlyMode"
        (cdkDropListDropped)="dropNiveau($event)"
    >
        <div
            *ngFor="let niveau of currentBien?.description"
            cdkDrag
            [cdkDragData]="niveau"
            class="d-flex no-gutters align-items-center"
        >
            <button
                mat-button
                color="primary"
                class="mr-1"
                [ngClass]="niveau === selectedNiveau ? 'current-stair-button' : 'stair'"
                (click)="changeNiveau(niveau)"
            >
                <span class="d-flex align-items-center" [ngClass]="{ justify: niveau['justifie'] }">
                    <span cdkDragHandle class="c-grabbable d-flex" *ngIf="!readonlyMode">
                        <lib-icon>drag_indicator</lib-icon>
                    </span>
                    <mat-icon *ngIf="!niveau.storeyId" class="icon-small mr-3">location_off</mat-icon>
                    {{ niveau.nom }}
                </span>
            </button>
        </div>

        <button
            *ngIf="!readonlyMode"
            class="border rounded"
            mat-icon-button
            matTooltip="Ajouter un niveau"
            (click)="addNiveau()"
            data-cy="ajouter-niveau-button"
        >
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <ng-container *ngIf="viewMode === ViewMode.LIST">
        <div *ngIf="!currentVolume" class="w-15 d-flex align-items-center justify-content-end">
            <span class="mr-2">Description directe</span>
            <mat-slide-toggle
                [checked]="descriptionDirecte"
                (change)="handleDescriptionDirecteChange($event)"
            ></mat-slide-toggle>
        </div>
        <div *ngIf="currentVolume" class="w-15 d-flex justify-content-end">
            <button mat-raised-button color="accent" class="my-auto" (click)="handleClickReprendreVisite()">
                <span>Reprendre la visite</span>
            </button>
        </div>
    </ng-container>
    <ng-container *ngIf="viewMode === ViewMode.PLAN">
        <div class="w-15 d-flex justify-content-end">
            <button
                mat-raised-button
                color="accent"
                class="my-auto"
                [disabled]="readonlyMode"
                (click)="handleGoToEditeur()"
            >
                <mat-icon>layers</mat-icon>
                <span>Editeur</span>
            </button>
        </div>
    </ng-container>
</div>
<!--<div *ngIf="currentBien?.jsonPlan" class="pr-2">Plan déjà présent</div>-->
