<div class="app-prelevement-init" *ngIf="isLoaded">
    <div class="verification-card-container">
        <verification-card
            *ngIf="form != undefined"
            [isInEditMode]="isInEditMode"
            [isValidated]="isValidated"
            (onCancel)="cancelModification()"
            (onSave)="saveModification()"
            (onValidate)="validateTab()"
            (onEdit)="startModification()"
            [form]="form"
        >
            Veuillez vérifier les compétences
        </verification-card>
    </div>
    <div class="form">
        <div class="section" *ngIf="contientMetop">
            <div class="header">Matériel du technicien</div>
            <mat-list role="list">
                <mat-list-item role="listitem" style="height: 72px" *ngFor="let item of lstFilds">
                    <div class="d-flex justify-content-between w-100 align-items-center">
                        <div class="col-6">{{ item.label }}</div>
                        <mat-form-field class="col-6" appearance="fill">
                            <input
                                matInput
                                name="{{ item.property }}"
                                id="{{ item.property }}"
                                [(ngModel)]="material[item.property]"
                                [readonly]="!isInEditMode"
                            />
                        </mat-form-field>
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
        <div class="section" *ngIf="materielChantierNecessaire">
            <div class="header">Matériel sur le chantier</div>
            <mat-list role="list">
                <mat-list-item role="listitem" style="height: 72px">
                    <div class="d-flex justify-content-between w-100 align-items-center">
                        <div class="col-6">Immatriculation UMD</div>
                        <mat-form-field class="col-6" appearance="fill">
                            <input
                                matInput
                                name="immatriculationUmd"
                                id="immatriculationUmd"
                                [(ngModel)]="material.immatriculationUmd"
                                [readonly]="!isInEditMode"
                            />
                        </mat-form-field>
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
    </div>
    <p *ngIf="!materielChantierNecessaire && !contientMetop">Aucun matériel requis</p>
</div>
