import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Contact, RoleContact } from '../../../model/contact.model';
import { EditContactModalComponent } from '../edit-contact/edit-contact-modal/edit-contact-modal.component';
import { first } from 'rxjs/operators';
import { InterventionService } from '../../../services/intervention.service';

@Component({
    selector: 'app-accompagnateurs',
    templateUrl: './accompagnateurs.component.html',
})
export class AccompagnateursComponent {
    private _contacts: Contact[] = [];

    @Input() readonlyMode = false;

    @Input() set contacts(contacts: Contact[]) {
        this._contacts = contacts ? contacts : this._contacts;
    }

    get contacts() {
        return this._contacts;
    }

    constructor(private matDialog: MatDialog, private interventionService: InterventionService) {
        this.interventionService
            .getCurrentIntervention()
            .pipe(first())
            .subscribe((intervention) => {
                // Init contacts
                this.contacts =
                    // Utilisation de JSON pour créer de nouvelles références pour les contacts et éviter de modifier la présence des contacts de l'intervention actuelle
                    JSON.parse(JSON.stringify(intervention.contacts))
                        .slice()
                        .map((it) => {
                            it.presence = undefined;
                            return it;
                        });
            });
    }

    onClickAddContact() {
        return this.matDialog
            .open(EditContactModalComponent, {
                data: {
                    contact: new Contact(),
                    isReadOnly: false,
                    isProprietaire: false,
                    isDonneurOrdre: false,
                    isCreation: true,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this._contacts.push(result.contact);
                }
            });
    }

    onClickEditContact(index: number) {
        const contactToEdit = { ...this.contacts[index] };
        return this.matDialog
            .open(EditContactModalComponent, {
                data: {
                    contact: contactToEdit,
                    isReadOnly: false,
                    isProprietaire: contactToEdit.typeRole === RoleContact.PROPRIETAIRE,
                    isDonneurOrdre: contactToEdit.typeRole === RoleContact.DONNEUR_ORDRE,
                    isCreation: false,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    if (result.deleteContact) {
                        this.contacts.splice(index, 1);
                    } else {
                        this.contacts[index] = result.contact;
                    }
                }
            });
    }
}
