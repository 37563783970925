// noinspection TypeScriptValidateJSTypes

import { ITypePrestationInterface } from '../../../../services/interfaces/type-prestation.interface';
import { Injectable, Type } from '@angular/core';
import { first } from 'rxjs/operators';
import { TypePrestation, TypePrestationEnum } from '../../../../model/type-prestation.model';
import { Route } from '@angular/router';
import { electriciteNavBarRoutes } from '../electricite-routing.module';
import { Diagnostic } from '../../../../model/diagnostic.model';
import { ContenuDiagnostic } from '../../../../model/diagnostic-contenu.model';
import { Intervention } from '../../../../model/intervention.model';
import { Rule } from '../../../../model/regle.model';
import { IDiagReportData, InterventionReportData } from '../../../../model/rapport.model';
import { Commentaire } from '../../../../model/commentaire.model';
import { CommentairePredefini } from '../../../../model/commentaire-predefini.model';
import { Electricite, ElectriciteReportData, PointControle, ValeurCheckpoint } from '../model/electricite.model';
import { ElectriciteService } from './electricite.service';
import { CommentUtils } from '../../../../utils/comment.utils';
import { BonCommandeAnalyseAdmin } from '../../../../model/bon-commande.model';
import { Observable, of } from 'rxjs';
import { ReportElecBlockComponent } from '../report/blocks/report-elec-block.component';
import { TypeReport } from '../../../../model/reference-prestation.model';
import { PARAM_VOLUME_VISITE, URL_MS_REPORT_FILE } from '../../../../shared/constants/cndiag.constants';
import { DocumentsService } from '../../../../services/documents.service';
import { ReportagePhotoService } from '../../../../services/reportage-photo.service';
import { nonOption, nonVerifiabledOption, ouiOption, sansObjetOption } from '../shared/electricite.constants';
import { LegendScreenshot } from 'src/app/model/screenshot-svg.model';
import { cn_storey } from '@acenv/cnmap-editor';
import { Bien, Niveau, Volume } from '../../../../model/bien.model';
import { PointDeControleService } from '../../../../services/point-de-controle.service';
import { CommentairesBlockComponent } from '../../hap/modules/report/blocks/commentaires-block/commentaires-block.component';
import { ItemElementMesurage, RowBienMesurage, RowNiveauMesurage, RowVolumeMesurage, SubBlockMesurage } from '../../mesurage/model/mesurage.model';
import { NAMES_MAPPER } from '../../mesurage/shared/mesurage.constants';
import { enumTypesCommentaire } from 'src/app/model/type-commentaire.model';
import { Estimation } from '../../polluant/model/estimation.model';
import { CustomSelectGroup, ItemCustomSelect } from 'src/app/modules/shared/custom-select/custom-select.model';
import { id } from 'date-fns/locale';
import { TypeVolume } from '../../../../model/type-element-a-controler.model';

/**
 * Liste des Type de component (Class) de block de rapport disponible pour la composition
 * du contenu d'un raport
 */
export const REPORT_BLOCK_CATALOG: { [key: string]: Type<any> } = {
    ReportElecBlockComponent: ReportElecBlockComponent,
    CommentairesBlockComponent: CommentairesBlockComponent,
};

@Injectable({
    providedIn: 'root',
})
export class TypeElectriciteService implements ITypePrestationInterface {
    private typePrestation: TypePrestation = TypePrestationEnum.ELECTRICITE;
    private idInterneIC = ['PC77', 'PC62', 'PC51', 'PC122']; // Id interne des IC (si répondu oui on coche l'IC si non on la décoche)
    constructor(
        private electriciteService: ElectriciteService,
        private readonly documentsService: DocumentsService,
        private readonly reportagePhotoService: ReportagePhotoService,
        private readonly pointDeControleService: PointDeControleService
    ) {}

    generateLegendForScreenshot(diagnostic: Diagnostic): LegendScreenshot[] {
        return [];
    }

    getTypePrestation(): TypePrestation {
        return this.typePrestation;
    }

    getRoutes(): Route[] {
        return electriciteNavBarRoutes;
    }

    getCodeBimEquipementBien(typePrestation: TypePrestation): string[] {
        console.log('TypeElectriciteService - getCodeBimEquipementBien');

        return undefined;
    }

    getCompletionPercentage(diagnostic: Diagnostic): number {
        // Points de contrôle
        const valeursParametres = diagnostic.pointsDeControleBiens[0].valeursParametres;
        const pointsControles = this.electriciteService.electriciteConfig$.value.pointsControles.filter(
            (cp) =>
                !!cp.description &&
                !!cp.numeroArticle &&
                !!cp.idChapitre &&
                (valeursParametres[cp.idInterne] ? !valeursParametres[cp.idInterne].isGrey : true)
        );
        const totalCp = pointsControles.length;
        const completeCp = pointsControles.filter((cp) => valeursParametres[cp.idInterne] && valeursParametres[cp.idInterne].valeur).length;
        return (completeCp * 100) / totalCp;
    }

    getContenuDiagnosticFromParent(diagnosticToUpdate: Diagnostic, diagnosticParent: Diagnostic): ContenuDiagnostic {
        return { ...diagnosticParent.contenuDiagnostic };
    }

    getContenuDiagnostic(typePrestation: TypePrestation): Observable<ContenuDiagnostic> {
        return of(new Electricite());
    }

    getDiagnosticReportData(intervention: Intervention, diagnostic: Diagnostic, rules?: Rule[], optionPlan?: boolean): IDiagReportData {
        const diagReportData = new ElectriciteReportData();
        diagReportData.id = diagnostic.id;
        diagReportData.typePrestation = diagnostic.typePrestation;
        diagReportData.refRapport = diagnostic.reportDatas.find((reportDataTemp) => reportDataTemp.typeReport === TypeReport.REPORT).refRapport;

        if (diagnostic.recommandationsFinales.length) {
            diagReportData.recommandations = diagnostic.recommandationsFinales.map((it) => it.contenu);
        }
        if (diagnostic.constatationsFinales.length) {
            diagReportData.constatationsDiverses = diagnostic.constatationsFinales.map((it) => it.contenu);
        }
        // Liste des Documents
        diagReportData.documentsData = this.documentsService.buildDocumentsData(intervention, diagnostic);
        diagReportData.annexes = diagReportData.documentsData
            .filter((doc) => doc.afficherDansRapport)
            .map((doc) => ({
                id: doc.nom,
                filename: URL_MS_REPORT_FILE + doc.idFichier,
                type: 'pdf',
            }));

        // Reportage photos
        diagReportData.reportagesPhotos = this.reportagePhotoService.buildReportagePhotoData(diagnostic);
        // Screenshot
        diagReportData.screenshotsPlan = diagnostic.screenshotsPlan;
        let config = null;
        this.electriciteService.electriciteConfig$.pipe(first()).subscribe((conf) => (config = conf));
        //Point de controles tout le temps afficher dans le rapport Année d'installation () / Distributeur / Installation alimentée
        diagReportData.pointsControlesInitialisation = this.getPointsControlesInitialisation(
            intervention,
            diagnostic.contenuDiagnostic as Electricite
        );
        const ptcRepNonTmp = this.getCheckpointsByReponse(diagnostic, nonOption.value, config.pointsControles);
        const ptcRepOuiTmp = this.getCheckpointsByReponse(diagnostic, ouiOption.value, config.pointsControles);
        const ptcRepNVTmp = this.getCheckpointsByReponse(diagnostic, nonVerifiabledOption.value, config.pointsControles);
        const ptcRepSOTmp = this.getCheckpointsByReponse(diagnostic, sansObjetOption.value, config.pointsControles);
        let allRepPC = [];
        allRepPC = allRepPC.concat(ptcRepNonTmp, ptcRepOuiTmp, ptcRepNVTmp, ptcRepSOTmp);

        const ptcICRepOui = this.getIC(ptcRepOuiTmp);

        // Filtre sur les points de contrôles qui ne sont pas dans "MesureCompensatoire"
        let idsPCMC = config.mesuresCompensatoires.map((id) => id.pcMesureCompensatoire);
        let idsPcRefMc = [];
        config.mesuresCompensatoires.forEach((mc) => {
            idsPcRefMc = idsPcRefMc.concat(mc.lstPointControlesAssocies);
        });

        idsPCMC = idsPCMC.concat(idsPcRefMc);

        const ptcRepNonMC = ptcRepNonTmp.filter((ptc) => {
            return idsPcRefMc.includes(ptc.idInterne);
        });

        const ptcAllRepNon = ptcRepNonTmp.filter((ptc) => {
            return !idsPCMC.includes(ptc.idInterne);
        });

        const ptcRepNon = ptcAllRepNon.filter((ptc) => {
            return !this.idInterneIC.includes(ptc.idInterne);
        });

        let mesuresCompensatoiresCorrectes: boolean;
        let anomalieSurMesureCompensatoire = false;

        for (let i = 0; i < config.mesuresCompensatoires.length; i++) {
            const repMesureCompensatoire = this.getValeurCheckpoint(diagnostic, config.mesuresCompensatoires[i].pcMesureCompensatoire);

            for (let j = 0; j < config.mesuresCompensatoires[i].lstPointControlesAssocies.length; j++) {
                if (this.getValeurCheckpoint(diagnostic, config.mesuresCompensatoires[i].lstPointControlesAssocies[j]).valeur === nonOption.value) {
                    anomalieSurMesureCompensatoire = true;
                    if (
                        repMesureCompensatoire?.valeur === nonOption.value ||
                        repMesureCompensatoire?.valeur === nonVerifiabledOption.value ||
                        repMesureCompensatoire?.valeur === sansObjetOption.value
                    ) {
                        mesuresCompensatoiresCorrectes = false;
                        break;
                    } else if (repMesureCompensatoire.valeur === ouiOption.value) {
                        mesuresCompensatoiresCorrectes = true;
                    }
                } else {
                    mesuresCompensatoiresCorrectes = true;
                }
            }
        }
        diagReportData.mesureCompensatoireDetectee = mesuresCompensatoiresCorrectes;
        diagReportData.anomalieSurMesureCompensatoire = anomalieSurMesureCompensatoire;

        // ConclusionEvalRisque
        diagReportData.conclusionEvalRisque = [];
        config.conclusionsEvalRisques.forEach((v) => {
            const categorie = config.conclusionsCategoriesEvalRisques.find((c) => c.idInterne === v.idCategorieEvalRisque);
            diagReportData.conclusionEvalRisque.push({
                idInterne: v.idInterne,
                reference: v.reference,
                description: v.description,
                ordreCategorie: v.ordre,
                ordre: categorie.ordre,
                conclusionCategorieEvalRisque: categorie.description,
                checked: false,
            });
        });
        diagReportData.conclusionEvalRisque.sort((a, b) => {
            return a.ordreCategorie - b.ordreCategorie === 0 ? a.ordre - b.ordre : a.ordreCategorie - b.ordreCategorie;
        });

        const ptcRepNonVerifiable = ptcRepNVTmp.filter((ptc) => {
            return !idsPCMC.includes(ptc.idInterne);
        });

        let pcRep = [];
        pcRep = pcRep.concat(ptcRepNon, ptcRepNonVerifiable, ptcICRepOui, ptcRepNonMC);
        const autresPC = allRepPC.filter((pc) => {
            return !pcRep.includes(pc);
        });

        let autresCommentaires: Commentaire[] = [];
        autresPC.forEach((pc) => {
            const pcValeur = this.getValeurCheckpoint(diagnostic, pc.idInterne);
            if (pcValeur.commentairesId.length > 0) {
                this.updateCommentaires(intervention, diagReportData, pcValeur?.commentairesId, pc.numeroArticle, '');
                autresCommentaires = autresCommentaires.concat(this.getCommentaires(intervention, pcValeur.commentairesId));
            }
        });

        diagReportData.autresCommentaires.concat(autresCommentaires);

        let idsCommentairesAffiches: string[] = [];
        ['anomaliesPointControle', 'nonVerifiablePointDeControle', 'informationComplementaire', 'anomalieMesureCompensatoire'].forEach(
            (property, index) => {
                diagReportData[property] = [];
                [ptcRepNon, ptcRepNonVerifiable, ptcICRepOui, ptcRepNonMC][index].forEach((ptc) => {
                    let anoPC = null;
                    if (ptc.idRisqueEncouru?.length) {
                        anoPC = this.initAnomaliesPointControle(
                            intervention,
                            diagnostic,
                            diagReportData,
                            ptc,
                            ptc.idRisqueEncouru,
                            index === 0 || index === 2
                        );
                    } else if (ptc.idAnomalie?.length) {
                        const anomalie = config.anomalies.find((ano) => ano.idInterne === ptc.idAnomalie);
                        anoPC = this.initAnomaliesPointControle(
                            intervention,
                            diagnostic,
                            diagReportData,
                            ptc,
                            anomalie.idRisqueEncouru,
                            index === 0 || index === 2
                        );
                    } else {
                        const ptcParent = config.pointsControles.find((p) => p.idInterne === ptc.idPointControleParent);
                        if (ptcParent) {
                            const anomalie = config.anomalies.find((ano) => ano.idInterne === ptcParent.idAnomalie);
                            const idRisqueEncouru = ptcParent.idRisqueEncouru ? ptcParent.idRisqueEncouru : anomalie?.idRisqueEncouru;
                            if (idRisqueEncouru) {
                                anoPC = this.initAnomaliesPointControle(
                                    intervention,
                                    diagnostic,
                                    diagReportData,
                                    ptc,
                                    idRisqueEncouru,
                                    index === 0 || index === 2
                                );
                            } else {
                                console.error("Rien n'a été trouvé pour ce point de controle : " + ptc.idInterne);
                            }
                        }
                    }

                    //stockage des ids des commentaires présent dans les Anomalie, non vérifiable,MC et IC
                    anoPC.pointControle.commentaires.forEach((comm) => {
                        idsCommentairesAffiches.push(comm.id);
                    });
                    if (anoPC.mesureCompensatoire.commentaires) {
                        anoPC.mesureCompensatoire.commentaires.forEach((comm) => {
                            idsCommentairesAffiches.push(comm.id);
                        });
                    }

                    diagReportData[property].push(anoPC);
                });
                diagReportData[property].sort((a, b) => a.conclusionEvalRisque?.reference?.localeCompare(b.conclusionEvalRisque?.reference));
            }
        );

        /*let autresCommentaires: Commentaire[] = [];
        intervention.commentaires.forEach((comm) => {
            if (!idsCommentairesAffiches.includes(comm.id)) {
                autresCommentaires.push(comm);
            }
        });*/

        diagReportData.anomalieDetectee = !!diagReportData.anomaliesPointControle.length;
        // Les constations diverses qui sont cochées
        diagReportData.constatationDiverse = [];
        config.constationsDiverses.forEach((cd) => {
            const valeurCheckpoint = this.getValeurCheckpoint(diagnostic, cd.idInterne);
            if (valeurCheckpoint?.valeur) {
                let commentaires = this.getCommentaires(intervention, valeurCheckpoint.commentairesId);
                diagReportData.constatationDiverse.push({
                    idInterne: cd.idInterne,
                    description: cd.description,
                    referenceConclusionEvalRisque: cd.referenceConclusionEvalRisque,
                    reference: cd.reference,
                    idTypeConstatationDiverse: cd.idTypeConstatationDiverse,
                    commentaires,
                });
            }
        });
        let conclusionText = diagnostic.pointsDeControleBiens[0].valeursParametres['CONCLUSION_REPORT'];
        if (!conclusionText) {
            conclusionText = '';
            if (diagReportData.anomalieDetectee || (diagReportData.anomalieSurMesureCompensatoire && !diagReportData.mesureCompensatoireDetectee)) {
                conclusionText =
                    "L'installation intérieure d'électricité comporte une ou plusieurs anomalies, il est conseillé de faire réaliser, par un installateur électricien qualifié, les travaux permettant de lever au moins les anomalies relevées.\n";
            }
            if (!diagReportData.anomalieDetectee && !diagReportData.anomalieSurMesureCompensatoire && diagReportData.constatationDiverse.length > 0) {
                conclusionText += 'L’installation intérieure d’électricité ne comporte aucune anomalie, mais fait l’objet de constatations diverses.';
            }
            if (
                !diagReportData.anomalieDetectee &&
                !diagReportData.anomalieSurMesureCompensatoire &&
                diagReportData.constatationDiverse.length == 0
            ) {
                conclusionText +=
                    "L'installation intérieure d'électricité ne comporte aucune anomalie et ne fait pas l’objet de constatations diverses";
            }

            if (diagReportData.nonVerifiablePointDeControle?.length) {
                conclusionText +=
                    "Certains points de contrôles n'ont pu être effectués. De ce fait la responsabilité du propriétaire reste pleinement engagée en cas d'accident ou d'incident ayant pour origine une défaillance de toute ou partie de l'installation n'ayant pu être contrôlée.";
            }
        }
        diagReportData.conclusionText = conclusionText;
        const commentaires = intervention.commentaires;
        // Bloc visite
        const subBlockVolumesNonVisites = new SubBlockMesurage('VOLUMES_NON_VISITES', []);
        const subBlockVolumesVisites = new SubBlockMesurage('VOLUMES_VISITES', []);

        // BIENS
        let rowBienNonVisites = new RowBienMesurage(intervention.relationInterventionBiens[0].bien.nom);
        let rowBienVisites = new RowBienMesurage(intervention.relationInterventionBiens[0].bien.nom);
        intervention.relationInterventionBiens[0].bien.description.forEach((niveau) => {
            // Visitabilité
            let rowNiveauNonVisites = new RowNiveauMesurage(niveau.nom);
            let rowNiveauVisites = new RowNiveauMesurage(niveau.nom);
            // NIVEAUX
            niveau.volumes.forEach((volume) => {
                const rowVolume = this.prepareVolume(volume, diagReportData, commentaires);
                // Visitabilité
                if (volume.valeursParametres[PARAM_VOLUME_VISITE] && ['ko', 'warning'].includes(volume.valeursParametres[PARAM_VOLUME_VISITE])) {
                    const rowVolumeNonVisite = { ...rowVolume };
                    rowVolumeNonVisite.commentaires = rowVolume.commentaires.filter(
                        (it) => it.type === enumTypesCommentaire.JUSTIFICATION_NON_VISITE
                    );
                    rowNiveauNonVisites.volumes.push(rowVolumeNonVisite);
                }
                if (volume.valeursParametres['visite'] && volume.valeursParametres[PARAM_VOLUME_VISITE] === 'ok') {
                    const rowVolumeVisite = { ...rowVolume };
                    rowNiveauVisites.volumes.push(rowVolumeVisite);
                }
            });
            // Visitabilité

            // VOLUMES
            // Visitabilité
            rowNiveauNonVisites.volumes.length && rowBienNonVisites.niveaux.push(rowNiveauNonVisites);
            rowNiveauVisites.volumes.length && rowBienVisites.niveaux.push(rowNiveauVisites);

            // Visitabilité
        });

        subBlockVolumesNonVisites.biens.push(rowBienNonVisites);
        subBlockVolumesVisites.biens.push(rowBienVisites);

        // Visitabilité
        if (subBlockVolumesNonVisites.biens[0].niveaux.length > 0) {
            diagReportData.auMoinsUnVolumeNonVisite = true;
        }
        diagReportData.volumesNonVisites = subBlockVolumesNonVisites;
        diagReportData.volumesVisites = subBlockVolumesVisites;
        diagReportData.etat = diagnostic.etat;
        return diagReportData;
    }

    /**
     * Initialisation d'un rowVolume
     */
    private prepareVolume(volume: Volume, diagReportData: any, commentaires: Commentaire[]) {
        const newRowVolume = new RowVolumeMesurage(volume.nom, volume.id);
        newRowVolume.visite = new ItemElementMesurage(
            volume.valeursParametres[PARAM_VOLUME_VISITE]
                ? this.translateValue(PARAM_VOLUME_VISITE, volume.valeursParametres[PARAM_VOLUME_VISITE])
                : null,
            null
        );

        newRowVolume.commentaires = commentaires
            .filter((c) => {
                return volume.commentairesId.indexOf(c.id) !== -1;
            })
            .filter((it) => it.type !== enumTypesCommentaire.NOTE_PERSONNELLE);

        newRowVolume.commentaires.map((com) => {
            const newCom = Object.assign({}, com);
            if (newCom.imageId) {
                newCom.imageId = URL_MS_REPORT_FILE + com.imageId;
            }
            return newCom;
        });
        return newRowVolume;
    }
    private translateValue(ppty: string, value: string): string {
        let valueToDisplay = value;
        if (NAMES_MAPPER[ppty]) {
            const finded = NAMES_MAPPER[ppty].find((it) => it.valeur === value);
            if (finded) {
                valueToDisplay = finded.afficher;
            }
        }
        return valueToDisplay;
    }

    getPointsControlesInitialisation(intervention: Intervention, contenuDiagnostic: Electricite): any[] {
        let ptcRet: any[] = [];
        let labelRaport = contenuDiagnostic.dateInstallation.valeur;
        if (contenuDiagnostic.dateInstallation.checked) {
            labelRaport = 'Non Renseigné';
        }
        if (contenuDiagnostic.dateInstallation.estimation) {
            labelRaport += ' (estimation)';
        }

        ptcRet.push({
            id: contenuDiagnostic.dateInstallation.id,
            label: "Année d'installation",
            valeur: contenuDiagnostic.dateInstallation.valeur,
            estimation: contenuDiagnostic.dateInstallation.estimation,
            nonRenseigne: contenuDiagnostic.dateInstallation.checked,
            commentaires: this.getCommentaires(intervention, contenuDiagnostic.dateInstallation.commentairesId),
            labelRaport: labelRaport,
        });

        labelRaport = contenuDiagnostic.fournisseur.valeur;
        if (contenuDiagnostic.fournisseur.checked) {
            labelRaport = 'Non Renseigné';
        }
        ptcRet.push({
            id: contenuDiagnostic.fournisseur.id,
            label: "Distributeur d'électricité",
            valeur: contenuDiagnostic.fournisseur.valeur,
            estimation: false,
            nonRenseigne: contenuDiagnostic.fournisseur.checked,
            commentaires: this.getCommentaires(intervention, contenuDiagnostic.fournisseur.commentairesId),
            labelRaport: labelRaport,
        });

        if (contenuDiagnostic.sousTension.valeur === 'sousTension') {
            labelRaport = 'Sous tension';
        } else {
            labelRaport = 'Hors tension';
        }
        if (contenuDiagnostic.sousTension.checked) {
            labelRaport = 'Non Renseigné';
        }
        ptcRet.push({
            id: contenuDiagnostic.sousTension.id,
            label: 'Installation alimentée',
            valeur: contenuDiagnostic.sousTension.valeur,
            estimation: false,
            nonRenseigne: contenuDiagnostic.sousTension.checked,
            commentaires: this.getCommentaires(intervention, contenuDiagnostic.sousTension.commentairesId),
            labelRaport: labelRaport,
        });
        //ptcRet.push(contenuDiagnostic.capotsDemontables);
        //ptcRet.push(contenuDiagnostic.numeroCompteur);
        //ptcRet.push(contenuDiagnostic.presenceDispositif30mA);
        // ptcRet.push(contenuDiagnostic.typeBien);
        return ptcRet;
    }

    private initAnomaliesPointControle(
        intervention: Intervention,
        diagnostic: Diagnostic,
        diagReportData: ElectriciteReportData,
        ptc: PointControle,
        pIdRisqueEncouru: string,
        checked?: boolean
    ): any {
        let config = null;
        this.electriciteService.electriciteConfig$.pipe(first()).subscribe((conf) => (config = conf));
        // checked all CERisque qui sont dans la liste "idEvalRisque" du "idRisqueEncouru".
        const risqueEncouru = config.risquesEncourus.find((re) => re.idInterne === pIdRisqueEncouru);
        let conclusionEvalRisque;
        risqueEncouru.idEvalRisques.forEach((id) => {
            conclusionEvalRisque = diagReportData.conclusionEvalRisque.find((c) => c.idInterne === id);
            if (checked) {
                conclusionEvalRisque.checked = true;
            }
        });
        // Regarder dans toutes les "MesureCompensatoire" si le point de contrôle existe
        // Dans "lstPointControlesAssocies" alors on regarde
        // la réponse au point de contrôle (qui est une mesure compensatoire) "pcMesureCompensatoire".                }
        let mesureCompensatoire = {};
        const mesureCompensatoireFound = config.mesuresCompensatoires.find((p) => p.lstPointControlesAssocies.includes(ptc.idInterne));
        if (mesureCompensatoireFound) {
            const chp = config.pointsControles.find((p) => p.idInterne === mesureCompensatoireFound.pcMesureCompensatoire);
            const chpReponse1: ValeurCheckpoint = this.getValeurCheckpoint(diagnostic, mesureCompensatoireFound.pcMesureCompensatoire);
            this.updateCommentaires(intervention, diagReportData, chpReponse1?.commentairesId, chp.description, '');
            mesureCompensatoire = {
                idInterne: mesureCompensatoireFound.pcMesureCompensatoire,
                description: chp.description,
                libelleRapport: chp.libelleRapport,
                reponse: chpReponse1?.valeur,
                commentaires: this.getCommentaires(intervention, chpReponse1?.commentairesId),
            };
        }
        const chpReponse2 = this.getValeurCheckpoint(diagnostic, ptc.idInterne);
        const cat = config.conclusionsCategoriesEvalRisques.find((c) => c.idInterne === conclusionEvalRisque.idCategorieEvalRisque);
        this.updateCommentaires(
            intervention,
            diagReportData,
            chpReponse2?.commentairesId,
            ptc.numeroArticle,
            conclusionEvalRisque.reference + ' ' + conclusionEvalRisque.description
        );
        return {
            pointControle: {
                idInterne: ptc.idInterne,
                description: ptc.description,
                libelleRapport: ptc.libelleRapport,
                numeroArticle: ptc.numeroArticle,
                numeroFiche: ptc.numeroFiche,
                commentaires: this.getCommentaires(intervention, chpReponse2.commentairesId),
            },
            mesureCompensatoire,
            conclusionEvalRisque: {
                reference: conclusionEvalRisque.reference,
                categorieConclusionEvalRisque: conclusionEvalRisque.conclusionCategorieEvalRisque,
                description: conclusionEvalRisque.description,
            },
            risqueEncouru: {
                idInterne: risqueEncouru.idInterne,
                description: risqueEncouru.description,
                ordre: risqueEncouru.ordre,
            },
        };
    }

    private updateCommentaires(
        intervention: Intervention,
        diagReportData: ElectriciteReportData,
        commentairesId: string[],
        objetReference: string,
        nonConformiteReference: string
    ) {
        intervention.commentaires.filter((c) => {
            if (commentairesId?.includes(c.id)) {
                c.objetReference = objetReference;
                c.nonConformiteReference = nonConformiteReference;

                diagReportData.reportagesPhotos.forEach((reportage) => {
                    reportage.pages.forEach((page) => {
                        page.reportagePhotoDataPageItem.forEach((pageItem) => {
                            if (pageItem.idFichier === c.imageId) {
                                pageItem.objetReference = objetReference;
                                pageItem.nonConformiteReference = nonConformiteReference;
                                pageItem.localisations = c.localisations;
                            }
                        });
                    });
                });
            }
        });
    }
    private getCommentaires(intervention: Intervention, commentairesId: string[]): Commentaire[] {
        return intervention.commentaires.filter((c) => {
            return commentairesId?.includes(c.id);
        });
    }

    private getValeurCheckpoint(diagnostic: Diagnostic, idInterne: string): ValeurCheckpoint {
        return diagnostic.pointsDeControleBiens[0].valeursParametres[idInterne];
    }
    private getCheckpointsByReponse(diagnostic: Diagnostic, reponse: any, pointsControles: PointControle[]): PointControle[] {
        return pointsControles.filter((ch) => {
            const valeur: ValeurCheckpoint = this.getValeurCheckpoint(diagnostic, ch.idInterne);
            return valeur && valeur.valeur == reponse;
        });
    }

    getIC(pointsControles: PointControle[]): PointControle[] {
        return pointsControles.filter((ptc) => this.idInterneIC.includes(ptc.idInterne));
    }

    getReportBlockType(componentName: string): Type<any> {
        const blockType = REPORT_BLOCK_CATALOG[componentName];
        if (!blockType) {
            console.log('Block %s not found', componentName);
        }
        return blockType;
    }

    isItemAlreadyFilled(diagnostic: Diagnostic, type: string, itemId: string): boolean {
        console.log('TypeElectriciteService - isItemAlreadyFilled');

        return false;
    }

    prepareFilteredCommentsForReport(diagnosticData: IDiagReportData, hiddenComments: Map<string, string[]>) {
        console.log('TypeElectriciteService - prepareFilteredCommentsForReport');
    }

    prepareForm(intervention: Intervention, contenuDiagnostic: ContenuDiagnostic) {
        console.log('TypeElectriciteService - prepareForm');
        const contentDiagElec = contenuDiagnostic as Electricite;
    }

    prepareSpecificComments(diagnostic: Diagnostic, commentairesGeneraux: Commentaire[], commentaires: CommentairePredefini[]): Commentaire[] {
        console.log('TypeElectriciteService - prepareSpecificComments');
        return commentaires.map((it) => CommentUtils.commentairePredefiniToCommentaireMapper(it));
    }

    getDiagnosticBonCommandeData(intervention: Intervention, diagnostic: Diagnostic): IDiagReportData {
        return undefined;
    }

    generateDiagnosticBonCommande(
        intervention: Intervention,
        diagnostic: Diagnostic,
        interReportData: InterventionReportData
    ): BonCommandeAnalyseAdmin {
        return undefined;
    }

    prepareStoreyForScreenshot(diagnostic: Diagnostic, currentStorey: cn_storey, conf: any) {}

    deplaceVolume(diagnostic: Diagnostic, volumeSource: Volume, niveauDestination: Niveau, currentBien: Bien) {
        this.pointDeControleService.deplaceVolume(diagnostic, volumeSource, niveauDestination, currentBien);
    }

    mergeNiveau(diagnostic: Diagnostic, niveauSource: Niveau, niveauDestination: Niveau, currentBien: Bien) {
        this.pointDeControleService.mergeNiveau(diagnostic, niveauSource, niveauDestination, currentBien);
    }

    mergeVolume(diagnostic: Diagnostic, volumeSource: Volume, volumeDestination: Volume, currentBien: Bien, typeVolume: TypeVolume) {
        this.pointDeControleService.mergeVolume(diagnostic, volumeSource, volumeDestination, currentBien, typeVolume);
    }

    deplaceEquipement(idEquipement: string, diagnostic: Diagnostic, volumeDestination: Volume, bien: Bien) {
        this.pointDeControleService.deplaceEquipement(idEquipement, diagnostic, volumeDestination, bien);
    }
}
