import { Component, Inject, OnInit } from '@angular/core';
import { Document } from '../../../../model/document.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationStore, BaseComponent, DateUtils, MongoUtils, User } from 'src/app/commons-lib';
import { TypePrestation, TypePrestationEnum, typePrestationToLabel, typesPrestation } from '../../../../model/type-prestation.model';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InterventionService } from '../../../../services/intervention.service';
import { takeUntil } from 'rxjs/operators';
import { TypeDocument } from '../../../../model/type-document.model';
import { ReferenceService } from 'src/app/services/reference.service';
import { Intervention } from 'src/app/model/intervention.model';
import { CnSpinnerService } from '../../cn-spinner/service/cn-spinner.service';
import { combineLatest, Observable, of } from 'rxjs';
import { FileUploaderOutput } from '../../file-uploader/wizy-file-uploader.component';
import { InterventionFileService } from 'src/app/services/intervention-file.service';
import { BaseObject } from '../../../../model/base-object.model';
import * as moment from 'moment/moment';
import { DATE_FORMAT_INTERNATIONAL } from '../../../../shared/constants/cndiag.constants';
import { InterventionFile, TypeReferenceFichier } from 'src/app/model/intervention-file.model';

class DocumentModalData {
    constructor(
        public idIntervention: string,
        public document: Document,
        public documentsAlreadyPresent: BaseObject[] = [],
        public typePrestation?: TypePrestation,
        public codeTypeDocument?: string,
        public docsObligatoires?: string[],
        public intervention?: Intervention,
        public editFichier: boolean = false,
        public idFile?: string,
        public typeDocument?: string
    ) {}
}

@Component({
    selector: 'app-edit-document-modal',
    templateUrl: './edit-document-modal.component.html',
    styleUrls: ['./edit-document-modal.component.scss'],
})
export class EditDocumentModalComponent extends BaseComponent implements OnInit {
    intervention: Intervention;
    // Defini si on créer un nouveau document ou si c'est une édition
    newDocument = true;
    documentToEdit = new Document();
    typesPrestation: TypePrestation[] = typesPrestation.filter((typePrestationTemp) => typePrestationTemp != TypePrestationEnum.COMMUN);
    // Contient l'ensemble des types de document existant
    typesDoc: TypeDocument[] = [];
    // Contient les types de document a proposé dans la liste déroulante suivant la prestation choisie
    typesDocFiltered: TypeDocument[] = [];
    // Défini si le type de prestation est mdofiable dans la modale. il ne l'est pas si on est dans un diagnostic
    typePrestationReadonly = false;
    formDoc: FormGroup;
    fileUploaderOutput: FileUploaderOutput;
    user: User;

    displayTypePrestation = (item: TypePrestation) => {
        return typePrestationToLabel(item);
    };
    editFichier: boolean = false;

    constructor(
        private readonly dialogRef: MatDialogRef<EditDocumentModalComponent>,
        private readonly formBuilder: FormBuilder,
        private readonly referenceService: ReferenceService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly interventionFileService: InterventionFileService,
        private readonly interventionService: InterventionService,
        @Inject(MAT_DIALOG_DATA) public data: DocumentModalData,
        private readonly authenticationService: AuthenticationStore
    ) {
        super();
        dialogRef.disableClose = true;
        this.editFichier = !data.document || data.editFichier;
        this.createFormDoc();
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth1000-dialog');
        this.cnSpinnerService
            .withSpinner(
                combineLatest([
                    this.referenceService.findAllTypesDocument(),
                    this.data.intervention ? of(this.data.intervention) : this.interventionService.getCurrentIntervention(),
                    this.authenticationService.getCurrentUser(),
                ])
            )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([allTypeDocuments, intervention, user]) => {
                this.user = user;
                this.intervention = intervention;
                this.typesDoc = allTypeDocuments;

                // Filtre de la liste des types de prestation suivant les prestations présentes dans l'intervention
                this.typesPrestation = this.typesPrestation.filter((typePrestationTemp) => {
                    return this.intervention.prestationsDiagnostics
                        .map((prestaDiagTemp) => prestaDiagTemp.prestation.typePrestation)
                        .includes(typePrestationTemp);
                });

                // Mode édition si data.document existe
                if (this.data.document) {
                    this.newDocument = false;
                    this.documentToEdit = this.data.document;
                } else {
                    this.documentToEdit.id = MongoUtils.generateObjectId();
                    this.documentToEdit.isEditable = true;
                }

                if (this.data.typePrestation != null) {
                    this.typePrestationReadonly = true;
                    this.documentToEdit.typePrestations = [this.data.typePrestation];
                }

                this.populateForm();

                // Edit document
                if (!this.newDocument || (!this.newDocument && this.typePrestationReadonly)) {
                    this.typePrestationChanged();
                    this.formDoc.patchValue({
                        typeDocument: this.documentToEdit.typeDocument
                            ? this.typesDocFiltered.find((typeDocTemp) => typeDocTemp.id == this.documentToEdit.typeDocument.id)
                            : undefined,
                    });
                }
                // Création d'un nouveau document, au sein d'un diagnostic
                if (this.typePrestationReadonly && this.newDocument) {
                    this.typePrestationChanged();
                }

                if (this.data.codeTypeDocument) {
                    const typeDoc = this.typesDoc.find((it) => it.code === this.data.codeTypeDocument);
                    this.formDoc.patchValue({
                        typeDocument: typeDoc ?? undefined,
                    });
                }
            });
    }

    /**
     * Crée la structure du formulaire utilisé dans la modale
     */
    createFormDoc() {
        this.formDoc = this.formBuilder.group({
            nom: [
                '',
                [
                    Validators.required,
                    (control: AbstractControl) => {
                        if (
                            control.value &&
                            this.data.documentsAlreadyPresent
                                .filter((it: BaseObject) => it.id != this.documentToEdit.id)
                                .map((it: BaseObject) => it.nom.toLowerCase())
                                .includes(control.value.toLowerCase())
                        ) {
                            return { erreurNomDoc: true };
                        }
                        return null;
                    },
                ],
            ],
            typePrestation: ['', [Validators.required]],
            typeDocument: [null, [Validators.required]],
            dateDocument: ['', []],
            formFichier: this.createFormFichier(),
        });
    }

    /**
     * Crée la strucutre du formulaire de la partie fichier
     * @returns
     */
    createFormFichier() {
        return this.formBuilder.group({
            sourceTransmission: [''],
            idFichier: [this.data.idFile ?? ''],
            nomFichier: [''],
        });
    }

    /**
     * Complète le formulaire en cas d'édition
     */
    populateForm() {
        this.formDoc.patchValue({
            nom: this.documentToEdit.nom,
            typePrestation: this.documentToEdit.typePrestations ? this.documentToEdit.typePrestations : undefined,
            typeDocument: this.documentToEdit.typeDocument
                ? this.typesDocFiltered.find((typeDocTemp) => typeDocTemp.id == this.documentToEdit.typeDocument.id)
                : undefined,
            dateDocument: this.documentToEdit.dateDocument,
        });
        this.preselectTypePrestation();
    }

    /**
     * préselectionne les types de prestation dans la liste déroulante
     */
    preselectTypePrestation() {
        if (this.data.idFile) {
            this.formDoc.patchValue({
                typePrestation: [
                    ...this.intervention.prestationsDiagnostics.map(
                        (prestaDiagTemp) => prestaDiagTemp.prestation.typePrestation
                    ),
                ],
            });
        }
        this.preselectTypeDocument();
    }

    /**
     * définie le type de document présélectionné dans la liste déroulante
     */
    preselectTypeDocument() {
        this.typePrestationChanged();
        if (this.data.typeDocument) {
            this.formDoc.patchValue({
                typeDocument: this.typesDocFiltered.find((typeDocTemp) => typeDocTemp.code == this.data.typeDocument),
            });
        } else if (this.documentToEdit.typeDocument) {
            this.formDoc.patchValue({
                typeDocument: this.typesDocFiltered.find(
                    (typeDocTemp) => typeDocTemp.id == this.documentToEdit.typeDocument.id
                ),
            });
        }
    }

    /**
     * Évènement déclenché lors du clic sur le bouton annuler
     */
    onClickCancel() {
        this.dialogRef.close(false);
    }

    /**
     * Évènement déclenché lors du clic sur le bouton valider
     */
    onClickValidate() {
        // Mise à jour de l'intervention
        this.cnSpinnerService
            .withSpinner(this.saveDocument())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.dialogRef.close({ document: this.documentToEdit });
            });
    }

    /**
     * En cas de changement de type de prestation, refiltrer la liste des types documents
     */
    typePrestationChanged() {
        this.formDoc.patchValue({ typeDocument: null });
        this.formDoc.updateValueAndValidity();

        if (this.formDoc.value.typePrestation == undefined) {
            this.typesDocFiltered = this.typesDoc.sort((c1, c2) => c1.nom.localeCompare(c2.nom));
        } else {
            this.typesDocFiltered = this.typesDoc
                .filter((t) => {
                    let typeDocumentForPrestation;
                    let typePrestation;
                    const typeDocumentCheckpoints = t.typeDocumentCheckpoint;

                    // On vérifie soit avec la referencePrestation et les poitns de controle s'il y en a sinon, avec la liste des types de prestation
                    if (typeDocumentCheckpoints && typeDocumentCheckpoints.length > 0) {
                        typeDocumentForPrestation = typeDocumentCheckpoints.filter((typeDocumentCheckpointValue) => {
                            return this.formDoc.value.typePrestation.includes(typeDocumentCheckpointValue.referencePrestation.typePrestation);
                        });
                    } else {
                        typePrestation = this.formDoc.value.typePrestation.every((typePrestationTemp) =>
                            t.typePrestations.includes(typePrestationTemp)
                        );
                    }

                    return (typeDocumentForPrestation && typeDocumentForPrestation.length > 0) || typePrestation;
                })
                .sort((c1, c2) => c1.nom.localeCompare(c2.nom));
        }
    }

    onFileUploaderOutputChanged(fileUploaderOutput: FileUploaderOutput): void {
        this.fileUploaderOutput = fileUploaderOutput;
    }

    /**
     * On met à jour le document avec les données du formulaire
     * On enregistre l'interventionFile contenant le fichier pièce jointe s'il existe
     * @returns
     */
    private saveDocument(): Observable<InterventionFile> {
        // Prepare les données
        this.prepareDataToSave();

        // Si il y a un fichier, on crée l'interventionFile correspondant
        if (this.fileUploaderOutput) {
            // Upload du fichier et ajout d'une occurence InterventionFile
            return this.interventionFileService.uploadInterventionFile(
                this.fileUploaderOutput.interventionFile,
                this.fileUploaderOutput.dataUrl,
                this.fileUploaderOutput.fileName
            );
        } else {
            return of(null);
        }
    }

    /**
     * Prepare les données à sauvegarder
     * @private
     */
    private prepareDataToSave() {
        this.documentToEdit.nom = this.formDoc.get('nom').value;
        this.documentToEdit.typeDocument = this.formDoc.get('typeDocument').value;
        this.documentToEdit.typePrestations = this.formDoc.get('typePrestation').value;

        if (this.formDoc.get('dateDocument').value) {
            const dateDocument = moment(this.formDoc.get('dateDocument').value);
            this.documentToEdit.dateDocument = dateDocument.format(DATE_FORMAT_INTERNATIONAL);
        }

        // on modifie les données du fichier uniquement si un fichier est renseigné et qu'on est en mode ajout de document (pas en modification)
        // ou si on a passé en data un id de fichier
        if (this.fileUploaderOutput && this.editFichier) {
            this.documentToEdit.dateCreation = DateUtils.localDateTimeString();
            this.documentToEdit.dateTransmission = DateUtils.localDateTimeString();
            this.documentToEdit.sourceTransmission = this.formDoc.get('formFichier').value.sourceTransmission;
            this.documentToEdit.idFichier = this.formDoc.get('formFichier').get('idFichier').value;
            this.documentToEdit.nomFichier = this.formDoc.get('formFichier').get('nomFichier').value;
        } else if (this.data.idFile) {
            this.documentToEdit.dateCreation = DateUtils.localDateTimeString();
            this.documentToEdit.dateTransmission = DateUtils.localDateTimeString();
            this.documentToEdit.sourceTransmission = this.formDoc.get('formFichier').value.sourceTransmission;
            this.documentToEdit.idFichier = this.data.idFile;
            this.documentToEdit.nomFichier = this.formDoc.get('nom').value;
        }
    }
}
