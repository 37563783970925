import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/commons-lib';
import { CnSpinnerService } from '../cn-spinner/service/cn-spinner.service';

class CustomSelectIntoModalData {
    constructor(
        public multiple: boolean,
        public pathValueDisplayChoices: string[],
        public choices: any[],
        public titreModale: string,
        public labelModale: string
    ) {}
}

@Component({
    selector: 'app-custom-select-into-modal',
    templateUrl: './custom-select-into-modal.component.html',
    styleUrls: ['./custom-select-into-modal.component.scss'],
})
export class CustomSelectIntoModalComponent extends BaseComponent implements OnInit, OnDestroy {
    multipleChoices: boolean;
    pathValueDisplayChoices: string[];
    choices: any[];
    titreModale: string;
    labelModale: string;
    choicesSelected: any = null;

    constructor(
        public dialogRef: MatDialogRef<CustomSelectIntoModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CustomSelectIntoModalData,
        private cnSpinnerService: CnSpinnerService
    ) {
        super();
    }

    ngOnInit(): void {
        this.cnSpinnerService.show();

        this.dialogRef.addPanelClass('minwidth600-dialog');
        if (this.data) {
            this.choices = this.data.choices;
            this.pathValueDisplayChoices = this.data.pathValueDisplayChoices;
            this.multipleChoices = this.data.multiple;
            this.titreModale = this.data.titreModale;
            this.labelModale = this.data.labelModale;
        }

        this.cnSpinnerService.hide();
    }

    changeSelection(item: any) {
        this.choicesSelected = item;
    }

    cancel() {
        this.dialogRef.close(false);
    }

    confirm() {
        this.dialogRef.close({
            choicesSelected: this.choicesSelected,
        });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
