import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent, ConfirmationService, NotificationService } from 'src/app/commons-lib';
import { MesureZone, PointDeControleVolume } from '../../../../../../model/point-de-controle.model';
import { MatDialog } from '@angular/material/dialog';
import { CalculatriceModaleComponent } from '../../../../../shared/calculatrice-modale/calculatrice-modale.component';
import { ListUtils } from '../../../../../../utils/list.utils';
import {
    MESURE_NON_COMMUNIQUE,
    PARAM_AUTRE,
    PARAM_HORS_CARREZ,
    PARAM_NON_HABITABLE,
    PROPERTIES_MESURAGE_CARREZ,
    PROPERTIES_MESURAGE_HABITABLE,
    PROPERTIES_MESURAGE_UTILE,
} from '../../../shared/mesurage.constants';
import {
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE,
    PARAM_TOOLTIP_CALCULTATRICE,
    PARAM_TOOLTIP_DEDUIRE,
    PARAM_TOOLTIP_ZONE_AJOUT,
    PARAM_TOOLTIP_ZONE_DEDUCTION,
} from '../../../../../../shared/constants/cndiag.constants';
import { TypePrestation, TypePrestationEnum } from '../../../../../../model/type-prestation.model';
import { PropertiesMesurage } from '../../../model/mesurage.model';

@Component({
    selector: 'app-mesure-zone',
    templateUrl: './mesure-zone.component.html',
    styleUrls: ['./mesure-zone.component.scss'],
})
export class MesureZoneComponent extends BaseComponent implements OnInit {
    readonly MESURE_NON_COMMUNIQUE = MESURE_NON_COMMUNIQUE;

    @Input() zone: MesureZone;

    @Input() typePrestation: TypePrestation;

    @Input()
    set volume(volume: PointDeControleVolume) {
        this._volume = volume;
        switch (this.typePrestation) {
            case TypePrestationEnum.MESURAGE:
                this.nonRepute = this._volume.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ];
                if (this.zoneToEdit) {
                    this.zoneToEdit.type = this.nonRepute ? PARAM_HORS_CARREZ : '';
                }
                break;
            case TypePrestationEnum.MESURAGE_HABITABLE:
                this.nonRepute = this._volume.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE];
                if (this.zoneToEdit) {
                    this.zoneToEdit.type = this.nonRepute ? PARAM_NON_HABITABLE : '';
                }
                break;
            case TypePrestationEnum.MESURAGE_UTILE:
                this.nonRepute = this._volume.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE];
                if (this.zoneToEdit) {
                    this.zoneToEdit.type = this.nonRepute ? PARAM_AUTRE : '';
                }
                break;
        }
    }

    @Output() mesureChangedFromUser = new EventEmitter<PointDeControleVolume>();

    _volume: PointDeControleVolume;
    // Mode edit d'une zone
    editZone = false;
    zoneToEdit: MesureZone;
    indexToEdit = -1;
    // Edition du nom de la zone
    editNameMode = false;
    originalName = '';
    nameAlreadyPresent = false;
    // Liste des zones du volume
    zones = [];
    listeMesuresLabels: string[];

    propertiesMesurage: PropertiesMesurage;
    nonRepute = false;
    tooltipsZone = [PARAM_TOOLTIP_ZONE_AJOUT, PARAM_TOOLTIP_ZONE_DEDUCTION];
    tooltipsTools = [PARAM_TOOLTIP_DEDUIRE, PARAM_TOOLTIP_CALCULTATRICE];

    constructor(private matDialog: MatDialog, private confirmationService: ConfirmationService, private notificationService: NotificationService) {
        super();
    }

    ngOnInit(): void {
        switch (this.typePrestation) {
            case TypePrestationEnum.MESURAGE:
                this.propertiesMesurage = PROPERTIES_MESURAGE_CARREZ;
                break;

            case TypePrestationEnum.MESURAGE_HABITABLE:
                this.propertiesMesurage = PROPERTIES_MESURAGE_HABITABLE;
                break;

            case TypePrestationEnum.MESURAGE_UTILE:
                this.propertiesMesurage = PROPERTIES_MESURAGE_UTILE;
                break;
        }
        // Labels du toggle
        this.listeMesuresLabels = this.propertiesMesurage.listeMesures.map((x) => x.replace(/_/g, ' '));

        // Liste des zones déjà présentes pour ce volume
        this.zones = (
            this._volume.valeursParametres[this.propertiesMesurage.listeMesuresZones[0]]
                ? this._volume.valeursParametres[this.propertiesMesurage.listeMesuresZones[0]]
                : []
        ).concat(
            this._volume.valeursParametres[this.propertiesMesurage.listeMesuresZones[1]]
                ? this._volume.valeursParametres[this.propertiesMesurage.listeMesuresZones[1]]
                : []
        );
        if (this.propertiesMesurage.isMesurageUtile) {
            this.zones = this.zones.concat(
                this._volume.valeursParametres[this.propertiesMesurage.listeMesuresZones[2]]
                    ? this._volume.valeursParametres[this.propertiesMesurage.listeMesuresZones[2]]
                    : []
            );
        }

        if (this.zone !== undefined) {
            // Mode édition d'une zone déjà existante
            this.editZone = true;
            this.zoneToEdit = { ...this.zone };
            this.originalName = this.zoneToEdit.nom;
            this.indexToEdit = this._volume.valeursParametres[this.zone.type + '_zones'].indexOf(this.zone);
        } else {
            // Création d'une zone
            this.editZone = false;
            switch (this.typePrestation) {
                case TypePrestationEnum.MESURAGE:
                    this.zoneToEdit = {
                        type: this.nonRepute ? PARAM_HORS_CARREZ : '',
                        nom: ListUtils.createUniqueName(
                            this.zones.map((r) => r.nom),
                            'Zone'
                        ),
                        surface: '',
                        mode: '',
                    };
                    break;
                case TypePrestationEnum.MESURAGE_HABITABLE:
                    this.zoneToEdit = {
                        type: this.nonRepute ? PARAM_NON_HABITABLE : '',
                        nom: ListUtils.createUniqueName(
                            this.zones.map((r) => r.nom),
                            'Zone'
                        ),
                        surface: '',
                        mode: '',
                    };
                    break;
                case TypePrestationEnum.MESURAGE_UTILE:
                    this.zoneToEdit = {
                        type: this.nonRepute ? PARAM_AUTRE : '',
                        nom: ListUtils.createUniqueName(
                            this.zones.map((r) => r.nom),
                            'Zone'
                        ),
                        surface: '',
                        mode: '',
                    };
                    break;
            }
        }
        // Vérification d'unicité de nom de zone
        this.isUniqueName();
        if (this.nameAlreadyPresent) {
            this.editNameMode = true;
        }
    }

    /**
     * Mode saisie
     */
    openNumKeyboard() {
        return this.matDialog
            .open(CalculatriceModaleComponent, {
                data: {
                    mode: 'saisie',
                    originalValue: this.zoneToEdit.surface,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.zoneToEdit.surface = result.resultat;
                }
            });
    }

    /**
     * Mode calculatrice
     */
    openCalculator() {
        return this.matDialog
            .open(CalculatriceModaleComponent, {
                data: {
                    mode: 'calculatrice',
                    originalValue: this.zoneToEdit.surface,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.zoneToEdit.surface = result.resultat;
                }
            });
    }

    /**
     * Edition du nom de la zone
     */
    editName() {
        this.editNameMode = true;
        this.originalName = this.zoneToEdit.nom;
    }

    /**
     * Confirmation après édition du nom de la zone
     */
    confirmName() {
        this.editNameMode = false;
        // Vérification d'unicité de nom de zone
        this.isUniqueName();
    }

    /**
     * Cancel après édition du nom de la zone
     */
    cancelName() {
        this.editNameMode = false;
        this.zoneToEdit.nom = this.originalName;
        // Vérification d'unicité de nom de zone
        this.isUniqueName();
    }

    /**
     * Vérification d'unicité de nom de zone
     */
    isUniqueName() {
        this.nameAlreadyPresent =
            this.zones.map((z) => z.nom.toLowerCase()).includes(this.zoneToEdit.nom.toLowerCase()) && this.zoneToEdit.nom !== this.originalName;
    }

    /**
     * La mesure est égale à zéro
     */
    setZero() {
        this.zoneToEdit.surface = '0';
    }

    /**
     * Suppression d'une zone
     */
    deleteZone() {
        this.confirmationService.confirmWarn('Êtes-vous sûr de vouloir supprimer cette zone ?', () => {
            this._volume.valeursParametres[this.zone.type + '_zones'].splice(this.indexToEdit, 1);
            // Calcul des totaux
            this.calculateTotal(this.propertiesMesurage.listeMesures);
            this.mesureChangedFromUser.emit(this._volume);
            this.editZone = false;
            this.notificationService.notify('La zone a bien été supprimée.');
        });
    }

    /**
     * Rajoute la surface à la liste déjà présente
     * Calcul de la surface totale de la liste
     */
    validateZone() {
        if (this.editZone) {
            this._volume.valeursParametres[this.zone.type + '_zones'].splice(this.indexToEdit, 1);
        }
        this._volume.valeursParametres[this.zoneToEdit.type + '_zones'] = this._volume.valeursParametres[this.zoneToEdit.type + '_zones']
            ? this._volume.valeursParametres[this.zoneToEdit.type + '_zones'].concat(this.zoneToEdit)
            : [].concat(this.zoneToEdit);
        // Calcul des totaux
        this.calculateTotal(this.propertiesMesurage.listeMesures);
        this.mesureChangedFromUser.emit(this._volume);
        this.editZone = false;
    }

    /**
     * Bouton validé enabled ou non
     */
    canValidate() {
        return !!(this.zoneToEdit.nom && this.zoneToEdit.type && this.zoneToEdit.surface && this.zoneToEdit.mode);
    }

    /**
     * Calcul le total de la liste
     */
    private calculateTotal(mesures: string[]) {
        mesures.forEach((m) => {
            let total = 0;
            const numOr0 = (n) => (isNaN(n) ? 0 : n);
            if (this._volume.valeursParametres[m + '_zones']) {
                this._volume.valeursParametres[m + '_zones'].forEach((z) => {
                    if (z.mode === 'add') {
                        total += numOr0(Number(z.surface));
                    } else {
                        total -= numOr0(z.surface);
                    }
                });
                this._volume.valeursParametres[m + '_zones_total'] = total.toString();
                this._volume.valeursParametres[m] = this._volume.valeursParametres[m + '_zones_total'];
            }
        });
    }
}
