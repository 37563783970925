import { Injectable } from '@angular/core';
import { MongoUtils } from 'src/app/commons-lib';
import { Bien, Niveau, Volume } from '../model/bien.model';
import { ListUtils } from '../utils/list.utils';
import { InterventionService } from './intervention.service';
import { CategorieOuvrage, OuvrageAControler, TypeIncrementation } from '../model/categorie-ouvrage.model';
import { ReferenceService } from './reference.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { cn_building, cn_element, extension_instance, zone_colors } from '@acenv/cnmap-editor';
import { TypeVolume } from '../model/type-element-a-controler.model';
import { extraireNomVolume } from '../utils/bien.utils';
import { Equipement } from '../model/equipement.model';
import { map } from 'rxjs/operators';
import { PARAM_VOLUME_LOT } from '../shared/constants/cndiag.constants';
import { isSameZone } from './sync-bien-plan.service';

export function isANumber(potentialNumber: number) {
    return potentialNumber !== null && !isNaN(potentialNumber);
}

@Injectable({
    providedIn: 'root',
})
export class DescriptionBienService {
    private volumeToMerge$ = new BehaviorSubject<Volume>(undefined);

    constructor(private readonly interventionService: InterventionService, private readonly referenceService: ReferenceService) {}

    getVolumeToMerge(): Observable<Volume> {
        return this.volumeToMerge$.asObservable();
    }

    getVolumeToMergeValue(): Volume {
        return this.volumeToMerge$.getValue();
    }

    setVolumeToMerge(volume: Volume) {
        this.volumeToMerge$.next(volume);
    }

    // NIVEAU

    createNiveauNom(listNiveaux: Niveau[]) {
        let nomNiveau;
        let index = listNiveaux.length;
        do {
            nomNiveau = !index ? 'RdC' : 'Niveau ' + index;
            index++;
        } while (listNiveaux.includes(nomNiveau.toLocaleUpperCase().trim()));
        return nomNiveau;
    }

    dupliquerNiveau(bienCible: Bien, niveauADupliquer: Niveau, typeVolumes: TypeVolume[]) {
        const newNiveau = { ...niveauADupliquer };
        newNiveau.id = MongoUtils.generateObjectId();
        newNiveau.nom = this.createNiveauNom(bienCible.description);
        newNiveau.index = bienCible.description.length;
        newNiveau.storeyId = null;
        newNiveau.volumes = [];
        niveauADupliquer.volumes.forEach((vol) => {
            this.dupliquerVolume(bienCible, newNiveau, vol, typeVolumes);
        });

        bienCible.description.push(newNiveau);
    }

    supprimerNiveau(bien: Bien, niveauASupprimer: Niveau) {
        bien.description.splice(
            bien.description.findIndex((it) => it.id === niveauASupprimer.id),
            1
        );
    }

    generateVolumeName(volumeToName: Volume, niveauOfVolume: Niveau) {
        if (volumeToName.volumeCache) {
            return 'Extérieur';
        }
        const genericName = `Espace ${niveauOfVolume.index ?? '?'}.${volumeToName.indexEspace ?? '?'}`;
        const hasMultiple = !!volumeToName.nomBase && niveauOfVolume.volumes.filter((it) => it.nomBase === volumeToName.nomBase).length > 1;
        const idx =
            hasMultiple && isANumber(volumeToName.numerotationDebut) && isANumber(volumeToName.numerotation)
                ? volumeToName.numerotationDebut + volumeToName.numerotation
                : undefined;
        const spaceName = idx !== undefined ? `${volumeToName.nomBase} ${volumeToName.prefixeNumerotation}${idx}` : volumeToName.nomBase;
        return spaceName ? `${genericName} (${spaceName})` : genericName;
    }

    dupliquerVolume(bien: Bien, niveauCible: Niveau, volumeADupliquer: Volume, typeVolumes: TypeVolume[]) {
        const newVolume = { ...volumeADupliquer };
        newVolume.id = MongoUtils.generateObjectId();

        const indexEspaceMax =
            niveauCible.volumes
                .filter((it) => isANumber(it.indexEspace))
                .map((volume) => volume.indexEspace)
                .reduce((a, b) => Math.max(a, b), 0) ?? 0;
        newVolume.indexEspace = indexEspaceMax + 1;
        const numerotationMax = niveauCible.volumes.map((volume) => volume.numerotation).reduce((a, b) => Math.max(a, b));
        newVolume.numerotation = numerotationMax + 1;
        newVolume.numerotationDebut = volumeADupliquer.numerotationDebut;
        newVolume.prefixeNumerotation = volumeADupliquer.prefixeNumerotation;
        newVolume.nomBase = volumeADupliquer.nomBase;

        newVolume.nom = this.generateVolumeName(newVolume, niveauCible);
        newVolume.spaceId = null;
        newVolume.usageId = volumeADupliquer.usageId;
        newVolume.commentairesId = [];
        newVolume.equipements = [];
        newVolume.ouvragesAControler = [];
        niveauCible.volumes.push(newVolume);
        volumeADupliquer.equipements.forEach((el) => {
            this.dupliquerElementAControler(newVolume, el);
        });
        volumeADupliquer.ouvragesAControler.forEach((ouv) => {
            this.dupliquerOuvrageAControler(ouv, { volume: volumeADupliquer, bien: bien });
        });
        newVolume.valeursParametres = {
            ...volumeADupliquer.valeursParametres,
        };
        niveauCible.volumes.forEach((vol) => (vol.nom = this.generateVolumeName(vol, niveauCible)));
    }

    supprimerVolume(niveau: Niveau, volumeASupprimer: Volume) {
        this.supprimerCommentairesFromVolume(volumeASupprimer);

        niveau.volumes.splice(
            niveau.volumes.findIndex((it) => it.id === volumeASupprimer.id),
            1
        );
        niveau.volumes.forEach((vol) => (vol.nom = this.generateVolumeName(vol, niveau)));
    }

    supprimerCommentairesFromVolume(volumeCible: Volume, typeCommentaire?: string[]) {
        const intervention = this.interventionService.getCurrentInterventionValue();

        intervention.commentaires
            .filter((it) => volumeCible.commentairesId.includes(it.id) && typeCommentaire?.some((type) => type === it.type))
            .forEach((it) => {
                intervention.commentaires.splice(intervention.commentaires.indexOf(it), 1);
                volumeCible.commentairesId.splice(volumeCible.commentairesId.indexOf(it.id), 1);
            });
    }

    // ELEMENT

    dupliquerElementAControler(volumeCible: Volume, elementADupliquer: Equipement) {
        const newElement = { ...elementADupliquer } as Equipement;
        newElement.id = MongoUtils.generateObjectId();
        newElement.name = ListUtils.createUniqueName(
            volumeCible.equipements.map((it) => it.name),
            elementADupliquer.name
        );
        volumeCible.equipements.push(newElement);
    }

    supprimerElementAControler(volume: Volume, elementASupprimer: Equipement) {
        volume.equipements.splice(
            volume.equipements.findIndex((it) => it.id === elementASupprimer.id),
            1
        );
    }

    // ELEMENT

    dupliquerOuvrageAControler(ouvrageAControler: OuvrageAControler, context: { volume: Volume; bien: Bien }) {
        this.referenceService.findAllCategoriesOuvrages().subscribe((categoriesOuvrages) => {
            const mapCategoriesOuvrages = new Map<string, CategorieOuvrage>();
            categoriesOuvrages.forEach((it) => {
                mapCategoriesOuvrages.set(it.code, it);
            });

            const newOuvrage = new OuvrageAControler();

            const listNoms = context.volume.ouvragesAControler
                .filter((it) => it.codeCategorieOuvrage === ouvrageAControler.codeCategorieOuvrage)
                .map((it) => it.nom);

            const categorieOuvrage = mapCategoriesOuvrages.get(ouvrageAControler.codeCategorieOuvrage);
            newOuvrage.id = MongoUtils.generateObjectId();
            newOuvrage.nom = this.generateNewNom(categorieOuvrage, context, categoriesOuvrages);

            newOuvrage.codeCategorieOuvrage = ouvrageAControler.codeCategorieOuvrage;
            newOuvrage.partiesOuvrages = [];
            ouvrageAControler.partiesOuvrages.forEach((it) => {
                const newPartieOuvrage = new OuvrageAControler();
                newPartieOuvrage.id = MongoUtils.generateObjectId();
                newPartieOuvrage.nom = it.nom;
                newPartieOuvrage.codeCategorieOuvrage = it.codeCategorieOuvrage;
                newPartieOuvrage.parametres = { ...it.parametres };
                newPartieOuvrage.codeRevetement = it.codeRevetement;
                newPartieOuvrage.codeSubstrat = it.codeSubstrat;
                newPartieOuvrage.valeurCouleur = it.valeurCouleur;
                newPartieOuvrage.valeurCaracteristiqueCouleur = it.valeurCaracteristiqueCouleur;
                newOuvrage.partiesOuvrages.push(newPartieOuvrage);
            });
            newOuvrage.parametres = { ...ouvrageAControler.parametres };
            newOuvrage.codeRevetement = ouvrageAControler.codeRevetement;
            newOuvrage.codeSubstrat = ouvrageAControler.codeSubstrat;
            newOuvrage.valeurCouleur = ouvrageAControler.valeurCouleur;
            newOuvrage.valeurCaracteristiqueCouleur = ouvrageAControler.valeurCaracteristiqueCouleur;
            context.volume.ouvragesAControler.push(newOuvrage);
        });
    }

    supprimerOuvrageAControler(volume: Volume, ouvrageAControler: OuvrageAControler) {
        volume.ouvragesAControler.splice(
            volume.ouvragesAControler.findIndex((it) => it.id === ouvrageAControler.id),
            1
        );
    }

    editVolumeInMap(bien: Bien, niveau: Niveau, volume: Volume): Observable<boolean> {
        if (bien.jsonPlan) {
            return this.referenceService.findAllTypesVolumes().pipe(
                map((typesVolumes) => {
                    const plan = JSON.parse(bien.jsonPlan);
                    const building = cn_building.unserialize(plan);
                    const storey = building?.find_storey(niveau.storeyId);
                    const space = storey?.scene?.get_space(volume.spaceId);
                    if (space) {
                        const listeTypeVolume = typesVolumes.find((it) => it.id === volume.usageId);
                        space.space_usage = listeTypeVolume?.codeBim;
                        space.name = volume.nomBase;
                        const newBuilding = building.serialize();
                        const valeurLot = volume.valeursParametres[PARAM_VOLUME_LOT];
                        if (valeurLot !== undefined && valeurLot !== null && valeurLot !== '') {
                            newBuilding.zones = newBuilding.zones || {};
                            newBuilding.zones.lot = newBuilding.zones.lot || [];
                            if (!newBuilding.zones.lot.some((it) => it.name === valeurLot)) {
                                newBuilding.zones.lot.push({
                                    ID: cn_element.generate_ID(),
                                    name: valeurLot,
                                    main_storey: niveau.storeyId,
                                    zone_type: '',
                                    rooms: [{ space: volume.spaceId, storey: niveau.storeyId }],
                                    color: zone_colors[newBuilding.zones.lot.length % zone_colors.length],
                                });
                            }
                        }

                        if (volume.nomBase) {
                            const extensionData = JSON.parse(newBuilding.extension_data);

                            const spacesNumerotation = extensionData.ac_env_spaces_numerotation ?? [];

                            const currentName = spacesNumerotation.find(
                                (it) => it.storey === niveau.storeyId && it.name === volume.nomBase && isSameZone(it.zone, valeurLot, bien)
                            );
                            if (!currentName) {
                                spacesNumerotation.push({
                                    name: volume.nomBase,
                                    storey: niveau.storeyId,
                                    zone: valeurLot,
                                    numerotation: volume.numerotationDebut,
                                    prefix: volume.prefixeNumerotation,
                                    values: [
                                        {
                                            id: volume.spaceId,
                                            index: volume.numerotationDebut - volume.numerotation,
                                        },
                                    ],
                                });
                            } else {
                                currentName.prefix = volume.prefixeNumerotation;
                                currentName.numerotation = volume.numerotationDebut;
                                const currentSpace = currentName.values.find((it) => it.id === volume.spaceId);
                                if (currentSpace) {
                                    currentSpace.index = volume.numerotation;
                                } else {
                                    currentName.values.push({
                                        id: volume.spaceId,
                                        index: volume.numerotation,
                                    });
                                }
                            }
                            // temp delete
                            extensionData.ac_env_spaces_numerotation = spacesNumerotation;
                            newBuilding.extension_data = JSON.stringify(extensionData);
                            if (extension_instance?.data?.ac_env_spaces_numerotation) {
                                extension_instance.data.ac_env_spaces_numerotation = spacesNumerotation;
                            }
                        }
                        bien.jsonPlan = JSON.stringify(newBuilding);
                        return true;
                    } else {
                        return false;
                    }
                })
            );
        }
        return of(false);
    }

    generateNewNom(categorieOuvrage: CategorieOuvrage, context: { volume: Volume; bien: Bien }, allCategoriesOuvrages: CategorieOuvrage[]): string {
        const conf = new Map<TypeIncrementation, RegExp>([
            [TypeIncrementation.CHIFFRE, /.* ([0-9])+$/],
            [TypeIncrementation.LETTRE, /.* ([A-Z])+$/],
        ]);
        const categorieForNumerotation = findCategorieForNumerotation(categorieOuvrage, allCategoriesOuvrages);
        let newNom = categorieOuvrage.nomOuvrage;
        if ([TypeIncrementation.LETTRE, TypeIncrementation.CHIFFRE].includes(categorieForNumerotation?.typeIncrementation)) {
            const incrementReference = categorieForNumerotation.incrementReference ?? 'ESPACE';
            const ouvrages = getOuvragesForCategorieAndReferenceLevel(
                categorieForNumerotation,
                incrementReference,
                context.bien,
                context.bien.description.find((niveau) => niveau.volumes.some((volume) => volume.id === context.volume.id)),
                context.volume,
                allCategoriesOuvrages
            );

            const regex = conf.get(categorieForNumerotation.typeIncrementation);
            const isLettre = categorieForNumerotation.typeIncrementation === TypeIncrementation.LETTRE;
            const last =
                ouvrages
                    .map((ouvrage) => regex.exec(ouvrage.nom))
                    .filter((match) => match?.length)
                    .map((m) => (isLettre ? customBase26ToDecimal(m[1]) : parseInt(m[1])))
                    .sort((a, b) => b - a)[0] ?? 0;
            const newIndex = last + 1;
            newNom = `${newNom} ${isLettre ? decimalToCustomBase26(newIndex) : newIndex}`;
        }

        return newNom;
    }
}

export function findCategorieForNumerotation(categorieOuvrage: CategorieOuvrage, allCategories: CategorieOuvrage[]) {
    const categoriesMap = new Map<string, CategorieOuvrage>();

    allCategories.forEach((obj) => {
        categoriesMap.set(obj.code, obj);
    });

    // Recursive function to check the current object and its parent
    function checkObject(currentCode: string): CategorieOuvrage | null {
        const obj = categoriesMap.get(currentCode);

        if (!obj) {
            return null;
        }

        if (obj.typeIncrementation && obj.typeIncrementation !== 'AUCUN') {
            return obj;
        }

        // Check the parent object
        if (obj.lienCodeParent) {
            return checkObject(obj.lienCodeParent);
        }
        return null;
    }

    return checkObject(categorieOuvrage.code);
}

export function getOuvragesForCategorieAndReferenceLevel(
    categorieParent: CategorieOuvrage,
    incrementReference: 'BIEN' | 'NIVEAU' | 'ESPACE',
    bien: Bien,
    niveau: Niveau,
    volume: Volume,
    allCategories: CategorieOuvrage[]
) {
    let ouvrages: OuvrageAControler[] = [];
    if (incrementReference === 'BIEN') {
        ouvrages = ouvrages.concat(bien.description.flatMap((niveau) => niveau.volumes).flatMap((volume) => volume.ouvragesAControler));
    } else if (incrementReference === 'NIVEAU') {
        ouvrages = ouvrages.concat(niveau.volumes.flatMap((volume) => volume.ouvragesAControler));
    } else {
        ouvrages = ouvrages.concat(volume.ouvragesAControler);
    }
    return ouvrages.filter((ouvrage) => isChildCategorieOf(allCategories, ouvrage.codeCategorieOuvrage, categorieParent));
}

export function isChildCategorieOf(allCategories: CategorieOuvrage[], codeCategorie: string, parentCategorieOuvrage: CategorieOuvrage): boolean {
    const categoriesMap = new Map<string, CategorieOuvrage>();

    allCategories.forEach((obj) => {
        categoriesMap.set(obj.code, obj);
    });

    function checkParentChain(currentCode: string): boolean {
        if (currentCode === parentCategorieOuvrage.code) {
            return true;
        }

        const obj = categoriesMap.get(currentCode);

        if (!obj) {
            return false;
        }

        if (obj.lienCodeParent === parentCategorieOuvrage.code) {
            return true;
        }

        if (obj.lienCodeParent) {
            return checkParentChain(obj.lienCodeParent);
        }

        return false;
    }

    return checkParentChain(codeCategorie);
}

function customBase26ToDecimal(s: string): number {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const base = letters.length;
    let result = 0;

    for (let i = 0; i < s.length; i++) {
        const value = letters.indexOf(s[i]) + 1;
        result = result * base + value;
    }

    return result;
}

function decimalToCustomBase26(num: number): string {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const base = letters.length;
    let result = '';

    while (num > 0) {
        const remainder = (num - 1) % base;
        result = letters[remainder] + result;
        num = Math.floor((num - 1) / base);
    }

    return result;
}
