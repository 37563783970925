<div class="equipement-container">
    <div class="h-100 row no-gutters p-4 scrollable-content">
        <mat-card class="d-flex w-100 p-2 mb-2">
            <mat-card-content class="w-100">
                <div class="d-flex justify-content-between w-100">
                    <div class="w-80 primary">Informations générales de la catégorie</div>
                    <div class="w-20">
                        <button
                            color="accent"
                            mat-raised-button
                            class="btn"
                            (click)="dupliquerCategorieEquipement(categorie?.id)"
                            [disabled]="categorie === undefined"
                        >
                            <lib-icon>content_copy</lib-icon>
                        </button>
                        <button
                            color="accent"
                            mat-raised-button
                            class="btn"
                            (click)="editerCategorieEquipement(categorie?.id)"
                            [disabled]="categorie === undefined"
                        >
                            <lib-icon>edit</lib-icon>
                        </button>
                        <button
                            color="warn"
                            mat-raised-button
                            class="btn"
                            (click)="supprimerCategorieEquipement(categorie?.id)"
                            [disabled]="categorie === undefined"
                        >
                            <lib-icon>delete</lib-icon>
                        </button>
                    </div>
                </div>

                <!-- État de l'equipement -->
                <div class="d-flex p-2 align-items-center">
                    <div class="col-xl-4 mat-subheading-2 m-0 primary">État</div>
                    <div class="statut-workflow consultation mb-3" [ngClass]="categorie?.etatEquipement">
                        {{ categorie?.etatEquipement | etatWorkflow }}
                    </div>
                </div>

                <!-- Nom de l'equipement -->
                <div class="d-flex p-2 align-items-center">
                    <div class="col-xl-4 mat-subheading-2 m-0 primary">Nom</div>
                    <div class="col-xl-8">
                        {{ categorie?.name }}
                    </div>
                </div>

                <!-- codeBim de l'equipement -->
                <div class="d-flex p-2 align-items-center">
                    <div class="col-xl-4 mat-subheading-2 m-0 primary">Code bim</div>
                    <div class="col-xl-8">
                        {{ categorie?.codeBim }}
                    </div>
                </div>

                <!-- Description de l'equipement  -->
                <div class="d-flex p-2 align-items-center">
                    <div class="col-xl-4 mat-subheading-2 m-0 primary">Description</div>
                    <div class="col-xl-8">
                        {{ categorie?.description }}
                    </div>
                </div>

                <!-- Catégorie parente-->
                <div class="d-flex p-2 align-items-center">
                    <div class="col-xl-4 mat-subheading-2 m-0 primary">Cat. parente</div>
                    <div class="col-xl-8">{{ nomCategorieParente() }}</div>
                </div>

                <!-- Prestation associées -->
                <div class="d-flex p-2 align-items-center">
                    <div class="col-xl-4 mat-subheading-2 m-0 primary">Types de prestation</div>
                    <div class="col-xl-8">{{ categorie?.typesPrestationsAssocie | concatNomsPrestations }}</div>
                </div>

                <!-- Abstrait ou peut être instancier pour créer un Equipement -->
                <div class="d-flex p-2 align-items-center">
                    <div class="col-xl-4 mat-subheading-2 m-0 primary">Création d'équipement</div>
                    <div class="col-xl-8">
                        <app-state-input
                            [choices]="[trueOption, falseOption]"
                            [tooltips]="toolTipOuiNon"
                            [(choiceValue)]="abstract"
                            [disabled]="true"
                        ></app-state-input>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <!------------ Pictogramme ------------->
        <!-- ATTENTION: les images des catégories (WorkType) n'existe plus, elles seront de retour dans une prochaine mise à jour
        <mat-card class="d-flex w-100 p-2 mb-2">
            <mat-card-content class="w-100">
                <div class="primary">Pictogramme</div>
                <div *ngIf="categorie?.imageUrl" class="pl-5">
                    <img [src]="categorie?.imageUrl" alt="Pictogramme de la catégorie" class="img-fluid" style="max-width: 100%; max-height: 60px" />
                </div>
            </mat-card-content>
        </mat-card>
        -->

        <!------------ définition paramètres ------------->
        <mat-card class="d-flex w-100 p-2 mb-2">
            <mat-card-content class="w-100">
                <div class="primary">Définition des paramètres</div>
                <div class="d-flex flex-column p-2 align-items-center">
                    <div *ngIf="categorie?.parametersDefinitions.length === 0" class="col-xl-11 mat-subheading-2 m-0 primary">
                        Pas de valeurs renseignées pour cette catégorie
                    </div>
                </div>

                <div *ngFor="let param of categorie?.parametersDefinitions">
                    <div>
                        <!-- Nom de l'equipement -->
                        <div class="d-flex p-2 align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary">Nom</div>
                            <div class="col-xl-8">
                                {{ param.name }}
                            </div>
                        </div>

                        <!-- codeBim de l'equipement -->
                        <div class="d-flex p-2 align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary">Code bim</div>
                            <div class="col-xl-8">
                                {{ param.codeBim }}
                            </div>
                        </div>
                    </div>

                    <div>
                        <!-- Description de l'equipement  -->
                        <div class="d-flex p-2 align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary">Description</div>
                            <div class="col-xl-8">
                                {{ param.description }}
                            </div>
                        </div>

                        <!-- choix  -->
                        <div class="d-flex p-2 align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary">Choix</div>
                            <div class="col-xl-8">
                                {{ param.choices.join(', ') }}
                            </div>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                </div>
            </mat-card-content>
        </mat-card>

        <!------------ Paramètres par défaut ------------->
        <mat-card class="d-flex w-100 p-2 mb-2">
            <mat-card-content class="w-100">
                <div class="primary">Valeurs des paramètres par défaut</div>
                <div class="d-flex flex-column p-2 align-items-center">
                    <div *ngIf="categorie?.valeursDefautParametres" class="col-xl-11 mat-subheading-2 m-0 primary">
                        Pas de valeurs renseignées pour cette catégorie
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
