import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/commons-lib';
import { URL_GESTION_TYPES_OUVRAGE_EDIT, URL_REFERENTIELS } from 'src/app/shared/constants/url.constants';

@Component({
    selector: 'app-gestion-types-ouvrage',
    templateUrl: './gestion-types-ouvrage.component.html',
    styleUrls: ['./gestion-types-ouvrage.component.scss'],
})
export class GestionTypesOuvrageComponent extends BaseComponent implements OnInit {
    constructor(private readonly router: Router) {
        super();
    }

    ngOnInit(): void {}

    creerTypesOuvrage() {
        this.router.navigate([URL_GESTION_TYPES_OUVRAGE_EDIT]);
    }

    back() {
        this.router.navigate([URL_REFERENTIELS]);
    }
}
