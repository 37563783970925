<lib-form-container [smallMode]="true">
    <ng-container *ngIf="user$ | async as user">
        <h1>Finalisation de l'inscription</h1>

        <mat-form-field class="w-100">
            <mat-label>Prénom</mat-label>
            <input matInput type="text" required [(ngModel)]="user.firstName" />
        </mat-form-field>

        <mat-form-field class="w-100">
            <mat-label>Nom</mat-label>
            <input matInput type="text" required [(ngModel)]="user.lastName" />
        </mat-form-field>

        <mat-form-field class="w-100">
            <mat-label>Société</mat-label>
            <input matInput type="text" [(ngModel)]="user.company" />
        </mat-form-field>

        <mat-form-field class="w-100">
            <mat-label>Adresse</mat-label>
            <input matInput type="text" [(ngModel)]="user.address" />
        </mat-form-field>

        <mat-form-field class="w-100">
            <mat-label>Numéro de téléphone</mat-label>
            <input matInput type="text" [(ngModel)]="user.phone" />
        </mat-form-field>

        <mat-form-field class="w-100">
            <mat-label>Mot de passe</mat-label>
            <input
                matInput
                type="password"
                required
                [(ngModel)]="newPassword"
                minlength="4"
                maxlength="100"
                #newPasswordField="ngModel"
            />
        </mat-form-field>

        <mat-form-field class="w-100">
            <mat-label>Confirmez le mot de passe</mat-label>
            <input matInput type="password" required [(ngModel)]="newPasswordConfirm" />
        </mat-form-field>

        <div class="text-center mt-3">
            <button
                mat-raised-button
                color="accent"
                [disabled]="
                    !resetKey ||
                    !newPassword ||
                    !newPasswordConfirm ||
                    !newPasswordField.valid ||
                    newPassword !== newPasswordConfirm ||
                    !user.firstName ||
                    !user.lastName
                "
                (click)="submit(user)"
            >
                <span>Terminer l'inscription</span>
            </button>
        </div>
    </ng-container>
</lib-form-container>
