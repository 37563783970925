// Codes bim pour les types d'éléments à contrôler

import { environment } from '../../../environments/environment';

/**
 * Liste des Code Bim /!\ Attention à la correspondance dans le backend
 */
export const CODE_BIM_TYPE_ELEMENT_EQUIPEMENTS = 'EQUIPEMENTS';
export const CODE_BIM_TYPE_ELEMENT_EQUIPEMENTS_SANITAIRES = 'EQUIPEMENTS_SANITAIRES';
export const CODE_BIM_TYPE_ELEMENT_EQUIPEMENTS_SANITAIRES_TOILETTES = 'TOILETTES';
export const CODE_BIM_TYPE_ELEMENT_EQUIPEMENTS_SANITAIRES_SALLE_DE_BAIN = 'SALLE_DE_BAIN';
export const CODE_BIM_TYPE_ELEMENT_RESEAUX_EVACUATION = 'RESEAUX_EVACUATION';
export const CODE_BIM_TYPE_ELEMENT_RESEAUX_EVACUATION_SIPHON_SOL = 'SIPHON_SOL';
export const CODE_BIM_TYPE_ELEMENT_RESEAUX_EVACUATION_SANIBROYEUR = 'SANIBROYEUR';
export const CODE_BIM_TYPE_ELEMENT_RESEAUX_EVACUATION_FOSSE_SEPTIQUE = 'FOSSE_SEPTIQUE';
export const CODE_BIM_TYPE_ELEMENT_RESEAUX_EVACUATION_ANCIENNE_FOSSE_SEPTIQUE = 'ANCIENNE_FOSSE_SEPTIQUE';
export const CODE_BIM_TYPE_ELEMENT_RESEAUX_EVACUATION_CLAPET_EU = 'CLAPET_ANTI_RETOUR_EU';
export const CODE_BIM_TYPE_ELEMENT_RESEAUX_EVACUATION_CLAPET_EP = 'CLAPET_ANTI_RETOUR_EP';
export const CODE_BIM_TYPE_ELEMENT_RESEAUX_EVACUATION_POMPE_EU = 'POMPE_EU';
export const CODE_BIM_TYPE_ELEMENT_RESEAUX_EVACUATION_POMPE_EP = 'POMPE_EP';
export const CODE_BIM_TYPE_ELEMENT_RESEAUX_EVACUATION_BAC_A_GRAISSE = 'BAC_A_GRAISSE';
export const CODE_BIM_TYPE_ELEMENT_RESEAUX_EVACUATION_REGARD_EU = 'REGARD_EU';
export const CODE_BIM_TYPE_ELEMENT_RESEAUX_EVACUATION_REGARD_EP = 'REGARD_EP';
export const CODE_BIM_TYPE_ELEMENT_RESEAUX_EVACUATION_REGARD_EI = 'REGARD_EI';
export const CODE_BIM_TYPE_ELEMENT_RESEAUX_EVACUATION_FILTRE_PISCINE = 'FILTRE_PISCINE';
export const CODE_BIM_TYPE_ELEMENT_PISCINE = 'PISCINE';
export const CODE_BIM_TYPE_ELEMENT_FONTAINE = 'FONTAINE';
export const CODE_BIM_TYPE_ELEMENT_RESEAUX_EVACUATION_RECUPERATEUR_EAU_PLUIE = 'RECUPERATEUR_EAU_PLUIE';
export const CODE_BIM_TYPE_ELEMENT_RESEAUX_EVACUATION_PUISARD = 'PUISARD';
export const CODE_BIM_TYPE_ELEMENT_ELECTROMENAGER_MULTIMEDIA = 'ELECTROMENAGER_MULTIMEDIA';
export const CODE_BIM_TYPE_ELEMENT_ELECTROMENAGER_MULTIMEDIA_CUISINE = 'CUISINE';
export const CODE_BIM_TYPE_ELEMENT_ELECTROMENAGER_MULTIMEDIA_BUANDERIE = 'BUANDERIE';
export const CODE_BIM_TYPE_ELEMENT_CVC_ECS = 'CVC-ECS';
export const CODE_BIM_TYPE_ELEMENT_CVC_ECS_CHAUFFAGE = 'CHAUFFAGE';
export const CODE_BIM_TYPE_ELEMENT_CVC_ECS_CHAUFFAGE_SYSTEME_PRODUCTION_ENERGIE = 'SYSTEME_PRODUCTION_ENERGIE';
export const CODE_BIM_TYPE_ELEMENT_CVC_ECS_CHAUFFAGE_SYSTEME_PRODUCTION_ENERGIE_CHAUDIERE = 'CHAUDIERE';
export const CODE_BIM_TYPE_ELEMENT_CVC_ECS_CHAUFFAGE_SYSTEME_PRODUCTION_ENERGIE_CHAUDIERE_FIOUL = 'CHAUDIERE_FIOUL';
export const CODE_BIM_TYPE_ELEMENT_CVC_ECS_CHAUFFAGE_SYSTEME_PRODUCTION_ENERGIE_CHAUDIERE_GAZ = 'CHAUDIERE_GAZ';
export const CODE_BIM_TYPE_ELEMENT_CVC_ECS_CHAUFFAGE_SYSTEME_EMISSION = 'SYSTEME_EMISSION';
export const CODE_BIM_TYPE_ELEMENT_CVC_ECS_CHAUFFAGE_SYSTEME_EMISSION_RADIATEUR = 'RADIATEUR';
export const CODE_BIM_TYPE_ELEMENT_CVC_ECS_CHAUFFAGE_PRODUCTION_ECS = 'PRODUCTION_ECS';
export const CODE_BIM_TYPE_ELEMENT_CVC_ECS_CHAUFFAGE_PRODUCTION_ECS_BALLON_EAU_CHAUDE = 'BALLON_EAU_CHAUDE';
export const CODE_BIM_TYPE_ELEMENT_CVC_ECS_VENTILATION = 'VENTILATION';
export const CODE_BIM_TYPE_ELEMENT_CVC_ECS_VENTILATION_EQUIPEMENTS_VENTILATION = 'EQUIPEMENTS_VENTILATION';
export const CODE_BIM_TYPE_ELEMENT_SECURITE = 'SECURITE';
export const CODE_BIM_TYPE_ELEMENT_SECURITE_INCENDIE = 'INCENDIE';
export const CODE_BIM_TYPE_ELEMENT_SECURITE_INCENDIE_EQUIPEMENT_SECURITE_INCENDIE = 'EQUIPEMENTS_SECURITE_INCENDIE';
export const CODE_BIM_TYPE_ELEMENT_SECURITE_ACCES_BATIMENT = 'ACCES_BATIMENT';
export const CODE_BIM_TYPE_ELEMENT_SECURITE_ACCES_BATIMENT_SECURITE_DES_PERSONNES_ET_CONTROLE_ACCES = 'SECURITE_PERSONNES_CONTROLE_ACCES';
export const CODE_BIM_TYPE_ELEMENT_SECURITE_ACCES_BATIMENT_SYSTEME_ECLAIRAGE_DE_SECURITE = 'SYSTEME_ECLAIRAGE_SECURITE';
export const CODE_BIM_TYPE_ELEMENT_ELECTRICITE = 'ELECTRICITE';
export const CODE_BIM_TYPE_ELEMENT_ELECTRICITE_LUMINAIRES = 'LUMINAIRE';
export const CODE_BIM_TYPE_ELEMENT_ELECTRICITE_LUMINAIRES_ECLAIRAGE = 'ECLAIRAGE';
export const CODE_BIM_TYPE_ELEMENT_ELECTRICITE_LUMINAIRES_ECLAIRAGE_ECLAIRAGE_EXTERIEUR = 'ECLAIRAGE_EXTERIEUR';
export const CODE_BIM_TYPE_ELEMENT_ELECTRICITE_APPAREILLAGE_ELECTRIQUE = 'APPAREILLAGE_ELECTRIQUE';
export const CODE_BIM_TYPE_ELEMENT_ELECTRICITE_APPAREILLAGE_ELECTRIQUE_PRISES_ELECTRIQUES = 'PRISES_ELECTRIQUES';

// Codes bim et valeurs pour les paramètres d'espaces
export const CODE_BIM_PARAM_ESPACE_HUMIDE = 'HUMIDE';
export const CODE_BIM_PARAM_ESPACE_CHAUFFE = 'CHAUFFE';
export const CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ = 'NON_REPUTE_CARREZ';
export const CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE = 'NON_REPUTE_HABITABLE';
export const CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE = 'NON_REPUTE_UTILE';

// Codes bim et valeurs pour les paramètres de types d'élément à contrôler
/* Code bim Electricite POUR LE MOMENT AUCUN CODE BIM, RESTE A DEFINIR*/
export const CODE_BIM_PARAM_CATEGORIE_ELECTRICITE = 'ELECTRICITE';
export const CODE_BIM_PARAM_TYPE_ELEMENT_DIFFERENTIEL_ELECTRICITE = 'DIFFERENTIEL';
export const CODE_BIM_PARAM_SENSIBILITE_DIFFERENTIEL_ELECTRICITE = 'SENSIBILITE_DIFFERENTIEL';
export const CODE_BIM_PARAM_TYPE_ELEMENT_DIFFERENTIEL_ELECTRICITE_30MA = '30MA';
export const CODE_BIM_PARAM_TYPE_ELEMENT_DIFFERENTIEL_ELECTRICITE_10MA = '10MA';
export const CODE_BIM_PARAM_TYPE_ELEMENT_TABLEAU_ELECTRICITE = 'TABLEAU_ELECTRIQUE';
export const CODE_BIM_PARAM_TYPE_ELEMENT_TABLEAU_ELECTRICITE_VALEUR = 'TABLEAU_ELECTRIQUE';
export const CODE_BIM_PARAM_CHAUDIERE = 'CHAUDIERE';
export const CODE_BIM_PARAM_CHAUDIERE_SOL = 'CHAUDIERE_SOL';
export const CODE_BIM_PARAM_CHAUDIERE_MURAL = 'CHAUDIERE_MURAL';

/** Code bim du paramètre de type d'élement à contrôler "Réseau d'évacuation" */
export const CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION = 'RESEAU_EVACUATION';
export const CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION_VALEUR_EM = 'EM';
export const CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION_VALEUR_EU = 'EU';
export const CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION_VALEUR_EP = 'EP';
export const CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION_VALEUR_EV = 'EV';
export const CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION_VALEUR_SECHES = 'TOILETTES_SECHES';

/** Catégorie de l'élément à contrôler */
export const CODE_BIM_PARAM_CATEGORIE = 'CATEGORIE';
export const CODE_BIM_PARAM_CATEGORIE_EQUIPEMENT = 'EQUIPEMENT';
export const CODE_BIM_PARAM_CATEGORIE_RESEAU = 'RESEAU';
export const CODE_BIM_PARAM_CATEGORIE_AUTRE = 'AUTRE';

/** Volume visité */
export const PARAM_VOLUME_VISITE = 'visite';

/** Numéro de lot */
export const PARAM_VOLUME_LOT = 'lot';

/**
 * TOOLTIPS
 */
export const PARAM_TOOLTIP_PRESENT = 'Présent';
export const PARAM_TOOLTIP_ABSENT = 'Absent';

export const PARAM_TOOLTIP_OUI = 'Oui';
export const PARAM_TOOLTIP_NON = 'Non';

export const PARAM_TOOLTIP_VISITEE = 'Visitée';
export const PARAM_TOOLTIP_PARTIELLEMENT_VISITEE = 'Partiellement visitée';
export const PARAM_TOOLTIP_NON_VISITEE = 'Non visitée';

export const PARAM_TOOLTIP_PARTIELLEMENT_ACCESSIBLE = 'Partiellement accessible';

export const PARAM_TOOLTIP_ODEUR_PRESENTE = 'Odeur présente';
export const PARAM_TOOLTIP_ODEUR_ABSENTE = 'Odeur absente';

export const PARAM_TOOLTIP_ETAT_BON = 'Bon';
export const PARAM_TOOLTIP_ETAT_MOYEN = 'Moyen';
export const PARAM_TOOLTIP_ETAT_MAUVAIS = 'Mauvais';

export const PARAM_TOOLTIP_NON_VISIBLE = 'Non visible';

export const PARAM_TOOLTIP_CONTROLE_VISUEL = 'Visuel';
export const PARAM_TOOLTIP_CONTROLE_COLORANT = 'Colorant';
export const PARAM_TOOLTIP_CONTROLE_FUMIGENE = 'Fumigène';

export const PARAM_TOOLTIP_ZONE_AJOUT = 'Ajouter la zone';
export const PARAM_TOOLTIP_ZONE_DEDUCTION = 'Déduire la zone';

export const PARAM_TOOLTIP_DEDUIRE = 'Calculer';
export const PARAM_TOOLTIP_CALCULTATRICE = 'Calculatrice';
export const PARAM_TOOLTIP_REINITIALISER = 'Ré-initialiser';
export const PARAM_TOOLTIP_SET0 = 'Zero';

export const PARAM_TOOLTIP_AJOUTE = 'Ajouter';
export const PARAM_TOOLTIP_RETIRE = 'Retirer';

/** FORMAT DE DATE */
export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_FORMAT_FRANCAIS_HH_MM_MOMENT = 'DD/MM/yyyy HH:mm';
export const DATE_FORMAT_FRANCAIS_HH_MM = 'dd/MM/yyyy HH:mm';
export const DATE_FORMAT_FRANCAIS_SANS_HEURE_MOMENT = 'DD/MM/YYYY';
export const DATE_FORMAT_FRANCAIS_SANS_HEURE = 'dd/MM/yyyy';
export const DATE_FORMAT_INTERNATIONAL_HH_MM = 'YYYY-MM-DD HH:mm';
export const DATE_FORMAT_INTERNATIONAL = 'YYYY-MM-DD';

/** MOIS */
export const MONTHS = [
    'Avant',
    'Après',
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre',
];

/** REGEX */
export const REGEX_EMAIL = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
export const REGEX_TELEPHONE = '^[0-9]{0,14}$';

export const URL_MS_REPORT_FILE = `s3://${environment.defaultBucket}/`;

export const CONTACT_AC_ENVIRONNEMENT = 'AC Environnement';

export const HTTP_STATUS_CONFLICT = 409;

export enum VOLUME_VISITE {
    OK = 'ok',
    KO = 'ko',
    WARNING = 'warning',
}
