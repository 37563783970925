import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { BaseComponent, ConfirmationService } from 'src/app/commons-lib';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, map } from 'rxjs/operators';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { INITIAL_PAGE_SIZE, SESSION_STORAGE_KEY_COFRAC, DEBOUNCE_TIME_VALUE } from 'src/app/shared/constants/admin.constants';
import { URL_GESTION_COFRACS_EDIT, URL_GESTION_COFRACS_CONSULTER } from 'src/app/shared/constants/url.constants';
import { PaginatedDataSource } from 'src/app/shared/paging/page';
import { Sort } from '@angular/material/sort';
import { SessionStoragePaginationUtils } from 'src/app/utils/session-storage-pagination.utils';
import { DEFAULT_SORT } from '../../gestion-agences/tableau-agences/tableau-agences.component';
import { Cofrac, CofracAdmin } from 'src/app/model/cofrac.model';
import { CofracApiService } from 'src/app/services/cofrac-api.service';

@Component({
    selector: 'app-tableau-cofrac',
    templateUrl: './tableau-cofrac.component.html',
    styleUrls: ['./tableau-cofrac.component.scss'],
})
export class TableauCofracComponent extends BaseComponent implements OnInit {
    @Input()
    initialPageSize = INITIAL_PAGE_SIZE;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayedColumns: string[] = ['nom', 'numero', 'dateDebutValidite', 'dateEcheance', 'typePrestationsString', 'actions'];

    dataSource: PaginatedDataSource<CofracAdmin>;
    sort: Sort;
    searchValue = SessionStoragePaginationUtils.getInitialReseach(SESSION_STORAGE_KEY_COFRAC);
    private search = new BehaviorSubject(this.searchValue);

    constructor(
        private readonly cofracApiService: CofracApiService,
        private readonly router: Router,
        private readonly cnSpinnerService: CnSpinnerService
    ) {
        super();
        this.sort = SessionStoragePaginationUtils.getInitialSort(SESSION_STORAGE_KEY_COFRAC, DEFAULT_SORT);
    }

    ngOnInit(): void {
        this.search.pipe(debounceTime(DEBOUNCE_TIME_VALUE), distinctUntilChanged(), takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.dataSource.fetch(0, this.dataSource.size);
        });
        this.dataSource = new PaginatedDataSource<CofracAdmin>(
            (pageRequest) => {
                return this.cnSpinnerService.withSpinner(
                    this.cofracApiService.searchCofracs(this.search.getValue(), pageRequest).pipe(
                        map((response) => {
                            response.content.map((cofrac) => {
                                return this.prepareCofrac(cofrac);
                            });
                            return response;
                        })
                    )
                );
            },
            SessionStoragePaginationUtils.getInitialSort(SESSION_STORAGE_KEY_COFRAC, DEFAULT_SORT),
            SessionStoragePaginationUtils.getInitialPageSize(SESSION_STORAGE_KEY_COFRAC, this.initialPageSize)
        );
    }

    /**
     * Action déclenchée lors du click sur le bouton Dupliquer Cofrac
     * @param cofrac
     */
    onClickDupliquerCofrac(cofrac: Cofrac) {
        this.setSessionStorageItem();
        this.redirectDupliquer(cofrac);
    }

    private redirectDupliquer(cofrac?: any) {
        if (cofrac) {
            this.router.navigate([URL_GESTION_COFRACS_EDIT, cofrac.id, 'duplicate']);
        }
    }

    /**
     * Action déclenchée lors du click sur le bouton "Editer"
     * @param cofrac
     */
    onClickEditerCofrac(cofrac: Cofrac) {
        this.setSessionStorageItem();
        this.redirectEditer(cofrac);
    }

    private redirectEditer(cofrac?: any) {
        if (cofrac) {
            this.setSessionStorageItem();
            this.router.navigate([URL_GESTION_COFRACS_EDIT, cofrac.id]);
        }
    }

    /**
     * Action déclenchée lors du click sur le bouton "Consulter"
     * @param cofrac
     */
    onClickConsulter(cofrac: Cofrac) {
        this.setSessionStorageItem();
        this.router.navigate([URL_GESTION_COFRACS_CONSULTER, cofrac.id]);
    }

    /**
     * Action déclenchée lors de la saisie d'un caractère dans la barre de recherche
     * @param $event
     */
    onKeyupSearchCofracs($event: any) {
        this.search.next($event.target.value);
        this.setSessionStorageItem();
    }

    /**
     * Action déclenchée lors d'un tri dans le tableau
     * @param $event
     */
    onChangeSortCofracs($event: Sort) {
        this.sort = $event;
        this.dataSource.sortBy($event);
        this.setSessionStorageItem();
    }

    /**
     * Set les valeurs du tableau dans le session storage (pagination, recherche, tri ..)
     * @private
     */
    private setSessionStorageItem() {
        SessionStoragePaginationUtils.setSessionStorageItem(SESSION_STORAGE_KEY_COFRAC, this.dataSource.size, this.search.getValue(), this.sort);
    }

    /**
     * Création d'un champs pour afficher les types de prestation du cofrac
     * @param cofrac
     * @returns
     */
    private prepareCofrac(cofrac: CofracAdmin): CofracAdmin {
        cofrac.typePrestations.forEach((typePrestation) => {
            if (cofrac.typePrestationsString && cofrac.typePrestationsString.length > 0) {
                cofrac.typePrestationsString += '; ' + typePrestation;
            } else {
                cofrac.typePrestationsString = typePrestation;
            }
        });

        return cofrac;
    }
}
