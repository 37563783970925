import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../utils/base.component';
import { NotificationService } from '../../notification/notification.service';
import { ConfirmationService } from '../../confirmation/confirmation.service';
import { ROLE_USER, User } from '../../authentication/user.model';
import { UserManagementService } from '../user-management.service';

@Component({
    selector: 'lib-user-detail',
    templateUrl: './user-detail.component.html',
    styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent extends BaseComponent implements OnInit, OnDestroy {
    /** Classe CSS sur le conteneur principal */
    @Input() containerClass = '';

    /** Si les sociétés sont gérées ailleurs, permet de passer les ids des utilisateur membres par nom de chaque société */
    @Input() userIdsByCompaniesNames: Map<string, string[]> = null;

    /** Si les sociétés sont gérées ailleurs, on valorise ici le nom des sociétés de l'utilisateur pour affichage  */
    userCompany: string;

    /** Liste des rôles possibles */
    authorities$: Observable<string[]>;

    /** Utilisateur courant */
    user: User = {};

    /** Mode création ? */
    creationMode: boolean;

    constructor(
        private userManagementService: UserManagementService,
        private notificationService: NotificationService,
        private confirmationService: ConfirmationService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        super();
    }

    ngOnInit() {
        this.route.paramMap
            .pipe(
                switchMap((params: ParamMap) => {
                    const userEmail = params.get('userEmail');
                    if (userEmail == 'new') {
                        this.creationMode = true;
                        const newUser = new User();
                        newUser.authorities = [ROLE_USER];
                        return of(newUser);
                    } else {
                        return this.userManagementService.find(userEmail);
                    }
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe((user) => {
                this.user = user;
                if (this.userIdsByCompaniesNames != null) {
                    const userCompanies = [];
                    this.userIdsByCompaniesNames.forEach((userIds, companyName) => {
                        if (userIds.includes(user.id)) {
                            userCompanies.push(companyName);
                        }
                    });
                    this.userCompany = userCompanies.sort().join(', ');
                }
            });

        this.authorities$ = this.userManagementService.authorities();
    }

    save() {
        if (this.creationMode) {
            this.userManagementService.create(this.user).subscribe(() => {
                this.notificationService.success("L'utilisateur a bien été créé");
                this.back();
            });
        } else {
            this.userManagementService.update(this.user).subscribe(() => {
                this.notificationService.success("L'utilisateur a bien été mis à jour");
                this.back();
            });
        }
    }

    back() {
        this.router.navigate([`/admin/users`]);
    }
}
