import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MpcaGeneralOld } from '../../../../../model/mpca.model.old';
import { ListeDeroulante } from '../../../../../model/polluant-config.model';
import { debounceTime } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MpcaMaterialSelectionModalComponent } from '../mpca-material-selection-modal/mpca-material-selection-modal.component';
import { Diagnostic } from 'src/app/model/diagnostic.model';

@Component({
    selector: 'app-mpca-form-general',
    templateUrl: './mpca-form-general.component.html',
    styleUrls: ['./mpca-form-general.component.scss'],
})
export class MpcaFormGeneralComponent implements OnInit, AfterViewInit {
    constructor(private readonly dialog: MatDialog) {}

    @Output()
    generalDataChange = new EventEmitter<MpcaGeneralOld>();

    @Input() // Interface pas encore retournée par l'API, valeur à vide
    materiauxConfig: any;

    @Input()
    diagnostic: Diagnostic;

    @Input()
    set etatDegradationConfig(etatsDegradation: ListeDeroulante) {
        const noms = ['Liste A', 'Liste B', 'Liste C', 'Autres'];
        noms.forEach((key) => this.etatsDegradation.push({ title: key, values: [] }));
        for (const item of etatsDegradation?.items) {
            if (item.startsWith('Score')) {
                this.etatsDegradation[0].values.push(item);
            } else if (['EP', 'AC2', 'AC1'].includes(item)) {
                this.etatsDegradation[1].values.push(item);
            } else if (['Bon état', 'Dégradé partiellement', 'Dégradé'].includes(item)) {
                this.etatsDegradation[2].values.push(item);
            } else {
                this.etatsDegradation[3].values.push(item);
            }
        }
    }
    etatsDegradation: { title: string; values: string[] }[] = [];

    @Input() // Interface pas encore retournée par l'API, valeur à vide
    typeAmianteConfig: any;

    @Input()
    formGeneral: FormGroup | AbstractControl;

    @Input() // Utilisé pour passer les champs en read-only lors de la visite de chantier
    disabled: Boolean;

    ngOnInit() {
        this.formGeneral
            .get('etatDegradation')
            .valueChanges.pipe(debounceTime(500))
            .subscribe((value) => {
                if (value === 'Retiré') {
                    this.generalDataChange.emit();
                }
            });
    }

    ngAfterViewInit() {}

    selectMaterial = (material = undefined) => {
        const dialogRef = this.dialog.open(MpcaMaterialSelectionModalComponent, {
            width: '250px',
            data: {
                diagnostic: this.diagnostic,
                materiauxConfig: this.materiauxConfig,
                material: material,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.materiaux) {
                this.formGeneral.patchValue({
                    materiaux: result.materiaux,
                    materiauxClient: result.materiaux.nom,
                });
            }
        });
    };

    public colorByItem(item: string, selected: boolean) {
        if (selected) {
            switch (item) {
                case 'Retiré':
                    return 'green';
                case 'Score 3':
                case 'AC2':
                case 'Dégradé':
                    return 'red';
                case 'Non renseigné':
                    return 'grey';
                case 'Score 1':
                case 'EP':
                case 'Bon état':
                    return 'yellow';
                case 'Score 2':
                case 'AC1':
                case 'Dégradé partiellement':
                case 'Encapsulé':
                    return 'orange';
            }
        } else {
            switch (item) {
                case 'Retiré':
                    return 'green-unselected';
                case 'Score 3':
                case 'AC2':
                case 'Dégradé':
                    return 'red-unselected';
                case 'Non renseigné':
                    return 'grey-unselected';
                case 'Score 1':
                case 'EP':
                case 'Bon état':
                    return 'yellow-unselected';
                case 'Score 2':
                case 'AC1':
                case 'Dégradé partiellement':
                case 'Encapsulé':
                    return 'orange-unselected';
            }
        }
        return null;
    }
}
