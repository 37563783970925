import { MongoUtils } from 'src/app/commons-lib';
import { PrelevementGenerique } from './prelevement-generique.model';

export class Sachet {
    id: string = MongoUtils.generateObjectId();
    reference?: string;
    numero?: number;
    prelevements: PrelevementGenerique[] = [];
    refIntervention: string;

    constructor() {}
}
