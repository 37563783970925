<div class="flex-grow-1 main-background">
    <mat-sidenav-container class="sidenav-container">
        <mat-sidenav mode="side" opened [disableClose]="true" [fixedTopGap]="115" fixedInViewport="true">
            <mat-nav-list>
                <a
                    *ngFor="let navItem of navItems"
                    #routerLinkActiveInstance="routerLinkActive"
                    [ngClass]="{
                        'elec-item-selected': routerLinkActiveInstance.isActive,
                        'tab-invalid': navItem.etat === 'INVALID'
                    }"
                    mat-list-item
                    routerLinkActive
                    [routerLink]="[navItem.route]"
                >
                    <div class="d-flex mat-tab-link-content">
                        <ng-container *ngIf="navItem.etat === 'INVALID'">
                            <lib-icon>priority_high</lib-icon>
                        </ng-container>
                        <span>{{ navItem.name }}</span>
                    </div>
                </a>
            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content style="min-height: 85vh" class="fullscreen-content">
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
