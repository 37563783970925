import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Agence } from '../model/agence.model';
import { extractPage, PageRequest, toHttpParams } from '../shared/paging/page';
import { Document } from '../model/document.model';
import { FileService } from './file.service';

/**
 * Service d'appel aux APIs pour les agences.
 * Ne pas appeler directement depuis les composants des pages, uniquement depuis d'autres services.
 * NB : On utilise HttpBackend au lieu de HttpClient ici, pour ne pas passer par les intercepteurs (donc on bypasse l'intercepteur).
 *      Le service appelant doit donc gérer lui-même les erreurs HTTP.
 */
@Injectable({
    providedIn: 'root',
})
export class AgenceApiService {
    private http: HttpClient;

    public resourceUrl = environment.apiUrl + '/agence';

    constructor(private readonly httpBackend: HttpBackend, private readonly fileService: FileService) {
        this.http = new HttpClient(httpBackend);
    }

    findOne(id: string): Observable<Agence> {
        return this.http.get<Agence>(`${this.resourceUrl}/${id}`);
    }

    findAllAgencesMinimales(): Observable<Agence[]> {
        return this.http.get<Agence[]>(`${this.resourceUrl}/findAllAgencesMinimales`);
    }

    searchAgences(search: string, pageRequest: PageRequest<Agence>) {
        return extractPage(
            this.http.get<Agence[]>(`${this.resourceUrl}`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), search },
            })
        );
    }

    /**
     * Créer une agence
     * @param agence
     */
    createAgence(agence: Agence): Observable<Agence> {
        return this.http.post<Agence>(this.resourceUrl, agence);
    }

    /**
     * Mettre à jour une agence
     * @param agence
     */
    updateAgenceOnline(agence: Agence): Observable<Agence> {
        return this.http.put<Agence>(this.resourceUrl, agence);
    }

    findAllAgencesByName(search: string, pageRequest: PageRequest<Agence>) {
        return extractPage(
            this.http.get<Agence[]>(`${this.resourceUrl}/find-all-agences-searched`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), search },
            })
        );
    }
}
