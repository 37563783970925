import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { InterventionService } from '../../../services/intervention.service';
import { Intervention } from '../../../model/intervention.model';
import { CnSpinnerService } from '../../shared/cn-spinner/service/cn-spinner.service';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DiagnosticService } from '../../../services/diagnostic.service';
import { Document, DocumentPreRapport } from '../../../model/document.model';
import { Diagnostic } from '../../../model/diagnostic.model';
import { RulesService } from 'src/app/services/rules.service';
import { addOption, removeOption } from '../../../shared/constants/states.constants';
import { PARAM_TOOLTIP_AJOUTE, PARAM_TOOLTIP_RETIRE } from '../../../shared/constants/cndiag.constants';
import { DocumentFileService } from '../../../services/document-file.service';
import { EtatProgressionService } from '../../../services/etat-progression.service';
import { ActivatedRoute } from '@angular/router';
import { DocumentsService } from '../../../services/documents.service';

@Component({
    selector: 'app-documents-rapport',
    templateUrl: './documents-rapport.component.html',
    styleUrls: ['./documents-rapport.component.scss'],
})
export class DocumentsRapportComponent extends BaseComponent implements OnInit {
    addOption = addOption;
    removeOption = removeOption;
    tooltipOnRapport = [PARAM_TOOLTIP_AJOUTE, PARAM_TOOLTIP_RETIRE];
    displayedColumns = ['docInfo', 'view', 'annexe', 'rapport'];

    documentsDiagnostic: (Document & DocumentPreRapport)[];
    readonlyMode = false;

    private currentIntervention: Intervention;
    private currentDiagnostic: Diagnostic;

    constructor(
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly interventionService: InterventionService,
        private readonly diagnosticService: DiagnosticService,
        private readonly documentFileService: DocumentFileService,
        private readonly etatProgressionService: EtatProgressionService,
        private readonly route: ActivatedRoute,
        private readonly documentService: DocumentsService
    ) {
        super();
    }

    ngOnInit(): void {
        this.cnSpinnerService
            .withSpinner(combineLatest([this.interventionService.getCurrentIntervention(), this.diagnosticService.getCurrentDiagnostic()]))
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([intervention, diagnostic]) => {
                this.currentIntervention = intervention;
                this.currentDiagnostic = diagnostic;
                this.readonlyMode = this.diagnosticService.isReadOnlyMode(this.currentIntervention, this.currentDiagnostic);

                if (!this.currentDiagnostic.documentsRapport) {
                    this.currentDiagnostic.documentsRapport = [];
                }

                this.documentsDiagnostic = DocumentsService.buildDocumentsAfficheRapport(
                    this.documentService.getDocumentsInformation(this.currentIntervention, this.currentDiagnostic),
                    this.currentDiagnostic,
                    !this.readonlyMode
                );
                // sync
                this.currentDiagnostic.documentsRapport = this.documentsDiagnostic.filter((doc) => doc.afficherDansRapport).map((doc) => doc.id);
                this.currentDiagnostic.documentsListRapport = this.documentsDiagnostic
                    .filter((doc) => doc.afficherDansListeDocuments)
                    .map((doc) => doc.id);
                this.checkValidity();
            });
    }

    documentIsOnRapportChanged() {
        this.currentDiagnostic.documentsRapport = this.documentsDiagnostic.filter((doc) => doc.afficherDansRapport).map((doc) => doc.id);
    }

    documentIsOnListChanged() {
        this.currentDiagnostic.documentsListRapport = this.documentsDiagnostic.filter((doc) => doc.afficherDansListeDocuments).map((doc) => doc.id);
    }

    onClickShowDocument(document: Document) {
        this.documentFileService.downloadFile(this.currentIntervention.id, document);
    }

    private checkValidity() {
        const code = this.route.snapshot.data['code'];
        this.etatProgressionService.updateDiagnostic(code, 'VOID', this.currentDiagnostic);
    }
}
