/**
 * ScreenshotSvg
 *
 * @title Capture de plan
 * @description Contient toutes les informations d'une capture de plan
 */
export class ScreenshotSvg {
    /**
     * Identifiant
     */
    id: string;
    /**
     * Nom du plan
     */
    nom: string;
    /**
     * Id du storey si plan associé
     */
    storeyId: string;
    /**
     * plan issu de l'éditeur de plan de repérage
     */
    jsonPlan: string;
    configurationSvg: string;
    cameraPosition: number[];
    autoGenerated = false;
}

export class LegendScreenshot {
    nomCategorie: string;
    items: LegendScreenshotItem[];
}

export class LegendScreenshotItem {
    nom: string;
    couleur: string;

    opacity: number;
    shapes: LegendShape[];

    constructor(nom: string, couleur: string, opacity: number, shapes: LegendShape[]) {
        this.nom = nom;
        this.couleur = couleur;
        this.opacity = opacity;
        this.shapes = shapes;
    }
}

export enum LegendShape {
    FILLED_SQUARE,
    EMPTY_SQUARE,
    LINE,
}
