import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent, ConfirmationService, NotificationService } from 'src/app/commons-lib';
import { EditModeEnum } from 'src/app/model/edit-mode.model';
import { Equipement } from 'src/app/model/equipement.model';
import { ReferenceApiService } from 'src/app/services/reference-api.service';
import { URL_GESTION_EQUIPEMENTS_EDIT } from 'src/app/shared/constants/url.constants';

@Component({
    selector: 'app-view-details-equipement',
    templateUrl: './view-details-equipement.component.html',
    styleUrls: ['./view-details-equipement.component.scss'],
})
export class ViewDetailsEquipementComponent extends BaseComponent implements OnInit, OnDestroy {
    @Output() deletedEquipementEvent = new EventEmitter<Equipement>();

    @Input()
    equipement: Equipement;

    constructor(
        private readonly router: Router,
        private readonly referenceApiService: ReferenceApiService,
        private readonly confirmationService: ConfirmationService,
        private readonly notificationService: NotificationService
    ) {
        super();
    }

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        //console.log(changes);
        this.equipement = changes.equipement.currentValue;
    }

    creerEquipement() {
        this.router.navigate([URL_GESTION_EQUIPEMENTS_EDIT, EditModeEnum.CREATE]);
    }

    dupliquerEquipement(id: string) {
        this.router.navigate([URL_GESTION_EQUIPEMENTS_EDIT, id, EditModeEnum.DUPLICATE]);
    }

    editerEquipement(id: string) {
        this.router.navigate([URL_GESTION_EQUIPEMENTS_EDIT, id, EditModeEnum.EDIT]);
    }

    supprimerEquipement(id: string) {
        this.confirmationService.confirm(`Voulez-vous supprimer définitivement cet équipement : ${this.equipement.name} ?`, () => {
            this.referenceApiService
                .deleteEquipement(id)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((resp) => {
                    this.notificationService.success(`L'équipement : ${this.equipement.name} à bien été supprimé`);
                    this.deletedEquipementEvent.emit(this.equipement);
                });
        });
    }
}
