import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Environment } from '../environment';
import { User } from '../authentication/user.model';

@Injectable({
    providedIn: 'root',
})
export class UserManagementService {
    public resourceUrl = Environment.managementUrl + '/users';

    constructor(private http: HttpClient) {}

    create(user: User): Observable<User> {
        // Supprime les champs non-nullable côté Kotlin
        delete user.langKey;

        return this.http.post<User>(this.resourceUrl, user);
    }

    update(user: User): Observable<User> {
        return this.http.put<User>(this.resourceUrl, user);
    }

    find(email: string): Observable<User> {
        return this.http.get<User>(`${this.resourceUrl}/${email}`);
    }

    findAll(): Observable<User[]> {
        // NB : La ressource est paginée, donc on demande une seule page de 9999 éléments ici
        return this.http.get<User[]>(`${this.resourceUrl}?page=0&size=9999&sort=id,asc`);
    }

    delete(email: string, archiveOnly = false, replacementUserEmail?: string): Observable<any> {
        let params = new HttpParams();
        if (archiveOnly) {
            params = params.set('archiveOnly', archiveOnly.toString());
        }
        if (replacementUserEmail) {
            params = params.set('replacementUserEmail', replacementUserEmail.toString());
        }
        return this.http.delete(`${this.resourceUrl}/${email}`, { params });
    }

    authorities(): Observable<string[]> {
        return this.http.get<string[]>(`${this.resourceUrl}/authorities`);
    }

    searchByEmail(query: string): Observable<User[]> {
        return this.http.get<User[]>(`${this.resourceUrl}/search-by-email/${query}`);
    }

    setPassword(email: string, newPassword: string, notifyUserByEmail: boolean): Observable<any> {
        return this.http.post(`${this.resourceUrl}/${email}/set-password`, { newPassword, notifyUserByEmail });
    }
}
