'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2021 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

import { cn_building } from './cn_building';
import { cn_space_area } from './cn_space_area';
import { cn_space } from './cn_space';
import { cn_storey } from './cn_storey';
import { cn_transaction_manager } from '../utils/cn_transaction_manager';
import { cn_camera } from '../svg/cn_camera';
import { logger } from '../utils/cn_logger';

//*** Available area regulations */
export const CN_AREA_REGULATIONS = [
    { code: 'carrez_area', label: 'Surface Loi Carrez', sub_labels: [], color_code: 0 },
    { code: 'inhabitable_area', label: 'Surface Habitable', sub_labels: [], color_code: 1 },
    { code: 'useful_area', label: 'Surface Utile', sub_labels: ['Surface utile Brute', 'Surface utile Nette'], color_code: 2 }
];

//***********************************************************************************
//***********************************************************************************
//**** cn_area_context
/**
 * An area context is hold by the building.
 * Each area context means a specific area regulation for the building.
 * An area context may have a sub labels. In that case, in each space there can be:
 * - major part of the area attached to the sub label 0
 * - Each area of sub label n is a sub part of area of sub label n-1.
 * examples:
 * - label = "Carrez", sub_labels = []
 * - label = "Surface utile", sub_labels = ["Surface Utile Brute","Surface Utile Nette"]
 */
//***********************************************************************************
//***********************************************************************************

export class cn_area_context {
    //***********************************************************************************
    /**
     * Constructor
     *
     * @param {string} code
     * @param {cn_building} building
     */
    constructor(code, building) {

        /** back pointer */
        this.building = building;

        /** regulation code */
        this.code = code;

        /** regulation */
        this.regulation = CN_AREA_REGULATIONS.find(r => r.code == code);

        /** regulation label */
        this.label = this.regulation ? this.regulation.label : undefined;

        //*** Sub labels */
        this.sub_labels = this.regulation ? this.regulation.sub_labels : undefined;

        //*** list of spaces */
        this.spaces = [];

        //*** list of space sub levels (relevant only if there are at least 2 sub labels) */
        this.space_sub_levels = [];

        /*** list of space areas (class cn_space_area)
         * Not a primary data, serialized only for information
         */
        this.space_areas = [];
    }

    //***********************************************************************************
    /**
     * Serialize method
     * @returns {object}
     */
    serialize() {
        var json = {};
        json.code = this.code;
        json.spaces = this.spaces.map(sp => sp.ID);
        if (this.sub_labels.length > 1)
            json.space_sub_levels = this.space_sub_levels.concat([]);
        return json;
    }

    //***********************************************************************************
    /**
     * Unserialize method
     * @param {object} json
     * @param {cn_building} building
     */
    static unserialize(json, building) {
        if (typeof (json.code) != 'string') return;
        let area_context = new cn_area_context(json.code, building);
        if (!area_context.regulation) {
            console.error(`Area regulation ${json.code} is unknown.`);
            return;
        }
        for (var i = 0; i < json.spaces.length; i++) {
            const id = json.spaces[i];
            var sp = null;
            for (var ns = 0; ns < building.storeys.length; ns++) {
                var storey = building.storeys[ns];
                sp = storey.scene.spaces.find(space => space.ID == id);
                if (sp) break;
            }
            if (sp) {
                area_context.spaces.push(sp);
                if (json.space_sub_levels && i < json.space_sub_levels.length)
                    area_context.space_sub_levels.push(json.space_sub_levels[i]);
            }
        }
        building.area_contexts.push(area_context);
    }

    //***********************************************************************************
    /**
     * Add one space to the area context
     * @param {cn_space} space
     * @param {number} sub_index
     * @param {cn_transaction_manager} transaction_manager If specified, will add a transaction event on current transaction.
     */
    add_space(space, sub_index = 0, transaction_manager = null) {
        if (transaction_manager)
            transaction_manager.push_item_set(this, ['spaces', 'space_sub_levels']);

        const index = this.spaces.indexOf(space);
        if (index >= 0) {
            if (index < this.space_sub_levels.length)
                this.space_sub_levels[index] = sub_index;
        } else {
            this.spaces.push(space);
            if (this.sub_labels.length > 1)
                this.space_sub_levels.push(sub_index);
        }
    }

    //***********************************************************************************
    /**
     * remove one space from the area context
     * @param {cn_space} space
     * @param {cn_transaction_manager} transaction_manager If specified, will add a transaction event on current transaction.
     */
    remove_space(space, transaction_manager = null) {
        var index = this.spaces.indexOf(space);
        if (index < 0) return;

        if (transaction_manager)
            transaction_manager.push_item_set(this, ['spaces', 'space_sub_levels']);

        this.spaces.splice(index, 1);
        if (index < this.space_sub_levels.length)
            this.space_sub_levels.splice(index, 1);
    }

    //***********************************************************************************
    /**
     * Returns sub level index for space in the area context, or -1 if not in area context.
     * @param {cn_space} space
     * @returns {number}
     */
    get_sub_level(space) {
        const index = this.spaces.indexOf(space);
        if (index < 0) return -1;
        if (index >= this.space_sub_levels.length) return 0;
        return this.space_sub_levels[index];
    }

    //***********************************************************************************
    /**
     * Check spaces in area context. Returns true if no change.
     * @returns {boolean}
     */
    check_spaces() {
        const scenes = this.building.storeys.map(st => st.scene);

        var any_change = 0;
        for (var i = 0; i < this.spaces.length; i++) {
            let index = scenes.indexOf(this.spaces[i].scene);
            if (index >= 0 && this.spaces[i].indoor) continue;
            any_change++;
            this.spaces.splice(i, 1);
            if (i < this.space_sub_levels.length) this.space_sub_levels.splice(i, 1);
            i--;
        }
        logger.log('any changes in area context : ', any_change, this.spaces.length);
        return any_change != 0;
    }

    //***********************************************************************************
    /**
     * Update space areas
     */
    update_space_areas() {
        var new_space_areas = [];
        this.building.storeys.forEach(storey => {
            storey.scene.spaces.forEach(space => {
                const sl = this.get_sub_level(space);
                if (sl >= 0) {
                    var sa = this.get_space_area(storey, space);
                    if (!sa) sa = new cn_space_area(space, storey, this);
                    sa.sub_level = sl;
                    new_space_areas.push(sa);
                }
            });
        });
        this.space_areas = new_space_areas;
    }

    //***********************************************************************************
    /** Returns the corresponding space area, if it exists */
    get_space_area(storey, space) {
        return this.space_areas.find(sa => sa.storey == storey && sa.space == space);
    }

    /**
     * Returns the measured area
     * @param {cn_storey} storey
     * @param {cn_space} space
     * @param {number} sub_area
     * @returns {number}
     */
    get_space_area_value(storey, space, sub_area = 0) {
        const space_area = this.get_space_area(storey, space);
        if (!space_area) return 0;
        if (sub_area >= 0 && sub_area < space_area.sub_areas.length)
            return space_area.sub_areas[sub_area];
        return 0;
    }

    //***********************************************************************************
    /**
     * Compute areas for a given storey, or all storeys if not specified
     * @param {cn_storey} storey_target
     */
    compute_space_areas(storey_target = null) {
        var obj = this;
        //*** Loop on the building storeys */
        for (var ns = 0; ns < this.building.storeys.length; ns++) {
            //*** we check that we have to treeat this storey */
            var storey = this.building.storeys[ns];
            if (storey_target && storey != storey_target) continue;

            //*** get all spaces in this storey in the area context */
            var space_areas = this.space_areas.filter(sp => sp.storey == storey);
            if (space_areas.length == 0) continue;

            //*** we need the roof volume */
            let roof_volume = storey.build_space_volume();
            let roof_footprint = (roof_volume) ? roof_volume.plane_intersection([0, 0, 1.8], [0, 0, 1]) : null;

            storey.scene.area_trimmings.forEach(at => {
                at.update();
                at.footprint = at.build_3d_polygon(1.8);
            });

            var stair_footprints = [];
            storey.scene.stairs.forEach(stair => {
                var pg = stair.build_footprint(0);
                if (pg) stair_footprints.push(pg);
            });
            storey.scene.columns.forEach(column => {
                var pg = column.build_footprint(0);
                if (pg) stair_footprints.push(pg);
            });

            space_areas.forEach(space_area => {
                space_area.floor_polygon = space_area.space.build_inner_polygon(1.8 + space_area.space.slab_offset, true);
                stair_footprints.forEach(sf => {
                    space_area.floor_polygon.substracts(sf);
                });
                space_area.floor_polygon.compute_contours();
                space_area.floor_area = space_area.floor_polygon.get_area();

                var pg0 = space_area.floor_polygon.clone();
                if (space_area.space.slab_offset != 0) {
                    const roof_footprint2 = (roof_volume) ? roof_volume.plane_intersection([0, 0, 1.8 + space_area.space.slab_offset], [0, 0, 1]) : null;
                    if (roof_footprint2)
                        pg0.intersects(roof_footprint2);
                    else
                        pg0.clear();
                } else if (roof_footprint)
                    pg0.intersects(roof_footprint);
                else
                    pg0.clear();

                storey.scene.area_trimmings.forEach(at => {
                    if (at.level == 0) pg0.substracts(at.footprint);
                });
                space_area.sub_areas = [];
                space_area.sub_polygons = [];
                for (var nsl = 0; nsl <= space_area.sub_level; nsl++) {
                    storey.scene.area_trimmings.forEach(at => {
                        if (at.level == nsl) pg0.substracts(at.footprint);
                    });
                    pg0.compute_contours();
                    space_area.sub_areas.push(pg0.get_area());
                    space_area.sub_polygons.push(pg0.clone());
                    if (nsl > 0) space_area.sub_polygons[nsl - 1].substracts(pg0);
                }
            });
        }
    }

    //***********************************************************************************
    /**
     * Update everything
     * @param {cn_storey} storey
     */
    update_deep(storey = null) {
        this.check_spaces();
        this.update_space_areas();
        this.compute_space_areas(storey);
    }

    //***********************************************************************************
    /**
     * Draw
     * @param {cn_storey} storey
     * @param {cn_camera} camera
     * @returns {string}
     */
    draw(storey, camera) {
        var html = '';

        this.space_areas.forEach(space_area => {
            if (space_area.storey == storey)
                html += space_area.draw(camera);
        });

        return html;
    }

}
