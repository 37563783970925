import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent, NotificationService } from 'src/app/commons-lib';
import { combineLatest, forkJoin, of } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { CheckValidityTabService } from 'src/app/services/check-validity-tab.service';
import {
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE,
    PARAM_TOOLTIP_NON_VISITEE,
    PARAM_TOOLTIP_PARTIELLEMENT_VISITEE,
    PARAM_TOOLTIP_VISITEE,
    PARAM_VOLUME_LOT,
    PARAM_VOLUME_VISITE,
} from 'src/app/shared/constants/cndiag.constants';
import { Bien, Volume } from '../../../../../model/bien.model';
import { Diagnostic, EtatDiagnostic } from '../../../../../model/diagnostic.model';
import { Intervention, RelationInterventionBien } from '../../../../../model/intervention.model';
import { PointDeControleBien, PointDeControleNiveau, PointDeControleVolume } from '../../../../../model/point-de-controle.model';
import { TypePrestation, TypePrestationEnum } from '../../../../../model/type-prestation.model';
import { DiagnosticService } from '../../../../../services/diagnostic.service';
import { EtatProgressionService } from '../../../../../services/etat-progression.service';
import { InterventionService } from '../../../../../services/intervention.service';
import { ProgressDiagnosticService } from '../../../../../services/progress-diagnostic.service';
import { notValidatedOption, validatedOption, warningOption } from '../../../../../shared/constants/states.constants';
import { DialogUtils } from '../../../../../utils/dialog.utils';
import { CnSpinnerService } from '../../../../shared/cn-spinner/service/cn-spinner.service';
import { CommentModalComponent } from '../../../../shared/comment-modal/comment-modal.component';
import { PropertiesMesurage } from '../../model/mesurage.model';
import { MesurageService } from '../../services/mesurage.service';
import {
    MESURE_NON_COMMUNIQUE,
    PARAM_AU_SOL,
    PARAM_MESURES_INCOHERENTES,
    PARAM_TYPE_MESURAGE_CARREZ,
    PARAM_TYPE_MESURAGE_CARREZ_ZONES,
    PARAM_TYPE_MESURAGE_CARREZ_ZONES_TOTAL,
    PARAM_TYPE_MESURAGE_HABITABLE,
    PARAM_TYPE_MESURAGE_HABITABLE_ZONES,
    PARAM_TYPE_MESURAGE_HABITABLE_ZONES_TOTAL,
    PROPERTIES_MESURAGE_CARREZ,
    PROPERTIES_MESURAGE_HABITABLE,
    PROPERTIES_MESURAGE_UTILE,
} from '../../shared/mesurage.constants';
import { cn_building } from '@acenv/cnmap-editor';
import { ViewerMapComponent } from '../../../../shared/map/viewer-map/viewer-map.component';
import { ViewerMapConfig } from '../../../../shared/map/viewer-map/viewer-map-config';
import { CnStoreyConfig } from '@acenv/cnmap-angular-editor-lib/lib/model/cn-storey-config.model';
import { RulesService } from '../../../../../services/rules.service';
import { enumTypesCommentaire } from 'src/app/model/type-commentaire.model';

export class MesurageConfig {
    typeMesurageComplementaire: string;
    listeMesuresComplementaires: string[];
    diagnosticComplementaireLabel: string;
    displayedColumnsComplementaires: string[];
    displayedColumnsZonesComplementaires: string[];
    listeMesuresZonesComplementaires: string[];
    listeMesuresZonesComplementairesTotales: string[];
    propertiesMesurage: PropertiesMesurage;
    typeMesurage: TypePrestation;
}

export const mesurageConfigMap: { [typeDiag: string]: MesurageConfig } = {
    MESURAGE: {
        typeMesurage: TypePrestationEnum.MESURAGE,
        typeMesurageComplementaire: TypePrestationEnum.MESURAGE_HABITABLE,
        listeMesuresComplementaires: PARAM_TYPE_MESURAGE_HABITABLE,
        diagnosticComplementaireLabel: 'Mesurage habitable',
        displayedColumnsComplementaires: ['type', 'habitable', 'non habitable', 'au sol', 'copy'],
        displayedColumnsZonesComplementaires: ['type', 'habitable', 'non habitable', 'copy'],
        listeMesuresZonesComplementaires: PARAM_TYPE_MESURAGE_HABITABLE_ZONES,
        listeMesuresZonesComplementairesTotales: PARAM_TYPE_MESURAGE_HABITABLE_ZONES_TOTAL,
        propertiesMesurage: PROPERTIES_MESURAGE_CARREZ,
    },
    MESURAGE_HABITABLE: {
        typeMesurage: TypePrestationEnum.MESURAGE_HABITABLE,
        typeMesurageComplementaire: TypePrestationEnum.MESURAGE,
        listeMesuresComplementaires: PARAM_TYPE_MESURAGE_CARREZ,
        diagnosticComplementaireLabel: 'Mesurage Carrez',
        displayedColumnsComplementaires: ['type', 'loi Carrez', 'hors loi Carrez', 'au sol', 'copy'],
        displayedColumnsZonesComplementaires: ['type', 'loi Carrez', 'hors loi Carrez', 'copy'],
        listeMesuresZonesComplementaires: PARAM_TYPE_MESURAGE_CARREZ_ZONES,
        listeMesuresZonesComplementairesTotales: PARAM_TYPE_MESURAGE_CARREZ_ZONES_TOTAL,
        propertiesMesurage: PROPERTIES_MESURAGE_HABITABLE,
    },
    MESURAGE_UTILE: {
        typeMesurage: TypePrestationEnum.MESURAGE_UTILE,
        propertiesMesurage: PROPERTIES_MESURAGE_UTILE,
        typeMesurageComplementaire: null,
        listeMesuresComplementaires: [],
        diagnosticComplementaireLabel: '',
        displayedColumnsComplementaires: [],
        displayedColumnsZonesComplementaires: [],
        listeMesuresZonesComplementaires: [],
        listeMesuresZonesComplementairesTotales: [],
    },
};

export class SelectedBien {
    constructor(
        public readonly currentBien: PointDeControleBien,
        public readonly currentRelationBien: RelationInterventionBien,
        public readonly bienBuilding: cn_building
    ) {}
}

@Component({
    selector: 'app-mesure',
    templateUrl: './mesure.component.html',
    styleUrls: ['./mesure.component.scss'],
})
export class MesureComponent extends BaseComponent implements OnInit {
    TypePrestationEnum = TypePrestationEnum;

    @ViewChild('viewMap') viewMap: ViewerMapComponent;

    readonly CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ = CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ;
    readonly CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE = CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE;
    readonly CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE = CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE;
    readonly MESURE_NON_COMMUNIQUE = MESURE_NON_COMMUNIQUE;
    readonly PARAM_VOLUME_VISITE = PARAM_VOLUME_VISITE;
    readonly PARAM_MESURES_INCOHERENTES = PARAM_MESURES_INCOHERENTES;
    readonly PARAM_VOLUME_LOT = PARAM_VOLUME_LOT;

    // Paramètres de visite
    validatedChoiceOption = validatedOption;
    notValidatedChoiceOption = notValidatedOption;
    warningChoiceOption = warningOption;
    tooltipsVisite = [PARAM_TOOLTIP_VISITEE, PARAM_TOOLTIP_PARTIELLEMENT_VISITEE, PARAM_TOOLTIP_NON_VISITEE];

    private currentIntervention: Intervention;
    currentDiagnostic: Diagnostic;
    readonlyMode = false;
    biens: Bien[] = [];

    selection: {
        get currentBien(): PointDeControleBien;
        get currentRelationBien(): RelationInterventionBien;
        get bienBuilding(): cn_building;
    };
    // Niveau courant
    currentNiveau: PointDeControleNiveau;

    // Point de controle volume courant
    currentPointDeControleVolume: PointDeControleVolume;
    // Tableau de mesures
    displayedColumnsZones: string[] = ['mode', 'nom', 'surface', 'edit'];
    // Mode de visualisation
    viewMode = 'list';

    noDescription = false;
    // Mode zone
    editZone = false;
    zoneToEdit: any;
    zones = [];

    hasNegativeMesure = false;

    private volumesComplementaires: PointDeControleVolume[];
    currentVolumeComplementaire: PointDeControleVolume;

    // sidebar
    pagesPanelOpened = true;
    storeyIdToDisplayInMap: string;
    viewerMapConfig: ViewerMapConfig = {
        isSelectablePieceSansEquipement: true,
        isSelectableEquipement: true,
    };

    cnMapConfig: CnStoreyConfig = {
        drawSpaceNames: true,
        drawBackground: true,
        drawSpaceAreas: false,
        drawGrid: false,
        drawScale: false,
        showCompass: false,
        padding: { left: 30, right: 30, top: 30, bottom: 30 },
    };

    private numOr0 = (n) => (isNaN(n) ? 0 : n);
    mesureSuggested: string;
    currentVolumeForMap: Volume;
    prevVolumeIsDisabled: boolean;
    nextVolumeIsDisabled: boolean;
    mesurageConfig: MesurageConfig;
    manqueJustificatifNonVisite = false;
    typeCommentaireJustifNonVisite: enumTypesCommentaire[] = [enumTypesCommentaire.JUSTIFICATION_NON_VISITE];

    constructor(
        private readonly matDialog: MatDialog,
        private readonly interventionService: InterventionService,
        private readonly diagnosticService: DiagnosticService,
        private readonly progressDiagnosticService: ProgressDiagnosticService,
        private readonly etatProgressionService: EtatProgressionService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly route: ActivatedRoute,
        private readonly notificationService: NotificationService,
        private readonly checkValidityTabService: CheckValidityTabService,
        private readonly mesurageService: MesurageService,
        private readonly rulesService: RulesService
    ) {
        super();
    }

    ngOnInit(): void {
        this.cnSpinnerService
            .withSpinner(
                combineLatest([this.interventionService.getCurrentIntervention(), this.diagnosticService.getCurrentDiagnostic()]).pipe(
                    switchMap(([intervention, diagnostic]) => {
                        this.currentIntervention = intervention;
                        this.currentDiagnostic = diagnostic;
                        this.readonlyMode = this.diagnosticService.isReadOnlyMode(this.currentIntervention, diagnostic);
                        this.mesurageConfig = mesurageConfigMap[this.currentDiagnostic.typePrestation];

                        this.biens = this.currentIntervention.relationInterventionBiens.map((relationBienPrincipal) => relationBienPrincipal.bien);
                        // Initialisation du formulaire de mesurages
                        MesurageService.initMesures(this.currentDiagnostic.pointsDeControleBiens, this.currentIntervention);

                        return this.findDiagnosticComplementaire();
                    }),
                    tap(() => {
                        this.currentIntervention.relationInterventionBiens
                            .flatMap((rb) => rb.bien.description.flatMap((niveau) => niveau.volumes))
                            .forEach((volume) => this.computeJustificationNonVisite(volume));
                        // Préselection du premier bien
                        if (this.currentDiagnostic.pointsDeControleBiens.length) {
                            this.changeCurrentBien(this.currentDiagnostic.pointsDeControleBiens[0].nomBien);
                            this.getHistory();
                        } else {
                            this.noDescription = true;
                        }
                        if (this.selection?.currentRelationBien?.bien?.jsonPlan) {
                            this.viewMode = 'plan';
                        }
                        this.checkValidity();
                        this.progressDiagnosticService.refresh();
                    }),
                    takeUntil(this.ngUnsubscribe)
                )
            )
            .subscribe();

        this.etatProgressionService.etatProgressions$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((diagn) => {
            this.readonlyMode = this.diagnosticService.isReadOnlyMode(this.currentIntervention, this.currentDiagnostic);
        });
    }

    /**
     * Change le bien en cours
     */
    changeCurrentBien(nomBien: string) {
        const currentBien = this.currentDiagnostic.pointsDeControleBiens.find((b) => b.nomBien === nomBien);
        const currentRelationBien = this.currentIntervention.relationInterventionBiens.find((rel) => rel.bien.id === currentBien.idBien);
        this.selection = new SelectedBien(currentBien, currentRelationBien, MesureComponent.loadBuilding(currentRelationBien.bien));
        this.initChangementBien();
        if (this.selection.currentBien.pointsDeControleNiveaux?.length > 0) {
            this.changeCurrentNiveau(this.selection.currentBien.pointsDeControleNiveaux[0].idNiveau);
        }
    }

    /**
     * Change le niveau en cours
     * Préselectionne le premier volume
     */
    changeCurrentNiveau(idNiveau: string) {
        this.currentNiveau = this.selection.currentBien.pointsDeControleNiveaux.find((e) => e.idNiveau === idNiveau);
        this.storeyIdToDisplayInMap = this.currentNiveau.storeyId;
        let volumeToSelect = null;
        if (this.viewMode !== 'plan' && this.currentNiveau.pointsDeControleVolumes.length) {
            volumeToSelect = this.currentNiveau.pointsDeControleVolumes[0];
        }
        this.changeCurrentVolume(volumeToSelect);
    }

    /**
     * Sélectionne le volume en cours ou déselectionne
     */
    changeCurrentVolume(volume) {
        this.mesureSuggested = null;
        this.currentVolumeForMap = null;
        this.manqueJustificatifNonVisite = false;
        this.currentPointDeControleVolume = volume;
        if (volume) {
            this.currentVolumeForMap = this.biens
                .find((it) => it.id === this.selection.currentBien.idBien)
                .description.find((niv) => niv.id === this.currentNiveau.idNiveau)
                .volumes.find((v) => v.id === this.currentPointDeControleVolume.idVolume);
            this.manqueJustificatifNonVisite =
                ['ko', 'warning'].includes(this.currentVolumeForMap?.valeursParametres[PARAM_VOLUME_VISITE]) && !this.currentVolumeForMap?.justifie;

            if (this.currentVolumeForMap?.spaceId && this.selection.bienBuilding) {
                const spaceFromBuilding = this.selection.bienBuilding.storeys
                    .find((it) => it.ID === this.currentNiveau.storeyId)
                    .scene.spaces.find((it) => it.ID === this.currentVolumeForMap.spaceId);
                if (spaceFromBuilding) {
                    this.mesureSuggested = Number(spaceFromBuilding.plain_area).toFixed(2);
                }
            }
            MesurageService.checkValidityMesures(
                this.currentPointDeControleVolume,
                this.mesurageConfig.propertiesMesurage.isMesurageUtile,
                this.mesurageConfig.propertiesMesurage.listeMesures
            );
            // Vérification des justifications de non visite, si nécessaire
            this.computeJustificationNonVisite(this.currentVolumeForMap);
        }

        // Desactivation des boutons quand c'est nécessaire
        this.prevVolumeIsDisabled =
            this.currentNiveau?.pointsDeControleVolumes?.findIndex((v) => v.idVolume === this.currentPointDeControleVolume?.idVolume) === 0;
        this.nextVolumeIsDisabled =
            this.currentNiveau?.pointsDeControleVolumes?.findIndex((v) => v.idVolume === this.currentPointDeControleVolume?.idVolume) ===
            this.currentNiveau?.pointsDeControleVolumes?.length - 1;

        // Vérification des justifications de non visite, si nécessaire
        this.doesVolumeHasNegativeMesures();

        this.findVolumeComplementaire();
        this.isRepute();

        // Récupération des zones si existantes
        this.getZones();
    }
    /**
     * Sélectionne le volume suivant ou précédant
     */
    nextPrevVolume(nextOrPrev) {
        const volumesList = this.currentNiveau.pointsDeControleVolumes;
        let Index = volumesList.findIndex((v) => v.idVolume === this.currentPointDeControleVolume.idVolume);
        switch (nextOrPrev) {
            case 'prev':
                if (Index <= volumesList.length && Index != 0) Index--;
                break;
            case 'next':
                if (Index >= 0 && Index < volumesList.length - 1) Index++;
                break;
            default:
                break;
        }
        this.mesureSuggested = null;
        this.currentVolumeForMap = null;
        this.manqueJustificatifNonVisite = false;
        this.currentPointDeControleVolume = volumesList[Index];

        this.currentVolumeForMap = this.biens
            .find((it) => it.id === this.selection.currentBien.idBien)
            .description.find((niv) => niv.id === this.currentNiveau.idNiveau)
            .volumes.find((v) => v.id === this.currentPointDeControleVolume.idVolume);

        if (this.currentVolumeForMap?.spaceId && this.selection.bienBuilding) {
            const spaceFromBuilding = this.selection.bienBuilding.storeys
                .find((it) => it.ID === this.currentNiveau.storeyId)
                .scene.spaces.find((it) => it.ID === this.currentVolumeForMap.spaceId);
            if (spaceFromBuilding) {
                this.mesureSuggested = Number(spaceFromBuilding.plain_area).toFixed(2);
            }
        }
        this.computeJustificationNonVisite(this.currentVolumeForMap);
        this.manqueJustificatifNonVisite =
            ['ko', 'warning'].includes(this.currentVolumeForMap?.valeursParametres[PARAM_VOLUME_VISITE]) && !this.currentVolumeForMap?.justifie;

        MesurageService.checkValidityMesures(
            this.currentPointDeControleVolume,
            this.mesurageConfig.propertiesMesurage.isMesurageUtile,
            this.mesurageConfig.propertiesMesurage.listeMesures
        );
        // Desactivation des boutons quand c'est nécessaire
        this.prevVolumeIsDisabled =
            this.currentNiveau?.pointsDeControleVolumes?.findIndex((v) => v.idVolume === this.currentPointDeControleVolume?.idVolume) === 0;

        this.nextVolumeIsDisabled =
            this.currentNiveau?.pointsDeControleVolumes?.findIndex((v) => v.idVolume === this.currentPointDeControleVolume?.idVolume) ===
            this.currentNiveau?.pointsDeControleVolumes?.length - 1;
        // Vérification des justifications de non visite, si nécessaire
        this.doesVolumeHasNegativeMesures();

        this.findVolumeComplementaire();
        this.isRepute();

        // Récupération des zones si existantes
        this.getZones();

        return;
    }

    attributeDisplay(attribute1, attribute2) {
        if (attribute1.idVolume == attribute2.idVolume) {
            return attribute1.nom;
        } else {
            return '';
        }
    }

    /**
     * Calcul les surfaces totales du niveau en cours, et du bien en cours
     */
    private calculSurfacesTotales() {
        MesurageService.calculTotalNiveauMesurage(
            this.currentNiveau,
            this.mesurageConfig.propertiesMesurage.listeMesures,
            this.mesurageConfig.propertiesMesurage.listeMesuresTotales
        );
        MesurageService.calculTotalBienMesurage(this.selection.currentBien, this.mesurageConfig.propertiesMesurage.listeMesuresTotales);

        this.progressDiagnosticService.refresh();
        this.updateSurfaceBien();
        this.checkValidity();
    }

    /**
     * Nettoient les zones de mesure en fonction des saisies
     */
    cleanMesures(mesure: string) {
        // Nettoie la Map des zones
        this.cleanFields(mesure);
        // Calcul de la troisième mesure si deux mesures présentes et que le type de mesurage n'est pas mesurage utile
        // Si les 3 mesures ont une valeur, il n'y a rien à déterminé donc on ne fait pas le calcul double facteur
        // Si l'utilisateur à cocher nonRepute, on va déduire l'autre valeur possible
        if (
            !this.mesurageConfig.propertiesMesurage.isMesurageUtile &&
            (!this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[0]] ||
                !this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[1]] ||
                !this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[2]] ||
                this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.typeNonRepute])
        ) {
            this.calculDoubleFacteur(mesure);
        }
        this.afterMesureChanged();
    }

    /**
     * Déduction de la mesure en paramètre suivant les autres mesures de la pièce
     */
    calcMesure(mesure: string) {
        if (this.currentPointDeControleVolume.valeursParametres[mesure]) {
            delete this.currentPointDeControleVolume.valeursParametres[mesure];
        }
        // Nettoie la Map des zones
        this.cleanFields(mesure);

        this.deduireMesure(mesure);

        this.afterMesureChanged();
    }

    /**
     * Lorsqu'un commentaire a été ajouté au volume courant, on rajoute le commentaire aux points de contrôle
     * Vérification de la présence ou non d'une justification en cas de non visite
     */
    currentVolumeCommentChanged(volume: Volume) {
        this.currentDiagnostic.pointsDeControleBiens.forEach((b) => {
            b.pointsDeControleNiveaux.forEach((n) => {
                n.pointsDeControleVolumes.forEach((v) => {
                    if (v.idVolume === volume.id) {
                        v.commentairesId = volume.commentairesId;
                    }
                });
            });
        });
        // Vérification des justifications de non visite, si nécessaire et mise à jour du bien
        this.computeJustificationNonVisite(volume);
        this.manqueJustificatifNonVisite =
            ['ko', 'warning'].includes(this.currentVolumeForMap?.valeursParametres[PARAM_VOLUME_VISITE]) && !this.currentVolumeForMap?.justifie;
    }

    /**
     * Vérification des justifications de non visite, si nécessaire
     */
    currentVisiteChanged(visiteValue: string) {
        this.currentDiagnostic.pointsDeControleBiens.forEach((b) => {
            b.pointsDeControleNiveaux.forEach((n) => {
                n.pointsDeControleVolumes.forEach((v) => {
                    if (v.idVolume === this.currentPointDeControleVolume.idVolume) {
                        v.valeursParametres[PARAM_VOLUME_VISITE] = visiteValue;
                        v.typesCommentaires = this.currentPointDeControleVolume.typesCommentaires;
                    }
                });
            });
        });

        // mise à jour de la valeur du paramètre visité de la pièce dans la relation intervention/bien de l'intervention
        this.interventionService.updateRelationInterventionBien(
            this.currentIntervention,
            this.selection.currentBien.idBien,
            this.currentNiveau.idNiveau,
            this.currentPointDeControleVolume.idVolume,
            PARAM_VOLUME_VISITE,
            visiteValue
        );

        // Vérification des justifications de non visite, si nécessaire
        this.computeJustificationNonVisite(this.currentVolumeForMap);
        this.manqueJustificatifNonVisite =
            ['ko', 'warning'].includes(this.currentVolumeForMap?.valeursParametres[PARAM_VOLUME_VISITE]) && !this.currentVolumeForMap?.justifie;
    }

    /**
     * Ajout d'une justification de non visite
     */
    addJustificationNonVisite(currentVolume: Volume) {
        // S'il faut rajouter une constatation diverse pour justifier la conformité
        if (!currentVolume.justifie) {
            if (['ko', 'warning'].includes(currentVolume.valeursParametres[PARAM_VOLUME_VISITE])) {
                this.matDialog
                    .open(CommentModalComponent, {
                        ...DialogUtils.configFullScreen(),
                        data: {
                            commentairesId: currentVolume.commentairesId,
                            typesCommentaires: [enumTypesCommentaire.JUSTIFICATION_NON_VISITE],
                            typeNouveauCommentaire: enumTypesCommentaire.JUSTIFICATION_NON_VISITE,
                        },
                    })
                    .afterClosed()
                    .subscribe((result) => {
                        if (result && result !== false) {
                            currentVolume.commentairesId = result.commentairesId;
                            this.currentDiagnostic.pointsDeControleBiens.forEach((b) => {
                                b.pointsDeControleNiveaux.forEach((n) => {
                                    n.pointsDeControleVolumes.forEach((v) => {
                                        if (v.idVolume === currentVolume.id) {
                                            v.commentairesId = result.commentairesId;
                                        }
                                    });
                                });
                            });
                            this.cnSpinnerService
                                .withSpinner(
                                    forkJoin([
                                        this.interventionService.updateIntervention(this.currentIntervention),
                                        this.diagnosticService.upsert(this.currentIntervention, this.currentDiagnostic),
                                    ]),
                                    'Sauvegarde en cours...'
                                )
                                .subscribe();
                            // Vérification des justifications de non visite, si nécessaire
                            this.computeJustificationNonVisite(currentVolume);
                            this.manqueJustificatifNonVisite =
                                ['ko', 'warning'].includes(this.currentVolumeForMap?.valeursParametres[PARAM_VOLUME_VISITE]) &&
                                !this.currentVolumeForMap?.justifie;
                        }
                    });
            }
        }
    }

    /**
     * Changement de tab
     */
    tabChanged() {
        this.editZone = false;
        this.zoneToEdit = undefined;
    }

    /**
     * Fermeture du mode mesurage par zone
     */
    closeZoneMode() {
        // Calcul de la troisième mesure si deux mesures présentes et que le type de mesurage n'est pas mesurage utile
        if (!this.mesurageConfig.propertiesMesurage.isMesurageUtile) {
            this.calculDoubleFacteur();
        }
        // Récupération des zones mises à jour
        this.getZones();
        // Calcul des surfaces totales (niveau en cours + bien en cours)
        this.calculSurfacesTotales();
        this.doesVolumeHasNegativeMesures();
        MesurageService.checkValidityMesures(
            this.currentPointDeControleVolume,
            this.mesurageConfig.propertiesMesurage.isMesurageUtile,
            this.mesurageConfig.propertiesMesurage.listeMesures
        );
        // Réinitialisation des paramètres
        this.editZone = false;
        this.zoneToEdit = undefined;
    }

    /**
     * Edition d'une zone de mesure particulière
     */
    editZoneRow(zone) {
        this.editZone = true;
        this.zoneToEdit = zone;
    }

    /**
     * Gestion des calculs et des booleans en fonction du paramètre "réputé"
     */
    isRepute() {
        if (this.currentPointDeControleVolume) {
            // Creation d'une nouvelle référence pour relancer les observables de cet objet
            this.currentPointDeControleVolume = Object.assign({}, this.currentPointDeControleVolume);
            if (this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.typeNonRepute]) {
                if (!this.mesurageConfig.propertiesMesurage.isMesurageUtile) {
                    this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[0]] = '0';
                    this.calculAuSol();
                    this.cleanFields(this.mesurageConfig.propertiesMesurage.listeMesures[0]);
                } else {
                    this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[0]] = '0';
                    this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[1]] = '0';
                    this.cleanFields(this.mesurageConfig.propertiesMesurage.listeMesures[0]);
                    this.cleanFields(this.mesurageConfig.propertiesMesurage.listeMesures[1]);
                }

                // Calcul des surfaces totales (niveau en cours + bien en cours)
                this.calculSurfacesTotales();
            }
        }
    }

    /**
     * En cas de mesurage complémentaire, copie des mesures brutes
     */
    applyMeasuresFromMesurageComplementaire() {
        this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[0]] =
            this.currentVolumeComplementaire.valeursParametres[this.mesurageConfig.listeMesuresComplementaires[0]];
        this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[2]] =
            this.currentVolumeComplementaire.valeursParametres[this.mesurageConfig.listeMesuresComplementaires[2]];
        this.calculMesureDeux();
        this.cleanFields(this.mesurageConfig.propertiesMesurage.listeMesures[0]);
        this.cleanFields(this.mesurageConfig.propertiesMesurage.listeMesures[2]);
        // Récupération des zones mises à jour
        this.getZones();
        this.doesVolumeHasNegativeMesures();
        MesurageService.checkValidityMesures(
            this.currentPointDeControleVolume,
            this.mesurageConfig.propertiesMesurage.isMesurageUtile,
            this.mesurageConfig.propertiesMesurage.listeMesures
        );

        // Si le paramètre de visite n'a pas été renseigné, il passe à "ok" automatiquement, dès qu'une mesure a été renseignée
        if (this.currentPointDeControleVolume.valeursParametres[PARAM_VOLUME_VISITE] === undefined) {
            this.currentPointDeControleVolume.valeursParametres[PARAM_VOLUME_VISITE] = 'ok';
        }
        // Calcul des surfaces totales du niveau et du bien courants
        this.calculSurfacesTotales();
    }

    /**
     * En cas de mesurage complémentaire, copie des zones brutes
     */
    applyZonesFromMesurageComplementaire() {
        for (let i = 0; i < this.mesurageConfig.propertiesMesurage.listeMesuresZones.length; i++) {
            this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesuresZones[i]] = [];

            this.currentVolumeComplementaire.valeursParametres[this.mesurageConfig.listeMesuresZonesComplementaires[i]] &&
                this.currentVolumeComplementaire.valeursParametres[this.mesurageConfig.listeMesuresZonesComplementaires[i]].forEach((z) => {
                    const newZone = { ...z };
                    newZone.type = this.mesurageConfig.propertiesMesurage.listeMesures[i];
                    this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesuresZones[i]].push(newZone);
                });
            this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesuresZonesTotales[i]] =
                this.currentVolumeComplementaire.valeursParametres[this.mesurageConfig.listeMesuresZonesComplementairesTotales[i]];
            this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[i]] =
                this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesuresZonesTotales[i]];
        }
        this.calculAuSol();
        // Récupération des zones mises à jour
        this.getZones();
        this.doesVolumeHasNegativeMesures();
        MesurageService.checkValidityMesures(
            this.currentPointDeControleVolume,
            this.mesurageConfig.propertiesMesurage.isMesurageUtile,
            this.mesurageConfig.propertiesMesurage.listeMesures
        );
        // Calcul des surfaces totales (niveau en cours + bien en cours)
        this.calculSurfacesTotales();
    }

    private afterMesureChanged() {
        // Récupération des zones mises à jour
        this.getZones();
        // Calcul des surfaces totales du niveau et du bien courants
        this.calculSurfacesTotales();
        // Si le paramètre de visite n'a pas été renseigné, il passe à "ok" automatiquement, dès qu'une mesure a été renseignée
        if (this.currentPointDeControleVolume.valeursParametres[PARAM_VOLUME_VISITE] === undefined) {
            this.currentPointDeControleVolume.valeursParametres[PARAM_VOLUME_VISITE] = 'ok';
            this.currentVisiteChanged('ok');
        }

        this.doesVolumeHasNegativeMesures();
        MesurageService.checkValidityMesures(
            this.currentPointDeControleVolume,
            this.mesurageConfig.propertiesMesurage.isMesurageUtile,
            this.mesurageConfig.propertiesMesurage.listeMesures
        );
        this.synchroniseMesureAuSolFromFormToMap();
    }

    /**
     * Construit la liste des niveaux du bien en cours.
     * Le premier niveau est pré-selectionné
     */
    private getNiveaux() {
        this.currentNiveau = this.selection.currentBien.pointsDeControleNiveaux[0];
        this.currentPointDeControleVolume = this.currentNiveau.pointsDeControleVolumes[0];
        this.doesVolumeHasNegativeMesures();
        MesurageService.checkValidityMesures(
            this.currentPointDeControleVolume,
            this.mesurageConfig.propertiesMesurage.isMesurageUtile,
            this.mesurageConfig.propertiesMesurage.listeMesures
        );
        this.findVolumeComplementaire();
        this.isRepute();
    }

    /**
     * Vérifie si le bien possède au moins un volume.
     * En fonction, construction de la liste des niveaux et calcul des totaux.
     */
    private initChangementBien() {
        // Vérification s'il y a au moins un volume dans la liste de niveau
        this.noDescription = !this.selection.currentBien.pointsDeControleNiveaux.flatMap((pdcN) => pdcN.pointsDeControleVolumes).length;

        if (!this.noDescription) {
            // Construction de la liste des niveaux du bien sélectionné
            this.getNiveaux();
            // Récupération des zones mises à jour
            this.getZones();
            // Calcul des surfaces totales (niveau en cours + bien en cours)
            this.calculSurfacesTotales();
            // Vérification des justifications de non visite, si nécessaire
            this.computeJustificationNonVisite(this.currentVolumeForMap);
            this.manqueJustificatifNonVisite =
                ['ko', 'warning'].includes(this.currentVolumeForMap?.valeursParametres[PARAM_VOLUME_VISITE]) && !this.currentVolumeForMap?.justifie;
        }
    }

    /**
     * Calcul la troisième mesure si au moins deux mesures sont présentes
     * Utilisé uniquement si on n'est pas en mesurageUtile => !this.isMesurageUtile
     */
    private calculDoubleFacteur(mesure?: string) {
        // Non réputé loi Carrez ou type habitable
        if (this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.typeNonRepute]) {
            this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[0]] = '0';
            // Index de la mesure qui vient d'être modifié => il faut donc calculer l'autre mesure
            const indexMesure = this.mesurageConfig.propertiesMesurage.listeMesures.findIndex((itMesure) => itMesure === mesure);
            if (indexMesure === 1) {
                this.calculAuSol();
            } else if (indexMesure === 2) {
                this.calculMesureDeux();
            }

            this.cleanFields(this.mesurageConfig.propertiesMesurage.listeMesures[0]);

            // Réputé loi Carrez ou habitable
        } else if (
            this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[0]] &&
            this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[1]]
        ) {
            this.calculAuSol();
        } else if (
            this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[0]] &&
            this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[2]]
        ) {
            this.calculMesureDeux();
        } else if (
            this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[1]] &&
            this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[2]]
        ) {
            this.calculMesureUn();
        }
    }

    /**
     * Calcul la mesure passée en paramètre si les deux autres mesures sont présentes
     * Si aucune mesure n'est passée en paramètre, on calcule la 3ème mesures par rapport aux 2 autres déjà saisie
     * Utilisé uniquement si on n'est pas en mesurageUtile => !this.isMesurageUtile
     */
    private deduireMesure(mesureADeduire: string) {
        const indexMesure = this.mesurageConfig.propertiesMesurage.listeMesures.findIndex((itMesure) => itMesure === mesureADeduire);
        // Non réputé loi Carrez ou type habitable
        if (this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.typeNonRepute]) {
            this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[0]] = '0';
            if (indexMesure === 1) {
                this.calculMesureDeux();
            } else {
                this.calculAuSol();
            }
        } else if (
            indexMesure === 0 &&
            this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[1]] &&
            this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[2]]
        ) {
            this.calculMesureUn();
        } else if (
            indexMesure === 1 &&
            this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[0]] &&
            this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[2]]
        ) {
            this.calculMesureDeux();
        } else if (indexMesure === 2) {
            // Cas de la surface au sol à déduire
            if (
                // Si les 2 autres mesures sont saisies
                this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[0]] &&
                this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[1]]
            ) {
                this.calculAuSol();
                // Si une seule des 2 autres mesures sont saisies, on force la 2ème à 0 pour déduire la surface au sol
            } else if (this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[0]]) {
                this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[1]] = '0';
                this.calculAuSol();
            } else if (this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[1]]) {
                this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[0]] = '0';
                this.calculAuSol();
            } else {
                this.notificationService.warn("Vous devez d'abord saisir une autre surface");
            }
        } else {
            this.notificationService.warn("Vous devez d'abord saisir une autre surface");
        }
    }

    /**
     * Récupère la liste des zones d'un volume si déjà présente
     */
    private getZones() {
        this.zones = [];
        if (this.currentPointDeControleVolume) {
            for (let i = 0; i < this.mesurageConfig.propertiesMesurage.listeMesuresZonesTotales.length; i++) {
                this.zones = this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesuresZonesTotales[i]]
                    ? this.zones.concat({
                          nom: 'Surface ' + this.mesurageConfig.propertiesMesurage.listeMesuresLabels[i],
                          surface:
                              this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesuresZonesTotales[i]],
                          style: this.mesurageConfig.propertiesMesurage.listeMesuresTotales[i].replace(/_/g, '-'),
                      })
                    : this.zones.concat([]);
                this.zones = this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesuresZones[i]]
                    ? this.zones.concat(
                          this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesuresZones[i]]
                      )
                    : this.zones.concat([]);
            }
            if (this.zones.length > 0 && this.currentPointDeControleVolume.valeursParametres[PARAM_VOLUME_VISITE] === undefined) {
                this.currentPointDeControleVolume.valeursParametres[PARAM_VOLUME_VISITE] = 'ok';
                this.currentVisiteChanged('ok');
            }
        }
    }

    /**
     * Nettoie la Map des zones
     */
    private cleanFields(mesure: string) {
        if (this.currentPointDeControleVolume.valeursParametres[mesure + '_zones_total']) {
            delete this.currentPointDeControleVolume.valeursParametres[mesure + '_zones_total'];
        }
        if (this.currentPointDeControleVolume.valeursParametres[mesure + '_zones']) {
            delete this.currentPointDeControleVolume.valeursParametres[mesure + '_zones'];
        }
        this.getZones();
    }

    /**
     * Vérifie si la pièce à contrôler a été non visitée ou partiellement visitée et si la justification est présente
     */
    private computeJustificationNonVisite(volume: Volume) {
        if (volume) {
            this.rulesService.computeJustificationNonVisite(volume, this.currentIntervention.commentaires, () => this.checkValidity());
        }
    }

    /**
     * Calcul pour loi Carrez ou habitable
     */
    private calculMesureUn() {
        this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[0]] = (
            Math.round(
                this.numOr0(Number(this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[2]])) *
                    100 -
                    this.numOr0(Number(this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[1]])) *
                        100
            ) / 100
        ).toString();
        this.cleanFields(this.mesurageConfig.propertiesMesurage.listeMesures[0]);
    }

    /**
     * Calcul pour surface au sol
     */
    private calculAuSol() {
        this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[2]] = (
            Math.round(
                this.numOr0(Number(this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[0]])) *
                    100 +
                    this.numOr0(Number(this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[1]])) *
                        100
            ) / 100
        ).toString();
        this.cleanFields(this.mesurageConfig.propertiesMesurage.listeMesures[2]);
    }

    /**
     * Calcul pour surface hors loi Carrez ou non habitable
     */
    private calculMesureDeux() {
        this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[1]] = (
            Math.round(
                this.numOr0(Number(this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[2]])) *
                    100 -
                    this.numOr0(Number(this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[0]])) *
                        100
            ) / 100
        ).toString();
        this.cleanFields(this.mesurageConfig.propertiesMesurage.listeMesures[1]);
    }

    /**
     * Trouver le diagnostic complémentaire, si existant
     */
    private findDiagnosticComplementaire() {
        const prestationComplementaire = this.currentIntervention.prestationsDiagnostics.find((it) => {
            return (
                it.prestation.typePrestation === this.mesurageConfig.typeMesurageComplementaire && it.etatDiagnostic !== EtatDiagnostic.NON_DEMARRE
            );
        });
        if (prestationComplementaire && prestationComplementaire.idDiagnostic) {
            return this.diagnosticService.findOne(prestationComplementaire.idDiagnostic).pipe(
                tap((d) => {
                    const diagnosticComplementaire = d;
                    if (diagnosticComplementaire?.pointsDeControleBiens?.length) {
                        this.volumesComplementaires = diagnosticComplementaire.pointsDeControleBiens.flatMap((b) => {
                            return b.pointsDeControleNiveaux.flatMap((n) => n.pointsDeControleVolumes);
                        });
                    }
                })
            );
        } else {
            return of(null);
        }
    }

    /**
     * Trouver le volume correspondant dans le diagnostic complémentaire, si existant
     */
    private findVolumeComplementaire() {
        if (this.currentPointDeControleVolume && this.volumesComplementaires && this.volumesComplementaires.length) {
            this.currentVolumeComplementaire = this.volumesComplementaires.find((v) => v.idVolume === this.currentPointDeControleVolume.idVolume);
        }
    }

    /**
     * Si on arrive sur cet onglet via une redirection depuis le récapitulatif ou la conclusion, on set le current bien, niveau et volume
     */
    private getHistory() {
        if (history.state.idBien) {
            const bien = this.currentDiagnostic.pointsDeControleBiens.find((b) => b.idBien === history.state.idBien);
            this.changeCurrentBien(bien.nomBien);
        }
        if (history.state.idNiveau) {
            this.changeCurrentNiveau(history.state.idNiveau);
            if (history.state.idVolume) {
                setTimeout(
                    () => this.changeCurrentVolume(this.currentNiveau.pointsDeControleVolumes.find((v) => v.idVolume === history.state.idVolume)),
                    300
                );
            }
        }
    }

    /**
     * Validité de l'onglet
     */
    private checkValidity() {
        this.checkValidityTabService
            .checkValidityMesure(
                this.currentDiagnostic.pointsDeControleBiens,
                this.currentIntervention.commentaires,
                this.mesurageConfig.propertiesMesurage
            )
            .subscribe((etat) => {
                const code = this.route.snapshot.data['code'];
                this.etatProgressionService.updateDiagnostic(code, etat, this.currentDiagnostic);
                this.progressDiagnosticService.refresh();
            });
    }

    /**
     * Affiche un message en cas d'un mesure négative
     * @private
     */
    private doesVolumeHasNegativeMesures() {
        this.hasNegativeMesure = false;
        if (this.currentPointDeControleVolume) {
            this.hasNegativeMesure =
                (this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[0]] &&
                    !MesurageService.isFilledAndPositive(
                        this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[0]]
                    )) ||
                (this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[1]] &&
                    !MesurageService.isFilledAndPositive(
                        this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[1]]
                    )) ||
                (this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[2]] &&
                    !MesurageService.isFilledAndPositive(
                        this.currentPointDeControleVolume.valeursParametres[this.mesurageConfig.propertiesMesurage.listeMesures[2]]
                    ));
        }
    }

    viewMapEvent(event: any) {
        if (event.toolName === 'SELECTION' && event.value && event.value.length > 0) {
            const selected = event.value[0];
            if (selected.area) {
                const volumeSelected = this.currentNiveau.pointsDeControleVolumes.find((it) => it.spaceId === selected.ID);
                if (volumeSelected) {
                    this.changeCurrentVolume(volumeSelected);
                    // On récupère la surface nette de la pièce (= surface totale sans trémis)
                    this.mesureSuggested = Number(selected.plain_area).toFixed(2);
                } else {
                    this.currentPointDeControleVolume = null;
                    this.mesureSuggested = null;
                }
            } else {
                this.currentPointDeControleVolume = null;
                this.mesureSuggested = null;
            }
        }
    }

    /**
     * Toggle mode liste ou plan
     */
    changeViewMode(value) {
        this.viewMode = value.value;
    }

    private synchroniseMesureAuSolFromFormToMap() {
        if (this.selection.bienBuilding) {
            let relationInterventionBien = this.currentIntervention.relationInterventionBiens.find((it) => it === this.selection.currentRelationBien);
            const jsonPlan = this.mesurageService.synchronizeDeclaredSurfaceFormDiagToBuilding(
                this.currentPointDeControleVolume.valeursParametres[PARAM_AU_SOL],
                this.currentPointDeControleVolume.spaceId,
                this.currentNiveau.storeyId,
                this.selection.bienBuilding,
                relationInterventionBien.bien.jsonPlan
            );
            if (jsonPlan) {
                relationInterventionBien.bien.jsonPlan = jsonPlan;
            }
        }
    }

    /**
     * Charge le bien
     * @private
     */
    static loadBuilding(bien: Bien) {
        if (bien) {
            if (bien && bien.jsonPlan) {
                return cn_building.unserialize(JSON.parse(bien.jsonPlan));
            } else {
                return undefined;
            }
        }
    }

    private changeCurrentRelationBien() {}

    private changeStair(etage: PointDeControleNiveau) {
        this.currentNiveau = etage;
        if (etage.index) {
            this.changeCurrentNiveau(etage.idNiveau);
        }
    }

    /**
     * Met à jour la surface du bien par le total des surfaces au sol calculé
     * @private
     */
    updateSurfaceBien() {
        const bien = this?.selection?.currentRelationBien?.bien;
        const surfaceAuSolTotal = parseFloat(this?.selection?.currentBien?.valeursParametres?.total_au_sol).toFixed(2);

        if (surfaceAuSolTotal) {
            bien.surface = parseFloat(surfaceAuSolTotal);
        }
    }
}
