<div *ngIf="optionPlan && screenshotsSvg?.length > 0">
    <div class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">
        {{ 'Annexes' | uppercase }}
    </div>
    <div>
        <div class="block-subtitle ml-4 my-4">Plans</div>
        <div class="block-content px-5 mb-4">
            <ng-container *ngFor="let screenshot of screenshotsWithSvg">
                <div class="position-relative p-0 mb-2 svg-plan">
                    <div class="bold primary mb-2">{{ screenshot.screenshotSvg.nom }}</div>
                    <div class="svg-preview" [innerHTML]="screenshot.svgPlan | safeHtml"></div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
