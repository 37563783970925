import { Injectable } from '@angular/core';
import { AuthenticationStore } from 'src/app/commons-lib';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UserSpecificService {
    constructor(private readonly authenticationService: AuthenticationStore) {}

    checkIfUserHasAuthorities(listRole: string[]): Observable<boolean> {
        return this.authenticationService.getCurrentUser().pipe(
            map(
                (user) =>
                    !!user?.authorities?.some((it) => {
                        return listRole.includes(it);
                    })
            )
        );
    }
}
