import { Injectable } from '@angular/core';
import { InterventionService } from './intervention.service';
import { EtatProgression, EtatValidation } from '../model/etat-progression.model';
import { Intervention } from '../model/intervention.model';
import { Diagnostic } from '../model/diagnostic.model';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { DiagnosticService } from './diagnostic.service';
import { CnSpinnerService } from '../modules/shared/cn-spinner/service/cn-spinner.service';
import { BehaviorSubject, combineLatest, of } from 'rxjs';

export type Mode = 'intervention' | 'diagnostic' | '';

@Injectable({
    providedIn: 'root',
})
export class EtatProgressionService {
    private mode: BehaviorSubject<Mode> = new BehaviorSubject<Mode>('');
    private refresh = new BehaviorSubject<string>('');
    public etatProgressions$ = combineLatest([this.mode.asObservable(), this.refresh.asObservable()]).pipe(
        switchMap(([mode]) => {
            switch (mode) {
                case 'diagnostic':
                    return combineLatest([this.interventionService.getCurrentIntervention(), this.diagnosticService.getCurrentDiagnostic()]).pipe(
                        map(([i, d]) => i.etatProgressions.concat(d.etatProgressions))
                    );
                case 'intervention':
                    return this.interventionService.getCurrentIntervention().pipe(map((i) => i.etatProgressions));
                case '':
                    return of([]);
            }
        }),
        shareReplay({ bufferSize: 1, refCount: true })
    );
    constructor(
        private interventionService: InterventionService,
        private diagnosticService: DiagnosticService,
        private cnSpinnerService: CnSpinnerService
    ) {}

    setMode(mode: 'intervention' | 'diagnostic' | '') {
        this.mode.next(mode);
    }

    updateIntervention(code: string, etat: EtatValidation, intervention: Intervention, saveInterventionIsNedded: boolean = true) {
        if (!intervention.etatProgressions) {
            intervention.etatProgressions = [];
        }
        if (this.updateProgressionAndRefresh(code, etat, intervention.etatProgressions)) {
            if (saveInterventionIsNedded) {
                this.saveIntervention(intervention);
            }
        }
    }

    updateDiagnostic(code: string, etat: EtatValidation, diagnostic: Diagnostic, forceUpdate: Boolean = false) {
        this.updateDiagnosticMultipleCode(new Map([[code, etat]]), diagnostic, forceUpdate);
    }

    updateDiagnosticMultipleCode(mapEtatByCode: Map<string, EtatValidation>, diagnostic: Diagnostic, forceUpdate: Boolean = false) {
        if (!diagnostic.etatProgressions) {
            diagnostic.etatProgressions = [];
        }

        let updateNecessary = false;
        for (const key of mapEtatByCode.keys()) {
            updateNecessary = updateNecessary || this.updateProgressionAndRefresh(key, mapEtatByCode.get(key), diagnostic.etatProgressions);
        }

        if (updateNecessary || forceUpdate) {
            this.saveDiagnostic(diagnostic);
        }
    }

    /**
     * Permet de supprimer un code de l'état de progression
     * @param code
     * @param etatProgressions
     */
    deleteCodeProgression(code: string, etatProgressions: EtatProgression[]) {
        if (code) {
            const indexEtatProgression = etatProgressions.findIndex((etatP) => etatP.code === code);
            if (indexEtatProgression && indexEtatProgression >= 0) {
                etatProgressions.splice(indexEtatProgression, 1);
            }
        }
    }

    private updateProgressionAndRefresh(code: string, etat: EtatValidation, etatProgressions: EtatProgression[]): boolean {
        let updated = true;
        if (code && etat) {
            const etatProgress = etatProgressions.find((etatP) => etatP.code === code);
            if (etatProgress) {
                updated = etatProgress.etat !== etat;
                etatProgress.etat = etat;
            } else {
                etatProgressions.push(new EtatProgression(code, etat));
            }
        } else {
            updated = false;
        }

        return updated;
    }

    private saveIntervention(intervention: Intervention) {
        this.cnSpinnerService
            .withSpinner(this.interventionService.updateIntervention(intervention), 'Mise à jour de la progression...')
            .pipe(tap(() => this.refresh.next('')))
            .subscribe();
    }

    private saveDiagnostic(diagnostic: Diagnostic) {
        this.cnSpinnerService
            .withSpinner(
                this.interventionService.getCurrentIntervention().pipe(
                    take(1),
                    switchMap((intervention) => {
                        return this.diagnosticService.upsert(intervention, diagnostic);
                    })
                ),
                'Mise à jour de la progression...'
            )
            .pipe(
                tap(() => {
                    this.refresh.next('');
                })
            )
            .subscribe();
    }
}
