import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'includes',
})
export class IncludesPipe implements PipeTransform {
    transform(array: any[], value: any): boolean {
        return array && array.includes(value);
    }
}
