import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { PolluantConfig } from '../../../../../model/polluant-config.model';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { Polluant } from '../../../../../model/polluant.model';
import { MpcaEchantillonnage, MpcaItemOld, MpcaMetopOld, MpcaPendantTravauxOld, MpcaProcessus } from '../../../../../model/mpca.model.old';
import { MpcaService } from '../../../../../services/mpca.service';
import { MpcaListingComponent } from '../../mpca-listing/mpca-listing.component';

@Component({
    selector: 'app-mpca-processus-modal',
    templateUrl: './mpca-processus-modal.component.html',
    styleUrls: ['./mpca-processus-modal.component.scss'],
})
export class MpcaProcessusModalComponent extends MpcaListingComponent implements OnInit {
    polluantConfig: PolluantConfig;
    diagnostic: Diagnostic;
    diagnosticPolluant: Polluant;
    selectedProcessus: any;
    selectedMpca: MpcaItemOld;
    processusForm: FormGroup;
    indexProcessus: number;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<MpcaProcessusModalComponent>,
        private mpcaFormService: MpcaService,
        injector: Injector
    ) {
        super(injector);
        this.polluantConfig = data.polluantConfig;
        this.selectedMpca = data.selectedMpca;
        this.selectedProcessus = data.selectedProcessus;
        this.diagnostic = data.diagnostic;
        this.indexProcessus = data.indexProcessus;
    }

    ngOnInit(): void {
        this.diagnosticPolluant = this.diagnostic.contenuDiagnostic as Polluant;

        //Création d'un nouveau processus
        if (this.selectedProcessus == undefined) {
            this.selectedProcessus = new MpcaProcessus();
        } else {
            if (this.selectedProcessus.metop == undefined) {
                this.selectedProcessus.metop = new MpcaMetopOld();
            }
            if (this.selectedProcessus.pendantTravaux == undefined) {
                this.selectedProcessus.pendantTravaux = new MpcaPendantTravauxOld();
            }
            if (this.selectedProcessus.echantillonnage == undefined) {
                this.selectedProcessus.echantillonnage = new MpcaEchantillonnage();
            }
        }
        this.initForm();
    }

    /**
     * @description initialisation du formulaire
     * @private
     */
    private initForm() {
        // Boucle sur la liste de MPCA existante

        this.processusForm = this.formBuilder.group({
            id: [this.selectedProcessus.id],
            nom: [this.selectedProcessus.nom, Validators.required],
            primary: [this.selectedProcessus.primary],
            taches: new FormArray([]),
            metop: this.formBuilder.group({
                methode: [this.selectedProcessus.metop.methode, Validators.required],
                technique: [this.selectedProcessus.metop.technique],
                outil: [this.selectedProcessus.metop.outil],
                autresOutils: [this.selectedProcessus.metop.autresOutils],
                aspiration: [this.selectedProcessus.metop.aspiration],
                travailHumide: [this.selectedProcessus.metop.travailHumide],
            }),
            pendantTravaux: this.formBuilder.group({
                niveauEmpoussierement: [this.selectedProcessus.pendantTravaux.niveauEmpoussierement, Validators.required],
                typeMasqueDesamianteur: [this.selectedProcessus.pendantTravaux.typeMasqueDesamianteur, Validators.required],
                concentrationFi: [this.selectedProcessus.pendantTravaux.concentrationFi, Validators.required],
            }),
            echantillonnage: this.formBuilder.group({
                concentrationAttendue: [this.selectedProcessus.echantillonnage.concentrationAttendue],
                dureeRepresentativeProcessus: [this.selectedProcessus.echantillonnage.dureeRepresentativeProcessus],
                dureeSaturationPoussieres: [this.selectedProcessus.echantillonnage.dureeSaturationPoussieres],
                debitPrelevement: [this.selectedProcessus.echantillonnage.debitPrelevement],
                fractionFiltre: [this.selectedProcessus.echantillonnage.fractionFiltre],
            }),
        });

        console.log(this.selectedMpca);

        // Si un ou plusieurs processus sont present, on va y ajouter ce processus
        // A travers un formulaire de group
        this.selectedProcessus.taches.forEach((tache) => {
            this.getProcessus(this.processusForm).push(this.mpcaFormService.getTacheGroup(tache));
        });

        // Lorsqu'on a un update sur un champ du formulaire, on va le valider puis sauvegarder en db
        this.processusForm.statusChanges.pipe().subscribe(() => {
            //this.saveDiagnostic(); // Sauvegarde des nouvelles données du form dans le diagnostic
        });
    }

    /**
     * @description Remplissage du « Niveau d’empoussièrement » selon la valeur saisie dans « Concentration attendue ».
     * Niveau 1 : empoussièrement dont la valeur est inférieure à 100 fibres par litre ;
     * Niveau 2 : empoussièrement dont la valeur est supérieure ou égale à 100 fibres par litre et inférieure à 6 000 fibres par litre
     * Niveau 3 : empoussièrement dont la valeur est supérieure ou égale à 6 000 fibres par litre et inférieure à 25 000 fibres par litre.
     */
    public onGeneralDataChange() {
        try {
            const formEchantillonnage = this.processusForm.get('echantillonnage');
            const formPendantTravaux = this.processusForm.get('pendantTravaux');
            const concentrationAttendue = formEchantillonnage.get('concentrationAttendue').value;
            switch (true) {
                case concentrationAttendue < 100:
                    return formPendantTravaux.get('niveauEmpoussierement').setValue('Niveau 1');
                case concentrationAttendue < 6_000:
                    return formPendantTravaux.get('niveauEmpoussierement').setValue('Niveau 2');
                case concentrationAttendue < 25_000:
                    return formPendantTravaux.get('niveauEmpoussierement').setValue('Niveau 3');
            }
        } catch (e) {
            console.error(e);
        }
    }
    /**
     * @description Sauvegarde des nouvelles données du formulaire dans le diagnostic
     * @private
     */
    public saveDiagnostic() {
        let totalErrors = 0;

        this.processusForm.markAllAsTouched();
        Object.keys(this.processusForm.controls).forEach((key) => {
            const controlErrors: ValidationErrors = this.processusForm.get(key).errors;
            if (controlErrors != null) {
                totalErrors++;
            }
        });

        if (totalErrors > 0) {
            return;
        }

        this.dialogRef.close({ value: this.processusForm, index: this.indexProcessus, action: 'save' });
    }

    /**
     * @description Annule les changement courant et ferme la modale
     */
    public cancel() {
        this.dialogRef.close({ action: 'cancel' });
    }

    /**
     * @description Ferme la modale et supprime le processus
     */
    public delete() {
        this.dialogRef.close({ action: 'delete', index: this.indexProcessus });
    }

    /**
     * Get le formulaire tableau root mpca
     */
    get mpca(): FormArray {
        return this.processusForm.get('descriptifList') as FormArray;
    }

    /**
     * Get le formulaire tableau processus
     * @param form
     * @private
     */
    private getProcessus(form: FormGroup | AbstractControl): FormArray {
        return form.get('taches') as FormArray;
    }
}
