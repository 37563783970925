'use strict';
//***********************************************************************************
//***********************************************************************************
//**** A handler to create a polygon freeform
//***********************************************************************************
//***********************************************************************************

import { cn_clone, cn_dist, cn_triangle_area, cnx_add, cnx_clone, cnx_mul } from '../utils/cn_utilities';
import { cn_event_handler } from './cn_event_handler';
import { fh_polygon } from '@acenv/fh-3d-viewer';
import { cn_mouse_event } from './cn_mouse_event';

/**
 * Events :
 * - "start_creation" : called on creation handler, when creation has started (becomes modal).
 * - "end_creation" : called when creation is over.
 * - "change" : called whenever the shape changes (creation or edition)
 */
export class cn_freeform_handler extends cn_event_handler {
    /**
     * Constructor
     * @param {number} pixel_precision If empty list, this handler will be a construction handler.
     * @param {number} angular_precision
     */
    constructor(pixel_precision = 20, angular_precision = 15) {
        super();
        this.vertices = [];
        this.pixel_precision = pixel_precision;
        this.anguar_precision = angular_precision;
        this.cursor = null;

        this.allow_creation = null;
        this.allow_freeform = null;
        this._active = false;

        this.creation_storey = null;

        this._plane_point = [];
        this._plane_normal = [];
    }

    //*****************************************************************
    //*** is polygon currently creating ?
    //*****************************************************************
    is_creating() {
        return (this.vertices.length >= 2);
    }

    //*****************************************************************
    //*** Draw the handler
    //*****************************************************************
    draw(camera) {
        var html = '';
        if (!this._active) return html;

        if (this.cursor) {
            var p = camera.world_to_screen(this.cursor);
            html += '<circle class=\'handle_vertex selected\' cx=\'' + p[0] + '\' cy=\'' + p[1] + '\' r=\'5\'/>';
            html += camera.draw_move_arrow(this.cursor, 'selected');
        }

        //*** draw contour
        html += '<path class=\'handle_outline\' d=\'';
        for (var i = 0; i < this.vertices.length; i++) {
            var p = camera.world_to_screen(this.vertices[i]);
            if (i == 0) html += 'M';
            else html += 'L';
            html += ' ' + p[0] + ' ' + p[1] + ' ';
        }
        html += '\' />';

        return html;
    }

    //*****************************************************************
    //*** Clear move data
    //*****************************************************************
    clear_move() {
        this._active = false;
        this.cursor = null;
    }

    /**
     * Manage a passive move. To return 'true' if something of interest under the mouse.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
    move(mouse_event) {
        this.clear_move();

        if (this.vertices.length == 0) {
            //*** In 3D, we need to have something under the mouse */
            if (mouse_event.camera.is_3d() && (!mouse_event.impact || mouse_event.impact.storey_element == null))
                return false;

            if (this.allow_creation && !this.allow_creation(mouse_event))
                return false;

            this._active = true;
            if (mouse_event.camera.is_3d())
                this.cursor = cnx_clone(mouse_event.impact.position);
            else
                this.cursor = cn_clone(mouse_event.mouse_world);

            if (mouse_event.camera.is_3d()) {
                this._plane_normal = cnx_clone(mouse_event.impact.normal);
                this._plane_point = cnx_add(cnx_clone(mouse_event.impact.position), cnx_mul(this._plane_normal, 0.01));
                this.creation_storey = mouse_event.impact.storey_element.storey;
            } else {
                this._plane_normal = [0, 0, 1];
                this._plane_point = cnx_clone(mouse_event.mouse_world);
                this.creation_storey = mouse_event.storey;
            }
            this.call('change', mouse_event);
            return true;
        }
        return true;
    }

    /**
     * Manage a grab. To return 'true' if grab is to be managed.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
    grab(mouse_event) {
        if (this.vertices.length != 0)
            return false;

        //*** In 3D, we need to have something under the mouse */
        if (mouse_event.camera.is_3d() && (!mouse_event.impact || mouse_event.impact.storey_element == null))
            return false;

        if (this.allow_creation && !this.allow_creation(mouse_event)) {
            return false;
        }

        this._creation = true;
        if (mouse_event.camera.is_3d()) {
            this._plane_normal = cnx_clone(mouse_event.impact.normal);
            this._plane_point = cnx_add(cnx_clone(mouse_event.impact.position), cnx_mul(this._plane_normal, 0.01));
            this.creation_storey = mouse_event.impact.storey_element.storey;
            this.vertices.push(this._plane_point);
        } else {
            this._plane_normal = [0, 0, 1];
            this._plane_point = cnx_clone(mouse_event.mouse_world);
            this.creation_storey = mouse_event.storey;
            this.vertices.push(this._plane_point);
        }
        this.call('start_creation', mouse_event);
        this.call('change', mouse_event);
        return true;
    }

    /**
     * Manage a drop. Only after a grab that returned true, and at least one drag. To return 'true' if drop had an effect.
     * @param {cn_mouse_event} mouse_event
     * @returns {boolean}
     */
    drop(mouse_event) {
        if (!this._creation) return false;

        this._creation = false;

        this._filter_contour(mouse_event.camera);
        this.call('end_creation', mouse_event);
        return true;
    }

    /**
     * Manage a click. Only after a grab that returned true, and at least one drag. To return 'true' if drop had an effect.
     * @param {cn_mouse_event} mouse_event
     * @returns {boolean}
     */
    click(mouse_event) {
        return this.drop(mouse_event);
    }

    /**
     * Manage a drag. Only after a grab that returned true. To return 'true' if drag had an effect.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
    drag(mouse_event) {
        const freeform_allowed = !this.allow_freeform || this.allow_freeform();
        if (!this._creation || !freeform_allowed) return false;

        //*** expect a minimum displacement for the first time */
        if (this.vertices.length > 1 || cn_dist(mouse_event.camera.world_to_screen(this.vertices[0]), mouse_event.mouse_screen) > this.pixel_precision) {
            if (mouse_event.camera.is_3d())
                mouse_event.move_to_plane(this._plane_point, this._plane_normal);
            this.vertices.push(cnx_clone(mouse_event.mouse_world));
        }

        this.call('change', mouse_event);
        return true;
    }

    _filter_contour(camera) {
        if (this.vertices.length == 0)
            return;
        var v0 = this.vertices[0];
        if (this.vertices.length < 2) {
            this.vertices = [v0];
            return;
        }
        const area_threshold = this.pixel_precision * this.pixel_precision / (30 * camera.world_to_screen_scale * camera.world_to_screen_scale);
        for (var i = 0; i < this.vertices.length; i++) {
            var p0 = this.vertices[i];
            var p1 = this.vertices[(i + 1) % this.vertices.length];
            var p2 = this.vertices[(i + 2) % this.vertices.length];

            var area = cn_triangle_area(p0, p1, p2);
            if (area < area_threshold) {
                this.vertices.splice((i + 1) % this.vertices.length, 1);
                i--;
                continue;
            }
        }

        //*** Build a polygon out of it, to check for the orientation */
        var polygon = new fh_polygon(this._plane_point, this._plane_normal);
        polygon.add_contour(this.vertices);
        polygon.compute_contours();
        this.vertices = [];
        if (polygon.contour_sizes.length > 0) {
            for (var i = 0; i < polygon.contour_sizes[0]; i++) {
                this.vertices.push(polygon.contour_vertices[i]);
            }
        }
        this.vertices.reverse();
        if (this.vertices.length < 3)
            this.vertices = [v0];
    }
}

