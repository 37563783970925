import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-duplication',
    templateUrl: './dialog-duplication.component.html',
})
export class DialogDuplicationComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<DialogDuplicationComponent>) {}

    choiceSelected: string;
    zones: string[] = [];

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth600-dialog');
    }

    onValider() {
        if (this.choiceSelected) {
            this.dialogRef.close(this.choiceSelected);
        }
    }
}
