import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { PolluantPrelevement } from 'src/app/modules/diagnostics/polluant/model/polluant-prelevement.model';
import { WidgetSelectModeEnum } from 'src/app/modules/shared/widget-select/widget-select.component';

@Component({
    selector: 'app-zone-form-metop',
    templateUrl: './zone-form-metop.component.html',
    styleUrls: ['./zone-form-metop.component.scss'],
})
export class ZoneFormMetopComponent implements OnInit {
    @Input()
    formMetop: FormGroup | AbstractControl;

    @Input()
    polluantConfig: any;

    @Input() // Utilisé pour passer les champs en read-only
    disabled: Boolean;

    @Input()
    contenuDiagnostic: PolluantPrelevement;

    widgetSelectModeEnum: typeof WidgetSelectModeEnum = WidgetSelectModeEnum;

    ngOnInit(): void {}

    /**
     * @description Fonction appellée par chaque élément du menu déroulant Objectif de mesurage
     *              afin de définir si il doit être désactivé ou non en fonction des choix précédents
     * @param item
     */
    isItemDisabled(item) {
        const formValues = this.formMetop.value.objectifsMesurage;

        //Si rien n'est sélectionné, tous les items sont actifs
        if (formValues.length == 0) {
            return false;
        }

        //Tous les items qui sont cochés restent actifs afin de pouvoir être décochés
        if (formValues.indexOf(item) >= 0) {
            return false;
        }

        let selectedCsp = formValues.filter((value) => value.domaineReglementaire == 'CODE_SANTE_PUBLIQUE')[0];
        //Si une valeure CSP est déjà sélectionnée, alors tous les autres éléments sont desactivés
        // sauf celui qui est déjà sélectionné afin de pouvoir le décocher
        if (selectedCsp != undefined) {
            return true;
        }

        //Tous les items CSP sont désactivés si ils ne vérifient aucune des conditions précédentes
        if (item.domaineReglementaire == 'CODE_SANTE_PUBLIQUE') {
            return true;
        }
    }
}
