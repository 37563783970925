import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PointDeControleBien, PointDeControleNiveau, PointDeControleVolume } from '../../../model/point-de-controle.model';
import { Bien } from '../../../model/bien.model';
import { BienService } from '../../../services/bien.service';
import { PARAM_VOLUME_VISITE } from '../../../shared/constants/cndiag.constants';

@Component({
    selector: 'app-checkpoint-sidebar',
    templateUrl: './checkpoint-sidebar.component.html',
    styleUrls: ['./checkpoint-sidebar.component.scss'],
})
export class CheckpointSidebarComponent implements OnInit {
    // PARAM volume
    readonly paramVolumeVisite = PARAM_VOLUME_VISITE;
    /**
     * Hauteur du header de l'accordeon
     */
    customCollapsedHeight = '40px';
    customExpandedHeight = '40px';

    /**
     * Biens
     */
    /** Liste des biens */
    biens: Bien[];
    /** Bien courant (Point de controle) */
    currentBien: PointDeControleBien;
    /** Nom du bien courant à afficher */
    currentBienToDisplay: string;

    /**
     * Étages
     */
    /** Liste des noms de biens */
    stairs: string[];
    /** Etage courant (Point de controle) */
    currentStair: PointDeControleNiveau;
    /** Nom de l'étage courant */

    /**
     * Pièces
     */
    // expandedRoom: string;
    selectedRoom: string;

    /**
     * Control class css 'piece-empty'
     */
    @Input()
    controlPieceEmpty: boolean = true;
    /**
     * Gestion différente pour l'onglet de pièce sans équipement
     */
    @Input()
    pieceSansEquipement = false;

    /**
     * Gestion multiple des pièces
     */
    @Input()
    multipleSelection = false;

    @Input()
    bienSelected: PointDeControleBien;

    @Input()
    niveauSelected: PointDeControleNiveau;

    /**
     * Formulaire de points de contrôles pour l'ensemble des biens
     */
    @Input()
    formPointDeControle: PointDeControleBien[];
    /**
     * Liste d'ID de point de controle de volume.
     * Cette liste est utilisé pour la gestion multiple des volumes/pieces
     * Cette liste est uniquement utilisée si multipleSelection est à true
     */
    @Input()
    listVolumeId: string[];

    @Output()
    currentBienChange = new EventEmitter<PointDeControleBien>();

    @Output()
    currentStairChange = new EventEmitter<PointDeControleNiveau>();

    @Output()
    currentRoomChange = new EventEmitter<PointDeControleVolume>();

    @Output()
    currentListRoomIdChange = new EventEmitter<string[]>();

    @Input()
    isInEditMode: Boolean = true;

    constructor(private bienService: BienService) {}

    ngOnInit(): void {
        // biens
        this.biens = this.bienService.filterBienFromCheckPoint(this.formPointDeControle);
        if (this.bienSelected && this.bienSelected.nomBien) {
            this.currentBien = this.bienSelected;
        } else {
            this.currentBien = this.formPointDeControle[0];
        }
        this.currentBienToDisplay = this.currentBien.nomBien;
        // étages
        this.getStairs();

        if (history.state.idBien) {
            this.currentBien = this.formPointDeControle.find((b) => b.idBien === history.state.idBien);
            this.currentBienToDisplay = this.currentBien.nomBien;
        }
        if (history.state.idNiveau) {
            this.currentStair = this.currentBien.pointsDeControleNiveaux.find((lvl) => lvl.idNiveau === history.state.idNiveau);
        } else if (this.pieceSansEquipement) {
            this.currentStairChange.emit(this.currentStair);
        }
        if (history.state.idVolume) {
            this.changeCurrentRoom(this.currentStair.pointsDeControleVolumes.find((v) => v.idVolume === history.state.idVolume));
        }
    }

    changeCurrentBien(bien: string) {
        this.currentBien = this.getPointDeControleAssainissementBienFromName(bien);
        this.getStairs(false);
        this.currentBienChange.emit(this.currentBien);
        this.currentStairChange.emit(this.currentStair);
    }

    changeCurrentStair(stair: any) {
        this.currentStair = this.getPointDeControleAssainissementEtageFromId(stair);
        this.selectedRoom = '';
        this.currentStairChange.emit(this.currentStair);
    }

    changeCurrentRoom(piece: PointDeControleVolume) {
        if (!this.multipleSelection) {
            this.selectedRoom = piece.idVolume;
            this.currentRoomChange.emit(piece);
        } else {
            const indexSelectedRoom = this.getIndexSelectedRoom(piece.idVolume);
            if (indexSelectedRoom >= 0) {
                this.listVolumeId.splice(indexSelectedRoom, 1);
            } else {
                this.listVolumeId.push(piece.idVolume);
            }
            this.currentListRoomIdChange.emit(this.listVolumeId);
        }
    }

    /**
     * Event au click sur "Tout sélectionner"
     * Si toutes les pièces sont sélectionnées alors on déselectionne Tout
     * Si non on sélectionne que les pièces non sélectionnées
     * @param {boolean} unCheckedAll
     */
    public changeSelectAll(unCheckedAll: boolean) {
        for (const piece of this.currentStair.pointsDeControleVolumes) {
            if (unCheckedAll ? true : this.getIndexSelectedRoom(piece.idVolume) < 0) {
                this.changeCurrentRoom(piece);
            }
        }
    }

    /**
     * Vérifie si tous les volumes du niveau actuel sont sélectionnés
     */
    public checkAllCheckedInNiveau() {
        let r = true;
        this.currentStair.pointsDeControleVolumes.forEach((pdcVolume) => {
            if (this.listVolumeId.indexOf(pdcVolume.idVolume) == -1) {
                r = false;
            }
        });

        return r;
    }

    private getStairs(withNiveauSelected: boolean = true) {
        this.stairs = this.currentBien.pointsDeControleNiveaux.map((e) => e.nom);
        if (this.niveauSelected && this.niveauSelected.nom && withNiveauSelected) {
            this.currentStair = this.niveauSelected;
        } else {
            this.currentStair = this.currentBien.pointsDeControleNiveaux[0];
        }
        this.selectedRoom = '';
    }

    private getPointDeControleAssainissementBienFromName(bien): PointDeControleBien {
        return this.formPointDeControle.find((b) => b.nomBien == bien);
    }

    private getPointDeControleAssainissementEtageFromId(etage): PointDeControleNiveau {
        return this.currentBien.pointsDeControleNiveaux.find((e) => e.idNiveau === etage.id);
    }

    private getIndexSelectedRoom(roomId: string) {
        return this.listVolumeId.findIndex((x) => x === roomId);
    }
}
