<div class="print-param-content p-1">
    <!--    &lt;!&ndash; Affichage des paramètres pour l'impresion PDF &ndash;&gt;-->
    <!--    <mat-accordion *ngIf="page">-->
    <!--        <mat-expansion-panel [expanded]="true">-->
    <!--            <mat-expansion-panel-header>-->
    <!--                <mat-panel-title>Page</mat-panel-title>-->
    <!--                <mat-panel-description></mat-panel-description>-->
    <!--            </mat-expansion-panel-header>-->

    <!--            <div>-->
    <!--                <mat-form-field>-->
    <!--                    <mat-label>Dimensions</mat-label>-->
    <!--                    <mat-select [(value)]="selectedSize" (selectionChange)="onSizeChange($event)">-->
    <!--                        <mat-option *ngFor="let size of pageSizes" [value]="size.value">-->
    <!--                            {{size.label}}-->
    <!--                        </mat-option>-->
    <!--                    </mat-select>-->
    <!--                </mat-form-field>-->
    <!--            </div>-->

    <!--            <div>-->
    <!--                <mat-form-field>-->
    <!--                    <mat-label>Orientation</mat-label>-->
    <!--                    <mat-select [(value)]="selectedOrientation" (selectionChange)="onOrientationChange($event)">-->
    <!--                        <mat-option *ngFor="let orientation of orientations" [value]="orientation.value">-->
    <!--                            {{orientation.label}}-->
    <!--                        </mat-option>-->
    <!--                    </mat-select>-->
    <!--                </mat-form-field>-->
    <!--            </div>-->

    <!--            <div>-->
    <!--                <lib-increment-input [label]="'Marges (cm)'"-->
    <!--                                     [(value)]="selectedMargin" [min]="0" [max]="9" [step]="0.1"-->
    <!--                                     (valueChangeFromUser)="onMarginChange()"></lib-increment-input>-->
    <!--            </div>-->

    <!--            <div class="mat-caption">Les paramètres seront appliqués à toutes les pages</div>-->

    <!--        </mat-expansion-panel>-->

    <!--        <mat-expansion-panel *ngIf="page && page.storeyId">-->
    <!--            <mat-expansion-panel-header>-->
    <!--                <mat-panel-title>Plan</mat-panel-title>-->
    <!--                <mat-panel-description></mat-panel-description>-->
    <!--            </mat-expansion-panel-header>-->

    <!--            <h4 class="my-2">Général</h4>-->

    <!--            <ng-container *ngTemplateOutlet="svgParameters"></ng-container>-->

    <!--        </mat-expansion-panel>-->
    <!--    </mat-accordion>-->

    <!-- Affichage des paramètres pour l'export SVG -->
    <div>
        <h4 class="my-2">Paramètres du plan</h4>

        <ng-container *ngTemplateOutlet="svgParameters"></ng-container>
    </div>
</div>

<ng-template #svgParameters>
    <div>
        <lib-increment-input
            [label]="'Echelle (1/)'"
            [(value)]="svgParameter.scale"
            [min]="0"
            [step]="1"
            [disabled]="svgParameter.fixedScale"
            (valueChangeFromUser)="onScaleChange()"
        ></lib-increment-input>
    </div>

    <div>
        <mat-form-field>
            <mat-label>Résolution (ppp)</mat-label>
            <mat-select [(value)]="svgParameter.resolution" (selectionChange)="onResolutionChange()">
                <mat-option *ngFor="let resolution of resolutions" [value]="resolution.value">
                    {{ resolution.label }} ({{ resolution.value }}ppp)
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div>
        <mat-checkbox [checked]="svgParameter.fixedScale" (change)="onFixedScaleChange($event)"
            >Bloquer l'échelle</mat-checkbox
        >
    </div>

    <div>
        <mat-checkbox [checked]="svgParameter.fixedPosition" (change)="onFixedPositionChange($event)"
            >Centrer la carte</mat-checkbox
        >
    </div>

    <div>
        <button
            mat-button
            color="primary"
            [disabled]="svgParameter.fixedPosition || svgParameter.fixedScale"
            (click)="onCenterViewChange($event)"
        >
            <mat-icon>center_focus_strong</mat-icon>
            <span>Adapter à la page</span>
        </button>
    </div>

    <ng-container *ngFor="let svgParam of svgParameter.svgDefaultParams">
        <div *ngIf="svgParam.code === 'display_elements'">
            <!-- Groupe de paramètres -->
            <h4 class="my-2">{{ svgParam.label }}</h4>
            <div *ngFor="let svgChildParam of svgParam.children">
                <app-export-print-svg-param
                    [svgParam]="svgChildParam"
                    [currentValue]="svgParameter.svgParamValues[svgChildParam.code]"
                    (paramChange)="onPlanParamChange($event)"
                ></app-export-print-svg-param>
            </div>
        </div>
        <div *ngIf="svgParam.code !== 'display_elements'">
            <!-- Paramètre -->
            <app-export-print-svg-param
                [svgParam]="svgParam"
                [currentValue]="svgParameter.svgParamValues[svgParam.code]"
                (paramChange)="onPlanParamChange($event)"
            ></app-export-print-svg-param>
        </div>
    </ng-container>
</ng-template>
