//export type SyncState = SyncState.Online | SyncState.PushInProgess | SyncState.Offline;

import { InjectionToken } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum SyncState {
    Online = 'Online',
    PushInProgess = 'PushInProgess',
    Offline = 'Offline',
}

export const SYNC_STATE = new InjectionToken<BehaviorSubject<SyncState>>(SyncState.Online);
