import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-orientation-indicator',
    templateUrl: './orientation-indicator.component.html',
    styleUrls: ['./orientation-indicator.component.scss'],
})
export class OrientationIndicatorComponent {
    @Input()
    orientation: string;

    @Input()
    precisionOrientation: string;

    @Input()
    valid: boolean = true;

    constructor() {}
}
