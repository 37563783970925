import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { takeUntil } from 'rxjs/operators';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { Intervention } from 'src/app/model/intervention.model';
import { BienData, InterventionReportData, IReportComponent, ReportBlockData } from 'src/app/model/rapport.model';
import { ScreenshotSvg } from 'src/app/model/screenshot-svg.model';
import { AssainissementReportData } from 'src/app/modules/diagnostics/assainissement/model/assainissement.model';
import { DiagnosticService } from 'src/app/services/diagnostic.service';
import { HapReportData } from '../../../../diagnostics/hap/model/hapData.model';
import { MesurageReportData } from '../../../../diagnostics/mesurage/model/mesurage.model';
import { TypePrestationEnum } from 'src/app/model/type-prestation.model';

@Component({
    selector: 'app-plans-block',
    templateUrl: './plans-block.component.html',
    styleUrls: ['./plans-block.component.scss'],
})
export class PlansBlockComponent extends BaseComponent implements OnInit, IReportComponent {
    @Input() data: ReportBlockData;
    @Input() intervention: Intervention;
    @Input() interReportData: InterventionReportData;
    @Input() screenshotsWithSvg: { screenshotSvg: ScreenshotSvg; svgPlan: string }[];

    biensData: BienData[];
    optionPlan = true;
    screenshotsSvg: ScreenshotSvg[] = [];

    private diagnostic: Diagnostic;
    private diagnosticData: any;

    constructor(private diagnosticService: DiagnosticService) {
        super();
    }

    ngOnInit(): void {
        this.diagnosticService
            .getCurrentDiagnostic()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((diag) => {
                this.diagnostic = diag;
                if (this.interReportData) {
                    if (this.diagnostic.typePrestation === TypePrestationEnum.ASSAINISSEMENT) {
                        this.diagnosticData = this.interReportData.diagnostics.find(
                            (dia) => dia.typePrestation === this.diagnostic.typePrestation
                        ) as AssainissementReportData;

                        if (this.diagnosticData) {
                            this.optionPlan = this.diagnosticData.optionPlan;
                            if (this.optionPlan) {
                                this.screenshotsSvg = this.diagnosticData.screenshotsPlan;
                            }
                        }
                    } else if (
                        [TypePrestationEnum.MESURAGE, TypePrestationEnum.MESURAGE_HABITABLE, TypePrestationEnum.MESURAGE_UTILE].includes(
                            this.diagnostic.typePrestation
                        )
                    ) {
                        this.diagnosticData = this.interReportData.diagnostics.find(
                            (dia) => dia.typePrestation === this.diagnostic.typePrestation
                        ) as MesurageReportData;

                        if (this.diagnosticData) {
                            this.optionPlan = this.diagnosticData.optionPlan;
                            if (this.optionPlan) {
                                this.screenshotsSvg = this.diagnosticData.screenshotsPlan;
                            }
                        }
                    } else if (
                        [
                            TypePrestationEnum.HAP_VALIDATION,
                            TypePrestationEnum.HAP_ETUDE_SITUATION,
                            TypePrestationEnum.HAP_VISITE_RECONNAISSANCE,
                            TypePrestationEnum.HAP_TERRAIN,
                            TypePrestationEnum.HAP_VALIDATION_RESULTATS,
                        ].includes(this.diagnostic.typePrestation)
                    ) {
                        this.diagnosticData = this.interReportData.diagnostics.find(
                            (dia) => dia.typePrestation === this.diagnostic.typePrestation
                        ) as HapReportData;

                        this.screenshotsSvg = this.diagnosticData.screenshotsPlan;
                    }
                }
            });
    }
}
