import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-button-togle-group',
    templateUrl: './buttons-togle-group.component.html',
    styleUrls: ['./buttons-togle-group.component.scss'],
})
export class ButtonsTogleGroupComponent implements OnInit, OnChanges {
    optionsAll = [
        { icon: 'transform', selected: false, type: AdditionnalAreaType.SURFACE_TRAVAIL },
        { icon: 'compare', selected: false, type: AdditionnalAreaType.SURFACE_INTERFACE },
        { icon: 'straighten', selected: false, type: AdditionnalAreaType.LONGUEUR_MAX },
    ];
    optionsNoLongMax = [
        { icon: 'transform', selected: false, type: AdditionnalAreaType.SURFACE_TRAVAIL },
        { icon: 'compare', selected: false, type: AdditionnalAreaType.SURFACE_INTERFACE },
    ];
    options = [];

    @Input() surface;
    @Input() volumesSurface: number;
    @Input() typeSurface: string;

    @Input()
    nbVolumes: number = 0;

    @Output() surfaceDataEmitter: EventEmitter<any> = new EventEmitter();

    constructor(private dialog: MatDialog) {}

    onOptionClick(selectedOption: any) {
        if (!selectedOption.selected) {
            this.clearSelection(selectedOption);
            selectedOption.selected = true;
            this.surfaceDataEmitter.emit(selectedOption.type);
        } else {
            this.surfaceDataEmitter.emit('');
        }
    }

    clearSelection(selectedOption: any) {
        this.options.forEach((option) => {
            if (option === selectedOption) {
                option.selected = !option.selected;
            } else {
                option.selected = false;
            }
        });
    }

    ngOnInit(): void {
        this.options = this.surface.get('surfaceCalcul').value >= 100 ? this.optionsNoLongMax : this.optionsAll;
        this.options.forEach((option) => {
            option.selected = option.type === this.surface.get('typeSurface').value;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.volumesSurface) {
            this.surface.get('surfaceCalcul').setValue(changes.volumesSurface.currentValue);
        }
        this.options = this.surface.get('surfaceCalcul').value >= 100 ? this.optionsNoLongMax : this.optionsAll;
        this.options.forEach((option) => {
            option.selected = option.type === this.surface.get('typeSurface').value;
        });
    }
}
export enum AdditionnalAreaType {
    SURFACE_TRAVAIL = 'SURFACE_TRAVAIL',
    SURFACE_INTERFACE = 'SURFACE_INTERFACE',
    LONGUEUR_MAX = 'LONGUEUR_MAX',
}
