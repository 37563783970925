'use strict';

import { fh_scene } from '@acenv/fh-3d-viewer';
import { cn_to_bbp, cn_to_bbp_for_fh3d } from '../utils/cn_bbp';
import { cn_building } from './cn_building';
import { cn_element } from './cn_element';
import * as THREE from 'three';
import { cn_storey_element } from './cn_storey_element';
import { CN_CURRENT_DATE } from '../utils/cn_transaction_manager';
import { CN_FACING_TRIMMING_PLACEMENT_FLOOR } from './cn_facing_trimming';
import { logger } from '../utils/cn_logger';

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
/**
 * @class cn_3d_building
 * The 3D version of a building
 */
export class cn_3d_building {
    //*******************************************************
    /**
     * Constructor
     */
    constructor() {
        this._building = null;
        this._scene_3d = new fh_scene();

        this._update_date = -1;
        this._update_log = true;
        this._edge_visibility = false;
    }

    get_update_date() {
        return this._update_date;
    }

    /**
     * Load a building
     * @param {cn_building} building
     */
    load_building(building) {
        this._building = building;
        const bbp = cn_to_bbp_for_fh3d(this._building);
        this._scene_3d.load_json(bbp, true);
        this._scene_3d.set_edge_visibility(false);
        this._scene_3d['json'] = bbp;

        bbp.objects.forEach(bbp_element => {
            if (typeof (bbp_element.cnmap_storey) == 'object' && typeof (bbp_element.cnmap_element) == 'object') {
                var obj = this._scene_3d._objects_by_id[bbp_element.ID];
                if (obj) {
                    obj.cnmap_storey = bbp_element.cnmap_storey;
                    obj.cnmap_element = bbp_element.cnmap_element;
                    obj.cnmap_date = CN_CURRENT_DATE;
                }
            }
        });

        for (var i = 0; i < bbp.instances.length; i++) {
            if (i < this._scene_3d._instances.length && bbp.instances[i].cnmap_object) {
                this._scene_3d._instances[i].cnmap_object = bbp.instances[i].cnmap_object;
            }
        }
        this._update_date = CN_CURRENT_DATE;
    }

    set_edge_visibility(v) {
        this._edge_visibility = v;
        this._scene_3d.set_edge_visibility(v);
    }

    /**
     * Returns the 3D object that matches the storey element
     * @param {cn_storey_element} storey_element
     * @returns {object}
     */
    get_3d_object(storey_element) {
        const obj = this._scene_3d._products.find(ob => ob.visible && ob.cnmap_element && (ob.cnmap_storey.ID == storey_element.storey.ID) && (ob.cnmap_element.ID == storey_element.element.ID));
        if (obj) return obj;
        return null;
    }

    /**
     * Returns all 3D objects that matches the element
     * @param {cn_element} element
     * @returns {Array<object>}
     */
    get_3d_objects(element) {
        return this._scene_3d._products.filter(ob => ob.visible && ob.cnmap_element && (ob.cnmap_element.ID == element.ID));
    }

    /**
     * Returns all 3D objects of a given type
     * @param {string} bim_code
     * @returns {Array<object>}
     */
    get_3d_objects_by_bim_code(bim_code) {
        return this._scene_3d._products.filter(ob => ob.json_object && (ob.json_object.Code_BIM == bim_code));
    }


    /**
     * Get an object by bim id
     * @param {string} bim_id
     * @returns {object}
     */
    get_3d_object_by_bimid(bim_id) {
        return this._scene_3d._objects_by_id[bim_id];
    }

    /**
     * Returns object by filter
     * @param {function} filter
     */
    get_3d_objects_filter(filter) {
        return this._scene_3d._products.filter(ob => filter(ob));
    }

    get_topography_objects(filter = 'map') {
        return this._scene_3d._products.filter(ob => ob.json_object && ob.json_object.topography && ob.json_object.topography == filter);
    }

    /**
     * Returns all instance indices of the given object
     * @param {object} object
     * @returns {Array<number>}
     */
    get_instance_ids(object) {
        const instance_ids = [];
        for (var i = 0; i < this._scene_3d._instances.length; i++) {
            if (this._scene_3d._instances[i].cnmap_object == object) instance_ids.push(i);
        }
        return instance_ids;
    }

    /**
     * Adds instances to scene. Return array of instance ids.
     * @param {Array<object>} instances
     * @returns {Array<number>}
     */
    add_instances(instances) {
        const instance_ids = [];
        for (var ii = 0; ii < instances.length; ii++) {
            var mesh = instances[ii];

            //*** Create a new THREE geometry object
            var geometry = new THREE.Geometry();

            //*** Read vertices
            for (var i = 0; i < mesh.vertices.length; i += 3)
                geometry.vertices.push(new THREE.Vector3(mesh.vertices[i], mesh.vertices[i + 1], mesh.vertices[i + 2]));

            //*** read faces
            for (var i = 0; i < mesh.triangles.length - 2; i += 3)
                geometry.faces.push(new THREE.Face3(mesh.triangles[i], mesh.triangles[i + 1], mesh.triangles[i + 2]));

            geometry.computeFaceNormals();

            geometry.cnmap_object = mesh.cnmap_object;

            instance_ids.push(this._scene_3d._instances.length);
            this._scene_3d._instances.push(geometry);
        }
        return instance_ids;
    }

    /**
     * Add one object in the scene
     * @param {cn_storey_element} storey_element
     * @returns {object}
     */
    add_element(storey_element, bbp_object) {
        const obj = this._scene_3d.add_object(bbp_object);
        if (!obj) return null;
        if (storey_element) {
            obj.cnmap_storey = storey_element.storey;
            obj.cnmap_element = storey_element.element;
            obj.cnmap_date = CN_CURRENT_DATE;
        }
        this._scene_3d.update_bounding_box();
        return obj;
    }

    /**
     * Add objects in the scene
     * @param {Array<object>} bbp_objects
     */
    add_bbp_objects(bbp_objects) {
        bbp_objects.forEach(bbp_object => {
            const obj = this._scene_3d.add_object(bbp_object);
            if (bbp_object.cnmap_storey)
                obj.cnmap_storey = bbp_object.cnmap_storey;
            if (bbp_object.cnmap_element)
                obj.cnmap_element = bbp_object.cnmap_element;
        });
        this._scene_3d.update_bounding_box();
    }

    /**
     * Removes one element from the 3D scene
     * @param {cn_storey_element} storey_element
     */
    remove_element(storey_element) {
        this.remove_objects([this.get_3d_object(storey_element)]);
    }

    /**
     * Removes all elements from the 3D scene
     * @param {cn_element} element
     */
    remove_elements(element) {
        this.remove_objects(this.get_3d_objects(element));
    }

    /**
     * Remove a list of objects
     * @param {Array<object>} objects
     */
    remove_objects(objects) {
        objects.forEach(ob => this._scene_3d.remove_object(ob));
        this._scene_3d.update_bounding_box();
    }

    /**
     * General update method
     * @returns {boolean} true if something has changed
     */
    update_3d() {
        if (this._update_date >= this._building.get_date()) {
            logger.log('No change in model', this._building.get_date());
            return false;
        }
        logger.log('Changes in model', this._building.get_date(), this._update_date);

        var res = false;
        const t0 = (new Date()).getTime();

        if (this._update_facings()) res = true;

        if (this._update_markers()) res = true;

        if (this._update_object_instances()) res = true;

        if (this._update_topography()) res = true;

        this._update_date = CN_CURRENT_DATE;

        logger.log('Update_3d', res, (new Date()).getTime() - t0);
        if (res)
            this._scene_3d.set_edge_visibility(this._edge_visibility);
        return res;
    }

    /**
     * Update all facings
     * @returns {boolean} true if something has changed
     */
    _update_markers() {
        var res = false;

        this._building.storeys.forEach(storey => {
            storey.markers.forEach(marker => {
                if (!marker.up_to_date_shape(this._update_date)) {
                    marker.update_3d(this);
                    res = true;
                }
            });
        });

        logger.log('Update markers', res);
        return res;
    }

    /**
     * Update all facings
     * @returns {boolean} true if something has changed
     */
    _update_object_instances() {
        var res = false;

        this._building.storeys.forEach(storey => {
            var object_instances = storey.scene.object_instances;
            if (storey.roof) object_instances = object_instances.concat(storey.roof.object_instances);
            object_instances.forEach(instance => {
                if (!instance.up_to_date_matrix(this._update_date)) {
                    instance.update_3d(this, storey);
                    res = true;
                }
            });
        });

        logger.log('Update markers', res);
        return res;
    }

    /**
     * Update all facings
     * @returns {boolean} true if something has changed
     */
    _update_topography() {
        var res = false;
        const t0 = (new Date()).getTime();

        if (!this._building.topography.up_to_date(this._update_date, 'heights')) {
            this._building.topography.update_3d(this);
            logger.log('Update ground', (new Date()).getTime() - t0);

            this._building.exterior.scene.walls.forEach(wall => wall.update_3d_facings(this, this._building.exterior));
            res = true;
        }

        logger.log('Update Topography', res, (new Date()).getTime() - t0);
        return res;
    }

    /**
     * Update all facings
     * @returns {boolean} true if something has changed
     */
    _update_facings() {
        var res = false;

        if (this._update_facing_geometries()) res = true;

        if (this._update_facing_textures()) res = true;

        logger.log('Update facings', res);
        return res;
    }

    /**
     * Update facing geometries
     * @returns {boolean} true if something has changed
     */
    _update_facing_geometries() {
        const t0 = (new Date()).getTime();
        var res = false;
        var up_to_date_wall_trimmings = true;
        if (!this._building.up_to_date(this._update_date, 'facing_trimmings'))
            up_to_date_wall_trimmings = false;
        else if (this._building.facing_trimmings.some(ft => !ft.up_to_date_shape(this._update_date)))
            up_to_date_wall_trimmings = false;

        const up_to_date_topography = this._building.topography.up_to_date(this._update_date, 'heights') && this._building.up_to_date(this._update_date, 'facings_above_ground') && this._building.up_to_date(this._update_date, 'facings_above_ground_height');
        var nb_changes = 0;
        var nb_walls = 0;
        this._building.all_storeys().forEach(storey => {
            const up_to_date_trimmings = storey.scene.up_to_date(this._update_date, 'facing_trimmings');

            //*** Check if some floor facing may have changed */
            if (!up_to_date_trimmings ||
                storey.scene.facing_trimmings.filter(ft => ft.placement == CN_FACING_TRIMMING_PLACEMENT_FLOOR).some(facing_trimming => !facing_trimming.up_to_date_shape(this._update_date))) {
                logger.log('Update space floor geometries for storey', storey.storey_index);
                storey.scene.spaces.forEach(space => space.update_3d_floor_facings(this, storey));
                res = true;
            }

            //*** Check if some wall facing may have changed */
            if (!up_to_date_wall_trimmings || !up_to_date_topography) {
                logger.log('Update facing wall geometries for storey', storey.storey_index);
                storey.get_walls().forEach(wall => nb_changes += wall.update_3d_facings(this, storey));
                nb_walls += storey.get_walls().length;
                res = true;
            }
        });

        logger.log('Update facing geometries', res, nb_changes, nb_walls, (new Date()).getTime() - t0);
        return res;
    }

    /**
     *
     * @returns {boolean} true if something has changed
     */
    _update_facing_textures() {
        var res = false;
        this._building.all_storeys().forEach(storey => {
            /**
             * Check wall facings
             */
            storey.get_walls().forEach(wall => {
                if (wall.facings.some(facing => facing && facing.up_to_date(this._update_date)) || !wall.up_to_date(this._update_date, 'facings')) {
                    wall.update_3d_facing_texture(this, storey);
                    res = true;
                }
            });

            /**
             * Check space facings
             */
            storey.scene.spaces.forEach(space => {
                if (space.facings.some(facing => facing && facing.up_to_date(this._update_date)) || !space.up_to_date(this._update_date, 'facings')) {
                    logger.log('Update space floor texture ', space.get_name());
                    space.update_3d_floor_facing_texture(this, storey);
                    res = true;
                }
            });

            /**
             * Check space facings
             */
            storey.scene.facing_trimmings.forEach(facing_trimming => {
                if ((facing_trimming.facing && !facing_trimming.facing.up_to_date(this._update_date)) || !facing_trimming.up_to_date(this._update_date, 'facing')) {
                    logger.log('Update trimming floor texture ', facing_trimming.ID);
                    facing_trimming.update_3d_texture(this, storey);
                    res = true;
                }
            });
        });

        /**
         * Check wall facing trimmings
         */
        this._building.facing_trimmings.forEach(facing_trimming => {
            if (!facing_trimming.up_to_date(this._update_date, 'facing')) {
                logger.log('Update trimming wall texture ', facing_trimming.ID);
                facing_trimming.update_3d_texture(this);
                res = true;
            }
        });

        logger.log('Update facing textures', res);
        return res;
    }

}

