import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-card-clickable',
    templateUrl: './card-clickable.component.html',
    styleUrls: ['./card-clickable.component.scss'],
})
export class CardClickableComponent implements OnInit {
    @Input() label: string;
    @Input() isSelected = false;
    @Input() readonlyMode = false;
    @Output()
    selectCardElement = new EventEmitter<string>();

    constructor() {}

    ngOnInit(): void {}

    onClickCard() {
        // On ne renvoie l'évènement que si l'on est pas en mode readonly
        if (!this.readonlyMode) {
            this.selectCardElement.emit();
        }
    }
}
