<!--Déclaration du filter svg, permet l'affichage des objets dans le svg-->
<span style="display: none" [innerHTML]="this.svgFilter | safeHtml"></span>

<h1 mat-dialog-title class="primary d-flex justify-content-between">Création de screenshot</h1>

<div mat-dialog-content class="dialog-content">
    <div class="d-flex h-100">
        <!--        Apperçu SVG-->
        <div
            #viewerElement
            class="svg-container d-flex h-100 flex-grow-1 mx-2"
            (onResize)="handleContainerResize($event)"
        >
            <div class="m-auto" [ngStyle]="{ width: divWidth, height: divHeight }" (onResize)="handleDivResize($event)">
                <svg class="svg-view h-100 w-100" id="svg_view" tabindex="0"></svg>
            </div>
        </div>

        <!--        Sidebar paramètres-->
        <div class="svg-params-container h-100 overflow-auto">
            <app-bien-niveau-select
                [displayArrows]="false"
                [biens]="biens"
                [column]="true"
                (bienChanged)="changeCurrentBien($event)"
                [selectedBien]="currentBienToDisplay"
                [selectedNiveau]="currentStairToDisplay"
                (niveauChanged)="changeSelectedStorey($event)"
            >
            </app-bien-niveau-select>

            <mat-divider></mat-divider>
            <div class="general-params mt-3">
                <lib-increment-input
                    [label]="'Hauteur (mm)'"
                    [(value)]="heightMm"
                    [min]="1"
                    [max]="1000"
                    [step]="1"
                    (valueChange)="updateSvgDivSize()"
                ></lib-increment-input>
                <lib-increment-input
                    [label]="'Largeur (mm)'"
                    [(value)]="widthMm"
                    [min]="1"
                    [max]="1000"
                    [step]="1"
                    (valueChange)="updateSvgDivSize()"
                ></lib-increment-input>
            </div>
            <mat-divider></mat-divider>
            <app-export-print-param
                [svgParameter]="svgParameter"
                (scaleChange)="onScaleChange()"
                (resolutionChange)="onResolutionChange()"
                (centerViewChange)="onCenterViewChange()"
                (fixedScaleChange)="onFixedScaleChange()"
                (fixedPositionChange)="onFixedPositionChange()"
                (planParamChange)="onPlanParamChange($event)"
            ></app-export-print-param>
        </div>
    </div>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-button color="primary" (click)="cancel()">
        <mat-icon>close</mat-icon>
        <span>Annuler</span>
    </button>
    <button mat-raised-button color="primary" (click)="export()">
        <mat-icon>save_alt</mat-icon>
        <span>Exporter</span>
    </button>
</div>
