<lib-form-container>
    <h1>Mot de passe</h1>

    <mat-form-field class="w-100">
        <mat-label>Mot de passe actuel</mat-label>
        <input matInput type="password" required [(ngModel)]="oldPassword" />
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>Nouveau mot de passe</mat-label>
        <input
            matInput
            type="password"
            required
            [(ngModel)]="newPassword"
            minlength="4"
            maxlength="100"
            #newPasswordField="ngModel"
        />
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>Confirmez le nouveau mot de passe</mat-label>
        <input matInput type="password" required [(ngModel)]="newPasswordConfirm" />
    </mat-form-field>

    <div class="my-3">
        <button
            mat-raised-button
            color="accent"
            [disabled]="
                !oldPassword ||
                !newPassword ||
                !newPasswordConfirm ||
                !newPasswordField.valid ||
                newPassword !== newPasswordConfirm
            "
            (click)="submit()"
        >
            <mat-icon>check</mat-icon>
            <span>Mettre à jour</span>
        </button>
        <button mat-button (click)="back()">
            <mat-icon>close</mat-icon>
            <span>Annuler</span>
        </button>
    </div>
</lib-form-container>
