import { Component, Input } from '@angular/core';
import { TypePrestation } from '../../../../../model/type-prestation.model';

@Component({
    selector: 'app-documents-prestation-chips',
    templateUrl: './documents-prestation-chips.component.html',
    styleUrls: ['./documents-prestation-chips.component.scss'],
})
export class DocumentsPrestationChipsComponent {
    @Input() typesPrestation: TypePrestation[] = [];
    // Si -1, on affiche toutes les prestations
    // Sinon on affiche le nombre de prestation demandé + un chip contenant le nombre de prestation restantes
    @Input() nbPrestationsDisplayed = -1;

    trackByTypePrestation(index: number, typePrestation: TypePrestation): TypePrestation {
        return typePrestation;
    }
}
