export class cn_slab_metrics {

    constructor(slab) {
        this.width = 0;
        this.length = 0;
        this.thickness = 0;
        this.area = 0;
        this.volume = 0;

        const bb = slab.get_bounding_box();
        this.width = bb.size[0];
        this.length = bb.size[1];
        this.thickness = slab.slab_type.thickness;
        const polygon = slab.build_polygon(0);
        const area = polygon.get_area();
        this.area = area;
        this.volume = slab.slab_type.thickness * area;

        this.perimeter = slab.outer_polygon.get_perimeter();

        this.gross_area = slab.outer_polygon.get_area();
        this.net_area = slab.pierced_outer_polygon.get_area();
        this.gross_volume = this.gross_area * slab.slab_type.thickness;
        this.net_volume = this.net_area * slab.slab_type.thickness;
    }

}
