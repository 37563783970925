import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PointDeControleElement } from '../../../../model/point-de-controle.model';

class ElementsModalData {
    constructor(public elements: PointDeControleElement[]) {}
}

@Component({
    selector: 'app-elements-list-modal',
    templateUrl: './elements-list-modal.component.html',
    styleUrls: ['./elements-list-modal.component.scss'],
})
export class ElementsListModalComponent implements OnInit {
    elementsDisplayed: PointDeControleElement[] = [];

    constructor(public dialogRef: MatDialogRef<ElementsListModalComponent>, @Inject(MAT_DIALOG_DATA) public data: ElementsModalData) {}

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth600-dialog');
        if (this.data) {
            this.elementsDisplayed = [...this.data.elements];
        }
    }

    supprimerElement(el: PointDeControleElement) {
        this.elementsDisplayed.splice(this.elementsDisplayed.indexOf(el), 1);
    }

    cancel() {
        this.dialogRef.close(false);
    }

    confirm() {
        this.dialogRef.close({ elements: this.elementsDisplayed });
    }
}
