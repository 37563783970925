import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent, User } from 'src/app/commons-lib';

export class Onglet {
    icon: string;
    label: string;
    url: string;
    typeIcon: string; // Valeur possible actuellement : 'mat-icon' ou 'mdi'
}

@Component({
    selector: 'app-onglet-administration',
    templateUrl: './onglet-administration.component.html',
    styleUrls: ['./onglet-administration.component.scss'],
})
export class OngletAdministrationComponent extends BaseComponent implements OnInit {
    @Input() listeOnglets: Onglet[];
    @Input() user?: User;

    constructor(private router: Router) {
        super();
    }

    ngOnInit(): void {}

    onClickOnglet(onglet: Onglet) {
        this.router.navigate([onglet.url], { queryParams: { user: this.user } });
    }
}
