import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'joinList',
})
export class JoinListPipe implements PipeTransform {
    transform(value: string[], separator: string = ', '): string {
        return value.join(separator);
    }
}
