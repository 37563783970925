<div [formGroup]="formFichier">
    <h2>Fichier</h2>
    <div class="pl-3">
        <label class="primary"
            >Transmis par
            <span
                *ngIf="
                    updateMode ||
                    formFichier.get('idFichier').value != '' ||
                    (formFichier.get('sourceTransmission').value != '' &&
                        formFichier.get('sourceTransmission').value != undefined)
                "
                class="required"
            ></span
        ></label>
        <app-custom-select
            class="w-75"
            formControlName="sourceTransmission"
            [selectControl]="formFichier.get('sourceTransmission')"
            [matFormFieldClass]="'w-100'"
            [choices]="listContactTransmisPar"
            [addline]="false"
            [filter]="false"
            [emptyOptionActivate]="true"
            (selectedValueChange)="onChangeSourceTransmission($event)"
        >
        </app-custom-select>

        <mat-divider class="mt-3 mb-4"></mat-divider>

        <div class="d-flex flex-column w-100">
            <label class="primary"
                >Pièce jointe
                <span
                    *ngIf="
                        updateMode ||
                        formFichier.get('idFichier').value != '' ||
                        (formFichier.get('sourceTransmission').value != '' &&
                            formFichier.get('sourceTransmission').value != undefined)
                    "
                    class="required"
                ></span
            ></label>
            <app-wizy-file-uploader
                [isImage]="!!idFile"
                [interventionId]="idIntervention"
                [diagnosticId]="undefined"
                [referenceId]="documentToEdit?.id"
                [fileId]="idFile"
                [typeReferenceFichier]="typeReferenceFichier"
                [textConfig]="fileUploaderTextConfig"
                [autoUpload]="false"
                (fileUploaded)="onFileUploaded($event)"
                (deleted)="onFileDeleted()"
            ></app-wizy-file-uploader>
        </div>

        <mat-divider class="mt-3 mb-4"></mat-divider>

        <div *ngIf="updateMode">
            <label class="primary"
                >Veuillez saisir la raison pour laquelle vous souhaitez remplacer le fichier<span
                    *ngIf="updateMode"
                    class="required"
                ></span
            ></label>
            <mat-form-field class="w-100 form-custom" appearance="outline">
                <textarea
                    matInput
                    formControlName="commentaireContent"
                    cdkAutosizeMinRows="5"
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="9"
                    cdkAutosizeMaxRows="9"
                ></textarea>
            </mat-form-field>
            <mat-error>
                <app-form-control-error-message
                    [control]="formFichier.get('commentaireContent')"
                ></app-form-control-error-message>
            </mat-error>
        </div>
    </div>
</div>
