import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LibFileUploaderModule, LibIconModule } from 'src/app/commons-lib';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CnDiagSharedModule } from '../../shared/shared.module';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { GestionCofracsComponent } from './gestion-cofracs.component';
import { CreationCofracComponent } from './creation-cofrac/creation-cofrac.component';
import { TableauCofracComponent } from './tableau-cofrac/tableau-cofrac.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
    declarations: [GestionCofracsComponent, CreationCofracComponent, TableauCofracComponent],
    imports: [
        PipesModule,
        CommonModule,
        MatToolbarModule,
        LibIconModule,
        MatButtonModule,
        MatIconModule,
        CnDiagSharedModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatTableModule,
        MatSortModule,
        MatMenuModule,
        MatPaginatorModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        LibFileUploaderModule,
        MatProgressBarModule,
    ],
})
export class GestionCofracsModule {}
