<mat-card>
    <div class="d-flex flex-column">
        <table
            mat-table
            [dataSource]="dataSource"
            matSort
            (matSortChange)="sortSyntheses($event)"
            [matSortActive]="sort?.active"
            [matSortDirection]="sort?.direction"
            matSortDisableClear="true"
        >
            <ng-container matColumnDef="dateGeneration">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Date de génération</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.dateGeneration | date: DATE_FORMAT_FRANCAIS_HH_MM }}
                </td>
            </ng-container>

            <ng-container matColumnDef="numerosCommandes">
                <th mat-header-cell *matHeaderCellDef>Commande</th>
                <td mat-cell *matCellDef="let row">
                    <ng-container *ngFor="let num of row.numerosCommandes; let last = last">
                        {{ num }}<span *ngIf="!last">, </span>
                    </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="typesPrestations">
                <th mat-header-cell *matHeaderCellDef>Types de prestation</th>
                <td mat-cell *matCellDef="let row">
                    <ng-container *ngFor="let type of row.typesPrestations; let last = last">
                        {{ type | typePrestation }}<span *ngIf="!last">, </span>
                    </ng-container>
                </td>
            </ng-container>
            <ng-container matColumnDef="nombrePrestations">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre de prestations</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.prestationsIds.length }}
                </td>
            </ng-container>

            <ng-container matColumnDef="adresseBien">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Adresse</th>
                <td mat-cell *matCellDef="let row">
                    {{ row?.adresseBien?.voie }}<br />
                    {{ row?.adresseBien?.codePostal }} {{ row?.adresseBien?.ville }}
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                    <button mat-icon-button (click)="export(row)">
                        <mat-icon>picture_as_pdf</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator
            #paginator
            *ngIf="dataSource.page$ | async as page"
            [length]="page.totalElements"
            [pageSize]="page.size"
            [pageIndex]="page.number"
            [pageSizeOptions]="[initialPageSize, 50, 100]"
            (page)="dataSource.fetch($event.pageIndex, paginator.pageSize)"
        ></mat-paginator>
    </div>
</mat-card>
