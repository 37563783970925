import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { InterventionReportData, IReportComponent, ReportBlockData } from '../../../../../../../model/rapport.model';
import { Intervention } from '../../../../../../../model/intervention.model';
import { Diagnostic } from '../../../../../../../model/diagnostic.model';
import { DiagnosticService } from '../../../../../../../services/diagnostic.service';
import { CnSpinnerService } from '../../../../../../shared/cn-spinner/service/cn-spinner.service';
import { takeUntil } from 'rxjs/operators';
import { EspaceData, HapReportData } from '../../../../model/hapData.model';
import { TypePrestation } from '../../../../../../../model/type-prestation.model';
import { InterventionService } from '../../../../../../../services/intervention.service';
import { HapService } from '../../../../services/hap.service';

@Component({
    selector: 'app-resultat-analyse-block',
    templateUrl: './resultat-analyse-block.component.html',
    styleUrls: ['./resultat-analyse-block.component.scss'],
})
export class ResultatAnalyseBlockComponent extends BaseComponent implements OnInit, IReportComponent {
    @Input() data: ReportBlockData;
    @Input() intervention: Intervention;
    @Input() interReportData: InterventionReportData;

    listeEspaces: EspaceData[] = [];
    private diagnostic: Diagnostic;
    private typePrestation: TypePrestation;

    constructor(
        private readonly interventionService: InterventionService,
        private readonly diagnosticService: DiagnosticService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly hapService: HapService
    ) {
        super();
    }

    ngOnInit(): void {
        this.cnSpinnerService
            .withSpinner(this.diagnosticService.getCurrentDiagnostic())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((diag) => {
                this.diagnostic = diag;
                this.typePrestation = this.diagnostic.typePrestation;

                if (this.interReportData) {
                    const diagnosticData = this.interReportData.diagnostics.find(
                        (dia) => dia.typePrestation === this.typePrestation
                    ) as HapReportData;
                    this.listeEspaces = diagnosticData.listeEspaces;
                }
            });
    }
}
