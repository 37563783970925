import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-operateur-checkbox',
    templateUrl: './operateur-checkbox.component.html',
    styleUrls: ['./operateur-checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: OperateurCheckboxComponent,
            multi: true,
        },
    ],
})
export class OperateurCheckboxComponent implements ControlValueAccessor {
    value = false;
    nom: string = '';

    @Input()
    disabled: boolean = false;

    onChange: (value: boolean) => void;

    constructor() {}

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {}

    writeValue(obj: boolean): void {
        this.value = obj;
    }

    setValue() {
        if (this.disabled) return;

        this.value = !this.value;
        this.onChange(this.value);
    }
}
