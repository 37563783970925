import { cn_add, cn_mul, cn_object_instance, cn_storey, cn_svg_configurator } from '@acenv/cnmap-editor';
import { Conformite } from 'src/app/model/conformite.model';
import { PointDeControleElement, PointDeControleNiveau } from 'src/app/model/point-de-controle.model';
import {
    STYLE_ELEMENT_A_JUSTIFIER,
    STYLE_ELEMENT_CONFORME,
    STYLE_ELEMENT_NON_CONFORME,
    STYLE_ELEMENT_NON_VERIFIE,
} from 'src/app/shared/constants/svg.constants';
import { ViewerMapComponent } from '../viewer-map/viewer-map.component';
import { SvgFormValues } from './shared/interfaces/export-svg-form-values.interface';
import { LegendScreenshot, LegendScreenshotItem, LegendShape } from '../../../../model/screenshot-svg.model';
import { generateLegendSVG } from '../../../../utils/svg-label.utils';
import { CndiagSpaceLabelizer } from '../tools/cndiag-space-labelizer';
import { Niveau } from '../../../../model/bien.model';

export function generateLegendOld(legendScreenshots: LegendScreenshot[]) {
    let legendContent = ``;
    // Hauteur du cartouche dynamique en fonction du nombre d'entrées
    const maxLine = Math.max(...legendScreenshots.flatMap((l) => l.items.length));
    const cartoucheHeight = 30 + (maxLine >= 3 ? 3 * 30 : maxLine * 30);
    // Position en y du cartouche et de la légende
    const yBase = 0;

    // Cartouche
    legendContent += `<g><rect x="0" y="${yBase}" width="800" height="${cartoucheHeight}" style="fill:rgb(255,255,255);"></rect>`;

    // Coordonnée en x du bloc zone
    let xCoordZone = 10;

    const legendItems = [];
    legendScreenshots.forEach((legend, i) => {
        if (legend.items.length) {
            if (i > 0) {
                xCoordZone += 170;
            }

            // Titre du bloc
            legendItems.push(
                `<text class="stroke: rgb(100,100,100);" x="${xCoordZone}" y="${20 + yBase}" font-weight="bold">${legend.nomCategorie}</text>`
            );

            // Affichage de chaque item
            let yCoord = 0;
            const items = legend.items.map((item: LegendScreenshotItem, index) => {
                // En fonction de la longueur du nom de l'item, on adapte le décalage vers la droite pour les futures légendes
                const maxItemNameLength = Math.max(...legend.items.flatMap((it) => it.nom.length));
                if (index % 3 === 0) {
                    if (index !== 0) {
                        xCoordZone += maxItemNameLength * 7 + 80;
                    }
                    yCoord = 0;
                } else {
                    yCoord++;
                }
                const shapes = item.shapes.map((shape, indexShape) => {
                    let result;
                    // Selon la forme de la légende :
                    switch (shape) {
                        // Rectangle de légende
                        case LegendShape.FILLED_SQUARE:
                            result = `<rect x="${xCoordZone + 30 + 45 * indexShape}" y="${
                                (yCoord + 1) * 20 + yBase + 10
                            }" width="40" height="15" style="fill:${item.couleur}; fill-opacity: ${item.opacity}; stroke:black;"></rect>`;
                            break;

                        // Rectangle vide
                        case LegendShape.EMPTY_SQUARE:
                            result = `<rect x="${xCoordZone + 30 + 45 * indexShape}" y="${
                                (yCoord + 1) * 20 + yBase + 10
                            }" width="40" height="15" style="fill:none; stroke:${item.couleur};stroke-width: 2px; stroke-dasharray: 4;"></rect>`;
                            break;

                        // Ligne
                        case LegendShape.LINE:
                            result = `<rect x="${xCoordZone + 30 + 45 * indexShape}" y="${
                                (yCoord + 1) * 20 + yBase + 15
                            }" width="40" height="2" style="fill:${item.couleur}; fill-opacity: ${item.opacity}; stroke:${item.couleur};"></rect>`;
                            break;
                    }
                    return result;
                });
                // intitulé de l'item
                return (
                    shapes.flat() +
                    `<text class="stroke: rgb(100,100,100)" x="${xCoordZone + 80 + 45 * (item.shapes.length - 1)}" y="${
                        20 + (yCoord + 1) * 20 + yBase
                    }">${item.nom}</text>`
                );
            });
            legendItems.push(items.flat());
        }
    });
    legendContent += legendItems.join();
    // Fermeture du cartouche
    legendContent += `</g>`;
    return legendContent;
}

/**
 * @class cn_svg_configurator - A tool to produce svg for a map
 */
export class CnSvgConfiguratorWizy extends cn_svg_configurator {
    private selectedPointControleNiveau: PointDeControleNiveau;
    private legend: LegendScreenshot[];

    public _space_labelizer: CndiagSpaceLabelizer;

    private CLASS_ELEMENT_CONFORME = '.element_conforme {fill: #6dc911;opacity: 0.5;stroke-width: 1px;}\n';
    private CLASS_ELEMENT_A_JUSTIFIER = '.element_a_justifier {fill: #fd7313;opacity: 0.5;stroke-width: 1px;}\n';
    private CLASS_ELEMENT_NON_CONFORME = '.element_non_conforme {fill: #ff0000;opacity: 0.5;stroke-width: 1px;}\n';
    private CLASS_ELEMENT_NON_VERIFIE = '.element_non_verifie {stroke-width: 1px;}\n';

    readonly STYLE_ELEMENT_CONFORME = STYLE_ELEMENT_CONFORME;
    readonly STYLE_ELEMENT_A_JUSTIFIER = STYLE_ELEMENT_A_JUSTIFIER;
    readonly STYLE_ELEMENT_NON_CONFORME = STYLE_ELEMENT_NON_CONFORME;
    readonly STYLE_ELEMENT_NON_VERIFIE = STYLE_ELEMENT_NON_VERIFIE;

    /**
     *
     * @param {string} svgContainerId
     * @param {object} storey
     * @param {boolean} [roof=false]
     * @param selectedPointControleNiveau
     * @param svgFormValuesEquipments
     * @param svgFormValuesPlan
     * @param legend
     */
    constructor(
        svgContainerId,
        storey: cn_storey,
        roof = false,
        selectedPointControleNiveau: PointDeControleNiveau,
        public svgFormValues: SvgFormValues,
        legend: LegendScreenshot[],
        currentNiveau: Niveau,
        imagesBase64
    ) {
        super(svgContainerId, storey, roof);
        // Renseigne la méthode statique pour récupérer l'URL de l'image d'un objet
        (cn_object_instance as any).image_id_to_url = ViewerMapComponent.getObjectImageUrlFromIconId;
        this.selectedPointControleNiveau = selectedPointControleNiveau;
        this.legend = legend;

        this._space_labelizer = new CndiagSpaceLabelizer(storey, currentNiveau, imagesBase64);

        this.refreshParamsByCode();
    }

    // ***********************************************************************************
    // **** render scene
    // ***********************************************************************************
    // Dans cette méthode, seul l'affichage des object_instance est gérée. Tout le reste se fait dans le _draw_scene parent de cn_svg_configurator
    // Le paramètre show_objects de cn_svg_configurator doit être forcé à false pour utiliser de façon optimale cette méthode.
    // Il est remplacé par un paramètre wizydiag contenu dans this.svgFormValuesPlan.elements.showObjects
    _draw_scene(camera) {
        const scene = this._scene;
        let html = super._draw_scene(camera, {
            html: '',
            zpsos_slabs: [],
            textures: new Set([]),
            splitted_sections: [],
            layers_slabs: [],
        });
        //if (this.svgFormValues?.equipment?.conformite.showConformeObjects || this.svgFormValues?.equipment?.conformite.showNonConformeObjects) {
        // tslint:disable-next-line: forin
        scene.object_instances.forEach((objectInstance) => {
            const element = this.findObjectInNiveauSelected(objectInstance.ID);
            const classes = ['exp'];
            if (element) {
                switch (element.conformite) {
                    case Conformite.CONFORME:
                        if (this.svgFormValues?.equipment?.conformite.showConformeObjects) {
                            html += objectInstance.draw(camera, classes, this.STYLE_ELEMENT_CONFORME);
                        }
                        break;
                    case Conformite.A_JUSTIFIER:
                        if (this.svgFormValues?.equipment?.conformite.showAJustifierObjects) {
                            html += objectInstance.draw(camera, classes, this.STYLE_ELEMENT_A_JUSTIFIER);
                        }
                        break;
                    case Conformite.NON_CONFORME:
                        if (this.svgFormValues?.equipment?.conformite.showNonConformeObjects) {
                            html += objectInstance.draw(camera, classes, this.STYLE_ELEMENT_NON_CONFORME);
                        }
                        break;
                    default:
                        if (this.svgFormValues?.equipment?.conformite.showNonVerifieObjects) {
                            html += objectInstance.draw(camera, classes, this.STYLE_ELEMENT_NON_VERIFIE);
                        }
                        break;
                }
            }
        });
        //}
        // Zones polluant + legende
        if (this.svgFormValues?.polluant?.zoneId?.length) {
            this.set_svg_param('show_zone', { property: 'usage', values: [this.svgFormValues?.polluant?.zoneId] });
        }

        if (!!this.svgFormValues?.plan?.text?.showLegend && this.legend?.length) {
            html += this.buildLegend();
        }
        return html;
    }

    _build_style(additionalClasses) {
        let html = super._build_style(additionalClasses);

        // Ajout des classes css wizydiag
        html += this.CLASS_ELEMENT_CONFORME;
        html += this.CLASS_ELEMENT_A_JUSTIFIER;
        html += this.CLASS_ELEMENT_NON_CONFORME;
        html += this.CLASS_ELEMENT_NON_VERIFIE;

        return html;
    }

    // ***********************************************************************************
    // **** Fixed position
    /**
     * @param {Boolean} v
     */
    set_fixed_position(v) {
        this._fixed_position = v;
        const box = this._scene.get_bounding_box();
        if (box && box.posmin && box.size) {
            const worldCenter = cn_add(box.posmin, cn_mul(box.size, 0.5));
            const worldHeight = (this._print_height * this._scale) / 1000;
            this._camera.set_world_focus(worldCenter, worldHeight);
        }
        this.call('camera_move');
    }

    private findObjectInNiveauSelected(objectId: string): PointDeControleElement {
        let pointControleElement;
        if (this.selectedPointControleNiveau) {
            this.selectedPointControleNiveau.pointsDeControleVolumes.forEach((pcv) => {
                pcv.pointsDeControleElements.forEach((pce) => {
                    if (pce.objectId === objectId) {
                        pointControleElement = pce;
                    }
                });
            });
        }

        return pointControleElement;
    }

    private buildLegend() {
        return generateLegendSVG(this.legend, {
            minColumnWidth: 50,
            maxItemsPerColumn: 3,
            paddingBetweenColumns: 30,
            paddingBetweenItemRows: 10,
            paddingBetweenShapeAndText: 5,
            itemCharacterWidth: 6,
            itemFontSize: 12,
            titleFontSize: 16,
            titleCharacterWidth: (16 / 12) * 6,
            xOffset: 0,
            yOffset: 0,
            lineShapeThickness: 1,
            paddingBetweenShapes: 10,
            paddingBetweenBlocks: 40,
            shapeWidth: 40,
            shapeHeight: 15,
            padding: {
                top: 20,
                left: 20,
                right: 20,
                bottom: 20,
            },
        });
    }

    private refreshParamsByCode() {
        //*** Display options */
        this._svg_params = this._get_params();

        var obj = this;
        //*** Build list of params by code */
        this._svg_params_by_code = [];
        function visit_params(lst) {
            for (var i in lst) {
                if (typeof lst[i].value != 'undefined') obj._svg_params_by_code[lst[i].code] = lst[i].value;
                if (typeof lst[i].children != 'undefined') visit_params(lst[i].children);
            }
        }
        visit_params(this._svg_params);

        this.refresh();
    }
}
