import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { BaseComponent } from 'src/app/commons-lib';
import { CategorieEquipement } from 'src/app/model/categorie-equipement.model';
import { Equipement } from 'src/app/model/equipement.model';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { ReferenceApiService } from 'src/app/services/reference-api.service';
import { URL_GESTION_CATEGORIES_EQUIPEMENTS_CREATE, URL_GESTION_EQUIPEMENTS_CREATE, URL_REFERENTIELS } from 'src/app/shared/constants/url.constants';
import { ViewListeEquipementsComponent } from './view-liste-equipements/view-liste-equipements.component';
import { TreeCategorieEquipementsComponent } from './tree-categorie-equipements/tree-categorie-equipements.component';

@Component({
    selector: 'app-gestion-equipements',
    templateUrl: './gestion-equipements.component.html',
    styleUrls: ['./gestion-equipements.component.scss'],
})
export class GestionEquipementsComponent extends BaseComponent implements OnInit {
    @ViewChild(ViewListeEquipementsComponent, { static: true }) listeEquipements: ViewListeEquipementsComponent;
    @ViewChild(TreeCategorieEquipementsComponent, { static: true })
    listeCategoriesEquipements: TreeCategorieEquipementsComponent;

    currentCategorie: CategorieEquipement;
    listeAllCategories: CategorieEquipement[];
    listeAllCategories$: Observable<CategorieEquipement[]>;
    currentEquipement: Equipement;
    idCategorie: string = '';

    constructor(
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly referenceApiService: ReferenceApiService
    ) {
        super();
    }

    ngOnInit() {
        if (this.route.snapshot.params.idEquipement) this.idCategorie = this.route.snapshot.params.idEquipement;

        this.listeAllCategories$ = this.referenceApiService.findAllCategorieEquipement();
        this.listeAllCategories$.subscribe((data) => {
            this.listeAllCategories = data;
        });
    }

    creerCategorieEquipement() {
        this.router.navigate([URL_GESTION_CATEGORIES_EQUIPEMENTS_CREATE, this.idCategorie]);
    }

    back() {
        this.router.navigate([URL_REFERENTIELS]);
    }

    selectCurrentCategorie(categorie: CategorieEquipement) {
        console.log(`CategorieEquipement ${categorie.name} selected`);
        this.currentEquipement = null;
        this.listeEquipements.unselectEquipement();
        this.currentCategorie = categorie;
        this.idCategorie = categorie.id;
    }

    selectCurrentEquipement(equipement: Equipement) {
        console.log(`Equipement ${equipement.name} selected`);
        this.currentEquipement = equipement;
    }

    deletedCurrentEquipement(equipement: Equipement) {
        console.log(`Equipement ${equipement.name} deleted`);
        this.currentEquipement = equipement;
        this.listeEquipements.refresh();
    }

    createEquipement(categorie: CategorieEquipement) {
        console.log(`Create new Equipement for Categorie ${categorie.name}`);
        this.router.navigate([URL_GESTION_EQUIPEMENTS_CREATE, categorie.id]);
    }

    deletedCurrentCategorieEquipement(categorie: CategorieEquipement) {
        console.log(`CategorieEquipement ${categorie.name} deleted`);
        this.currentCategorie = null;
        this.idCategorie = categorie.parentsIds[0];
        this.listeCategoriesEquipements.refresh();
    }
}
