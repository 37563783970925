import { Component, Inject } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Bien, Niveau, Volume } from '../../../../model/bien.model';
import { combineLatest, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { CnSpinnerService } from '../../../shared/cn-spinner/service/cn-spinner.service';
import { InterventionService } from '../../../../services/intervention.service';
import { ActivatedRoute } from '@angular/router';
import { ReferencePrestation } from '../../../../model/reference-prestation.model';

export class EquipementsOuvragesModalData {
    constructor(
        public tabToOpen: number,
        public readonlyMode?: boolean,
        public niveau?: Niveau,
        public volume?: Volume,
        public categorieOuvrageCode?: string,
        public filtresRefPrestations?: ReferencePrestation[]
    ) {}
}

export enum INDEXES_TABS_MODAL_EQUIPEMENTS_OUVRAGES {
    OUVRAGES,
    EQUIPEMENTS,
}

@Component({
    selector: 'app-description-bien-modal-equipements-ouvrages',
    templateUrl: './description-bien-modal-equipements-ouvrages.component.html',
    styleUrls: ['./description-bien-modal-equipements-ouvrages.component.scss'],
})
export class DescriptionBienModalEquipementsOuvragesComponent extends BaseComponent {
    tabsIndexes = INDEXES_TABS_MODAL_EQUIPEMENTS_OUVRAGES;
    currentTabIndex = INDEXES_TABS_MODAL_EQUIPEMENTS_OUVRAGES.EQUIPEMENTS;

    bien: Bien;
    currentNiveau: Niveau;
    currentIndexNiveau: number;
    currentVolume: Volume;
    currentIndexVolume: number;

    categorieKeyToOpen: string;

    selectedRefPrestations: ReferencePrestation[] = [];

    readonlyMode: boolean = false;

    constructor(
        private readonly interventionService: InterventionService,
        private readonly dialogRef: MatDialogRef<DescriptionBienModalEquipementsOuvragesComponent>,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly route: ActivatedRoute,
        @Inject(MAT_DIALOG_DATA) public data: EquipementsOuvragesModalData
    ) {
        super();
    }

    ngOnInit() {
        this.selectedRefPrestations = this.data.filtresRefPrestations;
        this.readonlyMode = this.data.readonlyMode;
        this.currentTabIndex = this.data.tabToOpen;
        this.currentNiveau = this.data.niveau;
        this.currentVolume = this.data.volume;
        this.categorieKeyToOpen = this.data.categorieOuvrageCode;
        this.cnSpinnerService
            .withSpinner(
                combineLatest([this.interventionService.getCurrentIntervention(), this.route.paramMap]).pipe(
                    takeUntil(this.ngUnsubscribe),
                    switchMap(([currentIntervention, params]) => {
                        return combineLatest([of(currentIntervention), of(params)]);
                    })
                )
            )
            .subscribe(([currentIntervention, params]) => {
                const idBien = params.get('idBien');
                if (idBien) {
                    this.bien = currentIntervention.relationInterventionBiens.find((it) => it.bien.id === idBien).bien;
                } else {
                    this.bien = currentIntervention.relationInterventionBiens.find((it) => it.isBienPrincipal).bien;
                }
                this.currentIndexVolume = this.currentNiveau.volumes.findIndex((it) => it.id === this.currentVolume.id);
                this.currentIndexNiveau = this.bien.description.findIndex((it) => it.id === this.currentNiveau.id);
                this.cnSpinnerService.hide();
            });
    }

    handlePiecePrecedente() {
        if (this.currentIndexVolume === 0) {
            if (this.currentIndexNiveau != 0) {
                this.currentIndexNiveau--;
                this.currentIndexVolume = this.bien.description[this.currentIndexNiveau].volumes.length - 1;
            }
        } else {
            this.currentIndexVolume--;
        }
        this.refreshNiveauAndVolume();
    }

    handlePieceSuivante() {
        if (this.currentIndexVolume === this.bien.description[this.currentIndexNiveau].volumes.length - 1) {
            if (this.currentIndexNiveau != this.bien.description.length - 1) {
                this.currentIndexNiveau++;
                this.currentIndexVolume = 0;
            }
        } else {
            this.currentIndexVolume++;
        }
        this.refreshNiveauAndVolume();
    }

    private refreshNiveauAndVolume() {
        this.currentNiveau = this.bien.description[this.currentIndexNiveau];
        this.currentVolume = this.currentNiveau.volumes[this.currentIndexVolume];
    }

    close() {
        const resultDialog = {
            result: false,
            niveau: this.currentNiveau,
            volume: this.currentVolume,
            filtresRefPrestations: this.selectedRefPrestations,
        };
        this.dialogRef.close(resultDialog);
    }
}
