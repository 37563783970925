import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'app-surface-input-dialog',
    templateUrl: './surface-input-dialog.component.html',
})
export class SurfaceInputDialogComponent {
    surface: AbstractControl;
    form: FormGroup;
    superficieCnMap: number;
    constructor(
        public dialogRef: MatDialogRef<SurfaceInputDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder
    ) {
        this.dialogRef.disableClose = true;
        this.form = this.formBuilder.group({
            value: [null, [Validators.required, Validators.min(0), this.decimalNumberValidator()]],
            automatique: [false],
        });
        this.surface = data.surface;
        this.superficieCnMap = data.superficieCnMap;
        if (data.declaredArea < 0) {
            this.form.get('automatique').setValue(true);
            this.form.get('value').setValue(this.superficieCnMap);
        } else {
            this.form.get('value').setValue(data.declaredArea);
        }
    }
    onCancelClick(): void {
        this.dialogRef.close({ confirmed: false, surface: null });
    }

    onConfirmClick(): void {
        if (this.form.valid) {
            this.dialogRef.close({
                confirmed: true,
                value: this.form.get('value').value,
                formControlName: this.data.formControlName,
                automatique: this.form.get('automatique').value,
            });
        }
        // else : Gérer l'affichage d'un message d'erreur ou autre action en cas de validation invalide.
    }

    decimalNumberValidator() {
        return (control) => {
            const pattern = /^[0-9]+(\.[0-9]+)?$/;
            if (control.value && !pattern.test(control.value)) {
                return { invalidNumber: true };
            }
            return null;
        };
    }

    onChangeAutoVal($event: MatCheckboxChange, mesure?: string) {
        this.form.get('automatique').setValue($event.checked);
        if (this.form.get('automatique').value) {
            this.form.get('value').setValue(this.superficieCnMap);
        } /*else {
            this.form.get('value').setValue('');
        }*/
    }

    valueChange() {
        this.form.get('automatique').setValue(false);
    }
}
