import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../utils/base.component';
import { User } from '../authentication/user.model';
import { UserService } from '../authentication/user.service';
import { NotificationService } from '../notification/notification.service';
import { ConfirmationService } from '../confirmation/confirmation.service';
import { AuthenticationStore } from '../authentication/authentication.store';
import { AuthenticationService } from '../authentication/authentication.service';

@Component({
    selector: 'lib-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent extends BaseComponent implements OnInit, OnDestroy {
    /** Classe CSS sur le conteneur principal */
    @Input() containerClass = '';

    /** Utilisateur courant */
    user: User;

    /** Mail en mode édition ? */
    editMailMode = false;

    /** Email de référence (pour tracer s'il a été modifié) */
    mailRef: string;

    constructor(
        private authenticationService: AuthenticationService,
        private authenticationStore: AuthenticationStore,
        private userService: UserService,
        private notificationService: NotificationService,
        private confirmationService: ConfirmationService,
        private router: Router
    ) {
        super();
    }

    ngOnInit() {
        this.authenticationStore
            .getCurrentUser()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((user) => {
                if (!user) {
                    return;
                }
                this.user = user;
                this.mailRef = user.email;
            });
    }

    save() {
        if (this.hasMailChanged()) {
            this.confirmationService.confirm(
                'Confirmez-vous la nouvelle adresse email "' +
                    this.user.email +
                    '" ?<br/>' +
                    'Attention : celle-ci sera désormais utilisée pour vous authentifier.',
                () => this.doSave()
            );
        } else {
            this.doSave();
        }
    }

    editMail() {
        this.confirmationService.confirm(
            'Êtes-vous sûr de vouloir modifier votre adresse email ?<br/>Celle-ci est utilisée pour vous authentifier.',
            () => (this.editMailMode = true)
        );
    }

    private doSave() {
        this.editMailMode = false;
        this.userService.updateAccount(this.user).subscribe(() => {
            if (this.hasMailChanged()) {
                this.notificationService.success(
                    'Le profil a bien été mis à jour. ' + 'Veuillez vous identifier à nouveau avec votre nouvelle adresse mail : ' + this.user.email
                );
                this.authenticationService.logout().subscribe();
                this.router.navigate(['/login']);
            } else {
                this.notificationService.success('Le profil a bien été mis à jour');
            }
        });
    }

    private hasMailChanged() {
        return this.mailRef !== this.user.email;
    }
}
