<div *ngIf="document.idFichier" class="attachment-container d-flex flex-row">
    <div class="file-name-box d-flex align-items-center justify-content-between w-100">
        <div>{{ document.nomFichier }}</div>

        <button mat-icon-button (click)="onClickView()">
            <mat-icon matSuffix>visibility</mat-icon>
        </button>
    </div>
    <div *ngIf="!readonlyMode && document.isEditable" class="d-flex flex-column">
        <button mat-icon-button color="warn" (click)="onClickDelete()">
            <lib-icon>delete</lib-icon>
        </button>

        <button mat-icon-button="primary" (click)="onClickUpdateFile()">
            <lib-icon>drive_folder_upload</lib-icon>
        </button>
    </div>
</div>

<!--------------- Ajouter un fichier ----------------->
<div
    *ngIf="!document.idFichier && !readonlyMode"
    class="attachment-container-add d-flex justify-content-center flex-column align-items-center"
    (click)="onClickAddFile()"
>
    <div><mat-icon matSuffix>add</mat-icon></div>
    <div>Ajouter une pièce jointe</div>
</div>
<!--------------- Message readOnly : fichier inexistant ----------------->
<div *ngIf="!document.idFichier && readonlyMode">
    <div>Pas de pièce jointe associée</div>
</div>

<div class="mt-2">
    <div *ngIf="document?.dateTransmission && document?.sourceTransmission">
        Transmis :
        <span class="text-detail"
            >Le {{ document?.dateTransmission | date: 'shortDate' }}
            <ng-container *ngIf="document?.sourceTransmission">par {{ document?.sourceTransmission }}</ng-container>
        </span>
    </div>
    <div *ngIf="document?.dateCreation && document?.creePar">
        Crée :
        <span class="text-detail">Le {{ document?.dateCreation | date: 'shortDate' }} par {{ document?.creePar }}</span>
    </div>
</div>
