<div class="d-flex flex-column h-100">
    <div class="d-flex w-100 align-items-baseline underlined pt-2">
        <h1 class="primary flex-grow-1 text-center">Éléments liés au commentaire</h1>
        <button mat-icon-button color="primary" (click)="cancel()">
            <lib-icon>close</lib-icon>
        </button>
    </div>

    <div mat-dialog-content class="mt-4">
        <div *ngFor="let el of elementsDisplayed" class="d-flex">
            <div class="equipment-card px-2 d-flex align-items-center w-100 mb-1">
                <div class="flex-grow-1">{{ el.nom }}</div>
                <button
                    *ngIf="elementsDisplayed.length > 1"
                    mat-icon-button
                    color="warn"
                    (click)="supprimerElement(el)"
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="justify-content-end">
        <button mat-raised-button color="primary" (click)="confirm()">
            <lib-icon>check</lib-icon>
            <span>Confirmer</span>
        </button>
    </div>
</div>
