/**
 * URLs de la partie admin
 */
export const URL_GESTION_BIENS = '/admin/gestion-biens';
export const URL_GESTION_BIENS_EDIT = '/admin/gestion-biens/edit-bien';
export const URL_GESTION_BIENS_CONSULTER = '/admin/gestion-biens/consulter-bien';
export const URL_GESTION_AGENCES = '/admin/gestion-agences';
export const URL_GESTION_AGENCES_EDIT = '/admin/gestion-agences/edit-agence';
export const URL_GESTION_AGENCES_CONSULTER = '/admin/gestion-agences/consulter-agence';
export const URL_GESTION_USERS = '/admin/gestion-users';
export const URL_GESTION_USERS_EDIT = '/admin/gestion-users/edit-user';
export const URL_GESTION_USERS_CONSULTER = '/admin/gestion-users/consulter-user';
export const URL_GESTION_COMMANDES = '/admin/gestion-commandes';
export const URL_GESTION_COMMANDES_EDIT = '/admin/gestion-commandes/edit-commande';
export const URL_GESTION_COMMANDES_CONSULTER = '/admin/gestion-commandes/consulter-commande';
export const URL_GESTION_CONTACTS = '/admin/gestion-contacts';
export const URL_GESTION_CONTACTS_EDIT = '/admin/gestion-contacts/edit-contact';
export const URL_GESTION_CONTACTS_CONSULTER = '/admin/gestion-contacts/consulter-contact';
export const URL_GESTION_COFRACS = '/admin/gestion-cofracs';
export const URL_GESTION_COFRACS_EDIT = '/admin/gestion-cofracs/edit-cofrac';
export const URL_GESTION_COFRACS_CONSULTER = '/admin/gestion-cofracs/consulter-cofrac';
export const URL_GESTION_SYNTHESES = '/admin/gestion-syntheses';
export const URL_GESTION_CREATION_SYNTHESE = '/admin/gestion-syntheses/creer-synthese';

export const URL_REFERENTIELS = '/admin/referentiels';
export const URL_GESTION_PIECES = '/admin/referentiels/volumes';
export const URL_GESTION_PIECES_EDIT = '/admin/referentiels/volumes/edit-volume';
export const URL_GESTION_PIECES_CONSULTER = '/admin/referentiels/volumes/consulter-volume';
export const URL_GESTION_EQUIPEMENTS = '/admin/referentiels/equipements';
export const URL_GESTION_TEMPLATE_RAPPORT = '/admin/referentiels/gestion-template-rapport';
export const URL_GESTION_LIBS_ICONES = '/admin/referentiels/libs-icones';
export const URL_GESTION_EQUIPEMENTS_EDIT = '/admin/referentiels/equipements/edit-equipement';
export const URL_GESTION_EQUIPEMENTS_CREATE = '/admin/referentiels/equipements/create-equipement';
export const URL_GESTION_CATEGORIES_EQUIPEMENTS_EDIT = '/admin/referentiels/equipements/edit-categorie-equipement';
export const URL_GESTION_CATEGORIES_EQUIPEMENTS_CREATE = '/admin/referentiels/equipements/create-categorie-equipement';
export const URL_GESTION_EQUIPEMENTS_CONSULTER = '/admin/referentiels/equipements/consulter-equipement';
export const URL_GESTION_TYPES_OUVRAGE = '/admin/referentiels/types-ouvrage';
export const URL_GESTION_TYPES_OUVRAGE_EDIT = '/admin/referentiels/types-ouvrage/edit-type-ouvrage';
export const URL_GESTION_MATERIAUX = '/admin/referentiels/materiaux';
export const URL_GESTION_MATERIAUX_EDIT = '/admin/referentiels/materiaux/edit-materiaux';
export const URL_GESTION_MATERIAUX_CONSULTER = '/admin/referentiels/materiaux/consulter-materiaux';
export const URL_GESTION_PRESTATIONS = '/admin/referentiels/prestations';
export const URL_GESTION_CATEGORIES_OUVRAGES = '/admin/referentiels/categories-ouvrages';
export const URL_GESTION_CATEGORIE_OUVRAGE_CONSULTER =
    '/admin/referentiels/categories-ouvrages/consulter-categorie-ouvrage';
export const URL_GESTION_CATEGORIE_OUVRAGE_EDIT = '/admin/referentiels/categories-ouvrages/edit-categorie-ouvrage';
export const URL_GESTION_PRESTATIONS_EDIT = '/admin/referentiels/prestations/edit-prestation';
export const URL_GESTION_PRESTATIONS_CONSULTER = '/admin/referentiels/prestations/consulter-prestation';

export const URL_GESTION_BONS_COMMANDE_ANALYSE = '/admin/gestion-bons-de-commande-analyse';
export const URL_GESTION_BONS_COMMANDE_ANALYSE_CONSULTER = `${URL_GESTION_BONS_COMMANDE_ANALYSE}/consulter-bon-commande`;

export const URL_GESTION_DOCUMENT = '/admin/gestion-documents';
export const URL_GESTION_DOCUMENT_CONSULTER_CATEGORIE = `${URL_GESTION_DOCUMENT}/consulter-categorie-document`;
export const URL_GESTION_DOCUMENT_EDIT_CATEGORIE = `${URL_GESTION_DOCUMENT}/edit-categorie-document`;
export const URL_GESTION_DOCUMENT_CONSULTER_TYPE = `${URL_GESTION_DOCUMENT}/consulter-type-document`;
export const URL_GESTION_DOCUMENT_EDIT_TYPE = `${URL_GESTION_DOCUMENT}/edit-type-document`;

export const URL_GESTION_COMMENTAIRES_PREDEFINIS = '/admin/gestion-commentaires-predefinis';
export const URL_GESTION_COMMENTAIRES_PREDEFINIS_LIST = `${URL_GESTION_COMMENTAIRES_PREDEFINIS}/commentaires-predefinis-list`;
export const URL_GESTION_COMMENTAIRES_PREDEFINIS_EDIT = `${URL_GESTION_COMMENTAIRES_PREDEFINIS}/edit`;
export const URL_GESTION_COMMENTAIRES_PREDEFINIS_CONSULTER = `${URL_GESTION_COMMENTAIRES_PREDEFINIS}/consulter`;

export const URL_GESTION_COMMENTAIRES_PREDEFINIS_CATEGORIES_LIST = `${URL_GESTION_COMMENTAIRES_PREDEFINIS}/categories-list`;
export const URL_GESTION_COMMENTAIRES_PREDEFINIS_CATEGORIES_EDIT = `${URL_GESTION_COMMENTAIRES_PREDEFINIS}/categories/edit`;
export const URL_GESTION_COMMENTAIRES_PREDEFINIS_CATEGORIES_CONSULTER = `${URL_GESTION_COMMENTAIRES_PREDEFINIS}/categories/consulter`;

/**
 * URL pour le tableau et la gestion des interventions
 */
export const URL_GESTION_INTERVENTIONS = '/gestion-interventions';
export const URL_GESTION_INTERVENTIONS_EDIT = '/gestion-interventions/edit-intervention';
export const URL_GESTION_INTERVENTIONS_CONSULTER = '/gestion-interventions/consulter-intervention';

/**
 * URL pour le tableau et la gestion des livrables
 */
export const URL_GESTION_LIVRABLES = '/gestion-livrables';

/**
 * URL communes
 */
export const URL_TABLEAU_DE_BORD = '/tableau-de-bord';
export const URL_TABLEAU_DE_BORD_OPERATEUR = '/tableau-de-bord/operator';
export const URL_TABLEAU_DE_BORD_MANAGER = '/tableau-de-bord/project';

export const URL_MON_COMPTE = `/tableau-de-bord/mon-compte`;
export const URL_MON_COMPTE_PROFIL = `/tableau-de-bord/mon-compte/profil`;
export const URL_MON_COMPTE_SIGNATURE = `/tableau-de-bord/mon-compte/signature`;
export const URL_MON_COMPTE_COMPETENCES = `/tableau-de-bord/mon-compte/competences`;
export const URL_MON_COMPTE_PREFERENCES = `/tableau-de-bord/mon-compte/preferences`;
export const URL_MON_COMPTE_MATERIEL = `/tableau-de-bord/mon-compte/materiel`;
export const URL_MODE_HORS_LIGNE = `/tableau-de-bord/mode-hors-ligne`;
export const URL_INTERVENTIONS = '/interventions';

export const URL_PART_DIAGNOSTICS = '/diagnostics';
