<div class="line-height-24px d-flex align-items-center flex-nowrap">
    <mat-icon *ngIf="data.icon" class="mr-2">{{ data.icon }}</mat-icon>
    <div class="flex-grow-1 d-flex align-items-center flex-nowrap" [class.with-close-button]="data.showClose">
        <div class="flex-grow-1" [innerHtml]="data.message | trustHtml"></div>
        <div
            *ngIf="data.actionMessage"
            class="pl-4 c-pointer font-weight-bold notification-action"
            [innerHtml]="data.actionMessage | trustHtml"
            (click)="action()"
        ></div>
    </div>
    <button mat-icon-button *ngIf="data.showClose" (click)="close()" class="close-button">
        <mat-icon>close</mat-icon>
    </button>
</div>
