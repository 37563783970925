<mat-error *ngIf="control?.hasError('ruleError')">
    <span *ngIf="control?.getError('ruleError').message !== ''">
        {{ control.getError('ruleError').message }}
    </span>
    <span *ngIf="message">{{ message }}</span>
</mat-error>

<mat-error *ngIf="control?.hasError('required')">
    <div class="form-error" [ngClass]="addClass">
        <span *ngIf="message">{{ message }}</span>
        <span *ngIf="!message">Required</span>
    </div>
</mat-error>

<mat-error *ngIf="control?.hasError('customError')">
    <div class="form-error" [ngClass]="addClass">
        <span *ngIf="!message && control?.getError('customError').message !== ''">
            {{ control.getError('customError').message }}
        </span>
        <span *ngIf="message">{{ message }}</span>
    </div>
</mat-error>
