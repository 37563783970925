import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MpcaPendantTravauxOld } from '../../../../../model/mpca.model.old';
import { ListeDeroulante } from '../../../../../model/polluant-config.model';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-mpca-form-pendant-travaux',
    templateUrl: './mpca-form-pendant-travaux.component.html',
    styleUrls: ['./mpca-form-pendant-travaux.component.scss'],
})
export class MpcaFormPendantTravauxComponent {
    @Output()
    pendantTravauxDataChange = new EventEmitter<MpcaPendantTravauxOld>();

    @Input()
    niveauEmpoussierementConfig: ListeDeroulante;

    @Input()
    typeMasqueConfig: ListeDeroulante;

    //    @Input() // Interface pas encore retournée par l'API, valeur à vide
    //    concentrationFIConfig: any;

    @Input()
    formPendantTravaux: FormGroup | AbstractControl;
}
