import { Pipe, PipeTransform } from '@angular/core';
import { EtatDiagnostic, etatDiagnosticToLabel } from '../model/diagnostic.model';

/**
 * Affiche le label de l'état d'un diagnostic
 */
@Pipe({
    name: 'etatDiagnostic',
})
export class EtatDiagnosticPipe implements PipeTransform {
    constructor() {}

    transform(etatDiagnostic: EtatDiagnostic): string {
        return etatDiagnosticToLabel(etatDiagnostic);
    }
}
