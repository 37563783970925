import { cn_edit_box, cn_marker_handler } from '@acenv/cnmap-editor';

/**
 * An edition marker handler class extends cn_edition_handler. It is similar than cn_marker_handler but without edition box panel
 */
export class SimpleMarkerHandler extends cn_marker_handler {
    /**
     * Constructor
     * @param {Array<cn_marker>} markers
     * @param {cn_svg_map | cn_view_overlay} map
     * @param {boolean} creation
     */
    constructor(markers, map, creation = false) {
        super(markers, map);

        const isEditBox = (handler: any): handler is cn_edit_box => true;
        this._markers = this._markers.filter(isEditBox);
    }
}
