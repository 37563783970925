<div class="bien-niveau-select-content">
    <div class="d-flex" [ngClass]="column === true ? 'flex-column' : 'flex-row border-bottom pl-3 pr-2'">
        <div *ngIf="!hideBiensSelection" [ngClass]="column === true ? 'w-100 bien-column' : 'w-50 p-2'">
            <app-custom-select
                class="w-100"
                [matFormFieldClass]="'w-100'"
                [choices]="bienChoices"
                [selectedValue]="selectedBien"
                (valueChangeFromUser)="changeBien($event)"
                [filter]="false"
                [addline]="false"
                [sorted]="false"
            ></app-custom-select>
        </div>
        <div class="d-flex flex-row align-items-center" [ngClass]="column === true ? 'w-100 p-0' : 'w-50 p-2'">
            <button
                *ngIf="displayArrows"
                mat-icon-button
                color="primary"
                [ngClass]="column === true ? 'pl-0' : ''"
                [disabled]="!selectedNiveau?.hasPrevious"
                (click)="previousNiveau()"
                data-cy="niveau-precedant-button"
            >
                <lib-icon iconName="arrow-left-bold-circle" [theme]="'mdi'"></lib-icon>
            </button>

            <!--            <app-custom-select-->
            <!--                class="w-75"-->
            <!--                [matFormFieldClass]="'w-100'"-->
            <!--                [choices]="niveauChoices"-->
            <!--                [pathValueDisplay]="['nom']"-->
            <!--                [selectedValue]="selectedNiveau"-->
            <!--                (valueChangeFromUser)="changeNiveau($event)"-->
            <!--                [filter]="false"-->
            <!--                [addline]="false"-->
            <!--                [sorted]="false"-->
            <!--            ></app-custom-select>-->
            <mat-form-field class="custom-select-container w-100" appearance="standard">
                <mat-select
                    [disabled]="!niveauChoices?.length"
                    [(value)]="selectedNiveau"
                    (selectionChange)="changeNiveau($event.value)"
                    [compareWith]="attributeDisplay"
                >
                    <mat-option *ngFor="let niv of niveauChoices" [value]="niv">
                        {{ niv.nom }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <button
                *ngIf="displayArrows"
                mat-icon-button
                color="primary"
                [ngClass]="column === true ? 'pr-0' : ''"
                [disabled]="!selectedNiveau?.hasNext"
                (click)="nextNiveau()"
                data-cy="niveau-suivant-button"
            >
                <lib-icon iconName="arrow-right-bold-circle" [theme]="'mdi'"></lib-icon>
            </button>
        </div>
    </div>
</div>
