<div *ngIf="!selectedCategorieOuvrage && !selectedCategorieOuvrageParent" class="w-20 h-100 d-flex flex-column">
    <div class="d-flex align-items-center border-bottom p-3 font-weight-light">Catégories</div>
    <div *ngIf="mapCategoriesOuvrages" class="d-flex flex-column overflow-hidden">
        <div class="flex-grow-1 overflow-auto">
            <ng-container *ngFor="let categorieParente of mapCategoriesParentes | keyvalue">
                <div
                    *ngIf="categorieParente.value > 0"
                    class="d-flex align-items-center justify-content-between py-1 px-2 border-bottom c-pointer"
                    [ngClass]="{
                        'bg-primary': categorieParente.key === selectedCategorieOuvrageParent?.code,
                        'text-white': categorieParente.key === selectedCategorieOuvrageParent?.code
                    }"
                    (click)="handleSelectCategorieOuvrageParent(categorieParente.key)"
                >
                    <span class="text-small">
                        ({{ categorieParente.value }})
                        {{ mapCategoriesOuvrages.get(categorieParente.key).nom }}
                    </span>
                    <div>
                        <button
                            mat-icon-button
                            matTooltip="Supprimer les ouvrages"
                            class="warn"
                            [disabled]="readonlyMode"
                            (click)="handleSupprimerCategorie($event, categorieParente.key)"
                        >
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="d-flex flex-grow-0 align-items-center justify-content-center p-2">
            <button mat-raised-button color="accent" class="my-auto mx-1" [disabled]="readonlyMode" (click)="handleRetourChoixCategoriesOuvrages()">
                <span>Ajouter</span>
            </button>
        </div>
    </div>
</div>

<div
    *ngIf="
        !readonlyMode &&
        ((!selectedCategorieOuvrage && !selectedCategorieOuvrageParent) || mapCategoriesParentes.get(selectedCategorieOuvrageParent?.code) === 0)
    "
    class="d-flex flex-column w-80 h-100 bg-ultralight justify-content-center m-0 border-left"
>
    <div class="w-100 flex-grow-0 d-flex justify-content-start align-items-center p-2 border-bottom">
        <mat-form-field appearance="outline" class="w-25 mr-2 search-form-field white-search-form-field small-search-form-field">
            <mat-icon matPrefix>search</mat-icon>
            <input
                matInput
                type="text"
                (keyup)="onKeyupSearchCategoriesOuvrages($event)"
                [(ngModel)]="searchCategoriesOuvrages"
                placeholder="Rechercher"
            />
            <button *ngIf="searchCategoriesOuvrages" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchCategoriesOuvrages()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <mat-chip-list *ngIf="selectedCategorieOuvrageRacine">
            <mat-chip [removable]="true">
                {{ selectedCategorieOuvrageRacine.nom }}
                <mat-icon (click)="handleRemoveSelectedCategorieOuvrageRacine()">close</mat-icon>
            </mat-chip>
        </mat-chip-list>
    </div>
    <div class="flex-grow-1 overflow-auto">
        <ng-container *ngIf="!selectedCategorieOuvrageRacine">
            <div *ngIf="listCategoriesOuvragesRacineToDisplay.length > 0" class="d-flex row no-gutters justify-content-center">
                <mat-card
                    *ngFor="let categorieOuvrageRacine of listCategoriesOuvragesRacineToDisplay"
                    class="d-flex col-2 categorie-ouvrage-card c-pointer"
                >
                    <mat-card-content
                        class="w-100 h-100 mh-100 d-flex flex-column justify-content-center align-items-center text-center"
                        (click)="handleSelectCategorieOuvrageRacine(categorieOuvrageRacine)"
                    >
                        {{ categorieOuvrageRacine.nom }}
                    </mat-card-content>
                </mat-card>
            </div>
            <div
                *ngIf="listCategoriesOuvragesRacineToDisplay.length === 0"
                class="d-flex row no-gutters flex-grow-1 justify-content-center overflow-auto"
            >
                Aucune catégorie supplémentaire ne correspond aux critères
            </div>
        </ng-container>
        <ng-container *ngIf="selectedCategorieOuvrageRacine">
            <div *ngIf="listCategoriesOuvragesToDisplay.length > 0" class="d-flex row no-gutters justify-content-center">
                <mat-card *ngFor="let categorieOuvrage of listCategoriesOuvragesToDisplay" class="d-flex col-2 categorie-ouvrage-card c-pointer">
                    <mat-card-content
                        class="w-100 h-100 mh-100 d-flex flex-column justify-content-center align-items-center text-center"
                        (click)="handleAjouterCategorieOuvrage(categorieOuvrage)"
                    >
                        {{ categorieOuvrage.nom }}
                    </mat-card-content>
                </mat-card>
            </div>
            <div *ngIf="listCategoriesOuvragesToDisplay.length === 0" class="d-flex row no-gutters flex-grow-1 justify-content-center overflow-auto">
                Aucune catégorie supplémentaire ne correspond aux critères
            </div>
        </ng-container>
    </div>
</div>

<div
    *ngIf="selectedCategorieOuvrageParent || selectedCategorieOuvrage"
    class="d-flex flex-column w-100 bg-ultralight h-100 justify-content-center m-0 border-left"
    [ngClass]="{
        'w-80': !selectedCategorieOuvrage && !selectedCategorieOuvrageParent
    }"
>
    <div class="w-100 flex-grow-0 d-flex align-items-center border-bottom">
        <button mat-icon-button class="m-2" (click)="handleRetourChoixCategoriesOuvrages()">
            <lib-icon>chevron_left</lib-icon>
        </button>
        <span>{{ selectedCategorieOuvrageParent ? selectedCategorieOuvrageParent.nom : selectedCategorieOuvrage.nom }}</span>
    </div>
    <div *ngIf="selectedCategorieOuvrage" class="row no-gutters flex-grow-1 overflow-auto">
        <div class="d-flex row no-gutters flex-grow-1 justify-content-center align-items-center overflow-auto">
            <mat-card *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" class="d-flex count-ouvrage-card c-pointer">
                <mat-card-content
                    class="w-100 h-100 mh-100 d-flex flex-column justify-content-center align-items-center text-center"
                    (click)="handleAjouterNombreOuvrages(i)"
                >
                    {{ i }}
                </mat-card-content>
            </mat-card>
            <mat-card class="d-flex count-ouvrage-card c-pointer">
                <mat-card-content
                    class="w-100 h-100 mh-100 d-flex flex-column justify-content-center align-items-center text-center"
                    (click)="handleAjouterAutreNbOuvrages()"
                >
                    Autre
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <app-description-bien-modal-ouvrages-parties
        *ngIf="selectedCategorieOuvrageParent"
        class="row no-gutters flex-grow-1 overflow-hidden"
        [readonlyMode]="readonlyMode"
        [bien]="bien"
        [codeCategorieOuvrageSelected]="selectedCategorieOuvrageParent.code"
        [currentVolume]="currentVolume"
        [mapCategoriesOuvrages]="mapCategoriesOuvrages"
        (ajouterOuvrageOfCategorie)="handleAjouterOuvrageOfCategorie($event)"
        (refreshCategoriesOuvragesParents)="handleRefreshCategoriesOuvragesParents()"
    ></app-description-bien-modal-ouvrages-parties>
</div>
