<div class="toolbar">
    <mat-toolbar class="border-bottom-1">
        <div>
            <lib-icon [class]="'icon-before-text'">rule</lib-icon>
            <span>Règles</span>
        </div>
        <div class="flex-grow-1"></div>
        <div>
            <lib-icon [class]="'icon-before-text warn'">cloud_off</lib-icon>
            <span>User</span>
        </div>
    </mat-toolbar>
</div>
<div class="flex-grow-1 main-background overflow-auto">
    <div class="container">
        <div class="row py-4">
            <div class="col-xl-6">
                <mat-card class="p-2 d-flex flex-column justify-content-between">
                    <mat-card-content>
                        <div class="d-flex p-2 align-items-center">
                            <div class="w-50 mat-subheading-2 m-0 primary">Import d'une règle</div>
                            <div class="w-50 pr-2">
                                <div>
                                    <mat-form-field appearance="fill">
                                        <mat-label>Référence Prestation</mat-label>
                                        <mat-select [(value)]="selectedRefPresta">
                                            <mat-option></mat-option>
                                            <mat-option *ngFor="let rp of referencePrestations" [value]="rp.id">
                                                {{ rp.nom }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field appearance="fill">
                                        <mat-label>Type de règle</mat-label>
                                        <mat-select [(value)]="selectedTypeRegle">
                                            <mat-option></mat-option>
                                            <mat-option *ngFor="let tr of typeRegles" [value]="tr">
                                                {{ tr }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <mat-card class="d-flex action-card">
                                    <mat-card-content class="w-100">
                                        <lib-file-uploader
                                            #fileUploader
                                            [isImage]="false"
                                            (startUpload)="uploadRule($event)"
                                            (uploadFinished)="uploadFileFinish($event)"
                                        ></lib-file-uploader>
                                        <button
                                            class="w-100"
                                            mat-button
                                            color="primary"
                                            (click)="fileUploader.selectFile()"
                                            matTooltip="Changer un fichier de règle"
                                        >
                                            <lib-icon class="icon-small">file_upload</lib-icon>
                                            <br />Changer un fichier de règle
                                        </button>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>
