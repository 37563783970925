<mat-form-field
    class="custom-select-container"
    [ngClass]="matFormFieldClass"
    [class.mat-input-readonly]="readonly"
    appearance="standard"
>
    <mat-label *ngIf="placeholder">{{ placeholder }}</mat-label>

    <ng-container *ngIf="!selectControl">
        <mat-select
            #mySelect
            [disableOptionCentering]="true"
            [multiple]="multiple"
            [(value)]="_selectedValue"
            panelClass="custom-select"
            [class.readonly]="readonly"
            [disabled]="disabled || readonly"
            [aria-labelledby]=""
            [required]="required"
        >
            <ng-container *ngIf="showGroup">
                <mat-select-filter
                    *ngIf="filter"
                    [array]="_groupChoices"
                    hasGroup="true"
                    [placeholder]="'Rechercher...'"
                    groupArrayName="items"
                    [displayMember]="pathValueDisplay && pathValueDisplay.length === 1 ? pathValueDisplay[0] : 'label'"
                    (filteredReturn)="filteredGroupChoices = $event"
                ></mat-select-filter>
            </ng-container>
            <ng-container *ngIf="!showGroup">
                <mat-select-filter
                    *ngIf="filter"
                    [array]="_choices"
                    [placeholder]="'Rechercher...'"
                    [displayMember]="pathValueDisplay && pathValueDisplay.length === 1 ? pathValueDisplay[0] : null"
                    (filteredReturn)="filteredList = $event"
                ></mat-select-filter>
            </ng-container>
            <div [class.with-filter]="filter" [class.with-add-line]="addline">
                <!-- Group-->
                <ng-container *ngIf="showGroup">
                    <mat-option
                        *ngIf="emptyOptionActivate"
                        (onSelectionChange)="selectionChanged('', $event)"
                    ></mat-option>
                    <mat-optgroup *ngFor="let group of filteredGroupChoices" [label]="group.label">
                        <mat-option
                            *ngFor="let item of group.items"
                            [value]="item.label"
                            (onSelectionChange)="selectionChanged(item.label, $event)"
                        >
                            {{ item.label }}
                        </mat-option>
                    </mat-optgroup>
                </ng-container>
                <!-- No Group-->
                <ng-container *ngIf="!showGroup">
                    <mat-option *ngIf="emptyOptionActivate" (onSelectionChange)="selectionChanged('', $event)"
                        >-</mat-option
                    >
                    <mat-option
                        *ngFor="let item of filteredList"
                        [value]="item"
                        [disabled]="disabledCallback(item)"
                        (onSelectionChange)="selectionChanged(item, $event)"
                    >
                        {{ item | findAttribute: pathValueDisplay }}
                    </mat-option>
                </ng-container>
            </div>
            <footer *ngIf="addline && !pathValueDisplay" class="d-flex">
                <button *ngIf="!addingElement" mat-button color="primary" class="w-100" (click)="showAddInput()">
                    <lib-icon>add_circle</lib-icon>
                    {{ addLineButtonLabel }}
                </button>
                <div *ngIf="addingElement" class="d-flex w-100 mx-2">
                    <mat-form-field class="flex-grow-1">
                        <input
                            placeholder="{{ placeholder }}"
                            matInput
                            type="text"
                            #input
                            (keydown.enter)="addEntry(input.value, $event); mySelect.close(); $event.stopPropagation()"
                            (keydown.space)="$event.stopPropagation()"
                        />
                    </mat-form-field>
                    <div class="pt-1">
                        <button mat-icon-button color="warn" matTooltip="Annuler" (click)="hideAddInput()">
                            <lib-icon>cancel</lib-icon>
                        </button>
                        <button
                            mat-icon-button
                            color="primary"
                            [disabled]="!input.value"
                            matTooltip="Ajouter"
                            (click)="addEntry(input.value, $event); mySelect.close()"
                        >
                            <lib-icon>add_circle</lib-icon>
                        </button>
                    </div>
                </div>
            </footer>
        </mat-select>
    </ng-container>

    <!--    TODO factoriser ?-->
    <ng-container *ngIf="selectControl">
        <mat-select
            [formControl]="selectControl"
            #mySelect
            [disableOptionCentering]="true"
            [multiple]="multiple"
            [(value)]="_selectedValue"
            panelClass="custom-select"
            [class.readonly]="readonly"
            [required]="required"
        >
            <!-- Group-->
            <ng-container *ngIf="showGroup">
                <mat-select-filter
                    *ngIf="filter"
                    [array]="_groupChoices"
                    hasGroup="true"
                    [placeholder]="'Rechercher...'"
                    groupArrayName="items"
                    [displayMember]="pathValueDisplay && pathValueDisplay.length === 1 ? pathValueDisplay[0] : 'label'"
                    (filteredReturn)="filteredGroupChoices = $event"
                ></mat-select-filter>
            </ng-container>
            <!-- No Group-->
            <ng-container *ngIf="!showGroup">
                <mat-select-filter
                    *ngIf="filter"
                    [array]="_choices"
                    [placeholder]="'Rechercher...'"
                    [displayMember]="pathValueDisplay && pathValueDisplay.length === 1 ? pathValueDisplay[0] : 'label'"
                    (filteredReturn)="filteredList = $event"
                ></mat-select-filter>
            </ng-container>
            <div [class.with-filter]="filter" [class.with-add-line]="addline">
                <!-- Group-->
                <ng-container *ngIf="showGroup">
                    <mat-option *ngIf="emptyOptionActivate" (onSelectionChange)="selectionChanged('', $event)"
                        >-</mat-option
                    >
                    <mat-optgroup *ngFor="let group of filteredGroupChoices" [label]="group.label">
                        <mat-option
                            *ngFor="let item of group.items"
                            [value]="item.id"
                            (onSelectionChange)="selectionChanged(item.id, $event)"
                        >
                            {{ item.label }}
                        </mat-option>
                    </mat-optgroup>
                </ng-container>
                <!-- No Group-->
                <ng-container *ngIf="!showGroup">
                    <mat-option *ngIf="emptyOptionActivate" (onSelectionChange)="selectionChanged('', $event)"
                        >-</mat-option
                    >
                    <mat-option
                        *ngFor="let item of filteredList"
                        [value]="item"
                        (onSelectionChange)="selectionChanged(item, $event)"
                    >
                        {{
                            pathValueDisplay && pathValueDisplay.length > 0
                                ? (item | findAttribute: pathValueDisplay)
                                : item
                        }}
                    </mat-option>
                </ng-container>
            </div>
            <footer *ngIf="addline && !pathValueDisplay" class="d-flex">
                <button *ngIf="!addingElement" mat-button color="primary" class="w-100" (click)="showAddInput()">
                    <lib-icon>add_circle</lib-icon>
                    {{ addLineButtonLabel }}
                </button>
                <div *ngIf="addingElement" class="d-flex w-100 mx-2">
                    <mat-form-field class="flex-grow-1">
                        <input
                            placeholder="{{ placeholder }}"
                            matInput
                            type="text"
                            #input
                            (keydown.enter)="addEntry(input.value, $event); mySelect.close(); $event.stopPropagation()"
                            (keydown.space)="$event.stopPropagation()"
                        />
                    </mat-form-field>
                    <div class="pt-1">
                        <button mat-icon-button color="warn" matTooltip="Annuler" (click)="hideAddInput()">
                            <lib-icon>cancel</lib-icon>
                        </button>
                        <button
                            mat-icon-button
                            color="primary"
                            [disabled]="!input.value"
                            matTooltip="Ajouter"
                            (click)="addEntry(input.value, $event); mySelect.close()"
                        >
                            <lib-icon>add_circle</lib-icon>
                        </button>
                    </div>
                </div>
            </footer>
        </mat-select>
    </ng-container>
</mat-form-field>
