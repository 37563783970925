/**
 * Contient les variables d'environnement utiles à la librairie.
 * À renseigner par l'application utilisant la librairie au moment du chargement de l'application.
 */
export class Environment {
    static apiUrl: string = null;
    static managementUrl: string = null;
    static defaultLandingPage: string = null;
    static appName: string = null;
    static appVersion: string = null;

    /* Paramétrage de la gestion des utilisateurs */

    /** Possibilité de simplement archiver l'utilisateur (en plus de la suppression classique) */
    static canArchiveUser = false;

    /** Possibilité de remplacer l'utilisateur par un autre */
    static canReplaceUser = false;

    /** Possibilité de modifier le mot de passe d'un utilisateur */
    static canSetUserPassword = false;
    static apiWikipimUrl: string;
    static apiCnbimUrl: any;
}
