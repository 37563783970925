import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-select-type-photo-modal',
    templateUrl: './select-type-photo-modal.html',
    styleUrls: ['./select-type-photo-modal.scss'],
})
export class SelectTypePhotoModal implements OnInit {
    TypePhotoChoisie: boolean;

    constructor(public dialogRef: MatDialogRef<SelectTypePhotoModal>) {
        dialogRef.disableClose = false;
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth600-dialog');
    }

    PrendreUnePhoto() {
        this.dialogRef.close(true);
    }

    ImporterUnePhoto() {
        this.dialogRef.close(false);
    }

    confirm() {
        this.dialogRef.close();
    }
}
