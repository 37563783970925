<app-mpca-form-skeleton>
    <div title>Pendant travaux</div>
    <div form>
        <div [formGroup]="formPendantTravaux" class="form-pendant-travaux">
            <div class="form-input">
                <mat-label class="col-6">Niveau d'empoussièrement</mat-label>
                <mat-label>{{ formPendantTravaux.get('niveauEmpoussierement')?.value }}</mat-label>
            </div>

            <div class="form-input mt-2">
                <mat-label class="col-6">Type de masque désamianteur</mat-label>
                <mat-select formControlName="typeMasqueDesamianteur" required data-cy="typeMasqueDesamianteur">
                    <mat-option *ngFor="let item of typeMasqueConfig.items" [value]="item">
                        {{ item }}
                    </mat-option>
                </mat-select>
            </div>
        </div>
    </div>
</app-mpca-form-skeleton>
