<h1 mat-dialog-title class="primary">Sélectionnez les commentaires à afficher</h1>

<div mat-dialog-content>
    <div *ngFor="let com of data.comments" class="mb-4">
        <div class="separation">
            <div class="d-flex flex-row align-items-center py-1">
                <mat-checkbox [(ngModel)]="com.selected" (change)="changeSelection($event.checked, com)">
                    <div class="ml-3">
                        <div class="d-flex align-items-center">
                            <div class="flex-grow-1">
                                <b>{{ com.date | date: 'EEEE d MMMM y - HH:mm:ss' | titlecase }}</b>
                                ({{ com.type | typeCommentaire }})
                            </div>
                        </div>
                        <div class="comments">{{ com.contenu }}</div>
                    </div>
                </mat-checkbox>
            </div>
        </div>
    </div>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-button color="primary" (click)="cancel()">
        <lib-icon>close</lib-icon>
        <span>{{ 'Annuler' | uppercase }}</span>
    </button>
    <button mat-raised-button color="accent" (click)="confirm()">
        <lib-icon>check</lib-icon>
        <span>{{ 'Valider' | uppercase }}</span>
    </button>
</div>
