export enum competence {
    HabilitationElectriqueBR = 'Habilitation électrique (BR)',
    CACES = "Certification d'Aptitude à la Conduite d'Engins en Sécurité (CACES)",
    AIPR = 'Risque réseaux enterrés ou aériens à proximité (AIPR)',
    CATEC = "Risque en espaces confinés dans le domaine de l'eau et de l'assainissement (CATEC)",
    GICCRA_N1 = 'Risque chimique (GICCRA-N1)',
    ATEX = 'Risque en zone explosive (ATEX)',
    PP58 = 'Risque Nucléaire Sécurité conventionnelle & zone contrôlée (PP58)',
    CasierVierge = 'Casier vierge',
}

export enum competenceHAP {
    GICCRA_N1 = 'Risque chimique (GICCRA - N1)',
    CasierVierge = 'Casier vierge',
    BR = 'Habilitation électrique (BR)',
    CACES = "Certification d'Aptitude à la Conduite d'Engins en Sécurité (CACES)",
    PP58 = 'Risque Nucléaire Sécurité conventionnelle & zone contrôlée (PP58)',
    AIPR = 'Risque réseaux enterrés ou aériens à proximité (AIPR)',
    ATEX = 'Risque en zone explosive (ATEX)',
    CATEC = 'Risque en espaces confinés dans le domaine de l’eau et de l’assainissement (CATEC)',
}
