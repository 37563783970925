import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import * as moment from 'moment';

import { FiltrePrelevementMetop, PolluantPrelevement, PrelevementMetop } from 'src/app/modules/diagnostics/polluant/model/polluant-prelevement.model';
import { WidgetSelectModeEnum } from 'src/app/modules/shared/widget-select/widget-select.component';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { FiltreModalComponent } from './filtres-item-list/filtres-modal/filtre-modal.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-prelevement-form-avant',
    templateUrl: './prelevement-form-avant.component.html',
    styleUrls: ['./prelevement-form-avant.component.scss'],
})
export class PrelevementFormAvantComponent implements OnInit {
    formAvant: FormGroup;

    @Input('diagnostic')
    diagnostic: Diagnostic;
    contenuDiagnostic: PolluantPrelevement;

    @Input('selectedBesoinId')
    selectedBesoinId: string;
    selectedPrelevement: PrelevementMetop;

    @Input() // Utilisé pour passer les champs en read-only
    disabled: Boolean;

    @Output('formChange')
    formChange: EventEmitter<any> = new EventEmitter<any>();

    widgetSelectModeEnum: typeof WidgetSelectModeEnum = WidgetSelectModeEnum;

    isLoaded: Boolean = false;

    constructor(private formBuilder: FormBuilder, private dialog: MatDialog, private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
        // sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/shower.svg')
    }

    ngOnInit(): void {
        this.contenuDiagnostic = this.diagnostic.contenuDiagnostic as PolluantPrelevement;

        //Récupération du Prélèvement par l'ID de son Besoin
        this.selectedPrelevement = this.contenuDiagnostic.prelevement.data.besoinsList.find(
            (b) => b.besoinPrelevement.id == this.selectedBesoinId
        ) as PrelevementMetop;

        this.createForm();
    }

    createForm() {
        this.formAvant = this.formBuilder.group({
            faitParLeClient: [this.selectedPrelevement.informationsClient.faitParLeClient, Validators.required],
            lotFiltre: [
                this.selectedPrelevement.informationsPrelevementAvant.lotFiltre,
                {
                    validators: Validators.required,
                    updateOn: 'blur',
                },
            ],
            dureeTotalePrelevement: [
                this.selectedPrelevement.informationsPrelevementAvant.dureeTotalePrelevement,
                {
                    validators: Validators.pattern(/\-?\d*\.?\d{1,2}/),
                    updateOn: 'blur',
                },
            ],
            informationMeteo: this.formBuilder.group({
                vitesseVent: [this.selectedPrelevement.informationsMeteoAvant.vitesseVent],
                directionVent: [this.selectedPrelevement.informationsMeteoAvant.directionVent],
                temperature: [this.selectedPrelevement.informationsMeteoAvant.temperature],
                hygrometrie: [this.selectedPrelevement.informationsMeteoAvant.hygrometrie],
                pressionAtmospherique: [this.selectedPrelevement.informationsMeteoAvant.pressionAtmospherique],
                conditionMeteo: [this.selectedPrelevement.informationsMeteoAvant.conditionMeteo],
            }),
            listeFiltres: this.formBuilder.array([]),
        });

        this.selectedPrelevement.listeFiltresAvant.forEach((filtre: FiltrePrelevementMetop) => {
            (this.formAvant.get('listeFiltres') as FormArray).push(this.createFilterForm(filtre));
        });

        this.formAvant.valueChanges.subscribe((newValue) => {
            const formValues = this.formAvant.getRawValue();

            //Patch des valeurs du diagnostic avec les valeurs du formulaire
            this.selectedPrelevement.informationsClient.faitParLeClient = formValues.faitParLeClient;
            this.selectedPrelevement.informationsPrelevementAvant.lotFiltre = formValues.lotFiltre;
            this.selectedPrelevement.informationsPrelevementAvant.dureeTotalePrelevement = formValues.dureeTotalePrelevement;

            //Météo
            this.selectedPrelevement.informationsMeteoAvant.vitesseVent = formValues.informationMeteo.vitesseVent;
            this.selectedPrelevement.informationsMeteoAvant.directionVent = formValues.informationMeteo.directionVent;
            this.selectedPrelevement.informationsMeteoAvant.temperature = formValues.informationMeteo.temperature;
            this.selectedPrelevement.informationsMeteoAvant.hygrometrie = formValues.informationMeteo.hygrometrie;
            this.selectedPrelevement.informationsMeteoAvant.conditionMeteo = formValues.informationMeteo.conditionMeteo;
            this.selectedPrelevement.informationsMeteoAvant.pressionAtmospherique = formValues.informationMeteo.pressionAtmospherique;

            this.selectedPrelevement.listeFiltresAvant = formValues.listeFiltres;

            //Envoi des changements au composant parent pour sauvegarde
            this.formChange.emit(this.contenuDiagnostic);
        });
    }

    createFilterForm(filtre: FiltrePrelevementMetop): FormGroup {
        const filtreForm = this.formBuilder.group({
            numeroFiltre: [filtre.numeroFiltre, Validators.required],
            numeroPompe: [filtre.numeroPompe, Validators.required],
            debitInitial: [filtre.debitInitial, Validators.required],
            testEtancheite: [filtre.testEtancheite, Validators.required],
        });

        return filtreForm;
    }

    /**
     * @description Calcul la durée en heures entre le début et la fin du prélèvement
     */
    calculDuree() {
        if (this.formAvant == undefined) {
            return '';
        }

        if (this.formAvant.get('dateDebutPrelevement').value != '' && this.formAvant.get('dateFinPrelevement').value != '') {
            return (
                Math.round(
                    moment
                        .duration(
                            moment(this.formAvant.get('dateFinPrelevement').value).diff(moment(this.formAvant.get('dateDebutPrelevement').value))
                        )
                        .asHours()
                ) + 'h'
            );
        }
        return '';
    }

    /**
     *  @description Ouvre la modal d'édition d'un filtre avec le paramètre '-1'
     *               pour indiquer une création
     */
    ajouterFiltre() {
        this.openModal(-1);
    }

    /**
     * @description Ouvre la modal d'édition d'un processus avec l'index du processus à modifier
     * @param index Index du processus à modifier
     */
    modifierFiltre(index: number) {
        this.openModal(index);
    }

    /**
     * Méthode appellée pour supprimer un processus de MPCA à l'index donné
     * @param index Index du processus à supprimer
     */
    supprimerFiltre(index: number) {
        (this.formAvant.get('listeFiltres') as FormArray).removeAt(index);
    }

    /**
     * Méthode appellée pour dupliquer un processus de MPCA
     * @param index Index du processus à dupliquer
     */
    dupliquerFiltre(index: number) {
        const processusToInsert = this.createFilterForm((this.formAvant.get('listeFiltres') as FormArray).value[index]);
        (this.formAvant.get('listeFiltres') as FormArray).insert(1, processusToInsert);
    }

    /**
     *
     * @param index Index du processus avec lequel initialiser le formulaire
     * Dans le cas d'une création, l'index est à '-1' pour initialiser le formulaire à vide
     */
    openModal(index) {
        let filtre: FiltrePrelevementMetop = new FiltrePrelevementMetop();
        if (index != -1) {
            filtre = (this.formAvant.get('listeFiltres') as FormArray).value[index];
        }

        const dialogRef = this.dialog.open(FiltreModalComponent, {
            width: '250px',
            data: {
                filtre,
                index,
            },
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result.index == '-1') {
                //Dans le cas d'une création
                //On ajoute le formulaire du filtre créé à la liste des filtres
                (this.formAvant.get('listeFiltres') as FormArray).push(this.createFilterForm(result.form));
            } else {
                //Dans le cas d'une mise à jour
                //On patch les valeurs du filtre avec les valeurs retournées
                (this.formAvant.get('listeFiltres') as FormArray).controls[result.index].patchValue({
                    numeroFiltre: result.form.numeroFiltre,
                    numeroPompe: result.form.numeroPompe,
                    debitInitial: result.form.debitInitial,
                    testEtancheite: result.form.testEtancheite,
                });
            }
        });
    }

    /**
     * @description Méthode appellée pour imprimer l'étiquette liée au prélèvement
     */
    public impressionEtiquette() {
        //A implémenter
    }
}
