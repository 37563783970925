export const SVG_FILTER = `
                    <defs>
                        <filter
                            id="object_shadow"
                            x="-20%"
                            y="-20%"
                            width="140%"
                            height="140%"
                            filterUnits="objectBoundingBox"
                            primitiveUnits="userSpaceOnUse"
                            color-interpolation-filters="linearRGB"
                        >
                            <feMorphology
                                operator="dilate"
                                radius="1 1"
                                in="SourceAlpha"
                                result="morphology"
                            ></feMorphology>
                            <feFlood flood-color="#000000" flood-opacity="1" result="flood"></feFlood>
                            <feComposite in="flood" in2="morphology" operator="in" result="composite"></feComposite>
                            <feMerge result="merge">
                                <feMergeNode in="composite" result="mergeNode"></feMergeNode>
                                <feMergeNode in="SourceGraphic" result="mergeNode1"></feMergeNode>
                            </feMerge>
                        </filter>
                        <filter id='icon_shadow' x='-20%' y='-20%' width='140%' height='140%' filterUnits='objectBoundingBox' primitiveUnits='userSpaceOnUse' color-interpolation-filters='linearRGB'>
                            <feMorphology operator='erode' radius='0.4' in='SourceAlpha' result='morphology'/>
                        </filter>
                    </defs>
`;

export const STYLE_ELEMENT_CONFORME = `style="fill:#6dc911;opacity: 0.7;stroke-width: 1px;`;
export const STYLE_ELEMENT_A_JUSTIFIER = `style="fill:#fd7313;opacity: 0.7;stroke-width: 1px;`;
export const STYLE_ELEMENT_NON_CONFORME = `style="fill: #ff0000;opacity: 0.7;stroke-width: 1px;`;
export const STYLE_ELEMENT_NON_VERIFIE = `style="fill:#727272;opacity: 0.7;stroke-width: 1px;`;
