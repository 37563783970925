import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

export class FormControlErrorMessageTextConfig {
    required? = 'Requis';
    pattern? = 'Format incorrect';
    email? = 'Format incorrect';
    erreurNomPerimetreExistant? = 'Un autre périmètre possède déjà le même nom';
    referenceExistante? = 'Cette référence existe déjà';
    latitudeError? = `La latitude n'est pas correcte`;
    longitudeError? = `La longitude n'est pas correcte`;
    matDatepickerParse? = `: format de date valide`;
    erreurNomDoc? = 'Un autre document possède déjà le même nom dans cette intervention';
}

@Component({
    selector: 'app-form-control-error-message',
    templateUrl: './form-control-error-message.component.html',
    styleUrls: ['./form-control-error-message.component.scss'],
})
export class FormControlErrorMessageComponent {
    @Input() control: FormControl | AbstractControl;

    @Input() set textConfig(textConfig: FormControlErrorMessageTextConfig) {
        // On merge car les paramètres de personnalisation sont optionnels.
        const originalTextConfig = new FormControlErrorMessageTextConfig();
        this._textConfig = Object.assign(originalTextConfig, textConfig);
    }

    get textConfig(): FormControlErrorMessageTextConfig {
        return this._textConfig;
    }

    private _textConfig = new FormControlErrorMessageTextConfig();
}
