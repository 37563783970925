/**
 * Description d'un choix pour le input-component
 */
import { Icon } from 'src/app/commons-lib';

export type StateChoiceBoxesClass = 'dark' | 'ok' | 'ko' | 'warning';

export class StateChoiceBoxes {
    constructor(public label: string, public value: string | boolean, public icon: Icon, public cssClass: StateChoiceBoxesClass = 'dark') {}
}
