<div class="toolbar">
    <mat-toolbar class="border-bottom-1 primary">
        <button mat-button class="px-0" (click)="back()">
            <lib-icon>arrow_back</lib-icon>
        </button>
        <div>
            <lib-icon [class]="'icon-before-text'">settings</lib-icon>
            <span>Gestion des catégories d'équipements</span>
        </div>
        <div class="flex-grow-1"></div>
        <div>
            <span>User</span>
        </div>
    </mat-toolbar>
</div>
<div class="flex-grow-1 main-background">
    <div class="p-4">
        <div class="row">
            <!-- Colonne gauche-->
            <div class="col-xl-6">
                <div>
                    <button color="accent" mat-raised-button class="bt-valid" (click)="creerCategorieEquipement()">
                        <lib-icon>add</lib-icon>
                        {{ 'Créer une catégorie' | uppercase }}
                    </button>
                </div>
                <div class="row no-gutters p-4">
                    <app-tree-categorie-equipements
                        (selectedCategorieEvent)="selectCurrentCategorie($event)"
                        [idCategorieSelected]="idCategorie"
                        style="width: 100%"
                    ></app-tree-categorie-equipements>
                </div>
                <div class="row no-gutters p-4">
                    <app-view-liste-equipements
                        [categorie]="currentCategorie"
                        (selectedEquipementEvent)="selectCurrentEquipement($event)"
                        (createEquipementEvent)="createEquipement($event)"
                    ></app-view-liste-equipements>
                </div>
            </div>
            <!-- Colonne droite-->
            <div class="col-xl-6" *ngIf="currentCategorie && currentEquipement == null">
                <app-view-details-categorie-equipement
                    *ngIf="listeAllCategories$ | async as listeAllCategories"
                    [categorie]="currentCategorie"
                    [listeAllCategories]="listeAllCategories"
                    (deletedCategorieEquipementEvent)="deletedCurrentCategorieEquipement($event)"
                ></app-view-details-categorie-equipement>
            </div>
            <div class="col-xl-6" *ngIf="currentEquipement">
                <app-view-details-equipement
                    [equipement]="currentEquipement"
                    (deletedEquipementEvent)="deletedCurrentEquipement($event)"
                ></app-view-details-equipement>
            </div>
        </div>
    </div>
</div>
