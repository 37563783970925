import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { Bien, Niveau, ViewMode, Volume } from '../../../../model/bien.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AddNiveauModalComponent } from '../add-niveau-modal/add-niveau-modal.component';
import { InterventionService } from '../../../../services/intervention.service';
import { MatDialog } from '@angular/material/dialog';
import { ExigenceDescription, Intervention } from '../../../../model/intervention.model';
import { EditorHandler } from '../../../shared/map/editor-map/handler/editor-handler';
import { ActivatedRoute } from '@angular/router';
import { PARAM_VOLUME_VISITE, VOLUME_VISITE } from '../../../../shared/constants/cndiag.constants';
import { DescriptionBienService } from '../../../../services/description-bien.service';

@Component({
    selector: 'app-description-bien-choix-niveau',
    templateUrl: './description-bien-choix-niveau.component.html',
    styleUrls: ['./description-bien-choix-niveau.component.scss'],
})
export class DescriptionBienChoixNiveauComponent extends BaseComponent {
    @Input()
    intervention: Intervention;

    @Input()
    currentBien: Bien;

    @Input()
    readonlyMode: boolean = false;

    @Input()
    selectedNiveau: Niveau;

    @Input()
    currentVolume: Volume;

    @Input()
    descriptionDirecte: boolean = false;

    @Input()
    viewMode: ViewMode;

    @Output()
    selectedNiveauChange: EventEmitter<Niveau> = new EventEmitter<Niveau>();

    @Output()
    currentVolumeChange: EventEmitter<Volume> = new EventEmitter<Volume>();

    @Output()
    descriptionDirecteChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    ViewMode = ViewMode;
    constructor(
        private readonly interventionService: InterventionService,
        private readonly matDialog: MatDialog,
        private readonly editorHandler: EditorHandler,
        private readonly activatedRoute: ActivatedRoute,
        private readonly descriptionBienService: DescriptionBienService
    ) {
        super();
    }

    ngOnInit() {
        this.refreshJustificationNiveaux();
    }

    changeNiveau(niveau: Niveau) {
        this.selectedNiveau = niveau;
        this.selectedNiveauChange.emit(this.selectedNiveau);
    }

    addNiveau() {
        return this.matDialog
            .open(AddNiveauModalComponent, {
                data: {
                    bien: this.currentBien,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    const volumeCache = new Volume();
                    volumeCache.nom = 'Extérieur';
                    volumeCache.volumeCache = true;
                    result.niveau.volumes.push(volumeCache);
                    result.niveau.index = this.currentBien.description.length;
                    this.currentBien.description.push(result.niveau);
                    this.selectedNiveau = this.currentBien.description[this.currentBien.description.length - 1];
                    this.selectedNiveauChange.emit(this.selectedNiveau);
                    this.currentVolume = null;
                    this.currentVolumeChange.emit(this.currentVolume);
                    // this.volumeCache = this.currentStair.volumes.find((it) => it.volumeCache);
                    // this.interventionService.updateIntervention(this.intervention).subscribe(
                    //     () => {
                    //         // this.createRoomIndexes();
                    //         // this.checkValidity();
                    //         this.cnSpinnerService.hide();
                    //     },
                    //     () => this.cnSpinnerService.hide()
                    // );
                }
            });
    }

    dropNiveau(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.currentBien.description, event.previousIndex, event.currentIndex);
        this.currentBien.description.forEach((niv) => {
            if (niv.storeyId) {
                // on ne gère pas les niveaux avec plan cnmap.
                return;
            }
            niv.index = this.currentBien.description.indexOf(niv);

            niv.volumes.forEach((vol) => (vol.nom = this.descriptionBienService.generateVolumeName(vol, niv)));
        });
        // if (this.viewMode === 'plan') {
        //     moveItemInArray(this.bienBuilding.storeys, event.previousIndex, event.currentIndex);
        // }
    }

    handleClickReprendreVisite() {
        this.currentVolume = null;
        this.currentVolumeChange.emit(this.currentVolume);
    }

    handleDescriptionDirecteChange($event) {
        this.descriptionDirecte = $event.checked;
        this.descriptionDirecteChange.emit(this.descriptionDirecte);
    }

    handleGoToEditeur() {
        const state = this.selectedNiveau.storeyId
            ? {
                  storeyIndex: this.selectedNiveau.index,
              }
            : null;

        let toUrl = `/interventions/${this.intervention.id}/bien/${this.currentBien.id}/editor`;
        if (this.intervention.exigenceDescription === ExigenceDescription.CROQUIS) {
            toUrl += '/map/sketch';
        }
        if (this.currentBien.description.length && this.currentBien.jsonPlan === undefined) {
            this.editorHandler.navigateToEditor(toUrl, null, state);
        } else {
            this.editorHandler.navigateToEditor(toUrl, this.activatedRoute, state);
        }
    }

    refreshJustificationNiveaux() {
        this.currentBien?.description.forEach(
            (niveau) =>
                (niveau['justifie'] =
                    niveau.volumes.filter(
                        (it) =>
                            !it.valeursParametres[PARAM_VOLUME_VISITE] ||
                            (it.valeursParametres[PARAM_VOLUME_VISITE] &&
                                (it.valeursParametres[PARAM_VOLUME_VISITE] === VOLUME_VISITE.KO ||
                                    it.valeursParametres[PARAM_VOLUME_VISITE] === VOLUME_VISITE.WARNING) &&
                                !it.justifie)
                    ).length > 0)
        );
    }
}
