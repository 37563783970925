import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-logement-conduits-metalliques',
    templateUrl: './logement-conduits-metalliques.component.html',
    styleUrls: ['./logement-conduits-metalliques.component.scss'],
})
export class LogementConduitsMetalliquesComponent implements OnInit {
    readonly MENU_NAME = 'Conduits métalliques';

    constructor() {}

    ngOnInit(): void {}
}
