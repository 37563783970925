import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-card-validation',
    templateUrl: './card-validation.component.html',
    styleUrls: ['./card-validation.component.scss'],
})
export class CardValidationComponent implements OnInit {
    @Input() isValid = false;
    @Input() disabled = false;
    @Output() isValidChange = new EventEmitter<boolean>();

    constructor() {}

    ngOnInit(): void {}

    onClickUpdate() {
        this.isValid = false;
        this.isValidChange.emit(this.isValid);
    }

    onClickValidate() {
        this.isValid = true;
        this.isValidChange.emit(this.isValid);
    }
}
