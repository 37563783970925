<div class="zone-surface-panel" *ngIf="biens != undefined">
    <app-bien-niveau-select
        [biens]="biens"
        [column]="true"
        (niveauChanged)="onNiveauChange($event?.nom)"
        [includeAll]="includeAllNiveaux"
    >
    </app-bien-niveau-select>

    <div class="header">
        <div class="header-row">
            <app-widget-select
                theme="dark"
                class="app-widget-input"
                [(ngModel)]="indexZoneSelected"
                (ngModelChange)="zoneChanged()"
            >
                <option *ngFor="let zone of zones.concat([extraZone]); let index = index" [value]="index">
                    {{ zone.identifiant != '' ? zone.identifiant + ': ' + zone.nom : '' }}
                </option>
            </app-widget-select>
            <app-next-previous-button
                class="next-previous"
                [previousDisabled]="indexZoneSelected == 0"
                [nextDisabled]="indexZoneSelected == zones.length - 1"
                (clicked)="navigationZone($event)"
            >
            </app-next-previous-button>
        </div>
    </div>
    <div class="content">
        <ng-content></ng-content>
        <div *ngFor="let selectedZone of selectedZones">
            <app-piece-unitaire-besoin
                class="expension-panel"
                *ngFor="let pu of selectedZone.listeSurfaces; let index = index"
                [pieceUnitaire]="pu"
                [indexBesoinSelected]="indexBesoinSelected"
                [index]="index"
                [volumes]="volumesInNiveau"
                [selectedZones]="selectedZones"
                [zone]="selectedZone"
                [besoins]="besoins"
                (besoinActionEmitter)="besoinAction($event)"
                [isInEditMode]="isInEditMode"
                [showActions]="showActions"
            >
            </app-piece-unitaire-besoin>
        </div>
    </div>
</div>
