'use strict';
//***********************************************************************************
//***********************************************************************************
//**** Rotation handler
//***********************************************************************************
//***********************************************************************************

import { cn_event_handler } from './cn_event_handler';
import { cn_mouse_event } from './cn_mouse_event';
import {
    cn_add,
    cn_cart,
    cn_clone,
    cn_dist,
    cn_dot,
    cn_is_clockwise,
    cn_middle,
    cn_mul,
    cn_normal,
    cn_normalize,
    cn_polar,
    cn_sub,
    cnx_add,
    cnx_clone,
    cnx_cross,
    cnx_mul,
    cnx_normalize,
    cnx_sub
} from '../utils/cn_utilities';
import { cn_camera } from './cn_camera';
import { cn_scene } from '../model/cn_scene';
import { cn_event_manager } from './cn_event_manager';
import { cn_number_input } from './cn_inputs';
import { cn_roof } from '../model/cn_roof';

export class cn_handler_wall_distance extends cn_event_handler {
    //*****************************************************************
    /**
     *
     * @param {cn_scene} scene
     * @param {cn_event_manager} parent
     */
    constructor(scene, parent) {
        super(parent);
        this.segments = [];
        this.scene = scene;

        this._dimension_boxes = [];
        this._mouseover = -1;
        this.altitude = 0;
    }

    /**
     * Fills handler with a contour, reversing orientation if necessary.
     * @param {Array<number[]>} contour
     */
    from_contour(contour) {
        const ctr = contour.concat([]);

        if (cn_is_clockwise(ctr))
            ctr.reverse();

        this.segments = [];
        const sz = ctr.length;
        for (var i = 0; i < sz; i++)
            this.segments.push([ctr[i], ctr[(i + 1) % sz]]);
    }

    //*****************************************************************
    /**
     * Draw the handler
     * @param {cn_camera} camera
     * @returns {string}
     */
    draw(camera) {
        var html = '';

        //*** Compute orientation of the contour */
        this._dimension_boxes = [];
        this.segments.forEach(segment => {
            const v0 = segment[0];
            const v1 = segment[1];

            const dir = cn_sub(v1, v0);
            var nor = cn_normal(dir);
            cn_normalize(nor);
            var p0 = cn_middle(v0, v1);
            var impact = null;
            if (this.scene.constructor == cn_scene)
                impact = this.scene.raytrace_bounds(p0, nor);
            else if (this.scene.constructor == cn_roof)
                // @ts-ignore
                impact = this.scene.raytrace(p0, nor);

            if (impact) {
                const index = this._dimension_boxes.length;
                const box = { direction: nor, p0: p0, p1: impact.point, measure: [0, 0] };
                this._dimension_boxes.push(box);
                html += camera.draw_measure(cnx_clone(p0, this.altitude), cnx_clone(impact.point, this.altitude), box.measure, this._mouseover == index, 0);
            }
        });

        return html;
    }

    //*****************************************************************
    /**
     * Clear move data
     */
    clear_move() {
        this._mouseover = -1;
    }

    /**
     * Manage a passive move. To return 'true' if something of interest under the mouse.
     * @param {cn_mouse_event} ev
     * @returns  {boolean}
     */
    click(ev) {
        if (this._mouseover >= 0 && this._mouseover < this._dimension_boxes.length) {

            const box = this._dimension_boxes[this._mouseover];
            const old_value = cn_dist(box.p0, box.p1);
            const input = new cn_number_input("Distance au mur:", old_value, "m", 3, 0, 10000);
            input.callback = () => this.call("change", cn_mul(box.direction, old_value - input.value));
            this.call('number_input', input);
            return true;
        }
        return false;
    }

    /**
     * Manage a passive move. To return 'true' if something of interest under the mouse.
     * @param {cn_mouse_event} ev
     * @returns  {boolean}
     */
    move(ev) {
        this._mouseover = this._find_mouseover(ev);
        return this._mouseover >= 0;
    }

    /**
     * Manage a grab. To return 'true' if grab is to be managed.
     * @param {cn_mouse_event} ev
     * @returns  {boolean}
     */
    grab(ev) {
        this._mouseover = this._find_mouseover(ev);
        return this._mouseover >= 0;
    }

    /**
     * Manage a drag. Only after a grab that returned true. To return 'true' if drag had an effect.
     * @param {cn_mouse_event} ev
     * @returns  {boolean}
     */
    drag(ev) {
        return this._mouseover >= 0;
    }

    //***********************************************************************************
    //**** Check what is under mouse
    //***********************************************************************************
    _find_mouseover(ev) {

        function mouseover_measure(measure) {
            if (Math.abs(measure[0] - ev.mouse_screen[0]) > 30) return false;
            if (Math.abs(measure[1] - ev.mouse_screen[1]) > 10) return false;
            return true;
        }

        return this._dimension_boxes.findIndex(b => mouseover_measure(b.measure));
    }
}

