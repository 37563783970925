import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-logement-inadapte',
    templateUrl: './logement-inadapte.component.html',
    styleUrls: ['./logement-inadapte.component.scss'],
})
export class LogementInadapteComponent implements OnInit {
    readonly MENU_NAME = 'Inadapté';

    constructor() {}

    ngOnInit(): void {}
}
