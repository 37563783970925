import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Commentaire } from '../../../model/commentaire.model';
import { TypeCommentaire, enumTypesCommentaire } from '../../../model/type-commentaire.model';
import { BaseComponent, ConfirmationService, DateUtils, FileUploaderComponent, MongoUtils, NotificationService } from 'src/app/commons-lib';
import { PointDeControleElement } from '../../../model/point-de-controle.model';
import { CommentairePredefini } from '../../../model/commentaire-predefini.model';
import { combineLatest, Observable, of } from 'rxjs';
import { DiagnosticService } from '../../../services/diagnostic.service';
import { InterventionService } from '../../../services/intervention.service';
import { Intervention } from '../../../model/intervention.model';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { CnSpinnerService } from '../cn-spinner/service/cn-spinner.service';
import { ReferenceService } from 'src/app/services/reference.service';
import { InterventionFile, TypeReferenceFichier } from '../../../model/intervention-file.model';
import { Diagnostic } from '../../../model/diagnostic.model';
import { combineLatestOrEmpty } from '../../../utils/rxjs.utils';
import { InterventionFileService } from '../../../services/intervention-file.service';
import { FileUploaderOutput, FileUploaderTextConfigFile } from '../file-uploader/wizy-file-uploader.component';
import { TypePrestationEnum } from 'src/app/model/type-prestation.model';
import { Bien } from 'src/app/model/bien.model';
import { CustomSelectGroup, ItemCustomSelect } from '../custom-select/custom-select.model';

class CommentModalData {
    constructor(
        public commentairesId: string[],
        public typesCommentaires: TypeCommentaire[],
        public typeNouveauCommentaire: TypeCommentaire,
        public replaceTypesCommentaires = false,
        public elements: any[],
        public elementPathValueDisplay: string[] | ((item: any) => string) = ['nom'],
        public elementsAreRemovable: true,
        public isDescription = false,
        public localisation: CustomSelectGroup[] = null
    ) { }
}

@Component({
    selector: 'app-comment-modal',
    templateUrl: './comment-modal.component.html',
    styleUrls: ['./comment-modal.component.scss'],
})
export class CommentModalComponent extends BaseComponent implements OnInit {
    enumTypesCommentaire = enumTypesCommentaire;
    disabledSave: boolean = true;
    readonlyMode: boolean = true;
    file: any;

    intervention: Intervention;
    elements: any[] = [];
    elementPathValueDisplay: string[] | ((item: any) => string) = ['nom'];
    elementsAreRemovable: true;
    typeCommentairePredefini = false;
    commentTypes: TypeCommentaire[] = [enumTypesCommentaire.CONSTATATION_DIVERSE, enumTypesCommentaire.NOTE_PERSONNELLE];
    nouveauCommentaire: Commentaire;
    nouveauCommentaireTemp: Commentaire;
    // Ceci est un test dans le but de tester une nouvelle fonctionalité
    commentairesAjoutes: Commentaire[];

    //afficher la localisation si null on affiche pas
    localisation?: CustomSelectGroup[] = null;

    commentaires: Commentaire[] = [];
    reponsesPredefinisFiltered: string[] = [];
    intitule: string = null;
    removable = false;
    isDescription = false;
    diagnostic: Diagnostic;
    editmode: boolean = false;

    // Pictures
    typeReferenceFichierPicture = TypeReferenceFichier.PHOTO_COMMENTAIRE;
    fileUploaderTextConfig: FileUploaderTextConfigFile;
    currentInterventionFile: InterventionFile = null;
    fileUploaderOutput: FileUploaderOutput;

    // Montre si une modification est en cours
    updating: boolean = false;
    // L'index du commentaire qui a été sélectionné pour la modificatioin
    selectedCommentaireIndex: number;
    @ViewChild('fileUploader') fileUploader: FileUploaderComponent;

    constructor(
        private readonly dialogRef: MatDialogRef<CommentModalComponent>,
        @Inject(MAT_DIALOG_DATA) private readonly data: CommentModalData,
        private readonly referenceService: ReferenceService,
        private readonly diagnosticService: DiagnosticService,
        private readonly confirmationService: ConfirmationService,
        private readonly notificationService: NotificationService,
        private readonly interventionService: InterventionService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly interventionFileService: InterventionFileService
    ) {
        super();
        this.createNewEmptyCommentaire();
        this.initFileUploaderTextConfig();
    }

    ngOnInit(): void {
        // Un autre ajout ici là
        this.commentairesAjoutes = [];
        //

        this.cnSpinnerService
            .withSpinner(
                combineLatestOrEmpty([this.interventionService.getCurrentIntervention(), this.diagnosticService.getCurrentDiagnostic(false)])
            )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                ([intervention, diagnostic]) => {
                    this.intervention = intervention;
                    this.diagnostic = diagnostic;
                    this.readonlyMode = this.diagnosticService.isReadOnlyMode(intervention, diagnostic);

                    // Utilisation des données envoyées en paramètre de la modale
                    if (this.data) {
                        this.localisation = this.data.localisation;
                        if (this.data.elementPathValueDisplay && this.data.elementPathValueDisplay.length > 0) {
                            this.elementPathValueDisplay = this.data.elementPathValueDisplay;
                        }
                        this.elementsAreRemovable = this.data.elementsAreRemovable;

                        if (this.data.commentairesId) {
                            this.commentaires = this.intervention.commentaires.filter((c) => {
                                return this.data.commentairesId.indexOf(c.id) !== -1;
                            });
                        }

                        if (this.data.typesCommentaires) {
                            if (this.data.replaceTypesCommentaires) {
                                // On ne garde pas les types de commentaires par défaut
                                // Supprimer les doublons de types de commentaires après ajout de plusieurs commentaires d'affilée
                                this.commentTypes = [...new Set(this.data.typesCommentaires.sort((c1, c2) => c1.localeCompare(c2)))];
                            } else {
                                // On garde les types de commentaires par défaut
                                // Supprimer les doublons de types de commentaires après ajout de plusieurs commentaires d'affilée
                                this.commentTypes = [
                                    ...new Set(this.commentTypes.concat(this.data.typesCommentaires).sort((c1, c2) => c1.localeCompare(c2))),
                                ];
                            }
                        }
                        if (this.data.typeNouveauCommentaire) {
                            this.typeCommentairePredefini = true;
                            this.nouveauCommentaire.type = this.data.typeNouveauCommentaire;
                        }
                        if (this.data.elements && this.data.elements[0] !== undefined) {
                            this.elements = this.data.elements;
                        }
                        if (this.data.isDescription) {
                            this.isDescription = this.data.isDescription;
                        }

                        this.commentaires.forEach((comm) => {
                            if (comm.imageId != null) {
                                let interfile = this.getInterventionFile(comm.imageId);
                                interfile.subscribe((value) => {
                                    comm.imageDateTimeOriginal = new Date(+value.creationDate).toLocaleDateString();
                                    comm.imageGpsLatitudeRef = value.gpsLatitudeRef;
                                    comm.imageGpsLatitude = value.gpsLatitude;
                                    comm.imageGpsLongitudeRef = value.gpsLongitudeRef;
                                    comm.imageGpsLongitude = value.gpsLongitude;
                                    comm.imageGpsLatitudeRef = value.gpsAltitudeRef;
                                    comm.imageGpsAltitude = value.gpsAltitude;
                                });
                            }
                        });
                        // En cas de commentaires sur plusieurs éléments, avec commentaires prédéfinis
                        this.getCommentairesPredefinisFiltered();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    /**
     * Evenement de click sur le bouton de fermeture de la modale
     */
    onClickBtnClose() {
        if (this.nouveauCommentaire.contenu || this.nouveauCommentaire.imageId) {
            this.confirmationService.confirmWarn('Le commentaire en cours de saisie ne sera pas sauvegardé<br>Souhaitez-vous continuer ?', () => {
                this.closeDialog();
            });
        } else {
            this.closeDialog();
        }
        this.editmode = false;
    }

    localisationChange(value: any) {
        this.nouveauCommentaire.localisations = value;
    }

    /**
     * Création du/des commentaire(s)
     */
    onClickBtnConfirm() {
        if (!this.nouveauCommentaire.type) {
            return this.notificationService.notify('Veuillez sélectionner un type de commentaire.');
        } else if (!this.nouveauCommentaire.contenu && this.nouveauCommentaire.localisations.length === 0) {
            return this.notificationService.notify('Veuillez remplir le champs "Commentaire" ou choisir une Localisation.');
        }
        // Mise à jour de l'intervention
        this.cnSpinnerService.withSpinner(this.postCommentaire()).pipe(takeUntil(this.ngUnsubscribe)).subscribe();
        this.commentairesAjoutes.push(this.nouveauCommentaire);
    }

    /**
     * Sélection du type de commentaire
     */
    onClickTypeCommentaire(type: TypeCommentaire) {
        this.nouveauCommentaire.type = type;
        this.getCommentairesPredefinisFiltered();
    }

    /**
     * Ajout d'un commentaire prédéfini dans les contenu du commentaire
     */
    onClikAddCommentairePredefini(com: string) {
        this.nouveauCommentaire.contenu = this.nouveauCommentaire.contenu ? this.nouveauCommentaire.contenu + ' \n' + com : com;
    }

    onClickCancel = () => {
        // Détection d'une modification
        if (this.nouveauCommentaire.contenu || this.nouveauCommentaire.imageId || this.commentairesAjoutes?.length) {
            this.confirmationService.confirmWarn('Êtes-vous sûr de vouloir quitter sans enregistrer ?', this.cancel);
        } else {
            this.cancel();
        }
        this.editmode = false;
    };
    private cancel = () => {
        if (this.commentairesAjoutes.length > 0) {
            for (const commentaire of this.commentairesAjoutes.filter((com) => !com.imageId)) {
                this.commentaires.splice(this.commentaires.indexOf(commentaire), 1);
                this.intervention.commentaires.splice(this.intervention.commentaires.indexOf(commentaire), 1);
                this.data.commentairesId.splice(this.data.commentairesId.indexOf(commentaire.id), 1);
            }
            const commWithImageId = this.commentairesAjoutes.filter((com) => !!com.imageId);
            if (commWithImageId.length > 0) {
                for (const commentaire of commWithImageId) {
                    this.deleteImageCommentaire(commentaire).subscribe(() => {
                        this.commentaires.splice(this.commentaires.indexOf(commentaire), 1);
                        this.intervention.commentaires.splice(this.intervention.commentaires.indexOf(commentaire), 1);
                        this.data.commentairesId.splice(this.data.commentairesId.indexOf(commentaire.id), 1);
                        if (commWithImageId.indexOf(commentaire) === commWithImageId.length - 1) {
                            this.closeDialog();
                        }
                    });
                }
            } else {
                this.closeDialog();
            }
            return;
        }
        this.closeDialog();
    };
    private deleteImageCommentaire(commentaire: Commentaire) {
        if (commentaire.imageId) {
            // Suppression de l'image
            return this.cnSpinnerService
                .withSpinner(
                    // On récupère l'InterventionFile
                    this.interventionFileService
                        .findByIdInterventionIdDiagnosticIdReference(
                            this.intervention.id,
                            this.diagnostic && this.diagnostic.id ? this.diagnostic.id : undefined,
                            commentaire.id,
                            TypeReferenceFichier.PHOTO_COMMENTAIRE,
                            commentaire.imageId
                        )
                        .pipe(
                            switchMap((interventionFile) => {
                                /**
                                 * Supprime l'image du commentaire et l'objet InterventionFile
                                 */
                                return this.interventionFileService.deleteInterventionFile(interventionFile, true);
                            })
                        )
                )
                .pipe(takeUntil(this.ngUnsubscribe));
        }
        return of(null);
    }

    /**
     * fonction appeléz quand l'utilisateur clique sur l'icone edit
     * pour mettre à jour le commentaire
     */
    onClickStartUpdateCommentaire(commentaire: Commentaire) {
        this.nouveauCommentaireTemp = Object.assign({}, commentaire);
        this.editmode = true;
        /**
         * updating devient "true" pour montrer que une modification est en cours
         * On assigne le nouveau commentaire à l'objet du commentaire
         * qui a été selectionné
         * Ensuite on conserve l'index du commentaire sélectionné
         */
        this.updating = true;
        this.nouveauCommentaire = Object.assign({}, commentaire);

        this.selectedCommentaireIndex = this.commentaires.indexOf(commentaire);
    }

    /**
     * Mettre à jour le commentaire
     * lorsque l'utilisateur clique sur le bouton modifier
     */
    onclickSaveUpdatedCommentaire() {
        this.disabledSave = false;
        /**
         * Si le commentaire contient une image
         * on retire l'image
         */

        let imageUploadPresent = false;

        if (this.fileUploaderOutput) {
            imageUploadPresent = true;

            if (this.commentaires[this.selectedCommentaireIndex].imageId) {
                this.interventionFileService
                    .findByIdInterventionIdDiagnosticIdReference(
                        this.intervention.id,
                        this.diagnostic && this.diagnostic.id ? this.diagnostic.id : undefined,
                        this.commentaires[this.selectedCommentaireIndex].id,
                        TypeReferenceFichier.PHOTO_COMMENTAIRE,
                        this.commentaires[this.selectedCommentaireIndex].imageId
                    )
                    .pipe(
                        switchMap((interventionFile) => {
                            /**
                             * Supprime l'image du commentaire et l'objet InterventionFile
                             */
                            this.nouveauCommentaire.imageId = null;
                            return this.interventionFileService.deleteInterventionFile(interventionFile, true);
                        })
                    );
            }
        }

        let uploadInterventionFile$;

        if (imageUploadPresent) {
            uploadInterventionFile$ = this.interventionFileService.uploadInterventionFile(
                this.fileUploaderOutput.interventionFile,
                this.fileUploaderOutput.dataUrl,
                null
            );
        } else {
            uploadInterventionFile$ = of(null);
        }

        uploadInterventionFile$.subscribe(() => {
            /**
             * Ici on modifie le commentaire dans l'intervention
             * pour ensuite mettre à jour l'intervention
             */
            this.intervention.commentaires[this.intervention.commentaires.indexOf(this.commentaires[this.selectedCommentaireIndex])] =
                this.nouveauCommentaire;

            /**
             * Le commentaire est modifié en local
             * On retire d'abord le commentaire du tableau commentaires qui est local
             * ensuite on rajoute le nouveau commentaire au même index
             */
            this.commentaires.splice(this.selectedCommentaireIndex, 1);
            this.commentaires.splice(this.selectedCommentaireIndex, 0, this.nouveauCommentaire);

            /**
             * On renitialise le nouveau commentaire
             * on met updating sur false
             */
            this.resetUpdating();
            this.fileUploaderOutput = null;
        });
        this.editmode = false;
    }

    /**
     * L'utilisateur souhaite annuler la modification
     * du commentaire
     */
    onClickCancelUpdateCommentaire() {
        this.resetUpdating();
        this.editmode = false;
    }

    private resetUpdating() {
        this.updating = false;
        this.createNewEmptyCommentaire();
    }

    /**
     * Supprime éventuellement le fichier associé à un commentaire
     * Supprime éventuellement l'occurence InterventionFile associé au commentaire
     * Supprime le commentaire
     * @param commentaire commentaire à supprimer
     */
    onClickDeleteCommentaire(commentaire: Commentaire) {
        this.confirmationService.confirmWarn('Êtes-vous sûr de vouloir supprimer ce commentaire ?', () => {
            if (commentaire.imageId) {
                // Suppression de l'image
                this.cnSpinnerService
                    .withSpinner(
                        // On récupère l'InterventionFile
                        this.interventionFileService
                            .findByIdInterventionIdDiagnosticIdReference(
                                this.intervention.id,
                                this.diagnostic && this.diagnostic.id ? this.diagnostic.id : undefined,
                                commentaire.id,
                                TypeReferenceFichier.PHOTO_COMMENTAIRE,
                                commentaire.imageId
                            )
                            .pipe(
                                switchMap((interventionFile) => {
                                    /**
                                     * Supprime l'image du commentaire et l'objet InterventionFile
                                     */
                                    return this.interventionFileService.deleteInterventionFile(interventionFile, true);
                                })
                            )
                    )
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe();
            }

            // Mise à jour des tableaux
            this.commentaires.splice(this.commentaires.indexOf(commentaire), 1);
            this.intervention.commentaires.splice(this.intervention.commentaires.indexOf(commentaire), 1);
            this.data.commentairesId.splice(this.data.commentairesId.indexOf(commentaire.id), 1);

            // Mise à jour de l'intervention
            this.cnSpinnerService
                .withSpinner(this.interventionService.updateIntervention(this.intervention))
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(() => {
                    this.notificationService.success('Le commentaire a bien été supprimé.');
                });
        });
        this.editmode = false;
    }

    /**
     * Action déclenchée lors du click pour supprimer un élément
     * @param el
     */
    onClickDeleteElement(el: PointDeControleElement) {
        this.elements.splice(this.elements.indexOf(el), 1);
        this.removable = this.elements.length > 1;
    }

    /**
     * Evenement déclenché lorsque l'image a été ajoutée
     * @param fileUploaderOutput
     */
    onFileUploaded(fileUploaderOutput: FileUploaderOutput): void {
        // On met à jour le form avec l'id de l'image
        this.nouveauCommentaire.imageId = fileUploaderOutput.interventionFile.fileId;

        // On stocke les informations de l'image pour l'uploader manuellement
        this.fileUploaderOutput = fileUploaderOutput;
    }

    /**
     * Evenement déclenché lorsque l'image a été supprimée
     */
    onFileDeleted(): void {
        // On supprime l'id de l'image dans le commentaire, ainsi que l'objet interventionFile
        this.nouveauCommentaire.imageId = undefined;
        this.fileUploaderOutput = null;
    }

    /**
     * Met à jour l'intervention afin d'intégrer le nouveau commentaire.
     * @private
     */
    private postCommentaire(): Observable<Intervention> {
        // Prepare les données
        this.prepareDataToSave();

        let uploadInterventionFile$;
        // Si il y a une image
        if (this.fileUploaderOutput) {
            // Upload du fichier et ajout d'une occurence InterventionFile
            uploadInterventionFile$ = this.interventionFileService.uploadInterventionFile(
                this.fileUploaderOutput.interventionFile,
                this.fileUploaderOutput.dataUrl,
                null
            );
        } else {
            uploadInterventionFile$ = of(null);
        }

        // Mise à jour de l'intervention
        return uploadInterventionFile$.pipe(
            switchMap(() => {
                // Ajout du commentaire dans la liste à afficher à l'écran.
                this.commentaires = this.commentaires.concat(this.nouveauCommentaire);

                this.commentaires.forEach((comm) => {
                    if (comm.imageId != null) {
                        let interfile = this.getInterventionFile(comm.imageId);
                        interfile.subscribe((value) => {
                            comm.imageDateTimeOriginal = new Date(+value.creationDate).toLocaleDateString();
                            comm.imageGpsLatitudeRef = value.gpsLatitudeRef;
                            comm.imageGpsLatitude = value.gpsLatitude;
                            comm.imageGpsLongitudeRef = value.gpsLongitudeRef;
                            comm.imageGpsLongitude = value.gpsLongitude;
                            comm.imageGpsLatitudeRef = value.gpsAltitudeRef;
                            comm.imageGpsAltitude = value.gpsAltitude;
                        });
                    }
                });

                // Mise à jour de l'intervention.
                return this.interventionService.updateIntervention(this.intervention).pipe(
                    tap(() => {
                        // Création d'un nouveau commentaire vide pour la prochaine saisie.
                        this.createNewEmptyCommentaire();

                        if (this.data.typeNouveauCommentaire) {
                            this.typeCommentairePredefini = true;
                            this.nouveauCommentaire.type = this.data.typeNouveauCommentaire;
                        }

                        this.fileUploaderOutput = null;
                    })
                );
            })
        );
    }

    /**
     * Crée un nouvel objet de commentaire vide
     * @private
     */
    private createNewEmptyCommentaire() {
        const type = this.nouveauCommentaire ? this.nouveauCommentaire.type : null;
        this.nouveauCommentaire = new Commentaire();
        this.nouveauCommentaire.id = MongoUtils.generateObjectId();
        this.nouveauCommentaire.type = type;
        this.currentInterventionFile = null;
    }

    /**
     * Prepare les données à sauvegarder
     * @private
     */
    private prepareDataToSave() {
        this.nouveauCommentaire.date = DateUtils.localDateTimeString();
        if (this.elements.length > 0) {
            if (this.elements.length === 1) {
                this.elements[0].commentairesId = [...new Set(this.elements[0].commentairesId.concat(this.nouveauCommentaire.id))];
                this.intervention.commentaires = this.intervention.commentaires.concat(this.nouveauCommentaire);
            } else {
                this.elements.forEach((el) => {
                    const nouveauCommentaireSpecifique = { ...this.nouveauCommentaire };
                    nouveauCommentaireSpecifique.id = MongoUtils.generateObjectId();
                    el.commentairesId = [...new Set(el.commentairesId.concat(nouveauCommentaireSpecifique.id))];
                    this.intervention.commentaires = this.intervention.commentaires.concat(nouveauCommentaireSpecifique);
                });
            }
        }
        if (this.data.commentairesId) {
            this.data.commentairesId = [...new Set(this.data.commentairesId.concat(this.nouveauCommentaire.id))];
        }

        if (this.elements.length === 0) {
            if (this.isDescription) {
                this.nouveauCommentaire.fromDescription = true;
            }

            this.intervention.commentaires = this.intervention.commentaires.concat(this.nouveauCommentaire);
        }
    }

    /**
     * Ferme la modale
     * @private
     */
    private closeDialog() {
        this.dialogRef.close({ commentairesId: this.commentaires.map((c) => c.id) });
    }

    /**
     * Initialise l'objet contenant les informations textuelles à afficher dans le composant d'upload
     * @private
     */
    private initFileUploaderTextConfig() {
        this.fileUploaderTextConfig = {
            mainText: `Ajouter l'image du commentaire`,
            deleteToolTip: `Supprimer l'image du commentaire`,
            changeToolTip: `Changer la photo du commentaire`,
        };
    }

    /**
     * récupère l'intervention file lié a un commentaire
     * @idFile : l'id du fichier
     */
    public getInterventionFile(idFile: string): Observable<InterventionFile> {
        const interFiles: Observable<InterventionFile> = this.interventionFileService.findByIdInterventionFileId(
            this.intervention.id,
            TypeReferenceFichier.PHOTO_COMMENTAIRE,
            idFile
        );
        return interFiles;
    }

    /**
     * Filtre la liste de commentaires prédéfinis selon le type de prestation et les codes ref.
     * Supprime les doublons.
     * @private
     */
    private getCommentairesPredefinisFiltered() {
        this.reponsesPredefinisFiltered = [];
        if (this.nouveauCommentaire.type) {
            if (this.elements.length) {
                this.elements = this.data.elements;
                this.removable = this.elements.length > 1;
            }
            combineLatestOrEmpty([this.referenceService.findAllCommentairesPredefinis(), this.diagnosticService.getCurrentDiagnostic(false)])
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(([res, diag]) => {
                    // Filtre selon la prestation et le type de prestation
                    let commentairesPredefinisFilteredByTypePrestation: CommentairePredefini[] = [];
                    if (diag != undefined) {
                        commentairesPredefinisFilteredByTypePrestation = res.filter(
                            (cp) => cp.typePrestation === diag.typePrestation && cp.typeCommentaire === this.nouveauCommentaire.type
                        );
                    } else if (!this.elements || this.elements.length === 0) {
                        commentairesPredefinisFilteredByTypePrestation = this.intervention.prestationsDiagnostics.flatMap((presta) =>
                            res.filter(
                                (cp) => cp.typePrestation === presta.prestation.typePrestation && cp.typeCommentaire === this.nouveauCommentaire.type
                            )
                        );
                    } else {
                        commentairesPredefinisFilteredByTypePrestation = res.filter(
                            (cp) =>
                                this.nouveauCommentaire.type === cp.typeCommentaire &&
                                this.elements.some(
                                    (elem) => elem.prestation?.typePrestation === cp.typePrestation || cp.typePrestation === TypePrestationEnum.COMMUN
                                )
                        );
                    }

                    // Filtre selon la reference
                    let commentairesPredefinis: CommentairePredefini[] = [];
                    if (
                        this.nouveauCommentaire.type === enumTypesCommentaire.JUSTIFICATION_NON_VISITE ||
                        this.nouveauCommentaire.type === enumTypesCommentaire.JUSTIFICATION_NON_CONFORMITE ||
                        this.nouveauCommentaire.type === enumTypesCommentaire.MOTIF_NON_VERIFIABLE ||
                        this.nouveauCommentaire.type === enumTypesCommentaire.MOTIF_NON_RENSEIGNE ||
                        this.nouveauCommentaire.type === enumTypesCommentaire.MOTIF_NON_DEMONTABLE ||
                        this.nouveauCommentaire.type === enumTypesCommentaire.CONSTATATION_DIVERSE ||
                        this.nouveauCommentaire.type === enumTypesCommentaire.JUSTIFICATION_ANNULATION_PRESTATION
                    ) {
                        commentairesPredefinis = commentairesPredefinis.concat(commentairesPredefinisFilteredByTypePrestation);
                    } else if (this.elements.length) {
                        this.elements.forEach((e) => {
                            commentairesPredefinisFilteredByTypePrestation.forEach((c) => {
                                c.references.includes(e.codeRef) && commentairesPredefinis.push(c);
                            });
                        });
                    }
                    // Suppression des doublons
                    commentairesPredefinis = commentairesPredefinis.filter((value, index, self) => {
                        return self.indexOf(value) === index;
                    });

                    const intitules = commentairesPredefinis.filter((c) => c.intitule);
                    if (intitules.length) {
                        this.intitule = intitules[0].text;
                        this.reponsesPredefinisFiltered = intitules[0].reponses.map((r) => r.text);
                    } else {
                        this.intitule = null;
                        this.reponsesPredefinisFiltered = this.reponsesPredefinisFiltered.concat(commentairesPredefinis.map((c) => c.text));
                    }
                });
        }
    }

    get heigthCommPre() {
        const px = 93 + +document.getElementById('idImageUpload').offsetHeight;
        return 'calc(100% - ' + px + 'px)';
    }
}
