export class SessionStorageUtils {
    static getItem(key: string): any {
        if (sessionStorage.getItem(key)) {
            return JSON.parse(sessionStorage.getItem(key));
        }
        return {};
    }

    static setItem(key: string, jsonObject: any) {
        const existingValue = this.getItem(key);
        if (existingValue) {
            sessionStorage.setItem(key, JSON.stringify(Object.assign(existingValue, jsonObject)));
        } else {
            sessionStorage.setItem(key, JSON.stringify(jsonObject));
        }
    }

    static removeItem(key: string) {
        sessionStorage.removeItem(key);
    }
}
