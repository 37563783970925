<form (ngSubmit)="onClickValider()" [formGroup]="formPerimetre" *ngIf="formPerimetre">
    <h1 mat-dialog-title class="primary d-flex justify-content-between">
        <span *ngIf="isCreation">Créer un périmètre d'investigation</span>
        <span *ngIf="!isCreation">Modifier un périmètre d'investigation</span>
        <button mat-button type="button" (click)="onClickCancel()">
            <lib-icon>close</lib-icon>
        </button>
    </h1>

    <div mat-dialog-content>
        <div class="row">
            <div class="col-6">
                <!-- Nom du périmètre -->
                <div class="d-flex p-2 align-items-center">
                    <div class="col-xl-4 mat-subheading-2 m-0 primary">
                        Nom du périmètre<span class="required"></span>
                    </div>
                    <div class="col-xl-8 pr-2">
                        <mat-form-field class="w-100">
                            <input matInput type="text" [readonly]="readonlyMode" formControlName="nomPerimetre" />
                            <mat-error *ngIf="formPerimetre.get('nomPerimetre').hasError('erreurNomPerimetreExistant')">
                                Un autre périmètre possède déjà le même nom.
                            </mat-error>
                            <app-mat-error [control]="formPerimetre.get('nomPerimetre')"></app-mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <!-- Selection du bien-->
                <div class="d-flex p-2 align-items-center">
                    <div class="col-xl-4 mat-subheading-2 m-0 primary">
                        Sélectionner un bien<span class="required"></span>
                    </div>
                    <div class="col-xl-8 pr-2">
                        <app-custom-select
                            class="w-100"
                            [readonly]="readonlyMode"
                            formControlName="bien"
                            [selectControl]="formPerimetre.get('bien')"
                            [matFormFieldClass]="'w-100'"
                            [choices]="listeBiens"
                            [addline]="false"
                            [pathValueDisplay]="['nom']"
                        >
                        </app-custom-select>
                    </div>
                </div>
            </div>
        </div>

        <div mat-dialog-actions class="justify-content-end">
            <button mat-button type="button" color="primary" (click)="onClickCancel()">
                <lib-icon>close</lib-icon>
                <span>Annuler</span>
            </button>
            <button mat-raised-button color="accent" type="submit" [disabled]="formPerimetre.invalid">
                <lib-icon>check</lib-icon>
                <span>Valider</span>
            </button>
        </div>
    </div>
</form>
