<div *ngFor="let zone of reportData.zones; let i = index" class="markdown-content pb-5">
    <div class="block-title p-2 pl-5 my-3 d-inline-block">{{ zone.typeZone }}</div>
    <div class="block-subtitle ml-4 mb-2">Définition des zones homogènes</div>

    <div class="block-content px-5 ml-2 mb-4">
        <div class="block-subtitle-2 mt-4 mb-2">{{ zone.typeZone + ': ' + zone.nom }}</div>

        <!-- Tableau Objectifs de mesurage -->
        <table>
            <tr>
                <th>Objectif(s) de mesurage</th>
                <th>Contexte réglementaire</th>
                <th>Objectif de comparaison analytique</th>
            </tr>
            <tr *ngFor="let obj of zone.objectifMesurage">
                <td class="text-center p-4">{{ obj.referenceLongue }}</td>
                <td class="text-center p-4">{{ obj.texteReglementaire }}</td>
                <td class="text-center p-4">
                    {{ obj.sensibiliteAnalytiqueDefinie ? obj.sensibiliteAnalytiqueAttendue : obj.limiteSuperieurIntervalConfiance }}
                </td>
            </tr>
        </table>

        <!-- MPCA -->
        <table class="mt-5">
            <tr>
                <th>MPCA Concerné</th>
                <th>Type d'amiante</th>
                <th>Etat de dégradation</th>
                <th>Protection & étanchéité</th>
                <th>Chocs & vibrations</th>
                <th>Exposition au circulation d'air</th>
            </tr>
            <tr *ngFor="let mpca of zone.listeMpca">
                <td>{{ mpca.nom }}</td>
                <td>{{ mpca.typeAmiante }}</td>
                <td>{{ mpca.general.etatDegradation }}</td>
                <td>{{ mpca.protectionExposition.etancheite }}</td>
                <td>{{ mpca.protectionExposition.chocVibration }}</td>
                <td>{{ mpca.protectionExposition.circulationAir }}</td>
            </tr>
        </table>

        <!-- Description des espaces: zone de travail et locaux adjacents -->
        <table class="mt-5" *ngIf="zone.isZoneDeTravail && zone.hasSurfacesInterface">
            <tr>
                <th colspan="5" class="text-center">Description du ou des espaces concernés</th>
            </tr>
            <tr>
                <th colspan="5" class="text-center subheader">
                    Locaux adjacents à la zone de travail pouvant être directement affecté par les émissions de fibres d'amiante à partir de la zone
                </th>
            </tr>
            <tr>
                <th>Désignation</th>
                <th>Usage général</th>
                <th>Superficie (m²)</th>
                <th>Caractéristiques de l'espace</th>
                <th>Nombre de PU</th>
            </tr>
            <ng-container *ngFor="let surface of zone.listeSurfaces">
                <tr *ngFor="let volume of surface.listeVolumes">
                    <td>{{ volume.nom }}</td>
                    <td>{{ zone.environnement + ' - ' + zone.usageLocaux + ' - ' + zone.frequentation }}</td>
                    <td>{{ volume.plan.area }}</td>
                    <td>{{ surface.longueurInterface + ' × ' + surface.hauteurInterface }}</td>
                    <td>{{ volume.puNb }}</td>
                </tr>
            </ng-container>
        </table>

        <!-- Description des espaces: zone de travail et locaux adjacent -->
        <table class="mt-5" *ngIf="zone.isZoneDeTravail && !zone.hasSurfacesInterface">
            <tr>
                <th colspan="5" class="text-center">Description du ou des espaces concernés</th>
            </tr>
            <tr>
                <th colspan="5" class="text-center subheader">
                    Locaux pouvant être affectés par les émissions de fibres d'amiante lors du transport stockage de matériel ou de déchets
                </th>
            </tr>
            <tr>
                <th>Désignation</th>
                <th>Usage général</th>
                <th>Superficie (m²)</th>
                <th>Caractéristiques de l'espace</th>
                <th>Nombre de PU</th>
            </tr>
            <ng-container *ngFor="let surface of zone.listeSurfaces">
                <tr *ngFor="let volume of surface.listeVolumes">
                    <td>{{ volume.nom }}</td>
                    <td>{{ zone.environnement + ' - ' + zone.usageLocaux + ' - ' + zone.frequentation }}</td>
                    <td>{{ volume.plan.area }}</td>
                    <td>{{ surface.inferieurSeuilMin ? '\< 15' : surface.longueurMax + 'm' }}</td>
                    <td>{{ volume.puNb }}</td>
                </tr>
            </ng-container>
        </table>

        <!-- Description des espaces: hors travaux et zone standard -->
        <table class="mt-5" *ngIf="!zone.isZoneDeTravail">
            <tr>
                <th colspan="5" class="text-center">Description du ou des espaces concernés</th>
            </tr>
            <tr>
                <th>Désignation</th>
                <th>Usage général</th>
                <th>Superficie (m²)</th>
                <th>Caractéristiques de l'espace</th>
                <th>Nombre de PU</th>
            </tr>
            <ng-container *ngFor="let surface of zone.listeSurfaces">
                <tr *ngFor="let volume of surface.listeVolumes">
                    <td>{{ volume.nom }}</td>
                    <td>{{ zone.environnement + ' - ' + zone.usageLocaux + ' - ' + zone.frequentation }}</td>
                    <td>{{ volume.plan.area }}</td>
                    <td>{{ surface.inferieurSeuilMin ? '\< 15' : surface.longueurMax + 'm' }}</td>
                    <td>{{ volume.puNb }}</td>
                </tr>
            </ng-container>
        </table>

        <!-- Caractéristiques de la zone de Travail -->
        <table class="mt-5" *ngIf="zone.isZoneDeTravail">
            <tr>
                <th colspan="5" class="text-center">Caractéristiques de la zone de travail</th>
            </tr>
            <tr>
                <th>Activité adjacente</th>
                <th>Confinement</th>
                <th>Nombre d'extracteurs</th>
                <th>SAS personnel</th>
                <th>SAS matériel</th>
            </tr>
            <tr>
                <td>{{ zone.activiteAdjacente }}</td>
                <td>{{ zone.confinement }}</td>
                <td>{{ zone.nombreExtracteurs }}</td>
                <td>{{ zone.sasPersonnel }}</td>
                <td>{{ zone.sasMateriel }}</td>
            </tr>
        </table>

        <!-- Prélèvements à poste fixe -->
        <table class="mt-5" *ngIf="zone.hasMeta">
            <tr>
                <th colspan="7" class="text-center">Prélèvements à poste fixe</th>
            </tr>
            <tr>
                <th colspan="7">Méthode : NF X43-050</th>
            </tr>
            <tr>
                <th colspan="7">Nombre total de prélèvements pour la ZSE : {{ 5 }}</th>
            </tr>
            <tr>
                <th>N° de besoin</th>
                <th>Conditions de mise en oeuvre</th>
                <th>Période</th>
                <th>Fréquence</th>
                <th>Durée</th>
                <th>Localisation</th>
                <th>Justificatif localisation</th>
            </tr>
            <ng-container *ngFor="let besoin of zone.besoins">
                <tr *ngIf="besoin.objectifMesurage?.norme == 'NF X43-050'">
                    <td>{{ besoin.numero }}</td>
                    <td>{{ besoin.objectifMesurage.descriptionConditionPrelevement }}</td>
                    <td>{{ '' }}</td>
                    <td>{{ besoin.objectifMesurage.frequence }}</td>
                    <td>{{ besoin.objectifMesurage.dureeMinHum + ' / ' + besoin.objectifMesurage.dureeMaxHum }}</td>
                    <td>{{ besoin.emplacement }}</td>
                    <td>{{ besoin.justificatifLocalisation }}</td>
                </tr>
            </ng-container>
        </table>

        <!-- Prélèvements individuels -->
        <table class="mt-5" *ngIf="zone.hasMetop">
            <tr>
                <th colspan="7" class="text-center">Prélèvements individuels</th>
            </tr>
            <tr>
                <th colspan="7">Méthode : NF X43-269</th>
            </tr>
            <tr>
                <th colspan="7">Nombre total de prélèvementspour la ZSE : {{ 5 }}</th>
            </tr>
            <tr>
                <th>N° de besoin</th>
                <th>Processus ou PO concerné</th>
                <th colspan="2">MPCA concerné</th>
                <th>Fréquence</th>
                <th>Durée</th>
                <th>SA visée</th>
            </tr>
            <ng-container *ngFor="let besoin of zone.besoins">
                <tr *ngIf="besoin.objectifMesurage?.norme == 'NF X43-269'">
                    <td>{{ besoin.numero }}</td>
                    <td>{{ besoin.processus?.nom }}</td>
                    <td colspan="2">
                        {{ besoin.processus?.mpca?.general?.partieOuvrageComposantMateriaux }}
                    </td>
                    <td>{{ besoin.objectifMesurage.frequence }}</td>
                    <td>{{ besoin.objectifMesurage.dureeMinHum + ' / ' + besoin.objectifMesurage.dureeMaxHum }}</td>
                    <td>{{ besoin.processus?.echantillonnage?.dureeRepresentativeProcessus }}</td>
                </tr>
            </ng-container>
            <tr>
                <th colspan="7" class="text-center">Descriptif prélèvements individuels</th>
            </tr>
            <tr>
                <th>N° de besoin</th>
                <th>Processus ou PO concerné</th>
                <th>Méthode / technique / Outil</th>
                <th>Aspiration</th>
                <th>Travail à l'humide</th>
                <th>Tâches</th>
                <th>Informations complémentaires (concentration attendue & Tr)</th>
            </tr>
            <ng-container *ngFor="let besoin of zone.besoins">
                <tr *ngIf="besoin.objectifMesurage.norme == 'NF X43-269'">
                    <td>{{ besoin.numero }}</td>
                    <td>{{ besoin.processus?.nom }}</td>
                    <td>
                        {{ [besoin.processus?.metop?.methode, besoin.processus?.metop?.technique, besoin.processus?.metop?.outil].join(' / ') }}
                    </td>
                    <td>{{ besoin.processus?.metop?.aspiration }}</td>
                    <td>{{ besoin.processus?.metop?.travailHumide }}</td>
                    <td>
                        <ul *ngIf="besoin.processus?.taches?.length">
                            <li *ngFor="let tache of besoin.processus.taches">
                                {{ tache.tache }}
                            </li>
                        </ul>
                    </td>
                    <td>
                        <p>Concentration attendue: {{ besoin.processus?.echantillonnage?.concentrationAttendue }}</p>
                        <p>Tr: {{ besoin.processus?.echantillonnage?.dureeRepresentativeProcessus }}</p>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>
</div>
