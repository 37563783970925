<div class="dialog">
    <h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Orientation du prélèvement</h2>
    <mat-dialog-content>
        <div class="compass">
            <div (click)="selectOrientation('NO')" [class.selected]="orientation == 'NO'">NO</div>
            <div (click)="selectOrientation('N')" [class.selected]="orientation == 'N'">N</div>
            <div (click)="selectOrientation('NE')" [class.selected]="orientation == 'NE'">NE</div>
            <div (click)="selectOrientation('E')" [class.selected]="orientation == 'E'">E</div>
            <div (click)="selectOrientation('C')" [class.selected]="orientation == 'C'">C</div>
            <div (click)="selectOrientation('O')" [class.selected]="orientation == 'O'">O</div>
            <div (click)="selectOrientation('SO')" [class.selected]="orientation == 'SO'">SO</div>
            <div (click)="selectOrientation('S')" [class.selected]="orientation == 'S'">S</div>
            <div (click)="selectOrientation('SE')" [class.selected]="orientation == 'SE'">SE</div>
        </div>

        <mat-form-field appearance="fill" class="w-100">
            <mat-label>Précision (facultatif)</mat-label>
            <input matInput [(ngModel)]="precisionOrientation" type="text" (keydown)="onChangePrecision($event)" />
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-flat-button color="primary" (click)="onConfirmClick()">Valider</button>
        <button mat-flat-button (click)="onCancelClick()">Annuler</button>
    </mat-dialog-actions>
</div>
