import { Pipe, PipeTransform } from '@angular/core';

/**
 * Indique si une chaîne de caractères commence par une autre chaîne de caractères.
 */
@Pipe({
    name: 'startsWith',
})
export class StartsWithPipe implements PipeTransform {
    transform(str: string, prefix: string): boolean {
        return str && str.startsWith(prefix);
    }
}
