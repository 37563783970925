import { Pipe, PipeTransform } from '@angular/core';

/**
 * Affiche le nom d'un type de bien
 */
@Pipe({
    name: 'fileExtension',
})
export class FileExtensionPipe implements PipeTransform {
    constructor() {}

    transform(filename: string): string {
        return filename.substr(filename.lastIndexOf('.') + 1);
    }
}
