<mat-accordion>
    <ng-container *ngFor="let type of allTypesOuvrageNodes">
        <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header class="p-0">
                <mat-panel-title>
                    <div class="d-flex w-100 ml-2 mr-2">
                        <div class="bold w-100">{{ type.data.name }}</div>
                        <div class="buttons justify-content-end d-flex">
                            <button mat-raised-button color="accent" (click)="editTypeOuvrage(type.data)">edit</button>
                            <button class="ml-2" mat-raised-button color="warn" (click)="deleteTypeOuvrage(type.data)">
                                supprimer
                            </button>
                        </div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="no-gutters">
                <div *ngFor="let typeOuvrage of type.children">
                    <div class="partie-ouvrage d-flex">
                        <div class="bold w-100">{{ typeOuvrage.name }}</div>
                        <div class="buttons justify-content-end d-flex">
                            <button mat-raised-button color="accent" (click)="editTypeOuvrage(typeOuvrage.data)">
                                edit
                            </button>
                            <button
                                class="ml-2"
                                mat-raised-button
                                color="warn"
                                (click)="deleteTypeOuvrage(typeOuvrage.data)"
                            >
                                supprimer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>
