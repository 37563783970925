<div class="mpca-form-echantillonnage">
    <app-mpca-form-skeleton>
        <div title>Échantillonnage</div>
        <div form>
            <div [formGroup]="formEchantillonnage" class="form-general" *ngIf="formEchantillonnage">
                <div class="input">
                    <mat-label>Concentration attendue (en f/L)</mat-label>
                    <app-increment-input-reactive
                        class="big-increment"
                        formControlName="concentrationAttendue"
                        data-cy="concentrationAttendue"
                        [incrementControl]="formEchantillonnage.get('concentrationAttendue')"
                        [min]="0"
                        [step]="1"
                        [numericKeyboard]="true"
                    >
                    </app-increment-input-reactive>
                </div>
                <div class="input">
                    <mat-label>Durée représentative du processus (Tr)</mat-label>
                    <app-increment-input-reactive
                        class="big-increment"
                        formControlName="dureeRepresentativeProcessus"
                        data-cy="dureeRepresentativeProcessus"
                        [incrementControl]="formEchantillonnage.get('dureeRepresentativeProcessus')"
                        [min]="0"
                        [step]="1"
                        [numericKeyboard]="true"
                    >
                    </app-increment-input-reactive>
                </div>
                <div class="input">
                    <mat-label>Durée de saturation en poussières (TsatP)</mat-label>
                    <app-increment-input-reactive
                        class="big-increment"
                        formControlName="dureeSaturationPoussieres"
                        data-cy="dureeSaturationPoussieres"
                        [incrementControl]="formEchantillonnage.get('dureeSaturationPoussieres')"
                        [min]="0"
                        [step]="1"
                        [numericKeyboard]="true"
                    >
                    </app-increment-input-reactive>
                </div>
                <div class="input">
                    <mat-label>Débit de prélèvement (en L/min)</mat-label>
                    <app-increment-input-reactive
                        class="big-increment"
                        formControlName="debitPrelevement"
                        data-cy="debitPrelevement"
                        [incrementControl]="formEchantillonnage.get('debitPrelevement')"
                        [min]="0"
                        [step]="1"
                        [numericKeyboard]="true"
                    >
                    </app-increment-input-reactive>
                </div>
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Fraction du filtre à traiter</mat-label>
                    <mat-select formControlName="fractionFiltre" required data-cy="fractionFiltre">
                        <mat-option></mat-option>
                        <mat-option value="1">1</mat-option>
                        <mat-option value="0.5">0.5</mat-option>
                        <mat-option value="0.25">0.25</mat-option>
                        <mat-option value="0.125">0.125</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </app-mpca-form-skeleton>
</div>
<div class="computed-values ml-3">
    <div [formGroup]="computedForm" class="mat-card d-flex f-direction-column align-items-center">
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Tmin(SA=1), en minutes</mat-label>
                <input matInput formControlName="tMin" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Tmin (comptage 100 fibres), en minutes</mat-label>
                <input matInput formControlName="tMin100" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Tmin retenu, en minutes</mat-label>
                <input matInput formControlName="tMinRetenu" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Objectif de mesures</mat-label>
                <input matInput formControlName="objMesures" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>TsatA, en minutes</mat-label>
                <input matInput formControlName="tSatA" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Tsat retenu, en minutes</mat-label>
                <input matInput formControlName="tSatARetenu" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Type de cas (Annexe L, NF X 43-269)</mat-label>
                <input matInput formControlName="typeCas" />
            </mat-form-field>
        </div>
        <mat-form-field appearance="outline" class="row inline-input">
            <mat-label>Schéma indicatif de prélèvement</mat-label>
            <input matInput formControlName="schemaPrelevement" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="row inline-input">
            <mat-label>Débit / Volume prélevé</mat-label>
            <input matInput formControlName="debitVolumePreleve" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="row inline-input">
            <mat-label>Schéma indicatif d'analyses</mat-label>
            <input matInput formControlName="schemaAnalyse" />
        </mat-form-field>
    </div>
</div>
