'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2021 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** cn_marker : a labelled element to mark information in a 2D / 3D scene
//***********************************************************************************
//***********************************************************************************

import { fh_add, fh_clone, fh_normalize, fh_polygon, fh_scene } from '@acenv/fh-3d-viewer';
import { cn_3d_building, cn_comment_picture, cn_element_visitor, cn_marker_input, cn_roof_opening, cn_roof_slab, cn_storey_element, logger } from '..';
import { cn_camera } from '../svg/cn_camera';
import { cn_add, cn_box, cn_clone, cn_color_hexa_to_rgb, cn_dot, cn_middle, cn_mul, cn_normal, cn_normalize, cn_point_on_segment, cn_sub, cnx_clone } from '../utils/cn_utilities';
import { cn_vertex } from './cn_vertex';
import { cn_beam } from './cn_beam';
import { cn_column } from './cn_column';
import { cn_element } from './cn_element';
import { cn_scene } from './cn_scene';
import { cn_contour } from './cn_contour';
import { cn_object_instance } from './cn_object_instance';
import { cn_opening } from './cn_opening';
import { cn_slab } from './cn_slab';
import { cn_stairs } from './cn_stairs';
import { cn_storey } from './cn_storey';
import { cn_wall } from './cn_wall';
import { cn_space } from './cn_space';
import { cn_roof } from './cn_roof';
import { cn_mouse_event } from '../svg/cn_mouse_event';
import { cn_transaction_manager } from '../utils/cn_transaction_manager';
import { to_image, to_world } from '../utils/cn_image_utils';

const configurations = new Map([
    ['', { head: true, line: true, tail: true }],
    ['chip', { head: true, line: false, tail: false }],
    ['label', { head: false, line: false, tail: true }],
    ['eraser', { head: false, line: false, tail: false }],
    ['liaison', { head: false, line: true, tail: false }]
]);

const LABEL_SIZE = 200;

export class cn_marker extends cn_element {
    //***********************************************************************************
    /**
     * Constructor
     *
     * @param {cn_storey} storey
     */
    constructor(storey, type = '') {
        super(storey);

        /** Back pointer to storey */
        this.storey = storey;

        //*** Marker color */
        this.color = '';

        //*** text color */
        this.label_color = '#ff000000';

        //*** text */
        this.label = '';

        //** group */
        this.group = ''

        //*** content */
        this.content = '';

        //*** anomaly ? */
        this.anomaly = true;

        //*** other parameters ? */
        this.parameters = {};

        //*** pictures linked to this marker */
        /**
         * @type {cn_comment_picture[]}
         */
        this.pictures = [];

        //*** Element on which the marker is set */
        this.element = null;

        //*** Position relative to the element */
        this.element_position = [0, 0, 0];

        //*** Side of the element (if applies) */
        this.element_side = 0;

        //*** placed on a vertical element ? */
        this.vertical_placement = false;

        //*** The next parameters are built from previous ones */
        //*** Space containing the marker */
        this.space = null;

        //*** global position of the marker */
        this.position = [0, 0, 0];

        //*** tail position of the marker */
        this.tail_position = [0, 0, 0];

        this.set_type(type);

        //*** tail position for 3D view, in screen coordinates */
        this.tail_position_3d = [100, -100];

        //*** Shape : an instance of cn_contour, or null */
        this.shape = null;

        //*** Shape placement. If not null, an instance of fh_matrix, used to transform shape to world coordinates. */
        this.shape_placement = null;

        //*** Shape color */
        this.shape_color = '#ff6c00';
        this.shape_opacity = 0.5;

        //*** global normal of the marker */
        this.normal = [0, 0, 0];
        this.roof = false;
        //*** TMP data */
        this.vertices = [];
        this.text_box = new cn_box();
    }

    /**
     *
     * @param {cn_marker_input} input_marker
     */
    hydrate_input_specific_params(input_marker) {
    }

    /**
     *
     * @param {cn_marker_input} input_marker
     */
    reconcialite_input_specific_params(input_marker) {
    }

    //***********************************************************************************
    /**
     * Serialize method
     * @returns {object}
     */
    serialize() {
        var json = {};
        json.ID = this.ID;
        json.color = this.color;
        json.label_color = this.label_color;
        json.label = this.label;
        json.content = this.content;
        json.pictures = this.pictures;
        json.roof = this.roof;
        json.vertical_placement = this.vertical_placement;
        if (this.element && typeof (this.element.s_index) == 'number')
            json.element = this.element.s_index;
        if (this.element) {
            if (this.element.constructor == cn_beam)
                json.element_type = 'cn_beam';
            if (this.element.constructor == cn_opening)
                json.element_type = 'cn_opening';
            if (this.element.constructor == cn_object_instance)
                json.element_type = 'cn_object_instance';
            if (this.element.constructor == cn_column)
                json.element_type = 'cn_column';
            if (this.element.constructor == cn_stairs)
                json.element_type = 'cn_stairs';
            if (this.element.constructor == cn_wall)
                json.element_type = 'cn_wall';
            if (this.element.constructor == cn_slab)
                json.element_type = 'cn_slab';
            if (this.element.constructor == cn_roof_slab)
                json.element_type = 'cn_roof_slab';
            if (this.element.constructor == cn_roof_opening)
                json.element_type = 'cn_roof_opening';
            if (this.element.constructor == cn_space)
                json.element_type = 'cn_space';
        }

        json.element_position = fh_clone(this.element_position);
        json.element_side = this.element_side;
        if (this.space && typeof (this.space.s_index) == 'number')
            json.space = this.space.s_index;
        json.position = fh_clone(this.position);
        json.tail_position = fh_clone(this.tail_position);
        json.normal = fh_clone(this.normal);
        json.group = this.group;
        json.shape_opacity = this.shape_opacity;
        json.shape_color = this.shape_color;
        if (this.shape) {
            json.shape = this.shape.serialize();
        }

        json.type = this.type || '';

        return json;
    }

    //***********************************************************************************
    /**
     * Unserialize method
     * @param {object} json
     * @param {cn_storey} storey
     * @returns {cn_marker}
     */
    static unserialize(json, storey) {
        const marker = new cn_marker(storey);
        if (typeof (json.ID) == 'string') marker.ID = json.ID;
        if (typeof (json.color) == 'string') marker.color = json.color;
        if (typeof (json.label_color) == 'string') marker.label_color = json.label_color;
        if (typeof (json.label) == 'string') marker.label = json.label;
        if (typeof (json.content) == 'string') marker.content = json.content;
        if (typeof (json.element_type) == 'string') {
            if (json.element_type == 'cn_slab')
                marker.element = storey.find_slab(json.position, 0);
            else if (json.element_type === 'cn_roof_opening')
                marker.element = storey.roof.find_opening(json.position)
            else if (json.element_type === 'cn_roof_slab')
                marker.element = storey.roof.find_slab(json.position)
            else if (typeof (json.element_id) == 'string')
                marker.element = storey.find_element_by_id(json.element_id);
            if (typeof (json.element) == 'number')
                marker.element = storey.find_element(json.element_type, json.element);
        }
        if (json.tail_position) {
            marker.tail_position = fh_clone(json.tail_position);
        }
        marker.element_position = fh_clone(json.element_position);
        marker.position = fh_clone(json.position);
        if (typeof (json.element_side) == 'number') marker.element_side = json.element_side;
        marker.roof = !!json.roof;

        if (typeof (json.shape_color) == 'string')
            marker.shape_color = json.shape_color;

        if (typeof (json.shape_opacity) == 'number')
            marker.shape_opacity = json.shape_opacity;

        if (typeof (json.shape) == 'object') {
            marker.shape = cn_contour.unserialize(json.shape, storey.scene, marker);
        }

        if (typeof (json.pictures) == 'object') {
            for (let i in json.pictures) {
                const pic = json.pictures[i];
                if (pic) {
                    let comment_picture = pic;
                    if (typeof (pic) == 'string') {
                        // Rétrocompatibilité avec les anciennes maquettes (où il y avait juste les ids des images)
                        comment_picture = new cn_comment_picture();
                        comment_picture.image_id = pic;
                        comment_picture.name = marker.label;
                        comment_picture.orientation = 0;
                        comment_picture.image_size = [0, 0];
                        comment_picture.offset = [0, 0];
                        comment_picture.show = false;
                    }
                    marker.pictures.push(comment_picture);
                }
            }
        }

        marker.vertical_placement = json.vertical_placement || false;
        marker.set_type(json.type || '');

        marker.group = json.group || '';

        marker.update();
        return marker;
    }

    set_type(type) {
        this.type = type;
        this.config = configurations.get(type) || configurations.get('');
    }

    //***********************************************************************************
    /**
     * Returns bounding box
     * @returns {cn_box}
     */
    get_bounding_box(scale = 0) {
        const box = new cn_box();
        if (scale) {
            const orientation_sign = this.tail_position[0] > this.position[0] ? 1 : -1;
            box.enlarge_point([this.tail_position[0] + LABEL_SIZE * scale * orientation_sign, this.tail_position[1]]);
        }
        box.enlarge_point(this.tail_position);
        box.enlarge_point(this.position);
        if (this.shape) {
            this.shape.vertices.forEach(v => box.enlarge_point(v.position));
        }

        if (this.pictures && this.pictures.length && this.pictures.some(picture => picture.show)) {
            let limits = [[0, 0], [0, 1], [1, 0], [1, 1]];
            this.pictures.filter(picture => picture.show).forEach(picture => {
                for (let l in limits) {
                    box.enlarge_point(to_world(limits[l], picture.orientation, picture.offset, picture.image_size, picture.scale));
                }
            });
        }

        return box;
    }

    /**
     * Returns the bounding box of the element
     * @param {cn_camera} camera
     * @returns {cn_box}
     */
    get_screen_bounding_box(camera) {
        let box = new cn_box();
        if (camera.is_3d()) {
            const z = this.get_altitude();
            const p0 = camera.world_to_screen(this.position, z);
            if (p0.length >= 2) {
                box.enlarge_point(p0)
                const p2 = cn_add(p0, this.tail_position_3d);
                box.enlarge_point(p2)
                const orientation_sign = this.tail_position_3d[0] > 0 ? 1 : -1;
                p2[0] += LABEL_SIZE * orientation_sign;
                box.enlarge_point(p2)
            }
            const shape = this.get_shape_3d();
            shape.forEach(v => box.enlarge_point(camera.world_to_screen(v)));
            return box;
        }

        box = super.get_screen_bounding_box(camera);
        const p = camera.world_to_screen(this.tail_position);
        const orientation_sign = this.tail_position[0] > this.position[0] ? 1 : -1;
        p[0] += LABEL_SIZE * orientation_sign;
        box.enlarge_point(p);
        return box;
    }

    //***********************************************************************************
    /**
     * Update a marker
     */
    update() {
        if (this.shape) this.shape.update();
        this.compute_geometry();
    }

    //***********************************************************************************
    /**
     * Compute 3D geometry from 2D, and sets space
     */
    compute_geometry() {
        this.vertical_placement = true;
        if (this.element) {
            if (this.element.constructor == cn_wall) {
                var side0 = (this.element_side == 0);
                var pstart = side0 ? this.element.bounds.pmin : this.element.bounds.pmax;
                this.position = cn_add(pstart, cn_mul(this.element.bounds.direction, this.element_position[0]));
                this.position.push(this.element_position[2]);
                this.normal = (side0) ? cn_mul(this.element.bounds.normal, -1) : cn_clone(this.element.bounds.normal);
                this.normal.push(0);

                this.space = (side0) ? this.element.spaces[0] : this.element.spaces[1];

                this.vertical_placement = false;
            } else if (this.element.constructor == cn_object_instance) {
                this.position = this.element.local_to_global(this.element_position);
                this.normal = [0.707, -0.707, 0];
                this.space = this.storey.scene.find_space(this.position, true);
            } else {
                this.position = fh_clone(this.element_position);
                if (this.config.line || this.config.head) {
                    this.normal = [0.707, -0.707, 0];
                } else {
                    this.normal = [0, 0, 0];
                }
                this.space = this.storey.scene.find_space(this.position, true);
            }
        } else if (this.shape) {
            var pos = [0, 0, 0];
            var nor = [0, 0, 1];
            this.shape.abscissa_to_point_and_normal(this.element_position[0], pos, nor);
            logger.log('computed point', pos);

            this.position = pos;
            this.normal = nor;
            this.space = this.storey.scene.find_space(this.position, true);
        }
    }

    //***********************************************************************************
    /**
     * returns 3D normal
     * @return {number[]}
     */
    get_3d_normal() {
        if (this.vertical_placement) return [0, 0, 1];
        const n = [this.normal[0], this.normal[1], 0];
        fh_normalize(n);
        return n;
    }

    /**
     * Rturns the altitude of the arrow point
     * @returns {number}
     */
    get_altitude() {
        if (this.roof) {
            var z = this.storey.roof_altitude;
            var h = this.storey.roof.compute_height(this.position);
            if (h) z += h;
            return z;
        }
        return this.storey.altitude;
    }

    /** returns the scene corresponding to the marker
     * @returns {cn_scene | cn_roof}
     */
    get_scene() {
        if (this.roof) return this.storey.roof;
        return this.storey.scene;
    }

    /**
     * Returns the list shape as a list of 3D points.
     * @returns {number[][]}
     */
    get_shape_3d() {
        if (!this.shape || this.shape.vertices.length == 0) return [];
        const z = (this.roof) ? this.storey.roof_altitude : this.storey.altitude;
        if (this.shape.vertices.some(v => v.position.length < 3))
            return this.shape.vertices.map(v => [v.position[0], v.position[1], z]);
        else
            return this.shape.vertices.map(v => [v.position[0], v.position[1], z + v.position[2]]);
    }

    is_shape_3d() {
        if (!this.shape) return false;
        return this.shape.vertices.every(v => v.position.length === 3);
    }

    /**
     * Sets 3D vertices
     * @param {number[][]} vertices
     * @param {boolean} force_3d
     */
    set_shape_3d(vertices, force_3d) {

        var shape_vertices = this.shape.vertices;

        while (shape_vertices.length < vertices.length)
            shape_vertices.push(new cn_vertex([0, 0, 0]));

        if (shape_vertices.length > vertices.length)
            shape_vertices.splice(0, shape_vertices.length - vertices.length);

        const offset = (this.roof) ? this.storey.roof_altitude : this.storey.altitude;
        for (var i = 0; i < vertices.length; i++) {
            if (force_3d) {
                var z = -offset;
                if (vertices[i].length > 0) z += vertices[i][2];
                shape_vertices[i] = new cn_vertex([vertices[i][0], vertices[i][1], z], force_3d);
            } else
                shape_vertices[i] = new cn_vertex(vertices[i]);
        }

        this.update();
    }

    /**
     * returns true if shape hasn't changed since date
     * @param {number} date
     * @returns {boolean}
     */
    up_to_date_shape(date) {
        if (!this.up_to_date(date, 'shape')) return false;
        if (this.shape && !this.shape.up_to_date(date, 'vertices')) return false;
        return true;
    }

    //***********************************************************************************
    /**
     * Place marker
     * @param {cn_mouse_event} mouse_event
     */
    place_from_mouse(mouse_event) {
        if (this.shape) {
            this.element = null;
            const mw = cnx_clone(mouse_event.mouse_world);
            if (this.roof) mw[2] -= this.storey.roof_altitude;
            else mw[2] -= this.storey.altitude;
            this.element_position[0] = this.shape.abscissa_from_point(mouse_event.mouse_world);
            this.compute_geometry();
            return;
        }

        const is_3d = (mouse_event.camera.is_3d());

        var position = cn_clone(mouse_event.mouse_world);

        if (is_3d) {
            this.element = null;
            if (mouse_event.impact && mouse_event.impact.storey_element) {
                this.element = mouse_event.impact.storey_element.element;
                this.storey = mouse_event.impact.storey_element.storey;
                position = fh_clone(mouse_event.impact.position);
                this.roof = (this.element.constructor == cn_roof_slab || this.element.constructor == cn_roof_opening);
                if (this.roof) {
                    position[2] -= this.storey.roof_altitude;
                    const h = this.storey.roof.compute_height(position);
                    if (h) position[2] -= h;
                } else
                    position[2] -= this.storey.altitude;
            }
        } else
            this._search_element(mouse_event.scene.constructor == cn_roof, mouse_event.mouse_world, mouse_event.camera.snap_world_distance);

        if (!this.element)
            return;

        //*** build placement */
        if (this.element.constructor == cn_wall) {
            var pp = cn_middle(this.element.bounds.pmin, this.element.bounds.pmax);
            var dir = cn_sub(position, pp);
            this.element_position[0] = cn_dot(this.element.bounds.direction, dir);
            if (this.element_position[0] < 0) this.element_position[0] = 0;
            else if (this.element_position[0] > this.element.bounds.length) this.element_position[0] = this.element.bounds.length;
            this.element_side = (cn_dot(dir, this.element.bounds.normal) > 0) ? 1 : 0;
            this.element_position[2] = (position.length >= 3) ? position[2] : 1;
        } else if (this.element.constructor == cn_object_instance) {
            var p = this.element.global_to_local(position);
            this.element_position[0] = p[0];
            this.element_position[1] = p[1];
            this.element_position[2] = (position.length >= 3) ? position[2] : 1;
            this.element_side = 0;
        } else {
            this.element_position[0] = position[0];
            this.element_position[1] = position[1];
            this.element_position[2] = (position.length >= 3) ? position[2] : 0;
            if (this.element.constructor == cn_space)
                this.element_position[2] = this.element.slab_offset;
            this.element_side = 0;
        }

        this.compute_geometry();
    }

    //***********************************************************************************
    /**
     * Place marker
     * @param {number[]} position
     * @param {number} tolerance
     */
    place(roof, position, tolerance) {

        if (this.shape) {
            this.element = null;
            this.element_position[0] = this.shape.abscissa_from_point(position);
            this.compute_geometry();
            return;
        }

        this._search_element(roof, position, tolerance);

        if (!this.element)
            return;

        //*** build placement */
        if (this.element.constructor == cn_wall) {
            var pp = cn_middle(this.element.bounds.pmin, this.element.bounds.pmax);
            var dir = cn_sub(position, pp);
            this.element_position[0] = cn_dot(this.element.bounds.direction, dir);
            if (this.element_position[0] < 0) this.element_position[0] = 0;
            else if (this.element_position[0] > this.element.bounds.length) this.element_position[0] = this.element.bounds.length;
            this.element_side = (cn_dot(dir, this.element.bounds.normal) > 0) ? 1 : 0;
            this.element_position[2] = 1;
        } else if (this.element.constructor == cn_object_instance) {
            var p = this.element.global_to_local(position);
            this.element_position[0] = p[0];
            this.element_position[1] = p[1];
            this.element_position[2] = 1;
            this.element_side = 0;
        } else {
            this.element_position[1] = position[1];
            this.element_position[0] = position[0];
            if (this.element.constructor == cn_space)
                this.element_position[2] = this.element.slab_offset;
            this.element_side = 0;
        }

        this.compute_geometry();
    }

    refresh_slab_element(storey) {
        this.element = storey.find_slab(this.element_position);
    }

    _search_element(roof, position, tolerance) {
        if (!roof) {
            const scene = this.storey.scene;
            //*** Maybe mouse over an object instance ?
            this.element = scene.find_object_instance(position, tolerance);

            //*** Maybe mouse over a beam ?
            if (!this.element)
                this.element = scene.find_beam(position, tolerance);

            //*** Maybe mouse over an openings ?
            if (!this.element)
                this.element = scene.find_opening(position, tolerance);


            //*** Maybe mouse over a column ?
            if (!this.element)
                this.element = scene.find_column(position, tolerance);

            //*** Maybe mouse over a stairs ?
            if (!this.element)
                this.element = scene.find_stairs(position);

            //*** Maybe mouse over a wall ?
            if (!this.element)
                this.element = scene.find_wall(position, tolerance);

            //*** Maybe mouse over a slab ?
            if (!this.element)
                this.element = this.storey.find_slab(position, tolerance);

            //*** Maybe mouse over a space ?
            if (!this.element)
                this.element = scene.find_space(position, tolerance);

        } else {
            const roof = this.storey.roof;
            this.element = roof.find_opening(position);

            if (!this.element)
                this.element = roof.find_slab(position);
        }
    }

    set_tail_position(position) {
        this.tail_position = position;
    }

    //***********************************************************************************
    /**
     * Drawing
     * @param {cn_camera} camera
     * @returns {string}
     */
    draw(camera, add_classes = [], ghost = false, pic = false, url_to_b64 = null) {
        var html = '';
        const css_class = add_classes.concat(this._extra_class());
        const z = this.get_altitude();
        //** draw shape */
        if (this.shape) {
            var draw_class = 'marker_shape';
            if (css_class)
                draw_class += ' ' + css_class.join(' ');
            html += '<path class=\'' + draw_class + '\' d=\'';
            var shape_contour = this.get_shape_3d();
            for (var j = 0; j < shape_contour.length; j++) {
                if (j == 0) html += 'M ';
                else if (j == 1) html += 'L ';
                var p = camera.world_to_screen(shape_contour[j]);
                if (p.length == 0) return '';
                html += '' + p[0] + ' ' + p[1] + ' ';
            }
            html += 'Z ';
            const shape_color = (camera.is_3d()) ? 'none' : this.shape_color;
            html += '\' style=\'fill: ' + shape_color + '; opacity: ' + this.shape_opacity + '\' />';
        }

        //*** compute various points of the line between position and tail */
        const p0 = camera.world_to_screen(this.position, z);
        if (p0.length == 0 || p0.some(coord => isNaN(coord))) return '';
        let p1 = null;
        let p2 = null;
        if (camera.is_3d()) {
            const normal = this.get_3d_normal();
            let d1 = cn_sub(camera.world_to_screen(fh_add(this.position, normal), z), p0);
            cn_normalize(d1);
            p1 = cn_add(p0, cn_mul(d1, 50));

            p2 = cn_add(p0, this.tail_position_3d);
            this.vertices = [p0, p1, p2];
        } else {
            p1 = camera.world_to_screen(cn_add(this.position, cn_mul(this.normal, camera.screen_to_world_scale * 50)));

            if (ghost || (this.tail_position[0] === 0 && this.tail_position[1] === 0)) {
                p2 = cn_clone(p1);
                if (this.config.line) {
                    if (p0[0] > p1[0]) {
                        p2[0] -= LABEL_SIZE;
                    } else {
                        p2[0] += LABEL_SIZE;
                    }
                }
                this.tail_position = camera.screen_to_world(p2);
            } else {
                p2 = camera.world_to_screen(this.tail_position);
            }
            this.vertices = [this.position];
            this.vertices.push(camera.screen_to_world(p1));
            this.vertices.push(camera.screen_to_world(p2));
        }

        var extra = '';
        if (css_class.length > 0) extra = css_class.join(' ');

        if (!camera.is_3d()) {
            if (this.pictures && this.pictures.length) {
                this.pictures.filter(picture => picture.show).forEach((picture, index, showedPictures) => {
                    if (picture.offset[0] === null && picture.offset[1] === null) {
                        const orientation_sign = p2[0] > p0[0] ? 1 : -1;
                        picture.offset = [this.tail_position[0] + (picture.image_size[0] * picture.scale / 2 + 220 * camera.screen_to_world_scale) * orientation_sign,
                            this.tail_position[1] - picture.image_size[1] * picture.scale / 2];
                        if (index > 0) {
                            const previous_picture = showedPictures[index - 1];
                            const offset_width = previous_picture.image_size[0] * previous_picture.scale + 30 * camera.screen_to_world_scale;
                            picture.offset[0] = previous_picture.offset[0] + offset_width * orientation_sign;
                        }
                    }
                    const w = picture.image_size[0] * picture.scale * camera.world_to_screen_scale;
                    const h = picture.image_size[1] * picture.scale * camera.world_to_screen_scale;
                    const picture_offset = camera.world_to_screen(picture.offset);
                    const image_url = cn_comment_picture.image_id_to_url(picture.image_id);
                    const url = url_to_b64 && url_to_b64[image_url] ? url_to_b64[image_url] : image_url;
                    html += '<g transform=\'translate(' + picture_offset[0] + ',' + picture_offset[1] + ') rotate(' + (-picture.orientation) + ') translate(' + (-0.5 * w) + ',' + (-0.5 * h) + ') \'>';
                    html += `<image xlink:href="${url}" x="0" y="0" width="${w}" height="${h}" />`;
                    html += '</g>';
                })
            }
        }

        html += this.draw_line_section(p0, p1, p2, css_class);
        html += this.draw_head_section(p0, p1, camera, extra);
        html += this.draw_tail_section(p0, p1, p2, extra, css_class, pic);

        return html;
    }

    _draw_label(p0, p1, css_class, pic) {
        var extra = '';
        if (css_class.length > 0) extra = css_class.join(' ');

        var html = '';
        const orientation_sign = p1[0] > p0[0] ? 1 : -1;
        html += `<circle class="marker_tail ${extra}" cx="${p1[0]}" cy="${p1[1]}" r="3"></circle>`
        html += this._draw_marker_icon(p1, orientation_sign);
        this.text_box.clear();
        this.text_box.enlarge_point([p1[0], p1[1] + 10]);
        this.text_box.enlarge_point([p1[0] + LABEL_SIZE * orientation_sign, p1[1] - 10]);
    }

    draw_line_section(p0, p1, p2, css_class) {
        let html = ''
        if (this.config.line) {
            if (this.type !== 'liaison') {
                html = `<path class="marker_line ${css_class.filter(cssClass => cssClass !== 'selected').join(' ')}"
                d="M ${p0[0]} ${p0[1]} Q ${p1[0]} ${p1[1]} ${p2[0]} ${p2[1]}" />`;
            } else {
                html = `<path class="marker_line ${css_class.filter(cssClass => cssClass !== 'selected').join(' ')}"
                d="M ${p0[0]} ${p0[1]} L ${p2[0]} ${p2[1]}" />`;
            }
        }
        return html;
    }

    draw_head_section(p0, p1, camera, extra) {
        let html = ''
        if (this.config.head) {
            var dir = cn_sub(p1, p0);
            cn_normalize(dir);
            dir = cn_mul(dir, 10);
            var pp1 = cn_add(p0, dir);
            var dd = cn_normal(cn_sub(pp1, p0));
            pp1 = cn_add(pp1, cn_mul(dd, 0.5));
            var pp2 = cn_sub(pp1, dd);
            html += this._draw_head(p0, pp1, pp2, camera, extra);
        }
        return html;
    }

    draw_tail_section(p0, p1, p2, extra, css_class, pic) {
        let html = ''
        if (this.config.tail) {
            const orientation_sign = p2[0] > p0[0] ? 1 : -1;
            html += `<circle class="marker_tail ${extra}" cx="${p2[0]}" cy="${p2[1]}" r="3"></circle>`
            html += this._draw_marker_icon(p2, orientation_sign);
            this.text_box.clear();
            this.text_box.enlarge_point([p2[0], p2[1] + 10]);
            this.text_box.enlarge_point([p2[0] + LABEL_SIZE * orientation_sign, p2[1] - 10]);

            if (css_class.indexOf('active_box') >= 0)
                html += '<rect class=\'active_box\' x=\'' + this.text_box.posmin[0] + '\' y=\'' + this.text_box.posmin[1] + '\' width=\'' + this.text_box.size[0] + '\' height=\'' + this.text_box.size[1] + '\' />';
            else if (css_class.indexOf('mouseover_box') >= 0)
                html += '<rect class=\'mouseover_box\' x=\'' + this.text_box.posmin[0] + '\' y=\'' + this.text_box.posmin[1] + '\' width=\'' + this.text_box.size[0] + '\' height=\'' + this.text_box.size[1] + '\' />';

            html += `<text class="${this._textbox_class().join(' ')}" x="${p2[0] + 24 * orientation_sign}" y="${p2[1] + 4}"
            text-anchor="${p2[0] > p1[0] ? 'start' : 'end'}">${this.label}`;
            if (pic) {
                html += ` 📷`;
            }
            html += `</text>`;
        }
        return html;
    }

    _draw_head(p0, pp1, pp2, camera, extra) {
        let html = '';
        if (this.type !== 'chip') {
            html = `<path class="marker_arrow ${extra}" d="M ${p0[0]} ${p0[1]} L ${pp1[0]} ${pp1[1]} ${pp2[0]} ${pp2[1]}  Z" />`;
        } else {
            const oc = (camera.is_3d()) ? 20 : camera.world_to_screen_scale * 0.2;
            const ic = (camera.is_3d()) ? 8 : camera.world_to_screen_scale * 0.08;
            html = `<path fill="${this.color}" opacity="${this.shape_opacity}"
            d="M${p0[0]} ${p0[1]}m-${oc},0a${oc},${oc},0 1,0 ${oc * 2},0a ${oc},${oc} 0 1,0 -${oc * 2},0zM${p0[0]}
            ${p0[1]}m-${ic},0a${ic},${ic},0 0,1 ${ic * 2},0a ${ic},${ic} 0 0,1 -${ic * 2},0z"/>`;
        }
        return html;
    }

    _extra_class() {
        return [];
    }

    _textbox_class() {
        return ['marker_text'];
    }

    _draw_marker_icon(point, orientation_sign) {
        return `<image  x="${point[0] - 9 + 15 * orientation_sign}" y="${point[1] - 9}" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAQAAABLCVATAAAAW0lEQVRIx2NgGAWjYFACZYbbDP9JhLeBujBAMcnGgGAxpkGqDPdINuYeUNcoIAMwMkQz1JMIo4G6MEA0WdEfTUODqOa1UUCNYgRroYEJioiI7CK6umgUjIJBAADZpdDE7pV5PgAAAABJRU5ErkJggg==" height="18px" width="18px"/>`;
    }

    /**
     * returns tail position, in screen coordinates
     * @param {cn_camera} camera
     * @return {number[] | null}
     */
    get_tail_screen(camera) {
        if (camera.is_3d()) {
            var arrow = this.get_arrow_screen(camera);
            if (arrow) return cn_add(arrow, this.tail_position_3d);
            return null;
        }
        return camera.world_to_screen(this.tail_position);
    }

    /**
     * returns tail position, in screen coordinates
     * @param {cn_camera} camera
     * @return {number[] | null}
     */
    get_arrow_screen(camera) {
        return camera.world_to_screen(this.position, this.get_altitude());
    }

    //***********************************************************************************
    /**
     * contains by a point
     * @param {number[]} position
     * @param {number} tolerance
     * @returns {boolean}
     */
    contains(position, tolerance = 0) {
        if (this.shape && this.shape.contains(position))
            return true;

        for (var i = 0; i < this.vertices.length - 1; i++) {
            if (cn_point_on_segment(position, this.vertices[i], this.vertices[i + 1], tolerance))
                return true;
        }

        if (this.pictures && this.pictures.length && this.pictures.filter(picture => picture.show).some(picture => {
            const pop = to_image(position, picture.orientation, picture.offset, picture.image_size, picture.scale)
            return pop[0] >= 0 && pop[0] <= 1 && pop[1] >= 0 && pop[1] <= 1;
        })) {
            return true;
        }

        return false;
    }

    //***********************************************************************************
    /**
     * contains 3D
     * @param {number[]} screen_position, in screen coordinates
     * @param {number} tolerance
     * @returns {boolean}
     */
    contains_3d(screen_position, tolerance = 0) {
        for (var i = 0; i < this.vertices.length - 1; i++) {
            if (cn_point_on_segment(screen_position, this.vertices[i], this.vertices[i + 1], tolerance))
                return true;
        }
        return false;
    }

    /**
     * Manages storey change
     * @param {cn_storey} new_storey
     * @param {cn_transaction_manager} transaction_manager
     * @returns {boolean} returns true if change was made
     */
    change_storey(new_storey, transaction_manager = null) {
        if (new_storey == this.storey) return false;
        var index = this.storey.markers.indexOf(this);
        if (index < 0) {
            console.error('WARNING in cn_marker.change_storey : marker does not seem attached to its storey');
            return false;
        }

        if (transaction_manager) {
            transaction_manager.push_item_set(this.storey, 'markers');
            transaction_manager.push_item_set(new_storey, 'markers');
            transaction_manager.push_item_set(this, 'storey');
        }
        this.storey.markers.splice(index, 1);
        this.storey = new_storey;
        new_storey.markers.push(this);

        return true;
    }

    /**
     * Update 3D data, if relevant
     * @param {cn_3d_building} building_3d
     */
    update_3d(building_3d) {
        if (!this.shape) {
            building_3d.remove_elements(this);
            return;
        }

        //*** maybe create object ?  */
        const objects3d = building_3d.get_3d_objects(this);
        if (objects3d.length < 1) {
            building_3d.add_element(new cn_storey_element(this, this.storey), this.build_bbp());
            return;
        }

        const marker_3d = objects3d[0];
        marker_3d.storey = this.storey.storey_index;
        if (marker_3d._meshes.length > 0) {
            //*** Update marker geometry */
            const polygon = new fh_polygon();
            polygon.add_contour(this.get_shape_3d());
            polygon.compute_tesselation();
            polygon.tesselation_vertices.forEach(v => v[2] += 0.01);
            fh_scene.update_mesh_geometry(marker_3d._meshes[0], polygon.tesselation_vertices, polygon.tesselation_triangles);

            //*** Update marker color */
            const col_dec = cn_color_hexa_to_rgb(this.shape_color);
            col_dec.push(this.shape_opacity);
            fh_scene.update_mesh_color(marker_3d._meshes[0], col_dec);
        }
    }

    /**
     * Builds a bbp object for the shape
     * @returns {object}
     */
    build_bbp() {
        if (!this.shape) return null;

        const bbp = {};
        bbp.ID = this.ID;
        bbp.Code_BIM = 'annotation';
        bbp.ETAGE = this.storey.storey_index;
        bbp.cnmap_storey = this.storey;
        bbp.cnmap_element = this;

        const geometry = {};
        const polygon = new fh_polygon();
        polygon.add_contour(this.get_shape_3d());
        polygon.compute_tesselation();
        polygon.tesselation_vertices.forEach(v => v[2] += 0.01);
        geometry.vertices = polygon.tesselation_vertices.flat();
        geometry.triangles = polygon.tesselation_triangles.concat([]);
        geometry.color = cn_color_hexa_to_rgb(this.shape_color);
        geometry.color.push(this.shape_opacity);
        geometry.views = ['3d'];
        bbp.geometries = [geometry];
        return bbp;
    }

    /**
     * Accept element visitor
     *
     * @param {cn_element_visitor} element_visitor
     */
    accept_visitor(element_visitor) {
        element_visitor.visit_marker(this);
    }

    /**
     * Performs a vertex operation
     * @param {function} operation
     */
    vertex_operation(operation) {
        operation(this.position);
        operation(this.element_position);
        operation(this.tail_position);
    }

    /**
     * Indicates if marker must have a label
     * @return {boolean}
     */
    is_label_required() {
        return !this.type || this.type === 'label'
    }

}
