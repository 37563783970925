import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { cn_background_map, cn_building, cn_object_instance, cn_storey, cn_svg_map } from '@acenv/cnmap-editor';
import { CndiagSelectionTool } from '../tools/cndiag-selection-tool';
import { map, takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/commons-lib';
import { PointDeControleBien } from '../../../../model/point-de-controle.model';
import { Niveau, Volume } from '../../../../model/bien.model';
import { ViewerMapConfig } from '../viewer-map/viewer-map-config';
import { BackgroundMapApiService } from '../../../../services/background-map-api.service';
import { RelationInterventionBien } from '../../../../model/intervention.model';
import { forkJoin, Observable, of } from 'rxjs';
import { InterventionService } from '../../../../services/intervention.service';
import { CnStoreyComponent, DrawingPicture } from '@acenv/cnmap-angular-editor-lib';
import { CnStoreyConfig } from '@acenv/cnmap-angular-editor-lib/lib/model/cn-storey-config.model';
import { CndiagToolSamplingCreation } from '../tools/cndiag-tool_sampling_creation';
import { CndiagToolSamplingEdition } from '../tools/cndiag-tool_sampling_edition';
import { CndiagPolluantTool } from '../tools/cndiag-polluant-tool';

const objectImageUrlFromIconId = (iconId: string) => {
    if (iconId.startsWith('uid')) {
        // Pour fonctionner avec les maquettes contenant les anciens objets par défaut dont l'URL comment par "uid"
        return iconId.substring(3);
    }
    return iconId;
};

@Component({
    selector: 'app-viewer-map-polluant',
    templateUrl: './viewer-map-polluant.component.html',
    styleUrls: ['./viewer-map-polluant.component.scss'],
})
export class ViewerMapPolluantComponent extends BaseComponent implements OnInit {
    @Input() multiSelection: boolean = false;
    @Input()
    set currentBien(currentBien: RelationInterventionBien) {
        this._currentBien = currentBien;
        if (this._building && this._currentBien) {
            this.loadBM();
            this.cnStoreyComp.refresh();
        }
    }

    @Input()
    set building(building: cn_building) {
        this._building = building;
        if (this._building && this._currentBien) {
            // if (this.storeyId) {
            //     this.changeStorey(this.storeyId);
            // } else
            if (this.currentNiveau) {
                this.changeStorey(this.currentNiveau.storeyId);
            } else {
                this.changeToFirstStorey();
            }
            this.cnStoreyComp.refresh();
        }
    }

    get building(): cn_building {
        return this._building;
    }

    // @Input()
    // set storeyIdToDisplay(storeyId: string) {
    //     this._storeyId = storeyId;
    //     if (storeyId !== undefined && this.building) {
    //         this.changeStorey(storeyId);
    //     }
    // }

    // get storeyId(): string {
    //     return this._storeyId;
    // }

    backgroundMaps: DrawingPicture[] = [];

    @ViewChild('libCnStorey', { static: true }) cnStoreyComp: CnStoreyComponent;

    @Input()
    readOnly = true;

    // Indique que l'on va utiliser des outils spécifique pour gérer les sélections
    @Input()
    useDiagTool = false;

    @Input()
    showControls = true;

    @Input()
    height100 = true;

    private _formPointDeControl: PointDeControleBien;
    @Input()
    set formPointDeControl(formPointDeControl: PointDeControleBien) {
        this._formPointDeControl = formPointDeControl;

        if (this._selectionTool) {
            this._selectionTool.refreshMap();
        }
    }

    get formPointDeControl() {
        return this._formPointDeControl;
    }

    // Niveau de la description
    @Input()
    set currentNiveau(niveau: Niveau) {
        this._currentNiveau = niveau;
        this.changeStorey(niveau.storeyId);
    }

    private _currentNiveau: Niveau;

    get currentNiveau() {
        return this._currentNiveau;
    }

    @Input()
    viewerMapConfig: ViewerMapConfig = {};

    @Input()
    cnMapConfig: CnStoreyConfig = {
        drawSpaceNames: true,
        drawBackground: true,
        drawGrid: false,
        drawScale: false,
        showCompass: false,
        padding: { left: 30, right: 30, top: 30, bottom: 30 },
    };

    @Input()
    set externalSelectedVolumes(externalSelectedVolume: Volume[]) {
        if (this.selectionTool) {
            this.selectionTool.selectVolumes(externalSelectedVolume);
        }
    }

    // @Input()
    // set multipleSelectionObject(isMultipleSelectionObject: boolean) {
    //     this._isMultipleSelectionObject = isMultipleSelectionObject;

    //     if (this._selectionTool) {
    //         this._selectionTool.multipleSelectionObject(isMultipleSelectionObject);
    //     }
    // }

    // get multipleSelectionObject() {
    //     return this._isMultipleSelectionObject;
    // }

    // POLLUANT
    selectionTool: CndiagPolluantTool;
    private sampleCreationTool: CndiagToolSamplingCreation;
    private sampleEditionTool: CndiagToolSamplingEdition;
    private cndiagSelectionTool: CndiagSelectionTool;
    private _referenceToMarker: any;
    get referenceToMarker(): any {
        return this._referenceToMarker;
    }
    // Représente l'objet pour lequel on veut faire un marker (périmètre / zone / prélèvement...)
    @Input() set referenceToMarker(referenceToMarker: any) {
        this._referenceToMarker = referenceToMarker;

        if (this.selectionTool) {
            if (this.referenceToMarker) {
                console.log(this.referenceToMarker);
                console.log(this.selectionTool);
                this.sampleCreationTool.referenceToMarker = this.referenceToMarker;
            }

            if (this._currentTool == 'SAMPLING_POLLUANT') {
                console.log('in');
                this.selectionTool.set_main_tool('SAMPLING_POLLUANT', this.referenceToMarker != null);
            } else {
                // this.selectionTool.set_main_tool(null);
                this.selectionTool.set_main_tool('selection', true);
            }
            this.cnStoreyComp.refresh();
        }
    }

    _currentTool;
    @Input() set currentTool(toolName: string) {
        this._currentTool = toolName;
        if (this.selectionTool) {
            if (this.referenceToMarker) {
                this.sampleCreationTool.referenceToMarker = this.referenceToMarker;
            }
            if (toolName == 'SAMPLING_POLLUANT') {
                this.selectionTool.set_main_tool('SAMPLING_POLLUANT', this.referenceToMarker != null);
            } else {
                this.selectionTool.set_main_tool(null);
            }
            this.cnStoreyComp.refresh();
        }
    }

    @Output()
    toolEvent = new EventEmitter<any>();

    currentStorey: cn_storey;
    cnStoreyReadonly: boolean;
    // elementId = 'storey_svg_editor';

    private _currentBien: RelationInterventionBien;
    private _building: cn_building;
    // private _storeyId: string;
    private _selectionTool: CndiagSelectionTool = null;
    // private _isMultipleSelectionObject = false;

    static getObjectImageUrlFromIconId(iconId: string) {
        if (iconId.startsWith('uid')) {
            // Pour fonctionner avec les maquettes contenant les anciens objets par défaut dont l'URL comment par "uid"
            return iconId.substr(3);
        }
        return iconId;
    }

    niveauZoom: number = 0;

    constructor(private readonly interventionService: InterventionService, private readonly backgroundMapApiService: BackgroundMapApiService) {
        (cn_background_map as any).image_id_to_url = (fileId) => {
            return (this.backgroundMaps.find((bgmu) => bgmu.fileId == fileId) || ({} as DrawingPicture)).imageUrl;
        };
        super();
    }

    ngOnInit(): void {
        // if (!this._storeyId) {
        //     this._storeyId = null;
        //     this.changeToFirstStorey();
        // }
        this.changeToFirstStorey();
        this.loadBM();
        // if (this.building && !this.currentStorey) {
        //     this.currentStorey = this.building.storeys[this._storeyId];
        // }
        // Ouverture de CnStoreyComponent en readonly si readOnly est true ou si useDiagTool est true.
        this.cnStoreyReadonly = this.readOnly || this.useDiagTool;
        // Renseigne la méthode statique pour récupérer l'URL de l'image d'un objet
        (cn_object_instance as any).image_id_to_url = objectImageUrlFromIconId;

        (this.cnStoreyComp as any).svgMap$
            .pipe(
                takeUntil(this.ngUnsubscribe),
                tap((svgMap: cn_svg_map) => {
                    if (this.useDiagTool && svgMap) {
                        svgMap._scene.storey = this.currentStorey;

                        // Unregisters events
                        if (this.sampleCreationTool) {
                            this.sampleEditionTool.unbind('sample_moved');
                            this.sampleEditionTool.unbind('sample_created');
                        }

                        // Outil création prélèvement
                        this.sampleCreationTool = new CndiagToolSamplingCreation(svgMap);
                        this.sampleCreationTool.referenceToMarker = this.referenceToMarker;
                        this.sampleCreationTool.on('sample_created', (value) => {
                            this.toolEvent.emit({
                                event: 'sample_created',
                                mode: 'creation',
                                tool: this.sampleCreationTool,
                                toolName: 'CREATION',
                                value: value,
                            });
                        });

                        // Outil modification prélèvement
                        this.sampleEditionTool = new CndiagToolSamplingEdition(svgMap);
                        this.sampleEditionTool.on('sample_moved', (value) => {
                            this.toolEvent.emit({
                                event: 'sample_moved',
                                mode: 'edition',
                                tool: this.sampleEditionTool,
                                toolName: 'EDITION',
                                value: value,
                            });
                        });

                        // Unregisters events
                        if (this.cndiagSelectionTool) {
                            this.cndiagSelectionTool.unbind('selection_change');
                            svgMap.unbind('remove_elements');
                        }
                        this.cndiagSelectionTool = new CndiagSelectionTool(svgMap, {}, this.currentStorey);
                        if (this.multiSelection) {
                            this.cndiagSelectionTool.multipleSelectionObject(true);
                            this.cndiagSelectionTool.multiSelection(true);
                        }
                        svgMap.on('remove_elements', (value) => {
                            this.toolEvent.emit({
                                event: 'remove_elements',
                                value: value,
                            });
                        });
                        this.cndiagSelectionTool.on('selection_change', (value) => {
                            this.toolEvent.emit({
                                event: 'selection_change',
                                mode: 'edition',
                                tool: this.selectionTool,
                                toolName: 'SELECTION',
                                value: value,
                            });
                        });

                        this.selectionTool = new CndiagPolluantTool(svgMap);

                        this.selectionTool.add_tool('selection', ['selection'], this.cndiagSelectionTool, this.cndiagSelectionTool);

                        this.selectionTool.add_tool('SAMPLING_POLLUANT', ['SAMPLING_POLLUANT'], this.sampleEditionTool, this.sampleCreationTool);

                        // Si un objet référence existe, on le set puis on active le tool creation
                        if (this.referenceToMarker) {
                            if (this._currentTool == 'SAMPLING_POLLUANT') {
                                this.sampleCreationTool.referenceToMarker = this.referenceToMarker;
                                this.selectionTool.set_main_tool('SAMPLING_POLLUANT', this.referenceToMarker != null);
                            } else {
                                this.selectionTool.set_main_tool('selection', true);
                            }
                            this.cnStoreyComp.refresh();
                        } else {
                            this.selectionTool.set_main_tool('SAMPLING_POLLUANT', false);
                        }

                        svgMap.set_svg_tool(this.selectionTool);
                    }
                })
            )
            .subscribe();
    }

    private loadBM() {
        this.loadBackgroundmaps(this._currentBien)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.cnStoreyComp.refresh());
    }

    // zoom(zoomIn: boolean) {
    //     if (zoomIn) this.niveauZoom++;
    //     else this.niveauZoom--;
    //     // this.cnStoreyComp.zoom(this.niveauZoom);
    // }

    // centerCamera() {
    //     this.cnStoreyComp.centerCamera();
    // }

    refresh() {
        this.cnStoreyComp.refresh();
    }

    private changeStorey(storeyId: string) {
        this.currentStorey = this.building.find_storey(storeyId);
        // this.cnStoreyComp.refresh();
    }

    private changeToFirstStorey() {
        this.currentStorey = this.building.storeys[0];
        this.cnStoreyComp.refresh();
    }

    private loadBackgroundmaps(currentBien: RelationInterventionBien): Observable<DrawingPicture[]> {
        if (currentBien.bien.backgroundMaps.length > 0) {
            return forkJoin(
                currentBien.bien.backgroundMaps
                    .slice()
                    .sort((a, b) => a.createdDate.localeCompare(b.createdDate))
                    .map((bg) => {
                        return this.backgroundMapApiService
                            .downloadBackgroundImage(this.interventionService.getCurrentInterventionValue().id, currentBien.id, bg.fileId)
                            .pipe(
                                map((res) => {
                                    const background = { ...bg };
                                    if (res) {
                                        background.imageUrl = res.fileContent;
                                    }
                                    return background;
                                })
                            );
                    })
            ).pipe(
                map((backgrounds) => {
                    this.backgroundMaps = backgrounds;
                    return this.backgroundMaps;
                })
            );
        } else {
            this.backgroundMaps = [];
            return of(this.backgroundMaps);
        }
    }
}
