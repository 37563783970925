import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { MatDialog } from '@angular/material/dialog';
import { CommentModalComponent } from '../comment-modal/comment-modal.component';
import { DialogUtils } from '../../../utils/dialog.utils';
import { Intervention, PrestationDiagnostic, Revision } from '../../../model/intervention.model';
import { InterventionService } from '../../../services/intervention.service';
import { takeUntil } from 'rxjs/operators';
import { DiagnosticService } from '../../../services/diagnostic.service';
import { CnSpinnerService } from '../cn-spinner/service/cn-spinner.service';
import { EnvironmentUtils } from 'src/app/utils/environment.utils';
import { FormControl } from '@angular/forms';
import { AddTexteModal } from '../add-texte-modal/add-texte-modal';

@Component({
    selector: 'app-notes-revision-buttons',
    templateUrl: './notes-revision-buttons.component.html',
    styleUrls: ['./notes-revision-buttons.component.scss'],
})
export class NotesRevisionButtonsComponent extends BaseComponent implements OnInit {
    @Input()
    prestationDiagnostic: PrestationDiagnostic;

    @Input()
    intervention: Intervention;

    @Input()
    multiPrestationsDiag;

    @Output()
    onClose = new EventEmitter<any>();

    constructor(public matDialog: MatDialog, private interventionService: InterventionService, private cnSpinnerService: CnSpinnerService) {
        super();
    }

    color(): string {
        let color = 'primary';
        if (this.multiPrestationsDiag == false) {
            if (
                this.prestationDiagnostic.revision.motifRevision === undefined ||
                this.prestationDiagnostic.revision.motifRevision === null ||
                this.prestationDiagnostic.revision.motifRevision.length === 0
            ) {
                color = 'warn';
            }
        } else {
            this.intervention.prestationsDiagnostics.forEach((presta) => {
                if (
                    presta.revision.motifRevision == undefined ||
                    presta.revision.motifRevision == null ||
                    presta.revision.motifRevision.length === 0
                ) {
                    color = 'warn';
                }
            });
        }
        return color;
    }

    ngOnInit(): void {}

    openMotifRevision() {
        const dialogRef = this.matDialog.open(AddTexteModal);
        let instance = dialogRef.componentInstance;
        instance.tooltip = 'Merci de renseigner un motif de révision';
        instance.title = 'Ajouter un motif de révision';
        instance.textesPredefinis.push(
            "Changement d'adresse. ",
            "Changement d'adresse du bien. ",
            "Changement d'adresse du propriétaire. ",
            'Erreur de saisie ',
            'Erreur de saisie sur le point de contrôle : '
        );

        if (this.multiPrestationsDiag == false) {
            instance.texte = this.prestationDiagnostic.revision?.motifRevision ?? (this.prestationDiagnostic.revision.motifRevision || null);
            dialogRef.afterClosed().subscribe((result) => {
                if (result != undefined) {
                    this.prestationDiagnostic.revision.motifRevision = result;
                    this.interventionService.updateIntervention(this.intervention).subscribe();
                }
            });
        } else {
            instance.texte =
                this.intervention.prestationsDiagnostics[0].revision?.motifRevision ??
                (this.intervention.prestationsDiagnostics[0].revision.motifRevision || null);
            this.intervention.prestationsDiagnostics.forEach((presta) => {
                dialogRef.afterClosed().subscribe((result) => {
                    if (result != undefined) {
                        presta.revision.motifRevision = result;
                        this.interventionService.updateIntervention(this.intervention).subscribe();
                    }
                });
            });
        }
    }
}
