<div class="pb-5">
    <div class="block-title p-2 pl-5 my-3 d-inline-block" *ngIf="data">{{ data.title }}</div>
    <div class="block-content px-5 ml-2 mb-4">
        <ng-container *ngFor="let espace of listeEspaces">
            <div class="block-subtitle-2 mt-4 mb-2">{{ espace.nom }}</div>
            <table>
                <tr>
                    <th class="nom">Périmètres concernés</th>
                    <th>Description</th>
                    <th class="type-ouvrage">Type d'ouvrage</th>
                </tr>

                <tr *ngIf="espace.listePerimetres.length === 0">
                    <td colspan="3" class="text-center p-4">Aucun périmètre défini pour cet espace.</td>
                </tr>

                <tr *ngFor="let perimetre of espace.listePerimetres">
                    <td>
                        <b>{{ perimetre.nom }}</b>
                    </td>
                    <td>
                        <b>{{ perimetre.description }}</b>
                    </td>
                    <td>
                        <b>{{ perimetre.typeOuvrage }}</b>
                    </td>
                </tr>
            </table>
        </ng-container>
    </div>
</div>
