'use strict';

import { cn_camera } from '../svg/cn_camera';
import { CN_CURRENT_DATE } from '../utils/cn_transaction_manager';

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** cn_element : base virtual class for almost everything
//***********************************************************************************
//***********************************************************************************

import { cn_add, cn_box, cn_uuid } from '../utils/cn_utilities';
import { cn_lazy_data } from './cn_lazy_data';

const STATUS_DISABLED = -1;
const STATUS_NORMAL = 0;
const STATUS_MOUSEOVER = 1;
const STATUS_SELECTED = 2;

var _NB_ELEMENTS = 0;

export class cn_element {
    constructor(parent = null) {
        this.ID = cn_element.generate_ID();
        _NB_ELEMENTS++;
        this.class_name = 'cn_element';
        this.draw_priority = 10;
        this.status = 0;
        this.parent = parent;

        this.removable = true;
        this.selectable = true;

        this.parameters = {};
        this.visible = true;
        this.visibility_3d = false;
        this.object = undefined;

        this.locked = false;
        this._date = CN_CURRENT_DATE;
        this._creation_date = CN_CURRENT_DATE;
        this._param_dates = {};
        this._children_dates = new Map();

        //*** Data used to link to 3D */
        this._storey_3d = null;
        this._object_3d = null;

        this._lazy_data = {};
    }

    /**
     * Generates an ID for element
     */
    static generate_ID() {
        return cn_uuid('element' + _NB_ELEMENTS);
    }

    //*************************************************
    //*** Compare to another element
    //*************************************************
    equals(other, check_names = true) {
        if (typeof (other) != 'object') return false;
        if (other == null) return false;
        if (other.constructor.name != this.constructor.name) return false;

        var s0 = this.serialize();
        var s1 = other.serialize();
        if (typeof (s0.ID) == 'string') s0.ID = '';
        if (typeof (s1.ID) == 'string') s1.ID = '';
        if (!check_names) {
            if (typeof (s0.name) == 'string') s0.name = '';
            if (typeof (s1.name) == 'string') s1.name = '';
        }
        return JSON.stringify(s0) == JSON.stringify(s1);
    }

    //*************************************************
    //*** Parameter management
    //*************************************************
    /**
     * @returns {object} - Returns the parameters of the element
     */
    get_parameters() {
        return this.parameters;
    }

    /**
     * @param {string} param_name : parameter name
     * @returns {any} - Returns the value of given parameter, or undefined
     */
    get_parameter(param_name) {
        return this.parameters[param_name];
    }

    /**
     * Sets a given parameter. If value is 'undefined', removes the parameter
     * @param {string} param_name
     * @param {any} value
     */
    set_parameter(param_name, value) {
        if (value == undefined) {
            if (typeof (this.parameters[param_name]) != 'undefined')
                delete this.parameters[param_name];
        } else
            this.parameters[param_name] = value;
    }

    serialize() {
        return undefined;
    }

    /**
     * Returns the bounding box of the element
     * @returns {cn_box}
     */
    get_bounding_box() {
        return new cn_box();
    }

    /**
     * Returns the bounding box of the element
     * @param {cn_camera} camera
     * @returns {cn_box}
     */
    get_screen_bounding_box(camera) {
        const box = new cn_box();
        const world_box = this.get_bounding_box();
        if (!world_box.posmin) return box;
        box.enlarge_point(camera.world_to_screen(world_box.posmin));
        box.enlarge_point(camera.world_to_screen(cn_add(world_box.posmin, world_box.size)));
        return box;
    }

    /**
     * SVG drawing
     * @param {cn_camera} camera
     * @param {Array<string>} extra
     * @returns {string}
     */
    draw(camera, extra) {
        return '';
    }

    /**
     * Sets the date. Only to be used from transactions !!!!
     * @param {Array<string>} param_names
     * @param {number} date
     * @param {any} changed_item_constructor
     */
    set_date(param_names, date, changed_item_constructor = null) {
        this._date = date;
        if (changed_item_constructor) {
            var i = this._children_dates[changed_item_constructor];
            if (!i) {
                i = new cn_element();
                this._children_dates[changed_item_constructor] = i;
            }
            i.set_date(param_names, date);
        }
        else
            param_names.forEach(pn => this._param_dates[pn] = date);
        if (this.parent && this.parent instanceof cn_element)
            this.parent.set_date(param_names, date, changed_item_constructor || this.constructor);
    }

    /**
     * Returns the lastest date of modification of a param.
     * If param_name is empty, or does not match a dated param, returns the date of the item
     * @param {string} param_name
     * @returns {number}
     */
    get_date(param_name = '') {
        if (param_name == '') return this._date;
        if (typeof (this._param_dates[param_name]) == 'number') return this._param_dates[param_name];
        return this._creation_date;
    }

    /**
     * returns true if param hasn't changed since date.
     * If param is empty, returns true if item is up to date.
     * @param {number} date
     * @param {string} param_name
     * @returns {boolean}
     */
    up_to_date(date, param_name = '') {
        if (param_name == '') return this._date <= date;
        if (typeof (this._param_dates[param_name]) == 'number') return this._param_dates[param_name] <= date;
        return this._creation_date <= date;
    }

    /**
     * returns true if param hasn't changed since date.
     * If param is empty, returns true if item is up to date.
     * @param {any} child_constructor
     * @param {number} date
     * @param {string} param_name
     * @returns {boolean}
     */
    children_up_to_date(child_constructor, date, param_name = '') {
        const ch = this._children_dates[child_constructor];
        if (!ch) return true;
        return ch.up_to_date(date, param_name);
    }

    /**
     * Checks an up to date dated element relative to a given identifyer.
     * Construction method is used to build the data if necessary.
     * The identifyer allows to store several lazy data for the same field in the same element
     * (for example identifyer can be the storey, if the element is part of a scene. This allows to store a lazy data for an element for each storey).
     * The reference is the reference that is checked for datation. If not set, the highest ancestor is taken.
     * @param {function} construction_method
     * @param {string} field_name
     * @param {any} identifyer
     * @param {cn_element} reference
     * @returns {cn_lazy_data}
     */
    get_lazy_data(construction_method, field_name, identifyer = null, reference = null) {
        if (typeof (this._lazy_data[field_name]) != 'object') this._lazy_data[field_name] = [];
        var x = this._lazy_data[field_name].find(e => e.identifyer == identifyer);

        //*** Date reference is either the reference passed in arguyment, either the highest ancestor. */
        var actual_reference = reference;
        if (!actual_reference) {
            actual_reference = this;
            while (actual_reference.parent) actual_reference = actual_reference.parent;
        }
        if (x && actual_reference.up_to_date(x.date))
            return x.data;

        if (!x) {
            x = new cn_lazy_data(identifyer);
            this._lazy_data[field_name].push(x);
        }
        x.data = construction_method();
        x.date = CN_CURRENT_DATE;
        return x.data;
    }
}

