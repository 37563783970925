'use strict';

//***********************************************************************************
//***********************************************************************************
//**** Plugin options
//***********************************************************************************
//***********************************************************************************
class cn_plugin_option_clazz {
    constructor() {
        this.numerotation = false;
        this.zpsos = false;
    }
}

export const cn_plugin_option = new cn_plugin_option_clazz();
