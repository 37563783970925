<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
    <!-- This is the tree node template for leaf nodes -->
    <!-- There is inline padding applied to this node using styles.
    This padding value depends on the mat-icon-button width. -->
    <mat-tree-node
        *matTreeNodeDef="let node"
        matTreeNodeToggle
        [ngClass]="{ 'background-highlight': activeNode === node }"
    >
        <button mat-button style="width: 100%" (click)="onClickNode(node)">{{ node.name }}</button>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <div class="mat-tree-node" [ngClass]="{ 'background-highlight': activeNode === node }">
            <button
                mat-button
                style="width: 100%"
                matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name"
                (click)="onClickNode(node)"
            >
                <mat-icon class="mat-icon-rtl-mirror">
                    {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                </mat-icon>
                {{ node.name }}
            </button>
        </div>
        <!-- There is inline padding applied to this div using styles.
            This padding value depends on the mat-icon-button width.  -->
        <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
            <ng-container matTreeNodeOutlet></ng-container>
        </div>
    </mat-nested-tree-node>
</mat-tree>
