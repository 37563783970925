import { cn_svg_map, cn_svg_tool_marker_edition } from '@acenv/cnmap-editor';
import { LevelToDisplay } from 'src/app/modules/diagnostics/hap/model/hap.model';
import { MarkerHandler } from '../editor-map/handler/marker-handler';
import { CndiagMarker } from '../model/cndiag-marker.model';

export class CndiagMarkerToolEdition extends cn_svg_tool_marker_edition {
    // Le level permet de savoir si on peut sélectionner ou pas l'objet sur lequel on a cliqué
    level: LevelToDisplay;
    // Si on est en read only, on ne doit pas pouvoir sélectionner un marker pour l'éditer
    readonlyMode = false;
    /**
     * @param svgMap
     * @param level
     */
    constructor(svgMap: cn_svg_map, level: LevelToDisplay, readonlyMode: boolean) {
        super(svgMap);
        this._marker_class = CndiagMarker;
        this.level = level;
        this.readonlyMode = readonlyMode;
    }

    // ***********************************************************************************
    // on_selection_change() {
    //     console.log('on_selection_change');
    //
    //     this._handler = null;
    //     // *** We create a handler only if single selection
    //     const sel = this._controller.get_selection();
    //     if (sel.length != 1 || sel[0].constructor != this._marker_class || this.readonlyMode) {
    //         return false;
    //     }
    //
    //     // *** build edition handler */
    //     this._handler = this._build_handler(sel as CndiagMarker[]);
    //     console.log(this._handler);
    //
    //     return true;
    // }

    // ***********************************************************************************
    /**
     * Builds a new marker handler.
     * @param {CndiagMarker} marker
     * @returns {MarkerHandler}
     */
    _build_handler(markers: CndiagMarker[]) {
        const markerHandler = new MarkerHandler(markers[0], this._map);
        // Prolonge l'évènement au composant parent
        markerHandler.on('change', () => {
            this.call('change');
        });
        return markerHandler;
    }
}
