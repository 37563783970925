import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WidgetSelectModeEnum } from 'src/app/modules/shared/widget-select/widget-select.component';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { MpcaItemOld } from 'src/app/modules/diagnostics/polluant/model/mpca.model.old';
import { MpcaProcessusModalComponent } from '../../../../../etude-situation/listeMPCA/mpca-descriptif/mpca-processus-modal/mpca-processus-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MpcaService } from 'src/app/modules/diagnostics/polluant/services/mpca.service';
import { PolluantPrelevement } from 'src/app/modules/diagnostics/polluant/model/polluant-prelevement.model';
import { MpcaProcessus } from '../../../../../../model/mpca.model.old';

@Component({
    selector: 'app-prelevement-form-materiaux',
    templateUrl: './prelevement-form-materiaux.component.html',
    styleUrls: ['./prelevement-form-materiaux.component.scss'],
})
export class PrelevementFormMateriauxComponent implements OnInit {
    formMateriaux: FormGroup;

    @Input('polluantConfig')
    polluantConfig: any;
    materiauxConfig: any[];
    etatDegradationConfig: any;
    typeAmianteConfig: any;
    typeProtectionExpositionConfig: any;
    typeEtancheiteConfig: any;
    typeConditionExpoChocVibrationConfig: any;
    typeConditionExpoCirculationAirConfig: any;

    @Input('diagnostic')
    diagnostic: Diagnostic;
    contenuDiagnostic: PolluantPrelevement;

    @Input('selectedMpcaId')
    selectedMpcaId: string;
    selectedMpca: MpcaItemOld;

    @Input() // Utilisé pour passer les champs en read-only
    disabled: Boolean;

    @Output('formChange')
    formChange: EventEmitter<any> = new EventEmitter<any>();

    widgetSelectModeEnum: typeof WidgetSelectModeEnum = WidgetSelectModeEnum;

    constructor(private formBuilder: FormBuilder, private dialog: MatDialog, private mpcaFormService: MpcaService) {}

    ngOnInit(): void {
        this.contenuDiagnostic = this.diagnostic.contenuDiagnostic as PolluantPrelevement;
        this.restoreData();
        this.initForm();
    }

    ngDoCheck(): void {
        //Cleanup
        if (this.selectedMpcaId == undefined || (this.selectedMpca != undefined && this.selectedMpcaId == this.selectedMpca.id)) {
            return;
        } else {
            //Déclence l'initialisation du formulaire uniquement si il s'agit d'un changement
            //de mpca à afficher
            this.selectedMpca = this.contenuDiagnostic.prelevement.data.mpcaList.find((mpca: MpcaItemOld) => mpca.id == this.selectedMpcaId);
            //this.selectedMpcaId = this.selectedMpca.id;
            this.initForm();
        }
    }

    restoreData() {}

    initForm() {
        if (this.selectedMpca != undefined) {
            //Création du formulaire
            this.formMateriaux = this.createMpcaForm(this.selectedMpca);
            this.formMateriaux.valueChanges.subscribe(() => {
                const index = this.contenuDiagnostic.prelevement.data.mpcaList.findIndex((mpca) => mpca.id == this.selectedMpcaId);
                this.contenuDiagnostic.prelevement.data.mpcaList[index] = this.formMateriaux.getRawValue();
                this.formChange.emit(this.contenuDiagnostic);
            });
        }
    }

    private createMpcaForm(item: MpcaItemOld): FormGroup {
        const _form = this.formBuilder.group(
            {
                id: [item.id],
                general: this.formBuilder.group({
                    materiaux: [
                        item.general.materiaux,
                        {
                            validators: Validators.required,
                            updateOn: 'change',
                        },
                    ],
                    materiauxClient: [item.general.materiauxClient],
                    etatDegradation: [item.general.etatDegradation, Validators.required],
                    typeAmiante: [item.general.typeAmiante, Validators.required],
                }),
                protectionExposition: this.formBuilder.group({
                    type: [
                        item.protectionExposition.type,
                        {
                            updateOn: 'change',
                        },
                    ],
                    etancheite: [
                        item.protectionExposition.etancheite,
                        {
                            updateOn: 'change',
                        },
                    ],
                    chocVibration: [
                        item.protectionExposition.chocVibration,
                        {
                            updateOn: 'change',
                        },
                    ],
                    circulationAir: [
                        item.protectionExposition.circulationAir,
                        {
                            updateOn: 'change',
                        },
                    ],
                }),
                processus: new FormArray([]),
            },
            { updateOn: 'blur' }
        );
        item.processus.forEach((process) => {
            (_form.get('processus') as FormArray).push(this.createProcessusForm(process));
        });
        return _form;
    }

    private createProcessusForm(processus: MpcaProcessus): FormGroup {
        if (processus.primary == null || processus.primary == undefined) {
            processus.primary = false;
        }

        const processForm = this.formBuilder.group(
            {
                id: [processus.id],
                nom: [processus.nom, Validators.required],
                primary: [processus.primary],
                taches: new FormArray([]),
                metop: this.formBuilder.group({
                    methode: [processus.metop.methode, Validators.required],
                    technique: [processus.metop.technique],
                    outil: [processus.metop.outil],
                    autresOutils: [processus.metop.autresOutils],
                    aspiration: [processus.metop.aspiration],
                    travailHumide: [processus.metop.travailHumide],
                }),
                pendantTravaux: this.formBuilder.group({
                    niveauEmpoussierement: [processus.pendantTravaux.niveauEmpoussierement, Validators.required],
                    typeMasqueDesamianteur: [processus.pendantTravaux.typeMasqueDesamianteur, Validators.required],
                    concentrationFi: [processus.pendantTravaux.concentrationFi, Validators.required],
                }),
                echantillonnage: this.formBuilder.group({
                    concentrationAttendue: [processus.echantillonnage.concentrationAttendue],
                    dureeRepresentativeProcessus: [processus.echantillonnage.dureeRepresentativeProcessus],
                    dureeSaturationPoussieres: [processus.echantillonnage.dureeSaturationPoussieres],
                    debitPrelevement: [processus.echantillonnage.debitPrelevement],
                    fractionFiltre: [processus.echantillonnage.fractionFiltre],
                }),
            },
            { updateOn: 'blur' }
        );

        processus.taches.forEach((tache) => {
            (processForm.get('taches') as FormArray).push(this.mpcaFormService.getTacheGroup(tache));
        });

        return processForm;
    }

    /**
     *  @description Ouvre la modal d'édition d'un processus avec le paramètre '-1'
     *               pour indiquer une création
     */
    addMpcaProcessus() {
        this.openModal(-1);
    }

    /**
     * @description Ouvre la modal d'édition d'un processus avec l'index du processus à modifier
     * @param index Index du processus à modifier
     */
    editMpcaProcessus(index: number) {
        this.openModal(index);
    }

    /**
     * Méthode appellée pour supprimer un processus de MPCA à l'index donné
     * @param index Index du processus à supprimer
     */
    deleteMpcaProcessus(index: number) {
        this.formMpcaProcessusList.removeAt(index);
    }

    /**
     * Méthode appellée pour dupliquer un processus de MPCA
     * @param index Index du processus à dupliquer
     */
    duplicateMpcaProcessus(index: number) {
        const processusToInsert = this.mpcaFormService.createProcessusForm(this.formMpcaProcessusList.value[index]);
        this.formMpcaProcessusList.insert(1, processusToInsert);
    }

    /**
     *
     * @param index Index du processus avec lequel initialiser le formulaire
     * Dans le cas d'une création, l'index est à '-1' pour initialiser le formulaire à vide
     */
    openModal(index) {
        let selectedProcessus = undefined;
        if (index != -1) {
            selectedProcessus = this.formMpcaProcessusList.value[index];
        }

        const dialogRef = this.dialog.open(MpcaProcessusModalComponent, {
            width: '250px',
            data: {
                diagnostic: this.diagnostic,
                polluantConfig: this.polluantConfig,
                selectedMpca: this.selectedMpca,
                selectedProcessus,
                indexProcessus: index,
            },
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result.action == 'save') {
                if (result.index == '-1') {
                    //Dans le cas d'une création
                    //On ajoute le formulaire du processus créé au formulaire du MPCA
                    this.formMpcaProcessusList.push(result.value);
                } else {
                    //Dans le cas d'une mise à jour
                    //On patch les valeurs du processus dans le formulaire du MPCA
                    //  avec les valeurs retournées
                    this.formMpcaProcessusList.controls[result.index].patchValue({
                        nom: result.value.getRawValue().nom,
                        metop: result.value.getRawValue().metop,
                        pendantTravaux: result.value.getRawValue().pendantTravaux,
                        echantillonnage: result.value.getRawValue().echantillonnage,
                    });

                    //Suppression de toutes les tâches de processus
                    while ((this.formMpcaProcessusList.controls[result.index].get('taches') as FormArray).controls.length !== 0) {
                        (this.formMpcaProcessusList.controls[result.index].get('taches') as FormArray).removeAt(0);
                    }

                    //Re-création des tâches de processus avec les valeurs retournées
                    result.value.get('taches').controls.forEach((tache: FormGroup) => {
                        (this.formMpcaProcessusList.controls[result.index].get('taches') as FormArray).push(tache);
                    });
                }
            } else if (result.action == 'delete') {
                //Supprimer du processus à l'index sélectionné
                this.deleteMpcaProcessus(result.index);
            }
        });
    }

    //Getter
    get formMpcaProcessusList(): FormArray {
        return this.formMateriaux.get('processus') as FormArray;
    }
}
