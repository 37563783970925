<h1 mat-dialog-title class="primary">
    <span>Ajouter une assurance</span>
</h1>

<form [formGroup]="assuranceForm" (ngSubmit)="onSubmitForm()">
    <div class="w-100 pr-2 pt-2">
        <div mat-dialog-content class="dialog-content">
            <div class="d-flex p-2 align-items-center">
                <div class="col-xl-4 mat-subheading-2 m-0 primary required">Compagnie</div>
                <div class="col-xl-8 pr-2">
                    <mat-form-field class="w-100">
                        <input matInput type="text" formControlName="compagnie" />
                        <app-mat-error [control]="assuranceForm.get('compagnie')"></app-mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="d-flex p-2 align-items-center">
                <div class="col-xl-4 mat-subheading-2 m-0 primary required">Numéro de police</div>
                <div class="col-xl-8 pr-2">
                    <mat-form-field class="w-100">
                        <input matInput type="text" formControlName="numeroPolice" />
                        <app-mat-error [control]="assuranceForm.get('numeroPolice')"></app-mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="d-flex p-2 align-items-center">
                <div class="col-xl-4 mat-subheading-2 m-0 primary required">Date de début</div>
                <div class="col-xl-8 pr-2">
                    <mat-form-field class="w-100">
                        <input
                            matInput
                            type="text"
                            formControlName="dateDebutValidite"
                            [matDatepicker]="dateDebutValidite"
                        />
                        <app-mat-error [control]="assuranceForm.get('dateDebutValidite')"></app-mat-error>
                        <mat-datepicker-toggle matSuffix [for]="dateDebutValidite"> </mat-datepicker-toggle>
                        <mat-datepicker #dateDebutValidite></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="d-flex p-2 align-items-center">
                <div class="col-xl-4 mat-subheading-2 m-0 primary required">Date d'échéance</div>
                <div class="col-xl-8 pr-2">
                    <mat-form-field class="w-100">
                        <input matInput type="text" formControlName="dateEcheance" [matDatepicker]="dateEcheance" />
                        <app-mat-error [control]="assuranceForm.get('dateEcheance')"></app-mat-error>
                        <mat-datepicker-toggle matSuffix [for]="dateEcheance"> </mat-datepicker-toggle>
                        <mat-datepicker #dateEcheance></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <!-- Justificatif -->
            <app-import-file
                [documentTitle]="documentTitle"
                [codeTypeDocument]="codeTypeDocument"
                [fileFormControl]="justificatifControl"
                [labelDocument]="labelDocument"
            ></app-import-file>
        </div>

        <div mat-dialog-actions class="justify-content-end">
            <button mat-button color="primary" type="button" (click)="onClickBtnCancel()">
                <mat-icon>close</mat-icon>
                <span>Annuler</span>
            </button>
            <button mat-raised-button color="primary" [disabled]="assuranceForm.invalid">
                <mat-icon>check</mat-icon>
                <span>Valider</span>
            </button>
        </div>
    </div>
</form>
