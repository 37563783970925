import { TypePrelevementAmiante } from 'src/app/model/prelevement-generique.model';
import { SelectItem } from '../../../../model/select-item.model';

export class PolluantConfig {
    id: string = '';
    objectifsMesurage: ObjectifsMesurage[];
    contenuListeDeroulante: ContenuListeDeroulante;
    dateDeCreation: Date;
    idInterne: string = '';
    objectifsEligible: ObjectifsTypeZone[];
}

export class ObjectifsTypeZone {
    contexte: String;
    environnement: String[];
    typeZone: String;
    zse: String;
    objectifs: String[];
    objectifsMultiple: boolean;
}
export class ObjectifsMesurage {
    id: string = '';
    description: string = '';
    conditionsPrelevement: ConditionsPrelevement[];
    titreRapport: string = '';
    sousTitreRapport: string = '';
    chapitreTravaux: boolean = false;
    pendantTravauxInterventionLieAmiante: boolean = false;
}

export class ConditionsPrelevement {
    id: string = '';
    label: string = '';
    reference: string = '';
    typeActiviteAmiante: string[];
    typePrelevement: TypePrelevementAmiante = TypePrelevementAmiante.UNDEFINED;
    methodeCalculQuantitePompe: string = '';
    materiauxConcernes: string[];
    objectifMesure: string = '';
    objectifCourtNomZone: string = '';
    contextTravaux: boolean = false;
    contexte?: string = '';
    processusName?: string = '';
    texteReglementaire: string = '';
    norme: string = '';
    normeApplication: string = '';
    sensibiliteAnalytiqueDefinie: boolean = false;
    sensibiliteAnalytique: number = 0;
    sensibiliteAnalytiqueAttendue: string = '';
    limiteSuperieurIntervalConfiance: string = '';
    descriptionTypePrelevement: string = '';
    descriptionConditionPrelevement: string = '';
    dureeMin: string = '';
    dureeMax: string = '';
    simulation: string = '';
    justificatifEmplacementObligatoire: boolean = false;
    justificatifEmplacement: string = '';
    emplacement: string = '';
    precisionLocalisation: string = '';
    frequence: string = '';
    calculAutoFrequence: boolean = false;
    valDefautPrelevementEstRelgementaire: boolean = false;
    materielChantierNecessaire: boolean = false;
}

export class ContenuListeDeroulante {
    analyseCauseNonConf: ListeDeroulante;
    impossibiliteTechnique: ListeDeroulante;
    zoneRecuperation: ListeDeroulante;
    zoneStockageDechet: ListeDeroulante;
    localisationAcr: ListeDeroulante;
    localisationMest: ListeDeroulante;
    localisationMetop: ListeDeroulante;
    evaluationValidation: ListeDeroulante;
    traitementImmediatNonConf: ListeDeroulante;
    etatDegradation: ListeDeroulante;
    frequentation: ListeDeroulanteIcones;
    justificatifAbsenceStrategie: ListeDeroulante;
    simulationActiviteHumaine: ListeDeroulanteGenerique;
    methodeSimulation: ListeDeroulanteIcones;
    pointDeRosee: ListeDeroulante;
    positionGeneraleOperateur: ListeDeroulante;
    postureGeneraleOperateur: ListeDeroulante;
    precipitations: ListeDeroulante;
    typeAmiante: ListeDeroulante;
    usageLocaux: ListeDeroulante;
    typeCaptageSource: ListeDeroulante;
    typeCirconstanceMest: ListeDeroulante;
    typeCompresseur: ListeDeroulante;
    typeConditionExpoChocVibration: ListeDeroulante;
    typeConditionExpoCirculationAir: ListeDeroulante;
    typeConfinement: ListeDeroulante;
    typeEnvironnement: ListeDeroulanteIcones;
    typeEtancheite: ListeDeroulante;
    typeMasque: ListeDeroulante;
    typeOutil: ListeDeroulante;
    typeProtectionExposition: ListeDeroulante;
    typeRemarquePrelevement: ListeDeroulante;
    typeSas: ListeDeroulante;
    typeTechniqueDepose: ListeDeroulante;
    typeTravailHumide: ListeDeroulante;
    usageLocal: ListeDeroulante;
    perimetreInvestigation: PerimetreInvestigation;
    modelIntroduction: ModelIntroduction;
    listeMateriaux: ListeDeroulante;
    methodeTechniqueTraitement: ListeDeroulante;
    niveauEmpoussierement: ListeDeroulante;
    ventilationZone: ListeDeroulanteIcones;
    justificatifLocalisation: ListeDeroulante;
    frequencePrelevement: ListeDeroulante;
    conditionPrelevement: ListeDeroulante;
    sasPersonnel: ListeDeroulante;
    contextesMesure: ListeDeroulanteGenerique;
    contexteZone: ListeDeroulanteIcones;
    typesDelimitation: ListeDeroulanteGenerique;
    activiteAdjacente: ListeDeroulanteIcones;
}

export class ListeDeroulante {
    nom: string = '';
    items: string[];
}

export class ListeDeroulanteGenerique {
    nom: string = '';
    items: any[];
}

export class ListeDeroulanteIcones {
    nom: string = '';
    items: SelectItem[];
}

export class Item {
    id: string = '';
    titre: string = '';
    description: string = '';
}

export class PerimetreInvestigation {
    nom: string = '';
    items: Item[];
}

export class Item2 {
    titre: string = '';
    description: string = '';
}

export class ModelIntroduction {
    nom: string = '';
    items: Item2[];
}
