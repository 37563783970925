import { ConfirmationOptions, ConfirmationService } from 'src/app/commons-lib';
import { EMPTY, of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export function confirm(confirmService: ConfirmationService, messageOrConfig: string | ConfirmationOptions) {
    return of({}).pipe(
        switchMap(() => {
            const confirmSubject = new Subject<boolean>();
            confirmService.confirm(
                messageOrConfig,
                () => confirmSubject.next(true),
                () => confirmSubject.next(false)
            );
            return confirmSubject.asObservable();
        })
    );
}
export function confirmWarn(confirmService: ConfirmationService, messageOrConfig: string | ConfirmationOptions) {
    return of({}).pipe(
        switchMap(() => {
            const confirmSubject = new Subject<boolean>();
            confirmService.confirmWarn(
                messageOrConfig,
                () => confirmSubject.next(true),
                () => confirmSubject.next(false)
            );
            return confirmSubject.asObservable();
        })
    );
}
