<mat-list>
    <div matSubheader *ngIf="data.header">{{ data.header }}</div>
    <ng-container *ngFor="let button of data.buttons">
        <mat-list-item
            mat-ripple
            class="c-pointer"
            [class.primary]="!button.warn"
            [class.warn]="button.warn"
            (click)="button.handler(); bottomSheetRef.dismiss()"
        >
            <mat-icon matListIcon *ngIf="button.icon">{{ button.icon }}</mat-icon>
            <p matLine>{{ button.text }}</p>
        </mat-list-item>
    </ng-container>
</mat-list>
