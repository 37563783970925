<div class="pb-5">
    <div class="block-title p-2 pl-5 my-3 d-inline-block" *ngIf="data">{{ data.title }}</div>

    <div class="px-5 mt-4 d-flex flex-row">
        <div class="block-group w-100 mr-3">
            <div class="mb-4">
                <div class="block-subtitle ml-4">Soumis à la procédure DT-DICT</div>
                <div class="px-5">
                    <div>Cette étude {{ preparation?.soumisDict ? 'a été ' : "n'a pas été" }} soumise à la DICT.</div>
                    <ng-container *ngIf="preparation?.soumisDict">
                        <div class="d-flex mt-1">
                            <div class="group-label">Date de la demande :</div>
                            <div class="group-value">{{ preparation?.dateDemandeDict }}</div>
                        </div>
                        <div class="d-flex mt-1">
                            <div class="group-label">Date de la réponse :</div>
                            <div class="group-value">{{ preparation?.dateReponse }}</div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="mb-4">
                <div class="block-subtitle ml-4">Visite de reconnaissance</div>
                <div class="px-5">
                    <div>
                        {{ preparation?.visiteReconnaissanceAmont ? 'Visite' : 'Pas de visite' }} de reconnaissance à
                        faire.
                    </div>
                    <ng-container *ngIf="preparation?.visiteReconnaissanceAmont">
                        <div class="d-flex mt-1">
                            <div class="group-label">Date de la visite de reconnaissance :</div>
                            <div class="group-value">{{ preparation?.dateVisiteReconnaissance }}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="preparation?.accompagnateursVisiteReconnaissance?.length">
                        <div class="group-label">Accompagnateur(s) :</div>
                        <div class="px-5 mt-4 d-flex flex-wrap">
                            <div
                                class="block-group item-contact mr-3 pb-4"
                                *ngFor="let contact of preparation?.accompagnateursVisiteReconnaissance"
                            >
                                <div class="group-title mb-3">{{ contact?.type }}</div>
                                <div class="d-flex mt-1">
                                    <div class="group-label">Nom :</div>
                                    <div class="group-value">{{ contact?.nom }}</div>
                                </div>
                                <div class="d-flex mt-1">
                                    <div class="group-label">Prénom :</div>
                                    <div class="group-value">{{ contact?.prenom }}</div>
                                </div>
                                <div class="d-flex mt-1">
                                    <div class="group-label">Adresse :</div>
                                    <div class="group-value text-format">{{ contact?.adresse }}</div>
                                </div>
                                <div class="d-flex mt-1">
                                    <div class="group-label">Code postal :</div>
                                    <div class="group-value">{{ contact?.codePostal }}</div>
                                </div>
                                <div class="d-flex mt-1">
                                    <div class="group-label">Ville :</div>
                                    <div class="group-value">{{ contact?.ville }}</div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="mb-4">
                <div class="block-subtitle ml-4">Modalités d'accès et de circulation</div>
                <div class="px-5">
                    <div *ngIf="preparation?.modaliteAcces?.length" class="px-5 ml-2 mb-4 bloc-content text-justify">
                        {{ preparation?.modaliteAcces }}
                    </div>
                </div>
            </div>
            <div class="mb-4">
                <div class="block-subtitle ml-4">Visite de terrain</div>
                <ng-container *ngIf="preparation?.accompagnateursVisiteTerrain?.length">
                    <div class="px-5">
                        <div><b>Accompagnateur(s) :</b></div>
                        <div class="px-5 mt-4 d-flex flex-wrap">
                            <div
                                class="block-group item-contact mr-3 pb-4"
                                *ngFor="let contact of preparation?.accompagnateursVisiteTerrain"
                            >
                                <div class="group-title mb-3">{{ contact?.type }}</div>
                                <div class="d-flex mt-1">
                                    <div class="group-label">Nom :</div>
                                    <div class="group-value">{{ contact?.nom }}</div>
                                </div>
                                <div class="d-flex mt-1">
                                    <div class="group-label">Prénom :</div>
                                    <div class="group-value">{{ contact?.prenom }}</div>
                                </div>
                                <div class="d-flex mt-1">
                                    <div class="group-label">Adresse :</div>
                                    <div class="group-value text-format">{{ contact?.adresse }}</div>
                                </div>
                                <div class="d-flex mt-1">
                                    <div class="group-label">Code postal :</div>
                                    <div class="group-value">{{ contact?.codePostal }}</div>
                                </div>
                                <div class="d-flex mt-1">
                                    <div class="group-label">Ville :</div>
                                    <div class="group-value">{{ contact?.ville }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
