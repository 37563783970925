// Paramètre points de controle mesurage

import {
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE,
} from 'src/app/shared/constants/cndiag.constants';
import { PropertiesMesurage } from '../model/mesurage.model';

/** Mesure n.c. */
export const MESURE_NON_COMMUNIQUE = 'n.c.';

/** Carrez */
export const PARAM_CARREZ = 'carrez';

/** Carrez zones */
export const PARAM_CARREZ_ZONES = 'carrez_zones';

/** Hors-Carrez */
export const PARAM_HORS_CARREZ = 'hors_carrez';

/** Hors-Carrez zones */
export const PARAM_HORS_CARREZ_ZONES = 'hors_carrez_zones';

/** Au sol */
export const PARAM_AU_SOL = 'au_sol';

/** Total Carrez */
export const PARAM_TOTAL_CARREZ = 'total_carrez';

/** Total Carrez zones */
export const PARAM_TOTAL_CARREZ_ZONES = 'carrez_zones_total';

/** Total Hors-Carrez */
export const PARAM_TOTAL_HORS_CARREZ = 'total_hors_carrez';

/** Total Hors-Carrez zones */
export const PARAM_TOTAL_HORS_CARREZ_ZONES = 'hors_carrez_zones_total';

/** Total Au sol */
export const PARAM_TOTAL_AU_SOL = 'total_au_sol';

/** Habitable */
export const PARAM_HABITABLE = 'habitable';

/** Habitable zones */
export const PARAM_HABITABLE_ZONES = 'habitable_zones';

/** Non habitable */
export const PARAM_NON_HABITABLE = 'non_habitable';

/** Non habitable zones */
export const PARAM_NON_HABITABLE_ZONES = 'non_habitable_zones';

/** Total habitable */
export const PARAM_TOTAL_HABITABLE = 'total_habitable';

/** Total habitable zones */
export const PARAM_TOTAL_HABITABLE_ZONES = 'habitable_zones_total';

/** Total non habitable */
export const PARAM_TOTAL_NON_HABITABLE = 'total_non_habitable';

/** Total non habitable zones */
export const PARAM_TOTAL_NON_HABITABLE_ZONES = 'non_habitable_zones_total';

/** Utile brut */
export const PARAM_UTILE_BRUT = 'utile_brut';

/** Utile brut zones */
export const PARAM_UTILE_BRUT_ZONES = 'utile_brut_zones';

/** Utile net */
export const PARAM_UTILE_NET = 'utile_net';

/** Utile net zones */
export const PARAM_UTILE_NET_ZONES = 'utile_net_zones';

/** Autre (utile) */
export const PARAM_AUTRE = 'autre';

/** Autre (utile) zones */
export const PARAM_AUTRE_ZONES = 'autre_zones';

/** Total Utile brut */
export const PARAM_TOTAL_UTILE_BRUT = 'total_utile_brut';

/** Total Utile brut zones */
export const PARAM_TOTAL_UTILE_BRUT_ZONES = 'utile_brut_zones_total';

/** Total Utile net */
export const PARAM_TOTAL_UTILE_NET = 'total_utile_net';

/** Total Utile net zones */
export const PARAM_TOTAL_UTILE_NET_ZONES = 'utile_net_zones_total';

/** Total Autre (utile) */
export const PARAM_TOTAL_AUTRE = 'total_autre';

/** Total Autre (utile) zones */
export const PARAM_TOTAL_AUTRE_ZONES = 'autre_zones_total';

/** Mesures incohérentes */
export const PARAM_MESURES_INCOHERENTES = 'mesures_incoherentes';

/** Type prestation mesurage carrez */
export const PARAM_TYPE_MESURAGE_CARREZ = [PARAM_CARREZ, PARAM_HORS_CARREZ, PARAM_AU_SOL];
export const PARAM_TYPE_MESURAGE_CARREZ_TOTAL = [PARAM_TOTAL_CARREZ, PARAM_TOTAL_HORS_CARREZ, PARAM_TOTAL_AU_SOL];
export const PARAM_TYPE_MESURAGE_CARREZ_ZONES = [PARAM_CARREZ_ZONES, PARAM_HORS_CARREZ_ZONES];
export const PARAM_TYPE_MESURAGE_CARREZ_ZONES_TOTAL = [PARAM_TOTAL_CARREZ_ZONES, PARAM_TOTAL_HORS_CARREZ_ZONES];
export const PROPERTIES_MESURAGE_CARREZ = new PropertiesMesurage(
    ['visite', 'nom', 'carrez', 'hors carrez', 'au sol'],
    ['carrez', 'hors carrez', 'au sol'],
    PARAM_TYPE_MESURAGE_CARREZ,
    PARAM_TYPE_MESURAGE_CARREZ_TOTAL,
    PARAM_TYPE_MESURAGE_CARREZ_ZONES,
    PARAM_TYPE_MESURAGE_CARREZ_ZONES_TOTAL,
    ['loi Carrez', 'hors loi Carrez', 'au sol'],
    ['carrez', 'horsCarrez', 'auSol'],
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ,
    false
);

/** Type prestation mesurage habitable */
export const PARAM_TYPE_MESURAGE_HABITABLE = [PARAM_HABITABLE, PARAM_NON_HABITABLE, PARAM_AU_SOL];
export const PARAM_TYPE_MESURAGE_HABITABLE_TOTAL = [PARAM_TOTAL_HABITABLE, PARAM_TOTAL_NON_HABITABLE, PARAM_TOTAL_AU_SOL];
export const PARAM_TYPE_MESURAGE_HABITABLE_ZONES = [PARAM_HABITABLE_ZONES, PARAM_NON_HABITABLE_ZONES];
export const PARAM_TYPE_MESURAGE_HABITABLE_ZONES_TOTAL = [PARAM_TOTAL_HABITABLE_ZONES, PARAM_TOTAL_NON_HABITABLE_ZONES];
export const PROPERTIES_MESURAGE_HABITABLE = new PropertiesMesurage(
    ['visite', 'nom', 'habitable', 'non habitable', 'au sol'],
    ['habitable', 'non habitable', 'au sol'],
    PARAM_TYPE_MESURAGE_HABITABLE,
    PARAM_TYPE_MESURAGE_HABITABLE_TOTAL,
    PARAM_TYPE_MESURAGE_HABITABLE_ZONES,
    PARAM_TYPE_MESURAGE_HABITABLE_ZONES_TOTAL,
    ['habitable', 'non habitable', 'au sol'],
    ['habitable', 'nonHabitable', 'auSol'],
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE,
    false
);

/** Type prestation mesurage utile */
export const PARAM_TYPE_MESURAGE_UTILE = [PARAM_UTILE_BRUT, PARAM_UTILE_NET, PARAM_AUTRE];
export const PARAM_TYPE_MESURAGE_UTILE_TOTAL = [PARAM_TOTAL_UTILE_BRUT, PARAM_TOTAL_UTILE_NET, PARAM_TOTAL_AUTRE];
export const PARAM_TYPE_MESURAGE_UTILE_ZONES = [PARAM_UTILE_BRUT_ZONES, PARAM_UTILE_NET_ZONES, PARAM_AUTRE_ZONES];
export const PARAM_TYPE_MESURAGE_UTILE_ZONES_TOTAL = [PARAM_TOTAL_UTILE_BRUT_ZONES, PARAM_TOTAL_UTILE_NET_ZONES, PARAM_TOTAL_AUTRE_ZONES];
export const PROPERTIES_MESURAGE_UTILE = new PropertiesMesurage(
    ['visite', 'nom', 'utile brute', 'utile nette', 'autre'],
    ['utile brute', 'utile nette', 'autre'],
    PARAM_TYPE_MESURAGE_UTILE,
    PARAM_TYPE_MESURAGE_UTILE_TOTAL,
    PARAM_TYPE_MESURAGE_UTILE_ZONES,
    PARAM_TYPE_MESURAGE_UTILE_ZONES_TOTAL,
    ['utile brute', 'utile nette', 'autre'],
    ['utileBrut', 'utileNet', 'utileAutre'],
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE,
    true
);

// *** Mapping noms pour rapport ***
export const NAMES_MAPPER = {
    visite: [
        {
            valeur: 'ko',
            afficher: 'Non visité',
        },
        {
            valeur: 'warning',
            afficher: 'Partiellement visité',
        },
        {
            valeur: 'ok',
            afficher: 'Visité',
        },
    ],
};
