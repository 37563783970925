(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("three"));
	else if(typeof define === 'function' && define.amd)
		define(["three"], factory);
	else if(typeof exports === 'object')
		exports["FH3D"] = factory(require("three"));
	else
		root["FH3D"] = factory(root["THREE"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__818__) => {
return 