import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/commons-lib';
import { URL_GESTION_BIENS_EDIT } from 'src/app/shared/constants/url.constants';

@Component({
    selector: 'app-gestion-biens',
    templateUrl: './gestion-biens.component.html',
    styleUrls: ['./gestion-biens.component.scss'],
})
export class GestionBiensComponent extends BaseComponent {
    constructor(private readonly router: Router) {
        super();
    }

    onClickCreerBien() {
        this.router.navigate([URL_GESTION_BIENS_EDIT]);
    }
}
