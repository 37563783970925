import { Component, Input, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

/**
 * Composant pour un spinner centré horizontalement avec sans problème de hauteur minimale.
 * 3 tailles sont disponibles : 'small', 'medium' ou 'large'.
 * La taille du 'strokeWidth' peut être personnalisée (défaut à 3%).
 * La couleur et la classe du spinner peuvent être personnalisés.
 */
@Component({
    selector: 'lib-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
    @Input()
    set color(value: 'primary' | 'accent' | 'warn' | 'grey') {
        if (value == 'grey') {
            this._isGrey = true;
            this._color = null;
        } else {
            this._isGrey = false;
            this._color = value;
        }
    }

    _color: ThemePalette = null;
    _isGrey = false;

    @Input() strokeWidth = 3;

    @Input() size: 'small' | 'medium' | 'large' = 'medium';

    constructor() {}

    ngOnInit() {}
}
