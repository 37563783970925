import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { extractPage, PageRequest, toHttpParams } from '../shared/paging/page';
import { BatchBonCommandeLaboratoire, BonCommandeAnalyseAdmin, BonCommandeAnalyseState } from '../model/bon-commande.model';
import { PrelevementEchantillonageADO } from '../model/prelevement-generique.model';

/**
 * Service d'appel aux APIs pour les bons de commande.
 * NB : On utilise HttpBackend au lieu de HttpClient ici, pour ne pas passer par les intercepteurs (donc on bypasse l'intercepteur).
 *      Le service appelant doit donc gérer lui-même les erreurs HTTP.
 */
@Injectable({
    providedIn: 'root',
})
export class BonCommandeAnalyseApiService {
    private http: HttpClient;

    public resourceUrl = environment.apiUrl + '/bons-de-commande-analyse';

    constructor(private readonly httpBackend: HttpBackend) {
        this.http = new HttpClient(httpBackend);
    }

    /**
     * Récupère la liste des bons de commande avec pagination et tri
     * @param search
     * @param pageRequest
     */
    searchBonsCommandeAnalyse(search: string, pageRequest: PageRequest<BonCommandeAnalyseAdmin>) {
        return extractPage(
            this.http.get<BonCommandeAnalyseAdmin[]>(`${this.resourceUrl}/find-all-pageable`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), search },
            })
        );
    }

    /**
     * Envoie un bon de commande au laboratoire
     * @param bonCommandeAnalyseAdmin
     */
    sendToLaboratoire(bonCommandeAnalyseAdmin: BonCommandeAnalyseAdmin): Observable<BonCommandeAnalyseAdmin> {
        return this.http.put<BonCommandeAnalyseAdmin>(`${this.resourceUrl}/send-to-laboratoire`, bonCommandeAnalyseAdmin);
    }

    /**
     * Récupère un bon de commande
     * @param id
     */
    findOne(id: string): Observable<BonCommandeAnalyseAdmin> {
        return this.http.get<BonCommandeAnalyseAdmin>(`${this.resourceUrl}/${id}`);
    }

    updateBonDeCommandeLaboratoire(bdcToUpdate: BonCommandeAnalyseAdmin): Observable<BonCommandeAnalyseAdmin> {
        return this.http.put<BonCommandeAnalyseAdmin>(`${this.resourceUrl}`, bdcToUpdate);
    }

    updateBatchBonDeCommandeLaboratoire(batchBonsCommande: BatchBonCommandeLaboratoire[]) {
        return this.http.put<BatchBonCommandeLaboratoire[]>(`${this.resourceUrl}/update-batch`, batchBonsCommande);
    }

    getResultatsEchantillons(idAgence: string, idsPrelevements: string[]) {
        return this.http.get<PrelevementEchantillonageADO[]>(`${this.resourceUrl}/find-results`, {
            params: {
                idAgence,
                idsPrelevements,
            },
        });
    }

    searchBonCommande(
        search: string,
        startDate: string,
        endDate: string,
        etats: BonCommandeAnalyseState[],
        idsPrestataire: string[],
        idsAgence: string[],
        idsInterventions: string[],
        idsLaboratoires: string[],
        idsCommandes: string[],
        idsBien: string[],
        pageRequest: PageRequest<BonCommandeAnalyseAdmin>
    ) {
        return extractPage(
            this.http.get<BonCommandeAnalyseAdmin[]>(`${this.resourceUrl}/liste-bons-de-commande-analyse-page`, {
                observe: 'response',
                params: {
                    ...toHttpParams(pageRequest),
                    search,
                    startDate,
                    endDate,
                    etats,
                    idsPrestataire,
                    idsAgence,
                    idsInterventions,
                    idsLaboratoires,
                    idsCommandes,
                    idsBien,
                },
            })
        );
    }
}
