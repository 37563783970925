<div class="forms">
    <input type="hidden" [formControl]="formMpca.get('id')" />
    <div class="forms-row">
        <div class="general">
            <app-mpca-form-general
                [formGeneral]="formMpca.get('general')"
                [diagnostic]="diagnostic"
                [etatDegradationConfig]="polluantConfig.contenuListeDeroulante.etatDegradation"
                [materiauxConfig]="polluantConfig.contenuListeDeroulante.listeMateriaux"
                [typeAmianteConfig]="polluantConfig.contenuListeDeroulante.typeAmiante"
                [disabled]="!isInEditMode"
                (generalDataChange)="onGeneralDataChange()"
            >
            </app-mpca-form-general>
        </div>
        <div>
            <app-mpca-processus-client
                *ngIf="showProcessus"
                [mpca]="this.selectedMpca"
                (add)="addMpcaProcessus($event)"
                (edit)="editMpcaProcessus($event)"
                (delete)="deleteMpcaProcessus($event)"
                (duplicate)="duplicateMpcaProcessus($event)"
                [disabled]="!isInEditMode"
            ></app-mpca-processus-client>
            <app-mpca-form-protection-exposition
                *ngIf="!showProcessus"
                [formProtectionExposition]="formMpca.get('protectionExposition')"
                [typeProtectionExposition]="polluantConfig.contenuListeDeroulante.typeProtectionExposition"
                [typeEtancheiteConfig]="polluantConfig.contenuListeDeroulante.typeEtancheite"
                [typeConditionExpoChocVibration]="polluantConfig.contenuListeDeroulante.typeConditionExpoChocVibration"
                [typeConditionExpoCirculationAir]="
                    polluantConfig.contenuListeDeroulante.typeConditionExpoCirculationAir
                "
                [disabled]="!isInEditMode"
            ></app-mpca-form-protection-exposition>
        </div>
    </div>
    <div class="col-6" style="padding: 0">
        <div>
            <app-mpca-form-protection-exposition
                *ngIf="showProcessus"
                [formProtectionExposition]="formMpca.get('protectionExposition')"
                [typeProtectionExposition]="polluantConfig.contenuListeDeroulante.typeProtectionExposition"
                [typeEtancheiteConfig]="polluantConfig.contenuListeDeroulante.typeEtancheite"
                [typeConditionExpoChocVibration]="polluantConfig.contenuListeDeroulante.typeConditionExpoChocVibration"
                [typeConditionExpoCirculationAir]="
                    polluantConfig.contenuListeDeroulante.typeConditionExpoCirculationAir
                "
                [disabled]="!isInEditMode"
            ></app-mpca-form-protection-exposition>
        </div>
    </div>
</div>
