import { RouterModule, Routes } from '@angular/router';

import { QuitDiagnosticGuard } from '../../../../../guards/quit-diagnostic.guard';
import { QuitDescriptionBienGuard } from '../../../../../guards/quit-description-bien.guard';
import {
    interventionRouteDescriptionIterateFunction,
    interventionRouteDescriptionIterateLabel,
    interventionRouteDescriptionIteratePath,
    interventionRouteDescriptionIterateSuffix,
    zoneRouteIterateFunction,
    zoneRouteIterateIterateLabel,
    zoneRouteIterateIteratePath,
    zoneRouteIterateIterateSuffix,
} from '../../../../interventions/description/description-bien.module';
import { Icon } from 'src/app/commons-lib';
import {
    DESC,
    DESC_BIEN,
    DESC_BIEN_ANNEXE,
    DOCS_DIAG,
    DOCUMENTS_RAPPORT,
    GESTION_PLAN,
    POLL_BESOIN,
    POLL_BESOIN_PERIMETRE,
    POLL_DATE_CONTACTS,
    POLL_ESTIMATION,
    POLL_ESTIMATION_COMPETENCE,
    POLL_ESTIMATION_PREVISITE,
    POLL_INIT_ETUDE,
    POLL_INIT_ETUDE_ACTIVITES,
    POLL_INIT_ETUDE_OBJECTIFS,
    POLL_INTRO,
    POLL_MODALITES,
    POLL_MPCA,
    POLL_MPCA_DESC,
    POLL_PERIMETRE,
    POLL_PERIMETRE_DESCRIPTIF,
    POLL_PERIMETRE_LOCALISATION,
    POLL_PREPARATION,
    POLL_RECAP_ETUDE,
    POLL_VISITE_CHANTIER,
    POLL_ZONE,
    PREVI_RAPPORT,
    RAPPORT,
    REPORTAGE_PHOTO,
    SAVE_RAPPORT,
} from 'src/app/shared/constants/names.step.constants';
import { EditorGuard } from '../../../../../guards/editor.guard';

import { ActivitesComponent } from '../../modules/etude-situation/initialisation/activites/activites.component';

import { ObjectifsComponent } from '../../modules/etude-situation/initialisation/objectifs/objectifs.component';
import { ValidationDocumentaireComponent } from '../../modules/etude-situation/validation-documentaire/validation-documentaire.component';
import { DescriptifComponent } from '../../modules/etude-situation/perimetre/descriptif/descriptif.component';
import { IntroStrategieComponent } from '../../modules/etude-situation/intro-strategie/intro-strategie.component';
import { MpcaDescriptifComponent } from '../../modules/etude-situation/listeMPCA/mpca-descriptif/mpca-descriptif.component';
import { ZoneComponent } from '../../modules/shared/zone/zone/zone.component';
import { BesoinsPrelevementsComponent } from '../../modules/etude-situation/besoins-prelevements/besoins-prelevements.component';
import { PrevisiteComponent } from '../../modules/etude-situation/estimation/previsite/previsite.component';
import { CompetenceComponent } from '../../modules/etude-situation/estimation/competence/competence.component';
import { RecapitulatifComponent } from '../../modules/etude-situation/recapitulatif/recapitulatif.component';
import { NgModule } from '@angular/core';
import { DiagnosticConfigComponent } from 'src/app/modules/diagnostics-shared/diagnostic-config/diagnostic-config.component';
import { PolluantModule } from '../../polluant.module';
import { PrintingComponent } from '../../modules/shared/test-impression/printing.component';
import { RapportComponent } from '../../../../diagnostics-shared/rapport/rapport.component';
import { DocumentsRapportComponent } from '../../../../diagnostics-shared/documents-rapport/documents-rapport.component';
import { ReportagePhotoListComponent } from '../../../../diagnostics-shared/reportage-photo/reportage-photo-list/reportage-photo-list.component';
import { GestionPlanPolluantComponent } from '../../modules/gestion-plan-polluant/gestion-plan-polluant.component';
import { PerimetreMapComponent } from '../../../../shared/map/perimetre-map/perimetre-map.component';
import { Intervention } from 'src/app/model/intervention.model';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { Polluant } from '../../model/polluant.model';
import { PerimetreInvestigation } from 'src/app/model/perimetre-investigation.model';
import { PolluantPerimetreSelectionRedirectionComponent } from '../../modules/etude-situation/besoins-prelevement-redirection/besoins-prelevement-redirection.component';
import { QuitDocumentGuard } from '../../../../../guards/quit-document.guard';
import { DescriptionBienPageComponent } from '../../../../interventions/description/description-bien-page/description-bien-page.component';
import { ModalitesComponent } from '../../modules/etude-situation/estimation/modalites/modalites.component';
import { DateContactsComponent } from '../../modules/etude-situation/estimation/date-et-contacts/date-contacts.component';
import { VisiteChantierComponent } from '../../modules/etude-situation/estimation/visite-chantier/visite-chantier.component';

const DEFAULT_PAGE = 'initialisation/activites';

export function prelevementRoutesByPerimetreIteratePath(perimetre: PerimetreInvestigation): string {
    return perimetre.id;
}

export function prelevementRoutesByPerimetreIterateLabel(perimetre: PerimetreInvestigation): string {
    return perimetre.nom;
}

export function prelevementRoutesByPerimetreIterateSuffix(perimetre: PerimetreInvestigation): string {
    return `_${perimetre.id}`;
}

// Gestion des routes dynamiques des Périmètre d'investigation
export function prelevementRoutesByPerimetresIterateFunction(context: { currentIntervention: Intervention; currentDiagnostic: Diagnostic }) {
    const perimetres = (context.currentDiagnostic.contenuDiagnostic as Polluant).perimetresInvestigation.data.listePerimetreInvestigation || [];

    return perimetres;
}

export let polluantEtudeSituationNavbarRoutes: Routes = [
    {
        path: 'description',
        data: {
            label: 'Description',
            icon: new Icon('floor-plan', 'mdi'),
            code: DESC,
        },
        children: [
            {
                path: '',
                redirectTo: 'bien-principal',
                pathMatch: 'full',
            },
            {
                path: 'bien-principal',
                data: {
                    label: 'Bien principal',
                    code: DESC_BIEN,
                },
                canDeactivate: [QuitDescriptionBienGuard],
                component: DescriptionBienPageComponent,
            },
            {
                path: ':idBien',
                data: {
                    iterate: {
                        function: interventionRouteDescriptionIterateFunction,
                        path: interventionRouteDescriptionIteratePath,
                        label: interventionRouteDescriptionIterateLabel,
                        suffix: interventionRouteDescriptionIterateSuffix,
                    },
                    code: DESC_BIEN_ANNEXE,
                },
                canDeactivate: [QuitDescriptionBienGuard],
                component: DescriptionBienPageComponent,
            },
            {
                path: 'bien-principal/editor',
                canActivate: [EditorGuard],
                loadChildren: () => import('../../../../shared/map/editor-map/editor-map.module').then((m) => m.EditorMapModule),
            },
            {
                path: ':idBien/editor',
                canActivate: [EditorGuard],
                loadChildren: () => import('../../../../shared/map/editor-map/editor-map.module').then((m) => m.EditorMapModule),
            },
        ],
    },
    {
        path: 'initialisation',
        canDeactivate: [QuitDiagnosticGuard],
        data: {
            label: 'Initialisation',
            icon: new Icon('settings'),
            code: POLL_INIT_ETUDE,
        },
        children: [
            {
                path: '',
                canDeactivate: [QuitDiagnosticGuard],
                redirectTo: 'activites',
                pathMatch: 'full',
            },
            {
                path: 'activites',
                data: {
                    label: 'Activités Amiante',
                    code: POLL_INIT_ETUDE_ACTIVITES,
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: ActivitesComponent,
            },
            {
                path: 'objectifs',
                data: {
                    label: 'Objectifs de mesurage',
                    code: POLL_INIT_ETUDE_OBJECTIFS,
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: ObjectifsComponent,
            },
        ],
    },
    {
        path: 'validationDoc',
        data: {
            label: 'Validation doc',
            icon: new Icon('list-status', 'mdi'),
            code: DOCS_DIAG,
        },
        canDeactivate: [QuitDocumentGuard],
        component: ValidationDocumentaireComponent,
    },
    {
        path: 'introduction',
        data: {
            label: 'Introduction',
            icon: new Icon('monitor-edit', 'mdi'),
            code: POLL_INTRO,
        },
        canDeactivate: [QuitDiagnosticGuard],
        component: IntroStrategieComponent,
    },
    {
        path: 'perimetre',
        data: {
            label: 'Périmètre',
            icon: new Icon('map-search', 'mdi'),
            code: POLL_PERIMETRE,
        },
        children: [
            {
                path: '',
                canDeactivate: [QuitDiagnosticGuard],
                redirectTo: 'descriptif',
                pathMatch: 'full',
            },
            {
                path: 'descriptif',
                data: {
                    label: 'Descriptif',
                    code: POLL_PERIMETRE_DESCRIPTIF,
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: DescriptifComponent,
            },
            {
                path: 'localisation',
                data: {
                    label: 'Plan de localisation',
                    code: POLL_PERIMETRE_LOCALISATION,
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: PerimetreMapComponent,
            },
        ],
    },
    {
        path: 'listeMPCA',
        data: {
            label: 'Liste MPCA',
            icon: new Icon('warning'),
            code: POLL_MPCA,
        },
        children: [
            {
                path: '',
                canDeactivate: [QuitDiagnosticGuard],
                redirectTo: 'descriptif',
                pathMatch: 'full',
            },
            {
                path: 'descriptif',
                data: {
                    label: 'Descriptif',
                    code: POLL_MPCA_DESC,
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: MpcaDescriptifComponent,
            },
        ],
    },
    {
        path: 'zone',
        data: {
            label: 'Zone',
            icon: new Icon('map-marker-path', 'mdi'),
            code: POLL_ZONE,
        },
        canDeactivate: [QuitDiagnosticGuard, QuitDocumentGuard],
        children: [
            {
                path: '',
                component: ZoneComponent,
            },
            {
                path: ':idPerimetreInvestigation',
                component: ZoneComponent,
                data: {
                    iterate: {
                        function: zoneRouteIterateFunction,
                        path: zoneRouteIterateIteratePath,
                        label: zoneRouteIterateIterateLabel,
                        suffix: zoneRouteIterateIterateSuffix,
                    },
                    code: POLL_ZONE,
                },
            },
        ],
    },
    {
        path: 'besoin',
        data: {
            label: 'Besoin',
            icon: new Icon('eyedropper', 'mdi'),
            code: POLL_BESOIN,
        },
        children: [
            {
                path: '',
                redirectTo: 'besoin-redirect',
                pathMatch: 'full',
            },
            {
                path: 'besoin-redirect',
                component: PolluantPerimetreSelectionRedirectionComponent,
            },
            {
                path: ':idPerimetre',
                data: {
                    iterate: {
                        function: prelevementRoutesByPerimetresIterateFunction,
                        path: prelevementRoutesByPerimetreIteratePath,
                        label: prelevementRoutesByPerimetreIterateLabel,
                        suffix: prelevementRoutesByPerimetreIterateSuffix,
                    },
                    code: POLL_BESOIN_PERIMETRE,
                },
                component: BesoinsPrelevementsComponent,
            },
        ],
        canDeactivate: [QuitDiagnosticGuard],
    },
    {
        path: 'preparation',
        data: {
            label: 'Préparation',
            icon: new Icon('head-question', 'mdi'),
            code: POLL_PREPARATION,
        },
        children: [
            {
                path: '',
                canDeactivate: [QuitDiagnosticGuard],
                redirectTo: 'modalites',
                pathMatch: 'full',
            },
            {
                path: 'modalites',
                data: {
                    label: "Modalités d'accès et de circulation",
                    code: POLL_MODALITES,
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: ModalitesComponent,
            },
            {
                path: 'dateEtContacts',
                data: {
                    label: 'Date et contacts pour intervention',
                    code: POLL_DATE_CONTACTS,
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: DateContactsComponent,
            },
            {
                path: 'competence',
                data: {
                    label: 'Compétence nécessaire',
                    code: POLL_ESTIMATION_COMPETENCE,
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: CompetenceComponent,
            },
            {
                path: 'estimatif',
                data: {
                    label: 'Estimatif',
                    code: POLL_ESTIMATION,
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: PrevisiteComponent,
            },
            {
                path: 'visiteChantier',
                data: {
                    label: 'Visite chantier',
                    code: POLL_VISITE_CHANTIER,
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: VisiteChantierComponent,
            },
        ],
    },
    {
        path: 'recapitulatif',
        data: {
            label: 'Récapitulatif',
            icon: new Icon('eye', 'fa'),
            code: POLL_RECAP_ETUDE,
        },
        canDeactivate: [QuitDiagnosticGuard],
        component: RecapitulatifComponent,
    },
    {
        path: 'rapport',
        data: {
            label: 'Rapport',
            icon: new Icon('book', 'fa'),
            code: RAPPORT,
        },
        children: [
            {
                path: '',
                canDeactivate: [QuitDiagnosticGuard],
                redirectTo: 'gestion-plan',
                pathMatch: 'full',
            },
            {
                path: 'gestion-plan',
                data: {
                    label: 'Gestion plan',
                    code: GESTION_PLAN,
                    status: 'VOID',
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: GestionPlanPolluantComponent,
            },
            {
                path: 'reportage-photo',
                data: {
                    label: 'Reportage photo',
                    code: REPORTAGE_PHOTO,
                },
                canDeactivate: [QuitDiagnosticGuard],
                children: [
                    {
                        path: '',
                        component: ReportagePhotoListComponent,
                    },
                ],
            },
            {
                path: 'documents-rapport',
                data: {
                    label: 'Documents',
                    code: DOCUMENTS_RAPPORT,
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: DocumentsRapportComponent,
            },
            {
                path: 'apercu-rapport',
                data: {
                    label: 'Aperçu rapport',
                    code: PREVI_RAPPORT,
                    actions: [
                        {
                            label: 'Valider',
                            icon: new Icon('signature-freehand', 'mdi'),
                            code: SAVE_RAPPORT,
                            textColor: '#FFFFFF',
                            backgroundColor: '#6DC911',
                        },
                    ],
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: RapportComponent,
            },
        ],
    },
];

const routes: Routes = [
    {
        path: '',
        redirectTo: 'config',
        pathMatch: 'full',
    },
    {
        path: 'config',
        component: DiagnosticConfigComponent,
        children: [
            {
                path: '',
                redirectTo: DEFAULT_PAGE,
                pathMatch: 'full',
            },
            ...polluantEtudeSituationNavbarRoutes,
        ],
    },
    {
        path: 'print',
        component: PrintingComponent,
    },
];

/**
 * Routes du module à utiliser pour les barres de navigation.
 * L'attribut "data" doit implémenter l'interface {@link NavigationLinkRouteData}
 */
@NgModule({
    imports: [PolluantModule, RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PolluantEtudeSituationRoutingModule {}
