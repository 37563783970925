<h1 mat-dialog-title class="primary d-flex justify-content-between mb-0">
    <div><!--zone vide--></div>
    <div>{{ currentNiveau.nom }} - {{ currentVolume.nom }}</div>
    <div class="d-flex flex-grow-0 justify-content-between align-items-center">
        <div class="buttons-container">
            <button
                mat-icon-button
                matTooltip="Pièce précédente"
                [disabled]="currentIndexNiveau === 0 && currentIndexVolume === 0"
                (click)="handlePiecePrecedente()"
                class="bt-navigate bt-navigate-before d-flex justify-content-center"
            >
                <mat-icon>chevron_left</mat-icon>
            </button>
            <button
                mat-icon-button
                matTooltip="Pièce suivante"
                class="bt-navigate bt-navigate-next d-flex justify-content-center"
                (click)="handlePieceSuivante()"
                [disabled]="
                    currentIndexNiveau === bien?.description.length - 1 &&
                    currentIndexVolume === bien?.description[currentIndexNiveau].volumes.length - 1
                "
            >
                <mat-icon>chevron_right</mat-icon>
            </button>
        </div>
        <div class="close">
            <button mat-icon-button class="d-flex justify-content-center" (click)="close()">
                <lib-icon>close</lib-icon>
            </button>
        </div>
    </div>
</h1>

<div class="dialog-container h-100 d-flex flex-column">
    <div class="d-flex flex-grow-0 justify-content-between align-items-center w-100 pr-2">
        <mat-tab-group [(selectedIndex)]="currentTabIndex">
            <mat-tab label="Ouvrages"></mat-tab>
            <mat-tab label="Equipements"></mat-tab>
        </mat-tab-group>
    </div>
    <app-description-bien-modal-ouvrages
        *ngIf="currentTabIndex === tabsIndexes.OUVRAGES"
        [readonlyMode]="readonlyMode"
        [bien]="bien"
        [currentVolume]="currentVolume"
        [categorieKeyToOpen]="categorieKeyToOpen"
        class="d-flex row no-gutters m-0 flex-grow-1 overflow-hidden"
    >
    </app-description-bien-modal-ouvrages>
    <app-description-bien-modal-equipements
        *ngIf="currentTabIndex === tabsIndexes.EQUIPEMENTS"
        [readonlyMode]="readonlyMode"
        [currentVolume]="currentVolume"
        [(selectedRefPrestations)]="selectedRefPrestations"
        class="d-flex row no-gutters m-0 flex-grow-1 overflow-hidden"
    >
    </app-description-bien-modal-equipements>
</div>
