import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionSheetComponent } from './action-sheet.component';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';

@NgModule({
    imports: [CommonModule, MatListModule, MatIconModule, MatRippleModule],
    declarations: [ActionSheetComponent],
    exports: [ActionSheetComponent],
})
export class LibActionSheetModule {}
