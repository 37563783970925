import { AnnexeData, ContactData, DocumentData, IDiagReportData, ReportagePhotoData } from '../../../../model/rapport.model';
import { TypePrestation } from '../../../../model/type-prestation.model';
import { ScreenshotSvg } from '../../../../model/screenshot-svg.model';
import { EtatDiagnostic } from 'src/app/model/diagnostic.model';

export class PrelevementGeneralData {
    reference?: string;
    gpsLatitude?: number;
    gpsLongitude?: number;
    description?: string;
}

export class PrelevementDetailsData {
    typePrelevement?: string;
    produitLimitationFibre?: string;
    typeCarottage?: string;
    profondeurCarottage?: number;
    /**
     * id et url du fichier contenant la photo de situation du prélèvement
     */
    idFichierImageSituation?: string;
    fichierImageSituationUrl?: string;
    /**
     * id et url du fichier contenant la photo d'échelle' du prélèvement
     */
    idFichierImageEchelle?: string;
    fichierImageEchelleUrl?: string;
}

export class PrelevementEchantillonageData {
    reference?: string;
    hauteur?: number;
    materiaux?: string[] = [];
    presenceAmiante?: string;
    methodeAnalyse?: string;
    resultatAnalyse?: string;
    unite?: string;
    color?: string;
    score?: string;
}

export class PrelevementDonneesTechniquesData {
    lave: boolean;
    couchesADissocier: boolean;
    analyseCouches: boolean;
    limitationFibre: boolean;
    pollutionSurfacique: boolean;
    analyseAmiante: boolean;
}

export class PrelevementData {
    nom?: string;
    general: PrelevementGeneralData;
    details: PrelevementDetailsData;
    echantillonages: PrelevementEchantillonageData[];
    donneesTechniques: PrelevementDonneesTechniquesData;
    isRealise: boolean;
}

export class ZoneData {
    nom: string;
    description: string;
    partieOuvrage: string;
    materiaux: string[] = [];
    idFichierImageSituation: string;
    fichierImageSituationUrl: string;
    listePrelevements: PrelevementData[] = [];
}

export class PerimetreData {
    nom: string;
    description: string;
    typeOuvrage?: string;
    listeZones: ZoneData[] = [];
    listeBesoins: PrelevementData[] = [];
}

export class EspaceData {
    nom: string;
    listePerimetres: PerimetreData[] = [];
}

export class PreparationData {
    soumisDict: boolean;
    dateDemandeDict: string;
    dateReponse: string;
    visiteReconnaissanceAmont: boolean;
    dateVisiteReconnaissance: string;
    accompagnateursVisiteReconnaissance: ContactData[] = [];
    modaliteAcces: string;
    accompagnateursVisiteTerrain: ContactData[] = [];
}

/**
 * Contient l'ensemble des données de rapport HAP
 */
export class HapReportData implements IDiagReportData {
    conformiteGlobale: boolean;
    id: string;
    typePrestation: TypePrestation;
    refRapport: string;
    objectif: string;
    listeEspaces: EspaceData[];
    listeEspacesNonRealises: EspaceData[];
    preparation: PreparationData;
    recommandations: string[] = [];
    constatationsDiverses: string[] = [];
    documentsData: DocumentData[];
    annexes: AnnexeData[];
    reportagesPhotos: ReportagePhotoData[];
    screenshotsPlan: ScreenshotSvg[];
    listDocuments: DocumentData[];

    /**
     * Etat du diagnostic
     *
     * @title Etat du diagnostic
     * @description L'état du diagnostic : EN_ATTENTE, TERMINE, FINI; Si tous les diagnostics sont fini ou annulé, l'intervention passe à terminé, et tous les diagostics passent à terminé.
     */
    etat: EtatDiagnostic;
}
