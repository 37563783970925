export enum TypeTrait {
    POINTILLE = 'POINTILLE',
    PLEIN = 'PLEIN',
}

/**
 * À partir d'un type de trait, renvoie le label associé.
 */
export function typeTraitToLabel(typeTrait: string | TypeTrait): string {
    switch (typeTrait) {
        case 'POINTILLE':
            return 'Pointillé';
        case 'PLEIN':
            return 'Plein';
        // À COMPLÉTER AVEC LES DIFFÉRENTS TYPES DE TRAIT...
        default:
            return typeTrait;
    }
}

export class Legende {
    typeTrait: TypeTrait;
    epaisseurTrait: string = '2';
    color = '#ff6c00';
}
