import { Pipe, PipeTransform } from '@angular/core';
import { ROLE_ADMIN, ROLE_USER } from '../authentication/user.model';

@Pipe({ name: 'translateAuthority' })
export class TranslateAuthorityPipe implements PipeTransform {
    constructor() {}

    transform(authority: string): string {
        switch (authority) {
            case ROLE_ADMIN:
                return 'SUPERADMIN';
            case ROLE_USER:
                return 'USER';
            default:
                return authority.replace('ROLE_', '').replace('_', ' ');
        }
    }
}
