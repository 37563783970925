<mat-card>
    <div class="input-with-prefix">
        <mat-form-field appearance="outline" class="w-50">
            <input
                matInput
                type="text"
                (keyup)="onKeyupSearchVolumes($event)"
                [(ngModel)]="searchValue"
                placeholder="Recherche libre"
            />
            <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
    </div>
    <div class="d-flex flex-column">
        <table
            mat-table
            [dataSource]="dataSource"
            matSort
            (matSortChange)="onChangeSortVolumes($event)"
            [matSortActive]="sort?.active"
            [matSortDirection]="sort?.direction"
            matSortDisableClear="true"
        >
            <ng-container matColumnDef="nom">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
                <td mat-cell *matCellDef="let row">{{ row.nom }}</td>
            </ng-container>

            <ng-container matColumnDef="typesElementsAControlerToString">
                <th mat-header-cell *matHeaderCellDef>Équipements inclus</th>
                <td mat-cell *matCellDef="let row" style="white-space: pre-line">
                    {{ row.typesElementsAControlerToString }}
                </td>
            </ng-container>

            <ng-container matColumnDef="typesBienAssocies">
                <th mat-header-cell *matHeaderCellDef>Type bien associés</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.typesBienAssocies | joinList }}
                </td>
            </ng-container>

            <ng-container matColumnDef="ordre">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Ordre</th>
                <td mat-cell *matCellDef="let row" class="mr-2">{{ row.ordre }}</td>
            </ng-container>

            <ng-container matColumnDef="etatVolume">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>État</th>
                <td mat-cell *matCellDef="let row">
                    <span class="statut-workflow" [ngClass]="row.etatVolume">{{ row.etatVolume | etatWorkflow }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                    <button mat-icon-button (click)="onClickConsulter(row)">
                        <mat-icon>visibility</mat-icon>
                    </button>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="onClickDupliquerVolume(row)">
                            <mat-icon>content_copy</mat-icon>
                            <span>Dupliquer</span>
                        </button>
                        <button
                            mat-menu-item
                            [disabled]="row.etatVolume !== 'INACTIF'"
                            (click)="onClickEditerVolume(row)"
                        >
                            <mat-icon>edit</mat-icon>
                            <span>Éditer</span>
                        </button>
                        <button mat-menu-item (click)="onClickChangerEtatVolume(row)">
                            <ng-container *ngIf="row.etatVolume === 'INACTIF'">
                                <mat-icon>lock_open</mat-icon>
                                <span>Activer</span>
                            </ng-container>
                            <ng-container *ngIf="row.etatVolume === 'ACTIF'">
                                <mat-icon>lock</mat-icon>
                                <span>Désactiver</span>
                            </ng-container>
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator
            #paginator
            *ngIf="dataSource.page$ | async as page"
            [length]="page.totalElements"
            [pageSize]="page.size"
            [pageIndex]="page.number"
            [pageSizeOptions]="[initialPageSize, 50, 100]"
            (page)="dataSource.fetch($event.pageIndex, paginator.pageSize)"
        ></mat-paginator>
    </div>
</mat-card>
