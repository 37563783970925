'use strict';

import { cn_extension_feature } from './cn_extension_feature';
import { cn_zoning_type } from './cn_zoning_type';

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** zone extension model
//***********************************************************************************
//***********************************************************************************


export class cn_zone_extension extends cn_extension_feature {

    /**
     * Tool zones extension
     * Ajoute d'autres types de zones que les zones par défaut (usage) :
     * format { property: <string de la propriété dans le building>, label: <string de label IHM>, icon: <string de ref de l'icone mdi> }
     *
     */
    constructor() {
        super(false);

        /**
         * Types de zones de base
         * @type {cn_zoning_type[]}
         */
        this.zones_tools_base = [
            new cn_zoning_type('lot', 'Lot', 'Lots', 'Lots', 'land-plots-circle-variant', true),
            new cn_zoning_type('thermal', 'Zone thermique', 'Zones thermiques', 'Thermiques', 'thermometer'),
            new cn_zoning_type('usage', 'Zone d\'usage', `Zones d'usage`, 'Usages', 'view-dashboard-outline'),
        ];

        /**
         * Types de zones supplémentaires
         * @type {cn_zoning_type[]}
         */
        this.zones_tools_addition = [];
    }

    /**
     * Returns zoning used by default :
     * - when instanciating zoning tool without defining zone type
     * - on building elements nomenclatures
     * - on space numerotation
     * - in BBP & IFC export
     *
     * Shall never return null.
     *
     * @return {cn_zoning_type}
     */
    get_default_zone_tool() {
        return this.zones_tools_base[0];
    }

    /**
     * @return {cn_zoning_type[]}
     */
    get_zones_tools() {
        return [
            ...this.zones_tools_base,
            ...this.zones_tools_addition,
        ];
    }

}


