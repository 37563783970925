<h1 mat-dialog-title>{{ data.titleLabel || 'Confirmation' }}</h1>

<div mat-dialog-content [innerHTML]="data.message"></div>

<div *ngIf="data.messageCheckboxConfirm" class="my-2">
    <mat-checkbox [(ngModel)]="enableConfirm">
        <span>{{ data.messageCheckboxConfirm }}</span>
    </mat-checkbox>
</div>

<div mat-dialog-actions>
    <button
        mat-raised-button
        [color]="data.warn ? 'warn' : 'primary'"
        cdkFocusInitial
        [disabled]="!enableConfirm"
        (click)="onConfirm()"
    >
        <mat-icon>check</mat-icon>
        <span>{{ data.confirmLabel || 'Confirmer' }}</span>
    </button>

    <button mat-button (click)="onDismiss()">
        <mat-icon>close</mat-icon>
        <span>{{ data.cancelLabel || 'Annuler' }}</span>
    </button>
</div>
