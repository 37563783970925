<div class="pb-5">
    <div class="block-title p-2 pl-5 my-3 d-inline-block" *ngIf="data">{{ data.title }}</div>

    <!--------------------- Bon de commande HAP --------------------->
    <div class="px-5 mt-4 d-flex flex-row">
        <div class="block-group mr-3 w-100">
            <div class="group-title mb-3">Bon de commande HAP</div>
            <div class="d-flex mt-1">
                <div class="group-label">Num du dossier :</div>
                <div class="group-value">{{ interReportData.dossierNumber }}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">Agence :</div>
                <div class="group-value">{{ interReportData.societe.nom }}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">Adresse :</div>
                <div class="group-value text-format">{{ interReportData.societe.adresse }}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">Code postal :</div>
                <div class="group-value">{{ interReportData.societe.codePostal }}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">Ville :</div>
                <div class="group-value">{{ interReportData.societe.ville }}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="group-label">Affaire / Dossier :</div>
                <div class="group-value">{{ 'n.c' }}</div>
            </div>
        </div>
    </div>
</div>
