'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** Pipe
//***********************************************************************************
//***********************************************************************************

import { cn_element } from './cn_element';
import { cn_box, cn_dist, cn_dot, cn_mul, cn_normal, cn_normalize, cn_sub } from '../utils/cn_utilities';
import { fh_clone, fh_cross, fh_extruded_polygon, fh_matrix, fh_normalize, fh_solid, fh_sub } from '@acenv/fh-3d-viewer';
import { cn_element_visitor } from '../utils/visitors/cn_element_visitor';
import { logger } from '../utils/cn_logger';

/**
 * @class cn_pipe
 */
export class cn_pipe extends cn_element {
    constructor(element_type) {
        super();

        //*** Model data
        this.vertices = [[0, 0, 0.1], [1, 0, 0.1]];
        this.element_type = element_type;
    }

    //***********************************************************************************
    //**** serialize
    //***********************************************************************************
    serialize() {
        var json = {};
        json.ID = this.ID;
        json.vertices = [fh_clone(this.vertices[0]), fh_clone(this.vertices[1])];
        json.element_type = this.element_type.ID;
        return json;
    }

    static unserialize(json, scene) {
        if (typeof (json.ID) != 'string') return false;
        if (typeof (json.vertices) != 'object') return false;
        if (typeof (json.element_type) != 'string') return false;

        var et = null;
        if (typeof (json.element_type) == 'string')
            et = scene.building.get_element_type(json.element_type);
        if (et == null) {
            logger.log('use default pipe type');
            et = scene.building.get_pipe_types()[0];
        }

        var pipe = new cn_pipe(et);
        pipe.ID = json.ID;
        pipe.vertices[0] = fh_clone(json.vertices[0]);
        pipe.vertices[1] = fh_clone(json.vertices[1]);
        scene.pipes.push(pipe);
    }

    /**
     * Returns true if pipe is vertical or almost
     * @returns {boolean}
     */
    is_vertical() {
        return cn_dist(this.vertices[0], this.vertices[1]) < 0.1;
    }

    //***********************************************************************************
    //**** Draw
    //***********************************************************************************
    draw(camera, add_classes = [], fill_pipe = '', fill_section = '') {
        var html = '';
        if (this.status < 0) return html;

        var mouseover = (add_classes.indexOf('mouseover') >= 0);
        var selected = (add_classes.indexOf('selected') >= 0);

        var p0 = camera.world_to_screen(this.vertices[0]);
        var p1 = camera.world_to_screen(this.vertices[1]);
        var th = 0.5 * camera.world_to_screen_scale * this.element_type.diameter;
        var normal = cn_normal(cn_sub(p1, p0));
        cn_normalize(normal);
        normal = cn_mul(normal, th);
        const neutral_pipe_css_class = fill_pipe ? 'pipe_neutral' : '';
        if (this.is_vertical()) {
            html += `<circle class="pipe_section_${this.element_type.material} pipe_fluid_${this.element_type.fluid} ${add_classes.join(' ')} ${neutral_pipe_css_class}" ${fill_pipe} `;
            html += `cx="${p0[0]}" cy="${p0[1]}" r="${(th < 10) ? 10 : th}" `;
        } else {
            html += `<circle class="pipe_section pipe_section_${this.element_type.material} pipe_fluid_${this.element_type.fluid} ${add_classes.join(' ')} ${neutral_pipe_css_class}" cx="${p0[0]}" cy="${p0[1]}" r="10" ${fill_pipe} />`;
            html += `<circle class="pipe_section pipe_section_${this.element_type.material} pipe_fluid_${this.element_type.fluid} ${add_classes.join(' ')} ${neutral_pipe_css_class}" cx="${p1[0]}" cy="${p1[1]}" r="10" ${fill_pipe} />`;
            html += `<path class="pipe_path pipe_${this.element_type.material} ${add_classes.join(' ')}" ${fill_section} d='`;
            html += 'M ' + (p0[0] - normal[0]) + ' ' + (p0[1] - normal[1]) + ' ';
            html += 'L ' + (p0[0] + normal[0]) + ' ' + (p0[1] + normal[1]) + ' ';
            html += 'L ' + (p1[0] + normal[0]) + ' ' + (p1[1] + normal[1]) + ' ';
            html += 'L ' + (p1[0] - normal[0]) + ' ' + (p1[1] - normal[1]) + ' ';
            html += 'Z\' ';
        }

        if (mouseover)
            html += 'filter=\'url(#mouseover_shadow)\'';
        else if (selected)
            html += 'filter=\'url(#selection_shadow)\'';

        html += '/>';

        //*** draw mouseover or selection extra */
        if (mouseover || selected) {
            const extra = (mouseover) ? 'mouseover' : 'selected';
            if (this.is_vertical())
                html += `<circle class="handle_vertex ${extra}" cx="${p0[0]}" cy="${p0[1]}" r="5" />`;
            else
                html += `<line class="handle_line ${extra}" x1="${p0[0]}" y1="${p0[1]}" x2="${p1[0]}" y2="${p1[1]}" />`;
        }

        return html;
    }

    //***********************************************************************************
    //**** Contains
    //***********************************************************************************
    contains(point, tolerance = 0) {
        if (this.is_vertical()) {
            return cn_dist(this.vertices[0], point) < 0.5 * this.element_type.diameter * 0.5 + tolerance;
        }
        var d = cn_sub(point, this.vertices[0]);
        var dir = cn_sub(this.vertices[1], this.vertices[0]);
        var l = cn_normalize(dir);
        var x = cn_dot(d, dir);
        if (x < -tolerance) return false;
        if (x > l + tolerance) return false;
        var y = cn_dot(d, cn_normal(dir));
        return (Math.abs(y) < 0.5 * this.element_type.diameter * 0.5 + tolerance);
    }

    //***********************************************************************************
    //**** Build 3D solid
    //***********************************************************************************
    /**
     * Builds an extruded polygon
     * @param {number} h : height of the floor
     * @returns {fh_solid}
     */
    build_solid(h) {
        var v0 = fh_clone(this.vertices[0]);
        var v1 = fh_clone(this.vertices[1]);
        v0[2] += h;
        v1[2] += h;
        var dz = fh_sub(v1, v0);
        var length = fh_normalize(dz);
        var dx = (this.is_vertical()) ? fh_cross(dz, [1, 0, 0]) : fh_cross(dz, [0, 0, 1]);
        fh_normalize(dx);
        var dy = fh_cross(dz, dx);
        fh_normalize(dy);

        var ori = fh_clone(v0);

        var solid = this.element_type.build_solid(length);
        var matrix = new fh_matrix();
        matrix.load_axis(ori, dx, dy, dz);
        solid.apply_matrix(matrix);
        return solid;
    }

    /**
     * Builds an extruded polygon
     * @param {number} h : height of the floor
     * @returns {fh_extruded_polygon}
     */
    build_extruded_polygon(h) {
        var vertices = [fh_clone(this.vertices[0]), fh_clone(this.vertices[1])];

        var dz = fh_sub(vertices[1], vertices[0]);
        var length = fh_normalize(dz);
        var dx = (this.is_vertical()) ? fh_cross(dz, [1, 0, 0]) : fh_cross(dz, [0, 0, 1]);
        fh_normalize(dx);
        var dy = fh_cross(dz, dx);
        fh_normalize(dy);

        var ori = fh_clone(vertices[0]);

        var extruded_polygon = fh_extruded_polygon.build_extrusion(this.element_type.build_footprint(), [0, 0, length], this.element_type.get_color());

        var matrix = new fh_matrix();
        matrix.load_axis(ori, dx, dy, dz);
        extruded_polygon.matrix = matrix;
        return extruded_polygon;
    }

    //***********************************************************************************
    //**** Vertex operation
    //***********************************************************************************
    /**
     * Performe a vertex operation
     * @param {function} operation : the function that transforms vertices
     */
    vertex_operation(operation) {
        operation(this.vertices[0]);
        operation(this.vertices[1]);
    }

    //***********************************************************************************
    /**
     * Returns bounding box
     * @returns {cn_box}
     */
    get_bounding_box() {
        var box = new cn_box();
        box.enlarge_point(this.vertices[0]);
        box.enlarge_point(this.vertices[1]);
        return box;
    }

    /**
     * Accept element visitor
     *
     * @param {cn_element_visitor} element_visitor
     */
    accept_visitor(element_visitor) {
        element_visitor.visit_pipe(this);
    }

}

