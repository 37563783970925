import { Component, Inject, Input, OnInit } from '@angular/core';
import { InterventionService } from '../../../services/intervention.service';
import { Document } from '../../../model/document.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileData } from '../../../shared/offline/offline-storage.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../lib/utils/base.component';

interface LoadFileFromDocsModalComponentData {}

@Component({
    selector: 'app-load-file-from-docs-modal',
    templateUrl: './load-file-from-docs-modal.component.html',
    styleUrls: ['./load-file-from-docs-modal.component.scss'],
})
export class LoadFileFromDocsModalComponent extends BaseComponent implements OnInit {
    docList: Document[] = [];
    loadedFile = false;
    documentSelected: Document = null;

    constructor(
        private interventionService: InterventionService,
        public dialogRef: MatDialogRef<LoadFileFromDocsModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: LoadFileFromDocsModalComponentData
    ) {
        super();
    }

    ngOnInit(): void {
        this.interventionService
            .getCurrentIntervention()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((intervention) => {
                this.docList = intervention.documents.filter((it) => it.typeDocument?.code === 'PLAN_BIEN');
                this.loadedFile = true;
            });
    }

    toggleFile(doc: Document) {
        if (this.documentSelected !== doc) {
            this.documentSelected = doc;
        } else {
            this.documentSelected = null;
        }
    }

    onClickCancel() {
        this.dialogRef.close();
    }

    onClickValidate() {
        this.dialogRef.close({
            idFile: this.documentSelected?.idFichier,
        });
    }
}
