import { Pipe, PipeTransform } from '@angular/core';
import { Intervention } from '../model/intervention.model';
import { Document } from '../model/document.model';
import { Diagnostic, EtatDiagnostic } from '../model/diagnostic.model';

/**
 * Affiche le nom d'une catégorie de document via son id
 */
@Pipe({
    name: 'isDocumentReadonly',
})
export class DocumentReadonlyPipe implements PipeTransform {
    constructor() {}

    transform(document: Document, intervention: Intervention, diagnostic: Diagnostic): boolean {
        const prestaClosedEtat = [EtatDiagnostic.TERMINE, EtatDiagnostic.FINI, EtatDiagnostic.EN_ATTENTE, EtatDiagnostic.ANNULE];
        return intervention.prestationsDiagnostics.some(
            (presta) => document.typePrestations.includes(presta.prestation.typePrestation) && prestaClosedEtat.includes(presta.etatDiagnostic)
        );
    }
}
