import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-header-editor-panel',
    templateUrl: './header-editor-panel.component.html',
    styleUrls: ['./header-editor-panel.component.scss'],
})
export class HeaderEditorPanelComponent {
    @Input() title = '';
    @Input() showBorder = true;

    @Output() closed = new EventEmitter<void>();

    onClickBtnClose(): void {
        this.closed.emit();
    }
}
