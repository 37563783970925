<div class="fullscreen-content">
    <div class="header">
        <div *ngIf="!isPrincipal" class="title">Ajouter un bien annexe</div>
        <div *ngIf="isPrincipal" class="title">Ajouter un bien principal</div>
        <button mat-button class="close-button" (click)="cancel()">
            <lib-icon>close</lib-icon>
        </button>
    </div>
    <div class="content scrollable-content">
        <app-edit-bien
            [intervention]="intervention"
            [bien]="bien"
            [formInfo]="formInfo"
            [isEditMode]="isEditMode"
            [isAdminMode]="isAdminMode"
        ></app-edit-bien>
    </div>
    <div class="floating-buttons-container bottom-right">
        <button *ngIf="!modeCreate" mat-button color="warn" (click)="confirmDelete()">
            <lib-icon>delete</lib-icon>
            {{ 'Supprimer' | uppercase }}
        </button>
        <button mat-button color="primary" (click)="cancel()">
            <mat-icon>close</mat-icon>
            <span>{{ 'Annuler' | uppercase }}</span>
        </button>
        <button mat-raised-button color="accent" [disabled]="!formInfo || !formInfo.valid" (click)="save()">
            <mat-icon>save</mat-icon>
            <span>{{ btSaveLabel | uppercase }}</span>
        </button>
    </div>
</div>
