import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { FileService } from './file.service';
import { CategorieCommentairePredefini } from '../model/categorie-commentaire-predefini.model';

/**
 * Service d'appel aux APIs pour les categories de commentaires prédéfinis.
 * Ne pas appeler directement depuis les composants des pages, uniquement depuis d'autres services.
 * NB : On utilise HttpBackend au lieu de HttpClient ici, pour ne pas passer par les intercepteurs (donc on bypasse l'intercepteur).
 *      Le service appelant doit donc gérer lui-même les erreurs HTTP.
 */
@Injectable({
    providedIn: 'root',
})
export class CategorieCommentairePredefiniApiService {
    private http: HttpClient;

    public resourceUrl = environment.apiUrl + '/reference/categorie-commentaire-predefini';

    constructor(private readonly httpBackend: HttpBackend, private readonly fileService: FileService) {
        this.http = new HttpClient(httpBackend);
    }

    /**
     * Renvoie une catégorie de commentaire prédéfini
     * @param id
     */
    findOne(id: string): Observable<CategorieCommentairePredefini> {
        return this.http.get<CategorieCommentairePredefini>(`${this.resourceUrl}/${id}`);
    }

    /**
     * Renvoie la liste complète des catégories de commentaires prédéfinis
     */
    findAll(): Observable<CategorieCommentairePredefini[]> {
        return this.http.get<CategorieCommentairePredefini[]>(`${this.resourceUrl}`);
    }

    /**
     * Crée ou met à jour une categorie de commentaire prédéfinis
     * @param categorieCommentairePredefini
     */
    upsert(categorieCommentairePredefini: CategorieCommentairePredefini): Observable<CategorieCommentairePredefini> {
        return this.http.put<CategorieCommentairePredefini>(this.resourceUrl, categorieCommentairePredefini);
    }
}
