import { Adresse } from './adresse.model';
import { Agence } from './agence.model';

/**
 * Type de rôle du contact
 */
export enum RoleContact {
    /** Propriétaire */
    'PROPRIETAIRE' = 'PROPRIETAIRE',

    /** Donneur d'ordre */
    'DONNEUR_ORDRE' = 'DONNEUR_ORDRE',

    /** Autre */
    'AUTRE' = 'AUTRE',
}

/**
 * Contact.
 */
export class Contact {
    id: string;

    /**
     * Type de rôle du contact
     */
    typeRole?: RoleContact = RoleContact.AUTRE;

    /**
     * Type de contact
     */
    type?: string;

    /**
     * Titre
     */
    titre?: string;

    /**
     * Civilité : M., Mme, M et Mme, Indivision, Succession
     */
    civilite?: string;

    /**
     * Prénom
     */
    prenom?: string;

    /**
     * Nom
     */
    nom?: string;

    /**
     * Adresse
     */
    adresse?: Adresse = new Adresse();

    /**
     * Adresse à plat du type voie; cmpl1; cmpl2; CP; ville utilisée pour filtrer dans les tableaux
     */
    adresseStringPourFiltre?: string;

    /**
     * Email
     */
    email?: string;

    /**
     * Numéro de téléphone
     */
    telephone?: string;

    /**
     * Numéro de téléphone 2
     */
    ligneDirecte?: string;

    /**
     * Présence pour l'intervention
     */
    presence?: boolean;

    /**
     * Si true, le siret sera renseigné
     * Si false, (entrepriseParent, service, titre, agenceRattachement) seront renseignés
     * Tout le reste sera renseigné dans tous les cas
     */
    estPersonneMorale = false;

    entrepriseParent?: Contact;

    nomEntrepriseParentPourFiltreTri?: string;

    service?: string;

    agenceRattachement?: Agence;

    siret?: string;

    constructor() {}
}
