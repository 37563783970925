import { Pipe, PipeTransform } from '@angular/core';
import { TypeCarottage, typeCarottageToLabel } from '../model/prelevement-generique.model';

/**
 * Affiche le nom d'un type de carottage
 */
@Pipe({
    name: 'typeCarottage',
})
export class TypeCarottagePipe implements PipeTransform {
    constructor() {}

    transform(typeCarottage: TypeCarottage): string {
        return typeCarottageToLabel(typeCarottage);
    }
}
