import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-epi-modal',
    templateUrl: './epi-modal.component.html',
    styleUrls: ['./epi-modal.component.scss'],
})
export class EpiModalComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<EpiModalComponent>) {
        dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth600-dialog');
    }

    confirm() {
        this.dialogRef.close();
    }
}
