import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../authentication/user.service';
import { NotificationService } from '../../notification/notification.service';

@Component({
    selector: 'lib-password-reset-finish',
    templateUrl: './password-reset-finish.component.html',
    styleUrls: ['./password-reset-finish.component.scss'],
})
export class PasswordResetFinishComponent implements OnInit {
    /** Classe CSS sur le conteneur principal */
    @Input() containerClass = '';

    resetKey: string;
    newPassword: string;
    newPasswordConfirm: string;

    constructor(
        private userService: UserService,
        private notificationService: NotificationService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            if (params['key']) {
                this.resetKey = params['key'];
            }
        });
    }

    submit() {
        this.userService.resetPasswordFinish(this.resetKey, this.newPassword).subscribe(() => {
            this.router.navigate(['/login']);
            // Au cas où le login soit dans une popup (comme dans CNBIM), on affiche la notification un poil plus tard
            setTimeout(() => {
                this.notificationService.success('Votre mot de passe a bien été modifié. Merci de vous identifier');
            }, 1000);
        });
    }
}
