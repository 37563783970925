import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { PolluantPrelevement } from 'src/app/modules/diagnostics/polluant/model/polluant-prelevement.model';
import { PolluantConfig } from '../../../../../model/polluant-config.model';
import { WidgetSelectModeEnum } from 'src/app/modules/shared/widget-select/widget-select.component';
import { shoe_icon, vent_icon, aerosol_icon } from 'src/assets/icons/icons.js';

@Component({
    selector: 'app-zone-form-meta',
    templateUrl: './zone-form-meta.component.html',
    styleUrls: ['./zone-form-meta.component.scss'],
})
export class ZoneFormMetaComponent {
    @Input()
    formMeta: FormGroup | AbstractControl;

    @Input()
    polluantConfig: PolluantConfig;

    @Input() // Utilisé pour passer les champs en read-only
    disabled: Boolean;

    @Input()
    contenuDiagnostic: PolluantPrelevement;

    widgetSelectModeEnum: typeof WidgetSelectModeEnum = WidgetSelectModeEnum;

    constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
        this.iconRegistry.addSvgIconLiteral('chaussure', this.sanitizer.bypassSecurityTrustHtml(shoe_icon));
        this.iconRegistry.addSvgIconLiteral('wind', this.sanitizer.bypassSecurityTrustHtml(vent_icon));
        this.iconRegistry.addSvgIconLiteral('aerosol', this.sanitizer.bypassSecurityTrustHtml(aerosol_icon));
    }
}
