<div class="button-container">
    <div class="button-container">
        <ng-container *ngFor="let option of options; let last = last">
            <button [ngClass]="{ 'selected-button': option.selected }" (click)="onOptionClick(option)">
                <mat-icon>{{ option.icon }}</mat-icon>
            </button>
            <span *ngIf="!last" class="spacer"></span>
        </ng-container>
    </div>
</div>
