<div>
    <div class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">
        {{ 'Commentaires' | uppercase }}
    </div>

    <ng-container>
        <div class="block-subtitle ml-4 mb-2">Recommandations</div>
        <div *ngIf="recommandations.length" class="px-5 ml-2 mb-4 bloc-content text-justify">
            {{ recommandations.join('.\n') }}
        </div>
        <div *ngIf="recommandations.length === 0" class="px-5 ml-2 mb-4 bloc-content text-justify">
            Aucune recommandation.
        </div>
    </ng-container>

    <ng-container>
        <div class="block-subtitle ml-4 mb-2">Constatations diverses</div>
        <div *ngIf="constatationsDiverses.length" class="px-5 ml-2 mb-4 bloc-content text-justify">
            {{ constatationsDiverses.join('.\n') }}
        </div>
        <div *ngIf="constatationsDiverses.length === 0" class="px-5 ml-2 mb-4 bloc-content text-justify">
            Aucune constatation.
        </div>
    </ng-container>
</div>
