'use strict';
//***********************************************************************************
//***********************************************************************************
//**** cn_gbxml converter :
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//**** Global functions :
//***********************************************************************************

import { cn_opening_type, code_to_label } from '../model/cn_opening_type';
import { cn_wall_type } from '../model/cn_wall_type';
import { fh_xml } from './fh_xml';
import { cn_azimut, cn_clamp_angle, cn_simplify_contour, cnx_dist, } from './cn_utilities';
import { fh_polar } from '@acenv/fh-3d-viewer';
import { cn_building } from '../model/cn_building';
import { cn_storey } from '../model/cn_storey';
import { cn_roof_opening_type, ROOF_OPENING_CATEGORY_LIST } from '../model/cn_roof_opening_type';
import { cn_slab_type } from '../model/cn_slab_type';
import { cn_thermal_bridge, cn_thermal_model, cn_thermal_opening, cn_thermal_space, cn_thermal_wall } from './exports/cn_thermal_model';
import { cn_opening } from '../model/cn_opening';

/**
 *
 * @param {cn_building} building
 * @param {object} log_data
 * @return {*}
 */
export function cn_to_gbxml(building, log_data = null) {
    var gbxml = new cn_gbxml(building, log_data);
    return gbxml.to_dom().serialize();
}


//***********************************************************************************
//***********************************************************************************
//**** cn_gbxml class :
//***********************************************************************************
//***********************************************************************************

class cn_gbxml {
    //********************************************************************************
    //**** Constructor
    //********************************************************************************
    constructor(building, log_data = null) {
        this._log_data = log_data;
        this._building = building;
        this._layers = [];
        this._storey = null;
        this.space_zone_map = new Map();
        this.unique_ids = [];

        this._thermal_model = new cn_thermal_model(this._building);
    }

    //********************************************************************************
    //**** Main function
    //********************************************************************************
    to_dom() {
        this._thermal_model.analyse();

        this._root = new fh_xml('gbXML');
        this._root.set_attribute('xmlns', 'http://www.gbxml.org/schema');
        this._root.set_attribute('temperatureUnit', 'C');
        this._root.set_attribute('lengthUnit', 'Meters');
        this._root.set_attribute('areaUnit', 'SquareMeters');
        this._root.set_attribute('volumeUnit', 'CubicMeters');
        this._root.set_attribute('useSIUnitsForResults', 'true');
        this._root.set_attribute('version', '5.10');

        this._names = {};
        if (this._log_data) {
            const log_data = new fh_xml('LogData');
            Object.entries(this._log_data).forEach(([k, v]) => {
                log_data.set_attribute(k, v);
            });
            this._root.append_child(log_data);
        }

        //*** build zones */
        this._thermal_model.zones.forEach(zone => {
            this._build_zone(zone.id, zone.name, zone.element.constructor == cn_storey);
        });

        //*** build main hierarchy */
        var campus = new fh_xml('Campus');
        campus.set_attribute('id', cn_building.create_unique_id(this._building.ID));
        this._gbxml_campus = campus;
        this._root.append_child(campus);

        const location = new fh_xml('Location');
        campus.append_child(location);
        location.append_child(new fh_xml('CADModelAzimuth', cn_clamp_angle(this._building.compass_orientation, 0).toFixed(1)));

        this._gbxml_building = new fh_xml('Building');
        campus.append_child(this._gbxml_building);
        this._gbxml_building.add_name_and_id(this._building);

        const building_type = this._building.get_building_reference(this._building.building_data.typology);
        this._gbxml_building.set_attribute('buildingType', building_type ? building_type.codeGbXml : 'Unknown');

        //*** Build storeys */
        this._building.storeys.forEach(storey => {
            this._build_storey(storey)
        });

        //*** Build spaces */
        this._thermal_model.spaces.forEach(space => {
            this._build_space(space)
        });

        //*** Build walls */
        this._thermal_model.walls.forEach(wall => {
            this._build_wall(wall)
        });

        //*** Build thermal bridges */
        this._thermal_model.bridges.forEach(bridge => {
            this._build_bridge(bridge)
        });

        //*** Build wall types */
        this._layers = [];
        var wall_types = this._building.get_wall_types();
        for (var i in wall_types)
            this._build_element_type(wall_types[i]);

        //*** Build slab types */
        this._building.get_slab_types().forEach(st => this._build_element_type(st));

        //*** Build opening types */
        var opening_types = this._building.get_opening_types();
        for (var i in opening_types)
            this._build_element_type(opening_types[i]);

        //*** Build Roof opening types */
        this._building.get_roof_opening_types().forEach(ot => this._build_element_type(ot));

        return this._root;
    }

    _build_zone(id, name, defaultZone = false) {
        const gbxml_zone = new fh_xml('Zone');
        gbxml_zone.set_attribute('id', id);
        gbxml_zone.set_attribute('defaultZone', defaultZone);
        gbxml_zone.append_child(new fh_xml('Name', name));
        this._root.append_child(gbxml_zone);
    }

    _build_storey(storey) {
        const gbxml_building_storey = new fh_xml('BuildingStorey');
        gbxml_building_storey.set_attribute('id', storey.ID);
        const storey_planar_geometry = storey.build_slab_polygon(storey.altitude, false, false);
        gbxml_building_storey.append_child(new fh_xml('Name', storey.get_storey_name()));
        gbxml_building_storey.append_child(new fh_xml('Level', (storey.altitude).toFixed(3)));
        const gbxml_planar_geometry = new fh_xml('PlanarGeometry');
        gbxml_planar_geometry.append_child(this._polygon_to_polyloop(storey_planar_geometry));
        gbxml_building_storey.append_child(gbxml_planar_geometry);
        this._gbxml_building.append_child(gbxml_building_storey);

        const area_trimmings = [];
        storey.scene.stairs.forEach(stair => {
            var pg = stair.build_footprint(0);
            if (pg) area_trimmings.push(pg);
        });
        storey.scene.columns.forEach(column => {
            var pg = column.build_footprint(0);
            if (pg) area_trimmings.push(pg);
        });

        storey['area_trimmings'] = area_trimmings;
    }

    /**
     * build construction type for an opening or roof opening element type
     * @param {*} element_type
     * @returns
     */
    _get_gbxml_construction(element_type) {
        if (element_type.gbxml_construction) return element_type.gbxml_construction;
        let gbxml_construction = {};

        // @ts-ignore
        element_type.gbxml_construction = gbxml_construction;
        if (element_type.constructor == cn_opening_type) {
            if (element_type.free) {
                gbxml_construction.openingType = 'Air';
                gbxml_construction.name = 'Baie libre';
            } else if (element_type.category == 'door') {
                if (element_type.opening == 'sliding')
                    gbxml_construction.openingType = 'SlidingDoor';
                else
                    gbxml_construction.openingType = 'NonSlidingDoor';

                if (element_type.glazing == 'none') {
                    gbxml_construction.name = 'Porte';
                    gbxml_construction.ref = 'constructionIdRef';
                } else {
                    gbxml_construction.name = 'Porte vitrée';
                    gbxml_construction.ref = 'windowTypeIdRef';
                }
            } else if (element_type.category == 'window') {
                if (element_type.opening == 'none')
                    gbxml_construction.openingType = 'FixedWindow';
                else
                    gbxml_construction.openingType = 'OperableWindow';
                gbxml_construction.name = 'Fenêtre';
                gbxml_construction.ref = 'windowTypeIdRef';
            }
        } else if (element_type.constructor == cn_roof_opening_type) {
            if (element_type.category == 'window') {
                if (element_type.opening == 'none')
                    gbxml_construction.openingType = 'FixedWindow';
                else
                    gbxml_construction.openingType = 'OperableWindow';
                gbxml_construction.name = 'Fenêtre de toit';
                gbxml_construction.ref = 'windowTypeIdRef';
            } else {
                if (element_type.opening == 'none')
                    gbxml_construction.openingType = 'FixedSkylight';
                else
                    gbxml_construction.openingType = 'OperableSkylight';
                gbxml_construction.name = code_to_label(element_type.category, ROOF_OPENING_CATEGORY_LIST);
                gbxml_construction.ref = 'windowTypeIdRef';
            }
        }
        return element_type.gbxml_construction;
    }

    //********************************************************************************
    //**** Build wall type
    //********************************************************************************
    _build_element_type(element_type) {

        let gbxml_construction = {};
        if (element_type.constructor == cn_opening_type || element_type.constructor == cn_roof_opening_type)
            gbxml_construction = this._get_gbxml_construction(element_type)

        function create_glass_layer(index) {
            let glazing = new fh_xml('Glaze');
            glazing.set_attribute('id', cn_building.create_unique_id(element_type.ID, 'Glaze', index));
            let thickness = new fh_xml('Thickness', '0.004');
            thickness.set_attribute('unit', 'Meters');
            glazing.append_child(thickness);
            return glazing;
        }

        function create_gas_layer(index) {
            let gaz_info = element_type.glazing_gaz.split('_');
            let gap = new fh_xml('Gap');
            gap.set_attribute('id', cn_building.create_unique_id(element_type.ID, 'Gap', index));
            gap.set_attribute('gas', (gaz_info[0] == 'air') ? 'Air' : 'Argon');
            let thickness = new fh_xml('Thickness', (0.001 * parseInt(gaz_info[1])).toFixed(3));
            thickness.set_attribute('unit', 'Meters');
            gap.append_child(thickness);
            return gap;
        }

        const cn_frame_list = ['alu', 'pvc', 'wood', 'steel'];
        const gbxml_frame_list = ['Aluminum', 'Vinyl', 'Wood', 'Aluminum'];
        const index = cn_frame_list.indexOf(element_type.frame);
        var gbxml_frame = '';
        if (index >= 0) {
            if ((index == 0 || index == 3) && element_type.frame_quality)
                gbxml_frame = 'AluminumWithBreak';
            else
                gbxml_frame = gbxml_frame_list[index];
        }

        var construction;
        if (gbxml_construction.ref == 'windowTypeIdRef') {
            construction = new fh_xml('WindowType');
            if (element_type.compute_physics && !element_type.free) {
                element_type.compute_physics();


                let u_value = new fh_xml('U-value', element_type.Uw.toFixed(3));
                u_value.set_attribute('unit', 'WPerSquareMeterK');
                u_value.set_attribute('userDefined', element_type.user_physics);
                construction.append_child(u_value);

                let solar_factor = new fh_xml('SolarHeatGainCoeff', element_type.Sw.toFixed(3));
                solar_factor.set_attribute('unit', 'Fraction');
                solar_factor.set_attribute('solarIncidentAngle', '0');
                solar_factor.set_attribute('userDefined', element_type.user_physics);
                construction.append_child(solar_factor);

                let transmission = new fh_xml('Transmittance', element_type.Tl.toFixed(3));
                transmission.set_attribute('unit', 'Fraction');
                transmission.set_attribute('type', 'Visible');
                transmission.set_attribute('userDefined', element_type.user_physics);
                construction.append_child(transmission);

                construction.append_child(create_glass_layer(0));

                if (element_type.glazing == 'double' || element_type.glazing == 'triple') {
                    construction.append_child(create_gas_layer(0));
                    construction.append_child(create_glass_layer(1));

                    if (element_type.glazing == 'triple') {
                        construction.append_child(create_gas_layer(1));
                        construction.append_child(create_glass_layer(2));
                    }
                }

                if (gbxml_frame != '') {
                    let frame = new fh_xml('Frame');
                    frame.set_attribute('id', cn_building.create_unique_id(element_type.ID, 'Frame'));
                    frame.set_attribute('type', gbxml_frame);
                    frame.set_attribute('opening', this._capitalize(element_type.opening));
                    if (element_type.constructor != cn_roof_opening_type)
                        frame.set_attribute('panels', element_type.panels);

                    if (element_type.constructor != cn_roof_opening_type) {
                        if (element_type.transom != 'none' && element_type.transom_height > 0) {
                            let transom = new fh_xml('Transom', element_type.transom_height);
                            transom.set_attribute('unit', 'Meters');
                            transom.set_attribute('shape', this._capitalize(element_type.transom));
                            frame.append_child(transom);
                        }

                        if (element_type.sill != 'none' && element_type.sill_height > 0) {
                            let sill = new fh_xml('Sill', element_type.sill_height);
                            sill.set_attribute('unit', 'Meters');
                            sill.set_attribute('opaque', element_type.sill == 'opaque');
                            frame.append_child(sill);
                        }
                    }

                    if (element_type.closing != 'none') {
                        let closing = new fh_xml('Closing');
                        closing.set_attribute('type', this._capitalize(element_type.closing));
                        frame.append_child(closing);
                    }


                    construction.append_child(frame);
                }
            }
        } else {
            construction = new fh_xml('Construction');

            if (element_type.compute_physics && !element_type.free) {
                element_type.compute_physics();
                let u_value = new fh_xml('U-value', element_type.Uw.toFixed(3));
                u_value.set_attribute('unit', 'WPerSquareMeterK');
                u_value.set_attribute('userDefined', element_type.user_physics);
                construction.append_child(u_value);

                if (element_type.Sw > 0) {
                    let solar_factor = new fh_xml('Transmittance', element_type.Sw.toFixed(3));
                    solar_factor.set_attribute('unit', 'Fraction');
                    solar_factor.set_attribute('type', 'Solar');
                    solar_factor.set_attribute('userDefined', element_type.user_physics);
                    construction.append_child(solar_factor);
                }

                if (element_type.Tl > 0) {
                    let transmission = new fh_xml('Transmittance', element_type.Tl.toFixed(3));
                    transmission.set_attribute('unit', 'Fraction');
                    transmission.set_attribute('type', 'Visible');
                    transmission.set_attribute('userDefined', element_type.user_physics);
                    construction.append_child(transmission);
                }
            }
            if (gbxml_frame != '' && !element_type.free)
                construction.append_child(new fh_xml('Description', gbxml_frame));
        }
        this._root.append_child(construction);
        construction.set_attribute('id', element_type.ID);

        construction.append_child(new fh_xml('Name', element_type.get_label()));

        if (!element_type.free && (element_type.constructor == cn_wall_type || element_type.constructor == cn_slab_type)) {
            var layers = element_type.layers;

            for (var i in layers) {
                var layer = this._add_layer(layers[i]);
                var layer_id = new fh_xml('LayerId');
                layer_id.set_attribute('layerIdRef', layer.ID);
                construction.append_child(layer_id);
            }

            let u_value = new fh_xml('U-value', element_type.get_U().toFixed(3));
            u_value.set_attribute('unit', 'WPerSquareMeterK');
            u_value.set_attribute('userDefined', element_type.user_physics);
            construction.append_child(u_value);
        }

    }

    _buildup_name(name) {
        var n = name;
        if (!this._names[name]) this._names[name] = 1;
        n += ' ' + this._names[name];
        this._names[name]++;
        return new fh_xml('Name', n);
    }

    //********************************************************************************
    //**** Add materials
    //********************************************************************************
    _add_layer(cn_layer) {
        var code = cn_layer.name + cn_layer.code + cn_layer.thickness;
        for (var i in this._layers) {
            if (this._layers[i].code == code) return this._layers[i];
        }

        var layer = {};
        layer.code = code;
        layer.ID = cn_building.create_unique_id('material', code);
        this._layers.push(layer);

        var mat = new fh_xml('Material');
        this._root.append_child(mat);
        mat.set_attribute('id', layer.ID + 'M');

        mat.append_child(new fh_xml('Name', cn_layer.name));

        var thickness = new fh_xml('Thickness', cn_layer.thickness);
        thickness.set_attribute('unit', 'Meters');
        mat.append_child(thickness);

        var conductivity = new fh_xml('Conductivity', cn_layer.conductivity);
        conductivity.set_attribute('unit', 'WPerMeterK');
        mat.append_child(conductivity);

        var density = new fh_xml('Density', cn_layer.density);
        density.set_attribute('unit', 'KgPerCubicM');
        mat.append_child(density);

        var specific_heat = new fh_xml('SpecificHeat', cn_layer.specific_heat);
        specific_heat.set_attribute('unit', 'JPerKgK');
        mat.append_child(specific_heat);

        var gb_layer = new fh_xml('Layer');
        this._root.append_child(gb_layer);
        gb_layer.set_attribute('id', layer.ID);
        gb_layer.append_child(new fh_xml('Name', cn_layer.name));
        var gb_material_id = new fh_xml('MaterialId');
        gb_material_id.set_attribute('materialIdRef', layer.ID + 'M');
        gb_layer.append_child(gb_material_id);

        return layer;
    }

    /**
     * Build space
     * @param {cn_thermal_space} thermal_space
     */
    _build_space(thermal_space) {
        var space = new fh_xml('Space');
        this._gbxml_building.append_child(space);

        const cn_space = thermal_space.storey_space.space;
        const cn_storey = thermal_space.storey_space.storey;

        space.set_attribute('id', thermal_space.id);
        space.set_attribute('buildingStoreyIdRef', cn_storey.ID);
        space.set_attribute('bufferSpace', !cn_space.is_heated());
        const space_ref = this._building.get_space_reference(cn_space.space_usage);
        if (space_ref && space_ref.codeGbXml && space_ref.codeGbXml != '')
            space.set_attribute('spaceType', space_ref.codeGbXml);
        space.append_child(new fh_xml('Name', thermal_space.name));

        if (thermal_space.zone)
            space.set_attribute('zoneIdRef', thermal_space.zone.id);

        //*** Special areas */
        //*** Raw area */
        space.append_child(new fh_xml('Area', '' + cn_space.area));

        //*** Floor area (removing volumns, stairs, slab openings) */
        const floor_polygon = cn_space.build_inner_polygon(0, true);
        if (cn_storey['area_trimmings'])
            cn_storey['area_trimmings'].forEach(tr => floor_polygon.substracts(tr));
        space.append_child(new fh_xml('FloorArea', '' + floor_polygon.get_area()));

        //*** Inhabitable area (same as above - area < 1.8m) */
        const floor_altitude = cn_storey.altitude + cn_space.slab_offset;
        thermal_space.solid.get_bounding_box();
        let roof_footprint = thermal_space.solid.plane_intersection([0, 0, floor_altitude + 1.8], [0, 0, 1]);
        if (roof_footprint) roof_footprint.intersects(floor_polygon);
        space.append_child(new fh_xml('InhabitableArea', (roof_footprint) ? '' + roof_footprint.get_area() : '0'));

        space.append_child(new fh_xml('Volume', '' + thermal_space.solid.get_volume()));

        space.append_child(this._solid_to_shell_geometry(thermal_space.solid, cn_building.create_unique_id(thermal_space.id, 'shellG')));
    }

    /**
     * Build gbxml opening
     * @param {cn_thermal_opening} thermal_opening
     * @returns
     */
    _build_opening(thermal_opening) {
        var planar_geometry = this._polygon_to_planar_geometry(thermal_opening.polygon);
        if (planar_geometry == null) return null;

        var opening = new fh_xml('Opening');
        opening.set_attribute('id', thermal_opening.id);

        const map_opening = (thermal_opening.storey_element && thermal_opening.storey_element.element) ? thermal_opening.storey_element.element : null;
        if (map_opening) {
            opening.append_child(new fh_xml('CADObjectId', cn_building.create_unique_id(thermal_opening.storey_element.storey, map_opening)));
            // @ts-ignore
            if (map_opening.constructor == cn_opening && !map_opening.opening_type.free) {
                // @ts-ignore
                const od = map_opening.get_outside_depth();
                const reveal = new fh_xml('Reveal', od.toFixed(3));
                reveal.set_attribute('unit', 'Meters');
                // @ts-ignore
                reveal.set_attribute('position', (map_opening.opening_position == 1) ? 'Tunnel' : (od > 0) ? 'Inside' : 'Outside');
                opening.append_child(reveal);
            }
        }

        if (thermal_opening.element_type) {
            let gbxml_construction = this._get_gbxml_construction(thermal_opening.element_type)
            if (gbxml_construction.openingType)
                opening.set_attribute('openingType', gbxml_construction.openingType);
            if (gbxml_construction.name)
                opening.append_child(new fh_xml('Name', gbxml_construction.name));
            if (gbxml_construction.ref)
                opening.set_attribute(gbxml_construction.ref, thermal_opening.element_type.ID);
        } else {
            opening.set_attribute('openingType', 'Air');
            opening.append_child(new fh_xml('Name', 'Trémie'));
        }

        opening.append_child(planar_geometry);
        opening.append_child(this._polygon_to_empty_rectangular_geometry(thermal_opening.polygon));

        return opening;
    }

    /**
     * Build thermal wall in gbxml
     * @param {cn_thermal_wall} thermal_wall
     * @returns
     */
    _build_wall(thermal_wall) {
        var planar_geometry = this._polygon_to_planar_geometry(thermal_wall.polygon);
        if (planar_geometry == null) return;

        var surface = new fh_xml('Surface');
        this._gbxml_campus.append_child(surface);

        var nb_adj = 0;
        for (var k = 0; k < 2; k++) {
            if (thermal_wall.spaces[k]) {
                var node = new fh_xml('AdjacentSpaceId');
                node.set_attribute('spaceIdRef', thermal_wall.spaces[k].id);
                if (!thermal_wall.vertical)
                    node.set_attribute('spaceAbove', k == 1);
                surface.append_child(node);
                nb_adj++;
            }
        }
        surface.append_child(planar_geometry);
        surface.append_child(this._polygon_to_empty_rectangular_geometry(thermal_wall.polygon));

        surface.set_attribute('id', thermal_wall.id);
        if (thermal_wall.element_type)
            surface.set_attribute('constructionIdRef', thermal_wall.element_type.ID);

        if (thermal_wall.storey_element && thermal_wall.storey_element.element)
            surface.append_child(new fh_xml('CADObjectId', cn_building.create_unique_id(thermal_wall.storey_element.storey, thermal_wall.storey_element.element)));

        if (thermal_wall.contact_type) {
            surface.set_attribute('surfaceType', thermal_wall.contact_type.gbxml);
            surface.append_child(this._buildup_name(thermal_wall.contact_type.label));
        }

        //*** Add openings */
        thermal_wall.openings.forEach(thermal_opening => {
            const opening = this._build_opening(thermal_opening);
            if (opening) surface.append_child(opening);
        });
    }

    /**
     * Build thermal bridges in gbxml
     * @param {cn_thermal_bridge} thermal_bridge
     */
    _build_bridge(thermal_bridge) {

        var bridge = new fh_xml('ThermalBridge');
        this._gbxml_campus.append_child(bridge);

        const connexion_type = thermal_bridge.connexion_type;
        bridge.append_child(this._buildup_name(connexion_type.label));
        bridge.set_attribute('id', thermal_bridge.id);
        bridge.set_attribute('thermalBridgeType', connexion_type.attribute);
        bridge.set_attribute('isLossy', thermal_bridge.lossy);

        bridge.append_child(this._build_cartesian_point(thermal_bridge.p0));
        bridge.append_child(this._build_cartesian_point(thermal_bridge.p1));
        bridge.append_child(new fh_xml('Length', cnx_dist(thermal_bridge.p0, thermal_bridge.p1).toFixed(3)));
        thermal_bridge.connexions.forEach(c => {
            const surf = new fh_xml('AdjacentSurfaceId');
            bridge.append_child(surf);
            surf.set_attribute('surfaceIdRef', c.surface.id);
            surf.append_child(this._build_cartesian_point(c.wall_direction));
        });
        bridge.append_child(new fh_xml('Connection-type', connexion_type.code))
    }

    //********************************************************************************
    //**** Solid to shell geometry
    //********************************************************************************
    _solid_to_shell_geometry(solid, id, zoffset = 0) {
        var shell_geometry = new fh_xml('ShellGeometry');
        shell_geometry.set_attribute('id', id);
        var closed_shell = new fh_xml('ClosedShell');
        shell_geometry.append_child(closed_shell);

        var faces = solid.get_faces();
        for (var i in faces) {
            closed_shell.append_child(this._polygon_to_polyloop(faces[i], zoffset));
        }
        return shell_geometry;
    }

    //********************************************************************************
    //**** polygon to polyloop
    //********************************************************************************
    _polygon_to_polyloop(polygon, zoffset = 0) {
        polygon.compute_contours();
        var polyloop = new fh_xml('PolyLoop');
        var sz = polygon.contour_sizes[0];
        for (var nv = 0; nv < sz; nv++) {
            polyloop.append_child(this._build_cartesian_point(polygon.contour_vertices[nv], zoffset));
        }

        return polyloop;
    }

    //********************************************************************************
    //**** polygon to planar geometry
    //********************************************************************************
    _polygon_to_planar_geometry(polygon, zoffset = 0) {
        var contour = cn_simplify_contour(polygon.to_single_contour().concat([]));
        if (contour == null || contour.length == 0) {
            console.error("wrong polygon")
            return null;
        }

        var surface = new fh_xml('PlanarGeometry');
        var polyloop = new fh_xml('PolyLoop');
        surface.append_child(polyloop);
        for (var nct = 0; nct < contour.length; nct++) {
            polyloop.append_child(this._build_cartesian_point(contour[nct], zoffset));
        }
        surface.append_child(new fh_xml('Area', polygon.get_area().toFixed(3)))

        return surface;
    }

    //********************************************************************************
    //**** polygon to planar geometry
    //********************************************************************************
    _polygon_to_empty_rectangular_geometry(polygon) {
        var surface = new fh_xml('RectangularGeometry');
        const pnor = fh_polar(polygon.get_normal());
        surface.append_child(new fh_xml('Azimuth', cn_azimut(polygon.get_normal(), this._building.compass_orientation).toFixed(1)));
        surface.append_child(new fh_xml('Tilt', cn_clamp_angle(90 - pnor[1] * 180 / Math.PI, 0).toFixed(1)));
        console.log(`Azimuth : ${cn_azimut(polygon.get_normal(), this._building.compass_orientation)} - ${polygon.get_normal()[0]} ${polygon.get_normal()[1]}`)
        return surface;
    }

    //********************************************************************************
    //**** polygon to planar geometry
    //********************************************************************************
    _build_cartesian_point(pt, zoffset = 0) {
        var cartesian_point = new fh_xml('CartesianPoint');
        cartesian_point.append_child(new fh_xml('Coordinate', pt[0].toFixed(3)));
        cartesian_point.append_child(new fh_xml('Coordinate', pt[1].toFixed(3)));
        cartesian_point.append_child(new fh_xml('Coordinate', (pt[2] + zoffset).toFixed(3)));
        return cartesian_point;
    }

    _capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
}
