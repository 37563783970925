<div class="storey-view-content">
    <lib-cn-storey
        #libCnStorey
        [storey]="currentStorey"
        [showControls]="true"
        [config]="{
            drawSpaceNames: true,
            drawBackground: true,
            draw_markers: true,
            drawGrid: true,
            drawScale: true,
            showCompass: true,
            showAngles: true,
            showMeasures: true,
            padding: { left: 30, right: 30, top: 0, bottom: 0 }
        }"
        [readOnly]="true"
    ></lib-cn-storey>
</div>
