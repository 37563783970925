import { Component, Input, OnInit } from '@angular/core';
import { InterventionReportData, IReportComponent, ReportBlockData } from '../../../../../../model/rapport.model';
import { AssainissementReportData, Caracteristique, ItemElementAssainissement } from '../../../model/assainissement.model';
import { BaseComponent } from 'src/app/commons-lib';
import { Intervention } from 'src/app/model/intervention.model';
import { TypePrestationEnum } from 'src/app/model/type-prestation.model';

@Component({
    selector: 'app-caracteristiques-block',
    templateUrl: './caracteristiques-block.component.html',
    styleUrls: ['./caracteristiques-block.component.scss'],
})
export class CaracteristiquesBlockComponent extends BaseComponent implements OnInit, IReportComponent {
    @Input() data: ReportBlockData;
    @Input() intervention: Intervention;
    @Input() interReportData: InterventionReportData;

    dataToDisplay: Caracteristique[] = [];

    constructor() {
        super();
    }

    ngOnInit(): void {
        if (this.interReportData) {
            const diagnosticData = this.interReportData.diagnostics.find(
                (dia) => dia.typePrestation === TypePrestationEnum.ASSAINISSEMENT
            ) as AssainissementReportData;
            this.prepareCaracteristiquesArray(diagnosticData);
        }
    }

    private prepareCaracteristiquesArray(diagnosticData) {
        const carac = diagnosticData.caracteristiques;
        this.dataToDisplay.push(carac.typeBatiment);
        this.dataToDisplay.push(carac.alimentationEau);
        this.dataToDisplay.push(carac.typeReseau);
        this.dataToDisplay.push(carac.documentsCaracteristique);
        this.dataToDisplay.push(carac.documentsImplantation);
        this.dataToDisplay.push(carac.reseauPublicEauPotable);
        this.dataToDisplay.push(carac.captageEau);
        this.dataToDisplay.push(carac.posteRefoulement);
        this.dataToDisplay.push(carac.clapetAntiRetourContrebas);
        this.dataToDisplay.push(carac.ancienneFosse);
        this.dataToDisplay.push(carac.ventilationGenerale);
    }

    private isDocumentPresent(code: string): ItemElementAssainissement {
        const item = new ItemElementAssainissement('n.c.', null);
        const value = this.interReportData.documentsPresents.includes(code);
        if (value === true) {
            item.valeur = 'Oui';
            item.style = 'conforme';
        } else if (value === false) {
            item.valeur = 'Non';
            item.style = 'non-conforme';
        }
        return item;
    }
}
