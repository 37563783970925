import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Document } from '../model/document.model';

/**
 * Service d'appel aux APIs pour les documents.
 * Ne pas appeler directement depuis les composants des pages, uniquement depuis d'autres services.
 * NB : On utilise HttpBackend au lieu de HttpClient ici, pour ne pas passer par les intercepteurs (donc on bypasse l'intercepteur).
 *      Le service appelant doit donc gérer lui-même les erreurs HTTP.
 */
@Injectable({
    providedIn: 'root',
})
export class DocumentsApiService {
    private http: HttpClient;

    public resourceUrl = environment.apiUrl + '/documents';

    constructor(private httpBackend: HttpBackend) {
        this.http = new HttpClient(httpBackend);
    }

    findOneDocument(id: string): Observable<Document> {
        return this.http.get<Document>(`${this.resourceUrl}/${id}`);
    }
}
