import { Component, OnDestroy } from '@angular/core';
import { BaseComponent } from '../../../../../../lib/utils/base.component';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { CnSpinnerService } from '../../../../../shared/cn-spinner/service/cn-spinner.service';
import { InterventionService } from '../../../../../../services/intervention.service';
import { EtatProgressionService } from '../../../../../../services/etat-progression.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationStore } from '../../../../../../lib/authentication/authentication.store';
import { UserInformationApiService } from '../../../../../../services/user-information-api.service';
import { PolluantService } from '../../../services/polluant.service';
import { NotificationService } from '../../../../../../lib/notification/notification.service';
import { ConfirmationService } from '@acenv/commons-lib';
import { EtapeDiagnosticGenerique } from '../../../../../../model/diagnostic-etape.model';
import { first } from 'rxjs/operators';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { POLL_LOT_FILTRE } from '../../../../../../shared/constants/names.step.constants';
import { PolluantPrelevement } from '../../../model/polluant-prelevement.model';

@Component({
    selector: 'app-poll-lot-de-filtre',
    templateUrl: './lot-de-filtre.component.html',
})
export class LotDeFiltreComponent extends BaseComponent implements OnDestroy {
    public diagnostic: Diagnostic = null;
    public lstLotDeFiltre: EtapeDiagnosticGenerique[] = [];
    public confLstLotDeFiltre: string[] = ['42FMETA65'];
    public currentItem: EtapeDiagnosticGenerique = null;
    public isAdding: boolean = false;
    private _contenuDiagnostic: PolluantPrelevement;

    constructor(
        private diagnosticService: DiagnosticService,
        private cnSpinnerService: CnSpinnerService,
        public interventionService: InterventionService,
        private etatProgressionService: EtatProgressionService,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationStore,
        private userInformationApiService: UserInformationApiService,
        private polluantService: PolluantService,
        private readonly notificationService: NotificationService,
        private confirmationService: ConfirmationService
    ) {
        super();
        this.diagnosticService
            .getCurrentDiagnostic()
            .pipe(first())
            .subscribe((diagnostic) => {
                this.diagnostic = diagnostic;
                this._contenuDiagnostic = this.diagnostic.contenuDiagnostic as PolluantPrelevement;
                if (this._contenuDiagnostic.lotDeFiltre?.data?.lstLotDeFiltre) {
                    this.lstLotDeFiltre = this._contenuDiagnostic.lotDeFiltre.data.lstLotDeFiltre;
                }
            });
    }

    /**
     * La valeur saisie par l'user ou selectionné est ajouté
     * @param v
     */
    onChangeValueByUser(v: string) {
        this.isAdding = false;
        this.currentItem.valeur = v;
        this.lstLotDeFiltre.push(this.currentItem);
    }

    /**
     * Au clic sur le btn d'ajout
     * un nouvel item est initialisé + l'affichage de la liste des lots
     */
    onAddLot() {
        this.currentItem = new EtapeDiagnosticGenerique();
        this.isAdding = true;
    }

    /**
     * à la suppression d'un lot de filtre
     * @param pItem
     */
    onDeleteLot(pItem: EtapeDiagnosticGenerique) {
        this.lstLotDeFiltre = this.lstLotDeFiltre.filter((item) => item !== pItem);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        (this.diagnostic.contenuDiagnostic as PolluantPrelevement).lotDeFiltre.data = {
            lstLotDeFiltre: this.lstLotDeFiltre,
        };
        this.etatProgressionService.updateDiagnostic(POLL_LOT_FILTRE, 'VALID', this.diagnostic, true);
    }
}
