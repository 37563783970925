<mat-card class="card-clickable p-2 d-flex" [ngClass]="isSelected ? 'card-selected' : ''" (click)="onClickCard()">
    <mat-card-content class="w-100">
        <div class="d-flex flex-column align-items-center">
            <div class="mat-subheading-2 m-0 text-center">{{ label }}</div>
        </div>
    </mat-card-content>
    <div *ngIf="isSelected" class="selected-icon">
        <div class="d-flex align-items-center justify-content-center h-100 w-100">
            <mat-icon matBadgePosition="below after"> done </mat-icon>
        </div>
    </div>
</mat-card>
