import {
    AuthenticationStore,
    HttpErrorInterceptor,
    LibAppInfoModule,
    LibAuthenticationModule,
    LibConfirmationModule,
    LibFileUploaderModule,
    LibHttpModule,
    LibIconModule,
    LibMarkdownModule,
    LibMaterialConfigModule,
    LibNotificationModule,
    MatPaginatorI18nFr,
} from 'src/app/commons-lib';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import localeFr from '@angular/common/locales/fr';
import { CommonModule, registerLocaleData } from '@angular/common';
import { CnDiagSharedModule } from './modules/shared/shared.module';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PipesModule } from './pipes/pipes.module';
import { MatSelectModule } from '@angular/material/select';
import { MatBadgeModule } from '@angular/material/badge';
import { CancelInterventionModalComponent } from './modules/shared/cancel-intervention-modal/cancel-intervention-modal.component';
import { AgendaComponent } from './modules/accueil/agenda/agenda.component';
import { MissionsComponent } from './modules/accueil/missions/missions.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTreeModule } from '@angular/material/tree';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MatCardModule } from '@angular/material/card';
import { MatTimepickerModule } from 'mat-timepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AjoutRegleComponent } from './modules/admin/ajout-regle/ajout-regle.component';
import { TYPE_PRESTATION_SERVICE } from './services/interfaces/type-prestation.interface';
import { TypeAssainissementService } from './modules/diagnostics/assainissement/services/type-assainissement.service';
import { TypeGazService } from './modules/diagnostics/gaz/services/type-gaz.service';
import { TypeMesurageService } from './modules/diagnostics/mesurage/services/type-mesurage.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { dbConfig } from './shared/constants/indexeddb.constants';
import { EpiModalComponent } from './modules/shared/epi-modal/epi-modal.component';
import { VisualiserDocumentModalComponent } from 'src/app/modules/shared/visualiser-document-modal/visualiser-document-modal.component';
import { SelectTypePhotoModal } from 'src/app/modules/shared/file-uploader/select-type-photo-modal/select-type-photo-modal';
import { CaptureWebcamImages } from 'src/app/modules/shared/capture-webcam-images/capture-webcam-images';
// tslint:disable-next-line:max-line-length
import { TypeMesurageHabitableService } from './modules/diagnostics/mesurage/services/type-mesurage-habitable.service';
import { TypeMesurageUtileService } from './modules/diagnostics/mesurage/services/type-mesurage-utile.service';
import { OfflineStorageModule } from './shared/offline/offline-storage.module';
import { IS_OFFLINE_CAPABLE } from './shared/offline/offline-storage.service';
import { SYNC_PROGRESS, syncProgress$ } from './services/synchronization.service';
import { BehaviorSubject } from 'rxjs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { InputDateModalComponent } from './modules/shared/input-date-modal/input-date-modal.component';
import { TypeElectriciteService } from './modules/diagnostics/electricite/services/type-electricite.service';
import { AppGlobalErrorhandler } from './shared/handler/app-global-error-handler';
import { TypeHapValidationService } from './modules/diagnostics/hap/services/type-hap-validation.service';
import { AppInfoComponent } from './modules/app-info/app-info.component';
import { RouterModule } from '@angular/router';
import { TypeHapVisiteReconnaissanceService } from './modules/diagnostics/hap/services/type-hap-visite-reconnaissance.service';
import { TypeHapValidationResultatsService } from './modules/diagnostics/hap/services/type-hap-validation-resultats.service';
import { TypeHapTerrainService } from './modules/diagnostics/hap/services/type-hap-terrain.service';
/* Polluant */
import { TypePolluantEtudeSituationService } from './modules/diagnostics/polluant/services/type-polluant-etude-situation.service';
import { TypePolluantVisiteChantierService } from './modules/diagnostics/polluant/services/type-polluant-visite-chantier.service';
import { TypePolluantPrelevementService } from './modules/diagnostics/polluant/services/type-polluant-prelevement.service';
import { TypePolluantRedactionStrategieService } from './modules/diagnostics/polluant/services/type-polluant-redaction-strategie.service';
import { TypePolluantResultatsAnalysesService } from './modules/diagnostics/polluant/services/type-polluant-resultats-analyses.service';
import { AdminModule } from './modules/admin/admin.module';
import { TypeHapEtudeSituationService } from './modules/diagnostics/hap/services/type-hap-etude-situation.service';
import {
    TypeBAREN101Service,
    TypeBAREN102Service,
    TypeBAREN103Service,
    TypeBARTH160Service,
    TypeBARTH161Service,
} from './modules/diagnostics/cee/services/type-CEE.serice';
import { map, shareReplay } from 'rxjs/operators';
import { EnerbimCommonsConfirmationModule, EnerbimCommonsNotificationModule } from '@acenv/commons-lib';
import { SyncState, SYNC_STATE } from './services/syncState';

registerLocaleData(localeFr, 'fr-FR');

const isOnline = new BehaviorSubject<SyncState>(SyncState.Online);

@NgModule({
    imports: [
        AdminModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        CnDiagSharedModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.serviceWorker,
            registrationStrategy: 'registerWithDelay:5000',
        }),
        BrowserAnimationsModule,
        MatButtonModule,
        MatSidenavModule,
        MatListModule,
        MatIconModule,
        MatDialogModule,
        EnerbimCommonsNotificationModule.forRoot(),
        EnerbimCommonsConfirmationModule.forRoot(),
        LibNotificationModule.forRoot(),
        LibConfirmationModule.forRoot(),
        LibAuthenticationModule.forRoot(),
        LibHttpModule.forRoot(),
        LibMarkdownModule.forRoot(),
        LibAppInfoModule.forRoot(),
        LibMaterialConfigModule,
        LibFileUploaderModule,
        LibIconModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatProgressBarModule,
        PipesModule,
        MatSelectModule,
        MatBadgeModule,
        MatToolbarModule,
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
        MatButtonToggleModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        MatCardModule,
        MatTimepickerModule,
        MatTableModule,
        MatTreeModule,
        MatPaginatorModule,
        MatSortModule,
        MatMenuModule,
        NgxIndexedDBModule.forRoot(dbConfig),
        OfflineStorageModule.forRoot(),
        MatSlideToggleModule,
        RouterModule,
        CommonModule,
    ],
    declarations: [
        AppComponent,
        CancelInterventionModalComponent,
        InputDateModalComponent,
        EpiModalComponent,
        VisualiserDocumentModalComponent,
        SelectTypePhotoModal,
        CaptureWebcamImages,
        AgendaComponent,
        MissionsComponent,
        AjoutRegleComponent,
        AppInfoComponent,
    ],
    entryComponents: [
        CancelInterventionModalComponent,
        InputDateModalComponent,
        EpiModalComponent,
        VisualiserDocumentModalComponent,
        SelectTypePhotoModal,
        CaptureWebcamImages,
    ],
    providers: [
        NgxImageCompressService,
        // Intercepteurs HTTP
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        // Locale de l'application
        {
            provide: LOCALE_ID,
            useValue: 'fr-FR',
        },
        // Configuration Angular Material
        // -> look & feel
        {
            provide: MAT_TABS_CONFIG,
            useValue: { animationDuration: '0ms' },
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'legacy' },
        },
        {
            provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
            useValue: { appearance: 'legacy' },
        },
        // -> paginator en français
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginatorI18nFr,
        },
        // Service des types de prestation
        {
            provide: TYPE_PRESTATION_SERVICE,
            useClass: TypeAssainissementService,
            multi: true,
        },
        {
            provide: TYPE_PRESTATION_SERVICE,
            useClass: TypeGazService,
            multi: true,
        },
        {
            provide: TYPE_PRESTATION_SERVICE,
            useClass: TypeMesurageService,
            multi: true,
        },
        {
            provide: TYPE_PRESTATION_SERVICE,
            useClass: TypeMesurageHabitableService,
            multi: true,
        },
        {
            provide: TYPE_PRESTATION_SERVICE,
            useClass: TypeMesurageUtileService,
            multi: true,
        },
        {
            provide: TYPE_PRESTATION_SERVICE,
            useClass: TypeHapValidationService,
            multi: true,
        },
        {
            provide: TYPE_PRESTATION_SERVICE,
            useClass: TypeHapEtudeSituationService,
            multi: true,
        },
        {
            provide: TYPE_PRESTATION_SERVICE,
            useClass: TypeHapVisiteReconnaissanceService,
            multi: true,
        },
        {
            provide: TYPE_PRESTATION_SERVICE,
            useClass: TypeHapValidationResultatsService,
            multi: true,
        },
        {
            provide: TYPE_PRESTATION_SERVICE,
            useClass: TypeHapTerrainService,
            multi: true,
        },
        {
            provide: TYPE_PRESTATION_SERVICE,
            useClass: TypeBAREN101Service,
            multi: true,
        },
        {
            provide: TYPE_PRESTATION_SERVICE,
            useClass: TypeBAREN102Service,
            multi: true,
        },
        {
            provide: TYPE_PRESTATION_SERVICE,
            useClass: TypeBAREN103Service,
            multi: true,
        },
        {
            provide: TYPE_PRESTATION_SERVICE,
            useClass: TypeBARTH160Service,
            multi: true,
        },
        {
            provide: TYPE_PRESTATION_SERVICE,
            useClass: TypeBARTH161Service,
            multi: true,
        },
        {
            provide: SYNC_STATE,
            useFactory: (isOnlineState: BehaviorSubject<SyncState>) => {
                isOnlineState.subscribe(() => {});
                return isOnlineState.asObservable();
            },
            deps: [SYNC_STATE],
        },
        {
            provide: SYNC_STATE,
            useValue: isOnline,
        },
        /**
         * Mode hors ligne seulement pour utilisateur avec profil 'OPERATEUR'
         */
        {
            provide: IS_OFFLINE_CAPABLE,
            useFactory: (authStore: AuthenticationStore) =>
                authStore.getCurrentUser().pipe(
                    map((user) => !!user && user.authorities?.includes('ROLE_OPERATOR')),
                    shareReplay()
                ),
            deps: [AuthenticationStore],
        },
        {
            provide: ErrorHandler,
            useClass: AppGlobalErrorhandler,
        },
        {
            provide: TYPE_PRESTATION_SERVICE,
            useClass: TypeElectriciteService,
            multi: true,
        },
        {
            provide: TYPE_PRESTATION_SERVICE,
            useClass: TypePolluantEtudeSituationService,
            multi: true,
        },
        {
            provide: TYPE_PRESTATION_SERVICE,
            useClass: TypePolluantVisiteChantierService,
            multi: true,
        },
        {
            provide: TYPE_PRESTATION_SERVICE,
            useClass: TypePolluantPrelevementService,
            multi: true,
        },
        {
            provide: TYPE_PRESTATION_SERVICE,
            useClass: TypePolluantRedactionStrategieService,
            multi: true,
        },
        {
            provide: TYPE_PRESTATION_SERVICE,
            useClass: TypePolluantResultatsAnalysesService,
            multi: true,
        },
        {
            provide: SYNC_PROGRESS,
            useValue: syncProgress$,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
