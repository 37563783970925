import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ElectriciteService } from './electricite.service';
import { Injectable } from '@angular/core';

@Injectable()
export class InitConfigResolver implements Resolve<void> {
    constructor(private electriciteService: ElectriciteService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.electriciteService.initConfig();
    }
}
