<form *ngIf="formUser" [formGroup]="formUser" (ngSubmit)="validerUser()">
    <div class="toolbar">
        <mat-toolbar class="border-bottom-1 primary">
            <button mat-button class="px-0" (click)="back()">
                <lib-icon>arrow_back</lib-icon>
            </button>
            <div>
                <lib-icon [class]="'icon-before-text'">people</lib-icon>
                <span *ngIf="mode === 'CREATE' && !isReadOnly">Création d'un employé</span>
                <span *ngIf="mode === 'EDIT' && !isReadOnly">Edition d'un employé</span>
                <span *ngIf="isReadOnly">Consultation d'un employé</span>
            </div>

            <div class="d-flex justify-content-end w-100">
                <button mat-button color="primary" class="mx-1" (click)="back()">
                    <mat-icon>close</mat-icon>
                    <span>{{ 'Annuler' | uppercase }}</span>
                </button>
                <button
                    *ngIf="!isReadOnly"
                    mat-raised-button
                    color="primary"
                    type="submit"
                    [disabled]="formUser?.invalid"
                >
                    <lib-icon>check</lib-icon>
                    <span>{{ 'Valider' | uppercase }}</span>
                </button>
                <button *ngIf="isReadOnly" mat-raised-button color="primary" (click)="editer()">
                    <mat-icon>edit</mat-icon>
                    <span>{{ 'Éditer' | uppercase }}</span>
                </button>
            </div>
        </mat-toolbar>
    </div>

    <div class="equipement-container">
        <div class="row no-gutters p-4 pb-2">
            <!-- Colonne gauche-->
            <div class="col-xl-6 pr-2">
                <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between">
                    <mat-card-content>
                        <div class="col-xl-12 m-0 my-3 primary">Informations personnelles</div>
                        <!-- Nom -->
                        <div class="d-flex align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Nom</div>
                            <div class="col-xl-8 pr-2">
                                <mat-form-field class="w-100">
                                    <input [readonly]="isReadOnly" matInput type="text" formControlName="nom" />
                                    <app-mat-error [control]="formUser?.get('nom')"></app-mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <!-- Prenom -->
                        <div class="d-flex align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Prénom</div>
                            <div class="col-xl-8 pr-2">
                                <mat-form-field class="w-100">
                                    <input [readonly]="isReadOnly" matInput type="text" formControlName="prenom" />
                                    <app-mat-error [control]="formUser?.get('prenom')"></app-mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="col-xl-12 m-0 my-3 primary">Contact professionnel</div>
                        <!-- Téléphone -->
                        <div class="d-flex align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary">Téléphone</div>
                            <div class="col-xl-8 pr-2">
                                <mat-form-field class="w-100">
                                    <input [readonly]="isReadOnly" matInput type="text" formControlName="telephone" />
                                </mat-form-field>
                            </div>
                        </div>

                        <!-- Email -->
                        <div class="d-flex align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Email</div>
                            <div class="col-xl-8 pr-2">
                                <mat-form-field class="w-100">
                                    <input [readonly]="isReadOnly" matInput type="text" formControlName="email" />
                                    <mat-error *ngIf="formUser?.get('email').hasError('erreurEmail')">
                                        Un autre employé existe déjà avec cet email.
                                    </mat-error>
                                    <app-mat-error [control]="formUser?.get('email')"></app-mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <!-- Date de sortie de l'employé -->
                        <div class="d-flex align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary">Date de sortie</div>
                            <mat-form-field
                                class="col-xl-4 pr-2"
                                (click)="!isReadOnly ? datepickerDateSortie.open() : ''"
                                appearance="fill"
                            >
                                <input
                                    matInput
                                    [readonly]="isReadOnly"
                                    formControlName="dateSortie"
                                    [matDatepicker]="datepickerDateSortie"
                                />
                                <mat-datepicker-toggle *ngIf="!isReadOnly" matSuffix [for]="datepickerDateSortie">
                                </mat-datepicker-toggle>
                                <mat-datepicker #datepickerDateSortie></mat-datepicker>
                                <app-mat-error [control]="formUser?.get('dateSortie')"></app-mat-error>
                            </mat-form-field>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <!--Colonne droite-->
            <div class="col-xl-6 pl-2">
                <mat-card class="d-flex mb-2 flex-column justify-content-between">
                    <mat-card-content>
                        <div class="col-xl-12 m-0 my-3 primary">Autre</div>
                        <!-- Agence -->
                        <div class="d-flex p-2 align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Agence</div>
                            <div class="col-xl-8">
                                <mat-form-field class="flex-grow-1 w-100">
                                    <input
                                        formControlName="agenceAppartenance"
                                        [matAutocomplete]="autoAgence"
                                        [required]="true"
                                        matInput
                                        [readonly]="isReadOnly"
                                        placeholder="Agence"
                                        type="text"
                                    />
                                    <mat-error
                                        *ngIf="formUser?.get('agenceAppartenance').hasError('agenceDoesNotExist')"
                                    >
                                        Aucune agence n'existe avec ce nom.
                                    </mat-error>
                                    <mat-autocomplete #autoAgence="matAutocomplete" [displayWith]="displayFnNom">
                                        <mat-option *ngIf="isSearchingAgences | async" class="is-loading">
                                            <mat-spinner diameter="25"></mat-spinner>
                                        </mat-option>
                                        <ng-container *ngIf="!(isSearchingAgences | async)">
                                            <mat-option *ngFor="let option of filteredSearchAgences" [value]="option">
                                                {{ option.nom }}
                                            </mat-option>
                                        </ng-container>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                        </div>

                        <!-- Rôles -->
                        <div class="d-flex p-2 align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Rôles</div>
                            <div class="col-xl-8">
                                <app-custom-select
                                    class="w-100"
                                    formControlName="roles"
                                    [readonly]="isReadOnly"
                                    [selectControl]="formUser?.get('roles')"
                                    [matFormFieldClass]="'w-100'"
                                    [choices]="roles"
                                    [addline]="false"
                                    [multiple]="true"
                                >
                                </app-custom-select>
                                <app-mat-error [control]="formUser?.get('roles')"></app-mat-error>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <div class="row no-gutters pl-4 pr-4 pb-4">
            <div class="col-xl-12">
                <mat-card class="d-flex flex-column justify-content-between">
                    <mat-card-content>
                        <div class="col-xl-12 m-0 my-3 primary">
                            <div class="d-flex justify-content-between">
                                Compétences
                                <button
                                    mat-raised-button
                                    color="primary"
                                    type="button"
                                    [disabled]="isReadOnly"
                                    (click)="addCompetence()"
                                >
                                    Ajouter une compétence
                                </button>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-footer>
                        <app-tableau-competences
                            class="pb-2"
                            [competences]="competences"
                            [canEdit]="!isReadOnly"
                            (editCompetenceEvent)="editCompetence($event)"
                            (deleteCompetenceEvent)="deleteCompetence($event)"
                        ></app-tableau-competences>
                    </mat-card-footer>
                </mat-card>
            </div>
        </div>
    </div>
</form>
