import { StateChoiceBoxes } from '../../../../model/states.model';
import { Icon } from 'src/app/commons-lib';
import { Chapitre, PointControle, ValeurCheckpoint } from '../model/electricite.model';
import { LOGEMENT, TABLEAU, TERRE } from '../../../../shared/constants/names.step.constants';
import { TypePrestationEnum } from 'src/app/model/type-prestation.model';

// *** Points de controle ***
// OUI
export const ouiOption = new StateChoiceBoxes('Oui', 'oui', new Icon('check_circle'), 'ok');
// NON
export const nonOption = new StateChoiceBoxes('Non', 'non', new Icon('cancel'), 'ko');
// Non vérifiable
export const nonVerifiabledOption = new StateChoiceBoxes('Non vérifiable', 'non_verifiable', new Icon('visibility_off'));
// SANS OBJET
export const sansObjetOption = new StateChoiceBoxes('Sans objet', 'sans_objet', new Icon('error'), 'warning');
//
export const PARAM_TOOLTIP_NON_VERIFIABLE = 'Non vérifiable';
export const PARAM_TOOLTIP_PART_ACCESSIBLE = 'Partiellement accessible';
export const PARAM_TOOLTIP_SANS_OBJET = 'Sans objet';

// Sous-tension
export const sousTensionOption = new StateChoiceBoxes('Sous-tension', 'sousTension', new Icon('power'), 'ok');
// Hors-tension
export const horsTensionOption = new StateChoiceBoxes('Hors-tension', 'horsTension', new Icon('power_off'), 'ko');

// Capots des tableaux électriques démontables
export const tous = new StateChoiceBoxes('Tous', 'tous', new Icon('check'));
export const aucun = new StateChoiceBoxes('Aucun', 'aucun', new Icon('not_interested'));
export const enPartie = new StateChoiceBoxes('En partie', 'enPartie', new Icon('check'));
export const zero = new StateChoiceBoxes('Zero Capot', 'zeroCapot', new Icon('check'));
export const demontable = new StateChoiceBoxes('Démontable', 'demontable', new Icon('check_circle'), 'ok');
export const nonDemontable = new StateChoiceBoxes('Non démontable', 'nonDemontable', new Icon('cancel'), 'ko');

// Présence d'au moins d'un dispositif 30mA est en tête de circuit
export const Oui = new StateChoiceBoxes('Oui', 'Oui', new Icon('check'));
export const Non = new StateChoiceBoxes('Non', 'Non', new Icon('not_interested'));
export const NonVerifiable = new StateChoiceBoxes('Non vérifiable', 'Non vérifiable', new Icon('visibility_off'));

// AGCP
export const presenceAGPC = new StateChoiceBoxes('Présence AGPC', true, new Icon('check-bold', 'mdi'), 'ok');
export const absenceAGPC = new StateChoiceBoxes('Absence AGPC', false, new Icon('cancel', 'mdi'), 'ko');

// Validité d'un point de contrôle
export const checkpointValid = (value: ValeurCheckpoint) => {
    if (!value) {
        return false;
    } else if (value.isGrey) {
        return true;
    } else if (!value.valeur) {
        return false;
    } else if (value.valeur == nonOption.value || value.valeur == nonVerifiabledOption.value) {
        if (value.commentairesId.length == 0) {
            return false;
        }
    }
    return true;
};

export const comparePointControle = (a: PointControle, b: PointControle) => a.ordreChapitre - b.ordreChapitre;
export const compareChapitre = (a: Chapitre, b: Chapitre) => a.chemin.localeCompare(b.chemin);

export const routesPointsDeControle = [
    {
        path: 'points-de-controles',
        data: {
            label: 'Points de contrôles',
        },
        children: [
            {
                path: 'mesurage',
                data: {
                    label: TypePrestationEnum.MESURAGE,
                },
            },
            {
                path: 'tableau',
                data: {
                    label: 'TABLEAU',
                },
                children: [
                    {
                        path: 'coupure',
                        data: {
                            label: 'Coupure',
                        },
                    },
                    {
                        path: 'differentiel',
                        data: {
                            label: 'Différentiel',
                        },
                    },
                    {
                        path: 'surintensite',
                        data: {
                            label: 'Surintensités',
                        },
                    },
                ],
            },
            {
                path: 'terre',
                data: {
                    label: 'TERRE',
                },
                children: [
                    {
                        path: 'prise-de-terre',
                        data: {
                            label: 'Prise de terre',
                        },
                    },
                    {
                        path: 'installation',
                        data: {
                            label: 'Installation',
                        },
                    },
                    {
                        path: 'calcul',
                        data: {
                            label: 'Calcul',
                        },
                    },
                ],
            },
            {
                path: 'logement',
                data: {
                    label: 'LOGEMENT',
                },
                children: [
                    {
                        path: 'contacts',
                        data: {
                            label: 'Contacts',
                        },
                    },
                    {
                        path: 'inadapte',
                        data: {
                            label: 'Inadapté',
                        },
                    },
                    {
                        path: 'salle-d-eau',
                        data: {
                            label: "Salle d'eau",
                        },
                    },
                    {
                        path: 'prises',
                        data: {
                            label: 'Prises',
                        },
                    },
                    {
                        path: 'huisseries-metalliques',
                        data: {
                            label: 'Huisseries métalliques',
                        },
                    },
                    {
                        path: 'conduits-metalliques',
                        data: {
                            label: 'Conduits métalliques',
                        },
                    },
                ],
            },
            {
                path: 'autre-equipement',
                data: {
                    label: 'AUTRE ÉQUIPEMENT',
                },
                children: [
                    {
                        path: 'parties-communes-et-privatives',
                        data: {
                            label: 'Parties communes et privatives',
                        },
                    },
                    {
                        path: 'piscine-privee',
                        data: {
                            label: 'Piscine privée',
                        },
                    },
                    {
                        path: 'fontaines',
                        data: {
                            label: 'Fontaines',
                        },
                    },
                    {
                        path: 'exterieur-ascenseur',
                        data: {
                            label: 'Extérieur/Ascenseur',
                        },
                    },
                ],
            },
        ],
    },
];
