<div>
    <div class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">
        {{ 'Liste des documents' | uppercase }}
    </div>
    <!--    Block Tableau-->
    <div class="block-content px-5 ml-2 mb-5">
        <table>
            <tr>
                <th class="nom">Nom</th>
                <th class="date">Date du document</th>
                <th class="annexe">Annexe</th>
                <th class="commentaires">Commentaires</th>
            </tr>

            <tr *ngIf="documentsToDisplay?.length === 0">
                <td class="text-center p-4" colspan="4">Aucun document</td>
            </tr>

            <tr *ngFor="let documentData of documentsToDisplay">
                <td>
                    <b>{{ documentData?.nom }}</b> ({{ documentData?.type }})
                </td>
                <td [ngClass]="{ warn: !documentData?.presente }">
                    {{ documentData?.presente ? documentData.dateDocument : 'Document non présenté' }}
                </td>
                <td><ng-container *ngIf="documentData.afficherDansRapport">X</ng-container></td>
                <td>
                    <ul>
                        <li *ngFor="let commentaire of documentData?.commentaires">
                            {{ commentaire?.contenu }}
                        </li>
                    </ul>
                </td>
            </tr>
        </table>
    </div>
</div>
