'use strict';

//***********************************************************************************
//***********************************************************************************
//**** Global params
//***********************************************************************************
//***********************************************************************************
class cn_global_params_model {
    constructor() {
        this.min_grid_spacing = 0;
    }
}

export const cn_global_params = new cn_global_params_model();
