import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestionCommandesComponent } from './gestion-commandes.component';
import { CreationCommandeComponent } from './creation-commande/creation-commande.component';
import { TableauCommandeComponent } from './tableau-commande/tableau-commande.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LibIconModule } from 'src/app/commons-lib';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CnDiagSharedModule } from '../../shared/shared.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [GestionCommandesComponent, CreationCommandeComponent, TableauCommandeComponent],
    imports: [
        CommonModule,
        MatToolbarModule,
        LibIconModule,
        MatButtonModule,
        MatIconModule,
        ReactiveFormsModule,
        MatCardModule,
        MatButtonToggleModule,
        MatFormFieldModule,
        MatInputModule,
        CnDiagSharedModule,
        MatDatepickerModule,
        FormsModule,
        MatTableModule,
        MatSortModule,
        MatMenuModule,
        MatPaginatorModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
    ],
})
export class GestionCommandesModule {}
