import { AfterViewChecked, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent, NotificationService } from 'src/app/commons-lib';
import { Bien, Niveau } from '../../../../model/bien.model';
import { ScreenshotSvg } from '../../../../model/screenshot-svg.model';
import { DrawingPicture, MM_TO_POINT_IMAGE_PRINT } from '@acenv/cnmap-angular-editor-lib';
import { SVG_FILTER } from '../../../../shared/constants/svg.constants';
import { cn_background_map, cn_building, cn_storey } from '@acenv/cnmap-editor';
import { CnSvgConfiguratorWizy } from './cn_svg_configurator_wizy';
import { ResizeObserverEntry } from 'ngx-resize-observer';
import {
    SvgFormValues,
    SvgFormValuesBien,
    SvgFormValuesEquipments,
    SvgFormValuesEspace,
    SvgFormValuesHap,
    SvgFormValuesPage,
    SvgFormValuesPlan,
    SvgFormValuesZones,
} from './shared/interfaces/export-svg-form-values.interface';
import { confAssainissement } from './shared/conf/export-svg-dialog-form-assainissement.conf';
import { exportSvgTypes, SvgConfigType } from './shared/conf/export-svg-types.conf';
import { ExportSvgFormConfMapper } from './shared/mappers/export-svg-form-mapper';
import { DiagnosticService } from 'src/app/services/diagnostic.service';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { InterventionService } from 'src/app/services/intervention.service';
import { TypePrestation, TypePrestationEnum } from 'src/app/model/type-prestation.model';
import { confHap } from './shared/conf/export-svg-dialog-form-hap.conf';
import { Intervention, RelationInterventionBien } from 'src/app/model/intervention.model';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { BackgroundMapApiService } from '../../../../services/background-map-api.service';
import { confMesurage } from './shared/conf/export-svg-dialog-form-mesure.conf';
import { Espace } from '../../../../model/espace.model';
import { DiagnosticHandlerService } from '../../../../services/diagnostic-handler.service';
import { ExportSvgService } from './shared/services/export-svg.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Polluant } from '../../../diagnostics/polluant/model/polluant.model';
import { CndiagMarker } from '../model/cndiag-marker.model';
import { RulesService } from '../../../../services/rules.service';

class ExportSvgModalData {
    biens: Bien[];
    espaces: Espace[];
    typePrestation: TypePrestation;
    svgConfiguration: any;
    screenshotId: string;
    cameraPosition: Array<number>;
}

@Component({
    selector: 'app-export-svg-dialog',
    templateUrl: './export-svg-dialog.component.html',
    styleUrls: ['./export-svg-dialog.component.scss'],
})
export class ExportSvgDialogComponent extends BaseComponent implements OnInit, AfterViewChecked {
    @ViewChild('viewerElement') viewerElement: ElementRef;
    firstInit = false;
    formConf = undefined;
    formValues: SvgFormValues = undefined;
    dynamicScale: number;
    biens: Bien[];
    espaces: Espace[];
    isValidTopForm: boolean;
    backgroundMaps: DrawingPicture[] = [];

    /** Dimension de la div du SVG */
    divWidth: string;
    divHeight: string;
    svgFilterHtml: string;
    readonly svgFilter = SVG_FILTER;

    exportSvgTypes = exportSvgTypes;
    exportSvgTypesFiltered: SvgConfigType[] = [];
    /** Taille du conteneur du svg */
    private viewWidth: number;

    private viewHeight: number;
    /** Dimension du plan en millimètre */
    private heightMm = 210;

    private widthMm = 297;

    private ratio: number;
    private selectedStorey: cn_storey;

    /** Bâtiment courant */
    private building: cn_building;

    /** SVG Configurator pour la page courante */
    private configurator: CnSvgConfiguratorWizy;

    private typePrestation: TypePrestation = TypePrestationEnum.ASSAINISSEMENT;

    public currentDiagnostic: Diagnostic;
    private currentIntervention: Intervention;

    /** Observable pour le changement de la position de caméra sur le configurator de la page courante */
    private configuratorChangeCameraPosition$ = new Subject<void>();
    private cameraPosition: Array<number>;

    topForm: FormGroup;
    details = [
        {
            label: 'Zoom technique',
            value: 'zoom',
        },
        {
            label: "Niveau d'intervention",
            value: 'niveau_intervention',
        },
    ];

    private imagesBase64;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ExportSvgModalData,
        private readonly dialogRef: MatDialogRef<ExportSvgDialogComponent>,
        private readonly notificationService: NotificationService,
        private readonly diagnosticHandlerService: DiagnosticHandlerService,
        private readonly exportSvgFormConfMapper: ExportSvgFormConfMapper,
        private readonly diagnosticService: DiagnosticService,
        private readonly interventionService: InterventionService,
        private readonly backgroundMapApiService: BackgroundMapApiService,
        private readonly exportSvgService: ExportSvgService,
        private readonly formBuilder: FormBuilder,
        private readonly rulesService: RulesService
    ) {
        (cn_background_map as any).image_id_to_url = (fileId) => {
            return (this.backgroundMaps.find((bgmu) => bgmu.fileId == fileId) || ({} as DrawingPicture)).imageUrl;
        };
        super();
        this.createTopForm();
    }
    ngAfterViewChecked(): void {
        if (this.typePrestation === TypePrestationEnum.POLLUANT_ETUDE_SITUATION) {
            try {
                let textElements = document.getElementsByClassName('legend_entry_text');
                let longestTextWidth = 0;

                for (let i = 0; i < textElements.length; i++) {
                    let textWidth = textElements[i].getBoundingClientRect().width;
                    if (textWidth > longestTextWidth) {
                        longestTextWidth = textWidth;
                    }
                }
                for (const element of Array.from(document.getElementsByClassName('legend_background exp'))) {
                    element.setAttribute('width', (longestTextWidth * 2).toString());
                    element['style'].stroke = 'transparent';
                    // parent
                    element.parentNode['style'].transform = 'translateX(50%)';
                }
            } catch (e) {}
        }
    }

    initConf(presetConf?: SvgConfigType) {
        let conf;
        // Chargement de la conf en fonction du module utilisé :
        switch (this.typePrestation) {
            case TypePrestationEnum.POLLUANT_ETUDE_SITUATION:
            case TypePrestationEnum.POLLUANT_VISITE_CHANTIER:
            case TypePrestationEnum.POLLUANT_PRELEVEMENT:
            case TypePrestationEnum.POLLUANT_REDACTION_STRATEGIE:
            case TypePrestationEnum.POLLUANT_RESULTATS_ANALYSES:
            case TypePrestationEnum.ASSAINISSEMENT:
                conf = presetConf ? presetConf : confAssainissement;
                // On set le premier bien séléctionné et le premier niveau
                conf.bien.controls.bien.value = this.biens[0];
                conf.bien.controls.niveau.value = this.biens[0].description[0];
                if (conf?.polluant?.controls?.zoneId) {
                    // Selection de la première zone
                    const diagnosticPolluant = this.currentDiagnostic.contenuDiagnostic as Polluant;
                    for (const perimetre of diagnosticPolluant.perimetresInvestigation.data.listePerimetreInvestigation) {
                        if (perimetre.idBien === conf.bien.controls.bien.value.id) {
                            for (const zone of diagnosticPolluant.zones.data.zonesList.filter((z) => z.idPerimetre === perimetre.id)) {
                                conf.polluant.controls.zoneId.value = zone.id;
                                conf.polluant.controls.perimetreId.value = perimetre.id;
                                break;
                            }
                            break;
                        }
                    }
                }
                break;
            case TypePrestationEnum.MESURAGE:
            case TypePrestationEnum.MESURAGE_HABITABLE:
            case TypePrestationEnum.MESURAGE_UTILE:
                conf = presetConf ? presetConf : confMesurage;
                // On set le premier bien séléctionné et le premier niveau
                conf.bien.controls.bien.value = this.biens[0];
                conf.bien.controls.niveau.value = this.biens[0].description[0];
                break;

            case TypePrestationEnum.HAP_VALIDATION:
            case TypePrestationEnum.HAP_ETUDE_SITUATION:
            case TypePrestationEnum.HAP_VISITE_RECONNAISSANCE:
            case TypePrestationEnum.HAP_TERRAIN:
            case TypePrestationEnum.HAP_VALIDATION_RESULTATS:
                conf = presetConf ? presetConf : confHap;
                // On set le premier espace séléctionné
                const espace = this.espaces[0];
                if (espace) {
                    conf.espace.controls.espace.value = espace;
                    const bien = this.biens.find((it) => it.id === espace.idBien);
                    conf.bien.controls.bien.value = bien;
                    conf.bien.controls.niveau.value = bien?.description[espace.indexNiveau];
                }
                break;
            default:
                conf = presetConf ? presetConf : confAssainissement;
                break;
        }
        if (!!!presetConf && this.exportSvgTypesFiltered.length === 0) {
            this.exportSvgTypesFiltered = [conf];
        }
        // On sauvegarde les valeurs :
        this.formValues = this.exportSvgFormConfMapper.toFormValues(conf, this.biens, this.espaces);
        if (this.configurator) {
            this.configurator.svgFormValues = this.formValues;
            this.configurator.refresh();
        }
        // On sauvegarde la conf
        conf.main.controls.nom.value = this.formConf?.main.controls.nom.value;
        this.formConf = conf;
    }

    ngOnInit(): void {
        // Il faut rajouter le filtre svg pour afficher les objets
        this.initFilterSvg();
        combineLatest([
            this.interventionService.getCurrentIntervention(),
            this.diagnosticService.getCurrentDiagnostic(),
            this.exportSvgService.getObservableImages(),
        ])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([intervention, diagnostic, imagesBase64]) => {
                this.currentIntervention = intervention;
                this.currentDiagnostic = diagnostic;
                this.imagesBase64 = imagesBase64;
                const prestDiag = intervention.prestationsDiagnostics.find((prest) => prest.idDiagnostic === this.currentDiagnostic.id);
                // this.reglesConformite = this.rulesService.findRulesControlIntoPrestation(prestDiag);
                const confsIdsSvgExport = prestDiag.prestation.referencePrestation.presetsExportScreenshot;
                this.exportSvgTypesFiltered = this.exportSvgTypes.filter((conf) => confsIdsSvgExport.includes(conf.id));
                this.details = this.exportSvgTypesFiltered.map((it) => {
                    return {
                        label: it.name,
                        value: it.id,
                    };
                });

                // Récupère le bâtiment courant
                if (this.data) {
                    this.biens = this.data.biens
                        .map((b) => {
                            const bien = { ...b };
                            bien.description = b.description.filter((n) => n.storeyId);

                            return bien;
                        })
                        .filter((b) => b.description.length);

                    this.espaces = this.data.espaces;
                    this.typePrestation = this.data.typePrestation;
                    this.initConf(this.exportSvgTypesFiltered[0]);
                    if (this.data.svgConfiguration) {
                        this.formValues = this.exportSvgFormConfMapper.toFormValues(this.data.svgConfiguration, this.biens, this.espaces);
                        this.formConf = this.data.svgConfiguration;
                        this.firstInit = true;
                    }
                    // On charge le bien :
                    this.changeBuilding(this.formValues.bien.bien, this.formValues.bien.niveau, this.formValues.espace.espace);
                }
                this.populateTopForm(this.data.screenshotId == null);
            });

        // Récupération du changement de position de la camera
        this.configuratorChangeCameraPosition$.pipe(debounceTime(400), takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.cameraPosition = this.configurator.get_camera_position();
        });
    }

    private createTopForm() {
        this.topForm = this.formBuilder.group({
            nom: ['', [Validators.required]],
            detail: [this.details[0].value],
        });

        // value Changed
        this.topForm
            .get('nom')
            .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((values) => {
                Object.assign(this.formValues.main, { nom: values });
            });

        // Status Changed
        this.topForm.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((status) => {
            this.isValidTopForm = this.topForm.valid;
        });
    }

    onClickBtnCancel() {
        this.dialogRef.close(false);
    }

    handleContainerResize(ignored: ResizeObserverEntry) {
        this.updateSvgDivSize();
    }

    handleDivResize(ignored: ResizeObserverEntry) {
        if (this.configurator) {
            this.configurator.refresh();
        }
    }

    onCenterViewChange() {
        this.configurator.center_camera();
    }

    onChangePagesGroup(pagesGroup: SvgFormValuesPage) {
        if (pagesGroup && pagesGroup.format === 'a4_paysage') {
            this.heightMm = 210;
            this.widthMm = 297;
        }

        this.updateSvgDivSize();
    }

    onChangeEspaceGroup(espaceGroup: SvgFormValuesEspace) {
        // Si on a changé d'espace, on assigne le nouveau :
        if (this.formValues.espace.espace && this.formValues.espace.espace.id !== espaceGroup.espace.id) {
            Object.assign(this.formValues.espace, espaceGroup);
            this.changeBuilding(null, null, espaceGroup.espace);
        }
    }

    onChangeBienGroup(bienGroup: SvgFormValuesBien) {
        // Si on a changé de bien, on assigne le nouveau :
        if (this.formValues.bien.bien && this.formValues.bien.bien.id !== bienGroup.bien.id) {
            Object.assign(this.formValues.bien, bienGroup);
            this.changeBuilding(bienGroup.bien, bienGroup.niveau, null);
        }
        // Changement de niveau :
        if (this.formValues.bien.niveau && this.formValues.bien.niveau.id !== bienGroup.niveau.id) {
            Object.assign(this.formValues.bien, bienGroup);
            setTimeout(() => this.changeBuilding(bienGroup.bien, bienGroup.niveau, null), 300);
        }

        const conf = Object.assign({}, this.formConf);
        conf.bien.controls.bien.value = bienGroup.bien;
        conf.bien.controls.niveau.value = bienGroup.niveau;
        this.formConf = conf;
        this.populateTopForm(true);
    }

    private updateConfiguratorPlanMapping(configurator: CnSvgConfiguratorWizy) {
        this.exportSvgService.updateConfiguratorPlanMapping(configurator, this.formValues.plan.general);
    }

    private updateConfiguratorPlanGroup(configurator: CnSvgConfiguratorWizy) {
        this.exportSvgService.updateConfiguratorPlanGroup(configurator, this.formValues.plan);
    }

    onChangePlanGroup(planGroup: SvgFormValuesPlan) {
        // Mise à jour de l'objets contenant les valeurs :
        Object.assign(this.formValues.plan, planGroup);

        if (this.configurator) {
            this.updateConfiguratorPlanGroup(this.configurator);
            this.configurator.refresh();
        }
    }

    onChangeEquipmentGroup(equipmentGroup: SvgFormValuesEquipments) {
        // Mise à jour de l'objets contenant les valeurs :
        Object.assign(this.formValues.equipment, equipmentGroup);

        if (this.configurator) {
            this.configurator.refresh();
        }
    }

    onChangeZoneGroup(zoneGroup: SvgFormValuesZones) {
        // Mise à jour de l'objets contenant les valeurs :
        if (
            this.formValues.zone.surface &&
            (this.formValues.zone.surface.showSurfacesDecomptees !== zoneGroup.surface.showSurfacesDecomptees ||
                this.formValues.zone.surface.showSurfacesComptabilisees !== zoneGroup.surface.showSurfacesComptabilisees)
        ) {
            Object.assign(this.formValues.zone, zoneGroup);
            // On recharge le bien :
            this.changeBuilding(this.formValues.bien.bien, this.formValues.bien.niveau, this.formValues.espace.espace);
            if (this.configurator) {
                this.configurator.refresh();
            }
        }
    }

    /**
     *
     * @param formValue
     */
    onChangePolluantForm(formValue: any) {
        if (this.typePrestation === TypePrestationEnum.POLLUANT_ETUDE_SITUATION) {
            const polluantForm = formValue.polluant;
            // Mise à jour de l'objets contenant les valeurs :
            Object.assign(this.formValues.polluant, polluantForm);

        const isPolluant = [
            TypePrestationEnum.POLLUANT_ETUDE_SITUATION,
            TypePrestationEnum.POLLUANT_VISITE_CHANTIER,
            TypePrestationEnum.POLLUANT_PRELEVEMENT,
            TypePrestationEnum.POLLUANT_REDACTION_STRATEGIE,
            TypePrestationEnum.POLLUANT_RESULTATS_ANALYSES,
        ].some((it) => it === this.currentDiagnostic?.typePrestation);
        if (this.configurator && isPolluant) {
                const diagnosticPolluant = this.currentDiagnostic.contenuDiagnostic as Polluant;
            const pSelected = diagnosticPolluant?.perimetresInvestigation?.data?.listePerimetreInvestigation?.find(
                    (p) => p.id === polluantForm.perimetreId
                );
                if (pSelected) {
                    // Affichage des besoins
                    this.selectedStorey.markers = [];
                    this.configurator.set_svg_param('show_markers', polluantForm.showBesoins);
                    // Parcours de la liste de markers de l'espace
                    try {
                        if (polluantForm.showBesoins) {
                            // Filtre par rapport aux zones
                            for (const besoinJSON of pSelected.listeMarkersJson) {
                                const parseJSON = JSON.parse(besoinJSON);
                                const besoin = diagnosticPolluant.besoins.data.besoinsList.find(
                                    (b) => b.markerId === parseJSON.ID && b.zoneId === polluantForm.zoneId
                                );
                                if (besoin) {
                                    this.selectedStorey.markers.push(CndiagMarker.unserialize(parseJSON, this.selectedStorey));
                                }
                            }
                        }
                    } catch (e) {}
                    // Affichages des zones
                    this.configurator.set_svg_param('show_zone', { property: 'usage', values: [polluantForm.zoneId] });
                } else {
                    this.selectedStorey.markers = [];
                    for (const perimetre of diagnosticPolluant.perimetresInvestigation.data.listePerimetreInvestigation) {
                        if (perimetre.idBien === formValue.bien.bien.id) {
                            if (polluantForm.showBesoins) {
                                for (const besoinJSON of perimetre.listeMarkersJson) {
                                    this.selectedStorey.markers.push(CndiagMarker.unserialize(JSON.parse(besoinJSON), this.selectedStorey));
                                }
                            }
                        }
                    }
                }
                this.configurator.set_svg_param('show_markers', !!(polluantForm.showBesoins || polluantForm.showPerimetres));
                if (polluantForm.showPerimetres) {
                    for (const espace of diagnosticPolluant.espaces.valeur) {
                        if (espace.idBien === formValue.bien.bien.id) {
                            this.selectedStorey.markers.push(
                                ...espace.listeMarkersJson.map((json) => CndiagMarker.unserialize(JSON.parse(json), this.selectedStorey))
                            );
                        }
                    }
                }
                this.configurator.refresh();
            }
        }
    }
    onChangeHapGroup(hapGroup: SvgFormValuesHap) {
        if (
            (this.formValues.hap.zone &&
                (this.formValues.hap.zone.showZoneNonRealises !== hapGroup.zone.showZoneNonRealises ||
                    this.formValues.hap.zone.showZoneInferieurEgal50 !== hapGroup.zone.showZoneInferieurEgal50 ||
                    this.formValues.hap.zone.showZoneEntre51Et500 !== hapGroup.zone.showZoneEntre51Et500 ||
                    this.formValues.hap.zone.showZoneEntre501Et1000 !== hapGroup.zone.showZoneEntre501Et1000 ||
                    this.formValues.hap.zone.showZoneSuperieur1000 !== hapGroup.zone.showZoneSuperieur1000)) ||
            (this.formValues.hap.surface &&
                (this.formValues.hap.surface.showPerimetres !== hapGroup.surface.showPerimetres ||
                    this.formValues.hap.surface.showZones !== hapGroup.surface.showZones ||
                    this.formValues.hap.surface.showPrelevements !== hapGroup.surface.showPrelevements)) ||
            (this.formValues.hap.prelevement &&
                (this.formValues.hap.prelevement.showPrelevementNonRealises !== hapGroup.prelevement.showPrelevementNonRealises ||
                    this.formValues.hap.prelevement.showPrelevementInferieurEgal50 !== hapGroup.prelevement.showPrelevementInferieurEgal50 ||
                    this.formValues.hap.prelevement.showPrelevementEntre51Et500 !== hapGroup.prelevement.showPrelevementEntre51Et500 ||
                    this.formValues.hap.prelevement.showPrelevementEntre501Et1000 !== hapGroup.prelevement.showPrelevementEntre501Et1000 ||
                    this.formValues.hap.prelevement.showPrelevementSuperieur1000 !== hapGroup.prelevement.showPrelevementSuperieur1000))
        ) {
            // Mise à jour de l'objets contenant les valeurs :
            Object.assign(this.formValues.hap, hapGroup);
            // On recharge le bien :
            this.changeBuilding(this.formValues.bien.bien, this.formValues.bien.niveau, this.formValues.espace.espace);
            if (this.configurator) {
                this.configurator.refresh();
            }
        }
    }

    onClickBtnZoom(zoomIn: boolean) {
        this.configurator.zoom(zoomIn);
    }

    onPresetExportSvgTypeChanged(idConf: string) {
        if (this.formConf.id !== idConf) {
            const confToUse = this.exportSvgTypesFiltered.find((it) => it.id === idConf);
            this.initConf(confToUse);
            this.changeBuilding(this.formValues.bien.bien, this.formValues.bien.niveau, this.formValues.espace.espace);
            this.populateTopForm(true);
        }
    }

    onClickBtnExport() {
        // Initialisation d'un nouveau screenshot + configurator
        const screenshotSvg = new ScreenshotSvg();
        if (this.data.screenshotId) {
            screenshotSvg.id = this.data.screenshotId;
        }
        screenshotSvg.storeyId = this.selectedStorey.ID;
        // Selon les backgroundmaps affichés, on refiltre le cn_storey (HAP par exemple)
        this.selectedStorey.background_maps = this.selectedStorey.background_maps.filter((b) =>
            this.backgroundMaps.map((bm) => bm.fileId).includes(b.image_id)
        );

        // MAJ des conformités
        let selectedPointControleNiveau, currentNiveau;
        if (this.formValues.bien.bien) {
            selectedPointControleNiveau = this.exportSvgService.majConformiteElement(
                this.currentIntervention,
                this.currentDiagnostic,
                this.formValues.bien.bien.id,
                this.selectedStorey.ID
            );
            const relBien = this.currentIntervention.relationInterventionBiens.find((rel) => rel.bien.id === this.formValues.bien.bien.id);
            currentNiveau = relBien.bien.description.find((it) => it.storeyId === this.selectedStorey.ID);
            currentNiveau?.volumes.forEach((volume) =>
                this.rulesService.computeJustificationNonVisite(volume, this.currentIntervention.commentaires)
            );
        }
        // Création d'un nouveau configurator
        const configuratorForExport = new CnSvgConfiguratorWizy(
            null,
            this.selectedStorey,
            false,
            selectedPointControleNiveau,
            this.formValues,
            this.getLegendItems(),
            currentNiveau,
            this.imagesBase64
        );

        // maj de la conf du configurator
        const height = Math.round(configuratorForExport.get_print_height() * MM_TO_POINT_IMAGE_PRINT(300));
        const width = Math.round(height * this.ratio);
        if (width != null && height != null) {
            configuratorForExport.render(width, height);
        }
        const planGroup = this.formValues.plan;
        configuratorForExport.set_print_height(this.heightMm);
        this.updateConfiguratorPlanMapping(configuratorForExport);
        if (width != null && height != null) {
            configuratorForExport.set_render_size(width, height);
        }

        this.updateConfiguratorPlanGroup(configuratorForExport);
        configuratorForExport.set_scale(planGroup.general.scale);

        // MAJ du repositionnement de la camera
        configuratorForExport.set_camera_position(this.cameraPosition);
        configuratorForExport.refresh();

        // Génération du contenu du SVG
        const renderSvgFromConfigurator = () => {
            const svgContent = configuratorForExport.render(width, height);
            let svgTxt = `<svg width="${width}" height="${height}" `;
            svgTxt += " xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>";
            svgTxt += this.svgFilter.replace('</defs>', '');
            svgTxt += svgContent.replace('<defs>', '');
            svgTxt += '</svg>';
            if (this.currentDiagnostic.typePrestation === 'POLLUANT_ETUDE_SITUATION') {
                for (const space of this.selectedStorey.scene.spaces) {
                    const regex = new RegExp(space.ID, 'g');
                    svgTxt = svgTxt.replace(regex, space.ID + new Date().getTime());
                }
                svgTxt = svgTxt.replace('.space.exp {stroke: none; fill: rgba(255, 255, 255, 0.5);}', '');
            }

            screenshotSvg.cameraPosition = this.cameraPosition;
            this.finishExport(screenshotSvg, svgTxt);
        };

        // On attend que les images soient complètement chargées avant de lancer le rendering
        if (configuratorForExport.are_images_loaded()) {
            renderSvgFromConfigurator();
        } else {
            configuratorForExport.on('images_loaded', () => renderSvgFromConfigurator());
        }

        // Backup original line : this.dialogRef.close({ screenshotSvg: screenshotSvg, bienId: this.selectedBien.id });
    }

    private finishExport(screenshotSvg: ScreenshotSvg, jsonSvg: string) {
        // Export de la configuration
        screenshotSvg.configurationSvg = this.exportConfiguration();

        // Création du nom, selon le diagnostic
        const labelType = this.exportSvgTypesFiltered.find((it) => it.id === this.formValues.main.detail).name;
        switch (this.typePrestation) {
            case TypePrestationEnum.ASSAINISSEMENT:
                const filenameAss = this.formValues.main.nom;
                screenshotSvg.nom = filenameAss;
                this.dialogRef.close({ screenshotSvg: screenshotSvg, bienId: this.formValues.bien.bien.id });
                break;
            case TypePrestationEnum.HAP_ETUDE_SITUATION:
            case TypePrestationEnum.HAP_TERRAIN:
            case TypePrestationEnum.HAP_VISITE_RECONNAISSANCE:
            case TypePrestationEnum.HAP_VALIDATION:
            case TypePrestationEnum.HAP_VALIDATION_RESULTATS:
                const filenameHAP = this.formValues.main.nom;
                screenshotSvg.nom = filenameHAP;
                this.dialogRef.close({ screenshotSvg: screenshotSvg, espaceId: this.formValues.espace.espace.id });
                break;
            default:
                const filenameDefault = this.formValues.main.nom;
                screenshotSvg.nom = filenameDefault;
                this.dialogRef.close({ screenshotSvg: screenshotSvg, bienId: this.formValues.bien.bien.id });
                break;
        }
    }

    /**
     * Génération du building et du storey suivant le type de prestation
     * @param bien
     * @param niveau
     * @param espace
     */
    private changeBuilding(bien: Bien, niveau: Niveau, espace: Espace) {
        let cnStorey: cn_storey;
        switch (this.typePrestation) {
            case TypePrestationEnum.ASSAINISSEMENT:
            case TypePrestationEnum.POLLUANT_ETUDE_SITUATION:
            case TypePrestationEnum.MESURAGE:
            case TypePrestationEnum.MESURAGE_HABITABLE:
            case TypePrestationEnum.MESURAGE_UTILE:
                const relBien = this.currentIntervention.relationInterventionBiens.find((rel) => rel.bien.id === bien.id);
                this.loadBackgroundmaps(relBien, relBien.bien.backgroundMaps)
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe(() => {
                        this.building = cn_building.unserialize(JSON.parse(relBien.bien.jsonPlan));

                        // On modifie le nom du building pour l'utiliser plus tard dans le nom de l'export
                        this.building.name = bien.nom;

                        // Changement de niveau :
                        cnStorey = this.getCnStorey(niveau.index);
                        // On ajoute les markers au storey.
                        //  Pour le moment, les commentaires sont cachés en prestations mesurages
                        this.diagnosticHandlerService
                            .getTypePrestationService(this.currentDiagnostic.typePrestation)
                            .prepareStoreyForScreenshot(this.currentDiagnostic, cnStorey, this.formValues);
                        setTimeout(() => this.updateStorey(cnStorey), 300);
                        if (this.configurator) {
                            this.configurator.refresh();
                        }
                    });
                break;

            case TypePrestationEnum.HAP_ETUDE_SITUATION:
            case TypePrestationEnum.HAP_VISITE_RECONNAISSANCE:
            case TypePrestationEnum.HAP_TERRAIN:
            case TypePrestationEnum.HAP_VALIDATION:
            case TypePrestationEnum.HAP_VALIDATION_RESULTATS:
                // Récupération du bien lié à celui sélectionné dans l'espace
                const relationInterventionBienEspace = this.currentIntervention.relationInterventionBiens.find((rel) => {
                    return rel.bien.id === espace.idBien;
                });
                // Si le bien existe et a un plan, on s'en sert pour construire le cn_building
                // sinon on crée un cn_building vide
                if (relationInterventionBienEspace && relationInterventionBienEspace.bien) {
                    this.loadBackgroundmaps(
                        relationInterventionBienEspace,
                        relationInterventionBienEspace.bien.backgroundMaps.filter((b) => b.fileId === espace.backgroundFileId)
                    )
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe(() => {
                            if (relationInterventionBienEspace.bien.jsonPlan) {
                                if (espace.indexNiveau !== null && espace.indexNiveau !== undefined) {
                                    this.building = cn_building.unserialize(JSON.parse(relationInterventionBienEspace.bien.jsonPlan));
                                    cnStorey = this.building.storeys[espace.indexNiveau];
                                } else {
                                    const refBuilding = cn_building.unserialize(JSON.parse(relationInterventionBienEspace.bien.jsonPlan));
                                    const bgMap = refBuilding.storeys[0].background_maps.find((it) => it.image_id === espace.backgroundFileId);
                                    this.building = cn_building.generate_new_building();
                                    if (bgMap) {
                                        this.building.storeys[0].background_maps.push(bgMap);
                                    }
                                    // On prend le storey 0 car le new_building ne crée qu'un storey
                                    cnStorey = this.building.storeys[0];
                                }
                            } else {
                                this.building = cn_building.generate_new_building();
                                // On prend le storey 0 car le new_building ne crée qu'un storey
                                cnStorey = this.building.storeys[0];
                            }
                            if (this.configurator) {
                                this.configurator.refresh();
                            }
                            // On modifie le nom du building pour l'utiliser plus tard dans le nom de l'export
                            this.building.name = relationInterventionBienEspace.bien.nom;

                            this.diagnosticHandlerService
                                .getTypePrestationService(this.currentDiagnostic.typePrestation)
                                .prepareStoreyForScreenshot(this.currentDiagnostic, cnStorey, this.formValues);
                            setTimeout(() => this.updateStorey(cnStorey), 300);
                        });
                } else {
                    // Console à garder pour retrouver plus facilement le message d'erreur qui apparait plusieurs fois dans l'appli
                    console.log(`Erreur recherche relationInterventionBien dans export-svg-dialog.component => changeBuilding`);
                    this.notificationService.warn(`Les informations du bien ont changé, des erreurs pourraient survenir.`);
                }

                break;
            default:
                this.building = cn_building.unserialize(JSON.parse(bien?.jsonPlan));

                // On modifie le nom du building pour l'utiliser plus tard dans le nom de l'export
                this.building.name = bien.nom;

                // Changement de niveau :
                cnStorey = this.getCnStorey(niveau.index);
                setTimeout(() => this.updateStorey(cnStorey), 300);
                break;
        }
    }

    // Mise à jour de la taille du conteneur du SVG en déterminant quelle dimension doit être adaptée
    private updateSvgDivSize() {
        if (this.viewerElement) {
            this.viewWidth = this.viewerElement.nativeElement.offsetWidth - 14;
            this.viewHeight = this.viewerElement.nativeElement.offsetHeight - 14;
            this.ratio = this.widthMm / this.heightMm;
            if (this.viewWidth > this.viewHeight * this.ratio) {
                this.divHeight = String(this.viewHeight) + 'px';
                this.divWidth = String(Math.round(this.viewHeight * this.ratio)) + 'px';
            } else {
                this.divWidth = String(this.viewWidth) + 'px';
                this.divHeight = String(Math.round(this.viewWidth / this.ratio)) + 'px';
            }
            if (this.configurator) {
                this.configurator.set_print_height(this.heightMm);
                this.configurator.refresh();
            }
        }
    }

    private updateStorey(storey: cn_storey) {
        this.selectedStorey = storey;
        let selectedPointControleNiveau, currentNiveau;
        if (this.formValues.bien.bien) {
            // Mise à jour de la conformité des éléments
            selectedPointControleNiveau = this.exportSvgService.majConformiteElement(
                this.currentIntervention,
                this.currentDiagnostic,
                this.formValues.bien.bien.id,
                this.selectedStorey.ID
            );
            const relBien = this.currentIntervention.relationInterventionBiens.find((rel) => rel.bien.id === this.formValues.bien.bien.id);
            currentNiveau = relBien.bien.description.find((it) => it.storeyId === this.selectedStorey.ID);
            currentNiveau?.volumes.forEach((volume) =>
                this.rulesService.computeJustificationNonVisite(volume, this.currentIntervention.commentaires)
            );
        }

        if (this.configurator) {
            this.configurator.stop_listening();
        }
        // Réinitialise l'object configurator

        this.configurator = new CnSvgConfiguratorWizy(
            'svg_view',
            this.selectedStorey,
            false,
            selectedPointControleNiveau,
            this.formValues,
            this.getLegendItems(),
            currentNiveau,
            this.imagesBase64
        );

        // Appliquer évenements à catch
        this.configurator.on('scale_change', () => {
            this.dynamicScale = parseFloat(this.configurator.get_scale().toFixed(2));
            this.formValues.plan.general.scale = this.dynamicScale;
        });
        // Appliquer chaque filtres au configurator
        this.updateConfiguratorPlanGroup(this.configurator);

        // On s'abonne aux mouvements de la caméra
        this.configurator.on('camera_move', () => this.configuratorChangeCameraPosition$.next());
        this.configurator.center_camera();
        if (this.firstInit && this.data.cameraPosition && this.data.cameraPosition.length) {
            this.configurator.set_camera_position(this.data.cameraPosition);
            this.firstInit = false;
        }
        this.configurator.refresh();
    }

    private getLegendItems() {
        return this.diagnosticHandlerService
            .getTypePrestationService(this.typePrestation)
            .generateLegendForScreenshot(this.currentDiagnostic, this.formValues);
    }

    private getCnStorey(indexNiveau: number) {
        return this.building.storeys.find((st) => st.storey_index === indexNiveau);
    }

    private initFilterSvg() {
        this.svgFilterHtml = `<svg width="0" height="0">` + this.svgFilter + `</svg>`;
    }

    private loadBackgroundmaps(currentBien: RelationInterventionBien, bms: DrawingPicture[]): Observable<DrawingPicture[]> {
        if (bms.length > 0) {
            return forkJoin(
                bms
                    .slice()
                    .sort((a, b) => a.createdDate.localeCompare(b.createdDate))
                    .map((bg) => {
                        return this.backgroundMapApiService
                            .downloadBackgroundImage(this.interventionService.getCurrentInterventionValue().id, currentBien.id, bg.fileId)
                            .pipe(
                                map((res) => {
                                    const background = { ...bg };
                                    if (res) {
                                        background.imageUrl = res.fileContent;
                                    }
                                    return background;
                                })
                            );
                    })
            ).pipe(
                map((backgrounds) => {
                    this.backgroundMaps = backgrounds;
                    return this.backgroundMaps;
                })
            );
        } else {
            this.backgroundMaps = [];
            return of(this.backgroundMaps);
        }
    }

    private exportConfiguration() {
        let formConf = this.formConf;
        let formValues = this.formValues;
        return this.exportSvgService.exportConfiguration(formConf, formValues);
    }

    private populateTopForm(generateName: boolean = false) {
        const valuesToPatch = this.exportSvgFormConfMapper.toTopFormValues(this.formConf, generateName, this.biens, this.espaces).main;
        valuesToPatch.detail = this.details.flatMap((it) => it.value).includes(valuesToPatch.detail) ? valuesToPatch.detail : this.details[0].value;
        this.topForm.patchValue(valuesToPatch);
        this.onPresetExportSvgTypeChanged(valuesToPatch.detail);
    }
}
