import { Component } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { URL_GESTION_CATEGORIE_OUVRAGE_EDIT, URL_REFERENTIELS } from '../../../../shared/constants/url.constants';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { takeUntil, tap } from 'rxjs/operators';
import { FileService } from '../../../../services/file.service';
import { CnSpinnerService } from '../../../shared/cn-spinner/service/cn-spinner.service';
import { ArbreCategoriesOuvrageComponent } from './arbre-categories-ouvrage/arbre-categories-ouvrage.component';

@Component({
    selector: 'app-gestion-categories-ouvrages-pieces',
    templateUrl: './gestion-categories-ouvrages-pieces.component.html',
    styleUrls: ['./gestion-categories-ouvrages-pieces.component.scss'],
})
export class GestionCategoriesOuvragesPiecesComponent extends BaseComponent {
    constructor(
        private readonly router: Router,
        private readonly httpClient: HttpClient,
        private readonly fileService: FileService,
        private readonly cnSpinner: CnSpinnerService
    ) {
        super();
    }

    creerCategorieOuvrage() {
        this.router.navigate([URL_GESTION_CATEGORIE_OUVRAGE_EDIT]);
    }

    back() {
        this.router.navigate([URL_REFERENTIELS]);
    }

    downloadReferentiel() {
        this.httpClient
            .get<{ url: string }>(`${environment.apiUrl}/categorie-ouvrage-referentiel/download`)
            .pipe(tap((it) => this.fileService.createElementToDownload(`${environment.apiUrl}${it.url}`, 'categories_ouvrages.xlsx')))
            .subscribe();
    }

    uploadReferentiel($event: Event, arbre: ArbreCategoriesOuvrageComponent) {
        const input = $event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            const selectedFile = input.files[0];

            const formData = new FormData();
            formData.append('file', selectedFile, selectedFile.name);
            this.cnSpinner
                .withSpinner(
                    this.httpClient
                        .post(`${environment.apiUrl}/categorie-ouvrage-referentiel/import`, formData, {
                            headers: new HttpHeaders({
                                enctype: 'multipart/form-data',
                            }),
                        })
                        .pipe(
                            takeUntil(this.ngUnsubscribe),
                            tap(() => arbre.refresh())
                        )
                )
                .subscribe();
        }
    }
}
