<div class="toolbar">
    <mat-toolbar class="border-bottom-1 primary">
        <div>
            <lib-icon [class]="'icon-before-text'">badge</lib-icon>
            <span>Gestion des contacts</span>
        </div>
        <div class="flex-grow-1"></div>
        <div>
            <span>User</span>
        </div>
    </mat-toolbar>
</div>
<div class="flex-grow-1 main-background overflow-auto">
    <div class="p-4">
        <div class="entete-tableau-admin">
            <div class="d-flex">
                <div
                    class="onglet-tab-admin mt-2"
                    [ngClass]="typeContactCourant != 'ENTREPRISES' ? 'selected' : ''"
                    (click)="typeContactChange('PERSONNES')"
                >
                    Personnes
                </div>
                <div
                    class="onglet-tab-admin mt-2"
                    [ngClass]="typeContactCourant === 'ENTREPRISES' ? 'selected' : ''"
                    (click)="typeContactChange('ENTREPRISES')"
                >
                    Entreprises
                </div>
            </div>
            <div class="buttons d-flex justify-content-end">
                <button color="accent" mat-raised-button class="bt-valid" (click)="creerContact()">
                    <lib-icon>add</lib-icon>
                    {{
                        typeContactCourant === 'ENTREPRISES'
                            ? ('Créer une entreprise' | uppercase)
                            : ('Créer une personne' | uppercase)
                    }}
                </button>
            </div>
        </div>

        <!-- we keep two components in order to init correctly -->
        <ng-container *ngIf="typeContactCourant === 'PERSONNES'">
            <app-tableau-contacts [type]="typeContactCourant"></app-tableau-contacts>
        </ng-container>
        <ng-container *ngIf="typeContactCourant === 'ENTREPRISES'">
            <app-tableau-contacts [type]="'ENTREPRISES'"></app-tableau-contacts>
        </ng-container>
    </div>
</div>
