import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-input-date-modal',
    templateUrl: './input-date-modal.component.html',
    styleUrls: ['./input-date-modal.component.scss'],
})
export class InputDateModalComponent implements OnInit {
    formDate: FormGroup;

    constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<InputDateModalComponent>) {
        dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth600-dialog');

        this.createForm();
    }

    /**
     * Crée le formulaire
     * @private
     */
    private createForm(): void {
        this.formDate = this.formBuilder.group({
            dateSortie: ['', Validators.required],
        });
    }

    cancel() {
        this.dialogRef.close(false);
    }

    confirm() {
        if (this.formDate.valid) {
            this.dialogRef.close({ dateSortie: this.formDate.value.dateSortie });
        }
    }
}
