import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppInfoModalComponent } from './app-info-modal.component';

@Injectable()
export class AppInfoService {
    constructor(private matDialog: MatDialog) {}

    showAppInfo() {
        this.matDialog.open(AppInfoModalComponent);
    }
}
