import { TypeRapport } from '../model/rapport.model';
import { User } from 'src/app/commons-lib';

export const rolesRapportTypeAccessMapping: { [key: string]: TypeRapport[] } = {
    ROLE_ADMIN: [
        TypeRapport.FINAL_EXCEL,
        TypeRapport.INTERMEDIATE,
        TypeRapport.FINAL_PDF,
        TypeRapport.INTERMEDIATE_PDF,
        TypeRapport.REPORT_DATA_JSON,
    ],
    ROLE_MANAGER: [TypeRapport.FINAL_EXCEL, TypeRapport.FINAL_PDF],
    ROLE_OPERATOR: [TypeRapport.FINAL_EXCEL, TypeRapport.FINAL_PDF],
    ROLE_PROJECT_LEADER: [TypeRapport.FINAL_EXCEL, TypeRapport.FINAL_PDF],
};

export function hasTypeRapportAccess(typeRapport: TypeRapport, user: User) {
    return new Set(
        user.authorities
            .map((it) => rolesRapportTypeAccessMapping[it])
            .filter((it) => !!it)
            .flat()
    ).has(typeRapport);
}
