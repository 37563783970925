<div class="detail-processus">
    <!-- <div *ngFor="let tacheItem of taches.controls; let i = index">
    </div> -->

    <mat-card [formGroup]="formTache">
        <div class="detail-processus-card">
            <div class="detail-processus-form">
                <div class="detail-processus-field">
                    <mat-label>Opérateur</mat-label>
                    <div class="detail-processus-operateur">
                        <div class="checkbox">
                            <app-operateur-checkbox formControlName="operateur1" data-cy="operateur-1-button"
                                >Opérateur 1
                            </app-operateur-checkbox>
                            <input type="text" *ngIf="modePrelevement" formControlName="nom1" />
                        </div>
                        <div class="checkbox">
                            <app-operateur-checkbox formControlName="operateur2" data-cy="operateur-2-button"
                                >Opérateur 2
                            </app-operateur-checkbox>
                            <input type="text" *ngIf="modePrelevement" formControlName="nom2" />
                        </div>
                        <div class="checkbox">
                            <app-operateur-checkbox formControlName="operateur3" data-cy="operateur-3-button"
                                >Opérateur 3
                            </app-operateur-checkbox>
                            <input type="text" *ngIf="modePrelevement" formControlName="nom3" />
                        </div>
                        <div class="checkbox">
                            <app-operateur-checkbox formControlName="operateur4" data-cy="operateur-4-button"
                                >Opérateur 4
                            </app-operateur-checkbox>
                            <input type="text" *ngIf="modePrelevement" formControlName="nom4" />
                        </div>
                    </div>
                </div>

                <mat-form-field class="w-100 detail-processus-field">
                    <mat-label>Nom de la tâche</mat-label>
                    <input matInput type="text" formControlName="tache" data-cy="nom-tache-input" />
                </mat-form-field>
            </div>

            <div class="detail-processus-action">
                <button
                    *ngIf="indexSelectedTache == undefined"
                    mat-raised-button
                    color="primary"
                    [disabled]="formTache.invalid"
                    data-cy="ajouter-tache-button"
                    (click)="add()"
                >
                    Ajouter
                </button>

                <button
                    *ngIf="indexSelectedTache != undefined"
                    mat-raised-button
                    color="primary"
                    [disabled]="formTache.invalid"
                    (click)="modifier()"
                >
                    Modifier
                </button>
            </div>
        </div>

        <div class="detail-processus-errors" *ngIf="formTache.touched && formTache.invalid">
            <app-form-error [control]="formTache" message="Vous devez au minimum choisir 1 opérateur"></app-form-error>

            <app-form-error
                [control]="formTache.get('tache')"
                message="Vous devez sélectionner une tâche"
            ></app-form-error>
        </div>
    </mat-card>

    <div class="result">
        <app-item-card
            *ngFor="let tacheItem of taches.controls; let i = index"
            (click)="selectTache(i)"
            [isSelected]="i === indexSelectedTache"
        >
            <div title class="title">Opérateur {{ getTitle(tacheItem.value) }}</div>
            <div state>{{ tacheItem.value.tache }}</div>
            <div action>
                <button mat-icon-button aria-label="copier" (click)="taches.push(getTacheGroup(tacheItem.value))">
                    <mat-icon>content_copy</mat-icon>
                </button>
                <!-- Doublon avec la sélection du card
                <button mat-icon-button aria-label="modifier" (click)="selectTache(i)">
                    <mat-icon>edit</mat-icon>
                </button>-->
                <button mat-icon-button aria-label="supprimer" class="warn" (click)="removeTache(i)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </app-item-card>
    </div>
</div>
