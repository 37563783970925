'use strict';
import { cn_beam } from '../model/cn_beam';
import { cn_svg_map } from './cn_svg_map';
import { cn_3d_line_handler } from './cn_3d_line_handler';
import { fh_clone } from '@acenv/fh-3d-viewer';
import { cn_mouse_event } from './cn_mouse_event';
import { cn_type_pastille } from './cn_type_pastille';
import { cn_edit_box } from './cn_edit_box';
import { cn_number_input } from './cn_inputs';
import { cn_column } from '../model/cn_column';
import { cn_3d_object_handler } from './cn_3d_object_handler';
import { cn_clone } from '../utils/cn_utilities';
import { cn_edition_handler } from './cn_edition_handler';

export class cn_beam_handler extends cn_edition_handler {
    //***********************************************************************************
    /**
     * Constructor
     * @param {Array<cn_beam | cn_column>} beams
     * @param {cn_svg_map} map
     * @param {boolean} creation
     */
    constructor(beams, map, creation = false) {
        super(beams, map);

        this._map = map;
        this._scene = this._map._scene;
        this._transaction_manager = this._map._building.transaction_manager;

        //*** beam
        this.beams = beams;
        this._beam = (beams.length > 1) ? null : beams[0];
        if (this._beam) this._initialize_edition();

        this._current_vertex_index = 0;

        //*** Edit box for mass selection */
        const edit_box = new cn_edit_box(this, beams, this._readOnly);
        this._handlers.push(edit_box);

        if (!this._readOnly) {
            //*** Type pastille */
            const type_pastille = new cn_type_pastille(beams, 'element_type', () => map._building.get_beam_types(), 'Type de structure', map);
            type_pastille.title = 'Modifier le type de poutre / colonne';
            edit_box.add_pastille(type_pastille);

            edit_box.add_lock_pastille(this._transaction_manager);
        }
        edit_box.add_select_siblings_pastille('element_type');
        edit_box.add_information_pastille('element_type');
    }

    //***********************************************************************************
    //**** Draws
    //***********************************************************************************
    draw(camera) {
        var html = '';

        if (this._beam) html += this._beam.draw(camera, ['selected']);

        if (this._handler) this._handler.visible = this._handler.active = !this._beam.locked;

        html += super.draw(camera);

        return html;
    }

    //***********************************************************************************
    //**** clear move effects
    //***********************************************************************************
    /**
     * Manage a passive move. To return 'true' if something of interest under the mouse.
     * @param {cn_mouse_event} ev
     * @returns  {boolean}
     */
    move(ev) {
        if (super.move(ev)) return true;

        return this._beam && this._beam.contains(ev.mouse_world, ev.camera.snap_world_distance);
    }

    _initialize_edition() {
        if (this._beam.constructor == cn_beam)
            this._edit_beam(this._beam);
        else if (this._beam.constructor == cn_column)
            this._edit_column(this._beam);
    }

    /**
     *
     * @param {cn_beam} beam
     */
    _edit_beam(beam) {
        this._handler = null;

        var obj = this;
        var scene = obj._scene;

        const handler = new cn_3d_line_handler(this, beam.vertices);
        this._handler = handler;
        this._handlers.push(handler);
        handler.automatic_height = 0;
        handler.snap_elements = scene.spaces.concat(scene.beams).concat(scene.columns);
        handler.snap_elements.splice(handler.snap_elements.indexOf(beam), 1);

        if (this._map.get_readonly()) {
            return;
        }

        //*** Callback on change */
        handler.on('change', () => {
            obj._transaction_manager.push_transaction('Modification de poutre', beam.ID);
            obj._transaction_manager.push_item_set(obj._beam, 'vertices');
            beam.vertices[0] = fh_clone(handler.vertices[0]);
            beam.vertices[1] = fh_clone(handler.vertices[1]);
        });

        handler.on('height_click', index => {
            const input = new cn_number_input('Hauteur', beam.vertices[index][2], 'm', 2, -100, 100);
            input.checkbox_label = 'Jusqu\'au plafond';
            input.checkbox_status = (beam.vertices[index][2] == 0);
            input.callback = () => {
                obj._transaction_manager.push_transaction('Hauteur de poutre');
                obj._transaction_manager.push_item_set(beam, 'vertices');
                beam.vertices[index][2] = (input.checkbox_status) ? 0 : input.value;
                handler.vertices[index][2] = (input.checkbox_status) ? 0 : input.value;
                obj._map.refresh_tool();
            }
            obj.call('number_input', input);
            return true;
        });
    }

    /**
     *
     * @param {cn_column} column
     * @returns
     */
    _edit_column(column) {
        var obj = this;
        var scene = obj._scene;

        //*** Create a handler for that object.
        const h = (!column.height) ? false : column.height;
        const handler = new cn_3d_object_handler(column.position, column.orientation, h, this._scene);
        handler.snap_elements = scene.spaces.concat(scene.beams).concat(scene.columns);
        handler.snap_elements.splice(handler.snap_elements.indexOf(column), 1);
        this._handler = handler;
        this._handlers.push(handler);

        if (this._map.get_readonly()) {
            return false;
        }

        handler.on('change', () => {
            obj._transaction_manager.push_transaction('Position de poteau', column.ID);
            obj._transaction_manager.push_item_set(column, ['position', 'orientation']);

            column.position = cn_clone(handler.position);
            column.orientation = handler.orientation;
        });

        handler.on('height_click', () => {
            const input = new cn_number_input('Hauteur', (column.height) ? column.height : 0, 'm', 2, 0.1, 100);
            input.checkbox_label = 'Jusqu\'au plafond';
            input.checkbox_status = (!column.height);
            input.callback = () => {
                const check_status = input.checkbox_status;
                const new_value = input.value;
                obj._transaction_manager.push_transaction('Hauteur de poteau');
                obj._transaction_manager.push_item_set(column, 'height');
                column.height = (check_status) ? 0 : new_value;
                handler.height = (check_status) ? 0 : new_value;
                obj._map.refresh_tool();
            }
            obj.call('number_input', input);
            return true;
        });
        return true;
    }

}

