import { Adresse } from './adresse.model';
import { UserWizy } from './user-wizy.model';
import { Document } from './document.model';
import { TypePrestation } from './type-prestation.model';
/**
 * Agence
 */
export class Cofrac {
    id: string;

    nom?: string;

    // Alphanumérique
    numero?: string;

    typePrestations: TypePrestation[] = [];

    idFichierLogo: string;
    logoUrl: string | ArrayBuffer;
    logoImage: FormData;

    /**
     * Date de début de validité du cofrac
     */
    dateDebutValidite: string;

    /**
     * Date d'échéance du cofrac (non obligatoire)
     */
    dateEcheance?: string;

    documentCofrac?: Document;
}

export class CofracAdmin {
    id: string;

    nom?: string;

    // Alphanumérique
    numero?: string;

    typePrestations: TypePrestation[] = [];

    typePrestationsString: string;

    idFichierLogo: string;

    /**
     * Date de début de validité du cofrac
     */
    dateDebutValidite: string;

    /**
     * Date d'échéance du cofrac (non obligatoire)
     */
    dateEcheance?: string;

    documentCofrac?: Document;
}
