<div>
    <div class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">
        {{ 'Grille de contrôle' | uppercase }}
    </div>
    <div class="d-flex flex-row">
        <div class="block-subtitle ml-4 mb-2 flex-grow-1">Raccordements des eaux usées</div>
        <div class="row mx-2">
            <div class="row legendes align-content-between mr-3">
                <div class="legende row">
                    <div class="conf-1"></div>
                    <div class="conf-2"></div>
                </div>
                <span class="ml-4">Conforme</span>
            </div>
            <div class="row legendes align-content-between mr-3">
                <div class="legende row">
                    <div class="non-conf-1"></div>
                    <div class="non-conf-2"></div>
                </div>
                <span class="ml-4">Non conforme</span>
            </div>
        </div>
    </div>
    <div class="block-content px-5 ml-2 mb-5">
        <ng-container *ngFor="let bien of raccordementEU.biens">
            <div class="block-subtitle-2 mt-4 mb-2">{{ bien.bien }}</div>
            <table>
                <tr>
                    <th>Niveau / Volume</th>
                    <ng-container *ngIf="optionPlan">
                        <th class="plan">Plan</th>
                        <th class="edit-comment"></th>
                    </ng-container>
                    <th>Équipement</th>
                    <th>Matériaux</th>
                    <th>État</th>
                    <th>Écoulement fluide</th>
                    <th>Contrôle</th>
                    <th>Rejet conforme</th>
                    <th>Commentaires</th>
                    <th class="edit-comment"></th>
                </tr>

                <tr *ngIf="bien.niveaux.length === 0">
                    <td [attr.colspan]="optionPlan ? '11' : '9'" class="text-center p-4">Aucun point de contrôle</td>
                </tr>

                <ng-container *ngFor="let lv of bien.niveaux">
                    <tr>
                        <td [attr.colspan]="optionPlan ? '11' : '9'" class="row-niveau">
                            {{ lv.niveau }}
                        </td>
                    </tr>

                    <ng-container *ngFor="let vol of lv.volumes">
                        <tr *ngFor="let el of vol.equipements" [ngClass]="el.conformite ? el.conformite.style : ''">
                            <td>
                                <b>{{ vol.volume }}</b>
                            </td>
                            <ng-container *ngIf="optionPlan">
                                <td>
                                    <ng-container *ngFor="let screen of vol.plans"> {{ screen.nom }} <br /> </ng-container>
                                </td>
                                <td>
                                    <button
                                        *ngIf="readonlyMode"
                                        mat-icon-button
                                        class="small-button button-group primary"
                                        (click)="onClickEditPlansList(vol)"
                                    >
                                        <mat-icon class="icon-small">edit</mat-icon>
                                    </button>
                                </td>
                            </ng-container>
                            <td>
                                {{ el.element }}
                            </td>
                            <td [ngClass]="el.materiaux ? el.materiaux.style : ''">
                                {{ el.materiaux.valeur }}
                            </td>
                            <td [ngClass]="el.etat ? el.etat.style : ''">
                                {{ el.etat.valeur }}
                            </td>
                            <td [ngClass]="el.ecoulement ? el.ecoulement.style : ''">
                                {{ el.ecoulement.valeur }}
                            </td>
                            <td [ngClass]="el.controle ? el.controle.style : ''">
                                {{ el.controle.valeur }}
                            </td>
                            <td [ngClass]="el.rejet ? el.rejet.style : ''">
                                {{ el.rejet.valeur }}
                            </td>
                            <td>
                                <ng-container *ngFor="let com of el.commentaires">
                                    <div [ngClass]="exclusionRaccordementEU.includes(com.id) ? 'hidden-com' : ''">{{ com.contenu }} <br /></div>
                                </ng-container>
                            </td>
                            <td>
                                <button
                                    mat-icon-button
                                    class="small-button button-group primary"
                                    *ngIf="!readonlyMode && el.commentaires.length"
                                    (click)="onClickEditDisplayedComments(el.commentaires, raccordementEU.code)"
                                >
                                    <mat-icon class="icon-small">edit</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </table>
        </ng-container>
    </div>

    <div class="d-flex flex-row">
        <div class="block-subtitle ml-4 mb-2 flex-grow-1">Équipements des eaux usées</div>
        <div class="row mx-2">
            <div class="row legendes align-content-between mr-3">
                <div class="legende row">
                    <div class="conf-1"></div>
                    <div class="conf-2"></div>
                </div>
                <span class="ml-4">Conforme</span>
            </div>
            <div class="row legendes align-content-between mr-3">
                <div class="legende row">
                    <div class="non-conf-1"></div>
                    <div class="non-conf-2"></div>
                </div>
                <span class="ml-4">Non conforme</span>
            </div>
        </div>
    </div>

    <div class="block-content px-5 ml-2 mb-5">
        <ng-container *ngFor="let bien of equipementsEU.biens">
            <div class="block-subtitle-2 mt-4 mb-2">{{ bien.bien }}</div>
            <table>
                <tr>
                    <th>Niveau / Volume</th>
                    <ng-container *ngIf="optionPlan">
                        <th class="plan">Plan</th>
                        <th class="edit-comment"></th>
                    </ng-container>
                    <th>Équipement</th>
                    <th>Odeur</th>
                    <th>État</th>
                    <th>Écoulement fluide</th>
                    <th>Contrôle</th>
                    <th>Rejet conforme</th>
                    <th>Commentaires</th>
                    <th class="edit-comment"></th>
                </tr>

                <tr *ngIf="bien.niveaux.length === 0">
                    <td [attr.colspan]="optionPlan ? '11' : '9'" class="text-center p-4">Aucun point de contrôle</td>
                </tr>

                <ng-container *ngFor="let lv of bien.niveaux">
                    <tr>
                        <td [attr.colspan]="optionPlan ? '11' : '9'" class="row-niveau">
                            {{ lv.niveau }}
                        </td>
                    </tr>

                    <ng-container *ngFor="let vol of lv.volumes">
                        <tr *ngFor="let el of vol.equipements" [ngClass]="el.conformite ? el.conformite.style : ''">
                            <td>
                                <b>{{ vol.volume }}</b>
                            </td>
                            <ng-container *ngIf="optionPlan">
                                <td>
                                    <ng-container *ngFor="let screen of vol.plans"> {{ screen.nom }} <br /> </ng-container>
                                </td>
                                <td>
                                    <button
                                        *ngIf="readonlyMode"
                                        mat-icon-button
                                        class="small-button button-group primary"
                                        (click)="onClickEditPlansList(vol)"
                                    >
                                        <mat-icon class="icon-small">edit</mat-icon>
                                    </button>
                                </td>
                            </ng-container>
                            <td>
                                {{ el.element }}
                            </td>
                            <td [ngClass]="el.odeur ? el.odeur.style : ''">
                                {{ el.odeur.valeur }}
                            </td>
                            <td [ngClass]="el.etat ? el.etat.style : ''">
                                {{ el.etat.valeur }}
                            </td>
                            <td [ngClass]="el.ecoulement ? el.ecoulement.style : ''">
                                {{ el.ecoulement.valeur }}
                            </td>
                            <td [ngClass]="el.controle ? el.controle.style : ''">
                                {{ el.controle.valeur }}
                            </td>
                            <td [ngClass]="el.rejet ? el.rejet.style : ''">
                                {{ el.rejet.valeur }}
                            </td>
                            <td>
                                <ng-container *ngFor="let com of el.commentaires">
                                    <div [ngClass]="exclusionEquipementEU.includes(com.id) ? 'hidden-com' : ''">{{ com.contenu }} <br /></div>
                                </ng-container>
                            </td>
                            <td>
                                <button
                                    mat-icon-button
                                    class="small-button button-group primary"
                                    *ngIf="!readonlyMode && el.commentaires.length"
                                    (click)="onClickEditDisplayedComments(el.commentaires, equipementsEU.code)"
                                >
                                    <mat-icon class="icon-small">edit</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </table>
        </ng-container>
    </div>

    <div class="d-flex flex-row">
        <div class="block-subtitle ml-4 mb-2 flex-grow-1">Raccordements des eaux pluviales</div>
        <div class="row mx-2">
            <div class="row legendes align-content-between mr-3">
                <div class="legende row">
                    <div class="conf-1"></div>
                    <div class="conf-2"></div>
                </div>
                <span class="ml-4">Conforme</span>
            </div>
            <div class="row legendes align-content-between mr-3">
                <div class="legende row">
                    <div class="non-conf-1"></div>
                    <div class="non-conf-2"></div>
                </div>
                <span class="ml-4">Non conforme</span>
            </div>
        </div>
    </div>
    <div class="block-content px-5 ml-2 mb-5">
        <ng-container *ngFor="let bien of raccordementEP.biens">
            <div class="block-subtitle-2 mt-4 mb-2">{{ bien.bien }}</div>
            <table>
                <tr>
                    <th>Niveau / Volume</th>
                    <ng-container *ngIf="optionPlan">
                        <th class="plan">Plan</th>
                        <th class="edit-comment"></th>
                    </ng-container>
                    <th>Équipement</th>
                    <th>Matériaux</th>
                    <th>État</th>
                    <th>Écoulement fluide</th>
                    <th>Contrôle</th>
                    <th>Rejet conforme</th>
                    <th>Commentaires</th>
                    <th class="edit-comment"></th>
                </tr>

                <tr *ngIf="bien.niveaux.length === 0">
                    <td [attr.colspan]="optionPlan ? '11' : '9'" class="text-center p-4">Aucun point de contrôle</td>
                </tr>

                <ng-container *ngFor="let lv of bien.niveaux">
                    <tr>
                        <td [attr.colspan]="optionPlan ? '11' : '9'" class="row-niveau">
                            {{ lv.niveau }}
                        </td>
                    </tr>

                    <ng-container *ngFor="let vol of lv.volumes">
                        <tr *ngFor="let el of vol.equipements" [ngClass]="el.conformite ? el.conformite.style : ''">
                            <td>
                                <b>{{ vol.volume }}</b>
                            </td>
                            <ng-container *ngIf="optionPlan">
                                <td>
                                    <ng-container *ngFor="let screen of vol.plans"> {{ screen.nom }} <br /> </ng-container>
                                </td>
                                <td>
                                    <button
                                        *ngIf="readonlyMode"
                                        mat-icon-button
                                        class="small-button button-group primary"
                                        (click)="onClickEditPlansList(vol)"
                                    >
                                        <mat-icon class="icon-small">edit</mat-icon>
                                    </button>
                                </td>
                            </ng-container>
                            <td>
                                {{ el.element }}
                            </td>
                            <td [ngClass]="el.materiaux ? el.materiaux.style : ''">
                                {{ el.materiaux.valeur }}
                            </td>
                            <td [ngClass]="el.etat ? el.etat.style : ''">
                                {{ el.etat.valeur }}
                            </td>
                            <td [ngClass]="el.ecoulement ? el.ecoulement.style : ''">
                                {{ el.ecoulement.valeur }}
                            </td>
                            <td [ngClass]="el.controle ? el.controle.style : ''">
                                {{ el.controle.valeur }}
                            </td>
                            <td [ngClass]="el.rejet ? el.rejet.style : ''">
                                {{ el.rejet.valeur }}
                            </td>
                            <td>
                                <ng-container *ngFor="let com of el.commentaires">
                                    <div [ngClass]="exclusionRaccordementEP.includes(com.id) ? 'hidden-com' : ''">{{ com.contenu }} <br /></div>
                                </ng-container>
                            </td>
                            <td>
                                <button
                                    mat-icon-button
                                    class="small-button button-group primary"
                                    *ngIf="!readonlyMode && el.commentaires.length"
                                    (click)="onClickEditDisplayedComments(el.commentaires, raccordementEP.code)"
                                >
                                    <mat-icon class="icon-small">edit</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </table>
        </ng-container>
    </div>

    <div class="d-flex flex-row">
        <div class="block-subtitle ml-4 mb-2 flex-grow-1">Équipements des eaux pluviales</div>
        <div class="row mx-2">
            <div class="row legendes align-content-between mr-3">
                <div class="legende row">
                    <div class="conf-1"></div>
                    <div class="conf-2"></div>
                </div>
                <span class="ml-4">Conforme</span>
            </div>
            <div class="row legendes align-content-between mr-3">
                <div class="legende row">
                    <div class="non-conf-1"></div>
                    <div class="non-conf-2"></div>
                </div>
                <span class="ml-4">Non conforme</span>
            </div>
        </div>
    </div>
    <div class="block-content px-5 ml-2 mb-5">
        <ng-container *ngFor="let bien of equipementsEP.biens">
            <div class="block-subtitle-2 mt-4 mb-2">{{ bien.bien }}</div>
            <table>
                <tr>
                    <th>Niveau / Volume</th>
                    <ng-container *ngIf="optionPlan">
                        <th class="plan">Plan</th>
                        <th class="edit-comment"></th>
                    </ng-container>
                    <th>Équipement</th>
                    <th>Odeur</th>
                    <th>État</th>
                    <th>Écoulement fluide</th>
                    <th>Contrôle</th>
                    <th>Rejet conforme</th>
                    <th>Commentaires</th>
                    <th class="edit-comment"></th>
                </tr>

                <tr *ngIf="bien.niveaux.length === 0">
                    <td [attr.colspan]="optionPlan ? '11' : '9'" class="text-center p-4">Aucun point de contrôle</td>
                </tr>

                <ng-container *ngFor="let lv of bien.niveaux">
                    <tr>
                        <td [attr.colspan]="optionPlan ? '11' : '9'" class="row-niveau">
                            {{ lv.niveau }}
                        </td>
                    </tr>

                    <ng-container *ngFor="let vol of lv.volumes">
                        <tr *ngFor="let el of vol.equipements" [ngClass]="el.conformite ? el.conformite.style : ''">
                            <td>
                                <b>{{ vol.volume }}</b>
                            </td>
                            <ng-container *ngIf="optionPlan">
                                <td>
                                    <ng-container *ngFor="let screen of vol.plans"> {{ screen.nom }} <br /> </ng-container>
                                </td>
                                <td>
                                    <button
                                        *ngIf="readonlyMode"
                                        mat-icon-button
                                        class="small-button button-group primary"
                                        (click)="onClickEditPlansList(vol)"
                                    >
                                        <mat-icon class="icon-small">edit</mat-icon>
                                    </button>
                                </td>
                            </ng-container>
                            <td>
                                {{ el.element }}
                            </td>
                            <td [ngClass]="el.odeur ? el.odeur.style : ''">
                                {{ el.odeur.valeur }}
                            </td>
                            <td [ngClass]="el.etat ? el.etat.style : ''">
                                {{ el.etat.valeur }}
                            </td>
                            <td [ngClass]="el.ecoulement ? el.ecoulement.style : ''">
                                {{ el.ecoulement.valeur }}
                            </td>
                            <td [ngClass]="el.controle ? el.controle.style : ''">
                                {{ el.controle.valeur }}
                            </td>
                            <td [ngClass]="el.rejet ? el.rejet.style : ''">
                                {{ el.rejet.valeur }}
                            </td>
                            <td>
                                <ng-container *ngFor="let com of el.commentaires">
                                    <div [ngClass]="exclusionEquipementEP.includes(com.id) ? 'hidden-com' : ''">{{ com.contenu }} <br /></div>
                                </ng-container>
                            </td>
                            <td>
                                <button
                                    mat-icon-button
                                    class="small-button button-group primary"
                                    *ngIf="!readonlyMode && el.commentaires.length"
                                    (click)="onClickEditDisplayedComments(el.commentaires, equipementsEP.code)"
                                >
                                    <mat-icon class="icon-small">edit</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </table>
        </ng-container>
    </div>
    <div class="d-flex flex-row">
        <div class="block-subtitle ml-4 mb-2 flex-grow-1">Toilettes sèches</div>
        <div class="row mx-2">
            <div class="row legendes align-content-between mr-3">
                <div class="legende row">
                    <div class="conf-1"></div>
                    <div class="conf-2"></div>
                </div>
                <span class="ml-4">Conforme</span>
            </div>
            <div class="row legendes align-content-between mr-3">
                <div class="legende row">
                    <div class="non-conf-1"></div>
                    <div class="non-conf-2"></div>
                </div>
                <span class="ml-4">Non conforme</span>
            </div>
        </div>
    </div>

    <div class="block-content px-5 ml-2 mb-5">
        <ng-container *ngFor="let bien of toilettesSeche.biens">
            <div class="block-subtitle-2 mt-4 mb-2">{{ bien.bien }}</div>
            <table>
                <tr>
                    <th>Niveau / Volume</th>
                    <ng-container *ngIf="optionPlan">
                        <th class="plan">Plan</th>
                        <th class="edit-comment"></th>
                    </ng-container>
                    <th>Équipement</th>
                    <th>Adaptation installation</th>
                    <th>Étanchéité</th>
                    <th>Règles épandage</th>
                    <th>Absence nuisance</th>
                    <th>Commentaires</th>
                    <th class="edit-comment"></th>
                </tr>

                <tr *ngIf="bien.niveaux.length === 0">
                    <td [attr.colspan]="optionPlan ? '11' : '9'" class="text-center p-4">Aucun point de contrôle</td>
                </tr>

                <ng-container *ngFor="let lv of bien.niveaux">
                    <tr>
                        <td [attr.colspan]="optionPlan ? '11' : '9'" class="row-niveau">
                            {{ lv.niveau }}
                        </td>
                    </tr>

                    <ng-container *ngFor="let vol of lv.volumes">
                        <tr *ngFor="let el of vol.equipements" [ngClass]="el.conformite ? el.conformite.style : ''">
                            <td>
                                <b>{{ vol.volume }}</b>
                            </td>
                            <ng-container *ngIf="optionPlan">
                                <td>
                                    <ng-container *ngFor="let screen of vol.plans">
                                        {{ screen.nom }} <br />
                                    </ng-container>
                                </td>
                                <td>
                                    <button
                                        *ngIf="readonlyMode"
                                        mat-icon-button
                                        class="small-button button-group primary"
                                        (click)="onClickEditPlansList(vol)"
                                    >
                                        <mat-icon class="icon-small">edit</mat-icon>
                                    </button>
                                </td>
                            </ng-container>
                            <td>
                                {{ el.element }}
                            </td>
                            <td [ngClass]="el.adaptation ? el.adaptation.style : ''">
                                {{ el.adaptation.valeur }}
                            </td>
                            <td [ngClass]="el.etancheite ? el.etancheite.style : ''">
                                {{ el.etancheite.valeur }}
                            </td>
                            <td [ngClass]="el.valorisation ? el.valorisation.style : ''">
                                {{ el.valorisation.valeur }}
                            </td>
                            <td [ngClass]="el.absenceNuisance ? el.absenceNuisance.style : ''">
                                {{ el.absenceNuisance.valeur }}
                            </td>
                            <td>
                                <ng-container *ngFor="let com of el.commentaires">
                                    <div [ngClass]="exclusionToilettesSeche.includes(com.id) ? 'hidden-com' : ''">
                                        {{ com.contenu }} <br />
                                    </div>
                                </ng-container>
                            </td>
                            <td>
                                <button
                                    mat-icon-button
                                    class="small-button button-group primary"
                                    *ngIf="!readonlyMode && el.commentaires.length"
                                    (click)="onClickEditDisplayedComments(el.commentaires, toilettesSeche.code)"
                                >
                                    <mat-icon class="icon-small">edit</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </table>
        </ng-container>
    </div>
</div>
