<div *ngIf="!readonlyMode" class="row w-100 pl-5 pt-3 m-0">
    <mat-form-field appearance="outline" class="w-25 white-search-form-field">
        <mat-icon matPrefix>search</mat-icon>
        <input
            matInput
            type="text"
            placeholder="Rechercher"
            [(ngModel)]="searchVolume"
            (keyup)="onKeyupSearchVolume($event)"
        />
        <button *ngIf="searchVolume" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchVolume()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-25 px-3">
        <mat-label>Type de bien</mat-label>
        <mat-select multiple [(ngModel)]="selectedTypesBien" (ngModelChange)="handleSelectedTypesBienChange()">
            <mat-option *ngFor="let typeBien of listTypesBien" [value]="typeBien.id">
                {{ typeBien.nom }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div *ngIf="!readonlyMode" class="row flex-grow-1 overflow-auto m-0 justify-content-center">
    <mat-card
        *ngFor="let typeVolume of listTypeVolumesToDisplay"
        class="d-flex col-2 piece-card c-pointer"
        (click)="handleAjouterVolume(typeVolume)"
    >
        <mat-card-content class="w-100 h-100">
            <div class="w-100 h-100 d-flex justify-content-center align-items-center">
                {{ typeVolume.nom }}
            </div>
        </mat-card-content>
    </mat-card>
</div>
