<h1 mat-dialog-title class="epi-title">Ajouter une photo</h1>

<div mat-dialog-content>
    <div id="app">
        <div class="BoxVideo">
            <video *ngIf="showVideo" #video id="video" autoplay [style.transform]="'scale(' + zoomScale + ')'"></video>
            <img *ngIf="showCapturedImage" [src]="capturedImage" width="1280" height="720" />
        </div>

        <div class="button-container">
            <!--<div class="flash-button">
                <button mat-raised-button (click)="toggleFlash()" *ngIf="showVideo && !capturing">
                    <mat-icon>{{ flashActive ? 'flash_on' : 'flash_off' }}</mat-icon>
                </button>
            </div>-->
            <div class="zoom-buttons" *ngIf="showVideo && !capturing">
                <button mat-raised-button class="zoom-button" (click)="zoomIn()">
                    <mat-icon>zoom_in</mat-icon>
                </button>
                <button mat-raised-button class="zoom-button" (click)="zoomOut()">
                    <mat-icon>zoom_out</mat-icon>
                </button>
            </div>
            <div class="rotate-button">
                <button mat-raised-button (click)="rotateCamera()" *ngIf="showVideo && !capturing">
                    <mat-icon>switch_camera</mat-icon>
                </button>
            </div>
        </div>
        <button mat-raised-button class="reCapture-button" (click)="ReCapture()" *ngIf="capturing">
            <mat-icon>close</mat-icon>
        </button>

        <div class="center">
            <button mat-raised-button class="equal-size-buttons capture-button" (click)="capture()" *ngIf="!capturing">
                <mat-icon>photo_camera</mat-icon>
                <div>Prendre une photo</div>
            </button>
        </div>
    </div>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-raised-button (click)="confirm()">
        <span>{{ 'Annuler' | uppercase }}</span>
    </button>
    <button mat-raised-button (click)="Enregistrer()">
        <span>{{ 'Enregistrer' | uppercase }}</span>
    </button>
</div>
