/**
 * Type élément à controler.
 */
import { EtatWorkflow } from './etat-workflow.model';
import { MongoUtils } from '../lib/utils/mongo.utils';
import { BaseObject } from './base-object.model';

export const OFFSET_LETTRE = 64;
export enum TypeIncrementation {
    LETTRE = 'LETTRE',
    CHIFFRE = 'CHIFFRE',
    AUCUN = 'AUCUN',
}

export enum TypeParametreCategorieOuvrage {
    STRING = 'STRING',
    BOOLEAN = 'BOOLEAN',
    NUMBER = 'NUMBER',
    LOCALISATION_BOUSSOLE = 'LOCALISATION_BOUSSOLE',
    LOCALISATION_OUVRAGE = 'LOCALISATION_OUVRAGE',
    SIMPLE_CHOICE = 'SIMPLE_CHOICE',
}

export const typeParametreCategorieOuvrageLabel = {
    STRING: 'Text simple',
    BOOLEAN: 'Oui/Non',
    NUMBER: 'Nombre',
    LOCALISATION_BOUSSOLE: 'Boussole',
    LOCALISATION_OUVRAGES: 'Localisation Ouvrages',
    SIMPLE_CHOICE: "Choix simple à partir d'une liste de valeurs",
};

export enum TypeCaracteristiqueCouleur {
    CLAIR = 'Clair',
    FONCE = 'Foncé',
    MAT = 'Mat',
    BRILLANT = 'Brillant',
    TEXTURE = 'Texturé',
}

export class OuvrageAControler {
    /**
     * Identifiant
     */
    public id: string = null;

    /**
     * Désignation
     */
    public nom: string;

    /**
     * Id du object_instance si plan associé
     */
    objectId: string = null;

    public codeCategorieOuvrage: string;

    public partiesOuvrages: OuvrageAControler[] = [];

    public parametres: Record<string, string | number | boolean> = {};

    public codeRevetement: string;

    public nomRevetement: string;

    public codeSubstrat: string;

    public nomSubstrat: string;

    public valeurCouleur: string;

    public nomCouleur: string;

    public valeurCaracteristiqueCouleur: string;

    constructor() {
        // Génère un id aléatoire lorsqu'on instancie un ouvrage à contrôler
        this.id = MongoUtils.generateObjectId();
    }
}
export class CategorieOuvrage {
    public id: string = null;

    public nom: string = null;

    public nomOuvrage: string = null;

    public typeIncrementation: TypeIncrementation;

    public code: string;

    public lienCodeParent: string;

    public etatCategorieOuvrage: EtatWorkflow;

    public parametres: ParametreCategorieOuvrage[] = [];

    public activatedRevetements: boolean = false;

    public revetements: Revetement[] = [];

    public activatedSubstrats: boolean = false;

    public substrats: Substrat[] = [];

    public activatedCouleurs: boolean = false;

    public couleurs: Couleur[] = [];

    public incrementReference?: 'BIEN' | 'NIVEAU' | 'ESPACE' = 'ESPACE';

    constructor() {}
}

export class Revetement extends BaseObject {
    code: string;
}

export class Substrat extends BaseObject {
    code: string;
    codeCnmap: string;
}

export class Couleur extends BaseObject {
    valeur: string;
}

export class ParametreCategorieOuvrage {
    public id: string = null;

    public code: string;

    public label: string;

    public typeParametre: TypeParametreCategorieOuvrage;

    public values: string[];

    constructor() {}
}
export interface OuvrageTmp {
    id: string;
    spaceId: string;
    storeyId: string;
    element:
        | 'beam'
        | 'column'
        | 'pipe'
        | 'wall'
        | 'wall_layer'
        | 'opening'
        | 'opening_frame'
        | 'opening_glazing'
        | 'opening_transom'
        | 'opening_sill'
        | '';

    categorie?: 'generic' | 'inner' | '';
    partie?: string;
    material?: string;
    shape?: string;
    fluid?: string;
    exterior?: boolean;
    outside?: boolean;
    inside?: boolean;
    free?: boolean;
    thickness?: number;
    layerIndex?: number;
    allId?: string;
    width?: number;
    height?: number;
    opening?: string;
    glazing?: string;
    decalage?: number;
    closing?: string;
    transom?: string;
    sill?: string;
    palier: string;
    floor?: boolean;
    ceiling?: boolean;
    floor_side?: boolean;
    ceiling_side?: boolean;
    wall_a?: boolean;
}
export type CategorieOuvrageMapping = Partial<OuvrageTmp> & {
    codeCategorie: string;
    parametres?: Record<
        string,
        {
            code: string;
            valueMapping: Record<string, string>;
        }
    >;
};
