import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PointDeControleBien } from '../../../model/point-de-controle.model';

@Component({
    selector: 'app-point-controle-bien-panel',
    templateUrl: './point-controle-bien-panel.component.html',
    styleUrls: ['./point-controle-bien-panel.component.scss'],
})
export class PointControleBienPanelComponent implements OnInit {
    @Input()
    biens: PointDeControleBien[] = [];

    @Input()
    listVolumeDisabled = [];

    @Input()
    volumesIdDispo = [];

    @Input()
    disabled: Boolean = false;

    @Output()
    panelSubmit: EventEmitter<String[]> = new EventEmitter<String[]>();

    @Output()
    panelCancel: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    listVolumeChecked = [];

    indexBienSelected: number = 0;
    indexNiveauSelected: number = 0;

    totalVolumeNb: number;

    constructor() {}

    ngOnInit(): void {
        //Compte le nombre total de volumes à travers les différents étages
        // utilisé lorsque l'on clique sur 'Tout séléctionner'
        this.totalVolumeNb = this.biens[0].pointsDeControleNiveaux.reduce((total, pdcNiveau) => {
            total += pdcNiveau.pointsDeControleVolumes.length;
            return total;
        }, 0);
    }

    volumeChange(checked: boolean, idVolume: string) {
        //Ajoute l'id du volume à la liste des volumes cochés ou non
        if (checked) {
            this.listVolumeChecked.push(idVolume);
        } else {
            const index = this.listVolumeChecked.indexOf(idVolume);
            if (index > -1) {
                this.listVolumeChecked.splice(index, 1);
            }
        }
    }

    /**
     * Event au click sur Tout sélectionner
     * @param {boolean} checked
     */
    public allCheckChange(checked: boolean) {
        console.log(this.biens[this.indexBienSelected]);

        for (const item of this.biens[this.indexBienSelected].pointsDeControleNiveaux[this.indexNiveauSelected].pointsDeControleVolumes) {
            if (!this.listVolumeDisabled.includes(item.idVolume) && (checked ? !this.listVolumeChecked.includes(item.idVolume) : true)) {
                this.volumeChange(checked, item.idVolume);
            }
        }
        // this.panelSubmit.emit(this.listVolumeChecked);
    }

    navigationVolume(direction) {
        if (direction === 'previous') {
            this.indexNiveauSelected--;
        } else {
            this.indexNiveauSelected++;
        }
    }

    toNumber(number) {
        return parseInt(number);
    }

    cancel() {
        this.panelCancel.emit();
    }

    save() {
        this.panelSubmit.emit(this.listVolumeChecked);
    }
}
