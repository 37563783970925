import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { input } from '../increment-input-reactive/increment-input-reactive.stories';
import { DESC_BIEN_ANNEXE } from 'src/app/shared/constants/names.step.constants';

@Component({
    selector: 'app-texte-modal',
    templateUrl: './add-texte-modal.html',
    styleUrls: ['./add-texte-modal.scss'],
})
export class AddTexteModal implements OnInit {
    texte: string;

    @Input()
    tooltip: string;

    @Input()
    title: string;

    @Input()
    textesPredefinis: string[] = [];

    constructor(public dialogRef: MatDialogRef<AddTexteModal>) {
        dialogRef.disableClose = false;
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth600-dialog');
    }

    onClikAddTextesPredefini(textePredefini: string) {
        if (this.texte) {
            this.texte += textePredefini;
        } else {
            this.texte = textePredefini;
        }
    }

    confirm() {
        this.dialogRef.close(this.texte);
    }
}
