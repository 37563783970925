import { Pipe, PipeTransform } from '@angular/core';
import { BonCommandeAnalyseState, bonCommandeAnalyseStateToLabel } from '../model/bon-commande.model';

/**
 * Affiche le label de l'état d'un bon de commande
 */
@Pipe({
    name: 'bonCommandeAnalyseState',
})
export class BonCommandeAnalyseStatePipe implements PipeTransform {
    transform(bonCommandeAnalyseState: BonCommandeAnalyseState): string {
        return bonCommandeAnalyseStateToLabel(bonCommandeAnalyseState);
    }
}
