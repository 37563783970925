import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';
import { CategorieEquipement } from 'src/app/model/categorie-equipement.model';
import { Equipement } from 'src/app/model/equipement.model';
import { ReferenceApiService } from 'src/app/services/reference-api.service';

@Component({
    selector: 'app-view-liste-equipements',
    templateUrl: './view-liste-equipements.component.html',
    styleUrls: ['./view-liste-equipements.component.scss'],
})
export class ViewListeEquipementsComponent implements OnInit {
    @Output() selectedEquipementEvent = new EventEmitter<Equipement>();
    @Output() createEquipementEvent = new EventEmitter<CategorieEquipement>();

    @Input()
    categorie: CategorieEquipement;

    listeEquipements: Equipement[];
    currentEquipement: Equipement;

    constructor(private readonly referenceApiService: ReferenceApiService) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        this.refresh();
    }

    refresh() {
        if (this.categorie && this.categorie.codeBim && !this.categorie.abstract) {
            this.referenceApiService.findAllEquipementByWorkTypeCodeBim(this.categorie.codeBim).subscribe(
                (listeEquipements) => {
                    this.listeEquipements = listeEquipements;
                },
                (error) => {
                    if (error.status != 404) {
                        console.log(error);
                    } else {
                        throwError(error);
                    }
                },
                () => {}
            );
        } else {
            this.listeEquipements = [];
        }
    }

    selectEquipement(equipement: Equipement) {
        //console.log(equipement);
        this.currentEquipement = equipement;
        this.selectedEquipementEvent.emit(equipement);
    }

    unselectEquipement() {
        this.currentEquipement = null;
    }

    createEquipement() {
        this.createEquipementEvent.emit(this.categorie);
    }
}
