import { Component, Input } from '@angular/core';

@Component({
    selector: 'lib-access-denied',
    templateUrl: './access-denied.component.html',
    styleUrls: ['./access-denied.component.css'],
})
export class AccessDeniedComponent {
    /** Classe CSS sur le conteneur principal */
    @Input() containerClass = '';

    constructor() {}
}
