import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrustHtmlPipe } from './trust-html.pipe';
import { StepPipe } from './step.pipe';
import { ReversePipe } from './reverse.pipe';
import { SmartWordBreakPipe } from './smart-word-break.pipe';
import { IncludesPipe } from './includes.pipe';
import { KeyValuePreserveOrderPipe } from './key-value-preserve-order.pipe';
import { StartsWithPipe } from './starts-with.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [StepPipe, TrustHtmlPipe, ReversePipe, SmartWordBreakPipe, IncludesPipe, KeyValuePreserveOrderPipe, StartsWithPipe],
    exports: [StepPipe, TrustHtmlPipe, ReversePipe, SmartWordBreakPipe, IncludesPipe, KeyValuePreserveOrderPipe, StartsWithPipe],
    providers: [StepPipe, TrustHtmlPipe, ReversePipe, SmartWordBreakPipe, IncludesPipe, KeyValuePreserveOrderPipe, StartsWithPipe],
})
export class LibPipesModule {}
