<app-mpca-form-skeleton>
    <div title>Informations pour META</div>
    <div form>
        <div [formGroup]="formMeta" class="form-meta">
            <!-- FREQUENTATION -->
            <div class="cndiad-form-field">
                <mat-label class="required">Fréquentation</mat-label>
                <app-widget-select
                    [mode]="widgetSelectModeEnum.BADGE"
                    formControlName="frequentation"
                    [disabled]="disabled"
                >
                    <option
                        *ngFor="let item of polluantConfig.contenuListeDeroulante.frequentation.items"
                        [value]="item"
                        datatype="label"
                    >
                        {{ item }}
                    </option>
                </app-widget-select>
            </div>

            <!-- SIMULATION -->
            <div class="cndiad-form-field">
                <mat-label class="required">Simulation</mat-label>
                <app-widget-select
                    [mode]="widgetSelectModeEnum.BADGE"
                    formControlName="simulation"
                    [disabled]="disabled"
                >
                    <option
                        *ngFor="let item of polluantConfig.contenuListeDeroulante.methodeSimulation.items"
                        [value]="item.label"
                        datatype="html"
                    >
                        <mat-icon *ngIf="item.icon != ''" [svgIcon]="item.icon" aria-hidden="false"></mat-icon>
                        {{ item.label }}
                    </option>
                </app-widget-select>
            </div>
        </div>
    </div>
</app-mpca-form-skeleton>
