import { SessionStorageUtils } from './session-storage.utils';

export class SessionStoragePaginationUtils {
    static getSessionStorageItem(key: string) {
        return SessionStorageUtils.getItem(key);
    }

    static getInitialPageSize(key: string, defaultInitialPageSize: number): number {
        const sessionStorageItem = this.getSessionStorageItem(key);
        return sessionStorageItem && sessionStorageItem.pageSize ? sessionStorageItem.pageSize : defaultInitialPageSize;
    }

    static getInitialReseach(key: string): string {
        const sessionStorageItem = this.getSessionStorageItem(key);
        return sessionStorageItem && sessionStorageItem.rechercheLibre ? sessionStorageItem.rechercheLibre : '';
    }

    static getInitialSort(key: string, defaultSort: any) {
        const sessionStorageItem = this.getSessionStorageItem(key);
        return sessionStorageItem && sessionStorageItem.sort ? sessionStorageItem.sort : defaultSort;
    }

    static setSessionStorageItem(key: string, pageSize: number, rechercheLibre: string, sort: any) {
        SessionStorageUtils.setItem(key, {
            pageSize: pageSize,
            rechercheLibre: rechercheLibre,
            sort: sort,
        });
    }
}
