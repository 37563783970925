import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';

@Component({
    selector: 'app-document-file-uploader',
    templateUrl: './document-file-uploader.component.html',
    styleUrls: ['./document-file-uploader.component.scss'],
})
export class DocumentFileUploaderComponent extends BaseComponent implements OnInit {
    @Input() nomFichier: string;
    @Output() fileDeleted = new EventEmitter();

    constructor() {
        super();
    }

    ngOnInit(): void {}
}
