import { Pipe, PipeTransform } from '@angular/core';

/**
 * Inverse l'ordre d'un tableau.
 */
@Pipe({ name: 'reverse' })
export class ReversePipe implements PipeTransform {
    constructor() {}

    transform(value: any[]) {
        return value ? value.slice().reverse() : [];
    }
}
