<h1 mat-dialog-title class="text-center">{{ appName }} © {{ year }}</h1>

<div mat-dialog-content>
    <div class="text-center">
        <h2 class="mt-2 text-left">
            Version application : <span class="font-weight-light font-italic">{{ appVersion }}</span>
        </h2>

        <h2 class="mt-2 text-left">
            Version API : <span class="font-weight-light font-italic">{{ apiVersion }}</span>
        </h2>

        <div class="mt-4 mat-h2">
            <a (click)="showChangelog()" href="javascript:void(0)">Journal des modifications</a>
        </div>
        <div class="mt-2 mat-h2">
            <a (click)="showLegal()" href="javascript:void(0)">Mentions légales</a>
        </div>

        <div class="mt-5">
            <a href="http://www.enerbim.com" target="_blank">EnerBIM</a>
        </div>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="close()">
        <mat-icon>close</mat-icon>
        <span>Fermer</span>
    </button>
</div>
