import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EtapeDiagnosticGenerique } from '../../../../../../model/diagnostic-etape.model';
import { first } from 'rxjs/operators';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { CeeModel, Chapitre, ISolution, PointControle, Reponse, TypeCee } from '../../../model/cee.model';
import { combineLatest } from 'rxjs';
import { CeeService } from '../../../services/cee.service';
import { TYPE_ISOLANT } from '../../../shared/cee.constants';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { CommentModalComponent } from '../../../../../shared/comment-modal/comment-modal.component';
import { DialogUtils } from '../../../../../../utils/dialog.utils';
import { MatDialog } from '@angular/material/dialog';
import { enumTypesCommentaire } from 'src/app/model/type-commentaire.model';

@Component({
    selector: 'app-dialog-solution-isolation',
    templateUrl: './dialog-solution-isolation.component.html',
    styleUrls: ['../points-de-controles.component.scss'],
})
export class DialogSolutionIsolationComponent {
    ceeModel: CeeModel;

    @Input() readonlyMode: boolean;
    @Input() titre: string = 'Ajouter une solution facturée';
    @Input() nomChapitre: string = null;
    @Input() valeurAControler: string; // BARTH161
    @Input() set valeurAControlerReel(v: string) {
        this._valeurAControlerReel = v;
        this.outValidityForm.emit(this.getValidity());
    } // BARTH161
    get valeurAControlerReel() {
        return this._valeurAControlerReel;
    }
    @Input() set solution(pSolution: ISolution) {
        this._solution = pSolution;
        console.log(pSolution);
        combineLatest([this.diagnosticService.getCurrentDiagnostic(), this.ceeService.ceeConfig$])
            .pipe(first())
            .subscribe(([diagnostic, config]) => {
                this.diagnostic = diagnostic;
                this.ceeModel = new CeeModel(diagnostic.contenuDiagnostic as CeeModel);
                const typeCee: TypeCee = config.typesCee.find((type) => type.description == diagnostic.typePrestation);
                const racine0: Chapitre = config.chapitres.find(
                    (ch) => ch.idTypeCee == typeCee.idInterne && (this.nomChapitre ? ch.description === this.nomChapitre : true)
                );
                const racine01: Chapitre = config.chapitres.find((ch) => ch.idChapitreParent == racine0.idInterne);
                const idChapitre = racine01 ? racine01.idInterne : racine0.idInterne;
                this.pointsDeControleGlobal = config.pointsControles.filter((p) => p.idChapitre == idChapitre);
                this.allPointsDeControle = config.pointsControles;
                this.chapitresBase = config.chapitres.filter((ch) => ch.idChapitreParent == idChapitre);
                this.solution.infoIsolant = this.solution.infoIsolant ? this.solution.infoIsolant : [];
                const ptc = this.pointsDeControleGlobal.find((p) => p.description == TYPE_ISOLANT);
                console.log(this.pointsDeControleGlobal);
                if (ptc) {
                    const lValue = this.solution.infoIsolant.find((value) => value.id == ptc.idInterne);
                    if (lValue) {
                        this.onChangeValue(lValue, false);
                    }
                }
            });
        this.outValidityForm.emit(this.getValidity());
    }
    get solution(): ISolution {
        return this._solution;
    }
    @Output() outValidityForm: EventEmitter<boolean> = new EventEmitter<boolean>();
    public diagnostic: Diagnostic;
    private _solution: ISolution;
    private _valeurAControlerReel: string; // BARTH161
    public pointsDeControleGlobal: PointControle[] = [];
    public pointsDeControlByTypeIsolant: PointControle[] = [];
    public allPointsDeControle: PointControle[];
    public chapitresBase: Chapitre[];

    constructor(private matDialog: MatDialog, private readonly ceeService: CeeService, private readonly diagnosticService: DiagnosticService) {}

    /**
     * Vérif validité form
     * return true, Si tous les point de controles sont répondu
     * false sinon
     */

    getValidity(): boolean {
        if (this.ceeService.isCheckPointsValid(this.solution?.infoIsolant, this.ceeModel)) {
            for (const ptc of this.solution?.infoIsolant) {
                const checkpoint = [...this.pointsDeControlByTypeIsolant, ...this.pointsDeControleGlobal].find((p) => p.idInterne === ptc.id);
                if (this.needJustification(checkpoint) && !ptc.commentairesId?.length) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    public getPointDeControle(pPointControle: PointControle): EtapeDiagnosticGenerique {
        const lValue: EtapeDiagnosticGenerique = this.solution.infoIsolant.find((value) => value.id == pPointControle.idInterne);
        if (lValue) {
            return lValue;
        }
        const newValue = new EtapeDiagnosticGenerique();
        newValue.id = pPointControle.idInterne;
        newValue.disabled = pPointControle.isGrey;
        this.solution.infoIsolant.push(newValue);
        return newValue;
    }

    onChangeValue(pValue: EtapeDiagnosticGenerique, isChangedFromUser: boolean = true) {
        const pointDeControle = this.pointsDeControleGlobal.find((p) => p.idInterne == pValue.id);
        if (pointDeControle && pointDeControle.description == TYPE_ISOLANT) {
            const reponse: Reponse = pointDeControle.lstChoixReponse.find((rep) => rep.code == pValue.valeur);
            if (!reponse) return;
            const chapitre = this.chapitresBase.find(
                (ch) => reponse.description == ch.description && ch.idChapitreParent === pointDeControle.idChapitre
            );
            const pointsDeControle = chapitre ? this.allPointsDeControle.filter((ptc) => ptc.idChapitre == chapitre.idInterne) : [];
            this.pointsDeControlByTypeIsolant = pointsDeControle;
            if (isChangedFromUser) {
                const idsChzckPointGlobal = this.pointsDeControleGlobal.map((ptc) => ptc.idInterne);
                this.solution.infoIsolant = this.solution.infoIsolant.filter((value) => idsChzckPointGlobal.includes(value.id));
            }
        }
        this.outValidityForm.emit(this.getValidity());
    }

    /**
     * En fonction de la config un point de contrôle aurait besoin de justification
     * return le message d'erreur sinon null
     * @param checkpoint
     */
    needJustification(checkpoint: PointControle) {
        if (checkpoint) {
            const value = this.getPointDeControle(checkpoint);
            for (const rep of checkpoint.lstChoixReponse || []) {
                if (rep.needJustification && rep.code === value?.valeur) {
                    return rep.phraseJustification;
                }
            }
            if (checkpoint.idInterne === 'PC430') {
                // JUSTIFICATION_NON_CONFORMITE
                if (value.valeur && +this.valeurAControlerReel !== +value.valeur && !value.commentairesId?.length) {
                    return new String();
                }
            }
        }

        return null;
    }

    public getAnomalieFromCheckpoint(checkpoint: PointControle): boolean {
        return !!this.ceeService.getAnomalieFromCheckpoint(this.getPointDeControle(checkpoint), this.ceeModel);
    }

    getTypeCommentaire(checkpoint: PointControle): string {
        if (this.getAnomalieFromCheckpoint(checkpoint) || checkpoint.idInterne === 'PC430') {
            return enumTypesCommentaire.JUSTIFICATION_NON_CONFORMITE;
        }
        if (this.needJustification(checkpoint)) {
            return enumTypesCommentaire.CONSTATATION_DIVERSE;
        }
        return '';
    }

    addJustification(pointDeControle: PointControle) {
        const value = this.getPointDeControle(pointDeControle);
        this.matDialog
            .open(CommentModalComponent, {
                ...DialogUtils.configFullScreen(),
                data: {
                    commentairesId: value?.commentairesId,
                    replaceTypesCommentaires: true,
                    typesCommentaires: [
                        enumTypesCommentaire.JUSTIFICATION_NON_CONFORMITE,
                        enumTypesCommentaire.NOTE_PERSONNELLE,
                        enumTypesCommentaire.REMARQUE,
                        enumTypesCommentaire.CONSTATATION_DIVERSE,
                    ],
                    typeNouveauCommentaire: this.getTypeCommentaire(pointDeControle),
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result && result !== false) {
                    value.commentairesId = result.commentairesId;
                }
                this.outValidityForm.emit(this.getValidity());
            });
    }
}
