import { Component, Input } from '@angular/core';
import { ReportagePhotoImageFile } from '../../../../../../model/diagnostic.model';

@Component({
    selector: 'app-reportage-photo-edit-preview-page',
    templateUrl: './reportage-photo-edit-preview-page.component.html',
    styleUrls: ['./reportage-photo-edit-preview-page.component.scss'],
})
export class ReportagePhotoEditPreviewPageComponent {
    @Input() imagesFilesPage: ReportagePhotoImageFile[] = [];
    @Input() titreReportagePhoto = '';

    getAnomalie(imageFilePage: ReportagePhotoImageFile) {
        return imageFilePage.nonConformiteReference ? imageFilePage.nonConformiteReference : '';
    }

    getReference(imageFilePage: ReportagePhotoImageFile): string {
        return imageFilePage.objetReference ? imageFilePage.objetReference : '';
    }

    getLocalisation(imageFilePage: ReportagePhotoImageFile): string {
        if (imageFilePage.localisations.length <= 0) {
            return '';
        }
        let ret: string = '(';
        imageFilePage.localisations.forEach((localisation) => {
            if (ret != '(') {
                ret += ' - ';
            }
            ret += localisation;
        });

        return ret + ')';
    }
}
