<h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    {{ data.modalLabel || 'Saisie de la surface' }} :
</h2>
<mat-dialog-content>
    <form [formGroup]="form">
        <div class="input">
            <mat-label class="col-sm-6">{{ data.formLabel || 'Saisie de la surface' }}</mat-label>
            <app-increment-input-reactive
                class="col-sm-6"
                formControlName="value"
                data-cy="value"
                [incrementControl]="form.get('value')"
                [min]="0"
                [step]="1"
                [numericKeyboard]="true"
                (valueChange)="valueChange()"
            >
            </app-increment-input-reactive>
            <mat-checkbox class="mr-1" formControlName="automatique" (change)="onChangeAutoVal($event, 'mesure2')"
                >Automatique</mat-checkbox
            >
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="onCancelClick()">Annuler</button>
    <button mat-button (click)="onConfirmClick()" [disabled]="!form.valid">Valider</button>
</mat-dialog-actions>
