<h1 mat-dialog-title class="primary">
    <span *ngIf="mode === 'CREATE'">Création d'un paramètre</span>
    <span *ngIf="mode === 'EDIT'">Edition d'un paramètre</span>
</h1>

<div mat-dialog-content>
    <div class="w-100 pr-2 pt-2" [formGroup]="formParam">
        <!--<h2>Paramètre</h2>-->

        <!-- nom du paramètre -->
        <div class="d-flex p-2 align-items-center">
            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Nom du paramètre</div>
            <div class="col-xl-8 pr-2">
                <mat-form-field class="w-100">
                    <input matInput type="text" formControlName="name" />
                    <mat-error *ngIf="formParam.get('name').hasError('erreurNameParametre')">Un autre paramètre possède déjà le même nom. </mat-error>
                    <app-mat-error [control]="formParam.get('name')"> </app-mat-error>
                </mat-form-field>
            </div>
        </div>

        <!-- codeBim du paramètre -->
        <div class="d-flex p-2 align-items-center">
            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Code bim du paramètre</div>
            <div class="col-xl-8 pr-2">
                <mat-form-field class="w-100">
                    <input matInput type="text" formControlName="codeBim" />
                    <mat-error *ngIf="formParam.get('codeBim').hasError('erreurCodeBimParametre')">
                        Un autre paramètre possède déjà le même code bim.
                    </mat-error>
                    <app-mat-error [control]="formParam.get('codeBim')"> </app-mat-error>
                </mat-form-field>
            </div>
        </div>

        <!-- Groupe de paramètre -->
        <div class="d-flex p-2 align-items-center">
            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Groupe du paramètre</div>
            <div class="col-xl-8 p-2">
                <app-custom-select
                    class="w-100"
                    formControlName="group"
                    [selectControl]="formParam.get('group')"
                    [matFormFieldClass]="'w-100'"
                    [choices]="listeGroups"
                    [pathValueDisplay]="['name']"
                    [addline]="false"
                    [filter]="false"
                >
                </app-custom-select>
                <app-mat-error [control]="formParam.get('type')"> </app-mat-error>
            </div>
        </div>

        <!-- Description de l'equipement  -->
        <div class="d-flex p-2 align-items-center">
            <mat-form-field class="w-100 mb-0" appearance="outline">
                <mat-label>Description du paramètre</mat-label>
                <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" formControlName="description"></textarea>
            </mat-form-field>
        </div>

        <!-- Type du paramètre -->
        <div class="d-flex p-2 align-items-center">
            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Type de valeur du paramètre</div>
            <div class="col-xl-8 p-2">
                <app-custom-select
                    class="w-100"
                    formControlName="type"
                    [selectControl]="formParam.get('type')"
                    [matFormFieldClass]="'w-100'"
                    [choices]="listeType"
                    [addline]="false"
                    (selectedValueChange)="changeType($event)"
                >
                </app-custom-select>
                <app-mat-error [control]="formParam.get('type')"> </app-mat-error>
            </div>
        </div>

        <!-- Champ de saisie de la valeur par défaut en fonction du type du paramètre -->
        <div class="d-flex p-2 align-items-center">
            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Valeur par défaut du paramètre</div>
            <div class="col-xl-8 p-2">
                <ng-container *ngIf="typeSelected == 'TEXTE' || typeSelected == 'COULEUR' || typeSelected == 'URL'">
                    <mat-form-field class="align-with-increment-input">
                        <mat-label>{{ currentParameter?.name }}</mat-label>
                        <input matInput type="text" formControlName="parameterValue" />
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="typeSelected == 'LISTE_CHOIX'">
                    <div class="col-xl-8">
                        <app-custom-select
                            class="w-100"
                            formControlName="parameterValue"
                            [selectControl]="formParam.get('parameterValue')"
                            [matFormFieldClass]="'w-100'"
                            [choices]="currentParameter?.choices"
                            [addline]="true"
                            [filter]="false"
                            [placeholder]="false"
                            (selectedValueChange)="changeChoices($event)"
                        >
                        </app-custom-select>
                        <app-mat-error [control]="formParam.get('parameterValue')"> </app-mat-error>
                    </div>
                </ng-container>
                <ng-container *ngIf="typeSelected == 'BOOLEEN'">
                    <mat-form-field class="align-with-increment-input">
                        <mat-label>{{ currentParameter?.name }}</mat-label>
                        <mat-select formControlName="parameterValue">
                            <mat-option [value]="undefined"><i>indéfini</i></mat-option>
                            <mat-option [value]="true">Oui</mat-option>
                            <mat-option [value]="false">Non</mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="typeSelected == 'NOMBRE'">
                    <lib-increment-input
                        [(value)]="valuesParameter[currentParameter?.codeBim]"
                        [step]="currentParameter?.numberDefinitions.nbDecimals | step"
                        [min]="currentParameter?.numberDefinitions.min"
                        [max]="currentParameter?.numberDefinitions.max"
                    ></lib-increment-input>
                </ng-container>
                <ng-container *ngIf="typeSelected == 'DATE'">
                    <mat-form-field class="align-with-increment-input date-field">
                        <mat-label>{{ currentParameter?.name }}</mat-label>
                        <input type="date" matInput formControlName="parameterValue" />
                    </mat-form-field>
                </ng-container>
            </div>
        </div>

        <div mat-dialog-actions class="justify-content-end">
            <button mat-button color="primary" (click)="onClickCancel()">
                <lib-icon>close</lib-icon>
                <span>Annuler</span>
            </button>

            <button
                mat-raised-button
                color="accent"
                [disabled]="formParam.invalid"
                (click)="onClickSaveModification()"
                data-cy="submit-prestation-button"
            >
                <lib-icon>check</lib-icon>
                <span>Confirmer</span>
            </button>
        </div>
    </div>
</div>
