import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { ConfirmationService } from './confirmation.service';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [CommonModule, MatIconModule, MatDialogModule, MatButtonModule, MatCheckboxModule, FormsModule],
    declarations: [ConfirmDialogComponent],
})
export class LibConfirmationModule {
    static forRoot(): ModuleWithProviders<LibConfirmationModule> {
        return {
            ngModule: LibConfirmationModule,
            providers: [ConfirmationService],
        };
    }
}
