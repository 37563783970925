'use strict';
//***********************************************************************************
//***********************************************************************************
//**** cn_svg_tool_slabs  : Manipulation of floor slabs
//***********************************************************************************
//***********************************************************************************

import { cn_svg_tool_creation } from './cn_svg_tool_creation';
import { cn_camera } from './cn_camera';
import { cn_thermal_model } from '../utils/exports/cn_thermal_model';
import { cn_element } from '../model/cn_element';
import { cn_storey } from '../model/cn_storey';
import { cn_wall_type } from '../model/cn_wall_type';
import { cn_slab } from '../model/cn_slab';
import { cn_roof_opening_type } from '../model/cn_roof_opening_type';

export class cn_thermal_type {
    /**
     *
     * @param {string} code
     * @param {string} label
     */
    constructor(code, label) {
        this.id = 'cnui' + cn_element.generate_ID();
        this.code = code;
        this.label = label;
        this.active = true;
        this.number = 0;
        this.children = [];
        this.parent = null;
    }

    add_child(c) {
        c.parent = this;
        this.children.push(c);
    }

    increment() {
        for (var p = this; p; p = p.parent)
            p.number++;
    }

    find_by_code(code, recursive = false) {
        var res = this.children.find(t => t.code == code);
        if (res || !recursive) return res;
        for (var k in this.children) {
            res = this.children[k].find_by_code(code, true);
            if (res) return res;
        }
        return null;
    }
}

/**
 * Events :
 */
export class cn_svg_tool_thermal extends cn_svg_tool_creation {
    //***********************************************************************************
    /**
     * Constructor
     */
    constructor() {
        super(null);

        this._thermal_analysis_date = 0;
        this._thermal_analysis = null;

        this.thermal_types = [];

        this.draw_mode = 'walls';

        this.opacity = 0.5;

        this.storey_display = false;
    }

    //***********************************************************************************
    /**
     * Open tool
     */
    open_tool() {
        super.open_tool();
        this._thermal_analysis = null;
        this._update_thermal_analysis();
    }

    find_thermal_type(code, recursive = false) {
        var res = this.thermal_types.find(t => t.code == code);
        if (res || !recursive) return res;
        for (var k in this.thermal_types) {
            res = this.thermal_types[k].find_by_code(code, true);
            if (res) return res;
        }
        return null;
    }

    _build_thermal_type(code, label) {
        const previous = this.find_thermal_type(code, true);
        const tt = new cn_thermal_type(code, label);
        if (previous) tt.active = previous.active;
        return tt;
    }

    _update_thermal_analysis() {

        if (this._thermal_analysis && this._thermal_analysis_date >= this._building._date) return;

        //*** Build thermal model */
        this._thermal_analysis = new cn_thermal_model(this._building);
        this._thermal_analysis.analyse();
        this._thermal_analysis_date = this._building._date;

        //*** Build thermal types for walls */
        const new_thermal_types = [];
        var wall_types = this._build_thermal_type('walls', 'Parois');
        new_thermal_types.push(wall_types);

        const vertical_walls = this._build_thermal_type('vertical_walls', 'Verticales');
        wall_types.add_child(vertical_walls);
        const horizontal_walls = this._build_thermal_type('horizontal_walls', 'Horizontales');
        wall_types.add_child(horizontal_walls);

        this._thermal_analysis.walls.forEach(wall => {
            const cat = (wall.vertical) ? vertical_walls : horizontal_walls;
            var wc = cat.find_by_code(wall.contact_type.code);
            if (!wc) {
                wc = this._build_thermal_type(wall.contact_type.code, wall.contact_type.label);
                cat.add_child(wc);
            }
            wall['thermal_type'] = wc;
            wc.increment();
        });

        //*** Build thermal types for bridges */
        var connexion_types = this._build_thermal_type('connexions', 'Ponts thermiques');
        new_thermal_types.push(connexion_types);

        const vertical_connexions = this._build_thermal_type('vertical_walls', 'Verticaux');
        connexion_types.add_child(vertical_connexions);
        const horizontal_connexions = this._build_thermal_type('horizontal_walls', 'Horizontaux');
        connexion_types.add_child(horizontal_connexions);

        this._thermal_analysis.bridges.forEach(bridge => {
            const cat = (bridge.vertical) ? vertical_connexions : horizontal_connexions;
            var wc = cat.find_by_code(bridge.connexion_type.code);
            if (!wc) {
                wc = this._build_thermal_type(bridge.connexion_type.code, bridge.connexion_type.label);
                cat.add_child(wc);
            }
            bridge['thermal_type'] = wc;
            wc.increment();
        });

        //*** Build thermal types for spaces */
        var zones = this._build_thermal_type('spaces', 'Espaces');
        new_thermal_types.push(zones);

        this._thermal_analysis.zones.forEach(zone => {
            var z = zones.find_by_code(zone.element.ID);
            if (!z) {
                z = this._build_thermal_type(zone.element.ID, (zone.element.constructor == cn_storey) ? zone.element.short_name : zone.element.name);
                zones.add_child(z);
            }
            zone.spaces.forEach(space => {
                var s = this._build_thermal_type(space.storey_space.space.ID + space.storey_space.storey.ID, space.storey_space.space.get_name(space.storey_space.storey));
                z.add_child(s);
                space['thermal_type'] = s;
                s.increment();
            });

        });

        //*** Build thermal types for wall types */
        var type_list = this._build_thermal_type('types', 'Typologies');
        new_thermal_types.push(type_list);

        var wall_type_list = this._build_thermal_type('wall_types', 'Types de parois');
        type_list.add_child(wall_type_list);

        const vertical_wall_types = this._build_thermal_type('vertical_wall_types', 'Murs');
        wall_type_list.add_child(vertical_wall_types);
        const horizontal_wall_types = this._build_thermal_type('horizontal_wall_types', 'Dalles');
        wall_type_list.add_child(horizontal_wall_types);
        const roof_wall_types = this._build_thermal_type('roof_wall_types', 'Toitures');
        wall_type_list.add_child(roof_wall_types);

        var opening_type_list = this._build_thermal_type('opening_types', 'Types de baies');
        type_list.add_child(opening_type_list);

        const window_types = this._build_thermal_type('window_types', 'Fenêtres');
        opening_type_list.add_child(window_types);
        const door_types = this._build_thermal_type('door_types', 'Portes');
        opening_type_list.add_child(door_types);
        const roof_opening_types = this._build_thermal_type('roof_opening_types', 'Baies de toiture');
        opening_type_list.add_child(roof_opening_types);

        this._thermal_analysis.walls.forEach(wall => {
            const wt = wall.element_type;
            const cnw = (wall.storey_element) ? wall.storey_element.element : null;
            if (wt && cnw) {
                const cat = (wt.constructor == cn_wall_type) ? vertical_wall_types : (cnw.constructor == cn_slab) ? horizontal_wall_types : roof_wall_types;
                var wc = cat.find_by_code(wt.ID);
                if (!wc) {
                    wc = this._build_thermal_type(wt.ID, wt.get_label());
                    cat.add_child(wc);
                }
                wall.element_type['thermal_type'] = wc;
                wc.increment();
            }
            wall.openings.filter(opening => opening.element_type).forEach(opening => {
                const ot = opening.element_type;
                if (ot) {
                    const cat = (ot.constructor == cn_roof_opening_type) ? roof_opening_types : (ot.category == 'window') ? window_types : door_types;
                    var oc = cat.find_by_code(ot.ID);
                    if (!oc) {
                        oc = this._build_thermal_type(ot.ID, ot.get_label());
                        cat.add_child(oc);
                    }
                    ot['thermal_type'] = oc;
                    oc.increment();
                }
            });
        });


        //*** Build thermal types for storeys (DEMO onbly) */
        if (this.storey_display) {
            var storey_list = this._build_thermal_type('storeys', 'Etages');
            new_thermal_types.push(storey_list);
            this._building.storeys.forEach(storey => {
                var storey_elt_list = this._build_thermal_type('storey_' + storey.storey_index, 'Etage ' + storey.storey_index);
                storey_list.add_child(storey_elt_list);
                storey_elt_list.add_child(this._build_thermal_type(`storey_facing_volume_${storey.storey_index}`, "facing volume"));
                storey_elt_list.add_child(this._build_thermal_type(`storey_facing_volume_no_dormers_${storey.storey_index}`, "facing volume no dormers"));
                storey_elt_list.add_child(this._build_thermal_type(`storey_thermal_volume_${storey.storey_index}`, "thermal_volume"));
                storey_elt_list.add_child(this._build_thermal_type(`storey_space_volume_${storey.storey_index}`, "space_volume"));
                storey_elt_list.add_child(this._build_thermal_type(`storey_space_volume_no_dormers_${storey.storey_index}`, "space_volume_no_dormers"));
                storey_elt_list.children.forEach(c => c.increment());
            });
        }

        this.thermal_types = new_thermal_types;
    }

    /**
     * Returns all thermal types present in the model
     * @returns {Array<cn_thermal_type>}
     */
    get_thermal_types() {
        this._update_thermal_analysis();
        return this.thermal_types;
    }

    /**
     * Sets draw mode. Value can be one code of the root thermal types
     * @param {string} value
     */
    set_draw_mode(value) {
        this.draw_mode = value;
        this.refresh();
    }

    /**
     * returns draw mode. Value can be one code of the root thermal types
     * @returns {string}
     */
    get_draw_mode() {
        return this.draw_mode;
    }

    set_opacity(value) {
        this.opacity = value;
        this.refresh();
    }

    get_opacity() {
        return this.opacity;
    }

    //***********************************************************************************
    /**
     * SVG rendering
     * @param {cn_camera} camera
     * @returns {string} svg rendered
     */
    draw(camera) {
        var html = '';

        this._update_thermal_analysis();

        html += `<rect x="0" y="0" width="${camera._width}" height="${camera._height}" fill="white" fill-opacity="${this.opacity}" />`;

        html += `<g opacity="${(this.opacity > 0.5) ? 1 : 2 * this.opacity}">`;

        if (this.draw_mode == 'spaces') {
            //*** Draw thermal spaces */
            this._thermal_analysis.spaces.filter(space => space['thermal_type'].active).forEach(space => {
                var draw_class = 'thermal_space';
                if (!space.storey_space.space.is_heated()) draw_class += ' non_heated';
                html += camera.draw_solid(space.solid, draw_class);
            });

        }
        if (this.draw_mode == 'walls') {
            //*** Draw thermal walls */
            this._thermal_analysis.walls.filter(wall => wall['thermal_type'].active).forEach(wall => {
                html += camera.draw_polygon(wall.polygon, 'thermal_wall ' + wall['thermal_type'].code);
                wall.openings.forEach(op => {
                    html += camera.draw_polygon(op.polygon, 'thermal_opening');
                });
            });
        }

        if (this.draw_mode == 'connexions') {
            //*** Draw thermal bridges */
            this._thermal_analysis.bridges.filter(bridge => bridge['thermal_type'].active).forEach(bridge => {
                const p0 = camera.world_to_screen(bridge.p0);
                const p1 = camera.world_to_screen(bridge.p1);
                if (p0 && p1) {
                    var draw_class = 'thermal_bridge';
                    if (!bridge.lossy) draw_class += ' non_lossy';
                    html += `<line x1="${p0[0]}" y1="${p0[1]}" x2="${p1[0]}" y2="${p1[1]}" class="${draw_class}" />`;
                }
            });
        }

        if (this.draw_mode == 'types') {
            //*** Draw thermal types */
            this._thermal_analysis.walls.forEach(wall => {
                if (wall.element_type && wall.element_type['thermal_type'].active)
                    html += camera.draw_polygon(wall.polygon, 'thermal_wall ' + wall['thermal_type'].code);
                wall.openings.forEach(op => {
                    if (op.element_type && op.element_type['thermal_type'].active)
                        html += camera.draw_polygon(op.polygon, 'thermal_opening ' + op.element_type['thermal_type'].parent.code);
                });
            });
        }

        function display_volume(storey, volume, storey_elt_list) {
            if (!storey[volume] || !storey_elt_list) return;
            const elt = storey_elt_list.find_by_code(`storey_${volume}_${storey.storey_index}`);
            if (elt && elt.active) html += camera.draw_solid(storey[volume], 'thermal_space', storey.altitude);
        }

        if (this.storey_display) {
            var storey_list = this.find_thermal_type('storeys');
            if (this.draw_mode == 'storeys') {
                this._building.storeys.forEach(st => {
                    const storey_elt_list = storey_list.find_by_code(`storey_${st.storey_index}`);
                    display_volume(st, "facing_volume", storey_elt_list);
                    display_volume(st, "facing_volume_no_dormers", storey_elt_list);
                    display_volume(st, "thermal_volume", storey_elt_list);
                    display_volume(st, "space_volume", storey_elt_list);
                    display_volume(st, "space_volume_no_dormers", storey_elt_list);
                });
            }
        }

        html += '</g>'
        html += super.draw(camera);

        return html;
    }


    //***********************************************************************************
    /**
     * click management
     * @param {object} ev
     * @returns {boolean} true if click was used
     */
    click(ev) {
        return false;
    }

    //***********************************************************************************
    /**
     * passive move management
     * @param {object} ev
     * @returns {boolean} true if passive move was used
     */
    move(ev) {
        return false;
    }

    grab(ev) {
        return false;
    }
}

