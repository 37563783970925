import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-orientation-dialog',
    templateUrl: './orientation-dialog.component.html',
    styleUrls: ['./orientation-dialog.component.scss'],
})
export class OrientationDialogComponent {
    orientation: string;
    precisionOrientation: string;

    constructor(public dialogRef: MatDialogRef<OrientationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.dialogRef.disableClose = true;

        this.orientation = data.orientation;
        this.precisionOrientation = data.precisionOrientation;
    }
    onCancelClick(): void {
        this.dialogRef.close({});
    }

    selectOrientation(orientation: string) {
        this.orientation = orientation;
    }

    onConfirmClick(): void {
        this.dialogRef.close({
            orientation: this.orientation,
            precisionOrientation: this.precisionOrientation,
        });
    }

    onChangePrecision(event) {
        this.precisionOrientation = event.target.value;
    }

    decimalNumberValidator() {
        return (control) => {
            const pattern = /^[0-9]+(\.[0-9]+)?$/;
            if (control.value && !pattern.test(control.value)) {
                return { invalidNumber: true };
            }
            return null;
        };
    }
}
