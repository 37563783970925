<div class="toolbar">
    <mat-toolbar class="border-bottom-1 primary">
        <div class="nav-bar-tableau-de-bord d-flex">
            <div>
                <lib-icon [class]="'icon-before-text'">info</lib-icon>
                <span>Informations sur l'application</span>
            </div>
        </div>
    </mat-toolbar>
</div>
<div class="flex-grow-1 main-background overflow-auto">
    <div class="container">
        <div class="row py-4">
            <!-- Colonne gauche-->
            <div class="col-12">
                <div class="mat-h3 bold mb-2 primary">
                    {{ 'Versions' | uppercase }}
                </div>
            </div>
            <div class="col-4">
                <mat-card class="mb-4 p-2">
                    <mat-card-content>
                        <div class="card-title">Application</div>
                        <div class="card-subtitle-bold">{{ appVersion }}</div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-4">
                <mat-card class="mb-4 p-2">
                    <mat-card-content>
                        <div class="card-title">API</div>
                        <div class="card-subtitle-bold">{{ apiVersion$ | async }}</div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-4">
                <mat-card class="mb-4 p-2">
                    <mat-card-content>
                        <div class="card-title">CnMap</div>
                        <div class="card-subtitle-bold">CnMap-Angular : {{ cnmapAngularVersion }}</div>
                        <div class="card-subtitle-bold">CnMap-Editor : {{ cnmapVersion }}</div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-12">
                <div class="mat-h3 bold mb-2 primary">
                    {{ 'Journal des modifications' | uppercase }}
                </div>
            </div>
        </div>
        <mat-card>
            <div class="d-flex flex-column">
                <div class="d-flex align-items-center">
                    <mat-form-field class="flex-grow-1">
                        <mat-label>Rechercher</mat-label>
                        <input matInput (keyup)="reserchFilter($event)" #input />
                    </mat-form-field>
                    <mat-form-field class="ml-3" style="flex-basis: 30%">
                        <mat-label>Filtre</mat-label>
                        <mat-select [(value)]="selectedValue" (selectionChange)="applyFilter()">
                            <mat-option *ngFor="let row of filtre" [value]="row.value">
                                {{ row.viewValue }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="Version">
                        <th mat-header-cell *matHeaderCellDef class="header-text-bold" mat-sort-header>Version</th>
                        <td mat-cell *matCellDef="let row">{{ row.Version }}</td>
                    </ng-container>
                    <ng-container matColumnDef="Date">
                        <th mat-header-cell *matHeaderCellDef class="header-text-bold" mat-sort-header>Date</th>
                        <td mat-cell *matCellDef="let row">{{ row.Date }}</td>
                    </ng-container>
                    <ng-container matColumnDef="Prestation">
                        <th mat-header-cell *matHeaderCellDef class="header-text-bold" mat-sort-header matTableFilter>
                            Prestation/Catégorie
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.Prestation }}</td>
                    </ng-container>
                    <ng-container matColumnDef="RefTicket">
                        <th mat-header-cell *matHeaderCellDef class="header-text-bold">Ref Ticket</th>
                        <td mat-cell *matCellDef="let row">{{ row.RefTicket }}</td>
                    </ng-container>
                    <ng-container matColumnDef="Descriptif">
                        <th mat-header-cell *matHeaderCellDef class="header-text-bold">Descriptif</th>
                        <td mat-cell *matCellDef="let row" [ngClass]="{ 'long-text': row.Descriptif?.length > 20 }">
                            {{ row.Descriptif }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
                <mat-paginator
                    [pageSizeOptions]="[5, 15, 30, dataSource.data.length]"
                    [pageSize]="30"
                    showFirstLastButtons
                    aria-label="Select page of periodic elements"
                >
                </mat-paginator>
            </div>
        </mat-card>
        <br />
        <div class="text-center">
            <button mat-raised-button color="primary" (click)="exportToExcel()">Exporter vers Excel</button>
        </div>
    </div>
    <br />
</div>
