import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { AppInfoModalComponent } from './app-info-modal.component';
import { AppInfoService } from './app-info.service';
import { ApiVersionService } from './api-version.service';

@NgModule({
    imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule, RouterModule],
    declarations: [AppInfoModalComponent],
})
export class LibAppInfoModule {
    static forRoot(): ModuleWithProviders<LibAppInfoModule> {
        return {
            ngModule: LibAppInfoModule,
            providers: [ApiVersionService, AppInfoService],
        };
    }
}
