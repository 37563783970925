import { ContenuDiagnostic } from '../../../../model/diagnostic-contenu.model';
import {
    EtapeDiagnosticAbstract,
    EtapeDiagnosticEspacesHAP,
    EtapeDiagnosticGenerique,
    EtapeDiagnosticMateriauCustom,
    EtapeDiagnosticPerimetreHAP,
    EtapeDiagnosticPreparationHAP,
} from '../../../../model/diagnostic-etape.model';

export class Hap implements ContenuDiagnostic {
    objectifs: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    counterReference: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    espaces: EtapeDiagnosticEspacesHAP = new EtapeDiagnosticEspacesHAP();
    materiauxCustom: EtapeDiagnosticMateriauCustom = new EtapeDiagnosticMateriauCustom();
    preparation: EtapeDiagnosticPreparationHAP = new EtapeDiagnosticPreparationHAP();
    perimetresInvestigation: EtapeDiagnosticPerimetreHAP = new EtapeDiagnosticPerimetreHAP();
}

export enum LevelToDisplay {
    PERIMETRE,
    ZONE,
    BESOIN,
    PRELEVEMENT,
}
