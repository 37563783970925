import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditFichierModalComponent } from '../documents-gestion/edit-fichier-modal/edit-fichier-modal.component';
import { BaseComponent } from 'src/app/commons-lib';
import { Commentaire } from '../../../model/commentaire.model';
import { CommentaireService } from '../../../services/commentaire.service';
import { enumTypesCommentaire } from 'src/app/model/type-commentaire.model';

export class CommentLightModalData {
    constructor(public title: string, public label: string) {}
}

export interface CommentLightModalResult {
    commentaire: Commentaire;
}

@Component({
    selector: 'app-comment-light-modal',
    templateUrl: './comment-light-modal.component.html',
    styleUrls: ['./comment-light-modal.component.scss'],
})
export class CommentLightModalComponent extends BaseComponent implements OnInit {
    title = `Ajout d'un commentaire`;
    label = 'Motif';
    form: FormGroup;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly dialogRef: MatDialogRef<EditFichierModalComponent>,
        private readonly commentaireService: CommentaireService,
        @Inject(MAT_DIALOG_DATA) public data: CommentLightModalData
    ) {
        super();
        this.title = this.data.title;
        this.label = this.data.label;
        dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.createForm();
    }

    /**
     * Action lorsque l'utilisateur clique sur le bouton Annuler.
     * Ferme la modale
     */
    onClickCancel(): void {
        this.dialogRef.close(null);
    }

    /**
     * Action lorsque l'utilisateur clique sur le bouton de validation
     */
    onClickValidate(): void {
        if (this.form.valid) {
            const commentLightModalResult: CommentLightModalResult = {
                commentaire: this.commentaireService.prepareLightCommentaireToSave(
                    enumTypesCommentaire.MOTIF_MODIFICATION_FICHIER,
                    this.form.get('commentaireContent').value
                ),
            };
            this.dialogRef.close(commentLightModalResult);
        }
    }

    /**
     * Créer le formulaire
     * @private
     */
    private createForm(): void {
        this.form = this.formBuilder.group({
            commentaireContent: ['', [Validators.required]],
        });
    }
}
