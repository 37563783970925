<div class="fullscreen-content">
    <div class="header">
        <div class="title">{{ data.help.title }}</div>
        <button mat-button class="close-button" (click)="close()">
            <lib-icon>close</lib-icon>
        </button>
    </div>

    <div class="content scrollable-content p-4" [innerHTML]="contenu | safeHtml"></div>
</div>
