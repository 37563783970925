import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-logement-huisseries-metalliques',
    templateUrl: './logement-huisseries-metalliques.component.html',
    styleUrls: ['./logement-huisseries-metalliques.component.scss'],
})
export class LogementHuisseriesMetalliquesComponent implements OnInit {
    readonly MENU_NAME = 'Huisseries métalliques';

    constructor() {}

    ngOnInit(): void {}
}
