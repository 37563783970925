import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { TypePrestation } from '../model/type-prestation.model';
import { RegleRef } from '../model/regle.model';

@Injectable({
    providedIn: 'root',
})
export class RulesApiService {
    private http: HttpClient;

    public resourceUrl = environment.apiUrl + '/regle';

    constructor(private httpBackend: HttpBackend) {
        this.http = new HttpClient(httpBackend);
    }

    findAllByTypesPrestations(listeTypesPrestations: TypePrestation[]): Observable<RegleRef[]> {
        let params = new HttpParams();
        params = params.set('listeTypesPrestations', listeTypesPrestations.toString());
        return this.http.get<RegleRef[]>(`${this.resourceUrl}/findAllByTypesPrestations`, {
            params,
        });
    }

    /**
     * Mets à jour une règle en base
     * @param regleRef
     */
    updateRule(regleRef: RegleRef): Observable<RegleRef> {
        return this.http.put<RegleRef>(this.resourceUrl, regleRef);
    }
}
