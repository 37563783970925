<div *ngIf="reportContent" class="report-content p-4">
    <ng-container *ngIf="!hasSignature">
        <div class="d-flex justify-content-center pb-4">
            <div class="to-justify d-flex justify-content-between align-items-center">
                Vous n'avez pas encore associé de signature à votre compte. Vous ne pouvez donc pas valider vos prestations terminées.
            </div>
            <button mat-raised-button color="accent" (click)="onClickBtnAddSignature()">
                <lib-icon [theme]="'mdi'" [iconName]="'signature-freehand'"></lib-icon>
                <span>{{ 'Ajouter ma signature' | uppercase }}</span>
            </button>
        </div>
    </ng-container>

    <ng-container>
        <app-block-report
            *ngFor="let block of reportContent.blocks"
            [reportBlock]="block"
            [typePrestation]="reportContent.typePrestation"
            [interReportData]="interReportData"
            [intervention]="intervention"
            [screenshotsWithSvg]="screenshotsWithSvg"
            class="pb-4"
        ></app-block-report>
    </ng-container>
</div>
