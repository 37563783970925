<div class="d-flex justify-content-between">
    <h1>Gestion des utilisateurs</h1>
    <div>
        <button mat-button (click)="exportEmails()">
            <mat-icon>alternate_email</mat-icon>
            <span>Export emails</span>
        </button>
        <button mat-raised-button color="accent" (click)="new()">
            <mat-icon>add</mat-icon>
            <span>Nouvel utilisateur</span>
        </button>
    </div>
</div>

<div class="table-container">
    <mat-form-field class="mx-3 users-filter">
        <mat-label>Filtrer</mat-label>
        <input matInput (keyup)="filterUsers($event.target.value)" placeholder="Email, nom, prénom, société..." />
    </mat-form-field>

    <table mat-table class="w-100" [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
            <td mat-cell *matCellDef="let user">{{ user.email }}</td>
        </ng-container>
        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Prénom</th>
            <td mat-cell *matCellDef="let user">{{ user.firstName }}</td>
        </ng-container>
        <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
            <td mat-cell *matCellDef="let user">{{ user.lastName }}</td>
        </ng-container>
        <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Société</th>
            <td mat-cell *matCellDef="let user">{{ user.company }}</td>
        </ng-container>
        <ng-container matColumnDef="authorities">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Rôles</th>
            <td mat-cell *matCellDef="let user">
                <span *ngFor="let authority of user.authorities" class="authority" [ngClass]="authority">{{
                    authority | translateAuthority
                }}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>État</th>
            <td mat-cell *matCellDef="let user">
                <span *ngIf="user['companyDeleted']; else elseBlock" class="warn">Société archivée</span>
                <ng-template #elseBlock>
                    <span *ngIf="!user.deletedDate">Actif</span>
                    <span *ngIf="user.deletedDate" class="warn">Archivé</span>
                </ng-template>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
            <td mat-cell *matCellDef="let user">
                <button mat-icon-button color="primary" matTooltip="Éditer" (click)="edit(user)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button
                    *ngIf="canArchiveUser && !user.deletedDate"
                    mat-icon-button
                    color="warn"
                    matTooltip="Archiver"
                    (click)="delete(user, true)"
                >
                    <mat-icon>delete</mat-icon>
                </button>
                <button
                    *ngIf="canArchiveUser && user.deletedDate"
                    mat-icon-button
                    color="primary"
                    matTooltip="Restaurer"
                    (click)="restore(user)"
                >
                    <mat-icon>restore_from_trash</mat-icon>
                </button>
                <button mat-icon-button color="warn" matTooltip="Supprimer" (click)="delete(user, false)">
                    <mat-icon>delete_forever</mat-icon>
                </button>
                <button
                    *ngIf="canSetUserPassword"
                    mat-icon-button
                    color="primary"
                    matTooltip="Modifier le mot de passe"
                    (click)="changePassword(user)"
                >
                    <mat-icon>lock</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
</div>
