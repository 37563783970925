<div class="container">
    <div class="flex-column d-flex">
        <table mat-table [dataSource]="documentsDiagnostic">
            <ng-container matColumnDef="docInfo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Documents</th>
                <td mat-cell *matCellDef="let row">
                    <b>{{ row.nom }} </b>
                    <span class="red-stars" *ngIf="row.requiredForIntervention">*</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef>Visualiser</th>
                <td mat-cell *matCellDef="let row">
                    <div class="flex">
                        <button
                            mat-icon-button
                            color="primary"
                            matTooltip="Afficher le document"
                            [disabled]="!row.idFichier"
                            (click)="onClickShowDocument(row)"
                        >
                            <mat-icon>visibility</mat-icon>
                        </button>
                    </div>
                </td></ng-container
            >
            <ng-container matColumnDef="annexe">
                <th mat-header-cell *matHeaderCellDef>Afficher dans la liste des documents ?</th>
                <td mat-cell *matCellDef="let row">
                    <div class="flex">
                        <app-state-input
                            class="right-separation"
                            [choices]="[addOption, removeOption]"
                            [tooltips]="tooltipOnRapport"
                            [disabled]="readonlyMode"
                            [(choiceValue)]="row.afficherDansListeDocuments"
                            (valueChangeFromUser)="documentIsOnListChanged()"
                        >
                        </app-state-input>
                    </div></td
            ></ng-container>
            <ng-container matColumnDef="rapport">
                <th mat-header-cell *matHeaderCellDef>Ajouter en annexes du rapport ?</th>
                <td mat-cell *matCellDef="let row">
                    <div class="flex">
                        <app-state-input
                            class="right-separation"
                            [choices]="[addOption, removeOption]"
                            [tooltips]="tooltipOnRapport"
                            [disabled]="readonlyMode || !row.idFichier"
                            [(choiceValue)]="row.afficherDansRapport"
                            (valueChangeFromUser)="documentIsOnRapportChanged()"
                        >
                        </app-state-input>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
</div>
