'use strict';
import { cn_layer } from '../model/cn_layer';
//***********************************************************************************
//***********************************************************************************
//**** cn_svg_map : SVG canvas
//***********************************************************************************
//***********************************************************************************
import { cn_roof } from '../model/cn_roof';
import { cn_plugin_option } from '../utils/cn_plugin_option';
import { cn_box, cn_clone, cn_dist, cn_sub } from '../utils/cn_utilities';
import { cn_camera } from './cn_camera';
import { cn_event_manager } from './cn_event_manager';
import { cn_roof_controller } from './cn_roof_controller';
import { cn_scene_controller } from './cn_scene_controller';
import { cn_space_labelizer } from './cn_space_labelizer';
import { cn_mouse_event } from './cn_mouse_event';
import { cn_view_roof } from './cn_view_roof';
import { CN_MIDDLE, logger } from '..';
import { HELPER } from '../utils/cn_wordings';

/** Allow custom dimensions declaration in spaces (e.g. : sketch mode) */
export const SVG_MAP_OPTION_SPACE_ALLOW_CUSTOM_DIMENSIONS = 'allow_space_custom_dimensions';

export class cn_svg_map extends cn_event_manager {
    /**
     * Constructor
     * @param {string} svg_container_id
     * @param {any} storey
     * @param {string} roof_container_id
     * @param {cn_event_manager} parent
     */
    constructor(svg_container_id, storey, roof_container_id = '', parent = null) {
        super(parent);
        let obj = this;
        this.draw_scale = true;
        this.draw_background = true;
        this.draw_previous_storey = false;
        this.previous_storey_opacity = 0.5;
        this.draw_exterior = false;
        this.exterior_opacity = 0.5;
        this.draw_grid = false;
        this.draw_space_names = true;
        this.draw_space_equipments = false;
        this.draw_space_areas = true;
        this.draw_markers = true;
        this.show_angles = true;
        this.show_measures = true;
        this.show_compass = true;
        this.simple_draw = false;
        this.storey_preview = false;
        this.draw_height_box = true;
        this.show_space_measure = true;
        this.default_wall_axis = CN_MIDDLE;

        this.view_roof = undefined;

        this._options = {};

        this.set_option(SVG_MAP_OPTION_SPACE_ALLOW_CUSTOM_DIMENSIONS, false);

        this._svg_container_id = svg_container_id;

        const roof = (typeof (roof_container_id) == 'boolean') ? roof_container_id : (roof_container_id != '');

        //*** Scene data
        this._storey = storey;
        this._building = this._storey.building;
        this._scene = (roof) ? this._storey.roof : this._storey.scene;
        this._scene.update();
        this._scene.update_deep();

        this._controller = (roof) ? new cn_roof_controller(this._scene) : new cn_scene_controller(this._scene, this._storey);

        // @ts-ignore
        this._space_labelizer = roof ? null : new cn_space_labelizer(this._storey, this._controller, this);
        if (this._space_labelizer)
            this._space_labelizer.on('edit', space => {
                obj.call('edit_space', space);
            });

        if (roof) logger.log('creating map with roof : ', this._scene);

        this._background_scene = null;
        this._background_scenes = [];
        this._background_map = 'init';
        this._background_maps = ['init'];

        this._camera = new cn_camera();
        this._camera['initialized'] = false;
        this._camera['storey'] = storey;

        this._tools = [];

        this._current_tool = null;

        this._minimum_displacement = false;
        this._pan_mode = false;
        this._mouse_position = [0, 0];
        this._mousedown_position = [0, 0];
        this._mousedown_buttons = 0;
        this._synchronized_cameras = [];

        this._area_selection = false;
        this._multi_selection = false;
        this._area_selection_start = null;
        this._area_selection_end = null;

        this._has_focus = false;

        this._move_delay = 50;
        this.grab_status = -1;
        this.touch_distance = 0;

        this.wheel_timeout = null;

        //*** compass interaction */
        this._mouseover_compass = false;
        this._clicked_compass = false;

        this.move_camera_mode = false;

        //*** We use a special event for svg event management.
        this._svg_event = new cn_mouse_event();
        this._svg_event.camera = this._camera;
        this._svg_event.building = this._building;
        this._svg_event.storey = this._storey;
        this._svg_event.scene = this._scene;

        this.events_listeners = new Map([
            ['mousedown', { listener: this.listener_mouse_down.bind(obj), capture: false }],
            ['mouseup', { listener: this.listener_mouse_up.bind(obj), capture: false }],
            ['wheel', { listener: this.listener_wheel.bind(obj), capture: false }],
            ['mouseenter', { listener: this.listener_mouse_enter.bind(obj), capture: false }],
            ['mouseleave', { listener: this.listener_mouse_leave.bind(obj), capture: false }],
            ['touchstart', { listener: this.listener_touch_start.bind(obj), capture: true }],
            ['touchend', { listener: this.listener_touch_end.bind(obj), capture: true }],
            ['touchmove', { listener: this.listener_touch_move.bind(obj), capture: true }],
            ['contextmenu', { listener: this.listener_context_menu.bind(obj), capture: false }]
        ]);

        if (!window.navigator.userAgent.includes('Mac OS')) {
            this.events_listeners.set('pointermove', { listener: this.listener_pointer_move.bind(obj), capture: false })
        } else {
            this.events_listeners.set('mousemove', { listener: this.listener_mouse_move.bind(obj), capture: false })
        }

        const main_event_container = document.getElementById(obj._svg_container_id + '_action');

        if (main_event_container) {
            this.events_listeners.forEach((ltr, type) => {
                main_event_container.addEventListener(type, ltr.listener, ltr.capture);
            })
        }

        //*** Build roof 3D view */
        if (roof && typeof (roof_container_id) == 'string') {
            this.view_roof = new cn_view_roof(roof_container_id, this._scene, this);
            this.on('get_focus', () => {
                this.view_roof.change_view_size(false);
                return true;
            });
            this.on('roof_change', () => {
                this.view_roof.rebuild_geometry();
                return true;
            });
            this.on('roof_update', () => {
                this.view_roof.update_geometry();
                return true;
            });
            this.on('reset_roof_view', () => {
                this.view_roof.reset_camera();
                return true;
            });
        }
    }

    /**
     * Sets readonly mode
     * @param {boolean} value
     */
    set_readonly(value) {
        this._readonly = value;
    }

    /**
     * Gets readonly mode
     * @return {boolean}
     */
    get_readonly() {
        return this._readonly;
    }

    //***********************************************************************************
    /**
     * Sets an option
     * @param {string} name
     * @param {any} value
     * @param {boolean} force - To bused only on creation options
     * @return {boolean} true if value was set
     */
    set_option(name, value, force = false) {
        if (typeof (this._options[name]) == 'undefined') {
            if (!force) return false;
        }
        this._options[name] = value;
    }

    //***********************************************************************************
    /**
     * Gets an option
     * @param {string} name
     * @return {any} returns option value
     */
    get_option(name) {
        return this._options[name];
    }

    //***********************************************************************************
    /**
     * Default event beahvior to stop propagation
     * @param {any} event from the event listener
     */
    set_default_event_behavior(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    //***********************************************************************************
    /**
     * Listener for mouse move event
     * @param {any} event from the event listener
     */
    listener_mouse_move(event) {
        this.call('get_focus');
        this._has_focus = true;
        this.set_default_event_behavior(event);
        this._mousemove(event);
    }

    //***********************************************************************************
    /**
     * Listener for pointer move event
     * @param {any} event from the event listener
     */
    listener_pointer_move(event) {
        if (event.pointerType === 'mouse' || (event.pointerType === 'pen' && event.buttons !== 1)) {
            this.call('get_focus');
            this._has_focus = true;
            this.set_default_event_behavior(event);
            this._mousemove(event);
        }
    }

    //***********************************************************************************
    /**
     * Listener for mouse down event
     * @param {any} event from the event listener
     */
    listener_mouse_down(event) {
        this.call('get_focus');
        this.set_default_event_behavior(event);
        this._mousedown(event);
    }

    //***********************************************************************************
    /**
     * Listener for mouse up event
     * @param {any} event from the event listener
     */
    listener_mouse_up(event) {
        this.set_default_event_behavior(event);
        this._mouseup(event);
    }

    //***********************************************************************************
    /**
     * Listener for mouse wheel event
     * @param {any} event from the event listener
     */
    listener_wheel(event) {
        this.set_default_event_behavior(event);
        this._mousewheel(event);
    }

    //***********************************************************************************
    /**
     * Listener for mouse enter event
     * @param {any} event from the event listener
     */
    listener_mouse_enter(event) {
        this.call('get_focus');
        this.set_default_event_behavior(event);
        this._mouseenter();
    }

    //***********************************************************************************
    /**
     * Listener for mouse leave event
     * @param {any} event from the event listener
     */
    listener_mouse_leave(event) {
        this.set_default_event_behavior(event);
        this._mouseleave();
    }

    //***********************************************************************************
    /**
     * Listener for touch start event
     * @param {any} event from the event listener
     */
    listener_touch_start(event) {
        this.call('get_focus');
        this._touchstart(event);
    }

    //***********************************************************************************
    /**
     * Listener for touch end event
     * @param {any} event from the event listener
     */
    listener_touch_end(event) {
        this.set_default_event_behavior(event);
        this._touchend(event);
    }

    //***********************************************************************************
    /**
     * Listener for touch move event
     * @param {any} event from the event listener
     */
    listener_touch_move(event) {
        this.call('get_focus');
        event.preventDefault();
        this._touchmove(event);
        event.stopPropagation();
    }

    //***********************************************************************************
    /**
     * Listener for context menu event
     * @param {any} event from the event listener
     */
    listener_context_menu(event) {
        this.set_default_event_behavior(event);
        return false;
    }

    //***********************************************************************************
    /**
     * Destroy all events' listeners
     */
    destroy() {
        const main_event_container = document.getElementById(this._svg_container_id + '_action');
        if (main_event_container) {
            this.events_listeners.forEach((ltr, type) => {
                main_event_container.removeEventListener(type, ltr.listener, ltr.capture);
            });
        }
        if (this.view_roof) {
            this.view_roof.destroy();
        }
    }

    //***********************************************************************************
    /**
     * Manage key event
     * @param {any} keyboard_event from the event listener
     * @returns {boolean} Returns true if event was used
     */
    manage_key_event(keyboard_event) {
        return this._keydown(keyboard_event)
    }

    //***********************************************************************************
    /**
     * Sets area selection mode. This mode will end on relaesing the mouse, then an event 'area_selection_end' is triggered.
     * @param {boolean} v
     */
    set_area_selection(v) {
        this._area_selection = v;
        if (this._area_selection) {
            this.call("instruction_input", HELPER.selection.area);
        }
        if (!this._current_tool._current_creation_tool && !this._current_tool._current_edition_tool && !this._area_selection && !this._multi_selection) {
            this.call("instruction_input", HELPER.selection.simple);
        }
    }

    set_measure_mode(v) {
        if (this._current_tool) this._current_tool.set_measure_mode(v);
        this.refresh_tool();
    }

    //***********************************************************************************
    /**
     * Sets multi selection mode
     * @param {boolean} v
     */
    set_multi_selection(v) {
        this._multi_selection = v
        this._svg_event.multi_selection = v;
        if (this._multi_selection) {
            this.call("instruction_input", HELPER.selection.multi);
        }
        if (!this._current_tool._current_creation_tool && !this._current_tool._current_edition_tool && !this._area_selection && !this._multi_selection) {
            this.call("instruction_input", HELPER.selection.simple);
        }
    }

    //***********************************************************************************
    /**
     * Zomms in or out, depending on input parameter
     * @param {number} ratio
     */
    zoom(ratio) {
        const svg_container = document.getElementById(this._svg_container_id);
        if (!svg_container) return;

        const svg_rect = svg_container.getBoundingClientRect();
        const w = svg_rect.right - svg_rect.left - this._camera.padding[1] - this._camera.padding[3];
        const h = svg_rect.bottom - svg_rect.top - this._camera.padding[0] - this._camera.padding[2];
        const mouse_screen = [this._camera.padding[3] + 0.5 * w, this._camera.padding[0] + 0.5 * h];

        if (this.wheel_timeout) {
            clearTimeout(this.wheel_timeout);
        }

        this._camera.wheel_ratio(mouse_screen, ratio);

        const self = this;
        this.wheel_timeout = setTimeout(() => {
            self._synchronize_cameras();
            self.refresh();
        }, 200);

        this.refresh_degraded();
    }

    //***********************************************************************************
    /**
     * sets / unsets wall type display in SVG
     * @param {boolean} v
     */
    set_wall_type_display(v) {
        this._camera.show_wall_type = v;
    }

    /**
     * sets / unsets facings display in SVG
     * @param {boolean} v
     */
    set_facings_display(v) {
        this._camera.show_facings = v;
    }

    /**
     * sets / unsets draw objects icon in SVG
     * @param {boolean} v
     */
    set_draw_objects_icon(v) {
        this._camera.draw_objects_icon = v;
    }

    /**
     * sets / unsets draw objects icon in SVG
     * @param {boolean} v
     */
    set_draw_objects_top_view(v) {
        this._camera.draw_objects_top_view = v;
    }

    //***********************************************************************************
    //**** sets svg tool
    //***********************************************************************************
    set_svg_tool(svg_tool) {
        if (this._current_tool) this._current_tool.close_tool();
        this._current_tool = svg_tool;
        if (this._current_tool) this._current_tool.open_tool();
        this.refresh_tool();
    }

    //***********************************************************************************
    //**** transaction refresh
    //***********************************************************************************
    transaction_refresh() {
        if (this._current_tool)
            this._current_tool.transaction_refresh();
        this.refresh();
    }

    //***********************************************************************************
    //**** Sets padding for camera center
    //***********************************************************************************
    set_padding(top = 0, right = 0, bottom = 0, left = 0) {
        this._camera.set_padding(top, right, bottom, left);
        this.center_camera(false);
    }

    //***********************************************************************************
    //**** camera center
    //***********************************************************************************
    center_camera(refresh = true) {
        this._camera.fit_box(this._scene.get_bounding_box());
        if (refresh) {
            this.refresh();
        }
    }

    //***********************************************************************************
    //**** camera center on a custom bounding box
    //***********************************************************************************
    center_camera_on_custom_bounding_box(bounding_box, refresh = true) {
        this._camera.fit_box(bounding_box);
        if (refresh) {
            this.refresh();
        }
    }

    /**
     * Refresh all layers
     */
    refresh() {
        this.refresh_background_and_overlay();
        this.refresh_main_and_tool();
    }

    /**
     * Refresh degraded all layers
     */
    refresh_degraded() {
        const simple_draw_old_value = this.simple_draw;
        this.simple_draw = true;
        this.refresh_background_and_overlay();
        this.refresh_main_and_tool();
        this.simple_draw = simple_draw_old_value;
    }

    /**
     * Refresh main and tool layers
     */
    refresh_main_and_tool() {
        this.refresh_main();
        this.refresh_tool();
    }

    /**
     * Refresh tool layer
     */
    refresh_tool() {
        const ghost_svg_container = document.getElementById(`${this._svg_container_id}_ghost`);
        if (ghost_svg_container) {
            this._init_camera();
            let html = '';
            html += '<pattern id=\'space_area_stripes_0\' patternUnits=\'userSpaceOnUse\' width=\'20\' height=\'20\' patternTransform=\'rotate(45)\'\'>';
            html += '	<line x1=\'0\' y=\'0\' x2=\'0\' y2=\'20\' class=\'space_area_stripes_blank\' stroke-width=\'20\' />';
            html += '   <line x1=\'20\' y=\'0\' x2=\'20\' y2=\'20\' class=\'space_area_stripes_color_0\' stroke-width=\'20\' />';
            html += '</pattern>';
            html += '<pattern id=\'space_area_stripes_1\' patternUnits=\'userSpaceOnUse\' width=\'20\' height=\'20\' patternTransform=\'rotate(45)\'\'>';
            html += '	<line x1=\'0\' y=\'0\' x2=\'0\' y2=\'20\' class=\'space_area_stripes_blank\' stroke-width=\'20\' />';
            html += '   <line x1=\'20\' y=\'0\' x2=\'20\' y2=\'20\' class=\'space_area_stripes_color_1\' stroke-width=\'20\' />';
            html += '</pattern>';
            html += '<pattern id=\'space_area_stripes_2_0\' patternUnits=\'userSpaceOnUse\' width=\'20\' height=\'20\' patternTransform=\'rotate(45)\'\'>';
            html += '	<line x1=\'0\' y=\'0\' x2=\'0\' y2=\'20\' class=\'space_area_stripes_blank\' stroke-width=\'20\' />';
            html += '   <line x1=\'20\' y=\'0\' x2=\'20\' y2=\'20\' class=\'space_area_stripes_color_sub\' stroke-width=\'20\' />';
            html += '</pattern>';
            html += '<pattern id=\'space_area_stripes_2_1\' patternUnits=\'userSpaceOnUse\' width=\'20\' height=\'20\' patternTransform=\'rotate(45)\'\'>';
            html += '	<line x1=\'0\' y=\'0\' x2=\'0\' y2=\'20\' class=\'space_area_stripes_color_sun\' stroke-width=\'20\' />';
            html += '   <line x1=\'20\' y=\'0\' x2=\'20\' y2=\'20\' class=\'space_area_stripes_color_sub\' stroke-width=\'20\' />';
            html += '</pattern>';
            const main_event_container = document.getElementById(this._svg_container_id + '_action');
            if (main_event_container) main_event_container.innerHTML = '<title>' + this._svg_event.title + '</title>';

            if (this._space_labelizer) {
                if (!this.simple_draw && !this.storey_preview) {
                    this._space_labelizer.visible = true;
                    this._space_labelizer.area_visible = [this.draw_space_areas, true];
                    this._space_labelizer.name_visible = [this.draw_space_names, true];
                    this._space_labelizer.equipments_visible = [true, true];
                    //html += `<g>${this._space_labelizer.draw(this._camera)}</g>`;
                } else {
                    this._space_labelizer.visible = false;
                }
            }

            if (this._current_tool) {
                html += `<g>${this._current_tool.draw(this._camera)}</g>`;
            }

            //*** draw area selection
            if (this._area_selection_start && this._area_selection_end) {
                let x, y, w, h;
                if (this._area_selection_start[0] < this._area_selection_end[0]) {
                    x = this._area_selection_start[0];
                    w = this._area_selection_end[0] - x;
                } else {
                    x = this._area_selection_end[0];
                    w = this._area_selection_start[0] - x;
                }
                if (this._area_selection_start[1] < this._area_selection_end[1]) {
                    y = this._area_selection_start[1];
                    h = this._area_selection_end[1] - y;
                } else {
                    y = this._area_selection_end[1];
                    h = this._area_selection_start[1] - y;
                }
                html += '<rect class=\'area_selection\' x=\'' + x + '\' y=\'' + y + '\' width=\'' + w + '\' height=\'' + h + '\' />';
            }
            ghost_svg_container.innerHTML = html;
        }
    }

    /**
     * Refresh context layers (background and overlay)
     */
    refresh_background_and_overlay() {
        const background_svg_container = document.getElementById(`${this._svg_container_id}_background`);
        if (background_svg_container) {
            this._init_camera();
            let html = this._get_default_svg_def_and_filters();
            html += '<g>';
            if (!this.storey_preview) {
                if (this.draw_grid) {
                    html += this._camera.draw_grid();
                } else {
                    html += this._camera.draw_background();
                }
                html += this._draw_background();
                this.refresh_overlay();
            }
            html += '</g>'

            background_svg_container.innerHTML = html;
        }
    }

    /**
     * Refresh overlay layer
     */
    refresh_overlay() {
        const overlay_svg_container = document.getElementById(`${this._svg_container_id}_overlay`);
        if (this.storey_preview || !overlay_svg_container) return;
        let overlay_html = '';

        if (this.draw_scale) {
            overlay_html += `<g>${this._camera.draw_scale()}</g>`;
        }
        if (this.show_compass) {
            overlay_html += `<g>${this._camera.draw_compass(this._building.compass_orientation, this._mouseover_compass)}</g>`;
        }
        overlay_svg_container.innerHTML = overlay_html;
    }

    /**
     * Refresh main layer
     */
    refresh_main() {
        const main_svg_container = document.getElementById(this._svg_container_id);
        if (main_svg_container) {
            this._init_camera();
            // @ts-ignore
            this._scene.draw_height_box = this.draw_height_box;
            let html = ''

            html += '<pattern id=\'slab_opening_stripes\' patternUnits=\'userSpaceOnUse\' width=\'20\' height=\'20\' patternTransform=\'rotate(45)\'\'>';
            html += '   <line x1=\'10\' y=\'0\' x2=\'10\' y2=\'20\' class=\'slab_opening_stripes_color\' stroke-width=\'2\' />';
            html += '</pattern>';

            if (!this.simple_draw && !this.storey_preview) {
                this._scene.storey = this._storey;
                html += this._scene.draw(this._camera);
            } else {
                html += this._scene.simple_draw(this._camera, ['space_simple_draw']);
            }

            main_svg_container.innerHTML = html;
        }
    }

    //***********************************************************************************
    //**** Init camera from bounding container
    //***********************************************************************************
    _init_camera() {
        const svg_container = document.getElementById(this._svg_container_id);
        if (!svg_container) return;
        const svg_rect = svg_container.getBoundingClientRect();
        this._camera.set_size(svg_rect.right - svg_rect.left, svg_rect.bottom - svg_rect.top);
        this._camera.show_angles = this.show_angles;
        this._camera.show_measures = this.show_measures;
        this._camera.show_space_measure = this.show_space_measure;
        this._camera.use_grid = this.draw_grid;
        if (!this._camera['initialized']) {
            this._camera['initialized'] = true;
            this._camera.fit_box(this._scene.get_bounding_box());
        }
        this._camera.element_filter = (this._current_tool) ? this._current_tool.element_filter : null;
    }

    //***********************************************************************************
    //**** Get svg defs and filters
    //***********************************************************************************
    _get_default_svg_def_and_filters() {
        let html = '<defs>';
        html += '<marker class=\'arrow\' id=\'arrow_next\' markerWidth=\'7\' markerHeight=\'5\' refX=\'6\' refY=\'2\' orient=\'auto\'>';
        html += '	<path d=\'M0,0 L0,4 L6,2 z\' />';
        html += '</marker>';
        html += '<marker class=\'arrow\' id=\'arrow_prev\' markerWidth=\'7\' markerHeight=\'5\' refX=\'0\' refY=\'2\' orient=\'auto\'>';
        html += '	<path d=\'M6,0 L6,4 L0,2 z\' />';
        html += '</marker>';
        html += '<filter id=\'selection_shadow\' x=\'-50\' y=\'-50\' width=\'1000\' height=\'1000\'>';
        html += '	<feGaussianBlur result=\'blurOut\' in=\'matrixOut\' stdDeviation=\'10\' />';
        html += '	<feBlend in=\'SourceGraphic\' in2=\'blurOut\' mode=\'normal\' />';
        html += '</filter>';
        html += '<filter id=\'dimmensioning_text_background\' x=\'-15%\' y=\'-10%\' width=\'130%\' height=\'120%\'>';
        html += '   <feFlood flood-color=\'#FFFFFF\' flood-opacity=\'1\' result=\'bg\' />';
        html += '   <feGaussianBlur stdDeviation=\'2\'/>';
        html += '   <feComponentTransfer>';
        html += '       <feFuncA type=\'table\' tableValues=\'0 0 0 1\'/>';
        html += '   </feComponentTransfer>';
        html += '   <feComponentTransfer>';
        html += '       <feFuncA type=\'table\' tableValues=\'0 1 1 1 1 1 1 1\'/>'
        html += '   </feComponentTransfer>'
        html += '   <feComposite operator=\'over\' in=\'SourceGraphic\'/>'
        html += '</filter>';
        html += '<filter id=\'equipment_label_text_background\' x=\'-20%\' y=\'-10%\' width=\'140%\' height=\'120%\'>';
        html += '   <feFlood class=\'equipment_label_text_background_color\' flood-opacity=\'1\' result=\'bg\' />';
        html += '   <feGaussianBlur stdDeviation=\'2\'/>';
        html += '   <feComponentTransfer>';
        html += '       <feFuncA type=\'table\' tableValues=\'0 0 0 1\'/>';
        html += '   </feComponentTransfer>';
        html += '   <feComponentTransfer>';
        html += '       <feFuncA type=\'table\' tableValues=\'0 1 1 1 1 1 1 1\'/>'
        html += '   </feComponentTransfer>'
        html += '   <feComposite operator=\'over\' in=\'SourceGraphic\'/>'
        html += '</filter>';
        html += '<filter id=\'dimmensioning_text_selected_background\' x=\'-15%\' y=\'-10%\' width=\'130%\' height=\'120%\'>';
        html += '   <feFlood class=\'dimmensioning_text_selected_background\' flood-opacity=\'1\' result=\'bg\' />';
        html += '   <feGaussianBlur stdDeviation=\'2\'/>';
        html += '   <feComponentTransfer>';
        html += '       <feFuncA type=\'table\' tableValues=\'0 0 0 1\'/>';
        html += '   </feComponentTransfer>';
        html += '   <feComponentTransfer>';
        html += '       <feFuncA type=\'table\' tableValues=\'0 1 1 1 1 1 1 1\'/>'
        html += '   </feComponentTransfer>'
        html += '   <feComposite operator=\'over\' in=\'SourceGraphic\'/>'
        html += '</filter>';
        html += '<filter id=\'dimmensioning_text_selectable_background\' x=\'-15%\' y=\'-10%\' width=\'130%\' height=\'120%\'>';
        html += '   <feFlood class=\'dimmensioning_text_selectable_background\' flood-opacity=\'1\' result=\'bg\' />';
        html += '   <feGaussianBlur stdDeviation=\'2\'/>';
        html += '   <feComponentTransfer>';
        html += '       <feFuncA type=\'table\' tableValues=\'0 0 0 1\'/>';
        html += '   </feComponentTransfer>';
        html += '   <feComponentTransfer>';
        html += '       <feFuncA type=\'table\' tableValues=\'0 1 1 1 1 1 1 1\'/>'
        html += '   </feComponentTransfer>'
        html += '   <feComposite operator=\'over\' in=\'SourceGraphic\'/>'
        html += '</filter>';
        html += '<filter id=\'mouseover_shadow\' x=\'-50\' y=\'-50\' width=\'1000\' height=\'1000\'>';
        html += '	<feGaussianBlur result=\'blurOut\' in=\'matrixOut\' stdDeviation=\'10\' />';
        html += '	<feBlend in=\'SourceGraphic\' in2=\'blurOut\' mode=\'normal\' />';
        html += '</filter>';
        html += '<filter id=\'object_shadow\' x=\'-20%\' y=\'-20%\' width=\'140%\' height=\'140%\' filterUnits=\'objectBoundingBox\' primitiveUnits=\'userSpaceOnUse\' color-interpolation-filters=\'linearRGB\'>'
        html += '<feMorphology operator=\'dilate\' radius=\'1 1\' in=\'SourceAlpha\' result=\'morphology\'></feMorphology>'
        html += '<feFlood flood-color=\'#000000\' flood-opacity=\'1\' result=\'flood\'></feFlood>'
        html += '<feComposite in=\'flood\' in2=\'morphology\' operator=\'in\' result=\'composite\'></feComposite>'
        html += '<feMerge result=\'merge\'>'
        html += '<feMergeNode in=\'composite\' result=\'mergeNode\'></feMergeNode>'
        html += '<feMergeNode in=\'SourceGraphic\' result=\'mergeNode1\'></feMergeNode>'
        html += '</feMerge>'
        html += '</filter>';
        html += '<filter id=\'inner-shadow\'>';
        html += '	<feFlood flood-color=\'rgb(0,0,0)\'/>';
        html += '	<feComposite in2=\'SourceAlpha\' operator=\'out\'/>';
        html += '	<feGaussianBlur stdDeviation=\'50\' result=\'blur\'/>';
        html += '	<feComposite operator=\'atop\' in2=\'SourceGraphic\'/>';
        html += '</filter> ';
        html += '<pattern id=\'pattern-wave\'';
        html += 'width=\'12\' height=\'8\'';
        html += 'patternUnits=\'userSpaceOnUse\'>';
        html += '   <path d=\'M0 3 C 4 0, 8 6, 12 3\' style=\'stroke:#3470bf;fill:white;\'/>';
        html += '</pattern>';
        html += '<pattern id=\'space_area_stripes_0\' patternUnits=\'userSpaceOnUse\' width=\'20\' height=\'20\' patternTransform=\'rotate(45)\'\'>';
        html += '	<line x1=\'0\' y=\'0\' x2=\'0\' y2=\'20\' class=\'space_area_stripes_blank\' stroke-width=\'20\' />';
        html += '   <line x1=\'20\' y=\'0\' x2=\'20\' y2=\'20\' class=\'space_area_stripes_color_0\' stroke-width=\'20\' />';
        html += '</pattern>';
        html += '<pattern id=\'space_area_stripes_1\' patternUnits=\'userSpaceOnUse\' width=\'20\' height=\'20\' patternTransform=\'rotate(45)\'\'>';
        html += '	<line x1=\'0\' y=\'0\' x2=\'0\' y2=\'20\' class=\'space_area_stripes_blank\' stroke-width=\'20\' />';
        html += '   <line x1=\'20\' y=\'0\' x2=\'20\' y2=\'20\' class=\'space_area_stripes_color_1\' stroke-width=\'20\' />';
        html += '</pattern>';
        html += '<pattern id=\'space_area_stripes_2_0\' patternUnits=\'userSpaceOnUse\' width=\'20\' height=\'20\' patternTransform=\'rotate(45)\'\'>';
        html += '	<line x1=\'0\' y=\'0\' x2=\'0\' y2=\'20\' class=\'space_area_stripes_blank\' stroke-width=\'20\' />';
        html += '   <line x1=\'20\' y=\'0\' x2=\'20\' y2=\'20\' class=\'space_area_stripes_color_sub\' stroke-width=\'20\' />';
        html += '</pattern>';
        html += '<pattern id=\'space_area_stripes_2_1\' patternUnits=\'userSpaceOnUse\' width=\'20\' height=\'20\' patternTransform=\'rotate(45)\'\'>';
        html += '	<line x1=\'0\' y=\'0\' x2=\'0\' y2=\'20\' class=\'space_area_stripes_color_sun\' stroke-width=\'20\' />';
        html += '   <line x1=\'20\' y=\'0\' x2=\'20\' y2=\'20\' class=\'space_area_stripes_color_sub\' stroke-width=\'20\' />';
        html += '</pattern>';
        html += '<pattern id=\'slab_opening_stripes\' patternUnits=\'userSpaceOnUse\' width=\'20\' height=\'20\' patternTransform=\'rotate(45)\'\'>';
        html += '   <line x1=\'10\' y=\'0\' x2=\'10\' y2=\'20\' class=\'slab_opening_stripes_color\' stroke-width=\'2\' />';
        html += '</pattern>';
        html += '</defs>';
        return html;
    }

    //***********************************************************************************
    //**** Force camera synchronization to maps
    //***********************************************************************************
    synchronize_cameras(maps) {
        this._synchronized_cameras = maps;
    }

    //***********************************************************************************
    //**** render background
    //***********************************************************************************
    _draw_background() {
        let html = ``;

        //*** In case of roof, always draw storey below roof */
        if (this._scene.constructor == cn_roof) {
            html += `<g opacity="0.5">${this._storey.scene.draw_as_backround(this._camera)}</g>`;
            return html;
        }

        // Dessiner l'étage inférieur
        if (this.draw_previous_storey) {
            const previous_storey = this._storey.get_previous_storey();
            if (previous_storey) {
                this._background_scenes = [previous_storey.scene];
                html += `<g opacity="${this.previous_storey_opacity}">`;
                if (!this.simple_draw) {
                    html += `${this._background_scenes[0].draw_as_backround(this._camera)}</g>`;
                } else {
                    html += `${this._background_scenes[0].simple_draw(this._camera, ['space_background_simple_draw'])}</g>`;
                }
            }
        }

        // Dessiner l'extérieur
        if (!this._storey.exterior && this.draw_exterior) {
            const exterior = this._building.find_exterior();
            if (exterior) {
                this._background_scenes = [exterior.scene];
                html += `<g opacity="${this.exterior_opacity}">`;
                if (!this.simple_draw) {
                    html += `${this._background_scenes[0].draw_as_backround(this._camera)}</g>`;
                } else {
                    html += `${this._background_scenes[0].simple_draw(this._camera, ['space_background_simple_draw'])}</g>`;
                }
            }
        }

        // Dessiner les fonds de carte
        const background_maps = this.draw_background ? this._storey.background_maps : [];

        if (this._storey.exterior) {
            const previous_storey = this._storey.get_previous_storey();
            if (previous_storey) {
                this._background_scenes = background_maps.concat(previous_storey.scene);
            }
        } else {
            this._background_scenes = background_maps;
        }

        if (this._background_scenes.length === 0) return html;

        this._background_scenes.forEach(bs => {
            if ((bs.background_opacity > 0) || bs.building) {
                bs.draw_numerotation = false;
                const opacity = bs.background_opacity ? bs.background_opacity : this.previous_storey_opacity;
                html += `<g opacity="${opacity}">`;

                if (!this.simple_draw) {
                    html += `${bs.draw(this._camera)}</g>`;
                } else {
                    html += `${bs.simple_draw(this._camera, ['space_background_simple_draw'])}</g>`;
                }
            }
        });

        return html;
    }

    //***********************************************************************************
    //**** Common SVG event management
    //***********************************************************************************

    update_svg_event(ev) {

        //*** Localized client position
        if (typeof (ev.clientX) != 'undefined') {
            this._svg_event.mouse_screen[0] = ev.clientX;
            this._svg_event.mouse_screen[1] = ev.clientY;
            var svg_container = document.getElementById(this._svg_container_id);
            if (svg_container) {
                var svg_rect = svg_container.getBoundingClientRect();
                this._svg_event.mouse_screen[0] -= svg_rect.left;
                this._svg_event.mouse_screen[1] -= svg_rect.top;
            }
        }

        this._svg_event.camera = this._camera;
        this._svg_event.mouse_world = this._camera.screen_to_world(this._svg_event.mouse_screen);
        this._svg_event.title = '';

        if (typeof (ev.buttons) != 'undefined')
            this._svg_event.buttons = ev.buttons;

        this._svg_event.ctrlKey = false;
        if (typeof (ev.ctrlKey) != 'undefined')
            this._svg_event.ctrlKey = ev.ctrlKey;

        this._svg_event.shiftKey = false;
        if (typeof (ev.shiftKey) != 'undefined')
            this._svg_event.shiftKey = ev.shiftKey;

        this._svg_event.altKey = false;
        if (typeof (ev.altKey) != 'undefined')
            this._svg_event.altKey = ev.altKey;

        if (typeof (ev.deltaY) != 'undefined')
            this._svg_event.wheel_up = (ev.deltaY > 0.2);

        this._svg_event.key = 0;
        if (typeof (ev.key) != 'undefined')
            this._svg_event.key = ev.key;
    }

    //***********************************************************************************
    //**** Mouse callbacks
    //***********************************************************************************

    _mousedown(ev) {
        this.update_svg_event(ev);

        this._minimum_displacement = false;
        this._mousedown_buttons = ev.buttons;
        this._mousedown_position = cn_clone(this._svg_event.mouse_screen);

        //*** are we clicking on the compass ? */
        this._clicked_compass = false;
        if (!this.storey_preview && ev.buttons & 1 && this.show_compass && this._camera.mouseover_compass(this._svg_event.mouse_screen)) {
            this._clicked_compass = true;
            this.call('click_compass', this._building.compass_orientation);
            return;
        }

        //*** are we initiating pan (other than left button) ?
        if ((ev.buttons & 1) == 0 || this.move_camera_mode || ev.altKey) {
            this._mouse_position = cn_clone(this._svg_event.mouse_screen);
            this._pan_mode = true;
            logger.log('initiate pan');
            return;
        }

        if (!this.move_camera_mode && (ev.buttons & 1) && this._current_tool) {
            if (this._current_tool && this._current_tool.grab(this._svg_event)) {
            } else {
                if (ev.shiftKey)
                    this._area_selection = true;
                if (this._area_selection && this._current_tool.area_selection)
                    this._area_selection_start = cn_clone(this._svg_event.mouse_screen);
                else {
                    this._mouse_position = cn_clone(this._svg_event.mouse_screen);
                    this._pan_mode = true;
                    logger.log('initiate pan from tool');
                }
            }
        }
    }

    _mouseup(ev) {
        this.update_svg_event(ev);

        if (this._clicked_compass) {
            this._clicked_compass = false;
            return;
        }

        if (this._current_tool && this._area_selection_start && this._minimum_displacement) {
            var area_ev = {};
            area_ev.box_screen = new cn_box();
            area_ev.box_screen.enlarge_point(this._area_selection_start);
            area_ev.box_screen.enlarge_point(this._svg_event.mouse_screen);

            area_ev.box_world = new cn_box();
            area_ev.box_world.enlarge_point(this._camera.screen_to_world(this._area_selection_start));
            area_ev.box_world.enlarge_point(this._svg_event.mouse_world);

            area_ev.camera = this._camera;

            area_ev.ctrlKey = this._svg_event.ctrlKey;

            this._current_tool.area_select(area_ev);
        } else if (!this.move_camera_mode && (ev.which == 1) && (this._current_tool)) {
            if (this._minimum_displacement) {
                if (this._current_tool)
                    this._current_tool.drop(this._svg_event);
            } else {
                if (this._current_tool)
                    this._current_tool.click(this._svg_event);
            }
        }

        this._area_selection_start = null;
        this._area_selection_end = null;
        if (!this._pan_mode && (this._area_selection || (this._current_tool
            && this._current_tool._main_tool && this._current_tool._main_tool && this._current_tool._main_tool.creation_tool
            && this._current_tool._main_tool.creation_tool.is_transient))) {
            if (this._area_selection) {
                this._area_selection = false;
                this.call('area_selection_end');
            }
            this.refresh_tool();
        } else if (this._pan_mode) {
            this._pan_mode = false;
            this.refresh();
        } else {
            this.refresh_main_and_tool();
        }

        if (!this._minimum_displacement && (ev.which == 1))
            this.call('click');
    }

    _mousemove(ev) {
        {
            logger.log('mouse move');
        }
        var t = (new Date()).getTime();
        if (typeof (this._lastmove) != 'undefined' && t - this._lastmove < this._move_delay * 2) {
            return;
        }
        this._lastmove = t;

        this.update_svg_event(ev);

        if (!this._minimum_displacement)
            this._minimum_displacement = (cn_dist(this._mousedown_position, this._svg_event.mouse_screen) > 10);

        var do_drag_and_drop = false;

        //*** Manage compass */
        if (!this.storey_preview && ev.buttons & 1 && this.show_compass && this._clicked_compass) {
            return;
        }

        if (!this.storey_preview && !(ev.buttons & 1) && this.show_compass) {
            const was_compass_mouseover = this._mouseover_compass;
            this._mouseover_compass = this._camera.mouseover_compass(this._svg_event.mouse_screen);
            if (was_compass_mouseover != this._mouseover_compass)
                this.refresh_overlay();
        }

        if (!this.storey_preview && !(ev.buttons & 1) && this.show_compass && this._mouseover_compass) {
            this._mouseover_compass = true;
        }
        //*** update selection area
        else if (this._area_selection_start) {
            if (ev.buttons & 1)
                this._area_selection_end = cn_clone(this._svg_event.mouse_screen);
            else
                this._area_selection_start = null;
            this.refresh_tool();
        }

        //*** process pan
        else if (this._pan_mode) {
            var delta = cn_sub(this._svg_event.mouse_screen, this._mouse_position);
            this._camera.pan(delta[0], delta[1]);
            this._mouse_position = cn_clone(this._svg_event.mouse_screen);
            this._synchronize_cameras();
            this.refresh_degraded();
        }
        //*** Drag / move current tool
        else if (this._current_tool) {
            if (ev.buttons & 1) {
                if (this._minimum_displacement && this._current_tool && this._current_tool.drag(this._svg_event))
                    this.refresh_main_and_tool();
            } else if (this._current_tool && this._current_tool.move(this._svg_event))
                this.refresh_tool();
        }

        var delay = (new Date()).getTime() - t;
        this._move_delay = 0.9 * this._move_delay + 0.1 * delay;
        if (this._move_delay > 100) this._move_delay = 100;
    }

    _mousewheel(ev) {
        this.update_svg_event(ev);
        this._camera.wheel(this._svg_event.mouse_screen, this._svg_event.wheel_up);
        this._synchronize_cameras();
        const self = this;
        if (this.wheel_timeout) {
            clearTimeout(this.wheel_timeout);
        }
        this.wheel_timeout = setTimeout(() => {
            self.refresh();
        }, 200);
        this.refresh_degraded();
    }

    _mouseenter() {
    }

    _mouseleave() {
        if (this._pan_mode) {
            this.refresh();
        }
        this._pan_mode = false;
        this._minimum_displacement = false;
    }

    _touchstart(ev) {
        {
            logger.log('touch start ', ev.targetTouches.length, ev.touches.length);
        }
        if (ev.targetTouches.length == 1) {
            this.grab_status = 0;
            this.mev = {};
            this.mev.buttons = 1;
            this.mev.which = 1;
            this.mev.clientX = ev.targetTouches[0].clientX;
            this.mev.clientY = ev.targetTouches[0].clientY;

            setTimeout(() => {
                if (this.grab_status > 0) {
                    logger.log('too late for 1');
                    return;
                }
                if (this._current_tool && this._current_tool._measure_points && this._current_tool._measure_points.length) {
                    this._mousemove(this.mev);
                }
                if (this.grab_status == 0) {
                    this.grab_status = 1;
                    this._mousedown(this.mev);
                } else {
                    this._mousedown(this.mev);
                    this._mouseup(this.mev);
                }
            }, 500)
        } else {
            if (this.grab_status == 1) {
                logger.log('too late for 2');
                return;
            }
            logger.log('grab status was ', this.grab_status);
            this.grab_status = 2;
            this.mev = {};
            this.mev.buttons = 2;
            this.mev.which = 2;
            this.mev.clientX = 0.5 * (ev.targetTouches[0].clientX + ev.targetTouches[1].clientX);
            this.mev.clientY = 0.5 * (ev.targetTouches[0].clientY + ev.targetTouches[1].clientY);
            var v0 = [ev.targetTouches[0].clientX, ev.targetTouches[0].clientY];
            var v1 = [ev.targetTouches[1].clientX, ev.targetTouches[1].clientY];
            this.touch_distance = cn_dist(v0, v1);
            this._mousedown(this.mev);
        }
    }

    _touchend(ev) {
        {
            logger.log('touch');
        }
        if (this.grab_status > 0) {
            this._mouseup(this.mev);
        }
        this.grab_status = -1;
    }

    _touchmove(ev) {
        {
            logger.log('Touch move', 'target touches : ', ev.targetTouches.length);
        }
        if (ev.targetTouches.length != this.grab_status) {
            return;
        }

        if (ev.targetTouches.length == 1) {
            this.mev.buttons = 1;
            this.mev.which = 1;
            this.mev.clientX = ev.targetTouches[0].clientX;
            this.mev.clientY = ev.targetTouches[0].clientY;
            logger.log('move 1 touch');
            this._mousemove(this.mev);
        } else {
            this.mev.buttons = 2;
            this.mev.which = 2;
            this.mev.clientX = 0.5 * (ev.targetTouches[0].clientX + ev.targetTouches[1].clientX);
            this.mev.clientY = 0.5 * (ev.targetTouches[0].clientY + ev.targetTouches[1].clientY);

            var v0 = [ev.targetTouches[0].clientX, ev.targetTouches[0].clientY];
            var v1 = [ev.targetTouches[1].clientX, ev.targetTouches[1].clientY];
            var distance = cn_dist(v0, v1);
            if (distance != this.touch_distance) {
                var ratio = this.touch_distance / distance;
                this.update_svg_event(ev);
                this._camera.wheel_ratio(this._svg_event.mouse_screen, ratio);
                this.touch_distance = distance;
            }

            logger.log('move several touch');
            this._mousemove(this.mev);
        }
    }

    _keydown(ev) {
        this.update_svg_event(ev);
        if (this._current_tool && this._current_tool.keydown(this._svg_event)) {
            this.refresh_main_and_tool();
            return true;
        } else if (ev.ctrlKey && (ev.key === 'y' || ev.key === 'z')) {
            if (ev.key === 'y') {
                this._building.transaction_manager.perform_redo();
            } else {
                this._building.transaction_manager.perform_undo();
            }
            this.transaction_refresh();
            return true;
        } else if (ev.key === 'Escape') {
            this.call('cancel_key');
            return true;
        }
        return false;
    }

    //***********************************************************************************
    //**** camera synchronization
    //***********************************************************************************
    _synchronize_cameras() {
        if (this._synchronized_cameras.length == 0) return;
        for (var i in this._synchronized_cameras) {
            var svg_map = this._synchronized_cameras[i];
            if (svg_map == this) continue;
            svg_map._camera.copy(this._camera);
            svg_map.refresh();
        }
    }

    //*******************************************************
    /**
     * Returns 'true' if there is something to copy
     * @returns {boolean}
     */
    can_copy() {
        return this._controller.can_copy();
    }

    //*******************************************************
    /**
     * Returns 'true' if there is something to paste
     * @returns {boolean}
     */
    can_paste() {
        for (var i in this._building.clipboard) {
            // TODO @seb verify if this is ok, because before modularisation we tested on scene constructor.
            if (this._building.clipboard[i].data_constructor == this._scene.constructor) return true;
        }
        return false;
    }

    //*******************************************************
    /**
     * Returns 'true' if there is something to delete
     * @returns {boolean}
     */
    can_delete() {
        return this._controller.can_delete();
    }

    //*******************************************************
    /**
     * Copy selection
     * @returns {boolean} true if something to copy
     */
    copy_selection() {
        if (!this._current_tool) return false;
        if (!this._current_tool.copy_selection) return false;
        return this._current_tool.copy_selection();
    }

    //*******************************************************************************
    /**
     * Cut selection
     * @returns {boolean} true if something to copy
     */
    cut_selection() {
        if (!this._current_tool) return false;
        if (!this._current_tool.cut_selection) return false;
        if (this._current_tool.cut_selection()) {
            this.delete_selection();
            return true;
        }
        return false;
    }

    //*******************************************************
    /**
     * Returns 'true' if there is something to delete
     * @returns {boolean}
     */
    can_cut() {
        return this._controller.can_cut();
    }

    //*******************************************************
    /**
     * Deletes selection
     * @returns {boolean} true if something was deleted
     */
    delete_selection() {
        if (!this._current_tool) return false;
        if (!this._current_tool.remove_selection) return false;
        this._current_tool.remove_selection();
        this.refresh_tool();
        return true;
    }

    //*******************************************************
    /**
     * Paste clipbaord
     * @returns {boolean} true if something to paste
     */
    paste_clipboard() {
        if (!this._current_tool) return false;
        if (!this._current_tool.paste_clipboard) return false;
        if (!this._current_tool.paste_clipboard()) return false;
        this.refresh_tool();
        return true;
    }

    //*******************************************************
    /**
     * Activate or deactivate numerotation features
     * @param {boolean} isActive
     */
    activateNumerotation(isActive) {
        cn_plugin_option.numerotation = isActive;
    }

    //***********************************************************************************
    /**
     * Add layer to draw
     * @param {Array<cn_layer>} layers
     */
    add_layer_to_draw(...layers) {
        // @ts-ignore
        this._scene.add_layer_to_draw(...layers);
    }

    /**
     * Highlight heated spaces
     *
     * @param {boolean} is_active
     */
    highlight_heated_spaces(is_active) {
        this._scene.highlight_heated_spaces(is_active);
    }

    //***********************************************************************************
    /**
     * Remove layer to draw
     * @param {Array<cn_layer>} layers
     */
    remove_layer_to_draw(...layers) {
        // @ts-ignore
        this._scene.remove_layer_to_draw(...layers);
    }

    //***********************************************************************************
    /**
     * Set draw samplings
     * @param {boolean} draw_samplings
     */
    set_draw_samplings(draw_samplings) {
        this._scene.draw_samplings = draw_samplings;
    }

    //***********************************************************************************
    /**
     * Set draw comments
     * @param {boolean} draw_comments
     */
    set_draw_comments(draw_comments) {
        this._scene.draw_comments = draw_comments;
    }


    //***********************************************************************************
    /**
     * Set draw comments color application
     * @param {boolean} draw_comments_application
     */
    set_draw_comments_application(draw_comments_application) {
        this._scene.draw_comments_application = draw_comments_application;
    }

    //***********************************************************************************
    /**
     * Sets the building orientation
     * @param {number} orientation
     */
    set_compass_orientation(orientation) {
        this._building.transaction_manager.push_transaction('Modification de la boussole', '');
        this._building.transaction_manager.push_item_set(this._building, 'compass_orientation');
        this._building.compass_orientation = orientation;
        this.refresh_background_and_overlay();
    }

    //***********************************************************************************
    /**
     * Returns the space labelizer. This returns null if the map is not placed upon a regular scene.
     * @returns {cn_space_labelizer}
     */
    get_space_labelizer() {
        return this._space_labelizer;
    }
}

