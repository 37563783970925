import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { DiagnosticService } from '../../../../../../../services/diagnostic.service';
import { ElectriciteService } from '../../../../services/electricite.service';

@Component({
    selector: 'app-coupure',
    templateUrl: './coupure.component.html',
    styleUrls: ['./coupure.component.scss'],
})
export class CoupureComponent extends BaseComponent implements OnInit {
    readonly COUPURE: string = 'Coupure';

    constructor(private diagnosticService: DiagnosticService, private electriciteService: ElectriciteService) {
        super();
    }

    ngOnInit(): void {}
}
