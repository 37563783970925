import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent, FileUploaderComponent, NotificationService } from 'src/app/commons-lib';
import { combineLatest, forkJoin, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { EditMode } from 'src/app/model/edit-mode.model';
import { EtatWorkflow } from 'src/app/model/etat-workflow.model';
import { Rule } from 'src/app/model/regle.model';
import { TypeElementAControler, TypeElementAControlerAdmin } from 'src/app/model/type-element-a-controler.model';
import { typesPrestation } from 'src/app/model/type-prestation.model';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { ReferenceApiService } from 'src/app/services/reference-api.service';
import { ReferenceService } from 'src/app/services/reference.service';
import { RulesService } from 'src/app/services/rules.service';
import { URL_GESTION_EQUIPEMENTS, URL_GESTION_EQUIPEMENTS_EDIT } from 'src/app/shared/constants/url.constants';
import { StringUtils } from 'src/app/utils/string.utils';
import { ValidatorUtils } from 'src/app/utils/validator.utils';
import { CnmapImageUtils, dataUrlToBlob } from '@acenv/cnmap-angular-editor-lib';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
    selector: 'app-creation-equipement',
    templateUrl: './creation-equipement.component.html',
    styleUrls: ['./creation-equipement.component.scss'],
})
export class CreationEquipementComponent extends BaseComponent implements OnInit, OnDestroy {
    idTypeElementAControler: string;
    typeElementAControlerInitial: TypeElementAControlerAdmin = new TypeElementAControlerAdmin();
    typeElementAControler: TypeElementAControlerAdmin = new TypeElementAControlerAdmin();

    listeFullTypeElementAControler: TypeElementAControler[] = [];
    listeNomTypeElementAControler: string[] = [];

    formTemplateValeursParametres: any[] = [];

    formTypeElement: FormGroup;

    mode: EditMode = 'CREATE';
    isReadOnly = false;

    imgPerso: string;

    isElementParentInitial = false;

    typesPrestation = typesPrestation;

    @ViewChild('fileUploader') fileUploader: FileUploaderComponent;
    editPicture: boolean;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly referenceApiService: ReferenceApiService,
        private readonly referenceService: ReferenceService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly notificationService: NotificationService,
        private readonly rulesService: RulesService,
        private readonly imageCompress: NgxImageCompressService
    ) {
        super();
    }

    ngOnInit(): void {
        this.formTypeElement = this.formBuilder.group({
            nom: [
                '',
                [
                    Validators.required,
                    (control: AbstractControl) => {
                        if (
                            control.value &&
                            ValidatorUtils.checkAlreadyExist(
                                this.formTypeElement.controls.nom.value,
                                this.mode !== 'EDIT',
                                this.typeElementAControlerInitial.nom,
                                this.listeFullTypeElementAControler.map((ref) => ref.nom)
                            )
                        ) {
                            return { erreurNomEquipement: true };
                        }
                        return null;
                    },
                ],
            ],
            abstrait: ['false', Validators.required],
            codeBim: [
                '',
                [
                    Validators.required,
                    (control: AbstractControl) => {
                        if (control.value && this.codeBimElementExisteDeja()) {
                            return { erreurCodeBimEquipement: true };
                        }
                        return null;
                    },
                ],
            ],
            etatEquipement: [EtatWorkflow.INACTIF, Validators.required],
            description: '',
            elementParent: ['', Validators.required],
            regle: 'true',
            typesPrestationsAssocie: ['', Validators.required],
        });

        this.cnSpinnerService
            .withSpinner(
                combineLatest([this.route.paramMap, this.route.data]).pipe(
                    takeUntil(this.ngUnsubscribe),
                    switchMap(([params, data]) => {
                        this.idTypeElementAControler = params.get('idEquipement');
                        if (this.idTypeElementAControler) {
                            this.mode = data.duplicate ? 'DUPLICATE' : 'EDIT';
                            this.isReadOnly = data.consulter;
                            return this.referenceApiService.findOneTypeElementAControler(this.idTypeElementAControler);
                        }
                        return of(null);
                    }),
                    switchMap((currentTypeElement) => forkJoin([of(currentTypeElement), this.referenceService.forceReloadTypesElementsAControler()]))
                )
            )
            .subscribe(([currentTypeElement, listeTypeElementAControler]) => {
                if (currentTypeElement) {
                    this.typeElementAControlerInitial = JSON.parse(JSON.stringify(currentTypeElement));
                    this.typeElementAControler = currentTypeElement;
                }
                this.imgPerso = this.typeElementAControler.icone;
                this.listeFullTypeElementAControler = listeTypeElementAControler;

                if (this.mode !== 'CREATE') {
                    this.isElementParentInitial = true;

                    let nom = '';
                    const abstrait = this.typeElementAControler.abstrait;
                    let codeBim = '';
                    const etatEquipement = EtatWorkflow.INACTIF;
                    const description = this.typeElementAControler.description;
                    const elementParent =
                        this.typeElementAControler.elementsParent && this.typeElementAControler.elementsParent.length > 0
                            ? this.typeElementAControler.elementsParent[0].nom
                            : '';
                    const prestationsAssociees = this.typeElementAControler.typesPrestationsAssocie;
                    this.initFormParametres(
                        this.listeFullTypeElementAControler.find((element) => element.codeBim === this.typeElementAControler.codeBim),
                        true
                    );
                    if (this.mode === 'EDIT') {
                        nom = this.typeElementAControler.nom;
                        codeBim = this.typeElementAControler.codeBim;
                    } else if (this.mode === 'DUPLICATE') {
                        this.typeElementAControler.id = null;
                    }

                    this.formTypeElement.patchValue({
                        nom: nom,
                        abstrait: abstrait,
                        codeBim: codeBim,
                        etatEquipement: etatEquipement,
                        description: description,
                        elementParent: elementParent,
                        typesPrestationsAssocie: prestationsAssociees,
                    });
                } else {
                    this.formTypeElement.controls['regle'].setValidators([Validators.required]);
                }

                // Initialisation de la liste des équipements parent possible pour la liste déroulante
                this.listeNomTypeElementAControler = this.listeFullTypeElementAControler
                    .filter((element) => element.id !== this.typeElementAControlerInitial.id)
                    .map((element) => element.nom);
            });
    }

    /**
     * Return true si le codeBim existe déjà et false sinon
     */
    codeBimElementExisteDeja(): boolean {
        const codeBimAControler = this.formTypeElement.controls.codeBim.value;
        if (this.mode === 'EDIT') {
            return (
                this.typeElementAControlerInitial.codeBim !== codeBimAControler &&
                this.listeFullTypeElementAControler
                    .map((ref) => StringUtils.toLowerCase(ref.codeBim).trim())
                    .includes(StringUtils.toLowerCase(codeBimAControler).trim())
            );
        } else {
            return this.listeFullTypeElementAControler
                .map((ref) => StringUtils.toLowerCase(ref.codeBim).trim())
                .includes(StringUtils.toLowerCase(codeBimAControler).trim());
        }
    }

    changeElementParent(nomElement: string) {
        // Réinitialisation
        this.typeElementAControler.elementsParent = [];

        const elementSelectionne = this.listeFullTypeElementAControler.find((element) => element.nom === nomElement);
        // On vérifie si l'element parent choisi est le parent initial
        if (this.typeElementAControlerInitial && this.typeElementAControlerInitial.elementsParent.length) {
            this.isElementParentInitial = this.typeElementAControlerInitial.elementsParent.some((parent) => parent.id === elementSelectionne.id);
        } else {
            this.isElementParentInitial = false;
        }

        // On récupère les ancètres de l'élementParent et on rajoute l'élément parent sélectionné
        if (elementSelectionne.idsAncetres.length > 0) {
            this.typeElementAControler.elementsParent.push(elementSelectionne);
            elementSelectionne.idsAncetres[0].forEach((ancetreId) =>
                this.typeElementAControler.elementsParent.push(this.listeFullTypeElementAControler.find((elem) => elem.id === ancetreId))
            );
        } else {
            this.typeElementAControler.elementsParent = [elementSelectionne];
        }

        this.initFormParametres(elementSelectionne, false);
    }

    /**
     *
     * @param elementParentSelectionne
     * @param utiliserValeursActuelles true si on est dans le ngInit, false sinon. Signifie qu'on renseigne
     * comme valeurs celle qui existent en base pour l'élément courant et non pour celui choisi
     */
    initFormParametres(elementParentSelectionne: TypeElementAControler, utiliserValeursActuelles: boolean) {
        // Réinitialisation
        this.formTemplateValeursParametres = [];

        if (elementParentSelectionne.definitionsParametres == null || elementParentSelectionne.definitionsParametres.length == 0) {
            elementParentSelectionne.idsAncetres.forEach((tabAncetres) => {
                tabAncetres.forEach((idAncetre) => {
                    this.formTemplateValeursParametres = this.formTemplateValeursParametres.concat(
                        JSON.parse(JSON.stringify(this.listeFullTypeElementAControler.find((elem) => elem.id === idAncetre).definitionsParametres))
                    );
                });
            });
        } else {
            this.formTemplateValeursParametres = elementParentSelectionne.definitionsParametres;
        }

        // On valorise les valeurs des paramètres si l'element choisi n'est pas un élément abstrait
        if (!utiliserValeursActuelles && !elementParentSelectionne.abstrait && elementParentSelectionne.valeursDefautParametres != null) {
            this.formTemplateValeursParametres.forEach((parametre) => {
                parametre.valeur = elementParentSelectionne.valeursDefautParametres[parametre.codeBim];
            });
        }
        if (utiliserValeursActuelles && !this.typeElementAControler.abstrait && this.typeElementAControler.valeursDefautParametres != null) {
            this.formTemplateValeursParametres.forEach((parametre) => {
                parametre.valeur = this.typeElementAControler.valeursDefautParametres[parametre.codeBim];
            });
        }

        // Supprimer les anciens formControl rajoutés :
        Object.keys(this.formTypeElement.controls).forEach((key) => {
            if (key.startsWith('val_param_')) {
                this.formTypeElement.removeControl(key);
            }
        });

        // Compléter le formControl :
        this.formTemplateValeursParametres.forEach((parametre) => {
            const fc = new FormControl(parametre.valeur, Validators.required);
            this.formTypeElement.addControl('val_param_' + parametre.codeBim, fc);
        });
    }

    validerEquipement() {
        this.cnSpinnerService.show('Sauvegarde en cours...');

        // Values
        this.typeElementAControler.nom = this.formTypeElement.value.nom;
        this.typeElementAControler.codeBim = this.formTypeElement.value.codeBim;
        this.typeElementAControler.description = this.formTypeElement.value.description;
        this.typeElementAControler.icone = this.imgPerso;
        this.typeElementAControler.etatEquipement = this.formTypeElement.value.etatEquipement;
        this.typeElementAControler.garderRegle = this.formTypeElement.value.regle;
        this.typeElementAControler.typesPrestationsAssocie = this.formTypeElement.value.typesPrestationsAssocie;
        this.typeElementAControler.definitionsParametres = [];
        this.typeElementAControler.valeursDefautParametres = new Map();
        this.formTemplateValeursParametres.forEach((val) => {
            // Récupérer le control correspondant:
            Object.keys(this.formTypeElement.controls).forEach((key) => {
                if (key.endsWith(val.codeBim)) {
                    this.typeElementAControler.valeursDefautParametres[val.codeBim] = this.formTypeElement.controls[key].value;
                }
            });
        });

        // ------------- Gestion des règles de l'équipement ----------------
        if (this.mode !== 'EDIT' && this.typeElementAControler.garderRegle) {
            this.rulesService.findAllByTypesPrestations(this.typeElementAControler.typesPrestationsAssocie).subscribe((reglesRef) => {
                reglesRef.forEach((regleRef) => {
                    let regleIsModified = false;
                    const regleData = JSON.parse(regleRef.jsonData) as Rule[];
                    regleData.forEach((element) => {
                        // Si la règle contient l'element parent dans ses références, on rajoute l'élément qu'on est en train de créer
                        if (element.references.includes(this.typeElementAControler.elementsParent[0].codeBim)) {
                            element.references.push(this.typeElementAControler.codeBim);
                            regleIsModified = true;
                        }
                    });
                    // Si les règles ont été modifié
                    if (regleIsModified) {
                        regleRef.jsonData = JSON.stringify(regleData);
                        this.rulesService.updateRule(regleRef).subscribe();
                    }
                });
            });
        }

        if (this.mode === 'EDIT') {
            this.referenceApiService.updateTypeElementAControler(this.typeElementAControler).subscribe(
                () => {
                    this.cnSpinnerService.hide();
                    this.notificationService.success("L'équipement a été modifié");
                    // On réinitialise la liste des equipements dans le cache de l'appli
                    this.referenceApiService.buildTypesEquipementsAControler$();
                    this.back();
                },

                () => this.cnSpinnerService.hide()
            );
        } else {
            this.referenceApiService.createTypeElementAControler(this.typeElementAControler).subscribe(
                () => {
                    this.cnSpinnerService.hide();
                    const message = this.mode === 'CREATE' ? "L'équipement a été créé" : "L'équipement a été dupliqué";
                    this.notificationService.success(message);
                    // On réinitialise la liste des equipements dans le cache de l'appli
                    this.referenceApiService.buildTypesEquipementsAControler$();
                    this.back();
                },
                () => this.cnSpinnerService.hide()
            );
        }
    }

    editer() {
        this.router.navigate([URL_GESTION_EQUIPEMENTS_EDIT, this.typeElementAControler.id]);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    back() {
        this.router.navigate([URL_GESTION_EQUIPEMENTS]);
    }

    // uploadIconeElement(picture: FormData) {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(picture.get('file') as File);
    //     reader.onload = () => {
    //         const img = new Image();
    //         img.src = reader.result as string;
    //         img.onload = () => {
    //             const height = img.naturalHeight;
    //             const width = img.naturalWidth;
    //             if (height > 57 || width > 57) {
    //                 this.notificationService.error(
    //                     'Le pictogramme est trop gros, il doit avoir une taille max de 57 pixels.'
    //                 );
    //                 this.editPicture = false;
    //             } else {
    //                 this.typeElementAControler.icone = picture;
    //                 this.fileUploader.handleUploadFileServiceCall(this.recupUrlImage());
    //             }
    //         };
    //     };
    // }

    uploadIconeElement(formData: FormData) {
        const file = formData.get('file') as File;
        CnmapImageUtils.getBase64(file).then((data) => {
            this.imageCompress.compressFile(data as string, -1, 100, 80, 120, 120).then((comp) => {
                this.imgPerso = this.verifySizeDocument(comp);
            });
        });
    }

    private verifySizeDocument(currentDocumentUrl: string): string {
        const imageBlob = dataUrlToBlob(currentDocumentUrl);
        if (imageBlob.size > 0.1 * 1048576) {
            this.notificationService.error("L'image est trop volumineuse. Veuillez en utiliser une autre.");
            return null;
        } else {
            return currentDocumentUrl;
        }
    }

    deleteIconeElement() {
        this.imgPerso = '';
    }
}
