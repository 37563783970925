import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { StateChoiceBoxes } from '../../../model/states.model';
import { notValidatedOption, validatedOption } from '../../../shared/constants/states.constants';

@Component({
    selector: 'app-global-state-input',
    templateUrl: './global-state-input.component.html',
    styleUrls: ['./global-state-input.component.scss'],
})
export class GlobalStateInputComponent extends BaseComponent implements OnInit, OnDestroy {
    @Input()
    choices: StateChoiceBoxes[] = [validatedOption, notValidatedOption];

    @Input()
    disabled = false;

    @Input()
    tooltips: string[] = [];

    @Output()
    changeAllChoice = new EventEmitter<string>();

    constructor() {
        super();
    }

    ngOnInit() {}

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    changeAllChoiceValue(value: string) {
        this.changeAllChoice.emit(value);
    }
}
