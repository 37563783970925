import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { HttpErrorService } from 'src/app/commons-lib';
import { CategorieCommentairePredefini } from '../model/categorie-commentaire-predefini.model';
import { CategorieCommentairePredefiniApiService } from './categorie-commentaire-predefini-api.service';

@Injectable({
    providedIn: 'root',
})
export class CategorieCommentairePredefiniService {
    constructor(
        private readonly categorieCommentairePredefiniApiService: CategorieCommentairePredefiniApiService,
        private readonly httpErrorService: HttpErrorService
    ) {}

    /**
     * Renvoie une categorie de commentaire prédéfini
     */
    findOne(id: string): Observable<CategorieCommentairePredefini> {
        return this.categorieCommentairePredefiniApiService.findOne(id).pipe(
            catchError((err) => throwError(err)),
            catchError((err) => this.httpErrorService.handleError(err))
        );
    }

    /**
     * Renvoie la liste complète des catégories
     */
    findAll(): Observable<CategorieCommentairePredefini[]> {
        return this.categorieCommentairePredefiniApiService.findAll().pipe(
            catchError((err) => throwError(err)),
            catchError((err) => this.httpErrorService.handleError(err))
        );
    }

    /**
     * Crée ou modifie une catégorie
     * @param categorie
     */
    upsert(categorie: CategorieCommentairePredefini): Observable<CategorieCommentairePredefini> {
        return this.categorieCommentairePredefiniApiService.upsert(categorie).pipe(
            catchError((err) => throwError(err)),
            catchError((err) => this.httpErrorService.handleError(err))
        );
    }

    buildCategoriesCommentairePredefini$(): Observable<CategorieCommentairePredefini[]> {
        return this.findAll().pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }
}
