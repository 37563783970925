<div class="mpca-list-select" [class.disabled]="!isInEditMode">
    <div class="mat-card">
        <div>Liste des differents MPCA <span *ngIf="mandatory" class="mandatory">*</span></div>
        <div *ngIf="isInEditMode">
            <button mat-button [matMenuTriggerFor]="menu" data-cy="ajouter-mpca-button">
                <mat-icon>add</mat-icon>
            </button>
            <mat-menu #menu="matMenu" disabled>
                <!-- Si 0 MPCA -->
                <ng-container *ngIf="mpcaList && mpcaList.length == 0">
                    <div style="padding: 12px">Aucun MPCA n'est enregistré</div>
                </ng-container>
                <ng-container *ngIf="mpcaList && mpcaList.length != 0">
                    <div *ngFor="let mpca of mpcaList">
                        <button mat-menu-item *ngIf="!mpcaIsAlreadySelected(mpca.id)" (click)="addItem(mpca.id)">
                            {{ mpca.general.materiauxClient }}
                        </button>
                    </div>
                </ng-container>
            </mat-menu>
        </div>
    </div>

    <div *ngFor="let mpca of selectedMpcaList; let index = index" class="mpca-list-select-card">
        <app-item-card>
            <div title>{{ mpca.general.materiauxClient }}</div>
            <div description>{{ mpca.general.etatDegradation }}</div>
            <div *ngIf="isInEditMode" action class="d-flex justify-content-between">
                <button
                    mat-stroked-button
                    [class]="mpca.general.etatDegradation === 'Retiré' ? 'btn-green-not-selected' : 'btn-green-selected'"
                    (click)="encapsuler.emit(mpca.id)"
                >
                    Encapsuler
                </button>
                <button
                    mat-stroked-button
                    [class]="mpca.general.etatDegradation === 'Retiré' ? 'btn-orange-selected' : 'btn-orange-not-selected'"
                    (click)="retirer.emit(mpca.id)"
                >
                    Retirer
                </button>
                <button mat-button color="warn" (click)="remove(mpca.id)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </app-item-card>
    </div>
</div>
