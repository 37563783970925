<table
    class="w-100"
    mat-table
    [dataSource]="dataSource"
    matSort
    [matSortActive]="sort?.active"
    [matSortDirection]="sort?.direction"
    matSortDisableClear="true"
>
    <ng-container matColumnDef="nom">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="pr-2">Nom</span></th>
        <td mat-cell *matCellDef="let row">{{ row?.nom }}</td>
    </ng-container>

    <ng-container matColumnDef="organisme">
        <th mat-header-cell *matHeaderCellDef><span class="pr-2">Organisme</span></th>
        <td mat-cell *matCellDef="let row">{{ row?.organisme }}</td>
    </ng-container>
    <ng-container matColumnDef="numero">
        <th mat-header-cell *matHeaderCellDef><span class="pr-2">N° de certification</span></th>
        <td mat-cell *matCellDef="let row">{{ row?.numero }}</td>
    </ng-container>
    <ng-container matColumnDef="login">
        <th mat-header-cell *matHeaderCellDef><span class="pr-2">Identifiant</span></th>
        <td mat-cell *matCellDef="let row">{{ row?.login }}</td>
    </ng-container>

    <ng-container matColumnDef="certificat">
        <th mat-header-cell *matHeaderCellDef><span class="pr-2">Certificat</span></th>
        <td mat-cell *matCellDef="let row">{{ row.competenceDocument?.typeDocument.nom }}</td>
    </ng-container>

    <ng-container matColumnDef="dateObtention">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="pr-2">Date de début</span></th>
        <td mat-cell *matCellDef="let row">{{ row?.dateObtention | date: DATE_FORMAT_FRANCAIS_SANS_HEURE }}</td>
    </ng-container>

    <ng-container matColumnDef="dateFin">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="pr-2">Date d'échance</span></th>
        <td mat-cell *matCellDef="let row">
            {{ row?.dateFin | date: DATE_FORMAT_FRANCAIS_SANS_HEURE }}
        </td>
    </ng-container>

    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let row">
            <button
                type="button"
                class="size-div-eye"
                mat-icon-button
                (click)="row?.competenceDocument?.id ? onClickOpenDocument(row?.competenceDocument) : ''"
            >
                <mat-icon>visibility</mat-icon>
            </button>
            <button *ngIf="canEdit" mat-icon-button type="button" [matMenuTriggerFor]="menu">
                <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item type="button" (click)="onEditButtonClicked(row)">
                    <mat-icon>edit</mat-icon>
                    <span>Éditer</span>
                </button>
                <button mat-menu-item type="button" (click)="onDeleteButtonClicked(row)">
                    <mat-icon>delete</mat-icon>
                    <span>Supprimer</span>
                </button>
            </mat-menu>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
