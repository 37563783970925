<div
    class="point-controle-volume-item"
    [ngClass]="{
        'point-controle-volume-item--activated': checked,
        'point-controle-volume-item--disabled': disabled,
        hidden: !visible
    }"
    (click)="selected()"
>
    <mat-checkbox class="mr-1" [checked]="disabled ? true : checked" [disabled]="true"></mat-checkbox>
    {{ label }}
</div>
