import { Component, OnInit } from '@angular/core';
import { MarkdownService } from '../markdown/markdown.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Environment } from '../environment';

@Component({
    selector: 'lib-mentions-legales',
    templateUrl: './mentions-legales.component.html',
    styleUrls: ['./mentions-legales.component.css'],
})
export class MentionsLegalesComponent implements OnInit {
    mentionsLegalesHtml$: Observable<string>;

    constructor(private markdownService: MarkdownService) {}

    ngOnInit(): void {
        this.mentionsLegalesHtml$ = this.markdownService.getMarkdownFile(`assets/legal/mentions-legales.md`).pipe(
            map((text) => text.replace(/{{appName}}/g, Environment.appName)),
            map((text) => this.markdownService.convertToHTML(text))
        );
    }
}
