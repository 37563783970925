import { MatDialogConfig } from '@angular/material/dialog';

export class DialogUtils {
    static configFullScreen(): MatDialogConfig {
        return {
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '100%',
            panelClass: 'fullscreen-dialog',
        };
    }
}
