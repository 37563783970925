<div class="checkpoint-content">
    <app-progress-diagnostic></app-progress-diagnostic>
    <mat-sidenav-container class="sidenav-container">
        <mat-sidenav [mode]="'side'" [opened]="true" class="sidebar-button">
            <div class="d-flex flex-column w-100 justify-content-end">
                <mat-button-toggle-group appearance="legacy" [vertical]="true">
                    <mat-button-toggle [checked]="true" matTooltip="Vue liste" value="list">
                        <mat-icon>list</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle [routerLink]="routePlan" matTooltip="Vue plan" value="plan">
                        <mat-icon>layers</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <mat-divider></mat-divider>
            </div>
        </mat-sidenav>
        <mat-sidenav-content class="main main-fullscreen">
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
