<div class="point-controle-bien-panel">
    <div class="overlay" *ngIf="disabled"></div>
    <div class="header">
        <div class="header-row">
            <app-widget-select theme="white" class="app-widget-input" [(ngModel)]="indexBienSelected">
                <option *ngFor="let item of biens; let index = index" [value]="index">{{ item.nomBien }}</option>
            </app-widget-select>
            <app-vue-switch-toggle></app-vue-switch-toggle>
        </div>

        <div class="header-row mt-3">
            <app-widget-select theme="dark" class="app-widget-input" [(ngModel)]="indexNiveauSelected">
                <option
                    *ngFor="let item of biens[indexBienSelected].pointsDeControleNiveaux; let index = index"
                    [value]="index"
                >
                    {{ item.nom }}
                </option>
            </app-widget-select>
            <div class="next-previous">
                <app-next-previous-button
                    (clicked)="navigationVolume($event)"
                    [previousDisabled]="toNumber(indexNiveauSelected) === 0"
                    [nextDisabled]="
                        toNumber(indexNiveauSelected) + 1 === biens[indexBienSelected].pointsDeControleNiveaux.length
                    "
                ></app-next-previous-button>
            </div>
        </div>
    </div>

    <div class="content">
        <div
            class="item"
            *ngIf="
                !disabled &&
                biens[indexBienSelected].pointsDeControleNiveaux[indexNiveauSelected].pointsDeControleVolumes
                    ?.length as total
            "
        >
            <app-point-controle-volume-item
                class="item"
                [visible]="total - listVolumeDisabled.length > 1"
                [disabled]="false"
                [checked]="total - listVolumeDisabled.length === listVolumeChecked.length - listVolumeDisabled.length"
                (change)="allCheckChange($event)"
                [label]="'Tout sélectionner'"
            ></app-point-controle-volume-item>
        </div>
        <div
            class="item"
            *ngFor="
                let item of biens[indexBienSelected].pointsDeControleNiveaux[indexNiveauSelected]
                    .pointsDeControleVolumes;
                let index = index
            "
        >
            <app-point-controle-volume-item
                class="item"
                [visible]="volumesIdDispo.includes(item.idVolume)"
                [disabled]="listVolumeDisabled.includes(item.idVolume)"
                [checked]="listVolumeChecked.includes(item.idVolume)"
                (change)="volumeChange($event, item.idVolume)"
                [label]="item.nom"
            ></app-point-controle-volume-item>
        </div>
    </div>

    <div class="action">
        <div *ngIf="listVolumeChecked.length">{{ listVolumeChecked.length }} éléments sélectionnés</div>
        <div class="action-button">
            <button mat-stroked-button (click)="cancel()" [disabled]="disabled">Annuler</button>
            <button
                mat-raised-button
                color="primary"
                [disabled]="listVolumeChecked.length === 0 || disabled"
                (click)="save()"
                data-cy="valider-volumes-button"
            >
                Valider
            </button>
        </div>
    </div>
</div>
