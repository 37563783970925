import { ITypePrestationInterface } from '../../../../services/interfaces/type-prestation.interface';
import { Injectable, Type } from '@angular/core';
import { TypePrestation, TypePrestationEnum } from '../../../../model/type-prestation.model';
import { Route } from '@angular/router';
import { ContenuDiagnostic } from '../../../../model/diagnostic-contenu.model';
import { Polluant, PolluantReportData } from '../model/polluant.model';
import { Intervention } from '../../../../model/intervention.model';
import { Diagnostic } from '../../../../model/diagnostic.model';
import { IDiagReportData, InterventionReportData } from '../../../../model/rapport.model';
import { Commentaire } from '../../../../model/commentaire.model';
import { CommentairePredefini } from '../../../../model/commentaire-predefini.model';
import { PolluantService } from './polluant.service';
import { polluantEtudeSituationNavbarRoutes } from '../../polluant/routing/polluant-etude-situation/polluant-etude-situation-routing.module';
import { Rule } from '../../../../model/regle.model';
import { BonCommandeAnalyseAdmin } from '../../../../model/bon-commande.model';
import { Observable, of } from 'rxjs';
import { TypeReport } from 'src/app/model/reference-prestation.model';
import { DocumentsService } from 'src/app/services/documents.service';
import { PollHeaderBlockComponent } from '../report/blocks/poll-header-block/poll-header-block.component';
import { PollIntroductionBlockComponent } from '../report/blocks/poll-introduction-block/poll-introduction-block.component';
import { PollPerimetreBlockComponent } from '../report/blocks/poll-perimetre-block/poll-perimetre-block.component';
import { PollContextBlockComponent } from '../report/blocks/poll-context-block/poll-context-block.component';
import { LegendScreenshot, LegendShape } from 'src/app/model/screenshot-svg.model';
import { cn_building, cn_storey } from '@acenv/cnmap-editor';
import { Bien, Niveau, Volume } from '../../../../model/bien.model';
import { PointDeControleService } from '../../../../services/point-de-controle.service';
import { PollZonesBlockComponent } from '../report/blocks/poll-zones-block/poll-zones-block.component';
import * as moment from 'moment';
import { MpcaItemOld } from '../model/mpca.model.old';
import { SvgFormValues } from '../../../shared/map/export-svg-dialog/shared/interfaces/export-svg-form-values.interface';
import { CnSvgConfiguratorWizy } from '../../../shared/map/export-svg-dialog/cn_svg_configurator_wizy';
import { SVG_FILTER } from '../../../../shared/constants/svg.constants';
import { exportSvgTypes } from '../../../shared/map/export-svg-dialog/shared/conf/export-svg-types.conf';
import { ExportSvgFormConfMapper } from '../../../shared/map/export-svg-dialog/shared/mappers/export-svg-form-mapper';
import { CndiagMarker } from '../../../shared/map/model/cndiag-marker.model';
import { TypeVolume } from '../../../../model/type-element-a-controler.model';

/**
 * Liste des Type de component (Class) de block de rapport disponible pour la composition
 * du contenu d'un raport
 */
export const REPORT_BLOCK_CATALOG: { [key: string]: Type<any> } = {
    PollHeaderBlockComponent: PollHeaderBlockComponent,
    PollIntroductionBlockComponent: PollIntroductionBlockComponent,
    PollPerimetreBlockComponent: PollPerimetreBlockComponent,
    PollZonesBlockComponent: PollZonesBlockComponent,
    PollContextBlockComponent: PollContextBlockComponent,
};

@Injectable({
    providedIn: 'root',
})
export class TypePolluantEtudeSituationService implements ITypePrestationInterface {
    // PARAM volume
    private typePrestation: TypePrestation = TypePrestationEnum.POLLUANT_ETUDE_SITUATION;

    constructor(
        private readonly polluantService: PolluantService,
        private readonly documentsService: DocumentsService,
        private readonly pointDeControleService: PointDeControleService,
        private readonly exportSvgFormConfMapper: ExportSvgFormConfMapper
    ) {}

    generateLegendForScreenshot(diagnostic: Diagnostic, conf: SvgFormValues): LegendScreenshot[] {
        const legendsScreenshot: LegendScreenshot[] = [];
        legendsScreenshot.push({
            nomCategorie: 'Besoins',
            items: [
                {
                    nom: 'Besoins',
                    couleur: '#3394CC',
                    opacity: 1,
                    shapes: [LegendShape.LINE],
                },
            ],
        });

        // Ecran périmètre - Plan de localisation
        try {
            const items = [];
            for (const p of (diagnostic.contenuDiagnostic as Polluant).espaces.valeur[0].listePerimetres) {
                items.push({
                    nom: p.nom,
                    couleur: p.legende.color,
                    opacity: 1,
                    shapes: [LegendShape.EMPTY_SQUARE],
                });
            }
            legendsScreenshot.push({
                nomCategorie: 'Délimitations',
                items,
            });
        } catch (e) {}
        return legendsScreenshot;
    }

    getTypePrestation(): TypePrestation {
        return this.typePrestation;
    }

    getRoutes(): Route[] {
        return polluantEtudeSituationNavbarRoutes;
    }

    getContenuDiagnosticFromParent(diagnosticToUpdate: Diagnostic, diagnosticParent: Diagnostic): ContenuDiagnostic {
        return { ...diagnosticParent.contenuDiagnostic };
    }

    getContenuDiagnostic(typePrestation: TypePrestation): Observable<ContenuDiagnostic> {
        return of(new Polluant());
    }

    getCodeBimEquipementBien(typePrestation: TypePrestation): string[] {
        return [];
    }

    getCompletionPercentage(diagnostic: Diagnostic): number {
        return 0;
    }

    getDiagnosticReportData(intervention: Intervention, diagnostic: Diagnostic, rules?: Rule[], optionPlan?: boolean): IDiagReportData {
        let plan;
        const diagReportData = new PolluantReportData();

        //bypass
        diagReportData.conformiteGlobale = true;
        //bypass
        diagReportData.id = diagnostic.id;
        diagReportData.typePrestation = diagnostic.typePrestation;
        const contenuDiagnostic = diagnostic.contenuDiagnostic as Polluant;
        const biens = intervention.relationInterventionBiens.map((relationBienPrincipal) => relationBienPrincipal.bien);
        let currentBien = diagnostic.pointsDeControleBiens[0];
        const bien = biens.find((b) => b.id === currentBien.idBien);
        /** **** DEBUT PLAN **** **/
        const conf = exportSvgTypes.find((form) => form.id === 'idConfPolluantPerimetre');
        // On set le premier bien séléctionné et le premier niveau
        conf.bien.controls.bien.value = biens[0];
        conf.bien.controls.niveau.value = biens[0].description[0];
        const formValues: any = this.exportSvgFormConfMapper.toFormValues(conf, [], []);
        // formValues.plan.text.showLegend = true;
        const relBien = intervention.relationInterventionBiens.find((rel) => rel.bien.id === bien.id);
        const building = cn_building.unserialize(JSON.parse(relBien.bien.jsonPlan));

        //Perimetre
        diagReportData.perimetre = contenuDiagnostic.perimetreDescriptif.data.description;
        diagReportData.perimetres = [];
        for (const pI of contenuDiagnostic.perimetresInvestigation.data.listePerimetreInvestigation) {
            const jsonPlans = [];
            for (const niveau of biens[0].description) {
                const selectedStorey = building.storeys.find((st) => st.storey_index === biens[0].description.indexOf(niveau));
                selectedStorey.markers = [];
                const legendsScreenshot = [];
                const items = [];
                for (const espace of contenuDiagnostic.espaces.valeur) {
                    if (espace.idBien === formValues.bien.bien.id && espace.idPerimetre === pI.id) {
                        selectedStorey.markers.push(
                            ...espace.listeMarkersJson.map((json) => CndiagMarker.unserialize(JSON.parse(json), selectedStorey))
                        );

                        for (const p of espace.listePerimetres) {
                            items.push({
                                nom: p.nom,
                                couleur: p.legende.color,
                                opacity: 1,
                                shapes: [LegendShape.EMPTY_SQUARE],
                            });
                        }
                    }
                }
                if (items.length) {
                    legendsScreenshot.push({
                        nomCategorie: 'Délimitations',
                        items,
                    });
                }
                // Création d'un nouveau configurator
                const configuratorForExport = new CnSvgConfiguratorWizy(
                    null,
                    selectedStorey,
                    false,
                    undefined,
                    formValues,
                    legendsScreenshot,
                    niveau,
                    []
                );

                // configuratorForExport.set_svg_param('show_zone', { property: 'usage', values: [conf.polluant.controls.zoneId.value] });
                // maj de la conf du configurator
                const height = 2300;
                const width = 3000;
                configuratorForExport.set_print_height(210);
                configuratorForExport.set_resolution(formValues.plan.general.resolution);
                configuratorForExport.set_fixed_position(formValues.plan.general.fixedPosition);
                configuratorForExport.set_fixed_scale(formValues.plan.general.fixedScale);
                configuratorForExport.set_scale(formValues.plan.general.scale);
                configuratorForExport.set_render_size(width, height);
                // Plan > Display Mapping :
                const displayGroups = formValues.plan.display;
                configuratorForExport.set_svg_param('render', displayGroups.render);
                configuratorForExport.set_svg_param('show_grid', displayGroups.showGrid);
                configuratorForExport.set_svg_param('show_scale', false);
                configuratorForExport.set_svg_param('show_background', displayGroups.showBackground);
                configuratorForExport.set_svg_param('show_compass', false);

                // Plan > Elements Mapping :
                const elementsGroups = formValues.plan.elements;
                configuratorForExport.set_svg_param('show_paces', elementsGroups.showSpaces);
                configuratorForExport.set_svg_param('show_outer_walls', elementsGroups.showOuterWalls);
                configuratorForExport.set_svg_param('show_inner_walls', elementsGroups.showInnerWalls);
                configuratorForExport.set_svg_param('show_balconies', elementsGroups.showBalconies);
                configuratorForExport.set_svg_param('show_windows', elementsGroups.showWindows);
                configuratorForExport.set_svg_param('show_doors', elementsGroups.showDoors);
                configuratorForExport.set_svg_param('show_stairs', elementsGroups.showStairs);
                configuratorForExport.set_svg_param('show_slab_openings', elementsGroups.showSlabOpenings);
                configuratorForExport.set_svg_param('show_beams', elementsGroups.showBeams);
                configuratorForExport.set_svg_param('show_columns', elementsGroups.showColumns);
                configuratorForExport.set_svg_param('show_pipes', elementsGroups.showPipes);
                configuratorForExport.set_svg_param('show_objects_shape', elementsGroups.showObjectsShape);
                configuratorForExport.set_svg_param('show_objects_icon', elementsGroups.showObjectsIcon);

                // Plan > Text Mapping :
                const textsGroups = formValues.plan.text;
                configuratorForExport.set_svg_param('space_labels', textsGroups.showSpaceLabel);
                configuratorForExport.set_svg_param('area_visible', textsGroups.showSpaceArea);
                configuratorForExport.set_svg_param('show_markers', true);
                configuratorForExport.set_svg_param('show_numerotation', textsGroups.showNumerotation);

                // Plan > Cotes :
                const cotesGroups = formValues.plan.cotes;
                configuratorForExport.set_svg_param('show_outer_measures', cotesGroups.showOuterMeasures);
                configuratorForExport.set_svg_param('show_inner_measures', cotesGroups.showInnerMeasures);
                configuratorForExport.set_svg_param('show_opening_measures', cotesGroups.showOpeningMeasures);
                configuratorForExport.set_scale(formValues.plan.general.scale);

                // Génération du contenu du SVG
                let jsonPlan = '';
                const svgContent = configuratorForExport.render(width, height);
                let svgTxt = `<svg width="${width}" height="${height}" `;
                svgTxt += " xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>";
                svgTxt += SVG_FILTER.replace('</defs>', '');
                svgTxt += svgContent.replace('<defs>', '');
                svgTxt += '</svg>';
                jsonPlan = svgTxt;
                for (const space of selectedStorey.scene.spaces) {
                    const regex = new RegExp(space.ID, 'g');
                    jsonPlan = jsonPlan.replace(regex, space.ID + new Date().getTime());
                }
                jsonPlan = jsonPlan.replace('.space.exp {stroke: none; fill: rgba(255, 255, 255, 0.5);}', '');
                jsonPlans.push({ niveau: niveau.nom, jsonPlan });
            }
            diagReportData.perimetres.push({
                nom: pI.nom,
                description: pI.description,
                plans: jsonPlans,
            });
        }
        /** **** FIN PLAN **** **/

        //Récupération des processus et lien avec mpca
        const processus = contenuDiagnostic.mpcaList.data.descriptifList.reduce((acc, mpca) => {
            if (mpca.processus && mpca.processus.length > 0) {
                acc = acc.concat(
                    mpca.processus.map((pr) => {
                        //Empêche dépendances cycliques
                        let newMpca = { ...mpca };
                        newMpca.processus = undefined;
                        pr.mpca = newMpca;
                        return pr;
                    })
                );
            }
            return acc;
        }, []);
        //Si le plan est utilisé, alors on charge ses données pour récupérer la superficie des pièces
        if (bien && bien.jsonPlan) {
            plan = cn_building.unserialize(JSON.parse(bien.jsonPlan));
        }

        diagReportData.zones = contenuDiagnostic.zones.data.zonesList.map((zone) => {
            zone.hasSurfacesInterface = false;
            zone.isZoneDeTravail = zone.typeZone == 'Zone de travail';
            zone.hasMeta = false;
            zone.hasMetop = false;

            zone.listeMpca = zone.listeMpca.map((mpca: MpcaItemOld) => {
                // Duplication de l'item MPCA
                const nMpca = JSON.parse(JSON.stringify(mpca));
                nMpca.nom = nMpca.general.nom;
                nMpca.typeAmiante = nMpca.general.typeAmiante.join(', ');
                return nMpca;
            });

            zone.listeSurfaces = zone.listeSurfaces.map((surface) => {
                surface.listeVolumes = surface.listeIdVolume.map((idVolume) => {
                    //Récupération des données du volume dans la description du bien
                    const volume = intervention.relationInterventionBiens[0].bien.description
                        .reduce((acc, niveau) => {
                            acc = acc.concat(niveau.volumes);
                            return acc;
                        }, [])
                        .find((volume) => volume.id == idVolume);

                    //Récupération des données du plan de ce volume
                    volume.plan = [
                        plan.storeys
                            .reduce((acc, storey) => {
                                acc = acc.concat(storey.scene.spaces);
                                return acc;
                            }, [])
                            .find((space) => space.ID == volume.spaceId),
                    ].map((space) => {
                        return {
                            ID: space.ID,
                            area: space.area.toFixed(2),
                        };
                    })[0];

                    //Calcul du nombre de PU du volume
                    let puNb;
                    if (surface.longueurInterface > 0 && surface.hauteurInterface > 0) {
                        puNb = Math.ceil(
                            (14 * surface.longueurInterface * surface.hauteurInterface) / (730 + surface.longueurInterface * surface.hauteurInterface)
                        );
                    } else if (surface.superficie <= 100 && surface.longueurMax <= 15) {
                        puNb = 1;
                    } else if (surface.superficie <= 100 && surface.longueurMax > 15) {
                        puNb = Math.ceil(surface.longueurMax / 15);
                    } else if (surface.superficie > 100) {
                        puNb = Math.ceil((14 * surface.superficie) / (730 + surface.superficie));
                    } else {
                        puNb = 1;
                    }
                    volume.puNb = puNb;

                    return volume;
                });

                if (surface.surfaceInterface != 0) {
                    zone.hasSurfacesInterface = true;
                }

                return surface;
            });

            // zone.environnement = zone.environnement;

            zone.besoins = contenuDiagnostic.besoins.data.besoinsList.map((besoin) => {
                if (besoin.objectifMesurage.norme == 'NF X43-050') {
                    zone.hasMeta = true;
                    besoin.isMeta = true;
                    besoin.isMetop = false;
                } else if (besoin.objectifMesurage.norme == 'NF X43-269') {
                    zone.hasMetop = true;
                    besoin.isMeta = false;
                    besoin.isMetop = true;
                }

                //Transformation des formats durée
                besoin.objectifMesurage.dureeMinHum = moment.duration(besoin.objectifMesurage.dureeMin).locale('fr').humanize();
                besoin.objectifMesurage.dureeMaxHum = moment.duration(besoin.objectifMesurage.dureeMax).locale('fr').humanize();

                //récupération du processus associé au besoin
                if (besoin.processusId) {
                    besoin.processus = processus.find((pr) => pr.id == besoin.processusId);
                }

                return besoin;
            });

            return zone;
        });

        diagReportData.refRapport = diagnostic.reportDatas.find((reportDataTemp) => reportDataTemp.typeReport === TypeReport.REPORT).refRapport;

        diagReportData.introduction = contenuDiagnostic.introduction.data.introduction;
        diagReportData.complement = contenuDiagnostic.introduction.data.complement;
        diagReportData.mpcaList = contenuDiagnostic.mpcaList.data.descriptifList;

        diagReportData.recommandations = null;
        diagReportData.constatationsDiverses = null;
        diagReportData.annexes = null;
        diagReportData.reportagesPhotos = null;
        diagReportData.documentsData = this.documentsService.buildDocumentsData(intervention, diagnostic);

        diagReportData.etat = diagnostic.etat;
        return diagReportData;
    }

    getReportBlockType(componentName: string): Type<any> {
        const blockType = REPORT_BLOCK_CATALOG[componentName];
        if (!blockType) {
            console.log('Block %s not found', componentName);
        }
        return blockType;
    }

    isItemAlreadyFilled(diagnostic: Diagnostic, type: string, itemId: string): boolean {
        return false;
    }

    prepareFilteredCommentsForReport(diagnosticData: IDiagReportData, hiddenComments: Map<string, string[]>) {}

    prepareForm(intervention: Intervention, contenuDiagnostic: ContenuDiagnostic) {
        this.polluantService.prepareFormActiviteAmiante(intervention, contenuDiagnostic as Polluant);
    }

    prepareSpecificComments(diagnostic: Diagnostic, commentairesGeneraux: Commentaire[], commentaires: CommentairePredefini[]) {}

    getDiagnosticBonCommandeData(intervention: Intervention, diagnostic: Diagnostic): IDiagReportData {
        return undefined;
    }

    generateDiagnosticBonCommande(
        intervention: Intervention,
        diagnostic: Diagnostic,
        interReportData: InterventionReportData
    ): BonCommandeAnalyseAdmin {
        return undefined;
    }

    prepareStoreyForScreenshot(diagnostic: Diagnostic, currentStorey: cn_storey, conf: any) {
        const idBien = conf.bien?.id;
        const idNiveau = conf.bien?.niveau?.id;
        this.polluantService.populateStoreyWithMarkers(diagnostic, currentStorey, idBien, idNiveau);
    }

    deplaceVolume(diagnostic: Diagnostic, volumeSource: Volume, niveauDestination: Niveau, currentBien: Bien) {
        this.pointDeControleService.deplaceVolume(diagnostic, volumeSource, niveauDestination, currentBien);
    }

    mergeNiveau(diagnostic: Diagnostic, niveauSource: Niveau, niveauDestination: Niveau, currentBien: Bien) {
        this.pointDeControleService.mergeNiveau(diagnostic, niveauSource, niveauDestination, currentBien);
    }

    mergeVolume(diagnostic: Diagnostic, volumeSource: Volume, volumeDestination: Volume, currentBien: Bien, typeVolume: TypeVolume) {
        this.pointDeControleService.mergeVolume(diagnostic, volumeSource, volumeDestination, currentBien, typeVolume);
    }

    deplaceEquipement(idEquipement: string, diagnostic: Diagnostic, volumeDestination: Volume, bien: Bien) {
        this.pointDeControleService.deplaceEquipement(idEquipement, diagnostic, volumeDestination, bien);
    }
}
