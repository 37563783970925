import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { combineLatest, of } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { Diagnostic, ReportagePhoto, ReportagePhotoImageFile } from 'src/app/model/diagnostic.model';
import { InterventionReportData, IReportComponent, ReportBlockData } from 'src/app/model/rapport.model';
import { DiagnosticService } from 'src/app/services/diagnostic.service';
import { InterventionService } from 'src/app/services/intervention.service';
import { combineLatestOrEmpty } from 'src/app/utils/rxjs.utils';
import { FileApiService } from '../../../../../services/file-api.service';
import { InterventionFile } from '../../../../../model/intervention-file.model';
import { InterventionFileService } from '../../../../../services/intervention-file.service';
import { CnSpinnerService } from '../../../../shared/cn-spinner/service/cn-spinner.service';
import { ReportagePhotoService } from '../../../../../services/reportage-photo.service';
import { Intervention } from 'src/app/model/intervention.model';

@Component({
    selector: 'app-reportage-photo-block',
    templateUrl: './reportage-photo-block.component.html',
    styleUrls: ['./reportage-photo-block.component.scss'],
})
export class ReportagePhotoBlockComponent extends BaseComponent implements OnInit, IReportComponent {
    @Input() data: ReportBlockData;
    @Input() intervention: Intervention;
    @Input() interReportData: InterventionReportData;

    currentDiagnostic: Diagnostic;

    mapImagesFileByReportagePhotoId: Map<string, ReportagePhotoImageFile[]> = new Map();

    // Utilisé pour la création du nombre de page souhaité
    array = Array;
    math = Math;

    constructor(
        private readonly diagnosticService: DiagnosticService,
        private readonly interventionService: InterventionService,
        private readonly fileApiService: FileApiService,
        private readonly interventionFileService: InterventionFileService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly reportagePhotoService: ReportagePhotoService
    ) {
        super();
    }

    ngOnInit(): void {
        // Récupération de l'intervention courante et de la liste des biens possédant des plans + screenshots
        this.cnSpinnerService
            .withSpinner(
                combineLatest([this.interventionService.getCurrentIntervention(), this.diagnosticService.getCurrentDiagnostic()]).pipe(
                    switchMap(([intervention, diagnostic]) => {
                        return combineLatestOrEmpty([
                            this.interventionFileService.getInterventionFilesOfDiagnostic(intervention, diagnostic),
                            this.interventionFileService.getInterventionFilesOfInterventionForReportagePhoto(intervention),
                        ]).pipe(
                            map(([interventionFilesOfDiagnostic, interventionFilesOfIntervention]) => {
                                return interventionFilesOfDiagnostic.concat(interventionFilesOfIntervention);
                            }),
                            switchMap((interventionFiles) => {
                                return combineLatest([
                                    of(interventionFiles),
                                    this.reportagePhotoService.generateReportagesAuto(intervention, diagnostic, interventionFiles),
                                ]);
                            }),
                            switchMap(([interventionFiles]) => {
                                const interventionFilesFiltered = this.filterInterventionFile(diagnostic.reportagesPhoto, interventionFiles);
                                return combineLatest([
                                    of(diagnostic),
                                    combineLatestOrEmpty(this.reportagePhotoService.getFiles(intervention, interventionFilesFiltered)),
                                ]);
                            })
                        );
                    })
                )
            )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([diagnostic, files]) => {
                this.currentDiagnostic = diagnostic;
                // Parcours de l'ensemble des reportages photos afin de récupérer toutes les images
                this.currentDiagnostic.reportagesPhoto.forEach((reportagePhoto) => {
                    const tabPhotosReportage: ReportagePhotoImageFile[] = [];
                    reportagePhoto.photos.forEach((detailPhoto) => {
                        const fileExist = files.find((file) => file.fileId === detailPhoto.idFichier);
                        // Si le fichier existe on le rajoute au tableau, sinon on crée une instance vide
                        // (afin d'afficher l'icône comme quoi il n'y a pas d'image)
                        const reportageImageFile: ReportagePhotoImageFile = new ReportagePhotoImageFile();
                        reportageImageFile.fileId = detailPhoto.idFichier;
                        reportageImageFile.legende = detailPhoto.legende;
                        reportageImageFile.creationDate = detailPhoto.creationDate;
                        reportageImageFile.gpsLatitudeRef = detailPhoto.gpsLatitudeRef;
                        reportageImageFile.gpsLatitude = detailPhoto.gpsLatitude;
                        reportageImageFile.gpsLongitudeRef = detailPhoto.gpsLongitudeRef;
                        reportageImageFile.gpsLongitude = detailPhoto.gpsLongitude;
                        reportageImageFile.gpsAltitudeRef = detailPhoto.gpsAltitudeRef;
                        reportageImageFile.gpsAltitude = detailPhoto.gpsAltitude;
                        reportageImageFile.objetReference = detailPhoto.objetReference;
                        reportageImageFile.nonConformiteReference = detailPhoto.nonConformiteReference;
                        reportageImageFile.localisations = detailPhoto.localisations;

                        if (fileExist) {
                            reportageImageFile.fileContent = fileExist.fileContent;
                        }
                        tabPhotosReportage.push(reportageImageFile);
                    });
                    // On rajoute la liste des photos dans la map
                    this.mapImagesFileByReportagePhotoId[reportagePhoto.id] = tabPhotosReportage;
                });
            });
    }

    /**
     * Filtre le tableau InterventionFile pour ne garder que les occurences contenus dans le reportage photo
     * @param reportagesPhoto
     * @param interventionFiles
     */
    private filterInterventionFile(reportagesPhoto: ReportagePhoto[], interventionFiles: InterventionFile[]): InterventionFile[] {
        const photoIds = reportagesPhoto
            .map((reportagePhoto) => {
                return reportagePhoto.photos.map((photo) => {
                    return photo.idFichier;
                });
            })
            .flat();

        return interventionFiles.filter((interventionFile) => {
            return photoIds.includes(interventionFile.fileId);
        });
    }
}
