export class ConclusionElement {
    nom: string;
    id: string;
    lot: string;
    elements: ConclusionElement[] = [];

    constructor(id: string, nom: string) {
        this.id = id;
        this.nom = nom;
    }
}
