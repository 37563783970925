import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../utils/base.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '../../notification/notification.service';
import { Environment } from '../../environment';

@Component({
    selector: 'lib-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseComponent implements OnInit, OnDestroy {
    loginForm: FormGroup;
    private params: Params;

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.login();
            event.preventDefault();
        }
    }

    constructor(
        private authenticationService: AuthenticationService,
        private fb: FormBuilder,
        private router: Router,
        private activateRoute: ActivatedRoute,
        private notificationService: NotificationService
    ) {
        super();
    }

    ngOnInit() {
        this.loginForm = this.fb.group({
            email: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(4)]],
        });

        this.activateRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => (this.params = params));
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    login() {
        if (!this.loginForm.valid) {
            return;
        }
        this.notificationService.notify('Connexion en cours...');
        this.authenticationService.login(this.loginForm.value).subscribe(
            () => {
                this.notificationService.dismiss();
                const url = this.params.linkUrl || Environment.defaultLandingPage;
                this.router.navigate([url]);
            },
            (error: HttpErrorResponse) => {
                this.notificationService.dismiss();
                if (error && (error.status === 0 || error.status === 502 || error.status === 503 || error.status === 504)) {
                    // Service indisponible -> message général de l'application
                    return;
                }
                let message;
                if (error && error.status === 401) {
                    message = error.error.detail.replace('Les identifications sont erronées', 'Les identifiants sont erronés'); // Remplace le message de Spring ;-)
                } else {
                    message = 'Impossible de vous connecter. Merci de vérifier vos identifiants ou de réessayer ultérieurement';
                }
                this.notificationService.error(message);
            }
        );
    }
}
