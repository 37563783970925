import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

import { blobToDataUrl } from '../../../../shared/offline/file.utils';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { BaseComponent, ConfirmationService, FileUploaderComponent, MongoUtils, NotificationService } from 'src/app/commons-lib';

import { InterventionFile, TypeReferenceFichier } from '../../../../model/intervention-file.model';
import { FileApiService } from '../../../../services/file-api.service';
import { InterventionFileApiService } from '../../../../services/intervention-file-api.service';
import { InterventionFileService } from '../../../../services/intervention-file.service';
import { CnSpinnerService } from '../../cn-spinner/service/cn-spinner.service';
import { FileService } from '../../../../services/file.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Observable } from 'rxjs';
import { FileUploaderOutput, FileUploaderTextConfigFile } from '../wizy-file-uploader.component';

@Component({
    selector: 'app-picture-file-uploader',
    templateUrl: './picture-file-uploader.component.html',
    styleUrls: ['./picture-file-uploader.component.scss'],
})
export class PictureFileUploaderComponent extends BaseComponent implements OnInit {
    @Input()
    dataUrl: string = null;
    @Input()
    fileId: string = null;
    @Input()
    useDownloadPipe = false;

    constructor() {
        super();
    }

    ngOnInit(): void {}
}
