import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, Form, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent, ConfirmationService } from 'src/app/commons-lib';
import { DATE_FORMAT_FRANCAIS_SANS_HEURE } from 'src/app/shared/constants/cndiag.constants';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

export interface EditValeursParametresData {
    placeholder: string;
    values: string[];
    readOnly: boolean;
}

@Component({
    selector: 'app-edit-valeurs-parametres-modal',
    templateUrl: './edit-valeurs-parametres-modal.component.html',
    styleUrls: ['./edit-valeurs-parametres-modal.component.scss'],
})
export class EditValeursParametresModalComponent extends BaseComponent implements OnInit {
    form: FormGroup = this.formBuilder.group({
        values: this.formBuilder.array([]),
    });

    valuesControls: FormControl[];

    constructor(
        public readonly dialogRef: MatDialogRef<EditValeursParametresModalComponent>,
        private readonly formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public readonly data: EditValeursParametresData
    ) {
        super();
        dialogRef.disableClose = false;
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth1000-dialog');
        if (this.data) {
            this.data.values?.forEach((v) => (this.form.get('values') as FormArray).push(this.formBuilder.control(v)));
            this.valuesControls = (this.form.get('values') as FormArray).controls as FormControl[];
        }
    }

    /**
     * Action déclenchée lors de l'envoi du formulaire
     */
    onSubmit() {
        if (this.form.valid && !this.data.readOnly) {
            this.dialogRef.close(this.form.value);
        }
    }

    /**
     * Action lors du click sur annuler
     */
    onClickCancel() {
        this.dialogRef.close(false);
    }

    deleteItem(i: number) {
        (this.form.get('values') as FormArray).removeAt(i);
    }

    drop(event: CdkDragDrop<FormArray>): void {
        moveItemInArray(this.valuesControls, event.previousIndex, event.currentIndex);
    }

    trackByFn(index: number, item: any): number {
        return index; // or item.id if you have a unique identifier
    }

    addValue() {
        (this.form.get('values') as FormArray).push(this.formBuilder.control(''));
    }
}
