import { BuildingStore, IconData, IEditorHandler } from '@acenv/cnmap-angular-editor-lib';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationStore, ConfirmationService, NotificationService } from 'src/app/commons-lib';
import { InterventionService } from '../../../../../services/intervention.service';
import { BienService } from '../../../../../services/bien.service';
import { map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { CnSpinnerService } from '../../../cn-spinner/service/cn-spinner.service';
import { Backup } from '@acenv/cnmap-angular-editor-lib/lib/model/backup.model';
import { HttpClient } from '@angular/common/http';
import { ReferenceService } from '../../../../../services/reference.service';
import { ParametersGroup, WorkType } from '@acenv/commons-lib';
import { TypeParametre } from '../../../../../model/definition-parametre.model';
import { SyncBienPlanService } from '../../../../../services/sync-bien-plan.service';

@Injectable({
    providedIn: 'root',
})
export class EditorHandler implements IEditorHandler {
    constructor(
        private interventionService: InterventionService,
        private buildingStore: BuildingStore,
        private bienService: BienService,
        private notificationService: NotificationService,
        private confirmationService: ConfirmationService,
        private authenticationStore: AuthenticationStore,
        private cnSpinnerService: CnSpinnerService,
        private syncBienPlanService: SyncBienPlanService,
        private router: Router,
        private http: HttpClient,
        private referenceService: ReferenceService
    ) {}

    enableObjectsParametersFavorites(): Observable<boolean> {
        return of(false);
    }
    getObjectsParametersFavorites(): Observable<{ [codeBim: string]: boolean }> {
        return of({});
    }
    setObjectsParameterFavorite(codeBim: string, favorite: boolean): Observable<void> {
        throw new Error('Method not implemented.');
    }
    getDrawingId(): string {
        throw new Error('Method not implemented.');
    }

    canExportBbp(): Observable<boolean> {
        return of(false);
    }

    getCurrentUserName(): Observable<string> {
        return this.authenticationStore.getCurrentUser().pipe(map((user) => `${user.lastName} ${user.firstName}`));
    }

    getBuildingName(): Observable<string> {
        return this.bienService.getCurrentBien().pipe(map((bien) => bien.bien.nom));
    }

    getName(): Observable<string> {
        return this.bienService.getCurrentBien().pipe(map((bien) => bien.bien.nom));
    }

    getLocalization(): Observable<{ address: string; postCode: string; city: string }> {
        return this.bienService.getCurrentBien().pipe(
            map((currentBien) => ({
                address: currentBien.bien.adresse.voie ? currentBien.bien.adresse.voie : '',
                postCode: currentBien.bien.adresse.codePostal ? currentBien.bien.adresse.codePostal : '',
                city: currentBien.bien.adresse.ville ? currentBien.bien.adresse.ville : '',
            }))
        );
    }

    getNotificationIcon(): Observable<IconData> {
        // const drawing = this.drawingService.getCurrentDrawingValue();
        // return this.offlineStorageService.getUnsyncedDrawingsIds().pipe(
        //     map(unsyncedDrawingsIds => {
        //         if (unsyncedDrawingsIds.includes(drawing.id)) {
        //             return new IconData('sync_disabled', 'Maquette non synchronisée sur le serveur', 'icon-error');
        //         } else {
        //             return null;
        //         }
        //     })
        // );
        return of(null);
    }

    isReadOnly(): Observable<boolean> {
        // return this.projectService.getCurrentProject().pipe(map(project => project.extendedRights === 'GUEST_RO'));
        return of(false);
    }

    navigateToEditor(toUrl: string, relativeTo: ActivatedRoute, state?) {
        localStorage.setItem('editorBackUrl', this.router.url);

        if (relativeTo) {
            this.router.navigate([toUrl], {
                state: state,
                relativeTo,
            });
        } else {
            this.router.navigate([toUrl], {
                state: state,
            });
        }
    }

    back() {
        const backUrl = localStorage.getItem('editorBackUrl');

        if (backUrl) {
            this.router.navigate([backUrl]);
        } else {
            const back = this.router.url.split('/bien/')[0];
            this.router.navigate([back]);
        }
    }

    save(): Observable<void> {
        const relationInterventionBien = this.bienService.getCurrentBienValue();
        const currentIntervention = this.interventionService.getCurrentInterventionValue();
        return this.cnSpinnerService.withSpinner(
            this.buildingStore.getCurrentBuilding().pipe(
                withLatestFrom(
                    this.referenceService.findAllTypesVolumes(),
                    this.referenceService.findAllCategoriesOuvrages(),
                    this.referenceService.findAllCategoriesOuvragesMappings()
                ),
                take(1),
                switchMap(([building, typesVolume, categoriesOuvrages, categoriesOuvragesMappings]) => {
                    const json = building.serialize();
                    /*let niveauIndex = 0;
                    for (const storey of json.storeys) {
                        let index = 0;
                        for (const space of storey.scene.spaces) {
                            if (!space.name) {
                                if (index === 0) {
                                    space.name = 'Extérieur';
                                } else {
                                    space.name = 'Espace ' + niveauIndex + '.' + index;
                                }
                            }
                            index++;
                        }
                        niveauIndex++;
                    }*/
                    const bien = currentIntervention.relationInterventionBiens.find((it) => it === relationInterventionBien).bien;
                    bien.jsonPlan = JSON.stringify(json);
                    this.syncBienPlanService.updateBienFromMap(bien, building, [], typesVolume, [], categoriesOuvrages, categoriesOuvragesMappings);
                    return this.interventionService.updateIntervention(currentIntervention).pipe(
                        tap(() => {
                            this.notificationService.success('Maquette enregistrée');
                            // this.interventionService.reloadCurrentIntervention();
                            // Indique que toutes les modifications ont été sauvées
                            this.bienService.setPlanModified(false);
                            this.buildingStore.setCurrentBuildingSaved();
                        }),
                        map(() => {})
                    );
                })
            ),
            'Sauvegarde en cours...'
        );
    }

    saveAs(): Observable<void> {
        return of(void 0);
    }

    navigateToBackgroundMapManager() {
        const backgroundUrl = this.router.url.split('/map/')[0] + '/background-maps';
        this.router.navigate([backgroundUrl]);
    }

    autoSave(backups: Backup[]): Observable<void> {
        return of(null);
    }

    getWikipimParametersGroup(): Observable<ParametersGroup[]> {
        return of([]);
    }

    getWikipimParametersHidden(): Observable<string[]> {
        return of([]);
    }

    setWikipimParameterHidden(codeBim: string, hidden: boolean): Observable<string[]> {
        return of([]);
    }
}
