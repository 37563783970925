'use strict';
import { cn_zpso } from '..';
//***********************************************************************************
//***********************************************************************************
//**** cn_svg_tool_zpso  : ZPSO Manager
//***********************************************************************************
//***********************************************************************************
import { cn_svg_map } from './cn_svg_map';
import { cn_svg_tool_layer, SUPPORT_OTHER, SUPPORT_WALL } from './cn_svg_tool_layer';

/**
 */
export class cn_svg_tool_zpso extends cn_svg_tool_layer {
    //***********************************************************************************
    /**
     * Constructor
     * @param {cn_svg_map} svg_map
     * @param {boolean} roofs : true if we are dealing with roof slabs
     */
    constructor(svg_map, roofs) {
        super(svg_map, roofs);
        this._type_wording = 'une ZPSO';
        this.all_support_rendering = true;
        this._wording_entry_key = 'zpso';
    }

    set_all_support_rendering(value) {
        this.all_support_rendering = value;
        if (!this.all_support_rendering) {
            this._scene.draw_layer_support = this.support;
            this.clear_move();
            this.unset_edit_handler();
        } else {
            this._scene.draw_layer_support = '';
        }
    }

    /**
     * Set tool support : wall, floor or ceil
     *
     * @param {string} support
     */
    set_support(support) {
        this.support = support
        if (!this.all_support_rendering) {
            this._scene.draw_layer_support = this.support;
            this.clear_move();
            this.unset_edit_handler();
        }
    }

    _has_elements() {
        return this._building && this._building.zpsos.length;
    }

    /**
     * Create new zpso
     *
     * @param { string } name
     * @param { string } color
     * @param { string } stripes
     * @param { object } stripes
     * @returns {cn_zpso}
     */
    create(name, color, stripes, specific_params = {}) {
        this.push_transaction('Création d\'une zpso');
        this.push_item_set(this._scene.building, 'zpsos');
        const result = this._scene.building.add_zpso(name, color, stripes, specific_params['control_result'] || '', specific_params['decision_criteria'] || '');
        return result
    }

    /**
     * Update name, color or stripes values
     *
     * @param {cn_zpso} zpso
     * @param {cn_zpso} new_values
     */
    modify(zpso, new_values) {
        super.modify(zpso, new_values);
        zpso.control_result = new_values.control_result;
        zpso.decision_criteria = new_values.decision_criteria;
    }

    /**
     * Delete list of zpsos
     *
     * @param {cn_zpso[]} zpsos
     */
    delete(zpsos) {
        this.push_transaction('Suppression de zpso');
        this.push_item_set(this._scene.building, 'zpsos');
        zpsos.forEach(zpso => {
            if (this.current_layer && zpso.ID === this.current_layer.ID) {
                this.unset_current_layer();
            }
            const index = this._scene.building.zpsos.findIndex(z => z.ID === zpso.ID);
            if (index >= 0) {
                this._scene.building.zpsos.splice(index, 1);
            }
        });
    }

    _search_element_on_other_support(element, position, tolerance) {
        [
            this._scene.find_object_instance,
            this._scene.find_beam,
            this._scene.find_opening,
            this._scene.find_column,
            this._scene.find_stairs,
            this._scene.find_pipe,
        ].forEach(searchFunction => {
            if (!element) {
                const el = searchFunction.apply(this._scene, [position, tolerance]);
                if (el) {
                    element = { el: el, support: SUPPORT_OTHER };
                }
            }
        });
        return element;
    }

    _search_element_on_wall_support(element, position, tolerance) {
        const el = this._scene.find_wall(position, tolerance);
        if (el) {
            element = { el: el, support: SUPPORT_WALL };
        }
        return element;
    }

    get_layers_ref() {
        return this._building.zpsos;
    }


}

