'use strict';
// @ts-ignore
import { cn_storey, cn_svg_tool_marker_creation } from '..';
import { cn_sampling } from '../model/cn_sampling';
import { cn_svg_map } from './cn_svg_map';
import { cn_marker } from '../model/cn_marker';
import { cn_element } from '../model/cn_element';
import { cn_mouse_event } from './cn_mouse_event';
import { cn_edition_handler } from './cn_edition_handler';
import { HELPER } from '../utils/cn_wordings';

export class cn_svg_tool_sampling_creation extends cn_svg_tool_marker_creation {
    //***********************************************************************************
    /**
     * Constructor
     * @param {cn_svg_map} map
     */
    constructor(map, free_position = false) {
        super(map);
        this.support = 'wall';
        this.all_support_rendering = true;
        this.free_position = free_position;
        this.element_filter = element => {
            return element.constructor == cn_sampling;
        }
    }

    //***********************************************************************************
    /**
     * Finds another marker under the mouse.
     * TODO: derivate this in order to manage marker derivates
     * @param {cn_mouse_event} mouse_event
     */
    _find_marker(mouse_event) {
        if (this._view_overlay) {
            var elt = this._view_overlay.find_mouse_element(mouse_event);
            if (!elt) return false;
            return (elt.element instanceof cn_sampling);
        }
        if (this._map) {
            return this._map._storey.find_sampling(mouse_event.mouse_world, mouse_event.camera.snap_world_distance);
        }
        return false;
    }

    _allow_freeform_marker() {
        return false;
    }

    //***********************************************************************************
    /**
     * Builds a new marker
     * TODO: derivate this in order to manage marker derivates
     * @param {cn_storey} storey
     * @returns {cn_marker}
     */
    _build_marker(storey) {
        var marker = new cn_sampling(this._map._storey, '', this.free_position);
        marker.label = 'Nouveau prélèvement';
        marker.support = this.support;
        return marker;
    }

    /**
     * Set tool support : wall, floor or ceil
     *
     * @param {string} support
     */
    set_support(support) {
        if (this.support !== support) {
            this.support = support;
            if (!this.all_support_rendering) {
                this._scene.draw_layer_support = this.support;
            }
            this._initialize_creation();
        }
    }

    set_all_support_rendering(value) {
        this.all_support_rendering = value;
        if (!this.all_support_rendering) {
            this._scene.draw_layer_support = this.support;
        } else {
            this._scene.draw_layer_support = '';
        }
    }

    open_tool() {
        super.open_tool();
        this.push_instruction_input(HELPER.sampling.creation);
    }

    set_sampling_content(sampling) {
        if (this._edition_handler) {
            // @ts-ignore
            this._edition_handler.set_marker_content(sampling);
            if (sampling.zpso) {
                // @ts-ignore
                this._edition_handler._marker['zpso'] = sampling.zpso;
            }
            if (sampling.control_result) {
                // @ts-ignore
                this._edition_handler._marker['control_result'] = sampling.control_result;
            }
            return true;
        }
        return false;
    }

    //***********************************************************************************
    /**
     * inserts a new marker (with transaction management)
     * TODO: derivate this in order to manage marker derivates
     */
    _insert_marker(marker) {
        this.push_transaction('Création de prélèvement');
        this.push_item_set(this._map._storey, 'samplings');
        this._map._storey.samplings.push(marker);
    }

    /**
     * TODO : derivate in order to allow edition of other element in the process of creation
     * @param {cn_mouse_event} mouse_event
     * @returns {cn_element}
     */
    _find_other_element(mouse_event) {
        if (this._map)
            return this._map._storey.find_sampling(mouse_event.mouse_world, mouse_event.camera.snap_world_distance);
        return null;
    }

    //***********************************************************************************
    /**
     * Builds a new marker handler.
     * TODO: derivate this in order to manage marker derivates
     * @param {Array<cn_marker>} markers
     * @returns {cn_edition_handler}
     */
    _build_edition_handler(markers) {
        var handler = super._build_edition_handler(markers);
        handler.transaction_name_shape_change = 'Modification de la zone de prélèvement';
        handler.transaction_name_arrow_change = 'Déplacement d\'un prélèvement';
        handler.transaction_name_tail_change = 'Déplacement de l\'étiquette d\'un prélèvement';
        handler.transaction_name_label_change = 'Modification du texte d\'un prélèvement';
        return handler;
    }

    _check_new_marker_creation(new_marker) {
        return new_marker.free_position || !!new_marker.element;
    }

}
