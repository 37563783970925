<div class="container" [formGroup]="formInformationMeteo">
    <div class="item vitesse-vent" *ngIf="this.formInformationMeteo.get('vitesseVent') != undefined">
        <div class="header">
            <mat-icon svgIcon="wind" aria-hidden="false" aria-label="Wind icon"></mat-icon>
        </div>
        <div class="content">
            <app-increment-input-reactive
                class="big-increment"
                formControlName="vitesseVent"
                [incrementControl]="formInformationMeteo.get('vitesseVent')"
                [min]="0"
                [step]="1"
                [numericKeyboard]="true"
                [style]="'vertical'"
            >
            </app-increment-input-reactive>
        </div>
    </div>
    <div class="item direction-vent" *ngIf="this.formInformationMeteo.get('directionVent') != undefined">
        <div class="header">
            <mat-icon svgIcon="windsock" aria-hidden="false" aria-label="Windsock icon"></mat-icon>
        </div>
        <div class="content">
            <compass-form formControlName="directionVent"></compass-form>
        </div>
    </div>

    <!-- Température-->
    <div class="item temperature" *ngIf="this.formInformationMeteo.get('temperature') != undefined">
        <div class="header">
            <mat-icon svgIcon="thermometer" aria-hidden="false" aria-label="Thermometer icon"></mat-icon>
        </div>
        <div class="content">
            <app-increment-input-reactive
                class="big-increment"
                formControlName="temperature"
                [incrementControl]="formInformationMeteo.get('temperature')"
                [min]="0"
                [step]="1"
                [numericKeyboard]="true"
                [style]="'vertical'"
            >
            </app-increment-input-reactive>
        </div>
    </div>
    <div class="item condition-meteo">
        <div class="header">
            <mat-icon svgIcon="weather" aria-hidden="false" aria-label="weather icon"></mat-icon>
        </div>
        <div class="content">
            <div class="row">
                <div class="col">
                    <button
                        mat-icon-button
                        matPrefix
                        aria-label="soleil"
                        color="primary"
                        class="mx-2"
                        [class.selected]="formInformationMeteo.get('conditionMeteo').value == 'soleil'"
                        (click)="setConditionMeteo('soleil')"
                    >
                        <mat-icon>wb_sunny</mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        matPrefix
                        aria-label="couvert"
                        color="primary"
                        class="mx-2"
                        [class.selected]="formInformationMeteo.get('conditionMeteo').value == 'couvert'"
                        (click)="setConditionMeteo('couvert')"
                    >
                        <mat-icon>wb_cloudy</mat-icon>
                    </button>
                </div>
                <div class="col">
                    <button
                        mat-icon-button
                        matPrefix
                        aria-label="pluie"
                        color="primary"
                        class="mx-2"
                        [class.selected]="formInformationMeteo.get('conditionMeteo').value == 'pluie'"
                        (click)="setConditionMeteo('pluie')"
                    >
                        <mat-icon svgIcon="rain" aria-hidden="false" aria-label="rain icon"></mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        matPrefix
                        aria-label="neige"
                        color="primary"
                        class="mx-2"
                        [class.selected]="formInformationMeteo.get('conditionMeteo').value == 'neige'"
                        (click)="setConditionMeteo('neige')"
                    >
                        <mat-icon svgIcon="snow" aria-hidden="false" aria-label="snow icon"></mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="item hygrometrie" *ngIf="this.formInformationMeteo.get('hygrometrie') != undefined">
        <div class="header">
            <mat-icon svgIcon="humidity" aria-hidden="false" aria-label="Humidity icon"></mat-icon>
        </div>
        <div class="content">
            <app-increment-input-reactive
                class="big-increment"
                formControlName="hygrometrie"
                [incrementControl]="formInformationMeteo.get('hygrometrie')"
                [min]="0"
                [step]="1"
                [numericKeyboard]="true"
                [style]="'vertical'"
            >
            </app-increment-input-reactive>
        </div>
    </div>
    <div class="item pression-atmo" *ngIf="this.formInformationMeteo.get('pressionAtmospherique') != undefined">
        <div class="header">
            <mat-icon svgIcon="barometer" aria-hidden="false" aria-label="Barometer icon"></mat-icon>
        </div>
        <div class="content">
            <app-increment-input-reactive
                class="big-increment"
                formControlName="pressionAtmospherique"
                [incrementControl]="formInformationMeteo.get('pressionAtmospherique')"
                [min]="0"
                [step]="10"
                [numericKeyboard]="true"
                [style]="'vertical'"
            >
            </app-increment-input-reactive>
        </div>
    </div>
</div>
