import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { takeUntil } from 'rxjs/operators';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { Intervention } from 'src/app/model/intervention.model';
import { IReportComponent, ReportBlockData, InterventionReportData } from 'src/app/model/rapport.model';
import { TypePrestation } from 'src/app/model/type-prestation.model';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { DiagnosticService } from 'src/app/services/diagnostic.service';
import { EspaceData, HapReportData } from '../../../../model/hapData.model';

@Component({
    selector: 'app-perimetre-block',
    templateUrl: './perimetre-block.component.html',
    styleUrls: ['./perimetre-block.component.scss'],
})
export class PerimetreBlockComponent extends BaseComponent implements OnInit, IReportComponent {
    @Input() data: ReportBlockData;
    @Input() intervention: Intervention;
    @Input() interReportData: InterventionReportData;

    private diagnostic: Diagnostic;
    private typePrestation: TypePrestation;

    listeEspaces: EspaceData[] = [];

    constructor(private readonly diagnosticService: DiagnosticService, private readonly cnSpinnerService: CnSpinnerService) {
        super();
    }

    ngOnInit(): void {
        this.cnSpinnerService
            .withSpinner(this.diagnosticService.getCurrentDiagnostic())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((diag) => {
                this.diagnostic = diag;
                this.typePrestation = this.diagnostic.typePrestation;
                if (this.interReportData) {
                    const diagnosticData = this.interReportData.diagnostics.find(
                        (dia) => dia.typePrestation === this.typePrestation
                    ) as HapReportData;
                    this.listeEspaces = diagnosticData.listeEspaces;
                }
            });
    }
}
