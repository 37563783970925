import { Pipe } from '@angular/core';
import { Estimation } from '../model/estimation.model';
import { Zone } from '../model/zone.model';

@Pipe({ name: 'objectifsMesurage' })
export class ObjectifsMesuragePipe {
    constructor() {}

    transform(value: any, debounceTime?: number) {
        if (value.length == 0) return '';

        return 'Ref ' + value.map((ref) => ref.reference).join(', ');
    }
}

@Pipe({ name: 'estimationPipe' })
export class EstimationPipe {
    constructor() {}

    //
    transform(value: Estimation) {
        if (value == undefined) return '';

        let transformedValue = value.strategie.nombreBatiment + ' bâtiment(s), ';
        transformedValue += value.strategie.nombreZones + ' zone(s), ';
        transformedValue += value.strategie.nombreVentilateurs + ' ventilateur(s),';
        transformedValue += value.strategie.nombreMeta + ' META(s),';
        transformedValue += value.strategie.nombreMetop + ' METOP(s)';

        return transformedValue;
    }
}

@Pipe({ name: 'highlightSearchPipe' })
export class HighlightSearchPipe {
    constructor() {}

    transform(value: string, searchString: string = '') {
        if (value == undefined) return '';

        const reg = new RegExp('(' + searchString.trim() + ')', 'ig');

        //@ts-ignore
        const matches = value.matchAll(reg);
        if (!matches) {
            return '';
        }

        //@ts-ignore
        let transformedValue = value.replaceAll(reg, (a) => {
            return `<b>${a}</b>`;
        });

        return `<span>${transformedValue}</span>`;
    }
}

@Pipe({ name: 'interventionPipe' })
export class InterventionPipe {
    constructor() {}

    //
    transform(value: Estimation) {
        if (value == undefined) return '';

        let transformedValue = value.premiereIntervention.nombreBatiment + ' bâtiment(s), ';
        transformedValue += value.premiereIntervention.nombreMeta + ' META(s),';
        transformedValue += value.premiereIntervention.nombreMetop + ' METOP(s)';

        return transformedValue;
    }
}

@Pipe({ name: 'competencesPipe' })
export class CompetencesPipe {
    constructor() {}

    //
    transform(value: Estimation) {
        if (value == undefined) return '';
        let transformedValue = '';
        value.listeCompetences.forEach((element) => {
            transformedValue += element + ', ';
        });

        return transformedValue;
    }
}

@Pipe({ name: 'mpcaMateriauxPipe' })
export class MpcaMateriauxPipe {
    constructor() {}

    //
    transform(value: string, field: number) {
        if (value == undefined) return '';

        let splitValue = value.split(' - ');

        //Si l'utilisateur n'a pas encore saisi de valeur dans la liste
        if (splitValue.length != 3) {
            //Dans le champ de texte qui n'est pas désactivé on renvoi la valeur qui est en train d'être saisie
            if (field == 2) {
                return value;
            }

            //Rien n'est renvoyé dans les autres champs
            //Permet d'attendre qu'une valeur ait été sélectionné avant de valoriser les champs auto
            return '';
        }

        return splitValue[field];
    }
}
@Pipe({ name: 'unCamelCasePipe' })
export class UnCamelCasePipe {
    constructor() {}

    //
    transform(value: string) {
        if (value == undefined) return '';

        return (
            value
                // insert a space before all caps
                .replace(/([A-Z])/g, ' $1')
                // uppercase the first character
                .replace(/^./, function (str) {
                    return str.toUpperCase();
                })
        );
    }
}
@Pipe({ name: 'zoneFullnamePipe' })
export class ZoneFullnamePipe {
    constructor() {}

    transform(zone: Zone) {
        if (!zone) return '';

        return zone.nom != '' ? `${zone.identifiant} : ${zone.nom}` : `${zone.identifiant}`;
    }
}
