import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LibFormContainerModule } from '../form-container/form-container.module';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PasswordResetInitComponent } from './init/password-reset-init.component';
import { PasswordResetFinishComponent } from './finish/password-reset-finish.component';
import { InscriptionFinishComponent } from './inscription-finish/inscription-finish.component';

@NgModule({
    imports: [CommonModule, FormsModule, LibFormContainerModule, MatButtonModule, MatFormFieldModule, MatInputModule],
    declarations: [PasswordResetInitComponent, PasswordResetFinishComponent, InscriptionFinishComponent],
})
export class LibPasswordResetModule {}
