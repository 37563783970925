<form [formGroup]="loginForm" (ngSubmit)="login()">
    <mat-form-field class="w-100" hideRequiredMarker>
        <mat-label>E-mail</mat-label>
        <input matInput type="text" required formControlName="email" data-cy="email-input" />
    </mat-form-field>

    <mat-form-field class="w-100" hideRequiredMarker>
        <mat-label>Mot de passe</mat-label>
        <input matInput type="password" required formControlName="password" data-cy="password-input" />
    </mat-form-field>

    <div class="text-center mt-3">
        <button
            mat-raised-button
            color="accent"
            type="submit"
            href="/touchid"
            [disabled]="!loginForm.valid"
            data-cy="submit-button"
        >
            Se connecter
        </button>
    </div>
    <div class="text-center mt-3">
        <button mat-button [routerLink]="'/password-reset/init'">Mot de passe oublié ?</button>
    </div>
</form>
