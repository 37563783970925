import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { TypeBien } from '../model/type-bien.model';
import { RaisonAnnulation } from '../model/raison-annulation';
import { HttpBackend, HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Aide } from '../model/aide.model';
import { TypeElementAControler, TypeElementAControlerAdmin, TypeVolume, TypeVolumeAdmin } from '../model/type-element-a-controler.model';
import { ReferencePrestation } from '../model/reference-prestation.model';
import { TypeDocument } from '../model/type-document.model';
import { MongoUtils } from 'src/app/commons-lib';
import { FileData, OfflineStorageService, ResourceWrapper } from '../shared/offline/offline-storage.service';
import { STORAGE_KEY_PICTO_PRESTATIONS } from '../shared/constants/indexeddb.constants';
import { Intervention } from '../model/intervention.model';
import { combineLatestOrEmpty } from '../utils/rxjs.utils';
import { extractPage, PageRequest, toHttpParams } from '../shared/paging/page';
import { TypeOuvrage } from '../model/type-ouvrage.model';
import { TypeMateriau } from '../model/type-materiau.model';
import { CategorieDocument } from '../model/categorie-document.model';
import { CategorieEquipement } from '../model/categorie-equipement.model';
import { Equipement } from '../model/equipement.model';
import { ParametersGroup } from '@acenv/commons-lib';
import { CategorieOuvrage, CategorieOuvrageMapping } from '../model/categorie-ouvrage.model';

export class PictoImageFileData implements FileData {
    fileId: string;
    fileContent?: string;
    fileName?: string;
    refPrestationId: string;
}

/**
 * Service pour les données de référence.
 *
 * 2 niveaux de cache :
 * - Cache RxJS, à durée courte : renvoie directement la valeur cachée via un shareReplay, évite d'appeler l'API trop souvent
 * - Cache service worker, à durée longue : lorsque l'API est appelée, permet de renvoyer une valeur en mode hors-ligne
 *
 * NB : les données renvoyées depuis le cache RxJS n'ont pas besoin d'être clonées, car elles sont en lecture seule
 */
@Injectable({
    providedIn: 'root',
})
export class ReferenceApiService {
    public resourceUrl = `${environment.apiUrl}/reference`;

    private http: HttpClient;

    // Ressource pour le wrapper HL
    private resource: ResourceWrapper<PictoImageFileData, string> = this.offlineStorage.wrapFileResource<PictoImageFileData>({
        cacheName: STORAGE_KEY_PICTO_PRESTATIONS,
        idField: 'fileId',
        resourceUrl: this.resourceUrl,
        paramsForPull: null,
        urlByTemplate: (data: PictoImageFileData) => `${this.resourceUrl}/${data.refPrestationId}/picto`,
    });

    constructor(private httpBackend: HttpBackend, private offlineStorage: OfflineStorageService) {
        this.http = new HttpClient(httpBackend);
    }

    buildTypesBien$(): Observable<TypeBien[]> {
        return this.http.get<TypeBien[]>(`${this.resourceUrl}/types-bien`).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    buildTypesOuvrage$(): Observable<TypeOuvrage[]> {
        return this.http.get<TypeOuvrage[]>(`${this.resourceUrl}/types-ouvrage`).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    buildTypesMateriau$(): Observable<TypeMateriau[]> {
        return this.http.get<TypeMateriau[]>(`${this.resourceUrl}/types-materiau`).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    buildRaisonsAnnulation$(): Observable<RaisonAnnulation[]> {
        return this.http.get<RaisonAnnulation[]>(`${this.resourceUrl}/raisons-annulation`).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    buildTypesEquipementsAControler$(): Observable<TypeElementAControler[]> {
        return this.http
            .get<TypeElementAControler[]>(`${this.resourceUrl}/types-elements-a-controler`)
            .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    buildParametersGroup$(): Observable<ParametersGroup[]> {
        return this.findAllParametersGroup().pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    buildCategoriesEquipements$(): Observable<CategorieEquipement[]> {
        return this.findAllCategorieEquipement().pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    buildEquipements$(): Observable<Equipement[]> {
        return this.findAllEquipement().pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    buildTypesVolumes$(): Observable<TypeVolume[]> {
        return this.http.get<TypeVolume[]>(`${this.resourceUrl}/types-volumes`).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    buildAides$(): Observable<Aide[]> {
        return this.http.get<Aide[]>(`${this.resourceUrl}/aides`).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    buildReferencePrestation$() {
        return this.http.get<ReferencePrestation[]>(`${this.resourceUrl}/reference-prestations`).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    buildCategorieOuvrage$(): Observable<CategorieOuvrage[]> {
        return this.http.get<CategorieOuvrage[]>(`${this.resourceUrl}/categories-ouvrages`).pipe(shareReplay(1));
    }

    /**
     * Met à jour une référence prestation avec un fichier de règles
     */
    uploadRuleFile(idReferencePrestation: string, selectedTypeRegle: string, formData: FormData): Observable<HttpEvent<any>> {
        return this.http.post<any>(`${this.resourceUrl}/${idReferencePrestation}/rule/${selectedTypeRegle}`, formData, {
            reportProgress: true,
            observe: 'events',
        });
    }

    /** --------------------------  Catégorie de document ------------------------- */
    buildCategorieDocuments$() {
        return this.http.get<CategorieDocument[]>(`${this.resourceUrl}/categorie-documents`).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    upsertCategorieDocument(categorieDocument: CategorieDocument): Observable<CategorieDocument> {
        return this.http.put<CategorieDocument>(`${this.resourceUrl}/categorie-documents`, categorieDocument);
    }

    /** -------------------------- Type de document ------------------------- */
    buildTypesDocument$() {
        return this.http.get<TypeDocument[]>(`${this.resourceUrl}/type-documents`).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    upsertTypeDocument(typeDocument: TypeDocument): Observable<TypeDocument> {
        return this.http.put<TypeDocument>(`${this.resourceUrl}/type-documents`, typeDocument);
    }

    /** --------------------------  Type d'ouvrage ------------------------- */
    findOneTypeOuvrage(idTypeOuvrage: string): Observable<TypeOuvrage> {
        return this.http.get<TypeOuvrage>(`${this.resourceUrl}/find-one-type-ouvrage/${idTypeOuvrage}`);
    }

    createTypeOuvrage(typeOuvrage: TypeOuvrage): Observable<TypeOuvrage> {
        return this.http.post<TypeOuvrage>(`${this.resourceUrl}/type-ouvrage`, typeOuvrage);
    }

    updateTypeOuvrage(typeOuvrage: TypeOuvrage): Observable<TypeOuvrage> {
        return this.http.put<TypeOuvrage>(`${this.resourceUrl}/type-ouvrage`, typeOuvrage);
    }

    deleteTypeOuvrage(typeOuvrageId: string): Observable<TypeOuvrage[]> {
        return this.http.delete<TypeOuvrage[]>(`${this.resourceUrl}/type-ouvrage/${typeOuvrageId}`);
    }

    isTypeOuvrageAvailable(typeOuvrageName: string, typeOuvrageParentsIds: string[] | null): Observable<string> {
        return this.http.get(`${this.resourceUrl}/type-ouvrage-available`, {
            params: {
                elementName: typeOuvrageName,
                elementParentIds: typeOuvrageParentsIds,
            },
            responseType: 'text',
        });
    }

    isTypeOuvrageAvailableParent(typeOuvrageName: string): Observable<string> {
        return this.http.get(`${this.resourceUrl}/type-ouvrage-available/parent`, {
            params: {
                elementName: typeOuvrageName,
            },
            responseType: 'text',
        });
    }

    /** --------------------------  Type élément à controler ------------------------- */

    /**
     * Renvoie tous les types éléments à controler pour la liste admin.
     */
    findAllTypesElementsAControlerPourAdmin(): Observable<TypeElementAControlerAdmin[]> {
        return this.http.get<TypeElementAControlerAdmin[]>(`${this.resourceUrl}/types-elements-a-controler-admin`);
    }

    findAllTypesElementsAControlerPourAdminPage(search: string, pageRequest: PageRequest<TypeElementAControlerAdmin>) {
        return extractPage(
            this.http.get<TypeElementAControlerAdmin[]>(`${this.resourceUrl}/types-elements-a-controler-admin_page'}`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), search },
            })
        );
    }

    findAllTypesElementsAControler(): Observable<TypeElementAControler[]> {
        return this.http.get<TypeElementAControler[]>(`${this.resourceUrl}/types-elements-a-controler/`);
    }

    findOneTypeElementAControler(idTypeElementAControler: string): Observable<TypeElementAControler> {
        return this.http.get<TypeElementAControler>(`${this.resourceUrl}/find-one-type-element-a-controler/${idTypeElementAControler}`);
    }

    createTypeElementAControler(typeElementAControler: TypeElementAControlerAdmin): Observable<TypeElementAControlerAdmin> {
        return this.http.post<TypeElementAControlerAdmin>(`${this.resourceUrl}/type-element-a-controler`, typeElementAControler);
    }

    updateTypeElementAControler(typeElementAControler: TypeElementAControlerAdmin): Observable<TypeElementAControlerAdmin> {
        return this.http.put<TypeElementAControlerAdmin>(`${this.resourceUrl}/type-element-a-controler`, typeElementAControler);
    }

    /** --------------------------  ParametersGroup ------------------------- */
    findAllParametersGroup(): Observable<ParametersGroup[]> {
        return this.http.get<ParametersGroup[]>(`${this.resourceUrl}/parameters-groups`);
    }

    /** --------------------------  CategorieEquipement ------------------------- */
    findAllCategorieEquipement(): Observable<CategorieEquipement[]> {
        return this.http.get<CategorieEquipement[]>(`${this.resourceUrl}/categories-equipements`);
    }

    findAllCategorieEquipementPage(search: string, pageRequest: PageRequest<CategorieEquipement>) {
        return extractPage(
            this.http.get<CategorieEquipement[]>(`${this.resourceUrl}/categories-equipements_page`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), search },
            })
        );
    }

    findOneCategorieEquipement(idCategorieEquipement: string): Observable<CategorieEquipement> {
        return this.http.get<CategorieEquipement>(`${this.resourceUrl}/categorie-equipement/${idCategorieEquipement}`);
    }

    findFirstCategorieEquipementByCodeBim(codeBim: string): Observable<CategorieEquipement> {
        return this.http.get<CategorieEquipement>(`${this.resourceUrl}/categorie-equipement-codebim`, {
            params: new HttpParams().set('codeBim', codeBim),
        });
    }

    createCategorieEquipement(catEquipement: CategorieEquipement): Observable<CategorieEquipement> {
        return this.http.post<CategorieEquipement>(`${this.resourceUrl}/categorie-equipement`, catEquipement);
    }

    updateCategorieEquipement(catEquipement: CategorieEquipement): Observable<CategorieEquipement> {
        return this.http.put<CategorieEquipement>(`${this.resourceUrl}/categorie-equipement`, catEquipement);
    }

    deleteCategorieEquipement(categorieId: string): Observable<CategorieEquipement> {
        return this.http.delete<CategorieEquipement>(`${this.resourceUrl}/categorie-equipement/${categorieId}`);
    }

    /** --------------------------  Equipement ------------------------- */
    findAllEquipement(): Observable<Equipement[]> {
        return this.http.get<any[]>(`${this.resourceUrl}/equipements`).pipe(
            map((its) => {
                const genericObjects = [];
                if (its?.length) {
                    for (const it of its) {
                        const result = new Equipement();
                        result.id = it.id;
                        result.name = it.name;
                        result.productTypeCodeBim = it.productTypeCodeBim;
                        result.width = it.width;
                        result.depth = it.depth;
                        result.height = it.height;
                        result.iconImageData = it.iconImageData;
                        result.contact = it.contact;
                        result.defaultHeight = it.defaultHeight;
                        result.parameters = it.parameters;
                        result.color = it.color;
                        result.sourceType = it.sourceType;
                        result.roofParallel = it.roofParallel;
                        if (it.geometricData) {
                            //console.log('string: ' + it.geometricData.geometries);
                            //console.log('object: ');
                            //console.log(JSON.parse(it.geometricData.geometries));
                            result.geometricData = {
                                topViewImageData: it.geometricData.topViewImageData,
                                geometries: JSON.parse(it.geometricData.geometries),
                                instanciations: JSON.parse(it.geometricData.instanciations),
                            };
                        }
                        genericObjects.push(result);
                    }
                }
                //console.log(genericObjects);
                return genericObjects;
            }),
            shareReplay({ bufferSize: 1, refCount: true })
        );
    }

    createEquipement(catEquipement: Equipement): Observable<Equipement> {
        return this.http.post<Equipement>(`${this.resourceUrl}/equipement`, catEquipement);
    }

    updateEquipement(catEquipement: Equipement): Observable<Equipement> {
        return this.http.put<Equipement>(`${this.resourceUrl}/equipement`, catEquipement);
    }

    findAllEquipementByWorkTypeCodeBim(codeBim: string): Observable<Equipement[]> {
        //return this.http.get<Equipement[]>(`${this.resourceUrl}/equipements-codebim/${codeBim}`);
        return this.http.get<Equipement[]>(`${this.resourceUrl}/equipements-codebim`, {
            params: new HttpParams().set('codeBim', codeBim),
        });
    }

    deleteEquipement(equipementId: string): Observable<Equipement> {
        return this.http.delete<Equipement>(`${this.resourceUrl}/equipement/${equipementId}`);
    }

    /** --------------------------  Type de materiaux ------------------------- */

    /**
     * Renvoie tous les types éléments à controler pour la liste admin.
     */
    findAllMateriaux(): Observable<TypeMateriau[]> {
        return this.http.get<TypeMateriau[]>(`${this.resourceUrl}/types-elements-a-controler-admin`);
    }

    findAllMateriauxPage(search: string, pageRequest: PageRequest<TypeMateriau>) {
        return extractPage(
            this.http.get<TypeMateriau[]>(`${this.resourceUrl}/types-materiau-page`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), search },
            })
        );
    }

    findOneMateriel(idMateriel: string): Observable<TypeMateriau> {
        return this.http.get<TypeElementAControler>(`${this.resourceUrl}/find-one-materiel/${idMateriel}`);
    }

    createMateriel(materiel: TypeMateriau): Observable<TypeMateriau> {
        return this.http.post<TypeMateriau>(`${this.resourceUrl}/materiel`, materiel);
    }

    updateMateriel(materiel: TypeMateriau): Observable<TypeMateriau> {
        return this.http.put<TypeMateriau>(`${this.resourceUrl}/materiel`, materiel);
    }

    deleteMateriel(materielID: string) {
        return this.http.delete(`${this.resourceUrl}/materiel/${materielID}`);
    }

    isMaterielAvailable(maretielName: string): Observable<string> {
        return this.http.get(`${this.resourceUrl}/materiel-available`, {
            params: {
                elementName: maretielName,
            },
            responseType: 'text',
        });
    }

    /** --------------------------  Volumes  ------------------------- */
    /**
     * Renvoie tous les types volumes pour la liste admin.
     */
    findAllTypesVolumesPourAdmin(): Observable<TypeVolumeAdmin[]> {
        return this.http.get<TypeVolumeAdmin[]>(`${this.resourceUrl}/types-volumes-admin`);
    }

    searchTypeVolumePourAdminPage(search: string, pageRequest: PageRequest<TypeVolumeAdmin>) {
        return extractPage(
            this.http.get<TypeVolumeAdmin[]>(`${this.resourceUrl}/types-volumes-admin-page`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), search },
            })
        );
    }

    findOneTypeVolume(idVolume: string): Observable<TypeVolume> {
        return this.http.get<TypeVolume>(`${this.resourceUrl}/find-one-type-volume/${idVolume}`);
    }

    upsertTypeVolume(typeVolume: TypeVolumeAdmin): Observable<TypeVolumeAdmin> {
        return this.http.post<TypeVolumeAdmin>(`${this.resourceUrl}/type-volume`, typeVolume);
    }

    /** --------------------------  Référence Prestation ------------------------- */
    findOneReferencePrestation(idPrestation: string): Observable<ReferencePrestation> {
        return this.http.get<ReferencePrestation>(`${this.resourceUrl}/find-one-reference-prestation/${idPrestation}`);
    }

    findAllReferencePrestationsPage(search: string, pageRequest: PageRequest<ReferencePrestation>) {
        return extractPage(
            this.http.get<ReferencePrestation[]>(`${this.resourceUrl}/reference-prestations-page`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), search },
            })
        );
    }

    createReferencePrestation(prestation: ReferencePrestation): Observable<ReferencePrestation> {
        return this.http.post<ReferencePrestation>(`${this.resourceUrl}/reference-prestation`, prestation);
    }

    updateReferencePrestation(prestation: ReferencePrestation): Observable<ReferencePrestation> {
        return this.http.put<ReferencePrestation>(`${this.resourceUrl}/reference-prestation`, prestation);
    }

    uploadPictoReferencePrestation(idPrestation: string, formData: FormData): Observable<HttpEvent<any>> {
        let params = new HttpParams();
        params = params.set('fileId', MongoUtils.generateObjectId());
        return this.http.post<any>(`${this.resourceUrl}/${idPrestation}/picto`, formData, {
            params,
            reportProgress: true,
            observe: 'events',
        });
    }

    /** --------------------------  Catégories Ouvrages Pièces ------------------------- */
    findAllCategoriesOuvragesPourAdminPage(search: string, pageRequest: PageRequest<CategorieOuvrage>) {
        return extractPage(
            this.http.get<CategorieOuvrage[]>(`${this.resourceUrl + '/categorie-ouvrage-admin_page'}`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), search },
            })
        );
    }

    findOneCategorieOuvrage(idCategorieOuvrage: string): Observable<CategorieOuvrage> {
        return this.http.get<CategorieOuvrage>(`${this.resourceUrl}/find-one-categorie-ouvrage/${idCategorieOuvrage}`);
    }

    createCategorieOuvrage(categorieOuvrage: CategorieOuvrage): Observable<CategorieOuvrage> {
        return this.http.post<CategorieOuvrage>(`${this.resourceUrl}/categorie-ouvrage`, categorieOuvrage);
    }

    updateCategorieOuvrage(categorieOuvrage: CategorieOuvrage): Observable<CategorieOuvrage> {
        return this.http.put<CategorieOuvrage>(`${this.resourceUrl}/categorie-ouvrage`, categorieOuvrage);
    }

    deleteCategorieOuvrage(categorieOuvrageId: string): Observable<CategorieOuvrage[]> {
        return this.http.delete<CategorieOuvrage[]>(`${this.resourceUrl}/categorie-ouvrage/${categorieOuvrageId}`);
    }

    /**
     * Télécharge l'image picto associée à la prestation
     * @param refPrestationId id de la reference
     * @param pictoId id du picto
     */
    downloadImage(refPrestationId: string, pictoId: string): Observable<PictoImageFileData> {
        if (pictoId && refPrestationId) {
            return this.resource.getOneByTemplate({
                fileId: pictoId,
                refPrestationId: refPrestationId,
            });
        } else {
            return of(null);
        }
    }

    /**
     * Pull l'ensenble des pictos associés au references contenu dans les interventions.
     * @param interventions
     */
    pullPictosReference(interventions: Intervention[]): Observable<Intervention[]> {
        return combineLatestOrEmpty(
            interventions
                .flatMap((intervention) => {
                    return intervention.prestationsDiagnostics;
                })
                .filter(
                    (prestationDiagnostics) =>
                        prestationDiagnostics &&
                        prestationDiagnostics.prestation &&
                        prestationDiagnostics.prestation.referencePrestation &&
                        prestationDiagnostics.prestation.referencePrestation.id &&
                        prestationDiagnostics.prestation.referencePrestation.idFichierPicto
                )
                .flatMap((prestationDiagnostics) => {
                    return prestationDiagnostics.prestation.referencePrestation;
                })
                .filter(
                    // Suppression des doublons
                    (referencePrestation, index, self) => {
                        return (
                            index ===
                            self.findIndex((t) => t.id === referencePrestation.id && t.idFichierPicto === referencePrestation.idFichierPicto)
                        );
                    }
                )
                .map((referencePrestation) => {
                    return this.downloadImage(referencePrestation.id, referencePrestation.idFichierPicto);
                })
        );
    }

    getWallTypes(): Observable<any> {
        return this.http.get(`${this.resourceUrl}/walls`).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    getOpeningTypes(): Observable<any> {
        return this.http.get(`${this.resourceUrl}/openings`).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    getBalconyTypes(): Observable<any> {
        return this.http.get(`${this.resourceUrl}/balconies`).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    getBeamTypes(): Observable<any> {
        return this.http.get(`${this.resourceUrl}/beams`).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    getCommentStyles(): Observable<any> {
        return this.http.get(`${this.resourceUrl}/comment-styles`).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    getSpaceUsages(): Observable<any> {
        return this.http.get(`${this.resourceUrl}/space-usages`).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    getSpaceUsagesEquipments(): Observable<any> {
        return this.http.get(`${this.resourceUrl}/space-equipments`).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    getObjects(): Observable<any> {
        return this.http.get(`${this.resourceUrl}/objects`).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    getFenceTypes(): Observable<any> {
        return this.http.get(`${this.resourceUrl}/fences`).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    getFenceOpeningTypes(): Observable<any> {
        return this.http.get(`${this.resourceUrl}/fence-openings`).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    getCategoriesOuvragesMappings(): Observable<CategorieOuvrageMapping[]> {
        return this.http
            .get<CategorieOuvrageMapping[]>(`${this.resourceUrl}/categories-ouvrages-mappings`)
            .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }
}
