<div class="h-100">
    <div class="toolbar">
        <mat-toolbar class="border-bottom-1 primary">
            <button mat-button class="px-0" (click)="back()">
                <lib-icon>arrow_back</lib-icon>
            </button>
            <div>
                <lib-icon [class]="'icon-before-text'">settings</lib-icon>
                <span>Aide à la création de template rapport</span>
            </div>
        </mat-toolbar>
    </div>

    <div class="col-6 d-flex align-items-center">
        <div class="input-with-prefix mr-2">
            <mat-form-field appearance="fill" class="w-100">
                <input
                    matInput
                    placeholder="Rechercher"
                    [(ngModel)]="searchValue"
                    (keyup)="filterChanged($event.target.value)"
                />
                <mat-icon matPrefix>search</mat-icon>
            </mat-form-field>
        </div>
        <div class="d-flex flex-row align-items-center w-100">
            <div class="mat-subheading-2 m-0 primary pl-4 pr-2">Types prestations</div>
            <mat-form-field appearance="standard" class="flex-grow-1">
                <mat-select (selectionChange)="onChangeFilterTypePrestation($event)">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let type of listTypePrestation" [value]="type">
                        {{ type | typePrestation }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="d-flex flex-row w-100 main-container">
        <!--    colonne de gauche avec arbre-->
        <div class="d-flex bold flex-column w-50 pr-2 h-100 scrollable-content white-background">
            <mat-tree class="p-2" [dataSource]="dataSource" [treeControl]="treeControl">
                <!-- Children -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [hidden]="!node.visible">
                    <span class="c-pointer" (click)="onNodeClicked($event, node)"> {{ node.name }}</span>
                </mat-tree-node>
                <!-- Bouton avec chevron / parent -->
                <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding [hidden]="!node.visible">
                    <div
                        class="d-flex bold c-pointer"
                        matTreeNodeToggle
                        [attr.aria-label]="'Toggle ' + node.name"
                        (click)="onNodeClicked($event, node)"
                    >
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                        </mat-icon>

                        {{ node.name }}
                    </div>
                </mat-tree-node>
            </mat-tree>
        </div>

        <!--    colonne de droite avec détails-->
        <div class="w-50 pl-2">
            <mat-card *ngIf="selectedNode" class="p-2 mb-2 d-flex flex-column justify-content-between">
                <mat-card-content class="d-flex flex-column">
                    <div class="mb-2 pb-2 border-bottom">
                        <h2>{{ selectedNode?.title ? selectedNode?.title : selectedNode?.name }}</h2>
                        <div class="d-flex p-2 align-items-center">
                            <b>Nom de la propriété :&nbsp; </b> {{ selectedNode?.name }}
                        </div>
                        <div *ngIf="selectedNode?.description" class="d-flex p-2 align-items-center">
                            <b>Description :&nbsp; </b> {{ selectedNode?.description }}
                        </div>
                        <div *ngIf="selectedNode?.type" class="d-flex p-2 align-items-center">
                            <b>Type :&nbsp; </b> {{ selectedNode?.type }}
                        </div>
                    </div>
                    <div class="d-flex flex-column" *ngIf="!listReportDataClass.includes(selectedNode?.name)">
                        <div class="d-flex flex-column justify-content-between p-2">
                            <div>
                                <div class="block-label">Champ simple</div>
                                <div>
                                    <button
                                        mat-button
                                        mat-raised-button
                                        mat-icon-button
                                        color="accent"
                                        class="mr-2"
                                        [cdkCopyToClipboard]="copyChampSimple"
                                        (click)="copyField()"
                                    >
                                        <mat-icon>content_copy</mat-icon>
                                    </button>
                                    <i style="font-size: small">{{ copyChampSimple }}</i>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex flex-column justify-content-between p-2">
                            <div>
                                <div class="block-label">Champ depuis la racine</div>
                                <div>
                                    <button
                                        mat-button
                                        mat-raised-button
                                        mat-icon-button
                                        color="accent"
                                        class="mr-2"
                                        [cdkCopyToClipboard]="copyChampDepuisRacine"
                                        (click)="copyField()"
                                    >
                                        <mat-icon>content_copy</mat-icon>
                                    </button>
                                    <i style="font-size: small">{{ copyChampDepuisRacine }}</i>
                                </div>
                            </div>
                        </div>

                        <div
                            class="d-flex flex-column justify-content-between p-2"
                            *ngIf="copyChampDepuisDernierTableau"
                        >
                            <div>
                                <div class="block-label">Champ depuis le dernier tableau</div>
                                <div>
                                    <button
                                        mat-button
                                        mat-raised-button
                                        mat-icon-button
                                        color="accent"
                                        class="mr-2"
                                        [cdkCopyToClipboard]="copyChampDepuisDernierTableau"
                                        (click)="copyField()"
                                    >
                                        <mat-icon>content_copy</mat-icon>
                                    </button>
                                    <i style="font-size: small">{{ copyChampDepuisDernierTableau }}</i>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex flex-column justify-content-between p-2" *ngIf="copyBoucle">
                            <div>
                                <div class="block-label">Copier la boucle</div>
                                <div class="d-flex align-items-center">
                                    <div>
                                        <button
                                            mat-button
                                            mat-raised-button
                                            mat-icon-button
                                            color="accent"
                                            class="mr-2"
                                            [cdkCopyToClipboard]="copyBoucle"
                                            (click)="copyField()"
                                        >
                                            <mat-icon>content_copy</mat-icon>
                                        </button>
                                    </div>
                                    <div>
                                        <i style="font-size: small" [innerHTML]="copyBoucleHtml | safeHtml"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
