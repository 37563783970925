import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Injector, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { combineLatest } from 'rxjs';
import { InterventionService } from 'src/app/services/intervention.service';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { takeUntil } from 'rxjs/operators';
import { RelationInterventionBien } from 'src/app/model/intervention.model';
import { Bien, Niveau, Volume } from 'src/app/model/bien.model';
import { Polluant } from '../../../model/polluant.model';
import { Besoin } from '../../../model/besoin.model';
import { Zone } from '../../../model/zone.model';
import { PolluantComponent } from '../../../utils/polluant-component';
import { IValidatableComponent } from '../../../services/interfaces/validatable-component.interface';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EtatValidation } from 'src/app/model/etat-progression.model';
import { WidgetSelectModeEnum } from 'src/app/modules/shared/widget-select/widget-select.component';
import { getRefInfo } from '../initialisation/objectifs/objectifs.component';
import { Surface } from '../../../model/surface.model';
import { PrevisiteComponent } from '../estimation/previsite/previsite.component';
import { cn_building, cn_marker, cn_storey, zone_colors } from '@acenv/cnmap-editor';
import { PerimetreInvestigation } from 'src/app/model/perimetre-investigation.model';
import { ConditionsPrelevement } from '../../../model/polluant-config.model';
import { FormContext } from 'src/app/model/rule/form-context.model';
import { OrientationDialogComponent } from './orientation-dialog/orientation-dialog.component';
import { CndiagMarker } from 'src/app/modules/shared/map/model/cndiag-marker.model';
import { ViewerMapPolluantComponent } from 'src/app/modules/shared/map/viewer-map-polluant/viewer-map-polluant.component';
import { TypePrestationEnum } from 'src/app/model/type-prestation.model';
import { FrequenceService } from '../../../services/frequence.service';
import { NotificationService } from '../../../../../../lib/notification/notification.service';
import { CndiagSampleMarker } from '../../../../../shared/map/tools/cndiag-tool_sampling_creation';
import { TypePrelevementAmiante } from 'src/app/model/prelevement-generique.model';
import { CheckpointHelpModalComponent } from '../../../../../shared/checkpoint-help-modal/checkpoint-help-modal.component';
import { enumTypesCommentaire } from 'src/app/model/type-commentaire.model';
import { ConfirmationService } from '@acenv/commons-lib';

@Component({
    selector: 'app-besoins-prelevements',
    templateUrl: './besoins-prelevements.component.html',
    styleUrls: ['./besoins-prelevements.component.scss'],
})
export class BesoinsPrelevementsComponent extends PolluantComponent implements OnInit, IValidatableComponent, OnDestroy, AfterViewChecked {
    TypePrestationEnum = TypePrestationEnum;

    ngAfterViewChecked(): void {
        try {
            // Les volumes de la zone selectionnée :
            let lstVolumesIds = [];
            this.currentZone.listeSurfaces.forEach((surface) => {
                lstVolumesIds = lstVolumesIds.concat(surface.listeIdVolume);
            });
            this.externalSelectedVolumes = this.currentNiveau.volumes.filter((v) => lstVolumesIds.includes(v.id));
            for (const element of this.el.nativeElement.querySelectorAll('path.space.selected')) {
                const el = document.querySelectorAll('path[d^="' + element.getAttribute('d') + '"]')[1];

                this.renderer.setStyle(el, 'fill', zone_colors[this.indexZoneSelected % zone_colors.length]);
                this.renderer.setStyle(el, 'opacity', 0.6);
            }
        } catch (e) {}
    }

    enumTypesCommentaire = enumTypesCommentaire;
    TypePrelevementAmiante = TypePrelevementAmiante;

    diagnostic: Diagnostic;

    listeBiens: RelationInterventionBien[] = [];
    besoins: Besoin[] = [];
    volumes: Volume[] = [];
    zones: Array<Zone> = [];

    widgetSelectModeEnum: typeof WidgetSelectModeEnum = WidgetSelectModeEnum;

    idBesoinSelected: string;
    indexZoneSelected: number;
    indexNiveauSelected: number = 0;
    showError: boolean = false;
    form: FormArray;

    polluantConfig: any;

    error: {
        message: string;
        buttonLabel?: string;
        redirect?: string;
    };

    selectedPerimetreInvestigation: PerimetreInvestigation;

    // NEW
    @ViewChild(ViewerMapPolluantComponent) viewerMap: ViewerMapPolluantComponent;

    noDescription = false;
    viewMode: 'list' | 'plan' = 'list';
    building: cn_building;
    bien: Bien;
    currentMapTool = null;
    elementToLocate: Besoin;
    objectifsMesurage: ConditionsPrelevement[]; //Liste des objectifs mesurage associées à la zone sélectionnée
    private _currentBien: RelationInterventionBien;
    set currentBien(currentBien: RelationInterventionBien) {
        this._currentBien = currentBien;
        this.loadBuilding();
    }
    get currentBien() {
        return this._currentBien;
    }
    externalSelectedVolumes?: Volume[] = [];

    static ecransDependants = [
        {
            composant: PrevisiteComponent,
            nom: 'estimations',
        },
    ];

    constructor(
        public interventionService: InterventionService,
        public dialog: MatDialog,
        private cnSpinnerService: CnSpinnerService,
        private route: ActivatedRoute,
        private router: Router,
        public injector: Injector,
        private el: ElementRef,
        private renderer: Renderer2,
        private changeDetectorRef: ChangeDetectorRef,
        private frequenceService: FrequenceService,
        private readonly notificationService: NotificationService,
        private readonly confirmationService: ConfirmationService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.cnSpinnerService.show();

        combineLatest([this.interventionService.getCurrentIntervention(), this.diagnosticService.getCurrentDiagnostic(), this.route.data])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                ([intervention, diagnostic, routerData]) => {
                    this.intervention = intervention;

                    this.polluantService
                        .findOnePolluantConfigIndexedDB(diagnostic.idConfig)
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe((polluantConfig) => {
                            this.polluantConfig = polluantConfig;
                            this.diagnostic = diagnostic;
                            this.contenuDiagnostic = diagnostic.contenuDiagnostic as Polluant;

                            const errorInit = this.initPerimetre();
                            if (errorInit) {
                                this.error = {
                                    message: "Les données saisies dans l'écran Zone sont incorrectes ou incomplètes",
                                    buttonLabel: "Retourner à l'écran Zone",
                                    redirect: errorInit,
                                };
                                this.isLoaded = true;
                                this.cnSpinnerService.hide();
                                return;
                            }

                            this.createForm();
                            this.initMarkers();
                            this.calcNumBesoin();

                            this.isInEditMode =
                                !(!!routerData.readonly || this.diagnosticService.isReadOnlyMode(intervention, this.diagnostic)) && this.isInEditMode;
                            if (!this.isInEditMode) {
                                // this.form.disable();
                            }
                            this.cnSpinnerService.hide();

                            //Creation du listener pour détecter le changement d'onglet
                            this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((val) => {
                                if (val instanceof NavigationEnd) {
                                    this.cnSpinnerService.show();

                                    this.isLoaded = false;

                                    this.initPerimetre();

                                    //Recréation du formulaire
                                    //this.restoreBesoinsData();
                                    this.changeDetectorRef.detectChanges();
                                    this.isLoaded = true;
                                    this.cnSpinnerService.hide();
                                }
                            });
                        });
                },
                () => this.cnSpinnerService.hide()
            );
    }

    public initPerimetre() {
        const routeIdPerimetre = this.route.snapshot.params.idPerimetre;

        this.idBesoinSelected = null;
        this.indexZoneSelected = null;

        // Récupération du périmètre d'investigation avec le paramètre de la route
        this.selectedPerimetreInvestigation = this.contenuDiagnostic.perimetresInvestigation.data.listePerimetreInvestigation.find(
            (p) => p.id == routeIdPerimetre
        );

        // Récupération du bien associé au périmètre d'investigation
        this.bien = this.intervention.relationInterventionBiens.find((value) => {
            return value.bien.id == this.selectedPerimetreInvestigation.idBien;
        }).bien;

        this.indexNiveauSelected = 0;

        //Récupération des volumes de ce bien
        this.volumes = this.bien.description.reduce((acc: any, niveau: Niveau) => {
            return acc.concat(niveau.volumes);
        }, []);

        this.isValidated = this.contenuDiagnostic.besoins.validated;

        // Récupération des zones du périmètre
        this.zones = this.contenuDiagnostic.zones.data.zonesList;
        if (!this.zones) {
            return 'zone';
        }

        if (this.contenuDiagnostic.zones.errors && this.contenuDiagnostic.zones.errors?.length > 0) {
            return 'zone';
        }

        if (this.zones.length > 0) {
            this.indexZoneSelected = 0;
        }

        // Récupération des besoins
        this.besoins = this.contenuDiagnostic.besoins.data.besoinsList || [];

        this.objectifsMesurage = this.currentZone.objectifMesurage;
        // this.formatterObjectifsMesurage();

        // Liste des biens
        this.listeBiens = this.intervention.relationInterventionBiens;
        this.currentBien = this.listeBiens[0] ?? null;

        this.isLoaded = true;
    }

    public createForm() {
        const formArray = this.formBuilder.array([]);

        // Récupération du nombre de PU de la zone sélectionnée
        const nbPu: number = this.puTotal(this.currentZone);
        this.currentZone.objectifMesurage.forEach((obj) => {
            // Calcul du nombre de besoins attendu pour chaque objectif de mesurage
            const ref = getRefInfo(this.polluantConfig.objectifsMesurage, obj.reference);
            const nbBesoins = this.getNbBesoins(nbPu, ref.methodeCalculQuantitePompe, this.currentZone);

            let f = this.besoins.filter((b) => {
                return b.zoneId == this.currentZone.id && b.objectifMesurage.id == ref.id;
            });

            // Si le nombre de besoin actuel est différent du nombre de besoin attendu
            if (f.length < nbBesoins) {
                const nbBesoinsACreer = nbBesoins - f.length;

                for (let i = 0; i < nbBesoinsACreer; i++) {
                    const _nouveauBesoin = new Besoin();
                    _nouveauBesoin.objectifMesurage = obj;
                    _nouveauBesoin.zoneId = this.currentZone.id;
                    _nouveauBesoin.numero = (this.besoins.length + 1).toString();
                    this.besoins.push(_nouveauBesoin);
                }
            }

            // Filtre les besoins sur la zone actuelle
            f = this.besoins.filter((b) => {
                return b.zoneId == this.currentZone.id && b.objectifMesurage.id == ref.id;
            });

            // détermine si l'on doit calculer la fréquence ou prendre celle de la config
            // En fonction de la condition "ref"
            const frequence = this.frequenceService.getFrequenceObj(ref, this.currentZone);
            obj.frequence = frequence ? frequence : obj.frequence;

            const formObjectif = this.formBuilder.group({
                label: this.formService.createFormControl('label', new FormContext('label', obj, Validators.required, this.ngUnsubscribe)),
                objectifMesurage: new FormControl(obj),
                nbBesoinMin: new FormControl(nbBesoins),
                nbBesoinSupp: new FormControl(f.length > nbBesoins ? f.length - nbBesoins : 0),
                besoins: new FormArray(
                    f.map((besoin) => {
                        return this.createFormBesoin(besoin);
                    })
                ),
            });

            formArray.push(formObjectif);
        });

        formArray.valueChanges.subscribe((v) => {
            this.checkValidity();
        });
        this.form = formArray;
    }

    calcNumBesoin() {}

    ouvrirDialogOrientation(event: any, formBesoin: FormGroup) {
        event.preventDefault();
        event.stopPropagation();
        this.idBesoinSelected = formBesoin.get('id').value;
        const besoin = this.selectedBesoin;

        this.dialog
            .open(OrientationDialogComponent, {
                width: '300px',
                data: {
                    orientation: this.selectedBesoin.orientation,
                    precisionOrientation: this.selectedBesoin.precisionOrientation,
                },
            })
            .afterClosed()
            .subscribe(({ orientation, precisionOrientation }) => {
                this.selectedBesoin.orientation = orientation;
                // formBesoin.get('orientation').setValue(orientation);
                formBesoin.patchValue({
                    orientation,
                    precisionOrientation,
                });
            });
    }

    /**
     * @description Transforme un besoin en FormGroup
     * @param besoinData Données du besoin à transformer en FormGroup
     * @returns Le FormGroup du besoin
     */
    createFormBesoin(besoinData: Besoin): FormGroup {
        return this.formBuilder.group(
            {
                id: [besoinData.id],
                numero: [besoinData.numero],
                objectifMesurage: [besoinData.objectifMesurage, Validators.required],
                piecesId: this.formService.createFormControl(
                    'piecesId',
                    new FormContext('piecesId', besoinData, Validators.required, this.ngUnsubscribe)
                ),
                markerId: this.formService.createFormControl(
                    'markerId',
                    new FormContext(
                        'markerId',
                        besoinData,
                        ((): ValidatorFn => {
                            return (control: FormGroup): ValidationErrors | null => {
                                const value = control.value;

                                //@ts-ignore
                                if (control._parent == null) {
                                    return null;
                                }
                                //@ts-ignore
                                const parent = control.parent.getRawValue();

                                // Si META, le champ est obligatoire
                                if (parent.objectifMesurage.norme.indexOf('43-050') >= 0) {
                                    return value == '' ? { mandatory: true } : null;
                                }

                                return null;
                            };
                        })(),
                        this.ngUnsubscribe
                    )
                ),
                zoneId: [besoinData.zoneId],
                orientation: this.formService.createFormControl(
                    'orientation',
                    new FormContext(
                        'orientation',
                        besoinData,
                        ((): ValidatorFn => {
                            return (control: FormGroup): ValidationErrors | null => {
                                const value = control.value;

                                //@ts-ignore
                                if (control._parent == null) {
                                    return null;
                                }
                                //@ts-ignore
                                const parent = control.parent.getRawValue();

                                // Si META, le champ est obligatoire
                                if (parent.objectifMesurage.norme.indexOf('43-050') >= 0) {
                                    return value == '' ? { mandatory: true } : null;
                                }

                                return null;
                            };
                        })(),
                        this.ngUnsubscribe
                    )
                ),
                precisionOrientation: this.formService.createFormControl(
                    'precisionOrientation',
                    new FormContext('precisionOrientation', besoinData, [], this.ngUnsubscribe)
                ),
                commentairesId: this.formService.createFormControl(
                    'commentairesId',
                    new FormContext('commentairesId', besoinData, [], this.ngUnsubscribe)
                ),
            },
            { updateOn: 'blur' }
        );
    }
    onBesoinSelected(idBesoin) {
        this.idBesoinSelected = idBesoin;
        if (idBesoin ? this.isMeta : true) {
            this.idBesoinSelected = null;
            this.elementToLocate = null;
            this.currentMapTool = 'SAMPLING_POLLUANT';
            return;
        }
        const vols = this.volumes.filter((v) => this.selectedBesoin?.piecesId.includes(v.id));
        this.externalSelectedVolumes = vols;

        this.elementToLocate = this.selectedBesoin;
        this.currentMapTool = 'SELECTION';
    }

    /**
     * Suppression d'un espace du besoin (uniquement METOP)
     * @param volumeId
     * @param formBesoin
     */
    public supprimerEspaceMetop(volumeId, formBesoin) {
        formBesoin.get('piecesId').value = formBesoin.get('piecesId').value.filter((v) => v !== volumeId);
    }

    private loadBuilding() {
        if (this._currentBien.bien && this._currentBien.bien.jsonPlan) {
            this.building = cn_building.unserialize(JSON.parse(this._currentBien.bien.jsonPlan));
            /* TODO : Ajout du calque 
            const layer = new cn_layer('ZONE_' + this.indexZoneSelected + 1);
            layer.color = zone_colors[this.indexZoneSelected % 10];
            layer.stripes = 'full';
            //layer.sections = [{ storey: this.building.storeys[0].ID }];

            let volume = null;
            for (const surface of this.currentZone.listeSurfaces) {
                for (const idVolume of surface.listeIdVolume) {
                    volume = this.currentNiveau.volumes.find((vol) => vol.id === idVolume);
                    if (volume) {
                        break;
                    }
                }
            }
            layer.add_element(volume.spaceId, this.building.storeys[0].ID, 'wall');

            this.building.layers = [layer];
            */

            // Charge les markers
            this.initMarkers();

            // this.populateStoreyWithMarkers(this.diagnostic, this.building.storeys[0], espace, levelOfMarkerToDisplay);
        }
    }

    /**
     * En fonction de la zone sélectionnée : initialisation des markers
     * TODO : à faire après init zones
     * @private
     */
    private initMarkers() {
        this.building.storeys.forEach((storey) => {
            storey.markers = [];
        });
        this.selectedPerimetreInvestigation.listeMarkersJson.forEach((markerJson) => {
            const cnMarker: cn_marker = JSON.parse(markerJson);
            try {
                const storey = this.building.storeys[cnMarker['storey_index']];
                const marker = CndiagSampleMarker.unserialize(JSON.parse(markerJson), storey);
                this.building.get_markers();
                if (this.findFormBesoinByReferenceId(marker.idReference)) {
                    storey.markers.push(marker);
                }
            } catch (e) {}
        });
    }

    // /**w
    //  * Complete le cn_storey avec le backgroundMap et les markers
    //  * @param diagnostic
    //  * @param currentStorey
    //  *  @param espace
    //  * @param levelOfMarkerToDisplay
    //  * @param svgHapConf
    //  * @param showBySeuil
    //  */
    // populateStoreyWithMarkers(
    //     diagnostic: Diagnostic,
    //     currentStorey: cn_storey,
    //     espace: Espace,
    //     levelOfMarkerToDisplay: LevelToDisplay,
    //     svgHapConf: LevelToDisplay[] = [],
    //     showBySeuil: SeuilsToDisplay = {
    //         showZoneInferieurEgal50: false,
    //         showZoneEntre51Et500: false,
    //         showZoneEntre501Et1000: false,
    //         showZoneSuperieur1000: false,
    //         showZoneNonRealises: false,
    //         showPrelevementInferieurEgal50: false,
    //         showPrelevementEntre51Et500: false,
    //         showPrelevementEntre501Et1000: false,
    //         showPrelevementSuperieur1000: false,
    //         showPrelevementNonRealises: false,
    //     }
    // ) {
    //     // Si on a des résultats de labo, on maj les résultats interprétés des markers
    //     if (diagnostic.typePrestation === TypePrestationEnum.HAP_VALIDATION_RESULTATS) {
    //         this.updateMarkersResultLevel(diagnostic.contenuDiagnostic as Hap, espace, currentStorey);
    //     }
    //     // On affiche dans le storey uniquement les markers correspondant au level que l'on veut afficher
    //     currentStorey.markers = [];
    //     // Liste des pvts non réalisés
    //     const listePrelevementsNonRealises = this.getListePrelevements(espace, false);
    //     // Configuration à utiliser
    //     const levelsActif: LevelToDisplay[] = svgHapConf.length
    //         ? svgHapConf
    //         : this.getLevelsToDisplayByCurrentLevel(levelOfMarkerToDisplay);
    //     // Parcours de la liste de markers de l'espace
    //     const cndiagMarkers = espace.listeMarkersJson.map((json) =>
    //         CndiagMarker.unserialize(JSON.parse(json), currentStorey)
    //     );
    //     cndiagMarkers
    //         .filter((marker) => marker.level === LevelToDisplay.ZONE)
    //         .forEach((marker) => {
    //             // Les filtres de seuils sont activés, on update le resultLevel de la zone avec la pire valeur des prélèvements de cette zone
    //             if (showSeuilsZones(showBySeuil)) {
    //                 this.findWorstPvtScoreFromZone(espace, marker, currentStorey);
    //             } else {
    //                 // Les filtres de sont pas activés, on reset le resultLevel de la zone pour afficher les couleurs d'origines
    //                 marker.resultLevel = undefined;
    //                 this.majMarkerJson(espace, marker);
    //             }
    //         });

    //     currentStorey.markers = cndiagMarkers.filter(
    //         filterMarkers(levelsActif, showBySeuil, listePrelevementsNonRealises)
    //     );
    //     this.sortMarkers(currentStorey.markers);
    //     currentStorey.markers.forEach((m: CndiagMarker) => (m.selectable = m.level === levelOfMarkerToDisplay));
    // }

    // Appelé lorsqu'un volume du plan est cliqué
    public onTouchOnPlan($event) {
        if ($event.event == 'sample_moved') {
            const newMarker = $event.value[0];
            // Récupération du formulaire du besoin avec l'idReference
            // let formBesoin: FormGroup;
            // this.form.controls.forEach((formObj: FormGroup) => {
            //     let f = (formObj.get('besoins') as FormArray).controls.find((formB: FormGroup) => {
            //         return formB.get('id').value == newMarker.idReference;
            //     }) as FormGroup;
            //     if (f) {
            //         formBesoin = f;
            //     }
            // });

            // Mise à jour du marker dans les données du diag
            this.selectedPerimetreInvestigation.listeMarkersJson = this.selectedPerimetreInvestigation.listeMarkersJson.map((markerJson) => {
                const marker = JSON.parse(markerJson);
                // Remplace le précédent marker par sa nouvelle version
                if (marker.ID == newMarker.ID) {
                    // newMarker.tail_position = [1, 1, 0];
                    const markerString = JSON.stringify(newMarker.serialize());
                    return markerString;
                }
                return markerJson;
            });

            // this.selectedPerimetreInvestigation.listeMarkersJson.push(JSON.stringify($event.value.serialize()));
            // this.selectedPerimetreInvestigation.listeMarkersJson.find(())

            this.checkValidity();
            return;
        } else if ($event.event == 'remove_elements') {
            const oldMarker = $event.value[0];
            this.selectedPerimetreInvestigation.listeMarkersJson = this.selectedPerimetreInvestigation.listeMarkersJson.filter((markerJson) => {
                const marker = CndiagMarker.unserialize(JSON.parse(markerJson), this.currentStorey);
                return marker.ID != oldMarker.ID;
            });
            const formBesoin: FormGroup = this.findFormBesoinByReferenceId($event.value[0].idReference);
            formBesoin.patchValue({
                piecesId: [],
                markerId: null,
            });
            this.checkValidity();
            return;
        }

        if (this.currentMapTool == 'SELECTION') {
            const previous = [...this.externalSelectedVolumes];
            this.externalSelectedVolumes = [];
            if (!this.idBesoinSelected || $event.value == null) {
                this.externalSelectedVolumes = previous;
                return;
            }

            const volume = this.currentNiveau.volumes.find((v) => {
                return v.spaceId == $event.value[0].ID;
            });

            if (volume) {
                // Mise à jour de la pièce sélectionnée dans le besoin
                this.form.controls.forEach((formObj: FormGroup) => {
                    let formBesoin = (formObj.get('besoins') as FormArray).controls.find((formB: FormGroup) => {
                        return formB.get('id').value == this.idBesoinSelected;
                    });
                    if (formBesoin) {
                        if (!formBesoin.get('piecesId').value.find((v) => v === volume.id)) {
                            formBesoin.get('piecesId').value.push(volume.id);
                        }
                    }
                });
            }
        } else if (this.currentMapTool == 'SAMPLING_POLLUANT') {
            // Les volumes de la zone selectionnée :
            let lstVolumesIds = [];
            this.currentZone.listeSurfaces.forEach((surface) => {
                lstVolumesIds = lstVolumesIds.concat(surface.listeIdVolume);
            });
            const volumesSelected = this.currentNiveau.volumes.filter((v) => lstVolumesIds.includes(v.id));
            // Récupération du volume cliqué avec le spaceID
            const volume = volumesSelected.find((v) => v.spaceId == $event.value?.element?.ID);

            // Si on ne trouve pas de volume, c'est que l'utilsiateur n'a pas cliqué sur une pièce (mur par exemple)
            if (!volume) {
                this.notificationService.notify('Veuillez placer le besoin dans un volume de la zone');
                this.currentMapTool = 'SAMPLING_POLLUANT';
                this.elementToLocate = null;
                return;
            }
            const formBesoin: FormGroup = this.findFormBesoinByReferenceId($event.value.idReference);

            //MARKER edit
            // console.log('on pose le marker en edition');
            // console.log(this.currentBien);

            if (!formBesoin.get('markerId').value) {
                // Ajout du marker à la liste des markers
                this.selectedPerimetreInvestigation.listeMarkersJson.push(JSON.stringify($event.value.serialize()));

                // Ajout du marker au plan
                this.currentStorey.markers.push($event.value);
                // console.log(this.currentStorey.markers);
            } else {
                // Si le besoin a déjà un marker
                // On cherche le marker dans la liste par son ID et on le remplace avec le nouveau
                this.selectedPerimetreInvestigation.listeMarkersJson = [
                    ...this.selectedPerimetreInvestigation.listeMarkersJson.map((markerJson: string) => {
                        const marker = CndiagMarker.unserialize(JSON.parse(markerJson), this.currentStorey);
                        if (marker.idReference == $event.value.idReference) {
                            return JSON.stringify($event.value.serialize());
                        }
                        return markerJson;
                    }),
                ];
                // Pareil pour le plan
                this.currentStorey.markers = [
                    ...this.currentStorey.markers.map((marker) => {
                        if (marker.idReference == $event.value.idReference) {
                            return $event.value;
                        }
                        return marker;
                    }),
                ];
            }

            formBesoin.patchValue({
                piecesId: [volume.id],
                markerId: $event.value.ID,
            });
        }

        // Déselection de l'outil
        this.currentMapTool = 'SAMPLING_POLLUANT';
        this.elementToLocate = null;
        if (!this.isMeta) {
            this.onBesoinSelected(this.idBesoinSelected);
        }

        this.checkValidity();
    }
    /**
     * Return formulaire (du besoin) avec l'idReference
     * @param referenceId
     * @returns
     */
    private findFormBesoinByReferenceId(referenceId: string): FormGroup | null {
        for (const formObj of this.form.controls) {
            const besoinArray = formObj.get('besoins') as FormArray;
            const foundForm = besoinArray.controls.find((formB) => formB.get('id').value === referenceId);
            if (foundForm) {
                return foundForm as FormGroup;
            }
        }
        return null;
    }

    // Ajouter un besoin de prélèvement pour l'objectif de mesurage cliqué
    public ajouterBesoin(index) {
        const formObj = this.form.at(index) as FormGroup;
        const objectifMesurage = formObj.get('objectifMesurage').value;

        const nouveauBesoin = new Besoin();
        nouveauBesoin.numero = (this.besoins.length + 1).toString();
        nouveauBesoin.objectifMesurage = objectifMesurage;
        nouveauBesoin.zoneId = this.currentZone.id;

        (formObj.get('besoins') as FormArray).push(this.createFormBesoin(nouveauBesoin));

        // Incrémentation du nombre de volumes dans le formulaire de l'objectif
        formObj.get('nbBesoinSupp').setValue(parseInt(formObj.get('nbBesoinSupp').value) + 1);

        this.besoins.push(nouveauBesoin);

        this.calculateObjNumber();

        this.idBesoinSelected = nouveauBesoin.id;
    }

    public localiserPrelevement(event, idBesoin) {
        event.preventDefault();
        event.stopPropagation();

        this.idBesoinSelected = idBesoin;
        this.currentMapTool = 'SAMPLING_POLLUANT';
        this.elementToLocate = this.selectedBesoin;
    }

    supprimerBesoin(event, indexObjectif, indexBesoin) {
        event.preventDefault();
        event.stopPropagation();

        this.confirmationService.confirmWarn(`Voulez-vous supprimer définitivement ce besoin ?`, () => {
            const formObj = this.form.at(indexObjectif) as FormGroup;
            const besoinASupprimer = (formObj.get('besoins') as FormArray).at(indexBesoin).value;

            // Mise à jour du formulaire
            (formObj.get('besoins') as FormArray).removeAt(indexBesoin);

            // Mise à jour de la liste des besoins
            this.besoins = this.besoins.filter((b) => b.id != besoinASupprimer.id);

            // Mise à jour des marker du plan
            this.currentStorey.markers = this.currentStorey.markers.filter((marker) => {
                return marker.idReference != besoinASupprimer.id;
            });
            // Mise à jour des marker de la liste
            this.selectedPerimetreInvestigation.listeMarkersJson = this.selectedPerimetreInvestigation.listeMarkersJson.filter((markerJson) => {
                const marker = CndiagMarker.unserialize(JSON.parse(markerJson), this.currentStorey);
                return marker.idReference != besoinASupprimer.id;
            });
            // Si le besoin supprimé est le besoin actuellement sélectionné, alors on le déselectionne
            if (besoinASupprimer.id == this.idBesoinSelected) {
                this.idBesoinSelected = undefined;
            }
            if (this.viewerMap) {
                this.viewerMap.refresh();
            }

            this.calculateObjNumber();

            this.checkValidity();
        });
    }

    public changeZone(index) {
        this.indexZoneSelected = index;
        this.createForm();
    }

    public changeBien(id) {
        this.bien = this.intervention.relationInterventionBiens.find((value) => {
            return id == this.selectedPerimetreInvestigation.idBien;
        }).bien;
    }

    //

    /**
     * Sélectionne la zone précédente
     *
     * @param one
     */
    public nextOrPreviousZone(one: 1 | -1) {
        if (one === -1 ? this.indexZoneSelected > 0 : this.indexZoneSelected < this.zones.length - 1) {
            this.indexZoneSelected += one;
            this.createForm();
            this.initMarkers();
        }
    }

    // Sélectionne le niveau précédent
    public previousNiveau() {
        if (this.indexNiveauSelected > 0) {
            this.changeNiveau(this.indexNiveauSelected - 1);
        }
    }

    // Sélectionne le niveau suivant
    public nextNiveau() {
        if (this.indexNiveauSelected < this.bien.description.length - 1) {
            this.changeNiveau(this.indexNiveauSelected + 1);
        }
    }

    // Sélectionne le niveau en fonction de l'index passé en paramètre
    // Et charge le plan de ce niveau
    public changeNiveau(index) {
        this.indexNiveauSelected = index;
        this.loadBuilding();
    }

    private checkValidity() {
        const code = `${this.route.snapshot.data['code']}_${this.route.snapshot.params.idPerimetre}`;

        //Récupération de toutes les erreurs
        const erreursBesoins = [];

        this.contenuDiagnostic.besoins.errors = erreursBesoins;
        this.contenuDiagnostic.besoins.data.besoinsList = this.besoins;

        let etat: EtatValidation = 'INVALID';
        if (this.typeMesurage == TypePrestationEnum.POLLUANT_VISITE_CHANTIER) {
            //L'écran est valide si le formulaire n'a pas d'erreurs
            // et si les données des Zones ont été validées
            if (this.form && (this.form.valid || this.form.errors == null) && this.contenuDiagnostic.besoins.validated) {
                etat = 'VALID';
            }
        } else {
            //L'écran est valide si le formulaire n'a pas d'erreurs
            if (this.form?.valid) {
                etat = 'VALID';
                for (const formObj of this.form.controls) {
                    for (const j in formObj.get('besoins')['controls']) {
                        if (j >= formObj.get('nbBesoinMin').value && !formObj.get('besoins')['controls'][j]?.value?.commentairesId?.length) {
                            etat = 'INVALID';
                        } else if (formObj.get('besoins')['controls'][j]?.value.objectifMesurage.reference === '13J') {
                            if (!formObj.get('besoins')['controls'][j]?.value?.objectifMesurage?.contexte) {
                                etat = 'INVALID';
                            } else if (!formObj.get('besoins')['controls'][j]?.value?.objectifMesurage?.proessusName) {
                                etat = 'INVALID';
                            }
                        }
                    }
                }
            }
        }

        this.etatProgressionService.updateDiagnostic(code, etat, this.diagnostic, true);
    }

    /**
     * Retourne le nombre de besoin de prélèvement minimum
     * @See POLL-COR-514
     * @param nbPu
     * @param methodeCalculQuantitePompe
     * @param zone
     */
    getNbBesoins(nbPu: number, methodeCalculQuantitePompe: string, zone: Zone): number {
        //Non applicable
        if (methodeCalculQuantitePompe == 'NON_APPLICABLE') {
            if (zone.typeZone == 'Zone de travail') {
                return getNombreProcess(zone);
            }
            return 1;
        }

        //Nombre d'extracteurs
        if (methodeCalculQuantitePompe == 'NB_EXTRACTEURS') {
            //Nombre d'extracteurs défini dans la zone
            return zone.zoneAssociee?.nombreExtracteurs;
        }

        //Colonne A et B
        if (nbPu == 1) {
            //Si 1 PU
            // Si la surface totale de la PU est < 10 alors seulement 1 besoin
            let nbVolume = 0;
            for (const surface of zone.listeSurfaces) {
                nbVolume += surface.listeIdVolume ? surface.listeIdVolume.length : 0;
            }
            if (nbVolume === 1) {
                let surfaceTotalePu = zone.listeSurfaces.reduce((acc, surface) => {
                    acc += surface.superficie;
                    return acc;
                }, 0);

                if (surfaceTotalePu < 10) {
                    return 1;
                } else if (surfaceTotalePu < 25) {
                    // Uniquement "Mesure état initial (maître d'ouvrage & de l'entreprise)"
                    if (zone.objectifMesurage[0]?.objectifMesure === 'Etat initial') {
                        return 1;
                    }
                }
            }
            //return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 2 : 2;
            return 2;
        } else if (nbPu == 2) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 2 : 2;
        } else if (nbPu == 3 || nbPu == 4) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 2 : 3;
        } else if (nbPu == 5 || nbPu == 6) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 3 : 4;
        } else if (nbPu == 7 || nbPu == 8) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 3 : 5;
        } else if (nbPu >= 9 || nbPu <= 11) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 3 : 6;
        } else if (nbPu >= 12 || nbPu <= 14) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 3 : 7;
        } else if (nbPu >= 15 || nbPu <= 17) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 4 : 8;
        } else if (nbPu >= 18 || nbPu <= 20) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 4 : 9;
        } else if (nbPu >= 21 || nbPu <= 25) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 5 : 10;
        } else if (nbPu >= 26 || nbPu <= 31) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 5 : 11;
        } else if (nbPu >= 32 || nbPu <= 38) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 6 : 12;
        } else if (nbPu >= 39 || nbPu <= 46) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 6 : 13;
        } else if (nbPu >= 47 || nbPu <= 55) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 7 : 14;
        } else if (nbPu > 55) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? Math.round(nbPu / 8) : Math.round(nbPu / 4);
        }
    }

    /**
     * Calcul le nombre de PU total d'une zone
     */
    public puTotal(zone: Zone): number {
        return zone.listeSurfaces.reduce((total: any, surface: Surface) => {
            return total + Math.abs(surface.nombrePu ?? 0);
        }, 0);
    }

    /**
     * Fonction permettant de calculer le numéro de tous les obj de mesurage
     * En fonction des autres obj de mesurage du même type
     */
    calculateObjNumber() {
        let objs = {};

        //Pour chacun des besoins
        this.form.controls.forEach((formB: FormGroup) => {
            let besoins = formB.getRawValue().besoins;

            besoins = besoins.map((b, i) => {
                b.numero = (i + 1).toString();
                return b;
            });
            // Uncaught Error: NG01002: Must supply a value for form control with name: 'orientation'
            besoins.forEach((b) => {
                besoins['piecesId'] = [];
                for (const property of ['orientation', 'precisionOrientation']) {
                    if (!b[property]) {
                        b[property] = '';
                    }
                }
            });

            //mise à jour du formulaire
            formB.get('besoins').setValue(besoins);
            // //Comptage du nombre de besoins de prélèvements ayant la même référence
            // if (objs[besoin?.objectifMesurage?.reference] == undefined) {
            //     objs[besoin?.objectifMesurage?.reference] = [];
            // }
            // objs[besoin?.objectifMesurage?.reference].push(besoin.id);

            // let numero = (i + 1).toString();

            // besoin.numero = numero;
        });
    }

    //Interface IValidatableComponent implémentation
    cancelModification() {
        this.isInEditMode = false;
        this.contenuDiagnostic.besoins.data = this.previousFormValue;
        this.previousFormValue = undefined;
    }

    saveModification() {
        this.isInEditMode = false;
        this.contenuDiagnostic = BesoinsPrelevementsComponent.supprimerDonneesDependantes(this.contenuDiagnostic);

        this.checkValidity();
    }

    validateTab() {
        this.isValidated = true;
        this.contenuDiagnostic.besoins.validated = true;
        this.checkValidity();
    }

    startModification() {
        this.isInEditMode = true;
        this.previousFormValue = this.contenuDiagnostic.besoins.data;
    }

    static supprimerDonneesDependantes(contenuDiagnostic: Polluant) {
        BesoinsPrelevementsComponent.ecransDependants.forEach(({ nom, composant }: any) => {
            //Suppression en cascade des données des écrans dépendants
            if (composant.supprimerDonneesDependantes) {
                let r = composant.supprimerDonneesDependantes(contenuDiagnostic);
            }

            contenuDiagnostic[nom].data = {};
        });

        return contenuDiagnostic;
    }

    navigateEcran(nomEcran) {
        const url = this.router.url.split('/');
        url.pop();
        url.pop();
        url.push(nomEcran);
        this.router.navigateByUrl(url.join('/'));
    }

    // NEW
    /**
     * Toggle mode liste ou plan
     */
    changeViewMode(value) {
        this.viewMode = value.value;
    }

    public addJustification(buttonId: string) {
        try {
            document.getElementById(buttonId).click();
        } catch (e) {}
    }
    /**
     * Renvoi le FormGroup du besoin actuellement sélectionné
     */
    // get formCurrentBesoin(): FormGroup {
    //     return this.form.controls[this.idBesoinSelected] as FormGroup;
    // }

    get besoinsList(): Besoin[] {
        return this.contenuDiagnostic.besoins.data;
    }

    get currentZone(): Zone {
        return this.zones[this.indexZoneSelected];
    }

    get selectedBesoin(): Besoin {
        return this.besoins.find((b) => b.id == this.idBesoinSelected);
    }

    get isMeta(): Boolean {
        return this.selectedBesoin.objectifMesurage.norme.indexOf('43-050') >= 0;
    }

    get currentNiveau(): Niveau {
        return this.bien.description[this.indexNiveauSelected];
    }

    get currentStorey(): cn_storey {
        return this.building.storeys[this.indexNiveauSelected];
    }

    get lstProcessus(): string[] {
        const processus: string[] = [];
        for (const mpca of this.currentZone.listeMpca) {
            for (const proc of mpca.processus) {
                if (!processus.find((p) => p === proc.nom)) {
                    processus.push(proc.nom);
                }
            }
        }
        return processus;
    }

    ngOnDestroy(): void {
        this.checkValidity();
        super.ngOnDestroy();
    }
}
function getNombreProcess(zone: Zone): number {
    let nb = 0;
    zone.listeMpca.map((mpca) => mpca.processus.map((_) => nb++));
    if (nb == 0) return 1;
    return nb;
}
