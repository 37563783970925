<h1 mat-dialog-title class="primary d-flex justify-content-between">
    <span>Sélectionner les matériaux</span>
    <button mat-button type="button" (click)="onClickCancel()">
        <lib-icon>close</lib-icon>
    </button>
</h1>

<mat-dialog-content>
    <div class="d-flex flex-column">
        <div class="custom-materiau">
            <div *ngIf="addCustomIsPossible && !addMateriauIsOpen" class="w-100 d-flex justify-content-end">
                <button mat-raised-button color="accent" (click)="onClickDisplayAddMateriau()">
                    <lib-icon>add</lib-icon>
                    <span>Ajouter un matériau non listé</span>
                </button>
            </div>
            <!-- Nom du matériau à créé -->
            <div *ngIf="addMateriauIsOpen" class="d-flex align-items-center" [formGroup]="formAddMateriau">
                <div class="col-xl-4 mat-subheading-2 m-0 primary required">Nom du matériau</div>
                <div class="col-xl-4 pr-2">
                    <mat-form-field class="w-100">
                        <input matInput type="text" formControlName="nomMateriau" />
                        <mat-error *ngIf="formAddMateriau.get('nomMateriau').hasError('erreurNomMateriauExistant')">
                            Un autre matériau possède déjà le même nom.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-xl-4">
                    <button mat-button type="button" color="primary" (click)="onClickDisplayAddMateriau()">
                        <lib-icon>close</lib-icon>
                        <span>Annuler</span>
                    </button>
                    <button
                        mat-raised-button
                        color="accent"
                        (click)="onClickAddMateriau()"
                        [disabled]="formAddMateriau.invalid"
                    >
                        <lib-icon>check</lib-icon>
                        <span>Ajouter</span>
                    </button>
                </div>
            </div>
        </div>
        <mat-divider class="mt-2"></mat-divider>
        <!-- Type de materiau -->
        <div class="d-flex align-content-start flex-wrap overflow-auto height-350">
            <!-- On découpe la liste des matériaux en tableaux de 6 pour les afficher de façon régulière -->
            <ng-container
                *ngFor="let materiauxForLine of array(math.ceil(materiauxModalList.length / 6)).fill(6); let i = index"
            >
                <div class="row w-100 m-0 pt-2">
                    <ng-container *ngFor="let typeMateriau of materiauxModalList?.slice(6 * i, 6 * i + 6)">
                        <div class="col-2">
                            <app-card-clickable
                                [isSelected]="typeMateriau.isSelected"
                                [label]="typeMateriau.typeMateriau.nom"
                                (selectCardElement)="onClickCard(typeMateriau)"
                            ></app-card-clickable>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-end">
    <button mat-button type="button" color="primary" (click)="onClickCancel()">
        <lib-icon>close</lib-icon>
        <span>Annuler</span>
    </button>
    <button mat-raised-button color="accent" (click)="onClickValider()">
        <lib-icon>check</lib-icon>
        <span>Valider</span>
    </button>
</mat-dialog-actions>
