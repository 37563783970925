<div class="d-flex p-2 align-items-center">
    <lib-file-uploader
        #fileUploader
        (startUpload)="onStartUploadFile($event)"
        (uploadFinished)="onFinishUploadFile($event)"
    >
    </lib-file-uploader>
    <div *ngIf="displayLabel" class="col-xl-4 mat-subheading-2 m-0 primary" [class.required]="required">
        {{ labelDocument ?? documentTitle }}
    </div>
    <div class="col-xl-8 pr-2">
        <div class="d-flex flex-wrap align-items-center justify-content-between">
            <div>
                <div
                    *ngIf="fileControl?.value?.nomFichier"
                    class="ml-3 d-flex align-items-center c-pointer"
                    (click)="onClickOpenFile()"
                >
                    <span class="file-icon-container">
                        <mat-icon [ngClass]="fileControl?.value?.nomFichier | fileExtension">
                            insert_drive_file</mat-icon
                        >
                        <span class="file-extension">{{ fileControl?.value?.nomFichier | fileExtension }}</span>
                    </span>
                    <b>{{ fileControl?.value?.nomFichier | filenameWithoutExtension }}</b>
                </div>
                <div
                    *ngIf="!fileControl?.value?.nomFichier"
                    class="ml-3 c-pointer card-subtitle-bold"
                    (click)="!readOnlyMode ? onSelectFile() : ''"
                >
                    Aucun fichier attaché
                </div>
                <span *ngIf="fileControl?.value?.dateUpload" class="ml-3 card-subtitle-bold">Date d'upload : </span
                ><span class="card-text">{{ fileControl?.value?.dateUpload | date: 'longDate' }}</span>
            </div>
            <div>
                <ng-container *ngIf="fileControl?.value && !readOnlyMode">
                    <button type="button" mat-icon-button color="primary" (click)="onClickOpenFile()">
                        <mat-icon>remove_red_eye</mat-icon>
                    </button>
                    <button type="button" mat-icon-button color="warn" (click)="onClickDeleteFile()">
                        <mat-icon>clear</mat-icon>
                    </button>
                </ng-container>
                <button *ngIf="!readOnlyMode" type="button" mat-icon-button color="primary" (click)="onSelectFile()">
                    <mat-icon>attach_file</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
