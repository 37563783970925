import { BaseComponent } from 'src/app/commons-lib';
import { Component, OnInit } from '@angular/core';
import { InterventionReportData, IReportComponent, ReportBlockData } from '../../../../../model/rapport.model';
import { Anomalie, ElectriciteReportData } from '../../model/electricite.model';
import { Intervention } from '../../../../../model/intervention.model';
import { Commentaire } from '../../../../../model/commentaire.model';
import { ReportCommentsListModalComponent } from '../../../../diagnostics-shared/rapport/report-comments-list-modal/report-comments-list-modal.component';
import { DiagnosticService } from '../../../../../services/diagnostic.service';
import { MatDialog } from '@angular/material/dialog';
import { Diagnostic } from '../../../../../model/diagnostic.model';
import { TypePrestationEnum } from 'src/app/model/type-prestation.model';

@Component({
    selector: 'app-report-elec-block',
    templateUrl: './report-elec-block.component.html',
    styleUrls: ['./report-elec-block.component.scss'],
})
export class ReportElecBlockComponent extends BaseComponent implements OnInit, IReportComponent {
    intervention: Intervention;
    conclusionText: string;
    data: ReportBlockData;
    diagnosticData: ElectriciteReportData;
    interReportData: InterventionReportData;
    noAnomalie: boolean;
    anomalies: { titre: string; anomalies: Anomalie[] }[] = [];
    evalRisque: {
        titre: string;
        conclusionEvalRisque: any[];
    }[] = [];
    anomaliesPointControle: any[] = [];
    exclusionVolumesNonVisites: string[] = [];
    exclusionVolumesVisites: string[] = [];
    disclaimer: string;
    private diagnostic: Diagnostic;
    public readonlyMode: boolean;
    public editing: boolean;
    public conclusionLibelles = [
        { order: 1, description: "L'installation intérieure d'électricité ne comporte aucune anomalie." },
        { order: 2, description: "L'installation intérieure d'électricité comporte une ou des anomalies." },
        {
            order: 3,
            description:
                "L'installation intérieure d'électricité comporte une ou des anomalies. Toutefois, l'opérateur a identifié que la (les) mesure(s) compensatoire(s) ont été mise(s) en place pour limiter le risque de choc électrique",
        },
    ];

    constructor(private diagnosticService: DiagnosticService, private matDialog: MatDialog) {
        super();
        this.diagnosticService.getCurrentDiagnostic().subscribe((diag) => {
            this.diagnostic = diag;
            this.readonlyMode = this.diagnosticService.isReadOnlyMode(this.intervention, this.diagnostic);
        });
    }

    ngOnInit(): void {
        if (this.interReportData) {
            this.diagnosticData = this.interReportData.diagnostics.find(
                (dia) => dia.typePrestation === TypePrestationEnum.ELECTRICITE
            ) as ElectriciteReportData;
            this.noAnomalie = this.diagnosticData.anomaliesPointControle?.length ? false : true;
            this.diagnosticData.conclusionEvalRisque?.forEach((val) => {
                const index = this.evalRisque.findIndex((v) => v.titre === val.conclusionCategorieEvalRisque);
                if (index >= 0) {
                    this.evalRisque[index].conclusionEvalRisque.push(val);
                } else {
                    const eRisk = { titre: val.conclusionCategorieEvalRisque, conclusionEvalRisque: [val] };
                    this.evalRisque.push(eRisk);
                }
            });
            this.diagnosticData.anomaliesPointControle.sort((a, b) => {
                return a.conclusionEvalRisque?.reference?.localeCompare(b.conclusionEvalRisque?.reference);
            });
            this.conclusionText = this.diagnosticData.conclusionText;
            if (this.data) {
                if (this.data.values['disclaimer']) {
                    this.disclaimer = this.data.values['disclaimer'];
                }
            }
            this.prepareExclusionLists();
        }
    }

    editDisplayedComments(commentaires: Commentaire[]) {
        const comments = [];
        commentaires.forEach((c) => {
            const comment = new Commentaire();
            comment.id = c.id;
            comment.type = c.type;
            comment.contenu = c.contenu;
            comments.push(comment);
        });
        const hiddenComments = {};
        if (this.diagnostic.hiddenComments['COM_ELEC']?.length) {
            hiddenComments['COM_ELEC'] = [...this.diagnostic.hiddenComments['COM_ELEC']];
        }
        return this.matDialog
            .open(ReportCommentsListModalComponent, {
                data: {
                    comments,
                    code: 'COM_ELEC',
                    hiddenComments: Object.assign({}, hiddenComments),
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.diagnostic.hiddenComments = result.hiddenComments;
                }
            });
    }

    saveConclusion() {
        this.diagnostic.pointsDeControleBiens[0].valeursParametres['CONCLUSION_REPORT'] = this.conclusionText;
        this.editing = false;
        this.diagnosticService.upsert(this.intervention, this.diagnostic);
    }

    contexteIntervention(): string {
        if (this.interReportData.bienPrincipal.contexteIntervention === 'vente') {
            return 'Vente : Le present diagnostic est valable pour une durée de 3 ans.';
        } else if (this.interReportData.bienPrincipal.contexteIntervention === 'location') {
            return 'Location : Le present diagnostic est valable pour une durée de 6 ans.';
        } else {
            return "Aucun contexte d'intervention n'a été renseigné.";
        }
    }

    private prepareExclusionLists() {
        this.exclusionVolumesNonVisites = this.diagnostic.hiddenComments['VOLUMES_NON_VISITES']
            ? this.diagnostic.hiddenComments['VOLUMES_NON_VISITES']
            : [];
        this.exclusionVolumesVisites = this.diagnostic.hiddenComments['VOLUMES_VISITES'] ? this.diagnostic.hiddenComments['VOLUMES_VISITES'] : [];
    }
}
