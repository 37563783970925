<h1 mat-dialog-title>Sortir l'employé</h1>

<form [formGroup]="formDate" (ngSubmit)="confirm()">
    <div mat-dialog-content>
        <!-- Date de sortie de l'employé -->
        <div class="d-flex align-items-center">
            <div class="col-xl-4 mat-subheading-2 m-0 primary">Date de sortie</div>
            <mat-form-field class="col-xl-4 pr-2" (click)="datepickerDateSortie.open()" appearance="fill">
                <input matInput formControlName="dateSortie" [matDatepicker]="datepickerDateSortie" />
                <mat-datepicker-toggle matSuffix [for]="datepickerDateSortie"> </mat-datepicker-toggle>
                <mat-datepicker #datepickerDateSortie></mat-datepicker>
                <app-mat-error [control]="formDate.get('dateSortie')"></app-mat-error>
            </mat-form-field>
        </div>
    </div>

    <div mat-dialog-actions class="justify-content-end">
        <button mat-button color="primary" (click)="cancel()">
            <lib-icon>close</lib-icon>
            <span>{{ 'Annuler' | uppercase }}</span>
        </button>
        <button mat-raised-button color="primary" type="submit" [disabled]="formDate.invalid">
            <lib-icon>check</lib-icon>
            <span>{{ 'Confirmer' | uppercase }}</span>
        </button>
    </div>
</form>
