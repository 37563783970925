import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Zone } from '../../diagnostics/polluant/model/zone.model';

@Component({
    selector: 'app-widget-select-search',
    templateUrl: './widget-select-search.component.html',
    styleUrls: ['./widget-select-search.component.scss'],
})
export class WidgetSelectSearchComponent implements OnInit /*, ControlValueAccessor */ {
    @Output() modelChange: EventEmitter<any> = new EventEmitter();
    @Input('optionSelected') selectedOption: Zone;
    @Input('options') options: any[];
    @Input('idPerimetre') idPerimetre: string;

    optionControl = new FormControl('');

    filteredOptions: Observable<any[]>;
    value: string;

    ngOnInit() {
        if (this.options?.length > 0 && Object.keys(this.options[0]).length > 0) {
            this.filteredOptions = this.optionControl.valueChanges.pipe(
                startWith(''),
                map((value) => this._filter(value || ''))
            );

            this.optionControl.setValue(this.options[0]);
            this.modelChange.emit(this.options[0]);
        }
    }

    private _filter(value: any): any {
        if (typeof value === 'string') {
            const filterValue = value?.toLowerCase();
            return this.options.filter(
                (option) =>
                    option.identifiant.toLowerCase().includes(filterValue.toLowerCase()) ||
                    option.nom.toLowerCase().includes(filterValue.toLowerCase())
            );
        } else {
            this.selectedOption = value;
        }
    }

    displayWithZoneName(zone: Zone) {
        if (!zone) return '';
        return zone.nom != '' ? `${zone.identifiant} : ${zone.nom}` : `${zone.identifiant}`;
    }

    onSelectionChange(event) {
        this.modelChange.emit(event.option.value);
    }

    clearInput() {
        this.optionControl.setValue('');
    }
}
