import { Component, Injector } from '@angular/core';
import { BluetoothPrintingService } from '../../../../../../services/bluetooth-printing.service';
import { PolluantComponent } from '../../../utils/polluant-component';
import { PrelevementAir, Temoin } from '../../../model/polluant-prelevement.model';
import { ZplPolluantService } from '../../../services/zplPolluant.service';
import { Besoin } from '../../../model/besoin.model';
import { Sachet } from '../../../../../../model/sachet.model';

@Component({
    selector: 'app-root',
    templateUrl: './printing.component.html',
    styleUrls: ['./printing.component.scss'],
})
export class PrintingComponent extends PolluantComponent {
    prelevement: PrelevementAir;
    besoin: Besoin;
    sachet: Sachet;
    temoin: Temoin;

    constructor(private zplService: ZplPolluantService, private printingService: BluetoothPrintingService, private injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        this.prelevement = new PrelevementAir();
        this.prelevement.id = 'prélèvement ID';
        this.prelevement.reference = 'Prelo123456789';
        this.prelevement.numero = 659;
        this.prelevement.refSachet = 'Sachet12345678';
        this.prelevement.refIntervention = 'Inter123456789';

        this.besoin = new Besoin();
        this.besoin.numero = '354';
        this.besoin.mpcaId = 'description du matériau';

        this.prelevement.besoinPrelevement = this.besoin;

        this.sachet = new Sachet();
        this.sachet.reference = 'Sachet12345678';
        this.sachet.id = 'sachet ID';
        this.sachet.numero = 4;
        this.sachet.refIntervention = 'Inter123456789';
        this.sachet.prelevements = [this.prelevement];

        this.temoin = new Temoin();
        this.temoin.id = 'temoin ID';
        this.temoin.reference = 'Témoin12345678';
        this.temoin.refSachet = 'Sachet12345678';
        this.temoin.refIntervention = 'Inter123456789';
    }

    testEtiquetteMateriau() {
        let etiquetteZebra = this.zplService.generateLabelMateriau(this.prelevement);
        this.printingService.sendMessage(etiquetteZebra);
    }

    testEtiquetteSachet() {
        let etiquetteZebra = this.zplService.generateLabelSachet(this.sachet);
        this.printingService.sendMessage(etiquetteZebra);
    }

    testEtiquetteTemoin() {
        let etiquetteZebra = this.zplService.generateLabelTemoin(this.temoin);
        this.printingService.sendMessage(etiquetteZebra);
    }

    testEtiquetteLingette() {
        let etiquetteZebra = this.zplService.generateLabelLingette(this.prelevement);
        this.printingService.sendMessage(etiquetteZebra);
    }

    testEtiquetteMET() {
        let etiquetteZebra = this.zplService.generateLabelMET(this.prelevement);
        this.printingService.sendMessage(etiquetteZebra);
    }

    testEtiquetteMEST() {
        let etiquetteZebra = this.zplService.generateLabelMEST(this.prelevement);
        this.printingService.sendMessage(etiquetteZebra);
    }

    testResetConnexion() {
        console.log('reset connexion WS');
        this.printingService.resetConnexion();
    }

    testCalibrageEtiquette() {
        let etiquetteZebra = this.zplService.generateLabelCalibration();
        this.printingService.sendMessage(etiquetteZebra);
    }
}
