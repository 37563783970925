import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, throwIfEmpty } from 'rxjs/operators';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { isServerUnavailableError } from 'src/app/commons-lib';
import { SyncState } from 'src/app/services/syncState';

/**
 * Interface pour un cache hors ligne.
 * Gère des données (model/contenu) et de la métadata (créé, modifié, supprimé etc).
 */
export interface OfflineCache<T, ID> {
    /**
     * Le nom du champ id de l'entité géré par ce cache.
     */
    readonly idField: string;
    /**
     * L'observable gérant l'état en ligne / hors ligne de ce cache.
     */
    readonly synchState: Observable<SyncState>;

    /**
     * Requêter tous les éléments du cache.
     * @param ids permet de restreindre la recherche à certains éléments.
     * @param options permet de setter le flag 'includeDeleted', qui permet de renvoyer également les éléments
     * dont la métadata indique qu'ils ont été supprimés.
     * @return un observable d'un tableau de {@link DataWithMeta}.
     */
    queryAll(ids?: ID[], options?: { includeDeleted: boolean }): Observable<DataWithMeta<T>[]>;

    /**
     * Obtenir un seul élément du cache via son id.
     * @param id l'id
     * @param options permet de setter le flag 'includeDeleted', qui permet de renvoyer également les éléments
     * dont la métadata indique qu'ils ont été supprimés.
     * @return un observable d'un {@link DataWithMeta}.
     */
    queryOne(id: ID, options?: { includeDeleted: boolean }): Observable<DataWithMeta<T>>;

    /**
     * Ajoute un élément au cache. Si l'élément n'est pas encore dans le cache et le flag 'noCreation' est à false (valeur par défaut),
     * l'élément est flagué 'créé' dans les metadatas.
     * S'il existe déjà dans le cache et le flag 'noCreation' est à false (valeur par défaut), il est flagué 'modifié' dans les metadatas.
     * TODO expliquer force et noCreation
     * @param data l'élement concerné
     * @param metadata possibles métadata à utiliser (sinon null)
     * @param force permet de forcer la mise à jour du cache.
     * @param noCreation
     */
    putOne(data: T, metadata?: OfflineCacheMetaData, force?: boolean, noCreation?: boolean): Observable<DataWithMeta<T>>;

    /**
     * Idem que {@link putOne} mais pour un tableau d'éléments.
     * @param data
     * @param metadatas
     * @param force
     * @param noCreation
     */
    putAll(data: T[], metadatas?: OfflineCacheMetaData[], force?: boolean, noCreation?: boolean): Observable<DataWithMeta<T>[]>;

    /**
     * Flag un élément comme supprimé dans les métadatas (s'il existe). Si le flag 'force' est positionné,
     * on supprime réellement du cache.
     * @param data
     * @param force
     */
    remove(data: T, force?: boolean): Observable<boolean>;

    /**
     * Exécuter le push de la synchro : on envoie tous les éléments flagués créés, modifiés ou supprimés dans le 'dataHandler'.
     * Si le {@link PushResult} indique que l'élément a été supprimé on supprime du cache.
     * Sinon on met à jour le cache avec le résultat de la synchro pour l'élément concerné.
     * @param dataHandler
     */
    doPush(dataHandler: (dataWithMedata: DataWithMeta<T>) => Observable<PushResult<T>>): Observable<any>;
}

/**
 *
 */
export interface OfflineCacheWrapper<T, ID> {
    get<Q>(
        onlineRequest: (query?: Q) => Observable<T[]>,
        offlineRequest?: (query: Q, store: OfflineCache<T, ID>) => Observable<DataWithMeta<T>[]>
    ): OfflineRequest<Q, T[]>;

    getOne<Q>(
        onlineRequest: (query?: ID) => Observable<T>,
        offlineRequest?: (query: ID, store: OfflineCache<T, ID>) => Observable<DataWithMeta<T>>
    ): OfflineRequest<ID, T>;

    save(saveRequest: (data?: T) => Observable<T>): OfflineRequest<T, T>;

    delete(deleteRequest: (data?: T) => Observable<boolean>): OfflineRequest<T, boolean>;
}

/** Interface permettant de gérer la synchronisation d'un cache.
 *
 */
export interface CacheSync<T> {
    /**
     * À appeler pour pousser des éléments depuis le cache vers le stockage en ligne.
     * @param create
     * @param modify
     * @param deleteRequest
     */
    push(
        create: (data?: T) => Observable<T>,
        modify: (data?: T) => Observable<T>,
        deleteRequest: (data?: T) => Observable<T>
    ): OfflineRequest<any, any>;

    /**
     * À appeler pour tirer des éléments depuis le stockage en ligne.
     * @param pullAction
     */
    pull(pullAction: () => Observable<T[]>): Observable<DataWithMeta<T>[]>;
}

export interface OfflineRequest<IN, OUT> {
    request(query?: IN): Observable<OUT>;
}

export class OfflineCacheMetaData {
    constructor(public modifiedLocally?, public createdLocally?, public deletedLocally?) {}
}

export class WithMeta {
    public metadata: OfflineCacheMetaData;
}

export class DataWithMeta<T> {
    content: T;
    metadata: OfflineCacheMetaData;
}

export class PushResult<T> {
    pushedData: T;
    savedData?: T;
    remove?: boolean;
}

/**
 * Outil pour gérer la séparation des métadatas du contenu des entités.
 * Permet de combiner les 2 en entrées du cache et de les séparer de nouveau à la sortie du cache.
 */
export class MetadataHandler<T> {
    /**
     * Ajouter le champ métadata à l'entrée du cache.
     * @param data
     * @param meta
     */
    public addMetadata(data: T, meta?: OfflineCacheMetaData): T & WithMeta {
        const metadata = Object.assign({ modifiedLocally: false, deletedLocally: false, createdLocally: false }, meta);
        return Object.assign({}, data, { metadata });
    }

    /**
     * Séparer les métadatas du contenu à la sortie du cache.
     * @param data
     */
    public splitMetadata(data: T & WithMeta): DataWithMeta<T> {
        const content = Object.assign({}, data);
        const metadata = content.metadata;
        delete content.metadata;
        return { content, metadata };
    }
}

/**
 * Implémentation du {@link OfflineCache} en utilisant {@link NgxIndexedDBService}.
 */
export class SimpleOfflineCache<T, ID> implements OfflineCache<T, ID> {
    private metadataHandler = new MetadataHandler<T>();

    /**
     * Constructeur
     * @param cacheName le nom du cache / de la collection indexeddb
     * @param idField le nom du champ id.
     * @param synchState un observable indiquant l'état en ligne / hors ligne.
     * @param indexeddb le service NgxIndexedDb
     */
    constructor(
        private cacheName: string,
        public readonly idField: string,
        public readonly synchState: Observable<SyncState>,
        private indexeddb: NgxIndexedDBService
    ) {}

    /**
     * Voir {@link OfflineCache}
     * @param data
     * @param metadatas
     * @param force
     * @param noCreation
     */
    putAll(data: T[], metadatas: OfflineCacheMetaData[] = [], force = false, noCreation = false): Observable<DataWithMeta<T>[]> {
        const ops = (data || []).map((val, idx) =>
            switchMap((last: DataWithMeta<T>[]) =>
                combineLatest(
                    last
                        .map((l) => {
                            return of(l);
                        })
                        .concat(this.putOne(val, metadatas ? metadatas[idx] : null, force, noCreation))
                )
            )
        );
        return ops.reduce((ob, op) => ob.pipe(op), of([]));
    }

    /**
     * Voir {@link OfflineCache}
     * @param data
     * @param metadata
     * @param force
     * @param noCreation
     */
    putOne(data: T, metadata?: OfflineCacheMetaData, force = false, noCreation = false): Observable<DataWithMeta<T>> {
        this.assureId(data);
        const id = data[this.idField];
        return this.indexeddb.getByID(this.cacheName, id).pipe(
            switchMap((d: T & WithMeta) => {
                const exists = !!d;
                let existingWithMetadata;
                if (exists) {
                    existingWithMetadata = this.metadataHandler.splitMetadata(d);
                    if (existingWithMetadata.metadata.deletedLocally) {
                        console.log('item supprimé en local', id);
                        return of(existingWithMetadata);
                    } else if (existingWithMetadata.metadata.modifiedLocally && !force) {
                        console.log('item modifié en local et pas de force', id);
                        return of(existingWithMetadata);
                    } else if (existingWithMetadata.metadata.createdLocally && !force) {
                        console.log('item créé en local et pas de force', id);
                        return of(existingWithMetadata);
                    } else {
                        if (!noCreation) {
                            existingWithMetadata.metadata.modifiedLocally = true;
                        }
                        const insertedMetadata = Object.assign({}, metadata, existingWithMetadata.metadata);
                        const insertedData = this.metadataHandler.addMetadata(data, insertedMetadata);
                        return this.indexeddb.update(this.cacheName, insertedData).pipe(map(() => this.metadataHandler.splitMetadata(insertedData)));
                    }
                } else {
                    let insertedMetadata = Object.assign({}, metadata);
                    if (!noCreation) {
                        insertedMetadata = Object.assign(insertedMetadata, { createdLocally: true });
                    }
                    const insertedData = this.metadataHandler.addMetadata(data, insertedMetadata);
                    return this.indexeddb.add(this.cacheName, insertedData).pipe(map(() => this.metadataHandler.splitMetadata(insertedData)));
                }
            }),
            catchError((err) => {
                console.log(err);
                return throwError(err);
            })
        );
    }

    /**
     * Voir {@link OfflineCache}
     * @param ids
     * @param options
     */
    queryAll(ids?: ID[], options?: { includeDeleted: boolean }): Observable<DataWithMeta<T>[]> {
        return this.indexeddb.getAll<T & WithMeta>(this.cacheName).pipe(
            map((result) => (ids ? result.filter((r) => ids.some((i) => r[this.idField] === i)) : result)),
            map((result: (T & WithMeta)[]) => (options && options.includeDeleted ? result : result.filter((r) => !r.metadata.deletedLocally))),
            map((result: (T & WithMeta)[]) => result.map((r) => this.metadataHandler.splitMetadata(r)))
        );
    }

    /**
     * Voir {@link OfflineCache}
     * @param id
     * @param options
     */
    queryOne(id: ID, options?: { includeDeleted: boolean }): Observable<DataWithMeta<T>> {
        return this.queryAll([id], options).pipe(map(([result]) => result));
    }

    /**
     * Voir {@link OfflineCache}
     * @param data
     * @param force
     */
    remove(data: T, force?: boolean): Observable<boolean> {
        const id = data[this.idField];
        return this.indexeddb.getByID<T & WithMeta>(this.cacheName, id).pipe(
            switchMap((d) => {
                if (d && force) {
                    return of(d).pipe(
                        switchMap(() => this.indexeddb.delete(this.cacheName, d[this.idField])),
                        map(() => true)
                    );
                } else if (d) {
                    return of(d).pipe(
                        tap(() => (d.metadata.deletedLocally = true)),
                        switchMap(() => this.indexeddb.update(this.cacheName, d)),
                        map(() => true)
                    );
                } else {
                    return of(false);
                }
            })
        );
    }

    /**
     * Voir {@link OfflineCache}
     * @param dataHandler
     */
    public doPush(dataHandler: (dataWithMedata: DataWithMeta<T>) => Observable<PushResult<T>>) {
        return this.indexeddb.getAll<T & WithMeta>(this.cacheName).pipe(
            map((data) => data.filter((m) => m.metadata.createdLocally || m.metadata.modifiedLocally || m.metadata.deletedLocally)),
            map((data) => data.map((d) => this.metadataHandler.splitMetadata(d))),
            switchMap((data) =>
                this.combineArrayToSequentialObservable(
                    data,
                    (d) =>
                        dataHandler(d).pipe(
                            switchMap((pushResult: PushResult<T>) => {
                                if (pushResult.remove) {
                                    return this.indexeddb.delete<T & WithMeta>(this.cacheName, pushResult.pushedData[this.idField]);
                                } else if (pushResult.pushedData) {
                                    return this.indexeddb.update<T & WithMeta>(
                                        this.cacheName,
                                        this.metadataHandler.addMetadata(pushResult.pushedData, {
                                            modifiedLocally: false,
                                            createdLocally: false,
                                        })
                                    );
                                } else {
                                    // TODO is this ok ?
                                    return of(null);
                                }
                            })
                        ),
                    of([])
                ).pipe(map((result) => result.filter((r) => !!r)))
            )
        );
    }

    private assureId(d: T) {
        if (!d[this.idField]) {
            // tslint:disable-next-line:no-console
            console.info('id field not defined ', d, this.idField);
            throw Error('id field not defined');
        }
    }

    /**
     * Exécute un callback sur un tableau d'objets pour former une chaîne d'observable qui est exécuté de manière séquentielle,
     * Quand le premier élément est traité, on démarre le second, pas en même temps.
     * Permet d'assurer de ne pas déclencher trop de requêtes http en même temps par exemple
     * et de contrôler l'ordre d'exécution.
     * @param items les items à traiter
     * @param req la callback renvoyant un observable. Exécuté une fois par items en entrée.
     * @param start l'observable servant de départ (souvent un tableau vide).
     * @private
     */
    private combineArrayToSequentialObservable<I, O>(items: I[], req: (item: I) => Observable<O>, start: Observable<O[]>) {
        return items.reduce((ob: Observable<O[]>, d: I) => ob.pipe(switchMap((last) => combineLatest(last.map((l) => of(l)).concat(req(d))))), start);
    }
}

/**
 * Implémentation par défaut du {@link CacheSync} et {@link OfflineCacheWrapper}.
 */
export class SimpleCacheWrapper<T, ID> implements OfflineCacheWrapper<T, ID>, CacheSync<T> {
    constructor(private cache: OfflineCache<T, ID>) {}

    public pull(pullAction: () => Observable<T[]>) {
        return pullAction().pipe(switchMap((data) => this.cache.putAll(data, null, false, true)));
    }

    public get<Q>(
        onlineRequest: (query?: Q) => Observable<T[]>,
        offlineRequest?: (query: Q, store: OfflineCache<T, ID>) => Observable<DataWithMeta<T>[]>
    ) {
        return new OfflineArrayRequest(this.cache, onlineRequest, offlineRequest);
    }

    public getOne(
        onlineRequest: (query?: ID) => Observable<T>,
        offlineRequest?: (query: ID, store: OfflineCache<T, ID>) => Observable<DataWithMeta<T>>
    ) {
        let request;
        if (!offlineRequest) {
            request = (id: ID, store: OfflineCache<T, ID>) => store.queryOne(id);
        } else {
            request = offlineRequest;
        }
        return new OfflineSingleItemRequest(this.cache, onlineRequest, request);
    }

    public save(saveRequest: (data?: T) => Observable<T>): OfflineRequest<T, T> {
        return new OfflineSaveRequest(this.cache, saveRequest);
    }

    public delete(deleteRequest: (data?: T) => Observable<boolean>) {
        return new OfflineDeleteRequest(this.cache, deleteRequest);
    }

    public push(
        create: (data?: T) => Observable<T>,
        modify: (data?: T) => Observable<T>,
        deleteRequest: (data?: T) => Observable<T>
    ): OfflineRequest<any, any> {
        return new PushRequest(this.cache, create, modify, deleteRequest);
    }
}

export function wrap<T, ID>(cache: OfflineCache<T, ID>) {
    return new SimpleCacheWrapper<T, ID>(cache);
}

export class OfflineArrayRequest<T, Q, ID> implements OfflineRequest<Q, T[]> {
    constructor(
        private cache: OfflineCache<T, ID>,
        private onlineRequest: (query?: Q) => Observable<T[]>,
        private offlineRequest: (query: Q, store: OfflineCache<T, ID>) => Observable<DataWithMeta<T>[]>
    ) {}

    request(query?: Q): Observable<T[]> {
        return this.cache.synchState.pipe(
            take(1),
            switchMap((online) => {
                if (online == SyncState.Online) {
                    return this.onlineRequest(query).pipe(
                        catchError((err) => {
                            console.log('OfflineArrayRequest error', err);
                            if (isServerUnavailableError(err)) {
                                return of(null);
                            } else {
                                return throwError(err);
                            }
                        }),
                        switchMap((onlineItems) => this.offlineRequest(query, this.cache).pipe(map((offlineItems) => [onlineItems, offlineItems]))),
                        switchMap(([onlineItems, offlineItems]) => {
                            if (onlineItems) {
                                // On cherche les éléments modifiés localement
                                const modifiedOfflineItems = offlineItems.filter((offline) => offline.metadata.modifiedLocally);
                                // on rafraichit le cache (c'est le cache qui s'occupe de ne pas écraser ce qui a été modifié localement.
                                return this.cache.putAll(onlineItems, null, false, true).pipe(
                                    map(() =>
                                        onlineItems.map((onlineItem) => {
                                            const offlineItem = modifiedOfflineItems.find(
                                                (m) => m.content[this.cache.idField] === onlineItem[this.cache.idField]
                                            );
                                            // s'il existe un item modifié localement on le retourne à la place de l'item local.
                                            if (offlineItem) {
                                                return offlineItem.content;
                                            } else {
                                                return onlineItem;
                                            }
                                        })
                                    )
                                );
                            } else {
                                return of(offlineItems.map((o) => o.content));
                            }
                        })
                        // TODO gestion d'erreur
                    );
                } else {
                    return this.offlineRequest(query, this.cache).pipe(map((d) => d.map((a) => (a ? a.content : undefined))));
                }
            })
        );
    }
}

function formatErrorMessage<Q, T, ID>(query: Q, cache: OfflineCache<T, ID>) {
    const cacheName = cache && (cache as unknown as any).cacheName ? (cache as unknown as any).cacheName : '';
    return `Item ${cacheName} ${query} non disponible en mode hors ligne`;
}

export class OfflineSingleItemRequest<Q, T, ID> implements OfflineRequest<Q, T> {
    private delegate: OfflineArrayRequest<T, Q, ID>;

    constructor(
        private cache: OfflineCache<T, ID>,
        private onlineRequest: (query?: Q) => Observable<T>,
        private offlineRequest: (query: Q, store: OfflineCache<T, ID>) => Observable<DataWithMeta<T>>
    ) {
        const wrappedOnlineQuery = (query?: Q) => onlineRequest(query).pipe(map((result) => [result]));
        const wrappedOfflineQuery = (query, store) => offlineRequest(query, store).pipe(map((result) => (result ? [result] : [])));
        this.delegate = new OfflineArrayRequest(this.cache, wrappedOnlineQuery, wrappedOfflineQuery);
    }

    public request(query?: Q) {
        return this.delegate.request(query).pipe(
            map(([result]) => result),
            take(1),
            filter((result) => !!result),
            throwIfEmpty(() => new Error(formatErrorMessage(query, this.cache)))
        );
    }
}

class OfflineSaveRequest<T, ID> implements OfflineRequest<T, T> {
    constructor(private cache: OfflineCache<T, ID>, private saveRequest: (data?: T) => Observable<T>) {}

    request(data?: T) {
        const id = data[this.cache.idField];
        return this.cache.synchState.pipe(
            take(1),
            switchMap((online) => {
                if (online == SyncState.Online) {
                    return this.saveRequest(data).pipe(
                        switchMap((savedData) => {
                            if (savedData && savedData[this.cache.idField]) {
                                return this.cache.putOne(savedData, null, true, true).pipe(map((d) => d.content));
                            } else {
                                return of(savedData);
                            }
                        }),
                        catchError((err) => {
                            console.log('erreur au save, on vas enregistrer en local pour une prochaine synchro.' + err.message);
                            return this.cache.putOne(data, null, true).pipe(map((d) => d.content));
                        })
                        //retryWhen((errors) => errors.pipe(delay(1000), take(3)))
                    );
                } else {
                    if (data && data[this.cache.idField]) {
                        return this.cache.putOne(data, null, true).pipe(map((d) => d.content));
                    } else {
                        return of(data);
                    }
                }
            })
        );
    }
}

class OfflineDeleteRequest<T, R, ID> implements OfflineRequest<T, boolean> {
    constructor(private cache: OfflineCache<T, ID>, private deleteRequest: (data?: T) => Observable<R>) {}

    request(data: T): Observable<boolean> {
        return this.cache.synchState.pipe(
            take(1),
            switchMap((online) => {
                if (online == SyncState.Online) {
                    return this.deleteRequest(data).pipe(switchMap(() => this.cache.remove(data, true)));
                } else {
                    return this.cache.remove(data);
                }
            }),
            map(() => true)
        );
    }
}

class PushRequest<T, ID> implements OfflineRequest<any, any> {
    constructor(
        private cache: OfflineCache<T, ID>,
        private createRequest: (data: T) => Observable<T>,
        private updateRequest: (data: T) => Observable<T>,
        private deleteRequest: (data: T) => Observable<T>
    ) {}

    request(): Observable<any> {
        const req = (d: DataWithMeta<T>) => {
            // Si l'objet est créé et supprimé dans la même session sans synchronization entre temps, rien à faire
            if (d.metadata.deletedLocally && d.metadata.createdLocally) {
                return of(null);
            } else if (d.metadata.deletedLocally) {
                return this.deleteRequest(d.content).pipe(map(() => ({ remove: true, pushedData: d.content })));
            } else if (d.metadata.createdLocally) {
                return this.createRequest(d.content).pipe(map((savedData) => ({ pushedData: d.content, savedData, remove: !savedData })));
            } else if (d.metadata.modifiedLocally) {
                return this.updateRequest(d.content).pipe(map((savedData) => ({ pushedData: d.content, savedData, remove: !savedData })));
            }
        };
        return this.cache.doPush(req);
    }
}
