'use strict';
//***********************************************************************************
//***********************************************************************************
//**** Build nomenclatures
//***********************************************************************************
//***********************************************************************************

import { cn_nomenclature, export_layers_zpsos, find_zone, push_cn_nomenclatures_zones } from '../cn_nomenclature';
import { cn_azimut, cn_mul } from '../cn_utilities';
import { cn_building } from '../../model/cn_building';
import { cn_storey, STOREY_EXTERIOR_LABEL } from '../../model/cn_storey';
import { extension_instance } from '../../extension/cn_extension';
import { cn_zone } from '../../model/cn_zone';
import { cn_plugin_option } from '../cn_plugin_option';

//***********************************************************************************
/**
 * Buils wall nomenclatures for the building
 * @param {cn_building} building
 * @param is_exterior
 * @returns {cn_nomenclature[]}
 */

export function cn_nomenclature_walls(building, is_exterior = false) {
    const output = [];
    output.push(new cn_nomenclature('Nom'));
    output.push(new cn_nomenclature('Type'));
    output.push(new cn_nomenclature('Niveau'));
    push_cn_nomenclatures_zones(output, true);
    output.push(new cn_nomenclature('Espace 1'));
    output.push(new cn_nomenclature('Espace 2'));
    if (!is_exterior) {
        output.push(new cn_nomenclature('Structure'));
        output.push(new cn_nomenclature('Isolation'));
    }
    output.push(new cn_nomenclature('Orientation').withNumberDefinition('°', 0));
    output.push(new cn_nomenclature('Longueur 1').withNumberDefinition('m', 2));
    output.push(new cn_nomenclature('Longueur 2').withNumberDefinition('m', 2));
    output.push(new cn_nomenclature('Hauteur').withNumberDefinition('m', 2));
    output.push(new cn_nomenclature('Epaisseur').withNumberDefinition('m', 2));
    output.push(new cn_nomenclature('Surface totale 1').withNumberDefinition('m²', 2));
    output.push(new cn_nomenclature('Surface totale 2').withNumberDefinition('m²', 2));
    if (!is_exterior) {
        output.push(new cn_nomenclature('Surface d\'échange').withNumberDefinition('m²', 2));
        output.push(new cn_nomenclature('Surface hors baies 1').withNumberDefinition('m²', 2));
        output.push(new cn_nomenclature('Surface hors baies 2').withNumberDefinition('m²', 2));
        output.push(new cn_nomenclature('Surface d\'échange hors baies').withNumberDefinition('m²', 2));
        output.push(new cn_nomenclature('U').withNumberDefinition('W/m²/K', 3));
    } else {
        output.push(new cn_nomenclature('Surface hors ouvertures 1').withNumberDefinition('m²', 2));
        output.push(new cn_nomenclature('Surface hors ouvertures 2').withNumberDefinition('m²', 2));
    }
    output.push(new cn_nomenclature('Volume').withNumberDefinition('m3', 2));
    output.push(new cn_nomenclature('Volume hors baies').withNumberDefinition('m3', 2));
    output.push(new cn_nomenclature('Calques').withVisibility(false));
    if (cn_plugin_option.zpsos) {
        output.push(new cn_nomenclature('ZPSOs').withVisibility(false));
    }

    const zones = building.zones;
    if (is_exterior) {
        /*** exterior */
        populate_nomenclature_walls(building.exterior, output, zones, is_exterior);
    } else {
        /*** interior */
        building.storeys.length && building.storeys.forEach((storey) => {
            populate_nomenclature_walls(storey, output, zones, is_exterior);
        });
    }

    return output;
}

//***********************************************************************************
//***********************************************************************************
/**
 /**
 * Builds wall nomenclatures for the given storey
 * @param {cn_storey} storey
 * @param {any[]} output
 * @param {{[property: string]: cn_zone[]}} zones
 * @param {boolean} is_exterior
 */
function populate_nomenclature_walls(storey, output, zones, is_exterior = false) {
    if (!storey.exterior) {
        storey.build_space_volume();
        storey.build_facing_volume();
    }
    storey.scene.storey = storey;
    storey.get_walls().filter(w => w.valid).forEach((wall) => {
        if (!wall.balcony) {

            const wall_type = wall.wall_type;
            let k = 0;

            //*** name */
            output[k].values.push(wall_type.get_label());
            k++

            //*** Type */
            output[k].values.push(wall_type.get_category_label());
            k++

            //*** storey */
            let storey_name = storey.exterior ? STOREY_EXTERIOR_LABEL : storey.storey_index;
            output[k].values.push(storey_name);
            k++

            const s0 = (wall.get_flow_direction()) ? 0 : 1;
            const s1 = 1 - s0;

            //*** Zone */
            extension_instance.zone.get_zones_tools().forEach(tool_zone => {
                let zone_to_display = find_zone(zones[tool_zone.property], wall.spaces[s0].ID, storey.ID);
                if (!zone_to_display) {
                    zone_to_display = find_zone(zones[tool_zone.property], wall.spaces[s1].ID, storey.ID);
                }
                output[k].values.push(zone_to_display);
                k++;
            });

            //*** Space 1 */
            output[k].values.push(wall.spaces[s0].get_name(storey));
            k++

            //*** Space 2 */
            output[k].values.push(wall.spaces[s1].get_name(storey));
            k++

            if (!is_exterior) {
                //*** Structure  */
                let structure = '';
                if (!wall.fence) {
                    structure = wall_type.get_structure_label();
                }
                output[k].values.push(structure);
                k++

                //*** Insulation  */
                let insulation = '';
                if (!wall.fence) {
                    insulation = wall_type.get_insulation_label();
                }
                output[k].values.push(insulation);
                k++
            }

            //*** orientation */
            const flow_normal = cn_mul(wall.bounds.normal, (s0 == 0) ? 1 : -1);
            output[k].values.push(cn_azimut(flow_normal, storey.building.compass_orientation));
            k++

            const wall_metrics = wall.get_metrics(storey);

            //*** length  */
            output[k].values.push(wall_metrics.side_length_1);
            k++
            output[k].values.push(wall_metrics.side_length_2);
            k++

            //*** height  */
            output[k].values.push(wall_metrics.height);
            k++

            //*** thickness  */
            output[k].values.push(wall_metrics.thickness);
            k++

            //*** full area  */
            output[k].values.push(wall_metrics.gross_side_area_1);
            k++
            output[k].values.push(wall_metrics.gross_side_area_2);
            k++

            if (!is_exterior) {
                output[k].values.push(wall_metrics.gross_side_exchange_area);
                k++
            }

            //*** area without openings  */
            output[k].values.push(wall_metrics.net_side_area_1);
            k++

            output[k].values.push(wall_metrics.net_side_area_2);
            k++

            if (!is_exterior) {
                output[k].values.push(wall_metrics.net_side_exchange_area);
                k++

                output[k].values.push(wall_type.get_U());
                k++
            }

            //*** volume  */
            output[k].values.push(wall_metrics.gross_volume);
            k++
            output[k].values.push(wall_metrics.net_volume);
            k++

            //*** layers & zpsos  */
            k = export_layers_zpsos(storey, wall, wall_type, output, k);
        }
    });
}
