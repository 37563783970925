import { ITypePrestationInterface } from '../../../../services/interfaces/type-prestation.interface';
import { Injectable, Type } from '@angular/core';
import { TypePrestation, TypePrestationEnum } from '../../../../model/type-prestation.model';
import { Route } from '@angular/router';
import { ContenuDiagnostic } from '../../../../model/diagnostic-contenu.model';
import { Polluant } from '../model/polluant.model';
import { Intervention } from '../../../../model/intervention.model';
import { Diagnostic } from '../../../../model/diagnostic.model';
import { IDiagReportData, InterventionReportData } from '../../../../model/rapport.model';
import { Commentaire } from '../../../../model/commentaire.model';
import { CommentairePredefini } from '../../../../model/commentaire-predefini.model';
import { PolluantService } from './polluant.service';
import { polluantEtudeSituationNavbarRoutes } from '../../polluant/routing/polluant-etude-situation/polluant-etude-situation-routing.module';
import { Rule } from '../../../../model/regle.model';
import { BonCommandeAnalyseAdmin } from '../../../../model/bon-commande.model';
import { Observable, of } from 'rxjs';
import { LegendScreenshot } from '../../../../model/screenshot-svg.model';
import { cn_storey } from '@acenv/cnmap-editor';
import { Bien, Niveau, Volume } from '../../../../model/bien.model';
import { PointDeControleService } from '../../../../services/point-de-controle.service';
import { TypeVolume } from '../../../../model/type-element-a-controler.model';

@Injectable({
    providedIn: 'root',
})
export class TypePolluantResultatsAnalysesService implements ITypePrestationInterface {
    prepareStoreyForScreenshot(diagnostic: Diagnostic, currentStorey: cn_storey, conf: any) {}
    // PARAM volume
    private typePrestation: TypePrestation = TypePrestationEnum.POLLUANT_RESULTATS_ANALYSES;

    constructor(private polluantService: PolluantService, private readonly pointDeControleService: PointDeControleService) {}

    getTypePrestation(): TypePrestation {
        return this.typePrestation;
    }

    generateLegendForScreenshot(diagnostic: Diagnostic): LegendScreenshot[] {
        return [];
    }

    getRoutes(): Route[] {
        return polluantEtudeSituationNavbarRoutes;
    }

    getContenuDiagnosticFromParent(diagnosticToUpdate: Diagnostic, diagnosticParent: Diagnostic): ContenuDiagnostic {
        return { ...diagnosticParent.contenuDiagnostic };
    }

    getContenuDiagnostic(typePrestation: TypePrestation): Observable<ContenuDiagnostic> {
        return of(new Polluant());
    }

    getCodeBimEquipementBien(typePrestation: TypePrestation): string[] {
        return [];
    }

    getCompletionPercentage(diagnostic: Diagnostic): number {
        return 0;
    }

    getDiagnosticReportData(intervention: Intervention, diagnostic: Diagnostic, rules?: Rule[], optionPlan?: boolean): IDiagReportData {
        return undefined;
    }

    getReportBlockType(componentName: string): Type<any> {
        return undefined;
    }

    isItemAlreadyFilled(diagnostic: Diagnostic, type: string, itemId: string): boolean {
        return false;
    }

    prepareFilteredCommentsForReport(diagnosticData: IDiagReportData, hiddenComments: Map<string, string[]>) {}

    prepareForm(intervention: Intervention, contenuDiagnostic: ContenuDiagnostic) {}

    prepareSpecificComments(diagnostic: Diagnostic, commentairesGeneraux: Commentaire[], commentaires: CommentairePredefini[]) {}

    getDiagnosticBonCommandeData(intervention: Intervention, diagnostic: Diagnostic): IDiagReportData {
        return undefined;
    }
    generateDiagnosticBonCommande(
        intervention: Intervention,
        diagnostic: Diagnostic,
        interReportData: InterventionReportData
    ): BonCommandeAnalyseAdmin {
        return undefined;
    }
    deplaceVolume(diagnostic: Diagnostic, volumeSource: Volume, niveauDestination: Niveau, currentBien: Bien) {
        this.pointDeControleService.deplaceVolume(diagnostic, volumeSource, niveauDestination, currentBien);
    }

    mergeNiveau(diagnostic: Diagnostic, niveauSource: Niveau, niveauDestination: Niveau, currentBien: Bien) {
        this.pointDeControleService.mergeNiveau(diagnostic, niveauSource, niveauDestination, currentBien);
    }

    mergeVolume(diagnostic: Diagnostic, volumeSource: Volume, volumeDestination: Volume, currentBien: Bien, typeVolume: TypeVolume) {
        this.pointDeControleService.mergeVolume(diagnostic, volumeSource, volumeDestination, currentBien, typeVolume);
    }

    deplaceEquipement(idEquipement: string, diagnostic: Diagnostic, volumeDestination: Volume, bien: Bien) {
        this.pointDeControleService.deplaceEquipement(idEquipement, diagnostic, volumeDestination, bien);
    }
}
