import { Component, Input, OnInit } from '@angular/core';
import { InterventionReportData, IReportComponent, ReportBlockData } from '../../../../../model/rapport.model';
import { DiagnosticService } from 'src/app/services/diagnostic.service';
import { TypePrestation } from 'src/app/model/type-prestation.model';
import { Intervention } from 'src/app/model/intervention.model';
import { BaseComponent } from 'src/app/commons-lib';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-administratifs-bon-commande-block',
    templateUrl: './administratifs-bon-commande-block.component.html',
    styleUrls: ['./administratifs-bon-commande-block.component.scss'],
})
export class AdministratifsBonCommandeBlockComponent extends BaseComponent implements OnInit, IReportComponent {
    @Input() data: ReportBlockData;
    @Input() intervention: Intervention;
    @Input() interReportData: InterventionReportData;

    typePrestation: TypePrestation;

    constructor(private readonly diagnosticService: DiagnosticService) {
        super();
    }

    ngOnInit(): void {
        this.diagnosticService
            .getCurrentDiagnostic()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((diag) => {
                this.typePrestation = diag.typePrestation;
            });
    }
}
