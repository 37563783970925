import { cn_marker } from '@acenv/cnmap-editor';
import { MongoUtils } from 'src/app/commons-lib';

export class CndiagMarkerZone extends cn_marker {
    // Correspond à l'identifiant de l'objet représenté par ce marker (zone, périmètre, prélèvement...)
    idZone: string = MongoUtils.generateObjectId();
    typeZone: string;

    /**
     * En plus du traitement parent, on map le level et l'idReference
     * @param json
     * @param storey
     * @returns
     */
    static unserialize(json, storey): CndiagMarkerZone {
        let marker = new CndiagMarkerZone(storey);

        const markerTemp = cn_marker.unserialize(json, storey);
        marker = Object.assign(marker, markerTemp);

        if (typeof json.idZone === 'string') marker.idZone = json.idZone;
        /*if (typeof json.typeZone === 'string')*/ marker.typeZone = json.typeZone;
        return marker;
    }

    /**
     * En plus du traitement parent, on map le level et l'idReference
     * @returns
     */
    serialize() {
        const markerJson = super.serialize();
        markerJson.idZone = this.idZone;
        markerJson.typeZone = this.typeZone;
        return markerJson;
    }
}
