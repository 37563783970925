<ng-container *ngIf="useDownloadPipe">
    <img
        *ngIf="fileId | fileDownload | async as fileContent"
        [src]="fileContent"
        alt="Image du bien"
        class="img-fluid"
    />
</ng-container>
<ng-container *ngIf="!useDownloadPipe">
    <img *ngIf="dataUrl" [src]="dataUrl" alt="Image du bien" class="img-fluid" />
</ng-container>
