<app-mpca-form-skeleton>
    <div title>Récupération META</div>
    <div form class="form" [formGroup]="formRecuperation">
        <div class="column">
            <div class="section">
                <div class="header">Informations client</div>
                <div class="cndiad-form-field">
                    <mat-label class="required">Fait par le client</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="faitParLeClient"
                        [disabled]="disabled"
                    >
                        <option value="true" datatype="icon">check</option>
                        <option value="false" datatype="icon">close</option>
                    </app-widget-select>
                </div>
            </div>
            <div class="section">
                <div class="header">Informations client</div>
                <mat-form-field appearance="outline">
                    <mat-label>Localisation du prélèvement</mat-label>
                    <input matInput formControlName="localisationPrelevement" [readonly]="disabled" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Lot filtre</mat-label>
                    <input matInput required formControlName="lotFiltre" [readonly]="disabled" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Numéro de pompe</mat-label>
                    <input matInput formControlName="numeroPompe" [readonly]="true" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Numéro de tête</mat-label>
                    <input matInput formControlName="numeroTete" [readonly]="true" />
                </mat-form-field>
                <mat-form-field class="ml-3">
                    <mat-label>Date début: </mat-label>
                    <input
                        matInput
                        readonly
                        formControlName="dateDebutPrelevement"
                        [ngxMatDatetimePicker]="datepickerDebut"
                        [readonly]="true"
                    />
                    <mat-datepicker-toggle matSuffix [for]="datepickerDebut" [disabled]="true"> </mat-datepicker-toggle>
                    <ngx-mat-datetime-picker
                        #datepickerDebut
                        [showSeconds]="false"
                        [disabled]="true"
                    ></ngx-mat-datetime-picker>
                </mat-form-field>
                <mat-form-field class="ml-3">
                    <mat-label>Date fin: </mat-label>
                    <input
                        matInput
                        readonly
                        formControlName="dateFinPrelevement"
                        [ngxMatDatetimePicker]="datepickerFin"
                        [readonly]="true"
                    />
                    <mat-datepicker-toggle matSuffix [for]="datepickerFin" [disabled]="disabled">
                    </mat-datepicker-toggle>
                    <ngx-mat-datetime-picker
                        #datepickerFin
                        [showSeconds]="false"
                        [disabled]="disabled"
                    ></ngx-mat-datetime-picker>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Durée totale du prélèvement:</mat-label>
                    <input matInput [readonly]="true" placeholder="en heures" [value]="calculDuree()" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Débit initial:</mat-label>
                    <input matInput formControlName="debitInitial" [readonly]="disabled" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Débit final:</mat-label>
                    <input matInput formControlName="debitFinal" [readonly]="disabled" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Volume:</mat-label>
                    <input matInput formControlName="volume" [readonly]="disabled" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Remarques: </mat-label>
                    <input matInput formControlName="remarques" [readonly]="disabled" />
                </mat-form-field>
            </div>
        </div>
        <div class="column">
            <div class="section">
                <div class="header">Conformité</div>
                <div class="cndiad-form-field">
                    <mat-label class="required">Prélèvement réglementaire</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="prelevementReglementaire"
                        [disabled]="disabled"
                    >
                        <option value="true" datatype="icon">check</option>
                        <option value="false" datatype="icon">close</option>
                    </app-widget-select>
                </div>
                <mat-form-field appearance="outine">
                    <mat-label>Justificatif non réglementaire</mat-label>
                    <mat-select required formControlName="lotFiltre" [disabled]="disabled">
                        <mat-option value="absenceStrategie">Absence de stratégie</mat-option>
                        <mat-option value="autre">Autre</mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="cndiad-form-field">
                    <mat-label class="required">Accréditation cofrac</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="accreditationCofrac"
                        [disabled]="disabled"
                    >
                        <option value="true" datatype="icon">check</option>
                        <option value="false" datatype="icon">close</option>
                    </app-widget-select>
                </div>
            </div>
        </div>
    </div>
</app-mpca-form-skeleton>
