<h1 mat-dialog-title>Fiche contact existant</h1>
<div mat-dialog-content>
    <div [innerHTML]="data.message"></div>
    <div class="row">
        <div class="col-6">Nom</div>
        <div class="col-6">{{ data.existingContact.nom }}</div>
        <div class="col-6" *ngIf="!data.existingContact.estPersonneMorale">Prénom</div>
        <div class="col-6" *ngIf="!data.existingContact.estPersonneMorale">{{ data.existingContact.prenom }}</div>
        <div class="col-6" *ngIf="data.existingContact.estPersonneMorale">Siret</div>
        <div class="col-6" *ngIf="data.existingContact.estPersonneMorale">{{ data.existingContact.siret }}</div>
        <div class="col-6">Téléphone</div>
        <div class="col-6">{{ data.existingContact.telephone }}</div>
        <div class="col-6">Adresse</div>
        <div class="col-6">
            <span>{{ data.existingContact.adresse | adresse }}</span>
        </div>
        <div class="col-6">Email</div>
        <div class="col-6">{{ data.existingContact.email }}</div>
        <div class="col-6" *ngIf="!data.existingContact.estPersonneMorale && data.existingContact.entrepriseParent">
            Entreprise
        </div>
        <div class="col-6" *ngIf="!data.existingContact.estPersonneMorale && data.existingContact.entrepriseParent">
            {{ data.existingContact.entrepriseParent.nom }}
        </div>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-raised-button [color]="'primary'" cdkFocusInitial (click)="onConfirm()">
        <mat-icon>edit</mat-icon>
        <span>{{ data.confirmLabel }}</span>
    </button>
    <button mat-button (click)="onDuplicate()">
        <mat-icon>content_copy</mat-icon>
        <span>Dupliquer les données</span>
    </button>
    <button mat-button (click)="onDismiss()">
        <mat-icon>close</mat-icon>
        <span>Annuler</span>
    </button>
</div>
