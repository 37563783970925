import { Injectable } from '@angular/core';
import { MongoUtils } from 'src/app/commons-lib';
import { HttpBackend, HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { Cofrac, CofracAdmin } from '../model/cofrac.model';
import { extractPage, PageRequest, toHttpParams } from '../shared/paging/page';
import { Document } from '../model/document.model';
import { FileService } from './file.service';
import { FileData, OfflineStorageService, ResourceWrapper } from '../shared/offline/offline-storage.service';
import { STORAGE_KEY_LOGO_COFRAC } from '../shared/constants/indexeddb.constants';

/**
 * Service d'appel aux APIs pour les cofracs.
 * Ne pas appeler directement depuis les composants des pages, uniquement depuis d'autres services.
 * NB : On utilise HttpBackend au lieu de HttpClient ici, pour ne pas passer par les intercepteurs (donc on bypasse l'intercepteur).
 *      Le service appelant doit donc gérer lui-même les erreurs HTTP.
 */

export class LogoImageFileData implements FileData {
    fileId: string;
    fileContent?: string;
    fileName?: string;
    cofracId: string;
}

@Injectable({
    providedIn: 'root',
})
export class CofracApiService {
    private http: HttpClient;

    public resourceUrl = environment.apiUrl + '/cofrac';

    // Ressource pour le wrapper HL
    private resource: ResourceWrapper<LogoImageFileData, string> = this.offlineStorage.wrapFileResource<LogoImageFileData>({
        cacheName: STORAGE_KEY_LOGO_COFRAC,
        idField: 'fileId',
        resourceUrl: this.resourceUrl,
        paramsForPull: null,
        urlByTemplate: (data: LogoImageFileData) => `${this.resourceUrl}/${data.cofracId}/logo`,
    });

    constructor(
        private readonly httpBackend: HttpBackend,
        private readonly fileService: FileService,
        private readonly offlineStorage: OfflineStorageService
    ) {
        this.http = new HttpClient(httpBackend);
    }

    findOne(id: string): Observable<Cofrac> {
        return this.http.get<Cofrac>(`${this.resourceUrl}/${id}`);
    }

    findAllCofracs(): Observable<Cofrac[]> {
        return this.http.get<Cofrac[]>(`${this.resourceUrl}/find-all-cofracs`);
    }

    searchCofracs(search: string, pageRequest: PageRequest<CofracAdmin>) {
        return extractPage(
            this.http.get<CofracAdmin[]>(`${this.resourceUrl}`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), search },
            })
        );
    }

    /**
     * Créer un cofrac
     * @param cofrac
     */
    createCofrac(cofrac: Cofrac): Observable<Cofrac> {
        return this.http.post<Cofrac>(this.resourceUrl, cofrac);
    }

    /**
     * Mettre à jour un cofrac
     * @param cofrac
     */
    updateCofracOnline(cofrac: Cofrac): Observable<Cofrac> {
        return this.http.put<Cofrac>(this.resourceUrl, cofrac);
    }

    /**
     * Upload le document du cofrac
     * @param documentId
     * @param file
     * @param fileId
     * @param dateUpload
     */
    uploadDocumentCofrac(documentId: string, file: FormData, fileId: string, dateUpload: string, sourceTransmission: string): Observable<Document> {
        let params = new HttpParams();
        params = params.set('fileId', fileId);
        params = params.set('dateUpload', dateUpload);
        params = params.set('idDocument', documentId);
        params = params.set('sourceTransmission', sourceTransmission);
        return this.http.post<any>(`${this.resourceUrl}/uploadDocumentCofrac`, file, {
            params,
        });
    }

    /**
     * Télécharge le document du cofrac
     * @param document
     */
    getDocumentCofracToDowload(document: Document) {
        const fileDownloadTokenUrl = `${this.resourceUrl}/getDocumentCofrac/${document.idFichier}`;
        const fileName = document.nomFichier;
        this.fileService.downloadDocumentFile(fileDownloadTokenUrl, fileName);
    }

    uploadLogo(idCofrac: string, formData: FormData): Observable<HttpEvent<any>> {
        let params = new HttpParams();
        params = params.set('fileId', MongoUtils.generateObjectId());
        return this.http.post<any>(`${this.resourceUrl}/${idCofrac}/logo`, formData, {
            params,
            reportProgress: true,
            observe: 'events',
        });
    }

    /**
     * Télécharge l'image logo associée au cofrac
     * @param cofracId id du cofrac
     * @param logoId id du logo
     */
    downloadImage(cofracId: string, logoId: string): Observable<LogoImageFileData> {
        if (logoId && cofracId) {
            return this.resource.getOneByTemplate({
                fileId: logoId,
                cofracId: cofracId,
            });
        } else {
            return of(null);
        }
    }
}
