<h1 mat-dialog-title class="epi-title">{{title}}</h1>

<div mat-dialog-content>
    <!-- Commentaires prédéfinis-->
    <mat-card-content class="choixTexteHauteurFixed">
        <div class="h-100 scrollable-content" *ngIf="textesPredefinis.length">
            <div class="d-flex flex-column mb-2">
                <mat-card
                    class="px-2 py-2 mb-2 d-flex flex-column justify-content-between text-justify commentaire-predefini"
                    *ngFor="let txt of textesPredefinis"
                    (click)="onClikAddTextesPredefini(txt)"
                >
                    {{ txt }}
                </mat-card>
            </div>
        </div>
    </mat-card-content>
    <mat-card-content class="w-100">
        <!-- Saisi libre d'un commentaire -->
        <div class="input-with-prefix">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>{{tooltip}}</mat-label>
                <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5" [(ngModel)]="texte"></textarea>
            </mat-form-field>
        </div>
    </mat-card-content>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-raised-button (click)="confirm()">
        <span>{{ 'Ajouter' | uppercase }}</span>
    </button>
</div>
