import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-logement-contacts',
    templateUrl: './logement-contacts.component.html',
    styleUrls: ['./logement-contacts.component.scss'],
})
export class LogementContactsComponent implements OnInit {
    readonly MENU_NAME = 'Contacts';

    constructor() {}

    ngOnInit(): void {}
}
