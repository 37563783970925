'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** Object instance
//***********************************************************************************
//***********************************************************************************

import { fh_matrix, fh_scene } from '@acenv/fh-3d-viewer';
import {
    cn_add,
    cn_box,
    cn_cart,
    cn_clone,
    cn_dist,
    cn_dot,
    cn_mul,
    cn_normal,
    cn_polar,
    cn_sub,
    cnx_add,
    cnx_clone,
    cnx_cross,
    cnx_mul,
    cnx_normalize
} from '../utils/cn_utilities';
import { cn_element_visitor } from '../utils/visitors/cn_element_visitor';
import { cn_camera } from '../svg/cn_camera';
import { cn_3d_building } from './cn_3d_building';
import { cn_roof } from './cn_roof';
import { cn_storey } from './cn_storey';
import { cn_element } from './cn_element';

// @ts-ignore
export class cn_object_instance extends cn_element {
    constructor(scene) {
        super(scene);
        this.scene = scene;

        //*** Model data
        this.object = null;
        this.position = [0, 0];
        this.orientation = 0;
        this.flipped = false;
        this.space = null;
        this.height = 0;

        //*** virtual : if true, the instance is attached to a space but does not have a geometry */
        this.virtual = false;

        //*** Attachment data, for wall contact */
        this._contact_wall = null;
        this._contact_wall_side = 0;
        this._contact_wall_position = 0;

    }

    //***********************************************************************************
    //**** serialize
    //***********************************************************************************
    serialize() {
        var json = {};
        // @ts-ignore
        json.ID = this.ID;
        json.object = this.object.ID;
        json.position = cn_clone(this.position);
        json.orientation = this.orientation;
        json.flipped = this.flipped;
        if (this.space && !this.is_roof())
            json.space = this.space.ID;
        json.parameters = this.parameters;
        json.height = this.height;
        json.virtual = this.virtual;
        return json;
    }

    static unserialize(json, scene) {
        if (typeof (json.ID) != 'string') return false;
        if (typeof (json.object) != 'string') return false;
        if (typeof (json.position) != 'object') return false;
        if (typeof (json.orientation) != 'number') return false;

        var instance = new cn_object_instance(scene);
        // @ts-ignore
        instance.ID = json.ID;
        instance.object = scene.building.get_object(json.object);
        if (instance.object == null) {
            console.error('Error reading object instance : source object not found');
            return null;
        }
        instance.position = cn_clone(json.position);
        instance.orientation = json.orientation;

        if (typeof (json.flipped) == 'boolean')
            instance.flipped = json.flipped;

        if (typeof (json.parameters) == 'object')
            instance.parameters = json.parameters;

        if (!instance.is_roof() && typeof (json.space) == 'string')
            instance.space = scene.get_space(json.space);

        if (typeof (json.height) == 'number')
            instance.height = json.height;

        if (typeof (json.virtual) == 'boolean')
            instance.virtual = json.virtual;

        scene.object_instances.push(instance);
        return instance;
    }

    //***********************************************************************************
    //**** Draw
    //***********************************************************************************
    // @ts-ignore
    draw(camera, add_classes = [], fill_color = '', url_to_b64 = null) {
        let html = '';
        if (this.object == null) return html;
        if (this.virtual) return html;

        if (camera.is_3d()) return this.draw_3d(camera, add_classes);

        const mouseover = (add_classes.indexOf('mouseover') >= 0);
        const selected = (add_classes.indexOf('selected') >= 0);
        // @ts-ignore
        const negativeStatus = this.status < 0;

        if (negativeStatus) {
            html += '<g opacity=\'0.3\'>';
        }

        const p = camera.world_to_screen(this.position);
        const flipped = this.flipped ? '' : ' scale(1,-1) ';
        const objectSize = [this.object.size[0] * camera.world_to_screen_scale, this.object.size[1] * camera.world_to_screen_scale];
        const iconSize = [40, 40];
        const objectGroupMarkup = `<g transform='translate(${p[0]},${p[1]}) rotate(${-this.orientation}) ${flipped} translate(${-0.5 * objectSize[0]},${-0.5 * objectSize[1]})'>`;
        const iconGroupMarkup = `<g transform='translate(${p[0]},${p[1]}) translate(${-0.5 * objectSize[0]},${-0.5 * objectSize[1]})'>`;

        // Draw top view
        if (camera.draw_objects_top_view || !this.object.get_object_icon_id()) {
            html += objectGroupMarkup;
            const source_type = this.object.get_source_type();
            const top_view_data = this.object.get_top_view_id();
            if (top_view_data) {
                let url = cn_object_instance.image_id_to_url(top_view_data);
                if (url_to_b64 && typeof (url_to_b64[url]) == 'string') url = url_to_b64[url];
                if (url !== '') {
                    html += `<image class="object_instance_shape" xlink:href="${url}" width='${objectSize[0]}' height='${objectSize[1]}' preserveAspectRatio='none' />`;
                }
            } else if (source_type === 'circle') {
                html += `<ellipse cx="${objectSize[0] / 2}" cy="${objectSize[1] / 2}" rx="${objectSize[0] / 2}" ry="${objectSize[1] / 2}" `;
            } else {
                html += `<rect x="0" y="0" width="${objectSize[0]}" height="${objectSize[1]}" `;
            }

            if (fill_color) {
                const style_directive = `${fill_color.slice(0, -1)};stroke:black;stroke-width:1px;"`;
                html += ` ${style_directive} `;
            // @ts-ignore
            } else if (add_classes.includes('exp') && add_classes.includes(this.ID)) {
                html += ` class="obj_exp_container ${add_classes.join(' ')}" `
            } else {
                html += `class="object_instance_shape" style="stroke:black; stroke-width: 1px; fill:${this.object.get_color()}" `;
            }
            if (mouseover)
                html += 'filter=\'url(#mouseover_shadow)\'';
            else if (selected)
                html += 'filter=\'url(#selection_shadow)\'';
            html += '/>';
            html += '</g>';
        }

        // Draw icon
        if (camera.draw_objects_icon && this.object.get_object_icon_id()) {
            const shiftX = (objectSize[0] - iconSize[0]) / 2;
            const shiftY = (objectSize[1] - iconSize[1]) / 2;
            let url = cn_object_instance.image_id_to_url(this.object.get_object_icon_id());
            if (url_to_b64 && typeof (url_to_b64[url]) == 'string') url = url_to_b64[url];
            if (url !== '') {
                html += iconGroupMarkup;
                if (fill_color) {
                    const style_directive = `${fill_color.slice(0, -1)};mix-blend-mode:multiply;"`;
                    html += `<rect ${style_directive} x="${shiftX}" y="${shiftY}" width="${iconSize[0]}" height="${iconSize[1]}" />`;
                // @ts-ignore
                } else if (add_classes.includes('exp') && add_classes.includes(this.ID)) {
                    html += `<rect class="${add_classes.join(' ')}" x="${shiftX}" y="${shiftY}" width="${iconSize[0]}" height="${iconSize[1]}" />`;
                }
                html += `<image xlink:href="${url}" x="${shiftX}" y="${shiftY}" width="${iconSize[0]}" height="${iconSize[1]}" preserveAspectRatio='xMidYMid' `;
                if (mouseover)
                    html += 'filter=\'url(#mouseover_shadow)\'';
                else if (selected)
                    html += 'filter=\'url(#selection_shadow)\'';
                html += '/>';
                html += '</g>';
            }
        }

        // Draw contour
        if (mouseover || selected) {
            html += objectGroupMarkup;
            html += `<rect class='object_contour ${add_classes.join(' ')}' x='0' y='0' width='${objectSize[0]}' height='${objectSize[1]}' />`;
            html += '</g>';
        }

        if (negativeStatus) {
            html += '</g>';
        }

        return html;
    }

    //***********************************************************************************
    //**** Draw contour
    //***********************************************************************************
    draw_contour(camera, add_classes) {
        var html = '';
        if (this.object == null) return html;

        var p = camera.world_to_screen(this.position);
        var sz = [this.object.size[0] * camera.world_to_screen_scale, this.object.size[1] * camera.world_to_screen_scale];

        html += '<g transform=\'translate(' + p[0] + ',' + p[1] + ') rotate(' + (-this.orientation) + ') translate(' + (-0.5 * sz[0]) + ',' + (-0.5 * sz[1]) + ') \'>';
        html += '<rect class=\'object_contour + ' + add_classes.join(' ') + '\' x=\'0\' y=\'0\' width=\'' + sz[0] + '\' height=\'' + sz[1] + '\' />';
        html += '</g>';
        return html;
    }

    /**
     * Draw in 3D
     * @param {cn_camera} camera
     * @param {Array<string>} add_classes
     * @returns {string}
     */
    draw_3d(camera, add_classes) {
        var html = '';
        var z = this.get_altitude(camera.storey);

        const path = [];
        if (this.is_roof()) {
            // @ts-ignore
            const matrix = this.build_3d_matrix(this.parent.storey.altitude, this.parent.storey);
            path.push(matrix.transform_point([-0.5, -0.5, 0]));
            path.push(matrix.transform_point([0.5, -0.5, 0]));
            path.push(matrix.transform_point([0.5, 0.5, 0]));
            path.push(matrix.transform_point([-0.5, 0.5, 0]));
        } else if (this.object.get_contact() == 'wall') {
            const p0 = this.local_to_global([1, 0]);
            const p1 = this.local_to_global([1, 1]);
            path.push(cnx_clone(p0, z));
            path.push(cnx_clone(p1, z));
            path.push(cnx_clone(p1, z + this.object.size[2]));
            path.push(cnx_clone(p0, z + this.object.size[2]));
        } else {
            path.push(cnx_clone(this.local_to_global([0, 0]), z));
            path.push(cnx_clone(this.local_to_global([1, 0]), z));
            path.push(cnx_clone(this.local_to_global([1, 1]), z));
            path.push(cnx_clone(this.local_to_global([0, 1]), z));
        }

        html += `<path class="object_contour ${add_classes.join(' ')}" d="M `;
        var index = 0;
        path.forEach(p => {
            const sp = camera.world_to_screen(p);
            if (index == 1) html += 'L ';
            index++;
            html += `${sp[0]} ${sp[1]} `;
        });
        html += ` Z" />`;
        return html;
    }

    /**
     * Returns the altitude
     * @param {cn_storey} storey
     * @returns {number}
     */
    get_altitude(storey) {
        if (!storey) return 0;

        var z = 0;
        if (this.is_roof()) {
            z += storey.roof_altitude;
            const slab = this.space;
            z += slab.slab_type.thickness / slab.normal[2];
            var delta = slab.compute_height(this.position);
            if (this.object && this.object.get_contact() == 'floor') {
                for (var n = 0; n < 4; n++) {
                    const pos = [0, 0];
                    if (n & 1) pos[0]++;
                    if (n & 2) pos[1]++;
                    const z = slab.compute_height(this.local_to_global(pos));
                    if (z < delta) delta = z;
                }
            }
            z += delta + this.height;
            return z;
        }

        z = storey.altitude + storey.compute_z_floor(this.position);
        if (this.object.get_contact() == 'ceiling')
            z += storey.compute_height(this.position) - this.height;
        else
            z += this.height;

        return z;
    }

    //***********************************************************************************
    //**** Contains
    //***********************************************************************************
    contains(point, tolerance = 0) {
        if (this.virtual) return false;
        var d = cn_sub(point, this.position);
        var sz0 = this.object.size[0] + 2 * tolerance;
        var sz1 = this.object.size[1] + 2 * tolerance;
        if (cn_dot(d, d) > sz0 * sz0 + sz1 * sz1) return false;
        var dx = [Math.cos(this.orientation * Math.PI / 180), Math.sin(this.orientation * Math.PI / 180)];
        if (Math.abs(cn_dot(d, dx)) > sz0 * 0.5) return false;
        var dy = cn_normal(dx);
        if (Math.abs(cn_dot(d, dy)) > sz1 * 0.5) return false;
        return true;
    }

    //***********************************************************************************
    /**
     * Returns conversion of local point (in range[0,1] if point on object) to global position.
     * @param {number[]} point
     * @returns {number[]}
     */
    local_to_global(point) {
        var dx = [Math.cos(this.orientation * Math.PI / 180), Math.sin(this.orientation * Math.PI / 180)];
        var dy = cn_normal(dx);
        return cn_add(this.position, cn_add(cn_mul(dx, (point[0] - 0.5) * this.object.size[0]), cn_mul(dy, (point[1] - 0.5) * this.object.size[1])))
    }

    //***********************************************************************************
    /**
     * Returns conversion of global point to local position  (in range[0,1] if point on object)
     * @param {number[]} point
     * @returns {number[]}
     */
    global_to_local(point) {
        var d = cn_sub(point, this.position);
        var dx = [Math.cos(this.orientation * Math.PI / 180), Math.sin(this.orientation * Math.PI / 180)];
        var dy = cn_normal(dx);
        return [0.5 + cn_dot(d, dx) / this.object.size[0], 0.5 + cn_dot(d, dy) / this.object.size[1]];
    }

    //***********************************************************************************
    /**
     * Builds 3D matrix
     * @param {number} h0 : actual height at level 0
     * @param {cn_storey} storey : current storey to compute
     */
    build_3d_matrix(h0, storey) {
        var matrix = new fh_matrix();
        var pos = cn_clone(this.position);
        pos.push(h0 + this.get_altitude(storey) - storey.altitude);
        matrix.translate(pos);

        if (this.is_roof() && this.object.roof_parallel) {
            const slab = this.space;
            if (slab.normal[2] < 0.99) {
                const dx = cnx_cross(slab.normal, [0, 0, 1]);
                cnx_normalize(dx);
                const dy = cnx_cross(slab.normal, dx);
                const mtx = new fh_matrix();
                const orientation = this.orientation * Math.PI / 180 - cn_polar(dx)[1];
                const cx = Math.cos(orientation);
                const sx = Math.sin(orientation);
                const dx2 = cnx_add(cnx_mul(dx, cx), cnx_mul(dy, sx));
                const dy2 = cnx_cross(slab.normal, dx2);
                mtx.load_axis([0, 0, 0], dx2, dy2, slab.normal);
                matrix.multiplies(mtx);
            }
        } else
            matrix.rotate(2, this.orientation * Math.PI / 180);
        if (this.flipped) {
            var mat = new fh_matrix();
            mat.values[5] = -1;
            matrix.multiplies(mat);
        }
        return matrix;
    }

    //***********************************************************************************
    /**
     * Performs a rotation operation
     * @param {number[]} center : center of ritation
     * @param {number} angle : rotation angle, in radians
     * @param {function} rotation_function : fnction that transforms a 2D point
     */
    // @ts-ignore
    perform_rotation(center, angle, rotation_function) {
        rotation_function(this.position);
        this.orientation += angle * 180 / Math.PI;
    }

    //***********************************************************************************
    /**
     * Vertex operation : transform all vertices
     * @param {function} operation : vertex operator
     */
    vertex_operation(operation) {
        operation(this.position);
    }

    //***********************************************************************************
    /**
     * flip operation : transform all vertices
     * @param {number[]} center : center of flip
     * @param {boolean} horizontal : true for horizontal flip, vertical otherwise
     * @param {function} operation : vertex operator
     */
    // @ts-ignore
    perform_flip(center, horizontal, operation) {
        operation(this.position);
        this.flipped = !this.flipped;
        if (horizontal)
            this.orientation = 180 - this.orientation;
        else
            this.orientation = -this.orientation;
    }

    //***********************************************************************************
    /**
     * Returns bounding box
     * @returns {cn_box}
     */
    get_bounding_box() {
        var box = new cn_box();
        if (this.virtual) return box;
        var dx = [Math.cos(this.orientation * Math.PI / 180), Math.sin(this.orientation * Math.PI / 180)];
        var dy = cn_normal(dx);
        dx = cn_mul(dx, 0.5 * this.object.size[0]);
        dy = cn_mul(dy, 0.5 * this.object.size[1]);
        var p = cn_sub(this.position, cn_add(dx, dy));
        box.enlarge_point(p);
        var p = cn_sub(this.position, cn_sub(dx, dy));
        box.enlarge_point(p);
        var p2 = cn_add(this.position, cn_add(dx, dy));
        box.enlarge_point(p2);
        var p2 = cn_add(this.position, cn_sub(dx, dy));
        box.enlarge_point(p2);
        return box;
    }

    //***********************************************************************************
    /**
     * Returns screen bounding box
     * @param {cn_camera} camera
     * @returns {cn_box}
     */
    get_screen_bounding_box(camera) {
        if (this.virtual || !camera.is_3d()) return super.get_screen_bounding_box(camera);

        var box = new cn_box();
        var dx = [Math.cos(this.orientation * Math.PI / 180), Math.sin(this.orientation * Math.PI / 180)];
        var dy = cn_normal(dx);
        dx = cnx_mul(dx, 0.5 * this.object.size[0]);
        dy = cnx_mul(dy, 0.5 * this.object.size[1]);
        const dz = [0, 0, this.object.size[2]];
        var pos = cnx_clone(this.position);
        pos[2] += this.get_altitude(camera.storey);
        if (this.object.get_contact() == 'ceiling') pos[2] -= this.object.size[2];
        for (var niter = 0; niter < 8; niter++) {
            var p = cnx_add(pos, cn_mul(dx, (niter & 1) ? 1 : -1));
            p = cnx_add(p, cn_mul(dy, (niter & 2) ? 1 : -1));
            if (niter & 4) p = cnx_add(p, dz);
            box.enlarge_point(camera.world_to_screen(p));
        }
        return box;
    }

    /**
     * @param {string} image_id
     * @return string
     */
    // @ts-ignore
    static image_id_to_url(image_id) {
        return undefined;
    }

    /**
     * Accept element visitor
     *
     * @param {cn_element_visitor} element_visitor
     */
    accept_visitor(element_visitor) {
        element_visitor.visit_object(this);
    }

    //***********************************************************************************
    /**
     * Sets parameters
     * @param {object} parameters
     */
    set_parameters(parameters) {
        this.parameters = parameters;
    }

    //***********************************************************************************
    /**
     * Returns parameters, or empty object if not defined.
     * @returns {object}
     */
    get_parameters() {
        if (typeof (this.parameters) == 'object')
            return this.parameters;
        return {};
    }

    is_roof() {
        // @ts-ignore
        return (this.parent && this.parent.constructor == cn_roof);
    }

    /**
     * Checks if the object is still placed against a wall.
     * Np effect if placement is not wall.
     * Will move to the closest wall .
     * Returns 'true' if current placement is OK.
     * @returns {boolean}
     */
    update_deep() {
        if (this.virtual) return true;

        // @ts-ignore
        this.space = (this.is_roof()) ? this.parent.find_slab(this.position) : this.parent.find_space(this.position);

        if (this.is_roof() || this.object.get_contact() != 'wall') return true;

        const object = this.object;

        //*** Function to turn a wall impact into an anchorage */
        function _place_on_wall_impact(impact) {
            const y = (impact.wall_side == 0) ? impact.wall.bounds.y0 - 0.5 * object.size[0] : impact.wall.bounds.y1 + 0.5 * object.size[0];
            const new_position = cn_add(cn_add(impact.wall.vertex_position(0), cn_mul(impact.wall.bounds.direction, impact.wall_position)), cn_mul(impact.wall.bounds.normal, y));
            var new_orientation = cn_polar(impact.wall.bounds.normal)[1] * 180 / Math.PI;
            if (impact.wall_side == 1) new_orientation -= 180;
            new_position.push(new_orientation);
            return new_position;
        }

        //*** check contact wall */
        // @ts-ignore
        if (this._contact_wall && this.parent.walls.indexOf(this._contact_wall) >= 0) {
            //logger.log("checking contact wall");
            var direction = cn_cart([1, this.orientation * Math.PI / 180]);
            var impact = this._contact_wall.raytrace(this.position, direction, 0.5 * object.size[0] + this._contact_wall.wall_type.thickness + 0.1);
            var new_position = null;
            if (impact) {
                //logger.log("impact with contact wall");
                new_position = _place_on_wall_impact(impact);

                //*** check that anchorage is the same */
                if (cn_dist(this.position, new_position) < 0.001 && cn_dist(cn_cart([1, this.orientation]), cn_cart([1, new_position[2]])) < 0.001) {
                    //logger.log("Contact wall OK");
                    return true;
                }
            } else if (this._contact_wall_position < this._contact_wall.bounds.length) {
                impact = {
                    wall: this._contact_wall,
                    wall_position: this._contact_wall_position,
                    wall_side: this._contact_wall_side
                };
                new_position = _place_on_wall_impact(impact);
            }

            if (impact) {
                //*** The wall works, but not at the same position */
                //logger.log("same wall, different position");
                this._contact_wall = impact.wall;
                this._contact_wall_side = impact.wall_side;
                this._contact_wall_position = impact.wall_position;
                this.position = cn_clone(new_position);
                this.orientation = new_position[2];
                return false;
            }
        }

        this._contact_wall = null;

        //*** impact is no more valid. We look around */
        var best_distance = 100;
        var best_impact = null;
        for (var theta = 0; theta < 360; theta += 15) {
            var direction = cn_cart([1, (this.orientation + theta) * Math.PI / 180]);
            // @ts-ignore
            var impact = this.parent.raytrace(this.position, direction, best_distance);
            if (!impact) continue;
            best_distance = impact.distance;
            best_impact = impact;
        }
        if (best_impact == null) return false;

        new_position = _place_on_wall_impact(best_impact);
        this._contact_wall = best_impact.wall;
        this._contact_wall_side = best_impact.wall_side;
        this._contact_wall_position = best_impact.wall_position;
        this.position = cn_clone(new_position);
        this.orientation = new_position[2];
        //logger.log("replacing object");
        return false;
    }

    /**
     * Update 3D data, if relevant
     * @param {cn_3d_building} building_3d
     * @param {cn_storey} storey
     */
    update_3d(building_3d, storey) {
        if (!this.object) return;

        // @ts-ignore
        const objects3d = building_3d.get_3d_objects(this).filter(ob => ob.cnmap_storey == storey);
        objects3d.forEach(ob => {
            var matrix = this.build_3d_matrix(storey.altitude, storey);
            if (this.object) matrix.multiplies(this.object.get_matrix(this.is_roof()));

            fh_scene.update_object_matrix(ob, matrix.values);
        });
    }

    /**
     * Returns true if matrix hasn't changed since date.
     * @param {number} date
     * @returns {boolean}
     */
    up_to_date_matrix(date) {
        // @ts-ignore
        if (!this.up_to_date(date, 'position')) return false;
        // @ts-ignore
        if (!this.up_to_date(date, 'orientation')) return false;
        // @ts-ignore
        if (!this.up_to_date(date, 'height')) return false;
        return true;
    }
}

