<div class="d-flex flex-column flex-grow-1 bg-white">
    <mat-button-toggle-group
        appearance="standard"
        class="border-0"
        [vertical]="true"
        (change)="changeViewMode($event)"
        [value]="viewMode"
    >
        <mat-button-toggle matTooltip="Vue liste" matTooltipPosition="right" [value]="VIEWMODE.LIST">
            <mat-icon>list</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle matTooltip="Vue plan" matTooltipPosition="right" [value]="VIEWMODE.PLAN">
            <mat-icon>layers</mat-icon>
        </mat-button-toggle>
    </mat-button-toggle-group>
    <mat-divider></mat-divider>
    <div *ngIf="viewMode === VIEWMODE.LIST" class="d-flex flex-column control-group"></div>
    <div *ngIf="viewMode === VIEWMODE.PLAN" class="d-flex flex-column control-group"></div>
</div>
