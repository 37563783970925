import { AfterViewInit, Component, Input } from '@angular/core';
import {
    comparePointControle,
    nonOption,
    nonVerifiabledOption,
    ouiOption,
    PARAM_TOOLTIP_NON_VERIFIABLE,
    PARAM_TOOLTIP_SANS_OBJET,
    sansObjetOption,
} from '../../../shared/electricite.constants';
import { combineLatest } from 'rxjs';
import { GuideDeReferenceComponent } from '../../guide-de-reference/guide-de-reference.component';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogUtils } from '../../../../../../utils/dialog.utils';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { first } from 'rxjs/operators';
import { ElectriciteService } from '../../../services/electricite.service';
import { Bouton, Chapitre, Photo, PointControle, PointControleAide, PointControleReponse, ValeurCheckpoint } from '../../../model/electricite.model';
import { BaseComponent, DateUtils, Icon, MongoUtils } from 'src/app/commons-lib';
import { PARAM_TOOLTIP_NON, PARAM_TOOLTIP_OUI } from '../../../../../../shared/constants/cndiag.constants';
import { CheckpointHelpModalComponent } from '../../../../../shared/checkpoint-help-modal/checkpoint-help-modal.component';
import { CommentModalComponent } from '../../../../../shared/comment-modal/comment-modal.component';
import { StateChoiceBoxes } from '../../../../../../model/states.model';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TypeCommentaire, enumTypesCommentaire } from '../../../../../../model/type-commentaire.model';
import { Commentaire } from 'src/app/model/commentaire.model';
import { InterventionService } from 'src/app/services/intervention.service';
import { Intervention } from 'src/app/model/intervention.model';
import { CustomSelectGroup, ItemCustomSelect } from 'src/app/modules/shared/custom-select/custom-select.model';

@Component({
    selector: 'app-checkpoint-base',
    templateUrl: './checkpoint-base.component.html',
    styleUrls: ['./checkpoint-base.component.scss'],
})
export class CheckpointBaseComponent extends BaseComponent implements AfterViewInit {
    readonly OUI = ouiOption.value;
    readonly NON = nonOption.value;
    readonly NON_VERIFIABLE = nonVerifiabledOption.value;
    readonly SANS_OBJET = sansObjetOption.value;

    readonly COMMENT_TYPES: TypeCommentaire[] = [
        enumTypesCommentaire.JUSTIFICATION_NON_CONFORMITE,
        enumTypesCommentaire.MOTIF_NON_VERIFIABLE,
        enumTypesCommentaire.NOTE_PERSONNELLE,
        enumTypesCommentaire.REMARQUE,
    ];

    // readonly COMMENT_TYPES_NON_VERIFIABLE: TypeCommentaire[] = [...this.COMMENT_TYPES];

    isReload: boolean;
    headerChoices: StateChoiceBoxes[] = [];
    aJustifier: string[] = []; // liste des idInterne
    diagnostic: Diagnostic;
    readonlyMode = false;
    choicesCheckPoint = [ouiOption, nonOption, nonVerifiabledOption, sansObjetOption];
    tooltipsEtatRaccord = [PARAM_TOOLTIP_OUI, PARAM_TOOLTIP_NON, PARAM_TOOLTIP_NON_VERIFIABLE, PARAM_TOOLTIP_SANS_OBJET];

    public checkpoints: { chapitre: Chapitre; pointsControles: PointControle[] }[] = [];
    protected _pointsControlesAides: PointControleAide[];
    protected _boutons: Bouton[];
    protected _photos: Photo[];
    protected intervention: Intervention;

    @Input() set menuName(menuName: string) {
        combineLatest([
            this.electriciteService.electriciteConfig$,
            this.diagnosticService.getCurrentDiagnostic(),
            this.interventionService.getCurrentIntervention(),
        ])
            .pipe(first())
            .subscribe(([config, diagnostic, intervention]) => {
                this.readonlyMode = this.diagnosticService.isReadOnlyMode(intervention, diagnostic);
                this.intervention = intervention;
                this.diagnostic = diagnostic;
                this._boutons = config.boutons;
                this._photos = config.photos;
                const menu = config.chapitres.find((ch) => ch.description == menuName);
                this._pointsControlesAides = config.pointsControlesAides;
                for (const chapitre of config.chapitres.filter((ch) => ch.idChapitreParent == menu.idInterne)) {
                    const pointsControles: PointControle[] = config.pointsControles
                        .filter((pc) => pc.idChapitre == chapitre.idInterne)
                        .sort(comparePointControle);
                    this.checkpoints.push({ chapitre: chapitre, pointsControles: pointsControles });
                }
                const idChapitreParent = menu.idInterne;
                this._boutons = config.boutons.filter((btn) => btn.idChapitreParent == idChapitreParent).sort((b1, b2) => b1.ordre - b2.ordre);
                for (const bouton of this._boutons) {
                    const stateBoxe = new StateChoiceBoxes(bouton.description, bouton.idInterne, new Icon('check_box_outline_blank'));
                    this.headerChoices.push(stateBoxe);
                }
                this.initPointControleisGrey();
                this.updateAJustifier();
            });
    }

    constructor(
        protected diagnosticService: DiagnosticService,
        protected electriciteService: ElectriciteService,
        protected activatedRoute: ActivatedRoute,
        protected readonly matDialog: MatDialog,
        private _snackBar?: MatSnackBar,
        protected interventionService?: InterventionService
    ) {
        super();
    }

    /**
     * Points de contrôle grisés
     */
    private initPointControleisGrey() {
        for (const chap of this.checkpoints) {
            const pointsControlesGrey = chap.pointsControles.filter((ptc) => ptc.isGrey);
            for (const ptc of pointsControlesGrey) {
                if (!this.diagnostic.pointsDeControleBiens[0].valeursParametres[ptc.idInterne]) {
                    const valeurCheck = new ValeurCheckpoint();
                    valeurCheck.isGrey = true;
                    valeurCheck.greyExplication = ptc.greyExplication;
                    valeurCheck.greyLienPointControleExplication = ptc.greyLienPointControleExplication;
                    this.diagnostic.pointsDeControleBiens[0].valeursParametres[ptc.idInterne] = valeurCheck;
                }
            }
        }
    }

    /**
     * Event au clic sur un chapitre
     * @param {PointControle[]} pointsControles
     * @param value
     */
    onChangeAll(pointsControles: PointControle[], value) {
        for (const checkpoint of pointsControles) {
            if (this.diagnostic.pointsDeControleBiens[0].valeursParametres[checkpoint.idInterne]) {
                if (this.diagnostic.pointsDeControleBiens[0].valeursParametres[checkpoint.idInterne].isGrey) {
                    continue;
                }
            }
            this.onValeurChange(value, checkpoint);
        }
    }

    /**
     * Event au clic sur un point de contrôle
     * @param {string} value
     * @param {string} idInterne
     */
    onValeurChange(value: string, pointControle: PointControle) {
        if (!(value && value.length)) {
            this.setAutoReponses(this.diagnostic.pointsDeControleBiens[0].valeursParametres[pointControle.idInterne].valeur, pointControle, true);
            this.diagnostic.pointsDeControleBiens[0].valeursParametres[pointControle.idInterne].valeur = null;
            return;
        }
        if (!this.diagnostic.pointsDeControleBiens[0].valeursParametres[pointControle.idInterne]) {
            this.diagnostic.pointsDeControleBiens[0].valeursParametres[pointControle.idInterne] = new ValeurCheckpoint();
        }
        let valeur = null;
        if (value && value.length > 0) {
            const repPossible = pointControle.lstReponsesImpossibles
                ? !pointControle.lstReponsesImpossibles.some((rep) => this.getOption(rep) == value)
                : true;
            if (repPossible) {
                valeur = value;
            } else {
                this.isReload = true;
                setTimeout(() => (this.isReload = false));
                this._snackBar.open('Cette réponse est indisponible pour ce point de contrôle', '', { duration: 5000 });
            }
        }
        this.diagnostic.pointsDeControleBiens[0].valeursParametres[pointControle.idInterne].valeur = valeur;
        this.setAutoReponses(valeur, pointControle);
        this.updateAJustifier();
        this.addCommAuto(pointControle);
    }

    /**
     * Insère automatiquement un commentaire sur un ptc donné en cas d'anomalie
     * @param value
     * @param pointControle
     */
    private addCommAuto(pointControle: PointControle) {
        if (pointControle.idInterne === 'PC80') {
            const commentaire = 'Il est recommandé de se rapprocher du gestionnaire du réseau public de distribution.'; //PC80
            const item: ValeurCheckpoint = this.diagnostic.pointsDeControleBiens[0].valeursParametres[pointControle.idInterne];

            if (item && (item.valeur == this.NON_VERIFIABLE || item.valeur == this.NON)) {
                const commId = this.addCommentaire(commentaire, item.commentairesId || []);
                if (commId) {
                    item.commentairesId = [...(item.commentairesId || []), commId];
                }
            } else {
                const commId = this.commentaireExist(commentaire, item.commentairesId);
                if (commId) {
                    item.commentairesId = item.commentairesId.filter((id) => id !== commId);
                    this.intervention.commentaires = this.intervention.commentaires.filter((com) => com.id !== commId);
                    this.interventionService.updateIntervention(this.intervention);
                }
            }
        }
    }
    /**
     * Ajout un commentaire sur le point de contrôle avec l'explication initiale
     * @param {string} expl
     * @param {string[]} commentairesId
     * @returns {string}
     */
    private addCommentaire(expl: string, commentairesId: string[]): string {
        // 1\ Vérification si non existant
        if (this.commentaireExist(expl, commentairesId)) {
            return null;
        }

        // 2\ Création du commentaire
        const nouveauCommentaire = new Commentaire();
        nouveauCommentaire.date = DateUtils.localDateTimeString();
        nouveauCommentaire.id = MongoUtils.generateObjectId();
        nouveauCommentaire.type = enumTypesCommentaire.REMARQUE;
        nouveauCommentaire.contenu = expl;
        this.intervention.commentaires = this.intervention.commentaires.concat(nouveauCommentaire);
        this.interventionService.updateIntervention(this.intervention);
        return nouveauCommentaire.id;
    }

    /**
     * Retourne le commentaireId s'il existe sinon null
     * @param expl
     * @param commentairesId
     * @returns
     */
    private commentaireExist(expl: string, commentairesId: string[]): string {
        for (const id of commentairesId) {
            const comm: Commentaire = this.intervention.commentaires.find((com) => com.id == id);
            if (comm && comm.contenu == expl) {
                return id;
            }
        }
        return null;
    }
    /**
     * Initialisation des points de contôles en fonction d'une réponse à un point de contrôle
     * @param {string} value
     */
    private setAutoReponses(value: string, pointControle: PointControle, isClear?: boolean) {
        if (!value) return;
        const index: number = [this.OUI, this.NON, this.NON_VERIFIABLE, this.SANS_OBJET].indexOf(value);
        const properties = [
            'lstPointControleReponseOui',
            'lstPointControleReponseNon',
            'lstPointControleReponseNonVerifiable',
            'lstPointControleReponseSansObjet',
        ];
        const lstPointsControleReponse: PointControleReponse[] = pointControle[properties[index]];

        if (isClear) {
            for (const pcr of lstPointsControleReponse) {
                this.diagnostic.pointsDeControleBiens[0].valeursParametres[pcr.idPointControle] = null;
            }
            return;
        }
        for (const pcr of lstPointsControleReponse) {
            if (!this.diagnostic.pointsDeControleBiens[0].valeursParametres[pcr.idPointControle]) {
                this.diagnostic.pointsDeControleBiens[0].valeursParametres[pcr.idPointControle] = new ValeurCheckpoint();
            }
            if (this.getOption(pcr.reponse)) {
                this.diagnostic.pointsDeControleBiens[0].valeursParametres[pcr.idPointControle].valeur = this.getOption(pcr.reponse);
            }

            this.diagnostic.pointsDeControleBiens[0].valeursParametres[pcr.idPointControle].isGrey = pcr.isGrey;
            this.diagnostic.pointsDeControleBiens[0].valeursParametres[pcr.idPointControle].greyLienPointControleExplication =
                pcr.greyLienPointControleExplication;
            this.diagnostic.pointsDeControleBiens[0].valeursParametres[pcr.idPointControle].greyExplication = pcr.greyInitialisationExplication;
        }
    }

    protected updateAJustifier() {
        this.aJustifier = [];
        for (const checkpoint of this.checkpoints) {
            if (checkpoint.pointsControles) {
                for (const cp of checkpoint.pointsControles) {
                    const item: ValeurCheckpoint = this.diagnostic.pointsDeControleBiens[0].valeursParametres[cp.idInterne];
                    if (item && (item.valeur == this.NON_VERIFIABLE || item.valeur == this.NON) && !item.commentairesId.length && !item.isGrey) {
                        this.aJustifier.push(cp.idInterne);
                    }
                }
            }
        }
    }

    getTypeCommentaire(value: string): string {
        if (value == 'NonVisible') {
            return enumTypesCommentaire.MOTIF_NON_VERIFIABLE;
        }
        if (value == 'Non') {
            return enumTypesCommentaire.JUSTIFICATION_NON_CONFORMITE;
        }
        return '';
    }

    addJustificationForAll() {
        this.matDialog
            .open(CommentModalComponent, {
                ...DialogUtils.configFullScreen(),
                data: {
                    replaceTypesCommentaires: true,
                    typesCommentaires: this.COMMENT_TYPES,
                    typeNouveauCommentaire: this.COMMENT_TYPES[0],
                    localisation: this.getItemsLocalisationCommentaire(),
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result && result !== false) {
                    this.aJustifier.forEach((idInternePC) => {
                        this.diagnostic.pointsDeControleBiens[0].valeursParametres[idInternePC].commentairesId = result.commentairesId;
                    });
                }
                this.updateAJustifier();
            });
    }

    addJustification(idInternePC: string, value: string) {
        this.matDialog
            .open(CommentModalComponent, {
                ...DialogUtils.configFullScreen(),
                data: {
                    replaceTypesCommentaires: true,
                    commentairesId: this.diagnostic.pointsDeControleBiens[0].valeursParametres[idInternePC].commentairesId,
                    typesCommentaires: this.COMMENT_TYPES,
                    typeNouveauCommentaire: this.typeNouveauCommentaireSelect(value),
                    localisation: this.getItemsLocalisationCommentaire(),
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result && result !== false) {
                    this.diagnostic.pointsDeControleBiens[0].valeursParametres[idInternePC].commentairesId = result.commentairesId;
                }
                this.updateAJustifier();
            });
    }

    onChangeComment() {
        this.updateAJustifier();
    }

    openGuideReference($event) {
        $event.stopPropagation();
        this.matDialog.open(GuideDeReferenceComponent, { ...DialogUtils.configFullScreen() });
    }

    showAides(checkpoint: any): boolean {
        let ret = false;
        const pointControleAide: PointControleAide[] = this._pointsControlesAides.filter((pca) => pca.idPointControle == checkpoint.idInterne);
        if (pointControleAide !== null && pointControleAide !== undefined && pointControleAide.length > 0) {
            ret = true;
        }
        return ret;
    }

    openHelp(checkpoint: any) {
        const pointControleAide: PointControleAide[] = this._pointsControlesAides.filter((pca) => pca.idPointControle == checkpoint.idInterne);
        const dialogRef = this.matDialog.open(CheckpointHelpModalComponent);
        dialogRef.componentInstance.title = checkpoint.description;
        const contents: { innerHTML: string; photo?: Photo }[] = [];
        pointControleAide
            .sort((a, b) => a.ordre - b.ordre)
            .forEach((pca) => {
                const photo = this._photos.find((ph) => ph.idInterne == pca.idPhoto);
                contents.push({ innerHTML: pca.description, photo: photo });
            });
        dialogRef.componentInstance.contents = contents;
    }
    /**
     * Event sur les boutons d'entête
     * Set les réponses auto
     * @param {string} idInterne
     */
    public onChangeBoutonHeader(idInterne: string) {
        const bouton = this._boutons.find((btn) => btn.idInterne == idInterne);
        if (bouton) {
            for (const cp of bouton.lstPointsControleReponse) {
                if (!this.diagnostic.pointsDeControleBiens[0].valeursParametres[cp.idPointControle]) {
                    this.diagnostic.pointsDeControleBiens[0].valeursParametres[cp.idPointControle] = new ValeurCheckpoint();
                }
                if (!this.diagnostic.pointsDeControleBiens[0].valeursParametres[cp.idPointControle].isGrey) {
                    if (this.getOption(cp.reponse)) {
                        this.diagnostic.pointsDeControleBiens[0].valeursParametres[cp.idPointControle].valeur = this.getOption(cp.reponse);
                    }
                }
            }
        } else {
            // Reinit
            for (const choice of this.headerChoices) {
                const btn = this._boutons.find((bt) => bt.idInterne == choice.value);
                for (const cp of btn.lstPointsControleReponse) {
                    this.diagnostic.pointsDeControleBiens[0].valeursParametres[cp.idPointControle] = null;
                }
            }
        }
    }

    private getOption(reponse: string): string | boolean {
        const option = [ouiOption, nonOption, nonVerifiabledOption, sansObjetOption].find((opt) => opt.label.toLowerCase() == reponse.toLowerCase());
        return option ? option.value : null;
    }

    ngAfterViewInit(): void {
        this.activatedRoute.queryParams
            .subscribe((params) => {
                const idInterne = params['idInterne'];
                if (idInterne) {
                    const ele = document.getElementById(idInterne);
                    if (ele) {
                        ele.scrollIntoView();
                        ele.classList.add('zoom-anim');
                    }
                }
            })
            .unsubscribe();
    }

    // Ceci est une fonction teste
    // A modifier si validée
    typeNouveauCommentaireSelect(valeur) {
        if (valeur === 'non') {
            return enumTypesCommentaire.JUSTIFICATION_NON_CONFORMITE;
        } else if (valeur === 'non_verifiable') {
            return enumTypesCommentaire.MOTIF_NON_VERIFIABLE;
        }
        return;
    }

    getItemsLocalisationCommentaire(): CustomSelectGroup[] {
        return this.electriciteService.getItemsLocalisationCommentaire(this.intervention);
    }
}
