<h1 mat-dialog-title>Modifier le mot de passe de l'utilisateur</h1>

<div mat-dialog-content>
    <mat-form-field class="w-100">
        <mat-label>Nouveau mot de passe</mat-label>
        <input
            matInput
            type="password"
            required
            autocomplete="off"
            [(ngModel)]="newPassword"
            minlength="4"
            maxlength="100"
            #newPasswordField="ngModel"
        />
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>Confirmez le nouveau mot de passe</mat-label>
        <input matInput type="password" required autocomplete="off" [(ngModel)]="newPasswordConfirm" />
    </mat-form-field>

    <mat-checkbox [(ngModel)]="notifyUserByEmail">Notifier automatiquement l'utilisateur par email</mat-checkbox>
</div>

<div mat-dialog-actions>
    <button
        mat-raised-button
        color="accent"
        [disabled]="
            !newPassword || !newPasswordConfirm || !newPasswordField.valid || newPassword !== newPasswordConfirm
        "
        (click)="submit()"
    >
        <span>Valider</span>
    </button>
</div>
