import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'step' })
export class StepPipe implements PipeTransform {
    constructor() {}

    transform(nbDecimals: number, defaultNbDecimals = 2): number {
        if (nbDecimals == null || isNaN(nbDecimals)) nbDecimals = defaultNbDecimals;
        return Math.pow(10, -nbDecimals);
    }
}
