<div class="equipement-container">
    <div class="h-100 row no-gutters p-4 scrollable-content">
        <mat-card class="d-flex w-100 p-2 mb-2">
            <mat-card-content class="w-100">
                <div class="d-flex justify-content-between w-100">
                    <div class="w-80 primary">Informations générales de l'équipement</div>
                    <div class="w-20">
                        <button
                            color="accent"
                            mat-raised-button
                            class="btn"
                            (click)="dupliquerEquipement(equipement?.id)"
                            [disabled]="equipement === undefined"
                        >
                            <lib-icon>content_copy</lib-icon>
                        </button>
                        <button
                            color="accent"
                            mat-raised-button
                            class="btn"
                            (click)="editerEquipement(equipement?.id)"
                            [disabled]="equipement === undefined"
                        >
                            <lib-icon>edit</lib-icon>
                        </button>
                        <button
                            color="warn"
                            mat-raised-button
                            class="btn"
                            (click)="supprimerEquipement(equipement?.id)"
                            [disabled]="equipement === undefined"
                        >
                            <lib-icon>delete</lib-icon>
                        </button>
                    </div>
                </div>

                <!-- Nom de l'equipement -->
                <div class="d-flex p-2 align-items-center">
                    <div class="col-xl-4 mat-subheading-2 m-0 primary">Nom</div>
                    <div class="col-xl-8">
                        {{ equipement?.name }}
                    </div>
                </div>

                <!-- codeBim de l'equipement -->
                <div class="d-flex p-2 align-items-center">
                    <div class="col-xl-4 mat-subheading-2 m-0 primary">Code bim</div>
                    <div class="col-xl-8">
                        {{ equipement?.productTypeCodeBim }}
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <!------------ Pictogramme ------------->
        <mat-card class="d-flex w-100 p-2 mb-2">
            <mat-card-content class="w-100">
                <div class="primary">Pictogramme</div>
                <div *ngIf="equipement?.iconImageData" class="pl-5">
                    <img
                        [src]="equipement?.iconImageData"
                        alt="Pictogramme de la catégorie"
                        class="img-fluid"
                        style="max-width: 100%; max-height: 60px"
                    />
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
