import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-button-toggle',
    templateUrl: './button-toggle.component.html',
    styleUrls: ['./button-toggle.component.scss'],
})
export class ButtonToggleComponent implements OnInit {
    @Input() isActive = false;
    @Input() label: string = null;
    @Input() toggleCondition;
    @Output() toggleChange = new EventEmitter<boolean>();

    constructor() {}

    ngOnInit(): void {}

    toggle() {
        this.isActive = !this.isActive;
        this.toggleChange.emit(this.isActive);
    }
}
