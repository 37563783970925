import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Assurance } from '../../../../../model/agence.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { CreationAssuranceModalComponent } from './creation-assurance/creation-assurance-modal.component';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/commons-lib';
import { Document } from '../../../../../model/document.model';
import { BonCommandeAnalyseState } from '../../../../../model/bon-commande.model';

@Component({
    selector: 'app-tableau-assurance',
    templateUrl: './tableau-assurance.component.html',
    styleUrls: ['./tableau-assurance.component.scss'],
})
export class TableauAssuranceComponent extends BaseComponent implements AfterViewInit, OnChanges {
    @Input() assurances: Assurance[] = [];

    @Input() set readonlyMode(readonlyMode: Boolean) {
        this._readonlyMode = readonlyMode;
        if (readonlyMode) {
            this.displayedColumns = ['compagnie', 'dateDebutValidite', 'dateEcheance'];
        } else {
            this.displayedColumns = ['compagnie', 'dateDebutValidite', 'dateEcheance', 'actions'];
        }
    }
    get readonlyMode(): Boolean {
        return this._readonlyMode;
    }

    @Output() updateAssurances: EventEmitter<Assurance[]> = new EventEmitter<Assurance[]>();

    private _readonlyMode: Boolean;
    displayedColumns: string[] = [];
    dataSource = new MatTableDataSource<Assurance>(this.assurances);

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(private readonly dialog: MatDialog) {
        super();
    }

    ngOnChanges(data): void {
        if (data.assurances) {
            this.dataSource = new MatTableDataSource<Assurance>(this.assurances);
        }
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
    }

    editAssurance(assurance: Assurance): void {
        const dialogRef = this.dialog.open(CreationAssuranceModalComponent, {
            width: '250px',
            data: { assurance: assurance },
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.assurances = this.assurances.filter((elt) => elt.numeroPolice !== assurance.numeroPolice);
                    this.assurances.push(result);
                    // this.assurances = [...this.assurances];
                    this.updateAssurances.emit([...this.assurances]);
                }
            });
    }
}
