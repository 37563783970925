import { Injectable } from '@angular/core';
import { InterventionReportData } from '../model/rapport.model';
import { TypePrestation } from '../model/type-prestation.model';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { DIAGNOSTICS_TREE, DiagnosticTree, ReportDataSchema } from '../../report-schema';

export class TemplateNode {
    name: string;
    title?: string;
    description?: string;
    type?: string;
    typesPrestation?: TypePrestation[];
    children?: TemplateNode[] = [];
    expandable: boolean;
    level: number;
    visible: boolean = true;
}

@Injectable({
    providedIn: 'root',
})
export class SchemaTemplateService {
    constructor(private readonly httpClient: HttpClient) {}

    getSchemaTemplate(): Observable<ReportDataSchema> {
        return this.httpClient.get<ReportDataSchema>(`assets/report-data/schema.json`).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    getInterventionReportDataSchema(schemaJson: any): TemplateNode[] {
        const resultat: TemplateNode[] = [];

        const interventionReportData = this.createTemplateNode(
            'InterventionReportData',
            schemaJson.definitions.InterventionReportData.type,
            schemaJson.definitions.InterventionReportData.description,
            schemaJson.definitions.InterventionReportData.title
        );
        const keys = Object.keys(schemaJson.definitions.InterventionReportData.properties);

        interventionReportData.children = keys.map((k) =>
            this.createNode(k, schemaJson.definitions.InterventionReportData.properties[k], schemaJson.definitions)
        );
        const interventionReportDataClone: TemplateNode = JSON.parse(JSON.stringify(interventionReportData));

        const diagsNodes = this.createDiagsNodes(DIAGNOSTICS_TREE, schemaJson);

        const node = interventionReportData.children.find((it) => it.name === 'diagnostics');
        if (node) {
            node.children = diagsNodes;
        }
        node.children.sort((a, b) => (a.name > b.name ? 1 : -1));
        resultat.push(interventionReportData);
        return resultat;
    }

    private createDiagsNodes(diagsToImplement: DiagnosticTree[], schemaJson: any) {
        return diagsToImplement.map((diag) => {
            const dataDiag = schemaJson.definitions[diag.reportData];
            const diagNode = this.createTemplateNode(diag.reportData, dataDiag.type, dataDiag.description, dataDiag.title);
            const keysDiag = Object.keys(dataDiag.properties);
            diagNode.typesPrestation = diag.typesPrestation;
            diagNode.children = keysDiag.map((kd) => {
                return this.createNode(kd, dataDiag.properties[kd], schemaJson.definitions);
            });
            return diagNode;
        });
    }

    private createTemplateNode(name: string, type: string, description: string, title: string) {
        const listDiagnosticsNodes = new TemplateNode();
        listDiagnosticsNodes.name = name;
        listDiagnosticsNodes.type = type;
        listDiagnosticsNodes.description = description;
        listDiagnosticsNodes.title = title;
        return listDiagnosticsNodes;
    }

    /**
     * Génére les données utilisées par le template pour afficher l'arborescence des diagtics
     * @param k key (nom du nœud)
     * @param v
     * @param schema
     */
    createNode(k, v, schema: any): TemplateNode {
        const newNode = new TemplateNode();
        newNode.name = k;
        newNode.description = v.description;
        if (v.type) {
            // Gestion des sous-nœuds tableaux
            if (v.type === 'array' && v.items) {
                if (v.items['$ref']) {
                    this.createSubNode(v.items['$ref'], newNode, schema, true);
                } else {
                    newNode.type = v.items.type ? v.items.type + '[]' : 'any[]';
                }
            } else {
                // Gestion des type primitifs
                newNode.type = v.type;
            }
        } else {
            // Gestion des sous-nœuds
            if (v['$ref']) {
                this.createSubNode(v['$ref'], newNode, schema, false);
            }
        }
        return newNode;
    }

    private createSubNode(v, newNode: TemplateNode, schema: any, fromArray = false) {
        const splitted = (v as string).split('/');
        newNode.type = fromArray ? splitted[splitted.length - 1] + '[]' : splitted[splitted.length - 1];
        splitted.shift();
        const subClass = splitted.map((it) => schema[it]).pop();
        let keys;
        if (subClass.properties) {
            keys = Object.keys(subClass.properties);
        } else if (subClass.enum) {
            return;
        }
        newNode.children = keys.map((clef) => this.createNode(clef, subClass.properties[clef], schema));
    }
}
