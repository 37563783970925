<h1 mat-dialog-title class="primary">
    <span>{{ titreModale }}</span>
</h1>

<div mat-dialog-content class="d-flex flex-column">
    <div class="w-100 pr-2 pt-2" [formGroup]="form">
        <mat-form-field class="w-100" *ngFor="let control of form.controls | keyvalue">
            <input [placeholder]="control.key" matInput [formControlName]="control.key" />
            <mat-error *ngIf="form.controls[control.key].hasError('error')"
                >{{ form.controls[control.key].errors.error }}
            </mat-error>
        </mat-form-field>
    </div>

    <div mat-dialog-actions class="justify-content-end">
        <button mat-button color="primary" (click)="cancel()">
            <lib-icon>close</lib-icon>
            <span>Annuler</span>
        </button>

        <button mat-raised-button color="accent" [disabled]="!form.valid" (click)="confirm()">
            <lib-icon>check</lib-icon>
            <span>Confirmer</span>
        </button>
    </div>
</div>
