import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Photo } from '../../diagnostics/electricite/model/electricite.model';

@Component({
    selector: 'app-checkpoint-help-modal',
    templateUrl: './checkpoint-help-modal.component.html',
    styleUrls: ['./checkpoint-help-modal.component.scss'],
})
export class CheckpointHelpModalComponent implements OnInit {
    title: string;
    contents: {
        innerHTML: string; //pour les description ou un contenu texte
        photo?: Photo;
        innerContenueHTML?: string; //si photo directement dans HTLM
    }[];
    constructor(public dialogRef: MatDialogRef<CheckpointHelpModalComponent>) {
        dialogRef.disableClose = false;
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth600-dialog');
    }

    confirm() {
        this.dialogRef.close();
    }

    public fullScreen(event: any) {
        try {
            event.target.requestFullscreen();
        } catch (e) {
            console.error(e);
        }
    }

    get isEmpty(): boolean {
        if (this.contents && this.contents.length) {
            return false;
        }
        return true;
    }
}
