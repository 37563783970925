import { cn_add, cn_camera, cn_clone, cn_marker, cn_mul } from '@acenv/cnmap-editor';
import { LevelToDisplay } from '../../../diagnostics/hap/model/hap.model';
import { ResultSeuil } from '../../../../model/prelevement-generique.model';

export class CndiagMarker extends cn_marker {
    // Correspond à l'identifiant de l'objet représenté par ce marker (zone, périmètre, prélèvement...)
    idReference: string;
    // Correspond au type de l'objet correspondant au marker
    level: LevelToDisplay;
    resultLevel: ResultSeuil;
    stroke_width: string;

    /**
     * En plus du traitement parent, on map le level et l'idReference
     * @param json
     * @param storey
     * @returns
     */
    static unserialize(json, storey): CndiagMarker {
        let marker = new CndiagMarker(storey);
        const markerTemp = cn_marker.unserialize(json, storey);
        marker = Object.assign(marker, markerTemp);

        if (typeof json.idReference === 'string') marker.idReference = json.idReference;
        if (typeof json.level === 'number') marker.level = json.level;
        if (typeof json.resultLevel === 'number') marker.resultLevel = json.resultLevel;
        if (typeof json.stroke_width === 'number') marker.stroke_width = json.stroke_width;

        return marker;
    }

    /**
     * En plus du traitement parent, on map le level et l'idReference
     * @returns
     */
    serialize() {
        const markerJson = super.serialize();
        markerJson.idReference = this.idReference;
        markerJson.level = this.level;
        markerJson.resultLevel = this.resultLevel;
        markerJson.stroke_width = this.stroke_width;

        return markerJson;
    }

    draw(camera: cn_camera, add_classes?: any[], ghost?: boolean, pic?: boolean): string {
        // On rajoute les classes correspondantes au niveau de précision du svg
        add_classes.push(`level${this.level}`);
        // On rajoute les classes correspondantes à l'analyse des résultats
        if (this.resultLevel !== undefined) {
            add_classes.push(`result-level${this.resultLevel}`);
        }

        let html = this.superDrawModified(camera, add_classes, ghost, pic);
        if (this.shape && this.level === LevelToDisplay.PERIMETRE) {
            let draw_class = 'marker_shape';
            html += "<path class='" + draw_class + "' d='";
            let inner_contour = this.shape.inner_contour;
            for (let j = 0; j < inner_contour.length; j++) {
                if (j == 0) html += 'M ';
                else if (j == 1) html += 'L ';
                let p = camera.world_to_screen(inner_contour[j]);
                html += '' + p[0] + ' ' + p[1] + ' ';
            }
            html += 'Z ';
            html +=
                "' style='stroke: " +
                this.shape_color +
                '; stroke-width: ' +
                this.stroke_width +
                'px; stroke-dasharray: ' +
                parseInt(this.stroke_width) * 2 +
                "; fill-opacity: 0;' />";
        }
        return html;
    }

    private superDrawModified(camera, add_classes = [], ghost = false, pic = false) {
        var html = '';
        const css_class = add_classes.concat(this._extra_class());
        if (this.shape) {
            var draw_class = 'marker_shape';
            if (css_class) draw_class += ' ' + css_class.join(' ');
            html += "<path class='" + draw_class + "' d='";
            var inner_contour = this.shape.inner_contour;
            for (var j = 0; j < inner_contour.length; j++) {
                if (j == 0) html += 'M ';
                else if (j == 1) html += 'L ';
                var p = camera.world_to_screen(inner_contour[j]);
                html += '' + p[0] + ' ' + p[1] + ' ';
            }
            html += 'Z ';
            html += "' style='fill: " + this.shape_color + '; opacity: ' + this.shape_opacity + "' />";
        }

        const p0 = camera.world_to_screen(this.position);
        const p1 = camera.world_to_screen(cn_add(this.position, cn_mul(this.normal, camera.screen_to_world_scale * 50)));
        let p2 = null;
        if (ghost || (this.tail_position[0] === 0 && this.tail_position[1] === 0)) {
            p2 = cn_clone(p1);
            if (this.config.line) {
                if (p0[0] > p1[0]) {
                    p2[0] -= 200;
                } else {
                    p2[0] += 200;
                }
            }
            this.tail_position = camera.screen_to_world(p2);
        } else {
            p2 = camera.world_to_screen(this.tail_position);
        }

        this.vertices = [this.position];
        // TODO reset p1
        this.vertices.push(camera.screen_to_world(p1));
        this.vertices.push(camera.screen_to_world(p2));

        var extra = '';
        if (css_class.length > 0) extra = css_class.join(' ');

        html += this.draw_line_section(p0, p1, p2, css_class);
        html += this.draw_head_section(p0, p1, camera, extra);
        html += this.draw_tail_section(p0, p1, p2, extra, css_class, pic);

        return html;
    }

    _draw_marker_icon(point, orientation_sign) {
        return ``;
    }

    _textbox_class(): string[] {
        const cssClasses = ['marker_text'];
        if (this.resultLevel !== undefined) {
            cssClasses.push(`result-level${this.resultLevel}`);
        }
        return cssClasses;
    }

    _draw_head(p0: any, pp1: any, pp2: any, camera: any, extra: any): string {
        return '';
    }

    draw_line_section(p0, p1, p2, css_class) {
        let html = '';
        if (this.config.line) {
            // afficher une ligne à 2 segments plutôt qu'une courbe bézier.
            html = `<path class="marker_line ${css_class.filter((cssClass) => cssClass !== 'selected').join(' ')}"
            d="M ${p0[0]} ${p0[1]} L ${p1[0]} ${p1[1]} ${p2[0]} ${p2[1]}" />`;
        }
        return html;
    }

    draw_tail_section(p0: any, p1: any, p2: any, extra: any, css_class: any, pic: any): string {
        // ne pas afficher le petit cercle à la fin de la ligne
        return super.draw_tail_section(p0, p1, p2, 'd-none', css_class, pic);
    }
}
