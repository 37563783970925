'use strict';

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2024 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** cn_lazy_data : data to store lazyly computed elements
//***********************************************************************************
//***********************************************************************************

export class cn_lazy_data {
    /**
     *
     * @param {any} identifyer
     */
    constructor(identifyer) {
        this.identifyer = identifyer;
        this.date = -1;
        this.data = null;
    }
}
