import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent, MongoUtils } from 'src/app/commons-lib';
import { ReferencePrestation } from '../../../../../model/reference-prestation.model';
import { ElementAControler, Volume } from '../../../../../model/bien.model';
import { ConfirmDialogComponent } from '../../../../../lib/confirmation/confirm-dialog.component';
import { TypeElementAControler, TypeVolume } from '../../../../../model/type-element-a-controler.model';
import { combineLatest, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { ReferenceService } from '../../../../../services/reference.service';
import { CnSpinnerService } from '../../../../shared/cn-spinner/service/cn-spinner.service';
import { MatDialog } from '@angular/material/dialog';
import { DescriptionBienService } from '../../../../../services/description-bien.service';
import { ListUtils } from '../../../../../utils/list.utils';
import { Equipement } from '../../../../../model/equipement.model';
import { CategorieEquipement } from 'src/app/model/categorie-equipement.model';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';

@Component({
    selector: 'app-description-bien-modal-equipements',
    templateUrl: './description-bien-modal-equipements.component.html',
    styleUrls: ['./description-bien-modal-equipements.component.scss'],
})
export class DescriptionBienModalEquipementsComponent extends BaseComponent {
    @Input()
    readonlyMode: boolean = false;

    @Input() set currentVolume(newVolume: Volume) {
        this._currentVolume = newVolume;
        this.filterTypesElements();
        this.filterElementsAControler();
        this.refreshListPiecesPreequipees();
    }

    get currentVolume() {
        return this._currentVolume;
    }

    @Input()
    selectedRefPrestations: ReferencePrestation[] = [];

    @Output()
    selectedRefPrestationsChange: EventEmitter<ReferencePrestation[]> = new EventEmitter<ReferencePrestation[]>();

    _currentVolume: Volume;

    displayPiecesPreequipees: boolean = false;
    listPiecesPreequipees: { nom: string; equipements: Equipement[] }[];

    isOverlayOpen = false;
    pieceToDisplay: { nom: string; equipements: Equipement[] };
    overlayTriggerOrigin: CdkOverlayOrigin;

    private typeVolumes: TypeVolume[];

    listEquipementsToDisplay: Equipement[];
    listChoixEquipementsToDisplay: Equipement[];
    searchTypesElements: string;

    listEquipements: Equipement[];
    refPrestations: ReferencePrestation[] = [];
    allCategoriesEquipements: CategorieEquipement[];

    constructor(
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly referenceService: ReferenceService,
        private readonly descriptionBienService: DescriptionBienService,
        private readonly dialog: MatDialog
    ) {
        super();
    }

    ngOnInit() {
        this.cnSpinnerService
            .withSpinner(
                combineLatest([
                    this.referenceService.findAllEquipements(),
                    this.referenceService.findAllTypesVolumes(),
                    this.referenceService.findAllReferencePrestations(),
                    this.referenceService.findAllCategoriesEquipements(),
                ]).pipe(takeUntil(this.ngUnsubscribe))
            )
            .subscribe(([equipements, typeVolumes, referencePrestations, allCategoriesEquipements]) => {
                this.typeVolumes = typeVolumes;
                this.listEquipements = equipements;
                this.allCategoriesEquipements = allCategoriesEquipements;
                this.refreshListPiecesPreequipees();

                referencePrestations.forEach((it) => {
                    this.refPrestations[it.typePrestation] = it;
                });

                this.filterTypesElements();
                this.filterElementsAControler();
                this.cnSpinnerService.hide();
            });
    }

    private refreshListPiecesPreequipees() {
        const currentVolumeType: TypeVolume = this.typeVolumes?.find((it) => it.id === this.currentVolume.usageId);

        this.listPiecesPreequipees = !currentVolumeType
            ? []
            : currentVolumeType.piecesPreequipeesConfigs.map((it) => {
                  return {
                      nom: it.nom,
                      equipements: this.listEquipements.filter((type) => it.idsEquipementsAssocies.includes(type.id)),
                  };
              });
    }

    handleAjouterElement(typeElementAControler: Equipement) {
        const nouvelElement = { ...typeElementAControler, id: MongoUtils.generateObjectId() } as Equipement;
        nouvelElement.name = ListUtils.createUniqueName(
            this.currentVolume.equipements.map((it) => it.name),
            typeElementAControler.name
        );
        this.currentVolume.equipements.push(nouvelElement);
        this.filterElementsAControler();
    }

    handleAjouterPiecePreequipee(piecePreequipee: { nom: string; equipements: Equipement[] }) {
        piecePreequipee.equipements.forEach((element) => {
            this.handleAjouterElement(element);
        });
    }

    handleSelectedRefPrestations($event) {
        this.selectedRefPrestations = $event;
        this.selectedRefPrestationsChange.emit(this.selectedRefPrestations);
        this.filterTypesElements();
        this.filterElementsAControler();
    }

    onKeyupSearchTypesElements($event) {
        this.filterTypesElements();
    }

    clearSearchTypesElements() {
        this.searchTypesElements = null;
        this.filterTypesElements();
    }

    handleOverlay(trigger, pieceToDisplay) {
        this.overlayTriggerOrigin = trigger;
        this.isOverlayOpen = !this.isOverlayOpen;
        this.pieceToDisplay = pieceToDisplay;
    }

    handleDupliquerElement(element: Equipement) {
        const message = "Voulez-vous vraiment dupliquer l'équipement " + element.name;
        this.dialog
            .open(ConfirmDialogComponent, {
                data: {
                    message,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.descriptionBienService.dupliquerElementAControler(this.currentVolume, element);
                    this.filterElementsAControler();
                }
            });
    }

    handleSupprimerEquipement(elementAControler: Equipement) {
        const message = "Voulez-vous vraiment supprimer l'élément " + elementAControler.name;
        this.dialog
            .open(ConfirmDialogComponent, {
                data: {
                    message,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.descriptionBienService.supprimerElementAControler(this.currentVolume, elementAControler);
                    this.filterElementsAControler();
                }
            });
    }

    private filterTypesElements() {
        if (this.listEquipements) {
            const typePrestasSelectionnes = this.selectedRefPrestations.map((it) => it.typePrestation);
            this.listChoixEquipementsToDisplay = this.listEquipements.filter(
                (it) =>
                    (!this.searchTypesElements || it.name.toLowerCase().includes(this.searchTypesElements.toLowerCase())) &&
                    (this.selectedRefPrestations.length === 0 ||
                        this.referenceService
                            .getTypePrestationsAssocie(it, this.allCategoriesEquipements)
                            .some((it) => typePrestasSelectionnes.includes(it)))
            );
        }
    }

    private filterElementsAControler() {
        const typesPrestas = this.selectedRefPrestations.map((ref) => ref.typePrestation);
        this.listEquipementsToDisplay = this.currentVolume.equipements.filter((it) => {
            return (
                this.selectedRefPrestations.length === 0 ||
                this.referenceService
                    .getTypePrestationsAssocie(it, this.allCategoriesEquipements)
                    .some((typePresta) => typesPrestas.includes(typePresta))
            );
        });
    }
}
