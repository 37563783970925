import { Pipe, PipeTransform } from '@angular/core';
import { PaginatedSelection } from '../shared/paging/page';

@Pipe({
    name: 'isPrestationChecked',
})
export class IsPrestationCheckedPipe implements PipeTransform {
    transform(value: { id: string }, selection: PaginatedSelection, ...args: unknown[]): boolean {
        if (selection.all.checked) {
            return true;
        } else if (value && selection.selectedItems) {
            const exists = selection.selectedItems.indexOf(value.id) !== -1;
            return selection.mode === 'select' ? exists : !exists;
        } else {
            return false;
        }
    }
}
