import { Injectable } from '@angular/core';
import { cn_background_map, cn_building, cn_image_dir } from '@acenv/cnmap-editor';
import { CnSvgConfiguratorWizy } from '../../cn_svg_configurator_wizy';
import { DrawingPicture, MM_TO_POINT_IMAGE_PRINT } from '@acenv/cnmap-angular-editor-lib';
import { ScreenshotSvg } from '../../../../../../model/screenshot-svg.model';
import { PointDeControleNiveau } from '../../../../../../model/point-de-controle.model';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { Intervention, RelationInterventionBien } from '../../../../../../model/intervention.model';
import { RulesService } from '../../../../../../services/rules.service';
import { SVG_FILTER } from '../../../../../../shared/constants/svg.constants';
import { BackgroundMapApiService } from '../../../../../../services/background-map-api.service';
import { DiagnosticHandlerService } from '../../../../../../services/diagnostic-handler.service';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { InterventionService } from '../../../../../../services/intervention.service';
import { ExportSvgFormConfMapper } from '../mappers/export-svg-form-mapper';
import { SvgFormValues } from '../interfaces/export-svg-form-values.interface';
import { Bien } from '../../../../../../model/bien.model';
import { ImageUtils } from '@acenv/commons-lib';
import { ICON_LOCKED_ORANGE, ICON_LOCKED_RED, ICON_UNLOCKED_GREEN } from '../../../tools/cndiag-space-labelizer';
import { FileApiService } from '../../../../../../services/file-api.service';
import { FileData } from '../../../../../../shared/offline/offline-storage.service';
import { InterventionFileApiService } from '../../../../../../services/intervention-file-api.service';
import { InterventionFileService } from '../../../../../../services/intervention-file.service';
import { TypeReferenceFichier } from '../../../../../../model/intervention-file.model';
import { encodeToBase64 } from '../../../../../../lib/utils/file.utils';
import { combineLatestOrEmpty } from '../../../../../../utils/rxjs.utils';
import { Hap } from '../../../../../diagnostics/hap/model/hap.model';

@Injectable({
    providedIn: 'root',
})
export class ExportSvgService {
    readonly svgFilter = SVG_FILTER;
    backgroundMaps: DrawingPicture[] = [];
    private heightMm = 210;

    private widthMm = 297;

    private ratio: number;

    constructor(
        private readonly rulesService: RulesService,
        private readonly interventionService: InterventionService,
        private readonly diagnosticHandlerService: DiagnosticHandlerService,
        private readonly exportSvgFormConfMapper: ExportSvgFormConfMapper,
        private readonly backgroundMapApiService: BackgroundMapApiService,
        private readonly fileApiService: FileApiService,
        private readonly interventionFileService: InterventionFileService,
        private readonly interventionFileApiService: InterventionFileApiService
    ) {
        (cn_background_map as any).image_id_to_url = (fileId) => {
            return (this.backgroundMaps.find((bgmu) => bgmu.fileId == fileId) || ({} as DrawingPicture)).imageUrl;
        };
    }

    refreshScreenshots(intervention: Intervention, diagnostic: Diagnostic) {
        if (diagnostic?.screenshotsPlan?.length) {
            const screenshots$ = this.loadBmsForAllUniquesBiens(diagnostic, intervention);
            return combineLatestOrEmpty([screenshots$, this.getObservableImages()]).pipe(
                switchMap(([_, imagesBase64]) => {
                    const sources = diagnostic.screenshotsPlan.map((screenshot) => {
                        const { selectedStorey, confParsed, bien, screenshotSvg } = this.prepareScreenshot(
                            intervention,
                            diagnostic,
                            screenshot,
                            imagesBase64
                        );
                        const configuratorForExport = this.refreshScreenshot(
                            diagnostic,
                            selectedStorey,
                            confParsed,
                            intervention,
                            bien,
                            screenshotSvg,
                            imagesBase64
                        );
                        return this.renderConfigurator(configuratorForExport, confParsed, screenshotSvg);
                    });
                    if (sources.length) {
                        return combineLatest(sources);
                    } else {
                        return of([]);
                    }
                })
            );
        } else {
            return of([]);
        }
    }

    saveScreenshots(intervention: Intervention, diagnostic: Diagnostic) {
        return this.refreshScreenshots(intervention, diagnostic).pipe(
            switchMap((screenshots) => {
                return screenshots?.length > 0 ? forkJoin(screenshots.map((s) => this.saveToFile(s, intervention, diagnostic))) : of([]);
            })
        );
    }

    private loadBmsForAllUniquesBiens(diagnostic: Diagnostic, intervention: Intervention): Observable<DrawingPicture[]>[] {
        // On récupère la liste d'ids uniques de biens pour éviter de lancer plusieurs fois les même requètes de get BMs
        const biensIdsUniqs = [
            ...new Set(
                diagnostic.screenshotsPlan
                    .map((s) => JSON.parse(s.configurationSvg))
                    .map((conf) => {
                        return conf.bien?.controls?.bien?.value?.id
                            ? conf.bien?.controls?.bien?.value?.id
                            : conf.espace?.controls?.espace?.value?.idBien;
                    })
            ),
        ];
        return biensIdsUniqs.map((id) =>
            this.loadBackgroundmaps(
                intervention.relationInterventionBiens.find((it) => it.bien.id === id),
                intervention.relationInterventionBiens.find((it) => it.bien.id === id).bien.backgroundMaps
            )
        );
    }

    /** Récupération des BM en fonction des biens
     *
     * @param currentBien
     * @param bms
     * @private
     */
    private loadBackgroundmaps(currentBien: RelationInterventionBien, bms: DrawingPicture[]): Observable<DrawingPicture[]> {
        if (bms.length > 0) {
            return forkJoin(
                bms
                    .slice()
                    .sort((a, b) => a.createdDate.localeCompare(b.createdDate))
                    .map((bg) => {
                        return this.backgroundMapApiService
                            .downloadBackgroundImage(this.interventionService.getCurrentInterventionValue().id, currentBien.id, bg.fileId)
                            .pipe(
                                map((res) => {
                                    const background = { ...bg };
                                    if (res) {
                                        background.imageUrl = res.fileContent;
                                    }
                                    return background;
                                })
                            );
                    })
            ).pipe(
                map((backgrounds) => {
                    backgrounds.forEach((b) => {
                        if (!this.backgroundMaps.find((bm) => bm.fileId === b.fileId)) {
                            this.backgroundMaps.push(backgrounds as any);
                            this.backgroundMaps = [...new Set(this.backgroundMaps.flat())];
                        }
                    });

                    return this.backgroundMaps;
                })
            );
        } else {
            return of(this.backgroundMaps);
        }
    }

    private prepareScreenshot(intervention: Intervention, diagnostic: Diagnostic, screenshotSvg: ScreenshotSvg, imagesBase64) {
        // Initialisation
        const espaces = (diagnostic?.contenuDiagnostic as Hap)?.espaces?.valeur || [];
        const biens = intervention.relationInterventionBiens.map((it) => it.bien);
        const confParsed = this.exportSvgFormConfMapper.toSideNavFormValues(JSON.parse(screenshotSvg.configurationSvg), biens, espaces);
        let bienId, storeyId, confEspace, selectedStorey, building, bien;

        // En cas de bien présent
        if (!confParsed.espace.espace && confParsed.bien.bien) {
            bienId = confParsed.bien.bien.id;
            storeyId = confParsed.bien.niveau?.storeyId;
            bien = intervention.relationInterventionBiens.find((it) => it.bien.id === bienId).bien;
            building = cn_building.unserialize(JSON.parse(bien.jsonPlan));
            selectedStorey = building.storeys.find((st) => st.ID === storeyId);

            if (!selectedStorey) {
                return;
            }

            // Selon les backgroundmaps affichés, on refiltre le cn_storey (HAP par exemple)
            selectedStorey.background_maps = selectedStorey.background_maps.filter((b) =>
                this.backgroundMaps.map((bm) => bm.fileId).includes(b.image_id)
            );
        } else {
            // En cas d'espace présent
            confEspace = confParsed.espace.espace;
            bienId = confEspace.idBien;
            bien = intervention.relationInterventionBiens.find((it) => it.bien.id === bienId).bien;
            if (bien.jsonPlan) {
                building = cn_building.unserialize(JSON.parse(bien.jsonPlan));
            }
        }
        // En cas d'espace présent
        if (confEspace) {
            if (confEspace.indexNiveau !== null && confEspace.indexNiveau !== undefined) {
                storeyId = building.storeys[confEspace.indexNiveau].ID;
                selectedStorey = building.storeys[confEspace.indexNiveau];
            } else {
                const newBuilding = cn_building.generate_new_building();
                if (building) {
                    const bgMap = building.storeys[0].background_maps.find((it) => it.image_id === confEspace.backgroundFileId);
                    if (bgMap) {
                        newBuilding.storeys[0].background_maps.push(bgMap);
                    }
                }
                // On prend le storey 0 car le new_building ne crée qu'un storey
                selectedStorey = newBuilding.storeys[0];
            }
        }
        return { selectedStorey, confParsed, bien, screenshotSvg };
    }

    private refreshScreenshot(
        diagnostic: Diagnostic,
        selectedStorey,
        confParsed,
        intervention: Intervention,
        bien: Bien,
        screenshotSvg: ScreenshotSvg,
        imagesBase64
    ) {
        this.diagnosticHandlerService
            .getTypePrestationService(diagnostic.typePrestation)
            .prepareStoreyForScreenshot(diagnostic, selectedStorey, confParsed);

        // MAJ des conformités
        const selectedPointControleNiveau = this.majConformiteElement(intervention, diagnostic, bien.id, screenshotSvg.storeyId);

        // Construction de la légende
        const legend = this.diagnosticHandlerService
            .getTypePrestationService(diagnostic.typePrestation)
            .generateLegendForScreenshot(diagnostic, confParsed);

        const currentNiveau = bien.description.find((it) => it.storeyId === selectedStorey.ID);

        // Création d'un nouveau configurator
        const configuratorForExport = new CnSvgConfiguratorWizy(
            null,
            selectedStorey,
            false,
            selectedPointControleNiveau,
            confParsed,
            legend,
            currentNiveau,
            imagesBase64
        );
        return configuratorForExport;
    }

    private renderConfigurator(
        configuratorForExport: CnSvgConfiguratorWizy,
        confParsed,
        screenshotSvg: ScreenshotSvg
    ): Observable<{ screenshotSvg: ScreenshotSvg; svgPlan: string }> {
        const behaviorSubject: BehaviorSubject<{ screenshotSvg: ScreenshotSvg; svgPlan: string }> = new BehaviorSubject<{
            screenshotSvg: ScreenshotSvg;
            svgPlan: string;
        }>(null);
        const { height, width } = this.updateConfiguratorRendererConf(configuratorForExport, confParsed, screenshotSvg);
        // Génération du contenu du SVG
        const renderSvgFromConfigurator = () => {
            const svgContent = configuratorForExport.render(width, height);
            let jsonSvg = `<svg width="${width}" height="${height}" `;
            jsonSvg += " xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>";
            jsonSvg += this.svgFilter.replace('</defs>', '');
            jsonSvg += svgContent.replace('<defs>', '');
            jsonSvg += '</svg>';
            behaviorSubject.next({ screenshotSvg, svgPlan: jsonSvg });
        };

        // On attend que les images soient complètement chargées avant de lancer le rendering
        if (configuratorForExport.are_images_loaded()) {
            renderSvgFromConfigurator();
        } else {
            configuratorForExport.on('images_loaded', () => renderSvgFromConfigurator());
        }
        return behaviorSubject.pipe(filter((res) => !!res));
    }

    private updateConfiguratorRendererConf(configuratorForExport: CnSvgConfiguratorWizy, confParsed, screenshotSvg: ScreenshotSvg) {
        // maj de la conf du configurator
        this.ratio = this.widthMm / this.heightMm;
        const height = Math.round(configuratorForExport.get_print_height() * MM_TO_POINT_IMAGE_PRINT(300));
        const width = Math.round(height * this.ratio);
        if (width != null && height != null) {
            configuratorForExport.render(width, height);
        }
        const planGroup = confParsed.plan;
        configuratorForExport.set_print_height(this.heightMm);
        this.updateConfiguratorPlanMapping(configuratorForExport, planGroup.general);
        if (width != null && height != null) {
            configuratorForExport.set_render_size(width, height);
        }
        this.updateConfiguratorPlanGroup(configuratorForExport, planGroup);
        configuratorForExport.set_scale(planGroup.general.scale);

        // MAJ du repositionnement de la camera
        if (screenshotSvg.cameraPosition && screenshotSvg.cameraPosition.length > 0) {
            configuratorForExport.set_camera_position(screenshotSvg.cameraPosition);
        }
        configuratorForExport.refresh();
        return { height, width };
    }

    updateConfiguratorPlanGroup(configurator: CnSvgConfiguratorWizy, planGroup) {
        if (configurator) {
            this.updateConfiguratorPlanMapping(configurator, planGroup.general);

            // Plan > Display Mapping :
            const displayGroups = planGroup.display;
            configurator.set_svg_param('render', displayGroups.render);
            configurator.set_svg_param('show_grid', displayGroups.showGrid);
            configurator.set_svg_param('show_scale', displayGroups.showScale);
            configurator.set_svg_param('show_background', displayGroups.showBackground);
            configurator.set_svg_param('show_compass', displayGroups.showCompass);

            // Plan > Elements Mapping :
            const elementsGroups = planGroup.elements;
            configurator.set_svg_param('show_paces', elementsGroups.showSpaces);
            configurator.set_svg_param('show_outer_walls', elementsGroups.showOuterWalls);
            configurator.set_svg_param('show_inner_walls', elementsGroups.showInnerWalls);
            configurator.set_svg_param('show_balconies', elementsGroups.showBalconies);
            configurator.set_svg_param('show_windows', elementsGroups.showWindows);
            configurator.set_svg_param('show_doors', elementsGroups.showDoors);
            configurator.set_svg_param('show_stairs', elementsGroups.showStairs);
            configurator.set_svg_param('show_slab_openings', elementsGroups.showSlabOpenings);
            configurator.set_svg_param('show_beams', elementsGroups.showBeams);
            configurator.set_svg_param('show_columns', elementsGroups.showColumns);
            configurator.set_svg_param('show_pipes', elementsGroups.showPipes);
            configurator.set_svg_param('show_objects_shape', elementsGroups.showObjectsShape);
            configurator.set_svg_param('show_objects_icon', elementsGroups.showObjectsIcon);

            // Plan > Text Mapping :
            const textsGroups = planGroup.text;
            configurator.set_svg_param('space_labels', textsGroups.showSpaceLabel);
            configurator.set_svg_param('area_visible', textsGroups.showSpaceArea);
            configurator.set_svg_param('show_visit_lock', textsGroups.showVisitLock);
            configurator.set_svg_param('show_markers', textsGroups.showMarkers);
            configurator.set_svg_param('show_numerotation', textsGroups.showNumerotation);

            // Plan > Cotes :
            const cotesGroups = planGroup.cotes;
            configurator.set_svg_param('show_outer_measures', cotesGroups.showOuterMeasures);
            configurator.set_svg_param('show_inner_measures', cotesGroups.showInnerMeasures);
            configurator.set_svg_param('show_opening_measures', cotesGroups.showOpeningMeasures);
        }
    }

    updateConfiguratorPlanMapping(configurator: CnSvgConfiguratorWizy, planGroup) {
        // Plan > General Mapping :
        configurator.set_resolution(planGroup.resolution);
        configurator.set_fixed_position(planGroup.fixedPosition);
        configurator.set_fixed_scale(planGroup.fixedScale);
        configurator.set_scale(planGroup.scale);
    }

    /**
     * Met à jour la conformité d'un élement en fonction des règles spécifiques du type de prestation
     * @param intervention
     * @param diagnostic
     * @param bienId
     * @param storeyId
     */
    majConformiteElement(intervention: Intervention, diagnostic: Diagnostic, bienId: string, storeyId: string): PointDeControleNiveau {
        if (diagnostic.pointsDeControleBiens) {
            // Récupération du PointControleNiveau par rapport au storey actuel
            const pointControleBien = diagnostic.pointsDeControleBiens.find((pcb) => pcb.idBien === bienId);
            if (pointControleBien) {
                const pointControleNiveau = pointControleBien.pointsDeControleNiveaux.find((pcn) => pcn.storeyId === storeyId);

                if (pointControleNiveau) {
                    pointControleNiveau.pointsDeControleVolumes.forEach((pcv) => {
                        pcv.pointsDeControleElements.forEach((pce) => {
                            this.rulesService.computeConformity(
                                pce,
                                diagnostic.contenuDiagnostic,
                                this.rulesService.findRulesControlIntoPrestation(
                                    intervention.prestationsDiagnostics.find((it) => it.idDiagnostic === diagnostic.id)
                                )
                            );
                        });
                    });

                    return pointControleNiveau;
                } else {
                    return undefined;
                }
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    }

    exportConfiguration(value, formValues1: SvgFormValues) {
        const svgConf = JSON.parse(JSON.stringify(value));
        // tslint:disable-next-line:forin
        for (const keyFormValue in formValues1) {
            // tslint:disable-next-line:forin
            for (const subKey in formValues1[keyFormValue]) {
                if (svgConf[keyFormValue] && svgConf[keyFormValue].controls && svgConf[keyFormValue].controls[subKey]) {
                    svgConf[keyFormValue].controls[subKey].value = formValues1[keyFormValue][subKey];
                }
                if (svgConf[keyFormValue] && svgConf[keyFormValue].groups && svgConf[keyFormValue].groups[subKey]) {
                    for (const subSubKey in formValues1[keyFormValue][subKey]) {
                        if (svgConf[keyFormValue].groups[subKey].controls && svgConf[keyFormValue].groups[subKey].controls[subSubKey]) {
                            svgConf[keyFormValue].groups[subKey].controls[subSubKey].value = formValues1[keyFormValue][subKey][subSubKey];
                        }
                    }
                }
            }
        }
        // On force le blocage de l'échelle
        svgConf.plan.groups.general.controls.fixedScale.value = true;
        if (svgConf.bien?.controls?.bien?.value?.id) {
            svgConf.bien.controls.bien.value = { id: svgConf.bien?.controls?.bien?.value?.id };
        }
        if (svgConf.bien?.controls?.niveau?.value?.id) {
            svgConf.bien.controls.niveau.value = {
                id: svgConf.bien?.controls?.niveau?.value?.id,
                storeyId: svgConf.bien?.controls?.niveau?.value?.storeyId,
            };
        }
        if (svgConf.espace?.controls?.espace?.value?.id) {
            svgConf.espace.controls.espace.value = { id: svgConf.espace?.controls?.espace?.value?.id };
        }

        return JSON.stringify(svgConf);
    }

    getObservableImages() {
        return forkJoin(
            [ICON_UNLOCKED_GREEN, ICON_LOCKED_ORANGE, ICON_LOCKED_RED].map((imgName) =>
                ImageUtils.getBase64ImageFromURL(cn_image_dir() + imgName).pipe(
                    map((base64) => {
                        return {
                            key: imgName,
                            value: base64,
                        };
                    })
                )
            )
        );
    }

    private saveToFile(
        screenshot: { screenshotSvg: ScreenshotSvg; svgPlan: string },
        intervention: Intervention,
        diagnostic: Diagnostic
    ): Observable<any> {
        const screenshotInDiag = diagnostic?.screenshotsPlan?.find((s) => s.id === screenshot.screenshotSvg.id);
        return this.fileApiService.uploadFile(`data:image/svg+xml;,${encodeToBase64(screenshot.svgPlan)}`, `${screenshot.screenshotSvg.id}.svg`).pipe(
            switchMap((fileData: FileData) =>
                this.interventionFileApiService.upsert(
                    this.interventionFileService.getNewInterventionFile(
                        intervention.id,
                        diagnostic.id,
                        screenshotInDiag.id,
                        TypeReferenceFichier.FICHIER_DOCUMENT,
                        fileData.fileId
                    )
                )
            ),
            tap((fileData: FileData) => (screenshotInDiag.jsonPlan = fileData.fileId))
        );
    }
}
