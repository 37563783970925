import { CODE_NON_COMMUNIQUE, CONCLUSION_ISOLANT, EGAL, INFERIEUR, INFERIEUR_EGAL, SUPPERIEUR, SUPPERIEUR_EGAL } from '../../../shared/cee.constants';
import { CeeService } from '../../../services/cee.service';
import { Anomalie, CeeConfig, CeeModel, PointControle } from '../../../model/cee.model';
import { first } from 'rxjs/operators';
import { Component } from '@angular/core';
import { combineLatest } from 'rxjs';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { EtatProgressionService } from '../../../../../../services/etat-progression.service';

@Component({
    selector: 'app-ano-isolant',
    templateUrl: './ano-isolant.component.html',
})
export class AnoIsolantComponent {
    anomalies: {
        solution: string;
        anomalies: string[];
    }[] = [];
    constructor(
        private readonly etatProgressionService: EtatProgressionService,
        private readonly diagnosticService: DiagnosticService,
        private readonly ceeService: CeeService
    ) {
        combineLatest([this.diagnosticService.getCurrentDiagnostic(), this.ceeService.ceeConfig$])
            .pipe(first())
            .subscribe(([diagnostic, config]) => {
                this.initAnomalies(config, this.ceeService.mapToCeeModel(diagnostic.contenuDiagnostic));
                this.etatProgressionService.updateDiagnostic(CONCLUSION_ISOLANT, 'VOID', diagnostic);
            });
    }

    private initAnomalies(config: CeeConfig, ceeModel: CeeModel) {
        for (const solution of ceeModel.solutions.solutions) {
            let nomSolution = '';
            if (solution.infoIsolant) {
                const solValue = solution.infoIsolant.find((val) => this.ceeService.idsNomSolution.includes(val.id));
                if (solValue) {
                    nomSolution = solValue.valeur;
                }
                const iAnomalie = {
                    solution: nomSolution,
                    anomalies: [],
                };
                for (const ptc of solution.infoIsolant) {
                    const pointcontrole: PointControle = config.pointsControles.find((p) => p.idInterne === ptc.id);
                    if (!pointcontrole) {
                        continue;
                    }
                    let anomalie: Anomalie = pointcontrole.lstAnomalie.find((an) => {
                        return an.codeReponse == ptc.valeur || (an.codeReponse === CODE_NON_COMMUNIQUE && ptc.checked);
                    });
                    if (!anomalie) {
                        anomalie = pointcontrole.lstAnomalie.find((an) => {
                            switch (an.type) {
                                case INFERIEUR:
                                    return +ptc.valeur < +an.valeurReference;
                                case SUPPERIEUR:
                                    return +ptc.valeur > +an.valeurReference;
                                case EGAL:
                                    return +ptc.valeur === +an.valeurReference;
                                case SUPPERIEUR_EGAL:
                                    return +ptc.valeur >= +an.valeurReference;
                                case INFERIEUR_EGAL:
                                    return +ptc.valeur <= +an.valeurReference;
                            }
                            return false;
                        });
                    }
                    if (anomalie) {
                        iAnomalie.anomalies.push(anomalie.description ? anomalie.description : pointcontrole.description);
                    }
                }
                // Point de controle Isolant
                for (const key of Object.getOwnPropertyNames(solution.pointsDeControles.data)) {
                    // Uniquement 161
                    const numero = solution.pointsDeControles.data[key].find((pt) => pt.id === 'PC416')?.valeur;
                    const nom = solution.pointsDeControles.data[key].find((pt) => pt.id === 'PC417')?.valeur;
                    const dotName = nom ? `${nom} N° ${numero ?? ''}` : key;
                    for (const checkpoint of solution.pointsDeControles.data[key]) {
                        if (checkpoint?.isAnomalie && 'PC421' === checkpoint?.id) {
                            iAnomalie.anomalies.push(dotName + " : la marque de la housse n'existe pas parmis la liste");
                        } else if (checkpoint?.isAnomalie && 'PC422' === checkpoint?.id) {
                            iAnomalie.anomalies.push(dotName + " : la référence de la housse n'existe pas parmis la liste");
                        }
                    }
                }
                this.addAnomalie(iAnomalie);
            }
        }
    }

    private addAnomalie(pAnomalie: any) {
        if (pAnomalie.anomalies.length) {
            this.anomalies.push(pAnomalie);
        }
    }
}
