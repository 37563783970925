<mat-card>
    <div class="input-with-prefix">
        <mat-form-field appearance="outline" class="w-50">
            <input
                matInput
                type="text"
                (keyup)="onKeyupSearchUsers($event)"
                [(ngModel)]="searchValue"
                placeholder="Recherche libre"
            />
            <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
    </div>
    <div class="d-flex flex-column">
        <table
            mat-table
            [dataSource]="dataSource"
            matSort
            (matSortChange)="onChangeSortUsers($event)"
            [matSortActive]="sort?.active"
            [matSortDirection]="sort?.direction"
            matSortDisableClear="true"
        >
            <ng-container matColumnDef="lastName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
                <td mat-cell *matCellDef="let row">{{ row.lastName }}</td>
            </ng-container>

            <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Prénom</th>
                <td mat-cell *matCellDef="let row">{{ row.firstName }}</td>
            </ng-container>

            <ng-container matColumnDef="companyName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Agence</th>
                <td mat-cell *matCellDef="let row">{{ row.companyName }}</td>
            </ng-container>

            <ng-container matColumnDef="listeRoleString">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Rôle</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.listeRoleString }}
                </td>
            </ng-container>

            <ng-container matColumnDef="nbInterventionsEnCours">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nb interventions en cours</th>
                <td mat-cell *matCellDef="let row">
                    <!-- TODO A réactiver quand la recherche par prestataire sera fonctionnelle sur le tableau d'intervention -->
                    <!-- <span
                        [ngClass]="{ 'item-clickable item-underline': row.nbInterventionsEnCours !== 0 }"
                        (click)="row.nbInterventionsEnCours !== 0 ? goToInterventionsOperateur(row) : ''"
                        >{{ row.nbInterventionsEnCours }}</span
                    > -->
                    {{ row.nbInterventionsEnCours }}
                </td>
            </ng-container>

            <ng-container matColumnDef="deletedDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Date de sortie</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.deletedDate | date: DATE_FORMAT_FRANCAIS_SANS_HEURE }}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                    <button mat-icon-button (click)="onClickConsulter(row)">
                        <mat-icon>visibility</mat-icon>
                    </button>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="onClickEditerUser(row)">
                            <mat-icon>edit</mat-icon>
                            <span>Éditer</span>
                        </button>
                        <button mat-menu-item (click)="onClickSortirUser(row)">
                            <mat-icon>logout</mat-icon>
                            <span>Sortir l'employé</span>
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator
            #paginator
            *ngIf="dataSource.page$ | async as page"
            [length]="page.totalElements"
            [pageSize]="page.size"
            [pageIndex]="page.number"
            [pageSizeOptions]="[initialPageSize, 50, 100]"
            (page)="dataSource.fetch($event.pageIndex, paginator.pageSize)"
        ></mat-paginator>
    </div>
</mat-card>
